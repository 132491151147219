import styled from "styled-components"
import { Upload } from "antd"
const { Dragger } = Upload
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  width: 100%;

  ${props => props.flex && `
    display: flex;
    flex-direction: column;
    gap: 30px;
  `}
`

export const DocumentTypeWrap = styled.div`
  padding: 30px;
`
export const StickyWrap = styled.div`
  position: sticky;
  bottom: 0px;
  background: white;
  width: 100%;
  border-top:1px solid #F1F1FE;
  padding-bottom:20px;
`

export const CardV2 = styled.div`
  display: flex;
  width: ${props => props.cardWidth ? props.cardWidth : "100%"};
  padding: ${props => props.padding || "16px"};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #DFDDDD;

  ${props => props.cardMaxWidth && `
    max-width: ${props.cardMaxWidth};
  `}

  .ant-upload {
    // padding: 50px;
    // width: 456px;
    display: flex;
    padding: 0px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 33.5px;
    background: #5A5FF2;
    border: 1px solid transparent;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    display: none;
  }

  .ant-upload-text {
    color: #F9F9F9 !important;
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .anticon-cloud-upload {
    color: black !important;
  }
`

export const SectionTitle = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  margin: 0;
`

export const FileUploadWrap = styled.div``

export const ToggleWrap = styled.div`
  display: flex;
  gap: 15px;

  p {
    color: var(--Color-Gray-400, #41446A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 149.5%; /* 17.94px */
    letter-spacing: -0.5px;
  }

  .ant-switch-checked {
    background-color: #5A5FF2;
  }
`

export const InformationWrap = styled.div`
  display: flex;
  gap: 5px;

  img {
    width: 15.481px;
    height: 16px;
    position: relative;
    top: 1px;
  }

  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.523%; /* 18.063px */
  }
`

export const UploadedWrap = styled.div`
  padding-top: 20px;
`

export const ListedDocsWrap = styled.div`
  padding-top: 33px;

  ${props => props.padding && `
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 9px;
    background: var(--Color-Neutral-100, #F1F1FE);
  `}
`

export const DocsTypeTitle = styled.p`
  color: var(--BG-Dark-mode, #292A37);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
`

export const CTAWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
`

export const TooltipWrap = styled.div`
  color: #303030 !important;
  img {
    height: 16px;
    width: 16px;
  }

  p, h1, h2, span {
    color: #303030;
  }
`
export const SubmitWrap = styled.div`
  padding-top: 20px;
  width: 30%;
  margin-left:-10px;
`
export const GoBack = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100px;
  text-decoration: underline;
  color: #5b5ff3;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
`

export const StyledDragger = styled(Dragger)`
  padding: 0 !important;

  .ant-upload.ant-upload-btn {
    padding: 0 !important;
  }

// .ant-upload.ant-upload-drag{
//   border: none !important;
//   background: none !important;
//   padding: 16px !important;
// }
`
