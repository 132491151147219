import { get, isEmpty } from "lodash"
import {
  takeLatest,
  take,
  call,
  put,
  select,
  race,
  takeEvery,
} from "redux-saga/effects"
import { destroy as reset } from "redux-form"
import {
  delay,
} from "redux-saga"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import { isSuccess } from "services/Utils"
import RadiusLogoBig from "images/Graphics/RadiusIconBig.svg"
import {
  getScheduledRoomEventDetailsAction,
} from "container/EventRoom/actions"
import {
  addUserToSpotlightNonTrack,
  addUsersToGridView,
  convertToGridView,
  createNameTag,
  convertToSidebarMode,
  createUserCircle,
  addUsersToSidebar,
} from "dumbComponents/VideoRooms/utils"
import { toast as createToast } from "react-toastify"
import * as RoomsActions from "./actions"
import * as RoomsAPI from "./apis"
import { convertToRichText } from "../Academy/selector"

const toastConfig = {
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "video-room-notifications",
}

const toggleGridView = RoomsActions.toggleGridViewAction.call
const toggleOtherUsersView = RoomsActions.toggleOtherUsersViewAction.call
const toggleSpotlightView = RoomsActions.toggleSpotlightViewAction.call
const toggleSidebarView = RoomsActions.toggleSidebarViewAction.call
const pinLocally = RoomsActions.pinUserLocallyAction.call
const resizeScreen = RoomsActions.screenResizeAction.call

function* handleInitLiveRoom(action) {
  try {
    const {
      roomId,
      agentId,
      onSuccess,
      kickUserAndJoin,
      token,
    } = action.data
    // Step 1: Get the room details
    const currentAgentId = parseInt(agentId, 10)

    const roomsResponse = yield take(RoomsActions.getRoomsActions.SUCCESS)

    yield put(RoomsActions.getRoomMembersAction.request({ roomId, bypassUIRefresh: true }))

    const membersAPIResponse = yield take(RoomsActions.getRoomMembersAction.SUCCESS)

    const roomsPayload = get(roomsResponse, "data[0]")

    const isHost = roomsPayload && roomsPayload.host_id === currentAgentId

    if (!token) {
      //if token doesnt exist or is host

      const currentSpeakers = get(membersAPIResponse, "data.speakers", [])

      const tokenExist = null

      // Step 2: Check if logged in user is Host, Listener or speaker? and then join the room

      const existingSpeaker = membersAPIResponse && currentSpeakers.filter(item => item.id === currentAgentId)

      yield put(RoomsActions.joinRoomsActions.request({
        roomId,
        payload: {
          role: isHost || existingSpeaker && existingSpeaker.length > 0 ? 0 : 1,
          check_multiple_user_session: 1,
          kick_existing_user_and_join: kickUserAndJoin || 0,
        },
      }))

      /*Let users join with fresh token*/
      if (!tokenExist || tokenExist === null) {
        if (isHost || existingSpeaker && existingSpeaker.length > 0) {
          yield put(RoomsActions.userCountAction.call(currentAgentId))
        }

        /**HOST JOINS WITH AUDIO ON */
        /**OTHERS WITH AUDIO OFF */
        if (isHost) {
          const payload = {
            agent_id: currentAgentId,
            muted: 0,
          }
          yield put(RoomsActions.muteUserAction.request({
            roomId,
            payload,
          }))
        } else if (existingSpeaker && existingSpeaker.length > 0) {
          yield put(RoomsActions.toggleMicrophoneAction.call(true))
        }

        // Now wait for Join Room, response
        const joinRoomResponse = yield take(RoomsActions.joinRoomsActions.SUCCESS)
        onSuccess({
          token: get(joinRoomResponse, "data.token"),
        })
      } else {
        throw new Error("No Token Error")
      }
    } else {
      if (isHost) {
        yield put(RoomsActions.userCountAction.call(currentAgentId))
        const payload = {
          agent_id: currentAgentId,
          muted: 0,
        }
        yield put(RoomsActions.muteUserAction.request({
          roomId,
          payload,
        }))
      }

      onSuccess({
        token: token.token || token,
      })
    }
  } catch (error) {
    const { onFailure } = action.data
    onFailure(error)
    console.error(error)
  }
}

function* handleGetToken(action) {
  try {
    const { payload } = action.data
    const res = yield call(RoomsAPI.createTokenAPI, { payload })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getTokenAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getTokenAction.failure(error))
  }
}

function* handleGetRooms(action) {
  try {
    const {
      roomId,
      appendUser,
      id,
      playVideo,
      track,
      user,
      getUserName,
      fetchLive,
      onSuccess,
      redirect,
    } = action.data || {}
    let q = ""
    if (!isEmpty(action.data)) {
      q += `?${fetchLive ? "fetch_live=true" : ""}`
    }
    if (roomId) {
      q += `room_id=${roomId}`
    }
    const res = yield call(RoomsAPI.getRoomsAPI, q)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getRoomsActions.success(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }
  } catch (error) {
    yield put(RoomsActions.getRoomsActions.failure(error))
  }
}

function* handleJoinRoom(action) {
  try {
    const { roomId, payload, callback } = action.data
    const res = yield call(RoomsAPI.joinRoomAPI, { roomId, payload })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.joinRoomsActions.success(response))
      if (callback) {
        callback({
          authToken: response.token,
        })
      }
    }
  } catch (error) {
    const { data, status } = error
    const { error: responseError } = data
    const { message } = responseError
    //pop-up for switch
    if (status === 409) {
      yield put(RoomsActions.toggleSwitchDeviceAction.call(true))
    }
    yield put(RoomsActions.getRoomsActions.failure(error))
  }
}

function* handleCreateRoom(action) {
  try {
    const { payload, isRedirect, history, external_url } = action.data
    const res = yield call(RoomsAPI.createRoomAPI, { payload })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.createRoomsActions.success(response))
      if (isRedirect && !external_url) {
        const { room, token } = response
        const { name } = room
        const roomName = name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ").join("-").substr(0, 50)
        yield put(RoomsActions.toggleCreateRoomModalAction.call(false))
        yield put(RoomsActions.clearCreatePayloadAction.call())
        yield put(RoomsActions.clearAndResetAllForms.call())
        /**SAFARI DOES NOT ALLOW POPUP */
        if (token) {
          root.localStorage.setItem("roomsJoinToken", token)
        }
        const liveRoomUrl = `/rooms/${room.room_id}/live/${roomName}`
        // const url = `/rooms/onboard/${room.room_id}/${roomName}?token=${token}`
        history.push(liveRoomUrl)
      } else if (external_url) {
        history.push("/rooms")
        root.open(external_url, "_blank")
      }
    }
  } catch (error) {
    yield put(RoomsActions.createRoomsActions.failure(error))
  }
}

function* handleLeaveRoom(action) {
  try {
    const { roomId, callback, handleExit } = action.data
    const res = yield call(RoomsAPI.leaveRoomAPI, roomId)
    if (isSuccess(res)) {
      CookiesStorage.remove("joinToken")
      CookiesStorage.remove("hasUserOnboarded")
      root.localStorage.removeItem("roomsJoinToken")
      const { response } = res.data
      if (callback) {
        callback()
      }
      if (!handleExit) {
        root.open("/community", "_self")
        root.close()
      }
    }
  } catch (error) {
    CookiesStorage.remove("joinToken")
    CookiesStorage.remove("hasUserOnboarded")
    yield put(RoomsActions.leaveRoomAction.failure(error))
  }
}

function* handleGetSuggestedUsers(action) {
  try {
    const res = yield call(RoomsAPI.getSuggestedUsersAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getSuggestedUsersActions.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getSuggestedUsersActions.failure(error))
  }
}

function* handleSendUserInvitation(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.sendUserInvitationAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.sendUserInvitationAction.success(response))
      createToast("Invite sent", toastConfig)
    }
  } catch (error) {
    yield put(RoomsActions.sendUserInvitationAction.failure(error))
  }
}

function* handleConfigureHost(action) {
  const {
    datum: hostObject,
    speakers,
    sharedScreens,
    remotePinnedUsers,
  } = action.data
  const existingRedux = yield select(state => state.rooms)

  const host = document.getElementById(`video-container-${hostObject.id}`)
  if (host !== null) {
    const hostElement = document.querySelector(`#video-container-${hostObject.id} .rooms-name-tag`)
    const hostImage = document.querySelector(`#video-container-${hostObject.id} .userCircle img`)
    const hoverElement = document.querySelector(`#controls-${hostObject.id}`)

    if (hostElement) {
      const name = `${hostObject.firstname} ${hostObject.lastname}`
      hostElement.innerText = name
    }
    if (hostImage) {
      hostImage.setAttribute("src", hostObject.image_url || RadiusLogoBig)
    }

    if (hoverElement) {
      hoverElement.classList.remove("hover-controls")
      hoverElement.classList.remove("speaker-hover-controls")
      hoverElement.classList.add("hide-hover-controls")
    }
  } else {
    /* THIS CASE HANDLES FOR OTHERS */
    const spotlightExist = document.querySelector(`#video-container-${hostObject.id}`)
    if (!spotlightExist) {
      const name = `${hostObject.firstname} ${hostObject.lastname}`
      yield put(RoomsActions.toggleSpotlightViewAction.call(true))
      if (speakers.length > 1) {
        if (sharedScreens.length > 0 || remotePinnedUsers.length > 0) {
          //let's try checking this
          convertToSidebarMode(hostObject.id, toggleSidebarView)
          yield put(toggleGridView(false))
          yield put(toggleSidebarView(true))
          addUsersToSidebar(null, hostObject.id, name, hostObject.image_url)
        } else {
          convertToGridView(toggleGridView, toggleOtherUsersView, toggleSpotlightView, hostObject.id)
          yield put(toggleSpotlightView(false))
          yield put(toggleGridView(true))
          yield put(toggleOtherUsersView(false))
          yield put(toggleSidebarView(false))
          addUsersToGridView(null, hostObject.id, name, null, existingRedux)
          yield put(resizeScreen())
        }
      } else {
        addUserToSpotlightNonTrack(hostObject.id, name, existingRedux)
      }
    }
    yield put(RoomsActions.userCountAction.call(hostObject.id))
  }
}

function* handleConfigureSpeakers(action) {
  const {
    datum: speakerObject,
    hostId,
    listeners,
    sharedScreens,
    remotePinnedUsers,
  } = action.data

  const existingRedux = yield select(state => state.rooms)

  const allUsers = speakerObject && speakerObject.filter(item => item.id !== hostId)
  const agentId = CookiesStorage.load("agentId")

  const isListener = listeners && listeners.filter(item => item.id === parseInt(agentId, 10))

  if (allUsers && allUsers.length > 0) {
    for (let index = 0; index < allUsers.length; index += 1) {
      yield put(RoomsActions.userCountAction.call(allUsers[index].id))
      const speaker = document.getElementById(`video-container-${allUsers[index].id}`)
      if (speaker) {
        const speakerElement = document.querySelector(`#video-container-${allUsers[index].id} .rooms-name-tag`)
        //const speakerCircle = document.querySelector(`#video-container-${allUsers[index].id} .userCircle`)
        const speakerImage = document.querySelector(`#video-container-${allUsers[index].id} .userCircle img`)
        const name = `${allUsers[index].firstname} ${allUsers[index].lastname}`
        const hoverElement = document.querySelector(`#controls-${allUsers[index].id}`)
        const micMuteElement = document.querySelector(`#microphone-mute-${allUsers[index].id}`)
        const micUnMuteElement = document.querySelector(`#microphone-unmute-${allUsers[index].id}`)
        const currentUserMuted = allUsers[index].isMuted || 0
        const imageUrl = allUsers[index].image_url
        if (speakerElement) {
          speakerElement.innerText = name
        } else {
          const nameTag = createNameTag()
          nameTag.innerText = name
          if (speaker) {
            speaker.append(nameTag)
          }
        }

        if (hoverElement) {
          hoverElement.classList.remove("hide-hover-controls")
          hoverElement.classList.remove("hover-controls")
          hoverElement.classList.add("speaker-hover-controls")
          //`microphone-mute- isMuted
          //microphone-unmute-
          // if (currentUserMuted === 1) {
          //   micMuteElement.classList.add("show-hover-mic")
          //   micMuteElement.classList.remove("hide-hover-mic")

          //   micUnMuteElement.classList.add("hide-hover-mic")
          //   micUnMuteElement.classList.remove("show-hover-mic")
          // } else {
          //   micMuteElement.classList.add("hide-hover-mic")
          //   micMuteElement.classList.remove("show-hover-mic")

          //   micUnMuteElement.classList.add("show-hover-mic")
          //   micUnMuteElement.classList.remove("hide-hover-mic")
          // }
        } else {
          //TODO NEED TO CHECK THIS CASE.
        }

        if (speakerImage) {
          speakerImage.setAttribute("src", imageUrl || RadiusLogoBig)
        } else {
          const circle = createUserCircle()
          circle.setAttribute("src", imageUrl || RadiusLogoBig)
          circle.style.display = "none"
          if (speaker) {
            speaker.append(circle)
          }
        }
      } else {
        const name = `${allUsers[index].firstname} ${allUsers[index].lastname}`
        const img = allUsers[index].image_url
        if (isListener && isListener.length > 0 || allUsers.length > 0) {
          //convert to grid view
          if (sharedScreens.length > 0 || remotePinnedUsers.length > 0) {
            //let's try checking this
            convertToSidebarMode(hostId, toggleSidebarView)
            yield put(toggleGridView(false))
            yield put(toggleSidebarView(true))
            addUsersToSidebar(null, allUsers[index].id, name, img)
          } else {
            convertToGridView(toggleGridView, toggleOtherUsersView, toggleSpotlightView, hostId)
            yield put(toggleSpotlightView(false))
            yield put(toggleGridView(true))
            yield put(toggleSidebarView(false))
            yield put(toggleOtherUsersView(false))
            addUsersToGridView(null, allUsers[index].id, name, img, existingRedux)
            yield put(resizeScreen())
          }
        }
      }
    }
  }
}

function* handleConfigureScreenSharers(action) {
  try {
    const {
      screenID,
      speakers,
      name,
    } = action.data

    const screenExists = document.querySelector(`#video-container-${screenID}`)
    if (!screenExists) {
      yield put(RoomsActions.toggleSpotlightViewAction.call(true))
      if (speakers.length > 1) {
        //converToGrid - This entire block can be removed
        convertToGridView(toggleGridView, toggleOtherUsersView, toggleSpotlightView, screenID)
        yield put(toggleSpotlightView(false))
        yield put(toggleGridView(false))
        yield put(toggleOtherUsersView(false))
        addUsersToGridView(null, screenID, name, null, null, true)
        yield put(resizeScreen())
      } else {
        addUserToSpotlightNonTrack(screenID.id, name, null)
      }
    } else {
      yield put(pinLocally({
        idToBePinned: screenID,
      }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetRoomMembers(action) {
  try {
    const {
      roomId,
      isHost,
      callback,
      screenShareId,
      agentSharingScreen,
      bypassUIRefresh,
    } = action.data
    const res = yield call(RoomsAPI.getRoomMembersAPI, roomId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getRoomMembersAction.success(response))

      const {
        hosts,
        speakers,
        listeners,
        screen_recordings: screenRecorders,
        screen_shares: sharedScreens,
        host_pinned_users: remotePinnedUsers,
      } = response || {}

      const agentId = CookiesStorage.load("agentId")
      // const isCurrentUserHost = hosts.filter(item => item.id === parseInt(agentId, 10))
      const configureHost = RoomsActions.configureHostUIAction.call
      const configureSpeakers = RoomsActions.configureSpeakersUIAction.call
      const configureSharers = RoomsActions.configureScreenSharersUIAction.call
      /**TODO: CHECK USE CASE FOR THIS variable below */
      // const isCurrentlyScreenShare = CookiesStorage.load("isScreenSharing")
      const pinnedUsers = yield select(state => state.rooms.pinnedIds) || []
      const isScreenRecordingActive = yield select(state => state.rooms.isRecording)
      const agoraStore = yield select(state => state.rooms.agoraIDStore)

      //lets fix this
      if (callback) {
        callback(response)
      }

      if (!bypassUIRefresh) {
        yield put(configureHost({
          datum: hosts[0],
          speakers,
          sharedScreens,
          remotePinnedUsers,
        }))

        yield put(configureSpeakers({
          datum: speakers,
          hostId: hosts[0].id,
          listeners,
          sharedScreens,
          remotePinnedUsers,
        }))
      }

      //show session recording prompt for new users
      if (screenRecorders && screenRecorders.length > 0 && !isScreenRecordingActive) {
        yield put(RoomsActions.toggleRecordingSessionPromptAction.call(true))
      }

      if (screenRecorders && screenRecorders.length > 0) {
        const cameraManRid = screenRecorders[0].resource_id
        const serialId = screenRecorders[0].sid
        const cameraManUid = screenRecorders[0].unique_id
        const originalId = screenRecorders[0].id

        if (!agoraStore.sid) {
          yield put(RoomsActions.storeRIDSIDAction.call({
            rid: cameraManRid,
            sid: serialId,
            cameraManId: cameraManUid,
            originalId: `${originalId}`,
          }))
        }
      }

      //This occurs during a PubNubEvent::ScreenShared
      if (agentSharingScreen && screenShareId) {
        const currentSharing = sharedScreens && sharedScreens.filter(item => item.id === agentSharingScreen)
        const name = currentSharing && currentSharing[0].firstname
        const text = `${name}'s screen`
        addUsersToGridView(null, screenShareId, text, null, null)
        yield put(resizeScreen())
        //convertToGridView(toggleGridView, toggleOtherUsersView, toggleSpotlightView, screenShareId)
        // yield put(toggleSpotlightView(false))
        // yield put(toggleGridView(false))
        // yield put(toggleOtherUsersView(false))
        // yield put(pinLocally({
        //   idToBePinned: screenShareId,
        // }))
      }

      //TODO need to check if already pinned!
      //Probably do it in the reducer of pinning.

      //This is for Refresh or Joining Post a screenshare event
      if (sharedScreens && !isEmpty(sharedScreens) && !bypassUIRefresh) {
        /**MANDATORY DELAY */
        yield delay(2000)
        for (let index = 0; index < sharedScreens.length; index += 1) {
          const uniqueScreenShareID = sharedScreens[index].unique_id
          yield put(configureSharers({
            name: `${sharedScreens[index].firstname} ${sharedScreens[index].lastname}'s Screen`,
            screenID: uniqueScreenShareID,
            speakers,
          }))
          const isAlreadyPinned = pinnedUsers && !pinnedUsers.includes(uniqueScreenShareID)
          if (!isAlreadyPinned) {
            yield put(pinLocally({
              idToBePinned: uniqueScreenShareID,
            }))
          }
        }
        yield put(toggleSidebarView(true))
      }

      if (remotePinnedUsers && !isEmpty(remotePinnedUsers) && !bypassUIRefresh) {
        /**MANDATORY DELAY */
        yield delay(2000)
        for (let index = 0; index < remotePinnedUsers.length; index += 1) {
          // const isAlreadyPinned = pinnedUsers && !pinnedUsers.includes(remotePinnedUsers[index].id)
          yield put(pinLocally({
            idToBePinned: remotePinnedUsers[index].id,
          }))
        }
        yield put(toggleSidebarView(true))
      }
    }
  } catch (error) {
    yield put(RoomsActions.getRoomMembersAction.failure(error))
  }
}

function* handleUpdateRoomMembers(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.updateRoomAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.updateRoomAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.updateRoomAction.failure(error))
  }
}

function* handleMuteUser(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.muteUserAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.muteUserAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.muteUserAction.failure(error))
  }
}

function* handleKickUser(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.kickUserOutAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.kickUserOutAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.kickUserOutAction.failure(error))
  }
}

function* handleBecomeSpeaker(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.becomeSpeakerAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.becomeSpeakerAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.becomeSpeakerAction.failure(error))
  }
}

function* handleSpeakerRequest(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.speakerRequestAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.becomeSpeakerAction.success(response))
      yield put(RoomsActions.toggleVerifySpeakerRequestModalAction.call({
        bool: false,
        agentId: null,
      }))
    }
  } catch (error) {
    yield put(RoomsActions.toggleVerifySpeakerRequestModalAction.call({
      bool: false,
      agentId: null,
    }))
    yield put(RoomsActions.speakerRequestAction.failure(error))
  }
}

function* handleReportUser(action) {
  try {
    const { payload } = action.data
    const res = yield call(RoomsAPI.roomReportUserAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.roomReportUserAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.roomReportUserAction.failure(error))
  }
}

function* handleReportRoom(action) {
  try {
    const { payload } = action.data
    const res = yield call(RoomsAPI.roomReportReasonsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.roomReportReasonAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.roomReportReasonAction.failure(error))
  }
}

function* handleGetTags(action) {
  try {
    const { query } = action.data
    const q = `?tag=${query}`
    const res = yield call(RoomsAPI.getTagsAPI, q)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getTagsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getTagsAction.failure(error))
  }
}

const getName = (name) => {
  if (!name) return ""
  const splitName = name.split(" ")
  return splitName.join("-")
}

function* handleCreateScheduleRooms(action) {
  try {
    const { payload, history } = action.data
    const res = yield call(RoomsAPI.createScheduleRoomAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.createScheduleRoomAction.success(response))
      yield put(RoomsActions.toggleCreateRoomModalAction.call(false))
      yield put(RoomsActions.clearAndResetAllForms.call())
      /**SAFARI DOES NOT ALLOW POPUP */
      const url = `/scheduledrooms/${response.id}/${getName(response.name)}`
      history.push(url)
    }
  } catch (error) {
    yield put(RoomsActions.createScheduleRoomAction.failure(error))
  }
}

function* handleAttendeeStatus(action) {
  try {
    const { payload, roomId } = action.data
    const res = yield call(RoomsAPI.attendeeStatusAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.attendeeStatusAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.attendeeStatusAction.failure(error))
  }
}

function* handleHeartBeat(action) {
  try {
    const { payload = {}, roomId } = action.data
    const res = yield call(RoomsAPI.userHeartBeatAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.userHeartBeatAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.userHeartBeatAction.failure(error))
  }
}

function* handleHostTransferRequest(action) {
  try {
    const { payload } = action.data
    const res = yield call(RoomsAPI.hostTransferRequestAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.hostTransferRequestAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.hostTransferRequestAction.failure(error))
  }
}

function* handleHostTransferResponse(action) {
  try {
    const { payload } = action.data
    const res = yield call(RoomsAPI.hostTransferResponseAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.hostTransferResponseAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.hostTransferResponseAction.failure(error))
  }
}

function* handleRemotePinToggle(action) {
  try {
    const { payload = {}, roomId } = action.data
    const res = yield call(RoomsAPI.toggleRemotePinningAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.togglePinForAllAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.togglePinForAllAction.failure(error))
  }
}

function* handleJoinRoomAsScreenShare(action) {
  try {
    const { payload = {}, roomId, callback } = action.data
    const res = yield call(RoomsAPI.joinRoomAsUniqueIDApi, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.joinRoomAsScreenSharerAction.success(response))
      if (callback) {
        callback({
          token: response.token,
          uid: response.uid,
        })
      }
    }
  } catch (error) {
    yield put(RoomsActions.joinRoomAsScreenSharerAction.failure(error))
  }
}

function* handleLeaveRoomAsScreenShare(action) {
  try {
    const { payload = {}, roomId } = action.data
    const res = yield call(RoomsAPI.leaveRoomUsingUniqueIDApi, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.leaveRoomAsScreenSharerAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.leaveRoomAsScreenSharerAction.failure(error))
  }
}

function* handleNotifyForScheduledRooms(action) {
  try {
    const { id } = action.data
    const res = yield call(RoomsAPI.notifyForScheduledRoomsAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.notifyForScheduledRoomsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.notifyForScheduledRoomsAction.failure(error))
  }
}

function* handleBulkInvite(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(RoomsAPI.bulkInviteAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.bulkInviteBasedOnLocationAction.success(response))
      createToast(`Invites sent to agents from ${payload.filter.city || payload.filter.state}`, toastConfig)
    }
  } catch (error) {
    yield put(RoomsActions.bulkInviteBasedOnLocationAction.failure(error))
  }
}

function* handleScheduledRoomsUpdate(action) {
  try {
    const { roomId, payload, history } = action.data
    const res = yield call(RoomsAPI.updateScheduledRoomsAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.updateScheduledRoomsAction.success(response))
      yield put(RoomsActions.toggleCreateRoomModalAction.call(false))
      yield put(getScheduledRoomEventDetailsAction.request({
        roomId,
      }))
    }
  } catch (error) {
    yield put(RoomsActions.updateScheduledRoomsAction.failure(error))
  }
}

function* handleGetScheduleRooms() {
  try {
    const payload = "following_host=true&room_not_created=true&only_upcoming=true&skip=0&limit=40"
    const res = yield call(RoomsAPI.getScheduledRoomsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getScheduleRoomsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getScheduleRoomsAction.failure(error))
  }
}

function* handleGetRoomTopics() {
  try {
    const res = yield call(RoomsAPI.getRoomsTopics)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getRoomTopicsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getRoomTopicsAction.failure(error))
  }
}

function* handleGetRoomTopicsEvents() {
  try {
    const res = yield call(RoomsAPI.getRoomTopicEvents)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getRoomTopicEventsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getRoomTopicEventsAction.failure(error))
  }
}

function* handleGetPromotedRooms() {
  try {
    const res = yield call(RoomsAPI.getPromotedRooms)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getPromotedRoomsAction.success(response))
    }
  } catch (error) {
    yield put(RoomsActions.getPromotedRoomsAction.failure(error))
  }
}

function* handleFetchDefaultImages() {
  try {
    const res = yield call(RoomsAPI.getDefaultImagesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getDefaultImagesAction.success(response))
    }
  } catch (e) {
    yield put(RoomsActions.getDefaultImagesAction.failure(e))
  }
}

//Upload room image to s3
function* handleFileUploadingTos3(action) {
  try {
    const {
      file,
    } = action.data

    const signedRes = yield fetch(`${FILE_UPLOAD_API}bucket=&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(RoomsActions.uploadImageToS3Action.success(signedResJson.fileUrlAfterUpload))
      createToast.info(`Uploaded ${file.name}`, toastConfig)
      yield put(RoomsActions.setImageUrlAction.call(signedResJson.fileUrlAfterUpload))
    }
  } catch (e) {
    createToast.error("Error Uploading file", toastConfig)
    yield put(RoomsActions.uploadImageToS3Action.failure(e))
  }
}

function* handleCancelScheduledRooms(action) {
  try {
    const {
      roomId,
      history,
    } = action.data
    const res = yield call(RoomsAPI.cancelScheduledRoomsAPI, roomId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.cancelScheduledRoomsAction.success(response))
      history.push("/community")
    }
  } catch (e) {
    yield put(RoomsActions.cancelScheduledRoomsAction.failure(e))
  }
}

function* handleClearForms() {
  yield put(reset("ROOMS_DETAILS_FORM"))
  yield put(reset("ROOMS_DETAILS_FORM_II"))
  yield put(reset("SCHEDULE_ROOMS_DETAILS_FORM"))
}

function* handleGetPreRecorderDetails(action) {
  try {
    const {
      courseId,
    } = action.data
    const res = yield call(RoomsAPI.getPreRecordedRoomsContentAPI, courseId)
    if (isSuccess(res)) {
      const { response } = res.data
      const parsedObj = convertToRichText(response)
      yield put(RoomsActions.getPreRecordedRoomsContentAction.success(parsedObj))
    }
  } catch (e) {
    yield put(RoomsActions.getPreRecordedRoomsContentAction.failure(e))
  }
}

function* handleRoomRecordingAcquire(action) {
  try {
    const {
      payload,
      originalId,
    } = action.data
    const res = yield call(RoomsAPI.acquireRoomsRecordingCapabitiesAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.acquireRoomRecordingAction.success(response))
      yield put(RoomsActions.storeRIDSIDAction.call({
        rid: response.resourceId,
        sid: null,
        cameraManId: payload.uid,
        originalId: `${originalId}`,
      }))
    }
  } catch (e) {
    yield put(RoomsActions.acquireRoomRecordingAction.failure(e))
  }
}
function* handleScheduledRoomsDelete(action) {
  try {
    const {
      id,
      data,
      history,
    } = action.data
    const res = yield call(RoomsAPI.deleteScheduledRoomsAPI, id, data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.deleteScheduledRoomsAction.success(response))
      yield put(RoomsActions.toggleCreateRoomModalAction.call(false))
      yield put(RoomsActions.clearAndResetAllForms.call())
      yield put(RoomsActions.createRoomPageSwitchAction.call({
        page: "select-type",
        type: null,
      }))
      history.push("/rooms")
      createToast("Room has been successfully deleted", toastConfig)
    }
  } catch (e) {
    yield put(RoomsActions.deleteScheduledRoomsAction.failure(e))
  }
}

function* handleJoinRoomAsScreenRecord(action) {
  try {
    const {
      roomId,
      payload,
      callback,
    } = action.data
    const res = yield call(RoomsAPI.joinRoomAsScreenRecordAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.joinRoomAsScreenRecordAction.success(response))
      if (callback) {
        callback({
          token: response.token,
          uid: response.uid,
        })
      }

      //call the acquire token api
      const acquirePayload = {
        cname: `${roomId}`,
        uid: `${response.uid}`,
        clientRequest: {
          resourceExpiredHour: 24,
          scene: 0,
        },
      }
      yield put(RoomsActions.acquireRoomRecordingAction.request({
        payload: acquirePayload,
        originalId: payload.agent_id,
      }))
    }
  } catch (e) {
    yield put(RoomsActions.joinRoomAsScreenRecordAction.failure(e))
  }
}

function* handleLeaveRoomAsScreenRecord(action) {
  try {
    const {
      roomId,
      payload,
    } = action.data
    const res = yield call(RoomsAPI.leaveRoomAsScreenRecordAPI, roomId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.leaveRoomAsScreenRecordAction.success(response))
      yield put(RoomsActions.createUnSubscribeIDArrayAction.call({
        id: `${payload.unique_id}`,
      }))
      yield put(RoomsActions.storeRIDSIDAction.call({
        rid: null,
        sid: null,
        cameraManId: null,
        originalId: null,
      }))
      yield put(RoomsActions.toggleScreenRecordModalAction.call(false))
    }
  } catch (e) {
    yield put(RoomsActions.leaveRoomAsScreenRecordAction.failure(e))
  }
}

function* handleStartRoomsRecording(action) {
  try {
    const {
      resourceId,
      payload,
      agentId,
      isScreenShared,
      screenShareUniqueId,
    } = action.data
    const res = yield call(RoomsAPI.startRoomsRecordAPI, resourceId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.startRoomsRecordAction.success(response))
      yield put(RoomsActions.storeRIDSIDAction.call({
        rid: response.resourceId,
        sid: response.sid,
        cameraManId: payload.uid,
        originalId: `${agentId}`,
      }))
      yield put(RoomsActions.toggleScreenRecordModalAction.call(false))

      if (isScreenShared) {
        const updateConfig = {
          uid: payload.uid,
          cname: payload.cname,
          clientRequest: {
            mixedVideoLayout: 2,
            maxResolutionUid: `${screenShareUniqueId.uniqueId}`,
            backgroundImage: `${CLOUDFRONT}/RadiusBGRoomsBG.jpg`,
            backgroundConfig: [...payload
              .clientRequest
              .recordingConfig
              .transcodingConfig
              .backgroundConfig,
            ],
          },
        }

        yield put(RoomsActions.updateRecordingLayoutAction.request({
          payload: updateConfig,
          resourceId: response.resourceId,
          sid: response.sid,
        }))
      }
    }
  } catch (e) {
    yield put(RoomsActions.startRoomsRecordAction.failure(e))
  }
}

function* handleStopRoomsRecording(action) {
  try {
    const {
      resourceId,
      sid,
      payload,
      leaveRoomPayload,
    } = action.data
    const res = yield call(RoomsAPI.stopRoomsRecordAPI, resourceId, sid, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.stopRoomsRecordAction.success(response))
      yield put(RoomsActions.leaveRoomAsScreenRecordAction.request({
        roomId: payload.cname,
        payload: leaveRoomPayload,
      }))
    }
  } catch (e) {
    yield put(RoomsActions.stopRoomsRecordAction.failure(e))
  }
}

//updateRecordingLayoutAction
function* handleLayoutUpdate(action) {
  try {
    const {
      resourceId,
      sid,
      payload,
      callback,
    } = action.data
    const res = yield call(RoomsAPI.updateRecordingLayoutAPI, resourceId, sid, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.updateRecordingLayoutAction.success(response))

      if (callback) {
        callback()
      }
    }
  } catch (e) {
    yield put(RoomsActions.updateRecordingLayoutAction.failure(e))
  }
}

function* handleUpdateSubscriptonList(action) {
  try {
    const {
      resourceId,
      sid,
      payload,
      callback,
    } = action.data
    const res = yield call(RoomsAPI.updateSubscriptionAPI, resourceId, sid, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.updateSubscriptionListAction.success(response))

      if (callback) {
        callback()
      }
    }
  } catch (e) {
    yield put(RoomsActions.updateSubscriptionListAction.failure(e))
  }
}

function* handleRoomsMetaDataFetch() {
  try {
    const res = yield call(RoomsAPI.getRoomsMetaDataConfigAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getRoomsMetaDataObjectsAction.success(response))
    }
  } catch (e) {
    yield put(RoomsActions.getRoomsMetaDataObjectsAction.failure(e))
  }
}

function* handleGetRecommendedRooms() {
  try {
    const res = yield call(RoomsAPI.getPromotedRecommendedRoomsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RoomsActions.getPromotedRecommendedRoomsAction.success(response))
    }
  } catch (e) {
    yield put(RoomsActions.getPromotedRecommendedRoomsAction.failure(e))
  }
}

export default function* main() {
  yield takeLatest(RoomsActions.initLiveRoomAction.type, handleInitLiveRoom)
  yield takeLatest(RoomsActions.getRoomsActions.REQUEST, handleGetRooms)
  yield takeLatest(RoomsActions.getTokenAction.REQUEST, handleGetToken)
  yield takeLatest(RoomsActions.joinRoomsActions.REQUEST, handleJoinRoom)
  yield takeLatest(RoomsActions.createRoomsActions.REQUEST, handleCreateRoom)
  yield takeLatest(RoomsActions.leaveRoomAction.REQUEST, handleLeaveRoom)
  yield takeLatest(RoomsActions.getSuggestedUsersActions.REQUEST, handleGetSuggestedUsers)
  yield takeLatest(RoomsActions.sendUserInvitationAction.REQUEST, handleSendUserInvitation)
  yield takeEvery(RoomsActions.getRoomMembersAction.REQUEST, handleGetRoomMembers)
  yield takeLatest(RoomsActions.updateRoomAction.REQUEST, handleUpdateRoomMembers)
  yield takeLatest(RoomsActions.muteUserAction.REQUEST, handleMuteUser)
  yield takeLatest(RoomsActions.kickUserOutAction.REQUEST, handleKickUser)
  yield takeLatest(RoomsActions.becomeSpeakerAction.REQUEST, handleBecomeSpeaker)
  yield takeLatest(RoomsActions.speakerRequestAction.REQUEST, handleSpeakerRequest)
  yield takeLatest(RoomsActions.roomReportUserAction.REQUEST, handleReportUser)
  yield takeLatest(RoomsActions.roomReportReasonAction.REQUEST, handleReportRoom)
  yield takeLatest(RoomsActions.getTagsAction.REQUEST, handleGetTags)
  yield takeLatest(RoomsActions.createScheduleRoomAction.REQUEST, handleCreateScheduleRooms)
  yield takeLatest(RoomsActions.attendeeStatusAction.REQUEST, handleAttendeeStatus)
  yield takeLatest(RoomsActions.configureHostUIAction.type, handleConfigureHost)
  yield takeLatest(RoomsActions.configureSpeakersUIAction.type, handleConfigureSpeakers)
  yield takeLatest(RoomsActions.configureScreenSharersUIAction.type, handleConfigureScreenSharers)
  yield takeLatest(RoomsActions.userHeartBeatAction.REQUEST, handleHeartBeat)
  yield takeLatest(RoomsActions.hostTransferRequestAction.REQUEST, handleHostTransferRequest)
  yield takeLatest(RoomsActions.hostTransferResponseAction.REQUEST, handleHostTransferResponse)
  yield takeLatest(RoomsActions.togglePinForAllAction.REQUEST, handleRemotePinToggle)
  yield takeLatest(RoomsActions.joinRoomAsScreenSharerAction.REQUEST, handleJoinRoomAsScreenShare)
  yield takeLatest(RoomsActions.leaveRoomAsScreenSharerAction.REQUEST, handleLeaveRoomAsScreenShare)
  yield takeLatest(RoomsActions.notifyForScheduledRoomsAction.REQUEST, handleNotifyForScheduledRooms)
  yield takeLatest(RoomsActions.bulkInviteBasedOnLocationAction.REQUEST, handleBulkInvite)
  yield takeLatest(RoomsActions.updateScheduledRoomsAction.REQUEST, handleScheduledRoomsUpdate)
  yield takeLatest(RoomsActions.getScheduleRoomsAction.REQUEST, handleGetScheduleRooms)
  yield takeLatest(RoomsActions.getDefaultImagesAction.REQUEST, handleFetchDefaultImages)
  yield takeLatest(RoomsActions.uploadImageToS3Action.REQUEST, handleFileUploadingTos3)
  yield takeLatest(RoomsActions.clearAndResetAllForms.type, handleClearForms)
  yield takeLatest(RoomsActions.cancelScheduledRoomsAction.REQUEST, handleCancelScheduledRooms)
  yield takeLatest(RoomsActions.getRoomTopicsAction.REQUEST, handleGetRoomTopics)
  yield takeLatest(RoomsActions.getRoomTopicEventsAction.REQUEST, handleGetRoomTopicsEvents)
  yield takeLatest(RoomsActions.getPromotedRoomsAction.REQUEST, handleGetPromotedRooms)
  yield takeLatest(RoomsActions.getPreRecordedRoomsContentAction.REQUEST, handleGetPreRecorderDetails)
  yield takeLatest(RoomsActions.acquireRoomRecordingAction.REQUEST, handleRoomRecordingAcquire)
  yield takeLatest(RoomsActions.deleteScheduledRoomsAction.REQUEST, handleScheduledRoomsDelete)
  yield takeLatest(RoomsActions.joinRoomAsScreenRecordAction.REQUEST, handleJoinRoomAsScreenRecord)
  yield takeLatest(RoomsActions.leaveRoomAsScreenRecordAction.REQUEST, handleLeaveRoomAsScreenRecord)
  yield takeLatest(RoomsActions.startRoomsRecordAction.REQUEST, handleStartRoomsRecording)
  yield takeLatest(RoomsActions.stopRoomsRecordAction.REQUEST, handleStopRoomsRecording)
  yield takeLatest(RoomsActions.updateRecordingLayoutAction.REQUEST, handleLayoutUpdate)
  yield takeLatest(RoomsActions.updateSubscriptionListAction.REQUEST, handleUpdateSubscriptonList)
  yield takeLatest(RoomsActions.getRoomsMetaDataObjectsAction.REQUEST, handleRoomsMetaDataFetch)
  yield takeLatest(RoomsActions.getPromotedRecommendedRoomsAction.REQUEST, handleGetRecommendedRooms)
}
