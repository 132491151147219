import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "PROPERTY_STATUS"

export const getPropertyStatusListAction = createSignalAction(BASE, "GET_PROPERTY_STATUS_LIST")

export const updatePropertyStatusAction = createSignalAction(BASE, "UPDATE_PROPERTY_STATUS")

export const updatePropertyStatusFromProfileAction = createSignalAction(BASE, "UPDATE_PROPERTY_STATUS_FROM_PROFILE")

export const handleUpdateSelectAction = createSimpleCreator(BASE, "UPDATE_CURRENT_PROPERTY_STATUS")

export const handleUserConfirmationAction = createSimpleCreator(BASE, "HANDLE_USER_CONFIRMATION")

export const toggleModalAction = createSimpleCreator(BASE, "TOGGLE_MODAL")

export const deletePropertyAction = createSignalAction(BASE, "DELETE_PROPERTY")

export const closeModalAction = createSimpleCreator(BASE, "CLOSE_MODAL")
