import React, { useEffect } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter, Link } from "react-router-dom"
import icons from "@icons"
import query from "query-string"
import ThanksImage from "images/Graphics/RadiusRoomsAssets/ThanksPageImageGirl.jpg"
import { authCheck } from "services/auth"
import container from "container/EventRoom"
import Rooms from "container/Rooms/index"
import Button from "@ui/Button"
import colors from "@colors"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import roomsPhoneMob from "images/rooms/roomsPhone.png"
import {
  APP_STORE_URL,
  minDesktopWidth,
  minTabletWidth,
  PLAY_STORE_URL,
  preDesktopWidth,
  preTabletWidth,
} from "shared/consts"
import Footer from "../common/Footer"
import NewUserRegister from "./NewUserRegister"
import AddToCalender from "./AddToCalender"

const isLogged = authCheck()

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-bottom: 200px;

  ${preTabletWidth`
    height: max-content;
    padding-bottom: 0px;
  `}
`

const LeftWrap = styled.div`
  flex-grow: 1;
  height: 549px;
  max-width: 50%;
  padding-left: 50px;

  ${preTabletWidth`
    padding: 0px;
    max-width: 100%;
  `}

  @media(max-width: 500px) {
    height: fit-content;
  }
`

const RightWrap = styled.div`
  flex-grow: 1;
  height: 549px;
  max-width: 50%;
  display: flex;
  position: relative;
  animation: append-animate .3s cubic-bezier(.15,.79,.58,.92) forwards;

  ${preTabletWidth`
    display: none;
  `}
`

const ThankYouWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  height: fit-content;
  padding-left: 20px;

  ${preTabletWidth`
    padding: 50px 50px 15px 50px;
  `}
`

const ThankYou = styled.p`
  font-family: ${ATC};
  font-size: 70px;
  font-weight: 300;
  line-height: initial;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 50px;

  ${minDesktopWidth`
    font-size: 70px;
  `}

  ${minTabletWidth`
    font-size: 55px;
  `}

  ${preTabletWidth`
    font-size: 44px;
    text-align: center;
  `}

  
`

const SubText = styled.p`
  font-family: ${ATC};
  font-size: 27px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  ${preTabletWidth`
    text-align: center;
    font-size: 25px;
  `}
`

const Circle = styled.div`
  height: 336px;
  width: 336px;
  border-radius: 100%;
  z-index: ${props => props.zIndex};

  background-color: rgba(59, 96, 228, 0.8);
  
  ${props => props.img && `
    background-image: url(${props.img});
    background-position: center;
    background-size: contain;
  `}

  position: absolute;
  top: ${props => props.top || 0};
  right: ${props => props.right || 0};
`

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const AddtoCalenderWrap = styled.div`
  font-family: ${ATC};
  font-size: 20px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;


  ${preTabletWidth`
    display: flex;
    justify-content: center;
  `}
`

const RedirectToCommunity = styled.div`
  text-decoration: none;

  ${preTabletWidth`
    display: none;
  `}
`

const BackButton = styled(Button)`
  color: ${colors.marineBLue};
  background-color: white;
  border: none;
  align-items: baseline;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 150px;
  display: flex;
  padding: 0px;
  i {
    display: inline-block;
    position: relative;
    transform: rotate(180deg);
  }
  span {
    margin-left: 15px;
  }
`

const ParentWrapMob = styled.div`
  margin-top: 55px;
`

const HeaderWrapMob = styled.div`
  h1 {
    font-size: 44px;
    text-align: center;
    padding: 0px 70px;
    font-family: ${HIGHLIGHT_FONT};
    color: ${colors.black};

    @media(max-width: 320px) {
      padding: 0px 40px;
    }
  }

  p {
    height: 24px;
    font-size: 16px;
    text-align: center;
    padding: 0px 70px;
    font-family: ${ATC};
    font-weight: 200;
    color: ${colors.black};
  }
`

const SocialWrapper = styled.div`
  text-align: center;
  flex: 3 auto;
  position: relative;
  margin: 20px;
`

const PhoneImageWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 55px;
  
  @media(max-width: 320px) {
    padding-left: 40px;
  }

  img {
    @media(max-width: 320px) {
      width: 100%;
    }
  }
`

const ScheduleRoomRegisterUI = ({
  id,
  notifyForRoom,
  eventData,
  history,
  toggleConfirmSignInModal,
  isConfirmSignInModalOpen,
  agentId,
}) => {
  const isMobile = root.innerWidth <= 767

  useEffect(() => {
    if (root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove()
    }
    if (isLogged || agentId) {
      notifyForRoom({
        id,
      })
    }
  }, [])

  const { search } = root.location
  const parsedQuery = query.parse(search)
  const { redirectToRSVP } = parsedQuery

  const { id: roomId, name } = eventData

  const handleRedirection = () => {
    const roomName = name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ").join("-").substr(0, 50)
    root.location.href = `/scheduledrooms/${roomId}/${roomName}`
  }

  return (
    <Wrap>
      <LeftWrap>
        <RedirectToCommunity>
          <BackButton onClick={handleRedirection}>
            <i className={icons.arrowsArrowRight}></i>
            <span>Back</span>
          </BackButton>
        </RedirectToCommunity>
        {isLogged ? (
          <>
            <ThankYouWrap>
              <ThankYou>
                Thank You For Registering!
              </ThankYou>
              <SubText>
                We are looking forward to hosting you in our room.
              </SubText>
            </ThankYouWrap>
            <AddtoCalenderWrap>
              <AddToCalender event={eventData} />
            </AddtoCalenderWrap>
            {isMobile && (
              <ParentWrapMob>
                <HeaderWrapMob>
                  <h1>
                    Get the Radius App
                  </h1>
                  <p>
                    Join this Room
                  </p>
                </HeaderWrapMob>
                <SocialWrapper>
                  <a
                    href={APP_STORE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://d2fedz0by71ckz.cloudfront.net/images/ios_app_store_btn.png"
                      alt="IOS app"
                      height={50}
                      width={150}
                    />
                  </a>
                  <a
                    href={PLAY_STORE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://d2fedz0by71ckz.cloudfront.net/images/google_play_store_btn.png"
                      alt="Android app"
                      height={50}
                      width={150}
                    />
                  </a>
                </SocialWrapper>
                <PhoneImageWrap>
                  <img
                    src={roomsPhoneMob}
                    alt="Rooms Phone"
                  />
                </PhoneImageWrap>
                <Footer />
              </ParentWrapMob>
            )}
          </>
        ) : (
          <LoginWrap>
            <ThankYou>
              Join the Conversation.
            </ThankYou>
            <SubText>
              Register or log in to attend our event.
            </SubText>
            <NewUserRegister
              toggleConfirmSignInModal={toggleConfirmSignInModal}
              isConfirmSignInModalOpen={isConfirmSignInModalOpen}
            />
          </LoginWrap>
        )}
      </LeftWrap>
      <RightWrap>
        <Circle img={ThanksImage} right="132px" zIndex="10" />
        <Circle zIndex="9" />
      </RightWrap>
    </Wrap>
  )
}

export default Rooms(container(withRouter(ScheduleRoomRegisterUI)))
