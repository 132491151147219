import React, { useState, useEffect } from "react";
import { Title, Description, Image, CustomModal } from "./styles.js";
import Listings from "container/Listings";

const LoadingDocumentsModal = ({ isOpen, createEnvelopeChecklistResponse, setOpenLoader }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const sampleData = [
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.2854 17.2791V23.4134C17.2854 23.7356 17.0252 23.9958 16.703 23.9958H0.58049C0.258288 23.9958 -0.00195312 23.7356 -0.00195312 23.4134V7.30327C-0.00195312 6.98109 0.258288 6.72083 0.58049 6.72083H6.70234V16.6967C6.70234 17.0189 6.96257 17.2791 7.28478 17.2791H17.2854Z"
            fill="#5A5FF2"
          />
          <path
            d="M24.0022 8.62928C24.0022 13.7845 20.9908 17.2668 17.2855 17.2792V10.7856C17.2855 10.5997 17.2112 10.4138 17.0749 10.2899L13.7041 6.91914C13.5678 6.7828 13.3943 6.70845 13.2085 6.70845H6.71484V0.58662C6.71484 0.264407 6.97507 0.00418348 7.29729 0.00418348H16.3809C20.6935 -0.00820933 24.0022 3.46164 24.0022 8.62928Z"
            fill="#A5A8FF"
          />
          <path
            d="M17.0749 10.2774C17.2112 10.4137 17.2855 10.5872 17.2855 10.7731V17.2667H7.29729C6.97507 17.2667 6.71484 17.0064 6.71484 16.6842V6.70837H13.2085C13.3943 6.70837 13.5802 6.78273 13.7041 6.91907L17.0749 10.2774Z"
            fill="#1F1F32"
          />
        </svg>
      ),
      description: "Docusign synced",
      subDescription: "Securely Signed, Seamlessly Synced",
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 19C20.2091 19 22 17.2091 22 15V15C22 12.7909 20.2091 11 18 11V11C18 7.68629 15.3137 5 12 5V5C9.43218 5.00126 7.15001 6.63692 6.3238 9.06818C3.85166 9.39889 2.00446 11.5058 2 14H2C2 16.7614 4.23858 19 7 19"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 19H8"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 19H16"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 18L14 16"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 16L12 18"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13V18"
            stroke="#5A5FF2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      description: "Pulling document",
      subDescription: "Securely Signed, Seamlessly Synced",
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 5L15.586 2.586C15.211 2.2109 14.7024 2.00011 14.172 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20"
            stroke="#5A5FF2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.378 12.626C21.7763 12.2277 22.0001 11.6874 22.0001 11.124C22.0001 10.5607 21.7763 10.0204 21.378 9.62203C20.9796 9.22367 20.4393 8.99988 19.876 8.99988C19.3126 8.99988 18.7723 9.22367 18.374 9.62203L14.364 13.634C14.1262 13.8716 13.9522 14.1654 13.858 14.488L13.021 17.358C12.9959 17.4441 12.9944 17.5353 13.0166 17.6221C13.0389 17.7089 13.084 17.7882 13.1474 17.8516C13.2108 17.915 13.2901 17.9601 13.3769 17.9824C13.4637 18.0046 13.5549 18.0031 13.641 17.978L16.511 17.141C16.8336 17.0468 17.1274 16.8728 17.365 16.635L21.378 12.626Z"
            stroke="#5A5FF2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 18H9"
            stroke="#5A5FF2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      description: "Filling document",
      subDescription: "Securely Signed, Seamlessly Synced",
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 6L9 17L4 12"
            stroke="#5A5FF2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      description: "Finalizing documents",
      subDescription: "Securely Signed, Seamlessly Synced",
    }
  ];

  useEffect(() => {
    if (!isOpen) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setCurrentIndex(0);
      return;
    }

    const id = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex >= sampleData.length - 1) {
          clearInterval(id); // Stop the interval before the last item
        }
        return nextIndex;
      });
    }, 2000);

    setIntervalId(id);

    return () => clearInterval(id);
  }, [isOpen]);


  const onClose = () => {
    if (currentIndex === sampleData.length - 1) {
        setOpenLoader(false);
      setCurrentIndex(0)
      setIntervalId(null)
    }
  };

  return (
    <CustomModal
      title=""
      closable={false}
      maskStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.80)",
        opacity: 0.9,
      }}
      centered={true}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width={514}
    >
      <div>
        <Image>{sampleData[currentIndex]?.image}</Image>
        <Title>{sampleData[currentIndex]?.description}</Title>
        <Description>{sampleData[currentIndex]?.subDescription}</Description>
      </div>
    </CustomModal>
  );
};

export default Listings(LoadingDocumentsModal);
