import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import { Input } from "@ui/antd"
import {
  Header,
  Content,
  ContentContainer,
} from "./styles"

const { TextArea } = Input

const Wrap = styled.div`
  padding: 20px;
`

const Label = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  color: ${colors.black};
`

const ButtonWrap = styled.div`
  display: flex;
  //padding: 0 20px;
`

const StatusChange = ({
  toggleStatusChangeModal,
  statusChangedTo,
  updateBrokerageListingStatus,
  updateBrokerageListingStatusResponse,
  id,
}) => {
  const [notes, setNotes] = useState("")

  const {
    isFetching: updatingStatus
  } = updateBrokerageListingStatusResponse || {}

  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Are you sure?
          </h1>
        </Header>
        <ContentContainer
          style={{
            padding: "0px 0px 20px 0px",
          }}
        >
          <Content>
            Please provide some notes on the status change for your own records.
          </Content>
          <Label>
            Notes:
          </Label>
          <TextArea
            style={{
              fontWeight: 300,
            }}
            rows={4}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            disabled={updatingStatus}
            isShowLoader={updatingStatus}
            onClick={() => {
              const payload = {
                listing_id: id,
                step: statusChangedTo,
                notes,
              }

              updateBrokerageListingStatus({
                payload,
              })
            }}
          >
            Change Status
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            disabled={updatingStatus}
            isShowLoader={updatingStatus}
            loaderOption={{
              color: "blue",
            }}
            onClick={() => {
              toggleStatusChangeModal({
                bool: false,
                statusChangedTo: "",
              })
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(Listings(StatusChange))
