import React, {useState} from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/OffersV2"
import { ShimmerBox } from "shared/styles/animation"
import { Drawer } from "@ui/antd"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import {
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import moment from "moment"
import { GoogleOutlined } from '@ant-design/icons';
import {
  Wrap,
  HeaderWrap,
  StyledStatus,
  StatusTitle,
  DocWrap,
  DocTitle,
  FooterWrap,
  DateCreatedContent,
  DocContent,
  DateContent,
  DateContentWrap,
  Image,
} from "./styles"
import {
  ButtonWrap,
  ProcessingWrap,
  ProcessingTitle,
} from "../RecommendedDocs/styles"
import DynamicMenu from "../DynamicMenu"
import SendOffer from "./SendOffer/index"

const Envelopes = ({
  dropdownList,
  mainList,
  docs,
  status,
  id,
  fillAndSign,
  date,
  creatingReceiverView,
  fetchOfferPackageDocumentsResponse,
  fetchOfferPackageDocuments,
  dealToView,
  refreshOfferDocumentsResponse,
  title,
  key,
  isDocumentProcessing,
}) => {
  const {
    isFetching: fetchingOfferPackageDetails,
  } = fetchOfferPackageDocumentsResponse || {}

  const { isFetching: fetchingEnv } = refreshOfferDocumentsResponse || {}
  const [currentId, setId] = useState("")

  const [viewOfferSendUI, setViewOfferSendUI] = useState(false)
  const handleSendOffer = (id) => {
    const payload = {
      envelopeIds: [id],
    };
    fetchOfferPackageDocuments({
      id: dealToView,
      payload,
      callback: () => {
        setViewOfferSendUI(true)
      },
    })
  }


  return (
    <>
      { viewOfferSendUI
        ? (
          <Drawer
            width="600px"
            title={(
              <div style={{ display: "flex", alignItems: "center" }}>
                <GoogleOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
                <span style={{ marginRight: "10px" }}>POWERED BY GMAIL</span>
              </div>
            )}
            placement="right"
            onClose={() => setViewOfferSendUI(false)}
            open={viewOfferSendUI}
            type="Basic Drawer"
            CloseIconRight
            hideDrawerTitle={false}
            // fontSize={titleFontSize}
            closeIcon={<CloseIcon />}
            maskClosable={false}
          >
            <SendOffer goBack={() => setViewOfferSendUI(false)} />
          </Drawer>
        ) : (
          <Wrap
            key={key}
          >
            <>
              {id === currentId && (
                <ProcessingWrap isDocumentProcessing={isDocumentProcessing}>
                  <ProcessingTitle>
                    Processing
                    <ThreeDotLoader />
                  </ProcessingTitle>
                </ProcessingWrap>
              )}
            </>
            <HeaderWrap>
              <DateContentWrap>
                <DateCreatedContent>
                  Created at
                </DateCreatedContent>
                <DateContent>
                  {moment(date).format("MM/DD/YY")}
                </DateContent>
              </DateContentWrap>
              {fetchingEnv ? (
                <ShimmerBox h="20px" w="100px" />
              ) : (
                <StyledStatus
                  status={status.value}
                >
                  <StatusTitle
                    status={status.value}
                  >
                    {status.label}
                  </StatusTitle>
                </StyledStatus>
              )}
            </HeaderWrap>
            <DocContent>
              <DocTitle
                fw={500}
              >
                {title}
              </DocTitle>
            </DocContent>
            <DocContent>
              {docs && docs.map(doc => (
                <DocWrap>
                  <DocTitle
                    style={{
                      wordWrap: "break-word", 
                      whiteSpace: "normal",
                    }}
                  >
                    {doc.name}
                  </DocTitle>
                </DocWrap>
              ))}
            </DocContent>
            <FooterWrap
              justifyEnd={status.value === "buyer_pending"}
            >
              {status.value === "action_required" && fillAndSign && typeof fillAndSign === "function" && (
                <ButtonWrap>
                  <BlueButton
                    onClick={() => {
                      setId(id)
                      fillAndSign(id)
                    }}
                  >
                    {creatingReceiverView ? (
                      <ThreeDotLoader color="#fff" />
                    ) : (
                      <p>Fill &amp; Sign</p>
                    )}
                  </BlueButton>
                </ButtonWrap>
              )}
              {status.value === "completed" && (
                <ButtonWrap>
                  <BlueButton maxWidth onClick={() => handleSendOffer(id)}>
                    {fetchingOfferPackageDetails ? (
                      <ThreeDotLoader color="#fff" />
                    ) : (
                      <p>Send Offer</p>
                    )}
                  </BlueButton>
                </ButtonWrap>
              )}
              <DynamicMenu
                dropdownList={dropdownList}
                mainList={mainList}
                id={id}
              />
            </FooterWrap>
          </Wrap>
        )
      }
    </>
  )
}

export default OffersV2(Envelopes)
