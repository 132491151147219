import React from "react"
import styled from "styled-components"
import TopBorderCard from "../TopBorderCard"
import { HeroText } from "../commonStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  min-height: 607px;
  position: relative;
  z-index: 2;
  padding-top: 246px;

  @media(max-width: 1099px) {
    padding: 30px;
    min-height: unset;
    margin: 100px auto;
  }
`

const Card = styled.div`
  background-color: ${props => props.bgColor || "#fff"};
  padding: 25px 25px;
  border-radius: 8px;
  height: 100%;
`

const MiniText = styled.h2`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24.528px;
  margin: 0;
`

const MainText = styled.h1`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 78.491px;
  font-variant: all-small-caps;
  margin: 0;
`

const TextWrap = styled.div``

const CardWrap = styled.div`
  display: flex;
  // align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 60px;
  gap: 41px;

  @media(max-width: 1099px) {
    padding: 0px;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
`

const SubText = styled.p`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.428px;
`

const DISPLAY_OBJECT = [{
  miniText: "Save",
  mainText: "6-8 Hours",
  subText: "per transaction with custom technology",
  borderColor: "#B6B8FF",
}, {
  miniText: "Enjoy",
  mainText: "Same Day",
  subText: "transaction payments",
  borderColor: "#F0A468",
}, {
  miniText: "Mobile",
  mainText: "3 minutes",
  subText: "or less offer drafting",
  borderColor: "#5A5FF2",
}]

const HeroSection = ({
  width,
}) => (
  <Wrap className="container">
    <TextWrap>
      <HeroText
        spanStyles
      >
        Drive Growth with
        {" "}
        <span>
          Mobile Efficiency
        </span>
      </HeroText>
    </TextWrap>
    <CardWrap>
      {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
        <TopBorderCard
          bColor={item.borderColor}
        >
          <Card>
            <MiniText>{item.miniText}</MiniText>
            <MainText>{item.mainText}</MainText>
            <SubText>{item.subText}</SubText>
          </Card>

        </TopBorderCard>
      ))}
    </CardWrap>
  </Wrap>
)

export default HeroSection
