import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import * as ReferralActions from "container/Referral/actions"
import {
  getIsUserATeamOwner,
  getIsUserATeamMember,
  getIsUserATeamAdmin,
  getIsUserABroker,
} from "container/App/selector"
import { savePreferenceAction, fetchUserPreferranceAction } from "container/Marketplace/actions"
import * as ClientProfileActions from "container/ClientProfile/actions"
import * as ClientActions from "./actions"

function mapStateToProps(state) {
  const {
    client,
    referral: {
      isStarringReferral,
      fetchReferrals,
      fetchSignedAgreement,
      prospects,
      isReferringLead,
      filterSkeleton,
      lastAcceptedReferral,
      singleProspect,
      assignAgentKeys,
      assigningAgent,
      teamMembers,
      confirmationModalMessage,
      isReferOutModalOpen,
      referOut,
    },
    clientProfile: {
      isOpenAdditionalDetailsModal, contactInfo, deleteContact, isOpenDeleteConfirmation,
    },
  } = state
  const { clientTypes } = filterSkeleton
  const formSelector = formValueSelector("POST_CALL_FEEDBACK_FORM")
  const postCallStatus = formSelector(state, "call_status")
  const postCallReason = formSelector(state, "reason")
  const isUserATeamOwner = getIsUserATeamOwner(state)
  const isUserATeamMember = getIsUserATeamMember(state)
  const isUserATeamAdmin = getIsUserATeamAdmin(state)
  const isUserABroker = getIsUserABroker(state)
  return {
    ...client,
    isUserATeamOwner,
    isStarringReferral,
    fetchReferrals,
    fetchSignedAgreement,
    prospects,
    isReferringLead,
    clientTypes,
    lastAcceptedReferral,
    isOpenAdditionalDetailsModal,
    contactInfo,
    isOpenDeleteConfirmation,
    deleteContact,
    singleProspect,
    postCallStatus,
    assignAgentKeys,
    assigningAgent,
    teamMembers,
    confirmationModalMessage,
    isReferOutModalOpen,
    isUserATeamMember,
    isUserATeamAdmin,
    referOut,
    postCallReason,
    isUserABroker,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      // Client Actions
      fetchClientProfile: ClientActions.fetchClientProfileAction.request,
      uploadClientImage: ClientActions.uploadClientImageAction.request,
      fetchClientUpdates: ClientActions.fetchClientUpdatesAction.request,
      editClientInfo: ClientActions.editClientInfoAction.request,
      updateClientProfile: ClientActions.updateClientProfileAction.request,
      fetchUserPreferrance: fetchUserPreferranceAction.request,
      fetchAndUpdateClientProfile: ClientActions.fetchAndUpdateClientProfileAction.request,
      fetchAndUpdateSalesInClient: ClientActions.fetchAndUpdateSalesInClientAction.request,
      setNotificationPreference: ClientActions.setNotificationPreferenceAction.request,
      fetchClientTimeline: ClientActions.fetchClientTimelineAction.request,
      updateArchivedLead: ClientActions.updateArchivedLeadAction.request,
      convertToCRMClient: ClientActions.convertToCRMClientAction.request,
      fetchCallStatus: ClientActions.fetchCallStatusAction.request,
      openEditProfile: ClientActions.openEditProfileAction,
      closeEditProfile: ClientActions.closeEditProfileAction,
      openISAAddClientModal: ClientActions.openISAAddClientModalAction,
      closeISAAddClientModal: ClientActions.closeISAAddClientModalAction,
      toggleCallFeedbackModal: ClientActions.toggleCallFeedbackModalAction.call,
      submitCallFeedback: ClientActions.submitCallFeedbackAction.request,
      userConfirmationModalAction: ClientActions.userConfirmationModalAction.call,
      userConfirm: ClientActions.userConfirmAction.call,
      addISAClient: ClientActions.addISAClientAction.request,

      // ClientProfile Actions
      deleteAdditionalContact: ClientProfileActions.deleteContactAction.request,
      openAdditionalDetailsModal: ClientProfileActions.openAdditionalDetailsModalAction.call,
      openDeleteConfirmation: ClientProfileActions.openDeleteConfirmationAction.call,
      fetchClientProfileTimeline: ClientProfileActions.fetchClientTimelineAction.request,
      markAsQualifiedLead: ClientProfileActions.markAsQualifiedLeadAction.request,

      // Referral Actions
      updateProspect: ReferralActions.updateProspectAction.request,
      fetchProspects: ReferralActions.fetchProspectsAction.request,
      updateProspectModal: ReferralActions.updateProspectModalAction,
      fetchFilterSkeleton: ReferralActions.fetchFilterSkeletonAction.request,
      fetchLead: ReferralActions.fetchLeadAction.request,
      fetchSingleProspect: ReferralActions.fetchSingleProspectAction.request,
      toggleAssignModal: ReferralActions.toggleAssignModalAction.call,
      getTeamMembers: ReferralActions.getTeamMembersAction.request,
      assignAgent: ReferralActions.assignAgentAction.request,
      referOutConfirm: ReferralActions.referConfirmedAction.call,
      toggleReferOut: ReferralActions.toggleReferOutAction.call,
      toggleSendToMarketPlaceModal: ReferralActions.toggleSendToMarketPlaceModalAction.call,

      // marketplace
      savePreference: savePreferenceAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
