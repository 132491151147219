import * as React from "react";
const HeartIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0833 3.33325C15.725 3.33325 17.5 5.81659 17.5 8.13325C17.5 12.8249 10.1333 16.6666 10 16.6666C9.86667 16.6666 2.5 12.8249 2.5 8.13325C2.5 5.81659 4.275 3.33325 6.91667 3.33325C8.43333 3.33325 9.425 4.09159 10 4.75825C10.575 4.09159 11.5667 3.33325 13.0833 3.33325Z"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default HeartIcon;
