import { useLottie, useLottieInteractivity } from "lottie-react"
import { useWindowSize } from "services/Utils"

const LottieSectionOne = ({
  animationData,
  width,
  height,
  endFrames,
  scrollFrames,
  mobileSpecificHeight,
  expand,
  reduce,
  modified,
  customStyle,
}) => {
  const { width: screenWidth } = useWindowSize()

  const options = {
    animationData,
  }

  const getDimension = () => {
    const sWidth = screenWidth < 1099 ? mobileSpecificHeight || "100%" : "681px"
    const sHeight = screenWidth < 1099 ? "100%" : "643px"

    return {
      width: sWidth,
      height: sHeight,
    }
  }

  const style = {
    width: getDimension().width,
    height: getDimension().height,
    ...customStyle,
  }

  const modifiedStyle = {
    height: getDimension().height,
    position: "relative",
    left: "-33px",
    minWidth: "600px",
  }

  if (expand) {
    modifiedStyle.transform = "scale(1.1)"
  }

  if (reduce) {
    modifiedStyle.height = "540px"
  }

  const lottieObj = useLottie(options, modified ? modifiedStyle : style)

  const actionData = [{
    visibility: [0, 0.2],
    type: "stop",
    frames: [0],
  },
  {
    visibility: [0.2, 0.45],
    type: "seek",
    frames: scrollFrames || [0, 200],
  },
  {
    visibility: [0.45, 1],
    type: "loop",
    frames: endFrames || [200, 300],
  },
  ]

  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: actionData,
  })

  return Animation
}

export default LottieSectionOne
