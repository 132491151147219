import React, { useState, useRef } from "react"
import CRMV2Container from "container/CRM"
import { getFormattedLocationObject } from "dumbComponents/CRMV2/CRMDashboard/Components/utils"
import { MoneyFormatter } from "services/formUtils"
import {
  Form,
  Space,
  NewGoogleAutoComplete,
  NewInputNumber,
  NewRadioButton,
  NewRadioGroup,
  NewButton,
} from "@ui/antd"
import styled from "styled-components"

import { LeadSourceWrap, StyledLabel } from "../../../common"
import { SectionHeading } from "../../styles"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form

const UpdateSearchCriteriaForm = ({
  initialValues,
  client_id,
  updateBuyerRequirement,
  toggleUserOverview,
  currentFilters,
  isBuyer,
}) => {
  const {
    min_budget, max_budget, min_size, max_size, area_unit, req_id,
    types, bathroom, bedroom, updated_at, sale_type, locations, sale_price,
  } = initialValues || {}
  const isFullAddress = locations && locations[locations.length - 1].c_locality_name !== null && locations[0].c_locality_name?.includes("USA")
  const currentLocation = isFullAddress ? locations && locations[0].c_locality_name
    : locations && locations[0].city !== null && locations[0].city.concat(", ", locations[0].state) || []
  const formRef = useRef(null)
  const [isDisabled, setDisabled] = useState(true)

  const setLocationInForm = (newValues) => {
    const currentFieldValues = formRef?.current?.getFieldsValue()

    if (!currentFieldValues) {
      return
    }

    const locationPayload = newValues.map(location => !location?.c_locality_id ? getFormattedLocationObject(location) : location)

    currentFieldValues.location = [
      ...locationPayload,
    ]

    formRef.current.setFieldsValue({ ...currentFieldValues })
    setDisabled(false)
  }

  const removeLocationFromForm = (newValues) => {
    const currentFieldValues = formRef?.current?.getFieldsValue()

    if (!currentFieldValues) {
      return
    }

    const locationPayload = newValues.map((location) => {
      const newLocation = { ...location }

      delete newLocation.readableLocation

      return location
    })

    currentFieldValues.location = [
      ...locationPayload,
    ]

    formRef.current.setFieldsValue({ ...currentFieldValues })
    setDisabled(false)
  }

  const onValueChange = () => {
    setDisabled(false)
  }

  const onFinish = (fieldValues) => {
    const {
      min_budget: updated_min_budget, max_budget: update_max_budget,
      min_size: updated_min_size, max_size: updated_max_size, area_unit: updated_area_unit,
      types: updated_type, bathroom: updated_bathroom, bedroom: updated_bedroom, sale_type: updated_sale_type,
      sale_price: updated_sale_price, location: updated_location,
    } = fieldValues || {}
    const payload = {
      min_budget: typeof updated_min_budget === "number" ? updated_min_budget : MoneyFormatter(updated_min_budget),
      max_budget: typeof update_max_budget === "number" ? update_max_budget : MoneyFormatter(updated_min_budget),
      min_size: updated_min_size,
      bathroom: updated_bathroom,
      bedroom: updated_bedroom,
      max_size: updated_max_size,
      area_unit: updated_area_unit,
      sale_type: updated_sale_type,
      types: updated_type,
      locations: updated_location,
      u_cust_id: client_id,
      sale_price: updated_sale_price,
    }
    updateBuyerRequirement({
      id: req_id, payload, clientId: client_id, currentFilters,
    })
    setDisabled(true)
  }
  return (
    <>
      <SectionHeading>Search Criteria</SectionHeading>

      <Form
        name="update_lead"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        ref={formRef}
      >
        <LeadSourceWrap width="100%" style={{ marginBottom: 0 }}>
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "8px" }}>
              <StyledLabel>Transaction Type</StyledLabel>
              <Item
                name="sale_type"
                style={{ marginBottom: 0 }}
                initialValue={sale_type}
                onChange={onValueChange}
              >
                <NewRadioGroup size="small">
                  <NewRadioButton value="resale">{isBuyer ? "Buy" : "Sale"}</NewRadioButton>
                  <NewRadioButton value="rent">Rent</NewRadioButton>
                </NewRadioGroup>
              </Item>
            </div>

            <Space align="baseline" style={{ width: "100%" }}>
              <Item
                name="bedroom"
                rules={[{ type: "number" }]}
                initialValue={bedroom}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Beds" />}
              >
                <NewInputNumber
                  formatter={value =>
                    value ? `${value} +`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                  }
                  min={1}
                  onChange={onValueChange}
                  placeholder="Beds"
                />
              </Item>
              <Item
                name="bathroom"
                rules={[{ type: "number" }]}
                initialValue={bathroom}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Baths" />}
              >
                <NewInputNumber
                  formatter={value =>
                    value ? `${value} +`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                  }
                  min={1}
                  onChange={onValueChange}
                  placeholder="Baths"
                />
              </Item>
            </Space>

            {sale_price && sale_price > 0 ? (
              <Space align="baseline" style={{ width: "100%" }}>
                <Item
                  name="sale_price"
                  rules={[{ message: "Only non-zero numbers allowed", type: "number" }]}
                  initialValue={sale_price}
                  onChange={onValueChange}
                  style={{ marginBottom: 0 }}
                  label={<LabelComponent text="Sale Price" />}
                >
                  <NewInputNumber
                    formatter={value =>
                      value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                    }
                    placeholder="Sale Price"
                  />
                </Item>
              </Space>
            ) : (
              <Space align="baseline" style={{ width: "100%" }}>
                <Item
                  name="min_budget"
                  rules={[{ message: "Required!", type: "number", required: true }]}
                  initialValue={min_budget}
                  onChange={onValueChange}
                    style={{ marginBottom: 0 }}
                    label={<LabelComponent text="Min Budget*" />}
                  >
                  <NewInputNumber
                    formatter={value =>
                      value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                    }
                    onChange={onValueChange}
                    placeholder="Min Budget*"
                  />
                </Item>
                <Item
                  name="max_budget"
                  rules={[{ message: "Required!", type: "number", required: true }]}
                  initialValue={max_budget}
                  onChange={onValueChange}
                    style={{ marginBottom: 0 }}
                    label={<LabelComponent text="Max Budget*" />}
                  >
                  <NewInputNumber
                    formatter={value =>
                      value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                    }
                    onChange={onValueChange}
                    placeholder="Max Budget*"
                  />
                </Item>
              </Space>
            )}

            <Space align="baseline" style={{ width: "100%" }}>
              <Item
                name="min_size"
                rules={[{ message: "Only numbers allowed", type: "number" }]}
                initialValue={min_size}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Min Size" />}
              >
                <NewInputNumber
                  formatter={value =>
                    value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                  }
                  onChange={onValueChange}
                  placeholder="Min Size"
                />
              </Item>
              <Item
                name="max_size"
                rules={[{ message: "Only numbers allowed", type: "number" }]}
                initialValue={max_size}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Max Size" />}
              >
                <NewInputNumber
                  formatter={value =>
                    value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                  }
                  onChange={onValueChange}
                  placeholder="Max Size"
                />
              </Item>
            </Space>
            <Item
              name="area_unit"
              initialValue={area_unit}
              onChange={onValueChange}
              style={{ marginBottom: 0 }}
            >
              <NewRadioGroup size="small">
                <NewRadioButton value="Sq.ft">Sq. ft</NewRadioButton>
                <NewRadioButton value="Sq.mt">Sq.mt</NewRadioButton>
                <NewRadioButton value="Acres">Acres</NewRadioButton>
              </NewRadioGroup>
            </Item>
            {/* TODO - maybe needed in future - abhinav */}
            {/* <Space key={key} style={{ display: "flex", marginBottom: 8, marginTop: 20 }} align="baseline">
              <Item name={[name, "property_type"]} label={<LabelComponent text="Property Type" />} rules={[{ required: true }]}>
                <Select placeholder="Select...">
                  {types.map(type => (
                    <Option value={type.value}>
                      {type.value}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Space> */}
            <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
              <Item
                name="location"
                rules={[
                  {
                    type: "array",
                    required: true,
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <NewGoogleAutoComplete
                  id="cities"
                  type={["(regions)"]}
                  name="cities"
                  initialValue={locations}
                  onLocationSelect={(newValues) => {
                    setLocationInForm(newValues)
                  }}
                  onLocationDeselect={(newValues) => {
                    removeLocationFromForm(newValues)
                  }}
                  placeholder="Search Neighborhood*"
                  restrict={{
                    lat: 37.09024,
                    lng: -95.712891,
                    south_west: {
                      lat: 25.82,
                      lng: -124.39,
                    },
                    north_east: {
                      lat: 49.38,
                      lng: -66.94,
                    },
                    address: "United States",
                    country: {
                      long_name: "United States",
                      short_name: "US",
                      types: ["country", "political"],
                    },
                  }}
                />
              </Item>
            </Space>

            <Item style={{ marginBottom: 0 }}>
              <NewButton type="primary" htmlType="submit" disabled={isDisabled}>
                Save Changes
              </NewButton>
            </Item>
          </Space>
        </LeadSourceWrap>
      </Form>
    </>
  )
}

export default CRMV2Container(UpdateSearchCriteriaForm)
