export const DISPLAY_OBJECT = [{
  personImage: `${CLOUDFRONT}/BrandonThumbail.png`,
  personName: "Brandon Thompson",
  personTestimonial: `Radius has provided tailored support for 
  MY business: branding/marketing assets, training, mortgage solutions, 
  stock ownership in the company, and a knowledgeable, team-centric community that are always there to help me grow.`,
  personTeamLogo: `${CLOUDFRONT}/BrandonTeam.png`,
  logoWidth: "171.429px",
  logoHeight: "20px",
}, {
  personImage: `${CLOUDFRONT}/JamesThumbnail.png`,
  personName: "James Quintero",
  personTestimonial: `Finding a brokerage to fill in the gaps is what brought 
me to Radius. Radius is constantly figuring out how to 
make agents' lives easier.`,
  personTeamLogo: `${CLOUDFRONT}/RiseTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JoeThumbnail.png`,
  personName: "Joe Stevenson",
  personTestimonial: `It seems like when I have a problem as an agent, Radius 
has a solution. These guys understand people.`,
  personTeamLogo: `${CLOUDFRONT}/IndieAgentTeamLogo.png`,
  logoWidth: "94px",
  logoHeight: "21px",
}, {
  personImage: `${CLOUDFRONT}/DeLeviThumbnail.png`,
  personName: "DeLevi Group",
  personTestimonial: `I came to Radius to create my own brand. Radius is 
about enabling the agent. They’re out there to provide every piece of support for their agent to succeed.`,
  personTeamLogo: `${CLOUDFRONT}/DeLeviTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JedCarloThumbnail.png`,
  personName: "Jed and Carlo Castaneda",
  personTestimonial: `Radius' cutting-edge tools and technology have 
  helped us to streamline our business by minimizing the small-time 
  consuming tasks and allowing us to focus on the most important 
  thing-our clients. This has resulted in an exponential increase in our business.`,
  personTeamLogo: `${CLOUDFRONT}/JedCarloTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/ChrisChruzTP.png`,
  personName: "Chris Cruz",
  personTestimonial: `The transition to Radius was a smooth, with easy onboarding. 
  I appreciate the support across all departments. Radius 
  is helping me move in the right direction, supporting my business growth.`,
  personTeamLogo: `${CLOUDFRONT}/ChrisTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/BrandonThumbail.png`,
  personName: "Brandon Thompson",
  personTestimonial: `Radius has provided tailored support for 
  MY business: branding/marketing assets, training, mortgage solutions, 
  stock ownership in the company, and a knowledgeable, team-centric community that are always there to help me grow.`,
  personTeamLogo: `${CLOUDFRONT}/BrandonTeam.png`,
  logoWidth: "171.429px",
  logoHeight: "20px",
}, {
  personImage: `${CLOUDFRONT}/JamesThumbnail.png`,
  personName: "James Quintero",
  personTestimonial: `Finding a brokerage to fill in the gaps is what brought 
me to Radius. Radius is constantly figuring out how to 
make agents' lives easier.`,
  personTeamLogo: `${CLOUDFRONT}/RiseTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JoeThumbnail.png`,
  personName: "Joe Stevenson",
  personTestimonial: `It seems like when I have a problem as an agent, Radius 
has a solution. These guys understand people.`,
  personTeamLogo: `${CLOUDFRONT}/IndieAgentTeamLogo.png`,
  logoWidth: "94px",
  logoHeight: "21px",
}, {
  personImage: `${CLOUDFRONT}/DeLeviThumbnail.png`,
  personName: "DeLevi Group",
  personTestimonial: `I came to Radius to create my own brand. Radius is 
about enabling the agent. They’re out there to provide every piece of support for their agent to succeed.`,
  personTeamLogo: `${CLOUDFRONT}/DeLeviTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JedCarloThumbnail.png`,
  personName: "Jed and Carlo Castaneda",
  personTestimonial: `Radius' cutting-edge tools and technology have 
  helped us to streamline our business by minimizing the small-time 
  consuming tasks and allowing us to focus on the most important 
  thing-our clients. This has resulted in an exponential increase in our business.`,
  personTeamLogo: `${CLOUDFRONT}/JedCarloTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/ChrisChruzTP.png`,
  personName: "Chris Cruz",
  personTestimonial: `The transition to Radius was a smooth, with easy onboarding. 
  I appreciate the support across all departments. Radius 
  is helping me move in the right direction, supporting my business growth.`,
  personTeamLogo: `${CLOUDFRONT}/ChrisTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/BrandonThumbail.png`,
  personName: "Brandon Thompson",
  personTestimonial: `Radius has provided tailored support for 
  MY business: branding/marketing assets, training, mortgage solutions, 
  stock ownership in the company, and a knowledgeable, team-centric community that are always there to help me grow.`,
  personTeamLogo: `${CLOUDFRONT}/BrandonTeam.png`,
  logoWidth: "171.429px",
  logoHeight: "20px",
}, {
  personImage: `${CLOUDFRONT}/JamesThumbnail.png`,
  personName: "James Quintero",
  personTestimonial: `Finding a brokerage to fill in the gaps is what brought 
me to Radius. Radius is constantly figuring out how to 
make agents' lives easier.`,
  personTeamLogo: `${CLOUDFRONT}/RiseTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JoeThumbnail.png`,
  personName: "Joe Stevenson",
  personTestimonial: `It seems like when I have a problem as an agent, Radius 
has a solution. These guys understand people.`,
  personTeamLogo: `${CLOUDFRONT}/IndieAgentTeamLogo.png`,
  logoWidth: "94px",
  logoHeight: "21px",
}, {
  personImage: `${CLOUDFRONT}/DeLeviThumbnail.png`,
  personName: "DeLevi Group",
  personTestimonial: `I came to Radius to create my own brand. Radius is 
about enabling the agent. They’re out there to provide every piece of support for their agent to succeed.`,
  personTeamLogo: `${CLOUDFRONT}/DeLeviTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JedCarloThumbnail.png`,
  personName: "Jed and Carlo Castaneda",
  personTestimonial: `Radius' cutting-edge tools and technology have 
  helped us to streamline our business by minimizing the small-time 
  consuming tasks and allowing us to focus on the most important 
  thing-our clients. This has resulted in an exponential increase in our business.`,
  personTeamLogo: `${CLOUDFRONT}/JedCarloTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/ChrisChruzTP.png`,
  personName: "Chris Cruz",
  personTestimonial: `The transition to Radius was a smooth, with easy onboarding. 
  I appreciate the support across all departments. Radius 
  is helping me move in the right direction, supporting my business growth.`,
  personTeamLogo: `${CLOUDFRONT}/ChrisTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/BrandonThumbail.png`,
  personName: "Brandon Thompson",
  personTestimonial: `Radius has provided tailored support for 
  MY business: branding/marketing assets, training, mortgage solutions, 
  stock ownership in the company, and a knowledgeable, team-centric community that are always there to help me grow.`,
  personTeamLogo: `${CLOUDFRONT}/BrandonTeam.png`,
  logoWidth: "171.429px",
  logoHeight: "20px",
}, {
  personImage: `${CLOUDFRONT}/JamesThumbnail.png`,
  personName: "James Quintero",
  personTestimonial: `Finding a brokerage to fill in the gaps is what brought 
me to Radius. Radius is constantly figuring out how to 
make agents' lives easier.`,
  personTeamLogo: `${CLOUDFRONT}/RiseTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JoeThumbnail.png`,
  personName: "Joe Stevenson",
  personTestimonial: `It seems like when I have a problem as an agent, Radius 
has a solution. These guys understand people.`,
  personTeamLogo: `${CLOUDFRONT}/IndieAgentTeamLogo.png`,
  logoWidth: "94px",
  logoHeight: "21px",
}, {
  personImage: `${CLOUDFRONT}/DeLeviThumbnail.png`,
  personName: "DeLevi Group",
  personTestimonial: `I came to Radius to create my own brand. Radius is 
about enabling the agent. They’re out there to provide every piece of support for their agent to succeed.`,
  personTeamLogo: `${CLOUDFRONT}/DeLeviTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JedCarloThumbnail.png`,
  personName: "Jed and Carlo Castaneda",
  personTestimonial: `Radius' cutting-edge tools and technology have 
  helped us to streamline our business by minimizing the small-time 
  consuming tasks and allowing us to focus on the most important 
  thing-our clients. This has resulted in an exponential increase in our business.`,
  personTeamLogo: `${CLOUDFRONT}/JedCarloTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/ChrisChruzTP.png`,
  personName: "Chris Cruz",
  personTestimonial: `The transition to Radius was a smooth, with easy onboarding. 
  I appreciate the support across all departments. Radius 
  is helping me move in the right direction, supporting my business growth.`,
  personTeamLogo: `${CLOUDFRONT}/ChrisTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/BrandonThumbail.png`,
  personName: "Brandon Thompson",
  personTestimonial: `Radius has provided tailored support for 
  MY business: branding/marketing assets, training, mortgage solutions, 
  stock ownership in the company, and a knowledgeable, team-centric community that are always there to help me grow.`,
  personTeamLogo: `${CLOUDFRONT}/BrandonTeam.png`,
  logoWidth: "171.429px",
  logoHeight: "20px",
}, {
  personImage: `${CLOUDFRONT}/JamesThumbnail.png`,
  personName: "James Quintero",
  personTestimonial: `Finding a brokerage to fill in the gaps is what brought 
me to Radius. Radius is constantly figuring out how to 
make agents' lives easier.`,
  personTeamLogo: `${CLOUDFRONT}/RiseTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JoeThumbnail.png`,
  personName: "Joe Stevenson",
  personTestimonial: `It seems like when I have a problem as an agent, Radius 
has a solution. These guys understand people.`,
  personTeamLogo: `${CLOUDFRONT}/IndieAgentTeamLogo.png`,
  logoWidth: "94px",
  logoHeight: "21px",
}, {
  personImage: `${CLOUDFRONT}/DeLeviThumbnail.png`,
  personName: "DeLevi Group",
  personTestimonial: `I came to Radius to create my own brand. Radius is 
about enabling the agent. They’re out there to provide every piece of support for their agent to succeed.`,
  personTeamLogo: `${CLOUDFRONT}/DeLeviTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/JedCarloThumbnail.png`,
  personName: "Jed and Carlo Castaneda",
  personTestimonial: `Radius' cutting-edge tools and technology have 
  helped us to streamline our business by minimizing the small-time 
  consuming tasks and allowing us to focus on the most important 
  thing-our clients. This has resulted in an exponential increase in our business.`,
  personTeamLogo: `${CLOUDFRONT}/JedCarloTeam.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}, {
  personImage: `${CLOUDFRONT}/ChrisChruzTP.png`,
  personName: "Chris Cruz",
  personTestimonial: `The transition to Radius was a smooth, with easy onboarding. 
  I appreciate the support across all departments. Radius 
  is helping me move in the right direction, supporting my business growth.`,
  personTeamLogo: `${CLOUDFRONT}/ChrisTeamLogo.png`,
  logoWidth: "87.188px",
  logoHeight: "45px",
}]
