import React from "react"
import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  height: 165px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
`

const Title = styled.h1`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  position: relative;
  top: -8px;
`

const TimelineText = styled.p`
  color: #5A5FF2;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.784px;
  margin: 0;
`

const TextAreaWrap = styled.div``

const ImageWrap = styled.div`
  height: 100%;

  img {
    height: calc(100% + 2px);
  }
`

const DISPLAY_OBJECT = [{
  text: "Immediate Ownership",
}, {
  text: "Brand Creation",
}, {
  text: "Brokerage Support",
}, {
  text: "Radius Ecosystem",
}]

const Timeline = () => (
  <Wrap>
    <ImageWrap>
      <img src={`${CLOUDFRONT}/TimelineGradient.png`} alt="timeline" />
    </ImageWrap>
    <TextAreaWrap>
      <Title>Radius Overview</Title>
      {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
        <TimelineText>
          {item.text}
        </TimelineText>
      ))}
    </TextAreaWrap>
  </Wrap>
)

export default Timeline
