import React, { useState } from "react"
import { required } from "services/formUtils"
import {
  Input,
  Select,
  LocationAutoComplete,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"
import { get } from "lodash"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"
import SubmitButton from "../Common/SubmitButton"
import {
  BASIC_INFO_FILE_TYPES,
  CLIENT_SOURCE,
  FIELD_VALUES,
  REPRESENTING_VALUES,
  US_OBJ,
} from "./data"
import CheckboxComponent from "../Common/RadioGroup"
import ClientArrayForm from "./ClientArrayField"
import {
  Wrap,
  FormWrap,
  InvalidLocation,
} from "../Common/CommonStyles"
import {
  HandleSubmit,
  FunctionalComponentDidMount,
  BrowserCloseListener,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"
import {
  DETAILS_FORM_ROUTE,
} from "../../Routes"

const handleAddressChange = (address, change, setError) => {
  const city = get(address, "locality.long_name")
  const state = get(address, "administrative_area_level_1.long_name")
  change("city", {
    city,
    state,
  })
  if (city) {
    setError(false)
  }
}

const BasicFormUI = ({
  ...props
}) => {
  const {
    change,
    dirty,
    invalid: isInvalid,
    handleSubmit,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    fetchTransactionCoordinationDocumentsResponse,
    id,
    formName,
    destroy,
    dispatch,
    combinedTCValues,
    fetchClient,
    getClientSearchResponse,
    isFormDisabled,
  } = props || {}

  const [isError, setError] = useState(false)

  BrowserCloseListener(dirty)

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    isFetching: creatingForm,
    isError: errorSubmittingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  const {
    data: clientData,
    isFetching: fetchingClient,
  } = getClientSearchResponse || {}

  const {
    initial_name,
    initial_email,
    initial_phone,
    file_type,
    source,
    zipcode,
    listing_address,
    client_type,
    city,
  } = combinedTCValues || {}

  const initialValuesExist = initial_name && initial_email && initial_phone
  let dbClientSourceType = ""

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    dbClientSourceType = currentDeal && currentDeal.source
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName, dispatch, change)
  }

  const isFormValid = isInvalid
  || !file_type
  || !source
  || !initialValuesExist
  || !zipcode
  || !listing_address
  || !client_type
  || !city
  || isError

  const isProcessing = !errorSubmittingForm
  && (fetchingValues || creatingForm || updatingTCStage || isFetchingDeals || fetchingClient)

  return (
    <>
      {fetchingValues ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          <Field
            name="listing_address"
            component={Input}
            disabled={isFormDisabled}
            validate={[required]}
            placeholder="123 Mission st"
            label="Property Street Address"
            customInputStyle={{
              width: "490px",
            }}
          />
          <Field
            name="city"
            onChange={(e) => {
              setError(true)
            }}
            component={LocationAutoComplete}
            disabled={isFormDisabled}
            placeholder="San Francisco"
            label="City"
            isTextbox
            validate={[required]}
            customInputStyle={{
              width: "490px",
            }}
            location={(addressVal) => { handleAddressChange(addressVal, change, setError) }}
            restrict={US_OBJ}
          />
          {isError && (
            <InvalidLocation>
              Invalid location, please select a value from dropdown.
            </InvalidLocation>
          )}
          <Field
            name="zipcode"
            component={Input}
            disabled={isFormDisabled}
            placeholder="90201"
            label="Zip"
            type="number"
            validate={[required]}
            customInputStyle={{
              width: "490px",
            }}
          />
          <div
            style={{
              width: "490px",
            }}
          >
            <Field
              name="client_type"
              component={Select}
              disabled={isFormDisabled}
              label="Representing"
              options={REPRESENTING_VALUES}
              config={{
                isSearchable: false,
              }}
            />
          </div>
          <CheckboxComponent
            optionArray={BASIC_INFO_FILE_TYPES}
            labelText="This file is a:"
            name="file_type"
            disabled={isFormDisabled}
          />
          <ClientArrayForm
            clientData={clientData}
            fetchClient={fetchClient}
            change={change}
            disabled={isFormDisabled}
          />
          <CheckboxComponent
            noButtonReposition
            optionArray={CLIENT_SOURCE}
            dbClientSourceType={dbClientSourceType}
            labelText="Client source"
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
            basicFormExtra
            name="source"
            disabled={isFormDisabled}
          />
          <SubmitButton
            disabled={isFormDisabled}
            isProcessing={isProcessing}
            text={isFormDisabled ? "Next" : "Continue"}
            isInvalid={isFormValid}
            fullWidth
          />
        </FormWrap>
      )}
    </>
  )
}

const BasicFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  properties,
  props,
  fetchClient,
  getClientSearchResponse,
  toggleErrorModal,
  fetchTCFiles,
  isErrorModalOpen,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  let disableField = false

  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0] || []
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  disableField = isCompleted || isCoListed || getIsEdit(currentDeal)

  console.log({ disableField });

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  return (
    <Wrap>
      <Header
        history={history}
        text="Basic Info"
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <BasicForm
          onSubmit={values => HandleSubmit(
            values,
            DETAILS_FORM_ROUTE,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "basic_info",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          isErrorModalOpen={isErrorModalOpen}
          id={parsedQuery.id}
          formName="TC_BASIC_FORM"
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          getClientSearchResponse={getClientSearchResponse}
          fetchClient={fetchClient}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const BasicForm = reduxForm({
  form: "TC_BASIC_FORM",
  destroyOnUnmount: false,
})(BasicFormUI)

export default BasicFormComponent
