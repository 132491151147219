import React from "react"
import styled from "styled-components"
import colors from "@colors"
import {
  minLargeDesktopWidth,
  preDesktopWidth,
  preTabletWidth,
  minDesktopWidth,
} from "shared/consts"
import LazyLoad from "react-lazyload"
import ContentElement from "../ContentElement"

const Wrap = styled.div`
  width: 100%;
  padding-top: 159px;
  padding-left: 59px;
  padding-right: 59px;

  ${minDesktopWidth`
    padding-left: 44px;
    padding-right: 44px;
  `}

  ${preDesktopWidth`
    padding-left: 31px;
    padding-right: 31px;
  `}

  margin-top: -66px;
`

const InnerWrap = styled.div`
  height: 687px;
  width: 100%;
  padding: 10px 104px 10px 104px;
  background: ${props => props.bgColor};
  display: flex;
  flex-direction: ${props => props.reverse ? "row-reverse" : "reverse"}
  ${minDesktopWidth`
    padding: 10px 66px 10px 66px;
  `}

  //cannot use media query util inside or with a prop condition
  @media(max-width: 1099px) {
    padding: 10px 0px 10px 0px;

    ${props => !props.reverse && `
      padding: 10px 49px 10px 49px;
    `}
  }

  ${preTabletWidth`
    flex-direction: column-reverse;
    height: max-content;
    padding: 43px 32px 37px 32px;
  `}
`

const FlexContent = styled.div`
  height: 100%;
  width: 100%;
`

const Image = styled.img`
  height: ${props => props.customHeight ? props.customHeight : "50%"};
  position: relative;
  top: ${props => props.customTop ? props.customTop : "178px"};
  right: ${props => props.customRight ? props.customRight : "25px"};
  max-height: ${props => props.maxHeight};


  //cannot use media query util inside or with a prop condition
  @media(min-width: 1100px) {
    height: ${props => props.customMinDesktopHeight};
    top: ${props => props.customMinDesktopTop};
    right: ${props => props.customMinDesktopRight ? props.customMinDesktopRight : "unset"};
  }

  //cannot use media query util inside or with a prop condition
  @media(min-width: 1280px) {
    height: ${props => props.customMediumDesktopHeight};
    top: ${props => props.customMediumDesktopTop};
  }

  ${minLargeDesktopWidth`
    right: unset;
    display: block;
    margin: 0 auto;
  `}

  //cannot use media query util inside or with a prop condition
  @media(max-width: 1099px) {
    height: unset;
    width: ${props => props.customTabletWidth || "304px"};
    position: relative;
    top: ${props => props.customTabletTop || "215px"};
    right: 4px;

    ${props => props.removePositional && `
      margin: 0 auto;
      display: block;
      right: unset;
    `}
  }

  //cannot use media query util inside or with a prop condition
  @media(max-width: 767px) {
    top: ${props => props.mobileImageTop || "48px"};
    margin: 0 auto;
    display: block;
  }
  
  //cannot use media query util inside or with a prop condition
  @media(max-width: 376px) {
    width: ${props => props.xsMobileImageWidth || "250px"};
    top: ${props => props.xsMobileImageTop || "48px"};
    margin: 0 auto;
    display: block;
  }

  animation: showUp 0.7s forwards;

  @keyframes showUp {
    0%{
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

const RoomsFeatured = ({
  isLMP,
  image,
  checklistData,
  tagText,
  headerText,
  hyperLink,
  subHeaderText,
  tagHeaderText,
  reverse,
  isTagHeaderText,
  bgColor,
  customHeight,
  customTop,
  customRight,
  headerTextWidth,
  customMinDesktopHeight,
  customMinDesktopRight,
  customMinDesktopTop,
  customMediumDesktopHeight,
  customMediumDesktopTop,
  customTabletWidth,
  customTabletTop,
  customListWidthTablet,
  customListWidthMinDesktop,
  mobileImageTop,
  mobileImageRight,
  xsMobileImageWidth,
  xsMobileImageTop,
  xsMobileImageRight,
  action,
  url,
  heap,
  maxHeight,
  subTextWidthMinDesktop,
  headerFontFamily,
  tagFontFamily,
  contentFontFamily,
}) => (
  <Wrap>
    <InnerWrap
      reverse={reverse}
      bgColor={bgColor}
    >
      <FlexContent>
        <ContentElement
          headerFontFamily={headerFontFamily}
          leftPadding={reverse}
          tagFontFamily={tagFontFamily}
          isLMP={isLMP}
          isTagHeaderText={isTagHeaderText}
          tagText={tagText}
          tagHeaderText={tagHeaderText || ""}
          headerText={headerText}
          subHeaderText={subHeaderText}
          checklistData={checklistData}
          hyperLink={hyperLink}
          headerTextWidth={headerTextWidth}
          customListWidthTablet={customListWidthTablet}
          action={action}
          url={url}
          heap={heap}
          customListWidthMinDesktop={customListWidthMinDesktop}
          subTextWidthMinDesktop={subTextWidthMinDesktop}
          contentFontFamily={contentFontFamily}
        />
      </FlexContent>
      <FlexContent>
        <LazyLoad>
          <Image
            loading="lazy"
            maxHeight={maxHeight}
            customRight={customRight}
            customHeight={customHeight}
            customTop={customTop}
            customMediumDesktopHeight={customMediumDesktopHeight}
            customMediumDesktopTop={customMediumDesktopTop}
            customTabletWidth={customTabletWidth}
            customTabletTop={customTabletTop}
            mobileImageTop={mobileImageTop}
            mobileImageRight={mobileImageRight}
            xsMobileImageWidth={xsMobileImageWidth}
            xsMobileImageTop={xsMobileImageTop}
            xsMobileImageRight={xsMobileImageRight}
            customMinDesktopHeight={customMinDesktopHeight}
            customMinDesktopRight={customMinDesktopRight}
            customMinDesktopTop={customMinDesktopTop}
            src={image}
            alt=""
            removePositional={reverse}
          />
        </LazyLoad>
      </FlexContent>
    </InnerWrap>
  </Wrap>
)

export default RoomsFeatured
