import React from "react"
import styled from "styled-components"
import { Route, Switch } from "react-router-dom"
import {
  Navbar,
  ICAHomepage,
  FeeSchedule,
  Partnership,
  ICAAgreement,
  Success,
  RadiusVerify,
  UpdateEmail,
  TeamMemberInvite,
} from "./Components"

const Wrap = styled.div`
`

const ICAOnboarding = () => {
  return (
    <Wrap>
      <Navbar />
      <Switch>
        <Route path="/office/ica-onboarding" exact component={ICAHomepage} />
        <Route path="/office/ica-onboarding/fee-schedule" exact component={FeeSchedule} />
        <Route path="/office/ica-onboarding/radius-partnership" exact component={Partnership} />
        <Route path="/office/ica-onboarding/agreement" exact component={ICAAgreement} />
        <Route path="/office/ica-onboarding/success" exact component={Success} />

        <Route path="/office/ica-onboarding/team-invite" component={TeamMemberInvite} headerVersion="v2" />
        <Route path="/office/ica-onboarding/verify-email" exact component={RadiusVerify} headerVersion="v2" />
        <Route path="/office/ica-onboarding/update-email" exact component={UpdateEmail} headerVersion="v2" />
      </Switch>
    </Wrap>
  )
}

export default ICAOnboarding
