import React from "react"
import styled from "styled-components"
import Slider from "./SlidersV2"
import { HeroText } from "../commonStyles"

const Wrap = styled.div`
  display: block;
  // padding: 62px 0px;
  display: block;
  // margin-top: 360px;
  position: relative;
  z-index: 2;
  //padding-top: 100px;

  @media(max-width: 1099px) {
    // padding: 0px;
    margin: auto;
    height: 730px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    //margin-top: 100px;
    padding-top: unset;
  }
`

const TextWrap = styled.div`
  margin-bottom: 75px;
`

const SectionSeven = ({
  width,
}) => (
  <Wrap className="container">
    <TextWrap>
      <HeroText
        spanStyles
        customPaddingMobile="20px"
      >
        <span>Our Partnerships</span>
        {" "}
        Speak For Themselves
      </HeroText>
    </TextWrap>
    <Slider />
  </Wrap>
)

export default SectionSeven
