import React from "react"
import styled from "styled-components"
import appContainer from "container/App"
import interestContainer from "container/App/applyFlowContainer"
import Form from "dumbComponents/common/NewApplyForm/Form"
import AppFormV2 from "../AppFormV2"
import QRCodeV2 from "../QRCodeV2"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const ANNUAL = ["Join a high-performing team", "Create a solo brand"]
const GROUP = ["Start my own team / brokerage", "Up-level my existing team / brokerage"]

const Wrap = styled.div`
  width: 442px;
  // height: 566px;
  flex-shrink: 0;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 45px;
  border-radius: 9px;
  position: relative;
  top: -50px;
  left: 800px;

  animation: swipeFromRight 0.2s ease-in forwards;


  @keyframes swipeFromRight {
    0% {
      left: 800px;
    }

    100% {
      left: 0;
    }
  }
`

const HeaderTextWrap = styled.div`
  margin-bottom: 19px;
`

const HeaderText = styled.h1`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.9%;

  span {
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.104px;
  }
`

const CrossButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;

  img {
    width: 10.488px;
    height: 10.488px;
  }
`

const PostSubmitTitle = styled.h2`
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.9%; /* 25.104px */
`

const Message = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.9%; /* 20.397px */
  margin-top:4px;
  margin-bottom: 19px;
`

const HeroSection = ({
  applyInterestsResponse,
  signUpFormValues,
  setFormVisible,
  submitInterestForm,
  appDownloadActionResponse,
  className,
  isMQLFormSubmitted,
}) => {
  const {
    isFetching,
    data: applyData,
  } = applyInterestsResponse || {}

  const { data: appDownloadData, isFetching: submittingForm } = appDownloadActionResponse || {}

  const handleOnSubmit = (values) => {
    const {
      account_type: productionType,
    } = signUpFormValues || {}

    // const nameArray = values.firstname.split(" ")
    // const firstname = nameArray.shift()
    // const lastname = nameArray.join(" ")
    const payload = {
      ...values,
      lastname: values.lastname,
      firstname: values.firstname,
      state: values.state,
      /*deprecated key number_of_transactions_last_year */
      number_of_transactions_last_year: 0,
      account_type: values.account_type,
      source: "Home Page",
      agency_name: "",
    }
    if (values.account_type.value === "Other") {
      payload.is_other_account_type = true
    }

    // if (productionType && productionType.production === "annual") {
    //   delete payload.group_annual_production
    //   payload.annual_production = values.annual_production.value
    // }

    // if (productionType && productionType.production === "group") {
    //   delete payload.annual_production
    //   payload.group_annual_production = values.group_annual_production.value
    // }

    if (productionType && ANNUAL.includes(productionType)) {
      delete payload.group_annual_production
      payload.annual_production = values.annual_production
    }

    if (productionType && GROUP.includes(productionType)) {
      delete payload.annual_production
      payload.group_annual_production = values.group_annual_production
    }

    submitInterestForm({
      payload,
      isLogged: false,
      logInModalClassName: "",
      vwoUserId: null,
      vwoClient: null,
      webClassName: null,
    })
  }

  return (
    <Wrap className="popup-anim">
      <CrossButtonWrap
        onClick={() => {
          setFormVisible({
            bool: false,
            className: "",
          })
        }}
      >
        <img src={`${CLOUDFRONT}/CrossVectorNew.png`} alt="cross.png" />
      </CrossButtonWrap>
      {isMQLFormSubmitted ? (
        <>
          <PostSubmitTitle>
            It&apos;s great to meet you!
          </PostSubmitTitle>
          <Message>
            {`A member of the Radius recruiting team will be in touch shortly to 
            learn more about your brand. In the meantime, download the Radius app 
            and join the world's largest community of real estate professionals!`}
          </Message>
          <QRCodeV2
            inForm
            isSubmitting={submittingForm}
            appDownloadSMSData={appDownloadData}
            className="form_mobile_app_link_request_web"
          />
        </>
      ) : (
        <>
          <HeaderTextWrap>
            <HeaderText>
              <span>With one click,</span>
              {" "}
              you’ve taken a first step towards building your real estate legacy.
            </HeaderText>
          </HeaderTextWrap>
          <Form
            onSubmit={handleOnSubmit}
            isSubmitting={isFetching}
            signUpFormValues={signUpFormValues}
            isLandingPage
            className={className}
          />
        </>
      )}
    </Wrap>
  )
}

export default interestContainer(appContainer(HeroSection))
