import React from "react"
import styled, { keyframes } from "styled-components"
import colors from "@colors"

const Wrapper = styled.div`
  margin: ${props => (props.margin ? props.margin : "0 auto")};
`
const Animation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  90% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
`

const Dot = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${props => props.color};
  transform: scale(0);
  display: inline-block;
  margin-right: ${props => props.spacing}px;
  animation: ${Animation} ${props => props.duration}ms infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
  &:nth-child(4) {
    animation-delay: 0.6s;
  }

  &:last-child {
    margin-right: 0px;
  }
`

const ThreeDotLoader = props => (
  <Wrapper margin={props.margin}>
    {[1, 2, 3].map(key => (
      <Dot key={key} {...props} />
    ))}
  </Wrapper>
)

ThreeDotLoader.defaultProps = {
  size: 10,
  color: colors.primaryColor,
  spacing: 3,
  duration: 1000,
  margin: 0,
}

export default ThreeDotLoader
