import React from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import moment from "moment"
import {
  Wrap,
  HeaderWrap,
  StyledStatus,
  StatusTitle,
  DocWrap,
  DocTitle,
  FooterWrap,
  DateCreatedContent,
  DocContent,
  DateContent,
  DateContentWrap,
} from "./styles"
import DynamicMenu from "../DynamicMenu"

const Envelopes = ({
  dropdownList,
  mainList,
  docs,
  status,
  id,
  fillAndSign,
  date,
  creatingReceiverView,
}) => {
  console.log(status)
  return (
    <Wrap>
      <HeaderWrap>
        <StyledStatus
          status={status.value}
        >
          <StatusTitle
            status={status.value}
          >
            {status.label}
          </StatusTitle>
        </StyledStatus>
        <DynamicMenu
          dropdownList={dropdownList}
          mainList={mainList}
          id={id}
        />
      </HeaderWrap>
      <DocContent>
        {docs && docs.map(doc => (
          <DocWrap>
            <DocTitle>
              {doc.name}
            </DocTitle>
          </DocWrap>
        ))}
      </DocContent>
      <FooterWrap>
        <DateContentWrap>
          <DateCreatedContent>
            Date created
          </DateCreatedContent>
          <DateContent>
            {moment(date).format("MM/DD/YY")}
          </DateContent>
        </DateContentWrap>
        {status.value === "action_required" && fillAndSign && typeof fillAndSign === "function" && (
          <BlueButton onClick={() => fillAndSign(id)}>
            {creatingReceiverView ? (
              <ThreeDotLoader color="#fff" />
            ) : (
              <p>Fill &amp; Sign</p>
            )}
          </BlueButton>
        )}
      </FooterWrap>
    </Wrap>
  )
}

export default Envelopes
