//GoogleAutoComplete
import React from "react"
import { Form, GoogleAutoComplete } from "@ui/antd"
import { get } from "lodash"
import {
  InputFieldWrap,
} from "./styles"

const InputField = ({
  placeholder,
  type,
  name,
  required,
  style,
  customRules,
  inputType,
  onChange,
  locationChange,
  formRef,
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required, type: "email" }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required }]
    }
  }

  const setStreet = (location, formRef, key) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      zipCode: get(location, "postal_code.long_name"),
      streetNumber: get(location, "street_number.long_name"),
      county: get(location, "administrative_area_level_2.long_name"),
      route: get(location, "route.long_name"),
    }

    console.log(locationDetails)

    const addressString = locationDetails.streetNumber
      && locationDetails
        .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null

    formRef.current.setFieldsValue({
      [key]: addressString || locationDetails.address,
      city: locationDetails.city,
      state: locationDetails.state,
      zipcode: locationDetails.zipCode,
      county: `${locationDetails.county}`,
    })
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
    >
      <GoogleAutoComplete
        id="cities"
        types={["address"]}
        name="cities"
        // formValue={cityFormValue}
        onChange={(e) => { }}
        location={(loc) => { setStreet(loc, formRef, name) }}
        placeholder={placeholder}
        styles={{
          borderRadius: "8px !important",
          border: "1px solid var(--Color-Primary-Primary, #898989) !important",
          padding: "12px 16px",
          height: "42px !important",
        }}
      />
    </Form.Item>
  )
}

export default InputField
