import React, { useEffect, useState } from "react"
import colors from "@colors"
import CRMV2Container from "container/CRM"
import {
  Form,
  NewButton,
  Space,
  NewGoogleAutoComplete,
  NewInputNumber,
  NewRadioButton,
  NewRadioGroup,
} from "@ui/antd"
import styled from "styled-components"
import { Add } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { getFormattedLocationObject } from "dumbComponents/CRMV2/CRMDashboard/Components/utils"
import { MinusCircleOutlined } from "@ant-design/icons"
import { SearchCriteriaHeaderWrap, LeadSourceWrap, StyledLabel, StyledSearchCriteriaHeading, NewLightButton } from "../../common"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item, List } = Form

const SearchCriteriaForm = ({
  formRef,
  getPropertyTypes,
  getPropertyTypeResponse,
}) => {
  useEffect(() => {
    getPropertyTypes()
  }, [])

  const [count, setFormCount] = useState([])
  const [number, setNumber] = useState(1)

  const { data: propertyTypes } = getPropertyTypeResponse || {}
  const { types } = propertyTypes || {}

  const [transactionType, setTransactionType] = useState("")
  const onTransactionTypeChange = (value) => {
    setTransactionType(value)
    formRef.current.setFieldsValue({ transactionType: value })
  }

  const [metric, setMetric] = useState("")
  const onMetricChange = (value) => {
    setMetric(value)
    formRef.current.setFieldsValue({ area_unit: value })
  }

  const setLocationInForm = (newValues, key) => {
    const currentFieldValues = formRef?.current?.getFieldsValue()

    if (!currentFieldValues) {
      return
    }

    if (!currentFieldValues?.search_criteria?.[key]) {
      currentFieldValues.search_criteria[key] = {}
    }

    const locationPayload = newValues.map(location => getFormattedLocationObject(location))

    currentFieldValues.search_criteria[key].location = [
      ...locationPayload,
    ]

    formRef.current.setFieldsValue({ ...currentFieldValues })
  }

  return (
    <>
      <StyledSearchCriteriaHeading>Search Criteria</StyledSearchCriteriaHeading>
      {/* <LeadSourceWrap width="100%"> */}
      <List name="search_criteria">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Space key={key} direction="vertical" size={16} style={{border: "1px solid #E0E0EB", borderRadius: "8px", padding: "12px"}}>
                  <SearchCriteriaHeaderWrap>
                    {/* <h2>Search Criteria</h2> */}
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name)
                        setNumber(number - 1)
                        setFormCount(count.filter(item => item !== number && item > number))
                      }}
                    />
                  </SearchCriteriaHeaderWrap>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledLabel>Transaction Type*</StyledLabel>
                    <Item
                      name={[name, "sale_type"]}
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: "Transaction type is required" }]}
                    >
                      <NewRadioGroup
                        onChange={onTransactionTypeChange}
                        value={transactionType}
                        size="small"
                      >
                        <NewRadioButton value="resale">Buy</NewRadioButton>
                        <NewRadioButton value="rent">Rent</NewRadioButton>
                      </NewRadioGroup>
                    </Item>
                  </div>
                  <Space key={key} align="baseline">
                    <Item
                      {...restField}
                      name={[name, "bedroom"]}
                      rules={[{ type: "number" }]}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Beds" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `${value} +`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        min={1}
                        placeholder="Beds"
                      />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, "bathroom"]}
                      rules={[{ type: "number" }]}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Baths" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `${value} +`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        min={1}
                        placeholder="Baths"
                      />
                    </Item>
                  </Space>
                  <Space key={key} align="baseline">
                    <Item
                      {...restField}
                      name={[name, "min_budget"]}
                      rules={[{ message: "Min budget is required", type: "number", required: true }]}
                      key={key}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Min Budget*" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        controls={false}
                        placeholder="Min Budget*"
                      />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, "max_budget"]}
                      rules={[{ message: "Max budget is required", type: "number", required: true }]}
                      key={key}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Max Budget*" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        controls={false}
                        placeholder="Max Budget*"
                      />
                    </Item>
                  </Space>
                  <Space key={key} align="baseline">
                    <Item
                      {...restField}
                      name={[name, "min_size"]}
                      rules={[{ message: "Only numbers allowed", type: "number" }]}
                      key={key}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Min Size" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        controls={false}
                        placeholder="Min Size"
                      />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, "max_size"]}
                      rules={[{ message: "Only numbers allowed", type: "number" }]}
                      key={key}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Max Size" />}
                    >
                      <NewInputNumber
                        formatter={value => value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
                        }
                        controls={false}
                        placeholder="Max Size"
                      />
                    </Item>
                  </Space>
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '8px' }}>
                    <StyledLabel>Units</StyledLabel>
                    <Item
                      name={[name, "area_unit"]}
                      style={{ marginBottom: 0 }}
                    >
                      <NewRadioGroup onChange={onMetricChange} value={metric} size="small">
                        <NewRadioButton value="Sq.ft">Sq. ft</NewRadioButton>
                        <NewRadioButton value="Sq.mt">Sq. mt</NewRadioButton>
                        <NewRadioButton value="Acres">Acres</NewRadioButton>
                      </NewRadioGroup>
                    </Item>
                  </div>
                  {/* TODO - maybe needed in future - abhinav */}
                  {/* <Space key={key} style={{ display: "flex", marginBottom: 8, marginTop: 20 }} align="baseline">
                      <Item name={[name, "property_type"]} label={<LabelComponent text="Property Type" />} rules={[{ required: true }]}>
                        <Select placeholder="Select...">
                          {types.map(type => (
                            <Option value={type.value}>
                              {type.value}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Space> */}
                  <Item
                    {...restField}
                    name={[name, "location"]}
                    rules={[
                      {
                        type: "array",
                        required: true,
                        message: "Neighborhood is required"
                      },
                    ]}
                    style={{ marginBottom: "16px" }}
                    label={<LabelComponent text="Search Neighborhood*" />}
                  >
                    <NewGoogleAutoComplete
                      id="cities"
                      type={["(regions)"]}
                      name="cities"
                      onLocationSelect={(newValues) => {
                        setLocationInForm(newValues, key)
                      }}
                      onLocationDeselect={(newValues) => {
                        setLocationInForm(newValues, key)
                      }}
                      placeholder="Search Neighborhood*"
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                          lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                          lng: -66.94,
                        },
                        address: "United States",
                        country: {
                          long_name: "United States",
                          short_name: "US",
                          types: ["country", "political"],
                        },
                      }}
                    />
                  </Item>
                </Space>
              </>
            ))}

            <NewLightButton
              type="primary"
              onClick={() => {
                add()
              }}
            >
              <Add color="#5A5FF2" /> Add a search
            </NewLightButton>
          </>
        )}
      </List>
    {/* </LeadSourceWrap> */}
    </>
    
  )
}

export default CRMV2Container(SearchCriteriaForm)
