import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: inline-block;
`

const CounterButton = styled.button`
  border-radius: 50%;
  border: none;
  background: transparent;
  width: ${prop => prop.size};
  height: ${prop => prop.size};
  cursor: pointer;
  padding: 0;
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`

const Optional = styled.span`
  color: #CCC;
  display: block;
  position: absolute;
  bottom: -20px !important;
  font-size: 12px;
  left: 0px;
`

const SubSection = styled.div`
  
`

const CountWrap = styled.div`
  display: flex;
  align-items: center;
  juctify-content: center;
  padding: 15px;
`

const Label = styled.label`
  font-size: 14px;
  color: #d4d4d4;
  font-weight: 300;
`

const Controls = styled.div`
  display: flex;
  margin-top: 5px;
`

const Counter = (field) => {
  const {
    input: {
      value = 0,
      onChange,
    },
    meta,
    optional,
    optionalText = "Optional",
    size,
    color,
    label,
  } = field
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Controls>
        <CounterButton
          type="button"
          onClick={() => { if (value > 0) { onChange(parseInt(value || 0, 0) - 1) } }}
        >
          <i
            className="icon-generic-Minus"
            style={{ fontSize: size, color }}
          >
          </i>
        </CounterButton>
        <CountWrap>{value || 0 }</CountWrap>
        <CounterButton
          type="button"
          onClick={() => { onChange(parseInt(value || 0, 0) + 1) }}
        >
          <i
            className="icon-generic-Plus"
            style={{ fontSize: size, color }}
          >
          </i>
        </CounterButton>
        <SubSection>
          {(meta.touched && meta.error)
              && <ErrorLabel>{meta.error}</ErrorLabel>
          }
          {!(meta.touched && meta.error) && optional && <Optional>{optionalText}</Optional>}
        </SubSection>
      </Controls>
    </Wrapper>
  )
}

Counter.defaultProps = {
  size: "50px",
  color: "#11adf3",
  input: {
    value: 0,
  },
}

export default Counter
