import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/messaging"

const stagingKeys = {
  apiKey: "AIzaSyA89-mVYwb6zXDRMxqOzYjwnzfwhnilLrQ",
  authDomain: "radius-staging.firebaseapp.com",
  projectId: "radius-staging",
  storageBucket: "radius-staging.appspot.com",
  messagingSenderId: "398268517637",
  appId: "1:398268517637:web:dc7c08bf89f5178fd2bb6c"
}

const initializedFirebaseAppStaging = firebase.initializeApp(stagingKeys)
firebase.analytics()

let messaging

if (firebase.messaging.isSupported() && Notification.permission !== "denied") {
  // messaging is supported
  messaging = initializedFirebaseAppStaging.messaging()
} else {
  // messaging is not supported
  console.warn("Messaging is not supported")
}

// messaging.usePublicVapidKey(
//   "BGLpxBdbgu20BExG1McCXB09PwZT5kRl0XqLzdXOJ0R95mttzrzEUj6BsQZxyEeLzSD3HN8xsXYygMsM8mSBnyQ"
// )
export { messaging }
