import React, { useRef } from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  InputLocation,
  AgentSearch,
  HiddenInputField,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
  WhiteButton,
} from "../../../../commonStyles"

/**
 * 
 * @param {*} param0 
 * @returns 
 */

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteAgent,
}) => {
  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
    }

    return initValues
  }

  return (
    <FormWrap
      isAddCoAgentForm={isAddCoAgentForm}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Agent Form"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            const id = prefilledData ? prefilledData.id : ""
            handleEditModeChange(id)
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V2/minus.svg`} alt="minus" />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_buyer_agent_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        // ref={this.formRef}
      >
        {/**HIDDEN FIELDS*/}
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="is_primary"/>

        {isAddCoAgentForm ? (
          <>
            <AgentSearch
              name="agent_first_name"
              inputType="text"
              placeholder="Co-Agent's Name"
              customRules={{}}
              isFormField
              formRef={formRef}
            />

            <InputField
              name="agent_last_name"
              inputType="text"
              placeholder="Last Name"
              customRules={{}}
            />
          </>
         
        ) : (
          <>
            <InputField
              name="agent_first_name"
              inputType="text"
              placeholder="First Name*"
            />

            <InputField
              name="agent_last_name"
              inputType="text"
              placeholder="Last Name"
              customRules={{}}
            />
          </>
        )}  
        

        <InputField
          name="email"
          inputType="email"
          type="email"
          placeholder="Email*"
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone*"
        />

        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="Agent License No*"
        />

        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Brokerage License No*"
        />

        {/**Requires Google Address*/}
        <InputLocation
          name="agent_brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Street Address*"
          formRef={formRef}
        />

        <InputField
          name="city"
          inputType="text"
          type="text"
          placeholder="City"
        />

        <InputField
          name="state"
          inputType="text"
          type="text"
          placeholder="State"
        />

        <InputField
          name="zipcode"
          inputType="text"
          type="text"
          placeholder="Zipcode"
        />

        <CTAWrap>
          {!isAddCoAgentForm && !prefilledData.isMainAgent && (
            <Form.Item>
              <DangerButton
                type="primary"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteAgent(prefilledData.agentId)}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )}

          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>{isAddCoAgentForm ? "Add Co-Agent" : "Save"}</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default BuyerAgentForm
