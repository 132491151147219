import styled from "styled-components"
import { Collapse } from "@ui/antd"
import { INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

export const SideNavWrap = styled.div`
  display: flex;
  align-items: end;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;

  ${props => props.isActive && `
    background: var(--Tabselected, #F4F4FF);
  `}
`

export const SideNavTitleWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const SideNavTitle = styled.p`
  margin: 0;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;

  ${props => props.isActive && `
    color: var(--Color-Primary-Primary, #5A5FF2);
  `}
`

export const StyledCollapse = styled(Collapse)`
  background-color: white !important;
  padding-top: 0px;

  .ant-collapse-content {
    border-top: 0px;
    border-radius: 9px !important;
  }

  border-radius: 9px !important;

  .ant-collapse-item {
    border-radius: 9px !important;
  }

  .ant-collapse-header-text {
    font-size: 16px;
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-weight: 500;
  }

  .ant-collapse-header {
    align-items: center !important;
  }

  .ant-collapse-expand-icon {
    display: flex;

    svg {
      fill: #5A5FF2;
    }
  }
`

const { Panel } = Collapse

export const StyledPanel = styled(Panel)``

export const ValidatorNumber = styled.div`
display: flex;
width: 18px;
height: 18px;
padding: 2px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: ${INTER};
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 141%; `