import React from "react"
import {
  ThreeDotContainer,
  StyledPopover,
} from "./styles"

const InputPopover = ({
  content,
}) => (
  <StyledPopover
    content={content}
    trigger="hover"
    placement="left"
    overlayInnerStyle={{
      padding: 0,
    }}
  >
    <ThreeDotContainer>
      <img src={`${CLOUDFRONT}/LISTING_V2/ThreeDots.svg`} alt="hello" />
    </ThreeDotContainer>
  </StyledPopover>
)

export default InputPopover
