import React from "react"
import styled from "styled-components"
import uuid from "uuid"
import { isEmpty } from "lodash"
import Heading from "dumbComponents/common/Typography/Heading"

const Wrapper = styled.div`
  position: relative;
  padding: 15px;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
`

const ChildWrap = styled.div`
  margin-top: 20px; 
`

const EditButton = styled.button`
  background: transparent;
  position: absolute;
  font-size: 14px;
  top: 14px;
  right: 10px;
  padding: 0px;
  color: #11adf3;
  border: none;
  cursor: pointer;
`

const shouldWeRender = (isShowEdit, initialValues, type) => {
  if (isEmpty(initialValues) && !isShowEdit) {
    return false
  }
  if (!isShowEdit && isEmpty(initialValues.education) && type === "education") {
    return false
  }
  if (!isShowEdit && isEmpty(initialValues.experiences) && type === "experiences") {
    return false
  }
  if (!isShowEdit && typeof initialValues === "object" && type === "addBuyerNeeds") {
    return false
  }
  return true
}

const showWeShowEdit = (isShowEdit, initialValues, type) => {
  if (isShowEdit) {
    if (type === "education" && !isEmpty(initialValues.education)) {
      return true
    }
    if (type === "experiences" && !isEmpty(initialValues.experiences)) {
      return true
    }
    if (type !== "experiences" && type !== "education" && !isEmpty(initialValues)) {
      return true
    }
  }
  return false
}

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.setState({
      id: uuid(),
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const {
      title,
      children,
      align,
      isShowEdit,
      handleEditClick,
      initialValues,
      type,
      editText,
      filters,
      className,
    } = this.props
    const { id, error } = this.state
    if (!shouldWeRender(isShowEdit, initialValues, type) || isEmpty(children)) {
      return null
    }
    return (
      <Wrapper className={className} key={id}>
        <Heading
          type="h7"
          transform="uppercase"
          align={align}
          color="#354052"
          style={{
            //whiteSpace: "nowrap",
            overflow: "hidden",
            paddingRight: 20,
            textOverflow: "ellipsis",
          }}
          title={title}
        >
          {title}
        </Heading>
        {!filters && showWeShowEdit(isShowEdit, initialValues, type) && (
          <EditButton onClick={() => { handleEditClick({ initialValues, type }) }}>{editText}</EditButton>
        )}
        { filters }
        { error ? (
          <div>Oop! Something went wrong!</div>
        ) : (
          <div key={type} style={{ marginTop: 20 }}>
            {children}
          </div>
        )}
      </Wrapper>
    )
  }
}

Panel.defaultProps = {
  align: "left",
  editText: "Edit",
}

export default Panel
