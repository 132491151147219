import React, { useState } from "react";
import OffersV2 from "container/OffersV2/index";
import root from "window-or-global";
import { EyeIcon } from "dumbComponents/OffersV2/icons";
import { Wrap } from "./style";
import CheckboxList from "../CheckboxList/index";

const DocsUI = ({
  docs,
  handleSubmit,
}) => {
  const [filterText, setFilterText] = useState("");

  const OptionList2 = [];

  const MainListV2 = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: id => root.open(id, "_blank"),
      showDivide: false,
    },
  ];

  return (
    <Wrap flex>
      {docs && docs.map((document) => (
        <div key={document.id}>
          <CheckboxList
            key={document.id}
            list={document}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            customFilterText={filterText}
            hideCheckBox
          />
        </div>
      ))}
    </Wrap>
  );
};

export default OffersV2(DocsUI);
