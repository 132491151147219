import axios from "axios"

export const fetchListingPageAPI = (MLS_ID, property_id) => {
 return axios.get(
    `${API_URL}public-page/property-info?mlsId=${MLS_ID}&mlsPropertyId=${property_id}&propertyInfoPage=true`
  )
}

export const postRequestTourInfoAPI = (payload) => {
  return axios.post(
     `${API_URL}public-page/property-info/enquiry`, payload
   )
 }