import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import {
  delay,
} from "redux-saga"
import root from "window-or-global"
import query from "query-string"
import { authCheck } from "services/auth"
import { destroy } from "redux-form"
import {
  isSuccess,
} from "services/Utils"
import cookie from "services/CookieStorage"
import createToast from "@ui/Toast"
import { addMinutes } from "date-fns"
import * as RealtyAPIs from "./api"
import * as RealtyActions from "./actions"

function* handleNewletter(action) {
  try {
    const {
      payload,
      isLoggedIn,
    } = action.data
    let finalPayload = null
    if (isLoggedIn) {
      const user = yield select(state => state.app.user)
      const {
        firstname,
        lastname,
        email,
      } = user
      finalPayload = {
        firstname,
        lastname,
        email,
      }
    } else {
      finalPayload = payload
    }
    const res = yield call(RealtyAPIs.submitNewLetterAPI, finalPayload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.submitNewsLetterAction.success(response))
      yield put(destroy("JUST_UPDATED_FORM"))
      createToast("You're Subscribed to news letter.")
    }
  } catch (error) {
    yield put(RealtyActions.submitNewsLetterAction.failure(error))
  }
}

function* handleSchduleACall(action) {
  try {
    const {
      payload,
      redirect,
      history,
    } = action.data
    const res = yield call(RealtyAPIs.submitScheduleACallAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      const isLogged = authCheck()
      yield put(RealtyActions.submitScheduleACallAction.success(response))
      yield put(RealtyActions.showReferralManagerV2Action.call(false))
      if (isLogged) {
        yield put(RealtyActions.pageTrackerAction.request({
          payload: {
            source: "chatbox_form_interest_submit",
          },
        }))
      }
      // createToast("Submitted, Thank You.")
      cookie.save("interestViaPopUp", true)

      if (redirect) {
        history.push("/realty/thankyou")
      } else {
        yield put(RealtyActions.rarFormModalToggleAction.call(false))
      }
    }
  } catch (error) {
    yield put(RealtyActions.submitScheduleACallAction.failure(error))
    createToast("Oops! Something went wrong! Please try again", "error")
  }
}

function* handlePageTracker(action) {
  try {
    // This is not handled in Reducer.
    const {
      payload,
    } = action.data
    const res = yield call(RealtyAPIs.pageTrackingAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.pageTrackerAction.success(response))
    }
  } catch (error) {
    yield put(RealtyActions.pageTrackerAction.failure(error))
  }
}

function* handleBrokerageFeatureViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(RealtyAPIs.brokerageFeatureViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.brokerageFeatureViewedAction.success(response))
    }
  } catch (error) {
    yield put(RealtyActions.brokerageFeatureViewedAction.failure(error))
  }
}

function* handleRARTracker(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(RealtyAPIs.RARTrackAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.pageTrackerAction.success(response))
    }
  } catch (error) {
    yield put(RealtyActions.pageTrackerAction.failure(error))
  }
}

function* handleHubspotTracking() {
  try {
    const { pathname, search } = root.location
    const parsedQuery = query.parse(search)
    const { source } = parsedQuery
    if (pathname && pathname.includes("/realty") && source) {
      yield put(RealtyActions.RARTrackerAction.request({
        payload: {
          source,
        },
      }))
    }
  } catch (error) {
    // silent fail
  }
}

function* handleCommunityUpcomingEvents() {
  try {
    const res = yield call(RealtyAPIs.commmunityEventListingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getCommunityUpcomingEventsForRARDboardAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getCommunityUpcomingEventsForRARDboardAction.failure(error))
  }
}

function* handleFetchQuotes() {
  try {
    const res = yield call(RealtyAPIs.getQuotesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getQuotesAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getQuotesAction.failure(error))
  }
}

function* handleFetchGetSupportAgents() {
  try {
    const res = yield call(RealtyAPIs.getAgentsForSupportAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getAgentSupportListAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getAgentSupportListAction.failure(error))
  }
}

function* handleFetchBrokerageSupport() {
  try {
    const res = yield call(RealtyAPIs.getBrokerageSupportAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getBrokerageSupportAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getBrokerageSupportAction.failure(error))
  }
}

function* handleSupportClick(action) {
  try {
    const {
      payload,
    } = action.data
    const notifyDisabled = cookie.load("support-notification")
    if (!notifyDisabled) {
      const res = yield call(RealtyAPIs.postSupportClickAPI, payload)
      if (isSuccess(res)) {
        cookie.save("support-notification", true, { expires: addMinutes(new Date(), 120) })
        const { response } = res.data
        yield put(RealtyActions.initiateSupportClickAction
          .success(response))
      }
    }
  } catch (error) {
    yield put(RealtyActions.initiateSupportClickAction.failure(error))
  }
}

function* handleUpgradeRequest() {
  try {
    const notifyDisabled = cookie.load("upgrade-notification")
    if (!notifyDisabled) {
      const res = yield call(RealtyAPIs.postUpgradeClickAPI)
      if (isSuccess(res)) {
        cookie.save("upgrade-notification", true, { expires: addMinutes(new Date(), 120) })
        const { response } = res.data
        yield put(RealtyActions.initiateUpgradeClickAction
          .success(response))
      }
    }
  } catch (error) {
    yield put(RealtyActions.initiateUpgradeClickAction.failure(error))
  }
}

function* handleTCClickRequest() {
  try {
    const res = yield call(RealtyAPIs.postTCClickAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.initiateTransactionCoordinationClickAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.initiateTransactionCoordinationClickAction.failure(error))
  }
}

function* handleGetCurrentPlanDetailsRequest() {
  try {
    const res = yield call(RealtyAPIs.getCurrentPlanDetailsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getCurrentPlanDetailsAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getCurrentPlanDetailsAction.failure(error))
  }
}

const createFilterMap = (data) => {
  const mapItem = []
  // console.log("===>", data.flatMap(item => {item.options}))
  if (data && data.length > 0) {
    data.forEach((item) => {
      if (item.options) {
        item.options.forEach((opt) => {
          mapItem.push({
            id: opt.id,
            isChecked: false,
          })
        })
      }
    })
  }

  return mapItem || []
}

function* handleFetchFiltersForResources() {
  try {
    const res = yield call(RealtyAPIs.getResourceFiltersAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      if (response) {
        yield put(RealtyActions.createFilterMapAction.call(createFilterMap(response.response)))
      }
      yield put(RealtyActions.fetchResourceFiltersAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.fetchResourceFiltersAction.failure(error))
  }
}

const getDocType = data => data.filter(item => item.isChecked).map(item => item.id)

function* handleFetchResourceDocuments(action) {
  try {
    const {
      limit,
      skip,
      searchText,
      updatedFilterMap,
      isInitialSearch,
    } = action.data

    const payload = {}

    payload.limit = limit || 10
    payload.skip = skip || 0
    payload.docType = updatedFilterMap && updatedFilterMap.length > 0 ? getDocType(updatedFilterMap) : []
    payload.searchText = searchText || ""

    yield put(RealtyActions.updateFilterMapAction.call(updatedFilterMap))
    // yield put(RealtyActions.toggleFilterComponentAction.call(false))

    const res = yield call(RealtyAPIs.fetchResourceDocumentsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isInitialSearch) {
        yield put(RealtyActions.toggleInitialSearchAction.call(true))
      } else {
        yield put(RealtyActions.toggleInitialSearchAction.call(false))
      }
      //currentResourceQuery
      yield put(RealtyActions.setCurrentFetchResourceSearchQueryAction.call(searchText))
      yield put(RealtyActions.setResourcesPaginationLimitAction.call(limit))
      yield put(RealtyActions.setResourcesPaginationSkipAction.call(skip || 0))
      yield put(RealtyActions.fetchResourceDocumentsAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.fetchResourceDocumentsAction.failure(error))
  }
}

function* handleRarResourcesRequestFetch() {
  try {
    const res = yield call(RealtyAPIs.getRequestsListAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getRarResourcesAction
        .success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getRarResourcesAction.failure(error))
  }
}

function* handleGetRoomsEvents() {
  try {
    const res = yield call(RealtyAPIs.getRoomsCalendarEventAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getRoomCalendarEventsAction.success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getRoomCalendarEventsAction.failure(error))
  }
}

function* handleGetAllInvitees(action) {
  try {
    const {
      skip,
      limit,
      history,
    } = action.data || {}
    yield delay(1200)
    const res = yield call(RealtyAPIs.getAllInviteesAPI, skip, limit)
    if (isSuccess(res)) {
      let existingInvitesFetched = yield select(state => state.realty.getAllInviteeDetailsResponse
      && state.realty.getAllInviteeDetailsResponse.data)

      if (!existingInvitesFetched || action.data.skip === 0) {
        existingInvitesFetched = {
          allInvitee: [],
        }
      }

      const response = {
        allInvitee: [...existingInvitesFetched.allInvitee, ...res.data.response.allInvitee],
      }
      const totalLength = yield select((state) => {
        const {
          recruitAndEarnV2Reducer,
        } = state || {}

        const {
          getReferralCumulativeDataResponse,
        } = recruitAndEarnV2Reducer || {}

        const {
          data,
        } = getReferralCumulativeDataResponse || {}

        if (data) {
          return data.totalInvitation
        }

        return 0
      })
      response.isDone = response.allInvitee.length === totalLength || 0

      if (response.allInvitee.length <= 0) {
        history.push("/realty/recruit-and-earn/onboarding")
      }

      yield put(RealtyActions.getAllInviteesAction.success(response))
    }
  } catch (error) {
    createToast("Oops! Something went wrong! Please try again", "error")
    yield put(RealtyActions.getAllInviteesAction.failure(error))
  }
}

function* handleGetCashbackProgram(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(RealtyAPIs.getCashbackProgramAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.getCashbackProgramAction.success(response))
    }
  } catch (error) {
    yield put(RealtyActions.getCashbackProgramAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(RealtyActions.submitNewsLetterAction.REQUEST, handleNewletter)
  yield takeLatest(RealtyActions.submitScheduleACallAction.REQUEST, handleSchduleACall)
  yield takeLatest(RealtyActions.pageTrackerAction.REQUEST, handlePageTracker)
  yield takeLatest(RealtyActions.brokerageFeatureViewedAction.REQUEST, handleBrokerageFeatureViewed)
  yield takeLatest(RealtyActions.RARTrackerAction.REQUEST, handleRARTracker)
  yield takeLatest(RealtyActions.URLBasedHubspotTrackingAction.type, handleHubspotTracking)
  yield takeLatest(RealtyActions.getCommunityUpcomingEventsForRARDboardAction.REQUEST, handleCommunityUpcomingEvents)
  yield takeLatest(RealtyActions.getQuotesAction.REQUEST, handleFetchQuotes)
  yield takeLatest(RealtyActions.getAgentSupportListAction.REQUEST, handleFetchGetSupportAgents)
  yield takeLatest(RealtyActions.getBrokerageSupportAction.REQUEST, handleFetchBrokerageSupport)
  yield takeLatest(RealtyActions.initiateSupportClickAction.REQUEST, handleSupportClick)
  yield takeLatest(RealtyActions.initiateUpgradeClickAction.REQUEST, handleUpgradeRequest)
  yield takeLatest(RealtyActions.initiateTransactionCoordinationClickAction.REQUEST, handleTCClickRequest)
  yield takeLatest(RealtyActions.getCurrentPlanDetailsAction.REQUEST, handleGetCurrentPlanDetailsRequest)
  yield takeLatest(RealtyActions.fetchResourceFiltersAction.REQUEST, handleFetchFiltersForResources)
  yield takeLatest(RealtyActions.fetchResourceDocumentsAction.REQUEST, handleFetchResourceDocuments)
  yield takeLatest(RealtyActions.getRarResourcesAction.REQUEST, handleRarResourcesRequestFetch)
  yield takeLatest(RealtyActions.getRoomCalendarEventsAction.REQUEST, handleGetRoomsEvents)
  yield takeLatest(RealtyActions.getAllInviteesAction.REQUEST, handleGetAllInvitees)
  yield takeLatest(RealtyActions.getCashbackProgramAction.REQUEST, handleGetCashbackProgram)
}
