import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const CrumbWrap = styled.button`
  display: flex;
  gap: 10px;
  cursor: pointer;
  border: none;
  background: transparent;
`

export const WriteAnOfferText = styled.div`
  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 15.052px */
    text-decoration-line: underline;

    span {
      transform: rotate(180deg);
    }
  }
`

export const ChevronWrap = styled.div`
  img {
    height: 16px;
    width: 16px;
  }
`

export const BuyerNameText = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 141%; /* 19.74px */
  }
`
