import React, { useState } from "react";
import {
  StyledButton,
  BottomPaneForMobile,
  MobileButton,
  WebContainer,
  StyledContactButton,
  OrWrap,
} from "./styles";
import RequestTourModal from "../RequestTourModal/index";
import ContactAgentModal from "../ContactAgentModal/index";

const index = ({ listingPageResponse }) => {
  const [openModal, setopenModal] = useState(false);
  const [openContactModal, setopenContactModal] = useState(false);

  return (
    <>
      {openModal && (
        <RequestTourModal
          openModal={openModal}
          onClose={() => setopenModal(false)}
          listingPageResponse={listingPageResponse}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        />
      )}
      {openContactModal && (
        <ContactAgentModal
          openModal={openContactModal}
          onClose={() => setopenContactModal(false)}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        ></ContactAgentModal>
      )}

      <BottomPaneForMobile>
        {/* <MobileButton
          style={{
            backgroundColor: "#5A5FF2",
            color: "white",
            marginRight: "4px",
          }}
          onClick={() => setopenModal(true)}
        >
          Request a tour
        </MobileButton> */}
        <MobileButton
          style={{ border: "1px solid #E0E0EB", marginLeft: "4px" }}
          onClick={() => setopenContactModal(true)}
        >
          Contact agent
        </MobileButton>
      </BottomPaneForMobile>

      <WebContainer>
        {/* <StyledButton onClick={() => setopenModal(true)}>
          Request a tour
        </StyledButton> */}
        <OrWrap>
          <hr style={{ width: "45%", margin: "0", color: "#E0E0EB" }}></hr>
          <p style={{ margin: "0 5px" }}>OR</p>
          <hr style={{ width: "45%", margin: "0", color: "#E0E0EB" }}></hr>
        </OrWrap>
        <StyledContactButton onClick={() => setopenContactModal(true)}>
          Contact agent
        </StyledContactButton>
      </WebContainer>
    </>
  );
};

export default index;
