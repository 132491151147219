import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import {
  fetchClientProfileAction,
  fetchClientUpdatesAction,
  editClientInfoAction,
  uploadClientImageAction,
  fetchClientPropertyListingAction,
  fetchClientTimelineAction,
  setNotificationPreferenceAction,
  updateArchivedLeadAction,
  addISAClientAction,
  addClientNoteAction,
  updateClientNoteAction,
  fetchClientNotesAction,
  OPEN_EDIT_PROFILE,
  CLOSE_EDIT_PROFILE,
  ARCHIVE_REFERRAL,
  WAIT_FOR_CLIENT_UPDATE,
  IS_CHECKLIST_UPDATE,
  OPEN_ISA_ADD_CLIENT_MODAL,
  CLOSE_ISA_ADD_CLIENT_MODAL,
  convertToCRMClientAction,
  fetchCallStatusAction,
  toggleCallFeedbackModalAction,
  submitCallFeedbackAction,
  userConfirmationModalAction,
  userConfirmAction,
} from "./actions"

const initState = {
  isFetchingClient: true,
  isUploadingImage: false,
  isFetchingClientTimeline: true,
  isSettingNotification: false,
  isFetchingUpdates: false,
  isSettingReminder: false,
  isUpdatingClientInfo: false,
  isEditProfileOpen: false,
  isLoadingProperties: true,
  isReferralCardBusy: false,
  isChecklistUpdating: false,
  isUpdatingArchivedLead: false,
  isOpenCallFeedback: false,
  isAddingISAClient: {
    request: false,
    error: false,
  },
  isISAAddClientModalOpened: false,
  isConvertClient: false,
  confirmationModalMessage: null,
  isUserConfirmModalOpen: false,
  ...createDeltaReducer("callStatus"),
  ...createDeltaReducer("submitCallStatus"),
}

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case fetchClientProfileAction.REQUEST: {
      return {
        ...state,
        isFetchingClient: true,
        clientResponse: null,
      }
    }
    case fetchClientProfileAction.FAILURE: {
      const { data: { error } } = action.data
      return {
        ...state,
        isFetchingClient: false,
        isFetchingClientError: true,
        clientResponse: error,
      }
    }
    case fetchClientProfileAction.SUCCESS: {
      const { data } = action.data || {}
      return {
        ...state,
        isFetchingClient: false,
        clientResponse: {
          ...data.response,
        },
      }
    }

    case updateArchivedLeadAction.REQUEST: {
      return {
        ...state,
        isUpdatingArchivedLead: true,
      }
    }

    case updateArchivedLeadAction.SUCCESS:
    case updateArchivedLeadAction.FAILURE: {
      return {
        ...state,
        isUpdatingArchivedLead: false,
        isReferralCardBusy: false,
      }
    }

    case uploadClientImageAction.REQUEST: {
      return {
        ...state,
        isUploadingImage: true,
      }
    }
    case ARCHIVE_REFERRAL: {
      const { referralId } = action.data
      return {
        ...state,
        isReferralCardBusy: referralId || true,
      }
    }

    // setNotificationPreferenceAction START
    case setNotificationPreferenceAction.REQUEST: {
      const { type } = action.data
      return {
        ...state,
        isSettingNotification: type,
      }
    }

    case setNotificationPreferenceAction.SUCCESS: {
      return {
        ...state,
        isSettingNotification: false,
        clientResponse: {
          ...action.data,
        },
      }
    }

    // setNotificationPreferenceAction END
    case fetchClientUpdatesAction.REQUEST: {
      return {
        ...state,
        isFetchingUpdates: true,
      }
    }

    case fetchClientUpdatesAction.SUCCESS: {
      return {
        ...state,
        isFetchingUpdates: false,
        updates: action.data,
      }
    }
    case uploadClientImageAction.SUCCESS:
    case uploadClientImageAction.FAILURE: {
      return {
        ...state,
        isUploadingImage: false,
      }
    }

    case fetchClientPropertyListingAction.REQUEST: {
      return {
        ...state,
        isLoadingProperties: true,
      }
    }
    case fetchClientPropertyListingAction.SUCCESS:
    case fetchClientPropertyListingAction.FAILURE: {
      return {
        ...state,
        isLoadingProperties: false,
        properties: action.data,
      }
    }

    // Timeline actions START --------
    case fetchClientTimelineAction.REQUEST: {
      return {
        ...state,
        isFetchingClientTimeline: true,
      }
    }

    case fetchClientTimelineAction.FAILURE:
    case fetchClientTimelineAction.SUCCESS: {
      return {
        ...state,
        isFetchingClientTimeline: false,
        clientTimeline: action.data || false,
      }
    }

    // Timeline actions ENDS --------
    case editClientInfoAction.SUCCESS:
    case editClientInfoAction.FAILURE: {
      return {
        ...state,
        isUpdatingClientInfo: false,
      }
    }
    case editClientInfoAction.REQUEST: {
      return {
        ...state,
        isUpdatingClientInfo: true,
      }
    }

    case addISAClientAction.REQUEST: {
      return {
        ...state,
        isAddingISAClient: {
          ...state.isAddingISAClient,
          request: true,
          message: false,
          error: false,
        },
      }
    }

    case addISAClientAction.SUCCESS: {
      return {
        ...state,
        isAddingISAClient: {
          ...state.isAddingISAClient,
          request: false,
          error: false,
        },
      }
    }

    case addISAClientAction.FAILURE: {
      const { message } = action.data
      return {
        ...state,
        isAddingISAClient: {
          ...state.isAddingISAClient,
          request: false,
          error: message,
        },
      }
    }

    case convertToCRMClientAction.REQUEST: {
      const { clientId } = action.data
      return {
        ...state,
        isConvertClient: true,
        clientId,
      }
    }

    case convertToCRMClientAction.SUCCESS:
    case convertToCRMClientAction.FAILURE: {
      return {
        ...state,
        isConvertClient: false,
      }
    }

    case fetchCallStatusAction.REQUEST:
    case fetchCallStatusAction.SUCCESS:
    case fetchCallStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, fetchCallStatusAction, "callStatus"),
      }
    }

    case submitCallFeedbackAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, submitCallFeedbackAction, "submitCallStatus"),
      }
    }
    case submitCallFeedbackAction.SUCCESS:
    case submitCallFeedbackAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, submitCallFeedbackAction, "submitCallStatus"),
        isOpenCallFeedback: false,
      }
    }

    case OPEN_EDIT_PROFILE: {
      return {
        ...state,
        isEditProfileOpen: true,
      }
    }
    case CLOSE_EDIT_PROFILE: {
      return {
        ...state,
        isEditProfileOpen: false,
      }
    }
    case WAIT_FOR_CLIENT_UPDATE: {
      return {
        ...state,
        isFetchingClient: action.data,
      }
    }
    case IS_CHECKLIST_UPDATE: {
      return {
        ...state,
        isChecklistUpdating: action.data,
      }
    }
    case OPEN_ISA_ADD_CLIENT_MODAL: {
      return {
        ...state,
        isISAAddClientModalOpened: true,
        isAddingISAClient: {
          ...state.isAddingISAClient,
          error: false,
        },
      }
    }
    case CLOSE_ISA_ADD_CLIENT_MODAL: {
      return {
        ...state,
        isISAAddClientModalOpened: false,
      }
    }

    case toggleCallFeedbackModalAction.type: {
      return {
        ...state,
        isOpenCallFeedback: action.data,
      }
    }

    case userConfirmationModalAction.type: {
      const { question, isShowModal } = action.data
      return {
        ...state,
        isUserConfirmModalOpen: isShowModal,
        confirmationModalMessage: question
      }
    }

    case userConfirmAction.type: {
      return {
        ...state,
        confirmationModalMessage: null,
      }
    }
    default:
      return state
  }
}
