import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import Button from "@ui/Button"
import icons from "@icons"
import colors from "@colors"
import { isMobile } from "services/Utils"
import ArrowIcon from "images/Graphics/RadiusGlobalBanner/ArrowElec.svg"
import { mobile, preTabletWidth, preDesktopWidth } from "@consts"
import { ATC } from "@fonts"

const Wrap = styled.div`
   background: ${colors.black};
   position: relative;
   transition-duration: 0.5s;
   overflow: hidden;
   height: 0px;
   padding: 0px;
   display: flex;
   align-items: baseline;
   justify-content: space-between;

   ${props => props.isOpenPressReleaseBanner && `
      padding: 10px 20px 10px 20px;
      height: 56px;
   `}
`

const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px;
    color: ${colors.white};
    font-size: 14px;
    font-weight: 300;
    margin-right: 20px;

    strong {
      font-weight: 500;
      font-size: 16px;
      ${mobile(`
        font-size: 14px;
      `)}
    }
  }
`

const ReadMoreBTN = styled(Button)`
  border-radius: 4px;
  background: ${colors.white};
  color: ${colors.contentText};
  width: auto;
  font-size: 12px;
  padding: 6px 12px;
`

const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 12px;
  color: ${colors.white};
  ${mobile`
    top: 10px;
    right: 10px;
  `}
`
const HideOnMobile = styled.span`
  ${mobile(`
    display: none;
  `)}
`

const Elevate = styled.p`
  font-family: ${ATC};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: left;
  color: white;

  ${preDesktopWidth`
   font-size: 14px;
  `}
  ${preTabletWidth`
    font-size: 9px;
    display: none;
  `}
`

const InnerDiv = styled.div`
  display: flex;
  align-items: baseline;
  ${props => props.gap && `
    gap: ${props.gap};
  `};
  padding: 4px;

  img {
    height: 14.4865140914917px;
    width: 29.853179931640625px;
    border-radius: 0px;

    ${preTabletWidth`
      display: none;
    `}
  }
`

const Date = styled.p`
  font-family: ${ATC};
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.white};

  ${preDesktopWidth`
   font-size: 12px;
  `}

  ${preTabletWidth`
    font-size: 10px;
    display: none;
  `}
`

const CTA = styled.a`
  display: block;
  text-decoration: none;
  height: 37px;
  width: 208px;
  padding: 8px 14px 8px 14px;
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  color: ${colors.black};
  border: none;
  position: relative;
  top: -4px;
  background: white;

  ${preDesktopWidth`
  font-size: 13px;
 `}

 ${preTabletWidth`
   font-size: 10px;
   width: max-content;
 `}
`

const Close = styled.button`
  display: block;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: DM Sans, sans-serif;
  color: #ffffff82;
  position: relative;
  top: -4px;
  left: 17px;
`

const MobileElevate = styled.p`
  display: none;
  font-family: ${ATC};
  font-size: 11px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: left;
  color: white;

  ${preTabletWidth`
    display: block;
  `}
`

const MobileDiv = styled.div`
  display: none;
  ${preTabletWidth`
    display: flex;
    flex-direction: column;
    position: relative;
    top: -13px;
  `}

`

const PressReleaseBanner = ({
  isOpenPressReleaseBanner,
  togglePressReleaseBanner,
}) => (
  <Wrap
    isOpenPressReleaseBanner={isOpenPressReleaseBanner}
  >
    <InnerDiv gap="20px">
      <img src={ArrowIcon} alt="arrow.png" />
      <Elevate>ELEVATE! RADIUS SPRING SUMMIT</Elevate>
      <MobileDiv>
        <MobileElevate>RADIUS SPRING SUMMIT</MobileElevate>
        <MobileElevate
          style={{
            margin: "0px",
            position: "relative",
            top: "-16px",
          }}
        >
          April 12, 2022 | 9AM PT
        </MobileElevate>
      </MobileDiv>
    </InnerDiv>
    <InnerDiv>
      <Date>April 12, 2022 |&nbsp;</Date>
      <Date>9AM PT</Date>
    </InnerDiv>
    <InnerDiv gap="5px">
      <CTA href="https://apply.radiusagent.com/radius-spring-summit-2022-elevate" target="_blank">
        Register for Free
      </CTA>
      {/*
        <Close onClick={() => togglePressReleaseBanner(false)}>x</Close>
      */}
    </InnerDiv>
  </Wrap>
)

export default withRouter(PressReleaseBanner)
