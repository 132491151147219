import React from "react"
import root from "window-or-global"
import Loadable from "react-loadable"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import queryString from "query-string"
import { Route, withRouter } from "react-router-dom"
import ScrollToTop from "@ui/ScrollToTop"
import { authCheck } from "services/auth"
import container from "container/App/headerLayoutContainer"
import RadiusBanners from "dumbComponents/common/RadiusBanners"
import Navbar from "dumbComponents/common/Navbar"
import { UTMExtract } from "services/Utils"

// const Navbar = Loadable({
//   loader: () => import("dumbComponents/common/Navbar/index.js"),
//   loading: <div></div>,
// })

// const RadiusBanners = Loadable({
//   loader: () => import("dumbComponents/common/RadiusBanners/index.js"),
//   loading: <></>,
// })

const LayoutWrap = styled.div`
  padding-top: 70px;
  transition-duration: 0.3s;
  ${props => props.isIosBrowser && `
    padding-top: 0px;
  `}
`

const handleLogout = () => {
  // this will failed on SSR
  try {
    root.location.href = `${RADIUS_URL}/signup`
  } catch (e) {
    // ignore error
  }
}

const Layout = ({
  children,
  location,
  globalPaddingTop,
  headerVersion,
  vwoVariation,
}) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
  const isRadiusHomes = parsedQuery && parsedQuery.navbar === "hide"

  return (
    <LayoutWrap
      isIosBrowser={isIosBrowser || isRadiusHomes}
      style={{ paddingTop: isIosBrowser || isRadiusHomes ? 0 : globalPaddingTop }}
    >
      {/* {!isIosBrowser && !isRadiusHomes && (
        <RadiusBanners />
      )} */}
      <ScrollToTop />
      {!isIosBrowser && !isRadiusHomes && (
        <Navbar vwoVariation={vwoVariation} location={location} headerVersion={headerVersion} />
      )}
      {children}
    </LayoutWrap>
  )
}

const HeaderRoute = ({
  component: Component,
  location,
  requireAuth,
  isOnlyPublic,
  globalPaddingTop,
  history,
  headerVersion,
  vwoVariation,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const isLogged = authCheck()
      if (requireAuth) {
        if (isLogged) {
          return (
            <Layout
              style={{ paddingTop: globalPaddingTop }}
              vwoVariation={vwoVariation}
              location={location}
              globalPaddingTop={globalPaddingTop}
              headerVersion={headerVersion}
            >
              <Component vwoVariation={vwoVariation} {...props} />
            </Layout>
          )
        }
        handleLogout()
      }
      if (isOnlyPublic && isLogged) {
        const redirectParams = UTMExtract()
        if (redirectParams) {
          history.push(`/community/?${redirectParams}`)
        } else {
          history.push("/community")
        }
      }
      return (
        <Layout
          style={{ paddingTop: globalPaddingTop }}
          location={location}
          vwoVariation={vwoVariation}
          globalPaddingTop={globalPaddingTop}
          headerVersion={headerVersion}
        >
          <Component vwoVariation={vwoVariation} {...props} />
        </Layout>
      )
    }}
  />
)

export default container(withRouter(HeaderRoute))
