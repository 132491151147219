/* eslint-disable max-len */
import React from "react"

const ActiveMic = () => (
  <svg width="15" height="22" viewBox="0 0 15 22" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M4.23807 10.5261C4.23807 12.7479 5.6678 14.3129 7.75442 14.3129C9.83138 14.3129 11.2611 12.7479 11.2611 10.5261V4.44974C11.2611 2.21821 9.83138 0.662903 7.75442 0.662903C5.6678 0.662903 4.23807 2.21821 4.23807 4.44974V10.5261ZM5.69678 10.5261V4.44974C5.69678 3.02002 6.5179 2.10229 7.75442 2.10229C8.99094 2.10229 9.8024 3.02002 9.8024 4.44974V10.5261C9.8024 11.9558 8.99094 12.8735 7.75442 12.8735C6.5179 12.8735 5.69678 11.9558 5.69678 10.5261ZM0.837646 10.8738C0.837646 14.6607 3.33966 17.3173 7.02024 17.6264V19.8772H3.43627C3.03053 19.8772 2.70208 20.2057 2.70208 20.6114C2.70208 21.0172 3.03053 21.3359 3.43627 21.3359H12.0629C12.4686 21.3359 12.7971 21.0172 12.7971 20.6114C12.7971 20.2057 12.4686 19.8772 12.0629 19.8772H8.47894V17.6264C12.1692 17.3173 14.6615 14.6607 14.6615 10.8738V8.9128C14.6615 8.50706 14.3427 8.18828 13.937 8.18828C13.5313 8.18828 13.2028 8.50706 13.2028 8.9128V10.8159C13.2028 14.11 11.0582 16.2933 7.75442 16.2933C4.44094 16.2933 2.29635 14.11 2.29635 10.8159V8.9128C2.29635 8.50706 1.97756 8.18828 1.56217 8.18828C1.15644 8.18828 0.837646 8.50706 0.837646 8.9128V10.8738Z"
      fill="#303030"
    />
  </svg>
)

export default ActiveMic
