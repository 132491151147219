import React from "react"
import { CheckedIcon } from "dumbComponents/common/Icons"
import { Wrap } from "./style"

const ValidateNumber = ({ value }) => (
  <>
    {(!value || value === null || value === undefined) && value !== 0 ? (
      <></>
    ) : (
      <>
        {value <= 0 ? (
          <CheckedIcon />
        ) : (
          <Wrap style={{ borderRadius: 100, background: "#F08068" }}>
            {value}
          </Wrap>
        )}
      </>
    )}
  </>
)

export default ValidateNumber
