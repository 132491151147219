import React from "react"
import styled from "styled-components"
import colors from "@colors"
import root, { console } from "window-or-global"
import BlankImage from "images/Graphics/TeamProfile/BlankImage.svg"
import Box from "@ui/BuilderComponents/BoxV2/index"
import Paragraph from "@ui/BuilderComponents/Paragraph/index"
import PropTypes from "prop-types"
import { ATC } from "@fonts"

const StyledImg = styled.img`
  height: 75px;
  width: 75px;
  border-radius: 100px;
`

const ShortPara = styled.b`
  font-family: ${ATC};
  font-weight: 200;
  color: ${colors.berryRed};
`

const MemberElement = ({
  name,
  position,
  photo,
  profileUrl,
  isSubscribedToBrokerPlan,
}) => (
  <Box
    displayStyle="flex"
    flexGap="10px"
    width="351px"
    alignItems="self-start"
    customDivStyling={{
      cursor: profileUrl ? "pointer" : "auto",
    }}
    onClick={() => {
      if (profileUrl) {
        root.open(`/profile/${profileUrl}`)
      }
    }}
  >
    <StyledImg src={photo || BlankImage} />
    <Box
      displayStyle="flex"
      width="264px"
      flexDirection="column"
      customDivStyling={{
        paddingTop: "9px",
      }}
    >
      <Paragraph
        paraFamily={ATC}
        minTabletParaSize="16px"
        preDesktopParaSize="16px"
        minLargeDesktopParaSize="16px"
        minDesktopParaSize="16px"
        paraWeight="300"
      >
        {name}
      </Paragraph>
      <Paragraph
        paraFamily={ATC}
        minTabletParaSize="12px"
        preDesktopParaSize="12px"
        minLargeDesktopParaSize="12px"
        minDesktopParaSize="12px"
        paraWeight="300"
      >
        {position}
        <ShortPara>{!isSubscribedToBrokerPlan ? " (Not Subscribed)" : ""}</ShortPara>
      </Paragraph>
    </Box>
  </Box>
)

MemberElement.propTypes = {
  profileUrl: PropTypes.string.isRequired,
}

export default MemberElement
