import React from "react"
import styled from "styled-components"
import Select from "react-select"
import addIcon from "images/addicon.png"
import Box from "@ui/Box"
import colors from "@colors"

const Wrap = styled.div`
  min-height: 125px;
  position: relative;
  top: 20px;
  border-top: 1px solid ${colors.black};
  padding-top: 12px;
  right: 30px;

`

const OptionsWrap = styled.div`
  display: ${props => props.hide ? "none" : "flex"};
  flex-direction: column;
  width: 50%;
  position: relative;
  top: 13px;
  ${props => props.blu && `
    position: relative;
    top: 24px;
   `}
`
const AddRTLButton = styled.button`
  background: none;
  border: none;
  position: relative;
  bottom: 20px;
  right: 67px;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: ${colors.primaryColor};
  cursor: pointer;
`
const AddImg = styled.img`
 height: 20px;
 width: 20px;
`

const OptionHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.67;
  color: ${colors.deepSeaBlue};
`

const OptionHeaderText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.black};
`

const RemovePlanCTA = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  color: red;
  position: relative;
  left: 10px;
  text-decoration: underline;
  cursor: pointer;
`

const THEME = theme => (
  {
    ...theme,
    border: "none",
    container: {
      ...theme.container,
    },
  }
)

class YourPlans extends React.Component {
  state = {
    hideRTL: true,
    hideBLU: true,
    hideRTLRemoveButton: true,
    hideBLURemoveButton: true,
  }

  toggleDropDown = (type) => {
    if (type === "blu") {
      this.setState({
        hideBLU: false,
      })
    } else {
      this.setState({
        hideRTL: false,
      })
    }
  }

  handleChange = (planDetail, cart, planType) => {
    const {
      addPlanToCart,
      removePlanFromCart,
      isCartHavingTeamPlan,
    } = this.props
    const {
      id: plan,
      type,
      isTeam,
    } = planDetail.value
    const cartHasTeamPlan = isTeam || type === "team" || (isCartHavingTeamPlan && planType === "blu")
    removePlanFromCart({
      product: planType,
    })
    addPlanToCart({
      product: planType,
      plan,
      type,
      cartHasTeamPlan,
    })
    if (planType === "rtl") {
      this.setState({
        hideRTLRemoveButton: false,
      })
    } else {
      this.setState({
        hideBLURemoveButton: false,
      })
    }
  }

  handleRemove = (type) => {
    const {
      removePlanFromCart,
    } = this.props
    if (type === "blu") {
      this.setState({
        hideBLU: true,
      })
    } else {
      this.setState({
        hideRTL: true,
      })
    }
    removePlanFromCart({
      product: type,
    })
  }

  getDefaultValueForDropDown = (type) => {
    const {
      cart,
      pricingPlansMap,
    } = this.props

    if (cart && cart[type] !== null) {
      const duration = cart[type].indexOf("yearly") > -1 ? 12 : pricingPlansMap[cart[type]].duration
      const planTier = pricingPlansMap[cart[type]].tier
      let dropdownString = `${planTier} ${duration} ${duration > 1 ? "months" : "month"} plan`
      if (type === "blu") {
        dropdownString = `${planTier} A La Carte`
      }
      return [
        {
          value: dropdownString,
          label: dropdownString,
        }]
    }

    return []
  }

  getValues = (data, key) => {
    const { pricingPlansMap } = this.props
    const getLabel = (val) => {
      const duration = pricingPlansMap[val.id].description
      if (pricingPlansMap[val.id].product === "blu") {
        return `${val.plan_tier} A La Carte (Per ${pricingPlansMap[val.id].quota} leads)`
      }
      return `${val.plan_tier} ${duration}`
    }

    const getData = id => pricingPlansMap[id]

    if (data && key) {
      const plansArray = []
      data[key].forEach((plan) => {
        plansArray.push({
          label: getLabel(plan),
          value: getData(plan.id),
        })
      })

      return plansArray.reverse()
    }
    return [{ label: "Loading..", value: "Loading.." }]
  }

  render() {
    const {
      data,
      cart,
      isCartHavingTeamPlan,
    } = this.props

    const {
      hideRTL,
      hideBLU,
      hideRTLRemoveButton,
      hideBLURemoveButton,
    } = this.state
    return (
      <Wrap>
        {
          (cart && !cart.rtl && hideRTL) ? (
            <OptionsWrap>
              <AddImg src={addIcon} alt="addIcon.png" />
              <AddRTLButton
                onClick={() => this.toggleDropDown("rtl")}
              >
               Add Real Time Lead
              </AddRTLButton>
            </OptionsWrap>
          ) : (
            <OptionsWrap>
              <OptionHeader>
                <OptionHeaderText>Real Time Lead</OptionHeaderText>
                <SelectText>TYPE, LEVEL AND COMMITMENT</SelectText>
              </OptionHeader>
              <Box d="flex">
                <Box flex="1">
                  <Select
                    defaultValue={this.getDefaultValueForDropDown("rtl")}
                    options={this.getValues(data, "rtl")}
                    theme={THEME}
                    onChange={e => this.handleChange(e, cart, "rtl")}
                  />
                </Box>
                {!hideRTLRemoveButton && (
                  <RemovePlanCTA
                    onClick={() => this.handleRemove("rtl")}
                  >
                    Remove
                  </RemovePlanCTA>
                )}
              </Box>
            </OptionsWrap>
          )
        }

        {
          (cart && !cart.blu && hideBLU) ? (
            <OptionsWrap
              hide={isCartHavingTeamPlan}
              blu
            >
              <AddImg src={addIcon} alt="addIcon.png" />
              <AddRTLButton
                onClick={() => this.toggleDropDown("blu")}
              >
               Add Bulk Lead Upload
              </AddRTLButton>
            </OptionsWrap>
          ) : (
            <OptionsWrap
              blu
              hide={isCartHavingTeamPlan}
            >
              <OptionHeader>
                <OptionHeaderText>Bulk Lead Upload</OptionHeaderText>
                <SelectText>TYPE, LEVEL AND COMMITMENT</SelectText>
                <Box d="flex">
                  <Box flex="1">
                    <Select
                      defaultValue={this.getDefaultValueForDropDown("blu")}
                      options={this.getValues(data, "blu")}
                      theme={THEME}
                      onChange={e => this.handleChange(e, cart, "blu")}
                    />
                  </Box>
                  {!hideBLURemoveButton && (
                    <RemovePlanCTA
                      onClick={() => this.handleRemove("blu")}
                    >
                      Remove
                    </RemovePlanCTA>
                  )}
                </Box>

              </OptionHeader>

            </OptionsWrap>
          )
        }
      </Wrap>
    )
  }
}

export default YourPlans
