import React, { useRef, useState, useEffect } from "react"
import { Form } from "@ui/antd"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
  HiddenInputField,
} from "dumbComponents/common/InputFields"
import { AddButton } from "dumbComponents/OffersV3/components"
import {
  TYPE_OF_OWNERSHIP,
  CLIENT_SOURCE,
} from "dumbComponents/OffersV2/consts"
import { normalizePhone } from "services/formUtils"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
  CoAgentWrap,
} from "./commonStyles"
// import {
//   InputField,
//   InputNumber,
//   SelectField,
//   InputLocation,
//   HiddenInputField,
// } from "../../InputFields"
import { BlueButton, DangerButton } from "../../../../commonStyles"

const { List } = Form

const BuyerForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteBuyer,
  form,
  additionalData,
  handleOnTypeChangeBuyerInfo,
}) => {
  const formRef = useRef(null)
  let testSource = false
  const [showOtherSource, setShowOtherSource] = useState(false)
  const [typeOfOwnership, setOwnership] = useState("individual")
  const [coBuyertypeOfOwnership, coBuyersetOwnership] = useState("individual") // do indexing later shrungi
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData[0],
        client_id: additionalData.client_id,
        req_id: additionalData.req_id,
        transaction_id: additionalData.transaction_id,
      }
      if (initValues.source === "other") {
        testSource = true
      }

      initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        const sliced = prefilledData.slice(1)
        initValues.co_buyer_details = sliced
      }
    }

    return initValues
  }


 useEffect(() => {
  if (prefilledData[0]?.type_of_ownership) {
    setOwnership(prefilledData[0].type_of_ownership)
  }
}, [prefilledData])


  const handleClientSourceChange = (value) => {
    if (value === "other") {
      setShowOtherSource(true)
    } else {
      setShowOtherSource(false)
      testSource = false
    }
  }

  const handleOwnershipchange = (value) => {
    setOwnership(value)
    formRef.current.setFieldsValue({
        ownership_name  : '',
        })
  };

  const getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Full Name of Attorney-in-fact"
    }
    return "Full Name"
  }

  const handleOwnershipchangeCoBuyer = (value) => {
    coBuyersetOwnership(value)
  };

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Buyer Form"}</p>
        </FormTitle>
      </HeaderWrap>

      <Form
        name="offers_buyer_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        form={form}
        onValuesChange={handleOnTypeChangeBuyerInfo}
      >

        <HiddenInputField name="client_id" />
        <HiddenInputField name="transaction_id" />
        <HiddenInputField name="req_id" />

        <FormFieldWrap>
          <InputField
            name="firstname"
            inputType="text"
            placeholder="First Name"
            required
            text="First Name"
            required="true"
          />

          <InputField
            name="lastname"
            inputType="text"
            placeholder="Last Name"
            required
            text="Last Name"
            required="true"
          />
        </FormFieldWrap>

        {/* <InputField
          name="buyer_client_source"
          inputType="text"
          placeholder="Client Source*"
        /> */}

        <FormFieldWrap>
          <InputField
            name="email"
            inputType="email"
            type="email"
            placeholder="Email"
            text="Email"
            required="true"
          />
          <InputNumber
            name="phone"
            inputType="phone"
            type="phone"
            placeholder="Phone"
            text="Phone"
            //required="true"
          />
        </FormFieldWrap>

        {/**Requires Google Address*/}
        {/* <InputField
          name="buyer_street_address"
          inputType="text"
          type="text"
          placeholder="Street Address*"
        /> */}

        <FormFieldWrap>
          <InputLocation
            name="address"
            inputType="text"
            type="text"
            placeholder="Street Address"
            formRef={formRef}
            text="Street Address"
          />

          <InputField
            name="apt"
            inputType="text"
            placeholder="Apt / Unit / Suite No."
            text="Apt/Unit/Suite No."
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="city"
            inputType="text"
            placeholder="City"
            text="City"
          
          />

          <InputField
            name="state"
            inputType="text"
            placeholder="State"
            text="State"
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="zipcode"
            inputType="text"
            placeholder="Zip Code"
            text="Zip Code"
          />
          <SelectField
            isFormField
            name="type_of_ownership"
            options={TYPE_OF_OWNERSHIP}
            placeholder="Type of Ownership"
            text="Type Of Ownership"
            required="true"
            onChange={handleOwnershipchange}
            requiredMessage="Required"
          />
        </FormFieldWrap>
        {typeOfOwnership !== "individual" && (
          <FormFieldWrap>
            <InputField
              name="ownership_name"
              inputType="text"
              placeholder={getFieldName(typeOfOwnership)}
              text={getFieldName(typeOfOwnership)}
            />
          </FormFieldWrap>
        )}
        <FormFieldWrap>
          <SelectField
            isFormField
            name="source"
            options={CLIENT_SOURCE}
            defaultValue={prefilledData ? prefilledData.source : null}
            type="text"
            placeholder="Client Source"
            text="Client Source"
            required="true"
            onChange={handleClientSourceChange}
            requiredMessage="Required"
          />
          {(testSource || showOtherSource) && (
            <InputField
              name="other_source"
              inputType="text"
              placeholder="Other Source"
              text="Other Source"
              required="true"
            />
          )}
        </FormFieldWrap>

        <List name="co_buyer_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => (
                <CoAgentWrap>
                  <HeaderWrap>
                    <FormTitle>
                      <p>Co-Buyer Information</p>
                    </FormTitle>
                    <CancelOrGoBackButton
                      onClick={() => {
                        remove(name)
                      }}
                    >
                      <img
                        src={`${CLOUDFRONT}/OFFERS_V3/Offers_v3_asset_icon.svg`}
                        alt="minus"
                        style={{ marginTop: "1px" }}
                      />
                      <p> Remove </p>
                    </CancelOrGoBackButton>
                  </HeaderWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "firstname"]}
                      inputType="text"
                      placeholder="First Name*"
                      text="First Name"
                      required="true"
                    />

                    <InputField
                      name={[name, "lastname"]}
                      inputType="text"
                      placeholder="Last Name"
                      customRules={{}}
                      text="Last Name"
                      required="true"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "email"]}
                      inputType="email"
                      type="email"
                      placeholder="Email"
                      text="Email"
                      required="true"
                    />

                    <InputNumber
                      name={[name, "phone"]}
                      inputType="phone"
                      type="phone"
                      placeholder="Phone"
                      text="Phone"
                      //required="true"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputLocation
                      name={[name, "address"]}
                      inputType="text"
                      type="text"
                      placeholder="Street Address"
                      fieldIndex={name}
                      formRef={formRef}
                      formName="co_buyer_details"
                      text="Street Address"
                    />

                    <InputField
                      name={[name, "apt"]}
                      inputType="text"
                      type="text"
                      placeholder="Apartment/Unit/Suite No."
                      text="Apartment/Unit/Suite No."
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "city"]}
                      inputType="text"
                      type="text"
                      placeholder="City"
                      text="City"
                    />

                    <InputField
                      name={[name, "state"]}
                      inputType="text"
                      type="text"
                      placeholder="State"
                      text="State"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "zipcode"]}
                      inputType="text"
                      type="text"
                      placeholder="Zipcode"
                      text="Zipcode"
                    />

                    <SelectField
                      isFormField
                      name={[name, "type_of_ownership"]}
                      options={TYPE_OF_OWNERSHIP}
                      defaultValue={
                        prefilledData ? prefilledData.type_of_ownership : null
                      }
                      placeholder="Type of Ownership"
                      text="Type of Ownership"
                      onChange={handleOwnershipchangeCoBuyer}
                      required="true"
                    />
                  </FormFieldWrap>
                  {coBuyertypeOfOwnership !== "individual" && (
                    <FormFieldWrap>
                      <InputField
                        name={[name,"ownership_name"]}
                        inputType="text"
                        placeholder={getFieldName(coBuyertypeOfOwnership)}
                        text={getFieldName(coBuyertypeOfOwnership)}
                      />
                    </FormFieldWrap>
                  )}
                </CoAgentWrap>
              ))}
              <FormFieldWrap>
                <CTAWrap>
                  <AddButton name="ADD A CO-BUYER" onClick={() => add()} />
                </CTAWrap>
              </FormFieldWrap>
            </>
          )}
        </List>

        <CTAWrap></CTAWrap>
      </Form>
    </FormWrap>
  )
};

export default BuyerForm
