import React from "react"
import styled from "styled-components"
import { handleDataLayerPush } from "services/dataLayerUtil"
import {
  TitleText,
  GreyCard,
  CardsWrap,
  FlexAnimContainer,
  ImageWrap,
} from "../commonStyles"
import { SubText } from "../../commonStyles"
import LottieMachine from "../AnimationsJSON/LottieMachine"
import { Approval } from "../AnimationsJSON/index"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  width: 100%;
  max-width: 503px;

   ${props => props.sticky && `
    display: none;
    @media(max-width: 1099px) {
      display: block;
      overflow: hidden;
    }
  `}

  .mortgage {

    @media(min-width: 400px) {
      max-width: 393px !important;
    }

    @media(min-width: 1099px){
      max-width: unset;
    }

    @media(max-width: 399px) {
      width: 100%;
    }
  }
`

const GreenTextWrap = styled.div``

const SmallText = styled.p`
  color: #686868;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.023%;
`

const AnchorLink = styled.a`
  text-decoration: none;
`

const GreenTextMortgage = styled.p`
  display: flex;
  gap: 10px;

  color: #306F4D;
  font-family: ${INTER};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;

  span img {
    width: 30px;
    height: 30px;
  }
`

const TextWrap = styled.div``

const DetailCardTwo = () => (
  <FlexAnimContainer two>
    <Wrap>
      <TextWrap>
        <TitleText>
          <span>Same Day</span>
          {" "}
          Pre-Approval
        </TitleText>
      </TextWrap>
      <SubText className="mortgage">
        A lot can happen in one day. Make the pre- approval process even easier as a part of the transaction process.
      </SubText>
      <SubText
        id="approval"
        style={{
          marginTop: "15px",
          marginBottom: "30px",
        }}
      >
        Our mobile ecosystem offers:
      </SubText>
      <GreenTextWrap>
        <AnchorLink
          onClick={() => {
            handleDataLayerPush(null, "loandesk_rates_clicked", "")
          }}
          className="loandesk_rates_link_web"
          href="https://www.loandeskmortgage.com"
          target="_blank"
        >
          <GreenTextMortgage>
            Check out LoanDesk rates
            <span>
              <img src={`${CLOUDFRONT}/GreenArrowRightMortgage.png`} alt="arrow-green" />
            </span>
          </GreenTextMortgage>
        </AnchorLink>
      </GreenTextWrap>
      <SmallText
        id="approval_II"
      >
        {`As Radius’ in-house lender, Loandesk Mortgage is committed to 
        providing real estate agents and their clients great rates, elevated service, 
        and a seamless experience — from start to finish.`}
      </SmallText>
    </Wrap>
    <Wrap sticky>
      <LottieMachine
        animationData={Approval}
        scrollFrames={[0, 150]}
        endFrames={[150, 300]}
        customStyle={{
          width: "582px",
          height: "100%",
          transform: "scale(1.1)",
        }}

      />
    </Wrap>
  </FlexAnimContainer>
)

export default DetailCardTwo
