import { takeLatest, call, put, select } from "redux-saga/effects"
import deepMerge from "deepmerge"
import { reset } from "redux-form"
import { getEpoch, isSuccess } from "services/Utils"
import root from "window-or-global"
import { get, isObject, isEmpty, isNumber } from "lodash"
import { toggleSalesStageChangeFormAction } from "container/Referral/actions"
import * as ClientActions from "container/Client/actions"
import * as ClientAPI from "container/Client/api"
import * as Actions from "./actions"
import * as API from "./api"
import createToast from "@ui/Toast"

function* fetchSearchCriteria(action) {
  try {
    const res = yield call(API.fetchClientSearchCriteriaAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientSearchCriteriaAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchClientSearchCriteriaAction.failure(error))
  }
}

function* fetchReminders(action) {
  try {
    yield put(reset("ADD_REMINDER_CLIENT_FORM"))
    const res = yield call(API.fetchClientReminderAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientReminderAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchClientReminderAction.failure(error))
  }
}

function* fetchClientProperties(action) {
  try {
    const res = yield call(API.fetchRecommendedPropertiesAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientPropertiesAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchClientPropertiesAction.failure(error))
  }
}

function* fetchClientPropertyDetails(action) {
  try {
    const { clientId, clientType } = action.data
    const res = yield call(API.fetchClientPropertyDetailsAPI, clientId, clientType)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientPropertiesDetailsActions.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchClientPropertiesDetailsActions.failure(error))
  }
}

function* fetchClientNotes(action) {
  try {
    const { clientId } = action.data
    const res = yield call(API.fetchClientNotesAPI, clientId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientNotesAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchClientNotesAction.failure(error))
  }
}

function* addClientNote(action) {
  try {
    yield put(Actions.saveNotesAction.call(true))
    const { clientId, payload, redirectUrl } = action.data
    const res = yield call(API.addNoteAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientNotesAction.request({ clientId }))
      yield put(Actions.addClientNoteAction.success(response))
      yield put(Actions.openAddEditNoteModalAction.call({ status: false }))
      yield put(Actions.saveNotesAction.call(false))
      // check type and redirect accordingly
      console.log(redirectUrl)
      if (redirectUrl === "outbound") {
        root.location.href = `/${redirectUrl}/${clientId}`
      } else {
        root.location.href = `/client/${clientId}/notes`
      }
    }
  } catch (error) {
    yield put(Actions.addClientNoteAction.failure(error))
  }
}

function* updateClientNote(action) {
  try {
    yield put(Actions.saveNotesAction.call(true))
    const { clientId, noteId, payload, redirectUrl } = action.data
    const res = yield call(API.updateNoteAPI, clientId, noteId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchClientNotesAction.request({ clientId }))
      yield put(Actions.addClientNoteAction.success(response))
      yield put(Actions.updateClientNoteAction.call({ status: false }))
      yield put(Actions.openUpdateNoteModalAction.call(false))
      yield put(Actions.saveNotesAction.call(false))
      console.log(redirectUrl)
      root.location.href = `/${redirectUrl}/${clientId}/notes`
    }
  } catch (error) {
    yield put(Actions.addClientNoteAction.failure(error))
  }
}

function* deleteClientNote(action) {
  try {
    yield put(Actions.saveNotesAction.call(true))
    const { clientId, noteId } = action.data
    const res = yield call(API.deleteNoteAPI, clientId, noteId)
    if (isSuccess(res)) {
      yield put(Actions.fetchClientNotesAction.request({ clientId }))
      yield put(Actions.openAddEditNoteModalAction.call({ status: false }))
      yield put(Actions.saveNotesAction.call(false))
    }
  } catch (error) {
    yield put(Actions.deleteClientNoteAction.failure(error))
  }
}

function* updateChecklist(action) {
  try {
    const { client, submittedValues } = action.data
    const clientData = { ...client }
    if (!clientData.sales[`${clientData.type}ChecklistV2`]) {
      clientData.sales[`${clientData.type}ChecklistV2`] = {}
    }
    const checklist = clientData && clientData.sales[`${clientData.type}ChecklistV2`]
    const currentStageName = clientData && clientData.sales["Current stage"]
    const currentCheckListData = checklist &&
    checklist.length > 0 &&
    checklist.filter(item => item.sales_stage === currentStageName)[0].checklists
    Object.keys(submittedValues).forEach((key) => {
      const currentChecklistIndex = currentCheckListData.findIndex((obj => obj.name === key))
      console.log(currentChecklistIndex)
      if (submittedValues[key] && currentCheckListData[currentChecklistIndex].date === 0) {
        currentCheckListData[currentChecklistIndex].date = getEpoch()
      }
      // only change it for false, not null or ""
      if (submittedValues[key] === false) {
        currentCheckListData[currentChecklistIndex].date = 0
      }
    })
    const { sales } = clientData
    const res = yield call(API.updateClientProfileAPI, clientData.id, { sales })
    if (isSuccess(res)) {
      yield put(Actions.updateChecklistAction.success(res))
      yield put(Actions.fetchClientTimelineAction.request({
        clientId: clientData.id,
        limit: 20,
        offset: 0,
      }))
    }
  } catch (error) {
    console.log(error)
    yield put(Actions.updateChecklistAction.failure(error))
  }
}

function* updateSalesStage(action) {
  try {
    const { sales, clientId, salesStage } = action.data
    if (salesStage === "In Contract" || salesStage === "Closed") {
      const activeLead = yield select(state => state.client.clientResponse)
      yield put(toggleSalesStageChangeFormAction.call({
        type: salesStage,
        lead: {
          ...activeLead,
          id: Number(activeLead.referralId),
          client_id: activeLead.id,
        },
      }))
    }
    const res = yield call(API.updateClientProfileAPI, clientId, {
      sales: {
        ...sales,
        "Current stage": salesStage,
      },
    })
    if (isSuccess(res)) {
      yield put(Actions.updateSalesStageAction.success(res.data))
      yield put(Actions.fetchClientTimelineAction.request({
        clientId,
        limit: 20,
        offset: 0,
      }))
      yield put(ClientActions.fetchClientProfileAction.success(res))
    }
  } catch (error) {
    yield put(Actions.updateSalesStageAction.failure(error))
  }
}

function* fetchClientTimeline(action) {
  try {
    const {
      clientId,
      limit,
      offset,
      isFetchingMore,
    } = action.data
    const res = yield call(API.fetchClientTimelineAPI, clientId, limit, offset)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        const { activities: newActivities, entities: newEntities, ...others } = response
        if (isEmpty(newActivities)) {
          yield put(Actions.fetchedAllTimelineEventsAction.call())
          return
        }
        const oldTimeline = yield select(state => state.clientProfile.timeline.data)
        const mergedRes = {
          activities: [
            ...oldTimeline.activities,
            ...newActivities,
          ],
          entities: deepMerge(newEntities || {}, oldTimeline.entities || {}),
        }
        yield put(Actions.fetchClientTimelineAction.success({
          ...mergedRes,
          ...others,
          isFetchingMore: false,
        }))
      } else {
        yield put(Actions.fetchClientTimelineAction.success(response))
      }
    }
  } catch (error) {
    yield put(Actions.fetchClientTimelineAction.failure(error))
  }
}

function* createReminder(action) {
  try {
    const { payload, clientId } = action.data
    const { isEdit, activity_id } = payload
    delete payload.isEdit
    let res = null
    if (isEdit) {
      res = yield call(API.editReminderAPI, clientId, payload, activity_id)
    } else {
      res = yield call(API.createReminderAPI, clientId, payload)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.setReminderAction.success(response))
      yield put(Actions.toggleReminderDropdownAction.call(false))
      yield put(Actions.toggleReminderEditModalAction.call(false))
      yield put(Actions.fetchClientReminderAction.request(clientId))
    }
  } catch (error) {
    yield put(Actions.setReminderAction.failure(error))
  }
}

function* addAdditionalContact(action) {
  try {
    const {
      contact, bond, family, clientId, serviceProviders,
    } = action.data
    const addRes = yield call(ClientAPI.addClientAPI, contact)
    const {
      response: { id, emails = [], name },
    } = addRes.data
    const payload = {
      bond: isObject(bond) ? bond.value : bond,
      family,
      client_email: emails[0].email,
      client_name: name,
      client_id: id,
    }
    const res = yield call(ClientAPI.editClientActionAPI, clientId, {
      service_providers: serviceProviders.concat(payload),
    })
    if (isSuccess(res)) {
      yield put(Actions.addContactAction.success(res))
      yield put(ClientActions.fetchClientProfileAction.success(res))
      yield put(Actions.openAdditionalDetailsModalAction.call(false))
    }
  } catch (error) {
    yield put(Actions.addContactAction.failure(error))
  }
}

function* updateAdditionalContact(action) {
  try {
    const {
      contact, family, bond, clientId, serviceProviders,
    } = action.data
    const updateRes = yield call(ClientAPI.editClientActionAPI, contact.id, contact)
    const {
      response: { emails = [], name, id },
    } = updateRes.data
    const payload = serviceProviders.map((provider) => {
      if (provider.client_id === id) {
        provider.bond = isObject(bond) ? bond.value : bond
        provider.family = family
        provider.client_email = emails[0].email
        provider.client_name = name
        provider.client_id = id
      }
      return provider
    })
    const res = yield call(ClientAPI.editClientActionAPI, clientId, {
      service_providers: payload,
    })
    if (isSuccess(res)) {
      yield put(Actions.updateContactAction.success(res))
      yield put(ClientActions.fetchClientProfileAction.success(res))
      yield put(Actions.openAdditionalDetailsModalAction.call(false))
    }
  } catch (error) {
    yield put(ClientActions.fetchClientProfileAction.failure(error))
  }
}

function* deleteAdditionalContact(action) {
  try {
    const { contactId, clientId, serviceProviders } = action.data
    const payload = serviceProviders.filter(provider => provider.client_id !== contactId)
    const res = yield call(ClientAPI.editClientActionAPI, clientId, {
      service_providers: payload,
    })
    if (isSuccess(res)) {
      yield put(Actions.deleteContactAction.success(res))
      yield put(ClientActions.fetchClientProfileAction.success(res))
      yield put(Actions.openDeleteConfirmationAction.call(false))
    }
  } catch (error) {
    yield put(Actions.deleteContactAction.failure(error))
  }
}

function* fetchContactInfo(action) {
  try {
    const { id, bond } = action.data
    const res = yield call(ClientAPI.fetchClientProfileAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      response.bond = response.tags ? bond : { value: bond }
      yield put(Actions.fetchContactInfoAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchContactInfoAction.failure(error))
  }
}

function* deleteReminder(action) {
  try {
    const { clientId, reminderId } = action.data
    const res = yield call(API.deleteReminderAPI, clientId, reminderId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.deleteReminderAction.success(response))
      yield put(Actions.fetchClientReminderAction.request(clientId))
    }
  } catch (error) {
    yield put(Actions.deleteReminderAction.failure(error))
  }
}

function* fetchBuyerMatches(action) {
  try {
    const { clientId } = action.data
    const payload = {
      newClient: true,
      newRequirement: true,
      sendClientDetails: true,
      sendAgentDetails: true,
      sendRequirementDetails: true,
      sendPropertyDetails: true,
      sendRecommendedPropertyDetails: true,
      clientId,
    }
    const res = yield call(API.fetchMatchesAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchBuyerMatchesAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchBuyerMatchesAction.failure(error))
  }
}

function* fetchEditProperty(action) {
  try {
    const res = yield call(API.fetchEditPropertyAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.fetchEditPropertyAction.success(response))
    }
  } catch (error) {
    yield put(Actions.fetchEditPropertyAction.failure(error))
  }
}

function* handleRequestUpdate(action) {
  try {
    const {
      update_requested_at,
      referralId,
      clientId,
    } = action.data

    const payload = {
      update_requested_at,
    }
    const res = yield call(API.sendClientRequestUpdateAPI, referralId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.sendRequestPayloadAction.success(response))
      createToast("Update Request Sent.")
      setTimeout(() => {
        root.location.href = `/outbound/${clientId}`
      }, 1000)
    }
  } catch (error) {
    yield put(Actions.sendRequestPayloadAction.failure(error))
  }
}

function* handleFetchPreferredLender(action) {
  try {
    const res = yield call(API.getPreferredLenderAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.getPreferredLenderAction.success(response))
    }
  } catch (error) {
    yield put(Actions.getPreferredLenderAction.failure(error))
  }
}

function* handleUpdatePreferredLender(action) {
  try {
    const {
      clientId,
      payload,
    } = action.data

    const res = yield call(API.updatePreferredLenderAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.updatePreferredLenderAction.success(response))
      yield put(Actions.togglePreferedLenderModalAction.call(false))
      yield put(Actions.getPreferredLenderAction.request(clientId))
    }
  } catch (error) {
    yield put(Actions.updatePreferredLenderAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(Actions.fetchClientSearchCriteriaAction.REQUEST, fetchSearchCriteria)
  yield takeLatest(Actions.fetchClientReminderAction.REQUEST, fetchReminders)
  yield takeLatest(Actions.fetchClientPropertiesAction.REQUEST, fetchClientProperties)
  yield takeLatest(Actions.fetchClientPropertiesDetailsActions.REQUEST, fetchClientPropertyDetails)
  yield takeLatest(Actions.fetchClientNotesAction.REQUEST, fetchClientNotes)
  yield takeLatest(Actions.addClientNoteAction.REQUEST, addClientNote)
  yield takeLatest(Actions.updateClientNoteAction.REQUEST, updateClientNote)
  yield takeLatest(Actions.deleteClientNoteAction.REQUEST, deleteClientNote)
  yield takeLatest(Actions.updateChecklistAction.REQUEST, updateChecklist)
  yield takeLatest(Actions.updateSalesStageAction.REQUEST, updateSalesStage)
  yield takeLatest(Actions.fetchClientTimelineAction.REQUEST, fetchClientTimeline)
  yield takeLatest(Actions.setReminderAction.REQUEST, createReminder)
  yield takeLatest(Actions.addContactAction.REQUEST, addAdditionalContact)
  yield takeLatest(Actions.updateContactAction.REQUEST, updateAdditionalContact)
  yield takeLatest(Actions.deleteContactAction.REQUEST, deleteAdditionalContact)
  yield takeLatest(Actions.fetchContactInfoAction.REQUEST, fetchContactInfo)
  yield takeLatest(Actions.deleteReminderAction.REQUEST, deleteReminder)
  yield takeLatest(Actions.fetchBuyerMatchesAction.REQUEST, fetchBuyerMatches)
  yield takeLatest(Actions.fetchEditPropertyAction.REQUEST, fetchEditProperty)
  yield takeLatest(Actions.sendRequestPayloadAction.REQUEST, handleRequestUpdate)
  yield takeLatest(Actions.getPreferredLenderAction.REQUEST, handleFetchPreferredLender)
  yield takeLatest(Actions.updatePreferredLenderAction.REQUEST, handleUpdatePreferredLender)
}
