import React from "react"
import { Route, Redirect } from "react-router-dom"
import { isObject } from "lodash"
import { checkForUserObject } from "services/Utils"
import ScrollToTop from "@ui/ScrollToTop"
import RoutesWithFreshChat from "./RouteWithFreshChat"

function isAuthenticated() {
  // Magic will happen here...
  const user = checkForUserObject("user")
  if (isObject(user) || user === "false") {
    return false
  }
  return true
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      !isAuthenticated()
        ? (
          <RoutesWithFreshChat>
            <ScrollToTop />
            <Component {...props} />
          </RoutesWithFreshChat>
        )
        : <Redirect to={props.redirect || "/"} />)
    }
  />
)

export default ProtectedRoute
