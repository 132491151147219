import React from "react"
import { Form, Select } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  SelectFieldNewAttachedStyled,
  InputFieldWrap,
  InputGroupStyled,
  InputNumberNewWrap,
  GroupedWrap,
} from "./styles"
const SizeInputGroup = ({
  /**Input Field Props */
  placeholder,
  type,
  name,
  required = false,
  customRules,
  inputType,
  onChange,
  text,
  disabled,

  /**Select Field Props */
  selectName,
  selectFieldPlaceHolder,
  options,
  selectStyles,
  arrowIcon,
  selectFieldName,
  selectDefaultValue,
  selectOnChange,
}) => {
  const getRules = () => {
    if (required) {
      return [{ required: true, message: "Required" }]
    }

    return []
  }

  return (
    <Form.Item
      name="combined"
      rules={getRules()}
      label={<LabelComponent text={`${text}`} required={required} />}
      required={required}
      disabled={disabled}
    >
      <InputGroupStyled compact>
        <Form.Item
          name={name}
          noStyle
        >
          <InputNumberNewWrap
            placeholder={placeholder}
            //style={style}
            type={inputType}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          name={selectName}
          id="formItemSizeGroup"
        >
          <SelectFieldNewAttachedStyled
            id="sizeGroup"
            className="sizeGroup"
            placeholder={selectFieldPlaceHolder || "Select"}
            options={options}
            style={{ ...selectStyles }}
            suffixIcon={arrowIcon}
            isFormField
            name={selectFieldName}
            defaultValue={selectDefaultValue}
            onChange={selectOnChange}
          />
        </Form.Item>
      </InputGroupStyled>
    </Form.Item>
  )
}

export default SizeInputGroup

