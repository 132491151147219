import React, { useEffect } from "react"
import { toast } from "react-toastify"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import {
  FetchFileIfIDExists,
  getIsEdit,
} from "dumbComponents/common/InputFields/utils"
import DeleteIcon from "images/icons/dustbinicon.png"
import DocumentIcon from "images/icons/document.png"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const FileTableWrap = styled.div`
  display: flex;
  height: max-content;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);
  background: var(--Color-Neutral-50, #FDFDFD);
  border-radius: 12px;
  padding-bottom: 10px;
  margin-botton: 85px;
`

const HeaderWrap = styled.div`
  display: flex;
  height: 45.5px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--Color-Neutral-100, #F1F1FE);
  padding: 10px 50px 0px 50px;
  border-radius-top-left: 12px;
  border-radius-top-right: 12px;
`

const ColumnFileNameHeader = styled.div`
  p {
    color: #0D141C;
    font-feature-settings: 'dlig' on;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
  }
`

const ColumnActionHeader = styled.div`
  p {
    color: #0D141C;
    font-feature-settings: 'dlig' on;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
  }
`

const ListWrap = styled.ul`
  width: 100%;
`

const FileRow = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 60px 0px 40px;
  align-items: baseline;
`

const Wrap = styled.div``

const FilesWrap = styled.div``

const FilesListWrap = styled.ul``

const FileLi = styled.li`
   display: flex;
   height: 46px;
  //  width: 490px;
   justify-content: space-between;
`

const FileName = styled.p`
 color: #0D141C;
  font-feature-settings: 'dlig' on;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`

const NameDiv = styled.div`
 display: flex;
 flex-direction: row-reverse;
 align-items: flex-start;

`

const DeleteIconEle = styled.img`
  height: 14px;
  position: relative;
  top: 1px;
`

const DocumentIconEle = styled.img`
 height: 35px;
`

const DeleteDiv = styled.div`

  display: flex;
  gap: 10px;
  align-items: baseline;
  cursor: pointer;

  p { 
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-feature-settings: 'dlig' on;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: 0.21px;
  }
`

const NoFilesUploaded = styled.p`
  margin: 0 auto;
  padding: 20px;
`

const handleRemoveFile = (id, deleteFunc, docuID) => {
  deleteFunc({
    fileToBeDeletedID: id,
    id: docuID,
  })
}

const FileUploadFormComponent = ({
  fetchTransactionCoordinationDocumentsResponse,
  fetchTCDocumentsFromS3Response,
  fetchFilesFromS3,
  deleteFileFromS3,
  fetchTCFiles,
  dealId,
  disabled,
}) => {
  const toastConfig = {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: true,
  }

  useEffect(() => {
    if (dealId) {
      fetchFilesFromS3({ id: dealId })
    }
  }, [])

  try {
    FetchFileIfIDExists(dealId, currentDeal, fetchTCFiles)
  } catch (e) {
    //fail silently
  }

  const {
    data: fetchedFiles,
  } = fetchTCDocumentsFromS3Response || {}

  const {
    data: deals,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = dealId
  const currentDeal = deals && deals.length > 0 && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const disableField = disabled || isCoListed || getIsEdit(currentDeal)

  // return (
  //   <Wrap>
  //     <FilesWrap>
  //       {fetchedFiles && fetchedFiles.length > 0 && (
  //         <FilesListWrap>
  //           {fetchedFiles.map(file => (
  //             <FileLi>
  //               <NameDiv>
  //                 <FileName>
  //                   {file.title}
  //                 </FileName>
  //                 <DocumentIconEle src={DocumentIcon} alt="doc.alt" />
  //               </NameDiv>
  //               {!disabled && (
  //                 <DeleteIconEle
  //                   src={DeleteIcon}
  //                   alt="doc.del"
  //                   onClick={() => {
  //                     if (!disableField) {
  //                       handleRemoveFile(file.id, deleteFileFromS3, dealId)
  //                     } else {
  //                       toast.error("Remove is disabled", toastConfig)
  //                     }
  //                   }}
  //                 />
  //               )}
  //             </FileLi>
  //           ))}
  //         </FilesListWrap>
  //       )}
  //     </FilesWrap>
  //   </Wrap>
  // )

  return (
    <FileTableWrap>
      <HeaderWrap>
        <ColumnFileNameHeader>
          <p>File Name</p>
        </ColumnFileNameHeader>
        <ColumnActionHeader>
          <p>Action</p>
        </ColumnActionHeader>
      </HeaderWrap>
      {fetchedFiles && fetchedFiles.length > 0 ? (
        <ListWrap>
          {fetchedFiles.map(file => (
            <FileRow>
              <NameDiv>
                <FileName>
                  {file.title}
                </FileName>
              </NameDiv>
              {!disabled && (
                <DeleteDiv
                  onClick={() => {
                    if (!disableField) {
                      handleRemoveFile(file.id, deleteFileFromS3, dealId)
                    } else {
                      toast.error("Remove is disabled", toastConfig)
                    }
                  }}
                >
                  <p>Delete</p>
                  <DeleteIconEle
                    src={`${CLOUDFRONT}/LISTING_V2/Blue_Dustbin.svg`}
                    alt="doc.del"
                  />
                </DeleteDiv>
              )}
            </FileRow>
          ))}
        </ListWrap>
      ) : (
        <NoFilesUploaded>No Files Uploaded.</NoFilesUploaded>
      )}
    </FileTableWrap>
  )
}

export default TransactionContainer(withRouter(FileUploadFormComponent))
//LISTING_V2/Blue_Dustbin.svg