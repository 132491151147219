import React, { useEffect } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listing from "container/Listings"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import CRMV2Container from "container/CRM"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 20px 20px 0px 31px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 20px 20px 30px 20px;
  flex-direction: row-reverse;
`

const ViewTransactionListing = ({
  viewTransaction,
  setIsViewListing,
  currentCRMDealId,
  setClientID,
  toggleUserOverview,
  getBrokerageTransactionStatus,
  getBrokerageTransactionStatusResponse,
  history,
  performNecessaryActionsBeforeLoadingListing,
  performNecessaryActionsBeforeLoadingListingResponse,
  viewListingPersistenceResponse,
  getCtIdResponse,
}) => {
  useEffect(() => {
    getBrokerageTransactionStatus({ transactionId: currentCRMDealId })
  }, [])

  const {
    isFetching: processing,
  } = viewListingPersistenceResponse || {}

  const {
    data: transactionDetails,
  } = getCtIdResponse || {}

  const { data } = getBrokerageTransactionStatusResponse || {}
  const { id } = data || {}
  
  return (
    <Wrap>
      <>
        <Header>
          <h1>
            View Listing
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            Would you like to view this Listing?
          </Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            isShowLoader={processing}
            onClick={(e) => {
              e.stopPropagation()
              setIsViewListing(false)
              viewTransaction(id)
              toggleUserOverview({ showUserOverviewDrawer: false })
              setClientID({
                id: "",
                deal_id: "",
              })
            }}
          >
            View Listing
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={processing}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              setIsViewListing(false)
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default Listing(withRouter(CRMV2Container(ViewTransactionListing)))
