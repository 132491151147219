import React from "react"
import { isEmpty } from "lodash"
import { Route, Redirect } from "react-router-dom"
import Header from "dumbComponents/common/HomeHeader"
import queryString from "query-string"
import { checkForUserObject } from "services/Utils"

const isAuthenticated = () => {
  // Magic will happen here...
  const user = checkForUserObject("user")
  if (isEmpty(user)) {
    return false
  }
  return true
}

const AgreementRoute = ({ component: Component, ...rest }) => {
  const { redirect, location } = rest
  const parsedQuery = queryString.parse(location.search)
  const { platform } = parsedQuery
  const isIOS = platform === "ios"

  return (
    <Route
      {...rest}
      render={props => isAuthenticated() ? (
        <Redirect to={redirect || "/referrals/isa?womensCouncil=1"} />
      ) : (
        <React.Fragment>
          {!isIOS && (<Header />)}
          <Component {...props} />
        </React.Fragment>
      )
      }
    />
  )
}

export default AgreementRoute
