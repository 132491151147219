import React, { useEffect } from "react"
import {
  OFFER_DOCS,
  LISTING_DOCS,
  UPLOADS,
} from "dumbComponents/OffersV2/consts"
import OffersV2 from "container/OffersV2/index"
import OldOffers from "container/TechAssets"
import { EyeIcon, TrashIcon } from "dumbComponents/OffersV2/icons"
import {
  DocumentShimmer,
  Tabs,
} from "dumbComponents/OffersV2/components"
import {
  Wrap,
  DocumentTypeWrap,
} from "./style"
import OfferDoc from "./OfferDoc"
import UploadDoc from "./UploadDocs"

const addDocumentsUI = ({
  options: LibraryOptions,
  toggleLibraryTab,
  currentLibraryTab,
  listingDocsList,
  offersDocList,
  fetchLibraryUploads,
  fetchLibraryUploadedFilesResponse,
  dealToView,
  docList,
  getOfferDocumentsResponse,
  getOfferDocuments,
  goBack,
  fetchOfferDocuments,
  stateForOffer,
  fetchOfferDocumentsResponse,
  updateRecommendedListOfDocs,
  fetchListingDocumentsResponse,
  fetchListingDocuments,
  getTemplatePreview,
  deleteLibraryUploadedFile,
  updateRecommendedListOfDocsResponse,
}) => {

  const {
    isFetching: fetchingOffersDocs,
  } = getOfferDocumentsResponse || {}

  const {
    isFetching: updatingRecommendedDocs,
  } = updateRecommendedListOfDocsResponse || {}

  const {
    isFetching: fetchingLibraryUploads,
  } = fetchLibraryUploadedFilesResponse || {}

  const {
    isFetching: offerDocumentsFetching,
  } = fetchOfferDocumentsResponse || {}

  const {
    isFetching: fetchingListingDocs,
  } = fetchListingDocumentsResponse || {}

  useEffect(() => {
    if (currentLibraryTab === UPLOADS) {
      fetchLibraryUploads()
    }

    if (currentLibraryTab === OFFER_DOCS) {
      fetchOfferDocuments({
        state: stateForOffer,
      })
    }

    if (currentLibraryTab === LISTING_DOCS) {
      fetchListingDocuments({
        state: stateForOffer,
      })
    }
  }, [currentLibraryTab])

  const handleSubmit = () => {
    const payload = {
      offerId: parseInt(dealToView, 10),
      templateIds: [],
    }

    const templatesToBeUploaded = offersDocList.filter(item => item.isSelected).map(item => item.id)
    payload.templateIds = [...templatesToBeUploaded]
    updateRecommendedListOfDocs({
      payload,
      id: dealToView,
      callBack: () => {
        goBack()
      },
    })
  }

  const OFFERS_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        //needs a loader
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "offer",
        })
      },
      showDivide: false,
    },
  ]

  const LISTING_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "listing",
        })
      },
      showDivide: false,
    },
  ]
  
  const UPLOAD_MAIN_LIST = [
    {
      label: "DELETE",
      image: <TrashIcon />,
      onClick: (id) => {
        //NEED TOAST
        deleteLibraryUploadedFile({
          id,
        })
      },
      showDivide: false,
    },
    // {
    //   label: "VIEW",
    //   image: <EyeIcon />,
    //   onClick: (id) => {
    //     const payload = {
    //       templateId: id,
    //     }
    //     console.log(id)
    //     //NEED TOAST
    //     // getTemplatePreview({
    //     //   payload,
    //     //   dealType: "offer",
    //     // })
    //   },
    //   showDivide: false,
    // },
    //OFFERS_V2/Trash.svg
  ]

  return (
    <Wrap>
      <Tabs
        steps={LibraryOptions}
        toggleTab={currentTab => toggleLibraryTab(currentTab)}
        currentTab={currentLibraryTab}
      />
      <DocumentTypeWrap>
        {currentLibraryTab === OFFER_DOCS && (
          <>
            {offerDocumentsFetching ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={offersDocList}
                handleSubmit={handleSubmit}
                mainList={OFFERS_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === LISTING_DOCS && (
          <>
            {fetchingListingDocs ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={listingDocsList}
                handleSubmit={handleSubmit}
                mainList={LISTING_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === UPLOADS && (
          <>
            {fetchingLibraryUploads ? (
              <DocumentShimmer />
            ) : (
              <UploadDoc
                handleSubmit={handleSubmit}
                goBack={goBack}
                mainList={UPLOAD_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
      </DocumentTypeWrap>
    </Wrap>
  )
}

export default OldOffers(OffersV2(addDocumentsUI))
