import React from "react";
import styled from "styled-components";
import { Collapse } from "@ui/antd"; // Import Collapse from Ant Design
import "./styles.css";
import {
  ListWrap,
  HeaderWrap,
  StyledArrow,
  ContentWrap,
  PendingList,
  ItemList,
  StyledCheckbox,
  CheckBoxWrap,
  StatusWrap,
  HeaderItemList,
  StatusHeaderWrap,
  ItemWrap,
  NumberWrap,
  DuedateWrap,
  Divide,
  StyledStatus,
  StatusTitle,
  MandatoryTag,
  MandatoryTagTitle,
  Wrap,
  StyledCollapse,
  StyledPanel
} from "./styles";

const { Panel } = Collapse;

const CheckListView = ({
  checklistTemplateList,
  checklistObjectData,
  fetchChecklistTemplate,
  fetchChecklistTemplateResponse,
  handleSelectedCheck,
  currentBrokerageType,
  handleSelectAllChange,
}) => {
  const statusMap = {
    pending: {
      value: "pending",
      label: "Your Signature Pending",
      color: "#FF8833",
    },
    sent: {
      value: "buyer_pending",
      label: "Seller Signature Pending",
      color: "#FFB266",
    },
    completed: {
      value: "completed",
      label: "Completed",
      color: "#77CC66",
    },
    action_required: {
      value: "action_required",
      label: "Your Signature Pending",
      color: "#FF8833",
    },
    active: {
      value: "active",
      label: "Drafted",
      color: "#007FFF",
    },
    voided: {
      value: "void",
      label: "Voided",
      color: "#808080",
    },
    declined: {
      value: "declined",
      label: "Declined",
      color: "#FF6347",
    },
    processing: {
      value: "processing",
      label: "Processing",
      color: "#80B2B2",
    },
  };

  const statusMapOffers = {
    pending: {
      value: "pending",
      label: "Your Signature Pending",
      color: "#FF8833",
    },
    sent: {
      value: "buyer_pending",
      label: "Buyer Signature Pending",
      color: "#FFB266",
    },
    completed: {
      value: "completed",
      label: "Completed",
      color: "#77CC66",
    },
    action_required: {
      value: "action_required",
      label: "Your Signature Pending",
      color: "#FF8833",
    },
    active: {
      value: "active",
      label: "Drafted",
      color: "#007FFF",
    },
    voided: {
      value: "void",
      label: "Voided",
      color: "#808080",
    },
    declined: {
      value: "declined",
      label: "Declined",
      color: "#FF6347",
    },
    processing: {
      value: "processing",
      label: "Processing",
      color: "#80B2B2",
    },
  };

  const StatusIcon = ({ color }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 11 11"
        fill="none"
        style={{ flexShrink: 0 }}
      >
        <circle cx="5.5" cy="5.5" r="5" fill={color} />
      </svg>
    );
  };

  // Prepare default active keys
  const defaultActiveKeys = checklistObjectData.map((_, index) => `${index}`);

  return (
    <>
      <Wrap>
        <StyledCollapse
          style={{ width: "100%" }}
          className="custom-collapse-panel"
          defaultActiveKey={defaultActiveKeys}
        >
          {checklistObjectData &&
            checklistObjectData.map((item, index) => (
              <StyledPanel
                header={
                  <HeaderWrap>
                    <p>{item.checklist}</p>
                    <NumberWrap>{item.total}</NumberWrap>
                    {item.due_date != null && (
                      <>
                        <Divide type="vertical" />
                        <DuedateWrap>
                          Due date :{" "}
                          {item.due_date != null ? item.due_date : "N/A"}
                        </DuedateWrap>
                      </>
                    )}
                  </HeaderWrap>
                }
                key={index}
              >
                <ListWrap>
                  <ContentWrap style={{ borderTop: "0px" }}>
                    <PendingList>
                      <p>
                        {item.pending}/{item.total} ITEMS PENDING
                      </p>
                    </PendingList>
                  </ContentWrap>
                  <ItemWrap>
                    <HeaderItemList>
                      <CheckBoxWrap>
                        <StyledCheckbox
                          inMenu
                          onChange={(e) =>
                            handleSelectAllChange(e, item.checklist)
                          }
                        />
                      </CheckBoxWrap>
                      <p>ITEM</p>
                    </HeaderItemList>
                    <StatusHeaderWrap>
                      <p> STATUS </p>
                    </StatusHeaderWrap>
                  </ItemWrap>
                  {item.document_items.map((documentItem) => (
                    <ItemWrap key={documentItem.id}>
                      <ItemList>
                        <CheckBoxWrap>
                          <StyledCheckbox
                            inMenu
                            checked={documentItem.isSelected}
                            value={item.id}
                            onChange={(e) =>
                              handleSelectedCheck(
                                e,
                                documentItem.document_item_id,
                                item.checklist
                              )
                            }
                          />
                        </CheckBoxWrap>
                        <p>{documentItem.document_item}</p>
                      </ItemList>
                      <StatusWrap>
                        {documentItem.status ? (
                          <>
                            {currentBrokerageType === "offers" ? (
                              <>
                                <StyledStatus>
                                  <StatusIcon
                                    color={
                                      statusMapOffers[documentItem.status].color
                                    }
                                  />
                                </StyledStatus>
                                <StatusTitle>
                                  {statusMapOffers[documentItem.status].label}
                                </StatusTitle>
                              </>
                            ) : (
                              <>
                                <StyledStatus>
                                  <StatusIcon
                                    color={statusMap[documentItem.status].color}
                                  />
                                </StyledStatus>
                                <StatusTitle
                                  status={statusMap[documentItem.status].value}
                                >
                                  {statusMap[documentItem.status].label}
                                </StatusTitle>
                              </>
                            )}
                          </>
                        ) : documentItem.mandatory ? (
                          <MandatoryTag mandatoryStatus>
                            <MandatoryTagTitle mandatoryStatus>
                              Mandatory
                            </MandatoryTagTitle>
                          </MandatoryTag>
                        ) : (
                          <MandatoryTag applicableStatus>
                            <MandatoryTagTitle applicableStatus>
                              If Applicable
                            </MandatoryTagTitle>
                          </MandatoryTag>
                        )}
                      </StatusWrap>
                    </ItemWrap>
                  ))}
                </ListWrap>
              </StyledPanel>
            ))}
        </StyledCollapse>
      </Wrap>
    </>
  );
};

export default CheckListView;
