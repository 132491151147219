import {
  takeLatest, call, put, takeEvery, select,
} from "redux-saga/effects"
import { get } from "lodash"
import * as TechAssetsActions from "container/TechAssets/actions"
import { IN_CONTRACT, CLOSED } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { isSuccess, MoneyFormatter } from "services/Utils"
import columnConfig from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/config"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { toast as createToast } from "react-toastify"
import * as CRMV2API from "./api"
import * as CRMV2Actions from "./actions"

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

const sortTransactionIds = (clientTransactions) => {
  const dragDropColumns = {}
  const allTransactions = {}
  columnConfig().map((column) => {
    const referralTypeArray = clientTransactions[column.value] || []
    dragDropColumns[column.value] = []
    referralTypeArray.map((referral) => {
      dragDropColumns[column.value].push(referral.id)
      allTransactions[referral.id] = referral
    })
  })

  return {
    allTransactions,
    dragDropColumns,
  }
}

function* getClient(action) {
  try {
    let query = ""
    const {
      clientId, updateView, type, togglePage = true,
    } = action.data
    if (clientId) {
      query = `/${clientId}`
    }
    const res = yield call(CRMV2API.getClientAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getClientAction.success(response))
      // yield put(CRMV2Actions.setTransactionDetailsAction.call(response))
      const {
        clientTransactions,
      } = response || {}

      const {
        BUYING,
        SELLING,
      } = clientTransactions || {}
      const agentInfo = yield select(state => (state.CRMV2.isUserOverviewDrawerOpen.agentInfo))

      // if (isEmpty(agentInfo)) {
      //   console.log(agentInfo, "here")
      //   yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ agentInfo }))
      // }

      if (togglePage) {
        if ((type === "buyer" && BUYING && BUYING.length !== 1)
          || (type === "seller" && SELLING && SELLING.length !== 1)) {
          yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: true }))
          yield put(CRMV2Actions.toggleDrawerPage.call("drawer-header"))
        } else if (type === "buyer") {
          yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: true }))
          yield put(CRMV2Actions.toggleDrawerPage.call("search-criteria"))
        } else if (type === "seller") {
          yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: true }))
          yield put(CRMV2Actions.toggleDrawerPage.call("property-listing"))
        }
      }
      if (updateView) {
        let currentView = []

        if (type === "buyer" && BUYING) {
          currentView = BUYING.filter(item => item.req_id === updateView)
        } else if (SELLING) {
          currentView = SELLING.filter(item => item.prop_id === updateView)
        }

        if (currentView && currentView.length) {
          yield put(CRMV2Actions.setTransactionDetailsAction.call(get(currentView, "[0]", {})))
        }
      }
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getClientAction.failure(error))
  }
}

const getStatuses = (statuses) => {
  if (statuses.length === 1) {
    return `&statuses=${statuses[0]}`
  }

  if (statuses.length > 1) {
    return `&statuses=${statuses.join(",")}`
  }

  return ""
}

const getTypes = (types) => {
  if (types.length === 1) {
    return `&types=${types[0]}`
  }

  if (types.length > 1) {
    return `&types=${types.join(",")}`
  }

  return ""
}

function buildQuery(offerStage, transactionId, currentFilters, page) {
  let query = ""
  let statusQuery = ""
  let typeQuery = ""
  let sortByQuery = ""
  let isFavouriteQuery = ""
  let viewType = ""
  let teamMemberIds = ""
  const offset = 20 * page

  if (offerStage) {
    query = `?status=${offerStage}&limit=20&offset=${offset}`
  } else if (transactionId) {
    query = `/${transactionId}`
  }

  //statuses
  if (currentFilters && currentFilters.view_type) {
    viewType = `?view_type=${currentFilters.view_type}`
    query += viewType
  }

  if (currentFilters && currentFilters.statuses.length > 0) {
    statusQuery = getStatuses(currentFilters.statuses)
    query += statusQuery
  }

  if (currentFilters && currentFilters.team_member_id) {
    teamMemberIds = currentFilters.team_member_id !== "view_all"
    && currentFilters.team_member_id !== "view_mine"
    && currentFilters.team_member_id !== "view_all_team_members"
      ? `&team_member_ids=${currentFilters.team_member_id}` : null

    if (teamMemberIds) {
      query += teamMemberIds
    }
  }

  if (currentFilters && currentFilters.types && currentFilters.types.length > 0) {
    const queryString = getTypes(currentFilters.types)
    typeQuery = statusQuery ? `${statusQuery}${queryString}` : `${queryString}`
    query += typeQuery
  }

  if (currentFilters && currentFilters.sortBy) {
    sortByQuery = statusQuery || typeQuery
      ? `&${query}&sortBy=${currentFilters.sortBy}` : `&sortBy=${currentFilters.sortBy}`
    query += sortByQuery
  }

  if (currentFilters && currentFilters.isFavourite && currentFilters.isFavourite[0]) {
    isFavouriteQuery = statusQuery || typeQuery || sortByQuery
      ? `&${query}&is_favourite=${currentFilters.isFavourite[0]}` : `&is_favourite=${currentFilters.isFavourite[0]}`
    query += isFavouriteQuery
  }

  return query
}

function* getClientTransactions(action) {
  try {
    const {
      offerStage,
      transactionId,
      currentFilters,
      page,
    } = action.data || {}

    const res = yield call(CRMV2API.getClientTransactionsAPI, buildQuery(offerStage, transactionId, currentFilters, page))
    if (isSuccess(res)) {
      const parsedData = sortTransactionIds(res.data.response.client_transactions)
      const { allTransactions, dragDropColumns } = parsedData
      let finalResponse
      const { response } = res.data
      if (offerStage) {
        finalResponse = {
          ...response,
          offerStage,
          dragDropColumns,
          allTransactions,
        }
      } else {
        finalResponse = {
          ...response,
          allTransactions,
          dragDropColumns,
        }
      }
      yield put(CRMV2Actions.getClientTransactionsAction.success(finalResponse))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getClientTransactionsAction.failure(error))
  }
}

function* getClientTransactionById(action) {
  try {
    const {
      transactionId,
      currentFilters,
    } = action.data || {}
    const res = yield call(CRMV2API.getClientTransactionsAPI, buildQuery("", transactionId, currentFilters))
    if (isSuccess(res)) {
      const { response } = res.data
      console.log("data", response)
      yield put(CRMV2Actions.getClientTransactionByIdAction.success(response))
    }
  } catch (error) {
    yield call(CRMV2Actions.getClientTransactionByIdAction.failure(error))
  }
}

function* updateClientTransaction(action) {
  try {
    const { transactionId, payload, currentFilters } = action.data
    const res = yield call(CRMV2API.updateClientTransactionAPI, transactionId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateClientTransactionAction.success(response))
      yield put(CRMV2Actions.getClientTransactionsAction.request({ currentFilters }))
      yield put(CRMV2Actions.getClientTransactionByIdAction.request({ transactionId }))
      customToast("Updated Successfully")
    }
  } catch (error) {
    yield put(CRMV2Actions.updateClientTransactionAction.failure(error))
  }
}

function* updateBuyerRequirement(action) {
  try {
    const {
      id, payload, clientId, currentFilters,
    } = action.data
    const {
      area_unit,
      bathroom,
      bedroom,
      max_budget,
      max_size,
      min_budget,
      min_size,
      sale_type,
      u_cust_id,
      locations,
    } = payload
    const finalSearchCriteriaPayload = {
      area_unit,
      bathroom,
      bedroom,
      locations,
      max_budget,
      max_size,
      min_budget,
      min_size,
      sale_type,
      u_cust_id,
    }
    const res = yield call(CRMV2API.editBuyerRequirementsAPI, id, finalSearchCriteriaPayload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateBuyerRequirementsAction.success(response))
      yield put(CRMV2Actions.getClientAction.request({
        clientId,
        updateView: id,
        type: "buyer",
      }))
      yield put(CRMV2Actions.getClientTransactionsAction.request({
        currentFilters,
      }))
      customToast("Updated Successfully")
      const transactionId = yield select(state => state.CRMV2.getCtIdResponse.data.id)
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId }))
    }
  } catch (error) {
    yield put(CRMV2Actions.updateBuyerRequirementsAction.failure(error))
  }
}

function* updatePropertyDetails(action) {
  try {
    const {
      propertyId, payload, agent_id, cityFormValue,
      clientId, currentFilters,
    } = action.data
    const {
      city,
      state,
      country,
      latitude,
      longitude,
      neighborhood,
      address,
      streetName,
      route,
      // zipcode
    } = cityFormValue
    const {
      area_coverage,
      no_of_bath_room,
      no_of_bed_room,
      sale_type,
      property_type,
      resale_price,
      sale_price,
      covered_area,
      price,
    } = payload
    const finalPayload = {
      area_coverage,
      no_of_bath_room,
      no_of_bed_room,
      rent_resale: sale_type,
      property_type,
      resale_price: typeof resale_price === "number" ? resale_price : (MoneyFormatter(resale_price)),
      sale_price: typeof sale_price === "number" ? sale_price : (MoneyFormatter(sale_price)),
      covered_area,
      price: typeof price === "number" ? price : (MoneyFormatter(price)),
      city,
      state,
      country,
      latitude,
      longitude,
      agent_id,
      address,
      // zipcode,
      property_name: `${streetName !== undefined ? streetName : ""} ${route !== undefined ? route : ""}`
    }
    const res = yield call(CRMV2API.updatePropertyDetailsAPI, propertyId, finalPayload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updatePropertyDetailsAction.success(response))
      yield put(CRMV2Actions.getClientAction.request({
        clientId,
        updateView: propertyId,
        type: "seller",
      }))
      yield put(CRMV2Actions.getClientTransactionsAction.request({
        currentFilters,
      }))
      customToast("Updated Successfully")
      const transactionId = yield select(state => state.CRMV2.getCtIdResponse.data.id)
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId }))
    }
  } catch (error) {
    yield put(CRMV2Actions.updatePropertyDetailsAction.failure(error))
  }
}

function* addClient(action) {
  try {
    const {
      payload, transactionPayload, fieldValues, cityFormValue, history, is_for_transaction,
    } = action.data
    const res = yield call(CRMV2API.addClientAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      //const { id, agent_id } = response
      const { id } = response
      yield put(CRMV2Actions.addClientAction.success(response))
      customToast("Client Added Successfully")
      const finalTransactionPayload = {
        ...transactionPayload,
        client_id: id,
      }
      if (fieldValues && fieldValues.search_criteria && fieldValues.search_criteria.length > 0) {
        const searchCriteriaPayload = fieldValues.search_criteria
        for (let index = 0; index < searchCriteriaPayload.length; index += 1) {
          const {
            area_unit,
            bathroom,
            bedroom,
            max_budget,
            max_size,
            min_budget,
            min_size,
            sale_type,
            location,
          } = searchCriteriaPayload[index]
          const finalSearchCriteriaPayload = {
            area_unit,
            bathroom,
            bedroom,
            locations: location,
            max_budget,
            max_size,
            min_budget,
            min_size,
            sale_type,
            u_cust_id: id,
            is_for_transaction,
          }
          yield put(CRMV2Actions.addBuyerRequirementsAction
            .request({ finalSearchCriteriaPayload, finalTransactionPayload, history }))
        }
      } else if (fieldValues && fieldValues.property_details && fieldValues.property_details.length > 0) {
        const propertyDetailsPayload = fieldValues.property_details
        for (let index = 0; index < propertyDetailsPayload.length; index += 1) {
          const {
            area_coverage,
            no_of_bath_room,
            no_of_bed_room,
            sale_type,
            property_status,
            property_type,
            resale_price,
            covered_area,
          } = propertyDetailsPayload[index]
          const {
            city,
            state,
            country,
            latitude,
            longitude,
            address,
            streetName,
            route,
          } = cityFormValue[index]
          const finalPropertyDetailsPayload = {
            area_coverage,
            no_of_bath_room,
            no_of_bed_room,
            sale_type,
            rent_resale: sale_type,
            u_cust_id: id,
            property_status,
            property_type,
            city,
            state,
            country,
            latitude,
            longitude,
            resale_price,
            covered_area,
            address,
            property_name: `${streetName !== undefined ? streetName : ""} ${route !== undefined ? route : ""}`,
            is_for_transaction,
            //agent_id
          }
          yield put(CRMV2Actions.addPropertyDetailsAction
            .request({ finalPropertyDetailsPayload, finalTransactionPayload, history }))
        }
      } else {
        yield put(CRMV2Actions.createClientTransactionAction.request({ finalTransactionPayload, history }))
      }
    }
  } catch (error) {
    console.log(error)
    customToast("There was an error while submitting the lead. Please try again later.", "error")
    yield put(CRMV2Actions.addClientAction.failure(error))
  }
}

function* getPropertyTypes() {
  try {
    const res = yield call(CRMV2API.getProprtyTypesAPI)
    if (isSuccess(res)) {
      const { response: { types = [] } = {} } = res.data
      const mappedTypes = types.map(item => ({ label: item, value: item }))
      yield put(CRMV2Actions.getPropertyTypeAction.success({ types: mappedTypes }))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getPropertyTypeAction.failure(error))
  }
}

function* handleGetBrokerageTransactionStatus(action) {
  try {
    const { transactionId } = action.data
    const res = yield call(CRMV2API.getBrokerageTransactionStatusAPI, transactionId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getBrokerageTransactionStatusAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.getBrokerageTransactionStatusAction.failure(error))
  }
}

function* handleAddBrokerageTransaction(action) {
  try {
    const { payload, transactionId, callback } = action.data
    const res = yield call(CRMV2API.addBrokerageTransactionAPI, transactionId, payload)
    const client_id = yield select(state => state.CRMV2.getCtIdResponse.data.client_id)
    const type = yield select(state => state.CRMV2.getCtIdResponse.data.type)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.addBrokerageTransactionAction.success(response))
      if (callback) {
        callback(response.id, response.transactionType)
      }
      yield put(CRMV2Actions.getClientAction.request({
        clientId: client_id,
        type,
        togglePage: false,
      }))
    }
  } catch (error) {
    yield put(CRMV2Actions.addBrokerageTransactionAction.failure(error))
  }
}

function* addBuyerRequirements(action) {
  try {
    const { finalSearchCriteriaPayload, finalTransactionPayload, history } = action.data
    const res = yield call(CRMV2API.addBuyerRequirementsAPI, finalSearchCriteriaPayload)
    const agentInfo = state => state.CRMV2.isUserOverviewDrawerOpen.agentInfo
    // if (agentInfo) {
    //   yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: true, agentInfo }))
    // }
    if (isSuccess(res)) {
      const { response } = res.data
      const { is_pre_exist } = response
      yield put(CRMV2Actions.addBuyerRequirementsAction.success(response))
      if (is_pre_exist) {
        return
      }
      const { req_id } = response
      const transactionPayload = {
        ...finalTransactionPayload,
        req_id,
      }

      if (finalTransactionPayload) {
        yield put(CRMV2Actions.createClientTransactionAction.request({
          finalTransactionPayload: transactionPayload, history,
        }))
      }
    }
  } catch (error) {
    console.log(error)
    customToast("There was an error while submitting the requirements of the lead. Please try again later.", "error")
    yield put(CRMV2Actions.addBuyerRequirementsAction.failure(error))
  }
}

function* getBuyerRequirements(action) {
  try {
    const clientId = action.data
    const res = yield call(CRMV2API.getBuyerRequirementsAPI, clientId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getBuyerRequirementsAction.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getBuyerRequirementsAction.failure(error))
  }
}

function* getPropertyDetails(action) {
  try {
    const propId = action.data
    const res = yield call(CRMV2API.getPropertyDetailsAPI, propId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getPropertyDetailsAction.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getPropertyDetailsAction.failure(error))
  }
}

function* addPropertyDetails(action) {
  try {
    const { finalPropertyDetailsPayload, finalTransactionPayload, history } = action.data
    const res = yield call(CRMV2API.addPropertyDetailsAPI, finalPropertyDetailsPayload)
    const agentInfo = state => state.CRMV2.isUserOverviewDrawerOpen.agentInfo
    // if (agentInfo) {
    //   yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: true, agentInfo }))
    // }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.addPropertyDetailsAction.success(response))
      const { is_pre_exist } = response
      if (is_pre_exist) {
        return
      }
      const { basic_id } = response
      const transactionPayload = {
        ...finalTransactionPayload,
        prop_id: basic_id,
      }

      if (finalPropertyDetailsPayload && finalPropertyDetailsPayload.client_id) {
        transactionPayload.client_id = finalPropertyDetailsPayload.client_id
      }

      if (finalTransactionPayload) {
        yield put(CRMV2Actions.createClientTransactionAction.request({ finalTransactionPayload: transactionPayload, history }))
      }
    }
  } catch (error) {
    console.log(error)
    customToast("There was an error while submitting the requirements of the lead. Please try again later.", "error")
    yield put(CRMV2Actions.addPropertyDetailsAction.failure(error))
  }
}

function* createClientTransaction(action) {
  try {
    const { finalTransactionPayload, history } = action.data || {}
    const {
      type, client_id, prop_id, req_id, status,
    } = finalTransactionPayload || {}
    const currentFilters = yield select(state => state.CRMV2.currentFilters)
    const res = yield call(CRMV2API.createClientTransactionAPI, finalTransactionPayload)
    yield put(CRMV2Actions.toggleDrawerPage.call("drawer-header"))
    if (isSuccess(res)) {
      const { response } = res.data
      const { transaction_id } = response || {}
      yield put(CRMV2Actions.createClientTransactionAction.success(response))
      yield put(CRMV2Actions.getClientTransactionByIdAction.request({ transactionId: transaction_id }))
      yield put(CRMV2Actions.setCurrentClientTransactionIDAction.call({ id: client_id, deal_id: transaction_id }))
      yield put(CRMV2Actions.getClientTransactionsAction.request({ currentFilters }))
      yield put(CRMV2Actions.toggleAddNewLeadDrawer.call(false))
      yield put(CRMV2Actions.getClientAction.request({
        clientId: client_id,
        type,
      }))
      if (status === "In Contract") {
        const transactionId = transaction_id
        const payload = {
          transactionId: transaction_id,
          transactionType: "Contract",
        }
        const resData = yield call(CRMV2API.addBrokerageTransactionAPI, transactionId, payload)
        // if (isSuccess(resData)) {
        //   const { response: addContractResponse } = resData.data
        //   yield put(setDealToView.call(addContractResponse?.id))
        // }
      }
      yield put(CRMV2Actions.getBrokerageTransactionStatusAction.request({ transactionId: transaction_id }))
      yield put(CRMV2Actions.setIsAddListing.call(false))
      yield put(CRMV2Actions.setIsCreateWarning.call(false))
      yield put(CRMV2Actions.setIsAddOffer.call(false))
      history.push(`?transactionId=${transaction_id}&client_id=${client_id}&type=${type}`)
      customToast("Transaction Created")
    }
  } catch (error) {
    console.log(error)
    customToast("There was an error while submitting the details of the transaction. Please try again later.", "error")
    yield put(CRMV2Actions.createClientTransactionAction.failure(error))
  }
}

function* handleImageUpload(action) {
  try {
    const {
      file,
      objName,
      fileName,
    } = action.data

    const signedRes = yield fetch(`${FILE_UPLOAD_API}bucket=&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      console.log("file upload saga", signedResJson)
      const existingObj = yield select(state => state.CRMV2.uploadToS3Response.data)
      console.log(existingObj)
      // if (existingObj) {
      //   yield put(CRMV2Actions.uploadImageAction.success({
      //     [objName]: {
      //       [objName]: signedResJson.fileUrlAfterUpload,
      //       [`${objName}FileName`]: fileName,
      //     },
      //     ...existingObj,
      //   }))
      // } else {
      //   yield put(CRMV2Actions.uploadImageAction.success({
      //     [objName]: {
      //       [objName]: signedResJson.fileUrlAfterUpload,
      //       [`${objName}FileName`]: fileName,
      //     },
      //   }))
      // }
    }
  } catch (e) {
    console.log("error====", e)
  }
}

function* getClientFilters() {
  try {
    const res = yield call(CRMV2API.getClientFilterSkeleton)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getClientsFilters.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.getClientsFilters.failure(error))
  }
}

function* changeCardOrder(action) {
  try {
    const { source, destination, draggableId } = action.data
    const dragDropColumns = yield select(state => state.CRMV2.getClientTransactionResponse.data.dragDropColumns)
    const newReferralIds = Array.from(dragDropColumns[source.droppableId])
    newReferralIds.splice(source.index, 1)
    newReferralIds.splice(destination.index, 0, draggableId)
    const payload = {
      dragDropColumns: {
        ...dragDropColumns,
        [source.droppableId]: newReferralIds,
      },
    }
    yield put(CRMV2Actions.changeCardOrderAction.success(payload))
  } catch (e) {
    console.log("Failed to change order of the card")
  }
}

function* changeCardStage(action) {
  try {
    const { source, destination, draggableId, currentFilters } = action.data
    const { dragDropColumns } = yield select(state => state.CRMV2.getClientTransactionResponse.data)
    const initialStageTransactions = Array.from(dragDropColumns[source.droppableId])
    initialStageTransactions.splice(source.index, 1)
    const finalStageTransactions = Array.from(dragDropColumns[destination.droppableId])
    finalStageTransactions.splice(destination.index, 0, draggableId)
    const payload = {
      dragDropColumns: {
        ...dragDropColumns,
        [source.droppableId]: initialStageTransactions,
        [destination.droppableId]: finalStageTransactions,
      },
    }
    yield put(CRMV2Actions.changeCardStageAction.success(payload))
    const query = `/${draggableId}`
    const res = yield call(CRMV2API.getClientTransactionsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      const {
        id, client_id, type, radius_transaction_type,
      } = response
      console.log("check", response)
      // this condition is specifically for in contract to closed transition
      // if radius_transaction_type exists we let it update
      // otherwise we show a modal with a message
      //if (radius_transaction_type === null && source.droppableId === IN_CONTRACT && destination.droppableId === CLOSED) {
      if (source.droppableId === IN_CONTRACT && destination.droppableId === CLOSED) {
        yield put(CRMV2Actions.toggleShowInContractAction.call(true))
        // const initialPostCheckStageTransactions = Array.from(dragDropColumns[destination.droppableId])
        // initialStageTransactions.splice(source.index, 1)
        // const finalPostCheckStageTransactions = Array.from(dragDropColumns[source.droppableId])
        // finalStageTransactions.splice(destination.index, 0, draggableId)
        // const postCheckpayload = {
        //   dragDropColumns: {
        //     ...dragDropColumns,
        //     [destination.droppableId]: initialPostCheckStageTransactions,
        //     [source.droppableId]: finalPostCheckStageTransactions,
        //   },
        // }
        // yield put(CRMV2Actions.changeCardStageAction.success(postCheckpayload))
        // return
      }
      if (id) {
        const updatePayload = {
          status: destination.droppableId,
          client_id,
          type,
        }
        //we are not allowing to update from CRM anymore
        // yield put(CRMV2Actions.updateClientTransactionAction
        //   .request({ payload: updatePayload, transactionId: id, currentFilters }))
      }
    }
  } catch (error) {
    console.log("Failed to changed stage of the card")
  }
}

function* handleUpdateContract(action) {
  try {
    const {
      dealId, payload, callback,
    } = action.data
    const res = yield call(CRMV2API.updateContractCRMAPI, dealId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateContractCRMAction.success(res.data && res.data.response))
      callback(response)
    }
  } catch (e) {
    yield put(CRMV2Actions.updateContractCRMAction.failure(e))
  }
}

function* createReminder(action) {
  try {
    const { payload, clientId } = action.data
    delete payload.isEdit
    const res = yield call(CRMV2API.createReminderAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.setReminderAction.success(response))
      yield put(CRMV2Actions.fetchClientReminderAction.request({
        transactionId: payload.client_transaction_id,
      }))
      yield put(CRMV2Actions.toggleReminderModalAction.call({
        data: {},
        bool: false,
      }))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId: payload.client_transaction_id }))
      customToast("Reminder Added")
    }
  } catch (error) {
    yield put(CRMV2Actions.setReminderAction.failure(error))
  }
}

function* deleteReminder(action) {
  try {
    const { id, clientId, transactionId } = action.data
    const res = yield call(CRMV2API.deleteReminderAPI, clientId, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.deleteReminderAction.success(response))
      yield put(CRMV2Actions.fetchClientReminderAction.request({
        transactionId,
      }))
      customToast("Reminder deleted")
    }
  } catch (error) {
    yield put(CRMV2Actions.deleteReminderAction.failure(error))
  }
}

function* addNote(action) {
  try {
    const { payload } = action.data
    const {
      client_transaction_id, clientId, note, share_with_sender_agent,
    } = payload
    const finalPayload = {
      client_transaction_id,
      note,
      share_with_sender_agent,
    }
    const res = yield call(CRMV2API.addNoteAPI, clientId, finalPayload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.addNotesAction.success(response))
      yield put(CRMV2Actions.getNotesAction.request({ transactionId: client_transaction_id, limit: 10, offset: 0 }))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId: client_transaction_id }))
      customToast("Note added")
    }
  } catch (error) {
    yield put(CRMV2Actions.addNotesAction.failure(error))
  }
}

function* getNote(action) {
  try {
    const { transactionId, limit, offset } = action.data
    const pagination = `limit=${limit}&offSet=${offset}`
    const query = `${transactionId}?${pagination}`
    const res = yield call(CRMV2API.getNoteAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getNotesAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.getNotesAction.failure(error))
  }
}

function* updateNote(action) {
  try {
    const {
      clientId, noteId, payload, id,
    } = action.data
    const res = yield call(CRMV2API.updateNoteAPI, clientId, noteId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateNotesAction.success(response))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId: id, limit: 10, offset: 0 }))
    }
  } catch (error) {
    yield put(CRMV2Actions.updateNotesAction.failure(error))
  }
}

function* deleteNote(action) {
  try {
    const { clientId, noteId, transactionId } = action.data
    const res = yield call(CRMV2API.deleteNoteAPI, clientId, noteId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.deleteNotesAction.success(response))
      yield put(CRMV2Actions.getNotesAction.request({ transactionId, limit: 10, offset: 0 }))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId }))
      customToast("Note deleted")
    }
  } catch (error) {
    yield put(CRMV2Actions.deleteNotesAction.failure(error))
  }
}

function* getTimeline(action) {
  try {
    const { transactionId } = action.data
    const res = yield call(CRMV2API.getTimelineAPI, transactionId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getTimelineAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.getTimelineAction.failure(error))
  }
}

function* getFinancingForTransaction(action) {
  try {
    const { transactionId } = action.data
    const res = yield call(CRMV2API.getFinancingForTransactionAPI, transactionId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getFinancingForTransactionAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.getFinancingForTransactionAction.failure(error))
  }
}

function* createFinancingForTransaction(action) {
  try {
    const { transactionId, payload, showToast } = action.data
    const res = yield call(CRMV2API.createFinancingForTransactionAPI, transactionId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.createFinancingForTransactionAction.success(response))
      yield put(CRMV2Actions.getFinancingForTransactionAction.request({ transactionId }))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId }))
      if (showToast) {
        customToast("Thanks for applying")
      }
    }
  } catch (error) {
    yield put(CRMV2Actions.createFinancingForTransactionAction.failure(error))
  }
}

function* updateFinancingForTransaction(action) {
  try {
    const { transactionId, payload, showToast } = action.data
    const res = yield call(CRMV2API.updateFinancingForTransactionAPI, transactionId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateFinancingForTransactionAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.createFinancingForTransactionAction.failure(error))
  }
}

function* updateClient(action) {
  try {
    const { clientId, payload, currentFilters } = action.data
    const res = yield call(CRMV2API.updateClientAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateClientAction.success(response))
      yield put(CRMV2Actions.getClientAction.request({
        clientId,
      }))
      yield put(CRMV2Actions.getClientTransactionsAction.request({ currentFilters }))
      customToast("Client Updated")
    }
  } catch (error) {
    yield put(CRMV2Actions.updateClientAction.failure(error))
  }
}

function* getReminder(action) {
  try {
    const { transactionId } = action.data
    const res = yield call(CRMV2API.getRemindersAPI, transactionId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.fetchClientReminderAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.fetchClientReminderAction.failure(error))
  }
}

function* toggleAgentFinance(action) {
  try {
    const res = yield call(CRMV2API.toggleAgentFinancePreferenceAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.toggleAgentFinancePreferenceAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.toggleAgentFinancePreferenceAction.failure(error))
  }
}

function* addCoClientDetails(action) {
  try {
    const { payload } = action.data
    const { transaction_id } = payload
    const res = yield call(CRMV2API.addCoClientDetailsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.addCoClientDetailsAction.success(response))
      yield put(CRMV2Actions.getClientTransactionByIdAction.request({ transactionId: transaction_id }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* updateCoClientDetails(action) {
  try {
    const { payload, id } = action.data
    const { transaction_id } = payload
    const res = yield call(CRMV2API.updateCoClientDetailsAPI, payload, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateCoClientDetailsAction.success(response))
      yield put(CRMV2Actions.getClientTransactionByIdAction.request({ transactionId: transaction_id }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteCoClientDetails(action) {
  try {
    const {
      id, clientTransactionId, params,
    } = action.data || {}
    const res = yield call(CRMV2API.deleteCoClientDetailsAPI, id, params)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.deleteCoClientDetailsAction.success(response))
      yield put(CRMV2Actions.getClientTransactionByIdAction.request({ transactionId: clientTransactionId }))
    }
  } catch (e) {
    console.log(e)
  }
}
function* archiveLead(action) {
  try {
    const { payload, currentFilters, history } = action.data || {}
    const res = yield call(CRMV2API.archiveLeadAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.archiveLeadAction.success(response))
      customToast("Lead Archived")
      yield put(CRMV2Actions.getClientTransactionsAction.request({
        currentFilters,
      }))
      const isDrawerOpen = yield select(state => state.CRMV2.isUserOverviewDrawerOpen.showUserOverviewDrawer)
      if (isDrawerOpen) {
        yield put(CRMV2Actions.toggleUserOverviewDrawer.call(false))
      }
      history.push("/crm")
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.archiveLeadAction.failure(error))
  }
}

function* unArchiveLead(action) {
  try {
    const { payload, currentFilters } = action.data
    const res = yield call(CRMV2API.unArchiveLeadAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.unArchiveLeadAction.success(response))
      customToast("Leads Unarchived")
      yield put(CRMV2Actions.getClientTransactionsAction.request({ currentFilters }))
    }
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.unArchiveLeadAction.failure(error))
  }
}

function* deleteClientTransaction(action) {
  try {
    const { transactionId, currentFilters, soft_delete } = action.data
    const res = yield call(CRMV2API.deleteClientTransactionAPI, transactionId, soft_delete)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.deleteClientTransactionAction.success(response))
      customToast("Client Transaction Deleted")
      yield put(CRMV2Actions.getClientTransactionsAction.request(currentFilters))
      yield put(CRMV2Actions.toggleUserOverviewDrawer.call({ showUserOverviewDrawer: false, agentInfo: {} }))
      yield put(CRMV2Actions.setCurrentClientIDAction.call(null))
    }
  } catch (error) {
    yield put(CRMV2Actions.deleteClientTransactionAction.failure(error))
  }
}

function* updateReminder(action) {
  try {
    const { payload, client_id, reminder_id } = action.data
    const res = yield call(CRMV2API.updateReminderAPI, client_id, reminder_id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.updateReminderAction.success(response))
      yield put(CRMV2Actions.fetchClientReminderAction.request({
        transactionId: payload.client_transaction_id,
      }))
      yield put(CRMV2Actions.toggleReminderModalAction.call({
        data: {},
        bool: false,
      }))
      yield put(CRMV2Actions.getTimelineAction.request({ transactionId: payload.client_transaction_id }))
      customToast("Reminder Updated")
    }
  } catch (error) {
    yield put(CRMV2Actions.updateReminderAction.failure(error))
  }
}

//getAgentDetailsAction
function* handleGetAgentDetails(action) {
  try {
    const { agentId, state } = action.data
    const res = yield call(CRMV2API.getAgentInformationAPI, agentId, state)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(CRMV2Actions.getAgentDetailsAction.success(response))
    }
  } catch (error) {
    yield put(CRMV2Actions.getAgentDetailsAction.failure(error))
  }
}

function* handleConfirmMoveToContract(action) {
  try {
    const { clientId, transactionId, history, isSeller } = action.data
    const transactionDetailsFetch = yield call(CRMV2API.getClientTransactionsAPI, `/${transactionId}`)
    const clientDetailsFetch = yield call(CRMV2API.getClientAPI, `/${clientId}`)

    const payload = {
      clients: [],
      listing_address: "",
      house_number: "",
      city: "",
      county: "",
      zipcode: "",
      file_type: "",
      client_type: "",
      state: "",
      price: "",
      list_price: "",
      rent_amount: "",
      rent_amount_term: "",
      other_rent_amount_term: "",
      commission_type: "",
      commision_percentage: "",
      fixed_fee: "",
      team_split_percentage: "",
      created_at: "", //might need change
      closed_on: "", //might need change
      lease_commencement_on: "", //mnc
      transaction_type: "",
      other_transaction_type: "",
      is_all_cash_offer: "",
      lender_email_address: "",
      country: "",
      agents_info: [],
      completed_steps: [],
      step: false,
      agent_first_name: "",
      agent_last_name: "",
      agent_license_number: "",
      agent_email: "",
      agent_phone: "",
      brokerage_address: "",
      brokerage_license_number: "",
      apt: "",
      agent_type: "",
      email: "",
      phone: "",
      agent_id: "",
    }
    if (isSuccess(transactionDetailsFetch)) {
      const { response } = transactionDetailsFetch.data
      console.log("transaction", response)

      const {
        type,
        client_source,
        req_id,
        agent_id,
        property,
      } = response || {}

      if (isSeller) {
        console.log("property", property)
        const {
          state,
          zipcode,
          city,
          county,
          address,
          price,
        } = response.property || {}

        payload.listing_address = address
        payload.zipcode = zipcode
        payload.state = state
        payload.city = city
        payload.county = county
        payload.rent_amount = ""
        payload.rent_amount_term = ""
        payload.client_source = client_source
        payload.other_rent_amount_term = ""
        payload.client_type = type
        payload.agent_type = type
        payload.req_id = req_id
        payload.agent_id = agent_id
        payload.list_price = price
        payload.file_type = "listing_with_accepted_offer"
      } else {
        const {
          rent_amount,
          rent_amount_term,
          other_rent_amount_term,
        } = response.searchCriteria || {}

        if (response.searchCriteria
          && response.searchCriteria.locations
          && response.searchCriteria.locations.length > 0) {
          const {
            c_locality_name,
            state,
            zipcode,
            city,
            county,
          } = response.searchCriteria.locations[0] || {}

          payload.listing_address = c_locality_name
          payload.zipcode = zipcode
          payload.state = state
          payload.city = city
          payload.county = county
          payload.rent_amount = rent_amount
          payload.rent_amount_term = rent_amount_term
          payload.client_source = client_source
          payload.other_rent_amount_term = other_rent_amount_term
          payload.client_type = type
          payload.agent_type = type
          payload.req_id = req_id
          payload.agent_id = agent_id
          payload.file_type = "pending_contract"
        }
      }
    }

    if (isSuccess(clientDetailsFetch)) {
      const { response } = clientDetailsFetch.data
      const {
        name,
        emails,
        phones,
        id,
      } = response

      const primaryClient = {
        source: payload.client_source || "",
        //agentId: payload.agent_id,
        name,
        email: emails && emails.length > 0 ? emails[0].email : "",
        phone: phones && phones.length > 0 ? phones[0].phone : "",
        client_id: id,
        req_id: payload.req_id,
        is_primary: 1,
      }

      payload.clients = [primaryClient]
    }

    let agentInfo = null
    if (payload.agent_id && payload.state) {
      agentInfo = yield call(CRMV2API.getAgentInformationAPI, payload.agent_id, payload.state)
      if (isSuccess(agentInfo)) {
        const { response } = agentInfo.data
        const primaryAgent = {
          ...response,
          is_primary: 1,
          agent_id: payload.agent_id,
          agent_type: payload.client_type,
        }

        payload.agents_info = [primaryAgent]
      }
    }
    yield put(TechAssetsActions.getContractById.success([payload]))
    yield put(CRMV2Actions.confirmMoveToCreateContractAction.success(payload))
    yield put(CRMV2Actions.toggleGoToContract.call({
      isShowGoToContract: false,
    }))
    yield put(TechAssetsActions.setEditingDealTeamMemberId.call(payload.agent_id))
    yield put(TechAssetsActions.setDealToView.call(transactionId))
    history.push(`/realty/transaction-coordination/contract/form?transaction_phase=true&transaction_id=${transactionId}`)
  } catch (error) {
    console.log(error)
    yield put(CRMV2Actions.confirmMoveToCreateContractAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(CRMV2Actions.getClientAction.REQUEST, getClient)
  yield takeLatest(CRMV2Actions.addClientAction.REQUEST, addClient)
  yield takeLatest(CRMV2Actions.getPropertyTypeAction.REQUEST, getPropertyTypes)
  yield takeEvery(CRMV2Actions.addBuyerRequirementsAction.REQUEST, addBuyerRequirements)
  yield takeEvery(CRMV2Actions.addPropertyDetailsAction.REQUEST, addPropertyDetails)
  yield takeEvery(CRMV2Actions.createClientTransactionAction.REQUEST, createClientTransaction)
  yield takeLatest(CRMV2Actions.getClientTransactionsAction.REQUEST, getClientTransactions)
  yield takeLatest(CRMV2Actions.uploadImageAction.REQUEST, handleImageUpload)
  yield takeLatest(CRMV2Actions.getBuyerRequirementsAction.REQUEST, getBuyerRequirements)
  yield takeLatest(CRMV2Actions.getPropertyDetailsAction.REQUEST, getPropertyDetails)
  yield takeLatest(CRMV2Actions.changeCardOrderAction.REQUEST, changeCardOrder)
  yield takeLatest(CRMV2Actions.updateClientTransactionAction.REQUEST, updateClientTransaction)
  yield takeLatest(CRMV2Actions.updateBuyerRequirementsAction.REQUEST, updateBuyerRequirement)
  yield takeLatest(CRMV2Actions.updatePropertyDetailsAction.REQUEST, updatePropertyDetails)
  yield takeLatest(CRMV2Actions.changeCardStageAction.REQUEST, changeCardStage)
  yield takeLatest(CRMV2Actions.getClientsFilters.REQUEST, getClientFilters)
  yield takeLatest(CRMV2Actions.setReminderAction.REQUEST, createReminder)
  yield takeLatest(CRMV2Actions.addNotesAction.REQUEST, addNote)
  yield takeLatest(CRMV2Actions.getNotesAction.REQUEST, getNote)
  yield takeLatest(CRMV2Actions.updateNotesAction.REQUEST, updateNote)
  yield takeLatest(CRMV2Actions.deleteNotesAction.REQUEST, deleteNote)
  yield takeLatest(CRMV2Actions.getTimelineAction.REQUEST, getTimeline)
  yield takeLatest(CRMV2Actions.getBrokerageTransactionStatusAction.REQUEST, handleGetBrokerageTransactionStatus)
  yield takeLatest(CRMV2Actions.addBrokerageTransactionAction.REQUEST, handleAddBrokerageTransaction)
  yield takeLatest(CRMV2Actions.deleteReminderAction.REQUEST, deleteReminder)
  yield takeLatest(CRMV2Actions.getFinancingForTransactionAction.REQUEST, getFinancingForTransaction)
  yield takeLatest(CRMV2Actions.createFinancingForTransactionAction.REQUEST, createFinancingForTransaction)
  yield takeLatest(CRMV2Actions.updateClientAction.REQUEST, updateClient)
  yield takeLatest(CRMV2Actions.fetchClientReminderAction.REQUEST, getReminder)
  yield takeLatest(CRMV2Actions.getClientTransactionByIdAction.REQUEST, getClientTransactionById)
  yield takeLatest(CRMV2Actions.toggleAgentFinancePreferenceAction.REQUEST, toggleAgentFinance)
  yield takeLatest(CRMV2Actions.addCoClientDetailsAction.REQUEST, addCoClientDetails)
  yield takeLatest(CRMV2Actions.updateCoClientDetailsAction.REQUEST, updateCoClientDetails)
  yield takeLatest(CRMV2Actions.deleteCoClientDetailsAction.REQUEST, deleteCoClientDetails)
  yield takeLatest(CRMV2Actions.updateContractCRMAction.REQUEST, handleUpdateContract)
  yield takeLatest(CRMV2Actions.archiveLeadAction.REQUEST, archiveLead)
  yield takeLatest(CRMV2Actions.unArchiveLeadAction.REQUEST, unArchiveLead)
  yield takeLatest(CRMV2Actions.deleteClientTransactionAction.REQUEST, deleteClientTransaction)
  yield takeLatest(CRMV2Actions.updateFinancingForTransactionAction.REQUEST, updateFinancingForTransaction)
  yield takeLatest(CRMV2Actions.updateReminderAction.REQUEST, updateReminder)
  yield takeLatest(CRMV2Actions.getAgentDetailsAction.REQUEST, handleGetAgentDetails)
  yield takeLatest(CRMV2Actions.confirmMoveToCreateContractAction.REQUEST, handleConfirmMoveToContract)
}
