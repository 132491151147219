import React, { useState } from "react"
import OffersV2 from "container/OffersV2/index"
import OldOffers from "container/TechAssets"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { LISTING_DOCS, OFFER_DOCS } from "dumbComponents/OffersV3/consts"
import {
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import { EyeIcon, ShareIcon } from "dumbComponents/OffersV2/icons"
import SearchBar from "../../../SearchBar"
import { Wrap, SubmitWrap, StickyWrap } from "./style"
import CheckboxList from "../../../CheckboxList/index"

const DocsUI = ({
  docs,
  handleSubmit,
  updateOfferDocsList,
  dealToView,
  offersDocList,
  getTemplatePreview,
  mainList,
  updatingRecommendedDocs,
  customHandle,
  listingDocsList,
  updateListingOfferDocs,
  currentLibraryTab,
  isUploadModal,
  placeholder,
}) => {
  const [filterText, setFilterText] = useState("")

  const OptionList2 = []

  // const MainListV2 = [
  //   {
  //     label: "VIEW",
  //     image: <EyeIcon />,
  //     onClick: () => {

  //     },
  //     showDivide: false,
  //   },
  // ]

  const filterFunction = (val) => {
    console.log(val)
    setFilterText(val)
  }

  const handleSelectedState = (e, eID) => {
    const checked = e.target.checked
    if (currentLibraryTab === OFFER_DOCS) {
      const currentDocs = [
        ...offersDocList,
      ]

      currentDocs.forEach((item) => {
        console.log(item.id, eID)
        if (item.id === eID) {
          item.isSelected = checked
        }
      })

      updateOfferDocsList(currentDocs)
    }
    if (currentLibraryTab === LISTING_DOCS) {
      const currentDocs = [
        ...listingDocsList,
      ]

      currentDocs.forEach((item) => {
        console.log(item.id, eID)
        if (item.id === eID) {
          item.isSelected = checked
        }
      })

      updateListingOfferDocs(currentDocs)
    }
  }

  const isSelected = offersDocList && offersDocList.length > 0 && offersDocList.filter(doc => doc.isSelected)

  return (
    <Wrap flex>
      <SearchBar
        customFunc={filterFunction}
        placeholder={placeholder}
      />
      {docs && docs.map((item) =>
            <CheckboxList
        list={item}
        onClick={e => { console.log("clicked")}}
        handleSelectedCheck={customHandle || handleSelectedState}
        dropdownList={OptionList2}
        mainList={mainList}
        customFilterText={filterText}
        isUploadModal={isUploadModal}
      />
      )}
  
      {handleSubmit && typeof handleSubmit === "function" && (
      <StickyWrap>
        <SubmitWrap>
          <BlueButton disabled={isSelected.length === 0 || updatingRecommendedDocs} maxWidth onClick={handleSubmit}>
            {updatingRecommendedDocs ? (
              <ThreeDotLoader color="#fff" />
            ) : (
              <p>Attach selected documents</p>
            )}
          </BlueButton>
          </SubmitWrap>
          </StickyWrap>

      )}
    </Wrap>
  )
}

export default OldOffers(OffersV2(DocsUI))
