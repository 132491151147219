import React, { useRef } from "react"
import styled from "styled-components"
import colors from "@colors"
import CRMV2Container from "container/CRM"
import moment from "moment"
import {
  Form, Radio, Select, Space, Input,
  Button, DatePicker,
} from "@ui/antd"
import {
  LabelComponent, Wrap, H1,
} from "../common"

const { Option } = Select

const { Item } = Form

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.silentWhite};
`

const OverviewInfo = styled.div`
  position: relative;
  padding: 10px 0px 12px 10px;
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    label::after {
      display: none;
    }
  }
`

const CustomSelect = styled(Select)`
  width: 200px !important;
  border-radius: 50px;
  .ant-select-selector {
    height: 35px !important;
    background-color: ${colors.dreamWhite} !important;
    border: 1px solid ${colors.moonGrey} !important;
  }
`

const CustomInput = styled(Input)`
  width: 234px !important;
  height: 35px !important;
  background: #FFFFFF !important;
  border: 1px solid ${colors.moonGrey} !important;
  border-radius: 8px !important;
`

const CustomItem = styled(Item)`
  .ant-row {
    gap: ${props => props.gap};
  }
  .ant-picker {
    width: 200px;
  }
`

const ButtonWrap = styled.div`
  position: absolute;
  right: 16px;
`

const BuyerLeadOverview = ({
  is_working_with_agent,
  is_pre_approved,
  transactionId,
  updateClientTransaction,
  client_id,
  type,
  timeline: currentTimeline,
  toggleUserOverview,
}) => {
  const { data: updatedData } = updateClientTransaction
  const {
    is_pre_approved: updated_is_pre_approved,
    is_working_with_agent: updated_is_working_with_agent,
    timeline: updatedTimeline,
  } = updatedData || {}
  const ref = useRef()

  const onFinish = (values) => {
    const { timeline, is_working_with_agent, is_pre_approved } = values
    console.log(values, "values")
    console.log(timeline, "timeline")
    updateClientTransaction({
      transactionId,
      payload: {
        is_working_with_agent: is_working_with_agent,
        is_pre_approved: is_pre_approved,
        client_id,
        type,
        timeline: timeline ? timeline.format("YYYY-MM-DD") : currentTimeline,
      },
    })
    toggleUserOverview(false)
  }
  return (
    <Wrap>
      <HeaderWrap>
        <H1>
          Information
        </H1>
      </HeaderWrap>
      <OverviewInfo>
        <Form
          onFinish={onFinish}
          ref={ref}
        >
          <CustomItem name="is_working_with_agent" gap="15px" label={<LabelComponent text="Working with Agent" fontSize={14} fontWeight={500} />}>
            <Radio.Group
              defaultValue={updated_is_working_with_agent ? updated_is_working_with_agent.toString() : is_working_with_agent?.toString()}
            >
              <Space direction="horizontal">
                <Radio value="1">
                  <LabelComponent text="Yes" />
                </Radio>
                <Radio value="0">
                  <LabelComponent text="No" />
                </Radio>
              </Space>
            </Radio.Group>
          </CustomItem>
          <CustomItem name="is_pre_approved" gap="59px" label={<LabelComponent text="Pre-approved" fontSize={14} fontWeight={500} />}>
            <Radio.Group
              defaultValue={updated_is_pre_approved ? updated_is_pre_approved.toString() : is_pre_approved?.toString()}
            >
              <Space direction="horizontal">
                <Radio value="1">
                  <LabelComponent text="Yes" />
                </Radio>
                <Radio value="0">
                  <LabelComponent text="No" />
                </Radio>
              </Space>
            </Radio.Group>
          </CustomItem>
          <Space style={{ display: "flex", marginTop: 10 }} align="baseline">
            <CustomItem name="timeline" gap="92px" label={<LabelComponent text="Timeline" fontSize={14} fontWeight={500} />}>
              <DatePicker
                format="MMMM Do YYYY"
                showTime
                defaultValue={currentTimeline ? moment(currentTimeline) : moment(new Date(), "MMMM Do YYYY")}
              />
            </CustomItem>
          </Space>
          <Space style={{ display: "flex", marginTop: 10 }} align="baseline">
            <CustomItem gap="46px" label={<LabelComponent text="Referral Source" fontSize={14} fontWeight={500} />}>
              <CustomInput defaultValue="CRM" disabled />
            </CustomItem>
          </Space>
          <Form.Item>
            <ButtonWrap>
              <Button
                style={{
                  float: "right",
                  background: `${colors.marineBLue}`,
                  borderColor: `${colors.marineBLue}`,
                }}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </ButtonWrap>
          </Form.Item>
        </Form>
      </OverviewInfo>
    </Wrap>
  )
}

export default CRMV2Container(BuyerLeadOverview)
