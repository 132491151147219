import styled, {createGlobalStyle} from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";
import { Modal } from "antd";

// const GlobalModalStyle = createGlobalStyle`
//   .ant-modal-wrap .ant-modal-centered {
//   background-color: red !important; /* Debugging color */
//     @media (max-width: 1024px) {
//       opacity: 0 !important;
//       visibility: hidden !important;
//       transition: opacity 0.3s ease !important;
//     }
//   }

//   .ant-modal-centered .ant-modal {
//   background-color: blue !important; /* Debugging color */
//     @media (max-width: 765px) {
//       max-width: 100vw !important;
//       top: 0;
//       vertical-align: bottom;
//       margin-bottom: 0;
//       border-bottom-right-radius: 0px;
//       border-bottom-left-radius: 0px;
//       transform-origin: bottom !important;
//       transition: transform 0.3s ease !important;
//     }
//   }
// `;

const ModalWrap = styled.body`
  .ant-modal-wrap .ant-modal-centered {
  background-color: red !important; /* Debugging color */
    @media (max-width: 1024px) {
      opacity: 0 !important;
      visibility: hidden !important;
      transition: opacity 0.3s ease !important;
    }
  }

  .ant-modal-centered .ant-modal {
  background-color: blue !important; /* Debugging color */
    @media (max-width: 765px) {
      max-width: 100vw !important;
      top: 0;
      vertical-align: bottom;
      margin-bottom: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      transform-origin: bottom !important;
      transition: transform 0.3s ease !important;
    }
  }
`;

const CustomAntModal = styled(Modal)`
  .ant-input {
    border-radius: 5px;
  }

  .ant-btn {
    background-color: #5a5ff2;
    height: 48px;
    border-radius: 32px;
  }

  .ant-btn:hover {
    background-color: #5a5ff2;
  }

  .ant-btn::selection:after {
    background-color: #5a5ff2 !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-wrap .ant-modal-centered {
  background-color: red !important; /* Debugging color */
    @media (max-width: 1024px) {
      opacity: 0 !important;
      visibility: hidden !important;
      transition: opacity 0.3s ease !important;
    }
  }

  .ant-modal-centered .ant-modal {
  background-color: blue !important; /* Debugging color */
    @media (max-width: 765px) {
      max-width: 100vw !important;
      top: 0;
      vertical-align: bottom;
      margin-bottom: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      transform-origin: bottom !important;
      transition: transform 0.3s ease !important;
    }
  }

  .ant-modal-mask{
    background-color: blue !important;
  }

  .ant-modal-body {
    padding: 0px 16px;
  }

  .ant-modal-header {
    padding-bottom: 0px;
  }

  .ant-modal-content {

    @media (max-width: 765px) {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .ant-form-item-required {
    flex-direction: row-reverse;
  }

  .ant-form-item-required::before {
    margin-left: 4px;
    color: black !important;
  }

  .ant-form-item-required::after {
    width: 0;
    margin: 0 !important;
    color: black;
  }

  .ant-input {
    height: 40px;
  }

  .ant-modal-header{
    padding-right: 0;
    padding-left: 0;
  }

  .ant-input:focus{
    border: 1px solid #5A5FF2 !important;
  }

  .ant-input:hover{
    border: 1px solid #5A5FF2 !important;
  }
`;

export { ModalWrap, CustomAntModal };
