import React, { Component } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter, Link } from "react-router-dom"
import Container from "container/Referral/CheckoutContainer"
import Heading from "dumbComponents/common/Typography/Heading"
import Button from "@ui/Button"
import { GetUserPlatform, saveUserObjectToCookie } from "services/Utils"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Loader from "@ui/Loader"
import Box from "@ui/Box"
import CheckIcon from "images/icons/check-icon.png"
import ReferralManagerContact from "dumbComponents/Referral/common/ReferralManagerContact"
import ErrorIcon from "images/icons/error-icon.svg"
import Modal from "@ui/Modal"
import YourPlans from "./YourPlans"
import BillingSummary from "./BillingSummary"
import icons from "@icons"
import colors from "@colors"

const getProcessedItems = (cart, setAmountForCheckout, teamSize, pricingPlansMap) => {
  const invoiceItems = []
  const discountItems = []

  let subtotal = 0
  let total = 0

  const getExactDuration = (name, detail) => {
    if (name.indexOf("yearly") > -1) return 12
    return detail.duration
  }

  Object.keys(cart).forEach((product) => {
    if (cart[product]) {
      const planName = cart[product]
      const planDetails = pricingPlansMap[planName]
      const isTeam = planDetails.type === "team"
      invoiceItems.push({
        description: planDetails.description,
        amountLabel: planDetails.amountLabel,
        duration: getExactDuration(planName, planDetails),
        planName,
        isTeam,
        amount: planDetails.amount,
        type: planDetails.isTeam,
        tier: planDetails.tier,
        commitment: planDetails.commitment,
        product: planDetails.product,
      })
      if (planDetails.discount) {
        subtotal += planDetails.undiscountedPrice
        discountItems.push({
          discountLabel: planDetails.discountLabel,
          discount: planDetails.discount,
          planName,
        })
      } else {
        subtotal = (isTeam && planDetails.product !== "blu") ?
          (planDetails.amount * teamSize * planDetails.duration) : subtotal + planDetails.amount
      }
      total = (isTeam && planDetails.product !== "blu") ? (planDetails.amount * teamSize * planDetails.duration) : total + planDetails.amount
    }
  })

  setAmountForCheckout({ subTotal: subtotal, checkoutTotal: total })

  return {
    invoiceItems,
    discountItems,
    subtotal,
    total,
  }
}

export const paymentConfirmationStates = {
  loading: "loading",
  success: "success",
  failure: "failure",
  empty: "empty",
  loggedOut: "loggedOut",
}

const Wrapper = styled.div`
  background-color: ${colors.greyBackground};
`

const InnerWrapper = styled.div`
  color: ${colors.contentText};
  width: 700px;
  margin: 50px auto;
`

const PaymentResponseModal = styled.div`
  text-align: center;
  padding: 0 20px 32px;

  p {
    text-align: center;
  }
`

const ReviewWrap = styled.div`
 position: absolute;
 width: 142px;
 height: 22px;
 left: 200px;
 top: 122px;
 @media(max-width: 1280px) {
  left: 60px;
  top: 118px;
 }
`
const ReviewPlanButton = styled.button`
 font-family: 'DM Sans', sans-serif;
 font-size: 18px;
 color: ${colors.primaryColor};
 background: none;
 border: none;
 text-decoration: underline;
 cursor: pointer;
`

const TeamSizeWrap = styled.div`
  margin-top:30px;
  margin-bottom:30px;
  border-top: 1px solid ${colors.charcoalBlueGrey};
  padding-top:20px;
  position: relative;
  right: 30px;
`

const TeamSizeQuantityWrap = styled.div`
  display: flex;
  float: right;
`
const TeamSizeControlsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  bottom: 36px;
  left: 5px;
  @media (max-width: 1280px) {
   left: -100px;
  }
`

const TeamSizeCTA = styled.button`
  border: none;
  margin-top: 2px;
  color: #11adf3;
  background: transparent;
  cursor: pointer;
}
`

const TeamSizeHeader = styled.p`
 font-family: 'DM Sans', sans-serif;
 font-size: 16px;
 font-weight: 500;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
`
const TeamSizeQuantity = styled.p`
 float: right;
  width: 27px;
  height: 26px;
  border-radius: 2px;
  position: relative;
  bottom: 30px;
  border: solid 1px ${colors.faintBlueishBlack};
  padding-top: 1px;
  padding-left: 4px;
  font-size: 1em;
  @media (max-width: 1280px) {
   right: 100px;
  }
`

const TeamSizeTextWrap = styled.div``

const TeamSizeQuestion = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${colors.faintBlueishBlack};
`
const ErrorDiv = styled.div``

const ErrorMsg = styled.p`
  font-size: 11px;
  color: ${colors.redText};
`
const HeaderText = styled.h3`
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  position: relative;
  right: 31px;
`

const StyledCheckIcon = styled.img`
  background: ${props => (props.error ? "#FFF" : "#51d3b7")};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
  display: block;
  position: relative;
  top: -40px;
`

const SizeSelectorImage = styled.i`
  color: ${props => props.disableColor ? colors.charcoalWhiteGrey : colors.primaryColor};
`

class ReferralsCheckout extends Component {
  componentDidMount() {
    const {
      fetchSubscriptions,
      getTeamMembers,
      fetchPlanDuration,
      currentPlanType,
      toggleBanner,
    } = this.props
    fetchSubscriptions()
    const type = currentPlanType === "Team" ? "team" : "personal"
    fetchPlanDuration({ type })
    getTeamMembers({ days: "30", label: "Last 30 days" })
    root.scrollTo(0, {})
    toggleBanner()
  }

  handleModalClose = () => {
    const { updatePaymentPricingModal } = this.props
    updatePaymentPricingModal({ paymentModalState: false })
  }

  triggerEmptyStateModal = () => {
    const { updatePaymentPricingModal, paymentModalState } = this.props
    if (paymentModalState !== paymentConfirmationStates.empty) {
      updatePaymentPricingModal({ paymentModalState: paymentConfirmationStates.empty })
    }
  }

  triggerLoggedOutModal = () => {
    const { updatePaymentPricingModal, paymentModalState } = this.props
    if (paymentModalState !== paymentConfirmationStates.loggedOut) {
      updatePaymentPricingModal({ paymentModalState: paymentConfirmationStates.loggedOut })
    }
  }

  handleRedirectToISAPricing = () => {
    const {
      history: {
        push,
      },
    } = this.props
    push("/radius-assist?isNoRedirection=true")
  }

  goToLogin = () => {
    this.handleModalClose()
    saveUserObjectToCookie({ redirectUrl: `${APP_URL}/radius-assist` })
    window.location.href = `${RADIUS_URL}/login`
  }

  handleSuccessRedirect = () => {
    const {
      history: { push },
      isCartHavingTeamPlan,
      activeSubscriptions,
    } = this.props
    const PLANS = ["monthly", "threeMonths", "sixMonths"]
    const activePlanKey = Object.keys(activeSubscriptions)[0]
    const isOnSinglePlanActive = Boolean(PLANS.find(x => x === activePlanKey))
    if (isOnSinglePlanActive) {
      root.location = `${APP_URL}/assist/pipeline`
    } else if (isCartHavingTeamPlan) {
      root.location = `${APP_URL}/assist/team`
    } else {
      this.handleModalClose()
      root.location = `${APP_URL}/settings/integrations/leadintegrations`
    }
  }

  render() {
    const {
      cart,
      isLoggedIn,
      coupon,
      paymentModalState,
      activeSubscriptions,
      teamSize,
      modifyMemberQuantity,
      subTotal,
      setAmountForCheckout,
      isCartHavingTeamPlan,
      monthlyPlanIfTeam,
      currentTeamSize,
      pricingPlansMap,
      plansDuration,
      addPlanToCart,
      clearPricingCart,
      removePlanFromCart,
      applyPricingCoupon,
      checkoutTotal,
      checkoutPricingCart,
      categorizedSubscriptions,
      currentPlanType,
    } = this.props
    const isCouponApplicable = activeSubscriptions.monthly
     || activeSubscriptions.sixMonths
     || activeSubscriptions.yearly
     || activeSubscriptions.threeMonths
     || activeSubscriptions.teamPlan

    const {
      data: planDurationData,
      isFetching: isFetchingPlanDuration,
    } = plansDuration

    const isCartEmpty = !cart.rtl && !cart.blu

    const {
      invoiceItems,
      discountItems,
    } = getProcessedItems(cart, setAmountForCheckout, teamSize, pricingPlansMap)

    if (!paymentModalState) {
      if (!isLoggedIn) {
        this.triggerLoggedOutModal()
      } else if (invoiceItems.length === 0) {
        this.triggerEmptyStateModal()
      }
    }

    // if (total) {
    //   setAmountForCheckout({ subTotal: subtotal, checkoutTotal: total })
    // }

    let plansPageRoute = "/radius-assist"

    if (Object.keys(activeSubscriptions).length > 0) {
      plansPageRoute = "/settings/subscriptions"
    }
    return (
      <Wrapper>
        <InnerWrapper>
          <Heading
            type="h3"
            font="Rubik"
            size="18px"
            align="left"
            color={colors.black}
          >
          </Heading>
          <HeaderText>
             Your Plans
          </HeaderText>
          {isCartHavingTeamPlan && (
            <TeamSizeWrap>
              <TeamSizeHeader>Quantity</TeamSizeHeader>
              <TeamSizeQuestion>
                 How many admin accounts?
              </TeamSizeQuestion>
              <TeamSizeQuantityWrap>
                <TeamSizeTextWrap>
                  <TeamSizeQuantity>{teamSize}</TeamSizeQuantity>
                </TeamSizeTextWrap>
                <TeamSizeControlsWrap>
                  <TeamSizeCTA
                    onClick={() => modifyMemberQuantity(
                      {
                        quantity: teamSize + 1,
                        monthlyPlanIfTeam,
                        subTotal,
                        type: "add",
                      }
                    )}
                  >
                    <i className={icons.arrowsExpandUp1} />
                  </TeamSizeCTA>
                  <TeamSizeCTA
                    disabled={teamSize <= (currentTeamSize || 1)}
                    onClick={() => modifyMemberQuantity(
                      {
                        quantity: teamSize - 1,
                        monthlyPlanIfTeam,
                        subTotal,
                        type: "reduce",
                      }
                    )}
                  >
                    <SizeSelectorImage
                      className={icons.arrowsExpandDown}
                      disableColor={teamSize <= currentTeamSize || 1}
                    />
                  </TeamSizeCTA>
                </TeamSizeControlsWrap>
              </TeamSizeQuantityWrap>
              {(teamSize === currentTeamSize) && (
                <ErrorDiv>
                  <ErrorMsg>
                    {`Looks like you already have ${currentTeamSize} agents on another team plan. `}
                    {"You won’t be able to select fewer than this amount"}
                  </ErrorMsg>
                </ErrorDiv>
              )}
            </TeamSizeWrap>
          )}
          <YourPlans
            data={planDurationData}
            isFetching={isFetchingPlanDuration}
            cart={cart}
            addPlanToCart={addPlanToCart}
            pricingPlansMap={pricingPlansMap}
            clearPricingCart={clearPricingCart}
            removePlanFromCart={removePlanFromCart}
            isCartHavingTeamPlan={isCartHavingTeamPlan}
            currentPlanType={currentPlanType}
            categorizedSubscriptions={categorizedSubscriptions}
          />
        </InnerWrapper>

        <Modal
          show={paymentModalState}
          showCloseButton={paymentModalState === paymentConfirmationStates.failure}
          toClose={this.handleModalClose}
          closeModalOnOutsideClick={false}
          width="562px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
          }}
        >
          <PaymentResponseModal>
            {paymentModalState === paymentConfirmationStates.success && (
              <React.Fragment>
                <StyledCheckIcon src={CheckIcon} />
                <Box>
                  <Heading>Payment Accepted</Heading>
                  <Paragraph mt="24">Thank you for choosing our subscription plans</Paragraph>
                </Box>
                <Box mt="60">
                  {GetUserPlatform() === "desktop" ? (
                    <Button size="large" onClick={this.handleSuccessRedirect}>
                      Continue
                    </Button>
                  ) : (
                    <div>
                      <Paragraph mt="24">
                        Please open the desktop app to see prospects
                        <br />
                        https://www.radiusagent.com/login
                      </Paragraph>
                    </div>
                  )}
                </Box>
              </React.Fragment>
            )}

            {paymentModalState === paymentConfirmationStates.failure && (
              <React.Fragment>
                <StyledCheckIcon src={ErrorIcon} error />
                <Box>
                  <Heading>Payment Failed</Heading>
                  <Paragraph mt="24">Please try again</Paragraph>

                </Box>
                <Box mt="60">
                  <Button size="large" onClick={this.handleModalClose}>
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {paymentModalState === paymentConfirmationStates.loggedOut && (
              <React.Fragment>
                <StyledCheckIcon src={ErrorIcon} error />
                <Box>
                  <Heading>Please Login To Continue</Heading>
                </Box>
                <Box mt="60">
                  <Button size="large" onClick={this.goToLogin}>
                    Login
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {paymentModalState === paymentConfirmationStates.empty && (
              <React.Fragment>
                <StyledCheckIcon src={ErrorIcon} error />
                <Box>
                  <Heading>No Plans In Cart</Heading>
                </Box>
                <Box mt="60">
                  <Link to={plansPageRoute}>
                    <Button size="large" onClick={this.handleModalClose}>
                      Add Plans
                    </Button>
                  </Link>
                </Box>
              </React.Fragment>
            )}

            {paymentModalState === paymentConfirmationStates.loading && (
              <React.Fragment>
                <div style={{ height: 300 }}>
                  <Loader top={85} bottom={100} />
                  <Box mt="90">
                    <Heading>Saving Credit Card Details...</Heading>
                  </Box>
                </div>
              </React.Fragment>
            )}
          </PaymentResponseModal>
        </Modal>
        {!isCartEmpty && (
          <BillingSummary
            invoiceItems={invoiceItems}
            isCartHavingTeamPlan={isCartHavingTeamPlan}
            checkoutPricingCart={checkoutPricingCart}
            teamSize={teamSize}
            isCouponApplicable={isCouponApplicable}
            coupon={coupon}
            applyPricingCoupon={applyPricingCoupon}
            subTotal={subTotal}
            discountItems={discountItems}
            checkoutTotal={checkoutTotal}
            plansPageRoute={plansPageRoute}
            pricingPlansMap={pricingPlansMap}
            categorizedSubscriptions={categorizedSubscriptions}
          />
        )}
        <ReferralManagerContact type="isa" />
        <ReviewWrap>
          <ReviewPlanButton
            onClick={() => this.handleRedirectToISAPricing()}
          >
            Review Plans
          </ReviewPlanButton>
        </ReviewWrap>
      </Wrapper>
    )
  }
}

export default withRouter(Container(ReferralsCheckout))
