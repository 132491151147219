import React, { useEffect } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import AppContainer from "container/App"
import MakerImage from "images/radius-highlight-marker.svg"
import OnboardingContainer from "container/OnboardingSetup"
import ReferMailIcon from "images/Graphics/referMail.png"
import cookie from "services/CookieStorage"
import colors from "@colors"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import Banner from "dumbComponents/OnboardingOrientation/Banner"
import InviteFeatureContainer from "container/App/inviteFeatureContainer"
import {
  maxTabletWidth,
} from "shared/consts"
import { showFeatureTab } from "services/Utils"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { authCheck } from "services/auth"
import RealtyContainer from "container/Realty"
import MainDropdownNav from "./MainDropDownNav"
import { PaymentPendingBanner } from "../GlobalBanners/index"
const MOBILE_BREAK_POINT = "1080px"
import { ATC, INTER } from "@fonts"

const Wrap = styled.ul`
  display: flex;
  gap: 55px;
  // ${maxTabletWidth(`
  //   display: none;
  // `)}

  @media(max-width: 1099px) {
    order: 3;
    align-items: center;
    width: 100%;
    min-width: unset;
    padding: 0;
    justify-content: center;
    gap: 13px;
  }
`

const Li = styled.li`
  font-weight: 500;
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  // min-width: 100px;
  color: #000;

  &:hover {
    color: ${colors.marineBLue};
  }

  &::after {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 65px;
    height: 15px;
    display: none;
    background: url(${MakerImage}) no-repeat center;
    background-size: contain;
    background-position: top;
    content: "";
  }


  ${props => props.isActive && `
    color: ${colors.marineBLue};
    // &::after {
    //   display: block;
    // }
  `}

  @media(min-width: 1100px) {
    ${props => props.minDesktopWidth && `
      width: ${props.minDesktopWidth};
    `}

    ${props => props.ml && `
      margin-left: 0px;
      `}

    ${props => props.mr && `
      margin-right: 0px;
    `}

    ${props => props.fml && `
      margin-left: ${props.fml};
    `}

    ${props => props.fmr && `
      margin-right: ${props.fmr};
    `}
  }



  @media(min-width: 1280px) {
    ${props => props.ml && `
      margin-left: ${props.ml || "-6px"};
      `}

    ${props => props.mr && `
      margin-right: ${props.mr || "-6px"};
      `}
  }

  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @media(max-width: 767px) {
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
`

const BorderSpan = styled.div`
  display: none;
  border-bottom: 1px solid #303030;
  width: 70%;
  margin: 0 auto 20px auto;

  @media(max-width: 767px) {
    display: block;
  }
`

const OtherItems = styled.div`
  display: flex;
  // gap: 15px;
  gap: 54px;

  ${props => props.modifyNavbar && `
    display: none;
  `}

  @media(max-width: 1099px) {
    display: none;
  }
`

const trackHubspotEvent = (RARTracker, events) => {
  const isLogged = authCheck()
  if (isLogged) {
    RARTracker({
      payload: {
        source: events,
      },
    })
  }
}

const NewMainWrapper = ({
  location: {
    pathname,
  },
  history,
  isLogged,
  getFeedByHomeClick,
  isClient,
  RARTracker,
  isBroker,
  toggleInvitePopUp,
  firstTimeReferralPopup,
  getCurrentReferralInviteStatusResponse,
  setCurrentInviteType,
  toggleSuccessModalUI,
  isAppMounted,
  citiesServed,
  isSubscribedToBrokerPlan,
  isVersionV2,
  hideResources,
  hideOurAgents,
  fetchOrientationInfoResponse,
  getOnboardingSetupResponse,
  setFormStage,
  isEven,
  toggleLandingPageMQLFormToggle,
  modifyNavbar,
  user: userData,
  getDisplayScreen,
  paymentBannerVisible,
  ...props
}) => {
  // const isAssistPage = pathname && (pathname.includes("/radius-assist") || pathname.includes("/assist"))
  const isAcademtPage = pathname && pathname.includes("/academy")
  const isAboutUsPage = pathname && pathname.includes("/about")
  const isOurAgentsPage = pathname && pathname.includes("/realty/agents")
  const isCommunityPage = pathname && pathname.includes("/network")
  const isRadiusRooms = pathname && pathname.includes("/rooms")
  const isForAgentsPage = pathname && pathname.includes("/realty/for-agents")
  const isRealtyPage = pathname && pathname.includes("/realty") && !isOurAgentsPage
  const isDashboard = pathname && pathname.includes("/realty/dashboard")

  const completionPercentage = fetchOrientationInfoResponse?.data?.response !== null ? fetchOrientationInfoResponse?.data?.response?.form_completion_percentage : 0
  const isOrientationCompleted = getOnboardingSetupResponse?.data?.isOrientationCompleted && getOnboardingSetupResponse?.data?.form_completion_percentage < 100

  const isCalifornia = citiesServed && citiesServed.length > 0 && citiesServed.filter(obj => obj.state === "California")
  const isColorado = citiesServed && citiesServed.length > 0 && citiesServed.filter(obj => obj.state === "Colorado")
  const showBrokerageContent = isSubscribedToBrokerPlan ||
    (isCalifornia && isCalifornia.length > 0) ||
    (isColorado && isColorado.length > 0)

  const {
    data: referredData,
  } = getCurrentReferralInviteStatusResponse || {}

  return (
    <Wrap
      isEven={isEven}
    >

      {false ? (
        <>
          {isAppMounted && showFeatureTab() && (
            <Li
              isEven={isEven}
              fmr="10px"
              id="new-navbar-radius-realty-web"
            >
              <MainDropdownNav isLogged={!!isLogged} userData={userData} isEven={isEven} type="product" />
            </Li>
          )}
          {isAppMounted && showFeatureTab() && (
            <Li
              isEven={isEven}
              isActive={isCommunityPage}
              // ml="25px"
              minDesktopWidth="max-content"
              onClick={() => {
                if (root.heap) {
                  root.heap.track("Click_Radius_Our_Community_Navbar")
                }
                trackHubspotEvent(RARTracker, "our-community")
                history.push("/network")
              }}
              id="new-navbar-radius-assist-web"
              style={{ cursor: "pointer" }}
            >
              Our Community
            </Li>
          )}
          {isAppMounted && showFeatureTab() && (
            <Li
              isEven={isEven}
              isActive={isRadiusRooms}
              className="rooms_nav_web"
              // ml="25px"
              minDesktopWidth="max-content"
              onClick={() => {
                trackHubspotEvent(RARTracker, "radius-rooms")
                history.push("/rooms")
              }}
              id="new-navbar-radius-rooms"
              style={{ cursor: "pointer" }}
            >
              Rooms
            </Li>
          )}
        </>
      ) : (
        <OtherItems modifyNavbar={modifyNavbar}>
          <Li
            isEven={isEven}
            // fmr="10px"
            id="new-navbar-radius-realty-web"
          >
            <MainDropdownNav isEven={isEven} type="who_we_help" />
          </Li>
          <Li
            isEven={isEven}
            className="rooms_nav_web"
            minDesktopWidth="max-content"
            isActive={isRadiusRooms}
            onClick={() => {
              root.open("https://www.radiusagent.com/benefits", "_self")
            }}
            style={{ cursor: "pointer" }}
          >
            <MainDropdownNav isEven={isEven} type="benefits" />
          </Li>
          {/* <Li
            isEven={isEven}
            minDesktopWidth="max-content"
            className="aboutus_nav_web"
            onClick={(e) => {
              e.stopPropagation()
              handleDataLayerPush(userData, "top_navigation_menu_clicked", "about_us")
              root.open("https://www.radiusagent.com/about", "_self")
            }}
            style={{ cursor: "pointer" }}
          >
            About Us
          </Li> */}
        </OtherItems>
      )}
      {isSubscribedToBrokerPlan && isDashboard && completionPercentage < 100 && (
        <>
          <Banner setFormStage={setFormStage} response={fetchOrientationInfoResponse?.data?.response} />
        </>
      )}
      {isSubscribedToBrokerPlan && isDashboard && isOrientationCompleted && (
        <>
          <Banner setFormStage={setFormStage} response={getOnboardingSetupResponse?.data} isSetupProfile />
        </>
      )}

      {paymentBannerVisible && (
        <PaymentPendingBanner />
      )}
    </Wrap>
  )
}

export default AppContainer(ApplyFlowContainer(
  withRouter(
    OnboardingContainer(RealtyContainer(InviteFeatureContainer(NewMainWrapper)))
  )
))

