import React, { useState } from "react"
import OffersV2 from "container/OffersV2/index"
import OldOffers from "container/TechAssets"
import AppContainer from "container/App"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  CloseButton,
  HeaderWrap,
} from "./styles"
import {
  EnvelopesWrap,
} from "../styles"

import Form from "./Form"
import Attachments from "./Attachments"

const SendOffer = ({
  goBack,
  completeOfferPackageToBeSent,
  sendOfferPackageMail,
  dealToView,
  sendOfferPackageMailResponse,
  fetchUserInfo,
  propertyInformationObject,
  buyersAgentInformationObject,
  user,
}) => {
  const {
    isFetching: sendingOfferPackage,
  } = sendOfferPackageMailResponse || {}
  console.log("propertyInformationObject envelopecard", propertyInformationObject)
  console.log("buyersAgentInformationObject", buyersAgentInformationObject)
  const {
    envelopes,
    proofOfFundsDocs = [],
    coverLetters = [],
    preQualified = [],
  } = completeOfferPackageToBeSent || {}
  
  const handleSend = (values) => {
    customToast("Sending offer package")
    fetchUserInfo()
    const {
      from,
      from_email,
      to,
      to_email,
      cc,
      cc_email,
      offer_package_subject,
      custom_message,
      original_message,
    } = values || {}


    const attachments = [
      ...proofOfFundsDocs.map(item => item.url),
      ...coverLetters.map(item => item.url),
      ...preQualified.map(item => item.url),
    ]

    const v2Docs = envelopes.map(item => ({
      id: item.id,
      documents: [
        ...item.documents,
      ],
    }))

    const getCcEmail = () => {
      if (typeof cc_email === "string") {
        return [{
          name: cc,
          email: cc_email,
        }]
      } if (Array.isArray(cc_email) && cc_email.length) {
        return cc_email.map(email => ({
          name: cc,
          email,
        }))
      }

      return ""
    }
    let emailBody = ""
    emailBody = custom_message.replace(/\n/g, "<br>")
    const payload = {
      subject: offer_package_subject,
      offerId: dealToView,
      body: emailBody,
      to: [
        {
          name: to,
          email: to_email,
        },
      ],
      cc: getCcEmail(),
      from: {
        name: `${user.firstname} ${user.lastname}`,
        email: from_email,
        //email: "shrungi@radiusagent.com"
      },
      attachmentUrls: attachments || [],
      docusignEnvelopes: [],
      docusignEnvelopesV2: v2Docs || [],
      entityType: "offerPackage",
      entityId: dealToView,
      secure_id: "bf30a11ce3e472d285dd7c1a5bbd8f48",
    }

    sendOfferPackageMail({
      payload,
    })
    goBack()
  }

  return (
    <EnvelopesWrap>
      {/* <HeaderWrap>
        <SectionTitle>
          POWERED BY GMAIL
        </SectionTitle>
      <CTAWrap>
        <CloseButton
          onClick={goBack}
        >
          <BlueCloseIcon />
        </CloseButton>
      </CTAWrap>
      </HeaderWrap> */}
      <SectionWrap>
        <Form
          onFinish={handleSend}
          sendingOfferPackage={sendingOfferPackage}
          propertyInformationObject={propertyInformationObject}
          buyersAgentInformationObject={buyersAgentInformationObject}
        >
          <Attachments />
        </Form>
      </SectionWrap>
    </EnvelopesWrap>
  )
}

export default AppContainer(OldOffers(OffersV2(SendOffer)))
