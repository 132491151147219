import React from "react"
import {
  InnerTab,
  SubLink,
} from "./commonStyles"
import {
  WebsiteIcon,
} from "../SidebarIcons"

const AgentWebsiteTab = ({
  agentWebsite,
}) => (
  <InnerTab>
    <div className="icon-block-new">
      <WebsiteIcon />
    </div>
    <div className="text-block">
      <SubLink to={agentWebsite} isLink={false}>
        Website
      </SubLink>
    </div>
  </InnerTab>
)

export default AgentWebsiteTab
