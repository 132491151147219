import React, { useEffect, useState } from "react"
import OffersV2 from "container/OffersV2"
import { moneyFormatter } from "shared/currencyUtils"
import { commaSeperatedNumberUS } from "services/formUtils"
import OffersStatus from "dumbComponents/common/OffersStatus"
import CRM from "container/CRM/index"
import { ShimmerBox } from "shared/styles/animation"
import {
  Wrap,
  BadgeTag,
  AddressH1,
  AddressH2,
  BuyerDetails,
  StatsDiv,
  AmountDiv,
  LastUpdatedDiv,
  SelectWrap,
  InnerWrap,
  InnerStatsDiv,
} from "./styles"

const OfferExtendedDetails = ({
  currentOfferId,
  getCtIdResponse,
  fetchInfoBasedOnMLSId,
  mlsId,
  fetchInfoBasedOnMLSIdResponse,
  getOfferStatusResponse,
  offerDealId,
  transactionId,
  propertyDetails = null,
  updatePropertyInformationTabResponse,
  getCurrentDealDetailsResponse,
  dealDetails,
  propertyInformationObject,
  fetchDealTermsInformationResponse,
}) => {
  const [fetchedMLSInformation, setMLSInformation] = useState({
    address: "N/A",
    zipcode: "N/A",
    state: "N/A",
    propertyStatus: "Active",
    beds: 0,
    bathrooms: 0,
    measurement: "sqft",
    city: "N/A",
    listPrice: 0,
    updatedAt: null,
  })

  const { isFetching: fetchingPropertyResponse } =
    updatePropertyInformationTabResponse || {}

  const { isFetching: fetchingDealResponse } =
    fetchDealTermsInformationResponse || {}
  const [currentFormState, setCurrentFormState] = useState(null)

  useEffect(() => {
    const { mls_number, currentState, form } = propertyInformationObject || {}
    const currentFormState = form[currentState]
    setCurrentFormState(currentFormState)
  }, [])

  useEffect(() => {
    const { mls_number, currentState, form } = propertyInformationObject || {}
    const currentFormState = form[currentState]
    setCurrentFormState(currentFormState)
    const mlsId = mls_number
    if (mlsId) {
      fetchInfoBasedOnMLSId({
        mlsId,
      })
    } else if (currentFormState || dealDetails) {
      const currentPayload = {
        ...fetchedMLSInformation,
      }

      const { zipcode, street, state, city } = currentFormState || {}

      if (zipcode) {
        currentPayload.zipcode = zipcode
      }

      if (street) {
        currentPayload.address = street
      }

      if (state) {
        currentPayload.state = state
      }

      if (city) {
        currentPayload.city = city
      }

      setMLSInformation(currentPayload)
    }
  }, [propertyInformationObject, currentFormState])

  useEffect(() => {
    if (!fetchingDealResponse && dealDetails) {
      const currentPayload = {
        ...fetchedMLSInformation,
      }

      const { price } = dealDetails || {}
      currentPayload.listPrice = price
      setMLSInformation(currentPayload)
    }
  }, [fetchingDealResponse])

  useEffect(() => {
    if (!fetchingPropertyResponse) {
      const currentPayload = {
        ...fetchedMLSInformation,
      }

      const { zipcode, street, state, city } = currentFormState || {}

      if (zipcode) {
        currentPayload.zipcode = zipcode
      }

      if (street) {
        currentPayload.address = street
      }

      if (state) {
        currentPayload.state = state
      }

      if (city) {
        currentPayload.city = city
      }

      setMLSInformation(currentPayload)
    }
  }, [fetchingPropertyResponse])

  const { data: currentOfferStatusData, isFetching: fetchingOfferRelatedData } =
    getOfferStatusResponse || {}

  const { data: mlsData, isFetching: fetchingMLSData } =
    fetchInfoBasedOnMLSIdResponse || {}

  const confirmationPopupCallback = (
    offerStatusChangeNote,
    selectedListingId,
    newValue
  ) => {
    // // const { newValue, selectedListingId } = this.state
    // const {
    //   updateOfferStatus,
    //   history,
    //   listingData,
    // } = this.props
    // const {
    //   buyer_clients,
    //   id: offerId,
    // } = listingData || {}
    // const id = buyer_clients && buyer_clients.length > 0 && buyer_clients[0]?.transaction_id
    // const payload = {
    //   notes: offerStatusChangeNote || "",
    //   offer_id: offerId,
    //   status: newValue,
    // }
    // updateOfferStatus({
    //   payload,
    // })
  }

  const handleChange = (prevValue, newValue, id, listingData) => {
    // const {
    //   updateOfferStatus,
    //   updateNote,
    //   offerStatusChangeNote,
    //   toggleConfirmToExecute,
    //   setEditingDealTeamMemberId,
    // } = this.props
    // const PREVIOUS = this.getHumanReadableValue(prevValue)
    // const UPDATED = this.getHumanReadableValue(newValue)
    // const { confirm } = Modal
    // const context = this
    // if (newValue === "offer_accepted") {
    //   setEditingDealTeamMemberId(parseInt(listingData.primary_agent_id, 10))
    //   this.setState({
    //     statusChangeText: "Please provide details of the contract.",
    //     currentTitle: "Congratulations!",
    //     newValue,
    //     selectedListingId: id,
    //   })
    // } else {
    //   this.setState({
    //     statusChangeText: `Status change from ${PREVIOUS} to ${UPDATED}`,
    //     currentTitle: "Do you want to update the offers status?",
    //     newValue,
    //     selectedListingId: id,
    //   })
    // }
    // const statusChangeText = newValue === "offer_accepted"
    //   ? "Please provide details of the contract." : `Status change from ${PREVIOUS} to ${UPDATED}`
    // const currentTitle = newValue === "offer_accepted"
    //   ? "Congratulations!" : "Do you want to update the offers status?"
    // if (prevValue !== newValue) {
    //   toggleConfirmToExecute({
    //     newValue,
    //     prevValue,
    //     selectedListingId: id,
    //     PREVIOUS,
    //     UPDATED,
    //     callback: confirmationPopupCallback,
    //     currentTitle: newValue === "offer_accepted" ? "Congratulations!" : "Do you want to update the offers status?",
    //   })
    // }
  }

  useEffect(() => {
    if (!fetchingMLSData && mlsData !== null) {
      const currentData =
        (mlsData.data && mlsData.new_records_count > 0 && mlsData.data[0]) ||
        ""

      const payload = {
        ...fetchedMLSInformation,
      }

      if (currentData) {
        const {
          UnparsedAddress,
          PostalCode,
          StateOrProvince,
          StandardStatus,
          BedroomsTotal,
          BathroomsFull,
          BathroomsTotalInteger,
          City,
          LivingArea,
          LivingAreaUnits,
          ListPrice,
          ModificationTimestamp,
        } = currentData || {}

        if (UnparsedAddress) {
          payload.address = UnparsedAddress
        }

        if (PostalCode) {
          payload.zipcode = PostalCode
        }

        if (BedroomsTotal) {
          payload.beds = BedroomsTotal
        }

        if (BathroomsFull || BathroomsTotalInteger) {
          payload.bathrooms = BathroomsFull || BathroomsTotalInteger
        }

        if (LivingArea) {
          payload.livingArea = LivingArea
        }

        if (LivingAreaUnits) {
          payload.measurement =
            LivingAreaUnits === "Square Feet"
              ? "sqft"
              : LivingAreaUnits === "Square Metres"
              ? "sqmt"
              : "acres"
        }

        if (City) {
          payload.city = City
        }

        if (StateOrProvince) {
          payload.state = StateOrProvince
        }

        if (StandardStatus) {
          payload.propertyStatus = StandardStatus
        }

        // if (ListPrice) {
        //   payload.listPrice = ListPrice
        // }

        if (ModificationTimestamp) {
          payload.updatedAt = ModificationTimestamp
        }

        setMLSInformation(payload)
      }
    }
  }, [mlsData, fetchingMLSData])

  /**Note to @AmanKumar : added a null check for Buyers*/
  const buyerName = getCurrentDealDetailsResponse.data?.buyers?.find(buyer => buyer.is_primary === 1)?.name
  const buyerTransactionId = getCurrentDealDetailsResponse.data?.buyers?.find(buyer => buyer.is_primary === 1)?.transaction_id
  const buyerClientId = getCurrentDealDetailsResponse.data?.buyers?.find(buyer => buyer.is_primary === 1)?.client_id
  const currStatus = getCurrentDealDetailsResponse.data?.offer_status

  return (
    <Wrap>
      <>
        {fetchingMLSData ? (
          <ShimmerBox w="100%" h="100px" />
        ) : (
          <>
            <InnerWrap>
              <AddressH1>
                <h1>{fetchedMLSInformation.address}</h1>
                <BadgeTag>
                  <p>{fetchedMLSInformation.propertyStatus || "Active"}</p>
                </BadgeTag>
              </AddressH1>
              <AddressH2>
                <h2>
                  {`${fetchedMLSInformation.city}, ${fetchedMLSInformation.state}, ${fetchedMLSInformation.zipcode}`}
                </h2>
              </AddressH2>
              {buyerName && (
                <BuyerDetails>
                  <p>{`Buyer Name: ${buyerName} \u00A0 | \u00A0`}</p>
                  <p>
                    <a
                      href={`/crm?transactionId=${buyerTransactionId}&client_id=${buyerClientId}&type=buyer`}
                      target="_blank"
                    >
                      {" "}
                      View Buyer Profile
                    </a>
                  </p>
                </BuyerDetails>
              )}
              <StatsDiv>
                {fetchedMLSInformation.beds > 0 && (
                  <InnerStatsDiv>
                    <img
                      src={`${CLOUDFRONT}/LISTING_V2/Beds.svg`}
                      alt="bathroom.svg"
                    />
                    <p>
                      <span>{fetchedMLSInformation.beds || 0}</span> beds
                    </p>
                  </InnerStatsDiv>
                )}

                {fetchedMLSInformation.bathrooms > 0 && (
                  <InnerStatsDiv>
                    <img
                      src={`${CLOUDFRONT}/LISTING_V2/Bathroom.svg`}
                      alt="Bathroom.svg"
                    />
                    <p>
                      <span>{fetchedMLSInformation.bathrooms || 0}</span> baths
                    </p>
                  </InnerStatsDiv>
                )}

                {fetchedMLSInformation.measurement &&
                  fetchedMLSInformation.livingArea && (
                    <InnerStatsDiv>
                      <img
                        src={`${CLOUDFRONT}/LISTING_V2/SquareInch.svg`}
                        alt="squareInch.svg"
                      />
                      <p>
                        <span>
                          {commaSeperatedNumberUS(
                            fetchedMLSInformation.livingArea
                          )}
                        </span>{" "}
                        {`${fetchedMLSInformation.measurement}`}
                      </p>
                    </InnerStatsDiv>
                  )}
              </StatsDiv>
            </InnerWrap>

            <InnerWrap
              style={{
                alignItems: "flex-end",
              }}
            >
              {fetchedMLSInformation.listPrice > 0 && (
                <AmountDiv>
                  <h1>{`$${moneyFormatter(
                    fetchedMLSInformation.listPrice
                  )}`}</h1>
                </AmountDiv>
              )}

              <SelectWrap>
                {/* <GlobalStatus offerIdForStatus={currentOfferId} /> */}
                {/**fetchingOfferRelatedData */}
                {fetchingOfferRelatedData ? (
                  <></>
                ) : (
                  <OffersStatus
                    status={(currentOfferStatusData && currentOfferStatusData.offerStatus) || (currStatus)}
                    handleChange={(e) => {
                      //handleChange(currentOfferStatusData.offerStatus, e, offerDealId, listingData)
                    }}
                    id={offerDealId}
                    tId={transactionId}
                  />
                )}
              </SelectWrap>
              {/* <LastUpdatedDiv>
                <p>{`Last Updated ${moment(fetchedMLSInformation.updatedAt).format("MM/DD/YYYY")}`}</p>
              </LastUpdatedDiv> */}
            </InnerWrap>
          </>
        )}
      </>
    </Wrap>
  )
}

export default OffersV2(CRM(OfferExtendedDetails))
