import React from "react"
import styled from "styled-components"
import colors from "@colors"
import Checkmark from "images/Graphics/RadiusEdgeAssets/Checkmark_1.svg"
import ForTeams_PeteFlint from "dumbComponents/NewTeamsPages/images/ForTeams_PeteFlint.png"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import ForTeams_quoteWrap from "dumbComponents/NewTeamsPages/images/ForTeams_quoteWrap.png"
import ForTeams_trulix from "dumbComponents/NewTeamsPages/images/ForTeams_trulix.png"
import ForTeams_nfx from "dumbComponents/NewTeamsPages/images/ForTeams_nfx.png"
import ForTeams_zillowGroup from "dumbComponents/NewTeamsPages/images/ForTeams_zillowGroup.png"

const Wrap = styled.div`
  margin: auto;
  padding: 60px;
  display: flex;
  justify-content: space-around;

  @media(max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
  }

  @media(max-width: 425px) {
    padding: 10px;
    gap: 40px;
  }
`

const ButtonWrap = styled.div`
  margin-top: 20px;
  > a {
    color: ${colors.marineBLue};
    font-family: ${ATC};
    font-size: 22px;
    text-decoration: none;
  }
`

const ContentWrap = styled.div`
  > div {
    @media(max-width: 768px) {
      width: 380px;
    }
    > h1 {
      max-width: 633px;
      width: 100%;
      font-size: 42px;
      font-family: ${HIGHLIGHT_FONT};
      color: ${colors.black};

      @media(max-width: 768px) {
        max-width: 645px;
        font-size: 36px;
      }

      @media(max-width: 425px) {
        font-size: 32px;
      }
    }

    > p {
      font-size: 22px;
      font-family: ${ATC};
      font-weight: 200;

      @media(max-width: 768px) {
        margin-top: 30px;
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }
  }
`

const PeteFlintImageAndCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media(max-width: 425px) {
    height: 520px;
  }
`

const ImageWrapper = styled.img`
  z-index: 2;

  @media(max-width: 425px) {
    width: 350px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: -35px;
  }
`

const CardWrapper = styled.div`
  margin-left: -125px;
  z-index: 1;
  width: 420px;
  height: 400px;
  background: #ffffff;
  padding: 40px;

  @media(max-width: 425px) {
    padding: 9px;
    margin-left: 0px;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    align-items: flex-start;
    gap: 10px;
    display: flex;

    > div {
      > p {
        margin: 0;
        font-size: 22px;
        font-family: ${ATC};
        font-weight: 200;
        color: #303030;

        @media(max-width: 768px) {
          font-size: 20px;
        }

        @media(max-width: 425px) {
          font-size: 18px;
        }
      }
      > small {
        font-size: 16px;
        color: #303030;
        font-family: ${ATC};
        font-weight: 200;
      }
    }
  }
`

const QuoteWrap = styled.div`
  font-family: ${HIGHLIGHT_FONT};
  width: 323px;

  > p {
    line-height: 32px;
    font-size: 24px;
    color: ${colors.black};

    @media(max-width: 768px) {
      font-size: 20px;
    }

    @media(max-width: 425px) {
      font-size: 18px;
    }
  }
`

const OwnWrap = styled.div`
  width: 246px;
  display: flex;
  flex-direction: column;
  margin-left: auto;

  > h4 {
    color: ${colors.suvaGray};
    font-family: ${ATC};

    @media(max-width: 425px) {
      margin-bottom: 5px;
    }
  }

  > small {
    color: ${colors.suvaGray};
    font-size: 14px;
    font-family: ${ATC};

    @media(max-width: 425px) {
      margin-bottom: 10px;
    }
  }

  @media(max-width: 425px) {
    width: 122px;
  }
`

const CompanyWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media(max-width: 425px) {
    flex-wrap: wrap;
    gap: 0px 10px;
  }
`

const Economics = () => {
  return (
    <Wrap>
      <PeteFlintImageAndCard>
        <ImageWrapper src={ForTeams_PeteFlint} alt="Radius Flat Fee" />
        <CardWrapper>
          <QuoteWrap>
            <img src={ForTeams_quoteWrap} alt="Radius Quote" />
            <p>
              A real estate company that empowers entrepreneurial agents to build the business of their dreams.
            </p>
          </QuoteWrap>
          <OwnWrap>
            <h4>
              PETE FLINT
            </h4>
            <small>
              Investor and board member
            </small>
            <CompanyWrap>
              <img src={ForTeams_trulix} alt="Radius Company" />
              <img src={ForTeams_nfx} alt="Radius Company" />
              <img src={ForTeams_zillowGroup} alt="Radius Company" />
            </CompanyWrap>
          </OwnWrap>
        </CardWrapper>
      </PeteFlintImageAndCard>
      <ContentWrap>
        <div>
          <h1>
            Better economics for entrepreneurs like you
          </h1>
          <TextWrap>
            <div>
              <img src={Checkmark} alt="Radius Checkmark" />
              <div>
                <p>
                  Get stock options and cash rewards for every new team member
                </p>
              </div>
            </div>
            <div>
              <img src={Checkmark} alt="Radius Checkmark" />
              <div>
                <p>
                  Radius’ modest pricing structure means increased team profitability
                </p>
              </div>
            </div>
            <div>
              <img src={Checkmark} alt="Radius Checkmark" />
              <div>
                <p>
                  Build and scale a team to become a sellable asset for retirement
                </p>
              </div>
            </div>
          </TextWrap>
        </div>
        <ButtonWrap>
          <a href="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true">
            Grow with Radius &gt;
          </a>
        </ButtonWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default Economics
