/* eslint-disable max-len */
import React from "react"

const VideoOff = () => (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M5.47992 17.4373H15.1446L13.7808 16.0645H5.73449C4.98896 16.0645 4.41314 15.8705 4.00704 15.4826C3.60094 15.0947 3.39788 14.5067 3.39788 13.7187V5.75427L1.93409 4.28139C1.93409 4.48747 1.93409 4.69052 1.93409 4.89055C1.93409 5.09057 1.93409 5.29059 1.93409 5.49061V13.9279C1.93409 15.0371 2.24625 15.9008 2.87056 16.519C3.50092 17.1312 4.37071 17.4373 5.47992 17.4373ZM14.9809 2.49029H5.2799L6.64368 3.85407H14.7264C15.4719 3.85407 16.0477 4.05106 16.4538 4.44504C16.8599 4.83296 17.063 5.4209 17.063 6.20887V13.646C17.063 13.7551 17.0569 13.8521 17.0448 13.937C17.0327 14.0218 17.0114 14.1067 16.9811 14.1915L18.254 15.4644C18.4358 15.034 18.5268 14.5613 18.5268 14.0461V5.99066C18.5268 4.88752 18.2116 4.02985 17.5812 3.41766C16.9569 2.79942 16.0901 2.49029 14.9809 2.49029ZM18.3085 7.42718V9.15463L22.6454 5.57244C22.6878 5.54213 22.7242 5.51788 22.7545 5.4997C22.7908 5.47546 22.8272 5.46333 22.8636 5.46333C22.9666 5.46333 23.0181 5.53607 23.0181 5.68154V14.2461C23.0181 14.3855 22.9666 14.4552 22.8636 14.4552C22.8272 14.4552 22.7908 14.4461 22.7545 14.4279C22.7242 14.4037 22.6878 14.3794 22.6454 14.3552L18.3085 10.773V12.5004L21.9635 15.5917C22.1453 15.7432 22.3332 15.8675 22.5272 15.9644C22.7211 16.0554 22.9121 16.1008 23.1 16.1008C23.5 16.1008 23.8212 15.9675 24.0637 15.7008C24.3061 15.4341 24.4274 15.0886 24.4274 14.6643V5.26331C24.4274 4.83903 24.3061 4.49353 24.0637 4.22684C23.8212 3.96014 23.5 3.8268 23.1 3.8268C22.9121 3.8268 22.7211 3.87529 22.5272 3.97227C22.3332 4.06319 22.1453 4.18441 21.9635 4.33594L18.3085 7.42718ZM18.7359 19.4648C18.8753 19.6042 19.042 19.6739 19.2359 19.6739C19.4299 19.6739 19.5935 19.6042 19.7269 19.4648C19.8602 19.3254 19.9269 19.1587 19.9269 18.9648C19.933 18.7769 19.8663 18.6132 19.7269 18.4738L1.77044 0.52645C1.63103 0.393102 1.46435 0.326429 1.27039 0.326429C1.07643 0.320367 0.909742 0.387041 0.770334 0.52645C0.636986 0.659797 0.570312 0.826481 0.570312 1.0265C0.570312 1.22046 0.636986 1.38412 0.770334 1.51746L18.7359 19.4648Z"
      fill="#ED1616"
    />
  </svg>
)

export default VideoOff
