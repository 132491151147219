import axios from "axios"

export const getAllFeedsAPI = params => axios.get(`${API_URL}community/feed${params ? `?${params}` : ""}`)

export const getTagsBasedOnTopicsAPI = topicId => axios.get(`${API_URL}community/topic/${topicId}/tags`)

export const getCityPreferencesAPI = () => axios.get(`${API_URL}community/preference/cities`)

export const setCityPreferencesAPI = payload => axios.put(`${API_URL}community/preference/cities`, payload)

export const getAllSpecializationAPI = () => axios.get(`${API_V2_URL}user/nar-designations`)

export const getSpecializationPreferencesAPI = () => axios.get(`${API_URL}community/preference/specializations`)

export const setSpecializationPreferencesAPI = payload => axios.put(`${API_URL}community/preference/specializations`, payload)

export const getAllTopicsAPI = () => axios
  .get(`${API_URL}community/topics/all`)

export const getTopicsPreferencesAPI = () => axios
  .get(`${API_URL}community/preference/topics`)

export const setTopicsPreferencesAPI = payload => axios
  .put(`${API_URL}community/preference/topics`, payload)

export const getSuggestedAgentsAPI = payload => axios
  .post(`${API_URL}user/suggestAgentsToFollow`, payload)

export const followAgentAPI = payload => axios
  .post(`${API_V2_URL}network/follow`, payload)

export const getOnboardingStatusAPI = () => axios
  .get(`${API_V2_URL}community/onboarding/unfinished-stages`)

export const likeFeedAPI = feedId => axios
  .put(`${API_URL}community/feed/${feedId}/like`)

export const unLikeFeedAPI = feedId => axios
  .put(`${API_URL}community/feed/${feedId}/unlike`)

export const likeCommentAPI = feedId => axios
  .put(`${API_URL}community/comment/${feedId}/like`)

export const unLikeCommentAPI = feedId => axios
  .put(`${API_URL}community/comment/${feedId}/unlike`)

export const getCommentsForFeedAPI = (feedId, params) => axios
  .get(`${API_URL}community/feed/${feedId}/comments${params ? `?${params}` : ""}`)

export const postCommentForFeedAPI = (feedId, payload) => axios
  .post(`${API_URL}community/feed/${feedId}/comment`, payload)

export const getFeedLikersAPI = feedId => axios
  .get(`${API_URL}community/feed/${feedId}/likers`)

export const getCommentLikersAPI = commentId => axios
  .get(`${API_URL}community/comment/${commentId}/likers`)

export const createFeedItemAPI = payload => axios
  .post(`${API_URL}community/feed`, payload)

export const updateFeedItemAPI = (feedId, payload) => axios
  .put(`${API_URL}community/feed/${feedId}`, payload)

export const getSnippetDataAPI = payload => axios
  .post(`${API_URL}snippet`, payload)

export const startOnboardingAPI = () => axios
  .post(`${API_URL}community/begin-onboarding`)

export const getSuggestedTopicsAPI = () => axios
  .get(`${API_URL}community/topic/suggest`)

export const followTopicAPI = topicId => axios
  .put(`${API_URL}community/topic/${topicId}/follow`)

export const createIntroPostAPI = payload => axios
  .post(`${API_URL}community/feed/introPost`, payload)

export const setAllTopicsAPI = () => axios
  .get(`${API_URL}community/onboarding/all-topics`)

export const skipIntroPostAPI = () => axios
  .post(`${API_URL}community/skip-intro`, {})

export const getLastestCourseAPI = () => axios
  .get(`${API_URL}shiksha/courses/new`)

export const getStandAloneFeedAPI = id => axios
  .get(`${API_URL}community/feed/${id}`)

export const reportOrDeleteFeedAPI = (feedId, payload) => axios
  .put(`${API_URL}community/feed/report-or-delete/${feedId}`, payload)

export const feedsViewedAPI = payload => axios
  .put(`${API_V2_URL}community/feed/view`, payload)

export const getNetworkActivityAPI = (skip, limit) => axios
  .get(`${API_V3_URL}network/activity?skip=${skip}&limit=${limit}`)

export const getPeopleToFollowAPI = payload => axios
  .post(`${API_URL}community/suggestInfluencerAgentsToFollow`, payload)

export const getPeopleYouMayKnowAPI = payload => axios
  .post(`${API_V2_URL}user/suggestAgentsToFollow`, payload)

export const blockAndUnblockAPI = payload => axios
  .post(`${API_URL}user-relations`, payload)

export const getReportReasonsAPI = () => axios
  .get(`${API_URL}community/report/reasons`)

export const mutePostAPI = (payload, feedId) => axios
  .post(`${API_URL}community/feed/mute/${feedId}`, payload)
