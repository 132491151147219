import React from "react"
import {
  InboundSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import {
  InnerTab,
  IconEle,
  NavLink,
  NotificationDot,
} from "./commonStyles"

const ClaimedLeadsTab = ({
  routeArray,
  updatesObjectExist,
  updateData,
}) => (
  <InnerTab referral isActive={routeArray[1] === "inbound"}>
    {updatesObjectExist
      && updateData.inbound
      && !updateData.inbound.seen ? (
        <NotificationDot />
      ) : (
        <NotificationDot zero></NotificationDot>
      )}
    <div className="icon-block-new">
      <IconEle left="10px" src={InboundSVGIcon} isActive={routeArray[1] === "inbound"} />
    </div>
    <div className="text-block">
      <NavLink
        ml="-5.8px"
        to="/referrals/inbound"
        isActive={routeArray[1] === "inbound"}
      >
        Claimed Leads
      </NavLink>
    </div>
  </InnerTab>
)

export default ClaimedLeadsTab
