import axios from "axios"

export const fetchClientProfileAPI = clientId => axios.get(`${API_V2_URL}clients/${clientId}`)

export const updateClientProfileAPI = (id, data) => axios.put(`${API_V2_URL}clients/${id}`, data)

export const uploadImageAPI = image => axios.post(IMAGE_API, image)

export const addClientAPI = payload => axios.post(`${API_V2_URL}clients`, payload)

export const editClientActionAPI = (clientId, client) => axios.put(`${API_V2_URL}clients/${clientId}`, client)

export const bulkClientUpdateAPI = (type, payload) => axios
  .put(`${API_V2_URL}clients-batch-update?updateType=${type}`, { clients: payload })

export const fetchClientUpdatesAPI = clientId => axios.get(`${API_URL}clientTimeline/notes/${clientId}`)

export const fetchClientPropertyListingAPI = () => axios.get(`${API_URL}user/property?sale_type=resale`)

export const setReminderAPI = (id, payload) => axios.post(`${API_V2_URL}client/${id}/reminders`, payload)

export const setNotificationPreferenceAPI = (referralId, payload) => axios
  .put(`${API_URL}refer/${referralId}/referral-settings`, payload)

export const fetchClientTimelineAPI = (referralId, type) => axios
  .get(`${API_V2_URL}clientTimeline/${referralId}?type=${type}`)

export const convertToCRMClientAPI = payload => axios
  .put(`${API_URL}consumers/convert-referral-to-CRM`, payload)

export const fetchCallStatusAPI = (referralId, referralType) => axios
  .get(`${API_V2_URL}refer/referrals/${referralId}?referralType=${referralType}`)

export const submitCallFeedbackAPI = payload => axios
  .post(`${API_URL}crs/referrals/called-client`, payload)

export const buyerLocationSubmitAPI = payload => axios
  .post(`${API_V2_URL}requirements`, payload)

export const sellerLocationSubmitAPI = payload => axios
  .post(`${API_URL}user/property`, payload)

export const markAsQaulifiedAPI = clientId => axios
  .put(`${API_URL}qualify-client/${clientId}`)
