import React from "react"
import styled from "styled-components"
import BrokerInterestContainer from "container/App/brokersInterestContainer"
import RealtyContainer from "container/Realty"
import Footer from "dumbComponents/common/FooterNew"
import Container from "container/App"
import root from "window-or-global"
import { Helmet } from "react-helmet"
import query from "query-string"
import {
  minLargeDesktopWidth,
} from "shared/consts"
import colors from "@colors"
import RadiusNetworkImagePreview from "images/Graphics/LandingPagePreviews/CommunityPagePreview.png"
import RoomsImage from "images/Graphics/HomeV6/Rooms4x.png?q=100"
import LMPImage from "images/Graphics/HomeV6/LMP.png?q=100"
import Academy from "images/Graphics/HomeV6/WebAppFrame.png?q=100"
import CommunityImage from "images/Graphics/HomeV6/Coast.png?q=100"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import { ATC } from "@fonts"

//SECTIONS
import Header from "./Header"
import FlexElement from "./Common/FlexElement"
import Testimonial from "./TestimonialV2"
// import CarouselElement from "./Carousel"
import Upgrade from "./Upgrade"
//SECTIONS

const Wrapper = styled.div`
  ${minLargeDesktopWidth`
    padding-left: 0px;
    padding-right: 0px;
  `}
`

const META_DESCRIPTION = "Radius Community is the largest community of real estate agents and professionals.  " +
"Radius features Radius Rooms, which is an audio based chat that allows agents to host and join events on topics " +
"in real estate.  Exchange real estate knowledge and listen to educational content created by the best " +
"real estate experts and coaches.  Radius also feature a referral marketplace " +
"that allows you exchange referrals amongst each other."

class CommunityLanding extends React.Component {
  state = {
    isIosBrowser: false,
  }

  circleRef = React.createRef()

  componentDidMount() {
    // setTimeout(() => {
    //   if (window.HubSpotConversations) {
    //     window.HubSpotConversations.widget.remove()
    //   }
    // }, 1500)
    const { search } = root.location
    const parsedQuery = query.parse(search)
    this.setState({
      isIosBrowser: parsedQuery && parsedQuery.platform === "ios",
    })
  }

  scrollToDownloadSection = () => {
    if (root && root.heap) {
      root.heap.track("Click_Home_Page_Scroll_To_Download")
    }
    const scrollPoint = root.screen.width < 768 ? 6000 : 5000
    root.scrollTo({
      top: scrollPoint,
      behavior: "smooth",
    })
  }

  render() {
    const meta = {
      title: "Radius Community of Real Estate Professionals",
      description: META_DESCRIPTION,
      keywords: "Referrals, community, real estate agents, marketplace, network, coaching, training, education",
      type: "website",
      url: `${WEB_URL}`,
      image: RadiusNetworkImagePreview,
    }

    const {
      history,
      toggleApplyModal,
    } = this.props

    const roomsSubHeaderText = "Create and join Rooms across the nation with industry" +
    " experts and have meaningful conversations live."

    const { isIosBrowser } = this.state
    return (
      <React.Fragment>
        <Wrapper>
          <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta property="title" content={meta.title} />
            <meta property="og:description" content={meta.description} />
            <meta property="description" content={meta.description} />
            <meta property="keywords" content={meta.keywords} />
            <meta property="og:image" content={meta.image} />
            <meta property="image" content={meta.image} />
          </Helmet>
          <Header />
          <FlexElement
            bgColor={colors.dreamWhite}
            isTagHeaderText
            image={RoomsImage}
            tagHeaderText="NEW FEATURE!"
            headerFontFamily="Butler"
            tagFontFamily={ATC}
            contentFontFamily={ATC}
            headerText="Share insights with other agents on topics that matter to you"
            hyperLink="Download the App "
            action={this.scrollToDownloadSection}
            subHeaderText={roomsSubHeaderText}
            headerTextWidth="530px"
            customMinDesktopHeight="52%"
            customMinDesktopRight="-13px"
            customMinDesktopTop="121px"
            customMediumDesktopHeight="75%"
            mobileImageTop="-6px"
            mobileImageRight="5px"
            xsMobileImageWidth="250px"
            xsMobileImageTop="0px"
            xsMobileImageRight="7px"
            customTop="122px"
            maxHeight="503px"
            checklistData={[
              {
                text: "Share your expertise with others",
              },
              {
                text: "Listen in with industry experts",
              },
              {
                text: "Host your own rooms",
              },
            ]}
          />
          <FlexElement
            isLMP
            bgColor={colors.white}
            isTagHeaderText
            image={LMPImage}
            headerFontFamily="Butler"
            tagFontFamily={ATC}
            contentFontFamily={ATC}
            tagHeaderText="LEADS"
            customHeight="90%"
            customTop="18px"
            customRight="-2px"
            customMediumDesktopHeight="100%"
            customMediumDesktopTop="18px"
            customTabletWidth="228px"
            customTabletTop="141px"
            reverse
            headerTextWidth="459px"
            mobileImageTop="8px"
            mobileImageRight="-43px"
            xsMobileImageWidth="250px"
            xsMobileImageTop="-22px"
            xsMobileImageRight="-9px"
            headerText="Send and receive referrals, leads and more from your peers"
            hyperLink="Start Claiming Leads "
            url="/signup"
            heap="Click_LMP_Interest_Home_Page_Signup"
            subHeaderText="Never miss an opportunity to earn!"
            checklistData={[
              {
                text: "Send and Receive Referrals",
              },
              {
                text: "Earn commission for each Referral",
              },
              {
                text: "Create a nationwide network",
              },
            ]}
          />
          <FlexElement
            bgColor={colors.dreamWhite}
            isTagHeaderText
            image={Academy}
            headerTextWidth="392px"
            headerFontFamily="Butler"
            tagFontFamily={ATC}
            contentFontFamily={ATC}
            tagHeaderText="ACADEMY"
            customTop="202px"
            customRight="-12px"
            customMediumDesktopHeight="61%"
            customMediumDesktopTop="192px"
            customListWidthTablet="288px"
            mobileImageTop="26px"
            mobileImageRight="2px"
            xsMobileImageWidth="250px"
            xsMobileImageTop="19px"
            xsMobileImageRight="2px"
            headerText="Strengthen your skills with the support of experts"
            hyperLink="Go to Radius Academy "
            heap="Click_Academy_Interest_Home_Page"
            url="/academy"
            customListWidthMinDesktop="258px"
            subHeaderText="Develop and refine your skills on a wide variety to agent-focused topics at no cost"
            checklistData={[
              {
                text: "Free short courses focused on Real Estate",
              },
              {
                text: "Negotation, closing, and selling techniques",
              },
              {
                text: "Material developed by industry experts",
              },
            ]}
          />
          <FlexElement
            bgColor={colors.white}
            isTagHeaderText
            reverse
            image={CommunityImage}
            headerFontFamily="Butler"
            contentFontFamily={ATC}
            tagFontFamily={ATC}
            mobileImageTop="1px"
            mobileImageRight="10px"
            tagHeaderText="NATIONWIDE NETWORKING"
            headerText="Connect with 80,000+ agents from coast to coast"
            headerTextWidth="503px"
            subTextWidthMinDesktop="385px"
            hyperLink="Download the App "
            subHeaderText="Share your successes, expertise and learn from other agents across the nation. "
            xsMobileImageWidth="250px"
            xsMobileImageTop="16px"
            xsMobileImageRight="-9px"
            action={this.scrollToDownloadSection}
            checklistData={[
              {
                text: "Ask questions",
              },
              {
                text: "Learn and expand into new markets",
              },
              {
                text: "Grow your professional network",
              },
            ]}
          />
          <Testimonial toggleApplyModal={toggleApplyModal} />
          <Upgrade onApply={() => history.push("/signup")} />
        </Wrapper>
        {!isIosBrowser && (
          <Footer
            customStyle={{
              position: "relative",
              top: "-81px",
              zIndex: "999",
            }}
            hideHubspot
          />
        )}
      </React.Fragment>
    )
  }
}

export default BrokerInterestContainer(Container(RealtyContainer(CommunityLanding)))
