import { createSelector } from "reselect"
import { isArray } from "lodash"

export const getColumnsByStatus = (leads) => {
  if (leads && leads.length) {
    const columns = {}
    leads.forEach((item) => {
      if (!isArray(columns[`${item.sales_stage}`])) {
        columns[`${item.sales_stage}`] = []
      }
      columns[`${item.sales_stage}`].push(item)
    })
    return columns
  }
  return {}
}
