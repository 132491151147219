import React, { useState, useRef } from "react";
import { Row, Col, Form } from "antd";
import BrbcContainer from "container/BRBC";
import CRMV2Container from "container/CRM";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import {
  InputLocation,
  InputField,
  InputNumber,
  HiddenInputField,
} from "dumbComponents/common/InputFields";
import {
  StyledBrbcModal,
  ModalHeading,
  ModalSubHeading,
  FooterContainer,
  ModalHeadingContainer,
  ModalFillBtn,
  ModalLightBtn,
} from "./styles";
import { StyledCobuyerForm, SubheadingContainer2, Subheading2 } from "./styles";
import { validatePhoneNumber } from "dumbComponents/ListingsV2/Components/ListingCoversheet/components/ListingsForms/Forms/utils.js";
import { CloseOutlined } from "@ant-design/icons";

const CoBuyerForm = ({
  name,
  remove,
  formRef,
  deleteCoBuyer,
  coBuyerId,
  deleteCoBuyerReponse,
  getClientTransactions,
  getClientTransactionsById,
  transactionId,
}) => {
  const { isFetching: fetchingDelCoBuyer, data: delCoBuyerData } =
    deleteCoBuyerReponse || {};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleRemoveClick = () => {
    const allValues = formRef.current.getFieldsValue();
    const coBuyerData = allValues.coBuyers && allValues.coBuyers[name];

    const isFormFilled =
      coBuyerData && coBuyerData.firstName && coBuyerData.email;

    if (isFormFilled) {
      setIsModalVisible(true);
    } else {
      remove(name);
    }
  };

  const handleConfirmDelete = () => {
    deleteCoBuyer({
      clientId: coBuyerId,
      callback: () => {
        getClientTransactionsById({
          transactionId: transactionId,
        });
      },
    });
    !fetchingDelCoBuyer && remove(name) && setIsModalVisible(false);
  };

  const deleteCoBuyerInfo = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Remove Co-Buyer Information</ModalHeading>
        <ModalSubHeading>
          Are you sure you want to remove the co-buyer information? This action
          cannot be undone, and any details entered will be lost.
        </ModalSubHeading>
      </ModalHeadingContainer>
    </>
  );

  const handleAddressChange = (locationObject) => {
    const address = locationObject?.[`${name},address`];

    if (formRef.current) {
      formRef.current.setFieldsValue({
        coBuyers: {
          [name]: {
            address: address, 
          },
        },
      });
    } else {
      console.error("Form reference is not available.");
    }
  };

  return (
    <StyledCobuyerForm>
      <SubheadingContainer2>
        <Subheading2>CO-BUYER INFORMATION</Subheading2>
        <CloseOutlined onClick={handleRemoveClick} />
      </SubheadingContainer2>
      <HiddenInputField name={[name, "clientId"]} />
      <HiddenInputField name={[name, "id"]} />
      <Row gutter={16}>
        <Col span={12}>
          <InputField
            name={[name, "firstName"]}
            inputType="text"
            placeholder="First name*"
            text="First name"
            required={true}
            requiredMessage="First name is required."
          />
        </Col>
        <Col span={12}>
          <InputField
            name={[name, "lastName"]}
            inputType="text"
            placeholder="Last name*"
            text="Last name"
            required={true}
            requiredMessage="Last name is required."
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <InputNumber
            name={[name, "phone"]}
            inputType="phone"
            type="phone"
            placeholder="Phone"
            text="Phone"
            customRules={{
              validator: validatePhoneNumber,
            }}
          />
        </Col>
        <Col span={12}>
          <InputField
            name={[name, "email"]}
            inputType="email"
            placeholder="Email*"
            text="Email"
            type="email"
            required={true}
            requiredMessage="Email is required."
          />
        </Col>
      </Row>
      {/* <InputLocation
        name={[name, "address"]}
        inputType="text"
        type="text"
        placeholder="Address"
        required={false}
        rules={[
          {
            required: false,
          },
        ]}
        formRef={formRef}
        text="Address"
        fullAddress={true}
        handleInitChange={handleAddressChange}
      /> */}

      {/* Confirmation modal */}
      <StyledBrbcModal
        title={deleteCoBuyerInfo}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <FooterContainer>
          {
            <ModalFillBtn
              onClick={handleConfirmDelete}
              btnColor={"delete-co-buyer"}
            >
              {fetchingDelCoBuyer ? <ThreeDotLoader color="white" /> : "Remove"}
            </ModalFillBtn>
          }
          <ModalLightBtn onClick={() => setIsModalVisible(false)}>Cancel</ModalLightBtn>
        </FooterContainer>
      </StyledBrbcModal>
    </StyledCobuyerForm>
  );
};

export default CRMV2Container(BrbcContainer(CoBuyerForm));
