import React from "react"
import styled from "styled-components"

const Container = styled.div`
 iframe {
   width: 100%;
   height: 102vh;
 }
`

const WhoWeServe = () => (
  <Container>
    <iframe src="https://radius-agent.framer.website/" title="who_we_serve">
    </iframe>
  </Container>
)

export default WhoWeServe
