import React from "react"
import styled from "styled-components"
import colors from "@colors"
import root from "window-or-global"
import SettingIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/settings.svg"
import TechAssets from "container/TechAssets/index"
import { ATC } from "@fonts"

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
  border: none;
`}
`

const H1 = styled.h1`
  font-family: ${ATC};
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;  
`

const CTAButton = styled.div`
  cursor: pointer;
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 5px;
`

const Title = styled.p`
  margin: 0;
  color: ${colors.black};
  font-family: ${ATC};
`

const Header = () => (
  <HeaderWrap>
    <H1>My Active Clients</H1>
    <CTAButton
      className="zapier_integration"
      onClick={() => {
        root.open("/settings/integrations/apigeneration", "_blank")
      }}
    >
      <ButtonWrap>
        <Title>
          Zapier Integrations
        </Title>
        <img src={SettingIcon} alt="setting" />
      </ButtonWrap>
    </CTAButton>
  </HeaderWrap>
)

export default TechAssets(Header)
