import React from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  margin: 0px 10px;
  margin-left: ${props => props.ml ? props.ml : ""};
  font-size: 12px;
  padding: 5px 10px;
  min-width: ${props => props.mw ? props.mw : "auto"};
  font-family: 'DM Sans', sans-serif;
  border-radius: ${props => props.br ? props.br : "4px"};
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  ${props => props.est && `
    background-color: #ffffff;
    border: 2px solid #354052;
  `}
  ${props => props.hide && `
    opacity: 0;
  `}
`

const Badge = ({
  children,
  est,
  hide,
  style,
  br,
  minwidth,
  ml,
}) => (
  <Wrapper ml={ml} mw={minwidth} br={br} est={est} hide={hide} style={style}>
    <span>{children}</span>
  </Wrapper>
)

export default Badge
