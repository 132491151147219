import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  showConfirmModalAction,
  confirmAction,
  cancelAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { app } = state
  const { confirmModal } = app
  return {
    ...confirmModal,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      showConfirmModal: showConfirmModalAction.call,
      confirm: confirmAction.call,
      cancel: cancelAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
