import cookie from "services/CookieStorage"
import STATES from "shared/US_STATES.json"
const user = cookie.load("user")
const agent_id = user.id

export const PAYLOAD = {
  agent_id,
  save_draft: 1,
  property_info: {},
  agents_info: [],
  client_info: [],
  transaction_details: {},
}

export const OFFERS_PAYLOAD = {
  agent_id,
  save_draft: 1,
  property_info: {},
  agents_info: [],
  client_info: [],
  transaction_details: {},
}

export const PROPERTY_INFORMATION = {
  agent_id, //int
  price: "", //int
  apn_number: "", //string
  street: "",
  apt: "",
  city: "",
  state: "California",
  county: "",
  transaction_type: "residential_1_4",
  area_unit: "Sq.ft",
  bedrooms: "",
  bathrooms: "",
  image_urls: [],
  mls_id: "",
  lot: "",
  block: "",
  legal_description: "",
  tax_parcel_id_number: "",
  land_lots: "",
  district: "",
  section_gmd: "",
  unit: "",
  phase_section: "",
  subdivision_development: "",
  plat_book: "",
  page: "",
  tax_id_number: "",
  draft: "Y",
  area: "",
  current_listing_step: "property_information_update",
}

export const LISTING_AGENT_INFORMATION = {
  name: "",
  agent_id: 0,
  email: "",
  phone: "",
  landline: null,
  listing_brokerage: "",
  brokerage_license_number: "",
  brokerage_address: "",
  apt: "",
  city: "",
  zipcode: "",
  agent_license_number: "",
  is_primary: 1,
  state: "",
}

export const SELLER_INFORMATION = {
  is_primary: 1,
  firstname: "",
  lastname: "",
  email: "",
  landline: "",
  phone: "",
  address: "",
  apt: null,
  city: "",
  zipcode: "",
  state: "",
  type_of_ownership: "",
  seller_image_url: "",
  source: "",
}

export const LISTING_TERMS_INFORMATION = {
  //source: "",
  listing_contract_date: "",
  listing_on_market_date: "",
  listing_expiration_date: "",
  property_marked_public: 0,
  current_listing_step: "source_time",
  draft: "Y",
}

export const VALID_STATES = ["California", "Colorado", "Florida", "Georgia", "Oregon", "Texas", "Washington"]

export const STATE_OPTIONS = Object.keys(STATES).map(key => ({ label: STATES[key], value: STATES[key] }))
