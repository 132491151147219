import React from "react"
import NewFeature from "images/newFeature.png"
import { ATC } from "@fonts"
import {
  SidebarSectionWrap,
  Header,
  NavLink,
  BrokerageWrap,
  ApplyButton,
} from "./commonStyles"

const JoinOurBrokerageTab = ({
  routeArray,
  handleApplyNowVersion2,
}) => (
  <SidebarSectionWrap className="onboading-nav-realty">
    <Header>
      <NavLink
        Header
        to="/realty"
        isActive={routeArray[0] === "realty"}
        style={{
          color: "#303030",
        }}
      >
        LEARN ABOUT RADIUS
      </NavLink>
      <BrokerageWrap>
        <p
          className="brokerage"
          style={{
            color: "#303030",
          }}
        >
          The modern tech brokerage with flexible commission structures and back-office to help your business grow.
        </p>
        <ApplyButton
          onClick={handleApplyNowVersion2}
          style={{
            font: `${ATC}`,
          }}
        >
          Learn More
        </ApplyButton>
      </BrokerageWrap>
    </Header>
  </SidebarSectionWrap>
)

export default JoinOurBrokerageTab
