import React, { useEffect } from "react"
import EditJobIcon from "dumbComponents/RealtyV3/RARRecruitment/images/editjobs.svg"
import dynamic from "next/dynamic"
import { convertToHTML } from "services/Utils"
import {
  HeaderContainer,
  Header,
  Paragraph,
  Icon,
  ButtonGroup,
  ButtonOutlined,
  ButtonFilled,
  RichTextEditorWrapper,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"
let RichTextEditor = null

const Requirements = ({
  header,
  requirementsEdit,
  editable,
  enableEdit,
  requirementsCopy,
  updateField,
  saveField,
  isDummy = false,
  paragraph,
  isLandingPage,
}) => {
  useEffect(() => {
    if (!isLandingPage) {
      RichTextEditor = dynamic(() => import("dumbComponents/common/UI/BuilderComponents/RichTextEditor"), { ssr: false })
    }
  }, [])
  return (
    <React.Fragment>
      <HeaderContainer>
        <Header fw="700" fs="24px" source="requirements">
          {header}
        </Header>
        {!requirementsEdit && editable && (<Icon src={EditJobIcon} onClick={() => enableEdit("requirements", true)} />)}
      </HeaderContainer>
      <Paragraph>
        {requirementsCopy && (
          <RichTextEditorWrapper readOnly={!requirementsEdit}>
            {requirementsEdit ? (
              <RichTextEditor readOnly={!requirementsEdit} saveRTF={value => updateField({ content: value }, "requirements")} text={requirementsCopy} height="150px" />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: convertToHTML(requirementsCopy) }}></span>
            )}
          </RichTextEditorWrapper>
        )}
        {isDummy && (<>{paragraph}</>)}
      </Paragraph>
      {requirementsEdit && (
        <ButtonGroup>
          <ButtonOutlined text="Cancel" action={() => enableEdit("requirements", false)} />
          <ButtonFilled text="Save changes" action={() => saveField("requirements")} />
        </ButtonGroup>
      )}
    </React.Fragment>
  
  )
}

export default Requirements
