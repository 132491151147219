import * as React from "react"

const Listings = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 22.685h6a2 2 0 0 0 2-2v-13l-5-5H6a2 2 0 0 0-2 2v10"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14 2.685v4a2 2 0 0 0 2 2h4M10.4 13.285a2.121 2.121 0 0 1 3 3l-5.4 5.4-4 1 1-4 5.4-5.4Z"
      />
    </svg>
  )
}

export default Listings
