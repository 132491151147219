import axios from "axios"

export const fetchUserProfileAPI = () => axios.get(`${API_V2_URL}user/info`)
export const updateLeadAgreementAPI = () => axios.put(`${API_URL}lead-agreement/update-status`)
export const fetchRedirectionLocationAPI = referralID => axios.get(`${API_URL}redirect-isa/${referralID}`)
export const getPopUpScreenAPI = () => axios.get(`${API_V2_URL}user/popup-screens-info`)
export const closePopUpScreenAPI = payload => axios.put(`${API_URL}ignore-subscription-invite-popup`, payload)
export const submitPartnerInterestAPI = payload => axios.post(`${API_URL}partner-referral-product-interest`, payload)
export const submitBrokerInterestAPI = payload => axios.post(`${API_URL}broker-product-interest`, payload)
export const recordUserSessionAPI = payload => axios
  .post(`${API_V2_URL}user/record-user-session`, payload)

export const userSeenPopupAPI = payload => axios
  .put(`${API_V2_URL}user/last-seen-promotional-template`, payload)

export const promotionalCTAClickAPI = payload => axios
  .put(`${API_V2_URL}user/last-promotional-interest-shown`, payload)

export const submitTrasactionalInterestAPI = payload => axios
  .post(`${API_URL}moncord-product-interest`, payload)

export const getAgentsForRealtyPageAPI = (q) => axios
  .get(`${API_URL}team-page/agents${q}`)

  export const getTeamsForRealtyPageAPI = (q) => axios
  .get(`${API_URL}team-page/teams${q}`)

export const getDashboardLinksAPI = () => axios
  .get(`${API_URL}rar-resources`)

export const getSidebarMenuUpdatesAPI = () => axios
  .get(`${API_URL}side-menu-updates`)

export const feedsViewedAPI = payload => axios
  .put(`${API_V2_URL}community/feed/view`, payload)

export const registerDeviceFCMAPI = payload => axios
  .post(`${API_URL}user/gcm`, payload)

export const sessionTrackingAPI = () => axios
  .post(`${API_URL}user/session`)

export const getNotificationMappingAPI = () => axios
  .get(`${API_URL}notifications-popup`)

export const setNotificationChoiceAPI = id => axios
  .post(`${API_URL}notifications-popup/${id}`)

export const logoutUserAPI = () => axios
  .get(`${API_URL}users/logout`)

export const updateIncompleteProfilePostSignUpAPI = payload => axios
  .put(`${API_V2_URL}user/info/update`, payload)

export const fakeSignDocusignAPI = () => payload => axios
  .post(`${API_URL}refer/uploadLeadAgreement`, payload)

export const fetchInviteStatusAPI = () => axios
  .get(`${API_URL}user-refer-status`)

export const postInviteAPI = payload => axios
  .post(`${API_URL}broker-invites`, payload)

export const saveOccupationChoiceAPI = payload => axios
  .post(`${API_V2_URL}product-interest`, payload)

export const setCurrentOnboardingStateAPI = payload => axios
  .post(`${API_V2_URL}product-interest`, payload)

export const saveHappinessMeterAPI = payload => axios
  .post(`${API_URL}agent-review`, payload)

export const triggerSlackNotificationAPI = payload => axios
  .post(`${API_URL}track-onboarding`, payload)

export const submitInterestAPI = payload => axios
  .post(`${API_URL}home-page-product-interest`, payload)

export const getStateBannerAPI = () => axios
  .get(`${API_URL}user/stateLaunchBanner`)

export const getReferralCumulativeDataAPI = () => axios
  .get(`${API_URL}brokerage-referrals`)
  
export const trackReferralHomePageAPI = payload => axios
  .post(`${API_URL}track-referral-home-page`, payload)

export const createBrokerMemberInviteLogAPI = payload => axios.post(`${API_URL}brokerage-member-invite`, payload)

export const appDownloadSMSAPI = payload => axios.post(`${API_URL}appDownload-urls`, payload)
