import React from "react"
import styled from "styled-components"
import { formatMoney } from "services/Utils"
import cookie from "services/CookieStorage"
import StripeCheckout from "react-stripe-checkout"
import Button from "@ui/Button"
import Loader from "@ui/Loader"
import icons from "@icons"
import colors from "@colors"

const BillingSummaryWrap = styled.div`
  width: 377px;
  height: auto;
  min-height: 110vh;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.12);
  background-color: ${colors.white};
  position: absolute;
  right: 0px;
  top: -30px;
  padding-bottom: 300px;
`
const BillBreakDownWrap = styled.div`
  margin-bottom:50px;
`

const HeaderWrap = styled.div`
  height: 100px;
  width: 100%;
  padding: 50px 30px;
  position: relative;
  top: 108px;
  margin-bottom: 88px;
`

const ListHeaderWrap = styled.div``

const ListHeader = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.black};
`

const HeaderText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 0.77;
  color: ${colors.contentText};
`
const ListItem = styled.li`
  &:last-child {
    border-bottom: 1px dashed ${colors.charcoalBlueGrey};
    padding-bottom: 50px;
  }
`

const CouponDetails = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  right: 20px;
  bottom: 50px;
`

const BreakDownList = styled.ul`
   padding: 31px;
`

const InputHolder = styled.div`
  width: 200px;
  margin-left: 24px;
  position: relative;
`
const CouponInput = styled.input`
  outline: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.borderColor};
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: inherit;
  color: inherit;
  height: 100%;
  border-radius: 4px;

  &:focus {
    border-color: ${colors.primaryColor};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: inherit;
    font-weight: inherit;
  }
`

const DescriptionWrap = styled.div``

const Description = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.faintBlueishBlack};
  margin: 0;
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom}px` : "0"};
  ${props => props.fontWeight && `
     font-weight: 500;
   `}

  ${props => props.isSuccess && `
    color: ${colors.textGreen};
  `}
`

const DisabledInput = styled.div`
  background-color: ${colors.bgGrey};
  padding: 12px;
  color: ${colors.disabledInput};
  width: 100%;
  font-size: 16px;
  position: relative;
`

const AppliedTag = styled.div`
  background-color: ${colors.blueDarkShade};
  color: ${colors.faintBlueishBlack};
  border-radius: 4px;
  padding: 4px 12px;
  line-height: 1.5;
  position: absolute;
  left: 0;
  top: 54px;
  display: flex;
`

const StyledIcon = styled.i`
  margin-right: 6px;
  position: relative;
  top: 4px;
  color: ${props => props.color};
`

const ApplyButton = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.primaryColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const Amount = styled.div`
  width: 140px;
  text-align: right;
  color: ${colors.faintBlueishBlack};
`

const CTASection = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;

  div{
   position: relative;
   left: 100px;
   bottom: 50px;
  }
`
const BillingDetails = styled.div`
  position: relative;
  right:24px;

`

const BillingItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  font-size: 16px;
  flex-direction: row-reverse;
  align-items: center;
`

const TotalItem = styled(BillingItem)`
  font-size: 22px;
  margin-bottom: 4px;
`
const TotalAmount = styled(Amount)`
  font-weight: 500;
  color: ${colors.contentText};
`

const Text = styled.p`
  position: relative;
  right: 119px;
`

const renderList = (invoices, teamSize, inviteCode) => {
  const getTotal = (adminCount, duration, amount, isTeam, type) => {
    const isBlu = type === "blu"
    if (isTeam && !isBlu) {
      return formatMoney(adminCount * duration * amount)
    }
    if (duration > 1) {
      return `${formatMoney((amount / duration))} X ${duration} ${duration > 1 ? "months" : "month"}`
    }
    return `${formatMoney(duration * amount)} ${isBlu ? "per 100 leads" : ""}`
  }
  const invoiceList = invoices.map((invoice, iter) => (
    <ListItem>
      <ListHeaderWrap>
        <ListHeader>
          {invoice.product === "rtl" ? "Real-time Leads" : "Bulk Lead Upload"}
        </ListHeader>
      </ListHeaderWrap>
      <DescriptionWrap>
        <Description>
          {`Type: ${invoice.type ? "Team" : "Single"}`}
        </Description>
        <Description>
          {`Level: ${invoice.tier}`}
        </Description>
        {invoice.product === "rtl"
         && (
           <Description>
             {`Commitment: ${invoice.commitment}`}
           </Description>
         )}
        {(invoice.isTeam && invoice.product === "rtl") && (
          <Description>
            {`Admin accounts: (${teamSize}) x ${invoice.amountLabel} months`}
          </Description>
        )}
        <Description
          marginBottom={30}
          fontWeight={500}
        >
          {`$${getTotal(teamSize, invoice.duration, invoice.amount, invoice.isTeam, invoice.product)}`}
        </Description>
      </DescriptionWrap>
    </ListItem>
  ))

  return (
    <React.Fragment>
      {invoiceList}
      {inviteCode && (
        <ListItem>
          <ListHeaderWrap>
            <ListHeader>
              Referral bonus
              <span role="img">💰</span>
            </ListHeader>
          </ListHeaderWrap>
          <DescriptionWrap>
            <Description isSuccess>
            $50 Amazon gift card
            </Description>
            <Description>
                We’ll deliver this card to your email once we confirm your subscription.
            </Description>
          </DescriptionWrap>
        </ListItem>
      )}
    </React.Fragment>
  )
}

class BillingSummary extends React.Component {
 state = {
   couponCode: "",
   inviteCode: null,
 }

 componentDidMount() {
   const inviteCode = cookie.load("inviteCode")
   if (inviteCode) {
     this.setState({
       inviteCode,
     })
   }
 }

 handleTextChange = (e) => {
   const couponCode = e.target.value
   this.setState({
     couponCode,
   })
 }

 handleCheckout = ({ id: tokenId }) => {
   const {
     checkoutPricingCart,
     teamSize,
     categorizedSubscriptions,
     pricingPlansMap,
     invoiceItems,
   } = this.props

   let nowFlag = false
   //const isSubscribedToBLU = categorizedSubscriptions.blu

   const isSubscribedToRTL = categorizedSubscriptions.rtl
   if (isSubscribedToRTL) {
     const currentlySubscribed = pricingPlansMap[categorizedSubscriptions.rtl]
     const currentDuration = currentlySubscribed.duration
     const inCartItem = invoiceItems.filter(item => item.product === "rtl")
     if (inCartItem.length > 1) {
       const cartItemDuration = pricingPlansMap[inCartItem[0].planName].duration
       nowFlag = cartItemDuration > currentDuration
     }
   }
   // else if (isSubscribedToBLU) {
   //   const currentlySubscribed = pricingPlansMap[categorizedSubscriptions.blu]
   //   const currentDuration = currentlySubscribed.duration
   //   const inCartItem = invoiceItems.filter(item => item.product === "blu")
   //   if (inCartItem.length > 1) {
   //     const cartItemDuration = pricingPlansMap[inCartItem[0].planName].duration
   //     nowFlag = cartItemDuration > currentDuration
   //   }
   // }

   checkoutPricingCart({
     tokenId,
     teamSize,
     now: nowFlag,
   })
 }

 handleApply = () => {
   const { couponCode } = this.state
   const { applyPricingCoupon } = this.props
   if (couponCode) {
     applyPricingCoupon({
       couponCode,
     })
   }
 }

 handleEnterPress = (e) => {
   if (e.key === "Enter") {
     e.preventDefault()
     this.handleApply()
   }
 }

 render() {
   const {
     invoiceItems,
     isCartHavingTeamPlan,
     teamSize,
     isCouponApplicable,
     coupon,
     subTotal,
     discountItems,
     checkoutTotal,
   } = this.props
   const { inviteCode } = this.state
   const {
     isApplying,
     couponCode,
     isValid,
   } = coupon

   return (
     <BillingSummaryWrap
       isCartHavingTeamPlan={isCartHavingTeamPlan}
     >
       <HeaderWrap>
         <HeaderText>
           Billing Summary
         </HeaderText>
       </HeaderWrap>
       <BillBreakDownWrap>
         <BreakDownList>
           {renderList(invoiceItems, teamSize, inviteCode)}
         </BreakDownList>
       </BillBreakDownWrap>
       {!(isCouponApplicable) && (
         <CouponDetails>
           {isApplying
             ? (
               <Loader size={25} />
             )
             : isValid
               ? (
                 <InputHolder>
                   <DisabledInput>
                     {couponCode}
                     <AppliedTag>
                       <StyledIcon color={colors.textGreen} className={icons.genericCheckMarkRounded} />
                       Coupon applied!
                     </AppliedTag>
                   </DisabledInput>
                 </InputHolder>
               )
               : (
                 <React.Fragment>
                   <InputHolder>
                     <CouponInput
                       value={this.state.couponCode}
                       onChange={this.handleTextChange}
                       onKeyPress={this.handleEnterPress}
                     />
                     {couponCode && (
                       <AppliedTag>
                         <StyledIcon
                           color={colors.redText}
                           className={icons.genericClose}
                         />
                         {`${couponCode} is not valid`}
                       </AppliedTag>
                     )}
                   </InputHolder>
                   <ApplyButton onClick={this.handleApply}>
                     Apply Coupon
                   </ApplyButton>
                 </React.Fragment>
               )
           }
         </CouponDetails>
       )}
       <BillingDetails>
         <BillingItem>
           <Amount>
             {`$${formatMoney(subTotal)}`}
           </Amount>
           <Text>
             Subtotal
           </Text>
         </BillingItem>
         {discountItems && discountItems.map(discountItem => (
           <BillingItem key={discountItem.planName}>
             <Amount>
               {`- $${formatMoney(discountItem.discount)}`}
             </Amount>
             <div>
               {discountItem.discountLabel}
             </div>
           </BillingItem>
         ))}
         <TotalItem>
           <TotalAmount>
             {`$${formatMoney(checkoutTotal)}`}
           </TotalAmount>
           <Text>Total</Text>
         </TotalItem>
       </BillingDetails>
       <CTASection>
         {/*<StyledLink to={plansPageRoute}>
           <StyledCaret className={icons.arrowsKeyboardLeft} />
           Review plans
         </StyledLink>*/}
         <StripeCheckout
           token={this.handleCheckout}
           stripeKey={`${STRIPE_PUBLISH_KEY}`}
           ComponentClass="div"
           panelLabel="Subscribe"
         >
           <Button
             color={colors.white}
             fontWeight={500}
             disabled={isApplying}
           >
             Go to payment
           </Button>
         </StripeCheckout>
       </CTASection>
     </BillingSummaryWrap>
   )
 }
}

export default BillingSummary
