import React, { useState } from "react"
import OffersV2 from "container/OffersV2/index"
import OldOffers from "container/TechAssets"
import { BlueCloseIcon } from "dumbComponents/OffersV2/icons"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  CloseButton,
} from "./styles"
import {
  EnvelopesWrap,
} from "../styles"

import Form from "./Form"
import Attachments from "./Attachments"

const SendOffer = ({
  goBack,
  completeOfferPackageToBeSent,
  sendOfferPackageMail,
  dealToView,
  sendOfferPackageMailResponse,
}) => {
  const {
    isFetching: sendingOfferPackage,
  } = sendOfferPackageMailResponse || {}

  const {
    envelopes,
    proofOfFundsDocs = [],
    coverLetters = [],
    preQualified = [],
  } = completeOfferPackageToBeSent || {}
  
  const handleSend = (values) => {
    const {
      from,
      from_email,
      to,
      to_email,
      cc,
      cc_email,
      offer_package_subject,
      custom_message,
    } = values || {}

    const attachments = [
      ...proofOfFundsDocs.map(item => item.url),
      ...coverLetters.map(item => item.url),
      ...preQualified.map(item => item.url),
    ]

    const v2Docs = envelopes.map(item => ({
      id: item.id,
      documents: [
        ...item.documents,
      ],
    }))

    const payload = {
      subject: offer_package_subject,
      offerId: dealToView,
      body: custom_message,
      to: [
        {
          name: to,
          email: to_email,
        },
      ],
      cc: [
        {
          name: cc,
          email: cc_email,
        },
      ],
      from: {
        name: from,
        email: from_email,
      },
      attachmentUrls: attachments || [],
      docusignEnvelopes: [],
      docusignEnvelopesV2: v2Docs || [],
      entityType: "offerPackage",
      entityId: dealToView,
      secure_id: "bf30a11ce3e472d285dd7c1a5bbd8f48",
    }

    sendOfferPackageMail({
      payload,
    })
    goBack()
  }

  return (
    <EnvelopesWrap>
      <CTAWrap>
        <CloseButton
          onClick={goBack}
        >
          <BlueCloseIcon />
        </CloseButton>
      </CTAWrap>
      <SectionWrap>
        <Form
          onFinish={handleSend}
          sendingOfferPackage={sendingOfferPackage}
        >
          <Attachments />
        </Form>
      </SectionWrap>
    </EnvelopesWrap>
  )
}

export default OldOffers(OffersV2(SendOffer))
