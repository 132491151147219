import React from "react"
import styled from "styled-components"
import colors from "@colors"
import TechAssetsContainer from "container/TechAssets"
import SuccessConfetti from "images/Graphics/Confetti.svg?q=100"
import Container from "container/Realty/TransactionFormContainer"
import { withRouter } from "react-router-dom"
import Loader from "@ui/Loader"
import { ordinalSuffix } from "../Forms/Common/Utils"
import {
  MAIN_MENU,
  BASIC_FORM_ROUTE,
} from "../Routes"

const success = "images/Graphics/success2.gif"

// const success = ENV === "staging"
//   ? "https://www.staging.radiusagent.com/dist/images/success.gif"
//   : "https://www.radiusagent.com/dist/images/success2.gif"

const Wrap = styled.div``

const InnerWrap = styled.div`
  height: 518px;
  width: 816px;
  border-radius: 16px;
  margin-top: 38px;
  background: ${colors.deepPurple};
  padding-top: 22px;
  padding-bottom: 46px;
  margin: auto;
`

const AnimationArea = styled.div`
  height: 200px;
  width: 287px;
  border-radius: 0px;
  margin: 22px auto;
`

// const SuccessGif = styled.img`
//   width: 640px;
//   height: 460px; 
//   position: relative; 
//   right: 188px;
//   pointer-events: none;
// `
const SuccessGif = styled.div`
  width: 640px;
  height: 460px; 
  position: relative; 
  right: 188px;
  pointer-events: none;
`

const MessageWrap = styled.div`
  height: max-content;
  width: 70%;
  margin: 0 auto;
`

const Message = styled.p`
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.white};
`

const SubMessage = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: ${colors.white};
  margin-top: 16px;
`

const CTAWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

const ViewSubmittedFilesCTA = styled.button`
  height: 48px;
  width: max-content;
  margin-right: 10px;
  border-radius: 100px;
  padding: 16px 14px 16px 14px;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  background: #F1F3F8;
  color: ${colors.black};
`

const SubmitAnotherFile = styled.button`
 height: 48px;
 width: max-content;
 margin-right: 10px;
 border-radius: 100px;
 padding: 16px 14px 16px 14px;
 font-family: DM Sans;
 font-size: 16px;
 font-style: normal;
 font-weight: 500;
 line-height: 16px;
 letter-spacing: 0px;
 text-align: center;
 border: none;
 background: transparent;
 color: ${colors.white};

 span {
  position: relative;
  left: 8px;
  top: 2px;
  font-size: 20px;
  display: inline;

 }
`

const SuccessSvgImageWrap = styled.img`
  position: absolute;
  // left: 350px;
  left: 42%;
  height: 30%;
`

const SuccessPageComponent = ({
  history,
  fetchTransactionCoordinationDocumentsResponse,
  transactionFormUpdateStageResponse,
  setLoaderOnAgentInfo,
  resetContractsForm,
  resetListingsForm,
  setDealToView,
  setOfferIdToView,
}) => {
  const {
    data: stageData,
    isFetching: isFetchingStageDetails,
  } = transactionFormUpdateStageResponse || {}

  const {
    metrics,
  } = stageData || {}

  let total = 0

  if (metrics && Object.keys(metrics).length > 0) {
    total = metrics.total_deals
  }

  const noStageData = stageData === null
    || stageData === undefined
    || stageData === "undefined"
    || metrics === null
    || metrics === undefined
    || metrics === "undefined"

  if (noStageData && total === 0) {
    // history.push("/realty/transaction-coordination")
  }
  console.log("==fetchTransactionCoordinationDocumentsResponse===", fetchTransactionCoordinationDocumentsResponse)
  return (
    <Wrap>
      {isFetchingStageDetails ? (
        <Loader top={50} />
      ) : (
        <InnerWrap>
          <SuccessSvgImageWrap src={SuccessConfetti} alt="svg" />
          <AnimationArea>
            {/* <SuccessGif src={success} /> */}
            <SuccessGif />
          </AnimationArea>
          <MessageWrap>
            <Message>{`Congrats on your ${ordinalSuffix(fetchTransactionCoordinationDocumentsResponse?.data?.length)} Transaction with Radius!`}</Message>
            <SubMessage>We’ve received your submission. Thank you.</SubMessage>
          </MessageWrap>
          <CTAWrap>
            <ViewSubmittedFilesCTA
              onClick={() => {
                history.push(MAIN_MENU)
              }}
            >
              View Your Contracts
            </ViewSubmittedFilesCTA>
            <SubmitAnotherFile
              onClick={() => {
                setLoaderOnAgentInfo(true)
                setOfferIdToView(null)
                setDealToView(null)
                resetContractsForm()
                resetListingsForm()
                history.push("/realty/transaction-coordination/contract/basic-info")
              }}
            >
              Add Another Contract
              <span>&gt;</span>
            </SubmitAnotherFile>
          </CTAWrap>
        </InnerWrap>
      )}
    </Wrap>
  )
}

export default TechAssetsContainer(Container(withRouter(SuccessPageComponent)))
