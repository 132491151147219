import React from "react"
import {
  WellnessSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const WellnessTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[0] === "wellness"}>
    <div className="icon-block-new">
      <IconEle
        left="22px"
        src={WellnessSVGIcon}
        alt="RARProducts"
      />
    </div>
    <div className="text-block">
      <NavLink
        to="/wellness/promo"
        isActive={routeArray[0] === "wellness"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_Wellness_web")
          }
        }}
      >
        Wellness
      </NavLink>
    </div>
  </InnerTab>
)

export default WellnessTab
