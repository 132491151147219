import React, { Component } from "react"
import { LoadScript } from "services/Utils"
import Textbox from "@ui/Textbox"
import styled from "styled-components"
import icons from "@icons"

const FormGroup = styled.div`
  margin-bottom: 15px;
  outline: 0;
  border: 1px solid #949494;
  border-radius: 8px;
  width: auto;
`

const InputGroup = styled.span`
  position: relative;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  border-radius: 8px;
`

const InputText = styled(Textbox)`
  border-right: none;
  display: table-cell;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border: none;
`

const InputGroupAddOn = styled.span`
  background: white;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: table-cell;
  i {
    color: black;
  }
`

class AutoComplete extends Component {
  constructor(props) {
    super(props)
    this.referenceHandler = this.referenceHandler.bind(this)
  }

  loadScript() {
    if (typeof window.google === "undefined") {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
  }

  googleAutoCompleteInitializer() {
    const options = {
      types: this.props.types,
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputElement, options)
    if (this.props.restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(this.props.restrict.south_west.lat, this.props.restrict.south_west.lng),
        new window.google.maps.LatLng(this.props.restrict.north_east.lat, this.props.restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = this.props.restrict.country.short_name.toLowerCase()
      geoComponents.setOptions(options)
    }
    window.google.maps.event.addDomListener(this.inputElement, "keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    window.google.maps.event.addListener(geoComponents, "place_changed", () => {
      const place = geoComponents.getPlace()
      if (place.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          south_west: {
            lat: place.geometry.viewport.getSouthWest().lat(),
            lng: place.geometry.viewport.getSouthWest().lng(),
          },
          north_east: {
            lat: place.geometry.viewport.getNorthEast().lat(),
            lng: place.geometry.viewport.getNorthEast().lng(),
          },
          address: place.formatted_address,
        }
        place.address_components.map(component => (location[component.types[0]] = component))
        if (this.props.clearInput) {
          this.inputElement.value = ""
          this.inputElement.focus()
        }
        this.props.location(location)
      }
    })
  }

  componentDidMount() {
    this.loadScript()
    // this.inputElement.focus();
  }

  referenceHandler(input) {
    this.inputElement = input
  }

  render() {
    const {
      loader: isLoader,
      isTextbox,
      placeholder,
      id,
      value,
      disabled,
    } = this.props
    const loader = isLoader ? (
      <img src="https://d2fedz0by71ckz.cloudfront.net/images/rds-logo-blue.gif" height="22" alt="radius loader" />
    ) : (
      <i className={icons.genericSearch} styles={{ color: "black" }} />
    )
    if (isTextbox) {
      return <Textbox id={id} placeholder={placeholder} reference={this.referenceHandler} />
    }

    return (
      <FormGroup>
        <InputGroup>
          <InputText
            disabled={disabled}
            id={id}
            placeholder={placeholder}
            reference={this.referenceHandler}
            defaultValue={value}
          />
          <InputGroupAddOn>{loader}</InputGroupAddOn>
        </InputGroup>
      </FormGroup>
    )
  }
}

export default AutoComplete
