import React from "react"
import ReactDOM from "react-dom"
import styled, { css } from "styled-components"
import Cookies from "react-cookies"
import root from "window-or-global"
import {
  get,
  isEmpty,
  isObject,
  union,
  size,
} from "lodash"
import { MOBILE_BREAK_POINT } from "shared/consts"
import { Helmet } from "react-helmet"
import Container from "container/Profile"
import SEOImg from "images/homeV5/RadiusNewPreview.png"
import feedContainer from "container/Network/feedContainer"
import Box from "@ui/Box"
import Button from "@ui/Button"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import CircularImage from "dumbComponents/common/CircularImage"
//import Feeds from "@ui/Feeds"
import Loader from "@ui/Loader"
import SeeMore from "@ui/SeeMorePara"
import LoaderButton from "@ui/LoaderButton"
import { moneyFormatter } from "shared/currencyUtils"
import {
  isBrowser as isBrowserFunction,
  canUserEdit,
  removeCommaFromNumber,
  checkForUserObject,
  addHttpInURl,
  MoneyFormatter,
} from "services/Utils"
// Icons
import ExpertiseIcon from "images/profile/muscles.svg"
import CameraIcon from "images/icons/camera.png"
import AboutIcon from "images/profile/brainstorm.svg"
import ShoppingListIcon from "images/profile/shopping-list.svg"
import { isArray } from "util"
import Panel from "./Panel"
import ProfileEditModal from "./EditOptions"
import ProfileCompletion from "./ProfileCompletion"
import SideBar from "./Sidebar"
import EmptyState from "./EmptyState"
import FeedContainer from "./FeedsContainer"
import {
  SocialMediaHolder,
  getTypeMapping,
  getInitialValueBasedOnType,
  PanelHeading,
  LoaderWrap,
} from "./HelperComponents"
import { ADD_BUYER_NEEDS_INITIAL_VALUES } from "./EditOptions/AddBuyerNeeds"
import CitiesServed from "./CitiesServed"

const FALLBACK_COVER_IMAGE = "https://s3.amazonaws.com/icons.agentdesks.com/mail_api/cover1.png"
const FALLBACK_SHARE_IMAGE = SEOImg

const Wrapper = styled.div`
  margin-top: 20px;
  max-width: 940px;
  margin: 0 auto;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    #ProfileBodyWrap {
      flex-direction: column;
      #SideBar {
        width: 100%;
      }
    }
    #InfoWrap {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-left: 0px;
      p,
      button {
        text-align: center;
      }

      #profileEditButton {
        align-self: flex-end;
        margin-right: 20px;
      }
    }
  }
`

const EditButton = styled.button`
  border: none;
  padding: 0px;
  background: transparent;
  color: #11adf3;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
`

const EditCoverImageButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #cccc;
  padding: 10px;
  color: #fff;
  visibility: hidden;
  cursor: pointer;

  ${props => props.type2
    && css`
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        font-size: 66px;
        margin-bottom: 10px;
      }

      span {
        font-weight: 300;
      }
    `}
`

const CoverImage = styled.div`
  min-height: 364px;
  background-size: cover;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;

  &:hover ${EditCoverImageButton} {
    visibility: visible;
  }
`

const UserInfoWrap = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
`

const Tag = styled.span`
  display: inline-block;
  background-color: #e9f7fd;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
`

const HoverOverlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  cursor: pointer;

  > img {
    width: 81px;
    height: auto;
  }

  > p {
    font-weight: 300;
    margin-top: 10px;
  }
`

const ProfileHeaderWrap = styled(Box)`
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 110px;
    padding-bottom: 30px;
    top: 0px;

    #profile-image-wrapper {
      top: -94px;
      position: absolute;
    }
  }
`

const Anchor = styled.a`
  color: #11adf3;
  text-decoration: none;
  font-weight: 300;
  margin-bottom: 5px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`

const CircularBadge = ({ src, title, alt }) => (
  <div
    style={{
      borderRadius: "50%",
      marginRight: 10,
      textAlign: "center",
      width: 65,
    }}
  >
    {typeof src === "string" ? <img src={src} alt={alt} style={{ width: 26, height: 26, marginBottom: 5 }} /> : <src />}
    <h6 style={{ fontSize: 10, margin: 0, padding: 0 }}>{title}</h6>
  </div>
)

const DataOrAdd = ({
  data = "", addTitle, handleClick, isYourProfile, isComponent,
}) => {
  if (!data && isYourProfile) {
    return (
      <EditButton
        style={{
          fontWeight: 300,
          marginBottom: 5,
          fontSize: 14,
          display: "block",
        }}
        onClick={handleClick}
      >
        {addTitle}
      </EditButton>
    )
  }
  if (data && isComponent) {
    return <Paragraph size="14">{data}</Paragraph>
  }
  if (typeof data !== "string") {
    return ""
  }
  if (!data && !isYourProfile) {
    return ""
  }
  return <Paragraph size="14">{data}</Paragraph>
}

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.isBrowser = isBrowserFunction()
  }

  eventRef = React.createRef()

  componentDidMount() {
    const {
      getFollowersAndFollowing,
      fetchSpecializations,
      agent,
      fetchUserProfileInfo,
      fetchBuyerNeeds,
      getOwnFollowerAndFollowing,
      fetchFilterSkeleton,
      history,
      getOnboardingDropDownValues,
      closeLikers,
      profileViewed,
      updateProfileViewCount,
    } = this.props
    if (isEmpty(agent)) {
      fetchUserProfileInfo(history)
    }
    const scrollTo = Cookies.load("scrollTo")
    closeLikers()
    getOnboardingDropDownValues()
    //getGoals()
    // This is called from client if AgentId exits or else called from fetchUserProfileInfo
    // if agent object is missing, if both cases in will be called.
    const apiAgentId = get(agent, "id", "")
    if (apiAgentId) {
      updateProfileViewCount({ agentId: apiAgentId })
      getFollowersAndFollowing(apiAgentId)
      fetchBuyerNeeds(`?agentId=${apiAgentId}`)
    }
    const secureId = checkForUserObject("secureId")
    getOwnFollowerAndFollowing()
    fetchFilterSkeleton()
    fetchSpecializations()
    this.setState({
      isBrowser: true,
      isLogged: !!secureId,
    })

    if (secureId) {
      profileViewed({ type: "profile" })
    }

    if (scrollTo) {
      this.scrollAction()
    } else {
      this.scrollToTop()
    }
  }

  scrollAction = () => {
    this.scrollToBottom()
  }

  scrollToTop = () => {
    root.scrollTo({
      top: this.eventRef.current && this.eventRef.current.scrollTop,
      behavior: "smooth",
    })
  }

  scrollToBottom = () => {
    root.scrollTo({
      top: this.eventRef.current && this.eventRef.current.offsetTop - 400 || 0,
      behavior: "smooth",
    })
  }

  handleEditClick = () => {
    const { openEditProfileModal, agent } = this.props
    openEditProfileModal({
      type: "editBasicInfo",
      initialValues: agent,
    })
  }

  handleProfileComplitionClick = (step) => {
    const { openEditProfileModal, agent, agent: { profileCompletionRemainingSteps } = {} } = this.props
    const nextStep = step || profileCompletionRemainingSteps[0]
    let activeTab
    if (nextStep === "mls") {
      activeTab = "Sync MLS"
    }
    const type = getTypeMapping(nextStep)
    openEditProfileModal({
      isProfileCompletion: true,
      type,
      initialValues: getInitialValueBasedOnType(type, agent),
      activeTab,
    })
  }

  handleEducationSubmit = (values, ignore, form) => {
    const { type } = form
    const { saveProfileUpdate } = this.props
    const payload = values[type]
      && values[type].length > 0
      && values[type]
        .filter(item => !isEmpty(item))
        .map((item) => {
          const finalItem = item
          if (type === "experiences") {
            finalItem.to_year = isObject(item.to_year) ? get(item, "to_year.value", "") : get(item, "to_year", "")
          } else {
            finalItem.year = isObject(item.year) ? get(item, "year.value", "") : get(item, "year", "")
          }
          finalItem.from_year = isObject(item.from_year) ? get(item, "from_year.value", "") : get(item, "from_year", "")
          return finalItem
        })
    saveProfileUpdate({
      [type]: payload || [],
    })
  }

  handleBasicInfoSave = (value) => {
    const finalValue = value
    const { saveProfileUpdate } = this.props
    delete finalValue.socialMedia
    saveProfileUpdate({ ...value })
  }

  handleNeighborhoodSave = (data) => {
    const { saveCityServed } = this.props
    saveCityServed(data)
  }

  handleBuyerNeedsSubmit = (value) => {
    const { agent } = this.props
    const agent_id = get(agent, "id")
    const { addBuyerNeeds } = this.props
    const {
      types, area_unit, max_budget, min_budget,
    } = value
    let formattedType
    if (isArray(types)) {
      formattedType = types.map(item => item.value)
    } else {
      formattedType = types.value
    }

    let minBudget = min_budget
    let maxBudget = max_budget

    if (typeof max_budget === "number") {
      maxBudget = max_budget.toString()
    }

    if (typeof min_budget === "number") {
      minBudget = min_budget.toString()
    }

    addBuyerNeeds({
      ...value,
      agent_id,
      types: formattedType,
      max_budget: MoneyFormatter(maxBudget),
      min_budget: MoneyFormatter(minBudget),
      area_unit: area_unit.value || area_unit,
    })
  }

  handleSkip = () => {
    const { agent, activeNextStepIndex, skipProfileCompletionStep } = this.props
    const newSkipIndex = activeNextStepIndex + 1
    const { profileCompletionRemainingSteps = [] } = agent
    this.handleProfileComplitionClick(profileCompletionRemainingSteps[newSkipIndex])
    skipProfileCompletionStep(newSkipIndex)
  }

  handleMessageClick = () => {
    const iframe = root.document.getElementById("messages_iframe")
    const payload = {
      type: "message-button-click",
      data: "this is sample data",
    }

    iframe.contentWindow.postMessage(JSON.stringify(payload), "*")
  }

  componentWillUnmount() {
    const { clearProfile } = this.props
    clearProfile()
  }

  render() {
    const { isBrowser, isLogged } = this.state
    const {
      history,
      //mappedFeeds,
      properties,
      isShowEditModal,
      openEditProfileModal,
      closeEditProfileModal,
      initialValuesForm,
      saveProfileUpdate,
      isSavingUpdates,
      preFetchedData,
      uploadProfileImage,
      getNeighbourhoodByCity,
      followers,
      following,
      activeTab,
      isFetchingNeighbourhood,
      isFetchingMoreNeighbourhood,
      isUploadingPropertyImages,
      propertyImages,
      getPropertyTypes,
      neighbourhoods,
      getPropertyStatus,
      preFetchedStatus,
      preFetchedTypes,
      uploadPropertyImages,
      saveAddListing,
      followAgent,
      buyerNeeds,
      unfollowAgent,
      agent = {},
      isShowFollowLoader,
      addCity,
      removeCity,
      ownFollowersAndFollowing,
      propertyColors,
      statusColors,
      mappedProperties,
      isProfileCompletion,
      activeNextStepIndex,
      removeImageFromCookie,
      //fetchMoreFeeds,
      //isFetchingFeeds,
      //isFetchedAllFeeds,
      removeCityServedCity,
      removeCityServedNeighbourhood,
      isRemovingCityServedCity,
      isRemovingCityServedNeighbourhood,
      removeBuyerNeeds,
      isRemovingBuyerNeeds,
      profileSubmitReferral,
      MLSSyncError,
      getAgentFeeds,
      goals,
      isGoalsSent,
      sendGoals,
      isSendingGoals,
      errorResponse: formErrorResponse,
      isUserOnboarded,
      isAppMounted,
      isaDropdownValuesObject,
      showIsaPopup,
      handleOnboardingSubmit,
      onboardingFormSubmit,
      user,
      feedsResponse,
      fetchCityNeighborhoodServed,
      unblockUser,
      unblockResponse,
    } = this.props
    const { isFetching: isUnblockingAgent } = unblockResponse
    const {
      isFetching: isSubmittingOnboardingForm,
      data: submitResponse,
    } = onboardingFormSubmit
    const {
      firstname,
      lastname,
      office_address,
      mlsLicense,
      images = [],
      about_agent,
      specializations = [],
      //badges = [],
      id: agentId,
      //citiesServed,
      //neighbourhoodsServed,
      profile_url,
      mlsInfo,
      mlsLicenseState,
      mlsPropertiesCount,
      profileCompletionPercentage,
      isCrsDesignee,
      agency_name = "",
      profileCompletionRemainingSteps,
      socialMedias,
      crs_designee_since,
      associations = [],
      website,
      userSpecializations = [],
      office_zipcode,
      office_city,
      office_state,
      office_street_address,
      office_suite_no,
      crsCitiesPurchased = [],
      crsFeaturedCities = [],
      crsZipcodePurchased = [],
    } = agent
    const isUserCanEdit = canUserEdit(agentId)
    const coverImage = images.find(item => item.type === "cover") || {}
    const profileImage = images.find(item => item.type === "profile") || {}
    const { unmappedNeighbourhoods, cities } = properties || {}
    const nextStep = profileCompletionRemainingSteps && profileCompletionRemainingSteps[activeNextStepIndex]
    const nextToNextStep = profileCompletionRemainingSteps && profileCompletionRemainingSteps[activeNextStepIndex + 1]
    const mergedSpecializations = union(userSpecializations, specializations)
    const OG_TITLE = `${firstname} ${lastname} - Top Real Estate Agent | Radius Agent Community`
    const OG_DESCRIPTION = `
      Discover ${firstname} ${lastname}, a top-rated real estate agent in the Radius Agent community.
      Connect with ${firstname} ${lastname} for expert advice and personalized service in your area.
    `

    if (isEmpty(agent)) {
      if (isBrowser) {
        const ele = root.document.getElementById("profile-page-container")
        if (ele) {
          ReactDOM.unmountComponentAtNode(ele)
        }
      }
      return (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )
    }

    return (
      <div id="profile-page-container">
        <Helmet>
          <title>{OG_TITLE}</title>
          <meta property="og:url" content={profile_url} />
          <meta property="og:title" content={OG_TITLE} />
          <meta property="og:description" content={OG_DESCRIPTION} />
          <meta property="og:image" content={profileImage.path || FALLBACK_SHARE_IMAGE} />
          <meta property="url" content={profile_url} />
          <meta property="type" content="article" />
          <meta property="title" content={OG_TITLE} />
          <meta property="description" content={OG_DESCRIPTION} />
          <meta name="title" content={OG_TITLE} />
          <meta name="description" content={OG_DESCRIPTION} />
          <meta property="image" content={profileImage.path || FALLBACK_SHARE_IMAGE} />
        </Helmet>
        <CoverImage style={{ backgroundImage: `url('${coverImage.path || FALLBACK_COVER_IMAGE}')` }}>
          {isUserCanEdit && (
            <EditCoverImageButton
              type2
              onClick={() => {
                openEditProfileModal({
                  type: "coverImageUpload",
                  initialValues: coverImage,
                })
              }}
            >
              <img src={CameraIcon} alt="upload Icon" />
              <span>Add photo</span>
            </EditCoverImageButton>
          )}
        </CoverImage>
        <ProfileEditModal
          syncMLSPRops={this.props}
          activeTab={activeTab}
          agentId={agentId}
          show={!!isShowEditModal}
          images={images}
          toClose={closeEditProfileModal}
          closeEditProfileModal={closeEditProfileModal}
          saveProfileUpdate={saveProfileUpdate}
          type={isShowEditModal}
          initialValues={initialValuesForm}
          isSavingUpdates={isSavingUpdates}
          preFetchedData={preFetchedData}
          uploadProfileImage={uploadProfileImage}
          getNeighbourhoodByCity={getNeighbourhoodByCity}
          neighbourhoods={neighbourhoods}
          isFetchingNeighbourhood={isFetchingNeighbourhood}
          isFetchingMoreNeighbourhood={isFetchingMoreNeighbourhood}
          getPropertyTypes={getPropertyTypes}
          getPropertyStatus={getPropertyStatus}
          preFetchedStatus={preFetchedStatus}
          preFetchedTypes={preFetchedTypes}
          uploadPropertyImages={uploadPropertyImages}
          isUploadingPropertyImages={isUploadingPropertyImages}
          isProfileCompletion={isProfileCompletion}
          propertyImages={propertyImages}
          saveAddListing={saveAddListing}
          handleEducationSubmit={this.handleEducationSubmit}
          handleBasicInfoSave={this.handleBasicInfoSave}
          handleNeighborhoodSave={this.handleNeighborhoodSave}
          handleBuyerNeedsSubmit={this.handleBuyerNeedsSubmit}
          addCity={addCity}
          removeCity={removeCity}
          nextStep={nextStep}
          nextToNextStep={nextToNextStep}
          profileCompletionRemainingSteps={profileCompletionRemainingSteps}
          skipProfileCompletionStep={this.handleSkip}
          removeImageFromCookie={removeImageFromCookie}
          removeCityServedNeighbourhood={removeCityServedNeighbourhood}
          removeCityServedCity={removeCityServedCity}
          removeBuyerNeeds={removeBuyerNeeds}
          isRemovingBuyerNeeds={isRemovingBuyerNeeds}
          MLSSyncError={MLSSyncError}
          formErrorResponse={formErrorResponse}
        />
        <UserInfoWrap>
          <Wrapper>
            <ProfileHeaderWrap d="flex" pt="40">
              <Box position="relative" t="-133" id="profile-image-wrapper">
                <CircularImage
                  size="194"
                  name={`${firstname} ${lastname}`}
                  image={profileImage.path}
                  fontSize="40px"
                  hideOverflow
                  hoverOverlay={
                    isUserCanEdit && (
                      <HoverOverlay
                        onClick={() => {
                          openEditProfileModal({
                            type: "profileImageUpload",
                            initialValues: profileImage,
                          })
                        }}
                      >
                        <img src={CameraIcon} alt="upload Icon" />
                        <p>Add Photo</p>
                      </HoverOverlay>
                    )
                  }
                  style={{
                    border: "2px solid #FFF",
                  }}
                />
              </Box>
              <Box flex="1" pl="40" id="InfoWrap">
                {isUserCanEdit && (
                  <EditButton
                    id="profileEditButton"
                    onClick={() => {
                      openEditProfileModal({
                        type: "editBasicInfo",
                        initialValues: agent,
                      })
                    }}
                  >
                    Edit
                  </EditButton>
                )}
                <Heading type="h4" align="left">{`${firstname || ""} ${lastname || ""}`}</Heading>
                <DataOrAdd
                  data={agency_name}
                  addTitle="Add Brokerage"
                  handleClick={this.handleEditClick}
                  isYourProfile={isUserCanEdit}
                />
                <DataOrAdd
                  data={
                    isCrsDesignee ? (
                      <span>
                        <p style={{ margin: 0 }}>{`${office_suite_no || ""} ${office_street_address || ""}`}</p>
                        <span>
                          {office_city ? `${office_city || ""}` : ""}
                          {office_state ? `, ${office_state || ""}` : ""}
                          {office_zipcode ? `, ${office_zipcode || ""}` : ""}
                        </span>
                      </span>
                    ) : (
                      office_address
                    )
                  }
                  addTitle="Add office Address"
                  handleClick={this.handleEditClick}
                  isYourProfile={isUserCanEdit}
                  isComponent
                />
                {mlsLicense && <Paragraph size="14">{`License #${mlsLicense}`}</Paragraph>}
                <DataOrAdd
                  data={socialMedias}
                  addTitle="Add Social Media"
                  handleClick={this.handleEditClick}
                  isYourProfile={isUserCanEdit}
                />
                { website && (
                  <Anchor href={addHttpInURl(website)} target="_blank" style={{ margin: "10px 0px" }}>
                    View my website
                  </Anchor>
                )}
                {isCrsDesignee && crs_designee_since && (
                  <Paragraph size="12" mt="4">{`CRS Since ${new Date(crs_designee_since).getFullYear()}`}</Paragraph>
                )}
                <Box d="flex" mt="10" mb="20">
                  {/* {badges && badges.map(badge => (
                    <CircularBadge
                      key={badge.id}
                      src={badge.badge_icon}
                      title={badge.badge_name}
                      alt={badge.badge_description}
                    />
                  ))} */}
                  {associations
                    && associations.map(association => (
                      <CircularBadge
                        key={association.association}
                        src={association.icon}
                        title={association.association}
                        alt={association.association}
                      />
                    ))}
                </Box>
                <SocialMediaHolder socialMedia={socialMedias} />
              </Box>
              {isUserCanEdit ? (
                <Box>
                  <Button
                    onClick={() => {
                      openEditProfileModal({
                        type: "shareProfile",
                        initialValues: profile_url,
                      })
                    }}
                  >
                    Share Profile
                  </Button>
                </Box>
              ) : (
                <Box d="flex" ai="flex-start">
                  {/* {isLogged ? (
                    <a href={`${CRM_URL}app/contacts?openchat=true&view=thread&agentId=${agentId}`} target="_blank">
                      <Button bsStyle="secondary" style={{ marginRight: 10 }}>
                        Message Agent
                      </Button>
                    </a>
                  ) : (
                    isBrowser && <ContactUsButton />
                  )} */}
                  <span style={{ width: 20, height: 1, display: "inline-block" }} />
                  {isBrowser ? (
                    <React.Fragment>
                      <Button onClick={() => { profileSubmitReferral({ agent, history }) }}>
                        Send Referral
                      </Button>
                    </React.Fragment>

                  ) : (
                    <Button type="button">Send Referral</Button>
                  )}
                </Box>
              )}
            </ProfileHeaderWrap>
          </Wrapper>
        </UserInfoWrap>
        <Wrapper>
          <Box d="flex" id="ProfileBodyWrap">
            {/* SideBar */}
            <SideBar
              agentid={agentId}
              isUserCanEdit={isUserCanEdit}
              unblockUser={() => { unblockUser({ payload: { agent_id: agent.id, is_blocked: 0 }}) }}
              isUnblockingAgent={isUnblockingAgent}
              following={following}
              followers={followers}
              handleFollowClick={followAgent}
              unfollowAgent={unfollowAgent}
              openEditProfileModal={openEditProfileModal}
              isShowFollowLoader={isShowFollowLoader}
              agent={agent}
              ownFollowersAndFollowing={ownFollowersAndFollowing}
            />
            {/* Main */}
            <Box flex="1">
              {isUserCanEdit && (
                <ProfileCompletion
                  nextStep={nextStep}
                  profileCompletionRemainingSteps={profileCompletionRemainingSteps || []}
                  progressPercentage={profileCompletionPercentage}
                  handleClick={this.handleProfileComplitionClick}
                />
              )}
              <Panel
                title="About"
                isShowEdit={isUserCanEdit}
                type="about"
                key="about"
                handleEditClick={openEditProfileModal}
                initialValues={about_agent}
                agentId={agentId}
              >
                {about_agent ? (
                  <SeeMore text={about_agent} />
                ) : (
                  <EmptyState
                    type="about"
                    src={AboutIcon}
                    isMain
                    title="Create your bio"
                    heroText="What would you like other agents to know about you?"
                    handleEditClick={openEditProfileModal}
                    initialValues={about_agent}
                  />
                )}
              </Panel>
              <Panel
                key="expertise"
                title="Expertise"
                isShowEdit={isUserCanEdit}
                type="expertise"
                handleEditClick={openEditProfileModal}
                initialValues={userSpecializations}
                agentId={agentId}
              >
                {mergedSpecializations && mergedSpecializations.length > 0 ? (
                  mergedSpecializations.map(item => <Tag key={item}>{item}</Tag>)
                ) : (
                  <EmptyState
                    type="expertise"
                    src={ExpertiseIcon}
                    isMain
                    handleEditClick={openEditProfileModal}
                    title="Share your Expertise"
                    heroText="What are your strengths?"
                    initialValues={userSpecializations}
                  />
                )}
              </Panel>
              <Panel
                title="Cities served"
                editText={!isEmpty(cities) ? "Add City and Neighborhoods" : ""}
                type="neighborhoodEdit"
                key="neighborhoodEdit"
                isShowEdit={isUserCanEdit}
                handleEditClick={openEditProfileModal}
                initialValues={{ cities }}
                agentId={agentId}
              >
                {!isEmpty(cities) ? (
                  <CitiesServed
                    isUserCanEdit={isUserCanEdit}
                    cities={cities}
                    unmappedNeighbourhoods={unmappedNeighbourhoods}
                    isCRS={isCrsDesignee}
                    removeCityServedCity={removeCityServedCity}
                    isRemovingCityServedCity={isRemovingCityServedCity}
                    iisRemovingCityServedNeighbourhood={isRemovingCityServedNeighbourhood}
                    removeCityServedNeighbourhood={removeCityServedNeighbourhood}
                    openEditProfileModal={openEditProfileModal}
                  />
                ) : (
                  isUserCanEdit && (
                    <EmptyState
                      type="neighborhoodEdit"
                      src={ShoppingListIcon}
                      isMain
                      handleEditClick={openEditProfileModal}
                      title="Add City and Neighborhoods"
                      heroText="What are your strengths?"
                      initialValues={cities}
                    />
                  )
                )}
              </Panel>
              {isCrsDesignee
                && ((unmappedNeighbourhoods && unmappedNeighbourhoods.length > 0)
                  || (crsFeaturedCities && crsFeaturedCities.length > 0)
                  || (crsCitiesPurchased && crsCitiesPurchased.length > 0)
                  || (crsZipcodePurchased && crsZipcodePurchased.length > 0)) && (
                    <Panel title="CRS Locations" editText="" initialValues={{ alwayShow: true }}>
                  {unmappedNeighbourhoods && unmappedNeighbourhoods.length > 0 && (
                    <React.Fragment>
                          <PanelHeading title="Neighborhoods" />
                          {unmappedNeighbourhoods.map(item => (
                            <Tag key={item}>{item}</Tag>
                          ))}
                        </React.Fragment>
                      )}

                  {crsFeaturedCities && crsFeaturedCities.length > 0 && (
                    <React.Fragment>
                      <PanelHeading title="Featured Cities" />
                      {crsFeaturedCities.map(item => (
                        <Tag key={item.city}>{item.city}</Tag>
                      ))}
                    </React.Fragment>
                  )}

                  {crsCitiesPurchased && crsCitiesPurchased.length > 0 && (
                    <React.Fragment>
                      <PanelHeading title="Cities" />
                      {crsCitiesPurchased.map(item => (
                        <Tag key={item.city}>{item.city}</Tag>
                      ))}
                    </React.Fragment>
                  )}

                  {crsZipcodePurchased && crsZipcodePurchased.length > 0 && (
                    <React.Fragment>
                      <PanelHeading title="Zipcodes" />
                      {crsZipcodePurchased.map(item => (
                        <Tag key={item.zipcode}>{item.zipcode}</Tag>
                      ))}
                    </React.Fragment>
                  )}
                </Panel>
              )}
              
              <Panel
                title="Buyer search criteria"
                key="buyerNeeds"
                type="addBuyerNeeds"
                isShowEdit={isUserCanEdit}
                handleEditClick={openEditProfileModal}
                initialValues={ADD_BUYER_NEEDS_INITIAL_VALUES}
                editText={<span style={{ fontSize: 20 }}>+</span>}
                agentId={agentId}
              >
                {buyerNeeds && buyerNeeds.length > 0 ? (
                  buyerNeeds.map((item) => {
                    let city = get(item, "locations[0].city", "")
                    city = city ? `, ${city}` : ""
                    return (
                      <Tag
                        key={item.req_id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openEditProfileModal({
                            type: "addBuyerNeeds",
                            initialValues: {
                              ...item,
                              isEditing: true,
                            },
                          })
                        }}
                      >
                        {`$${moneyFormatter(item.max_budget)}${city}`}
                      </Tag>
                    )
                  })
                ) : (
                  <EmptyState
                    type="addBuyerNeeds"
                    src={ShoppingListIcon}
                    isMain
                    handleEditClick={openEditProfileModal}
                    title="Add buyer needs"
                    heroText="Working with buyers?"
                    descriptionText="Share your buyers’ search criteria with other agents
                      to help them find a property match!"
                    initialValues={ADD_BUYER_NEEDS_INITIAL_VALUES}
                  />
                )}
              </Panel>
              <FeedContainer
                agentId={agentId}
                getAgentFeeds={getAgentFeeds}
                feeds={feedsResponse}
              />
              {/* {mappedFeeds.length > 0 && (
                <Panel
                  title="Recent activity"
                  isShowEdit={isUserCanEdit}
                  key="feeds"
                  initialValues={{
                    alwayShowEdit: true,
                  }}
                >
                  <Feeds
                    feeds={mappedFeeds}
                    isFetchingFeeds={isFetchingFeeds}
                    profileImage={profileImage}
                    name={`${firstname} ${lastname}`}
                    fetchMoreFeeds={fetchMoreFeeds}
                    isFetchedAllFeeds={isFetchedAllFeeds}
                  />
                </Panel>
              )} */}
            </Box>
          </Box>
        </Wrapper>
        {/* { ((isAppMounted && isUserCanEdit)
         && (showIsaPopup && !submitResponse))
         && (
           <NewOnboarding
             user={user}
             firstname={firstname}
             goals={goals}
             sendGoals={sendGoals}
             toClose={() => { }}
             dropdownValues={isaDropdownValuesObject}
             isSendingGoals={isSendingGoals}
             handleSubmit={handleOnboardingSubmit}
             isSubmittingOnboardingForm={isSubmittingOnboardingForm}
           />
         )} */}
      </div>
    )
  }
}

export default feedContainer(Container(Profile))
