import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getEventRoomDetailsAction, getScheduledRoomEventDetailsAction, joinRoomAsGuestDetailsAction, leaveRoomAsGuestDetailsAction } from "./actions"

const mapStateToProps = (state, ownProps) => {
  const { eventRoom: eventRoomServer } = (ownProps && ownProps.staticContext) || {}
  const { scheduledEventRoom: scheduledEventRoomServer } = (ownProps && ownProps.staticContext) || {}
  const { eventRoom } = state
  return {
    ...eventRoom,
    ...eventRoomServer,
    ...scheduledEventRoomServer,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      getEventRoomDetails: getEventRoomDetailsAction.request,
      joinRoomAsGuestDetails: joinRoomAsGuestDetailsAction.request,
      leaveRoomAsGuestDetails: leaveRoomAsGuestDetailsAction.request,
      getScheduledRoomsDetails: getScheduledRoomEventDetailsAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
