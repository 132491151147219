import React from "react"
import styled from "styled-components"
import { reduxForm, Field, } from "redux-form"
import { isObject } from "lodash"
import Select from "react-select"
//import { Input, Select } from "@ui/Form"
import Textbox from "@ui/Textbox"
import Box from "@ui/Box"
import {
  Footer,
  Header,
  SaveButton,
  BlankButton,
  H5,
} from "./commonStyles"

const createYearList = () => {
  const currentYear = new Date().getFullYear()
  const yearList = []
  const size = 50
  for (let i = currentYear - size; currentYear >= i; i += 1) {
    yearList.push({ value: i, label: i })
  }
  return yearList.reverse()
}

const Wrapper = styled.form`
  border: 1px solid  #e7ebed;
  border-radius: 4px;
`

const InputFormWrap = styled.div`
  padding: 10px;
  margin-bottom: 30px;
`

const SingleForm = styled.div`
  padding: 10px;
  position: relative;
  margin-bottom: 30px;
`

const FieldRow = styled.div`
  position: relative;

  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
      padding: 10px;
    }
  }
`

const RemoveButton = styled.button`
  position: absolute;
  color: #CC2A2F;
  border: none;
  background: transparent;
  bottom: -20px;
  right: 10px;
`

class RenderInputForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { initialValues } = this.props
    let FormatteValue
    if (!initialValues || initialValues.length === 0) {
      FormatteValue = [{ school: "", from_year: null, to_year: null }]
      this.setState({
        fields: [...FormatteValue],
      })
    } else {
      FormatteValue = initialValues && initialValues.length > 0 && initialValues.map(item => ({
        ...item,
        from_year: isObject(item.from_year) ? item.from_year : { value: item.from_year, label: item.from_year },
        year: isObject(item.year) ? item.year : { value: item.year, label: item.year },
      }))
    }
    this.setState({
      fields: [...FormatteValue],
    })
  }

  addItem = () => {
    const { fields } = this.state
    fields.push({ school: "", from_year: {}, year: {}, course: "" })
    this.setState({
      fields: [...fields],
    })
  }

  handleInput = (name, value, index) => {
    const { checkForChange } = this.props
    const { fields } = this.state
    fields[index][name] = value
    this.setState({
      fields: [...fields],
    })
    checkForChange(true)
  }

  removeItem = (index) => {
    const { checkForChange } = this.props
    const { fields } = this.state
    delete fields[index]
    const newFields = fields.filter(x => x)
    this.setState({
      fields: [...newFields],
    })
    checkForChange(true)
  }

  render() {
    const { fields } = this.state
    const yearList = createYearList()
    return (
      <InputFormWrap>
        {fields && fields.length > 0 && fields.map((item, index) => (
          <SingleForm>
            <FieldRow>
              {/* <Field
                name={`${item}.school`}
                label="School*"
                component={Input}
                optional
                optionalText="Ex: Colorado University"
              /> */}
              <H5 isLite label>School*</H5>
              <Textbox
                type="text"
                value={item.school}
                onChange={(e) => {
                  this.handleInput(e.target.name, e.target.value, index)
                }}
                name="school"
              />
            </FieldRow>
            <FieldRow>
              {/* <Field
                name={`${item}.course`}
                label="Degree*"
                component={Input}
                optional
                optionalText="Ex: Commercial Real Estate cert."
              /> */}
              <H5 isLite label>Degree*</H5>
              <Textbox
                type="text"
                value={item.course}
                onChange={(e) => {
                  this.handleInput(e.target.name, e.target.value, index)
                }}
                name="course"
              />
            </FieldRow>
            {/* <FieldRow>
              <Field
                name={`${item}.field_of_study`}
                label="Field of study**"
                component={Input}
                optional
                optionalText="Ex: Real Estate"
              />
              <H5 isLite label>Field of study*</H5>
              <Textbox
                type="text"
                value={item.field_of_study}
                onChange={(e) => {
                  this.handleInput(e.target.name, e.target.value, index)
                }}
                name="field_of_study"
              />
            </FieldRow> */}
            <Box d="flex">
              <Box flex="1">
                <FieldRow style={{ marginTop: 20 }}>
                  <H5 isLite label>From</H5>
                  {/* <Field
                    component={Select}
                    placeholder="From year"
                    name={`${item}.from_year`}
                    options={yearList}
                  /> */}
                  <Select
                    value={typeof item.from_year !== "object" ? { label: item.from_year, value: item.from_year } : item.from_year}
                    onChange={(val) => {
                      this.handleInput("from_year", val, index)
                    }}
                    options={yearList}
                  />
                </FieldRow>
              </Box>
              <Box flex="1" ml="20">
                <FieldRow style={{ marginTop: 20 }}>
                  <H5 isLite label>To</H5>
                  {/* <Field
                    component={Select}
                    placeholder="To year"
                    name={`${item}.to_year`}
                    options={yearList}
                  /> */}
                  <Select
                    value={typeof item.year !== "object" ? { label: item.year, value: item.year } : item.year}
                    onChange={(val) => {
                      this.handleInput("year", val, index)
                    }}
                    options={yearList}
                  />
                </FieldRow>
              </Box>
            </Box>
            <RemoveButton type="button" onClick={() => { this.removeItem(index) }}>Remove</RemoveButton>
          </SingleForm>
        ))}
        <BlankButton
          onClick={() => { this.addItem() }}
          type="button"
          style={{ marginTop: 20 }}
        >
          Add More....
        </BlankButton>
      </InputFormWrap>
    )
  }
}

class Education extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChanged: false,
    }
    this.formRef = React.createRef()
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    onSubmit({ education: this.formRef.current.state.fields }, null, { type: "education" })
  }

  checkForChange = (val) => {
    this.setState({
      isChanged: true,
    })
  }

  render() {
    const {
      isSavingUpdates,
      initialValues = [],
    } = this.props
    const { isChanged } = this.state
    const { education } = initialValues
    return (
      <Wrapper>
        <Header>
          <h4>Add your education</h4>
        </Header>
        <RenderInputForm
          initialValues={education}
          ref={this.formRef}
          checkForChange={this.checkForChange}
        />
        <Footer d="flex" jc="flex-end">
          <SaveButton
            width="100px"
            type="button"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSubmit}
            disabled={!isChanged}
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "EDUCATION_FORM",
})(Education)

