import React from "react"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets/index"
import Listings from "container/Listings/index"
import ListingsV2 from "container/ListingsV2/index"
import OffersV2 from "container/OffersV2/index"
import {
  WriteAnOfferText,
  CrumbWrap,
} from "./styles"

const TransactionBreadCrumb = ({
  history,
  toggleDocumentTab,
  setListingId,
  setCurrentListingTab,
  setOfferIdToView,
  setDealToView,
  toggleActiveTab,
  setCreateTransactionalFlow,
  setEditingDealTeamMemberId,
  clearExistingData,
}) => (
  <CrumbWrap
    onClick={() => {
      //history.goBack()
      setListingId(null)
      setDealToView(null)
      setOfferIdToView(null)
      setCreateTransactionalFlow(null)
      setEditingDealTeamMemberId(null)
      clearExistingData()
      setCurrentListingTab("cover_sheet")
      toggleActiveTab("cover_sheet")
      toggleDocumentTab("")
      history.push("/realty/transaction-dashboard")
    }}
  >
    <WriteAnOfferText>
      <p>
        {"<"}
        {" "}
        Return to all transactions
      </p>
    </WriteAnOfferText>
  </CrumbWrap>
)

export default Listings(ListingsV2(TechAssets(withRouter(OffersV2(TransactionBreadCrumb)))))
