import { Checkbox } from "antd"
import React from "react"
import {
  FilterWrap,
} from "./styles"

const OPTIONS = [{
  value: "residential_1_4",
  label: "Standard Residential",
},
{
  value: "manufactured_mo",
  label: "Manufactured / Mobile Home",
},
{
  value: "vacant_land",
  label: "Vacant Land",
},
{
  value: "residential_inc",
  label: "Residential Income Property",
},
{
  value: "lease",
  label: "Lease",
}]

const ListingType = ({
  onChange,
  value,
}) => (
  <FilterWrap
    style={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Checkbox.Group
      options={OPTIONS}
      onChange={onChange}
      value={value}
    />
  </FilterWrap>
)

export default ListingType

