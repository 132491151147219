import React from "react"
import styled from "styled-components"
import { Elements } from "react-stripe-elements"
import OnboardSetup from "container/OnboardingSetup"
import SettingsContainer from "container/Settings/BillingContainer"
import Container from "container/Settings"
import InjectedCardForm from "./InjectedCardForm"

const Wrap = styled.div`
`

const SetupForm = () => (
  <Wrap>
    <Elements>
      <InjectedCardForm />
    </Elements>
  </Wrap>
)

export default SettingsContainer(Container(OnboardSetup(SetupForm)))
