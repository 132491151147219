import * as React from 'react'

const Phone = props => {
  const { color = '#5A5FF2' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M10.854 13.644a12.634 12.634 0 0 1-2.638-3.809.749.749 0 0 1 .166-.827l.82-.818c.67-.67.67-1.62.084-2.206L8.112 4.81a2 2 0 0 0-2.828 0l-.652.652c-.74.741-1.05 1.81-.85 2.87.494 2.613 2.012 5.474 4.461 7.923 2.45 2.45 5.31 3.967 7.923 4.461 1.06.2 2.13-.109 2.87-.85l.651-.65a2 2 0 0 0 0-2.829l-1.173-1.173a1.5 1.5 0 0 0-2.12 0l-.904.904a.749.749 0 0 1-.827.166 12.66 12.66 0 0 1-3.809-2.64Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Phone
