import * as React from "react"

const Agents = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 11.685a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM10.3 15.685H7a4 4 0 0 0-4 4v2M17 20.685a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM21 21.685l-1.9-1.9"
      />
    </svg>
  )
}

export default Agents
