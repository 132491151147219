import styled from "styled-components"
import { Modal, Select, Button } from "@ui/antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  width: 350px;
`

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 9px !important;
  }
  .ant-select {
    border-radius: 9px !important;
    border: 1px solid #A5A8FF !important;
    width: 350px;
    height: 42px !important;
  }
  .ant-select-selector {
    border-radius: 9px !important;
    height: 100% !important;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center !important;
    padding: 48px 63px 36px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-vertical .ant-form-item-label > label {
    font-family: ${INTER};
    color: #000000;
  }
`

export const ModalTitle = styled.h1`
  font-family: ${MONTSERRAT};
  font-size: ${props => props.fontSize || "16px"};
  font-weight: 600;
  color: #5A5FF2;
  // padding: 0px 40px;
  text-align: ${props => props.textAlign || "center"};
`

export const ModalSelect = styled(Select)`
`

export const ModalDescription = styled.p`
  font-family: ${INTER};
  font-size: 15px;
  font-wight: 400;
  color: #000000;
  // padding: 0px 40px;
`

export const SubmitButton = styled(Button)`
  width: 350px !important;
  height: 42px !important;
  border-radius: 33.5px !important;
  background: #5A5FF2 !important;
  color: #F9F9F9 !important;
  border: none !important;
  margin-top: 10px;

  &:hover {
    background: #7a7eeb !important;
    color: #F9F9F9 !important;
  }
`
