import React from "react"
import { Wrap } from "./styles"
import createToast from "./customToast"

const Toast = () => {
  const success = () => {
    // createToast("Failed to send reminder", "success")
    createToast("Failed to send reminder", "error", () => console.log("here"))
  }
  return (
    <Wrap
      onClick={success}
    >
      Click here
    </Wrap>
  )
}

export default Toast
