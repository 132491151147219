import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

export const SideNavWrap = styled.div`
  display: flex;
  align-items: end;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;

  ${props => props.isActive && `
    background: var(--Tabselected, #F4F4FF);
  `}
`

export const SideNavTitleWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const SideNavTitle = styled.p`
  margin: 0;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;

  ${props => props.isActive && `
    color: var(--Color-Primary-Primary, #5A5FF2);
  `}
`
