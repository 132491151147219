import React from "react"
import styled from "styled-components"
import Checkbox from "@ui/Checkbox"
import Container from "container/Search/FilterContainer"
import colors from "@colors"
import icons from "@icons"
import Button from "@ui/Button"
import FilterAutoSuggest from "./FilterAutoSuggest"

const Wrapper = styled.div`
  background: #ffffff;
  color: ${colors.contentText};
  position: fixed;
  top: 74px;
  bottom: 0px;
  width: 375px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  right: -400px;
  transition-duration: 0.3s;
  z-index: 10;
  ${props => props.isFilterPanelOpen
  && `
    right: 0px;
  `}
`

const Header = styled.header`
  display: flex;
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.borderColor};
  ${props => props.dark
  && `
      background: #fbfcfc;
  `}

  > span {
    font-size: 18px;
    font-weight: 500;
  }

  > a {
    color: ${colors.primaryColor};
    font-size: 16px;
    cursor: pointer;
  }
`

const SubHeaderSpan = styled(Header)`
  padding: 12px 24px;
  
  ${props => props.clickable && `
    cursor: pointer;
  `}
`

const HeaderSpan = styled.span`
  letter-spacing: 0.9px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.28;
  user-select: none;
  ${props => props.clickable && `
    cursor: pointer;
  `}
`

const ScrollableArea = styled.div`
  overflow-y: scroll;
  padding-bottom: 200px;
`

const StyledListItem = styled.li`
  display: flex;
  line-height: 1.21;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
  border-bottom: 1px solid #e7ebed;
  transition-duration: 0.2s;
  cursor: pointer;
  user-select: none;

  > span {
    font-size: 18px;
  }

  > a {
    color: ${colors.primaryColor};
    font-size: 16px;
    cursor: pointer;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`

const StyledShowMore = styled(StyledListItem)`
  font-weight: 400;
  color: ${colors.primaryColor};
  
  > span {
    font-size: 14px;
  }

  > i {
    font-size: 20px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid ${colors.borderColor};
`

const Title = styled.span`
  font-size: 16px;
`

const TitleClear = styled.span`
  color: ${colors.primaryColor};
  font-weight: 500;
  cursor: pointer;
`

const CancelContainer = styled.div`
  width: 160px;
  text-align: center;
`

const StyledCancel = styled(Button)`
  border: none;
  width: fit-content;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  color: ${colors.primaryColor};
`

export const filterMapArray = [
  {
    key: "specialties",
    title: "Expertise",
    searchEnabled: true,
    placeholder: "Search expertise",
  },
  {
    key: "languages",
    title: "Language",
    searchEnabled: true,
    placeholder: "Search languages",
  },
  {
    key: "designation",
    title: "Designations",
    searchEnabled: true,
    placeholder: "Search designations",
  },
  {
    key: "certifications",
    title: "Certifications",
    searchEnabled: false,
  },
  {
    key: "citiesServed",
    title: "Neighborhoods Served",
    searchEnabled: true,
    placeholder: "Search neighborhoods",
  },
  {
    key: "counties",
    title: "Counties Served",
    searchEnabled: true,
    placeholder: "Search counties",
  },
  {
    key: "companies",
    title: "Company",
    searchEnabled: true,
    placeholder: "Search companies",
  },
]

export const keyedSingleFiltersMap = {
  narInvestor: "RPAC Major Investor",
}

const FilterSectionCollapsed = ({ filterMeta: { key, title }, changeFilterSelectedCategory }) => (
  <div>
    <SubHeaderSpan
      onClick={() => changeFilterSelectedCategory(key)}
      clickable
      dark
    >
      <HeaderSpan>{title}</HeaderSpan>
    </SubHeaderSpan>
  </div>
)

const FilterSection = (props) => {
  const {
    filterMeta,
    keyedFacadesForCategory,
    unappliedFiltersForCategory,
    appliedFiltersForCategory,
    selectSearchFilter,
    removeSearchFilter,
    lastFilterItemIndex,
    showMoreFiltersForCategory,
    filterSelectedCategory: filterCategory,
  } = props

  const { key, title, searchEnabled, placeholder } = filterMeta

  const handleFilterSelect = (itemToSelect) => {
    selectSearchFilter({
      itemToSelect,
      filterCategory,
    })
  }

  const handleFilterRemove = (itemToRemove) => {
    removeSearchFilter({
      itemToRemove,
      filterCategory,
    })
  }

  const toDisplayUnappliedFilters = unappliedFiltersForCategory.slice(0, lastFilterItemIndex)
  const toShowMore = lastFilterItemIndex < unappliedFiltersForCategory.length

  return (
    <div>
      <SubHeaderSpan
        dark
      >
        <HeaderSpan>{title}</HeaderSpan>
      </SubHeaderSpan>
      {searchEnabled && (
        <FilterAutoSuggest
          unappliedFiltersForCategory={unappliedFiltersForCategory}
          keyedFacadesForCategory={keyedFacadesForCategory}
          filterCategory={filterCategory}
          selectSearchFilter={selectSearchFilter}
          placeholder={placeholder}
        />
      )}
      <ul>
        {appliedFiltersForCategory.map(filterKey => (
          <StyledListItem key={keyedFacadesForCategory[filterKey]} onClick={() => handleFilterRemove(filterKey)}>
            <span>{keyedFacadesForCategory[filterKey]}</span>
            <Checkbox
              checked
            />
          </StyledListItem>
        ))}
        {toDisplayUnappliedFilters.map(filterKey => (
          <StyledListItem key={keyedFacadesForCategory[filterKey]} onClick={() => handleFilterSelect(filterKey)}>
            <span>{keyedFacadesForCategory[filterKey]}</span>
            <Checkbox
              checked={false}
            />
          </StyledListItem>
        ))}
        {toShowMore && (
          <StyledShowMore showMore onClick={showMoreFiltersForCategory}>
            <span>Show More</span>
            <i className={icons.arrowsExpandDown} />
          </StyledShowMore>
        )}
      </ul>
    </div>
  )
}

const SingleFilter = ({ filterMeta, checked, updateSingleFilterPayload }) => {

  const { key, title } = filterMeta

  const handleSingleFilterUpdate = (filterKey) => {
    updateSingleFilterPayload({
      filterKey,
    })
  }
  return (
    <StyledListItem onClick={() => handleSingleFilterUpdate(key)}>
      <span>{title}</span>
      <Checkbox
        checked={checked}
      />
    </StyledListItem>
  )
}

const FilterPanel = (props) => {
  const {
    keyedFacadesForCategory,
    appliedFiltersForCategory,
    unappliedFiltersForCategory,
    selectSearchFilter,
    removeSearchFilter,
    getSearchedAgents,
    clearAllSearchFilters,
    filterSelectedCategory,
    changeFilterSelectedCategory,
    lastFilterItemIndex,
    showMoreFiltersForCategory,
    showFilterPanel,
    toggleFilterPanel,
    keyedSingleFilters,
    updateSingleFilterPayload,
  } = props

  const handleApplyFilters = () => {
    getSearchedAgents({
      isInitial: false,
      currentPageNumber: 1,
    })
  }

  // const getFiltersToDisplay = (inputFilterMapArray) => {
  //   const clonedArray = JSON.parse(JSON.stringify(inputFilterMapArray))
  //   const assocKeys = Object.keys(associationMap)
  //   let removeAssociations = false
  //   if (assocKeys.length > 0) {
  //     let i
  //     for (i = 0; i < assocKeys.length; i += 1) {
  //       if (queryParams[assocKeys[i]]) {
  //         removeAssociations = true
  //         break
  //       }
  //     }
  //
  //     if (removeAssociations) {
  //       remove(clonedArray, {
  //         key: "associations",
  //       })
  //     }
  //     return clonedArray
  //   }
  //   return inputFilterMapArray
  // }
  //
  // const toDisplayFilterMapArray = getFiltersToDisplay(filterMapArray)

  return (
    <Wrapper isFilterPanelOpen={showFilterPanel}>
      <Header dark>
        <HeaderSpan>
          <Title>FILTERS</Title>
        </HeaderSpan>
        <div onClick={clearAllSearchFilters}>
          <TitleClear>
            Clear
          </TitleClear>
        </div>
      </Header>
      <ScrollableArea>
        {(filterMapArray).map(mapObject => (
          mapObject.key === filterSelectedCategory
            ? (
              <FilterSection
                key={mapObject.key}
                filterMeta={mapObject}
                keyedFacadesForCategory={keyedFacadesForCategory}
                appliedFiltersForCategory={appliedFiltersForCategory}
                unappliedFiltersForCategory={unappliedFiltersForCategory}
                selectSearchFilter={selectSearchFilter}
                removeSearchFilter={removeSearchFilter}
                filterSelectedCategory={filterSelectedCategory}
                changeFilterSelectedCategory={changeFilterSelectedCategory}
                lastFilterItemIndex={lastFilterItemIndex}
                showMoreFiltersForCategory={showMoreFiltersForCategory}
              />
            )
            : (
              <FilterSectionCollapsed
                key={mapObject.key}
                filterMeta={mapObject}
                changeFilterSelectedCategory={changeFilterSelectedCategory}
              />
            )
        ))}
        <SubHeaderSpan>
          <Title>Other Filters</Title>
        </SubHeaderSpan>
        <ul>
          {Object.keys(keyedSingleFilters).map(filterKey => (
            <SingleFilter
              key={filterKey}
              filterMeta={{
                key: filterKey,
                title: keyedSingleFiltersMap[filterKey],
              }}
              checked={keyedSingleFilters[filterKey]}
              updateSingleFilterPayload={updateSingleFilterPayload}
            />
          ))}
        </ul>
      </ScrollableArea>
      <ButtonContainer>
        <CancelContainer>
          <StyledCancel bsStyle="secondary" onClick={() => toggleFilterPanel(false)}>Cancel</StyledCancel>
        </CancelContainer>
        <Button width="160px" fontSize="18px" onClick={handleApplyFilters}>Apply</Button>
      </ButtonContainer>
    </Wrapper>
  )
}

export default Container(FilterPanel)
