import React, { useEffect } from "react"
import EditJobIcon from "dumbComponents/RealtyV3/RARRecruitment/images/editjobs.svg"
import dynamic from "next/dynamic"
import { convertToHTML } from "services/Utils"
import {
  HeaderContainer,
  Header,
  Paragraph,
  Icon,
  RichTextEditorWrapper,
  ButtonGroup,
  ButtonOutlined,
  ButtonFilled,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"
let RichTextEditor = null

const CommissionSplit = ({
  header,
  commissionSplitEdit,
  editable,
  enableEdit,
  commissionSplitCopy,
  updateField,
  saveField,
  paragraph,
  isDummy = false,
  isLandingPage,
}) => {
  useEffect(() => {
    if (!isLandingPage) {
      RichTextEditor = dynamic(() => import("dumbComponents/common/UI/BuilderComponents/RichTextEditor"), { ssr: false })
    }
  }, [])
  return (
    <React.Fragment>
      <HeaderContainer>
        <Header fw="700" fs="24px" source="commission">
          {header}
        </Header>
        {!commissionSplitEdit && editable && (<Icon src={EditJobIcon} onClick={() => enableEdit("commissionSplit", true)} />)}
      </HeaderContainer>
      <Paragraph>
        {commissionSplitCopy && (
          <RichTextEditorWrapper readOnly={!commissionSplitEdit}>
            {commissionSplitEdit ? (
              <RichTextEditor readOnly={!commissionSplitEdit} saveRTF={value => updateField({ content: value }, "commissionSplit")} text={commissionSplitCopy} height="150px" />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: convertToHTML(commissionSplitCopy) }}></span>
            )}
          </RichTextEditorWrapper>
        )}
        {isDummy && (<>{paragraph}</>)}
      </Paragraph>
      {commissionSplitEdit && (
        <ButtonGroup>
          <ButtonOutlined text="Cancel" action={() => enableEdit("commissionSplit", false)} />
          <ButtonFilled text="Save changes" action={() => saveField("commissionSplit")} />
        </ButtonGroup>
      )}
    </React.Fragment>
  
  )
}

export default CommissionSplit
