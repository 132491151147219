import React from "react"
import {
  Form, Space, NewSelect,
} from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form
const { Option } = NewSelect
import styled from "styled-components"

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "FSBO",
    value: "fsbo",
  }, {
    label: "Mail",
    value: "mail",
  }, {
    label: "Networking",
    value: "networking",
  }, {
    label: "New Construction",
    value: "new_construction",
  }, {
    label: "Open House",
    value: "open_house",
  }, {
    label: "Other - Agency",
    value: "other_agency",
  }, {
    label: "Other - Builder",
    value: "other_builder",
  }, {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  }, {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  }, {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  }, {
    label: "Personal Transaction",
    value: "personal_transaction",
  }, {
    label: "Property Management",
    value: "property_management",
  }, {
    label: "Floor Call",
    value: "floor_call",
  }, {
    label: "Referral - Attorney",
    value: "referral_attorney",
  }, {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  }, {
    label: "Referral - Lendor",
    value: "referral_lendor",
  }, {
    label: "Referral - Other",
    value: "referral_other",
  }, {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  }, {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  }, {
    label: "Referral - Title",
    value: "referral_title",
  }, {
    label: "Sign Call",
    value: "sign_call",
  }, {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  }, {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  }, {
    label: "Social Profile - Other",
    value: "social_profile_other",
  }, {
    label: "Events - Other",
    value: "events_other",
  },
]


const CommonForm = ({
  formRef,
  getClientFiltersResponse,
}) => {
  const { data: clientFilterStatus } = getClientFiltersResponse || {}
  const { clientTransactionStatuses } = clientFilterStatus || []
  return (
    <Space style={{ display: "block", marginBottom: 8, gap: 40, width: "100%" }} align="baseline">
      <Item name="status" rules={[{ required: true, message: "Lead status is required" }]}
      label={<LabelComponent text="Lead Status*" />}>
        <NewSelect placeholder="Lead Status*">
          {Object.keys(clientTransactionStatuses).map((key, index) => (
            <Option value={clientTransactionStatuses[key]} key={key}>
              {clientFilterStatus[key]}
            </Option>
          ))}
        </NewSelect>
      </Item>
      <Item name="client_source" rules={[{ required: true, message: "Client source is required" }]}
      label={<LabelComponent text="Client Source*" />}>
        <NewSelect placeholder="Client Source*" options={CLIENT_SOURCE} />
      </Item>
    </Space>
  )
}

export default CommonForm
