import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  toggleRARFormOpenAction,
  isaPopUpToggleAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { app, realty } = state
  const {
    currentRARPlanSelected,
  } = realty
  const { isRARPopupOpen, isISAPopUpOpen } = app
  return {
    currentRARPlanSelected,
    isRARPopupOpen,
    isISAPopUpOpen,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleRARFormOpen: toggleRARFormOpenAction.call,
      toggleISAPopupOpen: isaPopUpToggleAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
