import React from "react"
import Modal from "@ui/Modal"
import styled from "styled-components"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import colors from "@colors"
import { ATC } from "@fonts"
import LoaderButton from "@ui/LoaderButton"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 40px 30px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const AlertModal = ({
  setCheckDetailsAdded,
  toClose,
}) => (
  <Modal
    show
    toClose={toClose}
    modalVersion={3}
    closeIcon={CloseIcon}
    showCloseButton
    width="600px"
    modalStyles={{
      marginTop: 0,
      padding: 0,
      boxShadow: "none",
    }}
    customDialogColor={colors.black}
    zIndex={1001}
    dialogStyles={{
      background: "#F9F9F7",
      borderRadius: "8px",
      minHeight: "280px",
      fontFamily: {ATC},
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: "32px",
      textAlign: "center",
      color: colors.black,
    }}
    iconStyles={{
      padding: "8px",
      borderRadius: "18px",
      fontSize: "10px",
      right: "20px",
      width: "40px",
      margin: "6px",
    }}
  >
    <Wrap>
      <Header>
        <h1>
          Alert!
        </h1>
      </Header>
      <ContentContainer>
        <Content>
          You are adding a card in the In-Contract column without any transaction details. Please add the transaction details to proceed.
        </Content>
      </ContentContainer>
      <ButtonWrap>
        <LoaderButton
          bsStyle="marineBLueFill"
          padding="16px"
          margin="0 5px"
          width="100%"
          borderRadius="50px"
          loaderOption={{
            color: "white",
          }}
          onClick={() => {
            setCheckDetailsAdded(false)
          }}
        >
          Go Back
        </LoaderButton>
      </ButtonWrap>
    </Wrap>
  </Modal>
)

export default AlertModal