import styled from "styled-components"
import colors from "@colors"

const Button = styled.button`
  background: none;
  padding: 30px 5px;
  margin: 0px;
  color: ${props => props.color || `${colors.primaryColor}`};
  text-decoration: underline;
  border: none;
  font-size: inherit;
  cursor: pointer;
  transition-duration: 0.2s;

  
  
  &:hover {
    color: #0c85bb;
  }
`

export default Button
