import React from "react"
import OffersV2 from "container/OffersV2" //connect sagas individually to components
import {
  AgentCardWrap,
  BuyerAgentInformationText,
  EditButton,
  DetailsArea,
  AgentName,
  AgentEmail,
  AgentPhoneNumber,
  AddtionalInfoOuterWrap,
  AdditionalInfoInnerWrap,
  AdditionInfoTitle,
  AdditionalInfoValue,
} from "./styles"

/**
 *Do not create styled-components here
 */

const AgentCard = ({
  editAgent,
  data,
  title,
}) => (
  <AgentCardWrap>
    <EditButton onClick={editAgent}>
      Edit
    </EditButton>
    <BuyerAgentInformationText>
      <p>{title || "Buyer's Agent Information"}</p>
    </BuyerAgentInformationText>
    <DetailsArea>
      <AgentName>
        <p>{`${data.agent_first_name} ${data.agent_last_name}`}</p>
      </AgentName>
      <AgentEmail>
        <p>{data.email}</p>
      </AgentEmail>
      <AgentPhoneNumber>
        <p>{data.phone}</p>
      </AgentPhoneNumber>
    </DetailsArea>
    <AddtionalInfoOuterWrap>
      <AdditionalInfoInnerWrap>
        <AdditionInfoTitle>BROKERAGE LICENSE NO.</AdditionInfoTitle>
        <AdditionalInfoValue>{data.brokerage_license_number}</AdditionalInfoValue>
      </AdditionalInfoInnerWrap>
      <AdditionalInfoInnerWrap>
        <AdditionInfoTitle>AGENT LICENSE NO.</AdditionInfoTitle>
        <AdditionalInfoValue>{data.agent_license_number}</AdditionalInfoValue>
      </AdditionalInfoInnerWrap>
      <AdditionalInfoInnerWrap noBorder>
        <AdditionInfoTitle>BROKERAGE ADDRESS</AdditionInfoTitle>
        <AdditionalInfoValue>{data.agent_brokerage_address}</AdditionalInfoValue>
      </AdditionalInfoInnerWrap>
    </AddtionalInfoOuterWrap>
  </AgentCardWrap>
)

export default OffersV2(AgentCard)
