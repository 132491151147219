import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from "react-image-crop"
import { findIndex } from "lodash"
import Box from "@ui/Box"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { convertBlobToBase64 } from "services/Utils"
import cropperStyles from "./reactCropperStyles"
import "react-image-crop/dist/ReactCrop.css"

import {
  Wrapper,
  Footer,
  Header,
  SaveButton,
  InputFile,
  DeleteButton,
  Separator,
  NoImageFound,
  ErrorText,
} from "./commonStyles"

const centerAspectCrop = (
  mediaWidth,
  mediaHeight,
  aspect,
) => centerCrop(
  makeAspectCrop(
    {
      unit: "%",
      width: 100,
    },
    aspect,
    mediaWidth,
    mediaHeight,
  ),
  mediaWidth,
  mediaHeight,
)

const ImageWrap = styled.div`
  height: 400px;
  display: flex;
  background: #B2B2B2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const StyledWrapper = styled(Wrapper)`
  ${cropperStyles}
  .ReactCrop__crop-selection {
    border-radius: 0px !important;
  }
`

const Error = styled(ErrorText)`
  position: absolute;
  bottom: 8px;
  background: #FFF;
  border-radius: 4px;
  z-index: 10;
  padding: 10px;
`

class CoverImageUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChanged: false,
      isLoadingImage: true,
      crop: {
        aspect: 48 / 12,
        width: 100,
        height: 200,
        x: 0,
        y: 0,
      },
    }
  }

  componentDidMount() {
    const { initialValues: { path } } = this.props
    if (path) {
      fetch(path)
        .then(res => res.blob("image/jpeg"))
        .then(convertBlobToBase64)
        .then((blob) => {
          this.setState({
            src: blob,
            isLoadingImage: false,
          })
        })
        .catch((err) => {
          this.setState({
            error: err,
            isLoadingImage: false,
          })
        })
    } else {
      this.setState({
        isLoadingImage: false,
      })
    }
  }

  imageRef = React.createRef()

  onSelectFile = (e) => {
    console.log(e)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        this.setState({
          src: reader.result.toString() || "",
        })
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state

    if (crop.height && crop.width && crop.aspect) {
      this.setState({
        crop: { ...centerAspectCrop(crop.height, crop.width, crop.aspect) },
      })
    } else {
      this.makeClientCrop(crop, pixelCrop)
    }
  }

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop)
  };

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const { croppedImageFile } = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg",
      )
      this.setState({ croppedImageFile, isChanged: true })
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const TO_RADIANS = Math.PI / 180
    const currentImage = this.imageRef.current
    const rotate = 0

    if (!ctx) {
      throw new Error("No 2d context")
    }

    const scaleX = currentImage ? currentImage.naturalWidth / currentImage.width : 0
    const scaleY = currentImage ? currentImage.naturalHeight / currentImage.height : 0

    canvas.width = Math.floor(pixelCrop.width * scaleX)
    canvas.height = Math.floor(pixelCrop.height * scaleY)

    ctx.imageSmoothingQuality = "low"

    const cropX = pixelCrop.x * scaleX
    const cropY = pixelCrop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = currentImage.naturalWidth / 2
    const centerY = currentImage.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    // ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)

    ctx.drawImage(
      currentImage,
      0,
      0,
      currentImage.naturalWidth,
      currentImage.naturalHeight,
      0,
      0,
      currentImage.naturalWidth,
      currentImage.naturalHeight,
    )

    return new Promise((resolve) => {
      canvas.toBlob((rawBlob) => {
        const blob = rawBlob
        blob.name = fileName
        root.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = root.URL.createObjectURL(blob)
        resolve({
          croppedImageUrl: this.fileUrl,
          croppedImageFile: blob,
        })
      }, "image/jpeg")
    })
  }

  handleSave = () => {
    const { uploadProfileImage, agentId } = this.props
    const { croppedImageFile, isChanged } = this.state
    if (!isChanged) {
      this.setState({
        error: "Please select a file before saving",
      })
    }
    if (croppedImageFile) {
      uploadProfileImage({
        agentId,
        file: croppedImageFile,
        type: "cover",
      })
    }
  }

  removeImage = () => {
    const { saveProfileUpdate, agentId, images } = this.props
    const imageIndex = findIndex(images, item => item.type === "cover")
    images[imageIndex].path = ""
    const payload = {
      isCloseModal: true,
      id: agentId,
      images,
    }
    this.setState({
      src: null,
      crop: null,
      croppedImageFile: null,
      isChanged: true,
      error: false,
    })
    saveProfileUpdate(payload)
  }

  render() {
    const {
      isSavingUpdates,
      initialValues: { path = null },
    } = this.props
    const {
      crop,
      src,
      isLoadingImage,
      error,
      croppedImageFile,
    } = this.state

    return (
      <StyledWrapper>
        <Header>
          Edit Cover photo
        </Header>
        {isLoadingImage ? (
          <Box p="20px" h="20" d="flex" jc="center" ai="center">
            <ThreeDotLoader />
            <h6>Loading...</h6>
          </Box>
        ) : (
          <ImageWrap>
            {src ? (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              >
                <img
                  ref={this.imageRef}
                  alt="Crop me"
                  src={src}
                  onLoad={this.onImageLoaded}
                />
              </ReactCrop>
            ) : (
              <NoImageFound />
            )}

            {error && (
              <Error style={{ marginTop: 30 }}>{error}</Error>
            )}
          </ImageWrap>
        )}
        <Footer d="flex" jc="flex-end">
          {path && (
            <DeleteButton onClick={this.removeImage}>
              Delete Photo
            </DeleteButton>
          )}
          <Separator />
          <InputFile type="file" onChange={this.onSelectFile} htmlFor="coverPictureUpload">
            {path ? "Change Image" : "Upload Image"}
            <input type="file" id="coverPictureUpload" />
          </InputFile>
          <SaveButton
            disabled={!croppedImageFile}
            width="max-content"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSave}
          >
            {!croppedImageFile ? "Crop Image" : "Save"}
          </SaveButton>
        </Footer>
      </StyledWrapper>
    )
  }
}

export default CoverImageUpload
