import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || "20px 15px"};
  width: max-content;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

  ${props => props.minWidth && `
    min-width: ${props.minWidth};
  `}

  @media(min-width: 1366px) {
    ${props => props.largeDesktopWidth && `
    min-width: ${props.largeDesktopWidth};
  `}
  }


  @media(max-width: 1099px) {
    width: ${props => props.mobileWidth || "100%"};
    min-width: ${props => props.mobileMinWidth || "unset"};

    ${props => props.mobileFlexJC && `
      justify-content: ${props.mobileFlexJC};
    `}

    ${props => props.mobileFlexAI && `
      align-items: ${props.mobileFlexAI};
    `}

    ${props => props.mobileHeight && `
      height: ${props.mobileHeight};
    `}
  }
`

const GradientBox = ({
  children,
  minWidth,
  padding,
  mobileMinWidth,
  mobileFlexJC,
  mobileWidth,
  largeDesktopWidth,
  customStyle,
  minMobileHeight,
  mobileHeight,
  mobileFlexAI,
}) => (
  <Wrap
    className="gradient-wrap-alt"
    minWidth={minWidth}
    padding={padding}
    mobileMinWidth={mobileMinWidth}
    mobileFlexJC={mobileFlexJC}
    mobileWidth={mobileWidth}
    minMobileHeight={minMobileHeight}
    largeDesktopWidth={largeDesktopWidth}
    mobileHeight={mobileHeight}
    mobileFlexAI={mobileFlexAI}
    style={{
      ...customStyle || {},
    }}
  >
    {children}
  </Wrap>
)

export default GradientBox
