import { Button } from "antd"
import styled from "styled-components"
import { MONTSERRAT } from "@fonts"
import colors from "@colors"

const NewStyledButton = styled(Button)`
  border: 1px solid ${colors.new_primary} !important;
  border-radius: 22px !important;
  color: ${colors.new_buttonText} !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: ${MONTSERRAT} !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  //causing issue with padding
  //padding: 10px 16px !important;
  background: ${colors.new_primary} !important;
  display: flex !important;
  align-items: center !important;
  min-height: 38px !important;
  gap: 10px !important;

  &:focus,
  &:hover {
    background: ${colors.new_primaryHover} !important;
    border: 1px solid ${colors.new_primaryHover} !important;
  }

  &:placeholder {
    color: ${colors.new_placeholder} !important;
  }
`

export { NewStyledButton as NewButton }

export default Button
