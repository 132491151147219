import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import CookiesStorage from "services/CookieStorage"
import RadiusFallbackImage from "images/icons/radius-icon.png"
import Button from "@ui/Button"
import { get } from "lodash"
import { TimeZone } from "services/Utils"
import EditIcon from "dumbComponents/RealtyV3/RARRecruitment/images/editjobs.svg"
import { DeleteOutlined } from "@ant-design/icons"
import {
  NotesHeaderWrap, NotesWrap, AgentNotesInfo,
  AgentTitle, AgentPic, AddButton, Title, Icon,
} from "../../../common"

const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`

const NotesDetails = ({
  data,
  toggleShowDeleteNoteModal,
  updateNote,
  currentClientId,
  key,
  getCtIdResponse,
}) => {
  const [isEditing, setEditing] = useState(false)
  const id = get(getCtIdResponse, "data.id")
  const agentId = CookiesStorage.load("agentId")
  const [note, setNote] = useState(data.note)

  const handleChange = (value) => {
    setNote(value)
  }

  const handleSubmit = () => {
    const payload = {
      note,
    }
    updateNote({
      payload,
      noteId: data.id,
      clientId: currentClientId,
      id,
    })
    setEditing(false)
  }

  const handleCancel = () => {
    setEditing(false)
    setNote(data.note)
  }

  const PSTTime = TimeZone(data.updated_at)
  const { localeTimeString, localeDateString } = PSTTime
  return (
    <NotesHeaderWrap key={key}>
      <NotesWrap gap={10}>
        <AgentPic>
          <img src={data.user_profile || RadiusFallbackImage} alt="Agent DP" />
        </AgentPic>
        <AgentNotesInfo
          width="100%"
        >
          <AgentTitle>
            {agentId === data.agent_id.toString() ? "Me" : data.agent_name}
          </AgentTitle>
          <small>
            {`${localeDateString} • ${localeTimeString}`}
          </small>
          {isEditing ? (
            <>
              <textarea
                style={{
                  width: "100%",
                  height: "84px",
                  background: "#FFFFFF",
                  border: "1px solid #C7C7C7",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={note}
                onChange={e => handleChange(e.target.value)}
              />
              <ButtonWrap>
                <Button
                  bsStyle="marineBLueFill"
                  style={{
                    borderRadius: "0px",
                    width: "100%",
                  }}
                  onClick={handleSubmit}
                  disabled={note === ""}
                >
                  Update
                </Button>
                <Button
                  bsStyle="marineBLueFill"
                  style={{
                    borderRadius: "0px",
                    width: "100%",
                  }}
                  onClick={handleCancel}
                  disabled={note === ""}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </>
          ) : (
            <p>
              {note}
            </p>
          )}
        </AgentNotesInfo>
        <>
          <AddButton
            onClick={() => {
              setEditing(true)
            }}
            style={{
              position: "absolute",
              right: 25,
            }}
          >
            <Icon src={EditIcon} height={15} />
          </AddButton>
          {agentId === data.agent_id.toString() && (
            <AddButton
              onClick={() => {
                toggleShowDeleteNoteModal(data.id)
              }}
              style={{
                position: "absolute",
                right: 0,
                top: 1,
              }}
            >
              <DeleteOutlined style={{ fontSize: "18px" }} />
            </AddButton>
          )}
        </>
      </NotesWrap>
    </NotesHeaderWrap>
  )
}

export default CRMV2Container(NotesDetails)
