import React from "react"
import styled from "styled-components"
import icons from "@icons"
import colors from "@colors"
import { reduxForm, Field } from "redux-form"
import { get } from "lodash"
import Modal from "@ui/Modal"
import { required, normalizePhone } from "services/formUtils"
import { PhoneInput } from "@ui/Form"
import TDL from "@ui/ThreeDotLoader"
import Button from "@ui/Button"

const PHONE_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

const Wrap = styled.div`
  position: relative;
  text-align: center;

  h1 {
    font-family: 'DM Sans', sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: ${colors.contentText};
    margin: 20px 0px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }
`

const IconWrap = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.successColor};
  font-size: 30px;
  color: #FFF;
`

const Input = styled.input`
  display: block;
  width: 80%;
  border: none;
  border-bottom: 1px solid ${colors.contentText};
  text-align: center;
  padding: 16px;
  margin-bottom: 20px;
  margin: 20px auto;
  outline: none;
`

const ErrorText = styled.div`
  color: ${colors.redText};
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
`

const PhoneForm = ({
  formSubmitError,
  formSubmitMessage,
  handleSubmit,
  isSaving,
}) => (
  <div style={{ marginTop: 30 }}>
    <Field
      name="phone"
      component={PhoneInput}
      validate={required}
      normalize={normalizePhone}
      label="Please enter phone number"
      inputStyle={{
        border: "none",
        borderBottom: `1px solid ${colors.tabDisabledColor}`,
        paddingLeft: 0,
      }}
      isMaterial
    />
    {
      formSubmitError ? (
        <ErrorText>{formSubmitError}</ErrorText>
      ) : (
        formSubmitMessage && <ErrorText>{formSubmitMessage}</ErrorText>
      )
    }
    <Button onClick={handleSubmit} disabled={isSaving}>
      {isSaving ? (
        <TDL color="#FFF" />
      ) : (
        "Submit"
      )}
    </Button>
  </div>
)

const PhoneInputWrapped = reduxForm({
  form: "PHONE_COLLECTION_FORM",
})(PhoneForm)

class PhoneCollectionModal extends React.Component {
  state = {
    phone: "",
  }

  handleSubmit = (values) => {
    const { phone } = values
    const { collectPhoneNumber } = this.props
    if (PHONE_REGEX.test(phone)) {
      collectPhoneNumber(phone)
    } else {
      this.setState({
        error: "Please enter valid phone number",
      })
    }
    //collectPhoneNumber(phone)
  }

  // handleClick = () => {
  //   const { collectPhoneNumber } = this.props
  //   const { phone } = this.state
  //   if (PHONE_REGEX.test(phone)) {
  //     collectPhoneNumber(phone)
  //   } else {
  //     this.setState({
  //       error: "Please enter valid phone number",
  //     })
  //   }
  // }

  render() {
    const {
      toClose,
      isSaving,
      errorMessage,
      showCloseButton = false,
    } = this.props
    const { error } = this.state
    const apiErrorMessage = get(errorMessage, "data.error.message")
    return (
      <Modal
        show
        toClose={toClose}
        showCloseButton={showCloseButton}
      >
        <Wrap>
          <IconWrap>
            <i className={icons.communicationCall}></i>
          </IconWrap>
          <h1>Hello!</h1>
          <p>
            We need your mobile number to keep you updated and contact you about your referral’s progress.
          </p>
          <PhoneInputWrapped
            onSubmit={this.handleSubmit}
            isSaving={isSaving}
            formSubmitMessage={apiErrorMessage}
            formSubmitError={error}
          />
        </Wrap>
      </Modal>
    )
  }
}

export default PhoneCollectionModal
