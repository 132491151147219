import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const Wrap = styled.div`  
  border-radius: 6px;
`

export const ToastWrapper = styled.div`
  display: flex;
  align-items
`

export const StyledText = styled.p`
  color: #292A37 !important;
  margin-bottom: 0;
`

export const StyledCloseIcon = styled.img`
  position: absolute;
  top: 15px;
  right: 8px;
  font-size: 14px;
`

export const StyledStatusIcon = styled.img`
  margin: 2px 6px;
  width: 16px;
  height: 16px;
`

export const RetryButtonWrap = styled.div`
  position: absolute;
  right: 8px;
  top: 19px
`

export const RetryButton = styled.button`
  border: none;
  color: #5A5FF2;
  background: none;
  font-family: ${INTER};
  font-size: 12px;
`

