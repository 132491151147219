import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as AppSelector from "container/App/selector"
import {
  requestUpdateUserObject,
  fetchUserInfo,
} from "container/App/actionCreators"
import {
  toggleUserSettingsErrorModal,
  syncGmail,
  deactivateGmail,
  requestUpdateDisplayEmail,
} from "./actionCreators"
import * as SettingsSelector from "./selector"
import * as SettingsActions from "./actions"

const mapStateToProps = state => (
  {
    userSettingErrorModal: SettingsSelector.getUserSettingErrorModalFromStore(state),
    gmailEmail: AppSelector.getGmailEmailFromStore(state),
    gmailRefreshToken: AppSelector.getGmailRefreshTokenFromStore(state),
    primaryEmail: AppSelector.getPrimaryEmailFromStore(state),
    phone: AppSelector.getPhoneFromStore(state),
    isCalSync: AppSelector.getIsCalSyncFromStore(state),
    isMailSync: AppSelector.getIsMailSyncFromStore(state),
    isPbookSync: AppSelector.getIsPbookSyncFromStore(state),
    isSyncingGmail: SettingsSelector.getIsSyncingGmailFromStore(state),
    isUpdatingDisplayEmail: SettingsSelector.getIsUpdatingDisplayEmailFromStore(state),
    isUpdatingPhone: SettingsSelector.getIsUpdatingPhoneFromStore(state),
    isFetchingUserObject: AppSelector.getIsFetchingUserFromStore(state),
    isTeamOwner: AppSelector.getIsUserATeamOwner(state),
    radiusPhoneNumberResponse: SettingsSelector.getRadiusPhoneNumber(state),
    savingMultiPhoneAdminResponse: SettingsSelector.getSavingMultiPhoneAdminResponse(state),
    formValues: {
      emails: SettingsSelector.getUserEmailsSelector(state),
      phones: SettingsSelector.getUserPhoneNumbersSelector(state),
    },
  }
)

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleUserSettingsErrorModal,
  saveMultipleEmailsAndPhone: SettingsActions.saveMultipleEmailsAndPhoneAction.request,
  fetchRadiusPhoneNumber: SettingsActions.fetchRadiusPhoneNumberAction.request,
  syncGmail,
  requestUpdateUserObject,
  deactivateGmail,
  requestUpdateDisplayEmail,
  fetchUserInfo,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
