import styled, { css } from "styled-components"

const Like = styled.div`
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-image: url("https://s3.amazonaws.com/cdn.agentdesks.com/images/Artboard-36.png");
  background-position: ${props => props.likedByMe ? "right" : "left"};
  background-repeat: no-repeat;
  background-size: 2900%;
  margin-left: ${props => props.ml};
  ${props => props.animating
    && css`
      animation: like-burst 0.8s steps(28) 1;
    `}

  @keyframes like-burst {
    from {
      background-position: left;
    }
    to {
      background-position: right;
    }
  }
`

export default Like
