import React, { useEffect, useState } from "react"
import styled from "styled-components"
// import colors from "@colors"
import { getArePopupsSupressed } from "shared/utils"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import FormUI from "./Forms"
import Banner from "./Banner"

const Wrap = styled.div`
  display: flex;
  background: red;
  justify-content: center;
  align-items: center;
`

const OnboardingOrientation = ({
  fetchUserOrientationInfo,
  fetchOrientationInfoResponse,
  setFormStage,
  setFormStageValue,
}) => {
  const arePopupsSupressed = getArePopupsSupressed("reportingPopup")
  const [finalResponse, setResponse] = useState()

  useEffect(() => {
    fetchUserOrientationInfo({
      isFirstCall: true,
    })
    setResponse(fetchOrientationInfoResponse)
  }, [])

  if (arePopupsSupressed) {
    return <></>
  }

  return (
    <Wrap>
      <FormUI formStage={setFormStageValue} setFormStage={setFormStage} />
    </Wrap>
  )
}

export default OnboardingContainer(OnboardingOrientation)
