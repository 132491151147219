import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as AppActions from "./actions"

const initState = {
  currentOccupation: "",
  cityFieldError: false,
  ...createDeltaReducer("occupationSubmitResponse"),
  ...createDeltaReducer("happinessSubmitResponse"),
  ...createDeltaReducer("setCurrentOnboardingStepActionResponse"),
  ...createDeltaReducer("triggerSlackNotificationAPIResponse"),
}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case AppActions.saveOccupationTypeAction.REQUEST:
    case AppActions.saveOccupationTypeAction.SUCCESS:
    case AppActions.saveOccupationTypeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.saveOccupationTypeAction, "occupationSubmitResponse"),
      }
    }

    case AppActions.handleHappinessRangeAction.REQUEST:
    case AppActions.handleHappinessRangeAction.SUCCESS:
    case AppActions.handleHappinessRangeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.handleHappinessRangeAction, "happinessSubmitResponse"),
      }
    }

    case AppActions.setCurrentOnboardingStepAction.REQUEST:
    case AppActions.setCurrentOnboardingStepAction.SUCCESS:
    case AppActions.setCurrentOnboardingStepAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.setCurrentOnboardingStepAction, "setCurrentOnboardingStepActionResponse"),
      }
    }

    case AppActions.triggerSlackNotificationAction.REQUEST:
    case AppActions.triggerSlackNotificationAction.FAILURE:
    case AppActions.triggerSlackNotificationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          AppActions.triggerSlackNotificationAction,
          "triggerSlackNotificationAPIResponse"),
      }
    }

    case AppActions.triggerErrorStateAction.type: {
      return {
        ...state,
        cityFieldError: action.data,
      }
    }

    default:
      return state
  }
}
