import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Container from "container/OnboardingSetup"
import { get } from "lodash"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import CookiesStorage from "services/CookieStorage"
import { useWindowSize } from "services/Utils"
import queryString from "query-string"
import Loader from "@ui/Loader"
import GetStartedImage from "dumbComponents/Auth/SignupV2/Images/GetStartedImage.svg"
import { ATC, INTER } from "@fonts"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import Navbar from "../Navbar"

const CardWrap = styled.div`
  border-radius: 9px;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);
  padding: 25px;
  width: 406px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`

const CardTitle = styled.h1`
  color: var(--color-tertiary, #1C1C1F);
  margin: 0;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const CardDescription = styled.p`
  color: var(--color-primary, #5A5FF2);
  margin: 0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
`

const TeamMemberInvite = ({
  history,
  verifyToken,
  verifyEmailTokenResponse,
  getDisplayScreen,
  getDisplayScreenResponse,
  sendVerifyLink,
  sendVerfyLinkResponse,
}) => {
  const { width } = useWindowSize()
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery || {}
  useEffect(() => {
    const payload = {
      isRequestForBrokerageApp: true,
      authcode,
    }
    verifyToken({
      payload,
      // callback: (secureId, email) => getDisplayScreen({ secureId, email, history }),
    })
  }, [])
  const isFetching = get(verifyEmailTokenResponse, "isFetching")
  const firstname = get(verifyEmailTokenResponse, "data.user.firstname")
  const fetchingDisaply = get(getDisplayScreenResponse, "isFetching")
  const teamLeadName = get(verifyEmailTokenResponse, "data.user.teamInfo.teamLeadName")
  const teamName = get(verifyEmailTokenResponse, "data.user.teamInfo.teamName")
  const email = get(verifyEmailTokenResponse, "data.user.email")
  const fetchingMailResponse = get(sendVerfyLinkResponse, "data.isFetching")
  const secureId = get(verifyEmailTokenResponse, "data.secure_id")

  console.log(verifyEmailTokenResponse, "here", secureId)
  return (
    <>
      <Navbar />
      <Wrap>
        {(isFetching || fetchingDisaply) ? <Loader /> : (
          <ContentWrap>
            <RightWrap>
              <WelcomeContentWrap>
                <WelcomeTitle width="550px">
                  {`You've been invited by ${teamLeadName} to join ${teamName}`}
                </WelcomeTitle>
                <WelcomeDescription width="320px">
                  {`Hi ${firstname},`}
                </WelcomeDescription>
                <WelcomeDescription width="485px">
                  We're delighted to guide you through a simple, step-by-step process to get you started:
                </WelcomeDescription>
                <WelcomeDescription width="485px">
                  Please ensure you have access to your email throughout this process for verification purposes.
                </WelcomeDescription>
              </WelcomeContentWrap>
              <CardWrap>
                <CardTitle>
                  {`Your registered email address is ${email}`}
                </CardTitle>
                <CardDescription
                  onClick={() => history.push("/office/ica-onboarding/update-email")}
                >
                  Not the right email address?
                </CardDescription>
              </CardWrap>
              <ICAButtons
                style={{
                  width: "200px",
                }}
                onClick={() => {
                  if (width < 767) {
                    history.push("/signup/download-app")
                  } else {
                    getDisplayScreen({ secureId, email, history })
                  }
                }}
              >
                {fetchingMailResponse ? <ThreeDotLoader color="#FFFFFF" /> : "Accept Invitation"}
              </ICAButtons>
            </RightWrap>
            <LeftWrap justifyContent="flex-end">
              <LeftImage src={GetStartedImage} />
            </LeftWrap>
          </ContentWrap>
        )}
      </Wrap>
    </>
  )
}

export default withRouter(Container(TeamMemberInvite))
