import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import * as NavbarActions from "./actions"
import cookie from "services/CookieStorage"

const NOTIFICATIONVIEWS = "notificationsViewedResponse"

const initState = {
  isNotificationFetched: false,
  isStartNavbarSpotlightFlow: false,
  isOpenPressReleaseBanner: false,
  ...createDeltaReducer(NOTIFICATIONVIEWS),
}

export default function navbarReducer(state = initState, action) {
  switch (action.type) {
    // Notification
    case NavbarActions.fetchNotificationAction.REQUEST: {
      return {
        ...state,
        isFetchingNotifications: true,
      }
    }
    case NavbarActions.fetchNotificationAction.FAILURE:
    case NavbarActions.fetchNotificationAction.SUCCESS: {
      const { notification } = action.data
      return {
        ...state,
        notifications: notification,
        isFetchingNotifications: false,
      }
    }

    case NavbarActions.notificationViewedAction.REQUEST:
    case NavbarActions.notificationViewedAction.SUCCESS:
    case NavbarActions.notificationViewedAction.CLEAR:
    case NavbarActions.notificationViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.notificationViewedAction, NOTIFICATIONVIEWS),
      }
    }

    // Unread Notification
    case NavbarActions.fetchNotificationUnreadCountAction.REQUEST: {
      return {
        ...state,
        isFetchingUnreadNotification: true,
      }
    }
    case NavbarActions.fetchNotificationUnreadCountAction.FAILURE:
    case NavbarActions.fetchNotificationUnreadCountAction.SUCCESS: {
      const { unread } = action.data
      return {
        ...state,
        unreadCount: unread,
        isFetchingUnreadNotification: false,
      }
    }

    case NavbarActions.startTabsSpotlightFlowAction.type: {
      return {
        ...state,
        isStartNavbarSpotlightFlow: true,
      }
    }

    case NavbarActions.togglePressReleaseBannerAction.type: {
      const { isOpen } = action.data
      return {
        ...state,
        isOpenPressReleaseBanner: isOpen,
      }
    }

    default:
      return { ...state }
  }
}
