import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Header = styled.div`
  font-style: normal;
  text-align: left;
  display: flex;
  align-items: self-end;

  h1 {  
    color: var(--Color-Gray-400, #41446A);
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

export const Content = styled.p`
  color: var(--Dark-Gray-Dark-Gray-2, #252C32);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.084px;
  text-align: left;
`

export const ContentContainer = styled.div`
  padding: 0px 0px 0px 0px;
`

export const ParaWrap = styled.div`
  padding: ${props => props.padding || "20px"};
`

export const AnimationWrap = styled.div`
  position: relative;
  // top: 14px;

  img {
    width: 49px;
    height: 49px;
  }
`