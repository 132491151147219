import React from "react"
import styled from "styled-components"
import OnboardingContainer from "container/OnboardingSetup"
import CookiesStorage from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  MiddleWrap,
  StepsWrap,
  StepsContentWrap,
  StepTitle,
  StepDescription,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  GetStarted,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import {
  headphones,
  message,
  icaSignedImage,
} from "../../consts"
import { ATC, INTER } from "@fonts"

const Success = ({
  history,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap width="40%">
          <LeftImage src={icaSignedImage} top="60px" />
        </LeftWrap>
        <RightWrap width="60%" padding="0px 133px 0px 250px">
          <WelcomeContentWrap>
            <WelcomeTitle>
              Congratulations!
            </WelcomeTitle>
            <WelcomeDescription>
              You've signed your ICA! Once it has been signed by all the parties, here's what to look out for:
            </WelcomeDescription>
          </WelcomeContentWrap>
          <MiddleWrap>
            <StepsWrap>
              <img src={message} alt="hello" height={46} width={46} />
              <StepsContentWrap>
                <StepDescription>
                  A copy of your ica
                </StepDescription>
                <StepTitle
                  fontFamily={INTER}
                  fontSize="11px"
                >
                  Sent to your email for your records.
                </StepTitle>
              </StepsContentWrap>
            </StepsWrap>
            <StepsWrap>
              <img src={headphones} alt="hello" height={46} width={46} />
              <StepsContentWrap>
                <StepDescription>
                  Contact from Radius
                </StepDescription>
                <StepTitle
                  width="274px"
                  fontFamily={INTER}
                  fontSize="11px"
                >
                  Your Radius account manager will help you get started on this exciting journey.
                </StepTitle>
              </StepsContentWrap>
            </StepsWrap>
          </MiddleWrap>
          <GetStartedWrap>
            <GetStarted>
              Welcome to Radius
            </GetStarted>
            <ICAButtons
              onClick={() => history.push("/signup/download-app")}
              // onClick={() => getDisplayScreen({ secureId, email, history })}
            >
              Explore Radius Brokerage and Community Tools
            </ICAButtons>
          </GetStartedWrap>
        </RightWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(OnboardingContainer(Success))
