import React, { useState, useRef, useEffect } from "react"
import OffersV2 from "container/OffersV2/index"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  EnvelopesWrap,
} from "./style"
import OfferPackageCard from "./OfferPackageCard"

const OfferPackage = ({
  offerPackages,
}) => {
  console.log(offerPackages)
  const {
    envelopes,
    date,
    attachments,
  } = offerPackages || {}
  return (
    <EnvelopesWrap>
      <SectionWrap>
        <SectionTitle>
          <p>Sent Offer Package Details</p>
        </SectionTitle>
        {envelopes && envelopes.map(item => (
          <OfferPackageCard
            // docs={item.docs}
            id={item.envelopeId}
            title={item.title}
            date={date}
          />
        ))}

        {attachments && attachments.map(item => (
          <OfferPackageCard
            // docs={item.docs}
            id={item.envelopeId}
            title={item.title}
            date={date}
          />
        ))}
        
      </SectionWrap>
    </EnvelopesWrap>
  )
}
export default OffersV2(OfferPackage)
