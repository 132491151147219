import React, { useState } from "react"
import Select from "react-select"
import styled from "styled-components"
import { isArray } from "lodash"
import Box from "@ui/Box"
import ArrowDown from "images/home/arrow-down.svg"
import { isObject } from "util"
import { ATC, INTER } from "@fonts"

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
  position: relative;

  ${props => props.isLandingPage && `
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
    margin-top: 10px;

    span img {
      width: 18px;
      height: 18px;
    }
  `}
`

const ArrowLabel = styled.span`
  font-size: 12px;
  position: relative;

  ${props => props.isLandingPage && `
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
    margin-top: 10px;

    span img {
      width: 13px;
      height: 18px;
      filter: contrast(0) brightness(1.5);
      position: relative;
      right: 7px;
      top: 3px;
    }
  `}
`

const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #d4d4d4;
  font-weight: 300;
  margin: 5px 0px;
  line-height: 1.5;
  display: inline-block;
  > div > div > div {
    border: 1px solid #ED1616 !important;
  }
`

const Required = styled.p`
  display: inline-block;
  margin-left: 3px;
  color: #E34C4C;
  margin-bottom: 0px;
`

const checkForValue = (val = "", isMulti) => {
  if (!val) {
    return null
  }
  if (isMulti) {
    return val
  }
  if (isArray(val)) {
    return { value: val[0], label: val[0] }
  }
  if (!isObject(val)) {
    return { value: val, label: val }
  }
  return val
}

const customStyles = {
  singleValue: (provided) => {
    const overflow = "initial"
    const fontSize = "16px"
    return { ...provided, overflow, fontSize }
  },
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
    }
  },
}

const HTMLSelect = styled.select`
  width: 100%;
  padding-left: 10px;
  border-radius: 8px;
  font-family: ${INTER};
  border: 1px solid ${props => props.errored ? "#ff4b4b" : "#A5A8FF"};
  font-size: 12px;
  letter-spacing: 0.5px;
  height: 42px;

  & {
    -webkit-appearance: none;
    appearance: none;

    ${props => !props.isSelected && `
      color: #ccc;
    `}

    ${props => props.isSelected && `
      color: black;
    `}
  }
`

const HTMLOption = styled.option`
`

const HTMLOptionGroup = styled.optgroup``

const RenderSelectHTML = (field) => {
  const [isSelected, setSelected] = useState(false)

  const {
    input: { onFocus } = {},
    input,
    options,
    config = {},
    placeholder,
    meta,
    label,
    defaultValue,
    width,
    components,
    selectStyles,
    labelStyles = {},
    errorStyles,
    disabled,
    onBlur,
    placeholderStyled,
    isRequiredField,
    isLandingPage,
    optionType,
  } = field
  return (
    <Box onFocus={onFocus} w={width} position="relative">
      {label && (
        <Label style={labelStyles}>
          {label}
          {isRequiredField && (<Required>*</Required>)}
        </Label>
      )}
      <HTMLSelect
        {...config}
        {...field}
        className="form-control form-item__element--select"
        errored={meta.touched && meta.error}
        isSelected={isSelected}
        onChange={(e) => {
          const selectedValue = e.target.value
          if (selectedValue && selectedValue.length > 0) {
            input.onChange(e)
            setSelected(true)
          }
        }}
        onBlur={() => {
          if (onBlur) onBlur()
          input.onBlur(input.value)
        }}
      >
        <HTMLOption
          style={{
            color: "#ccc !important",
          }}
          value=""
          disabled
          selected
        >
          {placeholder}
        </HTMLOption>
        {optionType === "1" && (
          <>
            {options && options.map(item => (
              <HTMLOptionGroup label={item.label}>
                <>
                  {item.options && item.options.map(keys => (
                    <HTMLOption value={keys.label}>{keys.label}</HTMLOption>
                  ))}
                </>
              </HTMLOptionGroup>
            ))}
          </>
        )}
        {optionType === "2" && (
          <>
            {options && options.map(item => (
              <HTMLOption value={item.label}>{item.label}</HTMLOption>
            ))}
          </>
        )}
      </HTMLSelect>
      {meta.touched && meta.error ? (
        <ErrorLabel isLandingPage={isLandingPage}>
          {isLandingPage ? (
            <>
              <span>
                <img src={`${CLOUDFRONT}/AlertIcon.png`} alt="error" />
              </span>
            </>
          ) : meta.error}
        </ErrorLabel>
      ) : (
        <ArrowLabel isLandingPage={isLandingPage}>
          {isLandingPage ? (
            <>
              <span>
                <img src={ArrowDown} alt="error" />
              </span>
            </>
          ) : meta.error}
        </ArrowLabel>
      )}
    </Box>
  )
}

export default RenderSelectHTML
