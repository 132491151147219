import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import ProductMarketplace from "../Icons/NewIcons/ProductMarketplace"

const ProductsTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    isActive={routeArray[1] === "products-and-services" || routeArray[1] === "products"}
  >
    <div className="icon-block-new">
      <ProductMarketplace />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? "/realty/products-and-services" : "/realty/promos?type=products"}
        isActive={routeArray[1] === "products-and-services" || routeArray[1] === "products"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_productAndServicesRAR_web")
          }
        }}
      >
        Product Marketplace
      </NavLink>
    </div>
  </InnerTab>
)

export default ProductsTab
