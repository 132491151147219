import React from "react"
import styled, { css } from "styled-components"
import { get } from "lodash"
import { format } from "date-fns"
import { reduxForm, Field } from "redux-form"
import TimePicker from "rc-time-picker"
import DayPickerInput from "react-day-picker/DayPickerInput"
import {
  Input,
  Select,
  Counter,
  RadioGroup,
  Textarea,
  LocationAutoComplete,
} from "@ui/Form"
import { isBrowser } from "services/Utils"
import { commaSeperatedNumberUS, required, formatAmount } from "services/formUtils"
import Box from "@ui/Box"
import ImageCarousel from "@ui/Feeds/ImageCarousel"
import GoogleAutoComplete from "@ui/GoogleAutoComplete"
import {
  BlankButton,
  Footer,
  SaveButton,
} from "./commonStyles"

const Wrapper = styled.form`
  margin: 20px;
`

const FieldRow = styled.div`
  margin-top: 20px;
  position: relative;
`

const H5 = styled.h5`
  color: #354052;
  font-size: 16px;

  ${props => props.isLite && css`
    font-size: 14px;
  `}
`
const AdvancedOptions = styled.div`
  padding-bottom: 20px;
  .rc-time-picker,
  .DayPickerInput {
    input {
      border-radius: 4px;
      border: 1px solid #eaeaea;
      padding: 8px;
      font-size: 16px;
      height: 36px;
    }
  }
`

// const GroupWrap = styled.div`
//   display: flex;
//   align-items: center;

//   img {
//     width: 38px;
//     height: 38px;
//     border-radius: 50%;
//   }

//   p {
//     font-size: 14px;
//     margin: 0px 0px 0px 10px;
//   }
// `

// const InitialFormValue = {
//   no_of_bed_room: 1,
//   no_of_bath_room: 1,
//   rent_resale: "resale",
//   area_coverage: "Sq. Ft",
//   property_type: "",
//   property_status: "",
//   sqft_price: "",
//   address: "",
//   property_name: "",
//   features: "",
// }

class AddListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowMore: false,
    }
  }

  componentDidMount() {
    const {
      getPropertyTypes,
      getPropertyStatus,
      initialize,
      editPropertyDetails,
    } = this.props
    getPropertyTypes()
    getPropertyStatus()

    initialize({
      ...editPropertyDetails,
      rent_resale: "resale",
    })
  }

  toggleShowMore = () => {
    const { isShowMore } = this.state
    this.setState({
      isShowMore: !isShowMore,
    })
  }

  handleAddressSelect = (address) => {
    const { change } = this.props
    this.setState({
      address,
    }, () => {
      const {
        lat,
        lng,
        administrative_area_level_1: {
          long_name: state = "",
        } = {},
        country: {
          long_name: country = "",
        } = {},
      } = address
      change("address", address.address)
      change("state", state)
      change("latitude", lat)
      change("longitude", lng)
      change("country", country)
    })
  }

  handleImageUpload = (files) => {
    const { uploadPropertyImages, agentId } = this.props
    uploadPropertyImages({ files, agentId })
  }

  render() {
    const {
      address,
      isShowMore,
    } = this.state
    const {
      preFetchedStatus,
      preFetchedTypes,
      change,
      eventDate,
      isUploadingPropertyImages,
      propertyImages = [],
      handleSubmit,
      isSavingUpdates,
      dbPropertyImageList = [],
    } = this.props
    console.log(propertyImages)
    const imageSet = [...dbPropertyImageList, ...propertyImages]
    console.log(imageSet)
    return (
      <Wrapper onSubmit={handleSubmit}>
        <Box mb="30">
          <ImageCarousel
            handleFileUpload={this.handleImageUpload}
            isUploadImage
            isUploadingProgress={isUploadingPropertyImages}
            images={imageSet || []}
            isShowScroller={false}
          />
        </Box>
        <Field
          component={LocationAutoComplete}
          name="address"
          id="cities"
          types={["address"]}
          location={(addressVal) => { this.handleAddressSelect(addressVal) }}
          placeholder={address && get(address[0], "full_address.street_address", "Search by city")}
          loader={false}
          validate={[required]}
        />
        <FieldRow style={{ marginTop: 20 }}>
          <Field
            name="street_address"
            component={Input}
            label="Apartment, unit or suite number"
            validate={[required]}
          />
        </FieldRow>
        <FieldRow style={{ marginTop: 30 }}>
          <Field
            name="resale_price"
            component={Input}
            label="Estimated price"
            //normalize={commaSeperatedNumberUS}
            validate={[required]}
            format={formatAmount}
            type="text"
            optional
            optionalText="$"
          />
        </FieldRow>
        <FieldRow style={{ marginTop: 30 }}>
          <Box d="flex" mt="20px">
            <Box flex="1">
              <Field
                name="no_of_bed_room"
                component={Counter}
                label="Bed"
              />
            </Box>
            <Box flex="1">
              <Field
                name="no_of_bath_room"
                component={Counter}
                label="Bath"
              />
            </Box>
          </Box>
        </FieldRow>
        <FieldRow style={{ marginTop: 20 }}>
          <Field
            name="covered_area"
            component={Input}
            label="Size"
            type="number"
            //normalize={commaSeperatedNumberUS}
            validate={[required]}
            optional
            optionalText="Ex: Sq. ft"
          />
          <div style={{ marginTop: 10 }}>
            <Field
              name="area_coverage"
              component={RadioGroup}
              validate={[required]}
              options={[
                { label: "Sq. Ft", value: "Sq.ft" },
                { label: "Sq. M", value: "Sq.mt" },
                { label: "Acres", value: "Acres" },
              ]}
              label=""
            />
          </div>
        </FieldRow>
        <FieldRow style={{ marginTop: 20 }}>
          <Field
            name="features"
            placeholder="Description of property"
            cols={10}
            component={Textarea}
            label=""
            charLimit={1000}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="property_type"
            label="Select property type"
            placeholder="Select property type"
            validate={[required]}
            component={Select}
            options={preFetchedTypes}
            config={{
              isSearchable: false,
            }}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="property_status"
            label="Select property status"
            placeholder="Select property status"
            component={Select}
            validate={[required]}
            options={preFetchedStatus}
            config={{
              isSearchable: false,
            }}
          />
        </FieldRow>
        <BlankButton
          type="button"
          style={{ marginTop: 30 }}
          onClick={this.toggleShowMore}
        >
          {isShowMore ? "Show less..." : "Show more..."}
        </BlankButton>

        { isShowMore && (
          <AdvancedOptions>
            <FieldRow>
              <H5>Schedule an open house</H5>
              <Box d="flex">
                <Box flex="1">
                  <H5 isLite>Start Date</H5>
                  <DayPickerInput
                    style={{ marginBottom: 20 }}
                    formatDate={format}
                    format="MM-DD-YYYY"
                    modifiers={{ disabled: new Date() }}
                    placeholder={`${format(new Date(), "MM/DD/YYYY")}`}
                    selectedDay={eventDate}
                    onDayChange={(date) => {
                      change("start_date", date)
                    }}
                  />
                  <Box mt="20">
                    <TimePicker
                      use12Hours
                      placeholder="Start time"
                      format="h:mm a"
                      onChange={(t) => {
                        const time = t.toDate()
                        change("start_time", `${time.getHours()}-${time.getMinutes()}`)
                      }}
                      showSecond={false}
                    />
                  </Box>
                </Box>
                <Box flex="1">
                  <H5 isLite>End Date</H5>
                  <DayPickerInput
                    style={{ marginBottom: 20 }}
                    formatDate={format}
                    format="MM-DD-YYYY"
                    placeholder={`${format(new Date(), "MM/DD/YYYY")}`}
                    selectedDay={eventDate}
                    onDayChange={(date) => {
                      change("end_date", date)
                    }}
                  />
                  <Box mt="20">
                    <TimePicker
                      use12Hours
                      placeholder="End time"
                      format="h:mm a"
                      onChange={(t) => {
                        const time = t.toDate()
                        change("end_time", `${time.getHours()}-${time.getMinutes()}`)
                      }}
                      showSecond={false}
                    />
                  </Box>
                </Box>
              </Box>
            </FieldRow>
            {/* { false && (
              <div>
                <FieldRow>
                  <H5>Share with groups</H5>
                  <Field
                    label="Post to all groups"
                    component={Toggle}
                    name="post_to_all_group"
                  />
                </FieldRow>
                <FieldRow style={{ marginTop: 40 }}>
                  <Field
                    component={Toggle}
                    name="some_1"
                    labelComponent={(
                      <GroupWrap style={{ flex: 1 }}>
                        <img src="https://griffonagedotcom.files.wordpress.com/2016/07/profile-modern-2e.jpg" alt="" />
                        <p>Group Name</p>
                      </GroupWrap>
                    )}
                  />
                </FieldRow>
              </div>
            ) } */}
          </AdvancedOptions>
        )}
        <Footer d="flex" jc="flex-end">
          <SaveButton
            width="100px"
            isShowLoader={isSavingUpdates}
            type="submit"
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "ADD_LISTING_FORM",
})(AddListing)

