import styled from "styled-components"
import { ATC } from "@fonts"

export const SubHeader = styled.h2`
  font-family: ${ATC};
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

  @media(min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(min-width: 1366px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
`
