/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef } from "react"
import oldOffersContainer from "container/TechAssets"
import root from "window-or-global"
import queryString from "query-string"
import container from "container/OffersV2"
import CRMV2Container from "container/CRM"
import { withRouter } from "react-router-dom"
import ComplianceSection from "dumbComponents/Compliance"
import {
  StatusChange,
  OfferConversionToContract,
} from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals"
// import { useNavigate } from "react-router-dom";
import Listings from "container/Listings";
import ContractDetails from "dumbComponents/common/ContractDetailsForm"
import CRMModal from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals/CRMModal"
import CoverSheet from "./CoverSheet"
import { ShimmerBox } from "shared/styles/animation"
import DocusignDocs from "./DocusignDocs"
import {
  OffersWrap,
  MenuWrap,
  FormAndFunctionWrap,
} from "./commonStyles"
import {
  Tabs,
  HeaderSectionCommon,
  MLSModal,
  CreateEnvelopeModal,
  Shimmer,
  DocumentShimmer,
  VoidAndRestart
} from "./components"
import {
  BuyerInformation,
  PropertyInformation,
  DealTerms,
} from "./CoverSheet/Components"
import {
  Templates,
  Envelopes,
  OffersPackage,
} from "./DocusignDocs/Components"
import {
  COVERSHEET,
  DOCUMENTS,
  BUYER_AGENT_INFORMATION,
  PROPERTY_INFORMATION,
  DEAL_TERMS,
  TEMPLATES,
  ACTIVE,
  OFFER_PACKAGE,
  BUYER_INFORMATION_STEP,
  PROPERTY_INFORMATION_STEP,
  DEAL_TERMS_STEP,
  CONTRACT_DETAILS,
  COMPLIANCE,
} from "./consts"
import SendOffer from "./DocusignDocs/Components/Envelopes/SendOffer/index"
import OfferStatus from "../CRMV2/CRMDashboard/Components/DrawerContent/Tabs/Offers/OfferStatus"
import ErrorsList from "./CoverSheet/Components/ErrorsList"

const STEPS = [
  {
    value: "cover_sheet",
    label: "Create an offer",
  },
  {
    value: "documents",
    label: "Offer Package",
  },
  {
    value: "contract_details",
    label: "Contract Details",
  },
  {
    value: "compliance",
    label: "Compliance",
  },
]

const OffersV2 = ({
  currentActiveTab,
  currentSideNavTab,
  toggleBasicOfferDetailsModal,
  isBasicOfferDetailsModalOpen,
  toggleActiveTab,
  isCreateEnvelopeModalOpen,
  toggleCreateEnvelopeModal,
  fetchActiveEnvelopesFiles,
  fetchActiveEnvelopesFilesResponse,
  getOfferStatusMappings,
  getOfferPackage,
  getOfferPackageResponse,
  fetchValidateCoverSheet,
  /**OLD OFFERS */
  getDealDetails,
  dealToView,
  getCurrentDealDetailsResponse,
  getOfferDocuments,
  getOfferDocumentsResponse,
  getOfferStatus,
  location,
  history,
  fetchPropertyInformation,
  getClientTransactionsById,
  fetchDealTermsInformation,
  setEditingDealTeamMemberId,
  fetchPropertyInformationResponse,
  propertyInformationObject,
  toggleDocumentTab,
  getOfferStatusResponse,
  fetchEnvelopeListings,
  isOfferToContractRedirectionModalVisible,
  toggleOfferToContractConversion,
  isStatusChangeModalOpen,
  toggleStatusChangeModalOffer,
  editingDealTeamMemberId,
}) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  Object.keys(parsedQuery).forEach((key) => {
    if (parsedQuery[key] === "null") {
      parsedQuery[key] = null
    }
  })
  const splitUrl = location?.pathname?.split("/") || ""
  const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
  const id = dealToView || extractedId
  const {
    contract_phase,
  } = parsedQuery || {}
  const {
    isFetching: fetchingDealDetails,
  } = getCurrentDealDetailsResponse || {}

  const {
    isFetching: fetchingDocuments,
  } = getOfferDocumentsResponse || {}

  const {
    isFetching: fetchingPropertyInformation,
  } = fetchPropertyInformationResponse || {}

  const {
    data: currentOfferStatus,
    isFetching: fetchingStatus
  } = getOfferStatusResponse || {}

  const {
    offerStatus: current_offer_status,
  } = currentOfferStatus || {}

  const getCurrentDealId = () => {
    const splitUrl = location?.pathname?.split("/") || ""
    const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
    if (isNaN(extractedId)) {
      return ""
    }
    return extractedId
  }

  const getTransactionId = () => {
    if (!parsedQuery || !parsedQuery.transaction_id) return ""
    return parsedQuery.transaction_id
  }

  // const currentActiveTab = "documents";

  useEffect(() => {
    //get the ID and perform the necessary actions
    const {
      transaction_id,
      client_id,
      agent_id,
      currentActiveTab: activeTabFromUrl,
    } = parsedQuery || {}

    setEditingDealTeamMemberId(agent_id)

    if (isNaN(id) && (transaction_id || client_id)) {
      toggleBasicOfferDetailsModal(true)
    }

    const step = BUYER_INFORMATION_STEP
    if (!Number.isNaN(parseInt(id, 10))) {
      getDealDetails({
        id,
        step,
      })
      fetchPropertyInformation({
        id,
        current_offer_step: PROPERTY_INFORMATION_STEP,
      })
      fetchDealTermsInformation({
        id,
        current_offer_step: DEAL_TERMS_STEP,
      })


      // if (!contract_phase) {
      //   getOfferDocuments({
      //     payload: {
      //       offerId: id,
      //     },
      //   })
      // } else if (contract_phase) {
      //   toggleActiveTab("contract_details")
      // }

      getOfferStatus({ id })
    }
    // if (dealToView) {
    //   console.log("dealtoView", dealToView)
    //   fetchValidateCoverSheet({
    //     id: dealToView,
    //   })
    // }
    getOfferStatusMappings()
    if (transaction_id) {
      getClientTransactionsById({ transactionId: transaction_id })
    }

    if (activeTabFromUrl && id && !fetchingDocuments) {
      toggleActiveTab(activeTabFromUrl)
      appendCurrentActiveTabToUrl(activeTabFromUrl)
    }

    return () => {
      const queryParams = new URLSearchParams(location.search)

      if (queryParams.has("currentActiveTab")) {
        queryParams.delete("currentActiveTab")
        history.replace({
          search: "",
        })
      }
    }
  }, [])

  const queryParams = new URLSearchParams(location.search)
  const notificationsLocation = queryParams.has("currentActiveTab") ? queryParams.get("currentActiveTab") : "";

  useEffect(() => {
    if(contract_phase != "true"){
      if(queryParams.has("currentActiveTab")){
        toggleActiveTab(notificationsLocation)
        notificationsLocation === "documents" && localStorage.clear("from_notifs")
        toggleDocumentTab("recommended_docs") 
      }
      else if (current_offer_status && (current_offer_status === "new_offer" || current_offer_status === "pending_signatures" || current_offer_status === "completed" || current_offer_status === "submitted")) {
        toggleActiveTab("documents")
        toggleDocumentTab("recommended_docs")
      } 
      else if(current_offer_status && current_offer_status === "offer_accepted" && contract_phase === "submitted"){
        toggleActiveTab("compliance")
        toggleDocumentTab("recommended_docs")
      }
      else if (current_offer_status && current_offer_status === "offer_accepted") {
        toggleActiveTab("contract_details")
      }
      else {
        toggleActiveTab("cover_sheet")
        toggleDocumentTab("")
      }
    }
    // prevLocation.current = locationRouterDom;
  }, [current_offer_status])

  useEffect(() => {
    if(currentActiveTab === "compliance" && dealToView){
      fetchEnvelopeListings({
        type: "offers",
        offerId: dealToView,
      })
    }

  }, [currentActiveTab])
  

  useEffect(() => {
    const current = `${currentActiveTab}_${currentSideNavTab}`
    const id = dealToView
    if (!fetchingDealDetails && id) {
      switch (current) {
        case `${COVERSHEET}_${BUYER_AGENT_INFORMATION}`:
          getDealDetails({
            id,
            step: BUYER_INFORMATION_STEP,
          })
          break
        case `${COVERSHEET}_${PROPERTY_INFORMATION}`:
          getDealDetails({
            id,
            step: PROPERTY_INFORMATION_STEP,
          })
          break
        case `${COVERSHEET}_${DEAL_TERMS}`:
          getDealDetails({
            id,
            step: DEAL_TERMS_STEP,
          })
          break
        case `${DOCUMENTS}_${TEMPLATES}`:
          if (!fetchingDocuments) {
            //console.log("here yes 3")
            // getOfferDocuments({
            //   payload: {
            //     offerId: id,
            //   },
            // })
          }
          break
        case `${DOCUMENTS}_${ACTIVE}`:
          //console.log("here yes 4")
          // fetchActiveEnvelopesFiles({
          //   payload: {
          //     offerId: id,
          //   },
          // })
          break
        case `${DOCUMENTS}_${OFFER_PACKAGE}`:
          getOfferPackage({
            id,
          })
          break
        default:
          break
      }
    }
  }, [currentActiveTab, currentSideNavTab, dealToView])

  const appendCurrentActiveTabToUrl = (currentTab) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("currentActiveTab", currentTab)

    history.replace({
      search: queryParams.toString(),
    })
  }

  
  const localTab = localStorage.getItem("current_active_local_tab");
  // const updatedActiveTab = localTab ? localTab : currentActiveTab;
  
  return (
    <>
      <OffersWrap>
        <MenuWrap>
          <ErrorsList />

          <HeaderSectionCommon />
          {/* {current_offer_status && !fetchingDealDetails ? (
            <> */}
              <Tabs
            steps={STEPS}
            wantDisable={false}
            toggleTab={(currentTab) => {
              //toggleActiveTab(currentTab)
              //appendCurrentActiveTabToUrl(currentTab)
              // localStorage.setItem("current_active_local_tab", currentTab);
            }}
            // currentActiveTab={localTab ? toggleActiveTab(localTab) : currentActiveTab}
            currentActiveTab={currentActiveTab}
            id={getTransactionId()}
            isContractPhase={contract_phase}
            offerDealId={getCurrentDealId()}
            fetchingPropertyInformation={fetchingPropertyInformation}
            propertyInformationObject={propertyInformationObject}
          />
              {currentActiveTab === COVERSHEET && (
              <CoverSheet />
            )}
            {currentActiveTab === DOCUMENTS && (
              <DocusignDocs />
            )}
            {currentActiveTab === CONTRACT_DETAILS && (
              <ContractDetails />
            )}
            {currentActiveTab === COMPLIANCE && (
              <ComplianceSection transactionId={getTransactionId()}/>
            )}
          
          {/* {currentActiveTab === "components" && (
            <ComponentsTemp />
          )} */}
            {/* </>
          ) : (
            <>
              <ShimmerBox w="100%" h="200px" />
              <ShimmerBox w="100%" h="50px" style={{marginTop: "10px"}}/>
              <ShimmerBox w="100%" h="500px" style={{marginTop: "10px"}}/>
            </>
            
          )} */}
          
        </MenuWrap>
      </OffersWrap>
      {isBasicOfferDetailsModalOpen && (
        <MLSModal
          toClose={() => {
            const url = `${RADIUS_URL}/crm/?transactionId=${parsedQuery.transaction_id}&client_id=${parsedQuery.client_id}&type=buyer`
            history.push(url)
          }}
        />
      )}
      {isCreateEnvelopeModalOpen && (
        <CreateEnvelopeModal
          toClose={() => {
            toggleCreateEnvelopeModal(false)
          }}
        />
      )}
      {isOfferToContractRedirectionModalVisible.bool && (
        <CRMModal
          toClose={() => {
            toggleOfferToContractConversion({
              bool: false,
              url: "",
            })
          }}
        >
          <OfferConversionToContract
            toClose={() => toggleOfferToContractConversion({
              bool: false,
              url: "",
            })}
          />
        </CRMModal>
      )}
      {isStatusChangeModalOpen.bool && (
        <CRMModal
          toClose={() => {
            toggleStatusChangeModalOffer({
              bool: false,
              id: "",
            })
          }}
        >
          <StatusChange
            toClose={() => toggleStatusChangeModalOffer({
              bool: false,
              id: "",
            })}
          />
        </CRMModal>
      )}
      <VoidAndRestart />
    </>
  )
}

export default Listings(CRMV2Container(withRouter(oldOffersContainer(container(OffersV2)))))
