import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import colors from "@colors"
import CircularImage from "dumbComponents/common/CircularImage"
import VerifiedImg from "images/referrals/preferred.png"

const Wrap = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.disabledInput};
  padding: 8px 12px;
  border-radius: 12px;
  display: flex;
  margin-top: 12px;
`

const ImageWrap = styled.div`
  margin-right: 16px;
  cursor: pointer;
`

const InfoWrap = styled.div``

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 30px;
  cursor: pointer;
  > p {
    font-size: 12px;
    color: ${colors.contentText};
    font-weight: 600;
    margin: 0px;
    img {
      max-width: 16px;
      position: relative;
      top: 3px;
      margin-left: 3px;
    }
  }
  h6 {
    font-size: 12px;
    margin: 0px;
  }
`

const TextWrap = styled.div`
  margin: 16px 0px;
  > p {
    font-weight: 300;
    font-size: 14px;
  }
`

const CongratulatoryPost = ({
  firstname,
  lastname,
  agent_image,
  profile_name,
  agency_name,
  citiesServed,
  isBroker,
  history,
}) => {
  const handleProfileRedirect = () => {
    const url = `/profile/${profile_name}`
    const { pathname } = root.location
    if (pathname && pathname.includes("/profile/")) {
      root.location.href = url
    } else {
      history.push(url)
    }
  }

  return (
    <Wrap className="congratulatory-post-wrapper">
      <ImageWrap>
        <CircularImage
          size="30"
          onClick={handleProfileRedirect}
          name={`${firstname || ""} ${lastname || ""}`}
          image={agent_image}
          charSize={14}
        />
      </ImageWrap>
      <InfoWrap onClick={handleProfileRedirect}>
        <UserInfo onClick={handleProfileRedirect}>
          <p>
            {`${firstname || ""} ${lastname || ""}`}
            {isBroker && (
              <img src={VerifiedImg} alt="Verified Agent" />
            )}
          </p>
          <h6>{`${agency_name || ""}`}</h6>
        </UserInfo>
        <TextWrap>
          {citiesServed && citiesServed.length > 0 && (
            <p>
              {citiesServed.map(x => x.city).join(", ")}
            </p>
          )}
        </TextWrap>
      </InfoWrap>
    </Wrap>
  )
}
export default withRouter(CongratulatoryPost)
