import Modal from "@ui/Modal"
import React from "react"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import Rooms from "container/Rooms"
import { ConvertEpoch } from "services/Utils"
import RoomType from "./RoomType"
import RoomDetails from "./RoomDetails"
import RoomSettings from "./RoomSettings"
import ScheduleRoomSettings from "./ScheduleRoomsSettings"

const CreateRoom = ({
  toClose,
  createRoom,
  createScheduleRoom,
  history,
  createApiPayload,
  currentCreateRoomPage,
  specialRoomType,
  eventData,
  updateScheduledRooms,
  getRoomsMetaDataObjectsResponse,
}) => {
  const {
    data: metaData,
  } = getRoomsMetaDataObjectsResponse || {}

  const getCurrentPage = () => {
    switch (currentCreateRoomPage) {
      case "select-type":
        return <RoomType currentCreateRoomPage={currentCreateRoomPage} />
      case "room-details":
        return (
          <RoomDetails
            eventData={eventData}
            topics={metaData ? metaData.topicData : []}
            
          />
        )
      case "room-settings":
        return <RoomSettings submit={handleSubmit} />
      case "scheduled-rooms-settings":
        return (
          <ScheduleRoomSettings
            submit={scheduleRoomSubmit}
            eventData={eventData}
            updateSubmit={updateScheduledRoomSettings}
          />
        )
      default:
        return <RoomType />
    }
  }
  const handleSubmit = (values) => {
    const {
      about_speaker,
    } = values

    const payload = {
      ...values,
      invited_agent_ids: (values && values.speakers && values.speakers.map(agent => agent.id)),
    }

    payload.scheduled_at = null
    payload.frequency = null
    payload.about_speaker = about_speaker
    payload.is_video_room = 1

    if (specialRoomType === "property") {
      payload.is_live_property_tour = 1
    } else {
      payload.is_live_property_tour = 0
    }

    const finalPayload = {
      ...createApiPayload,
      ...payload,
    }

    createRoom({
      payload: finalPayload,
      isRedirect: true,
      history,
    })
  }

  const scheduleRoomSubmit = (values) => {
    const {
      scheduled_at,
      scheduled_at_time,
      frequency,
      locations,
      about_speaker,
      is_public,
      // time_zone,
      event_duration,
      external_url,
    } = values

    const payload = {}

    const unixTime = parseInt((new Date(`${scheduled_at}T${scheduled_at_time}`).getTime() / 1000).toFixed(0), 10)

    payload.frequency = frequency.value
    payload.scheduled_at = unixTime
    payload.invited_agent_ids = values && values.speakers && values.speakers.map(agent => agent.id)
    payload.elastic_filters = locations
    payload.is_live_property_tour = 0
    payload.about_speaker = about_speaker
    payload.is_public = is_public
    payload.is_video_room = 1
    payload.event_duration = event_duration
    payload.external_url = external_url

    const finalPayload = {
      ...createApiPayload,
      ...payload,
    }

    createScheduleRoom({
      payload: finalPayload,
      history,
    })
  }

  const updateScheduledRoomSettings = (values, roomId) => {
    const {
      scheduled_at,
      scheduled_at_time,
      frequency,
      locations,
      about_speaker,
      time_zone,
      event_duration,
      external_url,
    } = values

    const payload = {}

    const unixTime = parseInt((new Date(`${scheduled_at}T${scheduled_at_time}`).getTime() / 1000).toFixed(0), 10)

    payload.frequency = frequency.value
    payload.scheduled_at = unixTime
    payload.invited_agent_ids = values && values.speakers && values.speakers.map(agent => agent.id)
    payload.elastic_filters = locations
    payload.is_live_property_tour = 0
    payload.about_speaker = about_speaker
    payload.time_zone = time_zone
    payload.event_duration = event_duration
    payload.external_url = external_url

    const finalPayload = {
      ...createApiPayload,
      ...payload,
    }

    updateScheduledRooms({
      payload: finalPayload,
      roomId,
      history,
    })
  }

  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      modalStyles={{
        backgroundColor: colors.dreamWhite,
        padding: "50px",
        height: "max-content",
        minHeight: "230px",
      }}
      dialogStyles={{
        width: "max-content",
        borderRadius: "6px",
      }}
      overlayStyles={{
        zIndex: 9990,
      }}
    >
      {getCurrentPage()}
    </Modal>
  )
}

export default Rooms(withRouter(CreateRoom))
