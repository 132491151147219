import React from "react"
import CookiesStorage from "services/CookieStorage"
import {
  InnerTab,
  NavLink,
  NotificationIndicator,
} from "./commonStyles"
import Notifications from "../Icons/NewIcons/Notifications"

const NotificationsTab = ({
  routeArray,
  updatesObjectExist,
  updateData,
  notificationViewed,
  fetchNotificationUnreadCount,
  unreadCount,
}) => (
  <InnerTab referral isActive={routeArray[0] === "notifications"}>
    <div className="icon-block-new">
      <Notifications />
    </div>
    <div className="text-block" style={{ color: "#303030" }}>
      <NavLink
        to="/notifications"
        isActive={routeArray[0] === "notifications"}
        onClick={() => {
          CookiesStorage.save("notificationDotClicked", true)
          notificationViewed({ type: "notifications" })
          fetchNotificationUnreadCount()
        }}
      >
        Notifications
        {
          unreadCount > 0
          && updatesObjectExist
          && updateData.notifications
          && !updateData.notifications.seen
          && !CookiesStorage.load("notificationDotClicked")
            ? (
              <NotificationIndicator />
            ) : (
              <></>
            )
        }
      </NavLink>
    </div>
  </InnerTab>
)

export default NotificationsTab
