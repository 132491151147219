import React, { useEffect } from "react"
import CRMV2Container from "container/CRM"
import Loader from "@ui/Loader"
import { TabContentContainer } from "../../styles"
import TimelineDetails from "./TimelineDetails"

const Timeline = ({
  getTimelineActionResponse,
  getTimeline,
  getCtIdResponse,
}) => {
  const { data } = getCtIdResponse || {}
  const { id } = data || {}
  useEffect(() => {
    getTimeline({ transactionId: id })
  }, [])
  const { data: timelineResponse, isFetching } = getTimelineActionResponse || {}
  return (
    <TabContentContainer>
      {isFetching ? <Loader /> : (
        <>
          {timelineResponse && timelineResponse.map((data, index) => (
            <TimelineDetails
              data={data}
              index={index}
            />
          ))}
        </>
      )}
    </TabContentContainer>
  )
}

export default CRMV2Container(Timeline)
