export const BASIC_INFO_FILE_TYPES = [
  {
    id: 1,
    name: "New Listing",
    value: "new_listing",
    description: "You’re representing the seller, no accepted offer yet",
  },
  {
    id: 2,
    name: "Listing w/Accepted Offer",
    value: "listing_with_accepted_offer",
    description: "You’re representing the seller and you have a fully executed offer",
  },
  {
    id: 3,
    name: "Pending Contract",
    value: "pending_contract",
    description: "You’re representing the buyer and have a fully executed offer",
  },
  {
    id: 4,
    name: "Dual",
    value: "dual",
    description: "You’re representing the buyer and the seller and have a fully executed offer",
  },
  {
    id: 5,
    name: "Lease Listing",
    value: "lease_listing",
    description: "You’re representing the landlord, no accepted lease yet",
  },
  {
    id: 6,
    name: "Pending Lease",
    value: "pending_lease",
    description: "You’re representing the tenant and have a fully executed lease agreement",
  },
  {
    id: 7,
    name: "External Referral Agreement",
    value: "external_referral_agreement",
    description: "You referred a client out to another agent and they are in our contract",
  },
]

export const CLIENT_SOURCE = [
  {
    id: 1,
    name: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    id: 2,
    name: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    id: 3,
    name: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    id: 4,
    name: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    id: 5,
    name: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    id: 6,
    name: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    id: 7,
    name: "Self",
    value: "self",
  },
  {
    id: 8,
    name: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    id: 9,
    name: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    id: 10,
    name: "Other",
    value: "other",
  },
]

export const REPRESENTING_VALUES = [
  {
    value: "seller",
    label: "Seller",
  },
  {
    value: "buyer",
    label: "Buyer",
  },
  {
    value: "landlord",
    label: "Landlord",
  },
  {
    value: "tenant",
    label: "Tenant",
  },
  {
    value: "dual",
    label: "Dual",
  },
  {
    value: "other",
    label: "Other",
  },
]

export const FIELD_VALUES = {
  listing_address: "string",
  city: "string",
  zipcode: "string",
  client_type: "select",
  file_type: "checkbox",
  other_source: "string",
  source: "checkbox",
  initial_name: "array",
  initial_email: "array",
  initial_phone: "array",
  clientDetailsArray: [],
}

export const US_OBJ = {
  lat: 37.09024,
  lng: -95.712891,
  south_west: {
    lat: 25.82,
    lng: -124.39,
  },
  north_east: {
    lat: 49.38,
    lng: -66.94,
  },
  address: "United States",
  country: {
    long_name: "United States",
    short_name: "US",
    types: [
      "country",
      "political",
    ],
  },
}
