import React from "react"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  AgentNameWrap,
  AgentNameDiv,
} from "./styles"

const AgentName = ({ data }) => {
  const {
    agent_name: agentName,
    agent_image: agentImage,
  } = data

  return (
    <AgentNameWrap>
      <CircularImage
        name={agentName}
        size={24}
        fontSize={15}
        image={agentImage || ""}
      />
      <AgentNameDiv>
        {agentName}
      </AgentNameDiv>

    </AgentNameWrap>
  )
}

export default AgentName
