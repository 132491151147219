import React, { useState, useEffect } from "react";
import { Modal, Select, Checkbox, Form, Input, Button } from "antd";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import LoaderButton from "@ui/LoaderButton";
import createToast from "dumbComponents/common/Toast/customToast";
import { MessageText, ContactModalWrap } from "./styles.js";
import ConfrimTick from "../Icons/confirmTick.js";
import { ModalWrap, CustomAntModal } from "../customModalStyles.js";
import { normalizePhone } from "../../../services/formUtils.js";
import {
  SubmittedContentWrap,
  WebContent2,
} from "../RequestTourModal/styles.js";

const Index = ({
  openModal,
  onClose,
  address,
  pinCode,
  postCreateATourInfo,
  postCreateATourResponse,
  listingPageResponse,
}) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState(
    address && pinCode
      ? `I am interested in ${address}, ${pinCode}`
      : "I am interested"
  );
  const [requestSubmitted, setrequestSubmitted] = useState(false);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?\s*[-\/.]?\s*\d{3}\s*[-\/.]?\s*\d{4,}$/.test(
      phone
    );
  };

  const headerComponent1 = (
    <>
      <h3 style={{ marginLeft: "24px" }}>Contact a buyer's agent</h3>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );

  const handleClick = () => {
    if (isValidEmail(email) && isValidPhone(phone)) {
      const payload = {
        // agent_id: 10026994,
        client_name: name,
        client_email: email,
        client_phone: phone,
        client_message: message,
        mlsId: listingPageResponse?.data?.ListingId,
        mlsPropertyId: listingPageResponse?.data?.Id,
      };
      postCreateATourInfo(payload);
      !postCreateATourResponse.isFetching && setrequestSubmitted(true);
    } else {
      createToast("Enter valid data!", "error");
    }
  };

  const submitButton = {
    width: "100%",
    backgroundColor: "#5A5FF2",
    color: "white",
    height: "48px",
    border: "none",
  };

  return (
    <ModalWrap>
      <CustomAntModal
        title={headerComponent1}
        centered
        visible={openModal && !requestSubmitted}
        onCancel={onClose}
      >
        <ContactModalWrap>
          <Form
            name="basic"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 28,
            }}
            style={{
              width: "100%",
              paddingRight: "10px",
            }}
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Enter Name",
                },
              ]}
            >
              <Input
                placeholder="Your Name"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Enter Email",
                },
              ]}
            >
              <Input
                placeholder="Your Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="Phone"
              rules={[
                {
                  required: true,
                  message: "Enter Phone",
                  pattern: /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?\s*[-\/.]?\s*\d{3}\s*[-\/.]?\s*\d{4}$/,
                },
              ]}
              normalize={normalizePhone}
            >
              <Input
                placeholder="Your Phone"
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Message"
              name="Message"
              rules={[
                {
                  message: "Your Message",
                },
              ]}
            >
              <MessageText
                placeholder="Your Message"
                style={{ height: "80px" }}
                defaultValue={message}
                onChange={(e) => setMessage(e.target.value)}
              ></MessageText>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 28,
                span: 28,
              }}
            >
              <LoaderButton
                type="primary"
                htmlType="submit"
                disabled={!name || !isValidEmail(email) || !isValidPhone(phone)}
                onClick={handleClick}
                loaderOption={{
                  color: "white",
                }}
                style={submitButton}
                isShowLoader={postCreateATourResponse.isFetching}
              >
                Contact Agent
              </LoaderButton>
            </Form.Item>
          </Form>
        </ContactModalWrap>
      </CustomAntModal>
      {requestSubmitted && (
        <CustomAntModal
          title=" "
          centered
          visible={requestSubmitted}
          onOk={onClose}
          onCancel={onClose}
          width={600}
        >
          <SubmittedContentWrap>
            <ConfrimTick />
            <p style={{ fontSize: "20px", marginTop: "20px" }}>
              Your request was sent
            </p>
            <WebContent2>
              One of our agents will connect with you shortly
            </WebContent2>
            <WebContent2>
              to assist with your inquiry.
            </WebContent2>
          </SubmittedContentWrap>
        </CustomAntModal>
      )}
    </ModalWrap>
  );
};

export default ListingPageContainer(Index);
