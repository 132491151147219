import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import {
  //getFirstCharacterCapital,
  isSuccess,
  //saveUserObjectToCookie,
  //UrlSearchParams,
  //unixToDate,
} from "services/Utils"
import { convertToRichText } from "./selector"
import * as AcademyAPIS from "./api"
import * as AcademyActions from "./actions"

function* handleEventListing() {
  try {
    const res = yield call(AcademyAPIS.getEventListingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.getEventsListingAction.success(response))
    }
  } catch (error) {
    yield put(AcademyActions.getEventsListingAction.failure(error))
  }
}

function* handleCourseList(action) {
  try {
    const {
      id,
    } = action.data
    let res
    if (id <= -1 || !id) {
      res = yield call(AcademyAPIS.getCourseListingAPI)
    } else {
      res = yield call(AcademyAPIS.getFilteredCourseListingAPI, id)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      const parsedResponse = convertToRichText({ response })
      yield put(AcademyActions.getCourseListingAction.success(parsedResponse.response))
    }
  } catch (error) {
    yield put(AcademyActions.getCourseListingAction.failure(error))
  }
}

function* handleStartCourse(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(AcademyAPIS.startCourseAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.startCourseAction.success(response))
    }
  } catch (error) {
    yield put(AcademyActions.startCourseAction.failure(error))
  }
}

function* handleGetCourseDetails(action) {
  try {
    const {
      courseId,
    } = action.data
    const res = yield call(AcademyAPIS.getCourseDetailsAPI, courseId)
    if (isSuccess(res)) {
      const { response } = res.data
      const parsedObj = convertToRichText(response)
      yield put(AcademyActions.getCourseDetailsAction
        .success(parsedObj))
    }
  } catch (error) {
    yield put(AcademyActions.getCourseDetailsAction.failure(error))
  }
}

function* handleLessonDetails(action) {
  try {
    const {
      lessonId,
      agentId,
      courseId,
    } = action.data
    const res = yield call(AcademyAPIS.getLessonDetailsAPI, lessonId, agentId)
    const courseDetails = yield select(state => state.academy.courseDetails.data)
    if (!courseDetails) {
      yield put(AcademyActions.getCourseDetailsAction.request({ courseId }))
    }
    if (isSuccess(res)) {
      const { response } = res.data
      const parsedObj = convertToRichText(response)
      yield put(AcademyActions.getLessonDetailsAction
        .success(parsedObj))
      const { course_id: longCourseId } = parsedObj
      yield put(AcademyActions.changeCourseStatusAction.request({
        payload: {
          recently_visited_lesson_id: lessonId,
          course_id: longCourseId,
        },
      }))
    }
  } catch (error) {
    yield put(AcademyActions.getLessonDetailsAction.failure(error))
  }
}

function* handleEventDetails(action) {
  try {
    const {
      eventId,
    } = action.data
    const res = yield call(AcademyAPIS.getEventDetailsAPI, eventId)
    if (isSuccess(res)) {
      const { response } = res.data
      const parsedObj = convertToRichText(response)
      yield put(AcademyActions.getEventDetailsAction
        .success(parsedObj))
    }
  } catch (error) {
    yield put(AcademyActions.getEventDetailsAction.failure(error))
  }
}

function* handleNextLesson(action) {
  try {
    yield put(AcademyActions.getNextLessonAction.clear())
    const {
      lessonId,
    } = action.data
    const res = yield call(AcademyAPIS.getNextLessionAPI, lessonId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.getNextLessonAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.getNextLessonAction.failure(error))
  }
}

function* handleRegisterForEvent(action) {
  try {
    const {
      payload,
      isThirdParty,
    } = action.data
    const res = yield call(AcademyAPIS.registerForEventAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.registerForEventAction.success(response))
      yield put(AcademyActions.getEventDetailsAction.request({ eventId: payload.event_id }))
      if (!isThirdParty) {
        yield put(AcademyActions.toggleRegisteredModalAction.call(true))
      }
    }
  } catch (error) {
    yield put(AcademyActions.registerForEventAction.failure(error))
  }
}

function* handleLessonStatus(action) {
  try {
    const { payload } = action.data
    const res = yield call(AcademyAPIS.changeLessonStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.changeLessonStatusAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.changeLessonStatusAction.failure(error))
  }
}

function* handleCourseStatus(action) {
  try {
    const { payload } = action.data
    const res = yield call(AcademyAPIS.changeCourseStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.changeCourseStatusAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.changeCourseStatusAction.failure(error))
  }
}

function* handleCommunityUpcomingEvents() {
  try {
    const res = yield call(AcademyAPIS.commmunityEventListingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.getCommunityUpcomingEventsAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.getCommunityUpcomingEventsAction.failure(error))
  }
}

function* handleCategoriesFetch() {
  try {
    const res = yield call(AcademyAPIS.getAllCategoriesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.getCategoriesListingAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.getCategoriesListingAction.failure(error))
  }
}

function* handleAcademyViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(AcademyAPIS.academyViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AcademyActions.academyViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(AcademyActions.academyViewedAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(AcademyActions.getEventsListingAction.REQUEST, handleEventListing)
  yield takeLatest(AcademyActions.getCourseListingAction.REQUEST, handleCourseList)
  yield takeLatest(AcademyActions.getLessonDetailsAction.REQUEST, handleLessonDetails)
  yield takeLatest(AcademyActions.changeLessonStatusAction.REQUEST, handleLessonStatus)
  yield takeLatest(AcademyActions.startCourseAction.REQUEST, handleStartCourse)
  yield takeLatest(AcademyActions.getCourseDetailsAction.REQUEST, handleGetCourseDetails)
  yield takeLatest(AcademyActions.getEventDetailsAction.REQUEST, handleEventDetails)
  yield takeLatest(AcademyActions.getNextLessonAction.REQUEST, handleNextLesson)
  yield takeLatest(AcademyActions.registerForEventAction.REQUEST, handleRegisterForEvent)
  yield takeLatest(AcademyActions.changeCourseStatusAction.REQUEST, handleCourseStatus)
  yield takeLatest(AcademyActions.getCommunityUpcomingEventsAction.REQUEST, handleCommunityUpcomingEvents)
  yield takeLatest(AcademyActions.getCategoriesListingAction.REQUEST, handleCategoriesFetch)
  yield takeLatest(AcademyActions.academyViewedAction.REQUEST, handleAcademyViewed)
}
