import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CRMV2Container from "container/CRM";
import BrbcContainer from "container/BRBC";
import { ShimmerBox } from "shared/styles/animation";
import CircularImage from "dumbComponents/common/CircularImage";
import Box from "@ui/Box";
import DropdownMenu from "@ui/NewTooltip";
import { Li } from "@ui/DragDropColumn/common";
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg";
import moreMenuSVG from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Menu.svg";
import {
  normalizePhone,
  email,
} from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils";
import AddIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/AddIcon.svg";
import {
  Phone as PhoneIcon,
  Mail as MailIcon,
} from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/";
import colors from "@colors";
import { DownOutlined } from "@ant-design/icons";
import { ATC, MONTSERRAT, INTER } from "@fonts";
import { Select, Tabs, Row, Col } from "@ui/antd";
import { get } from "lodash";
import { TimeZone } from "services/Utils";
import Loader from "@ui/Loader";
import BuyerLeadOverview from "./BuyerLeadOverView";
import { LeadInfo, Title, Icon } from "../common";
import { SearchCriteriaInfo, PropertyInfo } from "./Tabs";
import BrbcBanner from "./BRBC/BrbcBanner";
import BrbcModal from "./BRBC/BrbcModal";
import { Dropdown, Space, Skeleton } from "antd";
import ThreeDotMenu from "../../../../../images/icons/threedotMenu";
import PubNubForBrbc from "./BRBC/PubNubForBrbc/index";
import ConsumerAppContainer from "container/ConsumerApp/index";

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "FSBO",
    value: "fsbo",
  },
  {
    label: "Mail",
    value: "mail",
  },
  {
    label: "Networking",
    value: "networking",
  },
  {
    label: "New Construction",
    value: "new_construction",
  },
  {
    label: "Open House",
    value: "open_house",
  },
  {
    label: "Other - Agency",
    value: "other_agency",
  },
  {
    label: "Other - Builder",
    value: "other_builder",
  },
  {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  },
  {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  },
  {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  },
  {
    label: "Personal Transaction",
    value: "personal_transaction",
  },
  {
    label: "Property Management",
    value: "property_management",
  },
  {
    label: "Floor Call",
    value: "floor_call",
  },
  {
    label: "Referral - Attorney",
    value: "referral_attorney",
  },
  {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  },
  {
    label: "Referral - Lendor",
    value: "referral_lendor",
  },
  {
    label: "Referral - Other",
    value: "referral_other",
  },
  {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  },
  {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  },
  {
    label: "Referral - Title",
    value: "referral_title",
  },
  {
    label: "Sign Call",
    value: "sign_call",
  },
  {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  },
  {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  },
  {
    label: "Social Profile - Other",
    value: "social_profile_other",
  },
  {
    label: "Events - Other",
    value: "events_other",
  },
];

const { TabPane } = Tabs;

const Wrap = styled.div`
  margin: 32px;
`;
const AppSpace = styled(Space)`
  color: #5a5ff2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
      width: 170px;

`;
const HeaderWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e0e6ed;
  display: flex;
  padding: 10px 18px;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 32px;
`;

const AgentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AgentNameAndTypeWrap = styled.div`
  display: flex;
  align-items: center;
`;

const AgentName = styled.p`
  color: ${colors.new_colorNeutral900};
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0;
`;

const AgentSource = styled.p`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    border-radius: 100%;
    background-color: ${colors.new_grey50};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;

    svg {
      transform: scale(0.75);
    }
  }

  .info {
    overflow: hidden;
    color: ${colors.new_primary}
    font-family: ${INTER};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;

const EditClientInfo = styled.p`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin: 0;
  text-align: right;
`;

const AgentType = styled.p`
  background-color: ${colors.new_primary};
  color: ${colors.white};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 24px;
  height: 20px;
  margin: 0;
  margin-left: 8px;
  text-transform: capitalize;
`;

const RightContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
`;

const AgentLastUpdated = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const AgentLastUpdatedLabel = styled.div`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const AgentLastUpdatedDate = styled.div`
  background: ${colors.new_borderPurple};
  border: 1px solid ${colors.new_secondary};
  border-radius: 4px;
  display: flex;
  padding: 2px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const ReqWrap = styled.div`
  width: 626px;
  border: 1px solid #d3dae8;
  border-radius: 11px;
  padding: 23px;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction:
  margin-top: 17px;
  height: 22px;
  justify-content: space-between;
  align-items: space-between;
`;
const AddProperty = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`;

const DrawerHeader = ({
  agentInfo,
  getCrmClientResponse,
  setCurrentDrawerPage,
  setClientID,
  toggleAddNewLeadDrawer,
  toggleUserOverview,
  setCurrentClientType,
  getCtIdResponse,
  fetchPresignedBrbcDocsResponse,
  fetchBrbcStatusResponse,
  inviteClient,
  copyAppInviteLink
}) => {

  const { data, isFetching } = getCrmClientResponse || {};
  // const { data: brbcData, isFetching: isFetchingBrbc } =
  //   fetchPresignedBrbcDocsResponse || {};
  const { clientTransactions, name, phones, emails, created_at } = data || {};
  const { data: clientTransactionData } = getCtIdResponse || {};
  const { type, client_source } = clientTransactionData || {};
  const { SELLING: sellerTransactions, BUYING: buyerTransactions } =
    clientTransactions || {};
  const { data: brbcData, isFetching: isFetchingBrbcData } =
    fetchBrbcStatusResponse || {};
  const phone = (!isFetching && phones && get(phones[0], "phone")) || "";
  const email = (!isFetching && emails && get(emails[0], "email")) || "";

  const dualType =
    sellerTransactions &&
    sellerTransactions.length > 0 &&
    buyerTransactions &&
    buyerTransactions.length > 0;

  const systemTime = TimeZone(created_at, false);
  const { localeDateString } = systemTime;

  const getClientSource = () => {
    const foundSource = CLIENT_SOURCE.find(
      (source) => source.value === client_source
    );
    if (foundSource) {
      return foundSource.label;
    }
    return null;
  };

  const [isBrbcModalVisible, setIsBrbcModalVisible] = useState(false);

  useEffect(() => {
    if (data && data.agent_id) {
      const timer = setTimeout(() => {
        setIsBrbcModalVisible(true);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [data]);

  const handleProfileMenuClick = (e) => {
    if (e.key === "edit-profile") {
      setCurrentDrawerPage("edit-profile");
    } else {
      // some action
    }
  };

  const handleAppInviteClick = (e) => {
    if (e.key === "invite") {
       const payload = {
      "client_ids": [data?.id]
       }
      inviteClient({payload, callback:()=>{}});
    } else {
      copyAppInviteLink();
    }
  };

  const profileMenuItems = [
    {
      label: "Edit",
      key: "edit-profile",
    },
  ];

  const appInviteMenuItems = [
    {
      key: "invite",
      label: "Invite via email & sms",
    },
    {
      key: "copy",
      label: "Copy invite link",
    },
  ];

  const profileMenu = {
    items: profileMenuItems,
    onClick: handleProfileMenuClick,
  };

  const appInviteMenu = {
    items: appInviteMenuItems,
    onClick: handleAppInviteClick,
  };

  return (
    <Wrap>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <HeaderWrap>
            {/* <CircularImage
              size={92}
              name={name}
              charColor={colors.marineBLue}
              fontSize="40px"
            /> */}
            <Row>
              <Col span={18}>
                <AgentInfo>
                  <AgentNameAndTypeWrap>
                    <AgentName>{name}</AgentName>
                    <AgentType>{dualType ? "DUAL" : type}</AgentType>
                  </AgentNameAndTypeWrap>
                  <AgentSource>
                    {`Source: ${
                      client_source === null ? "" : getClientSource()
                    }`}
                  </AgentSource>

                  {phone && (
                    <ContactInfoContainer>
                      <div className="icon">
                        <PhoneIcon />
                      </div>
                      <p className="info">{phone && normalizePhone(phone)}</p>
                    </ContactInfoContainer>
                  )}

                  <ContactInfoContainer>
                    <div className="icon">
                      <MailIcon />
                    </div>
                    <p className="info">{email}</p>
                  </ContactInfoContainer>
                </AgentInfo>
              </Col>
              <Col span={6}>
                <RightContainerWrap>
                  {/* <EditClientInfo
                    onClick={() => { setCurrentDrawerPage("edit-profile") }}
                  >
                    Edit Client Info
                  </EditClientInfo> */}

                  {type &&
                  (type.toLowerCase() === "buyer" ||
                    type.toLowerCase() === "Buyer") ? (
                    <div style={{display:'flex'}}>
                      <Dropdown menu={appInviteMenu}>
                        <AppSpace>
                          SEND APP INVITE
                              <DownOutlined style={{marginTop:'-5px'}} />
                        </AppSpace>
                      </Dropdown>

                      <Dropdown menu={profileMenu}>
                        <Space>
                          <ThreeDotMenu />
                        </Space>
                      </Dropdown>
                    </div>
                  ) : (
                    <EditClientInfo
                      onClick={() => {
                        setCurrentDrawerPage("edit-profile");
                      }}
                    >
                      Edit Client Info
                    </EditClientInfo>
                  )}

                  <AgentLastUpdated>
                    <AgentLastUpdatedLabel>Added on</AgentLastUpdatedLabel>
                    <AgentLastUpdatedDate>
                      {localeDateString}
                    </AgentLastUpdatedDate>
                  </AgentLastUpdated>
                </RightContainerWrap>
              </Col>
            </Row>
          </HeaderWrap>
          {brbcData && (
            <PubNubForBrbc
              brbcId={brbcData?.docusign[0]?.brbc_id}
              agentId={data?.agent_id}
              clientId={data?.id}
            />
          )}
          {!isFetchingBrbcData && brbcData ? (
            <BrbcBanner />
          ) : (
            <ShimmerBox h="50px" w="100%" />
          )}

          {type &&
            (type.toLowerCase() === "buyer" ||
              type.toLowerCase() === "Buyer") &&
            !isFetchingBrbcData &&
            brbcData?.status === "get_started" && (
              <BrbcModal
                brbcModalType="auto-modal"
                toggleModal={isBrbcModalVisible}
                onClose={() => setIsBrbcModalVisible(false)}
              />
            )}
          <LeadInfo defaultActiveKey="1">
            {sellerTransactions && (
              <>
                <TabPane tab="SELLING" key="2">
                  <PropertyInfo />
                </TabPane>
              </>
            )}
            {buyerTransactions && (
              <>
                <TabPane tab="BUYING" key="4">
                  <SearchCriteriaInfo />
                </TabPane>
              </>
            )}
            {!sellerTransactions &&
              !buyerTransactions &&
              type &&
              (type.toLowerCase() === "buyer" ||
                type.toLowerCase() === "Buyer") && (
                <ReqWrap>
                  <TitleDiv>
                    <Title fontSize={18}>Add Search</Title>
                    <AddProperty
                      onClick={() => {
                        setCurrentDrawerPage("add-same-lead");
                        toggleAddNewLeadDrawer(true);
                        toggleUserOverview({
                          showUserOverviewDrawer: false,
                          agentInfo,
                        });
                        setCurrentClientType("buyer");
                        setClientID({
                          id: data.client_id,
                          deal_id: data.id,
                        });
                      }}
                    >
                      <Title fontSize={15}>Add Criteria</Title>
                      <Icon src={AddIcon} />
                    </AddProperty>
                  </TitleDiv>
                </ReqWrap>
              )}
            {!sellerTransactions &&
              !buyerTransactions &&
              type &&
              (type.toLowerCase() === "seller" ||
                type.toLowerCase() === "Seller") && (
                <ReqWrap>
                  <TitleDiv>
                    <Title fontSize={18}>Add Listing</Title>
                    <AddProperty
                      onClick={() => {
                        setCurrentDrawerPage("add-same-lead");
                        toggleAddNewLeadDrawer(true);
                        toggleUserOverview({
                          showUserOverviewDrawer: false,
                          agentInfo,
                        });
                        setCurrentClientType("seller");
                        setClientID({
                          id: data.client_id,
                          deal_id: data.id,
                        });
                      }}
                    >
                      <Title fontSize={15}>Add Listing</Title>
                      <Icon src={AddIcon} />
                    </AddProperty>
                  </TitleDiv>
                </ReqWrap>
              )}
          </LeadInfo>
        </>
      )}
    </Wrap>
  );
};

export default ConsumerAppContainer(BrbcContainer(CRMV2Container(DrawerHeader)));
