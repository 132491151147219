import React, { useEffect } from "react"
import styled from "styled-components"
import Footer from "dumbComponents/common/FooterNew"
import app from "container/App"
import { useWindowSize, getOS } from "services/Utils"
import CookieStorage from "services/CookieStorage"
import { Helmet } from "react-helmet"
import root from "window-or-global"
import RoomsContainer from "container/Rooms"
import container from "container/App/applyFlowContainer"
import RadiusNetworkImagePreview from "images/Graphics/LandingPagePreviews/HomePagePreview.png"
import Legal from "./legal"
import LegalMobile from "./mobile"

const Wrap = styled.div`
 
`

const DesktopWrap = styled.div`
  display: block;

  @media(max-width: 1099px) {
    display: none;
  }
`

const MobileWrap = styled.div`
  display: none;

  @media(max-width: 1099px) {
    display: block;
  }
`

const Wrapper = styled.div`
`

const BGWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};
  `}

  ${props => props.footer && `
    padding-top: 80px;
  `}
`

const LegalDisclosures = () => {
  const META_DESCRIPTION = `Real estate brokerage for performing agents and teams - no split. 
  Only focus on sales and customer service, while Radius does the rest.`

  const meta = {
    title: "Radius Community of Real Estate Professionals",
    description: META_DESCRIPTION,
    keywords: "Referrals, community, real estate agents, marketplace, network, coaching, training, education",
    type: "website",
    url: `${WEB_URL}network`,
    image: RadiusNetworkImagePreview,
  }

  return (
    <>
      <Wrapper>
        <Helmet>
          <title>{meta.title}</title>
          <meta property="og:title" content={meta.title} />
          <meta property="title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="description" content={meta.description} />
          <meta property="keywords" content={meta.keywords} />
          <meta property="og:image" content={meta.image} />
          <meta property="image" content={meta.image} />
        </Helmet>
        <DesktopWrap>
          <Legal />

        </DesktopWrap>
        <MobileWrap>
          <LegalMobile />
        </MobileWrap>
      </Wrapper>
      <BGWrap
        backgroundColor="#1c1c1f"
        footer
      >
        <Footer />
      </BGWrap>
    </>
  )
}

export default RoomsContainer(app(container(LegalDisclosures)))
