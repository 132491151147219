/* eslint-disable max-len */
import React from "react"
import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import { delay } from "redux-saga"
import { uniqBy } from "lodash"
import { toast as createToast } from "react-toastify"
import { isSuccess } from "services/Utils"
import { authCheck } from "services/auth"
import { toggleSignUpFormAction } from "container/App/actions"
import WhiteArrow from "images/Graphics/RadiusJobsAssets/ArrowRightWhite_1.svg"
import { ATC } from "@fonts"

import {
  getAllCreatedJobsApi,
  createJobApi,
  updateJobApi,
  deleteJobApi,
  fetchApplicantsApi,
  getAllCreatedPublicJobsApi,
  markJobFavouriteApi,
  applyForJobApplicationAPI,
  updateJobApplicationAPI,
  markSeenForJobAPI,
} from "./api"
import {
  getAllCreatedJobs,
  createJob,
  updateJob,
  deleteJob,
  showShareModal,
  showJobEditModal,
  showJobCreateModal,
  showJobDeleteModal,
  fetchApplicants,
  getAllCreatedPublicJobs,
  markJobFavourite,
  applyJobAction,
  updateJobAction,
  toggleJobsDetailsModalAction,
  markSeenAction,
  toggleModalPagesAction,
  getSpecificCreatedPublicJobs,
  currentJobViewedAction,
  clearExistingJobsAction,
  showLoaderWhenDelayAction,
  setShowInitialRecruitmentPage,
  initialPageTabToggleAction,
} from "./actions"

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

const holdConfig = {
  position: "top-left",
  autoClose: false,
  hideProgressBar: true,
  className: "blue-background width-max-content",
}

const CustomToastWithLink = ({
  history,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      width: "max-content",
      alignItems: "inherit",
      marginRight: "36px",
    }}
  >
    <p
      style={{
        margin: "0",
        fontFamily: `${ATC}`,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        letterSpacing: "0em",
        textAlign: "left",

      }}
    >
      Added to favorites.
    </p>
    <button
      type="button"
      style={{
        border: "none",
        background: "transparent",
        color: "white",
        cursor: "pointer",
        padding: "0px 10px 0px 13px",
        fontSize: "18px",
      }}
      onClick={() => {
        history.push("/realty/jobs?type=favorites")
      }}
    >
      View all
      {" "}
      <span
        style={{
          position: "relative",
          left: "1px",
          top: "2px",
        }}
      >
        <img src={WhiteArrow} alt="whitearrow.svg" />
      </span>
    </button>
  </div>
)

function* handleGetAllCreatedJobs(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })

    const res = yield call(getAllCreatedJobsApi, query)
    if (isSuccess(res)) {
      let brokerageCreatedJobs = yield select(state => state.brokerJobsDashboard.brokerageCreatedJobs.data)
      if (!brokerageCreatedJobs || action.data.skip === 0) {
        brokerageCreatedJobs = {
          jobs: [],
          total: 0,
        }
      }
      const response = {
        jobs: [...brokerageCreatedJobs.jobs, ...res.data.response.jobs],
        total: res.data.response.total,
        // total: 0,
      }
      // if (!response.total) {
      //   yield put(setShowInitialRecruitmentPage.request(true))
      // }
      response.isDone = response.jobs.length === res.data.response.total
      yield put(getAllCreatedJobs.success(response))
    }
  } catch (error) {
    yield put(getAllCreatedJobs.failure(error))
  }
}

function* handleFetchApplicants(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })

    const res = yield call(fetchApplicantsApi, query)
    const response = res.data && res.data.response
    response.fetched = true
    if (isSuccess(res)) {
      yield put(fetchApplicants.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchApplicants.failure(error))
  }
}

function* handleCreateJob(action) {
  try {
    const res = yield call(createJobApi, action.data.body)
    if (isSuccess(res)) {
      yield put(showLoaderWhenDelayAction.call(true))
      /*ADDED DELAY TO GET CORRECT SLUG*/
      yield delay(2500)
      yield put(createJob.success(res.data && res.data.response))
      const result = yield call(getAllCreatedJobsApi, "skip=0&limit=10")
      if (isSuccess(result)) {
        yield put(showLoaderWhenDelayAction.call(false))
        yield put(getAllCreatedJobs.success(result.data && result.data.response))
        if (action.data.body.status === "drafted") {
          createToast("Job Saved as Draft!", toastConfig)
        } else {
          createToast("Job Published!", toastConfig)
          yield put(showShareModal.success({
            enabled: true, jobId: res.data.response.job_id, jobSlug: result.data.response.jobs[0].job_slug, team_name: result.data.response.jobs[0].team_name, job_title: result.data.response.jobs[0].job_title,
          }))
        }
        if (action.data.history) {
          yield put(getAllCreatedJobs.success(null))
          action.data.history.push("/teams/jobs")
        }
      }
    }
    // yield put(showJobEditModal.success({ enabled: false, jobId: null }))
    yield put(showJobCreateModal.success(false))
  } catch (error) {
    yield put(createJob.failure(error))
  }
}

function* handleUpdateJob(action) {
  try {
    const res = yield call(updateJobApi, action.data)
    const result = yield call(getAllCreatedJobsApi, "skip=0&limit=10")
    if (isSuccess(res) && isSuccess(result)) {
      yield put(updateJob.success(res.data && res.data.response))
      yield put(getAllCreatedJobs.success(result.data && result.data.response))
      if (action.data.body.status === "drafted") {
        createToast("Job Updated!", toastConfig)
      } else {
        createToast("Job Published!", toastConfig)
        yield put(showShareModal.success({ enabled: true, jobId: action.data.jobId, jobSlug: action.data.jobSlug }))
      }
      if (action.data.history) {
        yield put(getAllCreatedJobs.success(null))
        action.data.history.push("/teams/jobs")
      }
      yield put(showJobEditModal.success({ enabled: false, jobId: null }))
      yield put(showJobCreateModal.success(false))
    }
  } catch (error) {
    yield put(updateJob.failure(error))
  }
}

function* handleDeleteJob(action) {
  try {
    const res = yield call(deleteJobApi, action.data)
    const result = yield call(getAllCreatedJobsApi, "skip=0&limit=10")
    if (isSuccess(res) && isSuccess(result)) {
      yield put(deleteJob.success(res.data && res.data.response))
      yield put(getAllCreatedJobs.success(result.data && result.data.response))
    }
    yield put(showJobDeleteModal.success(false))
  } catch (error) {
    yield put(deleteJob.failure(error))
  }
}

function* handleGetAllCreatedPublicJobs(action) {
  try {
    const {
      payload,
      isSearch,
      saved,
      applied,
    } = action.data

    if (applied || saved) {
      yield put(clearExistingJobsAction.call())
    }

    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })

    const res = yield call(getAllCreatedPublicJobsApi, query)
    if (isSuccess(res)) {
      if (!isSearch) {
        let brokerageCreatedJobs = yield select(state => state.brokerJobsDashboard.brokerageCreatedJobs.data)
        if (!brokerageCreatedJobs || action.data.skip === 0) {
          brokerageCreatedJobs = {
            jobs: [],
            total: 0,
          }
        }
        const response = {
          jobs: uniqBy([...brokerageCreatedJobs.jobs, ...res.data.response.jobs], "id"),
          total: res.data.response.total,
        }
        if (saved) {
          response.jobs = uniqBy([...brokerageCreatedJobs.jobs, ...res.data.response.jobs].filter(item => item.saved), "id")
        }

        response.isDone = response.jobs.length === res.data.response.total
        yield put(getAllCreatedPublicJobs.success(response))
        yield put(currentJobViewedAction.call({ currentJobViewed: res.data.response.jobs[0] }))
      } else {
        const {
          response,
        } = res.data || {}
        yield put(getAllCreatedPublicJobs.success(response))
      }
    }
  } catch (error) {
    yield put(getAllCreatedPublicJobs.failure(error))
  }
}

function* handleGetSpecificCreatedPublicJobs(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })

    const res = yield call(getAllCreatedPublicJobsApi, query)
    if (isSuccess(res)) {
      let brokerageCreatedJobs = yield select(state => state.brokerJobsDashboard.brokerageCreatedJobs.data)
      if (!brokerageCreatedJobs || action.data.skip === 0) {
        brokerageCreatedJobs = {
          jobs: [],
          total: 0,
        }
      }
      const response = {
        jobs: [...brokerageCreatedJobs.jobs, ...res.data.response.jobs],
        total: res.data.response.total,
      }
      response.isDone = response.jobs.length === res.data.response.total
      if (res.data.response.total) {
        yield put(getSpecificCreatedPublicJobs.success(res.data.response.jobs[0]))
        yield put(currentJobViewedAction.call({ currentJobViewed: res.data.response.jobs[0] }))
      }
    }
  } catch (error) {
    yield put(getSpecificCreatedPublicJobs.failure(error))
  }
}

function* handleMarkJobFavourite(action) {
  try {
    const {
      job_id,
      saved,
      reloadWithFilter,
      history,
    } = action.data

    const payload = {
      job_id,
      saved,
    }

    const res = yield call(markJobFavouriteApi, payload)
    if (isSuccess(res)) {
      yield put(markJobFavourite.success(res.data && res.data.response))
      yield put(toggleJobsDetailsModalAction.call({ enabled: false }))
      const message = action.data.saved ? "View favorites" : "Removed as favorite"
      if (action.data.saved) {
        createToast(<CustomToastWithLink history={history} />, holdConfig)
      } else {
        createToast(message, toastConfig)
      }
      if (reloadWithFilter) {
        yield put(getAllCreatedPublicJobs.request({
          payload: {
            skip: 0,
            limit: 10,
            saved: true,
          },
        }))
      } else {
        yield put(getAllCreatedPublicJobs.request({
          payload: {
            skip: 0,
            limit: 10,
          },
        }))
      }
    }
  } catch (error) {
    yield put(markJobFavourite.failure(error))
  }
}

function* handleJobApplicationApply(action) {
  const {
    payload,
    landingPageJobApply,
  } = action.data
  try {
    const res = yield call(applyForJobApplicationAPI, payload)
    if (isSuccess(res)) {
      const {
        data,
      } = res || {}
      const {
        response,
      } = data || {}
      yield put(applyJobAction.success(response))
      yield put(toggleModalPagesAction.call({
        currentPage: "intro",
      }))
      createToast("Application Submitted", toastConfig)
      yield put(toggleJobsDetailsModalAction.call({ enabled: false }))
      yield put(getAllCreatedPublicJobs.request({
        skip: 0,
        limit: 10,
      }))
    }
    yield put(initialPageTabToggleAction.call({
      firstPageTab: "job",
    }))
  } catch (error) {
    const {
      data,
    } = error || {}

    const {
      error: {
        message = "",
      },
    } = data || {}
    createToast(message, toastConfig)
    yield put(applyJobAction.failure(error))
    yield put(toggleJobsDetailsModalAction.call({ enabled: false }))
    yield put(initialPageTabToggleAction.call({
      firstPageTab: "job",
    }))
    yield put(getAllCreatedPublicJobs.request({
      payload: {
        skip: 0,
        limit: 10,
      },
    }))
  } finally {
    if (landingPageJobApply) {
      yield put(toggleJobsDetailsModalAction.call({ enabled: false }))
      if (!authCheck()) {
        yield put(toggleSignUpFormAction.call(true))
      }
    }
  }
}

function* handleJobApplicationUpdate(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(updateJobApplicationAPI, payload)
    if (isSuccess(res)) {
      const {
        data,
      } = res || {}
      const {
        response,
      } = data || {}
      yield put(updateJobAction.success(response))
    }
  } catch (error) {
    yield put(updateJobAction.failure(error))
  }
}

function* handleJobSeen(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(markSeenForJobAPI, payload)
    if (isSuccess(res)) {
      const {
        data,
      } = res || {}
      const {
        response,
      } = data || {}
      yield put(markSeenAction.success(response))
    }
  } catch (error) {
    yield put(markSeenAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(fetchApplicants.REQUEST, handleFetchApplicants)
  yield takeLatest(getAllCreatedJobs.REQUEST, handleGetAllCreatedJobs)
  yield takeLatest(createJob.REQUEST, handleCreateJob)
  yield takeLatest(updateJob.REQUEST, handleUpdateJob)
  yield takeLatest(deleteJob.REQUEST, handleDeleteJob)
  yield takeLatest(getAllCreatedPublicJobs.REQUEST, handleGetAllCreatedPublicJobs)
  yield takeLatest(markJobFavourite.REQUEST, handleMarkJobFavourite)
  yield takeLatest(applyJobAction.REQUEST, handleJobApplicationApply)
  yield takeLatest(updateJobAction.REQUEST, handleJobApplicationUpdate)
  yield takeLatest(markSeenAction.REQUEST, handleJobSeen)
  yield takeLatest(getSpecificCreatedPublicJobs.REQUEST, handleGetSpecificCreatedPublicJobs)
  // yield takeLatest(showShareModal.REQUEST, handleDeleteJob)
}
