import styled from "styled-components"
import Button from "@ui/Button"
import React from "react"
import colors from "@colors"
import { ATC, HIGHLIGHT_FONT } from "@fonts"
import {
  maxLargeMobile, maxDesktopWidth,
} from "@consts"

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.mg && `
    margin: ${props.mg}
  `}
`
export const Header = styled.h2`
  width: 100%;
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: ${props => props.fw};
  font-size: ${props => props.fs};
  line-height: 37px;
  letter-spacing: 0.015em;
  color: ${colors.black};
  margin: 0;
  ${props => props.mg && `
    margin: ${props.mg}
  `}
  ${props => props.source && props.source === "jobTitle" && `
    ${maxDesktopWidth(`
      font-size: 36px;
    `)}
    ${maxLargeMobile(`
      font-size: 32px;
    `)}
  `}

  ${props => props.source && ["aboutTeam", "overview", "requirements", "commission"].includes(props.source) && `
    ${maxDesktopWidth(`
      font-size: 20px;
    `)}
    ${maxLargeMobile(`
      font-size: 18px;
    `)}
  `}

`

export const Label = styled.span`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 10px;
`

export const ButtonGroup = styled.div`
  padding: 20px 0 0px 0;
  justify-content: right;
  display: flex;
`

export const ButtonOutlined = ({ text, action }) => (
  <Button
    bsStyle="marineBLueOutlined"
    fontSize="16px"
    fontWeight="500"
    fontColor={colors.black}
    padding="8px 14px"
    borderRadius="0px"
    onClick={action}
  >
    {text}
  </Button>
)

export const ButtonFilled = ({ text, action }) => (
  <Button
    bsStyle="marineBLueFill"
    fontSize="16px"
    fontWeight="500"
    fontColor={colors.black}
    padding="8px 14px"
    borderRadius="0px"
    margin="0 0 0 10px"
    height="40px"
    onClick={action}
  >
    {text}
  </Button>
)

export const Icon = styled.img`
  cursor: pointer;
`

export const Image = styled.img`
  margin: 0 0 0 10px;
  cursor: pointer;
`

export const Paragraph = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 23px;
  color: ${colors.black};
`

export const Section = styled.div`
  margin-top: 30px;
`

export const RichTextEditorWrapper = styled.div`
  margin-top: 10px;
  .codex-editor__redactor {
    background: white;
    margin-top: 20px;
    font-size: 16px;
    font-family: ${ATC};
    font-weight: 200;
    padding: 10px !important;
    margin-bottom: 0px !important;
    min-height: 120px;
    ${props => props.pb && `
      padding-bottom: ${props.pb} !important;
    `}
    ${props => props.readOnly && `
      padding: 0 !important;
      margin: 0;
      border: none !important;
    `}
  }
  .ce-toolbar__content {
    margin: 0;
  }
  .ce-block__content {
    max-width: none;
    ${props => props.readOnly && `
      div[contenteditable="true"] {
        -webkit-user-modify: read-only;
        -moz-user-modify: read-only;
        user-modify: read-only;
      }
    `}
  }
  .ce-toolbar__actions--opened,
  .ce-inline-toolbar  {
    ${props => props.readOnly && `
      display: none;
    `}
  }
  > div {
    width: auto;
  }
  span {
    ol {
      list-style: auto;
    }
    ul {
      list-style: initial;
    }
    ol, ul {
      padding-left: 1.5em;
      font-size: 16px;
      font-weight: 100;
      color: ${colors.black};
    }
    
    ol li {
      padding-bottom: 10px;
      line-height: 1.4;
      color: ${colors.black};
    }

    ul li, ol li {
      padding-bottom: 10px;
      line-height: 1.4;
      color: ${colors.black};
    }
    p {
      font-size: 16px;
      font-weight: 100;
      color: ${colors.black};
    }
    strong {
      font-weight: bolder;
    }
    em {
      font-style: italic;
    }
  }

  @media(min-width: 1366px) {
    max-width: 80%;
  }
`
