import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import GetStartedImage from "../../Images/GetStartedImage.svg"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  SignupButtons,
} from "../../common"

const GetStartedPage = ({
  history,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <RightWrap>
          <WelcomeContentWrap>
            <WelcomeTitle>
              Your Mobile Business
            </WelcomeTitle>
            <WelcomeDescription>
              The Radius apps move with you, helping you to stay connected wherever your clients take you.
            </WelcomeDescription>
          </WelcomeContentWrap>
          <GetStartedWrap>
            <SignupButtons
              onClick={() => history.push("/signup/enter-email")}
            >
              Get Started
            </SignupButtons>
          </GetStartedWrap>
        </RightWrap>
        <LeftWrap
          justifyContent="flex-end"
        >
          <LeftImage src={GetStartedImage} />
        </LeftWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(GetStartedPage)
