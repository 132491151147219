import React, { useState, useEffect } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import cookie from "services/CookieStorage"
import { Checkbox, Button, Upload } from "@ui/antd"
import ImgCrop from "antd-img-crop"
import TechAssetsContainer from "container/TechAssets"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  overflow-y: scroll;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
  height: 300px;
  padding: 10px;
  box-shadow: 0px 0px 1px 1px #e8e8e8;
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-color: #b2b2b2;
  }
`

const ContentContainer = styled.div`
  padding: 40px 30px 0px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
`

const DocCheckContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  ${props => !props.noborder && `
    padding: 20px 10px;
    border-bottom: 1px solid #E5E5E5;
  `}
  .ant-checkbox-inner {
    border: 1px solid #303030;
  }
`

const DocLabel = styled.span`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
`

const Filter = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 40px;
  justify-content: space-between;
  margin-bottom: -20px;
  margin-top: 20px;
  p {
    margin-right: 10px;
  }
  span {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
`

const Note = styled.p`
  font-size: 14px;
  color: #303030;
  font-weight: 200;
  font-family: ${ATC};
  padding: 30px 0px 0 0;
  line-height: 18px;
  display: block !important;
  margin-bottom: -10px;
`

const UploadListingImages = ({
  showUploadListingImages, toggleUploadListingImages, listingImagesParent, imageUploadForListing,
  createAllListingImages, createAllListingImagesResponse, getAllListingsResponse, getAllListings
}) => {

  // const [listingImages, setListingImages] = useState([])

  const [toRemove, setToRemove] = useState([])
  const [count, setCount] = useState(0)

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (getAllListingsResponse.data) {
      const images = getAllListingsResponse.data?.find(listingData => listingData.id === showUploadListingImages)?.listing_images
      // {
      //   uid: '-1',
      //   name: 'image.png',
      //   status: 'done',
      //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
      if (images?.length) {
        setFileList([...images.map((im, index) => ({
          uid: index,
          name: 'image.png',
          status: 'done',
          url: im,
        }))])
      }
    }
  }, [])

  const createListingImages = async () => {
    const user = cookie.load("user") || {}
    const payload = {
      image_urls: fileList.map(file => file.url),
      deal_id: showUploadListingImages,
      listing_id: showUploadListingImages,
    }
    createAllListingImages({
      payload,
      callback: () => {
        getAllListings({
          skip: 0,
          limit: 200,
          agentId: user.agent_id || user.id,
          forListing: true,
          include: "in-contract-listings",
          transactionType: "Listing",
        })
        toggleUploadListingImages(false)
      },
    })
  }

  const onChange = async ({ fileList: newFileList }) => {
    console.log("==newFileList===", newFileList)
    if (newFileList.length < fileList.length) {
      setFileList(newFileList);
      return
    }
    const file = newFileList.find(lst => !lst.url)
    newFileList[newFileList.length - 1].status = "uploading"
    setFileList(newFileList);
    await imageUploadForListing({
      file: file.originFileObj,
      objName: `image${count}`,
      fileName: file.name,
      callback: (url) => {
        newFileList[newFileList.length - 1].status = "done"
        newFileList[newFileList.length - 1].url = url
        setFileList([...newFileList])
      }
    })
    return
  }

  const uploadImages = async (file) => {
    console.log("==file===", file)
    // setCount(count + 1)
    // await imageUploadForListing({
    //   file,
    //   objName: `image${count}`,
    //   fileName: file.name,
    //   callback: (url) => {
    //     const newFileList = fileList
    //     newFileList[newFileList.length-1].status = "done"
    //     newFileList[newFileList.length-1].url = url
    //     setFileList([...newFileList])
    //   }
    // })
  }

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  }

  return (
    <Wrap>
      {showUploadListingImages && (
        <Modal
          show
          toClose={() => { toggleUploadListingImages(false) }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          // height="400px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "#F9F9F7",
            borderRadius: "8px",
            minHeight: "280px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            height: "510px",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <h1>
                Upload Listing Images
              </h1>
            </Header>
            <Header>
              <Note>

              </Note>
              <Filter>
              </Filter>
            </Header>
            <ContentContainer>
              <Content>
                <ImgCrop aspect="2">
                  <Upload
                    action={uploadImages}
                    listType="picture-card"
                    fileList={fileList}
                    progress
                    onChange={onChange}
                  >
                    {fileList.length < 5 && '+ Upload'}
                  </Upload>
                </ImgCrop>

              </Content>
            </ContentContainer>
            <ButtonWrap>
              {/* <DocCheckContainer noborder>
                <Checkbox>
                  Send to my TC
                </Checkbox>
              </DocCheckContainer> */}
              <Button
                style={{
                  width: "45%",
                  height: 48,
                  background: "white",
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                }}
                type="primary"
                shape="round"
                size="large"
                // htmlType="submit"
                onClick={() => {
                  toggleUploadListingImages(false)
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  height: 48,
                  width: "45%",
                  background: "#3B60E4",
                  borderColor: "#white",
                  // background: selectedDoc.length ? "#3B60E4" : "#f5f5f5",
                  float: "right",
                  // borderColor: selectedDoc.length ? "white" : "#d9d9d9",
                }}
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                disabled={!fileList.length}
                loading={createAllListingImagesResponse.isFetching}
                // onClick={() => { toggleDocumentSelectionConfirm(true) }}
                onClick={() => {
                  // toggleUploadListingImages(false)
                  createListingImages()
                }}
              >
                Save
            </Button>
            </ButtonWrap>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(UploadListingImages))
