/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ViewContractForListing = ({
  processBeforeViewingContract,
  performNecessaryActionsBeforeLoadingListing,
  performNecessaryActionsBeforeLoadingListingResponse,
  processBeforeViewingContractResponse,
  listingData,
  getContractByIdResponse,
  toClose,
  listingView = "",
  history,
  getListingsResponse,
  setCurrentListingTab,
  handleCollapseValues,
  viewListingModal,
}) => {
  const {
    isFetching: processing,
  } = processBeforeViewingContractResponse || {}

  const {
    isFetching: fetchingContractDetails,
  } = getContractByIdResponse || {}

  const {
    isFetching: processListingView,
  } = performNecessaryActionsBeforeLoadingListingResponse || {}

  const {
    data: listings,
  } = getListingsResponse || {}

  const {
    dealId,
  } = listingData || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Confirm action
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              You might have changes on your current tab, would you like to view the listing details?
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            disabled={processListingView || processing || fetchingContractDetails}
            loaderOption={{
              color: "blue",
            }}
            onClick={toClose}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={processListingView || processing || fetchingContractDetails}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              //prefetch data
              if (dealId) {
                performNecessaryActionsBeforeLoadingListing({
                  dealId,
                  record: listingData,
                  history,
                  callback: () => {
                    handleCollapseValues(["property_information"])
                    setCurrentListingTab("cover_sheet")
                    viewListingModal({
                      bool: false,
                    })
                  },
                })
              }
            }}
          >
            {(processListingView || processing || fetchingContractDetails) ? "Please Wait" : "View Listing"}
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(TechAssets(Listings(ViewContractForListing)))

