import React from "react"
import RealtyContainer from "container/Realty"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Button from "@ui/Button"
import colors from "@colors"
import root from "window-or-global"
import { authCheck } from "services/auth"
import {
  mobile,
  preDesktopWidth,
  preTabletWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
} from "@consts"
import { ATC } from "@fonts"

const Wrap = styled.div`
`

const TestimonialContainerWrap = styled.div``

const TestimonialWrap = styled.div`
  .image-testimonial {
    width: 310px;
    height: 310px;
  }
  .image-testimonial-combine-1 {
    width: 160px;
    height: 310px;
    border-radius: 110px 0px 0px 110px;
  }
  .image-testimonial-combine-2 {
    width: 160px;
    height: 310px;
    border-radius: 0px 110px 110px 0px;
    border-left: 1px solid #FEFEFE;
  }
  align-items: center;
  padding: 48px 86px 48px 86px;
  border: 4px solid #f9f9f7;
  margin: 48px 0px;
  ${minDesktopWidth`
    padding: 48px 36px 48px 36px;
  `}
  ${preDesktopWidth`
    padding: 30px;

    .image-testimonial {
      width: 250px;
      height: 250px;
    }
  `}
  ${preTabletWidth`
    padding: 30px;
    .image-testimonial {
      width: 160px;
      height: 160px;
\    }
    .image-testimonial-combine-1 {
      width: 85px;
      height: 160px;
    }
    .image-testimonial-combine-2 {
      width: 85px;
      height: 160px;
      border-left: 1px solid #FEFEFE;
    }
  `}
`

const TestimonialContent = styled.div`
  img {
    width: 143px;
    height: 52px;
  }

  ${props => props.imageWidth && `
    width: ${props.imageWidth};
  `}
`

const TestimonialTitle = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: ${colors.black};
  margin-bottom: 30px;
  ${preDesktopWidth`
    font-size: 26px;
    line-height: 34px;
    padding: 0px;
    text-align: center;
  `}
  ${preTabletWidth`
    font-size: 24px;
    line-height: 32px;
    padding: 0px;
    text-align: center;
  `}
`
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-around;

  align-items: center;
  .left {
    margin: auto;
    padding: 0px 40px 0px 40px;
  }
  ${mobile`
    flex-wrap: wrap;
  `}
  ${preDesktopWidth`

    .left {
      padding: 0px;
      margin: auto;
    }
    
    .content-quote {
      display: none
    }
    .text-quote {
      width: 100%;
    }
  `}
  ${preTabletWidth`
    .content-quote {
      display: none
    }
  `}
  .right {
    margin: auto;

    .logo2 {
      max-width: 200px;
    }
    .apply-now {
      margin-left: 80px;
    }
    p {
      color: ${colors.black};
      font-family: ${ATC};
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
  
  
      ${preDesktopWidth`
        font-family: ${ATC};
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        width: 474px;
      `}
  
      ${preTabletWidth`
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        width: 278.9px !important;
      `}
  
      @media(min-width: 1024px) {
        font-size: 22px;
        line-height: 31px;
        width: 474px;
      }
  
      @media(min-width: 1440px) {
        font-size: 24px;
        line-height: 31px;
        width: 688px;
      }
    }
    ${preDesktopWidth`
    .content-quote {
      display: none
    }
    .apply-now {
      margin-left: 0px;
    }

    .logo1 {
      display: block;
      margin: 0 auto;
    }

    p {
      width: 474px;
      padding-top: 10px;
    }

  `}

    ${preTabletWidth`
      p {
        width: auto;
      }
      .content-quote {
        display: none
      }
      .apply-now {
        margin-left: 0px;
      }
      img {
        margin: 0 auto;
        display: block;
      }
    `}
  }
`
const Quote = styled.div`
  display: none;
  font-size: 32px;
  font-weight: 400;
  margin-right: 11px;
  font-family: ${ATC};
  position: relative;
  top: 19px;
  
  ${preDesktopWidth`
    margin-right: 0px;
    top: 39px;
    left: -9px;
  `}

  ${preTabletWidth`
    top: 36px;
    right: 8px;
    left: -16px;
  `}
`

const TestimonialPara = styled.div`
  display: flex;
  justify-content: space-around;
  ${mobile`
    flex-wrap: wrap;
  `}

  p {
    font-family: ${ATC};

    ${minDesktopWidth`
      margin-top: 15px;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
    `}

    ${minLargeDesktopWidth`
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
    `}

    ${preDesktopWidth`
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
    `}

    ${preTabletWidth`
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
    `}
  }
`
const handleTestimonialClick = (history, details, RARTracker, isIosBrowser) => {
  if (root.heap) {
    if (details.navigateButtonClick === "/realty/teams") {
      root.heap.track("Click_team_leads_realty_web")
    } else {
      root.heap.track("Click_individual_agents_realty_web")
    }
  }
  const isLogged = authCheck()
  if (isLogged) {
    RARTracker({
      payload: {
        source: details.hubspotRARKey,
      },
    })
  }

  if (isIosBrowser) {
    history.push(`${details.navigateButtonClick}?platform=ios`)
  } else {
    history.push(details.navigateButtonClick)
  }
}

const Testimonial = ({
  testimonials,
  heading,
  description,
  history,
  RARTracker,
  isIosBrowser,
  imageWidth,
  webImageWidth,
}) => (
  <Wrap id="realty-v2-testimonials">
    <TestimonialContainerWrap>
      {testimonials.map(details => (
        <TestimonialWrap className="row">
          {details.title && (<TestimonialTitle>{details.title}</TestimonialTitle>)}
          <ContentWrap className="inner">
            <div className="left">
              <img src={details.imageUrl1} alt="img" className={details.combine ? "image-testimonial-combine-1" : "image-testimonial"} />
              {details.imageUrl2 && (
                <img src={details.imageUrl2} alt="img" className={details.combine ? "image-testimonial-combine-2" : "image-testimonial"} />
              )}
            </div>
            {/* <Quote className="content-quote">“</Quote> */}
            <TestimonialContent webImageWidth={webImageWidth} imageWidth={imageWidth} class="right">
              <img className={details.className} src={details.logoUrl} alt="img" />
              <TestimonialPara>
                <p>{details.testimonial}</p>
              </TestimonialPara>
              {details.buttonText && (
                <Button
                  className="apply-now"
                  bsStyle="darkPurple"
                  padding="16px"
                  width="auto"
                  onClick={() => handleTestimonialClick(history, details, RARTracker, isIosBrowser)}
                >
                  {details.buttonText}
                </Button>
              )}

            </TestimonialContent>
          </ContentWrap>
        </TestimonialWrap>
      ))}
    </TestimonialContainerWrap>
  </Wrap>
)

export default withRouter(RealtyContainer(Testimonial))
