import React from "react"
import {
  DangerButton,
} from "./styles"

const InputButton = ({
  buttonName,
  ...props
}) => (
  <DangerButton
    {...props}
    type="danger"
  >
    <p>{buttonName || "Button Name"}</p>
  </DangerButton>
)

export default InputButton
