import * as NetworkAction from "container/Network/actions"

export const getAllFeeds = NetworkAction.getAllFeedsAction.request
export const getUserCities = NetworkAction.getCityPreferencesAction.request
export const setUserCities = NetworkAction.setCityPreferencesAction.request
export const getAllSpecializations = NetworkAction.getAllSpecializationsAction.request
export const getUserSpecializations = NetworkAction.getSpecializationPreferencesAction.request
export const setUserSpecializations = NetworkAction.setSpecializationPreferencesAction.request
export const getAllTopics = NetworkAction.getAllTopicsAction.request
export const getUserTopics = NetworkAction.getTopicsPreferencesAction.request
export const setUserTopics = NetworkAction.setTopicsPreferencesAction.request
export const getSuggestedAgents = NetworkAction.getSuggestedAgentsAction.request
export const followAgent = NetworkAction.followAgentAction.request
export const unfollowAgent = NetworkAction.unfollowAgentAction.request
export const getOnboardingStatus = NetworkAction.getOnboardingStatusAction.request
export const getCommentsForFeed = NetworkAction.getCommentsForFeedAction.request
export const getLikers = NetworkAction.getLikersAction.request
export const updateFeedLike = NetworkAction.updateFeedLikeAction.request
export const updateCommentLike = NetworkAction.updateCommentLikeAction.request
export const postCommentForFeed = NetworkAction.postCommentForFeedAction.request
export const getTagsForTopic = NetworkAction.getTagsBasedOnTopicsAction.request
export const uploadPostImage = NetworkAction.uploadPostImageAction.request
export const setTopicAndGetTags = NetworkAction.setTopicAndGetTagsAction.request
export const createUpdateFeedItem = NetworkAction.createUpdateFeedItemAction.request
export const getUnansweredQuestions = NetworkAction.getUnansweredQuestionsActions.request
export const getSnippetData = NetworkAction.getSnippetDataAction.request
export const startOnboarding = NetworkAction.startOnboardingAction.request
export const getSuggestedTopics = NetworkAction.getSuggestedTopicsAction.request
export const followSuggestedTopic = NetworkAction.followSuggestedTopicAction.request
export const createIntroPost = NetworkAction.createIntroPostAction.request
export const feedsViewed = NetworkAction.feedsViewedAction.request
export const changeFeedSelection = NetworkAction.changeFeedSelectionAction
export const handleSpecializationUpdate = NetworkAction.handleSpecializationUpdate
export const handleTopicsUpdate = NetworkAction.handleTopicsUpdate
export const changeSelectedTopic = NetworkAction.changeSelectedTopicAction
export const setUserCitiesInStore = NetworkAction.setUserCitiesAction
export const openFeedDetailView = NetworkAction.openFeedDetailViewAction
export const closeFeedDetailView = NetworkAction.closeFeedDetailViewAction
export const finishOnboarding = NetworkAction.finishOnboardingAction
export const updateCreateEditPostModal = NetworkAction.updateCreateEditPostModalAction
export const confirmCloseCreateEditModal = NetworkAction.confirmCloseCreateEditModalAction
export const closeResetCreateEditModal = NetworkAction.closeResetCreateEditModalAction
export const createEditTextChange = NetworkAction.createEditTextAction
export const openLikers = NetworkAction.openLikersAction
export const closeLikers = NetworkAction.closeLikersAction
export const openDiscoverAgents = NetworkAction.openDiscoverAgentsAction
export const closeDiscoverAgents = NetworkAction.closeDiscoverAgentsAction
export const setCommunityGroup = NetworkAction.setCommunityGroupAction
export const togglePersonalizeFeedModal = NetworkAction.togglePersonalizeFeedModalAction
export const triggerCreateEditModal = NetworkAction.triggerCreateEditModalAction
export const saveInitCreateEditModal = NetworkAction.saveInitCreateEditModalAction
export const openImagePreviewModal = NetworkAction.openImagePreviewModalAction
export const closeImagePreviewModal = NetworkAction.closeImagePreviewModalAction
export const updateAutoFocus = NetworkAction.updateAutoFocusAction
export const refreshCurrentFeed = NetworkAction.refreshCurrentFeedAction
export const resetFeedCache = NetworkAction.resetFeedCacheAction
export const removeMediaAndLinkify = NetworkAction.removeMediaAndLinkifyAction.call
export const removeLinkFromEditPost = NetworkAction.removeLinkFromPostEditAction.call
export const introPostTextChange = NetworkAction.introPostTextChangeAction
export const updateSelectedIntroCities = NetworkAction.updateSelectedIntroCitiesAction
export const toggleIntroPostModal = NetworkAction.toggleIntroPostModalAction
export const removeSnippetFromPreview = NetworkAction.removeSnippetFromPreviewAction.call
export const getLatestCourseFromAcademy = NetworkAction.getLatestCourseFromAcademyAction.request
export const getStandAloneFeed = NetworkAction.getStandAloneFeedAction.request
export const reportOrDelete = NetworkAction.reportOrDeleteAction.request
export const getNetworkActivity = NetworkAction.getNetworkActivityAction.request
export const getPeopleToFollow = NetworkAction.getPeopleToFollowAction.request
export const getPeopleYouMayKnow = NetworkAction.getPeopleYouMayKnowAction.request
export const blockAndUnBlock = NetworkAction.blockAndUnblockAction.request
export const toggleReportModal = NetworkAction.toggleReportModalAction.call
export const getBlockedReasons = NetworkAction.getBlockedReasonsAction.request
export const reportFeed = NetworkAction.reportFeedAction.request
export const mutePost = NetworkAction.mutePostAction.request

export const togglePostInRadiusFeed = NetworkAction.togglePostInRadiusFeedAction.call
