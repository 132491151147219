import React, { useEffect } from "react"
import Offer from "container/TechAssets"
import root from "window-or-global"
import { getStateLowerCase } from "dumbComponents/OffersV2/consts"
import app from "container/App"
import OffersV2 from "container/OffersV2/index"
import Listing from "container/Listings"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import OffersList from "./OffersList"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  display: flex;
  padding: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 11px;
  border: 1px solid var(--Old-Cement, #D3DAE8);
  background: #FFF;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
  p {
    color: var(--Typography-General-Primary, #303030);
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
  }
`

const BlueButton = styled.button`
  display: flex;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  border: none;
  background: var(--Color-Primary-Primary, #5A5FF2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 22px 6px 0px rgba(0, 0, 0, 0.00);

  color: var(--Color-Neutral-50, #FDFDFD);
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.5px;
`

const OffersTab = ({
  toggleBasicOfferDetailsModal,
  fetchBuyerOffers,
  data,
  history,
  fetchBuyerOffersResponse,
  setDealToView,
  updateBuyerInformationObject,
  setStateForOffer,
  user,
  updatePropertyInformationObject,
  getOfferStatusMappings,
  setMLSData,
  toggleActiveTab,
  clearOffersRelatedData,
  clearExistingData,
  setOfferIdToView,
}) => {
  const {
    id: transactionId,
    req_id,
    client_id,
    client_source,
    agent_id,
    searchCriteria: {
      locations,
    },
  } = data || {}

  useEffect(() => {
    fetchBuyerOffers({
      transactionId,
      history,
    })
    getOfferStatusMappings()
  }, [])

  const {
    data: buyerOffers,
    isFetching: fetchingBuyerOffers,
  } = fetchBuyerOffersResponse || {}

  return (
    <Wrap>
      <HeaderWrap>
        {buyerOffers && buyerOffers.length > 0 && (
          <BlueButton
            onClick={() => {
              toggleBasicOfferDetailsModal(true)

              setDealToView(null)
              setOfferIdToView(null)
              toggleActiveTab("cover_sheet")
              setMLSData({})

              updatePropertyInformationObject({
                addressRepresentation: false,
                currentState: "california",
                form: {},
                listing_agents: [],
                sellers: [],
                mls_number: "",
              })
              updateBuyerInformationObject({
                agents: [],
                buyers: [],
                lenders: [],
                selectedLender: null,
                selected_lender_id: null,
                vendors: [],
                selectedVendor: null,
                selected_vendor_id: null,
                financingType: null,
                source: "",
                other_source: "",
              })
              clearOffersRelatedData()
              clearExistingData()
              const state = locations[0].state || ""
              let url = `/realty/offers-v3?transaction_id=${transactionId}&req_id=${req_id}&client_id=${client_id}&client_source=${client_source}&agent_id=${agent_id}&currentActiveTab=cover_sheet`
              if (state) {
                const currState = getStateLowerCase(state)
                url += `&state=${currState}`
                setStateForOffer(currState)
              }
              //root.location.href = `${RADIUS_URL}${url}`
              history.push(url)
            }}
          >
            + Write an Offer
          </BlueButton>
        )}
      </HeaderWrap>
      <OffersList
        fetching={fetchingBuyerOffers}
        offers={buyerOffers}
        transactionId={transactionId}
        locations={locations}
        req_id={req_id}
        client_id={client_id}
        client_source={client_source}
        agentId={agent_id}
        clearExistingData={clearExistingData}

      />
    </Wrap>
  )
}

export default app(Listing(Offer(withRouter(OffersV2(OffersTab)))))

