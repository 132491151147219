import React, { useEffect } from "react"
import { required, email } from "services/formUtils"
import {
  Input,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"
import { withRouter } from "react-router-dom"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"
import { RADIUS_TC_OPEN_ESCROW_CONFIRMATION, FIELD_VALUES } from "./data"
import CheckboxComponent from "../Common/RadioGroup"
import SubmitButton from "../Common/SubmitButton"
import {
  Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
} from "../Common/CommonStyles"
import {
  HandleSubmit,
  FunctionalComponentDidMount,
  backButtonUrl,
  normalizePhone,
  BrowserCloseListener,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"
import {
  DETAILS_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../../Routes"

const EscrowFormUI = ({
  ...props
}) => {
  const {
    history,
    id,
    invalid: isInvalid,
    handleSubmit,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    fetchTransactionCoordinationDocumentsResponse,
    destroy,
    formName,
    escrowChoice,
    existingEscrowChoice,
    isFormDisabled,
  } = props || {}

  BrowserCloseListener()

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  const {
    isFetching: creatingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  let completedStepsArray = []

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    completedStepsArray = currentDeal && currentDeal.completed_steps
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName)
  }

  const isProcessing = fetchingValues || creatingForm || updatingTCStage || isFetchingDeals

  let showAllOptions = false

  showAllOptions = (existingEscrowChoice && existingEscrowChoice !== undefined
   && existingEscrowChoice === 1)
    || (escrowChoice !== undefined
     && escrowChoice === "radius_tc_open_escrow_yes") && escrowChoice !== null

  const getLastRoute = lastRouteArray => lastRouteArray.includes("transaction_coordination")
    ? COORDINATION_FORM_ROUTE : TCAGENT_FORM_ROUTE

  return (
    <>
      {fetchingValues ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          <CheckboxComponent
            noButtonReposition
            name="is_radius_tc_to_open_escrow"
            optionArray={RADIUS_TC_OPEN_ESCROW_CONFIRMATION}
            labelText="Radius TC to Open Escrow?"
            marginBottom="31px"
            disabled={isFormDisabled}
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          />
          {showAllOptions && (
          <>
            <Field
              type="email"
              name="escrow_officer_email"
              component={Input}
              placeholder="user@email.com"
              label="Escrow Officer Email"
              validate={[required, email]}
              disabled={isFormDisabled}
              customInputStyle={{
                width: "490px",
              }}
            />
            <Field
              type="phone"
              name="escrow_officer_phone"
              component={Input}
              normalize={normalizePhone}
              placeholder="321-392-1920"
              label="Escrow Officer Phone"
              validate={[required]}
              disabled={isFormDisabled}
              customInputStyle={{
                width: "490px",
              }}
            />
          </>
          )}
          <CTAWrap>
            <BackButton
              type="button"
              onClick={() => {
                //goes previous form
                backButtonUrl(history, id, getLastRoute(completedStepsArray))
              }}
            >
              Back
            </BackButton>
            <SubmitButton
              disabled={isFormDisabled}
              isProcessing={isProcessing}
              text={isFormDisabled ? "Next" : "Continue"}
              isInvalid={isInvalid || !escrowChoice}
            />
          </CTAWrap>
        </FormWrap>
      )}
    </>
  )
}

const EscrowFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  properties,
  props,
  toggleErrorModal,
  fetchTCFiles,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  const disableField = isCompleted || isCoListed || getIsEdit(currentDeal)

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  const {
    is_radius_tc_to_open_escrow,
  } = combinedTCValues || {}

  const {
    is_radius_tc_to_open_escrow: existingEscrowChoice,
  } = currentDeal || {}

  return (
    <Wrap>
      <Header
        text="Escrow / Pre-Escrow / Closing Attorney"
        history={history}
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <EscrowForm
          onSubmit={values => HandleSubmit(
            values,
            UPLOAD_FILE_FORM_ROUTE,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "escrow_pre_escrow",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          id={parsedQuery.id}
          formName="TC_ESCROW_DETAILS_FORM"
          escrowChoice={is_radius_tc_to_open_escrow}
          existingEscrowChoice={existingEscrowChoice}
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const EscrowForm = reduxForm({
  form: "TC_ESCROW_DETAILS_FORM",
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(TransactionContainer(withRouter(EscrowFormUI)))

export default TransactionContainer(withRouter(EscrowFormComponent))
