import React from "react"
import GlobalStatus from "dumbComponents/Offers/Components/GlobalStatus"
import {
  Wrap,
  BadgeTag,
  AddressH1,
  AddressH2,
  StatsDiv,
  AmountDiv,
  LastUpdatedDiv,
  SelectWrap,
  InnerWrap,
  InnerStatsDiv,
} from "./styles"

const PropertyInformationCard = ({
  currentOfferId,
}) => (
  <Wrap>
    <InnerWrap>
      <AddressH1>
        <h1>3928 Scottfield St</h1>
        <BadgeTag>
          <p>ACTIVE</p>
        </BadgeTag>
      </AddressH1>
      <AddressH2>
        <h2>Dublin, CA 94568</h2>
      </AddressH2>
      <StatsDiv>
        <InnerStatsDiv>
          <img src={`${CLOUDFRONT}/LISTING_V2/Beds.svg`} alt="bathroom.svg" />
          <p>
            <span>4</span>
            {" "}
            beds
          </p>
        </InnerStatsDiv>

        <InnerStatsDiv>
          <img src={`${CLOUDFRONT}/LISTING_V2/Bathroom.svg`} alt="Bathroom.svg" />
          <p>
            <span>4</span>
            {" "}
            baths
          </p>
        </InnerStatsDiv>

        <InnerStatsDiv>
          <img src={`${CLOUDFRONT}/LISTING_V2/SquareInch.svg`} alt="squareInch.svg" />
          <p>
            <span>2,208</span>
            {" "}
            sqft
          </p>
        </InnerStatsDiv>
      </StatsDiv>
    </InnerWrap>

    <InnerWrap
      style={{
        alignItems: "flex-end",
      }}
    >
      <AmountDiv>
        <h1>$500K</h1>
      </AmountDiv>
      <SelectWrap>
        <GlobalStatus offerIdForStatus={currentOfferId} />
      </SelectWrap>
      <LastUpdatedDiv>
        <p>Last Updated xx/xx/xxxx</p>
      </LastUpdatedDiv>
    </InnerWrap>
  </Wrap>
)

export default PropertyInformationCard
