import React from "react"
import { reduxForm } from "redux-form"
import SearchIcon from "images/icons/search-icon.svg"
import Box from "@ui/Box"
import {
  Wrapper,
  SearchInputWrap,
  TagsWrap,
  Tag,
  StyledButton,
  Footer,
  BlankButton,
  Header,
  SaveButton,
  ProfileCompletionButton,
} from "./commonStyles"

class Interests extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues } = this.props
    this.state = {
      interestList: initialValues,
      isChanged: false,
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  addItem = () => {
    const { search, interestList = [] } = this.state
    if (search) {
      this.setState({
        interestList: [...interestList, search],
        search: "",
        isChanged: true,
      })
    }
  }

  handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      this.addItem()
    }
  }

  handleSave = () => {
    const { saveProfileUpdate } = this.props
    const { interestList, search } = this.state
    const newItem = []
    if (search) {
      newItem.push(search)
    }
    saveProfileUpdate({
      interests: [...interestList, ...newItem],
    })
  }

  removeItem = (item) => {
    const { interestList = [] } = this.state
    const itemIndex = interestList.indexOf(item)
    interestList.splice(itemIndex, 1)
    this.setState({
      interestList: [...interestList],
      isChanged: true,
    })
  }

  render() {
    const {
      handleSubmit,
      isSavingUpdates,
      isProfileCompletion,
      nextStep,
      nextToNextStep,
      skipProfileCompletionStep,
    } = this.props
    const { search, interestList, isChanged } = this.state
    return (
      <Wrapper>
        <Header>
          <h4>What are your interests?</h4>
        </Header>
        <Box d="flex" p="15px">
          <SearchInputWrap onSubmit={handleSubmit} flex="1">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              value={search}
              name="search"
              component="input"
              type="text"
              placeholder="Enter your interests. ex Biking, swiming ..."
              onChange={this.handleInput}
              onKeyPress={this.handleEnterPress}
            />
          </SearchInputWrap>
          <StyledButton
            bsStyle="secondary"
            width="95"
            type="button"
            onClick={() => { this.addItem() }}
          >
            Add +
          </StyledButton>
        </Box>
        <TagsWrap>
          {interestList && interestList.length > 0 && interestList.map(item => (
            <Tag
              key={item}
              title={item}
              handleClick={this.removeItem}
            />
          ))}
        </TagsWrap>
        <Footer
          d="flex"
          style={{
            justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
            padding: 15,
          }}
        >
          {isProfileCompletion && nextToNextStep && (
            <BlankButton onClick={() => { skipProfileCompletionStep(nextStep) }} normal>
              Skip
            </BlankButton>
          )}
          { isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              width="100px"
              isShowLoader={isSavingUpdates}
              onClick={this.handleSave}
              disabled={!isChanged}
            >
              Save
            </SaveButton>
          )}
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "PROFILE_INTEREST_FORM",
})(Interests)

