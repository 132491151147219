import styled from "styled-components"

const RoundedTag = styled.span`
  margin: 0px 5px 10px 0px;
  font-size: 12px;
  padding: 5px 10px;
  min-width: 120px;
  font-family: 'DM Sans', sans-serif;
  border-radius: 15px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #ffffff;
  color: #354052;
  border: 1px solid #354052;
`

export default RoundedTag
