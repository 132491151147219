import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { copyAppInviteLinkAction, fetchAllClientsAction, fetchRecommendedPropertiesAction, getAppStatusAction, inviteBulkClientsAction, inviteClientsAction, isMelRecommendedAction, joinWaitlistAction, rejectPropertyStatusAction, removeBulkPropertyAction, turnOnMelRecommendationsAction } from "./actions"

const mapStateToProps = (state) => {
  const { consumerApp } = state
  return {
    ...consumerApp,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchAllClients: fetchAllClientsAction.request,
      copyAppInviteLink: copyAppInviteLinkAction.request,
      inviteBulkClients: inviteBulkClientsAction.request,
      joinWaitlist: joinWaitlistAction.request,
      inviteClient: inviteClientsAction.request,
      getAppStatus: getAppStatusAction.request,
      turnOnMelRecommendation: turnOnMelRecommendationsAction.request,
      fetchRecommendedProperties: fetchRecommendedPropertiesAction.request,
      rejectPropertyStatus: rejectPropertyStatusAction.request,
      removeBulkProperty: removeBulkPropertyAction.request,
      isMelRecommendedOn: isMelRecommendedAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
