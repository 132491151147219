import React from "react"
import OffersV2 from "container/OffersV2/index"
import { TrashIcon, EyeIcon, ShareIcon } from "dumbComponents/OffersV2/icons"
import CheckboxList from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/index"
import {
  SectionWrap,
  SectionTitle,
  EnvelopeTitle,
} from "./styles"

const Attachments = ({
  completeOfferPackageToBeSent,
}) => {
  const {
    envelopes,
    proofOfFundsDocs,
    coverLetters,
    preQualified,
  } = completeOfferPackageToBeSent || {}

  const OptionList2 = [
    {
      label: "VIEW",
      onClick: () => console.log("docs"),
    },
  ]

  const MainListV2 = [
    {
      label: "",
      image: <TrashIcon />,
      onClick: () => console.log("clicked send"),
      showDivide: false,
    },
    {
      label: "",
      image: <ShareIcon />,
      onClick: () => console.log("clicked send"),
      showDivide: false,
    },
    {
      label: "",
      image: <EyeIcon />,
      onClick: () => console.log("clicked view"),
      showDivide: false,
    },
  ]

  return (
    <SectionWrap
      style={{
        marginTop: "38px",
      }}
    >
      <SectionTitle>ATTACHMENTS</SectionTitle>
      {envelopes && envelopes.length > 0 && envelopes.map((item, iter) => (
        <>
          <EnvelopeTitle>
            <p>{`ENVELOPE ${iter + 1}`}</p>
          </EnvelopeTitle>
          <CheckboxList
            list={item.documents}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            hideCheckBox
            img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
          />
        </>
      ))}

      {proofOfFundsDocs && proofOfFundsDocs.length > 0 && (
        <>
          <EnvelopeTitle>
            <p>PROOF OF FUNDS</p>
          </EnvelopeTitle>
          <CheckboxList
            list={proofOfFundsDocs}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            hideCheckBox
            img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
          />
        </>
      )}

      {coverLetters && coverLetters.length > 0 && (
        <>
          <EnvelopeTitle>
            <p>COVER LETTERS</p>
          </EnvelopeTitle>
          <CheckboxList
            list={coverLetters}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            hideCheckBox
            img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
          />
        </>
      )}

      {preQualified && preQualified.length > 0 && (
        <>
          <EnvelopeTitle>
            <p>PRE APPROVED</p>
          </EnvelopeTitle>
          <CheckboxList
            list={preQualified}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            hideCheckBox
            img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
          />
        </>
      )}
    </SectionWrap>
  )
}

export default OffersV2(Attachments)
