import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import Modal from "@ui/Modal"
import Button from "@ui/Button"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import CircularImage from "dumbComponents/common/CircularImage"
import { checkForUserObject, getProfileUrl } from "services/Utils"
import icons from "@icons"

const user = checkForUserObject("user")
const agentId = user && user.id

const Wrapper = styled.div`
  position: relative;
`

const LikersList = styled.ul`
  list-style-type: none;
`

const LikersListItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f8f9fa;
  padding: 10px;
`

const Image = styled(CircularImage)`
  flex: 1 auto;
`

const UserInfo = styled.div`
  flex: 1;
  margin-left: 10px;
`

const Follow = styled.div`
  width: 120px;
  text-align: center;
`

const StyledButton = styled(Button)`
  font-size: 12px;
  width: 100px;
  font-weight: 500;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Likers = ({
  show,
  close,
  likers,
  followAgent,
  requestingAgentToFollow,
  unfollowAgent,
  isFetchingLikers,
  type,
}) => (
  <Modal
    show={show}
    toClose={close}
    modalStyles={{ padding: 5, marginTop: isFetchingLikers ? "50px" : "68px", marginBottom: "15px" }}
    loading={isFetchingLikers}
    title={type === "question" ? "UPVOTES" : "LIKES"}
  >
    <Wrapper>
      <LikersList>
        {likers
          && likers.map(liker => (
            <LikersListItem>
              <StyledLink to={getProfileUrl(liker)}>
                <Image
                  size={50}
                  image={liker.agent_image}
                  name={`${liker.firstname} ${liker.lastname || ""}`}
                  alt={`${liker.firstname} ${liker.lastname || ""}`}
                />
              </StyledLink>
              <UserInfo>
                <StyledLink to={getProfileUrl(liker)}>
                  <Paragraph size={13} lineHeight={1.46} fontWeight={500}>
                    {`${liker.firstname} ${liker.lastname || ""}`}
                  </Paragraph>
                </StyledLink>
                <Paragraph size={13} lineHeight={1.46} fontWeight={300}>
                  {liker.agency_name}
                </Paragraph>
                {(liker.office_city || liker.office_state) && (
                  <Paragraph size={13} lineHeight={1.46} fontWeight={300}>
                    <i className={icons.locationLocationPoint} />
                    &nbsp;
                    {`${liker.office_city || ""}${liker.office_state ? `, ${liker.office_state}` : ""}`}
                  </Paragraph>
                )}
              </UserInfo>
              {agentId !== liker.id && (
                <Follow>
                  {liker.isFollowing ? (
                    <StyledButton bsStyle="primary" onClick={() => unfollowAgent(liker.id)}>
                      {requestingAgentToFollow === liker.id ? "Unfollowing..." : "Following"}
                    </StyledButton>
                  ) : (
                    <StyledButton
                      bsStyle="secondary"
                      onClick={() => followAgent({ agent: liker, shouldFollowOnly: true })}
                    >
                      {requestingAgentToFollow === liker.id ? "Following..." : "Follow"}
                    </StyledButton>
                  )}
                </Follow>
              )}
            </LikersListItem>
          ))}
      </LikersList>
    </Wrapper>
  </Modal>
)

export default Likers
