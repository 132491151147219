import { bindActionCreators } from "redux"
import { connect } from "react-redux"
// import { getIsOnboardingCompleteFromStore } from "./selector"
import {
  profileSubmitReferralAction,
} from "container/StandAlone/SubmitReferral/actions"
import {
  getSearchedAgents,
  toggleFilterPanel,
  removeAndApplySearchFilter,
  clearAllSearchFilters,
  removeAndApplySingleFilter,
  getClientsByName,
} from "./actionCreators"
import * as SearchSelectors from "./selector"

const mapStateToProps = state => ({
  isFetchingAgents: SearchSelectors.getIsFetchingAgentsFromStore(state),
  agents: SearchSelectors.getAgentsFromStore(state),
  agentsTotalCount: SearchSelectors.getagentsTotalCountFromStore(state),
  facades: SearchSelectors.getFacadesFromStore(state),
  totalPages: SearchSelectors.getTotalPagesFromStore(state),
  currentPageNumber: SearchSelectors.getCurrentPageNumberFromStore(state),
  keyedFacades: SearchSelectors.getKeyedFacadesFromStore(state),
  appliedFiltersKeyPayload: SearchSelectors.getAppliedFiltersKeyPayload(state),
  searchedCity: SearchSelectors.getCityFromStore(state),
  appliedSingleFilterPayload: SearchSelectors.getAppliedSingleFilterPayload(state),
  profileSubmitReferral: profileSubmitReferralAction.call,
  isFetchingClientsByName: SearchSelectors.getIsFetchingClientsByNameFromStore(state),
  clientsByName: SearchSelectors.getClientsByNameFromStore(state),
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getSearchedAgents,
    toggleFilterPanel,
    removeAndApplySearchFilter,
    clearAllSearchFilters,
    removeAndApplySingleFilter,
    getClientsByName,
  },
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
