import React, {useState} from "react";
import {
  Header,
  SubHeading,
  Flex,
  Block,
  Info,
  InfoData,
  ScoreCard,
  SchoolName,
  ScoreWrap,
  ScoreNumber,
  ScoreOutOf,
  Grades,
  ListBullet,
  ButtonShow
} from "./styles.js";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const NearByRestaurants = ({ listingPageResponse }) => {
  const [showAllEntries, setShowAllEntries] = useState(false);

  const initialEntryCount = 3;

  const toggleShowAllEntries = () => {
    setShowAllEntries(!showAllEntries);
  };

  const renderEntries = () => {
    const entryCount = showAllEntries
      ? listingPageResponse?.data?.nearbyRestaurants?.length
      : initialEntryCount;
    return listingPageResponse?.data?.nearbyRestaurants
      .slice(0, entryCount)
      .map((school) => (
        <Flex key={school.name}>
          <ScoreWrap>
                <ScoreCard>
                  <ScoreNumber>{school?.rating}</ScoreNumber>
                  <ScoreOutOf>/5</ScoreOutOf>
                </ScoreCard>
                <Block>
                  <SchoolName>{school.name}</SchoolName>
                  <Flex>
                    <Grades>Total User Rating: </Grades>
                    <Grades style={{ fontWeight: 600 }}>
                      {" "}
                      &nbsp;{school.user_ratings_total}
                    </Grades>
                  </Flex>
                </Block>
              </ScoreWrap>
        </Flex>
      ));
  };
  return (
    <>
      {listingPageResponse?.data?.nearbyRestaurants?.length > 0 && (
        <>
          <Header>Nearby Restaurants</Header>
          {renderEntries()}
          {listingPageResponse?.data?.nearbyRestaurants?.length >
            initialEntryCount && (
            <ButtonShow onClick={toggleShowAllEntries}>
              {showAllEntries ? (<>Show Less <UpOutlined style={{marginTop: "1px", marginLeft: "5px"}} /></>) : (<>Show More <DownOutlined style={{marginTop: "1px", marginLeft: "5px"}} /></>)}
            </ButtonShow>
          )}
        </>
      )}
      <div style={{marginBottom: "48px"}}></div>
    </>
  );
};

export default ListingPageContainer(NearByRestaurants);
