import React from "react"
import styled from "styled-components"
import {
  Field,
  FieldArray,
  reduxForm,
  reset
} from "redux-form"
import { required } from "services/formUtils"
import colors from "@colors"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import PlusIcon from "images/icons/PlusIcon4x.png"
import DeleteIcon from "images/icons/DeleteIcon.png"
import DisabledPlusIcon from "images/icons/DisabledPlusIcon.svg"

const Form = styled.form`
  span {
    font-size: 11px;
    color: red;
  }
`

const SubmitButton = styled.button`
  cursor: pointer;
  background: transparent;
  height: 48px;
  width: 116px;
  border-radius: 100px;
  border: 2px solid ${colors.deepPurple};
  color: ${colors.deepPurple};
  position: relative;
  left: ${props => props.submitButtonLeftPosition || "460px"};
  top: -34px;
`

const AddAnotherEmailButton = styled.button`
  cursor: pointer;
  background: transparent;
  height: 48px;
  width: max-content;
  padding: 16px 16px 16px 0px;
  border: none;
  color: ${colors.deepPurple};
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  top: 12px;

  img {
    height: 12px;
    position: relative;
    top: 1.8px;
    left: 2px;
  }

  ${props => props.disabled && `
    cursor: not-allowed;
    color: ${colors.otterGrey};
  `}
`

const InputBox = styled.input`
  height: 54px;
  width: 100%;
  border-radius: 8px;
  padding: 15px 16px 15px 16px;
  border: 1px solid ${colors.black};

  ${props => props.customBorder && `
    border: 1px solid #96A3BD;
  `}

  ${props => props.isError && `
    border: 1px solid ${colors.errorDanger};
  `}

  &::placeholder {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #C5C5C5;
  }

  ${props => props.isOnboarding && `
     margin-bottom: 16px;
  `}

  ${props => props.topMargin && `
    margin-top: 16px;
  `}
`

const RemoveButton = styled.button`
  position: relative;
  top: 40px;
  left: ${props => props.customRemoveButtonLeft || "440px"};
  border: none;
  cursor: pointer;
  background: transparent;
  filter: contrast(0.5);

  img {
    height: 20px;
    position: relative;
    left: ${props => props.inFeed ? "9px" : "88px"};
  }
`

const SubmitText = styled.p`
  margin: 0;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`

const Disabled = styled.img`
  filter: contrast(0.5);
`

const afterSubmit = (result, dispatch) => {
  dispatch(reset("EmailArrays"))
}

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  isOnboarding,
  topMargin,
  customBorder,
}) => (
  <div>
    <InputBox
      topMargin={topMargin}
      isOnboarding={isOnboarding}
      isError={touched && error}
      {...input}
      type={type}
      placeholder={label}
      customBorder
    />
    {touched && error && <span>{error}</span>}
  </div>
)

const renderEmails = ({
  fields,
  meta: { error, submitFailed, touched },
  inFeed,
  customRemoveButtonLeft,
  customBorder,
}) => (
  <ul>
    {fields && fields.map((emails, index) => (
      <li key={index}>
        <RemoveButton
          isError={touched && error}
          inFeed={inFeed}
          type="button"
          customRemoveButtonLeft={customRemoveButtonLeft}
          onClick={() => fields.remove(index)}
        >
          <img src={DeleteIcon} alt="deleteIcon" />
        </RemoveButton>
        <Field
          name={`${emails}_email`}
          type="email"
          component={renderField}
          label="Enter e-mail address"
          validate={required}
          customBorder={customBorder}
        />
        {submitFailed && error && <span>{error}</span>}
      </li>
    ))}
    <li>
      <AddAnotherEmailButton disabled={fields.length > 3} type="button" onClick={() => fields.push({})}>
        Add Another
        {" "}
        <span>
          {fields.length > 3 ? (
            <Disabled src={DisabledPlusIcon} alt="deleteIcon" />
          ) : (
            <img src={PlusIcon} alt="plusIcon.png" />
          )}
        </span>
      </AddAnotherEmailButton>
    </li>
  </ul>
)

const EmailArrayComponent = ({
  ...props
}) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    submitButtonLeftPosition,
    isSubmittingInvites,
    inFeed,
    isOnboarding,
    customRemoveButtonLeft,
    customBorder,
  } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="initial_email"
        type="email"
        component={renderField}
        label="Enter e-mail address"
        validate={required}
        customBorder={customBorder}
      />
      {isOnboarding && (
        <>
          <Field
            name="onboarding_email_2"
            type="email"
            component={renderField}
            label="Enter e-mail address"
            isOnboarding
            topMargin
            customBorder={customBorder}
          />
          <Field
            name="onboarding_email_3"
            type="email"
            component={renderField}
            label="Enter e-mail address"
            customBorder={customBorder}
          />
        </>
      )}
      <FieldArray
        name="emails"
        component={renderEmails}
        props={{
          inFeed,
          isOnboarding,
          customRemoveButtonLeft,
          customBorder,
        }}
      />
      <div>
        <SubmitButton
          submitButtonLeftPosition={submitButtonLeftPosition}
          type="submit"
          disabled={submitting}
        >
          {isSubmittingInvites ? (
            <ThreeDotLoader size={6} color={colors.deepPurple} />
          ) : (
            <SubmitText>Send Invite</SubmitText>
          )}
        </SubmitButton>
      </div>
    </Form>
  )
}

export default reduxForm({
  form: "EmailArrays",
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(EmailArrayComponent)
