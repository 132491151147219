import React from "react"
import {
  RecruitmentSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const PostAJobTab = ({
  routeArray,
  isRecruitmentDashboardNewTag,
}) => (
  <InnerTab isActive={routeArray[1] === "recruitment"}>
    <div className="icon-block-new">
      <IconEle
        scale="1.5"
        src={RecruitmentSVGIcon}
        alt="RARhome"
        isActive={routeArray[1] === "recruitment"}
      />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/recruitment"
        isActive={routeArray[1] === "recruitment"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_RecruitmentRAR_web")
          }
        }}
      >
        Post a Job
      </NavLink>
    </div>
  </InnerTab>
)

export default PostAJobTab
