import { createSelector } from "reselect"

const selectAgentId = state => state.app.user && state.app.user.id
const selectRoomPayload = state => state.rooms.getRoomsResponse.data && state.rooms.getRoomsResponse.data[0]
const membersResponse = state => state.rooms.getRoomMembersActionResponse.data
const getRoomsResFull = state => state.rooms.getRoomsResponse.data && state.rooms.getRoomsResponse
const getJoinResFull = state => state.rooms.getRoomsResponse.data && state.rooms.joinRoomResponse

export const getIsHost = createSelector([
  selectAgentId,
  selectRoomPayload,
], (agentId, roomPayload) => {
  const { host_id } = roomPayload || {}
  return host_id === agentId
})

export const getFailureState = createSelector([
  getRoomsResFull,
  getJoinResFull,
], (roomsPayload, joinRoomRes) => {
  const { error } = roomsPayload || {}
  const { status } = error || {}
  if (status === 409) {
    return status
  }
  const { isError: joinError } = joinRoomRes || {}
  return joinError
})
