import * as React from "react"

const JobBoard = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21.42 11.607a1 1 0 0 0-.019-1.838L12.83 5.865a2 2 0 0 0-1.66 0l-8.57 3.9a1 1 0 0 0 0 1.832l8.57 3.908a2 2 0 0 0 1.66 0l8.59-3.898ZM22 10.685v6"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 13.185v3.5c0 .796.632 1.559 1.757 2.121 1.126.563 2.652.88 4.243.88 1.591 0 3.117-.317 4.243-.88 1.125-.562 1.757-1.325 1.757-2.12v-3.5"
      />
    </svg>
  )
}

export default JobBoard
