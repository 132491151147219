import styled from "styled-components";
import colors from "@colors";
import { INTER } from "@fonts";

const Container = styled.div`
  width: 100vw;
  margin: 0 auto;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${colors.new_lightBackground};
  @media (max-width: 1024px) {
    padding-top: 25px;
    display: block;
    padding-bottom: 100px;
    margin-bottom: 0px;
  }
`;
const NoDataWrapper = styled.div`
  margin: 0 auto;
  width: 460px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 6px 15px -2px rgba(16, 24, 40, 0.08),
    0px 6px 15px -2px rgba(16, 24, 40, 0.08);
  @media (max-width: 1024px) {
    width: 80vw;
    max-width:460px;
    padding: 10px;
    top: 35%;
    position: relative;
  }

`;

const StyledImage = styled.img`
  width: 79px;
  height: 76px;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;

const NoData = styled.div`
  color: ${colors.faintBlueishBlack};
  text-align: center;
  font-family: ${INTER};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const Description = styled.p`
  color: ${colors.textDisabledColor};
  text-align: center;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export { Container, StyledImage, Description, NoDataWrapper, NoData };
