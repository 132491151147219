import React, { useEffect } from "react"
import styled from "styled-components"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import { ATC, INTER } from "@fonts"
import colors from "../../../library/colors.js"

const FormWrapper = styled.div`
  #hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 {
    font-family: ${INTER};
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 label span{
        display: none;
    }

    
#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .input input{
        border: 1px solid #5A5FF2;
        width: 95%;
        border-radius: 4px;
        height: 40px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    #hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .input select{
        border: 1px solid #5A5FF2;
        width: 95%;
        border-radius: 4px;
        height: 40px;
        padding-left: 10px;

        font-family: inherit;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;

        margin-bottom: 10px;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs-submit .actions input {
    width: 100%;
    height: 42px;
    flex-shrink: 0;
    margin-top: 30px;
    border-radius: 24.5px;
    background: #1C1C1F;
    color: white;
    cursor: pointer;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_terms___conditions .input input{
    width: unset;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_terms___conditions {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    padding-top: 15px;
    position: relative;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_terms___conditions .inputs-list{
  list-style: none;
  padding: 0;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_terms___conditions .hs-error-msgs  {
  position: absolute;
  bottom: -25px;
  right: 10px;
  font-size: 10px;
  color: red;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_terms___conditions .hs-field-desc {
    line-height: 19px;
    font-weight: 400;
    font-size: 13px;
}

#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs-error-msg {
  color: #EF4444;
  // color: #EF4444;
  color: ${colors.new_errorRedColor};
}


#hsForm_80767e3a-3a65-4245-b481-e96845e1dcf2 .hs_error_rollup {
    position: absolute;
    right: 0;
    display: none;
}
`

const HubspotContactForm = (props) => {
  const { region, portalId, formId, toggleMQLFormPostSubmit } = props
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: "#hubspotForm",
        })
      }
    })

    window.addEventListener("message", (event) => {
      if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormSubmitted") {
        console.log("CALL SAGA HERE")
        toggleMQLFormPostSubmit(true)
      }
    })
  }, [])

  return (
    <FormWrapper>
      <div id="hubspotForm"></div>
    </FormWrapper>
  )
}

export default ApplyFlowContainer(HubspotContactForm)
