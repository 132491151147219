import React from "react"
import cookie from "services/CookieStorage"
import Listings from "container/Listings/index"
import { withRouter } from "react-router-dom"
import {
  Wrap,
  HeaderWrapH1,
  CreateListingButtonWrap,
} from "../commonStyles"

const ListingsHeader = ({
  toggleListingCreationModal,
  history,
  handleCollapseValues,
  clearExistingData,
  updateCurrentListingData,
}) => (
  <Wrap
    style={{
      padding: "20px 48px 30px 24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      borderBottom: "2px solid #DCDEFF",
    }}
  >
    <HeaderWrapH1>
      <h1>
        {
          localStorage.getItem("listing_type_dropdown")?.length
            ? localStorage.getItem("listing_type_dropdown")
            : "Your Listings"
        }
      </h1>
      <h2>Keep track of your earnings and total listings.</h2>
    </HeaderWrapH1>
    <CreateListingButtonWrap>
      <button
        type="button"
        // style={{display: "flex", alignItems: "center", justifyContent: "center"}}
        onClick={() => {
          //if team then show modal else
          const teamExists = cookie.load("brokerageTeamId") || ""
          const agentId = cookie.load("agentId")
          clearExistingData()
          handleCollapseValues(["property_information"])
          updateCurrentListingData({
            dealId: null,
            editingAgentId: agentId,
            transactionId: null,
            listingStatus: "incomplete",
            propertyData: null,
            agents: null,
            sellers: null,
            listingTerms: null,
            propertyDataErrorCount: null,
            agentsDataErrorCount: null,
            sellersDataErrorCount: null,
            listingTermsErrorCount: null,
          })
          if (teamExists && teamExists !== "null" && teamExists !== undefined) {
            toggleListingCreationModal(true)
          } else {
            history.push("/listings/form")
          }
        }}
      >
        <span style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img src={`${CLOUDFRONT}/LISTING_V2/add.svg`} alt="add.svg" />
        </span>
        <span>
          New Listing
        </span>
      </button>
    </CreateListingButtonWrap>
  </Wrap>
)

export default withRouter(Listings(ListingsHeader))
