import * as React from "react";
const CoAgentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1108 15.3125H6.38917C5.62167 15.3125 5 14.6908 5 13.9233V4.20167C5 3.43417 5.62167 2.8125 6.38917 2.8125H16.1117C16.8783 2.8125 17.5 3.43417 17.5 4.20167V13.9242C17.5 14.6908 16.8783 15.3125 16.1108 15.3125V15.3125Z"
      stroke="#111827"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7233 6.28414C13.5367 7.09747 13.5367 8.41664 12.7233 9.23081C11.91 10.0441 10.5908 10.0441 9.77666 9.23081C8.9625 8.41747 8.96333 7.09831 9.77666 6.28414C10.59 5.46997 11.9092 5.47081 12.7233 6.28414"
      stroke="#111827"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13.1741C14.8908 12.8999 14.7217 12.6532 14.505 12.4524V12.4524C14.1392 12.1124 13.66 11.9241 13.16 11.9241C12.3267 11.9241 10.1733 11.9241 9.34 11.9241C8.84 11.9241 8.36167 12.1132 7.995 12.4524V12.4524C7.77833 12.6532 7.60917 12.8999 7.5 13.1741"
      stroke="#111827"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15.3125V16.4233C15 17.1908 14.3783 17.8125 13.6108 17.8125H3.88917C3.12167 17.8125 2.5 17.1908 2.5 16.4233V6.70167C2.5 5.93417 3.12167 5.3125 3.88917 5.3125H5"
      stroke="#111827"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CoAgentIcon;
