/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect } from "react"
import {
  Form,
  NewRadioButton,
  NewRadioGroup,
  Space,
} from "@ui/antd"
import Listings from "container/Listings/index"
import './styles.css'
import {
  getState,
} from "dumbComponents/OffersV3/consts"
import {
  InputField,
  InputLocation,
  SelectField,
  HiddenInputField,
  InputNumber,
  SizeInputGroup,
  FloatingCTA,
  MLSwithAddress,
} from "dumbComponents/common/InputFields"
import {
  FormWrap,
  SubmitWrap,
  ValidationNumberCircle,
  ExtraWrap,
  RadioWrap,
  RadioQuestion,
  ExtraFormUIWrap,
  EditPropertyText,
} from "./commonStyles"
import {
  STATE_OPTIONS,
  PROPERTY_MEASURED_AS,
  DEFAULT_TRANSACTION_TYPE,
} from "./formConfig"

const PropertyInformationForm = ({
  onFinishFailed,
  handleSubmit,
  prefilledData,
  onValueChange,
  saving,
  disableForm,
  key,
  loading,
  handleInitPropertyChange,
  togglePropertyFormOrCardView,
  handleMLSChange,
  listingData,
  getListingMappings,
  getListingMappingsResponse,
  form,
  setCurrentListingsState,
  reduxState,
  formRef,
}) => {
  const {
    mls_id: MlsID,
  } = prefilledData || {}
  const [isMLSVisible, toggleMLS] = useState(MlsID ? "yes" : "no")
  const [currentState, setCurrentState] = useState("California")
  const {
    data: mappings,
  } = getListingMappingsResponse || {}

  const {
    dealId,
  } = listingData || {}

  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData && Object.keys(prefilledData).length > 0) {
      initValues = prefilledData
    }
    return initValues
  }

  useEffect(() => {
    if (prefilledData && prefilledData.state) {
      const currState = prefilledData.state
      setCurrentState(currState)
    }
    console.log( "check here",prefilledData)
  }, [prefilledData])

  useEffect(() => {
    if (reduxState) {
      const state = reduxState.charAt(0).toUpperCase() + reduxState.slice(1)
      console.log("state",reduxState,state)
      getListingMappings({state})
      setCurrentState(state)
    }
  }, [reduxState])

  useEffect(() => {
    if (listingData?.propertyData?.mls_id) {
      toggleMLS('yes');
    }
  },[listingData])
  useEffect(() => {
    if (isMLSVisible === "no") {
      //reset all values if any
      togglePropertyFormOrCardView(false)
    }
  }, [isMLSVisible])

  const getTransactionTypes = () => {
    if (!currentState) return DEFAULT_TRANSACTION_TYPE
    if (!mappings) return DEFAULT_TRANSACTION_TYPE
    if (mappings === undefined) return DEFAULT_TRANSACTION_TYPE
    if (mappings === null) return DEFAULT_TRANSACTION_TYPE
    if (mappings && !mappings.listing_type) return DEFAULT_TRANSACTION_TYPE
    if (mappings && mappings.listing_type && mappings.listing_type.length <= 0) return DEFAULT_TRANSACTION_TYPE

    const currentMappings = mappings.listing_type.filter(item => item.state === currentState)

    if (currentMappings.length <= 0) return DEFAULT_TRANSACTION_TYPE

    return currentMappings[0].values || DEFAULT_TRANSACTION_TYPE
  }

  const [extraFormUI, setExtraFormUIVisible] = useState(false)

  useEffect(() => {
    if(prefilledData?.mls_unique_id != null){
      toggleMLS("yes")
    }
    else{
      toggleMLS("no")
      setExtraFormUIVisible(false)
    }
  }, [prefilledData?.mls_unique_id])

  const toggleMLSSwitch = (value) => {
    if(value === "yes"){
      toggleMLS(value);
      setExtraFormUIVisible(true);
    }
    else{
      toggleMLS(value);
      setExtraFormUIVisible(false);
    }
  }

  return (
    <ExtraWrap>
      <RadioWrap>
        <RadioQuestion>
          <p>Is the property listed on MLS?</p>
        </RadioQuestion>
        <NewRadioGroup
          onChange={e => toggleMLSSwitch(e.target.value)}
          value={isMLSVisible}
          size="small"
        >
          <NewRadioButton value="yes">Yes</NewRadioButton>
          <NewRadioButton value="no">No</NewRadioButton>
        </NewRadioGroup>
      </RadioWrap>

      {prefilledData?.mls_unique_id && !extraFormUI && <EditPropertyText onClick={() => {
        setExtraFormUIVisible(true);
        toggleMLS("yes");
      }}>Add a new MLS property</EditPropertyText>}

      {isMLSVisible === "yes" && extraFormUI && (
        <ExtraFormUIWrap>
          <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <MLSwithAddress callback={handleMLSChange} />
            {MlsID && <div style={{ textAlign: "center" }}>MLS ID - {MlsID}</div>}
          </Space>
        </ExtraFormUIWrap>
      )}


      <FormWrap
        id={`${key}_wrap`}
        key={key}
      >
        <Form
          name="property_information"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={getInitialValues()}
          onKeyDown={(e) => { e.stopPropagation() }}
          onValuesChange={onValueChange}
          ref={formRef}
          disabled={disableForm}
          id={`${key}_form`}
          key={key}
          form={form}
        >
          <SelectField
            isFormField
            name="state"
            options={STATE_OPTIONS}
            placeholder="State"
            text="State"
            required={true}
            requiredMessage="State is required."
            onChange={(value) => {
              setCurrentState(value)
              setCurrentListingsState(value.toLowerCase())
            }}
          />

          <InputNumber
            name="price"
            inputType="money"
            type="money"
            placeholder="Current Listing Price"
            text="Current Listing Price"
            required={true}
            requiredMessage="Current listing price is required"
          />

          {(currentState !== "Florida"
          && currentState !== "California"
          && currentState !== "Texas"
          && currentState !== "Washington"
          && currentState !== "Oregon"
          && currentState !== "Colorado"
          && currentState !== "Georgia"
          ) ? (
            <>
              <InputNumber
                name="bedrooms"
                placeholder="Beds*"
                text="Beds"
                required={true}
                requiredMessage="Required"
              />

              <InputNumber
                name="bathrooms"
                placeholder="Baths*"
                text="Baths"
                required={true}
                requiredMessage="Required"
              />
            </>
            ) : (
              <>
                <InputNumber
                  name="bedrooms"
                  placeholder="Beds"
                  text="Beds"
                />

                <InputNumber
                  name="bathrooms"
                  placeholder="Baths"
                  text="Baths"
                />
              </>
            )}

          <SizeInputGroup
            placeholder="Enter Size"
            inputType="text"
            selectDefaultValue="Sq.Ft"
            isFormField
            name="area"
            options={PROPERTY_MEASURED_AS}
            text="Size"
            selectName="area_unit"
          />

          <InputLocation
            name="street"
            inputType="text"
            type="text"
            placeholder="Street Address"
            formRef={formRef}
            text="Street Address"
            requiredMessage="Street address is required"
            required={true}
            handleInitChange={handleInitPropertyChange}
          />

          <InputField
            name="city"
            inputType="text"
            placeholder="City"
            text="City"
            requiredMessage="City is required"
            required={true}
          />

          <InputField
            name="apt"
            inputType="text"
            placeholder="Apartment/Unit/Suite No."
            text="Apartment/Unit/Suite No."
          />

          <InputField
            name="county"
            inputType="text"
            placeholder="County"
            requiredMessage="County is required"
            text="County"
            required={true}
          />

          <InputField
            name="zipcode"
            inputType="text"
            placeholder="Zip Code"
            text="Zip Code"
            requiredMessage="Zip code is required"
            required={true}
          />

          <SelectField
            isFormField
            name="transaction_type"
            options={getTransactionTypes()}
            placeholder="Listing Type"
            text="Listing Type"
            required={true}
            requiredMessage="Listing type is required"
          />

          {(currentState === "California") && <InputField
            name="apn_number"
            inputType="text"
            placeholder="APN Number"
            text="APN Number"
            //required={true}
          />}

          {(currentState === "Colorado" || currentState === "Florida") && (
            <InputField
              name="legal_description"
              inputType="text"
              placeholder="Legal Description"
              text="Legal Description"
              requiredMessage="Legal description is required"
              required={true}
            />
          )}

          {currentState === "Florida" && (
            <InputField
              name="tax_id_number"
              inputType="text"
              placeholder="Tax ID Number"
              text="Tax ID Number"
              //required={true}
            />
          )}

          {currentState === "Texas" && (
            <>
              <InputField
                name="lot"
                inputType="text"
                placeholder="Lot"
                text="Lot"
                requiredMessage="Lot is required"
                required={true}
              />

              <InputField
                name="block"
                inputType="text"
                placeholder="Block"
                text="Block"
                requiredMessage="Block is required"
                required={true}
              />

              {/*UNKNOWN FIELD MIGHT FAIL*/}
              <InputField
                name="addition"
                inputType="text"
                placeholder="Addition"
                text="Addition"
                requiredMessage="Addition is required"
                required={true}
              />
            </>
          )}

          {currentState === "Washington" && (
            <InputField
              name="tax_parcel_id_number"
              inputType="text"
              placeholder="Tax Parcel ID Number"
              text="Tax Parcel ID Number"
            />
          )}

          {currentState === "Georgia" && (
          <>
            <InputField
              name="tax_parcel_id_number"
              inputType="text"
              placeholder="Tax Parcel ID Number"
              text="Tax Parcel ID Number"
              requiredMessage="Tax parcel ID No. is required"
              required={true}
            />
            <InputField
              name="land_lots"
              inputType="text"
              placeholder="Land Lot(s)"
              text="Land Lot(s)"
              //required={true}
            />
            <InputField
              name="district"
              inputType="text"
              placeholder="District"
              text="District"
              //required={true}
            />
            <InputField
              name="section_gmd"
              inputType="text"
              placeholder="Section / GMD"
              text="Section / GMD"
              //required={true}
            />
            <InputField
              name="lot"
              inputType="text"
              placeholder="Lot"
              text="Lot"
              //required={true}
            />
            <InputField
              name="block"
              inputType="text"
              placeholder="Block"
              text="Block"
              //required={true}
            />
            <InputField
              name="unit"
              inputType="text"
              placeholder="Unit"
              text="Unit"
              //required={true}
            />
            <InputField
              name="phase_section"
              inputType="text"
              placeholder="Phase / Section"
              text="Phase / Section"
              //required={true}
            />
            <InputField
              name="subdivision_development"
              inputType="text"
              placeholder="Subdivision / Development"
              text="Subdivision / Development"
              //required={true}
            />
            <InputField
              name="plat_book"
              inputType="text"
              placeholder="Plat Book"
              text="Plat Book"
              //required={true}
            />
            <InputField
              name="page"
              inputType="text"
              placeholder="Page"
              text="Page"
              //required={true}
            />
          </>
          )}

          {/* {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )} */}
          {!dealId && (
            <FloatingCTA customText="Create Listing" htmlType="submit" disabled={saving} loading={saving} />
          )}
        </Form>
      </FormWrap>
    </ExtraWrap>

  )
}

export default Listings(PropertyInformationForm)

