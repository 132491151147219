import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  ...createDeltaReducer("getAllPIMResponse"),
  ...createDeltaReducer("postFinalPIMResponse"),
}

export default function productInterestReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getAllPimAction.REQUEST:
    case Actions.getAllPimAction.SUCCESS:
    case Actions.getAllPimAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllPimAction, "getAllPIMResponse"),
      }
    }

    case Actions.postFinalCTAAction.REQUEST:
    case Actions.postFinalCTAAction.SUCCESS:
    case Actions.postFinalCTAAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.postFinalCTAAction, "postFinalPIMResponse"),
      }
    }

    default: {
      return state
    }
  }
}
