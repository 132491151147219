import React, { Component } from "react"
import styled from "styled-components"
import { LoadScript } from "services/Utils"

const StyledInput = styled.input`
  outline: none;
  background-color: inherit;
  border: none;
  width: 100%;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: inherit;
    font-weight: inherit;
  }
`

class CommunityLocation extends Component {
  constructor(props) {
    super(props)
    this.referenceHandler = this.referenceHandler.bind(this)
  }

  loadScript() {
    if (typeof window.google === "undefined") {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
  }

  googleAutoCompleteInitializer() {
    const options = {
      types: this.props.types,
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputElement, options)
    if (this.props.restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(this.props.restrict.south_west.lat, this.props.restrict.south_west.lng),
        new window.google.maps.LatLng(this.props.restrict.north_east.lat, this.props.restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = this.props.restrict.country.short_name.toLowerCase()
      geoComponents.setOptions(options)
    }
    window.google.maps.event.addDomListener(this.inputElement, "keyup", (e) => {
      //Handler does not exist, safe to delete?
      //this.props.handleChange(e.target.value)
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    window.google.maps.event.addListener(geoComponents, "place_changed", () => {
      const place = geoComponents.getPlace()
      if (place.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          south_west: {
            lat: place.geometry.viewport.getSouthWest().lat(),
            lng: place.geometry.viewport.getSouthWest().lng(),
          },
          north_east: {
            lat: place.geometry.viewport.getNorthEast().lat(),
            lng: place.geometry.viewport.getNorthEast().lng(),
          },
          address: place.formatted_address,
        }
        place.address_components.map(component => (location[component.types[0]] = component))
        if (this.props.clearInput) {
          this.inputElement.value = ""
          this.inputElement.focus()
        }
        this.props.location(location)
      }
    })
  }

  componentDidMount() {
    this.loadScript()
    // this.inputElement.focus();
  }

  referenceHandler(input) {
    this.inputElement = input
  }

  render() {
    const { placeholder } = this.props
    return (
      <StyledInput
        innerRef={this.referenceHandler}
        placeholder={placeholder}
      />
    )
  }
}

export default CommunityLocation
