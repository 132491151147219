import * as SettingsActions from "container/Settings/actions"

export const toggleSubscriptionCancelModal = SettingsActions.toggleSubscriptionCancelModal.action
export const getNotificationSettings = SettingsActions.getNotificationSettings.request
export const toggleNotificationSettingDisplay = SettingsActions.toggleNotificationSettingDisplay.action
export const requestToggleNotificationSetting = SettingsActions.requestToggleNotificationSetting.request
export const fetchAllLeadIntegrationSources = SettingsActions.fetchAllLeadIntegrationSourcesAction.request
export const toggleIntegrationDisplay = SettingsActions.toggleIntegrationDisplay.action
export const getUniqueEmail = SettingsActions.getUniqueEmailAction.request
export const updateLeadIntegrationSource = SettingsActions.updateLeadIntegrationSourceAction.request
export const updateReferralSettings = SettingsActions.updateReferralSettingsAction.request
export const toggleLeadIntegrationErrorModal = SettingsActions.toggleLeadIntegrationErrorModal.action
export const requestChangePassword = SettingsActions.requestChangePassword.request
export const toggleUserSettingsErrorModal = SettingsActions.toggleUserSettingsErrorModal.action
export const syncGmail = SettingsActions.syncGmailAction.request
export const deactivateGmail = SettingsActions.deactivateGmail.request
export const requestUpdateDisplayEmail = SettingsActions.requestUpdateDisplayEmail.request
export const fetchIntroValues = SettingsActions.fetchIntroValuesAction.request
export const fetchSMSTemplates = SettingsActions.fetchSMSTemplatesAction.request
export const updateSMSTemplates = SettingsActions.updateSMSTemplateAction.request
export const fetchLegends = SettingsActions.getSMSTemplateLegendsAction.request
export const changeLeadType = SettingsActions.changeLeadTypeAction.call
export const getCurrentAgent = SettingsActions.getCurrentAgentTypeAction.call
export const setCurrentAgent = SettingsActions.setCurrentAgentTypeAction.call
export const checkCurrentAgent = SettingsActions.checkCurrentAgentTypeAction.call
export const toggleAppointmentScheduling = SettingsActions.toggleAppointmentSchedulingAction.request
export const fetchCurrentAppointmentSchedulingStatus = SettingsActions.getCurrentAppointmentSchedulingAction.request
export const submitCalendlyLink = SettingsActions.submitCalendlyLinkAction.request
export const triggerEmail = SettingsActions.triggerEmailRequestAction.request
export const triggerCrossSellAnalytics = SettingsActions.triggerCrossSellAnalyticsAction.request
export const saveToStoreCurrentTemplate = SettingsActions.saveToStoreCurrentTemplateAction.call
export const handleToggleForSMSModal = SettingsActions.handleToggleForSMSModalAction.call
export const generateAPIToken = SettingsActions.generateAPITokenAction.request
export const reGenerateAPIToken = SettingsActions.reGenerateAPITokenAction.request
