import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const HeroText = styled.h1`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: ${props => props.fs || "40px"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  ${props => props.spanStyles && `
    span {
      color: #5A5FF2;
      font-family: ${MONTSERRAT};
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }  
  `}

  @media(max-width: 1099px) {
  font-size: ${props => props.fsMobile || "28px"};

    span {
      font-size: ${props => props.fsMobile || "28px"};
    }

    ${props => props.customPaddingMobile && `
        padding: ${props.customPaddingMobile || "unset"};
    `}
  }

  ${props => props.noMargin && `
    margin: 0;
  `}
`

export const SubText = styled.h2`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: ${props => props.fs || "18px"};
  font-style: normal;
  font-weight: 400;
  line-height: 25.38px;

  ${props => props.spanStyles && `
    span {
      color: #5A5FF2;
      font-family: ${INTER};
      font-weight: 700;
      font-style: normal;
    }  
  `}

  @media(max-width: 1099px) {
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: ${props => props.fsMobile || "16px"};
    font-style: normal;
    font-weight: 400;
    line-height: 169%; /* 27.04px */

    ${props => props.mobileWidth && `
      width: ${props.mobileWidth};
    `}
  }

  ${props => props.noMargin && `
    margin: 0;
  `}
`

export const BlueButton = styled.button`
  display: inline-flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: ${props => props.buttonFS || "15px"};
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 33.5px;
  background-color: #5A5FF2;

  border: none;

  ${props => props.height && `
    height: ${props.height};
  `}

  ${props => props.inForm && `
    height: 24.709px;
    color: #FFF;
    text-align: center;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.523%; /* 15.052px */
  `}

  @media(max-width: 1099px) {
    min-width: ${props => props.mobileMinWidth || "272px"};

    ${props => props.unsetMinWidth && `
      min-width: unset;
    `}

    color: #FFF;
    font-family: ${MONTSERRAT};
    font-size: ${props => props.mobileButtonFs || "12px"};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.hide && `
    display: none;
  `}

  ${props => props.desktopOnly && `
      @media(max-width: 1099px) {
        display: none;
      }
  `}

  ${props => props.move && `
      position: relative;
      top: 18px;

      @media(max-width: 1099px) {
        position: static;
      }
  `}
`

export const WhiteButton = styled.button`
  display: inline-flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 33.5px;

  border: 1px solid #5A5FF2;
  background: white;

  @media(max-width: 1099px) {
    min-width: 272px;

    ${props => props.unsetMinWidth && `
      min-width: unset;
    `}

    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`

export const YouGotMailWrap = styled.div`
  img {
    height: 40px;
    width: 40px;
  }

  h2 {
    color: #1C1C1F;
    font-family: ${MONTSERRAT};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 36.125px */
    margin: 0;
    ${props => props.footer && `
      color: #fff;
    `}
  }

  h3 {
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 163.523%; /* 29.434px */
    margin-top: 10px;
    ${props => props.footer && `
      color: #fff;
    `}
  }

  p {
    color: #B62121;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 18.063px */
    text-decoration-line: underline;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
`
