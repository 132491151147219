/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react"
import { handleDataLayerPush } from "services/dataLayerUtil"
import styled from "styled-components"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  display: flex;
  width: 777px;
  min-width: 348.579px;
  max-width: 777px;
  height: 354px;
  max-height: 354px;
  min-height: 198.21px;
  background: url(${CLOUDFRONT}/MarinImage.png);
  background-size: cover;
  border-radius: 8px;
  position: relative;
  cursor: pointer;


  ${props => props.isVideo && `
    background: white;
  `}

  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  video {
    height: 100%;
    width: 100%;
  }
  
  @media(min-width: 1100px) {
    width: 641px;
  }

  @media(min-width: 1280px) {
    width: 777px;
  }

  @media(max-width: 1099px) {
    width: 348.579px;
    height: 198.219px;
    flex-shrink: 0;
    max-width: 350px;
    margin: 0 auto;
  }
`

const TextWrap = styled.div`
  height: max-content;
  position: absolute;
  bottom: 0;
  padding: 25px;
`

const Description = styled.h1`
  color: #FFF;
  font-family: ${INTER};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.146px;
  width: 455px;

  @media(max-width: 1099px){
    color: #FFF;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 138.023%; /* 19.323px */
    width: 60%;
  }
`

const AboutMarin = styled.p`
  color: #FFF;
  font-family: ${INTER};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.023%;

  @media(max-width: 1099px) {
    color: #FFF;
    font-family: ${INTER};
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 109.023%; /* 10.902px */
  }
`

const WatchIntro = styled.p`
  color: #FFF;
  font-family: ${INTER};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  gap: 5px;

  span img {
    height: 13px;
    position: relative;
    top: 3px;
  }

  @media(max-width: 1099px) {
    color: #FFF;
    font-family: ${INTER};
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

const Video = () => {
  const [isVideo, setViewVideo] = useState(false)

  const handleViewVideo = () => {
    setViewVideo(true)
  }

  useEffect(() => {
    if (isVideo) {
      handleDataLayerPush(null, "intro_played", "")
    }
  }, [isVideo])

  return (
    <Wrap
      isVideo={isVideo}
      onClick={handleViewVideo}
      className="join_radius_overview_video_web"
    >
      {isVideo ? (
        <video
          autoPlay
          src="https://s3.amazonaws.com/cdn.agentdesks.com/video/Overview+Teaser.mp4"
          // eslint-disable-next-line react/no-unknown-property
          webkit-playsinline
          playsInline
          preload="auto"
        />
      ) : (
        <TextWrap>
          <Description>
            “Join us to learn more about how Radius can power your brand”
          </Description>
          <AboutMarin>
            Marin May - VP Partnerships
          </AboutMarin>
          <WatchIntro>
            Watch 10 second intro
            {" "}
            <span>
              <img src={`${CLOUDFRONT}/HPAsset_PlayButton.png`} alt="hp_radius" />
            </span>
          </WatchIntro>
        </TextWrap>
      )}
    </Wrap>
  )
}

export default Video
