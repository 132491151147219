import React from "react"
import {
  ResponseTagWrap,
} from "./style"

const ResponseTag = ({
  responseCount,
}) => (
  <ResponseTagWrap>
    <p>{`${responseCount} NEW ${responseCount > 1 ? "RESPONSES" : "RESPONSE"}`}</p>
  </ResponseTagWrap>
)

export default ResponseTag