import React, { useMemo, useRef, useState } from "react"
import { Form, Spin } from "@ui/antd"
import debounce from "lodash/debounce"
import { SelectFieldWrap } from "./styles"

function DebounceSelect({
  placeholder,
  fetchOptions,
  debounceTimeout = 800,
  name,
  ...props
}) {
  const rules = [{ required }]
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  return (
    <SelectFieldWrap
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      style={{
        width: "320px",
      }}
      {...props}
    />
  )
}

export default DebounceSelect
