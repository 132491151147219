import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Wrap,
  RightPane,
  Container,
  ColLeft,
  ColRight,
  ImagesContainer,
  TabWrapper,
  TabBtns,
  MobileCarousel,
  DatesCardWrapper,
  OpenHouseDesc,
  OpenHouseHeader,
  ShimmerContainer,
  WebOnlyOpenHouse,
  StickyWrap,
} from "./styles.js";
import { GlobalModalStyle } from "./customModalStyles";
import { ShimmerBox } from "shared/styles/animation";
import CarouselWrapper from "./Carousel/index";
import ListingMaps from "./ListingMaps/index";
import OpenHouse from "./OpenHouse/index";
import PricingHistory from "./PricingHistory/index";
import GettingAround from "./GettingAround/index";
import NearBySchools from "./NearBySchools/index";
import NearByRestaurants from "./NearByRestaurants/index";
import WhatsSpecial from "./WhatsSpecial/index";
import { Helmet } from "react-helmet";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import NoDataFound from "../NoDataFound/index";
import urlParser from "url-parse";
import { isSuccess, getQueryFromURL } from "services/Utils";
import root from "window-or-global";
import queryString from "query-string";
import RightStickyGrid from "./RightStickyCard/index";
import DatesCardCarousel from "./DatesCardCarousel/index";
// import TopStickNav from "./TopStickyNav/index"
import { Button, Checkbox, Divider, Tabs, Modal } from "antd";
import ShareIcon from "./Icons/share.js";
import HeartIcon from "./Icons/heart.js";
import RequestTourModal from "./RequestTourModal/index";
import CarouselMobile from "./Carousel/mobile.js";
import ContactAgentModal from "./ContactAgentModal/index";
import createToast from "dumbComponents/common/Toast/customToast";

const ListingPageLayout = ({
  fetchListingPageDetails,
  listingPageResponse,
  match,
}) => {
  const { MLS_ID } = match.params;
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (!listingPageResponse.data) {
      const payload = {
        MLS_ID: MLS_ID,
        Property_id: query.unique_mls_id,
      };
      fetchListingPageDetails(payload);
    }
  }, [MLS_ID]);
  const [openContactModal, setopenContactModal] = useState(false);

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = root.document.createElement("input");
    el.value = root.location.href;
    root.document.body.appendChild(el);
    el.select();
    root.document.execCommand("copy");
    root.document.body.removeChild(el);
    setCopied(true);
    createToast("Link Copied !");
  }

  const topNavBtns = (
    <div style={{ display: "flex" }}>
      <TabBtns onClick={copy}>
        <ShareIcon
          style={{ paddingRight: "8px", height: "30px", width: "30px" }}
        />{" "}
        Share this property
      </TabBtns>
      <TabBtns onClick={() => setopenContactModal(true)}>
        <HeartIcon
          style={{ paddingRight: "8px", height: "30px", width: "30px" }}
        />{" "}
        Interested
      </TabBtns>
    </div>
  );

  // const [activeTabKey, setActiveTabKey] = useState("overview");

  const tabItems = [
    {
      key: "overview",
      label: "Overview",
      children: "",
    },
    {
      key: "openHouses",
      label: "Open Houses",
      children: "",
    },
    {
      key: "gettingAround",
      label: "Getting Around",
      children: "",
    },
    {
      key: "pricingHistory",
      label: "Pricing & Tax History",
      children: "",
    },
  ];

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = tabItems.map((tab) => document.getElementById(tab.key));

  //     for (let i = sections.length - 1; i >= 0; i--) {
  //       const section = sections[i];
  //       const rect = section.getBoundingClientRect();

  //       if (rect.top <= 1) {
  //         setActiveTabKey(tabItems[i].key);
  //         break;
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [tabItems]);

  // const handleTabClick = (key) => {
  //   const element = root.document.getElementById(key);
  //   console.log(key);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleTabClick = (key) => {
    const element = root.document.getElementById(key);
    console.log(key);
    let offset = 0;
  
    if (element) {
      switch (key) {
        case "openHouses":
          offset = 1550;
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
          break;
  
        case "overview":
          offset = 0; // Replace with the desired offset for 'overview'
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
          break;
  
        case "gettingAround":
          offset = 2000; // Replace with the desired offset for 'gettingAround'
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
          break;
  
        // case "pricingHistory":
        //   offset = 2000;
        //   window.scrollTo({
        //     top: offset,
        //     behavior: "smooth",
        //   });
        //   break;
  
        default:
          // Default scroll behavior for other keys
          element.scrollIntoView({ behavior: "smooth" });
          break;
      }
    }
  };
  

  const [openModal, setopenModal] = useState(false);
  const [visitingDateTime, setVisitingDateTime] = useState();

  const handleSelectedDateTime = (dateTime) => {
    setopenModal(true);
  };
  // -----post date and time data-----

  const meta = {
    title: `${listingPageResponse?.data?.UnparsedAddress}, ${listingPageResponse?.data?.PostalCode} | MLS #${listingPageResponse?.data?.Id} | Radius`,
    description: `This ${listingPageResponse?.data?.LivingArea} ${listingPageResponse?.data?.LivingAreaUnits} Single Family home has ${listingPageResponse?.data?.BedroomsTotal} bed(s) and ${listingPageResponse?.data?.BathroomsFull} bath(s). It is located at ${listingPageResponse?.data?.UnparsedAddress}, ${listingPageResponse?.data?.PostalCode}.`,
    url: `${WEB_URL}`,
    imageUrl: `${listingPageResponse?.data?.Media[0]}`,
    keywords: `Buy house in ${listingPageResponse?.data?.PostalCode}, Buy House with ${listingPageResponse?.data?.BedroomsTotal} Bed(s), Buy House with ${listingPageResponse?.data?.BathroomsFull} bath(s),`,
  };

  return (
    <>
      {listingPageResponse?.data?.Id ? (
        <>
          <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta property="title" content={meta.title} />
            <meta property="og:url" content={meta.url} />
            <meta property="url" content={meta.url} />
            <meta property="og:image" content={meta.imageUrl} />
            <meta property="og:description" content={meta.description} />
            <meta property="description" content={meta.description} />
            <meta property="keywords" content={meta.keywords} />
          </Helmet>
          {openContactModal && (
            <ContactAgentModal
              openModal={openContactModal}
              onClose={() => setopenContactModal(false)}
              address={listingPageResponse?.data?.UnparsedAddress}
              pinCode={listingPageResponse?.data?.PostalCode}
            ></ContactAgentModal>
          )}

          <RequestTourModal
            openModal={openModal}
            onClose={() => setopenModal(false)}
            isVisitingDateTime={openModal}
            address={listingPageResponse?.data?.UnparsedAddress}
            pinCode={listingPageResponse?.data?.PostalCode}
          />

          <TabWrapper>
            <Tabs
              tabBarExtraContent={topNavBtns}
              // activeKey={activeTabKey}
              items={tabItems}
              onTabClick={handleTabClick}
              defaultActiveKey="1"
              style={{ width: "1355px", zIndex: "10", margin: "0px" }}
            />
          </TabWrapper>
          <div id="overview" style={{display: "hidden"}}></div>
          <ImagesContainer>
            {listingPageResponse?.data?.Media?.length > 0 && (
              <CarouselWrapper />
            )}
          </ImagesContainer>

          <Container>
            <ColLeft>
              <MobileCarousel>
                <CarouselMobile listingPageResponse={listingPageResponse} />
              </MobileCarousel>
              <Wrap style={{ paddingTop: "0px" }}>
                <WhatsSpecial />
                {listingPageResponse?.data?.Latitude &&
                  listingPageResponse?.data?.Longitude && <ListingMaps />}
                <WebOnlyOpenHouse>
                  <OpenHouse />
                </WebOnlyOpenHouse>
                <hr id="gettingAround" style={{ color: "#E0E0EB" }}></hr>
                {listingPageResponse?.data?.gettingAround?.bikeScore?.score ||
                listingPageResponse?.data?.gettingAround?.walkScore?.score ? (
                  <GettingAround />
                ) : null}
                <NearBySchools />
                <hr style={{ color: "#E0E0EB" }}></hr>
                <NearByRestaurants />
                <hr id="pricingHistory" style={{ color: "#E0E0EB" }}></hr>
                <PricingHistory />
              </Wrap>
            </ColLeft>

            <ColRight>
              <StickyWrap>
                <RightStickyGrid listingPageResponse={listingPageResponse} />
              </StickyWrap>
            </ColRight>
          </Container>
        </>
      ) : (
        <div
          style={{
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ShimmerContainer>
            <ShimmerBox
              w="96%"
              h="60px"
              style={{ margin: "10px auto", marginTop: "20px" }}
            />
            <ShimmerBox w="96%" h="400px" style={{ margin: "20px auto" }} />
          </ShimmerContainer>
          <Container style={{ paddingTop: "0px" }}>
            <ColLeft style={{ padding: "0 20px" }}>
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
            </ColLeft>
            <ColRight>
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
            </ColRight>
          </Container>
        </div>
      )}
    </>
  );
};

export default ListingPageContainer(ListingPageLayout);
