import React, { useEffect } from "react"
import styled from "styled-components"
import colors from "@colors"
import Rooms from "container/Rooms/index"
import { ATC } from "@fonts"

const Wrap = styled.div`
  height: 159px;
  width: 251px;
  border-radius: 6px;
  display: flex;
  gap: 5px;
  flex-direction: column;
`

const Image = styled.div`
  height: 92.69230651855469px;
  width: 251px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Title = styled.div`
  color: ${colors.black};
  margin-bottom: 9px;
  p {
    font-family: ${ATC};
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.black};
    color: #303030;
  }
`

const CtaWrap = styled.div``

const UploadCta = styled.button`
  border: none;
  cursor: pointer;
  color: ${colors.marineBLue};
  background: transparent;

  font-family: ${ATC};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`

const Fallback = "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_New_Fallback.jpg"

const UploadImage = ({
  currentImage,
  toggleImageUploadModal,
}) => (
  <Wrap>
    <Title>
      <p>Room Image</p>
    </Title>
    <Image src={currentImage || Fallback} />
    <CtaWrap>
      <UploadCta
        type="button"
        onClick={() => {
          toggleImageUploadModal(true)
        }}
      >
        Change Image
      </UploadCta>
    </CtaWrap>
  </Wrap>
)

export default Rooms(UploadImage)
