import React from "react"
import styled from "styled-components"
import emptyStateSignedPNG from "images/referrals/empty-state-signed.png"
import Heading from "dumbComponents/common/Typography/Heading"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 400px;
`

const Holder = styled.div`
  padding: 20px;
  background: #fff;
  height: 350px;
  width: 486px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HeroImage = styled.img`
  position: absolute;
  left: 0px;
  max-width: 280px;
  bottom: 0px;
`

const IconWrap = styled.i`
  font-size: 44px;
  font-weight: 300;
  width: 80px;
  height: 80px;
  background: #e7ebed;
  border-radius: 50%;
  display: inline-block;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

const NoReferralFound = ({ productType }) => (
  <Wrapper>
    <Holder>
      <IconWrap>!</IconWrap>
      {productType === "crm" ? (
        <Heading type="h2" color="#354052" weight="500" margin="30px 0 0 0">
          No clients found!
          <br />
        </Heading>
      ) : (
        <Heading type="h2" color="#354052" weight="500" margin="30px 0 0 0">
          No matches found!
          <br />
          Try again with
          <br />
          different filters.
        </Heading>
      )}
      
    </Holder>
    <HeroImage src={emptyStateSignedPNG} />
  </Wrapper>
)

export default NoReferralFound
