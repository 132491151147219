import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { Field } from "redux-form"
import { requiredWithInvalidEmptyString } from "services/formUtils"
import Textarea from "./RenderTextarea"

const LabelStyles = `
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  color: ${colors.errorDanger};
  font-size: 12px;
`

const InputWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: ${props => props.width || "auto"};
  z-index: 10;
    label {
      textarea {
        border: 1px solid ${colors.contentText};
      }
      textarea:focus {
        border: 1px solid ${colors.deepPurple};
      }
      > span {
        ${LabelStyles};
      }
      & ~ span {
        position: absolute;
        bottom: -20px;
        left: 0px;
      }
  }

  > div {
    /* For Location */
    > label {
      ${LabelStyles};
    }
    > div {
      border-radius:  ${props => props.borderRadius || "10px"};
      border: 1px solid ${colors.darkPurple};

      div[class$="-menu"] {
        background: ${colors.white};
      }
      input {
        border: none;
        background: ${colors.white};
        & ~ span {
          border: none;
        }
      }

      > div {
        background: none;
        border-color: transparent;
      }
    }
  }
  /* For Location */
  /* For Location */

`

const RadiusInput = (props) => {
  const {
    isRequired, name, placeholder, label, width, borderRadius,
  } = props
  return (
    <InputWrap width={width} borderRadius={borderRadius}>
      <Field
        name={name}
        component={Textarea}
        placeholder={placeholder}
        label={label}
        rows="7"
        validate={isRequired ? [requiredWithInvalidEmptyString] : []}
      />
    </InputWrap>

  )
}

export default RadiusInput

