const DEAL_TERMS_COMMON_FIELDS = [
  {
    name: "price",
    type: "text",
    placeholder: "Purchase Price*",
    modifiesForm: true,
    formatterType: "money",
  },
  {
    name: "loan_amount_percentage",
    type: "text",
    placeholder: "Loan Amount Percentage*",
    showOr: true,
    modifiesForm: true,
    formatterType: "percentage",
    disabled: true,
  },
  {
    name: "loan_amount",
    type: "text",
    placeholder: "Loan Amount*",
    modifiesForm: true,
    formatterType: "money",
  },
  {
    name: "earnest_money_deposit",
    type: "text",
    placeholder: "Earnest Money Deposit*",
    formatterType: "money",
  },
  {
    name: "balance_of_down_payment",
    type: "text",
    placeholder: "Downpayment Amount*",
    formatterType: "money",
  },
  {
    name: "initial_deposit_amount_percentage",
    type: "text",
    placeholder: "Initial Deposit Amount Percentage*",
    modifiesForm: true,
    showOr: true,
    formatterType: "percentage",
    disabled: true,
  },
  {
    name: "initial_deposit_amount",
    type: "text",
    placeholder: "Initial Deposit Amount*",
    modifiesForm: true,
    formatterType: "money",
  },
  {
    name: "increased_deposit_amount_percentage",
    type: "text",
    placeholder: "Increased Deposit Amount Percentage*",
    showOr: true,
    modifiesForm: true,
    formatterType: "percentage",
    disabled: true,
  },
  {
    name: "increased_deposit_amount",
    type: "text",
    placeholder: "Increased Deposit Amount*",
    modifiesForm: true,
    formatterType: "money",
  },
  {
    name: "additional_financial_amount_percentage",
    type: "text",
    placeholder: "Additional Financial Amount Percentage*",
    showOr: true,
    modifiesForm: true,
    formatterType: "percentage",
    disabled: true,
  },
  {
    name: "additional_financial_amount",
    type: "text",
    placeholder: "Additional Financial Amount*",
    modifiesForm: true,
    formatterType: "money",
  },
]

const ADDITIONAL_EARNEST_MONEY = {
  name: "additional_earnest_money",
  type: "text",
  placeholder: "Additional Earnest Money*",
  formatterType: "money",
}

const OTHER = {
  name: "other",
  type: "text",
  placeholder: "Other*",
  formatterType: "money",
}

const ADDITIONAL_DEPOSIT = {
  name: "additional_deposit",
  type: "text",
  placeholder: "Additional Deposit*",
  formatterType: "money",
}

export const getInputFieldsBasedOnState = (state) => {
  switch (state) {
    case "california":
      return [...DEAL_TERMS_COMMON_FIELDS, ADDITIONAL_EARNEST_MONEY]
    case "texas":
      return [...DEAL_TERMS_COMMON_FIELDS]
    case "florida":
      return [...DEAL_TERMS_COMMON_FIELDS, ADDITIONAL_EARNEST_MONEY, OTHER, ADDITIONAL_DEPOSIT]
    case "washington":
      return [...DEAL_TERMS_COMMON_FIELDS]
    case "oregon":
      return [...DEAL_TERMS_COMMON_FIELDS]
    case "colorado":
      return [...DEAL_TERMS_COMMON_FIELDS]
    case "georgia":
      return [...DEAL_TERMS_COMMON_FIELDS]
    default:
      return [...DEAL_TERMS_COMMON_FIELDS]
  }
}
