import React, { useEffect } from "react"
import {
  OFFER_DOCS,
  LISTING_DOCS,
  UPLOADS,
} from "dumbComponents/OffersV2/consts"
import OffersV2 from "container/OffersV2/index"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import OldOffers from "container/TechAssets"
import { EyeIcon, TrashIcon } from "dumbComponents/OffersV2/icons"
import {
  DocumentShimmer,
  Tabs,
} from "dumbComponents/OffersV2/components"
import Listings from "container/Listings"
import ListingsV2 from "container/ListingsV2"
import {
  Wrap,
  DocumentTypeWrap,
} from "./style"
import OfferDoc from "./OfferDoc"
import UploadDoc from "./UploadDocs"
import { OFFERS, LISTINGS, PURCHASE } from "dumbComponents/OffersV3/consts"

const addDocumentsUI = ({
  options: LibraryOptions,
  toggleLibraryTab,
  currentLibraryTab,
  listingDocsList,
  offersDocList,
  fetchLibraryUploads,
  fetchLibraryUploadedFilesResponse,
  dealToView,
  docList,
  getOfferDocumentsResponse,
  getOfferDocuments,
  goBack,
  fetchOfferDocuments,
  stateForOffer,
  fetchOfferDocumentsResponse,
  updateRecommendedListOfDocs,
  fetchListingDocumentsResponse,
  fetchListingDocuments,
  getTemplatePreview,
  deleteLibraryUploadedFile,
  updateRecommendedListOfDocsResponse,
  uploadedDocs,
  currentBrokerageType,
  listingIdToView,
  offerIdToView,
}) => {

  const {
    isFetching: fetchingOffersDocs,
  } = getOfferDocumentsResponse || {}

  const {
    isFetching: updatingRecommendedDocs,
  } = updateRecommendedListOfDocsResponse || {}

  const {
    isFetching: fetchingLibraryUploads,
  } = fetchLibraryUploadedFilesResponse || {}

  const {
    isFetching: offerDocumentsFetching,
  } = fetchOfferDocumentsResponse || {}

  const {
    isFetching: fetchingListingDocs,
  } = fetchListingDocumentsResponse || {}

  useEffect(() => {
    if (currentLibraryTab === UPLOADS) {
      fetchLibraryUploads({
        payload: {
          transaction_type: "offer",
          transaction_id: offerIdToView || dealToView,
        },
      })
    }

    if (currentLibraryTab === OFFER_DOCS) {
      fetchOfferDocuments({
        state: stateForOffer,
      })
    }

    if (currentLibraryTab === LISTING_DOCS) {
      fetchListingDocuments({
        state: stateForOffer,
      })
    }
  }, [currentLibraryTab])

  const handleSubmit = () => {
    const payload = {
      offerId: parseInt(dealToView, 10),
      templateIds: [],
    }

    let templatesToBeUploaded = []

    if (currentLibraryTab === UPLOADS) {
      const { sharedWithMe, teamDocs, transactionDocs } = uploadedDocs || {}
      const selectedSharedDoc = sharedWithMe.filter(item => item.isSelected).map(item => item.id)
      const selectedIndividualDoc = teamDocs.filter(item => item.isSelected).map(item => item.id)
      const selectedTransactionDocs = transactionDocs.filter(item => item.isSelected).map(item => item.id)
      templatesToBeUploaded = [...selectedSharedDoc, ...selectedIndividualDoc, ...selectedTransactionDocs]
      const idPayload = {
        ids: [...templatesToBeUploaded],
        type: "library_uploads",
      }
      payload.templateIds = [idPayload]
    }
    if (currentLibraryTab === LISTING_DOCS) {
      templatesToBeUploaded = listingDocsList.filter(item => item.isSelected).map(item => item.id)
      payload.templateIds = [...templatesToBeUploaded]
      const idPayload = {
        ids: [...templatesToBeUploaded],
        type: "listings",
      }
      payload.templateIds = [idPayload]
    }
    if (currentLibraryTab === OFFER_DOCS) {
      templatesToBeUploaded = offersDocList.filter(item => item.isSelected).map(item => item.id)
      payload.templateIds = [...templatesToBeUploaded]
      const idPayload = {
        ids: [...templatesToBeUploaded],
        type: "offers",
      }
      payload.templateIds = [idPayload]
      console.log(payload, "here123")
    }
    updateRecommendedListOfDocs({
      type: "offers",
      payload,
      id: dealToView,
      callBack: () => {
        goBack()
      },
    })
  }

  const OFFERS_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        //needs a loader
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "offer",
        })
      },
      showDivide: false,
    },
  ]

  const LISTING_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "listing",
        })
      },
      showDivide: false,
    },
  ]
  
  const UPLOAD_MAIN_LIST = [
    {
      label: "DELETE",
      image: <TrashIcon />,
      onClick: (id) => {
        //NEED TOAST
        customToast("Deleting document...")
        deleteLibraryUploadedFile({
          id,
          transaction_type: "offer",
          transaction_id: offerIdToView || dealToView,
        })
      },
      showDivide: false,
    },
    // {
    //   label: "VIEW",
    //   image: <EyeIcon />,
    //   onClick: (id) => {
    //     const payload = {
    //       templateId: id,
    //     }
    //     console.log(id)
    //     //NEED TOAST
    //     // getTemplatePreview({
    //     //   payload,
    //     //   dealType: "offer",
    //     // })
    //   },
    //   showDivide: false,
    // },
    //OFFERS_V2/Trash.svg
  ]

  return (
    <Wrap>
      <Tabs
        steps={LibraryOptions}
        toggleTab={currentTab => toggleLibraryTab(currentTab)}
        currentTab={currentLibraryTab}
      />
      <DocumentTypeWrap>
        {currentLibraryTab === OFFER_DOCS && (
          <>
            {offerDocumentsFetching ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={offersDocList}
                handleSubmit={handleSubmit}
                mainList={OFFERS_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === LISTING_DOCS && (
          <>
            {fetchingListingDocs ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={listingDocsList}
                handleSubmit={handleSubmit}
                mainList={LISTING_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === UPLOADS && (
          <>
            {fetchingLibraryUploads ? (
              <DocumentShimmer />
            ) : (
              <UploadDoc
                handleSubmit={handleSubmit}
                goBack={goBack}
                mainList={UPLOAD_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
                transactionType="offer"
                transactionId={offerIdToView || dealToView}
              />
            )}
          </>
        )}
      </DocumentTypeWrap>
    </Wrap>
  )
}

export default Listings(OldOffers(ListingsV2(OffersV2(addDocumentsUI))))
