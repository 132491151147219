import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const ListingCardWrap = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 20px;

  padding: 16px;
  border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--Gradient-1, #B6B8FF);
  background: rgba(255, 255, 255, 0.10);

  /* SD */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  backdrop-filter: blur(16.799999237060547px);
  flex-wrap: wrap;
`

export const AddressCard = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const AddressCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 228px;

  h1 {
    color: var(--Gray-900, #101828);
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    margin: 0;
  }

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    /* leading-tight/body-2/regular */
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 16.2px */
    letter-spacing: 0.5px;
    margin: 0;
  }
`

export const AddressImageCard = styled.div`
  border-radius: 8px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
  width: 94px;
  height: 102px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
`

export const StatCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  border-left: 1px solid var(--Color-Neutral-300, #DCDEFF);
  padding-left: 30px;
  padding-right: 20px;
  flex-wrap: wrap;
  

  ${props => props.noBorder && `
    border-left: none;
  `}

  ${props => props.noPadding && `
    padding: 0;
  `}

  @media(max-width: 1320px) {
    padding-left: 20px
    padding-right: 20px;
  }
`

export const ActionCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  border-left: 1px solid var(--Color-Neutral-300, #DCDEFF);
  padding-left: 20px;
  padding-right: 20px;
`

export const StyledPrice = styled.p`
  color: var(--Color-Primary-Primary, #5A5FF2);
  /* leading-tight/subtitle-2/semi-bold */
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 18.9px */
  letter-spacing: 0.5px;
  margin: 0;
`

export const StyledStat = styled.p`
  color: var(--Gray-500, #667085);
  text-align: center;

  /* Text sm/Normal */
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
`

export const StyledAction = styled.button`
  display: flex;
  gap: 5px;
  border: none;
  background: none;
  cursor: pointer;

 p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    leading-trim: both;
    text-edge: cap;
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin: 0;
  }

  img {
    height: 12px;
    width: 12px;
  }
`

/**IN CONTRACT TAB LISTING CARD*/

export const DetailsCardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ListingCardTitle = styled.div`
  h1 {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 24.084px */
    margin: 0;
  }
`

export const CTAWrap = styled.div`
  display: flex;
`

export const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  // padding-top: 20px;
  h2 {
    color: var(--gray-500, #71717A);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    margin: 0;
  }

  p {
    color: var(--gray-900, #18181B);
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 228.571% */
    margin: 0;
  }
`
