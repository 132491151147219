import AcademySVG from "dumbComponents/common/UI/Sidebar/Icons/Academy.svg"
import AssetsSVG from "dumbComponents/common/UI/Sidebar/Icons/Assets.svg"
import CashbackSVG from "dumbComponents/common/UI/Sidebar/Icons/Cashback.svg"
import CommunitySVGV2 from "dumbComponents/common/UI/Sidebar/Icons/CommunityV2.svg"
import CompanyProfileSVG from "dumbComponents/common/UI/Sidebar/Icons/CompanyProfile.svg"
import CRMSVG from "dumbComponents/common/UI/Sidebar/Icons/CRM.svg"
import DashboardHomeSVG from "dumbComponents/common/UI/Sidebar/Icons/DashboardHome.svg"
import FinancingSVG from "dumbComponents/common/UI/Sidebar/Icons/Financing.svg"
import FindanAgentSVG from "dumbComponents/common/UI/Sidebar/Icons/FindanAgent.svg"

import InboundSVG from "dumbComponents/common/UI/Sidebar/Icons/Inbound.svg"
import JobsSVG from "dumbComponents/common/UI/Sidebar/Icons/Jobs2.svg"
import MortgageSVG from "dumbComponents/common/UI/Sidebar/Icons/Mortgage.svg"
import NotificationsSVGV2 from "dumbComponents/common/UI/Sidebar/Icons/NotificationsV2.svg"
import OutboundSVG from "dumbComponents/common/UI/Sidebar/Icons/Outbound.svg"
import RecruitandEarnSVG from "dumbComponents/common/UI/Sidebar/Icons/RecruitandEarn.svg"
import RecruitmentSVG from "dumbComponents/common/UI/Sidebar/Icons/Recruitment.svg"
import ReferralsSVG from "dumbComponents/common/UI/Sidebar/Icons/Referrals.svg"

import ResourcesSVG from "dumbComponents/common/UI/Sidebar/Icons/Resources.svg"
import SalesLeaderboardSVG from "dumbComponents/common/UI/Sidebar/Icons/SalesLeaderboard.svg"
import SupportSVG from "dumbComponents/common/UI/Sidebar/Icons/Support.svg"
import TechandAssetsSVG from "dumbComponents/common/UI/Sidebar/Icons/TechandAssets.svg"
import ListingsSVG from "dumbComponents/common/UI/Sidebar/Icons/Listings.svg"

import TransactionCoordinationSVG from "dumbComponents/common/UI/Sidebar/Icons/TransactionCoordination2.svg"
import WellnessSVG from "dumbComponents/common/UI/Sidebar/Icons/Wellness3.svg"
import ListingVideoSVG from "dumbComponents/common/UI/Sidebar/Icons/ListingVideo.svg"
import OffersIconSVG from "dumbComponents/common/UI/Sidebar/Icons/OfferIcon.svg"
import LiveRoomTag from "dumbComponents/VideoRooms/icons/liveRoomsIcon.svg"

export const AcademySVGIcon = AcademySVG
export const AssetsSVGIcon = AssetsSVG
export const CashbackSVGIcon = CashbackSVG
export const CommunitySVGIcon = CommunitySVGV2
export const CompanyProfileSVGIcon = CompanyProfileSVG
export const CRMSVGIcon = CRMSVG
export const DashboardHomeSVGIcon = DashboardHomeSVG
export const FinancingSVGIcon = FinancingSVG
export const FindanAgentSVGIcon = FindanAgentSVG
export const InboundSVGIcon = InboundSVG
export const JobsSVGIcon = JobsSVG
export const MortgageSVGIcon = MortgageSVG

export const NotificationsSVGIcon = NotificationsSVGV2
export const OutboundSVGIcon = OutboundSVG

export const RecruitandEarnSVGIcon = RecruitandEarnSVG
export const RecruitmentSVGIcon = RecruitmentSVG
export const ReferralsSVGIcon = ReferralsSVG
export const ResourcesSVGIcon = ResourcesSVG
export const SalesLeaderboardSVGIcon = SalesLeaderboardSVG
export const SupportSVGIcon = SupportSVG

export const TechandAssetsSVGIcon = TechandAssetsSVG
export const TransactionCoordinationSVGIcon = TransactionCoordinationSVG
export const WellnessSVGIcon = WellnessSVG
export const ListingsIcon = ListingsSVG
export const OffersIcon = OffersIconSVG

export const ListingVideosSVGIcon = ListingVideoSVG
export const LiveRoomTagIcon = LiveRoomTag
