import styled from "styled-components";
import colors from "@colors";
import { MONTSERRAT, INTER } from "@fonts";
import {
  Button,
  Tag,
} from "antd";
export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap:8px;`
;

export const OuterWrap = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  position: fixed;
  width:400px;
  border-radius: 40px;
border: 1px solid ${colors.new_border_outline};
  background: ${colors.white};
  box-shadow: 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  transform: translate(-50%);
  bottom: 20px;
  left: 79%;
  z-index: 9;
  justify-content: space-between;
`
export const InnerText = styled.div`
  p {
    color: ${colors.new_grey400};
    /* leading-tight/subtitle-2/semi-bold */
    /* font-family: ${MONTSERRAT};*/
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
    margin: 0;
  }
`

export const GradientText = styled.span`
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 185%;
  letter-spacing: 0.667px;
  background: var(
    --Mel-Linear-Text,
    linear-gradient(
      142deg,
      #5a5ff2 -24.07%,
      #b6b8ff 5.49%,
      #f08068 47.53%,
      #f0a468 68.31%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;


export const FilterButton = styled(Button)`
  border-radius: 16px !important;
  background: ${colors.white} 16px !important;
  border: 1px solid ${colors.new_borderLight} 16px !important;
  color: ${colors.new_buttonColor} 16px !important;
  display: flex;
  align-items: center;
  height: 32px;

  &:hover {
    border-color: ${colors.new_primary} 16px !important;
    color: ${colors.new_primary} 16px !important;
  }

  .anticon {
    margin-left: 8px;
  }`
;

export const ApplyButton = styled(Button)`
  border-radius: 8px;
  background: ${colors.new_primary};
  color: ${colors.white};
  width: 100%;`
;

export const StatusTag = styled(Tag)`
  border-radius: 16px !important;
  font-size: 12px !important;
  padding: 4px 12px !important;
  border: none !important;
  color: ${(props) => (props.status === "Shortlisted" ? `${colors.new_text_purple_600}` : "#F05152")} !important;
  background: ${(props) =>
    props.status === "Shortlisted" ? `${colors.new_tab_color }` : `${colors.new_redTextColor}`} !important;`
;

export const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.new_text_purple_600};`
;

export const TimelineContainer = styled.div`
  padding: 16px;
  background-color: ${colors.new_buttonText};
  border-radius: 8px;
  margin-top: 16px;
  max-height: 300px;
  overflow-y: auto;`
;

export const TimelineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: ${(props) => (props.index % 2 === 0 ? `${colors.new_timeline_color}` : `${colors.white}`)};
  border-radius: 4px;
  margin-bottom: 8px;`
;

export const TimelineDate = styled.div`
  font-size: 12px;
  color: ${colors.new_timeline_Date_color};`
;

export const TimelineStatus = styled.div`
  font-size: 14px;
  color: ${(props) => (props.status === "Shortlisted" ? `${colors.new_primary}` : `${colors.new_timeline_status_color}`)};
  font-weight: 500;`
;
export const BedNumber = styled.p`
color:${colors.new_text_purple_600};
font-family: ${INTER};
font-size: 14px;
font-weight: 600;
letter-spacing: 0.5px;`

export const Beds = styled.p`
color: ${colors.new_levelColor};
font-family: ${INTER};
font-size: 14px;
font-weight: 400;
letter-spacing: 0.5px;`

export const Dot = styled.div`
height:6px;
width:6px;
background:${colors.new_border_outline};
border-radius:50%;
margin-top:8px;
margin-left:8px;
margin-right:8px;

`