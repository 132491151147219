import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import * as PropertyDetailsActions from "./actions"

const initState = {
  ...createDeltaReducer("propertyDetailsResponse"),
  ...createDeltaReducer("agentProfileResponse"),
}

export default function navbarReducer(state = initState, action) {
  switch (action.type) {
    case PropertyDetailsActions.getPropertyDetaillsAction.REQUEST:
    case PropertyDetailsActions.getPropertyDetaillsAction.FAILED:
    case PropertyDetailsActions.getPropertyDetaillsAction.FAILURE:
    case PropertyDetailsActions.getPropertyDetaillsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyDetailsActions.getPropertyDetaillsAction,
          "propertyDetailsResponse"
        ),
      }
    }

    case PropertyDetailsActions.getAgentPublicProfileAction.REQUEST:
    case PropertyDetailsActions.getAgentPublicProfileAction.FAILED:
    case PropertyDetailsActions.getAgentPublicProfileAction.FAILURE:
    case PropertyDetailsActions.getAgentPublicProfileAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyDetailsActions.getAgentPublicProfileAction,
          "agentProfileResponse"
        ),
      }
    }

    default: {
      return state
    }
  }
}
