import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

/**
 *Add project wide common styles here
 * and import accordingly.
 * For component specific styles,
 * go to the component and add to its inner style file.
 */

export const OffersWrap = styled.div`
  padding-top: 50px;
  display: flex;
  width: 100%;
  position: relative;
  min-height: 102vh;
`

export const TabsWrap = styled.div`
  border-radius: 9px;
  border: 1px solid #EBEBEB;
  background: var(--White, #FDFDFD);
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
`

export const MenuWrap = styled.div`
  display: flex;
  width: 50%;
  padding: 34px 28px 0px 28px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  height: 50%;
  position: sticky;
  top: 100px;
`

export const FormAndFunctionWrap = styled.div`
  display: flex;
  padding: 34px 31px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  flex-shrink: 0;
  align-self: stretch;
  height: 100%;
  width: 50%;

  border-left: 1px solid var(--Color-Neutral-300, #DCDEFF);
  background: #F9F9FF;

  min-height: 100vh;
`

export const WhiteButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: transparent;

  border-radius: 33.5px;
  border: 1px solid #5A6CF2;

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
`

export const BlueButton = styled.button`
  display: flex;
  padding: 16px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: none;
  cursor: pointer;

  border-radius: 33.5px;
  background: #5A5FF2;

  p {
    color: var(--White, #FDFDFD);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
  
  ${props => props.disabled && `
    cursor: not-allowed;
    background: #acacbd;
  `}
`

export const DangerButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: none;

  border-radius: 33.5px;
  background: #ff2c2c;

  p {
    color: var(--White, #FDFDFD);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
`

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
`

export const CTAWrap = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const TransparentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  // flex: 1 0 0;
  border: none;
  cursor: pointer;

  border-radius: 33.5px;
  background: transparent;

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    leading-trim: both;
    text-edge: cap;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
`
