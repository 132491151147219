import axios from "axios"
const LOCAL_URL = "http://localhost:8000/v1.0/"
export const getLeaderBoardStatsApi = query => axios.get(`${API_URL}brokerage-analytics-stats?${query}`)
export const getLeaderBoardStatsPostApi = payload => axios.post(`${API_V2_URL}brokerage-analytics-stats`, payload)
export const getTeamStatsApi = query => axios.get(`${API_URL}brokerage-team-stats?${query}`)
export const getLeaderBoardListsApi = (metric, duration, type, limit, skip, from_date, to_date) => axios
  .get(`${API_URL}leader-board?metric=${metric}&duration=${duration}&type=${type}&limit=${limit}&skip=${skip}&from_date=${from_date && parseInt(from_date, 10) || null}&to_date=${to_date && parseInt(to_date, 10) || null}`)
// eslint-disable-next-line max-len
export const getLeaderBoardMonthlyWinnerApi = (month, year) => axios.get(`${API_URL}leader-board-monthly-winner?month=${month}&year=${year}`)

export const getHomePageGraphStatsApi = payload => axios.post(`${API_URL}brokerage-clients-analytics-stats`, payload)

