import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import {
  tablet,
  smDesktop,
  xsMobile,
  preDesktopWidth,
  preTabletWidth,
  minDesktopWidth,
} from "@consts"
import colors from "@colors"
import { ATC } from "@fonts"

const Wrap = styled.div`
  padding: 0px 52px 52px 52px;
  ${minDesktopWidth`
    padding: 0px 52px 52px 52px;
  `}
  ${preDesktopWidth`
    padding: 0px 60px 60px 60px;
  `}
  ${preTabletWidth`
    padding: 0px 24px 24px 24px;
  `}
`
const Heading = styled.h1`
  font-family: Butler;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0.005em;
  text-align: center;
  width: 739px;
  margin: auto;
  color: ${colors.black};

  ${minDesktopWidth`
    width: 753px;
  `}

  ${preDesktopWidth`
    font-family: Butler;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 646px;
  `}

  ${preTabletWidth`
    font-family: Butler;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 284px;
  `}
`

const HeadContainer = styled.div`
  color: ${colors.black};
`

const Description = styled.p`
  font-family: ${ATC};
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  width: 696px;
  margin: auto;
  margin-top: 16px;
  color: ${colors.black};

  @media(min-width: 1100px) {
    font-family: ${ATC};
    font-size: ${props => props.DescTextSizeXL || "22px"};
  }

  @media(max-width: 1099px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    font-size: ${props => props.DescTextSizeSM || "22px"};
  }
 
  @media(max-width: 767px) {
    font-size: ${props => props.DescTextSizeS || "18px"};
    font-style: normal;
    font-weight: 300;
    line-height: ${props => props.lineHeightDescS || "26px"};
    letter-spacing: 0em;
    text-align: center;
    width: 284px;
  }
`

const BrandingContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 142px 0px 0px 0px;
  ${preDesktopWidth`
    padding: 90px 0px 0px 0px;
  `}
  ${preTabletWidth`
    padding: 72px 0px 0px 0px;
  `}
  color: #303030;
`

const BrandingImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 72px;
  ${preDesktopWidth`
    margin-top: 52px;
  `}
  ${preTabletWidth`
    margin-top: 24px;
  `}
`
const ImageWrap = styled.div`
  padding: 20px;
  // flex: 1 1 50%;
  flex: ${props => props.flex};
  ${preDesktopWidth`
    padding: 10px;
  `}
  ${preTabletWidth`
    padding: 12px 0px;
    flex: auto;
  `}
  img {
    width: 100%;
    height: 100%;
    // max-width: ${props => props.maxWidth};
    // max-height: 412px;
    ${preTabletWidth`
      min-height: 300px;
      object-fit: cover;
    `}
  }
`

const Text = styled.h6`
  font-family: ${ATC};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  margin-left: 17px;
  margin-top: 17px;

  ${preDesktopWidth`
    font-size: 14px;
    line-height: 20px;
  `}

  ${preTabletWidth`
    font-size: 14px;
    line-height: 20px;
  `}
`

const NonTabletImg = styled.img`
  display: block;
  @media(max-width:1099px) {
    display: none;
  }

  @media(max-width: 767px) {
    display: block;
  }
`

const TabletImage = styled.img`
  display: none;
  @media(max-width:1099px) {
    display: block;
  }

  @media(max-width: 767px) {
    display: none;
  }
`

const Branding = ({
  DescTextSizeS,
  brandingList,
  header,
  description,
  lineHeightDescS,
  DescTextSizeXL,
  DescTextSizeSM,
}) => (
  <Wrap>
    <BrandingContainerWrap>
      <HeadContainer>
        <Heading className="branding-heading">
          {header}
        </Heading>
        <Description
          DescTextSizeSM={DescTextSizeSM}
          DescTextSizeS={DescTextSizeS}
          lineHeightDescS={lineHeightDescS}
          DescTextSizeXL={DescTextSizeXL}
        >
          {description}
        </Description>
      </HeadContainer>
      <BrandingImageWrap>
        {brandingList.map(branding => (
          <ImageWrap maxWidth={branding.maxWidth} flex={branding.flex}>
            {branding.tag && (<Text>{branding.tag}</Text>)}
            <NonTabletImg src={branding.icon} alt="branding_icon.jpg" />
            <>
              {branding.tabletIcon && (
                <TabletImage src={branding.tabletIcon} alt="social.png" />
              )}
            </>
          </ImageWrap>
        ))}
      </BrandingImageWrap>
    </BrandingContainerWrap>
  </Wrap>
)

export default withRouter(Branding)
