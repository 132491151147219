import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  width: 359px;
  height: 200px;
  flex-shrink: 0;
  border-top: 4px solid ${props => props.bColor};
  border-left: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-right: 1px solid #FFF;
  border-radius: 8px;
  box-shadow: 0px 6px 6px -2px rgba(0, 0, 0, 0.25);
`

const HeroSection = ({
  bColor,
  children,
  bgColor,
}) => (
  <Wrap
    bColor={bColor}
    bgColor={bgColor}
  >
    {children}
  </Wrap>
)

export default HeroSection
