import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import colors from "@colors"
import moment from "moment"

const Wrap = styled.div``

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 300px;
  padding-bottom: 8px;
  padding: 14px 0px;
  ${props => props.isBottom && `
    border-bottom: 0.5px solid ${colors.suvaGray};
  `}
`

const Type = styled.h1`
  margin: 0;
  margin-left: 30px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
`

const Date = styled.h1`
  margin: 0;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-right: 30px;
  line-height: 17px;
`

const ApplicationDetails = styled.div``

const FinanceTransactionList = ({
  financingApplications,
}) => {
  return (
    <Wrap>
      <Header
        isBottom
      >
        <Type>
          Type
        </Type>
        <Date>
          Application Date
        </Date>
      </Header>
      <ApplicationDetails>
        {financingApplications && financingApplications.map(application => (
          <Header>
            <Type>
              {application.source}
            </Type>
            <Date>
              {moment(application.updated_at).format("MMMM D, YYYY")}
            </Date>
          </Header>
        ))}
      </ApplicationDetails>
    </Wrap>
  )
}

export default FinanceTransactionList
