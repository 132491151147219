import React from "react"
import {
  Button,
} from "./styles"

const AddButton = ({
  name,
  onClick,
  height,
  width,
  disabled = false,
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    height={height}
    width={width}
    type="button"
  >
    <img src={`${CLOUDFRONT}/OFFERS_V2/addIcon.svg`} alt="add_icon.svg" />
    <p>{name}</p>
  </Button>
)

export default AddButton
