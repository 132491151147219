import React from "react"
import styled from "styled-components"
import icons from "@icons"
import colors from "@colors"
import Loader from "@ui/Loader"
import Slider from "react-slick"
import ArrowImgRight from "images/academy/event-arrow-right.svg"

const MediaWrapper = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.div`
  height: 100%;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
`

const StyledUploadedImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: ${props => props.loading ? "grayscale(100%)" : "none"};
`

const NextArrowWrap = styled.div`
  img {
    position: absolute;
    width: 75px;
    height: 75px;
    ${props => props.dir === "right" && `
      top: -25px;
      left: -25px;
    `}
    ${props => props.dir === "left" && `
      transform: rotate(180deg);
      top: -30px;
      left: -30px;
    `}
  }
`

const DirectionArrow = ({ dir, onClick, className, style }) => (
  <NextArrowWrap
    onClick={(e) => {
      e.stopPropagation()
      onClick()
    }}
    className={className}
    style={{ ...style }}
    dir={dir}
  >
    <img src={ArrowImgRight} alt="Arrow Icon" />
  </NextArrowWrap>
)

const SLIDER_SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  nextArrow: <DirectionArrow dir="right" key="right" />,
  prevArrow: <DirectionArrow dir="left" key="left" />,
  centerMode: false,
}

const CloseIconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  background-color: ${colors.tagInactive};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCloseIcon = styled.i`
  font-size: 10px;
  color: ${colors.contentText};
`

const ImageDiv = styled.div`
  position: relative;
  width: 300px;
`

const MediaPreviewSection = (props) => {
  const { isMediaUploading, mediaURL, removeMedia, mediaArray } = props
  return (
    <MediaWrapper>
      <ImageWrapper>
        {isMediaUploading
          ? <Loader />
          : mediaArray && mediaArray.length > 0 && (
            <React.Fragment>

              <Slider {...SLIDER_SETTINGS}>
                {mediaArray.map(img => (
                  <ImageDiv>
                    <CloseIconContainer onClick={() => { removeMedia(img.imageId) }}>
                      <StyledCloseIcon className={icons.arrowsClose} />
                    </CloseIconContainer>
                    <StyledUploadedImage src={img.url} alt="Create new post" />
                  </ImageDiv>
                ))}
              </Slider>
              
              {/* {mediaArray.map(img => (
                <ImageDiv>
                  <CloseIconContainer onClick={() => { removeMedia(img.imageId) }}>
                    <StyledCloseIcon className={icons.arrowsClose} />
                  </CloseIconContainer>
                  <StyledUploadedImage src={img.url} alt="Create new post" />
                </ImageDiv>
              ))} */}
            </React.Fragment>
          )
        }
      </ImageWrapper>
    </MediaWrapper>
  )
}

export default MediaPreviewSection
