export const MAIN_MENU = "/realty/transaction-coordination"

export const BASIC_FORM_ROUTE = "/realty/transaction-coordination/basic-form"

export const DETAILS_FORM_ROUTE = "/realty/transaction-coordination/details-form"

export const COORDINATION_FORM_ROUTE = "/realty/transaction-coordination/coordination-form"

export const TCAGENT_FORM_ROUTE = "/realty/transaction-coordination/tc-agent-form"

export const ESCROW_FORM_ROUTE = "/realty/transaction-coordination/escrow-form"

export const SIGN_POST_FORM_ROUTE = "/realty/transaction-coordination/sign-post-form"

export const UPLOAD_FILE_FORM_ROUTE = "/realty/transaction-coordination/upload-file-form"

export const SUCCESS_ROUTE = "/realty/transaction-coordination/contract/successfully-filed"
