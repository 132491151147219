import React from "react"
import styled from "styled-components"
import { ATC, INTER } from "@fonts"
import colors from "@colors"

import {
  Form,
  AutoComplete,
  Checkbox,
  DatePicker,
  InputNumber,
  Input,
  Mentions,
  Rate,
  Radio,
  Switch,
  Slider,
  Select,
  TreeSelect,
  Transfer,
  TimerPicker,
  Upload,
  Drawer,
  Tabs,
  Tooltip,
  Menu,
} from "antd"

const { TextArea } = Input

const NewStyledInput = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid ${colors.new_borderGrey} !important;
  color: ${colors.new_grey400} !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: ${INTER} !important;
  font-weight: 400 !important;
  padding: 10px 14px !important;

  &:not(:disabled):focus, 
  &:not(:disabled):hover {
    border: 1px solid ${colors.new_primary} !important;
  }

  &:placeholder {
    color: ${colors.new_placeholder} !important;
  }
`

const NewStyledInputNumber = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid ${colors.new_borderGrey} !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  overflow: hidden !important;

  &:not(:disabled):focus, 
  &:not(:disabled):hover, 
  &:not(:disabled).ant-input-number-focused {
    border: 1px solid ${colors.new_primary} !important;
  }

  input {
    color: ${colors.new_grey400} !important;
    font-family: ${INTER} !important;
    font-weight: 400 !important;
    padding: 10px 14px !important;
    height: 49px !important;
  }

  input:placeholder {
    color: ${colors.new_placeholder} !important;
  }
`

const StyledInput = styled(Input)`
`

const StyledSelect = styled(Select)`
  ${props => props.alignTextLeft && `
    .ant-select-selection-placeholder {
      text-align: left;
    }
    .ant-select-selection-item {
      text-align: left;
    }
  `}
`

const NewStyledSelect = styled(Select)`
  * {
    font-family: ${INTER} !important;
    font-weight: 400 !important;
  }

  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid ${colors.new_borderGrey} !important;
    color: ${colors.new_grey400} !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    font-family: ${INTER} !important;
    font-weight: 400 !important;
    padding: 10px 14px !important;
  }

  &.ant-select-focused .ant-select-selector {
    border: 1px solid ${colors.new_primary} !important;
  }

  .ant-select-arrow {
    color: ${colors.new_grey400} !important;
  }

  &.ant-select-focused .ant-select-selector .ant-select-selection-item {
    color: ${colors.new_grey400} !important;
  }

  &.ant-select-multiple .ant-select-selector {
    height: 100% !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    height: 42px !important;
    align-items: center !important;
  }
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-family: ${ATC};
  }
  .ant-drawer-body {
    padding: 0px;
  }
  ${props => props.fontSize && `.ant-drawer-title { font-size: ${props.fontSize || "16px"}}`}
  ${props => props.hideDrawerTitle && ".ant-drawer-header { display: none; }"}
  ${props => props.CloseIconRight && `
    .ant-drawer-header-title {
      position: relative;
    }
    .ant-drawer-close {
      position: absolute;
      right: 0;
    }
  `}
`
const StyledTextArea = styled(TextArea)``

const NewStyledRadioButton = styled(Radio.Button)`
  border-radius: 7px !important;
  background: ${colors.new_colorNeutral200} !important;
  display: inline-flex !important;
  padding: 4px 8px !important;
  justify-content: center !important;
  align-items: center !important;
  color: ${colors.new_grey} !important;
  text-align: center !important;
  font-family: ${INTER} !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border: none !important;
  min-width: 80px !important;

  &:not(:disabled):hover {
    background: ${colors.new_colorNeutral300} !important;
    color: ${colors.new_grey} !important;
  }

  &.ant-radio-button-wrapper-checked,
  &.ant-radio-button-wrapper-checked:hover {
    border: none;
    background: ${colors.new_primary} !important;
    color: ${colors.new_colorNeutral50} !important;
    line-height: 17px !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px ${colors.new_primary + "80"} !important;
  }

  &.ant-radio-button-wrapper:not(:first-child)::before {
    content: none !important;
  }

  &.ant-radio-button-wrapper:first-child {
    border-left: 0 !important;
  }
`

const NewStyledRadioGroup = styled(Radio.Group)`
  padding: 2px !important;
  border-radius: 9px !important;
  background: ${colors.new_colorNeutral200} !important;
  display: flex !important;
  width: fit-content;
`

export {
  Form,
  AutoComplete,
  Checkbox,
  DatePicker,
  InputNumber,
  Mentions,
  Rate,
  Radio,
  Switch,
  Slider,
  TreeSelect,
  Transfer,
  TimerPicker,
  Upload,
  StyledInput as Input,
  StyledSelect as Select,
  StyledDrawer as Drawer,
  Tabs,
  StyledTextArea,
  Tooltip,
  NewStyledInput as NewInput,
  NewStyledSelect as NewSelect,
  NewStyledInputNumber as NewInputNumber,
  NewStyledRadioButton as NewRadioButton,
  NewStyledRadioGroup as NewRadioGroup,
  Menu,
}
