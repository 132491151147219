import React from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import {
  PinUser,
} from "../VideoContainer/Controls/Icons"

const IconWrap = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition-duration: 0.3s;
  transform: scale(1.5);
  filter: brightness(10);
`

const Wrap = styled.div``

const Pin = ({ id }) => (
  <Wrap
    id={`pin-container-${id}`}
    className="hide-pin"
  >
    <IconWrap>
      <PinUser />
    </IconWrap>
  </Wrap>
)
export default Pin
