import US_STATES from "./US_STATES_RADIUS.json"
import US_STATES_UPCOMING from "./US_STATES.json"

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))
const UPCOMING_STATE_OPTIONS = Object.keys(US_STATES_UPCOMING).map(key => ({ label: US_STATES_UPCOMING[key], value: US_STATES_UPCOMING[key] }))

const groupedOptions = [
  {
    label: "Available",
    options: STATE_OPTIONS.map(key => ({ value: key.value, label: key.label })),
  },
  {
    label: "Coming Soon",
    options: UPCOMING_STATE_OPTIONS.map(key => ({ value: key.value, label: key.label })),
  },
]

const mixedOptions = [
  ...STATE_OPTIONS,
  ...UPCOMING_STATE_OPTIONS,
]
export {
  mixedOptions,
  groupedOptions,
}
