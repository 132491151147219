import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  fetchSubscriptions,
  fetchDefaultCard,
  updateCard,
  toggleUpdateCardModal,
  fetchInvoices,
} from "container/Referral/actionCreators"
import { getSavedCard } from "./selector"
import * as ReferralSelectors from "container/Referral/selector"
import * as Actions from "./actions"

const mapStateToProps = state => ({
  categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
  subscriptions: ReferralSelectors.getSubscriptionsFromStore(state),
  isFetchingSubscription: ReferralSelectors.getIsFetchingSubscriptions(state),
  defaultCard: ReferralSelectors.getDefaultCardFromStore(state),
  isFetchingCard: ReferralSelectors.getIsFetchingCardFromStore(state),
  updateCardModalState: ReferralSelectors.getUpdateCardModalStateFromStore(state),
  isFetchingInvoices: ReferralSelectors.getIsFetchingInvoicesFromStore(state),
  invoices: ReferralSelectors.getInvoicesFromStore(state),
  pricingPlansMap: ReferralSelectors.pricingPlansMap(state),
  savedCard: getSavedCard(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchSubscriptions,
    fetchDefaultCard,
    updateCard,
    toggleUpdateCardModal,
    fetchInvoices,
    saveStripeToken: Actions.saveStripeTokenAction.request,
    getCardDetails: Actions.getCardDetailsAction.request,
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
