//GoogleAutoComplete
import React from "react"
import { Form, GoogleAutoComplete } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { get } from "lodash"
import {
  StyledGoogleAutoCompleted,
} from "./styles"

const InputField = ({
  placeholder,
  type,
  name,
  required = false,
  style,
  customRules,
  inputType,
  onChange,
  locationChange,
  formRef,
  fieldIndex,
  fullAddress = false,
  formName,
  text,
  requiredMessage = "",
  ignoreFields = [],
  handleInitChange,
  disabled,
  rules,
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required: true, type: "email" }]
      case "custom":
        return [{ ...customRules }]
      case "colisting_agent_address":
          return rules || [{required: false, message: requiredMessage}]
      default:
        return rules || [{ required, message: requiredMessage }]
    }
  }

  const setStreet = (location, formRef, key, ignoreFields) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      zipCode: get(location, "postal_code.long_name"),
      streetNumber: get(location, "street_number.long_name"),
      county: get(location, "administrative_area_level_2.long_name"),
      route: get(location, "route.long_name"),
    }

    console.log("locationDetails", locationDetails)

    const addressString = locationDetails.streetNumber
      && locationDetails
        .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : locationDetails.route

    if (fieldIndex >= 0) {
      const fields = formRef.current.getFieldsValue()
      const currentFields = fields[formName]

      const isIgnoreFieldsPresent = ignoreFields && ignoreFields.length > 0

      if (isIgnoreFieldsPresent) {
        Object.assign(currentFields[fieldIndex], {
          [key[key.length - 1]]: addressString || locationDetails.address,
        })
      } else {
        Object.assign(currentFields[fieldIndex], {
          [key[key.length - 1]]: addressString || locationDetails.address,
          city: locationDetails.city,
          state: locationDetails.state,
          zipcode: locationDetails.zipCode,
          county: `${locationDetails.county || ""}`,
          country: locationDetails.country,
        })
      }

      formRef.current.setFieldsValue({
        [formName]: currentFields,
      })
    } else {
      const formObject = {
        [key]: fullAddress ? locationDetails.address : addressString || locationDetails.address,
        city: locationDetails.city,
        state: locationDetails.state,
        zipcode: locationDetails.zipCode,
        county: `${locationDetails.county || ""}`,
        country: locationDetails.country,
      }

      if (ignoreFields && ignoreFields.length > 0) {
        for (let i = 0; i < ignoreFields.length; i += 1) {
          formObject[ignoreFields[i]] = null
        }
      }

      formRef.current.setFieldsValue({
        ...formObject,
      })

      handleInitChange(formObject)
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={`${text}`} required={required} />}
      required={required}
    >
      <StyledGoogleAutoCompleted
        id="cities"
        types={["address"]}
        name="cities"
        // formValue={cityFormValue}
        onChange={(e) => { }}
        location={(loc) => { setStreet(loc, formRef, name, ignoreFields) }}
        placeholder={placeholder}
        disabled={disabled}
        styles={{
          borderRadius: "8px !important",
          border: "1px solid #D0D5DD !important",
          padding: "12px 16px !important",
          height: "42px !important",
        }}
      />
    </Form.Item>
  )
}

export default InputField
