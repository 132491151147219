/* eslint-disable arrow-body-style */
import styled from "styled-components"
import { INTER, MONTSERRAT } from "@fonts"
import colors from "@colors"
import { Link } from "react-router-dom"
import LinkOrA from "dumbComponents/common/Navbar/LinkOrA"
import {
  mobile,
  NAVBAR_HEIGHT,
  SIDEBAR_WIDTH,
  ONBOARDING_BANNER_HEIGHT,
} from "@consts"

export const SidebarContainer = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  height: calc(100vh - calc(${NAVBAR_HEIGHT}px / 1.5));
  position: fixed;
  left: 0px;
  top: ${NAVBAR_HEIGHT}px;
  ${props => props.showOnboardingNav && `
    // add onboarding banner height
    top: calc(${NAVBAR_HEIGHT}px + ${ONBOARDING_BANNER_HEIGHT}px);
  `}
  background: var(--sidebar-background);

  padding: 48px 24px 72px 24px;

  ${props => props.showLoaderUntilTeamDetailsAreFetched && `
    padding: 0;
  `}

  z-index: 200;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  ${props => props.isHide && `
    visibility: hidden;
  `}

  ${mobile`
    display: none;
  `}
`

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:not(:first-of-type) {
    margin-top: 22px;
  }

  ${props => props.hide && `
    display: none;
  `}

  ${props => props.padding && `
    padding: 10px;
  `}

  ${props => props.marginTop && `
    margin-top: 32px;
  `}


  ${props => props.noMarginBottom && `
    margin-bottom: unset;
  `}

  ${props => props.isBannerOpen && `
    padding-top: 20px;
  `}

  .header {
    margin-bottom: 4px;

    p {
      color: var(--sidebar-item-text-color);
      font-family: ${INTER};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  ${props => props.bottomSupport && `
    display: none;
    position: relative;
    top: 80px;
  `}
`

export const Header = styled.div`
  margin-bottom: 15px;
`

export const BrokerageWrap = styled.div`
  padding-left: 20px;
`

export const SidebarSectionWrap = styled.div`
margin-bottom: 20px;
color: #303030;
padding-top: 15px;
padding-left: 7px;
transition-duration: 0.3s;
.brokerage {
  margin-top: 10px;
  font-size: 12px;
  color: #666b71;
  width: 80%;
}
`

export const ApplyButton = styled.a`
  color: ${colors.new_colorNeutral200};
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  cursor: pointer;

  &:hover {
    color: ${colors.new_colorNeutral200};
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const InnerTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 8px 12px;
  transition: 0.3s ease-out;
  // important to make the NavLink clickable throughout the nav item
  position: relative;
  
  svg * {
    stroke: var(--sidebar-item-text-color);
  }

  a {
    color: var(--sidebar-item-text-color);
  }

  ${props => props.isActive && `
    background: var(--sidebar-item-active-background);

    a {
      color: var(--sidebar-item-active-text-color);
    }

    svg * {
      stroke: var(--sidebar-item-active-text-color);
    }
  `}

  &:hover {
    background: var(--sidebar-item-active-background);

    a {
      color: var(--sidebar-item-active-text-color);
    }

    svg * {
      stroke: var(--sidebar-item-active-text-color);
    }
  }

  .notification-block {
   width: 10%;
   min-width: 10%;
  }

  .icon-block {
    height: 100%;
    width: 18%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    img {
      width: 18px;
      height: 18px;
      color: #3B60E4;
      fill: #3B60E4;
    }
  }

  .icon-block-new {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

    img, svg {
      width: 22px;
      height: 22px;
    }
  }

  .text-block {
    // to visually center the text with the icon
    margin-bottom: -3px;
  }
`
export const SubLink = styled(LinkOrA)`
text-decoration: none;
font-size: 14px;
font-weight: 500;
color: #303030;
margin-left: 3px;

${props => props.isActive && `
  color: ${colors.contentText};
  font-weight: 500;
`}
`

export const NotificationDot = styled.div`
position: relative;
z-index: 1000;
height: 8px;
width: 8px;
background: ${props => props.zero ? "transparent" : "#ea526f"};
display: inline-grid;
border-radius: 18px;
left: 11px;
top: 1px;
${props => props.zero && `
  display: none;
`}
${props => !props.zero && `
  margin-right: -9px;
`}
`

export const InfoTag = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background: ${colors.new_colorNeutral200};
  padding: 0px 10px;
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${props => props.hasSmallerFontSize && `
    font-size: 12px;
  `}
`

export const NotificationIndicator = styled.div`
  border-radius: 18px;
  background: #FFB4B4;
  display: flex;
  width: 10px;
  height: 10px;
`

export const IconEle = styled.img``

export const NavLink = styled(Link)`
  color: var(--sidebar-item-text-color);
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    color: var(--sidebar-item-active-text-color:)
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  ${props => props.Header && `
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #666b71;
    margin-left: 20px;
  `}

  ${props => props.notif && `
    // margin-left: -5px;
    `}
  ${props => props.academy && `
    // margin-left: -5.5px;
    `}

  ${props => props.isActive && `
    // font-weight: 500;
    // color: ${colors.marineBLue};
  `}

  i {
    font-size: 8px;
    margin-left: 10px;
  }

  a {
    font-size: 10px;
    color: ${colors.primaryColor};
    font-weight: 100;
  }

  ${props => props.ml && `
    // margin-left: ${props.ml || "-5px"};
  `}

  ${props => props.flex && `
      display: flex;
  `}

  span {
    position: relative;
    left: 12px;
    border-radius: 34px;
    height: 16px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 20px;
    bottom: 0px;
    padding: 5px;

    p {
      font-size: 10px !important;
      color: white !important;
      position: relative;
      text-align: center !important;
      top: -1px;
    }
  }
`

export const ImageSpan = styled.span`
  position: relative;
  margin-top: -5px;
  ${props => props.fa && `
    right: -5px;
    top: 4px;
  `}

  ${props => props.broker && `
    left: 110px;
    bottom: 25px;
  `}
  ${props => props.tp && `
    top: ${props.tp}
  `}
  img {
    height: 17px;
    width: 40px;
  }

  ${props => props.teamProfile && `
    position: relative;
    top: 4px;
    left: 6px;
  `}
  top: 0;
`

export const TeamImage = styled.img`
  border-radius: 5px;
  max-width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
`

export const TeamNameLabel = styled.p`
  color: var(--sidebar-item-text-color);
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
  transition: 0.3s ease-out;
`

export const TeamName = styled.p`
  color: var(--sidebar-item-text-color);
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
  transition: 0.3s ease-out;
`

export const TeamDetailsContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  margin-bottom: 22px;
  transition: 0.3s ease-out;
  background: var(--sidebar-background);

  &:hover {
    background: var(--sidebar-item-active-background);
    
    ${TeamName}, ${TeamNameLabel} {
      color: var(--sidebar-item-active-text-color);
    }
  }

  ${props => props.isActive && `
    background: var(--sidebar-item-active-background);
  `}

  ${props => !props.allowInteractions && `
    pointer-events: none;
    user-select: none;
  `}

  &:before {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 8px;
    background: var(--sidebar-border);
    z-index: -1;
  }
`

export const SidebarBody = styled.div``

export const SidebarFooter = styled.div`
  border-top: 1px solid #595E93;
  padding-top: 16px;
  margin-top: 22px;
`

export const UserProfileContainer = styled.div``

export const UserProfileLabel = styled.div`
  color: var(--Color-Neutral-50, #FDFDFD);
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const UserProfileDetails = styled.div``

export const UserProfileImage = styled.img``

export const UserProfileName = styled.div``
