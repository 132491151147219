import React, { useState } from "react"
import { reduxForm, Field, formValueSelector } from "redux-form"
import { connect } from "react-redux"
import { Textarea, Input } from "@ui/Form"
import styled from "styled-components"
import colors from "@colors"
import createToast from "@ui/Toast"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import Privacy from "./Privacy"
import InviteSpeakers from "./InviteSpeakers"
import InviteSpeakerModal from "../../InviteAgent"
import {
  RoomsButton,
  RoomsButtonInverted,
  CTAWrap,
} from "../common"

const errorStyles = {
  color: "red",
  fontWeight: "300",
  fontSize: "14px",
  marginTop: "7px",
}

const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const Wrap = styled.div`
  height: 32px;
  width: 206px;
  display: flex;
  background: ${colors.marineBLue};
  align-items: center;
`

const maxLength = max => value => value
  && value.length > max ? "Word limit exceeded" : undefined

const customStyleInputArea = {
  border: "none",
  background: `${colors.white}`,
  borderRadius: "8px",
  boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
  width: "100%",
}

const DetailsFormUI = (props) => {
  const {
    handleSubmit,
    change,
    is_public,
    speakers,
    isInviteAgentModalOpen,
    toggleInviteSpeakersModal,
    fetchAgentSearch,
    agents,
    clearAgents,
    specialRoomType,
    setPage,
    creatingRoom,
  } = props || {}

  const [searchText, setSearchText] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)

  const handleRemoveTag = (speaker) => {
    const updatedTags = speakers.filter(x => x.id !== speaker.id)
    change("speakers", updatedTags)
  }

  const handleSearchInput = (event) => {
    const {
      target: {
        value,
      },
    } = event || {}
    setShowDropDown(true)
    setSearchText(value)

    /** */

    const payload = {
      sortIsAsc: false,
      from: 0,
      size: 15,
      facade: true,
      sortByEntity: "agentScore",
      fullname: value,
      unRegistered: false,
      requiredFacades: [
        "wcr_pmn",
        "nar_investor_level",
        "specializations",
        "languages",
        "associations",
      ],
    }

    const delayedFetch = () => {
      fetchAgentSearch({ payload })
    }

    delayedFetch()

    if (event.target.value === "") {
      setShowDropDown(false)
    }
  }

  const handleRemoveSpeakers = (agent) => {
    const updatedSpeakers = speakers.filter(x => x.id !== agent.id)
    change("speakers", updatedSpeakers)
  }

  const handleSpeakerState = (agent) => {
    if (speakers.length === 2) {
      createToast("Cannot add more than 2 speakers", "error")
      return
    }
    change("speakers", [...speakers, agent])
    clearAgents()
    setSearchText("")
  }

  return (
    <FormWrap onSubmit={handleSubmit}>
      <InnerWrap>
        <Privacy
          change={change}
          is_public={is_public}
        />
        <InviteSpeakers
          speakers={speakers}
          handleRemoveTag={handleRemoveTag}
          toggleInviteSpeakersModal={toggleInviteSpeakersModal}
        />
        <Field
          name="about_speaker"
          label="About Speakers"
          validate={maxLength(250)}
          rows={4}
          version={2}
          component={Textarea}
          isVideoRoom
          customStyle={customStyleInputArea}
          errorStyles={errorStyles}
          charLimit={250}
        />
        {isInviteAgentModalOpen && (
          <InviteSpeakerModal
            toClose={() => toggleInviteSpeakersModal(false)}
            searchText={searchText}
            handleSearchInput={handleSearchInput}
            showDropDown={showDropDown}
            handleSpeakerState={handleSpeakerState}
            agents={agents}
            speakers={speakers}
            handleRemoveSpeakers={handleRemoveSpeakers}
          />
        )}
      </InnerWrap>
      <CTAWrap>
        <RoomsButtonInverted
          type="button"
          onClick={() => setPage({
            page: "room-details",
            type: specialRoomType,
          })}
        >
          <p>Go Back</p>
        </RoomsButtonInverted>
        {creatingRoom ? (
          <Wrap>
            <ThreeDotLoader color="#fff" />
          </Wrap>
        ) : (
          <RoomsButton
            type="submit"
          >
            <p>Start Room</p>
          </RoomsButton>
        )}
      </CTAWrap>
    </FormWrap>
  )
}

const DetailsForm = reduxForm({
  form: "ROOMS_DETAILS_FORM_II",
  destroyOnUnmount: false,
  initialValues: {
    is_public: 1,
    speakers: [],
    about_speaker: "",
  },
})(DetailsFormUI)

const formSelector = formValueSelector("ROOMS_DETAILS_FORM_II")

const SelectedForm = connect((state) => {
  const is_public = formSelector(state, "is_public")
  const speakers = formSelector(state, "speakers")
  const about_speaker = formSelector(state, "about_speaker")

  return {
    is_public,
    speakers,
    about_speaker,
  }
})(DetailsForm)

export default SelectedForm
