import React from "react"
import styled from "styled-components"
import colors from "@colors"
import CrossIcon from "images/Close_7.svg"

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.silentWhite};
`

const TitleWrap = styled.div``

const ImageWrap = styled.div`
  position: relative;
  cursor: pointer;
  top: 6px;
`

const Title = styled.h1`
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`

const FilterHeader = ({
  toggleFilterComponent,
}) => (
  <Wrap>
    <TitleWrap>
      <Title>Filter Leaderboard</Title>
    </TitleWrap>
    <ImageWrap onClick={() => toggleFilterComponent(false)}>
      <img src={CrossIcon} alt="closeIcon" />
    </ImageWrap>
  </Wrap>
)

export default FilterHeader
