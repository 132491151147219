import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div``

export const HeaderWrapH1 = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 4px;
  flex-direction: column;

  h1 {
    color: var(--Gray-900, #101828);
    font-family: ${INTER};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
  }

  h2 {
    color: var(--Gray-500, #667085);
    /* Text sm/Normal */
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  h1,h2 {
    margin: 0;
  }
`

export const CreateListingButtonWrap = styled.div`
 button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 22px;
  border: 1px solid var(--Color-Primary-Primary, #5A5FF2);
  background: var(--Color-Primary-Primary, #5A5FF2);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  span {
    color: #F9F9F9;
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    img {
      height: 20px;
      width: 20px;
    }
  }  
 }
`

export const StatsCard = styled.div`
  display: inline-flex;
  height: 132px;
  padding: 16px;
  align-items: flex-start;
  gap: 28px;
  flex-shrink: 0;
  width: 491px;
  height: max-content;

  border-radius: 12px;
  border: 1px solid var(--Gradient-1, #B6B8FF);
  background: var(--GLASS_FILL, rgba(229, 230, 255, 0.35));

  /* GLASS_EFFECT */
  box-shadow: 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  backdrop-filter: blur(16.799999237060547px);

  /*for position*/
  position: relative;

  @media(max-width: 1315px) {
    width: 451px;
  }
`

export const StatImageCircle = styled.div`
  display: flex;
  width: 63px;
  height: 63px;
  padding: 7.08px;
  justify-content: center;
  align-items: center;
  gap: 14.161px;
  flex-shrink: 0;

  border-radius: 177.012px;
  background: var(--Gradient-1-Radial, radial-gradient(81.3% 81.3% at 36.38% 24.87%, #B6B8FF 0%, #5A5FF2 54.17%, #F0A468 99.99%, #F0A468 100%));
  box-shadow: 0px 5.25px 13.125px 0px rgba(165, 168, 255, 0.30);
  backdrop-filter: blur(22.049999237060547px);

  position: absolute;
  top: -33px;
  left: 15px;



  span img {
    display: flex;
    width: 36.75px;
    height: 36.75px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
`

export const StatsInnerWrap = styled.div`
  display: flex;  
  padding: 20px 0px 16px 0px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const TitleAndPercentage = styled.div``

export const StatTitle = styled.div`
  h1 {
    color: var(--Color-Gray-500, #35364A);
    font-feature-settings: 'dlig' on;
    font-family: ${INTER};
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 90.909% */
  }
`

export const Percentage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  span img {
    width: 24px;
    height: 24px;
  }

  p {
    color: ${props => props.textColor};
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.814px;
    margin: 0;
  }
`

export const AccumalatedValue = styled.div`
  display: flex;
  //width: 160px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;

  font-family: ${MONTSERRAT};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 64.8px */
  letter-spacing: 0.814px;

  background: var(--Gradient-1, linear-gradient(146deg, #B6B8FF -28.78%, #5A5FF2 21.34%, #F08068 82.37%, #F0A468 110.69%, #F0A468 110.7%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const FilterWrap = styled.div`
  display: flex;
  gap: 20px;
`

export const ClearFilterButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
`
export const ListedDocsWrap = styled.div`
  padding-top: 33px;

  ${props => props.padding && `
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 9px;
    background: var(--Color-Neutral-100, #F1F1FE);
  `}
`

export const DocsTypeTitle = styled.p`
  color: var(--BG-Dark-mode, #292A37);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
`
