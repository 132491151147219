import React, { useState } from "react"
import Container from "container/Profile"
import queryString from "query-string"
import { Select } from "@ui/antd"
import USStates from "shared/RADIUS_US_STATES.json"
import { withRouter } from "react-router-dom"
import Loader from "@ui/Loader"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import GetStartedImage from "../../Images/GetStartedImage.svg"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  SignupButtons,
  InputWrap,
  CheckboxElement,
  SelectElement,
} from "../../common"

const { Option } = Select

const STATE_OPTIONS = Object.keys(USStates).map(key => ({ label: USStates[key], value: USStates[key] }))

const LetsConnectPage = ({
  history,
  saveProfileUpdate,
  isSavingUpdates,
  verifyEmailTokenResponse,
}) => {
  const { data, isFetching } = verifyEmailTokenResponse || {}
  const { user } = data || {}
  const parsedQuery = queryString.parse(history.location.search)
  const { email } = parsedQuery || {}
  const [firstname, setFirstName] = useState(user ? user?.firstname : "")
  const [lastname, setLastName] = useState(user ? user?.lastname : "")
  const [number, setNumber] = useState(user ? user?.phone : "")
  const [state, setStates] = useState()
  const handleClick = () => {
    const payload = {
      firstname,
      lastname,
      phone: number,
      state,
      redirectToAppDownload: true,
    }
    saveProfileUpdate(payload)
  }

  return (
    <Wrap>
      {isFetching ? <Loader /> : (
        <ContentWrap>
          <RightWrap>
            <WelcomeContentWrap>
              <WelcomeTitle width="fit-content">
                Welcome To Our Community
              </WelcomeTitle>
              <WelcomeDescription width="448px">
                Please tell us more about yourself.
              </WelcomeDescription>
              <InputWrap
                width={350}
                bordered
                placeholder="First Name"
                value={firstname}
                onChange={e => setFirstName(e.target.value)}
                style={{
                  borderRadius: 9,
                  border: "1px solid #5A5FF2",
                  borderColor: "5A5FF2",
                }}
              />
              <InputWrap
                width={350}
                bordered
                placeholder="Last Name"
                value={lastname}
                onChange={e => setLastName(e.target.value)}
                style={{
                  borderRadius: 9,
                  border: "1px solid #5A5FF2",
                  borderColor: "5A5FF2",
                }}
              />
              <InputWrap
                width={350}
                bordered
                placeholder="Phone Number"
                value={normalizePhone(number)}
                normalize={normalizePhone}
                onChange={e => setNumber(e.target.value)}
                style={{
                  borderRadius: 9,
                  border: "1px solid #5A5FF2",
                  borderColor: "5A5FF2",
                }}
              />
              <SelectElement
                width={350}
                bordered
                placeholder="State"
                value={state}
                onChange={e => setStates(e)}
              >
                {STATE_OPTIONS && STATE_OPTIONS.map(item => (
                  <Option value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </SelectElement>
            </WelcomeContentWrap>
            <WelcomeContentWrap>
              <CheckboxElement><WelcomeDescription fontSize="13px">I agree to be contacted by Radius via call, email, and text. To opt out, you can reply 'stop' and any time or click the unsubscribe link in the emails. Message and data rates may apply.</WelcomeDescription></CheckboxElement>
            </WelcomeContentWrap>
            <GetStartedWrap
              noHide
            >
              <SignupButtons
                width="123px"
                onClick={handleClick}
                disabled={isSavingUpdates}
              >
                {isSavingUpdates ? <ThreeDotLoader color="#FFFFFF" /> : "Submit"}
              </SignupButtons>
            </GetStartedWrap>
          </RightWrap>
          <LeftWrap justifyContent="flex-end">
            <LeftImage src={GetStartedImage} />
          </LeftWrap>
        </ContentWrap>
      )}
    </Wrap>
  )
}

export default withRouter(Container(LetsConnectPage))
