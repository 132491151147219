export function moneyFormatter(number = 0, decPlaces = 0, isSupportNA, isAddDollarSign) {
  let wasNegativeNumber = number < 0
  if (number < 0) {
    number = Math.abs(number)
  }
  if (number === 0 && isSupportNA) {
    return "N/A"
  }
  decPlaces = Math.pow(10, decPlaces)
  const abbrev = ["K", "M", "B", "T"]
  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)
    if (size <= number) {
      number = Math.round(number * decPlaces / size) / decPlaces
      if ((number == 1000) && (i < abbrev.length - 1)) {
        number = 1
        i++
      }
      number += abbrev[i]
      break
    }
  }
  if (wasNegativeNumber) {
    return isAddDollarSign ? `-$${number}` : `-${number}`
  }
  return isAddDollarSign ? `$${number}` : number
}

export const USCurrencyFormat = number => new Intl.NumberFormat(
  "en-US",
  { style: "currency", currency: "USD" }
).format(number).replace(/\D00$/, "")

export function addCommas(nStr) {
  let x, x1, x2
  nStr += '';
  x = nStr.split('.');
  x1 = x[0];
  x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export const numberWithComma = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const nonZeroCurrencyRegex = /^\$([1-9][0-9]{0,2}(,\d{3})*|[1-9][0-9]*|0\.[1-9][0-9]*|[1-9][0-9]*\.[0-9]+)$/
