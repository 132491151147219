import { createSignalAction, createSimpleCreator } from "shared/reduxUtils";

const BASE = "BRBC";

export const fetchPresignedBrbcDocs = createSignalAction(
  BASE,
  "FETCH_PRESIGNED_BRBC_DOCS"
);

export const sendBrbcReminderAction = createSignalAction(
  BASE,
  "SEND_BRBC_REMINDER"
);

export const newBrbcCoversheetAction = createSignalAction(
  BASE,
  "NEW_BRBC_COVERSHEET"
);

export const fetchBrbcCoversheetAction = createSignalAction(
  BASE,
  "FETCH_BRBC_COVERSHEET"
);

export const voidBrbcAction = createSignalAction(BASE, "VOID_BRBC_COVERSHEET");

export const uploadBrbcAction = createSignalAction(BASE, "UPLOAD_BRBC_DOC");

export const voidRestartBrbcAction = createSignalAction(
  BASE,
  "VOID_RESTART_BRBC_COVERSHEET"
);

export const sendBrbcEnvelopeAction = createSignalAction(
  BASE,
  "SEND_BRBC_ENVELOPE"
);

export const fetchBRBCStatusAction = createSignalAction(
  BASE,
  "FETCH_BRBC_STATUS"
);

export const senderViewAction = createSignalAction(BASE, "BRBC_SENDER_VIEW");

export const receiverViewAction = createSignalAction(
  BASE,
  "BRBC_RECEIVER_VIEW"
);

export const getAgentDetailsFromStateAction = createSignalAction(
  BASE,
  "GET_AGENT_DETAILS_FROM_STATE"
);

export const deleteCoBuyerAction = createSignalAction(BASE, "DELETE_CO_BUYER");

export const deleteBrbcEnvelopeAction = createSignalAction(
  BASE,
  "DELETE_BRBC_ENVELOPE"
);

export const deleteBrbcDocAction = createSignalAction(BASE, "DELETE_BRBC_DOC");
