import React, { useRef, useEffect } from "react"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  SelectField,
  InputLocation,
  InputNumber,
} from "dumbComponents/common/InputFields"
import {
  FormFieldWrap,
  FormWrap,
} from "./commonStyles"
// import {
//   InputField,
//   SelectField,
//   InputLocation,
//   InputNumber,
// } from "../../InputFields"

import { getInputFieldsBasedOnState } from "./formConfig"

const PropertyInformationForm = ({
  onFinish,
  onFinishFailed,
  prefilledData,
  handleOnTypeChangeForPropertyInformation,
  state = "california",
  disabled,
  getInitalPropertyValues,
  key,
  form,
  propertyInformationFormRef,
}) => {
  const formRef = useRef(null)
  // const getInitialValues = () => {
  //   let initValues = {}

  //   if (prefilledData) {
  //     initValues = prefilledData
  //   }
  //   //initValues.phone = normalizePhone(initValues.phone)
  //   return initValues
  // }

  useEffect(() => {
    if (prefilledData) {
      propertyInformationFormRef.setFieldsValue(prefilledData);
    }
  }, [prefilledData, propertyInformationFormRef]);

  return (
    <FormWrap
      id={`${key}_1`}
    >
      <Form
        id={`${key}_2`}
        name="offers_property_information_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // initialValues={getInitialValues()}
        onKeyDown={(e) => { e.stopPropagation() }}
        onValuesChange={handleOnTypeChangeForPropertyInformation}
        ref={formRef}
        disabled={disabled}
        form={propertyInformationFormRef}
      >
        {getInputFieldsBasedOnState(state).map((item, index) => {
          if (item.type === "select") {
            return (
              <SelectField
                isFormField={true}
                name={item.name}
                options={[]}
                defaultValue={prefilledData ? prefilledData.listing_type : "Select"}
                placeholder={item.placeholder || "Select"}
                text={item.text}
                required={item.required}
              />
            )
          }

          if (item.type === "googleLocation") {
            return (
              <InputLocation
                name={item.name}
                inputType="text"
                type="text"
                placeholder={item.placeholder}
                formRef={formRef}
                text={item.text}
                required={item.required}
                rules={[{ required: item.required, message: "Please enter a value" }]}
                handleInitChange={getInitalPropertyValues}
              />
            )
          }

          if (item.type === "money") {
            return (
              <InputNumber 
                inputType="number"
                type={item.formatterType}
                placeholder={item.placeholder}
                formRef={formRef}
                text={item.text}
                isFormField={true}
                required={item.required}
              />
            )

          }
          return (
            <>
              <InputField
                name={item.name}
                inputType="text"
                placeholder={item.placeholder}
                text={item.text}
                defaultValue={item.name === "state" ? state : ""}
                required={item.required}
              />
            </>
          )
        })}

        {/* <CTAWrap>
          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap> */}
      </Form>
    </FormWrap>
  )
}

export default PropertyInformationForm
