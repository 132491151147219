import React from "react"
import Modal from "@ui/Modal"
import Button from "@ui/Button"
import Box from "@ui/Box"
import colors from "@colors"
import styled from "styled-components"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import icons from "@icons"

const ConfirmationHeading = styled.h3`
  font-size: 20px;
  margin: 0;
  color: ${colors.contentText};
  ${props => props.customStyle && `
   font-family: 'DM Sans', sans-serif;
   font-size: 36px;
   font-weight: 500;
   font-style: normal;
   font-stretch: normal;
   line-height: 1.06;
   letter-spacing: normal;
   text-align: center;
   color: #354052;
   padding:30px;
  `}
`

const SubHeading = styled.p`
 ${props => props.customStyle && `
   font-family: 'DM Sans', sans-serif;
   font-size: 20px;
   font-weight: 300;
   font-style: normal;
   font-stretch: normal;
   line-height: 1.25;
   letter-spacing: normal;
   text-align: center;
   color: #354052;
  `}
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: relative;
  left: 18px;
  bottom: 269px;
  font-size: 18px;
  margin-left: -33px;
  cursor: pointer;

  i{
   width: 20px;
   height: 20px;
   color: #dadada;
  }
  ${props => props.isCancelInvite && `
     left: 26px;
     bottom: 220px;
   `}
`

const CTAWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  Button:nth-child(1){
    margin-right:5px;
  }

  ${props => props.customStyle && `
    .yesButton {
      width: 60%;
      position: relative;
      right: 18%;
    }
  `}
`

const UserConfirmationModal = ({
  userConfirm,
  question,
  hideButton,
  yesButtonText,
  noButtonText,
  subHeading,
  custom,
  customYes,
  hideYesButton,
  hideCrossButton,
  customFlag,
  children,
  isCancelInvite,
  isError,
}) => {
  const handleModalClose = () => {
    userConfirm(false)
  }
  return (
    <Modal
      show
      toClose={() => handleModalClose()}
      showCloseButton={false}
    >
      <Box>
        <ConfirmationHeading customStyle={custom}>
          { question || "Confirm ?"}
        </ConfirmationHeading>
        { subHeading && (
          <SubHeading customStyle={custom}>
            {subHeading}
          </SubHeading>
        )}
        <CTAWrapper customStyle={custom}>
          { !hideYesButton
           && (
             <Button
               width="false"
               fontSize="14px"
               className="yesButton"
               bsStyle={customYes}
               onClick={() => userConfirm(true)}
             >
               { customFlag ? <ThreeDotLoader color="#fff" /> : yesButtonText || "Yes"}
             </Button>
           )}
          { !hideButton && (
            <Button
              width="false"
              border="false"
              fontSize="14px"
              bsStyle="tertiary"
              onClick={() => userConfirm(false)}
            >
              { noButtonText || "No"}
            </Button>
          )}
          { (custom && !hideCrossButton) && (
            <CloseButton
              isCancelInvite={isCancelInvite}
              onClick={() => userConfirm(false)}
            >
              <i className={icons.arrowsClose} />
            </CloseButton>
          )}
        </CTAWrapper>
      </Box>
      {children}
    </Modal>
  )
}

export default UserConfirmationModal
