import React from "react"
import styled from "styled-components"

const SlideContainer = styled.div`
  width: 100%;
`

const Themes = {
  default: {
    thumbBackgroundImage: "#ffffff",
    sliderBackground: "linear-gradient(to right, #03affa, #79cff7)",
  },
  midnightBlue: {
    thumbBackgroundImage: "#190A36",
    border: true,
    sliderBackground: "linear-gradient(to right, #BDB2D8, #190A36)",
  },
}

const StyledRangeSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  border-radius: 45px;
  background-image: ${props => props.theme && Themes[props.theme] ? Themes[props.theme].sliderBackground : Themes.default.sliderBackground};
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  :disabled{
    background: #CCC;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    ${props => props.border && "border: 2px solid #FFFFFF"};
    background-color: ${props => props.theme && Themes[props.theme] ? Themes[props.theme].thumbBackgroundImage : Themes.default.thumbBackgroundImage};
    box-shadow: 0 1px 4px 0 rgba(141, 141, 141, 0.5);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    ${props => props.border && "border: 2px solid #FFFFFF"};
    background-color: ${props => props.theme && Themes[props.theme] ? Themes[props.theme].thumbBackgroundImage : Themes.default.thumbBackgroundImage};
    box-shadow: 0 1px 4px 0 rgba(141, 141, 141, 0.5);
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    ${props => props.border && "border: 2px solid #FFFFFF"};
    background-color: ${props => props.theme && Themes[props.theme] ? Themes[props.theme].backgroundImage : Themes.default.backgroundImage};
    box-shadow: 0 1px 4px 0 rgba(141, 141, 141, 0.5);
    cursor: pointer;
  }
`

const RangeSlider = ({
  min,
  max,
  selectedValue,
  onChange,
  height,
  theme,
  ...others
}) => (
  <SlideContainer>
    <StyledRangeSlider
      type="range"
      min={min}
      max={max}
      theme={theme}
      value={selectedValue || 0}
      onChange={onChange}
      style={{
        height,
      }}
      {...others}
    />
  </SlideContainer>
)

RangeSlider.defaultProps = {
  min: 1,
  max: 5000000,
  height: 12,
}

export default RangeSlider
