import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import { get } from "lodash"
import { delay } from "redux-saga"
import {
  isSuccess,
} from "services/Utils"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import uuid from "uuid"
import { toast as createToast } from "react-toastify"
import * as TeamAPI from "./teamApis"

import * as RealtyActions from "./actions"
import * as RoomActions from "../Rooms/actions"
import Realty from "./index"

const toastConfig = {
  position: "top-center",
  autoClose: 2500,
  hideProgressBar: true,
}

function* handleImageUploadToS3(action) {
  try {
    const {
      file,
      type,
      name,
    } = action.data
    createToast.info("Uploading", toastConfig)

    const signedRes = yield fetch(`${FILE_UPLOAD_API}filename=${`${uuid()}_${name}` || "image_file_.png"}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": "image/png" })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(RealtyActions.setImageBlocksAction.call({
        type,
        image: signedResJson.fileUrlAfterUpload,
      }))
      yield put(RealtyActions.setCurrentImageToBeCroppedAction.call({
        type: null,
        image: null,
      }))
      yield put(RealtyActions.uploadImagesToS3Action.success(signedResJson.fileUrlAfterUpload))
      createToast.info("Image Uploaded", toastConfig)
      yield put(RoomActions.setImageUrlAction.call(signedResJson.fileUrlAfterUpload))
    }
  } catch (e) {
    createToast.error("Error Uploading Image", toastConfig)
    yield put(RealtyActions.uploadImagesToS3Action.failure(e))
  }
}

function* handleTeamDetailUpload(action) {
  try {
    const {
      payload,
      profile,
      history,
    } = action.data

    const id = cookie.load("brokerageTeamId") || ""

    const res = yield call(TeamAPI.submitTeamDetails, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      createToast.info("Published!", toastConfig)
      yield put(RealtyActions.submitTeamDetailsAction.success(response))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamLogoRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamBannerRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageOneRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageTwoRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageThreeRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setCurrentImageToBeCroppedAction.call({
        type: null,
        image: null,
      }))
      yield put(RealtyActions.getTeamDetailsAction.request({
        id,
      }))
      root.location.href = `/realty/team-profile/${profile}`
    }
  } catch (e) {
    createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.submitTeamDetailsAction.failure(e))
  }
}

function* handeFetchTeamDetails(action) {
  try {
    const {
      id,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamDetailsAction.success(response))
    }
  } catch (e) {
    createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getTeamDetailsAction.failure(e))
  }
}

function* handeFetchTeamDetailsForLogo(action) {
  try {
    const {
      id,
    } = action.data

    if (!id) {
      return
    }

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(RealtyActions.getTeamDetailsForLogoAction.success(response))
    }
  } catch (e) {
    createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getTeamDetailsForLogoAction.failure(e))
  }
}

function* handleFetchOtherTeamDetails(action) {
  try {
    const {
      id,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getOtherTeamDetailsAction.success(response))
    }
  } catch (e) {
    createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getOtherTeamDetailsAction.failure(e))
  }
}

function* handleFetchTeamDetailsViaName(action) {
  try {
    const {
      name,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsViaNameAPI, name)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamDetailsViaNameAction.success(response))
    }
  } catch (e) {
    createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getTeamDetailsViaNameAction.failure(e))
  }
}

function* handleFetchTCAgentAssigned(action) {
  try {
    const { agentId } = action.data
    const res = yield call(TeamAPI.getTCAgentAssignedAPI, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTCAgentAssignedAction.success(response))
    }
  } catch (e) {
    // createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getTCAgentAssignedAction.failure(e))
  }
}

function* handleGetTeamTCs(action) {
  try {
    const { teamId } = action.data
    const res = yield call(TeamAPI.getTeamTCsAPI, teamId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamTCs.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage || "Something went wrong..", toastConfig)
    yield put(RealtyActions.getTeamTCs.failure(e))
  }
}

function* handleInviteTeamTCs(action) {
  try {
    const { payload } = action.data
    const res = yield call(TeamAPI.inviteTeamTCsAPI, payload)
    const teamDetails = yield select(state => state.teamProfileReducer.getTeamDetailsResponse?.data)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.inviteTeamTCs.success(response))
      yield put(RealtyActions.toggleCreateTCInvitation.call(false))
      yield put(RealtyActions.getTeamTCs.request({ teamId: teamDetails.id }))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage || "Something went wrong..", toastConfig)
    yield put(RealtyActions.inviteTeamTCs.failure(e))
  }
}

export default function* main() {
  yield takeEvery(RealtyActions.uploadImagesToS3Action.REQUEST, handleImageUploadToS3)
  yield takeLatest(RealtyActions.submitTeamDetailsAction.REQUEST, handleTeamDetailUpload)
  yield takeLatest(RealtyActions.getTeamDetailsAction.REQUEST, handeFetchTeamDetails)
  yield takeLatest(RealtyActions.getTeamDetailsForLogoAction.REQUEST, handeFetchTeamDetailsForLogo)
  yield takeLatest(RealtyActions.getTeamDetailsViaNameAction.REQUEST, handleFetchTeamDetailsViaName)
  yield takeLatest(RealtyActions.getOtherTeamDetailsAction.REQUEST, handleFetchOtherTeamDetails)
  yield takeLatest(RealtyActions.getTCAgentAssignedAction.REQUEST, handleFetchTCAgentAssigned)
  yield takeLatest(RealtyActions.getTeamTCs.REQUEST, handleGetTeamTCs)
  yield takeLatest(RealtyActions.inviteTeamTCs.REQUEST, handleInviteTeamTCs)
}
