import { get } from "lodash"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import {
  SUBMIT_REFERRAL_NEXT,
  SELECT_REFERRAL_SERVICE,
  saveBasicInfoAction,
  saveBuyerNeedsAction,
  saveReferralBackgroundAction,
  saveSellerRequirementAction,
  submitReferralAction,
  saveCommissionAction,
  profileSubmitReferralAction,
  isPartnerFlowAction,
  handleLeadReferralErrorAction,
  clearFormAction,
  clearErrorAction,
  saveSubmitReferralPayloadAction,
  getClientsSearchAction,
  setSubmitReferralTypeAction,
  setMarketplaceInitValuesAction,
  toggleReferralSuccessModalAction,
  setBasicInfoErrorAction
} from "./actions"

const initState = {
  isSavingReferral: false,
  receivingAgent: null,
  commission: {},
  sellerRequirement: {},
  referralBackground: {},
  buyerNeeds: {},
  basicInfo: {},
  submitReferralFlowType: "marketplace",
  ...createDeltaReducer("basicFormValidationResponse"),
}

export default function submitReferralReducer(state = initState, action) {
  switch (action.type) {
    case SUBMIT_REFERRAL_NEXT: {
      return {
        ...state,
      }
    }
    case setBasicInfoErrorAction.REQUEST:
    case setBasicInfoErrorAction.FAILURE:
    case setBasicInfoErrorAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, setBasicInfoErrorAction, "basicFormValidationResponse"),
      }
    }
    case SELECT_REFERRAL_SERVICE: {
      return {
        ...state,
        selectedReferralService: action.data,
      }
    }
    case saveBasicInfoAction.type: {
      return {
        ...state,
        basicInfo: action.data,
      }
    }
    case saveBuyerNeedsAction.type: {
      return {
        ...state,
        buyerNeeds: action.data,
      }
    }
    case saveReferralBackgroundAction.type: {
      return {
        ...state,
        referralBackground: action.data,
      }
    }
    case saveSellerRequirementAction.type: {
      return {
        ...state,
        sellerRequirement: action.data,
      }
    }
    case submitReferralAction.type: {
      return {
        ...state,
        isSavingReferral: true,
        errorCode: null,
        errorMessage: null,
        isCreatingReferralError: false,
      }
    }
    case profileSubmitReferralAction.type: {
      const { agent } = action.data
      return {
        ...state,
        receivingAgent: agent,
      }
    }
    case saveCommissionAction.type: {
      return {
        ...state,
        commission: action.data,
      }
    }
    case isPartnerFlowAction.type: {
      return {
        ...state,
        isPartnerFlow: true,
      }
    }
    case handleLeadReferralErrorAction.type: {
      const errorMessage = get(action.data, "error.message")
      const errorCode = get(action.data, "error.code")
      return {
        ...state,
        isCreatingReferralError: true,
        errorMessage,
        errorCode,
      }
    }
    case clearFormAction.type: {
      return {
        ...state,
        ...initState,
      }
    }
    case clearErrorAction.type: {
      return {
        ...state,
        isCreatingReferralError: false,
      }
    }
    case saveSubmitReferralPayloadAction.type: {
      return {
        ...state,
        ...action.data,
      }
    }

    case getClientsSearchAction.REQUEST: {
      return {
        ...state,
        isFetchingClient: true,
      }
    }
    case getClientsSearchAction.FAILURE:
    case getClientsSearchAction.SUCCESS: {
      return {
        ...state,
        isFetchingClient: false,
        CRMClients: action.data,
      }
    }
    case setSubmitReferralTypeAction.type: {
      const { redirect, type } = action.data
      return {
        ...state,
        submitReferralFlowType: type,
        // Reson for commenting
        // https://trello.com/c/41fS5E7G/32-land-on-the-outbound-referrals-tab-once-the-lead-is-submitted
        //redirectPath: redirect,
      }
    }

    case setBasicInfoErrorAction.req: {
      return {
        ...state,
        basicInfoError: action.data,
      }
    }

    case setMarketplaceInitValuesAction.type: {
      const { payload } = action.data
      const basicFormInitValues = {
        name: payload.client_name,
        emails: payload.client_email,
        type: { label: payload.client_type, value: payload.client_type },
      }
      if (payload.client_phone) {
        basicFormInitValues.phones = payload.client_phone
      }
      return {
        ...state,
        basicFormInitValues,
      }
    }
    case toggleReferralSuccessModalAction.type: {
      return {
        ...state,
        isShowSuccessModal: action.data,
      }
    }
    default: {
      return state
    }
  }
}
