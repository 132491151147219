import { call, put } from "redux-saga/effects"
import { get } from "lodash"
import { isSuccess } from "services/Utils"
import { reset } from "redux-form"
import createToast from "@ui/Toast"
import {
  fetchSubscriptionInviteAction,
  sendNewSubscriptionPromoInviteAction,
  resendNewSubscriptionPromoInviteAction,
} from "./actions"
import {
  inviteAgentPromoAPI,
  reInviteAgentPromoAPI,
  getInvitedAgentPromoAPI,
} from "./api"

export function* inviteAgentPromo(action) {
  try {
    const { payload } = action.data
    const res = yield call(inviteAgentPromoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(sendNewSubscriptionPromoInviteAction.success(response))
      yield put(reset("PROMO_INVITE_FORM"))
      createToast("Invite sent successfully", "success")
      yield put(fetchSubscriptionInviteAction.request())
    }
  } catch (error) {
    const message = get(error, "data.error.message")
    createToast(message, "error")
    yield put(sendNewSubscriptionPromoInviteAction.failure(error))
  }
}

export function* fetchSubscriptionInvite() {
  try {
    const res = yield call(getInvitedAgentPromoAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(fetchSubscriptionInviteAction.success(response))
    }
  } catch (error) {
    yield put(fetchSubscriptionInviteAction.failure(error))
  }
}

export function* reInviteAgentPromo(action) {
  try {
    const { payload } = action.data
    const res = yield call(reInviteAgentPromoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(resendNewSubscriptionPromoInviteAction.success(response))
    }
  } catch (error) {
    yield put(resendNewSubscriptionPromoInviteAction.failure(error))
  }
}
