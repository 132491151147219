import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import InviteContainer from "container/App/inviteFeatureContainer"
import { authCheck } from "services/auth"
import { checkForUserObject } from "services/Utils"

class Intercom extends React.PureComponent {
  componentDidMount() {
    const {
      user: userObj,
      isBroker,
    } = this.props

    // if (isBroker) {
    //   this.init()
    // }
    this.init()
  }

  init = () => {
    (function(){var w=root;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/iv9ssqkn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }

  componentDidUpdate() {
    if (root && root.Intercom && !root.Intercom.boot) {
      root.Intercom("boot", {
        ...root.intercomSettings,
      })
    } else if (root && root.Intercom && root.Intercom.boot) {
      root.Intercom("update", {
        ...root.intercomSettings,
      })
    }
  }

  componentWillUnmount() {
    //this.toggleBanner(false)
  }

  render() {
    const {
      show,
    } = this.state || {}

    if (!show) {
      return null
    }

    return (
      <></>
    )
  }
}

export default Intercom
