import React, { useState, useRef } from "react"
import styled from "styled-components"
import SidePanel from "dumbComponents/common/SidePanel"
import RightArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/RightArrow.svg"
import LeftArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/LeftArrow.svg"
import RightArrowPurple from "dumbComponents/VideoRooms/components/LandingPage/Components/images/RightArrowBlue.svg"
import RoomsContainer from "container/Rooms"
import Button from "@ui/Button"
import colors from "@colors"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import { ShimmerBox } from "shared/styles/animation"
import ScheduledRoomCarouselDetails from "./ScheduleRoomCarouselDetails"

const Wrap = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 15px;
  align-items: baseline;

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`

const MainWrap = styled.div`
  margin-bottom: 60px;
  padding-top: 100px;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Header = styled.h1`
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: ${colors.black};
  margin: 0;
  margin-bottom: 34px;

  @media(max-width: 1099px) {
    font-size: 24px;
  }

  @media(max-width: 767px) {
    font-family: Butler;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-start;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 30px;
`

const ViewFullCalendar = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  h1 {
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 18px;
    color: #849DF8;
  }

  @media(max-width: 767px) {
    display: none;
  }
`

const CalendarButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 8px 10px;
`

const Iframe = styled.iframe`
  width: 100%;
  height: 60vh;
  background: ${colors.fungiWhite};
`

const ScheduledRoomCarousel = ({
  getScheduleRoomsResponse,
  isLogged,
}) => {
  const [isLoadingIframe, setIsLoad] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { data: scheduledRoomsData, isFetching } = getScheduleRoomsResponse || {}
  const scrollRef = useRef(null)

  const scroll = (forwards) => {
    const numberOfItems = scheduledRoomsData && scheduledRoomsData.length
    let scrollRight = Math.ceil(scrollRef.current.offsetWidth/numberOfItems) + scrollRef.current.scrollLeft
    let scrollLeft = Math.ceil(scrollRef.current.offsetWidth/numberOfItems) - scrollRef.current.scrollLeft
    scrollRef.current.scrollTo({
      top: 0,
      left: forwards ? scrollRight + 75 : scrollLeft + 75,
      behavior: "smooth"
    })
  }
  return (
    <MainWrap className="container" id="scheduled-rooms">
      <HeaderWrap>
        <HeaderContent>
          <Header>
            Upcoming Rooms
          </Header>
          {isLogged && (
            <ViewFullCalendar
              onClick={() => {
                setIsOpen(true)
                setIsLoad(true)
              }}
            >
              <h1>
                View Full Calendar
              </h1>
              <img src={RightArrowPurple} alt="RIGHT ARROW PURPLE" />
            </ViewFullCalendar>
          )}
        </HeaderContent>
        <ButtonWrap>
          <button
            type="button"
            style={{ background: "none", border: "none" }}
            onClick={() => scroll(false)}
          >
            <img src={LeftArrow} alt="LEFT ARROW" />
          </button>
          <button
            type="button"
            style={{ background: "none", border: "none" }}
            onClick={() => scroll(true)}
          >
            <img src={RightArrow} alt="RIGHT ARROW" />
          </button>
        </ButtonWrap>
      </HeaderWrap>
      <Wrap
        innerRef={scrollRef}
      >
        {isFetching ? (
          <>
            <ShimmerBox w="410px" h="230px" />
            <ShimmerBox w="410px" h="230px" />
            <ShimmerBox w="410px" h="230px" />
            <ShimmerBox w="410px" h="230px" />
          </>
        ) : (
          scheduledRoomsData && scheduledRoomsData.map((scheduledRooms, index) => {
            return (
              <ScheduledRoomCarouselDetails data={scheduledRooms} index={index}/>
            )
          })
        )}
      </Wrap>
      <SidePanel
        toClose={() => {
          setIsOpen(false)
        }}
        isOpen={isOpen}
        title="Event Calendar"
      >
        {isOpen && (
          <Iframe
            onLoad={() => { setIsLoad(false) }}
            src={ROOMS_CALENDAR_IFRAME}
            frameborder="0"
          >
          </Iframe>
        )}
        <br />
        <CalendarButtonWrap>
          <Button
            onClick={() => { window.open(ROOOS_CALENDAR_SUBSCRIPTION) }}
            bsStyle="marineBLueFill"
            borderRadius="0px"
            style={{ margin: "0px auto" }}
          >
            Add to my Calendar
          </Button>
        </CalendarButtonWrap>
      </SidePanel>
    </MainWrap>
  )
}

export default RoomsContainer(ScheduledRoomCarousel)
