import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getIsUserABroker } from "container/App/selector"
import { RARTrackerAction } from "container/Realty/actions"
import {
  toggleInvitePopUpAction,
  getCurrentReferralInviteStatusAction,
  postEmailInvitesAction,
  setCurrentInviteType,
  toggleSuccessInviteSubmitFromModalAction,
  toggleStateAnnouncementAction,
  toggleApplyModalAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { inviteFeatureReducer, app } = state
  const isBroker = getIsUserABroker(state)
  const {
    postEmailInvitesResponse,
    currentInviteType,
    isInviteSubmittedSuccessfully,
    isInviteSubmittedSuccessfullyFromModal,
  } = app
  const {
    profile_name,
    id,
    firstTimeReferralPopup,
    isSubscribedToBrokerPlan,
    citiesServed,
  } = app.user

  const {
    isInvitePopUpOpen,
    getCurrentReferralInviteStatusResponse,
    isInviteFeedPostVisible,
    isAnnouncementGlobalModalOpen,
    getStateAnnouncementResponse,
  } = inviteFeatureReducer
  return {
    citiesServed,
    isInvitePopUpOpen,
    userName: profile_name || id,
    userId: id,
    firstTimeReferralPopup,
    getCurrentReferralInviteStatusResponse,
    isSubscribedToBrokerPlan,
    currentInviteType,
    postEmailInvitesResponse,
    isInviteFeedPostVisible,
    isInviteSubmittedSuccessfully,
    isInviteSubmittedSuccessfullyFromModal,
    isBroker,
    isAnnouncementGlobalModalOpen,
    getStateAnnouncementResponse,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleInvitePopUp: toggleInvitePopUpAction.call,
      fetchInviteStatus: getCurrentReferralInviteStatusAction.request,
      postEmailInvites: postEmailInvitesAction.request,
      setCurrentInviteType: setCurrentInviteType.call,
      toggleSuccessModalUI: toggleSuccessInviteSubmitFromModalAction.call,
      toggleStateAnnouncement: toggleStateAnnouncementAction.call,
      RARTracker: RARTrackerAction.request,
      toggleApplyForm: toggleApplyModalAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
