import React from "react"
import styled from "styled-components"
import colors from "@colors"
import CheckDoneIcon from "images/icons/check-done-icon.svg"

const Wrap = styled.div`
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  text-align: center;
`
const SectionHeader = styled.h1`
  margin: 32px 0 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;

  color: ${colors.black};
`

const SectionPara = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 360px;
  margin: 16px auto 24px;
  text-align: center;
  ${colors.black}
`

const CheckImageWrap = styled.img`
  width: 84px;
  height: 107px;
  border-radius: 16px;
  margin: auto;
  margin-top: 25px;
`

const RequestSubmitted = () => (
  <Wrap>
    <CheckImageWrap src={CheckDoneIcon} alt="icon.svg" />
    <SectionHeader>
      Thank you for your request.
    </SectionHeader>

    <SectionPara>
      Your request has been submitted. We’ll be in touch shortly!
    </SectionPara>
  </Wrap>
)

export default RequestSubmitted
