import * as React from "react";
const ShareIcon = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16634 8.33325H5.49967C4.57884 8.33325 3.83301 9.07909 3.83301 9.99992V15.8333C3.83301 16.7541 4.57884 17.4999 5.49967 17.4999H15.4997C16.4205 17.4999 17.1663 16.7541 17.1663 15.8333V9.99992C17.1663 9.07909 16.4205 8.33325 15.4997 8.33325H13.833"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4997 2.5V11.6667"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00098 5L10.5001 2.5L13.0001 5"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ShareIcon;
