import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import { delay } from "redux-saga"
// import { get } from "lodash"
import CookiesStorage from "services/CookieStorage"
import { destroy as reset } from "redux-form"
import uuid from "uuid"
import {
  isSuccess,
  checkForUserObject,
} from "services/Utils"
import { get, isNaN, uniqBy } from "lodash"
import { toast as createToast } from "react-toastify"
import * as AppActions from "container/App/actions"
import { showPopupAction, getPopupDetailsAction } from "container/TechAssets/actions"
import { getPopupDetailsApi } from "container/TechAssets/api"
import * as TCApis from "./api"
import * as RealtyActions from "./actions"
const toastConfig = {
  position: "top-center",
  autoClose: 2500,
  hideProgressBar: true,
}

const user = checkForUserObject("user")

const getLabel = value => value && value.replace(value[0], value[0].toUpperCase())

const setCorrectKeyValue = (key, value, id, thirdStageCompleted, fourthStageCompleted) => {
  switch (key) {
    case "is_radius_tc":
      if (thirdStageCompleted) return value ? "is_radius_tc_yes" : "is_radius_tc_no"
      return null
    case "is_using_deal_tc":
      if (thirdStageCompleted) return value ? "is_using_deal_tc_yes" : "is_using_deal_tc_no"
      return null
    case "is_all_cash_offer":
      if (thirdStageCompleted) return value ? "is_all_cash_offer_yes" : "is_all_cash_offer_no"
      return null
    case "is_radius_tc_to_open_escrow":
      if (fourthStageCompleted) return value ? "radius_tc_open_escrow_yes" : "radius_tc_open_escrow_no"
      return null
    case "schedule_sign_post_installation":
      if (fourthStageCompleted) {
        return value
          ? "sign_post_installation_request_yes"
          : "sign_post_installation_request_no"
      }
      return null
    default:
      return null
  }
}

const referenceMap = {
  listing_address: "string",
  city: "string",
  price: "string",
  commision_percentage: "string_percentage",
  source: "checkbox",
  created_at: "date",
  gross_commission: "string",
  radius_transaction_fee: "string",
  transaction_type: "checkbox",
  client_type: "select",
  is_radius_lead: "string",
  zipcode: "string",
  commission_type: "checkbox",
  fixed_fee: "string",
  file_type: "checkbox",
  closed_on: "date",
  lease_commencement_on: "date",
  team_split_percentage: "string_percentage",
  is_radius_tc: "checkbox",
  is_using_deal_tc: "checkbox",
  is_all_cash_offer: "checkbox",
  lender_email_address: "string",
  is_radius_tc_to_open_escrow: "checkbox",
  escrow_officer_email: "string",
  escrow_officer_phone: "string",
  schedule_sign_post_installation: "checkbox",
  sign_post_installation_on: "date",
  sign_post_installation_location: "string",
  hoa_guidelines: "string",
  sign_post_installation_riders: "string",
  initial_name: "array",
  clientDetailsArray: "array",
  list_price: "string",
  contract_on: "date",
  other_source: "string",
  other_transaction_type: "string",
  other_rent_amount_term: "string",
  rent_amount: "string",
  rent_amount_term: "checkbox",
  closing_agent_commision_percentage: "string_percentage",
}

function* handleFetchTCFormValues(action) {
  try {
    //
    const {
      id,
      init,
      fields,
      currentDeal,
    } = action.data

    if (currentDeal && currentDeal !== undefined && currentDeal !== null) {
      Object.keys(fields).forEach((item) => {
        if (fields[item] === "string" || referenceMap[item] === "string") {
          fields[item] = currentDeal[item]
          && currentDeal[item] !== undefined
          && currentDeal[item] !== null
          && (currentDeal[item]).toString()
        }

        if (fields[item] === "string_percentage" || referenceMap[item] === "string_percentage") {
          fields[item] = currentDeal[item] !== undefined
          && currentDeal[item] !== null
          && `${currentDeal[item]}` || ""
        }

        if (fields[item] === "checkbox" || referenceMap[item] === "checkbox") {
          const stageLength = currentDeal.completed_steps && currentDeal.completed_steps.length

          let isTransactionCoordStageCompleted = false

          const isEscrowStageCompleted = (stageLength >= 4)
          && (currentDeal.completed_steps.includes("escrow_pre_escrow")
          || currentDeal.completed_steps.includes("upload_files")
          || currentDeal.completed_steps.includes("completed"))

          const isPostSignageStageCompleted = (stageLength >= 4)
          && (currentDeal.completed_steps.includes("sign_post_installation")
          || currentDeal.completed_steps.includes("upload_files")
          || currentDeal.completed_steps.includes("completed"))

          const isFourthStageCompleted = isEscrowStageCompleted || isPostSignageStageCompleted

          if (isFourthStageCompleted) {
            isTransactionCoordStageCompleted = true
          } else {
            isTransactionCoordStageCompleted = currentDeal
            && currentDeal.completed_steps.includes("transaction_coordination")
          }

          if (currentDeal[item] !== undefined && typeof currentDeal[item] === "number") {
            fields[item] = setCorrectKeyValue(item,
              Boolean(currentDeal[item]),
              id, isTransactionCoordStageCompleted, isFourthStageCompleted)
          } else if (currentDeal && typeof currentDeal[item] !== "undefined" && currentDeal[item] !== undefined) {
            fields[item] = currentDeal[item]
          }
        }

        if (fields[item] === "select" || referenceMap[item] === "select") {
          fields[item] = currentDeal[item] !== undefined
          && { value: currentDeal[item], label: getLabel(currentDeal[item]) } || { value: "n/a", label: "N/A" }
        }

        if (fields[item] === "date" || referenceMap[item] === "date") {
          fields[item] = currentDeal[item] !== undefined
          && currentDeal[item] && new Date(currentDeal[item] * 1000) || ""
        }

        if ((fields[item] === "array" || referenceMap[item] === "array") || typeof fields[item] === "object") {
          const legacyClient = []

          if ((currentDeal.clients === null || currentDeal.clients === undefined) && currentDeal.client_name) {
            legacyClient.push({
              name: currentDeal.client_name,
              email: currentDeal.client_email,
              phone: currentDeal.client_phone,
              client_id: null,
            })
          }

          fields.initial_name = get(currentDeal, "clients[0].name", "client name")
          // fields.initial_name = {
          //   value: get(currentDeal, "clients[0].name", "client name"),
          //   label: get(currentDeal, "clients[0].name", "client name"),
          //   data: [{
          //     name: get(currentDeal, "clients[0].name", "client name"),
          //     email: get(currentDeal, "clients[0].email", "client email"),
          //     phone: get(currentDeal, "clients[0].phone", "client phone"),
          //     client_id: get(currentDeal, "clients[0].client_id"),
          //   }],
          // }
          fields.initial_email = get(currentDeal, "clients[0].email", "")
          fields.initial_phone = get(currentDeal, "clients[0].phone", "")

          if (item === "clientDetailsArray"
          && currentDeal.clients
          && currentDeal.clients.length > 0) {
            const restClients = currentDeal.clients && currentDeal.clients
              .filter((client, index) => index > 0 && client)
            fields.clientDetailsArray = restClients && restClients.length > 0 ? uniqBy([...restClients], "email") : []
          } else if (item === "clientDetailsArray" && legacyClient) {
            fields.clientDetailsArray = [...legacyClient]
          } else if (fields.initial_name === "array") {
            fields.initial_name = ""
            fields.initial_phone = ""
            fields.initial_email = ""
            fields.clientDetailsArray = []
          }
        }
      })
      init({
        ...fields,
      })
    }
    yield put(RealtyActions.transactionFormFetchValuesAction.success({
      combinedTCValues: { ...fields },
    }))
  } catch (e) {
    console.error(e)
    yield put(RealtyActions.transactionFormFetchValuesAction.failure(e))
  }
}

function* handleDealCreation(action) {
  try {
    const {
      payload,
      currentStage,
      nextForm,
      history,
    } = action.data

    Object.keys(payload).forEach((item) => {
      if (payload[item] === undefined) {
        payload[item] = ""
      }
    })
    const res = yield call(TCApis.createTCFormDealAPI, payload)

    if (isSuccess(res)) {
      const { response } = res.data
      const {
        deal_id,
      } = response || {}
      yield put(RealtyActions.transactionFormCreateDealViaFormAction.success(response))
      yield put(RealtyActions.transactionFormUpdateStageAction.request({
        currentStage,
        deal_id,
        history,
        nextForm,
      }))
    }
  } catch (e) {
    console.log(e)
    yield put(AppActions.toggleErrorModalAction.call(true))
    yield put(RealtyActions.transactionFormCreateDealViaFormAction.failure(e))
  }
}

function* handleUpdateTCFormStage(action) {
  try {
    //
    const {
      currentStage,
      history,
      deal_id,
      nextForm,
      isFinal,
      doNotShowSuccessPage,
      triggerSlackNotification,
      noReRoute,
      isNewListing,
    } = action.data

    const payload = {
      deal_id,
      step: currentStage,
    }

    const isNewListingFlow = isFinal && isNewListing
    const agentId = get(user, "agent_id") || get(user, "id")
    let res
    // only for new Listing
    if (isNewListingFlow) {
      if (currentStage === "upload_files") {
        res = yield call(TCApis.updateCreateDealFormStageAPI, payload)
      }
      yield delay(1000)
      yield call(TCApis.updateCreateDealFormStageAPI, {
        deal_id,
        step: "active_listing",
      })
    } else {
      console.log("2222")
      res = yield call(TCApis.updateCreateDealFormStageAPI, payload)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.transactionFormUpdateStageAction.success(response))
      yield put(RealtyActions.fetchTCFilesAction.request({
        skip: 0,
        limit: 100,
        agentId,
        ignoreReset: true,
      }))
      if (triggerSlackNotification && deal_id && agentId) {
        yield put(RealtyActions.triggerSlackNotificationAction.request({
          dealId: deal_id,
          agentId,
        }))
      }
      if (!isFinal && !noReRoute) {
        history.push(deal_id ? `${nextForm}?id=${deal_id}` : nextForm)
      } else if (doNotShowSuccessPage && !noReRoute) {
        history.push("/realty/transaction-coordination?filter=active")
      } else if (!noReRoute) {
        try {
          // to check if mortgage popup needs to be shown
          const mortgageRes = yield call(getPopupDetailsApi, `?eventType=deal_submission&popupType=mortgage&entityId=${deal_id}`)
          if (isSuccess(mortgageRes)) {
            yield put(getPopupDetailsAction.success(mortgageRes.data && mortgageRes.data.response))
            yield put(showPopupAction.call({ showMortgagePopup: true }))
          }
        } catch (err) {
          console.log(err)
        }
        history.push(nextForm)
      }
    }
  } catch (e) {
    yield put(RealtyActions.transactionFormUpdateStageAction.failure(e))
  }
}

function* handleTCDocumentsFetch(action) {
  try {
    // const {
    //   skip,
    //   limit,
    //   agentId,
    //   ignoreReset,
    //   contractOnly,
    // } = action.data
    const tcInformation = CookiesStorage.load("tcInformation")
    const teamDetails = yield select(state => state.teamProfileReducer.getTeamDetailsResponse?.data)
    let query = ""
    if (action.data?.agentId && tcInformation?.is_tc) {
      const teamLeadId = teamDetails?.team_members?.find(tm => tm.member_type === "Team Lead")?.agent_id
      action.data.agentId = teamLeadId
    }
    // let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    query += "contractOnly=1"
    let res = {}
    if (action.data?.apiMethod === "post") {
      res = yield call(TCApis.fetchTCDocumentsPostAPI, action.data)
    } else {
      res = yield call(TCApis.fetchTCDocumentsAPI, query)
    }
    // const res = yield call(TCApis.fetchTCDocumentsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.fetchTCFilesAction.success(response))
      if (!ignoreReset) yield put(RealtyActions.resetAllTCFromAction.request())
    }
  } catch (e) {
    yield put(RealtyActions.fetchTCFilesAction.failure(e))
  }
}

function* handleTCDealUpdate(action) {
  try {
    const {
      payload,
      currentStage,
      nextForm,
      history,
      formID,
    } = action.data

    Object.keys(payload).forEach((item) => {
      if (payload[item] === undefined) {
        payload[item] = ""
      }

      if (isNaN(payload[item])) {
        payload[item] = 0
      }
    })

    const res = yield call(TCApis.updateTCFormDealAPI, payload, formID)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.transactionFormUpdateAction.success(response))
      yield put(RealtyActions.transactionFormUpdateStageAction.request({
        currentStage,
        deal_id: formID,
        history,
        nextForm,
        noReRoute: false,
        doNotShowSuccessPage: true,
        triggerSlackNotification: false,
        isFinal: false,
        formValues: payload,
      }))
    }
  } catch (e) {
    yield put(AppActions.toggleErrorModalAction.call(true))
    yield put(RealtyActions.transactionFormUpdateAction.failure(e))
  }
}

function* handleFileUploadToDB(action) {
  try {
    const {
      name: title,
      url,
      id,
      currentContractStep,
    } = action.data

    const payload = {
      deal_id: id,
      current_contract_step: currentContractStep,
      documents: [
        {
          title,
          url,
        },
      ],
    }

    const res = yield call(TCApis.filesUploadToDBAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.uploadTCFilesToDbAction.success(response))
      yield put(RealtyActions.fetchTCDocumentsFromS3Action.request({
        id,
      }))
    }
  } catch (e) {
    createToast.error("Error Uploading file", toastConfig)
    yield put(RealtyActions.uploadTCFilesToDbAction.failure(e))
  }
}

function* handleFileUploadingTos3(action) {
  try {
    const {
      file,
      id,
      callback,
    } = action.data

    const signedRes = yield fetch(`${FILE_UPLOAD_API}filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const existingDocs = yield select(state => state.transactionReducer.documents)
      yield put(RealtyActions.uploadTCFilesAction.success(signedResJson.fileUrlAfterUpload))
      let fileName = ""
      const fileSplit = signedResJson.fileUrlAfterUpload.split("/")
      fileName = fileSplit && fileSplit[fileSplit.length - 1]
      const newDoc = {
        id: uuid.v4(),
        title: fileName,
        url: signedResJson.fileUrlAfterUpload,
      }
      yield put(RealtyActions.saveDocumentsForUploadToContractsAction.call([
        ...existingDocs,
        newDoc,
      ]))
      createToast.info(`Uploaded ${file.name}`, toastConfig)
      if (id && id !== null && id !== undefined) {
        yield put(RealtyActions.uploadTCFilesToDbAction.request({
          name: file.name,
          url: signedResJson.fileUrlAfterUpload,
          id,
          currentContractStep: "upload_files",
        }))
      }
    }
    if (callback) {
      callback()
    }
  } catch (err) {
    console.log("ERROR---", err)
    createToast.error("Error Uploading file", toastConfig)
    yield put(RealtyActions.uploadTCFilesAction.failure(e))
  }
}

function* handleTCFormReset() {
  try {
    let success = false
    yield put(reset("TC_BASIC_FORM"))
    yield put(reset("TC_TRANSACTION_DETAILS_FORM"))
    yield put(reset("TC_COORDINATION_DETAILS_FORM"))
    yield put(reset("TC_ESCROW_DETAILS_FORM"))
    yield put(reset("TC_SIGN_POST_DETAILS_FORM"))
    success = true

    if (success) {
      yield put(RealtyActions.resetAllTCFromAction.success({
        success: true,
      }))
    }
  } catch (e) {
    yield put(RealtyActions.resetAllTCFromAction.failure(e))
  }
}

function* handleFetchClientSearch(action) {
  try {
    const {
      text,
      sortBy,
      start,
      end,
      aggregationNotRequired,
      ascending,
    } = action.data

    const payload = {
      text,
      sortBy,
      start,
      end,
      aggregationNotRequired,
      ascending,
    }

    const res = yield call(TCApis.fetchClientDetailsAPI, payload)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getClientSearchAction.success(response))
    }
  } catch (e) {
    yield put(RealtyActions.getClientSearchAction.failure(e))
  }
}

function* handleFetchS3Documents(action) {
  try {
    const {
      id,
    } = action.data

    const res = yield call(TCApis.fetchTCDocumentsFromS3API, id)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.fetchTCDocumentsFromS3Action.success(response))
    }
  } catch (e) {
    yield put(RealtyActions.fetchTCDocumentsFromS3Action.failure(e))
  }
}

function* handleFetchTCBanner(action) {
  try {
    const {
      agentId,
    } = action.data

    const res = yield call(TCApis.getTCBannerAPI, agentId)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTCBannerAction.success(response))
    }
  } catch (e) {
    yield put(RealtyActions.getTCBannerAction.failure(e))
  }
}

function* handlePostTCBanner(action) {
  try {
    const {
      agentId,
    } = action.data
    const res = yield call(TCApis.postTCBannerAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.postTCBannerAction.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(RealtyActions.postTCBannerAction.failure(e))
  }
}

function* handleDeleteFileFromS3(action) {
  try {
    const {
      fileToBeDeletedID,
      id,
    } = action.data

    const res = yield call(TCApis.deleteTCDocumentsFromS3API, fileToBeDeletedID)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.deleteTCDocumentsFromS3Action.success(response))
      createToast.info("File Deleted", toastConfig)
      yield put(RealtyActions.fetchTCDocumentsFromS3Action.request({
        id,
      }))
    }
  } catch (e) {
    yield put(RealtyActions.deleteTCDocumentsFromS3Action.failure(e))
  }
}

function* handleTriggerSlackNotification(action) {
  try {
    const {
      dealId,
      agentId,
    } = action.data

    const res = yield call(TCApis.triggerSlackNotificationAPI, dealId, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.triggerSlackNotificationAction.success(response))
    }
  } catch (e) {
    yield put(RealtyActions.triggerSlackNotificationAction.failure(e))
  }
}

function* handleGetOnboardingCommission(action) {
  try {
    const res = yield call(TCApis.getOnboardingCommissionAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      const { response: responseData, isShow } = response
      if (!isShow) {
        return
      }
      yield put(RealtyActions.getOnboardingCommission.success(response))
      if (action?.data?.isDraft) {
        yield put(RealtyActions.toggleCommissionsPopup.call(0))
        return
      }
      if (action?.data?.isContinue) {
        yield put(RealtyActions.toggleCommissionsPopup.call(action.data.isContinue))
        return
      }
      if (!responseData) {
        yield put(RealtyActions.toggleCommissionsPopup.call(1))
      }
      if (responseData && responseData.completed_steps && responseData.completed_steps.length) {
        if (responseData.completed_steps.includes("personal_bank_info")) {
          yield put(RealtyActions.toggleCommissionsPopup.call(2))
        }
        if (responseData.completed_steps.includes("s_corp_bank_info")) {
          yield put(RealtyActions.toggleCommissionsPopup.call(5))
        }
        if (responseData.completed_steps.includes("tc_info")) {
          yield put(RealtyActions.toggleCommissionsPopup.call(5))
        }
      }
    }
  } catch (e) {
    yield put(RealtyActions.getOnboardingCommission.failure(e))
  }
}

function* handleCreateOnboardingCommission(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data

    const res = yield call(TCApis.createOnboardingCommissionAPI, payload)
    yield put(RealtyActions.getOnboardingCommission.request({ isDraft, isContinue }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.createOnboardingCommission.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(RealtyActions.createOnboardingCommission.failure(e))
  }
}

function* handkleUpdateOnboardingCommission(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data

    const res = yield call(TCApis.updateOnboardingCommissionAPI, payload)
    yield put(RealtyActions.getOnboardingCommission.request({ isDraft, isContinue }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(RealtyActions.updateOnboardingCommission.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(RealtyActions.updateOnboardingCommission.failure(e))
  }
}

export default function* main() {
  yield takeLatest(RealtyActions.transactionFormFetchValuesAction.REQUEST, handleFetchTCFormValues)
  yield takeLatest(RealtyActions.transactionFormCreateDealViaFormAction.REQUEST, handleDealCreation)
  yield takeEvery(RealtyActions.transactionFormUpdateStageAction.REQUEST, handleUpdateTCFormStage)
  yield takeLatest(RealtyActions.fetchTCFilesAction.REQUEST, handleTCDocumentsFetch)
  yield takeLatest(RealtyActions.transactionFormUpdateAction.REQUEST, handleTCDealUpdate)
  yield takeEvery(RealtyActions.uploadTCFilesAction.REQUEST, handleFileUploadingTos3)
  yield takeEvery(RealtyActions.uploadTCFilesToDbAction.REQUEST, handleFileUploadToDB)
  yield takeLatest(RealtyActions.resetAllTCFromAction.REQUEST, handleTCFormReset)
  yield takeLatest(RealtyActions.getClientSearchAction.REQUEST, handleFetchClientSearch)
  yield takeLatest(RealtyActions.fetchTCDocumentsFromS3Action.REQUEST, handleFetchS3Documents)
  yield takeLatest(RealtyActions.deleteTCDocumentsFromS3Action.REQUEST, handleDeleteFileFromS3)
  yield takeLatest(RealtyActions.triggerSlackNotificationAction.REQUEST, handleTriggerSlackNotification)

  yield takeLatest(RealtyActions.getOnboardingCommission.REQUEST, handleGetOnboardingCommission)
  yield takeLatest(RealtyActions.createOnboardingCommission.REQUEST, handleCreateOnboardingCommission)
  yield takeLatest(RealtyActions.updateOnboardingCommission.REQUEST, handkleUpdateOnboardingCommission)
  yield takeLatest(RealtyActions.getTCBannerAction.REQUEST, handleFetchTCBanner)
  yield takeLatest(RealtyActions.postTCBannerAction.REQUEST, handlePostTCBanner)
}
