import React from "react"
import styled from "styled-components"
import ModalCloseBtnV2 from "images/realty-v2/CloseIcon.png"


const Header = styled.div`
  margin-bottom: 51px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const GoBackButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;

  img {
   filter: brightness(0.5);
  }
`

const HeaderCommonComponent = ({
  text,
  history,
}) => (
  <Header>
    <h1>{text}</h1>
    <GoBackButton type="button" onClick={() => { history.push("/realty/transaction-coordination") }}>
      <img src={ModalCloseBtnV2} alt="close.png" />
    </GoBackButton>
  </Header>
)

export default HeaderCommonComponent
