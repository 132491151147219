import React from "react"
import {
  Wrap,
  SectionWrap,
  ListRowWrap,
} from "./styles"
import {
  PropertyInformationForm,
} from "./Forms"

const PropertyInformation = () => (
  <Wrap>
    <SectionWrap>
      <ListRowWrap>
        <PropertyInformationForm
          // prefilledData={form[stateForOffer]}
          state
          // //handleOnTypeChangeForPropertyInformation={handleOnTypeChangeForPropertyInformation}
        />
      </ListRowWrap>
    </SectionWrap>
  </Wrap>
)

export default PropertyInformation
