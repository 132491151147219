import React, { PureComponent } from "react"
import styled from "styled-components"
import { Field, reduxForm } from "redux-form"
import { supressPopups } from "shared/utils"
import Modal from "@ui/Modal"
import colors from "@colors"
import Box from "@ui/Box"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import TCContainer from "container/Realty/TransactionFormContainer"
import CommissionFormImage from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/images/CommissionForm.jpg"
import LoaderButton from "@ui/LoaderButton"
import { ATC } from "@fonts"
import {
  RadiusSelectTheme,
  RadiusInputTheme,
} from "@ui/Form"

const Wrap = styled.div`

`

const Form = styled.form`
  text-align: left;
  margin: 15px;
  label {
    font-family: ${ATC};
    input {
      font-family: ${ATC};
      font-weight: 200;
      box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
      border-radius: 4px;
      border: none;
    }
    input:focus {
      border: 1px solid ${colors.rockBlue};
    }
    span {
      font-family: ${ATC};
      font-weight: 200;
    }
  }
  input {
    font-family: ${ATC};
    font-weight: 200;
    box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
    border-radius: 4px;
    border: none;
  }
  input:focus {
    border: 1px solid ${colors.rockBlue};
  }
  #select_option {
    > div > div > div {
      width: 450px;
      border: 1px solid ${colors.rockBlue};
      font-family: ${ATC};
      font-weight: 200;
    }
  }
`

const FormContainer = styled.div`
  display: flex;
`
const FormWrap = styled.div``

const LaterButton = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  margin-top: 16px;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

const ButtonWrap = styled.div`
  margin: 35px 0 40px 0;
`

const Image = styled.img`
  margin: 15px;
`

const ModalForm = () => (
  <React.Fragment>
    <Form onSubmit={() => { }}>
      <Box mb="10" mt="0">
        <RadiusInputTheme
          name="s_corp_bank_name"
          placeholder=""
          label="S Corp Bank Name"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
        />
      </Box>
      <Box mb="10" mt="20">
        <RadiusInputTheme
          name="s_corp_routing_number"
          placeholder=""
          label="S Corp Routing Number"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
        />
      </Box>
      <Box mb="10" mt="20">
        <RadiusInputTheme
          name="s_corp_account_number"
          placeholder=""
          label="S Corp Account Number"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
        />
      </Box>
    </Form>
  </React.Fragment>
)

class CommissionsPopup extends PureComponent {
  getInitialValues = () => {
    const { getOnboardingCommissionResponse } = this.props
    if (getOnboardingCommissionResponse?.data?.response) {
      return getOnboardingCommissionResponse.data.response
    }
    return {}
  }

  isDisabled = () => {
    const { combinedTCValues } = this.props
    if (combinedTCValues.tcPaymentForm) {
      const {
        s_corp_bank_name,
        s_corp_routing_number,
        s_corp_account_number,
      } = combinedTCValues.tcPaymentForm
      if (
        s_corp_bank_name || s_corp_routing_number || s_corp_account_number
      ) {
        return false
      }
      return true
    }
    return true
  }

  WrappedForm = reduxForm({
    form: "TC_PAYMENT_FORM_2",
    // destroyOnUnmount: true,
    initialValues: this.getInitialValues(),
  })(ModalForm)

  goNext = (isDraft) => {
    const {
      toggleCommissionsPopup, getOnboardingCommissionResponse, createOnboardingCommission, updateOnboardingCommission, combinedTCValues,
    } = this.props
    const {
      s_corp_bank_name,
      s_corp_routing_number,
      s_corp_account_number,
    } = combinedTCValues.tcPaymentForm
    if (getOnboardingCommissionResponse?.data?.response) {
      updateOnboardingCommission({
        payload: {
          step_completed: "s_corp_bank_info",
          s_corp_bank_name,
          s_corp_routing_number,
          s_corp_account_number,
        },
        isDraft,
        isContinue: 3,
      })
    } else {
      createOnboardingCommission({
        payload: {
          step_completed: "s_corp_bank_info",
          s_corp_bank_name,
          s_corp_routing_number,
          s_corp_account_number,
        },
        isDraft,
        isContinue: 3,
      })
    }
    if (isDraft) {
      return toggleCommissionsPopup(0)
    }
  }

  goBack = () => {
    const {
      toggleCommissionsPopup,
    } = this.props
    toggleCommissionsPopup(1)
  }

  render() {
    const { WrappedForm } = this
    const {
      getOnboardingCommissionResponse, createOnboardingCommissionResponse, updateOnboardingCommissionResponse,
    } = this.props
    return (
      <Wrap>
        <FormContainer>
          <Image src={CommissionFormImage} />
          <FormWrap>
            <WrappedForm />
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                width="95%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                isShowLoader={createOnboardingCommissionResponse.isFetching || updateOnboardingCommissionResponse.isFetching || getOnboardingCommissionResponse.isFetching}
                onClick={() => { this.goNext() }}
              >
                Finish
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px"
                width="95%"
                margin="16px 0 0 0"
                borderRadius="50px"
                onClick={() => { this.goBack() }}
              >
                Go Back
              </LoaderButton>
              <LaterButton
                disabled={this.isDisabled()}
                onClick={() => {
                  if (!this.isDisabled()) {
                    this.goNext(true)
                    supressPopups("commissionsPopup")
                  }
                }}
              >
                Save for Later
              </LaterButton>
            </ButtonWrap>
          </FormWrap>
        </FormContainer>
      </Wrap>
    )
  }
}

export default TCContainer(CommissionsPopup)
