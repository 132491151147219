import React from "react"
import { LoadScript, initFreshChat } from "services/Utils"
import root from "window-or-global"

class WithFreshMessage extends React.Component {
  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }
}

export default WithFreshMessage
