import React from "react";
import Modal from "@ui/Modal";
import OffersV2 from "container/ListingsV2";
import OfferOld from "container/TechAssets";
import Listings from "container/Listings";
import { Wrap, ModalTitle, ModalDescription, FormWrapper } from "./style";
import { CreateEnvelopeForm } from "./components";

const CreateEnvelopeModal = ({
  toClose,
  sendListingDocuments,
  docList,
  toggleSideNavTab,
  sendListingDocumentsResponse,
  getOfferDocuments,
  dealToView,
  propertyInformationObject,
  listingData,
  envelopeListings,
  fetchEnvelopeListings,
  fetchMergedEnvelopes,
  listingIdToView,
  currentBrokerageType,
  sendOffersChecklistDocuments,
  offerIdToView,
  sendOffersChecklistDocumentsResponse,
  listingAddress
}) => {
  const {
    isFetching: sendingDocument,
  } = sendListingDocumentsResponse || {}

  const {
    isFetching: sendingDocumentOffer,
  } = sendOffersChecklistDocumentsResponse || {}

  const handleSubmit = (values) => {
    //envelopeIds
    console.log(values);
    const payload = {
      ...values,
      envelopeIds: [],
    };
    let selectedIds = [];
    for (let key in envelopeListings) {
      let selectedItems = envelopeListings[key]
        .filter((item) => item.isSelected && item.envelope_id != null)
        .map((item) => item.envelope_id);
      selectedIds = selectedIds.concat(selectedItems);
    }

    console.log(selectedIds);

    payload.envelopeIds = [...selectedIds];

    if(currentBrokerageType == "offers"){
      sendOffersChecklistDocuments({
        payload,
        callback: () => {
          toClose();
          //toggleSideNavTab("active")
          fetchEnvelopeListings({
            type:currentBrokerageType,
            offerId: offerIdToView || dealToView,
          });
          fetchMergedEnvelopes({
            type:currentBrokerageType,
            offerId: offerIdToView || dealToView,
          });
      },
      })
    } else {
      sendListingDocuments({
        payload,
        callback: () => {
          toClose();
          //toggleSideNavTab("active")
          fetchEnvelopeListings({
            type:currentBrokerageType,
            listingId: listingIdToView,
          });
          fetchMergedEnvelopes({
            type:currentBrokerageType,
            listingId: listingIdToView,
          });
        },
      });
    }
  }

  return (
    <Modal
      toClose={toClose}
      width="533px"
      show
      modalVersion={3}
      // closeIcon={CloseIcon}
      overlayStyles={{
        alignItems: "flex-start",
        borderRadius: "9px",
        zIndex: 1,
      }}
      modalStyles={{
        padding: "40px 25px 0px 25px",
      }}
      dialogStyles={{
        borderRadius: "8px",
      }}
    >
      <Wrap>
        <ModalTitle>
          <p>Customize email message</p>
        </ModalTitle>
        <ModalDescription>
          <p>
            {`Please add a subject and the personalized note for when
             this envelope will be sent to the seller. These documents will be
              sent to your seller for digital signature only after they have been
               fully executed by you.`}
          </p>
        </ModalDescription>
        <FormWrapper>
          <CreateEnvelopeForm
            toClose={toClose}
            onFinish={handleSubmit}
            sendingDocument={currentBrokerageType == "offers" ? sendingDocumentOffer :  sendingDocument}
            listingData={listingData}
            propertyInformationObject={propertyInformationObject}
            currentBrokerageType={currentBrokerageType}
            listingAddress={listingAddress}
          />
        </FormWrapper>
      </Wrap>
    </Modal>
  );
};

export default Listings(OffersV2(OfferOld(CreateEnvelopeModal)));
