export const RADIUS_TRANSACTION_COORDINATION_AGENT_CONFIRMATION = [
  {
    id: 1,
    name: "Yes",
    value: "is_using_deal_tc_yes",
  },
  {
    id: 2,
    name: "No",
    value: "is_using_deal_tc_no",
  },
]

export const FIELD_VALUES = {
  is_using_deal_tc: "checkbox",
}

