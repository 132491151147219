import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as AuthAction from "./actions"

const initState = {
  ...createDeltaReducer("loginResponse"),
  ...createDeltaReducer("appleSignInResponse"),
}

export default function appReducer(state = initState, action) {
  switch (action.data) {
    case AuthAction.loginUserAction.REQUEST:
    case AuthAction.loginUserAction.FAILED:
    case AuthAction.loginUserAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AuthAction.loginUserAction,
          "loginResponse"
        ),
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
