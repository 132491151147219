import React from "react"
import {
  Form,
  DatePicker,
} from "antd"
import moment from "moment"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  StyledDatePicker,
} from "./styles"

/**
 *
 * border-radius: 12px;
background: var(--Color-Primary-Primary, #5A5FF2);
 */
const commonRules = [
  {
    required: true,
    message: "Required",
  },
]

const InputDatePicker = ({
  onChange,
  name,
  text,
  datesDisabledFlag = "",
  required = false,
  requiredMessage = "Required",
}) => {
  const disabledDate = (current, type) => {
    // Can not select days before today and today
    if (type === "before") return current && current < moment().endOf("day")
    if (type === "after") return current && current > moment().endOf("day")
  }

  return (
    <Form.Item
      name={name}
      label={<LabelComponent text={text} />}
      rules={[{ required: required, message: requiredMessage }]}
      required={required}
    >
      {datesDisabledFlag ? (
        <StyledDatePicker disabledDate={current => disabledDate(current, datesDisabledFlag)} format="MM-DD-YYYY" isRequired={required}/>
      ) : (
        <StyledDatePicker format="MM-DD-YYYY" isRequired={required}/>
      )}
    </Form.Item>
  )
}

export default InputDatePicker
