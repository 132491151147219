import React, { useState, useEffect } from "react";
import Cookies from "react-cookies";
import colors from "colors";
import cookie from "services/CookieStorage";
import root from "window-or-global";
import urlParser from "url-parse";
import queryString from "query-string";
import { isEmpty, get } from "lodash";
import {
  MOBILE_BREAK_POINT,
  YOUTUBE,
  YOUTUBECOM,
  LOOM,
  VIMEO,
  VIMEOCOM,
} from "shared/consts";
import BenCurciImage from "images/ben-curci.jpg";
import { removeItemFromLocalStorage } from "shared/utils"

const youTubeEmbedRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// export const getImagePixelColor = (srcImg) => {
//   const myImg = new Image()
//   myImg.crossOrigin = "Anonymous"
//   myImg.onload = () => {
//     const context = document.createElement("canvas").getContext("2d")
//     context.drawImage(myImg, 0, 0)
//     const {
//       data,
//     } = context.getImageData(86, 104, 1, 1)
//   }
//   myImg.src = srcImg
// }

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function extractLinks(string, isTimeline) {
  const urlRegex = /(https?:\/\/[^ ]*)/;
  const tempString = string.split(/(https?:\/\/[^ ]*)/);
  const linkIndex =
    (tempString &&
      tempString.length > 0 &&
      tempString
        .map((str, index) => str.match(urlRegex) && index)
        .filter((ele, index) => ele !== null && index)[0]) ||
    "";
  const regExUrl = string.match(urlRegex);
  const url = regExUrl && regExUrl[1];
  tempString[linkIndex] = "link";
  let cleanUrl =
    url &&
    url
      .split(".")
      .filter((str) => str !== "" && str)
      .join(".");
  if (cleanUrl && cleanUrl[cleanUrl.length - 1] === '"') {
    cleanUrl = cleanUrl.replace('"', "");
  }
  if (linkIndex && url && cleanUrl) {
    return (
      <div>
        <p>
          {isTimeline && <strong>Note: </strong>}
          {tempString.map((str) => {
            if (str === "link") {
              return (
                <a href={cleanUrl} target="_blank">
                  {url}
                </a>
              );
            }
            return str;
          })}
        </p>
      </div>
    );
  }
  return string;
}

export const AnchorLink = ({ note, isTimeline }) => {
  const urlElement = extractLinks(note, isTimeline);
  if (urlElement && urlElement.type === "div") {
    return urlElement;
  }
  return <span>{`"${note}"`}</span>;
};

export const getEmbedConverter = (url) => {
  if (!url) return false;
  const check = url.split("/");
  const embeddedCode = url.split("/");
  const match = url.match(youTubeEmbedRegex);
  if (check.includes(YOUTUBE) || check.includes(YOUTUBECOM)) {
    return `https://www.youtube.com/embed/${match[2]}`;
  }

  if (check.includes(VIMEO) || check.includes(VIMEOCOM)) {
    return `https://player.vimeo.com/video/${embeddedCode[3]}`;
  }

  if (check.includes(LOOM)) {
    return `https://www.loom.com/embed/${embeddedCode[4]}`;
  }

  return "";
};

export const unicodeToChar = (text) =>
  text.replace(/\\u[\dA-F]{4}/gi, (match) =>
    String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16))
  );

export const isVideoUrl = (url) => {
  if (!url) return false;
  const check = url.split("/");
  return (
    check.includes(YOUTUBE) ||
    check.includes(YOUTUBECOM) ||
    check.includes(VIMEO) ||
    check.includes(VIMEOCOM) ||
    check.includes(LOOM)
  );
};

export function abbreviateNumber(value) {
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixNum = Math.floor(`${value}`.length / 3);
    let shortValue = "";
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export function getOS() {
  const { userAgent } = root.navigator || {};

  if (/iPhone/.test(userAgent)) {
    return "ios";
  }

  if (/Android/.test(userAgent)) {
    return "android";
  }

  return "browser";
}

export function getIsPremium(categorizedSubscriptions, pricingPlansMap) {
  const currentPlan =
    categorizedSubscriptions.rtl || categorizedSubscriptions.blu;
  const mappedPlan = pricingPlansMap[currentPlan];
  if (mappedPlan !== undefined) {
    if (mappedPlan.tier === "PREMIUM") {
      return true;
    }
  }
  return false;
}

export function LoadScript(url, callback) {
  const script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    //IE
    // eslint-disable-next-line func-names
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    //Others
    // eslint-disable-next-line func-names
    script.onload = function() {
      callback();
    };
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

// eslint-disable-next-line no-shadow
export function UrlSearchParams(queryString) {
  const results = new RegExp(`[?&]${queryString}=([^&#]*)`).exec(
    window.location.href
  );
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
}

export function getEpoch(customDate = new Date()) {
  return window.parseInt(customDate.getTime() / 1000);
}

export const unixToDate = (input) => {
  try {
    const a = new Date(0);
    a.setUTCSeconds(input);
    return a;
  } catch (e) {
    return "invalid date";
  }
};

export const getStoreKeyForReferrals = (type) => {
  switch (type) {
    case "incoming":
      return "inboundReferrals";
    case "outgoing":
      return "outboundReferrals";
    case "isa":
      return "isaReferrals";
    default:
      return "referral";
  }
};

export const checkForUserObject = (type) => {
  if (type) {
    if (type === "user") {
      return Cookies.load(type) || {};
    }
    return Cookies.load(type);
  }
  const leadAgreementSigned = Cookies.load("leadAgreementSigned");
  const referralManager = Cookies.load("referralManager");
  const isCrsDesignee = Cookies.load("isCrsDesignee");
  const allowLeadAgreement = Cookies.load("allowLeadAgreement");
  const isaLeadAgreementSigned = Cookies.load("isaLeadAgreementSigned");
  const gmail_email = Cookies.load("gmail_email");
  const office_short_state_name = Cookies.load("office_short_state_name");
  const office_city = Cookies.load("office_city");
  const profile_name = Cookies.load("profile_name");
  return {
    leadAgreementSigned,
    referralManager,
    isCrsDesignee,
    allowLeadAgreement,
    isaLeadAgreementSigned,
    gmail_email,
    office_short_state_name,
    office_city,
    profile_name,
  };
};

export const saveUserObjectToCookie = (data, ignoreList = []) => {
  //console.log("saving", data)
  Object.keys(data).forEach((key) => {
    //console.log(`Saving ${key}: `, data[key])
    if (!ignoreList.includes(key)) {
      cookie.save(key, data[key]);
    }
  });
};

export const isSuccess = (res = {}) =>
  res && res.status >= 200 && res.status <= 300;

export const getImageBasedOnDPI = (url = "", res) => {
  const FALLBACK_IMG =
    "http://d3h12z5cxndkqx.cloudfront.net/timelineIcons/submitted-deactivated@2x.png";
  if (!url) return FALLBACK_IMG;
  let fileFormat = "";
  for (let i = url.length; i >= 0; i -= 1) {
    fileFormat += url[i - 1];
    if (url[i - 1] === ".") {
      break;
    }
  }
  fileFormat = fileFormat
    .split("")
    .reverse()
    .join("");
  const urlBreak = url.split(fileFormat);
  const size = res ? `@${res}x` : "";
  return `${urlBreak[0]}${size}${fileFormat}`;
};

export const isCRS = () =>
  checkForUserObject("isCrsDesignee") === "true" &&
  checkForUserObject("allowLeadAgreement") === "false";

export const isTrueCRS = () => checkForUserObject("isCrsDesignee") === "true";

export const getReferralManager = (isDefault, type) => {
  let referralManager = cookie.load("referralManager");
  const user = checkForUserObject("user");

  if (referralManager && type === "concierge") return referralManager;

  if (
    !referralManager ||
    referralManager === "undefined" ||
    referralManager === "null" ||
    isDefault
  ) {
    referralManager = {
      firstname: "Ben",
      lastname: "Curci",
      email: "ben@radiusagent.com",
      // phone: "(275) 654-3223",
      title: "Hi there! Do you have any questions about Radius Assist?",
      photo: BenCurciImage,
      calendly_links: {
        isa_demo: "https://meetings.hubspot.com/ben854",
        isa_demo_web: "https://meetings.hubspot.com/ben854",
        isa_demo_mobile: "https://meetings.hubspot.com/ben854",
        concierge_demo: "https://meetings.hubspot.com/ben854",
        concierge_demo_web: "https://meetings.hubspot.com/ben854",
        concierge_demo_mobile: "https://meetings.hubspot.com/ben854",
        isa_intro_web: "https://meetings.hubspot.com/ben854",
        isa_intro_mobile: "https://meetings.hubspot.com/ben854",
        concierge_intro_mobile: "https://meetings.hubspot.com/ben854",
        concierge_intro_web: "https://meetings.hubspot.com/ben854",
        concierge_mobile: "https://meetings.hubspot.com/ben854",
        concierge_web: "https://meetings.hubspot.com/ben854",
        isa_mobile: "https://meetings.hubspot.com/ben854",
        isa_web: "https://meetings.hubspot.com/ben854",
      },
      isa_demo_link: "https://meetings.hubspot.com/ben854",
      id: 7792851,
    };
  }

  if (user && !isEmpty(user) && cookie.load("isaManager") && type === "isa") {
    const savedReferralManager = cookie.load("isaManager");
    if (savedReferralManager !== "null") {
      referralManager = savedReferralManager;
    }
  }
  return referralManager;
};

export const getPublicISACalendlyLinks = () => ({
  isa_fb: "https://calendly.com/radiusagent/radius-assist-fb",
  isa_wcr:
    "https://meetings.hubspot.com/radiusagent/radius-assist-interest-wcr",
});

export const isLinkActive = (url) => {
  const { pathname } = root.location || "";
  return pathname.split("/").includes(url);
};

export const phoneNumberFormatter = (phone) =>
  phone.replace(
    /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
    (_, p1, p2, p3, p4) => {
      let output = "";
      if (p1) output = `${p1}`;
      if (p2) output += `${p2}-`;
      if (p3) output += `${p3}-`;
      if (p4) output += `${p4}`;
      return output;
    }
  );

export const isBrowser = () => {
  try {
    if (window || navigator) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const newBlob = blob.slice(0, blob.size, "image/jpeg");
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(newBlob);
  });

export function searchToObject() {
  const pairs = root.location.search.substring(1).split("&");

  const obj = {};

  let pair;

  let i;

  for (i in pairs) {
    if (pairs[i] === "") continue;
    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}

export const GetUserPlatform = () => {
  if (/Android/i.test(navigator.userAgent)) {
    return "android";
  }
  if (/iPhone/i.test(navigator.userAgent)) {
    return "ios";
  }
  if (/iPad/i.test(navigator.userAgent)) {
    return "ipad";
  }
  return "desktop";
};

export const getQueryFromURL = (url) => {
  console.log("=====> url", url);
  console.log("======> Index", url.indexOf("watch"));
  // Check for agentId first
  let finalQuery = "";
  if (url.indexOf("woff") !== -1 || url.indexOf("ttf") !== -1) {
    console.log("WRONG FILES ====>", colors.red(url));
    return "";
  }

  if (url.indexOf("profile/agent?id") !== -1) {
    const location = urlParser(url);
    const query = queryString.parse(location.query);
    finalQuery = `?agentId=${query.id}`;
  } else if (url.indexOf("agentId") !== -1) {
    // AgenId exists, now get it.
    const location = urlParser(url);
    const query = queryString.parse(location.query);
    finalQuery = `?agentId=${query.agentId}`;
  } else if (url.indexOf("/profile/") !== -1) {
    //we don't have Agent ID, so let's use profileName
    let userId = url.replace("/profile/", "");
    const queryIndex = userId.indexOf("?");
    if (queryIndex !== -1) {
      userId = userId.substring(0, queryIndex);
    }
    if (!userId) {
      return null;
    }
    finalQuery = `?profileName=${userId}`;
  } else if (url.indexOf("/feed/") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    return pathArray[3];
  } else if (url.indexOf("/live") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    const id = pathArray[pathArray.length - 3];
    finalQuery = `?room_id=${id}`;
  } else if (url.indexOf("watch") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    const id = pathArray[pathArray.length - 2];
    finalQuery = id;
  } else if (url.indexOf("onboard") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    const id = pathArray[pathArray.length - 2];
    finalQuery = `?room_id=${id}`;
  } else if (url.indexOf("/rooms") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    finalQuery = `?skip=0&limit=10&room_id=${pathArray[2]}&show_live=true&include_immortal_rooms=true`;
  } else if (url.indexOf("/teamprofile") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    finalQuery = pathArray && pathArray[pathArray.length - 1];
  } else if (url.indexOf("/teams/jobs") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    finalQuery = "skip=0&limit=10";
    if (pathArray[3]) {
      finalQuery += `&slug=${pathArray[3]}`;
    }
  } else if (url.indexOf("/scheduledrooms") !== -1) {
    const { pathname = "" } = urlParser(url);
    const pathArray = pathname.split("/");
    finalQuery = `?room_id=${pathArray[2]}`;
  }
  // else if (url.indexOf("/property") !== -1) {
  //   const location = urlParser(url);

  //   console.log(location, "location");
  //   const pathArray = location.pathname.split("/");
  //   const query = queryString.parse(location.query);
  //   console.log(pathArray, 'pathArray in utils.js', query, 'location-->', location)
  //   finalQuery = `?mlsId=${pathArray[2]}&mlsPropertyId=${query.unique_mls_id}&propertyInfoPage=true`;
  //   console.log(finalQuery);
  // }
  else {
    return "";
  }
  return finalQuery;
};

export const canUserEdit = (apiId) => {
  try {
    const user = cookie.load("user");
    if (
      isBrowser() &&
      user &&
      user.id &&
      parseInt(user.id, 0) === parseInt(apiId, 0)
    ) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getFromCookie = (type) => {
  try {
    const res = cookie.load(type);
    return res;
  } catch (e) {
    return false;
  }
};

export const getUserIdFromCookie = () => {
  try {
    const user = cookie.load("user");
    if (isBrowser() && user && user.id) {
      return user.id;
    }
  } catch (e) {
    // handle it
  }
  return "";
};

export const removeCommaFromNumber = (val = "") => {
  try {
    if (typeof val === "number") {
      return val;
    }
    if (val && typeof val === "string") {
      return parseInt(val.replace(/,/g, ""), 0);
    }
    return val;
  } catch (e) {
    return val;
  }
};

export const MoneyFormatter = (value) => {
  if (!value || value === undefined) return 0;
  const replaceAsNumbers = value.replace(/[^\w\s]/gi, "");
  return parseInt(replaceAsNumbers, 10);
};

export const objectToQueryParams = (obj) => {
  const str = [];
  // console.log(obj)
  Object.keys(obj).forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      if (obj[key]) {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
      }
    }
  });
  return str.join("&");
};

export const convertUnicode = (input) =>
  input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
    const charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });

export const convertUnicodeEmoji = (input) =>
  input.replace(/\\U\+(\w+)/g, (match, hexCode) => {
    const charCode = parseInt(hexCode, 16);
    return String.fromCodePoint(charCode);
  });

export const getOnlyNotNullValues = (obj) => {
  const returnObject = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      returnObject[key] = obj[key];
    }
  });

  return returnObject;
};
export const addHttpInURl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }
  return url;
};

export const getProfileUrl = (data) => {
  if (!data) {
    return "";
  }
  const { profile_name, agentId, profile_url, id } = data;
  if (profile_name) {
    return `/profile/${profile_name}`;
  }
  if (agentId || id) {
    return `/profile/agent?id=${agentId || id}`;
  }
  if (profile_url) {
    return profile_url;
  }
  return "#";
};

export const convertStringToUnicode = (str) =>
  unescape(encodeURIComponent(str));

const jsxDecimalToHex = (d, padding) => {
  let hex = Number(d).toString(16);
  const paddingResult =
    typeof padding === "undefined" || padding === null ? 2 : padding;

  while (hex.length < paddingResult) {
    hex = `0${hex}`;
  }

  return hex;
};

export const unicodeToEscaped = (str) => {
  let escapedCode = "";
  let decValue = 0;

  for (let i = 0; i < str.length; i += 1) {
    decValue = str.charCodeAt(i);
    if (decValue > 127) {
      escapedCode += `\\u${jsxDecimalToHex(decValue, 4)}`;
    } else {
      escapedCode += str.charAt(i);
    }
  }

  return escapedCode;
};

// export const escapedToUnicode = (escapedCode) => {
//   const regExp = /\\u([\d\w]{4})/gi
//   const escapedCodeTemp = escapedCode.replace(regExp, function (match, group) {
//     return String.fromCharCode(parseInt(group, 16))
//   })
//   const unicode = unescape(escapedCodeTemp)

//   return unicode
// }

export const getRandomSeedValue = (min = 1000000000, max = 9000000000) =>
  Math.floor(Math.random() * (max - min)) + min;

export const isQualifiedProspects = () =>
  get(root, "location.pathname", "") === "/referrals/prospects/pipeline";

export const isInboundReferrals = () =>
  get(root, "location.pathname", "").includes("/referrals/inbound");

export const isAuthenticated = () => {
  const user = checkForUserObject("user");
  return !isEmpty(user);
};

export const isBroadcastChannelSupported = () => {
  try {
    if (BroadcastChannel) {
      console.log("Browser is supporting BroadcastChannel");
      return true;
    }
  } catch (error) {
    console.log("Browser is NOT supporting BroadcastChannel");
    console.log("Using polyfill");
    return false;
  }
};

export const getFirstCharacterCapital = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const getAssociationFromCookies = {
  isWcr: () => cookie.load("isWcr"),
  isAreaa: () => cookie.load("isAreaa"),
  isSamcar: () => cookie.load("isSamcar"),
  isCrsDesignee: () => cookie.load("isCrsDesignee"),
};

export const deleteArrayOfCookies = (keyArray) => {
  keyArray.forEach((key) => {
    cookie.remove(key);
  });
};

export const formatMoney = (amount) => {
  try {
    const decimalCount = 2;
    const decimal = ".";
    const thousands = ",";

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      Math.abs(Number(amount) || 0).toFixed(decimalCount),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const toggleBooleanFromInteger = (integerValue) =>
  integerValue ? 0 : 1;

export const pluralize = (value, singularForm, pluralForm) => {
  if (parseInt(value, 10) !== 1) {
    return pluralForm;
  }
  return singularForm;
};

export const getProductFromPlanId = (planId) => {
  if (planId.indexOf("rtl") > -1) return "rtl";
  if (planId.indexOf("blu") > -1) return "blu";
  if (planId.indexOf("broker") > -1) return "brk";
  return "rtl";
};

export const getProductFromTeamPlanId = (planId) => {
  if (planId.indexOf("team") > -1) {
    if (planId.indexOf("rtl") > -1) return "team_rtl";
    if (planId.indexOf("blu") > -1) return "team_blu";
  } else {
    if (planId.indexOf("rtl") > -1) return "single_rtl";
    if (planId.indexOf("blu") > -1) return "single_blu";
    if (planId.indexOf("broker") > -1) return "single_brk";
  }
};

export const getFormattedCardDetails = (inputMonth, inputYear) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const expMonth = `0${inputMonth}`.slice(-2);
  const expYear = inputYear.toString().slice(-2);

  let isExpired = false;
  if (currentYear > inputYear) {
    isExpired = true;
  } else if (currentYear === inputYear && currentMonth > inputMonth) {
    isExpired = true;
  }

  return {
    isExpired,
    expMonth,
    expYear,
  };
};

export const getAgentId = () => {
  const user = checkForUserObject("user");
  return get(user, "id") || get(user, "agent_id");
};

export const getSeatAmount = (subscription) => {
  const adminPlan = subscription.map((plan) =>
    plan.items.data.filter(
      (key) => key.plan.id.indexOf("admin") > -1 && key.plan
    )
  );
  const seatCost =
    get(adminPlan, "[0][0]plan.amount") /
    100 /
    get(adminPlan, "[0][0]plan.interval_count");
  return {
    seatCost,
  };
};

export const initFreshChat = (userData = false) => {
  try {
    if (root.fcWidget) {
      const user = userData;
      root.fcWidget.init({
        token: "a7fdf764-0221-49c4-abac-3297f6cee338",
        host: "https://wchat.freshchat.com",
        externalId: user.email,
        firstName: user.firstname,
        phone: user.phone,
        lastName: user.lastname,
        email: user.email,
      });
    }
    if (root.HubSpotConversations && userData && !isMobile()) {
      //root.HubSpotConversations.widget.load()
    }
  } catch (error) {
    console.warn("Error init freshchat, falling back tp defaults");
    root.fcWidget.init({
      token: "a7fdf764-0221-49c4-abac-3297f6cee338",
      host: "https://wchat.freshchat.com",
    });
    // ignore error
  }
};

export const isMobile = () => {
  const screenWidth = root.innerWidth;
  return screenWidth < 800;
};

export const shortTimeStamp = (text) => {
  if (text.includes("minutes")) {
    return text.replace("minutes", "mins");
  }
  if (text.includes("minute")) {
    return text.replace("minutes", "min");
  }
  if (text.includes("hour")) {
    return text.replace("hours", "hr");
  }
  if (text.includes("hours")) {
    return text.replace("hours", "hrs");
  }
  if (text.includes("day") || text.includes("days")) {
    return text.replace("days", "d");
  }
  return text;
};

export const clearCookiesOnLogout = () => {
  deleteArrayOfCookies([
    "redirectUrl",
    "brokerageTeamId",
    "brokerageTeamIdForLogo",
    "stateBannerViewedUpdated",
    "inviteType",
    "interestViaPopUp",
    "utm_source",
    "rar_promotions_source_page",
    "currentGroup",
    "secureId",
    "user",
    "isCrsDesignee",
    "leadAgreementSigned",
    "isaLeadAgreementSigned",
    "referralManager",
    "agentId",
    "onboardingComplete",
    "onboarding",
    "isWcr",
    "isAreaa",
    "isSamcar",
    "bannerClosed",
    "G_HOME_PAGE_VARIANT",
    "joinToken",
    "hasUserOnboarded",
    "isSpotlightShownNew",
    "tcInformation",
    "email",
    "networkAgentBannerStatus",
  ]);
  root.localStorage.removeItem("roomsJoinToken");
  removeItemFromLocalStorage("enableSpecialColors")
};

export const parseUsername = (user = {}) => {
  const { firstname, lastname } = user;
  let finalName = "";
  if (firstname) {
    finalName += firstname;
  }
  if (lastname) {
    finalName += lastname;
  }
  return finalName || "N/a";
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const gotoProfilePage = ({ history }) => {
  const redirectUrl = cookie.load("redirectUrl");
  if (
    redirectUrl &&
    (redirectUrl.indexOf("academy") !== -1 ||
      redirectUrl.indexOf("profile") !== -1 ||
      redirectUrl.indexOf("realty") !== -1 ||
      redirectUrl.indexOf("assist") !== -1 ||
      redirectUrl.indexOf("careers") !== -1 ||
      redirectUrl.indexOf("marketplace") !== -1 ||
      redirectUrl.indexOf("checkout") !== -1)
  ) {
    cookie.remove("redirectUrl");
    root.location.href = `${APP_URL}${redirectUrl}`;
    return;
  }
  if (GetUserPlatform() === "desktop") {
    root.location.href = `${APP_URL}/community`;
  } else {
    history.push("/page/redirect?tag=landing_page&params={}");
  }
};

export const setUtmSource = (expiresAfter) => {
  const date = new Date();
  date.setDate(date.getDate() + (expiresAfter || 1));
  const { search } = root.location;
  const parsedQuery = queryString.parse(search);
  if (
    parsedQuery &&
    parsedQuery.utm_source === "email" &&
    parsedQuery.utm_campaign === "Findem"
  ) {
    cookie.save("utm_campaign", "email_findem", { expires: date });
    return;
  }
  if (parsedQuery && parsedQuery.utm_source) {
    cookie.save("utm_campaign", parsedQuery.utm_source, { expires: date });
  }
};

export const getRarHubspotMeetingLink = () => {
  const rarHubspotLink =
    "https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true";
  const utmMap = {
    email_findem: {
      tag: "Findem",
      link:
        "https://meetings.hubspot.com/biju-ashokan/radius-agent-realty-findem-email-campaign?embed=true&utm_campaign=Findem",
    },
    Facebook: {
      tag: "Facebook",
      link: `${rarHubspotLink}&utm_campaign=Facebook`,
    },
    gad: {
      tag: "gad",
      link: `${rarHubspotLink}&utm_campaign=gad`,
    },
    mlp: {
      tag: "mobilelandingpage",
      link: `${rarHubspotLink}&utm_campaign=mobilelandingpage`,
    },
    fb: {
      tag: "Facebook2",
      link: `${rarHubspotLink}&utm_campaign=Facebook2`,
    },
    "google-ad": {
      tag: "gad",
      link: `${rarHubspotLink}&utm_campaign=gad`,
    },
    Job_ad: {
      tag: "Job_ad",
      link:
        "https://meetings.hubspot.com/radius/radius-agent-realty-learn-more-job-ad?embed=true&utm_campaign=job_ad",
    },
    EM_CR: {
      tag: "EM_CR",
      link:
        "https://meetings.hubspot.com/radiusagent/rc?embed=true&utm_campaign=EM_CR",
    },
    events: {
      tag: "ev",
      link:
        "https://meetings.hubspot.com/radiusagent/ev?embed=true&utm_campaign=ev",
    },
    event: {
      tag: "ev",
      link:
        "https://meetings.hubspot.com/radiusagent/ev?embed=true&utm_campaign=ev",
    },
    EM_IF: {
      tag: "EM_IF",
      link:
        "https://meetings.hubspot.com/radius/em-if?embed=true&utm_campaign=EM_IF",
    },
  };

  const utmSource = cookie.load("utm_campaign");
  if (!utmSource) return rarHubspotLink;
  if (!utmMap[utmSource] && utmSource)
    return `${rarHubspotLink}&utm_campaign=${utmSource}`;
  return utmMap[utmSource].link;
};

export const postCallProductClick = ({
  rarProductAndServices,
  sendSlackNotificationForProduct,
  show,
  id,
  type,
  history,
  source,
}) => {
  const { data } = rarProductAndServices;
  const stateObj = { showProductModal: show };
  if (id) {
    stateObj.currentContent = data[type].find((dt) => dt.id === id);
    if (show && root.heap) {
      root.heap.track(
        `Click_${stateObj.currentContent.identifier}_${stateObj.currentContent.actionType}_${source}_Web`
      );
    }
    if (stateObj.currentContent.actionType === "access") {
      sendSlackNotificationForProduct({
        rarTechAssetsId: stateObj.currentContent.id,
        status: "pending",
        requestType: "Access",
        message: `Trying to access : ${stateObj.currentContent.header}`,
      });
    }
    if (stateObj.currentContent.mailTo) {
      const mail = document.createElement("a");
      mail.href = `mailto:${stateObj.currentContent.mailTo}`;
      mail.click();
      return null;
    }
    if (stateObj.currentContent.actionLink) {
      if (stateObj.currentContent.actionLink.includes("radiusagent.com")) {
        history.push(
          stateObj.currentContent.actionLink.split("radiusagent.com")[1]
        );
        return null;
      }
      let url = stateObj.currentContent.actionLink;
      if (!url.includes("http")) {
        url = `http://${url}`;
      }
      root.open(url);
      return null;
    }
  }
  return stateObj;
};

export const sendHeapEvents = (event) => {
  if (root.heap) {
    root.heap.track(event);
  }
};

export const createGuestUniqueId = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const roomGuestSessionId = `guest_user_0${getRandomSeedValue(1000, 9999)}`;
  cookie.save("room_guest_session_id", roomGuestSessionId, { expires: date });
  return roomGuestSessionId;
};

export const getGuestUniqueId = () =>
  cookie.load("room_guest_session_id") || createGuestUniqueId();
export const getPlanLeadThreashold = () => {};

export const getStateBasedSupportLink = (
  state = "California",
  city = "California"
) => {
  const stateMap = [
    // {
    //   name: "Shatara Hardaway",
    //   state: "Georgia",
    //   hubspotLink: "https://calendly.com/shatara-hardaway",
    //   profilePic: "https://d3cyzvjm1vex6o.cloudfront.net/profile/8530414/1648487272212.jpeg",
    //   phone: "762-200-3336",
    //   email: "shatara.hardaway@radiusagent.com",
    //   supportEmail: "ga.broker@radiusagent.com",
    //   subText: "Georgia Managing Broker",
    // },
    // {
    //   name: "Tami Ritoch",
    //   state: "Oregon",
    //   hubspotLink: "https://calendly.com/tami-ritoch",
    //   profilePic: "https://d3cyzvjm1vex6o.cloudfront.net/profile/8529753/1650917922962.jpeg",
    //   phone: "564-212-5442",
    //   email: "tami.ritoch@radiusagent.com",
    //   supportEmail: "or.broker@radiusagent.com",
    //   subText: "Oregon Managing Broker",
    // },
    // {
    //   name: "Tami Ritoch",
    //   state: "Washington",
    //   hubspotLink: "https://calendly.com/tami-ritoch",
    //   profilePic: "https://d3cyzvjm1vex6o.cloudfront.net/profile/8529753/1650917922962.jpeg",
    //   phone: "564-212-5442",
    //   email: "tami.ritoch@radiusagent.com",
    //   supportEmail: "wa.broker@radiusagent.com",
    //   subText: "Washington Managing Broker",
    // },
    {
      name: "De Wilmore",
      state: "Oregon",
      hubspotLink: "https://meetings.hubspot.com/de-wilmore",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/profile/8447373/1641165139769.jpeg",
      phone: "415-917-1921",
      email: "de.wilmore@radiusagent.com",
      supportEmail: "ca.broker@radiusagent.com",
      subText: "Managing Broker",
      post: "Head of Agent Development",
    },
    {
      name: "De Wilmore",
      state: "California",
      hubspotLink: "https://meetings.hubspot.com/de-wilmore",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/profile/8447373/1641165139769.jpeg",
      phone: "415-917-1921",
      email: "de.wilmore@radiusagent.com",
      supportEmail: "ca.broker@radiusagent.com",
      subText: "Managing Broker",
      post: "Head of Agent Development",
    },
    {
      name: "De Wilmore",
      state: "Washington",
      hubspotLink: "https://meetings.hubspot.com/de-wilmore",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/profile/8447373/1641165139769.jpeg",
      phone: "415-917-1921",
      email: "de.wilmore@radiusagent.com",
      supportEmail: "ca.broker@radiusagent.com",
      subText: "Managing Broker",
      post: "Head of Agent Development",
    },
    {
      name: "De Wilmore",
      state: "Georgia",
      hubspotLink: "https://meetings.hubspot.com/de-wilmore",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/profile/8447373/1641165139769.jpeg",
      phone: "415-917-1921",
      email: "de.wilmore@radiusagent.com",
      supportEmail: "ca.broker@radiusagent.com",
      subText: "Managing Broker",
      post: "Head of Agent Development",
    },
    {
      name: "Katherine Rzad",
      state: "Texas",
      hubspotLink: "https://calendly.com/katherine-rzad-radius",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/user/80187/profile/1641318615120.jpeg",
      phone: "561-489-3407",
      email: "katherine.rzad@radiusagent.com",
      supportEmail: "tx.broker@radiusagent.com",
      subText: "Texas Managing Broker",
    },
    {
      name: "Katherine Rzad",
      state: "Florida",
      hubspotLink: "https://calendly.com/katherine-rzad-radius",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/user/80187/profile/1641318615120.jpeg",
      phone: "561-489-3407",
      email: "katherine.rzad@radiusagent.com",
      supportEmail: "fl.broker@radiusagent.com",
      subText: "Florida Managing Broker",
    },
    {
      name: "De Wilmore",
      state: "Colorado",
      hubspotLink: "https://meetings.hubspot.com/de-wilmore",
      profilePic:
        "https://d3cyzvjm1vex6o.cloudfront.net/profile/8447373/1641165139769.jpeg",
      phone: "415-917-1921",
      email: "de.wilmore@radiusagent.com",
      supportEmail: "ca.broker@radiusagent.com",
      subText: "Managing Broker",
      post: "Head of Agent Development",
    },
  ];
  const result = stateMap.find(
    (mp) => state.includes(mp.state) || city.includes(mp.state)
  );
  return result && result.name ? result : stateMap[0];
};

// Shamelessly copied from https://gist.github.com/oeon/0ada0457194ebf70ec2428900ba76255
export const convertAtob = (a) => {
  let b;
  let c;
  let d;
  const e = {};
  let f = 0;
  let g = 0;
  let h = "";
  const i = String.fromCharCode;
  const j = a.length;
  // eslint-disable-next-line no-plusplus
  for (b = 0; b < 64; b++)
    e[
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(
        b
      )
    ] = b;
  for (c = 0; j > c; c++)
    for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; )
      ((d = 255 & (f >>> (g -= 8))) || j - 2 > c) && (h += i(d));
  return h;
};

export const convertToHTML = (data) => {
  let localData = data;
  try {
    localData = decodeURIComponent(escape(convertAtob(data)));
  } catch (e) {
    localData = data;
  }
  return localData;
};

export const isStringUrl = (str) => {
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);
  return str.match(regex);
};

export const checkIfTeamUpdatedByLead = (teamDetails) =>
  teamDetails &&
  (teamDetails.about_team ||
    teamDetails.other_benefits ||
    (teamDetails.team_images && teamDetails.team_images.length));

export const getCamelCaseName = (iconTag) => {
  let abc = iconTag
    .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
    .replace(/-/g, "");
  abc = abc.replace(/icon/g, "");
  abc = abc.substring(0, 1).toLowerCase() + abc.substring(1);
  return abc;
};

export const getFirstAndLastDateFromMonth = (
  month = new Date().getMonth(),
  year = new Date().getFullYear()
) => {
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0, 24);
  return {
    firstDate,
    lastDate,
    firstDateEpoch: firstDate.getTime() / 1000,
    lastDateEpoch: lastDate.getTime() / 1000,
  };
};

export const getFirstAndLastDateFromYear = (
  year = new Date().getFullYear()
) => {
  const firstDate = new Date(year, 0, 1);
  const lastDate = new Date(year, 12, 0);
  return {
    firstDate,
    lastDate,
    firstDateEpoch: firstDate.getTime() / 1000,
    lastDateEpoch: lastDate.getTime() / 1000,
  };
};

export const getLiteralTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const TimeZone = (epoch, shortFormat) => {
  const date = new Date(epoch * 1000);
  let localeDateString;
  let localeTimeString;
  if (shortFormat) {
    localeDateString = date.toLocaleDateString();
    localeTimeString = date.toLocaleTimeString();
  } else {
    localeTimeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    localeDateString = date.toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return { localeTimeString, localeDateString };
};

export const pstTimeZone = (epoch, includeWeekday) => {
  let pstDateString;
  let pstTimeString;
  const date = new Date(epoch * 1000);
  if (includeWeekday) {
    pstTimeString = date.toLocaleTimeString("en-US", {
      timeZone: "America/Los_Angeles",
      hour: "2-digit",
      minute: "2-digit",
      Hour12: true,
    });
    pstDateString = date.toLocaleDateString("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
    });
  } else {
    pstTimeString = date.toLocaleTimeString("en-US", {
      timeZone: "America/Los_Angeles",
      hour: "2-digit",
      minute: "2-digit",
      Hour12: true,
    });
    pstDateString = date.toLocaleDateString("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return { pstTimeString, pstDateString };
};

export const customeTimeZone = (epoch, timeZone, isHour12) => {
  const date = new Date(epoch * 1000);
  let customTimeString;
  let customDateString;
  switch (timeZone) {
    case "CST":
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "CST",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "CST",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;
    case "HST":
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "HST",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "HST",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;
    case "AKST":
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "US/Alaska",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "US/Alaska",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;
    case "MST":
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "MST",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "MST",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;
    case "EST":
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "EST",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "EST",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      break;
    default:
      customTimeString = date.toLocaleTimeString("en-US", {
        timeZone: "America/Los_Angeles",
        hour: "2-digit",
        minute: "2-digit",
        hour12: isHour12,
      });
      customDateString = date.toLocaleDateString("en-US", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
  }

  return { customTimeString, customDateString };
};

export const ConvertEpoch = (epoch, time_zone) => {
  // current date in local timezone
  const currentLocalDate = new Date(epoch * 1000);
  let customTimeString;
  let customDateString;
  switch (time_zone) {
    case "CST":
    case "HST":
    case "MST":
    case "EST":
      customTimeString = currentLocalDate.toLocaleTimeString("en-US", {
        timeZone: `${time_zone}`,
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      customDateString = currentLocalDate.toLocaleDateString("en-CA", {
        timeZone: `${time_zone}`,
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      break;
    case "AKST":
      customTimeString = currentLocalDate.toLocaleTimeString("en-US", {
        timeZone: "US/Alaska",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      customDateString = currentLocalDate.toLocaleDateString("en-CA", {
        timeZone: "US/Alaska",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      break;
    case "PST":
      customTimeString = currentLocalDate.toLocaleTimeString("en-US", {
        timeZone: "America/Los_Angeles",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      customDateString = currentLocalDate.toLocaleDateString("en-CA", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      break;
    default:
      customTimeString = currentLocalDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      customDateString = currentLocalDate.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
  }

  const customEpoch = parseInt(
    (
      new Date(`${customDateString}T${customTimeString}`).getTime() / 1000
    ).toFixed(0),
    10
  );
  const diffInEpoch = epoch - customEpoch;
  const finalEpoch = epoch + diffInEpoch;

  return finalEpoch;
};

export const showDocusignFeature = () => {
  return true;
  if (ENV !== "production") {
    return true;
  }
  const user = checkForUserObject("user");
  const userId = get(user, "id") || get(user, "agent_id");
  const ENABLED_USER = [10026601, 7895854];
  return ENABLED_USER.includes(userId);
};

export const TABS_CONFIGURATION = {
  // this configuration should come from backend
  community: { access: { tc: false, broker: true } },
  bussiness_operation: { access: { tc: false, broker: true } },
  transaction_management: { access: { tc: true, broker: true } },
  transaction_management_crm: { access: { tc: false, broker: true } },
  transaction_dashboard_tab: { access: { tc: true, broker: true}},
  transaction_management_listing: { access: { tc: true, broker: true } },
  transaction_management_consumer_app: { access: { tc: true, broker: true } },
  transaction_management_offer: { access: { tc: true, broker: true } },
  transaction_management_contract: { access: { tc: true, broker: true } },
  transaction_management_mortgage: { access: { tc: false, broker: true } },
  transaction_management_home_tab: { access: { tc: false, broker: true } },
  transaction_management_stagingtab: { access: { tc: false, broker: true } },
  transaction_management_cashoffertab: { access: { tc: false, broker: true } },
  transaction_management_resourcetab: { access: { tc: false, broker: true } },
  marketting: { access: { tc: false, broker: true } },
  leads: { access: { tc: false, broker: true } },
  help: { access: { tc: false, broker: true } },
};

export const canAccessFeature = ({ type, tabName }) => {
  return TABS_CONFIGURATION[tabName]?.access?.[type];
};

export const showFeatureTab = () => {
  const tcInformation = cookie.load("tcInformation");
  return !tcInformation?.is_tc;
};

// can remove extra param broker later
export const enabledFeatureCheck = ({ tabName, isBroker }) => {
  const tcInformation = cookie.load("tcInformation");
  if (tcInformation?.is_tc) {
    return canAccessFeature({ tabName, type: "tc" });
  }
  return isBroker;
};

export function getSessionData(callback) {
  // Use your own ID here ↓
  const pattern = /_ga_J8ECV3XFM1=GS\d\.\d\.(.+?)(?:;|$)/;
  const match = document.cookie.match(pattern);
  const parts = match?.[1].split(".");

  if (!parts) {
    // Cookie not yet available; wait a bit and try again.
    window.setTimeout(() => getSessionData(callback), 200);
    return;
  }

  callback({
    ga_session_id: parts.shift(),
    ga_session_number: parts.shift(),
  });
}

export async function initiatePendo(userObject) {
  try {
    const { id: userId, email, name: full_name, userType } = userObject || {};

    if (root.pendo) {
      await root.pendo.initialize({
        visitor: {
          id: userId, // Required if user is logged in, default creates anonymous ID
          email, // Recommended if using Pendo Feedback, or NPS Email
          full_name,
          name: full_name,
          role: userType,
          isNetwork: userType === "network",

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: userId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          name: full_name, // Optional
          email,
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional
          role: userType,
          isNetwork: userType === "network",

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }
  } catch (e) {
    console.error("error", e);
  }
}

export const ga4DataLayerPush = async (userData = {}) => {
  try {
    if (Object.keys(userData).length <= 0) return;

    const isPartOfBrokerageTeam =
      (userData && parseInt(userData.brokerageTeamId, 10) > 0) ||
      userData.isBrokerageTeamMember;

    const userType = userData.isSubscribedToBrokerPlan
      ? isPartOfBrokerageTeam
        ? "brokerage_team"
        : "brokerage_agent"
      : "network";

    const classNameForDataLayer =
      (root && root.localStorage.getItem("variant_className")) || "";

    const payload = {
      event: "user_data",
      email: userData.email,
      user_type: userType,
    };

    if (classNameForDataLayer) {
      payload.class_name = classNameForDataLayer;
    }

    await root.dataLayer.push(payload);
  } catch (e) {
    console.log("DATA LAYER PUSH ERROR", e);
  }
};

// export const getOS = () => {
//   var userAgent = window.navigator.userAgent,
//     platform = window.navigator.platform,
//     macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
//     windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
//     iosPlatforms = ['iPhone', 'iPad', 'iPod'],
//     os = null;

//   if (macosPlatforms.indexOf(platform) !== -1) {
//     os = 'Mac OS';
//   } else if (iosPlatforms.indexOf(platform) !== -1) {
//     os = 'iOS';
//   } else if (windowsPlatforms.indexOf(platform) !== -1) {
//     os = 'Windows';
//   } else if (/Android/.test(userAgent)) {
//     os = 'Android';
//   } else if (!os && /Linux/.test(platform)) {
//     os = 'Linux';
//   }

//   return os;
// }

export const UTMExtract = () => {
  try {
    const urlSplit = root.location.href.split("?");
    if (urlSplit[1]) {
      return `${urlSplit[1]}`;
    }
    return null;
  } catch (e) {
    console.log(e, "here");
  }
};
