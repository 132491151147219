import React, { Component } from "react"
import onClickOutside from "react-onclickoutside"
import styled from "styled-components"
import { Manager, Reference, Popper } from "react-popper"
import colors from "@colors"

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  visibility: hidden;
  z-index: 8;
  min-width: 170px;

  &[data-placement*="top"] {
    margin-bottom: 12px;
  }

  &[data-placement*="right"] {
    margin-left: 12px;
  }

  &[data-placement*="bottom"] {
    margin-top: 12px;
  }

  &[data-placement*="left"] {
    margin-right: 12px;
    left: -60px !important;
    top: -5px !important;

    ${props => props.isVideoRoom && `
      left: -10px !important;
      top: -10px !important;
    `}
  }
`

const Arrow = styled.div`
  position: absolute;

  &[data-placement*="bottom"] {
    top: -16px;
    left: 0;
    width: 48px;
    height: 16px;

    &::after {
      top: 0;
    }
  }

  &[data-placement*="top"] {
    bottom: -17px;
    left: 0;
    width: 48px;
    height: 16px;

    &::after {
      bottom: 9px;
    }
  }

  &[data-placement*="right"] {
    left: -16px;
    height: 48px;
    width: 16px;

    &::before {
      top: 16px;
      left: 3px;
    }

    &::after {
      left: 4px;
    }
  }

  &[data-placement*="left"] {
    right: -16px;
    height: 48px;
    width: 16px;

    &::before {
      top: 16px;
      right: 3px;
    }

    &::after {
      right: 4px;
    }
  }

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    position: relative;
  }

  &::after {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    position: relative;
  }
`

const StyledList = styled.ul`
  text-align: left;

  & > li {
    font-size: 16px;
    font-weight: 300;
    padding: 15px 12px;
    border-top: 1px solid #f8f9fa;
    cursor: pointer;
    user-select: none;
    color: ${colors.primaryColor};
    display: flex;
    align-items: center;
  }

  & > li:first-child {
    border-top: none;
  }

  & > li:hover {
    background-color: #f8f9fa;
    position: relative;
  }
`

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  toggleVisibility = (visible) => {
    if (!visible) {
      this.tooltipRef.style.visibility = "visible"
    } else {
      this.tooltipRef.style.visibility = "hidden"
    }
  }

  handleClick = () => {
    const { visible } = this.state

    this.setState(
      {
        visible: !visible,
      },
      () => {
        this.toggleVisibility(visible)
      }
    )
  }

  handleClickOutside = () => {
    const { visible } = this.state

    if (visible) {
      this.setState(
        {
          visible: false,
        },
        () => {
          this.toggleVisibility(visible)
        }
      )
    }
  }

  render() {
    const {
      position, overlay, maxWidth, children, isVideoRoom
    } = this.props
    return (
      <Manager>
        <Reference>
          {({ ref }) => React.cloneElement(overlay, {
            innerRef: ref,
            onClick: this.handleClick,
          })
          }
        </Reference>

        <Popper placement={position} innerRef={node => (this.tooltipRef = node)}>
          {({
            ref, style, placement, arrowProps,
          }) => (
            <Container
              innerRef={ref}
              style={{ ...style, maxWidth: maxWidth || "auto" }}
              data-placement={placement}
              className="ignore-react-onclickoutside"
              isVideoRoom={isVideoRoom}
            >
              <StyledList onClick={this.handleClick}>{children}</StyledList>
              <Arrow innerRef={arrowProps.ref} data-placement={placement} style={arrowProps.style} />
            </Container>
          )}
        </Popper>
      </Manager>
    )
  }
}

DropdownMenu.defaultProps = {
  position: "bottom-left",
}

export default onClickOutside(DropdownMenu)
