import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import {
  TitleText,
  GreyCard,
  CardsWrap,
  FlexAnimContainer,
  ImageWrap,
} from "../commonStyles"
import { SubText } from "../../commonStyles"
import LottieMachine from "../AnimationsJSON/LottieMachine"
import { MobileOne } from "../AnimationsJSON/index"

const Wrap = styled.div`
  width: 100%;
  max-width: 503px;


  ${props => props.sticky && `
    display: none;
    @media(max-width: 1099px) {
      display: block;
    }
  `}
`

const Pixel = styled.div`
    width: 10px;
    height: 10px;
    background: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
`

const TextWrap = styled.div``

const DISPLAY_OBJECT = [{
  text: "In-App Contracts",
}, {
  text: "Performance Tracking",
}, {
  text: "Transaction Management",
}, {
  text: "Community",
}]

const DetailCardOne = ({
  MobileRef,
}) => (
  <FlexAnimContainer
    one
  >
    <div ref={MobileRef}></div>
    <Wrap>
      <TextWrap id="mobileBusiness_1">
        <TitleText>
          <span>Your</span>
          {" "}
          Mobile Business
        </TitleText>
      </TextWrap>
      <SubText
        spanStyles
        mobileWidth="343px"
      >
        Real estate happens in the field, not behind a desk. The
        {" "}
        Radius apps
        {" "}
        <span>move with you</span>
        {" "}
        , helping you to stay connected wherever your clients take you.
      </SubText>
      <SubText
        style={{
          marginTop: "15px",
          marginBottom: "30px",
        }}
      >
        Our mobile ecosystem offers:
      </SubText>
      <CardsWrap>
        {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
          <GreyCard>
            <p>{item.text}</p>
          </GreyCard>
        ))}
      </CardsWrap>
    </Wrap>
    <Wrap
      sticky
    >
      <LottieMachine
        animationData={MobileOne}
        scrollFrames={[0, 200]}
        endFrames={[200, 300]}
      />
    </Wrap>
    <Pixel id="mobileBusiness_2" />
  </FlexAnimContainer>
)

export default DetailCardOne
