import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const HeaderWrap = styled.div`
display: flex;
width: 450.535px;
justify-content: space-between;
align-items: center;`

export const SectionTitle = styled.div`
p {
  
  color: var(--gray-500, #71717A);
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.352px;
  margin: 0;
}

margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  //padding-bottom: 68px;
`

export const CTAWrap = styled.div`
  // padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
export const EnvelopeTitle = styled.div`
  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: 1.12px;
  }
`

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`
