import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import colors from "@colors"
import SummitLogoImg from "images/login-sign-up/summit-event/radius-agent-summit-logo.png"
import Button from "@ui/Button"
import { mobile, xsMobile } from "@consts"

// Agents Img
import Agent1 from "images/login-sign-up/summit-event/agents/cheryl-abrams-davis.png"
import Agent2 from "images/login-sign-up/summit-event/agents/sarita-dua.png"
import Agent3 from "images/login-sign-up/summit-event/agents/christina-pineda.png"
import Agent4 from "images/login-sign-up/summit-event/agents/leena-mendoza.png"
import Agent5 from "images/login-sign-up/summit-event/agents/dr-lee-davenport.png"
import Agent6 from "images/login-sign-up/summit-event/agents/5.png"
import Agent7 from "images/login-sign-up/summit-event/agents/marcus.png"

import Agent8 from "images/login-sign-up/summit-event/agents/mark-choey.png"
import Agent9 from "images/login-sign-up/summit-event/agents/patricia-villegas.png"
import Agent10 from "images/login-sign-up/summit-event/agents/rebecca-hidalgo-rains.png"
import Agent11 from "images/login-sign-up/summit-event/agents/shawneequa-badger.png"
import Agent12 from "images/login-sign-up/summit-event/agents/steve-peterson.png"
import Agent13 from "images/login-sign-up/summit-event/agents/roger-lopez.png"
import Agent14 from "images/login-sign-up/summit-event/agents/victor-reyes.png"

import SquareImg from "images/login-sign-up/summit-event/square.svg"
import NaherpImg from "images/login-sign-up/summit-event/naherp-logo.png"

const Wrap = styled.div`
  background: #FBF8EF;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  ${xsMobile(`
    padding: 20px;
    padding-top: 70px;
  `)}

  > img {
    ${xsMobile(`
      max-width: 140px;
    `)}
  }
`

const ContentWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  h3 {
    color: ${colors.primaryColor};
    font-weight: 700;
    font-size: 12px;
  }

  h2 {
    font-size: 20px;
    color: ${colors.black};
    line-height: 26px;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    margin: 20px 0px;
  }

  h6 {
    font-size: 7px;
  }
  img {
    margin-bottom: 20px;
  }
`

const AgentImgWrap = styled.div`
  border-top: 2px solid #D3DAE8;
  padding-top: 25px;
  width: 100%;
  ${xsMobile(`
    display: none;
  `)}
  ${mobile(`
    border-top: none;
    padding-botton: 20px;
  `)}
  > div {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;

    &:nth-child(2) {
      ${mobile(`
        display: none;
      `)}
    }
  }
`

const RegistrationInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  margin: 0px auto;
`

const Footer = styled.div`
  border-top: 2px solid #D3DAE8;
  width: 100%;
  display: flex;
  ${xsMobile(`
    margin-top: 20px;
  `)}
  p {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    span {
      display: none;
      ${mobile(`
        display: block;
      `)}
    }
  }
  img {
    width: 44px;
    height: 44px;
    ${xsMobile(`
      display: none;
    `)}
  }
`

const LoginSidebar = () => (
  <Wrap>
    <img src={SummitLogoImg} alt="Radius Agent Summit"/>
    <ContentWrap>
      <RegistrationInfo>
        <h3>FEBRUARY 25, 2020 &nbsp;&nbsp; 9AM PST</h3>
        <h2>
          1 Day &nbsp;|&nbsp; 7 sessions &nbsp;|&nbsp; 13+ real estate experts
        </h2>
        <p>
          Join real estate leaders, influencers, coaches, and entrepreneurs at the
          free Radius Agent Summit. Unlock the power of a single day to grow and
          rethink your real estate business.
        </p>
        <Button onClick={() => { root.open("https://summit.radiusagent.com/?utm_source=p_login") }}>
          Register For Free
        </Button>
        <h6>FEATURING</h6>
        <img src={NaherpImg} alt={NaherpImg} />
      </RegistrationInfo>
    </ContentWrap>
    <AgentImgWrap>
      <div>
        <img src={Agent1} alt="cheryl-abrams-davis" />
        <img src={Agent2} alt="sarita-dua" />
        <img src={Agent3} alt="christina-pineda" />
        <img src={Agent4} alt="leena-mendoza" />
        <img src={Agent5} alt="dr-lee-davenport.png" />
        <img src={Agent6} alt="5" />
        <img src={Agent7} alt="marcus" />
      </div>
      <div>
        <img src={Agent8} alt="mark-choey" />
        <img src={Agent9} alt="patricia-villegas" />
        <img src={Agent10} alt="rebecca-hidalgo-rains" />
        <img src={Agent11} alt="shawneequa-badger" />
        <img src={Agent12} alt="steve-peterson" />
        <img src={Agent13} alt="roger-lopez" />
        <img src={Agent14} alt="victor-reyes" />
      </div>
    </AgentImgWrap>
    <Footer>
      <p>
        <span>
          Or Continue to Login Below
        </span>
      </p>
      <img src={SquareImg} alt="Footer icon" />
      <img src={SquareImg} alt="Footer icon" />
      <img src={SquareImg} alt="Footer icon" />
      <img src={SquareImg} alt="Footer icon" />
    </Footer>
  </Wrap>
)

export default LoginSidebar
