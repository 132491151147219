import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.div`
  position: relative;
  width: 35px;
  height: 14px;
  border-radius: 10px;
  cursor: pointer;
  transition-duration: 0.3s;
  background: #dedede;

  ${props => props.isSelected && `
    background: ${colors.fadedPrimaryColor};
  `}
`

const Selector = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colors.primaryColor};
  transition-duration: 0.3s;
  background: ${colors.newTagText};

  ${props => props.isSelected && `
    right: 0px;
    background: ${colors.primaryColor};
  `}
`

const SwitchToggle = (props) => {
  const {
    isSelected,
    onClick,
  } = props
  return (
    <Wrap onClick={onClick} isSelected={isSelected}>
      <Selector isSelected={isSelected} />
    </Wrap>
  )
}

export default SwitchToggle
