import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import container from "container/NonAssist"
import ClientContainer from "container/Client"
import SubscriptionContainer from "container/Settings/SubscriptionsContainer"
import ClientSchedulingContainer from "container/Settings/ClientScheduling"
import PageTitle from "@ui/PageTitle"
import LinkButton from "@ui/LinkButton"
import Box from "@ui/Box"
import CRMRevampBanner from "./CRMRevampBanner"
import TogglePlan from "dumbComponents/NonAssist/TogglePlan"
import { REFER_TEXT } from "shared/consts"
import AddIcon from "images/icons/add-icon.svg"
import Shimmer from "dumbComponents/Referral/common/InboundShimmer"
import UserConfirmationModal from "dumbComponents/common/UserConfirmationModal"
import ButtonsWrap from "dumbComponents/Referral/common/ButtonWrap"
import { QualifiedCRMIcon, SubmittedCRMIcon } from "dumbComponents/common/UI/Sidebar/SidebarIcons"
import ArchiveModal from "./Helper/NonAssistArchivedLeads"
import DragAndDrop from "./DragAndDropArea"
import AddNotes from "./AddNotes"
import AddISALead from "../Referral/Prospects/AddISALead"

const Wrap = styled.div`
  
`

const IconBlock = styled.div`
  margin-top: -5px;
  padding: 0 6px;
  margin-left: -20px;
`

class CRM extends React.PureComponent {
  state = {}

  componentDidMount() {
    const {
      getNonAssistLeads, fetchCurrentAppointmentSchedulingStatus, history,

    } = this.props
    if (history && history.location.pathname === "/assist/crm") {
      this.setState({ showCRM: true })
    }
    getNonAssistLeads()
    fetchCurrentAppointmentSchedulingStatus()
  }

  handleRefer = (isMarkAsQualified, client) => {
    const { toggleReferOut, history } = this.props
    const { push } = history
    const {
      referralId,
      referral_receiver_id,
      id,
      referralType,
    } = client
    toggleReferOut({
      id: referralId,
      clientId: id,
      referralType,
      referralRecieverID: referral_receiver_id,
      push,
      clientObjFull: referralType === "crm" ? client : {},
      isMarkAsQualified: referralType === "crm" && isMarkAsQualified,
      isFromClientPage: true,
    })
  }

  isAssistCRMLeadEnabled = (appointmentSettingStatus, categorizedSubscriptions, leadsCapThreshold) => {
    if (!categorizedSubscriptions || !appointmentSettingStatus) return true
    let result = false
    let subscribed = false
    // check if agent has RTL subscription then check for lead quota
    if (categorizedSubscriptions.single_rtl && appointmentSettingStatus) {
      subscribed = true
      result = result || !(appointmentSettingStatus.rtl_leads_monthly_quota === 0 && appointmentSettingStatus.rtl_card_inactive)
    }
    // check if agent has BLU subscription then check for lead quota
    if (categorizedSubscriptions.single_blu && appointmentSettingStatus) {
      subscribed = true
      result = result || !(appointmentSettingStatus.excel_remaining_quota === 0 && !appointmentSettingStatus.card_active)
    }
    // check if agent has TEAM RTL subscription then check for lead quota
    if (categorizedSubscriptions.team_rtl && appointmentSettingStatus) {
      subscribed = true
      result = result || !(appointmentSettingStatus.team_rtl_leads_monthly_quota === 0 && appointmentSettingStatus.rtl_card_inactive)
    }
    // check if agent has TEAM BLU subscription then check for lead quota
    if (categorizedSubscriptions.team_blu && appointmentSettingStatus) {
      subscribed = true
      result = result || !(appointmentSettingStatus.team_excel_remaining_quota === 0 && !appointmentSettingStatus.card_active)
    }
    // check if agent has BROKER subscription then check for lead quota
    if (!subscribed && categorizedSubscriptions.single_brk && leadsCapThreshold) {
      result = !(
        leadsCapThreshold.rar_rtl_threshold === 0
        && [2, 3].includes(appointmentSettingStatus.auto_rtl_subscription_status)
        && [2, 3].includes(appointmentSettingStatus.auto_blu_subscription_status))
    }
    return result
  }

  handleFormSubmit = (client, dispatch, form) => {
    const { addISAClient } = this.props
    const newClient = { ...client }
    newClient.agent_requirements = JSON.stringify({ clientAdditionalDetails: newClient.notes })
    newClient.sales = {}
    newClient.sales["Current stage"] = "Leads"
    addISAClient({ client: newClient, skipReferLead: true })
  }

  render() {
    const { showCRM } = this.state
    const {
      dndColumns,
      getArchivedLeadNonAssist,
      // API responses
      nonAssistLeadsResponse,
      archiveModalResponse,
      saveNotesResponse,
      changeSalesState,
      openAddReminderModal,
      setReminder,
      closeAddReminderModal,
      isSettingReminder,
      isShowAddReminderModal,
      isShowAddNote,
      addNote,
      toggleAddNotesModal,
      toggleArchiveModal,
      push,
      isShowArchiveModal,
      updateArchivedLead,
      isReferOutModalOpen,
      referOutConfirm,
      confirmationModalMessage,
      referOutResponse,
      history,
      currentAppointmentSchedulingStatus,
      categorizedSubscriptions,
      leadsCapThreshold,
      openISAAddClientModal,
      isISAAddClientModalOpened,
      isAddingISAClient,
      savePreference,
      fetchCurrentAppointmentSchedulingStatus,
    } = this.props
    const { isFetching: isFetchingLeads, data } = nonAssistLeadsResponse
    const leadsCount = data && data.clients && data.clients.length
    const { isFetching: isSavingNotes } = saveNotesResponse
    const {
      data: appointmentSettingStatus,
      isFetching: fetchingAppointmentSettingStatus,
    } = currentAppointmentSchedulingStatus
    const submitType = !fetchingAppointmentSettingStatus && appointmentSettingStatus && appointmentSettingStatus.submit_type
    const {
      isFetching: isReferringOut,
      isError: isRefferError,
      error: referOutError,
    } = referOutResponse
    const errorMessage = get(referOutError, "data.error.message", "Error occurred")
    const isDisabled = this.isAssistCRMLeadEnabled(appointmentSettingStatus, categorizedSubscriptions, leadsCapThreshold)
    return (
      <Wrap>
        <CRMRevampBanner />
        {showCRM ? (
          <div>
            <PageTitle
              style={{
                marginTop: "50px",
              }}
            >
              CRM
            </PageTitle>
            <ButtonsWrap>
              <button type="button" onClick={() => { history.push("/assist/pipeline") }} disabled={!isDisabled}>
                {/* <img src={IntegrateIcon} alt="Integrate lead sources" /> */}
                <IconBlock>
                  <QualifiedCRMIcon isActive />
                </IconBlock>
                Qualified
              </button>
              <button type="button" onClick={() => { history.push("/assist/submitted") }} disabled={!isDisabled}>
                {/* <img src={AddIcon} alt="Integrate lead sources" /> */}
                <IconBlock>
                  <SubmittedCRMIcon isActive />
                </IconBlock>
                Assist Leads
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  openISAAddClientModal()
                }}
              >
                {/* <img src={AddIcon} alt="Integrate lead sources" /> */}
                <img src={AddIcon} alt="Integrate lead sources" />
                Add New Lead
              </button>
              {/* {!fetchingAppointmentSettingStatus && (
                <TogglePlan
                  isDisabled={isDisabled}
                  appointmentSettingStatus={appointmentSettingStatus}
                  savePreference={savePreference}
                  submitType={submitType}
                />
              )} */}
            </ButtonsWrap>
          </div>
        ) : (
          <PageTitle>
            Non-Assist
          </PageTitle>
        )}
        {isFetchingLeads ? (
          <Shimmer />
        ) : (
          <DragAndDrop
            dndColumns={dndColumns}
            changeSalesState={changeSalesState}
            nonAssistLeadsResponse={nonAssistLeadsResponse}
            leadsCount={leadsCount}
            hideMarkAsQualified
            cardProps={{
              openAddReminderModal,
              setReminder,
              closeAddReminderModal,
              isSettingReminder,
              isShowAddReminderModal,
              toggleAddNotesModal,
              handleRefer: this.handleRefer,
              push,
            }}
          />
        )}

        {isShowAddNote && (
          <AddNotes
            clientId={isShowAddNote}
            addNote={addNote}
            toggleAddNotesModal={toggleAddNotesModal}
            isSavingNotes={isSavingNotes}
          />
        )}
        <Box>
          <LinkButton onClick={() => { toggleArchiveModal(true) }}>
            Archived Leads
          </LinkButton>
        </Box>
        {isShowArchiveModal && (
          <ArchiveModal
            toggleArchiveModal={toggleArchiveModal}
            archiveModalResponse={archiveModalResponse}
            getArchivedLeadNonAssist={getArchivedLeadNonAssist}
            updateArchivedLead={updateArchivedLead}
          />
        )}

        {isReferOutModalOpen && (
          <UserConfirmationModal
            userConfirm={referOutConfirm}
            question={confirmationModalMessage}
            toClose={() => { }}
            hideButton="true"
            yesButtonText={isRefferError ? "Ok" : "Yes, refer out"}
            subHeading={isRefferError ? errorMessage : REFER_TEXT}
            custom="true"
            customYes={isRefferError ? "attention" : "primary"}
            isError={isRefferError}
          />
        )}
        {isISAAddClientModalOpened && (
          <AddISALead
            onSubmit={this.handleFormSubmit}
            show={isISAAddClientModalOpened}
            isAddingISAClient={isAddingISAClient}
          />
        )}
      </Wrap>
    )
  }
}

export default ClientContainer(SubscriptionContainer(ClientSchedulingContainer(container(CRM))))
