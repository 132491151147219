import styled from "styled-components"
import { Input } from "@ui/antd"

export const Wrap = styled.div``

export const StyledInput = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 42px !important;
`
