import React from "react"
import { Form } from "@ui/antd"
import {
  StyledFormItem,
  InputFieldWrap,
} from "./styles"

const HiddenInputField = ({
  name,
  value,
  defaultValue,
}) => (
  <>
    {value ? (
      <StyledFormItem
        hidden
        name={name}
      >
        <InputFieldWrap
          // placeholder={placeholder}
          // style={style}
          // type={inputType}
          value={value}
          defaultValue={defaultValue}
          disabled
        />
      </StyledFormItem>
    ) : (
      <StyledFormItem
        hidden
        name={name}
      />
    )}
  </>
)
  

export default HiddenInputField
