import React, { useState, useEffect } from "react"
import Listings from "container/Listings"
import { useWindowSize } from "services/Utils"
import {
  StatsCard,
  Wrap,
  StatImageCircle,
  StatsInnerWrap,
  TitleAndPercentage,
  StatTitle,
  AccumalatedValue,
  Percentage,
} from "../commonStyles"
import MetricStatsShimmer from "./StatsShimmer"

const ListingStats = ({
  getListingMetricsResponse,
}) => {
  const { width: screenWidth } = useWindowSize()
  const [metricState, setMetricState] = useState({
    potential_earnings: {
      colorHex: "#87E8AB",
      iconOrientation: "1",
      text: "N/A",
      value: 0,
    },
    total_listing_value: {
      colorHex: "#87E8AB",
      iconOrientation: "1",
      text: "N/A",
      value: 0,
    },
  })

  const {
    data: metrics,
    isFetching: fetchingMetrics,
  } = getListingMetricsResponse || {}

  useEffect(() => {
    if ((!fetchingMetrics || fetchingMetrics === undefined) && metrics) {
      if (metrics && Object.keys(metrics).length > 0) {
        setMetricState(state => ({
          ...state,
          ...metrics,
        }))
      } else if (metrics && Object.keys(metrics).length <= 0) {
        //reset
        setMetricState({
          potential_earnings: {
            colorHex: "#87E8AB",
            iconOrientation: "1",
            text: "",
            value: 0,
          },
          total_listing_value: {
            colorHex: "#87E8AB",
            iconOrientation: "1",
            text: "",
            value: 0,
          },
        })
      }
    }
  }, [fetchingMetrics, metrics])

  return (
    <Wrap
      style={{
        display: "flex",
        gap: "40px",
        padding: "60px 24px 19px 24px",
        flexWrap: "wrap",
      }}
    >
      {fetchingMetrics ? (
        <>
          <MetricStatsShimmer />
          <MetricStatsShimmer />
        </>
      ) : (
        <>
          <StatsCard>
            <StatImageCircle>
              <span>
                <img src={`${CLOUDFRONT}/LISTING_V2/Housing.svg`} alt="house.svg" />
              </span>
            </StatImageCircle>
            <StatsInnerWrap>
              <TitleAndPercentage>
                <StatTitle>
                  <h1>
                    Total Listing Value
                  </h1>
                </StatTitle>
                {metricState.total_listing_value.text && metricState.total_listing_value.value && (
                  <Percentage
                    textColor={metricState.total_listing_value.colorHex}
                  >
                    <span>
                      {metricState.total_listing_value.iconOrientation === "1" ? (
                        <img src={`${CLOUDFRONT}/LISTING_V2/UpGreenChevron.svg`} alt="chevron.svg" />
                      ) : (
                        <img src={`${CLOUDFRONT}/LISTING_V2/DownRedChevron.svg`} alt="chevron.svg" />
                      )}
                    </span>
                    <p>
                      {metricState.total_listing_value.text}
                    </p>
                  </Percentage>
                )}
              </TitleAndPercentage>
              <AccumalatedValue>
                {metricState.total_listing_value.value ? metricState.total_listing_value.value : "$0"}
              </AccumalatedValue>
            </StatsInnerWrap>
          </StatsCard>
          <StatsCard>
            <StatImageCircle>
              <span>
                <img src={`${CLOUDFRONT}/LISTING_V2/Dollar.svg`} alt="dollar.svg" />
              </span>
            </StatImageCircle>
            <StatsInnerWrap>
              <TitleAndPercentage>
                <StatTitle>
                  <h1>
                    Potential Earnings
                  </h1>
                </StatTitle>
                {metricState.potential_earnings.text && metricState.potential_earnings.value && (
                  <Percentage
                    textColor={metricState.potential_earnings.colorHex}
                  >
                    {metricState.potential_earnings.iconOrientation === "1" ? (
                      <img src={`${CLOUDFRONT}/LISTING_V2/UpGreenChevron.svg`} alt="chevron.svg" />
                    ) : (
                      <img src={`${CLOUDFRONT}/LISTING_V2/DownRedChevron.svg`} alt="chevron.svg" />
                    )}
                    <p>
                      {metricState.potential_earnings.text}
                    </p>
                  </Percentage>
                )}
              </TitleAndPercentage>
              <AccumalatedValue>
                {metricState.potential_earnings.value ? metricState.potential_earnings.value : "$0"}
              </AccumalatedValue>
            </StatsInnerWrap>
          </StatsCard>
        </>
      )}
    </Wrap>
  )
}

export default Listings(ListingStats)
