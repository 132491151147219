import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { hideNewFeedsAvailableAction } from "container/App/actions"
import {
  getNetworkFeedsFromStore,
  getIsFetchingFeedsFromStore,
  getIsPaginatedFetchFromStore,
  getFeedDetailFromStore,
  getUnansweredQuestionsFromStore,
  getIsLikingFeedFromStore,
  getIsLikingCommentFromStore,
  getSelectedGroupFromStore,
  getIsPostingCommentFromStore,
  getLikersList,
  getAgentsFolloweesFromStore,
  getRequestingAgentToFollowFromStore,
  getLikedAgentsToDisplay,
  getImagePreviewModalUrlFromStore,
  getSuggestedTopicsFromStore,
} from "./selector"
import {
  changeFeedSelection,
  openFeedDetailView,
  closeFeedDetailView,
  updateCreateEditPostModal,
  setTopicAndGetTags,
  createEditTextChange,
  getUnansweredQuestions,
  uploadPostImage,
  getCommentsForFeed,
  getLikers,
  openLikers,
  closeLikers,
  updateFeedLike,
  updateCommentLike,
  postCommentForFeed,
  setCommunityGroup,
  triggerCreateEditModal,
  getAllFeeds,
  followAgent,
  unfollowAgent,
  togglePersonalizeFeedModal,
  updateAutoFocus,
  openImagePreviewModal,
  closeImagePreviewModal,
  getSuggestedTopics,
  followSuggestedTopic,
  resetFeedCache,
  reportOrDelete,
  feedsViewed,
  blockAndUnBlock,
  toggleReportModal,
  getBlockedReasons,
  reportFeed,
  mutePost,
} from "./actionCreators"

const mapStateToProps = (state) => {
  const networkFeeds = getNetworkFeedsFromStore(state)
  const {
    isNewFeeds,
    isNewQuestions,
    questionLength,
    user,
  } = state.app
  const {
    profile: {
      isUnauthorized,
    }
  } = state
  const {
    isFetchingFeedsFirstTime,
    isShowReportModal,
    reportReasonsResponse,
    mutedFeeds,
  } = state.network
  const {
    feed,
    feedIds,
    groups,
    selectedOptions: { type },
    topFeedIds,
  } = networkFeeds
  return {
    user,
    feed,
    feedIds,
    groups,
    isNewFeeds,
    isNewQuestions,
    questionLength,
    type,
    topFeedIds,
    isFetchingFeedsFirstTime,
    isUnauthorized,
    isShowReportModal,
    reportReasonsResponse,
    mutedFeeds,
    isFetchingFeeds: getIsFetchingFeedsFromStore(state),
    isPaginatedFetch: getIsPaginatedFetchFromStore(state),
    feedDetail: getFeedDetailFromStore(state),
    unansweredQuestions: getUnansweredQuestionsFromStore(state),
    isLikingFeed: getIsLikingFeedFromStore(state),
    isLikingComment: getIsLikingCommentFromStore(state),
    selectedGroup: getSelectedGroupFromStore(state),
    isPostingComment: getIsPostingCommentFromStore(state),
    agentsFollowees: getAgentsFolloweesFromStore(state),
    requestingAgentToFollow: getRequestingAgentToFollowFromStore(state),
    likers: getLikersList(state),
    likeAgents: getLikedAgentsToDisplay(state),
    imagePreviewModalUrl: getImagePreviewModalUrlFromStore(state),
    suggestedTopics: getSuggestedTopicsFromStore(state),
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeFeedSelection,
    openFeedDetailView,
    closeFeedDetailView,
    updateCreateEditPostModal,
    setTopicAndGetTags,
    createEditTextChange,
    getUnansweredQuestions,
    uploadPostImage,
    getCommentsForFeed,
    getLikers,
    openLikers,
    closeLikers,
    updateFeedLike,
    updateCommentLike,
    postCommentForFeed,
    setCommunityGroup,
    triggerCreateEditModal,
    getAllFeeds,
    followAgent,
    unfollowAgent,
    togglePersonalizeFeedModal,
    updateAutoFocus,
    openImagePreviewModal,
    closeImagePreviewModal,
    getSuggestedTopics,
    followSuggestedTopic,
    resetFeedCache,
    reportOrDelete,
    feedsViewed,
    blockAndUnBlock,
    toggleReportModal,
    getBlockedReasons,
    reportFeed,
    hideNewFeedsAvailable: hideNewFeedsAvailableAction,
    mutePost,
  },
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
