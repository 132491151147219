import React from "react"
import styled, { } from "styled-components"
import { reduxForm } from "redux-form"
import Box from "@ui/Box"
import {
  Wrapper,
  Footer,
  Header,
  SaveButton,
  ErrorText,
  ProfileCompletionButton,
  BlankButton,
} from "./commonStyles"

const TextArea = styled.textarea`
  border: 1px solid #EEE;
  display: block;
  width: 100%;
  height: 260px;
`

const H6 = styled.h6`
  font-size: 12px;
  ${props => props.limitReached ? "color: red;" : "color: rgba(0,0,0, 0.4);"}
`

class EditAbout extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues } = this.props
    this.state = {
      about: initialValues || "",
    }
  }

  handleOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  removeError = () => {
    this.setState({
      error: null,
    })
  }

  handleSave = () => {
    const { saveProfileUpdate, agentId, isProfileCompletion } = this.props
    const { about } = this.state
    if (about && about.length > 1500) {
      this.setState({
        error: "You have exceeded the maximum character limit.",
      })
    } else {
      saveProfileUpdate({
        id: agentId,
        about_agent: about,
        isProfileCompletion,
      })
    }
  }

  render() {
    const {
      isSavingUpdates,
      nextToNextStep,
      skipProfileCompletionStep,
      isProfileCompletion,
      nextStep,
    } = this.props
    const { about, error } = this.state
    const charLeft = 1500 - about.length

    return (
      <Wrapper>
        <Header>
          <h4>Tell us about yourself</h4>
        </Header>
        <Box d="flex" p="15px" fd="column">
          <TextArea
            name="about"
            placeholder="Add note"
            value={about}
            onChange={this.handleOnChange}
            onFocus={this.removeError}
          />
          <H6 limitReached={charLeft < 0}>{`${charLeft} character count`}</H6>
        </Box>
        <Footer
          style={{
            justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
            alignItems: "center",
          }}
        >
          { isProfileCompletion && nextToNextStep && (
            <BlankButton
              onClick={() => { skipProfileCompletionStep(nextStep) }}
              normal
            >
              Skip
            </BlankButton>
          )}
          <ErrorText>{error}</ErrorText>
          { isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              width="100px"
              isShowLoader={isSavingUpdates}
              onClick={this.handleSave}
            >
              Save
            </SaveButton>
          )}
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "EDIT_ABOUT_FORM",
})(EditAbout)

