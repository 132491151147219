import Modal from "@ui/Modal"
import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import Rooms from "container/Rooms"
import Button from "@ui/Button"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  UserInput,
  TagCardDiv,
  RoomsButton,
  CTAWrap,
} from "../CreateRoomV2/common"
import { ATC } from "@fonts"

const Wrap = styled.div`
  height: 387px;
  width: 955px;
  border-radius: 9px;
  padding: 60px;

  display: flex;
  flex-direction: column;
  position: relative;
`
const AgentSelectWrap = styled.div`

  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  min-height: 60px;
  padding: 15px;

  > img {
    height: 20px;
    width: 20px;
    margin: 0px 0px 0px 10px;
    border-radius: 10px;
  }

  > div {
    display: flex;
    padding: 10px;
  }
`

const Title = styled.div`
  p {
    font-family: ${ATC};
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`

const SuggestedUsers = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 18px 0px;
    gap: 12px;
    position: relative;

    &:first-child {
      margin-top: 18px;
    }

    > img {
      height: 25px;
      width: 25px;
      border-radius: 30px;
    }

    > small {
      cursor: pointer;
    }
  }

`

const SuggestedAgentsDropdown = styled.ul`
  position: relative;
  top: 10px;

  > div {
    position: absolute;
    background: ${colors.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    overflow: scroll;
    border-radius: 10px;
    max-height: 700px;
  }
`

const SpeakerWrap = styled.div``

const InputWrap = styled.div``

const InviteAgentModal = ({
  toClose,
  searchText,
  handleSearchInput,
  showDropDown,
  agents,
  handleSpeakerState,
  speakers,
  handleRemoveSpeakers,
}) => (
  <Modal
    show
    toClose={toClose}
    modalVersion={3}
    modalStyles={{
      backgroundColor: colors.dreamWhite,
      padding: "20px",
      height: "max-content",
      minHeight: "230px",
    }}
    dialogStyles={{
      width: "max-content",
      borderRadius: "6px",
    }}
    overlayStyles={{
      zIndex: 9991,
    }}
  >
    <Wrap>
      <Title>
        <p>Invite Speakers</p>
      </Title>
      <InputWrap>
        <UserInput
          placeholder="What's the agent's name?"
          type="text"
          name="agentName"
          value={searchText}
          onChange={(e) => { handleSearchInput(e) }}
        />
      </InputWrap>
      {(agents && showDropDown) && (
        <SuggestedAgentsDropdown>
          <TagCardDiv>
            {agents.length > 0 && agents.map(agent => (
              <AgentSelectWrap>
                <CircularImage
                  name={`${agent.firstname} ${agent.lastname}`}
                  size={35}
                  image={agent.agent_image || ""}
                />
                <div>
                  {`${agent.firstname} ${agent.lastname}`}
                </div>
                <Button
                  type="button"
                  bsStyle="marineBLueOutlined"
                  borderRadius="0px"
                  width="65px"
                  style={{
                    fontSize: "14px",
                    position: "absolute",
                    right: "25px",
                  }}
                  onClick={() => { handleSpeakerState(agent) }}
                >
                  Invite
                </Button>
              </AgentSelectWrap>
            ))}
          </TagCardDiv>
        </SuggestedAgentsDropdown>
      )}
      <SpeakerWrap>
        {(speakers && speakers.length > 0) && (
          <SuggestedUsers>
            {
              speakers.map(item => (
                <div>
                  <CircularImage
                    name={`${item.firstname} ${item.lastname}`}
                    size={35}
                    image={item.image_url || ""}
                  />
                  <div>
                    {`${item.firstname} ${item.lastname}`}
                  </div>
                  <small
                    onClick={() => handleRemoveSpeakers(item)}
                    onKeyDown={() => handleRemoveSpeakers(item)}
                    style={{
                      position: "absolute",
                      right: "30px",
                    }}
                  >
                    x
                  </small>
                </div>
              ))}
          </SuggestedUsers>
        )}
      </SpeakerWrap>
      <CTAWrap
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "max-content",
        }}
      >
        <RoomsButton
          type="button"
          onClick={toClose}
        >
          <p>Confirm</p>
        </RoomsButton>
      </CTAWrap>
    </Wrap>
  </Modal>
)

export default Rooms(withRouter(InviteAgentModal))
