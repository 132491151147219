import * as React from "react";
const BrbcTickIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    viewBox="0 0 18 14"
    fill="none"
    {...props}
  >
    <path
      d="M17 1.5L6 12.5L1 7.5"
      stroke="#5A5FF2"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BrbcTickIcon;
