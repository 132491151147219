import * as ContactUsAction from "./action"

const initState = {
  isSendingContact: false,
}

export default function contactUsReducer(state = initState, action) {
  switch (action.type) {
    case ContactUsAction.submitContactFormAction.REQUEST: {
      return {
        ...state,
        isSendingContact: true,
      }
    }

    case ContactUsAction.submitContactFormAction.FAILURE:
    case ContactUsAction.submitContactFormAction.SUCCESS: {
      return {
        ...state,
        isSendingContact: false,
        response: action.data,
      }
    }
    case ContactUsAction.RESET_CONTACT_FORM: {
      return {
        ...state,
        response: null,
      }
    }
    default:
      return state
  }
}
