export const OFFER = "offer"
export const OFFER_CONTRACT = "offer_contract"
export const OFFER_LEASE = "offer_lease"
export const ADD_BUYER = "add_buyer"
export const LISTING = "listing"
export const LISTING_CONTRACT = "listing_contract"
export const LISTING_LEASE = "listing_lease"
export const CONTRACT = "contract"

export const DATE_FILTERS = [
  {
    label: "Last 7 days",
    value: "7_days",
  },
  {
    label: "Last 30 days",
    value: "30_days",
  },
  {
    label: "Last 90 days",
    value: "90_days",
  },
  {
    label: "Last year",
    value: "365_days",
  },
  // {
  //   label: "Custom",
  //   value: "custom_range",
  //   isCustom: true,
  // },
]
