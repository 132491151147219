import React, { useEffect, useState } from "react"
import {
  PROPERTY_TYPE,
  getState,
} from "dumbComponents/OffersV2/consts"
import root from "window-or-global"
import TechAssets from "container/TechAssets"
import { Form, Popover } from "antd"
import { ShimmerBox } from "shared/styles/animation"
import axios from "axios"
import OffersV2 from "container/OffersV2"
import DebouncedSelect from "dumbComponents/OffersV3/components/MLSModal/components/MLSTabs/Components/debouncedSelect"
import BuyerInformation from "dumbComponents/OffersV3/CoverSheet/Components/BuyerInformation/index"
import PropertyInformation from "dumbComponents/OffersV3/CoverSheet/Components/PropertyInformation"
import DealTerms from "dumbComponents/OffersV3/CoverSheet/Components/DealTerms"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import { DropdownIcon, DropdownUpIcon } from "dumbComponents/OffersV3/icons"
import { SideNav } from "../components"
import {
  CoverSheetTitle,
  MenuUiWrap,
  CoverSheetTabUI,
} from "./styles"

// const SIDENAV_OPTIONS = [
//   {
//     value: "property_information",
//     label: "Property Information",
//     component: <PropertyInformation />,
//   },
//   {
//     value: "buyer_information",
//     label: "Buyer Information",
//     component: <BuyerInformation />,
//   },
//   {
//     value: "deal_terms",
//     label: "Deal Terms",
//     component: <DealTerms />,
//   },
// ]

const customSelectStyles = {
  // width: "497px",
  height: "48px",
  overflow: "hidden",
}

const CoverSheet = ({
  setMLSData,
  mlsOrPropertyData,
  propertyInformationObject,
  fetchPropertyInformationResponse,
  updatePropertyInformationTab,
  dealToView,
  getCurrentDealDetailsResponse,
  fetchValidateCoverSheet,
  updatePropertyInformationObject,
}) => {
  // useEffect(() => {
  //   const { pathname } = root.location
  //   const splitUrl = pathname.split("/")
  //   const id = splitUrl && splitUrl[splitUrl.length - 1]
  //   const currentId = parseInt(id, 10)
  //   console.log(currentId, "here")
  //   let ID
  //   if (typeof currentId === "number") {
  //     ID = dealToView || currentId 
  //   }
  //   fetchValidateCoverSheet({
  //     id: ID,
  //   })
  // }, [])
  const [showIcon, setIcon] = useState(false)
  const [searchValue, setValue] = useState([])
  const [PROPERTY_INFORMATION_FORM_REF] = Form.useForm()
  const [LISTING_AGENT_INFORMATION_FORM_REF] = Form.useForm()
  const [SELLER_INFORMATION_FORM_REF] = Form.useForm()
  const [BUYER_INFORMATION_FORM_REF] = Form.useForm()
  const [BUYER_AGENT_INFORMATION_FORM_REF] = Form.useForm()
  const [FINANCING_INFORMATION_FORM_REF] = Form.useForm()
  const [VENDOR_INFORMATION_FORM_REF] = Form.useForm()
  const [DEAL_TERMS_FORM_REF] = Form.useForm()

  const { type_of_transaction } = propertyInformationObject || {}
  const { isFetching } = fetchPropertyInformationResponse || {}
  const { isFetching: fetchingInfo } = getCurrentDealDetailsResponse || {}

  const handleFilterQuery = (key, value) => {
    console.log(key, value, "here")
  }

  const fetchUserList = async (search) => {
    if (!search) return []
  
    const url = `${API_URL}get-mls-data?mlsId=${search}`

    const mapped = await axios.get(url)
      .then((res) => {
        const {
          response,
        } = res.data || {}
        return response && response.data || []
      })

    const options = mapped && mapped.map((item) => {
      return {
        ...item,
        value: item.Id,
        label: item.UnparsedAddress,
        address: item.UnparsedAddress,
        id: item.Id,
        image: item.Media[0]?.MediaURL || "",
        typedMLSId: search,
      }
    })

    console.log(options)

    return options
  }

  const handleChange = (newValue) => {
    let propertyPayload = {}
    setValue(newValue)
    console.log(newValue, "here")
    setMLSData(newValue)
    propertyPayload = {
      draft: 1,
      current_offer_step: "property_information_mobile",
    }
    const {
      id,
      Id,
      typedMLSId,
      address,
      City,
      StateOrProvince,
      Zipcode,
      ListAgentFullName,
      ListAgentMlsId,
      ListAgentEmail,
      ListAgentPreferredPhone,
      ListOfficeName,
      ListOfficeMlsId,
      mls_number,
      PostalCode,
      CountyOrParish,
      StreetNumber,
      StreetName,
    } = newValue || {}
    if (typedMLSId || mls_number) {
      propertyPayload.mls_number = typedMLSId || mls_number || id || Id
    }

    if (address || StreetNumber) {
      propertyPayload.street = address || `${StreetNumber}, ${StreetName}`
    }

    if (City) {
      propertyPayload.city = City
    }

    if (StateOrProvince) {
      propertyPayload.state = getState(StateOrProvince)
    }

    if (PostalCode || Zipcode) {
      propertyPayload.zipcode = PostalCode || Zipcode
    }

    if (CountyOrParish) {
      propertyPayload.county = CountyOrParish
    }

    console.log(ListAgentFullName, "here123")

    if (ListAgentFullName) {
      const listingAgent = {
        is_primary: 1,
        agent_id: null,
        is_radius_user: null,
        name: ListAgentFullName || "",
        email: ListAgentEmail || "",
        agent_license_number: ListAgentMlsId || "",
        landline: "",
        phone: ListAgentPreferredPhone || "",
        brokerage: ListOfficeName || "",
        brokerage_license_number: ListOfficeMlsId || "",
        brokerage_address: "",
        apt: null,
        city: "",
        state: "",
        zipcode: "",
      }
      propertyPayload.agents = [listingAgent]
    }
    console.log(propertyPayload, "here123")
    updatePropertyInformationTab({
      payload: propertyPayload,
      id: dealToView,
      isRedirect: true,
    })
  }

  const SIDENAV_OPTIONS = [
    {
      value: "property_information",
      label: "Property Information",
      component: (
        <PropertyInformation
          propertyInformationFormRef={PROPERTY_INFORMATION_FORM_REF}
          listingAgentInformationFormRef={LISTING_AGENT_INFORMATION_FORM_REF}
          sellerInformationFormRef={SELLER_INFORMATION_FORM_REF}
          buyerInformationFormRef={BUYER_INFORMATION_FORM_REF}
          buyerAgentInformationFormRef={BUYER_AGENT_INFORMATION_FORM_REF}
          financingInformationFormRef={FINANCING_INFORMATION_FORM_REF}
          vendorInformationFormRef={VENDOR_INFORMATION_FORM_REF}
          dealTermsFormRef={DEAL_TERMS_FORM_REF}
        />
      ),
    },
    {
      value: "buyer_information",
      label: "Buyer Information",
      component: (
        <BuyerInformation
          propertyInformationFormRef={PROPERTY_INFORMATION_FORM_REF}
          listingAgentInformationFormRef={LISTING_AGENT_INFORMATION_FORM_REF}
          sellerInformationFormRef={SELLER_INFORMATION_FORM_REF}
          buyerInformationFormRef={BUYER_INFORMATION_FORM_REF}
          buyerAgentInformationFormRef={BUYER_AGENT_INFORMATION_FORM_REF}
          financingInformationFormRef={FINANCING_INFORMATION_FORM_REF}
          vendorInformationFormRef={VENDOR_INFORMATION_FORM_REF}
          dealTermsFormRef={DEAL_TERMS_FORM_REF}
        />
      ),
    },
    {
      value: "deal_terms",
      label: "Deal Details",
      component: (
        <DealTerms
          propertyInformationFormRef={PROPERTY_INFORMATION_FORM_REF}
          listingAgentInformationFormRef={LISTING_AGENT_INFORMATION_FORM_REF}
          sellerInformationFormRef={SELLER_INFORMATION_FORM_REF}
          buyerInformationFormRef={BUYER_INFORMATION_FORM_REF}
          buyerAgentInformationFormRef={BUYER_AGENT_INFORMATION_FORM_REF}
          financingInformationFormRef={FINANCING_INFORMATION_FORM_REF}
          vendorInformationFormRef={VENDOR_INFORMATION_FORM_REF}
          dealTermsFormRef={DEAL_TERMS_FORM_REF}
        />
      ),
    },
  ]


  return (
    <MenuUiWrap>
      <CoverSheetTabUI>
        {isFetching ? (
          <ShimmerBox w="470px" h="50px" />
        ) : (
          <SelectField
            options={PROPERTY_TYPE}
            style={customSelectStyles}
            arrowIcon={showIcon ? <DropdownUpIcon /> : <DropdownIcon />}
            value={type_of_transaction}
            placeholder="Select property type"
            onDropdownVisibleChange={() => setIcon(!showIcon)}
            onChange={(val) => {
              const payload = {
                ...propertyInformationObject,
                current_offer_step: "property_information_mobile",
                type_of_transaction: val,
              }

              setMLSData({
                ...propertyInformationObject,
                purchaseType: val,
              })
              updatePropertyInformationTab({
                payload,
                id: dealToView,
                isRedirect: false
              })
              // updatePropertyInformationObject({
              //   ...payload,
              // })
            }}
          />
        )}
        <DebouncedSelect
          showSearch
          placeholder="Search by MLS"
          value={searchValue}
          fetchOptions={fetchUserList}
          onChange={newValue => handleChange(newValue)}
          propertyInformationObject={propertyInformationObject}
          isFetching={isFetching || fetchingInfo}
          fontSize="16px"
        />
      </CoverSheetTabUI>
  
      <SideNav
        options={SIDENAV_OPTIONS}
      />
    </MenuUiWrap>
  )
}

export default OffersV2(TechAssets(CoverSheet))
