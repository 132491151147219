import React from "react"
import styled from "styled-components"
import Box from "@ui/Box"
import Tag from "dumbComponents/common/StyledComponents/Tag"
import Loader from "@ui/Loader"

const TagContainer = styled.div`
  width: 100%;
  font-weight: 300;
  font-size: 14px;
`
const MultiTagChooser = (props) => {
  const {
    tags,
    selectedTags,
    maxSelectCount,
    onChange,
    onMaxReached,
    tagContainerStyle,
    tagKey,
    selectKey,
    loader,
  } = props

  const setTag = (tagId) => {
    const newSelectedTagIds = Object.assign({}, selectedTags)
    if (selectedTags[tagId]) {
      delete newSelectedTagIds[tagId]
      onChange(newSelectedTagIds)
    } else {
      const selectedTagsLength = Object.keys(selectedTags).length

      if (maxSelectCount && selectedTagsLength >= maxSelectCount) {
        if (onMaxReached) {
          onMaxReached()
        } else {
          console.log("Max number of tags reached")
        }
      } else {
        newSelectedTagIds[tagId] = true
        onChange(newSelectedTagIds)
      }
    }
  }

  return (
    <TagContainer style={tagContainerStyle}>
      {!loader
        ? (
          <Box d="flex" wrap="wrap">
            {
              tags.map((tagObject) => {
                return (
                  <Tag
                    key={tagObject[tagKey]}
                    isSelected={selectedTags[tagObject[selectKey]]}
                    onClick={() => setTag(tagObject[selectKey])}
                  >
                    {tagObject[tagKey]}
                  </Tag>
                )
              })
            }
          </Box>
        )
        : (
          <Loader top={0}/>
        )
      }
    </TagContainer>
  )
}

export default MultiTagChooser
