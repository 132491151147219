import React from "react"
import styled from "styled-components"
import Panel from "./Panel"
import { BlankButton } from "./EditOptions/commonStyles"
import { formatNextStep } from "./HelperComponents"

const ProgressWrap = styled.div`
  display: flex;
  align-items: baseline;
`

const ProgressBar = styled.div`
  flex: 1;
  border-radius: 10px;
  background: #f8f9fa;
  > div {
    height: 8px;
    background-image: linear-gradient(93deg, #0ac4ba, #2bda8e);
    border-radius: 10px;
  }
`

const CompletionText = styled.p`
  margin-left: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #354052;

  strong {
    font-weight: 500;
  }
`

const NextStepWrap = styled.div`
  font-size: 14px;
`

const ProfileCompletion = ({ progressPercentage, handleClick, profileCompletionRemainingSteps }) => (
  <Panel
    title="Strengthen your profile"
    isShowEdit
  >
    <ProgressWrap>
      <ProgressBar>
        <div style={{ width: `${progressPercentage}%` }}></div>
      </ProgressBar>
      <CompletionText>
        Your profile is
        <strong>{` ${progressPercentage}% complete`}</strong>
      </CompletionText>
    </ProgressWrap>
    { profileCompletionRemainingSteps[0] && (
      <NextStepWrap>
        Next Step:
        <BlankButton
          style={{ fontWeight: 300, textTransform: "capitalize" }}
          onClick={() => { handleClick() }}
        >
          {`Add ${formatNextStep(profileCompletionRemainingSteps[0])}`}
        </BlankButton>
      </NextStepWrap>
    )}
  </Panel>
)

export default ProfileCompletion
