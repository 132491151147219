/* eslint-disable max-len */
import React from "react"

const MuteMic = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none">
    <path
      d="M10.634 3.386C10.634 1.47326 9.40848 0.140137 7.62823 0.140137C5.98874 0.140137 4.8295 1.26625 4.64734 2.9223V3.25351L5.86453 4.46243L6.53585 5.12997L7.4063 5.98627C8.49353 7.08266 8.49793 7.08115 8.49793 7.08115L9.01285 7.6036L9.37536 7.97326L10.5843 9.18218C10.6174 8.99173 10.634 8.79301 10.634 8.59428V3.386ZM13.7059 15.5C13.9543 15.7484 14.3601 15.7484 14.6085 15.5C14.8486 15.2433 14.8569 14.8459 14.6085 14.5975L1.79893 1.79619C1.55052 1.55606 1.13651 1.54778 0.888099 1.79619C0.647971 2.0446 0.647971 2.45861 0.888099 2.69874L13.7059 15.5ZM1.69956 8.89237C1.69956 12.1382 3.84415 14.4153 6.99893 14.6803V16.6096H3.92695C3.57918 16.6096 3.29765 16.8911 3.29765 17.2389C3.29765 17.5866 3.57918 17.8599 3.92695 17.8599H11.3212C11.669 17.8599 11.9505 17.5866 11.9505 17.2389C11.9505 16.8911 11.669 16.6096 11.3212 16.6096H8.24925V14.6803C9.18492 14.6057 10.0212 14.3491 10.7499 13.9433L9.85562 13.0573C9.21804 13.3637 8.47281 13.5376 7.62823 13.5376C4.7881 13.5376 2.94988 11.6663 2.94988 8.84269V7.21148C2.94988 6.86371 2.67663 6.59046 2.32058 6.59046C1.97281 6.59046 1.69956 6.86371 1.69956 7.21148V8.89237ZM12.0085 10.5981L12.9773 11.5669C13.3499 10.7885 13.5486 9.886 13.5486 8.89237V7.21148C13.5486 6.86371 13.2754 6.59046 12.9276 6.59046C12.5798 6.59046 12.2983 6.86371 12.2983 7.21148V8.84269C12.2983 9.48027 12.1989 10.0682 12.0085 10.5981ZM7.62823 11.8401C7.94288 11.8401 8.23269 11.7905 8.49766 11.6994L8.31388 11.5108L6.97522 10.2359L6.4131 9.67956L5.97847 9.23334L4.61421 7.81594V8.59428C4.61421 10.4987 5.83969 11.8401 7.62823 11.8401Z"
      fill="#ED1616"
    />
  </svg>
)

export default MuteMic
