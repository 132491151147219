import { takeLatest, call, put, select } from "redux-saga/effects"
import { union } from "lodash"
import { isSuccess } from "services/Utils"
import root from "window-or-global"
import queryString from "query-string"
import * as AppActions from "container/App/actions"
import { isMobile } from "services/Utils"
import * as NavbarActions from "./actions"
import {
  fetchNotificationAPI,
  fetchNotificationUnreadCountAPI,
  finishOnboardingAPI,
  notificationViewedAPI,
} from "./api"

function* fetchNotification(action) {
  try {
    const {
      count,
      startTime,
      isFetchMore,
    } = action.data || {}
    const res = yield call(fetchNotificationAPI, count, startTime)
    if (isSuccess(res)) {
      const { response } = res.data
      const oldNotifications = yield select(state => state.navbar.notifications)
      const { notification: newNotifications = [] } = response
      if (isFetchMore) {
        try {
          const newNoti = union(oldNotifications, newNotifications)
          yield put(NavbarActions.fetchNotificationAction.success({
            notification: newNoti,
          }))
        } catch (error) {
          console.log("Error", error)
        }
      } else {
        yield put(NavbarActions.fetchNotificationAction.success(response))
      }
    }
  } catch (e) {
    yield put(NavbarActions.fetchNotificationAction.failure(e))
  }
}

function* fetchNotificationUnreadCount() {
  try {
    const res = yield call(fetchNotificationUnreadCountAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.fetchNotificationUnreadCountAction.success(response))
    }
  } catch (e) {
    yield put(NavbarActions.fetchNotificationUnreadCountAction.failure(e))
  }
}

function* togglePressReleaseBanner(action) {
  try {
    const { isOpen } = action.data
    const $header = root.document.querySelector(".main-navbar")
    const $sidebar = root.document.getElementById("logged-in-sidebar")
    const $indexRoute = root.document.getElementById("index-layout")
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const isAcademy = false
    if (isOpen) {
      // $header.style
      if (isAcademy) {
        $indexRoute.style.paddingTop = "60px"
      }
      $header.style.height = parsedQuery && parsedQuery.platform === "ios" ? "80px" : "125px"
      if ($sidebar) {
        $sidebar.style.top = "100px"
      }
      yield put(AppActions.togglebannerPaddingAction.call(120))
    } else {
      $header.style.height = "71px"
      if ($sidebar) {
        $sidebar.style.top = "50px"
      }
      yield put(AppActions.togglebannerPaddingAction.call(70))
      $indexRoute.style.paddingTop = "0px"
    }
  } catch (error) {
    console.log(error)
  }
}

function* finishTabsSpotlightFlow(action) {
  try {
    const { payload } = action
    const res = yield call(finishOnboardingAPI, payload)
    if (isSuccess(res)) {
      yield put(AppActions.fetchUserInfoAction.request())
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleNotificationViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(notificationViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.notificationViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(NavbarActions.notificationViewedAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(NavbarActions.fetchNotificationAction.REQUEST, fetchNotification)
  yield takeLatest(NavbarActions.fetchNotificationUnreadCountAction.REQUEST, fetchNotificationUnreadCount)
  yield takeLatest(NavbarActions.togglePressReleaseBannerAction.type, togglePressReleaseBanner)
  yield takeLatest(NavbarActions.finishTabsSpotlightFlowAction.REQUEST, finishTabsSpotlightFlow)
  yield takeLatest(NavbarActions.notificationViewedAction.REQUEST, handleNotificationViewed)
}
