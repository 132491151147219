import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  padding-bottom: 68px;

  ${props => props.v2 && `
    padding-bottom: unset;
    // display: flex;
    padding: 16px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Color-Neutral-100, #F1F1FE);
    margin-bottom: 68px;
  `}
`

export const CTAWrap = styled.div`
  // padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
export const ListRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  align-items: baseline;
`

export const StateDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  p {
    color: var(--BG-Dark-mode, #292A37);
    text-align: right;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  h2 {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`