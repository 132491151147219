import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import styled from "styled-components";
import ConsumerAppContainer from "container/ConsumerApp/index";

// Styled components
const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #0073ff;
  margin-bottom: 8px;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
`;

const SubTitle = styled.p`
  font-size: 14px;
  color: #7d7da9;
  margin-bottom: 32px;
`;

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const IconWrapper = styled.div`
  font-size: 24px;
  color: #ff9900;
  .points-icon {
    height: 24px;
    width: 24px;
    margin-top: 20px;
  }
`;

const FeatureDetails = styled.div`
  font-size: 14px;
  color: #555;

  .feature-title {
    font-weight: bold;
    color: #333;
  }

  .feature-description {
    margin-top: 4px;
    font-size: 14px;
    color: #7d7da9;
  }
`;

const FooterButton = styled(Button)`
  border-radius: 32px;
  background: var(--Color-Primary-Primary-500, #5a5ff2);
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  color: white;
  width: 100%;
  align-self: stretch;
`;

const InviteClientButton = styled.a`
  border-radius: 32px;
  background: var(--Color-Primary-Primary-500, #5a5ff2);
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  color: white;
  width: 100%;
  align-self: stretch;
  &:hover {
    opacity: 0.8;
    color: white;
  }
`;

const NewContentContainer = styled.div`
  text-align: center;
  padding: 16px;

  .image-container {
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    color: #7d7da9;
    margin-bottom: 16px;
  }

  .highlight {
    font-weight: bold;
    color: #a3a3c2;
    margin-top: 16px;
    font-weight: 500;
  }

  .questions-link {
    font-size: 14px;
    font-family: Inter;
    color: #5a5ff2;
    margin-top: 16px;
  }
`;

const ConsumerAppStatusModal = ({
  history,
  isOpen,
  isAppInWaitlist,
  setIsOpen,
  joinWaitlist,
  joinWaitlistResponse,
  getAppStatusResponse,
  teamImage,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(isOpen);
  const [isWaitlistJoined, setIsWaitlistJoined] = useState(isAppInWaitlist);

  const handleJoinWaitlist = () => {
    const payload = { app_type: "Individual" };
    joinWaitlist(payload);
  };

  useEffect(() => {
    if (joinWaitlistResponse?.data) {
      setIsWaitlistJoined(true);
    }
  }, [joinWaitlistResponse]);

  useEffect(() => {
    setIsModalVisible(isOpen);
  }, [isOpen]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsOpen(false);
  };

  const gotoInviteClients = () => {
    history.push("/realty/consumer-app");
  };

  return (
    <div>
      <Modal
        width={480}
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {!isWaitlistJoined && !getAppStatusResponse?.data?.wait_list ? (
          <>
            <ModalHeader>
              <div className="image-container">
                {teamImage?.icon_url ? (
                  <img
                    style={{ height: "80px" }}
                    src={teamImage?.icon_url}
                    alt="app logo"
                  />
                ): null}
              </div>
              <Title>
                Want your own{" "}
                <span style={{ color: "#ff9900" }}>private labeled</span> app
                for clients?
              </Title>
              <SubTitle>Join the waitlist for early access</SubTitle>
            </ModalHeader>
            <FeaturesList>
              {/* Features */}
              <Feature>
                <IconWrapper>
                  <img
                    className="points-icon"
                    src={`${CLOUDFRONT}/Consumer-app/paint.svg`}
                    alt=""
                  />
                </IconWrapper>
                <FeatureDetails>
                  <div className="feature-title">Custom branding</div>
                  <div className="feature-description">
                    Elevate your client experience with a private labeled app
                    tailored specifically for your clients.
                  </div>
                </FeatureDetails>
              </Feature>
              <Feature>
                <IconWrapper>
                  <img
                    className="points-icon"
                    src={`${CLOUDFRONT}/Consumer-app/Property-Search.svg`}
                    alt=""
                  />
                </IconWrapper>
                <FeatureDetails>
                  <div className="feature-title">
                    Collaborative search boards
                  </div>
                  <div className="feature-description">
                    Work together seamlessly with interactive boards to find the
                    perfect property faster.
                  </div>
                </FeatureDetails>
              </Feature>
              <Feature>
                <IconWrapper>
                  <img
                    className="points-icon"
                    src={`${CLOUDFRONT}/Consumer-app/Default.svg`}
                    alt=""
                  />
                </IconWrapper>
                <FeatureDetails>
                  <div className="feature-title">In-app communication</div>
                  <div className="feature-description">
                    Streamline your interactions with clients through real-time
                    messaging right in the app.
                  </div>
                </FeatureDetails>
              </Feature>
              <Feature>
                <IconWrapper>
                  <img
                    className="points-icon"
                    src={`${CLOUDFRONT}/Consumer-app/Vector.svg`}
                    alt=""
                  />
                </IconWrapper>
                <FeatureDetails>
                  <div className="feature-title">AI Assistance</div>
                  <div className="feature-description">
                    Leverage AI to receive personalized property recommendations
                    and insights.
                  </div>
                </FeatureDetails>
              </Feature>
            </FeaturesList>
            <InviteClientButton onClick={handleJoinWaitlist}>
              Join the waitlist
            </InviteClientButton>
          </>
        ) : getAppStatusResponse?.data?.status === "App Live" ? (
          <NewContentContainer>
            <div className="image-container">
              <img
                style={{ height: "80px" }}
                src={getAppStatusResponse?.data?.app_logo}
                alt="app logo"
              />
            </div>
            <div className="title">Your app is live!</div>
            <InviteClientButton href="/realty/consumer-app">
              Invite clients
            </InviteClientButton>
          </NewContentContainer>
        ) : (
          <NewContentContainer>
            <div className="image-container">
              <img
                src={`${CLOUDFRONT}/Consumer-app/graphics-waitlist.svg`}
                alt="New Design"
              />
            </div>
            <div className="title">You’re on the list!</div>
            <div className="description">
              Get ready to revolutionize your real estate business with your own
              private labeled, AI-powered app.
            </div>
            <div className="highlight">
              Currently #
              {joinWaitlistResponse?.data
                ? joinWaitlistResponse?.data?.wait_list
                : getAppStatusResponse?.data?.wait_list}{" "}
              in line
            </div>
            <div className="questions-link">HAVE QUESTIONS? CONTACT US</div>
          </NewContentContainer>
        )}
      </Modal>
    </div>
  );
};

export default ConsumerAppContainer(ConsumerAppStatusModal);
