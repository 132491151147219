import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as NonAssistActions from "./actions"

const initState = {
  dndColumns: {},
  isShowArchiveModal: false,
  ...createDeltaReducer("nonAssistLeadsResponse"),
  ...createDeltaReducer("saveNotesResponse"),
  ...createDeltaReducer("archiveModalResponse"),
}

export default function nonAssistReducer(state = initState, action) {
  switch (action.type) {
    case NonAssistActions.getNonAssistLeadsAction.REQUEST:
    case NonAssistActions.getNonAssistLeadsAction.FAILED:
    case NonAssistActions.getNonAssistLeadsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          NonAssistActions.getNonAssistLeadsAction,
          "nonAssistLeadsResponse"
        ),
      }
    }

    case NonAssistActions.getArchivedLeadNonAssistAction.REQUEST:
    case NonAssistActions.getArchivedLeadNonAssistAction.FAILED:
    case NonAssistActions.getArchivedLeadNonAssistAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          NonAssistActions.getArchivedLeadNonAssistAction,
          "archiveModalResponse"
        ),
      }
    }

    case NonAssistActions.addNoteAction.REQUEST:
    case NonAssistActions.addNoteAction.FAILED:
    case NonAssistActions.addNoteAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          NonAssistActions.addNoteAction,
          "saveNotesResponse"
        ),
      }
    }

    // NON API Actions
    case NonAssistActions.updateSalesStageAction.type: {
      return {
        ...state,
        dndColumns: action.data,
      }
    }
    case NonAssistActions.toggleArchiveModalAction.type: {
      return {
        ...state,
        isShowArchiveModal: action.data,
      }
    }

    case NonAssistActions.toggleAddNotesModalAction.type: {
      return {
        ...state,
        isShowAddNote: action.data,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
