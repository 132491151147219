import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { get } from "lodash"
import {
  getIsUserATeamOwner,
  getIsUserATeamMember,
  getIsUserATeamAdmin,
  getIsSubscribedToAnyISAPlan,
  getIsUserABroker,
  getDashbaordLinkFromStore,
} from "container/App/selector"
import {
  getFeedByHomeClickAction,
} from "container/Network/actions"
import { RARTrackerAction } from "container/Realty/actions"
import {
  getRedirectionLocationAction,
  getDashboardLinkAction,
} from "./actions"


const mapStateToProps = (state, props) => {
  const { referral, rooms } = state
  const { subscriptions } = referral
  const { getRoomsResponse } = rooms
  const isSubscribedToAnyISAPlan = getIsSubscribedToAnyISAPlan(state)
  const isUserATeamOwner = getIsUserATeamOwner(state)
  const isUserATeamMember = getIsUserATeamMember(state)
  const isUserATeamAdmin = getIsUserATeamAdmin(state)
  const isBroker = getIsUserABroker(state)
  const agentWebsite = get(state, "app.user.agent_website")
  const dashbaordLinks = getDashbaordLinkFromStore(state)
  return {
    subscriptions,
    isBroker,
    isSubscribedToAnyISAPlan,
    isUserATeamOwner,
    isUserATeamMember,
    isUserATeamAdmin,
    agentWebsite,
    dashbaordLinks,
    getRoomsResponse,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      getDashbaordLink: getDashboardLinkAction.request,
      getFeedByHomeClick: getFeedByHomeClickAction.call,
      RARTracker: RARTrackerAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
