import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const AgentCardWrap = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 24px;
  align-self: stretch;

  border-radius: 8px;
  background: #FFF;

  /* ss */
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.25);
`

export const BuyerAgentInformationText = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.523%; /* 21.073px */
  }
`

export const AgentName = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 27px */
  }
`

export const AgentEmail = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }
`

export const AgentPhoneNumber = styled.div`
  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
`

export const AddtionalInfoOuterWrap = styled.div`
  display: flex;
  gap:20px 60px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 25px;
`

export const AdditionalInfoInnerWrap = styled.div`
  // border-right: 1px solid #41446A;
  height: 48px;
  padding-right: 20px;

  ${props => props.noBorder && `
    border: none;
  `}
`

export const AdditionInfoTitle = styled.p`
  color: rgba(41, 42, 55, 0.60);
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
`

export const AdditionalInfoValue = styled.p`
  color: var(--BG-Dark-mode, #292A37);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`

export const EditButton = styled.button`
  border: none;
  color: var(--Color-Primary-Primary, #5A5FF2);
  background: transparent;
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
`

export const DetailsArea = styled.div`
  padding-top: 15px;
`
