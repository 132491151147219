import axios from "axios"
import getXUserAgentKey from "./axiosUtils"
import CookiesStorage from "./CookieStorage"

const headerXAgentKey = getXUserAgentKey()

axios.interceptors.request.use(
  (config) => {
    //console.log("Axios Request Config: ", config);
    config.headers.common["X-User-Agent"] = headerXAgentKey
    if (!config.headers["internal-id"]) {
      const secureId = CookiesStorage.load("secureId")
      if (secureId) {
        config.headers.common["secure-id"] = secureId
      }
    }

    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 422) {
      return error.response
    }
    return Promise.reject(error.response || error)
  }
)
