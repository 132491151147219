import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import TechAssetsContainer from "container/TechAssets"
import Listings from "container/Listings/index"
import Container from "container/Realty/TransactionFormContainer"
import { withRouter } from "react-router-dom"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import Loader from "@ui/Loader"
import { ordinalSuffix } from "./utils"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;

  border-radius: 12px;
  border: 1px solid var(--Gradient-1, #B6B8FF);
  background: linear-gradient(60deg, #EFE6FF 1.85%, #E5E3E9 100.75%);

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`

const AnimationArea = styled.div`
  img {
    width: 188.193px;
    height: 188.193px;
  }
`

const MainText = styled.div`
 width: 365px;
  p {
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: ${INTER};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 149%; /* 35.76px */
    margin: 0;
  }
`

const SubText = styled.div`
  p {
    color: var(--Gray-500, #667085);
    text-align: center;
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 149%; /* 23.84px */
  }
`

const GoToContracts = styled.div`
  button {
    display: flex;
    width: 322px;
    padding: 16px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: none;
    cursor: pointer;

    border-radius: 33.5px;
    background: #5A5FF2;

    color: var(--White, #FDFDFD);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

const ContractSuccessModal = ({
  history,
  fetchTransactionCoordinationDocumentsResponse,
  transactionFormUpdateStageResponse,
  showSuccessModal,
  isContractSuccessVisible,
  contractForAgent,
  totalDealCount,
  dealCountUpdate,
  setListingId,
  setOfferIdToView,
  setDealToView,
}) => {
  const {
    isFetching: isFetchingStageDetails,
  } = transactionFormUpdateStageResponse || {}

  return (
    <Modal
      toClose={() => {
        showSuccessModal(false)
        setListingId(null),
        setOfferIdToView(null),
        setDealToView(null),
        history.push("/realty/transaction-dashboard")
      }}
      width={594}
      show={isContractSuccessVisible}
      modalVersion={3}
      closeIcon={CloseIcon}
      overlayStyles={{
        alignItems: "flex-start",
      }}
      modalStyles={{
        padding: "0px",
        //background: "#262842",
        width: "450px",
      }}
      customDialogColor="transparent"
    >
      <Wrap>
        {isFetchingStageDetails ? (
          <Loader top={50} />
        ) : (
          <>
            <AnimationArea>
              <img src={`${CLOUDFRONT}/LISTING_V2/Success.gif`} alt="animation_contracts_success.gif" />
            </AnimationArea>
            <MainText>
              <p>
                {contractForAgent ? `Congrats on ${contractForAgent}'s ${ordinalSuffix(totalDealCount)} Transaction with Radius!` : `Congrats on your ${ordinalSuffix(totalDealCount)} Transaction with Radius!`}
              </p>
            </MainText>
            <SubText>
              <p>
                {/* {contractForAgent ? "We've received your submission. Thank you." : "We've received their submission. Thank you."} */}
                We have received your submission. Thank you.
              </p>
            </SubText>
            <GoToContracts>
              <button
                type="button"
                onClick={() => {
                  setListingId(null)
                  setOfferIdToView(null)
                  setDealToView(null)
                  showSuccessModal(false)
                  dealCountUpdate({
                    forAgent: "",
                    dealCount: 0,
                  })
                  history.push("/realty/transaction-dashboard")
                }}
              >
                View all my contracts
              </button>
            </GoToContracts>
          </>
        )}

      </Wrap>
    </Modal>
  )
}

export default withRouter(Container(Listings(TechAssetsContainer(ContractSuccessModal))))
