import React from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import Heading from "dumbComponents/common/Typography/Heading"
import ThreeDotLoader from "@ui/ThreeDotLoader"

const Wrapper = styled.div`
  ${Button} {
    span {
      margin-left: 20px;
    }

    .editIcon {
      position: relative;
      z-index: 10;
    }

    i {
      width: 20px;
      margin-left: 10px;
      height: 20px;
      opacity: 0;
      transition-duration: 0.4s;
      border-radius: 50%;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
`

const CusButton = styled.div`
  display: inline-block;
  height: 40px;
  display: inline-flex;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;

  ${props => props.isActive && `
    background: ${colors.primaryColor};
    color: ${colors.white};
  `}
`

const CityText = styled.span`
  padding: 10px 20px;
  transition-duration: 0.2s;
  color: ${colors.primaryColor};
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primaryColor};
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  &:hover {
    background: #edf9ff;
  }
  ${props => props.isActive && `
    background: ${colors.primaryColor};
    color: ${colors.white};

    &:hover {
      background: #0e9cdc;
    }
  `}

  ${props => props.isNonEditable && `
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  `}
`

const IconWrap = styled.span`
  position: relative;
  transition-duration: 0.2s;
  border-top-right-radius: 20px;
  border: 1px solid #11adf3;
  border-bottom-right-radius: 20px;
  border-left: 0px;

  &:hover {
    background: #edf9ff;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    color: ${colors.primaryColor};
    ${props => props.isActive && `
      color: ${colors.white};
      &:hover {
        background: #0e9cdc;
      }
    `}
  }
`

const Tag = styled.span`
  display: inline-block;
  background-color: #e9f7fd;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition-duration: 0.2s;

  ${props => props.selected && `
    background: #11ADF3;
    color: #FFF;
  `}

  ${props => props.edit && `
    background-color: transparent;
    color: #11ADF3;
    border: 1px solid #11ADF3;
  `}
`

class CitiesServed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCity: {},
      showMore: false,
      initLimit: 6,
    }
  }

  renderHeading = title => (
    <Heading
      type="h7"
      transform="uppercase"
      color="#354052"
      align="left"
      style={{
        margin: "15px 0px",
      }}
    >
      {title}
    </Heading>
  )

  selectActiveCity = (city) => {
    this.setState({
      activeCity: city,
    })
  }

  handleEditClick = (city) => {
    const { openEditProfileModal } = this.props
    openEditProfileModal({
      type: "neighborhoodEdit",
      initialValues: {
        city,
        isEditOpen: true,
      },
    })
    //removeCityServedCity(city)
  }

  handleShowMore = () => {
    const { cities } = this.props
    const { showMore } = this.state
    const initLimit = showMore ? 6 : cities.length
    this.setState({
      showMore: !showMore,
      initLimit,
    })
  }

  render() {
    const { cities, isRemovingCityServedCity, isUserCanEdit } = this.props || {}
    const { activeCity, initLimit, showMore } = this.state
    const { neighbourhoods = [] } = activeCity || {}
    const trimmedCities = cities.filter(x => !x.isHide).slice(0, initLimit)
    const onlyPinCodes = neighbourhoods.filter(item => parseInt(item.name, 0) > 0)
    const onlyNeighbourhood = neighbourhoods.filter(item => !parseInt(item.name, 0))
    return (
      <Wrapper>
        {trimmedCities && trimmedCities.length > 0 && trimmedCities.map(item => (
          <CusButton
            key={item.id}
            id={item.city === activeCity.city ? "cityServedActive" : ""}
            isActive={item.city === activeCity.city}
            width="auto"
            style={{
              marginRight: 10,
              marginBottom: 10,
              alignSelf: "flex-start",
            }}
          >
            {isRemovingCityServedCity === item.id
              ? <ThreeDotLoader color="#FFF" />
              : (
                <React.Fragment>
                  <CityText
                    isActive={item.city === activeCity.city}
                    isNonEditable={!isUserCanEdit}
                    onClick={(e) => { this.selectActiveCity(item, e) }}
                    style={!isUserCanEdit ? { marginLeft: 0 } : {}}
                  >
                    {`${item.city} ${item.short_state_name ? `, ${item.short_state_name}` : ""}`}
                  </CityText>
                  {isUserCanEdit && (
                    <IconWrap
                      onClick={() => { this.handleEditClick(item) }}
                      className="editIcon"
                      isActive={item.city === activeCity.city}
                    >
                      <i className="icon-generic-Edit"></i>
                    </IconWrap>
                  )}
                </React.Fragment>
              )
            }
          </CusButton>
        ))}
        { cities.length >= initLimit && (
          <Button
            bsStyle="secondary"
            width="auto"
            onClick={() => {
              this.handleShowMore()
            }}
            style={{
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            {showMore ? "Less..." : "More..."}
          </Button>
        )}
        {onlyNeighbourhood && onlyNeighbourhood.length > 0 && (
          <div>
            {this.renderHeading("Neighborhoods")}
            {onlyNeighbourhood.map(item => (
              <Tag key={item.name}>{item.name}</Tag>
            ))}
          </div>
        )}

        {onlyPinCodes && onlyPinCodes.length > 0 && (
          <div>
            {this.renderHeading("Zip Codes")}
            {onlyPinCodes.map(item => (
              <Tag key={item.name}>{item.name}</Tag>
            ))}
          </div>
        )}

        {/* {isCRS && unmappedNeighbourhoods && unmappedNeighbourhoods.length > 0 && (
          <div>
            {this.renderHeading("CRS Neighborhoods")}
            {unmappedNeighbourhoods.map(item => (
              <Tag key={item}>{item}</Tag>
            ))}
          </div>
        )} */}
      </Wrapper>
    )
  }
}

export default CitiesServed
