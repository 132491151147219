import React from "react"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { Drawer } from "@ui/antd"
import CRMV2Container from "container/CRM"
import { LeadFilters, AddNewLead } from "../DrawerContent"
import Loader from "@ui/Loader"

const NonUserHeaderDrawer = ({
  handleClose,
  open,
  title,
  titleFontSize,
  hideDrawerTitle,
  currentPage,
  width,
}) => {
  const getCurrentPage = () => {
    switch (currentPage) {
      case "add-lead":
      case "add-same-lead":
        return <AddNewLead handleClose={handleClose} />

      case "lead-filters":
        return <LeadFilters />

      default:
        return <Loader />
    }
  }
  return (
    <>
      {open && (
        <Drawer
          width={width || "537px"}
          title={title}
          placement="right"
          onClose={() => handleClose()}
          open={open}
          type="Basic Drawer"
          CloseIconRight
          hideDrawerTitle={hideDrawerTitle}
          fontSize={titleFontSize}
          closeIcon={<CloseIcon />}
          maskClosable={false}
        >
          {getCurrentPage()}
        </Drawer>
      )}
    </>
  )
}

export default CRMV2Container(NonUserHeaderDrawer)
