import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import RTViewer from "@ui/RTViewer"
import moment from "moment"
import colors from "@colors"
import Button from "@ui/Button"
import RegisteredAgents from "dumbComponents/Academy/EventDetails/RegisteredAgents"
import GuestList from "dumbComponents/Academy/EventDetails/GuestList"
import {
  unixToDate,
} from "services/Utils"
import { prettyUrl } from "dumbComponents/Academy/common/Helpers"

const Wrap = styled.div`
  color: ${colors.contentText};
  font-size: 14px;
  font-weight: 500;
  
  h5 {
    color: ${colors.contentText};
    font-size: 14px;
    margin: 0px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
  }
`

const CourseWrap = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${colors.borderGray};
  main {
    height: 240px;
    background: ${colors.borderGray} center no-repeat; 
    background-size: cover;
  }

  footer {
    background: ${colors.white};
    padding: 17px 25px;
    span {
      margin: 0px 20px;
      font-weight: 300;
    }
  }
`

const EventTitle = styled.h4`
  margin: 4px 0px;
`

const EVENT_DATE_FORMAT = "dddd, MMM DD, h:mm a"

class CourseFeed extends React.Component {
  state = {
    isShowGuestListModal: false,
  }

  toggleGuestList = (show) => {
    this.setState({
      isShowGuestListModal: show,
    })
  }

  goToEvent = () => {
    const {
      event_hash_id,
      id,
      title,
    } = this.props
    if (root.heap) {
      root.heap.track("community_feed_event_web")
    }
    root.open(`${WEB_URL}academy/event/${event_hash_id || id}/${prettyUrl(title)}`)
  }

  render() {
    const {
      cover_image_url,
      registered_event_agents,
      feedback_post_text_RTF,
      community_post_text,
      contentType,
      title,
      event_start_timestamp,
    } = this.props
    const { isShowGuestListModal } = this.state
    const isEventFeedback = contentType === "event_feedback"
    return (
      <Wrap>
        {!isEventFeedback && (
          <EventTitle>
            {title}
          </EventTitle>
        )}
        {isEventFeedback ? (
          <RTViewer
            data={feedback_post_text_RTF}
            style={{ margin: "0px" }}
          />
        ) : (
          <p>
            {community_post_text}
          </p>
        )}
        <CourseWrap>
          <main style={{ backgroundImage: `url(${cover_image_url})` }} />
          <footer>
            {isEventFeedback ? (
              <React.Fragment>
                {registered_event_agents && registered_event_agents.length > 0 && (
                  <RegisteredAgents
                    isInFeed
                    agents={[...registered_event_agents]}
                    toggleGuestList={this.toggleGuestList}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  borderRadius="4px"
                  width="130px"
                  style={{ backgroundColor: colors.academyTitleColor }}
                  onClick={this.goToEvent}
                >
                  Learn more
                </Button>
                <span>
                  {`${moment(unixToDate(event_start_timestamp)).format(EVENT_DATE_FORMAT)}`}
                </span>
              </React.Fragment>
            )}
          </footer>
        </CourseWrap>
        {isShowGuestListModal && (
          <GuestList
            agents={[...registered_event_agents]}
            toClose={() => { this.toggleGuestList(false) }}
          />
        )}
      </Wrap>
    )
  }
}

export default CourseFeed
