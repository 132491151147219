import React, {useRef} from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import HiddenInputField from "dumbComponents/common/InputFields/EmptyField"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"


const VENDOR_TYPE = [
  {value: "title", label: "Title"},
  {value: "escrow", label: "Escrow"},
  {value: "title_and_escrow", label: "Title and Escrow"},
  {value: "closing_attorney", label: "Closing Attorney"},
]
  

const VendorForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddNewVendor,
  deleteVendor,
}) => {
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      const { vendor_city, vendor_state, vendor_zipcode } = prefilledData || {}
      initValues = {
        ...prefilledData,
        city: vendor_city,
        state: vendor_state,
        zipcode: vendor_zipcode,
      }
    }

    return initValues
  }
  const formRef = useRef(null)

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Vendor Information"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            handleEditModeChange()
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V3/Offers_v3_asset_icon.svg`} alt="minus" style={{marginTop:'1px' }} />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_vendor_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        // ref={this.formRef}
      >
        <FormFieldWrap>

          <SelectField
            name="vendor_type"
            options={VENDOR_TYPE}
            placeholder="Select Vendor"
            isFormField={true}
            required={true}
            text="Select Vendor"
            //onChange={handleChangeForVendor}
          />
        
          <InputField
            name="vendor_office_name"
            inputType="text"
            required={true}
            placeholder="Vendor Office Name"
            text="Vendor Office Name"
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="vendor_officer_name"
            inputType="text"
            required={true}
            placeholder="Vendor Officer Name"
            text="Vendor Officer Name"
          />

          <InputField
            name="vendor_email"
            inputType="email"
            type="email"
            required={true}
            placeholder="Email"
            text="Email"
          />
        </FormFieldWrap>
        
        <FormFieldWrap>
          <InputNumber
            name="vendor_phone"
            inputType="phone"
            type="phone"
            required={true}
            placeholder="Phone*"
            text="Phone*"
          />

          {/**Requires Google Address*/}
          <InputLocation
            name="vendor_address"
            inputType="text"
            type="text"
            required={true}
            placeholder="Office Address*"
            text="Office Address*"
            formRef={formRef}
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="city"
            inputType="text"
            type="text"
            required={true}
            placeholder="City"
            text="City"
          />

          <InputField
            name="state"
            inputType="text"
            type="text"
            required={true}
            placeholder="State"
            text="State"
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="zipcode"
            inputType="number"
            type="number"
            required={true}
            placeholder="Zipcode"
            text="Zipcode"
          />
        </FormFieldWrap>

        <CTAWrap>
          {/* {!isAddNewVendor && (
            <Form.Item>
              <DangerButton
                type="primary"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteVendor(prefilledData.vendor_id)}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )} */}

          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default VendorForm
