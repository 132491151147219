import React from "react"
import { Form } from "@ui/antd"
import {
  InputFieldWrap,
} from "./styles"

const InputField = ({
  placeholder,
  type,
  name,
  required,
  style,
  customRules,
  inputType,
  showCount,
  maxLength,
  onChange,
  suffix,
  children,
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required, type: "email" }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required }]
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
    >
      {children ? (
        <InputFieldWrap
          // showCount={showCount}
          maxLength={maxLength}
          placeholder={placeholder}
          style={style}
          type={inputType}
          onChange={onChange}
          onPressEnter={() => { console.log("ASKDJkASJKDJSA") }}
        >
          {children}
        </InputFieldWrap>
      ) : (
        <InputFieldWrap
          // showCount={showCount}
          maxLength={maxLength}
          placeholder={placeholder}
          style={style}
          type={inputType}
          onChange={onChange}
        />
      )}
      
    </Form.Item>
  )
}

export default InputField
