import React, { useState, useEffect } from "react";
import OffersV2 from "container/OffersV2/index";
import TechAssets from "container/TechAssets";
import CheckboxList from "dumbComponents/OffersV3/DocusignDocs/Components/CheckboxList/index";
import { SectionWrap, SectionTitle, EnvelopeTitle } from "./styles";

const Attachments = ({
  completeOfferPackageToBeSent,
  createReceiverViewOffer,
}) => {

  const {
    envelopes: initialEnvelopes,
    proofOfFundsDocs: initialProofOfFundsDocs,
    coverLetters: initialCoverLetters,
    preQualified: initialPreQualified,
  } = completeOfferPackageToBeSent || {};

  const [attachments, setAttachments] = useState({
    envelopes: initialEnvelopes || [],
    proofOfFundsDocs: initialProofOfFundsDocs || [],
    coverLetters: initialCoverLetters || [],
    preQualified: initialPreQualified || [],
  });

  const OptionList2 = (section, item) => {
    const options = [
      {
        label: "VIEW",
        onClick: (section, id) => {
          const payload = {
            envelopeId: id,
          };
          createReceiverViewOffer(payload);
        },
      }
    ];

    // Conditionally add DELETE option for envelopes section
    if (section === "envelopes" && (attachments.envelopes.length > 0) && item.documents && item.documents.length > 1) {
      options.push({
        label: "DELETE",
        onClick: (section, id, documentId) => {
          deleteDocuments(section, id, documentId);
        },
      });
    } else if(section !=='envelopes') {
      options.push({
        label: "DELETE",
        onClick: (section, id, documentId) => {
          deleteDocuments(section, id, documentId);
        },
      });
    }

    return options;
  };
  const deleteDocuments = (section, id, documentId) => {
    const updatedAttachments = { ...attachments };
    if (updatedAttachments[section]) {
      updatedAttachments[section].map((item) => {
        if (item.id === id) {
          if (section === "envelopes" && item.documents) {
            item.documents = item.documents.filter((doc) => doc.id !== documentId);
                if (item.documents.length === 0) {
              updatedAttachments[section] = updatedAttachments[section].filter(
                (envelope) => envelope.id !== id
              );
            }
          } else {
            const indexToRemove = updatedAttachments[section].indexOf(item);
            if (indexToRemove !== -1) {
              updatedAttachments[section].splice(indexToRemove, 1);
            }
          }
        }
        return item;
      }).filter(Boolean);
      setAttachments(updatedAttachments);
    }

};
  const MainListV2 = [];

  return (
    <SectionWrap style={{ marginTop: "38px" }}>
      <SectionTitle>ATTACHMENTS</SectionTitle>

      {attachments.envelopes &&
        attachments.envelopes.map((item, iter) => (
          <div key={item.id}>
            {item.documents && item.documents.length > 0 && (
              <EnvelopeTitle>
                <p>{`ENVELOPE ${iter + 1}`}</p>
              </EnvelopeTitle>
            )}

            {item.documents &&
              item.documents.map((document) => (
                <CheckboxList
                  key={document.id}
                  list={document}
                  onClick={(e) => console.log("clicked", e.target.value)}
                  dropdownList={OptionList2("envelopes", item).map((option) => ({
                    ...option,
                    onClick: () =>
                      option.onClick("envelopes", item.id, document.id),
                  }))}
                  mainList={MainListV2}
                  hideCheckBox
                  img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
                />
              ))}
          </div>
        ))}

      {attachments.proofOfFundsDocs &&
        attachments.proofOfFundsDocs.map((item) => (
          <div key={item.id} style={ {marginTop:'20px'}}>
            <EnvelopeTitle>
              <p>PROOF OF FUNDS</p>
            </EnvelopeTitle>
            <CheckboxList
              list={item}
              onClick={(e) => console.log("clicked", e.target.value)}
              dropdownList={OptionList2("proofOfFundsDocs", item).map((option) => ({
                ...option,
                onClick: () => option.onClick("proofOfFundsDocs", item.id),
              }))}
              mainList={MainListV2}
              hideCheckBox
              img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
            />
          </div>
        ))}

      {attachments.coverLetters &&
        attachments.coverLetters.map((item) => (
          <div key={item.id}>
            <EnvelopeTitle>
              <p>COVER LETTERS</p>
            </EnvelopeTitle>
            <CheckboxList
              list={item}
              onClick={(e) => console.log("clicked", e.target.value)}
              dropdownList={OptionList2("coverLetters", item).map((option) => ({
                ...option,
                onClick: () => option.onClick("coverLetters", item.id),
              }))}
              mainList={MainListV2}
              hideCheckBox
              img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
            />
          </div>
        ))}

      {attachments.preQualified &&
        attachments.preQualified.map((item) => (
          <div key={item.id}>
            <EnvelopeTitle>
              <p>PRE APPROVED</p>
            </EnvelopeTitle>
            <CheckboxList
              list={item}
              onClick={(e) => console.log("clicked", e.target.value)}
              dropdownList={OptionList2("preQualified", item).map((option) => ({
                ...option,
                onClick: () => option.onClick("preQualified", item.id),
              }))}
              mainList={MainListV2}
              hideCheckBox
              img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
            />
          </div>
        ))}
    </SectionWrap>
  );
};

export default OffersV2(TechAssets(Attachments));
