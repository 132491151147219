import React from "react"
import Listing from "container/Listings"
import createToast from "dumbComponents/common/Toast/customToast"
import {
  Wrap,
  TabWrap,
  TabInfo,
  TabTitle,
} from "./styles"

const ListingTabs = ({
  currentActiveTab,
  steps,
  tabWidth,
  toggleTab,
  viewContractForListing,
  handleCollapseValues,
  viewListingModal,
  viewContract,
  listingData,
}) => (
  <Wrap>
    <TabWrap width={tabWidth}>
      {steps && steps.map(step => (
        <TabInfo
          disabled={step.isDisabled}
          onClick={() => {
            if (!listingData?.dealId) {
              if (step.value !== "cover_sheet") {
                createToast(`Please create the listing before moving to ${step.label?.toLowerCase()}.`, "error")
              }

              return
            }

            if (step.value === "contract_details") {
              //toggle modal here
              // viewContractForListing(true)
              viewContract()
            } else if (step.value === "cover_sheet") {
              viewListingModal({
                bool: true,
              })
              // handleCollapseValues(["property_information"])
              // toggleTab(step.value)
            } else {
              toggleTab(step.value)
            }
          }}
          isActive={currentActiveTab === step.value}
        >
          <TabTitle isActive={currentActiveTab === step.value}>
            {step.label}
          </TabTitle>
        </TabInfo>
      ))}
    </TabWrap>
  </Wrap>
)

export default Listing(ListingTabs)
