import React, { useRef, useEffect, useState } from "react"
import OffersV2 from "container/OffersV2"
import TechAssets from "container/TechAssets"
import createToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { AddButtonCTA } from "dumbComponents/OffersV3/components"
import { ShimmerBox } from "shared/styles/animation"
import { EyeIcon, ShareIcon } from "dumbComponents/OffersV3/icons"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import EnvelopesCard from "../../Envelopes/EnvelopeCard"
import Envelopes from "../../Envelopes/index"
import SignedDocumentsListing from "../../Envelopes/SignedDocumentsListing"
import {
  Header,
  HeaderWrap,
  LoaderWrap,
  Wrap,
  ProcessingWrap,
  ProcessingTitle,
  ShimmerWrap
} from "../styles"
import EmptyState from "./EmptyState"
import VoidReasonModal from "./VoidReasonModal"

const statusMap = {
  pending: {
    value: "pending",
    label: "Your Signature Pending",
  },
  sent: {
    value: "buyer_pending",
    label: "Buyer Signature Pending",
  },
  completed: {
    value: "completed",
    label: "Completed",
  },
  action_required: {
    value: "action_required",
    label: "Your Signature Pending",
  },
  active: {
    value: "active",
    label: "Active",
  },
  voided: {
    value: "void",
    label: "Voided",
  },
  declined: {
    value: "declined",
    label: "Declined",
  },
  sending: {
    value: "sending",
    label: "Sending",
  },
  processing: {
    value: "processing",
    label: "Processing",
  },
}

const FillAndSign = ({
  envelopes,
  sendForVoidAndRestartOffer,
  sendReminder,
  createEditViewOffer,
  downloadEnvelopeDocOffer,
  dealToView,
  createReceiverOfferViewV2,
  createReceiverOfferViewV2Response,
  fetchProofOfFunds,
  fetchCoverLetters,
  fetchPreApproved,
  fetchActiveEnvelopesFiles,
  fetchActiveEnvelopesFilesResponse,
  createReceiverViewOffer,
  sendForVoidAndDeleteOffer,
  getOfferDocumentsResponse,
  toogleVoidAndRestartOffer,
  toggleDocumentProcessing,
  isDocumentProcessing,
  proofOfFundsDocs,
  toggleAddPaperWorkModal,
}) => {
  const { isFetching } = fetchActiveEnvelopesFilesResponse || {}
  const { isFetching: fetchingDocs } = getOfferDocumentsResponse || {}
  const [isReasonModalVisible, setIsReasonModalVisible] = useState(false)
  const [modalEnvelopeId, setModalEnvelopeId] = useState(null)

  useEffect(() => {
    fetchProofOfFunds({
      id: dealToView,
      type: "proof_of_funds",
    })
    fetchCoverLetters({
      id: dealToView,
      type: "cover_letter",
    })
    fetchPreApproved({
      id: dealToView,
      type: "pre_qualification",
    })
    fetchActiveEnvelopesFiles({
      payload: {
        offerId: dealToView,
      },
    })

    toggleDocumentProcessing(false)
  }, [])
  const textRef = useRef("")
  const { isFetching: creatingReceiverView } = createReceiverOfferViewV2Response || {}
  const getDropDownMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return ACTION_REQUIRED.dropDownMenu
      case "sent":
      case "decline":
        return NON_COMPLETED_OPTION_LIST.dropDownMenu
      case "completed":
        return COMPLETED.dropDownMenu
      default:
        return []
    }
  }

  const handleVoidAndRestart = (id) => {
    // Modal.confirm({
    //   title: "Please confirm the action.",
    //   icon: <ExclamationCircleOutlined />,
    //   content: <Confirmation
    //     updateNote={(val) => {
    //       textRef.current = val
    //     }}
    //   />,
    //   onOk() {
    //     const payload = [{ envelopeId: id, reason: textRef.current }]
    //     createToast("Voiding and duplicating...")
    //     sendForVoidAndRestartOffer({
    //       payload,
    //       callback: () => {
    //         fetchActiveEnvelopesFiles({
    //           payload: {
    //             offerId: dealToView,
    //           },
    //         })
    //       },
    //     })
    //   },

    //   onCancel() {
    //     return false
    //   },
    // })
    toogleVoidAndRestartOffer(id)
  }

  const handleViewEnvelope = (id) => {
    createToast("Opening Document.")
    const payload = {
      envelopeId: id,
    }

    createReceiverViewOffer(payload)
  }

  const handleSendReminder = (id) => {
    const payload = {
      envelopeId: id,
    }
    sendReminder({
      payload,
    })
    createToast("Reminder Sent.")
    // alert("TOAST REQUIRED, REMINDER SENT")
  }

  const handleVoidDocument = (id) => {
    setIsReasonModalVisible(true)
    setModalEnvelopeId(id)
  }

  const handleEditDocument = (id) => {
    createToast("Opening Edit View.")
    createEditViewOffer({
      payload: {
        envelopeId: id,
      },
    })
  }

  const handleDownload = (id) => {
    createToast("Downloading...")
    const matchingEnvelope = envelopes.find(envelope => envelope.envelope_id === id)
    downloadEnvelopeDocOffer({
      payload: {
        envelopeId: id,
      },
      documentDetail: {
        name: `Offer_${matchingEnvelope?.title}`,
      },
    })
  }

  const NON_COMPLETED_OPTION_LIST = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
      {
        label: "SEND REMINDER",
        image: <ShareIcon />,
        onClick: id => handleSendReminder(id),
        showDivide: false,
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "VOID",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const ACTION_REQUIRED = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "EDIT",
        onClick: id => handleEditDocument(id),
      },
      {
        label: "DOWNLOAD",
        onClick: id => handleDownload(id),
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "VOID",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const COMPLETED = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
      {
        label: "DOWNLOAD",
        onClick: id => handleDownload(id),
        showDivide: true,
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
        showDivide: false,
      },
    ],
  }

  const getViewMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return []
      case "sent":
      case "decline":
        return []
      case "completed":
        return []
      default:
        return []
    }
  }

  const handleFillAndSign = (envelopeId) => {
    toggleDocumentProcessing(true)
    const payload = {
      envelopeId,
    }
    createReceiverOfferViewV2({
      payload,
    })
  }

  return (
    <>
      <Wrap isBg>
        <HeaderWrap>
          <Header>ENVELOPES</Header>
       <AddButtonCTA
          name="Add Pre-signed Paperwork"
          onClick={(e) => {
            e.stopPropagation()
            toggleAddPaperWorkModal(true)
          }}
        />
      </HeaderWrap>
      {(isFetching) ? (
        <ShimmerWrap>
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
        </ShimmerWrap>
        ) : (
        <>
          <Envelopes />
          {envelopes && envelopes.length > 0 ? (
            <>
              {envelopes && envelopes.map(item => (
                <EnvelopesCard
                  dropdownList={getDropDownMenu(item.status)}
                  mainList={getViewMenu(item.status)}
                  docs={item.merged_envelopes}
                  status={statusMap[item.status]}
                  id={item.envelope_id}
                  data={item.created_at_epoch}
                  fillAndSign={handleFillAndSign}
                  creatingReceiverView={creatingReceiverView}
                  title={item.title}
                  key={item.envelope_id}
                />
              ))}
            </>
            ) : (
              <EmptyState
                emptyHeader="No envelopes added"
                emptyDescription="Stay on track with Envelopes"
              />
            )}
          </>
        )}

        {isReasonModalVisible && (
          <VoidReasonModal
            isVisible={isReasonModalVisible}
            onCancel={() => setIsReasonModalVisible(false)}
            onOk={() => setIsReasonModalVisible(false)}
            id={modalEnvelopeId} // Pass the envelope ID
          />
        )}
      </Wrap>
    </>
  )
}

export default OffersV2(TechAssets(FillAndSign))
