import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Container from "container/Navbar"
import cookie from "services/CookieStorage"
import colors from "@colors"

//STYLED block

const BannerWrap = styled.div`
  width: 100%;
  height: 60px;
  background: ${colors.strawberryPink};
  display: none;
  ${props => props.show && `
    display: block;
    position: fixed;
    top: 0px;
    padding: 14px;
    z-index: 999;
   `}
  ${props => (props.noSubscriptions || props.isSchedule) && `
      background: ${colors.shallowGrey};
  `}

  ${props => props.isSchedule && `
    color: ${colors.contentText};
  `}
  display: none;
`

const BannerText = styled.p`
   font-family: 'DM Sans', sans-serif;
   font-size: 12px;
   color: ${colors.white};
   text-align: center;
   ${props => props.noSubscriptions && `
       color: ${colors.contentText};
    `}
 `
const CTA = styled.button`
 background: transparent;
 border: none;
 display: block;
 width: 100px;
 position: relative;
 bottom: 29px;
 float: right;
 color: ${colors.white};
 font-size: 20px;
 cursor: pointer;
 ${props => props.noSubscriptions && `
     color: ${colors.contentText};
  `}
`

const AnchorLink = styled.a`
   color: ${colors.white};
   ${props => props.noSubscriptions && `
       color: ${colors.contentText};
    `}

    ${props => props.isLink && `
      color: ${colors.primaryBorderColor};
    `}
`

const RedirectButton = styled.button`
  color: white;
  ${props => props.noSubscriptions && `
      color: ${colors.contentText};
   `}
   border: none;
   background: transparent;
   font-size: 12px;
   margin-left: -9px;
   margin-right: -9px;
   cursor: pointer;
   text-decoration: underline;
`

//STYLED block

class RadiusBanners extends React.PureComponent {
  componentDidMount() {
    const { fetchSubscriptions } = this.props
    fetchSubscriptions()
  }

  triggerInterestAndRedirect = () => {
    const {
      triggerInterest,
      history: {
        push,
      },
    } = this.props

    triggerInterest({ source: "headerBanner" })
    push("/radius-assist?isNoRedirection=true")
  }

  getMessage = () => {
    const { isCardInactive, scheduleCall } = this.props
    const { isShow, scheduleLink } = scheduleCall
    if (isShow) {
      return (
        <BannerText noSubscriptions>
          Interested in learning more about our lead qualification service? &nbsp;
          <AnchorLink
            href={scheduleLink}
            target="_blank"
            noSubscriptions
            isLink
          >
             Schedule a call
          </AnchorLink>
        </BannerText>
      )
    }
    if (isCardInactive) {
      return (
        <BannerText>
          Oh no! Your Radius Assist account has been paused due to non-payment. Please make sure to
          <AnchorLink
            href="/settings/billing"
            target="_blank"
          >
             &nbsp; update your billing information &nbsp;
          </AnchorLink>
          so that you can continue to submit and accept new leads.
        </BannerText>
      )
    }
    return (
      <BannerText
        noSubscriptions
      >
        Need help finding motivated leads? We’ve got you covered.
        <RedirectButton
          noSubscriptions
          onClick={() => this.triggerInterestAndRedirect()}
        >
           &nbsp; Learn more &nbsp;
        </RedirectButton>
        about Radius Assist.
      </BannerText>
    )
  }

  render() {
    const {
      isCardInactive,
      toggleHeaderBanner,
      categorizedSubscriptions,
      isHeaderBannerVisible,
      isAppMounted,
      finishedFetchingSubscriptions,
      scheduleCall,
    } = this.props
    const { isShow: isShowScheduleACallBanner } = scheduleCall
    const noSubscriptions = (Object.keys(categorizedSubscriptions).length <= 0
     && isHeaderBannerVisible
     && !cookie.load("bannerClosed")
     && finishedFetchingSubscriptions
    )

    return (
      <BannerWrap
        show={((isCardInactive || noSubscriptions || isShowScheduleACallBanner) && isAppMounted)}
        noSubscriptions={noSubscriptions}
        isSchedule={isShowScheduleACallBanner}
      >
        {this.getMessage()}
        <CTA
          noSubscriptions={noSubscriptions || isShowScheduleACallBanner}
          onClick={() => toggleHeaderBanner()}
        >
          X
        </CTA>
      </BannerWrap>
    )
  }
}

export default withRouter(Container(RadiusBanners))
