import axios from "axios"

const key = "aaa"
const secret = "aaa"

const getBase64String = () => {
  try {
    const concatString = `${key}:${secret}`
    const b64Enc = btoa(concatString)
    return `Basic ${b64Enc}`
  } catch (e) {
    console.error("ERR :", e)
  }
}

export const createTokenAPI = ({ payload }) => axios.post(`${API_URL}radius-club/event-room-token`, payload)
export const getRoomsAPI = (q = "") => axios.get(`${API_URL}radius-club/event-rooms${q}`)
export const joinRoomAPI = ({ roomId, payload }) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/join`, payload)
export const createRoomAPI = ({ payload }) => axios.post(`${API_URL}radius-club/event-room`, payload)
export const leaveRoomAPI = roomId => axios.delete(`${API_URL}radius-club/event-room/${roomId}/leave`)
export const getSuggestedUsersAPI = () => axios.get(`${API_URL}radius-club/event-room-agent/suggested-users-to-invite`)
export const sendUserInvitationAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/invite`, payload)
export const getRoomMembersAPI = roomId => axios.get(`${API_URL}radius-club/event-room/${roomId}/members`)
export const updateRoomAPI = (roomId, payload) => axios.put(`${API_URL}radius-club/event-room/${roomId}`, payload)
export const muteUserAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/mute-user`, payload)
export const kickUserOutAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/kick-out-user`, payload)
export const becomeSpeakerAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/become-speaker`, payload)
export const speakerRequestAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/approve-or-deny-speaker-request`, payload)
export const getUserDetailsAPI = agentId => axios
  .get(`${API_V2_URL}user/info/agentId?=${agentId}`)

export const roomReportReasonsAPI = () => axios.get(`${API_URL}event-room-report-keys`)
export const roomReportUserAPI = payload => axios.post(`${API_URL}event-room-report-user`, payload)
export const getTagsAPI = (q = "") => axios.get(`${API_URL}radius-club/tags${q}`)

export const createScheduleRoomAPI = payload => axios.post(`${API_URL}radius-club/scheduled-event-room`, payload)
export const attendeeStatusAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/attendee-status`, payload)

//heartBeatApi
export const userHeartBeatAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/host-live-status`, payload)

//hostTransferApis
export const hostTransferRequestAPI = payload => axios
  .post(`${API_URL}radius-club/event-rooms/host-transfer-request`, payload)
export const hostTransferResponseAPI = payload => axios
  .post(`${API_URL}radius-club/event-rooms/host-transfer-response`, payload)

//pin
export const toggleRemotePinningAPI = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/pin-user`, payload)

//screen-share
export const joinRoomAsUniqueIDApi = (roomId, payload) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/join-as-screen-share`, payload)

export const leaveRoomUsingUniqueIDApi = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/leave-room-as-screen-share`, payload)

//scheduled rooms
export const notifyForScheduledRoomsAPI = id => axios
  .post(`${API_URL}radius-club/scheduled-event-rooms-notify-me/${id}`)
export const getScheduledRoomsAPI = payload => axios
  .get(`${API_URL}radius-club/scheduled-event-rooms?${payload}`)

//invite bulk
export const bulkInviteAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/invite`, payload)

//update scheduled rooms
export const updateScheduledRoomsAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/scheduled-event-room/${roomId}`, payload)

//get default images
export const getDefaultImagesAPI = () => axios
  .get(`${API_URL}radius-club/events-rooms/images`)

//cancel scheduled rooms
export const cancelScheduledRoomsAPI = id => axios
  .post(`${API_URL}radius-club/remove-scheduled-event-room/${id}`)

export const getRoomsTopics = () => axios
  .get(`${API_URL}radius-club/event-topics`)

export const getPromotedRooms = () => axios
  .get(`${API_URL}radius-club/landing-page/promoted-rooms`)

export const getRoomTopicEvents = () => axios
  .get(`${API_URL}radius-club/landing-page-events`)

export const getPreRecordedRoomsContentAPI = id => axios
  .get(`${API_URL}shiksha/course/${id}`)

//rooms recording
export const acquireRoomsRecordingCapabitiesAPI = payload => axios
  .post(`${API_URL}radius-club/agora/acquire-resource`, payload)

export const deleteScheduledRoomsAPI = (id, payload) => axios
  .post(`${API_URL}radius-club/remove-scheduled-event-room/${id}`, payload)

export const joinRoomAsScreenRecordAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/join-as-screen-recording`, payload)

export const leaveRoomAsScreenRecordAPI = (roomId, payload) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/leave-room-as-screen-recording`, payload)

//start recording session with 3rd party Agora Api
export const startRoomsRecordAPI = (resourceId, payload) => axios
  .post(`${API_URL}radius-club/agora/start-recording/${resourceId}`, payload)

export const stopRoomsRecordAPI = (resourceId, sid, payload) => axios
  .post(`${API_URL}radius-club/agora/stop-recording/${resourceId}/${sid}`, payload)

export const updateRecordingLayoutAPI = (resourceId, sid, payload) => axios
  .post(`${API_URL}radius-club/agora/update-layout/${resourceId}/${sid}`, payload)

export const updateSubscriptionAPI = (resourceId, sid, payload) => axios
  .post(`${API_URL}radius-club/agora/update-recording/${resourceId}/${sid}`, payload)

//meta data api
export const getRoomsMetaDataConfigAPI = () => axios
  .get(`${API_URL}radius-club/event-rooms/rooms-metadata`)

export const getPromotedRecommendedRoomsAPI = () => axios
  .get(`${API_URL}shiksha/courses/get-promoted-courses`)
