import axios from "axios"

// export const getPropertyListAPI = (id, bundleId) => axios
//   .get(`${API_URL}property/status/${id}?bundleId=${bundleId}`)

export const getAllPimAPI = () => axios
.get(`${API_V2_URL}product-interest-modals`)

export const postCallAPI = url => axios
.post(url, {})
