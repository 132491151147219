import React from "react"
import { reduxForm } from "redux-form"
import SearchIcon from "images/icons/search-icon.svg"
import Box from "@ui/Box"
import {
  Wrapper,
  SearchInputWrap,
  TagsWrap,
  Tag,
  Footer,
  Header,
  SaveButton,
} from "./commonStyles"

class Designations extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues } = this.props
    this.state = {
      tagList: initialValues,
      search: "",
      isChanged: false,
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  addItem = (item) => {
    const { tagList } = this.state
    if (item) {
      this.setState({
        tagList: [...tagList, item],
        search: "",
        isChanged: true,
      })
    }
  }

  handleSave = () => {
    const { saveProfileUpdate } = this.props
    const { tagList } = this.state
    saveProfileUpdate({
      designations: [...tagList],
    })
  }

  removeItem = (item) => {
    const { tagList = [] } = this.state
    const itemIndex = tagList.indexOf(item)
    tagList.splice(itemIndex, 1)
    this.setState({
      tagList: [...tagList],
      isChanged: true,
    })
  }

  render() {
    const {
      handleSubmit,
      isSavingUpdates,
      preFetchedData: {
        designations: preFetchedDesignations = [],
        certifications = [],
      } = {},
    } = this.props
    const { search, tagList, isChanged } = this.state
    const filteredDesignations = [...certifications, ...preFetchedDesignations]
      .filter(item => item.toLowerCase().indexOf(search) !== -1 && tagList.indexOf(item) === -1)
    return (
      <Wrapper>
        <Header>
          <h4>What Designations do you belong to?</h4>
        </Header>
        <Box d="flex" p="15px">
          <SearchInputWrap onSubmit={handleSubmit} flex="1">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              name="search"
              component="input"
              type="text"
              placeholder="Seach Designations"
              value={search}
              onChange={this.handleInput}
            />
          </SearchInputWrap>
          {/* <StyledButton
            bsStyle="secondary"
            width="95"
            onClick={() => { this.addItem() }}
          >
            Add +
          </StyledButton> */}
        </Box>
        <TagsWrap>
          {tagList.map(item => (
            <Tag
              key={item}
              title={item}
              handleClick={this.removeItem}
            />
          ))}
          {filteredDesignations.map(item => (
            <Tag
              addable
              key={item}
              title={item}
              handleClick={() => { this.addItem(item) }}
            />
          ))}
        </TagsWrap>
        <Footer d="flex" jc="flex-end">
          <SaveButton
            width="100px"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSave}
            disabled={!isChanged}
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "PROFILE_LANGUAGES_FORM",
})(Designations)

