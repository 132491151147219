import React from "react"
import { toast } from "react-toastify"
import colors from "@colors"
import {
  closeIcon,
  checkIcon,
  infoIcon,
} from "dumbComponents/common/Icons"
import {
  Wrap,
  ToastWrapper,
  StyledText,
  StyledCloseIcon,
  StyledStatusIcon,
  RetryButtonWrap,
  RetryButton,
} from "./styles"
import "./toast.css"

const toastOptions = {
  closeButton: false,
  hideProgressBar: true,
  position: "top-right",
}

const toastIconMap = {
  success: checkIcon,
  error: infoIcon,
}

const CustomToast = ({
  closeToast,
  toastText,
  toastType,
  style,
  onClick,
}) => (
  <Wrap>
    <ToastWrapper>
      <StyledStatusIcon
        src={toastIconMap[toastType]}
      />
      <StyledText style={style}>
        {toastText}
      </StyledText>
      {toastType === "success" && (
        <StyledCloseIcon
          onClick={closeToast}
          src={closeIcon}
        />
      )}
      {/* {toastType === "error" && (
        <StyledCloseIcon
          onClick={closeToast}
          src={closeIcon}
        />
      )} */}
    </ToastWrapper>
  </Wrap>
)

const createToast = (toastText, toastType = "success", onClick) => {
  switch (toastType) {
    case "success": {
      toast(<CustomToast toastText={toastText} toastType={toastType} />, toastOptions)
      break
    }
    case "error": {
      toast.error(
        <CustomToast
          style={{ color: colors.white }}
          toastText={toastText}
          toastType={toastType}
          onClick={onClick}
        />, toastOptions
      )
      break
    }
    default:
      toast(<CustomToast toastText={toastText} toastType={toastType} />, toastOptions)
  }
}

export default createToast
