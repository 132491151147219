/* eslint-disable max-len */
import React from "react"

const MoreInfo = () => (
  <svg fill="none">
    <path d="M22.2699 4.47055C20.4826 2.68322 18.2054 1.46603 15.7263 0.972894C13.2473 0.479759 10.6777 0.732829 8.34242 1.7001C6.00719 2.66738 4.01123 4.30541 2.60694 6.40705C1.20266 8.5087 0.453125 10.9796 0.453125 13.5072C0.453125 16.0348 1.20266 18.5057 2.60694 20.6074C4.01123 22.709 6.00719 24.347 8.34242 25.3143C10.6777 26.2816 13.2473 26.5347 15.7263 26.0415C18.2054 25.5484 20.4826 24.3312 22.2699 22.5439C23.4605 21.3594 24.4054 19.9513 25.0501 18.4005C25.6948 16.8496 26.0267 15.1867 26.0267 13.5072C26.0267 11.8277 25.6948 10.1648 25.0501 8.61396C24.4054 7.06315 23.4605 5.65504 22.2699 4.47055ZM14.9368 20.2972H11.5271V11.8624H14.9368V20.2972ZM13.2319 10.4413C12.8623 10.4413 12.5009 10.3317 12.1936 10.1262C11.8863 9.92083 11.6468 9.62887 11.5055 9.28731C11.3641 8.94576 11.3273 8.56995 11.3996 8.20744C11.4719 7.84493 11.6501 7.51202 11.9116 7.25082C12.1732 6.98961 12.5064 6.81187 12.869 6.74006C13.2316 6.66826 13.6073 6.70562 13.9487 6.84743C14.2901 6.98924 14.5817 7.22912 14.7867 7.53671C14.9917 7.84431 15.1008 8.2058 15.1003 8.57545C15.1003 8.82069 15.052 9.06353 14.9581 9.29007C14.8641 9.51662 14.7265 9.72242 14.5529 9.89572C14.3794 10.069 14.1734 10.2064 13.9467 10.3C13.7201 10.3936 13.4772 10.4416 13.2319 10.4413Z" fill="white"/>
  </svg>
)

export default MoreInfo
