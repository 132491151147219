import React from "react"
import { reduxForm, Field } from "redux-form"
import styled from "styled-components"
import {
  required,
  email,
  normalizePhone,
  minLength,
} from "services/formUtils"
import { ATC, INTER } from "@fonts"
import colors from "@colors"
import Button from "@ui/Button"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Input,
  PhoneInput,
  HiddenField,
} from "@ui/Form"
import { BlueButton } from "../commonStyles"

const minLength10 = minLength(10)

const Wrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    color: ${colors.black};
  }
`
const InnerWrap = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`

const Form = (props) => {
  const {
    handleSubmit,
    isSubmitting,
    isWatchPage,
    inForm,
    className,
  } = props || {}

  let LABEL_STYLES = {
    color: colors.black,
  }

  if (isWatchPage) {
    LABEL_STYLES = {
      ...LABEL_STYLES,
      backgroundColor: "#F9F9F7",
    }
  }

  const staging = process.env.NODE_ENV !== "production"

  return (
    <Wrap
      onSubmit={handleSubmit}
      className={className}
    >
      <InnerWrap>
        <Field
          component={HiddenField}
          name="unique_form_submitted"
          label=""
          placeholder="join_our_community_form"
          hidden
          readOnly
          value="join_our_community_form"
        />
        <Field
          component={Input}
          validate={[required, email]}
          name="email"
          label=""
          placeholder="Email address"
          labelStyles={{
            ...LABEL_STYLES,
          }}
          isLandingPage
          errorPos="15px"
          customInputStyle={{
            paddingLeft: 10,
            borderRadius: "8px",
            marginTop: 5,
            fontFamily: INTER,
            letterSpacing: "0.5px",
            border: "1px solid #A5A8FF",
            //backgroundColor: `${footer ? "transparent" : "white"}`,
            //color: `${footer ? "#fff" : "#000"}`,
            // height: "22px",
            //padding: "8px 91px 5px 16px",
            height: "45px",
          }}
          required
        />
        <Field
          component={PhoneInput}
          validate={[minLength10, required]}
          normalize={normalizePhone}
          name="phone"
          placeholder="Phone Number"
          isShowAreaCode={false}
          inputStyle={{
            paddingLeft: 10,
            borderRadius: "8px",
            letterSpacing: "0.5px",
            marginTop: 5,
            fontFamily: INTER,
            border: "1px solid #A5A8FF",
            height: "45px",
            //backgroundColor: `${footer ? "transparent" : "white"}`,
            //color: `${footer ? "#fff" : "#000"}`,
            // height: "22px",
            //padding: "8px 91px 5px 16px",
          }}
          errorPos="15px"
          isLandingPage
          labelStyles={{
            ...LABEL_STYLES,
          }}
          isMaterial={false}
        />
      </InnerWrap>
      <InnerWrap>
        <BlueButton
          type="submit"
          disabled={isSubmitting}
          height="32px"
          style={{
            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
            width: inForm ? "122px" : "180px",
          }}
          inForm={inForm}
        >
          {isSubmitting ? (
            <ThreeDotLoader size={10} color={colors.white} />
          ) : (
            "Submit"
          )}
        </BlueButton>
      </InnerWrap>
    </Wrap>
  )
}

const AppForm = reduxForm({
  form: "APP_FORM_V2",
  destroyOnUnmount: true,
})(Form)

export default AppForm
