import React from "react"
import OffersV2 from "container/OffersV2"
import root from "window-or-global"
import TechAssets from "container/TechAssets"
import {
  TransparentButton,
} from "dumbComponents/OffersV2/commonStyles"
import { EyeIcon } from "dumbComponents/OffersV2/icons"
import {
  Wrap,
  DocWrap,
  DocTitle,
  DocContent,
  DateContent,
  OfferPackageTitle,
} from "./style"

const OfferPackageCard = ({
  docs,
  title,
  date,
  createReceiverViewOffer,
  id,
  attachments,
}) => (
  <Wrap>
    <OfferPackageTitle>
      <p>{title}</p>
      <DateContent>
        {`Package sent on ${date.slice(0, 10)}`}
      </DateContent>
    </OfferPackageTitle>
    {/* {responseCount && responseCount > 0 && (
      <ResponseTag
        responseCount={responseCount}
      />
    )} */}
    {console.log(docs, "here")}
    <DocContent>
      {docs && docs.map(doc => (
        <DocWrap>
          <DocTitle>
            {doc.name}
          </DocTitle>
          <TransparentButton
            onClick={() => {
              createReceiverViewOffer({
                envelopeId: id,
              })
            }}
          >
            <EyeIcon />
            <p>VIEW</p>
          </TransparentButton>
        </DocWrap>
      ))}
      {attachments && attachments.map(doc => (
        <DocWrap>
          <DocTitle>
            {doc.title}
          </DocTitle>
          <TransparentButton
            onClick={() => {
              root.open(doc.url, "_blank")
            }}
          >
            <EyeIcon />
            <p>VIEW</p>
          </TransparentButton>
        </DocWrap>
      ))}
    </DocContent>
  </Wrap>
)

export default OffersV2(TechAssets(OfferPackageCard))
