/* eslint-disable max-len */
/* eslint-disable quotes */
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import NeighbourhoodImage from "images/Graphics/ListingVideosAssets/NeighbourhoodGuideNew.png"
import PropertyTour from "images/Graphics/ListingVideosAssets/PropertyTour.png"
import MarketUpdate from "images/Graphics/ListingVideosAssets/MarketUpdate.png"
import TopicVideo from "images/Graphics/ListingVideosAssets/TopicVideo.png"
import AgentBio from "images/Graphics/ListingVideosAssets/AgentBioNew.jpg"
import BeforeAndAfter from "images/Graphics/ListingVideosAssets/BeforeAndAfterNew3.png"

import * as MarketingAction from "./actions"

const initState = {
  currentVideo: null,
  isVideoModalOpen: false,
  marketingVideos: [
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Property+Tour.mp4",
      name: "somevideo",
      image: PropertyTour,
    },
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Market+Update.mp4",
      name: "somevideo",
      image: MarketUpdate,
    },
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Topic+Video.mp4",
      name: "somevideo",
      image: TopicVideo,
    },
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Agent+Bio.mp4",
      name: "somevideo",
      image: AgentBio,
    },
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Neighborhood+Guide.mp4",
      name: "somevideo",
      image: NeighbourhoodImage,
    },
    {
      url: "https://s3.amazonaws.com/cdn.agentdesks.com/video/Rollo/Radius+Before+vs+After.mp4",
      name: "somevideo",
      image: BeforeAndAfter,
    },
  ],
}

export default function marketingTabReducer(state = initState, action) {
  switch (action.type) {
    case MarketingAction.toggleVideoModalAction.type: {
      const {
        bool,
        source,
        videoImage,
      } = action.data

      return {
        ...state,
        currentVideoImage: videoImage,
        currentVideo: source,
        isVideoModalOpen: bool,
      }
    }

    default: {
      return state
    }
  }
}
