import axios from "axios"

export const createClientAPI = payload => axios.post(`${API_V2_URL}clients`, payload)

export const createLeadAPI = payload => axios.post(`${API_URL}refer/lead`, payload)

export const referAClientAPI = payload => axios.post(`${API_URL}crs/refer`, payload)

export const getClientSearchAPI = payload => axios
  .post(`${API_V2_URL}clients/search`, payload)

export const directReferralAPI = payload => axios.post(`${API_URL}refer/direct-lead`, payload)

export const validateIfClientExistsAPI = payload => axios.post(`${API_V2_URL}upload/referrals/validate`, payload)

