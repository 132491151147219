import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import LazyLoad from "react-lazyload"
import {
  tablet,
  xsMobile,
  minDesktopWidth,
  preDesktopWidth,
  preTabletWidth,
  minLargeDesktopWidth,
} from "@consts"
import colors from "@colors"
import RadiusEdge from "images/Graphics/RadiusForTeams/TalentImageWeb.png?q=100"
import RadiusEdgeMobile from "images/Graphics/RadiusForTeams/TalentImageMobile.png?q=100"
import AgentIcon from "images/Graphics/RadiusForTeams/Talent1.svg?q=100"
import IncomeIcon from "images/Graphics/RadiusForTeams/Talent2.svg?q=100"
import MentorsIcon from "images/Graphics/RadiusForTeams/Talent3.svg?q=100"
import SocialMediaIcon from "images/Graphics/RadiusForTeams/Talent4.svg?q=100"

const Wrap = styled.div`
  padding: 0px 72px;
  ${minDesktopWidth`
    padding: 0px 52px;
  `}
  ${preDesktopWidth`
    padding: 0px 60px;
  `}
  ${preTabletWidth`
    padding: 0px 0px;
  `}
`
const Heading = styled.h1`
  font-family: Butler;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  width: 632px;
  margin: auto;
  color: ${colors.black};

  ${minDesktopWidth`
    font-size: 42px;
  `}

  ${minLargeDesktopWidth`
    font-size: 42px;
  `}

  ${preDesktopWidth`
    width: 549px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.01em;
    text-align: center;
  `}
  ${preTabletWidth`
    width: 327px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.005em;
    text-align: center;
  `}
`

const HeadContainer = styled.div`
  color: ${colors.black};
`

const Description = styled.p`
  font-family: ATC;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  // width: 65%;
  width: 632px;
  margin: auto;
  margin-top: 16px;
  color: ${colors.black};
  ${preDesktopWidth`
    width: 435px;
    font-size: 16px;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  `}
  ${preTabletWidth`
    width: 327px;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  `}
`

const TalentContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 192px 0px 0px 0px;
  ${tablet`
    padding: 128px 0px 0px 0px;
  `}
  ${xsMobile`
    padding: 72px 0px 0px 0px;
  `}
  color: #303030;
`

const ImageWrap = styled.div`
  // margin-top: 40px;
  text-align: center;
  img {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    transform: scale(1.02);
  }
  ${xsMobile`
    margin-top: 20px;
    img {
      transform: scale(1.6);
      position: relative;
      margin-top: 50px;
    }
  `}
`
const SectionInfo = styled.div`
  display: flex;
  padding: 100px 0px 0px 0px;
  justify-content: space-evenly;

  ${minDesktopWidth`
    gap: 50px;
  `}

  ${preDesktopWidth`
    padding: 72px 0px;
    flex-wrap: wrap;
  `}
`
const SectionWrapper = styled.div`
  text-align: center;
  flex: 1;

  @media(max-width: 1099px) {
    flex: 1 1 50%;
    ${props => props.margin && `
      margin-top: 15px;
    `}
  }
  ${xsMobile`
    padding: 24px;
    flex: 1 1 100%;
  `}
  img {
    width: 96px;
    height: 96px;
  }
  

`

const Text = styled.h6`
  font-family: ATC;
  font-size: 18px;
  line-height: 26px;
  padding: 8px 30px;
  margin-top: 0px;
  font-weight: 300;
  color: ${colors.black};

  ${minDesktopWidth`
    padding: 8px 0px;
    font-size: 18px;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    width: 278px;
  `}

  ${preDesktopWidth`
    font-family: ATC;
    font-size: 16px;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    padding: 8px 15px;
  `}

  ${preTabletWidth`
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  `}
`

const SECTION_INFO_LIST = [{
  icon: AgentIcon,
  text: "We’ll screen and select agents that match your team.",
}, {
  icon: SocialMediaIcon,
  text: "We manage social media and create recruiting assets to attract a wider audience of recruits.",
}, {
  icon: MentorsIcon,
  text: "We pair you with seasoned mentors so that you can create effective team strategies.",
}, {
  icon: IncomeIcon,
  text: "We allow you to earn more income since you keep 100% of commissions while charging your agents an override ",
}]

const TalentSection = ({ screenWidth }) => (
  <Wrap>
    <TalentContainerWrap>
      <HeadContainer>
        <Heading>
          We’ll help you find the best talent for your team.
        </Heading>
        <Description>
          Save your time for selling properties and let us find agents that make a great match for your team.
        </Description>
      </HeadContainer>
      <ImageWrap>
        <LazyLoad>
          {screenWidth <= 500 ? (<img src={RadiusEdgeMobile} alt="radius-financing" />) : (<img src={RadiusEdge} alt="radius-financing" />)}
        </LazyLoad>
      </ImageWrap>
      <SectionInfo>
        {SECTION_INFO_LIST.map((detail, index) => (
          <SectionWrapper margin={index > 1}>
            <img src={detail.icon} alt="radius-talent-section-info-icons" />
            <Text>{detail.text}</Text>
          </SectionWrapper>
        ))}
      </SectionInfo>
    </TalentContainerWrap>
  </Wrap>
)

export default withRouter(TalentSection)
