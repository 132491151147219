import React, { useEffect } from "react"
import styled from "styled-components"
import colors from "@colors"
import PubNubForDocusign from "dumbComponents/Listings/pages/Docusign/PubNubForDocusign"
import { LeftOutlined, GoogleOutlined } from "@ant-design/icons"
import { ATC } from "@fonts"
import { checkForUserObject } from "services/Utils"
import TCContainer from "container/Realty/TransactionFormContainer"
import { get } from "lodash"

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
  border: none;
`}
`

const H1 = styled.h1`
  font-family: ${ATC};
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;  
`

const IntegrationWarpper = styled.h1`
  font-family: ${ATC};
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  color: ${colors.black};
  cursor: pointer;
  display: flex;
  span {
    margin-left: 5px;
  }
`

const SubTextWrap = styled.div``

const SubText = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 378px;
  margin-top: 30px;
`

const ReturnPara = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #303030;
  display: flex;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`

const Header = ({ isFormPage, history }) => (
  <>
    <PubNubForDocusign />
    {isFormPage ? (
      <HeaderWrap noborder>
        <ReturnPara onClick={() => { history.push("/realty/transaction-dashboard") }}>
          <LeftOutlined />
          <span>Return to All Listings</span>
        </ReturnPara>
        {false && isFormPage && (
          <IntegrationWarpper onClick={() => { history.push("/realty/listings/coversheet/integrations") }}>
            Sync Your Gmail
            <GoogleOutlined />
          </IntegrationWarpper>
        )}
      </HeaderWrap>
    ) : (
      <>
        {/* <HeaderWrap>
          <H1>Listing Submissions</H1>
          <IntegrationWarpper onClick={() => { history.push("/realty/listings/coversheet/integrations?from_header=true") }}>
            Sync Your Gmail
            <GoogleOutlined />
          </IntegrationWarpper>
        </HeaderWrap> */}
        <SubTextWrap>
          {/* <SubText>
        Add new files, see submitted files and active listings
      </SubText> */}
          </SubTextWrap>
      </>
    )}
  </>
)

export default TCContainer(Header)
