import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import CRMV2Container from "container/CRM"
import TechAssetsContainer from "container/TechAssets"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import { CONTRACT } from "../../common"

const Wrap = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 40px 30px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 20px 20px 30px 20px;
  flex-direction: row-reverse;
`

const IncompleteToClosed = ({
  setDealToView,
  setIsClosed,
  transactionType,
  dealId,
  history,
  getBrokerageTransactionStatusResponse,
}) => {
  const {
    data: brokerageTransactionStatus,
  } = getBrokerageTransactionStatusResponse || {}

  // const {
  //   initiateTransaction,
  // } = brokerageTransactionStatus || {}

  const {
    initiateTransaction,
    id: brokerageId,
    status: brokerageStatus,
    transactionType: brokerageTransactionType,
  } = brokerageTransactionStatus || {}

  const urlToRedirect = `/realty/transaction-coordination/contract/basic-info?deal=${brokerageId}`

  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Complete Contract
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            This Contract is Incomplete. Please fill in the details to complete the Contract and then move it to the Closed status.
          </Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              if (!initiateTransaction && brokerageTransactionType === CONTRACT) {
                setDealToView(brokerageId)
                history.push(urlToRedirect)
              }
              setIsClosed(false)
            }}
          >
            Finish Contract
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              setIsClosed(false)
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(CRMV2Container(TechAssetsContainer(IncompleteToClosed)))
