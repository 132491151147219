import { Select } from "antd"
import React from "react"
import OffersV2 from "container/OffersV2/index"

const OfferStatusSelect = ({
  handleChange,
  getOfferStatusMappingsResponse,
  offerStatus,
}) => {

  const {
    data: mappings,
    isFetching: fetchingMappings,
  } = getOfferStatusMappingsResponse || {}


  const getOptions = () => {
    console.log("mappings", mappings)
    if (mappings) {
      let altered = [...mappings]
      altered.map((item) => {
        const curr = item
        if (curr.value === "new_offer") {
          curr.label = "New Offer"
        } else {
          curr.label = item.display_name
        }
      })
      return altered
    }

    return []
  }

  return (
    <>
      {fetchingMappings ? (
        <Select
          disabled
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={[
            {
              value: "pending_signature",
              label: "Pending Signature",
            },
          ]}
        />
      ) : (
        <Select
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={getOptions()}
          value={offerStatus}
        />
      )}
    </>
  )
}

export default OffersV2(OfferStatusSelect)

