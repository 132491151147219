import React from "react"
import styled from "styled-components"
import colors from "@colors"
import SalesImage from "images/Graphics/RadiusForAgents/NewSales.png?q=100"
import {
  preTabletWidth,
  preDesktopWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
} from "@consts"
import { ATC } from "@fonts"

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  background: #F9F9F7;
  overflow: hidden;
  flex-direction: row-reverse;
  justify-content: center;

  ${preDesktopWidth`
    border-radius: 0px;
    flex-direction: column-reverse;
    align-items: inherit;
    padding-bottom: 50px;
  `}

  ${minDesktopWidth`
    align-items: center;
    padding: 80px 0px;
    min-height: 500px;
  `}

`

const Block = styled.div`
   height: max-content;

   p {
    font-family: ${ATC};
    font-size: 22x;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    max-width: 549px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};

     b {
       font-weight: 700;
     }

     ${minDesktopWidth`
        padding-top: 0px;
        font-size: 22px;
        text-align: left;
        position: static;
        margin: initial;
        max-width: 521px;
    `}

    ${minLargeDesktopWidth`
       max-width: 549px;
    `}
  
     ${preDesktopWidth`
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 467px;
        width: 100%;
     `}

     ${preTabletWidth`
       font-size: 18px;
       font-style: normal;
       font-weight: 300;
       line-height: 26px;
       letter-spacing: 0em;
       text-align: left;
       width: 100%;
     `}

   }

   h1 {
     font-family: Butler;
     font-size: 42px;
     font-style: normal;
     font-weight: 700;
     line-height: 49px;
     letter-spacing: 0.005em;
     text-align: left;
     max-width: 632px;
     position: relative;
     top: 0;
     left: 0;
     margin: 0 auto;
     color: ${colors.black};
     padding-bottom: 16px;

     ${minDesktopWidth`
      font-size: 42px;
      font-style: normal;
      line-height: 49px;
      letter-spacing: 0.005em;
      text-align: left;
      max-width: 521px;  
    `}

    ${minLargeDesktopWidth`
      font-family: Butler;
      font-size: 42px;
      font-style: normal;
      line-height: 49px;
      letter-spacing: 0.005em;
      text-align: left;
      max-width: 632px;     
     `}

    ${preDesktopWidth`
      font-family: Butler;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0.01em;
      text-align: left;
      max-width: 533px;
      margin-left: 0px;
    `}

     ${preTabletWidth`
     font-size: 32px;
     font-style: normal;
     font-weight: 700;
     line-height: 39px;
     letter-spacing: 0.005em;
     text-align: left;
   `}
   }

   .salesImg {
     display: block;
     cursor: pointer;
     position: relative;
     animation: show .3s forwards;
     @keyframes show {
       0% {
         opacity: 0;
       }
       25% {
         opacity: 0.25;
       }
       50% {
         opacity: 0.50;
       }
       100% {
         opacity: 1;
       }
     }
   }

   .afterText {
     display: block;
     max-width: 260px;
   }

   @media(max-width: 1023px) {
     padding:50px 23px 23px 23px;
     margin-top: -21px;

     ${props => props.img && `
       padding: 23px 0px 23px 0px;
     `}

     ${props => props.negativeMargin && `
       margin-bottom: -98px;
    `}

     button {
       font-size: 16px;
       font-weight: 500;
       line-height: 16px;
       letter-spacing: 0px;
       text-align: center;
     }

     .salesImg {
       width: auto;
       top: 285px;
       left: 50%;
       height: 512px;
       padding-bottom: 74px;
       transform: translate( -50%, -50%);
     }
   }

   @media(min-width: 1024px) {
     margin-top: 0;
     ${props => props.img && `
        position: relative;
     `}

     padding: 52px;

     .salesImg {
       width: auto;
       height: 592px;
     }
   }

   @media(min-width: 1025px) {
     ${props => props.marginTop && `
       margin-top: 0px;
     `}
   }
`

class Sales extends React.Component {
  componentDidMount() {
    //
  }

  render() {
    const { header, description } = this.props
    return (
      <Wrapper>
        <Block
          className="imageContainer"
          negativeMargin
          img
        >
          <img src={SalesImage} className="salesImg" alt="radius_agent_realty" />
        </Block>
        <Block marginTop>
          <h1 className="sales-header">
            {header}
          </h1>
          <p className="sales-para">
            {description}
          </p>
        </Block>
      </Wrapper>
    )
  }
}

export default Sales
