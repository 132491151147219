import React, { useState, useEffect } from "react"
import { reduxForm, Field, formValueSelector } from "redux-form"
import { connect } from "react-redux"
import { Textarea, Input } from "@ui/Form"
import styled from "styled-components"
import colors from "@colors"
import moment from "moment"
import { get } from "lodash"
import { customeTimeZone, TimeZone } from "services/Utils"
import createToast from "@ui/Toast"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import uuid from "uuid"
import Privacy from "../RoomSettings/Privacy"
import Schedule from "./Schedule"
import InviteSpeakers from "../RoomSettings/InviteSpeakers"
import InviteSpeakerModal from "../../InviteAgent"
import InviteAttendees from "./InviteAttendees"
import InviteAttendeesModal from "../../InviteAttendeesModal/index"
import {
  RoomsButton,
  RoomsButtonInverted,
  CTAWrap,
} from "../common"

const DATE_FORMAT = "MM DD YY"

const errorStyles = {
  color: "red",
  fontWeight: "300",
  fontSize: "14px",
  marginTop: "7px",
}

const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const validURL = (str) => {
  const pattern = new RegExp("^(https?:\\/\\/)?"
  + "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|"
  + "((\\d{1,3}\\.){3}\\d{1,3}))"
  + "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*"
  + "(\\?[;&a-z\\d%_.~+=-]*)?"
  + "(\\#[-a-z\\d_]*)?$", "i")

  // const httpsCheck = new RegExp("(https)://[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?")

  return !pattern.test(str) ? "Enter Valid Secure URL" : undefined
}

const maxLength = max => value => value
  && value.length > max ? "Word limit exceeded" : undefined

const FREQUENCY_OPTIONS = [
  { value: 1, label: "Daily" },
  { value: 7, label: "Weekly" },
  { value: 30, label: "Monthly" },
  { value: 0, label: "Do not repeat" },
]

const DURATION_OPTIONS = [
  { value: "15m", label: "15 minutes" },
  { value: "30m", label: "30 minutes" },
  { value: "45m", label: "45 minutes" },
  { value: "1hr", label: "1 hour" },
]

const TIMEZONE_OPTIONS = [
  { value: "PST", label: "PST" },
  { value: "HST", label: "HST" },
  { value: "AKST", label: "AKST" },
  { value: "MST", label: "MST" },
  { value: "CST", label: "CST" },
  { value: "EST", label: "EST" },
]

const customStyleInputArea = {
  border: "none",
  background: `${colors.white}`,
  borderRadius: "8px",
  boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
  width: "100%",
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ScheduleDetailsFormUI = (props) => {
  const {
    handleSubmit,
    change,
    is_public,
    speakers,
    isInviteAgentModalOpen,
    toggleInviteSpeakersModal,
    fetchAgentSearch,
    agents,
    clearAgents,
    scheduled_at,
    locations,
    toggleInviteAttendeesModal,
    isInviteAttendeesModalOpen,
    specialRoomType,
    setPage,
    creatingRoom,
    initialize,
    eventData,
    roomFrequency,
  } = props || {}

  useEffect(() => {
    const {
      scheduled_at: scheduledAt,
      frequency,
      about_speaker,
      invited_attendees,
      elastic_filters = [],
      is_public: isPublic,
      event_duration,
      external_url,
    } = eventData || {}


    if (eventData && eventData !== null || eventData !== undefined) {
      const { localeTimeString, localeDateString } = TimeZone(scheduledAt)
      const eventAttendeeLocations = elastic_filters && elastic_filters.map((item) => {
        return {
          city: item.city,
          state: item.state,
          country: item.county,
          id: uuid.v4(),
          attendeeCount: "All",
        }
      })

      initialize({
        scheduled_at: moment(new Date(localeDateString)).format("YYYY-MM-DD"),
        scheduled_at_time: moment(localeTimeString, "h:mm A").format("HH:mm"),
        frequency: FREQUENCY_OPTIONS.filter(item => item.value === frequency)[0],
        about_speaker,
        speakers: invited_attendees || [],
        locations: eventAttendeeLocations || [],
        is_public: isPublic,
        event_duration: DURATION_OPTIONS.filter(item => item.value === event_duration)[0],
        external_url: String(external_url || "Enter URL"),
      })
    }
  }, [])

  const [searchText, setSearchText] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)
  const isUpdatingEvent = eventData && eventData !== null || eventData !== undefined

  const handleCity = (googleLocation) => {
    if (locations.length === 6) {
      createToast("Cannot add more than 3 locations", "error")
      return
    }

    const locationDetails = {
      city: get(googleLocation, "locality.long_name"),
      state: get(googleLocation, "administrative_area_level_1.long_name"),
      country: get(googleLocation, "country.long_name"),
      id: uuid.v4(),
    }

    const locationBasedPayload = {
      sortIsAsc: false,
      from: 0,
      size: 5,
      facade: true,
      sortByEntity: "agentScore",
      fullname: "",
      unRegistered: false,
      city: locationDetails.city || null,
      state: locationDetails.state || null,
      country: locationDetails.country || null,
      requiredFacades: [
        "wcr_pmn",
        "nar_investor_level",
        "specializations",
        "languages",
        "associations",
      ],
    }

    fetchAgentSearch({
      payload: locationBasedPayload,
      onSuccess: (agentCount) => {
        locationDetails.attendeeCount = agentCount
        change("locations", [...locations, locationDetails])
      },
    })
  }

  const handleRemoveTag = (speaker) => {
    const updatedTags = speakers.filter(x => x.id !== speaker.id)
    change("speakers", updatedTags)
  }

  const handleLocationRemoveTag = (location) => {
    const updatedTags = locations.filter(x => x.id !== location.id)
    change("locations", updatedTags)
  }

  const handleSearchInput = (event) => {
    const {
      target: {
        value,
      },
    } = event || {}
    setShowDropDown(true)
    setSearchText(value)

    const payload = {
      sortIsAsc: false,
      from: 0,
      size: 15,
      facade: true,
      sortByEntity: "agentScore",
      fullname: value,
      unRegistered: false,
      requiredFacades: [
        "wcr_pmn",
        "nar_investor_level",
        "specializations",
        "languages",
        "associations",
      ],
    }

    const delayedFetch = () => {
      fetchAgentSearch({ payload })
    }

    delayedFetch()

    if (event.target.value === "") {
      setShowDropDown(false)
    }
  }

  const handleRemoveSpeakers = (agent) => {
    const updatedSpeakers = speakers.filter(x => x.id !== agent.id)
    change("speakers", updatedSpeakers)
  }

  const handleRemoveLocation = (location) => {
    const updateLocations = locations.filter(x => x.id !== location.id)
    change("locations", updateLocations)
  }

  const handleSpeakerState = (agent) => {
    if (speakers.length === 2) {
      createToast("Cannot add more than 2 speakers", "error")
      return
    }
    change("speakers", [...speakers, agent])
    clearAgents()
    setSearchText("")
  }

  return (
    <FormWrap onSubmit={handleSubmit}>
      <InnerWrap>
        <FlexWrap>
          <Privacy
            change={change}
            is_public={is_public}
          />
          <Schedule
            change={change}
            scheduled_at={scheduled_at}
            frequencyOptions={FREQUENCY_OPTIONS}
            durationOptions={DURATION_OPTIONS}
          />
        </FlexWrap>
        <InviteSpeakers
          speakers={speakers}
          handleRemoveTag={handleRemoveTag}
          toggleInviteSpeakersModal={toggleInviteSpeakersModal}
        />
        <Field
          name="about_speaker"
          label="About Speakers"
          validate={maxLength(250)}
          rows={4}
          version={2}
          component={Textarea}
          isVideoRoom
          customStyle={customStyleInputArea}
          errorStyles={errorStyles}
          charLimit={250}
        />
        <InviteAttendees
          locations={locations}
          handleRemoveTag={handleLocationRemoveTag}
          toggleInviteAttendeesModal={toggleInviteAttendeesModal}
        />
        {/* <Field
          customInputStyle={{
            border: "none",
            background: `${colors.white}`,
            borderRadius: "8px",
            boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
            width: "100%",
          }}
          component={Input}
          name="external_url"
          label="External URL (Meet, Zoom or Teams)"
          validate={[validURL]}
          isVideoRoom
        /> */}
        {roomFrequency && roomFrequency.value === 0 && (
          <Field
            name="external_url"
            label="External URL (Meet, Zoom or Teams)"
            validate={[validURL]}
            rows={2}
            version={2}
            component={Textarea}
            isVideoRoom
            customStyle={customStyleInputArea}
            errorStyles={errorStyles}
            charLimit={1000}
          />
        )}
        {isInviteAgentModalOpen && (
          <InviteSpeakerModal
            toClose={() => toggleInviteSpeakersModal(false)}
            searchText={searchText}
            handleSearchInput={handleSearchInput}
            showDropDown={showDropDown}
            handleSpeakerState={handleSpeakerState}
            agents={agents}
            speakers={speakers}
            handleRemoveSpeakers={handleRemoveSpeakers}
          />
        )}
        {isInviteAttendeesModalOpen && (
          <InviteAttendeesModal
            toClose={() => toggleInviteAttendeesModal(false)}
            locations={locations}
            handleRemoveLocation={handleRemoveLocation}
            handleCity={handleCity}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        )}
      </InnerWrap>
      <CTAWrap>
        <RoomsButtonInverted
          type="button"
          disabled={creatingRoom}
          onClick={() => setPage({
            page: "room-details",
            type: specialRoomType,
          })}
        >
          <p>Go Back</p>
        </RoomsButtonInverted>
        <RoomsButton
          type="submit"
          disabled={creatingRoom}
        >
          {creatingRoom ? (
            <ThreeDotLoader color="#fff" />
          ) : (
            <p>{isUpdatingEvent ? "Update Room" : "Schedule Room"}</p>
          )}
        </RoomsButton>
      </CTAWrap>
    </FormWrap>
  )
}

const DetailsForm = reduxForm({
  form: "SCHEDULE_ROOMS_DETAILS_FORM",
  destroyOnUnmount: false,
  initialValues: {
    is_public: 1,
    speakers: [],
    scheduled_at: null,
    locations: [],
    about_speaker: "",
  },
})(ScheduleDetailsFormUI)

const formSelector = formValueSelector("SCHEDULE_ROOMS_DETAILS_FORM")

const SelectedForm = connect((state) => {
  const is_public = formSelector(state, "is_public")
  const speakers = formSelector(state, "speakers")
  const locations = formSelector(state, "locations")
  const scheduled_at = formSelector(state, "scheduled_at")
  const about_speaker = formSelector(state, "about_speaker")

  return {
    is_public,
    speakers,
    scheduled_at,
    locations,
    about_speaker,
  }
})(DetailsForm)

export default SelectedForm
