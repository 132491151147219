import React from "react"
import { Form, GoogleAutoComplete } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { get } from "lodash"
import {
  InputFieldWrap,
  StyledGoogleAutoComplete,
} from "./styles"

const InputField = ({
  placeholder = "",
  type,
  name,
  required = false,
  style,
  customRules = [],
  inputType,
  onChange,
  locationChange,
  formRef,
  fieldIndex,
  formName,
  text = "",
  getInitalPropertyValues,
}) => {
  const getRules = () => {
    const rules = []
    if (required) {
      rules.push({
        required: true,
        message: 'Required field',
      })
    }
    if (type === "email") {
      rules.push({ type: "email" })
    }
    if (type === "custom") {
      rules.push(...customRules)
    }
    return rules
  }

  const setStreet = (location, formRef, key) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      zipCode: get(location, "postal_code.long_name"),
      streetNumber: get(location, "street_number.long_name"),
      county: get(location, "administrative_area_level_2.long_name"),
      route: get(location, "route.long_name"),
    }


    const addressString = locationDetails.streetNumber
      && locationDetails
        .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null

    if (fieldIndex >= 0) {
      const fields = formRef.current.getFieldsValue()
      const currentFields = fields[formName]
      Object.assign(currentFields[fieldIndex], {
        [key[key.length - 1]]: addressString || locationDetails.address,
        city: locationDetails.city,
        state: locationDetails.state,
        zipcode: locationDetails.zipCode,
        county: `${locationDetails.county}`,
      })
      formRef.current.setFieldsValue({
        [formName]: currentFields,
      })

      console.log(fields)

    } else {
      formRef.current.setFieldsValue({
        [key]: addressString || locationDetails.address,
        city: locationDetails.city,
        state: locationDetails.state,
        zipcode: locationDetails.zipCode,
        county: `${locationDetails.county}`,
      })

      if (getInitalPropertyValues && typeof getInitalPropertyValues === "function") {
        getInitalPropertyValues({
          [key]: addressString || locationDetails.address,
          city: locationDetails.city,
          state: locationDetails.state,
          zipcode: locationDetails.zipCode,
          county: `${locationDetails.county}`,
        })
      }
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={text} />}
    >
      <GoogleAutoComplete
        id="cities"
        types={["address"]}
        name="cities"
        onChange={(e) => { }}
        location={(loc) => { setStreet(loc, formRef, name) }}
        placeholder={placeholder}
        styles={{
          borderRadius: "8px !important",
          border: "1px solid var(--Color-Primary-Primary, #898989) !important",
          padding: "12px 16px !important",
          height: "42px !important",
        }}
      />
    </Form.Item>
  )
}

export default InputField
