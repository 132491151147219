import React from "react";
import root from "window-or-global";
import CRMV2Container from "container/CRM";
import { LoadScript, checkForUserObject } from "services/Utils";
import Container from "container/TechAssets";
import BrbcContainer from "container/BRBC";

class PubNubForBrbc extends React.Component {
  componentDidMount() {
    this.loadScript();
  }

  loadScript = () => {
    const { brbcId, fetchBrbcStatus, agentId, clientId} = this.props;
    
    console.log("pubnub", brbcId, agentId, clientId);
    const brbcStatusChannel = `docusign_brbc_${brbcId}`;
    LoadScript(
      "https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js",
      () => {
        root.pubNubBrbcDocusign = new root.PubNub({
          publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
          subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
          uuid: agentId,
        });

        console.log("statusChannel", brbcStatusChannel);
        root.pubNubBrbcDocusign.subscribe({
          channels: [brbcStatusChannel],
        });

        root.pubNubBrbcDocusign.addListener({
          message: (data) => {
            console.log("Listening to..", brbcStatusChannel, data);

            try {
              const { message, channel } = data;
              console.log("check here->", data);
              console.log(message);

              if (channel === brbcStatusChannel) {
                fetchBrbcStatus({
                  agentId: agentId,
                  clientId: clientId,
                });
                console.log("inside pubnub");
                return;
              }
            } catch (e) {
              console.log("exception", e);
            }
          },
        });
      }
    );
  };

  componentWillUnmount() {
    const {fetchBrbcStatusResponse, getCrmClientResponse} = this.props;
    const { data: brbcData} =
    fetchBrbcStatusResponse || {};
    const brbcId = brbcData?.docusign[0]?.brbc_id;
    const brbcStatusChannel = `docusign_brbc_${brbcId}`;
    if (root.pubNubBrbcDocusign) {
      root.pubNubBrbcDocusign.unsubscribe({
        channels: [brbcStatusChannel],
      });
    }
  }

  render() {
    return null;
  }
}

export default BrbcContainer(Container(CRMV2Container(PubNubForBrbc)));
