import React from "react"
import {
  SalesLeaderboardSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import {
  InnerTab,
  IconEle,
  NavLink,
} from "./commonStyles"

const LeaderboardTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[1] === "leaderboard"}>
    <div className="icon-block-new">
      <IconEle src={SalesLeaderboardSVGIcon} alt="RARleaderboard" isActive={routeArray[1] === "leaderboard"} />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/leaderboard"
        isActive={routeArray[1] === "leaderboard"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_leaderboard_web")
          }
        }}
      >
        Leaderboard
      </NavLink>
    </div>
  </InnerTab>
)

export default LeaderboardTab
