import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { get } from "lodash"
import { ColorIcon } from "dumbComponents/OffersV3/icons"
import OffersV2 from "container/OffersV2/index"
import { toast as createToast } from "react-toastify"
import { checkForUserObject, showDocusignFeature } from "services/Utils"
import { withRouter } from "react-router-dom"
import CircularImage from "dumbComponents/common/CircularImage"
import ConfirmationPopup from "dumbComponents/Offers/Components/ConfirmationPopup"
import moment from "moment"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import { moneyFormatter } from "shared/currencyUtils"
import ListingsPropertyFallbackImage from "images/Graphics/OffersAssets/PropertyListingImageFallback.jpg"
import {
  Select,
  Modal,
} from "@ui/antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  fetchDetails,
} from "dumbComponents/Offers/commonUtils"
import ConfirmationNote from "dumbComponents/Offers/Components/ConfirmationNote"
import {
  BuyerAgentInfoRoute,
  OfferStatuses,
  ColorMap,
  OfferInfoStep,
} from "dumbComponents/Offers/consts"

const user = checkForUserObject("user")

const ColorWrap = styled.div`
  position: relative;
  z-index: 100;
  left: 3px;
`

const Wrap = styled.li`
  display: flex;
  background: #FFFFFF;
  // box-shadow: 0px 1px 4px 2px rgb(204 204 204 / 25%);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  justify-content: space-between;
  // height: 190px;
  // margin: 25px 0px;
  cursor: pointer;
  flex-direction: column;
  .ant-select-borderless {
    background-color: white !important;
  }
`

const StatusButton = styled.div`
  width: 12.23px;
  height: 12.23px;
  background: #B3E4C7;
  ${props => props.bg && `
    background: ${props.bg}
  `}
  border-radius: 50%;
`

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  background: white;
  // padding-left: 10px;
  .ant-select-selector {
    border-color: transparent !important;
  }
`

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: column;
  h4 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #849DF8;
    margin: 10px 0 0;
  }
`

class GlobalStatus extends PureComponent {
  state = {}

  componentDidMount() {
    const {
      getOffers,
    } = this.props
    const agentId = CookiesStorage.load("agentId")
    getOffers({
      skip: 0,
      limit: 100,
      query: `&agentId=${agentId}`,
    })
  }

  setDealToView = (dealId, primaryAgentId) => {
    const {
      history, getOfferSteps, dealToView, setDealToView: setDealToViewReducer, setEditingDealTeamMemberId, getDealDetails,
      listingData,
    } = this.props
    console.log("==listingData====", listingData)
    setEditingDealTeamMemberId(parseInt(primaryAgentId, 10))
    // if (primaryAgentId.toString() === CookiesStorage.load("agentId")) {
    setDealToViewReducer(dealId)
    // history.push(`${BuyerAgentInfoRoute}?dealId=${dealId}`)
    if (showDocusignFeature()) {
      getOfferSteps({
        payload: {
          offerId: dealId,
        },
        history,
        offerStatus: listingData.current_offer_status,
      })
      // } else {
      //   history.push(`${BuyerAgentInfoRoute}?dealId=${dealId}`)
      // }
    } else {
      history.push(`${BuyerAgentInfoRoute}?dealId=${dealId}`)
      // createToast("Cannot edit team member's offer.", toastConfig)
    }
  }

  handleChange = (prevValue, newValue, id, listingData) => {
    const {
      toggleConfirmToExecute,
    } = this.props

    const PREVIOUS = this.getHumanReadableValue(prevValue)
    const UPDATED = this.getHumanReadableValue(newValue)

    const tID = listingData && listingData.buyer_clients.length > 0 && listingData.buyer_clients[0].transaction_id

    if (prevValue !== newValue) {
      toggleConfirmToExecute({
        newValue,
        prevValue,
        selectedListingId: id,
        PREVIOUS,
        UPDATED,
        currentTransactionId: tID || "",
        callback: this.confirmationPopupCallback,
        currentTitle: newValue === "offer_accepted" ? "Congratulations!" : "Do you want to update the offers status?",
      })
    }
  }

  getOptions = () => {
    const {
      getOfferStatusMappingsResponse,
    } = this.props || {}

    const {
      data: mappings,
    } = getOfferStatusMappingsResponse || {}

    if (mappings) {
      let altered = [...mappings]
      altered.map((item) => {
        const curr = item
        if (curr.value === "new_offer") {
          curr.label = "New Offer"
        } else {
          curr.label = item.display_name
        }
      })
      return altered
    }

    return []
  }

  getColor = status => ColorMap.find(cl => cl.label === status)?.value

  getHumanReadableValue = status => OfferStatuses.find(stat => stat.value === status)?.label

  getPropertyListingImage = (image = []) => get(image, "[0]", ListingsPropertyFallbackImage)

  confirmationPopupCallback = (offerStatusChangeNote, selectedListingId, newValue, tId) => {
    // const { newValue, selectedListingId } = this.state
    const {
      updateOfferStatus,
      history,
    } = this.props
    if (newValue === "offer_accepted") {
      //history.push(`)
      root.location.href = `/realty/offers-v3/${selectedListingId}?contract_phase=true`
      // history.push(`/realty/offers/in-contracts/basic-info?offerId=${selectedListingId}&status=offer_accepted`)
    } else {
      const payload = {
        notes: offerStatusChangeNote || "",
        offer_id: selectedListingId,
        status: newValue,
      }
      updateOfferStatus({
        payload,
        callback: () => {
          const {
            getOffers,
          } = this.props
          const agentId = CookiesStorage.load("agentId")
          getOffers({
            skip: 0,
            limit: 100,
            query: `&agentId=${agentId}`,
          })
          // history.push(`/realty/offers/documents/lists?offerId=${selectedListingId}`)
        },
      })
    }
  }

  render() {
    const {
      getAllOffersActionResponse,
      offerIdForStatus,
      showConfirmToExecute,
      getOfferStatusMappingsResponse,
    } = this.props

    const {
      isFetching: fetchingMappings,
    } = getOfferStatusMappingsResponse || {}

    const listingData = getAllOffersActionResponse?.data?.find(dt => dt.id === parseInt(offerIdForStatus, 10)) || { current_offer_status: "incomplete" }

    console.log("listing data", listingData)

    return (
      <Wrap>
        {showConfirmToExecute && (<ConfirmationPopup />)}
        <StatusWrap>
          <SelectWrap>
            {!fetchingMappings && (
              <>
                {/* <StatusButton bg={this.getColor(listingData.current_offer_status)} /> */}
                <ColorWrap>
                  <ColorIcon color={ColorMap.find(item => item.label === listingData.current_offer_status)?.value || "red"} />
                </ColorWrap>
                <Select
                  value={listingData.current_offer_status}
                  onChange={
                    (e) => {
                      this.handleChange(listingData.current_offer_status, e, listingData.id, listingData)
                    }}
                  style={{
                    width: 195,
                    backgroundColor: "white",
                  }}
                  bordered={false}
                  disabled={listingData.current_offer_status === "incomplete"}
                  options={OfferStatuses}
                />
              </>
            )}
          </SelectWrap>
        </StatusWrap>
      </Wrap>
    )
  }
}

export default OffersV2(withRouter(TechAssetsContainer(GlobalStatus)))
