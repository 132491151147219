import React, { useEffect } from "react"
import ListingsV2 from "container/ListingsV2"
import Listings from "container/Listings"
import OldListings from "container/TechAssets"
// import {
//   RecommendedDocs,
//   OfferPackage,
// } from "./Components"
import Tabs from "../ListingsV2/Components/Tabs"
import {
  CoverSheetTitle,
  MenuUiWrap,
} from "./styles"
import CheckList from "./Components/Checklist"
import RecommendedDocs from "../ListingsV2/Components/RecommendedDocs/index"

const STEPS = [
  {
    value: "document_checklist",
    label: "All Documents",
  },
  {
    value: "offer_package",
    label: "Envelopes",
  },
]

const ComplianceFlow = ({
//   dealToView,
  toggleDocumentTab,
  currentDocumentTab,
  envelopeListings,
}) => {

  const fromNotifs = localStorage.getItem("from_notifs")

  useEffect(() => {
    if(fromNotifs === "true"){
      toggleDocumentTab("offer_package")
      localStorage.clear("from_notifs")
    }
    else{
      toggleDocumentTab("document_checklist")
    }
  }, [])

  const areEnvelopesPresent = () => {
    if (envelopeListings) {
      Object.keys(envelopeListings).forEach((key) => {
        // as soon as you find a key with a length, return true,
        // we don't need to check the rest
        if (envelopeListings[key].length > 0) {
          toggleDocumentTab("offer_package")
          return true
        }
      })
    }

    return false
  }

  useEffect(() => {
    areEnvelopesPresent()
  }, [envelopeListings])

  return (
    <MenuUiWrap>
      <Tabs
        steps={STEPS}
        toggleTab={currentTab => toggleDocumentTab(currentTab)}
        currentTab={currentDocumentTab}
        //tabWidth="35%"
      />
      {currentDocumentTab === "document_checklist" && (
        <CheckList />
      )}
      {currentDocumentTab === "offer_package" && (
        <>
       <RecommendedDocs />
        </>
      )}
    </MenuUiWrap>
  )
}

export default Listings(ListingsV2(OldListings(ComplianceFlow)))

