import { takeLatest, call, put, select } from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { addNoteAPI } from "container/ClientProfile/api"
import * as NonAssistActions from "./actions"
import * as NonAssistSelectors from "./selector"
import * as API from "./api"

function* getNonAssistLeads(actions) {
  try {
    // Ask about params
    const {
      salesStages = ["Leads", "Prospects", "In Contract", "Deals", "On Hold", "Archived"],
      type_list = ["Buyer", "Seller", "Renter"],
    } = actions.data || {}
    const payload = {
      text: "",
      type_list,
      sort_by: "created_at",
      ascending: false,
      start: 0,
      end: 50,
      aggregationNotRequired: false,
      salesStages,
    }
    const res = yield call(API.getNonAssistLeadsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      const { clients } = response
      const dndColumns = NonAssistSelectors.getColumnsByStatus(clients)
      yield put(NonAssistActions.updateSalesStageAction.call(dndColumns))
      yield put(NonAssistActions.getNonAssistLeadsAction.success(response))
    }
  } catch (error) {
    yield put(NonAssistActions.getNonAssistLeadsAction.failure(error))
  }
}

function* handleChangeSalesState(action) {
  try {
    const {
      destinationState: sales_stage,
      types,
      clientId,
      clientIndex,
    } = action.data
    const nonAssistLeadsResponse = yield select(state => state.nonAssist.nonAssistLeadsResponse)
    const selectedClient = nonAssistLeadsResponse.data.clients[clientIndex]
    const { sales } = selectedClient
    nonAssistLeadsResponse.data.clients[clientIndex].sales_stage = sales_stage
    const dndColumns = NonAssistSelectors.getColumnsByStatus(nonAssistLeadsResponse.data.clients)
    yield put(NonAssistActions.updateSalesStageAction.call(dndColumns))
    const payload = {
      sales: {
        ...sales,
        "Current stage": sales_stage,
      },
      sales_stage,
      types,
    }
    const res = yield call(API.changeSalesStateAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NonAssistActions.changeSalesStateAction.success(response))
    }
  } catch (error) {
    yield put(NonAssistActions.changeSalesStateAction.failure(error))
  }
}

function* handleChangeSalesStateV2(action) {
  try {
    const { sales, clientId } = action.data
    const payload = {
      sales: {
        ...sales,
      },
    }
    const res = yield call(API.changeSalesStateAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NonAssistActions.changeSalesStateAction.success(response))
    }
  } catch (error) {
    yield put(NonAssistActions.changeSalesStateAction.failure(error))
  }
}

function* handleAddNote(action) {
  try {
    const {
      payload,
      clientId,
    } = action.data
    const res = yield call(addNoteAPI, clientId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NonAssistActions.addNoteAction.success(response))
      yield put(NonAssistActions.toggleAddNotesModalAction.call(false))
    }
  } catch (error) {
    yield put(NonAssistActions.addNoteAction.failure(error))
  }
}

// function* handle(action) {
//   try {
//     const {  } = action.data
//     const res = yield call(API.., )
//     if (isSuccess(res)) {
//       const { response } = res.data
//       yield put(NonAssistActions.changeSalesStateAction.success(response))
//     }
//   } catch (error) {
//     yield put(NonAssistActions..failure(error))
//   }
// }

function* handleArchiveLeads() {
  try {
    const payload = {
      text: "",
      sort_by: "created_at",
      ascending: false,
      start: 0,
      end: 50,
      aggregationNotRequired: false,
      salesStages: ["Archived"],
    }
    const res = yield call(API.getNonAssistLeadsAPI, payload)
    if (isSuccess) {
      const { response } = res.data
      yield put(NonAssistActions.getArchivedLeadNonAssistAction.success(response))
    }
  } catch (error) {
    yield put(NonAssistActions.getArchivedLeadNonAssistAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(NonAssistActions.getNonAssistLeadsAction.REQUEST, getNonAssistLeads)
  yield takeLatest(NonAssistActions.changeSalesStateAction.REQUEST, handleChangeSalesState)
  yield takeLatest(NonAssistActions.getArchivedLeadNonAssistAction.REQUEST, handleArchiveLeads)
  yield takeLatest(NonAssistActions.addNoteAction.REQUEST, handleAddNote)
  yield takeLatest(NonAssistActions.changeSalesStateV2Action.REQUEST, handleChangeSalesStateV2)
}
