import React from "react"
import styled from "styled-components"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import { ATC, INTER, MONTSERRAT } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/CrossVectorNew.png

const Wrap = styled.div`
  width: ${props => props.width || "543px"};
  height: max-content;
  border-radius: 9px;
  background: #F0F1FF;
  opacity: 0;
  padding: 42px 17px 31px 26px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  animation: popUp 0.7s cubic-bezier(0,1.02,1,1.14) forwards;

  @keyframes popUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ${props => props.noBorder && `
    border-top: unset;
    background: transparent;
    box-shadow: unset;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  `}
`

const Text = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 153%; /* 24.48px */

  span {
    color: var(--color-primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 153%;
  }

  ${props => props.noBorder && `
    color: #fff;
  `}
`

const CrossButtonWrap = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;

  img {
    width: 10.488px;
    height: 10.488px;
  }
`

const AppStampWrap = styled.div`
  img {
    width: 100px;

    ${props => props.footer && `
      filter: invert(1);
    `}
  }

  ${props => props.footer && `
      margin-bottom: 0;
  `}
`

const FooterTitle = styled.p`
  color: #B6B8FF;
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const FormWrap = styled.div``

const FlexWrap = styled.div`
  display: flex;
`

const ScanToDownloadText = styled.p`
  color: var(--color-primary, #5A5FF2);
  font-family: ${INTER};
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 153%; /* 15.3px */
  text-transform: uppercase;
  display: flex;
  gap: 5px;

  span img {
    height: 13px;
    width: 13px;
  }
`

const LeftWrap = styled.div`
  width: 400px;
`

const RightWrap = styled.div``

const QRCodeWrap = styled.div`
  margin-top: 10px;
  img {
    width: 80px;
    height: 80px;
  }
`

const CloseText = styled.p`
  color: var(--color-primary, #5A5FF2);
  font-family: ${MONTSERRAT};
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 153%; /* 12.24px */
  text-decoration-line: underline;
  text-transform: uppercase;
`

const QRCode = ({
  toggle,
  noBorder: footer,
  appDownloadSMS,
  appDownloadSMSData,
  submittingForm,
  className,
  width,
}) => {
  const handleOnSubmit = (value) => {
    const payload = {
      phoneNumber: value.phone,
      email: value.email,
    }
    appDownloadSMS({ payload })
  }

  return (
    <Wrap
      width={width}
      noBorder={footer}
    >
      {!footer && (
        <CrossButtonWrap
          onClick={() => {
            toggle(false)
          }}
        >
          {/* <img src={`${CLOUDFRONT}/CrossVectorNew.png`} alt="cross.png" /> */}
          <CloseText>CLOSE</CloseText>
        </CrossButtonWrap>
      )}
      <>
        {footer && (
          <FooterTitle>
            Join Our Community
          </FooterTitle>
        )}
        <FlexWrap>
          <LeftWrap>
            <Text
              noBorder={footer}
            >
              Enjoy the benefits of a community
            </Text>
            <Text
              style={{
                marginTop: "-5px",
              }}
            >
              <span>85,000</span>
              {" "}
              strong … in the palm of your hand.
            </Text>
            <ScanToDownloadText>
              Scan to download the Radius Community App
              <span>
                <img src={`${CLOUDFRONT}/RADS_HP_ARROW_PURPLE.png`} alt="radius_purple_arrow" />
              </span>
            </ScanToDownloadText>
            <AppStampWrap
              footer={footer}
            >
              <img src={`${CLOUDFRONT}/AppStamp.png`} alt="ios_droid_radius" />
            </AppStampWrap>
          </LeftWrap>
          <RightWrap>
            <QRCodeWrap>
              <img src={`${CLOUDFRONT}/RADS_QR_BRIGHT.svg`} alt="qr_radius_png" />
            </QRCodeWrap>
          </RightWrap>
        </FlexWrap>
      </>
    </Wrap>
  )
}

export default ApplyFlowContainer(QRCode)
