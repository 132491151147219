import React from "react"
import OffersV2 from "container/OffersV2"
import {
  Wrap,
  StyledCheckbox,
  AttachmentText,
} from "./styles"
import DynamicMenu from "../DynamicMenu"

const CheckboxList = ({
  list,
  onClick,
  mainList,
  dropdownList,
  currentSearchText,
  customFilterText,
  hideCheckBox,
  img,
  handleSelectedCheck = () => {},
}) => {
  const currentText = currentSearchText || customFilterText
  return (
    <>
      {list && list.filter((item) => {
        if (currentText === "" || !currentText) {
          return item
        // eslint-disable-next-line no-else-return
        } else if (item.name.toLowerCase().includes(currentText.toLowerCase())) {
          return item
        }
      }).map(item => (
        <Wrap>
          {!hideCheckBox ? (
            <StyledCheckbox
              checked={item.isSelected}
              value={item.id}
              onChange={e => handleSelectedCheck(e, item.id)}
            >
              {item.name}
            </StyledCheckbox>
          ) : (
            <AttachmentText>
              {img && (
                <img src={img} alt="paperclip" />
              )}
              <p>{item.name}</p>
            </AttachmentText>
          )}
          <DynamicMenu
            dropdownList={dropdownList}
            mainList={mainList}
            id={item.template_id || item.id || item.url}
          />
        </Wrap>
      ))}
    </>
  )
}

export default OffersV2(CheckboxList)
