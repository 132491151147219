import React from "react"
import OffersV2 from "container/OffersV2" //connect sagas individually to components
import {
  AgentCardWrap,
  BuyerAgentInformationText as LenderInformationText,
  EditButton,
  DetailsArea,
  AgentName,
  AgentEmail,
  AgentPhoneNumber,
  AddtionalInfoOuterWrap,
  AdditionalInfoInnerWrap,
  AdditionInfoTitle,
  AdditionalInfoValue,
} from "../AgentCard/styles"

/**
 *Do not create styled-components here
 */

const LenderCard = ({
  editLender,
  data,
  title,
  deselectLender,
}) => (
  <AgentCardWrap>
    <EditButton right="50px" onClick={deselectLender}>
      Deselect
    </EditButton>
    <EditButton onClick={editLender}>
      Edit
    </EditButton>
    <LenderInformationText>
      <p>{title}</p>
    </LenderInformationText>
    <DetailsArea>
      <AgentName>
        <p>{data.lender_officer_name}</p>
      </AgentName>
      <AgentEmail>
        <p>{data.lender_email}</p>
      </AgentEmail>
      <AgentPhoneNumber>
        <p>{data.lender_phone}</p>
      </AgentPhoneNumber>
    </DetailsArea>
    <AddtionalInfoOuterWrap>
      <AdditionalInfoInnerWrap>
        <AdditionInfoTitle>LENDING COMPANY</AdditionInfoTitle>
        <AdditionalInfoValue>{data.lender_co}</AdditionalInfoValue>
      </AdditionalInfoInnerWrap>
      <AdditionalInfoInnerWrap noBorder>
        <AdditionInfoTitle>OFFICE ADDRESS</AdditionInfoTitle>
        <AdditionalInfoValue>{data.lender_office_address}</AdditionalInfoValue>
      </AdditionalInfoInnerWrap>
    </AddtionalInfoOuterWrap>
  </AgentCardWrap>
)

export default OffersV2(LenderCard)
