import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { StyledDragger, StyledDraggerDesc } from "./styles";
import BrbcContainer from "container/BRBC";
import Loader from "@ui/Loader";
import "./styles.css";

const { Dragger } = Upload;

const UploadBrbc = ({ onFileAttach }) => {
  const [fileList, setFileList] = useState([]);
  const [isAttaching, setIsAttaching] = useState(false);

  const handleAttach = (info) => {
    setIsAttaching(true);
    setTimeout(() => {
      setFileList([info.fileList[info.fileList.length - 1]]);
      setIsAttaching(false);

      if (onFileAttach) {
        onFileAttach(info);
      }
    }, 1000);
  };

  const handleRemove = (file) => {
    setFileList([]);
  };

  const draggerProps = {
    multiple: false,
    beforeUpload: (file) => {
      return false;
    },
    onChange: handleAttach,
    fileList,
  };

  return (
    <>
      {fileList.length === 0 && !isAttaching && (
        <Dragger {...draggerProps} style={StyledDragger}>
          <p className="ant-upload-drag-icon" style={{ textAlign: "center" }}>
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={StyledDraggerDesc}>
            <span style={{ fontWeight: "600" }}>Click to upload</span> or drag
            and drop
          </p>
        </Dragger>
      )}

      {isAttaching && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <Loader />
        </div>
      )}

      {!isAttaching && fileList.length > 0 && (
        <Upload
          listType="picture"
          fileList={fileList}
          onRemove={handleRemove}
          classname="styled-upload"
        />
      )}
    </>
  );
};

export default BrbcContainer(UploadBrbc);
