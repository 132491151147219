import React from "react"
import styled, { css } from "styled-components"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 35px;
  align-items: center;
  ${props => props.br && `
    border: ${props.br};
    ${props.selected && `
      border: 1px solid #4E26BE
    `};
  `}
  ${props => props.brrd && `
    border-radius: ${props.brrd};
  `}
  ${props => props.pd && `
    padding: ${props.pd};
  `}
`

const RadiuoButton = styled.div`
  position: relative;
  width: ${props => props.size};
  height: ${props => props.size};
  border: 2px solid ${props => props.color};
  border-radius: 50%;
  cursor: pointer;

  ${props => props.selected && css`
    border: 2px solid ${props.selectedBorderColor};
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 70%;
      height: 70%;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      background: ${props.selectedColor};
      transform: translateX(-50%) translateY(-50%);
    }
  `}

  ${props => props.disabled && `
      cursor: not-allowed;
  `}
`

const Label = styled.span`
    flex: 1;
    margin-left: 15px;
`

const RenderRadioButton = (field) => {
  const {
    buttonStyles,
    labelStyles,
    detailsWrapStyle,
    descriptionStyles,
    description,
    disabled,
    size,
    color,
    handleOnClick,
    value,
    selected,
    selectedColor,
    label,
    styles,
    clr,
    br,
    brrd,
    pd,
  } = field
  return (
    <Wrapper style={styles} br={br} brrd={brrd} pd={pd} selected={selected}>
      <RadiuoButton
        disabled={disabled}
        size={size}
        onClick={handleOnClick}
        value={value}
        color={color}
        selected={selected}
        selectedBorderColor={disabled ? "#38383873" : clr || selectedColor}
        selectedColor={clr || selectedColor}
        style={{
          ...buttonStyles,
        }}
      />
      {description ? (
        <div
          style={{
            ...detailsWrapStyle
          }}
        >
          <p
            style={{
              ...labelStyles,
            }}
          >
            {label}
          </p>
          {description && (
            <p
              style={{
                ...descriptionStyles
              }}
            >
              {description}
            </p>
          )}
        </div>
      ) : (
        <Label>
          {label}
        </Label>
      )}
    </Wrapper>
  )
}

RenderRadioButton.defaultProps = {
  size: "20px",
  color: "rgba(0, 0, 0, 0.4)",
  selectedColor: "#2DCAA8",
}

export default RenderRadioButton
