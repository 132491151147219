import React, { PureComponent } from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import TCContainer from "container/Realty/TransactionFormContainer"
import Form1 from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/Forms/form1"
import Form2 from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/Forms/form2"
import { ATC } from "@fonts"

const Wrap = styled.div`

`

class Forms extends PureComponent {
  render() {
    const { toggleCommissionsPopup, toggleCommissionsPopupStep } = this.props
    return (
      <Wrap>
        {toggleCommissionsPopupStep === 1 && (<Form1 />)}
        {toggleCommissionsPopupStep === 2 && (<Form2 />)}
      </Wrap>
    )
  }
}

export default TCContainer(Forms)
