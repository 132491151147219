import React, { useState } from "react"
import cookie from "services/CookieStorage"
import colors from "@colors"
import Listings from "container/Listings/index"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { withRouter } from "react-router-dom"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import moment from "moment"
import ShimmerBox from "./ShimmerBox"
import TransactionStat from "./TransactionStats"
import {
  HeaderWrap,
  Heading,
  OverviewWrap,
  HeaderContent,
  AddCTAWrap,
} from "../../commonStyles"
import AddOffer from "./AddOffer"
import AddListing from "./AddListing"

const TransactionHeader = ({
  handleCreateFlow,
  currentTransactionDashboardTab,
  fetchTransactionOverview,
  fetchTransactionOverviewResponse,
  getTeamDetailsResponse
}) => {
  const { isFetching: fetchingTeams, data: teamData } = getTeamDetailsResponse || {}
  const tcInformation = cookie.load("tcInformation")
  const teamLead = teamData?.team_members?.find(member => member.member_type === "Team Lead")
  const teamLeadId = teamLead ? teamLead.agent_id : null
  const [selectedYear, setSelectedYear] = useState(2024)
  const yearList = [
    {
      label: "2024",
      value: 2024,
    },
    {
      label: "2023",
      value: 2023,
    },

  ]
  const {
    isFetching: loadingOverview,
    data: overviewData,
  } = fetchTransactionOverviewResponse
  const handleYearChange = (value) => {
    setSelectedYear(value)
    const fromDate = moment().year(value).startOf("year").unix()
    const toDate = moment().year(value).endOf("year").unix()
    const payload = {
      clientTransactionType: currentTransactionDashboardTab,
      toDate,
      fromDate,
    }
    if (tcInformation?.is_tc && teamLeadId) {
      payload.agentId = teamLeadId
    }
    fetchTransactionOverview({
      payload,
    })
  }
  return (
    <HeaderWrap>
      <HeaderContent>
        <Heading>
          My Transactions
        </Heading>
        <AddCTAWrap>
          <AddOffer handleCreateFlow={handleCreateFlow} />
          <AddListing handleCreateFlow={handleCreateFlow} />
        </AddCTAWrap>
      </HeaderContent>
      <OverviewWrap>
        {
          loadingOverview ? (<ShimmerBox />) : (
            <>
              <SelectField
                placeholder="Select Year"
                options={yearList}
                onChange={handleYearChange}
                defaultValue={selectedYear}
                style={{
                  width: "183px !important",
                }}
                value={selectedYear}
                width="183px"
                borderColor={colors.transaction_tab_select}
              />
              <TransactionStat loading={loadingOverview} data={overviewData} />
            </>
          )
        }
      </OverviewWrap>
    </HeaderWrap>
  )
}

export default withRouter(TechAssetsContainer(TeamProfileContainer(Listings(TransactionHeader))))
