import React from "react"
import styled from "styled-components"
import { HeroText, SubText } from "../commonStyles"

const Wrap = styled.div`
  width: ${props => props.width || "max-content"};
  height: 218px;
  flex-shrink: 0;

  @media(max-width: 1099px) {
    width: 100%;
    max-width: 348px;
  }
`

const LogoWrap = styled.div`
  margin-bottom: 20px;
  img {
    width: ${props => props.logoWidth};
    height: ${props => props.logoHeight};
  }
`

const TitleWrap = styled.div``

const SectionThree = ({
  img,
  heroText,
  subText,
  logoWidth,
  logoHeight,
  width,
}) => (
  <Wrap
    width={width}
  >
    <LogoWrap
      logoWidth={logoWidth}
      logoHeight={logoHeight}
    >
      <img src={img} alt="radius_services" />
    </LogoWrap>
    <TitleWrap>
      <HeroText fs="30px">{heroText}</HeroText>
    </TitleWrap>
    <SubText>
      {subText}
    </SubText>
  </Wrap>
)

export default SectionThree
