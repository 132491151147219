import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const FormWrap = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;

  border-radius: 10px;
  background: var(--Color-Neutral-100, #F1F1FE);

  form {
    width: 100%;

    .ant-form-item {
      margin-left: unset;
    }
  }
`

export const FormTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.352px;
    margin: 0;
  }
`

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`

export const CancelOrGoBackButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;

  img {
    width: 16px;
    height: 16px;
  }
`

export const CTAWrap = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-form-item {
    width: unset;
  }
`
