import React, { useState } from "react"
import styled from "styled-components"
import { Droppable, Draggable } from "react-beautiful-dnd"
import colors from "@colors"
import CRMV2Container from "container/CRM"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Box from "@ui/Box"
import Add_light from "images/Graphics/Add_Light.svg"
import Button from "@ui/Button"
import { ATC } from "@fonts"
import {
  StatusDot,
} from "@ui/DragDropColumn/common"
import Card from "./Card"
import { BUYER, SELLER } from "../common"

const Container = styled.div`
  vertical-align: top;
  display: flex;
  flex-direction: column;
  // border: 1px solid ${colors.lightGray};
  border-radius: 6px 6px 0px 0px;
  background: ${colors.white};
  padding: 6px 0px;
  gap: 3px;
  height: ${props => `${props.height}px - 250px` || "500px"};
  &::-webkit-scrollbar { 
    display: none;
  }
`

const ClientList = styled.div`
  background-color: ${props => (!props.zeroStateBg ? "#F9F9F7" : "inherit")};
  border-radius: ${props => (!props.zeroStateBg ? "8px" : "0px")};
  flex-grow: 1;
  min-height: 300px;
  width: 206px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const DraggableDiv = styled.div``

const Title = styled(Paragraph)`
  margin-bottom: 0;
  font-family: ${ATC};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`

const NumberWrap = styled.div`
  background: #f9f9f7;
  border-radius: 49.5985px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
`

const HeaderWrap = styled.div`
  height: 41.82568359375px;
  width: 206px;
  border-radius: 3px;
  border: 1px solid #E5E5E5;
  display: flex;
  padding: 8px 0px 8px 7px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 41px;
  cursor: pointer;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5);

  &:hover {
    height: 100px;
    z-index: 9999;
    right: 0;
    justify-content: center;
    align-items: center;
    padding-left: 0px;

    & div {
      display: none;
    }


    & div:first-child {
       display: block;
       white-space: pre-line !important;
       text-overflow: unset;
       max-width: 100%;

       p {
        text-align: center !important;
        animation: show 0.2s ease-out forwards;
       }

       @keyframes show {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
       }
    }

    transition: height 0.2s ease-out 0.1s;
  }

`

const TitleWrap = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 109px;
`

const ShowMoreButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const AddIcon = styled.div`
  height: 16px;
  width: 30px;
  left: 151.33331298828125px;
  top: 3.105010986328125px;
  border-radius: 0px;

  img {
    height: 100%;
  }

`

const Column = ({
  column,
  leads,
  toggleUserOverviewDrawer,
  leadsNumber,
  getClientTransactions,
  setCurrentDrawerPage,
  toggleAddNewLeadDrawer,
  height,
  getCtIdResponse,
}) => {
  const [initialPage, setPage] = useState(1)
  const onClickUpdate = (value) => {
    setPage(initialPage + 1)
    const payload = {
      offerStage: value,
      page: initialPage,
    }
    getClientTransactions(payload)
  }
  const onClickHandle = () => {
    setCurrentDrawerPage("add-lead")
    toggleAddNewLeadDrawer(true)
  }

  const { data } = getCtIdResponse || {}
  const { req_id, prop_id, type, id } = data || {}

  const disabled = (BUYER.includes(type) && req_id === null)
    || (SELLER.includes(type) && prop_id === null)

  return (
    <Container
      className={column.className}
      height={height}
    >
      <HeaderWrap
        style={{
          borderBottom: column.backgroundColor,
          borderBottomWidth: "3.4px",
          borderBottomStyle: "solid",
          marginBottom: "12px",
        }}
        onClick={() => onClickHandle()}
      >
        <TitleWrap>
          <Title size={18} fontWeight={500}>
            {column.display_name}
          </Title>
        </TitleWrap>
        <NumberWrap>
          <p>
            {leadsNumber}
          </p>
        </NumberWrap>
        <AddIcon>
          <img src={Add_light} />
        </AddIcon>
      </HeaderWrap>
      <Droppable droppableId={column.value}>
        {(provided, snapshot) => (
          <ClientList
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            style={column.style}
            zeroStateBg={leads}
          >
            <>
              {leads && leads.length && leads.map((item, index) => (
                <Draggable
                  draggableId={item && item.id}
                  index={index}
                  // isDragDisabled={id === item.id && disabled}
                >
                  {(provided, snapshot) => (
                    <DraggableDiv
                      key={item && item.id}
                      innerRef={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDraggingOver={snapshot.isDragging}
                      style={{
                        display: "block",
                      }}
                    >
                      <Card
                        key={item && item.id}
                        data={item}
                        index={index}
                        toggleUserOverviewDrawer={toggleUserOverviewDrawer}
                        disabled={disabled}
                        id={id}
                      />
                    </DraggableDiv>
                  )}
                </Draggable>
              ))}
            </>
            {(!leads) && (
              <ShowMoreButtonWrap>
                <Button
                  style={{
                    borderRadius: "0",
                    width: "100%",
                    height: "40px",
                    border: "none",
                    backgroundColor: "#F9F9F7",
                    color: "#919191",
                  }}
                  onClick={() => onClickHandle()}
                >
                  + Add Client
                </Button>
              </ShowMoreButtonWrap>
            )}
            {leads && leadsNumber > 20 && (leads.length !== leadsNumber) && (
              <ShowMoreButtonWrap>
                <Button
                  style={{
                    borderRadius: "0",
                    width: "60%",
                    height: "40px",
                    borderWidth: "1px",
                    fontWeight: 300,
                  }}
                  bsStyle="marineBLueOutlined"
                  onClick={() => onClickUpdate(column.value)}
                >
                  Show More
                </Button>
              </ShowMoreButtonWrap>
            )}
          </ClientList>
        )}
      </Droppable>
    </Container>
  )
}

export default CRMV2Container(Column)
