import React from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import VideoRoomIcon from "images/rooms/icons/video-room.svg"
import ScheduleRoomIcon from "images/Graphics/RadiusRoomsAssets/ScheduleRoomSvg.svg"
import HouseTour from "images/Graphics/RadiusRoomsAssets/HouseTour.svg"
import colors from "@colors"
import {
  Header,
  SubText,
} from "./common"

const Wrap = styled.div`
  height: 486px;
  width: 733px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`
const TypeItem = styled.div`
  background: ${colors.white};
  padding: 17px 27px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  height: 77px;
  gap: 20px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition-duration: 0.3s;
  cursor: pointer;
  ${props => props.isActive && `
    border: 2px solid ${colors.marineBLue};
    background: ${colors.whiteBlueFaded};
  `}


  > p {
    flex: 1;
    padding: 0px;
    margin: 0px;
    font-size: 20px;
    font-weight: 300;
    color: ${colors.black};
  }
`

const CTAWrap = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
`

const SelectRoomType = ({
  createRoomPageSwitch,
}) => {
  const handlePageSwitch = (page, type = null) => {
    createRoomPageSwitch({
      page,
      type,
    })
  }

  return (
    <Wrap>
      <Header>
        <p>Create a Room</p>
        <SubText>
          <p>Choose the type of Room you&apos;d like to host.</p>
        </SubText>
      </Header>
      <CTAWrap>
        <TypeItem onClick={() => handlePageSwitch("room-details")}>
          <img src={VideoRoomIcon} alt="Video Room" />
          <p>
            Video Room
          </p>
        </TypeItem>
        <TypeItem onClick={() => handlePageSwitch("room-details", "scheduled")}>
          <img src={ScheduleRoomIcon} alt="Video Room" />
          <p>
            Schedule a room
          </p>
        </TypeItem>
        <TypeItem onClick={() => handlePageSwitch("room-details", "property")}>
          <img src={HouseTour} alt="Video Room" />
          <p>
            Host a live property tour
          </p>
        </TypeItem>
      </CTAWrap>
    </Wrap>
  )
}

export default Rooms(SelectRoomType)
