import createToast from "@ui/Toast"
import { saveAs } from "file-saver"

export const handleDownload = (url, filename) => {
  try {
    createToast("Downloading File")
    saveAs(
      url,
      filename,
    )
  } catch (e) {
    //
  }
}
