import { isInboundReferrals, isCRS } from "services/Utils"

export const columnConfigList = [
  {
    display_name: "Leads",
    type: "Non Assist Leads",
    value: "Leads",
    textColor: "#4e676a",
    backgroundColor: "#b2ebf2",
    progressPercentage: 25,
    checklist: true,
  },
  {
    display_name: "ACTIVE",
    type: "Non Assist Leads",
    value: "Prospects",
    textColor: "#00ce7d",
    backgroundColor: "#e5faf2",
    progressPercentage: 50,
    checklist: false,
  },
  {
    display_name: "IN CONTRACT",
    type: "Non Assist Leads",
    value: "In contract",
    textColor: "#066752",
    backgroundColor: "#51d3b7",
    progressPercentage: 75,
    checklist: true,
  },
  {
    display_name: "DEALS",
    type: "Non Assist Leads",
    value: "Deals",
    textColor: "#066752",
    backgroundColor: "#51d3b7",
    progressPercentage: 100,
    checklist: false,
  },
  {
    display_name: "ON HOLD",
    type: "Non Assist Leads",
    value: "On Hold",
    textColor: "#918c77",
    backgroundColor: "#fff5d0",
    progressPercentage: 0,
    checklist: false,
  },
  // {
  //   display_name: "ARCHIVED",
  //   type: "Non Assist Leads",
  //   value: "Archived",
  //   textColor: "#918c77",
  //   backgroundColor: "#fff5d0",
  //   progressPercentage: 0,
  //   checklist: false,
  // },
]

export const columnConfig = () => {
  if (isInboundReferrals() && isCRS()) {
    const PENDING = [{
      display_name: "PENDING",
      type: "Inbound Referral Status",
      value: "Pending",
      textColor: "#4e676a",
      backgroundColor: "#b2ebf2",
      progressPercentage: 0,
      checklist: true,
    }]
    return [
      ...PENDING,
      ...columnConfigList,
    ]
  }
  return [
    ...columnConfigList,
  ]
}

export default columnConfig
