import {
  createSignalAction,
  createActionCreator,
  createSimpleCreator,
  createBaseActionCreator,
} from "shared/reduxUtils"

const BASE = "EVENT_ROOMS"

export const getEventRoomDetailsAction = createSignalAction(BASE, "GET_EVENT_ROOM_DETAILS")
export const getScheduledRoomEventDetailsAction = createSignalAction(BASE, "GET_SCHEDULED_ROOMS_DETAILS")
export const joinRoomAsGuestDetailsAction = createSignalAction(BASE, "JOIN_EVENT_ROOM_AS_GUEST_DETAILS")
export const leaveRoomAsGuestDetailsAction = createSignalAction(BASE, "LEAVE_EVENT_ROOM_AS_GUEST_DETAILS")
