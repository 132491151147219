import React, { useRef } from "react"
import CRMV2Container from "container/CRM"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import {
  Form, NewInput, NewButton, Row, Col, Space,
} from "@ui/antd"
import { MinusCircleOutlined } from "@ant-design/icons"
import { SectionHeading, EditCoSellerContainer } from "../styles"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form

const CoSellerDetailsForm = ({
  setShowForm,
  initialValues,
  isShowForm,
  isEditing,
  addCoClientDetails,
  isSeller,
  isBuyer,
  getCtIdResponse,
  updateCoClientDetails,
  getBrokerageTransactionStatusResponse,
  setEditing,
  headingText
}) => {
  const [form] = Form.useForm()
  const formRef = useRef(null)

  const { data } = getCtIdResponse || {}
  const { id, type, client_id } = data || {}

  const { id: clientId } = initialValues || {}

  const { data: brokerageTransactionStatus } = getBrokerageTransactionStatusResponse || {}
  const { transactionType, id: brokerageId } = brokerageTransactionStatus || {}

  const client_type = type || (isSeller ? "seller" : (isBuyer ? "buyer" : ""))

  const onFinish = (values) => {
    setShowForm()
    const payload = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      client_type,
      transaction_id: id,
      client_id,
      deal_id: transactionType === "Listing" || transactionType === "Contract" ? brokerageId : null,
      offer_id: transactionType === "Offer" ? brokerageId : null,
    }
    if (isShowForm) {
      addCoClientDetails({ payload })
    } else if (isEditing) {
      updateCoClientDetails({ payload, id: clientId })
    }
  }

  return (
    <EditCoSellerContainer>
      <Row style={{ marginBottom: "16px" }}>
        <Col flex="auto">
          <SectionHeading>Co-{headingText} Details</SectionHeading>
        </Col>
        <Col>{!isEditing && <MinusCircleOutlined onClick={setShowForm} />}</Col>
      </Row>

      <Form
        name="add_new_lead"
        preserve={false}
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        ref={formRef}
        form={form}
        initialValues={{
          ...initialValues,
          phone: initialValues?.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3"),
        }}
      >
        <Item name="name" rules={[{ required: true, 
      message: 'Please enter name', }]}
        label={<LabelComponent text="Name*" />}>
          <NewInput placeholder="Name" />
        </Item>
        <Item name="phone" normalize={normalizePhone} rules={[
    {
      required: true,
      message: 'Please enter phone number',
    },
    {
      pattern: /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?\s*[-\/.]?\s*\d{3}\s*[-\/.]?\s*\d{4}$/,
      message: 'Phone number must be exactly 10 digits',
    },
  ]}
        label={<LabelComponent text="Phone Number*" />}>
          <NewInput placeholder="Phone Number" />
        </Item>
        <Item name="email" rules={[
      {
        type: 'email',
        message: 'Input valid E-mail!',
      },
      {
        required: true,
        message: 'Please enter e-mail',
      },
    ]}
        label={<LabelComponent text="Email*" />}>
          <NewInput placeholder="Email" />
        </Item>

        <Space>
          <NewButton
            type="primary"
            onClick={() => {
              setEditing(false)
            }}
          >
            Cancel
          </NewButton>
          <NewButton type="primary" htmlType="submit">
            Save
          </NewButton>
        </Space>
      </Form>
    </EditCoSellerContainer>
  )
}

export default CRMV2Container(CoSellerDetailsForm)
