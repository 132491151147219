import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";
import * as BrbcActions from "./actions";
const initState = {
  ...createDeltaReducer(BrbcActions.fetchPresignedBrbcDocs.RESPONSE),
  ...createDeltaReducer(BrbcActions.sendBrbcReminderAction.RESPONSE),
  ...createDeltaReducer(BrbcActions.uploadBrbcAction.RESPONSE),
  ...createDeltaReducer("newBrbcCoversheetReponse"),
  ...createDeltaReducer("voidBrbcReponse"),
  ...createDeltaReducer("voidRestartBrbcReponse"),
  ...createDeltaReducer("sendBrbcEnvelopeResponse"),
  ...createDeltaReducer("fetchBrbcStatusResponse"),
  ...createDeltaReducer("senderViewResponse"),
  ...createDeltaReducer("receiverViewResponse"),
  ...createDeltaReducer("agentDetailsResponse"),
  ...createDeltaReducer("deleteCoBuyerReponse"),
  ...createDeltaReducer("deleteBrbcEnvelopeResponse"),
  ...createDeltaReducer("deleteBrbcDocResponse"),
  ...createDeltaReducer(BrbcActions.voidRestartBrbcAction.RESPONSE),
};

export default function brbcReducer(state = initState, action) {
  switch (action.type) {
    case BrbcActions.fetchPresignedBrbcDocs.REQUEST:
    case BrbcActions.fetchPresignedBrbcDocs.FAILURE:
    case BrbcActions.fetchPresignedBrbcDocs.CLEAR:
    case BrbcActions.fetchPresignedBrbcDocs.SUCCESS: {
      return {
        ...state,
        fetchPresignedBrbcDocsResponse: {
          ...state.fetchPresignedBrbcDocsResponse,
          data: action.data.documents,
          isFetching: false,
        },
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.fetchPresignedBrbcDocs,
          BrbcActions.fetchPresignedBrbcDocs.RESPONSE
        ),
      };
    }

    case BrbcActions.sendBrbcReminderAction.REQUEST:
    case BrbcActions.sendBrbcReminderAction.FAILURE:
    case BrbcActions.sendBrbcReminderAction.CLEAR:
    case BrbcActions.sendBrbcReminderAction.SUCCESS: {
      return {
        ...state,
        sendBrbcReminderResponse: {
          ...state.sendBrbcReminderResponse,
          data: action.data,
          isFetching: false,
        },
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.sendBrbcReminderAction,
          BrbcActions.sendBrbcReminderAction.RESPONSE
        ),
      };
    }

    case BrbcActions.uploadBrbcAction.REQUEST:
    case BrbcActions.uploadBrbcAction.FAILURE:
    case BrbcActions.uploadBrbcAction.CLEAR:
    case BrbcActions.uploadBrbcAction.SUCCESS: {
      return {
        ...state,
        uploadBrbcDocResponse: {
          ...state.uploadBrbcDocResponse,
          data: action.data,
          isFetching: false,
        },
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.uploadBrbcAction,
          BrbcActions.uploadBrbcAction.RESPONSE
        ),
      };
    }

    case BrbcActions.newBrbcCoversheetAction.REQUEST:
    case BrbcActions.newBrbcCoversheetAction.SUCCESS:
    case BrbcActions.newBrbcCoversheetAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.newBrbcCoversheetAction,
          "newBrbcCoversheetReponse"
        ),
      };
    }

    case BrbcActions.fetchBrbcCoversheetAction.REQUEST:
    case BrbcActions.fetchBrbcCoversheetAction.FAILURE:
    case BrbcActions.fetchBrbcCoversheetAction.CLEAR:
    case BrbcActions.fetchBrbcCoversheetAction.SUCCESS: {
      return {
        ...state,
        fetchBrbcCoversheetReponse: {
          ...state.fetchBrbcCoversheetReponse,
          data: action.data,
          isFetching: false,
        },
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.fetchBrbcCoversheetAction,
          BrbcActions.fetchBrbcCoversheetAction.RESPONSE
        ),
      };
    }

    case BrbcActions.voidBrbcAction.REQUEST:
    case BrbcActions.voidBrbcAction.SUCCESS:
    case BrbcActions.voidBrbcAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.voidBrbcAction,
          "voidBrbcReponse"
        ),
      };
    }

    case BrbcActions.voidRestartBrbcAction.REQUEST:
    case BrbcActions.voidRestartBrbcAction.SUCCESS:
    case BrbcActions.voidRestartBrbcAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.voidRestartBrbcAction,
          "voidRestartBrbcReponse"
        ),
      };
    }

    case BrbcActions.sendBrbcEnvelopeAction.REQUEST:
    case BrbcActions.sendBrbcEnvelopeAction.SUCCESS:
    case BrbcActions.sendBrbcEnvelopeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.sendBrbcEnvelopeAction,
          "sendBrbcEnvelopeResponse"
        ),
      };
    }

    case BrbcActions.fetchBRBCStatusAction.REQUEST:
    case BrbcActions.fetchBRBCStatusAction.SUCCESS:
    case BrbcActions.fetchBRBCStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.fetchBRBCStatusAction,
          "fetchBrbcStatusResponse"
        ),
      };
    }

    case BrbcActions.senderViewAction.REQUEST:
    case BrbcActions.senderViewAction.SUCCESS:
    case BrbcActions.senderViewAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.senderViewAction,
          "senderViewResponse"
        ),
      };
    }

    case BrbcActions.receiverViewAction.REQUEST:
    case BrbcActions.receiverViewAction.SUCCESS:
    case BrbcActions.receiverViewAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.receiverViewAction,
          "receiverViewResponse"
        ),
      };
    }

    case BrbcActions.getAgentDetailsFromStateAction.REQUEST:
    case BrbcActions.getAgentDetailsFromStateAction.SUCCESS:
    case BrbcActions.getAgentDetailsFromStateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.getAgentDetailsFromStateAction,
          "agentDetailsResponse"
        ),
      };
    }

    case BrbcActions.deleteCoBuyerAction.REQUEST:
    case BrbcActions.deleteCoBuyerAction.SUCCESS:
    case BrbcActions.deleteCoBuyerAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.deleteCoBuyerAction,
          "deleteCoBuyerReponse"
        ),
      };
    }

    case BrbcActions.deleteBrbcEnvelopeAction.REQUEST:
    case BrbcActions.deleteBrbcEnvelopeAction.SUCCESS:
    case BrbcActions.deleteBrbcEnvelopeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.deleteBrbcEnvelopeAction,
          "deleteBrbcEnvelopeResponse"
        ),
      };
    }

    case BrbcActions.deleteBrbcDocAction.REQUEST:
    case BrbcActions.deleteBrbcDocAction.SUCCESS:
    case BrbcActions.deleteBrbcDocAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrbcActions.deleteBrbcDocAction,
          "deleteBrbcDocResponse"
        ),
      };
    }

    default: {
      return state;
    }
  }
}
