import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import icons from "@icons"
import colors from "@colors"
import Loader from "@ui/Loader"
import { MOBILE_BREAK_POINT, mobile } from "@consts"

export const EVENT_DATE_FORMAT = "dddd, MMM DD, h:mm a"

export const SectionTitle = styled.h2`
  color: ${colors.academyTitleColor};
  font-size: 32px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  margin: 60px 0px 20px 0px;

  ${mobile`
    font-size: 24px;
  `}

  ${props => props.dashboard && `
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
  `}
`

export const PlainTextArea = styled.p`
  font-weight: 300;
  font-size: 14px;
`

export const SectionSubTitle = styled.p`
  font-size: 20px;
  color: ${colors.contentText};
  margin-bottom: 40px;
  ${mobile`
    font-size: 16px;
    font-weight: 300;
  `}
`

export const HR = styled.hr`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ${mobile`
    display: none;
  `}

`

// GoBack Banner
const GoBackBannerWrap = styled(Link)`
  color: ${colors.faintBlueishBlack};
  margin: 20px 0px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  > div {
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 20px;
    i {
      transform: rotate(180deg);
    }
  }

  span {
    font-weight: 600;
  }
`
export const GoBackBanner = ({
  to = "/",
  title = "",
}) => (
  <GoBackBannerWrap to={to}>
    <div>
      <i className={icons.arrowsArrowRight}></i>
    </div>
    <span>{title}</span>
  </GoBackBannerWrap>
)
// ================

// Page Header
const AcademyPageWrap = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 100px;
  padding-bottom: 50px;

  ${mobile`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
  `}
`

const HeaderArea = styled.div`
  padding: 18px 40px;
  display: flex;
  /* ${props => props.pageType !== "event" ? `
     ${mobile`
        display: none;
      `}
  ` : `
    ${mobile`
      background: red;
    `}
  `} */

  ${mobile`
    display: none;
  `}
  > div {
    &:nth-child(1) {
      flex: 1;
      h3 {
        font-size: 14px;
        color: ${colors.dirtBrown};
        font-weight: bold;
      }

      h1 {
        font-size: 22px;
        color: ${colors.contentText};
        margin-top: 10px;
      }
    }

    &:nth-child(2) {
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const PageLoader = () => (
  <Loader top="0" bottom="0" />
)

export const AcademyWrap = ({
  subTitle,
  title,
  rightSection,
  children,
  isFetching,
  pageType,
}) => (
  <AcademyPageWrap>
    <HeaderArea pageType={pageType}>
      <div>
        <h3>{subTitle}</h3>
        <h1>{title}</h1>
      </div>
      <div>
        {rightSection}
      </div>
    </HeaderArea>
    {isFetching ? (
      <PageLoader />
    ) : (
      <React.Fragment>
        {children}
      </React.Fragment>
    )}
  </AcademyPageWrap>
)

export const ContentWrap = styled.div`
  display: flex;
  padding: 0px 48px;

  ${mobile(`
    flex-direction: column;
    padding: 0px 16px;
  `)}
`

export const MainSection = styled.div`
  flex: 2;
  margin-right: 40px;
  overflow: scroll;

  ${mobile`
    margin-right: 0px;
  `}
`

export const SidePanel = styled.div`
  flex: 1;
  max-width: 330px;
`

export const Title = styled.h3`
  margin-top: 40px;
  font-size: 18px;
  color: ${colors.deepSeaBlue};
  font-weight: 500;

  ${props => props.isInFeed && `
    margin-top: 0px;
    font-weight: 300;
    font-size: 16px;
  `}
`

export const CTAButton = styled.button`
  color: ${colors.dirtBrown};
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;

  ${props => props.status === "Start" && `
     background: #392463;
  `}

  ${props => props.status === "Resume Course" && `
     background: ${colors.strawberryPink};
  `}

  ${props => props.status === "Take Again" && `
     background: ${colors.gradientGreen};
  `}

  ${props => !props.isRegistered && props.type === "event" && `
     background: #392463;
  `}

  ${props => props.isRegistered && props.type === "event" && `
    background: ${colors.textDisabledColor};
  `}
`

export const secondsToHms = (d) => {
  const seconds = Number(d)
  const h = Math.floor(seconds / 3600)
  const m = Math.floor(seconds % 3600 / 60)

  const hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : ""
  const mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : ""
  return hDisplay + mDisplay
}

export const secondsInDuration = (seconds = 0) => {
  if (!seconds) {
    return ""
  }
  const mins = parseInt(seconds / 60)
  const hrs = parseFloat((mins / 60)).toFixed(0)
  if (hrs > 0 && mins === 60) {
    return `${hrs} ${hrs > 1 ? "hrs" : "hr"} ${parseFloat(mins % 60).toFixed(0)} mins`
  }
  return `${mins} mins`
}

export const MobileDetails = styled.div`
  display: none;
  ${mobile`
    display: flex;
  `}
  flex-direction: column;
  h6 {
    font-size: 10px;
    color: ${colors.tagInactive}
  }
  > div {
    display: flex;
    h2 {
      font-size: 18px;
      font-weight: 500;
      margin: 0px;
      color: ${colors.contentText};
      flex: 1;
    }

    span {
      color: ${colors.tagInactive};
    }
  }
`

export const imageSizeFix = (url = "") => {
  return url && url.replace(/.jpeg/g, "_650x420.jpeg")
}

export const prettyUrl = (url = "") => url.replace(/[^a-zA-Z ]/g, "").replace(/ /g, "-")
