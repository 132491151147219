export const RADIUS_STATES = [
  {
    value: "California",
    label: "California",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Washington",
    label: "Washington",
  },
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "Alaska",
    label: "Alaska",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Arizona",
    label: "Arizona",
  },
  {
    value: "Arkansas",
    label: "Arkansas",
  },
  {
    value: "Colorado",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    label: "Connecticut",
  },
  {
    value: "Delaware",
    label: "Delaware",
  },
  {
    value: "District Of Columbia",
    label: "District Of Columbia",
  },
  {
    value: "Federated States Of Micronesia",
    label: "Federated States Of Micronesia",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "Idaho",
    label: "Idaho",
  },
  {
    value: "Illinois",
    label: "Illinois",
  },
  {
    value: "Indiana",
    label: "Indiana",
  },
  {
    value: "Iowa",
    label: "Iowa",
  },
  {
    value: "Kansas",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    label: "Louisiana",
  },
  {
    value: "Maine",
    label: "Maine",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Maryland",
    label: "Maryland",
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
  },
  {
    value: "Michigan",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    label: "Minnesota",
  },
  {
    value: "Mississippi",
    label: "Mississippi",
  },
  {
    value: "Missouri",
    label: "Missouri",
  },
  {
    value: "Montana",
    label: "Montana",
  },
  {
    value: "Nebraska",
    label: "Nebraska",
  },
  {
    value: "Nevada",
    label: "Nevada",
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    label: "New Mexico",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "North Carolina",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    label: "North Dakota",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Ohio",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
  },
  {
    value: "South Carolina",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    label: "Tennessee",
  },
  {
    value: "Utah",
    label: "Utah",
  },
  {
    value: "Vermont",
    label: "Vermont",
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
  },
  {
    value: "Virginia",
    label: "Virginia",
  },
  {
    value: "West Virginia",
    label: "West Virginia",
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
  },
  {
    value: "Wyoming",
    label: "Wyoming",
  },
]
