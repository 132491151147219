export const handleJobDetails = (slugFetch, setCall, details, slug) => {
  slugFetch({
    skip: 0,
    limit: 10,
    slug,
  })
  setCall({
    currentJobViewed: details,
  })
}
