import React from "react"
import styled from "styled-components"
import container from "container/App/confirmModalContainer"
import Modal from "@ui/Modal"
import Button from "@ui/Button"
import LoaderButton from "@ui/LoaderButton"
import colors from "@colors"

const Wrap = styled.div`
  padding: 20px;
  text-align: center;
  header {
    font-size: 36px;
    color: ${colors.contentText};
    font-family: 'DM Sans', sans-serif;
  }

  p {
    margin: 40px 0px;
    font-weight: 100;
    font-size: 20px;
  }
`

const ButtonWrap = styled.div``

const ConfirmModal = ({
  title = "Title",
  body = "Enter Title",
  successButtonText = "Confirm",
  cancelButtonText = "Cancel",
  primaryButtonStyles,
  confirm,
  isShowCancel,
  showCloseButton = true,
  isShowConfirmLoader,
  cancel,
}) => (
  <Modal
    show
    toClose={() => { cancel(false) }}
    showCloseButton={showCloseButton}
    modalStyles={{
      background: `${colors.white}`,
    }}
  >
    <Wrap>
      <header>{title}</header>
      <p>{body}</p>
      <ButtonWrap>
        <LoaderButton
          onClick={() => confirm(true)}
          bsStyle={primaryButtonStyles}
          isShowLoader={isShowConfirmLoader}
          width="auto"
        >
          {successButtonText}
        </LoaderButton>
        {isShowCancel && (
          <Button onClick={() => (confirm(false))} bsStyle="attention">
            {cancelButtonText}
          </Button>
        )}
      </ButtonWrap>
    </Wrap>
  </Modal>
)

ConfirmModal.defaultProps = {
  primaryButtonStyles: "attention",
}

export default container(ConfirmModal)
