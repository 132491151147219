import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const OuterWrap = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 44px;

  position: fixed;

  border-radius: 40px;
  border: 1px solid rgba(253, 253, 253, 0.15);
  background: rgba(229, 230, 255, 0.35);
  box-shadow: 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  backdrop-filter: blur(16.799999237060547px);
  transform: translate(-50%);
  bottom: 20px;
  left: 50%;
  z-index: 9;
`

const InnerText = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    /* leading-tight/subtitle-2/semi-bold */
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
    margin: 0;
  }
`

// const SubmitButton = styled(Button)``

const FloatingCTA = ({
  onClick = () => {},
  loading,
  disabled,
  customText,
  type,
  htmlType = "",
}) => (
  <OuterWrap>
    <Button 
        htmlType={htmlType || "text"} 
        disabled={disabled || loading} 
        type={type || "primary"} 
        shape="round" size="large" 
        onClick={onClick} 
        loading={loading}>
      {disabled ? "Processing" : customText || "Submit"}
    </Button>
  </OuterWrap>
)

export default FloatingCTA
