import React, { Component } from "react"
import styled from "styled-components"
import Autosuggest from "react-autosuggest"
import RoundedInput from "@ui/RoundedInput"

const Wrapper = styled.div`
  position: relative;
`

const StyledInputWrapper = styled.div`
  padding: 4px 8px;
`

const SuggestionContainer = styled.div`
  position: absolute;
  background-color: white;
  top: 40px;
  left: 18px;
  width: 340px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
`

const StyledSuggestion = styled.div`
  padding: 12px;
  font-weight: 300;
  color: inherit;
  cursor: pointer;
  user-select: none;
  
  ${props => props.isHighlighted && `
    background-color: #f1f1f1;
  `}
`

export default class FilterAutoSuggest extends Component {
  state = {
    value: "",
    suggestions: [],
  }

  getSuggestionValue = (suggestion) => {
    const { keyedFacadesForCategory } = this.props
    return keyedFacadesForCategory[suggestion]
  }

  getSuggestions = (value) => {
    const { unappliedFiltersForCategory, keyedFacadesForCategory } = this.props;
    const searchedFilters = []
    let numberOfResults = 0
    if (unappliedFiltersForCategory.length > 0) {
      const searchText = value.toLowerCase()
      let i
      for (i = 0; i < unappliedFiltersForCategory.length; i += 1) {
        const filterKey = unappliedFiltersForCategory[i]
        const filterText = keyedFacadesForCategory[filterKey].toLowerCase()
        if (filterText.indexOf(searchText) > -1) {
          searchedFilters.push(filterKey)
          numberOfResults += 1
        }
        if (numberOfResults === 3) {
          break
        }
      }
    }

    return searchedFilters
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionSelected = (event, { suggestion: itemToSelect }) => {
    const { selectSearchFilter, filterCategory } = this.props
    this.setState({
      value: "",
    }, () => {
      selectSearchFilter({
        itemToSelect,
        filterCategory,
      })
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  renderSuggestionsContainer = ({ containerProps , children, query }) => {
    return (
      <SuggestionContainer {... containerProps}>
        {children}
      </SuggestionContainer>
    );
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <StyledSuggestion isHighlighted={isHighlighted}>
        {this.getSuggestionValue(suggestion)}
      </StyledSuggestion>
    )
  }

  renderInputComponent = inputProps => (
    <StyledInputWrapper>
      <RoundedInput>
        <input {...inputProps} />
      </RoundedInput>
    </StyledInputWrapper>
  )

  render() {
    const { value, suggestions } = this.state
    const { placeholder } = this.props

    const inputProps = {
      placeholder: placeholder || "Search",
      value,
      onChange: this.onChange,
    }

    return (
      <Wrapper>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          renderInputComponent={this.renderInputComponent}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={inputProps}
        />
      </Wrapper>
    )
  }
}