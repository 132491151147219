import React from "react"
import {
  Switch,
} from "antd"

/**
 *
 * border-radius: 12px;
background: var(--Color-Primary-Primary, #5A5FF2);
 */

const ToggleSwitch = ({
  onChange,
}) => (
  <Switch defaultChecked onChange={onChange} />
)

export default ToggleSwitch
