import root from "window-or-global"
import { isInboundReferrals, isCRS, getAssociationFromCookies } from "services/Utils"

const isWCR = getAssociationFromCookies.isWcr()

const columnConfigList = [
  {
    display_name: "ACCEPTED",
    type: "Inbound Referral Status",
    value: "Accepted",
    textColor: "#4e676a",
    backgroundColor: "#b2ebf2",
    progressPercentage: 25,
    checklist: true,
  },
  {
    display_name: "ACTIVE",
    type: "Inbound Referral Status",
    value: "Active",
    textColor: "#00ce7d",
    backgroundColor: "#e5faf2",
    progressPercentage: 50,
    checklist: true,
  },
  {
    display_name: "IN CONTRACT",
    type: "Inbound Referral Status",
    value: "In Contract",
    textColor: "#066752",
    backgroundColor: "#51d3b7",
    progressPercentage: 75,
    checklist: true,
  },
  {
    display_name: "CLOSED",
    type: "Inbound Referral Status",
    value: "Closed",
    textColor: "#ff0000",
    backgroundColor: "#f8e0e1",
    progressPercentage: 100,
    checklist: false,
  },
  {
    display_name: "ON HOLD",
    type: "Inbound Referral Status",
    value: "On Hold",
    textColor: "#918c77",
    backgroundColor: "#fff5d0",
    progressPercentage: 0,
    checklist: false,
  },
]

const columnConfig = () => {
  //isInboundReferrals() && (isCRS() || isWCR || )
  const { pathname } = root.location
  if (pathname && pathname.includes("inbound")) {
    const PENDING = [{
      display_name: "PENDING",
      type: "Inbound Referral Status",
      value: "Pending",
      textColor: "#4e676a",
      backgroundColor: "#b2ebf2",
      progressPercentage: 0,
      checklist: true,
    }]
    return [
      ...PENDING,
      ...columnConfigList,
    ]
  }
  return [
    ...columnConfigList,
  ]
}

export default columnConfig
