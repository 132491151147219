import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import {
  Block,
} from "./commonStyles"

const ShimmerLoader = () => (
  <Block>
    <ShimmerBox h="12px" w="61%" mt="10px" ml="18px" />
    <ShimmerBox h="12px" w="30%" mt="10px" ml="18px" />
    <ShimmerBox h="12px" w="30%" mt="10px" ml="18px" />
  </Block>
)

export default ShimmerLoader
