import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TabsWrap = styled.div`
  display: flex;
  background: #F1F1FE;
  border-radius: 14px;
  padding: 3px;
  width: 100%;
  text-align: center;
`

export const TabsInfo = styled.div`
  padding: 6px 6px;
  width: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.isSelected && `
    background: #5A5FF2;
    border-radius: 10px;
  `}
`

export const TabsTitle = styled.p`
  margin: 0;
  border-radius: 10px;
  font-family: ${INTER};
  font-weight: 400;
  font-size: 14px;
  color: #898989;


  ${props => props.isSelected && `
    color: #FDFDFD;
    font-weight: 500;
  `}
`

export const InputWrap = styled.div``
