import axios from "axios"

export const fetchApplicantsApi = query => axios.get(`${API_URL}jobs-applications?${query}`)
export const getAllCreatedJobsApi = query => axios.get(`${API_URL}jobs?${query}`)
export const createJobApi = payload => axios.post(`${API_URL}jobs`, payload)
export const updateJobApi = ({ body, jobId }) => axios.put(`${API_URL}jobs/${jobId}`, body)
export const deleteJobApi = ({ jobId }) => axios.delete(`${API_URL}jobs/${jobId}`)
export const getAllCreatedPublicJobsApi = query => axios.get(`${API_URL}get-all-jobs?${query}`)
export const markJobFavouriteApi = payload => axios.put(`${API_URL}jobs-saved`, payload)
export const applyForJobApplicationAPI = payload => axios.post(`${API_URL}jobs-applications`, payload)
export const updateJobApplicationAPI = payload => axios.put(`${API_URL}jobs-applications`, payload)
export const markSeenForJobAPI = payload => axios.put(`${API_URL}jobs-seen`, payload)
