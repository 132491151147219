import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { get, zip } from "lodash"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import TechAssetsContainer from "container/TechAssets"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, GoogleAutoComplete, Button, AutoComplete,
} from "@ui/antd"
import US_STATES from "shared/US_STATES.json"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
  ${props => props.threebuttons && `
    justify-content: space-between;
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    cityFormValue: "",
    addingVendor: false,
  }

  setLocaCity = (value) => {
    this.setState({
      cityFormValue: value,
    })
  }

  formRef = React.createRef();

  setCities = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      vendor_city: `${locationDetails.city || ""}`,
      vendor_state: `${locationDetails.state || ""}`,
    })
  }

  onFinish = (fieldValues) => {
    const {
      updateListingCoverSheet, history, dealToView, escrowIdToEdit,
    } = this.props
    const { cityFormValue } = this.state
    const payload = {
      ...fieldValues,
      draft: "N",
    }
    updateListingCoverSheet({
      payload: {
        ...payload,
        current_listing_step: "insert_vendor",
      },
      listing_id: dealToView,
      callAPI: ["tc_info", "select_vendor"],
      nextRoute: "/realty/transaction-coordination/contract/vendors",
      type: escrowIdToEdit ? "vendor_update" : null,
      vendorId: escrowIdToEdit,
      history,
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse, escrowIdToEdit } = this.props
    const selectVendor = getListingCoverSheetResponse.data?.selectVendor
    const result = {}
    if (selectVendor && escrowIdToEdit) {
      return {
        ...selectVendor.find(vn => vn.id === escrowIdToEdit),
      }
    }
    return result
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    console.log("==contacts===", agentGoogleContacts)
    return agentGoogleContacts && agentGoogleContacts.length ? agentGoogleContacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: `${ct.names[0].displayName}`, key: index })) : []
  }

  changeOtherFieldsValues = (objString, option) => {
    console.log("==payload=values=", objString)
    let { agentGoogleContacts } = this.props
    agentGoogleContacts = agentGoogleContacts.filter(ct => ct.names)
    const name = objString
    const nameArray = name.split(" ")
    const contactIndex = option.key
    const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
    const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
    if (phoneNumber || emailAddresses) {
      this.formRef.current.setFieldsValue({
        vendor_officer_first_name: nameArray[0],
        vendor_officer_last_name: nameArray[1] || "",
        vendor_email: emailAddresses,
        vendor_phone: phoneNumber,
      })
    }
  }

  setStreet = (location) => {
    console.log("==payload=values=location", location)
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      county: get(location, "administrative_area_level_2.long_name"),
    }
    let neighborhood = ""
    let zipcode = ""
    let county = ""
    if (location.street_number) {
      neighborhood += `${location.street_number.long_name} `
    }
    if (location.route) {
      neighborhood += location.route.long_name
    }
    if (location.postal_code) {
      zipcode = location.postal_code.long_name
    }
    if (locationDetails.county) {
      locationDetails.county = locationDetails.county.split(" County")?.[0]
      county = locationDetails.county
    }
    this.formRef.current.setFieldsValue({
      vendor_city: `${locationDetails.city || ""}`,
      vendor_state: `${locationDetails.state || ""}`,
      country: `${locationDetails.country}`,
      vendor_zipcode: zipcode,
      county,
    })
    if (neighborhood) {
      this.formRef.current.setFieldsValue({
        vendor_address: neighborhood || "",
      })
    }
  }

  render() {
    const { cityFormValue, addingVendor } = this.state
    const { history, getContractByIdResponse } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    const enableTC = ["California", "Oregon", "Washington"]
    const state = getContractByIdResponse.data?.[0]?.state
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Escrow Information
        </HeaderNav>
        <ProgressBarContainer>
        </ProgressBarContainer>
        <Container>
          <RightSideBar>
            <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} preserve={false} initialValues={this.getInitialValues()} ref={this.formRef}>
              <Form.Item name="vendor_type" label={<LabelComponent text="Vendor Type" required />} rules={commonRules} >
                <Select>
                  <Select.Option value="title">Title </Select.Option>
                  <Select.Option value="escrow">Escrow </Select.Option>
                  <Select.Option value="title_and_escrow">Title and Escrow </Select.Option>
                  <Select.Option value="closing_attorney">Closing Attorney </Select.Option>
                </Select>
              </Form.Item>
              <FlexBox>
                <Form.Item name="vendor_name" label={<LabelComponent text="Company Name" required />} rules={commonRules} >
                  <Input />
                </Form.Item>
                {/* <Form.Item name="vendor_number" label={<LabelComponent text="Company Number" required />} rules={commonRules}>
                  <Input />
                </Form.Item> */}
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_officer_first_name" label={<LabelComponent text="Officer First Name" required />} rules={commonRules} >
                  <AutoComplete
                    filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    options={this.getOptions()}
                    onChange={(value, option) => this.changeOtherFieldsValues(value, option)}
                  />
                  {/* <Input /> */}
                </Form.Item>
                <Form.Item name="vendor_officer_last_name" label={<LabelComponent text="Officer Last Name" required />} rules={commonRules} >
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item
                  name="vendor_email"
                  label={<LabelComponent text="Email" required />}
                  required
                  rules={[{ required: true, type: "email", message: "Invalid Email" }]}
                  
                >
                  <Input />
                </Form.Item>
                <Form.Item name="vendor_phone" label={<LabelComponent text="Phone Number" required />} rules={commonRules} >
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_address" label={<LabelComponent text="Company Address" required />} rules={commonRules} >
                  {/* <Input /> */}
                  <GoogleAutoComplete
                    id="cities"
                    types={["address"]}
                    name="cities"
                    // formValue={cityFormValue}
                    onChange={(e) => { }}
                    location={(loc) => { this.setStreet(loc) }}
                    placeholder=""
                    styles={{
                      height: "49px",
                      borderRadius: "0px",
                      background: "white",
                      fontSize: "16px",
                      marginTop: "10px",
                    }}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Form.Item name="vendor_city" label={<LabelComponent text="City" required />} rules={commonRules} >
                  <GoogleAutoComplete
                    id="cities"
                    types={["(cities)"]}
                    name="cities"
                    onChange={(e) => { this.setLocaCity(e.target.value) }}
                    location={(loc) => { this.setCities(loc) }}
                    placeholder=""
                    styles={{
                      height: "49px",
                      borderRadius: "0px",
                      background: "white",
                      fontSize: "16px",
                      marginTop: "10px",
                    }}
                  />
                </Form.Item>

                <Form.Item name="vendor_state" label={<LabelComponent text="State" required />} rules={commonRules} >
                  {/* <Select> */}
                  <Input />
                  {/* {STATE_OPTIONS.map(st => (
                      <Select.Option value={st.value}>{st.label}</Select.Option>
                    ))} */}
                  {/* </Select> */}
                </Form.Item>

                <Form.Item
                  name="vendor_zipcode"
                  label={<LabelComponent text="Zip Code" required />}
                  rules={[{ required: true, message: "Invalid Input", type: "string", pattern: new RegExp(/^\d{5,}$/), },]}
                  
                >
                  <Input />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <FlexBox wd="100%"></FlexBox>
                <FlexBox threebuttons>
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "white",
                        float: "right",
                        borderColor: "#3B60E4",
                        color: "#3B60E4",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      // htmlType="submit"
                      onClick={() => { history.push("/realty/transaction-coordination/contract/tc-info") }}
                    >
                      Back
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    {enableTC.includes(state) ? (
                      <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={() => { history.push("/realty/transaction-coordination/contract/upload?fromTC=true") }}
                    >
                      Skip
                    </Button>
                    ) : null}
                    </Form.Item>
                    <Form.Item>
                      <Button
                      style={{
                        width: 140,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                    // onClick={() => { history.push("/realty/listings/coversheet/tc-info") }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </FlexBox>
              </FlexBox>
            </Form>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
