import { createSelector } from "reselect"
import root from "window-or-global"
import {
  forOwn,
  isObject,
  isString,
  isArray,
} from "lodash"
import { authCheck } from "services/auth"
import {
  getIsUserATeamOwner,
  getIsUserATeamMember,
  getIsUserATeamAdmin,
  getIsUserABroker,
} from "container/App/selector"

const BASE64REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

const getAsItis = state => state
const getAccessTo = state => state.academy.courseDetails.data && state.academy.courseDetails.data.access_to
const getUser = state => state.app.user

export const convertToRichText = createSelector([
  getAsItis,
], (data) => {
  const parsedData = loopThroughAllKey(data)
  return parsedData
})

const loopThroughAllKey = (data) => {
  const newObj = {}
  forOwn(data, (value, key) => {
    if (isArray(value)) {
      newObj[key] = value.map(item => isObject(item) ? loopThroughAllKey(item) : item)
    } else if (isObject(value)) {
      newObj[key] = loopThroughAllKey(value)
    } else if (isString(value) && BASE64REGEX.test(value)) {
      try {
        const stringRaw = decodeURIComponent(escape(root.atob(value)))
        const parsedJson = JSON.parse(stringRaw)
        newObj[`${key}_RTF`] = parsedJson
        newObj[key] = value
      } catch (error) {
        newObj[key] = value
      }
    } else {
      newObj[key] = value
    }
  })
  return newObj
}

export const createAccessToArray = createSelector(
  [getAccessTo, getAsItis],
  (accessTo, state) => {
    const isTeamOwner = getIsUserATeamOwner(state)
    const isTeamMember = getIsUserATeamMember(state)
    const isAdmin = getIsUserATeamAdmin(state)
    return {
      team: isTeamOwner || isTeamMember || isAdmin,
      registred: authCheck(),
      broker: getIsUserABroker(state),
    }
  }
)
