import React from "react"
import ReferralContainer from "container/Referral"
import { InnerTab, NavLink } from "./commonStyles"
import CRM from "../Icons/NewIcons/CRM"

const CRMTab = ({ routeArray, isBroker }) => (
  <>
    {isBroker && (
      <InnerTab isActive={routeArray[0] === "crm"}>
        <div className="icon-block-new">
          <CRM />
        </div>
        <div className="text-block">
          <NavLink to="/crm" isActive={routeArray[0] === "crm"}>
            My Active Clients
          </NavLink>
        </div>
      </InnerTab>
    )}
  </>
)

export default ReferralContainer(CRMTab)
