import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import DocumentLibrary from "../Icons/NewIcons/DocumentLibrary"

const ResourcesTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    isActive={routeArray[1] === "resources" || routeArray[1] === "document_library"}
  >
    <div className="icon-block-new">
      <DocumentLibrary />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? "/realty/resources" : "/realty/promos?type=document_library"}
        isActive={routeArray[1] === "resources" || routeArray[1] === "document_library"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_ResourcesRAR_web")
          }
        }}
      >
        Document Library
      </NavLink>
    </div>
  </InnerTab>
)

export default ResourcesTab
