import React from "react";
import {
  Header,
  ScoreWrap,
  ScoreCard,
  ScoreDetails,
  ScoreType,
  ScoreDesc,
  Score,
  Wrap,
  ScoreDiv,
} from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const GettingAround = ({ listingPageResponse }) => {
  return (
    <>
      <Header>Getting Around</Header>
      <Wrap>
        {listingPageResponse?.data?.gettingAround?.bikeScore?.score && (
          <ScoreWrap>
            <ScoreCard>
              <img
                src={`${CLOUDFRONT}/SSRListing/bikescore.svg`}
                style={{ width: "32px", height: "32px" }}
                alt="WalkScore"
              />
            </ScoreCard>
            <ScoreDetails>
              <ScoreType>Bike Score®</ScoreType>
              <ScoreDiv>
                <Score style={{ color: "#5A5FF2" }}>
                  {listingPageResponse?.data?.gettingAround?.bikeScore?.score}
                </Score>
                <Score>/ 100</Score>
              </ScoreDiv>
              <ScoreDesc>
                {
                  listingPageResponse?.data?.gettingAround?.bikeScore
                    ?.description
                }
              </ScoreDesc>
            </ScoreDetails>
          </ScoreWrap>
        )}

        {listingPageResponse?.data?.gettingAround?.walkScore?.score && (
          <ScoreWrap style={{ marginLeft: "20px" }}>
            <ScoreCard>
              <img
                src={`${CLOUDFRONT}/SSRListing/walkscore.svg`}
                style={{ width: "32px", height: "32px" }}
                alt="WalkScore"
              />
            </ScoreCard>
            <ScoreDetails>
              <ScoreType>Walk Score®</ScoreType>
              <ScoreDiv>
                <Score style={{ color: "#5A5FF2" }}>
                  {" "}
                  {listingPageResponse?.data?.gettingAround?.walkScore?.score}
                </Score>
                <Score>/ 100</Score>
              </ScoreDiv>
              <ScoreDesc>
                {
                  listingPageResponse?.data?.gettingAround?.walkScore
                    ?.description
                }
              </ScoreDesc>
            </ScoreDetails>
          </ScoreWrap>
        )}
      </Wrap>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );
};

export default ListingPageContainer(GettingAround);
