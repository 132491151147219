import * as React from "react"

const Concierge = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9.685v-3a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v3"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 16.685a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a2 2 0 1 0-4 0v2H7v-2a2 2 0 1 0-4 0v5ZM5 18.685v2M19 18.685v2"
      />
    </svg>
  )
}

export default Concierge
