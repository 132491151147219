import React from "react"
import styled from "styled-components"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import Form from "./Form"
import { YouGotMailWrap } from "../commonStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/CrossVectorNew.png

const Wrap = styled.div`
  width: ${props => props.width || "543px"};
  height: max-content;
  border-radius: 9px;
  background: #F0F1FF;
  opacity: 0;
  padding: 19px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;

  animation: popUp 0.7s cubic-bezier(0,1.02,1,1.14) forwards;

  @keyframes popUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ${props => props.noBorder && `
    border-top: unset;
    background: transparent;
    box-shadow: unset;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  `}
`

const Text = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 153%; /* 19.89px */

  span {
    color: #5A5FF2;
    font-family: ${INTER};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 153%;
  }

  ${props => props.noBorder && `
    color: #fff;
  `}
`

const CrossButtonWrap = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;

  img {
    width: 10.488px;
    height: 10.488px;
  }
`

const AppStampWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 15px;

  img {
    width: 100px;

    ${props => props.footer && `
      filter: invert(1);
    `}
  }

  ${props => props.footer && `
      margin-bottom: 0;
  `}
`

const FooterTitle = styled.p`
  color: #B6B8FF;
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const FormWrap = styled.div``

const AppForm = ({
  toggle,
  noBorder: footer,
  appDownloadSMS,
  appDownloadSMSData,
  submittingForm,
  className,
  width,
}) => {
  const handleOnSubmit = (value) => {
    const payload = {
      phoneNumber: value.phone,
      email: value.email,
    }
    appDownloadSMS({ payload })
  }

  return (
    <Wrap
      width={width}
      noBorder={footer}
    >
      {!footer && (
        <CrossButtonWrap
          onClick={() => {
            toggle(false)
          }}
        >
          <img src={`${CLOUDFRONT}/CrossVectorNew.png`} alt="cross.png" />
        </CrossButtonWrap>
      )}
      {appDownloadSMSData ? (
        <YouGotMailWrap
          footer={footer}
        >
          <img src={`${CLOUDFRONT}/MailIncoming.png`} alt="mail" />
          <h2>You&apos;ve got mail</h2>
          <h3>Please check your texts and email for the Radius app download link</h3>
          <button
            type="button"
            onClick={() => {
              appDownloadSMS({ payload: appDownloadSMSData, reset: true })
            }}
            onKeyDown={() => { }}
          >
            <p>
              {submittingForm ? "Sending" : "Haven't received the link? Click here to re-send"}
            </p>
          </button>
        </YouGotMailWrap>
      ) : (
        <>
          {footer && (
            <FooterTitle>
              Join Our Community
            </FooterTitle>
          )}
          <Text
            noBorder={footer}
          >
            Enjoy the benefits of a community
            {" "}
            <span>85,000</span>
            {" "}
            strong … in the palm of your hand. Request a link to download the Radius app!
          </Text>
          <FormWrap>
            <Form
              className={className}
              onSubmit={handleOnSubmit}
              footer={footer}
              isSubmitting={submittingForm}
            />
          </FormWrap>
          <AppStampWrap
            footer={footer}
          >
            <img src={`${CLOUDFRONT}/AppStamp.png`} alt="ios_droid_radius" />
          </AppStampWrap>
        </>
      )}
    </Wrap>
  )
}

export default ApplyFlowContainer(AppForm)
