import React from "react"
import styled from "styled-components"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import Form from "./Form"
import { YouGotMailWrap } from "../commonStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/CrossVectorNew.png

const Wrap = styled.div`
  width: ${props => props.width ? props.width : "543px"};
  border-radius: 9px;
  background: #fff;
  opacity: 0;
  border-top: 4px solid #FFAB55;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  padding-top: 29px;
  padding-left: 64px;
  padding-right: 64px;
  padding-bottom: 29px;


  animation: popUp 0.7s cubic-bezier(0,1.02,1,1.14) forwards;

  @keyframes popUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


  ${props => props.inForm && `
    width: 100%;
    padding: 26px; 16px;
  `}
`

const Text = styled.p`
  color: #5A5FF2;
  text-align: left;
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.105px;
  text-transform: capitalize;
  margin: 0;


  ${props => props.inForm && `
    color: #5A5FF2;
    text-align: left;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 21.073px */
    text-transform: capitalize;
  `}
`

const AppStampWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 35px;
  margin-bottom: 30px;

  img {
    width: 100px;
  }
`

const SubText = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.48px;

  ${props => props.inForm && `
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 153%; /* 18.36px */
  `}
`

const FormWrap = styled.div``

const AppFormV2 = ({
  noBorder,
  appDownloadSMS,
  appDownloadActionResponse,
  inForm,
  appDownloadSMSData,
  isSubmitting,
  width,
  className,
}) => {
  const { isFetching, message } = appDownloadActionResponse || {}
  
  const handleOnSubmit = (value) => {
    const payload = {
      phoneNumber: value.phone,
      email: value.email,
    }
    appDownloadSMS({ payload })
  }

  return (
    <Wrap
      noBorder={noBorder}
      inForm={inForm}
      width={width}
    >
      {appDownloadSMSData ? (
        <YouGotMailWrap>
          <img src={`${CLOUDFRONT}/MailIncoming.png`} alt="mail" />
          <h2>You&apos;ve got mail</h2>
          <h3>Please check your texts and email for the Radius app download link</h3>
          <button
            type="button"
            onClick={() => {
              appDownloadSMS({ payload: appDownloadSMSData, reset: true })
            }}
            onKeyDown={() => { }}
          >
            <p>
              {isFetching ? "Sending" : "Haven't received the link? Click here to re-send"}
            </p>
          </button>
        </YouGotMailWrap>
      ) : (
        <>
          <Text
            inForm={inForm}
          >
            Join Our Community
          </Text>
          <SubText
            noBorder={noBorder}
            inForm={inForm}
          >
            Request a link to download the Radius app!
          </SubText>
          <FormWrap>
            <Form
              onSubmit={handleOnSubmit}
              isSubmitting={isSubmitting}
              inForm={inForm}
              className={className}
            />
          </FormWrap>
          <AppStampWrap>
            <img src={`${CLOUDFRONT}/AppStamp.png`} alt="ios_droid_radius" />
          </AppStampWrap>
        </>
      )}
    </Wrap>
  )
}

export default ApplyFlowContainer(AppFormV2)
