import React from "react";
import {
  CircleModalDiv,
  RemoveTitleModal,
  ModalDesc,
  ModalNoteDiv,
} from "../ListingsV2/Components/ListingCoversheet/components/ListingsForms/Forms/commonStyles";
import { Modal } from "antd";
import PurpleWarnIcon from "images/icons/purple-warn-icon";

const DeleteModal = ({
  visible,
  onOk,
  onCancel,
  heading,
  subText,
}) => {
  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Remove"
      okButtonProps={{ danger: true }}
      cancelText="Cancel"
    >
      <div>
        <CircleModalDiv>
          <PurpleWarnIcon />
        </CircleModalDiv>
        <RemoveTitleModal>
          {heading
            ? heading
            : "Remove Agent"}
        </RemoveTitleModal>
        <ModalDesc>
          {subText
            ? subText
            : "Are you sure you want to remove this agent? This action cannot be undone. The agent will lose access to all associated resources and permissions."}
        </ModalDesc>
      </div>
    </Modal>
  );
};

export default DeleteModal;
