import colors from "@colors"

const DEFAULT_BLACK = "#2a2a2a"
const DEFAULT_WHITE = "#fffefe"

const isValidHex = (hex) => {
  return /^#[0-9A-F]{6}$/i.test(hex)
}

export const isDarkColor = (color) => {
  const colorString = color.charAt(0) === "#" ? color.substring(1, 7) : color
  const r = parseInt(colorString.substring(0, 2), 16) // hexToR
  const g = parseInt(colorString.substring(2, 4), 16) // hexToG
  const b = parseInt(colorString.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }

    return Math.pow((col + 0.055) / 1.055, 2.4)
  })

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]

  return L <= 0.179
}

export const getAppColorScheme = (teamDetails) => {
  const scheme = {
    primary: "",
    background: "",
    isDefaultColorScheme: true,
    cssVariables: {
      // Sidebar
      sidebarBackground: colors.new_grey400,
      sidebarItemTextColor: colors.new_colorNeutral200,
      sidebarItemActiveBackground: colors.new_primary,
      sidebarItemActiveTextColor: colors.new_colorNeutral200,
      sidebarBorder: "linear-gradient(106.11deg, #B6B8FF 0.52%, #5A5FF2 36.66%, #F08068 80.27%, #EFA367 100%)",
    },
  }

  if (teamDetails?.brand_background_color?.length > 0 && isValidHex(teamDetails.brand_background_color)) {
    scheme.isDefaultColorScheme = false
    scheme.background = teamDetails.brand_background_color

    const isBrandBackgroundColorDark = isDarkColor(teamDetails.brand_background_color)

    // Sidebar
    scheme.cssVariables.sidebarBackground = teamDetails.brand_background_color
    scheme.cssVariables.sidebarItemTextColor = isBrandBackgroundColorDark ? DEFAULT_WHITE : DEFAULT_BLACK
  }

  if (teamDetails?.brand_primary_color?.length > 0 && isValidHex(teamDetails.brand_primary_color)) {
    scheme.isDefaultColorScheme = false
    scheme.primary = teamDetails.brand_primary_color

    const isBrandPrimaryColorDark = isDarkColor(teamDetails.brand_primary_color)

    // Sidebar
    scheme.cssVariables.sidebarItemActiveBackground = teamDetails.brand_primary_color
    scheme.cssVariables.sidebarItemActiveTextColor = isBrandPrimaryColorDark ? DEFAULT_WHITE : DEFAULT_BLACK
    scheme.cssVariables.sidebarBorder = teamDetails.brand_primary_color
  }

  if (
    teamDetails?.brand_background_color?.length > 0
    && teamDetails?.brand_primary_color?.length > 0
    && teamDetails.brand_background_color === teamDetails.brand_primary_color
    && isValidHex(teamDetails.brand_background_color)
    && isValidHex(teamDetails.brand_primary_color)
  ) {
    const isBrandPrimaryAndBackgroundColorDark = isDarkColor(teamDetails.brand_primary_color)

    // Sidebar
    scheme.cssVariables.sidebarItemActiveBackground = isBrandPrimaryAndBackgroundColorDark ? DEFAULT_WHITE : DEFAULT_BLACK
    scheme.cssVariables.sidebarItemActiveTextColor = isBrandPrimaryAndBackgroundColorDark ? teamDetails.brand_primary_color : DEFAULT_WHITE
    scheme.cssVariables.sidebarBorder = isBrandPrimaryAndBackgroundColorDark ? DEFAULT_WHITE : DEFAULT_BLACK
  }

  return scheme
}
