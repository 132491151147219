import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import * as AppActions from "container/App/actions"
import * as PubNubActions from "./actions"


const mapStateToProps = (state) => {
  const { pubNub, app } = state
  const {
    feedLength,
    questionLength,
  } = app
  const isLogged = authCheck()
  return {
    isLogged,
    ...pubNub,
    feedLength,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleNewPostsCTA: PubNubActions.toggleNewPostsCTAAction.call,
      showNewFeedsAvailable: AppActions.showNewFeedsAvailableAction,
      showNewQuestionAvailable: AppActions.showNewQuestionAvailableAction,
      currentMessageRecieved: PubNubActions.currentMessageRecievedAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
