import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Concierge from "../Icons/NewIcons/Concierge"

const StagingTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    wpt="3px"
    isActive={routeArray[1] === "presale" || routeArray[1] === "staging"}
  >
    <div className="icon-block-new">
      <Concierge />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/promos?type=staging"
        isActive={routeArray[1] === "presale" || routeArray[1] === "staging"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_presaleRAR_web")
          }
        }}
      >
        Concierge
      </NavLink>
    </div>
  </InnerTab>
)

export default StagingTab
