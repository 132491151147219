import React from "react";
import {
  ListPoints,
  ListBullet,
  ListWrapper,
  Heading,
  HighlightsWrapper,
  HighlightsCard,
} from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import YearBuilt from "../Icons/yearBuilt.js";
import SquareArea from "../Icons/squareArea.js";
import PricePerUnit from "../Icons/pricePerUnit.js";

const Highlights = ({ listingPageResponse }) => {
  return (
    <HighlightsWrapper>
      {listingPageResponse?.data?.highLights?.map((highlight) => (
        <>
          {highlight.label === "Year Built" && (
            <HighlightsCard key={highlight.label}>
              <ListWrapper>
                <ListPoints>
                  <YearBuilt style={{ marginRight: "8px" }} /> Built in{" "}
                  {highlight.value}
                </ListPoints>
              </ListWrapper>
            </HighlightsCard>
          )}
          {highlight.label === "Building Area Total" && (
            <HighlightsCard key={highlight.label}>
              <ListWrapper>
                <ListPoints>
                  <SquareArea style={{ marginRight: "8px" }} />{" "}
                  {highlight.value}
                </ListPoints>
              </ListWrapper>
            </HighlightsCard>
          )}
          {highlight.label === "Price Per Unit" && (
            <HighlightsCard key={highlight.label}>
              <ListWrapper>
                <ListPoints>
                  <PricePerUnit style={{ marginRight: "8px" }} />{" "}
                  {highlight.value}
                </ListPoints>
              </ListWrapper>
            </HighlightsCard>
          )}
        </>
      ))}
    </HighlightsWrapper>
  );
};

export default ListingPageContainer(Highlights);
