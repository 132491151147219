import React from "react"
import root from "window-or-global"
import { checkForUserObject } from "services/Utils"
import { DEFAULT_REFERRAL_MANAGER } from "shared/consts"

class ASMChat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const user = checkForUserObject("user")
    const { referralManager } = this.props
    const { firstname: referralManagerFirstName } = referralManager
    const {
      firstname = "",
      lastname = "",
      email = "",
      phone = "",
    } = user
    setTimeout(() => {
      if (root.fcWidget) {
        root.fcWidget.init({
          token: "a7fdf764-0221-49c4-abac-3297f6cee338",
          host: "https://wchat.freshchat.com",
          tags: [referralManagerFirstName],
          externalId: email,
          firstName: firstname,
          phone,
          lastName: lastname,
          email,
          config: {
            headerProperty: {
              hideChatButton: true,
            },
          },
        })
        root.fcWidget.user.setProperties({
          ASM: referralManagerFirstName,
        })
      }
    }, 2000)
  }

  render() {
    return null
  }
}

export default ASMChat
