import React from "react"
import { CollapsibleForm, Success } from "./components"
import {
  MenuUiWrap,
} from "./styles"

const ContractDetails = () => (
  <MenuUiWrap>
    <CollapsibleForm
      contractDetails
    />
    <Success />
  </MenuUiWrap>
)

export default ContractDetails
