import styled from "styled-components"

export const MenuUiWrap = styled.div`
  border-radius: 9px;
  border: 1px solid #EBEBEB;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  border-radius: 14px;
  background: #FFF;
  box-shadow: 6px 6px 30px 0px rgba(125, 153, 180, 0.20), 2px 2px 4px 0px rgba(86, 144, 198, 0.11);
  width: 100%;
  position: relative;
`
