import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Listings from "container/Listings";
import ListingsV2 from "container/ListingsV2";
import OffersV2 from "container/OffersV2"
import { SelectField } from "dumbComponents/common/InputFields";
import { Select, Input, Dropdown } from "@ui/antd";
import { withRouter } from "react-router-dom"
import root from "window-or-global"
import query from "query-string"
import StatusShimmer from "./StatusShimmer";
import DocumentShimmer from "dumbComponents/ListingsV2/Components/DocumentShimmer"
import createToast from "dumbComponents/ListingsV2/Components/Toast/customToast"
import {
  CheckListSelect,
  CheckListWrap,
  CheckListEmpty,
  AdditionalText,
} from "./styles";
import CheckListView from "../CheckListView/index";
import {
  FloatingCTA,
  FloatingCTAEnvelopes,
} from "dumbComponents/common/InputFields";
import {
  getBrokerageType
} from "dumbComponents/Compliance/utils"
import {
  OFFERS, LISTINGS, PURCHASE
} from "dumbComponents/OffersV3/consts"
import LoadingDocumentsModal from "../../../common/LoadingDocumentsModal/index";

const CheckList = ({
  currentDocumentTab,
  stateForOffer,
  currentState,
  fetchChecklistMetadata,
  fetchChecklistMetadataResponse,
  setChecklistTemplate, //shrungi - check if needed
  checklistTemplate,
  updateChecklistTemplateMetadata,
  updateChecklistTemplateMetadataResponse,
  fetchChecklistTemplate,
  fetchChecklistTemplateResponse,
  listingIdToView,
  checklistObject,
  updateChecklistObject,
  toggleDocumentTab,
  createEnvelopeChecklist,
  createEnvelopeChecklistResponse,
  dealId,
  listingData,
  fetchEnvelopeListingsResponse,
  history,
  history: {
    location,
  },
  dealToView,
  offerIdToView,
}) => {
  const currentBrokerageType = getBrokerageType(location)
  const isOffers = history && location.pathname.includes("/realty/offers-v3/")
  const [openLoader, setOpenLoader] = useState(false)
  useEffect(() => {
    if(currentBrokerageType == OFFERS){
      fetchChecklistTemplate({
        type:"offers",
        offerId: offerIdToView || dealToView,
        callback: (res) => {
          setChecklistTemplate(res.checklistTemplate);
        },
      });
      const payload = {
        type: "checklistTemplateMetadata",
        complianceType: PURCHASE, //shrungi - make is according to history -> find listing/offer
        state: stateForOffer,
      };
      fetchChecklistMetadata({
        type: "checklistTemplateMetadata",
        payload,
      });
    } else if (currentBrokerageType == LISTINGS) {
      fetchChecklistTemplate({
        type:LISTINGS,
        listingId: listingIdToView,
        callback: (res) => {
          setChecklistTemplate(res.checklistTemplate);
        },
      });
      const payload = {
        type: "checklistTemplateMetadata",
        complianceType: LISTINGS, //shrungi - make is according to history -> find listing/offer
        state: currentState,
      };
      fetchChecklistMetadata({
        type: "checklistTemplateMetadata",
        payload,
      });

    }
  }, []);

  const { isFetching, data: checklistTemplates } =
    fetchChecklistMetadataResponse || {};

  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (createEnvelopeChecklistResponse?.isError) {
      setSaving(false)
      setOpenLoader(false);
      }
  },[createEnvelopeChecklistResponse])
  const handleCreateEnvelopes = () => {
    let documentItemIds = [];
    setSaving(true);
    const currChecklist = [...checklistObject];
    currChecklist.forEach((item) => {
      item.document_items.forEach((documentItem) => {
        if (documentItem.isSelected) {
          documentItemIds.push(documentItem.document_item_id);
        }
      });
    });
    if(documentItemIds.length == 0){
      createToast("Please select documents to create envelope","error")
      setSaving(false);
      return
    }
    setOpenLoader(true);
    let payload 
    if(currentBrokerageType == OFFERS){
      payload = {
        offerId: offerIdToView || dealToView,
        documentItemIds
      }
    } else {
      payload = {
        listingId: listingIdToView,
        documentItemIds,
      };
    }
    createEnvelopeChecklist({
      type:currentBrokerageType,
      id: ((currentBrokerageType === OFFERS) ? (offerIdToView || dealToView) : listingIdToView),
      payload,
      callback: () => {
        setSaving(false)
        setOpenLoader(false);
        toggleDocumentTab("offer_package")
        // createToast("Added the selected documents to an envelope.", "success")
      },
    });
  };
  const handleChecklistTemplate = (value) => {
    setChecklistTemplate(value);
    const selectedTemplate = checklistTemplates.find(
      (template) => template.value === value
    );
    if(currentBrokerageType === OFFERS){
      const payload = {
        offerId: (offerIdToView || dealToView), //shrungi setListingID here
        checklistTemplateId: selectedTemplate.id,
      };
      updateChecklistTemplateMetadata({
        type:"offers",
        payload,
        callback: () => {
          fetchChecklistTemplate({
            type:"offers",
            offerId: (offerIdToView || dealToView),
          });
        },
      });
    } else {
      const payload = {
        listingId: listingIdToView, //shrungi setListingID here
        checklistTemplateId: selectedTemplate.id,
      };
      updateChecklistTemplateMetadata({
        type:"listings",
        payload,
        callback: () => {
          fetchChecklistTemplate({
            type:"listings",
            listingId: listingIdToView,
          });
        },
      });
    }
  };
  const { isFetching: fetchingTemplates, data: templates } =
    fetchChecklistTemplateResponse || {};

  const {isFetching: updatingTemplates} = updateChecklistTemplateMetadataResponse || {}
  const handleSelect = (e, eID, itemName) => {
    const checked = e.target.checked;
    const currChecklist = [...checklistObject];
    currChecklist.forEach((item) => {
      if (item.checklist === itemName) {
        item.document_items.forEach((documentItem) => {
          if (documentItem.document_item_id == eID) {
            documentItem.isSelected = checked;
          }
        });
      }
    });
    updateChecklistObject(currChecklist);
  };

  const selectAll = (itemChecklist) => {
    const currChecklist = [...checklistObject];
    currChecklist.forEach((item) => {
      if(item.checklist === itemChecklist) {
        item.document_items.forEach((documentItem) => {
          documentItem.isSelected = true;
        })
      }
    })
    updateChecklistObject(currChecklist);
  }

  const deselectAll = (itemChecklist) => {
    const currChecklist = [...checklistObject];
    currChecklist.forEach((item) => {
      if(item.checklist === itemChecklist) {
        item.document_items.forEach((documentItem) => {
          documentItem.isSelected = false;
        })
      }
    })
    updateChecklistObject(currChecklist);
  }
  const handleSelectAllChange = (e, itemChecklist) => {
    console.log(itemChecklist)
    const checked = e.target.checked;
    if(checked) selectAll(itemChecklist)
    else deselectAll(itemChecklist)
  }
  return (
    <>
      {openLoader && 
        <LoadingDocumentsModal isOpen={openLoader} setOpenLoader={setOpenLoader} />}
      {isFetching && fetchingTemplates && !checklistTemplates ? (
        <>
          <StatusShimmer />
          <CheckListWrap><DocumentShimmer /></CheckListWrap>
        </>
      ) : (
        <>
          <CheckListSelect>
            <SelectField
              name="source"
              options={checklistTemplates}
              placeholder="Select a checklist template"
              text="Checklist Template"
              required={true}
              requiredMessage="Required."
              onChange={(value) => {
                handleChecklistTemplate(value);
              }}
              value={checklistTemplate}
            />
          </CheckListSelect>
          <CheckListWrap>
            {fetchingTemplates ? (
              <DocumentShimmer />
            ) : (
              <>
                {checklistObject && checklistObject.length > 0 ? (
                  <CheckListView
                    checklistTemplateList={templates}
                    checklistObjectData={checklistObject}
                    handleSelectedCheck={handleSelect}
                    currentBrokerageType={currentBrokerageType}
                    handleSelectAllChange={handleSelectAllChange}
                  />
                ) : (
                  <CheckListEmpty>
                    <p>No checklist item to view</p>
                    <AdditionalText>
                      Pick one of the templates from the checklist dropdown
                    </AdditionalText>
                  </CheckListEmpty>
                )}
              </>
            )}
          </CheckListWrap>
          {checklistObject && checklistObject.length > 0 && (
            <FloatingCTAEnvelopes
              customText="Add To Envelope"
              disabled={isSaving}
              onClick={handleCreateEnvelopes}
              loading={isSaving}
            />
          )}
        </>
      )}
    </>
  );
};

export default withRouter(OffersV2((Listings(ListingsV2(CheckList)))))
