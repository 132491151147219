import React, { useEffect } from "react"
import container from "container/OffersV2"
import TechAssets from "container/TechAssets"
import { get } from "lodash"
import ValidateNumber from "dumbComponents/OffersV3/components/ValidateNumber"
import {
  Wrap,
  StyledCollapse,
  StyledPanel,
} from "./styles"

const getValidatorName = (value) => {
  if (value === "buyer_information") return "buyers_information_mobile"
  if (value === "property_information") return "property_information_mobile"
  if (value === "deal_terms") return "deal_information_mobile"
}

const SideNav = ({
  options,
  toggleSideNavTab,
  currentSideNavTab,
  toggleCoverSheet,
  currentCoverSheetTab,
  fetchValidateCoverSheetResponse,
  contractDetails,
  getCurrentDealDetailsResponse,
  fetchPropertyInformationResponse,
}) => {
  const {
    isFetching,
    data: validators,
  } = fetchValidateCoverSheetResponse
  const { data: dealPropertyData, isFetching: fetchingDetails } = getCurrentDealDetailsResponse || {}
  const { data: propertyInformation, isFetching: fetchingDealDetails } = fetchPropertyInformationResponse || {}

  // useEffect(() => {
  //   disableForm()
  // }, [fetchingDealDetails, fetchingDetails])

  const state = get(propertyInformation, "state")
  const street = get(propertyInformation, "street")
  const city = get(propertyInformation, "city")
  const zipcode = get(propertyInformation, "zipcode")
  const county = get(propertyInformation, "county")

  const updatedState = get(dealPropertyData, "state")
  const updatedStreet = get(dealPropertyData, "street")
  const updatedCity = get(dealPropertyData, "city")
  const updatedZipcode = get(dealPropertyData, "zipcode")
  const updatedCounty = get(dealPropertyData, "county")

  const currentState = updatedState || state
  const currentStreet = updatedStreet || street
  const currentCity = updatedCity || city
  const currentZipcode = updatedZipcode || zipcode
  const currentCounty = updatedCounty || county

  const disableForm = (val) => {
    const filledAddress = (currentState && currentStreet && currentCity && currentZipcode && currentCounty)
    const temp = (!filledAddress && (val === "buyer_information" || val === "deal_terms")) ? "disabled" : ""
    return temp
  }

  const getOpeningTabs = () => {
    const filledAddress = (currentState && currentStreet && currentCity && currentZipcode && currentCounty)
    const temp = !filledAddress ? ["property_information"] : ["property_information", "buyer_information", "deal_terms"]
    return temp
  }

  return (
    <Wrap>
      {options.map(option => (
        <StyledCollapse
          expandIconPosition="start"
          defaultActiveKey="property_information"
          // activeKey={getOpeningTabs()}
          //collapsible={disableForm(option.value)}
        >
          <StyledPanel
            header={option.label}
            key={option.value}
            extra={validators && (
              <ValidateNumber value={validators} index={getValidatorName(option.value)} />
            )}
          >
            {option.value === "buyer_information" && (
              <>
                {option.component}
              </>
            )}
            {option.value === "property_information" && (
              <>
                {option.component}
              </>
            )}
            {option.value === "deal_terms" && (
              <div>
                {option.component}
              </div>
            )}
          </StyledPanel>
        </StyledCollapse>
      ))}
    </Wrap>
  )
}

export default container(TechAssets(SideNav))
