import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 35px;
  align-items: center;
`

const InputGroup = styled.div`
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  width: 100%;
  input:checked ~ label {
    color: #354052;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #51d3b7;
      border-color: #51d3b7;
    }
  }
`

const Input = styled.input`
  width: 32px;
  height: 32px;
  order: 1;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
`

const InputGroupLabel = styled.label`
  padding: 12px 0;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  font-weight: 500;
  line-height: 1.56;
  cursor: pointer;
  position: relative;
  transition: color 200ms ease-in;
  overflow: hidden;
  &:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }
  &:after {
    width: 32px;
    height: 32px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }
`
const RenderRadioCheckbox = (field) => {
  const { label, input } = field
  return (
    <Wrapper>
      <InputGroup>
        <Input type="checkbox" id={input.name} {...input} checked={input.value} />
        <InputGroupLabel htmlFor={input.name}>{label}</InputGroupLabel>
      </InputGroup>
    </Wrapper>
  )
}

export default RenderRadioCheckbox
