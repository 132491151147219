import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import colors from "@colors"
import {
  tablet, minDesktopWidth, xsMobile, mobile,
} from "@consts"
import AgentTestimonial1 from "images/realty-v2/team/testimonial-black-label.jpg?q=100"
import LarryPerryLogo from "images/realty-v2/team/black-label-city.png"
import CommonTestimonial from "dumbComponents/RealtyV3/Common/Testimonial"

const Wrap = styled.div`
  padding: 108px 70px 0px 70px;
  ${tablet`
    padding: 0px 60px 0px 60px;
    .text-quote {
      margin-top: 100px;
    }
  `}
  ${xsMobile`
    padding: 31px 25px 0px 25px;
  `}

  .inner {
    padding: 5px;
  }
  .right {

    .logo1 {
      width: 145px;
    }

    ${mobile`
      .logo1 {
        margin-top: 20px;
        width: 124px;
      }
    `}
  }
`

const TestimonialList = [{
  title: "",
  buttonText: "",
  buttonLink: "",
  imageUrl1: AgentTestimonial1,
  combine: false,
  logoUrl: LarryPerryLogo,
  className: "logo1",
  testimonial: "“I tried for years being ‘old-fashioned’ and had a rough time.  If I had Radius 8 years ago, I would be in a much different position.”",
}]

const HEADING = "Radius Agent Testimonial"
const DESCRIPTION = "As a brokerage, we’re here for you."

const Testimonial = () => (
  <Wrap id="realty-v2-testimonials">
    <CommonTestimonial heading={HEADING} testimonials={TestimonialList} description={DESCRIPTION} />
  </Wrap>
)

export default withRouter(Testimonial)
