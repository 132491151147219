import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import styled from "styled-components"

const Wrap = styled.div``

const FieldShimmer = ({
  width = "129px",
}) => (
  <Wrap>
    <ShimmerBox w={width} h="42px" />
  </Wrap>
)

export default FieldShimmer
