import React from "react"
import _ from "lodash"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { LoadingOutlined } from "@ant-design/icons"
import { normalizePhone } from "services/formUtils"
import { AutoCompleteFieldWrap, StyledSpin } from "./styles"

const ClientsByNameSearch = ({
  // Redux Store
  getClientsByName,
  isFetchingClientsByName,
  clientsByName,

  // Props
  formRef,
  fullNameInputFieldName,
  firstNameInputFieldName,
  lastNameInputFieldName,
  emailInputFieldName,
  phoneInputFieldName,
  formItemLabelText,
  formItemPlaceholder,
  formItemName,
  editingAgentId,
  required = false,
  callback,
  disabled,

  // Extra required props if the field is in a subform
  subFormName,
  fieldIndexInTheSubForm,
}) => {
  const rules = [{ required, message: "Name is required" }]

  const searchClientsByName = (value) => {
    getClientsByName({
      textToSearch: value,
      currentEditingAgentId: editingAgentId,
    })
  }

  const debouncedSearchClientsByName = _.debounce(searchClientsByName, 300)

  const changeOtherFieldsValues = (value, option) => {
    // If the field is in a subform, we need to get the current values of the subform fields
    let currentSubFormFields = []
    if (fieldIndexInTheSubForm >= 0) {
      const fields = formRef.current.getFieldsValue()
      currentSubFormFields = fields[subFormName]
    }

    // Find the selected client
    const selectedClient = clientsByName.find(client => client.id === option.key)
    const nameParts = selectedClient?.name?.split(" ")
    const compiledFirstName = nameParts[0]
    const compiledLastNameAndRest = nameParts.slice(1).join(" ")
    const compiledEmail = selectedClient?.emails?.find(email => email.primary)?.email
    const compiledPhone = normalizePhone(selectedClient?.phones?.find(phone => phone.primary)?.phone)

    // Compile the agent details
    const agentDetails = {}

    if (selectedClient?.id?.length) {
      agentDetails.agent_id = selectedClient.id
      agentDetails.client_id = selectedClient.id
    }

    if (firstNameInputFieldName?.length) {
      agentDetails[firstNameInputFieldName] = compiledFirstName
    }

    if (lastNameInputFieldName?.length) {
      agentDetails[lastNameInputFieldName] = compiledLastNameAndRest
    }

    if (fullNameInputFieldName?.length) {
      agentDetails[fullNameInputFieldName] = selectedClient?.name
    }

    if (emailInputFieldName?.length && compiledEmail?.length) {
      agentDetails[emailInputFieldName] = compiledEmail
    }

    if (phoneInputFieldName?.length && compiledPhone?.length) {
      agentDetails[phoneInputFieldName] = compiledPhone
    }

    // If the field is in a subform, we need to update the current values of the subform fields
    if (fieldIndexInTheSubForm >= 0) {
      // Update the current values of the subform fields
      Object.assign(currentSubFormFields[fieldIndexInTheSubForm], agentDetails)

      // Set the new values of the subform fields
      formRef.current.setFieldsValue({
        [subFormName]: currentSubFormFields,
      })
    } else {
      // Set the new values of the form fields
      formRef.current.setFieldsValue({
        ...agentDetails,
      })
    }

    if (callback) {
      callback(agentDetails, fieldIndexInTheSubForm)
    }
  }

  return (
    <>
      <Form.Item
        name={formItemName}
        rules={rules}
        label={<LabelComponent text={formItemLabelText} required={required} />}
        required={required}
        disabled={disabled}
      >
        <AutoCompleteFieldWrap
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={debouncedSearchClientsByName}
          onSelect={(value, option) => changeOtherFieldsValues(value, option)}
          notFoundContent={
            isFetchingClientsByName ? (
              <StyledSpin
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : null
          }
          options={clientsByName.map(client => ({
            label: client.name,
            value: client.name,
            key: client.id,
          }))}
          placeholder={formItemPlaceholder}
          disabled={disabled}
        />
      </Form.Item>
    </>
  )
}

export default SearchContainer(ClientsByNameSearch)
