/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import {
  MLSSelect,
} from "dumbComponents/common/InputFields"
import Listings from "container/Listings/index"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div`
  height: 300px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const MLSModal = ({
  onClose,
  setMLSIdForOnMarket,
  moveToOnMarketResponse,
  moveToOnMarket,
  optionalMLSID,
  listingData,
  marketData,
  toClose,
}) => {
  const {
    isFetching: updatingStatus,
  } = moveToOnMarketResponse || {}
  const { mlsId: selectedMlsId, uniqueId: selectedUniqueId } = marketData || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              ADD MLS ID (OPTIONAL)
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              Providing an MLS ID allows us to enhance your listing with additional details.
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ParaWrap>
          <div
            style={{
              position: "relative",
              top: "-20px",
            }}
          >
            <MLSSelect
              onSelectMLS={(mlsData) => {
                const {
                  ListingId,
                  typedMLSId,
                  Id,
                } = mlsData || {}
                if (!ListingId && !typedMLSId) return false
                setMLSIdForOnMarket({
                  mlsId: ListingId || typedMLSId,
                  uniqueId: Id,
                })
                return true
              }}
            />
          </div>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={onClose || toClose}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={updatingStatus}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              if (listingData && !listingData.dealId) return
              const {
                mlsId,
                uniqueId,
              } = marketData || {}
              const payload = {
                mlsId: mlsId || null,
                listingId: listingData.dealId,
                mls_unique_id: uniqueId,
              }

              const statusChangePayload = {
                listing_id: listingData.dealId,
                step: "active_listing",
                notes: "",
              }

              moveToOnMarket({
                payload,
                statusChangePayload,
              })
            }}
          >
            {selectedMlsId?.length || selectedUniqueId?.length ? "Sync MLS ID" : "Skip"}
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default Listings(MLSModal)
