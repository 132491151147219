import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import { get, zip } from "lodash"
import cookie from "services/CookieStorage"
import root, { alert, setTimeout } from "window-or-global"
import queryString from "query-string"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import { PlusOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { normalizePhoneNumber, normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import Loader from "@ui/Loader"
// import { gapi } from "gapi-script"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, AutoComplete,
  GoogleAutoComplete,
} from "@ui/antd"
import { emailNormalisation } from "dumbComponents/Offers/commonUtils"
// import "antd/dist/antd.css"
import dynamic from "next/dynamic"
const loadGapi = dynamic(() => import("gapi-script").then(mod => mod.loadGapiInsideDOM), { ssr: false })

const Wrap = styled.div`
  margin-top: 52px;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
  p {
    font-weight: 200;
    font-size: 16px;
    margin-top: 3px;
  }
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    isSourceOther: null,
    formsInitialized: false,
  }

  formRef = React.createRef();

  componentDidMount() {
    const initializeGapi = async () => {
      try {
        await loadGapi()
        // Initialize gapi client
        window.gapi.load("client:auth2", this.fetchGoogleContacts)
      } catch (e) {
        //
      }
    }
    initializeGapi()
    const { getContractById, dealToView, history, currentListingContractID } = this.props
    console.log("----componentDidMount----CALLED")
    const {
      location,
    } = history
    const queryParams = queryString.parse(location.search)

    const {
      dealId,
      offerId,
    } = queryParams
    if (dealId) {
      if (offerId) {
        getContractById({
          offerId: dealToView,
        })
      } else if (currentListingContractID) {
        getContractById({
          dealId: currentListingContractID,
          transactionType: "Contract",
        })
      } else {
        getContractById({
          listingId: dealToView,
          transactionType: "Contract",
        })
      }
    }
  }

  setSource = (value) => {
    if (value === "other") {
      this.setState({
        isSourceOther: true,
      })
    } else {
      this.setState({
        isSourceOther: false,
      })
    }
  }

  fetchGoogleContacts = () => {
    const { googleAuthToken, setAgentGoogleContacts } = this.props
    // gapi?.client?.init({
    //   clientId: GOOGLE_CLIENTID,
    //   scope: "https://www.googleapis.com/auth/contacts.readonly",
    //   discoveryDocs: ["https://people.googleapis.com/$discovery/rest"],
    // }).then(() => {
    //   console.log("==TEST===2")
    //   // return gapi.client.people.people.connections.list({
    //   //   resourceName: "people/me",
    //   //   pageSize: 10,
    //   //   personFields: "names,emailAddresses",
    //   // })
    //   const tempToken = googleAuthToken?.data?.access_token
    //   return gapi.client.request({
    //     path: "https://content-people.googleapis.com/v1/people/me/connections?pageSize=2000&personFields=names%2CemailAddresses,phoneNumbers&sortOrder=FIRST_NAME_ASCENDING",
    //     headers: {
    //       Authorization: `Bearer ${tempToken}`,
    //     },
    //   })
    // }).then((response) => {
    //   console.log("==TEST===3", response)
    //   if (response.result?.connections) {
    //     setAgentGoogleContacts(response.result?.connections)
    //     this.setState({
    //       contacts: response.result?.connections,
    //     })
    //   }
    //   console.log(response.result)
    // }, (reason) => {
    //   console.log("==TEST===5")
    //   // console.log(`Error: ${reason.result.error.message}`)
    // })
  }

  getBackRoute = () => {
    const { isFromDirectForm, backFormRoute, history } = this.props
    if (!isFromDirectForm) {
      history.push("/realty/transaction-coordination/contract/basic-info")
    }
    history.push(backFormRoute)
  }

  getNextRoute = (isDraft) => {
    const { isFromDirectForm, mainPageRoute, nextFormRoute } = this.props
    if (!isFromDirectForm) {
      return isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/transaction-details"
    }
    return isDraft ? mainPageRoute : nextFormRoute
  }

  onFinish = (fieldValues, isDraft) => {
    const { updateContract, history, dealToView } = this.props
    const { cityFormValue, showCoListingform } = this.state
    const user = cookie.load("user") || {}
    console.log("==fieldValues==", fieldValues)
    const localFieldValues = {
      ...fieldValues,
    }
    let clientPayload = []
    const otherPayload = {
      source: fieldValues.source,
      current_contract_step: "client_info",
      agent_id: user.id,
      other_source: "",
    }
    otherPayload.source = fieldValues.source
    if (fieldValues.other_source) {
      otherPayload.other_source = fieldValues.other_source
    }

    console.log("==localFieldValues==", localFieldValues)
    Object.keys(localFieldValues).forEach((fvKey) => {
      const index = parseInt(fvKey.split("-")[1], 10)
      if (showCoListingform[index]) {
        const keyName = fvKey.split("-")[0]
        if (fvKey.split("-").length === 2) {
          if (!clientPayload[index]) {
            clientPayload[index] = {
              // is_primary: index === 0 ? "Y" : "N",
              is_primary: index === 0 ? 1 : 0,
              client_id: null,
              referral_id: null,
            }
          }
          clientPayload[index] = {
            ...clientPayload[index],
            client_id: null,
            referral_id: null,
            [keyName]: localFieldValues[fvKey],
          }
        }
      }
    })
    console.log("==otherPayload==", otherPayload)
    console.log("==clientPayload==", clientPayload)
    clientPayload = clientPayload.filter(dt => dt)
    updateContract({
      payload: {
        ...otherPayload,
        clients: clientPayload,
        saveDraft: isDraft ? 1 : 0,
      },
      dealId: dealToView,
      // callAPI: ["seller_info", "source_time"],
      nextRoute: this.getNextRoute(isDraft),
      history,
    })
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, cityFormName) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [cityFormName]: `${locationDetails.city || ""}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: `${locationDetails.city || ""}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getContractByIdResponse } = this.props
    const { cityFormValue, showCoListingform, isSourceOther, formsInitialized } = this.state
    const clientInfo = getContractByIdResponse.data?.[0]
    const localShowCoListingform = showCoListingform
    console.log("==getListingCoverSheetResponse.data==", getContractByIdResponse.data)
    console.log("==sellerInfo.data==", clientInfo)
    const result = {}
    if (clientInfo && clientInfo.clients) {
      clientInfo.clients.forEach((info, index) => {
        Object.keys(info).forEach((key) => {
          result[`${key}-${index}`] = info[key]
        })
        // need to check this logic
        if (index > 0 && !formsInitialized) {
          localShowCoListingform.push(true)
          this.setState({
            formsInitialized: true,
            showCoListingform: [...localShowCoListingform],
          })
        }
        // if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
      })
    }
    console.log("==result.data==", result)
    if (clientInfo && clientInfo.source) {
      result.source = clientInfo.source
      if (!isSourceOther) {
        this.setSource(clientInfo.source)
      }
    }
    if (clientInfo && clientInfo.other_source) {
      result.other_source = clientInfo.other_source
    }
    return result
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    console.log("==contacts===", agentGoogleContacts)
    return agentGoogleContacts && agentGoogleContacts.length ? agentGoogleContacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: `${ct.names[0].displayName}`, key: index })) : []
  }

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting[index] = false
    // const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  changeOtherFieldsValues = (objString, index, fromContacts, option) => {
    console.log("==payload=values=", objString, index, this.formRef.current.getFieldsValue(), option)
    if (fromContacts) {
      let { agentGoogleContacts } = this.props
      agentGoogleContacts = agentGoogleContacts.filter(ct => ct.names)
      const name = objString
      const contactIndex = option.key
      const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
      const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
      if (phoneNumber || emailAddresses) {
        this.formRef.current.setFieldsValue({
          [`agent_id-${index}`]: "",
          [`name-${index}`]: name,
          [`email-${index}`]: emailAddresses,
          [`phone-${index}`]: phoneNumber,
        })
      }
      return
    }
    const { agents } = this.props
    const obj = objString
    const agent = agents.find(ag => ag && ag.id === parseInt(option.key, 10)) || ""

    if(agent) {
      this.formRef.current.setFieldsValue({
        [`agent_id-${index}`]: parseInt(obj[0], 10),
        [`name-${index}`]: objString,
        [`email-${index}`]: agent.email,
        [`phone-${index}`]: agent.phone,
      })
    }

  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  render() {
    const { showCoListingform, cityFormValue, isSourceOther } = this.state
    const {
      history, createListingCoverSheetResponse, updateContractResponse, getContractByIdResponse,
      loaderOnAgentInfo, isFromDirectForm,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap mt={isFromDirectForm ? "0px" : "52px"}>
        {!isFromDirectForm && (
          <HeaderNav>
            Client Information
            <p>Please add all client names on the contract</p>
          </HeaderNav>
        )}
        <Container>
          <RightSideBar>
            <AddForm>
              {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) && (
                <Button
                  ghost
                  style={{
                    float: "right",
                    borderColor: "#3B60E4",
                    color: "#3B60E4",
                    display: "flex",
                    alignItems: "center",
                  }}
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<PlusCircleOutlined />}
                  onClick={() => { this.addAdditionalSeller() }}
                >
                  Add another client
                </Button>
              )}
            </AddForm>
            {getContractByIdResponse.isFetching || loaderOnAgentInfo ? (<Loader />) : (
              <Form style={{ marginTop: "10px" }} name="seller_information" colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef} disabled={getContractByIdResponse?.data?.[0] ? !this.getIsEdit(getContractByIdResponse?.data?.[0]) : false}>
                <FlexBox>
                  <Form.Item name="source" label={<LabelComponent text="Client Source" required />} rules={commonRules}>
                    <Select onChange={value => this.setSource(value)}>
                      <Select.Option value="radius_marketplace">Radius Marketplace</Select.Option>
                      <Select.Option value="zillow_agent_personal_account">Zillow (Agent's Personal Account)</Select.Option>
                      <Select.Option value="zillow_radius_provided">Zillow (Radius Provided)</Select.Option>
                      <Select.Option value="zillow_flex">Zillow Flex</Select.Option>
                      <Select.Option value="opcity_realtor_com">OpCity/Realtor.com</Select.Option>
                      <Select.Option value="team_mentor_lead">Team/Mentor Lead</Select.Option>
                      <Select.Option value="self">Self</Select.Option>
                      <Select.Option value="sphere_of_influence">Sphere of Influence/Personal</Select.Option>
                      <Select.Option value="referrals_agent_Lender">Referrals (Agent/Lender)</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </FlexBox>
                {isSourceOther && (
                  <Form.Item name="other_source" label={<LabelComponent text="Client Other Source" required />} rules={commonRules}>
                    <Input />
                  </Form.Item>
                )}
                {showCoListingform.map((lst, index) => (
                  <>
                    {lst && (
                      <>
                        {index > 0 && (<Divider />)}
                        {index > 0 && (
                          <HeaderInfo>
                            <h2>
                              Co-Contract Client Information
                            </h2>
                            <div onClick={() => { this.removeColistingAgent(index) }}>
                              <CloseCircleOutlined style={{ fontSize: "22px" }} />
                            </div>

                          </HeaderInfo>
                        )}
                        <Form.Item name={`name-${index}`} label={<LabelComponent text="Full Name" required />} rules={commonRules}>
                          {/* <Input /> */}
                          <AutoComplete
                            filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            options={this.getOptions()}
                            onChange={(e, option) => this.changeOtherFieldsValues(e, index, true, option)}
                          />
                        </Form.Item>
                        <FlexBox>
                          <Form.Item
                            name={`email-${index}`}
                            label={<LabelComponent text="Email" required />}
                            required
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Required",
                              },
                            ]}
                            normalize={emailNormalisation}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name={`phone-${index}`} label={<LabelComponent text="Client Phone" required />} rules={commonRules} normalize={normalizePhone}>
                            <Input />
                          </Form.Item>
                        </FlexBox>
                      </>
                    )}
                  </>
                ))}
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        disabled={false}
                        // htmlType="submit"
                        onClick={() => {
                          this.getBackRoute()
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            background: "#3B60E4",
                            float: "right",
                            borderColor: "white",
                          }}
                          type="primary"
                          shape="round"
                          size="large"
                          loading={updateContractResponse.isFetching || getContractByIdResponse.isFetching}
                          htmlType="submit"
                        // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                        >
                          Next
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            background: "#3B60E4",
                            float: "right",
                            borderColor: "white",
                          }}
                          type="primary"
                          shape="round"
                          size="large"
                          disabled={false}
                          onClick={() => { history.push("/realty/transaction-coordination/contract/transaction-details") }}
                        >
                          Next
                        </Button>
                      </Form.Item>
                    )}
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default withRouter(TCContainer(TechAssetsContainer(CoverSheet)))

