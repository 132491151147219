import React, {useRef} from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import HiddenInputField from "dumbComponents/common/InputFields/EmptyField"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"

const LenderForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  deleteLender,
  isAddNewLender,
}) => {
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
        if (prefilledData.lender_zipcode) {
    initValues.zipcode = prefilledData.lender_zipcode;
  }

        if (prefilledData.lender_city) {
    initValues.city = prefilledData.lender_city;
        }
          if (prefilledData.lender_state) {
    initValues.state = prefilledData.lender_state;
  }
    }

    return initValues
  }
  const formRef = useRef(null)

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Lender Information"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            handleEditModeChange()
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V3/Offers_v3_asset_icon.svg`} alt="minus" style={{marginTop:'1px' }} />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_lender_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
      >
        <FormFieldWrap>
          <InputField
            name="lender_officer_name"
            inputType="text"
            placeholder="Lender Officer Name"
            required={true}
            text="Lender Officer Name"
          />

          <InputField
            name="lender_co"
            inputType="text"
            required={true}
            placeholder="Lending Company"
            text="Lending Company"
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <InputField
            name="lender_email"
            inputType="email"
            type="email"
            required={true}
            placeholder="Email"
            text="Email"
          />

          <InputNumber
            name="lender_phone"
            inputType="phone"
            type="phone"
            required={true}
            placeholder="Phone"
            text="Phone*"
          />
        </FormFieldWrap>

        {/**Requires Google Address*/}
        <FormFieldWrap>
          <InputLocation
            name="lender_office_address"
            inputType="text"
            type="text"
            required={true}
            placeholder="Office Address*"
            formRef={formRef}
            text="Office Address*"
          />

          <InputField
            name="city"
            inputType="text"
            required={true}
            placeholder="Office City"
            text="Office City"
          />
        </FormFieldWrap>
        <FormFieldWrap>
          <InputField
            name="state"
            inputType="text"
            required={true}
            placeholder="Office State"
            text="Office State"
          />

          <InputNumber
            name="zipcode"
            inputType="number"
            type="number"
            required={true}
            placeholder="Zipcode"
            text="Zipcode*"
          />
        </FormFieldWrap>

        <CTAWrap>
          {/* {!isAddNewLender && (
            <Form.Item>
              <DangerButton
                type="primary"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteLender(prefilledData.lender_id)}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )} */}

          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default LenderForm
