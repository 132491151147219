import React from "react"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import Button from "@ui/Button"
import { Helmet } from "react-helmet"
import { get } from "lodash"
import colors from "@colors"
import {
  convertUnicode,
} from "services/Utils"
import CircularImage from "dumbComponents/common/CircularImage"
import LiveEventImg from "images/event-room/event-room-libe.svg"
import SEOImage1 from "images/event-room/event-room-share.jpg"
import UserIcon from "images/event-room/event-user-icon.svg"
import { GetUserPlatform } from "dumbComponents/Auth/authUtils"

const Wrap = styled.div`
  background: ${colors.lightWhiteGray};
  max-width: 660px;
  width: 100%;
  padding: 45px;
  border-radius: 15px;
  text-align: center;
  margin: 45px auto;
`

const Title = styled.h2`
  font-size: 24px;
  color: ${colors.black};
  font-weight: 500;

`

const SubText = styled.p`
  color: ${colors.suvaGray};
  margin: 20px auto;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
`

const ModButton = styled(Button)`
  background: ${colors.marineBLue};
  border: none;
  min-width: 350px;
`

const LiveEventWrap = styled.div`
  border-radius: 10px;
  background: ${colors.marineBLue};
  padding: 12px 0px;
`

const UserWrap = styled.div`
  display: flex;
  width: 100%;
`

const SubLabel = styled.h4`
  text-transform: uppercase;
  color: ${colors.reddishGrey};
  font-size: 14px;
  font-weight: 300;

  ${props => props.isStrong && `
    font-weight: 400;
    color: ${colors.black};
    padding: 0px 3px;
  `}
`
const UserName = styled.div`
  flex-direction: column;
  margin-top: 16px;
  > p {
    line-height: 1;
    padding: 0px;
    margin: 0px;
  }
`

const TotalUsers = styled.div`
  display: flex;
  text-align: center;
  margin: 10px auto;
  width: 200px;
  justify-content: center;
  border-top: 1px solid #ccc;
  padding: 27px 10px 20px 10px;
  img {
    max-height: 13px;
  }
  > h4 {
    margin: 0px !important;
  }
`

const SinglerUserWrap = styled.div`
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      background: #ccc;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`

export const ROLES = {
  SPEAKER: "speaker",
  HOST: "host",
  ATTENDEE: "listener",
}

export const filterBasedOnRole = (data, role) => {
  if (data && data.length > 0) {
    const filteredList = data.filter(x => x.Role === role)
    return filteredList
  }
  return []
}

const EventEnded = ({
  handleSignUp,
}) => (
  <React.Fragment>
    <Title>
      This event has ended.
    </Title>
    <SubText style={{ maxWidth: 350 }}>
      Don’t miss the latest events happening now! Start the conversation today.
    </SubText>
    <ModButton onClick={handleSignUp}>
      Sign Up
    </ModButton>
  </React.Fragment>
)

const UserDetails = ({
  eventData,
}) => {
  const host = get(filterBasedOnRole(eventData.attendees, ROLES.HOST), "[0]")
  const speakers = filterBasedOnRole(eventData.attendees, ROLES.SPEAKER)
    .filter(x => x.id !== host.id)
  const isGreaterThan5 = speakers && speakers.length > 5
  const filteredSpeaker = isGreaterThan5 ? speakers.slice(0, 5) : speakers
  return (
    <UserWrap>
      <Helmet>
        <title>
          {`Join the conversation! - ${convertUnicode(eventData.name)}`}
        </title>
        <meta property="image" content={SEOImage1} />
        <meta property="image" content={SEOImage1} />
        <meta property="og:image" content={SEOImage1} />
        <meta property="twitter:image" content={SEOImage1} />
        <meta property="og:description" content={"Join Radius Rooms, a social network for all real estate professionals. You can create rooms, talk about real estate topics, network with other real estate professionals or just join existing conversations."} />
        <meta property="description" content={"Join Radius Rooms, a social network for all real estate professionals. You can create rooms, talk about real estate topics, network with other real estate professionals or just join existing conversations."} />
      </Helmet>
      <SinglerUserWrap>
        <SubLabel>Moderator</SubLabel>
        <div>
          <CircularImage
            image={host.image_url}
            alt=""
            size={32}
            name={`${host.firstname} ${host.lastname || ""}`}
          />
        </div>
        <UserName>
          {`${host.firstname} ${host.lastname || ""}`}
        </UserName>
      </SinglerUserWrap>
      {filteredSpeaker && filteredSpeaker.length > 0 && (
        <SinglerUserWrap>
          <SubLabel>Speakers</SubLabel>
          <div>
            <React.Fragment>
              {filteredSpeaker && [...filteredSpeaker].splice(0, 5).map((x, index) => (
                <CircularImage
                  key={x.id}
                  image={x.image_url}
                  name={x.firstname}
                  size={32}
                  style={{ position: "relative", left: -(index * 8) }}
                />
              ))}
            </React.Fragment>
          </div>
          <UserName>
            {filteredSpeaker && [...filteredSpeaker].map(x => (
              <p>{x.firstname} {x.lastname || ""}</p>
            ))}
            {isGreaterThan5 && (
              <p>& more!</p>
            )}
          </UserName>
        </SinglerUserWrap>
      )}
    </UserWrap>
  )
}

const ActiveEvent = ({
  eventData,
  goToStore,
}) => {
  const attendeesCount = filterBasedOnRole(eventData.attendees, ROLES.ATTENDEE).length + 1
  return (
    <React.Fragment>
      <LiveEventWrap onClick={goToStore}>
        <img src={LiveEventImg} alt="Live event" />
      </LiveEventWrap>
      <Title>
        {convertUnicode(eventData.name)}
      </Title>
      <UserDetails eventData={eventData} />
      <TotalUsers>
        <SubLabel isStrong>
          <strong>{attendeesCount || 0}</strong>
        </SubLabel>
        <SubLabel>
          {attendeesCount === 1 ? "Attendee" : "Attendees"}
        </SubLabel>
      </TotalUsers>
    </React.Fragment>
  )
}

const Details = ({
  eventData,
  history,
}) => {
  const goToSignUp = () => {
    history.push("/signup")
  }
  const goToStore = () => {
    if (GetUserPlatform() !== "desktop") {
      root.open("http://onelink.to/e58ehn")
    }
  }
  return (
    <Wrap>
      {eventData && eventData.is_live === 0 ? (
        <EventEnded
          handleSignUp={goToSignUp}
        />
      ) : (
        <React.Fragment>
          {eventData && (
            <ActiveEvent eventData={eventData} goToStore={goToStore} />
          )}
        </React.Fragment>
      )}
    </Wrap>
  )
}

export default withRouter(Details)
