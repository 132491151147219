import React from "react"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import {
  PlusCircleOutlined, LoadingOutlined, CloseCircleOutlined,
} from "@ant-design/icons"
import {
  Wrap,
  SelectFieldWrap,
  StyledSpin,
} from "./styles"

const AgentSearch = ({
  placeholder,
  options,
  style,
  arrowIcon,
  isFormField,
  required,
  defaultValue,
  name,
  onChange,
  formRef,
  getSearchedAgents,
  isFetchingAgents,
  agents,
  firstNameLabel = "agent_first_name",
  lastNameLabel = "agent_last_name",
  isFirst = false,
}) => {
  const rules = [{ required }]

  const searchAgentDetails = (searchValue) => {
    getSearchedAgents({
      isInitial: true,
      currentPageNumber: 1,
      queryParams: {
        text_value: searchValue,
        unRegistered: false,
        text_search_params: [
          "email",
          "full_name",
        ],
        isBroker: true,
      },
    })
  }

  const changeOtherFieldsValues = (objString, index, fromContacts, option) => {
    try {
      // if (fromContacts) {
      //   let { agentGoogleContacts } = this.props
      //   agentGoogleContacts = agentGoogleContacts.filter(ct => ct.names)
      //   const name = objString
      //   const contactIndex = option.key
      //   const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
      //   const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
      //   if (phoneNumber || emailAddresses) {
      //     this.formRef.current.setFieldsValue({
      //       [`agent_id-${index}`]: "",
      //       [`name-${index}`]: name,
      //       [`email-${index}`]: emailAddresses,
      //       [`phone-${index}`]: phoneNumber,
      //     })
      //   }
      //   return
      // }
      const agent = agents.find(ag => ag.id === option.key)
      console.log(agent)
      formRef.current.setFieldsValue({
        agent_id: parseInt(agent.id, 10),
        [firstNameLabel]: agent.firstname,
        [lastNameLabel]: agent.lastname,
        name: objString,
        email: agent.email || agent?.brokerage_info_from_LOC?.email,
        phone: agent.phone || agent?.brokerage_info_from_LOC?.phone,
        agent_brokerage_address: agent?.brokerage_info_from_LOC?.brokerage_address,
        agent_license_number: agent?.brokerage_info_from_LOC?.agent_license_number,
        landline: agent?.brokerage_info_from_LOC?.landline,
        state: agent?.brokerage_info_from_LOC?.state,
        zipcode: agent?.brokerage_info_from_LOC?.zipcode,
        city: agent?.brokerage_info_from_LOC?.city,
        // [`listing_brokerage-${index}`]: agent?.brokerage_info_from_LOC?.brokerage,
        brokerage: agent?.brokerage_info_from_LOC?.brokerage,
        brokerage_license_number: agent?.brokerage_info_from_LOC?.brokerage_license_number,
        is_primary: isFirst ? 1 : 0,
      })
    } catch (e) {
      //fail silently
    }
  }

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={rules}
          >
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={((e, option) => changeOtherFieldsValues(e, index, false, option))}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => ({
                label: `${d.firstname} ${d.lastname}`,
                value: `${d.firstname} ${d.lastname}`,
                key: d.id,
              }))}
              placeholder={placeholder}
            // options={[{ value: "text", label: "label1" }]}
            />
          </Form.Item>
        )
      default:
        return (
          <Wrap>
            <SelectFieldWrap
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchAgentDetails}
              onChange={((e, option) => changeOtherFieldsValues(e, index, false, option))}
              notFoundContent={(isFetchingAgents) ? <StyledSpin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
              options={(agents).map(d => ({
                label: `${d.firstname} ${d.lastname}`,
                value: `${d.firstname} ${d.lastname}`,
                key: d.id,
              }))}
              placeholder={placeholder}
            // options={[{ value: "text", label: "label1" }]}
            />
          </Wrap>
        )
    }
  }

  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SearchContainer(AgentSearch)
