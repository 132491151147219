import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Collapse } from "@ui/antd";
import { ATC, INTER, MONTSERRAT } from "@fonts"

const { Panel } = Collapse;

export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-top:0px;
`;

export const StyledArrow = styled(DownOutlined)`
  position: relative;
  top: -5px;
  color: #5a5ff2;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const PendingList = styled.div`
  display: flex;
  padding: 0px 17px 14px 17px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Color-Neutral-200, #e5e6ff);
  p {
    color: #707070;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 16.2px */
    letter-spacing: 0.5px;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  p {
    color: var(--Color-Gray-400, #41446a);
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }
`;

export const ItemList = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  /* gap: 19px; */

  p {
    padding-left: 16px;
    color: var(--Dark-Gray-Dark-Gray-2, #252c32);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.084px;
  }
`;
export const ItemWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* gap: 19px; */
  padding-top: 10px;
  padding-bottom: 15px;
  align-self: stretch;
  border-bottom: 1px solid var(--Color-Neutral-200, #e5e6ff);
`;

export const HeaderItemList = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  align-self: stretch;
  p {
    padding-left: 16px;
    color: var(--Mid-Gray-Mid-Gray-3, #84919a);
    /* Headings / Heading 12 Semi Bold */
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.216px;
    text-transform: uppercase;
  }
`;
export const StatusHeaderWrap = styled.div`
width: 30%;
justify-content: center
display: flex;
align-items: flex-start;
flex: 1 0 0;

p {
    color: var(--Mid-Gray-Mid-Gray-3, #84919A);
text-align: center;
/* Headings / Heading 12 Semi Bold */
font-family: ${INTER};
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px;
letter-spacing: 0.216px;
text-transform: uppercase;
}
`;

export const StyledCheckbox = styled(Checkbox)`
  ${(props) =>
    props.blackbox &&
    `
      .ant-checkbox-inner {
        border-color: #292A37;
        outline: 0.2px solid #292A37;
      }
  `}

  ${(props) =>
    props.inMenu &&
    `
    color: var(--Color-Tertiary, #1C1C1F);
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 15.052px */
  `}
`;

export const Divide = styled(Divider)`
margin-top: -10px !important
margin: 0 8px;
border-left: 1px solid var(--Color-Neutral-300, #DCDEFF);
`;

export const CheckBoxWrap = styled.div`
position: relative
display: flex;
width: 48px;
top: -10px
flex-direction: column;
justify-content: flex-end;
align-items: center;
align-self: stretch;
`;
export const StatusWrap = styled.div`
width: 30%;
justify-content: center
display: flex;
align-items: flex-start;
flex: 1 0 0;
`;
export const NumberWrap = styled.div`
display: flex;
min-width: 36px;
padding: 2px 8px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 100px;
background: #7A7FFF;
margin-left: 4px
margin-top: -10px
height: 20px
color: var(--Color-Neutral-50, #FDFDFD);
font-family: ${INTER};
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 13.5px */
letter-spacing: 0.5px
`;

export const DuedateWrap = styled.div`
  display: flex;
  min-width: 36px;
  padding: 2px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--Color-Secondary-Secondary, #a5a8ff);
  color: var(--Color-Primary-Primary, #5a5ff2);
  font-family: ${INTER};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 13.5px */
  letter-spacing: 0.5px;
  margin-top: -10px;
`;

export const StyledStatus = styled.div`
  margin-right: 10px;
`;

export const StatusTitle = styled.p`
  color: var(--Color-Gray-400, #41446a);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  font-variant: small-caps;
  letter-spacing: 0.5px;
`;

export const MandatoryTag = styled.div`
display: flex;
width: 88px;
padding: 2px 8px;
justify-content: center;
align-items: center;
border-radius: 16px;
${(props) =>
  props.mandatoryStatus &&
  `background: var(--Primary-50, #F9F5FF);
`}
${(props) =>
  props.applicableStatus &&
  `background: var(--Gray-100, #F2F4F7);
`}
`
export const MandatoryTagTitle = styled.div`
text-align: center;
/* Text xs/Medium */
font-family: ${INTER};
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
${(props) =>
  props.mandatoryStatus &&
  `color: var(--Primary-700, #6941C6);
`}
${(props) =>
  props.applicableStatus &&
  `color: var(--Gray-700, #344054);
`}
`

export const Wrap = styled.div`
  width: 100%;
  .ant-collapse {
    border: 0px;
  }

  .ant-collapse > .ant-collapse-item {
    border: 1px solid #E5E6FF;
    margin-bottom: 36px;
  }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 16px 16px 4px 16px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
vertical-align: 1px;
    color: #7a7fff;
    }
`;
export const StyledPanel = styled(Panel)`
  .ant-collapse-content {
    border-top: 0px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 5px 0px 0 0px;
  }
  &&.ant-collapse-item.ant-collapse-content {
    border-top: 0 !important; /* Remove top border */
  }
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse {
    border: 0px;
  }

`;