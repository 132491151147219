import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { Helmet } from "react-helmet"
import colors from "@colors"
import { xsMobile, mobile } from "@consts"
import RadiusLogo from "images/home-v7/new-radius-logo.png"
import LoginSideImage from "images/login-sign-up/side-image.jpg"
//import LoginSideImage from "images/login-sign-up/summit-side-image.jpg"
import SummitSidebar from "./LoginSidebar"

const StyledGrid = styled.div`
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow: hidden;
  margin: 0px !important;
`

const Row = styled.div`
  display: flex;
  ${mobile(`
    flex-direction: column;
  `)}
`

const ContentArea = styled.div`
  flex: 1;
  ${mobile(`
    flex-direction: column;
    order: 2;
  `)}
`

const SideContainer = styled.div`
  position: relative;
  background: #CCC no-repeat center;
  background-size: cover;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  ${mobile(`
    height: auto;
    flex-direction: column;
    order: 1;
  `)}
// `

// const SideImage = styled.img`
//   height: 100vh;
//   width: auto;
//   right: 0;
//   position: absolute;
// `

const EntryBox = styled.div`
  font-family: DM Sans;
  width: 375px;
  min-height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 35px;
  padding: 30px;
  position: relative;
  z-index: 10;

  @media (max-width: 350px) {
    width: 290px;
  }
`

const Para = styled.p`
  font-family: DM Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
`

const EntryTitleScreen = styled.h1`
  width: 491px;
  margin: 0 auto;
  font-family: 'DM Sans', 'carrara-bol';
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  text-align: center;
  color: #3c4858;

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 50px;
    padding-top: 40px;
    font-size: 30px;
    line-height: 40px;
  }
`

const EntryBorder = styled.div`
  width: 94px;
  height: 8px;
  border-radius: 7px;
  background-color: #11adf3;
  margin: 30px auto;
`

const RadiusLogoWrap = styled.div`
  padding: 20px;
  ${mobile(`
    position: absolute;
    top: 0px;
    left: 0px;
    background: ${colors.white};
    width: 100%;
    z-index: 10;
    padding: 16px 20px;
  `)}
  img {
    height: 26px;
  }

  p {
    padding: 0px;
    margin: 0px;
  }
`

const withRegisterContainer = ({
  children,
  className,
  title,
  subTitle,
  metaTitle,
  metaDescription,
  noLogo,
  customTitleWidth,
  ...props
}) => (
  <React.Fragment>
    <Helmet>
      <title>
        {metaTitle || "sss"}
      </title>
      <meta property="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
    </Helmet>
    <StyledGrid>
      <Row style={{ minHeight: "100vh" }}>
        <ContentArea id="content-section" style={{ maxHeight: "100vh", overflow: "scroll" }}>
          <div style={{ zIndex: 10 }}>
            {!noLogo ? (
              <RadiusLogoWrap>
                <a href="/">
                  <img src={RadiusLogo} alt="Radius Agent" />
                </a>
              </RadiusLogoWrap>
            ) : (
              <RadiusLogoWrap />
            )}
            <div style={{ maxWidth: 500, margin: "0px auto", paddingBottom: 100 }}>
              <EntryTitleScreen id="register-container-title">
                {title}
              </EntryTitleScreen>
              <Para noLogo={noLogo} id="sub-title">{subTitle}</Para>
              <EntryBox>
                {children}
              </EntryBox>
            </div>
          </div>
        </ContentArea>
        {/*<SideContainer
          md={6}
          style={{ padding: "0" }}
          d="side-image"
        >
          <SummitSidebar />
        </SideContainer>*/}
      </Row>
    </StyledGrid>
  </React.Fragment>
)

export default withRegisterContainer
