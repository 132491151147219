import React from "react"
import AppContainer from "container/App"
import root from "window-or-global"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import { withRouter } from "react-router-dom"
import { addedCard } from "../../consts"
import { PageTitle, ExpireWrap, PageDescription } from "../../common"

const Success = ({
  fetchUserInfo,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap
          width="50%"
        >
          <LeftImage src={addedCard} width={400} height={350} top="200px" />
        </LeftWrap>
        <RightWrap
          width="50%"
          // padding="169px 133px 0px 133px"
          // justifyContent="flex-start"
        >
          <ExpireWrap>
            <PageTitle>
              Your credit card details have been submitted successfully!
            </PageTitle>
          </ExpireWrap>
          <ICAButtons
            style={{
              width: "290px",
              height: "50px",
            }}
            onClick={() => {
              fetchUserInfo({ triggerDataLayerPush: true, callback: () => { root.location.href = "/signup/download-app" } })
            }}
          >
            Explore Your Radius Account
          </ICAButtons>
        </RightWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(AppContainer(Success))
