import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import CRMV2Container from "container/CRM"
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg"
import { Draggable } from "react-beautiful-dnd"
import { formatAmount } from "services/formUtils"
import BrbcContainer from "container/BRBC"
import icons from "@icons"
import colors from "@colors"
import Box from "@ui/Box"
import DropdownMenu from "@ui/NewTooltip"
import moreMenuSVG from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Menu.svg"
import {
  Li,
} from "@ui/DragDropColumn/common"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"

const Wrap = styled.div`
  position: relative;
  display: flex;
  text-decoration: none;
  padding: 7px;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
`

const StyledMenuIcon = styled.img`
  padding: 2px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
`

const TrimmedText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    cursor: auto;
    color: ${colors.marineBLue};
    text-decoration: underline;
  }
`

const CardContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding: 5px;
  height: max-content;
  width: 100%;
  user-select: none;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5);
  border: 0.5px solid ${props => (props.isDragging ? colors.primaryColor : "#e5e5e5")};
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  &:hover {
    border: 1px dashed black;
  }
  ${props => props.isReferralCardBusy
    && `
      opacity: 0.5;
      pointer-events: none;
  `};
`

const CategoryTag = styled.span`
  border-radius: 4px;
  color: ${colors.marineBLue};
  font-family: ${ATC};
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 8px;
`

const PriceWrap = styled.small`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-family:${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

const Card = (props) => {
  const {
    data: agentInfo,
    toggleUserOverviewDrawer,
    setCurrentDrawerPage,
    index,
    getClient,
    setClientID,
    setCurrentClientId,
    getClientTransactionsById,
    history,
    clearData,
    setCurrentTab,
    toggleArchiveLeadModal,
    getBrokerageTransactionStatus,
    fetchPresignedBrbcDocs,
    fetchBrbcCoversheet,
    fetchBrbcStatus,
    disabled,
    id: cardID,
  } = props
  const {
    client_id, client_name: name,
    id,
    type,
    price,
    sale_price,
  } = agentInfo || {}
  const payload = {
    showUserOverviewDrawer: true,
    agentInfo,
  }

  const lowerType = type && type.toLowerCase()

  return (
    <Draggable
      draggableId={id}
      index={index}
      // isDragDisabled={cardID === id && disabled}
    >
      {(provided, snapshot) => (
        <CardContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
          isReferralCardBusy={false}
        >
          <div style={{ position: "relative", height: "100%" }}>
            <Wrap
              d="flex"
              onClick={() => {
                if (type.toLowerCase() === "buyer") {
                  setCurrentDrawerPage("search-criteria")
                } else if (type === "seller" || type === "Seller") {
                  setCurrentDrawerPage("property-listing")
                }
                toggleUserOverviewDrawer(payload)
                getClient({ clientId: client_id })
                setClientID({
                  id: client_id,
                  deal_id: id,
                })
                setCurrentClientId(client_id)
                clearData()
                getClientTransactionsById({ transactionId: id })
                history.push(`?transactionId=${id}&client_id=${client_id}&type=${type}`)
                getBrokerageTransactionStatus({ transactionId: id })
                fetchPresignedBrbcDocs({agentId: agentInfo?.agent_id, clientId: client_id})
                fetchBrbcStatus({agentId: agentInfo?.agent_id, clientId: client_id})
                setCurrentTab("OVERVIEW")
              }}
            >
              <Box
                flex="1"
                ta="left"
                style={{
                  maxWidth: "110px", display: "flex", flexDirection: "column", gap: "15px",
                }}
              >
                <CategoryTag type={lowerType}>
                  {type}
                </CategoryTag>
                <TrimmedText>{(name && name.trim()) || get(agentInfo, "emails[0].email", "No Name") }</TrimmedText>
                {price && price !== "NA" && price !== "0" && (
                  <PriceWrap>
                    {formatAmount(price)}
                  </PriceWrap>
                )}
              </Box>
              <Box position="absolute" t="10" r="8">
                <DropdownMenu
                  position="bottom"
                  component={<StyledMenuIcon src={moreMenuSVG} />}
                  top={20}
                  padding={10}
                  left={-190}
                  width={200}
                  borderColor="#FFF"
                  eventType="hover"
                  borderRadius={4}
                  showShadow
                  arrowPosition="right"
                >
                  <React.Fragment>
                    <Li
                      onClick={() => { console.log("view details") }}
                    >
                      <span>View Details</span>
                      <i className={icons.filesFileFolder} />
                    </Li>
                    <Li
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleArchiveLeadModal(true)
                        setClientID({
                          id: client_id,
                          deal_id: id,
                        })
                      }}
                    >
                      <span>Archive</span>
                      <img src={ArchiveIcon} alt="Refer out icon" style={{ width: "20px", height: "20px" }} />
                    </Li>
                  </React.Fragment>
                </DropdownMenu>
              </Box>
            </Wrap>
          </div>
        </CardContainer>
      )}
    </Draggable>
  )
}
export default withRouter(CRMV2Container(BrbcContainer(Card)))
