import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const PageTitle = styled.h1`
  color: var(--color-primary, #5A5FF2);
  font-family: ${MONTSERRAT};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 48px */
  margin: 0;
  width: ${props => props.width || "483px"};
  flex-shrink: 0;

  @media(max-width: 767px) {
    width: fit-content;
  }
`

export const ExpireWrap = styled.div`

`

export const PageDescription = styled.p`
  color: ${props => props.color || "var(--color-tertiary, #1C1C1F)"};
  font-family: ${INTER};
  font-size: ${props => props.fontSize || "16px"};
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin: 0;

  span {
    color: #5A5FF2;
    font-family: ${INTER};
    font-size: 12px;
    text-decoration: underline;
    margin-left: 3px;
  }
`
