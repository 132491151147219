import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  display: flex;
  max-width: 1140px;
  padding: 12px 16px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  width: 100%;
  // border-radius: 8px;
  border-radius: 10px;
  background: rgb(65,68,106);
  background: linear-gradient(87deg, rgba(65,68,106,1) 34%, rgba(90,95,242,1) 63%, rgba(65,68,106,1) 92%);
  height: 49px;
  position: relative;
`

const Button = styled.button`
  width: 127px;
  height: 29px;
  flex-shrink: 0;

  border-radius: 40px;
  border: 1px solid #FFF;
  background: transparent;
  cursor: pointer;
  z-index: 100;

  p {
    color: #FFF;
    text-align: center;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.523%; /* 21.073px */
    margin: 0;
  }
`

const Text = styled.p`
  color: #FFF;
  text-align: center;
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 30.105px */
  margin: 0;

  @media(max-width: 1099px) {
    font-size: 12px;
    text-align: center;
  }

  span.confetti {
    margin-right: 2px;
  }

  span.bold {
    color: #2B2B2B;
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: 1099px) {
      font-size: 12px;
    }
  }

  span.mel {
    background: linear-gradient(133deg, #5A5FF2 15.84%, #F08068 58.36%, #F0A468 79.84%, #A5A8FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span img {
    width: 15.15px;
    height: 16.374px;
    flex-shrink: 0;
  }

  a {
    color: var(--color-primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;

    @media(max-width: 1099px) {
      font-size: 12px;
    }
  }
`

const InfinityImage = styled.img`
  filter: invert(1) brightness(2);
  opacity: 0.4;
  position: absolute;
  left: 271px;
`

const EightImage = styled.img`
  filter: invert(1) brightness(2);
  opacity: 0.4;
  position: absolute;
  right: 168px;
  top: 1px;
`

const Banner = () => (
  <Wrap>
    <InfinityImage src="https://s3.amazonaws.com/cdn.agentdesks.com/images/Mel/Infinity.svg" alt="mel_infinity" />
    <Text>
      Your Real Estate AI Assistant:
      {" "}
      <span className="mel">Mel</span>
      <span>
        <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/Mel/StarMel.svg" alt="mel_logo" />
      </span>
      {" "}
      is here.
    </Text>
    <Button
      onClick={() => {
        handleDataLayerPush(null, "meet_mel_banner_clicked", "Meet Mel")
        root.open("https://www.radiusagent.com/mel")
      }}
    >
      <p>
        Meet Mel &gt;
      </p>
    </Button>
    <EightImage src="https://s3.amazonaws.com/cdn.agentdesks.com/images/Mel/EightFull.svg" alt="mel_infinity" />
  </Wrap>
)

export default Banner
