import React from "react"
import styled from "styled-components"
import checkedIcon from "images/icons/blue-checkmark.svg"
import colors from "@colors"

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-right: ${props => props.mr};
`

const UnChecked = styled.div`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: 50%;
  border: 2px solid ${colors.disabledColor};
  cursor: pointer;
`

const StyledImg = styled.img`
  vertical-align: middle;
  margin: ${props => props.margin};
`

const Checkbox = ({
  checked,
  onClick,
  size,
  mr,
  srcImage,
  wrapperStyles,
}) => (
  <Wrapper style={{ ...wrapperStyles }} onClick={onClick} mr={mr}>
    {checked ? (
      <StyledImg src={srcImage || checkedIcon} alt="Check Icon" width={size} />
    ) : (
      <UnChecked size={size} />
    )}
  </Wrapper>
)

Checkbox.defaultProps = {
  checked: false,
  size: 20,
}

export default Checkbox
