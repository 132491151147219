import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import colors from "@colors"
import CircularImage from "dumbComponents/common/CircularImage"
import Modal from "@ui/Modal"
import Box from "@ui/Box"

const Wrap = styled.div`
  margin: 55px 0px 40px 0px;
`

const Item = styled.div`
  display: flex;
  padding: 10px 20px;
  border: 1px solid ${colors.borderGray};
  cursor: pointer;
  &:hover {
    h5 {
      color: ${colors.primaryColor};
    }
  }
  h5 {
    margin: 0px;
    padding-left: 20px;
  }
`

const GuestList = ({
  toClose,
  agents,
}) => (
  <Modal
    show
    toClose={toClose}
    title="Event Attendees"
    modalStyles={{
      padding: 0,
      boxShadow: "none",
    }}
  >
    <Wrap>
      {agents && agents.map(item => (
        <Item key={item.agent_id} onClick={() => { root.open(item.profile_url) }}>
          <CircularImage
            image={item.agent_image}
            name={`${item.firstname} ${item.lastname}`}
            size="48"
          />
          <Box d="flex" ai="center" flex="1">
            <h5>{`${item.firstname} ${item.lastname}`}</h5>
          </Box>
        </Item>
      ))}
    </Wrap>
  </Modal>
)

export default GuestList
