import { bindActionCreators } from "redux"
import { connect } from "react-redux"

const mapStateToProps = (state) => {
  const { app } = state
  const { globalPaddingTop } = app
  return {
    globalPaddingTop,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({ }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
