import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  ...createDeltaReducer("rarMentorsList"),
  ...createDeltaReducer("rarMentorsForm"),
  ...createDeltaReducer("rarMentorsFormSubmit"),
}

export default function rarMentoring(state = initState, action) {
  switch (action.type) {
    case Actions.getAllRarMentorsList.REQUEST:
    case Actions.getAllRarMentorsList.SUCCESS:
    case Actions.getAllRarMentorsList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllRarMentorsList, "rarMentorsList"),
      }
    }

    case Actions.getRarMentorsFrom.REQUEST:
    case Actions.getRarMentorsFrom.SUCCESS:
    case Actions.getRarMentorsFrom.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getRarMentorsFrom, "rarMentorsForm"),
      }
    }

    case Actions.saveRarMentorsForm.REQUEST:
    case Actions.saveRarMentorsForm.SUCCESS:
    case Actions.saveRarMentorsForm.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.saveRarMentorsForm, "rarMentorsFormSubmit"),
      }
    }

    default: {
      return state
    }
  }
}
