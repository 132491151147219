import React from "react"
import styled from "styled-components"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { pstTimeZone } from "services/Utils"
import root from "window-or-global"
import { BlueButton } from "../commonStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"

//RoomsFeatureImage.png
const Wrap = styled.div`
  width: 554px;
  height: 455px;
  flex-shrink: 0;
  // padding: 0;
  padding: 12px;

  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


  @media(min-width: 1100px) {
    width: 530px;
  }

  @media(min-width: 1366px) {
    width: 554px;
  }

  @media(max-width: 1099px) {
    width: 100%;
    height: max-content;
  }
`

const Tag = styled.div`
  min-width: 105px;
  border-radius: 41.5px;
  width: max-content;
  height: 22px;
  position: absolute;
  background: #1C1C1F;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  margin-left: 20px;
`

const HeroWrap = styled.div`
  position: relative;
  height: 182px;
  width: 100%;
  background-image: url(${props => props.image || `${CLOUDFRONT}/RoomsFeatureImage.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`

const DateDetailsWrap = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.noData && `
    visibility: hidden;
  `}
`

const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 117px;
`

const Title = styled.div`

  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  span {
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-around;
  height: max-content;
  gap: 32px;
`

const Description = styled.p`
  color: #000;
  font-family: ${INTER};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.21px;
  min-height: 50px;
  overflow: scroll;

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`

const CTAWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Date = styled.p`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`

const HostedBy = styled.p`
  color: #000;
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Attendees = styled.p`
  color: #898989;
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`

const VideoRoomsCard = ({
  bgImage,
  title,
  description,
  host,
  scheduled_at,
  noData,
  roomId,
  attendeesCount,
}) => {
  const PSTTime = pstTimeZone(scheduled_at, true)
  const { pstTimeString, pstDateString } = PSTTime

  const { firstname, lastname } = host || {}

  const roomName = title

  return (
    <Wrap>
      <HeroWrap
        image={bgImage}
      >
        <Tag>{noData ? "NEW ROOM" : "UPCOMING"}</Tag>
      </HeroWrap>
      <Wrapper>
        <DateDetailsWrap noData={noData}>
          <Date>{`${pstDateString} | ${pstTimeString} PST`}</Date>
          <HostedBy>{`Hosted by ${firstname} ${lastname}`}</HostedBy>
        </DateDetailsWrap>
        <DetailsWrap>
          <Title>
            {!noData ? (
              <>
                {roomName}
              </>
            ) : (
              <>
                Start
                {" "}
                <span>
                  Your Own
                </span>
                {" "}
                Room
              </>
            )}
          </Title>
          <Description>
            {!noData && description ? (
              <>
                {description}
              </>
            ) : (
              <>
                {`Have something to share? Need help with an industry obstacle? 
              Launch a Room now and start the conversation with the Radius Community`}
              </>
            )}
          </Description>
        </DetailsWrap>
        <CTAWrap>
          <BlueButton
            unsetMinWidth
            height="18px"
            buttonFS="13px"
            mobileButtonFs="12px"
            move={noData}
            onClick={(e) => {
              e.stopPropagation()
              if (!noData) {
                const payload = {
                  section_name: "on_demand_coaching_and_community",
                }
                handleDataLayerPush(null, "register_clicked", "register", payload)
                root.open(`/scheduledrooms/${roomId}/${roomName}`)
              } else {
                const payload = {
                  section_name: "on_demand_coaching_and_community",
                }
                handleDataLayerPush(null, "get_started_clicked", "get_started", payload)
                root.open("/rooms")
              }
            }}
            className={noData ? "get_started_room_web" : "register_upcoming_room_web"}
          >
            {noData ? "Get Started" : "Register"}
          </BlueButton>
          {!noData && (
            <Attendees>{`${attendeesCount} ${attendeesCount > 1 ? "ATTENDEES" : "ATTENDEE"}`}</Attendees>
          )}
        </CTAWrap>
      </Wrapper>
    </Wrap>
  )
}

export default VideoRoomsCard
