import React from "react"
import styled from "styled-components"
import {
  DropdownWrap,
  MLSImage,
  MLSWrap,
  MLSAddress,
  MLSNumber,
} from "./googleLocationSearchStyles"
import { MONTSERRAT, INTER } from "@fonts"

export const EditButton = styled.button`
  border: none;
  color: var(--Color-Primary-Primary, #5A5FF2);
  background: transparent;
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
`

const FilledMLSTab = ({
  selectedOption,
  setSelectedOption,
  setDisplay,
}) => {
  const handleClick = () => {
    setDisplay("block")
    setSelectedOption()
  }
  return (
    <DropdownWrap
      isDropdown
    >
      <MLSImage src={selectedOption.image} alt="mls" />
      <MLSWrap>
        <MLSNumber>
          {`MLS# ${selectedOption.id}`}
        </MLSNumber>
        <MLSAddress
          fontSize="18px"
        >
          {selectedOption.address}
        </MLSAddress>
      </MLSWrap>
      <EditButton
        onClick={() => handleClick()}
      >
        EDIT
      </EditButton>
    </DropdownWrap>
  )
}

export default FilledMLSTab
