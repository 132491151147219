import * as React from "react"

const CRM = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <g fill={color} clipPath="url(#a)">
        <path d="M21.797 11.378a.653.653 0 0 0-.653.653v7.396l-5.225 2.09v-6.873a.653.653 0 0 0-1.306 0v6.872l-5.226-2.09V9.078l3.027 1.21a.653.653 0 1 0 .485-1.213L8.989 7.51h-.001l-.01-.004a.648.648 0 0 0-.485 0l-.012.004H8.48L1.96 10.12a.653.653 0 0 0-.41.606v11.757a.654.654 0 0 0 .896.606l6.288-2.515 6.277 2.51h.001l.012.006c.156.063.33.063.485 0l.011-.005h.002l6.52-2.608c.245-.1.408-.34.408-.607v-7.838a.653.653 0 0 0-.653-.653ZM8.081 19.426l-5.225 2.09v-10.35l5.225-2.09v10.35ZM17.878 4.847c-1.08 0-1.96.879-1.96 1.96 0 1.08.88 1.959 1.96 1.959s1.96-.88 1.96-1.96-.88-1.96-1.96-1.96Zm0 2.612a.653.653 0 1 1 0-1.306.653.653 0 0 1 0 1.306Z" />
        <path d="M17.878 2.234a4.577 4.577 0 0 0-4.572 4.572c0 2.346 3.668 6.501 4.086 6.968a.657.657 0 0 0 .972 0c.418-.466 4.086-4.622 4.086-6.968a4.577 4.577 0 0 0-4.572-4.572Zm0 10.107c-1.426-1.692-3.265-4.289-3.265-5.535a3.27 3.27 0 0 1 3.265-3.265 3.27 3.27 0 0 1 3.266 3.265c0 1.245-1.84 3.843-3.266 5.535Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M1.55 2.234H22.45v20.902H1.55z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CRM
