import React from "react"
import styled from "styled-components"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

const ShareIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  > i {
    width: 20px;
    height: 20px;
    border: #222 1px solid;
    border-radius: 50%;
    display: inline-flex;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .icon_wrap {
      width: 95px;
      padding: 0px 5px;
    }
  }
`

const IconWrap = styled.div.attrs({
  className: "icon_wrap",
})`
  position: absolute;
  top: 0px;
  left: 20px;
  height: 22px;
  display: flex;
  width: 95px;
  justify-content: space-between;
  transition-duration: 0.3s;
  width: 0px;
  overflow: hidden;
  border-radius: 20px;
  & > div {
    position: aboslute;
    top: 0px;
    left: 0px;
  }
`

const SIZE = 22

const ShareButton = ({ url = "" }) => (
  <ShareIcon>
    <i className="icon-generic-Share1"></i>
    <IconWrap>
      <FacebookShareButton url={encodeURI(url)}>
        <FacebookIcon round size={SIZE} />
      </FacebookShareButton>
      <LinkedinShareButton url={encodeURI(url)}>
        <LinkedinIcon round size={SIZE} />
      </LinkedinShareButton>
      <TwitterShareButton url={encodeURI(url)}>
        <TwitterIcon round size={SIZE} />
      </TwitterShareButton>
    </IconWrap>
  </ShareIcon>
)

export default ShareButton
