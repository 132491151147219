import React, { PureComponent } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { FileUpload } from "dumbComponents/common/InputFields"
import colors from "@colors"
import TechAssetsContainer from "container/TechAssets"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  padding: 0px 24px;
  .ant-form-item-label {    
    > label {
      color: #303030;
      font-family: ${ATC};
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    
  }
`

const SectionHeader = styled.p`
  color: var(--gray-900, #18181B);
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 169.231% */
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const RightSideBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const LabelText = styled.h2`
  color: var(--gray-500, #71717A);
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  margin: 0;
  width: 70%;
`

class CoverSheet extends PureComponent {
  state = {
    showEscrowOption: null,
    notTCOption: null,
  }

  getBackRoute = () => {
    const { isFromDirectForm, backFormRoute, history } = this.props
    if (!isFromDirectForm) {
      history.push("/realty/transaction-coordination/contract/transaction-details")
    }
    history.push(backFormRoute)
  }

  onFinish = (fieldValues) => {
    const {
      history, dealToView, uploadTCFiles,
    } = this.props
    uploadTCFiles({
      file: fieldValues.tc_file_upload.file,
      id: dealToView,
      callback: () => {
        history.push("/realty/transaction-coordination/contract/successfully-filed")
      },
    })
  }

  setShowEscrowOption = (value) => {
    this.setState({
      showEscrowOption: value === 1,
      notTCOption: value === 0,
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { showEscrowOption, notTCOption } = this.state
    const tcInfo = getListingCoverSheetResponse.data?.tcInfo
    const result = {}
    if (tcInfo) {
      if (showEscrowOption === null && notTCOption === null) {
        this.setState({
          showEscrowOption: tcInfo.is_using_deal_tc === 1,
          notTCOption: tcInfo.is_using_deal_tc === 0,
        })
      }
      return {
        ...tcInfo,
      }
    }
    return result
  }

  getIsEdit = deal => deal
  && !(deal.step === "commission_verified"
  || deal.step === "closed"
  || deal.step === "payment_received")

  render() {
    const {
      getContractByIdResponse,
      dealToView,
      disabled,
      handleFileUpload,
      documents,
      isContractPhase,
    } = this.props

    return (
      <Wrap>
        <Container>
          <SectionHeader>
            Upload Files
          </SectionHeader>
          <RightSideBar>
            <LabelText>
              {`Please upload the fully executed contract documents for 
                 this transaction.`}
            </LabelText>
            <FileUpload
              handleFileUpload={handleFileUpload}
              dealId={!isContractPhase ? dealToView : ""}
              disabled={disabled || !(!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])))}
            />
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default withRouter(TransactionContainer(TechAssetsContainer(CoverSheet)))

