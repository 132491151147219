import React, { useState } from "react";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import DatesCardCarousel from "../DatesCardCarousel/index.js";
import RequestTourModal from "../RequestTourModal/index";
import { OpenHouseHeader, OpenHouseDesc, DatesCardWrapper } from "../styles.js";
import "../styles.css";

const OpenHouse = ({ listingPageResponse }) => {
  const [visitingDateTime, setVisitingDateTime] = useState();
  const [openModal, setopenModal] = useState(false);

  const handleSelectedDateTime = (dateTime) => {
    setVisitingDateTime(dateTime);
    setopenModal(true);
  };

  return (
    <>
      {openModal && (
        <RequestTourModal
          openModal={openModal}
          onClose={() => setopenModal(false)}
          isVisitingDateTime={openModal}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
          visitingDateTimeProps={visitingDateTime}
        />
      )}
      <hr style={{ color: "#E0E0EB" }}></hr>
      <OpenHouseHeader id="openHouses">Open Houses</OpenHouseHeader>
      <OpenHouseDesc>Select a time that works best for you</OpenHouseDesc>
      <DatesCardWrapper>
        <DatesCardCarousel
          buttonTitle="Request a tour"
          buttonWidth="200"
          selectBarWidth="140"
          onSelectedDateTime={handleSelectedDateTime}
          selectBarWrapperWidth="620px"
          numberOfCards={6.5}
        />
      </DatesCardWrapper>
    </>
  );
};

export default ListingPageContainer(OpenHouse);
