import React from "react"
import styled from "styled-components"
import colors from "@colors"
import CloseIcon from "images/Close_7.svg"
import {
  TagCardDiv,
  TagButton
} from "../common"
import { ATC } from "@fonts"

const TagsWrap = styled.div`
  > input {
    width: 100%;
    height: 45px;
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    margin-top: 10px;
  }
`

const SuggestedAgentsDropdown = styled.div`
  position: relative;

  ${props => props.isAgentDropDown && `
    border: 1px solid #ccc;
  `}

  > div {
    position: absolute;
    background: ${colors.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    overflow: scroll;
    border-radius: 10px;
    max-height: 250px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const AgentSelectWrap = styled.div`

  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  min-height: 60px;
  padding: 15px;

  > img {
    height: 20px;
    width: 20px;
    margin: 0px 0px 0px 10px;
    border-radius: 10px;
  }

  > div {
    display: flex;
    padding: 10px;
  }
`

const TagsSpan = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: wrap;
  gap: 10px;

  > div {
    padding: 4px 12px;
    gap: 4px;
    background: #F1F3F8;
    border-radius: 36px;
    display: flex;
    align-items: center;
    width: max-content;

    > p {
      margin: 0px;
    }

    > button {
      border: none;
      cursor: pointer;
      img {
        width: 15px;
        height: 20px;
      }
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;

  > p {
    font-size: 20px;
    font-family: ${ATC};
    font-weight: 300;
    margin: 0 0 3px;
    color: ${colors.black};
  }

  > small {
    font-size: 20px;
    font-family: ${ATC};
    font-weight: 300;
    line-height: 24px;
    color: ${colors.suvaGray};
  }
`

const Tags = ({
  handleRemoveTag,
  roomTags,
  showDropDown,
  tagsSearch,
  handleTagsInput,
  handleKeyPress,
  tags,
}) => (
  <TagsWrap>
    <TitleWrap>
      <p>
        Tags
      </p>
      <small>
        (up to 10)
      </small>
    </TitleWrap>
    <input
      type="text"
      name="agentName"
      value={tagsSearch}
      onChange={(e) => { handleTagsInput(e) }}
      onKeyDown={e => handleKeyPress(e)}
    />
    {(roomTags && showDropDown) && (
      <SuggestedAgentsDropdown>
        <div>
          {roomTags.length > 0 && roomTags.map(roomTag => (
            <AgentSelectWrap
              onClick={e => handleKeyPress(e, roomTag)}
            >
              <div>
                {roomTag.name}
              </div>
            </AgentSelectWrap>
          ))}
        </div>
      </SuggestedAgentsDropdown>
    )}
    <TagsSpan>
      {tags && (
        tags.map(tag => (
          <TagCardDiv>
            <p>
              {tag.name}
            </p>
            <TagButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleRemoveTag(tag)
              }}
            >
              <img src={CloseIcon} alt="close" />
            </TagButton>
          </TagCardDiv>
        ))
      )}
    </TagsSpan>
  </TagsWrap>
)

export default Tags
