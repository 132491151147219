import cookie from "services/CookieStorage"
import STATES from "shared/US_STATES.json"
const user = cookie.load("user")
const agent_id = user.id

export const PAYLOAD = {
  agent_id,
  save_draft: 1,
  property_info: {},
  agents_info: [],
  client_info: [],
  transaction_details: {},
}

export const OFFERS_PAYLOAD = {
  agent_id,
  save_draft: 1,
  property_info: {},
  agents_info: [],
  client_info: [],
  transaction_details: {},
}

export const VALID_STATES = ["California", "Colorado", "Florida", "Georgia", "Oregon", "Texas", "Washington"]

export const STATE_OPTIONS = Object.keys(STATES).map(key => ({ label: STATES[key], value: STATES[key] }))
