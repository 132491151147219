import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import { getLeaderBoardStats, getLeaderBoardLists, getLeaderBoardMonthlyWinner, resetLeaderBoardLists, getTeamStats, getHomePageGraphStats } from "./actions"

const mapStateToProps = (state) => {
  const { leaderBoardRarDashboard } = state
  const isLogged = authCheck()
  return {
    isLogged,
    ...leaderBoardRarDashboard,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      getLeaderBoardStats: getLeaderBoardStats.request,
      getTeamStats: getTeamStats.request,
      getLeaderBoardLists: getLeaderBoardLists.request,
      resetLeaderBoardLists: resetLeaderBoardLists.request,
      getLeaderBoardMonthlyWinner: getLeaderBoardMonthlyWinner.request,
      getHomePageGraphStats: getHomePageGraphStats.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
