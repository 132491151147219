import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import colors from "@colors"

const Anchor = styled(Link)`
  font-size: 14px;
  padding-top: 4px;
  font-weight: 400;
  color: ${colors.primaryColor};
  text-decoration: none;
  cursor: pointer;
`

const Wrapper = styled.div`
  max-height: ${props => (props.show ? "84px" : "auto")};
  overflow: ${props => (props.show ? "hidden" : "auto")};
`

export default class SeeMoreTextWrapper extends Component {
  state = {
    showSeeMoreButton: false,
  }

  componentDidMount() {
    const { contentId, height, isEnabled } = this.props
    if (isEnabled) {
      this.ele = document.getElementById(contentId)
      const eleHeight = this.ele.clientHeight
      if (eleHeight > height) {
        this.setState({
          showSeeMoreButton: true,
        })
      }
    }
  }

  render() {
    const {
      onClick,
      children,
      isEnabled,
      height,
      to = "",
    } = this.props
    const { showSeeMoreButton } = this.state

    const show = showSeeMoreButton && isEnabled
    return (
      <React.Fragment>
        <Wrapper show={show} height={height}>
          {children}
        </Wrapper>

        {show && <Anchor to={to}>See more...</Anchor>}
      </React.Fragment>
    )
  }
}
