import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as AcademyActions from "./actions"

const EVENT_LISTING = "eventListing"
const COURSE_LISTING = "courseListing"
const COURSE_DETAILS = "courseDetails"
const LESSON_DETAILS = "lessonDetails"
const EVENT_DETAILS = "eventDetails"
const NEXT_LESSON = "nextLesson"
const COMMUNITY_UPCOMING_EVENTS = "communityEventsResponse"
const LESSON_STATUS = "lessonStatusResponse"
const CATEGORIES = "categoryFetchResponse"
const ACADEMYVIEWS = "academyViewedResponse"

const initState = {
  isregisteredForEventModal: false,
  ...createDeltaReducer(EVENT_LISTING),
  ...createDeltaReducer(COURSE_LISTING),
  ...createDeltaReducer(COURSE_DETAILS),
  ...createDeltaReducer(LESSON_DETAILS),
  ...createDeltaReducer(EVENT_DETAILS),
  ...createDeltaReducer(NEXT_LESSON),
  ...createDeltaReducer(COMMUNITY_UPCOMING_EVENTS),
  ...createDeltaReducer(LESSON_STATUS),
  ...createDeltaReducer(CATEGORIES),
  ...createDeltaReducer(ACADEMYVIEWS),
}

export default function academyReducer(state = initState, action) {
  switch (action.type) {
    case AcademyActions.getEventsListingAction.REQUEST:
    case AcademyActions.getEventsListingAction.SUCCESS:
    case AcademyActions.getEventsListingAction.CLEAR:
    case AcademyActions.getEventsListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getEventsListingAction, EVENT_LISTING),
      }
    }

    case AcademyActions.academyViewedAction.REQUEST:
    case AcademyActions.academyViewedAction.SUCCESS:
    case AcademyActions.academyViewedAction.CLEAR:
    case AcademyActions.academyViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.academyViewedAction, ACADEMYVIEWS),
      }
    }

    case AcademyActions.changeLessonStatusAction.REQUEST:
    case AcademyActions.changeLessonStatusAction.SUCCESS:
    case AcademyActions.changeLessonStatusAction.CLEAR:
    case AcademyActions.changeLessonStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.changeLessonStatusAction, LESSON_STATUS),
      }
    }
    case AcademyActions.getCourseListingAction.REQUEST:
    case AcademyActions.getCourseListingAction.SUCCESS:
    case AcademyActions.getCourseListingAction.CLEAR:
    case AcademyActions.getCourseListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getCourseListingAction, COURSE_LISTING),
      }
    }
    case AcademyActions.getCourseDetailsAction.REQUEST:
    case AcademyActions.getCourseDetailsAction.SUCCESS:
    case AcademyActions.getCourseDetailsAction.CLEAR:
    case AcademyActions.getCourseDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getCourseDetailsAction, COURSE_DETAILS),
      }
    }
    case AcademyActions.getLessonDetailsAction.REQUEST:
    case AcademyActions.getLessonDetailsAction.SUCCESS:
    case AcademyActions.getLessonDetailsAction.CLEAR:
    case AcademyActions.getLessonDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getLessonDetailsAction, LESSON_DETAILS),
      }
    }
    case AcademyActions.getEventDetailsAction.REQUEST:
    case AcademyActions.getEventDetailsAction.SUCCESS:
    case AcademyActions.getEventDetailsAction.CLEAR:
    case AcademyActions.getEventDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getEventDetailsAction, EVENT_DETAILS),
      }
    }
    case AcademyActions.getNextLessonAction.REQUEST:
    case AcademyActions.getNextLessonAction.SUCCESS:
    case AcademyActions.getNextLessonAction.CLEAR:
    case AcademyActions.getNextLessonAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AcademyActions.getNextLessonAction, NEXT_LESSON),
      }
    }

    //getCommunityUpcomingEventsAction
    case AcademyActions.getCommunityUpcomingEventsAction.REQUEST:
    case AcademyActions.getCommunityUpcomingEventsAction.SUCCESS:
    case AcademyActions.getCommunityUpcomingEventsAction.CLEAR:
    case AcademyActions.getCommunityUpcomingEventsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AcademyActions.getCommunityUpcomingEventsAction,
          COMMUNITY_UPCOMING_EVENTS
        ),
      }
    }

    case AcademyActions.getCategoriesListingAction.REQUEST:
    case AcademyActions.getCategoriesListingAction.SUCCESS:
    case AcademyActions.getCategoriesListingAction.CLEAR:
    case AcademyActions.getCategoriesListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AcademyActions.getCategoriesListingAction,
          CATEGORIES,
        ),
      }
    }

    case AcademyActions.toggleRegisteredModalAction.type: {
      return {
        ...state,
        isregisteredForEventModal: action.data,
      }
    }

    case AcademyActions.currentCategoryTypeAction.type: {
      const {
        currentCategoryType,
      } = action.data

      return {
        ...state,
        currentCategoryType,
      }
    }
    default: {
      return state
    }
  }
}
