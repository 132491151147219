import { createSelector } from "reselect"

export const getIsFetchingAgentsFromStore = state => state.search.isFetchingAgents

export const getAgentsFromStore = state => state.search.agents

export const getagentsTotalCountFromStore = state => state.search.agentsTotalCount

export const getFacadesFromStore = state => state.search.facades

export const getKeyedFacadesFromStore = state => state.search.keyedFacades

export const getAppliedFiltersFromStore = state => state.search.appliedFilters

export const getUnappliedFiltersFromStore = state => state.search.unappliedFilters

export const getAppliedFiltersKeyPayload = state => state.search.appliedFiltersKeyPayload

export const getTotalPagesFromStore = state => state.search.totalPages

export const getCurrentPageNumberFromStore = state => state.search.currentPageNumber

export const getRandomSeedFromStore = state => state.search.randomSeed

export const getQueryParamsFromStore = state => state.search.queryParams

export const getSelectedFiltersFromStore = state => state.search.selectedFilters

export const getFilterSelectedCategory = state => state.search.filterSelectedCategory

export const getLastFilterItemIndex = state => state.search.lastFilterItemIndex

export const getShowFilterPanel = state => state.search.showFilterPanel

export const getCityFromStore = state => state.search.queryParams.city

export const getKeyedSingleFiltersFromStore = state => state.search.keyedSingleFilters

export const getAppliedSingleFilterPayload = state => state.search.appliedSingleFilterPayload

export const getIsFetchingClientsByNameFromStore = state => state.search.isFetchingClientsByName

export const getClientsByNameFromStore = state => state.search.clientsByName

export const getKeyedFacadesForCategorySelector = createSelector(
  [getKeyedFacadesFromStore, getFilterSelectedCategory],
  (keyedFacades, filterSelectedCategory) => {
    if (keyedFacades[filterSelectedCategory]) {
      return keyedFacades[filterSelectedCategory]
    }
    return {}
  }
)

export const getAppliedFiltersForCategorySelector = createSelector(
  [getAppliedFiltersFromStore, getFilterSelectedCategory],
  (appliedFilters, filterSelectedCategory) => {
    if (appliedFilters[filterSelectedCategory]) {
      return appliedFilters[filterSelectedCategory]
    }
    return []
  }
)

export const getUnappliedFiltersForCategorySelector = createSelector(
  [getUnappliedFiltersFromStore, getFilterSelectedCategory],
  (unappliedFilters, filterSelectedCategory) => {
    if (unappliedFilters[filterSelectedCategory]) {
      return unappliedFilters[filterSelectedCategory]
    }
    return []
  }
)
