import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import RadiusCoverageChart from "dumbComponents/NewLandingPage/images/radiusCoverage.png"
import WhiteBlock from "dumbComponents/NewLandingPage/images/whiteBlock.png"
import purpleBlock from "dumbComponents/NewLandingPage/images/purpleBlock.png"
import upArrow from "dumbComponents/NewLandingPage/images/upArrow.png"
import colors from "@colors"
import { ATC } from "@fonts"

const ImageWrap = styled.div`
  position: absolute;
  bottom: 55px;
  left: 105px;
  display: flex;
  flex-direction: column;
  max-width: fit-content !important;
  max-height: fit-content !important;
  align-items: center;

  @media(max-width: 1200px) {
    top: 100px;
    left: 250px;
    flex-direction: row;
  }

  @media(max-width: 1099px) {
    top: 220px;
    left: 150px;
    flex-direction: column;
  }

  @media(max-width: 767px) {
    top: 68px;
    left: 175px;
    flex-direction: row;
  }

  @media(max-width: 425px) {
    flex-direction: column !important;
  }

  > img {
    width: 8px;
    height: 46px;

    @media(max-width: 1200px) {
      rotate: -90deg;
      margin-right: 25px;
    }

    @media(max-width: 1099px) {
      rotate: none;
      margin: 0px;
    }

    @media(max-width: 767px) {
      rotate: -90deg;
      margin-right: 25px;
    }
  }

  > p {
    color: ${colors.white};
    margin: 0;
    width: 49px;
    font-family: ${ATC};

    &:nth-child(2) {
      right: 95px;
      font-size: 20px;
      bottom: 75px;
      font-weight: 200;
      @media(max-width: 767px) {
        font-size: 18px;
      }

      @media(max-width: 425px) {
        font-size: 20px;
      }
    }

    &:nth-child(3) {
      right: 100px;
      font-size: 16px;
      bottom: 50px;
      font-weight: 200;

      @media(max-width: 425px) {
        font-size: 16px;
      }
    }
  }
`

const Wrap = styled.div`
  background: linear-gradient(0deg, rgba(48, 48, 48, 0.8), rgba(48, 48, 48, 0.8)), url("https://d2fedz0by71ckz.cloudfront.net/images/radiusCovBg.webp");
  background-position-x: center;
  background-position-y: bottom;
  background-size: 1760px;
  padding: 20px;
  border-radius: 8px;

  @media(max-width: 1099px) {
    padding: 20px 20px 70px;
  }

  > h1 {
    font-size: 28px;
    margin: 0 0 10px 0;
    font-family: ${ATC};
    color: ${colors.dreamWhite};
    letter-spacing: 3px;

    @media(max-width: 767px) {
      font-size: 24px;
    }

    @media(max-width: 425px) {
      font-size: 24px;
    }
  }

  > p {
    max-width: 562px;
    color: ${colors.dreamWhite};
    line-height: 25px;
    font-family: ${ATC};
    font-weight: 200;
  }
`

const PieWrap = styled.div`
  display: flex;
  position: relative;

  @media(max-width: 1200px) {
    flex-direction: column;
  }

  @media(max-width: 1099px) {
    flex-direction: row;
    justify-content: space-around;
    gap: 100px;
  }

  @media(max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  @media(max-width: 425px) {
    flex-direction: column;
  }
  > img {
    height: 251px;
    width: 251px;

    @media(max-width: 767px) {
      height: 180px;
      width: 180px;
    }
  }

  > div {
    max-width: 340px;
    width: 100%;

    @media(max-width: 1200px) {
      display: flex;
      max-width: 553px;
    }

    @media(max-width: 768px) {
      display: flex;
      flex-direction: column; 
    }

    @media(max-width: 767px) {
      flex-direction: row;
    }

    @media(max-width: 425px) {
      flex-direction: column;
    }
  }
`

const InfoPara = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > p{
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    margin: 0px;
    color: ${colors.dreamWhite};
    @media(max-width: 1109px) {
      text-align: center;
    }
    @media(max-width: 725px) {
      font-size: 20px;
      line-height: 22px;
      text-align: initial;
    }

    @media(max-width: 425px) {
      font-size: 16px;
    }
  }
`
const InfoDetails = styled.li`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.dreamWhite};
  @media(max-width: 725px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media(max-width: 425px) {
    font-size: 14px;
  }
`

const InfoDetailsWrap = styled.div`
  @media(max-width: 1109px) {
    display: flex;
    justify-content: center;
  }
  @media(max-width: 768px) {
    display: block;
  }
`

const InfoDetailsContainer = styled.ul`
  list-style: disc;
  margin-left: 28px;
  @media(max-width: 1109px) {
    margin-left: 40px;
  }
  @media(max-width: 725px) {
    margin-left: 20px;
  }

  @media(max-width: 425px) {
    margin-left: 27px;
  }
`

const DetailsWrap = styled.div`
  > div {
  margin-bottom: 15px;
  }
`

const RadiusCoverage = () => {
  return (
    <Wrap>
      <h1>
        PARTNERED WITH RADIUS
      </h1>
      <p>
        Radius is your ultimate operational partner. You only focus on sales and building your client relationships.
      </p>
      <PieWrap>
        <LazyLoad offset={100}>
          <img src={RadiusCoverageChart} alt="Radius Agent Coverage" />
        </LazyLoad>
        <ImageWrap>
          <img src={upArrow} alt="Arrow Radius" loading="lazy" />
          <p>90%</p>
          <p>Radius</p>
        </ImageWrap>
        <DetailsWrap>
          <div>
            <InfoPara>
              <img
                src={WhiteBlock}
                alt="Radius White Block"
                height={33}
                width={33}
                loading="lazy"
              />
              <p>
                YOU AND YOUR TEAM
              </p>
            </InfoPara>
            <InfoDetailsWrap>
              <InfoDetailsContainer>
                <InfoDetails>
                  Sales
                </InfoDetails>
                <InfoDetails>
                  Client Relations
                </InfoDetails>
                <InfoDetails>
                  Team Leadership and Mentorship
                </InfoDetails>
              </InfoDetailsContainer>
            </InfoDetailsWrap>
          </div>
          <div>
            <InfoPara>
              <img
                src={purpleBlock}
                alt="Radius Purple Block"
                height={33}
                width={33}
                loading="lazy"
              />
              <p>
                RADIUS
              </p>
            </InfoPara>
            <InfoDetailsWrap>
              <InfoDetailsContainer>
                <InfoDetails>
                  DBA setup
                </InfoDetails>
                <InfoDetails>
                  Brand Formation
                </InfoDetails>
                <InfoDetails>
                  Customized Marketing Support
                </InfoDetails>
                <InfoDetails>
                  Recruiting Support
                </InfoDetails>
                <InfoDetails>
                  Equity Ownership Opportunity
                </InfoDetails>
                <InfoDetails>
                  Transaction Coordination
                </InfoDetails>
                <InfoDetails>
                  Compliance and Escalation Support
                </InfoDetails>
                <InfoDetails>
                  Financing Programs and Concierge
                </InfoDetails>
                <InfoDetails>
                  Training and Continued Education
                </InfoDetails>
                <InfoDetails>
                  Wellness Programs
                </InfoDetails>
                <InfoDetails>
                  Lead Nurturing
                </InfoDetails>
              </InfoDetailsContainer>
            </InfoDetailsWrap>
          </div>
        </DetailsWrap>
      </PieWrap>
    </Wrap>
  )
}
export default RadiusCoverage
