import React from "react"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Agents from "../Icons/NewIcons/Agents"

const FindAnAgentTab = ({
  routeArray,
  isFindAnAgentNewTag,
}) => (
  <InnerTab className="onboading-find-an-agent" reducePadding={isFindAnAgentNewTag !== "seen"} isActive={routeArray[1] === "find"}>
    <div className="icon-block-new">
      <Agents />
    </div>
    <div className="text-block">
      <NavLink
        to="/referrals/find"
        isActive={routeArray[1] === "find"}
      >
        Agents
      </NavLink>
    </div>
  </InnerTab>
)

export default FindAnAgentTab
