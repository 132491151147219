import React from "react"
import colors from "@colors"
import styled, { css } from "styled-components"

const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  color: ${colors.black};
  input:active,
  input:focus {
    border-bottom: 2px solid #11adf3;
    ::placeholder {
      color: #ccc !important;
    }

    :-ms-input-placeholder {
      color: #ccc !important;
    }

    ::-ms-input-placeholder {
      color: #ccc !important;
    }
    & ~ span {
      /* //font-size: 14px; */
      bottom: 45px;
    }
  }

  ${props => props.isFilled
    && css`
      span {
        /* //font-size: 14px; */
        bottom: 45px;
      }
    `}
`

const Span = styled.span`
  transition-duration: 0.2s;
  pointer-events: none;
  /* position: absolute;
  bottom: 45px; */
  left: 0px;
  width: 100%;
  user-select: none;
  color: #d4d4d4;
  font-weight: 400;
`

const Textarea = styled.textarea`
  flex: 1;
  font-size: 16px;
  background: transparent;
  outline: none;
  padding: 5px 0px;
  border-radius: 4px;
  border: 1px solid ${props => props.isError ? "#ff4b4b !important" : "#d4d4d4"};
  transition-duration: 0.2s;
  padding: 10px;
  height: ${props => props.height};

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 400;
  }
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
`

const CharLimitText = styled.h6`
  font-color: #ddd;
  font-size: 12px;

  ${props => props.isVideoRoom && `
    position: absolute;
    margin: 0px;
    bottom: -25px;
    right: 0;
    color: #C5C5C5;
    font-size: 15px;
  `}

  ${props => props.isExceeded && `
      color: red;
  `}
`

const Input = (field) => {
  const {
    label,
    input: { value = "" },
    charLimit,
    placeholder,
    cols,
    height,
    rows,
    version,
    customStyle,
    isVideoRoom,
    errorStyles,
  } = field
  return (
    <Label isFilled={value}>
      {version === 2 && (
        <Span>{label}</Span>
      )}
      <Textarea
        style={{
          ...customStyle || {},
        }}
        placeholder={placeholder}
        height={height}
        cols={cols}
        rows={rows}
        isError={!!(field.meta.touched && field.meta.error)}
        {...field.input}
      />
      {version !== 2 && (
        <Span>{label}</Span>
      )}
      {field.meta.touched && field.meta.error && (
        <ErrorLabel style={{ ...errorStyles || {} }}>{field.meta.error}</ErrorLabel>
      )}
      {charLimit && (
        <CharLimitText
          isExceeded={value.length > charLimit}
          isVideoRoom={isVideoRoom}
        >
          {`${value.length}/${charLimit} character count`}
        </CharLimitText>
      )}
    </Label>
  )
}

Input.defaultProps = {
  height: "200px",
}

export default Input
