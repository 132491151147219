import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  delay,
  race,
  take,
} from "redux-saga/effects"
import { union, isEmpty, get } from "lodash"
import root, { setTimeout } from "window-or-global"
import query from "query-string"
import {
  isSuccess,
  objectToQueryParams,
  checkForUserObject,
  getOnlyNotNullValues,
  convertUnicode,
  unicodeToEscaped,
} from "services/Utils"
import * as FormInputAction from "container/FormInput/actions"
import CookiesStorage from "services/CookieStorage"
import { uploadImageAPI } from "container/Client/api"
import {
  showConfirmModalAction,
  hideConfirmModalAction,
  logoutUserAction,
  resetFeedInvitePostAction,
} from "container/App/actions"
import { confirmAction } from "container/App/saga"
import {
  getCreateEditModalState,
  getExpertiseToDisplay,
  getSelectedGroupFromStore,
  getSelectedOptionsOfFeedFromStore,
  getSuggestedTopicsFromStore,
  getSelectedIntroCitiesFromStore,
  getUnfinishedStagesFromStore,
} from "container/Network/selector"
import * as ProfileAction from "container/Profile/action"
import { unfollowAgentAPI } from "container/Profile/api"
import createToast from "@ui/Toast"
import { createEditPostModalEssentialState } from "./reducer"
import {
  getAllTopicsAPI,
  getTagsBasedOnTopicsAPI,
  getAllFeedsAPI,
  getCityPreferencesAPI,
  setCityPreferencesAPI,
  getAllSpecializationAPI,
  getSpecializationPreferencesAPI,
  setSpecializationPreferencesAPI,
  getTopicsPreferencesAPI,
  setTopicsPreferencesAPI,
  getSuggestedAgentsAPI,
  followAgentAPI,
  getOnboardingStatusAPI,
  likeFeedAPI,
  likeCommentAPI,
  unLikeFeedAPI,
  unLikeCommentAPI,
  getCommentsForFeedAPI,
  postCommentForFeedAPI,
  createFeedItemAPI,
  updateFeedItemAPI,
  getFeedLikersAPI,
  getCommentLikersAPI,
  getSnippetDataAPI,
  startOnboardingAPI,
  getSuggestedTopicsAPI,
  followTopicAPI,
  createIntroPostAPI,
  setAllTopicsAPI,
  skipIntroPostAPI,
  getLastestCourseAPI,
  getStandAloneFeedAPI,
  reportOrDeleteFeedAPI,
  feedsViewedAPI,
  getNetworkActivityAPI,
  getPeopleToFollowAPI,
  getPeopleYouMayKnowAPI,
  blockAndUnblockAPI,
  getReportReasonsAPI,
  mutePostAPI,
} from "./api"
import * as NetworkAction from "./actions"
const linkify = require("linkify-it")()

function* getAllFeeds(action) {
  try {
    let pubNubVal
    let res
    let isPaginated = false
    if (action.data) {
      const {
        selectedOptions,
        selectedOptions: { feed_ids, isPubNub },
      } = action.data
      isPaginated = !!feed_ids
      pubNubVal = isPubNub
      const params = objectToQueryParams(selectedOptions)
      res = yield call(getAllFeedsAPI, params)
    } else {
      res = yield call(getAllFeedsAPI)
    }

    if (isSuccess(res)) {
      const { response } = res.data
      if (!CookiesStorage.load("feedApiCalledForTheFirstTime")) {
        CookiesStorage.save("feedApiCalledForTheFirstTime", true)
      }
      const networkFeeds = yield select(state => state.network.networkFeeds)
      if (isPaginated) {
        const { feed } = response
        const newResponse = pubNubVal ? union(feed, networkFeeds.feed) : union(networkFeeds.feed, feed)
        yield put(NetworkAction.getAllFeedsAction.success({
          feed: newResponse,
          isPaginated,
        }))
      } else {
        yield put(NetworkAction.getAllFeedsAction.success(response))
      }

      yield put(resetFeedInvitePostAction.call())
    }
  } catch (e) {
    yield put(logoutUserAction.request())
    yield put(NetworkAction.getAllFeedsAction.failure(e))
  }
}

function* getTagsBasedOnTopics(action) {
  try {
    const { topicId } = action.data
    const res = yield call(getTagsBasedOnTopicsAPI, topicId)
    if (isSuccess(topicId)) {
      const { response } = res.data
      console.log(response)
    }
  } catch (e) {
    console.log(e)
  }
}

function* changeFeedSelection(action) {
  try {
    const { type } = action.data
    // if (isQuestion === "question") {
    //   yield
    //   return
    // }
    // yield* put(NetworkAction.changeFeedSelectionAction(action.data))
    const newState = yield select(state => state.network)
    const {
      networkFeeds: { selectedOptions },
    } = newState
    console.log(newState, "newState saga")
    yield put(NetworkAction.getAllFeedsAction.request({ selectedOptions }))
  } catch (error) {
    console.log(error)
    yield put(NetworkAction.getAllFeedsAction.failure(error))
  }
}

function* getUserCities() {
  try {
    const res = yield call(getCityPreferencesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.getCityPreferencesAction.success(response))
    }
  } catch (e) {
    yield put(NetworkAction.getCityPreferencesAction.failure(e))
  }
}

function* setUserCities(action) {
  try {
    const cities = yield select(state => state.network.userCities)
    const res = yield call(setCityPreferencesAPI, {
      cities,
    })
    if (isSuccess(res)) {
      yield put(NetworkAction.setCityPreferencesAction.success())

      if (action && action.data) {
        const { push, pushRoute, showToast } = action.data

        if (showToast) {
          createToast("Cities saved successfully")
        }

        if (push && pushRoute) {
          push(pushRoute)
        }
      }
    }
  } catch (e) {
    yield put(NetworkAction.setCityPreferencesAction.failure(e))
    if (action && action.data) {
      const { showToast } = action.data
      if (showToast) {
        createToast("Cities could not be saved", "error")
      }
    }
  }
}

function* getAllSpecializations() {
  try {
    const res = yield call(getAllSpecializationAPI)
    if (isSuccess(res)) {
      const { specializations } = res.data.response

      yield put(
        NetworkAction.getAllSpecializationsAction.success({
          specializations,
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

function* getUserSpecializations() {
  try {
    const res = yield call(getSpecializationPreferencesAPI)
    if (isSuccess(res)) {
      const { specializations } = res.data.response
      const selectedExpertise = {}

      specializations.forEach((specializationObject) => {
        const key = specializationObject.description
        selectedExpertise[key] = true
      })

      yield put(
        NetworkAction.getSpecializationPreferencesAction.success({
          selectedExpertise,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.getSpecializationPreferencesAction.failure(e))
  }
}

function* setUserSpecializations(action) {
  try {
    const expertise = yield select(getExpertiseToDisplay)
    const selectedExpertise = yield select(state => state.network.selectedExpertise)

    const specializations = []

    expertise.forEach((expertiseObject) => {
      if (selectedExpertise[expertiseObject.title]) {
        specializations.push(expertiseObject.title)
      }
    })

    const res = yield call(setSpecializationPreferencesAPI, {
      specializations,
    })
    if (isSuccess(res)) {
      yield put(NetworkAction.setSpecializationPreferencesAction.success())

      if (action && action.data) {
        const { push, pushRoute, showToast } = action.data

        if (showToast) {
          createToast("Expertise saved successfully")
        }

        if (push && pushRoute) {
          push(pushRoute)
        }
      }
    }
  } catch (e) {
    createToast("Expertise could not be saved", "error")
    yield put(NetworkAction.setSpecializationPreferencesAction.failure(e))
  }
}

function* getAllTopics() {
  try {
    const res = yield call(getAllTopicsAPI)
    if (isSuccess(res)) {
      const { topics } = res.data.response
      yield put(
        NetworkAction.getAllTopicsAction.success({
          topics,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.getAllTopicsAction.failure(e))
  }
}

function* getUserTopics() {
  try {
    const res = yield call(getTopicsPreferencesAPI)
    if (isSuccess(res)) {
      const { topics } = res.data.response
      const selectedTopics = {}
      topics.map((topicObject) => {
        selectedTopics[topicObject.id] = true
      })

      yield put(
        NetworkAction.getTopicsPreferencesAction.success({
          selectedTopics,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.getTopicsPreferencesAction.failure(e))
  }
}

function* setUserTopics(action) {
  try {
    const selectedTopics = yield select(state => state.network.selectedTopics)

    const topicIds = Object.keys(selectedTopics).map(topicId => parseInt(topicId, 10))

    const res = yield call(setTopicsPreferencesAPI, {
      topicIds,
    })
    if (isSuccess(res)) {
      yield put(NetworkAction.setTopicsPreferencesAction.success())

      if (action && action.data) {
        const { push, pushRoute, showToast } = action.data

        if (showToast) {
          createToast("Topics saved successfully")
        }

        if (push && pushRoute) {
          push(pushRoute)
        }
      }
    }
  } catch (e) {
    createToast("Topics could not be saved", "error")
    yield put(NetworkAction.setTopicsPreferencesAction.failure(e))
  }
}

function* getSuggestedAgents(action) {
  try {
    const { exclude } = action.data
    const res = yield call(getSuggestedAgentsAPI, {
      exclude,
    })
    if (isSuccess(res)) {
      const { agents } = res.data.response
      let payload
      if (exclude.length <= 0) {
        payload = {
          suggestedAgents: agents,
        }
      } else {
        const suggestedAgents = yield select(getSuggestedAgentsFromState)
        const existingAgents = suggestedAgents.concat(agents)
        payload = {
          suggestedAgents: existingAgents,
        }
      }
      yield put(NetworkAction.getSuggestedAgentsAction.success(payload))
    }
  } catch (e) {
    yield put(NetworkAction.getSuggestedAgentsAction.failure(e))
  }
}

const getSuggestedAgentsFromState = state => state.network.suggestedAgents
const getFollowedAgentsFromState = state => state.network.followedAgents

function* followAgent(action) {
  try {
    const { agent, agentIndex, shouldFollowOnly } = action.data
    const agentId = agent.id

    const followData = {
      follows: [
        {
          follow_agent_id: agentId,
          email: agent.email,
          phone: agent.phone,
          firstname: agent.firstname,
        },
      ],
      type: "agent",
    }

    const res = yield call(followAgentAPI, followData)

    if (isSuccess(res)) {
      if (!shouldFollowOnly) {
        const suggestedAgents = yield select(getSuggestedAgentsFromState)
        const followedAgents = yield select(getFollowedAgentsFromState)
        const newSuggestedAgents = suggestedAgents.slice()
        const newFollowedAgents = followedAgents.slice()
        newSuggestedAgents.splice(agentIndex, 1)
        newFollowedAgents.push(agentId)

        yield put(
          NetworkAction.followAgentAction.success({
            suggestedAgents: newSuggestedAgents,
            followedAgents: newFollowedAgents,
          })
        )

        //Pagination Starts Here
        if (newSuggestedAgents.length <= 6) {
          //Add existing items ids to exclude
          newSuggestedAgents.map((suggestedAgent) => {
            newFollowedAgents.push(suggestedAgent.id)
          })

          yield put(
            NetworkAction.getSuggestedAgentsAction.request({
              exclude: newFollowedAgents,
            })
          )
        }
      } else {
        yield put(
          NetworkAction.followAgentAction.success({
            agentId,
          })
        )
        yield put(ProfileAction.getOwnFollowerAndFollowingAction.request())
      }
    }
  } catch (e) {
    yield put(NetworkAction.followAgentAction.failure(e))
  }
}

function* unFollowAgent(action) {
  try {
    const { unfollowId, agentId } = action.data
    const res = yield call(unfollowAgentAPI, unfollowId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.unfollowAgentAction.success(response))
      yield put(ProfileAction.getFollowersAndFollowingAction.request(agentId))
      yield put(ProfileAction.getOwnFollowerAndFollowingAction.request())
    }
  } catch (e) {
    yield put(ProfileAction.unfollowAgentAction.failure(e))
  }
}

function* setAllTopics() {
  try {
    const res = yield call(setAllTopicsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.setAllTopicsAction.success(response))
    }
  } catch (error) {
    yield put(NetworkAction.setAllTopicsAction.failure(error))
  }
}

function* checkOnboardingStatus(action) {
  try {
    const { push, location } = action.data
    const res = yield call(getOnboardingStatusAPI)
    if (isSuccess(res)) {
      const { unfinishedStages } = res.data.response
      if (unfinishedStages.length > 0) {
        yield put(
          NetworkAction.getOnboardingStatusAction.success({
            isOnboardingComplete: false,
            unfinishedStages,
          })
        )

        if (!isEmpty(unfinishedStages)) {
          // check if unfinishedStages has topics then add all topics
          if (unfinishedStages.includes("topic")) {
            yield put(NetworkAction.setAllTopicsAction.request())
          }

          // Check unfinishedStages has topics then add all intro
          if (unfinishedStages.includes("intro")) {
            yield put(NetworkAction.toggleIntroPostModalAction(true))
          }
        }
        // const onboardingLocationMap = {
        //   city: "welcome",
        //   topic: "topics",
        //   specialisations: "expertise",
        // }
        // const toPushRoute = `/community/onboarding/${onboardingLocationMap[unfinishedStages[0]]}`
        // push(toPushRoute)
      } else {
        yield put(
          NetworkAction.getOnboardingStatusAction.success({
            isOnboardingComplete: true,
          })
        )
        CookiesStorage.save("onboardingComplete", true)
        if (location.pathname.startsWith("/community/onboarding")) {
          push("/community")
        }
      }
    }
  } catch (e) {
    yield put(NetworkAction.getOnboardingStatusAction.failure(e))
  }
}

function* getCommentsForFeed(action) {
  try {
    const res = yield call(getCommentsForFeedAPI, action.data)
    try {
      const { search } = root.location
      const parseQuery = query.parse(search)
      if (!isEmpty(parseQuery) && parseQuery.comment_id) {
        setTimeout(() => {
          const { comment_id } = parseQuery
          const $ele = root.document.getElementById(`comment_list_${comment_id}`)
          $ele.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
          $ele.classList.add("comment_animation")
        }, 1500)
      }
    } catch (scrollError) {
      console.log({ scrollError })
    }

    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.getCommentsForFeedAction.success(response))
    }
  } catch (e) {
    console.log(e);
    yield put(NetworkAction.getCommentsForFeedAction.failure(e))
  }
}

function* postCommentForFeed(action) {
  try {
    const { comment, feedId } = action.data
    const res = yield call(postCommentForFeedAPI, feedId, { comment: unicodeToEscaped(comment) })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.postCommentForFeedAction.success({ response, feedId }))
      yield put(NetworkAction.getCommentsForFeedAction.request(feedId))
      //yield put(NetworkAction.getUnansweredQuestionsActions.request({ reset: true }))
    }
  } catch (e) {
    yield put(NetworkAction.postCommentForFeedAction.failure(e))
  }
}

function* getLikers(action) {
  try {
    const { id, likersViewType } = action.data
    let res
    if (likersViewType === "comment") {
      res = yield call(getCommentLikersAPI, id)
    } else {
      res = yield call(getFeedLikersAPI, id)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.getLikersAction.success(response))
    }
  } catch (e) {
    yield put(NetworkAction.getLikersAction.failure(e))
  }
}

function* updateFeedLike(action) {
  try {
    const { id, likedByMe } = action.data
    let res
    if (!likedByMe) {
      res = yield call(likeFeedAPI, id)
    } else {
      res = yield call(unLikeFeedAPI, id)
    }

    if (isSuccess(res)) {
      yield put(
        NetworkAction.updateFeedLikeAction.success({
          likedByMe: !likedByMe,
          id,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.updateFeedLikeAction.failure(e))
  }
}

function* updateCommentLike(action) {
  try {
    const { id, likedByMe } = action.data
    let res
    if (!likedByMe) {
      res = yield call(likeCommentAPI, id)
    } else {
      res = yield call(unLikeCommentAPI, id)
    }
    if (isSuccess(res)) {
      yield put(NetworkAction.updateCommentLikeAction.success({ likedByMe: !likedByMe, id }))
    }
  } catch (e) {
    yield put(NetworkAction.updateCommentLikeAction.failure(e))
  }
}

function* finishOnboarding(action) {
  try {
    const { push } = action.data
    CookiesStorage.save("onboardingComplete", true)
    yield put(NetworkAction.setOnboardingCompleteAction())
    push("/community")

    const unfinishedStages = yield select(getUnfinishedStagesFromStore)
    const unfinishedStagesLength = unfinishedStages.length
    if (unfinishedStagesLength > 0 && unfinishedStages[unfinishedStagesLength - 1] === "intro") {
      yield delay(2000)
      yield put(NetworkAction.toggleIntroPostModalAction(true))
    }
  } catch (e) {
    console.warn(e)
  }
}

function* getTopicsBasedOnTopic(action) {
  try {
    const { topicId } = action.data
    const res = yield call(getTagsBasedOnTopicsAPI, topicId)
    if (isSuccess(res)) {
      console.log("Tags", res.data.response)
    }
  } catch (e) {
    console.log(e)
  }
}

function* uploadPostImage(action) {
  try {
    const { imageData } = action.data
    const agentId = yield select(state => state.app.user.id)
    const fd = new FormData()
    fd.append("imageFile", imageData)
    const data = {
      filePath: `network/${agentId}`,
      config: {
        bucket: "profile",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    const imageUploadRes = yield call(uploadImageAPI, fd)
    if (isSuccess(imageUploadRes)) {
      const {
        response: { imageURL },
      } = imageUploadRes.data
      yield put(
        NetworkAction.uploadPostImageAction.success({
          mediaType: "image",
          imageURL,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.uploadPostImageAction.failure(e))
  }
}

function* setTopicAndGetTags(action) {
  try {
    const { topicId } = action.data
    yield put(
      NetworkAction.updateCreateEditPostModalAction({
        topicId,
      })
    )
    const res = yield call(getTagsBasedOnTopicsAPI, topicId)

    if (isSuccess(res)) {
      const { tags } = res.data.response
      yield put(NetworkAction.setTopicAndGetTagsAction.success())

      yield put(
        NetworkAction.updateCreateEditPostModalAction({
          tags,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.setTopicAndGetTagsAction.failure())
  }
}

function* createUpdateFeedItem() {
  try {
    const createEditPostModal = yield select(state => state.network.createEditPostModal)
    const text = yield select(state => state.formInput.createEditPostText)

    const {
      type,
      selectedTags,
      topicId,
      mediaType,
      mediaURL,
      mediaArray,
      groupId,
      feedId,
      city,
      state,
      country,
      latitude,
      longitude,
      snippetURL,
      snippetTitle,
      snippetImageURL,
    } = createEditPostModal

    let fallbackGroupId

    if (!groupId) {
      const groups = yield select(state => state.network.networkFeeds.groups)
      fallbackGroupId = groups.length > 0 ? groups[0].id : 1
    }

    const tagIdArray = Object.keys(selectedTags).map(tagId => parseInt(tagId, 10))

    const payload = {
      title: unicodeToEscaped(text),
      topic_id: topicId,
      tag_ids: tagIdArray,
      feed_type: type,
      group_id: groupId || fallbackGroupId,
      city,
      state,
      country,
      latitude,
      longitude,
    }

    if (mediaType === "image") {
      payload.image_urls = [...mediaArray.map(img => img.url)]
      payload.content_type = "image"
    }

    if (!mediaType && snippetURL) {
      payload.snippet = {
        image: snippetImageURL,
        title: snippetTitle,
        url: snippetURL,
      }
    }

    const transformedPayload = getOnlyNotNullValues(payload)

    let res
    if (feedId) {
      res = yield call(updateFeedItemAPI, feedId, transformedPayload)
    } else {
      res = yield call(createFeedItemAPI, transformedPayload)
    }
    if (payload.feed_type === "question") {
      yield put(NetworkAction.getStandAloneFeedAction.request({
        feedId: get(res, "data.response.feed_id") || get(res, "data.response.id"),
        isInsertFlow: true,
      }))
    }
    if (isSuccess(res)) {
      yield put(NetworkAction.createUpdateFeedItemAction.success({
        type,
      }))
      createToast("Post created successfully.")
      if (feedId) {
        const feed = yield select(state => state.network.networkFeeds.feed)
        const feedItemToUpdateIndex = feed.findIndex(x => x.id === feedId)
        const feedItemToUpdate = feed[feedItemToUpdateIndex]

        const topics = yield select(state => state.network.topics)
        const targetTopicIndex = topics.findIndex(x => x.id === topicId)
        const topicTitle = topics[targetTopicIndex] && topics[targetTopicIndex].title

        const tags = yield select(state => state.network.createEditPostModal.tags)

        const filteredTags = tags.filter(tagObject => tagIdArray.includes(tagObject.id))

        let newImageUrls = []

        if (mediaType === "image" && mediaURL) {
          newImageUrls = [mediaURL]
        }

        const newFeedItem = {
          ...feedItemToUpdate,
          title: text,
          type,
          city,
          state,
          latitude,
          longitude,
          group_id: groupId || 1,
          imageUrls: newImageUrls,
          snippet: payload.snippet || null,
          topicId,
          topicTitle,
          tags: filteredTags,
        }

        const newFeed = feed.slice()
        newFeed.splice(feedItemToUpdateIndex, 1, newFeedItem)
        yield put(
          NetworkAction.updateFeedItemOnEditAction({
            feed: newFeed,
          })
        )

        yield put(NetworkAction.closeFeedDetailViewAction())
      } else {
        yield put(
          NetworkAction.changeFeedSelectionAction({
            type: type === "question" ? "question" : "",
            feed_ids: false,
            lat: false,
            lng: false,
          })
        )
      }

      //Reset Modal Data
      yield put(NetworkAction.resetCreateEditModalAction({
        type,
      }))
      yield put(
        FormInputAction.updateFormInputTextAction({
          key: "createEditPostText",
          text: "",
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.createUpdateFeedItemAction.failure(e))
  }
}

function* updateCreateEditPostModal(action) {
  try {
    const createEditPostModal = yield select(state => state.network.createEditPostModal)
    const newState = {
      ...createEditPostModal,
      ...action.data,
    }

    yield put(NetworkAction.changePostModalStateAction(newState))
  } catch (e) {
    console.log(e)
  }
}

const checkIfConfirmationNeeded = (currentState, initState) => {
  if (currentState.text !== initState.text) {
    return true
  }

  const comparisonKeys = Object.keys(createEditPostModalEssentialState)
  const comparisonKeysLength = comparisonKeys.length
  let i
  for (i = 0; i < comparisonKeysLength; i++) {
    const key = comparisonKeys[i]
    //console.log(key, currentState[key], initState[key])
    if (currentState[key] !== initState[key]) {
      return true
    }
  }

  return false
}

function* confirmCloseCreateEditModal() {
  try {
    const createEditPostModal = yield select(state => state.network.createEditPostModal)
    const createEditPostText = yield select(state => state.formInput.createEditPostText)
    const createEditPostModalInitData = yield select(state => state.network.createEditPostModalInitData)
    const currentState = getEssentialKeyValues(createEditPostModal, createEditPostText)
    const showConfirmationDialog = checkIfConfirmationNeeded(currentState, createEditPostModalInitData)

    if (showConfirmationDialog) {
      const newState = {
        ...createEditPostModal,
        showConfirmCloseModal: true,
      }
      yield put(NetworkAction.changePostModalStateAction(newState))
    } else {
      //Reset state
      yield put(NetworkAction.resetCreateEditModalAction())
      yield put(
        FormInputAction.updateFormInputTextAction({
          key: "createEditPostText",
          text: "",
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

function* closeResetCreateEditModal() {
  try {
    yield put(NetworkAction.resetCreateEditModalAction())
    yield put(
      FormInputAction.updateFormInputTextAction({
        key: "createEditPostText",
        text: "",
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* updateTextForPostModal(action) {
  try {
    yield put(
      FormInputAction.updateFormInputTextAction({
        key: "createEditPostText",
        text: action.data,
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* getUnansweredQuestions(action) {
  try {
    const PAGINATION_COUNT = 5
    const unansweredQuestions = yield select(state => state.network.unansweredQuestions)
    const { feed: oldFeeds, lastFetchedFrom, feedIds } = unansweredQuestions
    const selectedOptions = {
      type: "questions",
    }

    let newLastFetchedFrom = lastFetchedFrom

    if (action && action.data && action.data.fetchMore) {
      const {
        viewedFeed,
      } = action.data
      newLastFetchedFrom = lastFetchedFrom + PAGINATION_COUNT
      const toFetchFeedIds = feedIds.slice(newLastFetchedFrom, newLastFetchedFrom + PAGINATION_COUNT).join()
      console.log("New Feed Ids to Fetch: ", toFetchFeedIds)
      selectedOptions.feed_ids = viewedFeed || toFetchFeedIds
    }
    const selectedGroup = yield select(state => state.network.selectedGroup)
    if (selectedGroup) {
      selectedOptions.group_id = selectedGroup
    }
    const params = objectToQueryParams(selectedOptions)
    const res = yield call(getAllFeedsAPI, params)
    if (isSuccess(res)) {
      const { feed, feedIds } = res.data.response
      let newFeed
      if (action && action.data && action.data.reset) {
        newFeed = feed
      } else {
        newFeed = feed.concat(oldFeeds)
      }

      const payload = {
        feed: newFeed,
        lastFetchedFrom: newLastFetchedFrom,
      }
      if (feedIds) {
        payload.feedIds = feedIds
      }

      yield put(NetworkAction.getUnansweredQuestionsActions.success(payload))
    }
  } catch (e) {
    yield put(NetworkAction.getUnansweredQuestionsActions.failure(e))
  }
}

function* handleOpenFeedDetailsView() {
  try {
    const secureId = checkForUserObject("secureId")
    // if (!secureId) {
    //   root.location.href = `${WEB_URL}login`
    // }
  } catch (e) {
    // handle Error,
  }
}

function* updateFeedLikeStandAlone(action) {
  try {
    const { id, likedByMe } = action.data
    let res
    if (!likedByMe) {
      res = yield call(likeFeedAPI, id)
    } else {
      res = yield call(unLikeFeedAPI, id)
    }
    if (isSuccess(res)) {
      yield put(NetworkAction.updateFeedLikeStandAloneAction.success({ likedByMe: !likedByMe, id }))
    }
  } catch (e) {
    yield put(NetworkAction.updateFeedLikeStandAloneAction.failure(e))
  }
}

function* getSnippetData(action) {
  try {
    const {
      data: { url },
    } = action
    const res = yield call(getSnippetDataAPI, {
      url,
    })
    if (isSuccess(res)) {
      const snippetData = res.data.response
      const snippetURL = snippetData.url
      const snippetTitle = snippetData.title
      const snippetImageURL = snippetData && snippetData.images && snippetData.images[0] || null
      yield put(
        NetworkAction.updateCreateEditPostModalAction({
          snippetURL,
          snippetTitle,
          snippetImageURL,
        })
      )
      yield put(NetworkAction.getSnippetDataAction.success(res.data.response))
    }
  } catch (e) {
    yield put(NetworkAction.getSnippetDataAction.failure(e))
  }
}

function* startOnboarding() {
  try {
    const res = yield call(startOnboardingAPI)
    if (isSuccess(res)) {
      yield put(NetworkAction.startOnboardingAction.success())
    }
  } catch (e) {
    yield put(NetworkAction.startOnboardingAction.failure(e))
  }
}

const getSelectedTags = (allTags) => {
  const selectedTags = {}

  allTags.forEach((tagObject) => {
    selectedTags[tagObject.id] = true
  })

  return selectedTags
}

const getMediaKeys = (imageUrls, videoUrls) => {
  const mediaKeys = {}
  if (imageUrls.length > 0) {
    mediaKeys.mediaType = "image"
    mediaKeys.mediaURL = imageUrls[0]
  }
  return mediaKeys
}

const getEssentialKeyValues = (createEditModalState, text) => {
  const returnObject = {}
  returnObject.text = text
  const keys = Object.keys(createEditPostModalEssentialState)
  keys.forEach((key) => {
    returnObject[key] = createEditModalState[key] || null
  })
  return returnObject
}

function* triggerCreateEditModal(action) {
  try {
    if (!action.data) {
      const createEditPostModalState = yield select(getCreateEditModalState)
      const groupId = yield select(getSelectedGroupFromStore)
      const text = ""

      const initState = getEssentialKeyValues(createEditPostModalState, text)

      yield put(
        NetworkAction.updateCreateEditPostModalAction({
          show: true,
          groupId,
        })
      )

      yield put(NetworkAction.saveInitCreateEditModalAction(initState))
    } else {
      const { feedItem } = action.data

      const {
        imageUrls,
        videoUrls,
        topicId,
        type,
        title,
        group_id: groupId,
        tags: rawTags,
        id: feedId,
        city,
        state,
        country,
        latitude,
        longitude,
      } = feedItem

      const text = convertUnicode(title)

      const selectedTags = getSelectedTags(rawTags)

      const mediaKeys = getMediaKeys(imageUrls, videoUrls)

      const initialPayload = {
        show: true,
        editMode: true,
        text,
        groupId,
        selectedTags,
        type,
        feedId,
        city,
        state,
        country,
        latitude,
        longitude,
        ...mediaKeys,
      }

      //Load edit modal
      yield put(NetworkAction.updateCreateEditPostModalAction(initialPayload))

      //Load text
      yield put(
        FormInputAction.updateFormInputTextAction({
          key: "createEditPostText",
          text,
        })
      )

      //Load topics and tags
      yield put({
        type: NetworkAction.setTopicAndGetTagsAction.REQUEST,
        data: {
          topicId,
        },
      })

      //Save Init State
      const createEditModalState = {
        ...initialPayload,
        topicId,
      }

      const initState = getEssentialKeyValues(createEditModalState, text)
      yield put(NetworkAction.saveInitCreateEditModalAction(initState))
    }
  } catch (e) {
    console.log(e)
  }
}

function* refreshCurrentFeed() {
  try {
    const selectedOptions = yield select(getSelectedOptionsOfFeedFromStore)
    yield put({
      type: NetworkAction.getAllFeedsAction.REQUEST,
      data: {
        selectedOptions,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

function* getSuggestedTopics() {
  try {
    const res = yield call(getSuggestedTopicsAPI)
    if (isSuccess(res)) {
      const { topics } = res.data.response
      yield put(
        NetworkAction.getSuggestedTopicsAction.success({
          topics,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.getSuggestedTopicsAction.failure(e))
  }
}

function* followSuggestedTopic(action) {
  try {
    const { topicId, index } = action.data
    const res = yield call(followTopicAPI, topicId)
    if (isSuccess(res)) {
      const suggestedTopics = yield select(getSuggestedTopicsFromStore)
      const newSuggestedTopics = [...suggestedTopics]
      newSuggestedTopics.splice(index, 1)
      yield put(
        NetworkAction.followSuggestedTopicAction.success({
          suggestedTopics: newSuggestedTopics,
        })
      )
    }
  } catch (e) {
    yield put(NetworkAction.followSuggestedTopicAction.failure(e))
  }
}

function* removeMediaAndLinkify(action) {
  try {
    // const { type } = action.data
    // if (type === "link") {
    //   yield put(
    //     NetworkAction.updateCreateEditPostModalAction({
    //       snippetURL: null,
    //       snippetTitle: null,
    //       snippetImageURL: null,
    //     })
    //   )
    // }
    // else if (type === "image") {
    //   yield put(NetworkAction.updateCreateEditPostModalAction({
    //     mediaType: null,
    //     mediaURL: null,
    //   }))
    // }

    // if (type === "link") {
    //   yield put(
    //     FormInputAction.updateFormInputTextAction({
    //       key: "createEditPostText",
    //       text: "",
    //     })
    //   )
    // }
    //const text = yield select(state => state.formInput.createEditPostText)
    // const match = linkify.match(text)
    // if (match && match.length > 0) {
    //   yield put({
    //     type: NetworkAction.getSnippetDataAction.REQUEST,
    //     data: {
    //       url: match[0].url,
    //     },
    //   })
    // }
  } catch (e) {
    console.log(e)
  }
}

function* introPostTextChange(action) {
  try {
    yield put(
      FormInputAction.updateFormInputTextAction({
        key: "introPostText",
        text: action.data,
      })
    )
  } catch (e) {
    console.warn(e)
  }
}

function* createIntroPost() {
  try {
    const selectedCities = yield select(getSelectedIntroCitiesFromStore)
    const text = yield select(state => state.formInput.introPostText)
    const title = unicodeToEscaped(text)
    const intro_location_ids = Object.keys(selectedCities)
    const payload = {
      title,
      intro_location_ids,
      feed_type: "intro",
    }

    const res = yield call(createIntroPostAPI, payload)

    if (isSuccess(res)) {
      yield put(NetworkAction.createIntroPostAction.success())

      //Close intro post modal
      yield put(NetworkAction.toggleIntroPostModalAction(false))

      //Set group to community and fetch recent feed
      yield put(NetworkAction.setCommunityGroupAction({
        groupId: 1,
      }))

      yield put(NetworkAction.changeFeedSelectionAction({
        feed_ids: false,
        lat: false,
        lng: false,
      }))
    }
  } catch (e) {
    yield put(NetworkAction.createIntroPostAction.failure(e))
  }
}

function* toggleIntroPostModal(action) {
  try {
    if (action.data === false) {
      const res = yield call(skipIntroPostAPI)
    }
  } catch (error) {
    // Ignore Error
    console.warn("Error while skipping intro", error)
  }
}

function* getLatestCourseFromAcademy() {
  try {
    const agentId = yield select(state => state.app.user.id)
    const payload = {
      agent_id: agentId,
    }
    const res = yield call(getLastestCourseAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.getLatestCourseFromAcademyAction.success(response))
    }
  } catch (error) {
    yield put(NetworkAction.getLatestCourseFromAcademyAction.failure(error))
  }
}

function* getStandAloneFeed(action) {
  try {
    const { feedId, isInsertFlow } = action.data
    const res = yield call(getStandAloneFeedAPI, feedId)
    if (isSuccess(res)) {
      const { response } = res.data
      console.log(response)
      root.heap.track(`feed_details_${response.feed_type}_web`, { feed: response })
      if (isInsertFlow) {
        yield put(NetworkAction.insertNewlyCreatedQuestionsInFeedAction.call(response))
        return
      }
      yield put(NetworkAction.getStandAloneFeedAction.success(response))
      yield put(NetworkAction.openFeedDetailViewAction({
        selectedFeed: response,
      }))
      yield put(NetworkAction.getCommentsForFeedAction.request(response.id))
    }
  } catch (error) {
    const errorMsg = error.data.error
    yield put(NetworkAction.getStandAloneFeedAction.failure(errorMsg))
  }
}

function* handleReportOrDelete(action) {
  try {
    yield put(showConfirmModalAction.call({
      title: "Are you sure you want to delete this post?",
      body: "",
      successButtonText: "Delete Post",
    }))
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { isQuestion } = action.data
    const { data: isConfimed } = confirm
    if (!isConfimed) {
      return false
    }
    const { feedId } = action.data
    yield put(hideConfirmModalAction.call())

    const res = yield call(reportOrDeleteFeedAPI, feedId)
    if (isSuccess(res)) {
      const networkFeeds = yield select(state => state.network.networkFeeds)
      const { feed = [] } = networkFeeds
      const newFeeds = feed && feed.filter(item => item.id !== feedId)
      yield put(NetworkAction.reportOrDeleteAction.success({ newFeeds, feedId }))
      if (isQuestion) {
        yield put(NetworkAction.getUnansweredQuestionsActions.request({ reset: true }))
      } else {
        yield put(NetworkAction.getAllFeedsAction.request())
      }
    }
  } catch (error) {
    yield put(NetworkAction.reportOrDeleteAction.failure(error))
  }
}

function* feedsViewed(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(feedsViewedAPI, payload)
    if (isSuccess(res)) {
      yield put(NetworkAction.feedsViewedAction.success(res))
    }
  } catch (error) {
    yield put(NetworkAction.feedsViewedAction.failure(error))
  }
}

function* handleGetNetworkActivity(action) {
  try {
    const {
      limit = 10,
      skip = 0,
      isFetchingMore,
    } = action.data
    const res = yield call(getNetworkActivityAPI, skip, limit)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        // handle fetchMore
        const isDone = response && response.length === 0
        const oldActivities = yield select(state => state.network.networkActivityResponse.data.activities)
        yield put(NetworkAction.getNetworkActivityAction.success({
          activities: [
            ...oldActivities,
            ...response,
          ],
          isDone,
        }))
      } else {
        yield put(NetworkAction.getNetworkActivityAction.success({
          activities: response,
          isDone: false,
        }))
      }
    }
  } catch (error) {
    yield put(NetworkAction.getNetworkActivityAction.failure(error))
  }
}

function* handleHomeClick(action) {
  try {
    const { push } = action.data
    root.scrollTo(0, 0)
    if (push) {
      push("/community")
    }
    yield put(NetworkAction.getAllFeedsAction.request())
  } catch (error) {
    console.log("error", error)
  }
}

function* handleFetchPeopleToFollow(action) {
  try {
    const {
      skip,
      limit,
      isFetchingMore,
    } = action.data
    const payload = {
      exclude: [],
      skip: skip || 0,
      limit: limit || 5,
    }

    const res = yield call(getPeopleToFollowAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        // handle fetchMore
        const isDone = response && response.length === 0
        const oldData = yield select(state => state.network.getPeopleToFollowResponse.data.peopleToFollow.agents)
        if (isDone) {
          yield put(NetworkAction.getPeopleToFollowAction.success({
            peopleToFollow: {
              agents: [
                ...oldData,
              ],
            },
            isDone,
          }))
        } else {
          yield put(NetworkAction.getPeopleToFollowAction.success({
            peopleToFollow: {
              agents: [
                ...oldData,
                ...response.agents,
              ],
            },
            isDone: false,
          }))
        }
      } else {
        yield put(NetworkAction.getPeopleToFollowAction.success({
          peopleToFollow: response,
          isDone: false,
        }))
      }
    }
  } catch (error) {
    yield put(NetworkAction.getPeopleToFollowAction.failure(error))
  }
}

function* handleFetchPeopleYouMayKnow(action) {
  try {
    const {
      skip,
      limit,
      isFetchingMore,
    } = action.data

    const payload = {
      exclude: [],
      skip: skip || 0,
      limit: limit || 4,
    }

    const res = yield call(getPeopleYouMayKnowAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        // handle fetchMore
        const isDone = !response.agents
        const oldData = yield select(state => state.network.getPeopleYouMayKnowResponse.data.peopleYouMayKnow.agents)
        const oldFollowees = yield select(state => state.network.getPeopleYouMayKnowResponse.data.peopleYouMayKnow.agentsFollowees)
        if (isDone) {
          yield put(NetworkAction.getPeopleYouMayKnowAction.success({
            peopleYouMayKnow: {
              agents: [
                ...oldData,
              ],
              agentsFollowees: [
                ...oldFollowees,
                ...response.agentsFollowees,
              ]
            },
            isDone,
          }))
        } else {
          yield put(NetworkAction.getPeopleYouMayKnowAction.success({
            peopleYouMayKnow: {
              agents: [
                ...oldData,
                ...response.agents,
              ],
              agentsFollowees: [
                ...oldFollowees,
                ...response.agentsFollowees,
              ]
            },
            isDone: false,
          }))
        }
      } else {
        yield put(NetworkAction.getPeopleYouMayKnowAction.success({
          peopleYouMayKnow: response,
          isDone: false,
        }))
      }
    }
  } catch (error) {
    yield put(NetworkAction.getPeopleYouMayKnowAction.failure(error))
  }
}

function* handleBlockUnBlock(action) {
  try {
    const { payload, feedId } = action.data
    yield put(showConfirmModalAction.call({
      title: "Are you sure you want to block this user?",
      body: "",
      successButtonText: "Block user",
    }))
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { data: isConfimed } = confirm
    if (!isConfimed) {
      return false
    }
    const res = yield call(blockAndUnblockAPI, payload)
    if (isSuccess(res)) {
      yield put(hideConfirmModalAction.call())
      const { pathname } = root.location
      if (pathname && pathname.includes("questions")) {
        yield put(NetworkAction.getUnansweredQuestionsActions.request())
      } else if (pathname && pathname.includes("profile")) {
        root.location.reload()
      } else {
        yield put(NetworkAction.getAllFeedsAction.request())
      }
    }
  } catch (error) {
    yield put(hideConfirmModalAction.call())
    console.log(error);
  }
}

function* handleGetReportReasons() {
  try {
    const res = yield call(getReportReasonsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.getBlockedReasonsAction.success(response))
    }
  } catch (error) {
    yield put(NetworkAction.getBlockedReasonsAction.failure(error))
  }
}

function* handleReport(action) {
  try {
    const {
      payload,
    } = action.data
    const feedId = yield select(state => state.network.isShowReportModal)
    const res = yield call(reportOrDeleteFeedAPI, feedId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NetworkAction.reportFeedAction.success())
      yield put(NetworkAction.toggleReportModalAction.call(false))

      // Remove Feed List
      const { pathname } = root.location
      if (pathname && pathname.includes("questions")) {
        const networkFeeds = yield select(state => state.network.unansweredQuestions)
        const newFeeds = networkFeeds.feed && networkFeeds.feed.filter(x => x.id !== feedId)
        yield put(NetworkAction.getUnansweredQuestionsActions.success({
          ...networkFeeds,
          feed: newFeeds,
        }))
      } else if (pathname && pathname.includes("profile")) {
        root.location.reload()
      } else {
        const networkFeeds = yield select(state => state.network.networkFeeds)
        const newFeeds = networkFeeds.feed && networkFeeds.feed.filter(x => x.id !== feedId)
        yield put(NetworkAction.getAllFeedsAction.success({
          ...networkFeeds,
          feed: newFeeds,
        }))
      }
      createToast("Thank you for letting us know")
    }
  } catch (error) {
    console.log(error);
    yield (NetworkAction.toggleReportModalAction.call(false))
  }
}

function* handleMutePost(action) {
  try {
    const {
      feedId,
      isPostMuted,
    } = action.data

    const payload = {
      mute: !isPostMuted,
    }
    const res = yield call(mutePostAPI, payload, feedId)
    if (isSuccess(res)) {
      const { response } = res.data
      const muteMsg = "Notifications turned off for this post"
      const unMuteMsg = "Notifications turned on for this post"
      createToast(!isPostMuted ? muteMsg : unMuteMsg)
      const muteFeeds = yield select(state => state.network.mutedFeeds)
      if (!isPostMuted) {
        yield put(NetworkAction.storeMutedFeedIdAction.call({
          mutedFeeds: [...muteFeeds, feedId],
        }))
      } else {
        const removeMutedFeed = muteFeeds.filter(item => item !== feedId) || []
        yield put(NetworkAction.storeMutedFeedIdAction.call({
          mutedFeeds: removeMutedFeed,
        }))
      }
      yield put(NetworkAction.mutePostAction.success(response))
    }
  } catch (error) {
    yield put(NetworkAction.mutePostAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(NetworkAction.getAllFeedsAction.REQUEST, getAllFeeds)
  yield takeLatest(NetworkAction.getCityPreferencesAction.REQUEST, getUserCities)
  yield takeLatest(NetworkAction.setCityPreferencesAction.REQUEST, setUserCities)
  yield takeLatest(NetworkAction.getAllSpecializationsAction.REQUEST, getAllSpecializations)
  yield takeLatest(NetworkAction.getTagsBasedOnTopicsAction.REQUEST, getTagsBasedOnTopics)
  yield takeLatest(NetworkAction.getSpecializationPreferencesAction.REQUEST, getUserSpecializations)
  yield takeLatest(NetworkAction.setSpecializationPreferencesAction.REQUEST, setUserSpecializations)
  yield takeLatest(NetworkAction.getAllTopicsAction.REQUEST, getAllTopics)
  yield takeLatest(NetworkAction.getTopicsPreferencesAction.REQUEST, getUserTopics)
  yield takeLatest(NetworkAction.setTopicsPreferencesAction.REQUEST, setUserTopics)
  yield takeLatest(NetworkAction.getSuggestedAgentsAction.REQUEST, getSuggestedAgents)
  yield takeLatest(NetworkAction.followAgentAction.REQUEST, followAgent)
  yield takeLatest(NetworkAction.unfollowAgentAction.REQUEST, unFollowAgent)
  yield takeLatest(NetworkAction.getOnboardingStatusAction.REQUEST, checkOnboardingStatus)
  yield takeLatest(NetworkAction.getCommentsForFeedAction.REQUEST, getCommentsForFeed)
  yield takeLatest(NetworkAction.postCommentForFeedAction.REQUEST, postCommentForFeed)
  yield takeLatest(NetworkAction.getLikersAction.REQUEST, getLikers)
  yield takeLatest(NetworkAction.updateFeedLikeAction.REQUEST, updateFeedLike)
  yield takeLatest(NetworkAction.updateCommentLikeAction.REQUEST, updateCommentLike)
  yield takeLatest(NetworkAction.getTagsBasedOnTopicsAction.REQUEST, getTopicsBasedOnTopic)
  yield takeEvery(NetworkAction.uploadPostImageAction.REQUEST, uploadPostImage)
  yield takeLatest(NetworkAction.setTopicAndGetTagsAction.REQUEST, setTopicAndGetTags)
  yield takeLatest(NetworkAction.FINISH_ONBOARDING, finishOnboarding)
  yield takeLatest(NetworkAction.createUpdateFeedItemAction.REQUEST, createUpdateFeedItem)
  yield takeLatest(NetworkAction.UPDATE_CREATE_EDIT_POST_MODAL, updateCreateEditPostModal)
  yield takeLatest(NetworkAction.CONFIRM_CLOSE_CREATE_EDIT_MODAL, confirmCloseCreateEditModal)
  yield takeLatest(NetworkAction.CLOSE_RESET_CREATE_EDIT_MODAL, closeResetCreateEditModal)
  yield takeLatest(NetworkAction.CREATE_EDIT_TEXT_CHANGE, updateTextForPostModal)
  yield takeLatest(NetworkAction.CHANGE_FEED_SELECTION, changeFeedSelection)
  yield takeLatest(NetworkAction.getUnansweredQuestionsActions.REQUEST, getUnansweredQuestions)
  yield takeLatest(NetworkAction.OPEN_FEED_DETAIL_VIEW, handleOpenFeedDetailsView)
  yield takeLatest(NetworkAction.updateFeedLikeStandAloneAction.REQUEST, updateFeedLikeStandAlone)
  yield takeLatest(NetworkAction.getSnippetDataAction.REQUEST, getSnippetData)
  yield takeLatest(NetworkAction.startOnboardingAction.REQUEST, startOnboarding)
  yield takeLatest(NetworkAction.TRIGGER_CREATE_EDIT_MODAL, triggerCreateEditModal)
  yield takeLatest(NetworkAction.REFRESH_CURRENT_FEED, refreshCurrentFeed)
  yield takeLatest(NetworkAction.getSuggestedTopicsAction.REQUEST, getSuggestedTopics)
  yield takeLatest(NetworkAction.followSuggestedTopicAction.REQUEST, followSuggestedTopic)
  yield takeLatest(NetworkAction.REMOVE_MEDIA_AND_LINKIFY, removeMediaAndLinkify)
  yield takeLatest(NetworkAction.INTRO_POST_TEXT_CHANGE, introPostTextChange)
  yield takeLatest(NetworkAction.createIntroPostAction.REQUEST, createIntroPost)
  yield takeLatest(NetworkAction.setAllTopicsAction.REQUEST, setAllTopics)
  yield takeLatest(NetworkAction.TOGGLE_INTRO_POST_MODAL, toggleIntroPostModal)
  yield takeLatest(NetworkAction.getLatestCourseFromAcademyAction.REQUEST, getLatestCourseFromAcademy)
  yield takeLatest(NetworkAction.getStandAloneFeedAction.REQUEST, getStandAloneFeed)
  yield takeLatest(NetworkAction.reportOrDeleteAction.REQUEST, handleReportOrDelete)
  yield takeLatest(NetworkAction.feedsViewedAction.REQUEST, feedsViewed)
  yield takeLatest(NetworkAction.getNetworkActivityAction.REQUEST, handleGetNetworkActivity)
  yield takeLatest(NetworkAction.getFeedByHomeClickAction.type, handleHomeClick)
  yield takeLatest(NetworkAction.getPeopleToFollowAction.REQUEST, handleFetchPeopleToFollow)
  yield takeLatest(NetworkAction.getPeopleYouMayKnowAction.REQUEST, handleFetchPeopleYouMayKnow)
  yield takeLatest(NetworkAction.blockAndUnblockAction.REQUEST, handleBlockUnBlock)
  yield takeLatest(NetworkAction.getBlockedReasonsAction.REQUEST, handleGetReportReasons)
  yield takeLatest(NetworkAction.reportFeedAction.REQUEST, handleReport)
  yield takeLatest(NetworkAction.mutePostAction.REQUEST, handleMutePost)
}
