import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import RadiusButton from "@ui/LoaderButton"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import TechAssetsContainer from "container/TechAssets"
import PlusIcon from "images/icons/WhiteIconPlus.png"
// import { gapi } from "gapi-script"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, Message,
} from "@ui/antd"
import { EditOutlined } from "@ant-design/icons"
import dynamic from "next/dynamic"
const loadGapi = dynamic(() => import("gapi-script").then(mod => mod.loadGapiInsideDOM), { ssr: false })
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  // display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const VendorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const VendorBox = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  width: 47%;
  margin: 10px;
`
const VendorHeader = styled.div`
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
  h1 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    color: #303030;
    margin: 0 0 10px;
  }
`
const VendorContent = styled.div`
  margin: 20px 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  span {
    color: #8C8C8C;
  }
`
const VendorButton = styled(RadiusButton)`

`

const AddNewVendor = styled.button`
  height: 36px;
  width: 188px;
  border-radius: 100px;
  background: ${colors.marineBLue};
  color: ${colors.white};
  cursor: pointer;
  border: none;

  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;

  span img {
    height: 15px;
    position: relative;
    left: 11px;
    top: 2px;
  }
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    selectedVendor: null,
  }

  componentDidMount() {
    const initializeGapi = async () => {
      try {
        await loadGapi()
        // Initialize gapi client
        window.gapi.load("client:auth2", this.fetchGoogleContacts)
      } catch (e) {
        //
      }
    }
    initializeGapi()
    const { getListingCoverSheet, dealToView, getListingCoverSheetResponse } = this.props
    getListingCoverSheet({
      listing_id: dealToView || 0,
      callAPI: ["select_vendor"],
    })
  }

  setSelectedVendor = (value) => {
    this.setState({
      selectedVendor: value,
    })
  }

  getNextRoute = (isDraft) => {
    const { isFromDirectForm, mainPageRoute, nextFormRoute } = this.props
    if (!isFromDirectForm) {
      return isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/upload"
    }
    return isDraft ? mainPageRoute : nextFormRoute
  }

  onFinish = () => {
    const { selectedVendor } = this.state
    const { updateListingCoverSheet, history, dealToView, updateDealStatus, getListingCoverSheetResponse } = this.props
    const previousSelected = getListingCoverSheetResponse.data?.selectVendor?.find(dt => dt.is_selected)
    if (!(selectedVendor || previousSelected?.id)) {
      Message.error("Please select vendor before proceeding")
    }
    updateListingCoverSheet({
      payload: {
        vendor_id: selectedVendor || previousSelected.id,
        current_listing_step: "select_vendor",
        draft: "N",
      },
      listing_id: dealToView,
      callAPI: [],
      nextRoute: this.getNextRoute(),
      history,
      callback: () => {
        updateDealStatus({
          dealId: dealToView,
          step: "submitted",
        })
      },
    })
  }

  setEscrowIdToEdit = (id) => {
    const { setEscrowIdToEdit, history } = this.props
    setEscrowIdToEdit(id)
    history.push("/realty/transaction-coordination/contract/escrow-info")
  }

  getBackRoute = () => {
    const { isFromDirectForm, backFormRoute, history } = this.props
    if (!isFromDirectForm) {
      history.push("/realty/transaction-coordination/contract/tc-info")
    }
    history.push(backFormRoute)
  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  render() {
    const { getListingCoverSheetResponse, updateListingCoverSheetResponse, history, getContractByIdResponse } = this.props
    const { selectedVendor } = this.state
    const enableTC = ["California", "Oregon", "Washington"]
    const state = getContractByIdResponse.data?.[0]?.state
    return (
      <Wrap>
        <HeaderNav>
          <h2>
            Cover Sheet: Select Saved Vendors
          </h2>
          {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) && (
            <AddNewVendor onClick={() => this.setEscrowIdToEdit(null)}>
              Add New Vendor
              <span>
                <img src={PlusIcon} alt="test" />
              </span>
            </AddNewVendor>
          )}
        </HeaderNav>
        <ProgressBarContainer>
        </ProgressBarContainer>
        <Container>
          <RightSideBar>
            <VendorContainer>
              {getListingCoverSheetResponse.data?.selectVendor?.map(dt => (
                <VendorBox>
                  <VendorHeader>
                    <h1>
                      {dt.vendor_type}
                    </h1>
                    {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) && (
                      <EditOutlined onClick={() => this.setEscrowIdToEdit(dt.id)} />
                    )}
                  </VendorHeader>
                  <VendorContent>
                    <p>
                      {dt.vendor_name}
                    </p>
                    <p>
                      {dt.vendor_number}
                    </p>
                    <p>
                      {`${dt.vendor_officer_first_name} ${dt.vendor_officer_last_name}`}
                    </p>
                    <p>
                      {`${dt.vendor_address}, ${dt.vendor_city}, ${dt.vendor_state} ${dt.vendor_zipcode}`}
                    </p>
                    <p>
                      {dt.vendor_email}
                    </p>
                    <p>
                      {dt.vendor_phone}
                    </p>
                    {dt.notes && (
                      <span>
                        Notes:
                        {dt.notes}
                      </span>
                    )}
                  </VendorContent>
                  <VendorButton
                    bsStyle={(selectedVendor ? selectedVendor === dt.id : dt.is_selected) ? "marineBLueFill" : "marineBLueOutlined"}
                    padding="12px 8px"
                    width="100%"
                    fontSize="14px"
                    fontWeight="500"
                    disabled={!(!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])))}
                    // isShowLoader={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                    // loaderOption={{ color: colors.marineBLue }}
                    onClick={() => { this.setSelectedVendor(dt.id) }}
                  >
                    {(selectedVendor ? selectedVendor === dt.id : dt.is_selected) ? "Selected" : "Select"}
                  </VendorButton>
                </VendorBox>
              ))}
            </VendorContainer>
          </RightSideBar>
          <FlexBox mt="30px">
            <FlexBox wd="100%">
            </FlexBox>
            <FlexBox>
              <Form.Item>
                <Button
                  style={{
                    width: 119,
                    height: 48,
                    background: "white",
                    float: "right",
                    borderColor: "#3B60E4",
                    color: "#3B60E4",
                  }}
                  type="primary"
                  shape="round"
                  size="large"
                  // htmlType="submit"
                  onClick={() => {
                    this.getBackRoute()
                  }}
                >
                  Back
                </Button>
              </Form.Item>
              {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                <>
                  <Form.Item>
                  {enableTC.includes(state) ? (
                      <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={() => { history.push("/realty/transaction-coordination/contract/upload?fromTC=true") }}
                    >
                      Skip
                    </Button>
                    ) : null}
                </Form.Item>
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                      onClick={() => { this.onFinish() }}
                    // onClick={() => { history.push("/realty/listings/coversheet/seller-info") }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item>
                  {enableTC.includes(state) ? (
                      <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={() => { history.push("/realty/transaction-coordination/contract/upload?fromTC=true") }}
                    >
                      Skip
                    </Button>
                    ) : null}
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 140,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={() => { history.push("/realty/transaction-coordination/contract/upload") }}
                      >
                        Next
                    </Button>
                  </Form.Item>
                </>
              )}
            </FlexBox>
          </FlexBox>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)
