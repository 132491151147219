import axios from "axios"

export const getAllRarMentorsListApi = () => axios.get(`${API_URL}rar/mentors`)
// export const getAllRarMentorsListApi = () => axios.get("http://localhost:8000/v1.0/rar/mentors")
export const getRarMentorsFromApi = () => axios.get(`${API_URL}rar/mentor-registration`)
// export const getRarMentorsFromApi = () => axios.get("http://localhost:8000/v1.0/rar/mentor-registration")
export const saveRarMentorsFormApi = payload => axios.post(`${API_URL}rar/mentor-registration-submit`, payload)
// eslint-disable-next-line max-len
// export const saveRarMentorsFormApi = payload => axios.post("http://localhost:8000/v1.0/rar/mentor-registration-submit", payload)

