import React from "react"
import Modal from "@ui/Modal"

const RoomsModal = ({ toClose, children }) => {
  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      modalStyles={{
        padding: "20px",
        background: "linear-gradient(0deg, #F9F9F7, #F9F9F7), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
      }}
      overlayStyles={{
        zIndex: "10",
      }}
    >
      {children && children}
    </Modal>
  )
}

export default RoomsModal
