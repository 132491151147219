import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.div`
    position: relative;
    text-align: left;
    h6 {
        margin: 0px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.64);
    }
    p {
        color: rgba(255, 255, 255, 0.64);
        margin-top: 10px;
        font-weight: 300;
    }
`

const TooltipBody = ({
    header,
    text,
}) => (
    <Wrap>
        <h6>{header}</h6>
        <p>{text}</p>
    </Wrap>
)

export default TooltipBody
