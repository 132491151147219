import { takeLatest, call, put, select } from "redux-saga/effects"
import { orderBy, uniqueId } from "lodash"
import { getRandomSeedValue, isSuccess } from "services/Utils"
import * as SearchActions from "./actions"
import * as SeachAPIs from "./api"
import * as SearchSelectors from "./selector"
import { keyedSingleFiltersMap } from "dumbComponents/Search/FilterPanel"

const AGENT_SEARCH_PAGE_SIZE = 10

const getAppliedFilterObject = (keyedFacades, appliedFilters) => {
  const keyPayload = {}
  const valuePayload = {}
  Object.keys(keyedFacades).forEach((filterCategory) => {
    const payloadKeyArray = []
    const payloadValueArray = []
    appliedFilters[filterCategory].forEach((filterKey) => {
      payloadKeyArray.push(filterKey)
      payloadValueArray.push(keyedFacades[filterCategory][filterKey])
    })
    if (payloadKeyArray.length > 0) {
      keyPayload[filterCategory] = payloadKeyArray
      valuePayload[filterCategory] = payloadValueArray
    }
  })

  return {
    keyPayload,
    valuePayload,
  }
}

const processFacadesForFilterInit = (facades, keyedSingleFiltersMap) => {
  const keyedFacades = {}
  const facadeKeys = Object.keys(facades)
  const appliedFilters = {}
  const unappliedFilters = {}
  const keyedSingleFilters = {}

  facadeKeys.forEach((facadeKey) => {
    const keyedFilterObject = {}
    const unappliedKeys = []
    facades[facadeKey].forEach((filterObject, index) => {
      keyedFilterObject[index] = filterObject.key
      unappliedKeys.push(index)
    })
    keyedFacades[facadeKey] = keyedFilterObject
    appliedFilters[facadeKey] = []
    unappliedFilters[facadeKey] = unappliedKeys
  })

  Object.keys(keyedSingleFiltersMap).forEach((filterKey) => {
    keyedSingleFilters[filterKey] = false
  })

  return {
    keyedFacades,
    appliedFilters,
    unappliedFilters,
    keyedSingleFilters,
  }
}

const getTransformedFacades = (rawFacades) => {
  if (rawFacades && Object.keys(rawFacades).length > 0) {
    //Changing the key specializations to specialties
    const { specializations, designations, ...others } = rawFacades
    return {
      specialties: specializations,
      designation: designations,
      ...others,
    }
  }
  return null
}

const getAppliedSingleFilterPayload = (singleFilters) => {
  const appliedSingleFilterPayload = []
  Object.keys(singleFilters).forEach((filterKey) => {
    if (singleFilters[filterKey]) {
      appliedSingleFilterPayload.push(filterKey)
    }
  })
  return appliedSingleFilterPayload
}

function* getSearchedAgents(action) {
  try {
    const { isInitial, currentPageNumber, newEntry } = action.data

    const fromResult = (currentPageNumber - 1) * AGENT_SEARCH_PAGE_SIZE

    let randomSeed
    let queryParams
    let appliedFiltersKeyPayload = {}
    let appliedFiltersValuePayload = {}
    let singleFilterPayload = {}
    let appliedSingleFilterPayload = {}

    if (isInitial) {
      randomSeed = getRandomSeedValue()
      queryParams = action.data.queryParams
    } else {
      randomSeed = yield select(SearchSelectors.getRandomSeedFromStore)
      queryParams = yield select(SearchSelectors.getQueryParamsFromStore)

      const currentKeyedFacades = yield select(SearchSelectors.getKeyedFacadesFromStore)
      const currentAppliedFilters = yield select(SearchSelectors.getAppliedFiltersFromStore)
      const appliedFiltersPayload = getAppliedFilterObject(currentKeyedFacades, currentAppliedFilters)
      appliedFiltersKeyPayload = appliedFiltersPayload.keyPayload
      appliedFiltersValuePayload = appliedFiltersPayload.valuePayload

      singleFilterPayload = yield select(SearchSelectors.getKeyedSingleFiltersFromStore)
      appliedSingleFilterPayload = getAppliedSingleFilterPayload(singleFilterPayload)
    }

    const payload = {
      facade: isInitial,
      from: fromResult,
      size: AGENT_SEARCH_PAGE_SIZE,
      sortByEntity: "random",
      sortBySecondary: "unregistered_agent",
      sortIsAsc: true,
      randomSeed,
      ...queryParams,
      ...appliedFiltersValuePayload,
      ...singleFilterPayload,
    }

    //Reflecting change in UI before the API result
    yield put(SearchActions.updateAppliedFiltersPayload.action({
      appliedFiltersKeyPayload,
      appliedSingleFilterPayload,
    }))

    const res = yield call(SeachAPIs.agentSearchAPI, payload)

    if (isSuccess(res)) {
      const { agents = [], agentsTotalCount, facades: rawFacades } = res.data.response
      agents.push(newEntry)
      const facades = getTransformedFacades(rawFacades)
      let updatePayload
      const sortedAgents = orderBy(agents, ["agent_image", "userSpecializations", "citiesServed"], "desc")
      const totalPages = Math.ceil(agentsTotalCount / AGENT_SEARCH_PAGE_SIZE)

      if (isInitial) {
        updatePayload = {
          agents: sortedAgents,
          agentsTotalCount,
          facades,
          totalPages,
          currentPageNumber,
          randomSeed,
          queryParams,
        }
      } else {
        updatePayload = {
          agents: sortedAgents,
          agentsTotalCount,
          totalPages,
          currentPageNumber,
        }
      }
      yield put(SearchActions.getSearchedAgents.success(updatePayload))
      if (isInitial) {
        const { keyedFacades, appliedFilters, unappliedFilters, keyedSingleFilters } = processFacadesForFilterInit(facades, keyedSingleFiltersMap)
        yield put(SearchActions.initFilterData.action({
          keyedFacades,
          appliedFilters,
          unappliedFilters,
          keyedSingleFilters,
        }))
      }
      yield put(SearchActions.toggleFilterPanel.action(false))
      // window.scrollTo(0, 0)
    }
  } catch (e) {
    yield put(SearchActions.getSearchedAgents.failure(e))
  }
}

function* clearAllSearchFilters() {
  try {
    const facades = yield select(SearchSelectors.getFacadesFromStore)
    const { keyedFacades, appliedFilters, unappliedFilters, keyedSingleFilters } = processFacadesForFilterInit(facades, keyedSingleFiltersMap)
    yield put(SearchActions.initFilterData.action({
      keyedFacades,
      appliedFilters,
      unappliedFilters,
      keyedSingleFilters,
    }))

    yield put({
      type: SearchActions.getSearchedAgents.REQUEST,
      data: {
        isInitial: false,
        currentPageNumber: 1,
      },
    })
    yield put(SearchActions.toggleFilterPanel.action(false))
  } catch (e) {
    console.log(e)
  }
}

const moveItemFromOneArrayToAnother = (item, oldSourceArray, oldTargetArray) => {
  const index = oldSourceArray.indexOf(item)
  const sourceArray = oldSourceArray.slice(0)
  sourceArray.splice(index, 1)
  const targetArray = oldTargetArray.slice(0)
  targetArray.push(item)
  return {
    sourceArray,
    targetArray,
  }
}

function* selectSearchFilter(action) {
  try {
    const { filterCategory, itemToSelect } = action.data
    const appliedFilters = yield select(SearchSelectors.getAppliedFiltersFromStore)
    const unappliedFilters = yield select(SearchSelectors.getUnappliedFiltersFromStore)

    const {
      sourceArray: newUnappliedFilterArray,
      targetArray: newAppliedFilterArray,
    } = moveItemFromOneArrayToAnother(itemToSelect, unappliedFilters[filterCategory], appliedFilters[filterCategory])

    yield put(SearchActions.updateFiltersOnAction.action({
      filterCategory,
      newAppliedFilterArray,
      newUnappliedFilterArray,
    }))
  } catch (e) {
    console.log(e)
  }
}

function* removeSearchFilter(action) {
  try {
    const { filterCategory, itemToRemove } = action.data
    const appliedFilters = yield select(SearchSelectors.getAppliedFiltersFromStore)
    const unappliedFilters = yield select(SearchSelectors.getUnappliedFiltersFromStore)

    const newAppliedFilterArray = appliedFilters[filterCategory].slice(0)
    const index = newAppliedFilterArray.indexOf(itemToRemove)
    newAppliedFilterArray.splice(index, 1)

    const newUnappliedFilterArray = unappliedFilters[filterCategory].slice(0)

    let i
    for (i = 0; i < newUnappliedFilterArray.length; i += 1) {
      if (itemToRemove < newUnappliedFilterArray[i]) {
        break
      }
    }
    newUnappliedFilterArray.splice(i, 0, itemToRemove)

    yield put(SearchActions.updateFiltersOnAction.action({
      filterCategory,
      newAppliedFilterArray,
      newUnappliedFilterArray,
    }))
  } catch (e) {
    console.log(e)
  }
}

function* removeAndApplySearchFilter(action) {
  try {
    const { filterCategory, itemToRemove } = action.data
    yield put(SearchActions.removeSearchFilter.action({
      itemToRemove,
      filterCategory,
    }))

    yield put({
      type: SearchActions.getSearchedAgents.REQUEST,
      data: {
        isInitial: false,
        currentPageNumber: 1,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

function* removeAndApplySingleFilter(action) {
  try {
    const { filterKey } = action.data
    yield put(SearchActions.updateSingleFilterPayload.action({
      filterKey,
    }))

    yield put({
      type: SearchActions.getSearchedAgents.REQUEST,
      data: {
        isInitial: false,
        currentPageNumber: 1,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

function* changeFilterSelectedCategory(action) {
  try {
    const oldFilterSelectedCategory = yield select(SearchSelectors.getFilterSelectedCategory)
    const filterSelectedCategory = action.data

    if (filterSelectedCategory !== oldFilterSelectedCategory) {
      yield put(SearchActions.changeAndResetFilterCategory.action({
        filterSelectedCategory,
      }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* getClientsByName(action) {
  try {
    const {
      textToSearch,
      currentEditingAgentId,
    } = action.data

    const payload = {
      text: textToSearch,
      sort_by: "created_at",
      ascending: false,
      aggregationNotRequired: false,
      start: 0,
      end: 50,
      agentId: currentEditingAgentId,
    }

    const res = yield call(SeachAPIs.clientsByNameAPI, payload)

    if (isSuccess(res)) {
      const { clients } = res.data.response
      const clientsWithNewEntry = clients

      clientsWithNewEntry.push({
        name: textToSearch,
        firstName: "",
        lastName: "",
      })

      const successPayload = {
        clients: clientsWithNewEntry,
      }

      yield put(SearchActions.getClientsByName.success(successPayload))
    }
  } catch (e) {
    yield put(SearchActions.getClientsByName.failure(e))
  }
}

export default function* main() {
  yield takeLatest(SearchActions.getSearchedAgents.REQUEST, getSearchedAgents)
  yield takeLatest(SearchActions.clearAllSearchFilters.type, clearAllSearchFilters)
  yield takeLatest(SearchActions.selectSearchFilter.type, selectSearchFilter)
  yield takeLatest(SearchActions.removeSearchFilter.type, removeSearchFilter)
  yield takeLatest(SearchActions.changeFilterSelectedCategory.type, changeFilterSelectedCategory)
  yield takeLatest(SearchActions.removeAndApplySearchFilter.type, removeAndApplySearchFilter)
  yield takeLatest(SearchActions.removeAndApplySingleFilter.type, removeAndApplySingleFilter)
  yield takeLatest(SearchActions.getClientsByName.REQUEST, getClientsByName)
}
