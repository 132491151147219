import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as Actions from "./action"

const mapStateToProps = (state) => {
  const { goToApp } = state

  return {
    ...goToApp,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAppSettings: Actions.getAppSettingsAction.request,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
