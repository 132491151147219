import React from "react"
import styled from "styled-components"
import { xsMobile as mobile, tablet } from "@consts"
import root from "window-or-global"
import { ATC } from "@fonts"
import RightArrow from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/right-white-arrow.svg"
import EditedHero from "images/Graphics/MortgageLandingPageAssets/Edited_Hero.jpg"

const Wrap = styled.div`
  min-height: ${props => props.minHeight || "500px"};
  background: no-repeat center;
  background-image: url(${EditedHero});
  background-size: cover;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenteredTextWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h1`
  font-family: 'Butler';
  font-style: normal;
  font-weight: 700;
  font-size: ${props => props.fontSize || "42px"};
  line-height: ${props => props.lineHeight || "60px"};
  /* identical to box height, or 94% */

  text-align: center;
  letter-spacing: 0.005em;

  /* Typography/General/Light */

  color: #F9F9F7;

   ${tablet(`
      font-size: 50px;
      text-align: center;
      line-height: 55px;
    `)}
   ${mobile(`
     font-size: 50px;
     text-align: left;
  `)}
`

const Description = styled.p`
  font-family: ${props => props.fontFamily || "Butler"};
  font-style: normal;
  font-weight: ${props => props.fontWeight || "700"};
  font-size: ${props => props.fontSize || "22px"};
  line-height: ${props => props.lineHeight || "49px"};
  margin: 0;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: 0.005em;

  /* Typography/General/Light */

  color: #F9F9F7;

  ${tablet(`
  font-size: 32px;
  text-align: center;
  line-height: 40px;
  `)}
${mobile(`
 font-size: 30px;
 text-align: left;
`)}
`

const Container = styled.div`
  margin: 0px auto;
  max-width: 1200px;
`

const LearnMoreWrap = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
  p {
    color: #F9F9F7;
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 50px;
    margin-bottom: 2px;
  }
`

const Hero = ({
  CRMStyles,
  WrapStyle,
  DescriptionStyle,
  TitleStyle,
}) => (
  <Wrap
    style={{
      ...WrapStyle,
    }}
  >
    <Container>
      <CenteredTextWrap>
        <Title
          style={{
            ...TitleStyle,
          }}
        >
          Partner with Loandesk Mortgage
        </Title>
        <Description
          style={{
            ...DescriptionStyle,
          }}
        >
          Low Rates. Fast Closes. Elevated Service.
        </Description>
        {/* element for finance tab in CRM */}
        {CRMStyles && (
          <LearnMoreWrap
            onClick={() => {
              root.open("https://loandeskmortgage.com", "_blank")
            }}
          >
            <p>
              Learn More
            </p>
            <img src={RightArrow} alt="RIGHT ARROW" />
          </LearnMoreWrap>
        )}
      </CenteredTextWrap>
    </Container>
  </Wrap>
)

export default Hero
