import React from "react"
import {
  BlueButton,
} from "./styles"

const InputButton = ({
  buttonName,
  ...props
}) => (
  <BlueButton
    {...props}
  >
    <p>{buttonName || "Button Name"}</p>
  </BlueButton>
)

export default InputButton
