import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as AppSelectors from "container/App/selector"
import * as ReferralSelectors from "container/Referral/selector"
import * as ReferralsActions from "container/Referral/actions"
import {
  unsubscribeFromEmailAction,
  verifyAuthTokenForStripeAction,
  deleteAccountAction,
  sendAddCreditCardLinkV2Action,
  getClientSecretAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { settings, referral } = state
  const {
    isUnsubscribeFromEmail,
    verifyAuthTokenResponse,
    saveStripeTokenResponse,
    getClientSecretResponse,
  } = settings
  const {
    assistFeatures,
  } = referral
  return {
    assistFeatures,
    isUnsubscribeFromEmail,
    isUserATeamOwner: AppSelectors.getIsUserATeamOwner(state),
    isUserATeamMember: AppSelectors.getIsUserATeamMember(state),
    isUserATeamAdmin: AppSelectors.getIsUserATeamAdmin(state),
    isFetchingSubscription: ReferralSelectors.getIsFetchingSubscriptions(state),
    isSubscribedToAnyISAPlan: AppSelectors.getIsSubscribedToAnyISAPlan(state),
    categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
    finishedFetchingSubscriptions: ReferralSelectors.getFinishedFetchingSubscriptions(state),
    verifyAuthTokenResponse,
    saveStripeTokenResponse,
    getClientSecretResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchSubscriptions: ReferralsActions.fetchSubscriptionsAction.request,
      unsubscribeFromEmail: unsubscribeFromEmailAction.request,
      verifyAuthToken: verifyAuthTokenForStripeAction.request,
      deleteAccount: deleteAccountAction.request,
      sendAddCreditCardLinkV2: sendAddCreditCardLinkV2Action.request,
      getClientSecret: getClientSecretAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
