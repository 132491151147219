import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import AutoComplete from "@ui/GoogleAutoCompleteV2"
import { ATC } from "@fonts"
import root from "window-or-global"
import { MIN_DESKTOP_BREAKPOINT_WIDTH_LIMIT, maxDesktopWidth } from "@consts"
import AddIconColor from "dumbComponents/RealtyV3/RARRecruitment/images/AddIconColor201.svg"
import SubractIcon from "dumbComponents/FinancingDashboard/images/subtract.svg"
import EditJobIcon from "dumbComponents/RealtyV3/RARRecruitment/images/editjobs.svg"
import BrokerJobs from "container/BrokerJobs"
import { withRouter } from "react-router-dom"
import Pin from "images/Graphics/RadiusJobsAssets/MapPin.svg"
import LocationIcon from "dumbComponents/RealtyV3/RARRecruitment/images/location2.svg"
import {
  HeaderContainer,
  Header, Label,
  ButtonGroup,
  ButtonOutlined,
  ButtonFilled,
  Icon,
  Image,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"
import Select from "react-select"
import { handleJobDetails } from "../../pages/CommonFunctions"
const Wrap = styled.div``
const JobTitleContainer = styled.div`
  padding: 22px 22px 36px;
  ${props => props.padding && `
    padding: ${props.padding}
  `}
  border-bottom: 1px solid ${colors.fungiWhite};
  .form-group {
    border: 1px solid ${colors.eggYolkWhite};
    border-radius: 4px;
  }

  ${props => props.noPadding && `
    padding-left: 0px;
  `}
`
const SelectWrap = styled.div`
  margin-top: 20px;
  .sell_option {
    font-size: 16px;
    margin-top: 10px;
    > div {
      border: 1px solid ${colors.eggYolkWhite};
      border-radius: 4px;
    }
  }
`
const CityContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CityContainerInner = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CityWrap = styled.div`
  margin-right: 1px;
  display: flex;
  width: 49%;
  > div {
    width: 100%;
  }
  img {
    position: relative;
    left: -35px;
    top: 20px;
    cursor: pointer;
  }
`

const AddContainer = styled.div`
  display: flex;
  cursor: pointer;
  h2 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: ${colors.marineBLue};
    margin: 0; 
  }
`

const Location = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.black};
  background: ${colors.dreamWhite};
  padding: 7px;
  margin: 0 7px 5px 0;
  display: flex;
  align-items: center;
  ${props => props.isLandingPage && `
    font-size: 14px;
  `}
  ${maxDesktopWidth(`
    font-size: 12px;
  `)}
`

const LocationCell = styled.div`
  display: flex;
  width: 75%;
  flex-wrap: wrap;
  white-space: nowrap;
`

const LocationIconContainer = styled.img`
  height: 12px;
  margin-right: 5px;
`

const TagDiv = styled.div`
  position: relative;
  background: transparent;
  cursor: pointer;

  &:hover .extra {
    display: flex;
  }
`

const LocationDiv = styled.div`
  position: absolute;
  top: 0;
  left: 82px;
  display: none;
  height: 400px;
  width: max-content;
  background: #F9F9F7;
  padding: 20px;
  z-index: 100;
  overflow: scroll;
  flex-direction: column;
  gap: 17px;
  flex-wrap: nowrap;
`
const LocationTag = styled.div`
  height: 22px;
  width: max-content;
  border-radius: 0px;
  display: flex;
  background-color: ${props => props.backgroundColor || "#F9F9F7"};
  gap: 7px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;

  p {
    font-family: ATC;
    font-size: 12px;
    font-weight: 400;
    line-height: 9px;
    letter-spacing: 1px;
    text-align: left;
  }

  img {
    position: relative;
    top: -4px;
    color: #303030;
    filter: brightness(0.2);
  }
`

const JobTitleSection = ({
  jobTitleEdit,
  editable,
  jobTitle,
  jobTitleCopy,
  updateField,
  isLandingPage,
  enableEdit,
  locationDetailsCopy,
  handleCity,
  addCityForm,
  locationDetails,
  saveJobAndLocation,
  removeCityForm,
  getLocation,
  padding,
  toggleJobsModal,
  toggleModalPages,
  isIndividualJobPage,
  getSpecificCreatedPublicJobs,
  setCurrentViewedJob,
  match,
  brokerageSpecificJobsDetails,
  noPadding,
}) => {
  useEffect(() => {
    toggleModalPages({
      currentPage: "form_1",
    })
  }, [])

  const isTabletOrMobile = root.innerWidth <= MIN_DESKTOP_BREAKPOINT_WIDTH_LIMIT

  return (
    <Wrap>
      <JobTitleContainer padding={padding} noPadding={noPadding}>
        <HeaderContainer>
          <Header source="jobTitle" fw="700" fs={isLandingPage ? "42px" : "30px"} mg={isLandingPage ? "18px 0" : "10px 0"}>

            {jobTitleEdit && editable ? (
              <SelectWrap>
                <Label>Job Title</Label>
                <Select
                  className="sell_option"
                  value={{ label: jobTitleCopy, value: jobTitleCopy }}
                  options={[{ label: "Real Estate Agent", value: "Real Estate Agent" }, { label: "Broker", value: "Broker" }, { label: "Team Lead", value: "Team Lead" }]}
                  onChange={(item) => { updateField({ content: item.value }, "jobTitle") }}
                />
              </SelectWrap>
            ) : (<>{jobTitle}</>)}
          </Header>
          {!jobTitleEdit && editable && (<Icon src={EditJobIcon} onClick={() => enableEdit("jobTitle", true)} />)}
          {isLandingPage && (!isTabletOrMobile || isIndividualJobPage) && (
            <Button
              bsStyle="marineBLueFill"
              fontSize={isLandingPage ? "14px" : "16px"}
              fontWeight="500"
              fontColor="#303030"
              padding="8px 14px"
              borderRadius="0px"
              width="117px"
              onClick={() => {
                const { jobSlug } = match.params
                handleJobDetails(
                  getSpecificCreatedPublicJobs,
                  setCurrentViewedJob,
                  brokerageSpecificJobsDetails,
                  jobSlug,
                )
                toggleJobsModal({ enabled: true, isLandingPage: true })
                if (root.heap) {
                  root.heap.track("Clicked_Jobs_List_Landing_Page_Apply")
                }
              }}
            >
              Apply
            </Button>
          )}
        </HeaderContainer>
        {jobTitleEdit && editable ? (
          <CityContainer>
            <Label>Locations</Label>
            <CityContainerInner>
              <CityWrap>
                <AutoComplete
                  id="cities"
                  types={["(cities)"]}
                  location={data => handleCity(data, 0)}
                  value={getLocation(0)}
                  placeholder="Add City"
                  showLoader={false}
                  handleChangeInput={() => { }}
                  styles={{
                    height: "49px",
                    borderRadius: "0px",
                    background: "white",
                    fontSize: "16px",
                    marginTop: "10px",
                  }}
                />
                <span onClick={() => removeCityForm(0)}>
                  <img src={SubractIcon} alt="remove.svg" />
                </span>
              </CityWrap>
              {locationDetailsCopy.map((ld, index) => (
                <>
                  {index >= 1 && (
                    <CityWrap>
                      <AutoComplete
                        id="cities"
                        types={["(cities)"]}
                        location={data => handleCity(data, index)}
                        value={ld.location}
                        placeholder="Add City"
                        showLoader={false}
                        handleChangeInput={() => { }}
                        styles={{
                          height: "49px",
                          borderRadius: "0px",
                          background: "white",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      />
                      <span onClick={() => removeCityForm(index)}>
                        <img src={SubractIcon} alt="remove.svg" />
                      </span>
                    </CityWrap>
                  )}
                </>
              ))}
            </CityContainerInner>
            {locationDetailsCopy.length < 5 && (
              <AddContainer onClick={addCityForm}>
                <h2>Add Another City</h2>
                <Image
                  src={AddIconColor}
                />
              </AddContainer>
            )}
          </CityContainer>
        ) : (
          <>
            <LocationCell>
              {locationDetails && locationDetails.map((ld, index) => {
                if (index < 5) {
                  return (
                    <Location isLandingPage={isLandingPage}>
                      <LocationIconContainer src={LocationIcon} alt="location.svg" />
                      <span>{ld.location}</span>
                    </Location>
                  )
                }
                return false
              })}
            </LocationCell>
            <LocationCell>
              {locationDetails && locationDetails.length > 5 && (
                <TagDiv>
                  <LocationDiv className="extra">
                    {locationDetails.map((item, index) => {
                      if (index > 5) {
                        return (
                          <LocationTag
                            backgroundColor="#fff"
                          >
                            <span>
                              <img src={Pin} alt="pin.svg" />
                            </span>
                            <p>{`${item.location}`}</p>
                          </LocationTag>
                        )
                      }
                      return false
                    })}
                  </LocationDiv>
                  <p>
                    {`
                      ... and ${locationDetails && locationDetails.length - 5}+ locations
                    `}
                  </p>
                </TagDiv>
              )}
            </LocationCell>
          </>
        )}
        {jobTitleEdit && editable && (
          <ButtonGroup>
            <ButtonOutlined text="Cancel" action={() => enableEdit("jobTitle", false)} />
            <ButtonFilled text="Save changes" action={saveJobAndLocation} />
          </ButtonGroup>
        )}
      </JobTitleContainer>
    </Wrap>
  )
}

export default withRouter(BrokerJobs(JobTitleSection))
