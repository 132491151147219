import { createSignalAction, createActionCreator, createSimpleCreator } from "shared/reduxUtils"

const BASE = "CLIENT"
export const OPEN_EDIT_PROFILE = "OPEN_EDIT_PROFILE"
export const CLOSE_EDIT_PROFILE = "CLOSE_EDIT_PROFILE"
export const UPDATE_CURRENT_SALES_STAGE = "UPDATE_CURRENT_SALES_STAGE"
export const UPDATE_SALES_CHECKLIST = "UPDATE_SALES_CHECKLIST"
export const ARCHIVE_REFERRAL = "ARCHIVE_REFERRAL"
export const UPDATE_CHECKLIST_CLIENT = "UPDATE_CHECKLIST_CLIENT"
export const WAIT_FOR_CLIENT_UPDATE = "WAIT_FOR_CLIENT_UPDATE"
export const IS_CHECKLIST_UPDATE = "IS_CHECKLIST_UPDATE"
export const OPEN_ISA_ADD_CLIENT_MODAL = "OPEN_ISA_ADD_CLIENT_MODAL"
export const CLOSE_ISA_ADD_CLIENT_MODAL = "CLOSE_ISA_ADD_CLIENT_MODAL"
export const UPDATE_SALES_STAGE = "UPDATE_SALES_STAGE"

// Network Actions
export const fetchClientProfileAction = createSignalAction(BASE, "FETCH_CLIENT_PROFILE")
export const uploadClientImageAction = createSignalAction(BASE, "UPLOAD_CLIENT_IMAGE")
export const editClientInfoAction = createSignalAction(BASE, "EDIT_CLIENT_INFO")
export const fetchClientUpdatesAction = createSignalAction(BASE, "FETCH_CLIENT_UPDATES")
export const fetchClientPropertyListingAction = createSignalAction(BASE, "FETCH_CLIENT_PROPERTY_LISTING")
export const fetchAndUpdateClientProfileAction = createSignalAction(BASE, "FETCH_AND_UPDATE_CLIENT_PROFILE")
export const setReminderAction = createSignalAction(BASE, "SET_REMINDER")
export const fetchAndUpdateSalesInClientAction = createSignalAction(BASE, "FETCH_AND_UPDATE_SALES_IN_CLIENT")
export const updateClientProfileAction = createSignalAction(BASE, "UPDATE_CLIENT_PROFILE")
export const setNotificationPreferenceAction = createSignalAction(BASE, "SET_NOTIFICATION_PREFERENCE")
export const fetchClientTimelineAction = createSignalAction(BASE, "FETCH_CLIENT_TIMELIME")
export const updateArchivedLeadAction = createSignalAction(BASE, "UPDATE_ARCHIVED_LEAD")
export const addISAClientAction = createSignalAction(BASE, "ADD_ISA_CLIENT")
export const convertToCRMClientAction = createSignalAction(BASE, "CONVERT_TO_CRM_CLIENT")
export const fetchCallStatusAction = createSignalAction(BASE, "FETCH_CALL_STATUS")
export const submitCallFeedbackAction = createSignalAction(BASE, "SUBMIT_CALL_FEEDBACK")
export const buyerLocationSubmitAction = createSignalAction(BASE, "BUYER_LOCATION_SUBMIT")
export const sellerLocationSubmitAction = createSignalAction(BASE, "SELLER_LOCATION_SUBMIT")

// UI actions
export const openEditProfileAction = createActionCreator(OPEN_EDIT_PROFILE)
export const closeEditProfileAction = createActionCreator(CLOSE_EDIT_PROFILE)
export const updateCurrentSalesStageAction = createActionCreator(UPDATE_CURRENT_SALES_STAGE)
export const updateSalesChecklistAction = createActionCreator(UPDATE_SALES_CHECKLIST)
export const updateChecklistForClientAction = createActionCreator(UPDATE_CHECKLIST_CLIENT)
export const archiveReferralAction = createActionCreator(ARCHIVE_REFERRAL)
export const waitForClientUpdateAction = createActionCreator(WAIT_FOR_CLIENT_UPDATE)
export const isChecklistUpdateAction = createActionCreator(IS_CHECKLIST_UPDATE)
export const openISAAddClientModalAction = createActionCreator(OPEN_ISA_ADD_CLIENT_MODAL)
export const closeISAAddClientModalAction = createActionCreator(CLOSE_ISA_ADD_CLIENT_MODAL)
export const updateSalesStageAction = createActionCreator(UPDATE_SALES_STAGE)
export const toggleCallFeedbackModalAction = createSimpleCreator(BASE, "TOGGLE_CALL_FEEDBACK_MODAL")
export const userConfirmationModalAction = createSimpleCreator(BASE, "TOGGLE_USER_CONFIRMATION_MODAL")
export const userConfirmAction = createSimpleCreator(BASE, "USER_CONFIRM")
