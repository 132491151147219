import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import root from "window-or-global"
import interestContainer from "container/App/applyFlowContainer"
import { withRouter } from "react-router"
import {
  mobile,
} from "@consts"
import { ATC, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  font-family: ${ATC} !important;

  > p {
    font-size: 18px;
    line-height: 24px;
    font-family: ${ATC};
    font-weight: 300;
    color: ${colors.black};
    ${mobile(`
      font-size: 16px;
    `)}
  }
`

const Header = styled.div`
  h1 {
    color: #1E1E1E;
    font-family: ${MONTSERRAT};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 163.523%; /* 62.139px */
  }
`

const CSS_CODE = `
.ewebinar__RegForm {
    font-family: inherit;
    padding: 0;
    text-align: left;
    min-width: 300px;
    max-width: 450px;
    margin: auto;
  }

  .ewebinar__RegForm a:hover,
  .ewebinar__RegForm a:visited,
  .ewebinar__RegForm a:link,
  .ewebinar__RegForm a:active {
    text-decoration: none;
  }

  .ewebinar__RegForm * {
    box-sizing: border-box;
  }

  .ewebinar__RegForm:not(.loading) .ewebinar__Dots {
    opacity: 0;
    animation: none;
  }

  .ewebinar__RegForm__Content {
    padding: 1.5rem;
  }

  .ewebinar__RegForm:not(.ewebinar__RegForm--horizontal) .ewebinar__RegForm__Content {
    padding-bottom: 0.25rem;
  }

  .ewebinar__RegForm__Footer {
    padding: 1.5rem;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .ewebinar__RegForm .ewebinar__RegisterButton,
  .ewebinar__RegForm .ewebinar__RegisterButton__Wrap {
    max-width: unset !important;
    width: 100%;
  }

  .ewebinar__RegForm__Field {
    margin-bottom: 1.25rem;
  }

  .ewebinar__RegForm__Field input,
  .ewebinar__RegForm__Field select {
    font-size: inherit;
  }

  .ewebinar__RegForm__Field input {
    display: block;
    width: 100%;
    line-height: 1.21428571em;
    font-family: inherit;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: none;
  }

  .ewebinar__RegForm__Field__Input__Select {
    position: relative;
    border: none;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    outline: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    z-index: unset;
    opacity: 1 !important;
  }

  .ewebinar__RegForm__Field__Input__Select::after {
    position: absolute;
    content: '⌄';
    display: block;
    right: 1em;
    top: 50%;
    transform: translate(-10%, -75%) scale(2, 1.5);
    color: rgba(34, 36, 38, 0.15);
    pointer-events: none;
  }

  .ewebinar__RegForm__Field input::placeholder,
  .ewebinar__RegForm__Field__Input__Select select:invalid {
    color: #dedede !important;
  }

  .ewebinar__RegForm__Field__Input__Select select {
    font-size: inherit !important;
    font-family: inherit;
    height: 2.57142857em;
    padding: 0 1em;
    padding-right: 2.5rem;
    border: none;
    border-radius: 0.5em;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ewebinar__RegForm__Field__Input__Select select option {
    height: 2.57142857em;
  }

  .ewebinar__Session__Dropdown::after {
    position: absolute;
    content: '⌄';
    font-weight: 600;
    display: block;
    right: 1em;
    top: 50%;
    transform: translate(-10%, -75%) scale(2, 1.5);
    pointer-events: none;
  }

  .ewebinar__RegForm__Field__Input__Phone input {
    /*padding-left: 52px;*/
  }

  .ewebinar__RegForm__Error,
  .ewebinar__RegForm__Field__Error {
    color: #ff7470;
    font-size: 0.8rem;
    padding: 0.5833em 0.833em;
    display: none;
  }

  .ewebinar__RegForm__Error {
    margin-top: 0;
    padding: 0.5833em 0;
    margin-bottom: 0 !important;
    text-align: center;
  }

  .ewebinar__RegForm__Field__Error {
    display: none;
    position: relative;
    background: #fff;
    border: 1px solid #ff7470;
    border-radius: 0.28571429rem;
    margin-top: 0.5rem;
  }

  .ewebinar__RegForm__Field__Error::before {
    position: absolute;
    content: '';
    background: #fff;
    border-left: 1px solid;
    border-top: 1px solid;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    margin-top: -1px;
    border-color: inherit;
    border-width: 1px 0 0 1px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
  }

  @media only screen and (min-width: 992px) {}

  .ewebinar__RegForm__Field .ewebinar__Session__Dropdown__Select {
    height: auto;
    min-height: 2.58em;
  }

  .ewebinar__Session__Dropdown {
    position: relative;
    border: none;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    outline: none;
    border: 1px solid #444;
    font-size: 1.1em;
  }

  .ewebinar__Session__Dropdown__Select {
    font-family: inherit;
    height: 36px;
    padding: 0 1em;
    opacity: 1 !important;
    padding-right: 2.5rem;
    border: none;
    border-radius: 0.5em;
    font-size: 1em;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ewebinar__Session__Dropdown::after {
    position: absolute;
    content: '⌄';
    font-weight: 600;
    display: block;
    right: 1em;
    top: 50%;
    transform: translate(-10%, -75%) scale(2, 1.5);
    pointer-events: none;
  }

  .ewebinar__Session__Dropdown__Select:invalid {
    color: #dedede !important;
  }

  .ewebinar__Widget {
    line-height: 1.5;
  }

  .ewebinar__Widget select {
    display: flex;
  }

  .ewebinar__Widget * {
    box-sizing: border-box;
  }

  .ewebinar__RegisterButton {
    font-family: inherit;
    box-sizing: border-box;
    font-family: inherit;
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    cursor: pointer;
    border-width: 0px;
    outline: none;
    transition: all 0.2s;
    font-weight: 500;
    font-size: inherit;
    box-sizing: border-box;
    margin: 0;
  }

  .ewebinar__RegisterButton * {
    font-family: inherit;
  }

  .ewebinar__RegisterButton:hover {
    transform: scale(1.02);
  }

  .ewebinar__RegisterButton:active {
    box-shadow: none;
  }

  .ewebinar__RegisterButton .ewebinar__ButtonText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    vertical-align: baseline !important;
  }

  @media only screen and (max-width: 614px) {
    .ewebinar__RegisterButton {
      max-width: 320px !important;
      min-height: 42px !important;
      width: 100% !important;
    }
  }

  .ewebinar__Dots {
    opacity: 1;
    animation: 1.5s linear 0s normal forwards 1.5 delayin;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button .ewebinar__Dots {
    background: #3e58c9ff;
    border-radius: 50px;
  }

  .ewebinar__LoadingDot {
    height: 0.5em;
    width: 0.5em;
    border-radius: 100%;
    display: inline-block;
    animation: 1.2s ewebinar-loading-dot ease-in-out infinite;
  }

  .ewebinar__LoadingDot:nth-of-type(2) {
    animation-delay: 0.15s;
    margin-left: 0.5em;
  }

  .ewebinar__LoadingDot:nth-of-type(3) {
    animation-delay: 0.25s;
    margin-left: 0.5em;
  }

  @keyframes delayin {
    0% {
      opacity: 0;
    }

    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes ewebinar-loading-dot {
    30% {
      transform: translateY(-35%);
      opacity: 0.3;
    }

    60% {
      transform: translateY(0%);
      opacity: 0.8;
    }
  }
`

class EWebinarForm extends React.PureComponent {

  componentDidMount() {
    //
    (function (w, d, s, o, f, js, fjs) { 
      w["eWidget"] = o; w[o] = w[o]
      || function () 
      { (w[o].q = w[o].q || []).push(arguments) 
      }; if (d.getElementById(o)) return; 
      js = d.createElement(s),
      fjs = d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; 
      fjs.parentNode.insertBefore(js, fjs);
    }(window, document, "script", "_ew", "https://app.ewebinar.com/widget.js"))
    _ew("init", {
      root: "w1696451540110",
      isReview: false,
      mode: "public",
      openInPopup: false,
      for: "Registration",
      type: "RegForm",
      source: "",
      url: "https://app.ewebinar.com/webinar/radius-brokerage-overview-6293",
      shortUrl: "https://app.ewebinar.com/webinar/6293",
      sessions: [],
      formType: "LatestForm",
      ewebinar:
        {
          title: "Radius%20Brokerage%20Overview",
          borderRadius: 50,
          primaryColor: "#313131ff",
          readableColor: "#ffffff",
          actionColor: "#3e58c9ff",
          readableActionColor: "#ffffff",
          readableOnWhiteColor: "#313131ff",
          language: "en",
        },
      showGdprBanner: false,
      gdprBannerMode: "Off",
      gdprBannerText: "",
      carouselId: "",
      button: {
        btnText: "Register Now",
        showButtonTimer: true,
        buttonPrimaryColor: "#3e58c9ff",
        buttonReadableColor: "#ffffff",
        align: "Center",
        isFullWidth: false,
      },
      registerForm: {
        horizontal: false,
        hideSessionsDropdown: false,
        showOnlyBuiltInFields: false,
        showFieldsLabel: false,
        openLinkInNewWindow: true,
        showConsentCheckbox: false,
        formSessionType: "ShowDropdown",
        consentCheckboxText: "",
        fields: [{
          fieldName: "First name",
          propertyName: "firstName",
          type: "Text",
          subType: null,
          isRequired: true,
          isRemovable: false,
          note: "",
          options: null,
          __typename: "RegistrationFormField"
        }, {
          fieldName: "Last name",
          propertyName: "lastName",
          type: "Text",
          subType: null,
          isRequired: true,
          isRemovable: false,
          note: "",
          options: null,
          __typename: "RegistrationFormField",
        }, {
          fieldName: "Email",
          propertyName: "email",
          type: "Email",
          subType: null,
          isRequired: true,
          isRemovable: false,
          note: "",
          options: null,
          __typename: "RegistrationFormField",
        }, {
          fieldName: "Phone",
          propertyName: "phone",
          type: "Phone",
          subType: null,
          isRequired: true,
          isRemovable: true,
          note: "",
          options: null,
          __typename: "RegistrationFormField",
        }, {
          fieldName: "State",
          propertyName: "agentstate",
          type: "Text",
          subType: null,
          isRequired: true,
          isRemovable: true,
          note: "",
          options: null,
          __typename: "RegistrationFormField",
        }, {
          fieldName: "I want to:",
          propertyName: "broker_iwantto",
          type: "Selection",
          subType: "Single",
          isRequired: false,
          isRemovable: true,
          note: "",
          options: [{
            text: "Start my own team / brokerage",
            value: "",
            __typename: "OptionField",
          }, {
            text: "Up-level my existing team / brokerage",
            value: "",
            __typename: "OptionField",
          }, {
            text: "Join a high-performing team",
            value: "",
            __typename: "OptionField",
          },
          {
            text: "Start a solo brand",
            value: "",
            __typename: "OptionField",
          }],
          __typename: "RegistrationFormField",
        }, {
          fieldName: "Your Gross Production",
          propertyName: "your_gross_production",
          type: "Selection",
          subType: "Single",
          isRequired: true,
          isRemovable: true,
          note: "",
          options: [{
            text: "$25M and above",
            value: "",
            __typename: "OptionField",
          }, {
            text: "$11M to $25M",
            value: "",
            __typename: "OptionField",
          }, {
            text: "$3M to $10M",
            value: "",
            __typename: "OptionField",
          }, {
            text: "$1M to $2.9M",
            value: "",
            __typename: "OptionField",
          }, {
            text: "$1M or less",
            value: "",
            __typename: "OptionField",
          }],
          __typename: "RegistrationFormField",
        }],
      },
    })
  }

  render() {
    const {
      isWebinarFormVisible,
      toggleEWebinarModal,
    } = this.props
    //const fakeId = uuid().split("-")[0]

    return (
      <Modal
        toClose={
          () => {
            toggleEWebinarModal(false)
          }
        }
        // width={modalWidth}
        show={isWebinarFormVisible}
        modalVersion={3}
        closeIcon={`${CLOUDFRONT}/CrossVectorNew.png`}
        overlayStyles={{
          alignItems: "flex-start",
          borderRadius: "9px",
        }}
        iconStyles={{
          height: "12px",
          width: "12px",
          marginTop: "15px",
          marginRight: "15px",
        }}
        dialogStyles={{
          borderRadius: "8px",
        }}
        // modalStyles={{
        //   ...MODAL_STYLES,
        // }}
      >
        <Wrap
          onClick={(e) => {
            // e.stopPropagation()
            const element = root.document.getElementById("submitEwebinarButtonAnchor")
            console.log(e)
          }}
        >
          {/* <div dangerouslySetInnerHTML={htmlDoc} /> */}
          <Header>
            <h1>Join the Full Radius Overview</h1>
          </Header>
          <noscript>
            <div
              style={{
                textAlign: "center",
                marginTop: "1em",
                textDecoration: "none",
              }}
            >
              <a
                style={{
                  color: "#000",
                  fontSize: "13px",
                }}
              href="https://app.ewebinar.com/webinar/radius-brokerage-overview-6293">
                Register Now
              </a>
            </div>
            <style>
              {CSS_CODE}
            </style>
          </noscript>
          <div
            id="w1696451540110"
            class="ewebinar__Widget ewebinar__RegForm_Root"
            style={{
              width: "100%",
            }}
          >
            <form class="ewebinar__RegForm ewebinar--ltr">
              <div class="ewebinar__RegForm__Content">
                <div>
                  <div class="ewebinar__RegForm__Field ewebinar__RegForm__Field--Sessions">
                    <div
                      class="ewebinar__Session__Dropdown"
                      style={{
                        color: "#313131ff",
                        borderColor: "#313131ff",
                      }}
                    >
                      <select
                        name="session"
                      class="ewebinar__Session__Dropdown__Select ewebinar__Session__Dropdown__Select--placeholder}">
                      <option class="ewebinar__FixedBar__Content__Session__Dropdown__Select" value="" disabled="disabled"
                        selected="selected" hidden="">Getting sessions...</option>
                    </select></div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input"><input id="firstName" name="firstName" placeholder="First name" />
                    </div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input"><input id="lastName" name="lastName" placeholder="Last name" />
                    </div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input"><input id="email" name="email" placeholder="Email" /></div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input ewebinar__RegForm__Field__Input__Phone"><input id="phone"
                      name="phone" placeholder="Phone" /></div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input"><input id="agentstate" name="agentstate" placeholder="State" />
                    </div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input">
                      <div class="ewebinar__RegForm__Field__Input__Select ewebinar__RegForm__Field__Input__Select--placeholder">
                        <select name="broker_iwantto">
                          <option class="ewebinar__RegForm__Field__Input__Select--placeholder" value="" disabled="disabled"
                            selected="selected" hidden="">I want to:</option>
                        </select></div>
                    </div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                  <div class="ewebinar__RegForm__Field">
                    <div class="ewebinar__RegForm__Field__Input">
                      <div class="ewebinar__RegForm__Field__Input__Select ewebinar__RegForm__Field__Input__Select--placeholder">
                        <select name="your_gross_production">
                          <option class="ewebinar__RegForm__Field__Input__Select--placeholder" value="" disabled="disabled"
                            selected="selected" hidden="">Your Gross Production</option>
                        </select></div>
                    </div>
                    <div class="ewebinar__RegForm__Field__Error"></div>
                  </div>
                </div>
                <div class="ewebinar__RegForm__Captcha"></div>
                <div class="ewebinar__RegForm__Error"></div>
              </div>
              <div
                class="ewebinar__RegForm__Footer"
              >
                <a
                  id="submitEwebinarButtonAnchor"
                  class="ewebinar__RegisterButton__Wrap ewebinar--ltr"
                  href="javascript:;"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <button
                    class="ewebinar__Widget ewebinar__RegisterButton"
                    type="submit"
                    style={{
                      borderRadius: "50px",
                      background: "#3e58c9ff",
                      color: "#ffffff",
                    }}
                  >
                  <div class="ewebinar__Dots">
                    <span
                      class="ewebinar__LoadingDot"
                      style={{
                        background: "#ffffff",
                      }}
                    >
                    </span>
                    <span
                      class="ewebinar__LoadingDot"
                      style={{
                        background: "#ffffff",
                      }}
                    >
                        </span>
                        <span class="ewebinar__LoadingDot"
                      style={{
                        background: "#ffffff",
                      }}></span>
                      </div>
                      <span class="ewebinar__ButtonText"
                        style={{
                        whiteSpace: "nowrap",
                        }}>Register Now</span>
                </button></a></div>
            </form>
          </div>
        </Wrap>
      </Modal>
    )
  }
}

export default withRouter(interestContainer(EWebinarForm))
