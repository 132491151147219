/* eslint-disable max-len */
import React from "react"
import CRMV2Container from "container/CRM"
import TechAssetsContainer from "container/TechAssets"
import queryString from "query-string"
import ReferralContainer from "container/Referral"
import { withRouter } from "react-router-dom"
import CRMModal from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals/CRMModal"
import Loader from "@ui/Loader"
import cookie from "services/CookieStorage"
import ListingsContainer from "container/Listings"
import OffersV2 from "container/OffersV2"
import {
  ConfirmClosed,
  ConfirmGoToContract,
  ConfirmTransactionListing,
  ConfirmTransactionOffer,
  IncompleteToClosed,
  ListingToContract,
  OfferToContract,
  ViewTransactionContract,
  ViewTransactionListing,
  ViewTransactionOffer,
  EmptyDetailsWarning,
  CannotConvertContractToOffer,
  ShowInContractMessage,
  DeleteNoteModal,
  DeleteReminderModal,
  OfferConversionToContract,
  StatusChange,
} from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals"
import { checkIsBroker } from "../utils"
import { SELLER, BUYER, CONTRACT, INCOMPLETE, LISTING, OFFER } from "../common"

const AllCRMModals = (props) => {
  const {
    history,
    getBrokerageTransactionStatusResponse,
    getCtIdResponse,
    addBrokerageTransaction,
    setDealToView,
    isBroker,
    isAddListing,
    setIsAddListing,
    isViewListing,
    setIsViewListing,
    isAddOffer,
    setIsAddOffer,
    isViewOffer,
    setIsViewOffer,
    isViewContract,
    setIsViewContract,
    showGoToContract,
    isClosed,
    setIsClosed,
    setIsCreateWarning,
    isCreateWarning,
    isViewListingToContract,
    setIsViewListingToContract,
    isViewOfferToContract,
    setIsViewOfferToContract,
    cannotConvertContract,
    setCannotConvertContract,
    isEndContract,
    setIsEndContract,
    statusValue,
    toggleGoToContract,
    isShowWarning,
    toggleEmptyDeatilsWarning,
    isShowInContractWarning,
    toggleShowInContract,
    getClientTransactionsById,
    getClient,
    location,
    getContractById,
    getListingCoverSheet,
    isShowDeleteNoteModal,
    toggleShowDeleteNoteModal,
    isShowDeleteReminderModal,
    toggleShowDeleteReminderModal,
    isOfferToContractRedirectionModalVisible,
    toggleOfferToContractConversion,
    isStatusChangeModalOpen,
    toggleStatusChangeModal,
    setListingAddress,
    setEditingDealTeamMemberId,
    setOfferIdToView,
    clearExistingData,
    setListingId,
    setCurrentListingsState,
    performNecessaryActionsBeforeLoadingListing,
    setCurrentListingTab,
    setStateForOffer,
    toggleActiveTab,
    viewListingPersistence,
    viewListingModal,
    handleCollapseValues,
    viewListingModalResponse,
    setListingCardFromCRM,
  } = props

  const parsedQuery = queryString.parse(location.search)
  const { client_id } = parsedQuery

  const {
    isFetching,
    data: brokerageTransactionStatus,
  } = getBrokerageTransactionStatusResponse || {}

  const {
    initiateTransaction,
    id: brokerageId,
    status: brokerageStatus,
    transactionType: brokerageTransactionType,
  } = brokerageTransactionStatus || {}

  const { isShowGoToContract, result } = showGoToContract || {}

  const {
    data: clientTransactionById,
    isFetching: fetchingData,
  } = getCtIdResponse || {}

  const {
    type: clientType,
    id: transId,
  } = clientTransactionById || {}

  const createTransaction = (id, agentId) => {
    const transactionTypeFinal = isAddListing && initiateTransaction && SELLER.includes(clientType) ? LISTING : (isAddOffer && initiateTransaction && BUYER.includes(clientType) ? OFFER : CONTRACT)
    const payload = {
      transactionId: id || transId,
      transactionType: transactionTypeFinal,
    }
    if (!isBroker) {
      checkIsBroker(statusValue, clientType, history)
      return
    }
    addBrokerageTransaction({
      payload,
      transactionId: id || transId,
      callback: (dealId, transactionType) => {
        if (transactionType === OFFER) {
          setDealToView(dealId)
          //history.push(`/realty/offers/coversheet/buyer-agent-info?dealId=${dealId}`)
          if(agentId){
            history.push(`/realty/offers-v3/${dealId}?transaction_id=${id || transId}&agent_id=${agentId}&contract_phase=true`)
          } else {
            history.push(`/realty/offers-v3/${dealId}?transaction_id=${id || transId}&contract_phase=true`)
          }
          
        } else if (transactionType === LISTING) {
          setDealToView(dealId)
          getListingCoverSheet({
            dealId: dealId || 0,
            callAPI: ["agent_info"],
          })
          if (dealId) {
            clearExistingData()
            setDealToView(null)
            setListingCardFromCRM(true)
            viewListingPersistence({
              id: dealId,
              history,
              showToastsWhenCreatingListingFromCRM: true,
              isListingFromCRM: true,
            })
          }
        } else if (transactionType === CONTRACT) {
          setDealToView(dealId)
          getContractById({
            dealId,
          })
          history.push("/realty/transaction-coordination/contract/form")
        }
      },
    })
  }

  const viewTransaction = (id) => {
    if (brokerageTransactionStatus) {
      setOfferIdToView(null)
      if (brokerageTransactionType === OFFER) {
        setDealToView(id)
        history.push(`/realty/offers/coversheet/buyer-agent-info?dealId=${brokerageId}`)
      } else if (brokerageTransactionType === CONTRACT) {
        getContractById({
          dealId: id,
          callback: (res) => {
            const contractState = res[0]?.state
            const response = res[0]
            if(res[0]?.listing_id && !res[0]?.is_auto_generated){
              clearExistingData()
              setOfferIdToView(null)
              setListingId(res[0]?.listing_id)
              setListingAddress(res[0]?.listing_address)
              setCurrentListingsState(contractState.toLowerCase())
              response.active_contract_id = response.id
              performNecessaryActionsBeforeLoadingListing({
                dealId: res[0]?.listing_id,
                record: response,
                history,
                suppressNotifications: true,
                isContract: true,
              })
              history.push(`/listings/form/${res[0]?.listing_id}`)
            } else if (res[0]?.offer_id && !res[0]?.is_auto_generated){
              const dealId = res[0]?.offer_id
              setOfferIdToView(null)
              setDealToView(dealId)
              setStateForOffer(contractState.toLowerCase())
              setListingAddress(res[0]?.listing_address)
              setEditingDealTeamMemberId(parseInt(res[0]?.primary_agent_id, 10))
              //toggleActiveTab("cover_sheet")
              toggleActiveTab("contract_details")
              const {
                clients,
                deal_status,
              } = res[0] || {}
              if (clients && clients.length > 0) {
                const id = clients[0]?.transaction_id
                const url = `/realty/offers-v3/${dealId}?transaction_id=${id}&agent_id=${res[0]?.agent_id}`
                const sendToContractPhase = deal_status === "submitted"
                history.push(`${url}${sendToContractPhase ? "&contract_phase=submitted" : ""}`)
              } else {
                history.push(`/realty/offers-v3/${dealId}`)
              }
            } else{
              setOfferIdToView(null)
              setDealToView(res[0]?.id)
              setListingAddress(res[0]?.listing_address)
              const user = cookie.load("user") || {}
              const agentId = user.agent_id || user.id
              setEditingDealTeamMemberId(parseInt(res[0]?.agent_id, 10))
              if (res[0]?.step === "submitted") {
                const transactionId = res[0]?.clients && res[0]?.clients[0].transaction_id
                // const fileCreatedFromOffer = record.file_creation_flow === "offer"
                let url
              if(res[0]?.offer_id){
                setOfferIdToView(res[0]?.offer_id)
                setStateForOffer(contractState.toLowerCase())
                url = `/realty/transaction-coordination/contract/form/${res[0]?.id}?offerId=${res[0]?.offer_id}`
              } else {
                setListingId(res[0]?.listing_id)
                setCurrentListingsState(contractState.toLowerCase())
                url = `/realty/transaction-coordination/contract/form/${res[0]?.id}?listingId=${res[0]?.listing_id}`
              }
              history.push(url)
            } else {
              history.push("/realty/transaction-coordination/contract/form")
            }
            }
          },
        })
        //history.push("/realty/transaction-coordination/contract/basic-info")
      } else if (brokerageTransactionType === LISTING) {
        setListingId(id)
        viewListingPersistence({
          id,
          history,
          callback: () => {
            handleCollapseValues(["property_information"])
            setCurrentListingTab("cover_sheet")
            viewListingModal({
              bool: false,
            })
            //setIsLoading(false)
          },
        })
          //history.push(`/listings/form/${id}`)
      }
    }
  }

  const brokerageTransactionCondition = !initiateTransaction && brokerageId
  return (
    <>
      {initiateTransaction && isAddListing && SELLER.includes(clientType) && isCreateWarning  ? (
        <CRMModal
          toClose={() => {
            setIsAddListing(false)
            setIsCreateWarning(false)
          }}
        >
          <ConfirmTransactionListing
            createTransaction={createTransaction}
          />
        </CRMModal>
      ) : isViewListing && SELLER.includes(clientType)
  && (
    <CRMModal
      toClose={() => { setIsViewListing(false) }}
    >
      <ViewTransactionListing
        viewTransaction={viewTransaction}
      />
    </CRMModal>
  )}
      {initiateTransaction && isAddOffer && (BUYER.includes(clientType)) && isCreateWarning ? (
        <CRMModal
          toClose={() => {
            setIsAddOffer(false)
            setIsCreateWarning(false)
          }}
        >
          <ConfirmTransactionOffer
            createTransaction={createTransaction}
          />
        </CRMModal>
      ) : isViewOffer && (BUYER.includes(clientType))
  && (
    <CRMModal
      toClose={() => { setIsViewOffer(false) }}
    >
      <ViewTransactionOffer
        viewTransaction={viewTransaction}
      />
    </CRMModal>
  )}
      {isShowGoToContract && (
        <CRMModal
          toClose={() => {
            toggleGoToContract({ isShowGoToContract: false })
            setIsEndContract(false)
          }}
        >
          <ConfirmGoToContract
            crmClientData={result}
            dealId={brokerageId}
            transactionType={brokerageTransactionType}
            createTransaction={createTransaction}
          />
        </CRMModal>
      )}
      {(!initiateTransaction && isViewContract && brokerageTransactionType === CONTRACT) && (
        <CRMModal
          toClose={() => { setIsViewContract(false) }}
        >
          <ViewTransactionContract
            viewTransaction={viewTransaction}
          />
        </CRMModal>
      )}
      {brokerageTransactionCondition && isViewListingToContract && SELLER.includes(clientType) && (
        <CRMModal
          toClose={() => { setIsViewListingToContract(false) }}
        >
          <ListingToContract
            dealId={brokerageId}
            transactionType={brokerageTransactionType}
          />
        </CRMModal>
      )}
      {brokerageTransactionCondition && isViewOfferToContract && (BUYER.includes(clientType)) && (
        <CRMModal
          toClose={() => { setIsViewOfferToContract(false) }}
        >
          <OfferToContract
            dealId={brokerageId}
            transactionType={brokerageTransactionType}
          />
        </CRMModal>
      )}
      {brokerageTransactionCondition && cannotConvertContract && (BUYER.includes(clientType)) && (
        <CRMModal
          toClose={() => { setCannotConvertContract(false) }}
        >
          <CannotConvertContractToOffer
            dealId={brokerageId}
          />
        </CRMModal>
      )}
      {isFetching === true && fetchingData === true ? <Loader /> : (
        <>
          {(initiateTransaction && isAddListing && (SELLER.includes(clientType)) && isCreateWarning) && (
            <CRMModal
              toClose={() => {
                setIsAddListing(false)
                setIsCreateWarning(false)
              }}
            >
              <ConfirmTransactionListing
                createTransaction={createTransaction}
              />
            </CRMModal>
          )}
        </>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(initiateTransaction && isAddOffer && (BUYER.includes(clientType)) && isCreateWarning) && (
            <CRMModal
              toClose={() => {
                setIsAddOffer(false)
                setIsCreateWarning(false)
              }}
            >
              <ConfirmTransactionOffer
                createTransaction={createTransaction}
              />
            </CRMModal>
          )}
        </>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(isShowGoToContract && brokerageTransactionType !== CONTRACT) && (
            <CRMModal
              toClose={() => {
                setIsEndContract(false)
                toggleGoToContract({ isShowGoToContract: false })
              }}
            >
              <ConfirmGoToContract
                dealId={brokerageId}
                crmClientData={result}
                transactionType={brokerageTransactionType}
                createTransaction={createTransaction}
              />
            </CRMModal>
          )}
        </>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(brokerageTransactionCondition && isViewListingToContract && (SELLER.includes(clientType))) && (
            <CRMModal
              toClose={() => { setIsViewListingToContract(false) }}
            >
              <ListingToContract
                dealId={brokerageId}
                transactionType={brokerageTransactionType}
              />
            </CRMModal>
          )}
        </>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(brokerageTransactionCondition && isViewOfferToContract && (BUYER.includes(clientType))) && (
            <CRMModal
              toClose={() => { setIsViewOfferToContract(false) }}
            >
              <OfferToContract
                dealId={brokerageId}
                transactionType={brokerageTransactionType}
              />
            </CRMModal>
          )}
        </>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(brokerageTransactionCondition && cannotConvertContract && (BUYER.includes(clientType))) && (
            <CRMModal
              toClose={() => { setCannotConvertContract(false) }}
            >
              <CannotConvertContractToOffer
                dealId={brokerageId}
              />
            </CRMModal>
          )}
        </>
      )}
      {isShowWarning && (
        <CRMModal
          toClose={() => { toggleEmptyDeatilsWarning(false) }}
        >
          <EmptyDetailsWarning />
        </CRMModal>
      )}
      {isFetching && fetchingData ? <Loader /> : (
        <>
          {(brokerageTransactionType !== CONTRACT && isClosed) && (
            <CRMModal
              toClose={() => { setIsClosed(false) }}
            >
              <ConfirmGoToContract
                dealId={brokerageId}
                transactionType={brokerageTransactionType}
                createTransaction={createTransaction}
              />
            </CRMModal>
          )}
        </>
      )}
      {isShowInContractWarning && (
        <CRMModal
          toClose={() => {
            toggleShowInContract(false)
            getClientTransactionsById({ transactionId: transId })
            getClient({ clientId: client_id })
          }}
        >
          <ShowInContractMessage
            toClose={() => { toggleShowInContract(false) }}
          />
        </CRMModal>
      )}
      {isShowDeleteNoteModal && (
        <CRMModal
          toClose={() => {
            toggleShowDeleteNoteModal(false)
          }}
        >
          <DeleteNoteModal
            toClose={() => toggleShowDeleteNoteModal(false)}
          />
        </CRMModal>
      )}
      {isShowDeleteReminderModal && (
        <CRMModal
          toClose={() => {
            toggleShowDeleteReminderModal(false)
          }}
        >
          <DeleteReminderModal
            toClose={() => toggleShowDeleteReminderModal(false)}
          />
        </CRMModal>
      )}
      {isOfferToContractRedirectionModalVisible.bool && (
        <CRMModal
          toClose={() => {
            toggleOfferToContractConversion({
              bool: false,
              url: "",
            })
          }}
        >
          <OfferConversionToContract
            toClose={() => toggleOfferToContractConversion({
              bool: false,
              url: "",
            })}
          />
        </CRMModal>
      )}

      {isStatusChangeModalOpen.bool && (
        <CRMModal
          toClose={() => {
            toggleStatusChangeModal({
              bool: false,
              id: "",
              agentId: null,
            })
          }}
        >
          <StatusChange
            toClose={() => toggleStatusChangeModal({
              bool: false,
              id: "",
              agentId: null,
            })}
          />
        </CRMModal>
      )}
    </>
  )
}

export default withRouter(ReferralContainer(OffersV2(ListingsContainer(TechAssetsContainer(CRMV2Container(AllCRMModals))))))
