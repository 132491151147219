import React, { useState, useEffect, useRef } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/ListingsV2/index"
import Offer from "container/TechAssets"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/ListingsV2/commonStyles"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Modal,
} from "antd"
import AddButtonCTA from "dumbComponents/ListingsV2/Components/AddButtonCTA"
import Confirmation from "dumbComponents/ListingsV2/Components/Confirmation"
import { EyeIcon, ShareIcon } from "dumbComponents/ListingsV2/icons"
import {
  EnvelopesWrap,
} from "./styles"
import EnvelopesCard from "./EnvelopeCard"
import { StyledCheckbox } from "../CheckboxList/styles"
import AdditionalDocsUpload from "./AdditionDocsUpload"
import AdditionalDocsListing from "./AdditionalDocsListing"
import SendOffer from "./SendOffer/index"

const { confirm } = Modal

const statusMap = {
  pending: {
    value: "pending",
    label: "Your Signature Pending",
  },
  sent: {
    value: "buyer_pending",
    label: "Buyer Signature Pending",
  },
  completed: {
    value: "completed",
    label: "Completed",
  },
  action_required: {
    value: "action_required",
    label: "Your Signature Pending",
  },
  active: {
    value: "active",
    label: "Active",
  },
  void: {
    value: "void",
    label: "Voided",
  },
  declined: {
    value: "declined",
    label: "Declined",
  },
}

const Envelopes = ({
  envelopes,
  createReceiverViewOffer,
  proofOfFundsDocs,
  preApprovedDocs,
  coverLetterDocs,
  othersDocs,
  createReceiverOfferViewV2,
  createReceiverOfferViewV2Response,
  dealToView,
  uploadOfferRelated,
  fetchProofOfFunds,
  fetchPreApproved,
  fetchCoverLetters,
  updateProofOfFunds,
  updatePreApproved,
  updateCoverLetter,

  sendForVoidAndRestartOffer,
  sendForVoidAndDeleteOffer,
  sendReminder,
  createEditViewOffer,
  downloadEnvelopeDocOffer,
  fetchOfferPackageDocumentsResponse,
  fetchOfferPackageDocuments,
}) => {
  const [isAdditionalDocDropdownVisible, toggleAdditonalDocDropDown] = useState(false)
  const [viewOfferSendUI, setViewOfferSendUI] = useState(true)
  const textRef = useRef("")
  const {
    isFetching: fetchingOfferPackageDetails,
  } = fetchOfferPackageDocumentsResponse || {}

  const {
    isFetching: creatingReceiverView,
  } = createReceiverOfferViewV2Response || {}

  useEffect(() => {
    fetchProofOfFunds({
      id: dealToView,
      type: "proof_of_funds",
    })
    fetchCoverLetters({
      id: dealToView,
      type: "cover_letter",
    })
    fetchPreApproved({
      id: dealToView,
      type: "pre_qualification",
    })
  }, [])

  const getViewMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return []
      case "sent":
      case "decline":
        return NON_COMPLETED_OPTION_LIST.viewMenu
      case "completed":
        return COMPLETED.viewMenu
      default:
        return []
    }
  }

  const getDropDownMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return ACTION_REQUIRED.dropDownMenu
      case "sent":
      case "decline":
        return NON_COMPLETED_OPTION_LIST.dropDownMenu
      case "completed":
        return COMPLETED.dropDownMenu
      default:
        return []
    }
  }

  const handleViewEnvelope = () => {}
  const handleVoidAndRestart = (id) => {
    confirm({
      title: "Please confirm the action.",
      icon: <ExclamationCircleOutlined />,
      content: <Confirmation
        updateNote={(val) => {
          textRef.current = val
        }}
      />,
      onOk() {
        const payload = [{ envelopeId: id, reason: textRef.current }]
        sendForVoidAndRestartOffer({
          payload,
        })
      },

      onCancel() {
        return false
      },
    })
  }
  const handleVoidDocument = () => {}

  const handleSendReminder = (id) => {
    const payload = {
      envelopeId: id,
    }
    sendReminder({
      payload,
    })
    alert("TOAST REQUIRED, REMINDER SENT")
  }

  const handleEditDocument = (id) => {
    createEditViewOffer({
      payload: {
        envelopeId: id,
      },
    })
  }
  const handleDownload = (id) => {
    downloadEnvelopeDocOffer({
      payload: {
        envelopeId: id,
      },
      documentDetail: {
        name: `Offer_${dealToView}`,
      },
    })
  }

  const NON_COMPLETED_OPTION_LIST = {
    viewMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
      {
        label: "SEND REMINDER",
        image: <ShareIcon />,
        onClick: id => handleSendReminder(id),
        showDivide: false,
      },
    ],
    dropDownMenu: [
      {
        label: "Void and Restart",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "Void",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const ACTION_REQUIRED = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "Edit",
        onClick: id => handleEditDocument(id),
      },
      {
        label: "Download",
        onClick: id => handleDownload(id),
      },
    ],
  }

  const COMPLETED = {
    viewMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
    ],
    dropDownMenu: [
      {
        label: "Download",
        onClick: id => handleDownload(id),
      },
      {
        label: "Void and Restart",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "Void",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const handleFillAndSign = (envelopeId) => {
    const payload = {
      envelopeId,
    }
    createReceiverOfferViewV2({
      payload,
    })
  }

  const handleUpload = (payload) => {
    uploadOfferRelated({
      id: dealToView,
      payload,
    })
  }

  const handleSelectAllEnvelopes = () => {
    //
  }

  const handleSendOffer = () => {
    fetchOfferPackageDocuments({
      id: dealToView,
      callback: () => {
        setViewOfferSendUI(true)
      },
    })
  }

  return (
    <>
      {false ? (
        // <SendOffer
        //   goBack={() => setViewOfferSendUI(false)}
        // />
        <></>
      ) : (
        <EnvelopesWrap
          onClick={(e) => {
            // e.stopPropagation()
            // toggleAdditonalDocDropDown(false)
          }}
        >
          {proofOfFundsDocs && proofOfFundsDocs.length > 0 && (
            <SectionWrap>
              <AdditionalDocsListing
                docs={proofOfFundsDocs}
                typeOfDocument="PROOF OF FUNDS"
              />
            </SectionWrap>
          )}

          {preApprovedDocs && preApprovedDocs.length > 0 && (
            <SectionWrap>
              <AdditionalDocsListing
                docs={preApprovedDocs}
                typeOfDocument="PRE APPROVED"
              />
            </SectionWrap>
          )}

          {coverLetterDocs && coverLetterDocs.length > 0 && (
            <SectionWrap>
              <AdditionalDocsListing
                docs={coverLetterDocs}
                typeOfDocument="COVER LETTER"
              />
            </SectionWrap>
          )}

          <SectionWrap>
            <CTAWrap
              style={{
                postion: "relative",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <StyledCheckbox
                value="here"
                onChange={handleSelectAllEnvelopes}
              >
                Select all
              </StyledCheckbox> */}
              <AddButtonCTA
                name="Add Pre-signed Paperwork"
                onClick={(e) => {
                  e.stopPropagation()
                  toggleAdditonalDocDropDown(!isAdditionalDocDropdownVisible)
                }}
              />
              {isAdditionalDocDropdownVisible && (
                <AdditionalDocsUpload
                  close={() => toggleAdditonalDocDropDown(false)}
                  uploadFunction={handleUpload}
                />
              )}
            </CTAWrap>
          </SectionWrap>
        </EnvelopesWrap>
      )}
    </>
  )
}

export default Offer(OffersV2(Envelopes))

