import React from "react"
import OffersV2Container from "container/OffersV2"
import {
  Wrap,
  TabWrap,
  TabInfo,
  TabTitle,
} from "./styles"

const OfferTabs = ({
  currentTab,
  toggleTab,
  steps,
}) => {
  return (
    <Wrap>
      <TabWrap>
        {steps && steps.map(step => (
          <TabInfo onClick={() => toggleTab(step.value)} isActive={currentTab === step.value}>
            <TabTitle isActive={currentTab === step.value}>
              {step.label}
            </TabTitle>
          </TabInfo>
        ))}
      </TabWrap>
    </Wrap>
  )
}

export default OffersV2Container(OfferTabs)
