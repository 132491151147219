import React from "react"
import styled from "styled-components"
import colors from "@colors"
import Button from "@ui/Button"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const desc = `Joe Stevenson talks about leveling up his brokerage experience after
              joining Radius and how he’s on the path to success by leveraging all
              the Brokerage & Mortgage company have to offer top producers.`

const Wrap = styled.div`
  margin-top: 70px;
`

const ContentWrap = styled.div`
  padding: 60px;
  max-width: 1440px;
  margin: auto;
  margin-bottom: 150px;

  @media(max-width: 425px) {
    padding: 20px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media(max-width: 1099px) {
      flex-direction: column;
      align-items: center;
    }

    > iframe {
      max-width: 637px;
      width: 100%;
    }
  }
`

const QuoteWrap = styled.div`
  > div {
    max-width: 635px;
    width: 100%;
    > p {
      font-size: 22px;
      line-height: 35px;
      font-family: ${ATC};
      color: #303030;
      font-weight: 200;

      @media(max-width: 1099px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }
    
    > h1 {
      max-width: 556px;
      width: 100%;
      font-size: 42px;
      font-family: ${HIGHLIGHT_FONT};
      line-height: 49px;
      margin-top: 0;

      @media(max-width: 768px) {
        max-width: 645px;
        font-size: 36px;
      }

      @media(max-width: 425px) {
        font-size: 32px;
      }
    }
  }
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 290px !important;
  align-items: center;
  gap: 20px;

  > a {
    color: ${colors.marineBLue};
    font-family: ${ATC};
  }

  @media(max-width: 1099px) {
    margin: 20px auto 0px;
    justify-content: center;
  }
`

const Producers = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <div>
          <iframe width="560" height="420" src="https://www.youtube.com/embed/_hfXCmeTDYs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <QuoteWrap>
            <div>
              <h1>
                Learn how top producers are thriving at Radius
              </h1>
              <p>
                {desc}
              </p>
            </div>
            <ButtonWrap>
              <Button
                style={{
                  width: "290px",
                  padding: 10,
                  background: `${colors.marineBLue}`,
                  border: "none",
                  borderRadius: "0px",
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                Discover your next brokerage
              </Button>
              <a href="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true">
                Book a meeting
              </a>
            </ButtonWrap>
          </QuoteWrap>
        </div>
      </ContentWrap>
    </Wrap>
  )
}

export default Producers
