import React from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import colors from "@colors"
import {
  SubmitButton,
} from "./CommonStyles"

const SubmitButtonComponent = ({
  isProcessing,
  isInvalid,
  text,
  type,
  fullWidth,
  func,
  disabled: isFormDisabled,
}) => (
  <>
    {isFormDisabled ? (
      <SubmitButton
        type={type || "submit"}
        fullWidth={fullWidth}
        disabled={false}
        onClick={func}
      >
        {isProcessing ? (
          <ThreeDotLoader size={6} color={colors.white} />
        ) : (
          <p>{text}</p>
        )}
      </SubmitButton>
    ) : (
      <SubmitButton
        type={type || "submit"}
        fullWidth={fullWidth}
        disabled={isInvalid}
        onClick={func}
      >
        {isProcessing ? (
          <ThreeDotLoader size={6} color={colors.white} />
        ) : (
          <p>{text}</p>
        )}
      </SubmitButton>
    )}
  </>
)

export default SubmitButtonComponent
