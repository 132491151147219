import styled from "styled-components"
import {
  Select, Dropdown, Button, Menu
} from "@ui/antd"
import { Popover } from "antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
display: flex;
padding: 20px 32px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 16px;
align-self: stretch;
width: 100%
`
export const FilterWrapper = styled.div`
width: 100%
display: flex;
//justify-content: flex-end;
align-items: center;
gap: 16px;
align-self: stretch;
`
export const FilterButton = styled(Button)`
border-radius: 8px !important;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB) !important;
display: flex !important;
height: 42px !important;
padding: 8px 12px !important;
align-items: center !important;
gap: 8px !important;
color: var(--Global-Mapping-Gray-700, #373758) !important;
font-family: Inter !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 150% !important; /* 24px */
letter-spacing: 0.5px !important;
&:hover,
  &:focus,
  &:active {
    background-color: white !important; 
    color: var(--Global-Mapping-Gray-700, #373758) !important; 
    border-color: var(--Global-Mapping-Gray-100, #E0E0EB)!important; 
    box-shadow: none !important; 
  }

  &:focus-visible {
    outline: none !important;
  }
`
export const CommonFilterWrap = styled.div`
display: flex;
align-items: center;
gap: 16px;`

export const FilterWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
flex: 1 0 0;
gap: 8px;
`

export const SelectFieldWrap = styled(Select)`
// border-radius: 4px;
// border: 0.7px solid #898989;
width: 100%;

border-radius: 8px !important;
border: 1px solid var(--Color-Primary-Primary, ${props => props.borderColor ? props.borderColor : "#9CA3AF"}) !important;

.ant-select-selector {
  ${props => props.isFormField && `
    border-radius: 8px !important;
  `}
  height: 40px !important;
}

`
export const ClearFilterButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  p {
    color: var(--Color-Red-Red-500, #F05152);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: 0.5px;
  }
`
export const StyledButton = styled(Button)`
  display: flex !important;
  height: 42px !important;
  padding: 8px 12px !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 8px !important;
  font-weight: 300 !important;
  border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb) !important;
  background: white !important;
  color: #BFBFBF !important; /* Text color */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;


  &:hover,
  &:focus {
    border-color: var(--Global-Mapping-Gray-200, #c6c6cf) !important;
    color: #A3A3C !important; /* Prevent text from turning blue */
    background-color: white !important; /* Prevent background change */
  }

  &:active {
    border-color: var(--Global-Mapping-Gray-200, #c6c6cf) !important;
    color: #A3A3C2 !important; /* Prevent text from turning blue */
    background-color: white !important; /* Prevent background change */
  }
`
export const StyledButtonSelected = styled(Button)`
  display: flex !important;
  height: 42px !important;
  padding: 8px 12px !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 8px !important;
  font-weight: 300 !important;
  border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb) !important;
  background: white !important;
  color: #303030 !important; /* Text color */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;


  &:hover,
  &:focus {
    border-color: var(--Global-Mapping-Gray-200, #c6c6cf) !important;
    color: #A3A3C !important; /* Prevent text from turning blue */
    background-color: white; /* Prevent background change */
  }

  &:active {
    border-color: var(--Global-Mapping-Gray-200, #c6c6cf) !important;
    color: #A3A3C2 !important; /* Prevent text from turning blue */
    background-color: white !important; /* Prevent background change */
  }
`
export const CustomMenu = styled(Menu)`
  border-radius: 8px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  min-width: 200px !important; /* Adjust width as needed */

  .ant-menu-item {
    padding: 8px 12px !important;
    background-color: white !important; /* Default background */
    color: #a3a3c2 !important; /* Maintain consistent text color */
    transition: background-color 0.3s !important;
  }

  .ant-menu-item:hover {
    background-color: var(--Global-Mapping-Gray-50, #f5f5f9) !important; /* Subtle hover effect */
    color: #a3a3c2 !important; /* Prevent text from changing to blue */
  }

  .ant-menu-item:active {
    background-color: var(--Global-Mapping-Gray-100, #e0e0eb) !important; /* Light gray on click */
    color: #a3a3c2 !important; /* Prevent text from changing */
  }
`

export const DropDownWrap = styled(Dropdown)`
  .ant-dropdown-trigger {
  color: #a3a3c2 !important; /* Override hover color */
  background: white !important; /* Prevent background change */
}

.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:focus,
.ant-dropdown-trigger:active {
  color: #a3a3c2 !important;
  background: white !important;
}
`

export const CustomRangeContainer = styled.div`
  padding: 10px;

  .ant-btn-primary {
    border-radius: 8px !important;
    width: 100% !important;
  }
`