import React from "react"
import styled from "styled-components"
import icons from "@icons"
import colors from "@colors"
import { toast as createToast } from "react-toastify"
import UploadImageIcon from "images/network/upload-image-icon.svg"

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}


const StyledUploadIcon = styled.img`
  cursor: pointer;
  color: ${props => props.active ? colors.primaryColor : "inherit"};

  &:hover {
    color: ${colors.primaryColor};
  }
`

const MediaUpload = (props) => {
  const { mediaType, handleFileUpload } = props

  const handleImageUpload = (e) => {
    // eslint-disable-next-line prefer-destructuring
    const files = e.target.files
    
    console.log(files)

    if (files && files.length > 10) {
      createToast.info("Sorry! Cannot upload more than 10 images at a time", toastConfig)
      e.target.value = null
    }

    if (files && files.length > 0) {
      handleFileUpload(files)
      e.target.value = null
    }
  }

  return (
    <div>
      <label htmlFor="post-upload">
        <StyledUploadIcon
          className={icons.genericCamera}
          src={UploadImageIcon}
          active={mediaType === "image"}
          alt="Upload media"
        />
      </label>
      <input
        id="post-upload"
        type="file"
        multiple
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />
    </div>
  )
}

export default MediaUpload
