import React, { Component } from "react"
import styled from "styled-components"
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete"
import IconInput from "@ui/IconInput"
import icons from "@icons"
import colors from "@colors"
import poweredByGooglePNG from "images/poweredByGoogle.png"

const searchOptions = {
  types: ["(cities)"],
}


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${props => props.agentSearch && `
    width: 100%;
    position: relative;
    height: 67%;
    position: relative;
    bottom: 0px;

    .location-search-input {
      ::placeholder {
        color: grey;
      }
      color: black;
    }
    `}
`

const StyledIconInput = styled(IconInput)`
   width: 290px;
    ${props => props.agentSearch && `
        border: none !important;
        i {
          margin-top: -10px;
        }
      `}
`

const SuggestionsWrapper = styled.div`
  background-color: white;
  position: absolute;
  top: 40px;
  cursor: pointer;
  border: 1px solid ${colors.borderColor};
  width: 350px;
`

const Suggestion = styled.div`
  background-color: white;
  color: ${colors.tagInactive};
  font-weight: 300;
  border-top: 1px solid ${colors.borderColor};

  padding: 8px;
  ${props => props.active && `
    background-color: ${colors.greyBackground};
  `}
`
const PoweredByGoogle = styled.div`
  cursor: not-allowed;
  padding: 8px;
  position: relative;
`

const GoogleImage = styled.img`
  position: absolute;
  bottom: 4px;
  right: 0;
`

export default class GooglePlaces extends Component {
  handleSelect = (address, placeId) => {
    const { onLocationSelect, rawTextSearch, handleSubmit } = this.props
    if (address) {
      if (!placeId) {
        rawTextSearch(address)
      } else {
        geocodeByAddress(address)
          .then((results) => {
            const { address_components, geometry: { location } } = results[0]
            const componentMap = {}
            address_components.forEach((component) => {
              componentMap[component.types[0]] = component.long_name
            })
            const country = componentMap.country || null
            const state = componentMap.administrative_area_level_1 || null
            const city = componentMap.locality
            const lat = location.lat()
            const lng = location.lng()
            onLocationSelect({
              lat,
              lng,
              city,
              state,
              country,
            })
          })
          .catch(error => console.error("Google Places Error", error))
      }
    } else {
      handleSubmit()
    }
  }

  handleError = (status, clearSuggestions) => {
    //console.log("Google Maps API returned error with status: ", status)
    clearSuggestions()
  }

  render() {
    const {
      forwardedRef,
      addressTextBoxValue,
      handleAddressTextBoxChange,
      handleOnBlur,
      agentSearch,
      placeHolder,
    } = this.props

    return (
      <PlacesAutocomplete
        value={addressTextBoxValue}
        onChange={handleAddressTextBoxChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        onError={this.handleError}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Wrapper
            agentSearch={agentSearch}
          >
            <StyledIconInput
              iconClassName={icons.locationLocationPoint}
              agentSearch={agentSearch}
            >
              <input
                {...getInputProps({
                  placeholder: placeHolder || "Search places",
                  className: "location-search-input",
                  onBlur: handleOnBlur,
                  ref: forwardedRef,
                })}
              />
            </StyledIconInput>
            {loading || suggestions.length > 0 && (
              <SuggestionsWrapper>
                {loading && (
                  <Suggestion>Loading...</Suggestion>)
                }
                {suggestions.map(suggestion => (
                  <Suggestion
                    {...getSuggestionItemProps(suggestion)}
                    active={suggestion.active}
                  >
                    <span>{suggestion.description}</span>
                  </Suggestion>
                ))}
                <PoweredByGoogle>
                  <GoogleImage src={poweredByGooglePNG} height={14} alt="Radius Agent Google Login" />
                </PoweredByGoogle>
              </SuggestionsWrapper>
            )}

          </Wrapper>
        )}
      </PlacesAutocomplete>
    )
  }
}
