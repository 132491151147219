import {
  takeLatest,
  call,
  put,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import * as ContactUsActions from "./action"
import { submitInquiryAPI } from "./api"

function* handleContactUsFormSubmit(action) {
  try {
    const res = yield call(submitInquiryAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ContactUsActions.submitContactFormAction.success(response))
    }
  } catch (a) {
    yield put(ContactUsActions.submitContactFormAction.failure(e))
  }
}

export default function* main() {
  yield takeLatest(ContactUsActions.submitContactFormAction.REQUEST, handleContactUsFormSubmit)
}
