export const RADIUS_TC_OPEN_ESCROW_CONFIRMATION = [
  {
    id: 1,
    name: "Yes",
    value: "radius_tc_open_escrow_yes",
  },
  {
    id: 2,
    name: "No",
    value: "radius_tc_open_escrow_no",
  },
]

export const FIELD_VALUES = {
  is_radius_tc_to_open_escrow: "checkbox",
  escrow_officer_email: "string",
  escrow_officer_phone: "string",
}
