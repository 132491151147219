/*
  author - Saurav Tiru
  This file contains common code for Paragraph.
  DO NOT EDIT THIS FILE DIRECTLY.

  1) Custom Styling
    Use "customParaStyling" prop to pass custom paragraph style properties.

  2) Additional Properties
    Request author to verify additional properties before merging.

  3) Media Queries
    Do not change Media Query widths.
 */

import React from "react"
import styled from "styled-components"
import {
  LARGE_DESKTOP,
  MIN_DESKTOP,
  TABLET,
  MOBILE,
} from "../commonData"

const H1Wrapper = styled.h1`
  /*Paragraph Styling*/
  font-family: ${props => props.largeTextFamily || "sans-serif"};
  text-align: ${props => props.alignLargeText || "left"};
  text-decoration: ${props => props.largeTextDecoration || "none"};
  font-weight: ${props => props.largeTextWeight || "normal"};
  color: ${props => props.color};
  line-height: ${props => props.largeTextLineHeight};
  letter-spacing: ${props => props.letterSpacing};
  display: ${props => props.displayLargeText};
  justify-content: ${props => props.justifyLargeText};
  text-transform: ${props => props.largeTextTransform};

  @media(min-width: 1100px) {
      width: ${props => props.minDesktoplargeTextWidth || "max-content"};
      font-size: ${props => props.minDesktopLargeTextSize || "20px"};
  }

  @media(min-width: 1440px) {
    width: ${props => props.minLargeDesktoplargeTextWidth || "max-content"};
    font-size: ${props => props.minLargeDesktopLargeTextSize || "20px"};
  }
  
  @media(max-width: 1099px) {
      width: ${props => props.preDesktoplargeTextWidth || "max-content"};
      font-size: ${props => props.preDesktopLargeTextSize || "20px"};
  }

  @media(max-width: 767px) {
      width: ${props => props.preTabletlargeTextWidth || "max-content"};
      font-size: ${props => props.minTabletLargeTextSize || "20px"};
  }

  @media(max-width: 650px) {
    width: ${props => props.maxMobilelargeTextWidth || "max-content"};
    font-size: ${props => props.maxMobilelargeTextSize || "18px"};
}
    /*Paragraph Styling*/   
`

const LargeText = (props) => {
  const {
    customLargeTextStyling,
    children,
    content,
  } = props

  return (
    <H1Wrapper
      style={{
        ...customLargeTextStyling || {},
      }}
      {...props}
    >
      {children || content || null}
    </H1Wrapper>
  )
}

LargeText.defaultProps = {
  color: "#303030",
}

export default LargeText
