import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import CheckBox from "@ui/Checkbox"
import Button from "@ui/LoaderButton"
import Loader from "@ui/Loader"

const Wrap = styled.div`
  margin-top: 30px;
`

const ReasonsWrap = styled.div`
  padding: 10px 0px;
`

const Item = styled.div`
  display: flex;
  padding: 16px 0px;
  > p {
    flex: 1;
  }

  > input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
`

const FooterWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

class ReportModal extends React.PureComponent {
  state = {}

  componentDidMount() {
    const { getBlockedReasons } = this.props
    getBlockedReasons()
  }

  selectReason = (item) => {
    this.setState({
      selectedItem: item,
    })
  }

  handleOnChange = (e) => {
    const { name, value} = e.target
    this.setState({
      [name]: value
    })
  }

  handleClick = () => {
    const { reportFeed } = this.props
    const { selectedItem, otherReason } = this.state
    this.setState({
      isReporting: true,
    })
    reportFeed({
      payload: {
        reason: selectedItem === "Other" ? otherReason : selectedItem,
      },
    })
  }

  render() {
    const { selectedItem, isReporting, otherReason } = this.state
    const { toggleReportModal, reportReasonsResponse } = this.props
    const { isFetching, data } = reportReasonsResponse
    return (
      <Modal
        title="Report Feed"
        show
        toClose={() => toggleReportModal(false)}
      >
        <Wrap>
          {isFetching ? (
            <Loader top="0px" bottom="0px" />
          ) : (
            <ReasonsWrap>
              {data && data.reasons && data.reasons.map(item => (
                <Item key={item}>
                  <p>{item}</p>
                  <span>
                    <CheckBox onClick={() => { this.selectReason(item) }} checked={selectedItem === item} />
                  </span>
                </Item>
              ))}
              <Item>
                <p>Other</p>
                <span>
                  <CheckBox onClick={() => { this.selectReason("Other") }} checked={selectedItem === "Other"} />
                </span>
              </Item>
              {selectedItem === "Other" && (
                <Item>
                  <input
                    type="text"
                    name="otherReason"
                    onChange={this.handleOnChange}
                    placeholder="Reason for reporting"
                    value={otherReason}
                  />
                </Item>
              )}
            </ReasonsWrap>
          )}
          <FooterWrap>
            <Button bsStyle="attention" disabled={!selectedItem} onClick={this.handleClick} isShowLoader={isReporting}>
              Report
            </Button>
          </FooterWrap>
        </Wrap>
      </Modal>
    )
  }
}

export default ReportModal
