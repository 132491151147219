import React from "react"
import styled from "styled-components"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { Route, Switch } from "react-router-dom"
import BasicForm from "./BasicForm"
import TransactionForm from "./TransactionDetailsForm"
import CoordinationForm from "./CoordinationForm"
import TCAgentForm from "./TransactionAgentForm"
import EscrowForm from "./EscrowForm"
import SignPostageForm from "./SignageAndPostForm"
import FileUploadForm from "./FileUpload"
import {
  BASIC_FORM_ROUTE,
  DETAILS_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../Routes"

const Wrap = styled.div`
  margin-top: 24px;
`

const HeaderWrap = styled.div`
  height: 118px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  background: #364A7D;
  padding: 5px 4px 24px 0px;
  color: white;

  h1 {
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0 auto;
    width: 301px
  }
`

const FormComponent = ({
  ...props
}) => (
  <Wrap>
    <HeaderWrap>
      <h1>Not using TC?</h1>
      <p>Please add your file below and in SkySlope within 48 hours of acceptance</p>
    </HeaderWrap>
    <Switch>
      {/* <Route
        exact
        path={BASIC_FORM_ROUTE}
        render={properties => <BasicForm {...properties} {...props} />}
      />
      <Route
        exact
        path={DETAILS_FORM_ROUTE}
        render={properties => <TransactionForm {...properties} {...props} />}
      />
      <Route
        exact
        path={COORDINATION_FORM_ROUTE}
        render={properties => <CoordinationForm {...properties} {...props} />}
      />
      <Route
        exact
        path={TCAGENT_FORM_ROUTE}
        render={properties => <TCAgentForm {...properties} {...props} />}
      />
      <Route
        exact
        path={ESCROW_FORM_ROUTE}
        render={properties => <EscrowForm {...properties} {...props} />}
      />
      <Route
        exact
        path={SIGN_POST_FORM_ROUTE}
        render={properties => <SignPostageForm {...properties} {...props} />}
      />
      <Route
        exact
        path={UPLOAD_FILE_FORM_ROUTE}
        render={properties => <FileUploadForm {...properties} {...props} />}
      /> */}
    </Switch>
  </Wrap>
)

export default TransactionContainer(FormComponent)
