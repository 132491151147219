import React, { useEffect, useState } from "react"
import oldOffersContainer from "container/TechAssets"
import container from "container/OffersV2"
import { withRouter } from "react-router-dom"
import CoverSheet from "./CoverSheet"
import DocusignDocs from "./DocusignDocs"
import {
  OffersWrap,
  MenuWrap,
  FormAndFunctionWrap,
} from "./commonStyles"
import {
  Tabs,
  HeaderSectionCommon,
  MLSModal,
  CreateEnvelopeModal,
  Shimmer,
  DocumentShimmer,
  PubNubForOffersV2,
} from "./components"
import {
  BuyerInformation,
  PropertyInformation,
  DealTerms,
} from "./CoverSheet/Components"
import {
  Templates,
  Envelopes,
  OffersPackage,
} from "./DocusignDocs/Components"
import {
  COVERSHEET,
  DOCUMENTS,
  BUYER_AGENT_INFORMATION,
  PROPERTY_INFORMATION,
  DEAL_TERMS,
  TEMPLATES,
  ACTIVE,
  OFFER_PACKAGE,
  BUYER_INFORMATION_STEP,
  PROPERTY_INFORMATION_STEP,
  DEAL_TERMS_STEP,
} from "./consts"

const STEPS = [
  {
    value: "cover_sheet",
    label: "Step 1 | Cover Sheet",
  },
  {
    value: "documents",
    label: "Step 2 | Documents",
  },
]

const OffersV2 = ({
  currentActiveTab,
  currentSideNavTab,
  toggleBasicOfferDetailsModal,
  isBasicOfferDetailsModalOpen,
  toggleActiveTab,
  isCreateEnvelopeModalOpen,
  toggleCreateEnvelopeModal,
  fetchActiveEnvelopesFiles,
  fetchActiveEnvelopesFilesResponse,
  getOfferStatusMappings,
  getOfferPackage,
  getOfferPackageResponse,

  /**OLD OFFERS */
  getDealDetails,
  dealToView,
  getCurrentDealDetailsResponse,
  getOfferDocuments,
  getOfferDocumentsResponse,
  history,
  history: {
    location,
  },
}) => {
  const {
    isFetching: fetchingDealDetails,
  } = getCurrentDealDetailsResponse || {}

  const {
    isFetching: fetchingDocuments,
  } = getOfferDocumentsResponse || {}

  const {
    isFetching: fetchingActiveEnvelopes,
  } = fetchActiveEnvelopesFilesResponse || {}

  const {
    isFetcing: fetchingOfferPackage,
  } = getOfferPackageResponse || {}

  const getCurrentDealId = () => {
    const splitUrl = location?.pathname?.split("/") || ""
    const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
    return extractedId
  }

  useEffect(() => {
    //get the ID and perform the necessary actions
    const splitUrl = location?.pathname?.split("/") || ""
    console.log("splitUrl", splitUrl)
    const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
    const id = dealToView || extractedId
    const step = BUYER_INFORMATION_STEP
    if (id) {
      getDealDetails({
        id,
        step,
      })
    }
    getOfferStatusMappings()
  }, [])

  useEffect(() => {
    const current = `${currentActiveTab}_${currentSideNavTab}`
    const id = dealToView
    if (!fetchingDealDetails && id) {
      switch (current) {
        case `${COVERSHEET}_${BUYER_AGENT_INFORMATION}`:
          getDealDetails({
            id,
            step: BUYER_INFORMATION_STEP,
          })
          break
        case `${COVERSHEET}_${PROPERTY_INFORMATION}`:
          getDealDetails({
            id,
            step: PROPERTY_INFORMATION_STEP,
          })
          break
        case `${COVERSHEET}_${DEAL_TERMS}`:
          getDealDetails({
            id,
            step: DEAL_TERMS_STEP,
          })
          break
        case `${DOCUMENTS}_${TEMPLATES}`:
          if (!fetchingDocuments) {
            console.log("here yes 1")
            getOfferDocuments({
              payload: {
                offerId: id,
              },
            })
          }
          break
        case `${DOCUMENTS}_${ACTIVE}`:
          console.log("here yes 2")
          fetchActiveEnvelopesFiles({
            payload: {
              offerId: id,
            },
          })
          break
        case `${DOCUMENTS}_${OFFER_PACKAGE}`:
          getOfferPackage({
            id,
          })
          break
        default:
          break
      }
    }
  }, [currentActiveTab, currentSideNavTab, dealToView])


  return (
    <>
      <PubNubForOffersV2
        id={getCurrentDealId()}
      />
      <OffersWrap>
        <MenuWrap>
          <HeaderSectionCommon />
          <Tabs
            steps={STEPS}
            toggleTab={currentTab => toggleActiveTab(currentTab)}
            currentTab={currentActiveTab}
          />
          {currentActiveTab === COVERSHEET && (
            <CoverSheet />
          )}
          {currentActiveTab === DOCUMENTS && (
            <DocusignDocs />
          )}
          {/* {currentActiveTab === "components" && (
            <ComponentsTemp />
          )} */}
        </MenuWrap>
        <FormAndFunctionWrap>
          {/*Coversheet Forms*/}
          {currentActiveTab === COVERSHEET
            && currentSideNavTab === BUYER_AGENT_INFORMATION && (
              <>
                {fetchingDealDetails ? (
                  <Shimmer />
                ) : (
                  <BuyerInformation />
                )}
              </>
          )}
          {currentActiveTab === COVERSHEET
            && currentSideNavTab === PROPERTY_INFORMATION && (
              <>
                {fetchingDealDetails ? (
                  <Shimmer />
                ) : (
                  <PropertyInformation />
                )}
              </>
          )}
          {currentActiveTab === COVERSHEET
            && currentSideNavTab === DEAL_TERMS && (
              <>
                {fetchingDealDetails ? (
                  <Shimmer />
                ) : (
                  <DealTerms />
                )}
              </>
          )}

          {/*Documents Forms/Section*/}
          {currentActiveTab === DOCUMENTS
            && currentSideNavTab === TEMPLATES && (
              <>
                {fetchingDocuments ? (
                  <DocumentShimmer />
                ) : (
                  <Templates />
                )}
              </>
          )}
          {currentActiveTab === DOCUMENTS
            && currentSideNavTab === ACTIVE && (
              <>
                {fetchingActiveEnvelopes ? (
                  <DocumentShimmer />
                ) : (
                  <Envelopes />
                )}
              </>
          )}
          {currentActiveTab === DOCUMENTS
            && currentSideNavTab === OFFER_PACKAGE && (
            <>
              {fetchingOfferPackage ? (
                <DocumentShimmer />
              ) : (
                <OffersPackage />
              )}
            </>
          )}
        </FormAndFunctionWrap>
      </OffersWrap>
      {isBasicOfferDetailsModalOpen && (
        <MLSModal
          toClose={() => {
            toggleBasicOfferDetailsModal(false)
          }}
        />
      )}
      {isCreateEnvelopeModalOpen && (
        <CreateEnvelopeModal
          toClose={() => {
            toggleCreateEnvelopeModal(false)
          }}
        />
      )}
    </>
  )
}

export default withRouter(oldOffersContainer(container(OffersV2)))
