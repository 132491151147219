import React from "react"
import styled from "styled-components"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share"
import { addHttpInURl } from "services/Utils"
import Heading from "dumbComponents/common/Typography/Heading"
import { Arrow } from "@ui/Icons"
import DefaultIcon from "images/icons/web-icon.png"
import RefreshIcon from "images/icons/arrows-file-refresh.svg"
import InstaIcon from "images/icons/insta.png"

// Social Media Holder
const getSocialMediaIcon = (icon, size = 20) => {
  switch (icon) {
    case "facebook":
      return <FacebookIcon size={size} round />
    case "linkedin":
      return <LinkedinIcon size={size} round />
    case "twitter":
      return <TwitterIcon size={size} round />
    case "instagram":
      return <img src={InstaIcon} alt="Insta Icons" />
    default:
      return <img src={DefaultIcon} alt="icon" />
  }
}

const SocialMediaHolderWraper = styled.div`
  display: flex;
  margin-bottom: 10px;

  a {
    margin-right: 15px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
`

export const SocialMediaHolder = ({ socialMedia }) => (
  <SocialMediaHolderWraper>
    { socialMedia && Object.keys(socialMedia).map(key => (
      socialMedia[key].handle && (
        <a href={addHttpInURl(socialMedia[key].handle)} key={socialMedia[key].site} target="_blank">
          {getSocialMediaIcon(socialMedia[key].site, 30)}
        </a>
      )
    ))}
  </SocialMediaHolderWraper>
)

export const formatNextStep = (nextStep = "") => nextStep.replace(/_/g, " ")

export const getTypeMapping = (type) => {
  switch (type) {
    case "profile_image":
      return "profileImageUpload"
    case "agency_name":
      return "editBasicInfo"
    case "office_address":
      return "editBasicInfo"
    case "years_of_experience":
      return "experiences"
    case "specializations":
      return "expertise"
    case "about_agent":
      return "about"
    case "mls":
      return "sync-mls"
    case "experiences":
      return "experiences"
    case "interests":
      return "interest"
    case "languages":
      return "languages"
    default:
      return type
  }
}

export const getInitialValueBasedOnType = (type, agent) => {
  const {
    images,
    education,
    specializations,
    about_agent,
    mlsInfo,
    mlsLicense,
    mlsLicenseState,
    mlsPropertiesCount,
    interests,
    languages,
    experiences,
  } = agent || {}
  const profileImage = images && images.find(item => item.type === "profile") || {}
  switch (type) {
    case "profileImageUpload":
      return profileImage
    case "editBasicInfo":
      return agent
    case "education":
      return {
        education,
      }
    case "experiences":
      return { experiences }
    case "expertise":
      return specializations
    case "about":
      return about_agent
    case "sync-mls":
      return {
        mlsInfo,
        mlsLicense,
        mlsLicenseState,
        mlsPropertiesCount,
      }
    case "interest":
      return interests
    case "languages":
      return languages
    default:
      return null
  }
}

export const PanelHeading = ({ title }) => (
  <Heading
    type="h7"
    transform="uppercase"
    color="#354052"
    align="left"
    style={{
      margin: "15px 0px",
    }}
  >
    {title}
  </Heading>
)

export const LoaderWrap = styled.div`
  height: 400;
  display: flex;
  alignItems: center;
  justifyContent: center;
  flexDirection: center;
  text-align: center;
`
