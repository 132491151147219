import React, { useState } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  InputField,
  InputArea,
  HiddenInputField,
  InputTags,
} from "dumbComponents/OffersV3/CoverSheet/Components/InputFields"
import {
  BlueButton,
  WhiteButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  Form,
} from "@ui/antd"
import {
  FormWrap,
  CTAWrap,
  DIV,
  CountTracker,
  SuffixWrap,
} from "./styles"

const SendOfferForm = ({
  onFinish,
  onFinishFailed,
  toClose,
  children,
  sendingOfferPackage,
  propertyInformationObject,
  buyersAgentInformationObject,
}) => {
  const [subjectLength, setSubjectLength] = useState(0)
  const [messageLength, setMessageLength] = useState(0)

  const {
    currentState,
    form,
    listing_agents,
  } = propertyInformationObject || {}

  const convertStringToNewLine = (listingAgentName, buyerName, propertyAddress, agentName, agentEmail) => {
    const str = `Dear ${listingAgentName}, <br><br>I hope this email finds you well. I am writing on behalf of my clients, ${buyerName}, who are enthusiastic about the opportunity to present an offer for the property at - ${propertyAddress}.
      <br><br>Enclosed within this email, you will find the comprehensive offer package detailing the terms and conditions put forth by my clients.We believe that the offer accurately reflects their genuine interest in the property and their commitment to a seamless transaction.
      <br><br>Feel free to reach out if you have any questions. We sincerely value your time and attention to our proposal. My clients are eager to potentially own this exceptional property and anticipate a positive outcome.
      <br><br>Thank you so much, and we look forward to hearing from you.
      <br><br>Best regards,
      <br>${agentName}
      <br>${agentEmail}
      `

    let newMessage = ""
    newMessage = str.replace(/<br\s*\/?>/gi, "\n")
    return [str, newMessage]
  }

  const {
    agents,
    buyers,
  } = buyersAgentInformationObject || {}
  const address = form[currentState]
  let property_address = ""

  if (address.street) {
    property_address += `${address.street}, `
  }

  if (address.city) {
    property_address += `${address.city}, `
  }

  if (address.county) {
    property_address += `${address.county}, `
  }

  if (address.state) {
    property_address += `${address.state}, `
  }

  if (address.zipcode) {
    property_address += `${address.zipcode}`
  }

  const getInitialValues = () => {
    const intiValues = {
      from: agents[0].name,
      from_email: agents[0].email,
      to: listing_agents[0].name,
      to_email: listing_agents[0].email,
      cc: agents[0].name,
      cc_email: agents[0].email,
      offer_package_subject: `Submission of Buyer's Offer ${
        property_address ? `for ${property_address}` : ""
      }`,
      custom_message: convertStringToNewLine(
        listing_agents[0].name,
        buyers[0].name,
        property_address,
        agents[0].name,
        agents[0].email
      )[1],
      original_message: convertStringToNewLine(
        listing_agents[0].name,
        buyers[0].name,
        property_address,
        agents[0].name,
        agents[0].email
      )[0],
    }
    return intiValues
  }

  return (
    <FormWrap>
      <Form
        name="offers_send_offer_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
      // ref={this.formRef}
      >

        {/* <InputField
          name="from"
          inputType="text"
          placeholder="Your name"
          text="Your name*"
        /> */}
        <HiddenInputField name="from" />
        <HiddenInputField name="original_message" />

        {/* <InputField
          name="from_email"
          inputType="text"
          placeholder="Your email"
          text="Your email*"
        /> */}
        <HiddenInputField name="from_email" />
        
        {/* <InputField
          name="to"
          inputType="text"
          placeholder="Recipient's name"
          text="Recipient's name*"
        /> */}
        <HiddenInputField name="to" />

        <InputField
          name="to_email"
          inputType="text"
          placeholder="To"
          text="To*"
        />

        {/* <InputField
          name="cc"
          inputType="text"
          placeholder="Recipient's name"
          text="Recipient's name*"
        /> */}
        <HiddenInputField name="cc" />

        <InputTags
          name="cc_email"
          inputType="text"
          placeholder="CC"
          text="CC*"
        />

        <DIV>
          <InputField
            name="offer_package_subject"
            inputType="text"
            placeholder="Subject*"
            text="Subject*"
            maxLength={100}
            onChange={(e) => {
              const validText = e.target.value
              if (validText) {
                setSubjectLength(validText.length)
              }
            }}
          />
          <CountTracker>
            <p>{`${subjectLength} / 100`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          <InputArea
            name="custom_message"
            inputType="text"
            placeholder="Custom Message"
            maxLength={1000}
            // defaultValue={initial_message}
            onChange={(e) => {
              const validText = e.target.value
              if (validText) {
                setMessageLength(validText.length)
              }
            }}
          />
          <CountTracker>
            <p>{`${messageLength} / 1000`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          {children}
        </DIV>

        <CTAWrap>
          <Form.Item>
            <BlueButton
              style={{
                width: "100%",
              }}
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              {sendingOfferPackage ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Send Offer Package</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default SendOfferForm
