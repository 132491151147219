import React from "react"
import OffersV2 from "container/OffersV2"
import {
  Wrap,
  StyledInput,
} from "./styles"

const SearchBar = ({
  currentSearchText,
  setSearchText,
  customFunc,
  customFilterText,
}) => {
  return (
    <Wrap>
      <StyledInput
        placeholder="Search in Library"
        type="text"
        value={currentSearchText || customFilterText}
        onChange={(e) => {
          if (customFunc && typeof customFunc === "function")  {
            customFunc(e.target.value)
          } else {
            setSearchText(e.target.value)
          }
        }}
      />
    </Wrap>
  )
}

export default OffersV2(SearchBar)
