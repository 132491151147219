import { createSelector } from "reselect"
import { isEmpty } from "lodash"

const getDetails = profile => profile.feeds
  && profile.feeds.activities
  && profile.feeds.activities.details
const getnetworkActivities = profile => profile.feeds
  && profile.feeds.activities
  && profile.feeds.activities.networkActivities

const getCities = profile => profile.properties && profile.properties.cities
const getProperties = profile => profile.properties && profile.properties.properties

export const mapFeeds = createSelector(
  [getDetails, getnetworkActivities],
  (details = {}, networkActivities = []) => {
    if (networkActivities.length > 0) {
      const {
        propertyDetails,
      } = details
      const mappedData = []
      networkActivities.forEach((feed) => {
        if (feed.type_id === 15 || feed.type_id === 5) {
          const property = propertyDetails.find(item => item.basic_id === feed.basic_id)
          if (property) {
            mappedData.push({ ...feed, ...property })
          }
        }
      })
      return mappedData
    }
    return []
  }
)

export const getMappedPropertiesAndCity = createSelector(
  [getCities, getProperties],
  (cities = [], properties = {}) => {
    const citiesWithNoProperties = cities.filter(
      city => !isEmpty(city.cityActivePropertyIds) || !isEmpty(city.cityClosedPropertyIds)
    ) || {}
    const mappedProperties = citiesWithNoProperties.map((city) => {
      const { cityActivePropertyIds = [], cityClosedPropertyIds = [] } = city
      const propertyCount = (
        (cityActivePropertyIds && cityActivePropertyIds.length || 0)
        + (cityClosedPropertyIds && cityClosedPropertyIds.length || 0)
      )
      let cityPropertiesIds = []
      if (cityActivePropertyIds) {
        cityPropertiesIds = [...cityPropertiesIds, ...cityActivePropertyIds]
      }
      if (cityClosedPropertyIds) {
        cityPropertiesIds = [...cityPropertiesIds, ...cityClosedPropertyIds]
      }
      const cityProperties = cityPropertiesIds.map(
        id => properties[id]
      )
      return {
        ...city,
        propertyCount,
        cityProperties,
      }
    })
    return mappedProperties
  }
)

