import React from "react"
import { reduxForm } from "redux-form"
import SearchIcon from "images/icons/search-icon.svg"
import Box from "@ui/Box"
import Select from "react-select"
import {
  Wrapper,
  SearchInputWrap,
  TagsWrap,
  Tag,
  StyledButton,
  Footer,
  Header,
  SaveButton,
  ErrorText,
  BlankButton,
  ProfileCompletionButton,
} from "./commonStyles"

const LANGUAGES = [
  "Abkhaz", "Afar", "Afrikaans", "Akan", "Albanian", "Amharic", "Arabic", "Aragonese", "Armenian",
  "Assamese", "Avaric", "Avestan", "Aymara", "Azerbaijani", "Bambara", "Bashkir", "Basque",
  "Belarusian", "Bengali", "Bihari", "Bislama", "Bosnian", "Breton", "Bulgarian", "Burmese",
  "Catalan", "Valencian", "Chamorro", "Chechen", "Chichewa", "Chewa", "Nyanja", "Chinese",
  "Chuvash", "Cornish", "Corsican", "Cree", "Croatian", "Czech", "Danish", "Divehi", "Dhivehi",
  "Maldivian", "", "Dutch", "English", "Esperanto", "Estonian", "Ewe", "Faroese", "Fijian",
  "Finnish", "French", "Fula", "Fulah", "Pulaar", "Pular", "Galician", "Georgian", "German",
  "Greek", "Modern", "Gujarati", "Haitian", "Haitian Creole", "Hausa", "Hebrew", "Herero",
  "Hindi", "Hiri Motu", "Hungarian", "Interlingua", "Indonesian", "Interlingue", "Irish",
  "Igbo", "Inupiaq", "Ido", "Icelandic", "Italian", "Inuktitut", "Japanese", "Javanese",
  "Kalaallisut", "Greenlandic", "Kannada", "Kanuri", "Kashmiri", "Kazakh", "Khmer", "Kikuyu",
  "Gikuyu", "Kinyarwanda", "Kirghiz", "Kyrgyz", "Komi", "Kongo", "Korean", "Kurdish", "Kwanyama",
  "Kuanyama", "Latin", "Luxembourgish", "Letzeburgesch", "Luganda", "Limburgish", "Limburgan",
  "Limburger", "Lingala", "Lao", "Lithuanian", "Luba-Katanga", "Latvian", "Manx", "Macedonian",
  "Malagasy", "Malay", "Malayalam", "Maltese", "Marathi", "Marshallese", "Mongolian", "Nauru",
  "Navajo", "Navaho", "North Ndebele", "Nepali", "Ndonga", "Norwegian Nynorsk", "Norwegian",
  "Nuosu", "South Ndebele", "Occitan", "Ojibwe", "Ojibwa", "Old Church Slavonic", "Church Slavic",
  "Church Slavonic", "Old Bulgarian", "Old Slavonic", "Oromo", "Oriya", "Ossetian", "Ossetic",
  "Panjabi", "Punjabi", "Persian", "Polish", "Pashto", "Pushto", "Portuguese",
  "Quechua", "Romansh", "Kirundi", "Romanian", "Moldavian", "Moldovan",
  "Russian", "Sanskrit", "Sardinian", "Sindhi", "Northern Sami", "Samoan", "Sango",
  "Serbian", "Scottish Gaelic", "Gaelic", "Shona", "Sinhala", "Sinhalese", "Slovak",
  "Slovene", "Somali", "Southern Sotho", "Spanish", "Castilian", "Sundanese",
  "Swahili", "Swati", "Swedish", "Tamil", "Telugu", "Tajik", "Thai", "Tigrinya",
  "Tibetan Standard", "Tibetan", "Central", "Turkmen", "Tagalog", "Tswana", "Tonga",
  "Turkish", "Tsonga", "Tatar", "Twi", "Tahitian", "Uighur", "Uyghur", "Ukrainian",
  "Urdu", "Uzbek", "Venda", "Vietnamese", "Walloon", "Welsh", "Wolof", "Western Frisian",
  "Xhosa", "Yiddish", "Yoruba", "Zhuang", "Chuang"]
  
const finalLanguage = LANGUAGES.map(item => ({ label: item, value: item }))

class Languages extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues } = this.props
    this.state = {
      tagList: initialValues,
      isChanged: false,
    }
  }

  addItem = (item = "") => {
    const { tagList = [] } = this.state
    if (tagList.filter(x => x.toLocaleLowerCase() === item.toLocaleLowerCase()).length > 0) {
      this.setState({
        error: `${item} has already been added in the list.`,
      })
      return
    }
    if (item) {
      this.setState({
        tagList: [...tagList, item],
        search: "",
        error: "",
        isChanged: true,
      })
    } else {
      this.setState({
        error: "Please select an option",
      })
    }
  }

  handleInputType = (val) => {
    if (val !== "") {
      this.setState({
        search: val,
      })
    }
  }

  handleSave = () => {
    const { saveProfileUpdate } = this.props
    const { tagList } = this.state
    saveProfileUpdate({
      languages: [...tagList],
    })
  }

  removeItem = (item) => {
    const { tagList = [] } = this.state
    const itemIndex = tagList.indexOf(item)
    tagList.splice(itemIndex, 1)
    this.setState({
      tagList: [...tagList],
      isChanged: true,
    })
  }

  render() {
    const {
      handleSubmit,
      isSavingUpdates,
      isProfileCompletion,
      nextToNextStep,
      skipProfileCompletionStep,
      nextStep,
    } = this.props
    const { search, tagList, error, isChanged } = this.state
    return (
      <Wrapper>
        <Header>
          <h4>What languages do you speak?</h4>
        </Header>
        <Box d="flex" p="15px">
          <SearchInputWrap onSubmit={handleSubmit} flex="1" style={{ border: "none" }}>
            <img src={SearchIcon} alt="Search Icon" />
            <div style={{ width: "100%" }}>
              <Select
                name="search"
                component="input"
                type="text"
                value={search}
                placeholder="Search Language"
                isSearchable
                onChange={(item) => {
                  this.addItem(item.value)
                }}
                onInputChange={this.handleInputType}
                options={finalLanguage}
              />
            </div>
          </SearchInputWrap>
          <StyledButton
            type="button"
            bsStyle="secondary"
            width="95"
            onClick={() => { this.addItem(search) }}
          >
            Add +
          </StyledButton>
        </Box>
        <Box pl="20px">
          { error && (
            <ErrorText style={{ padding: "0px 62px", marginBottom: 0 }}>{error}</ErrorText>
          )}
        </Box>
        { tagList && tagList.length > 0 && (
          <TagsWrap>
            {tagList.map((item, index) => (
              <Tag
                key={`${item}-${index}`}
                title={item}
                handleClick={this.removeItem}
              />
            ))}
          </TagsWrap>
        )}
        <Footer
          d="flex"
          style={{
            justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
            padding: 15,
          }}
        >
          {isProfileCompletion && nextToNextStep && (
            <BlankButton onClick={() => { skipProfileCompletionStep(nextStep) }} normal>
              Skip
            </BlankButton>
          )}

          { isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              type="button"
              width="100px"
              isShowLoader={isSavingUpdates}
              onClick={this.handleSave}
              disabled={!isChanged}
            >
              Save
            </SaveButton>
          )}
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "PROFILE_LANGUAGES_FORM",
})(Languages)

