import React, { useState, useEffect } from "react";
import { Modal, Select, Checkbox, Form, Input, Button } from "antd";
import moment from "moment";
import DatesCardCarousel from "../DatesCardCarousel/index";
import "./styles.css";
import {
  Wrap,
  SubmittedContentWrap,
  WebContent,
  ModalHeader,
  DateTimeDesc,
  MessageText,
  PreviewWrapper,
  DateCardWrapper,
  Address,
  Number,
  Pipe,
  HouseDataWrap,
  RequestModalWrapper,
} from "./styles.js";
import queryString from "query-string";
import { ModalWrap, CustomAntModal } from "../customModalStyles.js";
import ConfrimTick from "../Icons/confirmTick.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import createToast from "dumbComponents/common/Toast/customToast";
import LoaderButton from "@ui/LoaderButton";
import { normalizePhone } from "../../../services/formUtils.js";

const formWrapper = {
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  width: "100%",
};

export const Index = ({
  openModal,
  onClose,
  listingPageResponse,
  postCreateATourInfo,
  isVisitingDateTime,
  address,
  pinCode,
  postCreateATourResponse,
  visitingDateTimeProps,
}) => {
  // ---- fetch is there visitingDate & visitngTime present, if all the info is there including the form set reqSubmitted to true ----

  let visitingDate = "";
  let visitingTime = "";

  const [requestSubmitted, setrequestSubmitted] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState(
    address && pinCode
      ? `I am interested in ${address}, ${pinCode}`
      : "I am interested"
  );
  // const [visitingDate, setVisitingDate] = useState();
  // const [visitingTime, setVisitingDate] = useState();
  const [visitingDateTime, setVisitingDateTime] = useState();

  useEffect(() => {
    setVisitingDateTime(visitingDateTimeProps);
  }, [visitingDateTimeProps]);

  const handleSelectedDateTime = (dateTime) => {
    setVisitingDateTime(dateTime);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?\s*[-\/.]?\s*\d{3}\s*[-\/.]?\s*\d{4,}$/.test(
      phone
    );
  };

  const query = queryString.parse(location.search);

  const handleUserDataForm = () => {
    if (isValidEmail(email) && isValidPhone(phone)) {
      const payload = {
        // agent_id: query.agentId ? parseInt(query.agentId, 10) : null,
        client_name: name,
        client_email: email,
        client_phone: phone,
        scheduled_at: visitingDateTime,
        client_message: message,
        mlsId: listingPageResponse?.data?.ListingId,
        mlsPropertyId: listingPageResponse?.data?.Id,
      };
      if (query.agentId) {
        payload.agent_id = parseInt(query.agentId, 10);
      }
      postCreateATourInfo(payload);
      setrequestSubmitted(true);
    } else {
      createToast("Enter valid data!", "error");
    }
  };

  const headerComponent1 = (
    <>
      <ModalHeader>Request a tour</ModalHeader>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );

  const headerComponent2 = (
    <>
      <ModalHeader>Confirm your tour</ModalHeader>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );

  const submitButton = {
    width: "100%",
    backgroundColor: "#5A5FF2",
    color: "white",
    height: "48px",
    border: "none",
  };

  return (
    <ModalWrap className="modalContainer">
      {isVisitingDateTime || visitingDateTime ? (
        <CustomAntModal
          title={headerComponent2}
          centered
          visible={openModal && !requestSubmitted}
          onCancel={onClose}
        >
          <RequestModalWrapper>
            <Form
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 28,
              }}
              style={{
                width: "100%",
                paddingRight: "10px",
              }}
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              autoComplete="off"
              classname="testForm"
            >
              <Form.Item
                label="Name"
                name="Name"
                rules={[
                  {
                    required: true,
                    message: "Enter Name",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Enter Email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: "Enter Phone",
                    pattern: /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?\s*[-\/.]?\s*\d{3}\s*[-\/.]?\s*\d{4}$/,
                  },
                ]}
                normalize={normalizePhone}
              >
                <Input
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Your Phone"
                  type="tel"
                />
              </Form.Item>

              <Form.Item
                label="Message"
                name="Message"
                rules={[
                  {
                    message: "Your Message",
                  },
                ]}
              >
                <MessageText
                  placeholder="Your Message"
                  style={{ height: "80px" }}
                  defaultValue={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></MessageText>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 28,
                  span: 28,
                }}
              >
                <LoaderButton
                  htmlType="submit"
                  disabled={
                    !name || !isValidEmail(email) || !isValidPhone(phone)
                  }
                  onClick={handleUserDataForm}
                  loaderOption={{
                    color: "white",
                  }}
                  style={submitButton}
                  isShowLoader={postCreateATourResponse.isFetching}
                >
                  Request a tour
                </LoaderButton>
              </Form.Item>
            </Form>
          </RequestModalWrapper>
        </CustomAntModal>
      ) : (
        <CustomAntModal
          title={headerComponent1}
          centered
          visible={openModal && !requestSubmitted}
          onOk={onClose}
          onCancel={onClose}
          width={500}
        >
          <RequestModalWrapper style={{ height: "520px" }}>
            <PreviewWrapper>
              {listingPageResponse?.data?.Media.length > 0 && (
                <img
                  src={listingPageResponse?.data?.Media[0]?.MediaURL}
                  width={120}
                  style={{ borderRadius: "10px" }}
                  alt="property"
                />
              )}
              <div style={{ marginLeft: "20px" }}>
                {listingPageResponse?.data?.UnparsedAddress && (
                  <Address>
                    {listingPageResponse?.data?.UnparsedAddress},{" "}
                    {listingPageResponse?.data?.PostalCode}
                  </Address>
                )}
                <HouseDataWrap>
                  <Number>{listingPageResponse?.data?.BedroomsTotal}</Number> bd
                  <Pipe>|</Pipe>
                  <Number>
                    {listingPageResponse?.data?.BathroomsTotalInteger}
                  </Number>{" "}
                  ba
                  <Pipe>|</Pipe>
                  <Number>{listingPageResponse?.data?.LivingArea}</Number> sqft
                </HouseDataWrap>
              </div>
            </PreviewWrapper>
            <hr />
            <DateTimeDesc>Select a time that works best for you.</DateTimeDesc>
            <DateCardWrapper
              style={{
                display: "block",
                marginRight: "20px",
                marginLeft: "16px",
                marginBottom: "16px",
              }}
            >
              <DatesCardCarousel
                buttonTitle="Next"
                onSelectedDateTime={handleSelectedDateTime}
                numberOfCards={3}
                showLine={true}
              />
            </DateCardWrapper>
          </RequestModalWrapper>
        </CustomAntModal>
      )}
      {requestSubmitted && (
        <CustomAntModal
          title=" "
          centered
          visible={requestSubmitted}
          onOk={onClose}
          onCancel={onClose}
          width={600}
        >
          <SubmittedContentWrap>
            <ConfrimTick />
            <p style={{ fontSize: "20px", marginTop: "20px" }}>
              Your tour request was sent.
            </p>
          </SubmittedContentWrap>
        </CustomAntModal>
      )}
    </ModalWrap>
  );
};

export default ListingPageContainer(Index);
