import { takeLatest, call, put, takeEvery, select } from "redux-saga/effects";
import { get } from "lodash";
import * as TechAssetsActions from "container/TechAssets/actions";
import customToast from "dumbComponents/OffersV3/components/Toast/customToast";
import { isSuccess, MoneyFormatter } from "services/Utils";
import * as BRBCAPI from "./api";
import * as BRBCActions from "./actions";

// FETCH BRBC STATUS

function* getBrbcStatus(action) {
  try {
    const { clientId, agentId, callback } = action.data;
    const res = yield call(BRBCAPI.fetchBrbcStatusAPI, agentId, clientId);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.fetchBRBCStatusAction.success(response));
      if(callback){
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.fetchBRBCStatusAction.failure(error));
  }
}

function* getPresignedBrbcDocs(action) {
  try {
    const { clientId, agentId } = action.data;
    const res = yield call(BRBCAPI.fetchPresignedDocs, agentId, clientId);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.fetchPresignedBrbcDocs.success(response));
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.fetchPresignedBrbcDocs.failure(error));
  }
}

function* sendBrbcReminder(action) {
  try {
    // const { payload } = action.data;
    console.log("at here", action.data);
    const res = yield call(BRBCAPI.sendBrbcReminder, action.data);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.sendBrbcReminderAction.success(response));
      customToast("Reminder sent");
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.error?.message}`, "error");
    yield put(BRBCActions.sendBrbcReminderAction.failure(error));
  }
}

function* uploadPresignedBrbcDoc(action) {
  try {
    const { agentId, clientId, url, name, callback } = action.data;
    const payload = {
      client_id: clientId,
      agent_id: agentId,
      documents: [
        {
          title: name,
          url: url,
        },
      ],
    };
    const res = yield call(BRBCAPI.addPresignedDocumentAPI, payload);

    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.uploadBrbcAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.uploadBrbcAction.failure(error));
  }
}

function* handleBrbcUpload(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
      clientId,
      agentId,
      callback,
    } = action.data;

    const signedDoc = yield fetch(
      `https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${file.name}`
    );
    const signedResJson = yield signedDoc.json();
    const myHeaders = new Headers({ "Content-Type": file.type });

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      // yield put(BRBCActions.uploadBrbcAction.success(response));
      yield call(uploadPresignedBrbcDoc, {
        data: {
          agentId: agentId,
          clientId: clientId,
          url: signedResJson.fileUrlAfterUpload,
          name: file.name,
          callback: callback,
        },
      });
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
  }
}

// BRBC COVERSHEET

function* fetchBRBCoversheetData(action) {
  try {
    const { clientId, agentId } = action.data;
    const res = yield call(BRBCAPI.fetchBrbcCoversheetAPI, agentId, clientId);

    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.fetchBrbcCoversheetAction.success(response));
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.fetchBrbcCoversheetAction.failure(error));
  }
}

function* addNewBrbcCoversheet(action) {
  try {
    const {
      agent_id,
      client_id,
      draft,
      state,
      type_of_representation,
      agent_firstname,
      agent_lastname,
      agent_license_number,
      brokerage_name,
      brokerage_license_number,
      coClients,
      client,
      callback,
    } = action.data;
    const payload = {
      agent_id,
      client_id,
      draft,
      state,
      type_of_representation,
      agent_firstname,
      agent_lastname,
      agent_license_number,
      brokerage_name,
      brokerage_license_number,
      coClients,
      client,
    };
    const res = yield call(BRBCAPI.addNewBrbcCoversheetAPI, payload);

    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.newBrbcCoversheetAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.newBrbcCoversheetAction.failure(error));
  }
}

// SEND BRBC ENVELOPE
function* sendBrbcEnvelope(action) {
  try {
    const {
      title,
      emailSubject,
      emailBody,
      envelopeIds,
      callback,
    } = action.data;
    const payload = {
      title,
      emailSubject,
      emailBody,
      envelopeIds,
    };
    const res = yield call(BRBCAPI.sendBrbcEnvelopeAPI, payload);

    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.sendBrbcEnvelopeAction.success(response));
      if (callback) {
        callback();
        return;
      }
      customToast("BRBC envelope sent");
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.sendBrbcEnvelopeAction.failure(error));
  }
}

// VOID BRBC

function* voidBrbcCoversheet(action) {
  try {
    const { envelopeId, reason, callback } = action.data;
    const payload = { envelopeId, reason };
    const res = yield call(BRBCAPI.voidBrbcAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.voidBrbcAction.success(response));
      customToast("BRBC voided");
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    console.log("here", error);
    yield put(BRBCActions.voidBrbcAction.failure(error));
  }
}

// VOID AND DUPLICATE BRBC

function* voidAndDuplicateBrbcCoversheet(action) {
  try {
    const { envelopeId, reason, callback } = action.data;
    const payload = { envelopeId, reason };
    const res = yield call(BRBCAPI.voidAndRestartBrbcAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.voidRestartBrbcAction.success(response));
      customToast("BRBC voided and duplicated");
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.voidRestartBrbcAction.failure(error));
  }
}

// SENDER VIEW BRBC DOC
function* senderViewBrbcDoc(action) {
  try {
    customToast(`Opening document.`);
    const { envelopeId } = action.data;
    const payload = { envelopeId };
    const res = yield call(BRBCAPI.senderViewAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.senderViewAction.success(response));
      window.open(response?.url, "_blank");
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.senderViewAction.failure(error));
  }
}

// RECEIVER VIEW
function* receiverViewBrbcDoc(action) {
  try {
    customToast(`Opening docusign.`);
    const { envelopeId } = action.data;
    const payload = { envelopeId };
    const res = yield call(BRBCAPI.receiverViewAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.receiverViewAction.success(response));
      window.open(response?.url, "_blank");
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.receiverViewAction.failure(error));
  }
}

// GET AGENT DETAILS
function* getAgentDetailsFromState(action) {
  try {
    const { agentId, state } = action.data;
    const res = yield call(BRBCAPI.getAgentDetailsFromStateAPI, agentId, state);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.getAgentDetailsFromStateAction.success(response));
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.getAgentDetailsFromStateAction.failure(error));
  }
}

// DELETE CO-BUYER
function* handleDeleteCoBuyer(action) {
  try {
    const { clientId, callback } = action.data;
    const res = yield call(BRBCAPI.deleteCoBuyerAPI, clientId);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.deleteCoBuyerAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.deleteCoBuyerAction.failure(error));
  }
}

// DELETE BRBC ENVELOPE
function* handleDeleteBrbcEnvelope(action) {
  try {
    const { client_id, agent_id, envelopeIds, callback } = action.data;
    const payload = {
      client_id,
      agent_id,
      envelopeIds,
    };
    const res = yield call(BRBCAPI.deleteBrbcEnvelopeAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.deleteBrbcEnvelopeAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.deleteBrbcEnvelopeAction.failure(error));
  }
}

// DELETE BRBC DOC
function* handleDeleteBrbcDoc(action) {
  try {
    const { agentId, clientId, documentId, callback } = action.data;
    const res = yield call(
      BRBCAPI.deleteUploadedBrbcAPI,
      agentId,
      clientId,
      documentId
    );
    if (isSuccess(res)) {
      const { response } = res.data;
      yield put(BRBCActions.deleteBrbcDocAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    customToast(`Error: ${error?.data?.response?.error}`, "error");
    yield put(BRBCActions.deleteBrbcDocAction.failure(error));
  }
}

export default function* main() {
  yield takeLatest(
    BRBCActions.fetchPresignedBrbcDocs.REQUEST,
    getPresignedBrbcDocs
  );
  yield takeLatest(
    BRBCActions.sendBrbcReminderAction.REQUEST,
    sendBrbcReminder
  );
  yield takeLatest(
    BRBCActions.newBrbcCoversheetAction.REQUEST,
    addNewBrbcCoversheet
  );
  yield takeLatest(BRBCActions.uploadBrbcAction.REQUEST, handleBrbcUpload);
  yield takeLatest(
    BRBCActions.fetchBrbcCoversheetAction.REQUEST,
    fetchBRBCoversheetData
  );
  yield takeLatest(BRBCActions.voidBrbcAction.REQUEST, voidBrbcCoversheet);
  yield takeLatest(
    BRBCActions.voidRestartBrbcAction.REQUEST,
    voidAndDuplicateBrbcCoversheet
  );
  yield takeLatest(
    BRBCActions.sendBrbcEnvelopeAction.REQUEST,
    sendBrbcEnvelope
  );
  yield takeLatest(BRBCActions.fetchBRBCStatusAction.REQUEST, getBrbcStatus);
  yield takeLatest(BRBCActions.senderViewAction.REQUEST, senderViewBrbcDoc);
  yield takeLatest(BRBCActions.receiverViewAction.REQUEST, receiverViewBrbcDoc);
  yield takeLatest(
    BRBCActions.getAgentDetailsFromStateAction.REQUEST,
    getAgentDetailsFromState
  );
  yield takeLatest(
    BRBCActions.deleteCoBuyerAction.REQUEST,
    handleDeleteCoBuyer
  );
  yield takeLatest(
    BRBCActions.deleteBrbcEnvelopeAction.REQUEST,
    handleDeleteBrbcEnvelope
  );
  yield takeLatest(
    BRBCActions.deleteBrbcDocAction.REQUEST,
    handleDeleteBrbcDoc
  );
}
