import React from "react"
import styled from "styled-components"
import HomeIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/HomeBlue.svg"
import TeamProfileIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/TeamProfileMarineBlue.svg"
import TransactionCoordinationIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/TCBlueV2.svg"
import ProductsIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/ProductsMarineBlue.svg"
import FinancingIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/FinancingMarineBlue.svg"
import ProductsandServicesIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/TechAndAssetsMarineBlue.svg"
import LeaderboardIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/LeaderboardMarineBlue.svg"
import AcceleratorIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/AccelaratorMarineBlue.svg"
import CRMIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/CRMMarineBlue.svg"
import RecruitIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/RecruitmentMarineBlue.svg"
import ResourcesIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/ResourcesMarineBlue.svg"
import NewsFeedIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/CommunityMarineBlue.svg"
import NotificationIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/NotificationsMarineBlue.svg"
import JobsPortalIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/JobsMarineBlue.svg"
import FindAnAgentIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/FindAnAgentMarineBlue.svg"
import AcademyIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/AcademyMarineBlue.svg"
import MarketplaceIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/MarketplaceMarineBlue.svg"
import ClaimedIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/InboundMarineBlue.svg"
import SubmittedIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/OutboundMarineBlue.svg"
import NewRecruitmentIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/NewRecruitmentMarineBlue.svg"
import NewAssetsIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/NewAssetsBlue.svg"
import WellnessIcon from "images/Graphics/SidebarAssets/MarineBlueIcons/Wellness.svg"
const Wrap = styled.div`
`
const LINK = `${CLOUDFRONT}/OFFERS_V2`

const DividerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
    <path d="M1 0V42" stroke="#A5A8FF" />
  </svg>
)

const CheckedIcon = () => (
  <Wrap>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M14.6716 5.05859L9.00462 10.7256L6.17188 7.89209" stroke="#22B458" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4453 10.1295V13.8C15.4453 14.67 14.7403 15.375 13.8703 15.375H4.27031C3.40031 15.375 2.69531 14.67 2.69531 13.8V4.2C2.69531 3.33 3.40031 2.625 4.27031 2.625H9.07031" stroke="#22B458" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </Wrap>
)

const addIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/addIcon.svg"
const sideArrowIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/sideArrowIcon.svg"
const dropdownIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/dropdownIcon.svg"
const lightBulbIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/offers_lightBulb.svg"
const formAddIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/offers_add_icon.svg"
const dividerIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/dividerBlue.svg"
const threeDotMoreIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/threeDotMore.svg"
const eyeIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/viewIcon.svg"
const shareIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/sendIcon.svg"

const closeIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/close.svg"
const checkIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/check.svg"
const infoIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/infoIcon.svg"
const trashIcon = `${LINK}/Trash.svg`
const blueCloseIcon = `${LINK}/Close.svg`

const AddIcon = () => (
  <div>
    <img src={addIcon} alt="add" />
  </div>
)

const SideArrowIcon = () => (
  <div>
    <img src={sideArrowIcon} alt="side arrow" />
  </div>
)

const DropdownIcon = () => (
  <div>
    <img src={dropdownIcon} alt="dropdown" />
  </div>
)

const LightBulbIcon = () => (
  <div>
    <img src={lightBulbIcon} alt="light bulb" />
  </div>
)

const FormAddIcon = ({
  height,
  width,
}) => (
  <div>
    <img src={formAddIcon} alt="form add" height={height} width={width} />
  </div>
)

const DivideIcon = () => (
  <div>
    <img src={dividerIcon} alt="divider icon" height={18} />
  </div>
)

const ThreeDotMoreIcon = () => (
  <div>
    <img src={threeDotMoreIcon} alt="three dot icon" height={18} />
  </div>
)

const EyeIcon = () => (
  <div>
    <img src={eyeIcon} alt="Eye Icon" />
  </div>
)

const ShareIcon = () => (
  <div>
    <img src={shareIcon} alt="Share Icon" />
  </div>
)

const TrashIcon = () => (
  <div>
    <img src={trashIcon} alt="Trash Icon" />
  </div>
)

const CloseIcon = () => (
  <div>
    <img src={closeIcon} alt="close" />
  </div>
)

const BlueCloseIcon = () => (
  <div>
    <img src={blueCloseIcon} alt="close" />
  </div>
)

export {
  HomeIcon,
  TeamProfileIcon,
  TransactionCoordinationIcon,
  FinancingIcon,
  ProductsandServicesIcon,
  LeaderboardIcon,
  AcceleratorIcon,
  CRMIcon,
  ProductsIcon,
  ResourcesIcon,
  RecruitIcon,
  NewsFeedIcon,
  JobsPortalIcon,
  NotificationIcon,
  FindAnAgentIcon,
  AcademyIcon,
  MarketplaceIcon,
  ClaimedIcon,
  SubmittedIcon,
  NewRecruitmentIcon,
  NewAssetsIcon,
  WellnessIcon,

  /**NEW*/
  CloseIcon,
  BlueCloseIcon,
  TrashIcon,
  ShareIcon,
  EyeIcon,
  ThreeDotMoreIcon,
  DivideIcon,
  FormAddIcon,
  LightBulbIcon,
  DropdownIcon,
  SideArrowIcon,
  AddIcon,
  blueCloseIcon,
  trashIcon,
  infoIcon,
  checkIcon,
  CheckedIcon,
  DividerIcon,
  closeIcon,
}
