import { Tooltip } from "antd"
import React from "react"

const OffersTooltip = ({
  children,
  title,
}) => (
  <Tooltip
    title={title}
    color="#C6C6FB"
    // getPopupContainer={(ele) => {
    //   console.log(ele)
    // }}
    overlayStyle={{
      borderRadius: "100px",
      color: "#303030",
    }}
  >
    {children}
  </Tooltip>
)

export default OffersTooltip
