import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as ReferralActions from "container/Referral/actions"
import * as Actions from "./actions"

const mapStateToProps = (state) => {
  const {
    recruitAndEarnV2Reducer,
    referral,
    realty,
    app,
  } = state
  const { sendBrokerInviteResponse } = referral
  const { profile_name, id, firstname } = app.user

  return {
    ...realty,
    ...recruitAndEarnV2Reducer,
    sendBrokerInviteResponse,
    userName: profile_name || id,
    userId: id,
    firstname,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleMilestoneInfoModal: Actions.toggleMilestoneInfoModalAction.call,
      getReferralCumulativeData: Actions.getReferralCumulativeDataAction.request,
      toggleFormModal: Actions.toggleRecruitAndEarnFormAction.call,
      inviteBroker: ReferralActions.inviteBrokerAction.request,
      toggleShareModal: Actions.toggleRecruitAndEarnShareModalAction.call,
      toggleRecruitAndEarnForm: Actions.toggleRecruitAndEarnFormViewAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
