import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "NAVBAR"

export const startTabsSpotlightFlowAction = createSimpleCreator(BASE, "START_NAVBAR_SPOTLIGHT_FLOW")
export const finishTabsSpotlightFlowAction = createSignalAction(BASE, "FINISH_NAVBAR_SPOTLIGHT_FLOW")

// API Actions
export const fetchNotificationAction = createSignalAction(BASE, "FETCH_NOTIFICATION")
export const fetchNotificationUnreadCountAction = createSignalAction(BASE, "FETCH_UNREAD_NOTIFICATIONS")
export const togglePressReleaseBannerAction = createSimpleCreator(BASE, "TOGGLE_PRELEASE_BANNER")
export const notificationViewedAction = createSignalAction(BASE, "NOTIFICATION_VIEW_ACTION")

export const getFeedByHomeClickAction = createSimpleCreator(BASE, "CLICKED_ON_HOME")
