import React from "react"
import TooltipBody from "./TooltipBody"

const zIndexFix = {
  styles: {
    options: {
      zIndex: 10000,
    },
  },
  offset: 0
}

export const getIntroModalText = (user) => {
  if (user && !user.isUserOnboarded) {
    return (
      <>
        <br />
          Welcome to Radius,
        <br />
        <br />
          Let’s show you around.
        <br />
      </>
    )
  }
  if (user && user.isUserOnboarded) {
    return (
      <>
        <br />
          Welcome back! We’ve refreshed our interface,
        <br />
        <br />
          Let’s show you around.
        <br />
      </>
    )
  }
}

export const createSteps = (user) => {
  const {
    isUserOnboarded,
    isSubscribedToAnyISAPlan,
    isSubscribedToBrokerPlan,
  } = user
  const steps = []
  // For existing users, isa, referral and realty
  if (isUserOnboarded) {
    steps.push(
      {
        target: ".onboading-nav-home-referral",
        content: (<TooltipBody
          header="Left Navigation"
          text="You can now easily navigate between pages using this left navigation. You are currently on Community"
        />),
        placementBeacon: "right",
        placement: "auto",
        disableBeacon: true,
        ...zIndexFix,
      },
    )
    // for realty users
    if (isSubscribedToBrokerPlan) {
      steps.push(
        {
          target: ".onboading-nav-realty",
          content: (<TooltipBody
            header="Realty"
            text="Easily access the print store, KvCore, and more all in one place."
          />),
          placementBeacon: "right",
          placement: "auto",
          disableBeacon: true,
          ...zIndexFix,
        },
      )
    }
    if (isSubscribedToAnyISAPlan) {
      steps.push(
        {
          target: ".onboading-nav-advance",
          content: (<TooltipBody
            header="Assist"
            text="Now, easily access your submitted and qualified leads plus your non-assist clients all from the home page."
          />),
          placementBeacon: "right",
          placement: "auto",
          disableBeacon: true,
          ...zIndexFix,
        },
      )
    } else {
      // for non-subsribers
      steps.push(
        {
          target: ".onboading-nav-advance",
          content: (<TooltipBody
            header="Advanced"
            text="Learn more about how our team can qualify your leads with Assist and what we provide on our Realty"
          />),
          placementBeacon: "right",
          placement: "auto",
          disableBeacon: true,
          ...zIndexFix,
        },
      )
    }
    steps.push(
      {
        target: ".onboading-nav-learn",
        content: (<TooltipBody
          header="Learn (New!)"
          text="Access our blogs and Academy where you can take courses and training from top coaches and our team."
        />),
        placementBeacon: "right",
        placement: "auto",
        disableBeacon: true,
        ...zIndexFix,
      },
      {
        target: ".onboading-nav-home-header",
        content: (<TooltipBody
          header="Home"
          text="You can go back to this current view from any other page by clicking home"
        />),
        placementBeacon: "top",
        placement: "auto",
        disableBeacon: true,
        ...zIndexFix,
      },
    )
    
    return steps
  }
  // for new users
  steps.push(
    {
      target: ".onboading-nav-home",
      content: (<TooltipBody
        header="Explore the community"
        text="This is where you can explore, post, engage, and search for agents."
      />),
      placementBeacon: "right",
      placement: "right",
      disableBeacon: true,
      ...zIndexFix,
    },
    {
      target: ".onboading-nav-referral",
      content: (<TooltipBody
        header="Exchange referrals"
        text="Send and receive referrals with other agents on our marketplace here!"
      />),
      disableBeacon: true,
      placementBeacon: "right",
      placement: "right",
      ...zIndexFix,
    },
    {
      target: ".onboading-nav-assist",
      content: (<TooltipBody
        header="Assist"
        text="Get access to Assist. Our expert team that qualifies your leads on your behalf via call, text, and email."
      />),
      disableBeacon: true,
      placementBeacon: "right",
      placement: "right",
      ...zIndexFix,
    },
    {
      target: ".onboading-nav-realty",
      content: (<TooltipBody
        header="Introducing Radius Brokerage"
        text="Built to empower modern agents to truly be independent. Find out why agents are quickly making the switch."
      />),
      disableBeacon: true,
      placementBeacon: "right",
      placement: "right",
      ...zIndexFix,
    },
    {
      target: ".onboading-nav-profile",
      content: (<TooltipBody
        header="Now, let’s get you set up"
        text="Click on the image icon to start updating your profile"
      />),
      disableBeacon: true,
      placementBeacon: "top",
      placement: "bottom",
      ...zIndexFix,
    },
  )
  return steps
}
