import React from "react"
import styled from "styled-components"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { isVideoUrl, getEmbedConverter } from "services/Utils"

const SnippetWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  border: solid 1px #a5a8ad;
  border-radius: 2px;
  margin-bottom: 10px;
`

const SnippetAnchor = styled.a`
  text-decoration: none;
`

const SnippetImage = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    margin: 0px auto;
    display: block;
    // position: relative;
    // top: 50%;
    // transform: translateY(-50%);
  }
`

const SnippetDetail = styled.div`
  background-color: #f8f9fa;
  padding: 5px;
`

const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  width: 405px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Snippet = ({ snippet, shared }) => (
  <SnippetWrapper>
    <SnippetAnchor href={snippet.url} target="_blank">
      {snippet.url && isVideoUrl(snippet.url) && (
        <iframe
          title="Video"
          width="430"
          height="250"
          src={snippet.url && getEmbedConverter(snippet.url)}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      )}
      {!(snippet.url.includes("youtube") || snippet.url.includes("youtu.be")) && snippet.image && (
        <SnippetImage>
          <img src={snippet.image} alt="" onError={e => (e.target.style.display = "none")} alt={snippet.title}/>
        </SnippetImage>
      )}
      <SnippetDetail>
        <StyledParagraph size="12" fontWeight={500}>
          {snippet.title}
        </StyledParagraph>
        <StyledParagraph size="12">{snippet.url}</StyledParagraph>
      </SnippetDetail>
    </SnippetAnchor>
  </SnippetWrapper>
)

export default Snippet
