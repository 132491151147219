import React from "react"
import OffersV2 from "container/OffersV2/index"
import { ListedDocsWrap, DocsTypeTitle } from "../commonStyle"
import OfferDoc from "../DocListingGeneric"

const AdditionalDocs = ({
  docs,
  typeOfDocument = "MY DOCUMENTS",
}) => (
  <ListedDocsWrap
    padding
  >
    <DocsTypeTitle>{typeOfDocument}</DocsTypeTitle>
    <OfferDoc
      docs={docs}
    />
  </ListedDocsWrap>
)

export default OffersV2(AdditionalDocs)
