import React from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import createToast from "@ui/Toast"
import GoogleIcon from "images/home-v7/google-login.png"
import { LoadScript } from "services/Utils"

const scopes = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/contacts.readonly",
]

const StyledButton = styled(Button)`
  border: none;
  box-shadow: 0px 1px 3px rgb(180, 180, 180);
  border-radius: 24.5px;

  padding: 8px 10px 8px 10px;
  color: #777;
  width: 350px;
  height: 45px;
  justify-content: center;
  font-weight: 300;
  text-align: left;
  background: #fff;
  display: flex;
  align-items: center;
  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
`

const StyledImg = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 15px;
`

const SocialButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #E4E4E4;
  cursor: pointer;
  color: #303030;

  > span {
    font-size: 18px;
  }

  > img {
    max-height: 25px;
    margin-right: 12px;
  }

  ${props => props.isGoogle && `
    background: #E4E4E4;
    color: ${colors.black};
  `}

  ${props => props.isApple && `
    background: #000;
    color: #fff;
  `}
`

class GoogleSignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gapiLoaded: false,
    }
  }

  componentDidMount() {
    this.loadScript();
  }

  loadScript = () => {
    LoadScript("https://apis.google.com/js/client.js", () => {
      this.setState({ gapiLoaded: true })
    })
  };

  checkAuth() {
    window.gapi.auth.authorize({
      client_id: GOOGLE_CLIENTID,
      scope: scopes,
      response_type: "code",
      access_type: "offline",
      approval_prompt: "force",
      redirect_uri: "postmessage",
      immediate: false,
      include_granted_scopes: false,
    }, (data) => {
      window.handleAuthResult(data)
    })
  }

  googleSignIn = () => {
    if (window.gapi.client) {
      window.gapi.client.setApiKey(GOOGLE_APIKEY)
      window.setTimeout(this.checkAuth, 1)
    }
  }

  render() {
    const {
      newUI,
      isModal,
      customButtonStyles,
    } = this.props
    const { gapiLoaded } = this.state
    if (gapiLoaded) {
      window.handleAuthResult = (authResult) => {
        const data = { code: authResult.code, redirect_uri: "postmessage" }
        const { signIn } = this.props
        if (authResult.error) {
          const err = "If you are in incongito mode, please switch to normal mode and try logging in with Gmail again"
          createToast(err, "error")
        }
        signIn(data)
      }
    }
    if (isModal) {
      return (
        <SocialButton
          onClick={this.googleSignIn}
          style={{
            ...customButtonStyles || {},
          }}
        >
          <img src={GoogleIcon} alt="Google Login" />
          <span>
            Continue with Google
          </span>
        </SocialButton>
      )
    }
    return (
      <StyledButton
        newUI={newUI}
        onClick={this.googleSignIn}
        style={{
          ...customButtonStyles || {},
        }}
      >
        <StyledImg
          src="//d2fedz0by71ckz.cloudfront.net/images/google_logo.png"
          alt="Google sign in"
        />
        Login with Google
      </StyledButton>
    )
  }
}

export default GoogleSignIn
