import Modal from "@ui/Modal"
import React from "react"
import CloseIcon from "images/Close_7.svg"
import styled from "styled-components"
import AutoComplete from "@ui/GoogleAutoCompleteV2"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import BellIcon from "images/Graphics/RadiusRoomsAssets/Bell.svg"
import Rooms from "container/Rooms"
import Loader from "@ui/Loader"
import {
  RoomsButton,
  CTAWrap,
} from "../CreateRoomV2/common"
import { ATC } from "@fonts"

const Wrap = styled.div`
  height: 487px;
  width: 955px;
  border-radius: 9px;
  padding: 60px;

  display: flex;
  flex-direction: column;

  position: relative;
`

const Title = styled.div`
  p {
    font-family: ${ATC};
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
`

const StoredLocations = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
`

const Location = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: space-between;
`

const LocationCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 18px 0px;
  gap: 12px;
  position: relative;
  background: white;
  padding: 15px;

  width: 370px;
  border-radius: 6px;
`

const AttendeeCount = styled.div`
  height: 34px;
  width: 334px;
  border-radius: 4px;
  background: rgba(241, 243, 248, 1);
  padding: 8px;

  p {
    font-family: ${ATC};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
`

const SpeakerWrap = styled.div``

const InputWrap = styled.div``

const Div = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }

  img {
    width: 19px;
    height: 21px;
  }

  small {
    cursor: pointer;
  }
`

const InviteAgentModal = ({
  toClose,
  locations,
  handleCity,
  handleRemoveLocation,
  fetchAgentSearchResponse,
  searchText,
  setSearchText,
}) => {
  const {
    isFetching: fetchingAgents,
  } = fetchAgentSearchResponse || {}

  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      modalStyles={{
        backgroundColor: colors.dreamWhite,
        padding: "20px",
        height: "max-content",
        minHeight: "230px",
      }}
      dialogStyles={{
        width: "max-content",
        borderRadius: "6px",
      }}
      overlayStyles={{
        zIndex: 9991,
      }}
    >
      <Wrap>
        <Title>
          <p>Invite Attendees</p>
        </Title>
        <InputWrap>
          <AutoComplete
            id="cities"
            clearInput
            types={["geocode"]}
            location={loc => handleCity(loc, 0)}
            value={searchText}
            placeholder="Notify everyone from a location"
            handleChangeInput={e => setSearchText(e.target.value)}
            borderColor={colors.white}
            showLoader={false}
            styles={{
              height: "49px",
              borderRadius: "0px",
              background: colors.white,
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "25px",
              width: "100%",
            }}
          />
        </InputWrap>
        <SpeakerWrap>
          {fetchingAgents ? (
            <Loader />
          ) : (
            <>
              {(locations && locations.length > 0) && (
                <StoredLocations>
                  {
                    locations.map(item => (
                      <LocationCard>
                        <Location>
                          <Div>
                            <img src={BellIcon} alt="bell-icon" />
                            <p>
                              {item.city ? (
                                <>
                                  {`${item.city}, ${item.state}`}
                                </>
                              ) : (
                                <>
                                  {`${item.state}`}
                                </>
                              )}
                            </p>
                          </Div>
                          <Div>
                            <small
                              onClick={() => handleRemoveLocation(item)}
                              onKeyDown={() => handleRemoveLocation(item)}
                              style={{
                                position: "absolute",
                                right: "30px",
                              }}
                            >
                              <img src={CloseIcon} alt="closeicon" />
                            </small>
                          </Div>
                        </Location>
                        <AttendeeCount>
                          <p>
                            {`${item.attendeeCount} agents will be notified.`}
                          </p>
                        </AttendeeCount>
                      </LocationCard>
                    ))}
                </StoredLocations>
              )}
            </>
          )}
        </SpeakerWrap>
        <CTAWrap
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            width: "max-content",
          }}
        >
          <RoomsButton
            type="button"
            onClick={toClose}
          >
            <p>Confirm</p>
          </RoomsButton>
        </CTAWrap>
      </Wrap>
    </Modal>
  )
}

export default Rooms(withRouter(InviteAgentModal))
