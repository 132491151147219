import React, { useState } from "react"
import OffersV2 from "container/OffersV2/index"
import {
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import { EyeIcon, ShareIcon } from "dumbComponents/OffersV2/icons"
import SearchBar from "../SearchBar"
import { Wrap, SubmitWrap } from "./style"
import CheckboxList from "../CheckboxList/index"

const DocsUI = ({
  docs,
  handleSubmit,
}) => {
  const [filterText, setFilterText] = useState("")

  const OptionList2 = []

  const MainListV2 = [

    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: () => console.log("clicked view"),
      showDivide: false,
    },
  ]

  return (
    <Wrap flex>
      <CheckboxList
        list={docs}
        onClick={e => console.log("clicked", e.target.value)}
        dropdownList={OptionList2}
        mainList={MainListV2}
        customFilterText={filterText}
        hideCheckBox
      />
    </Wrap>
  )
}

export default OffersV2(DocsUI)
