import React from "react"
import styled from "styled-components"
import colors from "@colors"

const LocationContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LocationWrapper = styled.div`
  padding: 6px;
  background-color: #f8f9fa;
  border: 1px solid ${colors.borderColor};
  color: ${colors.tagInactive};
  font-size: 14px;
  font-weight: 300;
  border-radius: 18px;
  display: flex;
  align-items: center;
  width: ${props => props.fit ? "fit-content" : "100%"};
  
  & > input {
    outline: none;
    background-color: inherit;
    border: none;
    width: 100%;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: inherit;
      font-weight: inherit;
    }
  }
`

const LocationIcon = styled.i`
  color: ${colors.primaryInactiveColor};
  margin: 0 6px 0 4px;
`

const RoundedInput = (props) => {
  const { iconClassName, children, fit, className } = props
  return (
    <LocationContainer className={className}>
      <LocationWrapper fit={fit}>
        <LocationIcon className={iconClassName} />
        {children}
      </LocationWrapper>
    </LocationContainer>
  )
}

export default RoundedInput
