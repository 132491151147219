import * as React from "react"

const CommissionTracker = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 5.68506V9.68506"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.68506H8C7.44772 9.68506 7 10.1328 7 10.6851V14.6851C7 15.2373 7.44772 15.6851 8 15.6851H10C10.5523 15.6851 11 15.2373 11 14.6851V10.6851C11 10.1328 10.5523 9.68506 10 9.68506Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.6851V17.6851"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 3.68506V5.68506"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 5.68506H16C15.4477 5.68506 15 6.13277 15 6.68506V12.6851C15 13.2373 15.4477 13.6851 16 13.6851H18C18.5523 13.6851 19 13.2373 19 12.6851V6.68506C19 6.13277 18.5523 5.68506 18 5.68506Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 13.6851V16.6851"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.68506V21.6851H21"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CommissionTracker
