import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Reporting from "../Icons/NewIcons/Reporting"
import CommissionTracker from "../Icons/NewIcons/CommissionTracker"

const HomeTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab pt="4px" isActive={routeArray[1] === "dashboard"}>
    <div className="icon-block-new">
      {
        isBroker ? <Reporting /> : <CommissionTracker />
      }
    </div>
    <div className="text-block">
      <NavLink
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_dashboardRAR_web")
          }
        }}
        to={isBroker ? "/realty/dashboard" : "/realty/promos?type=dashboard"}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        isActive={routeArray[1] === "dashboard"}
      >
        {isBroker ? "Reporting" : "Commission Tracker"}
        {/* <BetaHighlight>
          Beta
        </BetaHighlight> */}
      </NavLink>
    </div>
  </InnerTab>
)

export default HomeTab
