import React, { useState, useEffect } from "react"
import {
  File,
} from "@ui/Form"
import { toast } from "react-toastify"
import colors from "@colors"
import root from "window-or-global"
import query from "query-string"
import { required } from "services/formUtils"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import UploadIcon from "images/icons/upload_icon.png"
import DeleteIcon from "images/icons/dustbinicon.png"
import DocumentIcon from "images/icons/document.png"
import Header from "../Common/Header"
import SubmitButton from "../Common/SubmitButton"
import {
  // Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
} from "../Common/CommonStyles"
import {
  backButtonUrl,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"
import {
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  SUCCESS_ROUTE,
  COORDINATION_FORM_ROUTE,
  DETAILS_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../../Routes"

const Wrap = styled.div`
`

const DescriptionWrap = styled.div`
  width: 490px;
  margin-top: -29px;
  margin-bottom: 24px;

  p {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`

const FieldWrap = styled.div`
  height: 174px;
  // width: 490px;
  // border-radius: 16px;
  // border: 1px solid #96A3BD;
  // background: #F1F3F8;
  background: #FFFFFF;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);

  & input[type="file"] {
    display: none;
  }
`

const CustomLabel = styled.label`
  height: 36px;
  width: 138px;
  border-radius: 100px;
  padding: 10px 14px 10px 14px;
  background: ${colors.marineBLue};
  position: relative;
  top: 63px;
  cursor: pointer;
  display: block;
  margin: auto;
  ${props => props.disabled && `
    background: grey;
    cursor: not-allowed;
  `}

  p {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    display: flex;

    span img {
      position: relative;
      left: 8px;
      height: 14px;
    }
  }
`

const FileUploadIndicator = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-top: 17px;
  position: relative;
  visibility: hidden;
  height: 36px;
  margin-bottom: -36px;
  left: 4px;

  ${props => props.toggleVisibility && `
    visibility: visible;
   `}
`

const FilesWrap = styled.div``

const FilesListWrap = styled.ul``

const FileLi = styled.li`
   display: flex;
   height: 46px;
  //  width: 490px;
   justify-content: space-between;
`

const FileName = styled.p`
 font-family: DM Sans;
 font-size: 17px;
 font-style: normal;
 font-weight: 400;
 line-height: 22px;
 letter-spacing: 0px;
 text-align: left;
 position: relative;
 left: 10px;
 top: 4px;
`

const NameDiv = styled.div`
 display: flex;
 flex-direction: row-reverse;
 align-items: flex-start;

`

const DeleteIconEle = styled.img`
  height: 27px;
  cursor: pointer;
`

const DocumentIconEle = styled.img`
 height: 35px;
`

const stageUpdate = (
  tcStageUpdate,
  id, history,
  isNewListing,
  hasDoneUploadFileStage,
  hasAlreadyCompletedDeal,
  isCoListed,
  // isUsingDealTC,
) => {
  // TODO: MIGHT REQUIRE CHANGE
  const getRoute = () => {
    if (isNewListing) {
      return "/realty/transaction-coordination?filter=active"
    }

    if (hasAlreadyCompletedDeal) return "/realty/transaction-coordination"

    return SUCCESS_ROUTE
  }

  if (hasDoneUploadFileStage) {
    tcStageUpdate({
      deal_id: id,
      history,
      currentStage: "completed",
      nextForm: getRoute(),
      isFinal: true,
      isNewListing,
      doNotShowSuccessPage: false,
      triggerSlackNotification: !isCoListed && !hasAlreadyCompletedDeal,
    })
  } else {
    let slackTriggered = false
    if (!isNewListing) {
      setTimeout(() => {
        tcStageUpdate({
          deal_id: id,
          history,
          currentStage: "completed",
          includeSuccess: true,
          nextForm: getRoute(),
          isFinal: true,
          noReRoute: false,
          doNotShowSuccessPage: false,
          isNewListing,
          triggerSlackNotification: !isCoListed,
        })
      }, 3000) // Bugfix. There was a race condition between these two apis.
      slackTriggered = true
    }

    tcStageUpdate({
      deal_id: id,
      history,
      currentStage: "upload_files",
      includeSuccess: true,
      nextForm: getRoute(),
      isFinal: true,
      isNewListing,
      noReRoute: !isNewListing,
      doNotShowSuccessPage: false,
      triggerSlackNotification: (!slackTriggered && !isCoListed),
    })
  }

  // if (hasDoneUploadFileStage) {
  //   tcStageUpdate({
  //     deal_id: id,
  //     history,
  //     currentStage: "completed",
  //     nextForm: getRoute(),
  //     isFinal: true,
  //     isNewListing,
  //     doNotShowSuccessPage: false,
  //     triggerSlackNotification: !isCoListed && !hasAlreadyCompletedDeal,
  //   })
  // } else {
  //   let slackTriggered = false
  //   if (!isNewListing) {
  //     setTimeout(() => {
  //       console.log("Completed call now")
  //       tcStageUpdate({
  //         deal_id: id,
  //         history,
  //         currentStage: "completed",
  //         includeSuccess: true,
  //         nextForm: getRoute(),
  //         isFinal: true,
  //         noReRoute: false,
  //         doNotShowSuccessPage: false,
  //         isNewListing,
  //         triggerSlackNotification: !isCoListed,
  //       })
  //     }, 3000) // Bugfix. There was a race condition between these two apis.
  //     slackTriggered = true
  //   }

  //   tcStageUpdate({
  //     deal_id: id,
  //     history,
  //     currentStage: "upload_files",
  //     includeSuccess: true,
  //     nextForm: getRoute(),
  //     isFinal: true,
  //     isNewListing,
  //     noReRoute: !isNewListing,
  //     doNotShowSuccessPage: false,
  //     triggerSlackNotification: (!slackTriggered && !isCoListed) && isUsingDealTC,
  //   })
  // }
}

const handleRemoveFile = (id, deleteFunc, docuID) => {
  deleteFunc({
    fileToBeDeletedID: id,
    id: docuID,
  })
}

const getLastRoute = currentDeal => currentDeal.completed_steps.includes("escrow_pre_escrow")
  && currentDeal.file_type !== "lease_lisiting"
  ? ESCROW_FORM_ROUTE : (currentDeal.completed_steps.includes("sign_post_installation")
    && currentDeal.file_type === "new_listing" && currentDeal.file_type !== "lease_listing")
    ? SIGN_POST_FORM_ROUTE : currentDeal.completed_steps.includes("transaction_coordination")
      ? COORDINATION_FORM_ROUTE : TCAGENT_FORM_ROUTE

const FileUploadFormUI = ({
  history,
  handleFileUploader,
  currentDeal,
  tcStageUpdate,
  id,
  transactionFormUpdateStageResponse,
  uploadTCFilesToDbResponse,
  uploadTCFilesResponse,
  handleSubmit,
  fetchTransactionCoordinationDocumentsResponse,
  combinedTCValues,
  isFetchingFiles,
  fetchedFilesLength,
  isDeletingFiles,
  isFormDisabled,
  disabled,
}) => {
  const {
    isFetching: isUploadingToS3,
  } = uploadTCFilesResponse || {}

  const {
    isFetching: isUploadingToDB,
  } = uploadTCFilesToDbResponse || {}

  const {
    data: deals,
  } = fetchTransactionCoordinationDocumentsResponse

  const {
    isFetching: isUpdatingStage,
  } = transactionFormUpdateStageResponse || {}

  let isNewListing = combinedTCValues && combinedTCValues.file_type === "new_listing"

  let hasDoneUploadFileStage = false

  let hasAlreadyCompletedDeal = false

  let isCoListed = false

  let isUsingDealTC = false

  let disableField = false

  if (id && deals) {
    isNewListing = currentDeal && currentDeal.file_type === "new_listing"
    const currentSteps = currentDeal && currentDeal.completed_steps
    hasDoneUploadFileStage = currentSteps && currentSteps.length > 0
      && currentSteps.includes("upload_files")

    hasAlreadyCompletedDeal = currentSteps && currentSteps.length > 0
      && (currentSteps.includes("completed")
        || currentSteps.includes("tc_verified")
        || currentSteps.includes("commission_verified")
        || currentSteps.includes("verified"))
      && hasDoneUploadFileStage
    isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
    isUsingDealTC = currentDeal && currentDeal.is_using_deal_tc
    disableField = isCoListed || getIsEdit(currentDeal)
  }

  const isInvalid = currentDeal && currentDeal.is_using_radius_tc === 1 && fetchedFilesLength === 0

  const isProcessing = isUploadingToS3 || isUploadingToDB || isUpdatingStage || isFetchingFiles || isDeletingFiles
  return (
    <FormWrap onSubmit={handleSubmit}>
      <FieldWrap>
        <CustomLabel for="tc-file-upload">
          <p>
            Upload file
            <span>
              <img src={UploadIcon} alt="upload" />
            </span>
          </p>
        </CustomLabel>
        <Field
          name="tc_file_upload"
          component={File}
          multiple
          version2
          validate={[required]}
          id="tc-file-upload"
          customOnChange={(file) => {
            handleFileUploader(file)
          }}
        />
      </FieldWrap>
      <FileUploadIndicator
        toggleVisibility={isUploadingToS3 || isUploadingToDB}
      >
        Uploading files....
      </FileUploadIndicator>
      {/* <CTAWrap>
        <BackButton
          type="button"
          onClick={() => {
            //goes previous form
            backButtonUrl(history, currentDeal.id, getLastRoute(currentDeal))
          }}
        >
          Back
        </BackButton>
        <SubmitButton
          disabled={disableField}
          isProcessing={isProcessing}
          text={disableField ? "Exit" : "Continue"}
          type="submit"
          isInvalid={isInvalid}
          func={() => {
            if (disableField) {
              history.push("/realty/transaction-coordination")
            } else {
              stageUpdate(
                tcStageUpdate,
                id,
                history,
                isNewListing,
                hasDoneUploadFileStage,
                hasAlreadyCompletedDeal,
                disableField,
                isUsingDealTC,
              )
            }
          }}
        />
      </CTAWrap> */}
    </FormWrap>
  )
}

const FileUploadFormComponent = ({
  invalid,
  history,
  transactionFormUpdateStageResponse,
  uploadTCFilesToDbResponse,
  uploadTCFilesResponse,
  fetchTransactionCoordinationDocumentsResponse,
  uploadTCFiles,
  tcStageUpdate,
  combinedTCValues,
  fetchTCDocumentsFromS3Response,
  fetchFilesFromS3,
  deleteFileFromS3,
  deleteTCDocumentsFromS3Response,
  fetchTCFiles,
  dealId,
  disabled,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const toastConfig = {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: true,
  }

  useEffect(() => {
    if (dealId) {
      fetchFilesFromS3({ id: dealId })
    }
  }, [])

  try {
    FetchFileIfIDExists(dealId, currentDeal, fetchTCFiles)
  } catch (e) {
    //fail silently
  }

  const {
    data: fetchedFiles,
    isFetching: isFetchingFiles,
  } = fetchTCDocumentsFromS3Response || {}

  const {
    isFetching: isDeletingFiles,
  } = deleteTCDocumentsFromS3Response || {}

  const handleFileUploader = (file) => {
    const fileArr = []
    Object.values(file).forEach((item) => {
      fileArr.push(item)
    })
    let errorFlag = { remove: "", error: false }
    if (fetchedFiles) {
      fetchedFiles.forEach((itemFetched) => {
        const curr = fileArr[0].name
        if (itemFetched.title === curr) {
          errorFlag = { remove: curr, error: true }
        }
      })
    }
    if (errorFlag.error) {
      toast.error(`${errorFlag.remove} is already submitted`, toastConfig)
    } else {
      fileArr.forEach((fi) => {
        uploadTCFiles({
          file: fi,
          id: dealId,
        })
      })
    }
  }

  const {
    data: deals,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = dealId
  const currentDeal = deals && deals.length > 0 && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const disableField = isCoListed || getIsEdit(currentDeal)

  return (
    <Wrap>
      {/* <Header
        text="Upload File"
        history={history}
      /> */}
      {/* <DescriptionWrap>
        <p>Please upload fully executed contract documents for your TC to begin your transaction.</p>
        <p>
          If your TC does not have your CAR, MLS, and/or DocuSign logins, please
          email those to your assigned TC directly:
        </p>
      </DescriptionWrap> */}
      <FilesWrap>
        {fetchedFiles && fetchedFiles.length > 0 && (
          <FilesListWrap>
            {fetchedFiles.map(file => (
              <FileLi>
                <NameDiv>
                  <FileName>
                    {file.title}
                  </FileName>
                  <DocumentIconEle src={DocumentIcon} alt="doc.alt" />
                </NameDiv>
                {!disabled && (
                  <DeleteIconEle
                    src={DeleteIcon}
                    alt="doc.del"
                    onClick={() => {
                      if (!disableField) {
                        handleRemoveFile(file.id, deleteFileFromS3, dealId)
                      } else {
                        toast.error("Remove is disabled", toastConfig)
                      }
                    }}
                  />
                )}
              </FileLi>
            ))}
          </FilesListWrap>
        )}
      </FilesWrap>
      <FileUploadForm
        isFetchingFiles={isFetchingFiles}
        isInvalid={invalid}
        history={history}
        handleFileUploader={handleFileUploader}
        fetchedFilesLength={fetchedFiles && fetchedFiles.length > 0 || 0}
        uploadTCFiles={uploadTCFiles}
        currentDeal={currentDeal}
        id={dealId}
        tcStageUpdate={tcStageUpdate}
        fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
        transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
        uploadTCFilesToDbResponse={uploadTCFilesToDbResponse}
        uploadTCFilesResponse={uploadTCFilesResponse}
        combinedTCValues={combinedTCValues}
        isDeletingFiles={isDeletingFiles}
        disabled={disabled}
      />
    </Wrap>
  )
}

const FileUploadForm = reduxForm({
  form: "TC_TRANSACTION_FILE_UPLOAD_FORM",
})(FileUploadFormUI)

export default TransactionContainer(withRouter(FileUploadFormComponent))
