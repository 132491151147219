import React from "react"
import styled from "styled-components"
import AgentTestimonial from "./AgentTestimonial"
import AnotherTestimonial from "./AnotherTestimonial"

const Wrap = styled.div`
`

const Testimonials = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <AgentTestimonial toggleApplyModal={toggleApplyModal} />
      <AnotherTestimonial toggleApplyModal={toggleApplyModal} />
    </Wrap>
  )
}

export default Testimonials
