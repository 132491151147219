import React from "react"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  ListWrap,
  ListRowWrap,
} from "dumbComponents/OffersV3/DocusignDocs/Components/Templates/styles"
import { AddDocumentsUI } from "../../Templates/Components/index"
import {
  ModalWrap,
  StyledModal,
} from "../styles"

const LibraryModal = ({
  showModal,
  setModal,
}) => {
  const LibraryOptions = [
    {
      value: "offer_docs",
      label: "Offers",
    },
    {
      value: "listing_docs",
      label: "Listings",
    },
    {
      value: "uploads",
      label: "Uploads",
    },
  ]
  return (
    <ModalWrap>
      <StyledModal
        open={showModal}
        footer={false}
        onCancel={() => setModal(false)}
      >
        <ListRowWrap>
          <SectionTitle>
            <p>Add From Library</p>
          </SectionTitle>
        </ListRowWrap>
        <ListWrap>
          <AddDocumentsUI
            options={LibraryOptions}
            goBack={() => setModal(false)}
          />
        </ListWrap>
      </StyledModal>
    </ModalWrap>
  )
}

export default LibraryModal
