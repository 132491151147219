import React from "react"
import OffersV2 from "container/OffersV2" //connect sagas individually to components
import {
  AgentCardWrap,
  BuyerAgentInformationText,
  EditButton,
  DetailsArea,
  AgentName,
  AgentEmail,
  AgentPhoneNumber,
  AddtionalInfoOuterWrap,
  AdditionalInfoInnerWrap,
  AdditionInfoTitle,
  AdditionalInfoValue,
} from "../AgentCard/styles"

/**
 *Do not create styled-components here
 */

const ListingAgentCard = ({
  editSeller,
  data,
  title,
}) => (
  <AgentCardWrap>
    <EditButton onClick={editSeller}>
      Edit
    </EditButton>
    <BuyerAgentInformationText>
      <p>{title}</p>
    </BuyerAgentInformationText>
    <DetailsArea>
      <AgentName>
        <p>{`${data.firstname} ${data.lastname}`}</p>
      </AgentName>
      <AgentEmail>
        <p>{data.email}</p>
      </AgentEmail>
      <AgentPhoneNumber>
        <p>{data.phone}</p>
      </AgentPhoneNumber>
    </DetailsArea>
  </AgentCardWrap>
)

export default OffersV2(ListingAgentCard)
