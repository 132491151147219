import React from "react"
import { withRouter } from "react-router-dom"
import {
  WriteAnOfferText,
  BuyerNameText,
  CrumbWrap,
  ChevronWrap,
} from "./styles"

const OffersBreadCrumb = ({
  history,
}) => (
  <CrumbWrap
    onClick={() => {
      history.push("/realty/offers")
    }}
  >
    <WriteAnOfferText>
      <p>
        {"<"}
        {" "}
        Return to all offers
      </p>
    </WriteAnOfferText>
  </CrumbWrap>
)

export default withRouter(OffersBreadCrumb)
