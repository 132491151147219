import * as FormInputAction from "./actions"

const initState = {
  createEditPostText: "",
  introPostText: "",
  currentPasswordText: "",
  newPasswordText: "",
}

export default function formInputReducer(state = initState, action) {
  switch (action.type) {

    case FormInputAction.updateFormInputText.type:
    case FormInputAction.UPDATE_FORM_INPUT_TEXT: {
      const { key, text } = action.data
      return {
        ...state,
        [key]: text,
      }
    }

    default:
      return state
  }
}
