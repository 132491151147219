import React, { PureComponent } from "react"
import styled from "styled-components"
import { Field, reduxForm } from "redux-form"
import colors from "@colors"
import {
  required,
} from "services/formUtils"
import { supressPopups } from "shared/utils"
import Box from "@ui/Box"
import TCContainer from "container/Realty/TransactionFormContainer"
import CommissionFormImage from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/images/CommissionForm.jpg"
import LoaderButton from "@ui/LoaderButton"
import { ATC } from "@fonts"

import {
  Select,
  RadiusSelectTheme,
  RadiusInputTheme,
} from "@ui/Form"

const Wrap = styled.div`

`

const Form = styled.form`
  text-align: left;
  margin: 15px;
  label {
    font-family: ${ATC};
    input {
      font-family: ${ATC};
      font-weight: 200;
      box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
      border-radius: 4px;
      border: none;
    }
    input:focus {
      border: 1px solid ${colors.rockBlue};
    }
    span {
      font-family: ${ATC};
      font-weight: 200;
    }
  }
  input {
    font-family: ${ATC};
    font-weight: 200;
    box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
    border-radius: 4px;
    border: none;
  }
  input:focus {
    border: 1px solid ${colors.rockBlue};
  }
  #select_option {
    > div > div > div {
      width: 450px;
      font-family: ${ATC};
      font-weight: 200;
      box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
      border-radius: 4px;
      border: none;
      font-size: 16px;
      &:hover {
        border: none;
      }
    }
  }
`
const InputWrap = styled.div`
  label {
    font-family: ${ATC};
    font-weight: 200;
    font-size: 16px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 10px;
  }
  div[class$="-menu"] {
    font-size: 16px;
    z-index: 9999;
    margin-top: 1px;
    width: 450px;
    > div > div {
      font-size: 16px;
    }
  }
`

const FormContainer = styled.div`
  display: flex;
`
const FormWrap = styled.div``

const LaterButton = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  margin-top: 16px;
  cursor: pointer;
  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

const ButtonWrap = styled.div`
  margin: 35px 0 40px 0;
`

const Image = styled.img`
  margin: 15px;
`

const customStyles = {
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontSize: "16px",
    color: "#ccc",
  }),
  control: provided => ({
    ...provided,
    width: "450px",
    fontWeight: 200,
    boxShadow: "0px 1px 11px rgb(0 0 0 / 5%)",
    borderRadius: "4px",
    border: "none",
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: {ATC},
    fontWeight: 200,
    fontSize: "18px",
    position: "initial",
    transform: "none",
  }),
  valueContainer: provided => ({
    ...provided,
    padding: "8px 8px",
  }),
  option: provided => ({
    ...provided,
    fontFamily: {ATC},
    fontWeight: 300,
    color: colors.black,
    fontSize: 18,
    borderRadius: 0,
    textAlign: "left",
    zIndex: 99999,
  }),
}

const ModalForm = () => (
  <React.Fragment>
    <Form onSubmit={() => { }}>
      <Box mb="20" mt="0">
        {/* <RadiusSelectTheme
          id="select_topic"
          isRequired
          name="receiving_commission_mode"
          label="How would you like to receive your commission?"
          width="450px"
          borderRadius="8px"
          options={[{ label: "Wire", value: "wire" }]}
        /> */}
        <InputWrap>
          <Field
            id="select_topic"
            name="receiving_commission_mode"
            component={Select}
            isRequiredField
            placeholder="Select an option"
            label="How would you like to receive your commission?"
            options={[{ label: "Wire", value: "Wire" }, { label: "Check", value: "Check" }]}
            validate={required}
            onBlur={event => event.preventDefault()}
            selectStyles={customStyles}
            config={{ isSearchable: false }}
          />
        </InputWrap>
      </Box>
      <Box mb="10" mt="20">
        <RadiusInputTheme
          isRequired
          name="personal_bank_name"
          placeholder=""
          label="Personal Bank Name"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
          isRequiredField
        />
      </Box>
      <Box mb="10" mt="20">
        <RadiusInputTheme
          isRequired
          name="personal_bank_routing_number"
          placeholder=""
          label="Personal Bank Routing Number"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
          isRequiredField
        />
      </Box>
      <Box mb="10" mt="20">
        <RadiusInputTheme
          isRequired
          name="personal_bank_account_number"
          placeholder=""
          label="Personal Bank Account Number"
          width="450px"
          borderRadius="0px"
          fontSize="16px"
          spanBackground="none"
          isRequiredField
        />
      </Box>
    </Form>
  </React.Fragment>
)

class CommissionsPopup extends PureComponent {
  goNext = (isDraft) => {
    const {
      toggleCommissionsPopup, getOnboardingCommissionResponse, createOnboardingCommission, updateOnboardingCommission, combinedTCValues,
    } = this.props
    const {
      receiving_commission_mode,
      personal_bank_name,
      personal_bank_routing_number,
      personal_bank_account_number,
    } = combinedTCValues.tcPaymentForm
    if (getOnboardingCommissionResponse?.data?.response) {
      updateOnboardingCommission({
        payload: {
          step_completed: "personal_bank_info",
          receiving_commission_mode: receiving_commission_mode.value || receiving_commission_mode,
          personal_bank_name,
          personal_bank_routing_number,
          personal_bank_account_number,
        },
        isDraft,
        isContinue: 2,
      })
    } else {
      createOnboardingCommission({
        payload: {
          step_completed: "personal_bank_info",
          receiving_commission_mode: receiving_commission_mode.value || receiving_commission_mode,
          personal_bank_name,
          personal_bank_routing_number,
          personal_bank_account_number,
        },
        isDraft,
        isContinue: 2,
      })
    }
    if (isDraft) {
      return toggleCommissionsPopup(0)
    }
    // return toggleCommissionsPopup(2)
  }

  goBack = () => {
    const { toggleCommissionsPopup } = this.props
    toggleCommissionsPopup(0)
  }

  getInitialValues = () => {
    const { getOnboardingCommissionResponse } = this.props
    if (getOnboardingCommissionResponse?.data?.response) {
      return getOnboardingCommissionResponse.data.response
    }
    return {}
  }

  isDisabled = () => {
    const { combinedTCValues } = this.props
    if (combinedTCValues.tcPaymentForm) {
      const {
        receiving_commission_mode,
        personal_bank_name,
        personal_bank_routing_number,
        personal_bank_account_number,
      } = combinedTCValues.tcPaymentForm
      if (
        !receiving_commission_mode
        || !personal_bank_name
        || !personal_bank_routing_number
        || !personal_bank_account_number
      ) {
        return true
      }
      return false
    }
    return true
  }

  WrappedForm = reduxForm({
    form: "TC_PAYMENT_FORM_1",
    initialValues: this.getInitialValues(),
  })(ModalForm)

  render() {
    const { WrappedForm } = this
    const {
      getOnboardingCommissionResponse, createOnboardingCommissionResponse, updateOnboardingCommissionResponse,
    } = this.props
    console.log("==getOnboardingCommissionResponse===", getOnboardingCommissionResponse)
    console.log("==updateOnboardingCommissionResponse===", updateOnboardingCommissionResponse)
    return (
      <Wrap>
        <FormContainer>
          <Image src={CommissionFormImage} />
          <FormWrap>
            <WrappedForm />
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                width="95%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                disabled={this.isDisabled()}
                isShowLoader={createOnboardingCommissionResponse.isFetching || updateOnboardingCommissionResponse.isFetching || getOnboardingCommissionResponse.isFetching}
                onClick={() => { this.goNext() }}
              >
                Continue
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px"
                width="95%"
                margin="16px 0 0 0"
                borderRadius="50px"
                onClick={() => { this.goBack() }}
              >
                Close
              </LoaderButton>
              <LaterButton
                disabled={this.isDisabled()}
                onClick={() => {
                  if (!this.isDisabled()) {
                    this.goNext(true)
                    supressPopups("commissionsPopup")
                  }
                }}
              >
                Save for Later
              </LaterButton>
            </ButtonWrap>
          </FormWrap>
        </FormContainer>
      </Wrap>
    )
  }
}

export default TCContainer(CommissionsPopup)
