/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef, useState } from "react"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  InputNumber,
  ClientsByNameSearch,
  InputLocation,
  SelectField,
  AddButton,
  HiddenInputField,
  UploadImage,
} from "dumbComponents/common/InputFields"
import { normalizePhone } from "services/formUtils"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  DangerButton,
  SubmitWrap,
  SaveButton,
  ValidationNumberCircle,
} from "./commonStyles"
import {
  TYPE_OF_OWNERSHIP,
  CLIENT_SOURCE,
} from "./formConfig"
import {
  validatePhoneNumber,
} from "./utils"
import CoAgentIcon from "../../../../../../../images/icons/co-agent-icons";
import RemoveAgentBinIcon from "../../../../../../../images/icons/remove-agent-bin-icon";
import DeleteModal from "dumbComponents/Modals/DeleteModal"

const { List } = Form

const SellerInformation = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  isFirst,
  listingAgents = [],
  onValueChange,
  saving,
  disableForm,
  validationReport,
  handleImageUploadSupport,
  handleImageDeleteSupport,
  form,
  handleInitPropertyChange,
  editingAgentId,
}) => {
  const formRef = useRef(null)
  const [currentOwnerType, setTypeOfOwnership] = useState("")
  const [currentOwnerTypeCoSeller, setTypeOfOwnershipCoSeller] = useState({})
  const [ownershipType, setOwnershipType] = useState({})
  const [additionalSellers, updateSellers] = useState([])
  const [images, setImages] = useState({})
  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const getInitialValues = () => {
    let initValues = {}
    if (prefilledData && prefilledData.length > 0) {
      initValues = {
        ...prefilledData[0],
      }
      initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        initValues.co_sellers = prefilledData.slice(1, prefilledData.length)
      }
    }

    return initValues
  }

  const getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Full Name of Attorney-in-fact"
    }
    return "Full Name of Ownership"
  }

  useEffect(() => {
    if (prefilledData && prefilledData.length > 0) {
      updateSellers(prefilledData.length)
      const imageObject = {}
      const newOwnershipType = {}
      prefilledData.forEach((item, index) => {
        imageObject[index] = item.seller_image_url || ""
        if(index === 0 && item.type_of_ownership){
          setTypeOfOwnership(item?.type_of_ownership)
        } else if (index!=0 && item.type_of_ownership){
          newOwnershipType[index-1] = item.type_of_ownership || ""
          // setTypeOfOwnershipCoSeller(item?.type_of_ownership)
          setTypeOfOwnershipCoSeller((prev) => ({
            ...prev,
            [index-1]: item?.type_of_ownership, 
          }));
        }
      })
      setOwnershipType(newOwnershipType)
      setImages(imageObject)
    }
  }, [prefilledData])

  const uploadFunctionForSellerImage = (url, index) => handleImageUploadSupport(url, index)
  const handleImageRemove = index => handleImageDeleteSupport(index)
  const handleOwnershipType = (index, value) =>{
    const newOwnershipType = {}
    newOwnershipType[index] = item.type_of_ownership
    setOwnershipType({...newOwnershipType})
  }
  
  return (
    <FormWrap>
      {/* <HeaderWrap>
        <FormTitle>
          <p>{title || "Seller Information"}</p>
        </FormTitle>
      </HeaderWrap> */}

      <UploadImage
        postUploadFunction={url => uploadFunctionForSellerImage(url, 0)}
        handleImageRemove={() => handleImageRemove(0)}
        image={images[0]}
        showFallback={false}
      />

      <Form
        name="seller_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        disabled={disableForm}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        //form, content
        onValuesChange={(form, content) => {
          if (additionalSellers.length === 0 && (content.co_sellers === null || content.co_sellers === undefined)) {
            updateSellers(1)
          }
          onValueChange(form, content)
        }}
        form={form}
        //onValuesChange={handleOnTypeChangeForListingAgents}
      >
        <HiddenInputField name="is_primary" value={1} />

        {/* <InputField
          name="firstname"
          inputType="text"
          placeholder="First Name*"
          text="First Name"
          required={true}
          requiredMessage="Required."
          // disabled={disableForm}
        /> */}

        <ClientsByNameSearch
          formRef={formRef}
          firstNameInputFieldName="firstname"
          lastNameInputFieldName="lastname"
          emailInputFieldName="email"
          phoneInputFieldName="phone"
          formItemName="firstname"
          formItemPlaceholder="First Name"
          formItemLabelText="First Name"
          editingAgentId={editingAgentId}
          required={true}
        />

        <InputField
          name="lastname"
          inputType="text"
          placeholder="Last Name*"
          text="Last Name"
          required={true}
          requiredMessage="Last name is required"
          // disabled={disableForm}
        />

        <InputField
          name="email"
          inputType="email"
          placeholder="Email*"
          text="Email"
          type="email"
          required={true}
          // disabled={disableForm}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone"
          text="Phone"
          //requiredMessage="Phone is required."
          // disabled={disableForm}
          // required={true}
          customRules={{
            validator: validatePhoneNumber,
          }}
        />

        <InputLocation
          name="address"
          inputType="text"
          type="text"
          placeholder="Street Address"
          formRef={formRef}
          text="Street Address"
          // required={true}
          //ignoreFields={["state", "city", "zipcode"]}
          // disabled={disableForm}
          handleInitChange={handleInitPropertyChange}
          rules={[{ required: false }]}
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
          type="text"
          // required={true}
          // requiredMessage="City is required."
          // disabled={disableForm}
        />

        <InputField
          name="state"
          inputType="text"
          placeholder="State"
          text="State"
          type="text"
          // required={true}
          // requiredMessage="State is required."
          // disabled={disableForm}
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
          text="Zip Code"
          type="text"
          // required={true}
          // requiredMessage="Zip Code is required."
          // disabled={disableForm}
        />

        <SelectField
          isFormField
          name="type_of_ownership"
          options={TYPE_OF_OWNERSHIP}
          placeholder="Type of Ownership"
          text="Type Of Ownership"
          onChange={(val) => {
            setTypeOfOwnership(val)
          }}
          required={true}
          requiredMessage="Type of ownership is required"
        />

        {currentOwnerType && currentOwnerType !== "individual" && (
          <InputField
            placeholder={getFieldName(currentOwnerType)}
            text={getFieldName(currentOwnerType)}
            name="ownership_name"
            inputType="text"
            type="text"
          />
        )}
        
        <SelectField
          isFormField
          name="source"
          options={CLIENT_SOURCE}
          placeholder="Lead Source"
          text="Lead Source"
          onChange={() => {}}
          required={true}
          requiredMessage="Lead source is required"
        />

        <CTAWrap>
          {prefilledData && prefilledData.listing_agent_id && (
            <Form.Item>
              <DangerButton
                type="button"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => {}}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )}

          <List name="co_sellers">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap>
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                          e.stopPropagation()
                          if(agentToRemove !== null){
                            remove(agentToRemove)
                            setAgentToRemove(null);
                          }
                          setIsRemoveAgentModalVisible(false)
                        }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)}
                      heading="Remove Seller"
                      subText="Are you sure you want to remove this seller?" />
                    <HeaderWrap>
                      <FormTitle>
                        <p> <CoAgentIcon/> Additional Seller</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <RemoveAgentBinIcon/>
                        <p> Remove </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    <div
                      style={{
                        marginBottom: "20px",
                        width: "440px",
                      }}
                    >
                      <UploadImage
                        id={`${name}_${name}`}
                        postUploadFunction={url => uploadFunctionForSellerImage(url, parseInt(name, 10) + 1)}
                        handleImageRemove={() => handleImageRemove(parseInt(name, 10) + 1)}
                        image={images[parseInt(name, 10) + 1]}
                        showFallback={false}
                      />
                    </div>
                    
                    <FormFieldWrap>
                      <HiddenInputField name={[name, "is_primary"]} />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      {/* <InputField
                        name={[name, "firstname"]}
                        inputType="text"
                        placeholder="First Name*"
                        text="First Name"
                        required={true}
                        requiredMessage="Required."
                        // disabled={disableForm}
                      /> */}

                      <ClientsByNameSearch
                        formRef={formRef}
                        firstNameInputFieldName="firstname"
                        lastNameInputFieldName="lastname"
                        emailInputFieldName="email"
                        phoneInputFieldName="phone"
                        formItemName={[name, "firstname"]}
                        formItemPlaceholder="First Name"
                        formItemLabelText="First Name"
                        editingAgentId={editingAgentId}
                        fieldIndexInTheSubForm={name}
                        subFormName="co_sellers"
                        required={true}
                      />

                      <InputField
                        name={[name, "lastname"]}
                        inputType="text"
                        placeholder="Last Name"
                        text="Last Name"
                        required={true}
                        requiredMessage="Last name is required"
                        // disabled={disableForm}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "email"]}
                        type="email"
                        inputType="email"
                        placeholder="Email"
                        text="Email"
                        required={true}
                      />

                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        placeholder="Phone"
                        text="Phone"
                        type="phone"
                        // required={true}
                        // requiredMessage="Phone is required."
                        customRules={{
                          validator: validatePhoneNumber,
                        }}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                    <InputLocation
                        name={[name, "address"]}
                        inputType="text"
                        type="text"
                        placeholder="Address"
                        formRef={formRef}
                        formName="co_sellers"
                        fieldIndex={name}
                        //required={true}
                        //ignoreFields={["state", "city", "zipcode"]}
                        text="Address"
                        rules={[{ required: false }]}
                      />
                      <InputField
                        name={[name, "state"]}
                        inputType="text"
                        placeholder="State"
                        text="State"
                        type="text"
                        // required={true}
                        // requiredMessage="State is required."
                        // disabled={disableForm}
                      />
                 
                    </FormFieldWrap>
        
                    <FormFieldWrap>
                    <InputField
                        name={[name, "city"]}
                        inputType="text"
                        placeholder="City"
                        text="City"
                        type="text"
                        // required={true}
                        // requiredMessage="City is required."
                        // disabled={disableForm}
                      />

                      <InputField
                        name={[name, "zipcode"]}
                        inputType="text"
                        placeholder="Zipcode"
                        text="Zip Code"
                        type="text"
                        // required={true}
                        // requiredMessage="Zip Code is required."
                        // disabled={disableForm}
                      />
                      
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <SelectField
                        isFormField
                        name={[name, "type_of_ownership"]}
                        options={TYPE_OF_OWNERSHIP}
                        placeholder="Type of Ownership"
                        text="Type Of Ownership"
                        onChange={(val) => {
                          // handleOwnershipType(name, val)
                          // setTypeOfOwnershipCoSeller(val)
                          setTypeOfOwnershipCoSeller((prev) => ({
                            ...prev,
                            [name]: val, 
                          }));
                        }}
                        rules={[{ required: false }]}
                      />
                    {currentOwnerTypeCoSeller[name] && currentOwnerTypeCoSeller[name] !== "individual" && (
                      <InputField
                        placeholder={getFieldName(currentOwnerTypeCoSeller[name])}
                        text={getFieldName(currentOwnerTypeCoSeller[name])}
                        name={[name, "ownership_name"]}
                        inputType="text"
                        type="text"
                        />
                      )}

                    </FormFieldWrap>
                    
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={additionalSellers.length <= 0}
                        name="ADD ANOTHER SELLER"
                        onClick={() => {
                          updateSellers(count => count + 1)
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>
        </CTAWrap>

        {/* {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )} */}

        {/* {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )} */}
      </Form>
    </FormWrap>
  )
}

export default SellerInformation
