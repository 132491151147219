import React, { useMemo, useRef, useState } from "react"
import { Spin } from "antd"
import { isEmpty } from "lodash"
import debounce from "lodash/debounce"
import { ShimmerBox } from "shared/styles/animation"
import { SideArrowIconV2, SearchIcon } from "dumbComponents/OffersV3/icons"
import {
  DropdownStyleWrap,
  ResultWrap,
  ResultTitle,
  SelectFieldWrap,
  Wrap,
  StyledSelect,
  ErrorWrap,
} from "./googleLocationSearchStyles"
import FilledMLSTab from "./filledMLSTab"
import CustomDropdown from "./customDropdown"

function DebounceSelect({
  placeholder,
  fetchOptions,
  debounceTimeout = 800,
  value,
  onChange,
  isFetching,
  propertyInformationObject,
  fontSize,
  ...props
}) {
  const { mls_number } = propertyInformationObject || {}
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [selectDisplay, setDisplay] = useState(mls_number ? "none" : "block")
  const [selectedOption, setSelectedOption] = useState()
  const fetchRef = useRef(0)
  const [isError, setError] = useState(false)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (isEmpty(newOptions)) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 3000)
        }
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <>
      {isFetching ? (
        <ShimmerBox w="430px" h="50px" />
      ) : (
        <Wrap>
          <SelectFieldWrap>
            <SearchIcon
              customStyles={{
                position: "absolute",
                zIndex: "10",
                top: "17px",
                left: "10px",
                display: (mls_number ? "none" : selectDisplay),
              }}
            />
            <StyledSelect
              labelInValue
              placeholder={placeholder}
              filterOption={false}
              onSearch={debounceFetcher}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              options={options}
              dropdownStyle={{
                display: "none",
              }}
              style={{
                width: "100%",
                display: (mls_number ? "none" : selectDisplay),
              }}
              suffixIcon={<SideArrowIconV2 />}
              {...props}
            />
          </SelectFieldWrap>
          {(isError && !selectedOption) && (
            <ErrorWrap>
              No results found
            </ErrorWrap>
          )}
          <DropdownStyleWrap
            display={options && options.length > 0 || fetching}
          >
            <ResultWrap>
              <ResultTitle>
                {`${fetching ? "Searching" : `Showing ${options.length} results`}`}
              </ResultTitle>
            </ResultWrap>
            <CustomDropdown
              selectedOption={options}
              setOptions={setOptions}
              setFetching={setFetching}
              setDisplay={setDisplay}
              setSelectedOption={setSelectedOption}
              callback={onChange}
              isFetching={isFetching}
            />
          </DropdownStyleWrap>
          {(selectDisplay === "none" || mls_number) && (
            <FilledMLSTab
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setDisplay={setDisplay}
              propertyInformationObject={propertyInformationObject}
              isFetching={isFetching}
              fontSize={fontSize}
            />
          )}
        </Wrap>
      )}
    </>
  )
}

export default DebounceSelect
