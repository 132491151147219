import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as AppActions from "./actions"

const initState = {
  isMilestoneModalOpen: false,
  isFormOpen: false,
  isShareModelOpen: false,
  isRecruitFormVisible: true,
  ...createDeltaReducer("getReferralCumulativeDataResponse"),
}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case AppActions.toggleMilestoneInfoModalAction.type: {
      return {
        ...state,
        isMilestoneModalOpen: action.data,
      }
    }

    case AppActions.getReferralCumulativeDataAction.REQUEST:
    case AppActions.getReferralCumulativeDataAction.SUCCESS:
    case AppActions.getReferralCumulativeDataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.getReferralCumulativeDataAction, "getReferralCumulativeDataResponse"),
      }
    }

    case AppActions.toggleRecruitAndEarnFormAction.type: {
      return {
        ...state,
        isFormOpen: action.data,
      }
    }

    case AppActions.toggleRecruitAndEarnShareModalAction.type: {
      return {
        ...state,
        isShareModelOpen: action.data,
      }
    }
    
     case AppActions.toggleRecruitAndEarnFormViewAction.type: {
      return {
        ...state,
        isRecruitFormVisible: action.data,
      }
    }
    
    default:
      return state
  }
}
