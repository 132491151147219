import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import moment from "moment"
import { withRouter } from "react-router-dom"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import { get, zip } from "lodash"
import root, { alert, setTimeout } from "window-or-global"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import { PlusOutlined, PlusCircleOutlined } from "@ant-design/icons"
import Loader from "@ui/Loader"
// import { gapi } from "gapi-script"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button,
  GoogleAutoComplete, Radio, Space,
  DatePicker,
} from "@ui/antd"
import { emailNormalisation } from "dumbComponents/Offers/commonUtils"
import { addCommas, USCurrencyFormat, deformatCurrency, } from "shared/currencyUtils"
import dynamic from "next/dynamic"
const loadGapi = dynamic(() => import("gapi-script").then(mod => mod.loadGapiInsideDOM), { ssr: false })
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const FileTypeFieldMap = {
  listing_with_accepted_offer: ["price", "list_price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  pending_contract: ["price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  dual: ["price", "list_price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  lease_listing: ["list_price", "rent_amount_term", "rent_amount", "commission_type", "team_split_percentage", "created_at", "closed_on", "lease_commencement_on"],
  pending_lease: ["rent_amount_term", "rent_amount", "commission_type", "team_split_percentage", "created_at", "closed_on", "lease_commencement_on"],
  external_referral_agreement: ["price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
}

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    commissionType: null,
    isTransactionTypeOther: null,
    isRentTypeOther: null,
    showLenderEmail: null,
  }

  formRef = React.createRef();

  componentDidMount() {
    const initializeGapi = async () => {
      try {
        await loadGapi()
        // Initialize gapi client
        window.gapi.load("client:auth2", this.fetchGoogleContacts)
      } catch (e) {
        //
      }
    }
    initializeGapi()
    const { getContractById, dealToView } = this.props
    if (dealToView) {
      getContractById({
        dealId: dealToView || 0,
        // contractOnly: 1,
      })
    }
  }

  setSource = (value) => {
    if (value === "other") {
      this.setState({
        isTransactionTypeOther: true,
      })
    } else {
      this.setState({
        isTransactionTypeOther: false,
      })
    }
  }

  setRentTerm = (value) => {
    if (value === "other") {
      this.setState({
        isRentTypeOther: true,
      })
    } else {
      this.setState({
        isRentTypeOther: false,
      })
    }
  }

  setLenderEmail = (value) => {
    this.setState({
      showLenderEmail: value === 0,
    })
  }

  getNextRoute = (isDraft) => {
    const { isFromDirectForm, mainPageRoute, nextFormRoute } = this.props
    if (!isFromDirectForm) {
      return isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/upload"
    }
    return isDraft ? mainPageRoute : nextFormRoute
  }

  onFinish = (fieldValues, isDraft) => {
    const { updateContract, history, dealToView, getContractByIdResponse } = this.props
    const user = cookie.load("user") || {}
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const { data: contractData } = getContractByIdResponse || {}
    const fileType = get(contractData[0], "file_type", null)
    const clientType = get(contractData[0], "client_type", null)
    // const { cityFormValue } = this.state

    const payload = {
      ...fieldValues,
      agent_id: user.id,
      saveDraft: isDraft ? 1 : 0,
      current_contract_step: "transaction_details",
    }
    if (fieldValues.closed_on) {
      payload.closed_on = fieldValues.closed_on.unix()
    }
    if (fieldValues.created_at) {
      payload.created_at = fieldValues.created_at.unix()
    }
    if (fieldValues.lease_commencement_on) {
      payload.lease_commencement_on = fieldValues.lease_commencement_on.unix()
    }
    if (fileType && clientType) {
      if (fileType === "pending_contract"
      || fileType === "pending_lease"
      || (fileType === "external_referral_agreement" && (clientType !== "landlord"))) {
        payload.list_price = ""
      }

      if (fileType === "listing_with_accepted_offer"
      || fileType === "pending_contract"
      || fileType === "dual") {
        payload.rent_amount = ""
        payload.rent_amount_term = ""
      }

      if (fileType === "lease_listing"
      || fileType === "pending_lease"
      || (fileType === "external_referral_agreement" && (clientType === "landlord" || clientType === "tenant"))) {
        payload.price = ""
      }
    }
    updateContract({
      payload,
      dealId: dealToView,
      callForGetAPI: true,
      callAPI: ["select_vendor"],
      nextRoute: this.getNextRoute(isDraft),
      history,
    })
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, cityFormName) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [cityFormName]: `${locationDetails.city || ""}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: `${locationDetails.city || ""}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getContractByIdResponse } = this.props
    const { cityFormValue, showCoListingform, commissionType, isTransactionTypeOther, isRentTypeOther, showLenderEmail } = this.state
    const tcInfo = getContractByIdResponse.data?.[0]
    if (tcInfo) {
      if (showLenderEmail == null) {
        this.setState({
          showLenderEmail: tcInfo.is_all_cash_offer === 0,
        })
      }
    }
    const localShowCoListingform = showCoListingform
    console.log("==getContractByIdResponse.data==", getContractByIdResponse.data)
    console.log("==sellerInfo.data==", tcInfo)
    const result = {}

    if (tcInfo) {
      tcInfo.closed_on = tcInfo.closed_on ? moment(tcInfo.closed_on * 1000) : null
      tcInfo.created_at = tcInfo.created_at ? moment(tcInfo.created_at * 1000) : null
      tcInfo.lease_commencement_on = tcInfo.lease_commencement_on ? moment(tcInfo.lease_commencement_on * 1000) : null
      if (tcInfo.commission_type && !commissionType) {
        this.setState({
          commissionType: tcInfo.commission_type,
        })
      }
      if (!isTransactionTypeOther) {
        this.setSource(tcInfo.transaction_type)
      }
      if (!isRentTypeOther) {
        this.setRentTerm(tcInfo.rent_amount_term)
      }
      return tcInfo
    }
    console.log("==result.data==", result)
    return result
  }

  showField = (fieldType) => {
    const { getContractByIdResponse } = this.props
    const fileType = getContractByIdResponse.data?.[0]?.file_type || getContractByIdResponse.data?.file_type
    if (fileType && FileTypeFieldMap[fileType]) {
      return FileTypeFieldMap[fileType].includes(fieldType)
    }
    return false
  }

  showERAFields = (fieldType) => {
    const { getContractByIdResponse } = this.props
    const fileType = getContractByIdResponse.data?.[0]?.file_type || getContractByIdResponse.data?.file_type
    const clientType = getContractByIdResponse.data?.[0]?.client_type || getContractByIdResponse.data?.client_type
    if (!(clientType === "tenant" || clientType === "landlord") && fieldType === "price") {
      return true
    }
    if (fileType === "external_referral_agreement" && (clientType === "tenant" || clientType === "landlord")) {
      if (clientType === "tenant" || clientType === "landlord") {
        if (fieldType.includes("rent")) {
          return true
        }
        if (fieldType === "list_price" && clientType === "tenant") {
          return false
        }
        if (fieldType === "list_price" && clientType === "landlord") {
          return true
        }
        if (fieldType === "price" && (clientType === "landlord" || clientType === "tenant")) {
          return false
        }
      }
    } else if (fileType === "external_referral_agreement" && (clientType !== "landlord" || clientType !== "tenant")) {
      if (fieldType === "price") {
        return true
      }
    }
    return false
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  disabledDate = (current, type) => {
    // Can not select days before today and today
    if (type === "before") return current && current < moment().endOf("day")
    if (type === "after") return current && current > moment().endOf("day")
  }

  setCommissionType = (value) => {
    this.setState({
      commissionType: value,
    })
  }

  getBackRoute = () => {
    const { isFromDirectForm, backFormRoute, history } = this.props
    if (!isFromDirectForm) {
      history.push("/realty/transaction-coordination/contract/client-info")
    }
    history.push(backFormRoute)
  }

  formatter = (value) => {
    const check_value = value.toString().replace(/(\.\d{2}).*/, '$1')
    if (check_value && check_value.includes(".")) {
      return `$ ${addCommas(check_value)}`
    }
    return USCurrencyFormat(check_value)
  }

  formatterPercent = (value) => {
    const check_value = value.toString().replace(/(\.\d{2}).*/, '$1')
    return `% ${check_value}`
    
    
  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  render() {
    const { showCoListingform, cityFormValue, commissionType, isTransactionTypeOther, isRentTypeOther, showLenderEmail } = this.state
    const {
      history, updateContractResponse, updateListingCoverSheetResponse, getListingCoverSheetResponse,
      loaderOnAgentInfo, getContractByIdResponse, isFromDirectForm,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    const fileType = getContractByIdResponse.data?.[0]?.file_type

    console.log("==result.data getContractByIdResponse--==", getContractByIdResponse.data)
    return (
      <Wrap mt={isFromDirectForm ? "0px" : "52px"}>
        {!isFromDirectForm && (
          <HeaderNav>
            Transaction Details
          </HeaderNav>
        )}
        {/* <ProgressBarContainer>
        </ProgressBarContainer> */}
        <Container>
          <RightSideBar>
            {getContractByIdResponse.isFetching || loaderOnAgentInfo ? (<Loader />) : (
              <Form name="transaction_details" colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef} disabled={getContractByIdResponse?.data?.[0] ? !this.getIsEdit(getContractByIdResponse?.data?.[0]) : false}>
                <>
                  <FlexBox wd="100%">
                    {(this.showField("price") && this.showERAFields("price")) && (
                      <Form.Item name="price" label={<LabelComponent text="Sales Price" required />} rules={commonRules}>
                        <InputNumber 
                          formatter={value => value ? this.formatter(value) : `$ ${value}`}
                        />
                      </Form.Item>
                    )}
                    {(this.showField("list_price") || this.showERAFields("list_price")) && (
                      <Form.Item name="list_price" label={<LabelComponent text="List Price" required />} rules={commonRules}>
                        <InputNumber 
                          formatter={value => value ? this.formatter(value) : `$ ${value}`} />
                      </Form.Item>
                    )}
                    {(this.showField("rent_amount") || this.showERAFields("rent_amount")) && (
                      <Form.Item name="rent_amount" label={<LabelComponent text="Rent Amount" required />} rules={commonRules}>
                        <InputNumber 
                          formatter={value => value ? this.formatter(value) : `$ ${value}`} />
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {(this.showField("rent_amount_term") || this.showERAFields("rent_amount_term")) && (
                      <Form.Item
                        name="rent_amount_term"
                        label={<LabelComponent text="What is your rent term basis?" required />}
                        required
                        rules={commonRules}
                      >
                        <Select onChange={value => this.setRentTerm(value)}>
                          <Select.Option value="month">Month</Select.Option>
                          <Select.Option value="annual">Annual</Select.Option>
                          <Select.Option value="other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    )}
                    {this.showField("rent_amount_term") && isRentTypeOther && (
                      <Form.Item name="other_rent_amount_term" label={<LabelComponent text="Other Rent Term" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {this.showField("commission_type") && (
                      <Form.Item
                        name="commission_type"
                        label={<LabelComponent text="What type of commission are you receiving?" required />}
                        required
                        rules={commonRules}
                      >
                        <Select onChange={(value) => { this.setCommissionType(value) }}>
                          <Select.Option value="percentage">Percentage</Select.Option>
                          <Select.Option value="fixed_fee">Fixed Fee</Select.Option>
                          <Select.Option value="both">Both</Select.Option>
                        </Select>
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {["percentage", "both"].includes(commissionType) && (
                      <Form.Item
                        name="commision_percentage"
                        label={<LabelComponent text="What is your commission percentage?" required />}
                        required
                        rules={commonRules}
                      >
                        <InputNumber formatter={value => value ? this.formatterPercent(value) : `% ${value}`} />
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {["fixed_fee", "both"].includes(commissionType) && (
                      <Form.Item
                        name="fixed_fee"
                        label={<LabelComponent text="What is your fixed fee?" required />}
                        required
                        rules={commonRules}
                      >
                        <InputNumber 
                          formatter={value => value ? this.formatter(value) : `$ ${value}`} />
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {this.showField("team_split_percentage") && (
                      <Form.Item name="team_split_percentage" label={<LabelComponent text="What is your split with your team?" />}>
                        <InputNumber formatter={value => value ? this.formatterPercent(value) : `% ${value}`} />
                      </Form.Item>
                    )}
                  </FlexBox>
                  <FlexBox>
                    {this.showField("created_at") && (
                      <Form.Item name="created_at" label={<LabelComponent text="Acceptance date" required />} rules={commonRules}>
                        <DatePicker disabledDate={current => this.disabledDate(current, "after")} format="MM-DD-YYYY" />
                      </Form.Item>
                    )}
                    {this.showField("closed_on") && (
                      <Form.Item name="closed_on" label={<LabelComponent text="Estimated Close of Escrow" required />} rules={commonRules}>
                        <DatePicker disabledDate={current => this.disabledDate(current, "before")} format="MM-DD-YYYY" />
                      </Form.Item>
                    )}
                    {this.showField("lease_commencement_on") && (
                      <Form.Item name="lease_commencement_on" label={<LabelComponent text="Lease Commencement Date" required />} rules={commonRules}>
                        <DatePicker format="MM-DD-YYYY" />
                      </Form.Item>
                    )}

                    {/* <Form.Item name="listing_expiration_date" label={<LabelComponent text="Listing Expiration Date" required />} rules={commonRules}>
                      <DatePicker />
                    </Form.Item> */}
                  </FlexBox>
                  <FlexBox>
                    <Form.Item name="transaction_type" label={<LabelComponent text="Transaction Type" required />} rules={commonRules}>
                      <Select onChange={value => this.setSource(value)}>
                        <Select.Option value="residential_1_4">Standard Residential</Select.Option>
                        <Select.Option value="residential_inc">Residential Income Property</Select.Option>
                        <Select.Option value="vacant_land">Vacant Land</Select.Option>
                        <Select.Option value="manufactured_mo">Manufactured/Mobile Home</Select.Option>
                        <Select.Option value="new_construction">New Construction</Select.Option>
                        <Select.Option value="lease">Lease</Select.Option>
                        <Select.Option value="short_sales">Short Sales</Select.Option>
                        <Select.Option value="relocation">Relocation</Select.Option>
                        <Select.Option value="referral_agreem">Referral Agreement</Select.Option>
                        <Select.Option value="condominium">Condominium</Select.Option>
                        <Select.Option value="farm_and_ranch">Farm and Ranch</Select.Option>
                        <Select.Option value="commercial">Commercial</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </FlexBox>
                  {isTransactionTypeOther && (
                    <Form.Item name="other_transaction_type" label={<LabelComponent text="Other Transactional Type" required />} rules={commonRules}>
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item name="is_all_cash_offer" label={<LabelComponent text="Is this Transaction an all cash offer?" required />} rules={commonRules}>
                    <Radio.Group onChange={(e) => { this.setLenderEmail(e.target.value) }}>
                      <Space direction="vertical">
                        <Radio value={1}>
                          <LabelComponent text="Yes" />
                        </Radio>
                        <Radio value={0}>
                          <LabelComponent text="No" />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  {showLenderEmail && (
                    <Form.Item name="lender_email_address" label={<LabelComponent text="Lender Email" required />} rules={commonRules} normalize={emailNormalisation}>
                      <Input />
                    </Form.Item>
                  )}
                </>
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button>
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        disabled={false}
                        // htmlType="submit"
                        onClick={() => {
                          this.getBackRoute()
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            background: "#3B60E4",
                            float: "right",
                            borderColor: "white",
                          }}
                          type="primary"
                          shape="round"
                          size="large"
                          loading={getContractByIdResponse.isFetching || updateContractResponse.isFetching || getListingCoverSheetResponse.isFetching}
                          htmlType="submit"
                        // onClick={() => { history.push("/realty/listings/coversheet/listing-details") }}
                        >
                          Next
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            background: "#3B60E4",
                            float: "right",
                            borderColor: "white",
                          }}
                          type="primary"
                          shape="round"
                          size="large"
                          disabled={false}
                          onClick={() => { history.push("/realty/transaction-coordination/contract/tc-info") }}
                        >
                          Next
                        </Button>
                      </Form.Item>
                    )}
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default withRouter(TCContainer(TechAssetsContainer(CoverSheet)))

