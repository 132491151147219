import React from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import { Textarea } from "@ui/Form"
import Box from "@ui/Box"
import Button from "@ui/LoaderButton"
import Modal from "@ui/Modal"

const Wrap = styled.div`
  margin-top: 40px;
`

const FormWrap = styled.form`
  
`

const Form = ({
  handleSubmit,
  isSavingNotes,
}) => (
  <FormWrap onSubmit={handleSubmit}>
    <Field component={Textarea} name="note" />
    <Box d="flex" jc="space-between" mt="20">
      <Button isShowLoader={isSavingNotes}>
        Save
      </Button>
    </Box>
  </FormWrap>
)

const DecoratedForm = reduxForm({
  form: "NON_ASSIST_ADD_NOTE",
})(Form)

const AddNotesModal = ({
  addNote,
  toggleAddNotesModal,
  clientId,
  isSavingNotes,
}) => {
  const handleSubmit = (value) => {
    addNote({
      payload: value,
      clientId,
    })
  }

  return (
    <Modal
      show
      toClose={() => { toggleAddNotesModal(false) }}
      title="Add Note"
      dialogStyle
    >
      <Wrap>
        <h1>Add notes for the client</h1>
        <DecoratedForm
          onSubmit={handleSubmit}
          toggleAddNotesModal={toggleAddNotesModal}
          isSavingNotes={isSavingNotes}
        />
      </Wrap>
    </Modal>
  )
}
export default AddNotesModal
