/* eslint-disable max-len */
import React from "react"

const LeaveRoom = () => (
  <svg width="30" height="17" viewBox="0 0 40 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 13.1405C0 15.0909 1.17355 16.7108 3.23967 16.7108C3.63637 16.7108 4.24794 16.6777 5.10744 16.5289L10.9587 15.6529C13.3719 15.3058 14.0331 14.3141 14.0331 12.248C14.0331 11.6198 14.0331 11.0248 14.0496 10.3967C14.0496 9.88431 14.281 9.66944 14.6612 9.57027C17.091 9.02481 22.9092 9.02481 25.3389 9.57027C25.719 9.66944 25.9504 9.88431 25.9504 10.3967C25.967 11.0248 25.967 11.6198 25.967 12.248C25.967 14.3141 26.6281 15.3058 29.0413 15.6529L34.8925 16.5289C35.752 16.6777 36.3637 16.7108 36.7604 16.7108C38.8265 16.7108 40 15.0909 40 13.1405C40 12.6777 40 12.2314 40 11.7851C40 4.0992 31.405 0.281006 20 0.281006C8.61157 0.281006 0 4.0992 0 11.7851C0 12.2314 0 12.6777 0 13.1405ZM2.67769 12.7438C2.67769 12.4628 2.67769 12.1984 2.67769 11.9174C2.67769 5.27274 11.2231 2.77687 20 2.77687C28.777 2.77687 37.3224 5.27274 37.3224 11.9174C37.3224 12.1984 37.3224 12.4628 37.3224 12.7438C37.3224 13.5537 36.9422 14.2645 36.0992 14.2645C35.8678 14.2645 35.6198 14.2314 35.1571 14.1488L29.6033 13.1075C29.0579 12.9918 28.6446 12.7108 28.6446 11.9008C28.6446 11.2232 28.6446 10.5124 28.6446 9.83473C28.6446 8.19837 28.0497 7.5372 27.1902 7.28927C24.529 6.52894 15.4711 6.52894 12.8099 7.28927C11.9504 7.5372 11.3554 8.19837 11.3554 9.83473C11.3554 10.5124 11.3554 11.2232 11.3554 11.9008C11.3554 12.7108 10.9421 12.9918 10.3967 13.1075L4.84298 14.1488C4.38017 14.2314 4.14876 14.2645 3.90083 14.2645C3.05785 14.2645 2.67769 13.5537 2.67769 12.7438Z" fill="#ED1616"/>
  </svg>

)

export default LeaveRoom
