import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;

  border-radius: 10px;
  //background: var(--Color-Neutral-100, #F1F1FE);

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 10px;
    position: relative;

    .ant-form-item {
      margin-left: unset;
      width: 49% !important;
      margin-right: unset;
    }
  }
`

export const EditPropertyText = styled.p`
color: #5A5FF2;
text-decoration: underline;
margin: 0;
margin-bottom: 10px;
cursor: pointer;
`

export const FormTitle = styled.div`
   p {
    color: var(--Neutral-900, #111827);
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.352px;
    display: flex;
    margin: 0;
  }
`

export const ExtraWrap = styled.div`
  position: relative;
`

export const ValidationNumberCircle = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #F08068;
  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 141%; /* 14.1px */
`

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E6FF;
`

export const CancelOrGoBackButton = styled.button`
  border-radius: 8px;
  border: 1px solid var(--Color-Red-Red-500, #F05152);
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  img {
    width: 16px;
    height: 16px;
  }
  p {
    color: var(--Color-Red-Red-500, #F05152);
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
  }
`

export const CTAWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  .ant-form-item {
    width: unset;
  }
`

export const FormFieldWrap = styled.div`
  display: flex;
  gap: 10px;
`

export const CoWrap = styled.div`
  // background: #F6F6FF;
  padding: 16px;
  border-radius: 9px;
  margin: 10px 0px;
  width: 100%;
  background: #F9FAFB;
`
export const ListRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  align-items: baseline;
  margin-bottom: 4px;
`

export const SubmitWrap = styled.div`
  position: absolute;
  right: 0;
  top: -50px;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }
`

export const OffersWrap = styled.div`
  padding-top: 50px;
  display: flex;
  width: 100%;
  position: relative;
  min-height: 102vh;
`

export const TabsWrap = styled.div`
  border-radius: 9px;
  border: 1px solid #EBEBEB;
  background: var(--White, #FDFDFD);
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
`

export const MenuWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 34px 28px 0px 28px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  height: 50%;
  position: sticky;
  top: 100px;
`

export const FormAndFunctionWrap = styled.div`
  display: flex;
  padding: 34px 31px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  flex-shrink: 0;
  align-self: stretch;
  height: 100%;
  width: 50%;

  border-left: 1px solid var(--Color-Neutral-300, #DCDEFF);
  background: #F9F9FF;

  min-height: 100vh;
`

export const WhiteButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: transparent;

  border-radius: 33.5px;
  border: 1px solid #5A6CF2;

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
`

export const BlueButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: none;

  border-radius: 33.5px;
  background: #5A5FF2;

  p {
    color: var(--White, #FDFDFD);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
  
  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

export const DangerButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: none;

  border-radius: 33.5px;
  background: #ff2c2c;

  p {
    color: var(--White, #FDFDFD);
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
`

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  padding-bottom: 68px;
`

// export const CTAWrap = styled.div`
//   // padding-top: 18px;
//   padding-bottom: 18px;
// `
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const TransparentButton = styled.button`
  display: flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  // flex: 1 0 0;
  border: none;

  border-radius: 33.5px;
  background: transparent;

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    leading-trim: both;
    text-edge: cap;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0;
  }

  ${props => props.maxWidth && `
    width: 100%;
  `}
`

export const SaveButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--Color-Primary-Primary, #5A5FF2);
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
`

export const RadioWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`

export const RadioQuestion = styled.div`
 p {
    color: #6E6E6E;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
    margin: 0;
 }

`

export const ExtraFormUIWrap = styled.div`
 width: 100%;
 padding-top: 10px;
 padding-bottom: 40px;
`

export const StateDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  p {
    color: var(--BG-Dark-mode, #292A37);
    text-align: right;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  h2 {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`

export const StateWrap = styled.div`
  display: flex;
  width: 176px;
  align-items: flex-start;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
  position: absolute;
  right: 15px;
  top: -64px;

  div {
    height: 45px;
    /* padding: 2px; */
    display: flex;
    align-items: center;
  }
`

export const GreyState = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);
  background: var(--Color-Neutral-100, #F1F1FE);
  padding: 10px;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  p {
    color: var(--Gray-500, #667085);

    /* Text md/Normal */
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`
export const State = styled.div`
  background: #fff;
  display: flex;
  align-items: center;

  h2 {
    color: var(--Gray-500, #667085);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`;

export const CheckboxWrap = styled.div`
display: flex;
padding: 12px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 12px;
width: 100vw;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: #FFF;
`;

export const CoAgentWarnDiv = styled.div`
display: flex;
height: 44px;
padding: 4px 12px;
align-items: center;
gap: 16px;
border-radius: 5px;
background: var(--Primary-50, #EEF2FF);
color: var(--Global-Mapping-Primary, #5A5FF2);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
`;

export const CircleModalDiv = styled.div`
display: flex;
width: 48px;
height: 48px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 24px;
background: var(--Primary-50, #EEF2FF);
`;

export const RemoveTitleModal = styled.p`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
margin: 0;
margin-top: 20px;
margin-bottom: 5px;
`;

export const ModalDesc = styled.p`
color: var(--Neutral-500, #6B7280);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
`;

export const ModalNoteDiv = styled.div`
display: flex;
padding: 8px 12px;
align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Color-Yellow-Yellow-200, #FBCC8E);
background: var(--Color-Yellow-Yellow-50, #FEF4E6);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Color-Yellow-Yellow-900, #683C04);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 20px;
`;

export const ModalWarnDiv = styled.div`
display: flex;
padding: 8px 12px;
align-items: center;
gap: 12px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
background: var(--Global-Mapping-Gray-50, #EFEFF4);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Global-Mapping-Gray-700, #373758);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 20px;
`;