import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import Box from "@ui/Box"

const Wrap = styled.div`
  display: flex;
  max-width: 900px;
  
`

const Cols = styled.div`
  flex: 1;
  margin: 10px 20px;
`

const Card = styled.div`
  background: ${colors.white};
  padding: 8px;
  border-radius: 4px;
  height: 120px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
`

const Shimmer = () => (
  <Wrap>
    <Cols>
      <Card>
        <ShimmerBox w="60px" h="60px" br="50%" />
      </Card>
    </Cols>
    <Cols>
      <Card>
        <ShimmerBox w="60px" h="60px" br="50%" />
      </Card>
    </Cols>
    <Cols>
      <Card>
        <ShimmerBox w="60px" h="60px" br="50%" />
      </Card>
    </Cols>
  </Wrap>
)

export default Shimmer

