import React from "react"
import styled from "styled-components"
import appContainer from "container/App"
import { handleDataLayerPush } from "services/dataLayerUtil"
import interestContainer from "container/App/applyFlowContainer"
import { HeroText, SubText, BlueButton } from "../commonStyles"

const Wrap = styled.div`
  width: 100%;
  //background: #FAFAFA;

  //box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

  //border-radius: 9px;
  //border: 1px solid #5A5FF2;
  //background: #FAFAFA;

  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  justify-content: space-around;

  @media(max-width: 1099px) {
    padding: 22px 0px 5px 0px;
  }
`

const TextWrap = styled.div``

const CTAWrap = styled.div`
  margin-top: 32px;
  @media(max-width: 1099px) {
    margin-top: 35px;
  }
`

const Placard = ({
  toggleApplyModal,
  user: userData,
}) => (
  <Wrap>
    <TextWrap>
      <HeroText
        fs="40px"
        fsMobile="20px"
        style={{
          margin: "0",
        }}
      >
        Let’s Partner Up
      </HeroText>
    </TextWrap>
    <SubText
      fsMobile="12px"
      fs="18px"
    >
      Ready to launch your own brand? Radius is here to support your success… from day one.
    </SubText>
    <CTAWrap>
      <BlueButton
        unsetMinWidth
        height="56px"
        className="explore_ownership_partner_web"
        onClick={() => {
          const payload = {}
          payload.section_name = "lets_partner_up"
          handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
          toggleApplyModal({
            bool: true,
            accountType: "owner",
            className: "form_explore_ownership_partner_web",
            headerText: "With one click, you’ve taken a first step towards building your real estate legacy. ",
            // subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
          })
        }}
      >
        Talk with Radius
      </BlueButton>
    </CTAWrap>
  </Wrap>
)

export default interestContainer(appContainer(Placard))
