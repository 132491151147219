import React, { Component } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  user-select: none;
`

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const RenderWrapper = styled.div`
  
`

export default class Accordion extends Component {
  state = {
    show: this.props.initialOpen || false,
  }

  toggleShow = () => {
    const { show } = this.state
    this.setState({
      show: !show,
    })
  }

  render() {
    const { show } = this.state
    const { toggleComponent, renderComponent } = this.props

    return (
      <Wrapper>
        <ToggleWrapper onClick={this.toggleShow}>
          {toggleComponent}
        </ToggleWrapper>
        <RenderWrapper>
          {show && renderComponent}
        </RenderWrapper>
      </Wrapper>
    )
  }
}
