import React, { Component } from "react"
import styled from "styled-components"
import TextareaAutosize from "react-autosize-textarea"
import CircularImage from "dumbComponents/common/CircularImage"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import Button from "@ui/Button"
import colors from "@colors"
import { checkForUserObject } from "services/Utils"

const user = checkForUserObject("user")

const CommentBoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 100px;
  padding: 0 20px;
  border-right: 1px solid ${colors.disabledInput};
  border-left: 1px solid ${colors.disabledInput};
`

const ImageContainer = styled.div`
  flex: 1 auto;
  margin-right: 10px;
  padding: 12px 0;
`

const Info = styled.div`
  flex: 1 100%;
  padding: 12px 0;
`

const CommentTextArea = styled(TextareaAutosize)`
  border: none;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.75;
  color: #354052;
  background: #fbfcfc;
  overflow: auto;
  outline: none;
  width: 100%;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  &:focus {
    outline: 0;
  }
  ::placeholder {
    color: #a5a8ad;
    font-size: 14px;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  border: 1px solid #e6e8e9;
  min-height: 50px;
  justify-content: space-between;
  align-content: center;
  padding: 0 20px;
`

const CommentAction = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.i`
  margin-right: 15px;
`

const PostButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
`

class CommentBox extends Component {
  state = {
    comment: "",
  }

  constructor(props) {
    super(props)
    this.textRef = React.createRef()
  }

  onComment = (e) => {
    this.setState({ comment: e.target.value })
  }

  postComment = (e) => {
    e.preventDefault()
    const { postCommentForFeed, feedId } = this.props
    const { comment } = this.state
    postCommentForFeed({ comment, feedId })
    this.setState({
      comment: "",
    })
  }

  componentDidMount() {
    //this.focusCommentBox()
  }

  render() {
    const { comment } = this.state
    const { type, isPostingComment, shouldAutoFocus } = this.props
    const isQuestion = (type === "post" || type === "intro" || type === "academy" || type === "referral")
    return (
      <div>
        <form onSubmit={this.postComment}>
          <CommentBoxWrapper>
            <ImageContainer>
              <CircularImage
                image={user.agent_image}
                name={`${user.firstname || ""} ${user.lastname || ""}`}
                size={28}
                fontSize="12px"
              />
            </ImageContainer>
            <Info>
              <CommentTextArea
                placeholder={`Write your ${isQuestion ? "reply" : "answer"}`}
                rows={4}
                maxRows={4}
                value={comment}
                onChange={this.onComment}
                autoFocus={shouldAutoFocus}
                id="standalone-feed-text-area"
              />
            </Info>
          </CommentBoxWrapper>
          <ActionWrapper>
            <CommentAction>
              {/* <Icon className={icons.genericCamera} />
              <Icon className={icons.genericVideoCamera} /> */}
            </CommentAction>
            <CommentAction>
              {isQuestion ? (
                <PostButton type="submit" width="120px" disabled={!comment}>
                  {isPostingComment ? <ThreeDotLoader color="#FFF" /> : "POST"}
                </PostButton>
              ) : (
                <PostButton width="150px" type="submit" disabled={!comment}>
                  {isPostingComment ? <ThreeDotLoader color="#FFF" /> : "Add Answer"}
                </PostButton>
              )}
            </CommentAction>
          </ActionWrapper>
        </form>
      </div>
    )
  }
}

export default CommentBox
