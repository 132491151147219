import React, { useState } from "react"
import styled from "styled-components"
import appContainer from "container/App"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import root from "window-or-global"
import { SubText, WhiteButton, BlueButton } from "../commonStyles"
import AppForm from "../AppForm"
import QRCode from "../QRCode"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  display: flex;
  max-width: 543px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${props => props.isAppFormVisible && `
    justify-content: space-between;
  `}
  transition: justify-content 0.5s ease-out;
`

const HeroTextWrap = styled.div`
  margin-bottom: 48px;
`

const H1 = styled.h1`
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  @media(min-width: 1100px) {
    max-width: 484px;
  }

  @media(max-width: 1099px) {
    color: #1C1C1F;
    font-family: ${MONTSERRAT};
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 141.4%; /* 45.248px */
    text-transform: capitalize;
    max-width: unset;
    margin: 0;
  }

  ${props => props.noMarginTop && `
    margin-top: 0px;
  `}
`

const CTAWrap = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 31px;

  @media(max-width: 1099px) {
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    flex-wrap: wrap;
  }
`

const FormWrap = styled.div`
  position: relative;
  top: -9px;
`

const LeftSection = ({
  appDownloadActionResponse,
  setFormVisible,
  width,
  toggleApplyModal,
  isCurrentUserOniOS,
  toggleEWebinarModal,
  user: userData,
}) => {
  const [appFormVisible, setAppFormVisible] = useState(false)
  const { data: appDownloadData, isFetching: submittingForm } = appDownloadActionResponse || {}
  return (
    <Wrap
      isAppFormVisible={appFormVisible}
    >
      <HeroTextWrap>
        <H1>Launch your</H1>
        <H1 noMarginTop>real estate legacy</H1>
        <SubText
          mobileWidth="285px"
          noMargin
        >
          Brand. Ownership. Growth. Unlock your potential.
        </SubText>
        <CTAWrap>
          <BlueButton
            className="explore_ownership_legacy_web"
            onClick={() => {
              //if mobile take them app-store
              if (width < 1099) {
                toggleApplyModal({
                  bool: true,
                  className: "form_explore_ownership_legacy_web",
                  accountType: "owner",
                  headerText: "With one click, you’ve taken a first step towards building your real estate legacy. ",
                  // subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                })
              } else {
                //show form on the right side
                const payload = {}
                payload.section_name = "real_estate_legacy"
                handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
                setFormVisible({
                  heroMQLFormData: {
                    bool: true,
                    className: "form_explore_ownership_legacy_web",
                  },
                })
              }
            }}
          >
            Talk with Radius
          </BlueButton>
          <WhiteButton
            className="join_our_community_legacy_web"
            onClick={(e) => {
              e.preventDefault()
              root.location.href = "https://www.radiusagent.com/brokerage-overview"
            }}
          >
            Brokerage Overview
          </WhiteButton>
        </CTAWrap>
      </HeroTextWrap>
      {appFormVisible && (
        <FormWrap>
          <QRCode
            toggle={setAppFormVisible}
            appDownloadSMSData={appDownloadData}
            submittingForm={submittingForm}
            className="form_join_our_community_legacy_web"
            width="543px"
          />
        </FormWrap>
      )}
    </Wrap>
  )
}

export default appContainer(ApplyFlowContainer(LeftSection))
