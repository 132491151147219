import React from "react"
import styled from "styled-components"
import { Title } from "dumbComponents/Academy/common/Helpers"
import CircularImage from "dumbComponents/common/CircularImage"
import colors from "@colors"

const SIZE = 5

const Wrap = styled.div`
  p {
    font-weight: 300;
    margin-top: 20px;
    strong {
      font-weight: 400;
    }
  }
`
const Item = styled.div`
  position: relative;
  left: -20px;
  display: inline-block;
`

const AgentImageWrap = styled.div`
  display: flex;
  margin-left: -10px;
  margin-top: 20px;
  cursor: pointer;

  ${props => props.isInFeed && `
    margin-left: 0px;
  `}
`

const MoreIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.dirtBrown};
  font-size: 12px;
  position: relative;
  left: -25px;
`

const getName = agent => agent && `${agent.firstname || "NA"} ${agent.lastname || "KS"}`

const RegisteredAgents = ({
  agents,
  toggleGuestList,
  isInFeed,
}) => {
  const originalArray = [...agents]
  const isMoreThanSize = agents && agents.length > SIZE
  const firstAgent = getName(agents[0])
  return (
    <Wrap>
      {agents && agents.length > 0 && (
        <React.Fragment>
          <Title isInFeed={isInFeed}>
            { !isInFeed ? "Attendees" : "Thank you to all who attended!"}
            
          </Title>
          <AgentImageWrap onClick={() => { toggleGuestList(true) }} isInFeed={isInFeed}>
            {agents.splice(0, SIZE).map((item, index) => (
              <Item
                key={item.agent_image}
                style={{ left: `-${index}0px`, zIndex: `${index}` }}
              >
                <CircularImage
                  name={`${item.firstname} ${item.lastname}`}
                  style={{ border: "1px solid #FFF" }}
                  image={item.agent_image}
                  size="48"
                />
              </Item>
            ))}
            {isMoreThanSize && (
              <MoreIcon>
                {`+${originalArray.length > 5 ? agents.length : agents.slice(0, SIZE).length} more`}
              </MoreIcon>
            )}
          </AgentImageWrap>
          {!isInFeed && (
            <p>
              <strong>{`${firstAgent}`}</strong>
              {`${originalArray.length > 1 ? ` and ${originalArray.length - 1} ${(originalArray.length - 1) === 1 ? "other" : "others"}` : ""}
                ${originalArray.length === 1 ? "has" : "have"} registered for this event. Don’t miss out!`}
            </p>
          )}
          
        </React.Fragment>
      )}
    </Wrap>
  )
}

export default RegisteredAgents
