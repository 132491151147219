import {
  call,
  put,
  select,
  take,
  race,
  takeLatest,
} from "redux-saga/effects"
import { get } from "lodash"
import root from "window-or-global"
import { isSuccess } from "services/Utils"
import { getNumbers } from "services/formUtils"
import { getIsUserATeamOwner } from "container/App/selector"
import cookie from "services/CookieStorage"
import { uploadImageAPI } from "container/Client/api"
import createToast from "@ui/Toast"
import * as ClientSaga from "container/Client/saga"
import * as ClientAction from "container/Client/actions"
import {
  showConfirmModalAction,
  hideConfirmModalAction,
} from "container/App/actions"
import { confirmAction } from "container/App/saga"
import * as ReferralActions from "./actions"
import * as APIs from "./api"

export function* getMembers(action = {}) {
  try {
    const agentId = cookie.load("agentId")
    const { referralReceiverId } = action.data || {}
    const res = yield call(APIs.getTeamMemberAPI, agentId, referralReceiverId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getTeamMembersAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getTeamMembersAction.failure(error))
  }
}

function* handleAssignAction(action) {
  try {
    const {
      clientID,
      referralID,
      selectedbrokerID,
      isFetchClient,
    } = action.data
    const payload = {
      agentIdsToAssign: [selectedbrokerID],
      referral_id: referralID,
    }
    const { pathname } = root.location
    const isPipeline = pathname === "/referrals/prospects/pipeline"
    const res = yield call(APIs.assignAgentAPI, clientID, payload)
    if (isSuccess(res)) {
      const { data: { response } } = res
      const { action: clientAction, client_id } = response
      if (clientAction === "reload" && client_id && !isPipeline) {
        root.location = `${APP_URL}/client/${client_id}/timeline?referralId=${referralID}`
        return
      }
      yield put(ReferralActions.assignAgentAction.success(response))
      yield put(ReferralActions.toggleAssignModalAction.call({
        isOpenAssignModal: false,
        agentID: null,
        referralId: null,
        currentClientID: null,
      }))
      if (isFetchClient) {
        yield put(ClientAction.fetchClientProfileAction.request(clientID))
      }
      yield put(ReferralActions.fetchReferralsAction.request({ type: "isa", referralCategories: "isaPipeline" }))
    }
  } catch (error) {
    yield put(ReferralActions.assignAgentAction.failure(error))
  }
}

function* handleInvite(action) {
  try {
    const {
      reInvite,
      type,
      id,
    } = action.data
    let payload = {}
    if (!reInvite) {
      const {
        formValues: {
          phone,
        },
      } = action.data
      payload = {
        ...action.data.formValues,
        phone: phone && getNumbers(phone),
        new_seat: type === "seat",
      }
    } else {
      yield put(ReferralActions.setCurrentReInviteMemberIDAction.call(id))
      payload = {
        re_invite: reInvite,
        member_agent_id: id,
      }
    }
    const res = yield call(APIs.inviteAgentForTeamPlanAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.inviteTeamMemberAction.success(response))
      yield put(ReferralActions.toggleTeamInviteModalAction.call({ isOpenInviteModal: false, type: "" }))
      if (!reInvite) {
        yield put(ReferralActions.fetchTeamMembersAction.request({
          label: "Last 30 days",
          days: "30",
        }))
      }
    }
  } catch (error) {
    yield put(ReferralActions.inviteTeamMemberAction.failure(error))
    const { error: errorRes } = error.data
    const { message } = errorRes || {}
    if (message) {
      createToast(message, "error")
    } else {
      yield put(ReferralActions.toggleTeamInviteModalAction.call({ isOpenInviteModal: false, type: "" }))
    }
  }
}

function* handleInviteResponse(action) {
  try {
    const {
      payload,
      ownerId,
    } = action.data
    const res = yield call(APIs.handleInviteResponseAPI, ownerId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.handleInviteResponseAction.success(response))
    }
  } catch (error) {
    const errorObj = get(error, "data.error")
    yield put(ReferralActions.handleInviteResponseAction.failure(errorObj))
  }
}

function* fetchAgentsBasedOnPipeLineCall() {
  try {
    const isUserATeamOwner = yield select(state => getIsUserATeamOwner(state))
    if (isUserATeamOwner) {
      yield put(ReferralActions.getTeamMembersAction.request())
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleRemoveMember(action) {
  const { data } = action
  const { id, isCancelInvite } = data
  yield put(ReferralActions.removeTeamMemberModal.call({
    question: "Are You Sure?",
    isShowModal: true,
    isCancelInvite,
    currentlyRemovingID: id,
  }))
  const { confirm } = yield race({
    confirm: take(ClientSaga.afterConfirmSteps),
  })
  if (confirm.data) {
    try {
      const res = yield call(APIs.handleRemoveMemberAPI, id)
      if (isSuccess(res)) {
        yield put(ReferralActions.removeMemberAction.success(res))
        yield put(ReferralActions.showRemoveMemberSuccessModalAction.call())
        yield put(ReferralActions.fetchTeamMembersAction.request({
          label: "Last 30 days",
          days: "30",
        }))
      }
    } catch (e) {
      yield put(ReferralActions.removeMemberAction.failure(e))
    }
  }
}

function* handleRemoveMemberSuccessModal() {
  yield put(ReferralActions.hideRemoveMemberSuccessModalAction.call())
}

function* fetchTeamInfo() {
  try {
    const res = yield call(APIs.getTeamInfoAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getTeamInfoAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getTeamInfoAction.failure(error))
  }
}

function* handleFetchSubmittedMetrics(action) {
  try {
    const {
      days,
      sourceString,
      label,
    } = action.data
    const res = yield call(APIs.fetchSubmittedMetricsAPI, days, sourceString)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.setCurrentSourcesFilterAction.call({ sourceString }))
      yield put(ReferralActions.setCurrentTeamFilterAction.call({ value: days, label }))
      yield put(ReferralActions.fetchSubmittedMetricsAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.fetchSubmittedMetricsAction.failure(error))
  }
}

function* handleTeamImageUpload(action) {
  try {
    // Upload Image first and get the upload url
    const { file, ownerId, type } = action.data
    const fd = new FormData()
    fd.append("imageFile", file)
    const data = {
      filePath: `team-logo/${ownerId}`,
      config: {
        bucket: "team-logo",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    const imageRes = yield call(uploadImageAPI, fd)
    if (isSuccess(imageRes)) {
      const { response: imageUploadResponse } = imageRes.data
      const { imageURL } = imageUploadResponse
      const paylod = {
        logo_url: imageURL,
      }
      const uploadTeamImageRes = yield call(APIs.addTeamLogoAPI, ownerId, paylod)
      if (isSuccess(uploadTeamImageRes)) {
        const { response: uploadTeamImageResponse } = uploadTeamImageRes.data
        yield put(ReferralActions.toggleAddTeamLogoAction.call({ isShowTeamLogoModal: false }))
        yield put(ReferralActions.updateTeamImageAction.success(uploadTeamImageResponse))
        yield put(ReferralActions.getTeamInfoAction.success(uploadTeamImageResponse))
      }
    }
  } catch (error) {
    yield put(ReferralActions.updateTeamImageAction.failure(error))
  }
}

function* handleChangeRole(action) {
  try {
    const {
      value = "",
      memberId,
      agentId,
      seatCost,
    } = action.data
    const payload = {
      member_agent_id: memberId,
      role: value && value.toUpperCase(),
    }
    if (value === "ADMIN" && seatCost > 0) {
      yield put(showConfirmModalAction.call({
        title: "Are you sure?",
        body: `You’re about to upgrade this user to an admin
          on your team for $${seatCost} per month. They’ll be able to
          submit leads, access the pipeline, and assign leads.`,
        successButtonText: "Yes, upgrade",
      }))
      const { confirm } = yield race({
        confirm: take(confirmAction),
      })
      const { data: isConfimed } = confirm
      yield put(hideConfirmModalAction.call())
      if (!isConfimed && value === "ADMIN") {
        return false
      }
    }
    yield put(ReferralActions.toggleChangeRoleLoaderAction.call(memberId))
    const res = yield call(APIs.changeTeamRoleAPI, agentId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.fetchTeamMembersAction.request({
        label: "Last 30 days",
        days: "30",
      }))
      yield put(ReferralActions.changeTeamRoleAction.success(response))
      yield put(ReferralActions.toggleChangeRoleLoaderAction.call(false))
      return false
    }
  } catch (error) {
    const message = get(error, "data.error.message")
    yield put(ReferralActions.toggleChangeRoleLoaderAction.call(false))
    yield put(ReferralActions.changeTeamRoleAction.failure(error))
    createToast(message, "error")
    return false
  }
}

export default function* main() {
  yield takeLatest(ReferralActions.getTeamMembersAction.REQUEST, getMembers)
  yield takeLatest(ReferralActions.assignAgentAction.REQUEST, handleAssignAction)
  yield takeLatest(ReferralActions.inviteTeamMemberAction.REQUEST, handleInvite)
  yield takeLatest(ReferralActions.handleInviteResponseAction.REQUEST, handleInviteResponse)
  yield takeLatest(ReferralActions.fetchReferralsAction.SUCCESS, fetchAgentsBasedOnPipeLineCall)
  yield takeLatest(ReferralActions.toggleRemoveTeamMemberModalAction.type, handleRemoveMember)
  yield takeLatest(ReferralActions.toggleRemoveMemberSuccessModalAction.type, handleRemoveMemberSuccessModal)
  yield takeLatest(ReferralActions.fetchSubmittedMetricsAction.REQUEST, handleFetchSubmittedMetrics)
  yield takeLatest(ReferralActions.getTeamInfoAction.REQUEST, fetchTeamInfo)
  yield takeLatest(ReferralActions.updateTeamImageAction.REQUEST, handleTeamImageUpload)
  yield takeLatest(ReferralActions.changeTeamRoleAction.REQUEST, handleChangeRole)
}
