import axios from "axios"

export const fetchAllLeadIntegrationSourcesAPI = () => axios.get(`${API_URL}crs/referral-integrations`)
export const updateLeadIntegrationSourceAPI = payload => axios.post(`${API_URL}crs/referral-integrations`, payload)
export const updateReferralSettingsAPI = payload => axios.put(`${API_URL}user/referral-settings`, payload)
export const getReferralSettingsAPI = () => axios.get(`${API_URL}user/referral-settings`)
export const getUniqueEmailAPI = () => axios.get(`${API_V2_URL}user/unique-email`)
export const getRefreshTokenFromAuthCodeAPI = payload => axios.post(`${API_URL}user/sync-gmail-for-web`, payload)
export const syncGmailAPI = gmail_refresh_token => axios.post(`${API_URL}user/activate-gmail`, gmail_refresh_token)
export const deactivateGmailAPI = payload => axios.get(`${API_URL}user/deactivate-gmail`, payload)
export const unsubscribeFromEmailAPI = (hashId, payload) => axios
  .post(`${API_V2_URL}unsubscribe-notifications/${hashId}`, payload)
export const getNotificationSettingsAPI = () => axios.get(`${API_V3_URL}notification-settings`)
export const updateNotificationSettingsAPI = payload => axios.put(`${API_V2_URL}notification-settings`, payload)
export const updateNotificationSettingsV3API = (payload, id) => axios.put(`${API_V3_URL}notification-settings/${id}`, payload)
export const requestChangePasswordAPI = payload => axios.put(`${API_URL}user/change-password`, payload)
export const updateDisplayEmailAPI = payload => axios.put(`${API_URL}user/display-email`, payload)
export const updatePhoneAPI = payload => axios.put(`${API_URL}user/update-phone`, payload)
export const saveMultipleEmailsAndPhoneAPI = payload => axios.put(`${API_V2_URL}user/info/update`, payload)
export const triggerEmailRequestAPI = () => axios.post(`${API_URL}teams/upgrade`)
export const fetchRadiusPhoneNumberAPI = () => axios.get(`${API_URL}user/radius-phone-number`)
export const inviteAgentPromoAPI = payload => axios
  .post(`${API_URL}subscription-invites`, payload)
export const reInviteAgentPromoAPI = payload => axios
  .put(`${API_URL}resend-subscription-invites`, payload)
export const getInvitedAgentPromoAPI = () => axios
  .get(`${API_URL}subscription-invites`)
export const triggerCrossSellAPI = payload => axios
  .put(`${API_V2_URL}user/last-cross-sell-interest-shown`, payload)
export const fetchAssistNotesAPI = () => axios
  .get(`${API_URL}assist-preferences`)
export const getAssistPreferenceAPI = () => axios
  .get(`${API_URL}user/referral-settings`)
export const saveAssistPreferenceAPI = payload => axios
  .put(`${API_URL}user/referral-settings`, payload)
export const tokenGenerationAPI = () => axios
  .post(`${API_URL}user/api-key`)
export const newTokenGenerationAPI = () => axios
  .get(`${API_URL}user/api-key`)

export const saveAssistNoteAPI = (sourceId, payload) => axios
  .put(`${API_URL}assist-preferences/${sourceId}`, payload)

export const saveStripeTokenAPI = payload => axios.post(`${API_URL}stripe/customers`, payload)

export const saveStripeSubscriptionAPI = payload => axios.post(`${API_V2_URL}stripe/subscription/checkout`, payload)

export const getCardDetailsAPI = () => axios.get(`${API_URL}stripe/customers/payment`)

export const getSubscribedAddonsAPI = () => axios.get(`${API_V2_URL}stripe/subscribed-add-ons`)

export const getGoogleAuthTokenAPI = () => axios.get(`${API_V2_URL}access-token`)

export const verifyAuthTokenAPI = payload => axios.post(`${API_URL}user/email/verify-add-card-auth-code`, payload)
export const deleteAccountAPI = payload => axios.post(`${API_V2_URL}user/deleteUser-request`, payload)

export const sendAddCreditCardLinkV2API = payload => axios.post(`${API_URL}/email/add-card-details`, payload)

export const getClientSecretAPI = () => axios.post(`${API_URL}stripe/customers/secret`)
