import React from "react"

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

const FileInput = ({
  input: {
    value: omitValue,
    onChange,
    onBlur,
    ...inputProps
  },
  multiple,
  version2,
  customOnChange,
  meta: omitMeta,
  ref,
  ...props
}) => (
  <input
    version2={version2}
    multiple={multiple}
    onChange={(event) => {
      if (multiple) {
        onChange(event.target.files)
        customOnChange(event.target.files)
      } else {
        onChange(event.target.files[0])
        customOnChange(event.target.files[0])
      }
    }}
    onBlur={adaptFileEventToValue(onBlur)}
    type="file"
    {...props.input}
    {...props}
  />
)

export default FileInput
