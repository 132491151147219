import { Checkbox } from "antd"
import React from "react"
import {
  FilterWrap,
} from "./styles"

const OPTIONS = [
  {
    label: "Prospect",
    value: "incomplete",
    colorHex: "#77CC66",
  },
  {
    label: "Coming Soon",
    value: "coming_soon",
    colorHex: "#FFD700",
  },
  {
    label: "On Market",
    value: "active_listing",
    colorHex: "#007FFF",
  },
  {
    label: "Back on Market",
    value: "back_on_market",
    colorHex: "#808080",
  },
  {
    label: "Pending",
    value: "pending",
    colorHex: "#FFB266",
  },
  {
    label: "Canceled",
    value: "listing_canceled",
    colorHex: "#FF6347",
  },
  {
    label: "Expired",
    value: "listing_expired",
    colorHex: "#FF8833",
  },
]

const Status = ({
  onChange,
  value,
}) => (
  <FilterWrap
    style={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Checkbox.Group
      options={OPTIONS}
      onChange={onChange}
      value={value}
    />
  </FilterWrap>
)

export default Status

