import React from "react"
import Box from "@ui/BuilderComponents/BoxV2/index"
import dynamic from "next/dynamic"
import colors from "@colors"
import root from "window-or-global"
import { get, isEmpty } from "lodash"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { convertToHTML } from "services/Utils"
import { preDesktopWidth, minDesktopWidth, minMediumDesktopWidth } from "shared/consts"
import RealtyOurAgentSEO from "images/Graphics/LandingPagePreviews/HomePagePreview.png"
import TeamMembersListing from "dumbComponents/TeamProfile/EditPage/TeamMembers"
import BrokerJobsContainer from "container/BrokerJobs"
import LargeText from "@ui/BuilderComponents/LargeText/index"
import { withRouter, Redirect } from "react-router"
import TeamContainer from "container/Realty/TeamProfileContainer"
import PreviewPage from "dumbComponents/RealtyV3/RARRecruitment/pages/PreviewPage"
import {
  RichTextEditorWrapper,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"
import ImageBlock from "./ImageBlock"
import { ATC } from "@fonts"

/*
  Team Profile SSR
  SSR First Component.
  Make sure load times are less.
  SEO Component - done
*/

const Wrap = styled.div`
  width: 100%;

  ${preDesktopWidth`
    width: 100%;
  `}

  ${minDesktopWidth`
     width: 100%:
  `}

  @media(min-width: 1280px){
    width: 90%;
    margin: 0 auto;
    ${props => props.removeMaxWidth && `
      width: 100%;
      margin: 0 auto;
    `}
  }

  #team-profile-page-container {
    @media(max-width: 767px) {
      width: 100%;
      margin: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`

class TeamProfileSSR extends React.Component {
  // eslint-disable-next-line consistent-return
  componentDidMount() {
    const {
      getTeamDetailsViaName,
      getTeamDetailsViaNameResponse = {},
      history,
      teamName,
      shouldRerender = false,
      toggleEditMode,
      getSpecificCreatedPublicJobs,
      jobSlug,
    } = this.props || {}

    const {
      data,
    } = getTeamDetailsViaNameResponse || {}

    const {
      location: {
        pathname,
      },
    } = history || {}

    const splitPath = pathname && pathname.split("/")

    const name = teamName || splitPath && splitPath[splitPath.length - 1]

    if (!name) {
      return <Redirect to="/" />
    }

    if (isEmpty(data) || shouldRerender) {
      getTeamDetailsViaName({
        name,
      })
      toggleEditMode(false)
    }

    if (jobSlug) {
      getSpecificCreatedPublicJobs({
        skip: 0,
        limit: 10,
        slug: jobSlug,
      })
    }
  }

  getContent = (data) => {
    let content = ""
    try {
      content = convertToHTML(data)
    } catch (err) {
      content = data
      console.log("error======>>>>>>>", err)
    }
    return content
  }

  getTeamBannerImage = imageArray => imageArray && imageArray.filter(image => image.type === "banner")[0]

  getTeamOtherImages = imageArray => imageArray && imageArray.filter(image => image.type !== "banner")

  render() {
    const {
      getTeamDetailsViaNameResponse = {},
      brokerageSpecificJobsDetails,
      pageContentFromProps,
      hideAboutTeam,
      showJobDetails,
      removeMaxWidth,
    } = this.props || {}

    const {
      data: teamDetails,
    } = getTeamDetailsViaNameResponse || {}

    const {
      isFetching: fetchingJobDetails,
    } = brokerageSpecificJobsDetails || {}

    const fetchedTeamDetails = teamDetails || {}

    const meta = {
      description: `${fetchedTeamDetails.profile_name}: A real estate team led by real estate professionals`,
      keywords: "Real Estate Agents and Teams",
      type: "website",
      title: `${fetchedTeamDetails.profile_name}: Real Estate Team`,
      image: RealtyOurAgentSEO,
      url: `${WEB_URL}teamprofile/${fetchedTeamDetails.profile_name}`,
    }

    const otherImages = this.getTeamOtherImages(fetchedTeamDetails.team_images)

    return (
      <Wrap removeMaxWidth={removeMaxWidth}>
        {!showJobDetails && (
          <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta property="title" content={meta.title} />
            <meta property="og:url" content={meta.url} />
            <meta property="url" content={meta.url} />
            <meta property="og:description" content={meta.description} />
            <meta property="description" content={meta.description} />
            <meta property="og:image" content={meta.image} />
            <meta property="image" content={meta.image} />
            <meta property="keywords" content={meta.keywords} />
          </Helmet>
        )}
        <Box
          displayStyle="flex"
          justifyContent="center"
          alignItems="center"
          className="landing-page-wrap"
          id="team-profile-page-container"
        >
          <Box
            displayStyle="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="48px"
            paddingLargeDesktop="0px 48px 20px"
            paddingMinDesktop="0px 48px 20px"
            paddingTablet="0px 28px 0px 28px"
            paddingMobile="0px"
          >
            <ImageBlock
              currentTeamLogo={fetchedTeamDetails.icon_url || ""}
              currentBanner={this.getTeamBannerImage(fetchedTeamDetails.team_images)}
              otherImages={otherImages}
            />
            <Box
              displayStyle="flex"
              flexDirection="column"
              flexGap="10px"
              overflow="hidden"
              customDivStyling={otherImages && otherImages.length <= 0 ? {
                marginTop: "-100px",
              } : {}}
            >
              {showJobDetails && !fetchingJobDetails && brokerageSpecificJobsDetails && brokerageSpecificJobsDetails.data && (
                <PreviewPage
                  isIndividualJobPage
                  key={brokerageSpecificJobsDetails.data.id}
                  editable={false}
                  isLandingPage
                  mt="0px"
                  pageContentFromProps={pageContentFromProps}
                  selectedJobId={brokerageSpecificJobsDetails.data.id}
                  enableTeamProfile
                  noPadding
                  hideTeamLink
                />
              )}
              {!hideAboutTeam && (
                <>
                  <LargeText
                    content={`About ${fetchedTeamDetails.name || ""}`}
                    largeTextFamily="Butler"
                    color={colors.black}
                    minDesktopLargeTextSize="20px"
                    minLargeDesktopLargeTextSize="24px"
                    preDesktopLargeTextSize="20px"
                    minTabletLargeTextSize="18px"
                    largeTextWeight="bold"
                  />
                  {fetchedTeamDetails.about_team && (
                    <RichTextEditorWrapper>
                      <span dangerouslySetInnerHTML={{ __html: convertToHTML(fetchedTeamDetails.about_team || "") }}></span>
                    </RichTextEditorWrapper>
                  )}
                </>
              )}
              {fetchedTeamDetails.other_benefits && (
                <>
                  <LargeText
                    content="Team Benefits"
                    largeTextFamily="Butler"
                    color={colors.black}
                    minDesktopLargeTextSize="24px"
                    minLargeDesktopLargeTextSize="24px"
                    preDesktopLargeTextSize="20px"
                    minTabletLargeTextSize="18px"
                    largeTextWeight="bold"
                  />
                  <RichTextEditorWrapper>
                    <span dangerouslySetInnerHTML={{ __html: convertToHTML(fetchedTeamDetails.other_benefits || "") }}></span>
                  </RichTextEditorWrapper>
                </>
              )}
              <TeamMembersListing
                title="TEAM MEMBERS"
                teamMembers={fetchedTeamDetails.team_members}
                ff={ATC}
                fs="18px"
              />
            </Box>
          </Box>
        </Box>
      </Wrap>
    )
  }
}

export default withRouter(BrokerJobsContainer(TeamContainer(TeamProfileSSR)))
