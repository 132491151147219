import React from "react"
import styled from "styled-components"
import { HeroText } from "../commonStyles"
import GradientBox from "../GradientBox/index"
import MarinSection from "./MarinSection"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  //display: flex;
  width: 100%;
  // align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 150px;

  @media(max-width: 1099px) {
    // padding: 0px;
    gap: 20px;
  }
`

const GradientWrap = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // flex-wrap: wrap;
  gap: 32px;
  margin-top: 50px;

  @media(max-width: 1099px) {
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`

const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DISPLAY_OBJECT = [{
  heroText: "85,000",
  subText: "community members",
}, {
  heroText: "99%",
  subText: "team retention ",
}, {
  heroText: "$28.5B",
  subText: "in referrals shared ",
}, {
  heroText: "370%",
  subText: "in brokerage growth",
}]

const TextWrap = styled.div``

const H2Text = styled.h2`
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  @media(max-width: 1099px) {
    font-size: 24px;
  }
`

const PText = styled.p`
  color: #1C1C1F;
  text-align: center;
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media(max-width: 1099px) {
    font-size: 8.63px;
  }
`

const SectionFour = ({
  width,
}) => (
  <Wrap className="container">
    <TextWrap>
      <HeroText
        spanStyles
      >
        <span>Radius</span>
        {" "}
        by the Numbers
      </HeroText>
    </TextWrap>
    <GradientWrap>
      {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
        <GradientBox
          minWidth="261px"
          mobileMinWidth="168px"
          mobileFlexJC="center"
          largeDesktopMinWidth="261px"
          minHeight="160px"
          smallDesktopWidth="233px"
          fixedMobileWidth="168px"
        >
          <InnerWrap>
            <H2Text>{item.heroText}</H2Text>
            <PText>{item.subText}</PText>
          </InnerWrap>
        </GradientBox>
      ))}
    </GradientWrap>
    <MarinSection />
  </Wrap>
)

export default SectionFour
