import React, { useRef } from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
  AgentSearch,
  HiddenInputField,
  InputLocation,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"

const ListingAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteListingAgent,
  isFirst,
}) => {
  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
    }

    return initValues
  }

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Listing Agent Form"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            const id = prefilledData ? prefilledData.listing_agent_id : ""
            handleEditModeChange(id)
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V2/minus.svg`} alt="minus" />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_listing_agent_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
      >

        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="is_primary" />

        <AgentSearch
          name="listing_agent_first_name"
          inputType="text"
          placeholder="First Name"
          customRules={{}}
          isFormField
          formRef={formRef}
          firstNameLabel="listing_agent_first_name"
          lastNameLabel="listing_agent_last_name"
          isFirst={isFirst}
        />

        <InputField
          name="listing_agent_last_name"
          inputType="text"
          placeholder="Last Name"
          customRules={{}}
        />

        <InputField
          name="email"
          inputType="email"
          type="email"
          placeholder="Email*"
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone*"
        />

        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="License Number*"
        />

        <InputField
          name="brokerage"
          inputType="text"
          type="text"
          placeholder="Brokerage*"
        />

        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Brokerage License number*"
        />
        
        {/* <InputField
          name="brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Address*"
        /> */}

        <InputLocation
          name="brokerage_address"
          inputType="text"
          type="text"
          placeholder="Brokerage Address*"
          formRef={formRef}
        />

        <InputField
          name="apt"
          inputType="text"
          type="text"
          placeholder="Apartment / Unit / Suite No.*"
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
        />

        <InputField
          name="state"
          inputType="text"
          placeholder="State"
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
        />
        <CTAWrap>
          {prefilledData && prefilledData.listing_agent_id && (
            <Form.Item>
              <DangerButton
                type="button"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteListingAgent(prefilledData.listing_agent_id)}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )} 

          <Form.Item>
            <BlueButton
              type="submit"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default ListingAgentForm
