import React from "react"
import NewFeature from "images/newFeature.png"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
  ImageSpan,
} from "./commonStyles"
import Mortgage from "../Icons/NewIcons/Mortgage"

const MortgageTab = ({
  routeArray,
}) => (
  <InnerTab pt="3px" isActive={routeArray[1] === "mortgage"}>
    <div className="icon-block-new">
      <Mortgage />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/mortgage"
        isActive={routeArray[1] === "mortgage"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_mortgageRAR_web")
          }
        }}
      >
        Mortgage
        {false && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )}
      </NavLink>
    </div>
  </InnerTab>
)

export default MortgageTab
