import React, { Component } from "react"
import styled from "styled-components"
import Button from "@ui/LoaderButton"
import { LoadScript } from "services/Utils"
import { GoogleOutlined } from "@ant-design/icons"

// const scopes = [
//   "https://www.googleapis.com/auth/userinfo.email",
//   "https://www.googleapis.com/auth/userinfo.profile",
//   "https://www.google.com/m8/feeds",
//   "https://www.googleapis.com/auth/gmail.modify",
//   "https://www.googleapis.com/auth/calendar",
//   "https://www.googleapis.com/auth/contacts.readonly",
// ]

const scopes = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/contacts.readonly",
]

const StyledButton = styled(Button)`
  border: none;
  box-shadow: 0px 1px 3px rgb(180, 180, 180);
  border-radius: 2px;
  margin: ${props => props.margin ? props.margin : 0};
  padding: 8px 10px 8px 10px;
  color: #777;
  width: 150px;
  font-weight: 300;
  text-align: left;
  background: #fff;
  display: flex;
  align-items: center;
  margin-right: ${props => props.mr};
  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
`

const GoogleButton = styled(Button)`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`

const StyledImg = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 15px;
`

class GoogleSignIn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gapiLoaded: false,
    }
  }

  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    LoadScript("https://apis.google.com/js/client.js", () => {
      this.setState({ gapiLoaded: true })
    })
  }

  checkAuth() {
    window.gapi.auth.authorize(
      {
        client_id: GOOGLE_CLIENTID,
        scope: scopes,
        response_type: "code",
        access_type: "offline",
        approval_prompt: "force",
        redirect_uri: "postmessage",
        immediate: false,
      },
      (data) => {
        window.handleAuthResult(data)
      }
    )
  }

  googleSignIn = () => {
    if (window.gapi.client) {
      window.gapi.client.setApiKey(GOOGLE_APIKEY)
      window.setTimeout(this.checkAuth, 1)
    }
  }

  render() {
    const { gapiLoaded } = this.state
    const {
      text,
      sync,
      mr,
      type,
      loading,
      googleAuthToken,
      resetGoogleContacts,
      disconnectText,
    } = this.props
    if (gapiLoaded) {
      window.handleAuthResult = (authResult) => {
        console.log("==authResult==", authResult)
        const data = { code: authResult.code, redirect_uri: "postmessage" }
        sync(data)
      }
    }

    return (
      <>
        {(type && type === "theme-1") ? (
          <>
            {googleAuthToken ? (
              <GoogleButton
                bsStyle="marineBLueFill"
                padding="8px 10px"
                width="max-content"
                fontSize="14px"
                fontWeight="500"
                isShowLoader={loading}
                loaderOption={{
                  color: "#3B60E4",
                }}
                onClick={resetGoogleContacts}
              >
                {disconnectText || "Disconnect"}
                <GoogleOutlined />
              </GoogleButton>

            ) : (
              <GoogleButton
                bsStyle="marineBLueOutlined"
                padding="8px 10px"
                width="max-content"
                fontSize="14px"
                fontWeight="500"
                isShowLoader={loading}
                loaderOption={{
                  color: "#3B60E4",
                }}
                onClick={this.googleSignIn}
              >
                {text}
                <GoogleOutlined />
              </GoogleButton>
            )}
          </>
        ) : (
          <StyledButton onClick={this.googleSignIn} mr={mr}>
            <StyledImg src="//d2fedz0by71ckz.cloudfront.net/images/google_logo.png" alt="Google sign in" />
            {text}
          </StyledButton>
        )}
      </>
    )
  }
}

export default GoogleSignIn
