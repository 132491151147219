import { Upload } from "antd"
import ImgCrop from "antd-img-crop"
import React, { useState, useEffect } from "react"
const App = ({
  uploadImage,
  handlePostCrop,
  handleImageRemoval,
  dataList,
}) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      setFileList(dataList)
    }
  }, [dataList])

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const onRemove = () => {

  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <ImgCrop
      zoomSlider
      rotationSlider
      aspectSlider
      showReset
      cropShape="round"
      modalClassName="antd_radius_crop_modal"
      modalOk="Choose"
      modalCancel="Cancel"
      onModalOk={handlePostCrop}
    >
      <Upload
        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        customRequest={uploadImage}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        maxCount={1}
      >
        {fileList.length < 1 && "+ Upload"}
      </Upload>
    </ImgCrop>
  )
}

export default App
