import styled from "styled-components"
import { Collapse } from "@ui/antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
`

export const SideNavWrap = styled.div`
  display: flex;
  align-items: end;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;

  ${props => props.isActive && `
    background: var(--Tabselected, #F4F4FF);
  `}
`

export const SideNavTitleWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const SideNavTitle = styled.p`
  margin: 0;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;

  ${props => props.isActive && `
    color: var(--Color-Primary-Primary, #5A5FF2);
  `}
`

export const StyledCollapse = styled(Collapse)`
  background-color: white;
  position: relative;
  .ant-collapse-content {
    border-top: 0px;
    border-radius: 9px !important;
  }

  border-radius: 9px !important;

  .ant-collapse-item {
    border-radius: 9px !important;
  }

  .ant-collapse-header-text {
    font-size: 16px;
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
  }
`

const { Panel } = Collapse

export const StyledPanel = styled(Panel)`
  margin-bottom: 20px;
  position: relative;
`
export const ValidatorNumber = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 141%;
`

export const StatusWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const StatusTag = styled.div`
  background: #5b5ff3;
  padding: 8px;
  white-space: nowrap;
  border-radius: 8px;
  font-size: 13px;
  color: white;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  width: max-content;
`

export const ValidationNumberCircle = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #F08068;
  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 141%; /* 14.1px */
`

export const StateDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  p {
    color: var(--BG-Dark-mode, #292A37);
    text-align: right;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  h2 {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`

export const StateWrap = styled.div`
  display: flex;
  width: 176px;
  align-items: flex-start;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;

  div {
    height: 45px;
    /* padding: 2px; */
    display: flex;
    align-items: center;
  }
`

export const GreyState = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);
  background: var(--Color-Neutral-100, #F1F1FE);
  padding: 10px;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  p {
    color: var(--Gray-500, #667085);

    /* Text md/Normal */
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`
export const State = styled.div`
  background: #fff;
  display: flex;
  align-items: center;

  h2 {
    color: var(--Gray-500, #667085);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`