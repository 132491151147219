import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import colors from "@colors"
import icons from "@icons"
import RightArrow from "images/icons/rightArrow_33.svg"
import { withRouter } from "react-router-dom"
import { MOBILE_BREAK_POINT } from "shared/consts"
import LinkButton from "@ui/LinkButton"

const Wrap = styled.div`
  background: #0B8DEC;
  font-size: 14px;
  color: white;
  display: flex;
  padding: 15px;

  & > p {
    flex: 1;
    text-align: center;
    margin: 0px;
    padding: 0px;
  }

  button {
    color: white;
  }

  span img {
    position: relative;
    top: 2px;
    left: 7px;
    height: 13px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    & > p > span {
      display: none;
    }
  }
`

const CloseButton = styled.div`
`

const FinishItNowButton = styled.button`
  background: none;
  padding: 0px 5px;
  margin: 0px;
  color: ${colors.primaryColor};
  text-decoration: underline;
  border: none;
  font-size: inherit;
  cursor: pointer;
  transition-duration: 0.2s;
`

class IncompleteProfileBanner extends React.PureComponent {
  componentWillUnmount() {
    const { toggleIncompleteProfileBanner } = this.props
    toggleIncompleteProfileBanner(false)
  }

  render() {
    const {
      history,
      toggleIncompleteProfileBanner,
    } = this.props
    return (
      <Wrap>
        <p>
          <span>Welcome to Radius! Your profile isn’t finished.</span>
          <FinishItNowButton
            type="button"
            onClick={() => {
              toggleIncompleteProfileBanner(false)
              history.push("/incomplete-profile")
            }}
          >
            Finish it now
            <span>
              <img src={RightArrow} alt="rightArrow.svg" />
            </span>
          </FinishItNowButton>
        </p>
        <CloseButton
          onClick={() => {
            toggleIncompleteProfileBanner(false)
          }}
        >
          <i className={icons.arrowsClose}></i>
        </CloseButton>
      </Wrap>
    )
  }
}

export default withRouter(IncompleteProfileBanner)
