import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { INTER } from "@fonts"
import GoToAppContainer from "../../container/StandAlone/GoToApp"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
  gap: 0px;
  max-width: 400px;
  justify-content: center;
`

const StyledLink = styled.a`
  display: flex;
  height: 48px;
  max-width: 250px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${props => props.backgroundColor || "#fc466b"};
  cursor: pointer;
  color: #fdfdfd;
  text-align: center;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  margin-top: 16px;

  &:hover {
    color: #fdfdfd;
  }
`

const StyledHeading = styled.h2`
  color: #232338;
  font-family: ${INTER};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
`

const StyledText = styled.p`
  color: #7d7da9;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin-bottom: 0;
`

const StyledImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 0;
  outline: 0;
`

const StyledDownloadApp = styled.a`
  color: #7d7da9;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
`

const GoToApp = (props) => {
  const { getAppSettings, getAppSettingsResponse } = props
  const [isAndroid, setIsAndroid] = useState(false)
  const [isIOS, setIsIOS] = useState(false)

  // Move device detection to useEffect to ensure it only runs client-side
  useEffect(() => {
    // Check for device type only after component mounts
    setIsAndroid(/Android/i.test(navigator.userAgent))
    setIsIOS(/iPhone|iPad|iPod/i.test(navigator.userAgent))
  }, [])

  const urlParams = new URLSearchParams(window.location.search)
  const appId = urlParams.get("appId")
  const authCode = urlParams.get("authcode")

  useEffect(() => {
    getAppSettings({
      appId,
    })
  }, [])

  // Get appropriate app scheme based on device
  const getAppScheme = () => {
    if (!authCode) return "#"

    let baseScheme
    if (isAndroid) {
      baseScheme = getAppSettingsResponse?.data?.app_scheme_droid
    } else if (isIOS) {
      baseScheme = getAppSettingsResponse?.data?.app_scheme_ios
    }

    if (!baseScheme) return "#"

    return `${baseScheme}authenticate?authCode=${authCode}`
  }

  const appScheme = getAppScheme()

  // if (!appId || !authCode) {
  //   return (
  //     <Wrapper>
  //       <StyledText>
  //         Invalid URL, please try again. Missing
  //         {!appId ? " app id" : ""}
  //         {!authCode ? " auth code" : ""}
  //       </StyledText>
  //     </Wrapper>
  //   )
  // }

  return (
    <Wrapper>
      {getAppSettingsResponse?.data?.app_logo
      && getAppSettingsResponse?.data?.one_link_url
      && (getAppSettingsResponse?.data?.primary_color || "#fc466b") ? (
        <>
          {getAppSettingsResponse?.data?.app_logo && (
            <StyledImage src={getAppSettingsResponse?.data?.app_logo} />
          )}

          <StyledHeading>Sign in to Radius Client</StyledHeading>

          {getAppSettingsResponse?.data?.primary_color
            && getAppSettingsResponse?.data?.one_link_url
            && (appScheme === "#" ? (
              <StyledText>Please open the page on your mobile phone.</StyledText>
            ) : (
              <>
                <StyledText>
                  Click the button below to finish signing in on the radius client mobile app
                </StyledText>
                <StyledLink
                  href={appScheme}
                  backgroundColor={getAppSettingsResponse?.data?.primary_color || "#fc466b"}
                >
                  Open Radius Client app
                </StyledLink>
                <StyledDownloadApp href={getAppSettingsResponse?.data?.one_link_url}>
                  Do not have the app yet? Download now.
                </StyledDownloadApp>
              </>
            ))}
        </>
        ) : (
          <StyledText>Loading...</StyledText>
        )}
    </Wrapper>
  )
}

export default GoToAppContainer(GoToApp)
