import React, { useState } from "react"
import axios from "axios"
import DebouncedSelect from "./Components/debouncedSelect"
import {
  Wrap,
} from "./styles"

const MLSTabs = ({
  onSelectMLS,
}) => {
  const [searchValue, setValue] = useState([])
  const [isFetching, setFetching] = useState(false)

  const fetchUserList = async (search) => {
    if (!search) return []

    const url = `${API_URL}get-mls-data?mlsId=${search}`

    setFetching(true)
    const mapped = await axios.get(url)
      .then((res) => {
        setFetching(false)
        const {
          response,
        } = res.data || {}
        return response && response.data || []
      })

    const options = mapped && mapped.map((item) => {
      return {
        ...item,
        value: item.Id,
        label: item.UnparsedAddress,
        address: item.UnparsedAddress,
        id: item.Id,
        image: item.Media[0]?.MediaURL || "",
        typedMLSId: search,
      }
    })

    return options
  }

  return (
    <Wrap>
      <DebouncedSelect
        showSearch
        placeholder="Search by MLS"
        value={searchValue}
        fetchOptions={fetchUserList}
        isFetching={isFetching}
        onChange={(newValue) => {
          setValue(newValue)
          onSelectMLS(newValue)
        }}
      />
    </Wrap>
  )
}

export default MLSTabs
