import styled from "styled-components";
import colors from "@colors";
import { INTER } from "@fonts";

const MessageText = styled.textarea`
  width: 100%;
  height: 65px;
  border-radius: 8px;
  border: 1px solid #c1c1d7;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 300;
  color: #303030;
`;

const ContactModalWrap = styled.div`
  height: 500px;

  .ant-form-item-required {
    flex-direction: row-reverse;
  }

  .ant-form-item-required::before {
    margin-left: 4px;
    color: black !important;
  }

  .ant-form-item-required::after {
    width: 0;
    margin: 0 !important;
    color: black;
  }

  .ant-input{
    height: 40px;
  }

  .ant-input:focus{
    border-color: #5A5FF2 !important;
  }
`;

export { MessageText, ContactModalWrap };
