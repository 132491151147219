import styled from "styled-components"

const BoxHolder = styled.div`
  background-color: ${props => props.bgColor};
  display: ${props => props.d};
  max-width: ${props => props.width}px;
  min-width: ${props => props.minWidth}px;
  height: ${props => props.h}px;
  align-items: ${props => props.alignItems};
  border: ${props => props.border};
  border-radius: 4px;
  padding: ${props => props.p};
`

BoxHolder.defaultProps = {
  d: "flex",
  width: "350",
  h: "80",
  bgColor: "#ffffff",
  alignItems: "center",
  border: "solid 1px #e6e8e9",
}

export default BoxHolder
