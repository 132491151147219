export const COMMISSION_TYPE = [
  {
    id: 1,
    name: "Percentage",
    value: "percentage",
  },
  {
    id: 2,
    name: "Fixed Fee",
    value: "fixed_fee",
  },
  {
    id: 3,
    name: "Both",
    value: "both",
  },
]

export const TRANSACTION_TYPE = [
  {
    id: 1,
    name: "Standard Residential",
    value: "residential_1_4",
  },
  {
    id: 2,
    name: "Residential Income Property",
    value: "residential_inc",
  },
  {
    id: 3,
    name: "Vacant Land",
    value: "vacant_land",
  },
  {
    id: 4,
    name: "Manufactured/Mobile Home",
    value: "manufactured_mo",
  },
  {
    id: 5,
    name: "New Construction",
    value: "new_constructio",
  },
  {
    id: 6,
    name: "Lease",
    value: "lease",
  },
  {
    id: 7,
    name: "Short Sales",
    value: "short_sales",
  },
  {
    id: 8,
    name: "Relocation",
    value: "relocation",
  },
  {
    id: 9,
    name: "Referral Agreement",
    value: "referral_agreem",
  },
  {
    id: 10,
    name: "Other",
    value: "other",
  },
]

export const RENT_AMOUNT_TERM = [
  {
    id: 1,
    name: "Month",
    value: "month",
  },
  {
    id: 1,
    name: "Annual",
    value: "annual",
  },
  {
    id: 1,
    name: "Other",
    value: "other",
  },
]

export const FIELD_VALUES = {
  list_price: "string",
  price: "string",
  commission_type: "checkbox",
  closed_on: "date",
  lease_commencement_on: "date",
  created_at: "date",
  transaction_type: "checkbox",
  other_transaction_type: "string",
  commision_percentage: "string_percentage",
  fixed_fee: "string",
  team_split_percentage: "string_percentage",
  rent_amount: "string",
  rent_amount_term: "checkbox",
  other_rent_amount_term: "string",
  closing_agent_commision_percentage: "string_percentage",
}
