import styled from "styled-components"
import {
  Checkbox,
} from "@ui/antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #F1F1FE;
`

export const StyledCheckbox = styled(Checkbox)``

export const CheckboxText = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: ${MONTSERRAT};
  color: #41446A;
`

export const IncompleteCount = styled.span`
color: #FFF;
text-align: center;
font-family: ${INTER};
font-size: 12px;
font-style: normal;
font-weight: 500;
border-radius: 50%;
background: #F08068;
height: 20px;
width: 20px;
display: flex;
align-items: center;
justify-content: center;
}
`;

export const CTAWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CTAButton = styled.button`
  display: flex;
  gap: 5px;
  border: none;
  background: none;
  align-items: center;
  cursor: pointer;
`

export const CTAText = styled.p`
  margin: 0;
  color: #5A5FF2;
  font-size: 10px;
  font-family: ${MONTSERRAT};
  font-weight: 900;
`
