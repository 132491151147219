/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import CRMV2Container from "container/CRM"

const Wrap = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.red};
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 20px 30px 20px 30px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px 20px;
`

const UploadUpdateModal = ({
    toggleShowUpdate,
    setContentForUpdate,
    contentForUpdate
}) => {
  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Heads Up!
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            {contentForUpdate}
          </Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              toggleShowUpdate(false)
              setContentForUpdate(null)
            }}
          >
            Close
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default CRMV2Container(withRouter(TechAssetsContainer(UploadUpdateModal)))
