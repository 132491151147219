import axios from "axios"

// export const getPropertyListAPI = (id, bundleId) => axios
//   .get(`${API_URL}property/status/${id}?bundleId=${bundleId}`)

export const getAllPimAPI = () => axios
.get(`${API_V2_URL}product-interest-modals`)

export const postCallAPI = url => axios
.post(url, {})

export const fetchLibraryUploadsAPI = query => axios.get(`${API_URL}fetch-library-uploaded-files/add_to_library?${query}`)

export const getActiveEnvelopesAPI = query => axios.get(`${API_URL}brokerage-offers-docs/envelopes?${query}`)

export const getOffersFromBuyersAPI = transactionId => axios.get(`${API_URL}fetch-buyers/search-criteria/offers?transactionId=${transactionId}`)

export const fetchInfoBasedOnMLSIdAPI = mlsId => axios.get(`${API_URL}get-mls-data?mlsId=${mlsId}`)

export const fetchInfoBasedOnPropertyAddressAPI = propertyAddress => axios.get(`${API_URL}get-mls-data?propertyAddress=${propertyAddress}`)

export const createOfferFromPropertyTypeAPI = payload => axios.post(`${API_URL}add-offer`, payload)

export const getOfferStatusMappingsAPI = () => axios.get(`${API_URL}brokerage-offer-statuses`)

export const updateBuyerInformationTabAPI = (id, payload) => axios.put(`${API_URL}offer-cover/${id}`, payload)

export const updateLenderAPI = (lenderId, payload) => axios.put(`${API_URL}offer-cover/lender/${lenderId}`, payload)

export const updateVendorAPI = (vendorId, payload) => axios.put(`${API_URL}offer-cover/vendor/${vendorId}`, payload)

export const updatePropertyInformationTabAPI = (id, payload) => axios.put(`${API_URL}offer-cover/${id}`, payload)

export const updateDealInformationTabAPI = (id, payload) => axios.put(`${API_URL}offer-cover/${id}`, payload)

export const fetchDealTermsInformationAPI = (id, step) => axios.get(`${API_URL}offer-cover/${id}/${step}`)

export const fetchPropertyInformationAPI = (id, step) => axios.get(`${API_URL}offer-cover/${id}/${step}`)

export const fetchVendorInfoAPI = (id, step, agentId) => axios.get(`${API_URL}offer-cover/${id}/${step}?agent_id=${agentId}`)

export const fetchValidateCoverSheetAPI = (id) => axios.get(`${API_URL}offer-coversheet-validator?offerId=${id}`)

export const updateOfferPurchaseTypeAPI = (id, payload) => axios.put(`${API_URL}edit-offer/${id}`, payload)

export const fetchOfferDocumentsAPI = state => axios.get(`${API_URL}brokerage-offers-docs/templates?state=${state}`)

export const updateRecommendedListOfDocsAPI = payload => axios.post(`${API_V2_URL}brokerage-offers-docs/envelopes`, payload)

export const fetchListingDocumentsAPI = state => axios.get(`${API_URL}brokerage-docs/templates?state=${state}`)

export const updateLibraryUploadsAPI = payload => axios.put(`${API_URL}update-library-uploaded-files`, payload)

export const deleteLibraryUploadedFileAPI = fileId => axios.delete(`${API_URL}update-library-uploaded-files/${fileId}`)

export const fetchOfferRelatedDocumentsAPI = (id, type) => axios.get(`${API_URL}fetch-offers-uploaded-files/${id}/${type}`)

export const uploadOfferRelatedAPI = (id, payload) => axios.put(`${API_URL}update-offers-uploaded-files/${id}`, payload)

export const sendReminderAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/reminder`, payload)

export const createReceiverOfferViewV2API = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/receiver-view`, payload)

export const transactionFileUploadAPI = payload => axios.post(`${API_URL}transaction-uploaded-files`, payload)

export const deleteTransactionFileAPI = (clientId, fileId) => axios.delete(`${API_URL}search-criteria/${clientId}/${fileId}`)

export const fetchTransactionFilesAPI = id => axios.get(`${API_URL}transaction-uploaded-files/${id}`)

export const fetchOfferPackageDocumentsAPI = id => axios.get(`${API_URL}brokerage-offers-docs/documents/info?offerId=${id}`)

export const sendOfferPackageMailAPI = payload => axios.post(`${API_URL}send-offer-docs-email`, payload)

export const getOfferPackageAPI = id => axios.get(`${API_URL}get-offer-package/${id}`)

export const getOfferStatusAPI = id => axios.get(`${API_URL}fetch-offer-status/${id}`)

export const deleteOffer = offerId => axios.delete(`${API_URL}brokerage-offer-delete/${offerId}`)
