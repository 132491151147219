import React from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import { get } from "lodash"
import { Textarea } from "@ui/Form"
import colors from "@colors"
import {
  Header,
  FormWrap,
  InnerFormWrap,
} from "../common"
import Form from "./form"

const Wrap = styled.div`
  width: 955px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`

const InnerWrap = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
`

const RoomDetails = ({
  updateForm,
  getTagsResponse,
  getTags,
  clearTags,
  toggleImageUploadModal,
  isImageUploadOpen,
  speakers,
  toggleInviteSpeakersModal,
  isInviteAgentModalOpen,
  fetchAgentSearch,
  fetchAgentSearchResponse,
  clearAgents,
  specialRoomType,
  createRoomPageSwitch,
  createRoomResponse,
  submit,
  eventData,
}) => {
  const handleSubmit = (values) => {
    const payload = {
      ...values,
    }
    submit(payload)
  }

  const { agents } = fetchAgentSearchResponse.data || {}
  const creatingRoom = get(createRoomResponse, "isFetching")

  const roomTags = getTagsResponse.data || {}

  return (
    <Wrap>
      <Header>
        <p>Create a Live Room</p>
      </Header>
      <FormWrap>
        <InnerWrap>
          <InnerFormWrap>
            <Form
              onSubmit={handleSubmit}
              roomTags={roomTags}
              getTags={getTags}
              clearTags={clearTags}
              toggleImageUploadModal={toggleImageUploadModal}
              isImageUploadOpen={isImageUploadOpen}
              toggleInviteSpeakersModal={toggleInviteSpeakersModal}
              isInviteAgentModalOpen={isInviteAgentModalOpen}
              fetchAgentSearch={fetchAgentSearch}
              agents={agents}
              clearAgents={clearAgents}
              speakers={speakers}
              specialRoomType={specialRoomType}
              setPage={createRoomPageSwitch}
              creatingRoom={creatingRoom}
              eventData={eventData}
            />
          </InnerFormWrap>
        </InnerWrap>
      </FormWrap>
    </Wrap>
  )
}

export default Rooms(RoomDetails)
