import React from "react"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import Button from "@ui/Button"
import colors from "@colors"
import Coverage from "./Coverage"

const desc = `“The tools that we needed in order to grow at the rate we were looking at—from the backend, 
                the tech and the support system that Radius provides—is what allows us to be extra successful.”`

const Wrap = styled.div`
  padding: 60px;
  background-color: #F1F3F8;

  @media(max-width: 767px) {
    padding: 20px;
  }

  @media(max-width: 425px) {
    padding: 50px 10px;
    margin-bottom: 50px;
  }
`

const ContentWrap = styled.div`
  max-width: 1440px;
  margin: auto;
  margin-bottom: 150px;

  > h1 {
    font-size: 42px;
    font-family: ${HIGHLIGHT_FONT};
    margin-bottom: 80px;

    @media(max-width: 1099px) {
      text-align: center;
      font-size: 36px;
    }

    @media(max-width: 425px) {
      font-size: 32px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media(max-width: 1099px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    > iframe {
      max-width: 637px;
      width: 100%;
    }
  }
`

const QuoteWrap = styled.div`
  > div {
    max-width: 635px;
    width: 100%;
    > p {
      font-size: 24px;
      line-height: 35px;
      font-family: ${ATC};
      color: #303030;

      @media(max-width: 1099px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > h2 {
      color: #303030;
      font-size: 22px;
      font-weight: 200;
      margin-top: 60px;
      font-family: ${ATC};
      @media(max-width: 1099px) {
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > small {
      color: #303030;
      font-size: 16px;
      font-weight: 200;
      font-family: ${ATC};
    }
  }
`

const ButtonWrap = styled.div`
  @media(max-width: 1099px) {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`
const ButtonWrapAmen = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  margin: 50px;

  @media(max-width: 768px) {
    max-width: unset;
  }

  > a {
    color: ${colors.marineBLue};
    font-family: ${ATC};
  }
  
  @media(max-width: 1099px) {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`
const AgentTestimonial = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <h1>
          What our agents are saying
        </h1>
        <div>
          <QuoteWrap>
            <div>
              <p>
                {desc}
              </p>
              <h2>
                Brendan Perry
              </h2>
              <small>
                Divine Properties
              </small>
            </div>
            <ButtonWrap>
              <Button
                style={{
                  width: "180px",
                  padding: 10,
                  background: `${colors.marineBLue}`,
                  border: "none",
                  borderRadius: "0px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                Join Radius
              </Button>
            </ButtonWrap>
          </QuoteWrap>
          <LazyLoad offset={220}>
            <iframe
              width="560"
              height="315"
              // src="https://www.youtube.com/embed/NF9etSlnA6w"
              src="https://www.youtube-nocookie.com/embed/NF9etSlnA6w?rel=0&controls=0&modestbranding=1&showinfo=0&loop=1"

              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            
            </iframe>
          </LazyLoad>
        </div>
      </ContentWrap>
      <Coverage />
      <ButtonWrapAmen>
        <Button
          style={{
            width: "325px",
            padding: 10,
            background: `${colors.marineBLue}`,
            border: "none",
            borderRadius: "0px",
            marginTop: "30px",
          }}
          onClick={() => {
            toggleApplyModal({
              bool: true,
              accountType: "owner",
              headerText: "Nice to meet you!",
              subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
            })
          }}
        >
          Discover your next brokerage
        </Button>
        <a href="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true">
          Book a meeting
        </a>
      </ButtonWrapAmen>
    </Wrap>
  )
}

export default AgentTestimonial
