import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import root from "window-or-global"
import markAsQaulified from "images/icons/secured.svg"
import ReferOutIcon from "images/icons/referral-3x-new.svg"
import { Draggable } from "react-beautiful-dnd"
import icons from "@icons"
import Box from "@ui/Box"
import DropdownMenu from "@ui/NewTooltip"
import moreMenuSVG from "images/icons/more_menu.svg"
import AddReminderModal from "dumbComponents/common/AddReminderModal"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  CardContainer,
  Li,
  CategoryTag,
} from "@ui/DragDropColumn/common"

const Wrap = styled.a`
  position: relative;
  display: flex;
  text-decoration: none;
  padding: 7px;
  flex-direction: column;
  align-items: center;
`

const StyledMenuIcon = styled.img`
  padding: 2px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
`

const TrimmedText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`

const Card = (props) => {
  const {
    data: {
      name,
      id,
      index,
      image,
      type,
      email,
    },
    data,
    isShowAddReminderModal,
    openAddReminderModal,
    closeAddReminderModal,
    setReminder,
    isSettingReminder,
    toggleAddNotesModal,
    handleRefer,
    //push,
    hideMarkAsQualified,
  } = props

  const handleRedirect = () => {
    root.open(`/client/${id}/notes`, "_blank")
  }

  const lowerType = type && type.toLowerCase()
  return (
    <Draggable
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <CardContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
          isReferralCardBusy={false}
        >
          <div style={{ position: "relative" }}>
            <Wrap d="flex">
              <Box onClick={handleRedirect}>
                <CircularImage
                  image={image}
                  name={name || "Unknown"}
                  style={{ marginRight: 8 }}
                />
              </Box>
              <Box flex="1" ta="center" onClick={handleRedirect} style={{ maxWidth: "110px" }}>
                <TrimmedText>{(name && name.trim()) || get(data, "emails[0].email", "No Name") }</TrimmedText>
                <CategoryTag type={lowerType}>
                  {type}
                </CategoryTag>
              </Box>
              <Box position="absolute" t="0" r="0">
                <DropdownMenu
                  position="bottom"
                  component={<StyledMenuIcon src={moreMenuSVG} />}
                  top={20}
                  padding={10}
                  left={-35}
                  width={200}
                  borderColor="#FFF"
                  eventType="hover"
                  borderRadius={4}
                  showShadow
                >
                  <React.Fragment>
                    <Li
                      onClick={() => { openAddReminderModal(id) }}
                    >
                      <span>Add a reminder</span>
                      <i className={icons.interfaceCalendar} />
                    </Li>
                    <Li
                      onClick={() => { toggleAddNotesModal(id) }}
                    >
                      <span>Add Notes</span>
                      <i className={icons.filesFileFolder} />
                    </Li>
                    {!hideMarkAsQualified && (
                      <Li
                        onClick={() => { handleRefer(true, { ...data, referralType: "crm" }) }}
                      >
                        <span>Mark as Qualified</span>
                        <img src={markAsQaulified} alt="Mark as qualified" />
                      </Li>
                    )}
                    <Li
                      onClick={() => { handleRefer(false, { ...data, referralType: "crm" }) }}
                    >
                      <span>Refer out</span>
                      <img src={ReferOutIcon} alt="Refer out icon" style={{ width: "30px", height: "25px", transform: "translateX(8)" }} />
                    </Li>
                  </React.Fragment>
                </DropdownMenu>
              </Box>
            </Wrap>

            {isShowAddReminderModal === id && (
              <AddReminderModal
                referral={{
                  client_id: id,
                  id: null,
                }}
                id={id}
                toClose={closeAddReminderModal}
                setReminder={setReminder}
                isSettingReminder={isSettingReminder}
              />
            )}
          </div>
        </CardContainer>
      )}
    </Draggable>
  )
}
export default Card
