import React from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import { Textarea } from "@ui/Form"
import colors from "@colors"
import {
  Header,
  FormWrap,
  InnerFormWrap,
} from "../common"
import Form from "./form"

const Wrap = styled.div`
  width: 955px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`

const InnerWrap = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
`

const RoomDetails = ({
  getTagsResponse,
  getTags,
  clearTags,
  toggleImageUploadModal,
  isImageUploadOpen,
  speakers,
  toggleInviteSpeakersModal,
  toggleInviteAttendeesModal,
  isInviteAgentModalOpen,
  fetchAgentSearch,
  fetchAgentSearchResponse,
  clearAgents,
  isInviteAttendeesModalOpen,
  submit,
  createRoomPageSwitch,
  specialRoomType,
  createScheduleRoomResponse,
  eventData,
  scheduleRoomFormValues,
  updateSubmit,
}) => {
  const handleSubmit = (values) => {
    const { timezone, event_duration, frequency } = values
    const { value } = timezone || {}
    const { value: duration } = event_duration || {}
    const payload = {
      ...values,
      time_zone: value,
      event_duration: duration,
    }

    if (frequency && frequency.value !== 0) {
      payload.external_url = ""
    }

    if (eventData && eventData !== null || eventData !== undefined) {
      updateSubmit(payload, eventData.id)
    } else {
      submit(payload)
    }
  }

  const { agents } = fetchAgentSearchResponse.data || {}
  const { isFetching: creatingRoom } = createScheduleRoomResponse || {}

  const roomTags = getTagsResponse.data || {}

  const {
    frequency,
  } = scheduleRoomFormValues || {}

  return (
    <Wrap>
      <Header>
        <p>Schedule a Room</p>
      </Header>
      <FormWrap>
        <InnerWrap>
          <InnerFormWrap>
            <Form
              onSubmit={handleSubmit}
              roomTags={roomTags}
              getTags={getTags}
              clearTags={clearTags}
              toggleImageUploadModal={toggleImageUploadModal}
              isImageUploadOpen={isImageUploadOpen}
              toggleInviteSpeakersModal={toggleInviteSpeakersModal}
              isInviteAgentModalOpen={isInviteAgentModalOpen}
              fetchAgentSearch={fetchAgentSearch}
              agents={agents}
              clearAgents={clearAgents}
              speakers={speakers}
              toggleInviteAttendeesModal={toggleInviteAttendeesModal}
              isInviteAttendeesModalOpen={isInviteAttendeesModalOpen}
              specialRoomType={specialRoomType}
              setPage={createRoomPageSwitch}
              creatingRoom={creatingRoom}
              eventData={eventData}
              roomFrequency={frequency}
            />
          </InnerFormWrap>
        </InnerWrap>
      </FormWrap>
    </Wrap>
  )
}

export default Rooms(RoomDetails)
