import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { setReminderAction, updateArchivedLeadAction } from "container/Client/actions"
import {
  closeAddReminderModalAction,
  openAddReminderModalAction,
  toggleReferOutAction,
  referConfirmedAction,
} from "container/Referral/actions"
import * as ReferralSelectors from "container/Referral/selector"
import { getFormValues } from "redux-form"
import * as NonAssistActions from "./actions"



const mapStateToProps = (state) => {
  const { nonAssist, client, referral } = state
  const { isSettingReminder } = client
  const { isShowAddReminderModal, isReferOutModalOpen, referOut: referOutResponse } = referral
  const confirmationModalMessage = ReferralSelectors.confirmationModalMessage(state)
  const claimLeadForm = getFormValues("CLAIM_LEADS_STATUS")(state)
  return {
    ...nonAssist,
    isSettingReminder,
    isShowAddReminderModal,
    isReferOutModalOpen,
    confirmationModalMessage,
    referOutResponse,
    claimLeadForm,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getNonAssistLeads: NonAssistActions.getNonAssistLeadsAction.request,
  changeSalesState: NonAssistActions.changeSalesStateAction.request,
  changeSalesStateV2: NonAssistActions.changeSalesStateV2Action.request,
  addNote: NonAssistActions.addNoteAction.request,
  toggleAddNotesModal: NonAssistActions.toggleAddNotesModalAction.call,
  toggleArchiveModal: NonAssistActions.toggleArchiveModalAction.call,
  getArchivedLeadNonAssist: NonAssistActions.getArchivedLeadNonAssistAction.request,

  // Other Funtions (Non-assist)
  setReminder: setReminderAction.request,
  closeAddReminderModal: closeAddReminderModalAction,
  openAddReminderModal: openAddReminderModalAction,
  updateArchivedLead: updateArchivedLeadAction.request,
  toggleReferOut: toggleReferOutAction.call,
  referOutConfirm: referConfirmedAction.call,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
