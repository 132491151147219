export const getInputFieldsBasedOnState = (state) => {
  switch (state) {
    case "california":
      return [{
        name: "street",
        type: "googleLocation",
        text: "Street Address",
        placeholder: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
        required: false,
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }, {
        name: "apn_number",
        type: "text",
        placeholder: "APN Number",
        text: "APN Number",
        required: false,
      }]
    case "texas":
      return [{
        name: "apn_number",
        type: "text",
        placeholder: "APN number",
        text: "APN number",
        //required: true,
      }, {
        name: "street",
        type: "googleLocation",
        text: "Street Address",
        placeholder: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
        required: false,
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }, {
        name: "lot",
        type: "text",
        placeholder: "Lot",
        text: "Lot",
        required: false,
      }, {
        name: "block",
        type: "text",
        placeholder: "Block",
        text: "Block",
        required: false,
      }, {
        name: "county",
        type: "text",
        placeholder: "County",
        text: "County",
        required: false,
      }]
    case "florida":
      return [{
        name: "street",
        type: "googleLocation",
        text: "Street Address",
        placeholder: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
        required: false,
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }, {
        name: "tax_id_number",
        type: "text",
        placeholder: "Tax ID number",
        text: "Tax ID number",
        required: true,
      }, {
        name: "legal_description",
        type: "text",
        placeholder: "Legal Description",
        text: "Legal Description",
        required: true,
      }]
    case "washington":
      return [{
        name: "street",
        type: "googleLocation",
        placeholder: "Street Address",
        text: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
        required: false,
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }]
    case "oregon":
      return [{
        name: "street",
        type: "googleLocation",
        placeholder: "Street Address",
        text: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",

      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }]
    case "colorado":
      return [{
        name: "street",
        type: "googleLocation",
        placeholder: "Street Address",
        text: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }, {
        name: "legal_description",
        type: "text",
        placeholder: "Legal Description",
        text: "Legal Description",
        required: true,
      }]
    case "georgia":
      return [{
        name: "street",
        type: "googleLocation",
        text: "Street Address",
        placeholder: "Street Address",
        required: true,
      }, {
        name: "apt",
        type: "text",
        text: "Apartment/Unit/Suite No.",
        placeholder: "Apartment/Unit/Suite No.",
      }, {
        name: "city",
        type: "text",
        text: "City",
        placeholder: "City",
        required: true,
      }, {
        name: "county",
        type: "text",
        text: "County",
        placeholder: "County",
        required: false,
      }, {
        name: "state",
        type: "text",
        placeholder: "State",
        text: "State",
        required: true,
      }, {
        name: "zipcode",
        type: "text",
        placeholder: "Zip Code",
        text: "Zip Code",
        required: true,
      }, {
        name: "tax_parcel_id_number",
        type: "text",
        placeholder: "Tax Parcel ID number",
        text: "Tax Parcel ID number",
        required: true,
      }, {
        name: "land_lots",
        type: "text",
        placeholder: "Land Lot(s)",
        text: "Land Lot(s)",
        required: true,
      }, {
        name: "district",
        type: "text",
        placeholder: "District",
        text: "District",
        required: true,
      }, {
        name: "section_gmd",
        type: "text",
        placeholder: "Section / GMD",
        text: "Section / GMD",
        required: true,
      }, {
        name: "lot",
        type: "text",
        placeholder: "Lot",
        text: "Lot",
        required: false,
      }, {
        name: "block",
        type: "text",
        placeholder: "Block",
        text: "Block",
        required: false,
      }, {
        name: "unit",
        type: "text",
        text: "Unit",
        placeholder: "Unit",
        required: true,
      }, {
        name: "phase_section",
        type: "text",
        text: "Phase / Section",
        placeholder: "Phase / Section",
        required: true,
      }, {
        name: "subdivision_development",
        type: "text",
        text: "Subdivision / Development",
        placeholder: "Subdivision / Development",
      }, {
        name: "plat_book",
        type: "text",
        placeholder: "Plat-book",
        text: "Plat-book",
        required: true,
      }, {
        name: "page",
        type: "text",
        placeholder: "page",
        text: "Page",
        required: true,
      }]
    default:
      return []
  }
}
