import axios from "axios"

export const fetchAllClientsAPI = (payload) => axios.post(`${API_V2_URL}clients/search
`, payload)

export const copyAppInviteLinkAPI = (payload) => axios.get(`${API_URL}consumerapp/get-app-invite-url`)

export const inviteBulkClientsAPI = () => axios.post(`${API_V3_URL}bulk-consumer-app-invites`) 
export const joinWaitlistAPI = (payload) => axios.post(`${API_URL}consumerapp/request-consumerapp`, payload)
export const inviteClientsAPI = (payload) => axios.post(`${API_URL}consumerapp/invite-clients`,payload)
export const getAppStatusAPI = (payload) => axios.get(`${API_URL}consumerapp/get-app-info`)
export const turnOnMelRecommendationsAPI = (payload) => axios.post(`${API_V3_URL}toggle-activate-recommendations`, payload)
export const fetchRecommendedPropertiesAPI = (clientId, reqId, likeStatus, date,limit, skip, startDate, endDate) => axios.get(`${API_V3_URL}recommendations?clientId=${clientId}&reqId=${reqId}&likeStatus=${likeStatus}&sort=${date}&limit=${limit}&skip=${skip}&startDate=${startDate}&endDate=${endDate}`)
export const rejectPropertyStatusAPI = (payload) => axios.post(`${API_V3_URL}remove-recommendation`, payload)
export const removeBulkPropertyAPI = (payload) => axios.post(`${API_V3_URL}bulk-remove-recommendation`, payload);