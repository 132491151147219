import React from "react"
import {
  UserProfileContainer, UserProfileLabel, UserProfileDetails, UserProfileImage, UserProfileName,
} from "./commonStyles"

const UserProfile = (props) => {
  const { user, profileImage } = props

  return (
    <UserProfileContainer>
      <UserProfileLabel>Profile</UserProfileLabel>
      <UserProfileDetails>
        <UserProfileImage src={profileImage || user.agent_image} />
        <UserProfileName>{`${user.firstname || ""} ${user.lastname || ""}`}</UserProfileName>
      </UserProfileDetails>
    </UserProfileContainer>
  )
}

export default UserProfile
