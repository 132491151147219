import React from "react"
import styled from "styled-components"
import colors from "@colors"
import RealtyContainer from "container/Realty"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import root from "window-or-global"
import { authCheck } from "services/auth"
import { preTabletWidth, preDesktopWidth } from "@consts"
import { ATC } from "@fonts"

const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.heroHeight || "max-content"};
  display: flex;
  overflow: hidden;
  flex-direction: ${props => props.flexDirection || "row-reverse"};
  justify-content: space-between;

  ${props => props.marginBottom && `
    margin-bottom: ${props.marginBottom};
  `}

  ${props => props.bgColor && `
    background: ${props.bgColor};
    `}

    @media(min-width: 1024px) {
      min-height: 500px;
      padding: 52px;
    }
  
    @media(min-width: 1280px) {
      padding-left: 72px;
  
      ${props => props.paddingBottom && `
        padding-bottom: ${props.paddingBottom};
      `}
    }

  @media(max-width: 1099px) {
    padding: ${props => props.tabletPadding || "0px"};
    flex-direction: ${props => props.flexDirectionTablet || "initial"};
  }
  

  @media(max-width: 767px){
    border-radius: 0px;
    flex-direction: column;
    height: ${props => props.mobileHeroHeight || "max-content"};
    padding: ${props => props.mobileHeroPadding || "0px"}
  }

  ${props => props.customTabletOrientation && `
    @media(max-width: 767px) {
      border-radius: 0px;
      flex-direction: column;
      height: ${prop => prop.mobileHeroHeight || "max-content"};
    }

    @media(min-width: 768px) {
      min-height: 500px;
      flex-direction: row-reverse;
      height: ${prop => prop.mobileHeroHeight || "max-content"};
    }

    @media(min-width: 1280px) {
      padding-left: 72px;
      height: 700px;
    }
  `}

`

const Block = styled.div`
   height: max-content;

   ${props => props.noHeroImage && `
    display: none;
  `}

   ${props => props.customTabletOrientation && `
      @media(min-width: 768px) {
        width: 50%;
      }

      @media(min-width: 1024px) {
        width: 100%;
      }
    `}

   ${props => props.img && `
     height: max-content;
    `}

    .royaleOutline {
      filter: ${props => props.filter ? props.filter : "contrast(0.5)"};
    }

   .topImg {
     ${props => props.rotate && `
       transform: rotateY(180deg);
      `}
   }

   p, h1 {
     color: ${props => props.color || colors.black};
   }

   p {
     font-family: ${props => props.pFontFamily || "DM Sans"};
     font-style: normal;
     font-weight: ${props => props.descFontWeight || "400"};
     letter-spacing: 0em;
     text-align: left;
     max-width: 700px;
     padding-bottom: 16px;

     b {
       font-weight: 700;
     }

     @media(max-width: 767px){
       font-size: ${props => props.parafontSizeS || "24px"};
       text-align: ${props => props.mobileTextAlign || "center"};
       ${props => props.mobileDescLineHeight && `
        line-height: ${props.mobileDescLineHeight};
       `}
       max-width: ${props => props.paraMobileMaxWidth || "unset"};
     }

     @media(min-width: 768px){
       font-size: ${props => props.parafontSizeSM || "20px"};
       text-align: ${props => props.mobileTextAlign || "center"};
       max-width: ${props => props.tabletMaxWidth || "400px"};
       margin: ${props => props.customMarginValues || "initial"};
     }

     @media(min-width: 1024px){
       padding-top: 0px;
       font-size: ${props => props.parafontSizeML || "24px"};
       text-align: left;
       position: static;
       margin: initial;
       
       ${props => props.paraWidthML && `
         max-width: ${props.paraWidthML};
       `}

       ${props => props.paraLineHeight && `
         max-width: ${props.paraLineHeight};
       `}
     }

     @media(min-width: 1440px) {
       font-size: ${props => props.parafontSizeXL || "24px"};
       ${props => props.paraWidthXL && `
         max-width: ${props.paraWidthXL};
       `}
     }
   }

   h1 {
     font-family: ${props => props.h1FontFamily ? props.h1FontFamily : "DM Sans"};
     font-style: normal;
     font-weight: ${props => props.fontWeight ? props.fontWeight : "500"};
     max-width: 600px;
     position: relative;
     top: 0;
     left: 0;
     margin: 0 auto;
     padding-bottom: 16px;
     letter-spacing: 0.005em;
     


     @media(max-width: 767px){
       padding-top: 44px;
       max-width: ${props => props.headerMaxWidth || "600px"};
       font-size: ${props => props.fontSizeS || "36px"};
       text-align: ${props => props.mobileTextAlign || "center"};
       ${props => props.mobileNoMarginLeft && `
         margin-left: 0;
        `}
        line-height: ${props => props.mobileLineHeight || "initial"};

      ${props => props.mobileHeaderTextWidth && `
         width: ${props.mobileHeaderTextWidth};
      `}
     }

     @media(min-width: 768px){
       padding-top: 44px;
       font-size: ${props => props.fontSizeSM || "30px"};
       text-align: ${props => props.mobileTextAlign || "center"};
       max-width: ${props => props.tabletMaxWidth || "400px"};
       line-height: ${props => props.tabletLineHeight || "initial"};
       margin: ${props => props.customMarginValues || "initial"};
     }

     @media(min-width: 1024px){
       padding-top: 0px;
       font-size: ${props => props.fontSizeML || "36px"};
       text-align: left;
       position: static;
       margin: initial;
       line-height: ${props => props.headerLineHeightMediumDesktop || "initial"};
       max-width: ${props => props.fontWidthML || "600px"};
     }

     @media(min-width: 1440px){
       max-width: ${props => props.headerMaxWidthDesktop || "700px"};
       line-height: ${props => props.headerLineHeightDesktop || "initial"};
       font-size: ${props => props.fontSizeXL || "36px"};
     }
   }

   button {
     font-family: ${ATC};
     font-style: normal;
     position: ${props => props.buttonPos || "relative"};
     top: ${props => props.customButtonTop || "unset"};
     left: ${props => props.customButtonLeft || "unset"};
     margin: ${props => props.customMargin || "unset"};
     display: block;
     height: max-content;
     width: ${props => props.buttonWidth || "max-content"};
     padding: 16px;
     font-weight: 500;
     text-align: center;
     font-size: 24px;
     cursor: pointer;
     border: none;
     background: ${props => props.btnColor || colors.darkPurple};
     color: ${props => props.btnTextColor || colors.white};

     @media(max-width: 767px) {
       top: ${props => props.mobileCustomButtonTop || "0px"};
       left: ${props => props.mobileCustomButtonLeft || "0px"};
       margin: ${props => props.mobileCustomMargin || "unset"};
       font-size: 24px;
       font-style: normal;
       font-weight: 400;
       line-height: 18px;
       letter-spacing: 0px;
       text-align: center;
     }

     @media(min-width: 768px) {
       top: ${props => props.customButtonTop || "0px"};
       left: ${props => props.customButtonLeft || "0px"};
       margin: ${props => props.customMargin || "unset"};
       z-index: 10;
     }

     @media(min-width: 1024px) {
       ${props => props.positionDesktop && `
         position: relative;
         left: 0px;
        `}
     }
   }

   &:hover .after {
    display: block;
   }

   .topImg {
     display: block;
     cursor: pointer;
     position: relative;
     animation: show .3s forwards;
     transform: rotate(360deg);
     ${props => props.noHeroImage && `
       display: none;
     `}

     @keyframes show {
       0% {
         opacity: 0;
       }
       25% {
         opacity: 0.25;
       }
       50% {
         opacity: 0.50;
       }
       100% {
         opacity: 1;
       }
     }
   }


   @media(max-width: 1023px) {
     margin-top: -21px;
     
     ${props => props.contentBlock && `
       display: flex;
       align-items: ${props.contentAlign || "center"};
       flex-direction: column;
     `}

     ${props => props.img && `
       padding: 23px 0px 23px 0px;
     `}

     ${props => props.negativeMargin && `
       margin-bottom: -98px;
    `}

     button {
       font-size: 16px;
       font-weight: 500;
       line-height: 16px;
       letter-spacing: 0px;
       text-align: center;

       ${preDesktopWidth`
        font-size: 24px;
       `}

       ${preTabletWidth`
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: center;
       `}
     }

     .royaleOutline {
       position: relative;
       top: ${props => props.outlinePositionTop ? props.outlinePositionTop : "-291px"};
       left: ${props => props.outlinePositionLeft ? props.outlinePositionLeft : "50%"};
       width: ${props => props.outlinePositionImageWidth ? props.outlinePositionImageWidth : "auto"};
       z-index: ${props => props.outlineZindex ? props.outlineZindex : "2"};
       transform: ${props => props.outLineImageTransform ? props.outLineImageTransform : "translate( -50%, -50%)"};
     }

     .topImg {
       width: 555px;
       z-index: 3;
       top: 170px;
       left: 50%;
       transform: translate( -50%, -50%);
       ${props => props.rotate && `
         transform: translate( -50%, -50%);
        `}
     }

     ${props => props.royale && `
       height: 400px;
       padding: 50px 23px 0px 30px;

        h1, p {
          margin: unset;
        }

        button {
          margin: 20px 0;
        }

        h1, p {
          text-align: left;
        }

        h1 {
          margin-top: -34px;
        }

       .royaleOutline {
         z-index: 2;
         height: auto;
         width: 521px;
         top: -227px;
         position: relative;
         left: 470px;
         float: right;
      }

       .topImg {
         height: auto;
         width: 356px;
         top: 124px;
         left: 313px;
         z-index: 3;
         float: right;
        }
      `}
   }

   @media(max-width: 767px) {
     .topImg {
       width: 406px;
     }
   }

   @media(min-width: 1024px) {
     margin-top: 0;
     ${props => props.img && `
        position: relative;
     `}

     button {
       transform: translate(-4px, 17px);
     }

     .royaleOutline {
       z-index: 2;
       height: auto;
       width: 846px;
       left: 346px;
       float: right;
       bottom: 820px;
       position: relative;
     }

     .topImg {
       height: auto;
       width: ${props => props.royale ? "549px" : "650px"};
       top: -125px;
       left: 120px;

       ${props => props.royale && `
         z-index: 3;
         float: right;
         `}

       ${props => props.customStyle && `
         width: 1038px;
         top: -100px;
         left: -170px;
         height: 591px;
        `}
     }
   }

   @media(min-width: 1024px) {
     ${props => props.marginTop && `
       margin-top: 0px;
     `}
   }

   @media(min-width: 1280px) {
     ${props => props.marginTop && `
       margin-top: 0px;
       padding: 0px 0px 0px 4px;
     `}
   }
`

const HeroImageTablet = styled.img`
   display: none;

   ${preDesktopWidth`
      display: block;
      max-width: 800px;
      width: 100%;
      margin: 0 auto 90px auto;
   `}

   ${preTabletWidth`
     display: none;
   `}
`

const HeroImageMobile = styled.img`
   display: none;

   ${preTabletWidth`
      display: block;
      width: 100%;
      margin: 0 auto 90px auto;
      max-width: 400px;
   `}
`

const HeroImage = styled.img`
   ${props => props.hide && `
      @media(max-width: 1099px) {
        display: none !important;
      }
   `}
`

class CommonHero extends React.Component {
  componentDidMount() {
    //
  }

  handleApplyNow = () => {
    const {
      heap,
      isIosBrowser,
      history,
      RARTracker,
      hubspotKey,
      rarPromotionSourcePage,
      hubspotModalClick,
      toggleHubSpotModal,
      toggleRARModal,
      mobileHeaderTextWidth,
    } = this.props
    const isLogged = authCheck()

    if (!hubspotModalClick) {
      cookie.save("rar_promotions_source_page", rarPromotionSourcePage)
      if (root.heap) {
        root.heap.track(heap)
      }
      if (isLogged) {
        RARTracker({
          payload: {
            source: hubspotKey,
          },
        })
      }
      //history.push(isIosBrowser ? "/realty/choose-your-city?platform=ios" : "/realty/choose-your-city")
      toggleRARModal(true)
    } else {
      toggleHubSpotModal(true)
      if (root.heap) {
        root.heap.track(heap)
      }
      if (isLogged) {
        RARTracker({
          payload: {
            source: hubspotKey,
          },
        })
      }
    }
  }

  handleApplyNowVersion2 = () => {
    const {
      toggleApplyModal,
      heap,
    } = this.props
    if (root.heap) {
      root.heap.track(heap)
    }

    toggleApplyModal({
      bool: true,
      accountType: "owner",
      headerText: "Nice to meet you!",
      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
    })
  }

  render() {
    const {
      heroImage,
      children,
      subText,
      buttonText,
      rotate,
      customStyle,
      royale,
      fontFamily,
      outerImage,
      color,
      bgColor,
      btnColor,
      btnTextColor,
      heroHeight,
      mobileHeroHeight,
      fontSizeXL,
      fontSizeML,
      fontSizeSM,
      fontSizeS,
      parafontSizeXL,
      parafontSizeML,
      parafontSizeSM,
      parafontSizeS,
      customTabletOrientation,
      fontWeight,
      filter,
      noHeroImage,
      headerLineHeightDesktop,
      fontWidthML,
      fontWidthXL,
      mobileTextAlign,
      headerMaxWidth,
      tabletMaxWidth,
      customButtonTop,
      customButtonLeft,
      customMargin,
      mobileCustomButtonTop,
      mobileCustomButtonLeft,
      mobileCustomMargin,
      mobileNoMarginLeft,
      mobileLineHeight,
      tabletLineHeight,
      customMarginValues,
      positionDesktop,
      h1FontFamily,
      pFontFamily,
      headerLineHeightMediumDesktop,
      descFontWeight,
      mobileDescLineHeight,
      mobileHeaderTextWidth,
      marginBottom,
      paraWidthML,
      paraWidthXL,
      paddingBottom,
      mobileButtonWidth,
      heroImageMobile,
      headerMaxWidthDesktop,
      heroImageTablet,
      contentAlign,
      buttonPos,
      tabletPadding,
      flexDirection,
      mobileHeroPadding,
      buttonWidth,
      paraMobileMaxWidth,
      flexDirectionTablet
    } = this.props

    return (
      <Wrapper
        bgColor={bgColor}
        royale={royale}
        heroHeight={heroHeight}
        mobileHeroHeight={mobileHeroHeight}
        customTabletOrientation={customTabletOrientation}
        marginBottom={marginBottom}
        paddingBottom={paddingBottom}
        mobileHeroPadding={mobileHeroPadding}
        tabletPadding={tabletPadding}
        flexDirection={flexDirection}
        flexDirectionTablet={flexDirectionTablet}
      >
        <Block
          fontFamily={fontFamily}
          royale={royale}
          rotate={rotate}
          className="imageContainer"
          negativeMargin
          img
          customStyle={customStyle}
          customTabletOrientation={customTabletOrientation}
          filter={filter}
          noHeroImage={noHeroImage}
        >
          <HeroImage hide={heroImageMobile || heroImageTablet} src={heroImage} className="topImg" alt="radius_agent_realty" />
          {royale && (
            <HeroImage hide={heroImageMobile || heroImageTablet} src={outerImage} className="royaleOutline" alt="radius_agent_realty" />
          )}
          <HeroImageMobile
            src={heroImageMobile}
          />
          <HeroImageTablet src={heroImageTablet} />
        </Block>
        <Block
          color={color}
          fontFamily={fontFamily}
          marginTop
          btnColor={btnColor}
          btnTextColor={btnTextColor}
          royale={royale}
          fontSizeXL={fontSizeXL}
          fontSizeML={fontSizeML}
          fontSizeSM={fontSizeSM}
          fontSizeS={fontSizeS}
          parafontSizeXL={parafontSizeXL}
          parafontSizeML={parafontSizeML}
          parafontSizeSM={parafontSizeSM}
          parafontSizeS={parafontSizeS}
          fontWeight={fontWeight}
          headerLineHeightDesktop={headerLineHeightDesktop}
          headerLineHeightMediumDesktop={headerLineHeightMediumDesktop}
          fontWidthML={fontWidthML}
          fontWidthXL={fontWidthXL}
          mobileTextAlign={mobileTextAlign}
          headerMaxWidth={headerMaxWidth}
          tabletMaxWidth={tabletMaxWidth}
          customButtonTop={customButtonTop}
          customButtonLeft={customButtonLeft}
          customMargin={customMargin}
          mobileCustomButtonTop={mobileCustomButtonTop}
          mobileCustomButtonLeft={mobileCustomButtonLeft}
          mobileCustomMargin={mobileCustomMargin}
          mobileNoMarginLeft={mobileNoMarginLeft}
          mobileLineHeight={mobileLineHeight}
          tabletLineHeight={tabletLineHeight}
          customMarginValues={customMarginValues}
          positionDesktop={positionDesktop}
          h1FontFamily={h1FontFamily}
          pFontFamily={pFontFamily}
          descFontWeight={descFontWeight}
          mobileDescLineHeight={mobileDescLineHeight}
          mobileHeaderTextWidth={mobileHeaderTextWidth}
          paraWidthML={paraWidthML}
          paraWidthXL={paraWidthXL}
          mobileButtonWidth={mobileButtonWidth}
          headerMaxWidthDesktop={headerMaxWidthDesktop}
          contentBlock
          contentAlign={contentAlign}
          buttonPos={buttonPos}
          buttonWidth={buttonWidth}
          paraMobileMaxWidth={paraMobileMaxWidth}
        >
          {children && children[0]}
          {royale && children && children[1]}
          {!royale && children && children[1] && Object.keys(children[1].length > 0) ? (
            children[1]
          ) : (
            <p>
              {subText}
            </p>
          )}
          <button
            type="button"
            onClick={this.handleApplyNowVersion2}
          >
            {buttonText}
          </button>
        </Block>
      </Wrapper>
    )
  }
}

export default withRouter(RealtyContainer(CommonHero))
