/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from "react"
import { handleDataLayerPush } from "services/dataLayerUtil"
import styled from "styled-components"
import MQLForm from "../MQLForm"
import { ATC, INTER } from "@fonts"

const VIDEO_URL_PREFIX = "https://d2fedz0by71ckz.cloudfront.net/video"

const Wrap = styled.div`
  width: 388px;
  position: relative;
  z-index: 10;
  right: -94px;
  @media(max-width: 1099px) {
    width: 350px;
    right: 0;
  }
`

const VideoWrap = styled.div`
  width: 100%;
  height: 430px;
`

const VideoCardWrap = styled.div`
  display: flex;
  position: relative;
`

const OpacityCard = styled.div`
  width: 220px;
  height: 381px;
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  top: 15px;
  pointer-events: none;

  ${props => props.left && `
    left: -77px;
    top: 15px;
    background: url(${CLOUDFRONT}/GradientVideo.svg);
    background-size: cover;

    @media(max-width: 1099px) {
      left: 0;
    }
  `}

  ${props => props.right && `
    right: 96px;

    @media(max-width: 1099px) {
      right: 0;
    }
  `}

  img, video {
    width: 100%;
    height: 100%;
    filter: blur(0.5px);
    opacity: 0.5;
    border-radius: 8px;
  }
`

const MainCard = styled.div`
  width: 220px;
  height: 396px;
  position: absolute;
  z-index: 2;

  border-radius: 8px;

  img, video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 20;
  }


  @media(max-width: 1099px) {
    right: 70px;
  }

`

const ControlBar = styled.div`
  display: flex;
  background: #EDEDED;
  border-radius: 8px;
  width: 360px;
  height: 35px;
  flex-shrink: 0;
  position: relative;
  left: -69px;
  align-items: center;
  justify-content: center;

  @media(max-width: 1099px) {
    left: unset;
    width: 347px;
  }
`

const ControlBarText = styled.p`
  margin: 0;
  color: #ABA5A5;
  text-align: center;
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  position: relative;
  z-index: 1;
`

const ControlButton = styled.button`
   border: none;
   background: transparent;
   flex-grow: 1;
   position: relative;
   z-index: 15;
   max-width: 120px;

  ${props => props.active && `
    background: white;
    border-radius: 8px;
    height: 28px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

    p {
      color: #000;
    }
  `}
`

const RightSection = ({
  formVisible,
  setFormVisible,
  className,
}) => {
  const DEFAULT_MAIN = {
    thumbnail: `${CLOUDFRONT}/ElizabethTestimonialThumbnailNew.png`,
    url: `${VIDEO_URL_PREFIX}/aligoodCompressed.mp4`,
    videoUrl: `${VIDEO_URL_PREFIX}/aligoodCompressed.mp4`,
    video_name: "brand",
  }

  const DEFAULT_RIGHT = {
    thumbnail: `${CLOUDFRONT}/HeartstoneTestimonialThumbnail.png`,
    url: `${VIDEO_URL_PREFIX}/heartStoneCompressed.mp4`,
    videoUrl: `${VIDEO_URL_PREFIX}/heartStoneCompressed.mp4`,
    video_name: "ownership",
  }

  const DEFAULT_LEFT = {
    thumbnail: `${CLOUDFRONT}/IlaTestimonialThumbnail.png`,
    url: `${VIDEO_URL_PREFIX}/ilaCompressed.mp4`,
    videoUrl: `${VIDEO_URL_PREFIX}/ilaCompressed.mp4`,
    video_name: "growth",
  }

  const [activeId, setActiveId] = useState(2)
  const [leftVideo, setLeftVideo] = useState(DEFAULT_LEFT)
  const [mainVideo, setMainVideo] = useState(DEFAULT_MAIN)
  const [rightVideo, setRightVideo] = useState(DEFAULT_RIGHT)
  const videoRef = useRef()
  const leftOpacityCard = useRef()
  const rightOpacityCard = useRef()

  const interactionHandler = (id) => {
    if (videoRef) {
      videoRef.current.pause()
      videoRef.current.classList.add("popup-anim-2")

      setTimeout(() => {
        videoRef.current.classList.remove("popup-anim-2")
      }, 400)
    }

    const ELIZABETH = {
      thumbnail: `${CLOUDFRONT}/ElizabethTestimonialThumbnailNew.png`,
      url: `${VIDEO_URL_PREFIX}/aligoodCompressed.mp4`,
      video_name: "brand",
    }

    const HEARTSTONE = {
      thumbnail: `${CLOUDFRONT}/HeartstoneTestimonialThumbnail.png`,
      url: `${VIDEO_URL_PREFIX}/heartStoneCompressed.mp4`,
      video_name: "ownership",
    }

    const ILA = {
      thumbnail: `${CLOUDFRONT}/IlaTestimonialThumbnail.png`,
      url: `${VIDEO_URL_PREFIX}/ilaCompressed.mp4`,
      video_name: "growth",
    }

    let left = {}

    let main = {}

    let right = {}

    switch (id) {
      case 1:
        main = {
          ...HEARTSTONE,
        }
        right = {
          ...ILA,
        }
        left = {
          ...ELIZABETH,
        }
        break
      case 2:
        main = {
          ...ELIZABETH,
        }
        left = {
          ...HEARTSTONE,
        }
        right = {
          ...ILA,
        }
        break
      case 3:
        main = {
          ...ILA,
        }
        left = {
          ...HEARTSTONE,
        }
        right = {
          ...ELIZABETH,
        }
        break
      default:
        main = {
          ...ELIZABETH,
        }
        left = {
          ...ILA,
        }
        right = {
          ...ELIZABETH,
        }
    }

    setActiveId(id)
    setLeftVideo(left)
    setRightVideo(right)
    setMainVideo(main)
  }

  const handlePlayPause = (e, videoName) => {
    const payload = {
      video_name: videoName,
    }
    const videoElement = e.target
    videoRef.current.classList.remove("popup-anim-2")

    if (videoElement.paused) {
      videoElement.play()
      handleDataLayerPush(null, "hp_video_played", "", payload)
    } else {
      videoElement.pause()
    }
  }

  return (
    <>
      {formVisible ? (
        <MQLForm
          setFormVisible={setFormVisible}
          className={className}
        />
      ) : (
        <Wrap className="popup-anim-2">
          <VideoWrap>
            <VideoCardWrap>
              <OpacityCard
                left
                ref={leftOpacityCard}
              >
                <video poster={leftVideo.thumbnail} />
              </OpacityCard>
              <MainCard>
                <video
                  preload="none"
                  ref={videoRef}
                  id="videoPlayerHP"
                  controlsList="nofullscreen"
                  loading="lazy"
                  webkit-playsinline
                  playsInline
                  onClick={e => handlePlayPause(e, mainVideo.video_name)}
                  src={mainVideo.url}
                  poster={mainVideo.thumbnail}
                  fetchpriority="high"
                />
              </MainCard>
              <OpacityCard
                right
                ref={rightOpacityCard}
              >
                <video poster={rightVideo.thumbnail} />
              </OpacityCard>
            </VideoCardWrap>
          </VideoWrap>
          <ControlBar>
            <ControlButton
              //onMouseEnter={() => { interactionHandler(3) }}
              onClick={() => { interactionHandler(3) }}
              active={activeId === 3}
            >
              <ControlBarText>Growth</ControlBarText>
            </ControlButton>
            <ControlButton
              //onMouseEnter={() => { interactionHandler(2) }}
              onClick={() => { interactionHandler(2) }}
              active={activeId === 2}
            >
              <ControlBarText>Brand</ControlBarText>
            </ControlButton>
            <ControlButton
              //onMouseEnter={() => { interactionHandler(1) }}
              onClick={() => { interactionHandler(1) }}
              active={activeId === 1}
            >
              <ControlBarText>Ownership</ControlBarText>
            </ControlButton>
          </ControlBar>
        </Wrap>
      )}
    </>

  )
}

export default RightSection
