export const hexRgba = (hex, opacity) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const interimResult = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const result = interimResult
    ? {
      r: parseInt(interimResult[1], 16),
      g: parseInt(interimResult[2], 16),
      b: parseInt(interimResult[3], 16)
    }
    : {
      r: 0,
      g: 0,
      b: 0
    };

  return `rgba(${result.r}, ${result.g}, ${result.b}, ${opacity})`;
};