import React, { useState } from "react"
import styled from "styled-components"
import TechAssets from "container/TechAssets/index"
import {
  StyledTextArea,
} from "@ui/antd"

const StyledWrap = styled.div``

const StyledNote = styled.p``

const Label = styled.label``

const ConfirmationNote = ({
  text,
  updateNote,
  value,
  isOfferAccepted,
}) => {
  const [currentNote, setNote] = useState("")
  return (
    <StyledWrap>
      <StyledNote>
        {text}
      </StyledNote>
      {!isOfferAccepted && (
        <>
          <Label htmlFor="note">Note:</Label>
          <StyledTextArea
            name="note"
            value={currentNote}
            onChange={(e) => {
              const val = e.target.value
              setNote(val)
              updateNote(val)
            }}
          />
        </>
      )}

    </StyledWrap>
  )
}

export default ConfirmationNote
