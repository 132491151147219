import axios from "axios"

export const fetchNotificationAPI = (count = 15, startTime = 0) => axios
  .get(`${API_V2_URL}notification?count=${count}&startTime=${startTime}`)

export const fetchNotificationUnreadCountAPI = () => axios
  .get(`${API_V2_URL}notifications/unread-count`)

export const finishOnboardingAPI = () => axios
  .post(`${API_URL}user/onboarding`)

// View API
export const notificationViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)
