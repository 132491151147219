import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const TabWrap = styled.div`
  display: flex;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  padding: 8px 8px 0px 8px;
`
export const TabInfo = styled.div`
  padding: 8px;
  border-radius: 8px 8px 0px 0px;
  background: transparent;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid transparent

  ${props => props.isActive && `
    border-radius: 8px 8px 0px 0px;
    color: var(--Color-Primary-Primary, #5A5FF2);
    border-bottom: 2px solid var(--Color-Primary-Primary-500, #5A5FF2);
  `}
`
export const TabTitle = styled.p`
color: var(--Global-Mapping-Gray-700, #373758);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 18.9px */

  ${props => props.isActive && `
    color: var(--Color-Primary-Primary-500, #5A5FF2);
  `}
`
