import * as React from "react";
const RemoveAgentBinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M3.33331 4.97909H12.6666"
      stroke="#F05152"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4.97925V12.3126C12 13.0492 11.4033 13.6459 10.6667 13.6459H5.33333C4.59667 13.6459 4 13.0492 4 12.3126V4.97925"
      stroke="#F05152"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.81258H6"
      stroke="#F05152"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66665 7.64575V10.9791"
      stroke="#F05152"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33333 7.64575V10.9791"
      stroke="#F05152"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RemoveAgentBinIcon;
