import React from "react"
import CRMV2Container from "container/CRM"
import Loader from "@ui/Loader"
import { NewButton } from "@ui/antd"
import { Plus as PlusIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import SearchCriteriaInfoList from "./SearchCriteriaInfoList"
import {
  ProfileDetailsContainer,
  ProfileDetailsHeader,
  ProfileDetailsTitle,
  PropertyCardsList,
} from "../../styles"

const SearchCriteriaInfo = ({
  getCrmClientResponse,
  setCurrentDrawerPage,
  setClientID,
  toggleUserOverview,
  toggleAddNewLeadDrawer,
  setCurrentClientType,
  isUserOverviewDrawerOpen,
}) => {
  const { data } = getCrmClientResponse || {}
  const { clientTransactions } = data || {}
  const { BUYING: buyerTransaction } = clientTransactions || {}
  const { agentInfo } = isUserOverviewDrawerOpen || {}
  return (
    <ProfileDetailsContainer>
      <ProfileDetailsHeader>
        <ProfileDetailsTitle>Search Criteria</ProfileDetailsTitle>

        <NewButton
          type="primary"
          onClick={() => {
            setCurrentDrawerPage("add-same-lead")
            toggleAddNewLeadDrawer(true)
            toggleUserOverview({ showUserOverviewDrawer: false, agentInfo })
            setCurrentClientType("buyer")
            setClientID({
              id: data.client_id,
              deal_id: data.id,
            })
          }}
          icon={<PlusIcon />}
          iconPosition="start"
        >
          Add Search
        </NewButton>
      </ProfileDetailsHeader>

      <PropertyCardsList>
        {buyerTransaction ? (
          <>
            {buyerTransaction.map(x => <SearchCriteriaInfoList data={x} />)}
          </>
        ) : (
          <Loader />
        )}
      </PropertyCardsList>
    </ProfileDetailsContainer>
  )
}

export default CRMV2Container(SearchCriteriaInfo)
