import React from "react"
import Select from "react-select"
import styled from "styled-components"
import { isArray } from "lodash"
import Box from "@ui/Box"
import { isObject } from "util"

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
  position: relative;

  ${props => props.isLandingPage && `
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
    margin-top: 10px;

    span img {
      width: 18px;
      height: 18px;
    }
  `}
`
const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #d4d4d4;
  font-weight: 300;
  margin: 5px 0px;
  line-height: 1.5;
  display: inline-block;
  > div > div > div {
    border: 1px solid #ED1616 !important;
  }
`

const Required = styled.p`
  display: inline-block;
  margin-left: 3px;
  color: #E34C4C;
  margin-bottom: 0px;
`

const checkForValue = (val = "", isMulti) => {
  if (!val) {
    return null
  }
  if (isMulti) {
    return val
  }
  if (isArray(val)) {
    return { value: val[0], label: val[0] }
  }
  if (!isObject(val)) {
    return { value: val, label: val }
  }
  return val
}

const customStyles = {
  singleValue: (provided) => {
    const overflow = "initial"
    const fontSize = "16px"
    return { ...provided, overflow, fontSize }
  },
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
    }
  },
}

const RenderSelect = (field) => {
  const {
    input: { onFocus } = {},
    input,
    options,
    config = {},
    placeholder,
    meta,
    label,
    defaultValue,
    width,
    components,
    selectStyles,
    labelStyles = {},
    errorStyles,
    disabled,
    onBlur,
    placeholderStyled,
    isRequiredField,
    isLandingPage,
  } = field
  return (
    <Box onFocus={onFocus} w={width} position="relative">
      {label && (
        <Label style={labelStyles}>
          {label}
          {isRequiredField && (<Required>*</Required>)}
        </Label>
      )}
      <Select
        {...config}
        value={checkForValue(input.value, config.isMulti)}
        onChange={(item) => {
          input.onChange(item)
        }}
        onBlur={() => {
          if (onBlur) onBlur()
          input.onBlur(input.value)
        }}
        options={options}
        placeholder={(
          <div
            style={{
              borderColor: meta.touched && meta.error ? "1px solid red" : "inherit",
              fontSize: "12px",
              color: meta.touched && meta.error ? "#ED1616" : "inherit",
              ...placeholderStyled || {},
            }}
          >
            {placeholder}
          </div>
        )}
        defaultValue={defaultValue}
        styles={meta.touched && meta.error ? errorStyles : (selectStyles || customStyles)}
        components={components}
        isDisabled={disabled}
      />
      {meta.touched && meta.error && (
        <ErrorLabel isLandingPage={isLandingPage}>
          {isLandingPage ? (
            <>
              <span>
                <img src={`${CLOUDFRONT}/AlertIcon.png`} alt="error" />
              </span>
            </>
          ) : meta.error}
        </ErrorLabel>
      )}
    </Box>
  )
}

export default RenderSelect
