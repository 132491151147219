import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { get, isEmpty } from "lodash"
import moment from "moment"
import CookiesStorage from "services/CookieStorage"
import { Button } from "@ui/antd"
import colors from "@colors"
import { ATC } from "@fonts"

const Wrap = styled.div`
  padding: 25px 0px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContainer = styled.div``

const Title = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #303030;
  margin: 0;
`

const Description = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #303030;
  margin: 0;
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 8px;
`

const Header = ({
  setCurrentDrawerPage,
  getFinancingForTransactionResponse,
  getCtIdResponse,
  createFinancingForTransaction,
  getCrmClientResponse,
}) => {
  const agentId = CookiesStorage.load("agentId")
  const { data: financeData, isFetching } = getFinancingForTransactionResponse || {}
  const { data: transactionData } = getCtIdResponse || {}
  const { id } = transactionData || {}
  const { data: clientData } = getCrmClientResponse || {}
  const canApplyAgain = !isFetching && financeData && get(financeData, "[0].client_pre_approval_available")
  const payload = {
    chat_with_mlo: true,
  }
  const { emails, phones, name } = clientData || {}

  const firstName = name && name.split(" ")[0]
  const lastName = name && name.split(" ")[1] || ""

  const handleSubmit = () => {
    const subject = "Interested in mortgage"
    const michaelEmail = "michael.bardales@radius-mortgage.com"

    const mail = document.createElement("a")
    mail.href = `mailto:${michaelEmail}?subject=${encodeURIComponent(subject)}`
    mail.click()
    createFinancingForTransaction({ payload, transactionId: id, showToast: false })
  }

  /**
   * @author Saurav Tiru
   * @function handleInstantRecordForSoulFinancing
   * @description This function creates a record in soul mortgage financing dashboard with
   *              values such as name, email, phone, clientType, source and type.
   *              It also switches to the main form where further pre-approved client details
   *              can be filled.
   */
  const handleInstantRecordForSoulFinancing = () => {
    const property = {
      city: null,
      state: null,
      address: null,
      agent_id: null,
      price: null,
      zipcode: null,
      purpose: null,
    }

    const dataPayload = {
      clientType: "buyer",
      type: "mortgage",
      source: "mortgage",
      agent_id: parseInt(agentId, 10),
      emails,
      phones,
      firstname: firstName,
      lastname: lastName,
      property,
      loan_amount: null,
      loan_purpose: null,
      credit_profile: null,
      is_there_a_co_borrower: null,
      household_income: null,
    }

    createFinancingForTransaction({
      payload: dataPayload, transactionId: id, showToast: true,
    })

    setCurrentDrawerPage("mortgage")
  }
  return (
    <Wrap>
      <Container>
        <TitleContainer>
          <Title>
            Today’s Rates
          </Title>
          <Description>
            All APRs are updated daily.
          </Description>
        </TitleContainer>
        <ButtonWrap>
          {isEmpty(financeData) || canApplyAgain ? (
            <Button
              style={{
                background: `${colors.marineBLue}`,
                borderColor: `${colors.marineBLue}`,
                borderRadius: "4px",
              }}
              type="primary"
              onClick={() => handleInstantRecordForSoulFinancing()}
            >
              Get Client Pre-Approved
            </Button>
            
          ) : (
            <Button
              style={{
                background: `${colors.white}`,
                borderColor: `${colors.suvaGray}`,
                borderRadius: "4px",
              }}
              disabled
            >
              Applied
            </Button>
          )}
          <Button
            style={{
              color: `${colors.marineBLue}`,
              borderColor: `${colors.marineBLue}`,
              borderRadius: "4px",
            }}
            onClick={handleSubmit}
          >
            Chat with an MLO
          </Button>
        </ButtonWrap>
      </Container>
    </Wrap>
  )
}

export default CRMV2Container(Header)
