import styled from "styled-components";
import { Modal } from "antd";
import colors from "colors"
import {INTER } from "@fonts"

export const Title = styled.h4`
  color: ${colors.new_colorNeutral900};
  font-family: ${INTER};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    height: 172px !important;
    border-radius:8px !important;
  }
`;
  
export const Description = styled.p`
  color: ${colors.textSubHeading};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Image = styled.div`
  border-radius: 24px;
  background: #EEF2FF;
  height: 48px;
  width: 48px;
  padding: 12px;
`;
