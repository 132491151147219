import styled from "styled-components"
import { DatePicker } from "antd"
import { ATC, INTER } from "@fonts"

export const FilterWrap = styled.div`
  .ant-checkbox-inner {
    outline: 0.2px solid #5A5FF2;
    border-radius: 4px;
    border: 0.1px solid #666;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .ant-checkbox-group-item {
    color: #666;
    /* leading-tight/body-1/regular */
    font-family: ${INTER};
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
  }

  .ant-radio-wrapper {
    color: #666;

    /* leading-tight/body-1/regular */
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
  }
`

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 55px;
`

export const ExternalFilterWrap = styled.div`
  padding: 25px;
`

export const InnerFilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const FilterType = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);

    /* body-1/semi-bold */
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
  }
`

export const DateWrap = styled.div`
  // .ant-picker {
  //   border: 1px solid #9ba2ad !important;
  //   border-radius: 8px !important;
  // }
`

export const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #9ba2ad !important;
  border-radius: 8px !important;
  height: 40px !important;
  width: 188px !important;

  .ant-picker {
    border: 1px solid #9ba2ad !important;
    border-radius: 8px !important;
    height: 40px !important;
    width: 188px !important;
  }
`

export const InDateLabel = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    /* caption/medium */
    font-family: ${INTER};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
    letter-spacing: 0.5px;
  }
`

export const FlexWrap = styled.div`
  display: flex;
  gap: 30px;
`
