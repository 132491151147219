import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"

const H2 = styled.h2`
  font-family: ${ATC};
  color: #303030;
  font-size: ${props => props.fontSize || "34px"};
  border-bottom: 1px solid ${colors.milkyWhite};
  padding-bottom: 16px;
  margin-top: 70px;
  font-weight: 500;
  ${props => props.cursor && `
    cursor: pointer;
  `}

  ${props => props.isHideLine && `
    border-bottom: none;
  `}
`

const PageTitle = props => (
  <H2 {...props} />
)

export default PageTitle
