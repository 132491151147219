import React from "react"
import root from "window-or-global"
import styled from "styled-components"
import queryString from "query-string"
import { Route, withRouter } from "react-router-dom"
import ScrollToTop from "@ui/ScrollToTop"
import MarketingNavbar from "dumbComponents/common/MarketingNavbar"
import Footer from "dumbComponents/common/MarketingFooter"

const LayoutWrap = styled.div`
  transition-duration: 0.3s;
  ${props => props.isIosBrowser && `
    padding-top: 0px;
  `}
`

const Layout = ({ children, location, headerVersion }) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
  const isRadiusHomes = parsedQuery && parsedQuery.navbar === "hide"

  return (
    <LayoutWrap isIosBrowser={isIosBrowser || isRadiusHomes}>
      <ScrollToTop />
      {!isIosBrowser && !isRadiusHomes && (
        <MarketingNavbar location={location} headerVersion={headerVersion} />
      )}
      {children}
      <Footer />
    </LayoutWrap>
  )
}

class MailRoute extends React.Component {
  state = {}

  //remove hubspot chat

  render() {
    const {
      component: Component,
      SEOData,
      location,
      ...rest
    } = this.props
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Layout
              location={location}
              SEOData={SEOData}
            >
              <Component {...props} />
            </Layout>
          )
        }}
      />
    )
  }
}

export default withRouter(MailRoute)
