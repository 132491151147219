import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Support from "../Icons/NewIcons/Support"

const SupportTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    isActive={routeArray.includes("support")}
  >
    <div className="icon-block-new">
      <Support />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? {pathname: "https://knowledgebase.radiusagent.com/knowledge"} : "/realty/promos?type=support"}
        isActive={routeArray.includes("support")}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_Supoort_web")
          }
        }}
        target={isBroker ? "_blank" : ""}
      >
        Support
      </NavLink>
    </div>
  </InnerTab>
)

export default SupportTab
