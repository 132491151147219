import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Button from "@ui/Button"
import Modal from "@ui/Modal"
import ShareTheLove from "images/share-the-love.jpg"

const Wrap = styled.div`
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 44px;
    font-family: Carrera;
  }

  p {
    font-weight: 300;
    margin-top: 30px;
  }

  ${Button} {
    margin: 20px 0px;
  }


`

const HeaderImage = styled.img`
  width: 100%;
`

const ReferralPromo = ({
  header,
  body,
  CTA,
  toClose,
  history,
}) => {
  const handleLinkClick = () => {
    const { push } = history
    toClose({
      key: "shareTheLove",
    })
    push("/assist/invite-agents")
  }

  return (
    <Modal
      show
      toClose={() => toClose({
        key: "shareTheLove",
      })}
      modalStyles={{
        padding: 0,
      }}
    >
      <HeaderImage src={ShareTheLove} alt="Share the Love" />
      <Wrap>
        <h1>{header}</h1>
        <p>{body}</p>
        <Button onClick={handleLinkClick}>
          {CTA}
        </Button>
      </Wrap>
    </Modal>
  )
}

export default withRouter(ReferralPromo)
