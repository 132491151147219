import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as AuthAction from "./actions"

const mapStateToProps = (state, props) => {
  const { auth } = state
  return {
    ...auth,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      loginUser: AuthAction.loginUserAction.request,
      appleSignup: AuthAction.appleSignInAction.request,
      saveSocialLoginData: AuthAction.saveSocialLoginDataAction.request,
      saveProductInterest: AuthAction.saveProductInterestAction.request,
      saveProductInterestSteps: AuthAction.saveProductInterestStepsAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
