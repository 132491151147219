import React, { useEffect, useState } from "react"
import { reduxForm, Field, formValueSelector } from "redux-form"
import { connect } from "react-redux"
import styled from "styled-components"
import colors from "@colors"
import { get } from "lodash"
import { required } from "services/formUtils"
import { Textarea, Select } from "@ui/Form"
import createToast from "@ui/Toast"
import TagsUI from "./Tags"
import UploadImage from "./UploadImage"
import ImageModal from "../../ImageUpload"
import {
  RoomsButton,
  RoomsButtonInverted,
  CTAWrap,
  RoomsDeleteButton,
} from "../common"

const errorStyles = {
  color: "red",
  fontWeight: "300",
  fontSize: "14px",
  marginTop: "7px",
}

const DeleteRoomButtonWrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  gap: 10px;
  margin-top: 10px;
`

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SelectWrap = styled.div`
  p {
    background: #F9F9F7;
    font-size: 20px;
    color: #303030;
    margin-bottom: 10px;
    font-weight: 300;
  }
`

const InnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: flex-start;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > label {
    > span {
      background: ${colors.dreamWhite};
      font-size: 20px;
      color: ${colors.black};
      margin-bottom: 10px;
    }
  }
`

const maxLength = max => value => value
  && value.length > max ? "Word limit exceeded" : undefined

const customStyleInputArea = {
  border: "none",
  background: `${colors.white}`,
  borderRadius: "8px",
  boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
}

const DetailsFormUI = (props) => {
  const {
    getTags,
    handleSubmit,
    change,
    clearTags,
    roomTags,
    tags,
    isImageUploadOpen,
    toggleImageUploadModal,
    setPage,
    specialRoomType,
    eventData,
    initialize,
    topics,
    toggleDeleteRoomModal,
  } = props || {}

  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const {
      name,
      description,
      tags: eventTags,
      topic_id,
    } = eventData || {}

    if (eventData && eventData !== null || eventData !== undefined) {
      if (specialRoomType === "scheduled" || specialRoomType === "property") {
        initialize({
          name,
          description,
          tags: eventTags,
          topic_id: getCurrentTopicObject(topic_id),
        })

        setEditMode(true)
      } else {
        initialize({
          name,
          description,
          tags: eventTags,
        })
      }
    }
  }, [])

  const [tagsSearch, setTagsSearch] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)

  const handleRemoveTag = (tag) => {
    const updatedTags = tags.filter(x => x.name !== tag.name)
    change("tags", updatedTags)
  }

  const getTopics = () => {
    if (!topics) return [{ value: "-1", label: "No Topics" }]

    return topics.map(item => (
      {
        value: item.id,
        label: item.topic_name,
      }
    ))
  }

  const getCurrentTopicObject = (topicId) => {
    const currentMap = getTopics(topics)

    return currentMap.filter(item => item.value === topicId)[0]
  }

  const handleKeyPress = (event, getTag) => {
    if (event.key === "Enter" || getTag) {
      event.preventDefault()
      if (tags && tags.length === 10) {
        createToast("Cannot add more than 10 tags", "error")
        return
      // eslint-disable-next-line no-else-return
      } else if (tags && tags.find(tag => tag.name === event.target.value)) {
        createToast("Tag already added", "error")
        return
      }
      const tagPayload = {
        name: event.target.value || getTag.name,
      }
      change("tags", [...tags, tagPayload])
      clearTags()
      setTagsSearch("")
      setShowDropDown(false)
    }
  }

  const handleTagsInput = (event) => {
    if (event.target.value !== "") {
      setShowDropDown(true)
      getTags({
        query: event.target.value,
      })
      event.preventDefault()
      setTagsSearch(event.target.value)
    } else {
      setTagsSearch(event.target.value)
      setShowDropDown(false)
    }
  }

  return (
    <FormWrap onSubmit={handleSubmit}>
      <InnerWrap>
        <Field
          name="name"
          label={specialRoomType === "property" ? "Property Name" : "Room Topic"}
          validate={[required, maxLength(80)]}
          rows={2}
          version={2}
          component={Textarea}
          isVideoRoom
          customStyle={customStyleInputArea}
          charLimit={80}
          errorStyles={errorStyles}
        />
        <UploadImage />
      </InnerWrap>
      <Field
        name="description"
        label={specialRoomType === "property" ? "Property Description" : "Description"}
        validate={maxLength(1000)}
        rows={4}
        version={2}
        component={Textarea}
        isVideoRoom
        customStyle={customStyleInputArea}
        errorStyles={errorStyles}
        charLimit={1000}
      />
      {(specialRoomType === "scheduled" || specialRoomType === "property") && (
        <SelectWrap>
          <p>Room Category</p>
          <Field
            name="topic_id"
            component={Select}
            options={getTopics()}
            placeholder="Select category"
            validate={required}
          />
        </SelectWrap>
      )}
      <TagsUI
        tags={tags}
        handleRemoveTag={handleRemoveTag}
        handleKeyPress={handleKeyPress}
        handleTagsInput={handleTagsInput}
        showDropDown={showDropDown}
        tagsSearch={tagsSearch}
        roomTags={roomTags}
      />
      {isImageUploadOpen && (
        <ImageModal
          toClose={() => { toggleImageUploadModal(false) }}
        />
      )}
      <FlexWrap>
        {editMode && (
          <DeleteRoomButtonWrap>
            <RoomsDeleteButton
              type="button"
              onClick={() => {
                toggleDeleteRoomModal(true)
              }}
            >
              <p>Delete</p>
            </RoomsDeleteButton>
          </DeleteRoomButtonWrap>
        )}
        <CTAWrap>
          <RoomsButtonInverted
            type="button"
            onClick={() => setPage({
              page: "select-type",
            })}
          >
            <p>Go Back</p>
          </RoomsButtonInverted>
          <RoomsButton
            type="submit"
          >
            <p>Continue</p>
          </RoomsButton>
        </CTAWrap>
      </FlexWrap>
    </FormWrap>
  )
}

const DetailsForm = reduxForm({
  form: "ROOMS_DETAILS_FORM",
  destroyOnUnmount: false,
  initialValues: {
    tags: [],
  },
})(DetailsFormUI)

const formSelector = formValueSelector("ROOMS_DETAILS_FORM")

const SelectedForm = connect((state) => {
  const is_video_room = formSelector(state, "is_video_room")
  const is_public = formSelector(state, "is_public")
  const speakers = formSelector(state, "speakers")
  const tags = formSelector(state, "tags")
  const scheduled_at = formSelector(state, "scheduled_at")
  return {
    is_video_room,
    is_public,
    speakers,
    tags,
    scheduled_at,
  }
})(DetailsForm)

export default SelectedForm
