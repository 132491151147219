import * as React from "react";
const ConfirmTick = (props) => (
  <svg
    width={64}
    height={65}
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={64} height={64} rx={32} fill="#EFEFFE" />
    <path
      d="M48 21.5L26 43.5L16 33.5"
      stroke="#5A5FF2"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ConfirmTick;
