import * as React from "react";
const PricePerUnit = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H8C8.55228 21 9 20.5523 9 20V3"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1 21.23C15.9533 21.23 15.8333 21.18 15.74 21.08C15.6467 20.9867 15.6 20.87 15.6 20.73V19.84L15.83 20.06C15.37 20.0467 14.96 19.9867 14.6 19.88C14.2467 19.7733 13.9467 19.65 13.7 19.51C13.58 19.4367 13.4967 19.3467 13.45 19.24C13.4033 19.1267 13.3867 19.0133 13.4 18.9C13.4133 18.7867 13.4533 18.69 13.52 18.61C13.5933 18.5233 13.6833 18.47 13.79 18.45C13.8967 18.4233 14.02 18.45 14.16 18.53C14.3467 18.6367 14.59 18.74 14.89 18.84C15.1967 18.9333 15.5633 18.98 15.99 18.98C16.3233 18.98 16.5933 18.94 16.8 18.86C17.0067 18.78 17.1567 18.67 17.25 18.53C17.35 18.39 17.4 18.23 17.4 18.05C17.4 17.9033 17.3667 17.7767 17.3 17.67C17.2333 17.5567 17.1233 17.46 16.97 17.38C16.8167 17.2933 16.61 17.2233 16.35 17.17L15.29 16.94C14.6833 16.8067 14.23 16.5833 13.93 16.27C13.63 15.9567 13.48 15.5467 13.48 15.04C13.48 14.6467 13.5767 14.2967 13.77 13.99C13.9633 13.6767 14.2333 13.4267 14.58 13.24C14.9267 13.0533 15.3333 12.9433 15.8 12.91L15.6 13.06V12.22C15.6 12.08 15.6467 11.9633 15.74 11.87C15.8333 11.77 15.9533 11.72 16.1 11.72C16.24 11.72 16.3567 11.77 16.45 11.87C16.55 11.9633 16.6 12.08 16.6 12.22V13.06L16.39 12.9C16.6767 12.9067 16.99 12.9633 17.33 13.07C17.6767 13.1767 17.9733 13.3267 18.22 13.52C18.32 13.6 18.3867 13.6933 18.42 13.8C18.4533 13.9 18.4567 14 18.43 14.1C18.4033 14.1933 18.3533 14.2733 18.28 14.34C18.2067 14.4067 18.1133 14.4467 18 14.46C17.8933 14.4667 17.77 14.43 17.63 14.35C17.4367 14.2367 17.22 14.1467 16.98 14.08C16.74 14.0067 16.4533 13.97 16.12 13.97C15.6933 13.97 15.3567 14.0667 15.11 14.26C14.87 14.4467 14.75 14.6933 14.75 15C14.75 15.2267 14.8233 15.41 14.97 15.55C15.1167 15.6833 15.3667 15.79 15.72 15.87L16.81 16.11C17.45 16.25 17.92 16.4733 18.22 16.78C18.52 17.08 18.67 17.4767 18.67 17.97C18.67 18.3567 18.5733 18.7 18.38 19C18.1867 19.2933 17.92 19.53 17.58 19.71C17.2467 19.8833 16.8567 19.9933 16.41 20.04L16.6 19.83V20.73C16.6 20.87 16.5533 20.9867 16.46 21.08C16.3667 21.18 16.2467 21.23 16.1 21.23Z"
      fill="#232338"
    />
    <path
      d="M7 18H8.99998"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14H8.99998"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 10H8.99998"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 6H8.99998"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7V9"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 7V9"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PricePerUnit;
