import React from "react"
import styled from "styled-components"
import container from "container/App/applyFlowContainer"
import colors from "@colors"
import Box from "dumbComponents/common/UI/BuilderComponents/BoxV2"
import Companies from "dumbComponents/NewLandingPage/Companies"
import HeaderForm from "./Components/HeaderForm"
import LearnMoreAboutRadius from "./Components/LearnMore"
import BuildYourOwnBrand from "./Components/BuildYourOwnBrand"
import RecruitingSupport from "./Components/RecruitingSupport"
import Coverage from "../NewLandingPage/Testimonials/Coverage"
import DivineProperties from "./Components/DivineProperties"

const CompanyWrapper = styled.div`
  position: relative;
  top: -7rem;
  background: white;
  padding: 0 5%;
  width: 90%;
  margin: auto;
`

const PadWrap = styled.div`
  padding: 60px;
  background: ${colors.dreamWhite};

  @media(max-width: 1024px) {
    padding: 25px;
  }
`

const NewTeamsPage = ({
  toggleApplyModal,
}) => (
  <Box height="max-content">
    <HeaderForm />
    <CompanyWrapper>
      <Companies />
    </CompanyWrapper>
    <LearnMoreAboutRadius />
    <BuildYourOwnBrand />
    <RecruitingSupport toggleApplyModal={toggleApplyModal} />
    <PadWrap>
      <Coverage isTeamLP toggleApplyModal={toggleApplyModal} />
    </PadWrap>
    <DivineProperties toggleApplyModal={toggleApplyModal} />
  </Box>

)

export default container(NewTeamsPage)
