import React from "react"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import { useWindowSize } from "services/Utils"

const desc = `Team leads Brandon Utke and Brendan Perry share
              why they joined Radius and how Radius is helping them build their team.`

const Wrap = styled.div`
  padding: 60px;
  background-color: #F1F3F8;

  @media(max-width: 767px) {
    padding: 20px;
  }

  @media(max-width: 425px) {
    padding: 50px 10px;
    margin-bottom: 50px;
  }
`

const BlueHead = styled.h1`
  color: ${colors.marineBLue} !important;
  margin: 0px;
  font-size: 42px;
  font-family: ${HIGHLIGHT_FONT};
  
  @media(max-width: 1099px) {
    text-align: center;
    font-size: 36px;
  }

  @media(max-width: 425px) {
    font-size: 32px;
  }
`

const ContentWrap = styled.div`
  max-width: 1440px;
  margin: auto;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media(max-width: 1099px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    > iframe {
      max-width: 637px;
      width: 100%;
    }
  }
`

const QuoteWrap = styled.div`
  > div {
    max-width: 635px;
    width: 100%;
    > h1 {
      font-size: 42px;
      font-family: ${HIGHLIGHT_FONT};
      margin: 0px;
      color: ${colors.black};
  
      @media(max-width: 768px) {
        text-align: left;
        font-size: 36px;

        > span {
          color: ${colors.marineBLue};
        }
      }
  
      @media(max-width: 425px) {
        text-align: center;
        font-size: 32px;
      }
    }
    > p {
      font-size: 22px;
      line-height: 35px;
      font-family: ${ATC};
      font-weight: 200;
      color: ${colors.black};

      @media(max-width: 1099px) {
        font-size: 20px;
        line-height: 30px;
        margin-top: 15px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > h2 {
      color: #303030;
      font-size: 22px;
      font-weight: 200;
      margin-top: 60px;
      font-family: ${ATC};
      @media(max-width: 1099px) {
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > small {
      color: #303030;
      font-size: 16px;
      font-weight: 200;
      font-family: ${ATC};
    }
  }
`

const ButtonWrap = styled.div`
  @media(max-width: 1099px) {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`

const DivineProperties = ({
  toggleApplyModal,
}) => {

  const size = useWindowSize()
  return (
    <Wrap>
      <ContentWrap>
        <div>
          <QuoteWrap>
            <div>
              <h1>
                Divine Properties made the switch to Radius– 
                {size.width >= "768" && size.width < "1440" ? <span> here’s why </span>
                  : (
                    <BlueHead>
                      here’s why
                    </BlueHead>
                  )}
              </h1>
              <p>
                {desc}
              </p>
            </div>
            <ButtonWrap>
              <Button
                style={{
                  width: "365px",
                  padding: 10,
                  background: `${colors.marineBLue}`,
                  border: "none",
                  borderRadius: "0px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                Work with us
              </Button>
            </ButtonWrap>
          </QuoteWrap>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/NF9etSlnA6w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </ContentWrap>
    </Wrap>
  )
}

export default DivineProperties
