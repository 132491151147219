import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import {
  applyPricingCoupon,
  checkoutPricingCart,
  updatePaymentPricingModal,
  fetchSubscriptions,
  modifyMemberQuantity,
  setAmountForCheckout,
  getTeamMembers,
  fetchPlanDuration,
  addPlanToCart,
  removePlanFromCart,
  clearPricingCart,
} from "./actionCreators"
import { toggleHeaderBannerAction } from "container/App/actions"
import * as ReferralSelectors from "./selector"

const mapStateToProps = state => ({
  isLoggedIn: authCheck(),
  pricingPlansMap: ReferralSelectors.pricingPlansMap(state),
  teamSize: ReferralSelectors.teamSize(state),
  cart: ReferralSelectors.getCartFromStore(state),
  coupon: ReferralSelectors.getCouponObjectFromStore(state),
  paymentModalState: ReferralSelectors.getPaymentModalStateFromStore(state),
  activeSubscriptions: ReferralSelectors.getActiveSubscriptionsSelector(state),
  checkoutTotal: ReferralSelectors.checkoutTotal(state),
  subTotal: ReferralSelectors.subTotal(state),
  isCartHavingTeamPlan: ReferralSelectors.isCartHavingTeamPlan(state),
  monthlyPlanIfTeam: ReferralSelectors.monthlyPlanIfTeam(state),
  teamMembersList: ReferralSelectors.teamMembersList(state),
  currentTeamSize: ReferralSelectors.currentTeamSize(state),
  plansDuration: ReferralSelectors.plansDuration(state),
  currentPlanType: ReferralSelectors.currentPlanType(state),
  categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
  shareTheLoveInviteeResponse: ReferralSelectors.getShareTheLoveInviteeResponse(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  applyPricingCoupon,
  checkoutPricingCart,
  updatePaymentPricingModal,
  fetchSubscriptions,
  modifyMemberQuantity,
  setAmountForCheckout,
  getTeamMembers,
  fetchPlanDuration,
  addPlanToCart,
  removePlanFromCart,
  clearPricingCart,
  toggleBanner: toggleHeaderBannerAction.call,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
