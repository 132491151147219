import * as React from "react";
const YearBuilt = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5V9C17 10.1046 16.1046 11 15 11H7C4.79086 11 3 9.20914 3 7V7C3 4.79086 4.79086 3 7 3H15C16.1046 3 17 3.89543 17 5Z"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9365 4.5H18.9998C19.5521 4.5 19.9998 4.94772 19.9998 5.5V7.5C19.9998 8.05228 19.5521 8.5 18.9998 8.5H16.9998"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 6.5H22"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7H9.59848"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 11L9.5 17"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 11V12.4264C15 13.2955 14.2955 14 13.4264 14H10.5"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 17L6.50994 10.9702"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={3}
      y={17}
      width={8}
      height={4}
      rx={1}
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default YearBuilt;
