import React, { useState, useEffect } from "react"
import OffersV2 from "container/OffersV2"
import Listings from "container/Listings"
import Offer from "container/TechAssets"
import { EyeIcon, ShareIcon } from "dumbComponents/OffersV2/icons"
import { BlueButton } from "dumbComponents/OffersV2/commonStyles"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  ListWrap,
  ListRowWrap,
  GoBack,
} from "./styles"
import {
  AddButtonCTA,
} from "../../../components"
import CheckboxList from "../CheckboxList"
import { StyledCheckbox } from "../CheckboxList/styles"
import { AddDocumentsUI } from "./Components/index"

const LibraryOptions = [
  {
    value: "offer_docs",
    label: "Offer Docs",
  },
  {
    value: "listing_docs",
    label: "Listing Docs",
  },
  {
    value: "uploads",
    label: "Uploads",
  },
]

const Templates = ({
  dealTermObject,
  docList,
  currentSideNavTab,
  toggleCreateEnvelopeModal,
  updateRecommendedDocsObject,
  createReceiverView,
}) => {
  const [isAddDocumentUIVisible, toggleAddDocumentsUI] = useState(false)

  useEffect(() => {
    if (currentSideNavTab === "templates") {
      toggleAddDocumentsUI(false)
    }
  }, [currentSideNavTab])

  const OptionList = [
    {
      label: "Edit",
      onClick: () => console.log("Edit"),
    },
    {
      label: "Download",
      onClick: () => console.log("download"),
    },
    {
      label: "Send",
      onClick: () => console.log("Send"),
    },
  ]

  const MainList = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        //createReceiverView()
      },
      showDivide: false,
    },
  ]

  const toggleAllSelected = (e) => {
    const checked = e.target.checked
    const currentDocs = [
      ...docList,
    ]
    currentDocs.forEach((item) => {
      item.isSelected = checked
    })
    updateRecommendedDocsObject(currentDocs)
  }

  const handleSelectedState = (e, eID) => {
    const checked = e.target.checked
    const currentDocs = [
      ...docList,
    ]

    currentDocs.forEach((item) => {
      console.log(item.id, eID)
      if (item.id === eID) {
        item.isSelected = checked
      }
    })

    updateRecommendedDocsObject(currentDocs)
  }

  return (
    <Wrap>
      {/*Deal Terms*/}
      {isAddDocumentUIVisible ? (
        <>
          <SectionWrap>
            <ListRowWrap>
              <SectionTitle>
                <p>Add From Library</p>
              </SectionTitle>
              <GoBack
                onClick={() => toggleAddDocumentsUI(false)}
              >
                Go Back
              </GoBack>
            </ListRowWrap>
            <ListWrap>
              <AddDocumentsUI
                options={LibraryOptions}
                goBack={() => toggleAddDocumentsUI(false)}
              />
            </ListWrap>
          </SectionWrap>
        </>
      ) : (
        <>
          <SectionWrap>
            <SectionTitle>
              <p>Templates</p>
            </SectionTitle>
            <ListRowWrap
              row
            >
              <StyledCheckbox
                value="here"
                onChange={toggleAllSelected}
              >
                Select all
              </StyledCheckbox>
              <AddButtonCTA name="Add Documents from library" onClick={() => toggleAddDocumentsUI(true)} />
            </ListRowWrap>
              <ListWrap>
                {
                  docList && docList.map((item) =>
                      <CheckboxList
                list={item}
                handleSelectedCheck={handleSelectedState}
                onClick={e => console.log("clicked", e.target.value)}
                dropdownList={OptionList}
                mainList={MainList}
              />
                  )
                }
            
            </ListWrap>
          </SectionWrap>
          <SectionWrap>
            {/**DISABLE IF NOTHING SELECTED */}
            <BlueButton disabled={docList && docList.length <= 0} maxWidth onClick={() => toggleCreateEnvelopeModal(true)}>
              <p>Create Envelope</p>
            </BlueButton>
          </SectionWrap>
        </>
      )}
    </Wrap>
  )
}

export default Listings(Offer(OffersV2(Templates)))
