import { Select } from "@ui/antd"
import styled from "styled-components"

export const StyledSelect = styled(Select)`
  width: fit-content;
  height: 100%;
  .ant-select-selector {
    background-color: #F1F1FE !important;
    height: 23px !important;
    padding: 18px !important;
  }
  .ant-select-selection-item {
    font-size: 14px;
    text-transform: uppercase;
  }
`

export const Wrap = styled.div`
  height: 38px;
  position: relative;
`

export const ColorWrap = styled.div`
  position: absolute;
  z-index: 100;
  top: 8px;
  left: 3px;
`
