import styled from "styled-components"
import colors from "@colors"

export const ProgressWrap = styled.div`
display: flex;
width: 257px;
height: 50px;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
border-radius: 8px;
background: #FFF;
`
export const CapAmtWrap = styled.div`
align-self: stretch;
color: var(--Color-Gray-Gray-500, #5C5C94);
text-align: right;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%;
letter-spacing: 0.5px;
`
export const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
`
export const TooltipIndicator = styled.div`
position: absolute;
top: -24px; /* Adjust height above the progress bar */
padding: 4px 8px;
background: ${colors.new_text_purple_600};
color: #fff;
font-size: 10px;
font-weight: bold;
border-radius: 4px;
white-space: nowrap;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
 &::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Position below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${colors.new_text_purple_600}; /* Same color as the tooltip */
  }
`
