import React from "react"
import {
  RecruitandEarnSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const RecruitAndEarnTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[1] === "recruit-and-earn"}>
    <div className="icon-block-new">
      <IconEle
        scale="1.5"
        src={RecruitandEarnSVGIcon}
        alt="RARleaderboard"
        isActive={routeArray[1] === "recruit-and-earn"}
      />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/recruit-and-earn/promo"
        isActive={routeArray[1] === "recruit-and-earn"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_recruitAndEarnRAR_web")
          }
        }}
      >
        Refer & Earn
        {false && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )}
      </NavLink>
    </div>
  </InnerTab>
)

export default RecruitAndEarnTab
