import axios from "axios"
export const fetchProductAndServices = () => axios.get(`${API_URL}rar-product-services`)
export const saveRarTechRequestApi = payload => axios.post(`${API_URL}save-requested-product-services`, payload)
export const sendSlackNotificationForProductApi = payload => axios.post(`${API_URL}send-notification-for-product-request`, payload)
export const getRarAssetsApi = () => axios.get(`${API_URL}rar-assets`)
export const rarSaveCreativeRequestApi = payload => axios.post(`${API_URL}rar-creative-request`, payload)
export const saveDesignReferenceImageApi = payload => axios.post(`${API_URL}image`, payload)
export const getFinancingClientListApi = payload => axios.get(`${API_URL}mortgage/clients?skip=${payload.skip}&limit=${payload.limit}&type=${payload.type}`)
export const getFinancingSettingApi = () => axios.get(`${API_URL}mortgage/settings`)
export const rarSaveFinancingClientListApi = payload => axios.post(`${API_URL}mortgage/clients-properties`, payload)
export const fetchStateApi = payload => axios.get(`${API_V2_URL}states?agent_id=${payload.agentId}`)
// export const fetchTodaysPriceApi = () => axios.get(`https://mocki.io/v1/c5558589-9aaa-47da-8ee7-8f0cde305d35`)
// eslint-disable-next-line max-len
export const fetchTodaysPriceApi = () => axios.get(`${API_URL}mortgage/rates-settings`)

export const getPopupDetailsApi = query => axios.get(`${API_URL}popup/mortgage-rates${query}`)
export const fetchBrandingAssetsAPI = () => axios.get(`${API_URL}branding-assets-fetch`)
export const fetchBrandingInfoAPI = () => axios.get(`${API_URL}branding-assets-user-info`)
export const updateBrandingInfoAPI = payload => axios.put(`${API_URL}update-user-branding-info`, payload)
export const createBrandingInfoAPI = payload => axios.post(`${API_URL}save-user-branding-info`, payload)

export const getAllListingsAPI = query => axios.get(`${API_URL}brokerage-deals?${query}`)
export const getAllListingsPostAPI = payload => axios.post(`${API_V2_URL}brokerage-deals`, payload)
export const getListingStatusMapAPI = () => axios.get(`${API_URL}brokerage-listing-statuses`)

export const getListingCoverSheetAPI = path => axios.get(`${API_URL}listing-cover/${path}`)
export const createListingCoverSheetAPI = payload => axios.post(`${API_URL}listing-cover`, payload)
export const updateListingCoverSheetAPI = ({ payload, listing_id }) => axios.put(`${API_URL}listing-cover/${listing_id}`, payload)
export const updateListingCoverSheetVendorAPI = ({ payload, id }) => axios.put(`${API_URL}listing-cover/vendor/${id}`, payload)
export const saveListingStepsAPI = payload => axios.post(`${API_URL}brokerage-listing-steps`, payload)

// contracts
export const getContractByIdAPI = query => axios.get(`${API_URL}brokerage-deals?${query}`)
export const createContractAPI = (payload, query) => axios.post(`${API_URL}brokerage-deals-product${query ? `?${query}` : ""}`, payload)
export const updateContractAPI = ({ payload, dealId }) => axios.put(`${API_URL}brokerage-deals-product/${dealId}`, payload)

export const getGoogleAuthTokenAPI = () => axios.get(`${API_V2_URL}access-token`)

export const getListingHistoryAPI = ({ listingId }) => axios.get(`${API_URL}brokerage-deal-timeline-listing?listing_id=${listingId}`)

export const getDealBasedOnIDAPI = (step, id, agentId) => axios.get(`${API_URL}offer-cover/${id}/${step}?agent_id=${agentId}`)
export const createOfferAPI = payload => axios.post(`${API_URL}offer-cover`, payload)
export const updateOfferAPI = (payload, dealId) => axios.put(`${API_URL}offer-cover/${dealId}`, payload)
export const getAllOffersAPI = (skip, limit, query) => axios
  .get(`${API_URL}offers?skip=${skip}&limit=${limit}${query || ""}`)
export const updateOfferDealStatusAPI = payload => axios
  .post(`${API_URL}offer-status-update`, payload)
export const updateVendorAPI = (id, payload) => axios.put(`${API_URL}offer-cover/vendor/${id}`, payload)
export const updateLenderAPI = (id, payload) => axios.put(`${API_URL}offer-cover/lender/${id}`, payload)

export const getListingDocumentsAPI = query => axios.get(`${API_URL}brokerage-docs/envelopes?${query}`)

export const fetchDealTimelineAPI = dealId => axios.get(`${API_URL}contract-timeline-product?deal_id=${dealId}`)
export const fetchDealTCDetailsAPI = dealId => axios.get(`${API_URL}tc-schedule-meeting?deal_id=${dealId}`)
export const updateDealTimelineAPI = (dealId, payload) => axios.put(`${API_URL}update-contract-timeline/${dealId}`, payload)

// in progress
export const sendListingDocumentsAPI = payload => axios.put(`${API_URL}brokerage-docs/envelopes`, payload)

export const createSendeViewAPI = query => axios.post(`${API_URL}brokerage-docs/envelopes/sender-view?${query}`)
export const createEditViewAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes/correct-view`, payload)
export const createReceiverViewAPI = query => axios.post(`${API_URL}brokerage-docs/envelopes/receiver-view?${query}`)
export const downloadEnvelopeDocAPI = query => axios.get(`${API_URL}brokerage-docs/envelopes/download?${query}`)

export const getListingStepsAPI = query => axios.get(`${API_URL}listing-steps?${query}`)

export const updateDocusignEnvelopeStatusAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes/sending`, payload)

export const createAllListingImagesAPI = payload => axios.post(`${API_URL}brokerage-listing-images`, payload)

//Docusign Offers

export const sendForVoidAndRestartAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes/void-restart`, payload)

export const sendTroubleShootMessageAPI = payload => axios.post(`${API_URL}brokerage-docs/troubleshoot`, payload)

export const uploadListingDocToDocusignAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes/documents`, payload)

export const sendForVoidAndDeleteAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes/void`, payload)

export const regenerateListingDocumentsAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes`, payload)

// Offer API

export const sendOfferDocumentsAPI = payload => axios.put(`${API_URL}brokerage-offers-docs/envelopes`, payload)

export const createSendeViewOfferAPI = query => axios.post(`${API_URL}brokerage-offers-docs/envelopes/sender-view?${query}`)

// export const getOfferStepsAPI = query => axios.get(`${API_URL}listing-steps?${query}`)

export const updateOfferDocusignEnvelopeStatusAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/sending`, payload)

export const getOfferDocumentsAPI = query => axios.get(`${API_URL}brokerage-offers-docs/envelopes?${query}`)
export const sendForVoidAndRestartOfferAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/void-restart`, payload)
export const createReceiverViewOfferAPI = query => axios.post(`${API_URL}brokerage-offers-docs/envelopes/receiver-view?${query}`)
export const createEditViewOfferAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/correct-view`, payload)

export const sendTroubleShootMessageOfferAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/troubleshoot`, payload)
export const uploadOfferDocToDocusignAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/documents`, payload)
export const uploadCoverLetterDocToDocusignAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/templates/documents`, payload)

export const sendForVoidAndDeleteOfferAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/void`, payload)

export const regenerateOfferDocumentsAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes`, payload)

export const getOfferStepsAPI = query => axios.get(`${API_URL}brokerage-offer-steps?${query}`)

export const submitAndSendAllOffersFileAPI = query => axios.get(`${API_URL}brokerage-offers-docs/envelopes/bulk-download?${query}`)
export const sendAllOffersDocsAPI = query => axios.get(`${API_URL}brokerage-offers-docs/documents/info?${query}`)

export const sendDocusignDocsAPI = query => axios.get(`${API_URL}brokerage-docs/envelopes/download?${query}`)

export const requestToCreateZipAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes/bulk-download`, payload)

export const putCoverLetterDocsAPI = (dealId, payload) => axios.put(`${API_URL}update-offers-uploaded-files/${dealId}`, payload)

export const fetchCoverLetterDocsAPI = (dealId, location) => axios.get(`${API_URL}fetch-offers-uploaded-files/${dealId}/${location}`)

export const generateListingEnvelopeFromTemplateAPI = payload => axios.post(`${API_URL}brokerage-docs/envelopes`, payload)

export const generateOfferEnvelopeFromTemplateAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/envelopes`, payload)

export const fetchListingDocusignTemplatesAPI = query => axios.get(`${API_URL}brokerage-docs/templates?${query}`)

export const fetchOfferocusignTemplatesAPI = query => axios.get(`${API_URL}brokerage-offers-docs/templates?${query}`)

export const getTemplatePreviewListingAPI = payload => axios.post(`${API_URL}brokerage-docs/templates/preview`, payload)

export const getTemplatePreviewOfferAPI = payload => axios.post(`${API_URL}brokerage-offers-docs/templates/preview`, payload)

export const getAllOffersPostAPI = payload => axios.post(`${API_V2_URL}offers`, payload)

export const voidAndDuplicateCompletedOfferAPI = payload => axios
  .post(`${API_URL}brokerage-offers-docs/envelopes/delete-restart`, payload)

export const voidAndDuplicateCompletedListingAPI = payload => axios
  .post(`${API_URL}brokerage-docs/envelopes/delete-restart`, payload)

export const deleteCompletedOfferAPI = payload => axios
  .post(`${API_URL}brokerage-offers-docs/envelopes/delete`, payload)

export const deleteCompletedListingAPI = payload => axios
  .post(`${API_URL}brokerage-docs/envelopes/delete`, payload)

export const deleteCoverLetterAPI = params => axios
  .delete(`${API_V2_URL}brokerage-offers-docs/templates/documents${params}`)
export const sendMailToListingAgentsAPI = payload => axios.post(`${API_URL}send-offer-docs-email`, payload)

export const sendMortgagePropertiesAPI = payload => axios.post(`${API_URL}mortgage/properties`, payload)

export const sendClientDetailsAPI = payload => axios.post(`${API_URL}mortgage/clients`, payload)

export const createContractV2API = payload => axios.post(`${API_V2_URL}brokerage-deal-product`, payload)

export const updateContractV2API = (payload, dealId) => axios.put(`${API_V2_URL}update-brokerage-deals-product/${dealId}`, payload)

export const getAgentInformationAPI = (agentId, state) => axios
  .get(`${API_URL}fetch-agent-info-coversheet?state=${state}&agentId=${agentId}`)

export const getOfferContractDetailsAPI = id => axios
  .get(`${API_V2_URL}fetch-offer-contract-details?offerId=${id}`)

export const getContractFieldValidationAPI = id => axios
  .get(`${API_URL}contract/missing-details-count?dealId=${id}`)

export const convertOfferToContractAPI = (payload, offerId) => axios
  .post(`${API_V2_URL}brokerage-deal-product?offerId=${offerId}`, payload)

export const convertTransactionToContractAPI = (payload, transactionId) => axios
  .post(`${API_V2_URL}brokerage-deal-product?transactionId=${transactionId}`, payload)

export const fetchContractDetailsBasedOnIdApi = dealId => axios
  .get(`${API_URL}fetch-listing-contract-details?listingId=${dealId}`)

export const convertListingToContractAPI = (payload, listingId) => axios
  .post(`${API_V2_URL}brokerage-deal-product?listingId=${listingId}`, payload)

export const createSendeViewOfferChecklistAPI = query => axios.post(`${API_URL}brokerage-offers-checklist-docs/envelopes/sender-view?${query}`)

export const createEditViewOfferChecklistAPI = payload => axios.post(`${API_URL}brokerage-offers-checklist-docs/envelopes/correct-view`, payload)

export const downloadEnvelopeDocOfferChecklistAPI = query => axios.get(`${API_URL}brokerage-offers-docs/envelopes/download?${query}`)

export const sendForVoidAndRestartOfferChecklistAPI = payload => axios.post(`${API_URL}brokerage-offers-checklist-docs/envelopes/void-restart`, payload)

export const sendForVoidAndDeleteOfferChecklistAPI = payload => axios.post(`${API_URL}brokerage-offers-checklist-docs/envelopes/void`, payload)

export const createReceiverViewOfferChecklistAPI = query => axios.post(`${API_URL}brokerage-offers-checklist-docs/envelopes/receiver-view?${query}`)

export const fetchTransactionDataAPI = query => axios.get(`${API_URL}brokerage-transactions-list?${query}`)

export const fetchTransactionOverviewAPI = query => axios.get(`${API_URL}brokerage-transactions-overview?${query}`)

export const fetchContractStatusesAPI = payload => axios.get(`${API_URL}brokerage-contract-statuses`, payload)

export const deleteContractAPI = id => axios.delete(`${API_URL}brokerage-deals/${id}`)
