import React from "react"
import {
  Switch,
  Form,
} from "antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const commonRules = [
  {
    required: true,
    message: "Required",
  },
]

const ToggleSwitch = ({
  onChange,
  name,
  text,
  required = false,
  checked,
}) => (
  <Form.Item
    name={name}
    label={<LabelComponent text={text} required={required} />}
    rules={commonRules}
  >
    <Switch checked={checked} onChange={onChange} />
  </Form.Item>
)

export default ToggleSwitch
