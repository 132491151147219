import React, { useEffect, useState } from "react"
import styled from "styled-components"
import OnboardingContainer from "container/OnboardingSetup"
import FirstForm from "./FirstForm/FirstFormUI"
import SecondForm from "./SecondForm/SecondFormUI"
import ThirdForm from "./ThirdForm/ThirdFormUI"
import FourthForm from "./FourthForm/FourthFormUI"
import FifthForm from "./FifthForm/FifthFormUI"
import GettingStarted from "./GettingStarted"

const Form = styled.div`

  &::-webkit-scrollbar { 
    display: none;
  }

`
const BGBlur = styled.div`
  height: 100vh;
  width: 100vw;
  background: #354052;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`

const backgroundBlur = (completionPercentage, formStage) => (
  <>
    { (formStage !== "0")
      ? <BGBlur />
      : null
    }
  </>
)

const FormUI = ({
  fetchUserOrientationInfo, fetchOrientationInfoResponse, setFormStage, formStage,
}) => {
  const [isGoBack, setIsGoBack] = useState(false)
  useEffect(() => {
    fetchUserOrientationInfo({
      isFirstCall: true,
    })
  }, [])

  // eslint-disable-next-line consistent-return
  const formToShow = (stage, response) => {
    const count = response?.completed_steps ? response?.completed_steps.length : 0
    if (!isGoBack && response && response.completed_steps) {
      if (count === 0) {
        return "0"
      }
      if (response.completed_steps[count - 1] === "basic_license_information") {
        return "2"
      }
      if (response.completed_steps[count - 1] === "upload_files") {
        return "3"
      }
      if (response.completed_steps[count - 1] === "assistant_info") {
        return "4"
      }
      if (response.completed_steps[count - 1] === "referral_info") {
        return "5"
      }
      if (response.completed_steps[count - 1] === "checklist_screen_info") {
        return "0"
      }
      if (response.completed_steps[count - 1] === "get_started") {
        return "1"
      }
    } else return formStage
  }
  const FormStageToShow = ({ stage, response }) => {
    const tempStage = formToShow(stage, fetchOrientationInfoResponse.data.response)
    // setFormStage(tempStage)

    if (stage === "1") {
      return <FirstForm formStage={formStage} setFormStage={setFormStage} response={response} setIsGoBack={setIsGoBack} />
    }
    if (stage === "2") {
      return <SecondForm formStage={formStage} setFormStage={setFormStage} response={response} setIsGoBack={setIsGoBack} />
    }
    if (stage === "3") {
      return <ThirdForm formStage={formStage} setFormStage={setFormStage} response={response} setIsGoBack={setIsGoBack} />
    }
    if (stage === "4") {
      return <FourthForm formStage={formStage} setFormStage={setFormStage} response={response} setIsGoBack={setIsGoBack} />
    }
    if (stage === "5") {
      return <FifthForm formStage={formStage} setFormStage={setFormStage} response={response} setIsGoBack={setIsGoBack} />
    }
    return null
  }
  const completionPercentage = fetchOrientationInfoResponse?.data?.response !== null ? fetchOrientationInfoResponse?.data?.response?.form_completion_percentage : 0
  return (
    <>
      {backgroundBlur(completionPercentage, formStage)}
      <Form>
        {fetchOrientationInfoResponse?.data?.response && !fetchOrientationInfoResponse?.data?.response?.completed_steps?.length && formStage !== "0" ? (
          <GettingStarted setFormStage={setFormStage} setIsGoBack={setIsGoBack} />
        ) : (
          <>
            {fetchOrientationInfoResponse?.data?.response
              ? <FormStageToShow stage={formStage} response={fetchOrientationInfoResponse?.data?.response} />
              : null
            }
          </>
        )}
      </Form>
    </>
  )
}

export default OnboardingContainer(FormUI)

