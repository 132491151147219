import React from "react"
import Box from "@ui/BuilderComponents/BoxV2/index"
import styled from "styled-components"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import BlankImage from "images/Graphics/TeamProfile/BlankImage.svg"
import TeamBannerFallback from "images/Graphics/TeamProfile/LeadState.svg"
import {
  maxLargeMobile,
  maxDesktopWidth,
} from "@consts"

const FormWrap = styled.form`
display: flex;
flex-wrap: wrap;
width: ${props => props.width || "100%"};
&>span {
  position: relative;
  bottom: 17px;
}
& label input {
  border-radius: 8px;
}
 & label span, & div label {
   color: black;
   font-family: DM Sans;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 0px;
   text-align: left;
 }
`

const FieldWrap = styled.div`
  ${props => props.hide && `
    display: none !important;
  `}

  height: ${props => props.height || "174px"};
  width: ${props => props.width || "480px"};
  background: #F9F9F7;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: center;

  ${props => props.responsive && `
    ${maxDesktopWidth(`
      width: 142px;
      height: 133px;
    `)}

    ${maxLargeMobile(`
      width: 179px;
      height: 120px;
    `)}
  `}

  ${props => props.border && `
    border: 1px solid #fff;
  `}

  & input[type="file"] {
    display: none;
  }

  ${props => props.isUploading && `
    animation: flicker 1s linear 0.2s infinite alternate;

    @keyframes flicker {
      0% {
        background: #f9f9f745;
      }
      100% {
        background: #F9F9F7;
      }
    }
  `}

  ${props => props.absolute && `
    position: absolute;
  `}

  ${props => props.bg && `
    background-image: url(${props.bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media(max-width: 767px) {
      background-size: contain;
    }
  `}
`

const Image = styled.img`
  ${props => props.width && `
    width: ${props.width};
  `}
  ${props => props.height && `
    height: ${props.height || "50%"};
  `}
  ${props => props.responsive && `
    ${maxDesktopWidth(`
      width: 142px;
      height: 133px;
    `)}

    ${maxLargeMobile(`
      width: 179px;
      height: 120px;
    `)}
  `}
`

const ImageUploadUI = ({
  handleSubmit,
  teamLogo,
  teamBanner,
  imageOne,
  imageTwo,
  imageThree,
  width,
}) => (
  <FormWrap width={width} onSubmit={handleSubmit} className="image-from-wrap">
    <FieldWrap bg={teamBanner} height="202px" width="100%">
      <Box
        height="100%"
        width="100%"
        elementPosition="relative"
        displayStyle="flex"
        justifyContent="center"
        alignItems="center"
      />
    </FieldWrap>
    <FieldWrap
      border
      width="142px"
      height="142px"
      style={{
        top: "-88px",
        left: "58px",
      }}
    >
      <Box
        height="100%"
        width="100%"
        elementPosition="relative"
        displayStyle="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image width="unset" height="100%" src={teamLogo} alt="test" />
      </Box>
    </FieldWrap>
    <Box
      displayStyle="flex"
      width="100%"
      height="max-content"
      flexGap="27px"
      margin="-50px 0px 0px 0px"
    >
      <FieldWrap responsive hide={!imageOne} height="240px" width="256px">
        <Box
          height="100%"
          width="100%"
          elementPosition="relative"
          displayStyle="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image responsive height="100%" src={imageOne} alt="test" />
        </Box>
      </FieldWrap>
      <FieldWrap responsive hide={!imageTwo} height="240px" width="256px">
        <Box
          height="100%"
          width="100%"
          elementPosition="relative"
          displayStyle="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image responsive height="100%" src={imageTwo} alt="test" />
        </Box>
      </FieldWrap>
      <FieldWrap responsive hide={!imageThree} height="240px" width="256px">
        <Box
          height="100%"
          width="100%"
          elementPosition="relative"
          displayStyle="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image responsive height="100%" src={imageThree} alt="test" />
        </Box>
      </FieldWrap>
    </Box>
  </FormWrap>
)

const getImagePath = (img) => {
  if (!img || img === undefined || img === null || img === "undefined") return false

  return img.path
}

const ImageBlock = ({
  currentBanner,
  currentTeamLogo,
  otherImages,
  width,
}) => (
  <Box
    displayStyle="block"
    padding="40px 0px"
    paddingLargeDesktop="40px 0px"
    paddingMinDesktop="40px 0px"
    paddingTablet="0px 0px"
    paddingMobile="0px 0px"
    height="100%"
  >
    <Box
      displayStyle="flex"
      margin="20px 0px"
      width="100%"
    >
      <ImageUploadUI
        width={width}
        teamLogo={currentTeamLogo || BlankImage}
        teamBanner={currentBanner && getImagePath(currentBanner) || TeamBannerFallback}
        imageOne={otherImages && getImagePath(otherImages[0])}
        imageTwo={otherImages && getImagePath(otherImages[1])}
        imageThree={otherImages && getImagePath(otherImages[2])}
      />
    </Box>
  </Box>
)

export default TeamProfileContainer(ImageBlock)
