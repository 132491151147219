import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import CashOffers from "../Icons/NewIcons/CashOffers"

const CashOffersTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    pt="3px"
    isActive={routeArray[1] === "financing" || routeArray[1] === "cash_offers"}
  >
    <div className="icon-block-new">
      <CashOffers />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? "/realty/financing" : "/realty/promos?type=cash_offers"}
        isActive={routeArray[1] === "financing" || routeArray[1] === "cash_offers"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_financingRAR_web")
          }
        }}
      >
        Cash Offers
      </NavLink>
    </div>
  </InnerTab>
)

export default CashOffersTab

