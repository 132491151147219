import React from "react"
import styled from "styled-components"
import colors from "@colors"
import Button from "@ui/Button"
import EnvelopeIcon from "images/icons/MailIcon.png"
import root from "window-or-global"
import InviteContainer from "container/App/inviteFeatureContainer"

const Wrap = styled.div`
  height: max-content;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #4E26BE;
  background: ${colors.fadedBlueSea};
`

const IconWrap = styled.div``

const Envelope = styled.img`
  height: 28px;
`

const TextWrap = styled.div`
  margin-top: 11px;
`

const Description = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  span {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.deepPurple};
  }
`

const InviteButton = styled(Button)`
  background: transparent;
  cursor: pointer;
  border: none;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 0px;
  color: ${colors.black};

  span {
    position: relative;
    top: 2px;
    left: 2px;
    font-size: 16px;
  }
`

const InviteSidebarBlock = ({
  toggleInvitePopUp,
  setCurrentInviteType,
  toggleSuccessModalUI,
  isSubscribedToBrokerPlan,
  citiesServed,
}) => {
  const isCalifornia = citiesServed && citiesServed.length > 0 && citiesServed.filter(obj => obj.state === "California")
  const isColorado = citiesServed && citiesServed.length > 0 && citiesServed.filter(obj => obj.state === "Colorado")
  const showBrokerageContent = isSubscribedToBrokerPlan ||
    (isCalifornia && isCalifornia.length > 0) ||
    (isColorado && isColorado.length > 0)

  return (
    <Wrap>
      <IconWrap>
        <Envelope src={EnvelopeIcon} />
      </IconWrap>
      <TextWrap>
        {showBrokerageContent ? (
          <Description>
            Earn
            {" "}
            <span>cash rewards</span>
            {" "}
            when your friends join our brokerage
          </Description>
        ) : (
          <Description>
            Build the community by inviting your friends.
          </Description>
        )}
        <InviteButton
          onClick={() => {
            if (root.heap) root.heap.track("Click_Sidebar_Invite_CTA")
            setCurrentInviteType(showBrokerageContent ? "brokerage" : "community")
            toggleInvitePopUp(true)
            toggleSuccessModalUI(false)
          }}
        >
          Invite your friends
          {" "}
          <span>
            &gt;
          </span>
        </InviteButton>
      </TextWrap>
    </Wrap>
  )
}

export default InviteContainer(InviteSidebarBlock)
