import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Rooms from "../Icons/NewIcons/Rooms"

const RoomsTab = ({
  routeArray,
}) => (
  <InnerTab
    isActive={routeArray.includes("rooms")}
  >
    <div className="icon-block-new">
      <Rooms />
    </div>
    <div className="text-block">
      <NavLink
        to="/rooms"
        isActive={routeArray.includes("rooms")}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_Rooms_web")
          }
        }}
        target="_blank"
      >
        Rooms
      </NavLink>
    </div>
  </InnerTab>
)

export default RoomsTab