import React from "react";
import OffersV2 from "container/OffersV2";
import {
  Wrap,
  StyledCheckbox,
  AttachmentText,
  IncompleteCount,
} from "./styles";
import DynamicMenu from "../DynamicMenu";

const CheckboxListV2 = ({
  list,
  onClick,
  mainList,
  dropdownList,
  currentSearchText,
  customFilterText,
  hideCheckBox,
  img,
  handleSelectedCheck = () => {},
  isActiveFilter,
}) => {
  const currentText = currentSearchText || customFilterText;
  return (
    <>
      {list &&
        list
          .filter((item) => {
            if (currentText === "" || !currentText) {
              return item;
              // eslint-disable-next-line no-else-return
            } else if (
              item.name.toLowerCase().includes(currentText.toLowerCase())
            ) {
              return item;
            }
          })
          .map((item) => (
            <>
              {isActiveFilter ? (
                <>
                  {item.status === "active" && (
                    <Wrap>
                      {!hideCheckBox ? (
                        <StyledCheckbox
                          checked={item.isSelected}
                          value={item.id}
                          onChange={(e) => handleSelectedCheck(e, item.id)}
                        >
                          {item.name.replace(/_/g, " ")} &nbsp;
                          
                        </StyledCheckbox>
                      ) : (
                        <AttachmentText>
                          {img && <img src={img} alt="paperclip" />}
                          <p>{item.name}</p>
                        </AttachmentText>
                      )}
                      <div style={{display: "flex", alignItems: "center"}}>
                      {item?.incomplete_count ? (
                          <>
                            <IncompleteCount>
                              {item.incomplete_count}
                            </IncompleteCount>
                          </>
                        ) : null}
                      <DynamicMenu
                        dropdownList={dropdownList}
                        mainList={mainList}
                        id={
                          item.envelope_id ||
                          item.id ||
                          item.template_id ||
                          item.url
                        }
                      />
                      </div>
                    </Wrap>
                  )}
                </>
              ) : (
                <Wrap>
                  {!hideCheckBox ? (
                    <StyledCheckbox
                      checked={item.isSelected}
                      value={item.id}
                      onChange={(e) => handleSelectedCheck(e, item.id)}
                    >
                      {item.name}
                    </StyledCheckbox>
                  ) : (
                    <AttachmentText>
                      {img && <img src={img} alt="paperclip" />}
                      <p>{item.name}</p>
                    </AttachmentText>
                  )}
                  <DynamicMenu
                    dropdownList={dropdownList}
                    mainList={mainList}
                    id={
                      item.envelope_id ||
                      item.id ||
                      item.template_id ||
                      item.url
                    }
                  />
                </Wrap>
              )}
            </>
          ))}
    </>
  );
};

export default OffersV2(CheckboxListV2);
