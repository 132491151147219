import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import LoaderButton from "@ui/LoaderButton"
import TCContainer from "container/Realty/TransactionFormContainer"
import TC_PaymentGIF from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/images/tc_payment.gif"

const Wrap = styled.div`
  padding: 60px 90px 20px 90px;
`

const Image = styled.img`
  width: 246.27px;
`
const Content = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #F9F9F7;
`
const ButtonWrap = styled.div`
  margin: 35px 0 40px 0;
`
const NotNowButton = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #F9F9F7;
  margin-top: 20px;
  cursor: pointer;
`

class FinishModal extends PureComponent {
  notNow = () => {
    const { toggleCommissionsPopup } = this.props
    toggleCommissionsPopup(5)
  }

  submitTransaction = () => {
    const { history, toggleCommissionsPopup } = this.props
    history.push("/realty/transaction-coordination")
    toggleCommissionsPopup(5)
  }

  render() {
    const animation = ENV === "staging" ? TC_PaymentGIF : "https://www.radiusagent.com/dist/images/tc_payment.gif"
    return (
      <Wrap>
        <Image src={animation} />
        <Content>
          Great work. Now let’s start closing deals. Good luck!
        </Content>
        <ButtonWrap>
          <LoaderButton
            bsStyle="white"
            padding="16px"
            width="95%"
            borderRadius="50px"
            loaderOption={{
              color: "#3B60E4",
            }}
            isShowLoader={false}
            onClick={() => { this.submitTransaction() }}
          >
            Submit a Transaction
          </LoaderButton>
          <NotNowButton onClick={this.notNow}>
            Not now, thanks
          </NotNowButton>
        </ButtonWrap>
      </Wrap>
    )
  }
}

export default TCContainer(withRouter(FinishModal))

