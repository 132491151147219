import React from "react"
import OffersV2 from "container/OffersV2/index"
import { ColorIcon } from "dumbComponents/OffersV3/icons"
import {
  StyledSelect,
  Wrap,
  ColorWrap,
} from "./styles"

const OfferStatusSelect = ({
  handleChange,
  getOfferStatusMappingsResponse,
  getOfferStatusResponse,
}) => {
  const {
    data: mappings,
    isFetching: fetchingMappings,
  } = getOfferStatusMappingsResponse || {}

  const {
    data,
    isFetching: fetchingStatus,
  } = getOfferStatusResponse || {}

  const { offerStatus } = data || {}

  const getOptions = () => {
    if (mappings) {
      let altered = [...mappings]
      altered.map((item) => {
        const curr = item
        if (curr.value === "new_offer") {
          curr.label = "New Offer"
        } else {
          curr.label = item.display_name
        }
      })
      return altered
    }

    return []
  }

  const ColorMap = [
    {
      value: "green",
      label: "new_offer",
    },
    {
      value: "green",
      label: "incomplete",
    },
    {
      value: "yellow",
      label: "pending_signatures",
    },
    {
      value: "blue",
      label: "completed",
    },
    {
      value: "skin",
      label: "submitted",
    },
    {
      value: "red",
      label: "rejected",
    },
    {
      value: "grey",
      label: "cancelled",
    },
    {
      value: "skin",
      label: "offer_accepted",
    },
  ]

  // const getColor = () => {
  //   console.log(offerStatus, "here")
  //   const color = ColorMap.find(item => item.label === offerStatus)
  //   console.log(color.value, "here")
  //   return color.value
  // }

  console.log("getOfferStatusResponse", getOfferStatusResponse, getOfferStatusMappingsResponse)

  return (
    <>
      {(fetchingMappings || fetchingStatus) ? (
        <Wrap>
          <ColorWrap>
            <ColorIcon color="red" />
          </ColorWrap>
          <StyledSelect
            disabled
            onChange={handleChange}
            options={[
              {
                value: "pending_signature",
                label: "Pending Signature",
              },
            ]}
          />
        </Wrap>
      ) : (
        <Wrap>
          <ColorWrap>
            <ColorIcon color={ColorMap.find(item => item.label === offerStatus)?.value || "red"} />
          </ColorWrap>
          <StyledSelect
            onChange={handleChange}
            options={getOptions()}
            value={offerStatus}
          />
        </Wrap>
      )}
    </>
  )
}

export default OffersV2(OfferStatusSelect)

