/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
  AnimationWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 0 auto;
`

const ViewContractForListing = ({
  processBeforeViewingContract,
  performNecessaryActionsBeforeLoadingListingResponse,
  processBeforeViewingContractResponse,
  listingData,
  getContractByIdResponse,
  toClose,
  listingView = "",
  history,
  setCurrentListingTab,
}) => {
  const {
    isFetching: processing,
  } = processBeforeViewingContractResponse || {}

  const {
    isFetching: fetchingContractDetails,
  } = getContractByIdResponse || {}

  const {
    isFetching: processListingView,
  } = performNecessaryActionsBeforeLoadingListingResponse || {}

  const {
    dealId,
    contractId,
  } = listingData || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            {
              !contractId && (
                <AnimationWrap>
                  <img src={`${CLOUDFRONT}/LISTING_V2/Success.gif`} alt="animation_contracts_success.gif" />
                </AnimationWrap>
              )
            }
            <h1>
              {contractId ? "Listing updated!" : "Congratulations!"}
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              {contractId ? "Would you like to update your contract?" : "Would you like to add a contract now?"}
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            // isShowLoader={processListingView || processing || fetchingContractDetails}
            loaderOption={{
              color: "blue",
            }}
            onClick={toClose}
          >
            No, view all listings
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0"
            width="50%"
            borderRadius="50px"
            isShowLoader={processListingView || processing || fetchingContractDetails}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              //if listing view

              //prefetch data
              if (dealId) {
                processBeforeViewingContract({
                  dealId,
                  listingView,
                  history: listingView ? history : "",
                })
                setCurrentListingTab("contract_details")
              }

              //go to contracts page
            }}
          >
            {(processListingView || processing || fetchingContractDetails) ? "Please Wait" : contractId ? "Yes, update contract" : "Yes, add contract"}
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(TechAssets(Listings(ViewContractForListing)))

