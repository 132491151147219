import React, { useEffect } from "react"
import CRMV2Container from "container/CRM"
import Loader from "@ui/Loader"
import { NewButton } from "@ui/antd"
import { Plus as PlusIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import PropertyInfoList from "./PropertyInfoList"
import {
  ProfileDetailsContainer,
  ProfileDetailsHeader,
  ProfileDetailsTitle,
  PropertyCardsList,
} from "../../styles"

const PropertyRequirementInfo = ({
  getCrmClientResponse,
  getPropertyTypes,
  setCurrentDrawerPage,
  setClientID,
  toggleUserOverview,
  toggleAddNewLeadDrawer,
  setCurrentClientType,
  isUserOverviewDrawerOpen,
}) => {
  useEffect(() => {
    getPropertyTypes()
  }, [])
  const { data } = getCrmClientResponse || {}
  const { clientTransactions } = data || {}
  const { SELLING: sellerTransactions } = clientTransactions || {}
  const { agentInfo } = isUserOverviewDrawerOpen || {}
  return (
    <ProfileDetailsContainer>
      <ProfileDetailsHeader>
        <ProfileDetailsTitle fontSize={18}>All Properties</ProfileDetailsTitle>

        <NewButton
          type="primary"
          onClick={() => {
            setCurrentDrawerPage("add-same-lead")
            toggleAddNewLeadDrawer(true)
            toggleUserOverview({ showUserOverviewDrawer: false, agentInfo })
            setClientID({
              id: data.client_id,
              deal_id: data.id,
            })
            setCurrentClientType("seller")
          }}
          icon={<PlusIcon />}
          iconPosition="start"
        >
          Add Listing
        </NewButton>
      </ProfileDetailsHeader>

      <PropertyCardsList>
        {sellerTransactions ? (
          <>
            {sellerTransactions.map(x => (
              <PropertyInfoList data={x} />
            ))}
          </>
        ) : (
          <Loader />
        )}
      </PropertyCardsList>
    </ProfileDetailsContainer>
  )
}

export default CRMV2Container(PropertyRequirementInfo)
