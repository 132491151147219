import React from "react"
import styled from "styled-components"

const SwitchField = styled.div`
  overflow: hidden;
  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
    &:checked + label {
      background-color: #11adf3;
      color: #ffffff;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`
const SwitchTitle = styled.div`
  margin-bottom: 6px;
  color: #d4d4d4;
  font-weight: 300;
  font-size: 16px;
`

const SwitchFieldInput = styled.input``

const SwitchFieldLabel = styled.label`
  display: inline-block;
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  color: #354052;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 12px 14px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &:first-of-type {
    border-radius: 2px 0 0 2px;
  }
  &:last-of-type {
    border-radius: 0 2px 2px 0;
  }
`

const RenderButtonToggle = (props) => {
  const { label, children } = props
  return (
    <SwitchField>
      <SwitchTitle>{label}</SwitchTitle>
      {children}
    </SwitchField>
  )
}

export const ButtonToggleItem = (field) => {
  const {
    input, label, id, meta,
  } = field
  return (
    <React.Fragment>
      <input type="radio" id={id} {...input} />
      <SwitchFieldLabel htmlFor={id}>{label}</SwitchFieldLabel>
    </React.Fragment>
  )
}

export default RenderButtonToggle
