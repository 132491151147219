import React from "react"
import { withRouter } from "react-router-dom"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import { MOBILE_BREAK_POINT } from "shared/consts"
import queryString from "query-string"
import styled from "styled-components"
import Container from "container/Navbar"
import NewRadiusLogo from "images/home-v7/NewRadiusLogo.svg"
import {
  isCRS,
  searchToObject,
  checkForUserObject,
} from "services/Utils"
import colors from "@colors"
import { ATC } from "@fonts"
import PressReleaseBanner from "../Navbar/PressReleaseBanner"
import IncompleteProfileBanner from "../Navbar/IncompleteProfileBanner"

const Wrapper = styled.div`
  transition-duration: 0.5s;
  position: fixed;
  background: #fff;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 71px;
  ${props => !props.hideBorder && `
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2.5px 0px;
  `}
  ${props => props.background && `
    background: ${props.background}
  `}
  z-index: 1000;
  font-family: ${ATC};


  ${props => props.isShowInviteeBanner && `
    height: 118px;
  `}
  ${props => props.isCardInactive && `
     top: 45px;
  `}

  ${props => props.isVersionV2 && !props.isLogged && `
    box-shadow: none;
  `}

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    .container {
      flex-direction: row;

      #radius-logo {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      #MainNavigationWrap,
      #AccountNavWrap {
        display: none;
      }
    }
  }

  &.search-page {
    height: auto;

    & > .container {
      flex-direction: column;
      align-items: center;

      #MainNavigationWrap {
        display: none;
      }

      #agentSearchInput {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
`

const ContainerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding: 0px 24px;
  background: transparent;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
  }
`

const Brand = styled.div`
  width: 200px;
  cursor: pointer;

  img {
    @media(max-width: 375px) {
      height: 17px;
    }
    @media(min-width: 400px) {
      height: 20px;
    }
    @media(min-width: 426px) {
      height: 26px;
    }
  }
`

export const NavLink = styled.a`
  border-bottom: 4px solid transparent;
  padding: 5px 10px 0 10px;
  position: relative;
  height: 69px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(51, 51, 51);
  font-size: 12px;
  text-decoration: none;
  margin-top: 2px;
  color: ${props => (props.isActive ? `${colors.primaryColor}` : "")};
  border-bottom: ${props => (props.isActive ? `4px solid ${colors.primaryColor}` : "")};

  > p {
    margin: 0px;
    padding: 0px;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.primaryColor};
    border-bottom: 4px solid ${colors.primaryColor};
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    svg path {
      fill: #11adf3;
    }
  }
`

const RadiusLogoWrap = styled.div`
  width: 160px;
  position: fixed;
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
  img {
    max-height: 38px;
    position: relative;
    cursor: pointer;

    ${props => props.isBannerOn && `
      top: 56px;
    `}
  }
`

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogged: true,
    }
  }

  componentDidMount() {
    const {
      fetchSubscriptions,
      fetchPricingPlans,
    } = this.props
    const query = searchToObject()
    const screenWidth = root.innerWidth
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    this.searchQuery = query
    this.isBrowserVal = true
    const initState = {
      isLogged: checkForUserObject("secureId"),
      inviteCode: cookie.load("inviteCode"),
      isClient: true,
      isMobile: screenWidth < 940,
      isCrs: isCRS(),
    }
    this.setState({
      ...initState,
      isIosBrowser: parsedQuery && parsedQuery.platform === "ios"
    })
    fetchSubscriptions()
    fetchPricingPlans()
    //togglePressReleaseBanner({ isOpen: true })
  }

  render() {
    const {
      isLogged,
      isMobile,
      isIosBrowser,
    } = this.state
    const {
      history: {
        push,
      },
      isShowInviteeBanner,
      isOpenPressReleaseBanner,
      isIncompleteProfileBannerVisible,
      toggleIncompleteProfileBanner,
      isBroker,
      headerVersion,
      hideBorder,
      background,
    } = this.props
    // Location will be only available on server not on client.
    const isVersionV2 = headerVersion === "v2" || true

    const isSearchPage = root.location && root.location.pathname === "/search"

    return (
      <Wrapper
        hideBorder={hideBorder}
        background={background}
        className={isMobile && isSearchPage ? "search-page main-navbar" : "main-navbar"}
        isShowInviteeBanner={isShowInviteeBanner}
        isVersionV2={isVersionV2}
        isLogged={isLogged}
      >
        <ContainerWrap>
          {!isVersionV2 && (
            <Brand
              onClick={() => {
                if (!isIosBrowser) {
                  if (isLogged) {
                    push(isBroker ? "/realty/dashboard" : "/community")
                  } else {
                    push("/")
                  }
                }
              }}
            >
              <div>
                <img
                  src={NewRadiusLogo}
                  alt="radius logo666"
                />
              </div>
              {/* {!isLogged && (
                <SignUpMobileOnly href="/sign-up">Sign Up</SignUpMobileOnly>
              )} */}
            </Brand>
          )}
          {isVersionV2 && (
            <RadiusLogoWrap
              isBannerOn={isOpenPressReleaseBanner}
              onClick={() => {
                if (!isIosBrowser) {
                  if (isLogged) {
                    push(isBroker ? "/realty/dashboard" : "/community")
                  } else {
                    push("/")
                  }
                }
              }}
            >
              <img
                src={NewRadiusLogo}
                alt=""
                height={38}
                width={176}
              />
            </RadiusLogoWrap>
          )}
        </ContainerWrap>
        {false && isLogged && isIncompleteProfileBannerVisible && (
          <IncompleteProfileBanner
            toggleIncompleteProfileBanner={toggleIncompleteProfileBanner}
          />
        )}
      </Wrapper>
    )
  }
}

export default withRouter(Container(Navbar))
