import styled from "styled-components";
import colors from "@colors";
import { MONTSERRAT, INTER } from "@fonts";

const CardsWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .ant-card {
    border: 1px solid ${colors.new_border_outline};
  }
`;
const CarouselWrap = styled.div`
  max-width: 2000px;
`;

const DateCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 48px;
  .ant-btn {
    background-color: #5a5ff2;
    height: 48px;
    border-radius: 32px;
    width: 100%;
    color: white;
  }

  .ant-btn:hover {
    background-color: #5a5ff2;
  }

  .ant-btn::selection {
    background-color: #5a5ff2;
    color: white;
  }

  .ant-card-body {
    padding-top: 15px;
    padding-bottom: 5px;
  }

  .slick-next::before {
    color: #545487 !important;
  }

  .slick-prev::before {
    color: #545487 !important;
  }

  .slick-prev {
    margin-left: 5px;
    @media (max-width: 1024px) {
      margin-right: 20px;
      margin-left: 0;
    }
  }

  .slick-next {
    margin-left: 10px;
    @media (max-width: 1024px) {
      margin-right: 0;
      margin-left: 40px;
    }
  }

  .slick-list {
    width: 1300px !important;
  }

  .slick-slider {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ant-btn:hover {
    color: white;
  }

  .ant-card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 120px;
    margin: 0 5px;
    border-radius: 10px;
    color: #232338;
    @media (max-width: 1024px) {
      width: 112px;
      height: 118px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .ant-select {
    margin: 20px 0px;
    border: 1px solid #c1c1d7;
    border-radius: 10px;
    @media (max-width: 925px) {
      width: 100% !important;
    }

    @media (max-width: 655px) {
      width: 100% !important;
    }
  }

  .ant-btn {
    @media (max-width: 1024px) {
      width: 320px !important;
    }
  }
`;

const WeekDay = styled.p`
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
`;

const CardDate = styled.p`
  font-size: 28px;
`;

const Month = styled.p`
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
`;

export {
  CardsWrapper,
  CarouselWrap,
  DateCardWrapper,
  WeekDay,
  CardDate,
  Month,
};
