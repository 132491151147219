import React from "react"
import root from "window-or-global"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import { Route, withRouter } from "react-router-dom"
import { authCheck } from "services/auth"
import Navbar from "dumbComponents/common/Navbar"
import container from "container/App/headerLayoutContainer"
import ScrollToTop from "@ui/ScrollToTop"
import { UTMExtract } from "services/Utils"

const LayoutWrap = styled.div`
`

const handleLogout = () => {
  // this will failed on SSR
  try {
    root.location.href = `${WEB_URL}login`
  } catch (e) {
    // ignore error
  }
}

const Layout = ({ children, globalPaddingTop }) => {
  return (
    <LayoutWrap
      style={{ paddingTop: globalPaddingTop }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ScrollToTop />
      <div>
        {children}
      </div>
    </LayoutWrap>
  )
}

const OnboardingRoute = ({
  component: Component,
  location,
  requireAuth,
  isOnlyPublic,
  globalPaddingTop,
  history,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const isLogged = authCheck()
      if (requireAuth) {
        if (isLogged) {
          return (
            <Component {...props} />
          )
        }
        handleLogout()
      }
      if (isOnlyPublic && isLogged) {
        const redirectParams = UTMExtract()
        if (redirectParams) {
          history.push({
            pathname: "/community/",
            search: redirectParams,
          })
        } else {
          history.push("/community")
        }
      }
      return (
        <Layout
          style={{ paddingTop: globalPaddingTop }}
          location={location}
          globalPaddingTop={globalPaddingTop}
        >
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

export default container(withRouter(OnboardingRoute))
