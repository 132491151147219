import { useEffect } from "react"
import { checkForUserObject } from "services/Utils"
import { get } from "lodash"

const user = checkForUserObject("user")

export const FetchFileIfIDExists = (id, currentDeal, fetchTCFiles) => {
  useEffect(() => {
    if (id && (currentDeal === null
     || currentDeal === undefined
     || currentDeal === "undefined"
     || currentDeal.length === 0)) {
      const agentId = get(user, "agent_id") || get(user, "id")
      fetchTCFiles({
        skip: 0,
        limit: 100,
        agentId,
        ignoreReset: true,
      })
    }
  }, [])
}

export const getIsEdit = (deal) => {
  if (deal) {
    if (deal.step === "closed" || deal.step === "commission_verified" || deal.step === "tc_verified") {
      return true
    }
  }
  return false
}
