import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  URLBasedHubspotTrackingAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { realty } = state
  return {
    ...realty,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  URLBasedHubspotTracking: URLBasedHubspotTrackingAction.call,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
