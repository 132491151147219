import React from "react";

import {
  Icon,
  InfoWrapper,
  GetQualified,
  MortgageValue,
  EstimateHeading,
  Entity,
  Number,
  Information,
  Address,
  MortgageWrapper,
  ListingPrice,
  Header,
  ListingWrapper,
  StyledImage,
  InformationInnerWrap,
} from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const ListingInfo = ({ listingPageResponse }) => {
  const livingAreaUnits = listingPageResponse?.data?.LivingArea && (listingPageResponse?.data?.LivingAreaUnits == "Square Feet" ? 'sqft.' : listingPageResponse?.data?.LivingAreaUnits)
  return (
    <>
      {listingPageResponse?.data && (
        <ListingWrapper>
          <InfoWrapper
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            {listingPageResponse?.data?.ListPrice && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ListingPrice>
                  $
                  {parseFloat(
                    listingPageResponse?.data?.ListPrice
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                  })}
                </ListingPrice>
                {listingPageResponse?.data?.UnparsedAddress && (
                  <Address>
                    {listingPageResponse?.data?.UnparsedAddress},{" "}
                    {listingPageResponse?.data?.PostalCode}
                  </Address>
                )}
              </div>
            )}
            <Information>
              {listingPageResponse?.data?.BedroomsTotal && (
                <>
                  <InformationInnerWrap>
                    <Icon>
                      <StyledImage
                        src={`${CLOUDFRONT}/SSRListing/bedroom.svg`}
                        alt="Bed"
                      />
                    </Icon>
                    <Number>{listingPageResponse?.data?.BedroomsTotal}</Number>
                    <Entity>beds</Entity>
                  </InformationInnerWrap>
                </>
              )}

              {listingPageResponse?.data?.BathroomsFull && (
                <>
                  <InformationInnerWrap>
                    <Icon>
                      <StyledImage
                        src={`${CLOUDFRONT}/SSRListing/Bathroom.svg`}
                        alt="Bath"
                      />
                    </Icon>
                    <Number>{listingPageResponse?.data?.BathroomsFull}</Number>
                    <Entity>baths</Entity>
                  </InformationInnerWrap>
                </>
              )}

              {listingPageResponse?.data?.LivingArea && (
                <>
                  <InformationInnerWrap>
                    <Icon>
                      <StyledImage
                        src={`${CLOUDFRONT}/SSRListing/area.svg`}
                        alt="Area"
                      />
                    </Icon>
                    <Number>{listingPageResponse?.data?.LivingArea}</Number>
                    <Entity>
                      {livingAreaUnits}
                    </Entity>
                  </InformationInnerWrap>
                </>
              )}
            </Information>
          </InfoWrapper>
        </ListingWrapper>
      )}
    </>
  );
};

export default ListingPageContainer(ListingInfo);
