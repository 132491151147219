import React from "react"
import root from "window-or-global"
import { withRouter } from "react-router-dom"

class ScrollToTop extends React.PureComponent {
  state = {}

  componentDidMount() {
    root.scrollTo(0, 0)
  }

  componentWillUpdate(props) {
    const { location: { pathname: newPathname } } = props
    const { location: { pathname: oldPathname } } = this.props
    if (oldPathname !== newPathname) {
      root.scrollTo(0, 0)
    }
  }

  render() {
    // const {  } = this.state
    // const {  } = this.props
    return null
  }
}

export default withRouter(ScrollToTop)
