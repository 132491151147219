import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import root from "window-or-global"
import appContainer from "container/App"
import CloseButton from "images/icons/CloseSvg.svg"

const Wrap = styled.div`
  min-height: 150px;
  height: max-content;
  width: 645px;
  border-radius: 16px;
  padding: 32px 32px 24px 32px;
  background: ${colors.fadedBlueSea};
  border: 2px solid ${colors.deepPurple};

  ${props => props.successfullySubmitted && `
    min-height: unset;
  `}

  transition: min-height 1s forwards;
`

const H1 = styled.p`
 font-size: 25px;
`

const ReloadButton = styled.button`
 float: right;
 padding: 8px;
 border: none;
 border-radius: 14px;
 background: ${colors.deepPurple};
 color: white;
`

const ErrorModal = ({
  ...props
}) => {
  const {
    toggleErrorModal,
    redirectTo,
    history,
    customErrorMessage,
  } = props

  return (
    <Modal
      show
      toClose={() => { toggleErrorModal(false) }}
      width="800px"
      overlayColor="#000000b3"
      modalStyles={{
        padding: 0,
      }}
      customDialogColor="transparent"
      dialogStyles={{
        background: "transparent",
        overflow: "hidden",
        position: "relative",
        top: "31px",
      }}
      closeIcon={CloseButton}
      showCloseButton
      iconStyles={{
        position: "absolute",
        width: "19px",
        right: "172px",
        cursor: "pointer",
        top: "18px",
      }}
      modalVersion={2}
    >
      <Wrap>
        <H1>Oops! Something went wrong..</H1>
        {customErrorMessage && (
          <p>{customErrorMessage}</p>
        )}
        <ReloadButton
          onClick={() => {
            if (redirectTo) {
              toggleErrorModal(false)
              history.push(redirectTo)
            } else {
              root.location.reload(false)
            }
          }}
        >
          Reload the Page
        </ReloadButton>
      </Wrap>
    </Modal>
  )
}

export default appContainer(ErrorModal)
