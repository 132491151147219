import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import root from "window-or-global"
import { createLogger } from "redux-logger"
import { routerMiddleware } from "react-router-redux"
import sagas from "./rootSaga"
import rootReducer from "./rootReducer"

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "referral.isaPricing",
    "referral.pricingPlansMap",
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let reduxDevTools = false
const router = routerMiddleware()
const sagaMiddleware = createSagaMiddleware()

const middlewares = [router, sagaMiddleware]

if (root && process.env.NODE_ENV !== "production") {
  reduxDevTools = root.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ //eslint-disable-line no-underscore-dangle
  middlewares.push(createLogger({
    level: "info",
    collapsed: true,
  }))
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = reduxDevTools || compose
const enhancer = applyMiddleware(...middlewares)

// // eslint-disable-next-line no-underscore-dangle
// const preloadedState = root.__STATE__ ? root.__STATE__ : {}
// // eslint-disable-next-line no-underscore-dangle
// delete root.__STATE__

function configureStore() {
  const storeIni = createStore(persistedReducer, {}, composeEnhancers(enhancer))
  sagaMiddleware.run(sagas)
  return storeIni
}

export const store = configureStore()

export const dispatch = (props) => { store.dispatch(props) }

export const persistor = persistStore(store)
