import React from "react"
import { Form, GoogleAutoComplete } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { get } from "lodash"
import {
  InputFieldWrap,
  StyledGoogleAutoComplete,
} from "./styles"

const InputField = ({
  placeholder = "",
  type,
  name,
  required = false,
  style,
  customRules = [],
  inputType,
  onChange,
  locationChange,
  formRef,
  fieldIndex,
  formName,
  text = "",
  getInitalPropertyValues,
}) => {
  const getRules = () => {
    const rules = []
    if (required) {
      rules.push({
        required: true,
        message: "Please enter a value",
      })
    }
    if (type === "email") {
      rules.push({ type: "email" })
    }
    if (type === "custom") {
      rules.push(...customRules)
    }
    return rules
  }

  const setStreet = (location, formRef, key) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      zipCode: get(location, "postal_code.long_name"),
      streetNumber: get(location, "street_number.long_name"),
      county: get(location, "administrative_area_level_2.long_name"),
      route: get(location, "route.long_name"),
    }


    const addressString = locationDetails.streetNumber
      && locationDetails
        .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null

    if (fieldIndex >= 0) {
      const fields = formRef.current.getFieldsValue()
      const currentFields = fields[formName]
      Object.assign(currentFields[fieldIndex], {
        [key[key.length - 1]]: addressString || locationDetails.address,
        city: locationDetails.city,
        state: locationDetails.state,
        zipcode: locationDetails.zipCode,
        county: `${locationDetails.county}`,
      })
      formRef.current.setFieldsValue({
        [formName]: currentFields,
      })

      console.log(fields)

    } else {
      formRef.current.setFieldsValue({
        [key]: addressString || locationDetails.address,
        city: locationDetails.city,
        state: locationDetails.state,
        zipcode: locationDetails.zipCode,
        county: `${locationDetails.county}`,
      })

      if (getInitalPropertyValues && typeof getInitalPropertyValues === "function") {
        getInitalPropertyValues({
          [key]: addressString || locationDetails.address,
          city: locationDetails.city,
          state: locationDetails.state,
          zipcode: locationDetails.zipCode,
          county: `${locationDetails.county}`,
        })
      }
    }
  }

  const requiredStyles = required
  ? {
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 12px center",
      backgroundSize: "20px",
      transitionProperty: "background, background-image, background-repeat, background-position, background-size",
      transitionDuration: "0s",
    }
  : {};

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={text} />}
    >
      <GoogleAutoComplete
        id="cities"
        types={["address"]}
        name="cities"
        onChange={(e) => { }}
        location={(loc) => { setStreet(loc, formRef, name) }}
        placeholder={placeholder}
        styles={{
          borderRadius: "8px !important",
          border: "1px solid var(--Color-Primary-Primary, #898989) !important",
          padding: "12px 16px !important",
          height: "42px !important",
          ...requiredStyles,
        }}
      />
    </Form.Item>
  )
}

export default InputField
