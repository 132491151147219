import React, { useState, useMemo } from "react"
import uuid from "uuid"
import {
  ImageUploadWrap,
  UploadImageDisclaimer,
  AvatarWrap,
  ImageStyledWrap,
  FlexWrap,
} from "./styles"
import AvatarUploadAndCrop from "./AvatarUploadAndCrop"

const UploadImage = ({
  image,
  postCrop,
  index,
  deleteImage,
  id,
  postUploadFunction,
  showFallback = true,
}) => {
  const [currImage, setCurrImage] = useState("")
  const fallback = `${CLOUDFRONT}/LISTING_V2/GenericImageAvatar.png`

  const uploadImage = async (dataImage) => {
    try {
      const currentImage = dataImage.file
      const signedRes = await fetch(`${FILE_UPLOAD_API_V2}filename=${currentImage.name}`)
      const signedResJson = await signedRes.json()
      const myHeaders = new Headers({ "Content-Type": currentImage.type })

      const res = await fetch(signedResJson.url, {
        method: "PUT",
        headers: myHeaders,
        body: currentImage,
      })

      if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
        dataImage.onSuccess()
        if (postUploadFunction && typeof postUploadFunction === "function") {
          postUploadFunction(signedResJson.fileUrlAfterUpload)
          setCurrImage(signedResJson.fileUrlAfterUpload)
        }
      }
    } catch (e) {
      console.log(e)
      dataImage.onError()
    }
  }

  const getDataList = () => {
    const uid = uuid.v4()

    if (image) {
      const split = image.split("/")
      const name = split && split[split.length - 1]
      const payload = {
        uid,
        name,
        status: "done",
        url: image,
      }

      return [payload]
    }

    if (currImage) {
      const payload = {
        uid,
        name: "Seller Avatar",
        status: "done",
        url: currImage,
      }

      return [payload]
    }

    if (showFallback && fallback) {
      const payload = {
        uid,
        name: "Seller Avatar",
        status: "done",
        url: fallback,
      }

      return [payload]
    }

    return []
  }

  return (
    <ImageUploadWrap
      key={id}
      id={id}
    >
      <ImageStyledWrap
        key={`${id}_img_wrap`}
      >
        <AvatarWrap
          key={`${id}_img_avatar`}
        >
          <AvatarUploadAndCrop
            uploadImage={uploadImage}
            handlePostCrop={() => {}}
            dataList={useMemo(() => getDataList(), [image, currImage])}
          />
        </AvatarWrap>
      </ImageStyledWrap>
      <ImageStyledWrap
        key={`${id}_img_second_wrap`}
      >
        <FlexWrap
          key={`${id}_flex_wrap`}
        >
          <UploadImageDisclaimer
            key={`${id}_flex_wrap_disclaimer`}
          >
            <p>Please upload image in jpeg/png.</p>
          </UploadImageDisclaimer>
        </FlexWrap>
      </ImageStyledWrap>
    </ImageUploadWrap>
  )
}

export default UploadImage
