import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import LeftArrow from "images/rooms/icons/leftArrow_33.svg"
import CRMV2Container from "container/CRM"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import colors from "@colors"
import { get } from "lodash"
import { formatAmount } from "services/formUtils"
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Radio,
  GoogleAutoComplete,
  Upload,
  InputNumber,
} from "@ui/antd"
import {
  LabelComponent,
  RadioButton,
  TransAndBedWrap,
  TransactionTypeWrap,
  H2,
} from "../../../common"
import { ATC } from "@fonts"

const { Item } = Form
const { Option } = Select
const { Group } = Radio

const Wrap = styled.div`
  padding: 20px;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    font-family: ${ATC};
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const CloseIconWrap = styled.div`
  cursor: pointer; 
`

const Header = styled.h1`
  font-family: ${ATC};
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`

const FormWrap = styled.div``

const AddSearchCriteria = ({
  handleClose,
  uploadImage,
  isUploadingFile,
  getPropertyTypes,
  getPropertyTypeResponse,
  addSearchCriteria,
  currentClientTransactionID,
}) => {
  useEffect(() => {
    getPropertyTypes()
  }, [])

  const { data: propertyTypes } = getPropertyTypeResponse || {}
  const { types } = propertyTypes || {}

  const [count, setCount] = useState(0)
  const [fileList, setFileList] = useState([])
  const [transactionType, setTransactionType] = useState("")

  const formRef = useRef(null)

  const [metric, setMetric] = useState("")
  const onMetricChange = (value) => {
    setMetric(value)
    formRef.current.setFieldsValue({ metric: value })
  }

  const onFinish = (values) => {
    const payload = {
      ...values,
    }
    addSearchCriteria({
      finalSearchCriteriaPayload: payload,
    })
  }

  const validateMessages = () => {

  }

  const setStreet = (location) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      streetNumber: get(location, "street_number.long_name"),
      route: get(location, "route.long_name"),
    }

    const addressString = locationDetails.streetNumber
    && locationDetails
      .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null
    
    formRef.current.setFieldsValue({
      locations: addressString || locationDetails.address,
    })
  }

  const onTransactionTypeChange = () => {

  }

  return (
    <Wrap>
      <HeaderWrap>
        <CloseWrap
          onClick={() => handleClose()}
        >
          <img src={LeftArrow} alt="Left Arrow" height={15} />
          <p>
            Back to CRM
          </p>
        </CloseWrap>
        <CloseIconWrap
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseIconWrap>
      </HeaderWrap>
      <FormWrap>
        <Form
          name="add_search_criteria"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          validateMessages={validateMessages}
          ref={formRef}
        >
          <Header>Add Search</Header>
          <TransAndBedWrap>
            <TransactionTypeWrap>
              <H2>
                Transaction Type
              </H2>
              <Item name="sale_type" rules={[{ required: true }]}>
                <Group onChange={onTransactionTypeChange} value={transactionType}>
                  <Space
                    style={{
                      display: "flex", marginBottom: 8, marginTop: 20,
                    }}
                    align="baseline"
                    direction="horizontal"
                  >
                    <RadioButton value="resale">Sale</RadioButton>
                    <RadioButton value="rent">Rent</RadioButton>
                  </Space>
                </Group>
              </Item>
            </TransactionTypeWrap>
          </TransAndBedWrap>
          <Space style={{ display: "flex", marginBottom: 8, width: 300 }} align="baseline">
            <Item
              name="bedroom"
              rules={[{ required: true, message: "Missing", type: "number" }]}
              label={<LabelComponent text="Bed" />}
            >
              <InputNumber />
            </Item>
            <Item
              name="bathroom"
              rules={[{ required: true, message: "Missing", type: "number" }]}
              label={<LabelComponent text="Bath" />}
            >
              <InputNumber />
            </Item>
          </Space>
          <Space style={{ display: "flex", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item
              name="min_budget"
              label={<LabelComponent text="Min Budget" />}
              rules={[{ required: true, pattern: new RegExp(/^[$-,-0-9]+$/), message: "Only numbers allowed" }]}
              normalize={formatAmount}
            >
              <Input />
            </Item>
            <Item
              name="max_budget"
              rules={[{ required: true, pattern: new RegExp(/^[$-,-0-9]+$/), message: "Only numbers allowed" }]}
              label={<LabelComponent text="Max Budget" />}
              normalize={formatAmount}
            >
              <Input />
            </Item>
          </Space>
          <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
            <Item
              name="min_size"
              rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Only numbers allowed" }]}
              label={<LabelComponent text="Min Size" />}
            >
              <Input />
            </Item>
            <Item
              name="max_size"
              rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Only numbers allowed" }]}
              label={<LabelComponent text="Max Size" />}
            >
              <Input />
            </Item>
          </Space>
          <Item name="area_unit" rules={[{ required: true }]}>
            <Group onChange={onMetricChange} value={metric}>
              <Space direction="horizontal">
                <RadioButton value="Sq. ft">Sq. ft</RadioButton>
                <RadioButton value="Sq. m">Sq.mt</RadioButton>
                <RadioButton value="Acres">Acres</RadioButton>
              </Space>
            </Group>
          </Item>
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="types" label={<LabelComponent text="Property Type" />} rules={[{ required: true }]}>
              <Select placeholder="Select...">
                {types && types.map(type => (
                  <Option value={type.value}>
                    {type.value}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>
          <Space
            style={{
              display: "block",
              marginBottom: 8,
              marginTop: 20,
              width: "100%",
            }}
            align="baseline"
          >
            <Item name="locations" label={<LabelComponent text="Neighbourhood" />} rules={[{ required: true }]}>
              <GoogleAutoComplete
                id="cities"
                types={["address"]}
                name="cities"
                // formValue={cityFormValue}
                onChange={(e) => { }}
                location={(loc) => { setStreet(loc) }}
                placeholder=""
                styles={{
                  height: "49px",
                  borderRadius: "0px",
                  background: "white",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
                restrict={{
                  lat: 37.09024,
                  lng: -95.712891,
                  south_west: {
                    lat: 25.82,
                    lng: -124.39,
                  },
                  north_east: {
                    lat: 49.38,
                    lng: -66.94,
                  },
                  address: "United States",
                  country: {
                    long_name: "United States",
                    short_name: "US",
                    types: [
                      "country",
                      "political",
                    ],
                  },
                }}
              />
            </Item>
          </Space>
          <Item>
            <Button
              style={{
                background: "#3B60E4",
                float: "right",
                borderColor: "white",
              }}
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
            >
              Add Search
            </Button>
          </Item>
        </Form>
      </FormWrap>
    </Wrap>
  )
}

export default CRMV2Container(AddSearchCriteria)
