import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  brokerFormModalToggleAction,
  submitBrokerInterestAction,
  trackRecruitAndEarnAction,
  getAgentsActions,
  getTeamsActions,
  setCurrentClickedStateAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { app } = state
  const {
    isBrokerFormModalVisible,
    brokerInterestResponse,
    realtyAgentPageResponse,
    realtyTeamsPageResponse,
    currentState,
  } = app
  return {
    isBrokerFormModalVisible,
    brokerInterestResponse,
    realtyAgentPageResponse,
    realtyTeamsPageResponse,
    currentState,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleBrokerForm: brokerFormModalToggleAction.call,
      submitBrokerForm: submitBrokerInterestAction.request,
      trackRecruitAndEarn: trackRecruitAndEarnAction.request,
      getAgent: getAgentsActions.request,
      getTeams: getTeamsActions.request,
      setCurrentState: setCurrentClickedStateAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
