import React, { useState } from "react"
import { CloudUploadOutlined } from "@ant-design/icons"
import { message, Upload } from "antd"
import CookiesStorage from "services/CookieStorage"
import {
  AddButtonCTA,
  ToggleSwitch,
} from "dumbComponents/OffersV2/components"
import {
  CardV2,
  ToggleWrap,
  InformationWrap,
  GoBack,
} from "./style"
const { Dragger } = Upload

const FOR_TEAM = "team"
const FOR_MYSELF = "myself"

//OFFERS_V2/information_yellow.svg

const CustomFileUpload = ({
  uploadFunction,
  data,
  goBack,
  folderName = "s-offer-docs",

}) => {
  const [selfDocument, setSelfType] = useState(true)
  const [isUploading, setUploading] = useState(false)
  const agentId = CookiesStorage.load("agentId")


  const props = {
    name: "file",
    accept: ".pdf",
    multiple: false,
    onError: (err) => {
      console.log("onError", err)
    },
    onSuccess: (res, file) => {
      console.log(res)
      console.log(file)
      setUploading(false)

      const fileObj = {
        title: file.name,
        url: res.fileUrlAfterUpload,
        s3_path: `${folderName}/${agentId}/${file.name}`
      }

      const payload = {
        step: "add_to_library",
        is_self_document: selfDocument ? 1 : 0,
        documents: [fileObj]
      }

      uploadFunction(payload)
    },
    action: (file) => { console.log(file) },
    customRequest: async (customXHRObject) => {

      const {
        onStart,
        onSuccess,
        onProgress,
        onError,
        file,
      } = customXHRObject || {}
      try {
        console.log(file)
        setUploading(true)
        const signedRes = await fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${folderName}/${agentId}&filename=${file.name}`)
        const signedResJson = await signedRes.json()
        const myHeaders = new Headers({ "Content-Type": "application/pdf" })

        const res = await fetch(signedResJson.url, {
          method: "PUT",
          headers: myHeaders,
          body: file,
        })
        onSuccess(signedResJson, file)
      } catch (err) {
        onError(err)
      }
    },
    onChange(info) {
      const { status } = info.file
      console.log(status)
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }

  return (
    <CardV2
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "540px",
        position: "relative",
      }}
    >
      <GoBack
        onClick={goBack}
      >
        Go Back
      </GoBack>
      <ToggleWrap>
        <p>Documents for the team</p>
        <ToggleSwitch
          onChange={() => setSelfType(!selfDocument)}
          defaultChecked={selfDocument}
        />
        <p>Documents for my own use</p>
      </ToggleWrap>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">Upload documents from your computer</p>
      </Dragger>
      {!isUploading  && (
        <InformationWrap>
          <img src={`${CLOUDFRONT}/OFFERS_V2/information_yellow.svg`} alt="info" />
          <p>Accepted formats include PDF. File sizes should not exceed 5MB.</p>
        </InformationWrap>
      )}
    </CardV2>
  )
}

export default CustomFileUpload
