import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
`
const LINK = `${CLOUDFRONT}/OFFERS_V2`

export const DividerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
    <path d="M1 0V42" stroke="#A5A8FF" />
  </svg>
)

export const CheckedIcon = () => (
  <Wrap>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M14.6716 5.05859L9.00462 10.7256L6.17188 7.89209" stroke="#22B458" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4453 10.1295V13.8C15.4453 14.67 14.7403 15.375 13.8703 15.375H4.27031C3.40031 15.375 2.69531 14.67 2.69531 13.8V4.2C2.69531 3.33 3.40031 2.625 4.27031 2.625H9.07031" stroke="#22B458" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </Wrap>
)

const addIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/addIcon.svg"
const sideArrowIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/sideArrowIcon.svg"
const dropdownIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/dropdownIcon.svg"
const lightBulbIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/offers_lightBulb.svg"
const formAddIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/offers_add_icon.svg"
const dividerIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/dividerBlue.svg"
const threeDotMoreIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/threeDotMore.svg"
const eyeIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/viewIcon.svg"
const shareIcon = "https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/sendIcon.svg"
export const closeIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/close.svg"
export const checkIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/check.svg"
export const infoIcon = "https://s3.amazonaws.com/files.radiusagent.com/image/infoIcon.svg"
export const trashIcon = `${LINK}/Trash.svg`
export const blueCloseIcon = `${LINK}/Close.svg`

export const AddIcon = () => (
  <div>
    <img src={addIcon} alt="add" />
  </div>
)

export const SideArrowIcon = () => (
  <div>
    <img src={sideArrowIcon} alt="side arrow" />
  </div>
)

export const DropdownIcon = () => (
  <div>
    <img src={dropdownIcon} alt="dropdown" />
  </div>
)

export const LightBulbIcon = () => (
  <div>
    <img src={lightBulbIcon} alt="light bulb" />
  </div>
)

export const FormAddIcon = ({
  height,
  width,
}) => (
  <div>
    <img src={formAddIcon} alt="form add" height={height} width={width} />
  </div>
)

export const DivideIcon = () => (
  <div>
    <img src={dividerIcon} alt="divider icon" height={18} />
  </div>
)

export const ThreeDotMoreIcon = () => (
  <div>
    <img src={threeDotMoreIcon} alt="three dot icon" height={18} />
  </div>
)

export const EyeIcon = () => (
  <div>
    <img src={eyeIcon} alt="Eye Icon" />
  </div>
)

export const ShareIcon = () => (
  <div>
    <img src={shareIcon} alt="Share Icon" />
  </div>
)

export const TrashIcon = () => (
  <div>
    <img src={trashIcon} alt="Trash Icon" />
  </div>
)

export const CloseIcon = () => (
  <div>
    <img src={closeIcon} alt="close" />
  </div>
)

export const BlueCloseIcon = () => (
  <div>
    <img src={blueCloseIcon} alt="close" />
  </div>
)
