import React, { Component } from "react"
import styled, { css } from "styled-components"
import root from "window-or-global"
import { saveUserObjectToCookie } from "services/Utils"
import Button from "@ui/Button"
import LogoIcon from "images/icons/logo.svg"
import colors from "@colors"
import NavbarToggle from "./NavbarToggle"
import MenuDropdown from "./MenuDropdown"

const Wrapper = styled.div`
  background-color: ${colors.white};
  margin-top: -70px;
  @media (max-width: 1920px) {
   padding: 0 18.29%;
  }
  @media (max-width: 1680px) {
   padding: 0 13.8%;
  }
  @media (max-width: 1440px) {
   padding: 0 7.8%;
  }
  @media (max-width: 1280px) {
   padding: 0 32px;
  }
`

const StyledNavbar = styled.div`
  background: ${colors.white};
  border: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      top: 53px;
      left: 0;
      height: 1px;
      background-color: ${colors.borderColor};
    }
  }
`

const RightNav = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledBrand = styled.div`
  padding-top: 3px;

  @media (max-width: 500px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledLogo = styled.img`
  height: 28px;
  margin-top: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 3px;
  }

  @media (max-width: 320px) {
    padding: 5px;
  }
`

const StyledNavItem = styled.a`
  display: inline-block;
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #3c4858;
  transition: all 2s;
  padding: 10px 0;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;

  ${props => props.active && css`
    color: ${colors.primaryColor};
    z-index: 1;
    text-decoration: none;
    &:after {
      width: 100% !important;
      z-index: 1;
      opacity: 1 !important;
    }
  `}

  &:after,
  &:before {
    transition: all 0.5s;
  }

  &:hover {
    color: ${colors.primaryColor};
    z-index: 1;
    text-decoration: none;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    width: 0;
    color: transparent;
    border-radius: 25px;
    background: ${colors.primaryColor};
    height: 5px;
    text-align: left;
    margin: 0;
    opacity: 0;

    @media (max-width: 768px) {
      top: 50px;
    }
  }

  &:hover:after {
    width: 100%;
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
  }

  &:last-child {
    padding: 0;
    margin: 0 10px 0 15px;

    button {
      height: 57px;
      border-radius: 0;
    }
  }

  @media (max-width: 500px) {
   ${props => (!props.mobileOnly && !props.bothView) && `
      display: none;
    `}
  }

  @media (min-width: 500px) {
   ${props => props.mobileOnly && `
      display:none;
    `}
  }

  @media (max-width: 768px) {
    font-size: 15px;
    padding-top: 24px;
  }
`

const StyledButtonLink = styled.a`
  display: inline-block;
  padding: 0;
  margin: 0 0 0 5px;

  &:after {
    top: 60px;
    height: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    padding: 15px 0;
  }
`

const StyledButton = styled(Button)`
  height: 70px;
  border-radius: 0;
  width: 110px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  color: ${colors.white};
  // box-shadow: 24px 12px 30px 0 rgba(17, 173, 243, 0.23);
  border: solid 1.2px ${colors.primaryColor};
  background-color: ${colors.primaryColor};

  &:hover {
    color: ${colors.white};
  }

  @media (max-width: 768px) {
    height: 40px;
    border-radius: 80px;
  }
`

export default class Header extends Component {
  state = {
    isMenuOpen: true,
    isDropDownVisible: false,
    currentType: "",
  }

  toggleMenu = () => {
    const { isMenuOpen } = this.state
    this.setState({ isMenuOpen: !isMenuOpen })
  }

  toggleDropDown = (bool, type) => {
    this.setState({
      isDropDownVisible: bool,
      currentType: type,
    })
  }

  componentDidMount() {
    const activePath = root.location.pathname
    // if (activePath === "/radius-assist") saveUserObjectToCookie({ redirectUrl: `${APP_URL}/radius-assist` })
    let isMenuOpen = true
    if (window.innerWidth < 768) {
      isMenuOpen = false
    }
    this.setState({
      activePath,
      isMenuOpen,
    })
  }

  render() {
    const {
      isMenuOpen,
      isDropDownVisible,
      currentType,
      activePath,
    } = this.state

    return (
      <Wrapper>
        <StyledNavbar isMenuOpen={isMenuOpen}>
          <StyledLogoContainer>
            <StyledBrand>
              <a href="https://www.radiusagent.com">
                <StyledLogo src={LogoIcon} alt="RadiusAgent" />
              </a>
            </StyledBrand>

            <NavbarToggle onClick={this.toggleMenu} />
          </StyledLogoContainer>

          {isMenuOpen && (
            <RightNav>
              <StyledNavItem
                eventKey={1}
                href="https://blog.radiusagent.com"
                target="_blank"
                mobileOnly
              >
                Blog
              </StyledNavItem>

              <StyledNavItem
                eventKey={2}
                href="https://knowledgebase.radiusagent.com"
                mobileOnly
                target="_blank"
              >
                Knowledge Base
              </StyledNavItem>

              {/* <StyledNavItem
                active={activePath === "/isa-program" || activePath === "/radius-assist"}
                onMouseOver={() => this.toggleDropDown(true, "product")}
                onFocus={() => undefined}
              >
                Product
              </StyledNavItem> */}

              <StyledNavItem
                onMouseOver={() => this.toggleDropDown(true, "resources")}
                onFocus={() => undefined}
              >
                Resources
              </StyledNavItem>

              {/* <StyledNavItem
                href={`${WEB_URL}radius-assist`}
                mobileOnly
                active={activePath === "/isa-program" || activePath === "/radius-assist"}
              >
                Radius Assist
              </StyledNavItem> */}

              <StyledNavItem
                href={`${RADIUS_URL}/signup`}
                bothView
              >
               Log In
              </StyledNavItem>

              <a href={`${RADIUS_URL}/signup`}>
                <StyledButtonLink>
                  <StyledButton>Join now</StyledButton>
                </StyledButtonLink>
              </a>
            </RightNav>
          )}
          <MenuDropdown
            toggleDropDown={bool => this.toggleDropDown(bool)}
            isVisible={isDropDownVisible}
            currentType={currentType}
            activePath={activePath}
          />
        </StyledNavbar>
      </Wrapper>
    )
  }
}
