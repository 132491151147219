import React from "react"
import styled from "styled-components"
import Compliance from "../../../Compliance"


const ComplianceSection = () => {
  return (
    <Compliance />
  )
}

export default ComplianceSection