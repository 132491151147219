import {
  takeLatest,
  call,
  put,
  select,
  throttle,
  take,
  race,
} from "redux-saga/effects"
import queryString from "query-string"
import cookie from "services/CookieStorage"
import { getFormValues, submit, destroy, SubmissionError, change } from "redux-form"
import { get, isEmpty } from "lodash"
import root, { onemptied } from "window-or-global"
import { signCRSAgreementAction } from "container/Referral/actions"
import { getNumbers } from "services/formUtils"
import { isSuccess, checkForUserObject, isCRS, isTrueCRS, removeCommaFromNumber } from "services/Utils"
import { showPopupAction, getPopupDetailsAction } from "container/TechAssets/actions"
import { getPopupDetailsApi } from "container/TechAssets/api"
import { addBuyerNeedsAPI, saveAddListingAPI } from "container/Profile/api"
import {
  showConfirmModalAction,
  hideConfirmModalAction,
} from "container/App/actions"
import { confirmAction } from "container/App/saga"
import {
  createClientAPI,
  createLeadAPI,
  referAClientAPI,
  getClientSearchAPI,
  directReferralAPI,
  validateIfClientExistsAPI,
} from "./api"
import * as Actions from "./actions"

const isAgentSpecialFlow = (agent = {}, isOwnCRS, isOwnWCR) => {
  const { associations = [], isCrsDesignee } = agent
  const wcr = associations.filter(item => item.associationShortName === "wcr") || []
  const crs = associations.filter(item => item.associationShortName === "crs") || []
  return (wcr.length === 1 && isOwnWCR) || ((crs.length === 1 || isCrsDesignee) && isOwnCRS)
}

const getAssociationFromQuery = (associations = [], type) => {
  if (associations.length) {
    return !!associations.find(x => x.associationShortName === type).length
  }
  return null
}

// todo
function* validateBasicaInfoBeforeMovingForward(action) {
  try {
    const { emails, phones, callback } = action.data
    yield put(Actions.setBasicInfoErrorAction.request(action))
    const payload = {
      client_email: emails,
      client_phone: getNumbers(phones),
    }
    const res = yield call(validateIfClientExistsAPI, payload)
    yield put(Actions.setBasicInfoErrorAction.success(res.data))
    callback()
  } catch (error) {
    yield put(Actions.setBasicInfoErrorAction.failure(error.data.error))
    console.log(error)
  }
}

// function* marketplaceInitBasicForm(action) {
//   try {
//     const { payload } = action.data
//     initialize("SUBMIT_REFERRAL_BASIC_INFO", {
//       name: payload.client_name,
//       email: payload.client_email,
//       phone: payload.client_phone,
//     })
//   } catch (error) {
//     console.log("Error", error)
//   }
// }

const getAgentAssociation = (agent = {}, isOwnCRS, isOwnWCR) => {
  const { associations = [], isCrsDesignee } = agent
  const wcr = associations.filter(item => item.associationShortName === "wcr") || []
  const crs = associations.filter(item => item.associationShortName === "crs") || []
  if (crs.length > 0 && isCrsDesignee) {
    return "crs"
  }
  if (wcr.length > 0 && isOwnWCR) {
    return "wcr"
  }
}

function* submitReferralNextStep(action) {
  try {
    const { pathname } = root.location
    const { history } = action.data
    const submitReferral = yield select(state => state.submitReferral)
    // Handle Next Based on pathname,
    switch (pathname) {
      case "/submit/referral": {
        const { selectedReferralService } = submitReferral
        if (selectedReferralService === "myself") {
          history.push("/search")
          return
        }
        if (selectedReferralService === "help-needed") {
          history.push("/submit/referral/basic-info")
          return
        }
        return
      }
      case "/submit/referral/basic-info": {
        const basicFormValues = getFormValues("SUBMIT_REFERRAL_BASIC_INFO")(state)
        yield put(submit("SUBMIT_REFERRAL_BASIC_INFO"))
        const { type: submitType } = basicFormValues
        if (submitType === "Buyer") {
          history.push("/submit/referral/buyer-requirement?")
        }
        if (submitType === "Seller") {
          history.push("/submit/referral/seller-property")
        }
        return
      }
      case "/submit/referral/buyer-requirement": {
        const buyerRequirementForm = getFormValues("BUYER_REQUIREMENT")(state)
        return
      }
      default: {
        console.warn("no action found for path")
        return
      }
    }
  } catch (e) {
    console.warn(e)
  }
}

function* handleSubmitReferral(action) {
  try {
    const user = checkForUserObject("user")
    const redirectPath = yield select(state => state.submitReferral.redirectPath)
    const { history } = action.data
    const submitReferral = yield select(state => state.submitReferral)
    const ownIsCRS = isCRS()
    const isOwnWCR = cookie.load("isWcr")
    const {
      basicInfo,
      buyerNeeds,
      referralBackground,
      sellerRequirement,
      isPartnerFlow,
      receivingAgent,
    } = submitReferral
    const { phones, emails } = basicInfo
    history.push("/submit/referral/creating-referral")
    const timeline = get(buyerNeeds, "timeline")
    const referralType = get(basicInfo, "type")
    let sellerResponse = {}
    const buyerSellerAdditionalDetails = get(buyerNeeds, "additional_details") || get(sellerRequirement, "additional_details")
    const agent_requirements = {
      timeline,
      ...referralBackground,
      clientConnect: get(referralBackground, "clientConnect.value"),
      receiver_expertise: get(referralBackground, "receiver_expertise.value"),
      additional_notes: buyerSellerAdditionalDetails,
    }
    if (buyerNeeds.motivational_level && buyerNeeds.motivational_level.value) {
      agent_requirements.motivational_level = buyerNeeds.motivational_level.value
    }
    const requirementPayload = {
      ...buyerNeeds,
      max_budget: removeCommaFromNumber(buyerNeeds.max_budget),
      agent_id: user.agent_id,
    }
    const payload = {
      ...basicInfo,
      phones: [{
        phone: phones,
        type: "Work",
      }],
      emails: [{
        email: emails,
        type: "Work",
      }],
      sales_stage: "Leads",
      sources: ["Referral"],
      agent_requirements: JSON.stringify(agent_requirements),
      referralType: "out",
    }
    let requirementResponse = {}

    const clientRes = yield call(createClientAPI, payload)
    // Check Client Call
    if (isSuccess(clientRes)) {
      const clientResponse = clientRes.data.response
      requirementPayload.u_cust_id = clientResponse.id
      if (referralType === "Buyer" || referralType === "Renter") {
        // According to new requirement, remove additional detials from  requirement API
        delete requirementPayload.additional_details
        delete requirementPayload.motivational_level
        const requirementRes = yield call(addBuyerNeedsAPI, requirementPayload)
        // Check Requirement Call
        if (isSuccess(requirementRes)) {
          requirementResponse = requirementRes.data.response
        } else {
          throw requirementRes.data.response
        }
      }

      if (referralType === "Seller") {
        const sellerPayload = {
          ...sellerRequirement,
          property_type: get(sellerRequirement, "type.value"),
        }
        delete sellerPayload.type
        sellerPayload.u_cust_id = clientResponse.id
        sellerResponse = yield call(saveAddListingAPI, sellerPayload)
      }

      if (!isEmpty(receivingAgent) && !isPartnerFlow) {
        const directReferralPayload = {
          clientId: clientResponse.id,
          is_isa: 0,
          type: clientResponse.type,
          referred_client_status: "Submitted",
          direct_receiver_id: receivingAgent.id,
        }
        if (referralType === "Buyer" || referralType === "Renter") {
          directReferralPayload.req_id = requirementResponse.req_id
        }
        if (referralType === "Seller") {
          directReferralPayload.prop_id = get(sellerResponse, "data.response.basic_id")
        }
        yield call(directReferralAPI, directReferralPayload)
      }

      if (isPartnerFlow) {
        const referPayload = {
          days_before_referral_fee: parseInt(get(submitReferral, "commission.days_before_referral_fee"), 0),
          commission: parseInt(get(submitReferral, "commission.commission"), 0),
          req_id: get(requirementResponse, "req_id"),
          type: referralType,
          sender_client_id: get(clientResponse, "id"),
          sender_id: get(user, "id"),
          receiver_id: get(receivingAgent, "id"),
          association: getAgentAssociation(receivingAgent, ownIsCRS, isOwnWCR),
        }
        const rerefrralLeadResponse = yield call(referAClientAPI, referPayload)
        if (isSuccess(rerefrralLeadResponse)) {
          const { response: leadReferralSuccessResponse } = rerefrralLeadResponse.data
          yield put(signCRSAgreementAction.request({
            envelopeId: get(leadReferralSuccessResponse, "envelope_id"),
            returnUrl: `${BASE_URL}/referral-loader?callbackUrl=${encodeURIComponent(`${BASE_URL}/referrals/outbound`)}`,
            referralId: get(leadReferralSuccessResponse, "referralId"),
            push: history.push,
            type: "outgoing",
          }))
        } else {
          throw rerefrralLeadResponse.data.response
        }
        yield clearForm()
        return
      }
      if (isEmpty(receivingAgent)) {
        const leadPayload = {
          preferred_receiver_id: get(receivingAgent, "id"),
          clientId: clientResponse.id,
          type: referralType,
          referral_source: "SubmitLeads",
        }
        if (referralType === "Buyer" || referralType === "Renter") {
          leadPayload.req_id = requirementResponse.req_id
        }
        if (referralType === "Seller") {
          leadPayload.prop_id = get(sellerResponse, "data.response.basic_id")
        }
        const leadRes = yield call(createLeadAPI, leadPayload)
        if (isSuccess(leadRes)) {
          //history.push(redirectPath || "/referrals/outbound")
          const leadResponse = leadRes.data.response
          try {
            const mortgageRes = yield call(getPopupDetailsApi, `?eventType=lead_submission&popupType=mortgage&entityId=${leadResponse.referral_id}`)
            if (isSuccess(mortgageRes)) {
              yield put(getPopupDetailsAction.success(mortgageRes.data && mortgageRes.data.response))
              yield put(showPopupAction.call({ showMortgagePopup: true }))
            }
          } catch (err) {
            console.log(err)
          }
        } else {
          yield put(Actions.handleLeadReferralErrorAction.call(leadRes.data.response))
        }
      }
      yield put(Actions.toggleReferralSuccessModalAction.call(true))
      yield clearForm()
      //history.push(redirectPath || "/referrals/outbound")
    } else {
      throw clientRes.data.response
    }
  } catch (e) {
    console.log(e)
    yield put(Actions.handleLeadReferralErrorAction.call(e.data))
  }
}

function* clearForm() {
  yield put(destroy("SUBMIT_REFERRAL_BASIC_INFO"))
  yield put(destroy("BUYER_REQUIREMENT"))
  yield put(destroy("COMMISSION_FORM"))
  yield put(destroy("REFERRAL_BACKGROUND"))
  yield put(destroy("SELLER_PROPERTY"))
  yield put(Actions.clearFormAction.call())
}

function* handleProfileSubmitReferralAction(action) {
  try {
    const user = checkForUserObject("user")
    if (isEmpty(user)) {
      cookie.save("redirectUrl", root.location.href)
      root.location = `${RADIUS_URL}/signup`
      return
    }
    const { agent: receivingAgent } = action.data
    const ownIsCRS = isTrueCRS()
    const isOwnWCR = cookie.load("isWcr")
    const submitReferralPayload = {
      receivingAgent: {
        id: get(receivingAgent, "id"),
        associations: get(receivingAgent, "associations"),
        isCrsDesignee: get(receivingAgent, "isCrsDesignee"),
        agent_name: `${get(receivingAgent, "firstname")} ${get(receivingAgent, "lastname")}`,
      },
    }

    // CRS/WCR to CRS to CRS/WCR Flow
    if (isAgentSpecialFlow(receivingAgent, ownIsCRS, isOwnWCR)) {
      submitReferralPayload.isPartnerFlow = true
      yield put(Actions.isPartnerFlowAction.call())
    }
    cookie.save("submitReferralPayload", submitReferralPayload)
    const parsedURI = encodeURIComponent(JSON.stringify(submitReferralPayload))
    root.location = parsedURI ? `${APP_URL}/submit/referral/begin?profileData=${parsedURI}&type=agent&redirect=${root.location.pathname}` : "/referral/outbound"
  } catch (error) {
    console.warn("Error", error)
  }
}

function* handleLoadSubmitReferral() {
  try {
    const query = queryString.parse(root.location.search) || {}
    if (query.profileData) {
      const parsedPayload = JSON.parse(query.profileData)
      const { associations } = parsedPayload.receivingAgent
      yield put(Actions.saveSubmitReferralPayloadAction.call({ ...parsedPayload }))
      // const isWCR = getAssociationFromQuery(associations, "wrc")
      // const isCRSNew = getAssociationFromQuery(associations, "crs")
      //const isLeadAgreement = cookie.load("leadAgreementSigned")
      // if (!isWCR || !isCRSNew) {
      //   root.location = `${APP_URL}/unsigned`
      // }
    }
  } catch (e) {
    console.warn(e)
  }
}

function* handleCRSReferralFlow(action) {
  try {
    const { history } = action.data
    yield put(Actions.submitReferralAction.call({ history }))
  } catch (error) {
    console.warn(error)
  }
}

function* getClientSearch(action) {
  try {
    const res = yield call(getClientSearchAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.getClientsSearchAction.success(response))
    }
  } catch (error) {
    yield put(Actions.getClientsSearchAction.failure(error))
  }
}

function* handleCancelFlow(action) {
  try {
    const { history } = action.data
    yield put(showConfirmModalAction.call({
      title: "Are you sure?",
      body: "Exiting this referral page will also delete any information you’ve already provided.",
      successButtonText: "Yes, delete",
    }))
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { data: isConfimed } = confirm
    if (!isConfimed) {
      return false
    }
    const redirectPath = yield select(state => state.submitReferral.redirectPath)
    history.push(redirectPath || "/referrals/outbound")
    yield put(hideConfirmModalAction.call())
    yield clearForm()
    return true
  } catch (error) {
    console.warn(error)
  }
}

function* handleClientTypeChange(action) {
  // Be carefull editing this. this action is called for all input change.
  // But we only on control one. So be very carefull.!
  try {
    const { form, field } = action.meta
    if (form === "SUBMIT_REFERRAL_BASIC_INFO" && field === "type") {
      yield put(destroy("SELLER_PROPERTY"))
      yield put(destroy("BUYER_REQUIREMENT"))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* main() {
  yield takeLatest(Actions.SUBMIT_REFERRAL_NEXT, submitReferralNextStep)
  yield takeLatest(Actions.submitReferralAction.type, handleSubmitReferral)
  yield takeLatest(Actions.profileSubmitReferralAction.type, handleProfileSubmitReferralAction)
  yield takeLatest(Actions.handleCRSFollowAction.REQUEST, handleCRSReferralFlow)
  yield takeLatest(Actions.loadSubmitReferralPayloadAction.type, handleLoadSubmitReferral)
  yield takeLatest(Actions.cancelSubmitReferralFlowAction.type, handleCancelFlow)
  yield throttle(2000, Actions.getClientsSearchAction.REQUEST, getClientSearch)
  yield takeLatest(Actions.saveBasicInfoAction.type, validateBasicaInfoBeforeMovingForward)
  yield takeLatest("@@redux-form/CHANGE", handleClientTypeChange)
  //yield takeLatest(Actions.setMarketplaceInitValuesAction.type, marketplaceInitBasicForm)
}
