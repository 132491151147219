import React, { useState } from 'react'
import { get } from 'lodash'
import CRMV2Container from 'container/CRM'
import { Plus as PlusIcon } from 'dumbComponents/CRMV2/CRMDashboard/Components/Icons'
import { normalizePhone } from 'dumbComponents/TransactionCoordinationV2/Forms/Common/Utils'
import { CoSellerDetailsForm } from '../Forms'
import CoClientDetails from './CoClientDetails'
import { NewButton, Row, Col } from '@ui/antd'
import {
  ShortProfileViewContainer,
  NameAndProfileContainer,
  ContactInfoContainer,
  SectionHeading,
  ContactInfoRow,
} from '../styles'
import {
  Phone as PhoneIcon,
  Mail as MailIcon
} from 'dumbComponents/CRMV2/CRMDashboard/Components/Icons/'

const LeadDetails = ({
  getCrmClientResponse,
  isSeller,
  isBuyer,
  coClients,
}) => {
  const [showForm, setShowForm] = useState(false)
  const { data: clientData, isFetching } = getCrmClientResponse || {}
  const {
    name,
    phones,
    emails,
  } = clientData || {}
  const phone = !isFetching && phones && get(phones[0], "phone") || ""
  const email = !isFetching && emails && get(emails[0], "email") || ""
  const initialValues = {
    name,
    phone: normalizePhone(phone),
    email,
  }

  return (
    <>
      <Row align="middle">
        <Col flex="auto">
          <SectionHeading>Details</SectionHeading>
        </Col>
        <Col>
          <NewButton
            type="primary"
            onClick={() => {
              setShowForm(true)
            }}
            icon={<PlusIcon />}
            iconPosition="start"
          >
            {isSeller && "Add Seller"}
            {isBuyer && "Add Co-Buyer"}
          </NewButton>
        </Col>
      </Row>
      <ShortProfileViewContainer>
        <NameAndProfileContainer>
          <p>
            {name}
          </p>
          <span className="primary">Primary</span>
        </NameAndProfileContainer>

        <ContactInfoRow>
          {phone && (
            <ContactInfoContainer>
              <div className="icon">
                <PhoneIcon />
              </div>
              <p className="info">{phone && normalizePhone(phone)}</p>
            </ContactInfoContainer>
          )}

          <ContactInfoContainer>
            <div className="icon">
              <MailIcon />
            </div>
            <p className="info">
              {email}
            </p>
          </ContactInfoContainer>
        </ContactInfoRow>
      </ShortProfileViewContainer>
      {coClients &&
        coClients.map(data => <CoClientDetails data={data} initialValues={initialValues} />)}
      {showForm && (
        <CoSellerDetailsForm
          setShowForm={() => setShowForm(false)}
          isSeller={isSeller}
          isBuyer={isBuyer}
          isShowForm={showForm}
          headingText={isBuyer ? "buyer" : "seller"}
        />
      )}
    </>
  )
}

export default CRMV2Container(LeadDetails)
