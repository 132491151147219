import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import root from "window-or-global"
import copyToClipboard from "copy-to-clipboard"
import { toast as createToast } from "react-toastify"
import FlyMailImage from "images/Graphics/FlyMail.png"
import inviteContainer from "container/App/inviteFeatureContainer"
import FacebookIcon from "images/icons/Facebook.png"
import TwitterIcon from "images/icons/Twitter.png"
import LinkedInIcon from "images/icons/Linkedin.png"
import CloseButton from "images/icons/CloseSvg.svg"

//Social media share buttons
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share"

//EmailArrayComponent
import EmailArray from "./EmailArray"

const Wrap = styled.div`
  min-height: 533px;
  height: max-content;
  width: 645px;
  border-radius: 16px;
  padding: 32px 32px 24px 32px;
  background: ${colors.fadedBlueSea};
  border: 2px solid ${colors.deepPurple};

  ${props => props.successfullySubmitted && `
    min-height: unset;
  `}

  transition: min-height 1s forwards;
`

const InnerWrap = styled.div`
  height: max-content;
  width: 100%;
  border-bottom: 1px solid ${colors.dodoGrey};

  ${props => props.noBorder && `
    border-bottom: none;
  `}
`

const Title = styled.h1`
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-weight: 700;
    color: ${colors.deepPurple};
  }

  ${props => props.brokerage && `
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    width: 297px;
  `}
`

const SubTitle = styled.h2`
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`
const Description = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 10px;

  ${props => props.brokerage && `
    span a {
      color: black;
      cursor: pointer;
    }
  `}
`

const ShareWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
`

const SocialMediaIcon = styled.img`
  height: 13px;
  position: relative;
  left: 12px;
  top: -3px;
`

const FBShare = styled(FacebookShareButton)`
  height: 32px;
  width: 62px;
  border-radius: 8px;
  padding: 10px;
  border: 2px solid #D3DAE8;
  margin-right: 10px;
  cursor: pointer;
`

const TwitterShare = styled(TwitterShareButton)`
  height: 32px;
  width: 62px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 10px;
  border: 2px solid #D3DAE8;
  margin-right: 10px;
  cursor: pointer;
`

const LinkedinShare = styled(LinkedinShareButton)`
  height: 32px;
  width: 62px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 10px;
  border: 2px solid #D3DAE8;
  cursor: pointer;
`

const CopyButton = styled.button`
  height: 48px;
  width: 125px;
  border-radius: 4px;
  font-weight: 500;
  background: transparent;
  border-radius: 100px;
  color: ${colors.deepPurple};
  border: 2px solid ${colors.deepPurple};
  margin-left: 20px;
  cursor: pointer;
`

const FlyMailWrap = styled.div`
  max-height: 150px;
  img {
    width: 319px;
    position: relative;
    left: -24px;
  }
`

const ActionWrap = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
`

const LinkInput = styled.div`
  display: flex;
  background: #D3DAE8;
  border-radius: 16px;
  padding-top: 12px;
  padding-left: 10px;
  overflow: hidden;
  p {
    flex: 1;
    border-radius: 4px;
    color: ${colors.black};
    font-weight: 300;
    font-size: 16px;
    word-break: break-all;
    white-space: nowrap;
  }
`

const InviteSentWrap = styled.div`
  margin-top: -15px;
`

const Text = styled.p`
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
`

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background",
}

const InviteModal = ({
  ...props
}) => {
  const {
    toggleInvitePopUp,
    userId,
    userName,
    postEmailInvites,
    currentInviteType,
    postEmailInvitesResponse,
    isInviteSubmittedSuccessfullyFromModal,
  } = props

  const url = `${RADIUS_URL}?agent_id=${userId}&profile=${userName}&invite_type=${currentInviteType}`

  const {
    isFetching: isSubmittingInvites,
  } = postEmailInvitesResponse

  const handleCopy = (urlToBeCopied) => {
    copyToClipboard(urlToBeCopied)
    createToast("Invite Link Copied", toastConfig)
  }

  const handleSubmit = (val, dispatch) => {
    let emailArray = []

    const {
      initial_email,
      emails,
    } = val || {}

    //initial email
    emailArray.push(initial_email)

    const extractedEmails = emails && emails.length > 0 && emails.map(email => email._email)

    if (emails && emails.length > 0) {
      emailArray = [...emailArray, ...extractedEmails]
    }

    const payload = {
      firstname: "",
      lastname: "",
      emails: emailArray,
      phone: "",
      email: "",
      invite_type: currentInviteType,
    }
    if (root.heap) root.heap.track("Click_Modal_Invite_Submit_CTA")

    postEmailInvites({
      payload,
      dispatch,
    })
  }

  return (
    <Wrap>
      <Modal
        show
        toClose={() => { toggleInvitePopUp(false) }}
        width="800px"
        overlayColor="#000000b3"
        modalStyles={{
          padding: 0,
        }}
        customDialogColor="transparent"
        dialogStyles={{
          background: "transparent",
          overflow: "hidden",
          position: "relative",
          top: "31px",
        }}
        closeIcon={CloseButton}
        showCloseButton
        iconStyles={{
          position: "absolute",
          width: "19px",
          right: "172px",
          cursor: "pointer",
          top: "18px",
        }}
        modalVersion={2}
      >
        <Wrap successfullySubmitted={isInviteSubmittedSuccessfullyFromModal}>
          {isInviteSubmittedSuccessfullyFromModal ? (
            <>
              <FlyMailWrap>
                <img src={FlyMailImage} alt="flyemail" />
              </FlyMailWrap>
              <InviteSentWrap>
                <Text>Invite Sent!</Text>
              </InviteSentWrap>
            </>
          ) : (
            <>
              <InnerWrap>
                {currentInviteType === "brokerage" ? (
                  <Title brokerage>
                    Refer Your Friends and earn
                    {" "}
                    <span>cash rewards</span>
                  </Title>
                ) : (
                  <Title>Refer Your Friends</Title>
                )}
                {currentInviteType === "brokerage" ? (
                  <Description brokerage>
                    Receive cash rewards for each agent you invite who closed 12 or
                    more deals in 2021, and similarly for agents who close less than 12 deals in 2021.
                    {" "}
                    <span>
                      <a href="/terms-of-use" target="_blank">Terms and Conditions</a>
                    </span>
                  </Description>
                ) : (
                  <Description>Share Radius with your friends and help us grow your agent community.</Description>
                )}
              </InnerWrap>
              <InnerWrap>
                <SubTitle>Share on Social</SubTitle>
                <ShareWrap>
                  <FBShare url={url}>
                    <SocialMediaIcon src={FacebookIcon} alt="facebookIcon.png" />
                  </FBShare>
                  <TwitterShare url={url}>
                    <SocialMediaIcon src={TwitterIcon} alt="facebookIcon.png" />
                  </TwitterShare>
                  <LinkedinShare url={url}>
                    <SocialMediaIcon src={LinkedInIcon} alt="facebookIcon.png" />
                  </LinkedinShare>
                </ShareWrap>
              </InnerWrap>
              <InnerWrap>
                <SubTitle>Copy and Share your link</SubTitle>
                <ActionWrap>
                  <LinkInput>
                    <p>
                      {url}
                    </p>
                  </LinkInput>
                  <CopyButton
                    onClick={() => {
                      if (root.heap) root.heap.track("Click_Modal_Invite_Copy_URL_CTA")
                      handleCopy(url)
                    }}
                  >
                    Copy link
                  </CopyButton>
                </ActionWrap>
              </InnerWrap>
              <InnerWrap noBorder>
                <SubTitle>Via Email</SubTitle>
                <EmailArray
                  onSubmit={handleSubmit}
                  isSubmittingInvites={isSubmittingInvites}
                />
              </InnerWrap>
            </>
          )}
        </Wrap>
      </Modal>
    </Wrap>
  )
}

export default inviteContainer(InviteModal)
