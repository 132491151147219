import React from "react";
import { Container, StyledImage, Description, NoDataWrapper, NoData } from "./styles.js";

const NoDataFound = () => {
  return (
    <>
          <Container>
              <NoDataWrapper>
        <StyledImage
          src={`${CLOUDFRONT}/SSRListing/no-data-found.svg`}
          alt="no-data"
        />
        <NoData>No Properties Found</NoData>
        <Description>
          We’re constantly updating our listings, so check back soon.
                  </Description>
                  </NoDataWrapper>
      </Container>
    </>
  );
};

export default NoDataFound;
