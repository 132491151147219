import styled from "styled-components"
import colors from "@colors"

const ButtonsWrap = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.disabledInput};
  padding: 24px;
  background: ${colors.white};
  margin-top: 20px;
  display: flex;

> button {
  background: ${colors.contentText};
  color: ${colors.white};
  padding: 10px 16px;
  border: none;
  margin-right: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  cursor: pointer;

  > img {
    margin-right: 10px;
  }
  :disabled {
    background: ${colors.lightGray};
    cursor: not-allowed;
  }
}
`

export default ButtonsWrap

