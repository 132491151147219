import React, { useEffect } from "react"
import styled from "styled-components"
import queryString from "query-string"
import SettingsContainer from "container/Settings"
import Temp from "../temp"
import CookiesStorage from "services/CookieStorage"
import { get } from "lodash"
import Container from "container/Settings/SubscriptionsContainer"
import { StripeProvider } from "react-stripe-elements"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
} from "dumbComponents/Auth/SignupV2/common"
import SetupForm from "../../SetupForm"
import { PageTitle, ExpireWrap, PageDescription } from "../../common"
import { creditCardFee } from "../../consts"

const BottomText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

const AddCard = ({
  verifyAuthToken,
  history,
}) => {
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery
  const payload = {
    authCode: authcode,
  }

  useEffect(() => {
    verifyAuthToken({ payload })
  }, [authcode])

  const user = CookiesStorage.load("user")
  const firstname = get(user, "firstname")

  const handleClick = () => {
    const mail = document.createElement("a")
    mail.href = "mailto:support@radiusagent.com"
    mail.click()
  }

  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap
          width="50%"
        >
          <LeftImage src={creditCardFee} width={400} height={350} top="200px" />
        </LeftWrap>
        <RightWrap
          width="50%"
          padding="0px 70px 0px 90px"
        >
          <ExpireWrap>
            <PageTitle width="fit-content">
              {`${firstname}, add a payment method to complete your onboarding.`}
            </PageTitle>
            <PageDescription style={{ width: "501px", marginTop: "10px" }}>
              Set up your payment method for your subscription fees below.
              This card or account will be charged for any recurring fees mentioned in your fee schedule,
              like subscription or platform fees.
            </PageDescription>
          </ExpireWrap>
          <StripeProvider apiKey={`${STRIPE_PUBLISH_KEY}`}>
            <SetupForm />
          </StripeProvider>
          <PageDescription fontSize="14px">
            Note: Any transaction fees (your split, auditing fee, etc) will be withdrawn from your commission checks.
          </PageDescription>
          <BottomText>
            <PageDescription fontSize="12px" color="#212121">
              Got questions?
            </PageDescription>
            <PageDescription fontSize="12px" color="#212121">
              Contact
              <span
                onClick={() => handleClick()}
                onKeyDown={() => {}}
              >
                support@radiusagent.com
              </span>
            </PageDescription>
          </BottomText>
        </RightWrap>
        <Temp />
      </ContentWrap>
    </Wrap>
  )
}

export default Container(SettingsContainer(AddCard))
