import React from "react"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets/index"
import Listings from "container/Listings/index"
import ListingsV2 from "container/ListingsV2/index"
import {
  WriteAnOfferText,
  BuyerNameText,
  CrumbWrap,
  ChevronWrap,
} from "./styles"

const ListingsBreadCrumb = ({
  history,
  toggleDocumentTab,
  setListingId,
  setCurrentListingTab,
  setOfferIdToView,
  setDealToView,
}) => (
  <CrumbWrap
    onClick={() => {
      //history.goBack()
      setListingId(null)
      setDealToView(null)
      setOfferIdToView(null)
      setCurrentListingTab("cover_sheet")
      toggleDocumentTab("")
      history.push("/realty/listings")
    }}
  >
    <WriteAnOfferText>
      <p>
        {"<"}
        {" "}
        Return to all listings
      </p>
    </WriteAnOfferText>
  </CrumbWrap>
)

export default Listings(ListingsV2(TechAssets(withRouter(ListingsBreadCrumb))))
