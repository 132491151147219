import React, { useState } from "react"
import {
  Dropdown, Menu, DatePicker, Button,
} from "antd"
import styled from "styled-components"
import {
  StyledButton, StyledButtonSelected, CustomMenu, DropDownWrap, CustomRangeContainer
} from "./styles"

const { RangePicker } = DatePicker

const DateFilter = ({ optionList, handleDateChange, currentValue }) => {
  const [selectedValue, setSelectedValue] = useState("Select Date")
  const [customRange, setCustomRange] = useState([])

  const handleMenuClick = ({ key }) => {
    if (key !== "custom_range") {
      setSelectedValue(optionList.find(item => item.value === key).label)
      handleDateChange(key)
    }
  }

  const applyCustomRange = () => {
    if (customRange.length === 2) {
      const fromDate = customRange[0].startOf("day").unix()
      const toDate = customRange[1].endOf("day").unix()

      setSelectedValue(
        `${customRange[0].format("YYYY-MM-DD")} - ${customRange[1].format("YYYY-MM-DD")}`
      )
      handleDateChange({
        value: "custom_range",
        fromDate,
        toDate,
      })
    }
  }

  const menu = (
    <CustomMenu onClick={handleMenuClick}>
      {optionList.map(option => (
        <Menu.Item key={option.value}>{option.label}</Menu.Item>
      ))}
      <Menu.Divider />
      <CustomRangeContainer>
        <div style={{ marginBottom: "10px" }}>
          <span>Custom Date:</span>
        </div>
        <RangePicker
          onChange={dates => setCustomRange(dates)}
          value={customRange}
        />
        <Button
          type="primary"
          onClick={applyCustomRange}
          style={{ marginTop: "10px" }}
        >
          Apply
        </Button>
      </CustomRangeContainer>
    </CustomMenu>
  )

  return (
    <DropDownWrap overlay={menu} trigger={["click"]}>
      {
        currentValue == "Select Date" ? (
          <StyledButton>{currentValue}</StyledButton>
        ) : (
          <StyledButtonSelected>{currentValue}</StyledButtonSelected>
        )
      }
    </DropDownWrap>
  )
}

export default DateFilter
