import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Wellness from "../Icons/NewIcons/Wellness"

const WellnessTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[0] === "wellness"}>
    <div className="icon-block-new">
      <Wellness />
    </div>
    <div className="text-block">
      <NavLink
        to="/wellness"
        isActive={routeArray[0] === "wellness"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_Wellness_web")
          }
        }}
      >
        Wellness
      </NavLink>
    </div>
  </InnerTab>
)

export default WellnessTab
