import React, { useState } from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import { ATC } from "@fonts"
import { isEmpty } from "lodash"
import root from "window-or-global"
import appContainer from "container/App"
import { handleDataLayerPush } from "services/dataLayerUtil"
import CookiesStorage from "services/CookieStorage"
import RoomsContainer from "container/Rooms"
import { authCheck } from "services/auth"
import BluePlusIcon from "dumbComponents/VideoRooms/components/LandingPage/Components/images/BluePlusIcon.svg"
import HostFallbackImage from "images/icons/radius-icon.png"
import { pstTimeZone } from "services/Utils"
import colors from "@colors"
import AddToCalenderUI from "dumbComponents/EventRooms/AddToCalender"

const CardWrap = styled.div`
  background: #F9F9F7;
  border-radius: 8px;
  // min-height: 600px;
  cursor: pointer;
`

const CardDetails = styled.div`
  position: relative;
  height: max-content;
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.show ? "space-around" : "center"};
  align-items: baseline;
  height: ${props => props.show ? "500px" : "411px"};

  @media(max-width: 550px) {
    width: 100%;
  }

  transition: height 0.5s;
`

const RoomDetails = styled.div`
  display: ${props => props.show ? "block" : "none"};
  opacity: ${props => props.show ? "1" : "0"};
  transition: opacity 0.5s;
`

const RoomImage = styled.img`
  height: 230px;
  width: 550px;
  object-fit: contain;
  border-radius: 8px 8px 0px 0px;
  z-index: 1;
  position: relative;
  // background-color: #2F2D2D;

  @media(max-width: 550px) {
    width: 100%;
  }
`

const RoomTime = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.black};
  margin-top: 0px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media(max-width: 1024px) {
    font-size: 16px;
  }

  @media(max-width: 550px) {
    font-size: 14px;
  }
`

const RoomTitle = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  margin-top: 5px;
  overflow: scroll;


  @media(max-width: 1024px) {
    font-size: 22px;
  }

  @media(max-width: 550px) {
    font-size: 18px;
  }
`

const RoomDescription = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 23px;
  color: ${colors.suvaGray};
  overflow: scroll;
  @media(max-width: 550px) {
    font-size: 16px;
  }

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`

const HostDetails = styled.div`
  display: flex;
  gap: 12px;
`

const HostDetailsWrap = styled.div`
  p {
    margin-bottom: 0px;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.black};

    @media(max-width: 1024px) {
      font-size: 16px;
    }
  }

  h1 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: ${colors.black};
    margin-top: 5px;
    @media(max-width: 1024px) {
      font-size: 18px;
    }
  }
`

const RoomWrap = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SpeakerDetails = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 25px;
  align-items: center;
  height: 50px;
  p {
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    @media(max-width: 1024px) {
      font-size: 16px;
    }
  }
`

const TagAttendeeWrap = styled.div``

const CategoryWrap = styled.div`
  display: ${props => props.show ? "flex" : "none"};
  justify-content: space-between;
  margin: 20px 0px;
  flex-wrap: wrap;
  gap: 1px 10px;
  height: max-content;
  overflow: scroll;

  align-items: flex-end;
  overflow: scroll;
  justify-content: flex-start;

  ${props => !props.tags && `
    justify-content: end;
  `}

  opacity: ${props => props.show ? "1" : "0"};;

  transition: opacity 0.5s ease-in 1s;
`

const AttendeeCount = styled.small`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 23px;
  color: ${colors.suvaGray};
`

const RoomButtonWrap = styled.div`
  display: flex;
  gap: 25px;
`

const HoverDisplayWrap = styled.div`
  display: flex;
  height: 100%;
`

const SpeakerDetailWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const SpeakerName = styled.h1`
  margin: 0;
  margin: 0;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  @media(max-width: 1024px) {
    font-size: 16px;
  }
`

const CategoryTitleWrap = styled.div`
  display: flex;
  gap: 10px;


  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    color: ${colors.suvaGray};
    margin-bottom: 0px;
  }
`

const StyledRegisterButton = styled(Button)`
  @media(max-width: 550px) {
    width: 111px !important;
  }
`

const StyledCalendarButton = styled(Button)`
  @media(max-width: 767px) {
    width: max-content;
  }
`

const SpeakerMobileView = styled.div`
  display: flex;
  gap: 15px;

  @media(max-width: 550px) {
    flex-direction: column;
    gap: 0px;
  }
`

const BlankDiv = styled.div`
  height: 44.5px;
`

const BackgroundImage = styled.div`
  ${props => props.url && `
    background: url(${props.url});
    height: 230px;
    filter: blur(2px);
    position: absolute;
    width: 550px;
    background-size: cover;
    border-radius: 8px 8px 0px 0px;

    @media(max-width: 550px) {
      width: 100%;
    }
  `}
`

const HideOnHoverWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const VisibleDetails = styled.div`
  padding: 15px 0px 0px 15px;
`

const ImageWrap = styled.div`
    position: relative;
`

const ScheduledRoomCarouselDetails = ({
  data,
  notifyForRoom,
  index,
  user: userData,
}) => {
  const isLogged = authCheck()

  const [isHover, setHoverState] = useState(false)
  const [register, setRegister] = useState(false)
  const {
    name, description, scheduled_at, image_url,
    invited_attendees, host, attendees_count, tags,
  } = data || {}

  const { firstname, lastname, image_url: hostImage } = host || {}

  const PSTTime = pstTimeZone(scheduled_at, true)
  const { pstTimeString, pstDateString } = PSTTime

  const roomName = name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ").join("-").substr(0, 50)
  const currentAgent = CookiesStorage.load("agentId")
  const isRegistered = !isEmpty(data) && data.invited_attendees
  && !isEmpty(data.invited_attendees)
  && data.invited_attendees.filter(attendee => attendee.id === parseInt(currentAgent, 10)) || false

  const handleRegister = () => {
    if (isLogged) {
      setRegister(true)
      notifyForRoom({
        id: data.id,
      })
    } else {
      root.open(`/scheduledrooms/${data.id}/${roomName}?redirectToRSVP=true`)
    }
  }

  const handleRedirection = () => {
    root.open(`/scheduledrooms/${data.id}/${roomName}`)
  }

  const payload = {
    room_date: `${pstDateString} ${pstTimeString}`,
    hosted_by: `${firstname} ${lastname}`,
    speakers: "",
    room_type: "upcoming",
    room_name: name,
    room_section: "upcoming_rooms",
  }

  const speakerFilter = invited_attendees && invited_attendees.filter(speakersRole => speakersRole.Role === "speaker")
    .map(speakers => `${speakers.firstname} ${speakers.lastname}`) || null

  console.log("isHover", isHover)
  return (
    <CardWrap
      onClick={() => {
        if (speakerFilter && speakerFilter.length > 0) {
          payload.speakers = speakerFilter.join(",")
        }
        handleDataLayerPush(userData, "room_clicked", name, payload)
        setHoverState(true)
        handleRedirection()
      }}
      id={`${index}_scheduled_rooms`}
      onMouseEnter={() => { setHoverState(true) }}
      onMouseLeave={() => { setHoverState(false) }}
    >
      <ImageWrap>
        <BackgroundImage url={image_url} />
        <RoomImage src={image_url} alt="radius_rooms_event" />
      </ImageWrap>
      <CardDetails
        show={isHover}
      >
        <VisibleDetails>
          <RoomTime>
            {`${pstDateString} | ${pstTimeString} PST`}
          </RoomTime>
          <RoomTitle>
            {name}
          </RoomTitle>
          <RoomDetails
            show={isHover}
          >
            <RoomDescription>
              {description}
            </RoomDescription>

          </RoomDetails>
        </VisibleDetails>
        <RoomWrap>
          <HoverDisplayWrap>
            <HideOnHoverWrap>
              <HostDetails>
                <img src={hostImage || HostFallbackImage} alt="radius_rooms_event" height={60} width={60} style={{ borderRadius: "60px" }} />
                <HostDetailsWrap>
                  <p>Hosted By</p>
                  <h1>{`${firstname} ${lastname}`}</h1>
                </HostDetailsWrap>
              </HostDetails>
              {invited_attendees && (
                <SpeakerDetails>
                  <p>Speakers:</p>
                  <SpeakerMobileView>
                    {invited_attendees.filter(speakersRole => speakersRole.Role === "speaker").map(speakers => (
                      <SpeakerDetailWrap>
                        <img
                          src={speakers.image_url || HostFallbackImage}
                          alt="radius_rooms_event"
                          height={20}
                          width={20}
                          style={{ borderRadius: "60px" }}
                        />
                        <SpeakerName>
                          {`${speakers.firstname} ${speakers.lastname}`}
                        </SpeakerName>
                      </SpeakerDetailWrap>
                    ))}
                  </SpeakerMobileView>
                </SpeakerDetails>
              )}
              {!invited_attendees && (
                <BlankDiv />
              )}
              <TagAttendeeWrap>
                <CategoryWrap
                  tags={tags}
                  show={isHover && tags && tags.length > 0}
                >
                  {tags && tags.map(tag => (
                    <>
                      <CategoryTitleWrap>
                        <p>
                          •
                        </p>
                        <p>
                          {tag.name}
                        </p>
                      </CategoryTitleWrap>
                    </>
                  ))}
                </CategoryWrap>
                {attendees_count !== null && attendees_count > 0 ? (
                  <AttendeeCount>
                    {`${attendees_count} ${attendees_count > 1 ? "attendees" : "attendee"}`}
                  </AttendeeCount>
                ) : (
                  <AttendeeCount>
                    Be the first one to register!
                  </AttendeeCount>
                )}
              </TagAttendeeWrap>
              <hr />
              <RoomButtonWrap>
                {!isEmpty(isRegistered) || register ? (
                  <StyledRegisterButton
                    disabled
                    type="button"
                    style={{
                      borderRadius: "4px",
                      width: "311px",
                      height: "40px",
                    }}
                  >
                    Registered
                  </StyledRegisterButton>
                ) : (
                  <StyledRegisterButton
                    className="rooms_register"
                    bsStyle="marineBLueFill"
                    style={{
                      borderRadius: "4px",
                      width: "311px",
                      height: "40px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (speakerFilter && speakerFilter.length > 0) {
                        payload.speakers = speakerFilter.join(",")
                      }
                      handleDataLayerPush(userData, "room_register_clicked", name, payload)
                      handleRegister()
                    }}
                  >
                    Register
                  </StyledRegisterButton>
                )}
                <AddToCalenderUI
                  event={data}
                  noPosRepos
                >
                  <StyledCalendarButton
                    type="submit"
                    value="Add to Calendar"
                    bsStyle="marineBLueOutlined"
                    style={{
                      borderRadius: "4px",
                      width: "184px",
                      height: "40px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (speakerFilter && speakerFilter.length > 0) {
                        payload.speakers = speakerFilter.join(",")
                      }
                      handleDataLayerPush(userData, "add_to_calendar_clicked", "calendar", payload)
                    }}
                  >
                    Add To Calendar
                    <img src={BluePlusIcon} alt="PLUS ICON" />
                  </StyledCalendarButton>
                </AddToCalenderUI>
              </RoomButtonWrap>
            </HideOnHoverWrap>
          </HoverDisplayWrap>
        </RoomWrap>
      </CardDetails>
    </CardWrap>
  )
}

export default appContainer(RoomsContainer(ScheduledRoomCarouselDetails))
