import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "CLIENT_PROFILE"

export const fetchClientSearchCriteriaAction = createSignalAction(BASE, "FETCH_CLIENT_SEARCH_CRITERIA")
export const fetchClientReminderAction = createSignalAction(BASE, "FETCH_CLIENT_REMINDER")
export const fetchClientPropertiesAction = createSignalAction(BASE, "FETCH_CLIENT_PROPERTIES")
export const fetchClientPropertiesDetailsActions = createSignalAction(BASE, "FETCH_CLIENT_PROPERTIES_DETAILS")
export const fetchClientNotesAction = createSignalAction(BASE, "FETCH_CLIENT_NOTES")
export const fetchClientTimelineAction = createSignalAction(BASE, "FETCH_CLIENT_TIMELINE")
export const addClientNoteAction = createSignalAction(BASE, "ADD_CLIENT_NOTE")
export const updateClientNoteAction = createSignalAction(BASE, "UPDATE_CLIENT_NOTE")
export const deleteClientNoteAction = createSignalAction(BASE, "DELETE_CLIENT_NOTE")
export const updateChecklistAction = createSignalAction(BASE, "UPDATE_CHECKLIST")
export const updateSalesStageAction = createSignalAction(BASE, "UPDATE_SALES_STAGE")
export const setReminderAction = createSignalAction(BASE, "CREATE_REMINDER")
export const addContactAction = createSignalAction(BASE, "ADD_ADDITIONAL_CONTACT")
export const updateContactAction = createSignalAction(BASE, "UPDATE_ADDITIONAL_CONTACT")
export const deleteContactAction = createSignalAction(BASE, "DELETE_ADDITIONAL_CONTACT")
export const fetchContactInfoAction = createSignalAction(BASE, "FETCH_CONTACT_INFO")
export const deleteReminderAction = createSignalAction(BASE, "DELETE_REMINDER")
export const fetchBuyerMatchesAction = createSignalAction(BASE, "FETCH_BUYER_MATCHES")
export const fetchEditPropertyAction = createSignalAction(BASE, "FETCH_EDIT_PROPERTY")
export const markAsQualifiedLeadAction = createSignalAction(BASE, "MARK_AS_QAULIFIED_LEAD")

export const openAddEditNoteModalAction = createSimpleCreator(BASE, "OPEN_ADD_EDIT_NOTE_MODAL")
export const openUpdateNoteModalAction = createSimpleCreator(BASE, "OPEN_UPDATE_NOTE_MODAL")
export const openAdditionalDetailsModalAction = createSimpleCreator(BASE, "OPEN_ADDITIONAL_DETAILS_MODAL")
export const saveNotesAction = createSimpleCreator(BASE, "SAVE_NOTES")
export const openDeleteConfirmationAction = createSimpleCreator(BASE, "OPEN_DELETE_CONFIRMATION")
export const toggleReminderDropdownAction = createSimpleCreator(BASE, "TOGGLE_REMINDER_DROPDOWN")
export const toggleReminderEditModalAction = createSimpleCreator(BASE, "TOGGLE_EDIT_REMINDER_MODAL")
export const fetchedAllTimelineEventsAction = createSimpleCreator(BASE, "FETCHED_ALL_TIMELINE_EVENTS")
export const toggleEditPropertyModalAction = createSimpleCreator(BASE, "OPEN_EDIT_PROPERTY_MODAL")
export const toggleSendRequestModalAction = createSimpleCreator(BASE, "TOGGLE_REQUEST_MODAL")
export const sendRequestPayloadAction = createSignalAction(BASE, "SEND_REQUEST_PAYLOAD")
export const getPreferredLenderAction = createSignalAction(BASE, "GET_PREFERRED_LENDER")
export const updatePreferredLenderAction = createSignalAction(BASE, "UPDATE_PREFERRED_LENDER")
export const togglePreferedLenderModalAction = createSimpleCreator(BASE, "TOGGLE_UPDATE_MODAL")
export const toogleEditInfoModalAction = createSimpleCreator(BASE, "TOGGLE_EDIT_INFO_MODAL")
