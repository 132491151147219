import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "☘️🥶"

export const toggleUserOverviewDrawer = createSimpleCreator(BASE, "TOGGLE_USER_OVERVIEW")
export const toggleFilterClientLeadsDrawer = createSimpleCreator(BASE, "TOGGLE_FILTER_DRAWER")
export const toggleAddNewLeadDrawer = createSimpleCreator(BASE, "TOGGLE_ADD_LEAD_DRAWER")
export const toggleDrawerPage = createSimpleCreator(BASE, "TOGGLE_DRAWER_PAGE")

export const getClientAction = createSignalAction(BASE, "GET_CRM_CLIENT")
export const updateClientAction = createSignalAction(BASE, "UPDATE_CRM_CLIENT")
export const addClientAction = createSignalAction(BASE, "ADD_CRM_CLIENT")

export const createClientTransactionAction = createSignalAction(BASE, "CREATE_CLIENT_TRANSACTION")
export const updateClientTransactionAction = createSignalAction(BASE, "UPDATE_CLIENT_TRANSACTION")
export const getClientTransactionsAction = createSignalAction(BASE, "GET_CLIENT_TRANSACTION")
export const getClientTransactionByIdAction = createSignalAction(BASE, "GET_CT_ID")

export const addBuyerRequirementsAction = createSignalAction(BASE, "ADD_BUYER_REQ")
export const getBuyerRequirementsAction = createSignalAction(BASE, "GET_BUYER_REQ")
export const updateBuyerRequirementsAction = createSignalAction(BASE, "UPDATE_BUYER_REQ")

export const addPropertyDetailsAction = createSignalAction(BASE, "ADD_PROPERTY_DETAILS")
export const getPropertyDetailsAction = createSignalAction(BASE, "GET_PROPERTY_DETAILS")
export const updatePropertyDetailsAction = createSignalAction(BASE, "UPDATE_PROPERTY_DETAILS")

export const getPropertyTypeAction = createSignalAction(BASE, "GET_PROPERTY_TYPE")

export const uploadImageAction = createSignalAction(BASE, "UPLOAD_TO_S3")

export const getClientsFilters = createSignalAction(BASE, "GET_CLIENT_FILTERS")

export const changeCardOrderAction = createSignalAction(BASE, "CHANGE_CARD_ORDER")
export const changeCardStageAction = createSignalAction(BASE, "CHANGE_CARD_STAGE")

export const setTransactionDetailsAction = createSimpleCreator(BASE, "SET_TRANSACTION_DETAILS")
export const currentTopFilterTypeAction = createSimpleCreator(BASE, "TOP_FILTER_TYPE")
export const updateFilterQueriesTypeAction = createSimpleCreator(BASE, "UPDATE_FILTERS")
export const setCurrentClientTransactionIDAction = createSimpleCreator(BASE, "SET_CLIENT_TRANSACTION_ID")
export const setCurrentClientIDAction = createSimpleCreator(BASE, "SET_CLIENT_ID")
export const toggleGoToContract = createSimpleCreator(BASE, "TOGGLE_GO_TO_CONTRACT")
export const updateContractCRMAction = createSignalAction(BASE, "UPDATE_CONTRACT")

//reminders
export const fetchClientReminderAction = createSignalAction(BASE, "FETCH_CLIENT_REMINDER")
export const toggleReminderModalAction = createSimpleCreator(BASE, "TOGGLE_REMINDER_MODAL")
export const setReminderAction = createSignalAction(BASE, "CREATE_REMINDER")
export const deleteReminderAction = createSignalAction(BASE, "DELETE_REMINDER_ACTION")
export const updateReminderAction = createSignalAction(BASE, "UPDATE_REMINDER_ACTION")

export const addNotesAction = createSignalAction(BASE, "ADD_NOTE_ACTION")
export const getNotesAction = createSignalAction(BASE, "GET_NOTES_ACTION")
export const updateNotesAction = createSignalAction(BASE, "UPDATE_NOTES_ACTION")
export const deleteNotesAction = createSignalAction(BASE, "DELETE_NOTES_ACTION")

export const getTimelineAction = createSignalAction(BASE, "GET_TIMELINE_ACTION")
export const getBrokerageTransactionStatusAction = createSignalAction(BASE, "GET_BROKERAGE_TRANSACTION_STATUS")
export const addBrokerageTransactionAction = createSignalAction(BASE, "ADD_BROKERAGE_TRANSACTION")
export const setModalOpenConfirmAction = createSimpleCreator(BASE, "SET_MODAL_OPEN_CONFIRM")
export const setModalOpenViewAction = createSimpleCreator(BASE, "SET_MODAL_OPEN_VIEW")

export const setTabAction = createSimpleCreator(BASE, "SET_TAB_ACTION")

export const getFinancingForTransactionAction = createSignalAction(BASE, "GET_FINANCING_FOR_TRANSACTION")
export const createFinancingForTransactionAction = createSignalAction(BASE, "CREATE_FINANCE_TRANSATION")
export const updateFinancingForTransactionAction = createSignalAction(BASE, "UPDATE_FINANCE_TRANSACTION")

export const setClientTypeAction = createSimpleCreator(BASE, "SET_CLIENT_TYPE")

export const clearUpdatedDataAction = createSimpleCreator(BASE, "CLEAR_UPDATED_DATA")

export const toggleAgentFinancePreferenceAction = createSignalAction(BASE, "TOGGLE_AGENT_FINANCE")

export const addCoClientDetailsAction = createSignalAction(BASE, "ADD_CO_CLIENT_ACTION")
export const updateCoClientDetailsAction = createSignalAction(BASE, "UPDATE_CO_CLIENT_ACTION")
export const deleteCoClientDetailsAction = createSignalAction(BASE, "DELETE_CO_CLIENT_ACTION")

export const toggleArchiveLeadModalAction = createSimpleCreator(BASE, "TOGGLE_ARCHIVE_LEAD_MODAL")
export const archiveLeadAction = createSignalAction(BASE, "ARCHIVE_LEAD_ACTION")
export const getArchivedLeadAction = createSimpleCreator(BASE, "GET_ARCHIVED_LEAD")
export const unArchiveLeadAction = createSignalAction(BASE, "UNARCHIVE_LEAD_ACTION")
export const toggleModalForArchivedLeadsAction = createSimpleCreator(BASE, "TOGGLE_MODAL_FOR_ARCHIVED_MODAL")

export const deleteClientTransactionAction = createSignalAction(BASE, "DELETE_TRANSACTION_ACTION")

export const setIsViewListing = createSimpleCreator(BASE, "SET_IS_VIEW_LISTING")
export const setIsViewContract = createSimpleCreator(BASE, "SET_IS_VIEW_CONTRACT")
export const setIsViewOffer = createSimpleCreator(BASE, "SET_IS_VIEW_OFFER")
export const setIsAddListing = createSimpleCreator(BASE, "SET_IS_ADD_LISTING")
export const setIsAddOffer = createSimpleCreator(BASE, "SET_IS_ADD_OFFER")
export const setIsEndContract = createSimpleCreator(BASE, "SET_IS_END_CONTRACT")
export const setIsViewListingToContract = createSimpleCreator(BASE, "SET_IS_VIEW_LISTING_TO_CONTRACT")
export const setIsViewOfferToContract = createSimpleCreator(BASE, "SET_IS_VIEW_OFFER_TO_CONTRACT")
export const setCannotConvertContract = createSimpleCreator(BASE, "SET_CANNOT_CONVERT_CONTRACT")
export const setIsClosed = createSimpleCreator(BASE, "SET_IS_CLOSED")
export const setIsCreateWarning = createSimpleCreator(BASE, "SET_IS_CREATE_WARNING")
export const setStatusValue = createSimpleCreator(BASE, "SET_STATUS_VALUE")
export const setViewDeleteNoteModal = createSimpleCreator(BASE, "DELETE_NOTE_MODAL")
export const setViewDeleteReminderModal = createSimpleCreator(BASE, "DELETE_REMINDER_MODAL")

export const emptyDetailsWarningAction = createSimpleCreator(BASE, "TOGGLE_EMPTY_DETAILS")

export const toggleShowInContractAction = createSimpleCreator(BASE, "TOGGLE_SHOW_CONTRACT_MESSAGE")
export const getAgentDetailsAction = createSignalAction(BASE, "GET_AGENT_INFORMATION_WITH_STATE")

//NEW CONTRACT FLOWS
export const confirmMoveToCreateContractAction = createSignalAction(BASE, "CONFIRM_MOVE_TO_CONTRACT_CREATION")
