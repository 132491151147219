import React, { useEffect, useState } from "react"
import Offer from "container/TechAssets"
import { isEmpty } from "lodash"
import app from "container/App"
import OffersV2 from "container/OffersV2/index"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import createToast from "dumbComponents/OffersV3/components/Toast/customToast"
import UploadInterface from "./AdditionalDocumentUpload"
import DocList from "./DocList"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  display: flex;
  padding: 17px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 11px;
  border: 1px solid var(--Old-Cement, #D3DAE8);
  background: #FFF;
  width: 100%;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  position: relative;
  p {
    color: var(--Typography-General-Primary, #303030);
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
  }
`

const BlueButton = styled.button`
  display: flex;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  border: none;
  background: var(--Color-Primary-Primary, #5A5FF2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 22px 6px 0px rgba(0, 0, 0, 0.00);

  color: var(--Color-Neutral-50, #FDFDFD);
  /* leading-tight/body-2/semi-bold */
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.5px;

  img {
    width: 20px;
    height: 20px;
  }
`

const ZeroStateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  gap: 10px;
  width: 100%;
`

const ZeroStateHeader = styled.p`
  margin: 0;
  font-family: ${INTER};
  font-size: 18px;
  font-weight: 500;
  color: #A5A8FF;
  width: 100%;
  text-align: center;
  padding-bottom: 6px;
`

const ZeroStateDescription = styled.p`
  margin: 0;
  font-family: ${INTER};
  font-size: 16px;
  font-weight: 400;
  color: #A5A8FF;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E5E6FF;
  padding-bottom: 6px;
`

const UploadUI = ({
  data,
  transactionUploadFiles,
  fetchTransactionFiles,
  fetchTransactionFilesResponse,
  deleteTransactionFile,
  location,
}) => {
  const {
    data: docs,
    isFetching: fetchingDocs,
  } = fetchTransactionFilesResponse || {}

  const {
    id: transaction_id,
    client_id,
  } = data || {}

  console.log(client_id)

  const [isAdditionalDocDropdownVisible, toggleAdditonalDocDropDown] = useState(false)
  const [proofOfFunds, setProofOfFunds] = useState([])
  const [coverLetter, setCoverLetterDocs] = useState([])
  const [preApproved, setPreApprovedDocs] = useState([])

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (!transaction_id?.length && !parsed.transactionId?.length) {
      createToast("Could not fetch the uploaded files, please try again or contact support.", "error")
      return
    }

    fetchTransactionFiles({
      id: transaction_id || parsed.transactionId,
    })
    //fetchOfferRelatedDocuments()
  }, [])

  useEffect(() => {
    let proofDocs = []
    let coverDocs = []
    let preApprovedDocs = []
    if (docs && docs.length > 0) {
      proofDocs = docs.filter(item => item.type === "proof_of_funds")
      coverDocs = docs.filter(item => item.type === "cover_letter")
      preApprovedDocs = docs.filter(item => item.type === "pre_qualification")

      setProofOfFunds(proofDocs)
      setCoverLetterDocs(coverDocs)
      setPreApprovedDocs(preApprovedDocs)
    }
  }, [docs])

  const handleDelete = (fileId) => {
    const parsed = queryString.parse(location.search)

    if (!transaction_id?.length && !parsed.transactionId?.length) {
      createToast("Could not delete the file, please try again or contact support.", "error")
      return
    }

    deleteTransactionFile({
      fileId,
      clientId: client_id,
      transactionId: transaction_id || Number(parsed.transactionId),
    })
  }

  const handleUpload = (documents) => {
    const parsed = queryString.parse(location.search)

    if (!transaction_id?.length && !parsed.transactionId?.length) {
      createToast("Could not upload the selected file, please try again or contact support.", "error")
      return
    }

    const payload = {
      transaction_id: transaction_id || Number(parsed.transactionId),
      documents,
    }

    transactionUploadFiles({
      payload,
    })
  }

  return (
    <Wrap>
      <HeaderWrap>
        <p>Uploads</p>
        {!isEmpty(docs) && (
          <BlueButton
            onClick={() => toggleAdditonalDocDropDown(!isAdditionalDocDropdownVisible)}
          >
            <span>
              <img src={`${CLOUDFRONT}/OFFERS_V2/cloudUpload.svg`} alt="upload" />
            </span>
            Upload
          </BlueButton>
        )}
        {isAdditionalDocDropdownVisible && (
          <UploadInterface
            close={() => toggleAdditonalDocDropDown(false)}
            uploadFunction={handleUpload}
          />
        )}
      </HeaderWrap>
      {fetchingDocs ? (
        <p>Loading</p>
      ) : (
        <>
          {isEmpty(docs) ? (
            <ZeroStateWrap>
              <ZeroStateHeader>
                No documents uploaded
              </ZeroStateHeader>
              <ZeroStateDescription>
                Add proof of funds, cover letter and pre qual letters
              </ZeroStateDescription>
              <BlueButton
                onClick={() => toggleAdditonalDocDropDown(!isAdditionalDocDropdownVisible)}
              >
                <span>
                  <img src={`${CLOUDFRONT}/OFFERS_V2/cloudUpload.svg`} alt="upload" />
                </span>
                Upload
              </BlueButton>
            </ZeroStateWrap>
          ) : (
            <>
              {proofOfFunds && proofOfFunds.length > 0 && (
                <DocList
                  title="Proof of Funds"
                  docs={proofOfFunds}
                  handleDelete={handleDelete}
                />
              )}

              {coverLetter && coverLetter.length > 0 && (
                <DocList
                  title="Cover Letter"
                  docs={coverLetter}
                  handleDelete={handleDelete}
                />
              )}

              {preApproved && preApproved.length > 0 && (
                <DocList
                  title="Pre Approval"
                  handleDelete={handleDelete}
                  docs={preApproved}
                />
              )}
            </>
          )}
        </>
      )}
    </Wrap>
  )
}

export default withRouter(Offer(OffersV2(UploadUI)))

