import React, { useState } from "react"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import createToast from "dumbComponents/OffersV3/components/Toast/customToast"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import OffersV2 from "container/OffersV2"
import { Input } from "@ui/antd"
import {
  Wrap,
  Header,
  ButtonWrap,
  ContentContainer,
  Content,
  Label,
} from "./styles"

const { TextArea } = Input

const VoidAndRestart = ({
  toogleVoidAndRestartOffer,
  showVoidAndRestartOffer,
  sendForVoidAndRestartOfferResponse,
  sendForVoidAndRestartOffer,
  fetchActiveEnvelopesFiles,
  dealToView,
  getOfferDocuments,
}) => {
  const [notes, setNotes] = useState("")
  const handleClick = () => {
    const payload = [{ envelopeId: showVoidAndRestartOffer, reason: notes }]
    createToast("Voiding and duplicating...")
    sendForVoidAndRestartOffer({
      payload,
      callback: () => {
        createToast("Voided and duplicated.")
        toogleVoidAndRestartOffer(false)
        fetchActiveEnvelopesFiles({
          payload: {
            offerId: dealToView,
          },
        })
        getOfferDocuments({
          payload: {
            offerId: dealToView,
          },
        })
      },
    })
  }
  return (
    <Wrap>
      {showVoidAndRestartOffer && (
        <Modal
          show
          toClose={() => { toogleVoidAndRestartOffer(false) }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "#F9F9F7",
            borderRadius: "8px",
            minHeight: "450px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <h1>
                Are you sure?
              </h1>
            </Header>
            <ContentContainer>
              <Content>
                {`Please provide some reason before you
                void and restart
                selected docs.`}
              </Content>
              <Label>
                Reason:
              </Label>
              <TextArea
                style={{
                  fontWeight: 300,
                }}
                rows={4}
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </ContentContainer>
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                isShowLoader={sendForVoidAndRestartOfferResponse.isFetching}
                disabled={!notes}
                onClick={handleClick}
              >
                Void and Duplicate
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px"
                margin="0 5px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  toogleVoidAndRestartOffer(false)
                }}
              >
                No, thanks
              </LoaderButton>
            </ButtonWrap>
          </>
        </Modal>
      )}
    </Wrap>
  )
}

export default TechAssetsContainer(OffersV2(VoidAndRestart))
