import styled from "styled-components"
import { INTER } from "@fonts"

export const EnvelopesWrap = styled.div`
  width: 100%;
  // position: absolute;
  padding: 16px;
  background-color: #F9F9FF;
`

export const Wrap = styled.div`
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 7px 7px 8px #5A5FF217;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
`

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const StyledStatus = styled.div`
  padding: 6px 12px;
  border-radius: 24px;
  width: 148px;
  height: 28px;
  text-align: center;
  color: white;

  ${props => props.status === "pending" || props.status === "action_required" && `
    background: linear-gradient(89.56deg, #F25A5A 0.36%, #F99291 129.5%);
    text-shadow: 0px 1px 1.4px rgba(43, 0, 0, 0.70);
  `}

  ${props => props.status === "buyer_pending" && `
    background: linear-gradient(89.56deg, #D67A31 0.36%, #F0A468 129.5%);
    text-shadow: 0px 1px 1.4px rgba(43, 0, 0, 0.70);
  `}

  ${props => props.status === "completed" && `
    background: linear-gradient(89.56deg, #48C376 0.36%, #87E8AB 129.5%);
    color: black;
  `}

  ${props => props.status === "void" && `
    background: linear-gradient(89.56deg, #db3b3b 0.36%, #d91c1c 129.5%);
    text-shadow: 0px 1px 1.4px rgba(43, 0, 0, 0.70);
  `}

`

export const StatusTitle = styled.p`
  margin: 0;
  font-size: 10px;
  font-family: ${INTER};
  font-family: 500;

  ${props => props.status === "pending" && `
    color: #FDFDFD;
  `}

  ${props => props.status === "buyer_pending" && `
    color: #FDFDFD;
  `}

  ${props => props.status === "completed" && `
    color: #252529;
  `}

  ${props => props.status === "void" && `
    color: #FDFDFD;
  `}
`

export const DocContent = styled.div`
  margin-bottom: 20px;
`

export const DocWrap = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #E5E6FF;
`

export const DocTitle = styled.p`
  margin: 0;
  font-weight: ${props => props.fw || "200"}
`

export const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.justifyEnd && `
    justify-content: flex-end;
  `}
  // gap: 50%;
`

export const DateContentWrap = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const DateCreatedContent = styled.p`
  margin: 0;
  font-size: 12px;
  font-family: ${INTER};
  font-weight: 500;
`

export const DateContent = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${INTER};
  font-weight: 500;
`

export const AdditionalDropdown = styled.ul`
  position: absolute;
  right: 0;
  top: 35px;
  display: flex;
  width: 219px;
  height: max-content;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  list-style: none;
  flex-shrink: 0;
  z-index: 9;

  border-radius: 8px;
  background: var(--Color-Neutral-50, #FDFDFD);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const AdditionalDropdownLI = styled.li`
 display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);

  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    margin: 0;
  }
`
const Image = styled.img``
