import styled from "styled-components"
import { Input, Checkbox, Select } from "antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  padding-top: 84px;
  overflow-y: hidden;
  overflow: scroll;
  height: 100vh;
`

export const ContentWrap = styled.div`
  display: flex;
  height: 100vh;
  position: relative;

  @media(max-width: 767px) {
    height: 100%;
  }
`

export const LeftWrap = styled.div`
  background: #F6F7FF;
  width: ${props => props.width || "50%"};
  display: flex;
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "center"};
  padding-top: ${props => props.paddingTop || "0px"};

  @media(max-width: 1280px) {
    display: none;
  }

  @media(min-width: 1680px) {
    justify-content: center;
  }
`

export const LeftImage = styled.img`

  @media(max-width: 1440px) {
    position: absolute;
    top: ${props => props.top || "0"};
  }
`

export const RightWrap = styled.div`
  width: ${props => props.width || "50%"};
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || "center"};
  padding: ${props => props.padding || "0px 133px 0px 133px"};
  gap: 32px;

  @media(max-width: 1280px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 133px;
  }

  @media(max-width: 767px) {
    padding: 0px;
  }
`

export const WelcomeContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin-top: ${props => props.marginTop || "0px"}

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 9px !important;
    border: 1px solid #5A5FF2 !important;
  }

  @media(max-width: 1280px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  ${props => props.downloadApp && `
    @media(min-width: 1680px) {
      align-items: flex-end;
      text-align: right;
      justify-content: right;
    }
  `}
`

export const WelcomeTitle = styled.h1`
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  margin: 0;
  width: ${props => props.width || "445px"};
  // height: 75px;
  flex-shrink: 0;

  @media(max-width: 767px) {
    width: fit-content;
  }
`

export const WelcomeDescription = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${INTER};
  font-size: ${props => props.fontSize || "18px"};
  font-style: normal;
  font-weight: 300;
  line-height: 141%;
  margin: 0;
  margin-top: ${props => props.marginTop || "10px"};
  margin-right: ${props => props.marginRight || "0px"};
  width: ${props => props.width || "434px"};
  height: fit-content;

  @media(max-width: 767px) {
    width: fit-content;
  }

  ${props => props.downloadApp && `
    @media(min-width: 1680px) {
      width: 247px;
    }
  `}
`
export const UnderlinedText = styled.p`
  margin: 0;
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 141%;
  text-decoration-line: underline;
`
export const InputWrap = styled(Input)`
  margin-top: 20px !important;
  width: 350px !important;
  .ant-input {
    border-radius: 9px !important;
    border: 1px solid #5A5FF2 !mportant;
  }
`
export const GoogleLoginButton = styled.button`
  display: inline-flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 350px;
  height: 45px;

  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: ${props => props.buttonFS || "15px"};
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 24.5px;
  background-color: #FDFDFD;
  border: none;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
`

export const MiddleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const StepsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
`

export const StepsContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const StepTitle = styled.p`
  margin: 0;
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${props => props.fontFamily || "'${MONTSERRAT}', sans-serif"};
  font-size: ${props => props.fontSize || "12px"};
  font-style: normal;
  font-weight: ${props => props.fontWeight || "400"};
  line-height: 150.523%; /* 18.063px */
  ${props => props.width && `
    width: ${props.width};
  `}
`

export const StepDescription = styled.h1`
  margin: 0;
  color: var(--color-tertiary, #1C1C1F);
  font-family: "${MONTSERRAT}", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`

export const GetStartedWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  ${props => props.noHide && `
    @media(max-width: 767px) {
      display: flex !important;  
      align-items: center;
    }
  `}

  @media(max-width: 767px) {
    display: none;
  }


  ${props => props.downloadApp && `
    @media(min-width: 1680px) {
      align-items: flex-end;
    }
  `}
`

export const GetStarted = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 141%; /* 22.56px */
  margin: 0;
`

export const BottomText = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.523%; /* 18.423px */
  width: 383px;
  height: 27px;
  flex-shrink: 0;
`

export const Underlined = styled.span`
  color: #5A5FF2;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.523%;
  text-decoration-line: underline;
`
export const ScheduleFeeWrap = styled.div`
  width: 351px;
  height: max-content;
  flex-shrink: 0;
  border-radius: 9px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(#5A5FF2, #5A5FF2, #FFAB55) border-box;
  border: 1px solid transparent;
  padding: 27px 20px;
`
export const FeeContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.3px solid #C7C9FF;;
  padding: 10px 0px;

  &:last-child {
    border-bottom: none;
    padding: 10px 0px 0px 0px;
  }
  &:first-child {
    padding: 0px 0px 10px 0px;
  }
`

export const ScheduleFeeSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const ScheduleFeeContent = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
  text-transform: capitalize;
  margin: 0;
`

export const FeeContentSmall = styled.p`
  margin: 0;
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 9.031px */
  letter-spacing: 1.26px;
  text-transform: uppercase;
`

export const SignupButtons = styled.button`
  display: inline-flex;
  padding: 16px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: ${props => props.width || "155px"};

  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: ${props => props.buttonFS || "15px"};
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 33.5px;
  background-color: ${props => props.disabled ? "#8486C3" : "#5A5FF2"};

  border: none;

  ${props => props.disabled && `
    cursor: not-allowed;
  `}

  ${props => !props.disabled && `
    transition: 0.7s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  `}

  ${props => props.height && `
    height: ${props.height};
  `}
`
export const CheckboxElement = styled(Checkbox)`

`

export const SelectElement = styled(Select)`
  margin-top: 20px !important;
  width: 350px !important;
`
export const ICAButtons = styled.button`
display: inline-flex;
padding: 16px 33px;
justify-content: center;
align-items: center;
gap: 10px;
cursor: pointer;

color: #FFF;
font-family: ${MONTSERRAT};
font-size: ${props => props.buttonFS || "15px"};
font-style: normal;
font-weight: 500;
line-height: normal;

border-radius: 33.5px;
background-color: ${props => props.disabled ? "#8486C3" : "#5A5FF2"};

border: none;

${props => props.disabled && `
  cursor: not-allowed;
`}

${props => !props.disabled && `
  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`}

${props => props.height && `
  height: ${props.height};
`}
`
