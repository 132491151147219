import axios from "axios"

// client APIs
export const getClientAPI = clientId => axios
  .get(`${API_V3_URL}clients${clientId}`)

export const updateClientAPI = (clientId, payload) => axios
  .patch(`${API_V3_URL}clients/${clientId}`, payload)

export const addClientAPI = payload => axios
  .post(`${API_V3_URL}clients`, payload)

// transaction APIs
export const createClientTransactionAPI = payload => axios
  .post(`${API_V3_URL}client-transaction`, payload)

export const updateClientTransactionAPI = (transactionId, payload) => axios
  .patch(`${API_V3_URL}client-transaction/${transactionId}`, payload)

export const getClientTransactionsAPI = query => axios
  .get(`${API_V3_URL}client-transaction${query}`)

export const addPropertyDetailsAPI = payload => axios
  .post(`${API_URL}user/property`, payload)

export const updatePropertyDetailsAPI = (propertyId, payload) => axios
  .put(`${API_URL}user/property/${propertyId}`, payload)

export const getPropertyDetailsAPI = (propertyId = "") => axios
  .get(`${API_URL}user/property/${propertyId}`)

export const addBuyerRequirementsAPI = payload => axios
  .post(`${API_V2_URL}requirements`, payload)

export const editBuyerRequirementsAPI = (id, payload) => axios
  .put(`${API_V2_URL}requirements/${id}`, payload)

export const getBuyerRequirementsAPI = clientId => axios
  .get(`${API_V2_URL}requirements?u_cust_id=${clientId}`)

export const getProprtyTypesAPI = () => axios
  .get(`${API_URL}property/types`)

export const getClientFilterSkeleton = () => axios
  .get(`${API_V3_URL}client/filter-skeleton`)

//reminders

export const getRemindersAPI = id => axios
  .get(`${API_V2_URL}reminders/${id}`)

export const fetchClientReminderAPI = clientId => axios
  .get(`${API_V2_URL}client/${clientId}/reminders`)

// export const createRemindersAPI = id => axios
//   .post(`${API_URL}client/${id}/reminders`)

export const createReminderAPI = (clientId, payload) => axios
  .post(`${API_V2_URL}client/${clientId}/reminders`, payload)

export const updateReminderAPI = (client_id, reminder_id, payload) => axios
  .patch(`${API_URL}client/${client_id}/reminders/${reminder_id}`, payload)

export const addNoteAPI = (clientId, payload) => axios
  .post(`${API_URL}client/${clientId}/notes`, payload)

export const deleteReminderAPI = (clientId, id) => axios
  .delete(`${API_URL}client/${clientId}/reminders/${id}`)

export const updateNoteAPI = (clientId, noteId, payload) => axios
  .put(`${API_URL}client/${clientId}/notes/${noteId}`, payload)

export const getNoteAPI = query => axios
  .get(`${API_URL}transaction-notes/${query}`)

export const deleteNoteAPI = (clientId, noteId) => axios
  .delete(`${API_URL}client/${clientId}/notes/${noteId}`)

export const getTimelineAPI = transactionId => axios
  .get(`${API_V3_URL}client-transaction-timeline/${transactionId}`)
export const getBrokerageTransactionStatusAPI = transactionId => axios
  .get(`${API_URL}brokerage-transaction-status?transactionId=${transactionId}`)

export const addBrokerageTransactionAPI = (transactionId, payload) => axios
  .post(`${API_URL}add-brokerage-transaction?transactionId=${transactionId}`, payload)

export const getFinancingForTransactionAPI = transactionId => axios
  .get(`${API_V3_URL}client-transaction-finance/${transactionId}`)

export const createFinancingForTransactionAPI = (transactionId, payload) => axios
  .post(`${API_V3_URL}client-transaction-finance/${transactionId}`, payload)

export const updateFinancingForTransactionAPI = (transactionId, payload) => axios
  .put(`${API_URL}mortgage/clients-properties/${transactionId}`, payload)

export const toggleAgentFinancePreferenceAPI = value => axios
  .put(`${API_V3_URL}client-transaction-finance/toggle-preference/${value}`)

export const addCoClientDetailsAPI = payload => axios
  .post(`${API_V3_URL}client-transaction-co-client`, payload)

export const updateCoClientDetailsAPI = (payload, id) => axios
  .put(`${API_V3_URL}client-transaction-co-client/${id}`, payload)

export const deleteCoClientDetailsAPI = (id, params) => axios
  .delete(`${API_V3_URL}client-transaction-co-client/${id}?${params}`)

export const updateContractCRMAPI = (dealId, payload) => axios
  .put(`${API_URL}update-contract/${dealId}`, payload)

export const archiveLeadAPI = payload => axios
  .post(`${API_V3_URL}client-transaction/archive`, payload)

export const unArchiveLeadAPI = payload => axios
  .post(`${API_V3_URL}client-transaction/un-archive`, payload)

export const deleteClientTransactionAPI = (transactionId, soft_delete) => axios
  .delete(`${API_V3_URL}client-transaction/${transactionId}?soft_delete=${soft_delete}`)

export const getAgentInformationAPI = (agentId, state) => axios
  .get(`${API_URL}fetch-agent-info-coversheet?state=${state}&agentId=${agentId}`)
