import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import root from "window-or-global"
import {
  preTabletWidth,
  preDesktopWidth,
  minDesktopWidth,
  smallMobileMaxWidth,
  minMediumDesktopWidth,
  minExtraLargeDesktopWidth,
} from "shared/consts"
import {
  validateEmail,
} from "services/formUtils"
import { withRouter } from "react-router-dom"
import HeroImage from "images/Graphics/HomeV6/CommunityBG-min.png?q=70&h=700&w=1200&fm=webp"
import Person from "images/Graphics/HomeV6/RadiusAgentHeroPerson.png?q=60&h=700&w=750&fm=webp"
import PersonMobile from "images/Graphics/HomeV6/Pricing/AgentMobileSVG.svg?q=100"
import NewLeadPng from "images/Graphics/HomeV6/HeroPersonHelper.png?q=100"
import TabletLeadPng from "images/Graphics/HomeV6/TabletYouHaveNewLead.png?q=100"
import BlueTick from "images/Graphics/HomeV6/CheckmarkTransparent.svg"
// import HeroBG from "images/Graphics/CommunityNew.png?q=100"
import { ATC } from "@fonts"

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  ${preDesktopWidth`
    overflow: hidden;
  `}

  ${preTabletWidth`
    width: 100vw;
    flex-direction: column-reverse;
  `}
`

const PersonWrap = styled.div`
  height: 500px;
  width: 300px;
  background: url(${PersonMobile});
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: 1024px) {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background: url(${Person});
    height: 693px;
    width: 745px;
  }

  ${preDesktopWidth`
    height: 784px;
    width: 590px;
  `}

  ${preTabletWidth`
    height: 500px;
    width: 250px;
  `}
`

const DesktopAndAboveNewLead = styled.img`
  display: block;
  height: 61px;
  position: absolute;
  bottom: 294px;
  right: 0;

  @media(max-width: 1099px) {
    display: none;
  }
`

const PreDesktopImgNewLead = styled.img`
  display: none;

  ${preDesktopWidth`
    display: block;
    position: absolute;
    bottom: 334px;
    right: 207px;
  `}

  ${preTabletWidth`
    display: none
  `}
`

const InnerWrap = styled.div`
  height: 600px;
  width: 100%;
  border-radius: 0px;
  background: url(${HeroImage});
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 85px;
  padding-left: 80px;
  position: relative;

  ${minDesktopWidth`
    height: 684px;
  `}

  ${preDesktopWidth`
    padding-left: 60px;
    background-size: cover;
    height: 756px;
  `}

  ${preTabletWidth`
    padding-left: 30px;
    height: 732px;
    background-size: cover;
  `}
`

const SkewWrap = styled.div`
  height: 648.7998046875px;
  width: 40%;
  background-image: url(${HeroImage});
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 360px;
  position: absolute;
  border-bottom-left-radius: 360px;
  right: 5px;
  opacity: 0;
  background-position-x: -199px;

  ${minDesktopWidth`
    background-position-x: -260px;
    right: 0px;
  `}

  ${minMediumDesktopWidth`
    right: -4px;
  `}

  ${minExtraLargeDesktopWidth`
    background-position-x: 2px;
  `}

  ${preDesktopWidth`
    height: 431.72076416015625px;
    width: 895.5687866210938px;
    position: relative;
    top: 103px;
  `}

  ${preTabletWidth`
    height: 421.6601104736328px;
    width: 100%;
    top: 0px;
    position: relative;
    left: 57px;
  `}

  animation: showUp 0.7s forwards;

  @keyframes showUp {
    0%{
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

const HeaderText = styled.div`
  font-family: Butler, DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.dreamWhite};
  width: 629px;

  ${minDesktopWidth`
    width: 629px;
  `}

  ${preDesktopWidth`
    font-size: 36px;
    line-height: 46px;
    width: 317px;
  `}

  ${preTabletWidth`
    width: 100%;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    width: 335px;
  `}
`

const CheckPointWrap = styled.div`
  margin-top: 33px;
  width: 500px;
`

const CheckPointHeaderText = styled.h2`
  font-family: ${ATC}, DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.dreamWhite};

  ${preDesktopWidth`
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
  `}

  ${preTabletWidth`
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  `}
`

const ListUL = styled.div`
  width: 269px;
  height: 133px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px 23px;

  ${minDesktopWidth`
    width: 400px;
    height: 221px;
    align-items: baseline;
    flex-wrap: wrap;
  `}

  ${minMediumDesktopWidth`
    height: 142px;
  `}

  ${preDesktopWidth`
    height: 294px;
  `}

  ${preTabletWidth`
    height: 334px;
  `}
`

const ListLI = styled.div`
  display: flex;
  align-items: baseline;
`
const ListText = styled.p`
  font-family: ${ATC}, DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.dreamWhite};
  margin-left: 9px;

  @media(min-width: 1100px) {
    width: ${props => props.minDesktopWidth || "auto"};
  }

  @media(min-width: 1280px) {
    width: ${props => props.customWidth || "max-content"};
  }

  @media(max-width: 1099px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

    ${props => props.customPreDesktopWidth && `
      width: ${props.customPreDesktopWidth};
    `}
  }

  @media(max-width: 767px) {
    font-size: 16px;
    ${props => props.customPreTabletWidth && `
      width: ${props.customPreTabletWidth};
    `}
  }
`

const BlueTicketImg = styled.img`
  position: relative;
  top: 3px;
  filter: invert(7);

  ${preDesktopWidth`
    top: 4px;
  `}
`

const CTAWrap = styled.div`
  z-index: 2;
  position: relative;

  ${minDesktopWidth`
    margin-top: 20px;
  `}

  ${preDesktopWidth`
      position: absolute;
      width: max-content;
  `}
`

const CheckPointListWrap = styled.div``

const InputBox = styled.input`
  height: 60px;
  width: 497px;
  border-radius: 0px;
  border: 1px solid ${colors.marineBLue};
  padding: 16px;

  ${preTabletWidth`
    width: 331px;
  `}

  ${smallMobileMaxWidth`
    width: 281px;
  `}

  &::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 3;
  }
`

const InputCTAButton = styled.button`
  font-family: ${ATC};
  font-size: 16px;
  width: 64px;
  border-radius: 0px;
  background: ${colors.marineBLue};
  color: ${colors.dreamWhite};
  border: none;
  position: relative;
  right: 71px;
  top: 1px;
  height: 47px;
  cursor: pointer;
  z-index: 99;

  ${props => props.disabled && `
    cursor: not-allowed;
  `}

  ${preDesktopWidth`
    font-size: 16px;
  `}
`

const ErrorWrap = styled.div`
  position: absolute;
  top: 67px;

  p {
    font-family: ${ATC};
    color: ${colors.dreamWhite};
    font-size: 14px;
  }
`

const Header = ({
  history,
}) => {
  const [url, setUrl] = useState("")
  const [isError, setError] = useState(false)
  return (
    <Wrap>
      <InnerWrap>
        <HeaderText>
          A nationwide real estate network with endless possibilities.
        </HeaderText>
        <CheckPointWrap>
          <CheckPointHeaderText>Free access to:</CheckPointHeaderText>
          <CheckPointListWrap>
            <ListUL>
              <ListLI>
                <BlueTicketImg src={BlueTick} />
                <ListText>Lead Marketplace</ListText>
              </ListLI>
              <ListLI>
                <BlueTicketImg src={BlueTick} />
                <ListText>Referral Exchange</ListText>
              </ListLI>
              <ListLI>
                <BlueTicketImg src={BlueTick} />
                <ListText minDesktopWidth="215px">Business Opportunities</ListText>
              </ListLI>
              <ListLI>
                <BlueTicketImg src={BlueTick} />
                <ListText
                  minDesktopWidth="223px"
                  customPreDesktopWidth="228px"
                  customPreTabletWidth="117px"
                >
                  Education and Coaching
                </ListText>
              </ListLI>
              <ListLI>
                <BlueTicketImg src={BlueTick} />
                <ListText
                  customWidth="235.71px"
                  minDesktopWidth="197px"
                  customPreDesktopWidth="200px"
                  customPreTabletWidth="166px"
                >
                  Network of 80,000 local and national agents
                </ListText>
              </ListLI>
            </ListUL>
          </CheckPointListWrap>
        </CheckPointWrap>
        <CTAWrap>
          <InputBox
            placeholder="Email"
            type="email"
            onChange={(e) => {
              setUrl(e.target.value)
              const isValid = validateEmail(e.target.value)
              if (!isValid || isValid === null) {
                setError(true)
              } else {
                setError(false)
              }
            }}
          />
          {isError && (
            <ErrorWrap>
              <p>Enter Valid Email</p>
            </ErrorWrap>
          )}
          <InputCTAButton
            disabled={isError || !url}
            onClick={() => {
              if (root && root.heap) {
                root.heap.track("Click_Sign_Up_Cta_Header_Email")
              }
              history.push(`/signup?email_id=${url}`)
            }}
          >
            Join
          </InputCTAButton>
        </CTAWrap>
      </InnerWrap>
      <PersonWrap>
        <DesktopAndAboveNewLead src={NewLeadPng} alt="radius_new_leads.png" />
        <PreDesktopImgNewLead src={TabletLeadPng} alt="radius_new_leads_tablet.png" />
      </PersonWrap>
    </Wrap>
  )
}

export default withRouter(Header)
