import React from "react"
import ListingsV2Container from "container/ListingsV2"
import {
  Wrap,
  TabWrap,
  TabInfo,
  TabTitle,
} from "./styles"

const ListingsTabs = ({
  currentTab,
  toggleTab,
  steps,
  tabWidth,
}) => {
  return (
    <Wrap>
      <TabWrap width={tabWidth}>
        {steps && steps.map(step => (
          <TabInfo onClick={() => toggleTab(step.value)} isActive={currentTab === step.value}>
            <TabTitle isActive={currentTab === step.value}>
              {step.label}
            </TabTitle>
          </TabInfo>
        ))}
      </TabWrap>
    </Wrap>
  )
}

export default ListingsV2Container(ListingsTabs)
