import React, { useEffect, useState } from "react"
import container from "container/TechAssets"
import root from "window-or-global"
import { RADIUS_STATES } from "shared/RADIUS_STATES"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import CookiesStorage from "services/CookieStorage"
import colors from "@colors"
import AppContainer from "container/App"
import applyFlowContainer from "container/App/applyFlowContainer"
import { Form } from "@ui/antd"
import successConfetti from "./successConfetti.png"
import {
  Wrap,
  StyledModal,
  ModalTitle,
  ModalSelect,
  SubmitButton,
} from "./styles"

const I_WANT_TO_OPTIONS = [
  { value: "Start my own team / brokerage", label: "Start my own team / brokerage", production: "group" },
  { value: "Up-level my existing team / brokerage", label: "Up-level my existing team / brokerage", production: "group" },
  { value: "Join a high-performing team", label: "Join a high-performing team", production: "annual" },
  { value: "Create a solo brand", label: "Create a solo brand", production: "annual" },
]

const ANNUAL_PRODUCTION = [
  { value: "$1M or less", label: "$1M or less" },
  { value: "$1M - $2.9M", label: "$1M - $2.9M" },
  { value: "$3M - $10M", label: "$3M - $10M" },
  { value: "$11M - $25M", label: "$11M - $25M" },
  { value: "+$25M", label: "+$25M" },
]

const GROUP_PRODUCTION = [
  { value: "$5M-$10M", label: "$5M-$10M" },
  { value: "$10-$20M", label: "$10M-$20M" },
  { value: "$20M-$50M", label: "$20M-$50M" },
  { value: "$50M-$100M", label: "$50M-$100M" },
  { value: "$100M", label: "$100M+" },
]

const NetworkAgentModal = ({
  showNetworkAgentPopup,
  toggleNetworkAgentPopup,
  modalPage,
  toggleModalPage,
  submitInterestForm,
  user,
  applyInterestsResponse,
}) => {
  useEffect(() => {
    if (root.dataLayer) {
      const payload = {
        event: "annual_production_popup_viewed",
        agent_id: agentId,
        ag_nm: `${firstname} ${lastname}`,
        ag_em: email,
        agent_type: isSubscribedToBrokerPlan ? isPartOfBrokerageTeam ? "client" : "client" : "community_member",
        class_name: "annual_production_popup_viewed",
      }
      root.dataLayer.push(payload)
    }
  }, [])
  const agentId = CookiesStorage.load("agentId")
  const { isFetching } = applyInterestsResponse || {}
  const {
    firstname,
    lastname,
    email,
    phone,
    isSubscribedToBrokerPlan,
    brokerageTeamId,
    isBrokerageTeamMember,
  } = user || {}

  const isPartOfBrokerageTeam = user
      && parseInt(brokerageTeamId, 10) > 0 || isBrokerageTeamMember

  const [prod, selectedProd] = useState("")

  const ANNUAL = ["Join a high-performing team", "Create a solo brand"]

  const getModalPage = (page) => {
    switch (page) {
      case "success":
        return (
          <Wrap>
            <img src={successConfetti} alt="Success Confetti" />
            <ModalTitle
              fontSize="20px"
              textAlign="start"
            >
              Success! Your Radius profile has been updated.
            </ModalTitle>
            <SubmitButton
              htmlType="button"
              onClick={() => {
                toggleNetworkAgentPopup(false)
              }}
            >
              Close
            </SubmitButton>
          </Wrap>
        )
      default:
        return (
          <Wrap>
            <ModalTitle
              textAlign="start"
            >
              Welcome back! Please complete your profile below:
            </ModalTitle>
            <Form
              onFinish={handleSubmit}
              onValuesChange={handleChange}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item
                rules={rules}
                label="State"
                name="state"
              >
                <ModalSelect
                  options={RADIUS_STATES}
                  placeholder="State"
                />
              </Form.Item>
              <Form.Item
                rules={rules}
                label="Role"
                name="account_type"
                onChange
              >
                <ModalSelect
                  options={I_WANT_TO_OPTIONS}
                  placeholder="I want to"
                  value={prod}
                  onChange={value => selectedProd(value)}
                />
              </Form.Item>
              <Form.Item
                rules={rules}
                name="annual_production"
                label="Agent production"
              >
                <ModalSelect
                  options={ANNUAL.includes(prod) ? ANNUAL_PRODUCTION : GROUP_PRODUCTION}
                  placeholder={ANNUAL.includes(prod) ? "Annual production" : "Agent production"}
                />
              </Form.Item>
              <Form.Item>
                <SubmitButton
                  htmlType="submit"
                  disabled={disabled || isFetching}
                >
                  {isFetching ? (
                    <ThreeDotLoader color={colors.white} />
                  ) : (
                    "Submit"
                  )}
                </SubmitButton>
              </Form.Item>
            </Form>
          </Wrap>
        )
    }
  }

  const [disabled, setDisabled] = useState(true)

  const rules = [{
    required: true,
    message: "Required",
  }]

  const handleChange = (changedValue, allValues) => {
    const isDiabled = allValues.state && allValues.account_type && allValues.annual_production
    if (isDiabled) {
      setDisabled(false)
    }
  }

  const handleClose = () => {
    toggleNetworkAgentPopup(false)
    CookiesStorage.save("networkAgentBannerStatus", true)
    if (root.dataLayer) {
      const payload = {
        agent_id: agentId,
        agent_type: isSubscribedToBrokerPlan ? isPartOfBrokerageTeam ? "client" : "client" : "community_member",
        ag_nm: `${firstname} ${lastname}`,
        ag_eml: email,
        event: "annual_production_popup_skipped",
        class_name: "annual_production_popup_skipped",
      }
      root.dataLayer.push(payload)
    }
  }

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      firstname,
      lastname,
      email,
      phone,
      agency_name: "",
      number_of_transactions_last_year: 0,
      source: "Network Popup Web",
    }
    const interestFormObject = {
      agent_id: agentId,
      email,
      name: `${firstname} ${lastname}`,
      phone,
      state: values.state,
      source: "Network Popup Web",
      agency_name: "",
      account_type: values.account_type,
      agent_type: isSubscribedToBrokerPlan ? isPartOfBrokerageTeam ? "client" : "client" : "community_member",
      annual_production: values.annual_production,
      event: "annual_production_popup_submitted",
    }
    submitInterestForm({
      payload,
      callback: () => toggleModalPage("success"),
      interestFormObject,
      webClassName: "Annual_Production_Popup_Submitted",
    })
  }

  return (
    <StyledModal
      open={showNetworkAgentPopup}
      footer={null}
      onCancel={handleClose}
    >
      {getModalPage(modalPage)}
    </StyledModal>
  )
}

export default container(applyFlowContainer(AppContainer(NetworkAgentModal)))
