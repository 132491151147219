import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { PRODUCTS, RESOURCES } from "./DropdownValues"

const ListWrap = styled.li`
 ${props => !props.isVisible && `
    display: none;
  `}
`
const DropDownUL = styled.ul`
  list-style-type: none;
`

const ContentWrap = styled.div`
 font-size: 14px;
 padding-top: 14px;
 margin-left: 10px;
 width: 210px;
 min-width: 210px;

 p:first-child:hover{
  color: ${colors.primaryColor} !important;
 }
`

const LogoWrap = styled.div`
  point-events: none;
  cursor: none;
  min-width: 20%;
`
const Anchor = styled.a`
display: flex;
text-decoration: none !important;
color: ${colors.contentText} !important;
pointer-events: ${props => props.pointer ? props.pointer : "auto"};
`

const EmptyDiv = styled.div`
  width: 17px;
`

const ContentDescription = styled.p`
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  color: ${colors.contentText};
`
const Label = styled.p`
  margin: 0;
  font-weight: 500;
  ${props => props.isActive && `
     color: ${colors.primaryColor};
   `}
`

const Logo = styled.img`
  height: 30px;
  position: relative;
  top: 15px;
  left: 14px;
  point-events:none;
  cursor: none;
`

const DropDownWrap = styled.div`
  position: absolute;
  display: ${props => props.isVisible ? "flex" : "none"};
  width: 343px;
  height: ${props => props.isResource ? "120px" : "auto"};
  padding-bottom: 18px;
  left: 81.6%;
  top: 103%;
  z-index: 3;
  border-radius: 4px;
  box-shadow: 0 8px 12px 4px rgba(0, 0, 0, 0.18);
  background-color: ${colors.white};
  transition: height 0.6s ease;
  @media (max-width: 1440px) {
     left: 75.6%;
  }
  @media (max-width: 1280px) {
     left: 71.6%;
  }
`

const MenuDropdown = ({
  isVisible,
  currentType,
  toggleDropDown,
}) => {
  const toggleDropDownCallback = (bool) => {
    toggleDropDown(bool)
  }

  const renderDropdownUI = typeArray => (
    typeArray.map(key => (
      <ListWrap
        isVisible={key.visibility}
        key={key.id}
      >
        <Anchor
          pointer={key.pointer}
          href={key.href || ""}
          target={key.target}
        >
          {key.logo ? (
            <React.Fragment>
              <LogoWrap>
                <Logo
                  src={key.logo}
                  alt={key.alt}
                />
              </LogoWrap>
            </React.Fragment>
          ) : (
            <EmptyDiv />
          )}

          <ContentWrap>
            <Label>
              {key.label}
            </Label>
            <ContentDescription>{key.content}</ContentDescription>
          </ContentWrap>
        </Anchor>
      </ListWrap>
    ))
  )

  return (
    <DropDownWrap
      onMouseLeave={(e) => { toggleDropDownCallback(false, e) }}
      isVisible={isVisible}
      isResource={currentType === "resources"}
    >
      <DropDownUL>
        {currentType === "product" ? (
          (renderDropdownUI(PRODUCTS))
        ) : (
          (renderDropdownUI(RESOURCES))
        )}
      </DropDownUL>
    </DropDownWrap>
  )
}

export default MenuDropdown
