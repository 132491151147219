import React from "react"
import {
  Wrap,
  TitleWrap,
  TitleLeftWrap,
  TitleLeft,
  TitleRight,
  ImgWrap,
  Description,
} from "./styles"
import { DividerIcon } from "../../../../icons"

const ProTip = () => {
  return (
    <Wrap>
      <TitleWrap>
        <TitleLeftWrap>
          <ImgWrap>
            <img src={`${CLOUDFRONT}/OFFERS_V2/ProTipBulb.svg`} alt="proTip" />
          </ImgWrap>
          <TitleLeft>
            PRO TIP
          </TitleLeft>
        </TitleLeftWrap>
        <TitleRight>
          Have an MLS ID at your disposal?
        </TitleRight>
        <Description>
          {`Enter it to auto-fill parts of the property and seller sections. 
          Don't have an ID? No Worries. You can manually input the info below.`}
        </Description>
      </TitleWrap>
    </Wrap>
  )
}

export default ProTip
