import React from "react"
import ReferSideBarElement from "dumbComponents/common/InviteFeature/SidebarBlock"
import SidebarStatBox from "dumbComponents/RealtyV3/RecruitAndEarnV2/SidebarStatBox"
import {
  Block,
} from "./commonStyles"

const StatsBoxes = ({
  isBroker,
}) => (
  <Block padding>
    {!isBroker && <ReferSideBarElement />}
    {isBroker && <SidebarStatBox />}
  </Block>
)

export default StatsBoxes
