import * as SearchActions from "container/Search/actions"

export const getSearchedAgents = SearchActions.getSearchedAgents.request
export const selectSearchFilter = SearchActions.selectSearchFilter.action
export const removeSearchFilter = SearchActions.removeSearchFilter.action
export const clearAllSearchFilters = SearchActions.clearAllSearchFilters.action
export const changeFilterSelectedCategory = SearchActions.changeFilterSelectedCategory.action
export const showMoreFiltersForCategory = SearchActions.showMoreFiltersForCategory.action
export const toggleFilterPanel = SearchActions.toggleFilterPanel.action
export const removeAndApplySearchFilter = SearchActions.removeAndApplySearchFilter.action
export const updateAppliedFiltersPayload = SearchActions.updateAppliedFiltersPayload.action
export const updateSingleFilterPayload = SearchActions.updateSingleFilterPayload.action
export const removeAndApplySingleFilter = SearchActions.removeAndApplySingleFilter.action
export const getClientsByName = SearchActions.getClientsByName.request
