import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import app from "container/App"
import root from "window-or-global"
import copyToClipboard from "copy-to-clipboard"
import { toast as createToast } from "react-toastify"
import LoaderButton from "@ui/LoaderButton"
import FacebookIcon from "images/Graphics/RadiusJobsAssets/Facebook.svg"
import TwitterIcon from "images/Graphics/RadiusJobsAssets/Twitter.svg"
import LinkedInIcon from "images/Graphics/RadiusJobsAssets/LinkedIn.svg"
import {
  maxLargeMobile,
  L_MAX_MOBILE_BREAK_POINT_LIMIT,
} from "@consts"
import { authCheck } from "services/auth"
import CreateEditPostContainer from "container/Network/CreateEditPost"

//Social media share buttons
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share"
import { withRouter } from "react-router-dom"
import CookiesStorage from "services/CookieStorage"
import { handleDataLayerPush } from "services/dataLayerUtil"

const linkify = require("linkify-it")()

const ClickDiv = styled.div`
  display: flex;
`

const Wrap = styled.div`
  height: max-content;
`

const InnerWrap = styled.div`
  height: max-content;
  width: 100%; 
  border-bottom: 1px solid ${colors.dodoGrey};
  ${props => props.noBorder && `
    border-bottom: none;
  `}
`

const ShareWrap = styled.div`
  margin-bottom: 26px;
  display: flex;
  ${maxLargeMobile(`
    flex-wrap: wrap;
    justify-content: space-between;
  `)}
`

const SocialMediaIcon = styled.img`
  height: 20px;
  position: relative;
  right: -1px;
`

const FBShare = styled(FacebookShareButton)`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 12px;
  border: 1px solid ${colors.moonGrey};
  margin-right: 15px;
  cursor: pointer;
  ${maxLargeMobile(`
    width: 80px;
    border-radius: 0;
    text-align: center;
  `)}
`

const TwitterShare = styled(TwitterShareButton)`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 12px 9px;
  border: 1px solid ${colors.moonGrey};
  margin-right: 15px;
  cursor: pointer;
  ${maxLargeMobile(`
    width: 80px;
    border-radius: 0;
    text-align: center;
  `)}
`

const LinkedinShare = styled(LinkedinShareButton)`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 10px 9px;
  border: 1px solid ${colors.moonGrey};
  cursor: pointer;
  ${maxLargeMobile(`
    width: 80px;
    border-radius: 0;
    text-align: center;
  `)}
`
const SubTitle = styled.h2`
  font-family: ${ATC};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px 0 16px;
  color: ${colors.black};
`

const ActionWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 16px;
`

const LinkInput = styled.div`
  display: flex;
  background: ${colors.dreamWhite};
  border-radius: 8px;
  padding-top: 13px;
  padding-left: 10px;
  overflow: hidden;
  width: 70%;
  p {
    flex: 1;
    border-radius: 4px;
    color: ${colors.black};
    font-weight: 300;
    font-size: 16px;
    word-break: break-all;
    white-space: nowrap;
  }
`

const Title = styled.h3`
  display: flex;
  font-weight: 400;
  margin: 0;
  font-size: 36px;
  text-align: left;
  width: 387px;
  line-height: 45px;
`

const toastConfig = {
  position: "top-left",
  autoClose: 2500,
  hideProgressBar: true,
  className: "blue-background",
}

//GENERIC SHARE MODAL, BEFORE MAKING ANY CHANGES CONTACT ABHINAV 😎

const getName = (name) => {
  if (!name) return ""
  const splitName = name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ")
  return splitName.join("-")
}

const ShareModal = ({
  scheduleRooms,
  isVideoRoomsModal,
  roomId,
  createEditTextChange,
  getSnippetData,
  snippetURL,
  name,
  togglePostInRadiusFeed,
  toClose,
  isShowPostInRadiusFeed,
  history,
  customUrl,
  customText,
  user: userData,
  data: eventData,
  date,
}) => {
  const title = isVideoRoomsModal || scheduleRooms ? "Share this Room to your community" : ""
  const subtitle = isVideoRoomsModal || scheduleRooms ? "Share on Social" : ""
  const url = isVideoRoomsModal ? `${RADIUS_URL}/rooms/${roomId}/live/${getName(name) || ""}` : scheduleRooms
    ? `${RADIUS_URL}/scheduledrooms/${roomId}/${getName(name)}` : ""
  const isMobile = root.innerWidth <= L_MAX_MOBILE_BREAK_POINT_LIMIT
  const handleCopy = (e) => {
    e.stopPropagation()

    copyToClipboard(customUrl || url)

    createToast("Invite Link Copied", toastConfig)

    console.log(eventData)

    const payload = {
      room_date: date,
      hosted_by: eventData && eventData.host ? `${eventData.host.firstname} ${eventData.host.lastname || ""}` : "",
      speakers: "",
      room_type: "upcoming",
      room_name: eventData && eventData.name,
    }
  
    const speakerFilter = eventData && eventData.invited_attendees && eventData.invited_attendees.filter(speakersRole => speakersRole.Role === "speaker")
      .map(speakers => `${speakers.firstname} ${speakers.lastname}`) || null

    if (speakerFilter && speakerFilter.length > 0) {
      payload.speakers = speakerFilter.join(",")
    }
    handleDataLayerPush(userData, "room_shared", "copy_link", payload)
  }

  const getQuotes = () => {
    if (roomId && url) {
      return `Join our rooms. Please join by clicking here - ${url}`
    }
    return customUrl || url
  }

  const handleRadiusPost = () => {
    //TODO - TO MAKE RAW TEXT GENERIC, PASS IN PROPS WHICH CHECKS FOR THAT PARTICULAR CONDITION
    //JUST LIKE ABOVE EXAMPLES
    const rawText = customText && customUrl ? `${customText} ${customUrl}` : `Join our rooms. Please join by clicking here - ${url}`
    createEditTextChange(rawText)

    const match = linkify.match(rawText)
    if (match && match[0].url !== snippetURL) {
      getSnippetData({
        url: match[0].url,
      })
    }
    togglePostInRadiusFeed(true)
    toClose()
  }

  const isLogged = authCheck()

  //TODO - ADD RADIUS POST AFTER WE GET DESIGNS

  const payload = {
    room_date: date,
    hosted_by: eventData && eventData.host ? `${eventData.host.firstname} ${eventData.host.lastname || ""}` : "",
    speakers: "",
    room_type: "upcoming",
    room_name: eventData && eventData.name,
  }

  const speakerFilter = eventData && eventData.invited_attendees && eventData.invited_attendees.filter(speakersRole => speakersRole.Role === "speaker")
    .map(speakers => `${speakers.firstname} ${speakers.lastname}`) || null

  if (speakerFilter && speakerFilter.length > 0) {
    payload.speakers = speakerFilter.join(",")
  }

  return (
    <Wrap>
      <InnerWrap>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <ShareWrap
          onClick={(e) => {
            e.stopPropagation()
            handleDataLayerPush(userData, "room_share_with_others", "", payload)
          }}
        >
          {isLogged ? (
            <LoaderButton
              bsStyle="marineBLueOutlined"
              padding="5px 14px"
              height={isMobile ? "42px" : "auto"}
              borderRadius="0px"
              width={isMobile ? "100%" : "auto"}
              margin={isMobile ? "10px 0 20px" : "0 20px 0 0"}
              loaderOption={{
                color: colors.purpleHeart,
              }}
              onClick={() => handleRadiusPost(customUrl)}
            >
              Post in Radius Feed
            </LoaderButton>
          ) : (
            <LoaderButton
              bsStyle="marineBLueOutlined"
              padding="5px 14px"
              height={isMobile ? "42px" : "auto"}
              borderRadius="0px"
              width={isMobile ? "100%" : "auto"}
              margin={isMobile ? "10px 0 20px" : "0 20px 0 0"}
              loaderOption={{
                color: colors.purpleHeart,
              }}
              onClick={() => {
                CookiesStorage.save("redirectUrl", root.location.href)
                history.push("/signup")
              }}
            >
              Sign Up
            </LoaderButton>
          )}
          <ClickDiv
            onClick={(e) => {
              e.stopPropagation()
              handleDataLayerPush(userData, "room_share_with_others", "", payload)
            }}
          >
            <FBShare url={customUrl || url} quote={getQuotes({})}>
              <SocialMediaIcon src={FacebookIcon} alt="facebookIcon.png" />
            </FBShare>
            <TwitterShare url={customUrl || url} quote={getQuotes({})}>
              <SocialMediaIcon src={TwitterIcon} alt="facebookIcon.png" />
            </TwitterShare>
            <LinkedinShare url={customUrl || url} quote={getQuotes({})}>
              <SocialMediaIcon src={LinkedInIcon} alt="facebookIcon.png" />
            </LinkedinShare>
          </ClickDiv>
        </ShareWrap>
      </InnerWrap>
      <InnerWrap noBorder>
        <SubTitle>Or copy link</SubTitle>
        <ActionWrap>
          {!isMobile && (
            <LinkInput>
              <p title={customUrl || url}>
                {customUrl || url}
              </p>
            </LinkInput>
          )}
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="5px"
            width={isMobile ? "100%" : "108px"}
            height="42px"
            borderRadius="0px"
            margin={isMobile ? "10px 0 0" : "0px"}
            loaderOption={{
              color: colors.purpleHeart,
            }}
            onClick={handleCopy}
          >
            Copy Link
          </LoaderButton>
        </ActionWrap>
      </InnerWrap>
    </Wrap>
  )
}

export default app(CreateEditPostContainer(withRouter(ShareModal)))
