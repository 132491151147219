import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import createToast from "@ui/Toast"
import Container from "container/TechAssets"
import { get } from "lodash"

const user = checkForUserObject("user")

const agentId = user && user.id

class PubnubForDocusign extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      refreshListingDocuments,
      dealToView,
      updateDocusignEnvelopeStatus,
      updateDealStatus,
      getAllListings,
    } = this.props
    const docusignListingChannel = `docusign_listing_${dealToView}`
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {

      root.pubNubListingDocusign = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      root.pubNubListingDocusign.subscribe({
        channels: [docusignListingChannel, "docusign_return", `docusign_listing_status_${agentId}`],
      })

      root.pubNubListingDocusign.addListener({
        message: (data) => {
          console.log("Listening to..", docusignListingChannel, data)

          try {
            const { message, channel } = data
            console.log("check here->", data)
            const { event_type } = message
            console.log(message)
            console.log(event_type)

            if(channel === "docusign_return" && message.listingId && parseInt(message?.listingId, 10) === dealToView && message?.agentId === agentId) {
              let docusignData = message
              if(docusignData && docusignData.error) {
                createToastNew.error(docusignData.error, toastConfig)
                return
              }
              if (docusignData && docusignData.docusignEnvelopeId) {
                if(docusignData.docusignIntermediateStatus) {
                  updateDocusignEnvelopeStatus({
                    payload: {
                      "envelopeId": docusignData.docusignEnvelopeId,
                      "status": docusignData.docusignIntermediateStatus
                    },
                    callback: () => {
                      refreshListingDocuments({
                        payload: {
                          listingId: dealToView,
                        },
                      })
                    }
                  })
                } else {
                  refreshListingDocuments({
                    payload: {
                      listingId: dealToView,
                    },
                  })
                }
              }
              return
            }

            if(dealToView && (channel === docusignListingChannel)) {
              refreshListingDocuments({
                payload: {
                  listingId: dealToView,
                },
              })
            }

            if (channel === `docusign_listing_status_${agentId}` && message === "Listing Status changed") {
              const {
                getListingCoverSheetResponse,
                dealToView: dealId,
              } = this.props
              getAllListings({
                skip: 0,
                limit: 200,
                agentId: user.agent_id || user.id,
                forListing: true,
                include: "in-contract-listings",
              })
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { dealToView } = this.props
    const docusignListingChannel = `docusign_listing_${dealToView}`
    if (root.pubNubListingDocusign) {
      root.pubNubListingDocusign.unsubscribe({
        channels: [docusignListingChannel, "docusign_return"],
      })
    }
  }

  render() {
    return null
  }
}

export default Container(PubnubForDocusign)
