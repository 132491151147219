import React, { useState } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  InputField,
  InputArea,
} from "dumbComponents/OffersV2/components/InputFields"
import {
  BlueButton,
  WhiteButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  Form,
} from "@ui/antd"
import {
  FormWrap,
  CTAWrap,
  DIV,
  CountTracker,
  SuffixWrap,
} from "./styles"

const SendOfferForm = ({
  onFinish,
  onFinishFailed,
  toClose,
  children,
  sendingOfferPackage,
}) => {
  const [subjectLength, setSubjectLength] = useState(0)
  const [messageLength, setMessageLength] = useState(0)

  return (
    <FormWrap>
      <Form
        name="offers_send_offer_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={() => { }}
      // ref={this.formRef}
      >

        <InputField
          name="from"
          inputType="text"
          placeholder="Your name"
        />

        <InputField
          name="from_email"
          inputType="text"
          placeholder="Your email"
        />
        
        <InputField
          name="to"
          inputType="text"
          placeholder="Recipient's name"
        />

        <InputField
          name="to_email"
          inputType="text"
          placeholder="Recipient's email"
        />

        <InputField
          name="cc"
          inputType="text"
          placeholder="Recipient's name"
        />

        <InputField
          name="cc_email"
          inputType="text"
          placeholder="cc email"
        />

        <DIV>
          <InputField
            name="offer_package_subject"
            inputType="text"
            placeholder="Subject*"
            maxLength={100}
            onChange={(e) => {
              const validText = e.target.value
              if (validText) {
                setSubjectLength(validText.length)
              }
            }}
          />
          <CountTracker>
            <p>{`${subjectLength} / 100`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          <InputArea
            name="custom_message"
            inputType="text"
            placeholder="Custom Message"
            maxLength={500}
            onChange={(e) => {
              const validText = e.target.value
              if (validText) {
                setMessageLength(validText.length)
              }
            }}
          />
          <CountTracker>
            <p>{`${messageLength} / 500`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          {children}
        </DIV>

        <CTAWrap>
          <Form.Item>
            <BlueButton
              style={{
                width: "100%",
              }}
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              {sendingOfferPackage ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Send Offer Package</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default SendOfferForm
