import React from "react"
import styled from "styled-components"
import {
  smDesktop,
  preTabletWidth,
  preDesktopWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
} from "@consts"
import colors from "@colors"
import Marketing from "images/Graphics/RadiusForAgents/Marketing.svg?q=100"
import SocialMedia from "images/Graphics/RadiusForAgents/SocialMediaNew.svg?q=100"
import RecruitAndEarn from "images/Graphics/RadiusForAgents/RecruitAndEarnLogo.png?q=100"
import ClientResources from "images/Graphics/RadiusForAgents/ClientResource.svg?q=100"
import Technology from "images/Graphics/RadiusForAgents/Technology.svg?q=100"
import Legal from "images/Graphics/RadiusForAgents/Security.svg?q=100"
import Leads from "images/Graphics/RadiusForAgents/LeadsNurturing.svg?q=100"
import TransactionCoordination from "images/Graphics/RadiusForAgents/TransactionCoordinationNew.svg?q=100"
import Branding from "images/Graphics/RadiusForAgents/BrandingNew.svg?q=100"
import { ATC } from "@fonts"

const Wrap = styled.div`
  padding: 72px 72px;
  ${smDesktop`
    padding: 72px 52px;
  `}
  ${preDesktopWidth`
    padding: 0px 60px;
  `}
  ${preTabletWidth`
    padding: 0px 0px;
  `}
`
const Heading = styled.h1`
  font-family: Butler;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 840px;
  margin: auto;
  color: ${colors.black};

  ${minDesktopWidth`
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 745px;
  `}


  ${preDesktopWidth`
    font-size: 36px;
    font-style: normal;
    line-height: 45px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 493px;
  `}

  ${preTabletWidth`
    font-size: 32px;
    font-style: normal;
    line-height: 39px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 326px;
  `}

  /*PROP MEDIA QUERY CSS*/

  @media(min-width: 1100px) {
    ${props => props.fontSizeML && `
    font-size: ${props.fontSizeML};
  `}
    ${props => props.lineHeightML && `
      line-height: ${props.lineHeightML};
    `}
    ${props => props.widthML && `
      width: ${props.widthML};
   `}
  }

  @media(min-width: 1440px) {
    ${props => props.fontSizeXL && `
    font-size: ${props.fontSizeXL};
  `}
    ${props => props.lineHeightXL && `
      line-height: ${props.lineHeightXL};
    `}
    ${props => props.widthXL && `
      width: ${props.widthXL};
    `}
  }
  
  @media(max-width: 1099px) {
    ${props => props.fontSizeSM && `
    font-size: ${props.fontSizeSM};
  `}
    ${props => props.lineHeightSM && `
      line-height: ${props.lineHeightSM};
    `}
    ${props => props.widthSM && `
      width: ${props.widthSM};
    `}
  }
  @media(max-width: 767px) {
    ${props => props.fontSizeS && `
      font-size: ${props.fontSizeS};
    `}
    ${props => props.lineHeightS && `
      line-height: ${props.lineHeightS};
    `}
    ${props => props.widthS && `
      width: ${props.widthS};
    `}
  }
  /*PROP MEDIA QUERY CSS*/
`

const HeadContainer = styled.div`
  color: ${colors.black};
`

const Description = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  margin-top: 16px;
  color: ${colors.black};

  ${minDesktopWidth`
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    width: 590px;
  `}

  ${minLargeDesktopWidth`
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    width: 845px;
  `}

  ${preDesktopWidth`
    font-family: ${ATC};
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    width: 518px;
  `}

  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    width: 305px;
  `}
`

const ServicesWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 0px 50px 0px;
  ${preDesktopWidth`
    padding: 72px 0px 0px 0px;
  `}
  ${preTabletWidth`
    padding: 120px 0px 0px 0px;
  `}
  color: #303030;
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;

  @media(min-width: 1440px) {
    // width: 1300px;
  }
`

const InnerWrap = styled.div`
  height: 190px;
  border-radius: 0px;
  padding: 20px 30px;

  @media(max-width: 767px) {
    padding: 0px;
    flex: 1 1 50%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media(min-width: 768px) {
    padding: 0px;
    flex: 1 1 50%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media(min-width: 1024px) {
    flex: 1 1 25%;
    margin-bottom: 100px;
    padding: 10px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media(min-width: 1280px) {
    flex: 1 1 25%;
    margin-bottom: 100px;
    padding: 10px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media(min-width: 1440px) {
    margin-bottom: 50px;
    margin-bottom: 50px;
    flex: 1 1 25%;
    padding: 10px 30px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: auto;
    margin-bottom: 20px;
    min-height: 100px;
  }
`
const Type = styled.div`
 p {
  font-family: ${ATC};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: center;

   @media(min-width: 1024px) {
      font-size: 18px;
      font-style: normal;
      line-height: 28px;
      letter-spacing: 2px;
      text-align: center;
      width: 208px;
   }

   ${preDesktopWidth`
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 2px;
    text-align: center;
    max-width: 186px;
   `}

   ${preTabletWidth`
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: center;
    width: 137px;
   `}
 }
`
class Services extends React.PureComponent {
  componentDidMount() {
    //
  }

  render() {
    const {
      header,
      description,
      fontSizeXL,
      fontSizeML,
      fontSizeSM,
      fontSizeS,
      lineHeightML,
      lineHeightS,
      lineHeightSM,
      lineHeightXL,
      widthML,
      widthXL,
      widthSM,
      widthS,
    } = this.props
    return (
      <Wrap>
        <ServicesWrap>
          <HeadContainer>
            <Heading
              fontSizeXL={fontSizeXL}
              fontSizeML={fontSizeML}
              fontSizeSM={fontSizeSM}
              fontSizeS={fontSizeS}
              lineHeightML={lineHeightML}
              lineHeightS={lineHeightS}
              lineHeightSM={lineHeightSM}
              lineHeightXL={lineHeightXL}
              widthML={widthML}
              widthXL={widthXL}
              widthSM={widthSM}
              widthS={widthS}
            >
              {header}
            </Heading>
            <Description className="service-description">
              {description}
            </Description>
          </HeadContainer>
          <ImageContainer>
            <InnerWrap>
              <img src={Marketing} alt="radius_realty_branding_marketing" />
              <Type>
                <p>MARKETING</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={RecruitAndEarn} alt="radius_realty_recruit_and_earn" />
              <Type>
                <p>REFER AND EARN</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={ClientResources} alt="radius_realty_client_resources" />
              <Type>
                <p>CLIENT RESOURCES</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={Technology} alt="radius_realty_technology_and_training" />
              <Type>
                <p>TECHNOLOGY & TRAINING</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={Legal} alt="radius_realty_legal" />
              <Type>
                <p>LEGAL SUPPORT & COMPLIANCE</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={Leads} alt="radius_realty_leads" />
              <Type>
                <p>LEAD NURTURING</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={TransactionCoordination} alt="radius_realty_transaction_and_coordination" />
              <Type>
                <p>TRANSACTION COORDINATION</p>
              </Type>
            </InnerWrap>
            <InnerWrap>
              <img src={Branding} alt="radius_realty_branding" />
              <Type>
                <p>BRANDING</p>
              </Type>
            </InnerWrap>
          </ImageContainer>
        </ServicesWrap>
      </Wrap>
    )
  }
}

export default Services
