import React from "react"
import {
  DropdownWrap,
  MLSImage,
  MLSWrap,
  MLSAddress,
  MLSNumber,
} from "./googleLocationSearchStyles"

const CustomDropdown = ({
  selectedOption,
  setOptions,
  setFetching,
  setDisplay,
  setSelectedOption,
  callback
}) => {
  const handleClick = (option) => {
    setOptions([])
    setFetching(false)
    setDisplay("none")
    setSelectedOption(option)
    console.log(option)
    if(callback) {
      callback(option)
    }
  }

  return (
    <>
      {selectedOption && selectedOption.map(option => (
        <DropdownWrap
          onClick={() => handleClick(option)}
        >
          <MLSImage src={option.image} alt="mls" />
          <MLSWrap>
            <MLSNumber>
              {option.id}
            </MLSNumber>
            <MLSAddress>
              {option.address}
            </MLSAddress>
          </MLSWrap>
        </DropdownWrap>
      ))}
    </>
  )
}

export default CustomDropdown
