import React from "react"
import root from "window-or-global"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import { secondsInDuration, prettyUrl } from "dumbComponents/Academy/common/Helpers"

const Wrap = styled.div`
  color: ${colors.contentText};
  font-size: 14px;
  font-weight: 500;
  
  h5 {
    color: ${colors.contentText};
    font-size: 14px;
    margin: 0px;
  }
  p {
    font-weight: 300;
  }
`

const CourseWrap = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${colors.borderGray};
  main {
    height: 240px;
    background: ${colors.borderGray} center no-repeat; 
    background-size: cover;
  }

  footer {
    background: ${colors.white};
    padding: 17px 25px;
    span {
      margin: 0px 20px;
      font-weight: 300;
    }
  }
`

const CourseFeed = ({
  title = "",
  Lessons,
  total_duration_in_seconds,
  community_post_text,
  cover_image_url,
  id,
  course_hash_id,
}) => {
  const gotoCourse = () => {
    root.open(`${APP_URL}/academy/course-details/${course_hash_id || id}/${prettyUrl(title)}`)
  }

  return (
    <Wrap>
      <h5>{title}</h5>
      <p>
        {community_post_text}
      </p>
      <CourseWrap>
        <main style={{ backgroundImage: `url(${cover_image_url})` }} />
        <footer>
          <Button
            borderRadius="4px"
            width="130px"
            style={{ backgroundColor: colors.academyTitleColor }}
            onClick={gotoCourse}
          >
            Start Course
          </Button>
          <span>
            {`${Lessons && Lessons.length} lessons | ~${secondsInDuration(total_duration_in_seconds)}`}
          </span>
        </footer>
      </CourseWrap>
    </Wrap>
  )
}

export default CourseFeed
