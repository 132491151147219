/* eslint-disable import/no-cycle */
import React from "react"
import Pin from "./Pin"

/*
* THIS FILE IS INDEPENDENT OF ROOT CONTAINER.
* NORMAL REDUX ACTIONS ARE NOT APPLIED HERE.
* FOR API CALLS USE axios.
*/

const PinnedComponent = ({
  id,
}) => (
  <Pin id={id} />
)
export default PinnedComponent
