import { createSignalAction, createBaseActionCreator } from "shared/reduxUtils"

const BASE = "SEARCH"

export const getSearchedAgents = createSignalAction(BASE, "GET_SEARCHED_AGENTS")

export const toggleFilterPanel = createBaseActionCreator(BASE, "TOGGLE_FILTER_PANEL")

export const initFilterData = createBaseActionCreator(BASE, "INIT_FILTER_DATA")

export const selectSearchFilter = createBaseActionCreator(BASE, "SELECT_SEARCH_FILTER")

export const removeSearchFilter = createBaseActionCreator(BASE, "REMOVE_SEARCH_FILTER")

export const removeAndApplySearchFilter = createBaseActionCreator(BASE, "REMOVE_AND_APPLY_SEARCH_FILTER")

export const updateFiltersOnAction = createBaseActionCreator(BASE, "UPDATE_FILTERS_ON_ACTION")

export const clearAllSearchFilters = createBaseActionCreator(BASE, "CLEAR_ALL_SEARCH_FILTERS")

export const changeFilterSelectedCategory = createBaseActionCreator(BASE, "CHANGE_FILTER_SELECTED_CATEGORY")

export const showMoreFiltersForCategory = createBaseActionCreator(BASE, "SHOW_MORE_FILTERS_FOR_CATEGORY")

export const changeAndResetFilterCategory = createBaseActionCreator(BASE, "CHANGE_AND_RESET_FILTER_CATEGORY")

export const updateAppliedFiltersPayload = createBaseActionCreator(BASE, "UPDATE_APPLIED_FILTERS_PAYLOAD")

export const updateSingleFilterPayload = createBaseActionCreator(BASE, "UPDATE_SINGLE_FILTER_PAYLOAD")

export const removeAndApplySingleFilter = createBaseActionCreator(BASE, "REMOVE_AND_APPLY_SINGLE_FILTER")

export const getClientsByName = createSignalAction(BASE, "GET_CLIENTS_BY_NAME")
