import React from "react"
import { reduxForm } from "redux-form"
import createToast from "@ui/Toast"
import SearchIcon from "images/icons/search-icon.svg"
import Box from "@ui/Box"
import {
  Wrapper,
  SearchInputWrap,
  TagsWrap,
  Tag,
  Footer,
  Header,
  SaveButton,
  ErrorText,
  ProfileCompletionButton,
  BlankButton,
  HelpingText,
} from "./commonStyles"

class Expertise extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues = [] } = this.props
    this.state = {
      tagList: [...initialValues],
      search: "",
      showMoreCount: 1,
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  addItem = (item) => {
    const { tagList } = this.state
    if (item) {
      if (tagList.length === 3) {
        createToast("You can only select up to 3 expertise", "error")
        return
      }
      this.setState({
        tagList: [...tagList, item],
        search: "",
      })
    }
  }

  handleShowMore = () => {
    const { showMoreCount } = this.state
    this.setState({
      showMoreCount: showMoreCount + 1,
    })
  }

  handleSave = () => {
    const { saveProfileUpdate } = this.props
    const { tagList } = this.state
    if (tagList && tagList.length === 0) {
      this.setState({
        error: "Please select an expertise from the list",
      })
    } else {
      saveProfileUpdate({
        userSpecializations: [...tagList],
      })
    }
  }

  removeItem = (item) => {
    const { tagList = [] } = this.state
    const itemIndex = tagList.indexOf(item)
    tagList.splice(itemIndex, 1)
    this.setState({
      tagList: [...tagList],
    })
  }

  render() {
    const {
      handleSubmit,
      isSavingUpdates,
      preFetchedData: {
        specializations: preFetchedSpecializations = [],
      } = {},
      isProfileCompletion,
      skipProfileCompletionStep,
      nextStep,
      nextToNextStep,
    } = this.props
    const {
      search,
      tagList,
      showMoreCount,
      error,
    } = this.state
    const filteredSpecializations = preFetchedSpecializations
      .filter(item => item.toLowerCase().indexOf(search) !== -1 && tagList.indexOf(item) === -1)
      .filter((item, index) => index <= showMoreCount * 5)
    return (
      <Wrapper>
        <Header>
          <h4>What are your areas of expertise?</h4>
        </Header>
        <Box d="flex" p="15px">
          <SearchInputWrap onSubmit={handleSubmit} flex="1">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              name="search"
              component="input"
              type="text"
              placeholder="Seach Expertise"
              value={search}
              onChange={this.handleInput}
            />
          </SearchInputWrap>
        </Box>
        <HelpingText>
          Choose up to 3 areas of expertise. Selections will be visible in your public profile.
        </HelpingText>
        <TagsWrap>
          {tagList.map(item => (
            <Tag
              key={item}
              title={item}
              handleClick={this.removeItem}
            />
          ))}
          {filteredSpecializations.map(item => (
            <Tag
              addable
              key={item}
              title={item}
              handleClick={() => { this.addItem(item) }}
            />
          ))}
          { !search
            && filteredSpecializations.length > 0
            && preFetchedSpecializations.length !== (filteredSpecializations.length + tagList.length)
            && !(filteredSpecializations.length < 3) && (
            <Tag
              showMoreBtn
              title="Show More"
              handleClick={() => { this.handleShowMore() }}
            />
          )}
          { error && (
            <Box p="15">
              <ErrorText>{error}</ErrorText>
            </Box>
          )}
        </TagsWrap>
        <Footer
          d="flex"
          style={{
            justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
            padding: 15,
          }}
        >
          { isProfileCompletion && nextToNextStep && (
            <BlankButton
              onClick={() => { skipProfileCompletionStep(nextStep) }}
              normal
            >
              Skip
            </BlankButton>
          )}
          {isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              width="100px"
              isShowLoader={isSavingUpdates}
              onClick={this.handleSave}
              type="button"
            >
              Save
            </SaveButton>
          )}
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "PROFILE_EXPERTISE_FORM",
  destroyOnUnmount: true,
})(Expertise)

