import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "PRODUCT_INTEREST_MODAL"

export const getAllPimAction = createSignalAction(BASE, "FETCH_ALL_PIM")

export const postFinalCTAAction = createSignalAction(BASE, "POST_FINAL_CTA_CALL")
