import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import MD5 from "md5.js"
import { isSuccess, saveUserObjectToCookie, toggleBooleanFromInteger } from "services/Utils"
import { get } from "lodash"
import root from "window-or-global"
import * as ReferralActions from "container/Referral/actions"
import * as FormInputActions from "container/FormInput/actions"
import * as FormInputSelector from "container/FormInput/selector"
import * as AppActions from "container/App/actions"
import createToast from "@ui/Toast"
import { toast } from "react-toastify"
import Cookies from "react-cookies"
import cookie from "services/CookieStorage"
import * as TechAssetsActions from "container/TechAssets/actions"
import * as SettingsActions from "./actions"
import * as SettingsAPI from "./api"
import {
  inviteAgentPromo,
  fetchSubscriptionInvite,
  reInviteAgentPromo,
} from "./promoSaga"

const getProcessedIntegrations = integrations => integrations.map(sourceData => ({
  ...sourceData,
  showSetting: false,
}))

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

function* fetchAllLeadIntegrationSources() {
  try {
    const res = yield call(SettingsAPI.fetchAllLeadIntegrationSourcesAPI)
    if (isSuccess(res)) {
      const { integrations, referral_settings } = res.data.response
      const leadSources = getProcessedIntegrations(integrations)

      yield put(SettingsActions.fetchAllLeadIntegrationSourcesAction.success({
        leadSources,
        referral_settings,
      }))
    }
  } catch (e) {
    yield put(SettingsActions.fetchAllLeadIntegrationSourcesAction.failure(e))
  }
}

function* updateReferralSettings(action) {
  let res
  try {
    const { payload } = action.data
    res = yield call(SettingsAPI.updateReferralSettingsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.updateReferralSettingsAction.success(response))
    }
  } catch (e) {
    const { push } = action.data
    const {
      status,
    } = e
    if (status === 400) {
      const errorCode = get(e, "data.error.code")
      if (errorCode === 203) {
        //No subscription found for ISA option
        yield put(SettingsActions.toggleLeadIntegrationErrorModal.action({
          show: true,
          errorCode,
        }))
      } else if (errorCode === 50001) {
        yield put(ReferralActions.fetchSignedAgreementAction.request({ push }))
      } else {
        saveUserObjectToCookie({ isaLeadAgreementSigned: undefined })
        yield put(SettingsActions.hideListViewAction({ type: "empty" }))
      }
    }
    yield put(SettingsActions.updateReferralSettingsAction.failure(e))
  }
}

function* getUniqueEmail() {
  try {
    const res = yield call(SettingsAPI.getUniqueEmailAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.getUniqueEmailAction.success(response))
    }
  } catch (e) {
    yield put(SettingsActions.getUniqueEmailAction.failure(e))
  }
}

function* updateLeadIntegrationSource(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.updateLeadIntegrationSourceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.fetchAllLeadIntegrationSourcesAction.request())
      yield put(SettingsActions.updateLeadIntegrationSourceAction.success(response))
      yield put(SettingsActions.showListViewAction())
    }
  } catch (e) {
    yield put(SettingsActions.updateLeadIntegrationSourceAction.failure(e))
  }
}

function* syncGmail(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(SettingsAPI.getRefreshTokenFromAuthCodeAPI, payload)
    if (isSuccess(res)) {
      const {
        response: { refresh_token },
      } = res.data
      const syncRes = yield call(SettingsAPI.syncGmailAPI, { refresh_token })
      const {
        response: { gmail_email },
      } = syncRes.data
      saveUserObjectToCookie({ gmail_email })
      yield put(AppActions.localUpdateUserObject.action({
        gmail_refresh_token: refresh_token,
        gmail_email,
      }))
      const authRes = yield call(SettingsAPI.getGoogleAuthTokenAPI)
      try {
        const { response } = authRes.data
        yield put(TechAssetsActions.getGoogleAuthToken.success(response))
        // cookie.save("google_access_token", access_token)
        if (callback) {
          callback()
        }
      } catch (err) {
        console.log(err)
      }
      yield put(SettingsActions.syncGmailAction.success())
    }
  } catch (e) {
    yield put(SettingsActions.syncGmailAction.failure(e))
  }
}

function* deactivateGmail() {
  try {
    const res = yield call(SettingsAPI.deactivateGmailAPI, {
      params: {
        revoke_token: true,
      },
    })
    if (isSuccess(res)) {
      yield put(AppActions.localUpdateUserObject.action({
        gmail_refresh_token: null,
        gmail_email: null,
      }))
      yield put(SettingsActions.deactivateGmail.success())
    }
  } catch (e) {
    yield put(SettingsActions.deactivateGmail.failure(e))
  }
}

function* unsubscribeFromEmail(action) {
  try {
    const { hashId, email } = action.data
    const res = yield call(SettingsAPI.unsubscribeFromEmailAPI, hashId, { email })
    yield put(SettingsActions.unsubscribeFromEmailAction.success(res))
  } catch (e) {
    const { status, data: { error } = {} } = e || {}
    const message = status === 400 ? error.message : "Something went wrong!"
    yield put(SettingsActions.unsubscribeFromEmailAction.failure({ message }))
  }
}

function* deleteAccount(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.deleteAccountAPI, payload)
    if (isSuccess(res)) {
      yield put(SettingsActions.deleteAccountAction.success(res))
      toast.info("Delete Account Request Initiated", toastConfig)
      yield put(AppActions.logoutUserAction.request())
    }
  } catch (e) {
    const { status, data: { error } = {} } = e || {}
    const message = status === 400 ? error.message : "Something went wrong!"
    yield put(SettingsActions.deleteAccountAction.failure({ message }))
  }
}

const getNotificationSettingsById = (notificationSettings) => {
  const notificationSettingsById = {}
  notificationSettings.forEach((obj) => {
    obj.settings.forEach((rawNotificationObject) => {
      notificationSettingsById[rawNotificationObject.type_id] = {
        ...rawNotificationObject,
        showSetting: false,
      }
    })
  })
  return notificationSettingsById
}

function* getNotificationSettings() {
  try {
    const res = yield call(SettingsAPI.getNotificationSettingsAPI)
    if (isSuccess(res)) {
      const notificationSettings = res.data.response
      const notificationSettingsById = getNotificationSettingsById(notificationSettings)
      yield put(SettingsActions.getNotificationSettings.success({
        notificationSettings,
        notificationSettingsById,
      }))
    }
  } catch (e) {
    yield put(SettingsActions.getNotificationSettings.failure(e))
  }
}

function* requestToggleNotificationSetting(action) {
  try {
    const {
      notificationId,
      key,
      value,
      isFrequency,
      index,
    } = action.data
    yield put(SettingsActions.toggleNotificationSetting.action({
      notificationId,
      key,
      value,
      isFrequency,
      index,
    }))

    const curr = yield select(state => state.settings.notificationSettingsById[notificationId])

    const moddedPushFreq = []
    if (curr.push_frequency && !Array.isArray(curr.push_frequency)) {
      const objectArray = Object.entries(curr.push_frequency)
      objectArray.forEach(([keyC, valueC]) => {
        moddedPushFreq.push(valueC)
      })
      moddedPushFreq.forEach((keyV, indexV) => {
        if (indexV !== index) {
          keyV.enabled = 0
        }
      })
      curr.push_frequency = moddedPushFreq
    }

    // const payload = {
    //   notificationSettings: [{
    //     type_id: notificationId,
    //     [key]: value,
    //   }],
    // }

    const res = yield call(SettingsAPI.updateNotificationSettingsV3API, curr, curr.type_id)
    if (isSuccess(res)) {
      yield put(SettingsActions.requestToggleNotificationSetting.success())
      createToast("Notification Settings changed successfully!")
      yield put(SettingsActions.getNotificationSettings.request())
    } else {
      yield put(SettingsActions.toggleNotificationSetting.action({
        notificationId,
        key,
        value: toggleBooleanFromInteger(value),
        isFrequency,
        index,
      }))
    }
  } catch (e) {
    const {
      notificationId,
      key,
      value,
      isFrequency,
      index,
    } = action.data
    yield put(SettingsActions.toggleNotificationSetting.action({
      notificationId,
      key,
      value: toggleBooleanFromInteger(value),
      isFrequency,
      index,
    }))
    yield put(SettingsActions.requestToggleNotificationSetting.failure(e))
  }
}

function* requestChangePassword() {
  try {
    const currentPassword = yield select(FormInputSelector.getCurrentPasswordTextFromStore)
    const newPassword = yield select(FormInputSelector.getNewPasswordTextFromStore)
    const currentPassMd5 = new MD5().update(currentPassword).digest("hex")
    const newPassMd5 = new MD5().update(newPassword).digest("hex")
    const res = yield call(SettingsAPI.requestChangePasswordAPI, {
      currentPassword: currentPassMd5,
      newPassword: newPassMd5,
    })
    if (isSuccess(res)) {
      yield put(SettingsActions.requestChangePassword.success())
      createToast("Password changed successfully!")
      //Reset password fields to blank
      yield put(FormInputActions.updateFormInputText.action({
        key: "currentPasswordText",
        text: "",
      }))
      yield put(FormInputActions.updateFormInputText.action({
        key: "newPasswordText",
        text: "",
      }))
    }
  } catch (e) {
    const message = get(e, "data.error.message", "Please try again later")
    yield put(SettingsActions.requestChangePassword.failure(e))
    yield put(SettingsActions.toggleUserSettingsErrorModal.action({
      show: true,
      isPhoneError: false,
      message,
    }))
  }
}

function* requestUpdateDisplayEmail(action) {
  try {
    const { display_email } = action.data
    const res = yield call(SettingsAPI.updateDisplayEmailAPI, {
      display_email,
    })
    if (isSuccess(res)) {
      yield put(AppActions.localUpdateUserObject.action({
        display_email,
      }))
      createToast("Email updated successfully")
      yield put(SettingsActions.requestUpdateDisplayEmail.success())
    }
  } catch (e) {
    yield put(SettingsActions.requestUpdateDisplayEmail.failure(e))
  }
}

function* handleFetchIntroValues() {
  try {
    const headers = new Headers()
    const secureId = Cookies.load("secureId")
    headers.append("secure-id", secureId)
    const url = `${ADMIN_API_URL}notification-template-types?isa=1`
    const authFetch = () => fetch(url, {
      headers,
      mode: "cors",
    })
    const res = yield call(authFetch)
    if (isSuccess(res)) {
      const body = yield res.json()
      const { response } = body
      yield put(SettingsActions.fetchIntroValuesAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.fetchIntroValuesAction.failure(error))
  }
}

function* handleFetchSMSTemplates(action) {
  try {
    const { data } = action
    const { id } = data
    const headers = new Headers()
    const secureId = Cookies.load("secureId")
    headers.append("secure-id", secureId)
    const url = `${ADMIN_API_URL}notification-template-mappings/${id}?categories=intro,failedcall,missedcall`
    const authFetch = () => fetch(url, {
      headers,
      mode: "cors",
    })
    const res = yield call(authFetch)
    if (isSuccess(res)) {
      const body = yield res.json()
      const { response } = body
      yield put(SettingsActions.fetchSMSTemplatesAction.success(response))
      yield put(SettingsActions.setCurrentLeadIdAction.call(id))
    }
  } catch (error) {
    yield put(SettingsActions.fetchSMSTemplatesAction.failure(error))
  }
}

function* handleSMSTemplateUpdate(action) {
  try {
    const { data } = action
    const { modifiedSmsTemplate, templateId, currentLeadID } = data
    const headers = new Headers()
    const secureId = Cookies.load("secureId")
    headers.append("secure-id", secureId)
    headers.append("Content-Type", "application/json")
    const url = `${ADMIN_API_URL}notification-template-mappings/${templateId}`
    const authFetch = () => fetch(url, {
      method: "PUT",
      headers,
      mode: "cors",
      body: JSON.stringify(modifiedSmsTemplate),
    })
    const res = yield call(authFetch)
    if (isSuccess(res)) {
      yield put(SettingsActions.updateSMSTemplateAction.success())
      yield put(SettingsActions.handleToggleForSMSModalAction.call(
        {
          isSmsEditModalVisible: false,
          currentTemplate: null,
          showPremiumBlockerPrompt: false,
        }
      ))
      yield put(SettingsActions.fetchSMSTemplatesAction.request({ id: currentLeadID }))
    }
  } catch (error) {
    yield put(SettingsActions.updateSMSTemplateAction.failure())
  }
}

function* handleSMSTemplatesLegends() {
  try {
    const headers = new Headers()
    const secureId = Cookies.load("secureId")
    headers.append("secure-id", secureId)
    headers.append("Content-Type", "application/json")
    const url = `${ADMIN_API_URL}common/notification_template_variables?visible=1`
    const authFetch = () => fetch(url, {
      headers,
      mode: "cors",
    })
    const res = yield call(authFetch)
    if (isSuccess(res)) {
      const body = yield res.json()
      const { response } = body
      yield put(SettingsActions.getSMSTemplateLegendsAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.getSMSTemplateLegendsAction.failure())
  }
}

function* handleChangeLeadType(action) {
  const { data: { value } } = action
  yield put(SettingsActions.setLeadTypeAction.call(value))
}

function* handleCurrentAgentType(action) {
  const { data: { type, id } } = action
  yield put(SettingsActions.setCurrentAgentTypeAction.call(type))
  yield put(SettingsActions.fetchSMSTemplatesAction.request({ id }))
}

function* saveMultipleEmailsAndPhone(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.saveMultipleEmailsAndPhoneAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.saveMultipleEmailsAndPhoneAction.success(response))
      yield put(AppActions.fetchUserInfoAction.request())
    }
  } catch (error) {
    const message = get(error, "data.error.message")
    yield put(SettingsActions.toggleUserSettingsErrorModal.action({
      show: true,
      isPhoneError: true,
      message,
    }))
    yield put(AppActions.fetchUserInfoAction.request())
    yield put(SettingsActions.saveMultipleEmailsAndPhoneAction.failure(error))
  }
}

function* handleAppointmentScheduling(action) {
  try {
    const { type, setTo, newLink } = action.data
    const payload = {}
    if (type === "Link") {
      payload.calendly_link = newLink
    } else {
      payload.calendly_link_status = setTo
    }
    const res = yield call(SettingsAPI.updateReferralSettingsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.toggleAppointmentSchedulingAction.success(response))
      yield put(SettingsActions.getCurrentAppointmentSchedulingAction.request())
    }
  } catch (error) {
    yield put(SettingsActions.toggleAppointmentSchedulingAction.failure(error))
  }
}

function* fetchCurrentAppointmentSchedulingStatus() {
  try {
    const res = yield call(SettingsAPI.getReferralSettingsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.getCurrentAppointmentSchedulingAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.getCurrentAppointmentSchedulingAction.failure(error))
  }
}

//handleSubmitCalendlyLink
function* handleSubmitCalendlyLink(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(SettingsAPI.updateReferralSettingsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.submitCalendlyLinkAction.success(response))
      yield put(SettingsActions.getCurrentAppointmentSchedulingAction.request())
    }
  } catch (error) {
    yield put(SettingsActions.submitCalendlyLinkAction.failure(error))
  }
}

function* handleEmailTrigger() {
  try {
    const res = yield call(SettingsAPI.triggerEmailRequestAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.triggerEmailRequestAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.triggerEmailRequestAction.failure(error))
  }
}

function* handleCrossSell(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.triggerCrossSellAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.triggerCrossSellAnalyticsAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.triggerCrossSellAnalyticsAction.failure(error))
  }
}

function* handleReferralNumber() {
  try {
    const res = yield call(SettingsAPI.fetchRadiusPhoneNumberAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.fetchRadiusPhoneNumberAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.fetchRadiusPhoneNumberAction.failure(error))
  }
}

function* handleAssistPreference() {
  try {
    const res = yield call(SettingsAPI.fetchAssistNotesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.fetchAssistNotesAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.fetchAssistNotesAction.failure(error))
  }
}

function* handleFetchAssistPreference() {
  try {
    const res = yield call(SettingsAPI.getAssistPreferenceAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.fetchAssistPreferenceAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.fetchAssistPreferenceAction.failure(error))
  }
}

function* handleSaveAssistPreference(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.saveAssistPreferenceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.saveAssistPreferenceAction.success(response))
      yield put(SettingsActions.toggleAssistPreferenceEditAction.call(false))
      yield put(SettingsActions.fetchAssistPreferenceAction.request())
    }
  } catch (error) {
    yield put(SettingsActions.saveAssistPreferenceAction.failure(error))
  }
}

function* handleAPITokenGeneration() {
  try {
    const res = yield call(SettingsAPI.tokenGenerationAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.generateAPITokenAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.generateAPITokenAction.failure(error))
  }
}

function* handleAPITokenReGeneration() {
  try {
    const res = yield call(SettingsAPI.newTokenGenerationAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.reGenerateAPITokenAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.reGenerateAPITokenAction.failure(error))
  }
}

function* handleSaveAssistNotes(action) {
  try {
    const {
      payload,
      sourceId,
    } = action.data
    const res = yield call(SettingsAPI.saveAssistNoteAPI, sourceId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.saveAssistNotesActions.success(response))
      yield put(SettingsActions.toggleAssistNotesModalAction.call({
        isAssistNoteEditModalOpen: false,
        acitveAssistNoteEdit: null,
      }))
      yield put(SettingsActions.fetchAssistNotesAction.request())
    }
  } catch (error) {
    yield put(SettingsActions.saveAssistNotesActions.failure(error))
  }
}

function* handleSaveStripeToken(action) {
  try {
    const { token, callback, secureId, history } = action.data
    if (secureId) {
      const headers = new Headers()
      headers.append("secure-id", secureId)
      headers.append("Content-Type", "application/json")
      const url = `${API_URL}stripe/customers`
      const authFetch = () => fetch(url, {
        headers,
        mode: "cors",
        body: JSON.stringify({ token }),
        method: "POST",
      })
      const res = yield call(authFetch)
      if (isSuccess(res)) {
        yield put(SettingsActions.saveStripeTokenAction.success(res.stripe_id))
        if (callback) {
          callback()
        }
        history.push("/stripe/addCardDetails/authenticate/success")
      }
      return
    }
    const res = yield call(SettingsAPI.saveStripeTokenAPI, { token })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.saveStripeTokenAction.success(response))
      createToast("Saving New Card Details.")
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(SettingsActions.saveStripeTokenAction.failure(error))
  }
}

function* handleSaveStripeSubscription(action) {
  try {
    const {
      token, callback, secureId, history,
    } = action.data
    if (secureId) {
      const payload = {
        tokenId: token,
      }
      // const headers = new Headers()
      // headers.append("secure-id", secureId)
      // headers.append("Content-Type", "application/json")
      // const url = `${API_V2_URL}stripe/subscription/checkout`
      // const authFetch = () => fetch(url, {
      //   headers,
      //   mode: "cors",
      //   body: JSON.stringify({ tokenId: token }),
      //   method: "POST",
      // })
      // const res = yield call(authFetch)
      const res = yield call(SettingsAPI.saveStripeSubscriptionAPI, payload)
      if (isSuccess(res)) {
        yield put(SettingsActions.saveStripeSubscriptionAction.success(res.stripe_id))
        if (callback) {
          callback()
        }
        history.push("/stripe/addCardDetails/authenticate/success")
      }
    }
  } catch (error) {
    yield put(SettingsActions.saveStripeSubscriptionAction.failure(error))
    createToast(error.data.error.message, "error")
  }
}

function* handleGetCardDetails(action) {
  try {
    const { secureId } = action.data || {}
    if (secureId) {
      const headers = new Headers()
      headers.append("secure-id", secureId)
      headers.append("Content-Type", "application/json")
      const url = `${API_URL}stripe/customers/payment`
      const authFetch = () => fetch(url, {
        headers,
        mode: "cors",
      })
      const res = yield call(authFetch)
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(SettingsActions.getCardDetailsAction.success(response))
      }
      return
    }
    const res = yield call(SettingsAPI.getCardDetailsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.getCardDetailsAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.getCardDetailsAction.failure(error))
  }
}

function* handleGetBrokerAddons() {
  try {
    const res = yield call(SettingsAPI.getSubscribedAddonsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.getBrokerAddonsAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.getBrokerAddonsAction.failure(error))
  }
}

function* handleVerifyAuthToken(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.verifyAuthTokenAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.verifyAuthTokenForStripeAction.success(response))
      cookie.save("secureId", response.secure_id)
    }
  } catch (error) {
    yield put(SettingsActions.verifyAuthTokenForStripeAction.failure(error))
  }
}

function* handleSendAddCreditCardV2(action) {
  try {
    const { payload } = action.data
    const res = yield call(SettingsAPI.sendAddCreditCardLinkV2API, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.sendAddCreditCardLinkV2Action.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.sendAddCreditCardLinkV2Action.failure(error))
  }
}

function* handleGetClientSecret() {
  try {
    const res = yield call(SettingsAPI.getClientSecretAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(SettingsActions.getClientSecretAction.success(response))
    }
  } catch (error) {
    yield put(SettingsActions.getClientSecretAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(SettingsActions.fetchAllLeadIntegrationSourcesAction.REQUEST, fetchAllLeadIntegrationSources)
  yield takeLatest(SettingsActions.updateReferralSettingsAction.REQUEST, updateReferralSettings)
  yield takeLatest(SettingsActions.getUniqueEmailAction.REQUEST, getUniqueEmail)
  yield takeLatest(SettingsActions.updateLeadIntegrationSourceAction.REQUEST, updateLeadIntegrationSource)
  yield takeLatest(SettingsActions.syncGmailAction.REQUEST, syncGmail)
  yield takeLatest(SettingsActions.deactivateGmail.REQUEST, deactivateGmail)
  yield takeLatest(SettingsActions.triggerCrossSellAnalyticsAction.REQUEST, handleCrossSell)
  yield takeLatest(SettingsActions.unsubscribeFromEmailAction.REQUEST, unsubscribeFromEmail)
  yield takeLatest(SettingsActions.getNotificationSettings.REQUEST, getNotificationSettings)
  yield takeLatest(SettingsActions.requestToggleNotificationSetting.REQUEST, requestToggleNotificationSetting)
  yield takeLatest(SettingsActions.requestChangePassword.REQUEST, requestChangePassword)
  yield takeLatest(SettingsActions.requestUpdateDisplayEmail.REQUEST, requestUpdateDisplayEmail)
  yield takeLatest(SettingsActions.fetchIntroValuesAction.REQUEST, handleFetchIntroValues)
  yield takeLatest(SettingsActions.fetchSMSTemplatesAction.REQUEST, handleFetchSMSTemplates)
  yield takeLatest(SettingsActions.updateSMSTemplateAction.REQUEST, handleSMSTemplateUpdate)
  yield takeLatest(SettingsActions.getSMSTemplateLegendsAction.REQUEST, handleSMSTemplatesLegends)
  yield takeLatest(SettingsActions.changeLeadTypeAction.type, handleChangeLeadType)
  yield takeLatest(SettingsActions.checkCurrentAgentTypeAction.type, handleCurrentAgentType)
  yield takeLatest(SettingsActions.saveMultipleEmailsAndPhoneAction.REQUEST, saveMultipleEmailsAndPhone)
  yield takeLatest(SettingsActions.toggleAppointmentSchedulingAction.REQUEST, handleAppointmentScheduling)
  yield takeLatest(SettingsActions.getBrokerAddonsAction.REQUEST, handleGetBrokerAddons)
  yield takeLatest(SettingsActions.getCurrentAppointmentSchedulingAction.REQUEST,
    fetchCurrentAppointmentSchedulingStatus)
  yield takeLatest(SettingsActions.submitCalendlyLinkAction.REQUEST,
    handleSubmitCalendlyLink)
  // Promo Saga
  yield takeLatest(SettingsActions.sendNewSubscriptionPromoInviteAction.REQUEST, inviteAgentPromo)
  yield takeLatest(SettingsActions.fetchSubscriptionInviteAction.REQUEST, fetchSubscriptionInvite)
  yield takeLatest(SettingsActions.resendNewSubscriptionPromoInviteAction.REQUEST, reInviteAgentPromo)
  yield takeLatest(SettingsActions.triggerEmailRequestAction.REQUEST, handleEmailTrigger)
  yield takeLatest(SettingsActions.fetchRadiusPhoneNumberAction.REQUEST, handleReferralNumber)
  yield takeLatest(SettingsActions.fetchAssistNotesAction.REQUEST, handleAssistPreference)
  yield takeLatest(SettingsActions.fetchAssistPreferenceAction.REQUEST, handleFetchAssistPreference)
  yield takeLatest(SettingsActions.saveAssistPreferenceAction.REQUEST, handleSaveAssistPreference)
  yield takeLatest(SettingsActions.generateAPITokenAction.REQUEST, handleAPITokenGeneration)
  //apiReGenerationResponse
  yield takeLatest(SettingsActions.reGenerateAPITokenAction.REQUEST, handleAPITokenReGeneration)
  yield takeLatest(SettingsActions.saveAssistNotesActions.REQUEST, handleSaveAssistNotes)
  yield takeLatest(SettingsActions.saveStripeTokenAction.REQUEST, handleSaveStripeToken)
  yield takeLatest(SettingsActions.getCardDetailsAction.REQUEST, handleGetCardDetails)
  yield takeLatest(SettingsActions.verifyAuthTokenForStripeAction.REQUEST, handleVerifyAuthToken)

  yield takeLatest(SettingsActions.deleteAccountAction.REQUEST, deleteAccount)
  yield takeLatest(SettingsActions.sendAddCreditCardLinkV2Action.REQUEST, handleSendAddCreditCardV2)
  yield takeLatest(SettingsActions.saveStripeSubscriptionAction.REQUEST, handleSaveStripeSubscription)
  yield takeLatest(SettingsActions.getClientSecretAction.REQUEST, handleGetClientSecret)
}
