import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";

const Header = styled.h3`
  color: ${colors.new_grey600};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
  margin-bottom: 24px;
  margin-top: 48px;
  @media (max-width: 1024px) {
    padding-bottom: 6px;
    margin-top: 32px;
  }
`;
const SchoolName = styled.p`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  cursor: pointer;
`;

const ListBullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${colors.new_colorNeutral200};
  margin-right: 8px;
  margin-top: 2px;
`;

const SubHeading = styled.p`
  color: ${colors.new_grey600};
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
`;
const ScoreWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const Info = styled.p`
  color: #545487;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const InfoData = styled.p`
  color: #545487;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SchoolInfoWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
const Block = styled.div`
  display: block;
  padding-top: 5px;
`;

const Grades = styled.p`
  color: ${colors.new_text_purple_600};
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0;
`;

const ScoreCard = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10.5px 10px 9.5px 10px;
  justify-content: center;
  align-items: center;
  background: ${colors.new_colorNeutral100};
  border-radius: 50%;
`;

const ScoreNumber = styled.span`
  color: ${colors.new_primary};

  font-family: ${INTER};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const ScoreOutOf = styled.span`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const ButtonShow = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 8px;
  cursor: pointer;
`;

const SchoolLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.new_border_outline};
`;

const LevelTitle = styled.div`
  background: ${colors.new_tab_color};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${colors.new_levelColor};
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
`;

const SchoolCodes = styled.div`
  color: ${colors.new_text_purple_600};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.5px;

  span {
    font-weight: 600;
    text-transform: capitalize;
  }
`;

const SchoolRating = styled.p`
  margin: 0;
  color: ${(props) => {
    if (props.rating === "Good") return "#0C9F6E";
    if (props.rating === "Below average") return "#F05152";
    return "#262866";
  }};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const SmallCircle = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${colors.new_border_outline};
  margin: 0px 8px;
  border-radius: 50px;
`;

export {
  Header,
  SubHeading,
  ScoreCard,
  ScoreOutOf,
  ScoreNumber,
  Flex,
  Info,
  Block,
  InfoData,
  SchoolName,
  Grades,
  ListBullet,
  ScoreWrap,
  ButtonShow,
  SchoolLevel,
  SchoolCodes,
  LevelTitle,
  SchoolRating,
  SmallCircle,
  SchoolInfoWrap,
};
