import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  URLBasedHubspotTrackingAction,
  getAllInviteesAction,
  toggleSuccessModalAction,
  getCashbackProgramAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { realty, recruitAndEarnV2Reducer } = state
  return {
    ...realty,
    ...recruitAndEarnV2Reducer,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  URLBasedHubspotTracking: URLBasedHubspotTrackingAction.call,
  getAllInvitees: getAllInviteesAction.request,
  toggleSuccessModal: toggleSuccessModalAction.call,
  getCashbackProgram: getCashbackProgramAction.request,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
