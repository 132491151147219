import React, { useState, useMemo } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  BlueButton,
  WhiteButton,
} from "dumbComponents/ListingsV2/commonStyles"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  InputArea,
} from "dumbComponents/ListingsV2/Components/InputFields"
import TechAssets from "container/TechAssets"
import {
  FormWrap,
  CTAWrap,
  DIV,
  CountTracker,
} from "./commonStyles"

const CreateEnvelopeForm = ({
  onFinish,
  onFinishFailed,
  toClose,
  sendingDocument,
  listingData,
  propertyInformationObject,
  currentBrokerageType,
  listingAddress,
  getContractByIdResponse,
}) => {
  const [subjectLength, setSubjectLength] = useState(0)
  const [messageLength, setMessageLength] = useState(0)

  const {
    data: contractData,
  } = getContractByIdResponse || {}

  const getInitialValues = () => {
    let property_address

    if (currentBrokerageType === "offers") {
      const offerState = propertyInformationObject.currentState
      const address = propertyInformationObject.form[offerState]

      if (address?.street?.length) {
        property_address = `Please Sign: ${address.street}`
      } else if (listingAddress?.length) {
        property_address = `Please Sign: ${listingAddress}`
      } else if (contractData?.[0]?.property_information?.street?.length && contractData?.[0]?.property_information?.city?.length) {
        property_address = `Please Sign: ${contractData.property_information.street}, ${contractData.property_information.city}`
      }
    } else {
      const address = listingData.propertyData

      if (address?.street?.length && address?.city?.length) {
        property_address = `Please Sign: ${address.street}, ${address.city}`
      } else if (listingAddress?.length) {
        property_address = `Please Sign: ${listingAddress}`
      } else if (contractData?.[0]?.property_information?.street?.length && contractData?.[0]?.property_information?.city?.length) {
        property_address = `Please Sign: ${contractData.property_information.street}, ${contractData.property_information.city}`
      }
    }

    const intiValues = {
      emailSubject: property_address,
    }

    setSubjectLength(property_address.length)
    return intiValues
  }

  return (
    <FormWrap>
      <Form
        name="offers_envelope_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={useMemo(() => getInitialValues(), [propertyInformationObject, listingData.propertyData])}
      // ref={this.formRef}
      >

        <DIV>
          <InputField
            name="emailSubject"
            inputType="text"
            placeholder="Subject*"
            maxLength={100}
            text="Subject*"
            onChange={(e) => {
              const validText = e.target.value
              setSubjectLength(validText.length)
            }}
          />
          <CountTracker>
            <p>{`${subjectLength} / 100`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          <InputArea
            name="emailBody"
            inputType="text"
            placeholder="Personalized Note*"
            maxLength={500}
            onChange={(e) => {
              const validText = e.target.value
              setMessageLength(validText.length)
            }}
            type="custom"
            customRules={[{ required: true, message: "Note is required." }]}
          />
          <CountTracker>
            <p>{`${messageLength} / 500`}</p>
          </CountTracker>
        </DIV>

        <CTAWrap>
          <Form.Item>
            <WhiteButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="button"
              onClick={toClose}
            >
              <p>Close</p>
            </WhiteButton>
          </Form.Item>

          <Form.Item>
            <BlueButton
              style={{
                width: "356px",
              }}
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
              disabled={!(messageLength > 0 && subjectLength > 0)}
            >
              {sendingDocument ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Next</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default TechAssets(CreateEnvelopeForm)
