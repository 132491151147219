import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "ACADEMY"

export const getEventsListingAction = createSignalAction(BASE, "GET_EVENTS_LIST")
export const getCategoriesListingAction = createSignalAction(BASE, "GET_CATEGORIES_LIST")
export const getCourseListingAction = createSignalAction(BASE, "GET_COURSE_LIST")
export const startCourseAction = createSignalAction(BASE, "START_COURSE")
export const getCourseDetailsAction = createSignalAction(BASE, "GET_COURSE_DETAILS")
export const getLessonDetailsAction = createSignalAction(BASE, "GET_LESSION_DETAILS")
export const getEventDetailsAction = createSignalAction(BASE, "GET_EVENT_DETAILS")
export const getNextLessonAction = createSignalAction(BASE, "GET_NEXT_LESSON")
export const registerForEventAction = createSignalAction(BASE, "REGISTER_FOR_EVENT")
export const changeLessonStatusAction = createSignalAction(BASE, "CHANGE_LESSON_STATUS")
export const changeCourseStatusAction = createSignalAction(BASE, "CHANGE_COURSE_STATUS")
export const getCommunityUpcomingEventsAction = createSignalAction(BASE, "COMMUNITY_UPCOMING_EVENTS")
export const currentCategoryTypeAction = createSimpleCreator(BASE, "CURRENT_CATEGORY")

export const toggleRegisteredModalAction = createSimpleCreator(BASE, "TOGGLE_REGISTERED_MODAL")
export const academyViewedAction = createSignalAction(BASE, "ACADEMY_VIEW_ACTION")
