import React, { useState, useRef } from "react"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import Loader from "@ui/Loader"
import { LeftOutlined } from "@ant-design/icons"
import colors from "@colors"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import { get } from "lodash"
import {
  Form, NewInput, NewSelect, NewButton,
  InputNumber,
} from "@ui/antd"
import CRMV2Container from "container/CRM"
import {
  HeaderWrap, CloseWrap, LabelComponent,
} from "../../common"
import { validateFields } from "../../utils"

const { Item } = Form

const Wrap = styled.div``

const FormWrap = styled.div`
  padding: 20px;
`

const ErrorWrap = styled.h1`
  color: #ff4b4b;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  margin-bottom: 15px;
  margin-left: 5px;
`

const validateMessages = {
  required: "Required!",
  types: {
    email: "Email is not a valid!",
    number: "Phone Number is not a valid!",
  },
}

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "FSBO",
    value: "fsbo",
  }, {
    label: "Mail",
    value: "mail",
  }, {
    label: "Networking",
    value: "networking",
  }, {
    label: "New Construction",
    value: "new_construction",
  }, {
    label: "Open House",
    value: "open_house",
  }, {
    label: "Other - Agency",
    value: "other_agency",
  }, {
    label: "Other - Builder",
    value: "other_builder",
  }, {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  }, {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  }, {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  }, {
    label: "Personal Transaction",
    value: "personal_transaction",
  }, {
    label: "Property Management",
    value: "property_management",
  }, {
    label: "Floor Call",
    value: "floor_call",
  }, {
    label: "Referral - Attorney",
    value: "referral_attorney",
  }, {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  }, {
    label: "Referral - Lendor",
    value: "referral_lendor",
  }, {
    label: "Referral - Other",
    value: "referral_other",
  }, {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  }, {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  }, {
    label: "Referral - Title",
    value: "referral_title",
  }, {
    label: "Sign Call",
    value: "sign_call",
  }, {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  }, {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  }, {
    label: "Social Profile - Other",
    value: "social_profile_other",
  }, {
    label: "Events - Other",
    value: "events_other",
  },
]

const EditProfile = ({
  setCurrentDrawerPage,
  getCrmClientResponse,
  updateClient,
  currentFilters,
  getCtIdResponse,
  updateClientTransaction,
}) => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [isPhoneNumberValid, setPhoneNumber] = useState(true)
  const [isEmailValid, setEmailValid] = useState(true)
  const { data: clientData, isFetching } = getCrmClientResponse || {}
  const { name, emails, phones, id } = clientData || {}
  const phone = !isFetching && phones && get(phones, "[0].phone", "")
  const email = !isFetching && emails && get(emails, "[0].email", "")
  const { data: clientTransactionData } = getCtIdResponse || {}
  const { client_source: currentSource, id: transactionId, type, status } = clientTransactionData || {}

  const checkSecondaryPhoneValidity = (event) => {
    setPhoneNumber(true)
  }

  const checkSecondaryEmailValidity = () => {
    setEmailValid(true)
  }

  const onFinish = (values) => {
    const { phoneValid, emailValid, isOverallTrue } = validateFields(values)
    setPhoneNumber(phoneValid)
    setEmailValid(emailValid)
    const { phone: updatedPhone, email: updateMail, name: updatedName, client_source } = values
    if (isOverallTrue) {
      const payload = {
        phones: [{
          phone: updatedPhone.toString(),
        }],
        emails: [{
          email: updateMail,
        }],
        name: updatedName,
      }
      const clientTransactionPayload = {
        status,
        client_id: id,
        type,
        client_source,
      }
      if (clientTransactionPayload) {
        updateClientTransaction({ payload: clientTransactionPayload, transactionId, currentFilters })
      }
      updateClient({ payload, clientId: id, currentFilters })
      setCurrentDrawerPage("drawer-header")
    } else {
      console.log(isOverallTrue, values)
    }
  }
  return (
    <Wrap>
      <HeaderWrap background="#FFFFFF">
        <CloseWrap
          onClick={() => {
            setCurrentDrawerPage("drawer-header")
          }}
          fontColor={colors.black}
        >
          <LeftOutlined style={{ color: `${colors.black}` }} />
          <p>Back to Profile</p>
        </CloseWrap>
      </HeaderWrap>
      {isFetching ? (
        <Loader />
      ) : (
        <FormWrap>
          <Form
            name="add_new_lead"
            preserve
            colon={false}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            validateMessages={validateMessages}
            ref={formRef}
            form={form}
          >
            <Item
              name="name"
              label={<LabelComponent text="Full Name" />}
              rules={[{ required: true }]}
              initialValue={name}
            >
              <NewInput />
            </Item>
            <Item
              name="email"
              label={<LabelComponent text="Email" />}
              rules={[{ required: true }]}
              initialValue={email}
            >
              <NewInput onChange={checkSecondaryEmailValidity} />
            </Item>
            {!isEmailValid && <ErrorWrap>* Email Not Valid</ErrorWrap>}
            <Item
              name="phone"
              label={<LabelComponent text="Phone Number" />}
              onChange={e => checkSecondaryPhoneValidity(e)}
              rules={[{ required: true }]}
              initialValue={normalizePhone(phone)}
              // normalize={normalizePhone}
            >
              <NewInput controls={false} />
            </Item>
            {!isPhoneNumberValid && <ErrorWrap>* Phone Number Not Valid</ErrorWrap>}
            <Item
              name="client_source"
              label={<LabelComponent text="Client Source" />}
              rules={[{ required: true }]}
              initialValue={currentSource}
            >
              <NewSelect
                placeholder="Select..."
                options={CLIENT_SOURCE}
              ></NewSelect>
            </Item>
            <Item>
              <NewButton
                style={{
                  float: "right",
                }}
                type="primary"
                htmlType="submit"
              >
                Update
              </NewButton>
            </Item>
          </Form>
        </FormWrap>
      )}
    </Wrap>
  )
}

export default CRMV2Container(EditProfile)
