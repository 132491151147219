/* eslint-disable max-len */
import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import { withRouter } from "react-router-dom"
import { uniqBy } from "lodash"
import cookie from "services/CookieStorage"
import OffersV2 from "container/OffersV2"
import {
  TeamMemberSelect,
} from "dumbComponents/common/InputFields"
import Listings from "container/Listings/index"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div`
  height: 300px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const TeamLeadModalOffer = ({
  toClose,
  getTeamDetailsResponse,
  setEditingDealTeamMemberId,
  handleCreateFlow,
}) => {
  let defaultValue
  const user = cookie.load("user") || {}
  const loggedInAgentId = user.id
  const [agentIdValue, setAgentIdValue] = useState(loggedInAgentId)

  const handleMemberSelectChange = (id) => {
    setAgentIdValue(id)
  }

  const getMoreText = (agentId, userId, memberInfo) => {
    const tcInformation = cookie.load("tcInformation")
    if (tcInformation?.is_tc && memberInfo.member_type === "Team Lead") {
      return "(Team Lead)"
    }
    if (agentId === userId) {
      defaultValue = `${memberInfo.firstname} ${memberInfo.lastname} (For Me)`
      return "(For Me)"
    }
    return ""
  }

  const {
    data: teamDetails,
  } = getTeamDetailsResponse || {}
  const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.map(tm => ({
    value: tm.agent_id,
    label: `${tm.firstname} ${tm.lastname || ""} ${getMoreText(tm.agent_id, user.id, tm)}`,
    moreValue: tm.member_type,
  })) : []

  const merged = [
    ...teamNames.filter(tm => tm.moreValue === "Team Lead"),
    ...teamNames.filter(tm => tm.moreValue !== "Team Lead"),
  ]

  const teams = uniqBy([...merged], "value")
  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Create on behalf of :
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              Please choose an agent from the list
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ParaWrap
          padding="0px 20px 40px 20px"
        >
          <TeamMemberSelect
            onChange={handleMemberSelectChange}
            defaultValue={defaultValue}
            options={teams}
          />
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={toClose}
          >
            CANCEL
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            disabled={!agentIdValue && !defaultValue}
            onClick={() => {
              setEditingDealTeamMemberId(agentIdValue)
              handleCreateFlow(agentIdValue)
            }}
          >
            CREATE
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(OffersV2(TeamProfileContainer(Listings(TeamLeadModalOffer)))))
