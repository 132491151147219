import React from "react"
import { required } from "services/formUtils"
import {
  Input,
  DatePicker,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"
import moment from "moment"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { format } from "date-fns"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"
import {
  COMMISSION_TYPE,
  TRANSACTION_TYPE,
  RENT_AMOUNT_TERM,
  FIELD_VALUES,
} from "./data"
import CheckboxComponent from "../Common/RadioGroup"
import SubmitButton from "../Common/SubmitButton"
import {
  Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
  PercentageDiv,
} from "../Common/CommonStyles"
import {
  HandleSubmit,
  FunctionalComponentDidMount,
  backButtonUrl,
  FormatToPercentage,
  formatAmount,
  BrowserCloseListener,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"
import {
  BASIC_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../../Routes"

const DATE_FORMAT = "MMM DD, YYYY"

const MarginWrap = styled.div`
  margin-top: 27px;
`

const WrapperField = styled.div``

const TransactionFormUI = ({
  ...props
}) => {
  const {
    id,
    history,
    invalid: isInvalid,
    handleSubmit,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    combinedTCValues,
    fetchTransactionCoordinationDocumentsResponse,
    formName,
    destroy,
    isFormDisabled,
  } = props || {}

  BrowserCloseListener()

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  let isNewListing = combinedTCValues && combinedTCValues.file_type === "new_listing"
  let isNewListingWithOffer = combinedTCValues && combinedTCValues.file_type === "listing_with_accepted_offer"
  let isPendingLease = combinedTCValues && combinedTCValues.file_type === "pending_lease"
  let isLeaseListing = combinedTCValues && combinedTCValues.file_type === "lease_listing"
  let commissionType = ""
  let transactionType = ""
  let rentAmountTermType = ""
  let isExternalReferalAgreement = null
  let isDual = combinedTCValues && combinedTCValues.file_type === "dual"

  const {
    isFetching: creatingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
    isNewListing = currentDeal && currentDeal.file_type === "new_listing"
    commissionType = currentDeal && currentDeal.commission_type
    rentAmountTermType = currentDeal && currentDeal.rent_amount_term
    transactionType = currentDeal && currentDeal.transaction_type
    isPendingLease = currentDeal && currentDeal.file_type === "pending_lease"
    isLeaseListing = currentDeal && currentDeal.file_type === "lease_listing"
    isNewListingWithOffer = currentDeal && (currentDeal.file_type === "listing_with_accepted_offer")
    isDual = currentDeal && (currentDeal.file_type === "dual")
    isExternalReferalAgreement = currentDeal.file_type === "external_referral_agreement"
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName)
  }

  const isProcessing = fetchingValues || creatingForm || updatingTCStage || isFetchingDeals
  const showListPrice = isNewListing || isLeaseListing
  const showSalesPrice = !isNewListing && !isNewListingWithOffer && !isDual && !isLeaseListing && !isPendingLease
  const showRentPrice = isLeaseListing || isPendingLease
  const showEscrowDateOption = !isPendingLease && !isLeaseListing
  const showLeaseDateOption = isPendingLease

  return (
    <>
      {isFetchingDeals ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          {showListPrice && (
            <Field
              name="list_price"
              component={Input}
              disabled={isFormDisabled}
              placeholder="$1,000,000"
              label="List Price"
              validate={[required]}
              format={formatAmount}
              type="text"
              customInputStyle={{
                width: "490px",
              }}
            />
          )}
          {showSalesPrice && (
            <Field
              name="price"
              component={Input}
              disabled={isFormDisabled}
              placeholder="$1,000,000"
              label="Sales Price"
              validate={[required]}
              format={formatAmount}
              type="text"
              customInputStyle={{
                width: "490px",
              }}
            />
          )}
          {showRentPrice && (
            <Field
              name="rent_amount"
              component={Input}
              disabled={isFormDisabled}
              placeholder="$1,000,000"
              label="Rent Amount"
              validate={[required]}
              format={formatAmount}
              type="text"
              customInputStyle={{
                width: "490px",
              }}
            />
          )}
          {(isNewListingWithOffer || isDual) && (
          <>
            <Field
              name="price"
              component={Input}
              disabled={isFormDisabled}
              placeholder="$1,000,000"
              label="Sales Price"
              validate={[required]}
              type="text"
              format={formatAmount}
              customInputStyle={{
                width: "490px",
              }}
            />
            <Field
              name="list_price"
              component={Input}
              disabled={isFormDisabled}
              placeholder="$1,000,000"
              label="List Price"
              validate={[required]}
              type="text"
              format={formatAmount}
              customInputStyle={{
                width: "490px",
              }}
            />
          </>
          )}
          {showRentPrice && (
            <CheckboxComponent
              rentTermTypeExtra
              noButtonReposition
              name="rent_amount_term"
              rentAmountTermType={rentAmountTermType}
              optionArray={RENT_AMOUNT_TERM}
              labelText="What is your rent term basis?"
              marginBottom="31px"
              customLabelStyles={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: "0px",
                textAlign: "left",
              }}
              disabled={isFormDisabled}
            />
          )}
          <CheckboxComponent
            commissionTypeExtra
            noButtonReposition
            name="commission_type"
            commissionType={commissionType}
            optionArray={COMMISSION_TYPE}
            labelText="What type of Commission are you receiving?"
            marginBottom="31px"
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
            disabled={isFormDisabled}
          />
          <WrapperField>
            <Field
              name="team_split_percentage"
              component={Input}
              placeholder="10%"
              label="What is your split with your team?"
              validate={[required]}
              type="text"
              customInputStyle={{
                width: "490px",
              }}
              customLabelStyle={{
                width: "max-content",
              }}
              normalize={FormatToPercentage}
              disabled={isFormDisabled}
            >
              <PercentageDiv>
                <p>%</p>
              </PercentageDiv>
            </Field>
          </WrapperField>
          {isExternalReferalAgreement && (combinedTCValues.commission_type === "percentage" || combinedTCValues.commission_type === "both") && (
            <WrapperField>
              <Field
                name="closing_agent_commision_percentage"
                component={Input}
                placeholder="2.5%"
                label="What is closing agent's commission percentage?"
                validate={[required]}
                type="text"
                customInputStyle={{
                  width: "490px",
                }}
                customLabelStyle={{
                  width: "max-content",
                }}
                normalize={FormatToPercentage}
                disabled={isFormDisabled}
              >
                <PercentageDiv>
                  <p>%</p>
                </PercentageDiv>
              </Field>
            </WrapperField>
          )}
          
          {!isNewListing && (
            <MarginWrap>
              <Field
                label="Acceptance date"
                component={DatePicker}
                validate={[required]}
                disabled={isFormDisabled}
                afterLimit={moment().toDate()}
                name="created_at"
                //setTodayDateAsDefault
                config={{
                  formatDate: format,
                  format: DATE_FORMAT,
                  placeholder: `${format(new Date(), DATE_FORMAT)}`,
                }}
                customInputStyle={{
                  fontSize: "15px",
                  height: "54px",
                  width: "490px",
                  borderRadius: "8px",
                  padding: "15px 16px 15px 16px",
                  justify: "space-between",
                  border: "1px solid #96A3BD",
                }}
              />
            </MarginWrap>
          )}
          {showEscrowDateOption && (
            <MarginWrap>
              <Field
                label="Estimated Close of Escrow"
                component={DatePicker}
                validate={[required]}
                disabled={isFormDisabled}
                name="closed_on"
                beforeLimit={moment().toDate()}
                config={{
                  formatDate: format,
                  format: DATE_FORMAT,
                  placeholder: `${format(new Date(), DATE_FORMAT)}`,
                }}
                customInputStyle={{
                  fontSize: "15px",
                  height: "54px",
                  width: "490px",
                  borderRadius: "8px",
                  padding: "15px 16px 15px 16px",
                  justify: "space-between",
                  border: "1px solid #96A3BD",
                }}
              />
            </MarginWrap>
          )}
          {showLeaseDateOption && (
            <MarginWrap>
              <Field
                label="Lease Commencement Date"
                component={DatePicker}
                validate={required}
                disabled={isFormDisabled}
                name="lease_commencement_on"
                config={{
                  formatDate: format,
                  format: DATE_FORMAT,
                  placeholder: `${format(new Date(), DATE_FORMAT)}`,
                }}
                customInputStyle={{
                  fontSize: "15px",
                  height: "54px",
                  width: "490px",
                  borderRadius: "8px",
                  padding: "15px 16px 15px 16px",
                  justify: "space-between",
                  border: "1px solid #96A3BD",
                }}
              />
            </MarginWrap>
          )}
          <CheckboxComponent
            tTypeExtra
            noButtonReposition
            transactionType={transactionType}
            optionArray={TRANSACTION_TYPE}
            labelText="Transaction Type"
            marginBottom="31px"
            name="transaction_type"
            disabled={isFormDisabled}
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          />
          <CTAWrap>
            <BackButton
              type="button"
              onClick={() => {
                //goes previous form
                backButtonUrl(history, id, BASIC_FORM_ROUTE)
              }}
            >
              Back
            </BackButton>
            <SubmitButton
              disabled={isFormDisabled}
              isProcessing={isProcessing}
              text={isFormDisabled ? "Next" : "Continue"}
              isInvalid={isInvalid}
            />
          </CTAWrap>
        </FormWrap>
      )}
    </>
  )
}

const TransactionFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  props,
  toggleErrorModal,
  fetchTCFiles,
  ...properties
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)
  const allCashOfferRoutes = ["listing_with_accepted_offer", "dual", "pending_contract", "pending_lease"]
  const EscrowFormRoute = ["dual", "pending_contract", "external_referral_agreement"]
  //dont show for New Listing, External Referral Agreement, Lease Listing
  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  const disableField = isCompleted || isCoListed || getIsEdit(currentDeal)

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  let nextRoute = TCAGENT_FORM_ROUTE

  // let nextRouteV2 = combinedTCValues
  // && combinedTCValues.file_type
  // && combinedTCValues.file_type === new_listing ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE

  // if (parsedQuery.id && deals) {
  //   const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
  //   nextRoute = existingDeal && existingDeal.file_type === "new_listing" ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE
  // }

  if (parsedQuery.id && deals) {
    const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
    const fileType = existingDeal ? existingDeal.file_type : combinedTCValues.file_type
    nextRoute = existingDeal && allCashOfferRoutes.includes(fileType) ? TCAGENT_FORM_ROUTE
      : EscrowFormRoute.includes(fileType) ? TCAGENT_FORM_ROUTE
        : fileType !== "new_listing" ? TCAGENT_FORM_ROUTE : TCAGENT_FORM_ROUTE
  }

  return (
    <Wrap>
      <Header
        text="Transaction Details"
        history={history}
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <TransactionForm
          onSubmit={values => HandleSubmit(
            values,
            nextRoute,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "transaction_details",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          id={parsedQuery.id}
          formName="TC_TRANSACTION_DETAILS_FORM"
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const TransactionForm = reduxForm({
  form: "TC_TRANSACTION_DETAILS_FORM",
  destroyOnUnmount: false,
})(TransactionFormUI)

export default TransactionContainer(withRouter(TransactionFormComponent))
