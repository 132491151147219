import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import Offer from "container/TechAssets"
import moment from "moment"
import { checkForUserObject } from "services/Utils"
import OffersV2 from "container/OffersV2/index"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { getStateLowerCase } from "dumbComponents/OffersV3/consts"
import OfferStatusSelect from "./OfferStatus"
import { ATC, INTER } from "@fonts"

const Wrap = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`

const StyledLink = styled.div`
  color: rgb(59, 96, 228);
  cursor: pointer;
`

const OfferLi = styled.li`
  width: 100%;
`

const OfferCard = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Color-Neutral-300, #DCDEFF);
  background: var(--Color-Neutral-50, #FDFDFD);
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const DateDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    overflow: hidden;
    color: #898989;
    text-overflow: ellipsis;

    /* caption/regular */
    font-family: ${INTER};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    letter-spacing: 0.5px;
    margin: 0;
  }
`

const DropdownWrap = styled.div``

const Address = styled.div`
   width: 455px;

  p {
    color: var(--Color-Gray-600, #292A37);
    text-overflow: ellipsis;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const BlueButton = styled.button`
  display: flex;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  border: none;
  background: var(--Color-Primary-Primary, #5A5FF2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 6px 0px rgba(0, 0, 0, 0.01), 0px 22px 6px 0px rgba(0, 0, 0, 0.00);

  color: var(--Color-Neutral-50, #FDFDFD);
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.5px;
`

const ZeroStateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  gap: 10px;
`

const ZeroStateHeader = styled.p`
  margin: 0;
  font-family: ${INTER};
  font-size: 18px;
  font-weight: 500;
  color: #A5A8FF;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E5E6FF;
  padding-bottom: 6px;
`

const ShimmerWrap = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
`

const OffersList = ({
  offers,
  fetching,
  updateOfferStatus,
  fetchBuyerOffers,
  history,
  transactionId,
  toggleBasicOfferDetailsModal,
  updateBuyerInformationObject,
  setDealToView,
  locations,
  setStateForOffer,
  req_id,
  client_id,
  client_source,
  agentId,
  setEditingDealTeamMemberId,
  updatePropertyInformationObject,
  setMLSData,
  toggleActiveTab,
  toggleDocumentTab,
  updateOffersDocumentsObject,
  updateUploadedDocsObject,
  updateEnvelopesObject,
  clearValidateCoverSheet,
  clearOffersRelatedData,
  clearExistingData,
  setOfferIdToView,
}) => {
  const user = checkForUserObject("user")
  const currentLoggedInUserId = user && user.id

  const handleChange = (payload) => {
    updateOfferStatus({
      payload,
      callback: () => {
        fetchBuyerOffers({
          transactionId,
          history,
        })
      },
    })
  }

  return (
    <Wrap>
      {fetching ? (
        <OfferLi>
          <ShimmerWrap>
            <ShimmerBox w="100%" h="100px" />
            <ShimmerBox w="100%" h="100px" />
            <ShimmerBox w="100%" h="100px" />
            <ShimmerBox w="100%" h="100px" />
          </ShimmerWrap>
        </OfferLi>
      ) : (
        <>
          {offers && offers.length > 0 ? (
            <>
              {offers && offers.map((item) => {
                const isTeamMemberOrDifferentAgent = currentLoggedInUserId !== agentId
                if (isTeamMemberOrDifferentAgent) {
                  setEditingDealTeamMemberId(agentId)
                }
                //|| `${item.apt}, ${item.street}, ${item.city}, ${item.state}`
                const address = item.street?.length && item.city?.length ? `${item.street}, ${item.city}` : "Incomplete Property Address"
                const date = item.created_at_epoch
                const { id } = item
                const isOfferAccepted = item.current_offer_status === "offer_accepted"
                let addedQuery = `?transaction_id=${transactionId}&req_id=${req_id}&client_id=${client_id}&client_source=${client_source}&agent_id=${agentId}`
                if (isOfferAccepted) {
                  addedQuery += "&contract_phase=submitted"
                }
                const redirectUrl = `/realty/offers-v3/${id}${addedQuery}`

                return (
                  <OfferLi>
                    <OfferCard>
                      <Row>
                        <Address>
                          <p>{address}</p>
                        </Address>
                        <StyledLink
                          onClick={() => {
                            clearOffersRelatedData()
                            clearExistingData()
                            setOfferIdToView(null)
                            //root.location.href = `${RADIUS_URL}${redirectUrl}`
                            setDealToView(id)
                            clearValidateCoverSheet()

                            if (isOfferAccepted) {
                              toggleActiveTab("contract_details")
                            } else if (item.current_offer_status !== "incomplete") {
                              toggleActiveTab("documents")
                              toggleDocumentTab("recommended_docs")
                            } else {
                              toggleActiveTab("cover_sheet")
                              toggleDocumentTab("")
                            }

                            history.push(redirectUrl)
                          }}
                          // to={redirectUrl}
                        >
                          View Offer
                        </StyledLink>
                      </Row>
                      <Row>
                        <DateDiv>
                          <p>Created at</p>
                          <p>{moment(date * 1000).format("MM/DD/YY")}</p>
                        </DateDiv>
                        <DropdownWrap>
                          <OfferStatusSelect
                            status={item.current_offer_status}
                            handleChange={handleChange}
                            id={id}
                            tId={transactionId}
                          />
                        </DropdownWrap>
                      </Row>
                    </OfferCard>
                  </OfferLi>
                )
              })}
            </>
          ) : (
            <ZeroStateWrap>
              <ZeroStateHeader>
                No offers yet
              </ZeroStateHeader>
              <BlueButton
                onClick={() => {
                  toggleBasicOfferDetailsModal(true)
                  setDealToView(null)
                  setOfferIdToView(null)
                  toggleActiveTab("cover_sheet")
                  setMLSData({})
                  updateOffersDocumentsObject([])
                  updateUploadedDocsObject([])
                  updateEnvelopesObject([])
                  clearValidateCoverSheet()
                  updatePropertyInformationObject({
                    addressRepresentation: false,
                    currentState: "california",
                    form: {},
                    listing_agents: [],
                    sellers: [],
                    mls_number: "",
                  })
                  updateBuyerInformationObject({
                    agents: [],
                    buyers: [],
                    lenders: [],
                    selectedLender: null,
                    selected_lender_id: null,
                    vendors: [],
                    selectedVendor: null,
                    selected_vendor_id: null,
                    financingType: null,
                    source: "",
                    other_source: "",
                  })
                  clearOffersRelatedData()
                  const state = locations[0].state || ""
                  let url = `/realty/offers-v3?transaction_id=${transactionId}&req_id=${req_id}&client_id=${client_id}&client_source=${client_source}&agent_id=${agentId}`
                  if (state) {
                    const currState = getStateLowerCase(state)
                    url += `&state=${currState}`
                    setStateForOffer(currState)
                  }
                  //root.location.href = `${RADIUS_URL}${url}`
                  history.push(url)
                }}
              >
                + Write an Offer
              </BlueButton>
            </ZeroStateWrap>
          )}
        </>
      )}
    </Wrap>
  )
}

export default withRouter(Offer(OffersV2(OffersList)))
