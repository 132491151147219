import * as React from "react"

const ProductMarketplace = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m2 7.685 4.41-4.41a2 2 0 0 1 1.42-.59h8.34a2 2 0 0 1 1.42.59L22 7.685M4 12.685v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 22.685v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4M2 7.685h20M22 7.685v3a2 2 0 0 1-2 2 2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0 2.7 2.7 0 0 1-1.59.63 2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0 2.7 2.7 0 0 1-1.59.63 2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0 2.7 2.7 0 0 1-1.59.63 2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0 2.7 2.7 0 0 1-1.59.63 2 2 0 0 1-2-2v-3"
      />
    </svg>
  )
}

export default ProductMarketplace
