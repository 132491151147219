// import React, { useEffect } from "react";
// import { Header, Map } from "./styles.js";
// import ListingPageContainer from "container/ListingPage/ListingPageContainer";
// import root from "window-or-global"

// const ListingMaps = ({ listingPageResponse }) => {
//   useEffect(() => {
//     loadScript();
//   }, [
//   ]);

//   const loadScript = () => {
//     console.log("dhruvik", root)
//     // if (typeof root.google === "undefined") {
//       loadGoogleScript(
//         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
//         createMaps
//       );
//     // } else {
//     //   createMaps();
//     // }
//   };

//   const loadGoogleScript = (url, callback) => {
//     const script = root.document.createElement("script");
//     script.type = "text/javascript";

//     if (script.readyState) {
//       script.onreadystatechange = function () {
//         if (script.readyState === "loaded" || script.readyState === "complete") {
//           script.onreadystatechange = null;
//           callback();
//         }
//       };
//     } else {
//       script.onload = function () {
//         callback();
//       };
//     }

//     script.src = url;
//     root.document.getElementsByTagName("head")[0].appendChild(script);
//   };

//   const createMaps = () => {
//     // if (root?.google) {
//       const mapOptions = {
//         center: {
//           lat: parseFloat(listingPageResponse?.data?.Latitude),
//           lng: parseFloat(listingPageResponse?.data?.Longitude),
//         },
//         zoom: 15,
//       };

//       const map = root.google.maps.Map(
//         root.document.getElementById("map"),
//         mapOptions
//       );

//       const marker = root.google.maps.Marker({
//         position: {
//           lat: parseFloat(listingPageResponse?.data?.Latitude),
//           lng: parseFloat(listingPageResponse?.data?.Longitude),
//         },
//         map: map,
//         title: "Location",
//       });
//     // }
//   };

//   return (
//     <>
//       <Header>Locality</Header>
//       <Map id="map"></Map>
//     </>
//   );
// };

// export default ListingPageContainer(ListingMaps);

import React, { useEffect } from "react";
import { Header, Map } from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const ListingMaps = ({ listingPageResponse }) => {
  useEffect(() => {
    loadScript();
  }, [
    listingPageResponse?.data?.Latitude,
    listingPageResponse?.data?.Longitude,
  ]);

  const loadScript = () => {
    if (typeof window.google === "undefined") {
      loadGoogleScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
        createMaps
      );
    } else {
      createMaps();
    }
  };

  const loadGoogleScript = (url, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const createMaps = () => {
    const mapOptions = {
      center: {
        lat: parseFloat(listingPageResponse?.data?.Latitude),
        lng: parseFloat(listingPageResponse?.data?.Longitude),
      },
      zoom: 15,
    };

    const map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );

    const marker = new window.google.maps.Marker({
      position: {
        lat: parseFloat(listingPageResponse?.data?.Latitude),
        lng: parseFloat(listingPageResponse?.data?.Longitude),
      },
      map: map,
      title: "Location",
    });
  };

  return (
    <>
      <Header>Locality</Header>
      <Map id="map"></Map>
    </>
  );
};

export default ListingPageContainer(ListingMaps);
