import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import { getFormValues } from "redux-form"
import {
  getRarProductAndServices,
  saveRarTechRequest,
  sendSlackNotificationForProduct,
  getRarAssets,
  rarSaveCreativeRequest,
  saveDesignReferenceImage,
  removeDesignReferenceImage,
  getFinancingClientList,
  getFinancingSetting,
  rarSaveFinancingClientList,
  toggleFinanceModal,
  showSuccessBar,
  fetchState,
  fetchTodaysPriceAction,
  getPopupDetailsAction,
  showPopupAction,
  toggleScreenPopup,
  fetchBrandingAssetsAction,
  fetchBrandingInfoAction,
  updateBrandingInfoAction,
  uploadDocsToBucketAction,
  createBrandingInfoAction,
  setFileUploading,
  setBusinessCard,
  setYardSign,
  setIsShow,
  setPreferrerdTitle,
  setBothDone,
  getAllListings,
  getListingStatusMap,
  getListingCoverSheet,
  createListingCoverSheet,
  updateListingCoverSheet,
  setDealToView,
  setLoaderOnAgentInfo,
  updateDealStatus,
  saveListingStepsAction,
  setEscrowIdToEdit,
  getContractById,
  createContract,
  updateContract,
  setAgentGoogleContacts,
  getGoogleAuthToken,
  resetListingsForm,
  resetContractsForm,
  toggleViewListingHistory,
  toggleComfirmToGoToContract,
  toggleConfirmToExecute,
  getListingHistory,
  resetGoogleContacts,
  createOfferAction,
  updateOfferAction,
  getAllOffersAction,
  getCurrentDealDetailsAction,
  updateOfferDealStatusAction,
  resetLastDealAction,
  setCurrentVendorEditDetailsAction,
  updateVendorAction,
  toggleAllCashOfferAction,
  updateStatusNoteForChangeAction,
  updateLenderAction,
  toggleDocumentSelectionConfirm,
  toggleDocumentSentPopup,
  getListingDocuments,
  sendListingDocuments,
  createSendeView,
  createReceiverView,
  createReceiverViewOffer,
  createEditView,
  createEditViewOffer,
  downloadEnvelopeDoc,
  getListingSteps,
  downloadingDocs,
  toggleInterestFormAction,
  toggleMortgagePopupAction,
  refreshListingDocuments,
  updateDocusignEnvelopeStatus,
  fetchDealTimelineAction,
  fetchDealTCDetailsAction,
  updateDealTimelineAction,
  toggleUploadListingImages,
  imageUploadForListing,
  createAllListingImages,
  togglePreviewListingImages,
  setOfferInfoDetailsAction,
  getOfferDocumentsAction,
  toogleVoidAndRestart,
  toogleVoidAndRestartOffer,
  toogleTroubleshoot,
  sendForVoidAndRestart,
  sendForVoidAndRestartOffer,
  sendTroubleShootMessage,
  toggleUploadListingDocs,
  toggleUploadCoverLetter,
  showCoverLetterResponseAction,
  uploadListingDocToDocusign,
  toggleUploadLoader,
  toogleVoidAndDelete,
  sendForVoidAndDelete,
  regenerateListingDocuments,
  setLoaderAction,
  getOfferDocuments,
  sendOfferDocuments,
  createSendeViewOffer,
  downloadEnvelopeDocOffer,
  getOfferSteps,
  updateOfferDocusignEnvelopeStatus,
  toogleTroubleshootOffer,
  sendTroubleShootMessageOffer,
  uploadOfferDocToDocusign,
  uploadCoverLetterDocToDocusign,
  sendForVoidAndDeleteOffer,
  toogleVoidAndDeleteOffer,
  regenerateOfferDocuments,
  uploadCoverLetterAction,
  putCoverLetterDocsAction,
  fetchCoverLetterDocsAction,
  submitAndSendAllOffersFile,
  sendAllOffersDocsAction,
  sendDocusignDocsAction,
  refreshOfferDocuments,
  requestToCreateZip,
  setOfferDocumentDownloadStatus,
  setUploadedFilesNullAction,
  submitAndSendAllOffersFileReset,
  setEditingDealTeamMemberId,
  toggleAlertNavigation,
  resetTransactionState,
  toggleAddTransactionPopup,
  generateListingEnvelopeFromTemplate,
  generateOfferEnvelopeFromTemplate,
  fetchListingDocusignTemplates,
  fetchOfferocusignTemplates,
  getTemplatePreview,
  setUnselect,
  deleteCoverLetter,
  voidAndDuplicateCompletedOfferAction,
  setCurrentEnvelopeDetailsAction,
  voidAndDuplicateCompletedListingAction,
  deleteCompletedListingAction,
  deleteCompletedOfferAction,
  setComposeEmail,
  setSuccessPage,
  setOfferSentPopup,
  sendMailToListingAgents,
  setAgentId,
  toggleMortgageForm,
  sendMortgagePropertiesAction,
  sendClientDetailsAction,
  toggleNetworkAgentPopupAction,
  toggleModalPageAction,
  createContractV2Action,
  updateContractV2Action,
  showSuccessModalAction,
  getOfferContractDetailsAction,
  getContractFieldValidationAction,
  createOfferConvertedContractAction,
  createTransactionConvertedContractAction,
  dealCountUpdateAction,
  processBeforeViewingContractAction,
  createListingConvertedToContractAction,
  toggleOfferToContractConversionAction,
  toggleStatusChangeModalAction,
  setCurrentContractIDAction,
  setOfferIdToViewAction,
  toggleShowUpdateAction,
  setContentForUpdateAction,
  createSendeViewOfferChecklistAction,
  createEditViewOfferChecklistAction,
  downloadEnvelopeDocOfferChecklistAction,
  sendForVoidAndRestartOfferChecklistAction,
  sendForVoidAndDeleteOfferChecklistAction,
  createReceiverViewOfferChecklistAction,
  defineAgentTypeAction,
  setListingAddressAction,
  setTransactionDashboardTabAction,
  fetchTransactionDataAction,
  setTransactionDataObjectAction,
  fetchContractStatusesAction,
  toggleTransactionCreationForOfferAction,
  setCreateTransactionalFlowAction,
  constructTransactionQueryAction,
  clearExistingTransactionDataAction,
  fetchTransactionOverviewAction,
  deleteContractAction,
  setFilterTabOpenAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { techAssetsRarDashboard } = state
  const {
    brandingAssetsResponse,
    brandingInfoResponse,
    uploadDocsToBucketResponse,
    isShow,
    toShowMortgagePopup,
    fetchDealTimelineResponse,
    fetchDealTCDetailsResponse,
    offerInfoDetails,
    currentListingContractID,
    transactionQuery,
  } = techAssetsRarDashboard
  const assetsCreativeRequestForm = getFormValues("CREATIVE_REQUEST_FORM")(state)
  const mortgageForm = getFormValues("MORTGAGE_FORM")(state)
  const flyHomesForm = getFormValues("FLYHOMES_FORM")(state)
  const zoomCasaForm = getFormValues("ZOOM_CASA")(state)
  const curbioForm = getFormValues("CURBIO_FORM")(state)
  const brandingForm1 = getFormValues("BRANDING_FORM_1")(state)
  const brandingForm2 = getFormValues("BRANDING_FORM_2")(state)
  const brandingForm3 = getFormValues("BRANDING_FORM_3")(state)
  const isLogged = authCheck()
  const brandingFlowForms = {
    brandingForm1,
    brandingForm2,
    brandingForm3,
  }
  return {
    isLogged,
    ...techAssetsRarDashboard,
    assetsCreativeRequestForm,
    mortgageForm,
    flyHomesForm,
    zoomCasaForm,
    curbioForm,
    ...brandingFlowForms,
    brandingAssetsResponse,
    brandingInfoResponse,
    uploadDocsToBucketResponse,
    isShow,
    toShowMortgagePopup,
    fetchDealTimelineResponse,
    fetchDealTCDetailsResponse,
    offerInfoDetails,
    currentListingContractID,
    transactionQuery,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      getRarProductAndServices: getRarProductAndServices.request,
      saveRarTechRequest: saveRarTechRequest.request,
      sendSlackNotificationForProduct: sendSlackNotificationForProduct.request,
      getRarAssets: getRarAssets.request,
      rarSaveCreativeRequest: rarSaveCreativeRequest.request,
      saveDesignReferenceImage: saveDesignReferenceImage.request,
      removeDesignReferenceImage: removeDesignReferenceImage.request,
      getFinancingClientList: getFinancingClientList.request,
      getFinancingSetting: getFinancingSetting.request,
      rarSaveFinancingClientList: rarSaveFinancingClientList.request,
      toggleFinanceModal: toggleFinanceModal.request,
      showSuccessBar: showSuccessBar.request,
      fetchState: fetchState.request,
      toggleScreenPopup: toggleScreenPopup.call,
      setFileUploading: setFileUploading.call,
      setPreferrerdTitle: setPreferrerdTitle.call,
      setBusinessCard: setBusinessCard.call,
      setYardSign: setYardSign.call,
      setBothDone: setBothDone.call,
      setIsShow: setIsShow.call,
      fetchTodaysPrice: fetchTodaysPriceAction.request,
      getPopupDetails: getPopupDetailsAction.request,
      showPopup: showPopupAction.call,
      fetchBrandingAssets: fetchBrandingAssetsAction.request,
      fetchBrandingInfo: fetchBrandingInfoAction.request,
      updateBrandingInfo: updateBrandingInfoAction.request,
      createBrandingInfo: createBrandingInfoAction.request,
      uploadDocsToBucket: uploadDocsToBucketAction.request,

      getAllListings: getAllListings.request,
      getListingStatusMap: getListingStatusMap.request,
      updateDealStatus: updateDealStatus.request,
      saveListingSteps: saveListingStepsAction.request,
      getListingCoverSheet: getListingCoverSheet.request,
      createListingCoverSheet: createListingCoverSheet.request,
      updateListingCoverSheet: updateListingCoverSheet.request,
      setAgentId: setAgentId.call,
      // contracts
      getContractById: getContractById.request,
      createContract: createContract.request,
      updateContract: updateContract.request,
      createContractV2: createContractV2Action.request,
      updateContractV2: updateContractV2Action.request,
      showSuccessModal: showSuccessModalAction.call,
      createOfferConvertedContract: createOfferConvertedContractAction.request,

      setDealToView: setDealToView.call,
      setLoaderOnAgentInfo: setLoaderOnAgentInfo.call,
      setEscrowIdToEdit: setEscrowIdToEdit.call,

      setAgentGoogleContacts: setAgentGoogleContacts.call,
      getGoogleAuthToken: getGoogleAuthToken.request,
      resetContractsForm: resetContractsForm.request,
      resetListingsForm: resetListingsForm.request,

      toggleViewListingHistory: toggleViewListingHistory.call,
      toggleComfirmToGoToContract: toggleComfirmToGoToContract.call,
      toggleConfirmToExecute: toggleConfirmToExecute.call,
      getListingHistory: getListingHistory.request,
      resetGoogleContacts: resetGoogleContacts.call,

      fetchDealTimeline: fetchDealTimelineAction.request,
      fetchDealTCDetails: fetchDealTCDetailsAction.request,
      updateDealTimeline: updateDealTimelineAction.request,

      //offers
      getDealDetails: getCurrentDealDetailsAction.request,
      getOffers: getAllOffersAction.request,
      createOffer: createOfferAction.request,
      updateOffer: updateOfferAction.request,
      updateOfferStatus: updateOfferDealStatusAction.request,
      resetForm: resetLastDealAction.call,
      setCurrentVendorEditDetails: setCurrentVendorEditDetailsAction.call,
      updateVendor: updateVendorAction.request,
      updateLender: updateLenderAction.request,
      toggleAllCashOffer: toggleAllCashOfferAction.call,
      updateNote: updateStatusNoteForChangeAction.call,
      toggleDocumentSelectionConfirm: toggleDocumentSelectionConfirm.call,
      toggleDocumentSentPopup: toggleDocumentSentPopup.call,
      getListingDocuments: getListingDocuments.request,
      sendListingDocuments: sendListingDocuments.request,
      createSendeView: createSendeView.request,
      createReceiverView: createReceiverView.request,
      putCoverLetterDocs: putCoverLetterDocsAction.request,
      fetchCoverLetterDocs: fetchCoverLetterDocsAction.request,
      setUploadedFilesNull: setUploadedFilesNullAction.call,
      setUnselect: setUnselect.call,
      // createReceiverViewOffer: createReceiverViewOffer.request,
      createEditView: createEditView.request,
      createEditViewOffer: createEditViewOffer.request,
      downloadEnvelopeDoc: downloadEnvelopeDoc.request,
      getListingSteps: getListingSteps.request,
      downloadingDocs: downloadingDocs.call,
      toggleInterestForm: toggleInterestFormAction.call,
      toggleMortgagePopup: toggleMortgagePopupAction.call,
      refreshListingDocuments: refreshListingDocuments.request,
      updateDocusignEnvelopeStatus: updateDocusignEnvelopeStatus.request,
      toggleUploadListingImages: toggleUploadListingImages.call,
      toggleUploadListingDocs: toggleUploadListingDocs.call,
      toggleUploadCoverLetter: toggleUploadCoverLetter.call,
      showCoverLetter: showCoverLetterResponseAction.request,
      imageUploadForListing: imageUploadForListing.request,
      createAllListingImages: createAllListingImages.request,
      togglePreviewListingImages: togglePreviewListingImages.call,
      setOfferInfoDetails: setOfferInfoDetailsAction.call,
      setLoader: setLoaderAction.call,


      toogleVoidAndRestart: toogleVoidAndRestart.call,
      toogleTroubleshoot: toogleTroubleshoot.call,
      sendForVoidAndRestart: sendForVoidAndRestart.request,
      // sendForVoidAndRestartOffer: sendForVoidAndRestartOffer.request,
      sendTroubleShootMessage: sendTroubleShootMessage.request,
      uploadListingDocToDocusign: uploadListingDocToDocusign.request,
      toggleUploadLoader: toggleUploadLoader.call,
      sendForVoidAndDelete: sendForVoidAndDelete.request,
      toogleVoidAndDelete: toogleVoidAndDelete.call,
      regenerateListingDocuments: regenerateListingDocuments.request,

      //Docusign Offers Page Actions

      getOfferDocuments: getOfferDocuments.request,
      sendOfferDocuments: sendOfferDocuments.request,
      createSendeViewOffer: createSendeViewOffer.request,
      createReceiverViewOffer: createReceiverViewOffer.request,
      downloadEnvelopeDocOffer: downloadEnvelopeDocOffer.request,
      getOfferSteps: getOfferSteps.request,
      updateOfferDocusignEnvelopeStatus: updateOfferDocusignEnvelopeStatus.request,
      sendForVoidAndRestartOffer: sendForVoidAndRestartOffer.request,
      sendTroubleShootMessageOffer: sendTroubleShootMessageOffer.request,
      uploadOfferDocToDocusign: uploadOfferDocToDocusign.request,
      uploadCoverLetterDocToDocusign: uploadCoverLetterDocToDocusign.request,
      sendForVoidAndDeleteOffer: sendForVoidAndDeleteOffer.request,
      regenerateOfferDocuments: regenerateOfferDocuments.request,
      toogleVoidAndRestartOffer: toogleVoidAndRestartOffer.call,
      toogleTroubleshootOffer: toogleTroubleshootOffer.call,
      toogleVoidAndDeleteOffer: toogleVoidAndDeleteOffer.call,
      submitAndSendAllOffersFile: submitAndSendAllOffersFile.request,
      sendAllOffersDocs: sendAllOffersDocsAction.request,
      sendDocusignDocs: sendDocusignDocsAction.request,
      refreshOfferDocuments: refreshOfferDocuments.request,
      uploadCoverLetter: uploadCoverLetterAction.request,
      requestToCreateZip: requestToCreateZip.request,
      setOfferDocumentDownloadStatus: setOfferDocumentDownloadStatus.call,
      submitAndSendAllOffersFileReset: submitAndSendAllOffersFileReset.call,
      setEditingDealTeamMemberId: setEditingDealTeamMemberId.call,
      toggleAlertNavigation: toggleAlertNavigation.call,
      resetTransactionState: resetTransactionState.call,
      toggleAddTransactionPopup: toggleAddTransactionPopup.call,
      generateListingEnvelopeFromTemplate: generateListingEnvelopeFromTemplate.request,
      generateOfferEnvelopeFromTemplate: generateOfferEnvelopeFromTemplate.request,
      fetchListingDocusignTemplates: fetchListingDocusignTemplates.request,
      fetchOfferocusignTemplates: fetchOfferocusignTemplates.request,
      getTemplatePreview: getTemplatePreview.request,
      voidAndDuplicateCompletedOffer: voidAndDuplicateCompletedOfferAction.request,
      voidAndDuplicateCompletedListing: voidAndDuplicateCompletedListingAction.request,
      deleteCompletedListing: deleteCompletedListingAction.request,
      deleteCompletedOffer: deleteCompletedOfferAction.request,
      setCurrentEnvelopeDetails: setCurrentEnvelopeDetailsAction.call,
      deleteCoverLetter: deleteCoverLetter.request,
      setComposeEmail: setComposeEmail.call,
      setSuccessPage: setSuccessPage.call,
      setOfferSentPopup: setOfferSentPopup.call,
      sendMailToListingAgents: sendMailToListingAgents.request,
      toggleMortgageForm: toggleMortgageForm.call,
      sendPropertyDetails: sendMortgagePropertiesAction.request,
      sendClientDetails: sendClientDetailsAction.request,
      toggleNetworkAgentPopup: toggleNetworkAgentPopupAction.call,
      toggleModalPage: toggleModalPageAction.call,
      getOfferContractDetails: getOfferContractDetailsAction.request,

      //contract validation
      getContractFieldValidation: getContractFieldValidationAction.request,
      createTransactionConvertedContract: createTransactionConvertedContractAction.request,
      dealCountUpdate: dealCountUpdateAction.call,
      processBeforeViewingContract: processBeforeViewingContractAction.request,
      createListingConvertedToContract: createListingConvertedToContractAction.request,
      toggleOfferToContractConversion: toggleOfferToContractConversionAction.call,
      toggleStatusChangeModalOffer: toggleStatusChangeModalAction.call,
      setCurrentContractID: setCurrentContractIDAction.call,
      setOfferIdToView: setOfferIdToViewAction.call,
      toggleShowUpdate: toggleShowUpdateAction.call,
      setContentForUpdate: setContentForUpdateAction.call,
      createSendeViewOfferChecklist: createSendeViewOfferChecklistAction.request,
      createEditViewOfferChecklist: createEditViewOfferChecklistAction.request,
      downloadEnvelopeDocOfferChecklist: downloadEnvelopeDocOfferChecklistAction.request,
      sendForVoidAndRestartOfferChecklist: sendForVoidAndRestartOfferChecklistAction.request,
      sendForVoidAndDeleteOfferChecklist: sendForVoidAndDeleteOfferChecklistAction.request,
      createReceiverViewOfferChecklist: createReceiverViewOfferChecklistAction.request,
      defineAgentType: defineAgentTypeAction.call,
      setListingAddress: setListingAddressAction.call,
      setTransactionDashboardTab: setTransactionDashboardTabAction.call,
      fetchTransactionData: fetchTransactionDataAction.request,
      setTransactionData: setTransactionDataObjectAction.call,
      fetchContractStatuses: fetchContractStatusesAction.request,
      toggleTransactionCreationForOffer: toggleTransactionCreationForOfferAction.call,
      setCreateTransactionalFlow: setCreateTransactionalFlowAction.call,
      constructTransaction: constructTransactionQueryAction.call,
      clearExistingTransactionData: clearExistingTransactionDataAction.call,
      fetchTransactionOverview: fetchTransactionOverviewAction.request,
      deleteContract: deleteContractAction.request,
      setFilterTabOpen: setFilterTabOpenAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)

