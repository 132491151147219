import axios from "axios";

// get apis
export const fetchPresignedDocs = (agentId, clientId) =>
  axios.get(`${API_URL}fetch-uploaded-brbc-documents/${agentId}/${clientId}`);

export const sendBrbcReminder = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/reminder`, payload);

export const addPresignedDocumentAPI = (payload) =>
  axios.post(`${API_URL}upload-brbc-documents`, payload);

export const fetchBrbcStatusAPI = (agentId, clientId) =>
  axios.get(`${API_URL}fetch-brbc-status/${agentId}/${clientId}`);

export const createBrbcEnvelope = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes`, payload);

export const sendBrbcEnvelopeAPI = (payload) =>
  axios.put(`${API_URL}brokerage-brbc-docs/envelopes`, payload);

export const receiverViewAPI = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/receiver-view`, payload);

export const voidAndRestartBrbcAPI = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/void-restart`, payload);

export const voidBrbcAPI = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/void`, payload);

export const fetchBrbcDoc = (brbcId) =>
  axios.get(`${API_URL}brokerage-brbc-docs/envelopes?brbcId=${brbcId}`);

export const senderViewAPI = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/sender-view`, payload);

export const fetchBrbcCoversheetAPI = (agentId, clientId) =>
  axios.get(`${API_URL}fetch-brbc-coversheet/${agentId}/${clientId}`);

export const addNewBrbcCoversheetAPI = (payload) =>
  axios.post(`${API_URL}add-brbc-coversheet`, payload);

export const editBrbcCoversheet = (brbcId, payload) =>
  axios.put(`${API_URL}edit-brbc-coversheet/${brbcId}`, payload);

export const getAgentDetailsFromStateAPI = (agentId, state) =>
  axios.get(
    `${API_URL}fetch-agent-info-coversheet?state=${state}&agentId=${agentId}`
  );

export const deleteBrbcEnvelopeAPI = (payload) =>
  axios.post(`${API_URL}brokerage-brbc-docs/envelopes/delete`, payload);

export const deleteCoBuyerAPI = (clientId) =>
  axios.delete(`${API_V3_URL}client-transaction-co-client/${clientId}`);

export const deleteUploadedBrbcAPI = (agentId, clientId, documentId) =>
  axios.delete(
    `${API_URL}delete-brbc-uploaded-documents/${agentId}/${clientId}/${documentId}`
  );
