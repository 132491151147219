import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div``

export const ModalTitle = styled.div`
  margin-bottom: 5px;
  p {
    color: var(--Gray-900, #101828);
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 149%; /* 26.82px */
    margin: 0;
  }
`
export const SectionLabelWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 32px;
`

export const SectionCircle = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35.714px;
  border: 0.967px solid var(--Color-Primary-Primary, #5A5FF2);
  background: var(--Color-Primary-Primary, #5A5FF2);

  p {
    color: var(--base-white, #FFF);
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 169.231% */
    margin: 0;
  }
`

export const SectionText = styled.div`
  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
`

export const InputWrap = styled.div`
  margin-top: 32px;
`

export const CTAWrap = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 5px;
`

export const ModalDescription = styled.div`
  padding-bottom: 16px;
  p {
    color: #26293F;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
`
export const FormWrapper = styled.div``
