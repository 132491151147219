import React from "react"
import {
  InnerTab,
  ApplyButton,
} from "./commonStyles"
import JoinTheBrokerage from "../Icons/NewIcons/JoinTheBrokerage"

const JoinBrokerageTab = ({
  routeArray,
  isBroker,
  handleApplyNowVersion2,
}) => (
  <InnerTab show={!isBroker} isActive={routeArray[1] === "marketing-assets"}>
    <div className="icon-block-new">
      <JoinTheBrokerage />
    </div>
    <div className="text-block">
      <ApplyButton
        onClick={handleApplyNowVersion2}
      >
        Join the Brokerage
      </ApplyButton>
    </div>
  </InnerTab>
)

export default JoinBrokerageTab
