import React, { useEffect } from "react"
import uuid from "uuid"
import { getFinancingTypeOptions } from "dumbComponents/OffersV2/consts"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/OffersV2"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  CTAWrap,
  ListWrap,
} from "./styles"
import AgentCard from "../AgentCard/index"
import BuyerInformationCard from "../BuyerInformationCard"
import LenderCard from "../LenderInformationCard"
import VendorCard from "../VendorInformationCard"
import {
  AddButtonCTA,
} from "../../../components"
import {
  SelectField,
} from "../InputFields"
import {
  BlueButton,
} from "../../../commonStyles"
import {
  BuyerAgentForm,
  BuyerForm,
  LenderForm,
  VendorForm,
} from "./Forms"

const STEP = "buyers_information_mobile"

const BuyerInformation = ({
  isCoAgentFormVisible,
  isAddNewLenderFormVisible,
  isAddNewVendorFormVisible,
  buyersAgentInformationObject,
  addNewCoAgentForm,
  updateBuyerInformationObject,
  isBuyerFormVisible,
  addBuyerAgentForm,
  addLenderAgentForm,
  addVendorAgentForm,
  stateForOffer,
  updateBuyerInformationTab,
  dealToView,
  toggleBasicOfferDetailsModal,
  getCurrentDealDetailsResponse,
  updateBuyerInformationTabResponse,
}) => {
  const mainAgent = buyersAgentInformationObject.agents?.filter(item => item.isMainAgent)[0] || ""
  const coAgents = buyersAgentInformationObject.agents?.filter(item => !item.isMainAgent) || ""
  const {
    buyers,
    financingType,
  } = buyersAgentInformationObject || {}

  const {
    isFetching: fetchingBuyerData,
  } = getCurrentDealDetailsResponse || {}

  const {
    isFetching: updating,
  } = updateBuyerInformationTabResponse || {}

  useEffect(() => {
    if (!mainAgent && !fetchingBuyerData) {
      //toggleBasicOfferDetailsModal(true)
    }
  }, [mainAgent, fetchingBuyerData])

  if (!mainAgent) {
    return <></>
  }

  const handleGoBack = () => {
    addNewCoAgentForm(false)
  }

  const handleBuyerGoBack = () => {
    addBuyerAgentForm(false)
  }

  const handleLenderGoBack = () => {
    addLenderAgentForm(false)
  }

  const handleVendorGoBack = () => {
    addVendorAgentForm(false)
  }

  //handle main agent
  const onFinishMainAgent = (values) => {
    const data = {
      ...mainAgent,
      ...values,
      isEditModeVisible: false,
    }
    const payload = {
      ...buyersAgentInformationObject,
    }

    payload.agents[0] = data

    updateBuyerInformationObject(payload)
  }

  const onFinishEditBuyer = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    console.log("values", values)

    const currentEditBuyer = payload.buyers.filter(item => item.isBuyerEditFormVisible)[0]
    const currentEditBuyerID = currentEditBuyer.f_id

    const updated = {
      ...currentEditBuyer,
      ...values,
      isBuyerEditFormVisible: false,
    }

    payload.buyers.forEach((item, iter) => {
      if (item.f_id === currentEditBuyerID) {
        payload.buyers[iter] = { ...updated }
      }
    })

    updateBuyerInformationObject(payload)
  }

  const handleDeleteBuyer = (id) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const filteredBuyers = payload.buyers.filter(item => item.f_id !== id)

    payload.buyers = [...filteredBuyers]

    updateBuyerInformationObject(payload)
  }

  const handleToggleEditMode = (id) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentAgents = [...buyersAgentInformationObject.agents]

    currentAgents.forEach((item) => {
      const curr = item
      if (item.id === id) {
        curr.isEditModeVisible = !item.isEditModeVisible
      }
    })

    payload.agents = [...currentAgents]

    updateBuyerInformationObject(payload)
  }

  const handleBuyerToggleEditMode = (id) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentBuyers = [...buyersAgentInformationObject.buyers]

    currentBuyers.forEach((item) => {
      const curr = item
      if (item.f_id === id) {
        curr.isBuyerEditFormVisible = !item.isBuyerEditFormVisible
      }
    })

    payload.buyers = [...currentBuyers]

    updateBuyerInformationObject(payload)
  }

  const handleLenderEditToggle = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelectedLender = buyersAgentInformationObject.selectedLender

    currentSelectedLender.isLenderEditVisible = !currentSelectedLender.isLenderEditVisible

    payload.selectedLender = currentSelectedLender

    updateBuyerInformationObject(payload)
  }

  const onFinishAddBuyer = (values) => {
    const splitName = values.name.split(" ")
    const firstname = splitName[0]
    const lastname = splitName && splitName.length > 0 && splitName[1]

    const data = {
      ...values,
      isBuyerEditFormVisible: false,
      client_id: null,
      f_id: uuid.v4(),
      firstname,
      lastname,
      is_primary: buyers && buyers.length > 0 ? 0 : 1,
    }

    const payload = {
      ...buyersAgentInformationObject,
    }

    payload.buyers = [...buyersAgentInformationObject.buyers, data]

    updateBuyerInformationObject(payload)
    handleBuyerGoBack()
  }

  /*CO AGENT BLOCK */
  const onFinishAddCoAgent = (values) => {
    console.log("co", values)
    const data = {
      ...values,
      isMainAgent: false,
      isEditModeVisible: false,
      id: uuid.v4(),
    }
    const payload = {
      ...buyersAgentInformationObject,
    }

    payload.agents = [...buyersAgentInformationObject.agents, data]

    updateBuyerInformationObject(payload)
    handleGoBack()
  }

  const onFinishEditCoAgent = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentEditCoAgent = coAgents.filter(item => item.isEditModeVisible)[0]
    const currentEditCoAgentId = currentEditCoAgent.id

    const updated = {
      ...currentEditCoAgentId,
      ...values,
      isEditModeVisible: false,
    }

    coAgents.forEach((item, iter) => {
      if (item.id === currentEditCoAgentId) {
        coAgents[iter] = { ...updated }
      }
    })

    payload.agents = [mainAgent, ...coAgents]

    updateBuyerInformationObject(payload)
  }

  const handleDeleteCoAgent = (id) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const filteredAgents = payload.agents.filter(item => item.id !== id)

    payload.agents = [...filteredAgents]

    updateBuyerInformationObject(payload)
  }
  /*CO AGENT BLOCK */

  /**HANDLE LENDER FUNCTIONS*/
  const handleFinancingTypeChange = (value) => {
    const options = getFinancingTypeOptions(stateForOffer)

    const filteredForValue = options.filter(item => item.value === value)

    const payload = {
      ...buyersAgentInformationObject,
      financingType: filteredForValue[0].value
    }

    updateBuyerInformationObject(payload)
  }

  const onFinishAddLender = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const id = uuid.v4()

    const newLender = {
      ...value,
      value: id,
      lender_id: id,
      label: value.lender_officer_name,
      isLenderEditVisible: false,
    }

    const lenders = [...payload.lenders, newLender]
    payload.lenders = [...lenders]

    updateBuyerInformationObject(payload)
    addLenderAgentForm(false)
  }

  const onFinishEditLender = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentEditLender = payload.selectedLender

    const updated = {
      ...currentEditLender,
      ...values,
      isLenderEditVisible: false,
    }

    payload.lenders.forEach((item, iter) => {
      if (item.lender_id === currentEditLender.lender_id) {
        payload.lenders[iter] = { ...updated }
      }
    })

    payload.selectedLender = { ...updated }

    updateBuyerInformationObject(payload)
  }

  const handleChangeForLender = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelected = payload.lenders.filter(item => item.value === value)[0]
    payload.selectedLender = currentSelected
    payload.selected_lender_id = currentSelected.lender_id

    updateBuyerInformationObject(payload)
  }
  /**HANDLE LENDER FUNCTIONS*/

  /**HANDLE VENDOR FUNCTIONS*/
  const onFinishAddVendor = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const id = uuid.v4()

    const newVendor = {
      ...value,
      value: id,
      vendor_id: id,
      label: value.vendor_officer_name,
      isVendorEditVisible: false,
    }

    const vendors = [...payload.vendors, newVendor]
    payload.vendors = [...vendors]

    updateBuyerInformationObject(payload)
    addVendorAgentForm(false)
  }

  const onFinishEditVendor = (values) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentEditVendor = payload.selectedVendor

    const updated = {
      ...currentEditVendor,
      ...values,
      isVendorEditVisible: false,
    }

    payload.vendors.forEach((item, iter) => {
      if (item.vendor_id === currentEditVendor.vendor_id) {
        payload.vendors[iter] = { ...updated }
      }
    })

    payload.selectedVendor = { ...updated }

    updateBuyerInformationObject(payload)
  }

  //handleLenderEditToggle
  const handleVendorEditToggle = () => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelectedVendor = payload.selectedVendor

    currentSelectedVendor.isVendorEditVisible = !currentSelectedVendor.isVendorEditVisible

    payload.selectedVendor = currentSelectedVendor

    updateBuyerInformationObject(payload)
  }

  const handleChangeForVendor = (value) => {
    const payload = {
      ...buyersAgentInformationObject,
    }

    const currentSelected = payload.vendors.filter(item => item.value === value)[0]
    payload.selectedVendor = currentSelected
    payload.selected_vendor_id = currentSelected.id

    updateBuyerInformationObject(payload)
  }
  /**HANDLE VENDOR FUNCTIONS*/

  const handleFinalSubmit = () => {
    console.log(buyersAgentInformationObject)
    const {
      agents,
      buyers: currentBuyers,
      selected_lender_id = null,
      selected_vendor_id = null,
      financingType: currentFinancingType,
      source,
    } = buyersAgentInformationObject || {}

    const isAllCashOrSelfFinancing = !selected_lender_id
    && currentFinancingType === "cash"
    || currentFinancingType === "seller_financing" ? 1 : 0
    
    const payload = {
      clients: [...currentBuyers],
      source,
      other_source: "",
      agents: [...agents],
      vendor_id: selected_vendor_id,
      lender_id: selected_lender_id,
      is_all_cash: isAllCashOrSelfFinancing,
      financing_information: currentFinancingType,
      draft: 0,
      current_offer_step: STEP,
    }

    updateBuyerInformationTab({
      id: dealToView,
      payload,
    })
  }

  return (
    <Wrap>
      {/*Buyer's Agent Information*/}
      <SectionWrap>
        <SectionTitle>
          <p>Buyer&apos;s Agent Information</p>
        </SectionTitle>
        <ListWrap>
          {mainAgent && mainAgent.isEditModeVisible ? (
            <BuyerAgentForm
              title="Edit Buyer's Agent Information"
              prefilledData={mainAgent}
              handleEditModeChange={handleToggleEditMode}
              onFinish={onFinishMainAgent}
            />
          ) : (
            <AgentCard
              data={mainAgent}
              editAgent={() => handleToggleEditMode(mainAgent.id)}
            />
          )}
          {coAgents && coAgents.length > 0 && coAgents.map((item, iter) => (
            <>
              {item.isEditModeVisible ? (
                <BuyerAgentForm
                  title="Edit Buyer's Co-Agent Information"
                  prefilledData={item}
                  handleEditModeChange={handleToggleEditMode}
                  onFinish={onFinishEditCoAgent}
                  deleteAgent={handleDeleteCoAgent}
                />
              ) : (
                <AgentCard
                  data={item}
                  title={`Buyer's Co-Agent Information ${iter + 1}`}
                  editAgent={() => handleToggleEditMode(item.id)}
                />
              )}
            </>
          ))}
          {isCoAgentFormVisible ? (
            <BuyerAgentForm
              title="Co-Agent Information"
              isAddCoAgentForm
              handleEditModeChange={handleGoBack}
              onFinish={onFinishAddCoAgent}
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA name="ADD A CO-AGENT" onClick={() => addNewCoAgentForm(true)} />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Buyer's Information*/}
      <SectionWrap>
        <SectionTitle>
          <p>Buyer&apos;s Information</p>
        </SectionTitle>
        <ListWrap>
          {buyers && buyers.length > 0 && buyers.map((item, iter) => (
            <>
              {item.isBuyerEditFormVisible ? (
                <BuyerForm
                  title="Buyer Information"
                  prefilledData={item}
                  handleEditModeChange={handleBuyerToggleEditMode}
                  onFinish={onFinishEditBuyer}
                  deleteBuyer={handleDeleteBuyer}
                />
              ) : (
                <BuyerInformationCard
                  data={item}
                  title={iter > 0 ? `Co-Buyer ${iter} information` : "Buyer's Information"}
                  editBuyer={() => handleBuyerToggleEditMode(item.f_id)}
                />
              )}
            </>
          ))}
          {isBuyerFormVisible ? (
            <BuyerForm
              title="Co-Buyer Information"
              handleEditModeChange={handleBuyerGoBack}
              onFinish={onFinishAddBuyer}
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA
                name={buyers.length > 0 ? "ADD A CO-BUYER" : "ADD BUYER"}
                onClick={() => addBuyerAgentForm(true)}
              />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Lendor's Information*/}
      {/*Financing Type*/}
      <SectionWrap>
        <SectionTitle>
          <p>Financing Information</p>
        </SectionTitle>
        <ListWrap>
          <SelectField
            options={getFinancingTypeOptions(stateForOffer)}
            placeholder="Select Financing Type"
            defaultValue={buyersAgentInformationObject?.financingType}
            onChange={handleFinancingTypeChange}
          />
          {financingType && 
          (financingType !== "cash" && financingType !== "seller_financing") && (
            <SelectField
              options={[...buyersAgentInformationObject.lenders]}
              placeholder="Select Lender"
              defaultValue={buyersAgentInformationObject?.selected_lender_id}
              onChange={handleChangeForLender}
            />
          )}
          
          {buyersAgentInformationObject.selectedLender && (
            <>
              {buyersAgentInformationObject.selectedLender.isLenderEditVisible ? (
                <LenderForm
                  title="Edit Lender Information"
                  prefilledData={buyersAgentInformationObject.selectedLender}
                  handleEditModeChange={() => handleLenderEditToggle()}
                  onFinish={onFinishEditLender}
                />
              ) : (
                <LenderCard
                  title="Lender Information"
                  data={buyersAgentInformationObject.selectedLender}
                  editLender={() => handleLenderEditToggle()}
                />
              )}
            </>
          )}
          {isAddNewLenderFormVisible ? (
            <LenderForm
              title="New Lender Information"
              handleEditModeChange={handleLenderGoBack}
              onFinish={onFinishAddLender}
              isAddNewLender
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA name="ADD A NEW LENDER" onClick={() => addLenderAgentForm(true)} />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Buyer's Information*/}
      <SectionWrap>
        <SectionTitle>
          <p>Escrow &amp; Title Information</p>
        </SectionTitle>
        <ListWrap>
          <SelectField
            options={[...buyersAgentInformationObject.vendors]}
            placeholder="Select Vendor"
            defaultValue={buyersAgentInformationObject?.selected_vendor_id}
            onChange={handleChangeForVendor}
          />
          {buyersAgentInformationObject.selectedVendor && (
            <>
              {buyersAgentInformationObject.selectedVendor.isVendorEditVisible ? (
                <VendorForm
                  title="Edit Vendor Information"
                  prefilledData={buyersAgentInformationObject.selectedVendor}
                  handleEditModeChange={() => handleVendorEditToggle()}
                  onFinish={onFinishEditVendor}
                />
              ) : (
                <VendorCard
                  title="Vendor Information"
                  data={buyersAgentInformationObject.selectedVendor}
                  editVendor={() => handleVendorEditToggle()}
                />
              )}
            </>
          )}
          {isAddNewVendorFormVisible ? (
            <VendorForm
              title="New Vendor Information"
              handleEditModeChange={handleVendorGoBack}
              onFinish={onFinishAddVendor}
              isAddNewVendor
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA name="ADD A NEW VENDOR" onClick={() => addVendorAgentForm(true)} />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Submit Form*/}
      <SectionWrap>
        <BlueButton
          maxWidth
          onClick={handleFinalSubmit}
          disabled={updating}
        >
          {updating ? (
            <ThreeDotLoader color="#ffffff" />
          ) : (
            <p>Save &amp; continue</p>
          )}
        </BlueButton>
      </SectionWrap>
    </Wrap>
  )
}

export default OffersV2(BuyerInformation)
