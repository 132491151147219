import { call, put, takeLatest } from "redux-saga/effects";
import { isSuccess } from "services/Utils";
import { toast as createToast } from "react-toastify";
import {
  copyAppInviteLinkAction,
  fetchAllClientsAction,
  fetchRecommendedPropertiesAction,
  getAppStatusAction,
  inviteBulkClientsAction,
  inviteClientsAction,
  joinWaitlistAction,
  rejectPropertyStatusAction,
  removeBulkPropertyAction,
  turnOnMelRecommendationsAction,
} from "./actions";
import {
  copyAppInviteLinkAPI,
  fetchAllClientsAPI,
  fetchRecommendedPropertiesAPI,
  getAppStatusAPI,
  inviteBulkClientsAPI,
  inviteClientsAPI,
  joinWaitlistAPI,
  rejectPropertyStatusAPI,
  removeBulkPropertyAPI,
  turnOnMelRecommendationsAPI,
} from "./api";
import { message, Upload } from "antd";

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
};

function* fetchAllClientsSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(fetchAllClientsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAllClientsAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(fetchAllClientsAction.failure(error.message));
  }
}

function* copyAppInviteLinkSaga(action) {
  try {
    const data = yield call(copyAppInviteLinkAPI);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(copyAppInviteLinkAction.success(response));
      navigator.clipboard
        .writeText(response.one_link_url)
        .then(() => {
          message.success(`Link copied to clipboard`);
        })
        .catch((error) => {
          
        });
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(copyAppInviteLinkAction.failure(error.message));
  }
}

function* inviteBulkClientSaga(action) {
  try {
    const { payload, callback } = action.data;
    const data = yield call(inviteBulkClientsAPI);
    if (isSuccess(data)) {
      const { response } = data.data;
      message.success(`Invite sent via email / SMS`);

      yield put(inviteBulkClientsAction.success(response));
      if (callback) {
        callback();
        return;
      }
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(inviteBulkClientsAction.failure(error.message));
  }
}

function* joinWaitlistSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(joinWaitlistAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(joinWaitlistAction.success(response));
      message.success("Joined the waitlist successfully");
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(joinWaitlistAction.failure(error.message));
  }
}

function* inviteClientsSaga(action) {
  try {
    const {payload, callback} = action.data;
    const data = yield call(inviteClientsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(inviteClientsAction.success(response));
      message.success(`Client(s) invited successfully`);
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(inviteClientsAction.failure(error.message));
  }
}

function* getAppStatusSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getAppStatusAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getAppStatusAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getAppStatusAction.failure(error.message));
  }
}

function* turnOnMelRecommendationSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(turnOnMelRecommendationsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(turnOnMelRecommendationsAction.success(response));
      message.success("Updated successfully");
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(turnOnMelRecommendationsAction.failure(error.message));
  }
}

function* fetchRecommendedPropertiesSaga(action) {
  try {
    const { clientId, reqId, likeStatus, date, limit, skip, startDate, endDate} = action.data;

    const data = yield call(fetchRecommendedPropertiesAPI, clientId, reqId, likeStatus, date, limit, skip, startDate, endDate);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchRecommendedPropertiesAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(fetchRecommendedPropertiesAction.failure(error.message));
  }
}

function* removeBulkPropertySaga(action) {
  try {
    const { payload, callback} = action.data;

    const data = yield call(removeBulkPropertyAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(removeBulkPropertyAction.success(response));
      if (callback) callback()
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(removeBulkPropertyAction.failure(error.message));
  }
}

function* rejectPropertyStatusSaga(action) {
  try {
    const { payload, callback} = action.data;

    const data = yield call(rejectPropertyStatusAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(rejectPropertyStatusAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(rejectPropertyStatusAction.failure(error.message));
  }
}



export default function* main() {
  yield takeLatest(fetchAllClientsAction.REQUEST, fetchAllClientsSaga);
  yield takeLatest(copyAppInviteLinkAction.REQUEST, copyAppInviteLinkSaga);
  yield takeLatest(inviteBulkClientsAction.REQUEST, inviteBulkClientSaga);
  yield takeLatest(joinWaitlistAction.REQUEST, joinWaitlistSaga);
  yield takeLatest(inviteClientsAction.REQUEST, inviteClientsSaga);
  yield takeLatest(getAppStatusAction.REQUEST, getAppStatusSaga);
  yield takeLatest(turnOnMelRecommendationsAction.REQUEST, turnOnMelRecommendationSaga)
  yield takeLatest(fetchRecommendedPropertiesAction.REQUEST, fetchRecommendedPropertiesSaga)
  yield takeLatest(rejectPropertyStatusAction.REQUEST, rejectPropertyStatusSaga)
  yield takeLatest(removeBulkPropertyAction.REQUEST, removeBulkPropertySaga)

}
