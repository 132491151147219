import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import colors from "@colors"

const Wrapper = styled.div`
  width: 100px;
  font-size: 12px;
  color: ${props => props.isInvalid ? colors.redText : colors.tagInactive};
  text-align: right;
`

const POST_CHARACTER_LIMIT = 1000

const CharacterCount = ({ text }) => {
  const textLength = text.length || 0
  return (
    <Wrapper isInvalid={textLength > POST_CHARACTER_LIMIT}>
      {`${textLength}/1000`}
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  text: state.formInput.createEditPostText,
})

export default connect(mapStateToProps)(CharacterCount)
