import React, { useState } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  BlueButton,
  WhiteButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  Form,
} from "@ui/antd"
import {
  FormWrap,
  CTAWrap,
  DIV,
  CountTracker,
} from "./commonStyles"
import {
  InputField,
  InputArea,
} from "../../InputFields"

const CreateEnvelopeForm = ({
  onFinish,
  onFinishFailed,
  toClose,
  sendingDocument,
}) => {
  const [subjectLength, setSubjectLength] = useState(0)
  const [messageLength, setMessageLength] = useState(0)

  return (
    <FormWrap>
      <Form
        name="offers_envelope_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={() => { }}
      // ref={this.formRef}
      >
        <InputField
          name="title"
          inputType="text"
          placeholder="Envelope Name*"
        />

        <DIV>
          <InputField
            name="emailSubject"
            inputType="text"
            placeholder="Subject*"
            maxLength={100}
            onChange={(e) => {
              const validText = e.target.value
              setSubjectLength(validText.length)
            }}
          />
          <CountTracker>
            <p>{`${subjectLength} / 100`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          <InputArea
            name="emailBody"
            inputType="text"
            placeholder="Personalized Note*"
            maxLength={500}
            onChange={(e) => {
              const validText = e.target.value
              setMessageLength(validText.length)
            }}
            type="custom"
            customRules={[{ required: true, message: "Note is required." }]}
          />
          <CountTracker>
            <p>{`${messageLength} / 500`}</p>
          </CountTracker>
        </DIV>

        <CTAWrap>
          <Form.Item>
            <WhiteButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="button"
              onClick={toClose}
            >
              <p>Close</p>
            </WhiteButton>
          </Form.Item>

          <Form.Item>
            <BlueButton
              style={{
                width: "356px",
              }}
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
              disabled={!(messageLength > 0 && subjectLength > 0)}
            >
              {sendingDocument ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Next</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default CreateEnvelopeForm
