import styled from "styled-components"

export const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;

  ${props => props.isLandingPage && `
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
    margin-top: ${props.errorPos || "13px"};

    span img {
      width: 18px;
      height: 18px;
    }
  `}
`
