import React, { useEffect } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import LinkButton from "@ui/LinkButton"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import { Table } from "@ui/antd"
import colors from "@colors"
import { ATC } from "@fonts"
import { get } from "lodash"
import Box from "@ui/Box"
import Loader from "@ui/Loader"
import DropdownMenu from "@ui/NewTooltip"
import moreMenuSVG from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Menu.svg"
import {
  Li,
} from "@ui/DragDropColumn/common"
import { UnarchiveLeadsModal } from "../index"

const Wrap = styled.div`
  padding: 47px 42px 0px 42px;
`

const StyledMenuIcon = styled.img`
  padding: 2px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
`

const Header = styled.h1`
  font-family: ${ATC};
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.black};  
`

const H1Wrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
  border: none;
`}
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 5px;
`

const TableWrap = styled.div`
  margin-top: 15px;
`
const TableContentWrap = styled.div``

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
`

const Title = styled.p`
  width: 100%;
  height: 22px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
`

const Type = styled.p`
font-family: ${ATC};
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 8px;
display: flex;
align-items: center;
letter-spacing: 1px;
text-transform: uppercase;
color: ${colors.marineBLue};
`

const Number = styled.p`
font-family: ${ATC};
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: ${colors.black};
`

const ArchivedLeads = ({
  getClientTransactions,
  getClientTransactionResponse,
  toggleModalForArchivedLeads,
  isModalForArchivedLeadOpen,
}) => {
  useEffect(() => {
    const currentFilters = {
      view_type: "view_mine",
      statuses: ["Archived"],
    }
    getClientTransactions({
      currentFilters,
    })
  }, [])
  const { data, isFetching } = getClientTransactionResponse || {}
  const archivedLeads = get(data, "client_transactions.Archived")
  const COLUMNS = [
    {
      title: "Name",
      width: 100,
      dataIndex: "client_name",
      key: "client_name",
      render: (_, record) => {
        return (
          <Title>
            {`${record.client_name}`}
          </Title>
        )
      },
    },
    {
      title: "Type",
      width: 100,
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        return (
          <Type>
            {`${record.type}`}
          </Type>
        )
      },
    },
    {
      title: "Number",
      width: 100,
      dataIndex: "client_phone",
      key: "client_phone",
      render: (_, record) => {
        return (
          <Number>
            {`${normalizePhone(record.client_phone)}`}
          </Number>
        )
      },
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "client_email",
      key: "client_email",
      render: (_, record) => {
        return (
          <TableContentWrap>
            <Number>
              {`${record.client_email}`}
            </Number>
            <Box position="absolute" t="25" r="150">
              <DropdownMenu
                position="bottom"
                component={<StyledMenuIcon src={moreMenuSVG} />}
                top={20}
                padding={10}
                left={-190}
                width={200}
                borderColor="#FFF"
                eventType="hover"
                borderRadius={4}
                showShadow
                arrowPosition="right"
              >
                <React.Fragment>
                  <Li
                    onClick={() => { toggleModalForArchivedLeads(true) }}
                  >
                    <span>Unarchive Lead</span>
                  </Li>
                </React.Fragment>
              </DropdownMenu>
            </Box>
          </TableContentWrap>
        )
      },
    },
  ]
  return (
    <Wrap>
      <H1Wrap>
        <Header>Archived Leads</Header>
        <ButtonWrap>
          <LinkButton
            color={colors.marineBLue}
            onClick={() => {
              window.location.href = "/crm"
            }}
          >
            Go Back To All Leads
          </LinkButton>
        </ButtonWrap>
      </H1Wrap>
      {isFetching ? (
        <Loader />
      )
        : (
          <TableWrap>
            <CustomTable
              columns={COLUMNS}
              style={{
                border: "1px solid #E5E5E5",
              }}
              dataSource={archivedLeads}
              pagination={false}
            />
          </TableWrap>
        )}
      {isModalForArchivedLeadOpen && <UnarchiveLeadsModal />}
    </Wrap>
  )
}

export default CRMV2Container(ArchivedLeads)
