import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { isEmpty } from "lodash"
import { Link, withRouter } from "react-router-dom"
import CircularImage from "dumbComponents/common/CircularImage"
import Box from "@ui/Box"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Like from "dumbComponents/common/StyledComponents/Like"
import RoundedTag from "dumbComponents/common/StyledComponents/RoundedTag"
import {
  convertUnicode, unixToDate, getProfileUrl, checkForUserObject,
} from "services/Utils"
import UpvoteIcon from "dumbComponents/Network/MiddleContainer/common/UpvoteIcon"
import Loader from "@ui/Loader"
import distanceInWordsToNow from "date-fns/distance_in_words_to_now"
import icons from "@icons"

const linkify = require("linkify-it")()

const user = checkForUserObject("user")

const CommentBoxWrapper = styled.div`
  display: flex;
  background: #ffffff;
  align-items: flex-start;
  min-height: 100px;
  padding: 0 20px;
  border-left: 1px solid ${colors.disabledInput};
  border-right: 1px solid ${colors.disabledInput};
  position: relative;

  a {
    text-decoration: none;
  }
`
const ImageContainer = styled.div`
  flex: 1 auto;
  margin-right: 10px;
  padding: 12px 0;
`

const Info = styled.div`
  flex: 1 100%;
  padding: 12px 0;
`

const P = styled(Paragraph)`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  span {
    font-weight: 500;
    text-transform: capitalize;
  }

  ${props => props.isClickable && `
    cursor: pointer;
  `}
`

const Small = styled.small`
  font-size: 12px;
  color: #a5a8ad;
`

const LikeWrapper = styled.div`
  display: flex;
  /* padding: 10px 0; */
  margin-left: 0px;
  border: 1px solid ${colors.disabledInput};
`

const StyledLike = styled(Like)`
  position: relative;
  margin-left: 40px;
`

const Upvote = styled.div`
  position: relative;
  margin-left: 50px;
  padding: 15px;
  left: 30px;
  i {
    cursor: pointer;
    color: ${props => (props.likedByMe ? "#11adf3" : "#354052")};
  }
  span {
    margin-left: 10px;
    position: relative;
    top: -3px;
    font-size: 12px;
    cursor: pointer;
    color: ${props => (props.likedByMe ? "#11adf3" : "#354052")};
  }
`

const StyledSpan = styled.span`
  font-size: 12px;
  position: absolute;
  width: 150px;
  top: 19px;
  left: 44px;
  color: ${props => (props.likedByMe ? "#11adf3" : "#354052")};
`

const TopCommentWrap = styled.div`
  font-size: 10px;
  padding: 4px;
  color: ${colors.dirtBrown};
  background: ${colors.newTagInactive};
  position: absolute;
  top: 10px;
  font-weight: 500;
  right: 10px;
`

const LinkSpan = styled.a`
  color: ${colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const getShortLink = (text) => {
  if (text.length <= 37) {
    return text
  }
  return `${text.substring(0, 37)}...`
}

const getCompleteJSX = (rawText) => {
  const splitLines = rawText.split("\n")
  const wholeJSX = []
  splitLines.forEach((text, outerIdx) => {
    const match = linkify.match(text)
    const parts = []
    let stringPointer = 0
    if (match) {
      match.forEach((matchItem, idx) => {
        const { index, lastIndex } = matchItem
        const textPart = convertUnicode(text.substring(stringPointer, index))
        parts.push(textPart)

        const linkPart = getShortLink(text.substring(index, lastIndex))
        const linkJSX = (
          <LinkSpan key={`link-${idx}`} href={matchItem.url} target="_blank">
            {linkPart}
          </LinkSpan>
        )
        parts.push(linkJSX)
        stringPointer = lastIndex
      })
    }
    const textPart = convertUnicode(text.substring(stringPointer, text.length))
    parts.push(textPart || <br key={`br-${outerIdx}`} />)
    wholeJSX.push(<div key={`div-${outerIdx}`}>{parts}</div>)
  })
  return wholeJSX
}

const CommentsShimmer = () => (
  <Box p="20">
    <Loader top="0px" bottom="0px" />
  </Box>
)

const CommentLists = ({
  feedDetail: { comments, commentsCount, selectedFeed },
  openLikers,
  isLikingComment,
  updateCommentLike,
  topComment = {},
  history,
}) => (
  <React.Fragment>
    {/* <Box
      d="flex"
      jc="space-between"
      p="10px"
      style={{
        borderLeft: `1px solid ${colors.disabledInput}`,
        borderRight: `1px solid ${colors.disabledInput}`,
        borderBottom: `1px solid ${colors.disabledInput}`,
      }}
    >
      <Paragraph size={14} fontWeight={500}>
        {commentsCount && `${commentsCount} ${selectedFeed.type === "post" || selectedFeed.type === "intro" ? "Comment" : "Answer"}${commentsCount > 1 ? "s" : ""}`}
      </Paragraph>
      {comments && comments.length > 0 && selectedFeed.type === "question" && <RoundedTag>Top answer</RoundedTag>}
    </Box> */}

    {comments ? comments.map((comment) => {
      const { agent } = comment
      return (
        <div key={comment.id} id={`comment_list_${comment.id}`}>
          <CommentBoxWrapper>
            <Link to={getProfileUrl(agent)}>
              <ImageContainer>
                <CircularImage
                  image={agent.agent_image}
                  name={`${agent.firstname || ""} ${agent.lastname || ""}`}
                  size={60}
                  fontSize="12px"
                />
              </ImageContainer>
            </Link>
            <Info>
              {comment.id === topComment.id && (
                <TopCommentWrap>
                  {`Top ${selectedFeed.type === "question" ? "answer" : "comment"}`}
                </TopCommentWrap>
              )}
              <P onClick={() => { history.push(getProfileUrl(agent)) }} isClickable>
                <span>{`${agent.firstname} ${agent.lastname || ""}`}</span>
              </P>
              <P>
                {agent.agency_name ? `${agent.agency_name}` : ""}
              </P>
              <Small>{`${distanceInWordsToNow(unixToDate(comment.created_at))} ago`}</Small>
              <P mt="20" mb={0}>
                {getCompleteJSX(comment.comment)}
              </P>
            </Info>
          </CommentBoxWrapper>
          {(selectedFeed.type === "post" || selectedFeed.type === "intro" || selectedFeed.type === "academy" || selectedFeed.type === "referral") ? (
            <LikeWrapper>
              <StyledLike
                id="radLike"
                animating={isLikingComment === comment.id && comment.likedByMe}
                likedByMe={comment.likedByMe}
                onClick={() => updateCommentLike({ id: comment.id, likedByMe: comment.likedByMe })}
              >
                <StyledSpan likedByMe={comment.likedByMe}>
                  {/* {comment.likersCount > 0 && <span>&nbsp;.&nbsp;</span>} */}
                  {comment.likersCount ? `${comment.likersCount}` : ""}
                  <span
                    style={{ color: "#354052" }}
                    onClick={(e) => {
                      e.stopPropagation()
                      openLikers({ id: comment.id, likersViewType: "comment" })
                    }}
                  >
                    {/* {comment.likersCount ? `${comment.likersCount}` : ""} */}
                  </span>
                </StyledSpan>
              </StyledLike>
            </LikeWrapper>
          ) : (
            <LikeWrapper>
              <Upvote
                likedByMe={comment.likedByMe}
                onClick={() => updateCommentLike({ id: comment.id, likedByMe: comment.likedByMe })}
              >
                <UpvoteIcon
                  isActive={comment.likedByMe}
                />
                <span>
                  {/* {comment.likersCount > 0 && <span>&nbsp;.&nbsp;</span>} */}
                  {comment.likersCount ? `${comment.likersCount}` : ""}
                  <span
                    style={{ color: "#354052" }}
                    onClick={() => openLikers({ id: comment.id, likersViewType: "comment" })}
                  >
                  </span>
                </span>
              </Upvote>
            </LikeWrapper>
          )}
        </div>
      )
    }) : (
      <CommentsShimmer />
    )}
  </React.Fragment>
)

export default withRouter(CommentLists)
