import styled from "styled-components"
import colors from "@colors"
import { INTER } from "@fonts"

export const Wrap = styled.div``

export const Header = styled.div`
  font-family: ${INTER};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`
export const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-family: ${INTER};
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`
export const Label = styled.p`
  font-family: ${INTER};
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  color: ${colors.black};
`
export const ContentContainer = styled.div`
  padding: 40px 30px 60px;
`
export const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`
