import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 2px;
  top: 4px;
  background: ${props => props.bg || colors.whiteBlueFaded};

  ${props => props.xl && `
    height: 32px;
    width: 56px;
    border-radius: 20px;
  `}

`

const Selector = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => props.circleColor || colors.darkGreyPurple};
  transition-duration: 0.3s;
  box-shadow: -0.800000011920929px 0.800000011920929px 3.200000047683716px 0px #00000040;

  ${props => props.isSelected && `
    right: 0px;
    background: ${props.bg || colors.whiteBlueFaded};
  `}
  ${props => props.xl && `
    height: 28px;
    width: 28px;
  `}
`

const TextStyle = styled.p`
  opacity: 0.5;
  color: #544040;

  ${props => props.isSelected && `
    opacity: 1;
    color: ${colors.black};
  `}
`

const SwitchToggle = (props) => {
  const {
    isSelected,
    onClick,
    bg,
    circleColor,
    xl,
    leftText,
    rightText,
    customWrapStyle,
    selectorClassname,
    input: {
      onChange,
      value,
    } = {},
  } = props
  const handleClick = (...clickProps) => {
    if (!onClick) {
      onChange(!value)
    } else {
      onClick(clickProps)
    }
  }

  const deltaSelect = typeof onClick === "function" ? isSelected : value
  return (
    <Wrap
      onClick={handleClick}
      isSelected={deltaSelect}
      bg={bg}
      xl={xl}
      style={{
        ...customWrapStyle || {},
      }}
    >
      {leftText && (
        <TextStyle
          isSelected={!deltaSelect}
          style={{
            position: "relative",
            left: "-34px",
            top: "-3px",
          }}
        >
          {leftText}
        </TextStyle>
      )}
      <Selector isSelected={deltaSelect} circleColor={circleColor} xl={xl} className={selectorClassname} />
      {rightText && (
        <TextStyle
          isSelected={deltaSelect}
          style={{
            position: "relative",
            right: "-15px",
            top: "-3px",
          }}
        >
          {rightText}
        </TextStyle>
      )}
    </Wrap>
  )
}

export default SwitchToggle
