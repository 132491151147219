import React from "react"
import { Form } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  InputFieldWrap,
} from "./styles"

const InputField = ({
  placeholder,
  type,
  name,
  required,
  style,
  customRules,
  inputType,
  onChange,
  text,
  defaultValue,
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
      return [{ required, type: "email", message: 'Email is not valid' }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required, message: "Required Field" }]
    }
  }

  const capitabliseValue = () => {
    if (!defaultValue) return defaultValue
    return defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1)
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={`${text}`} required={required} />}
    >
      <InputFieldWrap
        placeholder={placeholder}
        //style={style}
        type={inputType}
        onChange={onChange}
        defaultValue={capitabliseValue()}
      />
    </Form.Item>
  )
}

export default InputField
