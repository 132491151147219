import styled from "styled-components"
import { INTER } from "@fonts"

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const TitleAndSelectWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Title = styled.div`
width: 192px;
  p  {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 133.333% */
    letter-spacing: -0.792px;
  }
`
