import React from "react"
import ListingVideosSVGIcon from "images/VideoCreator.png"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const ListingVideos = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[0] === "listing-videos"}>
    <div className="icon-block-new">
      <IconEle
        left="22px"
        src={ListingVideosSVGIcon}
        alt="RARProducts"
      />
    </div>
    <div className="text-block">
      <NavLink
        to="/listing-videos"
        isActive={routeArray[0] === "listing-videos"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_Wellness_web")
          }
        }}
      >
        Video Creator
        {/* {CookiesStorage.load("listingVideosTag") && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )} */}
      </NavLink>
    </div>
  </InnerTab>
)

export default ListingVideos
