import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import root from "window-or-global"
import { get } from "lodash"
import * as ReferralActions from "container/Referral/actions"
import * as AppActions from "./actions"

const initState = {
  isAppMounted: false,
  isShowApplyModal: false,
  lpMQLFormVisible: false,
  isWebinarFormVisible: false,
  heroMQLFormData: {
    bool: false,
    className: "className_undefined",
  },
  applyModalData: {
    bool: false,
    accountType: null,
    className: "className_undefined",
    headerText: "Nice to meet you!",
    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
  },
  signUpHeaderText: "Nice to meet you!",
  signUpSubText: `Tell us a little about yourself and one of our 
              team members will get to you shortly.`,
  isShowLoginModal: false,
  isFetchingUserObject: false,
  isShowMessagesPanel: false,
  isNewFeeds: false,
  isSavingPhoneNumber: false,
  isPhoneCollectionModalOpen: false,
  isAllowedClosingPhoneModal: false,
  isPhoneSaved: false,
  scheduleCall: {},
  errorMessage: null,
  user: {},
  isUserOnboarded: true,
  isShowConfirmModal: false,
  isHeaderBannerVisible: true,
  isBrokerFormModalVisible: false,
  isShowSignAgreementModal: false,
  globalPaddingTop: 70,
  feedLength: 0,
  questionLength: 0,
  homeRedDot: false,
  isIncompleteProfileBannerVisible: false,
  isVideoModalOpen: false,
  currentInviteType: "",
  isInviteSubmittedSuccessfully: false,
  isInviteSubmittedSuccessfullyFromModal: false,
  errorInComponent: false,
  isErrorModalOpen: false,
  toggleFormContent: null,
  isShowSuccessModal: false,
  isSubmittedReferralInterestForm: false,
  isShowHubspotModal: false,
  isSideMenuVisible: false,
  evenVariant: false, //((Math.floor(Math.random() * 10) + 1) % 2) === 0
  currentState: {
    state: "WASHINGTON",
    tagName: "UPCOMING",
    content: "We’re coming to Washington shortly, join the waitlist today.",
    tagColor: "#FFC63B",
    buttonText: "Join the waitlist",
  },
  currentSourceTab: "",
  isDownloadAppFormSubmitted: false,
  isMQLFormSubmitted: false,
  isMelRoadblockVisible: true,
  ...createDeltaReducer("brokerInterestResponse"),
  ...createDeltaReducer("redirectionResponse"),
  ...createDeltaReducer("popups"),
  ...createDeltaReducer("partnerInterestResponse"),
  ...createDeltaReducer("transactionalResponse"),
  ...createDeltaReducer("realtyAgentPageResponse"),
  ...createDeltaReducer("dashboardLinksResponse"),
  ...createDeltaReducer("sideMenuUpdateresponse"),
  ...createDeltaReducer("fcmDeviceRegisterResponse"),
  ...createDeltaReducer("getNotificationReminderMappingResponse"),
  ...createDeltaReducer("updateDBWithUserChoiceOnNotificationResponse"),
  ...createDeltaReducer("updateIncompleteProfileResponse"),
  ...createDeltaReducer("postEmailInvitesResponse"),
  ...createDeltaReducer("applyInterestsResponse"),
  ...createDeltaReducer("realtyTeamsPageResponse"),
  ...createDeltaReducer("submitRefferalInviteFormResponse"),
  ...createDeltaReducer(AppActions.appDownloadSMSAction.RESPONSE),
  ...get(root, "__STATE__.vwoVariant", {}),

}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    // App Mount START
    case AppActions.fetchUserInfoAction.REQUEST: {
      return {
        ...state,
        isFetchingUserObject: true,
        ...action.data,
      }
    }

    case AppActions.toggleScheduleCallBannerAction.type: {
      return {
        ...state,
        scheduleCall: {
          ...action.data,
        },
      }
    }

    case AppActions.toggleApplyModalAction.type: {
      const {
        bool,
        accountType,
        className,
        headerText,
        subHeaderText,
      } = action.data
      return {
        ...state,
        isShowApplyModal: bool,
        applyModalData: {
          bool,
          accountType,
          className,
          headerText,
          subHeaderText,
        },
      }
    }

    case AppActions.toggleSignUpFormAction.type: {
      const {
        bool,
        logInModalClassName,
        signUpHeaderText,
        signUpSubText,
      } = action.data || {}
      return {
        ...state,
        isShowLoginModal: bool,
        logInModalClassName,
        signUpHeaderText,
        signUpSubText,
      }
    }

    case AppActions.getPopUpScreenAction.REQUEST:
    case AppActions.getPopUpScreenAction.SUCCESS:
    case AppActions.getPopUpScreenAction.CLEAR:
    case AppActions.getPopUpScreenAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getPopUpScreenAction, "popups"),
      }
    }

    case AppActions.submitInterestFormAction.REQUEST:
    case AppActions.submitInterestFormAction.SUCCESS:
    case AppActions.submitInterestFormAction.CLEAR:
    case AppActions.submitInterestFormAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.submitInterestFormAction, "applyInterestsResponse"),
      }
    }

    case AppActions.updateDBWithUserChoiceOnNotificationAction.REQUEST:
    case AppActions.updateDBWithUserChoiceOnNotificationAction.SUCCESS:
    case AppActions.updateDBWithUserChoiceOnNotificationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.updateDBWithUserChoiceOnNotificationAction, "updateDBWithUserChoiceOnNotificationResponse"),
      }
    }

    //updateIncompleteProfileAction
    case AppActions.updateIncompleteProfileAction.REQUEST:
      {
        return {
          ...state,
          ...handleDeltaReducer(state,
            action, AppActions.updateDBWithUserChoiceOnNotificationAction, "updateIncompleteProfileResponse"),
          isUpdatingProfile: true,
        }
      }
    case AppActions.updateIncompleteProfileAction.SUCCESS:
    case AppActions.updateIncompleteProfileAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.updateDBWithUserChoiceOnNotificationAction, "updateIncompleteProfileResponse"),
        isUpdatingProfile: false,
      }
    }

    case AppActions.getNotificationReminderMappingAction.REQUEST:
    case AppActions.getNotificationReminderMappingAction.SUCCESS:
    case AppActions.getNotificationReminderMappingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.getNotificationReminderMappingAction, "getNotificationReminderMappingResponse"),
      }
    }

    case AppActions.registerDeviceForFCMAction.REQUEST:
    case AppActions.registerDeviceForFCMAction.SUCCESS:
    case AppActions.registerDeviceForFCMAction.CLEAR:
    case AppActions.registerDeviceForFCMAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.registerDeviceForFCMAction, "fcmDeviceRegisterResponse"),
      }
    }

    case AppActions.getDashboardLinkAction.REQUEST:
    case AppActions.getDashboardLinkAction.SUCCESS:
    case AppActions.getDashboardLinkAction.CLEAR:
    case AppActions.getDashboardLinkAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getDashboardLinkAction, "dashboardLinksResponse"),
      }
    }

    case AppActions.getSideMenuUpdatesAction.REQUEST:
    case AppActions.getSideMenuUpdatesAction.SUCCESS:
    case AppActions.getSideMenuUpdatesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getSideMenuUpdatesAction, "sideMenuUpdateresponse"),
      }
    }

    case AppActions.postEmailInvitesAction.REQUEST:
    case AppActions.postEmailInvitesAction.FAILURE:
    case AppActions.postEmailInvitesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.postEmailInvitesAction, "postEmailInvitesResponse"),
      }
    }

    case AppActions.submitTrasactionInterestAction.REQUEST:
    case AppActions.submitTrasactionInterestAction.SUCCESS:
    case AppActions.submitTrasactionInterestAction.CLEAR:
    case AppActions.submitTrasactionInterestAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.submitTrasactionInterestAction, "transactionalResponse"),
      }
    }

    case AppActions.getAgentsActions.REQUEST:
    case AppActions.getAgentsActions.SUCCESS:
    case AppActions.getAgentsActions.CLEAR:
    case AppActions.getAgentsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getAgentsActions, "realtyAgentPageResponse"),
      }
    }

    case AppActions.getTeamsActions.REQUEST:
    case AppActions.getTeamsActions.SUCCESS:
    case AppActions.getTeamsActions.CLEAR:
    case AppActions.getTeamsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getTeamsActions, "realtyTeamsPageResponse"),
      }
    }

    case AppActions.fetchUserInfoAction.FAILURE:
    case AppActions.fetchUserInfoAction.SUCCESS: {
      const {
        phone,
        firstname,
        lastname,
        email,
        brokerageTeamId,
        brokerageTeamIdForLogo,
      } = action.data || {}
      const isPhoneNumberMissing = action.data && !phone
      const isNameIncomplete = (action.data && !firstname) ||
        (action.data && !lastname)
      const isEmailMissing = action.data && !email
      return {
        ...state,
        brokerageTeamId,
        isIncompleteProfileBannerVisible: isPhoneNumberMissing ||
          isNameIncomplete || isEmailMissing,
        user: action.data || {},
        isFetchingUserObject: false,
        brokerageTeamIdForLogo,
      }
    }

    case AppActions.toggleSignAgreementModalAction.type: {
      return {
        ...state,
        isShowSignAgreementModal: action.data,
      }
    }
    // App Mount ENDS
    case AppActions.APP_READY: {
      return {
        ...state,
        isAppMounted: true,
      }
    }

    // Save Image
    case AppActions.SAVE_NEW_PROFILE_IMAGE: {
      return {
        ...state,
        profileImage: action.data,
      }
    }

    case AppActions.TOGGLE_MESSAGES_PANEL: {
      const { isShowMessagesPanel } = state
      const isShow = action.data || !isShowMessagesPanel
      return {
        ...state,
        isShowMessagesPanel: isShow,
      }
    }

    case AppActions.SHOW_NEW_FEED_AVAILABLE: {
      const {
        feedLength,
      } = action.data
      root.document.title = `New Post${feedLength && feedLength > 1 ? "s" : ""} | Community`
      return {
        ...state,
        homeRedDot: true,
        isNewFeeds: true,
        feedLength,
      }
    }
    case AppActions.HIDE_NEW_FEED_AVAILABLE: {
      root.document.title = "Community | Radius Agent"
      return {
        ...state,
        isNewFeeds: false,
      }
    }

    case AppActions.SHOW_NEW_QUESTION_AVAILABLE: {
      const {
        questionLength,
      } = action.data
      root.document.title = "New Questions | Community"
      return {
        ...state,
        homeRedDot: true,
        isNewQuestions: true,
        questionLength,
      }
    }
    case AppActions.HIDE_NEW_QUESTION_AVAILABLE: {
      root.document.title = "Community | Radius Agent"
      return {
        ...state,
        isNewQuestions: false,
        questionLength: 0,
      }
    }

    case AppActions.localUpdateUserObject.type:
    case AppActions.requestUpdateUserObject.FAILURE: {
      const payload = action.data
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      }
    }

    case AppActions.collectPhoneNumberAction.REQUEST: {
      return {
        ...state,
        isSavingPhoneNumber: true,
      }
    }

    case AppActions.collectPhoneNumberAction.SUCCESS: {
      return {
        ...state,
        isSavingPhoneNumber: false,
        isPhoneCollectionModalOpen: false,
        isPhoneSaved: true,
        errorMessage: null,
      }
    }
    case AppActions.collectPhoneNumberAction.FAILURE: {
      return {
        ...state,
        isSavingPhoneNumber: false,
        isPhoneCollectionModalOpen: true,
        isPhoneSaved: false,
        errorMessage: action.data,
      }
    }

    case AppActions.togglePhoneCollectionModalAction.type: {
      return {
        ...state,
        isPhoneCollectionModalOpen: action.data,
      }
    }

    case AppActions.getRedirectionLocationAction.REQUEST:
    case AppActions.getRedirectionLocationAction.SUCCESS:
    case AppActions.getRedirectionLocationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getRedirectionLocationAction, "redirectionResponse"),
      }
    }

    case AppActions.allowUserToClosePhoneCollectionModalAction.type: {
      return {
        ...state,
        isAllowedClosingPhoneModal: true,
        isPhoneSaved: true,
      }
    }

    case AppActions.showConfirmModalAction.type: {
      return {
        ...state,
        isShowConfirmModal: true,
        confirmModal: {
          ...action.data,
          isShowConfirmLoader: false,
        },
      }
    }

    case AppActions.hideConfirmModalAction.type: {
      return {
        ...state,
        isShowConfirmModal: false,
        confirmModal: null,
      }
    }

    case AppActions.toggleLoaderInConfirmAction.type: {
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          isShowConfirmLoader: action.data,
        },
      }
    }

    // specific to credit card banner will refactor later
    case AppActions.hideHeaderBannerAction.type: {
      return {
        ...state,
        isHeaderBannerVisible: false,
        user: {
          ...state.user,
          isCardInactive: false,
        },
      }
    }

    case AppActions.togglebannerPaddingAction.type: {
      return {
        ...state,
        globalPaddingTop: action.data,
      }
    }

    case AppActions.submitBrokerInterestAction.REQUEST:
    case AppActions.submitBrokerInterestAction.SUCCESS:
    case AppActions.submitBrokerInterestAction.CLEAR:
    case AppActions.submitBrokerInterestAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.submitBrokerInterestAction, "brokerInterestResponse"),
      }
    }

    case AppActions.toggleIncompleteProfileBannerAction.type: {
      return {
        ...state,
        isIncompleteProfileBannerVisible: action.data,
      }
    }

    case AppActions.submitPartnerInterestAction.REQUEST:
    case AppActions.submitPartnerInterestAction.SUCCESS:
    case AppActions.submitPartnerInterestAction.CLEAR:
    case AppActions.submitPartnerInterestAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.submitPartnerInterestAction, "partnerInterestResponse"),
      }
    }

    case ReferralActions.fetchSubscriptionsAction.FAILURE: {
      return {
        ...state,
        isHeaderBannerVisible: true,
      }
    }

    case AppActions.brokerFormModalToggleAction.type: {
      return {
        ...state,
        isBrokerFormModalVisible: action.data,
      }
    }

    case AppActions.hideNewFeedNotificationDotAction.type: {
      root.document.title = "Community | Radius Agent"
      return {
        ...state,
        homeRedDot: false,
      }
    }

    case AppActions.toggleHeroVideoModalAction.type: {
      return {
        ...state,
        isVideoModalOpen: action.data,
      }
    }

    case AppActions.toggleRARFormOpenAction.type: {
      return {
        ...state,
        isRARPopupOpen: action.data,
      }
    }

    case AppActions.isaPopUpToggleAction.type: {
      return {
        ...state,
        isISAPopUpOpen: action.data,
      }
    }

    case AppActions.setCurrentInviteType.type: {
      return {
        ...state,
        currentInviteType: action.data,
      }
    }

    case AppActions.resetFeedInvitePostAction.type: {
      return {
        ...state,
        isInviteSubmittedSuccessfully: false,
      }
    }

    case AppActions.toggleSuccessInviteSubmitFromFeed.type: {
      return {
        ...state,
        isInviteSubmittedSuccessfully: true,
      }
    }

    case AppActions.toggleSuccessInviteSubmitFromModalAction.type: {
      return {
        ...state,
        isInviteSubmittedSuccessfullyFromModal: action.data,
      }
    }

    //toggleErrorModalAction
    case AppActions.toggleErrorModalAction.type: {
      return {
        ...state,
        errorInComponent: !!action.data,
        isErrorModalOpen: action.data,
      }
    }

    case AppActions.setCurrentClickedStateAction.type: {
      return {
        ...state,
        currentState: action.data,
      }
    }

    case AppActions.setToggleSignUpFormContent.type: {
      return {
        ...state,
        toggleFormContent: action.data,
      }
    }

    case AppActions.submittedRefferalInviteFormAction.type: {
      return {
        ...state,
        isSubmittedReferralInterestForm: action.data,
      }
    }

    case AppActions.toggleSuccessModalAction.type: {
      return {
        ...state,
        isShowSuccessModal: action.data,
      }
    }

    case AppActions.toggleHubspotMeetingModalAction.type: {
      return {
        ...state,
        isShowHubspotModal: action.data,
      }
    }

    case AppActions.setCurrentTabForSourceAction.type: {
      return {
        ...state,
        currentSourceTab: action.data,
      }
    }

    case AppActions.sideMenuToggleAction.type: {
      return {
        ...state,
        isSideMenuVisible: action.data,
      }
    }

    case AppActions.appDownloadSMSAction.REQUEST:
    case AppActions.appDownloadSMSAction.SUCCESS:
    case AppActions.appDownloadSMSAction.CLEAR:
    case AppActions.appDownloadSMSAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.appDownloadSMSAction, AppActions.appDownloadSMSAction.RESPONSE),
      }
    }

    case AppActions.setIsAppDownloadSMSFormSubmittedAction.type: {
      return {
        ...state,
        isDownloadAppFormSubmitted: action.data,
      }
    }

    case AppActions.toggleLandingPageMQLFormAction.type: {
      const {
        heroMQLFormData,
      } = action.data || {}
      return {
        ...state,
        heroMQLFormData: {
          ...heroMQLFormData,
        },
      }
    }

    case AppActions.toggleEWebinarModalAction.type: {
      return {
        ...state,
        isWebinarFormVisible: action.data,
      }
    }

    case AppActions.toggleMQLFormPostSubmitAction.type: {
      return {
        ...state,
        isMQLFormSubmitted: action.data,
      }
    }

    case AppActions.showMelRoadBlockAction.type: {
      return {
        ...state,
        isMelRoadblockVisible: action.data,
      }
    }

    default:
      return state
  }
}
