import React, { useEffect } from "react"
import styled from "styled-components"
import queryString from "query-string"
import Container from "container/Settings/SubscriptionsContainer"
import CookiesStorage from "services/CookieStorage"
import SettingsContainer from "container/Settings"
import { withRouter, Route, Switch } from "react-router-dom"
import { get, isEmpty } from "lodash"
import Loader from "@ui/Loader"
import { Expired, Success, AddCard } from "./Components"
import { Navbar } from "../ICAOnboarding/Components/index"

const Wrap = styled.div`
  margin-top: 200px;
`

const StripePOC = ({
  verifyAuthToken,
  history,
  verifyAuthTokenResponse,
}) => {
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery
  const payload = {
    authCode: authcode,
  }

  useEffect(() => {
    verifyAuthToken({ payload })
  }, [authcode])

  const secureId = get(verifyAuthTokenResponse, "data.secure_id", CookiesStorage.load("secureId"))
  const isFetching = get(verifyAuthTokenResponse, "isFetching")

  if (isEmpty(secureId)) {
    return (
      <>
        <Navbar />
        {isFetching ? (
          <Wrap>
            <Loader />
          </Wrap>
        ) : (
          <Expired />
        )}
      </>
    )
  }

  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/stripe/addCardDetails/authenticate" exact component={AddCard} />
        <Route path="/stripe/addCardDetails/authenticate/success" component={Success} />
        <Route path="/stripe/addCardDetails/expired" component={Expired} />
      </Switch>
    </>
  )
}

export default withRouter(SettingsContainer(Container(StripePOC)))
