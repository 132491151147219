import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as PubNubActions from "./actions"

const initState = {
  isNewPostCTAVisible: false,
  viewedFeed: "",
}

export default function pubNubReducer(state = initState, action) {
  switch (action.type) {
    case PubNubActions.toggleNewPostsCTAAction.type: {
      const {
        isNewPostCTAVisible,
      } = action.data

      return {
        ...state,
        isNewPostCTAVisible,
      }
    }

    case PubNubActions.currentMessageRecievedAction.type: {
      const {
        viewedFeed,
      } = action.data

      return {
        ...state,
        viewedFeed,
      }
    }

    default: {
      return state
    }
  }
}
