const BUYER_AGENT_INFO_ROUTE = "/realty/offers/coversheet/buyer-agent-info"
const BUYER_INFO_ROUTE = "/realty/offers/coversheet/buyer-info"
const OFFER_INFO_ROUTE = "/realty/offers/coversheet/offer-info"
const LISTING_AGENT_INFO_ROUTE = "/realty/offers/coversheet/listing-agent-info"
const SELLER_INFO_ROUTE = "/realty/offers/coversheet/seller-info"
const ESCROW_INFO_ROUTE = "/realty/offers/coversheet/escrow-info"
const TITLE_INFO_ROUTE = "/realty/offers/coversheet/title-info"
const OFFER_WRITING_ROUTE = "/realty/offers/coversheet/offer-writing-info"
const VENDER_SELECT_ROUTE = "/realty/offers/coversheet/vendor-select"
const NEW_VENDOR_ROUTE = "/realty/offers/coversheet/new-vendor"
const UPDATE_VENDOR_ROUTE = "/realty/offers/coversheet/update-vendor"
const UPDATE_LENDER_ROUTE = "/realty/offers/coversheet/update-lender"
const LENDER_SELECT_ROUTE = "/realty/offers/coversheet/lender-select"
const LENDER_INFO_ROUTE = "/realty/offers/coversheet/lender-info"
const DOCUMENTS_INFO_ROUTE = "/realty/offers/coversheet/documents-info"
const GOOGLE_CONTACTS_INTEGRATION_ROUTE = "/realty/offers/google-contact-integration"
const DOCLISTS_ROUTE = "/realty/offers/documents/lists"
const PAGE_ARRAY = [1, 2, 3, 4, 5, 6, 7]
const PAGE_ARRAY_OPERATING_STATES = [1, 2, 3, 4, 5, 6, 7, 8]
const DOC_ARRAY = [1, 2]
const COVER_LETTER_ROUTE = "/realty/offers/cover-letter"
const PRE_QUALIFICATION_ROUTE = "/realty/offers/pre-qualification"
const PROOF_OF_FUNDS_ROUTE = "/realty/offers/proof-of-funds"
const SIGNED_DISCLOSURES_ROUTE = "/realty/offers/signed-disclosures"
const SUBMIT_AND_SEND_ALL_DOCUMENTS = "/realty/offers/submit-and-sign-all-documents"
const NEW_OFFER_ROUTE = "/realty/offers-v3"
const NEW_OFFER_ROUTE_V2 = "/realty/offers-v3"

//steps
const BUYER_AGENT_INFO_STEP = "buyer_agent_info"
const BUYER_INFO_STEP = "buyer_info"
const OFFER_INFO_STEP = "offer_info"
const LISTING_AGENT_INFO_STEP = "listing_agent_info"
const SELLER_INFO_STEP = "seller_info"
const OFFER_WRITING_INFO_STEP = "writing_opt_in"
const VENDOR_INFO_STEP = "vendor_info"
const VENDOR_LIST_STEP = "select_vendor"
const NEW_VENDOR_STEP = "insert_vendor"
const UPDATE_VENDOR_STEP = "update_vendor"
const LENDER_LIST_STEP = "select_lender"
const NEW_LENDER_STEP = "insert_lender"
const UPDATE_LENDER_STEP = "update_lender"
const LENDER_INFO_STEP = "lender_info"
const DOCUMENTS_INFO_STEP = "documents_info"

//Listing Statuses

const LISTING_STATUSES = [
  {
    label: "Incomplete",
    value: "incomplete",
  },
  {
    label: "On Market",
    value: "active_listing",
  },
  {
    label: "Withdrawn",
    value: "listing_canceled",
  },
  {
    label: "Expired",
    value: "listing_expired",
  },
  {
    label: "Coming Soon",
    value: "coming_soon",
  },
  {
    label: "Pending",
    value: "pending",
  },
]

//Offer Statuses
const OFFER_STATUSES = [
  {
    value: "incomplete",
    label: "In Progress",
    disabled: true,
  },
  {
    value: "new_offer",
    label: "New Offer",
  },
  {
    value: "pending_signatures",
    label: "Pending Signatures",
  },
  {
    label: "Submitted",
    value: "submitted",
  },
  {
    value: "completed",
    label: "Executed",
  },
  {
    value: "offer_accepted",
    label: "Offer Accepted",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "cancelled",
    label: "Canceled",
  },
]
const OFFER_STATUSES_FILTERS = [
  {
    value: "incomplete",
    label: "In Progress",
  },
  {
    value: "new_offer",
    label: "New Offer",
  },
  {
    value: "pending_signatures",
    label: "Pending Signatures",
  },
  {
    label: "Submitted",
    value: "submitted",
  },
  {
    value: "completed",
    label: "Executed",
  },
  {
    value: "offer_accepted",
    label: "Offer Accepted",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "cancelled",
    label: "Canceled",
  },
]

//Color Map
const ColorMap = [
  {
    value: "green",
    label: "new_offer",
  },
  {
    value: "green",
    label: "incomplete",
  },
  {
    value: "yellow",
    label: "pending_signatures",
  },
  {
    value: "blue",
    label: "completed",
  },
  {
    value: "skin",
    label: "submitted",
  },
  {
    value: "red",
    label: "rejected",
  },
  {
    value: "grey",
    label: "cancelled",
  },
  {
    value: "skin",
    label: "offer_accepted",
  },
]

const ListingColorMap = [
  {
    value: "#B3E4C7",
    label: "On Market",
  },
  {
    value: "#D2D8D8",
    label: "Incomplete",
  },
  {
    value: "#FAEBA6",
    label: "Coming Soon",
  },
  {
    value: "#B3E4C7",
    label: "Pending",
  },
  {
    value: "#EBBAB5",
    label: "Expired",
  },
  {
    value: "#D2D8D8",
    label: "Withdrawn",
  },
]

const EMAIL_RULES = [
  {
    required: true,
    type: "email",
    message: "Required",
  },
]

export {
  /*ROUTES*/
  BUYER_AGENT_INFO_ROUTE as BuyerAgentInfoRoute,
  BUYER_INFO_ROUTE as BuyerInfoRoute,
  OFFER_INFO_ROUTE as OfferInfoRoute,
  LISTING_AGENT_INFO_ROUTE as ListingAgentInfoRoute,
  SELLER_INFO_ROUTE as SellerInfoRoute,
  ESCROW_INFO_ROUTE as EscrowInfoRoute,
  TITLE_INFO_ROUTE as TitleInfoRoute,
  LENDER_INFO_ROUTE as LenderInfoRoute,
  VENDER_SELECT_ROUTE as VendorSelectRoute,
  UPDATE_VENDOR_ROUTE as UpdateVendorRoute,
  UPDATE_LENDER_ROUTE as UpdateLenderRoute,
  NEW_VENDOR_ROUTE as CreateVendorRoute,
  LENDER_SELECT_ROUTE as LenderSelectRoute,
  GOOGLE_CONTACTS_INTEGRATION_ROUTE as GoogleContactIntegrationRoute,
  OFFER_WRITING_ROUTE as OfferWritingRoute,
  DOCUMENTS_INFO_ROUTE as DocumentsInfoRoute,
  DOCLISTS_ROUTE as DocListsRoute,
  COVER_LETTER_ROUTE as CoverLetterRoute,
  PRE_QUALIFICATION_ROUTE as PreQualificationRoute,
  PROOF_OF_FUNDS_ROUTE as ProofOfFundsRoute,
  SIGNED_DISCLOSURES_ROUTE as SignedDisclosuresRoute,
  SUBMIT_AND_SEND_ALL_DOCUMENTS as SubmitAndSendAllDocuments,

  /*PROPERTIES*/
  PAGE_ARRAY as PageArray,
  PAGE_ARRAY_OPERATING_STATES as PageArrayOperatingStates,
  DOC_ARRAY as DocArray,
  BUYER_AGENT_INFO_STEP as BuyerAgentInfoStep,
  BUYER_INFO_STEP as BuyerInfoStep,
  OFFER_INFO_STEP as OfferInfoStep,
  LISTING_AGENT_INFO_STEP as ListingAgentInfoStep,
  SELLER_INFO_STEP as SellerInfoStep,
  OFFER_STATUSES as OfferStatuses,
  LISTING_STATUSES as ListingStatuses,
  VENDOR_LIST_STEP as VendorSelectStep,
  VENDOR_INFO_STEP as VendorInfoStep,
  NEW_VENDOR_STEP as CreateVendorStep,
  UPDATE_VENDOR_STEP as UpdateVendorStep,
  LENDER_LIST_STEP as LenderSelectStep,
  NEW_LENDER_STEP as CreateLenderStep,
  UPDATE_LENDER_STEP as UpdateLenderStep,
  LENDER_INFO_STEP as LenderInfoStep,
  OFFER_WRITING_INFO_STEP as OfferWritingOptInStep,
  DOCUMENTS_INFO_STEP as DocumentsInfoStep,
  NEW_OFFER_ROUTE as NewOfferRoute,
  OFFER_STATUSES_FILTERS as OfferStatusesFilters,
  ColorMap,
  ListingColorMap,

  //FORM RULES
  EMAIL_RULES as EmailValidations,
}
