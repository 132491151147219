import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share"
import Box from "@ui/Box"
import { Wrapper, Header, SaveButton } from "./commonStyles"

const SIZE = 30

const ShareIcon = styled.div`
  width: ${SIZE}px;
  height: ${SIZE}px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
`

const ClipBoardText = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(25, 25, 25, 0.32);
  color: rgba(25, 25, 25, 0.32);
  padding: 15px;

  textarea {
    border: none;
    outline: none;
    color: #555;
    font-size: 15px;
    height: auto;
    width: 100%;
    resize: none;
    &:focus {
      outline: 0;
    }
  }
`

const EmailIcon = styled.span`
  position: relative;
  top: -7px;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50%;
  border: 1px solid #11adf3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #11adf3;
`

class ShareProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLinkCopied: false,
    }
    this.textBox = React.createRef()
  }

  copyLink = () => {
    this.textBox.current.select()
    root.document.execCommand("copy")
    this.setState({
      isLinkCopied: true,
    })
  }

  render() {
    const { initialValues } = this.props
    const { isLinkCopied } = this.state
    return (
      <Wrapper>
        <Header>
          <h4>Share posting</h4>
        </Header>
        <Box p="30px 15px 0px 15px">
          <Box>
            <ShareIcon>
              <EmailShareButton subject="Profile share" body={`Check out my profile ${initialValues}`}>
                <EmailIcon>
                  <i className="icon-generic-Send" />
                </EmailIcon>
              </EmailShareButton>
            </ShareIcon>
            <ShareIcon>
              <FacebookShareButton url={initialValues}>
                <FacebookIcon size={SIZE} round />
              </FacebookShareButton>
            </ShareIcon>
            <ShareIcon>
              <LinkedinShareButton url={initialValues}>
                <LinkedinIcon size={SIZE} round />
              </LinkedinShareButton>
            </ShareIcon>
            <ShareIcon>
              <TwitterShareButton url={initialValues}>
                <TwitterIcon size={SIZE} round />
              </TwitterShareButton>
            </ShareIcon>
          </Box>
        </Box>
        <Box p="15px">
          <ClipBoardText>
            <textarea ref={this.textBox} value={initialValues} rows="1" />
          </ClipBoardText>
        </Box>
        <Box d="flex" jc="center" ai="center" fd="column" mb="20">
          <SaveButton
            width="100px"
            onClick={() => {
              this.copyLink()
            }}
          >
            {isLinkCopied ? "Copied" : "Copy Link"}
          </SaveButton>
        </Box>
      </Wrapper>
    )
  }
}

export default ShareProfile
