import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import { ATC } from "@fonts"
import { Helmet } from "react-helmet"
import CloseButton from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div`

`
const DescriptionWrap = styled.div`
  padding: 0 40px;
  h1 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: ${colors.black};
    margin: 4px 0px;
    width: 297px;
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 20px;
    color: ${colors.black};
    margin: 0px;
  }
`


const GetInTouchModal = ({ showGetInTouchModal }) => {
  return (
    <Wrap>
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
      </Helmet>
      <Modal
        show
        toClose={() => { showGetInTouchModal(false) }}
        width="800px"
        overlayColor="#000000b3"
        modalStyles={{
          padding: 0,
        }}
        customDialogColor="transparent"
        dialogStyles={{
          borderRadius: "0px",
          background: colors.white,
          overflow: "hidden",
          position: "relative",
          top: "31px",
          padding: "20px 1px",
        }}
        closeIcon={CloseButton}
        showCloseButton
        iconStyles={{
          padding: "8px",
          borderRadius: "18px",
          fontSize: "10px",
          right: "20px",
          margin: "6px",
          width: "40px",
        }}
        modalVersion={3}
      >
        <DescriptionWrap>
          <h1>Get in Touch</h1>
          <p>Meet your in-house lender.</p>
        </DescriptionWrap>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/radiusmortgage/realtorconnect"
          style={{
            minWidth: "320px",
            height: "630px",
          }}
        >
        </div>
      </Modal>
    </Wrap>
  )
}

export default GetInTouchModal
