import React, { useEffect } from "react"
import { INTER } from "@fonts"
import styled from "styled-components"
import OffersV2 from "container/OffersV2"
import ListingsV2 from "container/ListingsV2"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e18308;
  background: #fef4e6;
  margin-bottom: 28px;
`

const Title = styled.div`
  color: #1f2937;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

const FormName = styled.div`
  color: #1f2937;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
`

const ErrorWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const ErrorMessage = styled.div`
  color: #373758;
  font-family: ${INTER};
  font-size: 14px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
  display: block;
`

const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid ${({ isChecked }) => (isChecked ? "#0B9164" : "#373758")};
  background-position: center;
  background-size: 8px;
  background-repeat: no-repeat;

  ${({ isChecked }) => isChecked
    // eslint-disable-next-line max-len
    && "background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'%3E%3Cpath d='M1 3.00001L2.33333 4.33334L5 1.66667' stroke='%230B9164' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E\");"}
`

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust spacing between items */
`;

const FormWrapper = styled.div`
  flex: 1 1 calc(50% - 16px); /* Two columns, accounting for the gap */
  max-width: calc(50% - 16px); /* Ensures each item takes up 50% width */
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Stack items on smaller screens */
    max-width: 100%;
  }
`;


const formatFieldName = (fieldName) => {
  if (typeof fieldName !== "string") {
    throw new TypeError("Input must be a string")
  }

  // Handle empty string
  if (!fieldName) {
    return ""
  }

  // First replace dots and underscores with spaces
  let result = fieldName.replace(/[._]/g, " ")

  // Handle numeric indices by converting to human-readable numbers
  result = result.replace(/\b(\d+)\b/g, (match) => {
    // eslint-disable-next-line radix
    const number = parseInt(match) + 1 // Add 1 to convert 0-based to 1-based
    return ` ${number} -`
  })

  // Convert to sentence case
  result = result
    .split(" ")
    .filter(word => word.length > 0)
    .map((word, index) => {
      // Skip transformation for the numeric sections with dashes
      if (word === "-") return word
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(word)) return word

      // For regular words, apply proper casing
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
      return word.toLowerCase()
    })
    .join(" ")

  return result
}

const ErrorsList = (props) => {
  const { coversheetErrorsList, setCoversheetErrorsList } = props

  // Hide the list
  if (coversheetErrorsList?.isValid || !coversheetErrorsList?.errors?.length) {
    return null
  }

  // Cleanup the list on unmount
  useEffect(
    () => () => {
      setCoversheetErrorsList([])
    },
    []
  )

  const scrollToField = (form, field) => {
    const scrollOptions = {
      block: "end",
      scrollMode: "always",
      behavior: (actions) => {
        const [{ el, top }] = actions
        el.scrollTop = top + 300

        return "smooth"
      },
    }

    if (form?.formRef && field?.fieldNamePath) {
      console.log("check here", form?.formRef, field?.fieldNamePath)
      form.formRef.scrollToField(field?.fieldNamePath, scrollOptions)
    }
  }

  return (
    coversheetErrorsList.errors.length > 2 ? (
      <Wrapper id="coversheet-erorrs-list">
        <Title>Please fix the highlighted errors below</Title>
  
        <FormContainer>
          {coversheetErrorsList.errors.map((form) => (
            <FormWrapper key={form.formName}>
              <FormName>{form.formName}</FormName>
  
              {form.fields.map((field) => (
                <ErrorWrapper key={field.fieldName}>
                  <Checkbox isChecked={field.isChecked} />
                  <ErrorMessage onClick={() => scrollToField(form, field)}>
                    {`${formatFieldName(field.fieldName)} is missing`}
                  </ErrorMessage>
                </ErrorWrapper>
              ))}
            </FormWrapper>
          ))}
        </FormContainer>
      </Wrapper>
    ) : (
      <Wrapper id="coversheet-erorrs-list">
        <Title>Please fix the highlighted errors below</Title>
        {coversheetErrorsList.errors.map((form) => (
          <div key={form.formName}>
            <FormName>{form.formName}</FormName>
            {form.fields.map((field) => (
              <ErrorWrapper key={field.fieldName}>
                <Checkbox isChecked={field.isChecked} />
                <ErrorMessage onClick={() => scrollToField(form, field)}>
                  {`${formatFieldName(field.fieldName)} is missing`}
                </ErrorMessage>
              </ErrorWrapper>
            ))}
          </div>
        ))}
      </Wrapper>
    )
  );
  
}

export default OffersV2(ListingsV2(ErrorsList))
