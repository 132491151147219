import React, { useState, useRef, useEffect } from "react"
import OffersV2 from "container/OffersV2/index"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import {
  EnvelopesWrap,
} from "./style"
import OfferPackageCard from "./OfferPackageCard"

const OfferPackageContainer = ({
  offerPackages,
  packages,
}) => {
  const {
    envelopeIds: envelopes,
    created_at: date,
    attachmentNames: attachments,
  } = packages || {}
  console.log(packages, "here")
  return (
    <EnvelopesWrap>
      <SectionWrap>
        {envelopes && envelopes.map(item => (
          <OfferPackageCard
            docs={item.documents}
            id={item.id}
            title={item.title}
            date={date}
            attachments={attachments}
          />
        ))}

        {/* {attachments && attachments.map(item => (
          <OfferPackageCard
            // docs={item.docs}
            id={item.id}
            title={item.title}
            date={date}
          />
        ))} */}
        
      </SectionWrap>
    </EnvelopesWrap>
  )
}
export default OffersV2(OfferPackageContainer)
