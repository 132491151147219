import React from "react"
import styled from "styled-components"
import { HeroText, SubText } from "../commonStyles"
import VideoRoomsCard from "./VideoRoomsCard"
import Placard from "./Placard"
import GradientBox from "../GradientBox/index"

const Wrap = styled.div`
  display: flex;
  // align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding: 100px 0px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 59px;

  //padding: 62px 0px;

  // margin-bottom: 62px;

  @media(max-width: 1099px) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 auto;
  }
`

const RoomsWrap = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 32px;
  margin-bottom: 62px;
  flex-wrap: wrap;

   @media(max-width: 1099px) {
    flex-direction: column;
  }
`

const TextWrap = styled.div``

const SectionEight = ({
  scheduledRooms,
  isFetchingScheduledRooms,
  width,
}) => (
  <Wrap className="container">
    <TextWrap>
      <HeroText
        spanStyles
      >
        On-Demand
        {" "}
        <span>
          Coaching and Community
        </span>
      </HeroText>
    </TextWrap>
    <SubText>
      {`Get inspired with Radius Rooms. Access the world's largest repository of 
      live and on-demand real estate content. Share your knowledge, start a water cooler 
      moment, learn from the pros — it's ridiculously easy with Radius Rooms!`}
    </SubText>
    <RoomsWrap>
      {!isFetchingScheduledRooms && scheduledRooms && scheduledRooms[0] && (
        <VideoRoomsCard
          bgImage={scheduledRooms[0].og_image}
          title={scheduledRooms[0].name}
          description={`Get a behind-the-curtain view of how UWM work. See Mat Ishbia, along with our Sales Leaders, inform and fire you up! It’s a virtual seat, where you’ll get current market insights, & learn how to get the most out of UWM’s latest tools.`}
          scheduled_at={scheduledRooms[0].scheduled_at}
          host={scheduledRooms[0].host}
          attendeesCount={scheduledRooms[0].attendees_count}
          roomId={scheduledRooms[0].id}
          noData={false}
        />
      )}
      <VideoRoomsCard noData />
    </RoomsWrap>
    <GradientBox
      minWidth="100%"
      mobileMinWidth="100%"
      mobileFlexJC="flex-start"
      gradientOverride
      hoverAnim
    >
      <Placard />
    </GradientBox>
  </Wrap>
)

export default SectionEight
