import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const TitleText = styled.h1`
  color: #000;
  font-family: ${MONTSERRAT};
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 163.523%;

  span {
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 163.523%; /* 62.139px */
  }
`

export const GreyCard = styled.div`
  width: 359px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F4F5FF;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);


  p {
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    @media(max-width: 1099px) {
      color: #1C1C1F;
      font-family: ${INTER};
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media(max-width: 1099px) {
    justify-content: flex-start;
    padding-left: 35px;
    width: 100%;
  }
`

export const CardsWrap = styled.div`
  display: flex;
  gap: 14px;
  flex-direction: column;

  @media(max-width: 1099px) {
    align-items: center;
    justify-content: center;
  }
`

export const FlexAnimContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;  

  @media(max-width: 1099px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    ${props => props.one && `
    //background: url(${CLOUDFRONT}/PolkaDot.svg);
    background-repeat: repeat;
    background-size: contain;

    @media(max-width: 1099px) {
      height: 800px;
      margin-top: 200px;
      gap: 100px !important;
    }

    img {
      height: unset !important;
    }
  `}

  ${props => props.two && `
    margin-top: 18px;
    background-image: url(${CLOUDFRONT}/TopRightGradient.svg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;

    @media(max-width: 1099px) {
      height: 1500px;
    }
  `}

  ${props => props.three && `
    margin-top: 18px;
    background-image: url(${CLOUDFRONT}/DiamondSquaresPattern.svg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;

    @media(max-width: 1099px) {
      height: 600px;

      background-image: url(${CLOUDFRONT}/PolkaDot.svg);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
    }
  `}

   ${props => props.four && `
    margin-top: 18px;
    background-image: url(${CLOUDFRONT}/PerformanceTrackingGradient.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;

    @media(max-width: 1099px) {
      height: 715px;
      margin-top: 411px;
    }
  `}
  }
`

export const ImageWrap = styled.div`
  width: 513px;
  height: 567px;

  img {
    width: 100%;
    height: 100%;
  }

  @media(max-width: 1099px) {
    width: 100%;
  }
`
