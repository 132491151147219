import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as BrbcActions from "./actions";

const mapStateToProps = (state) => {
  const { brbc } = state;

  return {
    ...brbc,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchPresignedBrbcDocs: BrbcActions.fetchPresignedBrbcDocs.request,
      sendBrbcReminder: BrbcActions.sendBrbcReminderAction.request,
      uploadBrbcDoc: BrbcActions.uploadBrbcAction.request,
      uploadNewBrbcCoversheet: BrbcActions.newBrbcCoversheetAction.request,
      fetchBrbcCoversheet: BrbcActions.fetchBrbcCoversheetAction.request,
      voidBrbcCoversheet: BrbcActions.voidBrbcAction.request,
      voidDuplicateBrbcCoversheet: BrbcActions.voidRestartBrbcAction.request,
      sendBrbcEnvelope: BrbcActions.sendBrbcEnvelopeAction.request,
      fetchBrbcStatus: BrbcActions.fetchBRBCStatusAction.request,
      getSenderView: BrbcActions.senderViewAction.request,
      getReceiverView: BrbcActions.receiverViewAction.request,
      getAgentDetailsFromState:
        BrbcActions.getAgentDetailsFromStateAction.request,
      deleteCoBuyer: BrbcActions.deleteCoBuyerAction.request,
      deleteBrbcEnvelope: BrbcActions.deleteBrbcEnvelopeAction.request,
      deleteBrbcDoc: BrbcActions.deleteBrbcDocAction.request,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
