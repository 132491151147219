import React from "react"
import { Droppable } from "react-beautiful-dnd"
import Box from "@ui/Box"
import {
  Title,
  StatusDot,
  Container,
  ClientList,
} from "@ui/DragDropColumn/common"
import Card from "./Card"

const Column = ({
  column,
  leads,
  cardProps,
  hideMarkAsQualified,
}) => (
  <Container>
    <Box d="flex" ai="center" pr="10">
      <Title size={18} fontWeight={500} style={{ flex: 1 }}>
        {column.display_name}
      </Title>
      <StatusDot color={column.backgroundColor} />
    </Box>
    <Droppable droppableId={column.value}>
      {(provided, snapshot) => (
        <ClientList
          innerRef={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
          style={column.style}
        >
          {leads && leads.length && leads.map(item => (
            <Card
              key={item.id}
              data={item}
              hideMarkAsQualified={hideMarkAsQualified}
              {...cardProps}
            />
          ))}
        </ClientList>
      )}
    </Droppable>
  </Container>
)

export default Column
