import React, { useState } from "react"
import { message, Upload } from "antd"
import CookiesStorage from "services/CookieStorage"
import {
  AdditionalDropdown,
  AdditionalDropdownLI,
} from "./styles"

const AdditionalDocsUpload = ({
  close,
  uploadFunction,
}) => {
  const [fileType, setType] = useState("")
  const agentId = CookiesStorage.load("agentId")

  const props = {
    name: "file",
    accept: ".pdf",
    multiple: false,
    onError: (err) => {
      console.log("onError", err)
    },
    onSuccess: (res, file) => {
      const payload = {
        step: fileType,
        documents: [
          {
            title: file.name,
            url: res.fileUrlAfterUpload,
          },
        ],
      }
      uploadFunction(payload)
    },
    action: (file) => { console.log(file) },
    customRequest: async (customXHRObject) => {

      const {
        onStart,
        onSuccess,
        onProgress,
        onError,
        file,
      } = customXHRObject || {}
      try {
        console.log("fileType")
        const signedRes = await fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=s-offer-docs/${agentId}/${fileType}&filename=${file.name}`)
        const signedResJson = await signedRes.json()
        const myHeaders = new Headers({ "Content-Type": "application/pdf" })

        const res = await fetch(signedResJson.url, {
          method: "PUT",
          headers: myHeaders,
          body: file,
        })
        onSuccess(signedResJson, file)
        close()
      } catch (err) {
        onError(err)
      }
    },
    onChange(info) {
      const { status } = info.file
      console.log(status)
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    // onDrop(e) {
    //   console.log("Dropped files", e.dataTransfer.files)
    // },
  }

  console.log(fileType)

  return (
    <AdditionalDropdown>
      <AdditionalDropdownLI
        onClick={() => close()}
        style={{
          paddingLeft: "90%",
        }}
      >
        <p>X</p>
      </AdditionalDropdownLI>
      <AdditionalDropdownLI
        onClick={() => setType("proof_of_funds")}
      >
        <Upload {...props}>
          <p>Proof of Funds</p>
        </Upload>
      </AdditionalDropdownLI>
      <AdditionalDropdownLI
        onClick={() => setType("pre_qualification")}
      >
        <Upload {...props}>
          <p>Pre Approval</p>
        </Upload>
      </AdditionalDropdownLI>
      <AdditionalDropdownLI
        onClick={() => setType("cover_letter")}
      >
        <Upload {...props}>
          <p>Cover Letter</p>
        </Upload>
      </AdditionalDropdownLI>
      {/* <AdditionalDropdownLI
        onClick={() => setType("others")}
      >
        <Upload {...props}>
          <p>Others</p>
        </Upload>
      </AdditionalDropdownLI> */}
    </AdditionalDropdown>
  )
}

export default AdditionalDocsUpload
