export const RADIUS_TRANSACTION_COORDINATION_USAGE_CONFIRMATION = [
  {
    id: 1,
    name: "Yes",
    value: "is_radius_tc_yes",
  },
  {
    id: 2,
    name: "No",
    value: "is_radius_tc_no",
  },
]

export const RADIUS_TRANSACTION_ALL_CASH_OFFER_CONFIRMATION = [
  {
    id: 1,
    name: "Yes",
    value: "is_all_cash_offer_yes",
  },
  {
    id: 2,
    name: "No",
    value: "is_all_cash_offer_no",
  },
]

export const FIELD_VALUES = {
  is_radius_tc: "checkbox",
  is_all_cash_offer: "checkbox",
  lender_email_address: "string",
}
