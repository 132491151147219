import React from "react"
import {
  FileListWrap,
  HeaderFileListWrap,
  ListWrapper,
  File,
  CTAWrap,
  FileDetailWrap,
  ImageWrap,
  FileButton,
} from "./style"

const Doclist = ({
  title,
  docs,
  handleDelete,
}) => (
  <FileListWrap>
    <HeaderFileListWrap>
      <p>{title}</p>
    </HeaderFileListWrap>
    <ListWrapper>
      {docs && docs.map(item => (
        <File>
          <FileDetailWrap>
            <ImageWrap>
              <img src={`${CLOUDFRONT}/OFFERS_V2/cloudDone.svg`} alt="doc" />
            </ImageWrap>
            <p>{item.title || "Doc"}</p>
          </FileDetailWrap>
          <CTAWrap>
            <FileButton
              onClick={() => handleDelete(item.id)}
            >
              <img src={`${CLOUDFRONT}/OFFERS_V2/Trash.svg`} alt="doc" />
              <p>DELETE</p>
            </FileButton>
          </CTAWrap>
        </File>
      ))}
    </ListWrapper>
  </FileListWrap>
)

export default Doclist
