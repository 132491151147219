import React from "react"
import Modal from "@ui/Modal"
import colors from "@colors"
import container from "container/App"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import root from "window-or-global"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div``

const RadiusLogoWrap = styled.div`
  img {
    height: 44px;
    width: 208.2047882080078px;
  }
`

const Menu = styled.div`
  width: 165px;
  height: 103px;
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: -1000px;
  top: 79px;
  /* background: red; */
  z-index: 99999;
  border-radius: 9px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);

  ${props => props.isSideMenuVisible && `
    right: 43px;
  `}

  transition: right 0.6s ease-in;
`

const MenuButton = styled.button`

  width: 100%;
  display: flex;
 
  
  background: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;


  img {
    width: 23px;
    height: 23px;
  }

  ${props => props.border && `
    border-bottom: 0.5px solid #B6B8FF; 
  `}
`

const Title = styled.h1`
  color: #1C1C1F;
  text-align: center;
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 141.4%; /* 19.796px */
  text-transform: capitalize;
`

const BorderSpan = styled.div`
  border-bottom: 1px solid #303030;
  width: 1000px;
  position: relative;
  top: 20px;
  right: 0;
`

class SideMenuNavbar extends React.PureComponent {
  componentDidMount() {
    // const {  } = this.props
  }

  componentDidUpdate() {

  }

  render() {
    const {
      sideMenuToggle,
      isSideMenuVisible,
      history,
    } = this.props

    const MODAL_STYLES = {
      background: `${colors.white}`,
      padding: 0,
    }

    return (
      <Menu
        isSideMenuVisible={isSideMenuVisible}
      >
        <MenuButton
          checkRes
          border
          onClick={() => {
            history.push("/rooms")
            sideMenuToggle(false)
          }}
        >
          <Title>ROOMS</Title>
        </MenuButton>
        <MenuButton
          checkRes
          onClick={() => {
            history.push("/about")
            sideMenuToggle(false)
          }}
        >
          <Title>ABOUT US</Title>
        </MenuButton>
      </Menu>
    )
  }
}

export default withRouter(container(SideMenuNavbar))
