import React, { useEffect, useState } from "react"
import Listings from "container/Listings"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import StatusShimmer from "./StatusShimmer"
import {
  getStatusOptions,
} from "../../commonUtils"

const ListingStatus = ({
  currentStatus,
  handleStatusChange,
  getBrokerageListingsStatusesResponse,
  defaultValue,
}) => {
  const {
    data: statuses,
    isFetching: fetchingStatuses,
  } = getBrokerageListingsStatusesResponse || {}
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    setStatusList(() => {
      if (statuses) {
        const getStatusOptionsResult = getStatusOptions(statuses)

        const newStatuses = getStatusOptionsResult.map((item) => {
          const newItem = item

          newItem.disabled = false

          return newItem
        })

        return newStatuses
      }

      return []
    })
  }, [])

  return (
    <>
      {fetchingStatuses && !statuses ? (
        <StatusShimmer />
      ) : (
        <SelectField
          placeholder="Select Status"
          options={statusList}
          onChange={handleStatusChange}
          defaultValue={defaultValue}
          style={{
            width: "183px !important",
          }}
          value={currentStatus}
          width="183px"
        />
      )}
    </>
  )
}

export default Listings(ListingStatus)
