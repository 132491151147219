import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import query from "query-string"
import colors from "@colors"
import RealtyContainer from "container/Realty"
import Footer from "dumbComponents/common/FooterNew"
import cookie from "services/CookieStorage"
import { tablet, xsMobile, preTabletWidth, preDesktopWidth, minDesktopWidth } from "@consts"
import { Helmet } from "react-helmet"
import HubspotTracker from "dumbComponents/common/HubspotTracker"
import Hero from "dumbComponents/RealtyV3/Common/Hero"
import TalentSection from "dumbComponents/RealtyV3/RadiusTeamsPage/TalentSection"
import BrandingSection from "dumbComponents/RealtyV3/Common/Branding"
import ApplyNow from "dumbComponents/RealtyV3/RadiusTeamsPage/ApplyNow"
import Testimonial from "dumbComponents/RealtyV3/RadiusTeamsPage/Testimonial"
import ServicesWrap from "dumbComponents/RealtyV3/Common/Services"
import Sales from "dumbComponents/RealtyV3/Common/Sales"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import HubspotChat from "dumbComponents/Referral/common/HubspotChat"
import ReferralManagerContactV2 from "dumbComponents/Referral/common/ReferralManagerContactV2"
import ReferralManagerContactV3 from "dumbComponents/Referral/common/ReferralManagerContactV3"
import TabletBranding4 from "images/Graphics/RadiusForTeams/Social4x.png?q=100"
import Branding1 from "images/realty-v2/team/branding/branding-1.jpg?q=100"
import Branding2 from "images/realty-v2/team/branding/branding-2.jpg?q=100"
import Branding3 from "images/realty-v2/team/branding/branding-3.jpg?q=100"
import Branding4 from "images/Graphics/RadiusForTeams/ForTeamsSocialNew.png?q=100"
import AgentHero from "images/Graphics/RadiusForTeams/TeamHeroImageWeb4x.png?q=100"
import AgentHeroMobile from "images/Graphics/RadiusForTeams/RadiusTeamHeroMobile.png?q=100"
import AgentHeroTablet from "images/Graphics/RadiusForTeams/RadiusHeroTeamsTablet.png?q=100"
import RealtyTeamsSEO from "images/Graphics/LandingPagePreviews/ForTeamsPreview.png?q=100"
import RARFormModal from "../Common/ModalForm"
import { ATC } from "@fonts"

import {
  hideCSCChat,
} from "../common"

const Wrap = styled.div`
  overflow: hidden;

`
const ServiceWrapContainer = styled.div``

const BrandingSectionWrapper = styled.div``

const SalesWrapper = styled.div``

const BRANDING_LIST = [{
  icon: Branding1,
  tabletIcon: Branding1,
  maxWidth: "746px",
  flex: "1 1 50%",
  tag: "LOGO DESIGN",
}, {
  icon: Branding2,
  tabletIcon: Branding2,
  maxWidth: "522px",
  flex: "1 1 33%",
  tag: "YARD SIGNS",
},
{
  icon: Branding3,
  tabletIcon: Branding3,
  maxWidth: "522px",
  flex: "1 1 33%",
  tag: "MARKETING COLLATERAL",
}, {
  icon: Branding4,
  tabletIcon: TabletBranding4,
  maxWidth: "746px",
  flex: "1 1 50%",
  tag: "SOCIAL",
},
]
const BRANDING_HEADER = "Individualized branding and marketing built under your team name and logo."
const BRANDING_DESCRIPTION = "Your brand is unique. We want to promote your team through all the right channels."
const SERVICE_HEADER = "There is no one-size-fits-all approach to real estate teams."
// eslint-disable-next-line max-len
const SERVICE_DESCRIPTION = "We offer tailored recruitment services, social media management, client resources, transaction coordination and administrative processing for your team."
const SALES_HEADER = "As a team lead, we know you’re busy. We help you increase your sales velocity while saving you time."
// eslint-disable-next-line max-len
const SALES_DESCRIPTION = "Use the right technology and training so that you’re efficient and have more time. We curate the best-in-class technology and train you to use these tools."
class RealtyPage extends React.PureComponent {
  state = {
    variant: "",
  }

  componentDidMount() {
    const {
      pageTracker,
      brokerageFeatureViewed,
    } = this.props
    const { search } = root.location
    const parsedQuery = query.parse(search)
    brokerageFeatureViewed({
      type: "brokerage",
    })

    if (parsedQuery && parsedQuery.utm_source) {
      cookie.save("utm_source", parsedQuery.utm_source)
    }

    this.setState({
      variant: cookie.load("G_HOME_PAGE_VARIANT"),
      interestViaPopUp: cookie.load("interestViaPopUp"),
    })

    if (parsedQuery && parsedQuery.agent_id) {
      const date = new Date()
      date.setDate(date.getDate() + 1)
      cookie.save("realty_promotion_id", parsedQuery.agent_id, { expires: date })
    }
    pageTracker({
      payload: {
        rar: true,
      },
    })
    hideCSCChat()
    this.setState({
      isIosBrowser: parsedQuery && parsedQuery.platform === "ios",
    })

    setTimeout(() => {
      this.handleSectionScroll(parsedQuery.section)
    }, 1000)
  }

  handleSectionScroll = (section) => {
    const { setActiveNavbarSection, scrollToElement } = this.props
    setActiveNavbarSection(section)
    scrollToElement({
      id: `#realty-v2-${section}`,
      offset: -130,
    })
  }

  handleInView = (section) => {
    const { setActiveNavbarSection } = this.props
    setActiveNavbarSection(section)
  }

  getWindowDimensions = () => {
    const { innerWidth: width } = window
    return {
      width,
    }
  }

  render() {
    const {
      scrollToElement,
      activeTab,
      isOpenPressReleaseBanner,
      setCurrentLocationForRealty,
      submitScheduleACall,
      currentState,
      currentCity,
      isFreshChatPopUphidden,
      user,
      RARTracker,
      toggleRARModal,
      isRARModalOpen,
      scheduleACallResponse,
    } = this.props
    const { isIosBrowser, variant, interestViaPopUp } = this.state
    const screenWidth = this.getWindowDimensions().width
    const meta = {
      title: "An Online Brokerage for Real Estate Teams—Radius Agent",
      description: "Radius Agent is an online and cloud-based brokerage focused on helping real estate teams succeed. As a virtual brokerage, keep 100 percent of your commissions.",
      keywords: "online brokerage for real estate",
      type: "website",
      url: `${WEB_URL}realty/teams`,
      image: RealtyTeamsSEO,
    }
    return (
      <Wrap>
        <Helmet>
          <title>{meta.title}</title>
          <meta property="og:title" content={meta.title} />
          <meta property="title" content={meta.title} />
          <meta property="og:url" content={meta.url} />
          <meta property="url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
          <meta property="description" content={meta.description} />
          <meta property="keywords" content={meta.keywords} />
          <meta property="og:image" content={meta.image} />
          <meta property="image" content={meta.image} />
          <script
            type="text/javascript"
            src="https://a.omappapi.com/app/js/api.min.js"
            data-account="142677"
            data-user="130226"
            async
          >
          </script>
        </Helmet>
        <HubspotTracker />
        {/* <LandingPageSection> */}
        <Hero
          scrollToElement={scrollToElement}
          isIosBrowser={isIosBrowser}
          variant={variant}
          heroImageMobile={AgentHeroMobile}
          heroImage={AgentHero}
          h1FontFamily="Butler"
          pFontFamily={ATC}
          descFontWeight="300"
          fontWeight="600"
          buttonText="Learn More"
          hubspotKey="rar-team-page-apply-now-v2"
          rarPromotionSourcePage="realty_teams_page_hero_apply_now"
          heap="Click_apply_as_team_lead_web"
          fontSizeXL="54px"
          fontSizeML="54px"
          fontSizeSM="48px"
          fontSizeS="44px"
          parafontSizeXL="24px"
          parafontSizeML="24px"
          parafontSizeSM="24px"
          parafontSizeS="23px"
          btnColor={colors.marineBLue}
          btnTextColor={colors.white}
          mobileButtonWidth="241px"
          headerMaxWidthDesktop="646px"
          paraWidthML="488px"
          paraWidthXL="658px"
          tabletMaxWidth="536px"
          heroImageTablet={AgentHeroTablet}
          flexDirectionTablet="column"
        >
          <h1>Your team is your team. We just help your team succeed even more. </h1>
          <p>We give your team visibility and help reinforce your value to your clients.</p>
        </Hero>
        <ServiceWrapContainer>
          <ServicesWrap
            header={SERVICE_HEADER}
            description={SERVICE_DESCRIPTION}
            fontSizeXL="42px"
            fontSizeML="42px"
            fontSizeSM="36px"
            fontSizeS="42px"
            lineHeightXL="49px"
            lineHeightML="49px"
            lineHeightSM="45px"
            lineHeightS="49px"
            widthXL="616px"
            widthML="616px"
            widthSM="510px"
            widthS="308px"
          />
        </ServiceWrapContainer>
        {/* </LandingPageSection> */}
        <SalesWrapper>
          <Sales header={SALES_HEADER} description={SALES_DESCRIPTION} />
        </SalesWrapper>
        <TalentSection screenWidth={screenWidth} />
        <BrandingSectionWrapper>
          <BrandingSection
            DescTextSizeSM="16px"
            DescTextSizeXL="18px"
            DescTextSizeS="16px"
            brandingList={BRANDING_LIST}
            header={BRANDING_HEADER}
            description={BRANDING_DESCRIPTION}
            lineHeightDescS="24px"
          />
        </BrandingSectionWrapper>
        <Testimonial />
        <ApplyNow
          toggleRARModal={toggleRARModal}
          hubspotKey="rar-team-page-bottom-apply-now-v2"
          isIosBrowser={isIosBrowser}
          customBackground={colors.dreamWhite}
        />
        {!isIosBrowser && (
          <Footer
            isFreshChatHidden="true"
            customPadding="66px"
            customStyle={{
              position: "relative",
              top: "-81px",
              zIndex: "999",
            }}
          />
        )}
        {!isIosBrowser && (
          <React.Fragment>
            {isRARModalOpen && (
              <RARFormModal
                toClose={() => {
                  toggleRARModal(false)
                }}
              />
            )}
          </React.Fragment>
        )}
        {!isIosBrowser && (
          <React.Fragment>
            {isFreshChatPopUphidden && !interestViaPopUp ? (
              <ReferralManagerContactV3
                type="rar"
                popup="true"
                ms={3000}
                setCurrentLocationForRealty={setCurrentLocationForRealty}
                submitScheduleACall={submitScheduleACall}
                currentState={currentState}
                currentCity={currentCity}
                user={user}
                RARTracker={RARTracker}
                scheduleACallResponse={scheduleACallResponse}
              />
            ) : (
              <>
                {/* {root && root.HubSpotConversations && (
                  root.HubSpotConversations.widget.load()
                )} */}
              </>
            )}
          </React.Fragment>
        )}
        <EWebinarComponent />
      </Wrap>
    )
  }
}

export default RealtyContainer(RealtyPage)
