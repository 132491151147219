import React from "react"
import { withRouter, Link } from "react-router-dom"
import applyFlowContainer from "container/App/applyFlowContainer"
import {
  BannerWrap,
  BannerText,
  BannerIcon,
} from "../commonStyles"

const PaymentPendingBanner = () => (
  <BannerWrap
    backgroundColor="#FFF3F5"
  >
    <BannerIcon>
      <img src={`${CLOUDFRONT}/ErrorBannerICON.svg`} alt="alertIcon.png" />
    </BannerIcon>
    <BannerText>
      You&apos;re almost there! Access your brokerage tools by providing your credit card details
      <span>
        <Link
          to="/stripe/addCardDetails/authenticate"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          here
        </Link>
      </span>
      .
    </BannerText>
  </BannerWrap>
)

export default applyFlowContainer(withRouter(PaymentPendingBanner))
