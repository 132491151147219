import React from "react"
import { moneyFormatter } from "shared/currencyUtils"
import { getDate, getNonMultipliedTo1000Date } from "dumbComponents/ListingsV2/Components/Table/utils"
import * as ListingCardStyles from "./styles"

const ContractCard = ({
  handleEdit,
  handleView,
  details,
  mlsId = "",
}) => {
  console.log("details", details)
  const agentName = details ? details.agents[0].name : "Missing Information"
  const sellerName = details?.sellers?.[0]?.name || details?.sellers?.[0]?.firstname && details?.sellers?.[0]?.lastname ? `${details?.sellers?.[0]?.firstname} ${details?.sellers?.[0]?.lastname}` : "Missing Information"
  const escrowDate = details && details.closed_on ? getDate(details.closed_on) : ""
  const salePrice = details && details.price ? `$${moneyFormatter(details.price)}` : ""
  const acceptanceDate = details && details.created_at ? getDate(details.created_at) : ""
  const yearBuilt = ""

  return (
    <ListingCardStyles.ListingCardWrap
      style={{
        flexDirection: "column",
        height: "150px",
        gap: "43px",
      }}
    >
      <ListingCardStyles.DetailsCardRow>
        <ListingCardStyles.ListingCardTitle>
          <h1>Listing Details</h1>
        </ListingCardStyles.ListingCardTitle>

        <ListingCardStyles.CTAWrap>
          {mlsId && (
            <ListingCardStyles.ActionCard>
              <ListingCardStyles.StyledAction
                onClick={handleView}
              >
                <img src={`${CLOUDFRONT}/LISTING_V2/eye.svg`} alt="chevron.png" />
                <p>VIEW</p>
              </ListingCardStyles.StyledAction>
            </ListingCardStyles.ActionCard>
          )}

          <ListingCardStyles.ActionCard>
            <ListingCardStyles.StyledAction
              onClick={handleEdit}
            >
              <img src={`${CLOUDFRONT}/LISTING_V2/Edit.svg`} alt="chevron.png" />
              <p>EDIT</p>
            </ListingCardStyles.StyledAction>
          </ListingCardStyles.ActionCard>
        </ListingCardStyles.CTAWrap>
      </ListingCardStyles.DetailsCardRow>
      <ListingCardStyles.DetailsCardRow
        style={{
          justifyContent: "flex-start",
        }}
      >
        <ListingCardStyles.StatCard>
          <ListingCardStyles.DetailsWrap>
            <h2>Agent</h2>
            <p>{agentName}</p>
          </ListingCardStyles.DetailsWrap>
        </ListingCardStyles.StatCard>

        <ListingCardStyles.StatCard>
          <ListingCardStyles.DetailsWrap>
            <h2>Seller Name</h2>
            <p>{sellerName}</p>
          </ListingCardStyles.DetailsWrap>
        </ListingCardStyles.StatCard>

        {escrowDate && (
          <ListingCardStyles.StatCard>
            <ListingCardStyles.DetailsWrap>
              <h2>Close of escrow</h2>
              <p>{escrowDate}</p>
            </ListingCardStyles.DetailsWrap>
          </ListingCardStyles.StatCard>
        )}

        {salePrice && (
          <ListingCardStyles.StatCard>
            <ListingCardStyles.DetailsWrap>
              <h2>Sale Price</h2>
              <p>{salePrice}</p>
            </ListingCardStyles.DetailsWrap>
          </ListingCardStyles.StatCard>
        )}

        {yearBuilt && (
          <ListingCardStyles.StatCard>
            <ListingCardStyles.DetailsWrap>
              <h2>Year built</h2>
              <p>{yearBuilt}</p>
            </ListingCardStyles.DetailsWrap>
          </ListingCardStyles.StatCard>
        )}

        {acceptanceDate && (
          <ListingCardStyles.StatCard>
            <ListingCardStyles.DetailsWrap>
              <h2>Acceptance date</h2>
              <p>{acceptanceDate}</p>
            </ListingCardStyles.DetailsWrap>
          </ListingCardStyles.StatCard>
        )}

      </ListingCardStyles.DetailsCardRow>
    </ListingCardStyles.ListingCardWrap>
  )
}

export default ContractCard
