import root from "window-or-global"
import { authCheck } from "services/auth"

export const handleDataLayerPush = async (userData = null, event, text, additionalObject = null, callBack = null) => {
  try {
    const isLogged = authCheck()
    let dLayerPayload = {
      event,
      clicked_text: text,
      login_status: isLogged ? "logged_in" : "non_logged_in",
      visitor_type: "",
      agent_id: "",
      agent_type: "unknown_prospect",
      ag_nm: "",
      ag_eml: "",
    }

    if (additionalObject) {
      dLayerPayload = {
        ...dLayerPayload,
        ...additionalObject,
      }
    }

    if (isLogged && userData) {
      const isPartOfBrokerageTeam = userData
      && parseInt(userData.brokerageTeamId, 10) > 0 || userData.isBrokerageTeamMember
      const userType = userData.isSubscribedToBrokerPlan
        ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
      dLayerPayload.visitor_type = userType
      dLayerPayload.agent_id = userData.id
      dLayerPayload.agent_type = userType
      dLayerPayload.ag_nm = `${userData.firstname} ${userData.lastname}`
      dLayerPayload.ag_eml = userData.email
    }

    console.log("dLayerPayload", dLayerPayload)

    await root.dataLayer.push({
      ...dLayerPayload,
    })

    if(callBack) {
      callBack()
    }
  } catch (e) {
    console.log("Error occured for GA4", e)
  }
}
