import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  
`
const RenderJustText = (field) => {
  const {
    styles,
    input: {
      value,
    },
  } = field
  return (
    <Wrapper style={styles}>
      {value}
    </Wrapper>
  )
}

export default RenderJustText
