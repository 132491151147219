import {
  createSignalAction,
  createActionCreator,
  createSimpleCreator,
  createBaseActionCreator,
} from "shared/reduxUtils"

const BASE = "APP"

export const APP_READY = "APP_READY"
export const SAVE_NEW_PROFILE_IMAGE = "SAVE_NEW_PROFILE_IMAGE"
export const TOGGLE_MESSAGES_PANEL = "TOGGLE_MESSAGES_PANEL"
export const SYNC_USER_COOKIES = "SYNC_USER_COOKIES"
export const SHOW_NEW_FEED_AVAILABLE = "SHOW_NEW_FEED_AVAILABLE"
export const HIDE_NEW_FEED_AVAILABLE = "HIDE_NEW_FEED_AVAILABLE"
export const SHOW_NEW_QUESTION_AVAILABLE = "SHOW_NEW_QUESTION_AVAILABLE"
export const HIDE_NEW_QUESTION_AVAILABLE = "HIDE_NEW_QUESTION_AVAILABLE"

// API ACTIONS
export const fetchUserInfoAction = createSignalAction(BASE, "FETCH_USER_INFO")
export const updateReferralAgreementAction = createSignalAction(BASE, "UPDATE_USER_AGREEMENT")
export const requestUpdateUserObject = createSignalAction(BASE, "REQUEST_UPDATE_USER_OBJECT")
export const collectPhoneNumberAction = createSignalAction(BASE, "COLLECT_PHONE_NUMBER")
export const getRedirectionLocationAction = createSignalAction(BASE, "GET_REDIRECTION_LOCATION")
export const closePopUpAction = createSimpleCreator(BASE, "CLOSE_POPUP")
export const getReferralCumulativeDataAction = createSignalAction(BASE, "FETCH_REFERRAL_DATA")

export const appReadyAction = createActionCreator(APP_READY)
export const saveNewProfileImageAction = createActionCreator(SAVE_NEW_PROFILE_IMAGE)
export const toggleMessagesPanelAction = createActionCreator(TOGGLE_MESSAGES_PANEL)
export const syncUserCookieAction = createActionCreator(SYNC_USER_COOKIES)
export const showNewFeedsAvailableAction = createActionCreator(SHOW_NEW_FEED_AVAILABLE)
export const hideNewFeedsAvailableAction = createActionCreator(HIDE_NEW_FEED_AVAILABLE)
export const showNewQuestionAvailableAction = createActionCreator(SHOW_NEW_QUESTION_AVAILABLE)
export const hideNewQuestionAvailableAction = createActionCreator(HIDE_NEW_QUESTION_AVAILABLE)
export const pushRedirectAction = createSimpleCreator(BASE, "@@PUSH_REDIRECT")
export const pullRedirectAction = createSimpleCreator(BASE, "@@PULL_REDIRECT")
export const allowUserToClosePhoneCollectionModalAction = createSimpleCreator(BASE, "ALLOW_USER_CLOSE_MODAL")
export const localUpdateUserObject = createBaseActionCreator(BASE, "LOCAL_UPDATE_USER_OBJECT")
export const togglePhoneCollectionModalAction = createSimpleCreator(BASE, "TOGGLE_PHONE_COLLECTION_MODAL")
export const recordUserSessionAction = createSignalAction(BASE, "RECORD_USER_SESSION")
export const userSeenPopupAction = createSignalAction(BASE, "USER_SEEN_POPUP")
export const getDashboardLinkAction = createSignalAction(BASE, "GET_DASHBOARD_LINK")
export const logoutUserAction = createSignalAction(BASE, "LOGOUT_USER")

// Confirm Modal
export const showConfirmModalAction = createSimpleCreator(BASE, "SHOW_CONFIRM_MODAL")
export const hideConfirmModalAction = createSimpleCreator(BASE, "HIDE_CONFIRM_MODAL")
export const toggleLoaderInConfirmAction = createSimpleCreator(BASE, "TOGGLE_LOADER_CONFIRM")
export const confirmAction = createSimpleCreator(BASE, "CONFIRM_ACTION")
export const cancelAction = createSimpleCreator(BASE, "CANCEL_ACTION")
export const hideFreshchatOnMobileAction = createSimpleCreator(BASE, "HIDE_FRESHCHAT_ON_MOBILE")

// Global Popups | Share the love
export const getPopUpScreenAction = createSignalAction(BASE, "POPUP_SCREENS")
//Brokers Page Actions
export const submitBrokerInterestAction = createSignalAction(BASE, "SUBMIT_BROKER_INTEREST_ACTION")
export const brokerFormModalToggleAction = createSimpleCreator(BASE, "BROKER_FORM_MODAL_TOGGLE")
export const trackRecruitAndEarnAction = createSignalAction(BASE, "TRACK_RECRUIT_AND_EARN_LINK")

// Agent page
export const getAgentsActions = createSignalAction(BASE, "GET_AGENTS")
export const getTeamsActions = createSignalAction(BASE, "GET_TEAMS")

//Partner Page Form
export const submitPartnerInterestAction = createSignalAction(BASE, "SUBMIT_PARTNER_INTEREST_ACTION")
// Transactional Pages
export const submitTrasactionInterestAction = createSignalAction(BASE, "SUBMIT_TRANSACTIONAL_INTEREST_FORM")

// New Signup Flow
export const toggleApplyModalAction = createSimpleCreator(BASE, "TOGGLE_APPLY_MODAL")
export const toggleSignUpFormAction = createSimpleCreator(BASE, "TOGGLE_SIGNUP_FORM")
export const submitInterestFormAction = createSignalAction(BASE, "SUBMIT_INTEREST_FORM")

// Referral Invite Flow
export const submittedRefferalInviteFormAction = createSimpleCreator(BASE, "SUBMIT_REFERRAL_INVITE_FORM_ACTION")

// Global
export const toggleHeaderBannerAction = createSimpleCreator(BASE, "HANDLE_TOGGLE_BANNER")
export const hideHeaderBannerAction = createSimpleCreator(BASE, "HIDE_BANNER_ACTION")
export const promotionalPopUpCTAClckedAction = createSignalAction(BASE, "PROMOTIONAL_POPUP_CTA_CLICK")
export const toggleScheduleCallBannerAction = createSimpleCreator(BASE, "TOGGLE_SCHEDULE_CAL_BANNER")
export const togglebannerPaddingAction = createSimpleCreator(BASE, "TOGGLE_BANNER_PADDING")
export const trackSessionAction = createSignalAction(BASE, "SESSION_TRACKING")
export const toggleSignAgreementModalAction = createSimpleCreator(BASE, "TOGGLE_SIGN_AGREEMENT_MODAL")
export const fakeSignDocusignAction = createSignalAction(BASE, "FAKE_SIGN_DOCUSIGN")

//Side menu
export const getSideMenuUpdatesAction = createSignalAction(BASE, "GET_SIDE_MENU_UPDATES")
export const scrollToElementAction = createSimpleCreator(BASE, "SCROLL_TO_ELEMENT")

//FCM registration
export const registerDeviceForFCMAction = createSignalAction(BASE, "REGISTER_FCM_DEVICE")
export const getNotificationReminderMappingAction = createSignalAction(BASE, "NOTIFICATION_REMINDER")
export const updateDBWithUserChoiceOnNotificationAction = createSignalAction(BASE, "UPDATE_NOTIFICATION_CHOICE")

//Home Feed
export const hideNewFeedNotificationDotAction = createSimpleCreator(BASE, "TOGGLE_HOME_SIDEBAR_DOT")
//Incomplete PROFILE
export const toggleIncompleteProfileBannerAction = createSimpleCreator(BASE, "TOGGLE_INCOMPLETE_PROFILE_BANNER")
export const updateIncompleteProfileAction = createSignalAction(BASE, "UPDATE_INCOMPLETE_PROFILE")

//homePageAction
export const toggleHeroVideoModalAction = createSimpleCreator(BASE, "HOME_PAGE_VIDEO_MODAL_TOGGLE")

//RARPopUpGlobal
export const toggleRARFormOpenAction = createSimpleCreator(BASE, "TOGGLE_RAR_POPUP")
export const isaPopUpToggleAction = createSimpleCreator(BASE, "TOGGLE_ISA_POPUP")

//InvitePopUpGlobal
export const toggleInvitePopUpAction = createSimpleCreator(BASE, "TOGGLE_INVITE_POPUP")
export const getCurrentReferralInviteStatusAction = createSignalAction(BASE, "FETCH_INVITE_STATUS_INFO")
export const postEmailInvitesAction = createSignalAction(BASE, "POST_INVITES")
export const setCurrentInviteType = createSimpleCreator(BASE, "SET_CURRENT_INVITE_TYPE")
export const toggleFeedFromPostAction = createSimpleCreator(BASE, "TOGGLE_FEED_INVITE_POST")
export const resetFeedInvitePostAction = createSimpleCreator(BASE, "RESET_INVITE_FEED")
export const toggleSuccessInviteSubmitFromFeed = createSimpleCreator(BASE, "TOGGLE_SUCCESS_INVITE_FEED_UI")
export const toggleSuccessInviteSubmitFromModalAction = createSimpleCreator(BASE, "TOGGLE_SUCCESS_INVITE_MODAL_UI")
export const toggleMilestoneInfoModalAction = createSimpleCreator(BASE, "TOGGLE_MILESTONE_POPUP")

//onboarding
export const handleOccupationChangeAction = createSignalAction(BASE, "HANDLE_OCCUPATION_CHANGE")
export const handleHappinessRangeAction = createSignalAction(BASE, "HANDLE_HAPPINESS_METER")
export const saveOccupationTypeAction = createSignalAction(BASE, "HANDLE_SAVE_OCCUPATION_TYPE")
export const setCurrentOnboardingStepAction = createSignalAction(BASE, "HANDLE_ONBOARDING_STEP")
export const triggerErrorStateAction = createSimpleCreator(BASE, "CREATE_ERROR_STATE_ONBOARDING")
export const triggerSlackNotificationAction = createSignalAction(BASE, "TRIGGER_SLACK_NOTIFICATION")

//ERROR ACTIONS
export const errorOccuredComponentAction = createSimpleCreator(BASE, "TRIGGER_ERROR_ALERT")
export const toggleErrorModalAction = createSimpleCreator(BASE, "TOGGLE_ERROR_CONTAINER")

export const setCurrentClickedStateAction = createSimpleCreator(BASE, "SET_CURRENT_STATE")
export const setToggleSignUpFormContent = createSimpleCreator(BASE, "SET_TOGGLE_SIGNUP_FORM_CONTENT")

//New State Announcement
export const toggleStateAnnouncementAction = createSimpleCreator(BASE, "TOGGLE_STATE_ANNOUNCEMENT")
export const getStateAnnouncementAction = createSignalAction(BASE, "GET_STATE_BANNER_ANNOUNCEMENT")
export const toggleRecruitAndEarnFormAction = createSimpleCreator(BASE, "TOGGLE_RAR_INAPP_FORM")

//Recruit and Earn
export const toggleRecruitAndEarnShareModalAction = createSimpleCreator(BASE, "TOGGLE_SHARE_MODAL")
export const toggleRecruitAndEarnFormViewAction = createSimpleCreator(BASE, "TOGGLE_FORM_VIEW")

//apply form success
export const toggleSuccessModalAction = createSimpleCreator(BASE, "TOGGLE_SUCCES_MODAL")
export const toggleHubspotMeetingModalAction = createSimpleCreator(BASE, "TOGGLE_HUBSPOT_MODAL")

//hubspot modal
export const setCurrentTabForSourceAction = createSimpleCreator(BASE, "SET_HUBSPOT_MEETING_SOURCE")

//new toggle for sidemenu
export const sideMenuToggleAction = createSimpleCreator(BASE, "TOGGLE_SIDE_MENU")

export const appDownloadSMSAction = createSignalAction(BASE, "APP_DOWNLOAD_ACTION")
export const setIsAppDownloadSMSFormSubmittedAction = createSimpleCreator(BASE, "APP_DONWLOAD_SUBMITTED")
export const toggleLandingPageMQLFormAction = createSimpleCreator(BASE, "TOGGLE_LP_MQL_FORM")
export const toggleEWebinarModalAction = createSimpleCreator(BASE, "TOGGLE_EWEBINAR_FORM")

//new actions for MQL
export const toggleMQLFormPostSubmitAction = createSimpleCreator(BASE, "TOGGLE_POST_SUBMIT_MQL")

//mel
export const showMelRoadBlockAction = createSimpleCreator(BASE, "SHOW_MEL_ROADBLOCK")
