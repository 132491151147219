import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  toggleAppointmentScheduling,
  fetchCurrentAppointmentSchedulingStatus,
  submitCalendlyLink,
  triggerEmail,
  triggerCrossSellAnalytics,
} from "container/Settings/actionCreators"
import {
  fetchSubscriptions,
} from "container/Referral/actionCreators"
import {
  getIsUserATeamMember,
  getIsUserATeamAdmin,
} from "container/App/selector"
import * as ReferralSelectors from "container/Referral/selector"

const mapStateToProps = (state) => {
  const { settings, app, referral } = state
  const { isAppMounted } = app
  const { assistFeatures } = referral
  const isUserATeamMember = getIsUserATeamMember(state)
  const isUserATeamAdmin = getIsUserATeamAdmin(state)
  const {
    toggleAppointmentSchedulingData,
    currentAppointmentSchedulingStatus,
    submitCalendlyLinkResponse,
    triggerEmailResponse,
  } = settings
  return {
    toggleAppointmentSchedulingData,
    currentAppointmentSchedulingStatus,
    submitCalendlyLinkResponse,
    isAppMounted,
    assistFeatures,
    pricingPlansMap: ReferralSelectors.pricingPlansMap(state),
    categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
    isUserATeamMember,
    isUserATeamAdmin,
    triggerEmailResponse,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleAppointmentScheduling,
  fetchCurrentAppointmentSchedulingStatus,
  submitCalendlyLink,
  fetchSubscriptions,
  triggerEmail,
  triggerCrossSellAnalytics,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
