import React from "react"
import styled from "styled-components"
import CloseIcon from "images/icons/CloseIconNew.png"
import { useEffect } from "react"

const Wrap = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  display: none;
  z-index: 10000;

  ${props => props.isOpen && `
    display: block;
  `}
`

const CloseWrap = styled.div`
  width: 35px;
  height: 35px;
  cursor: pointer;
  > img {
    width: 100%;
  }
`

const InnerWrap = styled.div`
  width: 80%;
  height: 100vh;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #fff;
  padding: 40px;

`

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  h2 {
    flex: 1;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
`

const Main = styled.div`
  
`

const SidePanel = ({
  title = "Side panel Header",
  toClose = () => {},
  isOpen,
  children,
}) => {
  return (
    <Wrap isOpen={isOpen}>
      <InnerWrap>
        <Header>
          <h2>
            {title}
          </h2>
          <CloseWrap onClick={toClose}>
            <img src={CloseIcon} alt="close icon" />
          </CloseWrap>
        </Header>
        <Main>
          {children}
        </Main>
      </InnerWrap>
    </Wrap>
  )
}

export default SidePanel