import * as React from "react"

const Offers = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth={0.7}
        d="M8.703 20.927c-.434 0-.858-.176-1.165-.483L2.592 15.5a1.662 1.662 0 0 1-.483-1.165c0-.434.177-.858.483-1.166L10.01 5.75a1.662 1.662 0 0 1 1.166-.483h4.945c.106 0 .207.012.306.037.75.115 1.343.81 1.343 1.611v4.946c0 .433-.177.858-.483 1.165l-7.419 7.418a1.662 1.662 0 0 1-1.165.483Zm2.473-14.836a.832.832 0 0 0-.583.242L3.175 13.75a.832.832 0 0 0-.242.583c0 .216.089.428.242.582l4.945 4.946a.85.85 0 0 0 1.165 0l7.418-7.418a.83.83 0 0 0 .242-.583V6.915a.816.816 0 0 0-.681-.804.56.56 0 0 0-.143-.02h-4.945Z"
      />
      <path
        fill={color}
        stroke={color}
        strokeWidth={0.7}
        d="M20.185 22.576h-7.303c-.45 0-.887-.174-1.202-.48a1.64 1.64 0 0 1-.504-1.169v-2.785c0-.11.043-.215.12-.292l5.407-5.407a.826.826 0 0 0 .242-.582V6.915a.816.816 0 0 0-.681-.804.412.412 0 0 1-.013-.813 1.694 1.694 0 0 1 1.484.448l3.65 3.501c.322.312.506.739.506 1.171v10.51c0 .432-.184.859-.505 1.169a1.737 1.737 0 0 1-1.201.479ZM12 18.312v2.615c0 .211.092.422.254.578.161.157.39.247.628.247h7.303c.237 0 .465-.09.628-.247a.812.812 0 0 0 .254-.578V10.418a.812.812 0 0 0-.254-.577L17.77 6.92v4.94c0 .433-.177.858-.483 1.165L12 18.312Z"
      />
      <path
        fill={color}
        d="M14.885 9.388a1.238 1.238 0 0 1-1.237-1.236 1.238 1.238 0 0 1 2.473 0c0 .681-.555 1.236-1.236 1.236Zm0-1.648a.412.412 0 1 0 0 .824.412.412 0 0 0 0-.824Z"
      />
      <path
        fill={color}
        stroke={color}
        strokeWidth={0.7}
        d="M19.83 8.564a.412.412 0 0 1-.412-.412V5.679c0-1.136-.925-2.06-2.06-2.06-1.136 0-2.061.924-2.061 2.06v1.649a.412.412 0 0 1-.824 0V5.679a2.888 2.888 0 0 1 2.885-2.885 2.888 2.888 0 0 1 2.884 2.885v2.473a.412.412 0 0 1-.412.412Z"
      />
    </svg>
  )
}

export default Offers
