import React from "react"
import {
  AssetsSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import {
  InnerTab,
  IconEle,
  NavLink,
} from "./commonStyles"

const RequestCreativeTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    show={!isBroker}
    isActive={routeArray[2] === "creative-request-form" || routeArray[1] === "request_creative"}
  >
    <div className="icon-block-new">
      <IconEle
        src={`${CLOUDFRONT}/SidebarIcons/RequestCreativeIcon.png`}
        alt="RARhome"
        isActive={routeArray[2] === "creative-request-form" || routeArray[1] === "request_creative"}
        />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? "/realty/marketing-assets/creative-request-form" : "/realty/promos?type=request_creative"}
        isActive={routeArray[2] === "creative-request-form" || routeArray[1] === "request_creative"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_marketingAssetsRAR_web")
          }
        }}
      >
        Request Creative
      </NavLink>
    </div>
  </InnerTab>
)

export default RequestCreativeTab
