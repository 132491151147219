import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Container from "container/OnboardingSetup"
import AppContainer from "container/App"
import CookiesStorage from "services/CookieStorage"
import queryString from "query-string"
import AppleIcon from "dumbComponents/Auth/SignupV2/Images/AppleIcon.svg"
import AndroidIcon from "dumbComponents/Auth/SignupV2/Images/android.svg"
import {
  QRCodeImage,
  QRCodeNetwork,
  handPhoneLogo,
  handPhoneBrokerage,
} from "../../consts"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  SignupButtons,
} from "../../common"

const QRImage = styled.img`
  margin-top: 20px;
`

const IconImage = styled.img``

const DownloadApp = ({
  history,
  getDisplayScreen,
  showQRNetowrkApp,
  fetchUserInfo,
}) => {
  const parsedQuery = queryString.parse(history.location.search)
  const { showNetworkApp, fromCardDetails } = parsedQuery || {}
  const secureId = CookiesStorage.load("secureId")
  const email = CookiesStorage.load("email")
  const showBrokerageInfo = showNetworkApp || showQRNetowrkApp
  return (
    <Wrap>
      <ContentWrap>
        <RightWrap>
          <WelcomeContentWrap downloadApp>
            <WelcomeTitle width="fit-content">
              Did you know?
            </WelcomeTitle>
            <WelcomeDescription width="448px" downloadApp>
              {`Our ${showBrokerageInfo ? "community app is" : "brokerage tools are"} mobile-friendly too! Scan the QR code to join us on the go`}
            </WelcomeDescription>
            <QRImage src={showBrokerageInfo ? QRCodeNetwork : QRCodeImage} height={100} width={100} />
          </WelcomeContentWrap>
          <WelcomeContentWrap
            style={{
              flexDirection: "row",
            }}
            downloadApp
          >
            <WelcomeDescription width="fit-content" marginTop="2px" marginRight="10px">
              Available On
            </WelcomeDescription>
            <a
              href={showBrokerageInfo
                ? "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8"
                : "https://apps.apple.com/us/app/radius-office/id1669948444"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconImage src={AppleIcon} width={25} height={26} />
            </a>
            <a
              href={showBrokerageInfo
                ? "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
                : "https://play.google.com/store/apps/details?id=com.radiusagent.brokerage"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconImage src={AndroidIcon} width={25} height={26} />
            </a>
          </WelcomeContentWrap>
          <GetStartedWrap downloadApp>
            <SignupButtons
              width="300px"
              onClick={() => fromCardDetails ? fetchUserInfo({ callback: () => history.push("/community") }) : getDisplayScreen({ secureId, email, history })}
            >
              Continue using the web app
            </SignupButtons>
          </GetStartedWrap>
        </RightWrap>
        <LeftWrap justifyContent="flex-end">
          <LeftImage src={showBrokerageInfo ? handPhoneBrokerage : handPhoneLogo} />
        </LeftWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(Container(AppContainer(DownloadApp)))
