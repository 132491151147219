import axios from "axios"

export const getPropertyListAPI = (id, bundleId) => axios
  .get(`${API_URL}property/status/${id}?bundleId=${bundleId}`)

export const updatePropertyListAPI = (id, bundleId, payload) => axios
  .put(`${API_URL}property/status/${id}?bundleId=${bundleId}`, payload)

export const updatePropertyListFromProfileAPI = (id, payload) => axios
  .put(`${API_URL}user/property/${id}`, payload)

export const deletePropertyAPI = (id, basicId) => axios
  .delete(`${API_URL}property/status/${id}?basicId=${basicId}`)
