import React, { useEffect, useState } from "react"
import OffersV2 from "container/OffersV2"
import OldOffers from "container/TechAssets"
import { SideNav } from "../components"
import {
  CoverSheetTitle,
  MenuUiWrap,
} from "./styles"

const SIDENAV_OPTIONS = [
  {
    value: "templates",
    label: "Templates",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "offer_package",
    label: "Offer Package",
  },
]

const DocusignDocs = ({
  dealToView,
  getOfferDocuments,
  getOfferDocumentsResponse,
}) => {

  const {
    isFetching: fetchingDocuments,
  } = getOfferDocumentsResponse || {}

  useEffect(() => {
    console.log("fetchingDocuments", fetchingDocuments)
    if (!fetchingDocuments) {
      getOfferDocuments({
        payload: {
          offerId: dealToView,
        },
      })
    }
  }, [])
  
  return (
    <MenuUiWrap>
      <CoverSheetTitle>
        <p>Documents</p>
      </CoverSheetTitle>
      <SideNav
        options={SIDENAV_OPTIONS}
      />
    </MenuUiWrap>
  )
}

export default OffersV2(OldOffers(DocusignDocs))

