import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import CookiesStorage from "services/CookieStorage"
import { uniq, get, isEmpty } from "lodash"
import {
  SPOTLIGHT,
  SIDEBAR,
  OTHERS_VIDEO_FEED,
  GRID,
  resizeScreen,
} from "dumbComponents/VideoRooms/utils"
import root from "window-or-global"
import * as RoomsActions from "./actions"

const SHOW_MIC = "show-hover-mic"
const HIDE_MIC = "hide-hover-mic"

const initState = {
  createApiPayload: {},
  hasUserOnboarded: false,
  isLoggedInForOnboard: false,
  roomTerminated: false,
  isMicrophoneMuted: false,
  isCameraVideoPaused: true,
  isShowCreateRoomModal: false,
  isShowSwitchRoomsModal: false,
  isShowEndRoomModal: false,
  isShareRoomModal: false,
  isPermissionModalOpen: false,
  specialRoomType: null,
  userIds: [],
  isKickUserOutModal: {
    showModal: false,
    userId: null,
  },
  isShowAgentProfileModal: {
    toShow: false,
    userProfile: [],
  },
  speakerRequestDetails: {
    agentId: null,
  },
  currentImage: "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_New_Fallback.jpg",
  currentCreateRoomPage: "select-type",
  pinnedIds: [],
  isShowMakeSpeakerModal: {
    agent: "",
    isShow: false,
  },
  screenShareStore: [],
  pinLimit: 3,
  controlsEnabled: false,
  isScreenRecordModalOn: false,
  isShowSwitchHostsModal: false,
  isShowSwitchHostsReqModal: false,
  isShowSpeakerLimitModal: false,
  isShowInviteAgentToRoomModal: false,
  isShowParticipantList: false,
  isShowRoomInfo: false,
  isSpotlightViewActive: false,
  isGridViewActive: false,
  isOtherUserViewActive: false,
  isSidebarViewActive: false,
  isRequestControlsModalOpen: false,
  isScreenSharingActive: false,
  isScreenShareModalOpen: false,
  bannerObject: {
    message: "",
    actionButton: null,
    isVisible: false,
    buttonText: "",
  },
  createRoomForm: {},
  isUserMuted: {
    muted: 0,
    agent_id: "",
  },
  isShowRemoveSpeakerModal: {
    speaker: "",
    isShowModal: false,
  },
  isShowAttendeesListModal: false,
  isInviteAttendeesModalOpen: false,
  mapOfUsers: {},
  videoStack: [],
  exit: null,
  roomToken: null,
  /**SOUNDS**/
  welcomeSound: "https://s3.amazonaws.com/cdn.agentdesks.com/audio/mixkit-happy-bell-alert-601.wav",
  recordStartSound: "https://s3.amazonaws.com/cdn.agentdesks.com/audio/RecordStart.mp3",
  recordingStopSound: "https://s3.amazonaws.com/cdn.agentdesks.com/audio/RecordingStop.mp3",
  /**SOUNDS**/

  //joyride for controls
  runForControls: false,
  stepIndexForControls: 0,
  runForControlsListener: false,
  stepIndexForControlsListener: 0,
  switchToRegisterUI: false,
  switchToLoggedOutUI: false,
  isInviteAgentModalOpen: false,
  isConfirmSignInModalOpen: {
    showConfirmationModal: false,
    email: "",
  },
  isSwitchDeviceModalOpen: false,
  isShowDeleteConfirmation: false,
  isRecording: false,
  agoraIDStore: {
    rid: null,
    sid: null,
    cameraManId: null,
    originalId: null,
  },
  unsubscribeIds: [],
  isStopRecordingModalOpen: false,
  ...createDeltaReducer(RoomsActions.getRoomsActions.RESPONSE),
  ...createDeltaReducer(RoomsActions.getTokenAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.joinRoomsActions.RESPONSE),
  ...createDeltaReducer(RoomsActions.createRoomsActions.RESPONSE),
  ...createDeltaReducer(RoomsActions.leaveRoomAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getSuggestedUsersActions.RESPONSE),
  ...createDeltaReducer(RoomsActions.sendUserInvitationAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getRoomMembersAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.updateRoomAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.muteUserAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.kickUserOutAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.becomeSpeakerAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.roomReportReasonAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.speakerRequestAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.roomReportUserAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getTagsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.createScheduleRoomAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.attendeeStatusAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.togglePinForAllAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.joinRoomAsScreenSharerAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.leaveRoomAsScreenSharerAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.bulkInviteBasedOnLocationAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.updateScheduledRoomsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getScheduleRoomsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getDefaultImagesAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.uploadImageToS3Action.RESPONSE),
  ...createDeltaReducer(RoomsActions.cancelScheduledRoomsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getPreRecordedRoomsContentAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.acquireRoomRecordingAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.deleteScheduledRoomsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.joinRoomAsScreenRecordAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.leaveRoomAsScreenRecordAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.startRoomsRecordAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.stopRoomsRecordAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.updateRecordingLayoutAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.updateSubscriptionListAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getRoomsMetaDataObjectsAction.RESPONSE),
  ...createDeltaReducer(RoomsActions.getPromotedRecommendedRoomsAction.RESPONSE),
  ...get(root, "__STATE__.publicVideoRoomDetails", {}),
  ...get(root, "__STATE__.publicPreRecordedRoomDetails", {}),
}

const reducerUIResize = (speakerCount, tentativeChildElementHeight, tentativeChildElementWidth) => {
  const maxChildrenInOneRow = Math.ceil(Math.sqrt(speakerCount + 1))
  const totalNumberOfRows = Math.ceil(speakerCount / maxChildrenInOneRow)
  const heightOfSingleChild = tentativeChildElementHeight / totalNumberOfRows
  const widthOfSingleChild = tentativeChildElementWidth / maxChildrenInOneRow
  return { heightOfSingleChild, widthOfSingleChild }
}

export default function videoRoomsReducer(state = initState, action) {
  switch (action.type) {
    case RoomsActions.toggleCreateRoomModalAction.type: {
      return {
        ...state,
        isShowCreateRoomModal: action.data,
      }
    }

    case RoomsActions.toggleSwitchRoomsModalAction.type: {
      return {
        ...state,
        isShowSwitchRoomsModal: action.data,
      }
    }
    case RoomsActions.toggleKickUserOutModalAction.type: {
      return {
        ...state,
        isKickUserOutModal: action.data,
      }
    }
    case RoomsActions.toggleSwitchHostsReqModalAction.type: {
      return {
        ...state,
        isShowSwitchHostsReqModal: action.data,
      }
    }
    case RoomsActions.toggleMuteIconAction.type: {
      return {
        ...state,
        isUserMuted: action.data,
      }
    }
    case RoomsActions.toggleSpeakerLimitModalAction.type: {
      return {
        ...state,
        isShowSpeakerLimitModal: action.data,
      }
    }
    case RoomsActions.toggleInviteAgentToRoomModalAction.type: {
      return {
        ...state,
        isShowInviteAgentToRoomModal: action.data,
      }
    }
    case RoomsActions.toggleSwitchHostsModalAction.type: {
      return {
        ...state,
        isShowSwitchHostsModal: action.data,
      }
    }
    case RoomsActions.toggleShareRoomLinkModalAction.type: {
      return {
        ...state,
        isShareRoomModal: action.data,
      }
    }
    case RoomsActions.toggleRoomInfoAction.type: {
      return {
        ...state,
        isShowRoomInfo: action.data,
      }
    }
    case RoomsActions.toggleParticipantListAction.type: {
      return {
        ...state,
        isShowParticipantList: action.data,
      }
    }
    case RoomsActions.toggleEndRoomModalAction.type: {
      return {
        ...state,
        isShowEndRoomModal: action.data,
      }
    }
    case RoomsActions.toggleAgentProfileModalAction.type: {
      return {
        ...state,
        isShowAgentProfileModal: action.data,
      }
    }

    case RoomsActions.toggleMakeSpeakerModalAction.type: {
      return {
        ...state,
        isShowMakeSpeakerModal: action.data,
      }
    }
    case RoomsActions.toggleRemoveSpeakerModalAction.type: {
      return {
        ...state,
        isShowRemoveSpeakerModal: action.data,
      }
    }
    case RoomsActions.toggleAttendeesListModalAction.type: {
      return {
        ...state,
        isShowAttendeesListModal: action.data,
      }
    }
    case RoomsActions.toggleConfirmSignInModalAction.type: {
      return {
        ...state,
        isConfirmSignInModalOpen: action.data,
      }
    }
    case RoomsActions.toggleSwitchDeviceAction.type: {
      return {
        ...state,
        isSwitchDeviceModalOpen: action.data,
      }
    }
    case RoomsActions.getRoomsActions.REQUEST:
    case RoomsActions.getRoomsActions.FAILURE:
    case RoomsActions.getRoomsActions.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getRoomsActions, RoomsActions.getRoomsActions.RESPONSE),
      }
    }

    case RoomsActions.getScheduleRoomsAction.REQUEST:
    case RoomsActions.getScheduleRoomsAction.FAILURE:
    case RoomsActions.getScheduleRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getScheduleRoomsAction, RoomsActions.getScheduleRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.attendeeStatusAction.REQUEST:
    case RoomsActions.attendeeStatusAction.FAILURE:
    case RoomsActions.attendeeStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.attendeeStatusAction, RoomsActions.attendeeStatusAction.RESPONSE),
      }
    }

    case RoomsActions.leaveRoomAction.REQUEST:
    case RoomsActions.leaveRoomAction.FAILURE:
    case RoomsActions.leaveRoomAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.leaveRoomAction,
          RoomsActions.leaveRoomAction.RESPONSE),
      }
    }

    case RoomsActions.getTokenAction.REQUEST:
    case RoomsActions.getTokenAction.FAILURE:
    case RoomsActions.getTokenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getTokenAction, RoomsActions.getTokenAction.RESPONSE),
      }
    }

    case RoomsActions.joinRoomsActions.REQUEST:
    case RoomsActions.joinRoomsActions.FAILURE:
    case RoomsActions.joinRoomsActions.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.joinRoomsActions, RoomsActions.joinRoomsActions.RESPONSE),
      }
    }

    case RoomsActions.getRoomMembersAction.REQUEST:
    case RoomsActions.getRoomMembersAction.FAILURE:
    case RoomsActions.getRoomMembersAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getRoomMembersAction, RoomsActions.getRoomMembersAction.RESPONSE),
      }
    }

    case RoomsActions.createRoomsActions.REQUEST:
    case RoomsActions.createRoomsActions.FAILURE:
    case RoomsActions.createRoomsActions.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.createRoomsActions, RoomsActions.createRoomsActions.RESPONSE),
      }
    }

    case RoomsActions.getSuggestedUsersActions.REQUEST:
    case RoomsActions.getSuggestedUsersActions.FAILURE:
    case RoomsActions.getSuggestedUsersActions.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getSuggestedUsersActions, RoomsActions.getSuggestedUsersActions.RESPONSE),
      }
    }

    case RoomsActions.sendUserInvitationAction.REQUEST:
    case RoomsActions.sendUserInvitationAction.FAILURE:
    case RoomsActions.sendUserInvitationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.sendUserInvitationAction, RoomsActions.sendUserInvitationAction.RESPONSE),
      }
    }

    case RoomsActions.updateRoomAction.REQUEST:
    case RoomsActions.updateRoomAction.FAILURE:
    case RoomsActions.updateRoomAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.updateRoomAction, RoomsActions.updateRoomAction.RESPONSE),
      }
    }

    case RoomsActions.muteUserAction.REQUEST:
    case RoomsActions.muteUserAction.FAILURE:
    case RoomsActions.muteUserAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.muteUserAction, RoomsActions.muteUserAction.RESPONSE),
      }
    }

    case RoomsActions.kickUserOutAction.REQUEST:
    case RoomsActions.kickUserOutAction.FAILURE:
    case RoomsActions.kickUserOutAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.kickUserOutAction, RoomsActions.kickUserOutAction.RESPONSE),
      }
    }

    case RoomsActions.becomeSpeakerAction.REQUEST:
    case RoomsActions.becomeSpeakerAction.FAILURE:
    case RoomsActions.becomeSpeakerAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.becomeSpeakerAction, RoomsActions.becomeSpeakerAction.RESPONSE),
      }
    }

    case RoomsActions.roomReportReasonAction.REQUEST:
    case RoomsActions.roomReportReasonAction.FAILURE:
    case RoomsActions.roomReportReasonAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.roomReportReasonAction, RoomsActions.roomReportReasonAction.RESPONSE),
      }
    }

    case RoomsActions.speakerRequestAction.REQUEST:
    case RoomsActions.speakerRequestAction.FAILURE:
    case RoomsActions.speakerRequestAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.speakerRequestAction, RoomsActions.speakerRequestAction.RESPONSE),
      }
    }

    case RoomsActions.roomReportUserAction.REQUEST:
    case RoomsActions.roomReportUserAction.FAILURE:
    case RoomsActions.roomReportUserAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.roomReportUserAction, RoomsActions.roomReportUserAction.RESPONSE),
      }
    }

    case RoomsActions.getTagsAction.REQUEST:
    case RoomsActions.getTagsAction.FAILURE:
    case RoomsActions.getTagsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.getTagsAction, RoomsActions.getTagsAction.RESPONSE),
      }
    }

    case RoomsActions.createScheduleRoomAction.REQUEST:
    case RoomsActions.createScheduleRoomAction.FAILURE:
    case RoomsActions.createScheduleRoomAction.CLEAR:
    case RoomsActions.createScheduleRoomAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, RoomsActions.createScheduleRoomAction, RoomsActions.createScheduleRoomAction.RESPONSE),
      }
    }

    case RoomsActions.userHeartBeatAction.REQUEST:
    case RoomsActions.userHeartBeatAction.FAILURE:
    case RoomsActions.userHeartBeatAction.CLEAR:
    case RoomsActions.userHeartBeatAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.userHeartBeatAction,
          RoomsActions.userHeartBeatAction.RESPONSE),
      }
    }

    //handlePubNubAction
    case RoomsActions.handlePubNubAction.REQUEST:
    case RoomsActions.handlePubNubAction.FAILURE:
    case RoomsActions.handlePubNubAction.CLEAR:
    case RoomsActions.handlePubNubAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.handlePubNubAction,
          RoomsActions.handlePubNubAction.RESPONSE),
      }
    }

    case RoomsActions.togglePinForAllAction.REQUEST:
    case RoomsActions.togglePinForAllAction.FAILURE:
    case RoomsActions.togglePinForAllAction.CLEAR:
    case RoomsActions.togglePinForAllAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.togglePinForAllAction,
          RoomsActions.togglePinForAllAction.RESPONSE),
      }
    }

    case RoomsActions.joinRoomAsScreenSharerAction.REQUEST:
    case RoomsActions.joinRoomAsScreenSharerAction.FAILURE:
    case RoomsActions.joinRoomAsScreenSharerAction.CLEAR:
    case RoomsActions.joinRoomAsScreenSharerAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.joinRoomAsScreenSharerAction,
          RoomsActions.joinRoomAsScreenSharerAction.RESPONSE),
        isScreenSharingActive: true,
      }
    }

    case RoomsActions.leaveRoomAsScreenSharerAction.REQUEST:
    case RoomsActions.leaveRoomAsScreenSharerAction.FAILURE:
    case RoomsActions.leaveRoomAsScreenSharerAction.CLEAR:
    case RoomsActions.leaveRoomAsScreenSharerAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.leaveRoomAsScreenSharerAction,
          RoomsActions.leaveRoomAsScreenSharerAction.RESPONSE),
        isScreenSharingActive: false,
      }
    }

    case RoomsActions.notifyForScheduledRoomsAction.REQUEST:
    case RoomsActions.notifyForScheduledRoomsAction.FAILURE:
    case RoomsActions.notifyForScheduledRoomsAction.CLEAR:
    case RoomsActions.notifyForScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.notifyForScheduledRoomsAction,
          RoomsActions.notifyForScheduledRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.bulkInviteBasedOnLocationAction.REQUEST:
    case RoomsActions.bulkInviteBasedOnLocationAction.FAILURE:
    case RoomsActions.bulkInviteBasedOnLocationAction.CLEAR:
    case RoomsActions.bulkInviteBasedOnLocationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.bulkInviteBasedOnLocationAction,
          RoomsActions.bulkInviteBasedOnLocationAction.RESPONSE),
      }
    }

    case RoomsActions.updateScheduledRoomsAction.REQUEST:
    case RoomsActions.updateScheduledRoomsAction.FAILURE:
    case RoomsActions.updateScheduledRoomsAction.CLEAR:
    case RoomsActions.updateScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.updateScheduledRoomsAction,
          RoomsActions.updateScheduledRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.getDefaultImagesAction.REQUEST:
    case RoomsActions.getDefaultImagesAction.FAILURE:
    case RoomsActions.getDefaultImagesAction.CLEAR:
    case RoomsActions.getDefaultImagesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getDefaultImagesAction,
          RoomsActions.getDefaultImagesAction.RESPONSE),
      }
    }

    case RoomsActions.uploadImageToS3Action.REQUEST:
    case RoomsActions.uploadImageToS3Action.FAILURE:
    case RoomsActions.uploadImageToS3Action.CLEAR:
    case RoomsActions.uploadImageToS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.uploadImageToS3Action,
          RoomsActions.uploadImageToS3Action.RESPONSE),
      }
    }

    case RoomsActions.cancelScheduledRoomsAction.REQUEST:
    case RoomsActions.cancelScheduledRoomsAction.FAILURE:
    case RoomsActions.cancelScheduledRoomsAction.CLEAR:
    case RoomsActions.cancelScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.cancelScheduledRoomsAction,
          RoomsActions.cancelScheduledRoomsAction.RESPONSE),
      }
    }

    //getPreRecordedRoomsContentAction
    case RoomsActions.getPreRecordedRoomsContentAction.REQUEST:
    case RoomsActions.getPreRecordedRoomsContentAction.FAILURE:
    case RoomsActions.getPreRecordedRoomsContentAction.CLEAR:
    case RoomsActions.getPreRecordedRoomsContentAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getPreRecordedRoomsContentAction,
          RoomsActions.getPreRecordedRoomsContentAction.RESPONSE),
      }
    }

    case RoomsActions.acquireRoomRecordingAction.REQUEST:
    case RoomsActions.acquireRoomRecordingAction.FAILURE:
    case RoomsActions.acquireRoomRecordingAction.CLEAR:
    case RoomsActions.acquireRoomRecordingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.acquireRoomRecordingAction,
          RoomsActions.acquireRoomRecordingAction.RESPONSE),
      }
    }

    //deleteScheduledRoomsAction
    case RoomsActions.deleteScheduledRoomsAction.REQUEST:
    case RoomsActions.deleteScheduledRoomsAction.FAILURE:
    case RoomsActions.deleteScheduledRoomsAction.CLEAR:
    case RoomsActions.deleteScheduledRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.deleteScheduledRoomsAction,
          RoomsActions.deleteScheduledRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.startRoomsRecordAction.REQUEST:
    case RoomsActions.startRoomsRecordAction.FAILURE:
    case RoomsActions.startRoomsRecordAction.CLEAR:
    case RoomsActions.startRoomsRecordAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.startRoomsRecordAction,
          RoomsActions.startRoomsRecordAction.RESPONSE),
      }
    }

    case RoomsActions.stopRoomsRecordAction.REQUEST:
    case RoomsActions.stopRoomsRecordAction.FAILURE:
    case RoomsActions.stopRoomsRecordAction.CLEAR:
    case RoomsActions.stopRoomsRecordAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.stopRoomsRecordAction,
          RoomsActions.stopRoomsRecordAction.RESPONSE),
      }
    }

    case RoomsActions.clearRecordingStateAction.type: {
      return {
        ...state,
        stopRoomsRecordingResponse: {
          ...state.stopRoomsRecordingResponse,
          data: null,
        }
      }
    }
    
    case RoomsActions.updateRecordingLayoutAction.REQUEST:
    case RoomsActions.updateRecordingLayoutAction.FAILURE:
    case RoomsActions.updateRecordingLayoutAction.CLEAR:
    case RoomsActions.updateRecordingLayoutAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.updateRecordingLayoutAction,
          RoomsActions.updateRecordingLayoutAction.RESPONSE),
      }
    }

    case RoomsActions.updateSubscriptionListAction.REQUEST:
    case RoomsActions.updateSubscriptionListAction.FAILURE:
    case RoomsActions.updateSubscriptionListAction.CLEAR:
    case RoomsActions.updateSubscriptionListAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.updateSubscriptionListAction,
          RoomsActions.updateSubscriptionListAction.RESPONSE),
      }
    }

    case RoomsActions.getRoomsMetaDataObjectsAction.REQUEST:
    case RoomsActions.getRoomsMetaDataObjectsAction.FAILURE:
    case RoomsActions.getRoomsMetaDataObjectsAction.CLEAR:
    case RoomsActions.getRoomsMetaDataObjectsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getRoomsMetaDataObjectsAction,
          RoomsActions.getRoomsMetaDataObjectsAction.RESPONSE),
      }
    }

    case RoomsActions.addUserToVideoContainerAction.type: {
      return {
        ...state,
      }
    }

    case RoomsActions.userCountAction.type: {
      return {
        ...state,
        userIds: uniq([...state.userIds, action.data]),
      }
    }

    case RoomsActions.toggleSpotlightViewAction.type: {
      return {
        ...state,
        isSpotlightViewActive: action.data,
      }
    }

    case RoomsActions.toggleOtherUsersViewAction.type: {
      return {
        ...state,
        isOtherUserViewActive: action.data,
      }
    }

    case RoomsActions.toggleGridViewAction.type: {
      return {
        ...state,
        isGridViewActive: action.data,
      }
    }

    case RoomsActions.toggleSidebarViewAction.type: {
      return {
        ...state,
        isSidebarViewActive: action.data,
      }
    }

    case RoomsActions.setSpeakerPriviledgesAction.type: {
      return {
        ...state,
        controlsEnabled: action.data,
      }
    }

    case RoomsActions.toggleControlsRequestModalAction.type: {
      return {
        ...state,
        isRequestControlsModalOpen: action.data,
      }
    }

    case RoomsActions.speakerRequestDetailsAction.type: {
      const {
        agentId,
      } = action.data

      const details = {
        agentId,
      }

      return {
        ...state,
        speakerRequestDetails: details,
      }
    }

    case RoomsActions.toggleVerifySpeakerRequestModalAction.type: {
      const {
        agentId,
        bool,
        message,
      } = action.data

      const details = {
        agentId,
      }

      if (message) {
        details.message = message
      }

      return {
        ...state,
        speakerRequestDetails: details,
        isVerifyRequestModalOpen: bool,
      }
    }

    case RoomsActions.addUserToMapAction.type: {
      return {
        ...state,
        mappedUsers: action.data,
      }
    }

    case RoomsActions.toggleMicrophoneAction.type: {
      return {
        ...state,
        isMicrophoneMuted: action.data,
      }
    }

    case RoomsActions.toggleCameraVideoFeedAction.type: {
      return {
        ...state,
        isCameraVideoPaused: action.data,
      }
    }

    case RoomsActions.playSoundAction.type: {
      try {
        const {
          sound,
        } = action.data || {}

        if (sound) {
          sound.loop = false
          sound.play().catch(() => {
            //do nothing
          })
        }

        return {
          ...state,
        }
      } catch (error) {
        console.log("Sound error", error)
      }
      return {
        ...state,
      }
    }

    case RoomsActions.hoverAudioToggleAction.type: {
      const {
        isMuting,
        agentId,
      } = action.data

      const userHoverMicrophoneElement = document.querySelector(`#microphone-mute-${agentId}`)
      const userActiveMicrophoneHoverElement = document.querySelector(`#microphone-unmute-${agentId}`)

      if (isMuting) {
        if (userHoverMicrophoneElement) {
          userHoverMicrophoneElement.classList.remove(HIDE_MIC)
          userHoverMicrophoneElement.classList.add(SHOW_MIC)
        }

        if (userActiveMicrophoneHoverElement) {
          userActiveMicrophoneHoverElement.classList.remove(SHOW_MIC)
          userActiveMicrophoneHoverElement.classList.add(HIDE_MIC)
        }
      } else {
        if (userActiveMicrophoneHoverElement) {
          userActiveMicrophoneHoverElement.classList.remove(HIDE_MIC)
          userActiveMicrophoneHoverElement.classList.add(SHOW_MIC)
        }

        if (userHoverMicrophoneElement) {
          userHoverMicrophoneElement.classList.remove(SHOW_MIC)
          userHoverMicrophoneElement.classList.add(HIDE_MIC)
        }
      }
      return {
        ...state,
      }
    }

    /** LOGIC
    *   CONDITION 1 -  If there is one host and one listener.
    *               - NO UNPIN Logic required.
    */

    case RoomsActions.unpinLocalUserAction.type: {
      try {
        const {
          unPinnedAgentId,
        } = action.data
        const actionUser = document.querySelector(`#video-container-${unPinnedAgentId}`)
        const grid = document.getElementById(GRID)
        const pinnedAgents = state.pinnedIds && state.pinnedIds.filter(id => id !== unPinnedAgentId)
        const speakerCount = state.userIds.length
        const pinElement = document.querySelector(`#pin-container-${unPinnedAgentId}`)
        const sidebar = document.getElementById(SIDEBAR)
        // const sidebarChildrenCount = sidebar.childElementCount
        // console.log(sidebarChildrenCount, "sidebarChildrenCount")
        if (pinElement) {
          pinElement.classList.add("hide-pin")
          pinElement.classList.remove("show-pin")
        }
  
        const PapaParentElement = document.getElementById("video-container")
        const tentativeChildElementHeight = PapaParentElement.clientHeight
        const tentativeChildElementWidth = PapaParentElement.clientWidth
  
        if (speakerCount > 1) {
          /**ONLY UNPIN AND SEND TO SIDEBAR*/
  
          /**reset style*/
          // actionUser.setAttribute("style", `height: ${reducerUIResize(speakerCount).heightOfSingleChild}px; width: ${reducerUIResize(speakerCount).widthOfSingleChild}px;`)
  
          if (actionUser && actionUser !== null) {
            actionUser.setAttribute("style", `
              position: relative;
              height: 135px;
              width: 290px;
              order: ${speakerCount - 1};
           `)
            actionUser.classList.add("rooms-box-others")
            actionUser.classList.remove("rooms-box")
            if (pinnedAgents.length > 0) {
              if (sidebar) {
                sidebar.append(actionUser)
              }
              if (pinnedAgents.length === 1) {
                const currentPinned = document.querySelector(`#video-container-${pinnedAgents[0]}`)
                if (currentPinned) {
                  console.log("here")
                  currentPinned.setAttribute("style", "")
                }
              } else {
                console.log("else condition")
              }
              return {
                ...state,
                isSidebarViewActive: true,
                pinnedIds: pinnedAgents,
              }
            }
  
            if (grid) {
              actionUser.setAttribute("style", `height: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).heightOfSingleChild}px; width: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).widthOfSingleChild}px;`)
              grid.append(actionUser)
            }
          }
  
          const sidebarUsers = document.getElementsByClassName(OTHERS_VIDEO_FEED)
          const spotlightUsers = document.getElementsByClassName("rooms-box")
          console.log(tentativeChildElementHeight, "tentativeChildElementHeight")
          console.log(tentativeChildElementWidth, "tentativeChildElementWidth")
          if (sidebarUsers && sidebarUsers.length > 0) {
            for (let index = 0; index < sidebarUsers.length; index += 1) {
              sidebarUsers[index]
                .setAttribute("style", `height: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).heightOfSingleChild}px; width: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).widthOfSingleChild}px;`)
              sidebarUsers[index].classList.add("add-grid-to-tile")
              if (grid) {
                grid.append(sidebarUsers[index])
              }
            }
          }
  
          if (spotlightUsers && spotlightUsers.length > 0) {
            console.log("spotlightUsers exists")
            for (let index = 0; index < spotlightUsers.length; index += 1) {
              spotlightUsers[index]
                .setAttribute("style", `height: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).heightOfSingleChild}px; width: ${reducerUIResize(speakerCount, tentativeChildElementHeight, tentativeChildElementWidth).widthOfSingleChild}px;`)
              spotlightUsers[index].classList.add("add-grid-to-tile")
              if (grid) {
                grid.append(spotlightUsers[index])
              }
            }
          }
  
          const sidebarChildren = sidebar.children && sidebar.children.length
  
          return {
            ...state,
            isGridViewActive: pinnedAgents.length === 0,
            isSpotlightViewActive: spotlightUsers.length > 0,
            isSidebarViewActive: sidebarChildren > 0,
            isOtherUserViewActive: false,
            pinnedIds: pinnedAgents,
          }
        }
  
        /**THIS CASE COMES ONLY WHEN HOST IS LEFT */
  
        const gridUser = document.getElementsByClassName(OTHERS_VIDEO_FEED)
        const spotlight = document.getElementById(SPOTLIGHT)
        if (spotlight && gridUser && gridUser.length > 0) {
          gridUser[0].setAttribute("style", "")
          gridUser[0].classList.add("rooms-box")
          spotlight.append(gridUser[0])
          gridUser[0].classList.remove("rooms-box-others")
        } else {
          console.error("ERROR in single host case | Line 820 : rooms/reducer.js")
        }
  
        const currentHost = document.getElementsByClassName("rooms-box")
        if (currentHost && currentHost.length > 0) {
          currentHost[0].setAttribute("style", "")
          currentHost[0].classList.add("rooms-box")
          currentHost[0].classList.remove(OTHERS_VIDEO_FEED)
        } else {
          console.error("ERROR in current host case | Line 830 : rooms/reducer.js")
        }
  
        return {
          ...state,
          isGridViewActive: false,
          isSpotlightViewActive: true,
          isSidebarViewActive: false,
          isOtherUserViewActive: false,
          pinnedIds: pinnedAgents,
        }
      } catch(e) {
        console.log("ERROR in unpin", e)
      }

      //need to check || TRY CATCH FOR UNPINNING
      return {
        ...state,
      }
    }

    case RoomsActions.pinUserLocallyAction.type: {
      try {
        const {
          idToBePinned,
        } = action.data

        /**SAFE GUARD - if already pinned return*/

        const spotlight = document.getElementById(SPOTLIGHT)
        const speakerCount = state.userIds.length
        const pinnedUsers = state.pinnedIds
        const sidebar = document.getElementById(SIDEBAR)
        const screenShares = state.screenShareStore
        const videoContainer = document.querySelector("#video-container")
        const actionUser = document.querySelector(`#video-container-${idToBePinned}`)
        const pinElement = document.querySelector(`#pin-container-${idToBePinned}`)
        if (pinElement) {
          pinElement.classList.add("show-pin")
          pinElement.classList.remove("hide-pin")
        }

        if (speakerCount >= 1 && actionUser) {
          /**Move the user to spotlight */
          actionUser.classList.add("rooms-box")

          /**FOR ONE SINGLE PINNED USER*/
          if (pinnedUsers.length === 0 && actionUser) {
            /**LOOP NOT REQUIRED PROBABLY */
            // for (let index = 0; index < pinnedUsers.length; index += 1) {
            //   const pinnedUser = document.querySelector(`#video-container-${pinnedUsers[index]}`)
            //   if (pinnedUser) {
            //     pinnedUser.setAttribute("style", "")
            //   }
            // }
            actionUser.setAttribute("style", "")
            actionUser.classList.remove("add-grid-to-tile")
          } else if (pinnedUsers.length > 0) {
            /*Loops the pinned users array and pins them */
                      
            for (let index = 0; index < pinnedUsers.length; index += 1) {
              const pinnedUser = document.querySelector(`#video-container-${pinnedUsers[index]}`)
              if (pinnedUser) {
                pinnedUser.setAttribute("style", `
                  width: 48%;
                  height: calc(50vh - 100px);
                `)
              }
            }

            /**checks if the current action user is not part of pinned users array*/
            if (!pinnedUsers.includes(idToBePinned) && actionUser) {
              actionUser.setAttribute("style", `
              width: 48%;
              height: calc(50vh - 100px);
            `)
              actionUser.classList.remove("add-grid-to-tile")
            } else if (pinnedUsers.length === 1 && actionUser) {
            /**SAFEGUARD: if the user is not part of the pinned users and is the
             * only pinned users remove resizing styles.
            */
              actionUser.setAttribute("style", "")
            } else {
            /*Unused condition - keep it for debugging purposes.
            */
              console.log("outside for loop")
            }
          }

          /*=====*/
          actionUser.classList.remove("rooms-box-others")
          actionUser.classList.remove("hidden-container")
          if (spotlight) {
            spotlight.append(actionUser)
          }

          /*move rest of the grid users to sidebar */
          const others = state.userIds.filter(userId => userId !== idToBePinned
            && !state.pinnedIds.includes(userId)
            && !screenShares.includes(userId))

          for (let index = 0; index < others.length; index += 1) {
            const restUsers = document.querySelector(`#video-container-${others[index]}`)
            const agentId = CookiesStorage.load("agentId")
            let order
            if (others[index] === parseInt(agentId, 10)) {
              order = 1
            } else {
              order = index + 2
            }
            if (restUsers) {
              restUsers.setAttribute("style", `
                position: relative;
                height: 135px;
                width: 290px;
                order: ${order};
              `)
              restUsers.classList.add(OTHERS_VIDEO_FEED)
              restUsers.classList.remove("rooms-box")
              if (sidebar) {
                sidebar.append(restUsers)
              }

              if (((index + 1) * 135) > videoContainer.clientHeight) {
                if (order === 1) {
                  const userToHide = document.querySelector(`#video-container-${others[3]}`)
                  userToHide.classList.add("hidden-container")
                  userToHide.classList.remove("add-grid-to-tile")
                } else {
                  restUsers.classList.add("hidden-container")
                  restUsers.classList.remove("add-grid-to-tile")
                }
                // restUsers.classList.remove(OTHERS_VIDEO_FEED)
              }
            }
          }
        } else {
          //NEED TO CHECK IF ANYTHING REQUIRED.
          throw new Error("USER ELEMENT DOESN'T EXIST")
        }

        const isToggleRequired = speakerCount >= 1
        const otherUsers = document.getElementsByClassName(OTHERS_VIDEO_FEED)

        if (isToggleRequired) {
          return {
            ...state,
            isGridViewActive: false,
            isSpotlightViewActive: true,
            isSidebarViewActive: otherUsers && otherUsers.length > 0,
            isOtherUserViewActive: false,
            pinnedIds: uniq([...state.pinnedIds, idToBePinned]),
          }
        }

        return {
          ...state,
          pinnedIds: uniq([...state.pinnedIds, idToBePinned]),
        }
      } catch (e) {
        console.log(e)
      }

      return {
        ...state,
      }
    }

    case RoomsActions.screenResizeAction.type: {
      resizeScreen()
      return {
        ...state,
      }
    }

    case RoomsActions.removeUserIdAction.type: {
      return {
        ...state,
        userIds: action.data,
      }
    }

    case RoomsActions.configureScreenShareAction.type: {
      return {
        ...state,
      }
    }

    case RoomsActions.removeUserElementFromScreenAction.type: {
      const {
        id: unique_id,
      } = action.data

      const screenShareDiv = document.querySelector(`#video-container-${unique_id}`)
      if (screenShareDiv) screenShareDiv.remove()

      return {
        ...state,
      }
    }

    case RoomsActions.toggleVideoRoomsBannerAction.type: {
      const {
        message,
        actionButton,
        isVisible,
        buttonText,
      } = action.data

      const bannerObject = {
        message,
        actionButton,
        isVisible,
        buttonText,
      }

      return {
        ...state,
        bannerObject,
      }
    }

    case RoomsActions.revertToHostOnlyUIAction.type: {
      const {
        hostId,
      } = action.data

      const spotlight = document.getElementById(SPOTLIGHT)
      const host = document.querySelector(`#video-container-${hostId}`)

      /**
       * Remove and add classes
       */
      host.classList.add("rooms-box", "ui-change")
      host.setAttribute("style", "")
      host.classList.remove(OTHERS_VIDEO_FEED)
      host.classList.remove("add-grid-to-tile")

      /**append to spotlight*/
      if (spotlight) {
        spotlight.append(host)
      }

      return {
        ...state,
        isSidebarViewActive: false,
        isGridViewActive: false,
        isSpotlightViewActive: true,
        isOtherUserViewActive: false,
      }
    }

    case RoomsActions.roomEndAction.type: {
      return {
        ...state,
        roomTerminated: true,
      }
    }

    case RoomsActions.toggleToLoggedInViewAction.type: {
      return {
        ...state,
        isLoggedInForOnboard: action.data,
      }
    }

    case RoomsActions.triggerJoyRideForVideoRoomsControlsAction.type: {
      const {
        bool,
        stepIndexForControls,
      } = action.data

      return {
        ...state,
        runForControls: bool,
        stepIndexForControls,
      }
    }

    //triggerJoyRideForVideoRoomsListener
    case RoomsActions.triggerJoyRideForVideoRoomsListenerAction.type: {
      const {
        bool,
        stepIndexForControlsListener,
      } = action.data

      return {
        ...state,
        runForControlsListener: bool,
        stepIndexForControlsListener,
      }
    }

    case RoomsActions.toggleScreenShareStatusAction.type: {
      return {
        ...state,
        isScreenSharingActive: action.data,
      }
    }

    case RoomsActions.toggleScreenShareModalAction.type: {
      const {
        bool,
        screenShareFunction,
        exit,
      } = action.data

      return {
        ...state,
        isScreenShareModalOpen: bool,
        screenShareFunction,
        exit,
      }
    }

    case RoomsActions.togglePermissionModalAction.type: {
      return {
        ...state,
        isPermissionModalOpen: action.data,
      }
    }

    case RoomsActions.setUserOnboardStatusAction.type: {
      const isOnboarded = action.data
      CookiesStorage.save("hasUserOnboarded", isOnboarded)
      return {
        ...state,
        hasUserOnboarded: isOnboarded,
      }
    }

    case RoomsActions.switchUIScheduleRoomsAction.type: {
      return {
        ...state,
        switchToRegisterUI: action.data,
        switchToLoggedOutUI: false,
      }
    }

    case RoomsActions.switchUItoLoggedOutViewAction.type: {
      return {
        ...state,
        switchToLoggedOutUI: action.data,
        switchToRegisterUI: false,
      }
    }

    case RoomsActions.updateScreenShareStoreAction.type: {
      const {
        userId,
        uniqueId,
      } = action.data

      const storePayload = {
        userId,
        uniqueId,
      }

      const storeArray = []
      storeArray.push(storePayload)

      return {
        ...state,
        screenShareStore: uniq([...state.screenShareStore, ...storeArray]),
      }
    }

    case RoomsActions.removeIDScreenShareStoreAction.type: {
      const {
        id,
      } = action.data

      const current = state.screenShareStore
      const filtered = current && current.filter(item => item.uniqueId !== id) || []

      return {
        ...state,
        screenShareStore: filtered || [],
      }
    }

    case RoomsActions.createRoomPageSwitchAction.type: {
      const {
        page,
        type,
      } = action.data

      return {
        ...state,
        currentCreateRoomPage: page,
        specialRoomType: type,
      }
    }

    case RoomsActions.handleCreateRoomFormChangeAction.type: {
      const {
        name,
        value,
      } = action.data

      const currentFormState = state.createRoomForm
      const createRoomForm = {
        ...currentFormState,
        [name]: value,
      }

      return {
        ...state,
        createRoomForm,
      }
    }

    case RoomsActions.toggleImageUploadModalAction.type: {
      return {
        ...state,
        isImageUploadOpen: action.data,
      }
    }

    case RoomsActions.setImageUrlAction.type: {
      return {
        ...state,
        currentImage: action.data,
      }
    }

    case RoomsActions.clearFormImageAction.type: {
      return {
        ...state,
        currentImage: null,
      }
    }

    case RoomsActions.toggleInviteSpeakersModalAction.type: {
      return {
        ...state,
        isInviteAgentModalOpen: action.data,
      }
    }

    case RoomsActions.toggleInviteAttendeesModalAction.type: {
      return {
        ...state,
        isInviteAttendeesModalOpen: action.data,
      }
    }

    case RoomsActions.updatePayloadAction.type: {
      const payload = action.data
      return {
        ...state,
        createApiPayload: {
          ...state.createApiPayload,
          ...payload,
        },
      }
    }

    case RoomsActions.clearCreatePayloadAction.type: {
      return {
        ...state,
        currentCreateRoomPage: "select-type",
        currentImage: "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_New_Fallback.jpg",
        createApiPayload: {},
      }
    }

    case RoomsActions.clearAndResetAllForms.type: {
      return {
        ...state,
      }
    }

    case RoomsActions.getRoomTopicsAction.REQUEST:
    case RoomsActions.getRoomTopicsAction.FAILURE:
    case RoomsActions.getRoomTopicsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getRoomTopicsAction,
          RoomsActions.getRoomTopicsAction.RESPONSE),
      }
    }

    case RoomsActions.getPromotedRoomsAction.REQUEST:
    case RoomsActions.getPromotedRoomsAction.FAILURE:
    case RoomsActions.getPromotedRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getPromotedRoomsAction,
          RoomsActions.getPromotedRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.joinRoomAsScreenRecordAction.REQUEST:
    case RoomsActions.joinRoomAsScreenRecordAction.FAILURE:
    case RoomsActions.joinRoomAsScreenRecordAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.joinRoomAsScreenRecordAction,
          RoomsActions.joinRoomAsScreenRecordAction.RESPONSE),
      }
    }

    case RoomsActions.leaveRoomAsScreenRecordAction.REQUEST:
    case RoomsActions.leaveRoomAsScreenRecordAction.FAILURE:
    case RoomsActions.leaveRoomAsScreenRecordAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.leaveRoomAsScreenRecordAction,
          RoomsActions.leaveRoomAsScreenRecordAction.RESPONSE),
      }
    }

    case RoomsActions.getPromotedRecommendedRoomsAction.REQUEST:
    case RoomsActions.getPromotedRecommendedRoomsAction.FAILURE:
    case RoomsActions.getPromotedRecommendedRoomsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getPromotedRecommendedRoomsAction,
          RoomsActions.getPromotedRecommendedRoomsAction.RESPONSE),
      }
    }

    case RoomsActions.getRoomTopicEventsAction.REQUEST:
    case RoomsActions.getRoomTopicEventsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          RoomsActions.getRoomTopicEventsAction,
          RoomsActions.getRoomTopicEventsAction.RESPONSE),
      }
    }
    case RoomsActions.getRoomTopicEventsAction.SUCCESS: {
      try {
        const sortedEvents = []
        const roomTopicsEvents = action.data || {}
        const roomTopics = state.getRoomTopicsResponse.data
        if (state && state.getRoomTopicsResponse.data && roomTopicsEvents) {
          roomTopics && roomTopics.length > 0 && roomTopics.map((roomTopic, index) => {
            sortedEvents[roomTopic.topic_name.split(" ").join("_")] = []
            // on the 0th index, "Others" category room is shown
            // if any changes are made, backend needs to send "Others" in the 0th index for
            // the roomtopic api and we need to change index + 1 -> index two lines below
            console.log(roomTopicsEvents, "roomTopicsEvents")
            sortedEvents[roomTopic.topic_name.split(" ").join("_")].push(roomTopicsEvents[index + 1])
          })
        }
        return {
          ...state,
          ...handleDeltaReducer(state,
            action,
            RoomsActions.getRoomTopicEventsAction,
            RoomsActions.getRoomTopicEventsAction.RESPONSE),
          getRoomTopicEventsResponse: {
            error: false,
            isFetching: false,
            data: {
              sortedEvents,
            },
          },
        }
      } catch (e) {
        console.log(e)
        return {
          ...state,
        }
      }
    }

    case RoomsActions.toggleScreenRecordModalAction.type: {
      return {
        ...state,
        isScreenRecordModalOn: action.data,
      }
    }

    case RoomsActions.storeTokenAction.type: {
      return {
        ...state,
        roomToken: action.data,
      }
    }
  
    case RoomsActions.toggleDeleteRoomModalAction.type: {
      return {
        ...state,
        isShowDeleteConfirmation: action.data,
      }
    }

    case RoomsActions.toggleRecordingSessionPromptAction.type: {
      return {
        ...state,
        isRecording: action.data,
      }
    }

    case RoomsActions.storeRIDSIDAction.type: {
      const {
        rid,
        sid,
        cameraManId,
        originalId,
      } = action.data || {}

      const {
        rid: oldRid,
        sid: oldSid,
        cameraManId: oldCameraManId,
        originalId: oldOriginalId,
      } = state.agoraIDStore || {}

      return {
        ...state,
        agoraIDStore: {
          rid: rid || oldRid,
          sid: sid || oldSid,
          cameraManId: cameraManId || oldCameraManId,
          originalId: originalId || oldOriginalId,
        },
      }
    }

    case RoomsActions.createUnSubscribeIDArrayAction.type: {
      const {
        id,
      } = action.data

      const current = [...state.unsubscribeIds]
      current.push(id)
      const updated = uniq(current)

      return {
        ...state,
        unsubscribeIds: updated,
      }
    }

    case RoomsActions.toggleStopRecordingModalAction.type: {
      return {
        ...state,
        isStopRecordingModalOpen: action.data,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
