import { getStoreKeyForReferrals } from "services/Utils"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import { paymentConfirmationStates } from "dumbComponents/Referral/Checkout"
import { updateCardModalStates } from "dumbComponents/Settings/Billing"
import * as ClientActions from "container/Client/actions"
import { console } from "window-or-global"
import * as ReferralActions from "./actions"

const initState = {
  isFetchingReferrals: true,
  cartHasTeamPlan: false,
  isModalForMarketplaceOpen: false,
  monthlyPlanIfTeam: 0,
  isFormLoaded: false,
  isCartHavingTeamPlan: false,
  isFetchingFilterSkeleton: true,
  isFetchingArchivedReferrals: true,
  isFetchingMetric: true,
  isFilterPanelOpen: false,
  isShowAddReminderModal: false,
  isShowArchiveModal: false,
  isFetchingShowMore: false,
  isStarred: false,
  showProspectsModal: false,
  isChecklistModalOpen: false,
  isStarringReferral: false,
  isArchivedLeadModalOpen: false,
  isUserConfirmModalOpen: false,
  isUnsubscribing: false,
  isFetchingInvoices: false,
  isFetchingPlan: false,
  isFetchingSubscription: false,
  finishedFetchingSubscriptions: false,
  isFetchingProspects: false,
  isFetchingCard: false,
  defaultCard: null,
  updateCardModalState: false,
  isFirstLeadUpdate: true,
  lastAcceptedReferral: {},
  isInterested: false,
  isSubmittingForm: false,
  isShowTeamLogoModal: false,
  interestFormSubmitted: false,
  isShowInviteFlow: false,
  isShowChangeRoleModal: false,
  currentSourcesFilter: "",
  currentWcrFilterActive: false,
  isISASubmittedProspectModal: false,
  pricingPlansMap: [],
  inviteModal: {
    isOpenInviteModal: false,
    type: "",
  },
  archivedReferrals: [],
  outboundReferrals: {},
  isCancelInvite: false,
  assignAgentKeys: {
    isOpenAssignModal: false,
  },
  currentTeamFilterValue: [{
    display_name: "Lifetime",
    value: "all",
  }],
  inboundReferrals: {
    allReferrals: {},
    dragDropColumns: {},
    checklistData: {},
  },
  prospects: {
    data: [],
    currentProspect: null,
    modalState: false,
    errorCode: 0,
    errorMessage: "Please try again later",
  },
  outboundProspects: {
    data: [],
    currentProspect: null,
    modalState: false,
    errorCode: 0,
    errorMessage: "Please try again later",
  },
  filterSkeleton: {},
  selectedFilters: {},
  confirmationModalMessage: "",
  isPaymentSettingsModalOpen: {
    isOpen: false,
    tab: 1,
  },
  isSubscribing: {
    request: false,
    modalState: false,
  },
  invoices: [],
  isReferringLead: {
    request: false,
    error: false,
  },
  isaDropdownValuesObject: {},
  currentPlanType: "Single",
  teamSize: 1,
  isaPricing: {
    cart: {
      rtl: null,
      blu: null,
    },
    coupon: {
      isApplying: false,
      couponCode: "",
      isValid: false,
      planId: "",
      trialDays: null,
    },
    paymentModalState: false,
    isRemoveTeamMemberModalOpen: false,
    isRemoveMemberSuccessModalOpen: false,
    currentlyRemovingID: null,
    currentlyReInvitingMemberID: null,
    currentLimitForLeaderboard: 7,
  },
  // todo
  ...createDeltaReducer("singleProspect"),
  ...createDeltaReducer("pricingPlans"),
  ...createDeltaReducer("acceptLeadDropDownValues"),
  ...createDeltaReducer("settingPermissionValue"),
  ...createDeltaReducer("teamMembersList"),
  ...createDeltaReducer("teamMembers"),
  ...createDeltaReducer("teamFilterValues"),
  ...createDeltaReducer("assigningAgent"),
  ...createDeltaReducer("referOut"),
  ...createDeltaReducer("inviteAgent"),
  ...createDeltaReducer("teamAcceptResponse"),
  ...createDeltaReducer("removeMember"),
  ...createDeltaReducer("seatAmount"),
  ...createDeltaReducer("removingMember"),
  ...createDeltaReducer("submittedMetrics"),
  ...createDeltaReducer("agentSources"),
  ...createDeltaReducer("teamInfoResponse"),
  ...createDeltaReducer("uploadTeamImageResponse"),
  ...createDeltaReducer("changeTeamRoleResponse"),
  ...createDeltaReducer("plansDurationResponse"),
  ...createDeltaReducer("exportToExcelResponse"),
  ...createDeltaReducer("shareTheLoveInviteeResponse"),
  // Recruit and Earn
  ...createDeltaReducer("brokerInvitesResponse"),
  ...createDeltaReducer("brokerEarningResponse"),
  ...createDeltaReducer("sendBrokerInviteResponse"),
  // Sales Stage Update
  ...createDeltaReducer("saleUpdateResponse"),
  ...createDeltaReducer("closingStatgeInfoResponse"),
  ...createDeltaReducer("pendingClientInboundResponse"),
  ...createDeltaReducer("acceptOrRejectLeadResponse"),
  // Sales Stage Update
  ...createDeltaReducer("saleUpdateResponse"),
  ...createDeltaReducer("closingStatgeInfoResponse"),
  ...createDeltaReducer("fetchAgentSearchResponse"),
  ...createDeltaReducer("findAnAgentViewedResponse"),
  ...createDeltaReducer("getCurrrentUserRecruitAndEarnDetailsResponse"),
  ...createDeltaReducer("recruitAndEarnLeaderboardResponse"),
  ...createDeltaReducer("psuedoDocuSignResponse"),
}

export default function referralReducer(state = initState, action) {
  switch (action.type) {
    // Sign Agreement fetchOutboundReferralAPI
    case ReferralActions.fetchSignedAgreementAction.REQUEST: {
      return {
        ...state,
        isLoadingLeadAgreement: true,
      }
    }
    case ReferralActions.fetchSignedAgreementAction.SUCCESS:
    case ReferralActions.fetchSignedAgreementAction.FAILURE: {
      return {
        ...state,
        isLoadingLeadAgreement: false,
      }
    }

    case ReferralActions.saveQueryParamForReferralsAction.type: {
      return {
        ...state,
        parsedQueryFinal: action.data,
      }
    }

    // Share the love flow actions here
    case ReferralActions.startShareTheLoveInviteFlowAction: {
      const { invtee } = action.data
      return {
        ...state,
        invtee,
      }
    }

    case ReferralActions.toggleInviteBannerAction.type: {
      return {
        ...state,
        isShowInviteeBanner: action.data,
      }
    }

    case ReferralActions.toggleInviteModalAction.type: {
      return {
        ...state,
        isShowInviteFlow: action.data,
      }
    }

    case ReferralActions.getInviteeForSTLAction.REQUEST:
    case ReferralActions.getInviteeForSTLAction.SUCCESS:
    case ReferralActions.getInviteeForSTLAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.getInviteeForSTLAction, "shareTheLoveInviteeResponse"),
      }
    }

    case ReferralActions.findAnAgentViewedAction.REQUEST:
    case ReferralActions.findAnAgentViewedAction.SUCCESS:
    case ReferralActions.findAnAgentViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.findAnAgentViewedAction, "findAnAgentViewedResponse"),
      }
    }

    case ReferralActions.fetchAgentSearchAction.REQUEST:
    case ReferralActions.fetchAgentSearchAction.SUCCESS:
    case ReferralActions.fetchAgentSearchAction.CLEAR:
    case ReferralActions.fetchAgentSearchAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.fetchAgentSearchAction, "fetchAgentSearchResponse"),
      }
    }

    case ReferralActions.getInboundPendingReferralDetailsAction.REQUEST:
    case ReferralActions.getInboundPendingReferralDetailsAction.SUCCESS:
    case ReferralActions.getInboundPendingReferralDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.getInboundPendingReferralDetailsAction, "pendingClientInboundResponse"),
      }
    }
    // Share the love | Ends
    case ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.REQUEST:
    case ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.SUCCESS:
    case ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.getCurrentUserRecruitAndEarnDetailsAction,
          "getCurrrentUserRecruitAndEarnDetailsResponse"
        ),
      }
    }

    //getCurrentRecruitAndEarnLeaderboardDetailsAction
    case ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.REQUEST:
    case ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.SUCCESS:
    case ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction,
          "recruitAndEarnLeaderboardResponse"
        ),
      }
    }

    case ReferralActions.psuedoDocuSignAction.REQUEST:
    case ReferralActions.psuedoDocuSignAction.SUCCESS:
    case ReferralActions.psuedoDocuSignAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.psuedoDocuSignAction,
          "psuedoDocuSignResponse"
        ),
      }
    }

    case ReferralActions.fetchReferralsAction.REQUEST: {
      const { showMore } = action.data

      return {
        ...state,
        isFetchingReferrals: !showMore,
        isFetchingShowMore: showMore,
      }
    }

    case ReferralActions.fetchReferralsAction.SUCCESS: {
      const { response, type } = action.data
      return {
        ...state,
        type,
        isFetchingReferrals: false,
        isFetchingShowMore: false,
        [getStoreKeyForReferrals(type)]: { ...response },
      }
    }

    case ReferralActions.fetchArchivedReferralsAction.REQUEST: {
      return {
        ...state,
        isArchivedLeadModalOpen: true,
        isFetchingArchivedReferrals: true,
      }
    }

    case ReferralActions.fetchArchivedReferralsAction.SUCCESS: {
      const { type, archived } = action.data
      return {
        ...state,
        type,
        isFetchingArchivedReferrals: false,
        archivedReferrals: archived,
      }
    }

    case ReferralActions.fetchArchivedReferralsAction.FAILURE: {
      return {
        ...state,
        isFetchingArchivedReferrals: false,
      }
    }

    // Filter Skeleton
    case ReferralActions.fetchFilterSkeletonAction.FAILURE:
    case ReferralActions.fetchFilterSkeletonAction.SUCCESS: {
      return {
        ...state,
        isFetchingFilterSkeleton: false,
        filterSkeleton: { ...action.data },
      }
    }

    // Filters Selection
    case ReferralActions.SELECT_FILTER: {
      const { selectedFilters } = state
      const { type, item } = action.data
      const itemIndex = (
        type !== "referralSort"
        && type !== "Phone Options"
      ) && selectedFilters[type] && selectedFilters[type].findIndex(x => x.value === item.value)
      const newSelectedFilters = { ...selectedFilters }
      if (type === "referralSort") {
        if (newSelectedFilters.referralSort && newSelectedFilters.referralSort.value === item.value) {
          newSelectedFilters.referralSort = null
        } else {
          newSelectedFilters.referralSort = { ...item }
        }
      } else if (type === "Phone Options") {
        if (newSelectedFilters["Phone Options"] && newSelectedFilters["Phone Options"].value === item.value) {
          newSelectedFilters["Phone Options"] = null
        } else {
          newSelectedFilters["Phone Options"] = { ...item }
        }
      } else if (itemIndex === undefined || itemIndex === -1) {
        const selectedType = selectedFilters[type] ? [...selectedFilters[type], item] : [item]
        newSelectedFilters[type] = selectedType
      } else {
        const selectedType = selectedFilters[type]
        selectedType.splice(itemIndex, 1)
        newSelectedFilters[type] = selectedType
      }
      return {
        ...state,
        selectedFilters: newSelectedFilters,
      }
    }

    case ReferralActions.fetchStarReferralAction.REQUEST: {
      const { id } = action.data
      return {
        ...state,
        isStarringReferral: true,
      }
    }

    case ReferralActions.fetchStarReferralAction.SUCCESS: {
      const {
        id,
        referralType,
        index,
        starred,
        type,
      } = action.data
      if (referralType === "incoming") {
        return {
          ...state,
          isStarringReferral: false,
          inboundReferrals: {
            ...state.inboundReferrals,
            allReferrals: {
              ...state.inboundReferrals.allReferrals,
              [id]: {
                ...state.inboundReferrals.allReferrals[id],
                starred: !starred,
              },
            },
          },
        }
      }
      //error code -
      // const { submittedIsaReferrals: referrals } = state[type]
      // const selectedReferralIndex = referrals && referrals.findIndex(x => x.key === referralType)
      // referrals[selectedReferralIndex].value[index].starred = !starred

      //fix
      const { submittedIsaReferrals: referrals } = state[type]
      referrals[index].starred = !starred
      return {
        ...state,
        isStarringReferral: false,
        [getStoreKeyForReferrals(type)]: { ...referrals },
      }
    }

    case ReferralActions.signCRSAgreementAction.SUCCESS: {
      const { url } = action.data
      return {
        ...state,
        url,
      }
    }

    case ReferralActions.GET_STARRED_REFERRALS: {
      const { isStarred } = action.data
      return {
        ...state,
        isStarred: !isStarred,
      }
    }

    case ReferralActions.fetchReferalMetricAction.REQUEST: {
      return {
        ...state,
        isFetchingMetric: true,
      }
    }

    case ReferralActions.fetchReferalMetricAction.FAILURE:
    case ReferralActions.fetchReferalMetricAction.SUCCESS: {
      return {
        ...state,
        isFetchingMetric: false,
        referralMetric: action.data || {},
      }
    }

    case ReferralActions.subscribeAction.REQUEST: {
      return {
        ...state,
        isSubscribing: {
          ...state.isSubscribing,
          request: true,
          modalState: "loading",
        },
      }
    }

    case ReferralActions.subscribeAction.SUCCESS: {
      return {
        ...state,
        isSubscribing: {
          ...state.isSubscribing,
          request: false,
          modalState: "success",
        },
      }
    }

    case ReferralActions.subscribeAction.FAILURE: {
      return {
        ...state,
        isSubscribing: {
          ...state.isSubscribing,
          request: false,
          modalState: "failure",
        },
      }
    }

    case ReferralActions.unsubscribeAction.REQUEST: {
      return {
        ...state,
        isUnsubscribing: true,
      }
    }

    case ReferralActions.unsubscribeAction.SUCCESS:
    case ReferralActions.unsubscribeAction.FAILURE: {
      return {
        ...state,
        isUnsubscribing: false,
      }
    }

    case ReferralActions.fetchInvoicesAction.REQUEST: {
      return {
        ...state,
        isFetchingInvoices: true,
      }
    }

    case ReferralActions.fetchInvoicesAction.SUCCESS: {
      const { invoices } = action.data
      return {
        ...state,
        isFetchingInvoices: false,
        invoices,
      }
    }

    case ReferralActions.fetchInvoicesAction.FAILURE: {
      return {
        ...state,
        isFetchingInvoices: false,
      }
    }

    case ReferralActions.fetchPlansAction.REQUEST: {
      return {
        ...state,
        isFetchingPlan: true,
      }
    }

    case ReferralActions.fetchPlansAction.SUCCESS:
    case ReferralActions.fetchPlansAction.FAILURE: {
      const { plans } = action.data
      return {
        ...state,
        plans,
        isFetchingPlan: false,
      }
    }

    case ReferralActions.fetchSubscriptionsAction.REQUEST: {
      return {
        ...state,
        isFetchingSubscription: true,
        finishedFetchingSubscriptions: false,
      }
    }

    case ReferralActions.fetchSubscriptionsAction.SUCCESS: {
      const { response, featureMap, leadsCapThreshold } = action.data
      const { subscriptions } = response || {}
      return {
        ...state,
        subscriptions,
        leadsCapThreshold,
        assistFeatures: featureMap,
        isFetchingSubscription: false,
        finishedFetchingSubscriptions: true,
      }
    }

    case ReferralActions.fetchSubscriptionsAction.FAILURE: {
      const { response, featureMap } = action.data
      const { subscriptions } = response || {}
      return {
        ...state,
        subscriptions,
        assistFeatures: featureMap,
        isFetchingSubscription: false,
        finishedFetchingSubscriptions: true,
      }
    }

    case ReferralActions.fetchProspectsAction.REQUEST: {
      return {
        ...state,
        isFetchingProspects: true,
      }
    }

    case ReferralActions.fetchProspectsAction.SUCCESS:
    case ReferralActions.fetchProspectsAction.FAILURE: {
      const { prospects: prospectsData, referralType } = action.data
      return {
        ...state,
        prospects: {
          ...state.prospects,
          data: prospectsData,
          fullProspects: [...prospectsData],
        },
        outboundProspects: referralType === "outgoing" ? {
          ...state.outboundProspects,
          data: prospectsData,
        } : {
          ...state.outboundProspects,
        },
        isFetchingProspects: false,
      }
    }

    case ReferralActions.updateProspectAction.REQUEST: {
      return {
        ...state,
        prospects: {
          ...state.prospects,
        },
      }
    }

    case ReferralActions.updateProspectAction.FAILURE: {
      const { errorCode, errorMessage } = action.data
      return {
        ...state,
        prospects: {
          ...state.prospects,
          errorCode,
          errorMessage,
        },
      }
    }

    case ReferralActions.fetchDefaultCardAction.REQUEST: {
      return {
        ...state,
        isFetchingCard: true,
      }
    }

    case ReferralActions.fetchDefaultCardAction.SUCCESS: {
      const defaultCard = action.data
      return {
        ...state,
        isFetchingCard: false,
        defaultCard,
      }
    }

    case ReferralActions.fetchDefaultCardAction.FAILURE: {
      return {
        ...state,
        isFetchingCard: false,
      }
    }

    case ReferralActions.updateCardAction.REQUEST: {
      return {
        ...state,
        updateCardModalState: updateCardModalStates.loading,
      }
    }

    case ReferralActions.updateCardAction.SUCCESS: {
      return {
        ...state,
        updateCardModalState: updateCardModalStates.success,
      }
    }

    case ReferralActions.updateCardAction.FAILURE: {
      return {
        ...state,
        updateCardModalState: updateCardModalStates.error,
      }
    }

    case ReferralActions.toggleUpdateCardModal.type: {
      const updateCardModalState = action.data
      return {
        ...state,
        updateCardModalState,
      }
    }

    case ReferralActions.referLeadAction.REQUEST: {
      return {
        ...state,
        isReferringLead: {
          request: true,
          error: false,
        },
      }
    }

    case ReferralActions.referLeadAction.SUCCESS: {
      return {
        ...state,
        isReferringLead: {
          request: false,
          error: false,
        },
      }
    }

    case ReferralActions.referLeadAction.FAILURE: {
      const { message } = action.data
      return {
        ...state,
        isReferringLead: {
          ...state.isReferringLead,
          request: false,
          error: message,
        },
      }
    }

    case ReferralActions.OPEN_FILTER_PANEL: {
      return {
        ...state,
        isFilterPanelOpen: true,
      }
    }

    case ReferralActions.CLOSE_FILER_PANEL: {
      return {
        ...state,
        isFilterPanelOpen: false,
      }
    }

    case ReferralActions.SAVE_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: action.data,
        selectedFilters: action.data,
      }
    }

    case ReferralActions.CLEAR_FILTERS: {
      return {
        ...state,
        isStarred: false,
        selectedFilters: {},
      }
    }

    case ReferralActions.REMOVE_FILTER: {
      const { selectedFilters } = state
      const { id, item } = action.data
      if (id === "Phone Options") {
        delete selectedFilters[id]
      } else {
        const itemIndex = selectedFilters[id] && selectedFilters[id].findIndex(x => x.value === item.value)
        selectedFilters[id].splice(itemIndex, 1)
      }
      return {
        ...state,
        selectedFilters: { ...selectedFilters },
        appliedFilters: { ...selectedFilters },
      }
    }

    case ReferralActions.OPEN_CHECKLIST_MODAL: {
      return {
        ...state,
        isChecklistModalOpen: true,
      }
    }

    case ReferralActions.CLOSE_CHECKLIST_MODAL: {
      return {
        ...state,
        isChecklistModalOpen: false,
      }
    }

    case ReferralActions.changeInboundReferralOrderAction.SUCCESS:
    case ReferralActions.changeInboundReferralStageAction.SUCCESS: {
      const { dragDropColumns } = action.data
      return {
        ...state,
        inboundReferrals: {
          ...state.inboundReferrals,
          dragDropColumns,
        },
      }
    }

    case ReferralActions.UPDATE_CHECKLIST_IN_REFERRAL: {
      const { allReferrals } = action.data
      return {
        ...state,
        inboundReferrals: {
          ...state.inboundReferrals,
          allReferrals,
        },
      }
    }
    case ReferralActions.OPEN_ADD_REMINDER_MODAL: {
      return {
        ...state,
        isShowAddReminderModal: action.data,
      }
    }

    case ReferralActions.CLOSE_ADD_REMINDER_MODAL: {
      return {
        ...state,
        isShowAddReminderModal: false,
      }
    }

    case ReferralActions.SET_CHECKLIST_IN_REFERRAL: {
      const { checklistData } = action.data
      return {
        ...state,
        inboundReferrals: {
          ...state.inboundReferrals,
          checklistData,
        },
      }
    }

    case ReferralActions.toggleSendToMarketPlaceModalAction.type: {
      const {
        flag,
        id,
      } = action.data
      return {
        ...state,
        isModalForMarketplaceOpen: flag,
        currentReferralToBeSentLMP: id,
      }
    }

    case ReferralActions.UPDATE_DRAG_DROP_COLUMNS: {
      const { dragDropColumns } = action.data
      return {
        ...state,
        inboundReferrals: {
          ...state.inboundReferrals,
          dragDropColumns,
        },
      }
    }

    case ReferralActions.GET_MORE_REFERRALS: {
      return {
        ...state,
        isFetchingShowMore: action.data,
      }
    }

    case ReferralActions.TOGGLE_ARCHIVE_MODAL: {
      return {
        ...state,
        isShowArchiveModal: action.data,
      }
    }

    case ReferralActions.UPDATE_REFERRAL_NOTIFY_TIME: {
      const { referralId, date } = action.data
      const newAllReferral = state.inboundReferrals.allReferrals
      newAllReferral[referralId].notify_time = date
      return {
        ...state,
        allReferrals: { ...newAllReferral },
      }
    }

    case ReferralActions.OPEN_ARCHIVE_LEADS_MODAL: {
      return {
        ...state,
        isArchivedLeadModalOpen: true,
      }
    }

    case ReferralActions.CLOSE_ARCHIVE_LEADS_MODAL: {
      return {
        ...state,
        isArchivedLeadModalOpen: false,
      }
    }

    case ReferralActions.OPEN_PAYMENT_SETTINGS_MODAL: {
      const { tabIndex } = action.data
      return {
        ...state,
        isPaymentSettingsModalOpen: {
          ...state.isPaymentSettingsModalOpen,
          isOpen: true,
          tab: tabIndex || 1,
        },
      }
    }

    case ReferralActions.CLOSE_PAYMENT_SETTINGS_MODAL: {
      return {
        ...state,
        isPaymentSettingsModalOpen: {
          ...state.isPaymentSettingsModalOpen,
          isOpen: false,
          tab: 1,
        },
      }
    }

    case ReferralActions.UPDATE_CURRENT_PROSPECT: {
      const prospectsData = (state.prospects && state.prospects.data) || []
      const { fullProspects } = state.prospects
      let { currentProspect } = state.prospects
      if (prospectsData.length > 0) {
        currentProspect = prospectsData.shift()
      }

      return {
        ...state,
        prospects: {
          ...state.prospects,
          currentProspect,
          fullProspects: state.isFirstLeadUpdate ? fullProspects : currentProspect,
          data: prospectsData,
        },
      }
    }

    case ReferralActions.clearCurrentProspectsAction.type: {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          currentProspect: null,
        },
      }
    }

    case ReferralActions.lastAcceptedReferralAction.type: {
      const { clientId, referralId } = action.data
      return {
        ...state,
        lastAcceptedReferral: {
          ...state.lastAcceptedReferral,
          clientId,
          referralId,
        },
      }
    }

    case ReferralActions.UPDATE_PROSPECT_MODAL: {
      const modalState = action.data
      return {
        ...state,
        prospects: {
          ...state.prospects,
          modalState,
        },
      }
    }

    case ReferralActions.handleUserConfirmationAction.type: {
      return {
        ...state,
        isModalForMarketplaceOpen: false,
      }
    }

    case ReferralActions.UPDATE_SUBSCRIPTION_STATUS: {
      const { modalState } = action.data
      return {
        ...state,
        isSubscribing: {
          ...state.isSubscribing,
          modalState,
        },
      }
    }

    case ReferralActions.LEAD_EXPIRED: {
      return {
        ...state,
        leadError: action.data,
        showExpiredModal: true,
      }
    }

    case ReferralActions.CLOSE_LEAD_EXPIRED: {
      return {
        ...state,
        showExpiredModal: false,
      }
    }

    case ReferralActions.TOGGLE_LEAD_MODAL: {
      const { showProspectsModal, prospects } = state
      const { currentProspect } = prospects
      return {
        ...state,
        showProspectsModal: (!!action.data) || !showProspectsModal,
        forceShowProspectsModal: false,
        prospects: {
          ...prospects,
          currentProspect: action.data || currentProspect,
        },
      }
    }

    case ReferralActions.SHOW_LEAD_MODAL_BY_ID: {
      const { prospects } = state
      return {
        ...state,
        prospects: {
          ...prospects,
          currentProspect: action.data,
        },
        showProspectsModal: true,
        forceShowProspectsModal: false,
      }
    }

    case ReferralActions.closeLMPModalAction.type: {
      return {
        ...state,
        isModalForMarketplaceOpen: false,
      }
    }

    case ReferralActions.forceCloseProspectModalAction.type: {
      return {
        ...state,
        prospects: {
          data: [],
          currentProspect: null,
          modalState: false,
          errorCode: 0,
          errorMessage: "Please try again later",
        },
        showProspectsModal: false,
        forceShowProspectsModal: true,
      }
    }

    case ReferralActions.addPlanToCart.type: {
      const {
        product,
        plan,
        cartHasTeamPlan,
        monthlyPlanIfTeam,
      } = action.data
      return {
        ...state,
        isCartHavingTeamPlan: cartHasTeamPlan,
        monthlyPlanIfTeam,
        isaPricing: {
          ...state.isaPricing,
          cart: {
            ...state.isaPricing.cart,
            [product]: plan,
          },
        },
      }
    }

    case ReferralActions.removePlanFromCart.type: {
      const { product } = action.data
      return {
        ...state,
        isCartHavingTeamPlan: false,
        isaPricing: {
          ...state.isaPricing,
          cart: {
            ...state.isaPricing.cart,
            [product]: null,
          },
        },
      }
    }

    case ReferralActions.fetchSingleProspectAction.REQUEST:
    case ReferralActions.fetchSingleProspectAction.SUCCESS:
    case ReferralActions.fetchSingleProspectAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.fetchSingleProspectAction, "singleProspect"),
      }
    }

    case ReferralActions.getTeamMembersAction.REQUEST:
    case ReferralActions.getTeamMembersAction.SUCCESS:
    case ReferralActions.getTeamMembersAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.getTeamMembersAction, "teamMembers"),
      }
    }

    case ReferralActions.assignAgentAction.REQUEST:
    case ReferralActions.assignAgentAction.SUCCESS:
    case ReferralActions.assignAgentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.assignAgentAction, "assigningAgent"),
      }
    }

    case ReferralActions.changeTeamRoleAction.REQUEST:
    case ReferralActions.changeTeamRoleAction.SUCCESS:
    case ReferralActions.changeTeamRoleAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.changeTeamRoleAction, "changeTeamRoleResponse"),
      }
    }

    case ReferralActions.exportToExcelAction.REQUEST:
    case ReferralActions.exportToExcelAction.SUCCESS:
    case ReferralActions.exportToExcelAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.exportToExcelAction, "exportToExcelResponse"),
      }
    }

    case ReferralActions.getTeamInfoAction.REQUEST:
    case ReferralActions.getTeamInfoAction.SUCCESS:
    case ReferralActions.getTeamInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.getTeamInfoAction, "teamInfoResponse"),
      }
    }

    case ReferralActions.fetchPricingPlansAction.REQUEST:
    case ReferralActions.fetchPricingPlansAction.SUCCESS:
    case ReferralActions.fetchPricingPlansAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.fetchPricingPlansAction, "pricingPlans"),
      }
    }

    case ReferralActions.updateTeamImageAction.REQUEST:
    case ReferralActions.updateTeamImageAction.SUCCESS:
    case ReferralActions.updateTeamImageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.updateTeamImageAction, "uploadTeamImageResponse"),
      }
    }

    case ReferralActions.fetchAcceptLeadDropdownValuesAction.REQUEST:
    case ReferralActions.fetchAcceptLeadDropdownValuesAction.SUCCESS:
    case ReferralActions.fetchAcceptLeadDropdownValuesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchAcceptLeadDropdownValuesAction,
          "acceptLeadDropDownValues"
        ),
      }
    }

    case ReferralActions.fetchTeamMembersAction.REQUEST:
    case ReferralActions.fetchTeamMembersAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchTeamMembersAction,
          "teamMembersList"
        ),
      }
    }

    case ReferralActions.fetchTeamMembersAction.SUCCESS: {
      const { data: { total_seats } } = action
      return {
        ...state,
        currentTeamSize: total_seats,
        teamSize: total_seats,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchTeamMembersAction,
          "teamMembersList"
        ),
      }
    }

    case ReferralActions.updateTeamSizeAction.type: {
      return {
        ...state,
        teamSize: action.data,
      }
    }

    case ReferralActions.setPermissionValueAction.SUCCESS:
    case ReferralActions.setPermissionValueAction.FAILURE:
    case ReferralActions.setPermissionValueAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.setPermissionValueAction,
          "settingPermissionValue"
        ),
      }
    }

    case ReferralActions.inviteBrokerAction.SUCCESS:
    case ReferralActions.inviteBrokerAction.FAILURE:
    case ReferralActions.inviteBrokerAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.inviteBrokerAction,
          "sendBrokerInviteResponse"
        ),
      }
    }

    case ReferralActions.getBorkerEarningsAction.SUCCESS:
    case ReferralActions.getBorkerEarningsAction.FAILURE:
    case ReferralActions.getBorkerEarningsAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.getBorkerEarningsAction,
          "brokerEarningResponse"
        ),
      }
    }

    case ReferralActions.getBrokerInvitesAction.SUCCESS:
    case ReferralActions.getBrokerInvitesAction.FAILURE:
    case ReferralActions.getBrokerInvitesAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.getBrokerInvitesAction,
          "brokerInvitesResponse"
        ),
      }
    }

    case ReferralActions.fetchTeamFilterValuesAction.SUCCESS:
    case ReferralActions.fetchTeamFilterValuesAction.FAILURE:
    case ReferralActions.fetchTeamFilterValuesAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchTeamFilterValuesAction,
          "teamFilterValues"
        ),
      }
    }

    case ReferralActions.fetchAddASeatAmountAction.SUCCESS:
    case ReferralActions.fetchAddASeatAmountAction.FAILURE:
    case ReferralActions.fetchAddASeatAmountAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchAddASeatAmountAction,
          "seatAmount"
        ),
      }
    }

    case ReferralActions.fetchSubmittedMetricsAction.SUCCESS:
    case ReferralActions.fetchSubmittedMetricsAction.FAILURE:
    case ReferralActions.fetchSubmittedMetricsAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchSubmittedMetricsAction,
          "submittedMetrics"
        ),
      }
    }

    case ReferralActions.fetchSourcesAction.SUCCESS:
    case ReferralActions.fetchSourcesAction.FAILURE:
    case ReferralActions.fetchSourcesAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.fetchSourcesAction,
          "agentSources"
        ),
      }
    }

    case ReferralActions.removeMemberAction.FAILURE: {
      return {
        ...state,
        isRemoveTeamMemberModalOpen: true,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.removeMemberAction,
          "removingMember"
        ),
      }
    }

    case ReferralActions.removeMemberAction.REQUEST:
    case ReferralActions.removeMemberAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          ReferralActions.removeMemberAction,
          "removingMember"
        ),
      }
    }

    case ReferralActions.setCurrentTeamFilterAction.type: {
      const { data } = action
      const { value, label } = data
      return {
        ...state,
        currentTeamFilterValue: [{ display_name: label, value }],
      }
    }

    case ReferralActions.setCurrentSourcesFilterAction.type: {
      const { data } = action
      const { sourceString } = data
      return {
        ...state,
        currentSourcesFilter: sourceString,
      }
    }

    case ReferralActions.configureAcceptPermissionAction.type: {
      const { data } = action
      const { data: permission } = data
      return {
        ...state,
        currentAcceptValue: permission.data,
      }
    }

    case ReferralActions.configureAssignPermissionAction.type: {
      const { data } = action
      const { data: permission } = data
      return {
        ...state,
        currentAssignValue: permission.data,
      }
    }

    case ReferralActions.referOutAction.REQUEST:
    case ReferralActions.referOutAction.SUCCESS:
    case ReferralActions.referOutAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.referOutAction, "referOut"),
      }
    }

    case ReferralActions.inviteTeamMemberAction.REQUEST:
    case ReferralActions.inviteTeamMemberAction.SUCCESS:
    case ReferralActions.inviteTeamMemberAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.inviteTeamMemberAction, "inviteAgent"),
      }
    }

    case ReferralActions.handleInviteResponseAction.REQUEST:
    case ReferralActions.handleInviteResponseAction.SUCCESS:
    case ReferralActions.handleInviteResponseAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.handleInviteResponseAction, "teamAcceptResponse"),
      }
    }

    case ReferralActions.fetchPlanDurationAction.REQUEST:
    case ReferralActions.fetchPlanDurationAction.SUCCESS:
    case ReferralActions.fetchPlanDurationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.fetchPlanDurationAction, "plansDurationResponse"),
      }
    }

    case ReferralActions.acceptOrRejectLeadAction.REQUEST:
    case ReferralActions.acceptOrRejectLeadAction.SUCCESS:
    case ReferralActions.acceptOrRejectLeadAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.acceptOrRejectLeadAction, "acceptOrRejectLeadResponse"),
      }
    }

    case ReferralActions.applyPricingCoupon.REQUEST: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          coupon: {
            ...state.isaPricing.coupon,
            isApplying: true,
          },
        },
      }
    }

    case ReferralActions.applyPricingCoupon.SUCCESS: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          coupon: {
            ...state.isaPricing.coupon,
            isApplying: false,
            ...action.data,
          },
        },
      }
    }

    case ReferralActions.applyPricingCoupon.FAILURE: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          coupon: {
            ...state.isaPricing.coupon,
            isApplying: false,
          },
        },
      }
    }

    case ReferralActions.checkoutPricingCart.REQUEST: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          paymentModalState: paymentConfirmationStates.loading,
        },
      }
    }

    case ReferralActions.checkoutPricingCart.SUCCESS: {
      const { cart, paymentModalState } = action.data
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          cart,
          paymentModalState,
        },
      }
    }

    case ReferralActions.checkoutPricingCart.FAILURE: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          paymentModalState: paymentConfirmationStates.failure,
        },
      }
    }

    case ReferralActions.updatePaymentPricingModal.type: {
      const { paymentModalState } = action.data
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          paymentModalState,
        },
      }
    }

    case ReferralActions.clearPricingCart.type: {
      return {
        ...state,
        isCartHavingTeamPlan: false,
        isaPricing: {
          ...state.isaPricing,
          cart: {
            rtl: null,
            blu: null,
          },
        },
      }
    }

    case ReferralActions.clearCouponData.type: {
      return {
        ...state,
        isaPricing: {
          ...state.isaPricing,
          coupon: {
            isApplying: false,
            couponCode: "",
            isValid: false,
            planId: "",
            trialDays: null,
          },
        },
      }
    }

    case ReferralActions.checkPartnerSubscriptionInterestsAction.SUCCESS: {
      const { isInterested } = action.data
      return {
        ...state,
        isInterested,
      }
    }

    case ReferralActions.submitInterestFormAction.SUCCESS: {
      return {
        ...state,
        isSubmittingForm: false,
        interestFormSubmitted: true,
        isUserConfirmModalOpen: true,
      }
    }

    case ReferralActions.submitInterestFormAction.REQUEST: {
      return {
        ...state,
        isSubmittingForm: true,
        interestFormSubmitted: false,
      }
    }

    case ReferralActions.submitInterestFormAction.FAILURE: {
      return {
        ...state,
        isSubmittingForm: false,
        interestFormSubmitted: false,
      }
    }

    case ReferralActions.getISAFormDropdownValuesAction.SUCCESS: {
      const { data } = action.data
      const { response } = data
      return {
        ...state,
        isFormLoaded: true,
        isaDropdownValuesObject: response,
      }
    }

    case ReferralActions.toggleInterestConfirmationModalAction.type: {
      return {
        ...state,
        isUserConfirmModalOpen: false,
        confirmationModalMessage: null,
      }
    }

    case ClientActions.userConfirmationModalAction.type: {
      const { question, isShowModal } = action.data
      return {
        ...state,
        isUserConfirmModalOpen: isShowModal,
        confirmationModalMessage: question,
      }
    }

    case ClientActions.userConfirmAction.type: {
      return {
        ...state,
        confirmationModalMessage: null,
      }
    }

    case ClientActions.CLOSE_ISA_ADD_CLIENT_MODAL: {
      return {
        ...state,
        isReferringLead: {
          ...state.isReferringLead,
          request: false,
          error: false,
        },
      }
    }

    case ReferralActions.changePlanTypeAction.type: {
      const { type } = action.data
      return {
        ...state,
        currentPlanType: type,
      }
    }

    case ReferralActions.modifyMemberQuantityAction.type: {
      const {
        quantity,
        subTotal,
        monthlyPlanIfTeam,
        type,
      } = action.data
      const subTotalCalc = type === "add" ? subTotal + monthlyPlanIfTeam : subTotal - monthlyPlanIfTeam
      return {
        ...state,
        teamSize: quantity,
        subTotal: subTotalCalc,
      }
    }

    case ReferralActions.setAmountForCheckoutAction.type: {
      const { subTotal, checkoutTotal } = action.data
      return {
        ...state,
        checkoutTotal,
        subTotal,
      }
    }

    case ReferralActions.createPricingMapAction.type: {
      return {
        ...state,
        pricingPlansMap: action.data,
      }
    }

    case ReferralActions.toggleAssignModalAction.type: {
      const {
        isOpenAssignModal,
        agentID,
        referralId,
        currentClientID,
      } = action.data
      return {
        ...state,
        assignAgentKeys: {
          isOpenAssignModal,
          currentAgentID: agentID,
          currentReferralID: referralId,
          currentClientID,
        },
      }
    }

    case ReferralActions.toggleTeamInviteModalAction.type: {
      return {
        ...state,
        inviteModal: action.data,
        inviteAgent: {
          error: null,
        },
      }
    }

    case ReferralActions.toggleReferOutAction.type: {
      return {
        ...state,
        isReferOutModalOpen: false,
        confirmationModalMessage: null,
      }
    }

    case ReferralActions.referOutModalAction.type: {
      const {
        question,
        isShowModal,
      } = action.data
      return {
        ...state,
        isReferOutModalOpen: isShowModal,
        confirmationModalMessage: question,
      }
    }

    case ReferralActions.removeTeamMemberModal.type: {
      const {
        question,
        isShowModal,
        isCancelInvite,
        currentlyRemovingID,
      } = action.data
      return {
        ...state,
        isRemoveTeamMemberModalOpen: isShowModal,
        confirmationModalMessage: question,
        isCancelInvite,
        currentlyRemovingID,
      }
    }

    case ReferralActions.confirmRemoveMemberAction.type: {
      return {
        ...state,
        isRemoveTeamMemberModalOpen: action.data,
        removingMember: {
          isFetching: action.data,
          isError: false,
        },
      }
    }

    case ReferralActions.showRemoveMemberSuccessModalAction.type: {
      return {
        ...state,
        isRemoveTeamMemberModalOpen: false,
        isRemoveMemberSuccessModalOpen: true,
        confirmationModalMessage: null,
      }
    }

    case ReferralActions.hideRemoveMemberSuccessModalAction.type: {
      return {
        ...state,
        isRemoveMemberSuccessModalOpen: false,
      }
    }

    case ReferralActions.referConfirmedAction.type: {
      return {
        ...state,
        referOut: {
          isRefferError: false,
        },
        isReferOutModalOpen: false,
        confirmationModalMessage: null,
      }
    }

    case ReferralActions.setCurrentReInviteMemberIDAction.type: {
      return {
        ...state,
        currentlyReInvitingMemberID: action.data,
      }
    }

    case ReferralActions.toggleAddTeamLogoAction.type: {
      const { isShowTeamLogoModal = false } = action.data
      return {
        ...state,
        isShowTeamLogoModal,
      }
    }

    case ReferralActions.toggleChangeRoleLoaderAction.type: {
      return {
        ...state,
        isShowTeamRoleChangeLoader: action.data,
      }
    }

    case ReferralActions.toggleChangeRoleModalAction.type: {
      return {
        ...state,
        isShowChangeRoleModal: action.data,
      }
    }

    case ReferralActions.toggleSalesStageChangeFormAction.type: {
      const {
        type,
        lead,
      } = action.data
      const { saleStageChangeProps } = state
      return {
        ...state,
        saleStageChangeProps: {
          ...saleStageChangeProps,
          type,
          lead,
        },
      }
    }

    case ReferralActions.updateSalesStageFormAction.REQUEST:
    case ReferralActions.updateSalesStageFormAction.SUCCESS:
    case ReferralActions.updateSalesStageFormAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.updateSalesStageFormAction, "saleUpdateResponse"),
      }
    }

    case ReferralActions.getClosingStageInfoAction.REQUEST:
    case ReferralActions.getClosingStageInfoAction.SUCCESS:
    case ReferralActions.getClosingStageInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, ReferralActions.getClosingStageInfoAction, "closingStatgeInfoResponse"),
      }
    }

    case ReferralActions.setCurrentAgentNameAction.type: {
      return {
        ...state,
        currentAgentName: action.data,
      }
    }

    case ReferralActions.setCurrentLocationAction.type: {
      const {
        currentState,
        currentCountry,
        currentCity,
        addressTextBoxValue,
      } = action.data
      return {
        ...state,
        currentState,
        currentCountry,
        currentCity,
        addressTextBoxValue,
      }
    }

    case ReferralActions.setCurrentExpertiseFilterAction.type: {
      return {
        ...state,
        currentExpertise: action.data,
      }
    }

    case ReferralActions.setCurrentLanguageFilterAction.type: {
      return {
        ...state,
        currentLanguages: action.data,
      }
    }

    case ReferralActions.setCurrentCompaniesFilterAction.type: {
      return {
        ...state,
        currentCompanies: action.data,
      }
    }

    case ReferralActions.setCurrentWCRFilterAction.type: {
      return {
        ...state,
        currentWcrFilterActive: action.data,
      }
    }

    case ReferralActions.setCurrentWCRPMNFilterAction.type: {
      return {
        ...state,
        currentWCRPMNActive: action.data,
      }
    }

    case ReferralActions.setCurrentRPACFilterAction.type: {
      return {
        ...state,
        currentRPACActive: action.data,
      }
    }

    case ReferralActions.setCurrentLimitForLeaderboardAction.type: {
      return {
        ...state,
        currentLimitForLeaderboard: action.data,
      }
    }

    default:
      return state
  }
}
