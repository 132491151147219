import React, { useState } from "react"
import styled from "styled-components"
import {
  Input,
} from "@ui/antd"

const { TextArea } = Input

const Wrap = styled.div``

const ConfirmationModal = ({
  updateNote,
}) => {
  const [note, setNote] = useState("")
  return (
    <Wrap>
      <TextArea
        style={{
          height: 100,
        }}
        value={note}
        onChange={(e) => {
          setNote(e.target.value)
          updateNote(e.target.value)
        }}
      />
    </Wrap>
  )
}

export default ConfirmationModal
