import React from "react"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import Modal from "@ui/Modal"
import Button from "@ui/Button"
import colors from "@colors"

const Wrap = styled.div`
  p {
    margin-bottom: 20px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`

const ConfirmSignInModal = ({
  show,
  toClose,
  email,
  redirectUrl,
}) => {
  const handleRedirection = () => {
    root.location.href = `/signup?email=${email}`
    CookiesStorage.save("redirectUrl", redirectUrl)
  }
  return (
    <Modal
      toClose={toClose}
      show={show}
      width="400px"
      modalVersion={3}
      modalStyles={{
        background: `${colors.white}`,
      }}
    >
      <Wrap>
        <p>
          Email exists. Do you want to Login?
        </p>
        <ButtonWrap>
          <Button
            onClick={handleRedirection}
            bsStyle="marineBLueOutlined"
          >
            Yes
          </Button>
          <Button
            onClick={toClose}
            bsStyle="marineBLueOutlined"
          >
            No
          </Button>
        </ButtonWrap>
      </Wrap>
    </Modal>
  )
}

export default ConfirmSignInModal
