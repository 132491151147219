import React from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"

const LenderForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  deleteLender,
  isAddNewLender,
}) => {
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
    }

    return initValues
  }

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Lender Information"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            handleEditModeChange()
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V2/minus.svg`} alt="minus" />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_lender_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        // ref={this.formRef}
      >
        <InputField
          name="lender_officer_name"
          inputType="text"
          placeholder="Lender Officer Name*"
        />

        <InputField
          name="lender_co"
          inputType="text"
          placeholder="Lending Co"
        />

        <InputField
          name="lender_email"
          inputType="email"
          type="email"
          placeholder="Email*"
        />

        <InputNumber
          name="lender_phone"
          inputType="phone"
          type="phone"
          placeholder="Phone*"
        />

        {/**Requires Google Address*/}
        <InputField
          name="lender_office_address"
          inputType="text"
          type="text"
          placeholder="Office Address*"
        />

        <CTAWrap>
          {!isAddNewLender && (
            <Form.Item>
              <DangerButton
                type="primary"
                shape="round"
                size="large"
                // loading={creatingOffer || fetchingDeal || updatingDeal}
                htmlType="button"
                onClick={() => deleteLender(prefilledData.lender_id)}
              >
                <p>Delete</p>
              </DangerButton>
            </Form.Item>
          )}

          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default LenderForm
