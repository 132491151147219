import React from "react"
import { getStateShortName } from "../../../../../ListingsV2/Components/ListingTabs/consts"
import {
  InformationWrap,
  AddressNameWrap,
  SellerNameWrap,
} from "./styles"

const AddressInformationCard = ({ data, currentTransactionTab }) => {
  const {
    street,
    state,
    zipcode,
    clients,
    listing_address
  } = data

  const streetAddress = street || "Incomplete Address"
  const stateShort = getStateShortName(state)

  const listingStreetAddress = listing_address || "Incomplete Address"

  const sellerClients = clients && clients.filter(item => item.client_type === "seller")
  const sellerNames = sellerClients && sellerClients.map(item => item.name).join(", ")
  return (
    <InformationWrap>
      <AddressNameWrap>
        <div>
          {currentTransactionTab === "buyer" ? streetAddress : listingStreetAddress}
        </div>
        <div>
          {stateShort || ""} {" "} { zipcode || ""}
        </div>
      </AddressNameWrap>
      {currentTransactionTab === "buyer" && 
      (
        <SellerNameWrap>
          Seller: {sellerNames || "N/A"}
        </SellerNameWrap>
      )}
    </InformationWrap>
  )
}

export default AddressInformationCard
