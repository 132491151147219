import React from "react"
import styled from "styled-components"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Heading from "dumbComponents/common/Typography/Heading"
import ExperienceIcon from "images/profile/graduate-cap.svg"
import AccomplishmentIcon from "images/profile/cup-icon.svg"
import DesignationIcon from "images/profile/generic-secured.svg"
import LanguageIcon from "images/profile/languages-icon.svg"
import InterestIcon from "images/profile/transportation-cycle.svg"
import { checkForUserObject } from "services/Utils"
import FollowStat from "./FollowStat"
import Panel from "./Panel"
import EmptyState from "./EmptyState"

const SideBar = styled.div.attrs({
  id: "SideBar",
})`
  width: 220px;
  margin-right: 20px;

  span.award-hack {
    display: inline-block;
    background: #e9f7fd;
    padding: 5px 10px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 5px;

    p {
      margin: 0px;
      text-align: left;
      text-transform: capitalize;
    }
  }
`

const Tag = styled.span`
  display: inline-block;
  background-color: #e9f7fd;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
`

const SomeHack = props => (
  <div id="sidebar-wrapper">
    <div>
      <EmptyState {...props} />
    </div>
  </div>
)

class ProfileSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const secureId = checkForUserObject("secureId")
    if (secureId) {
      this.setState({
        isLogged: true,
      })
    }
  }

  render() {
    const { isLogged } = this.state
    const {
      isUserCanEdit,
      followers,
      following,
      unfollowAgent,
      handleFollowClick,
      openEditProfileModal,
      agent = {},
      isShowFollowLoader,
      ownFollowersAndFollowing,
      unblockUser,
      isUnblockingAgent,
    } = this.props
    const {
      experiences = [],
      education = [],
      awards = [],
      designations = [],
      languages = [],
      interests = [],
      id: agentId,
      nar_investor_level,
      nar_investor_level_ly,
      crsLeadershipPositions = [],
      certifications = [],
      isCrsDesignee,
    } = agent
    const RPAC = (nar_investor_level || nar_investor_level_ly) || ""
    return (
      <SideBar>
        <FollowStat
          agentId={agentId}
          agent={agent}
          isOwnProfile={isUserCanEdit}
          following={following}
          followers={followers}
          handleClick={handleFollowClick}
          unfollowAgent={unfollowAgent}
          isShowFollowLoader={isShowFollowLoader}
          ownFollowersAndFollowing={ownFollowersAndFollowing}
          isLogged={isLogged}
          unblockUser={unblockUser}
          isUnblockingAgent={isUnblockingAgent}
        />
        <Panel
          title="Experience"
          isShowEdit={isUserCanEdit}
          type="experiences"
          handleEditClick={openEditProfileModal}
          initialValues={{
            experiences,
          }}
        >
          {experiences && experiences.length > 0 && experiences.map(item => (
            <Paragraph key={item.title} size="14">
              <p>{item.company}</p>
              {item.title}
              <br />
              {`${item.from_year > 0 ? item.from_year : ""} ${item.to_year > 0 ? ` - ${item.to_year}` : ""}`}
            </Paragraph>
          ))}

          {(!experiences || experiences.length === 0)
            && isUserCanEdit && (
            <SomeHack
              id="experience"
              key="experience"
              type="experiences"
              src={ExperienceIcon}
              title="Add your experience"
              handleEditClick={openEditProfileModal}
              initialValues={{ experiences }}
              style={{ background: "none" }}
            />
          )}
        </Panel>
        <Panel
          title="Education"
          isShowEdit={isUserCanEdit && !isCrsDesignee}
          type="education"
          handleEditClick={openEditProfileModal}
          initialValues={{ education }}
        >
          {education && education.length > 0 && education.map((item, index) => (
            <Paragraph key={`${item.course}-${index}`} size="14" mt="20">
              {item.course}
              <span style={{ display: "block" }}>
                {`${item.from_year > 0 ? item.from_year : "" } ${item.year > 0 ? ` - ${item.year}` : ""}`}
              </span>
              {item.school && `(${item.school})`}
            </Paragraph>
          ))}
          {(!education || education.length === 0)
            && isUserCanEdit && (
            <EmptyState
              type="education"
              id="education"
              key="education"
              src={ExperienceIcon}
              handleEditClick={openEditProfileModal}
              title="Add your Education"
              initialValues={{ education }}
            />
          )}
        </Panel>
        <Panel
          title="Accomplishments"
          isShowEdit={isUserCanEdit && !isCrsDesignee}
          type="accomplishments"
          handleEditClick={openEditProfileModal}
          initialValues={awards}
        >
          {awards && awards.length > 0
            ? awards.map(item => (
              <span key={item.award} className="award-hack">
                <p>{`${item.award} ${item.year !== 0 ? ` - ${item.year}` : ""}`}</p>
              </span>
            ))
            : (
              <EmptyState
                type="accomplishments"
                handleEditClick={openEditProfileModal}
                initialValues={awards}
                src={AccomplishmentIcon}
                title="Add your Accomplishments"
              />
            )
          }
        </Panel>

        { isCrsDesignee && crsLeadershipPositions && crsLeadershipPositions.length > 0 && (
          <Panel
            title="CRS Leadership Position"
            isShowEdit={false}
            type="crsLeadership"
            handleEditClick={openEditProfileModal}
            initialValues={{ dummy: true }}
          >
            {crsLeadershipPositions.map(item => (
              <Tag key={item.position}>
                {`${item.position || ""}`}
                <br />
                {`${item.year > 0 ? ` - ${item.year}` : ""}`}
              </Tag>
            ))}
          </Panel>
        )}

        { certifications && certifications.length > 0 && (
          <Panel
            title="Certifications"
            isShowEdit={false}
            type="certifications"
            handleEditClick={openEditProfileModal}
            initialValues={{ dummy: true }}
          >
            {certifications.map(item => (
              <Tag key={item}>
                {`${item || ""}`}
              </Tag>
            ))}
          </Panel>
        )}
        <Panel
          title="Designations"
          isShowEdit={isUserCanEdit && !isCrsDesignee}
          type="designations"
          handleEditClick={openEditProfileModal}
          initialValues={designations}
        >
          {designations && designations.length > 0
            ? designations.map(item => <Tag key={item}>{item}</Tag>)
            : (
              <EmptyState
                src={DesignationIcon}
                title="Add your Designations"
                initialValues={designations}
                type="designations"
                handleEditClick={openEditProfileModal}
              />
            )
          }
        </Panel>
        { RPAC && (
          <Panel
            title="RPAC Major Investor"
            isShowEdit={false}
            type="rpac"
            handleEditClick={openEditProfileModal}
            initialValues={{ dummy: true }}
          >
            <Tag>{RPAC.replace(/"/g, "")}</Tag>
          </Panel>
        )}
        <Panel
          title="Languages"
          isShowEdit={isUserCanEdit}
          type="languages"
          handleEditClick={openEditProfileModal}
          initialValues={languages}
        >
          {languages && languages.length > 0
            ? languages.map(item => <Tag key={item}>{item}</Tag>)
            : (
              isUserCanEdit
              && (
                <EmptyState
                  type="languages"
                  handleEditClick={openEditProfileModal}
                  initialValues={languages}
                  src={LanguageIcon}
                  title="Add your Languages"
                />
              )
            )
          }
        </Panel>
        <Panel
          title="Interests"
          isShowEdit={isUserCanEdit}
          type="interest"
          handleEditClick={openEditProfileModal}
          initialValues={interests}
        >
          {interests && interests.length > 0
            ? interests.map(item => <Tag key={item}>{item}</Tag>)
            : (
              isUserCanEdit
              && (
                <EmptyState
                  type="interest"
                  handleEditClick={openEditProfileModal}
                  initialValues={interests}
                  src={InterestIcon}
                  title="Add your skills"
                />
              )
            )
          }
        </Panel>
      </SideBar>
    )
  }
}

export default ProfileSidebar

