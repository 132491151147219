import React from "react"
import styled from "styled-components"
import Loader from "@ui/Loader"
import FeedItem from "dumbComponents/Network/MiddleContainer/common/FeedItem"
import FeedDetail from "dumbComponents/Network/MiddleContainer/FeedDetail"
import Likers from "dumbComponents/Network/MiddleContainer/common/Likers"
import ReportFeedModal from "dumbComponents/Network/MiddleContainer/common/ReportFeedModal"
import Container from "container/Network/MiddleContainer"

const Wrap = styled.div`

`

class FeedContainer extends React.PureComponent {
  state = {
    isMounted: false,
  }

  componentDidMount() {
    const { getAgentFeeds, agentId } = this.props
    getAgentFeeds({
      payload: agentId,
    })
  }

  openFeedDetail = (feedData) => {
    const { getCommentsForFeed, openFeedDetailView } = this.props
    const { selectedFeed = {}, feedSource, shouldAutoFocus } = feedData
    getCommentsForFeed(selectedFeed.id)
    openFeedDetailView({
      selectedFeed,
      feedSource,
      shouldAutoFocus,
    })
  }

  showLikers = (payload) => {
    const { getLikers, openLikers } = this.props
    const { likersViewType } = payload
    getLikers(payload)
    openLikers({ likersViewType })
  }

  render() {
    const { isMounted } = this.state
    const {
      feeds,
      feedDetail,
      closeLikers,
      isLikingFeed,
      updateFeedLike,
      updateCommentLike,
      followAgent,
      requestingAgentToFollow,
      unfollowAgent,
      agentsFollowees,
      togglePersonalizeFeedModal,
      openImagePreviewModal,
      closeFeedDetailView,
      openLikers,
      isLikingComment,
      postCommentForFeed,
      isPostingComment,
      updateAutoFocus,
      likers,
      reportOrDelete,
      isUnauthorized,
      blockAndUnBlock,
      toggleReportModal,
      isShowReportModal,
      getBlockedReasons,
      reportReasonsResponse,
      reportFeed,
      mutePost,
      mutedFeeds,
    } = this.props
    const { isFetching, data } = feeds
    const { feed: feedsData } = data || {}
    return (
      <Wrap>
        {isShowReportModal && (
          <ReportFeedModal
            toggleReportModal={toggleReportModal}
            getBlockedReasons={getBlockedReasons}
            reportReasonsResponse={reportReasonsResponse}
            reportFeed={reportFeed}
          />
        )}
        {!isFetching ? (
          <div>
            {feedsData && feedsData.map(feed => (
              <FeedItem
                key={feed.id}
                feed={feed}
                mutePost={mutePost}
                mutedFeeds={mutedFeeds}
                feedsData={feedsData}
                openFeedDetailView={this.openFeedDetail}
                toggleEditFeedItemModal={() => this.toggleEditFeedItemModal(feed)}
                feedDetail={feedDetail}
                openLikers={this.showLikers}
                closeLikers={closeLikers}
                isLikingFeed={isLikingFeed}
                updateFeedLike={updateFeedLike}
                updateCommentLike={updateCommentLike}
                followAgent={followAgent}
                requestingAgentToFollow={requestingAgentToFollow}
                unfollowAgent={unfollowAgent}
                agentsFollowees={agentsFollowees}
                togglePersonalizeFeedModal={togglePersonalizeFeedModal}
                openImagePreviewModal={openImagePreviewModal}
                reportOrDelete={reportOrDelete}
                blockAndUnBlock={blockAndUnBlock}
                toggleReportModal={toggleReportModal}
                isOnProfile
              />
            ))}
            <FeedDetail
              feed={feedDetail.selectedFeed}
              show={false}
              close={closeFeedDetailView}
              selectedFeed={feedDetail}
              openLikers={openLikers}
              closeLikers={closeLikers}
              isLikingFeed={isLikingFeed}
              isLikingComment={isLikingComment}
              updateFeedLike={updateFeedLike}
              updateCommentLike={updateCommentLike}
              postCommentForFeed={postCommentForFeed}
              toggleEditFeedItemModal={() => this.toggleEditFeedItemModal(feedDetail.selectedFeed)}
              isPostingComment={isPostingComment}
              agentsFollowees={agentsFollowees}
              togglePersonalizeFeedModal={togglePersonalizeFeedModal}
              shouldAutoFocus={feedDetail.shouldAutoFocus}
              updateAutoFocus={updateAutoFocus}
            />
            <Likers
              show={feedDetail.isOpenLikers}
              isFetchingLikers={feedDetail.isFetchingLikers}
              likers={likers}
              close={closeLikers}
              followAgent={followAgent}
              requestingAgentToFollow={requestingAgentToFollow}
              unfollowAgent={unfollowAgent}
            />
          </div>
        ) : (
          <React.Fragment>
            {isUnauthorized ? (
              <p>Error</p>
            ) :
             (
              <Loader />
            )}
          </React.Fragment>
        )}
      </Wrap>
    )
  }
}

export default Container(FeedContainer)
