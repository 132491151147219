/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings/index"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const AnimationWrap = styled.div`
  position: relative;
  top: 14px;

  img {
    width: 49px;
    height: 49px;
  }
`

const DeleteInformationFromCoversheet = ({
  toClose,
  handleDelete,
  processDeletionOfFieldsInCoversheetResponse,
}) => {

  const {
    isFetching: deleting,
  } = processDeletionOfFieldsInCoversheetResponse || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Confirm Action?
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              This would reset fields in property information as well as other parts of your coversheet.
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "blue",
            }}
            onClick={toClose}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "white",
            }}
            onClick={handleDelete}
          >
            Delete
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(TechAssets(Listings(DeleteInformationFromCoversheet)))

