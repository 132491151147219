import React from "react"
import styled from "styled-components"
import ArchiveList from "dumbComponents/Referral/ArchivedLeads/ArchiveList"
import Loader from "@ui/Loader"
import Modal from "@ui/Modal"

const Wrap = styled.div`
  
`

class ArchivedLeadsNA extends React.PureComponent {
  state = {}

  componentDidMount() {
    const { getArchivedLeadNonAssist } = this.props
    getArchivedLeadNonAssist()
  }

  render() {
    // const {  } = this.state
    const { archiveModalResponse, toggleArchiveModal, updateArchivedLead } = this.props
    const { isFetching, data } = archiveModalResponse
    const { clients } = data || {}
    return (
      <Modal
        show
        toClose={() => { toggleArchiveModal(false) }}
        title="Archived Leads"
        modalStyles={{
          padding: 20,
          marginTop: 50,
        }}
      >
        <Wrap>
          {isFetching ? (
            <Loader top="0" bottom="0" />
          ) : (
            <React.Fragment>
              {clients && clients.length > 0 ? (
                <ArchiveList
                  archivedReferrals={clients}
                  isFetchingArchivedReferrals={isFetching}
                  updateArchivedLead={updateArchivedLead}
                  isUpdatingArchivedLead={false}
                  type="crm"
                />
              ) : (
                <h5>No Referrals found</h5>
              )}
            </React.Fragment>
          )}
        </Wrap>
      </Modal>
    )
  }
}

export default ArchivedLeadsNA
