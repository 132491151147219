import React, { PureComponent } from "react"
import styled from "styled-components"
import { removeSupressedPopups } from "shared/utils"
import { ATC } from "@fonts"
import TCContainer from "container/Realty/TransactionFormContainer"
import PaymentSVG from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/images/payment.svg"
import ArrowSVG from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/images/Arrow_1.svg"

const Wrap = styled.div`
  background: #ccc7af;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -42px 0 50px -50px;
  padding: 15px 24px;
  width: calc(100vw - 240px);
  cursor: pointer;
`

const Image = styled.img`
  margin-top: 5px;
`

const Header = styled.h1`
  margin: 0 0 0 19px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
`

const Content = styled.p`
  margin: 0 0 0 10px;
  font-style: normal;
  font-weight: 200;
  font-family: ${ATC};
  font-size: 14px;
  line-height: 16px;
  color: #303030;
`

const ImageContainer = styled.div`
  cursor: pointer;
`
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`
const ContentArrowContainer = styled.div``

class PaymentAccountSetupBar extends PureComponent {
  openModal = () => {
    removeSupressedPopups("commissionsPopup")

    const { toggleCommissionsPopup, getOnboardingCommissionResponse } = this.props

    if (
      getOnboardingCommissionResponse &&
      getOnboardingCommissionResponse.data &&
      getOnboardingCommissionResponse.data.response
    ) {
      const { response: responseData } = getOnboardingCommissionResponse.data
      if (!responseData) {
        toggleCommissionsPopup(1)
      }
      if (responseData && responseData.completed_steps && responseData.completed_steps.length) {
        if (responseData.completed_steps.includes("personal_bank_info")) {
          toggleCommissionsPopup(2)
        }
        if (responseData.completed_steps.includes("s_corp_bank_info")) {
          toggleCommissionsPopup(3)
        }
        if (responseData.completed_steps.includes("tc_info")) {
          toggleCommissionsPopup(5)
        }
      }
    } else {
      toggleCommissionsPopup(1)
    }
  }

  render() {
    return (
      <Wrap onClick={this.openModal}>
        <ContentContainer>
          <ImageContainer>
            <Image src={PaymentSVG} />
          </ImageContainer>
          <Header>Set up your payment account</Header>
          <Content>Set up your Radius profile and start your orientation.</Content>
        </ContentContainer>
        <ContentArrowContainer>
          <ImageContainer onClick={this.openModal}>
            <Image src={ArrowSVG} />
          </ImageContainer>
        </ContentArrowContainer>
      </Wrap>
    )
  }
}

export default TCContainer(PaymentAccountSetupBar)
