import React from "react"
import styled from "styled-components"
import app from "container/App"
import RoomsContainer from "container/Rooms"
import { handleDataLayerPush } from "services/dataLayerUtil"
import LazyLoad from "react-lazyload"
import RightArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/RightArrowRooms.svg"
import { ATC, HIGHLIGHT_FONT } from "@fonts"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import { minTabletWidth, preTabletWidth } from "shared/consts"

const MainWrap = styled.div`
  margin-top: 60px;
`

const Wrap = styled.div`;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
`

const InnerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  padding: 0px 0px 20px;

  @media(max-width: 767px) {
    padding: 0px;
  }
`

const HeaderWrap = styled.div`
  margin-bottom: 50px;
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: ${colors.black};

    @media(max-width: 767px) {
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.005em;
      text-align: left;
    }

    @media(max-width: 1099px) {
      font-size: 24px;
    }
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.black};

    @media(max-width: 767px) {
      font-family: ${ATC};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`

const CategoryImage = styled.div`
  background-image: url(${props => props.src});
  border-radius: 6px;
  height: 230px;
  width: 100%;
  background-size: cover;
`

const CardWrapMobile = styled.div`
  cursor: pointer;
  flex-grow: 1;
  ${props => props.isEven && `
  `}

  ${props => !props.isEven && `
  `}

  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    color: ${colors.suvaGray};
  }
`

const H1Wrap = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 10px;
  h1 {
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: ${colors.black};

    @media(max-width: 1099px){
      font-size: 18px;
    }
  }
`

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media(max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const CardData = styled.div`
  width: 49%;

  ${props => props.isEven && `
    &:last-child{
      width: 100%;
    }
  `}

  @media(max-width: 767px) {
    width: 100%;
  }

  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 23px;
    color: ${colors.suvaGray};
  }
`

const gridCreator = (arr) => {
  const modified = []
  let ignoreIndex = 0

  for(let index = 0; index < arr.length; index +=1) {
    if(index % 2 === 0 && arr[ignoreIndex] !== undefined) {
      let internalArray = []
      for(let index = 0; index < 2; index += 1) {
        if(arr[ignoreIndex] !== undefined) {
          internalArray.push(arr[ignoreIndex])
        }
        ignoreIndex += 1
      }
      modified.push(internalArray)
    } else if(index % 2 !== 0 && arr[ignoreIndex] !== undefined) {
      let internalArray = []
      for(let index = 0; index < 3; index += 1) {
        if(arr[ignoreIndex] !== undefined) {
          internalArray.push(arr[ignoreIndex])
        }
        ignoreIndex += 1
      }
      modified.push(internalArray)
    }
  }

  return modified
}

const BackgroundImage = styled.div`

`

const CardSection = ({
  getRoomTopicsResponse,
  size,
  user: userData,
}) => {
  console.log(getRoomTopicsResponse, "getRoomTopicsResponse")
  const { data: RoomTopicData, isFetching } = getRoomTopicsResponse || {}

  if (size.width < 1024) {
    return (
      <MainWrap className="new-container">
        <>
          <HeaderWrap>
            <h1>
              Topics that Inspire
            </h1>
            <p>
              From wealth building strategies to technology use, Radius Rooms has an event to help you grow professionally.
            </p>
          </HeaderWrap>
          <CardWrap>
            {RoomTopicData && RoomTopicData.map(RoomTopic => (
              <CardData
                onClick={() => {
                  handleDataLayerPush(userData, "topics_that_inspired_clicked", RoomTopic.topic_name)
                  let element = document.getElementById(`${RoomTopic.id}_category_id`);
                    element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
                }}
                isEven={RoomTopic.length % 2 !== 0}
              >
                <LazyLoad height={30}>
                  <CategoryImage src={RoomTopic.image_url} alt="DOWNLOAD THE APP" />
                </LazyLoad>
                <H1Wrap>
                  <h1>
                    {RoomTopic.topic_name}
                  </h1>
                  <img src={RightArrow} alt="RIGHT ARROW"/>
                </H1Wrap>
                <p>
                  {RoomTopic.topic_description}
                </p>
              </CardData>
            ))}
          </CardWrap>
        </>
      </MainWrap>
    )
  }

  let grid = [[1,2],[3,4,5],[6,7]]

  if(!isFetching && RoomTopicData) {
    grid = gridCreator(RoomTopicData)
  }

  return (
    <MainWrap id="event-topics" className="container">
      <>
        <HeaderWrap>
          <h1>
            Topics that Inspire
          </h1>
          <p>
            From wealth building strategies to technology use, Radius Rooms has an event to help you grow professionally.
          </p>
        </HeaderWrap>
        <Wrap>
          {isFetching ? (
            <>
              {grid && grid.map((array, outerIndex) => (
                <InnerWrap>
                  {array && array.map(() => (
                    <CardWrapMobile
                      isEven={outerIndex % 2 === 0}
                      onClick={() => {}}
                    >
                      <ShimmerBox w={outerIndex % 2 === 0 ? "626px" : "406px"} h="213px"/> 
                    </CardWrapMobile>
                  ))}
                </InnerWrap>
              ))}
            </>
          ) : (
            <>
              {grid && grid.map((array, outerIndex) => (
                <InnerWrap>
                  {array && array.map(Categorie => (
                    <CardWrapMobile
                      isEven={outerIndex % 2 === 0}
                      onClick={() => {
                        handleDataLayerPush(userData, "topics_that_inspired_clicked", Categorie.topic_name)
                        const element = document.getElementById(`${Categorie.id}_category_id`)
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" })
                        }
                      }}
                    >
                      <CategoryImage src={Categorie.image_url} alt="DOWNLOAD THE APP" />
                      <H1Wrap>
                        <h1>
                          {Categorie.topic_name}
                        </h1>
                        <img src={RightArrow} alt="RIGHT ARROW" style={{ marginLeft: "10px"}}/>
                      </H1Wrap>
                      <p>
                        {Categorie.topic_description}
                      </p>
                    </CardWrapMobile>
                  ))}
                </InnerWrap>
              ))}
            </>
          )}
        </Wrap>
        </>
    </MainWrap>
  )
}

export default app(RoomsContainer(CardSection))
