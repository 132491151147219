import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  race,
  take,
} from "redux-saga/effects"
import root from "window-or-global"
import { get, merge } from "lodash"
import cookie from "services/CookieStorage"
import createToast from "@ui/Toast"
import {
  openChecklistModal,
  closeChecklistModal,
  closeAddReminderModalAction,
  toggleArchiveModalAction,
  updateReferralNotifyTimeAction,
  fetchReferralsAction,
  closeArchiveLeadModalAction,
  referLeadAction,
  UPDATE_CHECKLIST_IN_REFERRAL,
  SET_CHECKLIST_IN_REFERRAL,
  UPDATE_DRAG_DROP_COLUMNS,
} from "container/Referral/actions"
import { getNonAssistLeadsAction } from "container/NonAssist/actions"
import {
  showConfirmModalAction,
  hideConfirmModalAction,
  toggleLoaderInConfirmAction,
} from "container/App/actions"
import { confirmAction } from "container/App/saga"
import {
  sendBroadcastAction,
  getLeadForMarketplaceAction,
  changeActiveBlockClaim,
} from "container/Marketplace/actions"
import {
  fetchClientTimelineAction as fetchClientProfileTimelineAction,
  markAsQualifiedLeadAction,
  toogleEditInfoModalAction,
} from "container/ClientProfile/actions"
// import { showPopupAction, getPopupDetailsAction } from "container/TechAssets/actions"
// import { getPopupDetailsApi } from "container/TechAssets/api"
import { getEpoch, isSuccess } from "services/Utils"
import {
  fetchClientProfileAPI,
  updateClientProfileAPI,
  bulkClientUpdateAPI,
  uploadImageAPI,
  editClientActionAPI,
  fetchClientUpdatesAPI,
  fetchClientPropertyListingAPI,
  setReminderAPI,
  setNotificationPreferenceAPI,
  fetchClientTimelineAPI,
  addClientAPI,
  convertToCRMClientAPI,
  fetchCallStatusAPI,
  submitCallFeedbackAPI,
  buyerLocationSubmitAPI,
  sellerLocationSubmitAPI,
  markAsQaulifiedAPI,
} from "./api"
import {
  fetchClientProfileAction,
  updateClientProfileAction,
  fetchAndUpdateClientProfileAction,
  fetchAndUpdateSalesInClientAction,
  fetchClientPropertyListingAction,
  setNotificationPreferenceAction,
  fetchClientTimelineAction,
  uploadClientImageAction,
  updateArchivedLeadAction,
  editClientInfoAction,
  closeEditProfileAction,
  fetchClientUpdatesAction,
  setReminderAction,
  waitForClientUpdateAction,
  isChecklistUpdateAction,
  addISAClientAction,
  closeISAAddClientModalAction,
  UPDATE_CURRENT_SALES_STAGE,
  UPDATE_SALES_CHECKLIST,
  ARCHIVE_REFERRAL,
  UPDATE_CHECKLIST_CLIENT,
  convertToCRMClientAction,
  fetchCallStatusAction,
  submitCallFeedbackAction,
  userConfirmationModalAction,
  userConfirmAction,
  buyerLocationSubmitAction,
  sellerLocationSubmitAction,
} from "./actions"

function* fetchClientProfile(action) {
  try {
    const res = yield call(fetchClientProfileAPI, action.data)
    if (isSuccess(res)) {
      yield put(fetchClientProfileAction.success(res))
    }
  } catch (e) {
    yield put(fetchClientProfileAction.failure(e))
  }
}

function* editClientInfo(action) {
  try {
    const { id, client, closeModal } = action.data
    const { client_image } = client
    let imageURL = null
    if (client_image) {
      yield put(uploadClientImageAction.request())
      const fd = new FormData()
      console.log(client_image);
      fd.append("imageFile", client_image)
      const data = {
        filePath: `clients/${id}`,
        config: {
          bucket: "profile",
          convertTo: "jpeg",
        },
      }
      fd.append("requestBody", JSON.stringify(data))
      const imageUploadRes = yield call(uploadImageAPI, fd)
      if (isSuccess(imageUploadRes)) {
        yield put(uploadClientImageAction.success())
        const { response } = imageUploadRes.data
        imageURL = response.imageURL
      } else {
        yield put(uploadClientImageAction.failure(imageUploadRes))
      }
    }
    delete client.types
    delete client.client_image
    const res = yield call(editClientActionAPI, id, {
      ...client,
      image: imageURL,
      type: client.type.value,
    })
    if (isSuccess(res)) {
      yield put(editClientInfoAction.success())
      yield put(fetchClientProfileAction.success(res))
      yield put(closeEditProfileAction())
      if (closeModal) {
        yield put(toogleEditInfoModalAction.call(false))
      }
    }
  } catch (e) {
    yield put(editClientInfoAction.failure(e))
  }
}

function* fetchClientUpdates(action) {
  try {
    const res = yield call(fetchClientUpdatesAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(fetchClientUpdatesAction.success(response))
    }
  } catch (e) {
    yield put(fetchClientUpdatesAction.failure(e))
  }
}

function* updateClientProfile(action) {
  try {
    const res = yield call(updateClientProfileAPI, action.data)
    if (res) {
      yield put(updateClientProfileAction.success(res))
    }
  } catch (e) {
    yield put(updateClientProfileAction.failure(e))
  }
}

function* fetchAndUpdateClientProfile(action) {
  try {
    const res = yield call(fetchClientProfileAPI, action.data.clientId)
    const response = yield call(updateClientProfileAPI, action.data.clientId, action.data.update)
    if (isSuccess(response)) {
      yield put(fetchClientProfileAction.success(res))
    }
  } catch (e) {
    yield put(fetchClientProfileAction.failure(e))
  }
}

function* fetchAndUpdateSalesInClient(action) {
  try {
    const res = yield call(fetchClientProfileAPI, action.data.clientId)
    const updatedObject = {
      sales: merge(res.data.response.sales, action.data.sales),
    }
    const response = yield call(updateClientProfileAPI, action.data.clientId, updatedObject)
    if (isSuccess(response)) {
      yield put(fetchClientProfileAction.success(res))
    }
  } catch (e) {
    yield put(fetchClientProfileAction.failure(e))
  }
}

function* updateCurrentSalesStage(action) {
  try {
    const {
      data: { clientId, sales, referralId },
    } = action
    const blacklistedSalesStageForChecklists = ["Pending", "Search Paused", "Closed"]
    if (action.data.openChecklist && blacklistedSalesStageForChecklists.indexOf(sales["Current stage"]) === -1) {
      yield put(waitForClientUpdateAction(true))
      yield put(openChecklistModal())
    } else if (sales["Current stage"] === "Archived") {
      yield put(toggleArchiveModalAction(referralId))
    }
    const res = yield call(fetchClientProfileAPI, clientId)
    const updatedObject = {
      sales: merge(res.data.response.sales, sales),
    }
    const response = yield call(updateClientProfileAPI, clientId, updatedObject)
    if (isSuccess(response)) {
      yield put(fetchClientProfileAction.success(res))
      yield put(waitForClientUpdateAction(false))
      const clientData = res.data.response
      const { type } = clientData
      const currentStage = clientData.sales["Current stage"]
      const checklist = get(res, `data.response.sales[${type} checklist][${currentStage}]`)
      //const checklist = filterSkeleton[`inbound${type}Checklist`][currentStage]
      const checklistV2 = get(res, `data.response.sales[${type}ChecklistV2]`)
      const stageData = checklistV2 &&
      checklistV2.length > 0 &&
      checklistV2 !== null &&
      checklistV2 !== undefined &&
      checklistV2.filter(check => check.sales_stage === currentStage)[0]
      const {
        sales_stage: currentStageV2,
        checklists: list,
      } = stageData || {}
      yield put({
        type: SET_CHECKLIST_IN_REFERRAL,
        data: {
          checklistData: {
            currentStage: currentStageV2,
            checklist: list,
          },
        },
      })
    }
  } catch (e) {
    console.log("Error in updating current sales stage", e)
  }
}

function* updateChecklistForClient(action) {
  try {
    yield put(waitForClientUpdateAction(true))
    yield put(openChecklistModal())
    let checklist
    const filterSkeleton = yield select(state => state.referral.filterSkeleton)
    console.log(filterSkeleton)
    const res = yield call(fetchClientProfileAPI, action.data.clientId)
    if (isSuccess(res)) {
      const clientData = res.data.response
      const { type } = clientData
      console.log(clientData)
      const currentStage = clientData.sales["Current stage"]
      if (!clientData.sales[`${type}ChecklistV2`]) {
        clientData.sales[`${type}ChecklistV2`] = filterSkeleton[`inbound${type}ChecklistV2`]
      }
      const currentStageData = clientData && clientData.sales &&
      clientData.sales[`${type}ChecklistV2`].filter(key => key.sales_stage === currentStage) || []
      if (currentStage === "Accepted" && currentStageData.length < 0) {
        checklist = clientData.sales[`${type}ChecklistV2`].Leads
      } else {
        checklist = currentStageData && currentStageData[0].checklists
      }
      yield put({
        type: SET_CHECKLIST_IN_REFERRAL,
        data: {
          checklistData: {
            currentStage,
            checklist,
          },
        },
      })

      yield put(fetchClientProfileAction.success(res))
      yield put(waitForClientUpdateAction(false))
    }
  } catch (e) {
    console.log("Error in updating current sales stage", e)
  }
}

function* updateSalesChecklist(action) {
  try {
    const { submittedValues } = action.data
    //get referral id for updating checklist
    const { allReferrals } = yield select(state => state.referral.inboundReferrals)
    const clientData = yield select(state => state.client.clientResponse)
    const filterSkeleton = yield select(state => state.referral.filterSkeleton)
    const clientId = clientData.id
    const referralId = Object.keys(allReferrals).find(referralKey => clientId === allReferrals[referralKey].client_id)
    const { type } = clientData
    const currentStage = clientData.sales["Current stage"]
    const checklist = filterSkeleton[`inbound${type}ChecklistV2`][currentStage]
    const checklistNew = filterSkeleton && filterSkeleton[`inbound${type}ChecklistV2`].filter(item => item.sales_stage === currentStage)[0].checklists
    let checkedItems = 0
    let totalItems = 0
    console.log(submittedValues)
    console.log(checklistNew)
    if (checklistNew && checklistNew.length > 0 && checklistNew !== null || checklistNew !== undefined) {
      checklistNew.forEach((item) => {
        totalItems = +1
        const index = checklistNew.findIndex((obj => obj.name === item.name))
        if (Object.keys(submittedValues).includes(item.name) && submittedValues[item.name]) {
          checklistNew[index].date = getEpoch()
          checkedItems = +1
        } else {
          checklistNew[index].date = 0
        }
      })
    }
    // else {
    //   Object.keys(checklist).forEach((item) => {
    //     totalItems++
    //     if (submittedValues[item]) {
    //       checklist[item].date = getEpoch()
    //       checkedItems++
    //     } else {
    //       checklist[item].date = 0
    //     }
    //   })
    // }

    if (!clientData.sales[`${type}ChecklistV2`]) {
      clientData.sales[`${type}ChecklistV2`] = {}
    }
    /**/
    // clientData.sales[`${type}ChecklistV2`][currentStage] = checklist
    clientData.sales[`${type}ChecklistV2`].forEach((item) => {
      if (item.sales_stage === currentStage) {
        const curr = item
        curr.checklists = checklistNew
      }
    })
    yield put(isChecklistUpdateAction(true))
    yield* fetchAndUpdateSalesInClient({
      ...action,
      data: {
        clientId: clientData.id,
        sales: clientData.sales,
      },
    })
    yield put(closeChecklistModal())
    yield put(isChecklistUpdateAction(false))
    yield put({
      type: UPDATE_CHECKLIST_IN_REFERRAL,
      data: {
        allReferrals: {
          ...allReferrals,
          [referralId]: {
            ...allReferrals[referralId],
            checklist: `${checkedItems}/${totalItems}`,
          },
        },
      },
    })
  } catch (e) {
    console.log(e)
    console.log("Error in updating sales checklist", e)
  }
}

function* archiveReferral(action) {
  try {
    const {
      clientId,
      referralId,
      currentSalesStage,
      statusChangeReasonId,
      statusChangeOtherReason,
      isFromClientPage,
      isFromClaimBlocker,
      isFromOutboundPage,
      isFromISAProspects,
    } = action.data
    const payload = {
      clientId,
      sales: {
        "Current stage": "Archived",
        statusChangeOtherReason,
        statusChangeReasonId,
        "Sales stage": {
          Archived: {
            date: getEpoch(),
          },
        },
      },
    }
    yield* fetchAndUpdateSalesInClient({ data: payload })
    if (!isFromISAProspects && !isFromClientPage && !isFromClaimBlocker && !isFromOutboundPage) {
      const { dragDropColumns } = yield select(state => state.referral.inboundReferrals)
      const newDragDropColumns = dragDropColumns[currentSalesStage].filter(item => item !== referralId)
      const { Archived } = dragDropColumns
      const newPayload = {
        dragDropColumns: {
          ...dragDropColumns,
          [currentSalesStage]: newDragDropColumns,
          Archived: merge(Archived, [referralId]),
        },
      }
      yield put({
        type: UPDATE_DRAG_DROP_COLUMNS,
        data: newPayload,
      })
    }
    if (isFromClientPage) {
      yield put(fetchClientProfileTimelineAction.request({
        clientId,
        limit: 20,
        offset: 0,
      }))
    }
    if (isFromClaimBlocker) {
      const { sliderRef, nextClaimBlocker, callBack } = action.data
      yield put(changeActiveBlockClaim.call(nextClaimBlocker))
      yield put(updateArchivedLeadAction.success())
      sliderRef.slickGoTo(nextClaimBlocker)
      callBack()
    }
    if (isFromOutboundPage) {
      yield put(fetchReferralsAction.request({
        type: "outgoing",
        baseUrl: API_V3_URL,
      }))
    }
    yield put(fetchClientProfileAction.request(clientId))
    yield put(toggleArchiveModalAction(false))
    return
  } catch (e) {
    console.log("Error in archiving referral", e)
  }
}

function* fetchClientPropertyListing() {
  try {
    const res = yield call(fetchClientPropertyListingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(fetchClientPropertyListingAction.success(response))
    }
  } catch (e) {
    yield put(fetchClientPropertyListingAction.failure(e))
  }
}

function* setReminder(action) {
  try {
    const { payload, id, referralId } = action.data
    const res = yield call(setReminderAPI, id, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(setReminderAction.success(response))
      if (referralId) {
        yield put(updateReferralNotifyTimeAction({ referralId, date: payload.date }))
      }
      yield put(closeAddReminderModalAction())
    }
  } catch (e) {
    yield put(setReminderAction.failure(e))
  }
}

function* setNotificationPreference(action) {
  try {
    const { id, type, value } = action.data
    const payload = {}
    if (type === "email") {
      payload.email_notification = value
    }
    if (type === "push") {
      payload.push_notification = value
    }
    const res = yield call(setNotificationPreferenceAPI, id, payload)
    if (res.status === 200) {
      const client = yield select(state => state.client.clientResponse)
      yield put(
        setNotificationPreferenceAction.success({
          ...client,
          ...payload,
        })
      )
    }
  } catch (e) {
    yield put(setNotificationPreferenceAction.failure(e))
  }
}

function* fetchClientTimeline(action) {
  try {
    const res = yield call(fetchClientTimelineAPI, action.data, "outgoing")
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(fetchClientTimelineAction.success(response))
    }
  } catch (e) {
    yield put(fetchClientTimelineAction.failure(e))
  }
}

export function* updateArchivedLead(action) {
  try {
    const { type, clients } = action.data
    const res = yield call(bulkClientUpdateAPI, type, clients)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(updateArchivedLeadAction.success(response))
      yield put(closeArchiveLeadModalAction())
      if (type === "isa") {
        yield put(fetchReferralsAction.request({ type, referralCategories: "isaPipeline", showMore: false }))
      } else if (type === "incoming") {
        yield put(fetchReferralsAction.request({ type, showMore: false }))
      } else if (type === "crm") {
        yield put(getNonAssistLeadsAction.request())
        yield put(toggleArchiveModalAction.call(false))
        yield put(fetchReferralsAction.request({ type, showMore: false }))
      }
    }
  } catch (e) {
    yield put(updateArchivedLeadAction.failure(e))
  }
}

export function* addISAClient(action) {
  try {
    const { client = {}, skipReferLead, } = action.data
    client.referralType = "isa"
    const res = yield call(addClientAPI, client)
    if (isSuccess(res)) {
      const { response } = res.data
      const payload = {
        clientId: response.id,
        type: client.type,
        referral_source: "submitleads",
        is_isa: true,
      }
      if (client.type === "Buyer") {
        const buyerPayload = {
          u_cust_id: response.id,
          sale_type: "resale",
        }

        if (
          client.locations
          && typeof client.locations === "object"
          && client.locations.length > 0
        ) {
          buyerPayload.locations = client.locations.map(key => (
            {
              c_locality_name: key.locality,
              c_locality_latitude: key.latitude,
              c_locality_longitude: key.longitude,
            }
          ))
        }

        yield put(buyerLocationSubmitAction.request({ buyerPayload, clientPayload: payload, skipReferLead }))
      } else if (client.type === "Seller") {
        const {
          longitude,
          latitude,
          state,
          address,
          country,
          street_address,
        } = client
        const sellerPayload = {
          property_status: "",
          u_cust_id: response.id,
          longitude,
          latitude,
          state,
          city: state,
          sale_type: "resale",
          address,
          country,
          street_address,
          rent_resale: "resale",
          property_name: "N/A",
        }
        yield put(sellerLocationSubmitAction.request({ sellerPayload, clientPayload: payload, skipReferLead }))
      }
      yield put(addISAClientAction.success(response))
    }
  } catch (e) {
    const {
      status,
    } = e
    const message = status === 400
      ? "You have already added this client as referral"
      : "Something went wrong! Please try again later."
    yield put(addISAClientAction.failure({ message }))
  }
}

export function* convertToCRMClient(action) {
  const { push, clientId } = action.data
  yield put(userConfirmationModalAction.call({
    question: "Are you sure you want the Radius Assist team to stop contacting this lead?",
    isShowModal: true,
  }))
  const { confirm } = yield race({
    confirm: take(afterConfirmSteps),
  })
  if (confirm.data) {
    try {
      const res = yield call(convertToCRMClientAPI, { clientId })
      if (isSuccess(res)) {
        yield put(convertToCRMClientAction.success({ clientId }))
        yield (put(push("/referrals/prospects/submitted")))
      }
    } catch (e) {
      yield put(convertToCRMClientAction.failure(e))
    }
  } else {
    yield put(convertToCRMClientAction.failure())
    yield put(userConfirmationModalAction.call({
      question: null,
      isShowModal: false,
    }))
  }
}

function* fetchCallStatus(action) {
  try {
    const { referralId, referralType } = action.data
    const res = yield call(fetchCallStatusAPI, referralId, referralType)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(fetchCallStatusAction.success(response || {}))
    }
  } catch (error) {
    yield put(fetchCallStatusAction.failure(error))
  }
}

function* handleCallFeedback(action) {
  try {
    const { toClose, isCallingPage, referral_id: referralId } = action.data
    cookie.save("callingReferralId", referralId)
    const res = yield call(submitCallFeedbackAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(submitCallFeedbackAction.success(response))
      if (isCallingPage) {
        const { pathname } = root.location
        if (pathname === "/referrals/marketplace") {
          const agentId = cookie.load("agentId")
          root.history.replaceState(null, null, pathname)
          yield put(getLeadForMarketplaceAction.request({ agentId }))
          toClose()
          return
        }
        yield put(sendBroadcastAction.call("enableCalling"))
        root.close()
      } else {
        toClose()
      }
    }
  } catch (error) {
    yield put(sendBroadcastAction.call("enableCalling"))
    yield put(submitCallFeedbackAction.failure(error))
    root.close()
  }
}

export function* afterConfirmSteps(action) {
  if (action.data) {
    return action.data
  }
  return false
}

function* buyerLocationSubmitHandle(action) {
  try {
    const { buyerPayload: payload, clientPayload, skipReferLead } = action.data
    const res = yield call(buyerLocationSubmitAPI, payload)
    if (isSuccess(res)) {
      const { data: { response } } = res
      clientPayload.req_id = response.req_id
      if (!skipReferLead) {
        yield put(referLeadAction.request({ payload: clientPayload, refreshReferrals: true }))
      }
      yield put(getNonAssistLeadsAction.request())
      yield put(closeISAAddClientModalAction.call())
      yield put(buyerLocationSubmitAction.success(response))
    }
  } catch (error) {
    yield put(buyerLocationSubmitAction.failure(error))
  }
}

function* sellerLocationSubmitHandle(action) {
  try {
    const { sellerPayload: payload, clientPayload, skipReferLead } = action.data
    const res = yield call(sellerLocationSubmitAPI, payload)
    if (isSuccess(res)) {
      const { data: { response } } = res
      clientPayload.prop_id = response.basic_id
      if (!skipReferLead) {
        yield put(referLeadAction.request({ payload: clientPayload, refreshReferrals: true }))
      }
      yield put(getNonAssistLeadsAction.request())
      yield put(closeISAAddClientModalAction.call())
      yield put(sellerLocationSubmitAction.success(response))
    }
  } catch (error) {
    yield put(sellerLocationSubmitAction.failure(error))
  }
}

function* handleMarkAsQaulified(action) {
  try {
    const {
      clientId,
      push,
    } = action.data
    yield put(showConfirmModalAction.call({
      title: "Are you sure?",
      body: `Marking this lead as qualified will remove them
      from our contact list and place them directly in your
      pipeline.`,
      successButtonText: "Yes, mark as qualified",
    }))
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { data: isConfimed } = confirm
    if (!isConfimed) {
      return false
    }
    yield put(toggleLoaderInConfirmAction.call(true))
    const res = yield call(markAsQaulifiedAPI, clientId)
    if (isSuccess(res)) {
      yield put(hideConfirmModalAction.call())
      const { response } = res.data
      const searchQuery = get(root, "location.search") || "?type=isa"
      yield put(markAsQualifiedLeadAction.success(response))
      push(`/client/${clientId}/timeline${searchQuery}`)
      yield put(fetchClientTimelineAction.request(clientId))
      yield put(fetchClientProfileAction.request(clientId))
    }
  } catch (error) {
    yield put(hideConfirmModalAction.call())
    const message = get(error, "data.error.message", "Opps! Something went wrong")
    createToast(message, "error")
    yield put(markAsQualifiedLeadAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(fetchClientProfileAction.REQUEST, fetchClientProfile)
  yield takeLatest(updateClientProfileAction.REQUEST, updateClientProfile)
  yield takeLatest(fetchAndUpdateClientProfileAction.REQUEST, fetchAndUpdateClientProfile)
  yield takeLatest(fetchAndUpdateSalesInClientAction.REQUEST, fetchAndUpdateSalesInClient)
  yield takeLatest(fetchClientPropertyListingAction.REQUEST, fetchClientPropertyListing)
  yield takeEvery(setNotificationPreferenceAction.REQUEST, setNotificationPreference)
  yield takeLatest(fetchClientUpdatesAction.REQUEST, fetchClientUpdates)
  yield takeLatest(editClientInfoAction.REQUEST, editClientInfo)
  yield takeLatest(fetchClientTimelineAction.REQUEST, fetchClientTimeline)
  yield takeLatest(setReminderAction.REQUEST, setReminder)
  yield takeLatest(updateArchivedLeadAction.REQUEST, updateArchivedLead)
  yield takeLatest(addISAClientAction.REQUEST, addISAClient)
  yield takeLatest(convertToCRMClientAction.REQUEST, convertToCRMClient)
  yield takeLatest(fetchCallStatusAction.REQUEST, fetchCallStatus)
  yield takeLatest(UPDATE_CURRENT_SALES_STAGE, updateCurrentSalesStage)
  yield takeLatest(UPDATE_CHECKLIST_CLIENT, updateChecklistForClient)
  yield takeLatest(UPDATE_SALES_CHECKLIST, updateSalesChecklist)
  yield takeLatest(submitCallFeedbackAction.REQUEST, handleCallFeedback)
  yield takeLatest(ARCHIVE_REFERRAL, archiveReferral)
  yield takeLatest(userConfirmAction.type, afterConfirmSteps)
  yield takeLatest(buyerLocationSubmitAction.REQUEST, buyerLocationSubmitHandle)
  yield takeLatest(sellerLocationSubmitAction.REQUEST, sellerLocationSubmitHandle)
  yield takeLatest(markAsQualifiedLeadAction.REQUEST, handleMarkAsQaulified)
}
