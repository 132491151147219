import React, { useEffect } from "react"
import OffersV2 from "container/OffersV2"
import TechAssets from "container/TechAssets"
import { Form } from "antd"
import Loader from "@ui/Loader"
import root from "window-or-global"
import {
  getState,
  DEAL_TERMS_STEP,
} from "dumbComponents/OffersV2/consts"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  ListWrap,
  ListRowWrap,
  StateDiv,
  State,
  StateWrap,
  GreyState,
} from "./styles"
import {
  DealTermsForm,
} from "./Forms"

const DealTerms = ({
  dealTermObject,
  updateDealTermsInformationTab,
  dealToView,
  stateForOffer,
  updateDealTermsInformationTabResponse,
  fetchDealTermsInformation,
  fetchDealTermsInformationResponse,
  propertyInformationObject,
  updatePropertyInformationTab,
  buyersAgentInformationObject,
  updateBuyerInformationTab,
  propertyFormRef,
  listingAgentFormRef,
  sellerFormRef,
  buyerFormRef,
  buyerAgentFormRef,
}) => {
  const {
    isFetching: updatingDealTerms,
  } = updateDealTermsInformationTabResponse || {}
  const deal_id = dealToView
  const {
    form,
    // type_of_transaction,
  } = dealTermObject || {}

  const [DEAL_TERMS_FORM_REF] = Form.useForm()

  useEffect(() => {
    const payload = {
      id: dealToView,
      current_offer_step: DEAL_TERMS_STEP,
    }
    fetchDealTermsInformation({
      id: deal_id,
      current_offer_step: DEAL_TERMS_STEP,
    })
  }, [])

  const {
    isFetching: fetchingDealTerms,
    data,
  } = fetchDealTermsInformationResponse || {}

  const handleFinalSubmit = (values) => {
    customToast("Validating coversheet, please wait...")
    const buyerAgentFormObject = buyerAgentFormRef.getFieldsValue()
    const propertInfoFormObject = propertyFormRef.getFieldsValue()
    const buyerFormObject = buyerFormRef.getFieldsValue()
    const sellerFormObject = sellerFormRef.getFieldsValue()
    const listingAgentfromObject = listingAgentFormRef.getFieldsValue()
    if (propertyFormRef) {
      propertyFormRef.submit()
    }

    if (listingAgentFormRef) {
      listingAgentFormRef.submit()
    }

    if (sellerFormRef) {
      sellerFormRef.submit()
    }

    if (buyerFormRef) {
      buyerFormRef.submit()
    }

    if (buyerAgentFormRef) {
      buyerAgentFormRef.submit()
    }

    // if (DEAL_TERMS_FORM_REF) {
    //   DEAL_TERMS_FORM_REF.submit()
    // }

    const {
      type_of_transaction,
      listing_agents: currentListingAgents,
      sellers: currentSellers,
      form: currentForm,
    } = propertyInformationObject || {}

    if (!type_of_transaction || type_of_transaction === undefined || type_of_transaction === null) {
      root.scrollTo(0, 0)
      customToast("Please select a property type.", "error")
      return
    }

    const payload = {
      ...values,
      down_payment_amount: values.balance_of_down_payment || 0, //fix shrungi
      current_offer_step: DEAL_TERMS_STEP,
      new_route: true,
      draft: 0,
      type_of_transaction,
      other_fees: [values.other_fees_1, values.other_fees_2] || [],
    }
    // const formValues = currentForm[stateForOffer]

    const listingAgentsPayload = currentListingAgents.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      agent_id: item.agent_id || 0, //fix shrungi
      is_radius_user: item.agent_id ? true: false,
      name: `${item.listing_agent_first_name} ${item.listing_agent_last_name}`,
      email: item.email || null,
      agent_license_number: item.agent_license_number || null,
      landline: item.landline || null,
      phone: item.phone || null,
      brokerage: item.brokerage || null,
      brokerage_license_number: item.brokerage_license_number || null,
      brokerage_address: item.brokerage_address || null,
      apt: item.apt || null,
      city: item.city || null,
      state: item.state || null,
      zipcode: item.zipcode || null,
    }))
  
    const sellerPayload = currentSellers.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      firstname: item.firstname,
      email: item.email,
      phone: item.phone,
      lastname: item.lastname,
      type_of_ownership: item.type_of_ownership,
      ownership_name: item.ownership_name,
    }))

    const propertyPayload = {
      clients: [
        ...sellerPayload,
      ],
      agents: [
        ...listingAgentsPayload,
      ],
      mls_select: "",
      mls_number: "",
      apn_number: "",
      street: "",
      apt: "",
      city: "",
      state: getState(stateForOffer),
      zipcode: "",
      county: "",
      ...propertyInformationObject,
      ...currentForm[stateForOffer],
      type_of_transaction,
      draft: 0,
      new_route : true,
      current_offer_step: "property_information_mobile",
    }

    const {
      buyers: currentBuyers,
      agents: currentAgents,
      selectedVendor,
      selected_vendor_id,
      selectedLender,
      selected_lender_id,
      vendors,
      lenders: currentLenders,
      financingType: newFinanceType,
      source,
      other_source,
    } = buyersAgentInformationObject || {}
    const isAllCashOrSelfFinancing = !selected_lender_id
    && newFinanceType === "cash"
    || newFinanceType === "seller_financing" ? 1 : 0

    if (newFinanceType === null || !newFinanceType || newFinanceType === undefined) {
      customToast("Financing Information missing", "error")
      return
    }

    const buyerAgentsPayload = currentAgents.map((item, iter) => ({
      agent_id: item.agent_id,
      is_primary: iter === 0 ? 1 : 0,
      name: `${item.agent_first_name ? `${item.agent_first_name} ${item.agent_last_name}` : `${item.name}`}`,
      email: item.email,
      phone: item.phone,
      landline: "",
      agent_license_number: item.agent_license_number,
      brokerage: item.brokerage,
      brokerage_license_number: item.brokerage_license_number,
      brokerage_address: item.brokerage_address,
      apt: item.buyer_apartment_number,
      city: item.city,
      state: item.state,
      zipcode: item.zipcode,
    }))

    const {
      co_buyer_details,
      type_of_ownership: typeOfOwnershipForm,
    } = buyerFormObject || {}


    const clientsPayload = currentBuyers.map((item, iter) => ({
      client_id: item.client_id ? item.client_id : null,
      req_id: item.req_id,
      transaction_id: item.transaction_id,
      is_primary: iter === 0 ? 1 : 0,
      firstname: item.firstname ? item.firstname : null,
      lastname: item.lastname ? item.lastname : null,
      email: item.email ? item.email : null,
      landline: null,
      phone: item.phone ? item.phone : null,
      address: item.address ? item.address : null,
      apt: item.apt ? item.apt : null,
      city: item.city ? item.city : null,
      state: item.state ? item.state : null,
      zipcode: item.zipcode ? item.zipcode : null,
      //type_of_ownership: item.type_of_ownership ? item.type_of_ownership : null,
      type_of_ownership: iter === 0 ? (item.type_of_ownership ? (item.type_of_ownership) : (typeOfOwnershipForm || null)) : (item.type_of_ownership ? item.type_of_ownership : co_buyer_details[iter - 1]?.type_of_ownership || null),
    }))

    const buyerPayload = {
      agents: [...buyerAgentsPayload],
      buyers: [...clientsPayload],
      draft: 0,
      new_route : true,
      lender_id: selected_lender_id,
      vendor_id: selected_vendor_id,
      financing_information: newFinanceType,
      source,
      other_source,
      is_all_cash: isAllCashOrSelfFinancing,
      current_offer_step: "buyers_information_mobile",
    }

    updateBuyerInformationTab({
      id: dealToView,
      payload: buyerPayload,
      noFetch: true,
    })

    //clean
    delete propertyPayload.sellers
    delete propertyPayload.listing_agents
    delete propertyPayload.form
    delete propertyPayload.currentState
    delete propertyPayload.price

    updatePropertyInformationTab({
      id: dealToView,
      payload: propertyPayload,
      doNotReload: true,
      callback: (id) => {
        updateDealTermsInformationTab({
          id,
          payload,
          noFetch: true,
        })
      },
    })
  }

  return (
    <Wrap>
      {fetchingDealTerms ? (<Loader top="0" bottom="0" style={{ margin: "100px auto" }} />)
        : (
          <SectionWrap>
            <ListRowWrap>
              <SectionTitle>
                <p>Deal Terms</p>
              </SectionTitle>
              <StateWrap>
                <GreyState>
                  <p>State</p>
                </GreyState>
                <State>
                  <h2>{getState(stateForOffer)}</h2>
                </State>
              </StateWrap>
            </ListRowWrap>
            <ListWrap>
              <DealTermsForm
                prefilledData={form[stateForOffer]}
                state={stateForOffer}
                onFinish={handleFinalSubmit}
                updatingDealTerms={updatingDealTerms}
                fetchingDealTerms={fetchingDealTerms}
                initialValues={data}
                //form={DEAL_TERMS_FORM_REF}
              />
            </ListWrap>
          </SectionWrap>
        )}
      {/*Deal Terms*/}
    </Wrap>
  )
}

export default OffersV2(TechAssets(DealTerms))
