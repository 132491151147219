import React, { useState } from "react";
import { Image } from "antd";
import "../styles.css";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import {
  HeartOutlined,
  ShareAltOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./gallery.css";
import ImgIcon from "../Icons/gallery.js";
import RequestTourModal from "../RequestTourModal/index";
import ContactAgentModal from "../ContactAgentModal/index";
import createToast from "dumbComponents/common/Toast/customToast";
import {
  CarouselWrap,
  ImageGrid,
  WebImageGrid,
  StyledImage,
  StyledImageCenter,
  DaysOnMarketPill,
  OpenAllImagesBtn,
  ModalHeaderContainer,
  CircleButton,
  TourBtn,
  DaysOnMarketPillExtended,
  OpenAllImagesBtnExtended,
  Address,
  ModalImageContainer,
  PreviewHeader,
  CircleButtonPreview,
  ModalPrice,
  StyledCarouselModal,
} from "./styles.js";
import root from "window-or-global";

const CarouselWrapper = ({ listingPageResponse }) => {
  const [openPhotos, setOpenPhotos] = useState(false);
  const [openContactModal, setopenContactModal] = useState(false);

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = root.document.createElement("input");
    el.value = root.location.href;
    root.document.body.appendChild(el);
    el.select();
    root.document.execCommand("copy");
    root.document.body.removeChild(el);
    setCopied(true);
    createToast("Link Copied !");
  }

  const [imageUrls, setImageUrls] = useState([]);

  const fetchAllImages = () => {
    const fetchedUrls = listingPageResponse?.data?.Media?.map(
      (item) => item.MediaURL
    );
    setImageUrls(fetchedUrls);
    setOpenPhotos(true);
  };

  const overlayElements = (
    <>
      {listingPageResponse?.data?.DaysOnMarket && (
        <DaysOnMarketPill>
          <p>{`${listingPageResponse?.data?.DaysOnMarket} Days on Market`}</p>
        </DaysOnMarketPill>
      )}
      {listingPageResponse?.data?.Media?.length > 1 && (
        <OpenAllImagesBtn onClick={fetchAllImages}>
          <ImgIcon />
          {`${listingPageResponse?.data?.Media?.length} Photos`}
        </OpenAllImagesBtn>
      )}
    </>
  );

  let imageElements = [];

  for (let i = 0; i < imageUrls.length; i = i + 6) {
    const currentImageUrl = imageUrls[i];
    const nextImageUrl = imageUrls[i + 1];
    const secondNextImageUrl = imageUrls[i + 2];
    const thirdNextImageUrl = imageUrls[i + 3];
    const forthNextImageUrl = imageUrls[i + 4];
    const fifthNextImageUrl = imageUrls[i + 5];

    imageElements.push(
      <div style={{ margin: "0" }}>
        <div
          key={i}
          className="image-pic-wrapper"
          style={{ marginBottom: "5px" }}
        >
          <div className="left" style={{ marginRight: "5px" }}>
            <Image src={currentImageUrl} alt={`Image ${i}`} height={515} />
          </div>
          <div className="right">
            {nextImageUrl && (
              <Image
                src={nextImageUrl}
                alt={`Image ${i}`}
                style={{ marginBottom: "5px" }}
              />
            )}
            {secondNextImageUrl && (
              <Image
                src={secondNextImageUrl}
                alt={`Image ${i}`}
                style={{ objectFit: "cover", height: "auto" }}
              />
            )}
          </div>
        </div>
        <div
          key={i}
          className="image-pic-wrapper"
          style={{ marginBottom: "3px" }}
        >
          <div className="right">
            {thirdNextImageUrl && (
              <Image
                src={thirdNextImageUrl}
                alt={`Image ${i}`}
                style={{ marginBottom: "5px" }}
              />
            )}
            {forthNextImageUrl && (
              <Image src={forthNextImageUrl} alt={`Image ${i}`} />
            )}
          </div>
          <div className="left" style={{ marginLeft: "5px" }}>
            {fifthNextImageUrl && (
              <Image src={fifthNextImageUrl} alt={`Image ${i}`} height={515} />
            )}
          </div>
        </div>
      </div>
    );
  }

  const [requestTourModalState, setRequestTourModalState] = useState(false);

  const handleRequestTourModal = () => {
    setOpenPhotos(false);
    setRequestTourModalState(true);
  };

  const modalTitle = (
    <>
      <ModalHeaderContainer>
        <div style={{ marginLeft: "24px" }}>
          <ModalPrice>
            $
            {parseFloat(listingPageResponse?.data?.ListPrice).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 0,
              }
            )}
          </ModalPrice>
          {listingPageResponse?.data?.UnparsedAddress && (
            <Address>
              {listingPageResponse?.data?.UnparsedAddress},{" "}
              {listingPageResponse?.data?.PostalCode}
            </Address>
          )}
        </div>
        <div>
          <CircleButton onClick={() => setopenContactModal(true)}>
            <HeartOutlined style={{ marginBottom: "5px" }} />
          </CircleButton>
          <CircleButton onClick={copy}>
            <ShareAltOutlined style={{ marginBottom: "5px" }} />
          </CircleButton>
          <TourBtn onClick={handleRequestTourModal}>Request a tour</TourBtn>
        </div>
      </ModalHeaderContainer>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );

  const [PreviewOpen, setPreviewOpen] = useState(false);

  const handleVisibleChange = (visible) => {
    setPreviewOpen(visible);
    console.log(PreviewOpen);
  };

  const handleOpenOnPreview = (modalName) => {
    if (modalName === "cnt") {
      setPreviewOpen(false);
      setopenContactModal(true);
    }
    if (modalName === "requestTour") {
      setPreviewOpen(false);
      setRequestTourModalState(true);
    }
  };

  return (
    <CarouselWrap>
      {PreviewOpen && (
        <PreviewHeader>
          <div>
            <ModalPrice style={{ color: "white" }}>
              $
              {parseFloat(listingPageResponse?.data?.ListPrice).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </ModalPrice>
            {listingPageResponse?.data?.UnparsedAddress && (
              <Address style={{ color: "#A3A3C2" }}>
                {listingPageResponse?.data?.UnparsedAddress},{" "}
                {listingPageResponse?.data?.PostalCode}
              </Address>
            )}
          </div>
          <div>
            <CircleButtonPreview onClick={() => handleOpenOnPreview("cnt")}>
              <HeartOutlined style={{ marginBottom: "5px", color: "white" }} />
            </CircleButtonPreview>
            <CircleButtonPreview onClick={copy}>
              <ShareAltOutlined
                style={{ marginBottom: "5px", color: "white" }}
              />
            </CircleButtonPreview>
            <TourBtn onClick={() => handleOpenOnPreview("requestTour")}>
              Request a tour
            </TourBtn>
            <CloseOutlined
              style={{ color: "#A3A3C2", marginBottom: "15px" }}
              onClick={() => setPreviewOpen(false)}
            />
          </div>
        </PreviewHeader>
      )}
      {requestTourModalState && (
        <RequestTourModal
          openModal={requestTourModalState}
          onClose={() => setRequestTourModalState(false)}
          listingPageResponse={listingPageResponse}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        />
      )}
      {openContactModal && (
        <ContactAgentModal
          openModal={openContactModal}
          onClose={() => setopenContactModal(false)}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        ></ContactAgentModal>
      )}
      <StyledCarouselModal
        title={modalTitle}
        centered
        open={openPhotos}
        onOk={() => setOpenPhotos(false)}
        onCancel={() => setOpenPhotos(false)}
        width={1200}
        style={{ padding: "32px 0px" }}
      >
        <ModalImageContainer className="image-container">
          <Image.PreviewGroup
            items={imageUrls}
            preview={{
              visible: PreviewOpen,
              onVisibleChange: (visible) => handleVisibleChange(visible),
            }}
            // rootClassName="propertyPage-previewImage"
            maskClassName="propertyPage-previewImage"
          >
            {imageElements}
          </Image.PreviewGroup>
        </ModalImageContainer>
      </StyledCarouselModal>
      {listingPageResponse?.data?.Media?.length == 1 && (
        <>
          <ImageGrid>
            {overlayElements}
            <StyledImageCenter
              src={listingPageResponse?.data?.Media[0]?.MediaURL}
              height="423px"
              width="1360px"
              onClick={fetchAllImages}
              style={{ objectFit: "cover", borderRadius: "16px" }}
            ></StyledImageCenter>
          </ImageGrid>
        </>
      )}
      {listingPageResponse?.data?.Media?.length == 2 && (
        <>
          <ImageGrid>
            {overlayElements}
            <StyledImageCenter
              src={listingPageResponse?.data?.Media[0]?.MediaURL}
              width="680px"
              onClick={fetchAllImages}
            ></StyledImageCenter>
          </ImageGrid>
          <ImageGrid>
            <StyledImageCenter
              src={listingPageResponse?.data?.Media[1]?.MediaURL}
              width="680px"
              onClick={fetchAllImages}
              style={{
                borderRadius: "0",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
              }}
            ></StyledImageCenter>
          </ImageGrid>
        </>
      )}
      {listingPageResponse?.data?.Media?.length > 2 && (
        <>
          {listingPageResponse?.data?.DaysOnMarket && (
            <DaysOnMarketPillExtended>
              <p>{`${listingPageResponse?.data?.DaysOnMarket} Days on Market`}</p>
            </DaysOnMarketPillExtended>
          )}
          <OpenAllImagesBtnExtended onClick={fetchAllImages}>
            <ImgIcon />
            {`${listingPageResponse?.data?.Media?.length} Photos`}
          </OpenAllImagesBtnExtended>
          <ImageGrid>
            <StyledImageCenter
              src={listingPageResponse?.data?.Media[0]?.MediaURL}
              width="900px"
              onClick={fetchAllImages}
            ></StyledImageCenter>
          </ImageGrid>
          <WebImageGrid>
            <div style={{ paddingBottom: "4px" }}>
              <StyledImage
                src={listingPageResponse?.data?.Media[1]?.MediaURL}
                width="440px"
                style={{ borderTopRightRadius: "16px" }}
                onClick={fetchAllImages}
              ></StyledImage>
            </div>
            <div style={{ paddingTop: "4px" }}>
              <StyledImage
                src={listingPageResponse?.data?.Media[2]?.MediaURL}
                width="440px"
                style={{ borderBottomRightRadius: "16px" }}
                onClick={fetchAllImages}
              ></StyledImage>
            </div>
          </WebImageGrid>
        </>
      )}
    </CarouselWrap>
  );
};

export default ListingPageContainer(CarouselWrapper);
