/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { useEffect } from "react"
import root from "window-or-global"
import query from "query-string"
import { checkForUserObject } from "services/Utils"
import { get, uniqBy } from "lodash"
import Cookies from "react-cookies"

const secureId = Cookies.load("secureId")
const user = checkForUserObject("user")

const createPayload = (values, currentDeal, throwError) => {
  try {
    const {
      listing_address,
      city,
      price,
      commision_percentage,
      source,
      created_at,
      row_created_at,
      date_of_listing_submission,
      other_transaction_type,
      other_source,
      gross_commission,
      radius_transaction_fee,
      transaction_type,
      client_type,
      is_radius_lead,
      zipcode,
      commission_type,
      fixed_fee,
      file_type,
      closed_on,
      lease_commencement_on,
      team_split_percentage,
      is_radius_tc,
      is_using_deal_tc,
      is_all_cash_offer,
      lender_email_address,
      is_radius_tc_to_open_escrow,
      escrow_officer_email,
      escrow_officer_phone,
      schedule_sign_post_installation,
      sign_post_installation_on,
      sign_post_installation_location,
      hoa_guidelines,
      sign_post_installation_riders,
      initial_name,
      initial_email,
      initial_phone,
      clientDetailsArray,
      list_price,
      contract_on,
      rent_amount,
      rent_amount_term,
      other_rent_amount_term,
      closing_agent_commision_percentage,
    } = values || {}

    // const {
    //   data,
    // } = initial_name || {}

    const isClientFromSearch = false

    const initialClientArray = [
      {
        name: initial_name,
        email: initial_email,
        phone: initial_phone,
        client_id: null,
        referral_id: null,
      },
    ]

    const extractedClientDetails = clientDetailsArray
    && clientDetailsArray.length > 0
    && clientDetailsArray.map(client => ({
      name: client.name,
      email: client.email,
      phone: client.phone,
      client_id: null,
      referral_id: null,
    }))

    let clientPayload = []

    if (isClientFromSearch) {
      clientPayload = extractedClientDetails
     && extractedClientDetails.length > 0
        ? uniqBy([...initialClientArray, ...extractedClientDetails], "email") : initialClientArray
    } else {
      clientPayload = extractedClientDetails
     && extractedClientDetails.length > 0
        ? uniqBy([...initialClientArray, ...extractedClientDetails], "email") : initialClientArray
    }

    const currentDealExists = currentDeal
    && typeof currentDeal === "object"
    && Object.keys(currentDeal).length > 0

    const isOtherSelectedInClientSource = source === "other"
    const isOtherSelectedInTransactionType = transaction_type === "other"
    const isOtherSelectedInRentTerm = rent_amount_term === "other"
    const verifiedClientSource = isOtherSelectedInClientSource ? other_source : null
    const verifiedTransactionType = isOtherSelectedInTransactionType ? other_transaction_type : null
    const verifiedRentTermType = isOtherSelectedInRentTerm ? other_rent_amount_term : ""
    const isRadiusTC = is_radius_tc === "is_radius_tc_yes" ? 1 : 0
    const isUsingDealTC = is_using_deal_tc === "is_using_deal_tc_yes" ? 1 : 0
    let isCashOffer = is_all_cash_offer === "is_all_cash_offer_yes" ? 1 : lender_email_address ? 0 : 1
    let isRadiusTcToOpenEscrow = is_radius_tc_to_open_escrow === "radius_tc_open_escrow_yes"
      ? 1 : escrow_officer_email && escrow_officer_phone ? 1 : 0
    let scheduleSignPostInstallation = schedule_sign_post_installation
    === "sign_post_installation_request_yes" ? 1 : sign_post_installation_on ? 1 : 0

    const currentCity = typeof city === "object" ? city.city ? city.city : city.state : city
    const currentState = typeof city === "object" ? city.state : currentDealExists ? currentDeal.state : "n/a"
    const allCashOfferRoutes = ["listing_with_accepted_offer", "dual", "pending_contract", "pending_lease"]
    const EscrowFormRoute = ["dual", "pending_contract", "external_referral_agreement"]

    const hasFileTypeChanged = (prevVal, fieldVal) => {
      if (prevVal === null || prevVal === undefined) return fieldVal
      if (fieldVal === null || fieldVal === undefined) return prevVal
      return fieldVal
    }

    const isDateOfListingSub = (currentDealExists, currentDeal) => {
      if (!currentDealExists && file_type === "new_listing") {
        return convertToTimestamp()
      } 
      else if (hasFileTypeChanged(currentDealExists && currentDeal.file_type, file_type) === "new_listing") {
        if (currentDealExists && currentDeal.date_of_listing_submission) {
                return currentDeal.date_of_listing_submission
              }
          return convertToTimestamp()
      } else if (!currentDealExists && file_type !== "new_listing") {
        return null
      } else if (hasFileTypeChanged(currentDealExists && currentDeal.file_type, file_type) !== "new_listing") {
        if (currentDealExists && currentDeal.date_of_listing_submission) {
          return currentDeal.date_of_listing_submission
        }
        return null
      }
    }

    const hasChanged = (prevVal, newVal, flag) => prevVal === newVal ? prevVal
      : newVal !== undefined && newVal !== null && newVal !== "checkbox" && !flag
        ? newVal : prevVal

    const hasNumberChanged = (prevVal, newVal) => prevVal === newVal ? prevVal
      : newVal !== undefined && newVal !== 0
        ? newVal : !isNaN(prevVal) ? prevVal : 0

    const hasDatesChanged = (prevVal, newVal) => prevVal === newVal ? prevVal
      : newVal !== undefined && !isNaN(newVal)
        ? newVal : !isNaN(prevVal) ? prevVal : 0

    const getClientArray = (existingClients, newClients) => {
      if (!existingClients || existingClients.length <= 0) return newClients
      //check for replacement
      if (isClientFromSearch) {
        const removedPrimaryClientArray = existingClients && existingClients.filter(client => client.client_id === null)
        const differencesArray = newClients && newClients
          .filter(({ email: id1 }) => existingClients.some(({ email: id2 }) => id2 !== id1)) || []

        if (differencesArray.length > 0) {
          return uniqBy([...differencesArray, ...newClients], "email")
        }

        return removedPrimaryClientArray
          && removedPrimaryClientArray.length > 0
          ? uniqBy([...removedPrimaryClientArray, ...initialClientArray, ...newClients], "email")
          : uniqBy([...initialClientArray, ...newClients], "email")
      }

      const combinedArray = newClients && newClients.length > 0 ? uniqBy([...existingClients, ...newClients], "email")
        : uniqBy([...existingClients], "email")

      const differencesArray = newClients && newClients
        .filter(({ email: id1 }) => existingClients.some(({ email: id2 }) => id2 !== id1)) || []

      if (differencesArray.length > 0) {
        return uniqBy([...differencesArray, ...newClients], "email")
      }

      return combinedArray
    }

    /**/
    isCashOffer = allCashOfferRoutes.includes(hasFileTypeChanged(currentDealExists
     && currentDeal.file_type, file_type)) ? isCashOffer : null

    isRadiusTcToOpenEscrow = EscrowFormRoute.includes(hasFileTypeChanged(currentDealExists
     && currentDeal.file_type, file_type)) ? isRadiusTcToOpenEscrow : null

    scheduleSignPostInstallation = hasFileTypeChanged(currentDealExists
     && currentDeal.file_type, file_type) === "new_listing" ? scheduleSignPostInstallation : null
    /**/

    return {

      rent_amount: hasNumberChanged(currentDeal && parseInt(currentDeal.rent_amount, 10), MoneyFormatter(rent_amount)),

      rent_amount_term: hasChanged(currentDeal && currentDeal.rent_amount_term, rent_amount_term),

      other_rent_amount_term: hasChanged(currentDeal && currentDeal.other_rent_amount_term, verifiedRentTermType),

      other_source: hasChanged(currentDeal && currentDeal.other_source, verifiedClientSource),

      other_transaction_type: hasChanged(currentDeal && currentDeal.other_transaction_type, verifiedTransactionType),

      created_at: hasDatesChanged(currentDealExists && currentDeal.created_at, convertToUnix(created_at)),

      listing_address: hasChanged(currentDealExists && currentDeal.listing_address, listing_address),

      city: hasChanged(currentDealExists && currentDeal.city, currentCity),

      state: currentState || "",

      list_price: hasNumberChanged(currentDealExists
       && parseInt(currentDeal.list_price, 10), MoneyFormatter(list_price)),

      price: hasNumberChanged(currentDealExists && parseInt(currentDeal.price, 10), MoneyFormatter(price)),

      commision_percentage: hasNumberChanged(currentDealExists && parseFloat(currentDeal.commision_percentage),
        commision_percentage && StoreAsFloat(commision_percentage)),

      source: hasChanged(currentDealExists && currentDeal.source, source),

      // gross_commission: currentDealExists
      // && currentDeal.gross_commission === 0
      //   ? gross_commission && parseInt(gross_commission, 10) === 0
      //     ? 0 : gross_commission && parseInt(gross_commission, 10)
      //   : currentDealExists && parseInt(currentDeal.gross_commission, 10),

      gross_commission: currentDealExists
      && !isNaN(currentDeal.gross_commission) ? currentDeal.gross_commission : 0,

      // radius_transaction_fee: currentDealExists
      // && currentDeal.radius_transaction_fee === 0
      //   ? radius_transaction_fee && parseInt(radius_transaction_fee, 10) === 0
      //     ? 0 : radius_transaction_fee && parseInt(radius_transaction_fee, 10)
      //   : currentDealExists && parseInt(currentDeal.radius_transaction_fee, 10),

      radius_transaction_fee: currentDealExists
      && !isNaN(currentDeal.radius_transaction_fee) ? currentDeal.radius_transaction_fee : 0,

      transaction_type: hasChanged(currentDealExists && currentDeal.transaction_type, transaction_type),

      client_type: hasChanged(currentDealExists && currentDeal.client_type, client_type && client_type.value),

      is_radius_lead: hasChanged(currentDealExists && currentDeal.is_radius_lead, is_radius_lead),

      zipcode: hasChanged(currentDealExists && currentDeal.zipcode
       && parseInt(currentDeal.zipcode, 10), zipcode && parseInt(zipcode, 10)),

      commission_type: hasChanged(currentDealExists && currentDeal.commission_type, commission_type),

      fixed_fee: hasNumberChanged(currentDealExists && parseInt(currentDeal.fixed_fee, 10), MoneyFormatter(fixed_fee)),

      file_type: hasFileTypeChanged(currentDealExists && currentDeal.file_type, file_type),

      row_created_at: hasFileTypeChanged(currentDealExists && currentDeal.file_type, file_type) === "new_listing" ? null : convertToTimestamp(),

      date_of_listing_submission: isDateOfListingSub(currentDealExists, currentDeal),

      closed_on: hasDatesChanged(currentDealExists && currentDeal.closed_on, convertToUnix(closed_on)),

      lease_commencement_on: hasDatesChanged(currentDealExists
       && currentDeal.lease_commencement_on, convertToUnix(lease_commencement_on)),

      team_split_percentage: hasNumberChanged(currentDealExists
       && parseFloat(currentDeal.team_split_percentage), team_split_percentage && StoreAsFloat(team_split_percentage)),

      is_radius_tc: hasChanged(currentDealExists && currentDeal.is_radius_tc, isRadiusTC),

      is_using_deal_tc: hasChanged(currentDealExists && currentDeal.is_using_deal_tc, isUsingDealTC),

      // eslint-disable-next-line max-len
      is_all_cash_offer: hasChanged(currentDealExists && currentDeal.is_all_cash_offer, isCashOffer, currentDeal && currentDeal.lender_email_address),

      lender_email_address: hasChanged(currentDealExists && currentDeal.lender_email_address, lender_email_address),

      is_radius_tc_to_open_escrow: hasChanged(currentDealExists
       && currentDeal.is_radius_tc_to_open_escrow, isRadiusTcToOpenEscrow),

      escrow_officer_email: hasChanged(currentDealExists && currentDeal.escrow_officer_email, escrow_officer_email),

      escrow_officer_phone: hasChanged(currentDealExists && currentDeal.escrow_officer_phone, escrow_officer_phone),

      schedule_sign_post_installation: hasChanged(currentDealExists && currentDeal.schedule_sign_post_installation,
        scheduleSignPostInstallation, currentDeal && currentDeal.schedule_sign_post_installation),

      sign_post_installation_on: hasDatesChanged(currentDealExists && currentDeal.sign_post_installation_on,
        convertToUnix(sign_post_installation_on)),

      sign_post_installation_location: hasChanged(currentDealExists && currentDeal.sign_post_installation_location,
        sign_post_installation_location),

      hoa_guidelines: hasChanged(currentDealExists && currentDeal.hoa_guidelines, hoa_guidelines),

      sign_post_installation_riders: hasChanged(currentDealExists
       && currentDeal.sign_post_installation_riders, sign_post_installation_riders),

      contract_on: hasDatesChanged(currentDealExists && currentDeal.contract_on, convertToUnix(contract_on)),

      clients: getClientArray(currentDealExists && currentDeal.clients, clientPayload) || [],

      client_name: "",

      client_email: "",

      closing_agent_commision_percentage: hasNumberChanged(currentDealExists
        && parseFloat(currentDeal.closing_agent_commision_percentage), closing_agent_commision_percentage && StoreAsFloat(closing_agent_commision_percentage)),
    }
  } catch (e) {
    throwError(true)
    console.error(e)
  }
  return []
}

const createMappedOptions = (options, input) => {
  if (options && typeof options !== "undefined" && options.length > 0) {
    return options.map(option => ({
      value: `${option.name}`,
      label: `${option.name} (${formatPhoneNumber(get(option, "phones[0].phone"))})`,
      data: [{
        name: option.name,
        phone: `${get(option, "phones[0].phone")}`,
        email: `${get(option, "emails[0].email")}`,
        client_id: option.id,
      }],
    }))
  }

  return [{
    value: input,
    label: input,
    data: [{
      name: input,
      phone: "",
      email: "",
      client_id: null,
    }],
  }]
}

export const HandleSubmit = (
  values,
  nextForm,
  history,
  formID,
  isFinal,
  fetchValuesWithID,
  createDeal,
  formValues,
  currentStage,
  updateDeal,
  currentDeal,
  throwError,
  isFormDisabled,
) => {
  const agentId = get(user, "agent_id") || get(user, "id")
  const payload = createPayload(formValues, currentDeal, throwError)
  if (payload.file_type === "new_listing") {
    payload.row_created_at = ""
    payload.created_at = ""
  }
  payload.agent_id = agentId

  if (isFormDisabled) {
    const { search } = root.location
    const parsedQuery = query.parse(search)
    let url = formID ? `${nextForm}?id=${formID}` : nextForm
    if (parsedQuery && parsedQuery.isEdit) {
      url += `&isEdit=${parsedQuery.isEdit}`
    }
    history.push(url)
    return
  }

  Object.keys(payload).forEach((item) => {
    payload[item] = payload[item] || typeof payload[item] === "number" ? payload[item] : null
  })

  if (formID) {
    updateDeal({
      payload,
      currentStage,
      nextForm,
      history,
      formID,
    })
  } else {
    createDeal({
      payload,
      currentStage,
      nextForm,
      history,
      formID,
    })
  }
}

export const delayFunctionForSearch = async (input, callback) => {
  try {
    const agentId = get(user, "agent_id") || get(user, "id")

    const payload = {
      text: input,
      sort_by: "created_at",
      start: 0,
      end: 10,
      aggregationNotRequired: false,
      ascending: false,
      agentId,
    }
    if (input) {
      let res = await fetch(`${API_V2_URL}clients/search`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": secureId,
        },
        body: JSON.stringify(payload),
      })

      res = await res.json()

      const {
        response: {
          clients,
        },
      } = res || {}

      const options = createMappedOptions(clients, input)

      callback(options)
    }
  } catch (e) {
    console.error(e)
  }
}

export const BrowserCloseListener = () => {
  /*eslint no-param-reassign: "error"*/
  const unloadCallback = (event) => {
    event.preventDefault()
    event.returnValue = ""
    return ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", unloadCallback)
    return () => window.removeEventListener("beforeunload", unloadCallback)
  }, [])
}

export const FetchFileIfIDExists = (id, currentDeal, fetchTCFiles) => {
  useEffect(() => {
    if (id && (currentDeal === null
     || currentDeal === undefined
     || currentDeal === "undefined"
     || currentDeal.length === 0)) {
      const agentId = get(user, "agent_id") || get(user, "id")
      fetchTCFiles({
        skip: 0,
        limit: 100,
        agentId,
        ignoreReset: true,
      })
    }
  }, [])
}

export const FunctionalComponentDidMount = (
  id,
  func,
  init,
  fieldValues,
  currentDeal,
  reset,
  formName,
  dispatch,
  change,
) => {
  useEffect(() => {
    if (id) {
      func({
        id,
        init,
        fields: fieldValues,
        currentDeal,
      })
    } else {
      if (change) change("city", "")
      reset(formName)
    }
  }, [])
}

export const backButtonUrl = (history, id, prevRoute) => history.push(id ? `${prevRoute}?id=${id}` : prevRoute)

const convertToUnix = date => new Date(date).getTime() / 1000

const convertToTimestamp = () => {
  const today = Date.now()
  const date = new Date(today)
  const timestamp = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.toLocaleTimeString()}`
  return timestamp
}

export const FormatToPercentage = (value, previousValue) => {
  if (!value) {
    return value
  }

  let valueDec = value
  valueDec = valueDec.replace(/([^0-9.]+)/, "")
  valueDec = valueDec.replace(/^(|\.)/, "")
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(valueDec)
  const finalValue = match[1] + match[2]

  return finalValue
}

export function removeByAttr(arr, attr, value) {
  let i = arr.length
  while (i--) {
    if (arr[i] && (arr[i][attr] === value)) {
      arr.splice(i, 1)
    }
  }
  return arr
}

export const StoreAsNum = (value) => {
  if (value !== null && typeof value !== "undefined" && value.length > 0) {
    return parseInt(value.replace(/[^\w\s]/gi, ""), 10)
  }
  return ""
}

export const StoreAsFloat = (value) => {
  if (value !== null && typeof value !== "undefined" && value.length > 0) {
    /*eslint no-useless-escape: 0*/
    return parseFloat(value.replace(/[^\w\.]/gi, ""))
  }
  return ""
}

export const ordinalSuffix = (i) => {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return `${i}st`
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  if (!i) return ""
  return `${i}th`
}

export const MoneyFormatter = (value) => {
  if (!value || value === undefined) return 0
  const replaceAsNumbers = value.replace(/[^\w\s]/gi, "")
  return parseInt(replaceAsNumbers, 10)
}

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}-`
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}


export const normalizePhoneNumber = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")
  // if (!previousValue || value.length > previousValue.length) {
  //   // typing forward
  //   if (onlyNums.length === 3) {
  //     return `${onlyNums}-`
  //   }
  //   if (onlyNums.length === 6) {
  //     return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`
  //   }
  // }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

const formatPhoneNumber = (str) => {
  const cleaned = (`${str}`).replace(/\D/g, "")
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return null
}

export const formatAmount = (input) => {
  if (!input) return ""

  const conv = input
    .replace(/,/g, "")
    .replace(/[^\d]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  if (conv && conv[0] !== "$") {
    return `$${conv}`
  }

  return conv.replace(/(\$)/g, "$")
}

export const getIsEdit = (deal) => {
  if (deal) {
    if (deal.step === "closed" || deal.step === "commission_verified" || deal.step === "tc_verified") {
      return true
    }
  }
  return false
}
