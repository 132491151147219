import React, { useEffect, useState } from "react";
import { Card, Select, Button } from "antd";
import {
  CardsWrapper,
  DateCardWrapper,
  WeekDay,
  CardDate,
  Month,
} from "./styles.js";
import Slider from "react-slick";
import moment from "moment";
import "./styles.css";

const dateCardStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "120px",
  border: "1px solid #5A5FF2",
  margin: "0 10px",
  borderRadius: "10px",
  color: "#232338",
};

const Index = ({
  buttonTitle,
  buttonWidth,
  selectBarWidth,
  onSelectedDateTime,
  selectBarWrapperWidth,
  numberOfCards,
  showLine,
}) => {
  const today = new Date();
  const weekdays = [];

  let countWeekdays = 0;
  let currentDay = today;

  while (countWeekdays < 14) {
    if (currentDay.getDay() !== 6 && currentDay.getDay() !== 0) {
      let cd =
        currentDay.getHours() > 16
          ? new Date(currentDay.getTime() + 24 * 60 * 60 * 1000)
          : currentDay;
      weekdays.push(cd);
      countWeekdays++;
    }

    currentDay = new Date(currentDay.getTime() + 24 * 60 * 60 * 1000);
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: numberOfCards,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const generateTimeOptions = () => {
    const format = "h:mm A";
    const startTime = new Date();
    startTime.setHours(9, 0, 0, 0);

    const endTime = new Date();
    endTime.setHours(18, 0, 0, 0);

    const timeOptions = [];
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      const formattedTime = currentTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      });
      timeOptions.push({
        value: formattedTime,
        label: formattedTime,
      });

      currentTime.setMinutes(currentTime.getMinutes() + 30); // Add 30 minutes
    }

    return timeOptions;
  };

  const generateTimeOptionsToday = () => {
    const format = "h:mm A";
    const newFormat = "hh:mm";
    const currentTime = moment();
    const startTime =
      currentTime.hour() < 9
        ? currentTime
            .clone()
            .startOf("day")
            .hour(9)
            .minute(0)
        : currentTime.clone();

    if (currentTime.minute() > 30) {
      startTime
        .add(1, "hour")
        .startOf("hour")
        .minute(30);
    } else {
      startTime.startOf("hour").minute(30);
    }

    const endTime = moment()
      .endOf("day")
      .hour(18)
      .minute(0);
    const timeOptions = [];

    let nextTime = startTime.clone();

    while (nextTime <= endTime) {
      if (nextTime.isAfter(moment())) {
        timeOptions.push({
          value: nextTime.format(newFormat),
          label: nextTime.format(format),
        });
      }
      nextTime.add(30, "minutes");
    }

    return timeOptions;
  };

  const timeOptionsToday = generateTimeOptionsToday();

  const [visitingDate, setVisitingDate] = useState(
    weekdays[0].toLocaleDateString("en-US", { day: "numeric" })
  );

  const [unixDate, setUnixDate] = useState();

  const [timeOptions, setTimeOptions] = useState(timeOptionsToday);
  const [visitingTime, setVisitingTime] = useState(timeOptions[0]?.value);
  const [rawDay, setRawDay] = useState(weekdays[0]);

  useEffect(() => {
    const currentDate = moment().format("D");
    const timeOptions =
      visitingDate === currentDate
        ? generateTimeOptionsToday()
        : generateTimeOptions();

    setTimeOptions(timeOptions);
    console.log("time now", timeOptions[0]?.value);
    setVisitingTime(timeOptions[0]?.value);
  }, [visitingDate]);

  const handleCardClick = (day) => {
    setVisitingDate(day.toLocaleDateString("en-US", { day: "numeric" }));
    setRawDay(day);
  };

  const handleTimeSelect = (time) => {
    setVisitingTime(time);
    console.log(time);
  };

  const selectStyle = {
    width: selectBarWidth ? `${selectBarWidth}%` : "100%",
  };

  const selectBarWrapper = {
    width: selectBarWrapperWidth,
  };

  const buttonStyle = {
    width: buttonWidth ? `${buttonWidth}px` : "100%",
    fontSize: "16px",
    marginTop: numberOfCards === 3 ? "40px" : "0",
    cursor: "pointer",
  };

  const handleDateTime = () => {
    const momentDate = moment(rawDay, "ddd MMM DD YYYY HH:mm:ss ZZ");
    momentDate.set("hour", moment(visitingTime, "h:mm A").format("HH"));
    momentDate.set("minute", moment(visitingTime, "h:mm A").format("mm"));
    const unixTimestamp = momentDate.unix();
    setUnixDate(unixTimestamp);
    onSelectedDateTime(unixTimestamp);
    console.log(unixDate);
  };

  return (
    <DateCardWrapper>
      <Slider {...settings} className="date-carousel">
        {weekdays.map((day, index) => (
          <div key={index}>
            <CardsWrapper>
              <Card
                bordered={true}
                style={{
                  backgroundColor:
                    visitingDate ===
                    day.toLocaleDateString("en-US", { day: "numeric" })
                      ? "#EFEFFE"
                      : "white",
                  border:
                    visitingDate ===
                      day.toLocaleDateString("en-US", { day: "numeric" }) &&
                    "1px solid #5A5FF2",
                }}
                onClick={() => handleCardClick(day)}
              >
                <WeekDay>
                  {day.toLocaleDateString("en-US", { weekday: "long" })}
                </WeekDay>
                <CardDate>
                  {day.toLocaleDateString("en-US", { day: "numeric" })}
                </CardDate>
                <Month>
                  {day.toLocaleDateString("en-US", { month: "short" })}
                </Month>
              </Card>
            </CardsWrapper>
          </div>
        ))}
      </Slider>
      <div style={selectBarWrapper}>
        <Select
          placeholder="Select Time"
          style={selectStyle}
          value={visitingTime}
          options={visitingDate ? timeOptions : null}
          onChange={handleTimeSelect}
        />
      </div>
      {showLine && (
        <hr
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "100px",
            color: "#E0E0EB",
          }}
        ></hr>
      )}
      {/* <button
        style={buttonStyle}
        disabled={!visitingDate || !visitingTime}
        onClick={handleDateTime}
        className="styledBtn"
      >
        {buttonTitle}
      </button> */}
    </DateCardWrapper>
  );
};

export default Index;
