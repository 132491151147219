import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import root from "window-or-global"
import { get } from "lodash"
import axios from "axios"
import colors from "@colors"
import createToast from "@ui/Toast"
import cookie from "services/CookieStorage"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { initiatePendo } from "services/Utils"
import { withRouter } from "react-router-dom"
import { GetUserPlatform, UrlSearchParams, getOnboardingNavigateRoute } from "./authUtils"
import GoogleSignIn from "./GoogleSignIn"
import AppleSignIn from "./AppleSignIn"


const fadeIn = keyframes`
  from {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
`

const SocialWrapper = styled.div`
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

const EntryBoxDivider = styled.div`
  height: 100%;
  flex-grow: 1;
  text-align: center;
`

const OrDiv = styled.p`
  display: inline-block;
  border: 1px solid ${colors.deepPurple};
  padding: 3px 3px;
  border-radius: 23px;
  font-size: 8px;
  position: relative;
  right: 2px;
`

const Divider = styled.div`
  height: 21px;
  width: 100%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 58px;
  flex-direction: row;
`

const Inline = styled.div`
  height: 1px;
  width: 141px;
  background: ${colors.deepPurple};
  position: relative;
  top: 9px;
  ${props => props.left && `
    left: 4px;
  `}
  ${props => props.right && `
    right: 4px;
  `}

  @media(max-width: 1099px) {
    display: none;
  }
`

const WcrImage = styled.img`
  height: 100px;
  display: flex;
  margin: 0 auto;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: ${fadeIn};
  animation-name: ${fadeIn};
`

class SocialSignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: true,
      showWcr: false,
    }
  }

  toggleWcr = () => {
    this.setState({ showWcr: true })
  }

  goToIncompleteProfile = (isPhone) => {
    const {
      history: {
        push,
      },
    } = this.props

    console.log("checking for apple")

    if (!isPhone || isPhone === null) {
      window.location.href = `${APP_URL}/signup/social`
    } else {
      window.location.href = `${APP_URL}/community`
    }
  }

  googleSignIn = (res) => {
    handleDataLayerPush(null, "login_page_interactions", "sign_in_with_google")
    const { pathname } = root.location
    const {
      history: {
        push,
      },
    } = this.props
    if (root.heap) {
      root.heap.track("Click_Google_Sign_In_SSO")
    }
    let data = {
      get_refresh_token: true,
      auth_code: res.code,
      redirect_uri: "postmessage",
      signup_source:
        UrlSearchParams(pathname, "signup_source") !== null ? UrlSearchParams(pathname, "signup_source") : undefined,
    }
    let self = this
    if (res.code) {
      axios.post(API_URL + "user/gmail/sign-up", data)
        .then(
          async (result) => {
            if (result.status > 400) {
              throw result
            }
            cookie.save("secureId", result.data.response.secure_id)
            if (result.data.response.newUser) {
              root.localStorage.setItem("google_user", JSON.stringify(result.data.response.user))
              root.localStorage.setItem("socialLogin", JSON.stringify({
                type: "google_login",
                firstname: result.data.response.user.firstname,
                lastname: result.data.response.user.lastname,
                email: result.data.response.user.email,
              }))

              const userPhoneNumber = get(result, "data.response.user.phone")
              this.goToIncompleteProfile(userPhoneNumber)
              // window.location.href = RADIUS_URL + "/signup/social"
              push("/onboarding/tell-about-yourself")
              return
            }
            const { user } = result.data.response
            const {
              account_type,
            } = user || {}

            const emailId = user.email
            const isPartOfBrokerageTeam = user && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
            const userType = user.isSubscribedToBrokerPlan
              ? isPartOfBrokerageTeam ? "brokerage_team" : "brokerage_agent" : "network"
        
            const payload = {
              login_method: "sign_in_with_google",
            }
            handleDataLayerPush(user, "login_success", "login_method", payload)

            const isRealEstateAgent = account_type === "real_estage_agent"
            cookie.saveUserToCookies(user)
            const isUserOnboarded = get(result, "data.response.user.isUserOnboarded")
            const onboardingQuizInfo = get(result, "data.response.user.onboardingQuizInfo")
            const citiesServed = get(result, "data.response.user.citiesServed")
            if (!isUserOnboarded && onboardingQuizInfo && onboardingQuizInfo.showOnboardingQuiz && isRealEstateAgent) {
              const onboardingRoute = getOnboardingNavigateRoute(onboardingQuizInfo.showStepKey)
              if (onboardingRoute) {
                window.location.href = `${APP_URL}${onboardingRoute}`
                return
              }
            }
            if (!(citiesServed && citiesServed.length) && isRealEstateAgent) {
              window.location.href = `${APP_URL}/onboarding/tell-about-yourself`
              return
            }
            if (cookie.load("redirectUrl")) {
              let redirectUrl = cookie.load("redirectUrl")
              cookie.remove("redirectUrl")
              window.location.href = `${APP_URL}${redirectUrl}`
              return
            }
            if (GetUserPlatform() === "desktop") {
              const initialLoginFlag = {
                id: result.data.response.user.id,
                flag: true,
              }
              cookie.save("initialLoginFlag", JSON.stringify(initialLoginFlag))
              const userPhoneNumber = get(result, "data.response.user.phone")
              this.goToIncompleteProfile(userPhoneNumber)
            } else if (result.data.response.newUser) {
              root.localStorage.setItem("socialLogin", JSON.stringify({
                type: "google_login",
                firstname: result.data.response.user.firstname,
                lastname: result.data.response.user.lastname,
                email: result.data.response.user.email,
              }))
              const userPhoneNumber = get(result, "data.response.user.phone")
              this.goToIncompleteProfile(userPhoneNumber)
            } else {
              window.location.href = RADIUS_URL + "/page/redirect?tag=landing_page&params={}"
            }
          },
          (error) => {
            createToast("Something went wrong!", "erorr")
          },
        ).catch((error) => {
          const errorMessage = get(error, "data.error.message")
          createToast(errorMessage, "erorr")
        })
    } else {
      console.error(res)
    }
  }

  redirectToCRS = () => {
    axios
      .get(API_URL + "crs/fetchAuthXmlEncoded", {
        params: { platform: "web", newLink: true },
      })
      .then((result) => {
        let form_sso = document.getElementById("TheForm")
        form_sso.SAMLRequest.value = result.data.response.SAMLRequest
        window.open("", "_self")
        form_sso.submit()
      })
  }

  redirectToWCR = (type) => {
    this.props.router.push("/signup")
  }

  handleAppleSignIn = (result) => {
    console.log(result)
    handleDataLayerPush(null, "login_page_interactions", "sign_in_with_apple")
  }

  render() {
    const { showWcr } = this.state
    return (
      <div>
        {!showWcr && (
          <React.Fragment>
            <SocialWrapper showWcr={showWcr}>
              <GoogleSignIn signIn={this.googleSignIn} />
              <AppleSignIn signIn={this.handleAppleSignIn} />
            </SocialWrapper>
            {/* <Divider>
              <EntryBoxDivider>
                <Inline left></Inline>
              </EntryBoxDivider>
              <EntryBoxDivider>
                <OrDiv>OR</OrDiv>
              </EntryBoxDivider>
              <EntryBoxDivider>
                <Inline right></Inline>
              </EntryBoxDivider>
            </Divider> */}
            {/* <SocialWrapper showWcr={this.state.showWcr}>
              <RrcSignInBtn clicked={this.redirectToCRS} />
            </SocialWrapper> */}

            {/* <SocialWrapper showWcr={this.state.showWcr}>
              <RrcSignInBtn
                clicked={!this.props.login ? this.redirectToWCR : this.toggleWcr}
                alt="Women's Council Realtor"
                image="//d2fedz0by71ckz.cloudfront.net/images/WCRLogoTransparent.png"
                text={`Women's Council ${!this.props.login ? "Sign up" : "login"}`}
              />
            </SocialWrapper> */}
          </React.Fragment>
        )}

        {this.state.showWcr && (
          <WcrImage src="//d2fedz0by71ckz.cloudfront.net/images/WCRLogoTransparent.png" alt="Women's Council Realtor" />
        )}

        {/* <EntryBoxDivider /> */}

        <form id="TheForm" method="post" action={SSO_URL} target="_self">
          <input type="hidden" name="SAMLRequest" />
        </form>
      </div>
    )

    // return (
    //   <div>
    //     <WcrImage src="//d2fedz0by71ckz.cloudfront.net/images/WCRLogoTransparent.png" alt="Women's Council Realtor" />
    //     <EntryBoxDivider />
    //   </div>
    // )
  }
}

export default withRouter(SocialSignIn)
