import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import container from "container/App"
import root from "window-or-global"
import flowContainer from "container/App/applyFlowContainer"
import { Link, withRouter } from "react-router-dom"
import RealtyContainer from "container/Realty"
import {
  minDesktopWidth,
  minMediumDesktopWidth,
} from "shared/consts"
import { useWindowSize, getOS } from "services/Utils"
import { authCheck } from "services/auth"
import CookiesStorage from "services/CookieStorage"
import { BlueButton } from "dumbComponents/HomePageV2/Components/commonStyles"
import QRCode from "dumbComponents/HomePageV2/Components/QRCode/index"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const LoginButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 56px;
  position: relative;

  ${props => props.modifyNavbar && `
    gap: 15px;
  `}

  @media(max-width: 1099px) {
    gap: 13px;
  }
`

const Button = styled.button`
  // padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  color: #000;
  // margin: 0px 5px;
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  //text-decoration-line: underline;
  text-transform: uppercase;
  border: none;
  background: transparent;

  span hr {
    margin: 0;
    color: #1C1C1F;
    opacity: 1;
    filter: brightness(0.2);
  }


  ${props => props.isPrimary && `
    border: 1px solid ${colors.white};
    background: ${colors.marineBLue};
    border-radius: 0px;
    color: ${colors.white};
    font-weight: 300;

    &:hover {
      color: ${colors.marineBLue};
    }
  `}

   &:hover {
      color: ${colors.marineBLue};
   }

  @media(max-width: 1099px) {
    display: none;
  }
`

const ApplyButton = styled.button`
  width: 214px;
  height: 50px;
  width: max-content;
  background: #5A5FF2;
  border-radius: 200px;
  padding: 5px 25px;

  color: #FFF;
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border: none;

  ${props => props.invert && `
     color: #5A5FF2;
     background: #fff;
     border: 1px solid #5A5FF2;
  `}

  @media(max-width: 1099px) {
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    height: 34px;
    width: 164px;
  }


  ${props => props.desktopOnly && `
    display: block;
    @media(max-width: 1099px) {
      display: none;
    }

    ${props.hide && `
      display: none;
    `}
  `}

  transition: 1s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`

const HamburgerCTA = styled.button`
  display: none;
  border: none;
  cursor: pointer;
  background: transparent;

  img {
    height: 20px;
    //width: 30px;
  }

  @media(max-width: 1099px) {
    display: block;
  }
`

const trackHubspotEvent = (RARTracker, events) => {
  const isLogged = authCheck()
  if (isLogged) {
    RARTracker({
      payload: {
        source: events,
      },
    })
  }
}

const AbsoluteForm = styled.div`
  position: absolute;
  right: 0;
  top: 70px;
`

const PublicRoutes = ({
  user: userData,
  isVersionV2,
  history,
  sideMenuToggle,
  toggleApplyModal,
  isEven,
  RARTracker,
  loginVariant,
  modifyNavbar,
  isSideMenuVisible,
  appDownloadActionResponse,
  toggleLandingPageMQLFormToggle,
  toggleEWebinarModal,
  history: {
    location,
  },
}) => {
  const size = useWindowSize()
  const { width } = size

  const isLegalPage = location.pathname && location.pathname.includes("/legal")
  const isRoot = location.pathname && location.pathname === "/"

  const [isAppFormVisible, setAppFormVisible] = useState(false)
  const { data: appDownloadData, isFetching: submittingForm } = appDownloadActionResponse || {}
  const isCurrentUserOniOS = getOS() === "ios"

  return (
    <LoginButtonWrap
      modifyNavbar={modifyNavbar}
    >
      {!modifyNavbar && (
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleDataLayerPush(null, "login_initiated", "login")
            const path = history.location.pathname
            const search = history.location.search
            CookiesStorage.save("redirectUrl", `${path}${search}`)
            history.push("/signup/enter-email")
          }}
          isEven={isEven}
          className="login_nav_web"
        >
          LOGIN
          <span>
            <hr />
          </span>
        </Button>
      )}

      <BlueButton
        hide={modifyNavbar}
        desktopOnly
        className="explore_ownership_nav_web"
        onClick={() => {
          root.location.href = "https://www.radiusagent.com/apply"
          // const variantHubSpot = isEven ? "Apply_blue_nb" : "Apply_white_nb"
          // trackHubspotEvent(RARTracker, variantHubSpot)
          // const payload = {
          //   section_name: "",
          //   destination_url: "N/A",
          // }
          // if (isLegalPage) {
          //   payload.section_name = "lets_partner_up"
          //   handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
          //   toggleApplyModal({
          //     bool: true,
          //     accountType: null,
          //     className: `${loginVariant}_apply_form`,
          //     headerText: "",
          //     subHeaderText: "",
          //   })
          // } else if (isRoot) {
          //   payload.section_name = "real_estate_legacy"
          //   handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
          //   toggleLandingPageMQLFormToggle({
          //     heroMQLFormData: {
          //       bool: true,
          //       className: "form_explore_ownership_nav_web",
          //     },
          //   })
          // } else {
          //   payload.section_name = "lets_partner_up"
          //   handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
          //   toggleApplyModal({
          //     bool: true,
          //     accountType: null,
          //     className: "form_explore_ownership_nav_web",
          //     headerText: "",
          //     subHeaderText: "",
          //   })
          // }
        }}
      >
        Talk with Radius
      </BlueButton>

      {modifyNavbar && (
        <ApplyButton
          className="explore_ownership_nav_web"
          onClick={() => {
            root.location.href = "https://www.radiusagent.com/apply"
            // const variantHubSpot = isEven ? "Apply_blue_nb" : "Apply_white_nb"
            // trackHubspotEvent(RARTracker, variantHubSpot)
            // const payload = {}
            // payload.section_name = "navbar"
            // handleDataLayerPush(userData, "talk_with_us_cta_clicked", "", payload)
            // toggleApplyModal({
            //   bool: true,
            //   accountType: null,
            //   className: "form_explore_ownership_nav_web",
            //   headerText: "",
            //   subHeaderText: "",
            // })
          }}
        >
          Talk with Radius
        </ApplyButton>
      )}

      {modifyNavbar && (
        <ApplyButton
          invert
          className="join_our_community_nav_web"
          onClick={(e) => {
            e.preventDefault()
            root.location.href = "https://www.radiusagent.com/brokerage-overview"
          }}
        >
          Brokerage Overview
        </ApplyButton>
      )}
      {isAppFormVisible && (
        <AbsoluteForm>
          <QRCode
            appDownloadSMSData={appDownloadData}
            submittingForm={submittingForm}
            toggle={setAppFormVisible}
            className="form_join_our_community_nav_web"
          />
        </AbsoluteForm>
      )}
      {!modifyNavbar && (
        <HamburgerCTA
          id="radius-hamburger"
          onClick={() => sideMenuToggle(!isSideMenuVisible)}
        >
          <img id="radius-hamburger-img" src={`${CLOUDFRONT}/PurpleBurgerMenu.png`} alt="hamburger_rads" />
        </HamburgerCTA>
      )}

    </LoginButtonWrap>
  )
}

export default RealtyContainer(flowContainer(container(withRouter(PublicRoutes))))

