import React, { useEffect } from "react"
import Footer from "dumbComponents/common/FooterNew"
import styled from "styled-components"
import RoomsContainer from "container/Rooms"
import { Helmet } from "react-helmet"
import ApplyFormContainer from "container/App/applyFlowContainer"
import { useWindowSize } from "services/Utils"
import { authCheck } from "services/auth"
import {
  JoinCommunity,
  DownloadTheApp,
  CardSection,
  CardCarousel,
  MainCarousel,
  ScheduledRoomCarousel,
  RoomsBanner,
  TrendingTopics,
  HappeningNow,
  RecommendedRooms,
} from "./Components"

const Wrap = styled.div``

const SEOImage = "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_SEOimage.png"

const RoomsLP = ({
  getScheduleRooms,
  getRoomTopics,
  getRoomTopicEvents,
  getPromotedRooms,
  toggleApplyModal,
  getRooms,
  getPromotedRecommendedRooms,
}) => {
  const size = useWindowSize()
  const isLogged = authCheck()

  useEffect(() => {
    getScheduleRooms()
    getRooms({
      fetchLive: true,
    })
    getRoomTopics()
    getPromotedRooms()
    getRoomTopicEvents()
    getPromotedRecommendedRooms()
  }, [])

  const meta = {
    title: "Empowering Real Estate Entrepreneurs: On Demand Content",
    description: "Level Up Your Real Estate Game: Live learning, networking, and more in Radius Rooms. Join the community, access exclusive content, and boost your career.",
    type: "website",
    url: `${WEB_URL}rooms`,
    image: SEOImage,
  }

  return (
    <Wrap className="external_rooms_page">
      <Helmet>
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.title} />
        <meta property="title" content={meta.title} />
        <meta property="og:url" content={meta.url} />
        <meta property="url" content={meta.url} />
        <meta property="og:description" content={meta.description} />
        <meta property="description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta property="image" content={meta.image} />
      </Helmet>
      <CardCarousel />
      {isLogged && (
        <RoomsBanner />
      )}

      {isLogged && (
        <HappeningNow />
      )}

      {!isLogged && (
        <CardSection
          size={size}
        />
      )}
      {!isLogged && (
        <JoinCommunity
          toggleApplyModal={toggleApplyModal}
        />
      )}
      <ScheduledRoomCarousel
        isLogged={isLogged}
      />

      {isLogged && (
        <RecommendedRooms />
      )}

      {!isLogged && (
        <DownloadTheApp />
      )}
      {isLogged && (
        <TrendingTopics size={size} />
      )}
      <MainCarousel />
      <Footer
        customStyle={{
          paddingTop: "100px",
        }}
      />
    </Wrap>
  )
}

export default ApplyFormContainer(RoomsContainer(RoomsLP))
