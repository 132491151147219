import React, { useState } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Container from "container/OnboardingSetup"
import GetStartedImage from "dumbComponents/Auth/SignupV2/Images/GetStartedImage.svg"
import CookiesStorage from "services/CookieStorage"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  InputWrap,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import Navbar from "../Navbar"
import { ATC, INTER } from "@fonts"

const CardWrap = styled.div`
  border-radius: 9px;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);
  padding: 25px;
  width: 406px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`

const CardTitle = styled.h1`
  color: var(--color-tertiary, #1C1C1F);
  margin: 0;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const UpdateEmail = ({
  history,
  sendVerifyLink,
}) => {
  const [email, setMail] = useState()
  const secureId = CookiesStorage.load("secureId")
  const currentEmail = CookiesStorage.load("email")

  const handleClick = () => {
    const payload = {
      isRequestForEmailUpdate: true,
      email,
    }
    sendVerifyLink({
      payload,
      secureId,
      callback: () => history.push(`/signup/email-sent?email=${email}`),
    })
  }

  return (
    <>
      <Navbar />
      <Wrap>
        <ContentWrap>
          <RightWrap>
            <WelcomeContentWrap>
              <WelcomeTitle height="fit-content">
                Update your email
              </WelcomeTitle>
            </WelcomeContentWrap>
            <CardWrap>
              <CardTitle>
                {`Your registered email address is ${currentEmail}, add your new email address here.`}
              </CardTitle>
              <InputWrap
                width={350}
                bordered
                placeholder="Enter your email address"
                value={email}
                onChange={e => setMail(e.target.value)}
                style={{
                  borderRadius: 9,
                  border: "1px solid #5A5FF2",
                  borderColor: "5A5FF2",
                  marginTop: "0px",
                }}
              />
              <ICAButtons
                style={{
                  width: "fit-content",
                }}
                onClick={() => handleClick()}
              >
                Send Verification Link
              </ICAButtons>
            </CardWrap>
          </RightWrap>
          <LeftWrap justifyContent="flex-end">
            <LeftImage src={GetStartedImage} />
          </LeftWrap>
        </ContentWrap>
      </Wrap>
    </>
  )
}

export default withRouter(Container(UpdateEmail))
