import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || "20px 15px"};
  width: max-content;

  ${props => props.minWidth && `
    min-width: ${props.minWidth};
  `}

  ${props => props.minHeight && `
    min-width: ${props.minHeight};
  `}

  @media(min-width: 1100px) {
    ${props => props.smallDesktopWidth && `
      min-width: ${props.smallDesktopWidth};
    `}
  }
  

  @media(min-width: 1440px) {
    ${props => props.largeDesktopMinWidth && `
      min-width: ${props.largeDesktopMinWidth};
    `}
  }


  @media(max-width: 1099px) {
    min-width: ${props => props.mobileMinWidth || "unset"};
    min-height: ${props => props.mobileMinHeight || "unset"};

    ${props => props.fixedMobileWidth && `
      width: ${props.fixedMobileWidth};
    `}

    ${props => props.mobileFlexJC && `
      justify-content: ${props.mobileFlexJC};
    `}

    ${props => props.mobileMaxWidth && `
      max-width: ${props.mobileMaxWidth};
    `}
  }

  ${props => props.gradientOverride && `
    background: transparent;
    border: 1px solid #5b5ff1;
  `}

   ${props => props.hoverAnim && `
      padding-left: 56px;
      padding-top: 39px;

      @media(max-width: 1099px) {
        padding-top: 22px;
        padding-left: 29px;
      }
      &: hover {
        background: white;
      }
   `}

   ${props => props.customPadding && `
      padding: ${props.customPadding};
   `}
`

const GradientBox = ({
  children,
  minWidth,
  minHeight,
  padding,
  mobileMinWidth,
  mobileMinHeight,
  mobileFlexJC,
  customClass,
  mobileMaxWidth,
  largeDesktopMinWidth,
  smallDesktopWidth,
  fixedMobileWidth,
  hoverAnim,
  gradientOverride,
  customPadding,
}) => (
  <Wrap
    className={customClass || "gradient-wrap"}
    minWidth={minWidth}
    minHeight={minHeight}
    padding={padding}
    mobileMinWidth={mobileMinWidth}
    mobileFlexJC={mobileFlexJC}
    mobileMaxWidth={mobileMaxWidth}
    largeDesktopMinWidth={largeDesktopMinWidth}
    mobileMinHeight={mobileMinHeight}
    smallDesktopWidth={smallDesktopWidth}
    fixedMobileWidth={fixedMobileWidth}
    gradientOverride={gradientOverride}
    hoverAnim={hoverAnim}
    customPadding={customPadding}
  >
    {children}
  </Wrap>
)

export default GradientBox
