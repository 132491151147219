import React from "react";
import { Li } from "@ui/DragDropColumn/common";
import DropdownMenu from "@ui/NewTooltip";
import { ThreeDotMoreIcon, DivideIcon } from "dumbComponents/ListingsV2/icons";
import { CTAWrap, CTAButton, CTAText, IncompleteCount } from "./styles";

const DynamicMenu = ({
  mainList,
  dropdownList,
  itemData,
  id,
  isCompletedFlow = false,
  envelopeId = null,
  documentName = "",
}) => {
  return (
    <>
      <CTAWrap>
      &nbsp;
      {itemData?.incomplete_count ? (
        <>
          <IncompleteCount>{itemData?.incomplete_count}</IncompleteCount>
        </>
      ) : null}
        {mainList &&
          mainList.map((main) => (
            <>
              <CTAButton
                style={{ width: "100px" }}
                onClick={() => {
                  main.onClick(id);
                }}
              >
                {main.image}
                <CTAText>{main.label}</CTAText>
              </CTAButton>
              {main.showDivide && <DivideIcon />}
            </>
          ))}
        {dropdownList && dropdownList.length > 0 && (
          <DropdownMenu
            position="bottom"
            component={<ThreeDotMoreIcon />}
            top={30}
            padding={10}
            left={-160}
            width={200}
            borderColor="#FFF"
            eventType="hover"
            borderRadius={4}
            showShadow
            arrowPosition="right"
          >
            <React.Fragment>
              {dropdownList.map((option) => (
                <Li
                  onClick={() => {
                    if (isCompletedFlow) {
                      option.onClick(id, envelopeId, documentName);
                    } else {
                      option.onClick(id);
                    }
                  }}
                  fontFam="Montserrat"
                  fontSize="12px"
                  color="#5A5FF2"
                  fontWeight="600"
                >
                  <span>{option.label}</span>
                </Li>
              ))}
            </React.Fragment>
          </DropdownMenu>
        )}
      </CTAWrap>
    </>
  );
};

export default DynamicMenu;
