import React from "react"
import styled from "styled-components"
import { DragDropContext } from "react-beautiful-dnd"
import Box from "@ui/Box"
import NoReferralFound from "dumbComponents/Referral/common/NoReferralFound"
import { columnConfig } from "./config"
import Column from "./Column"
import ZeroStateCRM from "../CRMV2/ZeroStateCRM/index"

const Wrap = styled.div`
  display: flex;
  margin-top: 20px;
  overflow-x: scroll;
  min-height: 100vh;
`

class DragAndDropArea extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
  }

  onDragEnd = (result) => {
    try {
      const { nonAssistLeadsResponse: { data } } = this.props
      const { clients } = data || {}
      const {
        draggableId: clientId,
        source: {
          droppableId: sourceStage,
        },
        destination: {
          droppableId: destinationState,
        },
      } = result
      const selectedClient = clients.find(x => x.id === clientId)
      const clientIndex = clients.map(x => x.id).indexOf(clientId)
      const { types } = selectedClient
      const { changeSalesState } = this.props
      changeSalesState({
        sourceStage,
        destinationState,
        clientId,
        types,
        clientIndex,
      })
    } catch (error) {
      // TODO: this
      console.log("something went toast", error)
    }
  }

  // onDragStart = () => {
  //   console.log("onDragStart")
  // }

  // onDragUpdate = () => {
  //   console.log("onDragUpdate")
  // }

  render() {
    // const {  } = this.state
    const {
      dndColumns,
      cardProps,
      leadsCount,
      hideMarkAsQualified,
    } = this.props
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
      >
        <Wrap>
          {leadsCount === 0 ? (
            <Box d="block" style={{ width: "100%" }}>
              <NoReferralFound />
            </Box>
          ) : (
            <React.Fragment>
              {columnConfig().map((column) => {
                const leads = dndColumns[column.value]
                return (
                  <Column
                    key={column.display_name}
                    column={column}
                    leads={leads}
                    cardProps={cardProps}
                    hideMarkAsQualified={hideMarkAsQualified}
                  />
                )
              })}
            </React.Fragment>
          )}
        </Wrap>
      </DragDropContext>
    )
  }
}

export default DragAndDropArea
