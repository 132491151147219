import styled from "styled-components"
import {
  Select,
  Input,
  InputNumber,
  Spin,
  Form,
  GoogleAutoComplete,
} from "@ui/antd"

const { TextArea } = Input

export const Wrap = styled.div`
  width: 100%;
`

export const SelectFieldWrap = styled(Select)`
  border-radius: 4px;
  border: 0.7px solid #898989;
  width: 100%;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    ${props => props.isRequired && `
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
      background-repeat: no-repeat !important;
      background-position: right 12px center !important;
      background-size: 20px !important;
      transition-property: background, background-image, background-repeat, background-position, background-size !important;
      transition-duration: 0s !important;
    `}
    height: 42px !important;
  }
`

export const InputFieldWrap = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 42px !important;

  ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 12px center !important;
    background-size: 20px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}
`

export const InputNumberWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  height: 42px !important;
  .ant-input-number-input {
    height: 42px !important;
  }

  ${props => props.isRequired && `
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M10 5.3125V15.3125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.3299 7.8125L5.66992 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.66992 7.8125L14.3299 12.8125' stroke='%23F37475' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 12px center !important;
    background-size: 20px !important;
    transition-property: background, background-image, background-repeat, background-position, background-size !important;
    transition-duration: 0s !important;
  `}
`
export const StyledSpin = styled(Spin)`
  width: 100%;
`

export const StyledFormItem = styled(Form.Item)`
  ${props => props.hidden && `
    min-height: 0px;
    max-height: 0px;
  `}
`

export const InputTextAreaWrap = styled(TextArea)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 125px !important;
`

export const StyledGoogleAutoComplete = styled(GoogleAutoComplete)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 42px !important;
`
