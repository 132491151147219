import React from "react"
import styled from "styled-components"
import applyFlowContainer from "container/App/applyFlowContainer"
import TickerTape from "../HeroSection/TickerTape"

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  max-height: 806px;

  ${props => props.type === "1" && `
    max-height: unset;

    @media(min-width: 768px) {
        top: 100%;
        padding-top: 145px;
    }

    @media(min-width: 1024px) {
      top: 790px;
    }

    @media(min-width: 1099px) {
      top: ${props.lpMQLFormVisible ? "680px" : "524px"} 
    }

    @media(max-width: 767px) {
      top: 100%;
      padding-top: 54px;
    }


    transition: top 0.5s ease-out;
    
  `}

  ${props => props.type === "2" && `
    background-image: url(${CLOUDFRONT}/YellowGradient.svg), url(${CLOUDFRONT}/YellowOvalGradient.svg);
    background-position: left bottom, right top;
    background-repeat: no-repeat,no-repeat;
    background-size: contain , contain;
    
    @media(min-width: 1100px) {
      max-height: 1000px;
      top: 90px;
    }

    @media(max-width: 767px) {
      max-height: 1323px;
    }
  `}

  ${props => props.type === "3" && `
      overflow: hidden;
      max-height: 418px;
      background: #F0F1FF;
      top: 772px;


      @media(min-width: 768px) {
        top: 1230px;
        max-height: 384px;
      }

       @media(min-width: 1100px) {
         top: 754px;
         max-height: 372px;
       }

       @media(min-width: 1366px) {
         top: 796px;
         max-height: 372px;
       }

      @media(max-width: 767px) {
        max-height: 367px;
        top: 1231px;
      }
  `}

  ${props => props.type === "4" && `
      background: #F3F3F3;

      @media(min-width: 1100px) {
        top: -74px;
        height: 800px;
      }
  `}

  ${props => props.type === "5" && `
      background: #F6F7FF;
      max-height: 1663px;

      @media(min-width: 1366px) {
        max-height: 1180px;
      }
  `}
`
const BackgroundMask = ({
  top,
  type,
  heroMQLFormData,
}) => (
  <Wrap
    top={top}
    type={type}
    lpMQLFormVisible={heroMQLFormData.bool}
  >
    {type === "1" && (
      <TickerTape />
    )}
  </Wrap>
)

export default applyFlowContainer(BackgroundMask)
