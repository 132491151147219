import React from "react"
import { ModalWrap } from "dumbComponents/VideoRooms/Modal/common"
import CreateEditPostContainer from "container/Network/CreateEditPost"
import Loader from "@ui/Loader"
import CreateEditPost from "./Network/CreateEditPost/index"

const PostInRadiusFeed = ({
  isFetchingSnippet,
}) => {
  return (
    <ModalWrap>
      {!isFetchingSnippet ? (
        <>
          <h3>
            Post in Radius feed
          </h3>
          <CreateEditPost />
        </>
      ) : (
        <Loader />
      )}
    </ModalWrap>
  )
}

export default CreateEditPostContainer(PostInRadiusFeed)
