import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { Field } from "redux-form"
import { required } from "services/formUtils"
import Select from "./RenderSelect"

const LabelStyles = `
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 10px;
`

const InputWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: ${props => props.width || "auto"};
  > div > div > div {
    border: 1px solid ${colors.contentText};
    box-shadow: none;
    border-radius: 8px;
    &:hover {
      border: 1px solid ${colors.deepPurple} !important;
      box-shadow: none;
    }
    &:focus {
      border: 1px solid ${colors.deepPurple} !important;
      box-shadow: 0 0 0 1px ${colors.deepPurple};
    }
  }
  > div > div div[class$="-menu"] {
    position: relative;
  }
  > div > div:first-child {
    border: 1px solid ${colors.contentText};
    border-radius: 8px;
    margin: 8px 0px;
  }
  > div {
    > label {
      ${LabelStyles};
    }
  }


`

const RadiusSelect = (props) => {
  const {
    isRequired, name, placeholder, label, width, borderRadius, options, isValid,
  } = props
  return (
    <InputWrap width={width} borderRadius={borderRadius} id="select_option">
      <Field
        name={name}
        options={options}
        component={Select}
        placeholder={placeholder}
        label={label}
        validate={isRequired ? [required] : []}
        errorStyles={{
          dropdownIndicator: base => ({
            ...base,
            color: colors.contentText,
          }),
          control: base => ({
            ...base,
            height: "52px",
            borderColor: "#ED1616 !important",
          }),
        }}
        selectStyles={{
          dropdownIndicator: base => ({
            ...base,
            color: colors.contentText,
          }),
          control: base => ({
            ...base,
            height: "52px",
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </InputWrap>

  )
}

export default RadiusSelect

