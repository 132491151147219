import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import styled from "styled-components"

const Wrap = styled.div``

const TransactionShimmer = () => (
  <Wrap>
    <ShimmerBox w="129px" h="42px" />
  </Wrap>
)

export default TransactionShimmer