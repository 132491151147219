import * as React from "react"

const Mortgage = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.8671C20 16.4081 16.587 20.6541 12 21.6851C7.413 20.6541 4 16.4081 4 11.8671V7.92805C4 7.11605 4.491 6.38405 5.243 6.07705L10.243 4.03105C11.369 3.57005 12.631 3.57005 13.757 4.03105L18.757 6.07705C19.509 6.38505 20 7.11605 20 7.92805V11.8671V11.8671Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.18506V8.18506"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.1851V16.1851"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.264 14.4701C10.523 14.8941 10.965 15.1871 11.5 15.1871H12H12.596C13.372 15.1871 14 14.5581 14 13.7831C14 13.1391 13.561 12.5781 12.937 12.4201L11.062 11.9491C10.439 11.7931 10 11.2321 10 10.5881C10 9.81208 10.629 9.18408 11.404 9.18408H12H12.5C13.033 9.18408 13.475 9.47608 13.733 9.89908"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Mortgage
