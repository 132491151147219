import React, { useEffect } from "react"
import styled from "styled-components"
import Offer from "container/TechAssets"
import OffersV2 from "container/OffersV2/index"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import CRMV2Container from "container/CRM"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import { checkForUserObject } from "services/Utils"
import Listing from "container/Listings"

const Wrap = styled.div`
  margin: 20px 0;
`
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
    margin-top: 0;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #f53f3f;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 40px 30px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ViewTransactionOffer = ({
  viewTransaction,
  setIsViewOffer,
  currentCRMDealId,
  toggleUserOverview,
  setClientID,
  getBrokerageTransactionStatus,
  getBrokerageTransactionStatusResponse,
  setEditingDealTeamMemberId,
  clearOffersRelatedData,
  clearExistingData,
  setOfferIdToView,
  setDealToView,
  clearValidateCoverSheet,
  toggleActiveTab,
  toggleDocumentTab,
  history,
  getCtIdResponse,
}) => {
  useEffect(() => {
    getBrokerageTransactionStatus({ transactionId: currentCRMDealId })
  }, [])

  const {
    data: ctIdResponse,
  } = getCtIdResponse || {}
  const {
    id: transactionId,
    req_id,
    client_id,
    client_source,
    agent_id,
  } = ctIdResponse || {}
  const { data } = getBrokerageTransactionStatusResponse || {}

  const user = checkForUserObject("user")
  const currentLoggedInUserId = user && user.id

  const isTeamMemberOrDifferentAgent = currentLoggedInUserId !== agent_id

  if (isTeamMemberOrDifferentAgent) {
    setEditingDealTeamMemberId(agent_id)
  }

  const isOfferAccepted = data.status === "offer_accepted"

  let addedQuery = `?transaction_id=${transactionId}&req_id=${req_id}&client_id=${client_id}&client_source=${client_source}&agent_id=${agent_id}`

  if (isOfferAccepted) {
    addedQuery += "&contract_phase=submitted"
  }

  const redirectUrl = `/realty/offers-v3/${data.id}${addedQuery}`

  return (
    <Wrap>
      <>
        <Header>
          <h1>View Offer</h1>
        </Header>
        <ContentContainer>
          <Content>Would you like to view this Offer?</Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={(e) => {
              e.stopPropagation()
              // setIsViewOffer(false)
              // viewTransaction(id)
              // toggleUserOverview({ showUserOverviewDrawer: false })
              // setClientID({
              //   id: "",
              //   deal_id: "",
              // })

              setIsViewOffer(false)
              clearOffersRelatedData()
              clearExistingData()
              setOfferIdToView(null)
              setDealToView(data.id)
              clearValidateCoverSheet()

              if (isOfferAccepted) {
                toggleActiveTab("contract_details")
              } else if (data.status !== "incomplete") {
                toggleActiveTab("documents")
                toggleDocumentTab("recommended_docs")
              } else {
                toggleActiveTab("cover_sheet")
                toggleDocumentTab("")
              }

              history.push(redirectUrl)
            }}
          >
            View Offer
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              setIsViewOffer(false)
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(Listing(CRMV2Container(Offer(OffersV2(ViewTransactionOffer)))))
