import React from "react"
import styled, { css } from "styled-components"
import Modal from "@ui/Modal"
import { reduxForm, Field } from "redux-form"
import Loader from "@ui/Loader"
import {
  Input,
  ButtonToggle,
  ButtonToggleItem,
  LocationAutoComplete,
  Textarea,
  PhoneInput,
} from "@ui/Form"
import root from "window-or-global"
import { get } from "lodash"
import Box from "@ui/Box"
import Button from "@ui/Button"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { required, normalizePhone, minLength } from "services/formUtils"
import Container from "container/Client"
import {
  Tag,
} from "ssr/Profile/EditOptions/commonStyles"

const minLength10 = minLength(10)

const Wrapper = styled.div`
  position: relative;
  margin-top: 50px;
  ${props => props.showLoader && `
      height:500px;
   `}
`
const AddPropertyLabel = styled.p`
 color: #d6d6d6;
 font-weight: 300;
 margin-bottom: 3px;
 margin-top: -10px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${props => props.disabled
    && css`
      opacity: 0.2;
      pointer-events: none;
    `}
`

class AddISALead extends React.Component {
  state = {
    currentLeadType: "Buyer",
  }

  handleModalClose = () => {
    const { closeISAAddClientModal } = this.props
    closeISAAddClientModal()
  }

  handleChange = (e) => {
    this.setState({
      currentLeadType: e.target.value
    })
  }

  handleAddressSelect = (address) => {
    const { change } = this.props
    const inputElement = root.document.getElementById("googleLocationInput")
    inputElement.value = ""
    const { lat, lng, sublocality_level_1: { long_name } = {} } = address
    const { selectedNeighborhood = [] } = this.state
    const locality = (
      long_name
      || get(address, "locality.long_name")
      || get(address, "administrative_area_level_2.long_name")
    )
    const neighborhood = {
      locality,
      latitude: lat,
      longitude: lng,
    }
    const updatedSelectedNeighborhood = [...selectedNeighborhood, neighborhood]
    change("locations", updatedSelectedNeighborhood)
    this.setState({
      selectedNeighborhood: updatedSelectedNeighborhood,
    })
  }

  handleAddressSelectForSeller = (address) => {
    const { change } = this.props
    this.setState({
      address,
    }, () => {
      const {
        lat,
        lng,
        administrative_area_level_1: {
          long_name: state = "",
        } = {},
        country: {
          long_name: country = "",
        } = {},
      } = address
      change("address", address.address)
      change("state", state)
      change("latitude", lat)
      change("longitude", lng)
      change("country", country)
    })
  }

  removeItem = (item) => {
    const { change } = this.props
    const { selectedNeighborhood = [] } = this.state
    const itemIndex = selectedNeighborhood.indexOf(item)
    selectedNeighborhood.splice(itemIndex, 1)
    change("locations", selectedNeighborhood)
    this.setState({
      selectedNeighborhood: [...selectedNeighborhood],
    })
  }

  render() {
    const {
      show, handleSubmit, isAddingISAClient, isReferringLead,
    } = this.props
    const {
      currentLeadType,
      selectedNeighborhood,
      address,
    } = this.state
    const showLoader = (isAddingISAClient.request || isReferringLead.request)
    return (
      <Modal
        show={show}
        toClose={this.handleModalClose}
        width="580px"
        title="ADD NEW LEAD"
        overlayStyles={{
          zIndex: 1,
        }}
      >
        <Wrapper showLoader={showLoader}>
          {showLoader ? (
            <Loader top={100} />
          ) : (
            <Form disabled={showLoader}>
              <Box d="flex" fd="column" mt="20">
                <Box mb="20">
                  <Field name="name" component={Input} type="text" label="Name" validate={required} />
                </Box>
                <Box mb="40">
                  <ButtonToggle label="Type of lead">
                    <Field
                      name="type"
                      component={ButtonToggleItem}
                      type="radio"
                      onChange={this.handleChange}
                      id="switch_left"
                      label="Buyer"
                      value="Buyer"
                      checked="true"
                    />
                    <Field
                      name="type"
                      component={ButtonToggleItem}
                      type="radio"
                      onChange={this.handleChange}
                      id="switch_right"
                      label="Seller"
                      value="Seller"
                    />
                  </ButtonToggle>
                </Box>
                <Box d="flex" mb="40">
                  <Box flex="1">
                    <Field name="emails[0].email" component={Input} type="email" label="Email" />
                  </Box>
                </Box>
                <Box d="flex" mb="40">
                  <Box flex="1">
                    <Field
                      name="phones[0].phone"
                      component={PhoneInput}
                      validate={minLength10}
                      normalize={normalizePhone}
                      inputStyle={{
                        border: "1px solid #CCC",
                        padding: "8px 16px",
                      }}
                      type="text"
                      label="Phone number"
                      labelStyles={{
                        marginBottom: "10px",
                      }}
                      ignoreOtherLabelStyles
                    />
                  </Box>
                </Box>
                <Box d="flex" mb="40">
                  <Box flex="1">
                    { currentLeadType === "Buyer" ? (
                      <div>
                        <Field
                          component={LocationAutoComplete}
                          name="locations"
                          id="googleLocationInput"
                          types={["address"]}
                          location={(addressVal) => { this.handleAddressSelect(addressVal) }}
                          placeholder="Type your Neighborhood"
                          loader={false}
                        />
                        { selectedNeighborhood && selectedNeighborhood.length > 0 && (
                          <Box>
                            {selectedNeighborhood.map(item => (
                              <Tag
                                key={item.locality || item.c_locality_id}
                                title={item.locality || item.c_locality_name}
                                handleClick={this.removeItem}
                              />
                            ))}
                          </Box>
                        ) }
                      </div>
                    ) : (
                      <div>
                        <AddPropertyLabel>Add Property</AddPropertyLabel>
                        <Field
                          component={LocationAutoComplete}
                          name="address"
                          id="cities"
                          types={["address"]}
                          location={(addressVal) => { this.handleAddressSelectForSeller(addressVal) }}
                          placeholder={address && get(address[0], "full_address.street_address", "Search by city")}
                          loader={false}
                        />
                        <Field
                          name="street_address"
                          component={Input}
                          label="Apartment, unit or suite number"
                        />
                      </div>
                    )}
                  </Box>
                </Box>
                <Box d="flex" mb="40">
                  <Box flex="1">
                    <AddPropertyLabel>Additional Notes</AddPropertyLabel>
                    <Field
                      name="notes"
                      component={Textarea}
                    />
                  </Box>
                </Box>
                <Box d="flex" jc="center" ai="center">
                  <Button
                    width="auto"
                    onClick={handleSubmit}
                    disabled={showLoader}
                  >
                    {showLoader ? "Submitting..." : "Submit"}
                  </Button>
                </Box>
                {!isAddingISAClient.request && isAddingISAClient.error && (
                  <Box d="flex" jc="center" ai="center">
                    <Paragraph color="#ff0000">{isAddingISAClient.error}</Paragraph>
                  </Box>
                )}
                {!isReferringLead.request && isReferringLead.error && (
                  <Box d="flex" jc="center" ai="center">
                    <Paragraph color="#ff0000">{isReferringLead.error}</Paragraph>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Wrapper>
      </Modal>
    )
  }
}

const AddISALeadForm = reduxForm({
  form: "ADD_ISA_LEAD",
  initialValues: { type: "Buyer" },
})(AddISALead)

export default Container(AddISALeadForm)
