import * as React from "react"

const JoinTheBrokerage = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m8 6.685 4-4 4 4"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 2.685v10.3a4 4 0 0 1-1.172 2.872L4 22.685M20 22.685l-5-5"
      />
    </svg>
  )
}

export default JoinTheBrokerage
