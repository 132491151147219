export const getStatusLabel = (stageArray, step) => {
  if (step === "audit_verified") {
    return "AUDIT VERIFIED"
  }
  if (step === "incomplete") {
    return "INCOMPLETE"
  }
  if (step === "closed") {
    return "CLOSED"
  }
  if (step === "active_listing") {
    return "ACTIVE LISTING"
  }
  if (step === "listing_verified") {
    return "LISTING VERIFIED"
  }
  if (step === "submitted") {
    return "CONTRACT SUBMITTED"
  }
  if (step === "demand_pending") {
    return "DEMAND PENDING"
  }
  if (step === "demand_completed") {
    return "DEMAND COMPLETED"
  }
  if (step === "payment_received") {
    return "COMMISSION FINALIZED"
  }
  if (step === "contract_expired") {
    return "CONTRACT EXPIRED"
  }
  if (step === "contract_canceled") {
    return "CONTRACT CANCELED"
  }
  if (step === "contract_withdrawn") {
    return "CONTRACT WITHDRAWN"
  }
  if (step === "commission_verified") {
    return "COMMISSION VERIFIED"
  }

  if (step === "gross_commission_received") {
    return "GROSS COMMISSION RECIEVED"
  }

  if (step === "agent_commission_initiated") {
    return "AGENT COMMISSION INITIATED"
  }

  if (stageArray.includes("audit_verified")) {
    return "AUDIT VERIFIED"
  }
  if (stageArray.includes("commission_verified")) {
    return "COMMISSION VERIFIED"
  }

  if (stageArray.includes("demand_pending")) {
    return "DEMAND PENDING"
  }
  if (stageArray.includes("demand_completed")) {
    return "DEMAND COMPLETED"
  }
  if (stageArray.includes("payment_received")) {
    return "COMMISSION FINALIZED"
  }

  if (stageArray.includes("submitted")) {
    return "CONTRACT SUBMITTED"
  }

  if (stageArray.includes("gross_commission_received")) {
    return "GROSS COMMISSION RECIEVED"
  }

  if (stageArray.includes === "agent_commission_initiated") {
    return "AGENT COMMISSION INITIATED"
  }

  return "INCOMPLETE"
}

export const getIsEdit = step => !step || (step
  && (step === "submitted" || step === "incomplete" || step === "audit_verified"))
