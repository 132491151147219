import React from "react"
// import { atcb_action } from "add-to-calendar-button"
// import "add-to-calendar-button/assets/css/atcb.css"
import CalendarIcon from "images/realty-v2/calendar-icon.svg"
import moment from "moment"
import styled from "styled-components"
import colors from "@colors"
import { getLiteralTimeZone } from "services/Utils"

const StyledInput = styled.button`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;

  background: none !important;
  cursor: pointer;
  border: none;
  color: ${colors.marineBLue} !important;

  padding: 2px;
  width: 208px;
  border: 1px solid ${colors.marineBLue} !important;
  border-radius: 100px;
  font-size: 17px;
  position: relative;
  left: 18px;
  top: 15px;

  @media(max-width: 767px) {
    left: unset;
  }


  ${props => props.noPosRepos && `
    position: static;
    margin-top: 15px;
  `}
`

const DURATION_MAP = {
  "15m" : 15,
  "30m": 30,
  "45m": 45,
  "1hr": 60,
}

const calculateEndTime = (time, event) => {
  if (!DURATION_MAP[time]) return moment((((event.scheduled_at + (60 * 60)) * 1000)))
  const numberValue = DURATION_MAP[time]
  return moment((((event.scheduled_at + (60 * numberValue)) * 1000)))
}

const AddToCalenderUI = ({
  event,
  noPosRepos,
  children,
}) => {

  console.log("event", event)

  const liveRoomName = event.name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ").join("-").substr(0, 50)
  const calenderEventDescription = `
    Title: Radius Event: ${event.name}

    <a href="www.radiusagent.com/scheduledrooms/${event.id}">www.radiusagent.com/scheduledrooms/${event.id}/${liveRoomName}</a>
    
    About this room:
    ${event.description || "An event hosted on Radius Rooms"}

    ${event.external_url && `
    <p>Hosted on</p>
     <a href=${event.external_url}>${event.external_url}</a>
    `}

    Speaker details:

    ${event.about_speaker && `
      About Speaker(s):
      ${event.about_speaker}
    `}
    
    * Speakers
    ${event.invited_attendees && event.invited_attendees.map((item, iter) => `
        ${iter + 1}. ${item.firstname} ${item.lastname}
    `)}
    
    Hosted by ${event.host.firstname} ${event.host.lastname} 
    
   For more details, please visit our website : <a href="www.radiusagent.com">www.radiusagent.com</a>
  `

  const config = {
    options: ["Apple", "Google", "iCal", "Microsoft365", "Outlook.com", "Yahoo"],
    // timeZone: getLiteralTimeZone(),
  }

  const addToCalenderPayload = {
    name: event.name,
    title: event.name,
    startDate: moment(event.scheduled_at * 1000),
    endDate: calculateEndTime(event.event_duration, event),
    description: calenderEventDescription,
    iCalFileName: `Radius-${event.name}`,
  }

  const finalConfig = {
    ...addToCalenderPayload,
    ...config,
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        // atcb_action(finalConfig)
      }}
    >
      {children || (
        <StyledInput
          type="submit"
          value="Add to Calendar"
          noPosRepos={noPosRepos}
        >
          <img src={CalendarIcon} alt="calender" />
          {" "}
          Add to Calendar
        </StyledInput>
      )}
    </form>
  )
}

export default AddToCalenderUI
