import React, { useEffect } from "react"
import OffersV2 from "container/OffersV2"
import createToast from "dumbComponents/common/Toast/customToast"
import TechAssets from "container/TechAssets"
import { Form } from "antd"
import Loader from "@ui/Loader"
import root from "window-or-global"
import {
  getState,
  DEAL_TERMS_STEP,
} from "dumbComponents/OffersV2/consts"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  ListWrap,
  ListRowWrap,
  StateDiv,
  State,
  StateWrap,
  GreyState,
} from "./styles"
import {
  DealTermsForm,
} from "./Forms"

const DealTerms = ({
  dealTermObject,
  updateDealTermsInformationTab,
  dealToView,
  stateForOffer,
  updateDealTermsInformationTabResponse,
  fetchDealTermsInformation,
  fetchDealTermsInformationResponse,
  propertyInformationObject,
  updatePropertyInformationTab,
  buyersAgentInformationObject,
  updateBuyerInformationTab,
  propertyFormRef,
  listingAgentFormRef,
  sellerFormRef,
  buyerFormRef,
  buyerAgentFormRef,
  propertyInformationFormRef,
  listingAgentInformationFormRef,
  sellerInformationFormRef,
  buyerInformationFormRef,
  buyerAgentInformationFormRef,
  financingInformationFormRef,
  vendorInformationFormRef,
  dealTermsFormRef,
  setCoversheetErrorsList,
}) => {
  const {
    isFetching: updatingDealTerms,
  } = updateDealTermsInformationTabResponse || {}
  const deal_id = dealToView
  const {
    form,
    // type_of_transaction,
  } = dealTermObject || {}

  const [DEAL_TERMS_FORM_REF] = Form.useForm()

  useEffect(() => {
    const payload = {
      id: dealToView,
      current_offer_step: DEAL_TERMS_STEP,
    }
    fetchDealTermsInformation({
      id: deal_id,
      current_offer_step: DEAL_TERMS_STEP,
    })
  }, [])

  const {
    isFetching: fetchingDealTerms,
    data,
  } = fetchDealTermsInformationResponse || {}

  const validateAllForms = async () => {
    // Default scroll options
    const scrollOptions = {
      block: "end",
      scrollMode: "always",
      behavior: (actions) => {
        const [{ el, top }] = actions
        el.scrollTop = top - 200
        return "smooth"
      },
    }

    // Check if all form refs exist
    const formConfigs = [
      { ref: propertyInformationFormRef, name: "Property's information" },
      { ref: listingAgentInformationFormRef, name: "Listing agent's information" },
      { ref: sellerInformationFormRef, name: "Seller's information" },
      { ref: buyerInformationFormRef, name: "Buyer's information" },
      { ref: buyerAgentInformationFormRef, name: "Buyer's agent information" },
      { ref: financingInformationFormRef, name: "Financing information" },
      { ref: vendorInformationFormRef, name: "Escrow and title information" },
      { ref: dealTermsFormRef, name: "Deal details" },
    ]

    // const testPropertyInformationFormRef = propertyInformationFormRef.getFieldsValue()
    // const testListingAgentInformationFormRef = listingAgentInformationFormRef.getFieldsValue()
    // const testSellerInformationFormRef = sellerInformationFormRef.getFieldsValue()
    // const testBuyerInformationFormRef = buyerInformationFormRef.getFieldsValue()
    // const testBuyerAgentInformationFormRef = buyerAgentInformationFormRef.getFieldsValue()
    // const testFinancingInformationFormRef = financingInformationFormRef.getFieldsValue()
    // const testVendorInformationFormRef = vendorInformationFormRef.getFieldsValue()
    // const testDealTermsFormRef = dealTermsFormRef.getFieldsValue()

    try {
      // Validate all forms and collect their errors
      const validationResults = await Promise.all(
        formConfigs.map(async ({ ref, name }) => {
          try {
            await ref.validateFields()
            return { isValid: true, errors: [] }
          } catch (error) {
            return {
              isValid: false,
              errors: error.errorFields.map(field => ({
                formName: name,
                formRef: ref,
                fieldName: field.name.join("."),
                fieldNamePath: field.name,
                errors: field.errors,
                isChecked: false,
              })),
            }
          }
        })
      )

      // Collect all errors
      const allErrors = validationResults.flatMap(result => result.errors).filter(Boolean)

      if (allErrors.length > 0) {
        // Group errors by form name
        const groupedErrors = allErrors.reduce((acc, error) => {
          if (!acc[error.formName]) {
            acc[error.formName] = {
              formRef: error.formRef,
              fields: [],
            }
          }

          acc[error.formName].fields.push({
            fieldName: error.fieldName,
            fieldNamePath: error.fieldNamePath,
            isChecked: error.isChecked,
            errors: error.errors,
          })

          return acc
        }, {})

        // Convert to array format
        const formattedErrors = Object.entries(groupedErrors).map(([formName, item]) => ({
          formName,
          formRef: item.formRef,
          fields: item.fields,
        }))

        // Create and scroll to error message
        // const errorMessage = allErrors
        //   .map(error => `${error.formName}: ${error.fieldName} - ${error.errors.join(", ")}`)
        //   .join("\n")
        // if (allErrors[0]?.fieldName) {
        //   formConfigs.forEach(({ ref }) => {
        //     ref?.scrollToField(allErrors[0].fieldName, scrollOptions)
        //   })
        // }

        return {
          isValid: false,
          errors: formattedErrors,
        }
      }

      console.log("All forms validated successfully")
      return {
        isValid: true,
        errors: [],
      }
    } catch (error) {
      console.log("Form validation failed:", error)
      return {
        isValid: false,
        errors: [],
      }
    }
  }

  const handleFinalSubmit = async () => {
    const result = await validateAllForms()

    setCoversheetErrorsList(result)

    if (result.isValid) {
      console.log("All forms are valid")
      customToast("Validating coversheet, please wait.")

      const buyerInformationObject = buyerInformationFormRef.getFieldsValue()
      const dealTermsObject = dealTermsFormRef.getFieldsValue()

      const {
        type_of_transaction,
        listing_agents: currentListingAgents,
        sellers: currentSellers,
        form: currentForm,
      } = propertyInformationObject || {}

      if (!type_of_transaction || type_of_transaction === undefined || type_of_transaction === null) {
        root.scrollTo(0, 0)
        customToast("Please select a property type", "error")
        return
      }

      const payload = {
        ...dealTermsObject,
        down_payment_amount: dealTermsObject.balance_of_down_payment || 0, //fix shrungi
        current_offer_step: DEAL_TERMS_STEP,
        new_route: true,
        draft: 0,
        type_of_transaction,
        other_fees: [dealTermsObject.other_fees_1, dealTermsObject.other_fees_2] || [],
      }

      const listingAgentsPayload = currentListingAgents.map((item, iter) => ({
        is_primary: iter === 0 ? 1 : 0,
        agent_id: item.agent_id || 0, //fix shrungi
        is_radius_user: item.agent_id ? true : false,
        name: `${item.listing_agent_first_name} ${item.listing_agent_last_name}`,
        email: item.email || null,
        agent_license_number: item.agent_license_number || null,
        landline: item.landline || null,
        phone: item.phone || null,
        brokerage: item.brokerage || null,
        brokerage_license_number: item.brokerage_license_number || null,
        brokerage_address: item.brokerage_address || null,
        apt: item.apt || null,
        city: item.city || null,
        state: item.state || null,
        zipcode: item.zipcode || null,
      }))

      const sellerPayload = currentSellers.map((item, iter) => ({
        is_primary: iter === 0 ? 1 : 0,
        firstname: item.firstname,
        email: item.email,
        phone: item.phone,
        lastname: item.lastname,
        type_of_ownership: item.type_of_ownership,
        ownership_name: item.ownership_name,
      }))

      const propertyPayload = {
        clients: [
          ...sellerPayload,
        ],
        agents: [
          ...listingAgentsPayload,
        ],
        mls_select: "",
        mls_number: "",
        apn_number: "",
        street: "",
        apt: "",
        city: "",
        state: getState(stateForOffer),
        zipcode: "",
        county: "",
        ...propertyInformationObject,
        ...currentForm[stateForOffer],
        type_of_transaction,
        draft: 0,
        new_route: true,
        current_offer_step: "property_information_mobile",
      }

      const {
        buyers: currentBuyers,
        agents: currentAgents,
        selectedVendor,
        selected_vendor_id,
        selectedLender,
        selected_lender_id,
        vendors,
        lenders: currentLenders,
        financingType: newFinanceType,
        source,
        other_source,
      } = buyersAgentInformationObject || {}
      const isAllCashOrSelfFinancing = !selected_lender_id
      && newFinanceType === "cash"
      || newFinanceType === "seller_financing" ? 1 : 0

      if (newFinanceType === null || !newFinanceType || newFinanceType === undefined) {
        return
      }

      const buyerAgentsPayload = currentAgents.map((item, iter) => ({
        agent_id: item.agent_id,
        is_primary: iter === 0 ? 1 : 0,
        name: `${item.agent_first_name ? `${item.agent_first_name} ${item.agent_last_name}` : `${item.name}`}`,
        email: item.email,
        phone: item.phone,
        landline: "",
        agent_license_number: item.agent_license_number,
        brokerage: item.brokerage,
        brokerage_license_number: item.brokerage_license_number,
        brokerage_address: item.brokerage_address,
        apt: item.buyer_apartment_number,
        city: item.city,
        state: item.state,
        zipcode: item.zipcode,
      }))

      const {
        co_buyer_details,
        type_of_ownership: typeOfOwnershipForm,
      } = buyerInformationObject || {}

      const clientsPayload = currentBuyers.map((item, iter) => ({
        client_id: item.client_id ? item.client_id : null,
        req_id: item.req_id,
        transaction_id: item.transaction_id,
        is_primary: iter === 0 ? 1 : 0,
        firstname: item.firstname ? item.firstname : null,
        lastname: item.lastname ? item.lastname : null,
        email: item.email ? item.email : null,
        landline: null,
        phone: item.phone ? item.phone : null,
        address: item.address ? item.address : null,
        apt: item.apt ? item.apt : null,
        city: item.city ? item.city : null,
        state: item.state ? item.state : null,
        zipcode: item.zipcode ? item.zipcode : null,
        //type_of_ownership: item.type_of_ownership ? item.type_of_ownership : null,
        type_of_ownership: iter === 0 ? (item.type_of_ownership ? (item.type_of_ownership) : (typeOfOwnershipForm || null)) : (item.type_of_ownership ? item.type_of_ownership : co_buyer_details[iter - 1]?.type_of_ownership || null),
      }))

      const buyerPayload = {
        agents: [...buyerAgentsPayload],
        buyers: [...clientsPayload],
        draft: 0,
        new_route: true,
        lender_id: selected_lender_id,
        vendor_id: selected_vendor_id,
        financing_information: newFinanceType,
        source,
        other_source,
        is_all_cash: isAllCashOrSelfFinancing,
        current_offer_step: "buyers_information_mobile",
      }

      updateBuyerInformationTab({
        id: dealToView,
        payload: buyerPayload,
        noFetch: true,
      })

      //clean
      delete propertyPayload.sellers
      delete propertyPayload.listing_agents
      delete propertyPayload.form
      delete propertyPayload.currentState
      delete propertyPayload.price

      updatePropertyInformationTab({
        id: dealToView,
        payload: propertyPayload,
        doNotReload: true,
        callback: (id) => {
          updateDealTermsInformationTab({
            id,
            payload,
            noFetch: true,
          })
        },
      })
    } else {
      // Handle validation errors
      console.log("Validation failed:", result.errors)

      root.window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <Wrap>
      {fetchingDealTerms ? (<Loader top="0" bottom="0" style={{ margin: "100px auto" }} />)
        : (
          <SectionWrap>
            <ListRowWrap>
              <SectionTitle>
                <p>Deal Details</p>
              </SectionTitle>
              <StateWrap>
                <GreyState>
                  <p>State</p>
                </GreyState>
                <State>
                  <h2>{getState(stateForOffer)}</h2>
                </State>
              </StateWrap>
            </ListRowWrap>
            <ListWrap>
              <DealTermsForm
                prefilledData={form[stateForOffer]}
                state={stateForOffer}
                onFinish={handleFinalSubmit}
                updatingDealTerms={updatingDealTerms}
                fetchingDealTerms={fetchingDealTerms}
                initialValues={data}
                dealTermsFormRef={dealTermsFormRef}
              />
            </ListWrap>
          </SectionWrap>
        )}
      {/*Deal Terms*/}
    </Wrap>
  )
}

export default OffersV2(TechAssets(DealTerms))
