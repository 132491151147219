import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.div`
  font-size: 12px;
  font-weight: 100;
  margin: 4px 0px;
  width: 400px;
  word-break: initial;
  white-space: pre-line;
  line-height: normal;
`
const LinkA = styled.a`
  text-decoration: none;

  ${props => props.disable && `
    color: ${colors.contentText};
    pointer-events: none;
  `}
`

const AcademyIntrsuctors = ({
  contentType,
  course,
  event,
}) => {
  const isCourse = contentType === "course"
  const payload = isCourse ? course : event
  const { Instructors, title } = payload || {}
  const createLink = () => (
    <React.Fragment>
      {Instructors && Instructors.length > 0 && Instructors.map(item => (
        <LinkA href={item.radius_agent_handle} target="_blank" disable={!item.radius_agent_handle}>
          {item.name && item.name.trim()}
        </LinkA>
      )).reduce((prev, curr) => [prev, ", ", curr])}
    </React.Fragment>
  )

  return (
    <Wrap>
      {(contentType === "course" || contentType === "event") && (
        <React.Fragment>
          {contentType === "event" ? "Hosted by " : "Instructed by "}
          {createLink()}
        </React.Fragment>
      )}
      {contentType === "event_feedback" && title}
    </Wrap>
  )
}

export default AcademyIntrsuctors
