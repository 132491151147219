import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import Button from "@ui/Button"
import { Field } from "redux-form"
import { required } from "services/formUtils"
import { Select } from "@ui/Form"
import {
  FormTitle,
} from "../common"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 400px;
`

const ErrorSpan = styled.span`
  font-size: 10px;
  color: red;
  position: relative;
  left: 0;
  top: -11px;
`

const Spacer = styled.div`
  margin: 10px 0px 10px 0px;
`

const FieldWrap = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 10px;
`

const FormWrap = styled.div``

const timeRenderField = ({ input, type, meta: { touched, error } }) => {
  return (
    (
      <div
        style={{
          width: "100%",
          marginBottom: "15px",
        }}
      >
        <p>Input Time</p>
        <input
          style={{
            width: "100%",
            height: "40px",
            border: "none",
            padding: "0px 10px",
          }}
          {...input}
          type={type}
        />
        {touched && error && <ErrorSpan>{error}</ErrorSpan>}
      </div>
    )
  )
}

const renderField = ({ input, type, meta: { touched, error } }) => {
  const minDate = new Date().toISOString().slice(0, 16)
  return (
    (
      <div>
        <p>Input Date</p>
        <input
          style={{
            width: "100%",
            height: "50px",
            border: "none",
            padding: "0px 10px",
            marginBottom: "10px",
          }}
          {...input}
          type={type}
          min={minDate}
        />
        {touched && error && <ErrorSpan>{error}</ErrorSpan>}
      </div>
    )
  )
}

const TimeSelectionUI = ({
  frequencyOptions,
  durationOptions,
}) => (
  <Wrap>
    <FormTitle>
      <p>Schedule Time</p>
    </FormTitle>
    <FormWrap>
      <Field
        name="scheduled_at"
        type="date"
        component={renderField}
        validate={required}
        // normalize={convertToUnix}
      />
      <Field
        name="scheduled_at_time"
        type="time"
        validate={required}
        component={timeRenderField}
        // normalize={convertToUnix}
      />
      <Field
        component={Select}
        options={durationOptions}
        placeholder="Room's duration"
        name="event_duration"
      />
      <Spacer />
      <Field
        component={Select}
        options={frequencyOptions}
        placeholder="Frequency"
        name="frequency"
        validate={required}
      />
    </FormWrap>

  </Wrap>
)

export default TimeSelectionUI

