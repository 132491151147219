import moment from "moment"

export const dataGenerator = (listingsData) => {
  if (listingsData === null || listingsData === undefined || listingsData.length <= 0) return []

  const mapped = listingsData.map(item => (
    {
      ...item,
      id: item.id,
      seller_firstname: item.seller_firstname,
      seller_lastname: item.seller_lastname,
      list_price: item.list_price,
      price: item.price,
      overview_price: item.overview_price,
      listing_expiration_date: getDate(item.listing_expiration_date) || "-",
      date_of_listing_submission: getNonMultipliedTo1000Date(item.date_of_listing_submission) || "-",
      listing_on_market_date: getDate(item.listing_on_market_date) || "-",
    }))

  return mapped || []
}

export const getDate = (date) => {
  if (!date || date === undefined || date === null) return ""
  const formatted = moment(date * 1000).format("DD MMM YYYY")
  return formatted
}

export const getFullDate = (date) => {
  if (!date || date === undefined || date === null) return ""
  const formatted = moment(date * 1000).format("MMMM D, YYYY")
  return formatted
}

export const getNonMultipliedTo1000Date = (date) => {
  if (!date || date === undefined || date === null) return ""
  const formatted = moment(date).format("DD MMM YYYY")
  return formatted
}

export const parseUTCDate = (date) => {
  if (!date || date === undefined || date === null) return ""
  const formatted = moment(date).format("MMMM D, YYYY")
  return formatted
}
