import React from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import colors from "@colors"
import { ATC } from "@fonts"
import LazyLoad from "react-lazyload"

const desc = `“Finding a brokerage to fill in the gaps is what brought me to Radius. From the educational
              perspective to understanding new agents and providing them with the tech platforms to plug into.
              There’s a lot of freedom and a lot of support, while they constantly wonder how to make the agent’s
              job better, or easier, or more fluid.”`

const Wrap = styled.div`
  padding: 60px;

  @media(max-width: 767px) {
    padding: 40px 20px;
  }

  @media(max-width: 425px) {
    padding: 20px;
    margin-bottom: 50px;
  }
`

const ContentWrap = styled.div`
  max-width: 1440px;
  margin: auto;

    @media(max-width: 425px) {
      font-size: 32px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media(max-width: 1099px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    > iframe {
      max-width: 637px;
      width: 100%;
    }
  }
`

const QuoteWrap = styled.div`
  > div {
    max-width: 635px;
    width: 100%;
    > p {
      font-size: 24px;
      line-height: 35px;
      color: #303030;
      font-family: ${ATC};

      @media(max-width: 1099px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > h2 {
      font-size: 22px;
      color: #303030;
      font-family: ${ATC};
      font-weight: 200;
      margin-top: 60px;
      @media(max-width: 1099px) {
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }

    > small {
      font-size: 16px;
      color: #303030;
      font-family: ${ATC};
      font-weight: 200;
    }
  }
`

const ButtonWrap = styled.div`
  @media(max-width: 1099px) {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`

const AgentTestimonial = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <div>
          <QuoteWrap>
            <div>
              <p>
                {desc}
              </p>
              <h2>
                Chris Strange and James Quintero
              </h2>
              <small>
                Rise Group Team Leads
              </small>
            </div>
            <ButtonWrap>
              <Button
                style={{
                  width: "180px",
                  padding: 10,
                  background: `${colors.marineBLue}`,
                  border: "none",
                  borderRadius: "0px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                Apply Now
              </Button>
            </ButtonWrap>
          </QuoteWrap>
          <LazyLoad offset={220}>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/pC0QTfl75gQ?rel=0&controls=0&modestbranding=1&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </LazyLoad>
        </div>
      </ContentWrap>
    </Wrap>
  )
}

export default AgentTestimonial
