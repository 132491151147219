import React from "react"
import root from "window-or-global"
import { enabledFeatureCheck } from "services/Utils"
import { InnerTab, NavLink } from "./commonStyles"
import Listings from "../Icons/NewIcons/Listings"

const SubmitListingsTab = ({ routeArray, isBroker }) => (
  <InnerTab isActive={routeArray[1] === "listings"}>
    <div className="icon-block-new">
      <Listings />
    </div>
    <div className="text-block">
      <NavLink
        to={
          enabledFeatureCheck({ isBroker, tabName: "transaction_management_listing" })
            ? "/realty/listings"
            : "/realty/promos?type=listings"
        }
        isActive={routeArray[1] === "listings"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_productAndServicesRAR_web")
          }
        }}
      >
        My Listings
        {/* {CookiesStorage.load("submitListings") && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )} */}
      </NavLink>
    </div>
  </InnerTab>
)

export default SubmitListingsTab
