/*
  author - Saurav Tiru
  This file contains common code for Div.
  DO NOT EDIT THIS FILE DIRECTLY.

  1) Custom Styling
    Use "customDivStyling" prop to pass custom div style properties.

  2) Additional Properties
    Request author to verify additional properties before merging.

  3) Media Queries
    Do not change Media Query widths.
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RadiusLogo from "images/home-v7/new-radius-logo.png"

const Wrapper = styled.div`

  /*Wrapper Styles*/
  display: ${props => props.displayStyle};
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border || "none"};
  border-radius: ${props => props.borderRadius};
  box-sizing: ${props => props.boxSizing};
  overflow: ${props => props.overflow};
  opacity: ${props => props.opacity || "1"};
  visibility: ${props => props.visibility};
  box-shadow: ${props => props.boxShadow || "none"};

  /*MARGIN PROPERTIES*/
  margin: ${props => props.margin};


  /*POSITIONAL BORDER PROPERTIES*/
  border-bottom: ${props => props.borderBottom};
  border-top: ${props => props.borderTop};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};


  /*PADDING PROPERTIES*/
  padding: ${props => props.padding};

  /*SIZE PROPERTIES*/
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};

  
  /*Background Image Styles*/
  ${props => props.isImage && `
    background: url(${props.image || RadiusLogo});
    background-repeat: ${props.backgroundImageRepeat || "no-repeat"};
    background-size: ${props.backgroundImageSize || "contain"};
    filter: ${props.imageFilter || "none"};
    background-position: ${props.backgroundImagePosition || "center"};
  `}

  /*Positional Styling*/
  position: ${props => props.elementPosition || "initial"};
  float: ${props => props.elementFloatPost || "initial"};
  top: ${props => props.elementTopPos || "unset"};
  right: ${props => props.elementRightPos || "unset"};
  bottom: ${props => props.elementBottomPos || "unset"};
  left: ${props => props.elementleftPos || "unset"};

  /*Flex Styles*/
  ${props => props.displayStyle === "flex" && `
    flex-direction: ${props.flexDirection || "unset"};
    flex-grow: ${props.flexGrow || "1"};
    justify-content: ${props.justifyContent || "initial"};
    align-items: ${props.alignItems || "initial"};
    flex-wrap: ${props.flexWrap || "nowrap"};
    gap: ${props.flexGap};
  `}

  /*WRAPPER MEDIA QUERY STYLING*/
  
  @media(min-width: 1100px) {
    display: ${props => props.displayMinDesktopStyle || props.displayStyle};
    padding: ${props => props.paddingMinDesktop};
  }

  @media(min-width: 1400px) {
    display: ${props => props.displayMaxDesktopStyle || props.displayStyle};
    padding: ${props => props.paddingLargeDesktop}
  }


  @media(max-width: 1099px) {
    display: ${props => props.displayTabletStyle || props.displayStyle};
    padding: ${props => props.paddingTablet};
  }

  @media(max-width: 767px) {
    display: ${props => props.displayMobileStyle || props.displayStyle};
    padding: ${props => props.paddingMobile};
  }   
`

const Box = (props) => {
  const {
    customDivStyling,
    children,
  } = props || {}

  return (
    <Wrapper
      {...props}
      style={{
        ...customDivStyling || {},
      }}
    >
      {children || null}
    </Wrapper>
  )
}

Box.propTypes = {
  /**
   * Box Size
   */
  boxSizing: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  paddingLargeDesktop: PropTypes.string,
  paddingMinDesktop: PropTypes.string,
  paddingTablet: PropTypes.string,
  paddingMobile: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  borderRadius: PropTypes.string,
  displayStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  overflow: PropTypes.string,
  flexGap: PropTypes.string,
}

Box.defaultProps = {
  boxSizing: "inherit",
  margin: "unset",
  padding: "unset",
  paddingLargeDesktop: "unset",
  paddingMinDesktop: "unset",
  paddingTablet: "unset",
  paddingMobile: "unset",
  width: "100%",
  height: "100%",
  maxWidth: "unset",
  maxHeight: "unset",
  borderRadius: "inherit",
  displayStyle: "block",
  backgroundColor: "transparent",
  overflow: "unset",
  flexGap: "0px",
}

export default Box
