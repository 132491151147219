import React from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import EventList from "../../Components/EventList"
import { SubHeader } from "./commonStyles"

const Wrap = styled.div`
  margin-top: 53px;
  margin-bottom: 20px;
  max-width: 1036px;
`

const Similar = ({
  getRoomTopicEventsResponse,
  getPreRecordedDetailsResponse,
  getRoomTopicsResponse,
}) => {
  const {
    data: video,
  } = getPreRecordedDetailsResponse || {}

  const { data: RoomTopicEventData } = getRoomTopicEventsResponse || {}
  const { sortedEvents } = RoomTopicEventData || {}

  const { data: RoomTopicData } = getRoomTopicsResponse || {}

  const Topic = RoomTopicData && RoomTopicData.filter(item => item.id === video.topic_id)[0]

  return (
    <Wrap>
      <SubHeader>
        Similar Rooms
      </SubHeader>
      <EventList
        id={video.topic_id}
        sortedEvents={sortedEvents && sortedEvents[`${Topic.topic_name.split(" ").join("_")}`]}
      />
    </Wrap>
  )
}

export default Rooms(Similar)
