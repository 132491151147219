import produce from "immer"
import { filterMapArray } from "dumbComponents/Search/FilterPanel"
import * as SearchInputAction from "./actions"

export const FILTERS_SHOW_COUNT = 5

const initState = {
  isFetchingAgents: false,
  showFilterPanel: false,
  agents: [],
  agentsTotalCount: null,
  facades: {},
  keyedFacades: {},
  keyedSingleFilters: {},
  appliedFilters: {},
  unappliedFilters: {},
  appliedFiltersKeyPayload: {},
  appliedSingleFilterPayload: [],
  totalPages: null,
  currentPageNumber: null,
  queryParams: {},
  filterSelectedCategory: filterMapArray[0].key,
  lastFilterItemIndex: FILTERS_SHOW_COUNT,
  filterSearchText: "",
  isFetchingClientsByName: false,
  clientsByName: [],
}

const searchReducer = (state = initState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SearchInputAction.toggleFilterPanel.type: {
      const showFilterPanel = action.data
      draft.showFilterPanel = showFilterPanel
      return
    }

    case SearchInputAction.getSearchedAgents.REQUEST: {
      draft.isFetchingAgents = true
      return
    }

    case SearchInputAction.getSearchedAgents.SUCCESS: {
      const {
        agents,
        agentsTotalCount,
        facades,
        totalPages,
        currentPageNumber,
        randomSeed,
        queryParams,
      } = action.data
      draft.isFetchingAgents = false
      draft.agents = agents
      draft.currentPageNumber = currentPageNumber
      if (agentsTotalCount) {
        draft.agentsTotalCount = agentsTotalCount
      }
      if (facades) {
        draft.facades = facades
      }
      if (totalPages) {
        draft.totalPages = totalPages
      }
      if (randomSeed) {
        draft.randomSeed = randomSeed
      }
      if (queryParams) {
        draft.queryParams = queryParams
      }
      return
    }

    case SearchInputAction.updateAppliedFiltersPayload.type: {
      const { appliedFiltersKeyPayload, appliedSingleFilterPayload } = action.data
      draft.appliedFiltersKeyPayload = appliedFiltersKeyPayload
      draft.appliedSingleFilterPayload = appliedSingleFilterPayload
      return
    }

    case SearchInputAction.initFilterData.type: {
      const { keyedFacades, appliedFilters, unappliedFilters, keyedSingleFilters } = action.data
      draft.keyedFacades = keyedFacades
      draft.appliedFilters = appliedFilters
      draft.unappliedFilters = unappliedFilters
      draft.keyedSingleFilters = keyedSingleFilters
      draft.lastFilterItemIndex = FILTERS_SHOW_COUNT
      return
    }

    case SearchInputAction.updateFiltersOnAction.type: {
      const { filterCategory, newAppliedFilterArray, newUnappliedFilterArray } = action.data
      draft.appliedFilters[filterCategory] = newAppliedFilterArray
      draft.unappliedFilters[filterCategory] = newUnappliedFilterArray
      return
    }

    case SearchInputAction.changeAndResetFilterCategory.type: {
      const { filterSelectedCategory } = action.data
      draft.filterSelectedCategory = filterSelectedCategory
      draft.lastFilterItemIndex = FILTERS_SHOW_COUNT
      return
    }

    case SearchInputAction.showMoreFiltersForCategory.type: {
      draft.lastFilterItemIndex += FILTERS_SHOW_COUNT
      return
    }

    case SearchInputAction.updateSingleFilterPayload.type: {
      const { filterKey } = action.data
      draft.keyedSingleFilters[filterKey] = !state.keyedSingleFilters[filterKey]
      return
    }

    case SearchInputAction.getClientsByName.REQUEST: {
      draft.isFetchingClientsByName = true

      return
    }

    case SearchInputAction.getClientsByName.SUCCESS: {
      const { clients } = action.data

      draft.isFetchingClientsByName = false
      draft.clientsByName = clients

      return
    }

    default:
      return state
  }
})

export default searchReducer

