import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import colors from "@colors"
import icons from "@icons"
import { MOBILE_BREAK_POINT } from "shared/consts"
import LinkButton from "@ui/LinkButton"

const Wrap = styled.div`
  background: ${colors.shallowGrey};
  font-size: 14px;
  color: #46536a;
  display: flex;
  padding: 15px;

  & > p {
    flex: 1;
    text-align: center;
    margin: 0px;
    padding: 0px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    & > p > span {
      display: none;
    }
  }
`

const CloseButton = styled.div`
`

class InviteBanner extends React.PureComponent {

  componentWillUnmount() {
    const { toggleInviteBanner } = this.props
    toggleInviteBanner(false)
  }

  render() {
    const {
      toggleInviteBanner,
      inviteeResponse,
    } = this.props
    return (
      <Wrap>
        <p>
          {`${inviteeResponse.firstname} invited you to try Radius Assist.`}
          <span>You’ll get a $50 Amazon gift card when you subscribe.</span>
          <LinkButton onClick={() => { root.document.getElementById("pricing-section").scrollIntoView() }}>
            Get started
          </LinkButton>
          .
        </p>
        <CloseButton
          onClick={() => {
            toggleInviteBanner(false)
          }}
        >
          <i className={icons.arrowsClose}></i>
        </CloseButton>
      </Wrap>
    )
  }
}

export default InviteBanner
