import React from "react"

const RenderHiddenField = (field) => {
  const {
    placeholder: defaultValue,
  } = field

  return (
    <input name="unique_form_submitted" type="hidden" defaultValue={defaultValue || "misc_form_update_dev"} />
  )
}

export default RenderHiddenField
