import React from "react"
import {
 Input,
} from "@ui/antd"
import {
  Wrap,
  InputSearchWrap,
} from "./styles"

const { Search } = Input

const InputSearch = ({
  onSearch,
  style,
  placeholder,
  noWidth,
  enterButton = false,
  defaultValue,
  borderColor
}) => (
  <Wrap
    noWidth={noWidth}
  >
    <InputSearchWrap
      borderColor={borderColor}
      placeholder={placeholder}
      onSearch={onSearch}
      style={style}
      enterButton={enterButton}
      defaultValue={defaultValue?.length ? defaultValue : ""}
    />
  </Wrap>
  
)

export default InputSearch

