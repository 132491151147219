import React from "react"
import Select from "react-select"
import styled from "styled-components"
import Box from "@ui/Box"

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
`
const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #d4d4d4;
  font-weight: 300;
  margin: 5px 0px;
`

const RenderSelect = (field) => {
  const {
    input: {
      onFocus,
    } = {},
    input,
    options,
    config,
    placeholder,
    meta,
    label,
    defaultValue,
    labelStyles = {},
  } = field
  return (
    <Box onFocus={onFocus}>
      {label && <Label styles={{ ...labelStyles }}>{label}</Label>}
      <Select
        {...config}
        value={(input.value.value)}
        onChange={(item) => {
          input.onChange(item)
        }}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </Box>
  )
}

export default RenderSelect
