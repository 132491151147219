import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
`

export const TitleWrap = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 0.897px solid var(--Style, #5A5FF2);
  background: #FFF;
`

export const TitleLeftWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TitleLeft = styled.p`
  margin: 0;
  width: max-content;
  color: var(--BG-Dark-mode, #292A37);
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  letter-spacing: 0.681px;
`

export const TitleRight = styled.p`
  margin: 0;
  color: #26293F;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
export const Description = styled.p`
  color: #26293F;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`

export const ImgWrap = styled.div`
  height: 24px;
  width: 24px;

  img {
    height: 100%;
    width: 100%;
  }
`
