import React from "react"
import styled, { css } from "styled-components"
import Container from "container/Leaderboard"
import colors from "@colors"
import Header from "./FilterHeader"
import Filters from "./FilterValue"

const Wrapper = styled.div`
  background: ${colors.white};
  position: fixed;
  top: 70px;
  right: 0px;
  bottom: 0px;
  width: 400px;
  height: 100%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  right: -400px;
  z-index: 10000;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0,0,0,0.3);

  ${props => props.isFilterOpen
    && css`
      right: 0px;
      width: 100%;
    `}
`

const FilterWrapper = styled.div`
  height: 100%;
  padding: 20px;
  background: ${colors.white};   
  position: fixed;
  right: -400px;
  transition-duration: 0.5s;
  ${props => props.isFilterOpen
    && css`
      right: 0px;
      width: 400px;
    `}
`

const Overlay = styled.div`
  width: calc(100vw - 400px);
  height: 100%;
  right: 400px;
`

const FiltersPanel = ({
  isFilterOpen,
  response,
  filterMap,
  toggleFilterComponent,
  getLeaderBoardLists,
  showMonth,
  activeTab,
  activeCategory,
  setTabActive,
  setActiveCategory,
  month,
  year,
}) => {
  const {
    data: filters,
    isFetching: fetchingFilters,
  } = response || {}

  return (
    <Wrapper isFilterOpen={isFilterOpen}>
      <Overlay onClick={() => toggleFilterComponent(false)} />
      <FilterWrapper isFilterOpen={isFilterOpen}>
        <Header toggleFilterComponent={toggleFilterComponent} />
        <Filters
          filters={filters && filters.response || []}
          fetchingFilters={fetchingFilters}
          filterMap={filterMap}
          getLeaderBoardLists={getLeaderBoardLists}
          showMonth={showMonth}
          activeTab={activeTab}
          activeCategory={activeCategory}
          setTabActive={setTabActive}
          setActiveCategory={setActiveCategory}
          month={month}
          year={year}
        />
      </FilterWrapper>
    </Wrapper>
  )
}

export default Container(FiltersPanel)
