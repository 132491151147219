import React, { useEffect } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Rooms from "container/Rooms"
import { isEmpty, get } from "lodash"
import root from "window-or-global"
import { authCheck } from "services/auth"
import { Redirect } from "react-router-dom"
import CreateEditPostContainer from "container/Network/CreateEditPost"
import App from "container/App"
import ShareModal from "dumbComponents/common/ShareModal"
import Footer from "dumbComponents/common/FooterNew"
import ShareRoomModal from "dumbComponents/EventRooms/ShareRoomModal"
import RoomsModal from "dumbComponents/VideoRooms/Modal/RoomsModal"
import PostInRadiusFeed from "dumbComponents/PostInRadiusFeed"
import CookiesStorage from "services/CookieStorage"

import {
  ShowcaseHeader,
  Player,
  Details,
  Similar,
  Shimmer,
} from "./Components"

const SEOImage = "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_SEOimage.png"

const Wrap = styled.div`
  @media(min-width: 1099px) {
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`

const ContentWrap = styled.div`
  padding-top: 83px;
  min-width: 100%;
`

const Showcase = ({
  getPreRecordedContentDetails,
  getPreRecordedDetailsResponse,
  getRoomTopicEvents,
  getRoomTopics,
  isAppMounted,
  isShowPostInRadiusFeed,
  togglePostInRadiusFeed,
  toggleShareLinkModal,
  isShareRoomModal,
}) => {
  const {
    isFetching: fetching,
    data: video,
  } = getPreRecordedDetailsResponse || {}

  const isLogged = authCheck()

  let isVimeo = ""

  useEffect(() => {
    const {
      location: {
        pathname,
      },
    } = root || {}

    const id = pathname.split("/")[3]

    if (isEmpty(video)) {
      getPreRecordedContentDetails({
        courseId: parseInt(id, 10),
      })
    }

    getRoomTopics()
    getRoomTopicEvents()
  }, [])

  const meta = {
    title: "Radius Rooms: Live Content & Networking for Real Estate",
    description: `Radius Rooms provides real estate professionals with live
     and on-demand educational content, networking opportunities, and training resources.`,
    type: "website",
    url: `${WEB_URL}rooms`,
    image: SEOImage,
  }

  const showShimmer = fetching || !isAppMounted || isEmpty(video)

  if (!isEmpty(video)) {
    meta.title = `Radius Rooms: Live Content & Networking for Real Estate || ${video.title}`
    meta.url = `${WEB_URL}/watch/${video.course_hash_id}/${video.title}`

    const {
      Lessons,
    } = video || {}

    const url = get(Lessons, "[0].video_url", "")

    if (url.includes("vimeo")) {
      const redirectUrl = `/academy/course-details/${video.course_hash_id}/${video.title}`

      return (
        <Redirect to={redirectUrl} />
      )
    }
  }

  return (
    <>
      <Wrap>
        <Helmet>
          <title>{meta.title}</title>
          <meta property="og:title" content={meta.title} />
          <meta property="title" content={meta.title} />
          <meta property="og:url" content={meta.url} />
          <meta property="url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
          <meta property="description" content={meta.description} />
          <meta property="og:image" content={meta.image} />
          <meta property="image" content={meta.image} />
        </Helmet>
        {showShimmer ? (
          <ContentWrap className="container">
            <Shimmer />
          </ContentWrap>
        ) : (
          <ContentWrap className="container">
            <ShowcaseHeader />
            <Player />
            <Details />
            <Similar />
          </ContentWrap>
        )}
      </Wrap>
      <Footer isFreshChatHidden="true" />
      {isAppMounted && isShareRoomModal && (
        <ShareRoomModal show toClose={() => { toggleShareLinkModal(false) }}>
          <ShareModal
            isVideoRoomsModal
            customUrl={`${RADIUS_URL}/watch/rooms/${video.course_hash_id}/${video.title}`}
            toClose={() => toggleShareLinkModal(false)}
            isLogged={isLogged}
            customText={`Watch "${video.title}" on Radius Rooms!`}
          />
        </ShareRoomModal>
      )}
      {isShowPostInRadiusFeed && (
        <RoomsModal
          show
          toClose={() => {
            togglePostInRadiusFeed(false)
          }}
        >
          <PostInRadiusFeed />
        </RoomsModal>
      )}
    </>
  )
}

export default CreateEditPostContainer(App(Rooms(Showcase)))
