import axios from "axios"

export const userLoginAPI = payload => axios.post(`${API_URL}user/sign-in`, payload)

export const appleSigninAPI = payload => axios.post(
  `${API_URL}user/apple-login`,
  payload
)

export const saveProductInterestAPI = payload => axios
  .post(`${API_URL}product-interest`, payload)

export const saveProductInterestStepsAPI = payload => axios
  .post(`${API_V2_URL}product-interest`, payload)
