import React from "react"
import styled from "styled-components"
import { SearchHeader } from "./Components/index"
import SectionHeader from "./Components/SectionHeader/index"

const Wrap = styled.div``

class CRMDashboard extends React.Component {
  state = {}

  render() {
    return (
      <Wrap>
        <SearchHeader />
      </Wrap>
    )
  }
}

export default CRMDashboard
