import {
  takeLatest,
  call,
  put,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import root from "window-or-global"
import * as PIMApi from "./api"
import * as PIMActions from "./actions"

function* handleFetchAllPIM() {
  try {
    const res = yield call(PIMApi.getAllPimAPI)
    if (isSuccess(res)) {
      yield put(PIMActions.getAllPimAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(PIMActions.getAllPimAction.failure(error))
  }
}

function* handlePostFinalCTACall(action) {
  try {
    const {
      url,
      webLink,
    } = action.data
    const res = yield call(PIMApi.postCallAPI, url)
    if (isSuccess(res)) {
      yield put(PIMActions.postFinalCTAAction.success(res))
      root.open(webLink || "/community", "_self")
    }
  } catch (error) {
    yield put(PIMActions.postFinalCTAAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(PIMActions.getAllPimAction.REQUEST, handleFetchAllPIM)
  yield takeLatest(PIMActions.postFinalCTAAction.REQUEST, handlePostFinalCTACall)
}
