import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import { getFormValues } from "redux-form"
import * as RealtyActions from "./actions"

const initState = {
  formSubmitFailed: false,
  toggleCommissionsPopupStep: null,
  showTCBannerForm: true,
  offerClaimed: false,
  documents: [],
  ...createDeltaReducer("transactionFormFetchValuesResponse"),
  ...createDeltaReducer("transactionFormCreateDealViaFormResponse"),
  ...createDeltaReducer("transactionFormUpdateStageResponse"),
  ...createDeltaReducer("fetchTransactionCoordinationDocumentsResponse"),
  ...createDeltaReducer("transactionFormUpdateResponse"),
  ...createDeltaReducer("uploadTCFilesResponse"),
  ...createDeltaReducer("uploadTCFilesToDbResponse"),
  ...createDeltaReducer("resetAllTCFromResponse"),
  ...createDeltaReducer("getClientSearchResponse"),
  ...createDeltaReducer("fetchTCDocumentsFromS3Response"),
  ...createDeltaReducer("deleteTCDocumentsFromS3Response"),

  ...createDeltaReducer("getOnboardingCommissionResponse"),
  ...createDeltaReducer("createOnboardingCommissionResponse"),
  ...createDeltaReducer("updateOnboardingCommissionResponse"),
  ...createDeltaReducer("getTCBannerResponse"),
}

export default function transactionFormReducer(state = initState, action) {
  switch (action.type) {
    case RealtyActions.transactionFormFetchValuesAction.REQUEST:
    case RealtyActions.transactionFormFetchValuesAction.FAILURE:
    case RealtyActions.transactionFormFetchValuesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.transactionFormFetchValuesAction,
          "transactionFormFetchValuesResponse"
        ),
      }
    }

    case RealtyActions.transactionFormCreateDealViaFormAction.REQUEST:
    case RealtyActions.transactionFormCreateDealViaFormAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.transactionFormCreateDealViaFormAction,
          "transactionFormCreateDealViaFormResponse"
        ),
      }
    }

    case RealtyActions.transactionFormCreateDealViaFormAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.transactionFormCreateDealViaFormAction,
          "transactionFormCreateDealViaFormResponse"
        ),
        formSubmitFailed: true,
      }
    }

    case RealtyActions.transactionFormUpdateStageAction.REQUEST:
    case RealtyActions.transactionFormUpdateStageAction.FAILURE:
    case RealtyActions.transactionFormUpdateStageAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.transactionFormUpdateStageAction,
          "transactionFormUpdateStageResponse"
        ),
      }
    }

    case RealtyActions.fetchTCFilesAction.REQUEST:
    case RealtyActions.fetchTCFilesAction.FAILURE:
    case RealtyActions.fetchTCFilesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.fetchTCFilesAction,
          "fetchTransactionCoordinationDocumentsResponse"
        ),
      }
    }

    case RealtyActions.transactionFormUpdateAction.REQUEST:
    case RealtyActions.transactionFormUpdateAction.FAILURE:
    case RealtyActions.transactionFormUpdateAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.transactionFormUpdateAction,
          "transactionFormUpdateResponse"
        ),
      }
    }

    case RealtyActions.uploadTCFilesAction.REQUEST:
    case RealtyActions.uploadTCFilesAction.FAILURE:
    case RealtyActions.uploadTCFilesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.uploadTCFilesAction,
          "uploadTCFilesResponse"
        ),
      }
    }

    case RealtyActions.uploadTCFilesToDbAction.REQUEST:
    case RealtyActions.uploadTCFilesToDbAction.FAILURE:
    case RealtyActions.uploadTCFilesToDbAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.uploadTCFilesToDbAction,
          "uploadTCFilesToDbResponse"
        ),
      }
    }

    case RealtyActions.resetAllTCFromAction.REQUEST:
    case RealtyActions.resetAllTCFromAction.FAILURE:
    case RealtyActions.resetAllTCFromAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.resetAllTCFromAction,
          "resetAllTCFromResponse"
        ),
      }
    }

    case RealtyActions.getClientSearchAction.REQUEST:
    case RealtyActions.getClientSearchAction.FAILURE:
    case RealtyActions.getClientSearchAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getClientSearchAction,
          "getClientSearchResponse"
        ),
      }
    }

    case RealtyActions.fetchTCDocumentsFromS3Action.REQUEST:
    case RealtyActions.fetchTCDocumentsFromS3Action.FAILURE:
    case RealtyActions.fetchTCDocumentsFromS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.fetchTCDocumentsFromS3Action,
          "fetchTCDocumentsFromS3Response"
        ),
      }
    }

    case RealtyActions.deleteTCDocumentsFromS3Action.REQUEST:
    case RealtyActions.deleteTCDocumentsFromS3Action.FAILURE:
    case RealtyActions.deleteTCDocumentsFromS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.deleteTCDocumentsFromS3Action,
          "deleteTCDocumentsFromS3Response"
        ),
      }
    }

    case RealtyActions.toggleCommissionsPopup.type: {
      return {
        ...state,
        toggleCommissionsPopupStep: action.data,
      }
    }

    case RealtyActions.getOnboardingCommission.REQUEST:
    case RealtyActions.getOnboardingCommission.FAILURE:
    case RealtyActions.getOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getOnboardingCommission,
          "getOnboardingCommissionResponse"
        ),
      }
    }

    case RealtyActions.createOnboardingCommission.REQUEST:
    case RealtyActions.createOnboardingCommission.FAILURE:
    case RealtyActions.createOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.createOnboardingCommission,
          "createOnboardingCommissionResponse"
        ),
      }
    }

    case RealtyActions.getTCBannerAction.REQUEST:
    case RealtyActions.getTCBannerAction.FAILURE:
    case RealtyActions.getTCBannerAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTCBannerAction,
          "getTCBannerResponse"
        ),
      }
    }

    case RealtyActions.updateOnboardingCommission.REQUEST:
    case RealtyActions.updateOnboardingCommission.FAILURE:
    case RealtyActions.updateOnboardingCommission.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.updateOnboardingCommission,
          "updateOnboardingCommissionResponse"
        ),
      }
    }

    case RealtyActions.setShowTCBannerFormAction.type: {
      return {
        ...state,
        showTCBannerForm: action.data,
      }
    }

    case RealtyActions.setOfferClaimedAction.type: {
      return {
        ...state,
        offerClaimed: action.data,
      }
    }

    case RealtyActions.saveDocumentsForUploadToContractsAction.type: {
      return {
        ...state,
        documents: action.data,
      }
    }

    case RealtyActions.clearTCDataAction.type: {
      return {
        ...state,
        ...createDeltaReducer("fetchTCDocumentsFromS3Response"),
      }
    }

    default:
      return state
  }
}
