/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from "react"
import styled from "styled-components"
import Container from "container/Settings"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import {
  Radio, Space, Input, Button,
} from "@ui/antd"
import { authCheck } from "services/auth"
import { CTAButton } from "../Auth/Login"

const { TextArea } = Input

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 134px;
  @media(max-width: 400px) {
    padding: 134px 70px;
  }
`
const Header = styled.div`
  display: flex;
  img {
    height: 25px;
  }
  h1 {
    margin: 0px;
    margin-top: 5px;
    font-size: 20px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #959595;
    margin-top: 10px;
    font-weight: 300;
  }
  margin-bottom: 20px;
`
const RadioButton = styled(Radio)`
  p {
    font-weight: 300;
    color: black;
  }
`
const DeleteButton = styled(Button)`
  width: 300px;
  margin-top: 20px;
  border-radius: 50px;
  height: 45px;
  background: red;
  border: none;
`

const AuthWrap = styled.div`
p {
    color: #959595;
    margin-top: 10px;
    font-weight: 300;
  }
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const LoginButtonWrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
`

const DeleteAccount = ({ deleteAccount }) => {
  const isLogged = authCheck()
  if (!isLogged) {
    CookiesStorage.save("redirectUrl", "/delete-account")
  }
  const [value, setValue] = useState(null)
  const [otherReason, setOtherReason] = useState("")
  const onChange = (e) => {
    setValue(e.target.value)
  }

  const requestToDelete = () => {
    let payload = {}
    if (value === "Other") {
      payload = {
        reason: otherReason,
      }
    } else {
      payload = {
        reason: value,
      }
    }
    deleteAccount({ payload })
  }
  return (
    <Wrap>
      <Header>
        {/* <img src={Dustbin} alt="Delete" /> */}
        <h1>Delete Account?</h1>
      </Header>
      <Content>
        <p>
          Delete account request: On raising the request, you will be logged out of the application. One of our team member will contact you on the provided email to proceed further with dowloading the data, deletion or updation of the account.
        </p>
        <AuthWrap>
          {isLogged ? (
            <ContentWrap>
              <p>
                Please specify a reason for the account deletion:
              </p>
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <RadioButton value="I'm not getting enough deals on the app.">
                    <p>
                      I'm not getting enough deals on the app.
                    </p>
                  </RadioButton>
                  <RadioButton value="I don't get relevant content from the app.">
                    <p>
                      I don't get relevant content from the app.
                    </p>
                  </RadioButton>
                  <RadioButton value="I want to delete a duplicate account.">
                    <p>
                      I want to delete a duplicate account.
                    </p>
                  </RadioButton>
                  <RadioButton value="I don't use Radius anymore">
                    <p>
                      I don't use Radius anymore
                    </p>
                  </RadioButton>
                  <RadioButton value="Other">
                    <p>
                      Other (Please Specify)
                    </p>
                    {value === "Other" ? (
                      <TextArea
                        style={{
                          height: 100,
                        }}
                        value={otherReason}
                        onChange={e => setOtherReason(e.target.value)}
                      />
                    ) : null }
                  </RadioButton>
                </Space>
              </Radio.Group>
              <DeleteButton
                type="primary"
                disabled={value === null}
                onClick={requestToDelete}
              >
                Request to Delete
              </DeleteButton>
            </ContentWrap>
          ) : (
            <>
              <p>You have to login to delete your account</p>
              <LoginButtonWrapper>
                <CTAButton className="login-button-hp" onClick={() => root.location.href = "/signup/enter-email"}>Login</CTAButton>
              </LoginButtonWrapper>
            </>
          )}
        </AuthWrap>
      </Content>
    </Wrap>
  )
}

export default Container(DeleteAccount)

