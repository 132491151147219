import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 70px;
  margin: 0 auto;
  height: 127px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

  @media(min-width: 1440px) {
    gap: 111px;
    margin: unset;
    justify-content: center;
  }

  @media(max-width: 1099px) {
    gap: 0;
    margin: unset;
    justify-content: space-around;
    width: 100%;
    height: 54px;
    min-width: 350px;
    max-width: 100%;
  }

`
const ImageWrap = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  ${props => props.img && `
    background: url(${props.img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}

  @media(max-width: 1099px) {
    width: ${props => props.mobileWidth};
    height: ${props => props.mobileHeight};
  }
`

const Media = () => (
  <Wrap>
    <ImageWrap
      width="74.065px"
      height="41px"
      mobileWidth="44.62px"
      mobileHeight="24.545px"
      img={`${CLOUDFRONT}/Inman.png`}
    />
    <ImageWrap
      width="143px"
      height="26.34px"
      mobileWidth="88.248px"
      mobileHeight="15.709px"
      img={`${CLOUDFRONT}/HousingWire.png`}
    >
    </ImageWrap>
    <ImageWrap
      width="64.056px"
      height="34px"
      mobileWidth="42.637px"
      mobileHeight="22.582px"
      img={`${CLOUDFRONT}/WSJ4x.png`}
    >
    </ImageWrap>
    <ImageWrap
      width="81.918px"
      height="28px"
      mobileWidth="64px"
      mobileHeight="21px"
      img={`${CLOUDFRONT}/Forbes.png`}
    >
    </ImageWrap>
  </Wrap>
)

export default Media
