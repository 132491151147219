import React from "react"
import {
  PROPERTY_TYPE,
} from "dumbComponents/OffersV2/consts"
import { DropdownIcon } from "dumbComponents/OffersV2/icons"
import { SideNav } from "../components"
import {
  SelectField,
} from "./Components"
import {
  CoverSheetTitle,
  MenuUiWrap,
} from "./styles"

const SIDENAV_OPTIONS = [
  {
    value: "buyer_information",
    label: "Buyer Information",
  },
  {
    value: "property_information",
    label: "Property Information",
  },
  {
    value: "deal_terms",
    label: "Deal Details",
  },
]

const customSelectStyles = {
  // width: "497px",
  height: "48px",
  overflow: "hidden",
}

const CoverSheet = () => (
  <MenuUiWrap>
    <CoverSheetTitle>
      <p>Cover sheet</p>
    </CoverSheetTitle>
    <SelectField
      options={PROPERTY_TYPE}
      style={customSelectStyles}
      arrowIcon={<DropdownIcon />}
      defaultValue="residential_1_4"
    />
    <SideNav
      options={SIDENAV_OPTIONS}
    />
  </MenuUiWrap>
)

export default CoverSheet
