import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import EditIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Edit.png"
import DeleteIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Delete.png"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import {
  Phone as PhoneIcon,
  Mail as MailIcon
} from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/"
import { CoSellerDetailsForm } from "../Forms"
import {
  ShortProfileViewContainer,
  NameAndProfileContainer,
  ContactInfoContainer,
  ContactInfoRow,
} from "../styles"

const IconWrap = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 8px;
`

const IconContainer = styled.div`
  cursor: pointer;
`

const CoClientDetails = ({
  data,
  deleteCoClientDetails,
  getCtIdResponse,
  getBrokerageTransactionStatusResponse,
}) => {
  const [isEditing, setEditing] = useState(false)
  const {
    name,
    phone,
    email,
    id,
  } = data || {}
  const { data: clientTransactionData } = getCtIdResponse || {}
  const { id: clientTransactionId } = clientTransactionData || {}
  const { data: brokerageTransactionStatus } = getBrokerageTransactionStatusResponse || {}
  const { transactionType, id: brokerageId } = brokerageTransactionStatus || {}

  const deal_id = transactionType === "Listing" || transactionType === "Contract" ? brokerageId : null
  const offer_id = transactionType === "Offer" ? brokerageId : null

  const params = `transaction_id=${clientTransactionId}&deal_id=${deal_id}&offer_id=${offer_id}`

  const handleDelete = () => {
    deleteCoClientDetails({
      id,
      clientTransactionId,
      params,
    })
  }
  return (
    <>
      {isEditing ? (
        <CoSellerDetailsForm
          setShowForm={() => setEditing(false)}
          initialValues={data}
          isEditing={isEditing}
          setEditing={setEditing}
          headingText="client"
        />
      ) : (
        <ShortProfileViewContainer>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <NameAndProfileContainer><p>{name}</p></NameAndProfileContainer>

            <IconWrap>
              <IconContainer onClick={() => setEditing(true)}>
                <img src={EditIcon} height={16} width={16} alt="Edit Icon" />
              </IconContainer>
              <IconContainer onClick={handleDelete}>
                <img src={DeleteIcon} height={16} width={16} alt="Delete Icon" />
              </IconContainer>
            </IconWrap>
          </div>

          <ContactInfoRow>
            {phone && (
              <ContactInfoContainer>
                <div className="icon">
                  <PhoneIcon />
                </div>
                <p className="info">{phone && normalizePhone(phone)}</p>
              </ContactInfoContainer>
            )}

            <ContactInfoContainer>
              <div className="icon">
                <MailIcon />
              </div>
              <p className="info">{email}</p>
            </ContactInfoContainer>
          </ContactInfoRow>
        </ShortProfileViewContainer>
      )}
    </>
  )
}

export default CRMV2Container(CoClientDetails)
