import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const AdditionalDropdown = styled.ul`
  position: absolute;
  top: 100px;
  right: 0;
  display: flex;
  width: 219px;
  height: max-content;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  list-style: none;
  flex-shrink: 0;
  z-index: 9;

  border-radius: 8px;
  background: var(--Color-Neutral-50, #FDFDFD);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const AdditionalDropdownLI = styled.li`
 display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);

  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
  }
`
export const FileListWrap = styled.div`
  width: 100%;
`

export const HeaderFileListWrap = styled.div`
  width: 100%;
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }
`
export const ListWrapper = styled.ul`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 9px;
  background: var(--Color-Neutral-100, #F1F1FE);
`

export const File = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--Color-Neutral-300, #DCDEFF);
  height: 72px;
`

export const CTAWrap = styled.div`
  display: flex;
`

export const FileDetailWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 24.084px */
    margin: 0;
  }
`

export const ImageWrap = styled.div`
  img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    position: relative;
    top: 3px;
  }
`

export const FileButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
  align-items: center;
  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    leading-trim: both;
    text-edge: cap;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0;
  }
`
