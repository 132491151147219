import React from "react"
import uuid from "uuid"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/OffersV2"
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  CTAWrap,
  ListWrap,
  ListRowWrap,
  StateDiv,
} from "./styles"
import ListingAgentCard from "../ListingAgentCard"
import SellerCard from "../SellerCard"
import {
  SelectField,
} from "../InputFields"
import {
  BlueButton,
} from "../../../commonStyles"
import {
  PropertyInformationForm,
  ListingAgentForm,
  SellerForm,
} from "./Forms"
import {
  AddButtonCTA,
} from "../../../components"
import {
  getState,
} from "../../../consts"
import {
  deleteFromObject,
} from "../../util"

const PropertyInformation = ({
  propertyInformationObject,
  isAddNewListingAgentVisible,
  isAddNewSellerVisible,
  updatePropertyInformationObject,
  addNewListingAgentToggle,
  addNewSellerAgentToggle,
  stateForOffer,
  updatePropertyInformationTab,
  buyersAgentInformationObject,
  dealToView,
  updatePropertyInformationTabResponse,
}) => {
  const payload = { ...propertyInformationObject }

  const { 
    isFetching: updatingPropertyInformation,
  } = updatePropertyInformationTabResponse || {}

  const {
    currentState,
    form,
    listing_agents,
    sellers,
  } = propertyInformationObject || {}

  const handleFinalSubmit = () => {
    //
    const {
      type_of_transaction,
      listing_agents: currentListingAgents,
      sellers: currentSellers,
      form: currentForm,
    } = propertyInformationObject || {}

    const formValues = currentForm[stateForOffer]

    const listingAgentsPayload = currentListingAgents.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      agent_id: item.agent_id,
      is_radius_user: item.agent_id ? true: false,
      name: `${item.listing_agent_first_name} ${item.listing_agent_last_name}`,
      email: item.email,
      agent_license_number: item.agent_license_number,
      landline: item.landline,
      phone: item.phone,
      brokerage: item.brokerage,
      brokerage_license_number: item.brokerage_license_number,
      brokerage_address: item.brokerage_address,
      apt: item.apt,
      city: item.city,
      state: item.state,
      zipcode: item.zipcode,
    }))
    
    const sellerPayload = currentSellers.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      firstname: item.firstname,
      lastname: item.lastname,
      type_of_ownership: item.type_of_ownership,
      ownership_name: item.type_of_ownership,
    }))


    const finalPayload = {
      clients: [
        ...sellerPayload,
      ],
      agents: [
        ...listingAgentsPayload,
      ],
      mls_select: "",
      mls_number: "",
      apn_number: "",
      street: "",
      apt: "",
      city: "",
      state: "",
      zipcode: "",
      county: "",
      ...formValues,
      type_of_transaction,
      draft: 0,
      current_offer_step: "property_information_mobile"
    }

    updatePropertyInformationTab({
      id: dealToView,
      payload: finalPayload,
    })
  }

  const handlePropertyFormUpdate = (values) => {
    const formPayload = {
      ...payload[form[stateForOffer]],
      ...values,
    }

    payload.form = {
      ...form,
      [stateForOffer]: {
        ...formPayload,
      },
    }

    updatePropertyInformationObject(payload)
  }

  const handleEditModeForListingAgent = (id) => {
    const currentListingAgents = [...propertyInformationObject.listing_agents]

    currentListingAgents.forEach((item) => {
      const curr = item
      if (item.listing_agent_id === id) {
        curr.isListingAgentEditVisible = !item.isListingAgentEditVisible
      }
    })

    payload.listing_agents = [...currentListingAgents]

    updatePropertyInformationObject(payload)
  }

  const handleEditListingAgent = (values) => {
    const currentEditListingAgent = payload.listing_agents.filter(item => item.isListingAgentEditVisible)[0]
    const currentListingAgentID = currentEditListingAgent.listing_agent_id

    const updated = {
      ...currentEditListingAgent,
      ...values,
      isListingAgentEditVisible: false,
    }

    payload.listing_agents.forEach((item, iter) => {
      if (item.listing_agent_id === currentListingAgentID) {
        payload.listing_agents[iter] = { ...updated }
      }
    })

    updatePropertyInformationObject(payload)
  }

  const handleDeleteListingAgent = (id) => {
    const filteredListingAgents = payload.listing_agents.filter(item => item.listing_agent_id !== id)

    payload.listing_agents = [...filteredListingAgents]

    updatePropertyInformationObject(payload)
  }

  const handleListingAgentGoBack = () => {
    addNewListingAgentToggle(false)
  }

  const handleSellerGoBack = () => {
    addNewSellerAgentToggle(false)
  }

  const onFinishAddSeller = (values) => {
    const data = {
      ...values,
      isSellerEditVisible: false,
      seller_id: uuid.v4(),
      seller_offers: 10000,
      seller_budget: 10000,
    }

    payload.sellers = [...payload.sellers, data]

    updatePropertyInformationObject(payload)
    handleSellerGoBack()
  }

  const onFinishAddListingAgent = (values) => {
    const data = {
      ...values,
      isListingAgentEditVisible: false,
      listing_agent_id: uuid.v4(),
    }

    payload.listing_agents = [...payload.listing_agents, data]

    updatePropertyInformationObject(payload)
    handleListingAgentGoBack()
  }

  const handleEditModeForSeller = (id) => {
    const currentSellers = [...propertyInformationObject.sellers]

    currentSellers.forEach((item) => {
      const curr = item
      if (item.seller_id === id) {
        curr.isSellerEditVisible = !item.isSellerEditVisible
      }
    })

    payload.sellers = [...currentSellers]

    updatePropertyInformationObject(payload)
  }

  const handleDeleteSeller = (id) => {
    const filteredListingAgents = payload.listing_agents.filter(item => item.seller_id !== id)

    payload.listing_agents = [...filteredListingAgents]

    updatePropertyInformationObject(payload)
  }

  const handleEditSeller = (values) => {
    const currentEditSeller = payload.sellers.filter(item => item.isSellerEditVisible)[0]
    const currentSellerId = currentEditSeller.seller_id

    const updated = {
      ...currentEditSeller,
      ...values,
      isSellerEditVisible: false,
    }

    payload.sellers.forEach((item, iter) => {
      if (item.seller_id === currentSellerId) {
        payload.sellers[iter] = { ...updated }
      }
    })

    updatePropertyInformationObject(payload)
  }

  const handleRepresentation = (e) => {
    const data = {
      ...propertyInformationObject,
      addressRepresentation: e.target.checked,
    }

    const mainAgent = buyersAgentInformationObject.agents.filter(item => item.isMainAgent)[0]

    const currentAgent = {
      ...mainAgent,
      is_primary: 1,
      listing_agent_first_name: mainAgent.agent_first_name,
      listing_agent_last_name: mainAgent.agent_last_name,
      listing_agent_id: mainAgent.agent_id,
    }

    if(e.target.checked) {
      data.listing_agents.forEach((item) => {
        item.is_primary = 0
      })

      data.listing_agents = [currentAgent, ...data.listing_agents]
    } else {
      data.listing_agents.shift()
      if(data.listing_agents.length > 0) {
        data.listing_agents[0].is_primary = 1
      }
    }

    updatePropertyInformationObject(data)
  }

  return (
    <Wrap>
      {/*Property Agent Information*/}
      <SectionWrap>
        <SectionTitle>
          <p>Property Information</p>
        </SectionTitle>
        <ListRowWrap>
          <StyledCheckbox
            value="here"
            onChange={e => handleRepresentation(e)}
          >
            I represent this listing address.
          </StyledCheckbox>
          <StateDiv>
            <p>State:</p>
            <h2>{getState(stateForOffer)}</h2>
          </StateDiv>
        </ListRowWrap>
        <ListWrap>
          <PropertyInformationForm
            prefilledData={form[stateForOffer]}
            state={stateForOffer}
            onFinish={handlePropertyFormUpdate}
          />
        </ListWrap>
      </SectionWrap>

      {/*Listing Agents Information*/}
      <SectionWrap v2>
        <SectionTitle>
          <p>Listing Agent Information</p>
        </SectionTitle>
        <ListWrap>
          {listing_agents && listing_agents.length > 0 && listing_agents.map((item, iter) => (
            <>
              {item.isListingAgentEditVisible ? (
                <ListingAgentForm
                  title="Listing Agent Information"
                  prefilledData={item}
                  handleEditModeChange={handleEditModeForListingAgent}
                  onFinish={handleEditListingAgent}
                  deleteListingAgent={handleDeleteListingAgent}
                />
              ) : (
                <ListingAgentCard
                  data={item}
                  title={iter > 0 ? `Listing Agent ${iter} information` : "Listing Agent's Information"}
                  editListingAgent={() => handleEditModeForListingAgent(item.listing_agent_id)}
                />
              )}
            </>
          ))}
          {isAddNewListingAgentVisible ? (
            <ListingAgentForm
              title="Listing Agent Information"
              handleEditModeChange={handleListingAgentGoBack}
              onFinish={onFinishAddListingAgent}
              isFirst={!listing_agents || listing_agents.length <= 0}
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA
                name={listing_agents.length > 0 ? "ADD ADDITIONAL LISTING AGENT" : "ADD LISTING AGENT"}
                onClick={() => addNewListingAgentToggle(true)}
              />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Sellers Information*/}
      <SectionWrap v2>
        <SectionTitle>
          <p>Seller Information</p>
        </SectionTitle>
        <ListWrap>
          {sellers && sellers.length > 0 && sellers.map((item, iter) => (
            <>
              {item.isSellerEditVisible ? (
                <SellerForm
                  title="Seller Information"
                  prefilledData={item}
                  handleEditModeChange={handleEditModeForSeller}
                  onFinish={handleEditSeller}
                  deleteSeller={handleDeleteSeller}
                />
              ) : (
                <SellerCard
                  data={item}
                  title={iter > 0 ? `Seller ${iter} information` : "Seller's Information"}
                  editSeller={() => handleEditModeForSeller(item.seller_id)}
                />
              )}
            </>
          ))}
          {isAddNewSellerVisible ? (
            <SellerForm
              title="Seller Information"
              handleEditModeChange={handleSellerGoBack}
              onFinish={onFinishAddSeller}
            />
          ) : (
            <CTAWrap>
              <AddButtonCTA
                name={sellers.length > 0 ? "ADD ADDITIONAL SELLER" : "ADD SELLER"}
                onClick={() => addNewSellerAgentToggle(true)}
              />
            </CTAWrap>
          )}
        </ListWrap>
      </SectionWrap>

      {/*Submit Form*/}
      <SectionWrap>
        <BlueButton maxWidth onClick={handleFinalSubmit}>
          {updatingPropertyInformation ? (
            <ThreeDotLoader color="#FFF" />
          ) : (
            <p>Save &amp; continue</p>
          )}
        </BlueButton>
      </SectionWrap>
    </Wrap>
  )
}

export default OffersV2(PropertyInformation)
