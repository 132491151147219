import React from "react"
import styled, { css } from "styled-components"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import ArrowImage from "images/icons/arrow.png"

const FALLBACK_IMAGE = "https://d2fedz0by71ckz.cloudfront.net/images/img-place-holder%402x.png"
const NO_IMAGE = "https://vollrath.com/ClientCss/images/VollrathImages/No_Image_Available.jpg"

const IconWrap = styled.div`
  display: none;
  align-item: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  justify-content: space-between;
  bottom: 65px;
  height: 1px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    background: #FFF;
    opacity: 0.5;
    transition-duration: 0.3s;

    &:hover{
      cusror: pointer;
      opacity: 1;
    }

    &.left {
      transform: rotate(90deg);
    }
    &.right {
      transform: rotate(-90deg);
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  position: relative;

  &:hover{
    .Icon_wrap{
      display: flex;
    }
  }
`

const ActiveImage = styled.div`
  position: relative;
  min-height: 450px;
  border-radius: 4px;
  background-color: #E9E9E9;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  background-position: center;

  ${props => props.noActiveImage && css`
    min-height: 330px;
  `}
`

const ThumbImageWrap = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
`

const ThumbImage = styled.div`
  width: 140px;
  height: 75px;
  min-height: min-content;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px 10px 10px 0px;
  background-position: center;
  cursor: pointer;
  display: inline-block;
`

const IndicatorWrap = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`

const IndicatorsDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background: rgba(255, 255, 255, 0.5);
  ${props => props.active && css`
    background: rgba(255, 255, 255, 1);
  `}
`

const UploadImageWrap = styled.label`
  background: rgba(0,0,0,0.3);
  transition-duration: 0.3s;
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 30px;
  display: flex;
  color: #FFF;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba(0,0,0,0.5);
  }

  i {
    margin-right: 20px;
  }

  input {
    display: none;
  }
`

const UploadProgress = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  color: #222;
  flex-direction: column;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;

  h4 {
    font-size: 25px;
  }
`

const ImageControl = ({ handleLeftClick, handleRightClick }) => (
  <IconWrap className="Icon_wrap">
    <img src={ArrowImage} alt="arrow" className="left" onMouseDown={handleLeftClick}/>
    <img src={ArrowImage} alt="arrow" className="right" onMouseDown={handleRightClick}/>
  </IconWrap>
)

const Indicators = ({ images, activeImage }) => (
  <IndicatorWrap>
    {images.map(item => (
      <IndicatorsDot active={activeImage.id === item.id} />
    ))}
  </IndicatorWrap>
)

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props)
    const { activeImage = props[0] } = props
    this.state = {
      activeImage,
    }
    this.ThumbImageWrapEle = React.createRef()
  }

  handleImageClick = (image) => {
    this.setState({
      activeImage: image,
    })
  }

  handleLeftClick = () => {
    const { scrollLeft } = this.ThumbImageWrapEle.current
    this.ThumbImageWrapEle.current.scrollLeft = scrollLeft - 200
  }

  handleRightClick = () => {
    const { scrollLeft } = this.ThumbImageWrapEle.current
    this.ThumbImageWrapEle.current.scrollLeft = scrollLeft + 200
  }

  onFileUpload = (event) => {
    const { files } = event.target || []
    const { handleFileUpload } = this.props
    handleFileUpload(files)
  }

  render() {
    let activeImage = null
    const { activeImage: stateActiveImage } = this.state
    const {
      images,
      isUploadImage,
      isUploadingProgress,
      isHideDots,
      isShowScroller,
    } = this.props
    if (stateActiveImage) {
      activeImage = stateActiveImage
    } else {
      activeImage = images[0]
    }
    const { image_url = FALLBACK_IMAGE } = activeImage || {}
    return (
      <Wrapper>
        <ActiveImage
          style={{ backgroundImage: `url("${image_url}"), url(${NO_IMAGE})` }}
          noActiveImage={!!image_url}
        >
          {isUploadImage}
          { !isHideDots && images && images.length > 0 && (
            <Indicators
              activeImage={activeImage}
              images={images}
            />
          )}
          { isUploadImage && !isUploadingProgress && (
            <UploadImageWrap htmlFor="propertyPhotoUpload" onChange={this.onFileUpload}>
              <h4>
                <i className="icon-generic-Camera"></i>
                <span>Upload Images</span>
              </h4>
              <input
                type="file"
                id="propertyPhotoUpload"
                multiple
                accept="image/jpg, image/jpeg, image/png"
              />
            </UploadImageWrap>
          )}
          { isUploadImage && isUploadingProgress && (
            <UploadProgress>
              <ThreeDotLoader size="20" />
              <h4>Uploading Images...</h4>
            </UploadProgress>
          )}
        </ActiveImage>
        {isShowScroller && (
          <ImageControl
            handleRightClick={this.handleRightClick}
            handleLeftClick={this.handleLeftClick}
          />
        )}
        { images && images.length > 0 && (
          <ThumbImageWrap innerRef={this.ThumbImageWrapEle}>
            {images.map(item => (
              <ThumbImage
                key={item.id}
                style={{ backgroundImage: `url("${item.image_url}"), url(${NO_IMAGE})` }}
                onClick={() => { this.handleImageClick(item) }}
              />
            ))}
          </ThumbImageWrap>
        )}
      </Wrapper>
    )
  }
}

ImageCarousel.defaultProps = {
  isShowScroller: true,
}

export default ImageCarousel
