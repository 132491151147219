import styled from "styled-components"
import { INTER } from "@fonts"

export const EnvelopesWrap = styled.div`
  width: 100%;
`

export const Wrap = styled.div`
  padding: 18px 12px 37px 24px;;
  background: #FFFFFF;
  box-shadow: 0px 7px 7px 8px #5A5FF217;
  border-radius: 8px;
  width: 100%;
`

export const StyledStatus = styled.div`
  padding: 6px 12px;
  border-radius: 24px;
  width: 148px;
  height: 28px;
  text-align: center;

  ${props => props.status === "pending" && `
    background: linear-gradient(89.56deg, #F25A5A 0.36%, #F99291 129.5%);
  `}

  ${props => props.status === "buyer_pending" && `
    background: linear-gradient(89.56deg, #D67A31 0.36%, #F0A468 129.5%);
  `}

  ${props => props.status === "completed" && `
    background: linear-gradient(89.56deg, #48C376 0.36%, #87E8AB 129.5%);
  `}

`

export const StatusTitle = styled.p`
  margin: 0;
  font-size: 10px;
  font-family: ${INTER};
  font-family: 500;

  ${props => props.status === "pending" && `
    color: #FDFDFD;
  `}

  ${props => props.status === "buyer_pending" && `
    color: #FDFDFD;
  `}

  ${props => props.status === "completed" && `
    color: #252529;
  `}
`

export const DocContent = styled.div`
  margin-bottom: 20px;
  background-color: #FAFAFF;
`

export const DocWrap = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #b8b9ee;
  margin-bottom: 5px;
`

export const DocTitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  font-family: ${INTER};
`

export const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50%;
`

export const DateContentWrap = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const DateCreatedContent = styled.p`
  margin: 0;
  font-size: 12px;
  font-family: ${INTER};
  font-weight: 500;
`

export const DateContent = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: ${INTER};
  font-weight: 500;
`

export const AdditionalDropdown = styled.ul`
  position: absolute;
  top: 100px;
  right: 0;
  display: flex;
  width: 219px;
  height: 172px;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  list-style: none;
  flex-shrink: 0;
  z-index: 9;

  border-radius: 8px;
  background: var(--Color-Neutral-50, #FDFDFD);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const AdditionalDropdownLI = styled.li`
 display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);

  p {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    margin: 0;
  }
`

export const ResponseTagWrap = styled.div`
  display: flex;
  height: 40px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: var(--blue, linear-gradient(90deg, #5A5FF2 0.36%, #A5A8FF 129.5%));

  p {
    color: var(--White, #FDFDFD);
    text-align: right;
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
    margin: 0;
  }
`

export const OfferPackageTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #DCDEFF;
  padding-bottom: 5px;
  p {
    color: var(--Dark-Blue, #41446A);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 21.073px */
    margin: 0;
  }
`
