import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "CONSUMER_APP"

// export const turnOnMelRecommendationsAction = createSignalAction(BASE, "CONSUMER_APP_TURN_ON_REC")
export const fetchAllClientsAction = createSignalAction(BASE, 'CONSUMER_APP_FETCH_CLIENTS')
export const copyAppInviteLinkAction = createSignalAction(BASE, 'CONSUMER_APP_COPY_LINK')
export const inviteBulkClientsAction = createSignalAction(BASE, 'CONSUMER_APP_INVITE_ALL_CLIENTS')
export const joinWaitlistAction = createSignalAction(BASE, 'CONSUMER_APP_JOIN_WAITLIST')
export const inviteClientsAction = createSignalAction(BASE, 'CONSUMER_APP_INVITE_CLIENTS')
export const getAppStatusAction = createSignalAction(BASE, 'CONSUMER_APP_GET_APP_STATUS')
export const turnOnMelRecommendationsAction = createSignalAction(BASE, 'CONSUMER_APP_TURN_ON_RECOMMENDATION')
export const fetchRecommendedPropertiesAction = createSignalAction(BASE, 'CONSUMER_APP_GET_RECOMMENDED_PROPERTIES')
export const rejectPropertyStatusAction = createSignalAction(BASE, 'CONSUMER_APP_REJECT_PROPERTY')
export const removeBulkPropertyAction = createSignalAction(BASE, 'CONSUMER_APP_BULK_REJECT')
export const isMelRecommendedAction = createSimpleCreator(BASE, 'CONSUMER_APP_MEL_RECOMMENDED')