import { bindActionCreators } from "redux"
import { getFormValues } from "redux-form"
import { connect } from "react-redux"
import { isEqual } from "lodash"
import * as AppActions from "container/App/actions"
import * as RealtyActions from "./actions"

const mapStateToProps = (state) => {
  const {
    transactionReducer,
    app,
  } = state

  const {
    errorInComponent,
    isErrorModalOpen,
  } = app

  const basicFormValues = getFormValues("TC_BASIC_FORM")(state)
  const transactionDetailValues = getFormValues("TC_TRANSACTION_DETAILS_FORM")(state)
  const coordinationValues = getFormValues("TC_COORDINATION_DETAILS_FORM")(state)
  const tcAgentValues = getFormValues("TC_AGENT_OPTED_FORM")(state)
  const escrowFormValues = getFormValues("TC_ESCROW_DETAILS_FORM")(state)
  const signageAndPostValues = getFormValues("TC_SIGN_POST_DETAILS_FORM")(state)
  const tcPaymentForm1 = getFormValues("TC_PAYMENT_FORM_1")(state)
  const tcPaymentForm2 = getFormValues("TC_PAYMENT_FORM_2")(state)
  const tcPaymentForm3 = getFormValues("TC_PAYMENT_FORM_3")(state)

  const combinedTCValues = {
    ...basicFormValues,
    ...transactionDetailValues,
    ...coordinationValues,
    ...tcAgentValues,
    ...escrowFormValues,
    ...signageAndPostValues,
    tcPaymentForm: {
      ...tcPaymentForm1,
      ...tcPaymentForm2,
      ...tcPaymentForm3,
    },
  }

  if (combinedTCValues && basicFormValues) {
    if (!isEqual(combinedTCValues.initial_name, basicFormValues.initial_name)) {
      combinedTCValues.initial_name = basicFormValues.initial_name
    }

    if (combinedTCValues.initial_email !== basicFormValues.initial_email) {
      combinedTCValues.initial_email = basicFormValues.initial_email
    }

    if (combinedTCValues.initial_phone !== basicFormValues.initial_phone) {
      combinedTCValues.initial_phone = basicFormValues.initial_phone
    }

    if (combinedTCValues.other_source !== basicFormValues.other_source) {
      combinedTCValues.other_source = basicFormValues.other_source
    }
  }

  return {
    ...transactionReducer,
    combinedTCValues,
    errorInComponent,
    isErrorModalOpen,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTCValuesBasedOnFormID: RealtyActions.transactionFormFetchValuesAction.request,
  createDeal: RealtyActions.transactionFormCreateDealViaFormAction.request,
  updateDeal: RealtyActions.transactionFormUpdateAction.request,
  tcStageUpdate: RealtyActions.transactionFormUpdateStageAction.request,
  fetchTCFiles: RealtyActions.fetchTCFilesAction.request,
  uploadTCFiles: RealtyActions.uploadTCFilesAction.request,
  resetAllTCForms: RealtyActions.resetAllTCFromAction.request,
  fetchClient: RealtyActions.getClientSearchAction.request,
  fetchFilesFromS3: RealtyActions.fetchTCDocumentsFromS3Action.request,
  deleteFileFromS3: RealtyActions.deleteTCDocumentsFromS3Action.request,
  toggleErrorModal: AppActions.toggleErrorModalAction.call,
  triggerSlackNotification: RealtyActions.triggerSlackNotificationAction.request,
  toggleCommissionsPopup: RealtyActions.toggleCommissionsPopup.call,
  getOnboardingCommission: RealtyActions.getOnboardingCommission.request,
  createOnboardingCommission: RealtyActions.createOnboardingCommission.request,
  updateOnboardingCommission: RealtyActions.updateOnboardingCommission.request,
  setShowTCBannerForm: RealtyActions.setShowTCBannerFormAction.call,
  setOfferClaimed: RealtyActions.setOfferClaimedAction.call,
  getTCBanner: RealtyActions.getTCBannerAction.request,
  postTCBanner: RealtyActions.postTCBannerAction.request,
  saveDocumentsForUploadToContracts: RealtyActions.saveDocumentsForUploadToContractsAction.call,
  clearTCData: RealtyActions.clearTCDataAction.call,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
