import React, { useEffect, useState, useRef } from "react"
import { authCheck } from "services/auth"
import styled from "styled-components"
import { isEmpty } from "lodash"
import Rooms from "container/Rooms"
import root from "window-or-global"
import JoinCommunityImage
  from "dumbComponents/VideoRooms/components/LandingPage/Components/images/JoinCommunityV2-min.jpg"
import interestContainer from "container/App/applyFlowContainer"
import { LoadScript } from "services/Utils"
import PlayIcon from "images/academy/latest-course.png"
import { ATC } from "@fonts"

const Wrap = styled.div`
  position: relative;
  padding-top: 53px;
`
const PlayerWrap = styled.div``

const Iframe = styled.iframe`
  width: 100%;
  height: 600px;
`
const AdvertWrap = styled.div`
  width: 100%;
  height: 600px;
  background: #000000 url(${JoinCommunityImage});
  background-size: cover;
  background-position-y: -89px;
  background-repeat: no-repeat;
  border-radius: 8px;

  padding: 55px;

  @media(min-width: 1366px) {
    background-position-y: -324px;
  }

  @media(max-width: 767px) {
    background-position-y: -2px;
    background-position-x: -4px;
    padding: 35px;
    background-size: contain;
  }
`

const HiddenDiv = styled.div`
  width: 100%;
  height: 600px;
  background: transparent;
  position: absolute;
`

const TextWrap = styled.div``

const H1 = styled.h1`
  color: white;
  font-family: 'Butler';
  font-size: 60px;

  @media(max-width:767px) {
    font-size: 38px;
    line-height: 38px;
  }
`

const H2 = styled.h2`
  color: white;
  font-family: ${ATC};
  font-size: 20px;
  font-weight: 300;
  max-width: 53%;
  line-height: 30px;

  @media(max-width:767px) {
    font-size: 17px;
    max-width: 100%;
    line-height: 28px;
  }
`

const Button = styled.button`
  margin-top: 20px;
  cursor: pointer;
  background: white;
  width: max-content;

  font-family: ${ATC};
  padding: 12px;
  border: none;
  border-radius: 8px;
  color: #303030;

  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 35px;
  margin-bottom: 30px;
  width: 120px;
  font-size: 20px;
`

const PreviewText = styled.button`
  cursor: pointer;
  border: none;

  color: white;
  font-size: 20px;
  font-weight: 300;

  color: white;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 35px;
  margin-bottom: 15px;
  font-size: 20px;
  background: #3a60e4a6;
  padding: 2px 10px 2px 10px;
  height: 38px;

  animation: preview 1.2s ease-in forwards;

  @keyframes preview {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media(max-width: 767px) {
    font-size: 14px;
    text-align: left;
  }
`

const Preview = ({
  toggleApplyModal,
  getPreRecordedDetailsResponse,
}) => {
  const [replaceContent, setReplace] = useState(false)
  const ytRef = useRef(null)
  const pausedStatus = useRef(false)
  const playerRef = useRef(null)
  const pauseRef = useRef(null)

  const isLogged = authCheck()

  const {
    data: video,
  } = getPreRecordedDetailsResponse || {}

  // const playerRef = useRef(null)

  useEffect(() => {
    if (!isLogged) {
      setTimeout(() => {
        setReplace(true)
        toggleApplyModal({
          bool: true,
          accountType: "owner",
          headerText: "Nice to meet you!",
          subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
        })
      }, 120000)
    } else {
      loadScript()
    }
    return () => {}
  }, [])

  const getEmbedCode = () => {
    try {
      const {
        Lessons,
      } = video || {}
      const url = !isEmpty(video) && !isEmpty(Lessons) && Lessons[0].video_url || ""
      const splitUrl = url && url.split("/") || []
      const embedCode = splitUrl[splitUrl.length - 1] || ""
      return embedCode
    } catch (e) {
      console.log("error", e)
    }
  }

  const loadScript = () => {
    LoadScript("https://www.youtube.com/iframe_api", () => {
      let player

      root.YT.ready(() => {
        if (isLogged) {
          onYouTubeIframeAPIReady()
        }
      })

      function onYouTubeIframeAPIReady() {
        player = new root.YT.Player("player", {
          height: "600",
          width: "100%",
          videoId: getEmbedCode(),
          playerVars: {
            playsinline: 1,
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        })
      }

      const onPlayerReady = (event) => {
        event.target.playVideo()
      }

      function onPlayerStateChange(event) {
        ytRef.current = event

        if (event.data === 2) {
          //setVideoPause(true)
          pausedStatus.current = true

          pauseRef.current.setAttribute("style", `
              height: 600px;
              position: absolute;
              background: rebeccapurple;
              width: 100%;
              background: #dbdbdbf2 url(${video.cover_image_url});
              background-size: contain;
              background-position: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-repeat: no-repeat;
          `)
        } else if (event.data === 1) {
          pausedStatus.current = false
          pauseRef.current.setAttribute("style", "display: none;")
        }

        if (!event.data) {
          pauseRef.current.setAttribute("style", `
              height: 600px;
              position: absolute;
              background: rebeccapurple;
              width: 100%;
              background: #dbdbdbf2 url(${video.cover_image_url});
              background-size: contain;
              background-position: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
          `)
        }
      }

      const stopVideo = () => {
        console.log("was i called?")
        player.stopVideo()
      }
    })
  }

  return (
    <Wrap>
      {!isLogged ? (
        <>
          {!replaceContent && (
            <HiddenDiv
              onClick={(e) => {
                e.preventDefault()
                return false
              }}
            >
              <PreviewText
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                This is a 2 minute preview, click here to sign up and watch the entire video.
              </PreviewText>
              {/* <Button
                onClick={() => toggleApplyModal(true)}
              >
                Sign up
              </Button> */}
            </HiddenDiv>
          )}
          {!replaceContent ? (
            <Iframe
              src={`https://www.youtube-nocookie.com/embed/${getEmbedCode()}?start=0&end=120&rel=0&controls=0&modestbranding=1&showinfo=0&autoplay=1&mute=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            >
            </Iframe>
          ) : (
            <AdvertWrap>
              <TextWrap>
                <H1>
                  Enjoying the Rooms recording?
                </H1>
                <H2>
                  {`
                  The full video is available to Radius Community members only. 
                  Membership is free, and grants you access to thousands of hours 
                  of professional development material as well as the world’s only real estate social network.
                  `}
                </H2>
                <Button
                  onClick={() => {
                    toggleApplyModal({
                      bool: true,
                      accountType: "owner",
                      headerText: "Nice to meet you!",
                      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                    })
                  }}
                >
                  Sign up
                </Button>
              </TextWrap>
            </AdvertWrap>
          )}
        </>
      ) : (
        <>
          {/* <Iframe
            src={`https://www.youtube-nocookie.com/embed/${getEmbedCode()}?rel=0
            &modestbranding=1&showinfo=0&autoplay=1&mute=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          /> */}
          <div
            className="pauseFadeIn"
            style={{
              display: "none",
            }}
            ref={pauseRef}
          >
            <button
              type="button"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => {
                ytRef.current.target.playVideo()
              }}
            >
              <img src={PlayIcon} alt="Play Button" />
            </button>
          </div>
          <div
            className="youtube-layer"
            ref={playerRef}
          >
            <div id="player"></div>
          </div>
        </>
      )}
    </Wrap>
  )
}

export default Rooms(interestContainer(Preview))
