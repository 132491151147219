import { createSelector } from "reselect"

const getSuggestedAgents = state => state.network.suggestedAgents
const getFeedsList = state => state.network.networkFeeds.feed
const getExpertise = state => state.network.specializations
const getLikers = state => state.network.feedDetail.likers
const getAgentsFollowees = state => state.network.agentsFollowees
const getIsStandAloneFeed = state => state.network.feedDetail.isStandAloneFeed
export const isCardInactive = state => state.app.user.isCardInactive
const getLikerListForStandAlone = state => state.network.feedDetail.selectedFeed
  && state.network.feedDetail.selectedFeed.minifiedLikers

export const getSuggestedAgentsToDisplay = createSelector(
  [getSuggestedAgents],
  suggestedAgents => suggestedAgents.slice(0, 6)
)

export const getLikedAgentsToDisplay = createSelector(
  [getFeedsList],
  (feeds = []) => {
    feeds.map((feed) => {
      feed.likedAgents = {
        agentsToDisplay: feed.minifiedLikers && feed.minifiedLikers.slice(0, 4) || [],
        otherLikedAgents: feed.minifiedLikers && feed.minifiedLikers.slice(4) || [],
      }
    })
  }
)

export const getExpertiseToDisplay = createSelector(
  [getExpertise],
  (specializations = []) => specializations.map(specialization => ({
    title: specialization,
  }))
)

export const getLikersList = createSelector(
  [getLikers, getAgentsFollowees, getIsStandAloneFeed, getLikerListForStandAlone],
  (likers = [], agentsFollowees = [], isStandAloneFeed, standAloneLikerList) => {
    const whichLiker = isStandAloneFeed ? standAloneLikerList : likers
    const newLikers = whichLiker.map(liker => ({
      ...liker,
      isFollowing: agentsFollowees.indexOf(liker.id) > -1,
    }))
    return [...newLikers]
  }
)

//Create Edit Modal Selectors
export const getCreateEditModalState = state => state.network.createEditPostModal

export const getPeopleToFollowResponse = state => state.network.getPeopleToFollowResponse

export const getPeopleYouMayKnowResponse = state => state.network.getPeopleYouMayKnowResponse

export const getCurrentFollowers = state => state.profile.ownFollowersAndFollowing

//Onboarding/Personalize Feed Selectors
export const getShowPersonalizeFeedModalFromStore = state => state.network.showPersonalizeFeedModal

export const getShowPersonalizeFeedTabFromStore = state => state.network.showPersonalizeFeedTab

export const getUserCitiesFromStore = state => state.network.userCities

export const getTopicsFromStore = state => state.network.topics

export const getSelectedTopicsFromStore = state => state.network.selectedTopics

export const getSelectedExpertiseFromStore = state => state.network.selectedExpertise

export const getIsFetchingTopicsFromStore = state => state.network.isFetchingTopics

export const getIsFetchingAllSpecializationsFromStore = state => state.network.isFetchingAllSpecializations

export const getIsFetchingUserCitiesFromStore = state => state.network.isFetchingUserCities

export const getIsFetchingUserTopicsFromStore = state => state.network.isFetchingUserTopics

export const getIsFetchingUserSpecializationsFromStore = state => state.network.isFetchingUserSpecializations

export const getIsSavingCityPreferencesFromStore = state => state.network.isSavingCityPreferences

export const getIsSavingTopicsPreferencesFromStore = state => state.network.isSavingTopicsPreferences

export const getIsSavingSpecializationPreferencesFromStore = state => state.network.isSavingSpecializationPreferences

export const getIsFetchingTagsFromStore = state => state.network.isFetchingTags

//For Feed
export const getNetworkFeedsFromStore = state => state.network.networkFeeds

export const getIsFetchingFeedsFromStore = state => state.network.isFetchingFeeds

export const getIsPaginatedFetchFromStore = state => state.network.isPaginatedFetch

export const getFeedDetailFromStore = state => state.network.feedDetail

export const getUnansweredQuestionsFromStore = state => state.network.unansweredQuestions

export const getIsLikingFeedFromStore = state => state.network.isLikingFeed

export const getIsLikingCommentFromStore = state => state.network.isLikingComment

export const getSelectedGroupFromStore = state => state.network.selectedGroup

export const getIsPostingCommentFromStore = state => state.network.isPostingComment

export const getImagePreviewModalUrlFromStore = state => state.network.imagePreviewModalUrl

export const getSelectedOptionsOfFeedFromStore = state => state.network.networkFeeds.selectedOptions

//Right Container
export const getIsOpenDiscoverAgentsFromStore = state => state.network.isOpenDiscoverAgents

export const getIsFetchingSuggestedAgentsFromStore = state => state.network.isFetchingSuggestedAgents

//Network Common
export const getIsOnboardingCompleteFromStore = state => state.network.isOnboardingComplete

export const getAgentsFolloweesFromStore = state => state.network.agentsFollowees

export const getRequestingAgentToFollowFromStore = state => state.network.requestingAgentToFollow

export const getSuggestedTopicsFromStore = state => state.network.suggestedTopics

export const getSelectedIntroCitiesFromStore = state => state.network.introPost.selectedCities

export const getShowIntroModalFromStore = state => state.network.introPost.showModal

export const getUnfinishedStagesFromStore = state => state.network.unfinishedStages

export const getIsPostingIntroPostFromStore = state => state.network.introPost.isPosting

export const getSnippetPrevFromStore = state => state.network.snippetPrev

export const getNewCourseResponse = state => state.network.newCourseResponse

export const getNetworkActivitiesFromStore = state => state.network.networkActivityResponse

export const getPostInRadiusFeed = state => state.network.isShowPostInRadiusFeed
