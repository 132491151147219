import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"
const initState = {
  ...createDeltaReducer("searchCriteria"),
  ...createDeltaReducer("reminders"),
  ...createDeltaReducer("createReminder"),
  ...createDeltaReducer("properties"),
  ...createDeltaReducer("propertyDetails"),
  ...createDeltaReducer("clientNotes"),
  ...createDeltaReducer("deleteClientNote"),
  ...createDeltaReducer("salesStage"),
  ...createDeltaReducer("timeline", {
    isFetchingMore: false,
  }),
  ...createDeltaReducer("addContact"),
  ...createDeltaReducer("sendRequestClientUpdateResponse"),
  ...createDeltaReducer("updateContact"),
  ...createDeltaReducer("deleteContact"),
  ...createDeltaReducer("contactInfo", {}),
  ...createDeltaReducer("deletingReminder"),
  ...createDeltaReducer("buyerMatches", []),
  ...createDeltaReducer("editProperty", {}),
  ...createDeltaReducer("getPreferredLenderResponse"),
  ...createDeltaReducer("updatePreferredLenderResponse"),
  isOpenAddEditNoteModal: false,
  selectedNote: false,
  isSavingNotes: false,
  isOpenAdditionalDetailsModal: false,
  isOpenDeleteConfirmation: false,
  isReminderOpen: false,
  isShowEditReminderModal: false,
  isOpenEditProperty: false,
  isRequestModalOpen: false,
  isEditInfoModalOpen: false,
}

export default function clientProfileReducer(state = initState, action) {
  switch (action.type) {
    case Actions.fetchClientSearchCriteriaAction.REQUEST:
    case Actions.fetchClientSearchCriteriaAction.SUCCESS:
    case Actions.fetchClientSearchCriteriaAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientSearchCriteriaAction, "searchCriteria"),
      }
    }
    case Actions.fetchClientReminderAction.REQUEST:
    case Actions.fetchClientReminderAction.SUCCESS:
    case Actions.fetchClientReminderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientReminderAction, "reminders"),
      }
    }
    case Actions.fetchClientPropertiesAction.REQUEST:
    case Actions.fetchClientPropertiesAction.SUCCESS:
    case Actions.fetchClientPropertiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientPropertiesAction, "properties"),
      }
    }
    case Actions.fetchClientPropertiesDetailsActions.REQUEST:
    case Actions.fetchClientPropertiesDetailsActions.SUCCESS:
    case Actions.fetchClientPropertiesDetailsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientPropertiesDetailsActions, "propertyDetails"),
      }
    }
    case Actions.fetchClientNotesAction.REQUEST:
    case Actions.fetchClientNotesAction.SUCCESS:
    case Actions.fetchClientNotesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientNotesAction, "clientNotes"),
      }
    }

    case Actions.updateSalesStageAction.REQUEST:
    case Actions.updateSalesStageAction.SUCCESS:
    case Actions.updateSalesStageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateSalesStageAction, "salesStage"),
      }
    }

    case Actions.fetchClientTimelineAction.REQUEST:
    case Actions.fetchClientTimelineAction.SUCCESS:
    case Actions.fetchClientTimelineAction.CLEAR:
    case Actions.fetchClientTimelineAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchClientTimelineAction, "timeline"),
      }
    }

    case Actions.addContactAction.REQUEST:
    case Actions.addContactAction.SUCCESS:
    case Actions.addContactAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.addContactAction, "addContact"),
      }
    }

    case Actions.fetchEditPropertyAction.REQUEST:
    case Actions.fetchEditPropertyAction.SUCCESS:
    case Actions.fetchEditPropertyAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchEditPropertyAction, "editProperty"),
      }
    }

    case Actions.updateContactAction.REQUEST:
    case Actions.updateContactAction.SUCCESS:
    case Actions.updateContactAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateContactAction, "updateContact"),
      }
    }

    case Actions.deleteContactAction.REQUEST:
    case Actions.deleteContactAction.SUCCESS:
    case Actions.deleteContactAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteContactAction, "deleteContact"),
      }
    }

    case Actions.fetchContactInfoAction.REQUEST:
    case Actions.fetchContactInfoAction.SUCCESS:
    case Actions.fetchContactInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchContactInfoAction, "contactInfo"),
      }
    }
    //sendRequestClientUpdateResponse

    case Actions.sendRequestPayloadAction.REQUEST:
    case Actions.sendRequestPayloadAction.SUCCESS:
    case Actions.sendRequestPayloadAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendRequestPayloadAction, "sendRequestClientUpdateResponse"),
      }
    }

    case Actions.setReminderAction.REQUEST:
    case Actions.setReminderAction.SUCCESS:
    case Actions.setReminderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.setReminderAction, "createReminder"),
      }
    }

    case Actions.getPreferredLenderAction.REQUEST:
    case Actions.getPreferredLenderAction.SUCCESS:
    case Actions.getPreferredLenderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getPreferredLenderAction, "getPreferredLenderResponse"),
      }
    }

    case Actions.updatePreferredLenderAction.REQUEST:
    case Actions.updatePreferredLenderAction.SUCCESS:
    case Actions.updatePreferredLenderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updatePreferredLenderAction, "updatePreferredLenderResponse"),
      }
    }

    case Actions.toggleReminderEditModalAction.type: {
      return {
        ...state,
        isShowEditReminderModal: action.data,
      }
    }

    case Actions.deleteReminderAction.REQUEST: {
      const { reminderId } = action.data
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteReminderAction, "deletingReminder"),
        isDeletingReminder: reminderId,
      }
    }
    case Actions.deleteReminderAction.SUCCESS:
    case Actions.deleteReminderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteReminderAction, "deletingReminder"),
        isDeletingReminder: false,
      }
    }

    case Actions.toggleReminderDropdownAction.type: {
      return {
        ...state,
        isReminderOpen: action.data,
      }
    }
    case Actions.fetchBuyerMatchesAction.REQUEST:
    case Actions.fetchBuyerMatchesAction.SUCCESS:
    case Actions.fetchBuyerMatchesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchBuyerMatchesAction, "buyerMatches"),
      }
    }

    case Actions.updateChecklistAction.REQUEST: {
      return {
        ...state,
        isUpdatingSalesStage: true,
      }
    }
    case Actions.updateChecklistAction.SUCCESS:
    case Actions.updateChecklistAction.FAILURE: {
      return {
        ...state,
        isUpdatingSalesStage: false,
      }
    }

    case Actions.fetchedAllTimelineEventsAction.type: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          isFetchingMore: false,
          isFetchching: false,
          isFetchedAll: true,
        },
      }
    }

    case Actions.openAddEditNoteModalAction.type: {
      const { status, note } = action.data
      return {
        ...state,
        isOpenAddEditNoteModal: status,
        selectedNote: note || false,
      }
    }

    case Actions.openUpdateNoteModalAction.type: {
      const { status, note } = action.data
      return {
        ...state,
        isRequestModalOpen: status,
        selectedNote: note || false,
      }
    }

    case Actions.saveNotesAction.type: {
      return {
        ...state,
        isSavingNotes: action.data,
      }
    }

    case Actions.openAdditionalDetailsModalAction.type: {
      return {
        ...state,
        isOpenAdditionalDetailsModal: action.data,
      }
    }

    case Actions.openDeleteConfirmationAction.type: {
      return {
        ...state,
        isOpenDeleteConfirmation: action.data,
      }
    }

    case Actions.toggleSendRequestModalAction.type: {
      return {
        ...state,
        isRequestModalOpen: action.data,
      }
    }

    case Actions.toggleEditPropertyModalAction.type: {
      return {
        ...state,
        isOpenEditProperty: action.data,
      }
    }

    case Actions.togglePreferedLenderModalAction.type: {
      return {
        ...state,
        isUpdateModalOpen: action.data,
      }
    }

    case Actions.toogleEditInfoModalAction.type: {
      return {
        ...state,
        isEditInfoModalOpen: action.data,
      }
    }

    default: {
      return state
    }
  }
}
