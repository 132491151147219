import React from "react"
import styled from "styled-components"
import colors from "@colors"

//STYLE BLOCK - START//
const BenefitsWrap = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 38px;
`

const BenefitsList = styled.ul`
  height: 100%;
  width: 100%;
`

const Benefit = styled.li`
  height: 50px;
  padding: 32px 0px 0px 44px;
`

const Text = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  line-height: 1.61;
  text-align: left;
  color: ${colors.contentText}
`
//STYLE BLOCK - END//

/**
Function - renderBenefits
usage - renders list of benefits based on plan type ( Single or Team )
params - type { string }
       - pricingPlans { array }
**/

const renderBenefits = (type, pricingPlans) => {
  const { data } = pricingPlans
  if (data) {
    const {
      benefits: {
        single_plans,
        team_plans,
      },
    } = data
    return (
      <BenefitsList>
        {type === "Single" ? (
          single_plans.map(key => (
            <Benefit>
              <Text>{key.benefit_text}</Text>
            </Benefit>
          ))
        ) : (
          team_plans.map(key => (
            <Benefit>
              <Text>{key.benefit_text}</Text>
            </Benefit>
          ))
        )}
      </BenefitsList>
    )
  }

  return null
}

const PlanBenefitsList = (
  {
    type,
    pricingPlans,
  }
) => (
  <BenefitsWrap>
    {renderBenefits(type, pricingPlans)}
  </BenefitsWrap>
)

export default PlanBenefitsList
