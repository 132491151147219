import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import { findIndex, get, isEmpty } from "lodash"
import { checkForUserObject } from "services/Utils"
import {
  getAllFeedsAction,
  getAllTopicsAction,
  getCityPreferencesAction,
  setCityPreferencesAction,
  getTagsBasedOnTopicsAction,
  getAllSpecializationsAction,
  followAgentAction,
  getSuggestedAgentsAction,
  getCommentsForFeedAction,
  getOnboardingStatusAction,
  uploadPostImageAction,
  getUnansweredQuestionsActions,
  postCommentForFeedAction,
  getTopicsPreferencesAction,
  setTopicsPreferencesAction,
  getSpecializationPreferencesAction,
  setSpecializationPreferencesAction,
  updateFeedLikeStandAloneAction,
  setTopicAndGetTagsAction,
  createUpdateFeedItemAction,
  getSuggestedTopicsAction,
  getSnippetDataAction,
  storeMutedFeedIdAction,
  OPEN_INVITE_AGENTS_MODAL,
  CLOSE_INVITE_AGENTS_MODAL,
  CHANGE_FEED_SELECTION,
  FETCHING_PAGINATED_CALLS,
  SET_USER_CITIES,
  UPDATE_SPECIALIZATION_PREFERENCE,
  UPDATE_TOPICS_PREFERENCE,
  OPEN_FEED_DETAIL_VIEW,
  CLOSE_FEED_DETAIL_VIEW,
  SET_ONBOARDING_COMPLETE,
  RESET_CREATE_EDIT_MODAL,
  CHANGE_POST_MODAL_STATE,
  TOGGLE_PERSONALIZE_FEED_MODAL,
  OPEN_LIKERS,
  CLOSE_LIKERS,
  getLikersAction,
  OPEN_DISCOVER_AGENTS,
  CLOSE_DISCOVER_AGENTS,
  updateFeedLikeAction,
  updateCommentLikeAction,
  SET_COMMUNITY_GROUP,
  SAVE_INIT_CREATE_EDIT_MODAL,
  unfollowAgentAction,
  UPDATE_FEED_ITEM_ON_EDIT,
  UPDATE_AUTO_FOCUS,
  OPEN_IMAGE_PREVIEW_MODAL,
  CLOSE_IMAGE_PREVIEW_MODAL,
  RESET_FEED_CACHE,
  followSuggestedTopicAction,
  UPDATE_SELECTED_INTRO_CITIES,
  TOGGLE_INTRO_POST_MODAL,
  createIntroPostAction,
  UPDATE_CREATE_EDIT_POST_MODAL,
  getLatestCourseFromAcademyAction,
  insertNewlyCreatedQuestionsInFeedAction,
  confirmFeedDeleteAction,
  getStandAloneFeedAction,
  reportOrDeleteAction,
  getNetworkActivityAction,
  getPeopleToFollowAction,
  getPeopleYouMayKnowAction,
  toggleReportModalAction,
  getBlockedReasonsAction,
  mutePostAction,
  togglePostInRadiusFeedAction,
  removeMediaAndLinkifyAction,
  removeLinkFromPostEditAction,
} from "./actions"

const standAloneFeed = get(root, "__STATE__.feedDetail", {})
const user = checkForUserObject("user")

export const createEditPostModalEssentialState = {
  topicId: null,
  selectedTags: {},
  mediaType: null,
  mediaURL: null,
  mediaArray: [],
  isMediaUploading: false,
  feedId: null,
  city: null,
  state: null,
  country: null,
  latitude: null,
  longitude: null,
  snippetURL: null,
  snippetTitle: null,
  snippetImageURL: null,
}

export const createEditPostModalInitialState = {
  show: false,
  type: "post",
  groupId: null,
  editMode: false,
  isPosting: false,
  tags: [],
  showConfirmCloseModal: false,
  ...createEditPostModalEssentialState,
}

const initState = {
  isFetchingFeeds: false,
  isFetchingFeedsFirstTime: true,
  isPaginatedFetch: false,
  isShowFeedDeleteModal: false,
  agentsFollowees: [],
  networkFeeds: {
    feed: [],
    feedIds: [],
    groups: [],
    topics: [],
    selectedOptions: {
      topic_ids: false,
      type: false,
      feed_ids: false,
    },
  },
  mutedFeeds: [],
  unansweredQuestions: {
    isFetching: false,
    feed: [],
    feedIds: [],
    lastFetchedFrom: 0,
  },
  isLikingFeed: false,
  isLikingComment: false,
  isFetchingTags: false,
  isOpenInviteAgentModal: false,
  isFetchingSuggestedAgents: false,
  isOpenDiscoverAgents: false,
  userCities: [],
  specializations: [],
  selectedExpertise: {},
  topics: [],
  selectedTopics: {},
  topicsSelectedCount: 0,
  suggestedAgents: [],
  followedAgents: [],
  isDeletingFeed: false,
  isNewQuestions: false,
  feedDetail: {
    isOpen: false,
    isFetchingComments: false,
    isFetchingLikers: false,
    isOpenLikers: false,
    shouldAutoFocus: false,
    likersViewType: null,
    comments: [],
    commentsCount: 0,
    likers: [],
    selectedFeed: standAloneFeed,
    isStandAloneFeed: !isEmpty(standAloneFeed),
  },
  isOnboardingComplete: CookiesStorage.load("onboardingComplete") === "true",
  createEditPostModal: {
    ...createEditPostModalInitialState,
  },
  createEditPostModalInitData: null,
  postCommentForFeed: {
    comment: null,
    feedId: null,
  },
  isPostingComment: false,
  selectedGroup: null,
  showPersonalizeFeedModal: false,
  showPersonalizeFeedTab: "cities",
  isFetchingTopics: false,
  isFetchingAllSpecializations: false,
  isFetchingUserCities: false,
  isFetchingUserTopics: false,
  isFetchingUserSpecializations: false,
  isSavingCityPreferences: false,
  isSavingTopicsPreferences: false,
  isSavingSpecializationPreferences: false,
  requestingAgentToFollow: null,
  imagePreviewModalUrl: null,
  suggestedTopics: [],
  introPost: {
    selectedCities: {},
    showModal: false,
    isPosting: false,
  },
  unfinishedStages: [],
  isShowNewCourseBanner: true,
  isShowPostInRadiusFeed: false,
  ...createDeltaReducer("newCourseResponse"),
  ...createDeltaReducer("networkActivityResponse"),
  ...createDeltaReducer("getPeopleToFollowResponse"),
  ...createDeltaReducer("getPeopleYouMayKnowResponse"),
  ...createDeltaReducer("reportReasonsResponse"),
  ...createDeltaReducer("mutePostResponse"),
  ...createDeltaReducer(togglePostInRadiusFeedAction.RESPONSE),
}

export default function networkReducer(state = initState, action) {
  switch (action.type) {
    case getLatestCourseFromAcademyAction.REQUEST:
    case getLatestCourseFromAcademyAction.SUCCESS:
    case getLatestCourseFromAcademyAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, getLatestCourseFromAcademyAction, "newCourseResponse"),
      }
    }

    case getPeopleToFollowAction.REQUEST:
    case getPeopleToFollowAction.SUCCESS:
    case getPeopleToFollowAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, getPeopleToFollowAction, "getPeopleToFollowResponse"),
      }
    }

    case getPeopleYouMayKnowAction.REQUEST:
    case getPeopleYouMayKnowAction.SUCCESS:
    case getPeopleYouMayKnowAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, getPeopleYouMayKnowAction, "getPeopleYouMayKnowResponse"),
      }
    }

    case getNetworkActivityAction.REQUEST:
    case getNetworkActivityAction.SUCCESS:
    case getNetworkActivityAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, getNetworkActivityAction, "networkActivityResponse"),
      }
    }

    case getBlockedReasonsAction.REQUEST:
    case getBlockedReasonsAction.SUCCESS:
    case getBlockedReasonsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, getBlockedReasonsAction, "reportReasonsResponse"),
      }
    }

    case mutePostAction.REQUEST:
    case mutePostAction.SUCCESS:
    case mutePostAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, mutePostAction, "mutePostResponse"),
      }
    }

    case insertNewlyCreatedQuestionsInFeedAction.type: {
      return {
        ...state,
        unansweredQuestions: {
          ...state.unansweredQuestions,
          feed: [
            action.data,
            ...state.unansweredQuestions.feed,
          ],
        },
      }
    }

    case confirmFeedDeleteAction.type: {
      return {
        ...state,
        isShowFeedDeleteModal: action.data,
      }
    }

    case togglePostInRadiusFeedAction.type: {
      return {
        ...state,
        isShowPostInRadiusFeed: action.data,
      }
    }

    case getStandAloneFeedAction.REQUEST: {
      return {
        ...state,
        feedError: {},
      }
    }

    case getStandAloneFeedAction.FAILURE: {
      return {
        ...state,
        feedError: action.data,
      }
    }

    case getAllFeedsAction.REQUEST: {
      if (action.data) {
        const {
          selectedOptions: { feed_ids },
        } = action.data

        return {
          ...state,
          isFetchingFeeds: true,
          isPaginatedFetch: !!feed_ids,
        }
      }
      return {
        ...state,
        isFetchingFeeds: true,
      }
    }

    case reportOrDeleteAction.REQUEST: {
      return {
        ...state,
        isDeletingFeed: true,
      }
    }
    case reportOrDeleteAction.SUCCESS: {
      const { newFeed } = action.data
      return {
        ...state,
        isDeletingFeed: false,
        networkFeeds: {
          ...state.networkFeeds,
          feed: newFeed,
        },
      }
    }

    case getAllFeedsAction.SUCCESS: {
      const {
        feed,
        feedIds,
        topics,
        isPaginated,
        groups,
        agentsFollowees,
        topFeedIds,
      } = action.data
      if (isPaginated) {
        return {
          ...state,
          isFetchingFeedsFirstTime: false,
          isFetchingFeeds: false,
          isPaginatedFetch: false,
          networkFeeds: {
            ...state.networkFeeds,
            feed,
          },
        }
      }
      return {
        ...state,
        agentsFollowees,
        isFetchingFeeds: false,
        isPaginatedFetch: false,
        isFetchingFeedsFirstTime: false,
        networkFeeds: {
          ...state.networkFeeds,
          feed,
          feedIds,
          topics,
          groups,
          topFeedIds,
        },
      }
    }

    case getAllFeedsAction.FAILURE: {
      return {
        ...state,
        isFetchingFeeds: false,
        isPaginatedFetch: false,
        isFetchingFeedsFirstTime: false,
      }
    }

    case setTopicAndGetTagsAction.REQUEST: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
        },
        isFetchingTags: true,
      }
    }

    case setTopicAndGetTagsAction.SUCCESS:
    case setTopicAndGetTagsAction.FAILURE: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
        },
        isFetchingTags: false,
      }
    }

    case getTagsBasedOnTopicsAction.REQUEST: {
      return {
        ...state,
        isFetchingTags: true,
      }
    }

    case getTagsBasedOnTopicsAction.SUCCESS:
    case getTagsBasedOnTopicsAction.FAILURE: {
      return {
        ...state,
        isFetchingTags: false,
      }
    }

    case getCityPreferencesAction.REQUEST: {
      return {
        ...state,
        isFetchingUserCities: true,
      }
    }

    case getCityPreferencesAction.SUCCESS:
    case SET_USER_CITIES: {
      const { cities } = action.data
      return {
        ...state,
        userCities: cities,
        isFetchingUserCities: false,
      }
    }

    case getSnippetDataAction.REQUEST: {
      return {
        ...state,
        isFetchingSnippet: true,
      }
    }

    case getSnippetDataAction.SUCCESS: {
      return {
        ...state,
        isFetchingSnippet: false,
        snippetPrev: action.data,
      }
    }

    // case UPDATE_CREATE_EDIT_POST_MODAL: {
    //   return {
    //     ...state,
    //     snippetPrev: action.data,
    //   }
    // }

    case getCityPreferencesAction.FAILURE: {
      return {
        ...state,
        isFetchingUserCities: false,
      }
    }

    case setCityPreferencesAction.REQUEST: {
      return {
        ...state,
        isSavingCityPreferences: true,
      }
    }

    case setCityPreferencesAction.SUCCESS:
    case setCityPreferencesAction.FAILURE: {
      return {
        ...state,
        isSavingCityPreferences: false,
      }
    }

    case getAllSpecializationsAction.REQUEST: {
      return {
        ...state,
        isFetchingAllSpecializations: true,
      }
    }

    case getAllSpecializationsAction.SUCCESS: {
      const { specializations } = action.data
      return {
        ...state,
        specializations,
        isFetchingAllSpecializations: false,
      }
    }

    case getAllSpecializationsAction.FAILURE: {
      return {
        ...state,
        isFetchingAllSpecializations: false,
      }
    }

    case getAllTopicsAction.REQUEST: {
      return {
        ...state,
        isFetchingTopics: true,
      }
    }

    case getAllTopicsAction.SUCCESS: {
      const { topics } = action.data
      return {
        ...state,
        isFetchingTopics: false,
        topics,
      }
    }

    case getAllTopicsAction.FAILURE: {
      return {
        ...state,
        isFetchingTopics: false,
      }
    }

    case getSuggestedAgentsAction.REQUEST: {
      return {
        ...state,
        isFetchingSuggestedAgents: true,
      }
    }

    case getSuggestedAgentsAction.SUCCESS: {
      const { suggestedAgents } = action.data
      return {
        ...state,
        suggestedAgents,
        isFetchingSuggestedAgents: false,
      }
    }

    case getSuggestedAgentsAction.FAILURE: {
      return {
        ...state,
        isFetchingSuggestedAgents: false,
      }
    }

    case followAgentAction.REQUEST: {
      const {
        agent: { id },
      } = action.data
      return {
        ...state,
        requestingAgentToFollow: id,
      }
    }

    case followAgentAction.SUCCESS: {
      const { suggestedAgents, followedAgents, agentId } = action.data
      if (agentId) {
        return {
          ...state,
          agentsFollowees: state.agentsFollowees.concat(agentId),
          requestingAgentToFollow: null,
        }
      }
      return {
        ...state,
        suggestedAgents,
        followedAgents,
        requestingAgentToFollow: null,
      }
    }

    case followAgentAction.FAILURE: {
      return {
        ...state,
        requestingAgentToFollow: null,
      }
    }

    case unfollowAgentAction.REQUEST: {
      const {
        agentId,
      } = action.data
      return {
        ...state,
        requestingAgentToFollow: agentId,
      }
    }

    case unfollowAgentAction.SUCCESS: {
      return {
        ...state,
        requestingAgentToFollow: null,
        agentsFollowees: state.agentsFollowees.filter(agentId => action.data !== agentId),
      }
    }

    case unfollowAgentAction.FAILURE: {
      return {
        ...state,
        requestingAgentToFollow: null,
      }
    }

    case getCommentsForFeedAction.REQUEST: {
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isFetchingComments: true,
        },
      }
    }

    case getCommentsForFeedAction.SUCCESS: {
      const { comments, commentsCount } = action.data
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isFetchingComments: false,
          comments,
          commentsCount,
        },
      }
    }

    case getCommentsForFeedAction.FAILURE: {
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isFetchingComments: false,
        },
      }
    }

    case getLikersAction.REQUEST: {
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isFetchingLikers: true,
        },
      }
    }

    case getLikersAction.SUCCESS: {
      const { likers, agentsFollowees } = action.data
      return {
        ...state,
        agentsFollowees,
        feedDetail: {
          ...state.feedDetail,
          isFetchingLikers: false,
          likers,
        },
      }
    }

    case getLikersAction.FAILURE: {
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isFetchingLikers: false,
        },
      }
    }

    case updateFeedLikeAction.REQUEST: {
      const {
        id,
        likedByMe,
        type,
        agentFeeds,
        isOnProfile,
      } = action.data
      if (isOnProfile) {
        return {
          ...state,
        }
      }
      const {
        feedDetail: { feedSource },
      } = state
      let feedKey = feedSource || "networkFeeds"
      if (type === "question") {
        feedKey = "unansweredQuestions"
      }
      const {
        [feedKey]: { feed },
      } = state
      const feedIndex = feed.findIndex(x => x.id === id)
      const agentFeedIndex = agentFeeds && agentFeeds.length > 0 && agentFeeds.findIndex(x => x.id === id)
      let newFeeds = [...feed]
      if (newFeeds.length <= 0) {
        newFeeds = [...agentFeeds]
      }
      newFeeds[feedIndex > -1 ? feedIndex : agentFeedIndex].likedByMe = !likedByMe
      if (!likedByMe) {
        newFeeds[feedIndex > -1 ? feedIndex : agentFeedIndex].likesCount += 1
        newFeeds[feedIndex > -1 ? feedIndex : agentFeedIndex].minifiedLikers.push(user)
      } else {
        newFeeds[feedIndex > -1 ? feedIndex : agentFeedIndex].likesCount -= 1
        newFeeds[feedIndex > -1 ? feedIndex
          : agentFeedIndex].minifiedLikers = newFeeds[feedIndex > -1 ? feedIndex
          : agentFeedIndex].minifiedLikers.filter(liker => liker.id !== user.id)
      }
      return {
        ...state,
        isLikingFeed: id,
        [feedKey]: {
          ...state[feedKey],
          feed: newFeeds,
        },
      }
    }

    case updateFeedLikeAction.SUCCESS: {
      return {
        ...state,
        isLikingFeed: false,
      }
    }

    case updateFeedLikeAction.FAILURE: {
      return {
        ...state,
        isLikingFeed: false,
      }
    }

    case updateCommentLikeAction.REQUEST: {
      const { id, likedByMe } = action.data
      const {
        feedDetail: { comments },
      } = state
      const commentIndex = findIndex(comments, { id })
      const newComments = [...comments]
      newComments[commentIndex].likedByMe = !likedByMe
      newComments[commentIndex].likersCount = !likedByMe
        ? newComments[commentIndex].likersCount + 1
        : newComments[commentIndex].likersCount - 1
      return {
        ...state,
        isLikingComment: id,
        feedDetail: {
          ...state.feedDetail,
          comments: newComments,
        },
      }
    }

    case updateCommentLikeAction.SUCCESS: {
      return {
        ...state,
        isLikingComment: false,
      }
    }

    case updateCommentLikeAction.FAILURE: {
      return {
        ...state,
        isLikingComment: false,
      }
    }

    case postCommentForFeedAction.REQUEST: {
      return {
        ...state,
        isPostingComment: true,
        postCommentForFeed: {
          ...state.postCommentForFeed,
        },
      }
    }

    case postCommentForFeedAction.SUCCESS: {
      const { feedId } = action.data
      const {
        feedDetail: { feedSource },
      } = state
      const feedKey = feedSource || "networkFeeds"
      const {
        [feedKey]: { feed },
      } = state
      const feedIndex = feed && feed.length > 0 && feed.findIndex(x => x.id === feedId)
      const newFeeds = [...feed]
      if (feedIndex !== -1 && newFeeds[feedIndex]) {
        newFeeds[feedIndex].commentsCount += 1
      }

      return {
        ...state,
        isPostingComment: false,
        postCommentForFeed: {
          ...state.postCommentForFeed,
          comment: null,
          feedId: null,
        },
        networkFeeds: {
          ...state.networkFeeds,
          feed: newFeeds,
        },
      }
    }

    case postCommentForFeedAction.FAILURE: {
      return {
        ...state,
        isPostingComment: false,
        postCommentForFeed: {
          ...state.postCommentForFeed,
        },
      }
    }

    case getOnboardingStatusAction.SUCCESS: {
      const { isOnboardingComplete, unfinishedStages } = action.data
      return {
        ...state,
        isOnboardingComplete,
        unfinishedStages,
      }
    }

    case uploadPostImageAction.REQUEST: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          isMediaUploading: true,
        },
      }
    }

    case uploadPostImageAction.SUCCESS: {
      const { imageURL, mediaType } = action.data
      const existingImages = state.createEditPostModal.mediaArray
      const newImage = {
        imageId: imageURL,
        url: imageURL,
      }
      const updated = [...existingImages, newImage]
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          // mediaURL: imageURL,
          isMediaUploading: false,
          mediaType,
          mediaArray: updated,
        },
      }
    }

    case uploadPostImageAction.FAILURE: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          mediaType: null,
          mediaURL: null,
          isMediaUploading: false,
        },
      }
    }

    case getUnansweredQuestionsActions.REQUEST: {
      return {
        ...state,
        unansweredQuestions: {
          ...state.unansweredQuestions,
          isFetching: true,
        },
      }
    }

    case getTopicsPreferencesAction.REQUEST: {
      return {
        ...state,
        isFetchingUserTopics: true,
      }
    }

    case getTopicsPreferencesAction.SUCCESS: {
      const { selectedTopics } = action.data
      return {
        ...state,
        selectedTopics,
        isFetchingUserTopics: false,
      }
    }

    case getTopicsPreferencesAction.FAILURE: {
      return {
        ...state,
        isFetchingUserTopics: false,
      }
    }

    case setTopicsPreferencesAction.REQUEST: {
      return {
        ...state,
        isSavingTopicsPreferences: true,
      }
    }

    case setTopicsPreferencesAction.SUCCESS:
    case setTopicsPreferencesAction.FAILURE: {
      return {
        ...state,
        isSavingTopicsPreferences: false,
      }
    }

    case getUnansweredQuestionsActions.SUCCESS:
    case getUnansweredQuestionsActions.FAILURE: {
      return {
        ...state,
        unansweredQuestions: {
          ...state.unansweredQuestions,
          isFetching: false,
          ...action.data,
        },
      }
    }

    case getSpecializationPreferencesAction.REQUEST: {
      return {
        ...state,
        isFetchingUserSpecializations: true,
      }
    }

    case getSpecializationPreferencesAction.SUCCESS: {
      const { selectedExpertise } = action.data
      return {
        ...state,
        selectedExpertise,
        isFetchingUserSpecializations: false,
      }
    }

    case getSpecializationPreferencesAction.FAILURE: {
      return {
        ...state,
        isFetchingUserSpecializations: false,
      }
    }

    case setSpecializationPreferencesAction.REQUEST: {
      return {
        ...state,
        isSavingSpecializationPreferences: true,
      }
    }

    case setSpecializationPreferencesAction.SUCCESS:
    case setSpecializationPreferencesAction.FAILURE: {
      return {
        ...state,
        isSavingSpecializationPreferences: false,
      }
    }

    case createUpdateFeedItemAction.REQUEST: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          isPosting: true,
        },
      }
    }

    case createUpdateFeedItemAction.SUCCESS: {
      const {
        type,
      } = action.data
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          type,
          isPosting: false,
        },
      }
    }
    case createUpdateFeedItemAction.FAILURE: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          isPosting: false,
        },
      }
    }

    case getSuggestedTopicsAction.SUCCESS: {
      const { topics } = action.data
      return {
        ...state,
        suggestedTopics: topics,
      }
    }

    case getSuggestedTopicsAction.FAILURE: {
      return {
        ...state,
        suggestedTopics: [],
      }
    }

    case followSuggestedTopicAction.SUCCESS: {
      const { suggestedTopics } = action.data
      return {
        ...state,
        suggestedTopics,
      }
    }

    case UPDATE_SPECIALIZATION_PREFERENCE: {
      const { selectedExpertise } = action.data
      return {
        ...state,
        selectedExpertise,
      }
    }

    case UPDATE_TOPICS_PREFERENCE: {
      const { selectedTopics } = action.data
      return {
        ...state,
        selectedTopics,
      }
    }

    case OPEN_INVITE_AGENTS_MODAL: {
      return {
        ...state,
        isOpenInviteAgentModal: true,
      }
    }

    case CLOSE_INVITE_AGENTS_MODAL: {
      return {
        ...state,
        isOpenInviteAgentModal: false,
      }
    }

    case FETCHING_PAGINATED_CALLS: {
      return {
        ...state,
        isFetchingFeeds: true,
      }
    }

    case CHANGE_FEED_SELECTION: {
      return {
        ...state,
        networkFeeds: {
          ...state.networkFeeds,
          selectedOptions: {
            ...state.networkFeeds.selectedOptions,
            ...action.data,
          },
        },
      }
    }
    case OPEN_FEED_DETAIL_VIEW: {
      const { selectedFeed, feedSource, shouldAutoFocus } = action.data
      const { isStandAloneFeed } = state.feedDetail
      //const { feed_url = "" } = selectedFeed
      const beforeFeedOpenURL = root.location.href
      //const route = feed_url && feed_url.replace(WEB_URL, "")
      // root.history.pushState("", "", `/${route}`)
      selectedFeed.likedAgents = {
        agentsToDisplay:
          selectedFeed.minifiedLikers
          && selectedFeed.minifiedLikers.length > 0
          && selectedFeed.minifiedLikers.slice(0, 4),
        otherLikedAgents:
          selectedFeed.minifiedLikers && selectedFeed.minifiedLikers.length > 0 && selectedFeed.minifiedLikers.slice(4),
      }
      return {
        ...state,
        beforeFeedOpenURL,
        feedError: {},
        feedDetail: {
          isOpen: true,
          selectedFeed,
          feedSource,
          shouldAutoFocus,
          isStandAloneFeed,
        },
      }
    }
    case CLOSE_FEED_DETAIL_VIEW: {
      const { feedDetail, beforeFeedOpenURL } = state
      const { selectedFeed, isStandAloneFeed } = feedDetail
      //root.history.pushState("", "", beforeFeedOpenURL)
      return {
        ...state,
        beforeFeedOpenURL: "",
        feedDetail: {
          isStandAloneFeed,
          isFeed: false,
          selectedFeed: isStandAloneFeed ? selectedFeed : {},
        },
      }
    }

    case UPDATE_AUTO_FOCUS: {
      const { shouldAutoFocus } = action.data
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          shouldAutoFocus,
        },
      }
    }

    case SET_ONBOARDING_COMPLETE: {
      return {
        ...state,
        isOnboardingComplete: true,
      }
    }

    case CHANGE_POST_MODAL_STATE: {
      return {
        ...state,
        createEditPostModal: {
          ...action.data,
        },
      }
    }

    case RESET_CREATE_EDIT_MODAL: {
      const {
        type,
      } = action.data
      return {
        ...state,
        createEditPostModal: {
          ...createEditPostModalInitialState,
          type,
        },
      }
    }

    case OPEN_LIKERS: {
      const { likersViewType } = action.data
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isOpen: false,
          isOpenLikers: true,
          likersViewType,
        },
      }
    }

    case CLOSE_LIKERS: {
      return {
        ...state,
        feedDetail: {
          ...state.feedDetail,
          isOpenLikers: false,
          likersViewType: false,
        },
      }
    }

    case OPEN_DISCOVER_AGENTS: {
      return {
        ...state,
        isOpenDiscoverAgents: true,
      }
    }

    case CLOSE_DISCOVER_AGENTS: {
      return {
        ...state,
        isOpenDiscoverAgents: false,
      }
    }

    case SET_COMMUNITY_GROUP: {
      const { groupId } = action.data
      return {
        ...state,
        selectedGroup: groupId,
      }
    }

    case TOGGLE_PERSONALIZE_FEED_MODAL: {
      const { show, type } = action.data
      return {
        ...state,
        showPersonalizeFeedModal: show,
        showPersonalizeFeedTab: type || "cities",
      }
    }

    case updateFeedLikeStandAloneAction.SUCCESS: {
      const { likedByMe } = action.data
      const {
        feedDetail: { selectedFeed, ...otherProps },
      } = state
      selectedFeed.likedByMe = likedByMe
      return {
        ...state,
        feedDetail: {
          ...otherProps,
          selectedFeed,
        },
      }
    }
    case SAVE_INIT_CREATE_EDIT_MODAL: {
      return {
        ...state,
        createEditPostModalInitData: {
          ...action.data,
        },
      }
    }

    case UPDATE_FEED_ITEM_ON_EDIT: {
      const { feed } = action.data
      return {
        ...state,
        networkFeeds: {
          ...state.networkFeeds,
          feed,
        },
      }
    }

    case OPEN_IMAGE_PREVIEW_MODAL: {
      const { url } = action.data
      return {
        ...state,
        imagePreviewModalUrl: url,
      }
    }

    case CLOSE_IMAGE_PREVIEW_MODAL: {
      return {
        ...state,
        imagePreviewModalUrl: false,
      }
    }

    case RESET_FEED_CACHE: {
      return {
        ...state,
        networkFeeds: {
          ...state.networkFeeds,
          selectedOptions: {
            type: false,
            feed_ids: false,
            topic_ids: false,
          },
        },
        selectedGroup: null,
      }
    }

    case UPDATE_SELECTED_INTRO_CITIES: {
      const { selectedCities } = action.data
      return {
        ...state,
        introPost: {
          ...state.introPost,
          selectedCities,
        },
      }
    }

    case TOGGLE_INTRO_POST_MODAL: {
      const showModal = action.data
      return {
        ...state,
        introPost: {
          ...state.introPost,
          showModal,
        },
      }
    }

    case createIntroPostAction.REQUEST: {
      return {
        ...state,
        introPost: {
          ...state.introPost,
          isPosting: true,
        },
      }
    }

    case createIntroPostAction.SUCCESS:
    case createIntroPostAction.FAILURE: {
      return {
        ...state,
        introPost: {
          ...state.introPost,
          isPosting: false,
        },
      }
    }

    case toggleReportModalAction.type: {
      return {
        ...state,
        isShowReportModal: action.data,
      }
    }

    case removeMediaAndLinkifyAction.type: {
      const {
        id,
      } = action.data

      const currentStoreOfImages = state.createEditPostModal.mediaArray
      const filtered = currentStoreOfImages.filter(img => id !== img.imageId)

      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          mediaArray: filtered,
        },
      }
    }

    case removeLinkFromPostEditAction.type: {
      return {
        ...state,
        createEditPostModal: {
          ...state.createEditPostModal,
          snippetURL: null,
          snippetTitle: null,
          snippetImageURL: null,
        },
      }
    }

    case storeMutedFeedIdAction.type: {
      const {
        mutedFeeds,
      } = action.data
      return {
        ...state,
        mutedFeeds,
      }
    }

    default:
      return state
  }
}
