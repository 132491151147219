import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import RealtyContainer from "container/Realty"
import RARPopupContainer from "container/App/RARPopUpContainer"
import root from "window-or-global"
import { tablet, xsMobile, preDesktopWidth } from "@consts"
import cookie from "services/CookieStorage"
import RealtyModalForm from "dumbComponents/Referral/common/RealtyCommonModalForm"

const Wrap = styled.div`
  padding: 60px 0px 20px 0px;
  background: transparent;
  ${tablet`
    padding: 0px 50px 50px;
  `}
  ${xsMobile`
    padding: 0px 35px 35px;
  `}
`

const ModalFormWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${xsMobile`
    margin-top: 0px;
    justify-content: flex-start;
    flex-wrap: wrap;
  `}
  color: ${colors.eggYolkWhite};
`

const AlreadySubmitted = styled.p`
  width: 340px;
  text-align: center;
  font-size: 20px;

  ${preDesktopWidth`
    width: auto;
  `}
`

const PostSubmission = styled.div`
  border: 2px solid white;
  padding: 21px;
  border-radius: 18px;
`

class RARFormModal extends React.Component {
  state = {
    formError: false,
    interestViaPopUp: false,
  }

  componentDidMount() {
    this.setState({
      interestViaPopUp: cookie.load("interestViaPopUp"),
    })
  }

  handleFormSubmit = (values) => {
    const {
      submitScheduleACall,
      addressTextBoxValue,
      currentState,
      currentCity,
      currentRARPlanSelected,
      teamSize,
      history,
      lmpForm,
    } = this.props

    const promoAgentId = parseInt(cookie.load("realty_promotion_id"), 10)
    const payload = values
    payload.state = currentState
    payload.city = currentCity
    payload.rar_promotions_source_page = cookie.load("rar_promotions_source_page") || ""
    if (promoAgentId) {
      payload.from_agent_id = promoAgentId || ""
    }
    if (cookie.load("utm_source")) {
      payload.utm_source = cookie.load("utm_source")
    }
    // if (!payload.state) {
    //   throw new SubmissionError({ city: "Please select city from the list" })
    // }

    if (currentRARPlanSelected && Object.keys(currentRARPlanSelected).length > 0) {
      payload.plan_name_display = currentRARPlanSelected.plan_name_display
      payload.is_team_plan = currentRARPlanSelected.is_team_plan
      payload.plan_name_internal = currentRARPlanSelected.plan_name_internal

      if (currentRARPlanSelected.is_team_plan === "1") {
        payload.team_members_count = teamSize
      }
    }

    delete payload.isFromList
    if (root.gtag) {
      root.gtag("event", "conversion", {
        send_to: "AW-415349152/5g0ICO_2pvkBEKDzhsYB",
        event_callback: () => { console.log("Gtag Event Logged") },
      })
    }
    if (currentCity) {
      this.setState({
        formError: false,
      })
      submitScheduleACall({
        payload,
        redirect: !lmpForm,
        history,
      })
    } else {
      this.setState({
        formError: true,
      })
    }
  }

  render() {
    const {
      formError,
      interestViaPopUp,
    } = this.state

    const {
      user,
      setCurrentLocationForRealty,
      currentCity,
      //headerFontSize,
      toClose,
      scheduleACallResponse,
    } = this.props

    const {
      isFetching: fetchingData,
    } = scheduleACallResponse || {}

    return (
      <Modal
        show
        toClose={toClose}
        width="800px"
        overlayColor="#190a36d1"
        modalStyles={{
          padding: 0,
        }}
        overlayStyles={{
          zIndex: 1000,
        }}
        customDialogColor="transparent"
        dialogStyles={{
          background: "transparent",
          position: "relative",
          top: "31px",
        }}
        customIconStyle={{
          position: "absolute",
          top: "6px",
          width: "35px",
          right: "154px",
          cursor: "pointer",
        }}
        customMobileIconStyle={{
          position: "absolute",
          top: "-44px",
          width: "35px",
          right: "38px",
          cursor: "pointer",
        }}
        modalVersion={2}
      >
        <Wrap>
          <ModalFormWrap>
            {interestViaPopUp ? (
              <PostSubmission>
                <AlreadySubmitted>
                  You&apos;ve already submitted the form.
                </AlreadySubmitted>
              </PostSubmission>
            ) : (
              <RealtyModalForm
                formError={formError}
                currentCity={currentCity}
                user={user}
                onSubmit={this.handleFormSubmit}
                setCurrentLocationForRealty={setCurrentLocationForRealty}
                isOpenForm
                inPage
                inModal
                fetchingData={fetchingData}
              />
            )}
          </ModalFormWrap>
        </Wrap>
      </Modal>
    )
  }
}

export default withRouter(RealtyContainer(RARPopupContainer(RARFormModal)))
