import React, { useState } from "react";
import {
  Header,
  ButtonShow,
  ColumnTitle,
  Row,
  Border,
  ColumnData,
  Wrap,
} from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const PricingHistory = ({ listingPageResponse }) => {
  const formatDateString = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const [showAllEntries, setShowAllEntries] = useState(false);

  const initialEntryCount = 1;

  const toggleShowAllEntries = () => {
    setShowAllEntries(!showAllEntries);
  };

  const renderEntries = () => {
    const entryCount = showAllEntries
      ? listingPageResponse?.data?.pricingHistory.length
      : initialEntryCount;
    return listingPageResponse?.data?.pricingHistory
      .slice(0, entryCount)
      .map((entry) => (
        <React.Fragment key={entry.Event}>
          <Border></Border>
          <Row>
            <ColumnData style={{ width: "200px" }}>
              {formatDateString(entry.Date)}
            </ColumnData>
            <Border></Border>
            <ColumnData style={{ width: "200px" }}>{entry.Event}</ColumnData>
            <Border></Border>
            <ColumnData style={{ width: "128px" }}>
              $
              {parseFloat(entry.listPrice).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </ColumnData>
          </Row>
        </React.Fragment>
      ));
  };

  return (
    <>
      {listingPageResponse?.data?.pricingHistory?.length > 0 && (
        <Wrap>
          <Header>Pricing History</Header>
          <Row>
            <ColumnTitle style={{ width: "200px" }}>Date</ColumnTitle>
            <ColumnTitle style={{ width: "200px" }}>Event</ColumnTitle>
            <ColumnTitle style={{ width: "128px" }}>Price</ColumnTitle>
          </Row>
          {renderEntries()}

          <Border></Border>
          {listingPageResponse?.data?.pricingHistory?.length >
            initialEntryCount && (
            <ButtonShow onClick={toggleShowAllEntries}>
              {showAllEntries ? "Show Less" : "Show More"}
            </ButtonShow>
          )}
        </Wrap>
      )}
    </>
  );
};

export default ListingPageContainer(PricingHistory);
