import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import colors from "library/colors.js"

export const TableWrap = styled.div`
  padding: 0 0px;

  tr {
    cursor: pointer;
  }

  .ant-pagination .anticon {
    position: relative;
    top: -3px;
  }

  .ant-pagination-item {
    border: none;
    
    &:hover {
      background: aliceblue;
    }
  }

  .ant-table-thead th {
    color: var(--Global-Mapping-Gray-400, #7D7DA9); /* Correct color */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }

  // th:nth-child(1) {
  //   color: var(--Gray-500, #667085);
  //   font-family: ${INTER};
  //   font-size: 12px;
  //   font-style: normal;
  //   font-weight: 600;
  //   line-height: 18px; /* 150% */
  // }

  td {
    color: var(--Global-Mapping-Gray-700, #373758);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    vertical-align: middle;
  }

  .ant-table-cell {
    @media(max-width: 1315px) {
      padding: 10px 10px;
    }
  }
`

export const InformationWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
`
export const AddressNameWrap = styled.div`
align-self: stretch;
color: var(--Global-Mapping-Gray-700, #373758);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 21.6px */
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
`
export const SellerNameWrap = styled.div`
color: var(--Color-Gray-Gray-500, #5C5C94);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
`

export const AgentNameWrap = styled.div`
display: flex;
flex-direction: row;;
justify-content: center;
align-items: flex-start;
align-self: stretch;
gap: 8px;
`

export const AgentNameDiv = styled.div`
color: var(--Global-Mapping-Gray-700, #373758);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%;
`
export const ContractStatusWrap = styled.div`
display: flex;
padding: 4px 12px;
justify-content: center;
align-items: center;
gap: 4px;
align-self: stretch;
border-radius: 24px;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px;

${props => props.complete
  && `
    background: ${colors.contract_bg_green_50} !important;
    color: ${colors.contract_green_700} !important;
`}
${props => props.incomplete
  && `
    background: ${colors.contract_bg_gray_50} !important;
    color: ${colors.contract_gray_700} !important;
`}
`
export const DeleteWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 12px;
flex-shrink: 0;
align-self: stretch;
`
