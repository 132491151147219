import React from "react"
import styled from "styled-components"
import {
  TitleText,
  FlexAnimContainer,
  ImageWrap,
} from "../commonStyles"
import { SubText } from "../../commonStyles"
import LottieMachine from "../AnimationsJSON/LottieMachine"
import { transactionOneJSON } from "../AnimationsJSON/index"

const Wrap = styled.div`
  width: 100%;
  max-width: 505px;

   ${props => props.sticky && `
    display: none;
    @media(max-width: 1099px) {
      display: block;
    }
  `}
`

const TextWrap = styled.div`
  width: 100%;
`

const DetailCardThree = () => (
  <FlexAnimContainer three>
    <Wrap>
      <TextWrap>
        <TitleText>
          Transaction Management
        </TitleText>
      </TextWrap>
      <SubText
        spanStyles
        id="transaction"
      >
        <span>An all-in-one platform</span>
        {" "}
        that supports your business from client outreach to closing.
        {" "}
        Stop piecing together a tech stack and become more efficient with the Radius ecosystem.
      </SubText>
      <SubText
        spanStyles
        style={{
          marginTop: "15px",
          marginBottom: "30px",
        }}
      >
        Be the first offer every time.
        {" "}
        <span>Create an offer with the</span>
        {" "}
        <span>Radius Office app</span>
        {" "}
        before you even leave the viewing.
      </SubText>
    </Wrap>
    <Wrap
      sticky
    >
      <LottieMachine
        animationData={transactionOneJSON}
        scrollFrames={[0, 100]}
        endFrames={[100, 300]}
        customStyle={{
          width: "100%",
          height: "533px",
        }}
      />
    </Wrap>
  </FlexAnimContainer>
)

export default DetailCardThree
