/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import SocialSignIn from "dumbComponents/Auth/SocialSignIn";
import OnboardingContainer from "container/OnboardingSetup";
import GetStartedImage from "../../Images/GetStartedImage.svg";
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  SignupButtons,
  InputWrap,
} from "../../common";

const EnterEmailPage = ({
  sendVerifyLink,
  history,
  sendVerfyLinkResponse,
}) => {
  const [email, setMail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { isFetching } = sendVerfyLinkResponse || {};

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClick = () => {
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }
    const payload = {
      email,
    };
    sendVerifyLink({
      payload,
      callback: () => {
        history.push(`/signup/email-sent?email=${payload.email}`);
      },
    });
  };

  const handleEmailChange = (e) => {
    setMail(e.target.value);
    setIsEmailValid(true); // Reset email validity on change
  };

  return (
    <Wrap>
      <ContentWrap>
        <RightWrap padding="55px 0px 0px 133px"
          // justifyContent="flex-start"
>
          <WelcomeContentWrap>
            <WelcomeTitle width="fit-content">
              Verify your email to login
            </WelcomeTitle>
            <WelcomeDescription width="fit-content">
              A verification link will be sent to your email address.
            </WelcomeDescription>
            <InputWrap
              width={350}
              bordered
              placeholder="Enter your email address"
              value={email}
              onChange={handleEmailChange}
              style={{
                borderRadius: 9,
                border: "1px solid #5A5FF2",
                borderColor: "5A5FF2",
              }}
            />
            {!isEmailValid && (
              <WelcomeDescription width="fit-content" style={{ color: 'red', fontSize: '14px'}}>
                Please enter a valid email address.
              </WelcomeDescription>
            )}
            <SignupButtons
              onClick={handleClick}
              width="fit-content"
              disabled={!email}
            >
              {isFetching ? <ThreeDotLoader color="#FFFFFF" /> : "Send verification link"}
            </SignupButtons>
          </WelcomeContentWrap>
          <WelcomeContentWrap>
            <WelcomeDescription>
              Other Login Options
            </WelcomeDescription>
            <SocialSignIn login />
          </WelcomeContentWrap>
        </RightWrap>
        <LeftWrap justifyContent="flex-end">
          <LeftImage src={GetStartedImage} />
        </LeftWrap>
      </ContentWrap>
    </Wrap>
  );
};

export default withRouter(OnboardingContainer(EnterEmailPage));
