import React from "react"
import styled, { css } from "styled-components"
import colors from "@colors"
import { mobile } from "shared/consts"

const Img = styled.div`
  position: relative;
  border-radius: ${props => props.borderRadius || "50%"};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e8e9;
  background-size: cover;
  color: #fff;
  position: relative;
  ${mobile(`
    right: initial;
  `)}
  ${props => props.hideOverflow
    && `
      overflow: hidden;
    `}

  ${props => props.isLast && `
    left: -17px;
  `}
  ${props => props.purpleBorder && `
    border: 2px solid ${colors.marineBLue};
  `}
`

const NameChar = styled.div`
  text-transform: uppercase;
  font-size: ${props => props.charSize};
  font-weight: 500;
  color: ${props => props.charColor || colors.contentText};
`

const UnseenDot = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00ce7d;
  position: absolute;
  top: 0px;
  right: 0px;
`

const DEFAULT_CHAR = "-"

const getNameChars = (name = "") => {
  const nameArray = name.split(" ")
  const nameLen = nameArray.length
  switch (nameLen) {
    case 1: {
      return nameArray[0] !== "" ? nameArray[0].substring(0, 2).toUpperCase() : DEFAULT_CHAR
    }
    case 2: {
      return `${nameArray[0].substring(0, 1)}${nameArray[1].substring(0, 1)}`
    }
    default:
      return `${name ? name.trim().substring(0, 2).toUpperCase() : DEFAULT_CHAR}`
  }
}

class CircularImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
    }
  }

  handleOnMouseEnter = () => {
    this.setState({
      isHovered: true,
    })
  }

  handleMouseLeave = () => {
    this.setState({
      isHovered: false,
    })
  }

  render() {
    const {
      image,
      name,
      size,
      style,
      children,
      unseen,
      hideOverflow,
      hoverOverlay,
      charSize,
      fontSize,
      onClick = () => {},
      isLast,
      purpleBorder,
      borderRadius,
      charColor,
      id,
    } = this.props

    const { isHovered } = this.state
    const letterSize = fontSize || charSize || "23px"
    return (!image ||
      image === "http://www.agentdesks.com/uploads/profile_pic/agent_img.png") ? (
        <Img
          id={id}
          hideOverflow={hideOverflow}
          onClick={onClick}
          style={{
            ...style,
            width: `${size}px`,
            height: `${size}px`,
          }}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          borderRadius={borderRadius}
        >
          {hoverOverlay && isHovered && hoverOverlay}
          {unseen === 1 && <UnseenDot />}
          <NameChar id={id} className="name-char" charSize={letterSize} charColor={charColor}>{getNameChars(name)}</NameChar>
          {children}
        </Img>
      ) : (
        <Img
          id={id}
          hideOverflow={hideOverflow}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={onClick}
          isLast={isLast}
          purpleBorder={purpleBorder}
          borderRadius={borderRadius}
          style={{
            ...style,
            backgroundImage: `url(${image})`,
            width: `${size}px`,
            height: `${size}px`,
          }}
        >
          {hoverOverlay && isHovered && hoverOverlay}
          {unseen === 1 && <UnseenDot />}
          {children}
        </Img>
      )
  }
}

CircularImage.defaultProps = {
  name: "",
  hideOverflow: false,
  size: 60,
}

export default CircularImage
