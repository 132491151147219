import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import AppContainer from "container/App"
import { withRouter } from "react-router-dom"

const Wrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: #FBFBFB;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`

const LogoDiv = styled.button`
  margin-left: 150px;
  padding: 25px 0px;
  display: block;
  background: transparent;
  border: none;
  cursor: pointer;

  img {
    height: 31.7005558013916px;
    width: 149.9988250732422px;
  }

  @media(max-width: 1099px) {
    img {
      width: 118.333px;
      height: 25px;
    }
    
    flex-shrink: 0;
  }
`

const Navbar = ({
  history,
  fetchUserInfo,
}) => {
  return (
    <Wrap>
      <LogoDiv
        onClick={() => {
          // fetchUserInfo({ callback: history.push("/community") })
          root.location.href="https://www.radiusagent.com"
        }}
      >
        <img
          src={`${CLOUDFRONT}/RadiusLogo3x.png`}
          alt="radius_circle"
        />
      </LogoDiv>
    </Wrap>
  )
}

export default withRouter(AppContainer(Navbar))
