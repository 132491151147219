import React from "react"
import { Route, Switch } from "react-router-dom"
import ListingTabs from "../ListingTabs"

const ListingRoutes = () => (
  <Switch>
    <Route path="/listings/form" exact component={ListingTabs} />
    <Route path="/listings/form/:id" exact component={ListingTabs} />
  </Switch>
)

export default ListingRoutes
