import React from "react"
import CookiesStorage from "services/CookieStorage"
import AppContainer from "container/App"
import root from "window-or-global"
import { getItemFromLocalStorage } from "shared/utils"
import {
  TeamDetailsContainer, TeamImage, TeamNameLabel, TeamName,
} from "./commonStyles"

const TeamDetails = ({
  routeArray,
  checkIfTeamUpdatedByLead,
  teamDetails,
  teamDetailsForLogo,
  toggleEditMode,
  thisAgentHasATeam,
  isBroker,
  user,
}) => {
  const data = teamDetails || teamDetailsForLogo

  return (
    <>
      {data?.name?.length ? (
        <TeamDetailsContainer
          allowInteractions={user?.isBrokerageTeamLead}
          isActive={routeArray[1] === "team-profile" || routeArray[1] === "team_development"}
          to={
            isBroker && thisAgentHasATeam
              ? checkIfTeamUpdatedByLead(data) || CookiesStorage.load("teamProfileWelcomeDone")
                ? "/realty/team-profile/edit"
                : "/realty/team-profile"
              : "/realty/promos?type=team_development"
          }
          onClick={() => {
            root.scrollTo(0, 0)
            if (isBroker && thisAgentHasATeam) {
              if (root.heap) {
                root.heap.track("click_sidebar_teamProfileRAR_web")
              }

              toggleEditMode(true)
            }
          }}
        >
          {data?.icon_url?.length > 0 && (
            <TeamImage src={data.icon_url} alt={`${data.name}'s team icon`} />
          )}
          {
            !data?.icon_url && (
              <div style={{textAlign: "center"}}>
                <TeamNameLabel>Team name</TeamNameLabel>
                <TeamName>{data.name}</TeamName>
              </div>
            )
          }
        </TeamDetailsContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export default AppContainer(TeamDetails)
