import React from "react"
import Modal from "@ui/Modal"
import colors from "@colors"
import Interests from "./Interests"
import Languages from "./Languages"
import Designations from "./Designations"
import Accomplishments from "./Accomplishments"
import EditAbout from "./EditAbout"
import Expertise from "./Expertise"
import ProfileImageUpload from "./ProfileImageUpload"
import CoverImageUpload from "./CoverImageUpload"
import EditBasicInfo from "./EditBasicInfo"
import ShareProfile from "./ShareProfile"
import NeighborhoodEdit from "./NeighborhoodEdit"
import SyncMLS from "./SyncMLS"
import Education from "./Education"
import Experience from "./Experience"
import AddBuyerNeeds from "./AddBuyerNeeds"

class ProfileEditModal extends React.Component {
  state = {}

  getWidthForModal = (type) => {
    switch (type) {
      case "coverImageUpload":
        return "800px"
      case "shareProfile":
        return "650px"
      default:
        return "700px"
    }
  }

  renderModal = ({
    type,
    handleEducationSubmit,
    handleBasicInfoSave,
    handleNeighborhoodSave,
    handleBuyerNeedsSubmit,
    addCity,
    syncMLSPRops,
    ...props
  }) => {
    switch (type) {
      case "interest":
        return <Interests type={type} {...props} />
      case "languages":
        return <Languages type={type} {...props} />
      case "designations":
        return <Designations type={type} {...props} />
      case "accomplishments":
        return <Accomplishments type={type} {...props} />
      case "about":
        return <EditAbout type={type} {...props} />
      case "expertise":
        return <Expertise type={type} {...props} />
      case "profileImageUpload":
        return <ProfileImageUpload type={type} {...props} />
      case "coverImageUpload":
        return <CoverImageUpload type={type} {...props} />
      case "editBasicInfo":
        return <EditBasicInfo type={type} onSubmit={handleBasicInfoSave} {...props} />
      case "shareProfile":
        return <ShareProfile type={type} {...props} />
      case "neighborhoodEdit":
        return (
          <NeighborhoodEdit
            type={type}
            handleNeighborhoodSave={handleNeighborhoodSave}
            addCity={addCity}
            {...props}
          />
        )
      case "sync-mls":
        return <SyncMLS type={type} syncMLSPRops={syncMLSPRops} {...props} />
      case "experiences":
        return <Experience type={type} onSubmit={handleEducationSubmit} {...props} />
      case "education":
        return <Education type={type} onSubmit={handleEducationSubmit} {...props} />
      case "addBuyerNeeds":
        return <AddBuyerNeeds type={type} onSubmit={handleBuyerNeedsSubmit} {...props} />
      default:
        return null
    }
  }

  render() {
    const { show, toClose, type } = this.props
    return (
      <Modal
        show={show}
        toClose={toClose}
        modalStyles={{ padding: 0, background: `${colors.white}` }}
        width={this.getWidthForModal(type)}
        dialogStyles={{ borderRadius: 4 }}
        overlayStyles={{
          zIndex: 1,
        }}
      >
        {this.renderModal(this.props)}
      </Modal>
    )
  }
}

export default ProfileEditModal
