export const DISPLAY_OBJECT = [{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
{
  height: "30px",
  width: "73.2px",
  img: `${CLOUDFRONT}/invertedteamslogos/TogetherRealtyInv.png`,
}, {
  height: "35px",
  width: "59.128px",
  img: `${CLOUDFRONT}/invertedteamslogos/VinitiaInv.png`,
}, {
  height: "30px",
  width: "70.689px",
  img: `${CLOUDFRONT}/invertedteamslogos/TonyGonzalesInv.png`,
}, {
  height: "38px",
  width: "74.81px",
  img: `${CLOUDFRONT}/invertedteamslogos/EllnerInv.png`,
}, {
  height: "35px",
  width: "61.086px",
  img: `${CLOUDFRONT}/invertedteamslogos/JalondaInv.png`,
}, {
  height: "40px",
  width: "51.668px",
  img: `${CLOUDFRONT}/invertedteamslogos/CCSInv.png`,
}, {
  height: "25px",
  width: "78.055px",
  img: `${CLOUDFRONT}/invertedteamslogos/TuffnelInv.png`,
}, {
  height: "30px",
  width: "28.373px",
  img: `${CLOUDFRONT}/invertedteamslogos/ACHT.png`,
}, {
  height: "30px",
  width: "101.551px",
  img: `${CLOUDFRONT}/invertedteamslogos/TrajectoryInv.png`,
}, {
  height: "40px",
  width: "47.324px",
  img: `${CLOUDFRONT}/invertedteamslogos/OctoberInv.png`,
}, {
  height: "30px",
  width: "62.083px",
  img: `${CLOUDFRONT}/invertedteamslogos/MagicGroupInv.png`,
}, {
  height: "35px",
  width: "46.994px",
  img: `${CLOUDFRONT}/invertedteamslogos/ForAgentsInv.png`,
}, {
  height: "35px",
  width: "74.079px",
  img: `${CLOUDFRONT}/invertedteamslogos/YSLInv.png`,
}, {
  height: "40px",
  width: "44.59px",
  img: `${CLOUDFRONT}/invertedteamslogos/NorthStarInv.png`,
}, {
  height: "30px",
  width: "37.081px",
  img: `${CLOUDFRONT}/invertedteamslogos/VersoInv.png`,
}, {
  height: "35px",
  width: "35.041px",
  img: `${CLOUDFRONT}/invertedteamslogos/THCRealEstate.png`,
}, {
  height: "20px",
  width: "95.755px",
  img: `${CLOUDFRONT}/invertedteamslogos/OneVisionInv.png`,
}, {
  height: "30px",
  width: "30px",
  img: `${CLOUDFRONT}/invertedteamslogos/AligoodInv.png`,
}, {
  height: "65.852px",
  width: "40px",
  img: `${CLOUDFRONT}/invertedteamslogos/HeartStoneInv.png`,
},
]
