import React from "react"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import colors from "@colors"
import styled from "styled-components"
import Button from "@ui/Button"
import ForTeams_RecruitingFIndImage from "dumbComponents/NewTeamsPages/images/ForTeams_RecruitingFIndImage.png"
import ForTeams_RecruitingSpeaker from "dumbComponents/NewTeamsPages/images/ForTeams_RecruitingSpeaker.png"
import ForTeams_RecruitingAmongUs from "dumbComponents/NewTeamsPages/images/ForTeams_RecruitingAmongUs.png"
import Economics from "./Economics"

const Wrap = styled.div`
  background: ${colors.dreamWhite};
  width: 100%;
  padding: 30px;
  @media(max-width: 1099px) {
    padding-bottom: 100px;
  }

  @media(max-width: 768px) {
    padding-bottom: 0px;
  }

  @media(max-width: 425px) {
    padding: 5px;
  }
`

const CompanyWrapper = styled.div`
  position: relative;
  top: -9rem;
  background: ${colors.dreamWhite};
  width: 90%;
  margin: auto;
`

const TestimonialsWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 50px 17px;
  margin-top: 40px;
  justify-content: center;
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 280px;
  background-color: ${colors.dreamWhite}s;
  @media(max-width: 425px) {
    padding: 10px;
  }
  @media(max-width: 1099px) {
    margin-top: 24px;
  }
  > img {
    width: 96px;
    height: 96px;
    @media(max-width: 425px) {
      margin: 10px 0px;
    }
  }
  > p {
    line-height: auto;
    color: #303030;
    margin-bottom: 0px;
    font-size: 22px;
    weight: 400;
    margin-top: 40px;
    font-family: ${ATC};
    @media(max-width: 1099px) {
      font-size: 20px;
    }
    @media(max-width: 425px) {
      font-size: 18px;
    }
  }
`

const ButtonWrap = styled.div`
  height: 47px;
  margin: 60px auto;
  display: flex;
  justify-content: center;

  @media(max-width: 425px) {
    > button {
      width: 100% !important;
    }
  }
`

const FirstInnerWrap = styled.div`
  padding: 30px;

  @media(max-width: 425px) {
    padding: 20px;
  }
  background: ${colors.dreamWhite};
  > h2 {
    font-size: 42px;
    color: #303030;
    text-align: center;
    font-family: ${HIGHLIGHT_FONT};
    @media(max-width: 1099px) {
      font-size: 36px;
    }
    @media(max-width: 425px) {
      font-size: 32px;
    }
  }
`

const RecruitingSupport = ({
  toggleApplyModal,
}) => (
  <Wrap>
    <CompanyWrapper>
      <FirstInnerWrap>
        <h2>Recruiting support to expand your team</h2>
        <TestimonialsWrap>
          <TextWrap>
            <img src={ForTeams_RecruitingFIndImage} alt="" />
            <p>
              Build a recruiting strategy with your dedicated
              expansion recruiter assigned to your team.
            </p>
          </TextWrap>
          <TextWrap>
            <img src={ForTeams_RecruitingSpeaker} alt="" />
            <p>
              Promote your team to our network of over 100,000 agents.
            </p>
          </TextWrap>
          <TextWrap>
            <img src={ForTeams_RecruitingAmongUs} alt="" />
            <p>
              Syndicate your job listing to major job
              boards from our recruiting dashboard.
            </p>
          </TextWrap>
        </TestimonialsWrap>
        <ButtonWrap>
          <Button
            style={{
              width: "365px",
              height: "47px",
              padding: 10,
              background: `${colors.marineBLue}`,
              border: "none",
              borderRadius: "0px",
            }}
            onClick={() => {
              toggleApplyModal({
                bool: true,
                accountType: "owner",
                headerText: "Nice to meet you!",
                subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
              })
            }}
          >
            Get Started
          </Button>
        </ButtonWrap>
      </FirstInnerWrap>
      <Economics />
    </CompanyWrapper>
  </Wrap>
)
export default RecruitingSupport
