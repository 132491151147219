import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;

  border-radius: 10px;
  background: #fff;

  form {
    width: 100%;

    .ant-form-item {
      margin-left: unset;
    }
  }

  // ${props => props.isAddCoAgentForm && `
  //   margin-top: 25px;
  // `}
`

export const FormTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.352px;
    margin: 0;
  }
`

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`

export const CancelOrGoBackButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;

  img {
    width: 16px;
    height: 16px;
  }
`

export const CTAWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  .ant-form-item {
    width: unset;
  }
`

export const DIV = styled.div``

export const CountTracker = styled.div`
  padding: 10px 0px 0px 5px;
  margin-top: -25px;

  p {
    color: #898989;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    letter-spacing: 0.5px;
  }
`
