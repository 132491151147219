import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getSearchedAgents,
  selectSearchFilter,
  removeSearchFilter,
  clearAllSearchFilters,
  changeFilterSelectedCategory,
  showMoreFiltersForCategory,
  toggleFilterPanel,
  updateSingleFilterPayload,
  getClientsByName,
} from "./actionCreators"
import * as SearchSelectors from "./selector"

const mapStateToProps = state => ({
  selectedFilters: SearchSelectors.getSelectedFiltersFromStore(state),
  keyedFacades: SearchSelectors.getKeyedFacadesFromStore(state),
  filterSelectedCategory: SearchSelectors.getFilterSelectedCategory(state),
  lastFilterItemIndex: SearchSelectors.getLastFilterItemIndex(state),
  keyedFacadesForCategory: SearchSelectors.getKeyedFacadesForCategorySelector(state),
  appliedFiltersForCategory: SearchSelectors.getAppliedFiltersForCategorySelector(state),
  unappliedFiltersForCategory: SearchSelectors.getUnappliedFiltersForCategorySelector(state),
  showFilterPanel: SearchSelectors.getShowFilterPanel(state),
  queryParams: SearchSelectors.getQueryParamsFromStore(state),
  keyedSingleFilters: SearchSelectors.getKeyedSingleFiltersFromStore(state),
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getSearchedAgents,
    selectSearchFilter,
    removeSearchFilter,
    clearAllSearchFilters,
    changeFilterSelectedCategory,
    showMoreFiltersForCategory,
    toggleFilterPanel,
    updateSingleFilterPayload,
    getClientsByName,
  },
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
