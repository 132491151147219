import React from "react"
import { connect } from "react-redux"
import Button from "@ui/Button"
import ThreeDotLoader from "@ui/ThreeDotLoader"

const POST_CHARACTER_LIMIT = 1000

const PostButton = ({
  onClick,
  text,
  isMediaUploading,
  topicId,
  isPosting,
  mediaURL,
  isLocationSet,
  isDisable,
}) => {
  let isTopicValid = true
  let isValid = false
  console.log(mediaURL)
  if (topicId === 62 && !isLocationSet) {
    isTopicValid = false
  }
  if (isTopicValid) {
    isValid = Boolean(text.length <= POST_CHARACTER_LIMIT && topicId && !isMediaUploading && (text.length > 0 || mediaURL) && isTopicValid)
  }
  return (
    <Button disabled={isDisable && !isValid} width="68px" onClick={onClick}>
      {isPosting ? <ThreeDotLoader color="#fff" /> : <span>Post</span>}
    </Button>
  )
}

const mapStateToProps = state => ({
  text: state.formInput.createEditPostText,
})

export default connect(mapStateToProps)(PostButton)
