import React from "react"
import { Link } from "react-router-dom"

const LinkOrA = ({ isLink = true, to, ...props }) => (
  isLink
    ? <Link to={to} {...props} />
    : <a href={to} {...props}/>
)

export default LinkOrA
