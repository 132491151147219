import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import styled from "styled-components"

const Wrap = styled.div``

const FormShimmer = () => (
  <Wrap>
    <ShimmerBox w="100%" h="42px" style={{ marginBottom: "20px" }} />
    <ShimmerBox w="100%" h="42px" style={{ marginBottom: "20px" }} />
    <ShimmerBox w="100%" h="42px" style={{ marginBottom: "20px" }} />
    <ShimmerBox w="100%" h="42px" style={{ marginBottom: "20px" }} />
    <ShimmerBox w="100%" h="42px" style={{ marginBottom: "20px" }} />
  </Wrap>
)

export default FormShimmer
