import React, { useState } from "react"
import cookie from "services/CookieStorage"
import { Popover } from "antd"
import Listings from "container/Listings/index"
import { withRouter } from "react-router-dom"
import TechAssetsContainer from "container/TechAssets"
import ProgressToCap from "./components/ProgressToCap"
import {
  StatsCardWrap,
  StatsCard,
  StatsLabel,
  StatsValue,
  ProgressCapLabel,
  ProgressCapWrap,
} from "./styles"
const TransactionStats = ({
  data,
  loading,
}) => {
  console.log("loading", data)

  const formatLabel = (label) => {
    const words = label.split("_")
    return words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
      .join(" ")
  }

  return (
    <>
      <StatsCardWrap>
        {data
        && Object.keys(data).map((key, index) => (
          <StatsCard key={index}>
            <StatsLabel>
              {formatLabel(key)}
              {data[key]?.total_cap_value_numeric ? (
                <Popover
                  placement="topRight"
                  title=""
                  content={(
                    <ProgressToCap
                      completedLabel={data[key]?.current_cap_value_string}
                      capLabel={data[key]?.total_cap_value_string}
                      capAmt={data[key]?.total_cap_value_numeric}
                      completedAmt={data[key]?.current_cap_value_numeric}
                    />
                  )}
                >
                  <ProgressCapWrap>
                    <ProgressCapLabel>
                      <img src={`${CLOUDFRONT}/transaction-revamp-grp.svg`} alt="transaction-revamp-grp.svg" />
                      <p>Progress to cap</p>
                    </ProgressCapLabel>
                  </ProgressCapWrap>
                </Popover>
              ) : (<></>) }
            </StatsLabel>
            <StatsValue>{data[key]?.value || "N/A"}</StatsValue>
          </StatsCard>
        ))}
      </StatsCardWrap>
    </>
  )
}

export default withRouter(TechAssetsContainer(Listings(TransactionStats)))
