import React from "react";
import { Wrap } from "./style";
import { CheckedIcon } from "dumbComponents/OffersV2/icons"

const ValidateNumber = ({ value, index }) => (
  <>
    {value[index] === 0 ? (
      <CheckedIcon />
    ) : (
      <Wrap style={{ borderRadius: 100, background: "#F08068" }}>
        {value[index]}
      </Wrap>
    )}
  </>
);

export default ValidateNumber;
