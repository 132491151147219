import React from "react"
import { Form } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  InputTextAreaWrap,
} from "./styles"

const InputArea = ({
  placeholder,
  type,
  name,
  required,
  text = "",
  style,
  customRules,
  inputType,
  showCount,
  maxLength,
  onChange,
  defaultValue,
  requiredMessage = "Please enter a value",
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required, type: "email" }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required, message: requiredMessage }]
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      initialValue={defaultValue}
      label={<LabelComponent text={`${text}`} required={required}/>}
    >
      <InputTextAreaWrap
        maxLength={maxLength}
        placeholder={placeholder}
        style={style}
        type={inputType}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default InputArea
