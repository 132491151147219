import React, { useEffect } from "react"
import OffersV2 from "container/OffersV2"
import OfferPackageContainer from "dumbComponents/OffersV3/DocusignDocs/Components/OffersPackage/index"
import { ShimmerBox } from "shared/styles/animation"
import TechAssets from "container/TechAssets"
import {
  Wrap,
} from "./styles"
import EmptyState from "../RecommendedDocs/Components/EmptyState"
const OfferPackage = ({
  getOfferPackage,
  dealToView,
  getOfferPackageResponse,
}) => {
  const { data, isFetching } = getOfferPackageResponse || {}
  const { offerPackage } = data || {}
  useEffect(() => {
    getOfferPackage({ id: dealToView })
  }, [])
  return (
    <Wrap>
      {isFetching ? (
        <ShimmerBox w="1100px" h="200px" />
      ) : (
        <>
          {offerPackage?.length>0 && offerPackage.map(item => (
            <OfferPackageContainer
              packages={item}
            />
          ))}
            {offerPackage?.length === 0 && 
             <EmptyState
              emptyHeader="No Offer Package yet"
            />}
        </>
      )}
    </Wrap>
  )
}

export default OffersV2(TechAssets(OfferPackage))
