import styled from "styled-components"
import { INTER } from "@fonts"

export const ListedDocsWrap = styled.div`
  padding-top: 33px;

  ${props => props.padding && `
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 9px;
    background: var(--Color-Neutral-100, #F1F1FE);
  `}
`

export const DocsTypeTitle = styled.p`
  color: var(--BG-Dark-mode, #292A37);
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
`
