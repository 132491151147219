import React from "react"
import styled from "styled-components"
import { ShimmerBox } from "shared/styles/animation"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  CTAWrap,
  ListWrap,
} from "./styles"

const InnerShimmer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  opacity: 0.3;
`

const Shimmy = () => (
  <InnerShimmer>
    <ShimmerBox w="378px" h="20px" style={{ marginBottom: "12px" }} />
    <ShimmerBox w="206px" h="97px" />
    <ShimmerBox w="206px" h="97px" />
    <ShimmerBox w="206px" h="97px" />
    <ShimmerBox w="206px" h="97px" />
  </InnerShimmer>
)



const STEP = "buyers_information_mobile"

const GenericWithShimmer = () => {

  return (
    <Wrap>
      {/*Buyer's Agent Information*/}
      <SectionWrap>
        <SectionTitle>
          <ShimmerBox w="222px" h="18px" style={{ marginBottom: "8px" }} />
        </SectionTitle>
        <ListWrap>
          <ShimmerBox w="50%" h="30px" style={{ marginBottom: "12px" }} />
          <ShimmerBox w="50%" h="30px" style={{ marginBottom: "40px" }} />
        </ListWrap>
        <ListWrap>
          <ShimmerBox w="100%" h="500px" style={{ marginBottom: "12px" }} />
        </ListWrap>
      </SectionWrap>
    </Wrap>
  )
}

export default GenericWithShimmer
