import React, { useEffect } from "react"
import styled from "styled-components"
import Loader from "@ui/Loader"
import OnboardingContainer from "container/OnboardingSetup"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import { Wrap } from "../../common"

const DocusignWrap = styled.div`
  height: 100vh;
  padding: 100px;
  background: #f5f4f4;
`

const ICAAgreement = ({
  getReceiverViewDoc,
  getAllDocsResponse,
  getAllDocs,
  getIcaViewResponse,
  updateEnvelopeStatus,
  history,
}) => {
  useEffect(() => {
    getAllDocs()
  }, [])
  const { data, isFetching: fetchingAllDocs } = getAllDocsResponse || {}
  const id = get(data, "0.envelope_id")
  const { data: icaViewData, isFetching: fetchingView } = getIcaViewResponse || {}
  const { url } = icaViewData || {}
  useEffect(() => {
    const payload = {
      envelopeId: id,
    }
    getReceiverViewDoc({ payload })
  }, [id])
  useEffect(() => {
    if (id) {
      const script = document.createElement("script")
      if (process.env.NODE_ENV === "development") {
        script.src = "https://js-d.docusign.com/bundle.js"
      } else {
        script.src = "https://js.docusign.com/bundle.js"
      }
      document.body.appendChild(script)

      if (url) {
        script.addEventListener("load", () => {
          window.DocuSign.loadDocuSign("2eee6cfa-1877-4276-9d13-eb631b238e6b").then((docusign) => {
            const signing = docusign.signing({
              url,
              displayFormat: "focused",
              style: {
                branding: {
                  primaryButton: {
                    /** Background color of primary button */
                    backgroundColor: "#FFE94D",
                    /** Text color of primary button */
                    color: "#303030",
                  },
                },

                /** High-level components we allow specific overrides for */
                signingNavigationButton: {
                  finishText: "Finish",
                  position: "bottom-center",
                },
              },
            })

            signing.on("ready", (event) => {
              console.log("UI is rendered")
            })

            signing.on("sessionEnd", (event) => {
              console.log("sessionend", event)
              const payload = {
                envelopeId: id,
                status: "agent_signed",
              }
              updateEnvelopeStatus({ payload })
              history.push("/office/ica-onboarding/success")
            })

            signing.mount("#agreement")
          })
        })
      }
    }
  }, [url])

  return (
    <Wrap>
      {(fetchingAllDocs || fetchingView) ? <Loader /> : (
        <DocusignWrap className="docusign-agreement" id="agreement"></DocusignWrap>
      )}
    </Wrap>
  )
}

export default withRouter(OnboardingContainer(ICAAgreement))
