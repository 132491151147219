import styled from "styled-components"
import colors from "@colors"

export const Wrap = styled.div`
  margin-top: 34px;
  height: max-content;
  width: 100%;
  border-radius: 16px;
  padding: 52px 100px 52px 100px;
  background: #FCFCFC;
  border: 1px solid #D3DAE8;
  min-height: 500px;
`

export const SubmitButton = styled.button`
  height: 48px;
  width: ${props => props.fullWidth ? "100%" : "240px"};
  border-radius: 100px;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  background: ${colors.deepPurple};
  color: #E9F3FB;
  margin-top: 38px;
  cursor: pointer;

  ${props => props.disabled && `
      cursor: not-allowed;
      color: ${colors.white};
      background: #BEBEBE;
   `}

   p {
    margin: 0;
   }
`

export const FormWrap = styled.form`
&>span {
  position: relative;
  bottom: 17px;
}
& label input {
  border-radius: 8px;
}
 & label span, & div label {
   color: black;
   font-family: DM Sans;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 0px;
   text-align: left;
   margin-bottom: 10px;
 }

 & label {
   margin-bottom: 22px;
 }
`

export const BackButton = styled.button`
  height: 48px;
  width: 240px;
  margin-right: 10px;
  border-radius: 100px;
  padding: 16px 14px 16px 14px;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: 1px solid ${colors.deepPurple};
  background: transparent;
  color: ${colors.deepPurple};
  margin-top: 38px;
`

export const CTAWrap = styled.div`
  display: flex;
`

export const PercentageDiv = styled.div`
  position: absolute;
  right: 0;
  top: 34px;
  margin-right: 10px;
  height: 23px;
  width: 20px;
  text-align: center;
  border-left: 1px solid #0000005e;
  color: #000000a1;
  padding-top: 1px;
  padding-left: 7px;
`

export const MarginWrap = styled.div`
  ${props => props.type}: ${props => props.value};
`

export const InvalidLocation = styled.p`
  font-size: 12px;
  position: relative;
  top: -7px;
  color: red;
`
