import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
p {
  color: var(--Color-Gray-400, #71717A);
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.352px;
  margin: 0;
}

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  //padding-bottom: 68px;
  width: 100%
  ${props => props.v2 && `
    padding-bottom: unset;
    // display: flex;
    padding: 16px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Color-Neutral-100, #F1F1FE);
    margin-bottom: 68px;
  `}
`
export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E6FF;
`

export const CTAWrap = styled.div`
  // padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
export const ListRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  align-items: baseline;
  margin-bottom: 4px;
`

export const StateDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  p {
    color: var(--BG-Dark-mode, #292A37);
    text-align: right;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  h2 {
    color: var(--BG-Dark-mode, #292A37);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`

export const StateWrap = styled.div`
  display: flex;
  width: 176px;
  align-items: flex-start;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;

  div {
    height: 45px;
    /* padding: 2px; */
    display: flex;
    align-items: center;
  }
`

export const GreyState = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Color-Neutral-100, #F1F1FE);
  background: var(--Color-Neutral-100, #F1F1FE);
  padding: 10px;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  p {
    color: var(--Gray-500, #667085);

    /* Text md/Normal */
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`
export const State = styled.div`
  background: #fff;
  display: flex;
  align-items: center;

  h2 {
    color: var(--Gray-500, #667085);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }
`
