import React from "react"
import OffersV2 from "container/OffersV2" //connect sagas individually to components
import {
  AgentCardWrap,
  BuyerAgentInformationText,
  EditButton,
  DetailsArea,
  AgentName,
  AgentEmail,
  AgentPhoneNumber,
  AddtionalInfoOuterWrap,
  AdditionalInfoInnerWrap,
  AdditionInfoTitle,
  AdditionalInfoValue,
} from "../AgentCard/styles"

/**
 *Do not create styled-components here
 */

const BuyerInformationCard = ({
  editBuyer,
  data,
  title,
}) => (
  <AgentCardWrap>
    <EditButton onClick={editBuyer}>
      Edit
    </EditButton>
    <BuyerAgentInformationText>
      <p>{title}</p>
    </BuyerAgentInformationText>
    <DetailsArea>
      <AgentName>
        <p>{data.name}</p>
      </AgentName>
      <AgentEmail>
        <p>{data.email}</p>
      </AgentEmail>
      <AgentPhoneNumber>
        <p>{data.phone}</p>
      </AgentPhoneNumber>
    </DetailsArea>
    <AddtionalInfoOuterWrap>
      {data.budget && (
        <AdditionalInfoInnerWrap>
          <AdditionInfoTitle>BUDGET</AdditionInfoTitle>
          <AdditionalInfoValue>{data.budget}</AdditionalInfoValue>
        </AdditionalInfoInnerWrap>
      )}
      {data.buyer_total_offers && (
        <AdditionalInfoInnerWrap noBorder>
          <AdditionInfoTitle>TOTAL OFFERS</AdditionInfoTitle>
          <AdditionalInfoValue>{data.buyer_total_offers}</AdditionalInfoValue>
        </AdditionalInfoInnerWrap>
      )} 
    </AddtionalInfoOuterWrap>
  </AgentCardWrap>
)

export default OffersV2(BuyerInformationCard)
