import * as React from "react"

const ReferAndEarn = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M20 8.685H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1ZM12 8.685v13M19 12.685v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7M7.5 8.685a2.5 2.5 0 1 1 0-5c.965-.017 1.91.451 2.713 1.343.802.892 1.425 2.166 1.787 3.657.362-1.49.985-2.765 1.787-3.657.803-.892 1.748-1.36 2.713-1.343a2.5 2.5 0 0 1 0 5"
      />
    </svg>
  )
}

export default ReferAndEarn
