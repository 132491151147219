import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  //padding-bottom: 68px;

  ${props => props.v2 && `
    padding-bottom: unset;
    // display: flex;
    padding: 16px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Color-Neutral-100, #F1F1FE);
    margin-bottom: 68px;
  `}
`

export const CTAWrap = styled.div`
  // padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const ListRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
`

export const GoBack = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100px;
  text-decoration: underline;
  color: #5b5ff3;
  height: 20px;
  // position: absolute;
  // right: 0;
  // top: 0;
  // margin-top: 10px;
`