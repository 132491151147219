import React, { useState, useEffect } from "react"
import Settings from "container/Settings"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import CheckoutForm from "./CheckoutForm"
import Loader from "@ui/Loader"

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY)

const Temp = ({
  getClientSecret,
  getClientSecretResponse,
}) => {
  const [clientSecret, setClientSecret] = useState("sk_test_tR3PYbcVNZZ796tH88S4VQ2u")

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    console.log("gett consol here")
    getClientSecret()
  }, [])

  const { data, isFetching } = getClientSecretResponse || {}
  const { client_secret } = data || {}

  useEffect(() => {
    setClientSecret(client_secret)
  }, [getClientSecretResponse])

  console.log(clientSecret, "here")

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {isFetching ? <Loader /> : (
        <>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} isFetching={isFetching} />
            </Elements>
          )}
        </>
      )}
    </div>
  );
}

export default Settings(Temp)
