import React, { useEffect, useState } from "react"
import queryString from "query-string"
import OfferV2 from "container/OffersV2"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import {
  PROPERTY_TYPE,
  getState,
} from "dumbComponents/OffersV2/consts"
import Modal from "@ui/Modal"
import { DropdownIcon, DropdownUpIcon } from "dumbComponents/OffersV3/icons"
import {
  Wrap,
  SectionCircle,
  SectionLabelWrap,
  SectionText,
  ModalTitle,
  InputWrap,
  CTAWrap,
} from "./style"
import {
  ProTip,
  MLSTabs,
} from "./components"
import {
  SelectField,
} from "../InputFields"
import {
  WhiteButton,
  BlueButton,
} from "../../commonStyles"
import { BUYER_INFORMATION_STEP, PROPERTY_INFORMATION_STEP } from "../../consts"

const customSelectStyles = {
  // width: "497px",
  height: "48px",
  overflow: "hidden",
}

const MLSModal = ({
  toClose,
  createOfferFromPropertyType,
  mlsOrPropertyData,
  setMLSData,
  history,
  stateForOffer,
  getDealDetails,
  fetchPropertyInformation,
  getOfferStatus,
  setStateForOffer,
  updatePropertyInformationObject,
  propertyInformationObject,
  toggleBasicOfferDetailsModal,
}) => {
  const [data, setData] = useState({})
  const [showIcon, setIcon] = useState(false)
  const [propertyType, setPropertyType] = useState("residential_1_4")
  const tcInformation = cookie.load("tcInformation")

  useEffect(() => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    Object.keys(parsedQuery).forEach((key) => {
      if (parsedQuery[key] === "null") {
        parsedQuery[key] = null
      }
    })
    const {
      transaction_id,
      req_id,
      client_id,
      client_source,
      state,
    } = parsedQuery || {}

    const parsedData = {
      ...parsedQuery,
    }

    setMLSData({
      ...mlsOrPropertyData,
      purchaseType: propertyType,
    })

    setData(parsedData)
  }, [])

  const handleChange = (val) => {
    setPropertyType(val)
    setMLSData({
      ...mlsOrPropertyData,
      purchaseType: val,
    })
  }

  const handleSubmit = () => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    Object.keys(parsedQuery).forEach((key) => {
      if (parsedQuery[key] === "null") {
        parsedQuery[key] = null
      }
    })
    const {
      transaction_id,
      agent_id,
    } = parsedQuery || {}

    const payload = {
      agent_id: parseInt(agent_id || cookie.load("agentId"), 10),
      draft: "N",
      transation_id: data.transactionId,
      type_of_transaction: propertyType,
      ...data,
    }

    const reduxObject = {
      ...propertyInformationObject,
      type_of_transaction: propertyType,
    }

    let propertyPayload = null

    if (mlsOrPropertyData && Object.keys(mlsOrPropertyData).length > 0) {
      propertyPayload = {
        draft: 1,
        current_offer_step: "property_information_mobile",
      }
      const {
        id,
        Id,
        typedMLSId,
        address,
        City,
        StateOrProvince,
        Zipcode,
        ListAgentFullName,
        ListAgentMlsId,
        ListAgentEmail,
        ListAgentPreferredPhone,
        ListOfficeName,
        ListOfficeMlsId,
        mls_number,
        PostalCode,
        CountyOrParish,
      } = mlsOrPropertyData || {}

      if (typedMLSId || mls_number) {
        reduxObject.mls_number = typedMLSId || mls_number || id || Id
        propertyPayload.mls_number = typedMLSId || mls_number || id || Id
      }

      if (address) {
        propertyPayload.street = address
      }

      if (City) {
        propertyPayload.city = City
      }

      if (StateOrProvince) {
        propertyPayload.state = getState(StateOrProvince)
        reduxObject.state = getState(StateOrProvince)

      }

      if (PostalCode || Zipcode) {
        propertyPayload.zipcode = PostalCode || Zipcode
      }

      if (CountyOrParish) {
        propertyPayload.county = CountyOrParish
      }

      if (ListAgentFullName) {
        const listingAgent = {
          is_primary: 1,
          agent_id: null,
          is_radius_user: null,
          name: ListAgentFullName || "",
          email: ListAgentEmail || "",
          agent_license_number: ListAgentMlsId || "",
          landline: "",
          phone: ListAgentPreferredPhone || "",
          brokerage: ListOfficeName || "",
          brokerage_license_number: ListOfficeMlsId || "",
          brokerage_address: "",
          apt: null,
          city: "",
          state: "",
          zipcode: "",
        }
        propertyPayload.agents = [listingAgent]
      }
    }

    updatePropertyInformationObject({
      ...reduxObject,
    })

    setStateForOffer(payload.state || "california")
    createOfferFromPropertyType({
      payload,
      history,
      propertyPayload,
      parsedQuery,
      callback: (id) => {
        fetchPropertyInformation({
          id,
          current_offer_step: PROPERTY_INFORMATION_STEP,
        })
        getOfferStatus({ id })

        // getDealDetails({
        //   id,
        //   step: BUYER_INFORMATION_STEP,
        // })
      },
    })
  }

  const { transaction_id, client_id } = data || {}

  return (
    <Modal
      toClose={() => {
        if (tcInformation?.is_tc) {
          toggleBasicOfferDetailsModal(false)
          history.push("/realty/transaction-dashboard")
        } else if (transaction_id) {
          history.push(`/crm/?transactionId=${transaction_id}&client_id=${client_id}&type=buyer`)
        } else {
          history.push("/realty/transaction-dashboard")
        }
      }}
      width="533px"
      show
      modalVersion={3}
      // closeIcon={CloseIcon}
      overlayStyles={{
        alignItems: "flex-start",
        borderRadius: "9px",
        zIndex: 1,
      }}
      modalStyles={{
        padding: "40px 25px 40px 25px",
      }}
      dialogStyles={{
        borderRadius: "8px",
      }}
    >
      <Wrap>
        <ModalTitle>
          <p>Lets get some basic details about your offer.</p>
        </ModalTitle>
        <SectionLabelWrap>
          <SectionCircle>
            <p>1</p>
          </SectionCircle>
          <SectionText>
            <p>Select a Purchase type</p>
          </SectionText>
        </SectionLabelWrap>
        <InputWrap>
          <SelectField
            options={PROPERTY_TYPE}
            style={customSelectStyles}
            arrowIcon={showIcon ? <DropdownUpIcon /> : <DropdownIcon />}
            onChange={handleChange}
            defaultValue="residential_1_4"
            onDropdownVisibleChange={() => setIcon(!showIcon)}
          />
        </InputWrap>
        <ProTip />
        <SectionLabelWrap>
          <SectionCircle>
            <p>2</p>
          </SectionCircle>
          <SectionText>
            <p>Search using MLS ID or Property address</p>
          </SectionText>
        </SectionLabelWrap>
        <MLSTabs
          showLoader={false}
        />
        <CTAWrap>
          <WhiteButton
            onClick={() => {
              if (tcInformation?.is_tc) {
                toggleBasicOfferDetailsModal(false)
                history.push("/realty/transaction-dashboard")
              } else if (transaction_id) {
                history.push(`/crm/?transactionId=${transaction_id}&client_id=${client_id}&type=buyer`)
              } else {
                history.push("/realty/transaction-dashboard")
              }
            }}
          >
            <p>Close</p>
          </WhiteButton>
          <BlueButton
            onClick={handleSubmit}
          >
            <p>Next</p>
          </BlueButton>
        </CTAWrap>
      </Wrap>
    </Modal>
  )
}

export default withRouter(OfferV2(MLSModal))
