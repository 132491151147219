import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { Field } from "redux-form"
import { required, onlyNumbers } from "services/formUtils"
import Input from "./RenderInput"

const LabelStyles = `
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.black};
  margin-bottom: 10px;
`

const InputWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: ${props => props.width || "auto"};
  z-index: 10;
  label {
     input {
      height: 52px;
      border-radius: ${props => props.borderRadius || "10px"};
      border: 1px solid ${colors.darkPurple};
      font-size: ${props => props.fontSize || "16px"};
    }
    input:focus {
      border: 1px solid ${colors.deepPurple};
    }
    > span {
      ${LabelStyles};
    }
    & ~ span {
      position: absolute;
      bottom: -20px;
      left: 0px;
    }
  }

  > div {
    /* For Location */
    > label {
      ${LabelStyles};
    }
    > div {
      border-radius:  ${props => props.borderRadius || "10px"};
      border: 1px solid ${colors.darkPurple};

      div[class$="-menu"] {
        background: ${colors.white};
      }
      input {
        border: none;
        background: ${colors.white};
        & ~ span {
          border: none;
        }
      }

      > div {
        background: none;
        border-color: transparent;
      }
    }
  }
  ${props => props.theme && props.theme === "otherParam" && `
    margin-bottom: 0px;
    label {
      input {
        border: none;
        background: #F1F3F8;
        border-radius: 4px;
        height: 35px;
      }
    }
  `}

`

const getValidation = (isRequired, isOnlyNumbers) => {
  const validate = []
  if (isRequired) validate.push(required)
  if (isOnlyNumbers) validate.push(onlyNumbers)
  return validate
}

const RadiusInput = (props) => {
  const {
    isRequiredField, isRequired, name, placeholder, label, width, borderRadius, theme, spanBackground, type, normalize, isOnlyNumbers,
  } = props
  return (
    <InputWrap width={width} borderRadius={borderRadius} theme={theme}>
      <Field
        name={name}
        component={Input}
        type={type || "text"}
        placeholder={placeholder}
        label={label}
        validate={getValidation(isRequired, isOnlyNumbers)}
        spanBackground={spanBackground}
        normalize={normalize}
        isRequiredField={isRequiredField}
      />
    </InputWrap>

  )
}

export default RadiusInput

