import { createSignalAction, createActionCreator, createSimpleCreator } from "shared/reduxUtils"

const BASE = "NETWORK"

export const getAllFeedsAction = createSignalAction(BASE, "GET_ALL_FEEDS")
export const getTagsBasedOnTopicsAction = createSignalAction(BASE, "GET_TAGS_BASED_ON_TOPICS")
export const getUnfinishedStagesAction = createSignalAction(BASE, "GET_UNFINISHED_STAGES")
export const getCityPreferencesAction = createSignalAction(BASE, "GET_CITY_PREFERENCES")
export const setCityPreferencesAction = createSignalAction(BASE, "SAVE_CITY_PREFERENCES")
export const getAllSpecializationsAction = createSignalAction(BASE, "GET_ALL_SPECIALIZATIONS")
export const getSpecializationPreferencesAction = createSignalAction(BASE, "GET_SPECIALIZATION_PREFERENCES")
export const setSpecializationPreferencesAction = createSignalAction(BASE, "SET_SPECIALIZATION_PREFERENCES")
export const getAllTopicsAction = createSignalAction(BASE, "GET_ALL_TOPICS")
export const getTopicsPreferencesAction = createSignalAction(BASE, "GET_TOPIC_PREFERENCES")
export const setTopicsPreferencesAction = createSignalAction(BASE, "SET_TOPIC_PREFERENCES")
export const getSuggestedAgentsAction = createSignalAction(BASE, "GET_SUGGESTED_AGENTS")
export const followAgentAction = createSignalAction(BASE, "FOLLOW_AGENT")
export const unfollowAgentAction = createSignalAction(BASE, "UNFOLLOW_AGENT")
export const getOnboardingStatusAction = createSignalAction(BASE, "GET_ONBOARDING_STATUS")
export const getCommentsForFeedAction = createSignalAction(BASE, "GET_COMMENTS_FOR_FEED")
export const postCommentForFeedAction = createSignalAction(BASE, "POST_COMMENT_FOR_FEED")
export const uploadPostImageAction = createSignalAction(BASE, "UPLOAD_POST_IMAGE")
export const setTopicAndGetTagsAction = createSignalAction(BASE, "SET_TOPIC_AND_GET_TAGS")
export const createUpdateFeedItemAction = createSignalAction(BASE, "CREATE_UPDATE_FEED_ITEM")
export const getUnansweredQuestionsActions = createSignalAction(BASE, "GET_UNANSWERED_QUESTIONS")
export const getLikersAction = createSignalAction(BASE, "GET_LIKERS")
export const updateFeedLikeAction = createSignalAction(BASE, "UPDATE_FEED_LIKE")
export const updateFeedLikeStandAloneAction = createSignalAction(BASE, "UPDATE_FEED_LIKE_STANDALONE")
export const updateCommentLikeAction = createSignalAction(BASE, "UPDATE_COMMENT_LIKE")
export const getSnippetDataAction = createSignalAction(BASE, "GET_SNIPPET_DATA")
export const startOnboardingAction = createSignalAction(BASE, "START_ONBOARDING")
export const getSuggestedTopicsAction = createSignalAction(BASE, "GET_SUGGESTED_TOPICS")
export const followSuggestedTopicAction = createSignalAction(BASE, "FOLLOW_SUGGESTED_TOPIC")
export const createIntroPostAction = createSignalAction(BASE, "CREATE_INTRO_POST")
export const setAllTopicsAction = createSignalAction(BASE, "SET_ALL_TOPICS")
export const getLatestCourseFromAcademyAction = createSignalAction(BASE, "GET_LATEST_COURSE_FROM_ACADEMY")
export const getStandAloneFeedAction = createSignalAction(BASE, "GET_STANDALONE_FEED")
export const reportOrDeleteAction = createSignalAction(BASE, "REPORT_OR_DELETE")
export const feedsViewedAction = createSignalAction(BASE, "FEEDS_VIEWED")
export const getNetworkActivityAction = createSignalAction(BASE, "GET_NETWORK_ACTIVITY")
export const blockAndUnblockAction = createSignalAction(BASE, "BLOCK_UNBLOCK_USER")
export const getBlockedReasonsAction = createSignalAction(BASE, "GET_REPORT_REASONS")
export const reportFeedAction = createSignalAction(BASE, "REPORT_FEED")

export const OPEN_INVITE_AGENTS_MODAL = "OPEN_INVITE_AGENTS_MODAL"
export const CLOSE_INVITE_AGENTS_MODAL = "CLOSE_INVITE_AGENTS_MODAL"
export const CHANGE_FEED_SELECTION = "CHANGE_FEED_SELECTION"
export const GET_PAGINATED_FEEDS = "GET_PAGINATED_FEEDS"
export const FETCHING_PAGINATED_CALLS = "FETCHING_PAGINATED_CALLS"
export const CHANGE_SELECTED_TOPIC = "CHANGE_TOPIC"
export const SET_USER_CITIES = "SET_USER_CITIES"
export const UPDATE_SPECIALIZATION_PREFERENCE = "UPDATE_SPECIALIZATION_PREFERENCE"
export const UPDATE_TOPICS_PREFERENCE = "UPDATE_TOPICS_PREFERENCE"
export const OPEN_FEED_DETAIL_VIEW = "OPEN_FEED_DETAIL_VIEW"
export const CLOSE_FEED_DETAIL_VIEW = "CLOSE_FEED_DETAIL_VIEW"
export const FINISH_ONBOARDING = "FINISH_ONBOARDING"
export const SET_ONBOARDING_COMPLETE = "SET_ONBOARDING_COMPLETE"
export const UPDATE_CREATE_EDIT_POST_MODAL = "UPDATE_CREATE_EDIT_POST_MODAL"
export const CHANGE_POST_MODAL_STATE = "CHANGE_POST_MODAL_STATE"
export const RESET_CREATE_EDIT_MODAL = "RESET_CREATE_EDIT_MODAL"
export const CREATE_EDIT_TEXT_CHANGE = "CREATE_EDIT_TEXT_CHANGE"
export const OPEN_LIKERS = "OPEN_LIKERS"
export const CLOSE_LIKERS = "CLOSE_LIKERS"
export const OPEN_DISCOVER_AGENTS = "OPEN_DISCOVER_AGENTS"
export const CLOSE_DISCOVER_AGENTS = "CLOSE_DISCOVER_AGENTS"
export const SET_COMMUNITY_GROUP = "SET_COMMUNITY_GROUP"
export const TOGGLE_PERSONALIZE_FEED_MODAL = "TOGGLE_PERSONALIZE_FEED_MODAL"
export const CONFIRM_CLOSE_CREATE_EDIT_MODAL = "CONFIRM_CLOSE_CREATE_EDIT_MODAL"
export const CLOSE_RESET_CREATE_EDIT_MODAL = "CLOSE_RESET_CREATE_EDIT_MODAL"
export const TRIGGER_CREATE_EDIT_MODAL = "TRIGGER_CREATE_EDIT_MODAL"
export const SAVE_INIT_CREATE_EDIT_MODAL = "SAVE_INIT_CREATE_EDIT_MODAL"
export const UPDATE_FEED_ITEM_ON_EDIT = "UPDATE_FEED_ITEM_ON_EDIT"
export const UPDATE_AUTO_FOCUS = "UPDATE_AUTO_FOCUS"
export const OPEN_IMAGE_PREVIEW_MODAL = "OPEN_IMAGE_PREVIEW_MODAL"
export const CLOSE_IMAGE_PREVIEW_MODAL = "CLOSE_IMAGE_PREVIEW_MODAL"
export const REFRESH_CURRENT_FEED = "REFRESH_CURRENT_FEED"
export const RESET_FEED_CACHE = "RESET_FEED_CACHE"
export const REMOVE_MEDIA_AND_LINKIFY = "REMOVE_MEDIA_AND_LINKIFY"
export const INTRO_POST_TEXT_CHANGE = "INTRO_POST_TEXT_CHANGE"
export const UPDATE_SELECTED_INTRO_CITIES = "UPDATE_SELECTED_INTRO_CITIES"
export const TOGGLE_INTRO_POST_MODAL = "TOGGLE_INTRO_POST_MODAL"

export const removeSnippetFromPreviewAction = createSimpleCreator("REMOVE_LINK_FROM_PREVIEW")
export const openInviteAgentsModalAction = createActionCreator(OPEN_INVITE_AGENTS_MODAL)
export const closeInviteAgentsModalAction = createActionCreator(CLOSE_INVITE_AGENTS_MODAL)
export const changeFeedSelectionAction = createActionCreator(CHANGE_FEED_SELECTION)
export const fetchPaginatedCallsAction = createActionCreator(FETCHING_PAGINATED_CALLS)
export const changeSelectedTopicAction = createActionCreator(CHANGE_SELECTED_TOPIC)
export const setUserCitiesAction = createActionCreator(SET_USER_CITIES)
export const handleSpecializationUpdate = createActionCreator(UPDATE_SPECIALIZATION_PREFERENCE)
export const handleTopicsUpdate = createActionCreator(UPDATE_TOPICS_PREFERENCE)
export const openFeedDetailViewAction = createActionCreator(OPEN_FEED_DETAIL_VIEW)
export const closeFeedDetailViewAction = createActionCreator(CLOSE_FEED_DETAIL_VIEW)
export const finishOnboardingAction = createActionCreator(FINISH_ONBOARDING)
export const setOnboardingCompleteAction = createActionCreator(SET_ONBOARDING_COMPLETE)
export const updateCreateEditPostModalAction = createActionCreator(UPDATE_CREATE_EDIT_POST_MODAL)
export const confirmCloseCreateEditModalAction = createActionCreator(CONFIRM_CLOSE_CREATE_EDIT_MODAL)
export const closeResetCreateEditModalAction = createActionCreator(CLOSE_RESET_CREATE_EDIT_MODAL)
export const changePostModalStateAction = createActionCreator(CHANGE_POST_MODAL_STATE)
export const resetCreateEditModalAction = createActionCreator(RESET_CREATE_EDIT_MODAL)
export const createEditTextAction = createActionCreator(CREATE_EDIT_TEXT_CHANGE)
export const openLikersAction = createActionCreator(OPEN_LIKERS)
export const closeLikersAction = createActionCreator(CLOSE_LIKERS)
export const openDiscoverAgentsAction = createActionCreator(OPEN_DISCOVER_AGENTS)
export const closeDiscoverAgentsAction = createActionCreator(CLOSE_DISCOVER_AGENTS)
export const setCommunityGroupAction = createActionCreator(SET_COMMUNITY_GROUP)
export const togglePersonalizeFeedModalAction = createActionCreator(TOGGLE_PERSONALIZE_FEED_MODAL)
export const triggerCreateEditModalAction = createActionCreator(TRIGGER_CREATE_EDIT_MODAL)
export const saveInitCreateEditModalAction = createActionCreator(SAVE_INIT_CREATE_EDIT_MODAL)
export const updateFeedItemOnEditAction = createActionCreator(UPDATE_FEED_ITEM_ON_EDIT)
export const updateAutoFocusAction = createActionCreator(UPDATE_AUTO_FOCUS)
export const openImagePreviewModalAction = createActionCreator(OPEN_IMAGE_PREVIEW_MODAL)
export const closeImagePreviewModalAction = createActionCreator(CLOSE_IMAGE_PREVIEW_MODAL)
export const refreshCurrentFeedAction = createActionCreator(REFRESH_CURRENT_FEED)
export const resetFeedCacheAction = createActionCreator(RESET_FEED_CACHE)
export const removeMediaAndLinkifyAction = createSimpleCreator(BASE, "REMOVE_LINK_MODIFY")
export const removeLinkFromPostEditAction = createSimpleCreator(BASE, "REMOVE_LINKIFY_EDIT_POST")
export const introPostTextChangeAction = createActionCreator(INTRO_POST_TEXT_CHANGE)
export const updateSelectedIntroCitiesAction = createActionCreator(UPDATE_SELECTED_INTRO_CITIES)
export const toggleIntroPostModalAction = createActionCreator(TOGGLE_INTRO_POST_MODAL)
export const insertNewlyCreatedQuestionsInFeedAction = createSimpleCreator(BASE, "INSERT_QUESTION")
export const confirmFeedDeleteAction = createSimpleCreator(BASE, "CONFIRM_FEED_DELETE")
export const getFeedByHomeClickAction = createSimpleCreator(BASE, "CLICKED_ON_HOME")
export const getPeopleToFollowAction = createSignalAction(BASE, "GET_PEOPLE_TO_FOLLOW")
export const getPeopleYouMayKnowAction = createSignalAction(BASE, "GET_PEOPLE_YOU_MAY_KNOW")
export const removeFeedFromFeedListAction = createSimpleCreator(BASE, "REMOVE_FEED_FROM_LIST")
export const toggleReportModalAction = createSimpleCreator(BASE, "TOGGLE_REPORT_MODAL")
export const mutePostAction = createSignalAction(BASE, "MUTE_POST")
export const storeMutedFeedIdAction = createSimpleCreator(BASE, "MUTED_POST_ID_STORE")

export const togglePostInRadiusFeedAction = createSimpleCreator(BASE, "TOGGLE_POST_IN_FEED")
