import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import {
  DropdownWrap,
  MLSImage,
  MLSWrap,
  MLSAddress,
  MLSNumber,
} from "./googleLocationSearchStyles"

const CustomDropdown = ({
  selectedOption,
  setOptions,
  setFetching,
  setDisplay,
  setSelectedOption,
  callback,
  isFetching,
}) => {
  const handleClick = (option) => {
    setOptions([])
    setFetching(false)
    setDisplay("none")
    setSelectedOption(option)
    console.log(option)
    if (callback) {
      callback(option)
    }
  }

  return (
    <>
      {isFetching ? (
        <DropdownWrap>
          <MLSWrap>
            <ShimmerBox w="430px" h="50px" />
          </MLSWrap>
        </DropdownWrap>
      ) : (
        <>
          {selectedOption && selectedOption.map(option => (
            <DropdownWrap
              onClick={() => handleClick(option)}
            >
              <MLSImage src={option.image} alt="mls" />
              <MLSWrap>
                <MLSNumber>
                  {option.id}
                </MLSNumber>
                <MLSAddress>
                  {option.address}
                </MLSAddress>
              </MLSWrap>
            </DropdownWrap>
          ))}
        </>
      )}
    </>
  )
}

export default CustomDropdown
