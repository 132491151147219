import styled from "styled-components"
import {
  Checkbox,
} from "@ui/antd"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #F1F1FE;
  flex-direction: column;
`

export const StyledCheckbox = styled(Checkbox)`
  ${props => props.blackbox && `
      .ant-checkbox-inner {
        border-color: #292A37;
        outline: 0.2px solid #292A37;
      }
  `}

  ${props => props.inMenu && `
    color: var(--Color-Tertiary, #1C1C1F);
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 15.052px */
  `}
`

export const CheckboxText = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: ${MONTSERRAT};
  color: #41446A;
`

export const CTAWrap = styled.div`
  display: flex;
`

export const CTAButton = styled.button`
  display: flex;
  gap: 5px;
  border: none;
  background: none;
  align-items: center;
  cursor: pointer;
`

export const CTAText = styled.p`
  margin: 0;
  color: #5A5FF2;
  font-size: 10px;
  font-family: ${MONTSERRAT};
`

export const AttachmentText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  p {
    color: var(--Typography-General-Primary, #303030);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px; /* 133.333% */
    margin: 0;
  }
  
  img {
    width: 12px;
    height: 13px;
    position: relative;
    top: 3px;
  }
`
