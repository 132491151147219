import React from "react";
import OffersV2 from "container/ListingsV2";
import Listings from "container/Listings";
import {
  ContentWrap,
  StyledCheckbox,
  AttachmentText,
  SubHeadingWrap,
  AddDocument,
  CustomPlusOutlined,
  AddButtonContentWrap,
  TextWrapper,
} from "./styles";
import DynamicMenu from "../DynamicMenu";

const CheckboxListV2 = ({
  list,
  onClick,
  mainList,
  dropdownList,
  currentSearchText,
  customFilterText,
  hideCheckBox,
  img,
  handleSelectedCheck = () => {},
  isActiveFilter,
  showAddDocument,
  isShowAddDocument,
  setEnvelopeIdForAddDocument,
}) => {
  const currentText = currentSearchText || customFilterText;
  return (
    <>
      {Object.entries(list).map(([key, items]) => (
        <>
          <SubHeadingWrap>
            {key.replace(/_/g, " ").toUpperCase()}
          </SubHeadingWrap>
          {items.length > 0 ? (
            <>
              {items.map((item) => (
                <>
                  {item.envelope_id ? (
                    <ContentWrap>
                      <StyledCheckbox
                        checked={item.isSelected}
                        value={item.id}
                        onChange={(e) =>
                          handleSelectedCheck(e, item.id, item, key)
                        }
                      >
                       <TextWrapper>
                        {item.name}
                      </TextWrapper>
                    
                      </StyledCheckbox>
                      {item &&   <DynamicMenu
                        dropdownList={dropdownList}
                        mainList={mainList}
                        itemData={item}
                        id={
                          item.envelope_id ||
                          item.id ||
                          item.template_id ||
                          item.url
                        }
                      />}
                    
                    </ContentWrap>
                  ) : (
                    <AddButtonContentWrap>
                      <AddDocument onClick={() =>{ 
                        setEnvelopeIdForAddDocument(item.id)
                        showAddDocument(true)}} >
                        <CustomPlusOutlined />
                        ADD DOCUMENT
                      </AddDocument>{" "}
                    </AddButtonContentWrap>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              <AddDocument>
                <CustomPlusOutlined />
                ADD DOCUMENT
              </AddDocument>
            </>
          )}
        </>
      ))}
    </>
  );
};

export default Listings(OffersV2(CheckboxListV2));
