import React, { useState } from "react"
import OffersV2 from "container/OffersV2/index"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  AddButtonCTA,
  OffersTooltip,
} from "dumbComponents/OffersV2/components"
import {
  BlueButton,
} from "dumbComponents/OffersV2/commonStyles"
import { EyeIcon, ShareIcon } from "dumbComponents/OffersV2/icons"
import {
  Wrap,
  UploadedWrap,
  ListedDocsWrap,
  DocsTypeTitle,
  CTAWrap,
  TooltipWrap,
  SubmitWrap,
  GoBack,
} from "./style"
import FileUpload from "./FileUpload"
import OfferDoc from "./OfferDoc"

const UploadDocsUI = ({
  uploadedDocs,
  goBack,
  updateLibraryUploads,
  mainList,
  updatingRecommendedDocs,
  handleSubmit,
  updateUploadedDocsObject,
}) => {
  const [currentUploadView, setUploadedView] = useState(false)

  // const OptionList2 = [
  //   {
  //     label: "Documents for the team",
  //     onClick: () => console.log("docs"),
  //   },
  //   {
  //     label: "Documents for personal use",
  //     onClick: () => console.log("personal"),
  //   },
  // ]

  // const MainListV2 = [
  //   {
  //     label: "SEND",
  //     image: <ShareIcon />,
  //     onClick: () => console.log("clicked send"),
  //     showDivide: true,
  //   },
  //   {
  //     label: "VIEW",
  //     image: <EyeIcon />,
  //     onClick: () => console.log("clicked view"),
  //     showDivide: false,
  //   },
  // ]

  // const filterFunction = (val) => {
  //   console.log(val)
  //   setFilterText(val)
  // }

  console.log("uploadedDocs", uploadedDocs)

  const handleUpload = (payload) => {
    updateLibraryUploads({
      payload,
    })
  }

  const customHandleMyDocuments = (e, id) => {
    const checked = e.target.checked
    const currentDocs = [
      ...uploadedDocs.sharedWithMe,
    ]

    currentDocs.forEach((item) => {
      console.log(item.id, id)
      if (item.id === id) {
        item.isSelected = checked
      }
    })

    const updated = {
      ...uploadedDocs,
      sharedWithMe: currentDocs,
    }

    updateUploadedDocsObject(updated)
  }

  const customHandleSharedDocuments = (e, id) => {
    const checked = e.target.checked
    const currentDocs = [
      ...uploadedDocs.teamDocs,
    ]

    currentDocs.forEach((item) => {
      console.log(item.id, id)
      if (item.id === id) {
        item.isSelected = checked
      }
    })

    const updated = {
      ...uploadedDocs,
      teamDocs: currentDocs,
    }

    updateUploadedDocsObject(updated)
  }

  return (
    <Wrap flex>
      {!currentUploadView ? (
        <UploadedWrap>
          <CTAWrap>
            <AddButtonCTA
              name="UPLOAD DOCUMENTS FROM YOUR DESKTOP"
              onClick={() => {
                setUploadedView(true)
              }}
            />
            <TooltipWrap>
              <OffersTooltip
                title="Accepted formats include PDF. File sizes should not exceed 5MB."
              >
                <img src={`${CLOUDFRONT}/OFFERS_V2/information_purple.svg`} alt="tooltip" />
              </OffersTooltip>
            </TooltipWrap>
          </CTAWrap>
          <ListedDocsWrap
            padding
          >
            <DocsTypeTitle>SHARED WITH ME</DocsTypeTitle>
            <OfferDoc
              docs={uploadedDocs.sharedWithMe}
              mainList={mainList}
              customHandle={customHandleMyDocuments}
            />
          </ListedDocsWrap>
          <ListedDocsWrap>
            <DocsTypeTitle>MY DOCUMENTS</DocsTypeTitle>
            <OfferDoc
              docs={uploadedDocs.teamDocs}
              mainList={mainList}
              customHandle={customHandleSharedDocuments}
            />
          </ListedDocsWrap>
          <SubmitWrap>
            <BlueButton maxWidth onClick={handleSubmit}>
              {updatingRecommendedDocs ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Attach selected documents</p>
              )}
            </BlueButton>
          </SubmitWrap>
        </UploadedWrap>
      ) : (
        <FileUpload
          uploadFunction={handleUpload}
          goBack={goBack}
        />
      )}
    </Wrap>
  )
}

export default OffersV2(UploadDocsUI)
