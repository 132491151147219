import React from "react"
import styled from "styled-components"
import { reduxForm, Field, formValueSelector } from "redux-form"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { format } from "date-fns"
import TimePicker from "rc-time-picker"
import DayPickerInput from "react-day-picker/DayPickerInput"
import Modal from "@ui/Modal"
import Box from "@ui/Box"
import Button from "@ui/Button"
import { getEpoch } from "services/Utils"
import { required } from "services/formUtils"
import {
  fetchClientPropertyListingAction,
  setReminderAction,
} from "container/Client/actions"
import { Select, Textarea } from "@ui/Form"
import Heading from "dumbComponents/common/Typography/Heading"
import { ADD_REMINDERS_EVENTS, ADD_REMINDERS_TYPES } from "shared/consts"
import PropertyListing from "./PropertyList"

const Form = styled.form``

const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  > span {
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    border: 1px solid #CCC;
    padding: 12px 8px;
    border-radius: 4px;
  }

  .rc-time-picker-panel-select {
    overflow-x: hidden;
  }
`

const A = styled.a`
  color: #11adf3;
  margin-left: 10px;
`

export const AddReminder = ({
  initialValues,
  eventDate,
  fetchClientPropertyListing,
  isLoadingProperties,
  properties,
  handleSubmit,
  event_type,
  change,
  toClose,
}) => (
  <Form onSubmit={handleSubmit}>
    <Box mb="20" ta="center">
      <span>{`You are setting reminder for ${initialValues.client_name}`}</span>
    </Box>
    <Field
      name="event_type"
      placeholder="Select a event type"
      component={Select}
      options={ADD_REMINDERS_EVENTS}
      label="Event*"
      validate={required}
    />
    { event_type && event_type.value === "property showing" && (
      <PropertyListing
        properties={properties}
        isLoadingProperties={isLoadingProperties}
        fetchClientPropertyListing={fetchClientPropertyListing}
      >
        {!isLoadingProperties && properties && properties.length > 0
          ? (
            <Field
              name="property"
              placeholder="Select a property"
              component={Select}
              options={properties && properties.length > 0 && properties.map(item => (
                { value: item.basic_id, label: item.address }
              ))}
              label="Event*"
              validate={required}
            />
          ) : (
            <p>
              No Property found!
              <A href={`${CRM_URL}app/property/add`}>Add new Property</A>
            </p>
          )}
      </PropertyListing>
    )}
    <Label>
      <span>Event Date</span>
      <DayPickerInput
        formatDate={format}
        format="MM-DD-YYYY"
        placeholder={`${format(new Date(), "MM-DD-YYYY")}`}
        selectedDay={eventDate}
        onDayChange={(date) => {
          change("date", date)
        }}
      />
    </Label>
    <Label>
      <span>Event Time</span>
      <TimePicker
        use12Hours
        placeholder="HH:MM"
        format="h:mm a"
        onChange={(t) => {
          const time = t.toDate()
          change("time", `${time.getHours()}-${time.getMinutes()}`)
        }}
        showSecond={false}
      />
    </Label>
    <Field
      name="notify_time"
      component={Select}
      options={ADD_REMINDERS_TYPES}
      validate={required}
      label="Remind me"
    />
    <Label>
      <span>Notes</span>
      <Field name="note" component={Textarea} />
    </Label>
    <Box d="flex" jc="flex-end" mt="40">
      <Box mr="20">
        <Button
          onClick={toClose}
          bsStyle="secondary"
          type="button"
        >
          Cancel
        </Button>
      </Box>
      <Button type="submit">Create</Button>
    </Box>
  </Form>
)

const formValidation = (values) => {
  const error = {}
  if (!values.date) {
    error.date = "* Required"
  }
  return error
}

let AddReminderForm = reduxForm({
  form: "ADD_REMINDER_FORM",
  validate: formValidation,
})(AddReminder)

const selector = formValueSelector("ADD_REMINDER_FORM")
AddReminderForm = connect(
  (state) => {
    const isLoadingProperties = get(state, "client.isLoadingProperties")
    const properties = get(state, "client.properties.property_list")
    return {
      isLoadingProperties,
      properties,
      event_type: selector(state, "event_type"),
    }
  },
  dispatch => ({
    ...bindActionCreators({
      fetchClientPropertyListing: fetchClientPropertyListingAction.request,
      setReminder: setReminderAction.request,
    }, dispatch),
  })
)(AddReminderForm)

class AddReminderModal extends React.Component {
  state = {
    selectedDate: new Date(),
  }

  handleSubmit = (values) => {
    const { setReminder, referral } = this.props
    const { client_id, id } = referral
    const {
      date,
      time,
      note,
      event_type,
      notify_time,
    } = values
    const dateArray = `${format(date, "YYYY-MM-DD-")}${time}`.split("-")
    // Some weird issue here. Months start from 0 - 11
    const dateObj = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3], dateArray[4])
    const formattedDate = getEpoch(dateObj)
    const payload = {
      client_id,
      type: event_type.label,
      note: note || "",
      date: formattedDate,
      notify_time: notify_time.value,
    }
    setReminder({ payload, id: client_id, referralId: id })
  }

  render() {
    const {
      toClose,
      referral,
    } = this.props
    const { selectedDate } = this.state
    return (
      <Modal
        show
        toClose={toClose}
      >
        <Box>
          <Heading type="h4">Add Reminder</Heading>
          <AddReminderForm
            toClose={toClose}
            onSubmit={this.handleSubmit}
            initialValues={{
              client_name: referral.client_name,
              date: selectedDate,
            }}
          />
        </Box>
      </Modal>
    )
  }
}

export default AddReminderModal
