import React, { useEffect } from "react"
import hubspotTrackerContainer from "container/Realty/hubspotTracker"

const HubspotTracker = ({
  URLBasedHubspotTracking,
}) => {
  useEffect(() => {
    URLBasedHubspotTracking()
  }, [])
  return null
}

export default hubspotTrackerContainer(HubspotTracker)
