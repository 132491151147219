import {
  takeLatest,
  call,
  put,
  takeEvery,
  select,
  throttle,
} from "redux-saga/effects"
import { capitalize, toLower } from "lodash"
import { convertToRichText } from "container/Academy/selector"
import { isSuccess } from "services/Utils"

const formatActionName = (actionName = "") => {
  try {
    const stringArray = actionName.split("_")
    const firstWord = stringArray.splice(0, 1)
    return `${toLower(firstWord)}${stringArray.map(x => capitalize(x)).join("")}`
  } catch (error) {
    return actionName
  }
}

export const createActionCreator = action => arg => ({
  type: action,
  data: arg,
})

export const createBaseActionCreator = (base, action) => {
  const type = `ACTION/${base}/${action}`
  return {
    type,
    action: data => ({
      type,
      data,
    }),
  }
}

export const createSignalAction = function createSignalAction(reducerName, action) {
  return ["REQUEST", "SUCCESS", "FAILURE", "PENDING", "CLEAR"].reduce((prev, curr) => {
    const newPrev = prev
    newPrev[curr] = `SIGNAL/${reducerName}/${action}/${curr}`
    newPrev[curr.toLowerCase()] = createActionCreator(prev[curr])
    return newPrev
  }, {
    RESPONSE: `${formatActionName(action)}Response`,
  })
}

export const createDeltaReducer = (dataName, extra) => ({
  [dataName]: {
    isFetching: false,
    isError: false,
    error: null,
    data: null,
    ...extra,
  },
})

export const createSimpleCreator = (base, action) => ({
  call: arg => ({
    type: `${base}/${action}`,
    data: arg,
  }),
  type: `${base}/${action}`,
})

export const handleDeltaReducer = (state, action, calledAction, key) => {
  const { CLEAR, FAILURE, PENDING, REQUEST, SUCCESS } = calledAction
  const { type, data } = action
  const { isFetchingMore } = data || {}
  const subState = state[key]
  switch (type) {
    case REQUEST: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: !isFetchingMore,
          isFetchingMore,
        },
      }
    }
    case SUCCESS: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: isFetchingMore,
          isFetchingMore: false,
          error: null,
          data,
        },
      }
    }
    case FAILURE: {
      return {
        ...state,
        [key]: {
          ...subState,
          isFetching: false,
          isFetchingMore: false,
          isError: true,
          error: data,
        },
      }
    }
    case CLEAR: {
      return {
        ...state,
        [key]: {
          ...createDeltaReducer(key),
        },
      }
    }
    default:
      return state
  }
}

export function* createSingalSagaHandler(ACTION, api, config) {
  yield takeLatest(ACTION.REQUEST, sagaHandler, ACTION.data, api, config)
}

function* sagaHandler(ACTION, api, config, payload) {
  try {
    const res = yield call(api, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      if (config.isRTParser) {
        const parsedObj = convertToRichText(response)
        yield put(ACTION.success(parsedObj))
        return
      }
    }
  } catch (error) {
    console.log(error)
    yield put(ACTION.failure(error))
  }
}
