import styled from "styled-components"
import {
  Modal,
  Select,
} from "antd"
import { withRouter } from "react-router-dom"
import React, { useState, useRef } from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import OffersV2 from "container/OffersV2/index"
import TechAssets from "container/TechAssets/index"
import ConfirmationModal from "./ConfirmationNote"

const { confirm } = Modal
const { Option } = Select

const StyledCircle = styled.div`
  width: 10px;
  height: 10px;

  background-color: ${props => props.color || "transparent"};
  border-radius: 100px;
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 15px;
`

const OfferStatusSelect = ({
  handleChange,
  getOfferStatusMappingsResponse,
  status,
  addNoteForStatusUpdate,
  noteForStatusUpdate = "",
  id,
  tId,
  history,
  toggleOfferToContractConversion,
  toggleStatusChangeModal,
  toggleActiveTab,
}) => {
  const context = this
  const [currentNote, updateNote] = useState("")
  const textRef = useRef(noteForStatusUpdate)

  // useEffect(() => {
  //   handleChange(currentStatus, true)
  // }, [currentStatus])
  console.log("noteForStatusUpdate", noteForStatusUpdate)

  const {
    data: mappings,
    isFetching: fetchingMappings,
  } = getOfferStatusMappingsResponse || {}

  const getOptions = () => {
    if (mappings) {
      let altered = [...mappings]
      altered.map((item) => {
        const curr = item
        if (curr.value === "new_offer") {
          curr.label = "New Offer"
          curr.color = item.colorHex
          curr.value = item.value
        } else {
          curr.label = item.display_name
          curr.color = item.colorHex
          curr.value = item.value
        }
      })
      return altered
    }

    return []
  }

  return (
    <>
      {fetchingMappings ? (
        <Select
          disabled
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={[
            {
              value: "pending_signature",
              label: "Pending Signature",
            },
          ]}
        />
      ) : (
        <Select
          style={{
            width: 200,
          }}
          disabled={status === "incomplete"}
          optionLabelProp="label"
          onChange={(val) => {
            if (val === "offer_accepted") {
              toggleActiveTab("contract_details")
              toggleOfferToContractConversion({
                bool: true,
                url: `/realty/offers-v3/${id}?transaction_id=${tId}&contract_phase=true`,
              })
              // confirm({
              //   title: "Please confirm the action.",
              //   icon: <ExclamationCircleOutlined />,
              //   content: "Please provide details of the contract.",
              //   onOk() {
              //     history.push(`/realty/offers-v3/${id}?transaction_id=${tId}&contract_phase=true`)
              //   },
              //   onCancel() {
              //     return false
              //   },
              // })
            } else {
              // confirm({
              //   title: "Please confirm the action.",
              //   icon: <ExclamationCircleOutlined />,
              //   content: <ConfirmationModal
              //     updateNote={(val) => {
              //       textRef.current = val
              //     }}
              //   />,
              //   onOk() {
              //     const payload = {
              //       notes: textRef.current,
              //       offer_id: id,
              //       status: val,
              //     }
              //     handleChange(payload)
              //   },

              //   onCancel() {
              //     return false
              //   },
              // })
              toggleStatusChangeModal({
                bool: true,
                id,
                status: val,
                transactionId: tId,
              })
            }
          }}
          // options={getOptions()}
          value={status}
        >
          {getOptions().map(item => (
            <Option value={item.value} label={item.label}>
              <StyledDiv className="demo-option-label-item">
                <StyledCircle
                  color={item.color}
                />
                {item.label}
              </StyledDiv>
            </Option>
          ))}
        </Select>
      )}
    </>
  )
}

export default TechAssets(withRouter(OffersV2(OfferStatusSelect)))

