import * as React from "react";
const SquareArea = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6172 14.1511L14.1378 15.6718"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1514 11.6169L16.672 13.1376"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6865 9.08179L19.2072 10.6024"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.082 16.686L11.6027 18.2067"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2919 7.91343L8.91367 13.2917C7.42851 11.8065 7.42851 9.39859 8.91367 7.91343C10.3988 6.42827 12.8068 6.42827 14.2919 7.91343Z"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.22442 15.9809L5.26602 16.9393C4.91825 17.2902 4.91825 17.8558 5.26602 18.2068L7.79808 20.7418C8.1489 21.0889 8.71378 21.0889 9.0646 20.7418L21.7419 8.06756C22.089 7.71673 22.089 7.15186 21.7419 6.80103L19.2028 4.26597C18.8519 3.9182 18.2862 3.9182 17.9353 4.26597L16.9769 5.22437C14.0066 2.25516 9.19163 2.25605 6.22242 5.22637C3.2532 8.19669 3.2541 13.0116 6.22442 15.9809Z"
      stroke="#232338"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SquareArea;
