import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { TimeZone } from "services/Utils"
import {
  Delete,
  Edit,
} from "../../../Icons"
import { ATC } from "@fonts"

const CardWrap = styled.div`
  height: max-content;
  padding: 6px 8px 6px 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const ReminderTitle = styled.p`
  font-family:${ATC} ;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #364A7D;
`

const ReminderDescription = styled.p`
  /* Byline/Small */

  font-family:${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 445px;
  /* identical to box height, or 133% */


  /* Typography/General/Supporting Text */

  color: #8C8C8C;
`

const ReminderDate = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #303030;
  text-align: right;
`

const RemindMeBy = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #303030;

  span {
    color: #8C8C8C;
  }
`

const InnerWrap = styled.div``

const CTAWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const CTA = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;

  display: flex;
  align-items: center;

  img {
    width: 20px;
  }
`

const ReminderCard = ({
  data,
  toggleShowDeleteReminderModal,
  setEditClicked,
}) => {
  const PSTTime = TimeZone(data.date)
  const { localeTimeString, localeDateString } = PSTTime
  return (
    <CardWrap>
      <InnerWrap>
        <ReminderTitle>
          {data.type}
        </ReminderTitle>
        {data && data.note && (
          <ReminderDescription>
            {data.note}
          </ReminderDescription>
        )}
      </InnerWrap>
      <InnerWrap>
        <ReminderDate>
          {`${localeTimeString}, ${localeDateString}`}
        </ReminderDate>
        <RemindMeBy>
          <span>Remind me:</span>
          {" "}
          {`${data.notify_time} before`}
        </RemindMeBy>
        <RemindMeBy>
          <span>Reminder duration:</span>
          {" "}
          {`${data.event_duration}`}
        </RemindMeBy>
        <CTAWrap>
          <CTA
            onClick={() => setEditClicked(data)}
          >
            <img src={Edit} alt="edit_reminder" />
          </CTA>
          <CTA
            onClick={() => {
              toggleShowDeleteReminderModal(data)
            }}
          >
            <img src={Delete} alt="delete_reminder" />
          </CTA>
        </CTAWrap>
      </InnerWrap>
    </CardWrap>
  )
}

export default CRMV2Container(ReminderCard)
