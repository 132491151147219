import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Field } from "redux-form"
import { required } from "services/formUtils"
import {
  Input,
  RadioGroup,
} from "@ui/Form"
import {
  PercentageDiv,
} from "./CommonStyles"
import {
  FormatToPercentage,
  formatAmount,
} from "./Utils"

const CheckMarkComponent = styled.div`
  margin-top: 52px;

  ${props => props.marginBottom && `
    margin-bottom: ${props.marginBottom};
  `}
`
const OtherInputWrap = styled.div`
  margin-top: 22px;
`

const LabelTextWrap = styled.div``

const LabelText = styled.p``

const modifyForm = (cType, dbTType, dbCSourceType, rentAmountTermType, tranFunc, otherFunc) => {
  useEffect(() => {
    if (cType) {
      tranFunc(cType === "percentage" ? 0 : cType === "fixed_fee" ? 1 : 2)
    } else if (dbTType) {
      otherFunc(dbTType === "other")
    } else if (dbCSourceType) {
      otherFunc(dbCSourceType === "other")
    } else if (rentAmountTermType) {
      otherFunc(rentAmountTermType === "other")
    }
  }, [])
}

const CheckboxComponent = ({
  optionArray,
  labelText,
  customLabelStyles,
  marginBottom,
  noButtonReposition,
  name,
  tTypeExtra,
  dbClientSourceType,
  commissionTypeExtra,
  commissionType,
  transactionType: dbTransactionType,
  basicFormExtra,
  disabled,
  rentTermTypeExtra,
  rentAmountTermType,
}) => {
  const [isOther, setOther] = useState(false)
  const [transactionType, setTransactionType] = useState(-1)


  if (commissionType || dbTransactionType || dbClientSourceType || rentAmountTermType) {
    modifyForm(commissionType, dbTransactionType, dbClientSourceType, rentAmountTermType, setTransactionType, setOther)
  }
  return (
    <CheckMarkComponent
      marginBottom={marginBottom}
    >
      <LabelTextWrap>
        <LabelText
          style={{
            ...customLabelStyles || {},
          }}
        >
          {labelText}
        </LabelText>
      </LabelTextWrap>
      <Field
        name={name}
        component={RadioGroup}
        disabled={disabled}
        fd="column"
        mt="12px"
        clr={disabled ? "#a68ded" : "#4E26BE"}
        labelStyles={{
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "18px",
          letterSpacing: "0em",
          textAlign: "left",
          margin: 0,
        }}
        descriptionStyles={{
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0em",
          textAlign: "left",
          margin: 0,
          color: "#8C8C8C",
        }}
        detailsWrapStyle={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "8px",
        }}
        buttonStyles={{
          bottom: noButtonReposition ? "unset" : "5px",
        }}
        options={optionArray && optionArray.map(item => (
          {
            label: item.name,
            value: item.value,
            description: item.description,
          }
        ))}
        validate={[required]}
        onChange={(event, newValue) => {
          setOther(newValue === "other")
          setTransactionType(newValue === "percentage" ? 0 : newValue === "fixed_fee" ? 1 : 2)
        }}
      />
      {(isOther && tTypeExtra) && (
        <OtherInputWrap>
          <Field
            name="other_transaction_type"
            component={Input}
            placeholder="Other transaction type"
            validate={[required]}
            customInputStyle={{
              height: "44px",
              width: "490px",
              borderRadius: "0px",
              borderBottom: "1px solid #4E26BE",
              background: "transparent",
              borderTop: "none",
              borderLeft: "1px solid #4E26BE",
              borderRight: "none",
            }}
          />
        </OtherInputWrap>
      )}
      {(isOther && rentTermTypeExtra) && (
        <OtherInputWrap>
          <Field
            name="other_rent_amount_term"
            component={Input}
            placeholder="Other Rent Term"
            validate={[required]}
            customInputStyle={{
              height: "44px",
              width: "490px",
              borderRadius: "0px",
              borderBottom: "1px solid #4E26BE",
              background: "transparent",
              borderTop: "none",
              borderLeft: "1px solid #4E26BE",
              borderRight: "none",
            }}
          />
        </OtherInputWrap>
      )}
      {(isOther && basicFormExtra) && (
        <OtherInputWrap>
          <Field
            name="other_source"
            component={Input}
            placeholder="Other source"
            validate={[required]}
            customInputStyle={{
              height: "44px",
              width: "490px",
              borderRadius: "0px",
              borderBottom: "1px solid #4E26BE",
              background: "transparent",
              borderTop: "none",
              borderLeft: "1px solid #4E26BE",
              borderRight: "none",
            }}
          />
        </OtherInputWrap>
      )}
      {commissionTypeExtra
       && (transactionType === 0
       || transactionType === 2)
       && (
         <OtherInputWrap>
           <Field
             name="commision_percentage"
             component={Input}
             placeholder="3%"
             disabled={disabled}
             label="What is your commission percentage?"
             validate={[required]}
             normalize={FormatToPercentage}
             type="text"
             customInputStyle={{
               width: "490px",
             }}
             customLabelStyle={{
               width: "max-content",
             }}
           >
             <PercentageDiv>
               <p>%</p>
             </PercentageDiv>
           </Field>
         </OtherInputWrap>
       )}
      {commissionTypeExtra
       && (transactionType === 1
       || transactionType === 2)
       && (
         <OtherInputWrap>
           <Field
             name="fixed_fee"
             component={Input}
             placeholder="$15,100"
             label="What is your fixed fee?"
             validate={[required]}
             format={formatAmount}
             type="text"
             disabled={disabled}
             customInputStyle={{
               width: "490px",
             }}
           />
         </OtherInputWrap>
       )}
    </CheckMarkComponent>
  )
}

export default CheckboxComponent
