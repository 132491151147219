import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import interestContainer from "container/App/applyFlowContainer"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import { withRouter } from "react-router"
import {
  mobile,
  xsMobile,
} from "@consts"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const Wrap = styled.div`
  height: 389px;
  width: 594px;

  font-family: ${ATC} !important;
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-size: 42px;
    line-height: 46px;
    color: ${colors.black};
    margin-bottom: 0px;
    margin-top: 0px;
    ${props => props.mb && `
      margin-bottom: ${props.mb};
    `}

    ${mobile(`
      font-size: 36px;
      line-height: 45px;
    `)}

    ${xsMobile(`
      font-size: 32px;
      line-height: 42px;
    `)}
  }
  > p {
    font-size: 18px;
    line-height: 24px;
    font-family: ${ATC};
    font-weight: 300;
    color: ${colors.black};
    ${mobile(`
      font-size: 16px;
    `)}
  }
`

const ImageWrap = styled.div`
  height: 172px;
  width: 100%;
  background: #303030 url(${`${CLOUDFRONT}/Promos/Success.png`});
  background-size: 106%;
  background-repeat: no-repeat;
`

const TextBlock = styled.div`
 padding: 24px 25px;

 h1 {
  font-family: Butler;
  font-size: 34px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 18px;
 }

 p {
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
 }
`

class InterestForm extends React.PureComponent {

  componentDidMount() {

  }


  render() {

    const {
      toClose,
      isShowSuccessModal,
      toggleSuccessModal,
    } = this.props || {}

    return (
      <React.Fragment>
        <Modal
          toClose={() => {
            toggleSuccessModal(false)
          }}
          width={594}
          show={isShowSuccessModal}
          modalVersion={3}
          closeIcon={CloseIcon}
          overlayStyles={{
            alignItems: "flex-start",
          }}
          modalStyles={{
            background: `${colors.white}`,
            padding: "0px",
          }}
        >
          <Wrap>
            <ImageWrap />
            <TextBlock>
              <h1>
                We&apos;re excited that you&apos;re interested in learning more about the Radius Brokerage!
              </h1>
              <p>
                Our team will be in touch with you shortly.
              </p>
            </TextBlock>
          </Wrap>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(interestContainer(InterestForm))
