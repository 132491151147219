import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
padding-top: 84px;
`

export const ContentWrap = styled.div`
display: flex;
position: relative;
height: 100vh;
`

export const LeftWrap = styled.div`
  background: #F6F7FF;
  width: ${props => props.width || "50%"};
  display: flex;
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "center"};
  padding-top: ${props => props.paddingTop || "0px"};

  @media(max-width: 1280px) {
    display: none;
  }
`

export const LeftImage = styled.img`
  position: absolute;
  top: 0;
`

export const RightWrap = styled.div`
width: ${props => props.width || "60%"};
display: flex;
flex-direction: column;
justify-content: ${props => props.justifyContent || "center"};
padding: ${props => props.padding || "0px 133px 0px 133px"};
gap: 32px;
`

export const WelcomeContentWrap = styled.div`
display: flex;
flex-direction: column;
align-items: ${props => props.alignItems || "flex-start"};
gap: 2px;
`

export const WelcomeTitle = styled.h1`
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px; /* 121.875% */
  margin: 0;
  width: ${props => props.width || "478px"};
  height: ${props => props.height || "50px"};
  flex-shrink: 0;
`

export const WelcomeDescription = styled.p`
color: var(--color-tertiary, #1C1C1F);
font-family: ${INTER};
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 141%; /* 25.38px */
margin: 0;
width: ${props => props.width || "434px"};
height: fit-content;
`

export const MiddleWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
`

export const StepsWrap = styled.div`
display: flex;
align-items: center;
gap: 19px;
`

export const StepsContentWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
`

export const StepTitle = styled.p`
margin: 0;
color: var(--color-tertiary, #1C1C1F);
font-family: ${MONTSERRAT};
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 150.523%; /* 18.063px */
width: ${props => props.width || "fit-content"};
`

export const StepDescription = styled.h1`
margin: 0;
color: var(--color-tertiary, #1C1C1F);
font-family: ${MONTSERRAT};
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
`

export const GetStartedWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`

export const GetStarted = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 141%; /* 22.56px */
  margin: 0;
`

export const BottomText = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.523%; /* 18.423px */
  width: 383px;
  height: 27px;
  flex-shrink: 0;
`

export const Underlined = styled.span`
  color: #5A5FF2;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.523%;
  text-decoration-line: underline;
  cursor: pointer;
`
export const ScheduleFeeWrap = styled.div`
  min-width: 351px;
  width: fit-content;
  height: max-content;
  flex-shrink: 0;
  border-radius: 9px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(#5A5FF2, #5A5FF2, #FFAB55) border-box;
  border: 1px solid transparent;
  padding: 27px 20px;
`
export const FeeContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.3px solid #C7C9FF;;
  padding: 10px 0px;
  gap: 30px;

  &:last-child {
    border-bottom: none;
    padding: 10px 0px 0px 0px;
  }
  &:first-child {
    padding: 0px 0px 10px 0px;
  }
`

export const ScheduleFeeSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const ScheduleFeeContent = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
  text-transform: capitalize;
  margin: 0;
`

export const FeeContentSmall = styled.p`
  margin: 0;
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 9.031px */
  letter-spacing: 1.26px;
  text-transform: uppercase;
`

export const ICAButtons = styled.button`
display: inline-flex;
padding: 16px 33px;
justify-content: center;
align-items: center;
gap: 10px;
cursor: pointer;

color: #FFF;
font-family: ${MONTSERRAT};
font-size: ${props => props.buttonFS || "15px"};
font-style: normal;
font-weight: 500;
line-height: normal;

border-radius: 33.5px;
background-color: ${props => props.disabled ? "#8486C3" : "#5A5FF2"};

border: none;

${props => props.disabled && `
  cursor: not-allowed;
`}

${props => !props.disabled && `
  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`}

${props => props.height && `
  height: ${props.height};
`}
`