import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "🎥🚪"

export const userCountAction = createSimpleCreator(BASE, "USER_COUNT")
export const removeUserIdAction = createSimpleCreator(BASE, "REMOVE_USER")
export const initLiveRoomAction = createSimpleCreator(BASE, "INIT_LIVE_ROOM")
export const toggleCreateRoomModalAction = createSimpleCreator(BASE, "TOGGLE_CREATE_ROOM_MODAL")
export const toggleSwitchRoomsModalAction = createSimpleCreator(BASE, "TOGGLE_SWITCH_ROOMS_MODAL")
export const toggleEndRoomModalAction = createSimpleCreator(BASE, "TOGGLE_END_ROOM_MODAL")
export const toggleShareRoomLinkModalAction = createSimpleCreator(BASE, "TOGGLE_SHARE_LINK_MODAL")
export const toggleAgentProfileModalAction = createSimpleCreator(BASE, "TOGGLE_AGENT_PROFILE_MODAL")
export const toggleSwitchHostsModalAction = createSimpleCreator(BASE, "TOGGLE_SWITCH_HOSTS_MODAL")
export const toggleSwitchHostsReqModalAction = createSimpleCreator(BASE, "TOGGLE_SWITCH_HOSTS_REQ_MODAL")
export const toggleSpeakerLimitModalAction = createSimpleCreator(BASE, "TOGGLE_SPEAKER_LIMIT_MODAL")
export const toggleInviteAgentToRoomModalAction = createSimpleCreator(BASE, "TOGGLE_INVITE_AGENT_MODAL")
export const toggleParticipantListAction = createSimpleCreator(BASE, "TOGGLE_PARTICIPANT_LIST")
export const toggleRoomInfoAction = createSimpleCreator(BASE, "TOGGLE_ROOM_INFO")
export const addUserToVideoContainerAction = createSimpleCreator(BASE, "ADD_USER_TO_BLOCK")
export const toggleMuteIconAction = createSimpleCreator(BASE, "TOGGLE_MUTE_ICON")
export const toggleKickUserOutModalAction = createSimpleCreator(BASE, "KICK_USER_OUT")
export const toggleSwitchDeviceAction = createSimpleCreator(BASE, "TOGGLE_SWITCH_DEVICE")

export const getTokenAction = createSignalAction(BASE, "GET_TOKEN")
export const getRoomsActions = createSignalAction(BASE, "GET_ROOMS")
export const joinRoomsActions = createSignalAction(BASE, "JOIN_ROOM")
export const createRoomsActions = createSignalAction(BASE, "CREATE_ROOM")
export const leaveRoomAction = createSignalAction(BASE, "LEAVE_ROOM")
export const getSuggestedUsersActions = createSignalAction(BASE, "GET_SUGGESTED_USERS")
export const sendUserInvitationAction = createSignalAction(BASE, "SEND_USER_INVITE")
export const getRoomMembersAction = createSignalAction(BASE, "GET_ROOM_MEMBERS")
export const updateRoomAction = createSignalAction(BASE, "UPDATE_ROOM")
export const muteUserAction = createSignalAction(BASE, "MUTE_USER")
export const kickUserOutAction = createSignalAction(BASE, "KICK_USER")
export const becomeSpeakerAction = createSignalAction(BASE, "BECOME_SPEAKER")
export const speakerRequestAction = createSignalAction(BASE, "SPEAKER_REQ_RES")
export const roomReportReasonAction = createSignalAction(BASE, "ROOM_REPORT_REASON")
export const roomReportUserAction = createSignalAction(BASE, "REPORT_USER_REASON")
export const getTagsAction = createSignalAction(BASE, "GET_TAGS")
export const createScheduleRoomAction = createSignalAction(BASE, "CREATE_SCHEDULE_ROOM")
export const attendeeStatusAction = createSignalAction(BASE, "ATTENDEE_STATUS")
export const handlePubNubAction = createSignalAction(BASE, "HANDLE_PUBNUB")

//UI manipulation actions
export const toggleSpotlightViewAction = createSimpleCreator(BASE, "TOGGLE_SPOTLIGHT_VIEW")
export const toggleOtherUsersViewAction = createSimpleCreator(BASE, "TOGGLE_OTHER_USERS_VIEW")
export const toggleGridViewAction = createSimpleCreator(BASE, "TOGGLE_GRID_VIEW")
export const toggleSidebarViewAction = createSimpleCreator(BASE, "TOGGLE_SIDEBAR_VIEW")
export const screenResizeAction = createSimpleCreator(BASE, "RESIZE_TILES_IN_GRID")

//screen-share related
export const joinRoomAsScreenSharerAction = createSignalAction(BASE, "JOIN_ROOM_AS_SHARER")
export const leaveRoomAsScreenSharerAction = createSignalAction(BASE, "LEAVE_ROOM_AS_SHARER")
export const configureScreenShareAction = createSimpleCreator(BASE, "CONFIGURE_SHARE_SCREEN")
export const toggleScreenShareStatusAction = createSimpleCreator(BASE, "TOGGLE_SCREEN_SHARE")
export const updateScreenShareStoreAction = createSimpleCreator(BASE, "ADD_SCREEN_SHARE_TO_STORE")
export const removeIDScreenShareStoreAction = createSimpleCreator(BASE, "REMOVE_SS_FROM_STORE")

//speaker priviledges
export const setSpeakerPriviledgesAction = createSimpleCreator(BASE, "SET_SPEAKER_PRIVILEDGES")
export const speakerRequestDetailsAction = createSimpleCreator(BASE, "SPEAKER_REQUEST_DETAILS")

//modal
export const toggleControlsRequestModalAction = createSimpleCreator(BASE, "TOGGLE_REQUEST_MODAL")
export const toggleVerifySpeakerRequestModalAction = createSimpleCreator(BASE, "TOGGLE_VERIFY_SPEAKER_REQUEST_MODAL")
export const toggleMakeSpeakerModalAction = createSimpleCreator(BASE, "TOGGLE_MAKE_SPEAKER")
export const addUserToMapAction = createSimpleCreator(BASE, "PUT_TO_MAP")
export const configureHostUIAction = createSimpleCreator(BASE, "CONFIGURE_HOST_UI")
export const configureSpeakersUIAction = createSimpleCreator(BASE, "CONFIGURE_SPEAKRS")
export const configureRemotePinUIAction = createSimpleCreator(BASE, "CONFIGURE_REMOTE_PIN_USERS")
export const configureScreenSharersUIAction = createSimpleCreator(BASE, "CONFIGURE_SCREEN_SHARE_USERS")
export const toggleRemoveSpeakerModalAction = createSimpleCreator(BASE, "TOGGLE_REMOVE_SPEAKER_MODAL")
export const toggleAttendeesListModalAction = createSimpleCreator(BASE, "TOGGLE_ATTENDEES_LIST_MODAL")
export const toggleScreenShareModalAction = createSimpleCreator(BASE, "TOGGLE_SCREEN_SHARE_MODAL")

//controls
export const toggleMicrophoneAction = createSimpleCreator(BASE, "TOGGLE_MICROPHONE")
export const toggleCameraVideoFeedAction = createSimpleCreator(BASE, "TOGGLE_CAMERA_VIDEO")

//heartbeat
export const userHeartBeatAction = createSignalAction(BASE, "DHAK_DHAK")

//sounds
export const playSoundAction = createSimpleCreator(BASE, "PLAY_SOUND")

//hover actions
export const toggleMuteOnHoverElementAction = createSimpleCreator(BASE, "TOGGLE_HOVER_MUTE")
export const hoverAudioToggleAction = createSimpleCreator(BASE, "TOGGLE_HOVER_AUDIO_STATE")

//pinning
export const pinUserLocallyAction = createSimpleCreator(BASE, "PIN_LOCAL")
export const unpinLocalUserAction = createSimpleCreator(BASE, "UNPIN_LOCAL")
export const pinForAllAction = createSignalAction(BASE, "PIN_ALL")
//host transfer
export const hostTransferRequestAction = createSignalAction(BASE, "HOST_TRANSFER_REQUEST")
export const hostTransferResponseAction = createSignalAction(BASE, "HOST-TRANSFER_RESPONSE")

/**Host Only Pin Action*/
export const togglePinForAllAction = createSignalAction(BASE, "TOGGLE_PIN_FOR_ALL")

//Other actions
export const removeUserElementFromScreenAction = createSimpleCreator(BASE, "REMOVE_USER_ELEMENT")
export const revertToHostOnlyUIAction = createSimpleCreator(BASE, "HOST_ONLY_UI")

//banner
export const toggleVideoRoomsBannerAction = createSimpleCreator(BASE, "TOGGLE_BANNER")

//end room
export const roomEndAction = createSimpleCreator(BASE, "TERMINATE_ROOM")

//onboard
export const toggleToLoggedInViewAction = createSimpleCreator(BASE, "TOGGLE_LOGGED_IN_VIEW")

//Joyride
export const triggerJoyRideForVideoRoomsControlsAction = createSimpleCreator(BASE, "TRIGGER_JOYRIDE_HOST_SPEAKER")
export const triggerJoyRideForVideoRoomsListenerAction = createSimpleCreator(BASE, "TRIGGER_JOYRIDE_LISTENER")

//Permissions
export const togglePermissionModalAction = createSimpleCreator(BASE, "TOGGLE_PERMISSIONS_MODAL")

//Onboard
export const setUserOnboardStatusAction = createSimpleCreator(BASE, "SET_USER_ONBOARDED")

//scheduled rooms
export const notifyForScheduledRoomsAction = createSignalAction(BASE, "NOTIFY_FOR_SCHEDULED_ROOMS")
export const getScheduleRoomsAction = createSignalAction(BASE, "GET_SCHEDULE_ROOMS")

//bulk invite
export const bulkInviteBasedOnLocationAction = createSignalAction(BASE, "BULK_INVITE_PER_LOCATION")

//update scheduled rooms
export const updateScheduledRoomsAction = createSignalAction(BASE, "UPDATE_SCHEDULED_ROOMS")

//Schedule Rooms Actions
export const switchUIScheduleRoomsAction = createSimpleCreator(BASE, "SWITCH_UI")
export const switchUItoLoggedOutViewAction = createSimpleCreator(BASE, "SWITCH_LOGGED_OUT")
export const cancelScheduledRoomsAction = createSignalAction(BASE, "CANCEL_SCHEDULED_ROOMS")

//create room actions
export const createRoomPageSwitchAction = createSimpleCreator(BASE, "CREATE_ROOMS_PAGE_SWITCH")
export const handleCreateRoomFormChangeAction = createSimpleCreator(BASE, "HANDLE_CREATE_ROOM_FORM_CHANGES")
export const toggleImageUploadModalAction = createSimpleCreator(BASE, "TOGGLE_IMAGE_UPLOAD_MODAL")
export const getDefaultImagesAction = createSignalAction(BASE, "GET_DEFAULT_IMAGES_ACTION")
export const setImageUrlAction = createSimpleCreator(BASE, "SET_IMAGE_URL")
export const clearFormImageAction = createSimpleCreator(BASE, "CLEAR_IMAGES")
export const uploadImageToS3Action = createSignalAction(BASE, "UPLOAD_TO_S3")
export const speakersStoreAction = createSimpleCreator(BASE, "STORE_SPEAKERS_ACTION")
export const toggleInviteSpeakersModalAction = createSimpleCreator(BASE, "INVITE_AGENT_MODAL_ACTION")
export const toggleInviteAttendeesModalAction = createSimpleCreator(BASE, "INVITE_ATTENDEES_MODAL_ACTION")
export const updatePayloadAction = createSimpleCreator(BASE, "CREATE_PAYLOAD_FOR_CREATION")
export const clearCreatePayloadAction = createSimpleCreator(BASE, "CLEAR_PAYLOAD")
export const clearAndResetAllForms = createSimpleCreator(BASE, "CLEAR")

//schedule rooms login flow
export const toggleConfirmSignInModalAction = createSimpleCreator(BASE, "TOGGLE_SIGN_IN_MODAL")

// landing page actions
export const getRoomTopicsAction = createSignalAction(BASE, "GET_ROOM_TOPICS")
export const getPromotedRoomsAction = createSignalAction(BASE, "GET_PROMOTED_ROOMS")
export const getRoomTopicEventsAction = createSignalAction(BASE, "GET_ROOM_TOPIC_EVENTS")
//speaker functions
export const makeSpeakerBySpeakerAction = createSignalAction(BASE, "MAKE_SPEAKER_BY_SPEAKER")

//pre-recorded
export const getPreRecordedRoomsContentAction = createSignalAction(BASE, "GET_PRE_RECORDED_DETAILS")

//rooms recording
export const joinRoomAsScreenRecordAction = createSignalAction(BASE, "JOIN_ROOM_AS_SCREEN_RECORD")
export const leaveRoomAsScreenRecordAction = createSignalAction(BASE, "LEAVE_ROOM_AS_SCREEN_RECORD")
export const toggleScreenRecordModalAction = createSimpleCreator(BASE, "TOGGLE_SCREEN_RECORD")
export const acquireRoomRecordingAction = createSignalAction(BASE, "ROOMS_RECORDING_ACQUIRE")
export const startRoomsRecordAction = createSignalAction(BASE, "START_ROOMS_RECORDING")
export const stopRoomsRecordAction = createSignalAction(BASE, "STOP_ROOMS_RECORDING")
export const storeTokenAction = createSimpleCreator(BASE, "STORE_TOKEN")
export const toggleRecordingSessionPromptAction = createSimpleCreator(BASE, "TOGGLE_RECORDING_SESSION_PROMPT")
export const storeRIDSIDAction = createSimpleCreator(BASE, "STORE_RID_SID")
export const updateRecordingLayoutAction = createSignalAction(BASE, "UPDATE_LAYOUT")
export const updateSubscriptionListAction = createSignalAction(BASE, "UPDATE_SUBSCRIPTION")
export const toggleStopRecordingModalAction = createSimpleCreator(BASE, "TOGGLE_STOP_RECORDING_MODAL")

//delete room
export const deleteScheduledRoomsAction = createSignalAction(BASE, "DELETE_SCHEDULED_ROOMS")
export const toggleDeleteRoomModalAction = createSimpleCreator(BASE, "TOGGLE_ROOM_DELETE_MODAL")
export const clearRecordingStateAction = createSimpleCreator(BASE, "CLEAR_RECORDING_STATE")
export const createUnSubscribeIDArrayAction = createSimpleCreator(BASE, "UPDATE_UNSUBCRIBE_IDS")

//api to fetch images and types
export const getRoomsMetaDataObjectsAction = createSignalAction(BASE, "GET_ROOMS_META_DATA_OBJECTS")

export const getPromotedRecommendedRoomsAction = createSignalAction(BASE, "GET_RECOMMEDED_ROOMS")
