import React from "react"
import Modal from "@ui/Modal"
import OffersV2 from "container/OffersV2"
import OfferOld from "container/TechAssets"
import {
  Wrap,
  ModalTitle,
  ModalDescription,
  FormWrapper,
} from "./style"
import {
  CreateEnvelopeForm,
} from "./components"

const CreateEnvelopeModal = ({
  toClose,
  sendOfferDocuments,
  docList,
  toggleSideNavTab,
  sendOfferDocumentsResponse,
  getOfferDocuments,
  dealToView,
  propertyInformationObject,
  fetchActiveEnvelopesFiles,
}) => {

  const {
    isFetching: sendingDocument,
  } = sendOfferDocumentsResponse || {}

  const handleSubmit = (values) => {
    //envelopeIds
    const payload = {
      ...values,
      envelopeIds: [],
    }

    const filtered = docList.filter((item) => item.isSelected).map(item => item.envelope_id)

    payload.envelopeIds = [...filtered]

    sendOfferDocuments({
      payload,
      callback: () => {
        toClose()
        toggleSideNavTab("active")
        getOfferDocuments({
          payload: {
            offerId: dealToView,
          },
        })
        fetchActiveEnvelopesFiles({
          payload: {
            offerId: dealToView,
          },
        })
      },
    })
  }

  return (
    <Modal
      toClose={toClose}
      width="533px"
      show
      modalVersion={3}
      // closeIcon={CloseIcon}
      overlayStyles={{
        alignItems: "flex-start",
        borderRadius: "9px",
        zIndex: 1,
      }}
      modalStyles={{
        padding: "40px 25px 0px 25px",
      }}
      dialogStyles={{
        borderRadius: "8px",
      }}
    >
      <Wrap>
        <ModalTitle>
          <p>Customize email message</p>
        </ModalTitle>
        <ModalDescription>
          <p>
            {`These documents will be sent to your client for digital signature 
        only after they have been fully executed by you.`}
          </p>
        </ModalDescription>
        <FormWrapper>
          <CreateEnvelopeForm
            toClose={toClose}
            onFinish={handleSubmit}
            sendingDocument={sendingDocument}
            propertyInformationObject={propertyInformationObject}
          />
        </FormWrapper>
      </Wrap>
    </Modal>
  )
}

export default OffersV2(OfferOld(CreateEnvelopeModal))

