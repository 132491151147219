import React, {useState} from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import cookie from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import { Select } from "antd"
import { ATC, INTER } from "@fonts"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    color: var(--Gray-900, #101828);
    font-family: ${INTER};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 149%; /* 35.76px */
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  color: var(--Gray-500, #667085);
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 149%; /* 23.84px */
  text-align: left;
`

const ContentContainer = styled.div`
  padding: 0px 20px 20px 30px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0px 20px 30px;
`

const AddTransactionPopup = ({
  showAddTransactionPopup,
  toggleAddTransactionPopup,
  history,
  dealToView,
  setEditingDealTeamMemberId,
  header,
  getTeamDetailsResponse,
  createRoute,
  callback,
  subtextForTC,
  subtextForLead,
}) => {
  let defaultValue
  const getMoreText = (agentId, userId, memberInfo) => {
    const tcInformation = cookie.load("tcInformation")
    if (tcInformation?.is_tc && memberInfo.member_type === "Team Lead") {
      return "(Team Lead)"
    }
    if (agentId === userId) {
      defaultValue = `${memberInfo.firstname} ${memberInfo.lastname} (For Me)`
      return "(For Me)"
    }
    return ""
  }

  const getOptions = () => {
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.map(tm => ({
      value: tm.agent_id,
      label: `${tm.firstname} ${tm.lastname || ""} ${getMoreText(tm.agent_id, user.id, tm)}`,
      moreValue: tm.member_type,
    })) : []

    return [
      ...teamNames.filter(tm => tm.moreValue === "Team Lead"),
      ...teamNames.filter(tm => tm.moreValue !== "Team Lead"),
    ]
  }
  const tcInformation = cookie.load("tcInformation")

  const [selectValue, setSelectValue] = useState(null);

  return (
    <Wrap>
      {showAddTransactionPopup && (
        <Modal
          show
          toClose={() => {
            toggleAddTransactionPopup(false)
            setEditingDealTeamMemberId(null)
            setSelectValue(null)
          }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "white",
            borderRadius: "8px",
            minHeight: "211px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <h1>
                {header}
              </h1>
            </Header>
            <ContentContainer>
              <Content>
                {tcInformation?.is_tc ? subtextForTC : subtextForLead}
                
              </Content>
              <Content>
                {/* <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())}
                  // filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                  onChange={(valv) => {
                    setEditingDealTeamMemberId(valv)
                  }}
                  options={getOptions()}
                  onChange={(valv) => {
                    setEditingDealTeamMemberId(valv)
                  }}
                /> */}
                <SelectField
                  borderColor="#D0D5DD"
                  placeholder="Choose"
                  onChange={(valv) => {
                    setEditingDealTeamMemberId(valv)
                    setSelectValue(valv)
                  }}
                  required={true}
                  options={getOptions()}
                  defaultValue={defaultValue}
                />
              </Content>
            </ContentContainer>
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px 33px"
                margin="0 5px"
                width="max-content"
                borderRadius="50px"
                borderWidth="1px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  toggleAddTransactionPopup(false)
                  setEditingDealTeamMemberId(null)
                  setSelectValue(null)
                }}
              >
                No Thanks
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                margin="0 5px"
                width="100%"
                borderWidth="1px"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                disabled={!selectValue && !defaultValue}
                onClick={() => {
                  toggleAddTransactionPopup(false)
                  callback()
                }}
              >
                Proceed
              </LoaderButton>
            </ButtonWrap>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(TeamProfileContainer(AddTransactionPopup)))
