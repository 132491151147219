import React from "react"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  NavLink,
  ImageSpan,
} from "./commonStyles"
import ReferAndEarn from "../Icons/NewIcons/ReferAndEarn"

const RecruitAndEarnTab = ({
  routeArray,
  isBroker,
}) => (
  <InnerTab
    isActive={routeArray[1] === "recruit-and-earn" || routeArray[1] === "refer_and_earn"}
  >
    <div className="icon-block-new">
      <ReferAndEarn />
    </div>
    <div className="text-block">
      <NavLink
        // to={isBroker ? "/realty/recruit-and-earn" : "/realty/promos?type=refer_and_earn"}
        to={"/realty/recruit-and-earn"}
        isActive={routeArray[1] === "recruit-and-earn" || routeArray[1] === "refer_and_earn"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_recruitAndEarnRAR_web")
          }
        }}
      >
        Refer & Earn
        {false && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )}
      </NavLink>
    </div>
  </InnerTab>
)

export default RecruitAndEarnTab
