import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "PRODUCT_INTEREST_MODAL"

export const getAllPimAction = createSignalAction(BASE, "FETCH_ALL_PIM")

export const postFinalCTAAction = createSignalAction(BASE, "POST_FINAL_CTA_CALL")

export const toggleActiveTabAction = createSimpleCreator(BASE, "TOGGLE_ACTIVE_TAB")

export const toggleSideNavAction = createSimpleCreator(BASE, "TOGGLE_SIDE_NAV")

export const toggleCoverSheetAction = createSimpleCreator(BASE, "TOGGLE_COVER_SHEET")

export const toggleMLSTabAction = createSimpleCreator(BASE, "TOGGLE_MLS_TAB")

export const toggleLibraryTabAction = createSimpleCreator(BASE, "TOGGLE_LIBRARY_TAB")

export const setSearchTextAction = createSimpleCreator(BASE, "SET_SEARCH_TEXT")

export const toggleBasicOfferDetailsAction = createSimpleCreator(BASE, "TOGGLE_BASIC_OFFER_DETAILS_MODAL")

export const addNewCoAgentFormAction = createSimpleCreator(BASE, "TOGGLE_ADD_NEW_CO_AGENT_FORM")

export const addBuyerAgentFormAction = createSimpleCreator(BASE, "ADD_BUYER")

export const addVendorAgentFormAction = createSimpleCreator(BASE, "ADD_VENDOR")

export const addLenderAgentFormAction = createSimpleCreator(BASE, "ADD_LENDER")

export const updateBuyerInformationObjectAction = createSimpleCreator(BASE, "UPDATE_BUYER_INFORMATION_OBJECT")

export const updatePropertyInformationObjectAction = createSimpleCreator(BASE, "UPDATE_PROPERTY_INFORMATION_OBJECT")

export const addNewListingAgentToggleAction = createSimpleCreator(BASE, "ADD_NEW_LISTING_AGENT_TOGGLE")

export const addNewSellerAgentToggleAction = createSimpleCreator(BASE, "ADD_NEW_SELLER_TOGGLE")

export const updateDealTermsObjectAction = createSimpleCreator(BASE, "UPDATE_DEAL_TERMS_OBJECT")

export const toggleCreateEnvelopeModalAction = createSimpleCreator(BASE, "TOGGLE_CREATE_ENVELOPE_MODAL")

export const setCurrentStepAction = createSimpleCreator(BASE, "SET_CURRENT_STEP")

export const updateOffersDocumentsObjectAction = createSimpleCreator(BASE, "UPDATE_OFFERS_DOCUMENTS_LIST")

export const updateUploadedDocsObjectAction = createSimpleCreator(BASE, "UPDATE_UPLOADED_DOCS")

export const updateEnvelopesObjectAction = createSimpleCreator(BASE, "UPDATE_ENVELOPE_OBJECT")

export const setMLSDataAction = createSimpleCreator(BASE, "SET_MLS_DATA")

export const updateStatusForCurrentCoverSheet = createSimpleCreator(BASE, "UPDATE_OFFER_STATUS")

export const setStateForOfferAction = createSimpleCreator(BASE, "SET_STATE_FOR_OFFER")

export const updateOfferDocsListAction = createSimpleCreator(BASE, "UPDATE_OFFER_DOC_LIST")

export const updateListingOfferDocsAction = createSimpleCreator(BASE, "UPDATE_LISTING_DOCS")

export const addNoteForStatusUpdateAction = createSimpleCreator(BASE, "ADD_NOTE_FOR_STATUS_UPDATE")

export const updateRecommendedDocsObjectAction = createSimpleCreator(BASE, "UPDATE_RECOMMENDED_LIST")

export const updateProofOfFundsAction = createSimpleCreator(BASE, "UPDATE_PROOF_OF_FUNDS")

export const updatePreApprovedAction = createSimpleCreator(BASE, "UPDATE_PRE_APPROVED")

export const updateCoverLetterAction = createSimpleCreator(BASE, "UPDATE_COVER_LETTER_DOCS")

export const updateOtherDocsAction = createSimpleCreator(BASE, "UPDATE_OTHER_DOCS")

export const updateConfirmationNoteBeforeAction = createSimpleCreator(BASE, "UPDATE_CONFIRMATION_NOTE")

export const updateStateForOffersAction = createSimpleCreator(BASE, "UPDATE_OFFERS_STATE")

//SIGNAL ACTIONS
export const fetchLibraryUploadedFilesAction = createSignalAction(BASE, "FETCH_LIBRARY_UPLOADED_FILES")

export const fetchActiveEnvelopesFilesAction = createSignalAction(BASE, "FETCH_ACTIVE_ENVELOPES")

export const fetchBuyerOffersAction = createSignalAction(BASE, "FETCH_BUYER_OFFERS")

export const fetchInfoBasedOnMLSIdAction = createSignalAction(BASE, "FETCH_INFO_BASED_ON_MLS_ID")

export const fetchInfoBasedOnPropertyAddressAction = createSignalAction(BASE, "FETCH_INFO_BASED_ON_PROPERTY_ADDRESS")

export const createOfferFromPropertyTypeAction = createSignalAction(BASE, "CREATE_OFFER_FROM_PROPERTY_TYPE")

export const getOfferStatusMappingsAction = createSignalAction(BASE, "OFFER_STATUS_MAPPING")

export const updateBuyerInformationTabAction = createSignalAction(BASE, "UPDATE_BUYER_INFORMATION_TAB")

export const updateLenderInformationAction = createSignalAction(BASE, "UPDATE_LENDER_INFORMATION")

export const updateVendorInformationAction = createSignalAction(BASE, "UPDATE_VENDOR_INFORMATION")

export const updatePropertyInformationTabAction = createSignalAction(BASE, "UPDATE_PROPERTY_INFO_TAB")

export const updateDealTermsInformationTabAction = createSignalAction(BASE, "UPDATE_DEAL_TAB")

export const fetchValidateCoverSheetAction = createSignalAction(BASE, "FETCH_VALIDATE_COVER_SHEET")

export const fetchDealTermsInformationAction = createSignalAction(BASE, "FETCH_DEAL_TERMS_INFO")

export const fetchPropertyInformationAction = createSignalAction(BASE, "FETCH_PROPERTY_INFO")

export const fetchVendorInfoAction = createSignalAction(BASE, "FETCH_VENDOR_INFO")

export const updateOfferPurchaseTypeAction = createSignalAction(BASE, "UPDATE_OFFER_PURCHASE_TYPE")

export const fetchOfferDocumentsAction = createSignalAction(BASE, "FETCH_OFFER_DOCUMENTS")

export const updateRecommendedListOfDocsAction = createSignalAction(BASE, "UPDATE_RECOMMENDED_LIST")

export const fetchListingDocumentsAction = createSignalAction(BASE, "FETCH_LISTING_DOCUMENTS")

export const updateLibraryUploadsAction = createSignalAction(BASE, "UPDATE_LIBRARY_UPLOAD")

export const deleteLibraryUploadedFileAction = createSignalAction(BASE, "DELETE_LIBRARY_UPLOADED_FILE")

export const fetchOfferRelatedDocumentsAction = createSignalAction(BASE, "FETCH_OFFER_RELATED_DOCUMENTS")

export const uploadOfferRelatedAction = createSignalAction(BASE, "UPLOAD_OFFER_RELATED_DOCUMENT")

export const sendReminderAction = createSignalAction(BASE, "SEND_REMINDER")

export const createReceiverOfferViewV2Action = createSignalAction(BASE, "TRIGGER_RECEIVER_VIEW")

export const fetchProofOfFundsAction = createSignalAction(BASE, "FETCH_PROOF_OF_FUNDS")

export const fetchPreApprovedAction = createSignalAction(BASE, "FETCH_PRE_APPROVED")

export const fetchCoverLettersAction = createSignalAction(BASE, "FETCH_COVER_LETTERS")

export const fetchOtherAdditionalDocsAction = createSignalAction(BASE, "FETCH_OTHER_ADD_DOCS")

export const transactionUploadFilesAction = createSignalAction(BASE, "UPLOAD_TRANSACTION_FILES")

export const deleteTransactionFileAction = createSignalAction(BASE, "DELETE_TRANSACTION_FILE")

export const fetchTransactionFilesAction = createSignalAction(BASE, "FETCH_TRANSACTION_FILES")

export const fetchOfferPackageDocumentsAction = createSignalAction(BASE, "FETCH_OFFER_PACKAGE_DOCUMENTS")

export const updateOfferPackageDetailsAction = createSimpleCreator(BASE, "UPDATE_OFFER_PACKAGE_DETAILS")

export const sendOfferPackageMailAction = createSignalAction(BASE, "SEND_OFFER_PACKAGE_MAIL")

export const getOfferPackageAction = createSignalAction(BASE, "GET_OFFER_PACKAGE")

export const updateCurrentOfferPackageObjectAction = createSimpleCreator(BASE, "UPDATE_CURRENT_OFFER_PACKAGE")

export const toggleDocumentTabAction = createSimpleCreator(BASE, "TOGGLE_DOCUMENT_LISTING_TAB")

export const getOfferStatusAction = createSignalAction(BASE, "GET_OFFER_STATUS")
