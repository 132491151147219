import styled from "styled-components";
import colors from "@colors";
import { MONTSERRAT, INTER } from "@fonts";

const Header = styled.div`
  color: ${colors.new_grey600};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
  margin-top: 48px;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    margin-top: 32px;
    margin-bottom: 0;
    padding-bottom: 6px;
  }
`;

const ScoreWrap = styled.div`
  display: flex;
  align-items: left;
  margin: 0px 20px;
  @media (max-width: 1024px) {
  margin-top: 25px;
  }
`;
const ScoreCard = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  padding: 12.5px 12px 11.5px 12px;
  justify-content: center;
  align-items: center;
  background: ${colors.new_colorNeutral100};
  border-radius: 50%;
`;
const ScoreDesc = styled.p`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: 0.5px;
`;

const ScoreDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  padding: 0;
`;
const ScoreType = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 16px;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-bottom: 1px dashed ${colors.new_primary};
`;

const Score = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const Wrap = styled.div`
display:flex
margin-bottom: 48px;
margin-left: -18px;
@media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ScoreDiv = styled.div`
  display: flex;
  margin-top: 4px;
`;

export { Header, ScoreWrap, ScoreCard, ScoreDetails, ScoreType, Wrap, Score, ScoreDesc, ScoreDiv };