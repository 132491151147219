import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import { Input } from "@ui/antd"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const { TextArea } = Input

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-family: ${ATC};
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const Label = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  color: ${colors.black};
`

const ContentContainer = styled.div`
  padding: 40px 30px 60px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ConfirmToExecute = ({
  showComfirmToGoToContract, toggleConfirmToExecute, history, dealToView,
  updateDealStatus, getAllListings, showConfirmToExecute, saveListingSteps,
}) => {
  const user = cookie.load("user") || {}
  const [notes, setNotes] = useState("")
  return (
    <Wrap>
      {showConfirmToExecute && (
        <Modal
          show
          toClose={() => { toggleConfirmToExecute(false) }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "#F9F9F7",
            borderRadius: "8px",
            minHeight: "450px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <h1>
                Are you sure?
              </h1>
            </Header>
            <ContentContainer>
              <Content>
                Please provide some notes on the status change for your own records.
              </Content>
              <Label>
                Notes:
              </Label>
              <TextArea
                style={{
                  fontWeight: 300,
                }}
                rows={4}
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </ContentContainer>
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  saveListingSteps({
                    payload: {
                      listing_id: dealToView,
                      step: showConfirmToExecute,
                      notes,
                    },
                    callback: () => {
                      getAllListings({
                        skip: 0,
                        limit: 200,
                        agentId: user.agent_id || user.id,
                        forListing: true,
                        include: "in-contract-listings",
                        transactionType: "Listing",
                      })
                    },
                  })
                  // updateDealStatus({
                  //   dealId: dealToView,
                  //   step: showConfirmToExecute,
                  //   notes,
                  //   callback: (() => {
                  //     getAllListings({
                  //       skip: 0,
                  //       limit: 200,
                  //       agentId: user.agent_id || user.id,
                  //       forListing: true,
                  //       include: "in-contract-listings",
                  //     })
                  //     toggleConfirmToExecute(false)
                  //   }),
                  // })
                }}
              >
                Change status
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px"
                margin="0 5px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  toggleConfirmToExecute(false)
                }}
              >
                No, thanks
              </LoaderButton>
            </ButtonWrap>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(ConfirmToExecute))
