import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import styled from "styled-components"

const Wrap = styled.div``

const ListingShimmer = () => (
  <Wrap>
    <ShimmerBox w="50%" h="45px;" />
  </Wrap>
)

export default ListingShimmer
