import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { get } from "lodash"
import { useWindowSize } from "services/Utils"
import Loader from "@ui/Loader"
import AppContainer from "container/App"
import Container from "container/OnboardingSetup"
import { DownloadApp } from "dumbComponents/Auth/SignupV2/Pages/index"
import CookiesStorage from "services/CookieStorage"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  MiddleWrap,
  StepsWrap,
  StepsContentWrap,
  StepTitle,
  StepDescription,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  GetStarted,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import {
  DashIcon,
  ReviewFreeIcon,
  ComplianceImage,
  signICAActive,
} from "../../consts"

const ICAHomepage = ({
  history,
  verifyToken,
  verifyEmailTokenResponse,
  user,
  getDisplayScreen,
  getDisplayScreenResponse,
}) => {
  const { width } = useWindowSize()

  const secureId = CookiesStorage.load("secureId")
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery || {}
  useEffect(() => {
    if (!secureId && width > 767) {
      const payload = {
        isRequestForBrokerageApp: true,
        authcode,
      }
      verifyToken({
        payload,
        callback: (secureid, email) => getDisplayScreen({ secureId: secureid, email, history }),
      })
    }
  }, [width])

  const firstname = get(verifyEmailTokenResponse, "data.user.firstname", user?.firstname)
  const isFetching = get(verifyEmailTokenResponse, "isFetching")
  const fetchingDisplay = get(getDisplayScreenResponse, "isFetching")

  if (width < 767) {
    return <DownloadApp />
  }

  return (
    <Wrap>
      {(firstname === undefined || isFetching || fetchingDisplay) ? <Loader /> : (
        <ContentWrap>
          <LeftWrap>
            <LeftImage src={ComplianceImage} top="60px" />
          </LeftWrap>
          <RightWrap>
            <WelcomeContentWrap>
              <WelcomeTitle
                height="fit-content"
                width="fit-content"
              >
                {`Welcome to Radius, ${firstname}!`}
              </WelcomeTitle>
              <WelcomeDescription>
                We’re thrilled to have you on board and eager to get started on this exciting new journey with you.
              </WelcomeDescription>
            </WelcomeContentWrap>
            <MiddleWrap>
              <StepsWrap>
                <img src={ReviewFreeIcon} alt="hello" height={46} width={46} />
                <StepsContentWrap>
                  <StepTitle
                    fontWeight="600"
                  >
                    Step 1
                  </StepTitle>
                  <StepDescription>
                    REVIEW FEE SCHEDULE
                  </StepDescription>
                </StepsContentWrap>
              </StepsWrap>
              <img src={DashIcon} alt="okay" height={39} width={52} />
              <StepsWrap>
                <img src={signICAActive} alt="hello" height={46} width={46} />
                <StepsContentWrap>
                  <StepTitle
                    fontWeight="600"
                  >
                    Step 2
                  </StepTitle>
                  <StepDescription>
                    SIGN ICA
                  </StepDescription>
                </StepsContentWrap>
              </StepsWrap>
            </MiddleWrap>
            <GetStartedWrap>
              <GetStarted>
                Ready to begin?
              </GetStarted>
              <ICAButtons
                onClick={() => history.push("/office/ica-onboarding/fee-schedule")}
              >
                Get Started
              </ICAButtons>
            </GetStartedWrap>
          </RightWrap>
        </ContentWrap>
      )}
    </Wrap>
  )
}

export default withRouter(Container(AppContainer(ICAHomepage)))
