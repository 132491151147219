import React, { useEffect, useState } from "react"
import {
  useStripe,
} from "@stripe/react-stripe-js"

const CheckoutForm = ({
  clientSecret,
}) => {
  const stripe = useStripe();

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!clientSecret) {
      return
    }

    console.log(clientSecret, "here")

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      console.log(setupIntent, "here")
      switch (setupIntent.status) {
        case "succeeded":
          console.log("Payment succeeded!", "here")
          break;
        case "processing":
          console.log("Your payment is processing.", "here")
          break;
        case "requires_payment_method":
          console.log("Your payment was not successful, please try again.", "here")
          break;
        default:
          console.log("Something went wrong.", "here")
          break;
      }
    });
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e, "here")

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe.collectBankAccountForSetup({
      clientSecret,
      params: {
        payment_method_type: "us_bank_account",
        payment_method_data: {
          billing_details: {
            name: name,
            email: email,
          },
        },
      },
      expand: ["payment_method"],
    })
      .then(({setupIntent, error}) => {
        console.log(setupIntent, "here")
        if (error) {
          console.log(error.message);
          // The payment failed for some reason.
        } else if (setupIntent.status === "requires_confirmation") {
          stripe.confirmUsBankAccountSetup(
            `${clientSecret}`,
          ).then(({ setupIntent }) => {
            console.log(setupIntent, "here123")
          })
        } else if (setupIntent.status === "succeeded") {
          // Confirmation succeeded! The account is now saved.
          // Display a message to customer.
          console.log(setupIntent.status, "here12")
        } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
          // The account needs to be verified via microdeposits.
          // Display a message to consumer with next steps (consumer waits for
          // microdeposits, then enters a statement descriptor code on a page sent to them via email).
          console.log(setupIntent.status, "here1234")
        }
      })
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div>
        <label>
          Email
        </label>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>
          Name
        </label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <button disabled={!stripe} id="submit">
        <span id="button-text">
          Pay now
        </span>
      </button>
    </form>
  );
}

export default CheckoutForm