import styled from "styled-components"
import colors from "@colors"
import { Menu as MenuIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { NewSelect } from "@ui/antd/Forms"
import { Select } from "antd"
import { MONTSERRAT, INTER } from "@fonts"

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    ${props => `
        color: ${props.textColor} !important;
        background: ${props.backgroundColor} !important;
        background-color: ${props.backgroundColor} !important;
    `}
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    font-family: Inter, "sans-serif" !important;
    font-weight: 600 !important;
    padding: 4px 12px !important;
  }

  .ant-select-arrow {
    ${props => `
        color: ${props.textColor} !important;
    `}
  }

  &.ant-select-focused .ant-select-selector .ant-select-selection-item {
    ${props => `
        color: ${props.textColor} !important;
    `}
    border-color: none;
    font-weight: 600 !important
  }

  &.ant-select .ant-select-disabled {
    ${props => `
        color: ${props.textColor} !important;
        background-color: ${props.backgroundColor} !important;
    `}
  }

  &ant-select-selection-item {
    ${props => `
        color: ${props.textColor} !important;
    `}
    border-color: none;
    font-weight: 600 !important
  }

  &.ant-select-multiple .ant-select-selector {
    height: 100% !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    height: 42px;
    align-items: center;
    font-weight: 600 !important
  }
  &.ant-select-disabled {
     ${props => `
        color: ${props.textColor} !important;
        background-color: transparent !important;
    `}
}
&.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${props => `
        color: ${props.textColor} !important;
        background-color: ${props.backgroundColor} !important;
    `}
}
`

export const StatusSelectContainer = styled.div`
display: flex;
  align-items: center;
  width: 100%

  ${CustomSelect} {
    .ant-select-selector {
        ${props => `
            color: ${props.textColor} !important;
        `}
      text-align: center
      box-shadow: none !important;
      font-family: ${INTER} !important;
      font-weight: 600; !important
      letter-spacing: 0.5px;
      height: 28px !important;
      width: 144px;
      font-size: 12px;
      line-height: 20px
      border-radius: 24px !important;

    }

    &.ant-select .ant-select-selector {
        ${props => `
            background-color: ${props.backgroundColor} !important;
        `}
    }
    

    &.ant-select .ant-select-selector .ant-select-selection-item {
    ${props => `
        color: ${props.textColor} !important;
    `}
    border-color: none;
    font-weight: 600 !important
    }

    .ant-select-arrow {
        ${props => `
            color: ${props.textColor} !important;
        `}
      font-family: ${INTER}, 'sans-serif' !important;
      font-weight: 600 !important;
    }

    &.ant-select .ant-select-disabled {
    ${props => `
        color: ${props.textColor} !important;
        background-color: ${props.backgroundColor} !important;
    `}
  }

    &.ant-select-focused{
      border-color: none;
    }
}
  }
`
