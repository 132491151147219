import cookie from "react-cookies"
import { find, isUndefined } from "lodash"

const date = new Date()
date.setDate(date.getDate() + 365)

const cookieOptions = {
  path: "/",
  domain: COOKIE_HOST,
  expires: date,
}

const CookiesStorage = {
  save: (key, value, options = {}) => cookie.save(key, value, { ...cookieOptions, ...options }),
  load: (key) => {
    if (cookie.load(key)) {
      const value = cookie.load(key)
      if (value === "true" || value === "false") {
        return JSON.parse(value)
      }
      return cookie.load(key)
    }
    return false
  },
  remove: key => cookie.remove(key, cookieOptions),
  checkForCookieUserObject: (history) => {
    const user = CookiesStorage.load("user")
    if (user) {
      window.location.href = `${APP_URL}/community`
    }
  },
  saveUserToCookies: (user) => {
    CookiesStorage.save("user", {
      id: user.id,
      agency_id: user.agency_id,
      agency_name: user.agency_name,
      agent_id: user.id,
      agent_image: !isUndefined(
        find(user.images, {
          type: "profile",
        }),
      )
        ? find(user.images, {
          type: "profile",
        }).path
        : null,
      country: user.country,
      country_code: user.country_code,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      profile_url: user.profile_url || "",
      brokerageTeamIdForLogo: user.brokerageTeamIdForLogo,
    })

    CookiesStorage.save("allowLeadAgreement", user.allowLeadAgreement)
    CookiesStorage.save("isCrsDesignee", user.isCrsDesignee)
    CookiesStorage.save("leadAgreementSigned", user.leadAgreementSigned)
  },
}

export default CookiesStorage
