import React, { useEffect } from "react"
import dynamic from "next/dynamic"
import { convertToHTML } from "services/Utils"
import EditJobIcon from "dumbComponents/RealtyV3/RARRecruitment/images/editjobs.svg"
import {
  HeaderContainer,
  Header,
  Paragraph,
  ButtonGroup,
  ButtonOutlined,
  ButtonFilled,
  Icon,
  RichTextEditorWrapper,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"
let RichTextEditor = null

const Overview = ({
  header,
  overviewEdit,
  editable,
  enableEdit,
  overviewCopy,
  updateField,
  saveField,
  isDummy = false,
  paragraph,
  isLandingPage,
}) => {
  useEffect(() => {
    if (!isLandingPage) {
      RichTextEditor = dynamic(() => import("dumbComponents/common/UI/BuilderComponents/RichTextEditor"), { ssr: false })
    }
  }, [])

  return (
    <React.Fragment>
      <HeaderContainer>
        <Header fw="700" fs="24px" source="overview">
          {header}
        </Header>
        {!overviewEdit && editable && (<Icon src={EditJobIcon} onClick={() => enableEdit("overview", true)} />)}
      </HeaderContainer>
      <Paragraph>
        {overviewCopy && (
          <RichTextEditorWrapper readOnly={!overviewEdit}>
            {overviewEdit ? (
              <>
                {RichTextEditor && (<RichTextEditor readOnly={!overviewEdit} saveRTF={value => updateField({ content: value }, "overview")} text={overviewCopy} height="150px" />)}
              </>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: convertToHTML(overviewCopy) }}></span>
            )}
          </RichTextEditorWrapper>
        )}
        {isDummy && (<>{paragraph}</>)}
      </Paragraph>
      {overviewEdit && (
        <ButtonGroup>
          <ButtonOutlined text="Cancel" action={() => enableEdit("overview", false)} />
          <ButtonFilled text="Save changes" action={() => saveField("overview")} />
        </ButtonGroup>
      )}
    </React.Fragment>
  )
}

export default Overview
