import {
  createSignalAction,
  createActionCreator,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "SUBMIT_REFERRAL"

export const SUBMIT_REFERRAL_NEXT = "SUBMIT_REFERRAL_NEXT"
export const SUBMIT_REFERRAL_BACK = "SUBMIT_REFERRAL_BACK"
export const SELECT_REFERRAL_SERVICE = "SELECT_REFERRAL_SERVICE"

export const submitReferralNextAction = createActionCreator(SUBMIT_REFERRAL_NEXT)
export const submitReferralBackAction = createActionCreator(SUBMIT_REFERRAL_BACK)
export const selectReferralServiceAction = createActionCreator(SELECT_REFERRAL_SERVICE)

// API Action
export const handleCRSFollowAction = createSignalAction(BASE, "HANDLE_CRS_REFERRAL_FLOW")

// Simple Actions
export const saveBasicInfoAction = createSimpleCreator(BASE, "SAVE_BASIC_INFO")
export const saveBuyerNeedsAction = createSimpleCreator(BASE, "SAVE_BUYER_NEEDS")
export const saveReferralBackgroundAction = createSimpleCreator(BASE, "SAVE_REFERRAL_BACKGROUND")
export const saveSellerRequirementAction = createSimpleCreator(BASE, "SAVE_SELLER_REQUIREMENT")
export const submitReferralAction = createSimpleCreator(BASE, "SAVE_REFERRAL")
export const profileSubmitReferralAction = createSimpleCreator(BASE, "PROFILE_SUBMIT_REFERRAL")
export const saveCommissionAction = createSimpleCreator(BASE, "SAVE_COMMISSION")
export const isPartnerFlowAction = createSimpleCreator(BASE, "IS_PARTNER_FLOW")
export const handleLeadReferralErrorAction = createSimpleCreator(BASE, "HANDLE_REFERRAL_ERROR")
export const clearFormAction = createSimpleCreator(BASE, "CLEAR_FORM")
export const clearErrorAction = createSimpleCreator(BASE, "CLEAR_ERROR")
export const loadSubmitReferralPayloadAction = createSimpleCreator(BASE, "LOAD_SUBMIT_REFERRAL")
export const saveSubmitReferralPayloadAction = createSimpleCreator(BASE, "SAVE_SUBMIT_REFERRAL")
export const getClientsSearchAction = createSignalAction(BASE, "GET_CLIENT_SEARCH")
export const setSubmitReferralTypeAction = createSimpleCreator(BASE, "SET_FLOW_TYPE")
export const cancelSubmitReferralFlowAction = createSimpleCreator(BASE, "CANCEL_SUBMIT_REFERRAL_FLOW")
export const setMarketplaceInitValuesAction = createSimpleCreator(BASE, "SET_MARKETPLACE_INIT_VALUES")
export const toggleReferralSuccessModalAction = createSimpleCreator(BASE, "TOGGLE_REFERRAL_SUCCESS_MODAL")
export const setBasicInfoErrorAction = createSignalAction(BASE, "SET_BASIC_INFO_ERROR")
