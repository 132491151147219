import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import colors from "@colors"
import { get } from "lodash"
import queryString from "query-string"
import ChatIcon from "../Images/Chat.svg"
import MailIcon from "../Images/MailSupport.svg"

const Wrap = styled.div`
  height: 200px;
  left: 239px;
  top: 71px;
  background: ${colors.black};
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  padding: 0 48px;
`
const Name = styled.h1`
  font-family: 'Butler';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: ${colors.whiteBlueFaded};;
  margin: 0;
`

const NameWrap = styled.div`
  margin: 77px 0;
`

const Text = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.dreamWhite};
  margin: 0;
`

const SubText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.dreamWhite};
  margin: 0;
`

const HelpContainer = styled.div`
  display: flex;
  margin-top: 77px;
  margin-right: 50px;
`

const LiveChat = styled.div`
  margin-left: 12px;
`

const Icon = styled.img``

const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  border: 1px solid ${colors.dreamWhite};
  color: ${colors.dreamWhite};
  padding: 10px;
  width: 222px;
  height: 69px;
  cursor: pointer;
  ${props => props.marginRight && `
    margin-right: ${props.marginRight}
  `}
`

const sendMail = ({ mailId }) => {
  if (root.heap) {
    root.heap.track("click_send_mail_contact_dashboard")
  }
  const mail = document.createElement("a")
  mail.href = `mailto:${mailId}`
  mail.click()
  return null
}

const callHubspotChat = (history) => {
  if (root.heap) {
    root.heap.track("click_live_chat_contact_dashboard")
  }
  const search = get(root, "location.search", {})
  // const status = window.HubSpotConversations.widget.status()
  // const parsedQuery = queryString.parse(search)
  // if (parsedQuery.show_live_chat) {
  //   //root.HubSpotConversations.widget.open()
  //   return
  // }
  //history.push("?show_live_chat=true&hide_main_thread=true")
  //root.HubSpotConversations.widget.refresh({ openToNewThread: true })
  root.Intercom("showNewMessage")
}

const Header = ({
  name, mailId, history, hideSupport,
}) => (
  <Wrap>
    <NameWrap>
      <Name>
        {`Hello, ${name}! `}
      </Name>
    </NameWrap>
    {!hideSupport && (
      <HelpContainer>
        <SupportContainer marginRight="12px" onClick={() => callHubspotChat(history)}>
          <Icon src={ChatIcon} />
          <LiveChat>
            <Text>
              Live Chat
            </Text>
            <SubText>
              Get one-on-one support.
            </SubText>
          </LiveChat>
        </SupportContainer>

        <SupportContainer marginRight="60px" onClick={() => { sendMail({ mailId }) }}>
          <Icon src={MailIcon} />
          <LiveChat>
            <Text>
              Email Support
            </Text>
            <SubText>
              Send general questions.
            </SubText>
          </LiveChat>
        </SupportContainer>

      </HelpContainer>
    )}
  </Wrap>
)

export default withRouter(Header)
