import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import AppContainer from "container/App"
import CircularImage from "dumbComponents/common/CircularImage"
import RadiusFallbackImage from "images/icons/radius-icon.png"
import RemoveIcon from "images/realty-v2/pricing/Minus.svg"
import Button from "@ui/Button"
import CookiesStorage from "services/CookieStorage"
import createToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { withRouter } from "react-router"
import {
  NotesHeaderWrap, NotesWrap, AgentNotesInfo,
  AgentTitle, AgentPic, AddButton, Icon, Title,
} from "../../../common"

const Wrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

const AgentInfoWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const NotesForm = ({
  setNoteClicked,
  addNote,
  currentClientId,
  user,
  getCtIdResponse,
  location,
}) => {
  const { data } = getCtIdResponse || {}
  const { id } = data || {}
  const [disabled, setDisabled] = useState(true)
  const [note, setNote] = useState("")
  const handleChange = (value) => {
    setNote(value)
    setDisabled(false)
  }
  const { agent_image, firstname, lastname } = user || {}
  const { search } = location
  const queryParams = new URLSearchParams(search)
  const clientIdFromParams = queryParams.get("client_id")

  const handleSubmit = () => {
    if (!note || !note?.length) {
      createToast("Please enter a note.", "error")
      return
    }

    const clientId = currentClientId || clientIdFromParams

    if (!id || !clientId) {
      createToast("Something went wrong while adding the note. Please close the card and try again.", "error")
      return
    }

    const payload = {
      note,
      client_transaction_id: id,
      clientId,
      share_with_sender_agent: true,
    }
    addNote({ payload })
    setNoteClicked(false)
  }

  return (
    <NotesHeaderWrap>
      <NotesWrap showPadding gap={0}>
        <Wrap>
          <AgentPic>
            <CircularImage
              image={agent_image}
              name={`${firstname || ""} ${lastname || ""}`}
              size={72}
            />
          </AgentPic>
          <AgentNotesInfo
            width="100%"
          >
            <AgentInfoWrap>
              <AgentTitle>
                Me
              </AgentTitle>
              <small>
                {new Date().toLocaleDateString([], { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
              </small>
            </AgentInfoWrap>
            <textarea
              style={{
                width: "100%",
                height: "84px",
                background: "#FFFFFF",
                border: "1px solid #C7C7C7",
                borderRadius: "8px",
                padding: "10px",
              }}
              onChange={e => handleChange(e.target.value)}
            />
            {!(note === "") && (
              <Button
                bsStyle="marineBLueFill"
                style={{
                  borderRadius: "0px",
                  width: "100%",
                }}
                onClick={handleSubmit}
                disabled={note === ""}
              >
                Add
              </Button>
            )}
          </AgentNotesInfo>
        </Wrap>
        <AddButton
          onClick={() => {
            setNoteClicked(false)
          }}
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Icon src={RemoveIcon} height={15} />
        </AddButton>
      </NotesWrap>
    </NotesHeaderWrap>
  )
}

export default AppContainer(CRMV2Container(withRouter(NotesForm)))
