import {
  Divider,
  Space,
  Spin,
  Dropdown,
  Table,
  Empty,
  Modal,
  message as Message,
  Carousel,
  Image,
  Popover,
  Pagination,
  Row,
  Col,
  Collapse,
  notification,
  Tag,
} from "antd"
import styled from "styled-components"
import { INTER } from "@fonts"
import colors from "@colors"

const NewStyledTag = styled(Tag)`
  padding: 4px 8px !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 4px !important;
  border: 1px solid ${colors.new_secondary} !important;
  background: #f8f8ff !important;
  color: ${colors.new_grey600} !important;
  font-family: ${INTER} !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  white-space: pre-wrap !important;

  .ant-tag-close-icon {
    color: ${colors.new_grey600} !important;
    font-size: 12px !important;
  }
`

export {
  Divider,
  Space,
  Spin,
  Dropdown,
  Table,
  Empty,
  Modal,
  Message,
  Carousel,
  Image,
  Popover,
  Pagination,
  Row,
  Col,
  Collapse,
  notification,
  NewStyledTag as NewTag,
}
