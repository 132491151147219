/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings/index"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const AnimationWrap = styled.div`
  position: relative;
  top: 14px;

  img {
    width: 49px;
    height: 49px;
  }
`

const GoToContractForListing = ({
  query,
  isDeleteModalOpen,
  deleteListing,
  deleteListingResponse,
}) => {
  const {
    id,
  } = isDeleteModalOpen || {}

  const {
    isFetching: deleting,
  } = deleteListingResponse || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <AnimationWrap>
              <img src={`${CLOUDFRONT}/LISTING_V2/Success.gif`} alt="animation_contracts_success.gif" />
            </AnimationWrap>
            <h1>
              Congratulations!
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              To proceed, kindly provide additional details regarding the contract.
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "blue",
            }}
            onClick={() => {
              //go back to coversheet
            }}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              //prefetch data
              //show loader
              //go to contracts page
            }}
          >
            Confirm
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default Listings(GoToContractForListing)
