import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import { Input } from "@ui/antd"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const { TextArea } = Input

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
    width: 500px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-family: ${ATC};
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const Label = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  color: ${colors.black};
`

const ContentContainer = styled.div`
  padding: 40px 30px 60px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const Alert = ({
  toggleAlertNavigation, showAlertNavigation,
}) => (
  <Wrap>
    {showAlertNavigation && (
      <Modal
        show
        toClose={() => {
          toggleAlertNavigation(false)
        }}
        modalVersion={3}
        closeIcon={CloseIcon}
        showCloseButton
        width="600px"
        modalStyles={{
          marginTop: 0,
          padding: 0,
          boxShadow: "none",
        }}
        customDialogColor={colors.black}
        zIndex={1}
        dialogStyles={{
          background: "#F9F9F7",
          borderRadius: "8px",
          minHeight: "320px",
          fontFamily: ATC,
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "28px",
          lineHeight: "32px",
          textAlign: "center",
          color: colors.black,
        }}
        iconStyles={{
          padding: "8px",
          borderRadius: "18px",
          fontSize: "10px",
          right: "20px",
          width: "40px",
          margin: "6px",
        }}
      >
        <>
          <Header>
            <h1>
              Are You Sure!
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              {showAlertNavigation.content}
            </Content>
          </ContentContainer>
          <ButtonWrap>
            <LoaderButton
              bsStyle="marineBLueFill"
              padding="16px"
              width="50%"
              borderRadius="50px"
              loaderOption={{
                color: "white",
              }}
              onClick={() => {
                toggleAlertNavigation(false)
                showAlertNavigation.nextRoute()
              }}
            >
              {showAlertNavigation.buttonOkText}
            </LoaderButton>
            <LoaderButton
              bsStyle="marineBLueOutlined"
              padding="16px"
              margin="0 5px"
              width="50%"
              borderRadius="50px"
              loaderOption={{
                color: "white",
              }}
              onClick={() => {
                toggleAlertNavigation(false)
              }}
            >
              {showAlertNavigation.buttonCancelText}
            </LoaderButton>
          </ButtonWrap>
        </>
      </Modal>
    )}

  </Wrap>
)

export default withRouter(TechAssetsContainer(Alert))
