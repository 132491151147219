import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as AppActions from "./actions"

const initState = {
  isInvitePopUpOpen: false,
  isInviteFeedPostVisible: true,
  isAnnouncementGlobalModalOpen: false,
  ...createDeltaReducer("getCurrentReferralInviteStatusResponse"),
  ...createDeltaReducer("getStateAnnouncementResponse"),
}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case AppActions.toggleInvitePopUpAction.type: {
      return {
        ...state,
        isInvitePopUpOpen: action.data,
      }
    }

    case AppActions.getCurrentReferralInviteStatusAction.REQUEST:
    case AppActions.getCurrentReferralInviteStatusAction.SUCCESS:
    case AppActions.getCurrentReferralInviteStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.getCurrentReferralInviteStatusAction, "getCurrentReferralInviteStatusResponse"),
      }
    }

    case AppActions.getStateAnnouncementAction.REQUEST:
    case AppActions.getStateAnnouncementAction.SUCCESS:
    case AppActions.getStateAnnouncementAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, AppActions.getStateAnnouncementAction, "getStateAnnouncementResponse"),
      }
    }

    case AppActions.toggleFeedFromPostAction.type: {
      return {
        ...state,
        isInviteFeedPostVisible: action.data,
      }
    }

    case AppActions.toggleStateAnnouncementAction.type: {
      return {
        ...state,
        isAnnouncementGlobalModalOpen: action.data,
      }
    }

    default:
      return state
  }
}
