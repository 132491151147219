import {
  createSimpleCreator,
  createSignalAction,
} from "shared/reduxUtils"

const BASE = "MARKETING"

export const toggleNewPostsCTAAction = createSimpleCreator(BASE, "TOGGLE_NEW_POST_CTA")
export const currentMessageRecievedAction = createSimpleCreator(BASE, "STORE_VIEWED")
export const setCurrentVideoAction = createSimpleCreator(BASE, "SET_VIDEO")
export const toggleVideoModalAction = createSimpleCreator(BASE, "TOGGLE_VIDEO_MODAL")

