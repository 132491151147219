import React, { useState } from "react"
import { normalizePhone } from "services/formUtils"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { Form } from "@ui/antd"
import {
  InputFieldWrap, InputNumberWrap, InputNumberWithPercentageWrap,
} from "./styles"

const InputNumber = ({
  placeholder,
  type,
  name,
  required = false,
  customRules,
  disabled,
  onChange,
  text,
  requiredMessage,
}) => {
  const [internalValue, setInternalValue] = useState(null)

  const formatter = (value) => {
    if (value === undefined || value === null || value === "") {
      return ""
    }
    return `${parseFloat(value).toFixed(2)}%`
  }

  const parser = (value) => {
    if (value == null) {
      return ""
    }
    return value.replace("%", "")
  }

  const handleBlur = () => {
    if (internalValue !== undefined && internalValue !== null && internalValue !== "") {
      const formattedValue = parseFloat(internalValue).toFixed(2)
      setInternalValue(formattedValue)
      onChange(formattedValue)
    }
  }

  const getInputElement = () => {
    switch (type) {
      case "phone":
        return (
          <InputFieldWrap
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            isRequired={required}
          />
        )

      case "money":
        return (
          <InputNumberWrap
            placeholder={placeholder}
            formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
            controls={false}
            disabled={disabled}
            onChange={onChange}
            isRequired={required}
          />
        )

      case "percentage":
        return (
          <InputNumberWithPercentageWrap
            placeholder={placeholder}
            addonAfter="%"
            parser={value => value.replace("%", "")}
            controls={false}
            onChange={(value) => {
              if (onChange) {
                onChange(value?.toFixed(2))
              }
            }}
            disabled={disabled}
            precision={2}
            isRequired={required}
          />
        )

      default:
        return (
          <InputNumberWrap
            placeholder={placeholder}
            controls={false}
            onChange={onChange}
            disabled={disabled}
            isRequired={required}
          />
        )
    }
  }

  const rules = required && requiredMessage ? [{ required: true, message: requiredMessage || "This field is required" }] : required ? [{ required: true, ...customRules }] : []
  return (
    <Form.Item
      name={name}
      rules={rules}
      normalize={type === "phone" ? normalizePhone : ""}
      label={<LabelComponent text={`${text}`} />}
      required={required}
    >
      {getInputElement()}
    </Form.Item>
  )
}

export default InputNumber
