import React from "react"
import styled from "styled-components"
import colors from "@colors"
const linkify = require("linkify-it")()

const StyledTextArea = styled.textarea`
  background-color: transparent;
  word-break: break-word;
  width: 99.5%;
  height: 100%;
  outline: none;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-weight: 300;
  caret-color: ${colors.primaryColor};
  resize: none;
  color: transparent;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  line-height: 1.5;
`

const StyledTextDisplay = styled.div`
  width: 99.5%;
  font-size: 16px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 1.5;
  word-break: break-word;
`

const LinkSpan = styled.span`
  color: ${colors.primaryColor};
`

const getTextToRender = (rawText) => {
  const splitLines = rawText.split("\n")

  const wholeJSX = []

  splitLines.forEach((text, outerIdx) => {
    const match = linkify.match(text)
    const parts = []
    let stringPointer = 0

    if (match) {
      match.forEach((matchItem, idx) => {
        const { index, lastIndex } = matchItem
        const textPart = text.substring(stringPointer, index)
        parts.push(textPart)

        const linkPart = text.substring(index, lastIndex)
        // This will hide the link,
        const linkJSX = <LinkSpan key={`link-${idx}`}>{linkPart}</LinkSpan>
        //const linkJSX = ""
        parts.push(linkJSX)

        stringPointer = lastIndex
      })
    }

    const textPart = text.substring(stringPointer, text.length)
    parts.push(textPart || <br key={`br-${outerIdx}`} />)
    wholeJSX.push(<div key={`div-${outerIdx}`}>{parts}</div>)
  })

  return (
    <React.Fragment>
      {wholeJSX}
    </React.Fragment>
  )
}

const removeLinksFromText = (text) => {
  //const match = linkify.match(text)
  // if (match) {
  //   match.forEach((matchItem, idx) => {
  //     const { index, lastIndex } = matchItem
  //     const textPart = text.substring(stringPointer, index)
  //     parts.push(textPart)

  //     const linkPart = text.substring(index, lastIndex)
  //     // This will hide the link,
  //     //const linkJSX = <LinkSpan key={`link-${idx}`}>{linkPart}</LinkSpan>
  //     const linkJSX = ""
  //     parts.push(linkJSX)

  //     stringPointer = lastIndex
  //   })
  // }
  return text
}

const TextDisplayComponent = ({ text }) => {
  const toRender = getTextToRender(text)
  return (
    <StyledTextDisplay>
      {toRender}
    </StyledTextDisplay>
  )
}

const TextAreaComponent = ({ placeholder, onChange, text }) => (
  <StyledTextArea
    type="text"
    placeholder={placeholder}
    value={text}
    onChange={onChange}
  />
)

const TextFormAndDisplay = ({ placeholder, onChange, text }) => (
  <React.Fragment>
    <TextAreaComponent
      placeholder={placeholder}
      onChange={onChange}
      text={removeLinksFromText(text)}
    />

    <TextDisplayComponent
      text={text}
    />
  </React.Fragment>
)

export default TextFormAndDisplay
