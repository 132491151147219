import styled from "styled-components"
import colors from "@colors"
import Paragraph from "dumbComponents/common/Typography/Paragraph"

export const Title = styled(Paragraph)`
  padding-left: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
`

export const StatusDot = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${props => (props.color ? props.color : "#CCC")};
  display: inline-block;
`

export const Container = styled.div`
  border-right: 2px solid #e7ebed;
  vertical-align: top;
  display: flex;
  flex-direction: column;

  &:last-child{
    border-right: none;
  }
  
`

export const ClientList = styled.div`
  background-color: ${props => (props.isDraggingOver ? "rgba(240, 240, 240, 0.7)" : "inherit")};
  flex-grow: 1;
  padding: 4px 16px 16px;
  min-height: 300px;
  width: 206px;
`

export const CardContainer = styled.div`
  position: relative;
  padding: 5px;
  margin-bottom: 32px;
  width: 100%;
  user-select: none;
  background-color: white;
  border: 2px solid ${props => (props.isDragging ? colors.primaryColor : "white")};
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  }
  ${props => props.isReferralCardBusy
    && `
      opacity: 0.5;
      pointer-events: none;
  `};
`

export const Li = styled.li`
  list-style: none;
  padding: 10px;
  color: ${props => props.color || colors.black};
  display: flex;
  transition-duration: 0.2s;

  &:hover {
    background: #f1f1f1;
    cursor: pointer;
  }

  > span {
    flex: 1;
    font-weight: ${props => props.fontWeight || 300};
    font-family: ${props => props.fontFam || "DM Sans"};
    font-size: ${props => props.fontSize};
    a {
      color: ${colors.black};
    }
  }
  > i {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const CategoryTag = styled.span`
  border-radius: 4px;
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  ${props => props.type === "buyer" && `
    background-color: #354052;
  `}

   ${props => props.type === "seller" && `
    background-image: linear-gradient(254deg, #0ac4ba 101%, #2bda8e -2%);
  `}
`

export const Name = styled.p`
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  line-height: 1.13;
  color: #354052;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
`

export const StarWrap = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 2;
  transform: translateX(35px) translateY(-16px)
`
