import React from "react"
import Listings from "container/Listings"
import { withRouter } from "react-router-dom"
import TechAssetsContainer from "container/TechAssets"
import {
  ActionsList,
  ActionItem,
} from "./style"

const ActionPopOver = ({
  toggleDeleteModal,
  record,
  performNecessaryActionsBeforeLoadingListing,
  history,
  clearExistingData,
  setDealToView,
}) => {
  const handleDelete = () => {
    const listingId = record.id
    toggleDeleteModal({
      bool: true,
      id: listingId,
    })
  }
  const handleView = () => {
    //MUST CLEAN EXISTING DATA
    clearExistingData()
    setDealToView(null)
    console.log("here",record)

    const listingId = record.id
    performNecessaryActionsBeforeLoadingListing({
      dealId: listingId,
      record,
      history,
    })
  }

  return (
    <ActionsList>
      <ActionItem
        onClick={handleView}
      >
        <img src={`${CLOUDFRONT}/LISTING_V2/eye.svg`} alt="eye" />
        <p>VIEW</p>
      </ActionItem>
      <ActionItem
        onClick={handleDelete}
      >
        <img src={`${CLOUDFRONT}/LISTING_V2/dustbin.svg`} alt="dustbin" />
        <p>DELETE</p>
      </ActionItem>
    </ActionsList>
  )
}

export default withRouter(TechAssetsContainer(Listings(ActionPopOver)))
