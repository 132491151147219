import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import {
  StatsCard,
} from "../commonStyles"

/**
 * height: 132px;
  padding: 16px;
  align-items: flex-start;
  gap: 28px;
  flex-shrink: 0;
  width: 491px;
 */

const MetricStatsShimmer = () => (
  <ShimmerBox w="491px" h="132px" />
)

export default MetricStatsShimmer
