import React from "react"
import styled from "styled-components"
import { supressPopups } from "shared/utils"
import OnboardingLastImage from "../../Images/OnboardingLastImage.png"
import FifthFormDetails from "./FifthFormDetails"
import CloseIcon from "../../Images/CrossIcon.png"
import { ATC } from "@fonts"

const Wrap = styled.div`
  width: 950px;
  height: 629px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: #F9F9F7;
  position: fixed;
  z-index: 1000;
  border: 1px solid #E5E5E5;
  left: calc(50% - 950px/2);
  top: calc(50% - 530px/2 + 0.42px);
  overflow: hidden;

  &::-webkit-scrollbar { 
    display: none;
  }
`
const TopContainer = styled.div`
  display: flex;
  position: absolute;
  top: 19px;
  left: 22px;
  justify-content: space-between;
  width: 96%;
  padding: 0px;
  margin: 0px;
`

const TitleDiv = styled.div`
  width: 722px;
  height: 58px;
`
const Title = styled.h1`
  margin: 0px;
  padding: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #303030;
`
const Subtitle = styled.h3`
  margin-top: 9px;
  padding: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #303030;
`
const CrossIcon = styled.div`
  width: 24px;
  height: 24px;
`

const ProgressBar = styled.div`
  display: flex;
  top: 97px;
  left: 22px;
  position: absolute;
`
const FirstStep = styled.div`
  width: 173.2px;
  height: 6px;
  margin-right: 4px;
  background: #3B60E4;
`
const SecondStep = styled.div`
  width: 173.2px;
  height: 6px;
  margin-left: 4px;
  margin-right: 4px;
  background: #3B60E4;

`
const ThirdStep = styled.div`
  width: 173.2px;
  height: 6px;
  margin-left: 4px;
  margin-right: 4px;
  background: #3B60E4;

`
const FourthStep = styled.div`
  width: 173.2px;
  height: 6px;
  margin-left: 4px;
  margin-right: 4px;
  background: #3B60E4;
`
const FifthStep = styled.div`
  width: 173.2px;
  height: 6px;
  margin-left: 4px;
  background: #3B60E4;
`
const FormWrap = styled.div`
  display: flex;
  width: 906px;
  height: 501.16px;
  background: #F9F9F7;
  position: absolute;
  top: 135px;
  left: 22px;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`
const Image = styled.img`
    height: 480px;
    width: 407.6px;
`

const FifthForm = ({ formStage, response, setFormStage, setIsGoBack }) => {
  const handleClose = () => {
    setFormStage("0")
    supressPopups("reportingPopup")
  }
  return (
    <Wrap>
      <TopContainer>
        <TitleDiv>
          <Title>Getting Started with Radius: License & MLS Transfer</Title>
        </TitleDiv>
        <CrossIcon><img src={CloseIcon} alt="close" onClick={handleClose} /></CrossIcon>
      </TopContainer>
      <ProgressBar>
        <FirstStep formStage={formStage} />
        <SecondStep formStage={formStage} />
        <ThirdStep formStage={formStage} />
        <FourthStep formStage={formStage} />
        <FifthStep formStage={formStage} />
      </ProgressBar>
      <FormWrap>
        <Image src={OnboardingLastImage} />
        <FifthFormDetails setIsGoBack={setIsGoBack} response={response} setFormStage={setFormStage} />
      </FormWrap>
    </Wrap>
  )
}

export default FifthForm
