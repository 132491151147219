import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const CoverSheetTitle = styled.div`
  border-bottom: 1px solid var(--Color-Neutral-100, #F1F1FE);
  p {
    overflow: hidden;
    color: var(--Color-Gray-600, #292A37);
    text-overflow: ellipsis;
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.5px;
  }
`

export const MenuUiWrap = styled.div`
  border-radius: 9px;
  border: 1px solid #EBEBEB;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  border-radius: 14px;
  background: #FFF;
  box-shadow: 6px 6px 30px 0px rgba(125, 153, 180, 0.20), 2px 2px 4px 0px rgba(86, 144, 198, 0.11);
  width: 100%;
`
