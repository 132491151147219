import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import {
  Modal, Radio, Space, Input,
} from "@ui/antd"
import colors from "@colors"
import { ATC } from "@fonts"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { get } from "lodash"
import { withRouter } from "react-router-dom"

const { TextArea } = Input

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close {
      top: 15px;
    }
  }
  .ant-modal-footer {
    border-top: none;

    .ant-btn-primary {
      border-radius: 4px;
      background: ${colors.marineBLue} !important;
      border-color: ${colors.marineBLue} !important;
    }

    .ant-btn-default {
      border-radius: 4px;
      color: ${colors.marineBLue} !important;
      border-color: ${colors.marineBLue} !important;
    }
  }
  .ant-modal-body {
    padding: 20px;
  }
`

const ModalTitle = styled.h1`
  margin: 0;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ModalDescription = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
  margin: 5px 0px 15px;
`

const Value = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  font-family: ${ATC};
  color: ${colors.black};
  margin-bottom: 10px;
`

const ArchiveLeadsModal = ({
  isArchiveModalOpen,
  toggleArchiveLeadModal,
  getClientFiltersResponse,
  archiveLead,
  currentCRMDealId,
  currentFilters,
  history,
  isShowWarning,
}) => {
  const [value, setValue] = useState(0)
  const [note, setNote] = useState("")
  const { data } = getClientFiltersResponse || {}
  const ArchiveReasonMap = get(data, "statusChangeReasonMap.Archived")
  const onChange = (e) => {
    setValue(e.target.value)
  }
  const handleSubmit = () => {
    const payload = {
      notes: note,
      reason_id: value,
      transaction_id: currentCRMDealId,
    }
    archiveLead({
      payload,
      currentFilters,
      history,
    })
    toggleArchiveLeadModal(false)
  }
  return (
    <CustomModal
      open={isArchiveModalOpen}
      onOk={handleSubmit}
      onCancel={() => toggleArchiveLeadModal(false)}
      okText="Archive"
      closeIcon={<CloseIcon />}
      okButtonProps={{
        disabled: value === 0,
        type: value === 0 ? "ghost" : "primary",
      }}
    >
      <ModalTitle>
        Archive Leads
      </ModalTitle>
      <ModalDescription>
        Select the reason for archiving lead:
      </ModalDescription>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          {ArchiveReasonMap && ArchiveReasonMap.map(archive => (
            <Radio value={archive.id}><Value>{archive.reason}</Value></Radio>
          ))}
        </Space>
      </Radio.Group>
      <TextArea
        rows={2}
        placeholder="Notes"
        style={{
          marginTop: "10px",
        }}
        onChange={e => setNote(e.target.value)}
      />
    </CustomModal>
  )
}

export default withRouter(CRMV2Container(ArchiveLeadsModal))
