import React from "react"
import { Form } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  InputFieldWrap,
} from "./styles"

const InputField = ({
  placeholder,
  type,
  name,
  required = false,
  style,
  customRules,
  inputType,
  onChange,
  text,
  disabled,
  requiredMessage = "Please enter a value",
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required: true, type: "email", message: "Please enter a valid email" }]
      case "custom":
        return [{ ...customRules }]
      default:
        if (required) {
          return [{ required: true, message: requiredMessage?.length ? requiredMessage : "Please enter a value" }]
        }
        return []
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={`${text}`} />}
      required={required}
      disabled={disabled}
    >
      <InputFieldWrap
        placeholder={placeholder}
        //style={style}
        type={inputType}
        onChange={onChange}
        disabled={disabled}
        isRequired={required}
      />
    </Form.Item>
  )
}

export default InputField
