import React, { useState } from "react";
import { Carousel, Modal, Image } from "antd";
import {
  ImageContainer,
  CustomOverlay,
  StyledImage,
  StyledImageMobile,
  DaysPill,
  CarouselHeader,
  ButtonGroup,
  Button,
  PreviewHeaderMobile,
  PreviewFooterMobile,
  Address,
  CircleButtonPreview,
  TourBtn,
  ModalHeaderContainer,
  ModalPrice,
  StyledCarouselModal,
  CarouselNumbers,
} from "./styles.js";
import {
  HeartOutlined,
  ShareAltOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import ShareIcon from "../Icons/share.js";
import HeartIcon from "../Icons/heart.js";
import ContactAgentModal from "../ContactAgentModal/index.js";
import createToast from "dumbComponents/common/Toast/customToast";
import RequestTourModal from "../RequestTourModal/index";
import root from "window-or-global";

const CarouselMobile = ({ listingPageResponse }) => {
  const [openPhotos, setOpenPhotos] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [openContactModal, setopenContactModal] = useState(false);

  const fetchAllImages = () => {
    const fetchedUrls = listingPageResponse?.data?.Media?.map(
      (item) => item.MediaURL
    );
    setImageUrls(fetchedUrls);
    setOpenPhotos(true);
  };

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = root.document.createElement("input");
    el.value = root.location.href;
    root.document.body.appendChild(el);
    el.select();
    root.document.execCommand("copy");
    root.document.body.removeChild(el);
    setCopied(true);
    createToast("Link Copied !");
  }

  let imageElements = [];
  console.log("urls are here", imageUrls);

  for (let i = 0; i < imageUrls.length; i++) {
    const currentImageUrl = imageUrls[i];
    imageElements.push(
      <Image
        src={currentImageUrl}
        alt={`Image ${i}`}
        style={{ margin: "5px 0px", borderRadius: "5px" }}
      />
    );
  }

  const [requestTourModalState, setRequestTourModalState] = useState(false);
  const [isPreviewModal, setisPreviewModal] = useState(false);

  const handleVisibleChange = (value) => {
    setisPreviewModal(value);
  };

  const handleOpenOnPreview = (modalName) => {
    if (modalName === "cnt") {
      setisPreviewModal(false);
      setopenContactModal(true);
    }
    if (modalName === "requestTour") {
      setisPreviewModal(false);
      setRequestTourModalState(true);
    }
  };

  const modalTitle = (
    <>
      <ModalHeaderContainer>
        <div style={{ marginLeft: "24px" }}>
          <ModalPrice>
            $
            {parseFloat(listingPageResponse?.data?.ListPrice).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 0,
              }
            )}
          </ModalPrice>
          {listingPageResponse?.data?.UnparsedAddress && (
            <Address>
              {listingPageResponse?.data?.UnparsedAddress},{" "}
              {listingPageResponse?.data?.PostalCode}
            </Address>
          )}
        </div>
      </ModalHeaderContainer>
      <hr style={{ color: "#E0E0EB" }}></hr>
    </>
  );

  return (
    <>
      {isPreviewModal && (
        <>
          <PreviewHeaderMobile>
            <p style={{ fontSize: "16px", color: "white" }}>
              $
              {parseFloat(listingPageResponse?.data?.ListPrice).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </p>
            {listingPageResponse?.data?.UnparsedAddress && (
              <Address style={{ color: "#A3A3C2", fontSize: "12px" }}>
                {listingPageResponse?.data?.UnparsedAddress},{" "}
                {listingPageResponse?.data?.PostalCode}
              </Address>
            )}
            <CloseOutlined
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "#A3A3C2",
                alignItems: "flex-end",
              }}
              onClick={() => setisPreviewModal(false)}
            />
          </PreviewHeaderMobile>
          {/* <PreviewFooterMobile>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <CircleButtonPreview
                onClick={() => handleOpenOnPreview("cnt")}
                style={{ width: "40px", height: "40px" }}
              >
                <HeartOutlined
                  style={{ marginBottom: "5px", color: "white" }}
                />
              </CircleButtonPreview>
              <CircleButtonPreview
                onClick={copy}
                style={{ width: "40px", height: "40px" }}
              >
                <ShareAltOutlined
                  style={{ marginBottom: "5px", color: "white" }}
                />
              </CircleButtonPreview>
              <TourBtn
                style={{
                  width: "250px",
                  height: "40px",
                  margin: "0",
                  marginLeft: "16px",
                }}
                onClick={() => handleOpenOnPreview("requestTour")}
              >
                Request a tour
              </TourBtn>
            </div>
          </PreviewFooterMobile> */}
        </>
      )}
      <StyledCarouselModal
        title={modalTitle}
        centered
        open={openPhotos}
        onOk={() => setOpenPhotos(false)}
        onCancel={() => setOpenPhotos(false)}
        width={1200}
      >
        <Image.PreviewGroup
          items={imageUrls}
          preview={{
            visible: isPreviewModal,
            onVisibleChange: (visible) => handleVisibleChange(visible),
          }}
        >
          {imageElements}
        </Image.PreviewGroup>
      </StyledCarouselModal>
      {openContactModal && (
        <ContactAgentModal
          openModal={openContactModal}
          onClose={() => setopenContactModal(false)}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        ></ContactAgentModal>
      )}
      {requestTourModalState && (
        <RequestTourModal
          openModal={requestTourModalState}
          onClose={() => setRequestTourModalState(false)}
          listingPageResponse={listingPageResponse}
          address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}
        />
      )}
      <Image.PreviewGroup items={imageUrls}>
        <Carousel autoplay={false} dots={false} effect="scrollx">
          {listingPageResponse?.data?.Media?.length > 0 &&
            listingPageResponse?.data?.Media.map((item) => (
              <>
                <div key={item.Id}>
                  <CarouselHeader>
                    {listingPageResponse?.data?.DaysOnMarket && (
                      <DaysPill>
                        {`${listingPageResponse?.data?.DaysOnMarket} Days on Market`}
                      </DaysPill>
                    )}
                    {/* <ButtonGroup>
                      <Button onClick={copy}>
                        <ShareIcon />
                      </Button>
                      <Button onClick={() => setopenContactModal(true)}>
                        <HeartIcon />
                      </Button>
                    </ButtonGroup> */}
                  </CarouselHeader>

                  <ImageContainer>
                    <div className="col-md-12">
                      <StyledImageMobile
                        src={item?.MediaURL}
                        alt={`Slide ${item?.MediaURL}`}
                        onClick={fetchAllImages}
                      />
                      <CarouselNumbers>
                        <p>{`${item?.Order + 1}/${
                          listingPageResponse?.data?.Media?.length
                        }`}</p>
                      </CarouselNumbers>
                    </div>
                  </ImageContainer>
                </div>
              </>
            ))}
        </Carousel>
      </Image.PreviewGroup>
    </>
  );
};

export default ListingPageContainer(CarouselMobile);
