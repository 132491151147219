import React from "react";
import OffersV2 from "container/OffersV2";
import {
  Wrap,
  StyledCheckbox,
  AttachmentText,
} from "./styles";
import DynamicMenu from "../DynamicMenu";

const CheckboxList = ({
  list,
  onClick,
  mainList,
  dropdownList,
  currentSearchText,
  customFilterText,
  hideCheckBox,
  img,
  isUploadModal,
  handleSelectedCheck = () => {},
}) => {
  const currentText = currentSearchText || customFilterText;
  const shouldRender = !currentText || list.name.toLowerCase().includes(currentText.toLowerCase());

  if (!shouldRender) {
    return null; 
  }

  return (
    <Wrap key={list.id} style={{display: '-webkit-box', paddingRight: '18px'}}>
      {!hideCheckBox ? (
        <StyledCheckbox
          checked={list.isSelected}
          value={list.id}
          onChange={(e) => handleSelectedCheck(e, list.id)}
        >
          {list.name}
        </StyledCheckbox>
      ) : (
        <AttachmentText>
          {img && <img src={img} alt="paperclip" />}
          <p style={{width:'500px'}}>{list.name}</p>
        </AttachmentText>
      )}
      <DynamicMenu
        dropdownList={dropdownList}
        mainList={mainList}
        id={
          isUploadModal
            ? list.id
            : list.envelope_id || list.template_id || list.id || list.url
        }
      />
    </Wrap>
  );
};

export default OffersV2(CheckboxList);
