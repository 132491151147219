import React from "react"
import { normalizePhone } from "services/formUtils"
import { Form } from "@ui/antd"
import {
  InputFieldWrap, InputNumberWrap,
} from "./styles"

const InputNumber = ({
  placeholder,
  type,
  name,
  required,
  customRules,
}) => {
  const getInputElement = () => {
    switch (type) {
      case "phone":
        return (
          <InputFieldWrap
            placeholder={placeholder}
          />
        )

      case "money":
        return (
          <InputNumberWrap
            placeholder={placeholder}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            controls={false}
          />
        )

      default:
        return (
          <InputFieldWrap
            placeholder={placeholder}
          />
        )
    }
  }

  const rules = [{ required, ...customRules }]
  return (
    <Form.Item
      name={name}
      rules={rules}
      normalize={type === "phone" ? normalizePhone : ""}
    >
      {getInputElement()}
    </Form.Item>
  )
}

export default InputNumber
