import React from "react"
import { Form, NewSelect } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const InputTags = ({
  placeholder,
  type,
  name,
  required,
  style,
  customRules,
  inputType,
  onChange,
  text,
  defaultValue,
  requiredMessage="Required Field"
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required, type: "email", message: "Email is not valid" }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required, message: requiredMessage }]
    }
  }

  const capitaliseValue = () => {
    if (!defaultValue) return defaultValue
    return defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1)
  }

  return (
    <Form.Item name={name} rules={getRules()} label={<LabelComponent text={`${text}`} />}>
      <NewSelect
        mode="tags"
        style={{ width: "100%", ...style }}
        placeholder={placeholder}
        type={inputType}
        onChange={onChange}
        defaultValue={capitaliseValue()}
        open={false}
      />
    </Form.Item>
  )
}

export default InputTags
