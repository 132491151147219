import React, { Component } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Container from "container/Network/CreateEditPost"
import Modal from "@ui/Modal"
import Box from "@ui/Box"
import colors from "@colors"
import Accordion from "@ui/Accordion"
import icons from "@icons"
import MultiTagChooser from "@ui/MultiTagChooser"
import CommunityLocation from "@ui/CommunityLocation"
import Loader from "@ui/Loader"
import Button from "@ui/Button"
import CircularImage from "dumbComponents/common/CircularImage"
import { checkForUserObject } from "services/Utils"
import CreateTextForm from "dumbComponents/Network/CreateEditPost/CreateTextForm"
import PostButton from "./PostButton"
import CharacterCount from "./CharacterCount"
import MediaPreviewSection from "./MediaPreviewSection"
import MediaUpload from "./MediaUpload"
import SnippetPrev from "./SnippetPreview"
const linkify = require("linkify-it")()

const user = checkForUserObject("user")

const TypeSwitchBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid ${colors.borderColor};
`

const StyledTab = styled.div`
  position: relative;
  margin-right: 12px;
  cursor: pointer;
  user-select: none;
  color: ${colors.primaryInactiveColor};

  ${props => props.active
    ? `
      color: ${colors.primaryColor};
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${colors.primaryColor};
        bottom: -13px;
      }`
    : null}
`

const TagContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  font-weight: 300;
  font-size: 14px;
`

const Tag = styled.span`
  padding: 10px 8px;
  border-radius: 6px;
  background-color: ${props => (props.isSelected ? colors.tagActive : "white")};
  border: 1px solid ${props => (props.isSelected ? colors.tagActive : colors.tagInactive)};
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  color: ${props => (props.isSelected ? colors.contentText : colors.tagInactive)};
`

const TopicTitle = styled.div`
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
   color: red;
  }
`

const TextEditWrapper = styled.div`
  padding: 8px 16px;
  background-color: white;
  display: flex;
`

const ProfileImageContainer = styled.div`
  margin-right: 12px;
  width: 50px;
  height: 50px;
`

const TextBoxWrapper = styled.div`
  flex: 1;
  position: relative;
  min-height: 40px;
  margin-top: 12px;
`

const BottomRow = styled.div`
  padding: 8px 24px 8px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.isShowPostInRadiusFeed && `
    background: linear-gradient(0deg, rgb(249, 249, 247), rgb(249, 249, 247)), linear-gradient(0deg, rgb(229, 229, 229), rgb(229, 229, 229));
  `}
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

const TopicWrapper = styled.div`
  background-color: ${colors.greyBackground};
`

const LocationContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderColor};
`

const LocationWrapper = styled.div`
  padding: 4px;
  background-color: #f8f9fa;
  border: 1px solid ${colors.borderColor};
  color: ${colors.tagInactive};
  font-size: 14px;
  font-weight: 300;
  border-radius: 18px;
  display: flex;
  align-items: center;
  width: ${props => (props.fit ? "fit-content" : "100%")};
`

const LocationIcon = styled.i`
  color: ${colors.primaryInactiveColor};
  margin: 0 6px 0 4px;
`

const CloseIcon = styled.i`
  font-size: 10px;
  font-weight: 300;
  color: ${colors.primaryInactiveColor};
  margin: 0 2px 0 6px;
`

const LocationTag = styled.div`
  margin: 0;
  cursor: pointer;
  line-height: 17px;
  width: fit-content;
  display: flex;
  align-items: center;
`

const ConfirmationHeading = styled.h3`
  font-size: 20px;
  margin: 0;
  color: ${colors.contentText};
`

const ConfirmationText = styled.p`
  margin-top: 24px;
  font-size: 14px;
  color: ${colors.contentText};
`

const CTAWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ErrorText = styled.div`
  color: ${colors.redText};
  font-size: 12px;
  flex: 1;
  padding: 0px 20px;
`

const Wrapper = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.disabledInput};
  margin-bottom: 20px;

  ${props => props.isShowPostInRadiusFeed && `
    border: none;
  `}
`

const MAX_TAGS_ALLOWED_COUNT = 2

const ChooseTagsAccordion = () => (
  <TopicTitle>
    <p>Choose up to 2 tags</p>
  </TopicTitle>
)

const TopicChooser = ({
  topics, setTopic, selectedTopicId, isFetchingTopics,
}) => (
  <TopicWrapper>
    <TopicTitle>
      <p>
        Choose a topic
        <span>*</span>
      </p>
      {isFetchingTopics && <Loader size={20} top={0} right={0} margin="0" />}
    </TopicTitle>
    <TagContainer>
      <Box d="flex" wrap="wrap">
        {topics.map(topicObject => (
          <Tag
            key={topicObject.title}
            isSelected={selectedTopicId && topicObject.id === selectedTopicId}
            onClick={() => setTopic(topicObject.id)}
          >
            {topicObject.title}
          </Tag>
        ))}
      </Box>
    </TagContainer>
  </TopicWrapper>
)

class CreateEditPost extends Component {
  state = {
    isFileSizeError: false,
  }

  componentDidMount() {
    const { topics, getAllTopics } = this.props
    if (topics.length === 0) {
      getAllTopics()
    }
  }

  handleClose = () => {
    const { confirmCloseCreateEditModal } = this.props
    confirmCloseCreateEditModal()
  }

  changePostType = (type) => {
    const { updateCreateEditPostModal } = this.props
    updateCreateEditPostModal({
      type,
      mediaType: null,
      mediaURL: null,
    })
  }

  setTopic = (newTopicId) => {
    const {
      setTopicAndGetTags,
      updateCreateEditPostModal,
      topicId,
    } = this.props

    if (topicId !== newTopicId) {
      setTopicAndGetTags({
        topicId: newTopicId,
      })
      updateCreateEditPostModal({
        selectedTags: {},
      })
    }
  }

  handleInputChange = (e) => {
    const rawText = e.target.value
    const { createEditTextChange, getSnippetData, snippetURL } = this.props
    createEditTextChange(rawText)

    const match = linkify.match(rawText)
    if (match && match[0].url !== snippetURL) {
      getSnippetData({
        url: match[0].url,
      })
    }
  }

  handleFileUpload = (file) => {
    const { uploadPostImage } = this.props
    //const sizeInMB = (file.size / 1024) / 1024
    // if (sizeInMB > 4) {
    //   console.log(`Image Size: ${sizeInMB}MB`)
    //   this.setState({
    //     isFileSizeError: true,
    //   })
    // } else {
    //   this.setState({
    //     isFileSizeError: false,
    //   })
    // }

    console.log(file)

    Object.keys(file).forEach((images) => {
      uploadPostImage({
        imageData: file[images],
        mediaType: "image",
      })
    })
  }

  removeLink = () => {
    const { removeLinkFromEditPost } = this.props
    removeLinkFromEditPost()
  }

  removeUploadedImageFunction = (id) => {
    const { removeMediaAndLinkify } = this.props
    removeMediaAndLinkify({
      id,
    })
  }

  handlePost = () => {
    const { createUpdateFeedItem, isShowPostInRadiusFeed, togglePostInRadiusFeed } = this.props
    createUpdateFeedItem()
    if (isShowPostInRadiusFeed) {
      togglePostInRadiusFeed(false)
    }
  }

  changeSelectedTags = (selectedTags) => {
    const { updateCreateEditPostModal } = this.props
    updateCreateEditPostModal({
      selectedTags,
    })
  }

  handleCityAdd = (location) => {
    const locationObject = {
      latitude: location.lat,
      longitude: location.lng,
      country: location.country.long_name,
      city: location.locality.long_name,
      state: location.administrative_area_level_1 ? location.administrative_area_level_1.long_name : null,
    }

    const { updateCreateEditPostModal } = this.props
    updateCreateEditPostModal(locationObject)
  }

  removeLocation = () => {
    const { updateCreateEditPostModal } = this.props
    const locationObject = {
      city: null,
      state: null,
      country: null,
      latitude: null,
      longitude: null,
    }
    updateCreateEditPostModal(locationObject)
  }

  closeResetModal = () => {
    const { closeResetCreateEditModal } = this.props
    closeResetCreateEditModal()
  }

  cancelModalClose = () => {
    const { updateCreateEditPostModal } = this.props
    updateCreateEditPostModal({
      showConfirmCloseModal: false,
    })
  }

  getTypeTabs = (editMode, type) => {
    const tabsMap = {
      post: {
        value: "post",
        name: "Post",
      },
      question: {
        value: "question",
        name: "Add a Question",
      },
    }
    const tabs = []

    if (editMode) {
      tabs.push(tabsMap[type])
      return tabs
    }

    tabs.push(tabsMap.post, tabsMap.question)

    return tabs
  }

  render() {
    const {
      show,
      editMode,
      type,
      tags,
      selectedTags,
      topicId,
      mediaType,
      mediaURL,
      mediaArray,
      isMediaUploading,
      city,
      state,
      latitude,
      longitude,
      isPosting,
      showConfirmCloseModal,
      isFetchingTopics,
      topics,
      isFetchingTags,
      createEditPostText,
      snippetPrev,
      isFetchingSnippet,
      snippetImageURL,
      snippetTitle,
      snippetURL,
      history: {
        location,
      },
      isShowPostInRadiusFeed,
    } = this.props
    const { isFileSizeError } = this.state
    const typeTabs = this.getTypeTabs(editMode, type)

    console.log(mediaArray)
    return (
      <Wrapper
        isShowPostInRadiusFeed={isShowPostInRadiusFeed}
      >
        <Modal
          show={showConfirmCloseModal}
          showCloseButton={false}
          closeModalOnOutsideClick={false}
          closeModalOnEscape={false}
          width="300px"
          mm="120px 0"
          boxShadow
          modalStyles={{
            marginTop: 0,
            padding: 0,
          }}
        >
          <Box p="24px">
            <ConfirmationHeading>
              Discard Changes?
            </ConfirmationHeading>
            <ConfirmationText>
              You can't undo this and you will lose all the changes made to the post
            </ConfirmationText>

            <CTAWrapper>
              <Button width={false} border={false} fontSize="14px" bsStyle="tertiary" onClick={this.closeResetModal}>
                Discard
              </Button>

              <Button width={false} fontSize="14px" onClick={this.cancelModalClose}>
                Keep Changes
              </Button>
            </CTAWrapper>
          </Box>
        </Modal>
        {/* <TypeSwitchBox>
          {typeTabs.map(tab => (
            <StyledTab key={tab.value} active={type === tab.value} onClick={() => this.changePostType(tab.value)}>
              {tab.name}
            </StyledTab>
          ))}
        </TypeSwitchBox> */}

        <TextEditWrapper>
          <ProfileImageContainer>
            <CircularImage
              size={48}
              charSize={16}
              image={user.agent_image}
              name={`${user.firstname || ""} ${user.lastname || ""}`}
            />
          </ProfileImageContainer>

          <TextBoxWrapper>
            <CreateTextForm
              onChange={this.handleInputChange}
              placeholder={
                location.pathname.indexOf("questions") > -1
                  ? "Ask a question to the community.."
                  : "What’s happening?"
              }
            />
          </TextBoxWrapper>
        </TextEditWrapper>
        {(isMediaUploading || mediaArray && mediaArray.length > 0) && (
          <MediaPreviewSection
            isMediaUploading={isMediaUploading}
            mediaURL={mediaURL}
            mediaArray={mediaArray}
            removeMedia={this.removeUploadedImageFunction}
          />
        )}

        {(isFetchingSnippet || (
          snippetTitle && snippetURL
        )) && (
          <SnippetPrev
            data={snippetPrev}
            isFetchingSnippet={isFetchingSnippet}
            removeUploadedImage={this.removeLink}
            snippetTitle={snippetTitle}
            snippetURL={snippetURL}
            snippetImageURL={snippetImageURL}
          />
        )}
        <BottomRow
          isShowPostInRadiusFeed={isShowPostInRadiusFeed}
        >
          <ActionContainer>
            <MediaUpload
              mediaType={mediaType}
              handleFileUpload={this.handleFileUpload}
            />
          </ActionContainer>
          {isFileSizeError && (
            <ErrorText>
              Please upload image less than 4MB
            </ErrorText>
          )}
          <PostButton
            onClick={this.handlePost}
            isMediaUploading={isMediaUploading}
            topicId={topicId}
            isLocationSet={Boolean(latitude && longitude)}
            isPosting={isPosting}
            mediaURL={mediaArray && mediaArray.length > 0}
            isDisable={isFileSizeError || !createEditPostText}
          />
        </BottomRow>

        {/* <LocationContainer>
          <LocationWrapper fit={Boolean(city || state)}>
            <LocationIcon className={icons.locationLocationPoint} />
            {city || state ? (
              <LocationTag onClick={this.removeLocation}>
                {`${city || ""}, ${state || ""}`}
                <CloseIcon className={icons.arrowsClose} />
              </LocationTag>
            ) : (
              <CommunityLocation
                id="post-tag"
                types={["(cities)"]}
                location={this.handleCityAdd}
                placeholder={`${topicId === 62 ? "Required" : "Optional"}: add a location related to this post`}
                loader
              />
            )}
          </LocationWrapper>

          <CharacterCount />
        </LocationContainer> */}

        {/* <TopicChooser
          topics={topics}
          selectedTopicId={topicId}
          setTopic={this.setTopic}
          isFetchingTopics={isFetchingTopics}
        /> */}

        {/* {!isFetchingTags ? (
          <Accordion
            initialOpen
            toggleComponent={<ChooseTagsAccordion />}
            renderComponent={(
              <MultiTagChooser
                tags={tags}
                maxSelectCount={MAX_TAGS_ALLOWED_COUNT}
                selectedTags={selectedTags}
                onChange={this.changeSelectedTags}
                tagContainerStyle={{
                  padding: "10px 24px 0",
                  backgroundColor: colors.greyBackground,
                }}
                tagKey="title"
                selectKey="id"
              />
            )}
          />
        ) : (
          <Box p="20">
            <Loader size={20} top={20} />
          </Box>
        )} */}


      </Wrapper>
    )
  }
}

export default withRouter(Container(CreateEditPost))
