import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 40px 30px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const AnimationWrap = styled.div`
  img {
    width: 49px;
    height: 49px;
  }
`

const ConfirmToGoToContracts = ({ showComfirmToGoToContract, toggleComfirmToGoToContract, history, dealToView, setEditingDealTeamMemberId, isListing, saveListingSteps, getContractById,setDealToView  }) => {
  const redirectUrl = isListing ? `/realty/listings/in-contracts/basic-info?dealId=${dealToView}&status=listing_pending`
    : `/realty/offers/in-contracts/basic-info?offerId=${dealToView}&status=offer_accepted`
  return (
    <Wrap>
      {showComfirmToGoToContract && (
        <Modal
          show
          toClose={() => { 
            toggleComfirmToGoToContract(false)
            setEditingDealTeamMemberId(null)
          }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "#F9F9F7",
            borderRadius: "8px",
            minHeight: "280px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <AnimationWrap>
                <img src={`${CLOUDFRONT}/LISTING_V2/Success.gif`} alt="animation_contracts_success.gif" />
              </AnimationWrap>
              <h1>
                Congratulations!
              </h1>
            </Header>
            <ContentContainer>
              <Content>
                Please provide details of the contract.
              </Content>
            </ContentContainer>
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                padding="16px"
                margin="0 5px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  toggleComfirmToGoToContract(false)
                  saveListingSteps({
                    payload: {
                      listing_id: dealToView,
                      step: "pending",
                      notes: "",
                    },
                    callback: (dealId) => {
                      if (isListing) {
                        getContractById({
                          dealId: dealId.deal_id,
                          transactionType: "Contract",
                        })
                        setDealToView(dealId.deal_id)
                        history.push(`/realty/listings/in-contracts/basic-info?dealId=${dealId.deal_id}&status=listing_pending`)
                      }
                    },
                  })
                }}
              >
                Add Contract Details
              </LoaderButton>
              <LoaderButton
                bsStyle="marineBLueOutlined"
                padding="16px"
                margin="0 5px"
                width="50%"
                borderRadius="50px"
                loaderOption={{
                  color: "white",
                }}
                onClick={() => {
                  toggleComfirmToGoToContract(false)
                  setEditingDealTeamMemberId(null)
                }}
              >
                No, thanks
              </LoaderButton>
            </ButtonWrap>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(ConfirmToGoToContracts))
