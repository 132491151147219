import React from "react"
import {
  FilterButton,
} from "./styles"

const Filter = ({
  onClick,
}) => (
  <FilterButton
    onClick={onClick}
  >
    <img src={`${CLOUDFRONT}/LISTING_V2/FilterLines.svg`} alt="filter_icon"/>
    <p>Filters</p>
  </FilterButton>
)

export default Filter
