import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./action"

const initState = {
  ...createDeltaReducer("getAppSettingsResponse"),
}

export default function goToAppReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getAppSettingsAction.REQUEST:
    case Actions.getAppSettingsAction.SUCCESS:
    case Actions.getAppSettingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAppSettingsAction, "getAppSettingsResponse"),
      }
    }

    default:
      return state
  }
}
