import React, { useRef } from "react"
import styled from "styled-components"
import RoomsContainer from "container/Rooms"
import CourseList from "./CourseOnlyList"
import RightArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/RightArrow.svg"
import LeftArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/LeftArrow.svg"
import { HIGHLIGHT_FONT } from "@fonts"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import { preTabletWidth, minDesktopWidth } from "@consts"

const Wrap = styled.div`
  margin-bottom: 30px;
`

const MainWrap = styled.div`
  padding-top: 30px;
  @media(max-width: 767px) {
    margin-top: 60px;
  }
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Header = styled.h1`
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: ${colors.black};
  margin: 0;
  margin-bottom: 15px;
  text-transform: capitalize;

  @media(min-width: 768px) {
    font-size: 24px;
  }

  @media(min-width: 1099px) {
    font-size: 30px;
  }

  @media(max-width: 767px) {
    font-size: 20px;
    text-align: left;
  }

  ${minDesktopWidth && `
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: center;
  `}
`

const AnchorWrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-start;
`

const RecommendedRooms = ({
  getRecommededRoomsResponse,
}) => {
  const { data: rooms, isFetching: fetchingRecommendedRooms } = getRecommededRoomsResponse || {}

  const scroll = (forwards, topic) => {
    const target = document.getElementById(`${topic}_scroll_target_id`)
    target.scrollTo({
      top: 0,
      left: forwards ? target.scrollLeft + 425 : target.scrollLeft - 425,
      behavior: "smooth",
    })
  }

  return (
    <MainWrap className="container">
      {fetchingRecommendedRooms ? (
        <>
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
        </>
      ) : rooms && rooms.length > 0 && (
        <>
          <AnchorWrap>
            <HeaderWrap>
              <Header>
                Recommended for You
              </Header>
              <ButtonWrap>
                <button
                  type="button"
                  style={{ background: "none", border: "none" }}
                  onClick={() => scroll(false, "course_radius")}
                >
                  <img src={LeftArrow} alt="LEFT ARROW" />
                </button>
                <button
                  type="button"
                  style={{ background: "none", border: "none" }}
                  onClick={() => scroll(true, "course_radius")}
                >
                  <img src={RightArrow} alt="RIGHT ARROW" />
                </button>
              </ButtonWrap>
            </HeaderWrap>
            <Wrap>
              <CourseList id="course_radius" sortedEvents={rooms} />
            </Wrap>
          </AnchorWrap>
        </>
      )}
    </MainWrap>
  )
}

export default RoomsContainer(RecommendedRooms)
