import React, { useState } from "react";
import {
  Header,
  SubHeading,
  Flex,
  Block,
  Info,
  InfoData,
  ScoreCard,
  SchoolName,
  ScoreWrap,
  ScoreNumber,
  ScoreOutOf,
  Grades,
  ListBullet,
  ButtonShow,
  SchoolLevel,
  SchoolCodes,
  LevelTitle,
  SchoolRating,
  SmallCircle,
  SchoolInfoWrap,
} from "./styles.js";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";

const NearBySchools = ({ listingPageResponse }) => {
  const [showAllEntries, setShowAllEntries] = useState(false);

  const initialEntryCount = window.innerWidth <= 768 ? 3 : 6;

  const toggleShowAllEntries = () => {
    setShowAllEntries(!showAllEntries);
  };

  const renderEntries = () => {
    const entryCount = showAllEntries
      ? listingPageResponse?.data?.schoolsData?.data?.length
      : initialEntryCount;
    return listingPageResponse?.data?.schoolsData?.data
      .slice(0, entryCount)
      .map((school) => (
        <Flex key={school.name}>
          <ScoreWrap>
            <Block>
              <Grades> Distance: {school?.distance?.toFixed(1)} mi</Grades>
              <SchoolName
                onClick={() => window.open(school["overview-url"], "_self")}
              >
                {school.name}
              </SchoolName>
              <Flex>
                <SchoolCodes>
                  Level: <span>{school["level-codes"]}</span>
                </SchoolCodes>
                {school.rating_band && (
                  <>
                    <SmallCircle></SmallCircle>
                    <SchoolRating rating={school.rating_band}>
                      {school.rating_band}
                    </SchoolRating>
                  </>
                )}
              </Flex>
            </Block>
          </ScoreWrap>
        </Flex>
      ));
  };
  return (
    <>
      {listingPageResponse?.data?.schoolsData?.data?.length > 0 && (
        <>
          <Header>Nearby Schools</Header>
          <Info>Schools provided by the listing agent.</Info>
          <Flex>
            {listingPageResponse?.data?.schoolsData?.district && (
              <>
                <Info>District: </Info>
                <InfoData>
                  {listingPageResponse?.data?.schoolsData?.district}
                </InfoData>
              </>
            )}
          </Flex>
          <Info>
            This data may not be complete. We recommend contacting the local
            school district to confirm school assignments for this home
          </Info>
          <SubHeading>GreatSchools Rating</SubHeading>

          <SchoolInfoWrap>{renderEntries()}</SchoolInfoWrap>

          {listingPageResponse?.data?.schoolsData?.data?.length >
            initialEntryCount && (
            <ButtonShow onClick={toggleShowAllEntries}>
              {showAllEntries ? (
                <>
                  Show Less{" "}
                  <UpOutlined style={{ marginTop: "1px", marginLeft: "5px" }} />
                </>
              ) : (
                <>
                  Show More{" "}
                  <DownOutlined
                    style={{ marginTop: "1px", marginLeft: "5px" }}
                  />
                </>
              )}
            </ButtonShow>
          )}
          <div style={{ marginBottom: "48px" }}></div>
        </>
      )}
    </>
  );
};

export default ListingPageContainer(NearBySchools);
