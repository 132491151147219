import React from "react"
import styled from "styled-components"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import colors from "@colors"
import roomsPhoneMob from "images/rooms/roomsPhone.png"
import {
  APP_STORE_URL,
  PLAY_STORE_URL,
  preTabletWidth,
} from "shared/consts"
import Footer from "../common/Footer"

const ParentWrapMob = styled.div``

const Wrap = styled.div``

const HeaderWrapMob = styled.div`
  h1 {
    height: 104px;
    font-size: 44px;
    text-align: center;
    padding: 0px 70px;
    font-family: ${HIGHLIGHT_FONT};
    color: ${colors.black};

    @media(max-width: 320px) {
      padding: 0px 40px;
    }
  }

  p {
    height: 24px;
    font-size: 16px;
    text-align: center;
    padding: 0px 70px;
    font-family: ${ATC};
    font-weight: 200;
    color: ${colors.black};
  }
`

const SocialWrapper = styled.div`
  text-align: center;
  flex: 3 auto;
  position: relative;
  margin: 20px;
`

const PhoneImageWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 55px;
  
  @media(max-width: 320px) {
    padding-left: 40px;
  }

  img {
    @media(max-width: 320px) {
      width: 100%;
    }
  }
`

const ThankYouWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  height: fit-content;
  padding-left: 20px;

  ${preTabletWidth`
    padding: 50px 0px;
  `}
`

const ThankYou = styled.p`
  font-family: ${ATC};
  font-size: 70px;
  font-weight: 300;
  line-height: initial;
  letter-spacing: 0em;
  text-align: left;

  ${preTabletWidth`
    font-size: 44px;
    text-align: center;
  `}
`

const SubText = styled.p`
  font-family: ${ATC};
  font-size: 27px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  ${preTabletWidth`
    text-align: center;
    font-size: 25px;
  `}
`

const GetAppMobile = () => (
  <Wrap>
    <ThankYouWrap>
      <ThankYou>
        Thank You For Registering!
      </ThankYou>
      <SubText>
        We are looking forward to hosting you in our room.
      </SubText>
    </ThankYouWrap>
    <ParentWrapMob>
      <HeaderWrapMob>
        <h1>
          Get the Radius App
        </h1>
        <p>
          Join this Room
        </p>
      </HeaderWrapMob>
      <SocialWrapper>
        <a
          href={APP_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://d2fedz0by71ckz.cloudfront.net/images/ios_app_store_btn.png"
            alt="IOS app"
            height={50}
            width={150}
          />
        </a>
        <a
          href={PLAY_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://d2fedz0by71ckz.cloudfront.net/images/google_play_store_btn.png"
            alt="Android app"
            height={50}
            width={150}
          />
        </a>
      </SocialWrapper>
      <PhoneImageWrap>
        <img
          src={roomsPhoneMob}
          alt="Rooms Phone"
        />
      </PhoneImageWrap>
      <Footer />
    </ParentWrapMob>
  </Wrap>
)

export default GetAppMobile
