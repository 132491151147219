import React from "react"
import styled from "styled-components"
import { isObject } from "lodash"
import Textbox from "@ui/Textbox"
import Select from "react-select"
import {
  Footer,
  Header,
  SaveButton,
  BlankButton,
  H5,
  ProfileCompletionButton,
} from "./commonStyles"

const createYearList = () => {
  const currentYear = new Date().getFullYear()
  const yearList = []
  const size = 50
  for (let i = currentYear - size; currentYear >= i; i += 1) {
    yearList.push({ value: i, label: i })
  }
  // For Leater
  // yearList.push({ value: "Present", label: "Present" })
  return yearList.reverse()
}

const Wrapper = styled.form`
  border: 1px solid  #e7ebed;
  border-radius: 4px;
`

const InputFormWrap = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`

const SingleForm = styled.div`
  padding: 10px;
  position: relative;
  margin-top: 30px;

  &:first-child{
    margin-top: -25px;
  }
`

const FieldRow = styled.div`
  margin-top: 50px;
  position: relative;

  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
      padding: 10px;
    }
  }
`

const RemoveButton = styled.button`
  position: absolute;
  color: #CC2A2F;
  border: none;
  background: transparent;
  bottom: -20px;
  right: 10px;
  cursor: pointer;
`

class RenderInputForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: [],
    }
  }

  componentDidMount() {
    const { initialValues } = this.props
    let FormatteValue
    if (!initialValues || initialValues.length === 0) {
      FormatteValue = [{ title: "", from_year: null, to_year: null }]
      this.setState({
        fields: [...FormatteValue],
      })
    } else {
      FormatteValue = initialValues && initialValues.length > 0 && initialValues.map(item => ({
        ...item,
        from_year: isObject(item.from_year) ? item.from_year : { value: item.from_year, label: item.from_year },
        to_year: isObject(item.to_year) ? item.to_year : { value: item.to_year, label: item.to_year },
      }))
    }
    this.setState({
      fields: [...FormatteValue],
    })
  }

  addItem = () => {
    const { fields } = this.state
    fields.push({ title: "", from_year: {}, to_year: {} })
    this.setState({
      fields: [...fields],
    })
  }

  handleInput = (name, value, index) => {
    const { checkForChange } = this.props
    const { fields } = this.state
    fields[index][name] = value
    this.setState({
      fields: [...fields],
    })
    checkForChange(true)
  }

  removeItem = (index) => {
    const { fields } = this.state
    const { checkForChange } = this.props
    delete fields[index]
    const newFields = fields.filter(x => x)
    this.setState({
      fields: [...newFields],
    })
    checkForChange(true)
  }

  render() {
    const { fields } = this.state
    const yearList = createYearList()
    return (
      <InputFormWrap>
        {fields && fields.length > 0 && fields.map((item, index) => (
          <SingleForm>
            <FieldRow>
              <H5 isLite label>Title*</H5>
              <Textbox
                type="text"
                value={item.title}
                onChange={(e) => {
                  this.handleInput(e.target.name, e.target.value, index)
                }}
                name="title"
              />
              {/* <Field
                name={`${item}.title`}
                label="Title*"
                component={}
                optional
                optionalText="Ex. Manager"
              /> */}
            </FieldRow>
            {/* <FieldRow>
              <Field
                name={`${item}.company`}
                label="Company*"
                component={Input}
                optional
                optionalText="Ex. Remax"
              />
            </FieldRow> */}
            <FieldRow style={{ marginTop: 35 }}>
              <H5 isLite label>From</H5>
              <Select
                value={typeof item.from_year !== "object" ? { label: item.from_year, value: item.from_year } : item.from_year}
                onChange={(val) => {
                  this.handleInput("from_year", val, index)
                }}
                options={yearList}
              />
            </FieldRow>
            <FieldRow style={{ marginTop: 20 }}>
              <H5 isLite label>To</H5>
              <Select
                value={typeof item.to_year !== "object" ? { label: item.to_year, value: item.to_year } : item.to_year}
                onChange={(val) => {
                  this.handleInput("to_year", val, index)
                }}
                options={yearList}
              />
            </FieldRow>
            <RemoveButton type="button" onClick={() => { this.removeItem(index) }}>Remove</RemoveButton>
          </SingleForm>
        ))}
        <BlankButton
          onClick={() => { this.addItem() }}
          type="button"
          style={{ marginTop: 20 }}
        >
          Add More....
        </BlankButton>
      </InputFormWrap>
    )
  }
}

class Experience extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChanged: false,
    }
    this.formRef = React.createRef()
  }

  handleSubmitFunc = () => {
    const { onSubmit } = this.props
    onSubmit({ experiences: this.formRef.current.state.fields }, null, { type: "experiences" })
  }

  checkForChange = (val) => {
    this.setState({
      isChanged: true,
    })
  }

    render() {
      const {
        isSavingUpdates,
        initialValues = {},
        isProfileCompletion,
        toClose,
        nextToNextStep,
      } = this.props
      const { experiences } = initialValues
      const { isChanged } = this.state
      return (
        <Wrapper>
          <Header>
            <h4>Add your experience </h4>
          </Header>
          <RenderInputForm
            initialValues={experiences}
            ref={this.formRef}
            checkForChange={this.checkForChange}
          />
          <Footer
            d="flex"
            style={{
              justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
              padding: 15,
            }}
          >
            {isProfileCompletion && nextToNextStep && (
              <BlankButton onClick={toClose} normal>
                Skip
            </BlankButton>
          )}
          {isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSubmitFunc}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              width="100px"
              type="button"
              isShowLoader={isSavingUpdates}
              onClick={(this.handleSubmitFunc)}
              disabled={!isChanged}
            >
                Save
            </SaveButton>
          )}
        </Footer>
      </Wrapper>
    )
  }
}

export default Experience

