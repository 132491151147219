import React, { PureComponent } from "react"
import styled from "styled-components"
import { toast as createToast } from "react-toastify"
import { ATC } from "@fonts"
import moment from "moment"
import cookie from "services/CookieStorage"
import { checkForUserObject, getProfileUrl } from "services/Utils"
import { withRouter } from "react-router-dom"
import CircularImage from "dumbComponents/common/CircularImage"
import TechAssetsContainer from "container/TechAssets"
import colors from "@colors"
import {
  Button, Select, Divider, Modal,
} from "@ui/antd"

import { ExclamationCircleOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons"
import ListingsPropertyFallbackImage from "dumbComponents/Listings/images/ListingsPropertyFallbackImage.jpg"
import { isEmpty } from "lodash"

const user = checkForUserObject("user")
const toastConfig = {
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "video-room-notifications",
}

const InfoContainer = styled.div`
  width: 100%;
  padding: 0 20px;
`

const PropertyInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5px 0 70px;
  border-top: 0.5px solid #C7C7C7;
  padding: 10px 0px;
  margin-top: 10px;
  p {
    font-family: ${ATC};
    margin-right: 28px;
    margin-bottom: 0px;
  }
`

const ListSubtextHeader = styled.div`
  display: flex;
`

const Container = styled.div``

const Wrap = styled.div`
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 2px rgb(204 204 204 / 25%);
  border-radius: 4px;
  justify-content: space-between;
  // max-width: 1200px;
  height: 200px;
  align-items: center;
  margin: 25px 0px;
  cursor: pointer;
`

const Image = styled.img`
  width: 300px;
  height: 200px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  margin-top: 8px;
  ${props => props.noMargin && `
    margin-top: 0px;
  `}
`

const ListHeader = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: #303030;
  margin: 0;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
`

const ListSubtext = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: #8C8C8C;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  p {
    
  }
`

const StatusButton = styled.div`
  width: 12.23px;
  height: 12.23px;
  background: #B3E4C7;
  ${props => props.bg && `
    background: ${props.bg}
  `}
  border-radius: 50%;
`

const StatusContainer = styled.div`
  padding: 20px;
  p {
    margin-top: 7px;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #8C8C8C;
  }
`

const ListContainer = styled.div`
  margin-left: 70px;
`

const FirstBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  background: #F9F9F7;
  padding-left: 10px;
  .ant-select-selector {
    border-color: transparent !important;
  }
`

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: column;
  h4 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #849DF8;
    margin: 10px 0 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
`

const ImageWrap = styled.div`
  display: flex;
`
const ImageOverLay = styled.div`
  width: 300px;
  position: absolute;
  height: 200px;
  opacity: 0;
  border-radius: 4px;
  &:hover {
    opacity: 1;
    background: rgba(0,0,0,0.7);
    transition: all 0.4s ease-in-out 0s;
  }
`

const ImageContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 27%; */
  height: 100%;
`

const IconContainer = styled.div`

`

const IconContent = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 18px;
  color: white;
  margin: 0;
  text-align: center;
`

const ColorMap = [
  {
    value: "#B3E4C7",
    label: "On Market",
  },
  {
    value: "#D2D8D8",
    label: "Incomplete",
  },
  {
    value: "#FAEBA6",
    label: "Coming Soon",
  },
  {
    value: "#B3E4C7",
    label: "Pending",
  },
  {
    value: "#EBBAB5",
    label: "Expired",
  },
  {
    value: "#D2D8D8",
    label: "Withdrawn",
  },
]

class ListingList extends PureComponent {
  setDealToView = (dealId, listingAgentId) => {
    const {
      listingData,
      setDealToView,
      setLoaderOnAgentInfo,
      history,
      getListingSteps,
      setEditingDealTeamMemberId,
      setAgentId,
      setCurrentContractID,
    } = this.props
    const user = cookie.load("user") || {}
    const agentId = user.agent_id || user.id
    setCurrentContractID(null)
    setEditingDealTeamMemberId(parseInt(listingAgentId, 10))
    // if (agentId === listingAgentId) {
    setDealToView(dealId)
    setAgentId(listingAgentId)
    setLoaderOnAgentInfo(true)
    getListingSteps({
      payload: {
        listingId: dealId,
      },
      history,
      listingStatus: listingData.displayDealStatus,
      callback: () => {
        history.push("/realty/listings/coversheet/listing-agent-info")
      },
    })
    // history.push("/realty/listings/coversheet/listing-agent-info")
    // }
  }

  confirmStatusToPendng = () => {
    const {
      listingData, getListingStatusMapResponse, updateDealStatus, getAllListings,
      history, setDealToView, setLoaderOnAgentInfo,
    } = this.props
    Modal.confirm({
      title: "Are you sure!",
      icon: <ExclamationCircleOutlined />,
      content: "Changing status to pending will take you to Contract Page. You will be asked to fill few more details on Contract Page",
      okText: "Sure",
      cancelText: "Not Now",
      onOk() {
        setDealToView(listingData.id)
        setLoaderOnAgentInfo(true)
        history.push(`/realty/transaction-coordination/contract/basic-info?deal=${listingData.id}`)
      },
    })
  };

  handleChange = (value) => {
    const {
      listingData, getListingStatusMapResponse, updateDealStatus, getAllListings,
      history, setDealToView, setLoaderOnAgentInfo, toggleComfirmToGoToContract,
      toggleConfirmToExecute, toggleViewListingHistory, setEditingDealTeamMemberId, saveListingSteps,
    } = this.props
    const user = cookie.load("user") || {}
    const step = getListingStatusMapResponse.data?.find(dt => dt.display_name === value)?.value
    setDealToView(listingData.id)
    setLoaderOnAgentInfo(true)
    if (value === "Pending") {
      toggleComfirmToGoToContract(true)
      setEditingDealTeamMemberId(parseInt(listingData.agent_id, 10))
      // this.confirmStatusToPendng()
      return
    }
    if (["Expired", "Withdrawn"].includes(value)) {
      toggleConfirmToExecute(step)
      return
    }
    saveListingSteps({
      payload: {
        listing_id: listingData.id,
        step,
        notes: "",
      },
      callback: () => {
        getAllListings({
          skip: 0,
          limit: 200,
          agentId: user.agent_id || user.id,
          forListing: true,
          include: "in-contract-listings",
          transactionType: "Listing",
        })
      },
    })

    // updateDealStatus({
    //   dealId: listingData.id,
    //   step,
    //   callback: (() => {
    //     getAllListings({
    //       skip: 0,
    //       limit: 200,
    //       agentId: user.agent_id || user.id,
    //       forListing: true,
    //       include: "in-contract-listings",
    //     })
    //   }),
    // })
    // const user = cookie.load("user") || {}
    // getAllListings({
    //   skip: 0,
    //   limit: 200,
    //   agentId: user.id,
    //   forListing: true,
    // })
  }

  getColor = status => ColorMap.find(cl => cl.label === status)?.value
  
  render() {
    const { listingData, getListingStatusMapResponse, toggleViewListingHistory, toggleUploadListingImages, togglePreviewListingImages } = this.props
    return (
      <Wrap>
        <FirstBox>
          <ImageOverLay>
            <ImageContent className="image_content">
              {listingData.listing_images?.length && (
                <IconContainer>
                  <EyeOutlined style={{ fontSize: '32px', color: 'white', margin: "0 25px" }} onClick={() => { togglePreviewListingImages(listingData.id) }} />
                  <IconContent>View</IconContent>
                </IconContainer>
              )}
              <IconContainer>
                <UploadOutlined style={{ fontSize: '32px', color: 'white', margin: "0 25px" }} onClick={() => { toggleUploadListingImages(listingData.id) }} />
                <IconContent>Upload</IconContent>
              </IconContainer>


            </ImageContent>
          </ImageOverLay>
          {listingData.listing_images === null || listingData.listing_images === undefined  ? (
            <Image src={ListingsPropertyFallbackImage} onClick={() => { this.setDealToView(listingData.id, listingData.agent_id) }} />
          ) : (
            <Image
              noMargin
              src={listingData.listing_images && listingData.listing_images.length > 0 && listingData.listing_images[0]}
              onClick={() => { this.setDealToView(listingData.id, listingData.agent_id) }}
            />
          )}
          <InfoContainer>
            <PropertyInfoContainer>
              <ListContainer onClick={() => { this.setDealToView(listingData.id, listingData.agent_id) }}>
                <ListHeader>
                  {listingData.listing_address ? (
                    <>
                      {listingData.listing_address}
                    </>
                  ) : (
                    <>
                      Incomplete Property Information
                    </>
                  )}
                </ListHeader>
                <ListHeader mt="3px">
                  {listingData.city && (
                    <>
                      {`${listingData.city}, ${listingData.state} ${listingData.zipcode}`}
                    </>
                  )}
                </ListHeader>
                <ListSubtext mt="8px">
                  <span>
                    Seller(s):&nbsp;
                  </span>
                  {listingData.clients && listingData.clients.length ? listingData.clients.map((col, index) => (
                    <span>
                      {`${index === 0 ? "" : ", "}${col.name || ""}`}
                    </span>
                  )) : (
                    <span>
                      N/A
                    </span>
                  )}
                </ListSubtext>
              </ListContainer>
              <StatusWrap>
                <SelectWrap>
                  <StatusButton bg={this.getColor(listingData.displayDealStatus)} />
                  <Select
                    value={listingData.displayDealStatus}
                    onChange={this.handleChange}
                    style={{
                      width: 155,
                      backgroundColor: "#F9F9F7",
                    }}
                    bordered={false}
                    // disabled={listingData.displayDealStatus === "Incomplete" || user.id !== listingData.agent_id}
                    disabled={listingData.displayDealStatus === "Incomplete"}
                    options={[
                      {
                        value: "On Market",
                        label: "On Market",
                      },
                      // {
                      //   value: "Incomplete",
                      //   label: "Incomplete",
                      // },
                      {
                        value: "Coming Soon",
                        label: "Coming Soon",
                      },
                      {
                        value: "Pending",
                        label: "Pending",
                      },
                      {
                        value: "Expired",
                        label: "Expired",
                      },
                      {
                        value: "Withdrawn",
                        label: "Withdrawn",
                      },
                    ]}
                  />
                </SelectWrap>
                <div onClick={() => toggleViewListingHistory(listingData.id)}>
                  <h4>View Listing History</h4>
                </div>
              </StatusWrap>
            </PropertyInfoContainer>
            <DateContainer onClick={() => { this.setDealToView(listingData.id, listingData.agent_id) }}>
              <ListSubtextHeader>
                <ListSubtext>
                  <p>Listing Start</p>
                  <p>{moment(listingData.deal_created_at).format("MM-DD-YYYY")}</p>
                </ListSubtext>
                <ListSubtext>
                  <p>On Market</p>
                  {listingData.listing_on_market_date ? (
                    <p>{moment(listingData.listing_on_market_date * 1000).format("MM-DD-YYYY")}</p>
                  ) : (
                    <p>N/A</p>
                  )}
                </ListSubtext>
                <ListSubtext>
                  <p>Listing End</p>
                  {listingData.listing_expiration_date ? (
                    <p>{moment(listingData.listing_expiration_date * 1000).format("MM-DD-YYYY")}</p>
                  ) : (
                    <p>N/A</p>
                  )}
                </ListSubtext>
              </ListSubtextHeader>
              <ImageContainer>
                {listingData.agent_id === user.id && (<CircularImage style={{ marginRight: "5px" }} size={40} charSize="18px" image={user.agent_image} name={`${user.firstname || ""} ${user.lastname || ""}`} />)}
                {listingData.agent_id !== user.id && (<CircularImage style={{ marginRight: "5px" }} size={40} charSize="18px" image={listingData.agent_image} name={`${listingData.agent_firstname || ""} ${listingData.agent_lastname || ""}`} />)}
                {listingData.co_listing_agents && listingData.co_listing_agents.length && listingData.co_listing_agents.map((col) => {
                  if (col.agent_id !== listingData.agent_id) {
                    return (
                      <CircularImage style={{ marginRight: "5px" }} size={40} charSize="18px" image={col.profile_url} name={`${col.firstname || ""} ${col.lastname || ""}`} />
                    )
                  }
                })}
              </ImageContainer>
            </DateContainer>
          </InfoContainer>
        </FirstBox>
        {/* <StatusContainer>
          <p>
            Last updated
            {" "}
            {moment(listingData.row_updated_at).format("YYYY-MM-DD")}
          </p>
        </StatusContainer> */}
      </Wrap>
    )
  }
}

export default withRouter(TechAssetsContainer(ListingList))
