import {
  takeLatest,
  call,
  put,
  race,
  take,
} from "redux-saga/effects"
import {
  createSingalSagaHandler
} from "shared/reduxUtils"
import { forIn } from "lodash"
import cookie from "services/CookieStorage"
import {
  getFirstCharacterCapital,
  isSuccess,
  saveUserObjectToCookie,
  UrlSearchParams,
  unixToDate,
} from "services/Utils"
import { isArray } from "util"
import createToast from "@ui/Toast"
import * as PropertyStatusAPIs from "./api"
import * as PropertyStatusEmailer from "./actions"

function* handlePropertyListing(action) {
  try {
    const {
      id,
      bundleId,
    } = action.data
    const res = yield call(PropertyStatusAPIs.getPropertyListAPI, id, bundleId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(PropertyStatusEmailer.getPropertyStatusListAction.success(response))
    }
  } catch (error) {
    yield put(PropertyStatusEmailer.getPropertyStatusListAction.failure(error))
  }
}

function* handlePropertyDeletion(action) {
  try {
    const {
      basicId,
      id,
      hashId,
      bundleId,
    } = action.data
    const { confirm } = yield race({
      confirm: take(confirmAction),
    })
    const { data: isConfimed } = confirm
    if (isConfimed) {
      const res = yield call(PropertyStatusAPIs.deletePropertyAPI, id, basicId)
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(PropertyStatusEmailer.deletePropertyAction.success(response))
        yield put(PropertyStatusEmailer.handleUserConfirmationAction.call(false))
        yield put(PropertyStatusEmailer.getPropertyStatusListAction.request({
          id: hashId,
          bundleId,
        }))
      }
    }
  } catch (e) {
    yield put(PropertyStatusEmailer.handleUserConfirmationAction.call(false))
    yield put(PropertyStatusEmailer.deletePropertyAction.failure(e))
  }
}

function* handlePropertyUpdate(action) {
  try {
    const {
      id,
      bundleId,
      payload,
      val,
    } = action.data
    const currentParams = decodeURIComponent(cookie.load("property_status"))
    const currentObj = currentParams && JSON.parse(
      currentParams.substring(
        currentParams.indexOf("{"), currentParams.lastIndexOf("}") + 1
      )
    )
    const {
      hash,
    } = currentObj || {}
    const res = yield call(PropertyStatusAPIs.updatePropertyListAPI, id, bundleId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(PropertyStatusEmailer.updatePropertyStatusAction.success(response))
      yield put(PropertyStatusEmailer.handleUpdateSelectAction.call({
        currentPropertyStatus: val,
      }))
      yield put(PropertyStatusEmailer.getPropertyStatusListAction.request({
        id: hash,
        bundleId,
      }))
      createToast("Property Status Updated")
    }
  } catch (error) {
    console.log(error)
    yield put(PropertyStatusEmailer.updatePropertyStatusAction.failure(error))
  }
}

function* handlePropertyUpdateFromProfile(action) {
  try {
    const {
      basicId,
      payload,
      val,
    } = action.data
    const res = yield call(PropertyStatusAPIs.updatePropertyListFromProfileAPI, basicId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(PropertyStatusEmailer.updatePropertyStatusFromProfileAction.success(response))
      createToast("Property Status Updated")
    }
  } catch (error) {
    console.log(error)
    yield put(PropertyStatusEmailer.updatePropertyStatusFromProfileAction.failure(error))
  }
}

export function* confirmAction(action) {
  if (action.data) {
    return action.data
  }
  return false
}

export default function* main() {
  yield takeLatest(PropertyStatusEmailer.getPropertyStatusListAction.REQUEST, handlePropertyListing)
  yield takeLatest(PropertyStatusEmailer.updatePropertyStatusAction.REQUEST, handlePropertyUpdate)
  yield takeLatest(PropertyStatusEmailer.updatePropertyStatusFromProfileAction.REQUEST, handlePropertyUpdateFromProfile)
  yield takeLatest(PropertyStatusEmailer.toggleModalAction.type, handlePropertyDeletion)
}
