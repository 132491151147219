import React from "react"
import styled from "styled-components"
import colors from "@colors"
import GoogleAutoComplete from "@ui/GoogleAutoComplete"

const Wrapper = styled.div`
`

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
`
const Label = styled.label`
  color: ${colors.starInactive};
  pointer-events: none;
  transition-duration: 0.2s;
  font-weight: 100;
  margin-bottom: 10px;
  display: block;
`

const RenderLocationAutoComplete = (props) => {
  const {
    id,
    types,
    location,
    placeholder,
    loader,
    input: {
      onBlur,
      onFocus,
      value,
      onChange,
    } = {},
    meta: {
      error,
      touched,
    } = {},
    labelStyles = {},
    label,
    restrict,
    customInputStyle,
    disabled,
  } = props
  return (
    <Wrapper onBlur={onBlur} onChange={onChange} onFocus={onFocus} style={{ ...customInputStyle }}>
      {label && (
        <Label>
          {label}
        </Label>
      )}
      <GoogleAutoComplete
        id={id}
        types={types}
        location={location}
        placeholder={placeholder}
        loader={loader}
        value={typeof value === "string" ? value : null}
        labelStyles={labelStyles}
        restrict={restrict}
        disabled={disabled}
      />
      { touched && error && <ErrorText>{error}</ErrorText> }
    </Wrapper>
  )
}

export default RenderLocationAutoComplete
