import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { sortBy } from "lodash"
import colors from "@colors"
import { getDuration } from "container/Referral/saga"
import PlanDetails from "./PlanDetails"
import PlanBenefitsList from "./PlanBenefitsList"

// STYLES - {BLOCK START}//
const PlanTypeBanner = styled.div`
   height: 78px;
   width: 72.2%;
   background-color: ${colors.contentText};
   position: absolute;
   left: 27.6%;
`

const MobileOuterWrap = styled.div`
  display: none;
  @media (max-width: 500px) {
   display: block;
   background: ${colors.white};
   overflow-y: hidden;
   overflow-x: scroll;
   padding-bottom: 73px;
   position: relative;
   bottom: 120px;
 }
`

const BenefitsColumnWrap = styled.div`
   height: 100%;
   min-width: 27.7%;
   display: flex;
   flex-grow: 1;
   border-right: 1px solid ${colors.shallowGrey};
   flex-direction: column;
`
const ComparePlansWrap = styled.div`
   height: 25%;
   padding: 45px;
`

const ComparePlansText = styled.p`
   font-size: 36px;
   font-weight: 500;
   line-height: 1.28;
   font-family: 'DM Sans', sans-serif;
   text-align: left;
   position: relative;
   top: 36px;
   color: ${colors.contentText};
`

const MobileListWrap = styled.ul`
  display: flex;
  position: relative;
  top: 39px;
`

const OverlayWrap = styled.div`
   height: 45px;
   width: 100%;
   background-color: ${colors.shallowGrey};
   position: absolute;
   top: ${props => props.top ? props.top : "21.8%"};
   padding: 7px 0 10px 0;
`

const OverlayText = styled.p`
   font-family: 'DM Sans', sans-serif;
   text-align: left;
   margin-left: 44px;
   font-size: 22px;
   line-height: 1.32;
   color: ${colors.contentText};
`

const ISADetailedPlanCardWrap = styled.div`
  background: ${colors.priceCardGrey};
  display: flex;
  width: 1116px;
  height: 897px;
  position: relative;
  top: 56px;
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 500px) {
   display: none !important;
  }
`
// STYLES - {BLOCK END}//

const createOptions = (fullPlanList = [], type) =>  {
  return fullPlanList
  && fullPlanList
  && fullPlanList.length > 0
  && fullPlanList.filter(
    item => item.plan_tier === type
      && (item.billing_interval > 1 || item.billing_cycle === "YEARLY")
      && (item.is_legacy_plan === 0)
  ).map(item => ({
    value: item.plan_id,
    label: getDuration(
      item.billing_interval,
      item.stripe_plan_id,
    ),
  }))
}

class ISADetailedPlanCard extends React.Component {
state = {}

hookRef = React.createRef()
scrollRef = React.createRef()

componentDidMount() {
  const { toggleHeaderBanner } = this.props
  if (this.hookRef && this.scrollRef) {
    try {
      toggleHeaderBanner()
      this.scrollRef.current.scrollLeft = 178
    } catch (e) {
      //do nothing
    }
  }
}

handleDurationChange = (item, type) => {
  this.setState({
    [`selectedDuration${type}`]: {
      ...item,
    },
  })
}

shouldBeAddedOnPricing = (
  basicSelection,
  premiumSelection,
  type,
  id,
  dataType,
  isVisible,
) => {
  if (dataType === "team") {
    return !!isVisible
  }
  if (!basicSelection && type === "BASIC") {
    return id === "rtl_yearly_3588_v2"
  }
  if (basicSelection && type === "BASIC") {
    return id === basicSelection.value
  }
  if (!premiumSelection && type === "PREMIUM") {
    return id === "rtl_yearly_4548"
  }
  if (premiumSelection && type === "PREMIUM") {
    return id === premiumSelection.value
  }
}

renderPlanDetails = (
  type,
  pricingPlans,
  addPlanToCart,
  removePlanFromCart,
  isCartHavingTeamPlan,
  isLoggedIn,
  cart,
  categorizedSubscriptions,
  isMobile,
  pricingPlansMap,
) => {
  const { data } = pricingPlans
  if (data) {
    const {
      single_plans,
      team_plans,
      benefits,
    } = data
    const renderObject = {
      Single: {
        type: "Single",
        renderData: this.createPlansRenderObject(single_plans),
        render: (() => (
          renderObject.Single.renderData.map(planDetail => (
            <PlanDetails
              planDetail={planDetail}
              type={type}
              benefits={benefits}
              addPlanToCart={addPlanToCart}
              removePlanFromCart={removePlanFromCart}
              isCartHavingTeamPlan={isCartHavingTeamPlan}
              isLoggedIn={isLoggedIn}
              cart={cart}
              isMobile={isMobile}
              categorizedSubscriptions={categorizedSubscriptions}
              pricingPlansMap={pricingPlansMap}
              durationOptions={createOptions(single_plans.rtl, planDetail.planTier)}
              handleDurationChange={this.handleDurationChange}
            />
          ))
        )),
      },
      Team: {
        type: "Team",
        renderData: this.createPlansRenderObject(team_plans, "team"),
        render: (() => (
          renderObject.Team.renderData.map(planDetail => (
            <PlanDetails
              planDetail={planDetail}
              type={type}
              benefits={benefits}
              addPlanToCart={addPlanToCart}
              removePlanFromCart={removePlanFromCart}
              isCartHavingTeamPlan={isCartHavingTeamPlan}
              isLoggedIn={isLoggedIn}
              cart={cart}
              isMobile={isMobile}
              categorizedSubscriptions={categorizedSubscriptions}
              pricingPlansMap={pricingPlansMap}
            />
          ))
        )),
      },
    }

    return renderObject[type].render()
  }
  return null
}

createPlansRenderObject = (plansObj, dataType) => {
  const planDetailObject = []
  const { rtl, blu } = plansObj
  const { selectedDurationBASIC, selectedDurationPREMIUM } = this.state
  if (blu) {
    blu.forEach((plan) => {
      if (plan.visible === 1) {
        planDetailObject.push({
          planTier: plan.plan_tier,
          unitPrice: parseInt(plan.unit_price / plan.quota, 10),
          type: "Bulk Lead Upload",
          abbr: "blu",
          quota: plan.quota,
          planType: plan.plan_type,
          benefits: plan.plan_benefits,
          nickname: plan.product_nickname,
          id: plan.plan_id,
          monthlyPrice: plan.monthly_price,
        })
      }
    })
  }

  sortBy(rtl, item => item.plan_tier).forEach((plan) => {
    if (
      this.shouldBeAddedOnPricing(
        selectedDurationBASIC,
        selectedDurationPREMIUM,
        plan.plan_tier,
        plan.plan_id,
        dataType,
        plan.visible,
      )
    ) {
      planDetailObject.push({
        planTier: plan.plan_tier,
        unitPrice: plan.monthly_price,
        type: "Real-time Upload",
        abbr: "rtl",
        quota: plan.quota,
        benefits: plan.plan_benefits,
        nickname: plan.product_nickname,
        planType: plan.plan_type,
        id: plan.plan_id,
        outstandingPrice: plan.outstanding_lead_price,
        monthlyPrice: plan.monthly_price,
        teamUnitPrice: plan.outstanding_lead_price,
      })
    }
  })
  return planDetailObject
}

render() {
  const {
    currentPlanType,
    pricingPlans,
    addPlanToCart,
    removePlanFromCart,
    isCartHavingTeamPlan,
    isLoggedIn,
    cart,
    categorizedSubscriptions,
    mobileOnly,
    pricingPlansMap,
  } = this.props
  return (
    <React.Fragment>
      <ISADetailedPlanCardWrap>
        <PlanTypeBanner>
        </PlanTypeBanner>
        <OverlayWrap>
          <OverlayText>Benefits</OverlayText>
        </OverlayWrap>
        <OverlayWrap
          top="85%"
        >
          <OverlayText>Pick your plans</OverlayText>
        </OverlayWrap>
        <BenefitsColumnWrap>
          <ComparePlansWrap>
            <ComparePlansText>
              Compare
              <br />
              Plans
            </ComparePlansText>
          </ComparePlansWrap>
          <PlanBenefitsList
            type={currentPlanType}
            pricingPlans={pricingPlans}
          />
        </BenefitsColumnWrap>
        {this.renderPlanDetails(
          currentPlanType,
          pricingPlans,
          addPlanToCart,
          removePlanFromCart,
          isCartHavingTeamPlan,
          isLoggedIn,
          cart,
          categorizedSubscriptions,
          false,
          pricingPlansMap,
        )}
      </ISADetailedPlanCardWrap>
      {mobileOnly && (
        <MobileOuterWrap
          innerRef={this.hookRef}
        >
          <MobileListWrap
            innerRef={this.scrollRef}
          >
            {this.renderPlanDetails(
              currentPlanType,
              pricingPlans,
              addPlanToCart,
              removePlanFromCart,
              isCartHavingTeamPlan,
              isLoggedIn,
              cart,
              categorizedSubscriptions,
              true,
              pricingPlansMap,
            )}
          </MobileListWrap>
        </MobileOuterWrap>
      )}
    </React.Fragment>
  )
}
}

export default withRouter(ISADetailedPlanCard)
