/* eslint-disable max-len */
import React from "react"

const ActiveMic = () => (
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.6259" cy="5.45809" r="1.54207" transform="rotate(90 8.6259 5.45809)" fill="#303030" />
    <circle cx="8.6259" cy="11.5421" r="1.54207" transform="rotate(90 8.6259 11.5421)" fill="#303030" />
    <circle cx="8.6259" cy="17.6265" r="1.54207" transform="rotate(90 8.6259 17.6265)" fill="#303030" />
  </svg>
)

export default ActiveMic
