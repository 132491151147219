import React from "react"
import styled from "styled-components"
import TimePicker from "rc-time-picker"
import { ErrorLabel } from "./common"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  ${ErrorLabel} {
    margin-top: 5px;
  }
`

const RenderTimePicker = ({ config, input, meta }) => (
  <Wrap>
    <TimePicker
      {...config}
      onChange={(date) => {
        input.onChange(date)
      }}
      value={input.value || ""}
    />
    {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
  </Wrap>
)

export default RenderTimePicker
