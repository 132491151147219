import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import moment from "moment"
import { ATC } from "@fonts"
import colors from "@colors"

const Wrap = styled.div`
  padding: 10px 15px 12px 15px;
`

const TimelineInfoWrap = styled.div`
  display: flex;
  gap: 50px;
`

const DateWrap = styled.div`
  p {
    text-align: center;
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.luxorBlue};
  }
`

const InfoWrap = styled.div`
  background: #F1F3F8;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  ${props => props.index === 0 && `
    background: #364A7D;
  `}

  ${props => props.index === 0 && `
    background: #364A7D;
  `}

  p {
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black};
    ${props => props.index === 0 && `
      color: ${colors.dreamWhite};
    `}
  }
`

const TimelineDetails = ({
  data,
  index,
}) => {
  const { text, time } = data || {}
  return (
    <Wrap>
      <TimelineInfoWrap>
        <DateWrap>
          <p>
            {moment(time).format("MMM D")}
          </p>
        </DateWrap>
        <InfoWrap
          index={index}
        >
          <p>
            {text}
          </p>
        </InfoWrap>
      </TimelineInfoWrap>
    </Wrap>
  )
}

export default CRMV2Container(TimelineDetails)
