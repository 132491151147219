import axios from "axios"

export const getLeadForMarketplaceAPI = (agentId, offset, limit) => axios
  .get(`${API_V4_URL}prospects/${agentId}`)

export const sendDownloadLinkAPI = payload => axios
  .post(`${API_V2_URL}appDownloadSms`, payload)

export const fetchRecentlyClaimedAPI = (offset, limit) => axios
  .get(`${API_URL}prospects/recently-claimed?offset=${offset}&limit=${limit}`)

export const fetchUserMarketPlacePreferenceAPI = () => axios
  .get(`${API_URL}user/referral-settings`)

export const savePreferenceAPI = payload => axios
  .put(`${API_URL}user/referral-settings`, payload)

export const claimUnverifiedLeadAPI = payload => axios
  .post(`${API_URL}events`, payload)

export const getAgentNumberForCallingAPI = toNumber => axios
  .get(`${API_URL}caller/number-info?toPhoneNumber=${toNumber}`)

export const getClaimBlockerAPI = (offset = 0, limit = 10) => axios
  .get(`${API_URL}refer/claim-blocker?offset=${offset}&limit=${limit}`)

// View API
export const lmpViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)

// Delete Lead
export const deleteLMPLeadAPI = referralId => axios
  .delete(`${API_URL}prospects/${referralId}`)

// Lead Viewed
export const leadViewedAPI = payload => axios
  .post(`${API_URL}prospects/seen`, payload)

export const postCallFeedbackAPI = () => axios
  .get(`${API_URL}call-feedback-options`)
