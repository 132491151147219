import React from "react"
import {
  TechandAssetsSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import {
  InnerTab,
  IconEle,
  NavLink,
} from "./commonStyles"

const ProductsPromoTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[1] === "products-and-services"}>
    <div className="icon-block-new">
      <IconEle src={TechandAssetsSVGIcon} alt="RARProducts" isActive={routeArray[1] === "products-and-services"} />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/products-and-services/promo"
        isActive={routeArray[1] === "products-and-services"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_productAndServicesRAR_web")
          }
        }}
      >
        Product Marketplace
      </NavLink>
    </div>
  </InnerTab>
)

export default ProductsPromoTab
