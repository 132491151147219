import React, { useState } from "react"
import ListingsV2 from "container/ListingsV2/index"
import Listings from "container/Listings"
import TechAssets from "container/TechAssets"
import OffersV2 from "container/OffersV2/index"
import createToast from "dumbComponents/ListingsV2/Components/Toast/customToast"
import { TrashIcon, EyeIcon, ShareIcon } from "dumbComponents/ListingsV2/icons"
import CheckboxList from "dumbComponents/ListingsV2/Components/CheckboxList/index"
import { SectionWrap, SectionTitle, EnvelopeTitle } from "./styles"

const Attachments = ({
  currentBrokerageType,
  completeListingPackageToBeSent,
  createReceiverViewOfferChecklist,
  createReceiverView,
  listingIdToView,
  dealToView,
  offerIdToView,
  downloadEnvelopeDoc,
  downloadEnvelopeDocOfferChecklist,
}) => {
  const { envelopes } = completeListingPackageToBeSent || {}

  const [finalEnvelopes, setFinalEnvelopes] = useState(envelopes)

  const OptionList2 = (section, item) => {
    const options = [
      {
        label: "VIEW",
        onClick: (documentId, envelopeId, name) => {
          createToast("Downloading...")
          if (currentBrokerageType == "listing") {
            downloadEnvelopeDoc({
              payload: {
                envelopeId,
                documentId,
              },
              documentDetail: {
                name,
              },
              callback: () => {
                console.log("done")
              },
            })
          } else {
            downloadEnvelopeDocOfferChecklist({
              payload: {
                envelopeId,
                documentId,
              },
              documentDetail: {
                name,
              },
              callback: () => {
                console.log("done")
              },
            })
          }
        },
      },
    ]
    let totalDocuments = 0
    finalEnvelopes.forEach(e => {
      if (e.documents.length > 0) {
        totalDocuments += e.documents.length
      }
    })
    if (
      section === "envelopes" && totalDocuments>1
    ) {
      options.push({
        label: "DELETE",
        onClick: (id, documentId) => {
          deleteDocuments(id, documentId)
        },
      })
    } else if (section !== "envelopes") {
      options.push({
        label: "DELETE",
        onClick: (id, documentId ) => {
          deleteDocuments(id, documentId)
        },
      })
    }

    return options
  }

  const deleteDocuments = ( docId, envelopeId) => {
    const updatedEnvelopes = [...finalEnvelopes]
    updatedEnvelopes
      .map((item) => {
        if (item.id === envelopeId) {
          if (item.documents) {
            item.documents = item.documents.filter(
              (doc) => doc.id !== docId
            )
          }
        }
        return item
      })
      .filter(Boolean)
    setFinalEnvelopes(updatedEnvelopes)
  }



  const MainListV2 = []

  return (
    <SectionWrap
      style={{
        marginTop: "38px",
      }}
    >
      <SectionTitle>ATTACHMENTS</SectionTitle>

      {finalEnvelopes &&
        finalEnvelopes.length > 0 &&
        finalEnvelopes.map((item, iter) => (
          <div key={item.id}>
            {item.documents && item.documents.length > 0 && (
              <EnvelopeTitle>
                <p>{`ENVELOPE ${iter + 1}`}</p>
              </EnvelopeTitle>
            )}

            {item.documents &&
              item.documents.map((document) => (
                <CheckboxList
                  list={document}
                  onClick={(e) => console.log("clicked", e.target.value)}
                  dropdownList={OptionList2("envelopes", item).map(
                    (option) => ({
                      ...option,
                      onClick: () =>
                        option.onClick(document.id, item.id, document.name),
                    })
                  )}
                  mainList={MainListV2}
                  hideCheckBox
                  envelopeId={item.id}
                  img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
                />
              ))}
          </div>
        ))}

      {/* {preQualified && preQualified.length > 0 && (
        <>
          <EnvelopeTitle>
            <p>PRE APPROVED</p>
          </EnvelopeTitle>
          <CheckboxList
            list={preQualified}
            onClick={e => console.log("clicked", e.target.value)}
            dropdownList={OptionList2}
            mainList={MainListV2}
            hideCheckBox
            img={`${CLOUDFRONT}/OFFERS_V2/Paperclip.svg`}
          />
        </>
      )} */}
    </SectionWrap>
  )
}

export default Listings(OffersV2(ListingsV2(TechAssets(Attachments))))
