/*eslint no-param-reassign: ["error", { "props": false }]*/
import React from "react"
import styled from "styled-components"
import Select from "react-select"
import checkMark from "images/isa/icon-checkmark_1.png"
import CheckIcon from "images/CheckIcon"
import colors from "@colors"

// STYLE BLOCK - START //

const PlanColumnWrap = styled.div`
   height: 100%;
   flex: 12;
   flex-shrink: 0;
   display: flex;
   flex-direction: column;
   border-right: 2px solid ${colors.shallowGrey};
`
const PlanTypeWrap = styled.div`
   width: fit-content;
   height: 20px;
   border-radius: 4px;
   background: ${props => props.isPremium ? "linear-gradient(105deg, #2bda8e -1%, #0ac4ba)" : `${colors.white}`};
   position: relative;
   left: ${props => props.isPremium ? "32%" : "40%"};
   padding: 4px;
   ${props => props.mobileOnly && `
        height: 100%;
    `}
    @media(max-width: 500px) {
      background: white;
      left: ${props => props.isPremium ? "35%" : "40%"};
    }
`
const StyledCheckIcon = styled(CheckIcon)`
  position: relative;
  top: -7px;
  margin-right: 6px;
`
const PlanName = styled.p`
   font-family: 'DM Sans', sans-serif;
   font-size: 16px;
   font-weight: 400;
   text-align: center;
   padding-top: 6px;
   color: ${colors.white};
`
const PlanType = styled.p`
   font-family: 'DM Sans', sans-serif;
   font-size: 12px;
   font-weight: 500;
   line-height: 1;
   letter-spacing: normal;
   text-align: center;
   color: ${props => props.isPremium ? `${colors.white}` : `${colors.contentText}`}
   @media(max-width: 500px) {
     color: ${colors.contentText};
   }
`
const PlanTypeAndName = styled.div`
   width: 100%;
   height: 79px;
   background-color: transparent;
   position: relative;
   padding-top: 15px;
   padding-left: 10px;
   @media(max-width : 500px) {
    background: ${colors.contentText};
    ${props => props.isPremium && `
        background: linear-gradient(112deg, #0ac4ba -2%, #2bda8e);
     `}
   }
`
const PlanAmountWrap = styled.div`
   height: 158.6px;
   width: 100%;
   padding: 8px;
   ${props => props.mobileOnly && `
    padding: 40px 41px 40px 40px;
    `}
`
const Dollar = styled.div`
   position: relative;
   font-size: 19px;
   display: inline;
   bottom: 7px;
   right: 3px;
   margin: 0;
`
const AmountText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  margin: 0px;
  color: ${colors.contentText};
`
const PlanMiniDescription = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  text-align: center;
  font-weight: lighter;
  color: ${colors.dirtBrown};
`
const PlanBenefitsStatusWrap = styled.div`
  height: 65%;
  width: 100%;
`
const StatusIcon = styled.img`
  height: 30px;
  width: 30px;
`
const MobilePlanWrap = styled.div`
  width: 241px;
  height: fit-content;
  object-fit: contain;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
`
const MobileListEle = styled.li`
   padding: 10px;
`
const StatusBlock = styled.div`
  height: 45px;
  width: 100%;
  background-color: transparent;
  padding: 29px 0 20px 0;
`
const TeamOnly = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 21px;
  left: 4px;
`
const BluOnly = styled.p`
  font-weight: 500;
`
const TeamOnlyText = styled.p`
  font-weight: ${props => props.fw ? props.fw : "100"};
  font-size: 15px;
  margin: 0;
`
const CTAWrap = styled.div`
  height: 92px;
  width: 100%;
  padding: 29px;
  display: flex;
  flex-direction: column;
`
const CTAButton = styled.button`
  min-width: 75px;
  width: fit-content;
  height: 29px;
  border-radius: 4px;
  border: solid 2px ${colors.contentText};
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  text-align: center;
  background: ${props => props.isDisabled ? `${colors.contentText}` : `${colors.priceCardGrey}`};
  color: ${props => props.isDisabled ? `${colors.white}` : `${colors.contentText}`};
  cursor: ${props => props.isDisabled ? "not-allowed" : "pointer"};
  position: relative;
  left: ${props => props.isDisabled ? "0" : "32px"};
  ${props => props.isAdded && `
     left: 32px !important;
   `}
   ${props => props.isSubscribed && `
      left: 15px !important;
    `}
`
const RemovePlanCTA = styled.button`
  border: none;
  background: transparent;
  font-size: 12px;
  color: ${colors.redText};
  position: relative;
  top: 3px;
  cursor: pointer;
 `
const BenefitsWrap = styled.div`
  height: 61%;
  width: 100%;
  background: ${colors.bgGrey};
  padding-bottom: 32px;
`
const BenefitsHeader = styled.h3`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.53px;
  text-align: center;
  color: ${colors.textGreen};
  padding: 25px;
`
const BenefitText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.56;
  letter-spacing: -0.53px;
  color: ${colors.contentText};
  position: relative;
  left: 11px;
  text-align: left;

`
const StyledList = styled.li`
  display: flex;
`
const StyledIconWrap = styled.div`
  display: inline-block;
  position: relative;
  top: 9px;
  left: 11px;
`

const SelectionWrap = styled.div`
   position: relative;
   transform: scale(0.8);
`

const getDescription = (type, abbr) => {
  const isTeam = type === "Team"
  const isRTL = abbr === "rtl"
  if (isTeam && isRTL) return ""
  if (!isTeam && isRTL) return "PER MONTH"
  return "PER LEAD"
}

const getCTAText = (cart, id, abbr, tier, type, map, current) => {
  let currentPlanLegacy = false
  let isCurrentPlanPremium = false
  if (map && map !== undefined && current) {
    if (map[current[abbr]] !== undefined) {
      currentPlanLegacy = map[current[abbr]].isLegacy === 1
      isCurrentPlanPremium = map[current[abbr]].tier === "PREMIUM"
    }
  }

  //const isTeamBLU = type === "Team" && abbr === "blu"
  const isPremium = tier === "PREMIUM"
  if (current[abbr] === id) {
    return { text: "Subscribed", isAdded: false, isSubscribed: true }
  }

  if (cart[abbr] === id) {
    return { text: "Added", isAdded: true }
  }

  if ((currentPlanLegacy || isPremium) && (Object.keys(current).length > 0 && !isCurrentPlanPremium)) {
    return { text: "Upgrade", isAdded: false }
  }

  return { text: "Add", isAdded: false }
}

const handleAction = (planDetail, actionFunction, actionType, isCartHavingTeamPlan) => {
  const {
    nickname: product,
    id: plan,
    planType: type,
    monthly_price: monthlyPrice,
  } = planDetail
  const cartHasTeamPlan = type === "team_plans" || (isCartHavingTeamPlan && product === "blu")
  const monthlyPlanIfTeam = cartHasTeamPlan ? monthlyPrice : 0
  switch (actionType) {
    case "ADD":
      actionFunction({
        product,
        plan,
        cartHasTeamPlan,
        monthlyPlanIfTeam,
      })
      break
    case "REMOVE":
      actionFunction({
        product,
      })
      break
    default:
      //do nothing
  }
}

const createMapping = (benefits, type) => {
  const {
    single_plans,
    team_plans,
  } = benefits

  const currentListMapping = type === "Single" ? single_plans : team_plans

  return currentListMapping.map(map => (
    {
      text: map.benefit_text,
      value: false,
    }
  ))
}

const renderBenefitStatus = (planBenefitStatus, type, benefits) => {
  const currentBenefitList = createMapping(benefits, type)
  currentBenefitList.forEach(item => (
    planBenefitStatus.forEach((benefit) => {
      if (item.text === benefit.benefit_text) {
        item.value = benefit.text ? benefit.text : "logo"
      }
    })
  ))
  return currentBenefitList.map(benefit => (
    <StatusBlock>
      {benefit.value === "logo" ? (
        <StatusIcon src={checkMark} alt="" />
      ) : (
        <p>{benefit.value}</p>
      )}
    </StatusBlock>
  ))
}

class PlanDetails extends React.PureComponent {
  renderSelection = () => {
    const {
      durationOptions,
      handleDurationChange,
      planDetail,
    } = this.props
    return (
      <SelectionWrap>
        <Select
          options={durationOptions}
          defaultValue={durationOptions[2]}
          onChange={(item) => { handleDurationChange(item, planDetail.planTier) }}
        />
        <h6>{planDetail.planId}</h6>
      </SelectionWrap>
    )
  }

  render() {
    const {
      planDetail,
      type,
      benefits,
      addPlanToCart,
      removePlanFromCart,
      isCartHavingTeamPlan,
      cart,
      isMobile,
      pricingPlansMap,
      categorizedSubscriptions,
    } = this.props
    const {
      isSubscribed,
      isAdded,
      text,
    } = getCTAText(
      cart,
      planDetail.id,
      planDetail.abbr,
      planDetail.planTier,
      type,
      pricingPlansMap,
      categorizedSubscriptions,
    )
    const isTeam = type === "Team"
    const isPremium = planDetail.planTier === "PREMIUM"
    return (
      <React.Fragment>
        {isMobile ? (
          <MobileListEle>
            <MobilePlanWrap>
              <PlanTypeAndName
                mobileOnly
                isPremium={isPremium}
              >
                <PlanTypeWrap
                  isPremium={isPremium}
                  isTeam={type === "Team"}
                >
                  <PlanType
                    isPremium={isPremium}
                  >
                    {planDetail.planTier}
                  </PlanType>
                </PlanTypeWrap>
                <PlanName>
                  {planDetail.type}
                </PlanName>
              </PlanTypeAndName>
              <PlanAmountWrap
                mobileOnly
              >
                <AmountText>
                  <Dollar>$</Dollar>
                  {planDetail.unitPrice}
                </AmountText>
                <PlanMiniDescription>
                  {getDescription(type, planDetail.abbr)}
                  <br />
                  <React.Fragment>
                    {(planDetail.abbr === "blu") && (
                      <BluOnly>
                        {`MINIMUM ${planDetail.quota} LEADS`}
                      </BluOnly>
                    )}
                    {(type === "Team" && planDetail.abbr === "rtl") && (
                      <TeamOnly>
                        <TeamOnlyText>PER MONTH</TeamOnlyText>
                        <TeamOnlyText
                          fw={500}
                        >
                          {`$${planDetail.outstandingPrice} / ADDITIONAL LEAD`}
                        </TeamOnlyText>
                      </TeamOnly>
                    )}
                  </React.Fragment>
                </PlanMiniDescription>
              </PlanAmountWrap>
              <BenefitsWrap>
                <BenefitsHeader>BENEFITS</BenefitsHeader>
                <ul>
                  { planDetail.benefits && (
                    planDetail.benefits.map(planText => (
                      <StyledList>
                        <StyledIconWrap>
                          <StyledCheckIcon />
                        </StyledIconWrap>
                        <BenefitText>
                          {`${planText.text ? `${planText.text} ` : ""} ${planText.benefit_text}`}
                        </BenefitText>
                      </StyledList>
                    ))
                  )}
                </ul>
              </BenefitsWrap>
            </MobilePlanWrap>
          </MobileListEle>
        ) : (
          <PlanColumnWrap
            isTeam={type === "Team"}
          >
            <PlanTypeAndName>
              <PlanTypeWrap
                isPremium={isPremium}
                isTeam={type === "Team"}
              >
                <PlanType
                  isPremium={isPremium}
                >
                  {planDetail.planTier}
                </PlanType>
              </PlanTypeWrap>
              <PlanName>
                {planDetail.type}
              </PlanName>
            </PlanTypeAndName>
            <PlanAmountWrap>
              <AmountText>
                <Dollar>$</Dollar>
                {planDetail.unitPrice}
              </AmountText>
              <PlanMiniDescription>
                {planDetail.abbr === "rtl" && getDescription(type, planDetail.abbr)}
                <br />
                {planDetail.abbr === "rtl" && type !== "Team" && this.renderSelection()}
                <React.Fragment>
                  {(planDetail.abbr === "blu") && (
                    <BluOnly>
                      {`MINIMUM ${planDetail.quota} LEADS`}
                    </BluOnly>
                  )}
                  {(type === "Team" && planDetail.abbr === "rtl") && (
                    <TeamOnly>
                      <TeamOnlyText>PER MONTH</TeamOnlyText>
                      <TeamOnlyText
                        fw={500}
                      >
                        {`$${planDetail.outstandingPrice} / ADDITIONAL LEAD`}
                      </TeamOnlyText>
                    </TeamOnly>
                  )}
                </React.Fragment>
              </PlanMiniDescription>
            </PlanAmountWrap>
            <PlanBenefitsStatusWrap>
              {renderBenefitStatus(planDetail.benefits, type, benefits)}
            </PlanBenefitsStatusWrap>
            <CTAWrap>
              <CTAButton
                isDisabled={isAdded || isSubscribed}
                disabled={isAdded || isSubscribed}
                isTeam={isTeam}
                isAdded={isAdded}
                isSubscribed={isSubscribed}
                onClick={() => handleAction(planDetail, addPlanToCart, "ADD", isCartHavingTeamPlan)}
              >
                {text}
              </CTAButton>
              {(isAdded && !isSubscribed) && (
                <RemovePlanCTA
                  onClick={() => handleAction(planDetail, removePlanFromCart, "REMOVE")}
                >
                Remove
                </RemovePlanCTA>
              )}
            </CTAWrap>
          </PlanColumnWrap>
        )}
      </React.Fragment>
    )
  }
}

export default PlanDetails
