/* eslint-disable max-len */
import React from "react"

const PhoneBook = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_20_15479)">
      <circle cx="11" cy="11.5" r="10.5417" fill="#F1E7FF" stroke="#F2BA7D" stroke-width="0.916667"/>
      <circle cx="11" cy="11.5" r="10.5417" stroke="#593198" stroke-width="0.916667"/>
      <path d="M4.47357 7.73206L11.0002 3.96389L17.5269 7.73206V15.2684L11.0002 19.0365L4.47357 15.2684V7.73206Z" stroke="url(#paint0_linear_20_15479)" stroke-width="0.177649"/>
      <g filter="url(#filter0_i_20_15479)">
      <path d="M11.0003 4.03906L17.4619 7.76968V15.2309L11.0003 18.9615L4.53868 15.2309V7.76968L11.0003 4.03906Z" fill="url(#paint1_linear_20_15479)"/>
      <path d="M11.0003 4.03906L17.4619 7.76968V15.2309L11.0003 18.9615L4.53868 15.2309V7.76968L11.0003 4.03906Z" fill="url(#paint2_linear_20_15479)" fill-opacity="0.25" style="mix-blend-mode:soft-light"/>
      </g>
      <path d="M4.71633 7.87225L11.0003 4.24419L17.2843 7.87225V15.1284L11.0003 18.7564L4.71633 15.1284V7.87225Z" stroke="url(#paint3_linear_20_15479)" stroke-width="0.355297"/>
      <g style="mix-blend-mode:overlay" opacity="0.15">
      <mask id="mask0_20_15479" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="14" height="15">
      <path d="M8.73633 11.5894C8.73633 12.2378 8.99966 12.8273 9.42675 13.253C9.85253 13.6787 10.4421 13.9434 11.0906 13.9434C11.7391 13.9434 12.3286 13.6801 12.7544 13.253C13.1802 12.8273 13.4448 12.2378 13.4448 11.5894C13.4448 10.9409 13.1815 10.3514 12.7544 9.9257C12.3286 9.49996 11.7391 9.23535 11.0906 9.23535C10.4421 9.23535 9.85253 9.49865 9.42675 9.9257C8.99966 10.3514 8.73633 10.9396 8.73633 11.5894ZM11.0906 9.63358C11.629 9.63358 12.119 9.85234 12.4727 10.2073C12.8264 10.561 13.0465 11.0497 13.0465 11.5894C13.0465 12.1291 12.8264 12.6164 12.4714 12.9701C12.1177 13.3237 11.629 13.5438 11.0893 13.5438C10.5508 13.5438 10.0608 13.3251 9.70711 12.9701C9.35338 12.6164 9.13329 12.1278 9.13329 11.588C9.13329 11.0483 9.35207 10.561 9.70711 10.206C10.0621 9.85365 10.5508 9.63358 11.0906 9.63358Z" fill="#FBF8EF"/>
      <path d="M14.8801 11.5892C14.8801 10.612 14.508 9.71987 13.8975 9.04655C13.3316 8.4217 12.5612 7.98679 11.6927 7.84794C11.5852 7.83091 11.4883 7.91343 11.4883 8.02216V8.07063C11.4883 8.15709 11.5512 8.23045 11.6363 8.24486C12.4171 8.37192 13.1089 8.76622 13.6146 9.33082C14.153 9.93078 14.4792 10.722 14.4792 11.5892C14.4792 11.9848 14.4111 12.3647 14.2853 12.7171C14.1766 13.0223 14.0259 13.3066 13.8399 13.5646C13.7888 13.6354 13.7967 13.7323 13.8582 13.7926L13.8923 13.8266C13.9696 13.9039 14.0967 13.8934 14.1596 13.8057C14.3652 13.5214 14.5329 13.2083 14.6548 12.873C14.8002 12.4721 14.8801 12.0398 14.8801 11.5892Z" fill="#FBF8EF"/>
      <path d="M10.4653 7.85035C9.67009 7.98266 8.95871 8.36386 8.4124 8.91011C7.86216 9.4603 7.47962 10.1795 7.34992 10.9812C7.33289 11.0899 7.41411 11.1894 7.52416 11.1894H7.57132C7.6591 11.1894 7.73115 11.1239 7.74557 11.0375C7.86347 10.3236 8.2041 9.68299 8.69407 9.19306C9.18143 8.70576 9.81682 8.36517 10.5256 8.24596C10.6199 8.23024 10.6907 8.15164 10.6907 8.05601V8.03898C10.6907 7.92109 10.5832 7.8307 10.4653 7.85035Z" fill="#FBF8EF"/>
      <path d="M8.54862 14.3963C9.22201 15.0068 10.1142 15.3788 11.0915 15.3788C11.5422 15.3788 11.9745 15.2989 12.3754 15.1535C12.7108 15.0317 13.0239 14.864 13.3082 14.6583C13.396 14.5941 13.4064 14.4671 13.3292 14.3911L13.2951 14.357C13.2335 14.2955 13.1366 14.2889 13.0671 14.3387C12.809 14.5247 12.5234 14.6754 12.2195 14.7841C11.8671 14.9098 11.4872 14.978 11.0915 14.978C10.2242 14.978 9.43294 14.6505 8.83291 14.1134C8.26826 13.6077 7.87393 12.9161 7.74685 12.1353C7.73244 12.0502 7.65907 11.9873 7.57261 11.9873H7.52413C7.41539 11.9873 7.33286 12.0842 7.34989 12.1917C7.48876 13.0602 7.92502 13.8304 8.54862 14.3963Z" fill="#FBF8EF"/>
      <path d="M11.6848 16.7754C12.1839 16.7191 12.6621 16.592 13.1089 16.4034C13.553 16.2174 13.9644 15.9711 14.3364 15.6751C14.419 15.6096 14.4255 15.4864 14.3508 15.4131L14.3469 15.4091L14.3194 15.3816C14.2565 15.3188 14.1556 15.3122 14.0849 15.3685C13.743 15.6397 13.363 15.8663 12.9543 16.0379C12.5429 16.2108 12.104 16.3274 11.6442 16.3798C11.5551 16.3903 11.4883 16.465 11.4883 16.5554V16.5947V16.5999C11.4883 16.706 11.58 16.7872 11.6848 16.7754Z" fill="#FBF8EF"/>
      <path d="M14.9131 14.8456L14.917 14.8496C14.9917 14.9242 15.1135 14.9177 15.179 14.8352C15.4751 14.4631 15.7201 14.0505 15.9074 13.6077C16.0948 13.161 16.2218 12.6829 16.2795 12.1838C16.2913 12.079 16.2087 11.9873 16.1039 11.9873H16.0987H16.0594C15.9703 11.9873 15.8943 12.0541 15.8838 12.1432C15.8314 12.603 15.7135 13.0418 15.5419 13.4532C15.3703 13.8619 15.1449 14.2418 14.8724 14.5837C14.8161 14.6544 14.8227 14.7539 14.8855 14.8181L14.9131 14.8456Z" fill="#FBF8EF"/>
      <g style="mix-blend-mode:soft-light">
      <path d="M11.0905 6.3623C9.64945 6.3623 8.34329 6.94786 7.39609 7.89496C6.4502 8.84076 5.86328 10.1481 5.86328 11.5891C5.86328 12.9632 6.39649 14.2129 7.26639 15.1456C8.09437 16.0325 9.22629 16.6324 10.4958 16.7765C10.5023 16.7778 10.5089 16.7778 10.5154 16.7778C10.6111 16.7778 10.691 16.6992 10.691 16.601V16.5957V16.5564C10.691 16.4674 10.6242 16.3914 10.5351 16.3809C9.35992 16.246 8.31184 15.6879 7.54806 14.864C6.75021 14.0033 6.26286 12.8532 6.26286 11.5891C6.26286 10.2581 6.80393 9.05166 7.67776 8.17791C8.55159 7.30417 9.75819 6.76315 11.0892 6.76315C12.3522 6.76315 13.5037 7.25177 14.3645 8.04823C15.1885 8.81194 15.7466 9.85991 15.8816 11.0349C15.892 11.124 15.9667 11.1908 16.0571 11.1908H16.0964H16.1017C16.2065 11.1908 16.289 11.0991 16.2772 10.9956C16.1331 9.7276 15.5331 8.59448 14.6461 7.76659C13.7147 6.89546 12.4635 6.3623 11.0905 6.3623Z" fill="#FBF8EF"/>
      </g>
      <path d="M11.0909 4.92676C9.2528 4.92676 7.58768 5.67344 6.38108 6.87991C5.17449 8.08639 4.42773 9.75135 4.42773 11.5892C4.42773 12.4355 4.58757 13.245 4.87841 13.9891C5.14435 14.6703 5.51904 15.2964 5.98019 15.8492C6.01688 15.8938 6.06928 15.916 6.12168 15.916C6.16885 15.916 6.21601 15.8977 6.25269 15.8623L6.27234 15.8427C6.34047 15.7746 6.34571 15.6672 6.28414 15.5938C5.84918 15.0711 5.49546 14.4777 5.24654 13.8345C4.97666 13.1376 4.82731 12.3805 4.82731 11.5892C4.82731 9.8627 5.52952 8.29729 6.66406 7.16287C7.79729 6.02844 9.36416 5.3263 11.0909 5.3263C11.8822 5.3263 12.6394 5.47563 13.3364 5.74549C13.9822 5.99569 14.577 6.35069 15.0997 6.78822C15.1325 6.81573 15.1731 6.83014 15.2137 6.83014C15.2596 6.83014 15.3041 6.81311 15.3382 6.77905L15.3631 6.75416L15.3696 6.74761C15.443 6.67425 15.4378 6.55373 15.3578 6.48692C14.8037 6.02189 14.1748 5.64462 13.491 5.37739C12.7468 5.08657 11.9372 4.92676 11.0909 4.92676Z" fill="#FBF8EF"/>
      <path d="M17.3027 9.18806C17.0355 8.50426 16.6582 7.87547 16.1944 7.32136C16.1276 7.24145 16.0071 7.23621 15.9337 7.30957L15.9272 7.31612L15.9023 7.34101C15.8381 7.4052 15.8328 7.50868 15.8918 7.57942C16.3293 8.1021 16.6844 8.69682 16.9346 9.34263C17.2045 10.0395 17.3538 10.7967 17.3538 11.5879C17.3538 13.3144 16.6516 14.8798 15.5171 16.0143C14.3826 17.1487 12.817 17.8508 11.0903 17.8508C10.299 17.8508 9.54176 17.7015 8.84479 17.4317C8.19892 17.1815 7.60413 16.8265 7.08141 16.3889C7.01066 16.33 6.90716 16.3339 6.84297 16.3994L6.81808 16.4243L6.81153 16.4308C6.73816 16.5042 6.7434 16.6247 6.82332 16.6915C7.37749 17.1553 8.00633 17.5325 8.6902 17.7998C9.43433 18.0906 10.244 18.2504 11.0903 18.2504C12.927 18.2504 14.5935 17.5037 15.7988 16.2972C17.0054 15.0908 17.7521 13.4258 17.7521 11.5892C17.7534 10.7417 17.5936 9.93343 17.3027 9.18806Z" fill="#FBF8EF"/>
      </mask>
      <g mask="url(#mask0_20_15479)">
      <g filter="url(#filter1_i_20_15479)">
      <path d="M11.0004 4.47168L17.0867 7.98557V15.0133L11.0004 18.5272L4.9142 15.0133V7.98557L11.0004 4.47168Z" fill="url(#paint4_radial_20_15479)"/>
      </g>
      </g>
      </g>
      <g style="mix-blend-mode:soft-light">
      <path d="M17.1109 7.98545L10.9998 4.47266L4.88867 7.96266V8.88233C7.50787 12.3467 11.5053 14.4994 15.8393 14.7794L17.1109 14.8615V7.98545Z" fill="white" fill-opacity="0.5"/>
      </g>
      <g style="mix-blend-mode:soft-light">
      <ellipse cx="9.81642" cy="13.0164" rx="4.92069" ry="3.03582" transform="rotate(28.3752 9.81642 13.0164)" fill="url(#paint5_radial_20_15479)" fill-opacity="0.75"/>
      </g>
      <g style="mix-blend-mode:soft-light">
      <ellipse cx="14.1757" cy="5.66258" rx="9.91738" ry="3.03582" transform="rotate(28.3752 14.1757 5.66258)" fill="url(#paint6_radial_20_15479)"/>
      </g>
      </g>
      <defs>
      <filter id="filter0_i_20_15479" x="4.53906" y="4.03906" width="12.9238" height="15.8488" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="0.925926"/>
      <feGaussianBlur stdDeviation="0.462963"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.380392 0 0 0 0 0.227451 0 0 0 0 0.619608 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_20_15479"/>
      </filter>
      <filter id="filter1_i_20_15479" x="4.91406" y="4.47168" width="12.1719" height="14.9816" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="0.925926"/>
      <feGaussianBlur stdDeviation="0.462963"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_20_15479"/>
      </filter>
      <linearGradient id="paint0_linear_20_15479" x1="11.0002" y1="3.86133" x2="11.0002" y2="19.1391" gradientUnits="userSpaceOnUse">
      <stop stop-color="#DED3F7"/>
      <stop offset="1" stop-color="#371189"/>
      </linearGradient>
      <linearGradient id="paint1_linear_20_15479" x1="11.0003" y1="4.03906" x2="11.0003" y2="18.9615" gradientUnits="userSpaceOnUse">
      <stop stop-color="#E7D8FF"/>
      <stop offset="0.536458" stop-color="#512B8F"/>
      <stop offset="1" stop-color="#C4B3DF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_20_15479" x1="11.0003" y1="4.03906" x2="11.0003" y2="18.9615" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint3_linear_20_15479" x1="11.0003" y1="4.03906" x2="11.0003" y2="18.9615" gradientUnits="userSpaceOnUse">
      <stop stop-color="#B99AE9"/>
      <stop offset="1" stop-color="#7D62BD"/>
      </linearGradient>
      <radialGradient id="paint4_radial_20_15479" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.9969 11.4959) rotate(89.9708) scale(7.03133 6.31504)">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="1" stop-color="white"/>
      </radialGradient>
      <radialGradient id="paint5_radial_20_15479" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.81642 13.0164) rotate(90) scale(3.03582 4.92069)">
      <stop stop-color="white"/>
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint6_radial_20_15479" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.1757 5.66258) rotate(90) scale(3.03582 9.91738)">
      <stop stop-color="white"/>
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <clipPath id="clip0_20_15479">
      <rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
)

export default PhoneBook
