import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import { get } from "lodash"
import root from "window-or-global"
import * as Actions from "./actions"

const initState = {
  currentJobViewed: null,
  firstPageTab: "job",
  currentPage: "intro",
  isJobsModalOpen: false,
  searchQuery: {
    type: null,
    location: null,
  },
  ...createDeltaReducer("brokerageJobApplicants"),
  ...createDeltaReducer("brokerageCreatedJobs"),
  ...createDeltaReducer("brokerageSpecificJobsDetails"),
  ...createDeltaReducer("brokerageCreateJobs"),
  ...createDeltaReducer("brokerageUpdateJobs"),
  ...createDeltaReducer("brokerageDeleteJobs"),
  ...createDeltaReducer("favouriteJob"),
  ...createDeltaReducer("applyJobApplicationResponse"),
  ...createDeltaReducer("updateJobApplicationResponse"),
  ...createDeltaReducer("markSeenResponse"),
  ...get(root, "__STATE__.brokerJobsDashboard", {}),
}

export default function rarBrokerJobs(state = initState, action) {
  switch (action.type) {
    case Actions.fetchApplicants.REQUEST:
    case Actions.fetchApplicants.SUCCESS:
    case Actions.fetchApplicants.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchApplicants, "brokerageJobApplicants"),
      }
    }

    case Actions.getAllCreatedJobs.REQUEST:
    case Actions.getAllCreatedJobs.SUCCESS:
    case Actions.getAllCreatedJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllCreatedJobs, "brokerageCreatedJobs"),
      }
    }

    case Actions.applyJobAction.REQUEST:
    case Actions.applyJobAction.SUCCESS:
    case Actions.applyJobAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.applyJobAction, "applyJobApplicationResponse"),
      }
    }

    case Actions.updateJobAction.REQUEST:
    case Actions.updateJobAction.SUCCESS:
    case Actions.updateJobAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateJobAction, "updateJobApplicationResponse"),
      }
    }

    case Actions.createJob.REQUEST:
    case Actions.createJob.SUCCESS:
    case Actions.createJob.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createJob, "brokerageCreateJobs"),
      }
    }

    case Actions.updateJob.REQUEST:
    case Actions.updateJob.SUCCESS:
    case Actions.updateJob.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateJob, "brokerageUpdateJobs"),
      }
    }

    case Actions.markSeenAction.REQUEST:
    case Actions.markSeenAction.SUCCESS:
    case Actions.markSeenAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.markSeenAction, "markSeenResponse"),
      }
    }

    case Actions.deleteJob.REQUEST:
    case Actions.deleteJob.SUCCESS:
    case Actions.deleteJob.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteJob, "brokerageDeleteJobs"),
      }
    }

    case Actions.showShareModal.REQUEST:
    case Actions.showShareModal.SUCCESS:
    case Actions.showShareModal.FAILURE: {
      return {
        ...state,
        shareModalEnabled: action.data.enabled,
        jobShareId: action.data.jobId,
        jobShareSlug: action.data.jobSlug,
        teamName: action.data.team_name,
        role: action.data.job_title,
      }
    }

    case Actions.showJobCreateModal.REQUEST:
    case Actions.showJobCreateModal.SUCCESS:
    case Actions.showJobCreateModal.FAILURE: {
      return {
        ...state,
        jobCreateModalEnabled: action.data,
      }
    }

    case Actions.showJobDeleteModal.REQUEST:
    case Actions.showJobDeleteModal.SUCCESS:
    case Actions.showJobDeleteModal.FAILURE: {
      return {
        ...state,
        jobDeleteModalEnabled: action.data.enabled,
        jobDeleteId: action.data.jobId,
      }
    }

    case Actions.showJobEditModal.REQUEST:
    case Actions.showJobEditModal.SUCCESS:
    case Actions.showJobEditModal.FAILURE: {
      return {
        ...state,
        jobEditModalEnabled: action.data.enabled,
        jobEditId: action.data.jobId,
      }
    }

    case Actions.setShowInitialRecruitmentPage.REQUEST:
    case Actions.setShowInitialRecruitmentPage.SUCCESS:
    case Actions.setShowInitialRecruitmentPage.FAILURE: {
      return {
        ...state,
        showInitialRecruitmentPage: action.data,
      }
    }

    case Actions.getAllCreatedPublicJobs.REQUEST:
    case Actions.getAllCreatedPublicJobs.SUCCESS:
    case Actions.getAllCreatedPublicJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllCreatedPublicJobs, "brokerageCreatedJobs"),
      }
    }

    case Actions.getSpecificCreatedPublicJobs.REQUEST:
    case Actions.getSpecificCreatedPublicJobs.SUCCESS:
    case Actions.getSpecificCreatedPublicJobs.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getSpecificCreatedPublicJobs, "brokerageSpecificJobsDetails"),
      }
    }

    case Actions.markJobFavourite.REQUEST:
    case Actions.markJobFavourite.SUCCESS:
    case Actions.markJobFavourite.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.markJobFavourite, "favouriteJob"),
      }
    }

    case Actions.toggleJobsDetailsModalAction.type: {
      return {
        ...state,
        isJobsModalOpen: action.data.enabled,
        landingPageJobApply: action.data.isLandingPage,
      }
    }

    case Actions.toggleModalPagesAction.type: {
      const {
        currentPage,
      } = action.data
      return {
        ...state,
        currentPage,
      }
    }

    case Actions.initialPageTabToggleAction.type: {
      const {
        firstPageTab,
      } = action.data
      return {
        ...state,
        firstPageTab,
      }
    }

    case Actions.currentJobViewedAction.type: {
      const {
        currentJobViewed,
      } = action.data

      return {
        ...state,
        currentJobViewed,
      }
    }

    case Actions.setLocationValuesForJobsAction.type: {
      const {
        currentCity,
        currentState,
      } = action.data
      return {
        ...state,
        currentCity,
        currentState,
      }
    }

    case Actions.setCurrentSearchTermAction.type: {
      const {
        type,
        location,
      } = action.data

      return {
        ...state,
        searchQuery: {
          type,
          location,
        },
      }
    }

    case Actions.clearExistingJobsAction.type: {
      return {
        ...state,
        brokerageCreatedJobs: {
          data: null,
          isFetching: true,
        },
      }
    }

    case Actions.showLoaderWhenDelayAction.type: {
      return {
        ...state,
        brokerageCreatedJobs: {
          isFetching: action.data,
        },
      }
    }

    default: {
      return state
    }
  }
}
