import React from "react"
import { normalizePhone } from "services/formUtils"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import { Form } from "@ui/antd"
import {
  InputFieldWrap, InputNumberWrap,
} from "./styles"

const InputNumber = ({
  placeholder = "",
  type,
  name,
  required = false,
  customRules = [],
  disabled,
  onChange,
  text = "",
}) => {
  const getInputElement = () => {
    switch (type) {
      case "phone":
        return (
          <InputFieldWrap
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
          />
        )

      case "money":
        return (
          <InputNumberWrap
            placeholder={placeholder}
            formatter={value =>
              value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))(?:\.\d{1,2})?/g, ",") : ""
            }
            controls={false}
            disabled={disabled}
            onChange={onChange}
          />
        )

      case "percentage":
        return (
          <InputNumberWrap
            placeholder={placeholder}
            formatter={value => value ? `${value} %` : ""}
            controls={false}
            onChange={onChange}
            disabled={disabled}
          />
        )

      default:
        return (
          <InputFieldWrap
            placeholder={placeholder}
          />
        )
    }
  }

  const rules = []
  if (required) {
    rules.push({
      required: true,
      message: 'Required field',
    })
  }

  if (customRules) {
    rules.push(...customRules)
  }

  return (
    <Form.Item
      name={name}
      rules={rules}
      normalize={type === "phone" ? normalizePhone : ""}
      label={<LabelComponent text={text} />}
    >
      {getInputElement()}
    </Form.Item>
  )
}

export default InputNumber
