import styled from "styled-components"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const StatsCardWrap = styled.div`
display: flex;
padding-top: 8px;
align-items: flex-start;
width:100%
gap: 120px;
align-self: stretch;
`

export const StatsCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
`

export const StatsLabel = styled.div`
color: var(--Color-Gray-Gray-500, #5C5C94);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 18.9px */
letter-spacing: -0.28px;
display: flex;
align-items: flex-start;
gap: 8px;
`

export const StatsValue = styled.div`
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 43.2px */
letter-spacing: -0.64px;
background: var(--Gradient-1-1, linear-gradient(146deg, #B6B8FF -28.78%, #5A5FF2 21.34%, #F08068 82.37%, #F0A468 110.69%, #F0A468 110.7%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`
export const TestWrap = styled.div`
display: flex;
padding-top: 8px;
align-items: flex-start;
justify-content: space-between;
width: 100%;
align-self: stretch;
`
export const ProgressCapWrap = styled.div`
border-radius: 40px;
background: var(--Color-Primary-Primary-50, #EFEFFE);
display: flex;
height: 20px;
padding: 2px 8px;
align-items: center;
gap: 4px;
// &:hover .info-card {
//     visibility: visible;
//     opacity: 1;
//   }
`

export const ProgressCapLabel = styled.div`
display: flex; 
  align-items: center; 
  gap: 4px; 

  img {
    width: 16px;
    height: 16px;
  }

  p {
    color: var(--Mel-Chat-Background-Primary-900, #262866);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 16.2px */
    margin: 0; /* Removes default paragraph margin */
  }
`
