import axios from "axios"

export const fetchClientSearchCriteriaAPI = clientId => axios
  .get(`${API_V2_URL}requirements?u_cust_id=${clientId}`)

export const fetchClientReminderAPI = clientId => axios
  .get(`${API_V2_URL}client/${clientId}/reminders`)

export const fetchRecommendedPropertiesAPI = clientId => axios
  .get(`${API_URL}recommended-properties/?clientId=${clientId}&sendPropertyDetails=true`)

export const fetchClientPropertyDetailsAPI = (clientId, clientType) => axios
  .get(`${API_URL}client/${clientId}/properties?client_type=${clientType}`)

export const fetchClientNotesAPI = clientId => axios
  .get(`${API_URL}client/${clientId}/notes?adminNotes=true`)

export const addNoteAPI = (clientId, payload) => axios
  .post(`${API_URL}client/${clientId}/notes`, payload)

export const updateNoteAPI = (clientId, noteId, payload) => axios
  .put(`${API_URL}client/${clientId}/notes/${noteId}`, payload)

export const deleteNoteAPI = (clientId, noteId) => axios
  .delete(`${API_URL}client/${clientId}/notes/${noteId}`)

export const updateClientProfileAPI = (id, data) => axios
  .put(`${API_V2_URL}clients/${id}`, data)

export const fetchClientTimelineAPI = (clientId, limit, offset) => axios
  .get(`${API_V2_URL}clientTimeline/${clientId}?limit=${limit}&offset=${offset}`)

export const editReminderAPI = (clientId, payload, reminderId) => axios
  .put(`${API_V2_URL}client/${clientId}/reminders/${reminderId}`, payload)

export const createReminderAPI = (clientId, payload) => axios
  .post(`${API_V2_URL}client/${clientId}/reminders`, payload)

export const deleteReminderAPI = (clientId, reminderId) => axios
  .delete(`${API_V2_URL}client/${clientId}/reminders/${reminderId}`)

export const fetchMatchesAPI = payload => axios.post(`${API_URL}matches/get-matches`, payload)

export const fetchEditPropertyAPI = propertyId => axios
  .get(`${API_URL}user/property/${propertyId}`)

export const sendClientRequestUpdateAPI = (ref_id, payload) => axios
  .put(`${API_URL}crs-referral-settings/${ref_id}`, payload)

export const getPreferredLenderAPI = clientID => axios
  .get(`${API_V2_URL}clients/${clientID}`)

export const updatePreferredLenderAPI = (clientID, body) => axios
  .put(`${API_URL}preferred-lender/${clientID}`, body)
