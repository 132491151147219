import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Checkbox } from "antd"
import root from "window-or-global"
import OnboardingContainer from "container/OnboardingSetup"
import { ShimmerBox } from "shared/styles/animation"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  RightWrap,
  MiddleWrap,
  StepsWrap,
  StepsContentWrap,
  StepTitle,
  StepDescription,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  GetStarted,
  BottomText,
  Underlined,
  ScheduleFeeWrap,
  ScheduleFeeContent,
  FeeContentWrap,
  ScheduleFeeSmall,
  FeeContentSmall,
  ICAButtons,
} from "../../common"
import { DashIcon, ReviewFreeIcon, disableIcon } from "../../consts"

const FeeSchedule = ({
  history,
  getFeeSchedule,
  getFeeScheduleResponse,
}) => {
  useEffect(() => {
    getFeeSchedule()
  }, [])

  const { data, isFetching } = getFeeScheduleResponse || {}

  const [isChecked, setChecked] = useState(false)
  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap
          // paddingTop="200px"
          // alignItems="flex-start"
        >
          <MiddleWrap>
            <StepsWrap>
              <img src={ReviewFreeIcon} alt="hello" height={57} width={57} style={{ marginLeft: "-3px" }} />
              <StepsContentWrap>
                <StepTitle>
                  Step 1
                </StepTitle>
                <StepDescription>
                  REVIEW FEE SCHEDULE
                </StepDescription>
              </StepsContentWrap>
            </StepsWrap>
            <img src={DashIcon} alt="okay" height={39} width={52} />
            <StepsWrap>
              <img src={disableIcon} alt="hello" height={46} width={46} />
              <StepsContentWrap style={{ marginLeft: "10px" }}>
                <StepTitle>
                  Step 2
                </StepTitle>
                <StepDescription>
                  SIGN ICA
                </StepDescription>
              </StepsContentWrap>
            </StepsWrap>
          </MiddleWrap>
        </LeftWrap>
        <RightWrap>
          <WelcomeContentWrap>
            <WelcomeTitle>
              Let’s review your
            </WelcomeTitle>
            <WelcomeTitle>
              fee schedule
            </WelcomeTitle>
            <WelcomeDescription>
              At Radius, we’re all about transparency. Get to know your fee schedule below.
            </WelcomeDescription>
          </WelcomeContentWrap>
          {isFetching ? <ShimmerBox w="351px" h="243px" /> : (
            <ScheduleFeeWrap>
              <>
                {data && data.map(fe => (
                  <>
                    {(fe.sub_label) ? (
                      <FeeContentWrap>
                        <ScheduleFeeSmall>
                          <ScheduleFeeContent>
                            {fe.label}
                          </ScheduleFeeContent>
                          <FeeContentSmall>
                            {fe.sub_label}
                          </FeeContentSmall>
                        </ScheduleFeeSmall>
                        <ScheduleFeeContent>
                          {`${fe.type === "dollar" ? "$" : ""} ${fe.value}`}
                        </ScheduleFeeContent>
                      </FeeContentWrap>
                    ) : (
                      <FeeContentWrap>
                        <ScheduleFeeContent>
                          {fe.label}
                        </ScheduleFeeContent>
                        <ScheduleFeeContent>
                          {`${fe.type === "dollar" ? "$" : ""} ${fe.value}`}
                        </ScheduleFeeContent>
                      </FeeContentWrap>
                    )}
                  </>
                ))}
              </>
            </ScheduleFeeWrap>
          )}
          <GetStartedWrap>
            <GetStarted>
              <Checkbox
                defaultChecked={isChecked}
                onChange={() => setChecked(!isChecked)}
                style={{
                  marginRight: "5px",
                }}
              />
              I Acknowledge
            </GetStarted>
            <ICAButtons
              onClick={() => history.push("/office/ica-onboarding/radius-partnership")}
              disabled={!isChecked}
            >
              Keep Going
            </ICAButtons>
          </GetStartedWrap>
          <BottomText>
            If you have any questions or need clarification,&nbsp;
            <Underlined onClick={() => root.open("https://knowledgebase.radiusagent.com/knowledge/what-is-my-fee-schedule", "_blank")}>you can learn more about fee schedules here. </Underlined>
          </BottomText>
        </RightWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(OnboardingContainer(FeeSchedule))
