import React from "react"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  AgentNameWrap,
  ContractStatusWrap,
} from "./styles"

const ContractStatus = ({ data, contractStatus, labelStatus }) => (
  <AgentNameWrap>
    {
      contractStatus ? (
        <>
          <ContractStatusWrap
            complete={contractStatus !== "incomplete"}
            incomplete={contractStatus === "incomplete"}
          >
            {labelStatus}
          </ContractStatusWrap>
        </>
      ) : (
        <> - </>
      )
    }
  </AgentNameWrap>
)

export default ContractStatus
