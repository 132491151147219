import React from "react"
import {
  Li,
} from "@ui/DragDropColumn/common"
import DropdownMenu from "@ui/NewTooltip"
import {
  ThreeDotMoreIcon,
  DivideIcon,
} from "dumbComponents/OffersV2/icons"
import {
  CTAWrap,
  CTAButton,
  CTAText,
} from "./styles"

const DynamicMenu = ({
  mainList,
  dropdownList,
  id,
}) => {
  return (
    <CTAWrap>
      {mainList && mainList.map(main => (
        <>
          <CTAButton
            onClick={() => {
              console.log(id)
              main.onClick(id)
            }}
          >
            {main.image}
            <CTAText>
              {main.label}
            </CTAText>
          </CTAButton>
          {main.showDivide && (
            <DivideIcon />
          )}
        </>
      ))}
      {dropdownList && dropdownList.length > 0 && (
        <DropdownMenu
          position="bottom"
          component={<ThreeDotMoreIcon />}
          top={30}
          padding={10}
          left={-160}
          width={200}
          borderColor="#FFF"
          eventType="hover"
          borderRadius={4}
          showShadow
          arrowPosition="right"
        >
          <React.Fragment>
            {dropdownList.map(option => (
              <Li
                onClick={() => {
                  option.onClick(id)
                }}
                fontFam="Montserrat"
                fontSize="12px"
              >
                <span>{option.label}</span>
              </Li>
            ))}
          </React.Fragment>
        </DropdownMenu>
      )}

    </CTAWrap>
  )
}

export default DynamicMenu
