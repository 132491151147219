import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { MOBILE_BREAK_POINT } from "shared/consts"
import { withRouter } from "react-router-dom"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import RARContainer from "container/App/recruitAndEarnV2Container"
import RARContainerV2 from "container/Realty/RecruitAndEarnContainer"

const Wrap = styled.div``
const ButtonWrap = styled.div`
  margin-top: 7px;
`
const ReferAnAgent = styled.button`
  height: 33px;
  width: 155px;
  border-radius: 100px;
  font-size: 16px;
  background: ${colors.marineBLue};
  color: ${colors.white};
  cursor: pointer;
  border: none;
  margin-right: 10px;

  span img {
    height: 12px;
    position: relative;
    left: 8px;
    top: 1px;
  }
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    display: none;
  }
`

const ReferAnAgentNav = ({
  toggleRecruitAndEarnForm,
  toggleFormModal,
  history,
  getReferralCumulativeDataResponse,
}) => {
  const {
    data: referralData,
  } = getReferralCumulativeDataResponse || {}
  const { totalInvitationAccepted } = referralData || 0
  return (
    <Wrap>
      <ButtonWrap>
        <ReferAnAgent
          onClick={() => {
            if (totalInvitationAccepted === 0) {
              history.push("/realty/recruit-and-earn/onboarding")
            } else {
              history.push("/realty/recruit-and-earn")
            }
            toggleRecruitAndEarnForm(true)
            toggleFormModal(true)
          }}
        >
          Refer an Agent
          <span>
            <img src={PlusIcon} alt="refer and agent" />
          </span>
        </ReferAnAgent>
      </ButtonWrap>
    </Wrap>
  )
}

export default withRouter(RARContainerV2(RARContainer(ReferAnAgentNav)))
