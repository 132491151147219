import styled from "styled-components"
import { ATC, HIGHLIGHT_FONT } from "@fonts"
import colors from "@colors"

const ModalWrap = styled.div`
  background: linear-gradient(0deg, #F9F9F7, #F9F9F7),
  linear-gradient(0deg, #E5E5E5, #E5E5E5);
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > input {
    width: 100%;
    height: 54px;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
    border: 1px solid ${colors.eggYolkWhite};
    margin: 25px 0px 10px 0px;
  }

  > h3 {
    margin: 0;
    text-align: left;
    display: flex;
    font-family: ${HIGHLIGHT_FONT};
    line-height: 37px;
    width: 377px;
    font-size: 30px;

    ${props => props.isSpeakerLimitModal && `
      font-size: 24px;
    `}
  }
  > div {
    display: flex;
    gap: ${props => props.flexGap || "15px"};

    > img {
      max-height: 98px !important;
      width: 90px;
      display: flex;
      border-radius: 48px;
    }
    
    &:nth-child(2) {
      display: block;
    }
  }

`

const ModalButtons = styled.div`
  display: flex! important;
  flex-direction: row;
  gap: 10px !important;
  position: ${props => props.pos || "relative"};
  right: 0;
  bottom: 0;


> div {
  display: flex;
  gap: 10px;
}
`

const ModalDescWrap = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  display: flex;
  font-size: 16px;
  line-height: 23px;
  margin-top: 10px;
`

export { ModalButtons, ModalDescWrap, ModalWrap }
