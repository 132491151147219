import React from "react"
import colors from "@colors"
import Button from "./Button"
import ThreeDotLoader from "./ThreeDotLoader"

const LoaderButton = ({
  isShowLoader,
  loaderOption,
  children,
  color,
  ...props
}) => (
  <Button {...props}>
    {isShowLoader ? (
      <ThreeDotLoader color={color || "#fff"} {...loaderOption} />
    ) : children }
  </Button>
)

export default LoaderButton
