import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "NON-ASSIST"

export const getNonAssistLeadsAction = createSignalAction(BASE, "GET_NON_ASSIST_LEADS")
export const changeSalesStateAction = createSignalAction(BASE, "CHANGE_SALES_STATE")
export const addNoteAction = createSignalAction(BASE, "ADD_NOTE")
export const getArchivedLeadNonAssistAction = createSignalAction(BASE, "GET_ARCHIVED_LEADS")
export const changeSalesStateV2Action = createSignalAction(BASE, "CHANGE_SALES_STATE_V2")

// NON-API ACTION
export const updateSalesStageAction = createSimpleCreator(BASE, "UPDATE_SALES_STAGE")
export const toggleAddNotesModalAction = createSimpleCreator(BASE, "TOGGLE_ADD_NOTES_MODAL")
export const toggleArchiveModalAction = createSimpleCreator(BASE, "TOGGLE_ARCHIVE_MODAL")

