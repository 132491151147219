import React from "react"
import styled from "styled-components"
import { ShimmerBox } from "shared/styles/animation"
import { useWindowSize } from "services/Utils"

const InnerShimmer = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  opacity: 0.3;
  justify-content: center;
  align-items: center;
`

const Shimmy = () => {
  const size = useWindowSize()

  const {
    width,
  } = size || {}

  return (
    <>
      {width <= 1099 ? (
        <InnerShimmer>
          <ShimmerBox w="357px" h="199px" />
          <ShimmerBox w="357px" h="40px" />
          <ShimmerBox w="357px" h="90px" />
          <ShimmerBox w="357px" h="150px" />
        </InnerShimmer>
      ) : (
        <InnerShimmer>
          <ShimmerBox w="1217px" h="555px" />
          <ShimmerBox w="1217px" h="120px" />
          <ShimmerBox w="1217px" h="213px" />
          <ShimmerBox w="1217px" h="334px" />
        </InnerShimmer>
      )}
    </>
  )
}

export default Shimmy
