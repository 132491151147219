import React from "react"
import Modal from "@ui/Modal"
import FeedItem from "./common/FeedItem"
import CommentBox from "./common/CommentBox"
import CommentLists from "./common/CommentLists"

const FeedDetail = ({
  feed,
  selectedFeed,
  show,
  close,
  openLikers,
  closeLikers,
  isLikingFeed,
  isLikingComment,
  updateFeedLike,
  updateCommentLike,
  postCommentForFeed,
  isPostingComment,
  agentsFollowees,
  togglePersonalizeFeedModal,
  toggleEditFeedItemModal,
  shouldAutoFocus,
  updateAutoFocus,
}) => (
  <Modal show={show} toClose={close} showCloseButton={false} modalStyles={{ padding: 0 }}>
    {feed && (
      <FeedItem
        feed={feed}
        feedDetail={selectedFeed}
        openLikers={openLikers}
        closeLikers={closeLikers}
        isLikingFeed={isLikingFeed}
        updateFeedLike={updateFeedLike}
        agentsFollowees={agentsFollowees}
        togglePersonalizeFeedModal={togglePersonalizeFeedModal}
        toggleEditFeedItemModal={toggleEditFeedItemModal}
        updateAutoFocus={updateAutoFocus}
        isFeedDetailView
      />
    )}
    <CommentBox
      type={feed.type}
      feedId={feed.id}
      postCommentForFeed={postCommentForFeed}
      isPostingComment={isPostingComment}
      shouldAutoFocus={shouldAutoFocus}
    />
    {selectedFeed.commentsCount > 0 && (
      <CommentLists
        feedDetail={selectedFeed}
        openLikers={openLikers}
        closeLikers={closeLikers}
        isLikingComment={isLikingComment}
        updateCommentLike={updateCommentLike}
      />
    )}
  </Modal>
)

export default FeedDetail
