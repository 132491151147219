import React from "react"
import styled from "styled-components"
import { isEmpty, get, remove, toLower } from "lodash"
import { reduxForm } from "redux-form"
import GoogleAutoComplete from "@ui/GoogleAutoComplete"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import Heading from "dumbComponents/common/Typography/Heading"
import Box from "@ui/Box"
import {
  Wrapper,
  Tag,
  Footer,
  Header,
  SaveButton,
} from "./commonStyles"

const NeighborhoodWrap = styled.div`
  margin-top: 20px;
`

const NoNeighbourhoodsFound = styled.div`
  padding: 0px;
  font-style: italic;
  font-weight: 100;
  color: #999;
`

const DeleteButton = styled.button`
  border: none;
  background: transparent;
  color: #f96d72;
  font-size: 16px;
  cursor: pointer;
`

class NeighborhoodEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  selectCity = (address) => {
    const { addCity } = this.props
    this.setState({
      selectedCity: address,
      selectedNeighborhood: [],
    })
    const {
      locality: {
        long_name: city,
      } = {},
      administrative_area_level_1: {
        long_name: state,
      } = {},
      country: {
        long_name: country,
      } = {},
      lat: latitude,
      lng: longitude,
    } = address
    const payload = {
      city: city || state,
      state,
      country,
      latitude,
      longitude,
    }
    addCity({ payload })
    this.searchForNeighborhood(address)
  }

  componentDidMount() {
    const { initialValues, getNeighbourhoodByCity } = this.props
    const { city, isEditOpen } = initialValues || {}
    if (isEditOpen) {
      const { neighbourhoods = [] } = city
      getNeighbourhoodByCity({ city: city.city })
      const modifiedList = neighbourhoods.map(x => x.name)
      this.setState({
        selectedCity: city,
        selectedNeighborhood: modifiedList,
      })
    }
  }

  handleSave = () => {
    const { handleNeighborhoodSave, closeEditProfileModal } = this.props
    const { selectedNeighborhood, selectedCity } = this.state
    const cityServedId = get(selectedCity, "id")
    if (selectedNeighborhood) {
      handleNeighborhoodSave({
        neighbourhoods: selectedNeighborhood,
        cityServedId,
      })
    } else {
      closeEditProfileModal()
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  searchForNeighborhood = (address) => {
    const city = get(address, "locality.long_name", "").toLowerCase()
    const { getNeighbourhoodByCity } = this.props
    getNeighbourhoodByCity({
      city,
    })
  }

  getCityName = () => {
    const { selectedCity } = this.state
    const city = get(selectedCity, "locality.long_name", "") || get(selectedCity, "city") || ""
    return toLower(city)
  }

  selectNeighborhood = (item) => {
    const { getNeighbourhoodByCity } = this.props
    const { selectedNeighborhood = [] } = this.state
    const city = this.getCityName()
    this.setState({
      selectedNeighborhood: [...selectedNeighborhood, item],
    })
    getNeighbourhoodByCity({
      city: toLower(city),
      neighbourhood: item,
    })
  }

  removeNeighborhood = (item) => {
    const { initialValues: { isEditOpen, city } = {}, removeCityServedNeighbourhood } = this.props
    const { selectedNeighborhood = [] } = this.state
    remove(selectedNeighborhood, x => x === item)
    if (isEditOpen) {
      const { id } = city || {}
      removeCityServedNeighbourhood({ cityServedId: id, neighbourhoods: [item] })
    }
    this.setState({
      selectedNeighborhood: [...selectedNeighborhood],
    })
  }

  selectAll = () => {
    const { neighbourhoods } = this.props
    const { selectedNeighborhood } = this.state
    this.setState({
      selectedNeighborhood: [selectedNeighborhood, ...neighbourhoods],
    })
  }

  fetchMore = () => {
    const { getNeighbourhoodByCity, neighbourhoods } = this.props
    const city = this.getCityName()
    getNeighbourhoodByCity({
      city: toLower(city),
      offset: neighbourhoods.length,
      limit: 10,
      isFetchingMore: true,
    })
  }

  removeCity = () => {
    const { selectedCity } = this.state
    const { removeCity, removeCityServedCity, initialValues: { isEditOpen } } = this.props
    removeCity()
    if (isEditOpen) {
      const id = get(selectedCity, "id")
      removeCityServedCity(id)
    }
  }

  render() {
    const {
      isSavingUpdates,
      neighbourhoods = [],
      isFetchingNeighbourhood,
      isFetchingMoreNeighbourhood,
      initialValues,
    } = this.props
    const { isEditOpen } = initialValues
    const {
      selectedCity = {},
      selectedNeighborhood,
    } = this.state
    const cityName = get(selectedCity, "locality.long_name") || get(selectedCity, "city")
    const isNeighbourhoods = neighbourhoods && neighbourhoods.length > 0
    return (
      <Wrapper>
        <Header>
          <h4>Add cities</h4>
        </Header>
        <Box p="20px">
          <Box>
            { !isEmpty(selectedCity)
              ? (
                <Tag
                  title={cityName}
                  handleClick={() => {
                    if (!isEditOpen) {
                      this.selectCity({})
                      this.removeCity()
                    }
                  }}
                  showMoreBtn={isEditOpen}
                />
              ) : (
                <GoogleAutoComplete
                  id="cities"
                  types={["(cities)"]}
                  location={(address) => { this.selectCity(address) }}
                  placeholder="Search city"
                  loader={false}
                />
              )}
          </Box>
          { !isEmpty(selectedCity) && (
            <div>
              { isFetchingNeighbourhood && !isFetchingMoreNeighbourhood
                ? "Loading..."
                : (
                  <div>
                    <Box mt="40">
                      <Heading
                        type="h7"
                        transform="uppercase"
                        color="#354052"
                        align="left"
                        style={{
                          margin: "15px 0px",
                        }}
                      >
                        Select neighborhoods you serve in this city
                      </Heading>
                      {/* <SearchInputWrap flex="1">
                        <img src={SearchIcon} alt="Search Icon" />
                        <input
                          name="search"
                          component="input"
                          type="text"
                          placeholder="Seach Neighbourhood"
                          value={search}
                          onChange={this.handleInput}
                        />
                      </SearchInputWrap> */}
                      <NeighborhoodWrap>
                        {selectedNeighborhood && selectedNeighborhood.map(item => (
                          <Tag
                            key={item.name || item}
                            title={item.name || item}
                            handleClick={() => { this.removeNeighborhood(item) }}
                          />
                        ))}
                        { isNeighbourhoods && neighbourhoods
                          .filter(x => selectedNeighborhood.indexOf(x) === -1)
                          .map(item => (
                            <Tag
                              key={item}
                              addable
                              title={item}
                              handleClick={() => { this.selectNeighborhood(item) }}
                            />
                          ))}
                        { isNeighbourhoods && (
                          <Tag
                            addable
                            showMoreBtn
                            title="Show More"
                            handleClick={() => { this.fetchMore() }}
                          />
                        )}
                        {isFetchingMoreNeighbourhood && <ThreeDotLoader />}
                      </NeighborhoodWrap>
                      {/* <BlankButton onClick={() => { this.selectAll() }}>Select All</BlankButton> */}
                    </Box>
                    { !isFetchingMoreNeighbourhood && !isNeighbourhoods && (
                      <NoNeighbourhoodsFound>No neighborhoods found for the city</NoNeighbourhoodsFound>
                    )}
                  </div>
                )
              }
            </div>
          )}
        </Box>
        <Footer d="flex" style={{ justifyContent: "space-between" }}>
          {isEditOpen && (
            <DeleteButton onClick={this.removeCity}>
              Delete
            </DeleteButton>
          )}
          <SaveButton
            width="100px"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSave}
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "EDIT_NEIGHBORHOOD_FORM",
})(NeighborhoodEdit)

