import React from "react"
import styled from "styled-components"
import {
  TitleText,
  GreyCard,
  CardsWrap,
  FlexAnimContainer,
  ImageWrap,
} from "../commonStyles"
import { SubText } from "../../commonStyles"
import LottieMachine from "../AnimationsJSON/LottieMachine"
import { PerformanceTrack } from "../AnimationsJSON/index"

const Wrap = styled.div`
  width: 100%;
  max-width: 503px;

   ${props => props.sticky && `
    display: none;
    @media(max-width: 1099px) {
      display: block;
    }
  `}
`

const Pixel = styled.div`
    width: 10px;
    height: 10px;
    background: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
`

const TextWrap = styled.div``

const DISPLAY_OBJECT = [{
  text: "Total Commission",
}, {
  text: "Total Production ",
}, {
  text: "Refer & Earn ",
}]

const DetailCardFour = () => (
  <FlexAnimContainer four>
    <Wrap>
      <TextWrap
        id="performance"
      >
        <TitleText>
          Performance Tracking
        </TitleText>
      </TextWrap>
      <SubText>
        <span>Track revenue and team production</span>
        {" "}
        in real-time. The Radius platform provides out-of-the-box,
        {" "}
        error-proof reporting on business growth and team performance.
      </SubText>
      <SubText
        style={{
          marginTop: "15px",
          marginBottom: "30px",
        }}
      >
        Our mobile ecosystem offers:
      </SubText>
      <CardsWrap>
        {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
          <GreyCard>
            <p>{item.text}</p>
          </GreyCard>
        ))}
      </CardsWrap>
    </Wrap>
    <Wrap
      sticky
    >
      <LottieMachine
        animationData={PerformanceTrack}
        scrollFrames={[0, 150]}
        endFrames={[150, 250]}
        customStyle={{
          height: "500px",
          position: "relative",
          top: "93px",
        }}
      />
    </Wrap>
    <Pixel id="performance_II" />
  </FlexAnimContainer>
)

export default DetailCardFour
