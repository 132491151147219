import * as React from "react"

const MyAssets = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M6 14.6851L7.45 11.7851C7.61696 11.4537 7.87281 11.1753 8.18893 10.9811C8.50504 10.7868 8.86897 10.6843 9.24 10.6851H20M20 10.6851C20.3055 10.6845 20.6071 10.754 20.8816 10.8881C21.1561 11.0223 21.3963 11.2175 21.5836 11.4589C21.7709 11.7003 21.9004 11.9814 21.9622 12.2806C22.024 12.5798 22.0164 12.8892 21.94 13.1851L20.39 19.1851C20.279 19.615 20.0281 19.9957 19.6769 20.2673C19.3256 20.5389 18.894 20.6858 18.45 20.6851H4C3.46957 20.6851 2.96086 20.4743 2.58579 20.0993C2.21071 19.7242 2 19.2155 2 18.6851V5.68506C2 4.58506 2.9 3.68506 4 3.68506H7.93C8.25941 3.68676 8.58331 3.76981 8.8729 3.92682C9.1625 4.08383 9.40882 4.30994 9.59 4.58506L10.41 5.78506C10.5912 6.06018 10.8375 6.28629 11.1271 6.4433C11.4167 6.60031 11.7406 6.68335 12.07 6.68506H18C18.5304 6.68506 19.0391 6.89577 19.4142 7.27084C19.7893 7.64592 20 8.15463 20 8.68506V10.6851Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16.6851C14.5523 16.6851 15 16.2373 15 15.6851C15 15.1328 14.5523 14.6851 14 14.6851C13.4477 14.6851 13 15.1328 13 15.6851C13 16.2373 13.4477 16.6851 14 16.6851Z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MyAssets
