import {
  call,
  takeLatest,
  put,
  select,
} from "redux-saga/effects"
import {
  isSuccess,
} from "services/Utils"
import * as ReferralActions from "./actions"
import * as APIs from "./api"

function* handleFetchREEUserDetails() {
  try {
    const res = yield call(APIs.getUserInviteDetailsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.success(response))
    }
  } catch (error) {
    yield put(ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.failure(error))
    console.log(error)
  }
}

function* handleRecruitAndEarnLeaderboardFetch(action) {
  try {
    const {
      skip,
      limit,
      isFetchingMore,
    } = action.data
    const res = yield call(APIs.getLeaderboardForRecruitAndEarnAPI, skip, limit)
    if (isSuccess(res)) {
      const { response } = res.data
      //handle isFetchingMore
      if (isFetchingMore) {
        const isDoneFetchingItems = response && response.length === 0
        const oldData = yield select(state => state.referral.recruitAndEarnLeaderboardResponse.data)
        if (isDoneFetchingItems) {
          yield put(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.success({
            agents: [
              ...oldData.agents,
            ],
            isDone: true,
          }))
        } else if (response && response.length > 0) {
          yield put(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.success({
            agents: [
              ...oldData.agents,
              ...response,
            ],
            isDone: false,
          }))
        } else {
          yield put(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.success({
            agents: [
              ...oldData.agents,
            ],
            isDone: true,
          }))
        }
      } else {
        yield put(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.success({
          agents: response,
          isDone: false,
        }))
      }
    }
  } catch (error) {
    yield put(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.failure(error))
    console.log(error)
  }
}

export default function* main() {
  yield takeLatest(ReferralActions.getCurrentUserRecruitAndEarnDetailsAction.REQUEST, handleFetchREEUserDetails)
  yield takeLatest(ReferralActions.getCurrentRecruitAndEarnLeaderboardDetailsAction.REQUEST, handleRecruitAndEarnLeaderboardFetch)
}
