import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./actions";

function mapStateToProps(state, ownProps) {
  const { listingPage } = state;
  const { listingPageDetails } = (ownProps && ownProps.staticContext) || {}

  return {
    ...listingPage,
    ...listingPageDetails
  }
}


const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchListingPageDetails: Actions.fetchListingPageDetailsAction.request,
      postCreateATourInfo: Actions.handleRequestATourAction.request
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);