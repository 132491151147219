import React, { useRef } from "react"
import styled from "styled-components"
import RoomsContainer from "container/Rooms"
import EventList from "./EventList"
import RightArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/RightArrow.svg"
import LeftArrow from "dumbComponents/VideoRooms/components/LandingPage/Components/images/LeftArrow.svg"
import { HIGHLIGHT_FONT } from "@fonts"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import { preTabletWidth, minDesktopWidth } from "@consts"

const Wrap = styled.div`
  margin-bottom: 50px;
`

const MainWrap = styled.div`

  @media(min-width: 1100px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media(max-width: 767px) {
    margin-top: 60px;
  }
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const Header = styled.h1`
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  color: ${colors.black};
  margin: 0;
  margin-bottom: 15px;
  text-transform: capitalize;

  @media(min-width: 768px) {
    font-size: 24px;
  }

  @media(min-width: 1099px) {
    font-size: 24px;
  }

  @media(max-width: 767px) {
    font-size: 20px;
    text-align: left;
  }

  ${minDesktopWidth && `
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: center;
  `}
`

const AnchorWrap = styled.div`

`

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-start;
`

const createID = (topic) => {
  return `${topic.id}_category_id`
}

const MainCarousel = ({
  getRoomTopicsResponse,
  getRoomTopicEventsResponse,
}) => {
  const { data: RoomTopicEventData, isFetching } = getRoomTopicEventsResponse || {}
  const { sortedEvents } = RoomTopicEventData || {}
  const { data: RoomTopicData } = getRoomTopicsResponse || {}

  let scrollRef = useRef(null)

  const scroll = (forwards, topic) => {
    // const numberOfItems = scheduledRoomsData && scheduledRoomsData.length
    // let scrollRight = Math.ceil(scrollRef.current.offsetWidth/numberOfItems) + scrollRef.current.scrollLeft
    // let scrollLeft = Math.ceil(scrollRef.current.offsetWidth/numberOfItems) - scrollRef.current.scrollLeft
    const target = document.getElementById(`${topic.id}_scroll_target_id`)
    target.scrollTo({
      top: 0,
      left: forwards ? target.scrollLeft + 425 : target.scrollLeft - 425,
      behavior: "smooth"
    })
    console.log(target.scrollLeft)
  }

  return (
    <MainWrap className="container">
      {isFetching ? (
        <>
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
          <ShimmerBox w="310px" h="230px" />
        </>
      ) : (
        <>
          {RoomTopicData && RoomTopicData.map(topic => (
            <AnchorWrap
              id={createID(topic)}
            >
              <HeaderWrap>
                <Header>
                  {topic.topic_name}
                </Header>
                <ButtonWrap>
                  <button
                    type="button"
                    style={{ background: "none", border: "none" }}
                    onClick={() => scroll(false, topic)}
                  >
                    <img src={LeftArrow} alt="LEFT ARROW" />
                  </button>
                  <button
                    type="button"
                    style={{ background: "none", border: "none" }}
                    onClick={() => scroll(true, topic)}
                  >
                    <img src={RightArrow} alt="RIGHT ARROW" />
                  </button>
                </ButtonWrap>
              </HeaderWrap>
              <Wrap>
                <EventList
                  id={topic.id}
                  sortedEvents={sortedEvents && sortedEvents[`${topic.topic_name.split(" ").join("_")}`]}
                  topic={topic.topic_name}
                />
              </Wrap>
            </AnchorWrap>
          ))}
        </>
      )}
    </MainWrap>
  )
}

export default RoomsContainer(MainCarousel)
