import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getPropertyTypesAction,
  addBuyerNeedsAction,
  openEditProfileModalAction,
  removeBuyerNeedsAction,
  getPropertyStatusAction,
  uploadPropertyImagesAction,
  saveAddListingAction,
  closeEditProfileModalAction,
  clearPropertyImagesAction,
} from "container/Profile/action"
import {
  toggleSignAgreementModalAction,
} from "container/App/actions"
import {
  toggleArchiveModalAction,
  fetchFilterSkeletonAction,
  getTeamMembersAction,
} from "container/Referral/actions"
import { archiveReferralAction } from "container/Client/actions"
import { processBuyerMatches, processTimeline } from "./selectors"
import * as Actions from "./actions"

function mapStateToProps(state) {
  const { clientProfile, profile, referral, client, app } = state
  const { isShowSignAgreementModal } = app
  const {
    filterSkeleton,
    isShowArchiveModal,
    assignAgentKeys,
  } = referral
  const {
    isReferralCardBusy,
    clientResponse,
  } = client
  const {
    referralType,
  } = clientResponse || {}
  const {
    propertyColorCodes,
    clientTypes,
    clientBuyerChecklist,
    clientSellerChecklist,
    statusChangeReasonMap,
  } = filterSkeleton || {}
  const { timeline } = clientProfile
  const parsedTimeline = processTimeline(timeline)
  const { buyerMatches } = clientProfile
  const matches = processBuyerMatches(buyerMatches)
  const {
    preFetchedTypes,
    preFetchedStatus,
    isShowEditModal,
    isSavingUpdates,
    isUploadingPropertyImages,
    propertyImages,
    initialValuesForm,
  } = profile
  return {
    clientResponse,
    ...clientProfile,
    propertyColorCodes,
    statusColors: clientTypes,
    clientTypes,
    isShowEditModal,
    isSavingUpdates,
    preFetchedTypes,
    preFetchedStatus,
    isUploadingPropertyImages,
    propertyImages,
    clientBuyerChecklist,
    clientSellerChecklist,
    statusChangeReasonMap,
    matches,
    parsedTimeline,
    isShowArchiveModal,
    isReferralCardBusy,
    initialValuesForm,
    referralType,
    assignAgentKeys,
    isShowSignAgreementModal,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchClientSearchCriteria: Actions.fetchClientSearchCriteriaAction.request,
      fetchClientReminder: Actions.fetchClientReminderAction.request,
      fetchClientProperties: Actions.fetchClientPropertiesAction.request,
      fetchClientPropertiesDetails: Actions.fetchClientPropertiesDetailsActions.request,
      getPropertyStatus: getPropertyStatusAction.request,
      setReminderAction: Actions.setReminderAction.request,
      saveAddListing: saveAddListingAction.request,
      fetchClientTimeline: Actions.fetchClientTimelineAction.request,
      uploadPropertyImages: uploadPropertyImagesAction.request,
      fetchFilterSkeleton: fetchFilterSkeletonAction.request,
      getPropertyTypes: getPropertyTypesAction.request,
      addBuyerNeeds: addBuyerNeedsAction.request,
      openEditProfileModal: openEditProfileModalAction,
      closeEditProfileModal: closeEditProfileModalAction,
      removeBuyerNeeds: removeBuyerNeedsAction.request,
      fetchClientNotes: Actions.fetchClientNotesAction.request,
      addClientNote: Actions.addClientNoteAction.request,
      updateClientNote: Actions.updateClientNoteAction.request,
      deleteClientNote: Actions.deleteClientNoteAction.request,
      updateChecklist: Actions.updateChecklistAction.request,
      updateSalesStage: Actions.updateSalesStageAction.request,
      addAdditionalContact: Actions.addContactAction.request,
      fetchContactInfo: Actions.fetchContactInfoAction.request,
      updateAdditionalContact: Actions.updateContactAction.request,
      fetchBuyerMatches: Actions.fetchBuyerMatchesAction.request,
      openAddEditNoteModal: Actions.openAddEditNoteModalAction.call,
      openUpdateNoteModal: Actions.openUpdateNoteModalAction.call,
      openDeleteConfirmation: Actions.openDeleteConfirmationAction.call,
      toggleReminderDropdown: Actions.toggleReminderDropdownAction.call,
      toggleReminderEditModal: Actions.toggleReminderEditModalAction.call,
      deleteReminder: Actions.deleteReminderAction.request,
      clearTimeline: Actions.fetchClientTimelineAction.clear,
      archiveReferral: archiveReferralAction,
      toggleArchiveModal: toggleArchiveModalAction,
      fetchEditProperty: Actions.fetchEditPropertyAction.request,
      toggleEditPropertyModal: Actions.toggleEditPropertyModalAction.call,
      getTeamMembers: getTeamMembersAction.request,
      toggleRequestModal: Actions.toggleSendRequestModalAction.call,
      requestUpdate: Actions.sendRequestPayloadAction.request,
      toggleSignAgreementModal: toggleSignAgreementModalAction.call,
      getPreferredLender: Actions.getPreferredLenderAction.request,
      updatePreferredLender: Actions.updatePreferredLenderAction.request,
      toggleUpdateModal: Actions.togglePreferedLenderModalAction.call,
      clearPropertyImages: clearPropertyImagesAction.call,
      toggleEditInfoModal: Actions.toogleEditInfoModalAction.call,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
