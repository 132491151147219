/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react"
import Listings from "container/Listings"
import ListingsV2 from "container/ListingsV2"
import root from "window-or-global"
import TechAssets from "container/TechAssets"
import ContractDetails from "dumbComponents/common/ContractDetailsForm"
import { ContractCard } from "dumbComponents/common/ListingsCommon"
import { withRouter } from "react-router-dom"
import ComplianceSection from "dumbComponents/Compliance"
import queryString from "query-string"
import {
  ListingsWrap,
  MenuWrap,
  ListingDetailsDiv,
  MenuUiWrap
} from "./styles"
//import Tabs from "dumbComponents/ListingsV2/Components/Tabs"
import {
  Tabs,
  HeaderSectionCommon,
} from "./components"

const STEPS = [
    {
      value: "contract_details",
      label: "Contract Details",
    },
    {
      value: "compliance",
      label: "Compliance",
    },
  ]

const ContractsTabs = ({
    currentContractsTab = "contract_details",
    setCurrentContractsTab,
}) => {

  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  useEffect(() => {
    
    
  }, [])


  

  return (
    <>
    <MenuUiWrap>
      <Tabs
        steps={STEPS}
        toggleTab={currentTab => setCurrentContractsTab(currentTab)}
        currentTab={currentContractsTab}
        //tabWidth="35%"
      />
      {currentContractsTab === "contract_details" && (
        <ContractDetails />
      )}
      {currentContractsTab === "compliance" && (
       <ComplianceSection />
      )}
    </MenuUiWrap>
    </>
  )
}

export default TechAssets(withRouter((Listings(ContractsTabs))))
