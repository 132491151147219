import React from "react"
import styled from "styled-components"
import TechAssets from "container/TechAssets"
import { Table } from "@ui/antd"
import { ShimmerBox } from "shared/styles/animation"
import { ATC } from "@fonts"
import colors from "@colors"

const Wrap = styled.div`
`

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: none;
    border-bottom: none;
    color: ${colors.black};
    font-family: ${ATC};
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
`

const Title = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black};
  margin: 0;
`

const Description = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  margin: 0;
`

const Rate = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3B60E4;
  margin: 0;
`

const TableContent = ({
  todaysPriceResponse,
}) => {
  const { data: todaysRateData, isFetching } = todaysPriceResponse || {}
  const COLUMNS = [
    {
      width: 100,
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        if (isFetching) {
          return (
            <ShimmerBox
              w="75px"
              h="50px"
            />
          )
        }
        return (
          <Title>
            {record.title}
          </Title>
        )
      },
    },
    {
      width: 200,
      dataIndex: "description",
      key: "description",
      render: (_, record) => {
        if (isFetching) {
          return (
            <ShimmerBox
              w="200px"
              h="50px"
            />
          )
        }
        return (
          <Description>
            {record.description}
          </Description>
        )
      },
    },
    {
      title: "Rate",
      width: 50,
      dataIndex: "rate",
      key: "rate",
      render: (_, record) => {
        if (isFetching) {
          return (
            <ShimmerBox
              w="50px"
              h="50px"
            />
          )
        }
        return (
          <Rate>
            {`${record.rate}%`}
          </Rate>
        )
      },
    },
    {
      title: "APR",
      width: 50,
      dataIndex: "apr",
      key: "apr",
      render: (_, record) => {
        if (isFetching) {
          return (
            <ShimmerBox
              w="50px"
              h="50px"
            />
          )
        }
        return (
          <Rate>
            {`${record.apr}%`}
          </Rate>
        )
      },
    },
  ]
  return (
    <Wrap>
      <CustomTable
        // loading={{ indicator: <ShimmerBox w="468px" h="650px" />, spinning: isFetching || false }}
        columns={COLUMNS}
        dataSource={todaysRateData}
        pagination={false}
      />
    </Wrap>
  )
}

export default TechAssets(TableContent)
