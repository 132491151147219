import React from "react"
import styled, { keyframes } from "styled-components"
import root from "window-or-global"
import CircularImage from "dumbComponents/common/CircularImage"
import RARPopUpContainer from "container/App/RARPopUpContainer"
import { getReferralManager, getPublicISACalendlyLinks, getOS } from "services/Utils"
import { authCheck } from "services/auth"
import queryString from "query-string"
import colors from "@colors"
import icons from "@icons"
import ASMChat from "./ASMChat"

const showAnimation = keyframes`
  0% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }

  100% {
    height: 340px;
    width: 300px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
`

const showAnimationFlip = keyframes`
  0% {
    height: 340px;
    width: 300px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
  100% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }


`

const HelloWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  overflow: hidden;
  width: 100%;
  opacity: 1;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;

  ${props => props.isOpen && `
    width: 0%;
    opacity: 0;
    height: 0px;
  `}

  span {
    margin-left: 5px;
  }

  ${props => props.isISA && `
    line-height: 1.5;
    text-align: left;
  `}
`

const StyledAnchor = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  background: ${colors.white};
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  z-index:9999;

  ${props => props.isOpen ? `
    animation: ${showAnimation} 0.3s ease forwards;
  ` : `
    animation: ${showAnimationFlip} 0s ease forwards;
    display: none;
  `}


  /* ${props => props.isISA && `
    width: 320px !important;
  `} */
  /* ${props => props.isOpen && props.isISA && `
    height: 340px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 4px;
    cursor: inherit;
    width: 310px !important;
    background: yellow;
  `}

   ${props => props.isOpen && !props.isISA && `
      height: 340px;
      width: 350px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      border-radius: 5px;
      cursor: inherit;
   `} */
`

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 28.5px;
  border: 2px solid #2bda8e;
  color: #262d35;
  transition-duration: 0.5s;
  padding: 6px;

  ${props => props.isOpen && `
    border-radius: 4px;
    border: none;
    margin-top: 10px;
  `}
`

const MessageWrap = styled.div`
  width: 220px;
  margin: 20px auto;
  text-align: center;
  min-height: 100px;
  p {
    margin: 6px 0px 0px 0px;
    padding: 0px;
    color: ${colors.contentText};

    &:nth-child(1) {
      font-weight: 100;
    }

    &:nth-child(2) {
      font-weight: 500;
    }

    &:nth-child(3) {
      font-weight: 300;
    }
  }

  a {
    margin-top: 10px;
    color: #333;
    display: block;
  }
  ${props => props.isQualified && `
        margin: 39px auto
    `}
`

const ControllWrap = styled.div`
  display: flex;
  flex-direction: column;

  a, button  {
    font-size: 18px;
    width: 100%;
    display: block;
    border: none;
    padding: 12px;
    text-decoration: none;
    font-weight: 100;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #333;
  }
`

const CommunicationWrap = styled.div`
  display: flex;
  a, button  {
    border: 1px solid #CCC;
  }
 button {
  border-right: none !important;
 }

`

const BookADemoDiv = styled.div``

const CloseIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 16px;
  color: #CCC;
  display: none;

  ${props => props.isOpen && `
    display: inherit;
  `}
`

const BookADemo = styled.a`
  background: ${colors.primaryColor};
  color: ${colors.white} !important;
`

const ISAText = styled.div`
 p {
   font-weight: 300 !important;

   strong {
     font-weight: 500 !important;
   }
 }
`

const FoldedView = styled.div`
  width: 60px;
  height: 60px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 10px;
  position: fixed;
  background: center no-repeat;
  background-size: cover;
  right: 20px;
  bottom: 20px;
  border: 4px solid ${colors.white};
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  background-color: #3C4857;
`

// const Content = ({ isOpen, title, type }) => (
//   <HelloWrap isOpen={isOpen} isISA={type === "isa"}>
//     {title}
//     {type !== "isa" && (
//       <span role="img" aria-label="message to referral manager">
//         👋
//       </span>
//     )}
//   </HelloWrap>
// )

class ReferralManagerContact extends React.Component {
  constructor(props) {
    super(props)
    const {
      isOpen = false,
      type,
    } = props
    this.state = {
      isOpen,
      isMounted: false,
      type,
      source: "web",
    }
  }

  componentDidMount() {
    const currentOS = getOS()
    this.setState({
      source: currentOS !== "browser" ? "mobile" : root.location.search && queryString.parse(root.location.search).src || "web",
    })
    this.setState({
      isMounted: true,
    })

    const {
      toggleISAPopupOpen,
      isISAPopUpOpen,
    } = this.props

    setTimeout(() => {
      if (root.fcWidget) {
        root.fcWidget.on("widget:closed", () => {
          root.fcWidget.hide()
        })
      }
    }, 2000)

    if (!authCheck()) {
      setTimeout(() => {
        this.setState({
          isMounted: true,
        })
        toggleISAPopupOpen(!isISAPopUpOpen)
      }, 4000)
    }
  }

  toggleOpen = () => {
    const {
      toggleISAPopupOpen,
      isISAPopUpOpen,
    } = this.props

    toggleISAPopupOpen(!isISAPopUpOpen)
  }

  handleChatClick = () => {
    this.setState({
      isOpen: false,
    })
    root.fcWidget.show()
    root.fcWidget.open()
  }

  getBookADemoLink = (isLoggedIn, referralManager) => {
    const { source } = this.state
    if (!isLoggedIn) {
      if (source === "mobile") {
        return "https://meetings.hubspot.com/radiusagent/radius-assist-isa-interest-mlp"
      }
      if (source === "wcr") {
        return "https://meetings.hubspot.com/radiusagent/radius-assist-interest-wcr"
      }
      return "https://meetings.hubspot.com/radiusagent/radius-assist-isa-interest-lp"
    }
    const { type } = this.props
    const { calendly_links } = referralManager
    if (type === "qualified" || type === "isa") {
      const {
        isa_web,
        isa_mobile,
      } = calendly_links || {}
      return source === "mobile" ? isa_mobile
        : source !== "web" && source !== "mobile" ? getPublicISACalendlyLinks()[`isa_${source}`] : isa_web
    }

    if (type === "concierge") {
      const { concierge_web, concierge_mobile } = calendly_links || {}
      return source === "mobile" ? concierge_mobile : concierge_web
    }
    return "https://meetings.hubspot.com/radiusagent/radius-assist-isa-interest-lp"
  }

  render() {
    const { isOpen, isMounted } = this.state
    const {
      type,
      isTeam,
      isISAPopUpOpen,
    } = this.props
    let referralManager = null
    if (type === "isa") {
      // This is for logged in state for Radius Assist
      referralManager = getReferralManager(true, "isa")
    } else {
      // This is for other pages.
      referralManager = getReferralManager(false, type)
    }
    const {
      photo,
      firstname,
      lastname,
      phone,
      email,
      title,
    } = referralManager || {}

    if (!isMounted) {
      return null
    }
    return (
      <React.Fragment>
        <FoldedView
          style={{ backgroundImage: `url(${photo})` }}
          onClick={this.toggleOpen}
        />
        <StyledAnchor
          onClick={this.toggleOpen}
          isOpen={isISAPopUpOpen}
          isISA={type === "isa" || type === "concierge"}
        >
          <CloseIcon onClick={this.toggleOpen} isOpen={isISAPopUpOpen}>
            <i className={icons.arrowsClose}></i>
          </CloseIcon>
          <StyledButton isOpen={isISAPopUpOpen} isISA={type === "isa"}>
            {/* <SupportImage style={{ backgroundImage: `url(${photo})` }} /> */}
            <CircularImage
              image={photo}
              size={isOpen ? 92 : 40}
              name={`${firstname} ${lastname || ""}`}
              alt="referral manager"
              style={{
                transitionDuration: "0.3s",
                backgroundPosition: "center",
              }}
            />
            {/* <Content
              isOpen={isOpen}
              title="Hi! How can I help you?"
              type={type}
            /> */}
          </StyledButton>
          <MessageWrap
            isQualified={type === "qualified"}
          >
            <ISAText>
              <p>
                {firstname === "Austin" ? "Hi, my name is Austin, How can I help you?" : "Hi, how can I help you today?"}

              </p>
            </ISAText>
            {(phone && type !== "qualified") && (
              <a href={`tel:${phone}`}>{phone || ""}</a>
            )}
          </MessageWrap>
          <ControllWrap>
            <CommunicationWrap d="flex">
              <button onClick={this.handleChatClick} type="button">
                Chat
              </button>
              <a href={`mailto:${email}`}>
                Email
              </a>
            </CommunicationWrap>
            {(type !== "qualified") && (
              <BookADemoDiv>
                <BookADemo
                  href={this.getBookADemoLink(authCheck(), referralManager) || "https://meetings.hubspot.com/radiusagent/radius-assist-isa-interest-lp"}
                  target="_blank"
                >
                  {(type === "concierge" || isTeam
                  ) ? "Schedule a call" : "Book a Demo"}
                </BookADemo>
              </BookADemoDiv>
            )
            }
          </ControllWrap>
          <ASMChat referralManager={referralManager} />
        </StyledAnchor>
      </React.Fragment>

    )
  }
}

export default RARPopUpContainer(ReferralManagerContact)
