import React from "react"
import {
  OffersIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import Listings from "container/Listings"
import root from "window-or-global"
import { enabledFeatureCheck } from "services/Utils"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Offers from "../Icons/NewIcons/Offers"

const SubmitListingsTab = ({
  routeArray,
  isBroker,
  clearExistingData: clearListingData,
}) => (
  <InnerTab isActive={routeArray[1] === "offers"}>
    <div className="icon-block-new">
      <Offers />
    </div>
    <div className="text-block">
      <NavLink
        to={enabledFeatureCheck({ isBroker, tabName: "transaction_management_offer" }) ? "/realty/offers" : "/realty/promos?type=offers"}
        isActive={routeArray[1] === "offers"}
        onClick={() => {
          root.scrollTo(0, 0)
          clearListingData()
          if (root.heap) {
            root.heap.track("click_sidebar_productAndServicesRAR_web")
          }
        }}
      >
        My Offers
        {/* <BetaHighlight>
          Beta
        </BetaHighlight> */}
        {/* {CookiesStorage.load("offers") && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )} */}
      </NavLink>
    </div>
  </InnerTab>
)

export default Listings(SubmitListingsTab)
