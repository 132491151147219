import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div`
display: flex;
width: 18px;
height: 18px;
padding: 2px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: ${INTER};
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 141%; `