import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchListingPageDetailsAction,
  handleRequestATourAction,
} from "./actions";
import { fetchListingPageAPI, postRequestTourInfoAPI } from "./api";
import { isSuccess } from "services/Utils";
import { toast as createToast } from "react-toastify";

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
};

function* fetchListingPageInfo(action) {
  try {
    const { MLS_ID, Property_id } = action.data;
    const data = yield call(fetchListingPageAPI, MLS_ID, Property_id);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchListingPageDetailsAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    createToast.error(errorMessage, toastConfig);
    yield put(fetchListingPageDetailsAction.failure(error.message));
  }
}

function* handleRequestATour(action) {
  try {
    const payload = action.data;
    const res = yield call(postRequestTourInfoAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;

      console.log(response);
      yield put(handleRequestATourAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Unable to send data!";
    createToast.error(errorMessage, toastConfig);
    yield put(handleRequestATourAction.failure(error));
  }
}

export default function* main() {
  yield takeLatest(fetchListingPageDetailsAction.REQUEST, fetchListingPageInfo);
  yield takeLatest(handleRequestATourAction.REQUEST, handleRequestATour);
}
