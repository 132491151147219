import React from "react"
import { reduxForm, Field } from "redux-form"
import styled from "styled-components"
import colors from "@colors"
import { get } from "lodash"
import { authCheck } from "services/auth"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { preTabletWidth, minTabletWidth, preDesktopWidth } from "@consts"
import {
  Input,
  PhoneInput,
  LocationAutoComplete,
} from "@ui/Form"
import {
  required,
  email,
  normalizePhone,
  minLength,
} from "services/formUtils"
import Button from "@ui/Button"

const minLength10 = minLength(10)

const PADDING = "20px"

const US_OBJ = {
  lat: 37.09024,
  lng: -95.712891,
  south_west: {
    lat: 25.82,
    lng: -124.39,
  },
  north_east: {
    lat: 49.38,
    lng: -66.94,
  },
  address: "United States",
  country: {
    long_name: "United States",
    short_name: "US",
    types: [
      "country",
      "political",
    ],
  },
}

const FormWrap = styled.form``

const Footer = styled.div`
  display: flex;
  padding: 10px ${PADDING};
  justify-content: flex-end;

  button {
    height: 48px;
    width: 327px;
    font-size: 16px;
    border-radius: 100px;
    padding: 16px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    background: ${colors.whiteBlueFaded};
    color: ${colors.black};

    ${props => props.inPage && `
      color: ${colors.white};
      margin: 0 auto;
      background: ${colors.darkRoyalePurple};
    `}


    &:disabled {
      cursor: not-allowed;
      color: ${colors.transparentWhite};
      background: ${colors.transaprentYolkWhite};
    }

    transition: background 0.3s linear;
  }
`

const InputWrap = styled.div`
  position: relative;
  margin-bottom: 12px;
  z-index: 10;
    label {
     input {
      border-radius: 10px;
      border: 1px solid ${colors.darkPurple};
    }
    > span {
      font-size: 16px;
      color: ${colors.white};
      font-weight: 700;
      background: none;
      margin-bottom: 10px;

      ${props => props.inPage && `
        color: ${colors.black};
        `}
    }
    & ~ span {
      position: absolute;
      bottom: -15px;
      right: 0px;
    }
  }

  > div {
    /* For Location */
    > label {
      font-size: 16px;
      color: ${colors.white};
      font-weight: 700;
      background: none;
      margin-bottom: 10px;

      ${props => props.inPage && `
        color: ${colors.black};
        `}
    }
    > div {
      border-radius: 10px;
      border: 1px solid ${colors.darkPurple};

      div[class$="-menu"] {
        background: ${colors.white};
      }
      input {
        border: none;
        background: transparent;
        & ~ span {
          border: none;
        }
      }

      > div {
        background: none;
        border-color: transparent;
      }
    }
  }
  /* For Location */
  .pac-target-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: white;
  }

  input {
    border-radius: 10px;
    background: white;
    color: black;
  }
  /* For Location */

`

const FormDiv = styled.div`
  padding: 10px;
  z-index: 5;
  height: 0px;
  opacity: 0;
  width: 375px;
  border-radius: 24px;
  padding: 25px 50px 25px 50px;
  position: fixed;
  background: ${colors.darkPurple};
  right: 20px;
  bottom: 80px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;

  ${preDesktopWidth`
    right: 0px;
    padding:5px 22px;
  `}


  ${props => props.isOpenForm && `
    opacity: 1;
    height: 491px;
  `}

  ${props => !props.isOpenForm && `
    height: 0px;
  `}

  ${props => props.inPage && `
    position: static;
    background: ${colors.white};
    height: max-content;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;

    ${preTabletWidth`
      width: 85vw;
      max-width: 496px;
    `}

    ${minTabletWidth`
      width: 496px;
    `}
  `}

  transition: height 0.5s;
`

export const SaveButton = ({ children, isShowLoader, ...props }) => (
  <Button {...props}>
    { isShowLoader
      ? <ThreeDotLoader color="#FFF" />
      : children
    }
  </Button>
)

const ErrorText = styled.span`
  color: ${colors.red};
  font-size: 12px;
  position: relative;
  bottom: 12px;
`

const SubText = styled.p`
  font-family: DM Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
  margin-bottom: 30px;
`

const HeaderText = styled.h1`
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  color: ${colors.black};
`

class RealtyModalForm extends React.Component {
  componentDidMount() {
    const {
      initialize,
      user,
    } = this.props
    const isLogged = authCheck()

    if (isLogged && user && Object.keys(user).length > 0) {
      initialize({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        email: user.email || user.primary_email || "",
        phone: user.phone && normalizePhone(user.phone) || "",
      })
    }
  }

  handleAddressChange = (address) => {
    const {
      change,
      setCurrentLocationForRealty,
    } = this.props
    const state = get(address, "administrative_area_level_1.long_name")
    const city = get(address, "locality.long_name")
    change("city", city)
    change("state", state)
    setCurrentLocationForRealty({
      currentCity: city || "",
      currentState: state || "",
    })
  }

  render() {
    const {
      handleSubmit,
      isOpenForm,
      formError,
      inPage,
      fetchingData,
    } = this.props

    return (
      <FormDiv isOpenForm={isOpenForm} inPage={inPage}>
        <HeaderText>
          Request Info
        </HeaderText>
        <SubText>
          We’re currently operating in California, Colorado, Florida, Washington, Oregon, and Georgia and working on expanding nationally!
        </SubText>
        <FormWrap p="0px 25px 25px 25px" onSubmit={handleSubmit}>
          <InputWrap inPage={inPage}>
            <Field
              name="firstname"
              component={Input}
              label="First Name*"
              placeholder="First Name"
              validate={[required]}
            />
          </InputWrap>
          <InputWrap inPage={inPage}>
            <Field
              name="lastname"
              component={Input}
              label="Last Name *"
              placeholder="Last Name"
              validate={[required]}
            />
          </InputWrap>
          <InputWrap inPage={inPage}>
            <Field
              name="city"
              component={LocationAutoComplete}
              placeholder="City"
              label="City *"
              validate={[required]}
              location={(addressVal) => { this.handleAddressChange(addressVal) }}
              restrict={US_OBJ}
              types={["(cities)"]}
            />
          </InputWrap>
          {formError && (
            <ErrorText>Invalid Location</ErrorText>
          )}
          <InputWrap inPage={inPage}>
            <Field
              name="email"
              component={Input}
              label="Email *"
              placeholder="Email"
              validate={[required, email]}
            />
          </InputWrap>
          <InputWrap inPage={inPage}>
            <Field
              name="phone"
              component={PhoneInput}
              isMaterial={false}
              isShowAreaCode={false}
              placeholder="Phone"
              label="Phone *"
              normalize={normalizePhone}
              validate={[required, minLength10]}
            />
          </InputWrap>
          <Footer inPage={inPage} d="flex" jc="flex-end">
            <button
              width="100px"
              type="submit"
            >
              {fetchingData ? (
                <ThreeDotLoader color="#FFF" />
              ) : (
                <p>Submit</p>
              )}
            </button>
          </Footer>
        </FormWrap>
      </FormDiv>
    )
  }
}

export default reduxForm({
  form: "REALTY_MODAL_FORM",
})(RealtyModalForm)
