import React from "react"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import styled from "styled-components"
import icons from "@icons"
import Like from "dumbComponents/common/StyledComponents/Like"
import colors from "@colors"
import { saveUserObjectToCookie } from "services/Utils"
import UpvoteIcon from "dumbComponents/Network/MiddleContainer/common/UpvoteIcon"
import SeeMore from "@ui/SeeMorePara"
import ShareButton from "./common/ShareButton"

const FeedActionWrapper = styled.div`
  border: 1px solid ${colors.disabledInput};
  border-top: none;
  padding: 5px 25px 5px 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  position: relative;
  animation: fade-in-fwd 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  min-height: 60px;
  z-index: 1;
  ${props => !props.isStandAlone
    && `
      margin-bottom: 20px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: none;
      border-left: 1px solid #e6e8e9;
      border-right: 1px solid #e6e8e9;
    `}
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateY(20px);
      transform: translateY(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${props => props.isAcademyFeed && `
    background: ${colors.feedHighlight};
  `}
`

const StyledSpan = styled.span`
  font-size: 12px;
  margin-left: 10px;
`

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: ${props => (props.likedByMe ? colors.primaryColor : colors.contentText)};
`

const ShareText = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
`

const Upvote = styled.div`
  margin-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
  }
`

class FeedAction extends React.Component {
  componentDidMount() {
    this.likeEle = document.getElementById("radLike")
    if (this.likeEle) {
      this.likeEle.addEventListener("click", this.onLike)
      this.likeEle.addEventListener("animationend", this.onAnimationEnd)
    }
  }

  componentWillUnmount() {
    if (this.likeEle) {
      this.likeEle.removeEventListener("click", this.onLike)
      this.likeEle.removeEventListener("animationend", this.onAnimationEnd)
    }
  }

  triggerFeedDetailView = (selectedFeed, focus = false) => {
    try {
      const { openFeedDetailView, history } = this.props
      const agentName = get(selectedFeed, "agent.firstname")
      const urlTitle = selectedFeed.title && selectedFeed.title.substr(0, 50).replace(/%/g, '')
      const redirectURL = root.encodeURI(`/${agentName}/feed/${selectedFeed.id}/${urlTitle}`)
      openFeedDetailView({
        selectedFeed,
        feedSource: "networkFeeds",
        shouldAutoFocus: focus,
      })
      history.push(redirectURL)
    } catch (error) {
      console.log(error)
    }
  }

  handleLogin = () => {
    saveUserObjectToCookie({ redirectUrl: root.location.href })
    root.location.href = `${WEB_URL}login`
  }

  handleCommentClick = () => {
    const { isLogged, feed, isStandAlone } = this.props
    if (isLogged) {
      root.localStorage.setItem("community-last-scroll", root.scrollY)
      this.triggerFeedDetailView(feed, true)
    } else {
      this.handleLogin()
    }
    if (isStandAlone) {
      root.document.getElementById("standalone-feed-text-area").focus()
    }
  }

  render() {
    const {
      feed,
      updateFeedLike,
      feedsData,
      isLikingFeed,
      isFeedDetailView,
      isLogged,
      updateAutoFocus,
      isAcademyFeed,
      isStandAlone,
      isOnProfile,
    } = this.props
    const {
      feed_url,
      commentsCount = "",
      likesCount = "",
    } = feed
    return (
      <FeedActionWrapper
        isFeedDetailView={isFeedDetailView}
        isAcademyFeed={isAcademyFeed}
        isStandAlone={isStandAlone}
      >
        {(feed.type === "post" || feed.type === "intro" || isAcademyFeed || true) && (
          <ActionItem
            onClick={this.handleCommentClick}
          >
            <i className={icons.interfaceMessageTyping} />
            <StyledSpan>{(commentsCount > 0 && !isStandAlone) && commentsCount}</StyledSpan>
          </ActionItem>
        )}
        {/* {feed.type === "question" && (
          <ActionItem
            onClick={() => isFeedDetailView ? updateAutoFocus({ shouldAutoFocus: true }) : this.triggerFeedDetailView(feed, true)
            }
          >
            <i className={icons.filesUpdateEditFile} />
            <StyledSpan>Answer</StyledSpan>
          </ActionItem>
        )} */}
        <ActionItem
          onClick={() => (isLogged ? updateFeedLike({ id: feed.id, likedByMe: feed.likedByMe, type: feed.type, agentFeeds: feedsData, isOnProfile }) : this.handleLogin())}
          likedByMe={feed.likedByMe}
        >
          {(feed.type === "post" || feed.type === "academy" || feed.type === "intro" || feed.type === "question" || feed.type === "referral") && (
            <React.Fragment>
              <Like
                id="radLike"
                animating={isLikingFeed === feed.id && feed.likedByMe}
                likedByMe={feed.likedByMe}
                ml="-50px"
              />
            </React.Fragment>
          )}
          {/*feed.type === "question" && (
            <Upvote likedByMe={feed.likedByMe}>
              <UpvoteIcon isActive={feed.likedByMe} />
            </Upvote>
          )*/}
          {!isStandAlone && (
            <StyledSpan style={{ position: "relative", left: "-10px" }}>{likesCount > 0 && likesCount}</StyledSpan>
          )}
        </ActionItem>
        <ActionItem>
          <ShareButton url={`${feed_url}`} />
          <ShareText>
            Share
          </ShareText>
        </ActionItem>
      </FeedActionWrapper>
    )
  }
}

export default withRouter(FeedAction)
