import styled from "styled-components"
import icomoonEot from "./icomoon.eot"
import icomoonTTF from "./icomoon.ttf"
import icomoonWOFF from "./icomoon.woff"
import icomoonSVG from "./icomoon.svg"
const StyledIcons = styled.div`
@font-face {
    font-family: 'icomoon';
  src:  url('${icomoonEot}');
  src:  url('${icomoonEot}?4g8hk4#iefix') format('embedded-opentype'),
    url('${icomoonTTF}?4g8hk4') format('truetype'),
    url('${icomoonWOFF}?4g8hk4') format('woff'),
    url('${icomoonSVG}?4g8hk4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-icon-generic-More-menu:before {
    content: "\\e900";
  }
  .icon-icon-transportation-Boat:before {
    content: "\\e90a";
  }
  .icon-icon-transportation-Car:before {
    content: "\\e90c";
  }
  .icon-icon-interface-Double-tick:before {
    content: "\\e914";
  }
  .icon-icon-interface-Horizontal:before {
    content: "\\e917";
  }
  .icon-icon-interface-Infinity:before {
    content: "\\e91c";
  }
  .icon-icon-interface-Switch-button:before {
    content: "\\e91d";
  }
  .icon-icon-generic-Visible:before {
    content: "\\e91e";
  }
  .icon-icon-arrows-send-receive:before {
    content: "\\e92c";
  }
  .icon-icon-business-Bar-graph:before {
    content: "\\e930";
  }
  .icon-icon-arrows-Compress:before {
    content: "\\e935";
  }
  .icon-icon-arrows-Converge:before {
    content: "\\ea0b";
  }
  .icon-icon-arrows-Expand-down:before {
    content: '\\ea1b';
  }
  .icon-icon-arrows-Expand-up:before {
    content: "\\ea28";
  }
  .icon-icon-arrows-File-redirect:before {
    content: "\\ea2a";
  }
  .icon-icon-arrows-Horizontal-scroll:before {
    content: "\\ea43";
  }
  .icon-icon-arrows-Keyboard-left:before {
    content: "\\ea58";
  }
  .icon-icon-arrows-Keyboard-right:before {
    content: "\\ea5b";
  }
  .icon-icon-arrows-Stats:before {
    content: "\\ea8c";
  }
  .icon-icon-arrows-Expand-up1:before {
    content: "\\ea8d";
  }
  .icon-generic-Share-round:before {
    content: "\\ec68";
  }
  .icon-generic-Share1:before {
    content: "\\ea8b";
  }
  .icon-generic-Share-round1:before {
    content: "\\ea89";
  }
  .icon-arrows-Close-fill:before {
    content: "\\ea8a";
  }
  .icon-generic-Facebook:before {
    content: "\\ea83";
  }
  .icon-generic-IG:before {
    content: "\\ea84";
  }
  .icon-generic-LinkedIn:before {
    content: "\\ea85";
  }
  .icon-generic-Star-active:before {
    content: "\\ea86";
  }
  .icon-generic-Star1:before {
    content: "\\ea87";
  }
  .icon-generic-Twitter:before {
    content: "\\ea88";
  }
  .icon-arrows--Close:before {
    content: "\\e901";
  }
  .icon-arrows-Arroe-merge:before {
    content: "\\e902";
  }
  .icon-arrows-Arrow-diverge:before {
    content: "\\e903";
  }
  .icon-arrows-Arrow-divert:before {
    content: "\\e904";
  }
  .icon-arrows-Arrow-forward:before {
    content: "\\e905";
  }
  .icon-arrows-Arrow-relevance:before {
    content: "\\e906";
  }
  .icon-arrows-Arrow-right:before {
    content: "\\e907";
  }
  .icon-arrows-Arrow-send-receive:before {
    content: "\\e908";
  }
  .icon-arrows-Arrow-up:before {
    content: "\\e909";
  }
  .icon-arrows-Controller:before {
    content: "\\e90b";
  }
  .icon-arrows-Diverge:before {
    content: "\\e90d";
  }
  .icon-arrows-double-sided-arrow:before {
    content: "\\e90e";
  }
  .icon-arrows-Download:before {
    content: "\\e90f";
  }
  .icon-arrows-Drag:before {
    content: "\\e910";
  }
  .icon-arrows-Eject-right:before {
    content: "\\e911";
  }
  .icon-arrows-Eject:before {
    content: "\\e912";
  }
  .icon-arrows-Enlarge:before {
    content: "\\e913";
  }
  .icon-arrows-Expand:before {
    content: "\\e915";
  }
  .icon-arrows-Fast-forward:before {
    content: "\\e916";
  }
  .icon-arrows-File-refresh:before {
    content: "\\e918";
  }
  .icon-arrows-File-right-turn:before {
    content: "\\e919";
  }
  .icon-arrows-Full-screen:before {
    content: "\\e91a";
  }
  .icon-arrows-game-pad:before {
    content: "\\e91b";
  }
  .icon-arrows-Lift:before {
    content: "\\e91f";
  }
  .icon-arrows-Log-in:before {
    content: "\\e920";
  }
  .icon-arrows-Log-out:before {
    content: "\\e921";
  }
  .icon-arrows-Merge:before {
    content: "\\e922";
  }
  .icon-arrows-Next:before {
    content: "\\e923";
  }
  .icon-arrows-Refresh:before {
    content: "\\e924";
  }
  .icon-arrows-Right-arrow:before {
    content: "\\e925";
  }
  .icon-arrows-Rotation:before {
    content: "\\e926";
  }
  .icon-arrows-S-shaped-curve-arrow:before {
    content: "\\e927";
  }
  .icon-arrows-S-curved:before {
    content: "\\e928";
  }
  .icon-arrows-Screen-minimize:before {
    content: "\\e929";
  }
  .icon-arrows-Scroll-up-arrow:before {
    content: "\\e92a";
  }
  .icon-arrows-Scroll-up:before {
    content: "\\e92b";
  }
  .icon-arrows-Share:before {
    content: "\\e92d";
  }
  .icon-arrows-Shrink:before {
    content: "\\e92e";
  }
  .icon-arrows-Shuffle-arrow:before {
    content: "\\e92f";
  }
  .icon-arrows-U-turn-arrow:before {
    content: "\\e931";
  }
  .icon-arrows-Upload:before {
    content: "\\e932";
  }
  .icon-business-Auction:before {
    content: "\\e933";
  }
  .icon-business-Bank:before {
    content: "\\e934";
  }
  .icon-business-Basket:before {
    content: "\\e936";
  }
  .icon-business-Bill:before {
    content: "\\e937";
  }
  .icon-business-Briefcase:before {
    content: "\\e938";
  }
  .icon-business-Cart:before {
    content: "\\e939";
  }
  .icon-business-Cash-bag:before {
    content: "\\e93a";
  }
  .icon-business-Cash-out:before {
    content: "\\e93b";
  }
  .icon-business-Cash:before {
    content: "\\e93c";
  }
  .icon-business-Chart:before {
    content: "\\e93d";
  }
  .icon-business-Credit-card:before {
    content: "\\e93e";
  }
  .icon-business-Dollar:before {
    content: "\\e93f";
  }
  .icon-business-Give-money:before {
    content: "\\e940";
  }
  .icon-business-Pie-chart:before {
    content: "\\e941";
  }
  .icon-business-Safe-box:before {
    content: "\\e942";
  }
  .icon-business-Shop:before {
    content: "\\e943";
  }
  .icon-business-Shopping:before {
    content: "\\e944";
  }
  .icon-business-Visa-card:before {
    content: "\\e945";
  }
  .icon-business-Wallet:before {
    content: "\\e946";
  }
  .icon-communication-Antenna:before {
    content: "\\e947";
  }
  .icon-communication-Call:before {
    content: "\\e948";
  }
  .icon-communication-Calls-not-allowed:before {
    content: "\\e949";
  }
  .icon-communication-Chat-SMS:before {
    content: "\\e94a";
  }
  .icon-communication-Cloud:before {
    content: "\\e94b";
  }
  .icon-communication-Contacts:before {
    content: "\\e94c";
  }
  .icon-communication-Conversation:before {
    content: "\\e94d";
  }
  .icon-communication-Dish:before {
    content: "\\e94e";
  }
  .icon-communication-Download:before {
    content: "\\e94f";
  }
  .icon-communication-Fill-41:before {
    content: "\\e950";
  }
  .icon-communication-Hotspot:before {
    content: "\\e951";
  }
  .icon-communication-Inbox:before {
    content: "\\e952";
  }
  .icon-communication-Mail:before {
    content: "\\e953";
  }
  .icon-communication-Message-bubble:before {
    content: "\\e954";
  }
  .icon-communication-Mic:before {
    content: "\\e955";
  }
  .icon-communication-Monitor:before {
    content: "\\e956";
  }
  .icon-communication-News-paper:before {
    content: "\\e957";
  }
  .icon-communication-Phone:before {
    content: "\\e958";
  }
  .icon-communication-Printer:before {
    content: "\\e959";
  }
  .icon-communication-Radio:before {
    content: "\\e95a";
  }
  .icon-communication-Satellite:before {
    content: "\\e95b";
  }
  .icon-communication-Speaker:before {
    content: "\\e95c";
  }
  .icon-communication-System:before {
    content: "\\e95d";
  }
  .icon-communication-Upload:before {
    content: "\\e95e";
  }
  .icon-communication-Web-cam:before {
    content: "\\e95f";
  }
  .icon-electronics-Ac:before {
    content: "\\e960";
  }
  .icon-electronics-Bed-lamp:before {
    content: "\\e961";
  }
  .icon-electronics-Chimney:before {
    content: "\\e962";
  }
  .icon-electronics-Fridge:before {
    content: "\\e963";
  }
  .icon-electronics-Game-pad:before {
    content: "\\e964";
  }
  .icon-electronics-Hair-dryer:before {
    content: "\\e965";
  }
  .icon-electronics-Iron-box:before {
    content: "\\e966";
  }
  .icon-electronics-Mouse-pointer:before {
    content: "\\e967";
  }
  .icon-electronics-Oven:before {
    content: "\\e968";
  }
  .icon-electronics-Plug:before {
    content: "\\e969";
  }
  .icon-electronics-Processor:before {
    content: "\\e96a";
  }
  .icon-electronics-Server:before {
    content: "\\e96b";
  }
  .icon-electronics-Smart-watch:before {
    content: "\\e96c";
  }
  .icon-electronics-Speaker:before {
    content: "\\e96d";
  }
  .icon-electronics-Washing-machine:before {
    content: "\\e96e";
  }
  .icon-files-Add-file:before {
    content: "\\e96f";
  }
  .icon-files-Config-file:before {
    content: "\\e970";
  }
  .icon-files-Copy-file:before {
    content: "\\e971";
  }
  .icon-files-Copy-to-clipboard:before {
    content: "\\e972";
  }
  .icon-files-Download-file:before {
    content: "\\e973";
  }
  .icon-files-Duplicate-file:before {
    content: "\\e974";
  }
  .icon-files-Empty-file:before {
    content: "\\e975";
  }
  .icon-files-Encrypted-file:before {
    content: "\\e976";
  }
  .icon-files-File-add:before {
    content: "\\e977";
  }
  .icon-files-File-attach:before {
    content: "\\e978";
  }
  .icon-files-File-back:before {
    content: "\\e979";
  }
  .icon-files-File-bill:before {
    content: "\\e97a";
  }
  .icon-files-File-camera:before {
    content: "\\e97b";
  }
  .icon-files-File-caution:before {
    content: "\\e97c";
  }
  .icon-files-File-check-mark:before {
    content: "\\e97d";
  }
  .icon-files-File-checked:before {
    content: "\\e97e";
  }
  .icon-files-File-close:before {
    content: "\\e97f";
  }
  .icon-files-File-cloud:before {
    content: "\\e980";
  }
  .icon-files-File-cut:before {
    content: "\\e981";
  }
  .icon-files-File-database:before {
    content: "\\e982";
  }
  .icon-files-File-disabled:before {
    content: "\\e983";
  }
  .icon-files-File-download:before {
    content: "\\e984";
  }
  .icon-files-File-draw:before {
    content: "\\e985";
  }
  .icon-files-File-edit:before {
    content: "\\e986";
  }
  .icon-files-File-error:before {
    content: "\\e987";
  }
  .icon-files-File-folder:before {
    content: "\\e988";
  }
  .icon-files-File-heart:before {
    content: "\\e989";
  }
  .icon-files-FIle-image:before {
    content: "\\e98a";
  }
  .icon-files-File-layers:before {
    content: "\\e98b";
  }
  .icon-files-File-link:before {
    content: "\\e98c";
  }
  .icon-files-FIle-Loading:before {
    content: "\\e98d";
  }
  .icon-files-File-locked:before {
    content: "\\e98e";
  }
  .icon-files-File-minus:before {
    content: "\\e98f";
  }
  .icon-files-File-music:before {
    content: "\\e990";
  }
  .icon-files-File-next:before {
    content: "\\e991";
  }
  .icon-files-File-options:before {
    content: "\\e992";
  }
  .icon-files-File-play:before {
    content: "\\e993";
  }
  .icon-files-File-question-mark:before {
    content: "\\e994";
  }
  .icon-files-File-refresh:before {
    content: "\\e995";
  }
  .icon-files-File-reload:before {
    content: "\\e996";
  }
  .icon-files-File-search:before {
    content: "\\e997";
  }
  .icon-files-File-secured:before {
    content: "\\e998";
  }
  .icon-files-FIle-settings:before {
    content: "\\e999";
  }
  .icon-files-File-share:before {
    content: "\\e99a";
  }
  .icon-files-File-star:before {
    content: "\\e99b";
  }
  .icon-files-File-text:before {
    content: "\\e99c";
  }
  .icon-files-File-unlock:before {
    content: "\\e99d";
  }
  .icon-files-File-upload:before {
    content: "\\e99e";
  }
  .icon-files-File-user:before {
    content: "\\e99f";
  }
  .icon-files-File-vector:before {
    content: "\\e9a0";
  }
  .icon-files-FIle-volume:before {
    content: "\\e9a1";
  }
  .icon-files-File:before {
    content: "\\e9a2";
  }
  .icon-files-Files:before {
    content: "\\e9a3";
  }
  .icon-files-Folder-chart:before {
    content: "\\e9a4";
  }
  .icon-files-Folder-search:before {
    content: "\\e9a5";
  }
  .icon-files-Folder-sync:before {
    content: "\\e9a6";
  }
  .icon-files-Graph-chart-file:before {
    content: "\\e9a7";
  }
  .icon-files-Graph-file:before {
    content: "\\e9a8";
  }
  .icon-files-History-file:before {
    content: "\\e9a9";
  }
  .icon-files-Key-file:before {
    content: "\\e9aa";
  }
  .icon-files-Locked-folder:before {
    content: "\\e9ab";
  }
  .icon-files-Move-file:before {
    content: "\\e9ac";
  }
  .icon-files-Network-folder:before {
    content: "\\e9ad";
  }
  .icon-files-Remove-file:before {
    content: "\\e9ae";
  }
  .icon-files-Settings-file:before {
    content: "\\e9af";
  }
  .icon-files-Source-file:before {
    content: "\\e9b0";
  }
  .icon-files-Spread-sheet-file:before {
    content: "\\e9b1";
  }
  .icon-files-Update-edit-file:before {
    content: "\\e9b2";
  }
  .icon-files-Uplaod-file:before {
    content: "\\e9b3";
  }
  .icon-files-Vector-file:before {
    content: "\\e9b4";
  }
  .icon-files-View-file:before {
    content: "\\e9b5";
  }
  .icon-files-Zip-file:before {
    content: "\\e9b6";
  }
  .icon-generic--New-post:before {
    content: "\\e9b7";
  }
  .icon-generic-3d-triangle:before {
    content: "\\e9b8";
  }
  .icon-generic-Alert:before {
    content: "\\e9b9";
  }
  .icon-generic-Archive:before {
    content: "\\e9ba";
  }
  .icon-generic-Art:before {
    content: "\\e9bb";
  }
  .icon-generic-Attach:before {
    content: "\\e9bc";
  }
  .icon-generic-Badge-Circular:before {
    content: "\\e9bd";
  }
  .icon-generic-Badge:before {
    content: "\\e9be";
  }
  .icon-generic-Ball:before {
    content: "\\e9bf";
  }
  .icon-generic-basket:before {
    content: "\\e9c0";
  }
  .icon-generic-Battery:before {
    content: "\\e9c1";
  }
  .icon-generic-Bell:before {
    content: "\\e9c2";
  }
  .icon-generic-Blub:before {
    content: "\\e9c3";
  }
  .icon-generic-Bookmark:before {
    content: "\\e9c4";
  }
  .icon-generic-Building:before {
    content: "\\e9c5";
  }
  .icon-generic-Camera:before {
    content: "\\e9c6";
  }
  .icon-generic-Caution-triangle:before {
    content: "\\e9c7";
  }
  .icon-generic-Certified-Badge:before {
    content: "\\e9c8";
  }
  .icon-generic-Check-mark-rounded:before {
    content: "\\e9c9";
  }
  .icon-generic-Check-mark:before {
    content: "\\e9ca";
  }
  .icon-generic-Close:before {
    content: "\\e9cb";
  }
  .icon-generic-Collaborate:before {
    content: "\\e9cc";
  }
  .icon-generic-Community:before {
    content: "\\e9cd";
  }
  .icon-generic-Copy:before {
    content: "\\e9ce";
  }
  .icon-generic-Cube:before {
    content: "\\e9cf";
  }
  .icon-generic-Dashboard:before {
    content: "\\e9d0";
  }
  .icon-generic-Diamond:before {
    content: "\\e9d1";
  }
  .icon-generic-Disabled:before {
    content: "\\e9d2";
  }
  .icon-generic-Edit:before {
    content: "\\e9d3";
  }
  .icon-generic-Factory:before {
    content: "\\e9d4";
  }
  .icon-generic-Female:before {
    content: "\\e9d5";
  }
  .icon-generic-Filter:before {
    content: "\\e9d6";
  }
  .icon-generic-Fire:before {
    content: "\\e9d7";
  }
  .icon-generic-Flag:before {
    content: "\\e9d8";
  }
  .icon-generic-Folder:before {
    content: "\\e9d9";
  }
  .icon-generic-Gift-box:before {
    content: "\\e9da";
  }
  .icon-generic-hand-gesture-point-up:before {
    content: "\\e9db";
  }
  .icon-generic-Heart:before {
    content: "\\e9dc";
  }
  .icon-generic-Hexagon:before {
    content: "\\e9dd";
  }
  .icon-generic-Hidden:before {
    content: "\\e9de";
  }
  .icon-generic-Home:before {
    content: "\\e9df";
  }
  .icon-generic-House:before {
    content: "\\e9e0";
  }
  .icon-generic-Instant:before {
    content: "\\e9e1";
  }
  .icon-generic-Key:before {
    content: "\\e9e2";
  }
  .icon-generic-Life-saver:before {
    content: "\\e9e3";
  }
  .icon-generic-Lock:before {
    content: "\\e9e4";
  }
  .icon-generic-Male:before {
    content: "\\e9e5";
  }
  .icon-generic-Message-heart:before {
    content: "\\e9e6";
  }
  .icon-generic-Minus:before {
    content: "\\e9e7";
  }
  .icon-generic-Mouse-pointer:before {
    content: "\\e9e8";
  }
  .icon-generic-Not-secure:before {
    content: "\\e9e9";
  }
  .icon-generic-Notification-off:before {
    content: "\\e9ea";
  }
  .icon-generic-Notification:before {
    content: "\\e9eb";
  }
  .icon-generic-Nuclear:before {
    content: "\\e9ec";
  }
  .icon-generic-Paint-brush:before {
    content: "\\e9ed";
  }
  .icon-generic-Pencil:before {
    content: "\\e9ee";
  }
  .icon-generic-Plus:before {
    content: "\\e9ef";
  }
  .icon-generic-Post-box:before {
    content: "\\e9f0";
  }
  .icon-generic-Power:before {
    content: "\\e9f1";
  }
  .icon-generic-Question-mark:before {
    content: "\\e9f2";
  }
  .icon-generic-Scan:before {
    content: "\\e9f3";
  }
  .icon-generic-Search:before {
    content: "\\e9f4";
  }
  .icon-generic-Secured:before {
    content: "\\e9f5";
  }
  .icon-generic-Send:before {
    content: "\\e9f6";
  }
  .icon-generic-Settings:before {
    content: "\\e9f7";
  }
  .icon-generic-Share:before {
    content: "\\e9f8";
  }
  .icon-generic-Shield:before {
    content: "\\e9f9";
  }
  .icon-generic-Star:before {
    content: "\\e9fa";
  }
  .icon-generic-Tag:before {
    content: "\\e9fb";
  }
  .icon-generic-Telescope:before {
    content: "\\e9fc";
  }
  .icon-generic-Thumbs-down:before {
    content: "\\e9fd";
  }
  .icon-generic-Thumbs-up:before {
    content: "\\e9fe";
  }
  .icon-generic-Ticket:before {
    content: "\\e9ff";
  }
  .icon-generic-Time:before {
    content: "\\ea00";
  }
  .icon-generic-Trash:before {
    content: "\\ea01";
  }
  .icon-generic-Trophy:before {
    content: "\\ea02";
  }
  .icon-generic-Umbrella:before {
    content: "\\ea03";
  }
  .icon-generic-Unlock:before {
    content: "\\ea04";
  }
  .icon-generic-User-add:before {
    content: "\\ea05";
  }
  .icon-generic-User-approve:before {
    content: "\\ea06";
  }
  .icon-generic-User-important:before {
    content: "\\ea07";
  }
  .icon-generic-User-remove:before {
    content: "\\ea08";
  }
  .icon-generic-User:before {
    content: "\\ea09";
  }
  .icon-generic-Video-camera:before {
    content: "\\ea0a";
  }
  .icon-generic-Zoom-in:before {
    content: "\\ea0c";
  }
  .icon-generic-Zoom-out:before {
    content: "\\ea0d";
  }
  .icon-interface-Alarm:before {
    content: "\\ea0e";
  }
  .icon-interface-Auto-rotate-locked:before {
    content: "\\ea0f";
  }
  .icon-interface-Bluetooth:before {
    content: "\\ea10";
  }
  .icon-interface-Calendar:before {
    content: "\\ea11";
  }
  .icon-interface-Camera:before {
    content: "\\ea12";
  }
  .icon-interface-Cart:before {
    content: "\\ea13";
  }
  .icon-interface-Chat-bubble:before {
    content: "\\ea14";
  }
  .icon-interface-Chat:before {
    content: "\\ea15";
  }
  .icon-interface-Check-mark:before {
    content: "\\ea16";
  }
  .icon-interface-Circuit-hub:before {
    content: "\\ea17";
  }
  .icon-interface-Circuit-nodes:before {
    content: "\\ea18";
  }
  .icon-interface-Circuit-path:before {
    content: "\\ea19";
  }
  .icon-interface-Color-picker:before {
    content: "\\ea1a";
  }
  .icon-interface-Empty-file:before {
    content: "\\ea1c";
  }
  .icon-interface-Equalizer:before {
    content: "\\ea1d";
  }
  .icon-interface-Event:before {
    content: "\\ea1e";
  }
  .icon-interface-Exit-full-screen:before {
    content: "\\ea1f";
  }
  .icon-interface-Expand-share:before {
    content: "\\ea20";
  }
  .icon-interface-Expand:before {
    content: "\\ea21";
  }
  .icon-interface-Fill:before {
    content: "\\ea22";
  }
  .icon-interface-Flame:before {
    content: "\\ea23";
  }
  .icon-interface-Full-screen:before {
    content: "\\ea24";
  }
  .icon-interface-History:before {
    content: "\\ea25";
  }
  .icon-interface-Home:before {
    content: "\\ea26";
  }
  .icon-interface-Horizontal-swipe:before {
    content: "\\ea27";
  }
  .icon-interface-Hotspot:before {
    content: "\\ea29";
  }
  .icon-interface-Left-align:before {
    content: "\\ea2b";
  }
  .icon-interface-Loader:before {
    content: "\\ea2c";
  }
  .icon-interface-Lock-open:before {
    content: "\\ea2d";
  }
  .icon-interface-Message-bubble:before {
    content: "\\ea2e";
  }
  .icon-interface-Message-typing:before {
    content: "\\ea2f";
  }
  .icon-interface-Minimize-screen:before {
    content: "\\ea30";
  }
  .icon-interface-Moon-night:before {
    content: "\\ea31";
  }
  .icon-interface-Network-signal:before {
    content: "\\ea32";
  }
  .icon-interface-Notification:before {
    content: "\\ea33";
  }
  .icon-interface-Offer:before {
    content: "\\ea34";
  }
  .icon-interface-Paint-brush:before {
    content: "\\ea35";
  }
  .icon-interface-Parallel-circuit-lines:before {
    content: "\\ea36";
  }
  .icon-interface-Path:before {
    content: "\\ea37";
  }
  .icon-interface-Pendrive:before {
    content: "\\ea38";
  }
  .icon-interface-Phone-rotate:before {
    content: "\\ea39";
  }
  .icon-interface-Phone-vibration:before {
    content: "\\ea3a";
  }
  .icon-interface-Pulse-machine:before {
    content: "\\ea3b";
  }
  .icon-interface-Reuse:before {
    content: "\\ea3c";
  }
  .icon-interface-Save:before {
    content: "\\ea3d";
  }
  .icon-interface-Scroll-up:before {
    content: "\\ea3e";
  }
  .icon-interface-Selection:before {
    content: "\\ea3f";
  }
  .icon-interface-Settings:before {
    content: "\\ea40";
  }
  .icon-interface-Sms:before {
    content: "\\ea41";
  }
  .icon-interface-Swipe-left:before {
    content: "\\ea42";
  }
  .icon-interface-Touch-hand-gesture:before {
    content: "\\ea44";
  }
  .icon-interface-Touch:before {
    content: "\\ea45";
  }
  .icon-interface-Typing-message-bubble:before {
    content: "\\ea46";
  }
  .icon-interface-Typing:before {
    content: "\\ea47";
  }
  .icon-location-Add-location:before {
    content: "\\ea48";
  }
  .icon-location-Directions:before {
    content: "\\ea49";
  }
  .icon-location-Discover:before {
    content: "\\ea4a";
  }
  .icon-location-Globe:before {
    content: "\\ea4b";
  }
  .icon-location-GPS-tracking:before {
    content: "\\ea4c";
  }
  .icon-location-GPS:before {
    content: "\\ea4d";
  }
  .icon-location-Location-pin:before {
    content: "\\ea4e";
  }
  .icon-location-Location-point:before {
    content: "\\ea4f";
  }
  .icon-location-Location-remove:before {
    content: "\\ea50";
  }
  .icon-location-Map-chart:before {
    content: "\\ea51";
  }
  .icon-location-Map-pin:before {
    content: "\\ea52";
  }
  .icon-location-Navigator:before {
    content: "\\ea53";
  }
  .icon-location-No-GPS:before {
    content: "\\ea54";
  }
  .icon-location-Paper-pin:before {
    content: "\\ea55";
  }
  .icon-location-Verified-location:before {
    content: "\\ea56";
  }
  .icon-target-arrow:before {
    content: "\\ea57";
  }
  .icon-transportation-Bus:before {
    content: "\\ea59";
  }
  .icon-transportation-Cab:before {
    content: "\\ea5a";
  }
  .icon-transportation-Cargo:before {
    content: "\\ea5c";
  }
  .icon-transportation-Cycle:before {
    content: "\\ea5d";
  }
  .icon-transportation-Estimate-delivery:before {
    content: "\\ea5e";
  }
  .icon-transportation-Flight:before {
    content: "\\ea5f";
  }
  .icon-transportation-Free-delivery-shipment:before {
    content: "\\ea60";
  }
  .icon-transportation-Harbour:before {
    content: "\\ea61";
  }
  .icon-transportation-Helmet:before {
    content: "\\ea62";
  }
  .icon-transportation-Hook-crane:before {
    content: "\\ea63";
  }
  .icon-transportation-Metro-train:before {
    content: "\\ea64";
  }
  .icon-transportation-Parachute:before {
    content: "\\ea65";
  }
  .icon-transportation-Parcel:before {
    content: "\\ea66";
  }
  .icon-transportation-Road-barrier-cone:before {
    content: "\\ea67";
  }
  .icon-transportation-Road-roller:before {
    content: "\\ea68";
  }
  .icon-transportation-Rocket:before {
    content: "\\ea69";
  }
  .icon-transportation-Scooter:before {
    content: "\\ea6a";
  }
  .icon-transportation-Ship:before {
    content: "\\ea6b";
  }
  .icon-transportation-Sign-board:before {
    content: "\\ea6c";
  }
  .icon-transportation-Trolley:before {
    content: "\\ea6d";
  }
  .icon-transportation-Truck:before {
    content: "\\ea6e";
  }
  .icon-weather-Cloud-rain:before {
    content: "\\ea6f";
  }
  .icon-weather-Cloud:before {
    content: "\\ea70";
  }
  .icon-weather-Lightning:before {
    content: "\\ea71";
  }
  .icon-weather-Moon:before {
    content: "\\ea72";
  }
  .icon-weather-Rain-cloud:before {
    content: "\\ea73";
  }
  .icon-weather-Rain-drop:before {
    content: "\\ea74";
  }
  .icon-weather-Rain:before {
    content: "\\ea75";
  }
  .icon-weather-Rainy:before {
    content: "\\ea76";
  }
  .icon-weather-Snow-flake:before {
    content: "\\ea77";
  }
  .icon-weather-Sun-Cloud:before {
    content: "\\ea78";
  }
  .icon-weather-Sun:before {
    content: "\\ea79";
  }
  .icon-weather-Sunny:before {
    content: "\\ea7a";
  }
  .icon-weather-Temperature:before {
    content: "\\ea7b";
  }
  .icon-weather-Thunder:before {
    content: "\\ea7c";
  }
  .icon-weather-Umbrella-rain:before {
    content: "\\ea7d";
  }
  .icon-weather-Wave-windy:before {
    content: "\\ea7e";
  }
  .icon-weather-Wind-sock:before {
    content: "\\ea7f";
  }
  .icon-weather-Wind:before {
    content: "\\ea80";
  }
  .icon-weather-Windy:before {
    content: "\\ea81";
  }
  .icon-weather-Winter:before {
    content: "\\ea82";
  }
`
export default StyledIcons