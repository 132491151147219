import axios from "axios"
export const getEventRoomPublicAPI = query => axios
  .get(`${API_URL}radius-club/event-rooms${query}`)

//getScheduledRoomEventDetailsAction
export const getScheduledRoomsPublicDetailsAPI = query => axios
  .get(`${API_URL}radius-club/scheduled-event-rooms?room_id=${query}`)

export const joinRoomAsGuestDetailsAPI = ({ roomId, payload }) => axios
  .put(`${API_URL}radius-club/event-room/${roomId}/join-as-guest`, payload)

export const leaveRoomAsGuestDetailsAPI = ({ roomId, payload }) => axios
  .post(`${API_URL}radius-club/event-room/${roomId}/guest-leave`, payload)

