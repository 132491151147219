import React from "react"
import styled from "styled-components"
import CircularImage from "dumbComponents/common/CircularImage"
import { convertUnicode } from "services/Utils"
import colors from "@colors"

const Wrap = styled.div`
  border-radius: 12px;
  padding: 16px;
  background: ${colors.newTagInactive};
  margin-top: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;

  p {
    flex: 1;
    padding: 0px;
    margin: 0px 0px 0px 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.contentColor};
  }

  span {
    font-size: 12px;
    color: ${colors.dirtBrown};
  }
`
const CommentText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.contentColor};
  font-weight: 300;
  margin-top: 10px;
`

const TopComment = ({
  agent,
  comment,
  type = "comment",
}) => (
  <Wrap>
    <Header>
      <CircularImage
        image={agent.agent_image}
        size={32}
        charSize="12px"
        name={`${agent.firstname || ""} ${agent.lastname || ""}`}
      />
      <p>
        {`${agent.firstname || ""} ${agent.lastname || ""}`}
      </p>
      <span>
        {`Top ${type}`}
      </span>
    </Header>
    <CommentText>{convertUnicode(comment)}</CommentText>
  </Wrap>
)

export default TopComment
