const colors = {
  //SHADES OF SHALLOW WHITE
  blueDarkShade: "#f0f3f5",
  milkyGrey: "#f5f5f5",
  priceCardGrey: "#f7f7f7",
  bgGrey: "#f8f9fa",
  greyBackground: "#fbfcfc",
  lightWhiteGray: "#fcfcfc",
  white: "#ffffff",
  transparentWhite: "#fcfcfc",
  transaprentYolkWhite: "##8080807a",
  horizonWhite: "#F8F8F8",
  tempGray: "#616161",

  //SHADES OF WHITE
  paleWhite: "#dedede",
  teethWhite: "#fcf9f1",
  borderGray: "#eaeaea",
  veryLightGray: "#eeeeee",
  newTagInactive: "#eef0f0",
  shallowGrey: "#e5e9eb",
  moonGrey: "#D3DAE8",
  otterGrey: "#B4B4B4",
  disabledInput: "#e6e8e9",
  borderColor: "#e7e8ea",
  disabledColor: "#e7ebed",
  tagActive: "#e9f7fd",
  whiteBlue: "#f8f9fa",
  spaceWhiteGrey: "#FCFCFC",
  eggYolkWhite: "#C7C7C7",

  //SHADES OF YELLOW
  starActive: "#ffd05b",
  ripeMango: "#F39C12",
  exclamationCircle: "#faad14",

  //SHADES OF RED
  peachRed: "#c47070",
  redText: "#f96d72",
  strawberryPink: "#ea526f",
  sunsetPink: "#ff3e3e",
  blushRed: "#F6284F",
  red: "#ff0000",
  errorDanger: "#ff4b4b",
  berryRed: "#ED1616",

  //SHADES OF GREEN
  textGreen: "#09c199",
  turqoiseGreen: "#0ac4ba",
  successColor: "#51d3b7",
  gradientGreen: "#2bda8e",
  mossGreen: "#018786",

  //SHADES OF ORANGE
  pulpyOrange: "#EE8B31",

  //SHADES OF BLUE
  whiteBlueFaded: "#e9f3fb",
  fadedPrimaryColor: "#ADE1F8",
  fadedBlueSea: "#F1F3F8",
  primaryColor: "#11adf3",
  iconDarkColor: "#868b90",
  infoBorderColor: "#10adf3",
  primaryBorderColor: "#11adf3",
  primaryHover: "#0088a6",
  newPrimaryColor: "#0B8DEC",
  mainFadedPrimaryColor: "#8DB5F0",
  feedHighlight: "#f5fcff",
  marineBLue: "#3B60E4",
  luxorBlue: "#364A7D",
  maldivesBlue: "#4B89DC",
  purpleHeart: "#4f26be",
  purpleTabs: "#7A7FFF",
  textSubHeading: "#6b7280",
  new_purpleGradient: "#EEF2FF",

  // Academy Color
  academyTitleColor: "#392463",
  lilacPurple: "#8E44AD",
  lightPurple: "#565ca7",
  darkPurple: "#190A36",
  darkRoyalePurple: "#121425",
  lightCream: "#FBF8EF",

  //SHADES OF BLACK AND DARK GREY
  dreamWhite: "#F9F9F7",
  paleGrayWhite: "#f6f6f6",
  milkyWhite: "#dadada",
  starInactive: "#d4d4d4",
  tabDisabledColor: "#c4c7ca",
  textDisabledColor: "#c6c9cb",
  darkGreyPurple: "#524969",
  faintGrey: "#adb2b9",
  bluishGrey: "#adb3bb",
  grayishBlue: "#aab3be",
  primaryInactiveColor: "#a5a8ad",
  tagInactive: "#ccc",
  charcoalWhiteGrey: "#808080",
  faintBlueishBlack: "#868b90",
  iconDarkPurple: "#898989",

  newTagText: "#898c90",
  silkGrey: "#929292",
  charcoalBlueGrey: "#979797",
  dirtBrown: "#666b71",
  reddishGrey: "#d8d8d8",
  contentText: "#354052",
  slateGrey: "#949494",
  deepSeaBlue: "#3c4858",
  ashGrey: "#34495E",
  deepSeaGray: "#767676",
  textColor: "#262626",
  black: "#303030",
  lightGrayishBlue: "#F1F3F8",
  deepPurple: "#4E26BE",
  lightGray: "#BEBEBE",
  suvaGray: "#8C8C8C",
  solitude: "#EAF5FF",
  silentWhite: "#EFEFEF",
  fungiWhite: "#e5e5e5",
  dodoGrey: "#d0d0d0",
  yolkYellow: "#F6F1E9",
  shallowMilk: "#f9f9f7",
  rockBlue: "#96A3BD",

  // New Colors
  new_primary: "#5A5FF2",
  new_primary50: "#EEF2FF",
  new_primaryHover: "#484CC1",
  new_secondary: "#A5A8FF",
  new_grey: "#6E6E6E",
  new_grey400: "#41446A",
  new_grey50: "#F7F8F9",
  new_placeholder: "#898989",
  new_borderGrey: "#D0D5DD",
  new_borderPurple: "#DCDEFF",
  new_colorNeutral50: "#FDFDFD",
  new_colorNeutral100: "#F1F1FE",
  new_colorNeutral200: "#E5E6FF",
  new_colorNeutral300: "#D9DAFF",
  new_colorNeutral500: "#64748b",
  new_grey600: "#292A37",
  new_text_purple_600: "#232338",
  new_border_outline: "#E0E0EB",
  new_tab_color: "#EFEFF4",
  new_colorNeutral900: "#111827",
  new_buttonText: "#F9F9F9",
  new_light: "#F9F9F7",
  new_blueGradient: "linear-gradient(271deg, #5A5FF2 -0.67%, #A5A8FF 131.87%)",
  new_lightBackground: "#F9F9FF",
  new_typographyGeneralPrimary: "#303030",
  new_errorReFFEFF4dColor: "#EF4444",
  new_redTextColor: "#FFEFF4",
  new_levelColor: "#545487",
  new_borderLight: "#e4e4e4",
  new_buttonColor: "#333",
  new_timeline_color: "#f0f0f0",
  new_timeline_Date_color: "#b0b0b0",
  new_timeline_status_color: "#f05152",
  new_border_color: "#d9d9d9",
  // FRAMER
  framer_color_gray_700: "#1f1f32",
  framer_cyan: "#5af2ed",
  framer_color_neutral_100: "#f1f1fe",
  framer_color_neutral_300: "#DCDEFF",
  framer_black: "#000",

  // CONTRACT COLORS
  contract_bg_gray_50: "#EFEFF4",
  contract_gray_700: "#373758",
  contract_bg_green_50: "#E7F5F1",
  contract_green_700: "#09714E",

  transaction_tab_select: "#E0E0EB",
  
};

export default colors;
