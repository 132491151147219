import React from "react"
import styled from "styled-components"
import colors from "@colors"
import Button from "@ui/Button"
import Container from "container/Leaderboard"
import cookie from "services/CookieStorage"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import {
  getFirstAndLastDateFromMonth,
  getFirstAndLastDateFromYear,
} from "services/Utils"
import FilterComponent from "./FilterComponents"

const Wrap = styled.div`
  padding-top: 32px;
`

const Header = styled.div`
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
`

const HeaderFilter = styled(Button)`
  letter-spacing: 2px;
  text-align: left;
  padding-left: 0px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.black};
`

const CategoriesWrap = styled.div`
  display: flex;
  padding: 0px;
  background: ${colors.white};
  border: 1px solid ${colors.moonGrey};
  border-radius: 8px;
  align-items: center;
  margin-top: 16px;
`

const Categories = styled.h2`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 122px;
  height: 40px;
  border-radius: 8px;
  flex: 1;
  margin: 0;
  cursor: pointer;
  ${props => props.active && `
    background: ${colors.deepPurple};
    color: white;
  `}
`
const fetchLeaderBoardLists = (getLeaderBoardLists, showMonth, setTabActive, value, activeCategory, month, year) => {
  setTabActive(value)
  console.log("month=====2222", month)
  console.log("year=====2222", year)
  const { firstDateEpoch, lastDateEpoch } = month === -1 ? getFirstAndLastDateFromYear(year) : getFirstAndLastDateFromMonth(month, year)
  console.log("firstDateEpoch=====2222", firstDateEpoch)
  console.log("lastDateEpoch=====2222", lastDateEpoch)
  getLeaderBoardLists({
    duration: showMonth ? "monthly" : "",
    type: value,
    metric: activeCategory,
    limit: 10,
    skip: 0,
    refresh: true,
    from_date: firstDateEpoch || null,
    to_date: lastDateEpoch || null,
  })
}

const FilterValues = ({
  filters,
  filterMap,
  fetchDocuments,
  currentResourceSearchValue,
  getLeaderBoardLists,
  showMonth,
  activeTab,
  activeCategory,
  setTabActive,
  setActiveCategory,
  getTeamDetailsResponse,
  month,
  year,
}) => {
  const {
    data: teamDetails,
  } = getTeamDetailsResponse || {}
  const isBrokerageTeamMember = teamDetails && teamDetails.id
  console.log("month=====", month)
  console.log("year=====", year)
  const setActiveCategoryValue = (value) => {
    fetchLeaderBoardLists(getLeaderBoardLists, showMonth, setTabActive, activeTab, value, month, year)
    setActiveCategory(value)
  }

  return (
    <Wrap>
      <Header>
        <HeaderFilter onClick={() => { }}>View By</HeaderFilter>
      </Header>
      <CategoriesWrap>
        <Categories active={activeTab === "agents"} onClick={() => fetchLeaderBoardLists(getLeaderBoardLists, showMonth, setTabActive, "agents", activeCategory, month, year)}>
          All Agents
        </Categories>
        {isBrokerageTeamMember && (
          <Categories active={activeTab === "my_team"} onClick={() => fetchLeaderBoardLists(getLeaderBoardLists, showMonth, setTabActive, "my_team", activeCategory, month, year)}>
            My Team
          </Categories>
        )}
        <Categories active={activeTab === "teams"} onClick={() => fetchLeaderBoardLists(getLeaderBoardLists, showMonth, setTabActive, "teams", activeCategory, month, year)}>
          Teams
        </Categories>
      </CategoriesWrap>
      <Header mt="32px">
        <HeaderFilter onClick={() => { }}>Category</HeaderFilter>
      </Header>

      <FilterComponent
        activeTab={activeTab}
        filters={filters}
        filterMap={filterMap}
        fetchDocuments={fetchDocuments}
        currentResourceSearchValue={currentResourceSearchValue}
        activeCategory={activeCategory}
        setActiveCategoryValue={setActiveCategoryValue}
      />

    </Wrap>
  )
}

export default TeamProfileContainer(Container(FilterValues))
