import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { Pagination } from "@ui/antd"
import AddIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/AddIcon.svg"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import {
  Title, AddButton, Icon,
} from "../../../common"
import NotesDetails from "./NotesDetails"
import NotesForm from "./NotesForm"
import { TabContentContainer } from "../../styles"

const HeaderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${colors.silentWhite};
  margin-right: 15px;
  padding-bottom: 10px;
`

const ZeroStateWrap = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPagination = styled.div`
  ul {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`

const NotesTab = ({
  getNotesActionResponse,
  getCtIdResponse,
  getNote,
}) => {
  const [currentPage, setPage] = useState(1)
  const { data, isFetching } = getNotesActionResponse || {}
  const { data: transactionData } = getCtIdResponse || {}
  const { id } = transactionData || {}

  useEffect(() => {
    getNote({ transactionId: id, limit: 10, offset: 0 })
  }, [])
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(10)
  const { notes, total_notes } = data || {}
  const [addNoteClicked, setNoteClicked] = useState(false)
  const onChange = (page, pageSize) => {
    getNote({ transactionId: id, limit: 10, offset: 10 * (page - 1) })
  }
  return (
    <TabContentContainer>
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setNoteClicked(true)
          }}
        >
          <Title>
            Add Notes
          </Title>
          <Icon src={AddIcon} />
        </AddButton>
      </HeaderWrap>
      {addNoteClicked && (
        <NotesForm
          setNoteClicked={setNoteClicked}
        />
      )}
      {isFetching ? (<ShimmerBox w="100%" h="100px" />) : (
        <>
          {notes && notes.map(note => <NotesDetails data={note} key={note.id} />)}
        </>
      )}
      {addNoteClicked || (notes && notes.length === 0) && (
        <ZeroStateWrap>
          <Title>
            No notes yet.
          </Title>
        </ZeroStateWrap>
      )}
      {total_notes > 10 && (
        <StyledPagination>
          <Pagination
            defaultCurrent={currentPage}
            total={total_notes || 0}
            onChange={onChange}
            defaultPageSize={10}
          />
        </StyledPagination>
      )}
    </TabContentContainer>
  )
}

export default CRMV2Container(NotesTab)
