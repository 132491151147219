import React from "react"
import container from "container/OffersV2"
import { CheckedIcon, SideArrowIcon } from "dumbComponents/OffersV2/icons"
import {
  Wrap,
  SideNavWrap,
  SideNavTitle,
  SideNavTitleWrap,
} from "./styles"

const SideNav = ({
  options,
  toggleSideNavTab,
  currentSideNavTab,
}) => {
  return (
    <Wrap>
      {options.map(option => (
        <SideNavWrap onClick={() => toggleSideNavTab(option.value)} isActive={currentSideNavTab === option.value}>
          <SideNavTitleWrap>
            <SideNavTitle isActive={currentSideNavTab === option.value}>
              {option.label}
            </SideNavTitle>
            <CheckedIcon />
          </SideNavTitleWrap>
          <SideArrowIcon />
        </SideNavWrap>
      ))}
    </Wrap>
  )
}

export default container(SideNav)
