import React from "react"
import {
  EmptyStateContent,
  EmptyStateHeader,
  EmptyStateWrap,
} from "../styles"

const EmptyState = ({
  emptyHeader,
  emptyDescription,
}) => {
  return (
    <EmptyStateWrap>
      <EmptyStateHeader>
        {emptyHeader}
      </EmptyStateHeader>
      <EmptyStateContent>
        {emptyDescription}
      </EmptyStateContent>
    </EmptyStateWrap>
  )
}

export default EmptyState
