import React from "react"
import {
  InnerTab,
  NavLink,
  NotificationIndicator,
  InfoTag,
} from "./commonStyles"
import NewsFeed from "../Icons/NewIcons/NewsFeed"

const NewsFeedTab = ({
  routeArray,
  homeRedDot,
  handleHomeClick,
  showLiveTag,
}) => (
  <InnerTab isActive={routeArray[0] === "community"} className="onboading-nav-home">
    <div className="icon-block-new">
      <NewsFeed />
    </div>
    <div className="text-block">
      <NavLink
        to="/community"
        isActive={routeArray[0] === "community"}
        onClick={handleHomeClick}
      >
        News Feed
        {showLiveTag && (
          <InfoTag hasSmallerFontSize>LIVE</InfoTag>
        )}
        {
          homeRedDot
            ? (
              <NotificationIndicator />
            ) : (
              <></>
            )
        }
      </NavLink>
    </div>
  </InnerTab>
)

export default NewsFeedTab
