import React, { useEffect } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import Loader from "@ui/Loader"
import moment from "moment"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 15px;
  margin-bottom: 50px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  margin-bottom: 10px;
  padding: 0px 30px 20px;
  overflow: auto;
  max-height: 400px;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: #3B60E4;
  margin-right: 10px;
  border-radius: 50%;
`

const Line = styled.div`
  width: 4px;
  height: 80px;
  background: #3B60E4;
  margin-left: 4px;
  margin-right: 10px;
  margin-top: -14px;
`

const StatusHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 15px;
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    color: #8C8C8C;
    margin: 0;
  }
`

const StatusContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
`

const NotesContainer = styled.div`
  display: flex;
  align-items: center;
`

const NoDataContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: left;
  color: ${colors.black};
  margin: 0;
  text-align: center;
`

const ViewListingHistory = ({
  showViewListingHistory, toggleViewListingHistory, history, dealToView, getListingHistory, getListingHistoryResponse,
}) => {
  useEffect(() => {
    getListingHistory({
      listingId: showViewListingHistory,
    })
  }, [])
  return (
    <Wrap>
      {showViewListingHistory && (
        <Modal
          show
          toClose={() => { toggleViewListingHistory(false) }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="600px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "#F9F9F7",
            borderRadius: "0px",
            minHeight: "300px",
            maxHeight: "600px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
              <h1>
                Listing History
              </h1>
            </Header>
            <ContentContainer>
              {getListingHistoryResponse.isFetching && (
                <Loader />
              )}
              {!getListingHistoryResponse.isFetching && !(getListingHistoryResponse.data && getListingHistoryResponse.data.length !== 0) && (
                <Content>
                  <NoDataContent>
                    No History Available
                  </NoDataContent>
                </Content>
              )}
              {getListingHistoryResponse?.data?.map(listHistory => (
                <Content>
                  <StatusContainer>
                    <Dot />
                    <StatusHeader>
                      <h3>
                        {`Status updated : ${listHistory.status}`}
                      </h3>
                      <p>
                        {moment(listHistory.created_at).format("MM-DD-YYYY")}
                      </p>
                    </StatusHeader>
                  </StatusContainer>
                  <NotesContainer>
                    <Line />
                    <p>
                      {`Notes: ${listHistory.notes || "N/A"}`}
                    </p>
                  </NotesContainer>
                </Content>
              ))}
            </ContentContainer>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(ViewListingHistory))
