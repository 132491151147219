import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import icons from "@icons"
import colors from "@colors"
import { ATC } from "@fonts"

const Wrap = styled.div`
`

const ArrowWrap = styled.div`
 font-size: 16px;
  i {
    position: relative;
    top: 1px;
    display: inline-block;
    position: relative;
    transform: rotate(180deg);
    color: ${colors.marineBLue};
  }
`

const BackToRooms = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.marineBLue};
`

const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  align-items: baseline;
  gap: 15px;
`

const ShowcaseHeader = ({
  history,
}) => (
  <Wrap>
    <Button
      onClick={() => {
        history.push("/rooms")
      }}
    >
      <ArrowWrap>
        <i className={icons.arrowsArrowRight}></i>
      </ArrowWrap>
      <BackToRooms>
        Back to rooms
      </BackToRooms>
    </Button>
  </Wrap>
)

export default withRouter(ShowcaseHeader)
