import React, { useState } from "react"
import { Radio, Space } from "antd"
import Listings from "container/Listings"
import {
  FilterWrap,
} from "./styles"

const SortBy = ({
  onChangeForLexicographicalSort,
  value,
}) => (
  <FilterWrap>
    <Radio.Group onChange={onChangeForLexicographicalSort} value={value}>
      <Space direction="vertical">
        <Radio value="ASC">Name A-Z</Radio>
        <Radio value="DESC">Name Z-A</Radio>
      </Space>
    </Radio.Group>
  </FilterWrap>
)

export default Listings(SortBy)
