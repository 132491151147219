/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import queryString from "query-string"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import CRMV2Container from "container/CRM"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div`
  margin: 20px 0;
`
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
    margin-top: 0;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 40px 30px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ConfirmClosed = ({
  showGoToContract,
  currentFilters,
  updateClientTransaction,
  statusValue,
  location,
  setIsClosed,
  changeCardStage,
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { client_id, transactionId, type } = parsedQuery || {}

  const { result } = showGoToContract || {}
  const {
    draggableId,
    source,
    destination,
  } = result || {}

  const handleChange = () => {
    const payload = {
      status: statusValue,
      client_id,
      type,
    }
    updateClientTransaction({
      transactionId,
      payload,
      currentFilters,
    })
  }

  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Congratulations!
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            You are about to Close this Transaction
          </Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="100%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              // if (result) {
              //   changeCardStage({
              //     source,
              //     destination,
              //     draggableId,
              //     currentFilters,
              //   })
              // }
              if (statusValue !== null) {
                handleChange()
              }
              setIsClosed(false)
            }}
          >
            Go Ahead
          </LoaderButton>
          {/* <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              setIsClosed(false)
              // setEditingDealTeamMemberId(null)
            }}
          >
            No, thanks
          </LoaderButton> */}
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default CRMV2Container(withRouter(TechAssetsContainer(ConfirmClosed)))
