import React, { useState } from "react"
import { Radio, Space } from "antd"
import Listings from "container/Listings"
import {
  FilterWrap,
} from "./styles"

const SortByPricing = ({
  onChangeForNumericalValueOfPriceSort,
  value,
}) => (
  <FilterWrap>
    <Radio.Group onChange={onChangeForNumericalValueOfPriceSort} value={value}>
      <Space direction="vertical">
        <Radio value="DESC">Listing Price (High to Low)</Radio>
        <Radio value="ASC">Listing Price (Low to High)</Radio>
      </Space>
    </Radio.Group>
  </FilterWrap>
)

export default Listings(SortByPricing)
