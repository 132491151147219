import {
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  all,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { getLeaderBoardStatsApi, getLeaderBoardListsApi, getLeaderBoardMonthlyWinnerApi, getTeamStatsApi, getLeaderBoardStatsPostApi, getHomePageGraphStatsApi } from "./api"
import { getLeaderBoardStats, getLeaderBoardLists, getLeaderBoardMonthlyWinner, resetLeaderBoardLists, getTeamStats, getHomePageGraphStats } from "./actions"

function* handleFetchLeaderBoardStats(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    let res = {}
    if (action.data?.apiMethod === "post") {
      res = yield call(getLeaderBoardStatsPostApi, action.data)
    } else {
      res = yield call(getLeaderBoardStatsApi, query)
    }
    if (isSuccess(res)) {
      yield put(getLeaderBoardStats.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getLeaderBoardStats.failure(error))
  }
}

function* handleGetTeamStats(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(getTeamStatsApi, query)
    if (isSuccess(res)) {
      yield put(getTeamStats.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getTeamStats.failure(error))
  }
}

function* handleFetchLeaderBoardLists(action) {
  const {
    metric = "",
    duration = "",
    type = "",
    limit,
    skip,
    refresh,
    from_date,
    to_date,
    callBack,
    firstTimeCallback,
  } = action.data
  try {
    if (refresh) yield put(resetLeaderBoardLists.request())
    if (callBack) callBack()
    const res = yield call(getLeaderBoardListsApi, metric, duration, type, limit, skip, from_date, to_date)
    let leaderBoardLists = yield select(state => state.leaderBoardRarDashboard.leaderBoardLists.data)
    if (!(leaderBoardLists && leaderBoardLists.response) || refresh) {
      leaderBoardLists = {
        isDone: false,
        response: [],
        emptyListObj: {},
      }
    }
    if (isSuccess(res)) {
      let response = res.data && res.data.response && res.data.response.result
      const isDone = response && response.length === 0
      if (firstTimeCallback) {
        firstTimeCallback(response.length)
        return
      }
      response = [...leaderBoardLists.response, ...response]
      yield put(resetLeaderBoardLists.success())
      yield put(getLeaderBoardLists.success({
        response,
        isDone,
        emptyListObj: res.data.response.emptyListObj,
      }))
    }
  } catch (error) {
    yield put(getLeaderBoardLists.failure(error))
  }
}

function* handleFetchLeaderBoardMonthlyWinner(action) {
  try {
    const res = yield all(action.data.payload.map(dt => call(getLeaderBoardMonthlyWinnerApi, dt.month, dt.year)))
    let leaderBoardMonthlyWinner = yield select(state => state.leaderBoardRarDashboard.leaderBoardMonthlyWinner.data)
    if (action.data.onMount) {
      leaderBoardMonthlyWinner = []
    }
    leaderBoardMonthlyWinner = leaderBoardMonthlyWinner || []
    let response = res.filter(dt => dt.data.response.length).map(dt => dt.data.response)
    response = [...leaderBoardMonthlyWinner, ...response]
    yield put(getLeaderBoardMonthlyWinner.success(response))
  } catch (error) {
    yield put(getLeaderBoardMonthlyWinner.failure(error))
  }
}

function* handleGetHomePageGraphStats(action) {
  try {
    // let query = ""
    // Object.keys(action.data).forEach((dt) => {
    //   query += `${dt}=${action.data[dt]}&`
    // })
    const res = yield call(getHomePageGraphStatsApi, action.data)
    if (isSuccess(res)) {
      yield put(getHomePageGraphStats.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getHomePageGraphStats.failure(error))
  }
}

export default function* main() {
  yield takeLatest(getLeaderBoardStats.REQUEST, handleFetchLeaderBoardStats)
  yield takeLatest(getTeamStats.REQUEST, handleGetTeamStats)
  yield takeLatest(getLeaderBoardLists.REQUEST, handleFetchLeaderBoardLists)
  yield takeEvery(getLeaderBoardMonthlyWinner.REQUEST, handleFetchLeaderBoardMonthlyWinner)
  yield takeLatest(getHomePageGraphStats.REQUEST, handleGetHomePageGraphStats)
}
