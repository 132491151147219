import React, { useState } from "react"
import OffersV2 from "container/OffersV2"
import axios from "axios"
import DebouncedSelect from "./Components/debouncedSelect"
import GoogleLocationInput from "./Components/index"
import {
  Wrap,
  TabsWrap,
  TabsInfo,
  TabsTitle,
} from "./styles"

const MLS = [
  {
    value: "mls",
    label: "Search using MLS",
  },
  {
    value: "address",
    label: "Search using Property Address",
  },
]

const MLSTabs = ({
  toggleMLSTab,
  currentMLSTab,
}) => {
  const [city, setCity] = useState()
  const [searchValue, setValue] = useState([])

  const handleFilterQuery = (key, value) => {
    console.log(key, value, "here")
  }

  const fetchUserList = async (search) => {
    const url = `${API_URL}agent/elastic/query`

    const payload = {
      text_value: search,
      sortIsAsc: false,
      ascending: false,
      facade: false,
      from: 0,
      size: 10,
      text_search_params: ["email", "full_name"],
      unRegistered: false,
    }

    const mapped = await axios.post(url, payload)
      .then((res) => {
        const {
          response,
        } = res.data || {}
        return response && response.agents || []
      })

    const options = mapped && mapped.map(item => ({
      value: item.id,
      label: `${item.firstname || "Radius"} ${item.lastname || "User"}`,
    }))

    return options
  }

  return (
    <Wrap>
      <TabsWrap>
        {MLS.map(option => (
          <TabsInfo isSelected={option.value === currentMLSTab} onClick={() => toggleMLSTab(option.value)}>
            <TabsTitle isSelected={option.value === currentMLSTab}>
              {option.label}
            </TabsTitle>
          </TabsInfo>
        ))}
      </TabsWrap>
      {currentMLSTab === "mls" && (
        <DebouncedSelect
          showSearch
          placeholder="Search by MLS"
          value={searchValue}
          fetchOptions={fetchUserList}
          onChange={(newValue) => {
            setValue(newValue)
            handleFilterQuery("agent_id", newValue)
          }}
        />
      )}
      {currentMLSTab === "address" && (
        <GoogleLocationInput
          city={city}
          setCity={(val) => {
            setCity(() => {
              handleFilterQuery("location", val)
              return val
            })
          }}
          placeholder="Search By Property Address"
        />
      )}
    </Wrap>
  )
}

export default OffersV2(MLSTabs)
