import { createSignalAction, createActionCreator, createSimpleCreator } from "shared/reduxUtils"

const BASE = "PROFILE"

export const OPEN_EDIT_PROFILE_MODAL = "OPEN_EDIT_PROFILE_MODAL"
export const CLOSE_EDIT_PROFILE_MODAL = "CLOSE_EDIT_PROFILE_MODAL"
export const UPDATE_AGENT_PROFILE_INFO = "UPDATE_AGENT_PROFILE_INFO"
export const MLS_STATE_CHANGE = "MLS_STATE_CHANGE"
export const SKIP_PROFILE_COMPLETION_STEP = "SKIP_PROFILE_COMPLETION_STEP"
export const CLEAR_PROFILE = "CLEAR_PROFILE"
export const PROFILE_VIEWED = "PROFILE_VIEWED"

// API Actions
export const fetchUserProfileAction = createSignalAction(BASE, "FETCH_USER_PROFILE")
export const fetchUserProfileInfoAction = createSignalAction(BASE, "FETCH_PROFILE_INFO")
export const followAgentAction = createSignalAction(BASE, "FOLLOW_AGENT")
export const getFollowersAndFollowingAction = createSignalAction(BASE, "FETCH_FOLLOWERS_AND_FOLLOWING")
export const saveProfileUpdateAction = createSignalAction(BASE, "SAVE_PROFILE_UPDATE")
export const fetchSpecializationsAction = createSignalAction(BASE, "FETCH_SPECIALIZATION")
export const uploadProfileImageAction = createSignalAction(BASE, "UPLOAD_PROFILE_IMAGE")
export const getNeighbourhoodByCityAction = createSignalAction(BASE, "GET_NEIGHBORHOOD_BY_CITY")
export const saveCityServedAction = createSignalAction(BASE, "SAVE_CITY_SERVED")
export const getPropertyTypesAction = createSignalAction(BASE, "GET_PROPERTY_TYPE")
export const getPropertyStatusAction = createSignalAction(BASE, "GET_PROPERTY_STATUS")
export const uploadPropertyImagesAction = createSignalAction(BASE, "UPLOAD_PROPERTY_IMAGES")
export const saveAddListingAction = createSignalAction(BASE, "SAVE_ADD_LISTING")
export const getProfilecompletionAction = createSignalAction(BASE, "GET_PROFILE_COMPLITION")
export const unfollowAgentAction = createSignalAction(BASE, "UNFOLLOW_AGENT")
export const fetchBuyerNeedsAction = createSignalAction(BASE, "FETCH_BUYER_NEEDS")
export const addCityAction = createSignalAction(BASE, "ADD_CITY")
export const removeCityAction = createSignalAction(BASE, "REMOVE_CITY")
export const getOwnFollowerAndFollowingAction = createSignalAction(BASE, "GET_OWN_FOLLOWERS_AND_FOLLOWING")
export const addBuyerNeedsAction = createSignalAction(BASE, "ADD_BUYER_NEEDS")
export const getMLSServingListsAction = createSignalAction(BASE, "GET_MLS_SERVING_LISTS")
export const syncMLSAction = createSignalAction(BASE, "SYNC_MLS")
export const removeSyncedMlsAction = createSignalAction(BASE, "REMOVE_SYNCED_MLS")
export const fetchCityNeighborhoodServedAction = createSignalAction(BASE, "CITY_NEIGHIBORHOOD_SERVED")
export const fetchMoreFeedsAction = createSignalAction(BASE, "FETCH_MORE_FEEDS")
export const removeCityServedCityAction = createSignalAction(BASE, "REMEOVE_CITY_SERVED_CITY")
export const removeCityServedNeighbourhoodAction = createSignalAction(BASE, "REMOVE_CITY_SERVED_NEIGHBOURHOOD")
export const removeBuyerNeedsAction = createSignalAction(BASE, "REMOVE_BUYER_NEEDS")
export const getGoalsAction = createSignalAction(BASE, "GET_GOALS")
export const sendGoalsAction = createSignalAction(BASE, "SEND_GOALS")
export const submitOnboardingFormAction = createSignalAction(BASE, "SUBMIT_ONBOARD_FORM")
export const getAgentFeedsAction = createSignalAction(BASE, "FETCH_USER_FEEDS")
export const unblockUserAction = createSignalAction(BASE, "UNBLOCK_USER")
export const updateProfileViewCountAction = createSignalAction(BASE, "UPDATE_PROFILE_VIEW_COUNT")
export const clearPropertyImagesAction = createSimpleCreator(BASE, "CLEAR_PROPERTY_IMAGES_ARRAY")

export const TOGGLE_FEED_LOADER = "TOGGLE_FEED_LOADER"
// Non-API actions
export const openEditProfileModalAction = createActionCreator(OPEN_EDIT_PROFILE_MODAL)
export const closeEditProfileModalAction = createActionCreator(CLOSE_EDIT_PROFILE_MODAL)
export const updateAgentProfileInfoAction = createActionCreator(UPDATE_AGENT_PROFILE_INFO)
export const mlsStateChangeAction = createActionCreator(MLS_STATE_CHANGE)
export const skipProfileCompletionStepAction = createActionCreator(SKIP_PROFILE_COMPLETION_STEP)
export const toggleFeedLoaderToggleAction = createActionCreator(TOGGLE_FEED_LOADER)
export const clearProfileAction = createActionCreator(CLEAR_PROFILE)
export const feedErrorFetchAction = createSimpleCreator(BASE, "ERROR_FETCH_401")

//View Action
export const profileViewedAction = createSignalAction(BASE, PROFILE_VIEWED)
