import React, { useState, useRef } from "react"
import styled from "styled-components"
import { LeftOutlined } from "@ant-design/icons"
import { get } from "lodash"
import CRMV2Container from "container/CRM"
import colors from "@colors"
import CookiesStorage from "services/CookieStorage"
import {
  GoogleAutoComplete, Form, Button,
  InputNumber, Select,
} from "@ui/antd"
import BgImage from "images/Graphics/RadiusOurAgentsAssets/HeroBackground.png"
import BgImage2 from "images/realty-v2/royal-background.jpg"
import { ATC } from "@fonts"
import {
  HeaderWrap, CloseWrap, LabelComponent,
} from "../../../../common"

const { Item } = Form
const { Option } = Select

const Wrap = styled.div``

const ImageWrap = styled.div`
  margin: 0 auto;
  width: 600px;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
`

const Title = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Description = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
  margin-top: 15px;
`

const FormFillWrap = styled.div`
  width: 600px;
  margin: 0 auto;
`

const FormTitle = styled.h1`
font-family: ${ATC};
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #303030;
margin-left: 5px;
`

const buyBeforeYouSellDesc = `Help unlock the majority of your client’s equity and
enable them to pay off any mortgages. Radius will acquire the property for up to 75%
of the current home value,  prepare the home with expert designers and vendors and lastly, 
resell the home for top dollar and you keep all the upside.`

const cashOfferDesc = `Help clients close quickly and make an offer in an expedited manner. 
Radius will purchase the property on their behalf with a quick cash closing. 
Your client then repurchases the home from Radius Edge and obtains a mortgage. 
Apply to get your client pre-approved by our mortgage lender.`

const mortgageDesc = ""

const preSaleHomePrepDesc = `Offer your clients a peace of mind with pre-sale home preperations 
for interior and exterior improvements. Work with a professional designer who will help identify
which aspects of the home to renovate for maximum selling value.`

const FinanceTransactionForm = ({
  setCurrentDrawerPage,
  getCrmClientResponse,
  setCurrentTab,
  currentPage,
  createFinancingForTransaction,
  currentCRMDealId,
  currentClientType,
  currentTransactionDetails,
  getCtIdResponse,
  updateBuyerReqResponse,
  updateClientFinanceTransaction,
  createFinanceTransationResponse,
}) => {
  const secureId = CookiesStorage.load("secureId")
  const [cityFormValue, setCityFormValue] = useState([])
  const formRef = useRef(null)
  const [form] = Form.useForm()
  const { data: financeTransactionData } = createFinanceTransationResponse || {}
  const { client_id } = financeTransactionData || {}
  const { data: transactionData } = getCtIdResponse || {}
  const { id, searchCriteria } = transactionData || {}
  const { locations, max_budget } = searchCriteria || {}

  const isFullAddress = locations && locations[locations.length - 1].c_locality_name !== null && locations[0].c_locality_name.includes("USA")

  const {
    data: updatedData,
    isFetching: isUpdating,
  } = updateBuyerReqResponse || {}

  const {
    locations: updatedLocations,
    max_budget: updatedMaxBudget,
  } = updatedData || {}

  const locationCurrent = updatedLocations || locations
  const currentMaxBud = updatedMaxBudget || max_budget

  const finalLocation = locationCurrent && locationCurrent[locationCurrent.length - 1].c_locality_name !== null
  && locationCurrent[locationCurrent.length - 1].c_locality_name.includes("USA")
    ? locationCurrent && locationCurrent[locationCurrent.length - 1].c_locality_name
    : `${locationCurrent && locationCurrent[locationCurrent.length - 1].c_locality_name},
  ${locationCurrent && locationCurrent[locationCurrent.length - 1].city !== null
    ? `${locationCurrent && locationCurrent[locationCurrent.length - 1].city}, ` : ""}
  ${locationCurrent && locationCurrent[locationCurrent.length - 1].state}`

  const agentId = CookiesStorage.load("agentId")
  const { data: clientData } = getCrmClientResponse || {}
  const { emails, phones, name } = clientData || {}
  const img = currentPage === "buy_before_you_sell" ? BgImage
    : (currentPage === "cash_offer" ? BgImage2 : (currentPage === "mortgage" ? BgImage : BgImage2))
  const title = currentPage === "buy_before_you_sell" ? "Buy Before You Sell"
    : (currentPage === "cash_offer" ? "Cash Offers" : (currentPage === "mortgage" ? "Mortgage" : "Pre-sale Home Prep"))
  const desc = currentPage === "buy_before_you_sell" ? buyBeforeYouSellDesc
    : (currentPage === "cash_offer" ? cashOfferDesc : (currentPage === "mortgage" ? mortgageDesc : preSaleHomePrepDesc))

  const firstName = name && name.split(" ")[0]
  const lastName = name && name.split(" ")[1] || ""

  const setLocaCity = (value) => {
    console.log(value)
  }

  const setCities = (location) => {
    const currentFieldValues = formRef.current.getFieldsValue()
    const locationDetails = {
      c_locality_name: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      address: get(location, "address"),
      route: get(location, "route.long_name"),
      streetName: get(location, "street_number.short_name"),
      zipcode: get(location, "postal_code.short_name"),
    }
    currentFieldValues.location = locationDetails.address
    currentFieldValues.zipcode = parseInt(locationDetails.zipcode, 10)
    formRef.current.setFieldsValue({ ...currentFieldValues })
    setCityFormValue(locationDetails)
  }

  const onFinish = (values) => {
    let payload
    setCurrentTab("FINANCING")
    if (currentPage === "mortgage") {
      const property = {
        city: cityFormValue.c_locality_name,
        state: cityFormValue.state,
        address: cityFormValue.c_locality_name,
        agent_id: parseInt(agentId, 10),
        price: values.price,
        zipcode: values.zipcode,
        purpose: values.purpose,
      }
      payload = {
        clientType: "buyer",
        type: currentPage,
        source: currentPage,
        agent_id: parseInt(agentId, 10),
        emails,
        phones,
        firstname: firstName,
        lastname: lastName,
        property,
        loan_amount: values.loan_amount,
        loan_purpose: values.loan_purpose,
        credit_profile: values.credit_profile,
        is_there_a_co_borrower: values.is_there_a_co_borrower,
        household_income: values.household_income,
        secure_id: secureId,
      }
    } else {
      const property = {
        city: cityFormValue.c_locality_name,
        state: cityFormValue.state,
        address: cityFormValue.address,
        agent_id: parseInt(agentId, 10),
        price: values.price,
        zipcode: values.zipcode,
      }
      payload = {
        clientType: currentClientType,
        type: currentPage,
        source: currentPage,
        agent_id: parseInt(agentId, 10),
        emails,
        phones,
        firstname: firstName,
        lastname: lastName,
        property,
      }
    }
    setCurrentDrawerPage("search-criteria")
    updateClientFinanceTransaction({ payload, transactionId: client_id, showToast: true })
  }
  return (
    <Wrap>
      <HeaderWrap
        background="#FFFFFF"
      >
        <CloseWrap
          onClick={() => {
            setCurrentDrawerPage("search-criteria")
            setCurrentTab("FINANCING")
          }}
          fontColor={colors.black}
        >
          <LeftOutlined style={{ color: `${colors.black}` }} />
          <p>
            Back to Financing
          </p>
        </CloseWrap>
      </HeaderWrap>
      <ImageWrap>
        <Title>
          {title}
        </Title>
        <Image
          src={img}
        />
        <Description>
          {desc}
        </Description>
      </ImageWrap>
      <FormFillWrap>
        <Form
          name="transaction_form"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          ref={formRef}
          form={form}
        >
          <FormTitle>
            Client&apos;s Property Address
          </FormTitle>
          <Item
            name="location"
            initialValue={finalLocation}
          >
            <GoogleAutoComplete
              id="cities"
              types={["address"]}
              name="cities"
              // formValue={cityFormValue}
              onChange={(e) => { setLocaCity(e.target.value) }}
              location={(loc) => { setCities(loc) }}
              placeholder=""
              styles={{
                height: "49px",
                borderRadius: "0px",
                background: "white",
                fontSize: "16px",
                marginTop: "10px",
              }}
              restrict={{
                lat: 37.09024,
                lng: -95.712891,
                south_west: {
                  lat: 25.82,
                  lng: -124.39,
                },
                north_east: {
                  lat: 49.38,
                  lng: -66.94,
                },
                address: "United States",
                country: {
                  long_name: "United States",
                  short_name: "US",
                  types: [
                    "country",
                    "political",
                  ],
                },
              }}
            />
          </Item>
          <FormTitle>
            Zipcode
          </FormTitle>
          <Item
            name="zipcode"
            rules={[{
              pattern: /^\d{5,6}/,
              message: "Zipcode should be at least 5 digits",
            }]}
          >
            <InputNumber
              controls={false}
            />
          </Item>
          <FormTitle>
            Price
          </FormTitle>
          <Item
            name="price"
            initialValue={currentMaxBud}
          >
            <InputNumber
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              controls={false}
            />
          </Item>
          {currentPage === "mortgage" && (
            <>
              <FormTitle>
                Loan Amount
              </FormTitle>
              <Item
                name="loan_amount"
                rules={[{ required: true, message: "Loan Amount Required" }]}
              >
                <InputNumber
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  controls={false}
                />
              </Item>
              <FormTitle>
                Type of property
              </FormTitle>
              <Item name="type">
                <Select placeholder="Select...">
                  <Option value="Single Family">Single Family</Option>
                  <Option value="Multi-Family">Multi-Family</Option>
                  <Option value="Condominium">Condominium</Option>
                  <Option value="Townhome">Townhome</Option>
                </Select>
              </Item>
              <FormTitle>
                Main use of property
              </FormTitle>
              <Item name="purpose">
                <Select placeholder="Select...">
                  <Option value="Primary Home">Primary Home</Option>
                  <Option value="Secondary Home">Secondary Home</Option>
                  <Option value="Investment Property">Investment Property</Option>
                </Select>
              </Item>
              <FormTitle>
                Household Income
              </FormTitle>
              <Item
                name="household_income"
                rules={[{ required: true, message: "Household Income Required" }]}
              >
                <Select placeholder="Select...">
                  <Option value="$50,000 - $150,000">$50,000 - $150,000</Option>
                  <Option value="$150,001 - $300,000">$150,001 - $300,000</Option>
                  <Option value="$300,001 - $500,000">$300,001 - $500,000</Option>
                  <Option value="$500,001 +">$500,001 +</Option>
                </Select>
              </Item>
              <FormTitle>
                Is there a co-owner?
              </FormTitle>
              <Item
                name="is_there_a_co_borrower"
                rules={[{ required: true, message: "Co-owner Required" }]}
              >
                <Select placeholder="Select...">
                  <Option value={false}>No</Option>
                  <Option value={true}>Yes</Option>
                </Select>
              </Item>
              <FormTitle>
                What’s the client’s credit score?
              </FormTitle>
              <Item name="credit_profile">
                <Select placeholder="Select...">
                  <Option value="Excellent (720+)">Excellent (720+)</Option>
                  <Option value="Good (660-719)">Good (660-719)</Option>
                  <Option value="Average (620-659)">Average (620-659)</Option>
                  <Option value="Below Average (580-619)">Below Average (580-619)</Option>
                  <Option value="Low (< 579)">{"Low (< 579)"}</Option>
                </Select>
              </Item>
            </>
          )}
          <Item>
            <Button
              className="crm_mortgage_submit"
              style={{
                float: "left",
                background: `${colors.marineBLue}`,
                borderColor: `${colors.marineBLue}`,
              }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Item>
        </Form>
      </FormFillWrap>
    </Wrap>
  )
}

export default CRMV2Container(FinanceTransactionForm)
