import * as ReferralsActions from "container/Referral/actions"
import * as ProfileActions from "container/Profile/action"
import * as ClientActions from "container/Client/actions"
import * as AppActions from "container/App/actions"

export const fetchSignedAgreement = ReferralsActions.fetchSignedAgreementAction.request
export const fetchReferrals = ReferralsActions.fetchReferralsAction.request
export const fetchArchivedReferrals = ReferralsActions.fetchArchivedReferralsAction.request
export const fetchFilterSkeleton = ReferralsActions.fetchFilterSkeletonAction.request
export const fetchReferalMetric = ReferralsActions.fetchReferalMetricAction.request
export const fetchStarReferral = ReferralsActions.fetchStarReferralAction.request
export const changeInboundReferralStage = ReferralsActions.changeInboundReferralStageAction.request
export const changeInboundReferralOrder = ReferralsActions.changeInboundReferralOrderAction.request
export const signCRSAgreement = ReferralsActions.signCRSAgreementAction.request
export const subscribe = ReferralsActions.subscribeAction.request
export const unsubscribe = ReferralsActions.unsubscribeAction.request
export const fetchInvoices = ReferralsActions.fetchInvoicesAction.request
export const fetchSubscriptions = ReferralsActions.fetchSubscriptionsAction.request
export const fetchPlans = ReferralsActions.fetchPlansAction.request
export const fetchDefaultCard = ReferralsActions.fetchDefaultCardAction.request
export const updateCard = ReferralsActions.updateCardAction.request
export const fetchProspects = ReferralsActions.fetchProspectsAction.request
export const updateProspect = ReferralsActions.updateProspectAction.request
export const searchReferralByName = ReferralsActions.searchReferralByName
export const searchReferralTypeEvent = ReferralsActions.searchReferralTypeEventAction
export const selectFilter = ReferralsActions.selectFilter
export const closeFilterPanel = ReferralsActions.closeFilterPanel
export const openFilterPanel = ReferralsActions.openFilterPanel
export const fetchFilteredReferrals = ReferralsActions.fetchFilteredReferralsAction
export const clearFilters = ReferralsActions.clearFilters
export const removeFilter = ReferralsActions.removeFilter
export const getStarredReferrals = ReferralsActions.getStarredReferrals
export const openChecklistModal = ReferralsActions.openChecklistModal
export const closeChecklistModal = ReferralsActions.closeChecklistModal
export const toggleArchiveModal = ReferralsActions.toggleArchiveModalAction
export const openAddReminderModal = ReferralsActions.openAddReminderModalAction
export const closeAddReminderModal = ReferralsActions.closeAddReminderModalAction
export const getMoreReferrals = ReferralsActions.getMoreReferrals
export const openArchiveLeadModal = ReferralsActions.openArchiveLeadModalAction
export const closedArchiveLeadModal = ReferralsActions.closeArchiveLeadModalAction
export const watchArchiveReferralType = ReferralsActions.watchArchiveReferralTypeAction
export const openPaymentSettings = ReferralsActions.openPaymentSettingsAction
export const closePaymentSettings = ReferralsActions.closePaymentSettingsAction
export const updateProspectModal = ReferralsActions.updateProspectModalAction
export const updateSubscriptionStatus = ReferralsActions.updateSubscriptionStatusAction
export const addPlanToCart = ReferralsActions.addPlanToCart.action
export const removePlanFromCart = ReferralsActions.removePlanFromCart.action
export const applyPricingCoupon = ReferralsActions.applyPricingCoupon.request
export const checkoutPricingCart = ReferralsActions.checkoutPricingCart.request
export const updatePaymentPricingModal = ReferralsActions.updatePaymentPricingModal.action
export const clearPricingCart = ReferralsActions.clearPricingCart.action
export const toggleUpdateCardModal = ReferralsActions.toggleUpdateCardModal.action
export const checkPartnerSubscriptionInterest = ReferralsActions.checkPartnerSubscriptionInterestsAction.request
export const submitInterestForm = ReferralsActions.submitInterestFormAction.request
export const getISAFormDropdownValues = ReferralsActions.getISAFormDropdownValuesAction.request
export const toggleInterestConfirmationModal = ReferralsActions.toggleInterestConfirmationModalAction.call
export const sendMetricSMS = ReferralsActions.sendMetricSMSAction.request
export const fetchPricingPlans = ReferralsActions.fetchPricingPlansAction.request
export const changePlanType = ReferralsActions.changePlanTypeAction.call
export const modifyMemberQuantity = ReferralsActions.modifyMemberQuantityAction.call
export const setAmountForCheckout = ReferralsActions.setAmountForCheckoutAction.call
export const inviteTeamMember = ReferralsActions.inviteTeamMemberAction.request
export const toggleTeamInviteModal = ReferralsActions.toggleTeamInviteModalAction.call
export const handleInviteResponse = ReferralsActions.handleInviteResponseAction.request
export const createPricingMap = ReferralsActions.createPricingMapAction.call
export const fetchPlanDuration = ReferralsActions.fetchPlanDurationAction.request
export const exportToExcel = ReferralsActions.exportToExcelAction.request
export const FSISAPageVisited = ReferralsActions.FSISAPageVisitedAction.request

// SHARE THE LOVE FLOW <3
export const toggleInviteModalAction = ReferralsActions.toggleInviteModalAction.call
export const startShareTheLoveInviteFlowAction = ReferralsActions.startShareTheLoveInviteFlowAction.call

// Profile
export const getPropertyTypes = ProfileActions.getPropertyTypesAction.request

//CLIENT
export const userInterestConfirmed = ClientActions.userConfirmationModalAction.call

//TEAM
export const fetchAcceptLeadDropdownValues = ReferralsActions.fetchAcceptLeadDropdownValuesAction.call
export const toggleRemoveTeamMemberModal = ReferralsActions.toggleRemoveTeamMemberModalAction.call
export const confirmRemoveMember = ReferralsActions.confirmRemoveMemberAction.call
export const hideRemoveMemberSuccessModal = ReferralsActions.hideRemoveMemberSuccessModalAction.call
export const toggleRemoveMemberSuccessModal = ReferralsActions.toggleRemoveMemberSuccessModalAction.call
export const getTeamMembers = ReferralsActions.fetchTeamMembersAction.request

// App Actions
export const toggleScheduleCallBanner = AppActions.toggleScheduleCallBannerAction.call

// Inbound Actions
//Find Agent actions
export const fetchAgentSearch = ReferralsActions.fetchAgentSearchAction.request

