import React, { useEffect, useState } from "react"
import { reduxForm, Field, formValueSelector } from "redux-form"
import root from "window-or-global"
import {
  required,
  email,
  normalizePhone,
  minLength,
  maxLength,
  nonSpecialCharOnly,
  leastChars,
  onlyAlphabets,
} from "services/formUtils"
import HubspotForm from "dumbComponents/common/HubspotForm"
import colors from "@colors"
import US_STATES from "shared/US_STATES_RADIUS.json"
import { groupedOptions, mixedOptions} from "shared/GroupedStates"
import Button from "@ui/Button"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Input,
  PhoneInput,
  Select,
  SelectHTML,
  HiddenField,
} from "@ui/Form"
import { Checkbox } from "@ui/antd"
import styled from "styled-components"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { ATC, INTER } from "@fonts"

const SubTypeBox = styled.div``

const CheckBoxText = styled.p`
  position: relative;
  top: -6px;
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 149.523%; /* 13.457px */
`

//const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))
//const UPCOMING_STATE_OPTIONS = Object.keys(US_STATES_UPCOMING).map(key => ({ label: US_STATES_UPCOMING[key], value: US_STATES_UPCOMING[key] }))
const minLength3 = leastChars(2)
const minLength10 = minLength(10)
const maxLenth30 = maxLength(30)

// (50+, 30+, 20+, 10+, 5 or fewer)
const DEALS_CLOSED_OPTIONS = [
  { label: "30+", value: 30 },
  { label: "20+", value: 20 },
  { label: "10+", value: 10 },
  { label: "5 or fewer", value: 5 },
]

const SIGN_UP_FORM_AGENT_TYPE = [
  { value: "Independent Real Estate Agent", label: "Indie Agent" },
  { value: "Team Lead", label: "Aspiring Team Lead" },
  { value: "Team Lead", label: "Current Team Lead" },
  { value: "Managing Broker", label: "Brokerage Owner" },
  { value: "Agent Looking for Team", label: "Agent Looking for Team" },
  { value: "Other", label: "Other" },
]

const I_WANT_TO_OPTIONS = [
  { value: "Start my own team / brokerage", label: "Start my own team / brokerage", production: "group" },
  { value: "Up-level my existing team / brokerage", label: "Up-level my existing team / brokerage", production: "group" },
  { value: "Join a high-performing team", label: "Join a high-performing team", production: "annual" },
  { value: "Create a solo brand", label: "Create a solo brand", production: "annual" },
]

const ANNUAL_PRODUCTION = [
  { value: "$1M or less", label: "$1M or less" },
  { value: "$1M - $2.9M", label: "$1M - $2.9M" },
  { value: "$3M - $10M", label: "$3M - $10M" },
  { value: "$11M - $25M", label: "$11M - $25M" },
  { value: "+$25M", label: "+$25M" },
]

const GROUP_PRODUCTION = [
  { value: "$250M", label: "$250M+" },
  { value: "$100-$250M", label: "$100M-$250M" },
  { value: "$50M-$100M", label: "$50M-$100M" },
  { value: "$25M-$50M", label: "$25M-$50M" },
  { value: "$10M-$25M", label: "$10M-$25M" },
  { value: "< $10M", label: "< $10M" },
]

const getAccountTypeObject = (type) => {
  switch (type) {
    case "owner":
      return { value: "Managing Broker", label: "Brokerage Owner" }
    case "lead":
      return { value: "Team Lead", label: "Current Team Lead" }
    case "indie":
      return { value: "Independent Real Estate Agent", label: "Indie Agent" }
    default:
      return { value: "", label: "" }
  }
}

const formatGroupLabel = data => (
  <SubTypeBox>
    <span
      style={{
        color: "black",
      }}
    >
      {data.label}
    </span>
  </SubTypeBox>
)

const Wrap = styled.form`
  label {
    color: ${colors.black};
  }
`

const Spacer = styled.div`
  height: 12px;
`

const SubmitButton = styled.button`
  width: 100%;
  background-color: ${colors.black};
  width: 100%;
  height: 42px;
  border-radius: 24.5px;
  border: none;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  // z-index: 1;
  color: #fff;

  ${props => props.disabled && `
    cursor: not-allowed;
    background-color: #9C9C9C !important;
  `}
`

const customStyles = {
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontSize: "12px",
    color: "#ccc",
    fontWeight: "300",
    fontFamily: "${INTER}",
    letterSpacing: "0.5px",
  }),
  control: provided => ({
    ...provided,
    border: "1px solid #A5A8FF",
    borderRadius: "8px",
    height: "42px",
  }),
  option: provided => ({
    ...provided,
    fontFamily: {ATC},
    fontWeight: 300,
    color: colors.black,
    fontSize: 12,
    borderRadius: 0,
    textAlign: "left",
    zIndex: 99999,
  }),
  groupHeading: defaultStyles => ({
    ...defaultStyles,
    fontSize: "12px",
    color: "#ccc",
  }),
  input: provided => ({
    ...provided,
    fontSize: 12,
  }),
  valueContainer: defaultStyles => ({
    ...defaultStyles,
    fontSize: 12,
  }),
}

const errorStyles = {
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontSize: "12px",
    color: "#ccc",
    fontWeight: "300",
    fontFamily: "${INTER}",
    letterSpacing: "0.5px",
  }),
  control: provided => ({
    ...provided,
    border: "1px solid #ED1616",
    borderRadius: "8px",
    height: "42px",
  }),
  option: provided => ({
    ...provided,
    fontFamily: {ATC},
    fontWeight: 300,
    color: colors.black,
    fontSize: 12,
    borderRadius: 0,
    textAlign: "left",
    zIndex: 99999,
  }),
  groupHeading: defaultStyles => ({
    ...defaultStyles,
    fontSize: "12px",
    color: "#ccc",
  }),
  input: provided => ({
    ...provided,
    fontSize: 12,
  }),
  valueContainer: defaultStyles => ({
    ...defaultStyles,
    fontSize: 12,
  }),
}

const Form = (props) => {
  const {
    handleSubmit,
    isSubmitting,
    initialize,
    user,
    isWatchPage,
    accountType,
    className,
    webClassName,
    signUpFormValues,
    isLandingPage,
  } = props || {}

  const {
    account_type: productionType,
  } = signUpFormValues || {}

  const [optIn, setOptIn] = useState(false)

  // useEffect(() => {
  //   if (user) {
  //     initialize({
  //       firstname: `${user.firstname} ${user.lastname}`,
  //       phone: user.phone,
  //       email: user.primary_email,
  //       state: user.state || "Select",
  //       agency_name: user.agency_name || "",
  //     })

  //     handleDataLayerPush(user, "lead_form_impression", "",)

  //   } else if (accountType) {
  //     // initialize({
  //     //   account_type: getAccountTypeObject(accountType),
  //     // })
  //   }
  // }, [])

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script)

    // script.addEventListener("load", () => {
    //   // @TS-ignore
    //   if (root.hbspt) {
    //   // @TS-ignore
    //     root.hbspt.forms.create({
    //       region: "na1",
    //       portalId: "7498349",
    //       formId: "80767e3a-3a65-4245-b481-e96845e1dcf2",
    //       target: "#hubspotForm",
    //     })
    //   }
    // })
    handleDataLayerPush("", "lead_form_impression", "",)

    root.addEventListener("message", async (event) => {
      if (
        event.data.type === "hsFormCallback"
        && event.data.eventName === "onFormSubmitted"
      ) {
        if (root.dataLayer) {
          const payload = {
            event: "lead_form_submit",
            state: event.data.data.submissionValues.state_2023,
            annual_production:
                event.data.data.submissionValues
                  .group_s_annual_production
                  || event.data.submissionValues.your_annual_production,
            ag_eml: event.data.data.submissionValues.email,
            ag_nm:
                event.data.data.submissionValues.firstname
                + event.data.data.submissionValues.lastname,
            agent_id: "",
          }
          await root.dataLayer.push(payload)
        }
      }
    })
  }, [])

  let LABEL_STYLES = {
    color: colors.black,
  }

  if (isWatchPage) {
    LABEL_STYLES = {
      ...LABEL_STYLES,
      backgroundColor: "#F9F9F7",
    }
  }

  let placeholderErrorSelect = {
    fontSize: "12px",
    color: "#ED1616",
    fontWeight: "300",
    fontFamily: "${INTER}",
    letterSpacing: "0.5px",
  }

  // const groupedOptions = [
  //   {
  //     label: "Available",
  //     options: STATE_OPTIONS.map(key => ({ value: key.value, label: key.label })),
  //   },
  //   {
  //     label: "Coming Soon",
  //     options: UPCOMING_STATE_OPTIONS.map(key => ({ value: key.value, label: key.label })),
  //   },
  // ]

  const ANNUAL = ["Join a high-performing team", "Create a solo brand"]
  const GROUP = ["Start my own team / brokerage", "Up-level my existing team / brokerage"]

  return (
    <HubspotForm
      region="na1"
      portalId="7498349"
      formId="80767e3a-3a65-4245-b481-e96845e1dcf2"
    />
  )

  // return (
  //   <Wrap
  //     onSubmit={handleSubmit}
  //     className={webClassName || className || "form_explore_ownership_legacy_web"}
  //   >
  //     <Field
  //       component={HiddenField}
  //       name="unique_form_submitted"
  //       label=""
  //       placeholder="explore_ownership_mql_form"
  //       hidden
  //       readOnly
  //       value="explore_ownership_mql_form"
  //     />
  //     <Field
  //       component={Input}
  //       validate={[required, minLength3, maxLenth30, onlyAlphabets]}
  //       name="firstname"
  //       label=""
  //       placeholder="First Name"
  //       labelStyles={{
  //         ...LABEL_STYLES,
  //       }}
  //       isLandingPage={isLandingPage}
  //       customInputStyle={{
  //         paddingLeft: 10,
  //         borderRadius: "8px",
  //         fontFamily: "${INTER}",
  //         border: "1px solid #A5A8FF",
  //         fontSize: "12px",
  //         letterSpacing: "0.5px",
  //         height: "42px",
  //       }}
  //       required
  //     />
  //     <Spacer />
  //     <Field
  //       component={Input}
  //       validate={[required, minLength3, maxLenth30, onlyAlphabets]}
  //       name="lastname"
  //       label=""
  //       placeholder="Last Name"
  //       labelStyles={{
  //         ...LABEL_STYLES,
  //       }}
  //       isLandingPage={isLandingPage}
  //       customInputStyle={{
  //         paddingLeft: 10,
  //         borderRadius: "8px",
  //         fontFamily: "${INTER}",
  //         border: "1px solid #A5A8FF",
  //         fontSize: "12px",
  //         letterSpacing: "0.5px",
  //         height: "42px",
  //       }}

  //     />
  //     <Spacer />
  //     <Field
  //       isLandingPage={isLandingPage}
  //       component={PhoneInput}
  //       validate={[minLength10, required]}
  //       normalize={normalizePhone}
  //       name="phone"
  //       label=""
  //       placeholder="Phone Number"
  //       isShowAreaCode={false}
  //       inputStyle={{
  //         paddingLeft: 10,
  //         borderRadius: "8px",
  //         fontFamily: "${INTER}",
  //         border: "1px solid #A5A8FF",
  //         fontSize: "12px",
  //         height: "42px",
  //         // height: "22px",
  //         //padding: "8px 91px 5px 16px",
  //         letterSpacing: "0.5px",
  //       }}
  //       labelStyles={{
  //         ...LABEL_STYLES,
  //       }}
  //       isMaterial={false}
  //     />
  //     <Spacer />
  //     <Field
  //       component={Input}
  //       isLandingPage={isLandingPage}
  //       validate={[required, email]}
  //       name="email"
  //       label=""
  //       placeholder="Email"
  //       labelStyles={{
  //         ...LABEL_STYLES,
  //       }}
  //       customInputStyle={{
  //         paddingLeft: 10,
  //         borderRadius: "8px",
  //         fontFamily: "${INTER}",
  //         border: "1px solid #A5A8FF",
  //         fontSize: "12px",
  //         letterSpacing: "0.5px",
  //         height: "42px",
  //       }}
  //       components={{
  //         IndicatorSeparator: () => null,
  //       }}
  //       required
  //     />
  //     <Spacer />
  //     <Field
  //       component={SelectHTML}
  //       validate={[required]}
  //       onBlur={event => event.preventDefault()}
  //       options={groupedOptions}
  //       name="state"
  //       label=""
  //       placeholder="State"
  //       optionType="1"
  //       isLandingPage
  //       formatGroupLabel={formatGroupLabel}
  //       selectStyles={customStyles}
  //       components={{
  //         IndicatorSeparator: () => null,
  //       }}
  //       labelStyles={{
  //         margin: "0px",
  //       }}
  //       errorStyles={errorStyles}
  //     />
  //     <Spacer />
  //     <Field
  //       component={SelectHTML}
  //       validate={[required]}
  //       onBlur={event => event.preventDefault()}
  //       name="account_type"
  //       options={I_WANT_TO_OPTIONS}
  //       label=""
  //       optionType="2"
  //       placeholder="I want to"
  //       selectStyles={customStyles}
  //       isLandingPage
  //       labelStyles={{
  //         color: colors.black,
  //         margin: "0px",
  //       }}
  //       components={{
  //         IndicatorSeparator: () => null,
  //       }}
  //       errorStyles={errorStyles}
  //       required
  //     />
  //     <Spacer />
  //     {productionType && GROUP.includes(productionType) && (
  //       <Field
  //         component={SelectHTML}
  //         validate={[required]}
  //         onBlur={event => event.preventDefault()}
  //         options={GROUP_PRODUCTION}
  //         name="group_annual_production"
  //         label=""
  //         optionType="2"
  //         placeholder="Group's Annual Production"
  //         selectStyles={customStyles}
  //         isLandingPage
  //         components={{
  //           IndicatorSeparator: () => null,
  //         }}
  //         labelStyles={{
  //           margin: "0px",
  //         }}
  //         required
  //       />
  //     )}
  //     {productionType && ANNUAL.includes(productionType) && (
  //       <Field
  //         component={SelectHTML}
  //         validate={[required]}
  //         onBlur={event => event.preventDefault()}
  //         options={ANNUAL_PRODUCTION}
  //         name="annual_production"
  //         label=""
  //         optionType="2"
  //         placeholder="Annual Production"
  //         selectStyles={customStyles}
  //         isLandingPage
  //         components={{
  //           IndicatorSeparator: () => null,
  //         }}
  //         labelStyles={{
  //           margin: "0px",
  //         }}
  //         errorStyles={errorStyles}
  //         required
  //       />
  //     )}
  //     <Spacer style={{ height: "15px" }} />
  //     <Checkbox
  //       onChange={() => {
  //         setOptIn(!optIn)
  //       }}
  //     >
  //       <CheckBoxText>
  //         {`I agree to be contacted by Radius via call, email, and text. 
  //         To opt out, you can reply "stop" at any time or click the unsubscribe 
  //         link in the emails. Message and data rates may apply.`}
  //       </CheckBoxText>
  //     </Checkbox>
  //     <Spacer style={{ height: "18px" }} />
  //     <SubmitButton type="submit" disabled={!optIn || isSubmitting}>
  //       {isSubmitting ? (
  //         <ThreeDotLoader size={10} color={colors.white} />
  //       ) : (
  //         "Submit"
  //       )}
  //     </SubmitButton>
  //   </Wrap>
  // )
}

const WrappedForm = reduxForm({
  form: "APPLY_FORM_V2",
  destroyOnUnmount: true,
})(Form)

export default WrappedForm
