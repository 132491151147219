import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import Card from "./Card"
import AppFormV2 from "../AppFormV2"
import QRCodeV2 from "../QRCodeV2/index"
import { BlueButton } from "../commonStyles"

const Wrap = styled.div`
  width: 1448px;
  height: 377px;
  // background: #F0F1FF;
  // padding: 74px 0px 0px 148px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 42px;

  @media(max-width: 1099px) {
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 10px;
    margin: auto;
    justify-content: center;
  }
`

const AppFormWrap = styled.div`
  position: relative;
  left: -15px;
  
  @media(max-width: 1099px) {
      display: none;
    }
`

const MobileCTAWrap = styled.div`
  display: none;
  @media(max-width: 1099px) {
    display: block;
  }
`

const HeroSection = ({
  appDownloadActionResponse,
  isCurrentUserOniOS,
}) => {
  const {
    data: appDownloadData,
    isFetching: submittingForm,
  } = appDownloadActionResponse || {}

  return (
    <Wrap>
      <Card
        img={`${CLOUDFRONT}/CommunityLogoPng.png`}
        heroText="Community"
        subText="Enjoy the benefits of a community 85,000 strong… in the palm of your hand."
        logoHeight="66px"
        logoWidth="66px"
        width="489px"
      />
      <AppFormWrap>
        <QRCodeV2
          isDownloadAppFormSubmitted
          appDownloadSMSData={appDownloadData}
          isSubmitting={submittingForm}
          width="492px"
          className="form_submit_community_download_web"
        />
      </AppFormWrap>
      <MobileCTAWrap>
        <BlueButton
          className="join_our_community_mobile"
          onClick={() => {
            const url = isCurrentUserOniOS
              ? "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8" 
              : "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
            root.open(url, "_blank")
          }}
        >
          Join The Community
        </BlueButton>
      </MobileCTAWrap>
    </Wrap>
  )
}

export default ApplyFlowContainer(HeroSection)
