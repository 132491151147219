import axios from "axios"

export const submitTeamDetails = payload => axios
  .post(`${API_URL}brokerage-teams/submit-team-details`, payload)

export const getTeamDetailsAPI = id => axios
  .get(`${API_URL}brokerage-teams/get-team-details/${id}`)

export const getTeamDetailsViaNameAPI = name => axios
  .get(`${API_V2_URL}brokerage-teams/get-team-details/${name}`)

export const getTCAgentAssignedAPI = agentId => axios.get(`${API_URL}brokerage-tc-list?agent_id=${agentId}`)

export const getTeamTCsAPI = id => axios.get(`${API_URL}brokerage-team-tc/${id}`)

export const inviteTeamTCsAPI = payload => axios.post(`${API_URL}brokerage-team-tc`, payload)
