import React, { useEffect, useState } from "react"
import root from "window-or-global"
import styled from "styled-components"
import GradientBoxV2 from "../GradientBoxV2/index"
import { DISPLAY_OBJECT } from "./Data"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  top: -5px;

  @media(max-width: 1099px) {
    gap: 20px;
  }

`

const Card = styled.div`
  width: 544px;
  height: 350px;
  padding: 48px 54px 54px 48px;

  @media(max-width: 1099px) {
    padding: unset;
    width: 100%;
    height: max-content;
    min-height: 350px;
    padding-top: 20px;
  }
`

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 15px;

  @media(max-width: 1099px) {
    margin-bottom: 35px;
  }
`

const ImageWrap = styled.div`
  img {
    width: 75.768px;
    // height: 54.915px;
  }
`

const Name = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.816px;
`

const TestimonialSection = styled.div`
  min-height: 120px;
  p {
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 15.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.84px;
  }
  
  @media(max-width: 1099px) {
      min-height: 155px;
  }

  border-bottom: 0.5px solid #898989;
`

const TeamLogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;

  height: ${props => props.height};
  width: ${props => props.width};

  background: url(${props => props.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 15px auto;

  img {
    width: 106.188px;
    // height: 45px;
    flex-shrink: 0;
  }
`

const SectionSeven = () => {
  const logoWidth = 544
  const screenWidth = root.innerWidth // Get the screen width
  const numLogos = DISPLAY_OBJECT.length

  const [position, setPosition] = useState(0)

  useEffect(() => {
    const moveLogos = () => {
      setPosition(prevPosition => (prevPosition + 1) % (logoWidth * numLogos + screenWidth))
    }

    const intervalId = setInterval(moveLogos, 20) // Adjust the speed of movement
    return () => clearInterval(intervalId)
  }, [logoWidth, numLogos, screenWidth])

  return (
    <>
      <Wrap>
        {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
          <GradientBoxV2
            minWidth="261px"
            // mobileMinWidth="168px"
            largeDesktopWidth="544px"
            mobileFlexJC="flex-start"
            mobileFlexAI="baseline"
            mobileWidth="348px"
            mobileHeight="410px"
            customStyle={{
              transform: `translateX(-${position}px)`,
              flexShrink: 0,
            }}
          >
            <Card>
              <TitleSection>
                <ImageWrap>
                  <img src={item.personImage} alt={item.personName} />
                </ImageWrap>
                <Name>{item.personName}</Name>
              </TitleSection>
              <TestimonialSection>
                <p>{item.personTestimonial}</p>
              </TestimonialSection>
              <TeamLogoSection
                width={item.logoWidth}
                height={item.logoHeight}
                img={item.personTeamLogo}
              >
                {/* <img src={item.personTeamLogo} alt={`${item.personName} Team`} /> */}
              </TeamLogoSection>
            </Card>
          </GradientBoxV2>
        ))}
      </Wrap>
    </>
  )
}

export default SectionSeven
