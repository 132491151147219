import React from "react"
import styled from "styled-components"
import { reduxForm } from "redux-form"
import Select from "react-select"
import SearchIcon from "images/icons/search-icon.svg"
import Box from "@ui/Box"
import CloseIcon from "images/icons/cancel.png"
import {
  Wrapper,
  SearchInputWrap,
  StyledButton,
  Footer,
  Header,
  SaveButton,
} from "./commonStyles"

const AwardList = styled.div`
  margin: 10px 20px;
`

const Award = styled.div`
  border-top: 1px solid #e7ebed;
  display: inline-flex;
  border-radius: 4px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  background: #e9f7fd;
  margin-left: 10px;
  p {
    flex: 1;
    margin: 0px;
    margin-right: 20px;
  }

  span {
    width: 100px;
    display: flex;
    color: rgba(0, 0, 0, 0.5);
  }
`

const Img = styled.img`
  height: 10px;
  cursor: pointer;
`

const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin: 10px 20px;
`

const createYearList = () => {
  const currentYear = new Date().getFullYear()
  const yearList = []
  const size = 40
  for (let i = currentYear - size; currentYear >= i; i += 1) {
    yearList.push({ value: i, label: i })
  }
  return yearList.reverse()
}

class Accomplishments extends React.Component {
  constructor(props) {
    super(props)
    const { initialValues } = this.props
    this.state = {
      tagList: initialValues,
      yearList: createYearList(),
      selectedYear: null,
      isChanged: false,
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  addItem = () => {
    const { search, tagList, selectedYear } = this.state
    if (search && selectedYear) {
      this.setState({
        tagList: [...tagList, { award: search, year: selectedYear }],
        search: "",
        selectedYear: "",
        error: "",
        isChanged: true,
      })
    } else {
      this.setState({
        error: "Please enter award and year",
      })
    }
  }

  handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      this.addItem()
    }
  }

  selectYear = (val) => {
    this.setState({
      selectedYear: val.value,
      isChanged: true,
    })
  }

  handleSave = () => {
    const { saveProfileUpdate, initialValues } = this.props
    const { tagList } = this.state
    if (tagList === initialValues) {
      this.setState({
        error: "Please add an award before saving",
      })
    } else {
      saveProfileUpdate({
        awards: [...tagList],
      })
    }
  }

  removeItem = (item) => {
    const { tagList = [] } = this.state
    const itemIndex = tagList.indexOf(item)
    tagList.splice(itemIndex, 1)
    this.setState({
      tagList: [...tagList],
      isChanged: true,
    })
  }

  render() {
    const { handleSubmit, isSavingUpdates } = this.props
    const {
      search,
      tagList,
      yearList,
      selectedYear,
      error,
      isChanged,
    } = this.state
    return (
      <Wrapper>
        <Header>
          <h4>What are your accomplishments?</h4>
        </Header>
        <Box d="flex" p="15px">
          <SearchInputWrap onSubmit={handleSubmit} flex="1">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              name="search"
              component="input"
              type="text"
              placeholder="Add accomplishments"
              value={search}
              onChange={this.handleInput}
              onKeyPress={this.handleEnterPress}
            />
          </SearchInputWrap>
          <div style={{ width: 100 }}>
            <Select
              placeholder="Year"
              options={yearList}
              value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
              onChange={this.selectYear}
            />
          </div>
          <StyledButton
            bsStyle="secondary"
            width="95"
            onClick={() => { this.addItem() }}
          >
            Add +
          </StyledButton>
        </Box>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        <p></p>
        <AwardList>
          {tagList.map(item => (
            <Award key={item.year}>
              <div style={{ marginRight: 20 }}>
                <p>{item.award}</p>
                <span>{item.year}</span>
              </div>
              <Img
                src={CloseIcon}
                alt="close icon"
                onClick={() => { this.removeItem(item) }}
              />
            </Award>
          ))}
        </AwardList>
        <Footer d="flex" jc="flex-end">
          <SaveButton
            width="100px"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSave}
            disabled={!isChanged}
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "PROFILE_LANGUAGES_FORM",
})(Accomplishments)

