import React from "react"
import { required } from "services/formUtils"
import {
  Input,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"
import { withRouter } from "react-router-dom"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"
import {
  // DETAILS_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../../Routes"
import {
  RADIUS_TRANSACTION_ALL_CASH_OFFER_CONFIRMATION,
  FIELD_VALUES,
} from "./data"
import SubmitButton from "../Common/SubmitButton"
import CheckboxComponent from "../Common/RadioGroup"
import {
  Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
} from "../Common/CommonStyles"
import {
  HandleSubmit,
  FunctionalComponentDidMount,
  backButtonUrl,
  BrowserCloseListener,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"

const TransactionFormUI = ({
  ...props
}) => {
  const {
    id,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    history,
    handleSubmit,
    invalid,
    fetchTransactionCoordinationDocumentsResponse,
    isAllCashOffer,
    existingIsAllCashOffer,
    destroy,
    formName,
    isFormDisabled,
  } = props || {}

  BrowserCloseListener()

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  const {
    isFetching: creatingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName)
  }

  const isProcessing = fetchingValues || creatingForm || updatingTCStage || isFetchingDeals

  return (
    <>
      {fetchingValues ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          {/*<CheckboxComponent
            noButtonReposition
            name="is_radius_tc"
            validate={[required]}
            optionArray={RADIUS_TRANSACTION_COORDINATION_USAGE_CONFIRMATION}
            labelText="Are you using Radius Transaction Coordination?"
            marginBottom="31px"
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          />*/}
          <CheckboxComponent
            noButtonReposition
            validate={[required]}
            name="is_all_cash_offer"
            optionArray={RADIUS_TRANSACTION_ALL_CASH_OFFER_CONFIRMATION}
            labelText="Is this transaction an all cash offer?"
            marginBottom="31px"
            disabled={isFormDisabled}
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          />
          {(existingIsAllCashOffer && existingIsAllCashOffer !== undefined && !existingIsAllCashOffer)
           || (isAllCashOffer !== undefined && isAllCashOffer === "is_all_cash_offer_no") && (
             <Field
               name="lender_email_address"
               type="email"
               component={Input}
               label="Lender Email Address"
               validate={[required]}
               disabled={isFormDisabled}
             />
           )}
          <CTAWrap>
            <BackButton
              type="button"
              onClick={() => {
                backButtonUrl(history, id, TCAGENT_FORM_ROUTE)
              }}
            >
              Back
            </BackButton>
            <SubmitButton
              disabled={isFormDisabled}
              isProcessing={isProcessing}
              text={isFormDisabled ? "Next" : "Continue"}
              isInvalid={invalid}
            />
          </CTAWrap>
        </FormWrap>
      )}
    </>
  )
}

const CoordinationFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  properties,
  props,
  toggleErrorModal,
  fetchTCFiles,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  const disableField = isCompleted || isCoListed || getIsEdit(currentDeal)

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  const {
    is_radius_tc,
    is_all_cash_offer,
  } = combinedTCValues || {}

  const EscrowFormRoute = ["dual", "pending_contract", "external_referral_agreement"]

  let nextRoute = combinedTCValues
  && combinedTCValues.file_type
  && EscrowFormRoute.includes(combinedTCValues.file_type) ? ESCROW_FORM_ROUTE : SIGN_POST_FORM_ROUTE

  // let nextRouteV2 = combinedTCValues
  // && combinedTCValues.file_type
  // && combinedTCValues.file_type === new_listing ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE

  // if (parsedQuery.id && deals) {
  //   const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
  //   nextRoute = existingDeal && existingDeal.file_type === "new_listing" ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE
  // }

  if (parsedQuery.id && deals) {
    const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
    nextRoute = existingDeal && EscrowFormRoute.includes(existingDeal.file_type)
      ? ESCROW_FORM_ROUTE : existingDeal.file_type !== "new_listing" ? UPLOAD_FILE_FORM_ROUTE : SIGN_POST_FORM_ROUTE
  }

  const {
    is_radius_tc: existingIsRadiusTC,
    is_all_cash_offer: existingIsAllCashOffer,
  } = currentDeal || {}

  return (
    <Wrap>
      <Header
        text="Transaction Coordination"
        history={history}
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <TransactionForm
          isRadiusTC={is_radius_tc}
          isAllCashOffer={is_all_cash_offer}
          existingIsRadiusTC={existingIsRadiusTC}
          existingIsAllCashOffer={existingIsAllCashOffer}
          onSubmit={values => HandleSubmit(
            values,
            nextRoute,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "transaction_coordination",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          id={parsedQuery.id}
          formName="TC_COORDINATION_DETAILS_FORM"
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const TransactionForm = reduxForm({
  form: "TC_COORDINATION_DETAILS_FORM",
  destroyOnUnmount: false,
})(TransactionContainer(withRouter(TransactionFormUI)))

export default TransactionContainer(withRouter(CoordinationFormComponent))
