import * as React from 'react'

const Mail = props => {
  const { color = '#5A5FF2' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M3 7.3v0c0 .6.3 1.1.8 1.5l6 4.1c1.4.9 3.1.9 4.5 0l6-4c.4-.5.7-1 .7-1.6v0c0-1-.8-1.8-1.8-1.8H4.8c-1 0-1.8.8-1.8 1.8Z"
        clipRule="evenodd"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M3 7.5v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-10M3.59 18.912l6.094-6.094M14.36 12.86l6.051 6.052"
      />
    </svg>
  )
}

export default Mail
