import {
  takeLatest,
  call,
  put,
} from "redux-saga/effects"
import createToast from "@ui/Toast"
import { isSuccess } from "services/Utils"
import { getAllRarMentorsListApi, getRarMentorsFromApi, saveRarMentorsFormApi } from "./api"
import { getAllRarMentorsList, getRarMentorsFrom, saveRarMentorsForm } from "./actions"

function* handleFetchAllRARMentorsList() {
  try {
    const res = yield call(getAllRarMentorsListApi)
    if (isSuccess(res)) {
      yield put(getAllRarMentorsList.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getAllRarMentorsList.failure(error))
  }
}

function* handleFetchRarMentorsForm() {
  try {
    const res = yield call(getRarMentorsFromApi)
    if (isSuccess(res)) {
      yield put(getRarMentorsFrom.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getRarMentorsFrom.failure(error))
  }
}

function* handleSaveRarMentorsForm(action) {
  try {
    const { details, history } = action.data
    const res = yield call(saveRarMentorsFormApi, { registrationDetails: details })
    if (isSuccess(res)) {
      yield put(saveRarMentorsForm.success(res.data && res.data.response))
      history.push("/realty/mentoring/form-submitted")
      createToast("Registration successful !")
    }
  } catch (error) {
    yield put(saveRarMentorsForm.failure(error))
    createToast("Registration Error, Please try after sometime !")
  }
}

export default function* main() {
  yield takeLatest(getAllRarMentorsList.REQUEST, handleFetchAllRARMentorsList)
  yield takeLatest(getRarMentorsFrom.REQUEST, handleFetchRarMentorsForm)
  yield takeLatest(saveRarMentorsForm.REQUEST, handleSaveRarMentorsForm)
}
