import React from "react"
import styled from "styled-components"
import colors from "@colors"
import CircularImage from "dumbComponents/common/CircularImage"
import root from "window-or-global"
import Star from "images/recruit-and-earn/star.png"

import { ShimmerBox } from "shared/styles/animation"

// eslint-disable-next-line max-len
import { LeaderBoardHeading, LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"

const Wrap = styled.div`
  ${props => props.withBackground && `
    background: ${colors.lightGrayishBlue}
    border: 1px solid ${colors.moonGrey};
    border-radius: 8px;
    padding: 12px 8px;
    margin: 12px 9px;
  `}
  ${props => !props.withBackground && `
    border-top: 1px solid ${colors.silentWhite};
    padding: 12px 8px 0 8px;
    margin: 12px 9px 0 9px;
  `}
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Head = styled(LeaderBoardHeading)`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 0 0 7px;
  color: ${colors.deepPurple}
`

const Details = styled.div`
  padding: 0px 12px;
`
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    ${LeaderBoardFontStyle}
    position: absolute;
    margin-left: -8px;
    padding: 0 0 2px 2px;
    text-align: center;
    width: 16px;
    height: 17px;
    background: ${colors.white};
    border-radius: 40px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    z-index: 99;
  }
  img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
`

const NameWrapper = styled(LeaderBoardHeading)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
  margin: 0;
  ${props => props.withBackground && `
    color: ${colors.deepPurple};
  `}
`

const ListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const TotalCount = styled(LeaderBoardHeading)`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  margin: 0 57px 0 0;
  ${props => props.withBackground && `
    color: ${colors.deepPurple};
  `}
`
const ProfileWarp = styled.div`
  display: flex;
  align-items: center;
`

const StarIcon = styled.img`
  width: 16px;
  height: 16px;
  left: 14px;
  bottom: 10px;
  margin-bottom: -6px;
`

const LeaderContainer = styled.div`
  display: flex;
  justify-content: start;
`

const openProfile = (profileUrl) => {
  if (profileUrl) root.open(profileUrl)
}

const LeaderboardList = ({
  headText, agentName, imgSrc, withBackground, rank, totalCount, isLoading, profileUrl,
}) => (
  <Wrap withBackground={withBackground} onClick={() => { openProfile(profileUrl) }}>
    {isLoading ? (<LoadingLeaderBoard />) : (
      <ListWrap>
        <ProfileWarp>
          <ImageWrapper>
            {!withBackground && (<span>{rank}</span>)}
            <CircularImage
              name={agentName}
              size={48}
              charSize={16}
              image={imgSrc || ""}
              purpleBorder={withBackground}
            />
          </ImageWrapper>
          <Details>
            {withBackground && (
              <LeaderContainer>
                <StarIcon
                  src={Star}
                  alt="star.png"
                />
                <Head>
                  LEADER
                </Head>
              </LeaderContainer>
            )}
            <NameWrapper withBackground={withBackground}>
              {agentName}
            </NameWrapper>
          </Details>
        </ProfileWarp>
        <div>
          <TotalCount withBackground={withBackground}>
            {totalCount}
          </TotalCount>
        </div>
      </ListWrap>
    )}
  </Wrap>
)

const LoadingLeaderBoard = () => (
  <ListWrap>
    <ProfileWarp>
      <ImageWrapper>
        <ShimmerBox w="50px" h="50px" br="50%" style={{ margin: "0px auto" }} />
      </ImageWrapper>
      <Details>
        <NameWrapper>
          <ShimmerBox w="200px" h="16px" style={{ margin: "0px auto" }} />
        </NameWrapper>
        <NameWrapper>
          <ShimmerBox w="100px" h="16px" style={{ margin: "0px auto" }} />
        </NameWrapper>
      </Details>
    </ProfileWarp>
    <div>
      <TotalCount>
        <ShimmerBox w="22px" h="22px" br="50%" style={{ margin: "0px auto" }} />
      </TotalCount>
    </div>
  </ListWrap>
)

export default LeaderboardList
