import React from "react"
import styled from "styled-components"
import colors from "@colors"
import recruitAndEarnContainer from "container/App/recruitAndEarnV2Container"
import { moneyFormatter } from "shared/currencyUtils"
import { Link } from "react-router-dom"

const Wrap = styled.div`
  height: max-content;
  width: 100%;
  border-radius: 16px;
  padding: 16px 0px;
  border: 1px solid ${colors.marineBLue};
  background: ${colors.fadedBlueSea};
  display: flex;
  flex-direction: column;
`

const TextWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${colors.marineBLue};
  padding-bottom: 9px;
  border-bottom: 1px solid ${colors.marineBLue};
`

const DuoTextWrap = styled.div`
  display: flex;
  text-align: center;
  padding-top: 17px;
  padding-bottom: 16px;
`

const DuoText = styled.div`
  font-size: 28px;
  color: #3B60E4;
  display: flex;
  flex-direction: column;
  width: 50%;

  &:first-child {
    border-right: 1px solid ${colors.fungiWhite};
  }
`

const DuoTextSilent = styled.div`
  font-size: 12px;
  width: 50%;
  opacity: 50%;
  align-self: center;
  margin-top: 10px;
`

const EarnMoreButton = styled.button`
  border-radius: 4px;
  height: 28px;
  width: 154px;
  background: transparent;
  margin: 0px 31px;
  border-radius: 100px;
  color: ${colors.marineBLue};
  border: 1px solid ${colors.marineBLue};
  position: relative;
  align-self: center;
  cursor: pointer;
`

const SidebarStatBox = ({
  getReferralCumulativeDataResponse,
  toggleRecruitAndEarnForm,
  toggleFormModal,
}) => {
  const {
    data: referralData,
  } = getReferralCumulativeDataResponse || {}
  const { totalInvitationAccepted, totalRewards } = referralData || 0
  return (
    <Wrap>
      <TextWrap>Refer &amp; Earn</TextWrap>
      <DuoTextWrap>
        <DuoText>
          {totalInvitationAccepted}
          <DuoTextSilent>
            Agent Referred
          </DuoTextSilent>
        </DuoText>
        <DuoText>
          {moneyFormatter(totalRewards, 1, false, true)}
          <DuoTextSilent>
            Rewards Earned
          </DuoTextSilent>
        </DuoText>
      </DuoTextWrap>
      <Link
        to={totalInvitationAccepted > 0 ? "/realty/recruit-and-earn" : "/realty/recruit-and-earn/onboarding"}
        onClick={() => {
          toggleRecruitAndEarnForm(true)
          toggleFormModal(true)
        }}
      >
        <EarnMoreButton>
          Earn More
        </EarnMoreButton>
      </Link>
    </Wrap>
  )
}

export default recruitAndEarnContainer(SidebarStatBox)
