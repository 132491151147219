import React from "react"
import styled from "styled-components"
import colors from "@colors"
import icons from "@icons"

const StyledTag = styled.span`
  padding: 10px 8px;
  border-radius: 6px;
  background-color: ${props => props.isSelected ? colors.tagActive : "white"};
  border: 1px solid ${props => props.isSelected ? colors.tagActive : colors.tagInactive};
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
  color: ${props => props.isSelected ? colors.contentText : colors.tagInactive};
`

const StyledCloseIcon = styled.i`
  font-size: 12px;
  margin-left: 6px;
`

const Tag = (props) => {
  const { children, isSelected, onClick, showCross } = props
  return (
    <StyledTag
      isSelected={isSelected}
      onClick={onClick}
    >
      {children}
      {showCross && <StyledCloseIcon className={icons.arrowsClose} />}
    </StyledTag>
  )
}

export default Tag
