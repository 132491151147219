import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "MARKETPLACE"

// API Actions
export const getLeadForMarketplaceAction = createSignalAction(BASE, "GET_LEAD_MARKETPLACE_LEADS")
export const sendDownloadLinkToPhoneAction = createSignalAction(BASE, "SEND_DOWNLOAD_LINK_TO_PHONE")
export const fetchRecentlyClaimedLeadsAction = createSignalAction(BASE, "FETCH_RECENTLY_CLAIMED_LEAD")
export const fetchUserPreferranceAction = createSignalAction(BASE, "FETCH_MARKETPLACE_PREFERENCES")
export const savePreferenceAction = createSignalAction(BASE, "SAVE_PREFERENCE_ACTION")
export const claimUnverifiedLeadAction = createSignalAction(BASE, "CLAIM_UNVERIFIED_LEAD")
export const getAgentNumberForCallingAction = createSignalAction(BASE, "GET_AGENT_NUMBER_FOR_CALLING")
export const getClaimBlockerAction = createSignalAction(BASE, "GET_CLAIM_BLOCKER")
export const saveReferralNoteAction = createSignalAction(BASE, "SAVE_REFERRAL_NOTE")

// Non-API actions
export const togglePreferenceModalAction = createSimpleCreator(BASE, "TOGGLE_PREFERENCE_MODAL")
export const toggleDownloadAppModalAction = createSimpleCreator(BASE, "TOGGLE_DOWNLOAD_APP_MODAL")
export const selectCityInPreferenceAction = createSimpleCreator(BASE, "SELECT_CITY_IN_PREFERENCE")
export const toggleLoaderAction = createSimpleCreator(BASE, "TOGGLE_LOADER")
export const toggleMicrophonePermissionModalAction = createSimpleCreator(BASE, "TOGGLE_MICROPHONE_PERMISSION")
export const toggleCallingBlockAction = createSimpleCreator(BASE, "TOGGLE_CALL_BLOCK")
export const handleRemovedNeighbourhoodAction = createSimpleCreator(BASE, "HANDLE_REMOVED_NEIGHBOURHOOD")
export const removeNeighbourhoodAction = createSimpleCreator(BASE, "REMOVE_NEIGHBOURHOOD")
export const handleNeighbourhoodStoreAction = createSimpleCreator(BASE, "HANDLE_NEIGHBOURHOOD_STORE")
export const handleDispatchedNeighbourhoodAction = createSimpleCreator(BASE, "HANDLE_NEIGHBOURHOOD_DISPATCHED_ACTIONS")
export const handleCityRemovalAction = createSimpleCreator(BASE, "HANDLE_CITY_REMOVAL_ACTION")
export const changeActiveBlockClaim = createSimpleCreator(BASE, "CHANGE_ACTIVE_BLOCKED_CLAIM")
export const toggleClaimBlockerModalAction = createSimpleCreator(BASE, "TOGGLE_CLAIM_BLOCKER")
// Twilio Actions
const TWILIO_BASE = "@@Twilio =>"
export const twilioReadyAction = createSimpleCreator(TWILIO_BASE, "READY")
export const connectCallAction = createSimpleCreator(TWILIO_BASE, "CONNECT_CALL")
export const ringingCallAction = createSimpleCreator(TWILIO_BASE, "RINGING_CALL")
export const connectedCallAction = createSimpleCreator(TWILIO_BASE, "CALL_CONNECTED")
export const disconnectCallAction = createSimpleCreator(TWILIO_BASE, "DISCONNECT_CALL")
export const errorInCallAction = createSimpleCreator(TWILIO_BASE, "ERROR_IN_CALL")
export const toggleMuteAction = createSimpleCreator(TWILIO_BASE, "MUTE_TOGGLE_ACTIONS")
export const updateCallStatusAction = createSimpleCreator(TWILIO_BASE, "UPDATE_CALL_STATE")
export const updateCountDownTimerAction = createSimpleCreator(TWILIO_BASE, "UPDATE_TIMER")

// Page Boardcast
const PageBroadcast = "@@BROADCAST =>"
export const sendBroadcastAction = createSimpleCreator(PageBroadcast, "SEND_MESSAGE")
export const receiveBroadcastAction = createSimpleCreator(PageBroadcast, "RECEIVE_MESSAGE")

// Feature Viewed
export const lmpViewedAction = createSignalAction(BASE, "LMP_VIEW_ACTION")

//new Design Actions
export const toggleLeadDetailsViewAction = createSimpleCreator(BASE, "TOGGLE_LEAD_DETAIL_VIEW")

export const deleteMarketplaceLeadAction = createSignalAction(BASE, "DELETE_LMP_LEAD")
export const leadViewedAction = createSignalAction(BASE, "LEAD_VIEWED")
export const creatingMailForVerifiedLeadAction = createSimpleCreator(BASE, "CREATING_MAIL")
export const setCurrentNewLeadsAvailableAction = createSimpleCreator(BASE, "SET_NEW_LEADS_COUNT")
export const toggleLeadDeletionPromptModalAction = createSimpleCreator(BASE, "TOGGLE_LEAD_DELETION_MODAL")
export const showRedirectionLoaderAction = createSimpleCreator(BASE, "SHOW_REDIRECTION_LOADER")

export const getPostCallFeedbackOptionsAction = createSignalAction(BASE, "FETCH_POST_CALL_FEEDBACK_OPTIONS")
