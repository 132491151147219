import React, { useState, useEffect } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import { Checkbox, Button, Upload, Carousel } from "@ui/antd"
import ImgCrop from "antd-img-crop"
import TechAssetsContainer from "container/TechAssets"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  overflow-y: scroll;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
  height: 300px;
  padding: 10px;
  box-shadow: 0px 0px 1px 1px #e8e8e8;
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-color: #b2b2b2;
  }
  .slick-slider slick-initialized {
    height: 300px;
  }
`
const Image = styled.img`
  width: 800px;
  height: 400px;
  margin: auto;
`

const ContentContainer = styled.div`
  padding: 40px 20px 0px;
  margin-top: 20px;
  .slick-dots-bottom {
    margin: auto !important
  }
  .slick-list {
    height: 450px;
  }
`


const PreviewImages = ({
  previewListingImages, togglePreviewListingImages, getAllListingsResponse
}) => {

  const [listingImages, setListingImages] = useState([])

  useEffect(() => {
    if (getAllListingsResponse.data) {
      const images = getAllListingsResponse.data?.find(listingData => listingData.id === previewListingImages)?.listing_images
      if (images?.length) {
        setListingImages([...images])
      }
    }
  }, [setListingImages])

  const getImages = () => {}

  return (
    <Wrap>
      {previewListingImages && (
        <Modal
          show
          toClose={() => { togglePreviewListingImages(false) }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton
          width="800px"
          // height="400px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: "rgb(255 255 255 / 0%)",
            // background: "white",
            borderRadius: "8px",
            minHeight: "280px",
            fontFamily: {ATC},
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            height: "510px",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          <>
            <Header>
            </Header>
            <ContentContainer>
              {/* <Content> */}
                <Carousel autoplay>
                  {listingImages?.map((img) => (
                    <div>
                      <Image src={img} />
                    </div>
                  ))}
                </Carousel>
              {/* </Content> */}
            </ContentContainer>
          </>
        </Modal>
      )}

    </Wrap>
  )
}

export default withRouter(TechAssetsContainer(PreviewImages))
