import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import JoinCommunityImage from "dumbComponents/VideoRooms/components/LandingPage/Components/images/JoinCommunityV2-min.jpg"
import GrowWithRadiusIcon from "dumbComponents/VideoRooms/components/LandingPage/Components/images/GrowWithRadius.svg"
import TeachWithRadiusIcon from "dumbComponents/VideoRooms/components/LandingPage/Components/images/TeachWithRadius.svg"
import NetworkWithRadiusIcon from "dumbComponents/VideoRooms/components/LandingPage/Components/images/NetworkWithRadius.svg"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import colors from "@colors"
import { getOS } from "services/Utils"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import Button from "@ui/Button"
import { preTabletWidth, minDesktopWidth, minTabletWidth} from "@consts"
import GradientBox from "dumbComponents/HomePageV2/Components/GradientBox/index"
import QRCode from "dumbComponents/HomePageV2/Components/QRCode"
import { BlueButton } from "dumbComponents/HomePageV2/Components/commonStyles"


const ParaWrapInfo = [
  {
    image: GrowWithRadiusIcon,
    title: "Grow",
    para: `Learn from the brightest minds in real estate. Radius Rooms provides the professional growth you need to take your business to the next level.`
  },
  {
    image: TeachWithRadiusIcon,
    title: "Teach",
    para: `What’s your greatest strength? We provide the stage and mic—you bring your industry knowledge!`
  },
  {
    image: NetworkWithRadiusIcon,
    title: "Network",
    para: `Engage with your peers now. Over 85,000+ community members are to network with today.`
  },
]

const Wrap = styled.div`
  background: url(${JoinCommunityImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;

  
  @media(min-width: 1100px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media(min-width: 1366px) {
    background-position-y: -253px;
  }

  @media(max-width: 1099px) {
    background-position-x: center;
    padding-top: 50px;
  }

  @media(max-width: 478px) {
    background-position-x: -635px;
    background-repeat: no-repeat;
  }
`

const HeaderWrap = styled.div`
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.015em;
    color: ${colors.dreamWhite};

    ${preTabletWidth && `
      font-family: Butler;
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.005em;
      text-align: left;
    `}

    ${minDesktopWidth && `
      font-size: 30px;
      font-weight: 700;
      line-height: 37px;
      letter-spacing: 0.015em;
      text-align: left;
    `}

    @media(max-width: 1024px) {
      font-size: 24px;
    }
  }
`

const DescWrap = styled.div`
  padding: 60px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  margin-top: 72px;

  @media(max-width: 1024px) {
    padding: 30px;
  }

  @media(max-width: 478px) {
    text-align: center;
  }

  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-weight: 700;
    font-size: 42px;
    line-height: 49px;
    letter-spacing: 0.005em;
    color: ${colors.dreamWhite};
    width: 700px;


    ${preTabletWidth && `
      width: 100%;
      font-family: Butler;
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.005em;
      text-align: left;
    `}

    ${minTabletWidth && `
      font-size: 36px;
      width: 100%;
    `}
  }
`

const Description = styled.div`
  margin-top: 57px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 525px) {
    flex-direction: column;
    gap: 60px;
  }
`

const ParaWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  h1 {
    font-family: ${ATC};
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: ${colors.dreamWhite};
    width: 100%;

    ${preTabletWidth`
      font-family: ${ATC};
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    `}
  }

  img {
    height: 54px;
    width: 54px;
  }

  p {
    font-family: ${ATC};
    font-weight: 200;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.dreamWhite};
    width: 328px;

    @media(max-width: 1280px) {
      width: 100%;
    }

    @media(max-width: 1099px) {
      width: 218px;
    }

    @media(max-width: 768px) {
      width: 145px;
    }

    @media(max-width: 478px) {
      width: 100%;
      margin: auto;
    }
  }
  
`

const DesktopWrap = styled.div`
  display: block;
  margin-top: 30px;

  @media(max-width: 1099px) {
    display: none;
  }
  
`

const MobileCTAWrap = styled.div`
  display: none;

  @media(min-width: 391px) {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 400px;
  }

  @media(min-width: 1100px) {
    display: none;
  }

  @media(max-width: 390px) {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    top: 26px;
  }
`

const ParaStyle = styled.p`

  font-family: ${ATC};
  font-weight: 200;
  font-size: 16px;
  line-height: 23px;
  color: ${colors.dreamWhite};
  width: 611px;

  @media(max-width: 768px) {
    width: 100%;
  }

`

const JoinCommunity = ({
  toggleApplyModal,
  submittingForm,
  appDownloadActionResponse,
}) => {
  const {
    data: appDownloadData,
    isFetching: submittingInterestForm,
  } = appDownloadActionResponse || {}

  const isCurrentUserOniOS = getOS() === "ios" || getOS() === "android"

  return (
    <Wrap className="container">
      <HeaderWrap>
        <h1>
          There’s more to learn inside.
        </h1>
        <ParaStyle>
          Radius Rooms hosts educational content live every day. Join the Radius Community to access all of our upcoming and previously recorded events!
        </ParaStyle>
        {/* <Button
        className="join_the_community"
        style={{
          borderRadius: "4px",
          background: `${colors.dreamWhite}`,
          color: `${colors.black}`,
          border: "none",
          height: "48px",
          width: "186px",
          marginTop: "28px",
        }}
        onClick={() => {
          toggleApplyModal({
            bool: true,
            accountType: "owner",
            headerText: "Nice to meet you!",
            subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
          })
        }}
      >
        Join the Community
      </Button> */}
        <MobileCTAWrap>
          {/* <GetConnected /> */}
          <BlueButton
            height="45px"
            mobileMinWidth="212px"
            onClick={() => {
              const url = isCurrentUserOniOS
                ? "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8"
                : "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
              root.open(url, "_blank")
            }}
            className="rooms_join_our_community"
          >
            Join The Community
          </BlueButton>
        </MobileCTAWrap>
        <DesktopWrap>
          <GradientBox
            customClass="gradient-wrap-black"
            customPadding="18px 32px 28px 32px"
          >
            <QRCode
              className="form_rooms_join_our_community"
              noBorder
              submittingForm={submittingInterestForm}
              appDownloadSMSData={appDownloadData}
              // width="650px"
            />
          </GradientBox>
        </DesktopWrap>
      </HeaderWrap>
      <DescWrap>
        <h1>
          Join the Community to access all that Radius Rooms has to offer.
        </h1>
        <Description>
          {ParaWrapInfo.map(ParaInfo => (
            <>
              <ParaWrap>
                <img src={ParaInfo.image} alt="Grow with Radius" />
                <h1>
                  {ParaInfo.title}
                </h1>
                <p>
                  {ParaInfo.para}
                </p>
              </ParaWrap>
            </>
          ))}
        </Description>
      </DescWrap>
    </Wrap>
  )
}

export default ApplyFlowContainer(JoinCommunity)
