import React from "react"
import {
  Button,
} from "./styles"

const AddButtonCTA = ({
  name,
  onClick,
  disabled,
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
  >
    <img src={`${CLOUDFRONT}/OFFERS_V2/addIcon.svg`} alt="add_icon.svg" height={13} width={13} />
    <p>{name}</p>
  </Button>
)

export default AddButtonCTA
