import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.span`
  
`

const UpvoteIcon = ({ isActive }) => (
  <Wrap>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
      <path
        stroke={isActive ? colors.primaryColor : colors.contentText}
        fill={isActive ? colors.primaryColor : "transparent"}
        fillRule="evenodd"
        d="M5.043.445L.29 6.102c-.314.374-.378.878-.172 1.317.208.439.64.712 1.129.712h1.735v6.632c0 .682.559 1.237 1.246 1.237h3.542c.688 0 1.246-.555 1.246-1.237V8.13h1.735c.488 0 .922-.273 1.128-.712.081-.17.12-.35.12-.528 0-.282-.098-.56-.291-.789L6.957.445C6.72.163 6.371 0 6 0c-.37 0-.72.163-.957.445"
      />
    </svg>
  </Wrap>
)

export default UpvoteIcon
