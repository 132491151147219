import * as React from 'react'

const Menu = props => {
  const { color = '#BEBEBE' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={4} fill="none" {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.625 2.407a1.526 1.526 0 1 1-3.052 0 1.526 1.526 0 0 1 3.052 0Zm6.104 0a1.526 1.526 0 1 1-3.052 0 1.526 1.526 0 0 1 3.052 0Zm4.578 1.526a1.526 1.526 0 1 0 0-3.052 1.526 1.526 0 0 0 0 3.052Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Menu
