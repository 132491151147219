import React, { useState, useEffect } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { DISPLAY_OBJECT } from "./TeamsData"

const Wrap = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  flex-wrap: no-wrap;
  overflow: hidden;
  height: 80px;
  background: #1C1C1F;
  z-index: 2;

  @media(max-width: 1099px) {
    height: 60px;
  }
`

const SquareImages = styled.div`
  height: calc(${props => props.height} + 5px);
  width: calc(${props => props.width} + 5px);
  flex-shrink: 0;
  filter: brightness(2) contrast(2);
  position: relative;

  background: url(${props => props.logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  // min-width: ${props => props.width};
  // min-height: ${props => props.height};

  //animation: move 20s linear infinite;

   @keyframes move {
    0% { left: 0 }
    100% { left: 100vw }
  }
`

const TickerTape = () => {
  const logoWidth = 544
  const screenWidth = root.innerWidth // Get the screen width
  const numLogos = DISPLAY_OBJECT.length

  const [position, setPosition] = useState(0)

  useEffect(() => {
    const moveLogos = () => {
      setPosition(prevPosition => (prevPosition + 1) % (logoWidth * numLogos + screenWidth))
    }

    const intervalId = setInterval(moveLogos, 20) // Adjust the speed of movement
    return () => clearInterval(intervalId)
  }, [logoWidth, numLogos, screenWidth])

  return (
    <Wrap>
      {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
        <SquareImages
          style={{
            transform: `translateX(-${position}px)`,
          }}
          height={item.height}
          width={item.width}
          logo={item.img}
        />
      ))}
    </Wrap>
  )
}

export default TickerTape
