import styled from "styled-components";
import { Row, Modal, Dragger, Card } from "antd";

// BRBC BANNER
// export const BrbcCard = styled(Card)`
//   .ant-card-body {
//     display: flex;
//     padding: 12px 16px 12px 13px;
//     align-items: center;
//     gap: 8px;
//     align-self: stretch;
//     border-radius: 8px;
//     background: #f5f5fa;
//   }
//   border-radius: 8px;
// `;

export const BrbcCard = styled(Card)`
  .ant-card-body {
    display: flex;
    padding: 12px 16px 12px 13px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #f5f5fa;
  }

  border-radius: 8px;
  position: relative;
  animation: ${(props) =>
      props.bannerStatus === "get_started"
        ? "move-shadow 4s linear infinite"
        : "none"}
    @keyframes move-shadow {
    0% {
      box-shadow: 0 0 0 0px #5a5ff2;
    }
    25% {
      box-shadow: 0 0 0 0.5px #5a5ff2;
    }
    50% {
      box-shadow: 0 0 0 1px #5a5ff2;
    }
    75% {
      box-shadow: 0 0 0 0.5px #5a5ff2;
    }
    100% {
      box-shadow: 0 0 0 0px #5a5ff2;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardHeadingDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardHeadingBold = styled.p`
  color: #232338;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  margin: 0;
`;

export const CardHeadingLight = styled.p`
  color: #7d7da9;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
  max-width: 400px;
  margin: 0;
  margin-top: 5px;
`;

export const GetStartedButton = styled.button`
  border-radius: 8px;
  background: transparent;
  color: #5a5ff2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  display: flex;
  border: none;
  padding: 4px 8px 4px 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    background: #cccdfb;
  }

  &:hover {
    background: #cccdfb;
  }
`;

export const OnSuccessContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #5a5ff2 !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
    margin-right: 5px;
  }
`;

export const ReminderText = styled.p`
  color: var(--Color-Primary-Primary-500, #5a5ff2);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  width: 135px;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin: 0;
`;

// ENDS HERE

export const BrbcDrawerContainer = styled.div`
  padding: 32px;
`;

export const DrawerHeading = styled.p`
  color: #232338;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.5px;
  margin: 0;
`;

export const DrawerDesc = styled.p`
  color: #373758;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.5px;
  margin: 0;
  margin-top: 5px;
`;

export const SubheadingContainer = styled.div`
  display: flex;
  padding-left: 10px;
  width: 100%;
  align-items: center;
  padding-top: 32px;
  justify-content: space-between;
  border-top: 1px solid #e0e0eb;
`;

export const SubheadingContainer2 = styled.div`
  display: flex;
  padding-left: 5px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HeadingContainer = styled.div`
  padding-bottom: 32px;
  margin-top: 10px;
  border-bottom: 1px solid #e0e0eb;
  width: 100%;
`;

export const Subheading = styled.p`
  color: #303030;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

export const Subheading2 = styled.p`
  color: #7d7da9;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

export const StyledRow = styled(Row)`
  padding-top: 32px;
`;

export const StyledCobuyerForm = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Color-Gray-Gray-200, #c1c1d7);
  background: var(--Color-White-White, #fdfdfd);
  padding: 16px;
  margin-bottom: 32px;
`;

export const CobuyerBtn = styled.button`
  border-radius: 28px;
  border: 1px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  right: 0;
  position: relative;
  cursor: pointer;
  top: -230px;
  right: -500px;
`;

export const SignBtn = styled.button`
  display: flex;
  height: 48px;
  padding: 12px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background: #5a5ff2;
  border: none;
  color: #fdfdfd;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  cursor: pointer;

  &:disabled {
    background-color: #d9d9d9;
    color: #999999;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const StyledBrbcModal = styled(Modal)`
  height: 200px;
  .ant-modal-body {
    border-radius: 8px;
    padding: 0;
  }

  .ant-modal-header {
    padding: 0;
  }
`;

export const ReasonHeading = styled.p`
  color: var(--Global-Mapping-Gray-700, #373758);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.5px;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 5px;
`;

export const ReasonSubheading = styled.p`
  color: var(--Global-Mapping-Gray-400, #7d7da9);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  letter-spacing: 0.5px;
  margin: 0;
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 0px 10px;
  margin-top: 10px;
`;

export const ModalHeading = styled.p`
  color: var(--Global-Mapping-Gray-900, #232338);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 27px */
  letter-spacing: 0.5px;
  margin: 0;
`;

export const ModalSubHeading = styled.p`
  color: var(--Color-Gray-Gray-700, #373758);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.5px;
  max-width: 450px;
  margin: 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb);
`;

export const ModalHeadingContainer = styled.div`
  width: 100%;
  padding: 16px;
  padding-bottom: 0px;
`;

export const ModalFillBtn = styled.button`
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 32px;
  background: ${(props) =>
    props.btnColor === "auto-modal" ||
    props.btnColor === "upload-doc" ||
    props.btnColor === "sign-brbc"
      ? "#5a5ff2"
      : "#F05152"}
  color: ${(props) =>
    props.btnColor === "auto-modal" ||
    props.btnColor === "upload-doc" ||
    props.btnColor === "sign-brbc"
      ? "#fdfdfd"
      : "white"}
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
`;

export const ModalLightBtn = styled.button`
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 32px;
  background-color: white;
  border: 1px solid var(--Color-Primary-Primary-500, #5a5ff2);
  color: var(--Color-Primary-Primary-500, #5a5ff2);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.5px;
  cursor: pointer;
`;

export const BrbcFileContainer = styled.div`
  padding: 16px;
  border-top: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb);
`;

export const StyledDragger = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  border: "1px dashed var(--Color-Gray-Gray-200, #c1c1d7)",
  margin: "10px 0px",
};

export const StyledDraggerDesc = {
  color: "var(--Color-Primary-Primary-500, #5A5FF2)",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "135%",
  letterSpacing: "0.5px",
  textAlign: "center",
};
