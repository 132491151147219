import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";
import { get } from "lodash";
import root from "window-or-global";

import { fetchListingPageDetailsAction, handleRequestATourAction } from "./actions";

const initialState = {
  ...createDeltaReducer("listingPageResponse"),
  ...createDeltaReducer("postCreateATourResponse"),
  ...get(root, "__STATE__.listingPageDetails", {}),
};

export default function listingPageReducer(state = initialState, action) {
  
  switch (action.type) {
    case fetchListingPageDetailsAction.REQUEST:
    case fetchListingPageDetailsAction.SUCCESS:
    case fetchListingPageDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchListingPageDetailsAction,
          "listingPageResponse"
        ),
      };
    }

    case handleRequestATourAction.REQUEST:
    case handleRequestATourAction.SUCCESS: 
    case handleRequestATourAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          handleRequestATourAction,
          "postCreateATourResponse"
        ),
      };
    }

    default:
      return state;
  }
}