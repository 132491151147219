import React from "react"
import {
  AcademySVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import {
  InnerTab,
  IconEle,
  NavLink,
  NotificationDot,
} from "./commonStyles"

const AcademyTab = ({
  updatesObjectExist,
  updateData,
}) => (
  <InnerTab>
    {updatesObjectExist
      && updateData.academy
      && (updateData.academy.courses
        || updateData.academy.academy
        || updateData.academy.events)
      ? (
        <NotificationDot />
      ) : (
        <NotificationDot zero />
      )}
    <div className="icon-block-new">
      <IconEle src={AcademySVGIcon} />
    </div>
    <div className="text-block">
      <NavLink
        to="/academy"
        academy
        onClick={() => { root.heap.track("Click_AcademyLeftMenu_Web") }}
      >
        Academy
        {/* <i className={icons.arrowsArrowRight}></i> */}
      </NavLink>
    </div>
  </InnerTab>
)

export default AcademyTab
