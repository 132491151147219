import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { get } from "lodash"
import { formValueSelector } from "redux-form"
import { fetchFilterSkeletonAction, getISAFormDropdownValuesAction } from "container/Referral/actions"
import { saveNewProfileImageAction } from "container/App/actions"
import { profileSubmitReferralAction } from "container/StandAlone/SubmitReferral/actions"
import { mapFeeds, getMappedPropertiesAndCity } from "./selector"

import * as ProfileAction from "./action"

const mapStateToProps = (state, ownProps, otherProps) => {
  const { profile: profileServer } = (ownProps && ownProps.staticContext) || {}
  console.log("PROFILE SERVER IT SEEMS", profileServer)
  const {
    app,
    referral,
    profile,
    profile: { isShowEditModal, isUnauthorized },
  } = state
  const { user, isAppMounted } = app
  const { isaDropdownValuesObject } = referral
  const {
    isUserOnboarded,
    showIsaPopup,
  } = user
  const mappedProperties = getMappedPropertiesAndCity(profile)
  const mappedFeeds = mapFeeds({
    ...profileServer,
    ...profile,
  })
  const syncMlsValues = {}
  const propertyColors = get(referral, "filterSkeleton.propertyColorCodes")
  const statusColors = get(referral, "filterSkeleton.clientTypes")
  if (isShowEditModal === "sync-mls") {
    const formSelector = formValueSelector("SYNC_MLS_FORM")
    syncMlsValues.state = formSelector(state, "mls_state")
    syncMlsValues.serving_id = formSelector(state, "serving_id")
    syncMlsValues.email = formSelector(state, "email")
    syncMlsValues.license = formSelector(state, "license")
    syncMlsValues.agent_mls_id = formSelector(state, "agent_mls_id")
  }
  console.log(ownProps)
  return {
    user,
    ...profileServer,
    ...profile,
    mappedFeeds,
    syncMlsValues,
    propertyColors,
    statusColors,
    mappedProperties,
    isUserOnboarded,
    isAppMounted,
    isaDropdownValuesObject,
    showIsaPopup,
    isUnauthorized,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getFollowersAndFollowing: ProfileAction.getFollowersAndFollowingAction.request,
    saveProfileUpdate: ProfileAction.saveProfileUpdateAction.request,
    getPropertyTypes: ProfileAction.getPropertyTypesAction.request,
    getPropertyStatus: ProfileAction.getPropertyStatusAction.request,
    fetchSpecializations: ProfileAction.fetchSpecializationsAction.request,
    uploadProfileImage: ProfileAction.uploadProfileImageAction.request,
    getNeighbourhoodByCity: ProfileAction.getNeighbourhoodByCityAction.request,
    uploadPropertyImages: ProfileAction.uploadPropertyImagesAction.request,
    openEditProfileModal: ProfileAction.openEditProfileModalAction,
    closeEditProfileModal: ProfileAction.closeEditProfileModalAction,
    saveAddListing: ProfileAction.saveAddListingAction.request,
    getProfilecompletion: ProfileAction.getProfilecompletionAction.request,
    fetchUserProfileInfo: ProfileAction.fetchUserProfileInfoAction.request,
    followAgent: ProfileAction.followAgentAction.request,
    unfollowAgent: ProfileAction.unfollowAgentAction.request,
    fetchBuyerNeeds: ProfileAction.fetchBuyerNeedsAction.request,
    saveCityServed: ProfileAction.saveCityServedAction.request,
    addCity: ProfileAction.addCityAction.request,
    getOwnFollowerAndFollowing: ProfileAction.getOwnFollowerAndFollowingAction.request,
    addBuyerNeeds: ProfileAction.addBuyerNeedsAction.request,
    fetchFilterSkeleton: fetchFilterSkeletonAction.request,
    getMlsServingLists: ProfileAction.getMLSServingListsAction.request,
    syncMLS: ProfileAction.syncMLSAction.request,
    removeSyncedMLS: ProfileAction.removeSyncedMlsAction.request,
    mlsStateChange: ProfileAction.mlsStateChangeAction,
    removeCity: ProfileAction.removeCityAction.request,
    skipProfileCompletionStep: ProfileAction.skipProfileCompletionStepAction,
    removeImageFromCookie: saveNewProfileImageAction,
    fetchMoreFeeds: ProfileAction.fetchMoreFeedsAction.request,
    removeCityServedCity: ProfileAction.removeCityServedCityAction.request,
    removeCityServedNeighbourhood: ProfileAction.removeCityServedNeighbourhoodAction.request,
    removeBuyerNeeds: ProfileAction.removeBuyerNeedsAction.request,
    clearProfile: ProfileAction.clearProfileAction,
    getGoals: ProfileAction.getGoalsAction.request,
    profileSubmitReferral: profileSubmitReferralAction.call,
    sendGoals: ProfileAction.sendGoalsAction.request,
    getOnboardingDropDownValues: getISAFormDropdownValuesAction.request,
    handleOnboardingSubmit: ProfileAction.submitOnboardingFormAction.request,
    getAgentFeeds: ProfileAction.getAgentFeedsAction.request,
    fetchCityNeighborhoodServed: ProfileAction.fetchCityNeighborhoodServedAction.request,
    profileViewed: ProfileAction.profileViewedAction.request,
    unblockUser: ProfileAction.unblockUserAction.request,
    updateProfileViewCount: ProfileAction.updateProfileViewCountAction.request,
    clearPropertyImages: ProfileAction.clearPropertyImagesAction.call,
  },
  dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
