import root from "window-or-global"

export const hideCSCChat = () => {
  setTimeout(() => {
    // root.fcWidget.hide()
    // root.fcWidget.on("widget:closed", () => {
    //   root.fcWidget.hide()
    // })
    if(root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove()
    }
  }, 2000)
}
