import React, { useRef } from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
  WhiteButton,
} from "../../../../commonStyles"
import { getInputFieldsBasedOnState } from "./formConfig"

const PropertyInformationForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  state = "california",
}) => {
  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = prefilledData
    }
    return initValues
  }


  return (
    <FormWrap>
      <Form
        name="offers_property_information_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
      >
        {/* <InputField
          name="price"
          inputType="text"
          placeholder="Price"
        /> */}
        {getInputFieldsBasedOnState(state).map((item) => {
          if (item.type === "select") {
            console.log("PLACEHOLDER", item.placeholder)
            return (
              <SelectField
                isFormField={true}
                name={item.name}
                options={[]}
                defaultValue={prefilledData ? prefilledData.listing_type : "Select"}
                placeholder={item.placeholder || "Select"}
              />
            )
          }

          if (item.type === "googleLocation") {
            return (
              <InputLocation
                name={item.name}
                inputType="text"
                type="text"
                placeholder={item.placeholder}
                formRef={formRef}
              />
            )
          }

          return (
            <InputField
              name={item.name}
              inputType="text"
              placeholder={item.placeholder}
            />
          )
        })}

        <CTAWrap>
          <Form.Item>
            <BlueButton
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default PropertyInformationForm
