import React from "react";
import {
  AddressWrap,
  ImageAndAddressWrap,
  SellerAndPriceWrap,
  Address,
  SellerName,
  PropertyPriceLabel,
  PropertyPriceValue,
  PropertyImage,
  PropertyPriceWrap,
  Block,
} from "./style";
import { getStateShortName } from "../ListingTabs/consts";

const AddressInformationCard = ({ data }) => {
  const {
    listing_address,
    seller_firstname,
    seller_lastname,
    house_number,
    listing_label,
    state,
    city,
    zipcode,
    overview_price,
  } = data || {};

  const numberWithCommas = (x) => {
    if (!x) {
      return ""
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const listingAddress = listing_address || "Incomplete Address";
  const stateShort = getStateShortName(state);
  const isSellerNamePresent = seller_firstname || "";
  const sellerName = `Seller Name: ${seller_firstname}${
    seller_lastname ? ` ${seller_lastname}` : ""
  }`;
  const isValueLabelPresent = listing_label || "";
  const currentStatus = (listing_label && listing_label.split("$")) || "";
  return (
    <AddressWrap>
      <ImageAndAddressWrap>
        <PropertyImage src={`${CLOUDFRONT}/LISTING_V2/PropertyGeneric.png`} />
        <Block>
          <Address>{house_number ? `${house_number},` : ""}</Address>
          <Address>{listingAddress}</Address>
          <Address>
            {city ? `${city},` : ""} {stateShort ? stateShort : ""}{" "}
            {zipcode ? zipcode : ""}
          </Address>
        </Block>
      </ImageAndAddressWrap>
      <SellerAndPriceWrap>
        {isSellerNamePresent && <SellerName>{sellerName}</SellerName>}
        {isValueLabelPresent && (
          <PropertyPriceWrap>
            <PropertyPriceLabel>{currentStatus[0]}</PropertyPriceLabel>
            <PropertyPriceValue>{`$${numberWithCommas(overview_price)}`}</PropertyPriceValue>
          </PropertyPriceWrap>
        )}
      </SellerAndPriceWrap>
    </AddressWrap>
  );
};

export default AddressInformationCard;
