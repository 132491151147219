import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import {
  toggleApplyModalAction,
  submitInterestFormAction,
  toggleSignUpFormAction,
  setToggleSignUpFormContent,
  submittedRefferalInviteFormAction,
  toggleSuccessModalAction,
  toggleHubspotMeetingModalAction,
  setCurrentTabForSourceAction,
  appDownloadSMSAction,
  toggleLandingPageMQLFormAction,
  toggleEWebinarModalAction,
  toggleMQLFormPostSubmitAction,
  showMelRoadBlockAction,
} from "./actions"

const mapStateToProps = (state) => {
  const { app } = state
  const {
    isShowApplyModal,
    applyInterestsResponse,
    isShowLoginModal,
    toggleFormContent,
    isSubmittedReferralInterestForm,
    user,
    isShowSuccessModal,
    isShowHubspotModal,
    currentSourceTab,
    applyModalData,
    isAppMounted,
    logInModalClassName,
    signUpHeaderText,
    signUpSubText,
    isDownloadAppFormSubmitted,
    appDownloadActionResponse,
    lpMQLFormVisible,
    isWebinarFormVisible,
    heroMQLFormData,
    isMQLFormSubmitted,
    isMelRoadblockVisible,
  } = app
  const signUpFormValues = getFormValues("APPLY_FORM_V2")(state)
  const footerHomeFormValues = getFormValues("APPLY_FORM_HOME_PAGE")(state)
  return {
    isShowSuccessModal,
    isShowApplyModal,
    applyInterestsResponse,
    isShowLoginModal,
    signUpFormValues,
    toggleFormContent,
    isSubmittedReferralInterestForm,
    user,
    isShowHubspotModal,
    currentSourceTab,
    applyModalData,
    isAppMounted,
    logInModalClassName,
    signUpHeaderText,
    signUpSubText,
    footerHomeFormValues,
    isDownloadAppFormSubmitted,
    appDownloadActionResponse,
    lpMQLFormVisible,
    isWebinarFormVisible,
    heroMQLFormData,
    isMQLFormSubmitted,
    isMelRoadblockVisible,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      toggleApplyModal: toggleApplyModalAction.call,
      submitInterestForm: submitInterestFormAction.request,
      submittedRefferalInviteForm: submittedRefferalInviteFormAction.call,
      toggleSignUpForm: toggleSignUpFormAction.call,
      setToggleSignUpFormContent: setToggleSignUpFormContent.call,
      toggleSuccessModal: toggleSuccessModalAction.call,
      toggleHubspotMeetingModal: toggleHubspotMeetingModalAction.call,
      setCurrentTabForSource: setCurrentTabForSourceAction.call,
      appDownloadSMS: appDownloadSMSAction.request,
      toggleLandingPageMQLFormToggle: toggleLandingPageMQLFormAction.call,
      toggleEWebinarModal: toggleEWebinarModalAction.call,
      toggleMQLFormPostSubmit: toggleMQLFormPostSubmitAction.call,
      showMelRoadBlock: showMelRoadBlockAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
