import React from "react"
import styled from "styled-components"
import appContainer from "container/App"
import { handleDataLayerPush } from "services/dataLayerUtil"
import interestContainer from "container/App/applyFlowContainer"
import root from "window-or-global"
import GradientBox from "../GradientBox"
import { HeroText, SubText, BlueButton } from "../commonStyles"
import PublishingMedia from "./PublishingMedia"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  position: relative;
  // padding: 100px 0px;
  z-index: 2;
  margin: 116px auto;
  padding-top: 160px;

  display: flex;
  flex-direction: column;
  gap: 6px;


  @media(max-width: 1099px) {
    // padding: 30px;
    padding-top: 40px;
    margin: 180px 0px 65px;
    display: block;
  }
`
const MediaWrap = styled.div`
  margin-top: 85px;

  @media(max-width: 1099px) {
    margin-top: 66px;
  } 
`

const CTAWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GradientWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
  margin-bottom: 37px;

  p {
    margin: 0;
  }  

  @media(max-width: 1099px) {
    gap: 18px;
    justify-content: center;
  }
`
const TextWrap = styled.div`
  margin-bottom: 32px;
`

const ImageBox = styled.div`
  display: flex;
  gap: 23px;
  

  img {
    width: 20.001px;
    height: 20px;
    position: relative;
    top: 7px;
  }

  p {
    color: #1C1C1F;
    font-family: ${MONTSERRAT};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32.705px;
  }

  @media(max-width: 1099px) {
    gap: 25px;

    p {
      font-size: 15px;
    }
  }

`

const DISPLAY_OBJECT = [{
  text: "No waiting period",
  image: `${CLOUDFRONT}/CircleCheckMark.png`,
}, {
  text: "No upfront cost",
  image: `${CLOUDFRONT}/CircleCheckMark.png`,
}, {
  text: "No brokers license required",
  image: `${CLOUDFRONT}/CircleCheckMark.png`,
}]

const SectionTwo = ({
  toggleApplyModal,
}) => {

  const staging = process.env.NODE_ENV !== "production"

  return (
    <Wrap
      className="container"
      staging={staging}
    >
      <TextWrap>
        <HeroText
          spanStyles
          noMargin
        >
          Start
          {" "}
          <span>Your Own</span>
          {" "}
          real estate brand today
        </HeroText>
      </TextWrap>
      <SubText
        spanStyles
        noMargin
      >
        Radius is a modern real estate ecosystem providing complete business ownership to
        {" "}
        <span>real estate entrepreneurs.</span>
        {" "}
        We mix a dynamic blend of technology, community, white-glove services, and lending solutions to support your business goals.
      </SubText>
      <GradientWrap>
        {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
          <GradientBox
            minWidth="359px"
            mobileMinWidth="100%"
            mobileFlexJC="flex-start"
            largeDesktopMinWidth="359px"
            smallDesktopWidth="325px"
            gradientOverride
          >
            <ImageBox>
              <img src={item.image} alt="radius_benefits" />
              <p>{item.text}</p>
            </ImageBox>
          </GradientBox>
        ))}
      </GradientWrap>
      <TextWrap>
        <SubText
          noMargin
        >
          Wherever you are in your career, we can help you take it to the next level.
        </SubText>
      </TextWrap>
      <CTAWrap>
        <BlueButton
          className="explore_ownership_brand_web"
          onClick={(e) => {
            e.stopPropagation()
            const payload = {
              section_name: "start_your_own_real_estate_brand",
            }
            handleDataLayerPush(null, "who_we_help_cta_clicked", "who_we_help", payload)
            //root.location.href = "https://www.radiusagent.com/who-we-serve"
          }}
        >
          Who We Help
        </BlueButton>
      </CTAWrap>
      <MediaWrap>
        <PublishingMedia />
      </MediaWrap>
    </Wrap>
  )
}

export default appContainer(interestContainer(SectionTwo))
