import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import root from "window-or-global"
import interestContainer from "container/App/applyFlowContainer"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import { withRouter } from "react-router"
import {
  mobile,
  xsMobile,
} from "@consts"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const Wrap = styled.div`
  height: 600px;
  width: 800px;

  iframe {
    height: 100%;
    width: 100%;
  }

  font-family: ${ATC} !important;
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-size: 42px;
    line-height: 46px;
    color: ${colors.black};
    margin-bottom: 0px;
    margin-top: 0px;
    ${props => props.mb && `
      margin-bottom: ${props.mb};
    `}

    ${mobile(`
      font-size: 36px;
      line-height: 45px;
    `)}

    ${xsMobile(`
      font-size: 32px;
      line-height: 42px;
    `)}
  }
  > p {
    font-size: 18px;
    line-height: 24px;
    font-family: ${ATC};
    font-weight: 300;
    color: ${colors.black};
    ${mobile(`
      font-size: 16px;
    `)}
  }
`

class HubspotModal extends React.PureComponent {
  componentDidMount() {
    const outsideScope = this
    root.addEventListener("message", async (event) => {
      const {
        data,
      } = event || {}

      const {
        currentSourceTab,
      } = outsideScope.props

      if (root.dataLayer && data.meetingBookSucceeded) {
        const source = `hubspot_meeting_confirm_button_web_${currentSourceTab || "Misc"}`
        await root.dataLayer.push({
          event: "hubspot_meeting_confirmation",
          source,
        })
      }
    })
  }

  render() {
    const {
      isShowHubspotModal,
      toggleHubspotMeetingModal,
    } = this.props || {}

    return (
      <React.Fragment>
        <Modal
          toClose={() => {
            toggleHubspotMeetingModal(false)
          }}
          width={594}
          show={isShowHubspotModal}
          modalVersion={3}
          closeIcon={CloseIcon}
          overlayStyles={{
            alignItems: "flex-start",
          }}
          modalStyles={{
            background: `${colors.white}`,
            padding: "0px",
          }}
        >
          <Wrap>
            <iframe
              title="hubspot_modal"
              src="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true"
            />
          </Wrap>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(interestContainer(HubspotModal))
