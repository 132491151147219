/* eslint-disable max-len */
import React from "react"

const VideoOn = () => (
  <svg width="23" height="16" viewBox="0 0 23 16" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M3.77581 15.4974H13.3069C14.4196 15.4974 15.2891 15.1903 15.9154 14.5762C16.5478 13.956 16.8639 13.0896 16.8639 11.9768V4.0145C16.8639 2.90786 16.5478 2.04748 15.9154 1.43336C15.2891 0.813154 14.4196 0.503052 13.3069 0.503052H3.77581C2.71173 0.503052 1.85135 0.813154 1.19466 1.43336C0.544053 2.04748 0.21875 2.90786 0.21875 4.0145V11.9768C0.21875 13.0896 0.531892 13.956 1.15818 14.5762C1.79054 15.1903 2.66309 15.4974 3.77581 15.4974ZM4.03118 14.1202C3.28329 14.1202 2.70565 13.9256 2.29826 13.5365C1.89087 13.1473 1.68718 12.5575 1.68718 11.7671V4.2334C1.68718 3.44294 1.89087 2.85314 2.29826 2.46399C2.70565 2.06876 3.28329 1.87115 4.03118 1.87115H13.0515C13.7994 1.87115 14.377 2.06876 14.7844 2.46399C15.1918 2.85314 15.3955 3.44294 15.3955 4.2334V11.7671C15.3955 12.5575 15.1918 13.1473 14.7844 13.5365C14.377 13.9256 13.7994 14.1202 13.0515 14.1202H4.03118ZM16.6542 5.44645V7.17937L20.9956 3.59495C21.0382 3.56455 21.0746 3.54023 21.105 3.52199C21.1415 3.49767 21.178 3.48551 21.2145 3.48551C21.3179 3.48551 21.3695 3.55847 21.3695 3.7044V12.2961C21.3695 12.4359 21.3179 12.5058 21.2145 12.5058C21.178 12.5058 21.1415 12.4967 21.105 12.4785C21.0746 12.4542 21.0382 12.4298 20.9956 12.4055L16.6542 8.82109V10.5449L20.3116 13.6459C20.494 13.7979 20.6825 13.9226 20.877 14.0199C21.0716 14.1111 21.2631 14.1567 21.4516 14.1567C21.8529 14.1567 22.1752 14.0229 22.4184 13.7554C22.6616 13.4878 22.7833 13.1412 22.7833 12.7156V3.28485C22.7833 2.85922 22.6616 2.51264 22.4184 2.2451C22.1752 1.97756 21.8529 1.84379 21.4516 1.84379C21.2631 1.84379 21.0716 1.89243 20.877 1.98972C20.6825 2.08093 20.494 2.20253 20.3116 2.35454L16.6542 5.44645Z"
      fill="#303030"
    />
  </svg>

)

export default VideoOn
