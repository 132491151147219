import React, { useState, useCallback, useEffect } from "react"
import Listings from "container/Listings"
import { Button, DangerButton } from "dumbComponents/common/InputFields"
import createToast from "dumbComponents/common/Toast/customToast"
import { Drawer, NewButton } from "@ui/antd"
import moment from "moment"
import { isBoolean } from "lodash"
import {
  ExternalFilterWrap,
  FilterSection,
  InnerFilterWrap,
  FilterType,
  FlexWrap,
} from "./styles"
import SortBy from "./SortBy"
import ListingType from "./ListingType"
import Status from "./Status"
import DaysOnMarket from "./DaysOnMarket"
import DateRange from "./DateRange"
import SortByPricing from "./SortByPricing"

const ListingsFilterDrawer = ({
  handleClose,
  open,
  title,
  titleFontSize,
  hideDrawerTitle,
  width,
  constructQuery,
  query,
  clearFiltersOnDemand,
}) => {
  const [transactionSelected, setTransactionTypeSelected] = useState(null)
  const [dealTypeSelected, setDealTypeSelected] = useState(null)
  const [queryState, setQueryState] = useState({
    sortByName: null,
    sortByListingPrice: null,
    dealType: null,
    transactionType: null,
    fromListing: null,
    toListing: null,
    daysOnMarket: null,
  })

  // useEffect(() => {
  //   if (queryState.dealType !== query.dealType) {
  //     setQueryState(curr => ({
  //       ...curr,
  //       dealType: query.dealType,
  //     }))
  //     setDealTypeSelected(query.dealType)
  //   }
  // }, [query, query.dealType])

  const onChangeForLexicographicalSort = (e) => {
    const {
      target: {
        value,
      },
    } = e || {}
    setQueryState(state => ({
      ...state,
      sortByName: value,
    }))
  }

  const onChangeForNumericalValueOfPriceSort = (e) => {
    const {
      target: {
        value,
      },
    } = e || {}
    setQueryState(state => ({
      ...state,
      sortByListingPrice: value,
    }))
  }

  const handleListingTypeChange = (arrayOfValues = []) => {
    const stringOfQuery = arrayOfValues.length > 0 ? arrayOfValues.join(",") : null
    setQueryState((state) => {
      return {
        ...state,
        transactionType: stringOfQuery,
      }
    })

    setTransactionTypeSelected(arrayOfValues)
  }

  const handleStatusChange = (arrayOfValues) => {
    const stringOfQuery = arrayOfValues.length > 0 ? arrayOfValues.join(",") : null
    setQueryState((state) => {
      return {
        ...state,
        dealType: stringOfQuery,
      }
    })

    setDealTypeSelected(arrayOfValues)
  }

  const handleDaysOnMarketChange = (e) => {
    const {
      target: {
        value,
      },
    } = e || {}
    setQueryState(state => ({
      ...state,
      daysOnMarket: value,
    }))
  }

  const handleFromDateChange = (value) => {
    if (value) {
      const formattedDate = value.format("YYYY-MM-DD")
      setQueryState(state => ({
        ...state,
        fromListing: formattedDate,
      }))
    } else {
      setQueryState(state => ({
        ...state,
        fromListing: moment().format("YYYY-MM-DD"),
      }))
    }
  }

  const handleToDateChange = (value) => {
    if (value) {
      const formattedDate = value.format("YYYY-MM-DD")
      setQueryState(state => ({
        ...state,
        toListing: formattedDate,
      }))
    }
  }

  const clearFilters = useCallback(() => {
    setQueryState((item) => {
      const curr = {
        ...item,
      }

      Object.keys(curr).forEach((item) => {
        curr[item] = null
      })

      return curr
    })

    setTransactionTypeSelected([])
    setDealTypeSelected([])

    createToast("Filters cleared.")
  }, [queryState])

  useEffect(() => {
    if (isBoolean(clearFiltersOnDemand)) {
      clearFilters()
    }
  }, [clearFiltersOnDemand])

  const ApplyButton = () => {
    createToast("Filters applied.")
    const curr = {
      ...query,
      ...queryState,
    }

    if (curr.fromListing && typeof curr.fromListing !== "string") {
      const fromDate = curr.fromListing.format("YYYY-MM-DD")
      curr.fromListing = fromDate
    }
    constructQuery({
      query: curr,
    })
    handleClose()
  }

  return (
    <Drawer
      width={width || "537px"}
      title={title}
      placement="right"
      onClose={() => handleClose()}
      open={open}
      type="Basic Drawer"
      CloseIconRight
      hideDrawerTitle={hideDrawerTitle}
      fontSize={titleFontSize}
      closeIcon={<img src={`${CLOUDFRONT}/LISTING_V2/CloseIcon.svg`} alt="icon" />}
      maskClosable={false}
    >
      <ExternalFilterWrap>
        <FlexWrap>
          <FilterSection>
            <InnerFilterWrap>
              <FilterType>
                <p>Sort By (A-Z, Z-A)</p>
                <SortBy
                  onChangeForLexicographicalSort={onChangeForLexicographicalSort}
                  value={queryState.sortByName}
                />
              </FilterType>
            </InnerFilterWrap>
            <InnerFilterWrap>
              <FilterType>
                <p>Sort By Listing Price</p>
                <SortByPricing
                  onChangeForNumericalValueOfPriceSort={onChangeForNumericalValueOfPriceSort}
                  value={queryState.sortByListingPrice}
                />
              </FilterType>
            </InnerFilterWrap>
            <InnerFilterWrap>
              <FilterType>
                <p>Listing Type</p>
                <ListingType
                  onChange={handleListingTypeChange}
                  value={transactionSelected || []}
                />
              </FilterType>
            </InnerFilterWrap>
          </FilterSection>
          <FilterSection>
            <InnerFilterWrap>
              <FilterType>
                <p>Status</p>
                <Status
                  onChange={handleStatusChange}
                  value={dealTypeSelected || []}
                />
              </FilterType>
            </InnerFilterWrap>
            <InnerFilterWrap>
              <FilterType>
                <p>Days on Market</p>
                <DaysOnMarket
                  onChange={handleDaysOnMarketChange}
                  value={queryState.daysOnMarket}
                />
              </FilterType>
            </InnerFilterWrap>
          </FilterSection>
        </FlexWrap>
        <FlexWrap>
          <FilterType>
            <p>Date</p>
            <DateRange
              onFromDateChange={handleFromDateChange}
              onToDateChange={handleToDateChange}
              fromListing={queryState.fromListing}
              toListing={queryState.toListing}
            />
          </FilterType>
        </FlexWrap>
        <FlexWrap
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <NewButton
            type="primary"
            onClick={ApplyButton}
          >
            Apply Filters
          </NewButton>
          {/* <DangerButton
            type="danger"
            onClick={clearFilters}
            buttonName="Clear Filters"
            loading={false}
            style={{
              height: "45px",
              width: "166px",
            }}
          /> */}
        </FlexWrap>
      </ExternalFilterWrap>
    </Drawer>
  )
}

export default Listings(ListingsFilterDrawer)
