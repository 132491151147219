import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  ...createDeltaReducer("leaderBoardStats"),
  ...createDeltaReducer("teamStats"),
  ...createDeltaReducer("leaderBoardLists"),
  ...createDeltaReducer("leaderBoardMonthlyWinner"),
  ...createDeltaReducer("getHomePageGraphStatsResponse"),
}

export default function rarLeaderboard(state = initState, action) {
  switch (action.type) {
    case Actions.getLeaderBoardStats.REQUEST:
    case Actions.getLeaderBoardStats.SUCCESS:
    case Actions.getLeaderBoardStats.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getLeaderBoardStats, "leaderBoardStats"),
      }
    }

    case Actions.getTeamStats.REQUEST:
    case Actions.getTeamStats.SUCCESS:
    case Actions.getTeamStats.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getTeamStats, "teamStats"),
      }
    }

    case Actions.getLeaderBoardLists.REQUEST:
    case Actions.getLeaderBoardLists.SUCCESS:
    case Actions.getLeaderBoardLists.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getLeaderBoardLists, "leaderBoardLists"),
      }
    }

    case Actions.resetLeaderBoardLists.REQUEST:
    case Actions.resetLeaderBoardLists.SUCCESS:
    case Actions.resetLeaderBoardLists.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.resetLeaderBoardLists, "isResetLeaderBoardLists"),
      }
    }

    case Actions.getLeaderBoardMonthlyWinner.REQUEST:
    case Actions.getLeaderBoardMonthlyWinner.SUCCESS:
    case Actions.getLeaderBoardMonthlyWinner.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getLeaderBoardMonthlyWinner, "leaderBoardMonthlyWinner"),
      }
    }

    case Actions.getHomePageGraphStats.REQUEST:
    case Actions.getHomePageGraphStats.SUCCESS:
    case Actions.getHomePageGraphStats.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getHomePageGraphStats, "getHomePageGraphStatsResponse"),
      }
    }

    default: {
      return state
    }
  }
}
