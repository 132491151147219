import React from "react"
import root from "window-or-global"

export default function HubspotChat() {

    return (
        <>
            {/* {root.HubSpotConversations &&
                root.HubSpotConversations.widget.load()
            } */}
        </>

    )
}