/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect, useMemo } from "react"
import RemoveAgentBinIcon from "../../../../../../../images/icons/remove-agent-bin-icon.js"
import CoAgentIcon from "../../../../../../../images/icons/co-agent-icons.js"
import PurpleWarnIcon from "../../../../../../../images/icons/purple-warn-icon.js"
import { Checkbox, Input, Modal } from 'antd';
import uuid from "uuid"
import {
  Form,
  NewRadioButton,
  NewRadioGroup,
} from "@ui/antd"
import {
  InputField,
  InputNumber,
  AgentSearch,
  InputLocation,
  SelectField,
  HiddenInputField,
  AddButton,
} from "dumbComponents/common/InputFields"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  CoWrap,
  FormFieldWrap,
  DangerButton,
  SubmitWrap,
  ListRowWrap,
  ValidationNumberCircle,
  RadioWrap,
  RadioQuestion,
  CheckboxWrap,
  CoAgentWarnDiv,
  CircleModalDiv,
  RemoveTitleModal,
  ModalDesc,
  ModalNoteDiv,
} from "./commonStyles"
import {
  validatePhoneNumber,
} from "./utils"
import { normalizePhone } from "services/formUtils"
import {
  CLIENT_SOURCE,
} from "./formConfig"
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";

const { List } = Form

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  isFirst,
  listingAgents = [],
  onValueChange,
  saving,
  disableForm,
  key,
  formName,
  form,
  validationReport,
  uniqueIdentifier,
  handleInitPropertyChange,
}) => {
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [defaultValuesForIsRadiusAgentBool, setDefaultValuesForIsRadiusAgentBool] = useState({})
  const formRef = useRef(null)
  const [isRadiusAgent, setRadiusAgent] = useState(false)
  const [currentListingAgentCount, setListingAgentCount] = useState(0)

  const handleRepresentation = (val) => {
    setRadiusAgent(val)
  }

  const getInitialValues = () => {
    let initValues = {}
    let newRadiusAgentBool = {}
    if (prefilledData && prefilledData.length > 0) {
      initValues = { ...prefilledData }
      const firstname = prefilledData[0].name ? prefilledData[0].name.split(" ")[0] : ""
      const lastname = prefilledData[0].name ? prefilledData[0].name.split(" ")[1] : ""
      initValues.agent_first_name = firstname
      initValues.agent_last_name = lastname
      initValues.name = prefilledData[0].name
      initValues.phone = prefilledData[0].phone ? normalizePhone(prefilledData[0].phone) : prefilledData[0].phone
      initValues.email = prefilledData[0].email
      initValues.listing_brokerage = prefilledData[0].listing_brokerage || prefilledData[0].brokerage
      initValues.brokerage_address = prefilledData[0].brokerage_address
      initValues.brokerage_license_number = prefilledData[0].brokerage_license_number
      initValues.agent_license_number = prefilledData[0].agent_license_number
      initValues.apt = prefilledData[0].apt
      initValues.state = prefilledData[0].state
      initValues.city = prefilledData[0].city
      initValues.zipcode = prefilledData[0].zipcode
      initValues.agent_type = prefilledData[0].agent_type
      initValues.agent_id = prefilledData[0].agent_id
      if (prefilledData.length > 1) {
        let otherAgents = prefilledData.slice(1, prefilledData.length)
        otherAgents = otherAgents.map((item, index) => {
          console.log(item)
          const curr = item
          const coAgentFirstName = curr.name ? curr.name.split(" ")[0] : null
          const coAgentLastName = curr.name ? curr.name.split(" ")[1] : null

          curr.agent_first_name = coAgentFirstName
          curr.agent_last_name = coAgentLastName
          curr.agent_id = curr.agent_id || 0
          curr.listing_brokerage = curr.listing_brokerage || curr.brokerage || ""
          curr.brokerage_address = curr.brokerage_address || ""
          curr.phone = curr.phone ? normalizePhone(curr.phone) : ""

          setDefaultValuesForIsRadiusAgentBool({
            ...defaultValuesForIsRadiusAgentBool,
            [index]: !!curr.agent_id,
          })
          newRadiusAgentBool[index] = curr.agent_id != 9999999 && curr.agent_id != 0 ? true : false
          console.log('check here', curr.agent_id);
          // setRadiusAgentBoolForCoAgent({
          //   ...isRadiusAgentBool,
          //   [index]: !curr.agent_id,
          // })

          return curr
        })

        initValues.co_listing_agents = otherAgents && otherAgents.length > 0 ? [...otherAgents] : []
      }
    }
    setRadiusAgentBoolForCoAgent(newRadiusAgentBool)
    
    return initValues
  }

  useEffect(() => {
    if (prefilledData && prefilledData.length > 0) {
      setListingAgentCount(prefilledData.length)
    }
  }, [prefilledData])

  useEffect(() => {
    return () => {
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }
  }, [])

  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

  // helper functions to manage name states 
  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const handleCheckboxChange = (e, name) => {
      const {
        target: {
          checked,
        },
      } = e || {}
      setRadiusAgentBoolForCoAgent(existingValues => ({
        ...existingValues,
        [name]: checked,
      }))
  };

  return (
    <FormWrap
      id={`${key}_wrap`}
      key={key}
    >
      {/* <HeaderWrap>
        <FormTitle>
          <p>Listing Agent Information</p>
        </FormTitle>
      </HeaderWrap> */}
      {/* <RadioWrap>
        <RadioQuestion>
          <p>Is agent part of Radius?</p>
        </RadioQuestion>
        <NewRadioGroup
          onChange={e => handleRepresentation(e.target.value)}
          value={isRadiusAgent}
          size="small"
        >
          <NewRadioButton value={true}>Yes</NewRadioButton>
          <NewRadioButton value={false}>No</NewRadioButton>
        </NewRadioGroup>
      </RadioWrap> */}

      <Form
        id={`${key}_form`}
        key={key}
        name={formName}
        // preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={useMemo(() => getInitialValues(), [prefilledData])}
        ref={formRef}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        onValuesChange={onValueChange}
        form={form}
        //onValuesChange={handleOnTypeChangeForListingAgents}
      >
        <HiddenInputField name="is_primary" value={1} />
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="agent_type" />

        {isRadiusAgent ? (
          <AgentSearch
            name="agent_first_name"
            inputType="text"
            placeholder="First Name"
            customRules={{}}
            isFormField
            formRef={formRef}
            firstNameLabel="agent_first_name"
            lastNameLabel="agent_last_name"
            isFirst={isFirst}
            text="First Name*"
          />
        ) : (
          <InputField
            name="agent_first_name"
            inputType="text"
            placeholder="First Name"
            customRules={{}}
            text="First Name"
            required={true}
            requiredMessage="Agent's First Name is required"
            disabled={disableForm}
          />
        )}

        <InputField
          name="agent_last_name"
          inputType="text"
          placeholder="Last Name"
          text="Last Name"
          required={true}
          requiredMessage="Agent's Last Name is required"
          disabled={disableForm}
        />

        <InputField
          name="email"
          inputType="email"
          placeholder="Email"
          text="Email"
          type="email"
          required={true}
          disabled={disableForm}
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone"
          text="Phone"
          //requiredMessage="Phone is required."
          //required={true}
          disabled={disableForm}
          customRules={{
            validator: validatePhoneNumber,
          }}
        />

        <InputField
          name="apt"
          inputType="text"
          placeholder="Apartment/Unit/Suite No."
          text="Apartment/Unit/Suite No."
          type="text"
          disabled={disableForm}
        />

        <InputLocation
          name="brokerage_address"
          inputType="text"
          type="colisting_agent_address"
          placeholder="Brokerage Address"
          formRef={formRef}
          text="Brokerage Address"
          // required={true}
          //ignoreFields={["state", "city", "zipcode"]}
          disabled={disableForm}
          handleInitChange={handleInitPropertyChange}
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
          type="text"
          //required={true}
          //requiredMessage="City is required."
          disabled={disableForm}
        />
        
        <InputField
          name="state"
          inputType="text"
          placeholder="State"
          text="State"
          type="text"
          // required={true}
          // requiredMessage="State is required."
          disabled={disableForm}
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
          text="Zip Code"
          type="text"
          // required={true}
          // requiredMessage="Zip Code is required."
          disabled={disableForm}
        />

        <InputField
          name="agent_license_number"
          inputType="text"
          type="text"
          placeholder="Agent License Number"
          text="Agent License Number"
          required={true}
          requiredMessage="Agent's License Number is required"
          disabled={disableForm}
        />

        <InputField
          name="brokerage_license_number"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage License Number"
          text="Listing Brokerage License Number"
          required={true}
          requiredMessage="Listing Brokerage License Number is required"
          disabled={disableForm}
        />

        <InputField
          name="listing_brokerage"
          inputType="text"
          type="text"
          placeholder="Listing Brokerage"
          text="Listing Brokerage"
          requiredMessage="Listing brokerage is required"
          disabled={disableForm}
          required={true}
        />

        <CTAWrap>
          <List name="co_listing_agents">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap >
                    {/* <Modal
                      visible={isRemoveAgentModalVisible}
                      onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)}
                      okText="Remove agent"
                      okButtonProps={{ danger: true }}
                      cancelText="Cancel"
                    >
                      <div>
                        <CircleModalDiv><PurpleWarnIcon/></CircleModalDiv>
                        <RemoveTitleModal>Remove Agent</RemoveTitleModal>
                        <ModalDesc>Are you sure you want to remove this agent? This action cannot be undone. The agent will lose access to all associated resources and permissions.</ModalDesc>
                        <ModalNoteDiv>
                          Note: Any ongoing tasks or processes assigned to this agent will need to be reassigned manually.
                        </ModalNoteDiv>
                      </div>
                    </Modal> */}
                    <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                    <HeaderWrap>
                      <FormTitle>
                        <p> <CoAgentIcon/> Co-Listing Agent</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={() => openRemoveAgentModal(name)}
                      >
                        <RemoveAgentBinIcon />
                        <p> Remove agent </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    {/* <ListRowWrap>
                      <RadioWrap>
                        <RadioQuestion>
                          <p>Is agent part of Radius?</p>
                        </RadioQuestion>
                        <NewRadioGroup
                          onChange={(e) => {
                            const {
                              target: {
                                value,
                              },
                            } = e || {}
                            setRadiusAgentBoolForCoAgent(existingValues => ({
                              ...existingValues,
                              [name]: value,
                            }))
                          }}
                          //defaultValue={defaultValuesForIsRadiusAgentBool[name]}
                          value={isRadiusAgentBool[name]}
                          size="small"
                        >
                          <NewRadioButton value={true}>Yes</NewRadioButton>
                          <NewRadioButton value={false}>No</NewRadioButton>
                        </NewRadioGroup>
                      </RadioWrap>
                      <CheckboxWrap>
                      {isRadiusAgentBool[name] && (
                        <div style={{width: "100%"}}>
                          <CoAgentWarnDiv>
                            <PurpleWarnIcon/> Great! Let's quickly find your colleague in our directory.
                          </CoAgentWarnDiv>
                          <FormFieldWrap style={{width: "100%"}}>
                          <AgentSearch
                              name={[name, "agent_first_name"]}
                              inputType="text"
                              placeholder="Search by name"
                              customRules={{}}
                              isFormField
                              firstNameLabel="agent_first_name"
                              lastNameLabel="agent_last_name"
                              formRef={formRef}
                              formName="co_listing_agents"
                              fieldIndex={name}
                            />
                          </FormFieldWrap>
                            
                        </div>
                      )}
                      </CheckboxWrap>
                    </ListRowWrap> */}
                    <FormFieldWrap>
                      <HiddenInputField name={[name, "agent_id"]} />

                      {/* {isRadiusAgentBool[name] ? (
                        <AgentSearch
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder="Co-listing agent's first name"
                          customRules={{}}
                          isFormField
                          firstNameLabel="agent_first_name"
                          lastNameLabel="agent_last_name"
                          formRef={formRef}
                          text="Co-listing agent's first name"
                          formName="co_listing_agents"
                          fieldIndex={name}
                          required={true}
                        />
                      ) : (
                        <InputField
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder="Co-listing agent's first name"
                          text="Co-listing agent's first name"
                          required={true}
                          requiredMessage="Agent's first name is required."
                        />
                      )} */}

                        <AgentSearch
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder="Co-listing agent's first name"
                          customRules={{}}
                          isFormField
                          firstNameLabel="agent_first_name"
                          lastNameLabel="agent_last_name"
                          formRef={formRef}
                          text="Co-listing agent's first name"
                          formName="co_listing_agents"
                          fieldIndex={name}
                          required={true}
                          requiredMessage="Agent's first name is required"
                        />

                        {/* <InputField
                          name={[name, "agent_first_name"]}
                          inputType="text"
                          placeholder="Co-listing agent's first name"
                          text="Co-listing agent's first name"
                          required={true}
                          disabled={isRadiusAgentBool[name]}
                          requiredMessage="Agent's first name is required."
                        /> */}

                      <InputField
                        name={[name, "agent_last_name"]}
                        inputType="text"
                        placeholder="Co-listing agent's last name"
                        text="Co-listing agent's last name"
                        type="text"
                        required={true}
                        requiredMessage="Agent's last name is required"
                      />

                    </FormFieldWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "email"]}
                        type="email"
                        inputType="email"
                        placeholder="Email"
                        text="Email"
                        required={true}
                      />

                      <InputNumber
                        name={[name, "phone"]}
                        inputType="phone"
                        placeholder="Phone"
                        text="Phone"
                        type="phone"
                        //required={true}
                        //requiredMessage="Phone is required."
                        customRules={{
                          validator: validatePhoneNumber,
                        }}
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                           <InputField
                        name={[name, "apt"]}
                        inputType="text"
                        placeholder="Apartment/Unit/Suite No."
                        text="Apartment/Unit/Suite No."
                        type="text"
                      />
                            <InputLocation
                        name={[name, "brokerage_address"]}
                        inputType="text"
                        type="colisting_agent_address"
                        placeholder="Brokerage Address"
                        formRef={formRef}
                        formName="co_listing_agents"
                        fieldIndex={name}
                        // required={true}
                        //ignoreFields={["state", "city", "zipcode"]}
                        text="Brokerage Address"
                      />
                   
                    </FormFieldWrap>
                    
                    <FormFieldWrap>
                      <InputField
                        name={[name, "city"]}
                        inputType="text"
                        placeholder="City"
                        text="City"
                        type="text"
                        // required={true}
                        // requiredMessage="City is required."
                      />

                         <InputField
                        name={[name, "state"]}
                        inputType="text"
                        placeholder="State"
                        text="State"
                        type="text"
                        // required={true}
                        // requiredMessage="State is required."
                      />
                    </FormFieldWrap>

                    <FormFieldWrap>
                        <InputField
                        name={[name, "zipcode"]}
                        inputType="text"
                        placeholder="Zip Code"
                        text="Zip Code"
                        type="text"
                        // required={true}
                        // requiredMessage="Zip Code is required."
                      />
                      <InputField
                        name={[name, "agent_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Agent License Number"
                        text="Agent License Number"
                        requiredMessage="Agent's License is required"
                        required={true}
                      />

                  
                    </FormFieldWrap>

                    <FormFieldWrap>
                      <InputField
                        name={[name, "listing_brokerage"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage"
                        text="Listing Brokerage"
                        requiredMessage="Listing Brokerage is required"
                        required={true}
                      />

                          <InputField
                        name={[name, "brokerage_license_number"]}
                        inputType="text"
                        type="text"
                        placeholder="Listing Brokerage License Number"
                        text="Listing Brokerage License Number"
                        requiredMessage="Listing Brokerage License Number is required"
                        required={true}
                      />
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                <FormFieldWrap>
                  <CTAWrap>
                    <Form.Item>
                      <AddButton
                        disabled={currentListingAgentCount <= 0}
                        name="ADD CO LISTING AGENT"
                        onClick={(e) => {
                          e.stopPropagation()
                          setListingAgentCount(count => count + 1)
                          setRadiusAgentBoolForCoAgent({...isRadiusAgentBool, [currentListingAgentCount-1]: false})
                          add()
                        }}
                      />
                    </Form.Item>
                  </CTAWrap>
                </FormFieldWrap>
              </>
            )}
          </List>
        </CTAWrap>

        {/* {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )} */}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}

      </Form>
    </FormWrap>
  )
}

export default BuyerAgentForm
