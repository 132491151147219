import React, { useEffect } from "react"
import styled from "styled-components"
import Footer from "dumbComponents/common/FooterNew"
import app from "container/App"
import { useWindowSize, getOS } from "services/Utils"
import CookieStorage from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import { Helmet } from "react-helmet"
import root from "window-or-global"
import RoomsContainer from "container/Rooms"
import container from "container/App/applyFlowContainer"
// import RadiusNetworkImagePreview from "images/Graphics/LandingPagePreviews/RadiusPreviewImageNew.png"
import HeroSection from "./Components/HeroSection"
import BackgroundMask from "./Components/BackgroundMask/index"
import SectionTwo from "./Components/SectionTwo"
import SectionThree from "./Components/SectionThree"
import SectionFour from "./Components/SectionFour"
import SectionFive from "./Components/SectionFive"
import AnimationSection from "./Components/AnimationSection/index"
import SectionSeven from "./Components/SectionSeven"
import SectionEight from "./Components/SectionEight"

const Wrap = styled.div`
  position: relative;

  @media(max-width: 1099px) {
    max-width: 768px;
    margin: 0 auto;
  }
`

const Wrapper = styled.div`
`

const BGWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  // ${props => props.stopOverflow && `
  //   overflow: hidden;
  // `}

  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};
  `}

  ${props => props.footer && `
    padding-top: 110px;
    // height: 800px;
  `}
`

const SliderWrap = styled.div`
  margin-top: 103px;
  overflow: hidden;

  @media(max-width: 1099px) {
    margin-top: 424px;
  }
`

const NewHomePage = ({
  getScheduleRooms,
  getScheduleRoomsResponse,
  history,
}) => {
  useEffect(async () => {
    history.push("/signup")
    getScheduleRooms()
    if (root && CookieStorage) {
      const sessionCookie = CookieStorage.load("_ga_J8ECV3XFM1")
      const gaSessionId = sessionCookie && sessionCookie.split(".")[2]
      if (root.dataLayer && gaSessionId) {
        await root.dataLayer.push({ session_id: gaSessionId })
      }
    }
  }, [])

  const size = useWindowSize()
  const { width } = size
  
  const isCurrentUserOniOS = getOS() === "ios"

  const RadiusNetworkImagePreview = "https://img-radius.imgix.net/RadiusPreviewImageNew.png"

  const META_DESCRIPTION = `Radius is a modern real estate brokerage providing complete business 
  ownership to real estate entrepreneurs. We mix a dynamic blend of technology, community, 
  white-glove services, and lending solutions to support your business goals.`

  const meta = {
    title: "Empowering Real Estate Entrepreneurs: Our Story",
    description: META_DESCRIPTION,
    keywords: "Referrals, community, real estate agents, marketplace, network, coaching, training, education",
    type: "website",
    url: `${WEB_URL}network`,
    image: RadiusNetworkImagePreview,
  }

  const {
    data: scheduledRooms,
    isFetching: fetchingScheduledRooms,
  } = getScheduleRoomsResponse || {}

  return (
    <>
      <Wrapper className="external_home_page">
        <Helmet>
          <title>{meta.title}</title>
          <meta property="og:title" content={meta.title} />
          <meta property="title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="description" content={meta.description} />
          <meta property="keywords" content={meta.keywords} />
          <meta property="og:image" content={meta.image} />
          <meta property="image" content={meta.image} />
        </Helmet>
        <Wrap>
          <BGWrap
            stopOverflow
          >
            <HeroSection
              width={width}
              isCurrentUserOniOS={isCurrentUserOniOS}
            />
            <BackgroundMask type="1" />
          </BGWrap>
          <BGWrap>
            <BackgroundMask type="2" />
            <SectionTwo width={width} />
          </BGWrap>
          
          <BGWrap>
            <BackgroundMask type="3" />
            <SectionThree
              isCurrentUserOniOS={isCurrentUserOniOS}
              width={width}
            />
          </BGWrap>
          <SectionFour width={width} />
          <BGWrap>
            <BackgroundMask type="2" />
            <SectionFive width={width} />
          </BGWrap>
          <AnimationSection width={width} />
          <BGWrap>
            <BackgroundMask type="4" />
            <SliderWrap>
              <SectionSeven width={width} />
            </SliderWrap>
          </BGWrap>
          <BGWrap>
            <BackgroundMask type="5" />
            <SectionEight
              scheduledRooms={scheduledRooms}
              fetchingScheduledRooms={fetchingScheduledRooms}
              width={width}
            />
          </BGWrap>
        </Wrap>
      </Wrapper>
      <BGWrap
        backgroundColor="#1c1c1f"
        footer
      >
        <Footer />
      </BGWrap>
    </>
  )
}

export default withRouter(RoomsContainer(app(container(NewHomePage))))
