import React from "react"
import { Drawer } from "@ui/antd"
import Loader from "@ui/Loader"
import CRMV2Container from "container/CRM"
import { AddPropertyInfo, AddSearchCriteria } from "../DrawerContent/Tabs"
import {
  PropertyRequirementDetailsInfo, DrawerHeader, SearchCriteriaDetailsInfo, FinanceTransactionForm,
} from "../DrawerContent"
import { EditProfile } from "../DrawerContent/Forms"
import BrbcDrawer from "../DrawerContent/BRBC/BrbcDrawer"

const UserHeaderDrawer = ({
  handleClose,
  isUserOverviewDrawerOpen,
  currentPage,
  setCurrentDrawerPage,
  toggleUserOverview,
  getCrmClientResponse,
}) => {
  const { showUserOverviewDrawer, agentInfo } = isUserOverviewDrawerOpen || {}
  const payload = {
    showUserOverviewDrawer: true,
    agentInfo,
  }
  const getCurrentPage = () => {
    switch (currentPage) {
      case "drawer-header":
        return <DrawerHeader agentInfo={agentInfo} getCrmClientResponse={getCrmClientResponse} />

      case "add-property":
        return (
          <AddPropertyInfo
            agentInfo={agentInfo}
            handleClose={() => {
              setCurrentDrawerPage("drawer-header")
              toggleUserOverview(payload)
            }}
          />
        )

      case "add-search-criteria":
        return (
          <AddSearchCriteria
            agentInfo={agentInfo}
            handleClose={() => {
              setCurrentDrawerPage("drawer-header")
              toggleUserOverview(payload)
            }}
          />
        )

      case "search-criteria":
        return (
          <SearchCriteriaDetailsInfo
            handleClose={() => {
              setCurrentDrawerPage("drawer-header")
              toggleUserOverview(payload)
            }}
          />
        )
      
      case "property-listing":
        return (
          <PropertyRequirementDetailsInfo
            handleClose={() => {
              setCurrentDrawerPage("drawer-header")
              toggleUserOverview(payload)
            }}
          />
        )

      case "edit-reminder":
        return (
          <AddSearchCriteria
            agentInfo={agentInfo}
            handleClose={() => {
              setCurrentDrawerPage("drawer-header")
              toggleUserOverview(payload)
            }}
          />
        )

      case "buy_before_you_sell":
      case "cash_offer":
      case "presale_home_prep":
      case "mortgage":
        return (
          <FinanceTransactionForm />
        )

      case "edit-profile":
        return (
          <EditProfile />
        )
      
      case "brbc-form-drawer":
        return (
          <BrbcDrawer />
        )

      default:
        return <p>Loader</p>
    }
  }
  return (
    <>
      {showUserOverviewDrawer && (
        <Drawer
          width={690}
          placement="right"
          onClose={handleClose}
          open={showUserOverviewDrawer}
          type="Basic Drawer"
          headerStyle={{
            display: "none",
          }}
        >
          {getCurrentPage()}
          {/* put children here with a redux calling function */}
        </Drawer>
      )}
    </>
  )
}

export default CRMV2Container(UserHeaderDrawer)
