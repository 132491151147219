import React, { lazy } from "react"
import styled from "styled-components"
import root from "window-or-global"
import HousingWire from "dumbComponents/NewLandingPage/images/housingWire.png"
import Inman from "dumbComponents/NewLandingPage/images/inman.png"
import WallStreet from "dumbComponents/NewLandingPage/images/wallStreetJournal.png"
import Yahoo from "dumbComponents/NewLandingPage/images/yahoo.png"

const CompanyWrap = styled.div`
  display: flex;
  margin: 65px auto;
  margin-bottom: 0px;
  gap: 130px;
  max-width: 1310px;
  width: 100%;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;

  @media(max-width: 1099px) {
    gap: 37px;
  }

  @media(max-width: 478px) {
    margin: 25px auto;
  }

  > img {
    cursor: pointer;
    height: 100%;
  }
`

const Image = styled.img`
`

const Companies = () =>  {
  return (
    <CompanyWrap>
      <Image
        src={Inman}
        alt="Inman"
        loading="lazy"
        width={108}
        height={38}
        onClick={() => {root.open("https://www.inman.com/2020/08/18/radius-agent-is-launching-a-virtual-100-commission-brokerage/")}}
      />
      <Image
        src={WallStreet}
        loading="lazy"
        alt="WallStreet"
        width={295}
        height={38}
        onClick={() => {root.open("https://www.wsj.com/articles/social-network-for-real-estate-grabs-funding-1532518200")}}
      />
      <Image
        src={Yahoo}
        alt="Yahoo"
        loading="lazy"
        width={121}
        height={38}
        onClick={() => {root.open("https://www.yahoo.com/now/real-estate-tech-brokerage-radius-080000463.html")}}
      />
      <Image
        src={HousingWire}
        alt="Housing Wire"
        loading="lazy"
        width={164}
        height={38}
        onClick={() => {root.open("https://www.housingwire.com/articles/radius-launches-mortgage-lending-arm/")}}
      />
    </CompanyWrap>
  )
}

export default Companies
