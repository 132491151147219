import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import AppContainer from "container/App"
import { SwitchToggleV2 } from "@ui/Form"
import root from "window-or-global"
import LeftArrow from "images/rooms/icons/leftArrow_33.svg"
import colors from "@colors"
import { ATC } from "@fonts"
import { get, isEmpty } from "lodash"

const Wrap = styled.div``

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 65px;
  background: #B5DCC7;
  margin-left: -10px;
  align-items: center;
  padding: 0px 30px;

  @media(max-width: 1366px) {
    padding: 0px;
  }
`

const TitleWrap = styled.div`
  display: flex;
  gap: 16px;
`

const Title = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
  font-family: ${ATC};
`

const LearnMoreWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
`

const LearnMore = styled.p`
  margin: 0;
  color: ${colors.black};
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
`

const SwitchWrap = styled.div`
  display: flex;
  gap: 10px;
`

const ToggleWrap = styled.div`
  margin-top: -3px;
`

const SwitchHeader = styled.p`
  margin: 0;
`

const RivenMortgageBanner = ({
  toggleAgentFinance,
  user,
}) => {
  const preferRadiusMortgage = get(user, "userReferralSettings.prefer_radius_mortgage")
  const [change, setChange] = useState(preferRadiusMortgage === 1)
  const officeAddress = get(user, "office_state")
  const citiesServed = get(user, "citiesServed")
  const californiaCheck = (citiesServed && citiesServed.filter(cities => cities.state.toLowerCase() === "california"))
  const isCalifornia = (officeAddress
    && officeAddress.toLowerCase().includes("california"))
      || !isEmpty(californiaCheck)

  useEffect(() => {
    setChange(preferRadiusMortgage === 1)
  }, [preferRadiusMortgage])

  const handleChange = () => {
    setChange(!change)
    toggleAgentFinance(Number(!change))
  }
  return (
    <>
      {isCalifornia && (
        <Wrap>
          <Container>
            <TitleWrap>
              <Title>
                Make sure every client is ready for pre-approval with Loandesk Mortgage.
              </Title>
              <LearnMoreWrap
                onClick={() => {
                  root.open("https://loandeskmortgage.com", "_blank")
                }}
              >
                <LearnMore>
                  Learn more
                </LearnMore>
                <img
                  src={LeftArrow}
                  alt="Right Arrow"
                  style={{
                    rotate: "180deg",
                  }}
                  height={14}
                  width={14}
                />
              </LearnMoreWrap>
            </TitleWrap>
            <SwitchWrap>
              <SwitchHeader>
                Lending Pre-Approval: OFF
              </SwitchHeader>
              <ToggleWrap>
                <SwitchToggleV2
                  selectorClassname="loan_pre_approval_toggle"
                  isSelected={change}
                  onClick={handleChange}
                  customWrapStyle={{
                    background: change ? "#306F4D" : `${colors.white}`,
                  }}
                />
              </ToggleWrap>
              <SwitchHeader>
                ON
              </SwitchHeader>
            </SwitchWrap>
          </Container>
        </Wrap>
      )}
    </>
  )
}

export default AppContainer(CRMV2Container(RivenMortgageBanner))
