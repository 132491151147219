import axios from "axios"

const ACADEMY_ENDPOINT = `${API_URL}shiksha`

// Events API
export const getEventListingAPI = () => axios.get(`${ACADEMY_ENDPOINT}/events?upcoming=true`)
export const commmunityEventListingAPI =
() => axios.get(`${ACADEMY_ENDPOINT}/events?upcoming=true&skip=0&limit=3`)
export const getEventDetailsAPI = eventId => axios.get(`${ACADEMY_ENDPOINT}/event/${eventId}`)
export const registerForEventAPI = payload => axios.post(
  `${ACADEMY_ENDPOINT}/agent-event`, payload
)

// Courses API
export const getCourseListingAPI = () => axios.get(`${ACADEMY_ENDPOINT}/courses`)
export const getFilteredCourseListingAPI = id => axios.get(`${ACADEMY_ENDPOINT}/courses?categoryIds=${id}`)
export const startCourseAPI = payload => axios.post(`${ACADEMY_ENDPOINT}/agent-course`, payload)
export const getCourseDetailsAPI = courseId => axios.get(`${ACADEMY_ENDPOINT}/course/${courseId}`)
export const getLessonDetailsAPI = lessonId => axios
  .get(`${ACADEMY_ENDPOINT}/lesson/${lessonId}`)
export const getNextLessionAPI = lessonId => axios.get(`${ACADEMY_ENDPOINT}/lesson/${lessonId}/next`)
export const changeLessonStatusAPI = payload => axios.post(`${ACADEMY_ENDPOINT}/agent-lesson`, payload)
export const changeCourseStatusAPI = payload => axios.post(`${ACADEMY_ENDPOINT}/agent-course`, payload)

// Categories API
export const getAllCategoriesAPI = () => axios
  .get(`${ACADEMY_ENDPOINT}/categories`)

// View API
export const academyViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)
