import React, { useEffect } from "react"
import styled from "styled-components"
import TechAssets from "container/TechAssets"
import { get } from "lodash"
import CRMV2Container from "container/CRM"
import HeroHeader from "dumbComponents/FinancingDashboard/pages/MortgageLP/Hero"
import { ATC } from "@fonts"
import MortgageBanner from "./MortgageBanner"
import Header from "./Header"
import TableContent from "./TableContent"

const Wrap = styled.div``

const WrapStyle = {
  minHeight: "200px",
}

const DescriptionStyle = {
  fontFamily: { ATC },
  lineHeight: "12px",
  fontWeight: "300",
  fontSize: "14px",
}

const TitleStyle = {
  lineHeight: "28px",
  fontSize: "25px",
}

const FinanceTabV2 = ({
  fetchTodaysPrice,
  getFinancingForTransaction,
  getCtIdResponse,
  getFinancingForTransactionResponse,
}) => {
  const { data } = getCtIdResponse || {}
  const { id } = data || {}
  const { data: financeData, isFetching } = getFinancingForTransactionResponse || {}
  const letterAvailable = !isFetching && financeData && get(financeData, "[0].is_pre_approval_letter_available")
  const showMortgageBanner = (letterAvailable || (financeData && financeData.length > 0))
  useEffect(() => {
    fetchTodaysPrice()
    getFinancingForTransaction({ transactionId: id })
  }, [])
  return (
    <Wrap>
      {showMortgageBanner && (
        <MortgageBanner />
      )}
      <HeroHeader
        CRMStyles
        WrapStyle={{
          minHeight: "200px",
        }}
        DescriptionStyle={{
          fontFamily: `${ATC}`,
          lineHeight: "12px",
          fontWeight: "300",
          fontSize: "14px",
        }}
        TitleStyle={{
          lineHeight: "28px",
          fontSize: "25px",
        }}
      />
      <Header />
      <TableContent />
    </Wrap>
  )
}

export default CRMV2Container(TechAssets(FinanceTabV2))
