import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import ReferralContainer from "container/Referral"
import TechAssetsContainer from "container/TechAssets"
import { DragDropContext } from "react-beautiful-dnd"
import { useWindowSize } from "services/Utils"
import Box from "@ui/Box"
import { ATC } from "@fonts"
import colors from "@colors"
import ZeroStateCRM from "dumbComponents/CRMV2/ZeroStateCRM"
import createToast from "@ui/Toast"
import { columnConfig } from "./config"
import Column from "./Column"
import {
  IN_CONTRACT, CLOSED, SELLER, BUYER, SENDING_RECEIVING_OFFERS, SHOWING_TOURS,
} from "../common"

const Wrap = styled.div`
  display: flex;
  margin-top: 20px;
  overflow-x: scroll;
  height: ${props => `${props.height}px` || "500px"};
  margin-right: 10px;
  gap: 24px;
  border-radius: 8px;
  ${props => props.leadCount && `
    border-bottom: 1px solid #bababa;
  `}
  &::-webkit-scrollbar { 
    display: none;
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   bottom: 0;
  //   left: 0;
  //   pointer-events: none;
  //   background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
  //   width: 100%;
  //   height: 4em;
  // }
`

const ShimmerDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`

const H1 = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.black};
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const DragAndDropArea = ({
  changeCardOrder,
  changeCardStage,
  currentFilters,
  leadsCount,
  toggleUserOverviewDrawer,
  getClientTransactions,
  getClientTransactionResponse,
  getClientTransactionsById,
  getBrokerageTransactionStatus,
  getBrokerageTransactionStatusResponse,
  stageLeadCount,
  toggleGoToContract,
  setIsAddListing,
  setIsCreateWarning,
  setIsAddOffer,
  setIsClosed,
  setIsEndContract,
  setIsViewOfferToContract,
  setIsViewListingToContract,
  setStatusValue,
  statusValue,
  getCtIdResponse,
  toggleEmptyDeatilsWarning,
  getClient,
  setCannotConvertContract,
  toggleShowInContract,
}) => {
  const size = useWindowSize()
  const { height } = size || {}
  // useWindowSize gives us the height of the entire window
  // so we are subtracting the height of the components above it and
  // making it responsive in terms that in bigger screens more cards are shown
  const windowHeight = height - 250

  const onBeforeDragStart = (result) => {
    try {
      const {
        draggableId,
        source,
        destination,
      } = result
      getBrokerageTransactionStatus({ transactionId: draggableId })
      getClientTransactionsById({ transactionId: draggableId })
    } catch (error) {
      // TODO: this
      console.log("something went toast", error)
    }
  }

  const onDragEnd = (result) => {
    try {
      const {
        draggableId,
        source,
        destination,
      } = result

      console.log("--result--", result)

      const { data, isFetching } = getCtIdResponse || {}
      const {
        req_id, prop_id, type, client_id,
      } = data || {}
      const { data: brokerage_transaction_status } = getBrokerageTransactionStatusResponse || {}
      const { initiateTransaction, status: transactionStatus, transactionType } = brokerage_transaction_status || {}
      if (BUYER.includes(type) && req_id === null) {
        toggleEmptyDeatilsWarning(true)
        getClient({ clientId: client_id })
        return
      }

      if (SELLER.includes(type) && prop_id === null) {
        toggleEmptyDeatilsWarning(true)
        getClient({ clientId: client_id })
        return
      }

      const startColumnId = source.droppableId
      const endColumnId = destination.droppableId
      setStatusValue(destination.droppableId)

      if (endColumnId === source.droppableId && startColumnId === source.index) {
        return
      }
      if (endColumnId === IN_CONTRACT) {
        setIsEndContract(true)
      }

      if (startColumnId === endColumnId) {
        changeCardOrder({
          source,
          destination,
          draggableId,
        })
        return
      }
      if ((endColumnId === IN_CONTRACT) && startColumnId !== CLOSED) {
        toggleGoToContract({
          isShowGoToContract: true,
          result,
        })
        return
      }
      if ((startColumnId === IN_CONTRACT || startColumnId === CLOSED) && endColumnId !== CLOSED) {
        if (SELLER.includes(type)) {
          setIsViewListingToContract(true)
        } else {
          setIsViewOfferToContract(true)
        }
        toggleGoToContract({
          isShowGoToContract: false,
          result,
        })
        return
      }
      if (startColumnId !== IN_CONTRACT && endColumnId === CLOSED) {
        setStatusValue(IN_CONTRACT)
        setIsClosed(true)
      }
      if ((startColumnId === IN_CONTRACT && endColumnId === CLOSED) || (endColumnId !== CLOSED)) {
        changeCardStage({
          source,
          destination,
          draggableId,
          currentFilters,
        })
      }
      if (initiateTransaction) {
        if ((startColumnId !== IN_CONTRACT || startColumnId !== CLOSED) && (endColumnId !== IN_CONTRACT || endColumnId !== CLOSED)) {
          if (SELLER.includes(type)) {
            setIsAddListing(true)
          }
        }
        if ((startColumnId !== IN_CONTRACT && endColumnId !== CLOSED)) {
          setIsCreateWarning(true)
        }
        if (endColumnId === "Sending/Receiving Offers" && (startColumnId !== IN_CONTRACT || startColumnId !== CLOSED)) {
          if (BUYER.includes(type)) {
            setIsAddOffer(true)
          }
        }
      }
    } catch (error) {
      // TODO: this
      console.log("something went toast", error)
    }
  }

  const { data } = getClientTransactionResponse || {}
  const { dragDropColumns, allTransactions } = data || {}
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}
      // onDragUpdate={onDragUpdate}
    >
      {leadsCount !== 0 && (
        <HeaderWrap>
          <H1>Active Client Status</H1>
        </HeaderWrap>
      )}
      <Wrap
        height={windowHeight}
        leadCount={leadsCount > 0}
      >
        {leadsCount === 0 ? (
          <Box d="block" style={{ width: "100%" }}>
            <ZeroStateCRM />
          </Box>
        ) : (
          <React.Fragment>
            {dragDropColumns && columnConfig().map((column) => {
              const transactionIdArray = dragDropColumns[column.value]
              const transaction = transactionIdArray && transactionIdArray.length > 0
                && transactionIdArray.map(transactionId => allTransactions[transactionId])
              const leadCount = stageLeadCount[`${column.value}`]
              return (
                <Column
                  key={column.display_name}
                  column={column}
                  leads={transaction}
                  leadsNumber={leadCount || 0}
                  toggleUserOverviewDrawer={toggleUserOverviewDrawer}
                  getClientTransactions={getClientTransactions}
                  height={height}
                />
              )
            })}
          </React.Fragment>
        )}
      </Wrap>
    </DragDropContext>
  )
}

export default TechAssetsContainer(ReferralContainer(CRMV2Container(DragAndDropArea)))
