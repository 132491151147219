import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import createToast from "@ui/Toast"
import Rooms from "container/Rooms"
import Container from "container/Referral"
import PubNubContainer from "container/PubNubActivities"

const user = checkForUserObject("user")

const agentId = user && user.id

class PubnubForFeed extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      showNewFeedsAvailable,
      currentMessageRecieved,
      showNewQuestionAvailable,
      getRooms,
    } = this.props

    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {

      root.pubNubFeed = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      const notificationChannel = `realtime_feed_post_${agentId}`
      const questionChannel = `realtime_feed_question_${agentId}`
      const sideMenuChannel = `${agentId}-side-menu`
      const userOnlineChannel = `RADIUS_AGENT_${agentId}_web`
      const videoRoomsChannel = "event-rooms-channel"

      root.pubNubFeed.subscribe({
        channels: [notificationChannel, questionChannel, userOnlineChannel, videoRoomsChannel],
      })

      root.pubNubFeed.addListener({
        message: (data) => {
          console.log("Listening to..", notificationChannel)
          console.log("Listening to..", questionChannel)
          console.log("Listening to..", sideMenuChannel)

          try {
            const { message, channel } = data
            console.log("check here->", data)
            const { event_type } = message
            console.log(message)
            console.log(event_type)
            if (message && message.length > 0 && channel.indexOf("post") > -1) {
              const splitMsg = message.split(",")
              const feedLength = splitMsg.length

              currentMessageRecieved({
                viewedFeed: message,
              })

              showNewFeedsAvailable({
                message,
                feedLength,
              })
            } else if (message && message.length > 0 && channel.indexOf("question") > -1) {
              const splitQuestions = message.split(",")
              const questionLength = splitQuestions.length

              currentMessageRecieved({
                viewedFeed: message,
              })

              showNewQuestionAvailable({
                questionLength,
              })
            }
            if (message && event_type === "yournetwork_videoroom_created") {
              getRooms({
                fetchLive: true,
              })
            }
            if (message && event_type === "public_video_room_created") {
              getRooms({
                fetchLive: true,
              })
            }
            if (message && event_type === "room_ended") {
              getRooms({
                fetchLive: true,
              })
              console.log("ROOM ENDED😎😎😎😎😎😎")
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { agentId } = this
    if (root.pubNubFeed) {
      root.pubNubFeed.unsubscribe({
        channels: [`realtime_feed_post_${agentId}`, `realtime_feed_question_${agentId}`, "event-rooms-channel"],
      })
    }
  }

  render() {
    return null
  }
}

export default Rooms(PubNubContainer(Container(PubnubForFeed)))
