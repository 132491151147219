import React, { useState, useEffect } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  InputArea,
  HiddenInputField,
} from "dumbComponents/ListingsV2/Components/InputFields"
import {
  InputField,
} from "dumbComponents/common/InputFields"
import {
  InputTags,
} from "dumbComponents/OffersV3/CoverSheet/Components/InputFields"
import {
  BlueButton,
  WhiteButton,
} from "dumbComponents/ListingsV2/commonStyles"
import {
  Form,
} from "@ui/antd"
import {
  FormWrap,
  CTAWrap,
  DIV,
  CountTracker,
  SuffixWrap,
} from "./styles"
import EmailSelect from "./InputTags"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const SendOfferForm = ({
  onFinish,
  onFinishFailed,
  toClose,
  children,
  sendingOfferPackage,
  propertyInformationObject,
  buyersAgentInformationObject,
}) => {
  const [subjectLength, setSubjectLength] = useState(0)
  const [messageLength, setMessageLength] = useState(0)
  const [validText, setValidText] = useState('');

  const {
    currentState,
    form,
    listing_agents,
  } = propertyInformationObject || {}

  const {
    agents,
    buyers,
  } = buyersAgentInformationObject || {}
  const address = form[currentState]
  //const property_address = `${address.street}, ${address.city}, ${address.county}, ${address.state}, ${address.zipcode}`
  const getInitialValues = () => {
    const intiValues = {
      from: agents[0].name,
      from_email: agents[0].email,
      to: listing_agents[0].name,
      to_email: listing_agents[0].email,
      cc: agents[0].name,
      cc_email: agents[0].email,
      offer_package_subject: `Submission of Buyer's Offer for ${property_address}`,
      custom_message: "",
    }
    return intiValues
  }

  useEffect(() => {
    if (validText) {
      setMessageLength(validText.length);
    } else {
      setMessageLength(0);
    }
  }, [validText]);

  return (
    <FormWrap>
      <Form
        name="offers_send_offer_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <HiddenInputField name="from" />
        <HiddenInputField name="original_message" />

        <HiddenInputField name="from_email" />
        
        <HiddenInputField name="to" />

        <InputField
          name="to_email"
          type="email"
          placeholder="To"
          text="To"
          required={true}
          requiredMessage="Please enter an email address."
        />

        {/* <InputTags
          name="cc_email"
          type="email"
          placeholder="CC"
          text="CC"
        />   */}
        <Form.Item name="cc_email" label={<LabelComponent text="CC"/>} >
        <EmailSelect text="CC"/>
        </Form.Item>
        

        <HiddenInputField name="cc" />

        <DIV>
          <InputField
            name="offer_package_subject"
            inputType="text"
            placeholder="Subject"
            text="Subject"
            required={true}
            requiredMessage="Please enter a subject."
            maxLength={100}
            onChange={(e) => {
              const validText = e.target.value
              if (validText) {
                setSubjectLength(validText.length)
              }
            }}
          />
          <CountTracker>
            <p>{`${subjectLength} / 100`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          <InputArea
            name="custom_message"
            inputType="text"
            placeholder="Custom Message*"
            requiredMessage="Please enter a message."
            maxLength={900}
            required={true}
            onChange={(e) => {
              const newText = e.target.value;
              setValidText(newText);
            }}
          />
          <CountTracker>
            <p>{`${messageLength} / 900`}</p>
          </CountTracker>
        </DIV>

        <DIV>
          {children}
        </DIV>

        <CTAWrap style={{    position: 'sticky',background: '#F9F9FF', paddingTop:'20px',
    bottom: 0}}>
          <Form.Item>
            <BlueButton
              style={{
                width: "100%",
              }}
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              {sendingOfferPackage ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Send</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default SendOfferForm
