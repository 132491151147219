import React from "react"
import styled from "styled-components"
import colors from "@colors"

const LabelTextWrap = styled.div``

const LabelText = styled.p`
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
`

const LabelDesc = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`

const ExtraDesc = styled.p`
  font-family: DM Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`

const FieldHeaderComponent = ({
  header,
  desc,
  extraDesc,
}) => (
  <LabelTextWrap>
    <LabelText>{header}</LabelText>
    <LabelDesc>{desc}</LabelDesc>
    {extraDesc && (
      <ExtraDesc>{extraDesc}</ExtraDesc>
    )}
  </LabelTextWrap>
)

export default FieldHeaderComponent
