import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { LoadScript } from "services/Utils"

const Wrap = styled.div`
  background: #f1f1f1;
  margin-top: 10px;
  video {
    width: 100%;
  }
`

class VideoComponent extends React.PureComponent {
  componentDidMount() {
    if (!root.Hls) {
      LoadScript("https://cdn.jsdelivr.net/npm/hls.js", () => {
        this.attackVideo()
      })
    } else {
      this.attackVideo()
    }
  }

  attackVideo = () => {
    if (root.Hls.isSupported()) {
      const { videos, id } = this.props
      const videoCont = root.document.getElementById(`video-container-${id}`)
      const hls = new root.Hls()
      hls.loadSource(videos[0].url)
      hls.attachMedia(videoCont)
    }
  }

  render() {
    const { id, videos } = this.props
    return (
      <Wrap>
        <video id={`video-container-${id}`} controls />
      </Wrap>
    )
  }
}

export default VideoComponent
