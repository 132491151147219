import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import {
  DashboardHomeSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const BetaHighlight = styled.p`
  font-size: 12px !important;
  line-height: 1 !important;
  text-align: left !important;
  color: #eb526f !important;
  margin: 3px 6px 0;
  font-family: ${ATC};
  font-weight: 200;
`



const CommissionTracker = ({
  routeArray,
}) => (
  <InnerTab pt="4px" isActive={routeArray[1] === "dashboard"}>
    <div className="icon-block-new">
      <IconEle src={`${CLOUDFRONT}/SidebarIcons/Status.png`} alt="RARhome" isActive={routeArray[1] === "dashboard"} />
    </div>
    <div className="text-block">
      <NavLink
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_dashboardRAR_web")
          }
        }}
        to="/realty/dashboard/promo"
        // eslint-disable-next-line react/jsx-no-duplicate-props
        isActive={routeArray[1] === "dashboard"}
      >
        Commission Tracker
        {/* <BetaHighlight>
          Beta
        </BetaHighlight> */}
      </NavLink>
    </div>
  </InnerTab>
)

export default CommissionTracker
