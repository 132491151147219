import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Toggle = styled.button`
  display: none;
  background-color: white;
  padding: 9px 10px;
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;

  &:focus {
    background-color: #ddd;
    border-color: #ddd;
  }

  @media (max-width: 768px) {
    display: inline-block;
  }
`

const IconBar = styled.div`
  background-color: #888;
  display: block;
  width: 22px;
  height: 2px;
  margin-top: 4px;
  border-radius: 1px;

  &:first-child {
    margin-top: 0;
  }
`

const NavbarToggle = ({ onClick }) => (
  <Toggle onClick={onClick}>
    <IconBar />
    <IconBar />
    <IconBar />
  </Toggle>
)

export default NavbarToggle
