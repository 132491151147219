import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import InviteContainer from "container/App/inviteFeatureContainer"
import { authCheck } from "services/auth"
import { checkForUserObject } from "services/Utils"


const Wrapper = styled.div`
  max-width: 300px;
  margin-top: 1em;
  padding: 0.25em 0 1em;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  font-family: sans-serif;
`

const H3 = styled.h3`
  text-align: center;
  font-size: 18px;
`
const WebinarAnchorWrap = styled.div`
  text-align: center;
  margin-top: 1em;
`

const WebinarAnchor = styled.a`
  color: #000;
  font-size: 13px;
`

const RegisterAnchor = styled.a`
  text-decoration: none;
`

const RegisterButton = styled.button`
  font-family: inherit;
  position: relative;
  display: flex;
  padding: 0.7em 2em;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  outline: none;
  border-radius: 50px;
  transition: all 0.2s;
  background: #3e58c9ff;
  color: #ffffff;
  margin: 0 auto;
  font-weight: 500;
  font-size: inherit;
  box-sizing: border-box;
`

const CloseBtn = styled.div`

`

const Span = styled.span`
  opacity: 1;
`

const CSS_CODE = `
.ewebinar__Dots {
  opacity: 1;
  animation: 1.5s linear 0s normal forwards 1 delayin;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ewebinar__LoadingDot {
  height: 0.5em;
  width: 0.5em;
  border-radius: 100%;
  display: inline-block;
  animation: 1.2s ewebinar-loading-dot ease-in-out infinite;
}

.ewebinar__LoadingDot:nth-of-type(2) {
  animation-delay: 0.15s;
  margin-left: 0.5em;
}

.ewebinar__LoadingDot:nth-of-type(3) {
  animation-delay: 0.25s;
  margin-left: 0.5em;
}

@keyframes delayin {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ewebinar-loading-dot {
  30% {
    transform: translateY(-35%);
    opacity: 0.3;
  }
  60 % {
    transform: translateY(0%);
    opacity: 0.8;
  }
}

.ewebinar__Widget {
  line-height: 1.5;
}

.ewebinar__Widget select {
  display: flex;
}

.ewebinar__Widget * {
  box-sizing: border-box;
}

.ewebinar__RegisterButton {
  font-family: inherit;
  * {
    font-family: inherit;
  }
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.1);
    border: none !important;
  }
  &:active {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 614px) {
  .ewebinar__RegisterButton {
    max-width: 320px !important;
    min-height: 42px !important;
    width: 100% !important;
  }
}
`

class EWebinar extends React.PureComponent {
  state = {
    show: true,
  }

  componentDidMount() {
    const {
      user: userObj,
      isBroker,
    } = this.props

    const {
      citiesServed: userObjectCitiesServed,
    } = userObj || {}

    const pathnameArray = [
      "realty",
      "/network",
      "/radius",
      // "/radius-assist",
      "/realty",
      "/realty/agents",
      "/realty/teams",
      "/realty/pricing",
      "/realty/for-agents",
      "/realty/for-teams",
      "/realty/radius-financing",
      "/rooms",
      "/login",
      "/signup",
    ]

    const isLoggedIn = authCheck()
    const { pathname } = root.location
    const pathnameConditions = pathname === "/" || pathnameArray.includes(pathname)
    console.log(pathname)
    const excludePaths = !pathname.includes("onboarding") && !pathname.includes("rooms") && !pathname.includes("login")
    //const isCalifornia = userObjectCitiesServed && userObjectCitiesServed.length > 0 &&
    //userObjectCitiesServed.filter(obj => obj.state === "California")
    //const isColorado = userObjectCitiesServed && userObjectCitiesServed.length > 0 &&
    //userObjectCitiesServed.filter(obj => obj.state === "Colorado")
    //const showBrokerageUsers = (isCalifornia && isCalifornia.length > 0) || (isColorado && isColorado.length > 0)
    console.log("excludePaths", excludePaths)
    const publicState = !isLoggedIn && pathnameConditions && excludePaths
    console.log(publicState)
    const loggedInState = isLoggedIn && !isBroker && excludePaths

    if (!publicState) {
      this.setState({
        show: false,
      })

      //const ele = root.document.getElementsByClassName("ewebinar__Has_FloatIn")
    }

    if (false && root.innerWidth > 1024 && (publicState || loggedInState)) {
      (function(w, d, s, o, f, js, fjs) {
        w.eWidget = o;
        w[o] = w[o] || function() {
          (w[o].q = w[o].q || []).push(arguments)
        }
        if (d.getElementById(o)) return;
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o;
        js.src = f;
        js.defer = 1;
        fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', '_ew', 'https://app.ewebinar.com/widget.js'));
      _ew("init", {
        for: "Registration",
        type: "FloatIn",
        btnText: "Join the next webinar",
        source: "",
        jitBtnText: "Go to session now",
        addToCalendarBtnText: "Add to calendar",
        replayBtnText: "Watch replay now",
        onlyButton: false,
        openInPopup: false,
        position: "Left",
        mobilePosition: "Left",
        url: "https://app.ewebinar.com/webinar/radius-brokerage-overview-6293",
        shortUrl: "https://app.ewebinar.com/webinar/6293",
        title: "10-Minute%20Radius%20Agent%20Brokerage%20Overview",
        description: "About%20this%20webinar%20Learn%20about%20Radius%20Agent%20in%20less%20than%2010%20minutes.%20What%20you%E2%80%99ll%0Alearn%20We%E2%80%99ll%20discuss%20the%20benefits%20of%20our%20brokerage,%20including:%20Lead%20Partnerships%0ACommission%20Structure%20Support%20Benefits%20Please%20sign-up!",
        presenters: [{
            avatar: "https://ewebinar.imgix.net/user/1871/profile-1633099814204?ixlib=js-2.3.2&fit=facearea&w=100&h=100&dpr=2&faceindex=1&facepad=5&mask=ellipse&fm=png&&s=051da1d4e1878d2b2f79d3daf088b95f",
            name: "Ciara%20Tejani"
        }],
        image: "https://ewebinar.imgix.net/ewebinar/14086/thumbnail-1632849767736?ixlib=js-2.3.2&fit=clip&w=500&h=300&dpr=2&fm=jpg&bg=FFFFFF&s=08fe0e1ecbec3346c63bb240186a88b9",
        language: "en",
        sessions: [],
        borderRadius: 50,
        primaryColor: "#3e58c9ff",
        readableColor: "#ffffff",
        readableOnWhiteColor: "#3e58c9ff",
        root: "w1633575782974"
      })
      this.toggleBanner(true)
    }
  }

  toggleBanner = (show) => {
    setTimeout(() => {
      try {
        const ele = root.document.querySelector(".ewebinar__Widget")
        if (ele && show) {
          ele.style.display = "block"
          ele.style.zIndex = "99"
        } else if(ele) {
          ele.style.display = "none"
        }
      } catch (error) {
        console.log(error)
      }
    }, 1000)
  }

  componentDidUpdate() {
    console.log("??")
    const ele = root.document.querySelector(".ewebinar__Widget")
    console.log(ele, "<-")
    const { pathname } = root.location
    console.log(pathname)
    if (ele && (pathname === "/rooms" || pathname === "/signup")) {
      ele.setAttribute("style", "display: none;")
    } else if (ele) {
      ele.setAttribute("style", "display: block;")
    }
  }

  componentWillUnmount() {
    //this.toggleBanner(false)
  }

  render() {
    const {
      show,
    } = this.state || {}

    if (!show) {
      return null
    }
  
    return (
      <noscript>
        <style>
          {CSS_CODE}
        </style>
        <>
          {false && (
            <Wrapper>
              <CloseBtn>
                close
              </CloseBtn>
              <H3>10-Minute Radius Agent Brokerage Overview</H3>
              <RegisterAnchor
                className="ewebinar__RegisterButton__Wrap"
                href="https://app.ewebinar.com/webinar/10-minute-radius-agent-brokerage-overview-2540"
              >
                <RegisterButton
                  type="button"
                  className="ewebinar__Widget ewebinar__RegisterButton"
                >
                  <Span className="ewebinar__ButtonText">
                    Join the next Webinar
                  </Span>
                </RegisterButton>
              </RegisterAnchor>
              <WebinarAnchorWrap>
                <WebinarAnchor href="https://ewebinar.com">
                  Best automated webinars
                </WebinarAnchor>
              </WebinarAnchorWrap>
            </Wrapper>
          )}
        </>
      </noscript>
    )
  }
}

export default InviteContainer(EWebinar)
