import React from "react"
import styled, { keyframes } from "styled-components"
import { withRouter } from "react-router-dom"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride"
import colors from "@colors"
import TooltipBody from "./TooltipBody"
import { createSteps } from "./helpers"

const zIndexFix = {
  styles: {
    options: {
      zIndex: 10000,
    },
  },
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(255, 100, 100, 0.9);
    transform: scale(1.6);
  }
`

const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: rgba(255, 27, 14, 0.6);
  border-radius: 50%;
  display: inline-block;
  height: 3rem;
  width: 3rem;
`

class JoyrideComponent extends React.Component {
  state = {
    run: false,
    steps: [],
    stepIndex: 0,
    sidebarOpen: true,
  }

  componentDidMount() {
    const isSpotlightShown = cookie.load("isSpotlightShownNew")
    const isVideoRoomsShown = cookie.load("isVideoRoomsSpotlightShown")
    // //Spotlight is already been shown. Stop it and move on.
    if (isSpotlightShown) {
      return
    }
    this.ele = root.document.getElementById("index")
    this.ele.classList.add("noScroll")
    cookie.save("isSpotlightShownNew", true)
    const { user } = this.props
    const steps = createSteps(user)
    this.setState({
      run: true,
      steps,
    })
  }

  handleJoyrideCallback = (data) => {
    const {
      action,
      index,
      type,
      status,
    } = data
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      const { finishTabsSpotlightFlow } = this.props
      this.ele.classList.remove("noScroll")
      finishTabsSpotlightFlow({
        isUserOnboarded: true,
      })
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false, stepIndex: 0 })
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      this.setState({
        stepIndex,
      })
    }
  }

  render() {
    const {
      run,
      steps,
      stepIndex,
    } = this.state
    return (
      <ReactJoyride
        continuous
        run={run}
        beaconComponent={Beacon}
        steps={steps}
        stepIndex={stepIndex}
        hideBackButton
        scrollToFirstStep
        callback={this.handleJoyrideCallback}
        disableScrollParentFix
        disableScrolling
        spotlightPadding="4"
        locale={{
          last: "Finish",
        }}
        styles={{
          options: {
            arrowColor: colors.contentText,
            backgroundColor: colors.contentText,
            overlayColor: "rgba(255, 255, 255, 0.7)",
            primaryColor: colors.primaryColor,
            textColor: colors.white,
          },
          tooltipFooter: {
            marginTop: 0,
          },
          tooltipContent: {
            padding: "0px",
          },
          buttonNext: {
            background: "transparent",
            color: colors.white,
          },
          buttonClose: {
            display: "none",
          },
        }}
      />
    )
  }
}

export default withRouter(JoyrideComponent)
