import React from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OnboardingContainer from "container/OnboardingSetup"
import GetStartedImage from "../../Images/GetStartedImage.svg"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  SignupButtons,
  UnderlinedText,
} from "../../common"

const EmailSentPage = ({
  location,
  sendVerifyLink,
  sendVerfyLinkResponse,
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { email } = parsedQuery
  const { isFetching } = sendVerfyLinkResponse || {}

  const handleClick = () => {
    const payload = {
      email,
    }
    sendVerifyLink({ payload })
  }

  return (
    <Wrap>
      <ContentWrap>
        <RightWrap>
          <WelcomeContentWrap>
            <WelcomeTitle width="455px">
              A verification link has been sent to your email
            </WelcomeTitle>
            <WelcomeDescription width="501px">
              {`A verification link will be sent to ${email}`}
            </WelcomeDescription>
          </WelcomeContentWrap>
          <WelcomeContentWrap marginTop="30px">
            <UnderlinedText>Haven’t received an email yet?</UnderlinedText>
            <SignupButtons
              width="256px"
              onClick={handleClick}
            >
              {isFetching ? <ThreeDotLoader color="#FFFFFF" /> : "Re-send Verification link"}
            </SignupButtons>
          </WelcomeContentWrap>
        </RightWrap>
        <LeftWrap justifyContent="flex-end">
          <LeftImage src={GetStartedImage} />
        </LeftWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(OnboardingContainer(EmailSentPage))
