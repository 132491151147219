import React from "react"
import { Form } from "@ui/antd"
import SearchContainer from "container/Search/SearchContainer"
import {
  Wrap,
  SelectFieldWrap,
} from "./styles"
import DebounceSelect from "./Debounced"

const DebouncedAgentSearch = ({
  placeholder,
  options,
  style,
  arrowIcon,
  isFormField,
  required,
  defaultValue,
  name,
  onChange,
  formRef,
  getSearchedAgents,
  isFetchingAgents,
  agents,
}) => {
  const rules = [{ required }]

  const fetchUserList = async (search) => {
    const url = `${baseUrl}/v1.0/agent/elastic/query`

    const payload = {
      text_value: search,
      sortIsAsc: false,
      ascending: false,
      facade: false,
      from: 0,
      size: 10,
      text_search_params: ["email", "full_name"],
      unRegistered: false,
    }

    const mapped = await axios.post(url, payload)
      .then((res) => {
        const {
          response,
        } = res.data || {}
        return response && response.agents || []
      })

    const options = mapped && mapped.map(item => ({
      value: item.id,
      label: `${item.firstname || "Radius"} ${item.lastname || "User"}`,
    }))

    return options
  }

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={rules}
          >
            <DebounceSelect
              showSearch
              placeholder="Select users"
              value={searchValue}
              fetchOptions={fetchUserList}
              // onChange={(newValue) => {
              //   setValue(newValue)
              //   handleFilterQuery("agent_id", newValue)
              // }}
            />
          </Form.Item>
        )
      default:
        return (
          <Wrap>
            <DebounceSelect
              showSearch
              placeholder="Select users"
              value={searchValue}
              fetchOptions={fetchUserList}
            />
          </Wrap>
        )
    }
  }

  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SearchContainer(DebouncedAgentSearch)
