import styled from "styled-components"
import { INTER } from "@fonts"

export const FormWrap = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 10px;
  form {
    width: 100%;

    .ant-form-item {
      margin-left: unset;
    }
  }

  // ${props => props.isAddCoAgentForm && `
  //   margin-top: 25px;
  // `}
`;

export const FormWrap2 = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 10px;
  background: #F9FAFB;
  form {
    width: 100%;

    .ant-form-item {
      margin-left: unset;
    }
  }

  // ${props => props.isAddCoAgentForm && `
  //   margin-top: 25px;
  // `}
`

export const FormTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #71717A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.352px;
    margin: 0;
    display: flex;
  }
`

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E6FF;
  margin-bottom: 20px;
`

export const CancelOrGoBackButton = styled.button`
  border-radius: 8px;
  border: 1px solid var(--Color-Red-Red-500, #F05152);
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  img {
    width: 16px;
    height: 16px;
  }
  p {
    color: var(--Color-Red-Red-500, #F05152);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    letter-spacing: 1px;
    margin: 0;
    display: flex;
    text-transform: uppercase;
  }
`

export const CTAWrap = styled.div`
  display: flex;

  .ant-form-item {
    width: unset;
  }
`

export const FormFieldWrap = styled.div`
  display: flex;
  gap: 10px;
`
export const CoAgentWrap = styled.div`
  background: #F9FAFB;
  padding: 16px;
  border-radius: 9px;
  margin: 10px 0px;
`
export const ListRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  align-items: baseline;
  margin-bottom: 4px;
`