import React from "react"
import styled from "styled-components"
import ForTeams_BrandImage from "dumbComponents/NewTeamsPages/images/ForTeams_BrandImage.png"
import colors from "@colors"
import Checkmark from "images/Graphics/RadiusEdgeAssets/Checkmark_1.svg"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const Wrap = styled.div`
  padding: 60px;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media(max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media(max-width: 425px) {
    padding: 10px;
  }
`

const ImageWrap = styled.div`

  > img {
    width: 100%;
  }

  > div {
    position: absolute;
    bottom: 0;
    padding: 10px 10px 0 0;
    background-color: ${colors.white};

    > p {
      font-size: 16px;
      margin: 0;
    }

    > h1 {
      font-size: 83px;
      margin: 0;
      color: ${colors.marineBLue};

      @media(max-width: 425px) {
        font-size: 60px;
      }
    }
  }
`

const ContentWrap = styled.div`
  > div {
    padding: 20px;
    > h1 {
      max-width: 291px;
      width: 100%;
      font-size: 42px;
      font-family: ${HIGHLIGHT_FONT};
      color: ${colors.black};

      @media(max-width: 768px) {
        max-width: 645px;
        font-size: 36px;
        margin-bottom: 30px;
      }

      @media(max-width: 425px) {
        font-size: 32px;
        display: flex;
        text-align: center;
      }
    }

    > p {
      font-size: 22px;
      max-width: 399px;
      font-weight: 400;

      @media(max-width: 768px) {
        margin-top: 30px;
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }
  }
`
const CheckmarkIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 5px;
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    align-items: flex-start;
    gap: 10px;
    display: flex;

    > p {
      margin: 0;
      font-size: 22px;
      width: 399px;
      font-family: ${ATC};
      font-weight: 200;
      color: ${colors.black};

      @media(max-width: 1024px) {
        font-size: 20px;
        width: 505px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
        width: 100%;
      }
    }
  }
`

const BuildYourOwnBrand = () => {
  return (
    <Wrap>
      <ContentWrap>
        <div>
          <h1>
            Build your own brand, not ours
          </h1>
          <TextWrap>
            <div>
              <CheckmarkIcon src={Checkmark} alt="Radius Checkmark" />
              <p>
                Work with our in-house design team to create a brand
              </p>
            </div>
            <div>
              <CheckmarkIcon src={Checkmark} alt="Radius Checkmark" />
              <p>
                Get all new marketing collateral for your team
              </p>
            </div>
            <div>
              <CheckmarkIcon src={Checkmark} alt="Radius Checkmark" />
              <p>
                We will set up your DBA & handle legal paperwork
              </p>
            </div>
          </TextWrap>
        </div>
      </ContentWrap>
      <ImageWrap>
        <img src={ForTeams_BrandImage} alt="Radius Flat Fee" />
      </ImageWrap>
    </Wrap>
  )
}

export default BuildYourOwnBrand
