import * as React from "react"

const Notifications = (props) => {
  const { color = "#E5E6FF" } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 8.685a6 6 0 1 1 12 0c0 7 3 9 3 9H3s3-2 3-9ZM10.3 21.685a1.94 1.94 0 0 0 3.4 0"
      />
    </svg>
  )
}

export default Notifications
