import React from "react"
import { get } from "lodash"
import GoogleLocation from "@ui/GoogleAutoCompleteV3"
import {
  GoogleLocationWrap,
} from "./googleLocationSearchStyles"

const GoogleLocationInput = ({
  setCity,
  city,
  disabled,
  placeholder,
}) => (
  <GoogleLocationWrap>
    <GoogleLocation
      type="cities"
      disabled={disabled}
      value={city}
      placeholder={placeholder}
      placeholderColor="#bfbfbf"
      location={(location) => {
        const selectedCity = {
          city: get(location, "locality.long_name"),
          state: get(location, "administrative_area_level_1.long_name"),
          country: get(location, "country.long_name"),
          latitude: get(location, "lat"),
          longitude: get(location, "lng"),
        }
    
        setCity(selectedCity)
      }}
    />
  </GoogleLocationWrap>
)

export default GoogleLocationInput
