import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import OnboardingContainer from "container/OnboardingSetup"
import { withRouter } from "react-router-dom"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  RightWrap,
  MiddleWrap,
  StepsWrap,
  StepsContentWrap,
  StepTitle,
  StepDescription,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  GetStartedWrap,
  BottomText,
  Underlined,
  ICAButtons,
} from "../../common"
import {
  DashIcon,
  ReviewFreeIcon,
  checkTick,
  disableIcon,
} from "../../consts"

const checkTickContent = [
  {
    content: "Your role and responsibilities",
  },
  {
    content: "Fee Schedule",
  },
  {
    content: "Terms of our partnership",
  },
  {
    content: "Other legal provisions",
  },
]

const CheckTickWrap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const CheckTickContent = styled.p`
  margin: 0;
  color: var(--color-tertiary, #1C1C1F);
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 21.073px */
  text-transform: capitalize;
`

const Partnership = ({
  history,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap
          // paddingTop="200px"
          // alignItems="flex-start"
        >
          <MiddleWrap>
            <StepsWrap>
              <img src={ReviewFreeIcon} alt="hello" height={57} width={57} style={{ marginLeft: "-3px" }} />
              <StepsContentWrap>
                <StepTitle>
                  Step 1
                </StepTitle>
                <StepDescription>
                  REVIEW FEE SCHEDULE
                </StepDescription>
              </StepsContentWrap>
            </StepsWrap>
            <img src={DashIcon} alt="okay" height={39} width={52} />
            <StepsWrap>
              <img src={disableIcon} alt="hello" height={46} width={46} />
              <StepsContentWrap style={{ marginLeft: "10px" }}>
                <StepTitle>
                  Step 2
                </StepTitle>
                <StepDescription>
                  SIGN ICA
                </StepDescription>
              </StepsContentWrap>
            </StepsWrap>
          </MiddleWrap>
        </LeftWrap>
        <RightWrap>
          <WelcomeContentWrap>
            <WelcomeTitle>
              Solidify our partnership
            </WelcomeTitle>
            <WelcomeDescription>
              Your next step is to sign your Independent Contractor Agreement. Inside your ICA, you’ll find essential details about the following:
            </WelcomeDescription>
          </WelcomeContentWrap>
          {checkTickContent.map(content => (
            <CheckTickWrap>
              <img src={checkTick} alt="check tick" height={15} width={15} />
              <CheckTickContent>
                {content.content}
              </CheckTickContent>
            </CheckTickWrap>
          ))}
          <GetStartedWrap>
            <ICAButtons
              onClick={() => history.push("/office/ica-onboarding/agreement")}
            >
              Sign ICA
            </ICAButtons>
          </GetStartedWrap>
          <BottomText>
            If you have any questions or need clarification,&nbsp;
            <Underlined onClick={() => root.open("https://knowledgebase.radiusagent.com/knowledge/understanding-your-ica", "_blank")}>you can learn more about ICA signing here. </Underlined>
          </BottomText>
        </RightWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default withRouter(OnboardingContainer(Partnership))
