import React, { useEffect } from "react"
import { required } from "services/formUtils"
import {
  Input,
  DatePicker,
  Textarea,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"
import { withRouter } from "react-router-dom"
import { format } from "date-fns"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"
import { REQUESTING_SIGN_POST_INSTALLATION, FIELD_VALUES } from "./data"
import SubmitButton from "../Common/SubmitButton"
import CheckboxComponent from "../Common/RadioGroup"
import {
  Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
  MarginWrap,
} from "../Common/CommonStyles"
import {
  HandleSubmit,
  FunctionalComponentDidMount,
  backButtonUrl,
  BrowserCloseListener,
  FetchFileIfIDExists,
} from "../Common/Utils"
import {
  DETAILS_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "../../Routes"
const DATE_FORMAT = "MMM DD, YYYY"

const SignPostageFormUI = ({
  ...props
}) => {
  const {
    id,
    history,
    invalid: isInvalid,
    handleSubmit,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    fetchTransactionCoordinationDocumentsResponse,
    destroy,
    scheduleSignPostInstallation,
    existingSchedulePostValue,
    formName,
    isFormDisabled,
  } = props || {}

  BrowserCloseListener()

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    isFetching: creatingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  let completedStepsArray = []

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    completedStepsArray = currentDeal && currentDeal.completed_steps
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName)
  }

  const isProcessing = fetchingValues || creatingForm || updatingTCStage || isFetchingDeals

  const showAllOptions = (existingSchedulePostValue && existingSchedulePostValue !== undefined
   && existingSchedulePostValue)
    || (scheduleSignPostInstallation !== undefined
     && scheduleSignPostInstallation === "sign_post_installation_request_yes")

  const getLastRoute = lastRouteArray => lastRouteArray.includes("transaction_coordination")
    ? COORDINATION_FORM_ROUTE : TCAGENT_FORM_ROUTE

  return (
    <>
      {isProcessing ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          <CheckboxComponent
            noButtonReposition
            name="schedule_sign_post_installation"
            optionArray={REQUESTING_SIGN_POST_INSTALLATION}
            labelText="If you are already using a sign post company, would you like our TC to coordinate with them?"
            marginBottom="31px"
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
            validate={[required]}
            disabled={isFormDisabled}
          />
          {showAllOptions && (
            <>
              <Field
                label="Date you want the sign to be installed"
                component={DatePicker}
                validate={[required]}
                disabled={isFormDisabled}
                name="sign_post_installation_on"
                config={{
                  formatDate: format,
                  format: DATE_FORMAT,
                  placeholder: `${format(new Date(), DATE_FORMAT)}`,
                }}
                customInputStyle={{
                  height: "54px",
                  width: "490px",
                  borderRadius: "8px",
                  padding: "15px 16px 15px 16px",
                  justify: "space-between",
                  border: "1px solid #96A3BD",
                }}
              />
              <MarginWrap
                type="margin-top"
                value="20px"
              >
                <Field
                  name="sign_post_installation_riders"
                  component={Input}
                  disabled={isFormDisabled}
                  placeholder="Select riders"
                  label="If riders are necessary, please specific"
                  validate={[required]}
                  customInputStyle={{
                    width: "490px",
                  }}
                />
              </MarginWrap>
              <MarginWrap
                type="margin-top"
                value="20px"
              >
                <Field
                  component={Textarea}
                  disabled={isFormDisabled}
                  version={2}
                  name="sign_post_installation_location"
                  label="What is the specific location where the sign should be installed?"
                  placeholder="The sign should be in the front lawn, on the left side of the driveway."
                  cols="30"
                  rows="10"
                  validate={[required]}
                  customLabelStyles={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                  customStyle={{
                    height: "121.83999633789062px",
                    width: "490px",
                    borderRadius: "8px",
                    padding: "15px 16px 15px 16px",
                    border: "1px solid #96A3BD",
                  }}
                />
              </MarginWrap>
              <MarginWrap
                type="margin-top"
                value="20px"
              >
                <Field
                  component={Textarea}
                  version={2}
                  disabled={isFormDisabled}
                  name="hoa_guidelines"
                  label="Are there any specific HOA guidelines?"
                  placeholder="The sign must be near the middle of the yard."
                  cols="30"
                  rows="10"
                  validate={[required]}
                  customLabelStyles={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                  customStyle={{
                    height: "121.83999633789062px",
                    width: "490px",
                    borderRadius: "8px",
                    padding: "15px 16px 15px 16px",
                    border: "1px solid #96A3BD",
                  }}
                />
              </MarginWrap>
            </>
          )}
          <CTAWrap>
            <BackButton
              type="button"
              onClick={() => {
                //goes previous form
                backButtonUrl(history, id, getLastRoute(completedStepsArray))
              }}
            >
              Back
            </BackButton>
            <SubmitButton
              isInvalid={isInvalid || !scheduleSignPostInstallation}
              disabled={isFormDisabled}
              isProcessing={isProcessing}
              text={isFormDisabled ? "Next" : "Continue"}
            />
          </CTAWrap>
        </FormWrap>
      )}
    </>
  )
}

const SignPostageFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  properties,
  props,
  toggleErrorModal,
  fetchTCFiles,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  const disableField = isCompleted || isCoListed

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  const {
    schedule_sign_post_installation,
  } = combinedTCValues || {}

  const {
    schedule_sign_post_installation: existingScheduleSignPostInstallation,
  } = currentDeal || {}

  return (
    <Wrap>
      <Header
        text="Sign and Post Installation"
        history={history}
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <SignPostageForm
          onSubmit={values => HandleSubmit(
            values,
            UPLOAD_FILE_FORM_ROUTE,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "sign_post_installation",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          id={parsedQuery.id}
          scheduleSignPostInstallation={schedule_sign_post_installation}
          existingSchedulePostValue={existingScheduleSignPostInstallation}
          formName="TC_SIGN_POST_DETAILS_FORM"
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const SignPostageForm = reduxForm({
  form: "TC_SIGN_POST_DETAILS_FORM",
  destroyOnUnmount: false,
})(TransactionContainer(withRouter(SignPostageFormUI)))

export default SignPostageFormComponent
