import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getCreateEditModalState,
  getIsFetchingTopicsFromStore,
  getTopicsFromStore,
  getIsFetchingTagsFromStore,
  getSnippetPrevFromStore,
  getPostInRadiusFeed,
  dataToPostInRadiusFeed,
} from "container/Network/selector"
import {
  updateCreateEditPostModal,
  getAllTopics,
  uploadPostImage,
  setTopicAndGetTags,
  createUpdateFeedItem,
  getSnippetData,
  confirmCloseCreateEditModal,
  closeResetCreateEditModal,
  createEditTextChange,
  removeMediaAndLinkify,
  removeSnippetFromPreview,
  togglePostInRadiusFeed,
  removeLinkFromEditPost,
} from "./actionCreators"

const mapStateToProps = state => ({
  ...getCreateEditModalState(state),
  topics: getTopicsFromStore(state),
  isFetchingTopics: getIsFetchingTopicsFromStore(state),
  createEditPostText: state.formInput.createEditPostText,
  isFetchingSnippet: state.network.isFetchingSnippet,
  snippetPrev: getSnippetPrevFromStore(state),
  isFetchingTags: getIsFetchingTagsFromStore(state),
  isShowPostInRadiusFeed: getPostInRadiusFeed(state),
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateCreateEditPostModal,
    getAllTopics,
    uploadPostImage,
    setTopicAndGetTags,
    createUpdateFeedItem,
    getSnippetData,
    confirmCloseCreateEditModal,
    closeResetCreateEditModal,
    createEditTextChange,
    removeMediaAndLinkify,
    togglePostInRadiusFeed,
    removeLinkFromEditPost,
  }, dispatch)
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
