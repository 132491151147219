import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import root from "window-or-global"
import { get } from "lodash"
import colors from "@colors"
import queryString from "query-string"
import { getOS } from "services/Utils"
import { authCheck } from "services/auth"
import appContainer from "container/App"
import interestContainer from "container/App/applyFlowContainer"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import { withRouter } from "react-router"
import QRCodeV2 from "dumbComponents/HomePageV2/Components/QRCodeV2/index"
import { BlueButton } from "dumbComponents/HomePageV2/Components/commonStyles"
import {
  mobile,
  xsMobile,
} from "@consts"
import Form from "./Form"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const ANNUAL = ["Join a high-performing team", "Create a solo brand"]
const GROUP = ["Start my own team / brokerage", "Up-level my existing team / brokerage"]

const DesktopWrap = styled.div`
  display: block;
  
  @media(max-width: 1099px) {
    display: none;
  }
`

const MobileWrap = styled.div`
  display: none;
  
  @media(max-width: 1099px) {
    display: block;
  }
`

const ImageWrap = styled.div`
  height: 225px;
  background: #303030 url(${props => props.src});
  background-size: cover;
  background-position-y: 0px;
  background-repeat: no-repeat;
  transform: rotate(0deg);
`

const PaddingWrap = styled.div`
  ${props => props.isWatchRoom && `
      padding: 40px;
      background: #F9F9F7;
  `}
`

const Wrap = styled.div`
  font-family: ${ATC} !important;

  > p {
    font-size: 18px;
    line-height: 24px;
    font-family: ${ATC};
    font-weight: 300;
    color: ${colors.black};
    ${mobile(`
      font-size: 16px;
    `)}
  }
`

const SubText = styled.p`
  ${props => props.watchPage && `
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 19px;

    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  `}

  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #303030;
  margin-top: 10px;
`

const Header = styled.h1` 
  color: #1C1C1F;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.9%;

  span {
    color: #5A5FF2;
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.9%; /* 25.104px */
  }
`

const PostSubmitTitle = styled.h2`
  color: #5A5FF2;
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 156.9%; /* 25.104px */
`

const Message = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.9%; /* 20.397px */
  margin-top:4px;
  margin-bottom: 19px;
`

class InterestForm extends React.PureComponent {
  state = { isJobPage: false, modalWidth: "500px", subText: "Tell us a little about yourself and one of our team members will get back to you shortly." }

  componentDidMount() {
    // const {  } = this.props
    const currentPathName = this.getCurrentPath()
    if (currentPathName === "jobs") {
      this.setState({
        isJobPage: true,
        modalWidth: "650px",
        subText: "Finish creating your account for exclusive access to the Radius community and more.",
      })
    } else if (currentPathName === "watch") {
      //The full video is available to Radius Community members only. Membership is free, and grants you access to thousands of hours of professional development material as well as the world’s only real estate social network.
      this.setState({
        isWatchPage: true,
        isJobPage: false,
        modalWidth: "650px",
        subText: `The full video is available to Radius Community members only. 
        Membership is free, and grants you access to thousands of hours of 
        professional development material as well as the world’s only real estate social network.`,
      })
    }
  }

  componentDidUpdate(newProps, prevProps) {
    const {
      history,
    } = newProps || {}
    if (history.location.pathname === "/rooms" && prevProps.isWatchPage) {
      this.setState({
        isWatchPage: false,
        subText: "Finish creating your account for exclusive access to the Radius community and more.",
      })
    }
  }

  returnMappedPage = (path) => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const { interestSource } = parsedQuery || {}

    switch (path) {
      case "for-agents":
        return { source: "Agents Page" }
      case "marketing/for-agents":
        return { source: "Marketing For Agents Page" }
      case "marketing/for-teams":
        return { source: "Marketing For Teams Page" }
      case "teams":
        return { source: "Teams Page" }
      case "pricing":
        return { source: "Pricing Page" }
      case "agents":
        return { source: "Our Agents Page" }
      case "radius-financing":
        return { source: "Financing Page" }
      case "community":
        return { source: interestSource || "Logged-in Community Page" }
      case "jobs":
        return { source: "Team Jobs", header: "Thank you for applying!" }
      case "referral-invite":
        return { source: "Recruit and Earn", header: "Join Radius" }
      case "rooms":
        return { source: "Rooms Landing Page", header: "Join Radius" }
      case "watch":
        return { source: "Rooms Pre Recorded Content", header: "Enjoying the Rooms recording?" }
      default:
        return { source: interestSource || "Miscellaneous" }
    }
  }

  getCurrentSource = () => {
    const {
      history: {
        location,
      },
    } = this.props
    const {
      pathname,
    } = location

    const {
      isWatchPage,
    } = this.state

    if (isWatchPage) {
      return this.returnMappedPage("watch").source
    }

    const splitPath = pathname.split("/")
    let currentPathName = splitPath.length > 2 ? splitPath[splitPath.length - 1] : splitPath[1]
    /*TODO - Hacky Solution requires change below*/
    if (splitPath.includes("marketing")) {
      currentPathName = `marketing/${currentPathName}`
    }
    /*TODO - Hacky Solution requires change above*/
    return currentPathName ? this.returnMappedPage(currentPathName).source : "Home Page"
  }

  getCurrentPath = () => {
    const {
      history: {
        location,
      },
    } = this.props
    const {
      pathname,
    } = location

    if (pathname.includes("watch")) {
      return "watch"
    }

    const splitPath = pathname.split("/")
    return splitPath.length > 2 ? splitPath[splitPath.length - 1] : splitPath[1]
  }

  getHeading = () => {
    const {
      applyModalData,
    } = this.props || {}

    const currentPathName = this.getCurrentPath()
    const mappedPathValues = this.returnMappedPage(currentPathName)
    return currentPathName && mappedPathValues.header ? mappedPathValues.header : applyModalData.headerText || "Nice to meet you!"
  }

  handleOnSubmit = (values) => {
    const { submitInterestForm, applyModalData, vwoUserId, vwoClient, signUpFormValues } = this.props

    const {
      account_type: productionType,
    } = signUpFormValues || {}

    // const nameArray = values.firstname.split(" ")
    // const firstname = nameArray.shift()
    // const lastname = nameArray.join(" ")
    const payload = {
      ...values,
      lastname: values.lastname,
      firstname: values.firstname,
      state: values.state,
      /*deprecated key number_of_transactions_last_year */
      number_of_transactions_last_year: 0,
      account_type: values.account_type,
      source: this.getCurrentSource(),
      agency_name: "",
    }
    if (values.account_type.value === "Other") {
      payload.is_other_account_type = true
    }

    if (productionType && ANNUAL.includes(productionType)) {
      delete payload.group_annual_production
      payload.annual_production = values.annual_production
    }

    if (productionType && GROUP.includes(productionType)) {
      delete payload.annual_production
      payload.group_annual_production = values.group_annual_production
    }

    const alt = applyModalData.className && applyModalData.className.indexOf("blue") > -1 ? "blue_variant_log_in_form" : "white_variant_log_in_form"
    const mod = applyModalData.className && applyModalData.className.replace("form", "") || ""
    const logInModalClassName = mod ? `${mod}log_in_form` : alt

    console.log("payload", payload)

    submitInterestForm({
      payload,
      isLogged: authCheck(),
      logInModalClassName,
      vwoUserId,
      vwoClient,
      webClassName: applyModalData.className,
    })
  }

  render() {
    const {
      applyModalData,
      isShowApplyModal,
      toggleApplyModal,
      applyInterestsResponse,
      showModal = true,
      formHeader,
      user,
      history,
      signUpFormValues,
      appDownloadActionResponse,
      isMQLFormSubmitted,
    } = this.props
    const { isFetching: submittingAppForm, data: appData } = appDownloadActionResponse || {}
    const { isFetching, data: formData } = applyInterestsResponse || {}
    const { isJobPage, modalWidth, subText, isWatchPage } = this.state
    const isLogged = authCheck()
    //const fakeId = uuid().split("-")[0]

    const isCurrentUserOniOS = getOS() === "ios" || getOS() === "android"

    let MODAL_STYLES = {
      background: `${colors.white}`,
      borderRadius: "9px",
    }

    let ICON_STYLES = {}

    if (isWatchPage) {
      MODAL_STYLES = {
        ...MODAL_STYLES,
        padding: "0px",
        // width: "657px",
      }

      ICON_STYLES = {
        ...ICON_STYLES,
        filter: "invert(1) contrast(100) brightness(100)",
      }
    }

    const {
      subHeaderText,
    } = applyModalData || {}

    return (
      <React.Fragment>
        {showModal ? (
          <Modal
            toClose={
              () => {
                const search = get(root, "location.search", {})
                const parsedQuery = queryString.parse(search)
                const { tag } = parsedQuery
                if (tag) {
                  history.push("/")
                } else {
                  toggleApplyModal({
                    bool: false,
                    accountType: null,
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }
              }
            }
            width={modalWidth}
            show={applyModalData.bool}
            modalVersion={3}
            closeIcon={CloseIcon}
            overlayStyles={{
              alignItems: "flex-start",
              borderRadius: "9px",
            }}
            iconStyles={{
              ...ICON_STYLES,
            }}
            dialogStyles={{
              borderRadius: "8px",
            }}
            modalStyles={{
              ...MODAL_STYLES,
            }}
          >
            <Wrap
              className={applyModalData.className}
            >
              {!isMQLFormSubmitted ? (
                <>
                  {isWatchPage && (
                    <ImageWrap src={`${CLOUDFRONT}/woman_smartphone.png`} />
                  )}
                  <PaddingWrap
                    isWatchRoom={isWatchPage}
                  >
                    <Header
                      watchPage={isWatchPage}
                    >
                      <span>With one click,</span>
                      {" "}
                      you’ve taken a first step towards building your real estate legacy.
                    </Header>
                    {subHeaderText && (
                      <SubText
                        watchPage={isWatchPage}
                      >
                        {subHeaderText}
                      </SubText>
                    )}
                    <Form
                      isJobPage={isJobPage}
                      onSubmit={this.handleOnSubmit}
                      isSubmitting={isFetching}
                      user={isLogged ? user : null}
                      isWatchPage={isWatchPage}
                      accountType={applyModalData.accountType}
                      webClassName={applyModalData.className}
                      signUpFormValues={signUpFormValues}
                      isLandingPage
                    />
                  </PaddingWrap>
                </>
              ) : (
                <>
                  <PostSubmitTitle>
                    It&apos;s great to meet you!
                  </PostSubmitTitle>
                  <Message>
                    {`A member of the Radius recruiting team will be in touch shortly to 
    learn more about your brand. In the meantime, download the Radius app 
    and join the world's largest community of real estate professionals!`}
                  </Message>
                  <DesktopWrap>
                    <QRCodeV2
                      inForm
                      isSubmitting={submittingAppForm}
                      appDownloadSMSData={appData}
                      className="form_mobile_app_link_request_web"
                    />
                  </DesktopWrap>
                  <MobileWrap>
                    <BlueButton
                      onClick={() => {
                        const url = isCurrentUserOniOS ? "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8" : "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
                        root.open(url, "_blank")
                      }}
                    >
                      Join Our Community
                    </BlueButton>
                  </MobileWrap>
                </>
              )}
            </Wrap>
          </Modal>
        ) : (
          <Wrap
            mb="20px"
            className={applyModalData.className}
          >
            <h1>{formHeader}</h1>
            <Form
              isJobPage={isJobPage}
              onSubmit={this.handleOnSubmit}
              isSubmitting={isFetching}
              webClassName={applyModalData.className}
              signUpFormValues={signUpFormValues}
              isLandingPage
            />
          </Wrap>
        )}
      </React.Fragment>
    )
  }
}

export default appContainer(withRouter(interestContainer(InterestForm)))
