/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import LoaderButton from "@ui/LoaderButton"
import TechAssetsContainer from "container/TechAssets"
import GetInTouchModal from "dumbComponents/FinancingDashboard/components/GetInTouchModal"

const Container = styled.div`
`

const Wrap = styled.div`
  text-align: left;
  padding: 30px 30px 30px 30px;
`
const HeaderContainer = styled.div`
  h1 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #303030;
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: #303030;
    margin-bottom: 27px;
  }
`
const RatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
`
const RatesBox = styled.div`
  border: 1px solid #C7C7C7;
  padding: 20px;
  margin: 4px;
  min-width: 180px;
`
const RateHeader = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #303030;
  border-bottom: 1px solid #C7C7C7;
  padding-bottom: 15px;
  margin: 0 -20px;
`
const RateDescription = styled.div`
  padding-top: 15px;
`
const RateText = styled.p`
  font-family: ${ATC}
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #EE8B31;
`
const APRText = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #303030;
`
const TimeText = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #303030;
`
const NotNow = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  text-align: center;
  cursor: pointer;
`

const ButtonWrap = styled.div`
  margin: 35px 0 40px 0;
  text-align: center;
`

const dummyValues = [{
  rateHeader: "VA",
  rateText: "4.125% rate",
  aprText: "4.361% apr",
  timeText: "30-year fixed",
}, {
  rateHeader: "3.5% Down",
  rateText: "4.125% rate",
  aprText: "4.361% apr",
  timeText: "30-year fixed",
}, {
  rateHeader: "10% Down",
  rateText: "4.125% rate",
  aprText: "4.361% apr",
  timeText: "30-year fixed",
}, {
  rateHeader: "20% Down",
  rateText: "4.125% rate",
  aprText: "4.361% apr",
  timeText: "Conventional 30-year fixed ",
}]
class MortgagePopup extends React.Component {
  state = {
    showGetInTouch: false,
  }

  componentDidMount() {

  }

  showGetInTouchModal = (show) => {
    this.setState({
      showGetInTouch: show,
    })
  }

  render() {
    const { showPopup, popupDetails, history } = this.props
    const { showGetInTouch } = this.state
    const { data: popupDetailsData } = popupDetails
    console.log("===this.props==MortgagePopup==", this.props)
    return (
      <Container>
        {showGetInTouch ? (<GetInTouchModal showGetInTouchModal={this.showGetInTouchModal} />) : (
          <Modal
            show
            toClose={() => { showPopup({ showMortgagePopup: false }) }}
            modalVersion={3}
            closeIcon={CloseIcon}
            width="850px"
            modalStyles={{
              marginTop: 0,
              padding: 0,
              boxShadow: "none",
            }}
            customDialogColor={colors.black}
            zIndex={1}
            dialogStyles={{
              background: "#F9F9F7",
              borderRadius: "0px",
              minHeight: "600px",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "28px",
              lineHeight: "32px",
              textAlign: "center",
              color: colors.black,
            }}
            iconStyles={{
              padding: "8px",
              borderRadius: "18px",
              fontSize: "10px",
              right: "20px",
              width: "40px",
              margin: "6px",
            }}
          >
            <Wrap>
              <HeaderContainer>
                <h1>Get your clients the best rates!</h1>
                <p>Loandesk Mortgage offers low rates, fast closes and exceptional service.</p>
              </HeaderContainer>
              {showGetInTouch && (<GetInTouchModal showGetInTouchModal={this.showGetInTouchModal} />)}
              <RatesContainer>
                {popupDetailsData && popupDetailsData.map(values => (
                  <RatesBox>
                    <RateHeader>
                      {values.title}
                    </RateHeader>
                    <RateDescription>
                      <RateText>
                        {values.rate}
                        %
                      </RateText>
                      <APRText>
                        {values.apr}
                        %
                      </APRText>
                      <TimeText>
                        {values.time_period}
                      </TimeText>
                    </RateDescription>
                  </RatesBox>
                ))}

              </RatesContainer>
              <ButtonWrap>
                <LoaderButton
                  bsStyle="marineBLueFill"
                  padding="16px"
                  width="95%"
                  borderRadius="50px"
                  loaderOption={{
                    color: "#3B60E4",
                  }}
                  onClick={() => { this.showGetInTouchModal(true) }}
                >
                  Book a Meeting
                </LoaderButton>
                <LoaderButton
                  bsStyle="marineBLueOutlined"
                  padding="16px"
                  width="95%"
                  margin="16px 0 0 0"
                  borderRadius="50px"
                  loaderOption={{
                    color: "#3B60E4",
                  }}
                  onClick={() => {
                    history.push("/realty/mortgage")
                    showPopup({ showMortgagePopup: false })
                  }}
                >
                  Learn More
                </LoaderButton>
              </ButtonWrap>
              <NotNow onClick={() => showPopup({ showMortgagePopup: false })}>
                Not now, thanks
              </NotNow>
            </Wrap>
          </Modal>
        )}
      </Container>
    )
  }
}

export default withRouter(TechAssetsContainer(MortgagePopup))
