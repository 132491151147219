import {
  takeLatest,
  call,
  put,
} from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import { getUserProfileAPI } from "container/Profile/api"
import { getPropertyDetailsAPI } from "./api"
import * as PropertyActions from "./actions"

function* handleGetProperty(action) {
  try {
    const { propertyId } = action.data
    const res = yield call(getPropertyDetailsAPI, propertyId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(PropertyActions.getPropertyDetaillsAction.success(response))
      const { agent_id } = response
      yield put(PropertyActions.getAgentPublicProfileAction.request({
        agentId: agent_id,
      }))
    }
  } catch (error) {
    yield put(PropertyActions.getPropertyDetaillsAction.failure(error))
  }
}

function* handleGetAgentPublicProfile(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getUserProfileAPI, `?agentId=${agentId}`)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(PropertyActions.getAgentPublicProfileAction.success(response))
    }
  } catch (error) {
    yield put(PropertyActions.getAgentPublicProfileAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(PropertyActions.getPropertyDetaillsAction.REQUEST, handleGetProperty)
  yield takeLatest(PropertyActions.getAgentPublicProfileAction.REQUEST, handleGetAgentPublicProfile)
}

