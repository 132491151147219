import React from "react"
import styled from "styled-components"
import applyFlowContainer from "container/App/applyFlowContainer"
import RightSection from "./RightSection"
import LeftSection from "./LeftSection"
import Banner from "./Banner"

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const TopSection = styled.div`
  position: relative;
  top: 13px;
  z-index: 50;

  @media(max-width: 1099px) {
    position: relative;
    top: 28px;
    margin-bottom: 33px;
  }
`
const BottomSection = styled.div`
   display: flex;
  justify-content: space-between;
  // padding: 100px 25px;
  margin-bottom: 72px;
  min-height: 703px;
  align-items: center;
  padding-top: 72px;
  position: relative;
  z-index: 30;
  // overflow: hidden;


  @media(min-width: 1100px) {
    min-height: unset;
  }

  @media(max-width: 1099px) {
    padding-top: 40px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const HeroSection = ({
  width,
  isCurrentUserOniOS,
  heroMQLFormData,
  toggleLandingPageMQLFormToggle,
}) => (
  <>
    <Wrap className="container">
      <TopSection>
        <Banner />
      </TopSection>
      <BottomSection>
        <LeftSection
          setFormVisible={toggleLandingPageMQLFormToggle}
          width={width}
          isCurrentUserOniOS={isCurrentUserOniOS}
        />
        <RightSection
          formVisible={heroMQLFormData.bool}
          setFormVisible={toggleLandingPageMQLFormToggle}
          className={heroMQLFormData.className}
        />
      </BottomSection>
    </Wrap>
    {/* <TickerTape /> */}
  </>
)

export default applyFlowContainer(HeroSection)

