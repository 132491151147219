import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import { isEmpty } from "lodash"
import * as MarketplaceActions from "./actions"

const initState = {
  isOpenPreferenceModal: false,
  isDownloadAppModalOpen: false,
  isSendingSMS: false,
  selectedCity: null,
  isAddingCity: false,
  isSavingPreference: false,
  isAskingForPermissionModal: false,
  // when active takes `Id`
  isClaimingId: false,
  isReadyToCall: false,
  isOnCall: false,
  neighbourhoodStore: [],
  callingState: "Dailing",
  isNeighbourhoodBusy: false,
  isShowClaimBlockerModal: false,
  isShowLeadDetailModal: false,
  // This is used to react-slick.
  activeClaimIndex: 0,
  isOpeningMailUI: false,
  isLeadDeletionModalVisible: false,
  currentNewLeadsCount: 0,
  ...createDeltaReducer("leads"),
  ...createDeltaReducer("userPreference"),
  ...createDeltaReducer("recentlyClaimed"),
  ...createDeltaReducer("agentFromNumber"),
  ...createDeltaReducer("claimBlockerResponse"),
  ...createDeltaReducer("saveNoteResponse"),
  ...createDeltaReducer("lmpViewedResponse"),
  ...createDeltaReducer("deleteLMPLeadResponse"),
  ...createDeltaReducer("leadsViewedResponse"),
  ...createDeltaReducer("postCallFeedbackOptionsResponse"),
}

export default function marketplaceReducer(state = initState, action) {
  switch (action.type) {
    case MarketplaceActions.togglePreferenceModalAction.type: {
      return {
        ...state,
        isOpenPreferenceModal: action.data,
      }
    }

    case MarketplaceActions.toggleClaimBlockerModalAction.type: {
      return {
        ...state,
        isShowClaimBlockerModal: action.data,
      }
    }

    case MarketplaceActions.toggleLeadDetailsViewAction.type: {
      const {
        isShowLeadDetailModal,
        currentLead,
      } = action.data
      return {
        ...state,
        isShowLeadDetailModal,
        currentLead,
      }
    }

    case MarketplaceActions.updateCountDownTimerAction.type: {
      return {
        ...state,
        callingCountDown: action.data,
      }
    }

    case MarketplaceActions.changeActiveBlockClaim.type: {
      return {
        ...state,
        activeClaimIndex: action.data,
      }
    }

    case MarketplaceActions.getPostCallFeedbackOptionsAction.REQUEST:
    case MarketplaceActions.getPostCallFeedbackOptionsAction.SUCCESS:
    case MarketplaceActions.getPostCallFeedbackOptionsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action, MarketplaceActions.getPostCallFeedbackOptionsAction, "postCallFeedbackOptionsResponse"),
      }
    }

    case MarketplaceActions.deleteMarketplaceLeadAction.REQUEST:
    case MarketplaceActions.deleteMarketplaceLeadAction.SUCCESS:
    case MarketplaceActions.deleteMarketplaceLeadAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.deleteMarketplaceLeadAction, "deleteLMPLeadResponse"),
      }
    }

    case MarketplaceActions.getLeadForMarketplaceAction.REQUEST:
    case MarketplaceActions.getLeadForMarketplaceAction.SUCCESS:
    case MarketplaceActions.getLeadForMarketplaceAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.getLeadForMarketplaceAction, "leads"),
        isReadyToCall: false,
      }
    }

    case MarketplaceActions.leadViewedAction.REQUEST:
    case MarketplaceActions.leadViewedAction.SUCCESS:
    case MarketplaceActions.leadViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.leadViewedAction, "leadsViewedResponse"),
        isReadyToCall: false,
      }
    }

    case MarketplaceActions.fetchRecentlyClaimedLeadsAction.REQUEST:
    case MarketplaceActions.fetchRecentlyClaimedLeadsAction.SUCCESS:
    case MarketplaceActions.fetchRecentlyClaimedLeadsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.fetchRecentlyClaimedLeadsAction, "recentlyClaimed"),
      }
    }

    case MarketplaceActions.lmpViewedAction.REQUEST:
    case MarketplaceActions.lmpViewedAction.SUCCESS:
    case MarketplaceActions.lmpViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.lmpViewedAction, "lmpViewedResponse"),
      }
    }

    case MarketplaceActions.saveReferralNoteAction.REQUEST:
    case MarketplaceActions.saveReferralNoteAction.SUCCESS:
    case MarketplaceActions.saveReferralNoteAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.saveReferralNoteAction, "saveNoteResponse"),
      }
    }

    case MarketplaceActions.fetchUserPreferranceAction.REQUEST:
    case MarketplaceActions.fetchUserPreferranceAction.SUCCESS:
    case MarketplaceActions.fetchUserPreferranceAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.fetchUserPreferranceAction, "userPreference"),
      }
    }

    case MarketplaceActions.getClaimBlockerAction.REQUEST:
    case MarketplaceActions.getClaimBlockerAction.SUCCESS:
    case MarketplaceActions.getClaimBlockerAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.getClaimBlockerAction, "claimBlockerResponse"),
      }
    }

    case MarketplaceActions.getAgentNumberForCallingAction.REQUEST:
    case MarketplaceActions.getAgentNumberForCallingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.getAgentNumberForCallingAction, "agentFromNumber"),
      }
    }

    case MarketplaceActions.getAgentNumberForCallingAction.SUCCESS: {
      if (isEmpty(action.data)) {
        action.data.fromPhoneNumber = "+14156568986"
      }
      return {
        ...state,
        ...handleDeltaReducer(state, action, MarketplaceActions.getAgentNumberForCallingAction, "agentFromNumber"),
      }
    }

    case MarketplaceActions.claimUnverifiedLeadAction.REQUEST: {
      const { payload } = action.data
      return {
        ...state,
        isClaimingId: payload.referral_id,
      }
    }

    case MarketplaceActions.claimUnverifiedLeadAction.SUCCESS:
    case MarketplaceActions.claimUnverifiedLeadAction.FAILURE: {
      const { isClaimingId } = state
      return {
        ...state,
        isClaimingId: false,
        isReadyToCall: isClaimingId,
      }
    }

    // NOT API Actions
    case MarketplaceActions.selectCityInPreferenceAction.type: {
      return {
        ...state,
        selectedCity: action.data,
      }
    }

    case MarketplaceActions.sendDownloadLinkToPhoneAction.REQUEST: {
      return {
        ...state,
        isSendingSMS: true,
      }
    }
    case MarketplaceActions.sendDownloadLinkToPhoneAction.FAILURE:
    case MarketplaceActions.sendDownloadLinkToPhoneAction.SUCCESS: {
      return {
        ...state,
        isSendingSMS: false,
        isDownloadAppModalOpen: false,
      }
    }

    case MarketplaceActions.toggleDownloadAppModalAction.type: {
      return {
        ...state,
        isDownloadAppModalOpen: action.data,
      }
    }

    case MarketplaceActions.toggleLoaderAction.type: {
      const { key, value } = action.data
      return {
        ...state,
        [key]: value,
      }
    }

    case MarketplaceActions.toggleMicrophonePermissionModalAction.type: {
      return {
        ...state,
        isAskingForPermissionModal: action.data || false,
      }
    }

    case MarketplaceActions.toggleCallingBlockAction.type: {
      return {
        ...state,
        isOnCall: action.data,
      }
    }

    // Twilio Events
    case MarketplaceActions.connectCallAction.type: {
      return {
        ...state,
        callingState: "connecting",
      }
    }
    case MarketplaceActions.ringingCallAction.type: {
      return {
        ...state,
        callingState: "ringing",
      }
    }
    case MarketplaceActions.connectedCallAction.type: {
      return {
        ...state,
        callingState: "connected",
      }
    }

    case MarketplaceActions.updateCallStatusAction.type: {
      const { status } = action.data
      return {
        ...state,
        callingState: status,
      }
    }

    case MarketplaceActions.handleDispatchedNeighbourhoodAction.type: {
      const { data } = action
      return {
        ...state,
        neighbourhoodStore: data,
      }
    }

    case MarketplaceActions.handleCityRemovalAction.type: {
      return {
        ...state,
        selectedCity: null,
        neighbourhoodStore: [],
      }
    }

    case MarketplaceActions.creatingMailForVerifiedLeadAction.type: {
      return {
        ...state,
        isOpeningMailUI: action.data,
      }
    }

    case MarketplaceActions.setCurrentNewLeadsAvailableAction.type: {
      return {
        ...state,
        currentNewLeadsCount: action.data,
      }
    }

    case MarketplaceActions.toggleLeadDeletionPromptModalAction.type: {
      const {
        idToBeDeleted,
        isLeadDeletionModalVisible,
      } = action.data

      return {
        ...state,
        isLeadDeletionModalVisible,
        idToBeDeleted,
      }
    }

    case MarketplaceActions.showRedirectionLoaderAction.type: {
      return {
        ...state,
        isShowRedirectionLoader: action.data,
      }
    }

    default:
      return state
  }
}
