import {
  createSignalAction,
} from "shared/reduxUtils"

const BASE = "RAR_MENTORING"

export const getAllRarMentorsList = createSignalAction(BASE, "RAR_MENTORS_LIST")
export const getRarMentorsFrom = createSignalAction(BASE, "RAR_MENTORS_FORM")
export const saveRarMentorsForm = createSignalAction(BASE, "SAVE_RAR_MENTORS_FORM")

