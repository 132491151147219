import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import * as ReferralActions from "container/Referral/actions"
import * as ProfileActions from "container/Profile/action"
import * as AppActions from "container/App/actions"
import * as AppSelectors from "container/App/selector"
import * as ClientActions from "container/Client/actions"
import {
  getIsUserABroker,
} from "container/App/selector"
import * as MarketplaceActions from "./actions"

const mapStateToProps = (state) => {
  const {
    marketplace,
    referral,
    profile,
    client,
    app,
  } = state
  const { isOpenCallFeedback, submitCallStatus, isReferralCardBusy } = client
  const { isShowSignAgreementModal } = app
  const formSelector = formValueSelector("POST_CALL_FEEDBACK_FORM")
  const claimBlockerFormSelector = formValueSelector("CLAIM_BLOCKER_NOTES_FORM")
  const claimBlockerActiveClient = claimBlockerFormSelector(state, "clientId")
  const claimBlockerActiveNote = claimBlockerFormSelector(state, "note")
  const postCallStatus = formSelector(state, "call_status")
  const postCallReason = formSelector(state, "reason")
  const isUserABroker = getIsUserABroker(state)
  const {
    isFetchingProspects,
    prospects,
    showProspectsModal,
    showExpiredModal,
    lastAcceptedReferral,
    leadError,
    isFetchingReferrals,
    inboundReferrals,
    singleProspect,
    filterSkeleton: {
      statusChangeReasonMap,
    },
    isShowArchiveModal,
    forceShowProspectsModal,
  } = referral
  const {
    allReferrals,
  } = inboundReferrals
  const claimedByYou = {
    isFetching: isFetchingReferrals,
    data: {
      allReferrals,
    },
  }
  const {
    properties,
    neighbourhoods,
    isFetchingNeighbourhood,
    isFetchingMoreNeighbourhood,
    isRemovingCityServedNeighbourhood,
  } = profile
  return {
    ...marketplace,
    isSubscribedToAnyISAPlan: AppSelectors.getIsSubscribedToAnyISAPlan(state),
    isFetchingProspects,
    prospects,
    showProspectsModal,
    showExpiredModal,
    lastAcceptedReferral,
    properties,
    leadError,
    neighbourhoods,
    claimedByYou,
    isFetchingNeighbourhood,
    isRemovingCityServedNeighbourhood,
    isFetchingMoreNeighbourhood,
    isOpenCallFeedback,
    submitCallStatus,
    postCallStatus,
    singleProspect,
    isReferralCardBusy,
    statusChangeReasonMap,
    isShowArchiveModal,
    claimBlockerActiveClient,
    claimBlockerActiveNote,
    forceShowProspectsModal,
    postCallReason,
    isUserABroker,
    isShowSignAgreementModal,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      togglePreferenceModal: MarketplaceActions.togglePreferenceModalAction.call,
      getNeighbourhoodByCity: ProfileActions.getNeighbourhoodByCityAction.request,
      getLeadForMarketplace: MarketplaceActions.getLeadForMarketplaceAction.request,
      fetchProspects: ReferralActions.fetchProspectsAction.request,
      toggleLeadModal: ReferralActions.toggleLeadModalAction,
      updateProspectModal: ReferralActions.updateProspectModalAction,
      updateProspect: ReferralActions.updateProspectAction.request,
      fetchReferrals: ReferralActions.fetchReferralsAction.request,
      savePreference: MarketplaceActions.savePreferenceAction.request,
      fetchLead: ReferralActions.fetchLeadAction.request,
      closeLeadExpired: ReferralActions.closeLeadExpiredAction,
      fetchSignedAgreement: ReferralActions.fetchSignedAgreementAction.request,
      updateLeadReferralState: ReferralActions.updateLeadReferralStateAction.request,
      pushRedirect: AppActions.pushRedirectAction.call,
      toggleSignAgreementModal: AppActions.toggleSignAgreementModalAction.call,
      toggleDownloadAppModal: MarketplaceActions.toggleDownloadAppModalAction.call,
      sendDownloadLinkToPhone: MarketplaceActions.sendDownloadLinkToPhoneAction.request,
      fetchRecentlyClaimedLeads: MarketplaceActions.fetchRecentlyClaimedLeadsAction.request,
      fetchUserPreferrance: MarketplaceActions.fetchUserPreferranceAction.request,
      fetchCityNeighborhoodServed: ProfileActions.fetchCityNeighborhoodServedAction.request,
      addCity: ProfileActions.addCityAction.request,
      removeCity: ProfileActions.removeCityAction.request,
      removeCityServedNeighbourhood: ProfileActions.removeCityServedNeighbourhoodAction.request,
      saveCityServed: ProfileActions.saveCityServedAction.request,
      selectCityInPreference: MarketplaceActions.selectCityInPreferenceAction.call,
      toggleLoader: MarketplaceActions.toggleLoaderAction.call,
      claimUnverifiedLead: MarketplaceActions.claimUnverifiedLeadAction.request,
      connectCall: MarketplaceActions.connectCallAction.call,
      toggleCallingBlock: MarketplaceActions.toggleCallingBlockAction.call,
      toggleCallFeedbackModal: ClientActions.toggleCallFeedbackModalAction.call,
      submitCallFeedback: ClientActions.submitCallFeedbackAction.request,
      getAgentNumberForCalling: MarketplaceActions.getAgentNumberForCallingAction.request,
      fetchSingleProspect: ReferralActions.fetchSingleProspectAction.request,
      removeNeighbourhood: MarketplaceActions.removeNeighbourhoodAction.call,
      handleRemoveNeighbourhood: MarketplaceActions.handleRemovedNeighbourhoodAction.call,
      handleNeighbourhoodStore: MarketplaceActions.handleNeighbourhoodStoreAction.call,
      fetchLeadAction: ReferralActions.fetchLeadAction.request,
      getClaimBlocker: MarketplaceActions.getClaimBlockerAction.request,
      saveReferralNote: MarketplaceActions.saveReferralNoteAction.request,
      toggleClaimBlockerModal: MarketplaceActions.toggleClaimBlockerModalAction.call,
      toggleArchiveModal: ReferralActions.toggleArchiveModalAction,
      fetchFilterSkeleton: ReferralActions.fetchFilterSkeletonAction.request,
      archiveReferral: ClientActions.archiveReferralAction,
      lmpViewed: MarketplaceActions.lmpViewedAction.request,
      toggleLeadDetailsView: MarketplaceActions.toggleLeadDetailsViewAction.call,
      deleteMarketplaceLead: MarketplaceActions.deleteMarketplaceLeadAction.request,
      leadViewed: MarketplaceActions.leadViewedAction.request,
      setCurrentNewLeadCount: MarketplaceActions.setCurrentNewLeadsAvailableAction.call,
      toggleLeadDeletionModal: MarketplaceActions.toggleLeadDeletionPromptModalAction.call,
      getPostCallFeedbackOptions: MarketplaceActions.getPostCallFeedbackOptionsAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
