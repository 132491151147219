import * as React from "react";
const PurpleWarnIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17.8125V17.8125C5.8575 17.8125 2.5 14.455 2.5 10.3125V10.3125C2.5 6.17 5.8575 2.8125 10 2.8125V2.8125C14.1425 2.8125 17.5 6.17 17.5 10.3125V10.3125C17.5 14.455 14.1425 17.8125 10 17.8125Z"
      stroke="#5A5FF2"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99996 14.4792V10.3125H9.16663"
      stroke="#5A5FF2"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.79084 6.97915C9.67584 6.97915 9.5825 7.07248 9.58334 7.18748C9.58334 7.30248 9.67667 7.39581 9.79167 7.39581C9.90667 7.39581 10 7.30248 10 7.18748C10 7.07248 9.90667 6.97915 9.79084 6.97915"
      stroke="#5A5FF2"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PurpleWarnIcon;
