import React from "react"
import { Form } from "@ui/antd"
import {
  InputTextAreaWrap,
} from "./styles"

const InputArea = ({
  placeholder,
  type,
  name,
  required,
  style,
  customRules,
  inputType,
  showCount,
  maxLength,
  onChange,
}) => {
  const getRules = () => {
    switch (type) {
      case "email":
        return [{ required, type: "email" }]
      case "custom":
        return [{ ...customRules }]
      default:
        return [{ required }]
    }
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
    >
      <InputTextAreaWrap
        maxLength={maxLength}
        placeholder={placeholder}
        style={style}
        type={inputType}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default InputArea
