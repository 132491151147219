import root from "window-or-global"

export const supressPopups = (keyName = "supressPopups") => {
  if (sessionStorage) {
    sessionStorage.setItem(keyName, true)

    if (root) {
      root.addEventListener("beforeunload", () => {
        sessionStorage.removeItem(keyName)
      })
    }
  }
}

export const getArePopupsSupressed = (keyName = "supressPopups") => {
  if (sessionStorage) {
    const arePopupsSupressed = JSON.parse(sessionStorage.getItem(keyName))

    return arePopupsSupressed || false
  }

  return false
}

export const removeSupressedPopups = (keyName = "supressPopups") => {
  if (sessionStorage) {
    sessionStorage.removeItem(keyName)
  }
}

export const setItemInLocalStorage = (key, value) => {
  if (typeof key !== "string" || key.trim() === "") {
    console.error("Invalid key: Key must be a non-empty string.")
    return
  }

  if (value === undefined) {
    console.error("Invalid value: Cannot store `undefined` in localStorage.")
    return
  }

  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    console.error("Error saving to localStorage", error)
  }
}

// Get an item from localStorage with validation
export const getItemFromLocalStorage = (key) => {
  if (typeof key !== "string" || key.trim() === "") {
    console.error("Invalid key: Key must be a non-empty string.")
    return null
  }

  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  } catch (error) {
    console.error("Error reading from localStorage", error)
    return null
  }
}

// Remove an item from localStorage with validation
export const removeItemFromLocalStorage = (key) => {
  if (typeof key !== "string" || key.trim() === "") {
    console.error("Invalid key: Key must be a non-empty string.")
    return
  }

  try {
    if (localStorage.getItem(key) === null) {
      console.warn(`Warning: Key "${key}" does not exist in localStorage.`)
    } else {
      localStorage.removeItem(key)
    }
  } catch (error) {
    console.error("Error removing item from localStorage", error)
  }
}

// Clear all items in localStorage with validation
export const clearAllInLocalStorage = () => {
  try {
    if (localStorage.length === 0) {
      console.warn("Warning: localStorage is already empty.")
    } else {
      localStorage.clear()
    }
  } catch (error) {
    console.error("Error clearing localStorage", error)
  }
}
