import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import applyFlowContainer from "container/App/applyFlowContainer"
import { HeroText, SubText, BlueButton } from "../commonStyles"
import Timeline from "./Timeline"
import Video from "./Video"

const Wrap = styled.div`
  margin-top: 101px;
`

const TextWrap = styled.div`
  margin-bottom: 30px;
`

const MidSection = styled.div`
  margin-top: 65px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media(max-width: 1099px) {
    flex-direction: column;
    gap: 70px;
    justify-content: center;
    // align-items: center;
  }
`

const CTAWrap = styled.div`
  display: block;

  @media(max-width: 1099px) {
    display: none;
  }
`

const MobileCTAWrap = styled.div`
  display: none;

  @media(max-width: 1099px) {
    display: block;
    margin: 0 auto;
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RightSection = styled.div``

const MarinSection = ({
  toggleEWebinarModal,
}) => (
  <Wrap>
    <TextWrap>
      <HeroText
        spanStyles
      >
        Experience
        {" "}
        <span>Radius</span>
        {" "}
        Firsthand
      </HeroText>
    </TextWrap>
    <SubText>
      Got three minutes? Dig into the Radius ecosystem in this live overview:
    </SubText>
    <MidSection>
      <LeftSection>
        <Timeline />
        <CTAWrap>
          <BlueButton
            className="join_radius_overview_web"
            onClick={() => {
              //toggleEWebinarModal(true)
              root.open("https://www.radiusagent.com/brokerage-overview", "_blank")
            }}
          >
            Join the Full Radius Overview
          </BlueButton>
        </CTAWrap>
      </LeftSection>
      <RightSection>
        <Video />
      </RightSection>
      <MobileCTAWrap>
        <BlueButton
          className="join_radius_overview_web"
          onClick={() => {
            // toggleEWebinarModal(true)
            root.open("https://www.radiusagent.com/brokerage-overview", "_blank")
          }}
        >
          Join the Full Radius Overview
        </BlueButton>
      </MobileCTAWrap>
    </MidSection>
  </Wrap>
)

export default applyFlowContainer(MarinSection)
