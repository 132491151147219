import React from "react"
import styled from "styled-components"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import { ATC, INTER, MONTSERRAT } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/CrossVectorNew.png

const Wrap = styled.div`
  width: ${props => props.width ? props.width : "543px"};
  border-radius: 9px;
  background: #FFFFFF;
  opacity: 0;
  border-top: 4px solid #FFAB55;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  position: relative;


  animation: popUp 0.7s cubic-bezier(0,1.02,1,1.14) forwards;

  @keyframes popUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

    padding-top: 31px;
    padding-left: 32px;
    padding-right: 41px;
    padding-bottom: 5px;


  ${props => props.inForm && `
    width: 100%;
    background: #F0F1FF;
    padding: 18px;
  `}
`

const Text = styled.p`
  color: var(--color-primary, #5A5FF2);
  text-align: left;
  font-family: ${MONTSERRAT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.523%; /* 30.105px */
  text-transform: capitalize;


  ${props => props.inForm && `
    color: var(--color-primary, #5A5FF2);
    text-align: left;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 21.073px */
    text-transform: capitalize;
  `}
`

const AppStampWrap = styled.div`
  margin-right: 35px;
  margin-bottom: 30px;

  img {
    width: 100px;
  }
`

const SubText = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.48px;

  ${props => props.inForm && `
    color: #1C1C1F;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 153%; /* 18.36px */
  `}
`

const FormWrap = styled.div``

const FlexWrap = styled.div`
  display: flex;
`

const ScanToDownloadText = styled.p`
  color: #1C1C1F;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 153%; /* 21.42px */
  width: 272px;

  ${props => props.inForm && `
    font-size: 12px;
    width: 218px;
  `}

  span img {
    height: 13px;
    width: 13px;
  }
`

const LeftWrap = styled.div`
  width: 400px;
`

const RightWrap = styled.div``

const QRCodeWrap = styled.div`
  margin-top: 10px;
  img {
    width: 80px;
    height: 80px;
  }
`

const AppFormV2 = ({
  noBorder,
  appDownloadSMS,
  appDownloadActionResponse,
  inForm,
  appDownloadSMSData,
  isSubmitting,
  width,
  className,
}) => {
  const { isFetching, message } = appDownloadActionResponse || {}

  const handleOnSubmit = (value) => {
    const payload = {
      phoneNumber: value.phone,
      email: value.email,
    }
    appDownloadSMS({ payload })
  }

  return (
    <Wrap
      noBorder={noBorder}
      inForm={inForm}
      width={width}
    >
      {/* <Text
        inForm={inForm}
      >
        Join Our Community
      </Text>
      <SubText
        noBorder={noBorder}
        inForm={inForm}
      >
        Request a link to download the Radius app!
      </SubText>
      <AppStampWrap>
        <img src={`${CLOUDFRONT}/AppStamp.png`} alt="ios_droid_radius" />
      </AppStampWrap> */}

      <FlexWrap>
        <LeftWrap>
          <Text
            inForm={inForm}
          >
            Join Our Community
          </Text>
          <ScanToDownloadText
            inForm={inForm}
          >
            Scan to download the Radius Community App
          </ScanToDownloadText>
          <AppStampWrap>
            <img src={`${CLOUDFRONT}/AppStamp.png`} alt="ios_droid_radius" />
          </AppStampWrap>
        </LeftWrap>
        <RightWrap>
          <QRCodeWrap>
            <img src={`${CLOUDFRONT}/RADS_QR_BRIGHT.svg`} alt="qr_radius_png" />
          </QRCodeWrap>
        </RightWrap>
      </FlexWrap>
    </Wrap>
  )
}

export default ApplyFlowContainer(AppFormV2)
