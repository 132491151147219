import * as React from 'react'

const LeftArrow = props => {
  const { color = '#5A5FF2' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
      <path stroke={color} strokeLinecap="round" strokeWidth={1.5} d="M8 1 3 6l5 5" />
    </svg>
  )
}

export default LeftArrow
