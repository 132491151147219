import {
  createSignalAction,
  createActionCreator,
  createSimpleCreator,
  createBaseActionCreator,
} from "shared/reduxUtils"

const BASE = "AUTH"

export const loginUserAction = createSignalAction(BASE, "LOGIN_USER")
export const signUpUserAction = createSignalAction(BASE, "SIGN_UP_USER")
export const appleSignInAction = createSignalAction(BASE, "APPLE_SIGNIN")
export const saveSocialLoginDataAction = createSignalAction(BASE, "SAVE_SOCIAL_LOGIN")
export const saveProductInterestAction = createSignalAction(BASE, "SAVE_PRODUCT_INTEREST")
export const saveProductInterestStepsAction = createSignalAction(BASE, "SAVE_PRODUCT_INTEREST_STEPS")
