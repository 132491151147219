import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { findIndex } from "lodash"
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from "react-image-crop"
import colors from "@colors"
import { convertBlobToBase64 } from "services/Utils"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import Box from "@ui/Box"
import cropperStyles from "./reactCropperStyles"

import {
  Wrapper,
  Footer,
  Header,
  SaveButton,
  InputFile,
  DeleteButton,
  Separator,
  NoImageFound,
  BlankButton,
  ProfileCompletionButton,
} from "./commonStyles"

const centerAspectCrop = (
  mediaWidth,
  mediaHeight,
  aspect,
) => centerCrop(
  makeAspectCrop(
    {
      unit: "%",
      width: 100,
    },
    aspect,
    mediaWidth,
    mediaHeight,
  ),
  mediaWidth,
  mediaHeight,
)

const ImageWrap = styled.div`
  display: flex;
  background: #B2B2B2;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;

  /* .ReactCrop__image {
    max-height: fit-content !important;
  } */
`

const ErrorBox = styled.div`
  padding: 8px;
  background: ${colors.white};
  color: ${colors.redText};
  position: absolute;
  bottom: 10px;
  border-radius: 4px;
`

const StyledWrapper = styled(Wrapper)`
  ${cropperStyles}
`

class ProfileImageUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingImage: true,
      crop: {
        aspect: 1,
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      },
    }
  }

  imageRef = React.createRef()

  componentDidMount() {
    const { initialValues: { path } } = this.props
    if (path) {
      fetch(path)
        .then(res => res.blob("image/jpeg"))
        .then(convertBlobToBase64)
        .then((blob) => {
          this.setState({
            src: blob,
            isLoadingImage: false,
          })
        })
        .catch((err) => {
          this.setState({
            error: err,
            isLoadingImage: false,
          })
        })
    } else {
      this.setState({
        isLoadingImage: false,
      })
    }
  }

  checkImageSize = (size) => {
    // const sizeInMB = (size / 1024) / 1024
    // console.log(`Image Size: ${sizeInMB}MB`)
    // if (sizeInMB > 4) {
    //   this.setState({
    //     errorMessage: "Please upload image less than 4MB",
    //   })
    //   return false
    // }
    this.setState({
      errorMessage: null,
    })
    return true
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0 && this.checkImageSize(e.target.files[0].size)) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        this.setState({
          src: reader.result,
        })
      })
      reader.readAsDataURL(e.target.files[0])
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image
    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state
    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...centerAspectCrop(crop.height, crop.width, crop.aspect) },
      })
    } else {
      this.makeClientCrop(crop, pixelCrop)
    }
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop)
  };

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const { croppedImageUrl, croppedImageFile } = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg",
      )
      this.setState({ croppedImageUrl, croppedImageFile })
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const TO_RADIANS = Math.PI / 180
    const currentImage = this.imageRef.current
    const rotate = 0

    if (!ctx) {
      throw new Error("No 2d context")
    }

    const scaleX = currentImage ? currentImage.naturalWidth / currentImage.width : 1
    const scaleY = currentImage ? currentImage.naturalHeight / currentImage.height : 1

    canvas.width = Math.floor(pixelCrop.width * scaleX)
    canvas.height = Math.floor(pixelCrop.height * scaleY)

    ctx.imageSmoothingQuality = "low"

    const cropX = pixelCrop.x * scaleX
    const cropY = pixelCrop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    // ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)

    ctx.drawImage(
      currentImage,
      0,
      0,
      currentImage ? currentImage.naturalWidth : 1,
      currentImage ? currentImage.naturalHeight : 1,
      0,
      0,
      currentImage.naturalWidth,
      currentImage.naturalHeight,
    )

    return new Promise((resolve) => {
      canvas.toBlob((rawBlob) => {
        const blob = rawBlob
        blob.name = fileName
        root.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = root.URL.createObjectURL(blob)
        console.log({
          file111: this.fileUrl,
          blob,
        });
        resolve({
          croppedImageUrl: this.fileUrl,
          croppedImageFile: blob,
        })
      }, "image/jpeg")
    })
  }

  handleSave = () => {
    const { uploadProfileImage, agentId } = this.props
    const { croppedImageFile } = this.state
    uploadProfileImage({
      agentId,
      file: croppedImageFile,
      type: "profile",
    })
  }

  removeImage = () => {
    const {
      saveProfileUpdate,
      agentId,
      images,
      removeImageFromCookie,
      closeEditProfileModal,
    } = this.props
    const imageIndex = findIndex(images, item => item.type === "profile")
    images[imageIndex].path = ""
    const payload = {
      isCloseModal: false,
      id: agentId,
      images,
    }
    removeImageFromCookie(null)
    this.setState({
      src: null,
      crop: null,
      croppedImageFile: null,
    })
    saveProfileUpdate(payload)
    closeEditProfileModal()
  }

  render() {
    const {
      isSavingUpdates,
      initialValues: { path = null },
      isProfileCompletion,
      nextToNextStep,
      skipProfileCompletionStep,
      nextStep,
    } = this.props
    const { crop, src, isLoadingImage, errorMessage, croppedImageFile } = this.state
    return (
      <StyledWrapper>
        <Header>
          Edit Profile photo
        </Header>
        { isLoadingImage ? (
          <Box p="20px" h="20" d="flex" jc="center" ai="center">
            <ThreeDotLoader />
            <h6>Loading...</h6>
          </Box>
        ) : (
          <ImageWrap>
            {src ? (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                circularCrop
              >
                <img
                  ref={this.imageRef}
                  alt="Crop me"
                  src={src}
                  onLoad={this.onImageLoaded}
                />
              </ReactCrop>
            ) : (
              <NoImageFound />
            )}
            {errorMessage && (
              <ErrorBox>{errorMessage}</ErrorBox>
            )}
          </ImageWrap>
        ) }
        <Footer
          d="flex"
          style={{
            justifyContent: isProfileCompletion && nextToNextStep ? "space-between" : "flex-end",
            padding: 15,
          }}
        >
          {isProfileCompletion && nextToNextStep && (
            <BlankButton onClick={() => { skipProfileCompletionStep(nextStep) }} normal>
              Skip
            </BlankButton>
          )}
          {path && (
            <DeleteButton onClick={this.removeImage}>
              Delete Photo
            </DeleteButton>
          )}
          <Separator />
          <InputFile type="file" onChange={this.onSelectFile} htmlFor="profilePictureUpload">
            {path ? "Change Image" : "Upload Image"}
            <input type="file" id="profilePictureUpload" />
          </InputFile>

          {isProfileCompletion ? (
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          ) : (
            <SaveButton
              disabled={!croppedImageFile}
              width="max-content"
              isShowLoader={isSavingUpdates}
              onClick={this.handleSave}
            >
              {!croppedImageFile ? "Crop Image" : "Save"}
            </SaveButton>
          )}
        </Footer>
      </StyledWrapper>
    )
  }
}

export default ProfileImageUpload
