import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  position: relative;
`

export const GoogleLocationWrap = styled.div`
  width: 100%;
`
export const DropdownStyleWrap = styled.div`
  display: none;
  position: absolute;
  ${props => props.display && `
    display: block;
    background: #FDFDFD;
    border-radius: 8px;
    width: 100%;
    top: 60px;
    box-shadow: 4px 4px 8px 4px #00000040;
    z-index: 1000;
  `}
`

export const DropdownWrap = styled.div`
  padding: 8px 20px 8px 20px;
  border: 1px solid #F1F1FE;
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;

  ${props => props.isDropdown && `
    border-radius: 5px;
    border: 1px solid #8486C3;
    background-color: #F1F1FE;
  `}
`

export const ResultWrap = styled.div`
  padding: 16px 20px;
`

export const ResultTitle = styled.p`
  margin: 0;
  font-family: ${MONTSERRAT};
  font-weight: 500;
  font-size: 10px;
  color: #292A37;
`

export const MLSImage = styled.img`
  height: 45px;
  width: 53px;
`

export const MLSWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const MLSNumber = styled.p`
  margin: 0;
  font-family: ${INTER};
  font-weight: 400;
  font-size: 8px;
  color: #292A37;
`

export const MLSAddress = styled.p`
  margin: 0;
  font-family: ${INTER};
  font-weight: 400;
  font-size: ${props => props.fontSize || "12px"};
  color: #5A5FF2;
`
