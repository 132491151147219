/* eslint-disable max-len */
import React from "react"

const RequestSpeaker = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.42969 17.9453L5.89844 14.2734" stroke="#303030" strokeWidth="1.46875" strokeLinecap="round" />
    <path d="M1.85938 10.2344C2.52563 11.5669 4.94478 14.2896 9.55555 15.4413C10.3869 15.649 11.1984 15.1048 11.4062 14.2734V14.2734V13.4107C11.4062 13.2578 11.467 13.1111 11.5751 13.003V13.003C11.8533 12.7248 11.7857 12.2415 11.4574 12.0247C10.7366 11.5485 9.71938 10.7884 9.07714 10.1184C8.64954 9.67233 9.02585 9.20993 9.64282 9.2445C10.7381 9.30588 12.1977 9.29009 12.7158 8.93597C12.844 8.84837 12.875 8.6848 12.875 8.52956V7.44528C12.875 6.94096 13.2505 6.51556 13.751 6.453L13.9439 6.42889C14.7393 6.32946 15.1429 5.34343 14.7428 4.64878C14.1376 3.59794 13.6094 2.34184 13.6094 1.42188" stroke="#303030" strokeWidth="1.46875" strokeLinecap="round"/>
    <path d="M16.1711 10.2345C16.1711 11.336 15.4368 13.172 14.8853 13.7509" stroke="#303030" strokeWidth="1.46875" strokeLinecap="round" />
    <path d="M18.383 9.49996C18.7501 12.0703 17.6486 14.2734 16.1798 16.4765" stroke="#303030" strokeWidth="1.46875" strokeLinecap="round" />
  </svg>

)

export default RequestSpeaker
