import React from "react"
import styled from "styled-components"
import { supressPopups } from "shared/utils"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import Button from "@ui/LoaderButton"
// import UploadIcon from "../../Images/Upload.png"

const FormWrap = styled.div`
  margin-left: 31px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar { 
    display: none;
  }
`

const Label = styled.div`
width: 449.43px;
height: 22px;
font-family: ${ATC};
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 22px;
color: #303030;
margin-bottom: 8px;
`
const TextInputSmall = styled.input`
  width: 449.43px;
  height: 49px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  padding-left: 16px;
  font-weight: 200;
  font-size: 16px;
`
const TextInputBig = styled.textarea`
  width: 449.43px;
  height: 134px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  margin-top: 8px;
  padding-left: 16px;
  padding-top: 8px;
  line-height: 20px;
  margin-bottom: 22px;
  font-weight: 200;
  font-size: 16px;
`
const ContinueButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  gap: 10px;
  width: 449.43px;
  height: 48px;
  background: #3B60E4;
  border-radius: 36px;
  margin-top: 24.5px;
  margin-bottom: 16px;
  border: 0px;
  cursor: pointer;

  ${props => props.disabled === true && `
  background: #989898;
  color: white;
`}
`
const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 449.43px;
  margin-bottom: 16px;
  height: 48px;
  border: 1.25px solid #3B60E4;
  border-radius: 36px;
  color: #3B60E4;
  cursor: pointer;
`
const SaveForLater = styled.h3`
  display: flex;
  align-self: center;
  width: 113px;
  height: 22px;
  margin: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  cursor: pointer;
`

class FourthFormDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    const { response } = this.props
    this.state = {
      form: {
        step_completed: "referral_info",
        reason_to_join_radius: response.reason_to_join_radius ? response.reason_to_join_radius : null,
        referred_by: response.referred_by ? response.referred_by : null,
      },
    }
  }

  updateOrientationInfoFunction = () => {
    const { updateOrientationInfo, setFormStage } = this.props
    const { form } = this.state
    const payload = { ...form }
    this.setState({
      isLoading: true,
    })
    updateOrientationInfo({ payload, setStage: "5" })
    // setFormStage("5")
  }

  goBackFunction = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("3")
  }

  saveForLater = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("0")
    supressPopups("reportingPopup")
  }

  validateForm = () => {
    const { form } = this.state
    if (
      form.reason_to_join_radius === ""
      || form.referred_by === ""
    ) {
      return true
    }
    return false
  }

  render() {
    const {
      form,
      reasonChange,
      referChange,
      isLoading,
    } = this.state
    const { response } = this.props
    return (
      <FormWrap>
        <Label>
          What is your main reason for joining Radius Agent?

        </Label>
        <TextInputBig
          value={reasonChange ? form.reason_to_join_radius : response.reason_to_join_radius}
          required={!response.reason_to_join_radius}
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                reason_to_join_radius: e.target.value,
              },
              reasonChange: true,
            })
          }}
        />
        <Label>
          Who referred you?
        </Label>
        <TextInputSmall
          value={referChange ? form.referred_by : response.referred_by}
          required
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                referred_by: e.target.value,
              },
              referChange: true,
            })
          }}
        />
        <ContinueButton
          isShowLoader={isLoading}
          disabled={this.validateForm()}
          onClick={this.updateOrientationInfoFunction}
        >
          Continue
        </ContinueButton>
        <GoBackButton onClick={this.goBackFunction}>Go back</GoBackButton>
        <SaveForLater onClick={this.saveForLater}>Save For Later</SaveForLater>
      </FormWrap>
    )
  }
}

export default OnboardingContainer(FourthFormDetails)
