import React, { useEffect } from "react"
import OnboardingContainer from "container/OnboardingSetup"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import Loader from "@ui/Loader"

const ConditionalRenderingPage = ({
  verifyToken,
  getDisplayScreen,
  location,
  history,
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { authcode } = parsedQuery
  useEffect(() => {
    const payload = {
      isRequestForBrokerageApp: true,
      authcode,
    }
    verifyToken({
      payload,
      callback: (secureId, email, newUser) => getDisplayScreen({
        secureId, email, history, newUser,
      }),
    })
  }, [])

  return (
    <Loader />
  )
}

export default withRouter(OnboardingContainer(ConditionalRenderingPage))
