import React from "react"
import { Progress } from "antd"
import colors from "@colors"
import {
  ProgressWrap,
  CapAmtWrap,
  ProgressBarWrapper,
  TooltipIndicator,
} from "./styles"

const ProgressToCap = ({
  completedLabel, capLabel, capAmt, completedAmt,
}) => {
  const currentCap = completedAmt || 0
  const percent = (currentCap / capAmt) * 100
  const total = 10000
  const completed = (percent / 100) * completedAmt
  return (
    <ProgressWrap>
      <ProgressBarWrapper>
        <Progress percent={percent} status="active" showInfo={false} strokeColor={colors.new_primary} />
        <TooltipIndicator
          style={{
            left: `${percent}%`,
            transform: percent > 90 ? "translateX(-100%)" : "translateX(-50%)", // Adjust for overflow
          }}
        >
          {completedLabel}
        </TooltipIndicator>
      </ProgressBarWrapper>
      <CapAmtWrap>{capLabel}</CapAmtWrap>
    </ProgressWrap>
  )
}

export default ProgressToCap
