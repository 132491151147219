import React, { PureComponent } from "react"
import RealtyContainer from "container/Realty"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import {
  tablet,
  mobile,
  xsMobile,
  minDesktopWidth,
  minLargeDesktopWidth,
  preDesktopWidth,
  preTabletWidth,
} from "@consts"
import EllipseTopLeft from "images/Graphics/RadiusEdgeAssets/LeftArcArrow.svg?q=100"
import EllipseBottomRight from "images/Graphics/RadiusEdgeAssets/RightArcArrow.svg?q=100"

const Wrap = styled.div`
  padding: ${props => props.wrapPadding || "0px 72px 25px 72px"};

  @media(max-width: 767px) {
    padding: ${props => props.mobileOuterPadding || "0px 24px"};
  }
`
const ApplyNowSectionContainerWrap = styled.div`
  padding: ${props => props.customPadding ? props.customPadding : "82px 82px"};
  border-radius: ${props => props.customBorderRadius || "16px"};
  background: ${props => props.customBackground || "#190A36"};
  position: relative;
  ${xsMobile`
    margin-top: 72px;
    padding: 156px 50px;
  `}
`
const EllipseTop = styled.div`
  ${props => props.hide && `
    display: none;
  `}
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 100%;
    
    ${minDesktopWidth`
      width: 70%;
    `}

    ${minLargeDesktopWidth`
     width: 100%;
    `}

    ${preDesktopWidth`
       width: 52%;
    `}

    ${preTabletWidth`
       width: 50%;
    `}
  }
`
const EllipseBottom = styled.div`
  ${props => props.hide && `
    display: none;
  `}
  position: absolute;
  right: 0;
  bottom: 0;
  img {
    width: 100%;
    float: right;
    
    ${minDesktopWidth`
      width: 70%;
    `}

    ${minLargeDesktopWidth`
     width: 100%;
    `}

    ${preDesktopWidth`
       width: 52%;
    `}

    ${preTabletWidth`
       width: 50%;
    `}
  }
`

class Board extends PureComponent {
  render() {
    const {
      children,
      customPadding,
      wrapPadding,
      mobileOuterPadding,
      customBackground,
      hideArcs,
      customBorderRadius,
    } = this.props

    return (
      <Wrap wrapPadding={wrapPadding} mobileOuterPadding={mobileOuterPadding}>
        <ApplyNowSectionContainerWrap
          customBackground={customBackground}
          customPadding={customPadding}
          customBorderRadius={customBorderRadius}
        >
          <EllipseTop hide={hideArcs}>
            <img src={EllipseTopLeft} alt="EllipseTopLeft.svg" />
          </EllipseTop>
          {children}
          <EllipseBottom hide={hideArcs}>
            <img src={EllipseBottomRight} alt="EllipseBottomRight.svg" />
          </EllipseBottom>
        </ApplyNowSectionContainerWrap>
      </Wrap>
    )
  }
}

export default withRouter(RealtyContainer(Board))
