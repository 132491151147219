import * as React from "react"

function Rooms() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        stroke="#E5E6FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.993 2.996h12.005a2 2 0 012 2v14.007a2 2 0 01-2 2H5.993a2 2 0 01-2-2V4.997a2 2 0 012-2z"
        clipRule="evenodd"
      >

      </path>
      <path
        stroke="#E5E6FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.992 11v2M3.992 19c0 1.488 1.566 2.456 2.895 1.79l4.002-2a2.003 2.003 0 001.107-1.791V2.996"
      >

      </path>
    </svg>
  );
}

export default Rooms