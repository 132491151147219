import * as React from "react";
const ImgIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6639 17.5H3.33561C2.41478 17.5 1.66895 16.7542 1.66895 15.8333V4.16667C1.66895 3.24583 2.41478 2.5 3.33561 2.5H16.6639C17.5848 2.5 18.3306 3.24583 18.3306 4.16667V15.8333C18.3306 16.7542 17.5839 17.5 16.6639 17.5Z"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84518 6.32141C8.49605 6.97228 8.49605 8.02756 7.84518 8.67843C7.19431 9.32931 6.13903 9.32931 5.48816 8.67843C4.83728 8.02756 4.83728 6.97228 5.48816 6.32141C6.13903 5.67053 7.19431 5.67053 7.84518 6.32141"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.333 13.3333L14.8172 10.5208C14.458 10.2333 13.933 10.2916 13.6455 10.6508L10.8655 14.1258C10.578 14.4849 10.0538 14.5433 9.69384 14.2558L8.13717 13.0099C7.78301 12.7266 7.26717 12.7783 6.97634 13.1274L3.33301 17.4999"
      stroke="#232338"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ImgIcon;
