import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import traditionalCoverageChart from "dumbComponents/NewLandingPage/images/traditionalCoverage.png"
import WhiteBlock from "dumbComponents/NewLandingPage/images/whiteBlock.png"
import redBlock from "dumbComponents/NewLandingPage/images/redBlock.png"
import upArrowBlack from "dumbComponents/NewLandingPage/images/upArrowBlack.png"
import colors from "@colors"
import { ATC } from "@fonts"

const WrapAmen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
> p {
  font-family: ${ATC};
  margin: 0px;
}
`

const ImageWrap = styled.div`
  position: absolute;
  bottom: 55px;
  left: 55px;
  display: flex;
  flex-direction: column;
  max-width: fit-content !important;
  max-height: fit-content !important;
  align-items: center;

  @media(max-width: 1200px) {
    top: 100px;
    left: 250px;
    flex-direction: row;
  }

  @media(max-width: 1099px) {
    top: 230px;
    left: 90px;
    flex-direction: column;
  }

  @media(max-width: 767px) {
    top: 68px;
    left: 175px;
    flex-direction: row;
  }

  @media(max-width: 425px) {
    flex-direction: row !important;
  }

  > img {
    width: 8px;
    height: 46px;

    @media(max-width: 1200px) {
      rotate: -90deg;
      margin-right: 25px;
    }

    @media(max-width: 1099px) {
      rotate: none;
      margin: 0px;
    }

    @media(max-width: 767px) {
      rotate: -90deg;
      margin-right: 25px;
    }
  }

  > p {
    color: ${colors.black};
    margin: 0;
    width: max-content;
    font-family: ${ATC};

    &:nth-child(2) {
      right: 95px;
      font-size: 20px;
      bottom: 75px;
      font-weight: 200;
      @media(max-width: 767px) {
        font-size: 18px;
      }

      @media(max-width: 425px) {
        font-size: 20px;
      }
    }

    &:nth-child(3) {
      right: 100px;
      font-size: 16px;
      bottom: 50px;
      font-weight: 200;
    }
  }
`

const Wrap = styled.div`
  padding: 20px;

  > h1 {
    font-size: 28px;
    margin: 0 0 10px 0;
    font-family: ${ATC};
    color: ${colors.black};

    @media(max-width: 425px) {
      font-size: 22px;
    }
  }

  > p {
    max-width: 562px;
    color: ${colors.black};
    line-height: 25px;
    font-family: ${ATC};
    font-weight: 200;
  }
`

const PieWrap = styled.div`
  display: flex;
  position: relative;

  @media(max-width: 1200px) {
    flex-direction: column;
  }

  @media(max-width: 1099px) {
    flex-direction: row;
    justify-content: space-around;
    gap: 100px;
  }

  @media(max-width: 425px) {
    flex-direction: column;
  }

  @media(max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
  
  > img {
    height: 251px;
    width: 251px;

    @media(max-width: 767px) {
      height: 180px;
      width: 180px;
    }
  }

  > div {
    max-width: 340px;
    width: 100%;

    @media(max-width: 1200px) {
      display: flex;
      max-width: 494px;
    }

    @media(max-width: 768px) {
      display: flex;
      flex-direction: column; 
    }

    @media(max-width: 767px) {
      flex-direction: row;
    }

    @media(max-width: 425px) {
      flex-direction: column;
    }
  }
`

const InfoPara = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > p{
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    margin: 0px;
    color: ${colors.black};
    @media(max-width: 1109px) {
      text-align: center;
    }
    @media(max-width: 725px) {
      font-size: 20px;
      line-height: 22px;
      text-align: initial;
    }
    @media(max-width: 425px) {
      font-size: 16px;
      line-height: 22px;
      text-align: initial;
    }
  }
`
const InfoDetails = styled.li`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  @media(max-width: 725px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media(max-width: 425px) {
    font-size: 14px;
  }
`

const InfoDetailsWrap = styled.div`
  @media(max-width: 1109px) {
    display: flex;
    justify-content: center;
  }
  @media(max-width: 768px) {
    display: block;
  }
`

const InfoDetailsContainer = styled.ul`
  list-style: disc;
  margin-left: 28px;
  @media(max-width: 1109px) {
    margin-left: 40px;
  }
  @media(max-width: 768px) {
    margin-left: 27px;
  }

  @media(max-width: 425px) {
    margin-left: 27px;
  }
`

const DetailsWrap = styled.div`
  > div {
    margin-bottom: 15px;
  }
`

const RadiusCoverage = () => {
  return (
    <Wrap>
      <h1>
        Under a Traditional Brokerage
      </h1>
      <p>
        You are responsible for both sales and implementation. Brokerage only provides basic support.
      </p>
      <PieWrap>
        <LazyLoad offset={100}>
          <img src={traditionalCoverageChart} alt="Radius Agent Coverage" />
        </LazyLoad>
        <ImageWrap>
          <img src={upArrowBlack} alt="Arrow Radius" loading="lazy" />
          <WrapAmen>
            <p>90%</p>
            <p>You and your team</p>
          </WrapAmen>
        </ImageWrap>
        <DetailsWrap>
          <div>
            <InfoPara>
              <img
                src={WhiteBlock}
                alt="Radius White Block"
                height={33}
                width={33}
                loading="lazy"
              />
              <p>
                YOU AND YOUR TEAM
              </p>
            </InfoPara>
            <InfoDetailsWrap>
              <InfoDetailsContainer>
                <InfoDetails>
                  Sales and Lead Nurturing
                </InfoDetails>
                <InfoDetails>
                  Client Service
                </InfoDetails>
                <InfoDetails>
                  Leadership and Mentoring
                </InfoDetails>
                <InfoDetails>
                  DBA and Brand Formation
                </InfoDetails>
                <InfoDetails>
                  Customized Marketing Assets
                </InfoDetails>
                <InfoDetails>
                  Recruiting
                </InfoDetails>
                <InfoDetails>
                  Transaction Coordination
                </InfoDetails>
                <InfoDetails>
                  Compliance and Escalation Support
                </InfoDetails>
                <InfoDetails>
                  Client Relationship Management 
                </InfoDetails>
                <InfoDetails>
                  Financing and Concierge Support
                </InfoDetails>
                <InfoDetails>
                  Growth Knowledge and Education
                </InfoDetails>
                <InfoDetails>
                  Vendor Managment
                </InfoDetails>
              </InfoDetailsContainer>
            </InfoDetailsWrap>
          </div>
          <div>
            <InfoPara>
              <img
                src={redBlock}
                alt="Radius White Block"
                height={28}
                width={28}
                loading="lazy"
              />
              <p>
                TRADITIONAL BROKERAGE
              </p>
            </InfoPara>
            <InfoDetailsWrap>
              <InfoDetailsContainer>
                <InfoDetails>
                  Limited agent support
                </InfoDetails>
                <InfoDetails>
                  Basic Insurance
                </InfoDetails>
                <InfoDetails>
                  One size fits all marketing support
                </InfoDetails>
              </InfoDetailsContainer>
            </InfoDetailsWrap>
          </div>
        </DetailsWrap>
      </PieWrap>
    </Wrap>
  )
}
export default RadiusCoverage
