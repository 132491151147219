import React from "react"
import styled from "styled-components"
import DownloadTheRadiusApp from "dumbComponents/VideoRooms/components/LandingPage/Components/images/DownloadTheRadiusApp.png"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import { preTabletWidth, minTabletWidth } from "@consts"

const Wrap = styled.div`
  background: url(${DownloadTheRadiusApp});
  background-size: cover;
  display: flex;
  justify-content: end;
  height: 350px;

  @media(max-width: 767px) {
    height: 414px;
    width: 100%;
  }
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 49px;
    letter-spacing: 0.005em;
    color: #FFFFFF;

    ${preTabletWidth && `
      font-family: Butler;
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.005em;
      text-align: left;
      width: 203px;
    `}

    ${minTabletWidth && `
      font-size: 36px;
      width: 230px;
    `}
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    width: 498px;

    ${preTabletWidth`
      font-family: ${ATC};
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      width: 100%;
    `}

    @media(max-width: 1024px) {
      font-size: 16px;
      width: 379px;
    }

    ${preTabletWidth`
      width: 100%;
    `}
  }
`

const SocialWrapper = styled.div`
  text-align: left;
  flex: 3 auto;
  position: relative;
  margin-top: 16px;
`

const DownloadTheApp = () => (
  <Wrap className="container">
    <HeaderWrap>
      <h1>
        Download the Radius App
      </h1>
      <p>
        Download the Radius App and take all your favorite Rooms content on-to-go with you!
      </p>
      <SocialWrapper>
        <a
          href="https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://d2fedz0by71ckz.cloudfront.net/images/ios_app_store_btn.png"
            alt="IOS app"
            height={40}
            width={120}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://d2fedz0by71ckz.cloudfront.net/images/google_play_store_btn.png"
            alt="Android app"
            height={40}
            width={120}
          />
        </a>
      </SocialWrapper>
    </HeaderWrap>
  </Wrap>
)

export default DownloadTheApp
