import React, { useEffect } from "react"
import styled from "styled-components"
import {
  Field,
  FieldArray,
} from "redux-form"
import {
  RenderAsyncSelect,
} from "@ui/Form"
import { required, email } from "services/formUtils"
import colors from "@colors"
import PlusIcon from "images/icons/PlusIcon4x.png"
import DeleteIcon from "images/icons/DeleteIcon.png"
import DisabledPlusIcon from "images/icons/DisabledPlusIcon.svg"
import debounce from "lodash.debounce"
import { delayFunctionForSearch, normalizePhone } from "../Common/Utils"
import Header from "../Common/FieldHeaderCommon"

const FieldName = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: ${props => props.marginBottom || "10px"};
`

const AddAnotherClientButton = styled.button`
  cursor: pointer;
  background: transparent;
  height: 48px;
  width: max-content;
  padding: 16px 16px 16px 0px;
  border: none;
  color: ${colors.deepPurple};
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  top: 12px;

  img {
    height: 12px;
    position: relative;
    top: 1.8px;
    left: 2px;
  }

  ${props => props.disabled && `
    cursor: not-allowed;
    color: ${colors.otterGrey};
  `}
`

const Wrap = styled.div`
  width: 490px;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid #D3DAE8;
  background: #F1F3F8;
  margin-top: 16px;

  span {
   color: red;
   position: relative;
   top: 10px;
   font-size: 13px;
  }
`

const FieldWrap = styled.div`
  margin-bottom: 30px;

  ${props => props.removeRedSpan && `
     span {
      display: none;
     }
  `}
`

const InputBox = styled.input`
  height: 54px;
  width: 100%;
  border-radius: 8px;
  padding: 15px 16px 15px 16px;
  border: 1px solid ${colors.black};

  ${props => props.customBorder && `
    border: 1px solid #96A3BD;
  `}

  ${props => props.isError && `
    border: 1px solid ${colors.errorDanger};
  `}

  &::placeholder {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #C5C5C5;
  }

  ${props => props.isOnboarding && `
     margin-bottom: 16px;
  `}

  ${props => props.topMargin && `
    margin-top: 16px;
  `}

  span {
   color: red;
   position: relative;
   top: 10px;
   font-size: 13px;
  }
`

const RemoveButton = styled.button`
  position: relative;
  border: none;
  cursor: pointer;
  background: transparent;
  filter: contrast(0.5);

  img {
    height: 20px;
    position: relative;
  }
`

const Disabled = styled.img`
  filter: contrast(0.5);
`
const SearchMessage = styled.p`
 margin-top: 4px;
 font-size: 12px;
 font-style: italic;
 margin-left: 2px;
`

const RemoveText = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  position: relative;
  left: -7px;

  span {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`

const Wrapper = styled.div`
  margin-top: 52px;
`

const CTAArea = styled.div`
 height: 20px;

 button {
  float: right;
  padding: 6px;
  background: transparent;
  border: none;
  cursor: pointer;
 }
`

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  isOnboarding,
  topMargin,
  disabled,
}) => (
  <div>
    <InputBox
      topMargin={topMargin}
      isOnboarding={isOnboarding}
      isError={touched && error}
      {...input}
      type={type}
      placeholder={label}
      disabled={disabled}
    />
    {touched && error && <span>{error}</span>}
  </div>
)

const renderClientFields = ({
  fields,
  disabled,
  meta: { error, submitFailed, touched },
}) => (
  <ul>
    {fields && fields.map((clients, index) => (
      <li>
        <Wrap
          style={{
            marginTop: "16px",
          }}
        >
          <RemoveButton
            isError={touched && error}
            type="button"
            onClick={() => fields.remove(index)}
          >
            <RemoveText>
              <span>
                <img src={DeleteIcon} alt="deleteIcon" />
              </span>
              Remove
            </RemoveText>
          </RemoveButton>
          <FieldWrap>
            <FieldName>Client Name</FieldName>
            <Field
              name={`${clients}.name`}
              type="name"
              component={renderField}
              label="Enter name"
              validate={[required]}
              disabled={disabled}
            />
          </FieldWrap>
          <FieldName>Client Email</FieldName>
          <FieldWrap>
            <Field
              name={`${clients}.email`}
              type="email"
              component={renderField}
              label="Enter e-mail address"
              validate={[required, email]}
              disabled={disabled}
            />
          </FieldWrap>
          <FieldWrap>
            <FieldName>Client Phone</FieldName>
            <Field
              name={`${clients}.phone`}
              type="phone"
              component={renderField}
              label="313-244-1337"
              validate={[required]}
              normalize={normalizePhone}
              disabled={disabled}
            />
          </FieldWrap>
        </Wrap>
        {submitFailed && error && <span>{error}</span>}
      </li>
    ))}
    {!disabled && (
      <li>
        <AddAnotherClientButton disabled={fields.length > 3} type="button" onClick={() => fields.push({})}>
          Add Another
          {" "}
          <span>
            {fields.length > 3 ? (
              <Disabled src={DisabledPlusIcon} alt="deleteIcon" />
            ) : (
              <img src={PlusIcon} alt="plusIcon.png" />
            )}
          </span>
        </AddAnotherClientButton>
      </li>
    )}
  </ul>
)

const ClientArrayComponent = ({
  fetchClient,
  phone,
  emailId,
  change,
  disabled,
}) => {
  useEffect(() => {
    if (phone) change("initial_phone", phone)
    if (emailId) change("initial_email", emailId)
  }, [phone, emailId])

  return (
    <Wrapper>
      <Header
        header="Client Information"
        desc="Please add all client names on the contract"
      />
      <Wrap>
        <FieldWrap removeRedSpan>
          <FieldName marginBottom="34px">Client Details *</FieldName>
          {/*<Field
            name="initial_name"
            type="name"
            component={RenderAsyncSelect}
            label="Enter name"
            promiseOptions={debounce(delayFunctionForSearch, 2000)}
          />
          <SearchMessage>
            If client details are missing, please fill them in the fields below*
          </SearchMessage>*/}
          <FieldWrap>
            <FieldName>Client Name</FieldName>
            <Field
              name="initial_name"
              type="name"
              component={renderField}
              label="Enter client name"
              validate={[required]}
              disabled={disabled}
            />
          </FieldWrap>
        </FieldWrap>
        <FieldWrap>
          <FieldName>Client Email</FieldName>
          <Field
            name="initial_email"
            type="email"
            component={renderField}
            label="Enter email address"
            validate={[required, email]}
            disabled={disabled}
          />
        </FieldWrap>
        <FieldWrap>
          <FieldName>Client Phone</FieldName>
          <Field
            name="initial_phone"
            type="phone"
            component={renderField}
            label="313-244-1337"
            validate={[required]}
            normalize={normalizePhone}
            disabled={disabled}
          />
        </FieldWrap>
        {/*<CTAArea>
          <button
            type="button"
            onClick={() => {
              change("initial_name", { value: null, label: null })
              change("initial_phone", null)
              change("initial_email", null)
            }}
          >
            Reset
          </button>
        </CTAArea>*/}
      </Wrap>
      <FieldArray
        name="clientDetailsArray"
        component={renderClientFields}
        fetchClient={fetchClient}
        disabled={disabled}
      />
    </Wrapper>
  )
}

export default ClientArrayComponent
