import * as React from "react";
const BrbcSignPending = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21 11V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H12"
      stroke="#5A5FF2"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15L12.15 14.611C12.371 14.584 12.577 14.483 12.735 14.326L20.366 6.69499C21.211 5.84999 21.211 4.47999 20.366 3.63399V3.63399C19.521 2.78899 18.151 2.78899 17.305 3.63399L9.745 11.194C9.592 11.347 9.493 11.545 9.462 11.76L9 15Z"
      stroke="#5A5FF2"
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BrbcSignPending;
