import React, { Component } from "react"
import styled from "styled-components"
import root from "window-or-global"
import { isEmpty } from "lodash"
import icons from "@icons"
import colors from "@colors"
import firebase from "firebase/app"
import CookiesStorage from "services/CookieStorage"
import CircularImage from "dumbComponents/common/CircularImage"
import { deleteArrayOfCookies, showFeatureTab } from "services/Utils"
import container from "container/App/sidebarContainer"
import navBarContainer from "container/Navbar"
import globalContainer from "container/App"
import axios from "axios"
import { ShimmerBox } from "shared/styles/animation"
import Box from "@ui/Box"
import { Link } from "react-router-dom"
import PremiumUserMarker from "images/premium-user.svg"
import LoginButton from "./LoginButton"
// import { messaging } from "../../../init-fcm"

const StyledDropdown = styled.div`
  position: relative;
  &:hover {
    ul {
      display: block;
    }
  }

  ${props => props.isPushedByGiftIcon && `
    margin-right: 50px;
  `}
`

const NotificationDot = styled.div`
  position: absolute;
  z-index: 1000;
  height: 11px;
  width: 11px;
  background: #ea526f;
  display: inline-grid;
  border-radius: 18px;
  top: 0;
  right: 0;
  box-shadow: 0px 2px 3px grey;`

const DropdownAnchor = styled.a`
  border-radius: 0px;
  color: #354052;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px;
  opacity: 0.9;
  position: relative;
  text-align: center;
  height: 51px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  img {
    margin: 0 auto 3px;
    display: block;
    max-width: none;
    vertical-align: middle;
    border: 0;
    padding-top: 5px;
  }
`

const DropdownList = styled.ul`
  position: absolute;
  top: 62px;
  right: -22px;
  z-index: 2200;
  width: 300px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in-out;
  display: none;
  &:hover {
    display: block;
  }
`

const DropdownItem = styled.li`
  &:first-child {
    a {
      border-top: none !important;
      padding-top: 15px;
    }
  }

  a {
    border-bottom: 1px solid #e8eaeb !important;
    font-size: 15px;
    line-height: 15px;
    color: #3b4859;
    padding: 16px;
    font-weight: 300;
    clear: both;
    display: block;
    text-decoration: none;
    background: #FFF;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
`

const ArrowWrap = styled.div`
  display: flex;
  position: relative;
  top: 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
    color: ${colors.marineBLue};
  }

  ${props => props.listVisible && `
    transform: rotate(-90deg);
  `}
`

const AgentInfoWrap = styled.div`
  text-align: left;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 63px;
  ${props => props.isLogged && `
    min-width: 50px;
  `}
  p {
    font-family: "Arial", sans-serif;
    font-size: 16px;
    color: ${colors.contentText};
    font-weight: 500;
    margin: 0px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  h5 {
    margin: 0px;
    font-size: 12px;
    color: ${colors.faintBlueishBlack};
    img {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }

  ${props => props.isShimmer && `
    flex-direction: row;
  `}
`

const ProfileShimmer = () => (
  <AgentInfoWrap isShimmer>
    <Box>
      <ShimmerBox br="24px" w="48px" h="48px" />
    </Box>
    <Box flex="1" d="flex" jc="center" fd="column" ml="10" w="60">
      <Box>
        <ShimmerBox br="5px" w="100%" h="14px" />
      </Box>
      <Box>
        <ShimmerBox br="5px" w="50%" h="8px" />
      </Box>
    </Box>
  </AgentInfoWrap>
)

const clearCookiesOnLogout = () => {
  deleteArrayOfCookies(
    [
      "redirectUrl",
      "brokerageTeamId",
      "brokerageTeamIdForLogo",
      "interestViaPopUp",
      "currentGroup",
      "secureId",
      "user",
      "isCrsDesignee",
      "leadAgreementSigned",
      "isaLeadAgreementSigned",
      "referralManager",
      "agentId",
      "onboardingComplete",
      "onboarding",
      "isWcr",
      "isAreaa",
      "isSamcar",
      "bannerClosed",
      "FCMToken",
      "teamProfileWelcomeDone",
      "joinToken",
      "hasUserOnboarded",
      "isSpotlightShownNew",
    ]
  )
  window.location.href = `${RADIUS_URL}/signup`
}

class ProfileDropdown extends Component {
  state = {
    listVisible: false,
    isMounted: false,
  }

  componentDidMount() {
    const {
      getSidebarUpdates,
      sideMenuUpdateresponse,
    } = this.props

    const {
      data: updateData,
      isFetching,
    } = sideMenuUpdateresponse || {}

    const updatesObjectExist = updateData && Object.keys(updateData).length > 0

    if (!updatesObjectExist && !isFetching) {
      getSidebarUpdates()
    }

    this.setState({
      isMounted: true,
    })
  }

  logout = () => {
    const { logoutUser } = this.props
    if (CookiesStorage.load("FCMToken")) {
      try {
        firebase.messaging().deleteToken()
          .then((data) => { console.log("token deleted", data) })
          .catch((err) => { console.log(err) })
      } catch (error) {
        console.log("Error Logging out");
      }
    }
    logoutUser()
  }

  handleTrackProfileDropdown = () => {
    if (root.heap) {
      root.heap.track("navbar_hover_profile_web")
    }
  }

  render() {
    const {
      profileImage,
      isPushedByGiftIcon,
      user,
      isAdvanceUser,
      sideMenuUpdateresponse,
      isEven,
    } = this.props

    const {
      data: updateData,
    } = sideMenuUpdateresponse || {}

    const updatesObjectExist = updateData && Object.keys(updateData).length > 0

    const {
      isClient,
      listVisible,
      isMounted,
    } = this.state
    const { firstname, lastname, agent_image } = user || {}
    const isUseLogged = !isEmpty(user)
    return (
      <StyledDropdown isPushedByGiftIcon={isPushedByGiftIcon} className="onboading-nav-profile">
        <DropdownAnchor onMouseEnter={this.handleTrackProfileDropdown} >
          {isUseLogged ? (
            <React.Fragment>
              <CircularImage
                size="48"
                image={profileImage || agent_image}
                charSize="16"
                name={`${firstname || ""} ${lastname || ""}`}
              >
                {updatesObjectExist &&
                  updateData.profile && (
                  <NotificationDot></NotificationDot>
                )}
              </CircularImage>
              
              <AgentInfoWrap isEven={isEven} isLogged={isUseLogged}>
                <p>{`${firstname || ""} ${lastname || ""}`}</p>
                {isAdvanceUser && (
                  <h5>
                    <img src={PremiumUserMarker} alt="Premium User" />
                    <span
                      style={{
                        color: "#000",
                      }}
                    >
                      Premium
                    </span>
                  </h5>
                )}
              </AgentInfoWrap>
              <ArrowWrap listVisible={listVisible}>
                <i className={icons.arrowsExpandDown}></i>
              </ArrowWrap>
            </React.Fragment>
          ) : (
            <ProfileShimmer />
          )}
        </DropdownAnchor>
        <DropdownList>
          {showFeatureTab() && (
            <>
              <DropdownItem>
                {isUseLogged && (
                  <a href={user.profile_url ? user.profile_url : `${PROFILE_URL}profile/agent?id=${user.id}`}>
                    View my profile
                  </a>
                )}
              </DropdownItem>
              <DropdownItem>
                <Link to="/settings">Settings</Link>
              </DropdownItem>
            </>
          )}
          <DropdownItem>
            <a onClick={this.logout} className="logout-buttom-hp">Logout</a>
          </DropdownItem>
        </DropdownList>
      </StyledDropdown>
    )
  }
}

export default globalContainer(navBarContainer(container(ProfileDropdown)))
