import React from "react"
import { ShimmerBox } from "shared/styles/animation"
import styled from "styled-components"

const Wrap = styled.div`
  padding-bottom: 20px;
`

const DrawerShimmer = ({
  height = "30px",
  width = "100%",
}) => (
  <Wrap>
    <ShimmerBox w={width} h={height} />
  </Wrap>
)

export default DrawerShimmer
