import React from "react"
import { required } from "services/formUtils"
import {
  Input,
} from "@ui/Form"
import Loader from "@ui/Loader"
import root from "window-or-global"
import query from "query-string"

import { withRouter } from "react-router-dom"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import Header from "../Common/Header"

import {
  DETAILS_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
} from "../../Routes"
import {
  RADIUS_TRANSACTION_COORDINATION_AGENT_CONFIRMATION,
  FIELD_VALUES,
} from "./data"

import SubmitButton from "../Common/SubmitButton"
import CheckboxComponent from "../Common/RadioGroup"
import {
  Wrap,
  FormWrap,
  CTAWrap,
  BackButton,
} from "../Common/CommonStyles"

import {
  HandleSubmit,
  FunctionalComponentDidMount,
  backButtonUrl,
  BrowserCloseListener,
  FetchFileIfIDExists,
  getIsEdit,
} from "../Common/Utils"

const TCAgentFormUI = ({
  ...props
}) => {
  const {
    id,
    fetchTCValuesBasedOnFormID,
    transactionFormFetchValuesResponse: dataValues,
    initialize,
    transactionFormCreateDealViaFormResponse: createFormData,
    transactionFormUpdateStageResponse: updateStageData,
    history,
    handleSubmit,
    invalid,
    fetchTransactionCoordinationDocumentsResponse,
    // isUsingTCAgent,
    // existingisUsingTCAgent,
    destroy,
    formName,
    isFormDisabled,
  } = props || {}

  BrowserCloseListener()

  const {
    isFetching: fetchingValues,
  } = dataValues || {}

  const {
    data: deals,
    isFetching: isFetchingDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  const {
    isFetching: creatingForm,
  } = createFormData || {}

  const {
    isFetching: updatingTCStage,
  } = updateStageData || {}

  if (id && deals) {
    const currentDeal = deals && deals.filter(deal => deal.id === parseInt(id, 10))[0]
    FunctionalComponentDidMount(id, fetchTCValuesBasedOnFormID, initialize, FIELD_VALUES, currentDeal)
  } else {
    FunctionalComponentDidMount(false, false, false, false, false, destroy, formName)
  }

  const isProcessing = fetchingValues || creatingForm || updatingTCStage || isFetchingDeals

  return (
    <>
      {fetchingValues ? (
        <Loader top={50} />
      ) : (
        <FormWrap onSubmit={handleSubmit}>
          <CheckboxComponent
            noButtonReposition
            // validate={[required]}
            name="is_using_deal_tc"
            optionArray={RADIUS_TRANSACTION_COORDINATION_AGENT_CONFIRMATION}
            labelText="Would you like to use your Radius in-house TC for this transaction?"
            marginBottom="31px"
            disabled={isFormDisabled}
            customLabelStyles={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          />
          <CTAWrap>
            <BackButton
              type="button"
              onClick={() => {
                backButtonUrl(history, id, DETAILS_FORM_ROUTE)
              }}
            >
              Back
            </BackButton>
            <SubmitButton
              // disabled={isFormDisabled}
              isProcessing={isProcessing}
              text="Continue"
              // isInvalid={invalid}
            />
          </CTAWrap>
        </FormWrap>
      )}
    </>
  )
}

const TCAGENTFormComponent = ({
  history,
  fetchTCValuesBasedOnFormID,
  transactionFormFetchValuesResponse,
  transactionFormCreateDealViaFormResponse,
  transactionFormUpdateStageResponse,
  fetchTransactionCoordinationDocumentsResponse,
  createDeal,
  updateDeal,
  combinedTCValues,
  properties,
  props,
  toggleErrorModal,
  fetchTCFiles,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const {
    data: deals,
    isFetching: fetchingFiles,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = parsedQuery && parsedQuery.id
  const currentDeal = deals && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const isCompleted = currentDeal && currentDeal.completed_steps && currentDeal.completed_steps.includes("verified")
  const disableField = isCompleted || isCoListed || getIsEdit(currentDeal)

  /*For Browser Refresh*/
  FetchFileIfIDExists(parsedQuery.id, currentDeal, fetchTCFiles)

  const {
    is_using_deal_tc,
  } = combinedTCValues || {}

  const EscrowFormRoute = ["dual", "pending_contract", "external_referral_agreement"]
  const allCashOfferRoutes = ["listing_with_accepted_offer", "dual", "pending_contract", "pending_lease"]
  let nextRoute = combinedTCValues
  && combinedTCValues.file_type
  && allCashOfferRoutes.includes(combinedTCValues.file_type) ? COORDINATION_FORM_ROUTE
    : EscrowFormRoute.includes(combinedTCValues.file_type) ? ESCROW_FORM_ROUTE : SIGN_POST_FORM_ROUTE
  // let nextRouteV2 = combinedTCValues
  // && combinedTCValues.file_type
  // && combinedTCValues.file_type === new_listing ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE

  // if (parsedQuery.id && deals) {
  //   const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
  //   nextRoute = existingDeal && existingDeal.file_type === "new_listing" ? SIGN_POST_FORM_ROUTE : ESCROW_FORM_ROUTE
  // }

  if (parsedQuery.id && deals) {
    const existingDeal = deals && deals.filter(deal => deal.id === parseInt(parsedQuery.id, 10))[0]
    const fileType = existingDeal ? existingDeal.file_type : combinedTCValues.file_type
    nextRoute = existingDeal && allCashOfferRoutes.includes(fileType) ? COORDINATION_FORM_ROUTE
      : EscrowFormRoute.includes(fileType) ? ESCROW_FORM_ROUTE
        : fileType !== "new_listing" ? UPLOAD_FILE_FORM_ROUTE : SIGN_POST_FORM_ROUTE
  }

  const {
    is_using_deal_tc: existingisUsingTCAgent,
  } = currentDeal || {}

  return (
    <Wrap>
      <Header
        text="Transaction Coordination"
        history={history}
      />
      {fetchingFiles ? (
        <Loader top={50} />
      ) : (
        <TransactionForm
          isUsingTCAgent={is_using_deal_tc}
          existingisUsingTCAgent={existingisUsingTCAgent}
          onSubmit={values => HandleSubmit(
            values,
            nextRoute,
            history,
            parsedQuery.id,
            false,
            fetchTCValuesBasedOnFormID,
            createDeal,
            combinedTCValues,
            "transaction_coordination",
            updateDeal,
            currentDeal,
            toggleErrorModal,
            disableField,
          )}
          isFormDisabled={disableField}
          id={parsedQuery.id}
          formName="TC_AGENT_OPTED_FORM"
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormFetchValuesResponse={transactionFormFetchValuesResponse}
          transactionFormCreateDealViaFormResponse={transactionFormCreateDealViaFormResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          fetchTCValuesBasedOnFormID={fetchTCValuesBasedOnFormID}
          combinedTCValues={combinedTCValues}
          history={history}
          {...properties}
          {...props}
        />
      )}
    </Wrap>
  )
}

const TransactionForm = reduxForm({
  form: "TC_AGENT_OPTED_FORM",
  destroyOnUnmount: false,
})(TransactionContainer(withRouter(TCAgentFormUI)))


export default TransactionContainer(withRouter(TCAGENTFormComponent))
