/* eslint-disable no-else-return */
import React from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import root from "window-or-global"
import { get } from "lodash"
import colors from "@colors"
import {
  Input,
  RadioGroup,
  Select,
  Counter,
  LocationAutoComplete,
} from "@ui/Form"
import { required, commaSeperatedNumberUS, formatAmount } from "services/formUtils"
import Heading from "dumbComponents/common/Typography/Heading"
import Box from "@ui/Box"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Wrapper,
  Header,
  SaveButton,
  Footer,
  Tag,
  DeleteButton,
} from "./commonStyles"
import { ATC } from "@fonts"

const FormWrap = styled.form`
  padding: 20px;
`

const FieldRowWrap = styled.div`
  position: relative;
`

export const ADD_BUYER_NEEDS_INITIAL_VALUES = {
  represented_by_self: 1,
  sale_type: "resale",
  bedroom: 1,
  bathroom: 1,
  priority: 1,
  display_in_profile: 1,
  types: "",
  area_unit: "Sq.ft",
  notes: "",
}

const customStyles = {
  option: provided => ({
    ...provided,
    fontFamily: {ATC},
    fontWeight: 300,
    color: colors.black,
    fontSize: 18,
    borderRadius: 0,
    textAlign: "left",
    zIndex: 99999,
  }),
}

const FieldRow = ({ title, children }) => (
  <FieldRowWrap>
    <Heading
      type="h7"
      color="#354052"
      align="left"
      center={false}
      style={{
        margin: "15px 0px",
      }}
    >
      {title}
    </Heading>
    {children}
  </FieldRowWrap>
)

class AddBuyerNeeds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { getPropertyTypes, initialValues } = this.props
    const { locations } = initialValues
    if (locations && locations.length > 0) {
      this.setState({
        selectedNeighborhood: locations,
      })
    }
    getPropertyTypes()
  }

  handleAddressSelect = (address) => {
    const { change } = this.props
    const inputElement = root.document.getElementById("googleLocationInput")
    inputElement.value = ""
    const { lat, lng, sublocality_level_1: { long_name } = {} } = address
    const { selectedNeighborhood = [] } = this.state
    const locality = long_name || get(address, "locality.long_name")
    const neighborhood = {
      locality,
      latitude: lat,
      longitude: lng,
      c_locality_name: locality,
    }
    const updatedSelectedNeighborhood = [...selectedNeighborhood, neighborhood]
    change("locations", updatedSelectedNeighborhood)
    this.setState({
      selectedNeighborhood: updatedSelectedNeighborhood,
    })
  }

  handleSave = () => {
    const { handleSubmit } = this.props
    handleSubmit()
  }

  removeItem = (city) => {
    const { change } = this.props
    const { selectedNeighborhood = [] } = this.state
    const filtered = selectedNeighborhood && selectedNeighborhood.length > 0
    && selectedNeighborhood.filter(item => item.c_locality_name !== city) || []
    change("locations", selectedNeighborhood)
    this.setState({
      selectedNeighborhood: [...filtered],
    })
  }

  render() {
    const { selectedNeighborhood } = this.state
    const {
      preFetchedTypes,
      isSavingUpdates,
      pristine,
      removeBuyerNeeds,
      isRemovingBuyerNeeds,
      clientId,
    } = this.props
    const reqID = get(this.props, "initialValues.req_id")
    return (
      <Wrapper>
        <Header>
          <h4>Add Buyer Needs</h4>
        </Header>
        <FormWrap>
          <FieldRow title="Transaction type *">
            <Field
              name="sale_type"
              component={RadioGroup}
              options={[
                { label: "Sale", value: "resale" },
                { label: "Rent", value: "rent" },
              ]}
              validate={required}
            />
          </FieldRow>
          <FieldRow title="Priority Level *">
            <Field
              name="priority"
              component={RadioGroup}
              options={[
                { label: "Hot", value: 1 },
                { label: "Warm", value: 2 },
                { label: "Inactive", value: 3 },
              ]}
              validate={required}
            />
          </FieldRow>
          {/* BUDGET -------------------------------------- START */}
          <Box d="flex">
            <Box flex="1" mr="20">
              <FieldRow title="Budget - Min">
                <Field
                  name="min_budget"
                  component={Input}
                  type="text"
                  format={formatAmount}
                  //normalize={commaSeperatedNumberUS}
                />
              </FieldRow>
            </Box>
            <Box flex="1">
              <FieldRow title="Budget - Max*">
                <Field
                  name="max_budget"
                  component={Input}
                  type="text"
                  //normalize={commaSeperatedNumberUS}
                  format={formatAmount}
                  validate={required}
                />
              </FieldRow>
            </Box>
          </Box>
          {/* BUDGET -------------------------------------- END */}

          {/* SIZE -------------------------------------- START */}
          <Box d="flex">
            <Box flex="1" mr="20">
              <FieldRow title="Size - Min">
                <Field
                  name="min_size"
                  component={Input}
                  optional
                  type="number"
                />
              </FieldRow>
            </Box>
            <Box flex="1" mr="20">
              <FieldRow title="Size - Max*">
                <Field
                  name="max_size"
                  component={Input}
                  validate={required}
                  type="number"
                />
              </FieldRow>
            </Box>
            <Box w="150">
              <FieldRow title="Unit">
                <Field
                  name="area_unit"
                  component={Select}
                  validate={required}
                  options={[
                    { label: "Sq. Ft", value: "Sq.ft" },
                    { label: "Sq. M", value: "Sq.mt" },
                    { label: "Acres", value: "Acres" },
                  ]}
                  selectStyles={customStyles}
                />
              </FieldRow>
            </Box>
          </Box>
          {/* SIZE -------------------------------------- ENDS*/}

          {/* ROOMS -------------------------------------- START*/}
          <Box d="flex" mt="20px">
            <Box flex="1">
              <FieldRow title="Bed">
                <Field
                  name="bedroom"
                  component={Counter}
                />
              </FieldRow>
            </Box>
            <Box flex="1">
              <FieldRow title="Bath">
                <Field
                  name="bathroom"
                  component={Counter}
                />
              </FieldRow>
            </Box>
          </Box>
          {/* ROOMS -------------------------------------- END*/}
          <FieldRow title="Property Type *">
            <Field
              name="types"
              component={Select}
              validate={required}
              options={preFetchedTypes}
              config={{
                isSearchable: true,
                isMulti: true,
              }}
            />
          </FieldRow>
          <FieldRow title="Neighborhood *">
            <Field
              component={LocationAutoComplete}
              name="locations"
              id="googleLocationInput"
              types={["(regions)"]}
              location={(addressVal) => { this.handleAddressSelect(addressVal) }}
              placeholder="Type your Neighborhood*"
              validate={selectedNeighborhood && selectedNeighborhood.length > 0 ? "" : required}
              loader={false}
            />
          </FieldRow>
          { selectedNeighborhood && selectedNeighborhood.length > 0 && (
            <Box>
              {selectedNeighborhood.map(item => (
                <Tag
                  key={item.locality || item.c_locality_id}
                  title={item.locality || item.c_locality_name}
                  handleClick={this.removeItem}
                />
              ))}
            </Box>
          ) }
          <Box> </Box>
        </FormWrap>
        <Footer d="flex" style={reqID ? { justifyContent: "space-between" } : { justifyContent: "flex-end" }}>
          {reqID && (
            <DeleteButton onClick={() => { removeBuyerNeeds({ reqId: reqID, clientId }) }}>
              {isRemovingBuyerNeeds ? (
                <ThreeDotLoader />
              ) : (
                <span>Delete</span>
              )}
            </DeleteButton>
          )}
          <SaveButton
            width="100px"
            type="button"
            isShowLoader={isSavingUpdates}
            onClick={this.handleSave}
            disabled={pristine}
          >
            Save
          </SaveButton>
        </Footer>
      </Wrapper>
    )
  }
}

const Form = reduxForm({
  form: "ADD_BUYER_NEEDS",
})(AddBuyerNeeds)

export default Form
