/*
  author - Saurav Tiru
  This file contains common code for Paragraph.
  DO NOT EDIT THIS FILE DIRECTLY.

  1) Custom Styling
    Use "customParaStyling" prop to pass custom paragraph style properties.

  2) Additional Properties
    Request author to verify additional properties before merging.

  3) Media Queries
    Do not change Media Query widths.
 */

import React from "react"
import styled from "styled-components"
import colors from "@colors"

const ParagraphWrapper = styled.p`
  /*Paragraph Styling*/
  font-family: ${props => props.paraFamily || "sans-serif"};
  text-align: ${props => props.alignPara || "left"};
  text-decoration: ${props => props.paraDecoration || "none"};
  font-weight: ${props => props.paraWeight || "normal"};
  color: ${props => props.color || colors.black};
  margin: ${props => props.margin || "initial"};
  letter-spacing: ${props => props.letterSpacing || "initial"};

  @media(min-width: 1100px) {
     width: ${props => props.minDesktopParaWidth || "100%"};
     font-size: ${props => props.minDesktopParaSize || "20px"};
  }

  @media(min-width: 1440px) {
    width: ${props => props.minLargeDesktopParaWidth || "100%"};
    font-size: ${props => props.minLargeDesktopParaSize || "20px"};
  }
  
  @media(max-width: 1099px) {
     width: ${props => props.preDesktopParaWidth || "100%"};
     font-size: ${props => props.preDesktopParaSize || "20px"};
  }

  @media(max-width: 767px) {
     width: ${props => props.preTabletParaWidth || "100%"};
     font-size: ${props => props.minTabletParaSize || "20px"};
  }

  @media(max-width: 650px) {
    width: ${props => props.maxMobileParaWidth || "100%"};
    font-size: ${props => props.maxMobileParaSize || "18px"};
 }
   /*Paragraph Styling*/   
`

const Paragraph = (props) => {
  const {
    customParaStyling,
    children,
    content,
  } = props
  return (
    <ParagraphWrapper
      style={{
        ...customParaStyling || {},
      }}
      {...props}
    >
      {children || content || null}
    </ParagraphWrapper>
  )
}

export default Paragraph
