import React, { useRef, useState } from "react"
import { Modal, Button } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import OffersV2 from "container/OffersV2/index"
import styled from "styled-components"
import createToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { Confirmation } from "dumbComponents/OffersV3/components"
import TechAssets from "container/TechAssets/index"

const VoidReasonModal = ({
  isVisible,
  id,
  onCancel,
  onOk,
  sendForVoidAndDeleteOffer,
  fetchActiveEnvelopesFiles,
  dealToView,
}) => {

  const [reason, setReason] = useState("")

  const handleOk = () => {
    const payload = [{ envelopeId: id, reason: reason }]
    createToast("Voiding Document.")
    sendForVoidAndDeleteOffer({
      payload,
      callback: () => {
        fetchActiveEnvelopesFiles({
          payload: {
            offerId: dealToView,
          },
        })
      },
    })

    onOk()
  }

  const handleCancel = () => {
    onCancel()
  }

  const TitleContainer = styled.div`
    display: flex
    align-items: center
  `

  const IconStyle = {
    fontSize: "24px",
    marginRight: "12px",
    color: "#faad14",
  }

  const ModalContentStyle = {
    marginTop: "-15px",
  }

  return (
    <Modal
      title={
        <TitleContainer>
          <ExclamationCircleOutlined style={IconStyle} />
          <span>Please confirm the action.</span>
        </TitleContainer>
      }
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={reason.length === 0}
          onClick={handleOk}
        >
          Ok
        </Button>,
      ]}
      bodyStyle={{ padding: "32px 32px 24px", ...ModalContentStyle }}
      centered
      width={400}
    >
      <Confirmation
        updateNote={(val) => {
          setReason(val)
        }}
      />
    </Modal>
  )
}

export default OffersV2(TechAssets(VoidReasonModal))
