import React from "react"
import TransactionBreadCrumb from "dumbComponents/TransactionDashboard/components/BreadCrumbTransaction"
import {
  HeaderWrap,
  TitleAndSelectWrap,
  Title,
} from "./styles"

const HeaderSectionCommon = () => (
  <HeaderWrap>
    {/* <OffersBreadCrumb /> */}
    <TransactionBreadCrumb />
    <TitleAndSelectWrap>
      <Title>
        <p>New Offer</p>
      </Title>
    </TitleAndSelectWrap>
  </HeaderWrap>
)

export default HeaderSectionCommon
