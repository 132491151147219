import React from "react"
import {
  InnerTab,
  NavLink,
  NotificationDot,
} from "./commonStyles"
import Referrals from "../Icons/NewIcons/Referrals"

const MarketplaceTab = ({
  routeArray,
  updatesObjectExist,
  updateData,
  leadAgreementSigned,
  total,
}) => (
  <InnerTab referral isActive={routeArray[1] === "marketplace"}>
    {updatesObjectExist
      && updateData.lmp
      && !updateData.lmp.seen ? (
        <NotificationDot />
      ) : (
        <NotificationDot zero></NotificationDot>
      )}
    <div className="icon-block-new">
      <Referrals />
    </div>
    <div className="text-block">
      <NavLink
        flex
        onClick={() => {
          // if (leadAgreementSigned !== "signed") {
          //   CookiesStorage.save("leadAgreementSigned", "docusignAgreementCreated")
          // }
        }}
        to="/referrals/marketplace"
        isActive={routeArray[1] === "marketplace"}
      >
        Referrals
        {total > 0 && (
          <span>
            <p>{total > 0 && total}</p>
          </span>
        )}
      </NavLink>
    </div>
  </InnerTab>
)

export default MarketplaceTab
