import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import { withRouter } from "react-router-dom"
import Container from "container/TechAssets"
import OffersV2 from "container/OffersV2"
import CRMContainer from "container/CRM"

//getClientTransactionsById({ transactionId: transactionId || currentCRMDealId })


const user = checkForUserObject("user")

const agentId = user && user.id

class PubnubForDocusign extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      refreshOfferDocuments, dealToView, updateOfferDocusignEnvelopeStatus, submitAndSendAllOffersFile,
      setOfferDocumentDownloadStatus, id, fetchActiveEnvelopesFiles, getOfferStatus, history,
      transactionId, getClientTransactionsById, toggleDocumentProcessing
    } = this.props
    const currentDealId = dealToView || id
    console.log(currentDealId,"currentDealId")
    const docusignOfferChannel = `docusign_offer_${currentDealId}`
    const offerDocumentStatusChannel = `offer_all_documents_status_${currentDealId}`
    console.log(PUBNUB_FEED_PUBLISH_KEY, "-", PUBNUB_FEED_SUBSCRIBE_KEY)
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {
      root.pubNubListingDocusign = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      console.log("===docusignListingChannel===", docusignOfferChannel)
      console.log("===offerDocumentStatusChannel===", offerDocumentStatusChannel)
      root.pubNubListingDocusign.subscribe({
        channels: [offerDocumentStatusChannel, docusignOfferChannel, "docusign_return"],
      })

      root.pubNubListingDocusign.addListener({
        message: (data) => {
          console.log("Listening to..", docusignOfferChannel, data)

          try {
            const { message, channel } = data
            console.log("check here->", data)
            const { event_type } = message
            console.log(message)
            console.log(event_type)

            if (channel === "docusign_return" && message.listingId && parseInt(message?.listingId, 10) === currentDealId && message?.agentId === agentId) {
              const docusignData = message
              if (docusignData && docusignData.docusignEnvelopeId) {
                if (docusignData.docusignIntermediateStatus) {
                  updateOfferDocusignEnvelopeStatus({
                    payload: {
                      envelopeId: docusignData.docusignEnvelopeId,
                      status: docusignData.docusignIntermediateStatus,
                    },
                    callback: () => {
                      refreshOfferDocuments({
                        payload: {
                          offerId: currentDealId,
                        },
                      })
                      fetchActiveEnvelopesFiles({
                        payload: {
                          offerId: currentDealId,
                        },
                      })
                      getOfferStatus({ id: currentDealId })
                      console.log("here false")
                      toggleDocumentProcessing(true)
                      getClientTransactionsById({ transactionId })
                    },
                  })
                } else {
                  refreshOfferDocuments({
                    payload: {
                      offerId: currentDealId,
                    },
                  })
                  getOfferStatus({ id: currentDealId })
                  console.log("here false")
                  toggleDocumentProcessing(false)
                  getClientTransactionsById({ transactionId })
                }
              }
              return
            }

            if (currentDealId && (channel === docusignOfferChannel)) {
              refreshOfferDocuments({
                payload: {
                  offerId: currentDealId,
                },
              })
              getOfferStatus({ id: currentDealId })
              toggleDocumentProcessing(false)
              getClientTransactionsById({ transactionId })
            }
            if (currentDealId && (channel === offerDocumentStatusChannel)) {
              // to get url
              submitAndSendAllOffersFile({
                payload: {
                  offerId: currentDealId,
                },
                callback: () => {
                  setOfferDocumentDownloadStatus("done")
                },
              })
              getOfferStatus({ id: currentDealId })
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { dealToView, id } = this.props
    const currentDealId = dealToView || id
    const docusignOfferChannel = `docusign_offer_${currentDealId}`
    const offerDocumentStatusChannel = `offer_all_documents_status_${currentDealId}`
    if (root.pubNubListingDocusign) {
      root.pubNubListingDocusign.unsubscribe({
        channels: [offerDocumentStatusChannel, docusignOfferChannel, "docusign_return"],
      })
    }
  }

  render() {
    return null
  }
}

export default withRouter(CRMContainer(OffersV2(Container(PubnubForDocusign))))

