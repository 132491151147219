import React, { useEffect, useState } from "react"
import styled from "styled-components"
import queryString from "query-string"
import AppContainer from "container/App"
import root from "window-or-global"
import OnboardingContainer from "container/OnboardingSetup"
import Container from "container/Settings/SubscriptionsContainer"
import SettingsContainer from "container/Settings"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from "react-stripe-elements"
import {
  ICAButtons,
} from "dumbComponents/ICAOnboarding/common"
import { ATC, INTER } from "@fonts"
import { withRouter } from "react-router-dom"
import { get, isEmpty } from "lodash"
import CookiesStorage from "services/CookieStorage"
import ThreeDotLoader from "@ui/ThreeDotLoader"

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 548px;
`

const CardNumberInputWrap = styled(CardNumberElement)`
  display: block;
  height: 47px;
  max-width: 500px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 8px;
  border: 1px solid var(--color-primary, #5A5FF2);
  outline: 0;
  background: white;
`

const CardCVInputWrap = styled(CardCVCElement)`
  display: block;
  height: 47px;
  width: 100%;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  border-radius: 8px;
  border: 1px solid var(--color-primary, #5A5FF2);
  background: white;
`

const CardExpireInputWrap = styled(CardExpiryElement)`
  display: block;
  height: 47px;
  width: 100%;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 8px;
  border: 1px solid var(--color-primary, #5A5FF2);
  outline: 0;
  background: white;
`

const CardFieldsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const CardResponsiveWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FormWrap = styled.div`
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormHeader = styled.h1`
  color: var(--gray-900, #101828);
    font-family: ${INTER};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
`

const FormFooter = styled.p`
  color: #636262;
    font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`

const InvalidWrap = styled.p`
  margin: 0;
  color: red;
`

const InjectedCardForm = ({
  verifyAuthToken,
  history,
  getStripeSecretKey,
  getStripeSecretResponse,
  verifyAuthTokenResponse,
  saveStripeSubscription,
  getCardDetails,
  saveStripeSubscriptionResponse,
  fetchUserInfo,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [isInvalid, setInvalid] = useState(false)
  const { isFetching } = saveStripeSubscriptionResponse || {}
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery
  const payload = {
    authCode: authcode,
  }

  useEffect(() => {
    verifyAuthToken({ payload })
  }, [authcode])


  const handleBlur = () => {
    console.log("[blur]")
  }
  const handleChange = (change) => {
    console.log("[change]", change)
    if (!isEmpty(change.error)) {
      const message = get(change, "error.message")
      setInvalid(message)
    } else {
      setInvalid(false)
    }
  }
  const handleFocus = () => {
    console.log("[focus]")
  }
  const handleReady = () => {
    console.log("[ready]")
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const secureId = CookiesStorage.load("secureId")
    if (props.stripe) {
      const tokenPayload = await props.stripe
        .createToken()
      const tokenId = get(tokenPayload, "token.id")
      saveStripeSubscription({
        token: tokenId,
        secureId,
        history,
        callback: () => {
          getCardDetails({ secureId })
        },
      })
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }

  return (
    <CardWrap>
      <form onSubmit={handleSubmit}>
        <FormWrap>
          <FormHeader>
            Enter your card details here
          </FormHeader>
          <CardResponsiveWrap>
            <CardNumberInputWrap
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              placeholder="Enter Card Number"
            />
            <CardFieldsWrap>
              <CardExpireInputWrap
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                placeholder="Expiration MM/YY"
              />
              <CardCVInputWrap
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                placeholder="CVV"
              />
            </CardFieldsWrap>
            {isInvalid && (
              <InvalidWrap>{isInvalid}</InvalidWrap>
            )}
          </CardResponsiveWrap>
          <FormFooter>
            Your credit card details are encrypted and securely stored.
          </FormFooter>
        </FormWrap>
        <ButtonWrap>
          <ICAButtons
            type="submit"
            disabled={isFetching || isInvalid}
            style={{
              width: "300px",
            }}
          >
            {isFetching ? <ThreeDotLoader color="#FFFFFF" /> : "Submit & Proceed"}
          </ICAButtons>
          <ICAButtons
            type="button"
            style={{
              width: "130px",
              color: "#5A5FF2",
              border: "1px solid #5A5FF2",
              backgroundColor: "white",
            }}
            onClick={() => {
              setLoading(true)
              history.push("/signup/download-app?fromCardDetails=true")
              // fetchUserInfo({ callback: () => { root.location.href = "/community" } })
            }}
          >
            {loading ? <ThreeDotLoader color="#FFFFFF" /> : "Skip"}
          </ICAButtons>
        </ButtonWrap>
      </form>
    </CardWrap>
  )
}

export default injectStripe(withRouter(Container(SettingsContainer(OnboardingContainer(AppContainer(InjectedCardForm))))))
