import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import OffersV2 from "container/OffersV2"
import TechAssets from "container/TechAssets"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import { Input } from "@ui/antd"
import {
  Header,
  Content,
  ContentContainer,
} from "./styles"

const { TextArea } = Input

const Wrap = styled.div`
  padding: 20px;
`

const Label = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  color: ${colors.black};
`

const ButtonWrap = styled.div`
  display: flex;
  //padding: 0 20px;
`

const StatusChange = ({
  isStatusChangeModalOpen,
  toggleStatusChangeModal,
  updateOfferStatus,
  fetchBuyerOffers,
  history,
  getOfferStatus,
  dealToView,
  getOffers,
}) => {
  const [notes, setNotes] = useState("")

  const {
    id,
    status,
    transactionId,
    agentId,
  } = isStatusChangeModalOpen || {}

  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Are you sure?
          </h1>
        </Header>
        <ContentContainer
          style={{
            padding: "0px 0px 20px 0px",
          }}
        >
          <Content>
            Please provide some notes on the status change for your own records.
          </Content>
          <Label>
            Notes:
          </Label>
          <TextArea
            style={{
              fontWeight: 300,
            }}
            rows={4}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            //disabled={updatingStatus}
            //isShowLoader={updatingStatus}
            onClick={() => {
              const payload = {
                notes,
                offer_id: id,
                status,
              }

              updateOfferStatus({
                payload,
                callback: () => {
                  toggleStatusChangeModal({
                    bool: false,
                    id: "",
                    status: "",
                    agentId: null,
                    transactionId: null,
                  })
                  if (!dealToView) {
                    if (!agentId) {
                      fetchBuyerOffers({
                        transactionId,
                        history,
                      })
                    } else {
                      getOffers({
                        skip: 0,
                        limit: 100,
                        query: `&agentId=${agentId}`,
                      })
                    }
                  } else if (dealToView) {
                    getOfferStatus({ id: dealToView })
                  }
                },
              })
            }}
          >
            Change Status
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            //disabled={updatingStatus}
            //={updatingStatus}
            loaderOption={{
              color: "blue",
            }}
            onClick={() => {
              toggleStatusChangeModal({
                bool: false,
                id: "",
                transactionId: null,
                agentId: null,
              })
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default OffersV2(withRouter(TechAssets(StatusChange)))

