import styled from "styled-components"
import {
  Select,
  Input,
  InputNumber,
} from "@ui/antd"

const { TextArea } = Input

export const Wrap = styled.div`
  width: 100%;
`

export const SelectFieldWrap = styled(Select)`
  border-radius: 4px;
  border: 0.7px solid #898989;
  width: 100%;

  .ant-select-selector {
    ${props => props.isFormField && `
      border-radius: 8px !important;
    `}
    height: 47px !important;
  }
`

export const InputFieldWrap = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 42px !important;
  position: relative;
`

export const InputTextAreaWrap = styled(TextArea)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  padding: 12px 16px;
  height: 125px !important;
`

export const InputNumberWrap = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid var(--Color-Primary-Primary, #898989) !important;
  height: 42px !important;
  .ant-input-number-input {
    height: 42px !important;
  }
`
