import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as CRMV2Actions from "./actions"

const mapStateToProps = (state) => {
  const { CRMV2 } = state

  return {
    ...CRMV2,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      toggleUserOverview: CRMV2Actions.toggleUserOverviewDrawer.call,
      toggleFilterDrawer: CRMV2Actions.toggleFilterClientLeadsDrawer.call,
      toggleAddNewLeadDrawer: CRMV2Actions.toggleAddNewLeadDrawer.call,
      setCurrentDrawerPage: CRMV2Actions.toggleDrawerPage.call,
      setCurrentTransactionDetails: CRMV2Actions.setTransactionDetailsAction.call,
      getClient: CRMV2Actions.getClientAction.request,
      addClient: CRMV2Actions.addClientAction.request,
      updateClient: CRMV2Actions.updateClientAction.request,
      getPropertyTypes: CRMV2Actions.getPropertyTypeAction.request,
      addSearchCriteria: CRMV2Actions.addBuyerRequirementsAction.request,
      addPropertyDetails: CRMV2Actions.addPropertyDetailsAction.request,
      createClientTransaction: CRMV2Actions.createClientTransactionAction.request,
      getClientTransactions: CRMV2Actions.getClientTransactionsAction.request,
      getClientTransactionsById: CRMV2Actions.getClientTransactionByIdAction.request,
      uploadImage: CRMV2Actions.uploadImageAction.request,
      getSearchCriteria: CRMV2Actions.getBuyerRequirementsAction.request,
      getPropertyDetails: CRMV2Actions.getPropertyDetailsAction.request,
      getClientFilter: CRMV2Actions.getClientsFilters.request,
      changeCardOrder: CRMV2Actions.changeCardOrderAction.request,
      updateClientTransaction: CRMV2Actions.updateClientTransactionAction.request,
      updateBuyerRequirement: CRMV2Actions.updateBuyerRequirementsAction.request,
      updatePropertyDetails: CRMV2Actions.updatePropertyDetailsAction.request,
      setTopFilter: CRMV2Actions.currentTopFilterTypeAction.call,
      updateFilterQueries: CRMV2Actions.updateFilterQueriesTypeAction.call,
      changeCardStage: CRMV2Actions.changeCardStageAction.request,
      setClientID: CRMV2Actions.setCurrentClientTransactionIDAction.call,
      toggleGoToContract: CRMV2Actions.toggleGoToContract.call,
      updateContractCRM: CRMV2Actions.updateContractCRMAction.request,

      //reminder actions
      toggleReminderModal: CRMV2Actions.toggleReminderModalAction.call,
      createReminder: CRMV2Actions.setReminderAction.request,
      deleteReminder: CRMV2Actions.deleteReminderAction.request,
      addNote: CRMV2Actions.addNotesAction.request,
      getNote: CRMV2Actions.getNotesAction.request,
      updateNote: CRMV2Actions.updateNotesAction.request,
      deleteNote: CRMV2Actions.deleteNotesAction.request,
      getTimeline: CRMV2Actions.getTimelineAction.request,
      getBrokerageTransactionStatus: CRMV2Actions.getBrokerageTransactionStatusAction.request,
      addBrokerageTransaction: CRMV2Actions.addBrokerageTransactionAction.request,
      setModalOpenConfirm: CRMV2Actions.setModalOpenConfirmAction.call,
      setModalOpenView: CRMV2Actions.setModalOpenViewAction.call,
      setCurrentClientId: CRMV2Actions.setCurrentClientIDAction.call,
      setCurrentTab: CRMV2Actions.setTabAction.call,
      getFinancingForTransaction: CRMV2Actions.getFinancingForTransactionAction.request,
      createFinancingForTransaction: CRMV2Actions.createFinancingForTransactionAction.request,
      setCurrentClientType: CRMV2Actions.setClientTypeAction.call,
      getReminder: CRMV2Actions.fetchClientReminderAction.request,
      clearData: CRMV2Actions.clearUpdatedDataAction.call,
      toggleAgentFinance: CRMV2Actions.toggleAgentFinancePreferenceAction.request,
      addCoClientDetails: CRMV2Actions.addCoClientDetailsAction.request,
      updateCoClientDetails: CRMV2Actions.updateCoClientDetailsAction.request,
      deleteCoClientDetails: CRMV2Actions.deleteCoClientDetailsAction.request,
      toggleArchiveLeadModal: CRMV2Actions.toggleArchiveLeadModalAction.call,
      archiveLead: CRMV2Actions.archiveLeadAction.request,
      getArchiveLeads: CRMV2Actions.getArchivedLeadAction.request,
      unArchiveLeads: CRMV2Actions.unArchiveLeadAction.request,
      toggleModalForArchivedLeads: CRMV2Actions.toggleModalForArchivedLeadsAction.call,
      deleteClientTransaction: CRMV2Actions.deleteClientTransactionAction.request,
      //view modals for CRM with Transaction Management
      setIsViewListing: CRMV2Actions.setIsViewListing.call,
      setIsViewContract: CRMV2Actions.setIsViewContract.call,
      setIsViewOffer: CRMV2Actions.setIsViewOffer.call,
      setIsAddListing: CRMV2Actions.setIsAddListing.call,
      setIsAddOffer: CRMV2Actions.setIsAddOffer.call,
      setIsEndContract: CRMV2Actions.setIsEndContract.call,
      setIsViewListingToContract: CRMV2Actions.setIsViewListingToContract.call,
      setIsViewOfferToContract: CRMV2Actions.setIsViewOfferToContract.call,
      setCannotConvertContract: CRMV2Actions.setCannotConvertContract.call,
      setIsClosed: CRMV2Actions.setIsClosed.call,
      setIsCreateWarning: CRMV2Actions.setIsCreateWarning.call,
      setStatusValue: CRMV2Actions.setStatusValue.call,

      buyerReqClear: CRMV2Actions.addBuyerRequirementsAction.clear,
      propListClear: CRMV2Actions.addPropertyDetailsAction.clear,
      crmClietClear: CRMV2Actions.getClientAction.clear,
      updateClientFinanceTransaction: CRMV2Actions.updateFinancingForTransactionAction.request,
      toggleEmptyDeatilsWarning: CRMV2Actions.emptyDetailsWarningAction.call,
      toggleShowInContract: CRMV2Actions.toggleShowInContractAction.call,
      toggleShowDeleteNoteModal: CRMV2Actions.setViewDeleteNoteModal.call,
      toggleShowDeleteReminderModal: CRMV2Actions.setViewDeleteReminderModal.call,
      updateReminder: CRMV2Actions.updateReminderAction.request,
      getAgentDetails: CRMV2Actions.getAgentDetailsAction.request,
      confirmMoveToCreateContract: CRMV2Actions.confirmMoveToCreateContractAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
