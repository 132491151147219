import React, { useEffect } from "react"
import styled from "styled-components"
import { useWindowSize } from "services/Utils"
import Container from "container/OnboardingSetup"
import { withRouter } from "react-router-dom"
import GetStartedImage from "dumbComponents/Auth/SignupV2/Images/GetStartedImage.svg"
import { get } from "lodash"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  WelcomeContentWrap,
  WelcomeTitle,
  WelcomeDescription,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import Navbar from "../Navbar"
import { ATC, INTER } from "@fonts"

const CardWrap = styled.div`
  border-radius: 9px;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);
  padding: 25px;
  width: 406px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`

const CardTitle = styled.h1`
  color: var(--color-tertiary, #1C1C1F);
  margin: 0;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const CardDescription = styled.p`
  color: var(--color-primary, #5A5FF2);
  margin: 0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
`

const RadiusVerify = ({
  history,
  verifyEmailTokenResponse,
  sendVerifyLink,
}) => {
  const { width } = useWindowSize()
  const email = get(verifyEmailTokenResponse, "data.user.email")

  const handleClick = () => {
    const payload = {
      email,
    }
    sendVerifyLink({
      payload,
      callback: () => {
        if (width > 767) {
          history.push(`/signup/email-sent?email=${email}`)
        } else {
          history.push("/signup/download-app")
        }
      },
    })
  }
  return (
    <>
      <Navbar />
      <Wrap>
        <ContentWrap>
          <RightWrap>
            <WelcomeContentWrap>
              <WelcomeTitle height="fit-content">
                Radius wants to verify your email
              </WelcomeTitle>
              <WelcomeDescription>
                A verification link will be sent to your email address.
              </WelcomeDescription>
            </WelcomeContentWrap>
            <CardWrap>
              <CardTitle>
                {`Your registered email address is ${email}`}
              </CardTitle>
              <CardDescription
                onClick={() => history.push("/office/ica-onboarding/update-email")}
              >
                Not the right email address?
              </CardDescription>
            </CardWrap>
            <ICAButtons
              style={{
                width: "159px",
              }}
              onClick={() => handleClick()}
            >
              Verify Email
            </ICAButtons>
          </RightWrap>
          <LeftWrap justifyContent="flex-end">
            <LeftImage src={GetStartedImage} />
          </LeftWrap>
        </ContentWrap>
      </Wrap>
    </>
  )
}

export default withRouter(Container(RadiusVerify))
