import React from "react";
import CRMV2Container from "container/CRM";
import { withRouter } from "react-router-dom";
import ReferralContainer from "container/Referral";
import TechAssetsContainer from "container/TechAssets";
import {
  DrawerHeading,
  DrawerDesc,
  BrbcDrawerContainer,
  HeadingContainer,
} from "./styles";
import BrbcForm from "./BrbcForm";
import { LeftOutlined } from "@ant-design/icons";
import { CloseWrap } from "../../common";

const BrbcDrawer = ({ setCurrentDrawerPage }) => {
  return (
    <BrbcDrawerContainer>
      <CloseWrap
        onClick={() => {
          setCurrentDrawerPage("drawer-header");
        }}
        fontColor="#5A5FF2"
      >
        <LeftOutlined style={{ color: `#5A5FF2` }} />
        <p>Back to Profile</p>
      </CloseWrap>
      <HeadingContainer>
        <DrawerHeading>Buyer representation</DrawerHeading>
        <DrawerDesc>
          Fill out this form to set up the representation. You'll sign it next
          and the buyer will receive it via email for their signature.
        </DrawerDesc>
      </HeadingContainer>
      <BrbcForm />
    </BrbcDrawerContainer>
  );
};

export default withRouter(
  ReferralContainer(TechAssetsContainer(CRMV2Container(BrbcDrawer)))
);
