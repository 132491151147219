import React from "react"
//import ReactDOM from "react-dom"
import { isObject, isArray } from "lodash"
import styled from "styled-components"
//import Transition from "react-transition-group/Transition"
import colors from "@colors"
import { setTimeout, clearInterval } from "window-or-global"

const Wrapper = styled.div`
  position: ${props => props.popOverPosition || "relative"};
  padding-right: ${props => props.padding_right ? `${props.padding_right}px !important` : "0px"};
  ${props => props.isCustomStyles && `
    width: 181px;
    max-width: 181px;
  `}

  ${props => props.minWidth && `
    min-width: ${props.minWidth}px;
  `}
`

const ChildrenWrapp = styled.div`
  z-index: 9999;
  position: ${props => props.elementPosition ? props.elementPosition : "absolute"};
  padding: ${props => props.childPadding ? props.childPadding : "auto"}
  border-top-width: 3px;
  z-index: ${props => props.zIndex};
  background: ${props => props.backgroundColor};
  border: 0.5px solid #3c404654;
  border-radius: ${props => props.borderRadius}px;
  ${props => props.isForceHide && `
    display: none;
  `}
  ${props => props.bottom ? `bottom: ${props.bottom}px;` : ""};
  ${props => props.left ? `left: ${props.left}px;` : ""};
  ${props => props.right ? `right: ${props.right}px;` : ""};
  ${props => props.top ? `top: ${props.top}px;` : ""};
  ${props => props.width ? `width: ${props.width}px;` : ""};
  ${props => props.height ? `height: ${props.height}px;` : ""};
  ${props => props.overflow ? `overflow: ${props.height};` : ""};
  ${props => props.showShadow
    ? `box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 8px 10px 1px rgba(0, 0, 0, 0.14)`
    : "box-shadow: none;"
};
`

const Arrow = styled.div`
  position: absolute;

  &[data-placement*='bottom'] {
    top: -16px;
    ${props => props.arrowPosition === "right" ? "right: 0;" : "left: 0;"}
    width: 48px;
    height: 16px;

    &::before, &::after {
      border-width: 0 8px 8px 8px;
    }

    &::before {
      bottom: -7px;
      border-color: #3c404654 transparent ${props => props.borderColor} transparent;
    }

    &::after {
      top: 0;
      border-color: transparent transparent ${props => props.backgroundColor} transparent;
    }
  }

  &[data-placement*='top'] {
    bottom: -16px;
    left: 0;
    width: 48px;
    height: 16px;

    &::before, &::after {
      border-width: 8px 8px 0 8px;
    }

    &::before {
      border-color: #3c404654 transparent transparent transparent;
    }

    &::after {
      bottom: 9px;
      border-color: ${props => props.backgroundColor} transparent transparent transparent;
    }
  }

  &[data-placement*='right'] {
    left: -16px;
    height: 48px;
    width: 16px;

    &::before, &::after {
      border-width: 8px 8px 8px 0;
    }

    &::before {
      top: 16px;
      left: 3px;
      border-color: #3c404654 ${props => props.color} transparent transparent;
    }

    &::after {
      left: 4px;
      border-color: transparent ${props => props.backgroundColor} transparent transparent;
    }
  }

  &[data-placement*='left'] {
    right: -16px;
    height: 48px;
    width: 16px;

    &::before, &::after {
      border-width: 8px 0 8px 8px;
    }

    &::before {
      top: 16px;
      right: 3px;
      border-color: #3c404654 transparent transparent ${props => props.color};
    }

    &::after {
      right: 4px;
      border-color: transparent transparent transparent ${props => props.color};
    }
  }

  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: relative;
  }

  &::after {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: relative;
  }
`

class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowing: false,
    }
  }

  toggleHoverState = (ele, value) => {
    this.setState({
      isShowing: value,
    })
  }

  handleEvent = (ele, value, type) => {
    const { eventType, hoverDelay } = this.props
    if (eventType === "hover" && (type === "mouseleave" || type === "mouseenter")) {
      if (hoverDelay && type === "mouseenter") {
        this.HoverTASK = setTimeout(() => {
          this.toggleHoverState(null, value)
        }, hoverDelay)
      } else if (hoverDelay && type === "mouseleave") {
        clearInterval(this.HoverTASK)
        this.toggleHoverState(null, value)
      } else {
        this.toggleHoverState(null, value)
      }
    } else if (eventType === "click" && value === null && type === "click") {
      const { isShowing } = this.state
      this.toggleHoverState(null, !isShowing)
    }
  }

  render() {
    const { isShowing } = this.state
    const {
      children,
      component,
      position,
      backgroundColor,
      top,
      bottom,
      left,
      right,
      width,
      borderColor,
      showShadow,
      show,
      padding_right,
      customStyles,
      tooltip,
      height,
      overflow,
      minWidth,
      hideWhenEmpty = false,
      zIndex = 50,
      hideTooltip,
      elementPosition,
      childPadding,
      customClassName,
      arrowPosition,
      borderRadius,
      popOverPosition,
      customChildStyles,
    } = this.props
    let childArray = []
    if (isArray(children)) {
      childArray = children
    } else {
      childArray.push(children)
    }

    const invalidChild = childArray.filter(x => (x === false || x === undefined))
    if (hideWhenEmpty && invalidChild.length === childArray.length) {
      return null
    }
    return (
      <Wrapper
        popOverPosition={popOverPosition}
        onMouseEnter={(ele) => { this.handleEvent(ele, true, "mouseenter") }}
        onMouseLeave={(ele) => { this.handleEvent(ele, false, "mouseleave") }}
        onClick={(ele) => { this.handleEvent(ele, null, "click") }}
        padding_right={padding_right}
        minWidth={minWidth}
        isCustomStyles={customStyles}
        style={{ ...customStyles }}
        className={customClassName || ""}
      >
        {tooltip && (tooltip)}
        {component}
        {children && (isShowing || show) && (
          <ChildrenWrapp
            childPadding={childPadding}
            bottom={bottom}
            left={left}
            top={top}
            right={right}
            width={width}
            position={position}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            showShadow={showShadow}
            height={height}
            overflow={overflow}
            zIndex={zIndex}
            isForceHide={hideTooltip}
            elementPosition={elementPosition}
            borderRadius={borderRadius}
            style={{ ...customChildStyles || {} }}
          >
            <Arrow
              data-placement={position}
              backgroundColor={backgroundColor}
              borderColor={borderColor}
              arrowPosition={arrowPosition}
            />
            {children}
          </ChildrenWrapp>
        )
        }
      </Wrapper>
    )
  }
}

Tooltip.defaultProps = {
  position: "top",
  backgroundColor: "#FFF",
  width: 300,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderColor: colors.primaryColor,
  eventType: "hover",
  borderRadius: 0,
  showShadow: false,
}

export default Tooltip
