import { Space, Tooltip} from "antd"
import React from "react"
import moment from "moment"
import {
  InDateLabel,
  DateWrap,
  StyledDatePicker,
} from "./styles"

const disabledDate = (current, setDate, today) => {
  if (setDate) {
    return current && current < moment(setDate).endOf("day")
  }

  return current && current < today
}

const DateRange = ({
  onFromDateChange,
  onToDateChange,
  fromListing,
  toListing,
}) => (
  <Space direction="horizontal">
    <DateWrap>
      <InDateLabel>
        <p>FROM</p>
      </InDateLabel>
      <StyledDatePicker
        onChange={onFromDateChange}
        allowClear
        //defaultPickerValue={moment()}
        defaulValue={fromListing}
      />
    </DateWrap>
    <DateWrap>
      <InDateLabel>
        <p>TO</p>
      </InDateLabel>
      {!fromListing || fromListing === null ? (
        <Tooltip title="Please select a starting date" color="red">
          <StyledDatePicker
            onChange={onToDateChange}
            allowClear
            defaulValue={toListing}
            disabled={!fromListing || fromListing === null}
            disabledDate={current => disabledDate(current, fromListing, moment())}
          />
        </Tooltip>
      ) : (
        <StyledDatePicker
          onChange={onToDateChange}
          allowClear
          defaulValue={toListing}
          disabled={!fromListing || fromListing === null}
          disabledDate={current => disabledDate(current, fromListing, moment())}
        />
      )}
    </DateWrap>
  </Space>
)
export default DateRange
