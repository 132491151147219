import React from "react"
import root from "window-or-global"
import { checkForUserObject } from "services/Utils"
import Container from "container/Referral"

class Pubnub extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.pubnub = new PubNubReact({
  //     publishKey: `${NEW_PUBNUB_PUBLISH_KEY}`,
  //     subscribeKey: `${NEW_PUBNUB_SUBSCRIBE_KEY}`,
  //   })
  //   this.pubnub.init(this)
  // }

  componentDidMount() {
    //try {
      // const user = checkForUserObject("user")
      // const agentId = user && user.id
      // this.agentId = agentId
      // const notificationChannel = `${agentId}-Notifications`
      // this.pubnub = new root.PubNubReact({
      //   publishKey: `${PUBNUB_PUBLISH_KEY}`,
      //   subscribeKey: `${PUBNUB_SUBSCRIBE_KEY}`,
      // })
      // this.pubnub.init(this)
      // console.log("Listening to..", notificationChannel)
      // this.pubnub.subscribe({
      //   channels: [notificationChannel],
      //   withPresence: true,
      // })
    // } catch (error) {
    //   console.log(error)
    // }

    // this.pubnub.getMessage(notificationChannel, (data) => {
    //   try {
    //     const { message } = data
    //     if (message) {
    //       const {
    //         fetchProspects,
    //       } = this.props
    //       const parsedMessage = JSON.parse(message)
    //       const { type } = parsedMessage
    //       if (parsedMessage.imageName && parsedMessage.imageName !== "bell") {
    //         fetchProspects({ referralType: parsedMessage.imageName, agentId })
    //       }

    //       // if (type === "feed") {
    //       //   showNewFeedsAvailable(parsedMessage)
    //       // }
    //     }
    //   } catch (e) {
    //     console.log("exception", e)
    //   }
    // })
  }

  componentWillUnmount() {
    // const { agentId } = this
    // this.pubnub.unsubscribe({
    //   channels: [`${agentId}-Notifications`],
    // })
  }

  render() {
    return null
  }
}

export default Container(Pubnub)
