import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects"
import { eventChannel, END } from "redux-saga"
import root from "window-or-global"
import { get } from "lodash"
import { formValueSelector } from "redux-form"
import deepMerge from "deepmerge"
import cookie from "services/CookieStorage"
import createToast from "@ui/Toast"
import { differenceInSeconds } from "date-fns"
import { isSuccess, isBrowser, isBroadcastChannelSupported } from "services/Utils"
import { dispatch } from "store/config"
import * as AppActions from "container/App/actions"
import * as ProfileActions from "container/Profile/action"
import * as ReferralActions from "container/Referral/actions"
import * as ClientActions from "container/Client/actions"
import { addNoteAPI } from "container/ClientProfile/api"
import { getSingleProspectAPI } from "container/Referral/api"
import { showPopupAction, getPopupDetailsAction } from "container/TechAssets/actions"
import { getPopupDetailsApi } from "container/TechAssets/api"
import * as MarketplaceActions from "./actions"
import * as API from "./api"

let CALL_TASK = null
let CALL_RINGING_FROM = null
const RINGING_TIMEOUT = 40 // seconds

if (isBrowser()) {
  root.RADIUS = {
    CALLTONE: new Audio("https://d2fedz0by71ckz.cloudfront.net/telephone-ring-04.mp3"),
  }
  root.RADIUS.CALLTONE.onended = () => {
    root.RADIUS.CALLTONE.play()
  }
  if (isBroadcastChannelSupported()) {
    root.PageBroadCast = new BroadcastChannel("Page_Channel")
    root.PageBroadCast.onmessage = function (event) {
      dispatch(MarketplaceActions.receiveBroadcastAction.call(event.data))
    }
  } else {
    // BroadcastChannel is not supported in Safari and IE
    import("broadcast-channel").then((Module) => {
      root.PageBroadCast = new Module.default("Page_Channel")
      root.PageBroadCast.onmessage = function (event) {
        dispatch(MarketplaceActions.receiveBroadcastAction.call(event.data))
      }
    })
  }
}

function* getLeadForMarketplace(action) {
  try {
    const {
      agentId,
      limit,
      offset,
      popupLeadId,
      push,
    } = action.data
    const res = yield call(API.getLeadForMarketplaceAPI, agentId, limit, offset)
    if (isSuccess(res)) {
      const { response } = res.data
      const {
        newLeadsCount,
      } = response
      yield put(MarketplaceActions.setCurrentNewLeadsAvailableAction.call(newLeadsCount))
      yield put(MarketplaceActions.getLeadForMarketplaceAction.success(response))
      if (popupLeadId) {
        yield put(MarketplaceActions.showRedirectionLoaderAction.call(true))
        yield put(ReferralActions.fetchLeadAction.request({
          referralId: popupLeadId,
          agentId,
          push,
        }))
      }
    }
  } catch (error) {
    yield put(MarketplaceActions.getLeadForMarketplaceAction.failure(error))
  }
}

function* sendDownloadLinkToPhone(action) {
  try {
    const res = yield call(API.sendDownloadLinkAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.sendDownloadLinkToPhoneAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.sendDownloadLinkToPhoneAction.failure(error))
  }
}

function* fetchRecentlyClaimedLeads(action) {
  try {
    const {
      offset,
      limit,
      isFetchingMore,
    } = action.data
    const res = yield call(API.fetchRecentlyClaimedAPI, offset, limit)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        const { recently_claimed_referrals, total } = response
        const { prevData } = action.data
        yield put(MarketplaceActions.fetchRecentlyClaimedLeadsAction.success({
          recently_claimed_referrals: deepMerge(prevData, recently_claimed_referrals),
          total,
          isFetchingMore: false,
        }))
      } else {
        yield put(MarketplaceActions.fetchRecentlyClaimedLeadsAction.success(response))
      }
    }
  } catch (error) {
    yield put(MarketplaceActions.fetchRecentlyClaimedLeadsAction.failure(error))
  }
}

function* fetchUserPreferrance() {
  try {
    const res = yield call(API.fetchUserMarketPlacePreferenceAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.fetchUserPreferranceAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.fetchUserPreferranceAction.failure(error))
  }
}

function* selectCityInPreference(action) {
  try {
    yield put(MarketplaceActions.toggleLoaderAction.call({
      key: "isNeighbourhoodBusy",
      value: true,
    }))
    const {
      city = "",
      newNeighbourhoodAdded,
    } = action.data
    yield put(ProfileActions.getNeighbourhoodByCityAction.request({
      city: city.toLowerCase(),
      neighbourhood: newNeighbourhoodAdded,
      isMarketplace: true,
    }))
  } catch (error) {
    console.warn("Something went wrong in selectCityInPreference", error)
  }
}

function* savePreference(action) {
  try {
    yield put(MarketplaceActions.toggleLoaderAction.call({
      key: "isSavingPreference",
      value: true,
    }))
    const { payload } = action.data
    const res = yield call(API.savePreferenceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.savePreferenceAction.success(response))
      yield put(MarketplaceActions.fetchUserPreferranceAction.success(response))
      yield put(MarketplaceActions.toggleLoaderAction.call({
        key: "isSavingPreference",
        value: false,
      }))
      yield put(MarketplaceActions.fetchRecentlyClaimedLeadsAction.request({
        limit: 5,
        offset: 0,
      }))
      yield put(MarketplaceActions.togglePreferenceModalAction.call(false))
    }
  } catch (error) {
    createToast(error, "error")
    yield put(MarketplaceActions.savePreferenceAction.failure(error))
    root.location.reload()
  }
}

export function countdown(secs) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      secs -= 1
      if (secs >= 0) {
        emitter(secs)
      } else {
        // this causes the channel to close
        emitter(END)
      }
    }, 1000)
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
    }
  })
}

function* claimUnverifiedLead(action) {
  try {
    const { payload, push, isMail } = action.data
    const {
      is_verified,
      agentId,
      referral_id,
    } = payload
    //cookie.save("callObject", JSON.stringify(payload))
    root.localStorage.setItem("callObject", JSON.stringify(payload))
    if (is_verified && false) {
      // For Verified
      const res = yield call(API.claimUnverifiedLeadAPI, {
        user_id: agentId,
        event_name: "agent_called_client_after_accept",
        entity_id: String(referral_id),
        entity: "referral",
      })
      if (isMail) {
        yield put(MarketplaceActions.creatingMailForVerifiedLeadAction.call(true))
      }

      // const leadData = yield call(getSingleProspectAPI, agentId, referral_id)
      // console.log({ leadData });
      // yield put(ReferralActions.updateProspectAction.request({
      //   agentId,
      //   referralId: referral_id,
      //   referralType: "incoming",
      //   prospectStatus: 1,
      //   isFromLMP: true,
      //   push,
      //   isMail,
      // }))
    } else {
      // For Unverified
      const res = yield call(API.claimUnverifiedLeadAPI, {
        user_id: agentId,
        event_name: is_verified ? "agent_called_client_first_time_after_accept" : "agent_called_unverified_client",
        entity_id: String(referral_id),
        entity: "referral",
      })
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(MarketplaceActions.claimUnverifiedLeadAction.success(response))
        if (!isMail) {
          const channel = yield call(countdown, 6)
          yield takeEvery(channel, startCallingCountdown)
        } else if (isMail) {
          //console.log("response", response)
        }
      }
    }
  } catch (error) {
    yield put(MarketplaceActions.creatingMailForVerifiedLeadAction.call(false))
    yield put(MarketplaceActions.claimUnverifiedLeadAction.failure(error))
  }
}

export function* startCallingCountdown(secs) {
  yield put(MarketplaceActions.toggleCallingBlockAction.call(true))
  yield put(MarketplaceActions.updateCountDownTimerAction.call(secs))
  if (secs === 0) {
    root.open("/marketplace/active-call", "marketplace-call")
  }
}

function* getAgentNumberForCalling(action) {
  try {
    const { toNumber } = action.data
    const res = yield call(API.getAgentNumberForCallingAPI, toNumber)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.getAgentNumberForCallingAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.getAgentNumberForCallingAction.failure(error))
  }
}

// TWILIO SAGAS ====================== START
function* connectCall(action) {
  try {
    const { payload } = action.data
    root.ActiveCall = root.TwilioDevice.connect(payload)
    root.RADIUS.CALLTONE.play()
    root.ActiveCall.on("connect", (event) => {
      dispatch(MarketplaceActions.connectCallAction.call(event))
    })
    CALL_RINGING_FROM = new Date()
    CALL_TASK = setInterval(() => {
      const status = root.ActiveCall.status()
      // Disconnect the call after 40 secoonds of Ringing
      console.log(differenceInSeconds(new Date(), CALL_RINGING_FROM))
      if (status === "ringing" && differenceInSeconds(new Date(), CALL_RINGING_FROM) > RINGING_TIMEOUT) {
        console.log("User didn't pick the call")
        root.ActiveCall.disconnect()
      }

      if (status === "open") {
        console.log("Call Connected Now.")
        root.RADIUS.CALLTONE.pause()
        clearInterval(CALL_TASK)
      }
      console.log("Call Status => ", status)
      dispatch(MarketplaceActions.updateCallStatusAction.call({ status }))
    }, 1000)

    root.ActiveCall.on("connected", (event) => {
      dispatch(MarketplaceActions.connectedCallAction.call(event))
    })

    root.ActiveCall.on("open", (event) => {
      dispatch(MarketplaceActions.connectedCallAction.call(event))
    })

    root.ActiveCall.on("closed", (event) => {
      dispatch(MarketplaceActions.connectedCallAction.call(event))
    })

    root.ActiveCall.on("hangup", (event) => {
      dispatch(MarketplaceActions.connectedCallAction.call(event))
    })

    root.ActiveCall.on("ringing", (event) => {
      dispatch(MarketplaceActions.ringingCallAction.call(event))
    })

    root.ActiveCall.on("disconnet", (event) => {
      dispatch(MarketplaceActions.disconnectCallAction.call(event))
    })
  } catch (error) {
    yield put(MarketplaceActions.errorInCallAction.call(error))
  }
}

function* disconnectCall(action) {
  try {
    // const callObject = cookie.load("callObject")
    const callObject = JSON.parse(root.localStorage.getItem("callObject"))
    clearInterval(CALL_TASK)
    root.RADIUS.CALLTONE.pause()
    const res = yield call(API.claimUnverifiedLeadAPI, {
      user_id: cookie.load("agentId"),
      event_name: "call_ended",
      entity_id: callObject.referral_id,
      entity: "referral",
    })
    yield root.TwilioDevice.disconnectAll()
    yield put(ClientActions.toggleCallFeedbackModalAction.call(true))
    //console.log(connection)
  } catch (error) {
    yield put(MarketplaceActions.errorInCallAction.call(error))
  }
}

// TWILIO SAGAS ====================== ENDS

function* sendBoardCastAction(action) {
  try {
    root.PageBroadCast.postMessage(action.data)
  } catch (error) {
    console.log(error)
  }
}

function* receiveBroadcast(action) {
  try {
    const agentId = cookie.load("agentId")
    const { location } = root
    if (action.data === "enableCalling") {
      const callingReferralId = cookie.load("callingReferralId")
      // Fetch new leads for marketplace
      const { pathname = "" } = location
      if (pathname.includes("/client/")) {
        root.location.reload()
        return
      }
      yield put(MarketplaceActions.getLeadForMarketplaceAction.request({ agentId }))
      yield put(MarketplaceActions.toggleCallingBlockAction.call(false))
      yield put(MarketplaceActions.toggleLeadDetailsViewAction.call({
        isShowLeadDetailModal: false,
        currentLead: null,
      }))
      // fetch popups
      yield put(AppActions.getPopUpScreenAction.request())
      try {
        const mortgageRes = yield call(getPopupDetailsApi, `?eventType=lead_claim&popupType=mortgage&entityId=${callingReferralId}`)
        if (isSuccess(mortgageRes)) {
          yield put(getPopupDetailsAction.success(mortgageRes.data && mortgageRes.data.response))
          yield put(showPopupAction.call({ showMortgagePopup: true }))
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (action.data === "errorInCall") {
      yield put(MarketplaceActions.getLeadForMarketplaceAction.request({ agentId }))
      yield put(MarketplaceActions.toggleCallingBlockAction.call(false))
      yield put(MarketplaceActions.toggleLeadDetailsViewAction.call({
        isShowLeadDetailModal: false,
        currentLead: null,
      }))
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleAddRemovedNeighbourhood(action) {
  const { data } = action
  const { type, neighbourhood } = data
  let neighbourhoodArray = []
  if (type === "remove") {
    neighbourhoodArray = [...yield select(state => state.marketplace.removedNeighbourhoodsArr), neighbourhood]
  } else {
    neighbourhoodArray = (yield select(state => state.marketplace.removedNeighbourhoodsArr)).map(item => item !== data)
  }
  yield put(MarketplaceActions.handleRemovedNeighbourhoodAction.call(neighbourhoodArray))
}

function* handleNeighbourhoodStore(action) {
  const { data } = action
  const { type, neighbourhood } = data
  let neighbourhoodArray = []
  switch (type) {
    case "remove":
      neighbourhoodArray = (yield select(state => state.marketplace.neighbourhoodStore))
        .filter(item => item !== neighbourhood)
      break
    case "add":
      neighbourhoodArray = [...yield select(state => state.marketplace.neighbourhoodStore), neighbourhood]
      break
    case "city_remove":
      neighbourhoodArray = []
      break
    default:
      neighbourhoodArray = []
      break
  }
  yield put(MarketplaceActions.handleDispatchedNeighbourhoodAction.call(neighbourhoodArray))
}

function* handleClaimBlocker(action) {
  try {
    const {
      offset,
      limit,
    } = action.data
    const res = yield call(API.getClaimBlockerAPI, offset, limit)
    if (isSuccess(res)) {
      const { response } = res.data
      //yield put(MarketplaceActions.toggleClaimBlockerModalAction.call(referrals_count > 0))
      yield put(MarketplaceActions.getClaimBlockerAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.getClaimBlockerAction.failure(error))
  }
}

function* handleSaveNote(action) {
  try {
    const {
      sliderRef,
      activeClaimIndex,
    } = action.data
    const formSelector = formValueSelector("CLAIM_BLOCKER_NOTES_FORM")
    const state = yield select(store => store)
    const note = formSelector(state, "note")
    if (!note || (note && note.length < 4)) {
      throw new Error("Please provide an update regarding this lead.")
    }
    const clientId = formSelector(state, "clientId")
    const claimBlockerPendings = get(state, "marketplace.claimBlockerResponse.data.referrals.length")
    const notesRes = yield call(addNoteAPI, clientId, { note })
    if (isSuccess(notesRes)) {
      const { response } = notesRes.data
      const newIndex = activeClaimIndex + 1
      yield put(MarketplaceActions.changeActiveBlockClaim.call(newIndex))
      sliderRef.slickGoTo(newIndex)
      yield put(MarketplaceActions.saveReferralNoteAction.success(response))
      if (newIndex >= claimBlockerPendings) {
        const agentId = cookie.load("agentId")
        yield put(MarketplaceActions.toggleClaimBlockerModalAction.call(false))
        yield put(MarketplaceActions.getLeadForMarketplaceAction.request({
          agentId,
        }))
        yield put(MarketplaceActions.getClaimBlockerAction.request({ limit: 200, offset: 0 }))
      }
    }
  } catch (error) {
    yield put(MarketplaceActions.saveReferralNoteAction.failure(error))
  }
}

function* handleClaimBlockerToggle() {
  yield put(ReferralActions.forceCloseProspectModalAction.call())
}

function* handleLMPViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(API.lmpViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.lmpViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.lmpViewedAction.failure(error))
  }
}

function* handleLMPLeadDeletion(action) {
  try {
    const {
      referralId,
      agentId,
    } = action.data
    const res = yield call(API.deleteLMPLeadAPI, referralId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.deleteMarketplaceLeadAction.success(response))
      yield put(MarketplaceActions.toggleLeadDeletionPromptModalAction.call({
        idToBeDeleted: "",
        isLeadDeletionModalVisible: false,
      }))
      yield put(MarketplaceActions.getLeadForMarketplaceAction.request({
        agentId,
      }))
    }
  } catch (error) {
    yield put(MarketplaceActions.deleteMarketplaceLeadAction.failure(error))
  }
}

function* handleLeadsViewed(action) {
  try {
    const {
      id,
    } = action.data
    const payload = {
      ids: [id],
    }
    const res = yield call(API.leadViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.leadViewedAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.leadViewedAction.failure(error))
  }
}

function* handleFetchPostFeedbackCallOptions() {
  try {
    const res = yield call(API.postCallFeedbackAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(MarketplaceActions.getPostCallFeedbackOptionsAction.success(response))
    }
  } catch (error) {
    yield put(MarketplaceActions.getPostCallFeedbackOptionsAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(MarketplaceActions.getLeadForMarketplaceAction.REQUEST, getLeadForMarketplace)
  yield takeLatest(MarketplaceActions.sendDownloadLinkToPhoneAction.REQUEST, sendDownloadLinkToPhone)
  yield takeLatest(MarketplaceActions.fetchRecentlyClaimedLeadsAction.REQUEST, fetchRecentlyClaimedLeads)
  yield takeLatest(MarketplaceActions.fetchUserPreferranceAction.REQUEST, fetchUserPreferrance)
  yield takeLatest(MarketplaceActions.savePreferenceAction.REQUEST, savePreference)
  yield takeLatest(MarketplaceActions.claimUnverifiedLeadAction.REQUEST, claimUnverifiedLead)
  yield takeLatest(MarketplaceActions.selectCityInPreferenceAction.type, selectCityInPreference)
  yield takeLatest(MarketplaceActions.getAgentNumberForCallingAction.REQUEST, getAgentNumberForCalling)
  yield takeLatest(MarketplaceActions.getClaimBlockerAction.REQUEST, handleClaimBlocker)
  yield takeLatest(MarketplaceActions.saveReferralNoteAction.REQUEST, handleSaveNote)
  // Twilio Action
  yield takeLatest(MarketplaceActions.connectCallAction.type, connectCall)
  yield takeLatest(MarketplaceActions.disconnectCallAction.type, disconnectCall)
  yield takeLatest(MarketplaceActions.sendBroadcastAction.type, sendBoardCastAction)
  yield takeLatest(MarketplaceActions.receiveBroadcastAction.type, receiveBroadcast)
  // Preferences Actions
  yield takeLatest(MarketplaceActions.removeNeighbourhoodAction.type, handleAddRemovedNeighbourhood)
  yield takeLatest(MarketplaceActions.handleNeighbourhoodStoreAction.type, handleNeighbourhoodStore)
  yield takeLatest(MarketplaceActions.toggleClaimBlockerModalAction.type, handleClaimBlockerToggle)
  yield takeLatest(MarketplaceActions.deleteMarketplaceLeadAction.REQUEST, handleLMPLeadDeletion)
  yield takeLatest(MarketplaceActions.leadViewedAction.REQUEST, handleLeadsViewed)

  //view SAGA
  yield takeLatest(MarketplaceActions.lmpViewedAction.REQUEST, handleLMPViewed)
  yield takeLatest(MarketplaceActions.getPostCallFeedbackOptionsAction.REQUEST, handleFetchPostFeedbackCallOptions)
}
