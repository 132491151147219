import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { scrollToElementAction, toggleApplyModalAction, toggleHubspotMeetingModalAction } from "container/App/actions"
import * as RealtyActions from "./actions"

const mapStateToProps = (state) => {
  const { realty, navbar, app } = state
  const {
    user,
    isShowApplyModal,
  } = app
  const {
    currentState,
    currentRARPlanSelected,
    isAgentType,
  } = realty
  const { isOpenPressReleaseBanner } = navbar
  return {
    ...realty,
    isAgentType,
    currentRARPlanSelected,
    user,
    currentState,
    isOpenPressReleaseBanner,
    isShowApplyModal,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  submitNewsLetter: RealtyActions.submitNewsLetterAction.request,
  submitScheduleACall: RealtyActions.submitScheduleACallAction.request,
  pageTracker: RealtyActions.pageTrackerAction.request,
  setActiveNavbarSection: RealtyActions.setActiveNavbarSectionAction.call,
  // toggleVideoModal: RealtyActions.toggleVideoModalAction.call,
  toggleHubSpotModal: RealtyActions.toggleHubSpotModalAction.call,
  scrollToElement: scrollToElementAction.call,
  setCurrentLocationForRealty: RealtyActions.setCurrentLocationAction.call,
  brokerageFeatureViewed: RealtyActions.brokerageFeatureViewedAction.request,
  showReferralManagerV2: RealtyActions.showReferralManagerV2Action.call,
  setCurrentRARPlanType: RealtyActions.togglePlanTypeAction.call,
  RARTracker: RealtyActions.RARTrackerAction.request,
  storeRARPlanSelected: RealtyActions.storeRARPlanSelectedAction.call,
  storeTeamSize: RealtyActions.storeTeamSizeAction.call,
  toggleRARModal: RealtyActions.rarFormModalToggleAction.call,
  toggleApplyModal: toggleApplyModalAction.call,
  toggleHubspotMeetingModal: toggleHubspotMeetingModalAction.call,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
