import root from "window-or-global"
import {
  get,
  uniq,
  map,
  filter,
} from "lodash"
import deepmerge from "deepmerge"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import {
  updateFeedLikeAction,
  reportOrDeleteAction
} from "container/Network/actions"
import {
  OPEN_EDIT_PROFILE_MODAL,
  CLOSE_EDIT_PROFILE_MODAL,
  UPDATE_AGENT_PROFILE_INFO,
  MLS_STATE_CHANGE,
  SKIP_PROFILE_COMPLETION_STEP,
  saveProfileUpdateAction,
  fetchSpecializationsAction,
  uploadProfileImageAction,
  getFollowersAndFollowingAction,
  getNeighbourhoodByCityAction,
  getPropertyStatusAction,
  getPropertyTypesAction,
  uploadPropertyImagesAction,
  getProfilecompletionAction,
  fetchUserProfileInfoAction,
  fetchBuyerNeedsAction,
  unfollowAgentAction,
  followAgentAction,
  addCityAction,
  addBuyerNeedsAction,
  saveAddListingAction,
  getOwnFollowerAndFollowingAction,
  getMLSServingListsAction,
  syncMLSAction,
  removeSyncedMlsAction,
  fetchCityNeighborhoodServedAction,
  fetchUserProfileAction,
  fetchMoreFeedsAction,
  removeCityServedCityAction,
  removeCityServedNeighbourhoodAction,
  removeBuyerNeedsAction,
  getGoalsAction,
  removeCityAction,
  submitOnboardingFormAction,
  CLEAR_PROFILE,
  sendGoalsAction,
  getAgentFeedsAction,
  feedErrorFetchAction,
  profileViewedAction,
  unblockUserAction,
  clearPropertyImagesAction,
} from "./action"

const initState = {
  ...get(root, "__STATE__.profile", {}),
  isShowEditModal: false,
  initialValuesForm: null,
  isSavingUpdates: false,
  isFetchingFeeds: false,
  isFetchingNeighbourhood: false,
  isUploadingPropertyImages: false,
  isFetchingProfileCompletion: true,
  isShowFollowLoader: false,
  preFetchedData: {},
  isSyncingMls: false,
  isRemovingMls: false,
  isFetchingMls: false,
  mlsStates: [],
  mlsLists: [],
  filteredMlsLists: [],
  goals: [],
  activeNextStepIndex: 0,
  isRemovingCityServedCity: false,
  isRemovingCityServedNeighbourhood: true,
  errorResponse: null,
  hasError: true,
  isGoalsSent: false,
  isSendingGoals: false,
  isUnauthorized: false,
  ...createDeltaReducer("onboardingFormSubmit"),
  ...createDeltaReducer("feedsResponse"),
  ...createDeltaReducer("profileViewedResponse"),
  ...createDeltaReducer("unblockResponse"),
}

export default function referralReducer(state = initState, action) {
  switch (action.type) {
    case fetchUserProfileInfoAction.FAILURE:
    case fetchUserProfileInfoAction.SUCCESS: {
      return {
        ...state,
        ...action.data.profile,
      }
    }
    case OPEN_EDIT_PROFILE_MODAL: {
      const {
        type,
        initialValues,
        activeTab,
        ...props
      } = action.data
      return {
        ...state,
        isShowEditModal: type,
        initialValuesForm: initialValues,
        activeTab,
        ...props,
      }
    }

    case SKIP_PROFILE_COMPLETION_STEP: {
      return {
        ...state,
        activeNextStepIndex: action.data,
      }
    }

    case CLOSE_EDIT_PROFILE_MODAL: {
      return {
        ...state,
        isShowEditModal: false,
        initialValuesForm: null,
        isProfileCompletion: false,
        activeNextStepIndex: 0,
      }
    }

    case UPDATE_AGENT_PROFILE_INFO: {
      return {
        ...state,
        agent: { ...action.data },
      }
    }
    // Save Profile Update
    case saveProfileUpdateAction.REQUEST: {
      return {
        ...state,
        isSavingUpdates: true,
      }
    }
    case saveProfileUpdateAction.SUCCESS: {
      return {
        ...state,
        isSavingUpdates: false,
        errorResponse: null

      }
    }
    case saveProfileUpdateAction.FAILURE: {
      return {
        ...state,
        isSavingUpdates: false,
        errorResponse: action.data
      }
    }
    // Save Fetch Speci
    case fetchSpecializationsAction.REQUEST: {
      return {
        ...state,
      }
    }
    case fetchSpecializationsAction.FAILURE:
    case fetchSpecializationsAction.SUCCESS: {
      return {
        ...state,
        preFetchedData: {
          ...action.data,
        },
      }
    }
    // get followers
    case getFollowersAndFollowingAction.REQUEST: {
      return {
        ...state,
        isFetchingFollowersAndFollowing: true,
      }
    }
    case getFollowersAndFollowingAction.SUCCESS:
    case getFollowersAndFollowingAction.FAILURE: {
      return {
        ...state,
        ...action.data,
        isFetchingFollowersAndFollowing: false,
      }
    }
    // Get Neighbourhood API
    case getNeighbourhoodByCityAction.REQUEST: {
      const loadingState = {}
      const { isFetchingMore } = action.data
      if (isFetchingMore) {
        loadingState.isFetchingMoreNeighbourhood = true
        loadingState.isFetchingNeighbourhood = false
      } else {
        loadingState.isFetchingNeighbourhood = false
        loadingState.isFetchingMoreNeighbourhood = true
      }
      return {
        ...state,
        ...loadingState,
      }
    }
    case getNeighbourhoodByCityAction.FAILURE:
    case getNeighbourhoodByCityAction.SUCCESS: {
      const { neighbourhoods } = action.data
      return {
        ...state,
        isFetchingNeighbourhood: false,
        isFetchingMoreNeighbourhood: false,
        neighbourhoods,
      }
    }

    // Get Property Status
    case getPropertyStatusAction.SUCCESS:
    case getPropertyStatusAction.FAILURE: {
      const { statuses } = action.data
      return {
        ...state,
        preFetchedStatus: statuses,
      }
    }
    // Get Type Status
    case getPropertyTypesAction.SUCCESS:
    case getPropertyTypesAction.FAILURE: {
      const { types } = action.data
      return {
        ...state,
        preFetchedTypes: types,
      }
    }

    // Image Profile Upload
    case uploadProfileImageAction.REQUEST: {
      return {
        ...state,
        isSavingUpdates: true,
      }
    }
    case uploadProfileImageAction.SUCCESS:
    case uploadProfileImageAction.FAILURE: {
      return {
        ...state,
        isSavingUpdates: false,
      }
    }

    // Property Image Uplaod
    case uploadPropertyImagesAction.REQUEST:
      return {
        ...state,
        isUploadingPropertyImages: true,
      }
    case uploadPropertyImagesAction.FAILURE:
    case uploadPropertyImagesAction.SUCCESS: {
      const existingPropertyImages = state.propertyImages || []
      return {
        ...state,
        isUploadingPropertyImages: false,
        propertyImages: [...existingPropertyImages, ...action.data],
      }
    }

    case clearPropertyImagesAction.type: {
      return {
        ...state,
        propertyImages: [],
      }
    }

    // Get Profile
    case getProfilecompletionAction.REQUEST: {
      return {
        ...state,
        isFetchingProfileCompletion: true,
      }
    }

    case getProfilecompletionAction.FAILURE:
    case getProfilecompletionAction.SUCCESS: {
      return {
        ...state,
        isFetchingProfileCompletion: false,
        profileCompletion: action.data,
      }
    }

    // Buyer Needs
    case fetchBuyerNeedsAction.REQUEST: {
      return {
        ...state,
        isFetchingBuyerNeeds: true,
      }
    }
    case fetchBuyerNeedsAction.FAILURE:
    case fetchBuyerNeedsAction.SUCCESS: {
      const { publicRequirements = [] } = action.data
      const modifiedpayload = publicRequirements
                              && publicRequirements.length > 0
                              && publicRequirements.map((item) => {
                                const { types } = item
                                return {
                                  ...item,
                                  types: types.map(x => ({ value: x, label: x })),
                                }
                              })
      return {
        ...state,
        isFetchingBuyerNeeds: false,
        buyerNeeds: publicRequirements ? [...modifiedpayload] : [],
      }
    }

    // Unfollow API
    case unfollowAgentAction.REQUEST:
      return {
        ...state,
        isShowFollowLoader: action.data.unfollowId,
      }
    case unfollowAgentAction.SUCCESS:
    case unfollowAgentAction.FAILURE:
      return {
        ...state,
        isShowFollowLoader: false,
      }
    // AddCity
    case addCityAction.SUCCESS:
    case addCityAction.FAILURE:
      return {
        ...state,
        addCityResponse: action.data,
      }

    // Get Own Followers and Following
    case getOwnFollowerAndFollowingAction.SUCCESS:
    case getOwnFollowerAndFollowingAction.FAILURE:
      return {
        ...state,
        ownFollowersAndFollowing: action.data,
      }

    // Follow an agent
    case followAgentAction.REQUEST:
      return {
        ...state,
        isShowFollowLoader: action.data.id,
      }
    case followAgentAction.FAILURE:
    case followAgentAction.SUCCESS:
      return {
        ...state,
        isShowFollowLoader: false,
      }
    // Add Buyer needs
    case addBuyerNeedsAction.REQUEST: {
      return {
        ...state,
        isSavingUpdates: true,
      }
    }
    case addBuyerNeedsAction.FAILURE:
    case addBuyerNeedsAction.SUCCESS: {
      return {
        ...state,
        isSavingUpdates: false,
        addBuyerListRes: action.data,
      }
    }

    // Save Add Listing
    case saveAddListingAction.REQUEST: {
      return {
        ...state,
        isSavingUpdates: true,
      }
    }
    case saveAddListingAction.FAILURE:
    case saveAddListingAction.SUCCESS: {
      return {
        ...state,
        isSavingUpdates: false,
      }
    }

    case getMLSServingListsAction.SUCCESS:
    case getMLSServingListsAction.FAILURE: {
      const { mls } = action.data
      const mlsStates = []
      const stateOptions = uniq(map(mls, "state"))
      stateOptions.map(mlsState => mlsStates.push({ label: mlsState, value: mlsState }))
      return {
        ...state,
        mlsLists: mls,
        mlsStates,
      }
    }

    case feedErrorFetchAction.type: {
      return {
        ...state,
        isUnauthorized: true,
      }
    }

    case syncMLSAction.REQUEST: {
      return {
        ...state,
        isSyncingMls: true,
        MLSSyncError: null,
      }
    }

    case syncMLSAction.SUCCESS:
    case syncMLSAction.FAILURE: {
      const errorMessage = get(action.data, "data.error.message")
      return {
        ...state,
        isSyncingMls: false,
        MLSSyncError: errorMessage,
      }
    }

    case removeSyncedMlsAction.REQUEST: {
      const { mlsId } = action.data
      return {
        ...state,
        isRemovingMls: mlsId,
        isSavingUpdates: true,
      }
    }

    case removeSyncedMlsAction.SUCCESS: {
      const { mlsId } = action.data
      return {
        ...state,
        agent: {
          ...state.agent,
          mlsInfo: filter(state.agent.mlsInfo, mls => mls.id !== mlsId),
        },
        isRemovingMls: false,
        isSavingUpdates: false,
      }
    }

    case removeSyncedMlsAction.FAILURE: {
      return {
        ...state,
        isRemovingMls: false,
        isSavingUpdates: false,
      }
    }

    case MLS_STATE_CHANGE: {
      const { mlsState } = action.data
      const { mlsLists } = state
      const filteredMlsLists = []
      filter(mlsLists, { state: mlsState })
        .map(mlsItem => filteredMlsLists.push({
          label: mlsItem.name,
          value: mlsItem.id,
          license_state: mlsItem.state_short_name,
        }))
      if (mlsState) {
        return {
          ...state,
          filteredMlsLists,
        }
      }
      return state
    }

    // Fetch neighborhood saved
    case fetchCityNeighborhoodServedAction.REQUEST: {
      return {
        ...state,
        isLoadingProperties: true,
      }
    }
    case fetchCityNeighborhoodServedAction.FAILURE:
    case fetchCityNeighborhoodServedAction.SUCCESS: {
      return {
        ...state,
        properties: action.data,
      }
    }

    // Fetch User info
    case fetchUserProfileAction.FAILURE:
    case fetchUserProfileAction.SUCCESS: {
      return {
        ...state,
        agent: action.data,
      }
    }

    // fetch more Feeds
    case fetchMoreFeedsAction.REQUEST: {
      return {
        ...state,
        isFetchingFeeds: true,
      }
    }
    case fetchMoreFeedsAction.FAILURE: {
      return {
        ...state,
        isFetchingFeeds: false,
      }
    }
    case fetchMoreFeedsAction.SUCCESS: {
      const { feeds } = state
      const isFetchedAllFeeds = get(action.data, "activities.networkActivities.length") === 0
      return {
        ...state,
        isFetchingFeeds: false,
        feeds: deepmerge(feeds, action.data),
        isFetchedAllFeeds,
      }
    }

    // Remove city (City Served)
    case removeCityServedCityAction.REQUEST: {
      const { id } = action.data
      return {
        ...state,
        isRemovingCityServedCity: id,
      }
    }
    case removeCityServedCityAction.FAILURE:
    case removeCityServedCityAction.SUCCESS: {
      return {
        ...state,
        isRemovingCityServedCity: false,
      }
    }

    // Remove Neighbourhood (City Served)
    case removeCityServedNeighbourhoodAction.REQUEST: {
      const { id } = action.data
      return {
        ...state,
        isRemovingCityServedCity: id,
      }
    }
    case removeCityServedNeighbourhoodAction.FAILURE:
    case removeCityServedNeighbourhoodAction.SUCCESS: {
      return {
        ...state,
        isRemovingCityServedNeighbourhood: false,
      }
    }

    // Remove Buyer Needs
    case removeBuyerNeedsAction.REQUEST: {
      return {
        ...state,
        isRemovingBuyerNeeds: true,
      }
    }

    case removeBuyerNeedsAction.FAILURE:
    case removeBuyerNeedsAction.SUCCESS: {
      return {
        ...state,
        isRemovingBuyerNeeds: false,
      }
    }

    case getGoalsAction.REQUEST: {
      return {
        ...state,
        isFetchingGoals: true,
      }
    }

    case getGoalsAction.SUCCESS: {
      const { data } = action.data
      const { response } = data
      return {
        ...state,
        isFetchingGoals: false,
        goals: response,
      }
    }

    case getGoalsAction.FAILURE: {
      return {
        ...state,
        isFetchingGoals: false,
      }
    }

    case sendGoalsAction.FAILURE: {
      return {
        ...state,
        isGoalsSent: false,
        hasError: true,
      }
    }

    case sendGoalsAction.REQUEST: {
      return {
        ...state,
        isSendingGoals: true,
      }
    }

    case sendGoalsAction.SUCCESS: {
      return {
        ...state,
        isGoalsSent: true,
        hasError: false,
        isSendingGoals: false,
      }
    }

    case removeCityAction.SUCCESS: {
      return {
        ...state,
        neighbourhoods: [],
      }
    }

    case submitOnboardingFormAction.SUCCESS:
    case submitOnboardingFormAction.FAILURE:
    case submitOnboardingFormAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          submitOnboardingFormAction,
          "onboardingFormSubmit"
        ),
      }
    }

    case unblockUserAction.SUCCESS:
    case unblockUserAction.FAILURE:
    case unblockUserAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          unblockUserAction,
          "unblockResponse"
        ),
      }
    }

    case getAgentFeedsAction.SUCCESS:
    case getAgentFeedsAction.FAILURE:
    case getAgentFeedsAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          getAgentFeedsAction,
          "feedsResponse"
        ),
      }
    }

    case profileViewedAction.SUCCESS:
    case profileViewedAction.FAILURE:
    case profileViewedAction.REQUEST: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          profileViewedAction,
          "profileViewedResponse"
        ),
      }
    }

    case updateFeedLikeAction.REQUEST: {
      const { id, isOnProfile } = action.data
      if (isOnProfile) {
        const feeds = get(state, "feedsResponse.data.feed")
        const feedIndex = feeds.findIndex(feed => feed.id === id)
        const selectedFeed = feeds[feedIndex]
        if (selectedFeed.likedByMe) {
          feeds[feedIndex].likedByMe = false
          feeds[feedIndex].likesCount -= 1
        } else {
          feeds[feedIndex].likedByMe = true
          feeds[feedIndex].likesCount += 1
        }
        return {
          ...state,
          feedsResponse: {
            ...state.feedsResponse,
            data: {
              ...state.feedsResponse.data,
              feed: feeds,
            },
          },
        }
      }
      return {
        ...state,
      }
    }

    case reportOrDeleteAction.SUCCESS: {
      const { pathname } = root.location
      const { feedId, newFeeds } = action.data
      if (pathname.includes(pathname) && feedId) {
        const feeds = get(state, "feedsResponse.data.feed") || newFeeds
        const feedIndex = feeds.findIndex((feed) => {
          console.log(feed)
          return feed.id === feedId
        })
        feeds.splice(feedIndex, 1)
        return {
          ...state,
          feedsResponse: {
            ...state.feedsResponse,
            data: {
              ...state.feedsResponse.data,
              feed: feeds,
            },
          },
        }
      }
      return {
        ...state,
      }
    }

    // Clear Profile
    case CLEAR_PROFILE: {
      return {
        ...state,
        agent: {},
        ...createDeltaReducer("feedsResponse"),
      }
    }
    default:
      return state
  }
}
