import React from "react"
import styled from "styled-components"
import {
  convertUnicode,
} from "services/Utils"

const Span = styled.span`
  color: #11adf3;
  cursor: pointer;
  margin-left: 10px;
`

const Wrap = styled.span`
  font-weight: 300;
  display: block;
`

class SeeMore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowMore: false,
    }
  }

  handleShowMore = () => {
    const { isShowMore } = this.state
    this.setState({
      isShowMore: !isShowMore,
    })
  }

  render() {
    const { text, charLimit } = this.props
    const { isShowMore } = this.state
    const finalText = isShowMore ? text : text.substring(0, charLimit)
    return (
      <Wrap>
        {finalText.split("\n").map((item, key) => <p key={key}>{convertUnicode(item)}</p>)}
        {text.length > charLimit && !isShowMore ? "..." : ""}
        { text.length > charLimit && (
          <Span onClick={this.handleShowMore}>
            {isShowMore ? "Show Less" : "Show More"}
          </Span>
        )}
      </Wrap>
    )
  }
}

SeeMore.defaultProps = {
  text: "",
  charLimit: 200,
}

export default SeeMore
