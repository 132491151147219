import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { fetchAgentSearchAction } from "container/Referral/actions"
import { joinRoomAsGuestDetailsAction } from "container/EventRoom/actions"
import { setCurrentImageToBeCroppedAction, uploadImagesToS3Action } from "container/Realty/actions"
import { getFormValues } from "redux-form"
import * as RoomsActions from "./actions"
import * as RoomsSelectors from "./selector"
import { convertToRichText } from "../Academy/selector"

const mapStateToProps = (state, ownProps) => {
  const { rooms, app, referral, teamProfileReducer,
  } = state
  const { user } = app
  const { fetchAgentSearchResponse } = referral
  const { currentImageToBeCropped, uploadTeamImageToS3Response } = teamProfileReducer
  const scheduleRoomFormValues = getFormValues("SCHEDULE_ROOMS_DETAILS_FORM")(state)

  const isHost = RoomsSelectors.getIsHost(state)
  const isOverallFailure = RoomsSelectors.getFailureState(state)
  const { publicVideoRoomDetails: publicVideoRoomDetailsServer } = (ownProps && ownProps.staticContext) || {}
  const {
    publicPreRecordedRoomDetails: publicPreRecordedRoomDetailsServer,
  } = (ownProps && ownProps.staticContext) || {}

  return {
    ...rooms,
    ...publicVideoRoomDetailsServer,
    ...publicPreRecordedRoomDetailsServer,
    user,
    isHost,
    isOverallFailure,
    fetchAgentSearchResponse,
    currentImageToBeCropped,
    uploadTeamImageToS3Response,
    scheduleRoomFormValues,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      addUserToStore: RoomsActions.userCountAction.call,
      removerUserFromStore: RoomsActions.removeUserIdAction.call,
      getRooms: RoomsActions.getRoomsActions.request,
      toggleCreateRoomModal: RoomsActions.toggleCreateRoomModalAction.call,
      getToken: RoomsActions.getTokenAction.request,
      joinRoom: RoomsActions.joinRoomsActions.request,
      createRoom: RoomsActions.createRoomsActions.request,
      initLiveRoom: RoomsActions.initLiveRoomAction.call,
      toggleEndRoomModal: RoomsActions.toggleEndRoomModalAction.call,
      toggleSwitchRoomsModal: RoomsActions.toggleSwitchRoomsModalAction.call,
      toggleSwitchRoomsReqModal: RoomsActions.toggleSwitchHostsReqModalAction.call,
      toggleSpeakerLimitModal: RoomsActions.toggleSpeakerLimitModalAction.call,
      toggleInviteAgentToRoomModal: RoomsActions.toggleInviteAgentToRoomModalAction.call,
      toggleShareLinkModal: RoomsActions.toggleShareRoomLinkModalAction.call,
      toggleAgentProfileModal: RoomsActions.toggleAgentProfileModalAction.call,
      toggleSwitchHostsModal: RoomsActions.toggleSwitchHostsModalAction.call,
      toggleSwitchHostsReqModal: RoomsActions.toggleSwitchHostsReqModalAction.call,
      toggleParticipantListDrawer: RoomsActions.toggleParticipantListAction.call,
      toggleRoomInfoDrawer: RoomsActions.toggleRoomInfoAction.call,
      toggleKickUserOutModal: RoomsActions.toggleKickUserOutModalAction.call,
      toggleAttendeesListModal: RoomsActions.toggleAttendeesListModalAction.call,
      setCurrentImageToBeCropped: setCurrentImageToBeCroppedAction.call,
      toggleConfirmSignInModal: RoomsActions.toggleConfirmSignInModalAction.call,
      toggleSwitchDeviceModal: RoomsActions.toggleSwitchDeviceAction.call,
      // toggleMuteIcon: RoomsActions.toggleMuteIconAction.call,
      leaveRoom: RoomsActions.leaveRoomAction.request,
      getSuggestedUsers: RoomsActions.getSuggestedUsersActions.request,
      fetchAgentSearch: fetchAgentSearchAction.request,
      sendUserInvitation: RoomsActions.sendUserInvitationAction.request,
      clearAgents: fetchAgentSearchAction.clear,
      getRoomMembers: RoomsActions.getRoomMembersAction.request,
      getTags: RoomsActions.getTagsAction.request,
      clearTags: RoomsActions.getTagsAction.clear,
      createScheduleRoom: RoomsActions.createScheduleRoomAction.request,
      muteUser: RoomsActions.muteUserAction.request,
      attendeeStatus: RoomsActions.attendeeStatusAction.request,
      kickUserOut: RoomsActions.kickUserOutAction.request,

      addUserToVideoContainer: RoomsActions.addUserToVideoContainerAction.call,

      //ui manipulation actions
      toggleSpotlightView: RoomsActions.toggleSpotlightViewAction.call,
      toggleOtherUsersView: RoomsActions.toggleOtherUsersViewAction.call,
      toggleGridView: RoomsActions.toggleGridViewAction.call,
      toggleSidebarView: RoomsActions.toggleSidebarViewAction.call,
      revertToHostUI: RoomsActions.revertToHostOnlyUIAction.call,
      endRoom: RoomsActions.roomEndAction.call,
      screenResize: RoomsActions.screenResizeAction.call,

      //guest actions
      joinRoomAsGuest: joinRoomAsGuestDetailsAction.request,

      //controls
      viewControls: RoomsActions.setSpeakerPriviledgesAction.call,
      requestControls: RoomsActions.becomeSpeakerAction.request,
      toggleRequestModal: RoomsActions.toggleControlsRequestModalAction.call,
      speakerRequestDetail: RoomsActions.speakerRequestDetailsAction.call,
      toggleVerifySpeakerRequestModal: RoomsActions.toggleVerifySpeakerRequestModalAction.call,
      toggleRemoveSpeakerModal: RoomsActions.toggleRemoveSpeakerModalAction.call,
      speakerRequestVerify: RoomsActions.speakerRequestAction.request,
      toggleMicrophone: RoomsActions.toggleMicrophoneAction.call,
      toggleCamera: RoomsActions.toggleCameraVideoFeedAction.call,
      toggleHoverAudio: RoomsActions.hoverAudioToggleAction.call,
      enableScreenShare: RoomsActions.toggleScreenShareStatusAction.call,
      toggleScreenShareModal: RoomsActions.toggleScreenShareModalAction.call,
      toggleMakeSpeakerModal: RoomsActions.toggleMakeSpeakerModalAction.call,

      //heartbeat
      heartBeat: RoomsActions.userHeartBeatAction.request,

      //sound
      playSound: RoomsActions.playSoundAction.call,

      //pinning
      pinLocally: RoomsActions.pinUserLocallyAction.call,
      localUnpin: RoomsActions.unpinLocalUserAction.call,
      //host transfer
      hostTransferRequest: RoomsActions.hostTransferRequestAction.request,
      hostTransferResponse: RoomsActions.hostTransferResponseAction.request,
      remotePinToggle: RoomsActions.togglePinForAllAction.request,

      //screenShare or uniqueId
      joinAsScreenShare: RoomsActions.joinRoomAsScreenSharerAction.request,
      leaveAsScreenShare: RoomsActions.leaveRoomAsScreenSharerAction.request,

      //other actions
      removeUserElement: RoomsActions.removeUserElementFromScreenAction.call,

      //banner actions
      toggleVideoRoomsBanner: RoomsActions.toggleVideoRoomsBannerAction.call,

      //onboarding
      toggleToLoggedInView: RoomsActions.toggleToLoggedInViewAction.call,

      //joyride
      triggerJoyRideForVideoRoomsControls: RoomsActions.triggerJoyRideForVideoRoomsControlsAction.call,
      triggerJoyRideForVideoRoomsListener: RoomsActions.triggerJoyRideForVideoRoomsListenerAction.call,

      //permissions
      togglePermissionsModal: RoomsActions.togglePermissionModalAction.call,

      //Onboarding
      setUserOnboarded: RoomsActions.setUserOnboardStatusAction.call,

      //ScheduledRooms
      notifyForRoom: RoomsActions.notifyForScheduledRoomsAction.request,
      getScheduleRooms: RoomsActions.getScheduleRoomsAction.request,

      //bulk invite
      bulkInvite: RoomsActions.bulkInviteBasedOnLocationAction.request,

      //update scheduled rooms
      updateScheduledRooms: RoomsActions.updateScheduledRoomsAction.request,
      switchRegister: RoomsActions.switchUIScheduleRoomsAction.call,
      switchLoggedOutView: RoomsActions.switchUItoLoggedOutViewAction.call,
      cancelScheduledRooms: RoomsActions.cancelScheduledRoomsAction.request,

      //screen share store
      updateScreenShareStore: RoomsActions.updateScreenShareStoreAction.call,
      removeScreenShareFromStore: RoomsActions.removeIDScreenShareStoreAction.call,
      createRoomPageSwitch: RoomsActions.createRoomPageSwitchAction.call,

      //form related
      updateForm: RoomsActions.handleCreateRoomFormChangeAction.call,
      toggleImageUploadModal: RoomsActions.toggleImageUploadModalAction.call,
      getDefaultImages: RoomsActions.getDefaultImagesAction.request,
      setImage: RoomsActions.setImageUrlAction.call,
      clearImage: RoomsActions.clearFormImageAction.call,
      uploadImage: RoomsActions.uploadImageToS3Action.request,
      toggleInviteSpeakersModal: RoomsActions.toggleInviteSpeakersModalAction.call,
      toggleInviteAttendeesModal: RoomsActions.toggleInviteAttendeesModalAction.call,
      createPayload: RoomsActions.updatePayloadAction.call,
      clearPayload: RoomsActions.clearCreatePayloadAction.call,
      clearForms: RoomsActions.clearAndResetAllForms.call,
      agentResponseClear: fetchAgentSearchAction.clear,

      //image edit
      uploadToS3: uploadImagesToS3Action.request,

      //landing page
      getRoomTopics: RoomsActions.getRoomTopicsAction.request,
      getRoomTopicEvents: RoomsActions.getRoomTopicEventsAction.request,
      getPromotedRooms: RoomsActions.getPromotedRoomsAction.request,

      //academy
      getPreRecordedContentDetails: RoomsActions.getPreRecordedRoomsContentAction.request,

      //rooms_recording
      toggleScreenRecordModal: RoomsActions.toggleScreenRecordModalAction.call,
      acquireRoomRecording: RoomsActions.acquireRoomRecordingAction.request,
      storeToken: RoomsActions.storeTokenAction.call,
      //delete
      deleteScheduledRoom: RoomsActions.deleteScheduledRoomsAction.request,
      toggleDeleteRoomModal: RoomsActions.toggleDeleteRoomModalAction.call,

      joinRoomAsScreenRecord: RoomsActions.joinRoomAsScreenRecordAction.request,
      leaveRoomAsScreenRecord: RoomsActions.leaveRoomAsScreenRecordAction.request,
      toggleRecordingSessionPrompt: RoomsActions.toggleRecordingSessionPromptAction.call,
      startRoomsRecording: RoomsActions.startRoomsRecordAction.request,
      stopRoomsRecording: RoomsActions.stopRoomsRecordAction.request,
      updateRecordingLayout: RoomsActions.updateRecordingLayoutAction.request,
      updateSubscriptionList: RoomsActions.updateSubscriptionListAction.request,
      clearRecordState: RoomsActions.clearRecordingStateAction.call,
      updateUnsubscribedIds: RoomsActions.createUnSubscribeIDArrayAction.call,
      toggleStopRecordingModal: RoomsActions.toggleStopRecordingModalAction.call,

      //meta data
      getRoomsMetaDataConfig: RoomsActions.getRoomsMetaDataObjectsAction.request,
      getPromotedRecommendedRooms: RoomsActions.getPromotedRecommendedRoomsAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
