import React, { PureComponent } from "react"
import styled from "styled-components"
import { supressPopups, getArePopupsSupressed } from "shared/utils"
import Modal from "@ui/Modal"
import colors from "@colors"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import TCContainer from "container/Realty/TransactionFormContainer"
import Forms from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/Forms"
import FinishModal from "dumbComponents/TransactionCoordinationV2/CommissionsPopup/Forms/FinishModal"

import { ATC } from "@fonts"

const Wrap = styled.div`

`

const Header = styled.div`
  font-family: ${ATC};
  font-style: normal;
  margin-left: 15px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const ProgressBar = styled.div`
  width: 450px;
  border: 2px solid #E5E5E5;
  margin: 15px 4px;
  ${props => props.completed && `
    border: 2px solid #3B60E4;
  `}
`
const ProgressBarWrap = styled.div`
  margin: 10px 15px 0px 15px;
  display: flex;
  justify-content: center;
`

const HeaderContainer = styled.div`

`

class CommissionsPopup extends PureComponent {
  render() {
    const { toggleCommissionsPopup, toggleCommissionsPopupStep } = this.props
    const arePopupsSupressed = getArePopupsSupressed("commissionsPopup")

    if (arePopupsSupressed) {
      return <></>
    }

    return (
      <Wrap>
        <Modal
          show
          toClose={() => { 
            toggleCommissionsPopup(0)
            supressPopups("commissionsPopup")
          }}
          modalVersion={3}
          closeIcon={CloseIcon}
          showCloseButton={toggleCommissionsPopupStep !== 3}
          width={toggleCommissionsPopupStep === 3 ? "600px" : "950px"}
          modalStyles={{
            marginTop: 0,
            padding: 0,
            boxShadow: "none",
          }}
          customDialogColor={colors.black}
          zIndex={1}
          dialogStyles={{
            background: toggleCommissionsPopupStep === 3 ? "#3B60E4" : "#F9F9F7",
            borderRadius: "0px",
            minHeight: toggleCommissionsPopupStep === 3 ? "400px" : "600px",
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "28px",
            lineHeight: "32px",
            textAlign: "center",
            color: colors.black,
          }}
          iconStyles={{
            padding: "8px",
            borderRadius: "18px",
            fontSize: "10px",
            right: "20px",
            width: "40px",
            margin: "6px",
          }}
        >
          {toggleCommissionsPopupStep === 3 ? (
            <>
              <FinishModal />
            </>
          ) : (
            <>
              <HeaderContainer>
                <Header>
                  <h1>Processing your Commissions</h1>
                </Header>
                <ProgressBarWrap>
                  <ProgressBar completed={toggleCommissionsPopupStep >= 1} />
                  <ProgressBar completed={toggleCommissionsPopupStep >= 2} />
                </ProgressBarWrap>
              </HeaderContainer>
              <Forms />
            </>
          )}
        </Modal>
      </Wrap>
    )
  }
}

export default TCContainer(CommissionsPopup)
