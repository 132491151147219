import styled from "styled-components";
import colors from "@colors";
import { Modal } from "antd";

const CarouselWrap = styled.div`
  position: relative;
  border-radius: 17px;
  padding: 8px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    margin-top: 0px;
    border: none;
    padding: 0;
    border-radius: 0;
  }

  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .ant-image-preview-operations-operation:not(:first-child) {
    display: none;
  }

  .ant-image-preview-operations {
    margin-top: 20px;
  }
`;

const CustomOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${colors.white};
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  background: rgba(41, 42, 55, 0.75);
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 12px;
  @media (max-width: 1024px) {
    border-radius: 10px;
    width: 100vw;
  }
`;

const StyledImage = styled.img`
  margin: "5px 0";
  transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  @media (max-width: 1024px) {
    height: 291px;
  }
  @media (max-width: 768px) {
    width: 400px !important;
    height: 291px;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(50%);
  }
`;

const StyledImageMobile = styled.img`
  margin: "5px 0";
  @media (max-width: 1024px) {
    height: 291px;
  }
  @media (max-width: 768px) {
    height: 291px;
  }
`;

const StyledImageCenter = styled.img`
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  @media (max-width: 1024px) {
    width: 100vw;
    max-width: 100%;
    height: 291px;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(50%);
  }
`;

const ImageGrid = styled.div`
  position: relative;
  margin-right: 5px;
`;

const WebImageGrid = styled.div`
  // maxHeight: 400px;
  margin-left: 5px;
`;

const DaysOnMarketPill = styled.div`
  position: absolute;
  // top: 20px;
  // left: 60px;
  width: 157px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 15px;
  background-color: #00000099;
  z-index: 10;
  color: white;

  p {
    margin: 0;
  }
`;

const DaysOnMarketPillExtended = styled.div`
  position: absolute;
  width: 157px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 530px;
  margin-right: 1150px;
  background-color: #00000099;
  z-index: 10;
  color: white;

  p {
    margin: 0px;
  }
`;

const OpenAllImagesBtn = styled.button`
  position: absolute;
  cursor: pointer;
  bottom: 20px;
  right: 60px;
  // right: 0;
  // bottom: 0;
  width: 150px;
  height: 46px;
  background-color: #ffffff;
  color: #232338;
  border: 1px solid #e0e0eb;
  border-radius: 100px;
  display: flex;
  // margin-top: 520px;
  // margin-left: 1150px;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  z-index: 10;
`;

const OpenAllImagesBtnExtended = styled.button`
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 46px;
  background-color: #ffffff;
  color: #232338;
  border: 1px solid #e0e0eb;
  border-radius: 100px;
  display: flex;
  margin-top: 520px;
  margin-left: 1150px;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  z-index: 10;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CircleButton = styled.button`
  border-radius: 100px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  text-align: center;
  background-color: white;
  border: 1px solid #e0e0eb;
  margin: 0px 10px;

  &:hover {
    background-color: #efeffe;
  }
`;

const TourBtn = styled.button`
  width: 200px;
  cursor: pointer;
  height: 48px;
  color: white;
  background-color: #5a5ff2;
  border-radius: 32px;
  border: none;
  margin: 0px 10px;
  margin-right: 50px;
`;

const DaysPill = styled.div`
  height: 24px;
  background-color: #00000099;
  border: none;
  font-family: Inter;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  padding: 3px 11px;
`;

const CarouselHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  z-index: 10;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
`;

const Button = styled.button`
  display: flex;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  gap: 8px;
`;

const Address = styled.p`
  font-size: 14px;
  color: #373758;
  font-weight: 300;
`;

const ModalImageContainer = styled.div`
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.9);
  }
`;

const PreviewHeader = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 82px;
  border-bottom: 1px solid #2b2b45;
  z-index: 10000;
`;

const CircleButtonPreview = styled.button`
  border-radius: 100px;
  cursor: pointer;
  width: 48px;
  background: transparent;
  height: 48px;
  text-align: center;
  border: 1px solid white;
  margin: 0px 10px;

  &:hover {
    background-color: #efeffe;
  }
`;

const PreviewHeaderMobile = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 82px;
  border-bottom: 1px solid #2b2b45;
  z-index: 10000;
`;

const PreviewFooterMobile = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 82px;
  border-top: 1px solid #2b2b45;
  z-index: 10000;
`;

const ModalPrice = styled.p`
  fontsize: 20px;
  color: #232338;
  margin-bottom: 4px;
`;

const StyledCarouselModal = styled(Modal)`
  .ant-modal-header {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-close-x {
    margin-top: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
`;

const CarouselNumbers = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 12px;
  width: 51px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  left: 48%;

  p {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 16.2px */
    letter-spacing: 0.5px;
  }
`;

export {
  StyledImage,
  CarouselHeader,
  ImageContainer,
  CustomOverlay,
  CarouselWrap,
  WebImageGrid,
  StyledImageCenter,
  ImageGrid,
  DaysOnMarketPill,
  OpenAllImagesBtn,
  ModalHeaderContainer,
  CircleButton,
  TourBtn,
  StyledImageMobile,
  DaysPill,
  ButtonGroup,
  Button,
  DaysOnMarketPillExtended,
  OpenAllImagesBtnExtended,
  Address,
  ModalImageContainer,
  PreviewHeader,
  CircleButtonPreview,
  PreviewHeaderMobile,
  PreviewFooterMobile,
  ModalPrice,
  StyledCarouselModal,
  CarouselNumbers,
};
