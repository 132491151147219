import React, { useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import CRMV2Container from "container/CRM"
import TechAssets from "container/TechAssets/index"
import { ATC } from "@fonts"
import Loader from "@ui/Loader"
import colors from "@colors"
import { Button } from "@ui/antd"
import FinanceTransactionList from "./FinanceTransactionList"
import {
  Wrap, H1,
} from "../../../common"
import { BuyBeforeYouSell as BPng, PreSale } from "../../../Icons"

const IconWrap = styled.div`
  height: 53px;
  width: 48px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`

const Header = styled.div``

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  width: 100%;
  height: 100px;

  background: ${colors.white};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  gap: 10px;

  cursor: ${props => props.cursor || "auto"};
`

const FinancingOptions = styled.div``

const Title = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
`

const Description = styled.p`
  font-family: ${ATC};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
`

const FinancingTab = ({
  getFinancingForTransaction,
  currentCRMDealId,
  getFinancingForTransactionResponse,
  setCurrentDrawerPage,
  currentPage,
  setCurrentClientType,
  getCtIdResponse,
}) => {
  const { data } = getCtIdResponse || {}
  const { id } = data || {}
  useEffect(() => {
    getFinancingForTransaction({ transactionId: id })
  }, [])
  const { data: financingOptions, isFetching } = getFinancingForTransactionResponse || {}
  const { availableOptions, financingApplications } = financingOptions || {}
  const { updated_at } = financingApplications && financingApplications[financingApplications.length - 1] || {}
  let type

  if (currentPage === "property-listing") {
    type = "seller"
    setCurrentClientType(type)
  } else if (currentPage === "search-criteria") {
    type = "buyer"
    setCurrentClientType(type)
  }

  return (
    <>
      {!isFetching ? (
        <Wrap
          noBoxShadow
        >
          <FinancingOptions>
            <Header>
              <H1>Financing Options</H1>
            </Header>
            <Options>
              {/* <Option
                onClick={() => setCurrentDrawerPage("buy_before_you_sell")}
                cursor="pointer"
              >
                <IconWrap>
                  <img src={BPng} alt="buy_before_you_sell" />
                </IconWrap>
                <TextWrap>
                  <Title>
                    Buy Before You Sell
                  </Title>
                  <Description>
                    {`Enable your clients a way to pay off their mortgage by unlocking 
                    the majority of the property’s equity.`}
                  </Description>
                </TextWrap>
                {isBuyBeforeYouSell ? (
                  <Button
                    style={{
                      background: `${colors.marineBLue}`,
                      borderColor: `${colors.marineBLue}`,
                      color: `${colors.white}`,
                    }}
                  >
                    Apply
                  </Button>
                ) : (
                  <Button
                    style={{
                      background: `${colors.moonGrey}`,
                      borderColor: `${colors.moonGrey}`,
                      color: `${colors.white}`,
                    }}
                  >
                    Applied
                  </Button>
                )}
              </Option> */}
              {/* {type === "seller" && (
                <Option>
                  <IconWrap>
                    <img src={PreSale} alt="pre_sale_home_prep" />
                  </IconWrap>
                  <TextWrap>
                    <Title>
                      Pre-Sale Home Prep
                    </Title>
                    <Description>
                      {`Offer your clients a peace of mind with pre-sale 
                      home preperations for interior and exterior improvements.`}
                    </Description>
                  </TextWrap>
                  {isPreSaleHomePrep ? (
                    <Button
                      style={{
                        background: `${colors.marineBLue}`,
                        borderColor: `${colors.marineBLue}`,
                        color: `${colors.white}`,
                      }}
                      onClick={() => setCurrentDrawerPage("presale_home_prep")}
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      style={{
                        background: `${colors.moonGrey}`,
                        borderColor: `${colors.moonGrey}`,
                        color: `${colors.white}`,
                      }}
                    >
                      Applied
                    </Button>
                  )}
                </Option>
              )} */}
              {(type === "buyer" || type === "Buyer") && (
                <>
                  <Option>
                    <IconWrap>
                      <img src={PreSale} alt="pre_sale_home_prep" />
                    </IconWrap>
                    <TextWrap>
                      <Title>
                        Mortgage
                      </Title>
                      <Description>
                        {`You got your clients to the finish line, let’s not make them wait.
                        Loandesk Mortgage prides itself on getting your buyers into their new home as efficiently as possible.`}
                      </Description>
                      {updated_at && (
                        <Description>
                          {`Last Applied On ${moment(updated_at).format("MMM. DD")}`}
                        </Description>
                      )}
                    </TextWrap>
                    <Button
                      style={{
                        background: `${colors.marineBLue}`,
                        borderColor: `${colors.marineBLue}`,
                        color: `${colors.white}`,
                      }}
                      onClick={() => setCurrentDrawerPage("mortgage")}
                    >
                      Apply
                    </Button>
                  </Option>
                  {/* <Option>
                    <IconWrap>
                      <img src={PreSale} alt="pre_sale_home_prep" />
                    </IconWrap>
                    <TextWrap>
                      <Title>
                        Cash Offer
                      </Title>
                      <Description>
                        {`Offer your clients a peace of mind with pre-sale 
                        home preperations for interior and exterior improvements.`}
                      </Description>
                    </TextWrap>
                    {isCashOffer ? (
                      <Button
                        style={{
                          background: `${colors.marineBLue}`,
                          borderColor: `${colors.marineBLue}`,
                          color: `${colors.white}`,
                        }}
                        onClick={() => setCurrentDrawerPage("cash_offer")}
                      >
                        Apply
                      </Button>
                    ) : (
                      <Button
                        style={{
                          background: `${colors.moonGrey}`,
                          borderColor: `${colors.moonGrey}`,
                          color: `${colors.white}`,
                        }}
                      >
                        Applied
                      </Button>
                    )}
                  </Option> */}
                </>
              )}
            </Options>
          </FinancingOptions>
        </Wrap>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default CRMV2Container(TechAssets(FinancingTab))
