/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings/index"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ConfirmDelete = ({
  toClose,
  loading,
  handleDeleteTransaction,
}) => {
  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Confirm Deletion!
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              Are you sure you want to delete the transaction?
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "blue",
            }}
            onClick={toClose}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={loading}
            loaderOption={{
              color: "white",
            }}
            onClick={handleDeleteTransaction}
          >
            Delete
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default Listings(ConfirmDelete)
