import React from "react"
import styled from "styled-components"
import container from "container/App/applyFlowContainer"
import HeaderForm from "dumbComponents/NewTeamsPages/Components/HeaderForm"
import Companies from "dumbComponents/NewLandingPage/Companies"
import WebinarInfo from "./Components/WebinarInfo"
import Testimonials from "./Components/Testimonials"
import FlatFee from "./Components/Commission"
import Producers from "./Components/Producers"

const Wrap = styled.div`
`

const CompanyWrapper = styled.div`
  position: relative;
  top: -7rem;
  background: white;
  padding: 0 5%;
  width: 90%;
  margin: auto;
`

const NewAgentsLP = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <HeaderForm
        isAgentLP
      />
      <CompanyWrapper>
        <Companies />
      </CompanyWrapper>
      <WebinarInfo toggleApplyModal={toggleApplyModal} />
      <Testimonials
        toggleApplyModal={toggleApplyModal}
      />
      <FlatFee toggleApplyModal={toggleApplyModal} />
      <Producers toggleApplyModal={toggleApplyModal} />
    </Wrap>
  )
}

export default container(NewAgentsLP)
