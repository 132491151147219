import React, { Component } from "react"
import styled from "styled-components"
import { values } from "lodash"
import Checkbox from "@ui/Checkbox"
import colors from "@colors"
import Button from "@ui/Button"
import Box from "@ui/Box"
import Badge from "@ui/Badge"
import Loader from "@ui/Loader"
import icons from "@icons"
import CircularImage from "dumbComponents/common/CircularImage"
import { getEpoch } from "services/Utils"

const Wrapper = styled.ul`
  max-height: 500px;
  overflow-y: scroll;
  margin-top: 30px;
`

export const Li = styled.li`
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;

  &:nth-child(odd) {
    background: #fbfcfc;
  }

  a {
    text-decoration: none;
    color: ${colors.primaryColor};
  }
`

const StyledButton = styled(Button)`
  //margin: 60px 0 30px 0;
  margin-top: 20px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  width: 234px;
`

class ArchiveList extends Component {
  state = {
    selectedLeads: {},
  }

  onSelectArchiveLead = (client) => {
    const { selectedLeads } = this.state
    const newClient = { ...client }
    const defaultSalesObject = {
      "Current stage": "Active",
      "Buyer checklist": null,
      "Seller checklist": null,
      backgroundColor: "#e5faf2",
      progressPercentage: 50,
      textColor: "#00ce7d",
    }
    if (newClient.sales) {
      newClient.sales["Current stage"] = "Active"
      newClient.sales["Sales stage"] = client.sales["Sales stage"]
      const Active = {
        date: getEpoch(),
      }
      if (newClient.sales["Sales stage"]) {
        newClient.sales["Sales stage"].Active = Active
      } else {
        newClient.sales["Sales stage"] = {}
        newClient.sales["Sales stage"].Active = Active
      }
    } else {
      newClient.sales = defaultSalesObject
    }
    if (!selectedLeads[client.id]) {
      const selectedLead = {
        id: client.client_id || client.id,
        sales: newClient.sales,
      }
      this.setState({
        selectedLeads: {
          ...selectedLeads,
          [client.id]: selectedLead,
        },
      })
    } else {
      delete selectedLeads[client.id]
      this.setState({
        selectedLeads: {
          ...selectedLeads,
        },
      })
    }
  }

  updateClientsToActive = () => {
    const { selectedLeads } = this.state
    const { updateArchivedLead, type } = this.props
    updateArchivedLead({ type, clients: values(selectedLeads) })
  }

  render() {
    const {
      archivedReferrals,
      isFetchingArchivedReferrals,
      isUpdatingArchivedLead,
      type,
    } = this.props
    const { selectedLeads } = this.state
    return (
      <Box d="block">
        <StyledButton
          onClick={() => this.updateClientsToActive()}
          disabled={values(selectedLeads).length === 0 || isUpdatingArchivedLead}
        >
          <i className={icons.arrowsShare} style={{ transform: "rotateY(180degs)" }} />
          &nbsp;Move back to Active
        </StyledButton>
        {isFetchingArchivedReferrals ? (
          <div style={{ height: "200px" }}>
            <Loader />
          </div>
        ) : (
          <Wrapper>
            {archivedReferrals.length > 0
              && archivedReferrals.map(lead => (
                <Li key={lead.id}>
                  <Box p="5px">
                    <Checkbox
                      size={20}
                      id={lead.id}
                      name={lead.id}
                      checked={selectedLeads[lead.id]}
                      onClick={() => {
                        this.onSelectArchiveLead(lead)
                      }}
                    />
                  </Box>
                  <Box p="5px" mr="10px">
                    <CircularImage image={lead.clientImage} name={lead.client_name || ""} />
                  </Box>
                  <Box flex="1" p="5px">
                    <a href={`/client/${lead.client_id || lead.id}${type === "crm" ? "/notes" : ""}`} target="_blank">
                      <span>{lead.client_name || lead.client_email || lead.name}</span>
                    </a>
                  </Box>
                  <Badge
                    style={{
                      backgroundColor: lead.type === "Seller" ? "#a2c5ff" : "#6e84a9",
                      color: "#ffffff",
                      width: "56px",
                      padding: "5px 0px",
                    }}
                  >
                    {lead.type || "Buyer"}
                  </Badge>
                </Li>
              ))}
          </Wrapper>
        )}
      </Box>
    )
  }
}

export default ArchiveList
