import React from "react"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import colors from "@colors"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"

const Wrap = styled.ul`
  display: flex;
  ${props => props.width && `
    width: ${props.width}
  `}
  border-bottom: 3px solid ${colors.disabledInput};
  margin-bottom: 34px;
  margin-left: 48px;
  margin-right: 48px;
`

const StyledLink = styled(Link)`
  ${LeaderBoardFontStyle}
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 8px;
  display: inline-block;
  position: relative;
  width: 100px;
  text-align: center;
  ${props => props.width && `
    width: ${props.width}
  `}

  ${props => props.isActive ? `
    font-weight: 600;
    color: ${colors.marineBLue};
    &:after {
      content: "";
      height: 3px;
      position: absolute;
      bottom: -6px;
      left: 0px;
      width: 100%;
      background: ${colors.marineBLue};
      border-radius: 2px;
      width: 135px;
      ${props.lineWidth && `
        width: ${props.lineWidth};
      `}
    }
  ` : `
    color: ${colors.black};
    border-bottom: 3px solid transparent;
  `}
`

const LeaderboardTab = ({ location, getTeamDetailsResponse }) => {
  const { pathname } = location
  const {
    data: teamDetails,
  } = getTeamDetailsResponse || {}
  return (
    <Wrap width={teamDetails && teamDetails.id ? "520px" : "385px"}>
      <li>
        <StyledLink to="/realty/dashboard" isActive={pathname === "/realty/dashboard"} lineWidth="103px">
          STATS
        </StyledLink>
      </li>
      {teamDetails && teamDetails.id && (
        <li>
          <StyledLink to="/realty/dashboard/team-stats" isActive={pathname === "/realty/dashboard/team-stats"} lineWidth="107px">
            MY TEAM
          </StyledLink>
        </li>
      )}
      <li>
        <StyledLink to="/realty/dashboard/leaderboard" isActive={pathname === "/realty/dashboard/leaderboard"} width="135px" lineWidth="135px">
          LEADERBOARD
        </StyledLink>
      </li>
      <li>
        <StyledLink to="/realty/dashboard/monthly-rewards" isActive={pathname === "/realty/dashboard/monthly-rewards"} width="156px" lineWidth="156px">
          MONTHLY AWARDS
        </StyledLink>
      </li>
    </Wrap>
  )
}

export default TeamProfileContainer(withRouter(LeaderboardTab))
