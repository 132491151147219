
import { DeleteOutlined } from "@ant-design/icons"
import React from "react"
import { Button } from "antd"
import {
  DeleteWrap,
} from "./styles"

const DeleteTransaction = ({ data, handleDelete, currentContractStatus }) => (
  <DeleteWrap>
    <Button
      type="text"
      icon={<DeleteOutlined style={{ fontSize: "16px", color: "#232338" }} />}
      onClick={handleDelete}
      disabled={!(currentContractStatus == "incomplete" || currentContractStatus == null)}
    />
  </DeleteWrap>
)

export default DeleteTransaction
