import styled from "styled-components";
import colors from "@colors";
import { MONTSERRAT } from "@fonts";

const Header = styled.div`
  color: ${colors.new_grey600};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
  margin-top:48px;
  margin-bottom:24px;
  @media (max-width: 1024px){
      margin-top:32px;
  } 
`;

const Map = styled.div`
height: 400px;
width: 100%; 
height: 267px;
border-radius: 8px;
margin-bottom: 48px;
 @media (max-width: 1024px){
      height:267px;
} 
`
export { Header, Map };