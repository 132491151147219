import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { MONTSERRAT, INTER } from "@fonts"
import BrbcContainer from "container/BRBC";
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import CRMV2Container from "container/CRM"
import LeftArrow from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/LeftArrow"
import { checkForUserObject } from "services/Utils"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import {
  Form, NewInput, NewSelect, NewButton,
  NewInputNumber,
} from "@ui/antd"
import Cookies from "services/CookieStorage"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import { IN_CONTRACT } from "../../common"
import SearchCriteria from "./SearchCriteriaForm"
import PropertyRequirement from "./PropertyRequirementForm"
import CommonForm from "./CommonForm"
import DuplicateTransaction from "../DuplicateTransaction"
import { validateFields } from "../../utils"
import CannotAddInContract from "./CannotAddInContract"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form
const { Option } = NewSelect

const Wrap = styled.div`
  background: ${colors.white};
  height: 100%;
`

const HeaderWrap = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    font-size: 18px;
    margin: 0;
    color: ${colors.new_primary};
    font-family: ${INTER};
  }
`

const H1 = styled.h1`
  font-family: ${MONTSERRAT};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.new_grey400};
  margin: 0;
`

const ButtonWrap = styled.div`
  ${props => props.leadSource && `
    bottom: 0;
    top: inherit !important;
  `}
  position: absolute;
  left: 0;
  top: 0;
`

const RequirementWrap = styled.div`
  display: flex;
  position: relative; 
  flex-direction: column;
  padding-bottom: 60px;
`

const ErrorWrap = styled.h1`
  color: #ff4b4b;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  margin-bottom: 15px;
  margin-left: 5px;
`

const FormWrap = styled.div`
  padding: 0 24px 24px;

  .ant-form-item {
    margin-left: 0;
    margin-right: 0;
  }
`

const AddNewLead = ({
  handleClose,
  addClient,
  toggleAddNewLeadDrawer,
  getClientFiltersResponse,
  currentPage,
  isUserOverviewDrawerOpen,
  toggleUserOverview,
  addSearchCriteria,
  addPropertyDetails,
  currentClientType,
  setCurrentClientType,
  updateCrmClientResponse,
  history,
  getCrmClientResponse,
  addBuyerReqResponse,
  addPropertyDetailsResponse,
  createClientTransactionResponse,
  addCrmClientResponse,
  getTeamDetailsResponse,
  getCtIdResponse,
  fetchBrbcStatus,
}) => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [cityFormValue, setCityFormValue] = useState([])
  const [fileList, setFileList] = useState([])
  const [isPhoneNumberValid, setPhoneNumber] = useState(true)
  const [isEmailValid, setEmailValid] = useState(true)
  const [checkDetailsAdded, setCheckDetailsAdded] = useState(false)
  const [agentForWhomClientIsBeingCreated, setAgentForForm] = useState("")
  const { data, isFetching: fetchingClientData } = getCrmClientResponse || {}
  const { data: clientTransactionData } = getCtIdResponse || {}
  const { client_source, status } = clientTransactionData || {}
  const { agentInfo } = isUserOverviewDrawerOpen || {}
  const disabled = currentPage === "add-same-lead"
  const {
    emails,
    name,
    phones,
    type,
    agent_id,
  } = data || {}
  const phone = !fetchingClientData && phones && get(phones[0], "phone") || ""
  const email = !fetchingClientData && emails && get(emails[0], "email") || ""
  const { client_id, id } = agentInfo || {}
  const { data: updatedData, isFetching } = updateCrmClientResponse || {}
  const {
    name: updatedName,
    phones: updatedPhones,
    emails: updatedEmails,
  } = updatedData || {}
  const updatedPhone = !isFetching && updatedPhones && get(updatedPhones[0], "phone") || ""
  const updatedEmail = !isFetching && updatedEmails && get(updatedEmails[0], "email") || ""
  const [leadSource, setLeadSource] = useState(currentClientType || type)
  const clientId = client_id || id
  const { isFetching: fetchingBuyerReq } = addBuyerReqResponse || {}
  const { isFetching: fetchingPropDetails } = addPropertyDetailsResponse || {}
  const { isFetching: fetchingClientTransaction } = createClientTransactionResponse || {}
  const { isFetching: addingClient } = addCrmClientResponse || {}
  const isLoading = fetchingBuyerReq || fetchingPropDetails || fetchingClientTransaction || addingClient
  const existingCriteria = get(addBuyerReqResponse, "data.is_pre_exist")
  const existingListing = get(addPropertyDetailsResponse, "data.is_pre_exist")
  const criteriaExisits = !fetchingBuyerReq && existingCriteria
  const listingExisits = !fetchingPropDetails && existingListing
  const {
    data: members,
    isFetching: fetchingMembers,
  } = getTeamDetailsResponse || {}
  const user = checkForUserObject("user")
  const agentId = user && user.id

  const secureId = Cookies.load("secureId")

  const { firstname, lastname } = user || {}

  const checkSecondaryPhoneValidity = () => {
    setPhoneNumber(true)
  }

  console.log("check here", addCrmClientResponse.data);

  const checkSecondaryEmailValidity = () => {
    setEmailValid(true)
  }

  const [modalOpenPermi, setModalOpenPermi] = useState(false);
  const onFinish = (fieldValues) => {
    toggleUserOverview(false)
    const { phoneValid, emailValid, isOverallTrue } = validateFields(fieldValues)
    setPhoneNumber(phoneValid)
    setEmailValid(emailValid)
    const {
      email, phone, name, source, status,
      client_source, agent_id: fieldAgentId,
    } = fieldValues
    let finalStatus = ""
    if (status === "New Client") {
      finalStatus = "New Client/Accepted"
    } else {
      finalStatus = status
    }
    const payload = {
      name,
      type,
      emails: [{
        email,
      }],
      phones: [{
        phone,
      }],
    }

    if (fieldAgentId) {
      payload.agent_id = fieldValues.agent_id
    }

    if (agent_id) {
      payload.agent_id = agent_id
    }

    const transactionPayload = {
      source: "crm",
      transaction_referral_type: "crm",
      type: source,
      secure_id: secureId,
      status,
      client_source,
    }
    if (isOverallTrue) {
      if (clientId) {
        if (currentClientType === "buyer") {
          const searchCriteriaPayload = fieldValues.search_criteria
          for (let index = 0; index < searchCriteriaPayload.length; index += 1) {
            const {
              area_unit,
              bathroom,
              bedroom,
              max_budget,
              max_size,
              min_budget,
              min_size,
              sale_type,
              location,
            } = searchCriteriaPayload[index]
            const finalSearchCriteriaPayload = {
              area_unit,
              bathroom,
              bedroom,
              locations: location,
              max_budget,
              max_size,
              min_budget,
              min_size,
              sale_type,
              u_cust_id: clientId,
            }
            addSearchCriteria({ finalSearchCriteriaPayload, finalTransactionPayload: { ...transactionPayload, client_id: clientId }, history })
          }
          toggleAddNewLeadDrawer(false)
        } else if (currentClientType === "seller") {
          const propertyDetailsPayload = fieldValues.property_details
          for (let index = 0; index < propertyDetailsPayload.length; index += 1) {
            const {
              area_coverage,
              no_of_bath_room,
              no_of_bed_room,
              sale_type,
              property_status,
              property_type,
              resale_price,
              covered_area,
            } = propertyDetailsPayload[index]
            const { city, state, country, latitude, longitude, address } = cityFormValue[index]
            const finalPropertyDetailsPayload = {
              area_coverage,
              no_of_bath_room,
              no_of_bed_room,
              address,
              sale_type,
              rent_resale: sale_type,
              u_cust_id: clientId,
              property_status,
              property_type,
              city,
              state,
              country,
              latitude,
              longitude,
              resale_price,
              covered_area,
            }
            addPropertyDetails({ finalPropertyDetailsPayload, finalTransactionPayload: { ...transactionPayload, client_id: clientId }, history })
          }
        }
        // fetchBrbcStatus({agentId: agent_id, clientId: clientId})
        toggleAddNewLeadDrawer(false)
      } else if (fieldValues && ((fieldValues.property_details && fieldValues.property_details.length > 0) || (fieldValues.search_criteria && fieldValues.search_criteria.length > 0))) {
        addClient({
          payload, transactionPayload, fieldValues, cityFormValue, history, is_for_transaction: 1,
        })
        // fetchBrbcStatus({agentId: agent_id, clientId: clientId})
      } else {
        if (status === IN_CONTRACT) {
          setCheckDetailsAdded(true)
          return
        }
        addClient({ payload, transactionPayload, history })
        // fetchBrbcStatus({agentId: agent_id, clientId: clientId})
      }
    }
    setCurrentClientType("")
    setModalOpenPermi(true);
  }

  useEffect(() => {
    fetchBrbcStatus({agentId: agent_id, clientId: addCrmClientResponse.data?.id})
  }, [addCrmClientResponse.data])

  const onLeadSourceSelect = (value) => {
    setLeadSource(value)
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "Required!",
    types: {
      email: "Email is not a valid!",
      number: "Phone Number is not a valid!",
    },
  }
  /* eslint-enable no-template-curly-in-string */

  const initialValues = () => {
    let values = {}
    if (currentClientType === "buyer") {
      values = {
        name: updatedName || name || "",
        email: updatedEmail || email || "",
        phone: updatedPhone || phone || "",
        source: currentClientType || "",
        status,
        client_source,
        search_criteria: [{}],
      }
    } else if (currentClientType === "seller") {
      values = {
        name: updatedName || name || "",
        email: updatedEmail || email || "",
        phone: updatedPhone || phone || "",
        source: currentClientType || "",
        status,
        client_source,
        property_details: [{}],
      }
    }
    return values
  }

  console.log("createClientTransactionResponse", createClientTransactionResponse)

  return (
    <Wrap>
      {checkDetailsAdded && (
        <CannotAddInContract setCheckDetailsAdded={setCheckDetailsAdded} toClose={checkDetailsAdded} />
      )}
      <HeaderWrap>
        <CloseWrap
          onClick={() => {
            setLeadSource("")
            form.resetFields()
            setCurrentClientType("")
            handleClose()
            setModalOpenPermi(false)
            toggleAddNewLeadDrawer(false)
            if (agentInfo) {
              toggleUserOverview(false)
            }
          }}
        >
          <LeftArrow />
          <p>
            Back to CRM
          </p>
        </CloseWrap>
        <H1>
          {currentPage === "add-same-lead" ? "Add Info" : "Add New Lead"}
        </H1>
      </HeaderWrap>
      <FormWrap>
        <Form
          name="add_new_lead"
          preserve={false}
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          validateMessages={validateMessages}
          ref={formRef}
          form={form}
          initialValues={initialValues()}
        >
          {/*onChange={(value) => { setAgentForForm(value) }}* */}
          {!currentClientType && members && members.id && (
            <Item name="agent_id" label={<LabelComponent text="On whose behalf is the client being created?" />}>
              <NewSelect placeholder="On whose behalf is the client being created?" value={agentId} defaultValue={`${firstname} ${lastname} (Me)`} allowClear>
                {fetchingMembers ? (
                  <Option value={agentId}>{`${firstname} ${lastname} (Me)`}</Option>
                ) : (
                  <>
                    <Option value={agentId}>{`${firstname} ${lastname} (Me)`}</Option>
                    {members.team_members.map((item) => {
                      if (item.agent_id !== parseInt(agentId, 10)) {
                        return (
                          <Option value={item.agent_id}>{`${item.firstname} ${item.lastname} (Agent)`}</Option>
                        )
                      }
                    })}
                  </>
                )}
              </NewSelect>
            </Item>
          )}
          <Item name="name" rules={[{ required: true }]}
          label={<LabelComponent text="Full Name*" />}>
            <NewInput disabled={disabled} placeholder="Full Name*" />
          </Item>
          <Item name="email" rules={[{ required: true }]}
            label={<LabelComponent text="Email*" />}>
            <NewInput onChange={checkSecondaryEmailValidity} disabled={disabled} placeholder="Email*" />
          </Item>
          {!isEmailValid && (
            <ErrorWrap>* Email Not Valid</ErrorWrap>
          )}
          {currentPage === "add-same-lead" ? (
            
            <Item name="phone" normalize={normalizePhone} rules={[{ required: true }]}
              label={<LabelComponent text="Phone Number*" />}>
              <NewInputNumber
                onChange={e => checkSecondaryPhoneValidity(e)}
                formatter={value => `${value}`.replace(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3")}
                disabled={disabled}
                placeholder="Phone Number*"
              />
            </Item>
          ) : (
              <Item name="phone" normalize={normalizePhone} rules={[{ required: true }]}
                label={<LabelComponent text="Phone Number*" />}>
              <NewInput onChange={checkSecondaryPhoneValidity} disabled={disabled} placeholder="Phone Number*" />
            </Item>
          )}
          {!isPhoneNumberValid && (
            <ErrorWrap>* Phone Number Not Valid</ErrorWrap>
          )}
          <Item name="source" rules={[{ required: true }]}
          label={<LabelComponent text="Lead Type*" />}>
            <NewSelect onChange={(value) => { onLeadSourceSelect(value) }} placeholder="Lead Type*">
              <Option value="seller">Seller</Option>
              <Option value="buyer">Buyer</Option>
            </NewSelect>
          </Item>
          <RequirementWrap>
            {(leadSource === "buyer") && (
              <>
                <CommonForm
                  getClientFiltersResponse={getClientFiltersResponse}
                />
                <SearchCriteria
                  formRef={formRef}
                  setCityFormValue={setCityFormValue}
                  cityFormValue={cityFormValue}
                />
              </>
            )}
            {(leadSource === "seller") && (
              <>
                <CommonForm
                  getClientFiltersResponse={getClientFiltersResponse}
                />
                <PropertyRequirement
                  formRef={formRef}
                  setCityFormValue={setCityFormValue}
                  cityFormValue={cityFormValue}
                  setFileList={setFileList}
                  fileList={fileList}
                />
              </>
            )}
            {((criteriaExisits || listingExisits) && modalOpenPermi && !isLoading) ? (
              <DuplicateTransaction
                title={leadSource === "buyer" ? "This search criteria already exists." : "This listing already exists."}
                description={leadSource === "buyer" ? "Do you still want to create a new search criteria?"
                  : "Do you still want to create a new listing profile?"}
                type={leadSource}
                fieldValues={formRef && formRef.current && formRef.current.getFieldsValue()}
                cityFormValue={cityFormValue}
                resetForm={() => {
                  setLeadSource("")
                  form.resetFields()
                  setCurrentClientType("")
                }}
              />
            ) : (
              <ButtonWrap
                leadSource={leadSource || type}
              >
                <Item>
                  <NewButton
                    className="add_client_submit"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </NewButton>
                </Item>
              </ButtonWrap>
            )}
          </RequirementWrap>
        </Form>
      </FormWrap>
    </Wrap>
  )
}

export default BrbcContainer(TeamProfileContainer(withRouter(CRMV2Container(AddNewLead))))
