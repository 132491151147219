import React from "react"
import {
  OutboundSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import {
  InnerTab,
  IconEle,
  NavLink,
  NotificationDot,
} from "./commonStyles"

const SubmittedLeadsTab = ({
  routeArray,
  updatesObjectExist,
  updateData,
}) => (
  <InnerTab referral isActive={routeArray[1] === "outbound"}>
    {updatesObjectExist
      && updateData.outbound
      && !updateData.outbound.seen ? (
        <NotificationDot />
      ) : (
        <NotificationDot zero />
      )}
    <div className="icon-block-new">
      <IconEle left="10px" src={OutboundSVGIcon} isActive={routeArray[1] === "outbound"} />
    </div>
    <div className="text-block">
      <NavLink
        ml="-5.8px"
        to="/referrals/outbound"
        isActive={routeArray[1] === "outbound"}
      >
        Submitted Leads
      </NavLink>
    </div>
  </InnerTab>
)

export default SubmittedLeadsTab
