import React, { useRef } from "react"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Form,
} from "@ui/antd"
import {
  FormWrap,
  CTAWrap,
  OrWrap,
} from "./commonStyles"
import {
  InputField,
  SelectField,
  InputNumber,
} from "../../InputFields"
import {
  BlueButton,
} from "../../../../commonStyles"
import { getInputFieldsBasedOnState } from "./formConfig"

const PropertyInformationForm = ({
  onFinish,
  onFinishFailed,
  prefilledData,
  state = "california",
  updatingDealTerms,
}) => {
  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}

    console.log("Prefilled", prefilledData)

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
    }

    return initValues
  }

  const moneyFormatter = (number = 0, decPlaces = 0) => {
    decPlaces = Math.pow(10, decPlaces)
    const digitsAfterDec = number.toString().includes(".") ? number.toString().split(".")[1].length : number
    if (number.toString().includes(".")) {
      number = Math.round(number * decPlaces) / decPlaces
    }
    return number
  }

  const calculatePercentage = (amount, percentage) => {
    const values = formRef?.current?.getFieldsValue()
    console.log("HERE??")
    if (values[amount] === null || values[amount] === 0) {
      formRef.current.setFieldsValue({
        [percentage]: null,
      })
    }
    if (values.price && values[amount]) {
      const percent = (values[amount] * 100) / values.price
      formRef.current.setFieldsValue({
        [percentage]: moneyFormatter(percent, 2),
      })
    }
  }

  const totalPrice = () => {
    const values = formRef?.current?.getFieldsValue()
    if (values.price) {
      const total = values.price - ((values.loan_amount || 0) + (values.initial_deposit_amount || 0) + (values.increased_deposit_amount || 0) + (values.additional_financial_amount || 0))
      formRef.current.setFieldsValue({
        price: values.price,
        balance_of_down_payment: total,
      })
    }
  }

  return (
    <FormWrap>
      <Form
        name="offers_deal_terms_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
      >
        {getInputFieldsBasedOnState(state).map((item) => {
          if (item.type === "select") {
            return (
              <>
                <label htmlFor={item.name}>{item.placeholder}</label>
                <SelectField
                  isFormField
                  name={item.name}
                  options={[]}
                  defaultValue={prefilledData ? prefilledData.listing_type : null}
                  placeholder={item.placeholder}
                />
                {item.showOr && (
                  <OrWrap>
                    <p>OR</p>
                  </OrWrap>
                )}
              </>
            )
          }

          return (
            <>
              <label htmlFor={item.name}>{item.placeholder}</label>
              <InputNumber
                name={item.name}
                inputType="text"
                type={item.formatterType}
                placeholder={item.placeholder}
                disabled={item.disabled}
                onChange={() => {
                  const values = formRef?.current?.getFieldsValue()
                  if (item.modifiesForm) {

                    if (item.name === "loan_amount") {
                      calculatePercentage("loan_amount", "loan_amount_percentage")
                      totalPrice()
                      return
                    }

                    if (item.name === "initial_deposit_amount") {
                      calculatePercentage("initial_deposit_amount", "initial_deposit_amount_percentage")
                      totalPrice()
                      return
                    }

                    if (item.name === "increased_deposit_amount") {
                      calculatePercentage("increased_deposit_amount", "increased_deposit_amount_percentage")
                      totalPrice()
                      return
                    }

                    if (item.name === "additional_financial_amount") {
                      calculatePercentage("additional_financial_amount", "additional_financial_amount_percentage")
                      totalPrice()
                      return
                    }
                    
                    //FOR PRICE
                    if (values.loan_amount) {
                      calculatePercentage("loan_amount", "loan_amount_percentage")
                    }
                    if (values.initial_deposit_amount) {
                      calculatePercentage("initial_deposit_amount", "initial_deposit_amount_percentage")
                    }
                    if (values.increased_deposit_amount) {
                      calculatePercentage("increased_deposit_amount", "increased_deposit_amount_percentage")
                    }
                    if (values.additional_financial_amount) {
                      calculatePercentage("additional_financial_amount", "additional_financial_amount_percentage")
                    }
                    totalPrice()
                  }
                }}
              />
              {item.showOr && (
                <OrWrap>
                  <p>OR</p>
                </OrWrap>
              )}
            </>
          )
        })}

        <CTAWrap>
          <Form.Item>
            <BlueButton
              maxWidth
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              {updatingDealTerms ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Save &amp; Continue</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap>
      </Form>
    </FormWrap>
  )
}

export default PropertyInformationForm
