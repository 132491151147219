import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  border-radius: 9px;
  border: 1px solid var(--Gradient-1, #B6B8FF);
  background: rgba(255, 255, 255, 0.35);

  /* gls_d */
  box-shadow: 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  backdrop-filter: blur(16.799999237060547px);
`

export const BadgeTag = styled.div`
  display: flex;
  height: 22px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Color-Gray-400, #41446A);

  p {
    color: var(--Lockedcolor, #F8F8FF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 9px; /* 90% */
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
  }
`

export const AddressH1 = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  h1 {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${MONTSERRAT};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 114.286% */
    margin: 0;
  }
`

export const AddressH2 = styled.div`
  h2 {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
    margin: 0;
  }
`
export const BuyerDetails = styled.div`
  display: flex;
  p{
    color: var(--Color-Gray-400, #41446A);
    font-style: normal;
    font-weight: 500;
  }
  a {
    color: var(--Color-Primary-Primary, #5A5FF2);
    margin-left: 5px;
    font-style: normal;
    font-weight: 500;
  }
`

export const StatsDiv = styled.div`
  display: flex;
  gap: 20px;
`

export const AmountDiv = styled.div`
  h1 {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${MONTSERRAT};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 114.286% */
  }
`

export const LastUpdatedDiv = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    text-align: right;
    font-family: ${MONTSERRAT};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`

export const SelectWrap = styled.div``

export const InnerWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

export const InnerStatsDiv = styled.div`
  display: flex;
  gap: 8px;

  img {
    display: flex;
    width: 19.864px;
    height: 19.864px;
    justify-content: center;
    align-items: center;
  }

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 14.898px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.621px;

    span {
      color: var(--Color-Primary-Primary, #5A5FF2);
      font-family: ${INTER};
      font-size: 14.898px;
      font-style: normal;
      font-weight: 600;
      line-height: 135%; /* 20.112px */
      letter-spacing: 0.621px;
    }
  }
`