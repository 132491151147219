import { takeLatest, call, put } from "redux-saga/effects"
import { isSuccess } from "services/Utils"
import * as Actions from "./action"
import { getAppSettingsAPI } from "./api"

function* handleGetAppSettings(action) {
  try {
    const res = yield call(getAppSettingsAPI, action.data)

    if (isSuccess(res)) {
      const { response } = res.data
      yield put(Actions.getAppSettingsAction.success(response))
    }
  } catch (e) {
    console.error("API error:", e)
    yield put(Actions.getAppSettingsAction.failure(e))
  }
}

export default function* main() {
  yield takeLatest(Actions.getAppSettingsAction.REQUEST, handleGetAppSettings)
}
