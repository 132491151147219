import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import FileUpload from "dumbComponents/TransactionCoordinationV2/Forms/FileUpload"
import colors from "@colors"
import { get } from "lodash"
import root from "window-or-global"
import queryString from "query-string"
import Loader from "@ui/Loader"
import TechAssetsContainer from "container/TechAssets"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, Radio, Space, Upload,
} from "@ui/antd"
import { UploadOutlined } from "@ant-design/icons"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-label {    
    > label {
      color: #303030;
      font-family: ${ATC};
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    
  }
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const Container = styled.div`
  display: flex;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
  margin-top: 56px;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`
class CoverSheet extends PureComponent {
  state = {
    showEscrowOption: null,
    notTCOption: null,
  }

  getBackRoute = () => {
    const { isFromDirectForm, backFormRoute, history } = this.props
    if (!isFromDirectForm) {
      history.push("/realty/transaction-coordination/contract/transaction-details")
    }
    history.push(backFormRoute)
  }

  // getNextRoute = (isDraft) => {
  //   const { isFromDirectForm, mainPageRoute, nextFormRoute } = this.props
  //   if (!isFromDirectForm) {
  //     return isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/transaction-details"
  //   }
  //   return isDraft ? mainPageRoute : nextFormRoute
  // }

  onFinish = (fieldValues) => {
    const {
      updateListingCoverSheet, history, dealToView, getListingCoverSheetResponse, updateDealStatus, uploadTCFiles, uploadTCFilesResponse,
    } = this.props
    uploadTCFiles({
      file: fieldValues.tc_file_upload.file,
      id: dealToView,
      callback: () => {
        history.push("/realty/transaction-coordination/contract/successfully-filed")
      },
    })
  }

  setShowEscrowOption = (value) => {
    this.setState({
      showEscrowOption: value === 1,
      notTCOption: value === 0,
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { showEscrowOption, notTCOption } = this.state
    const tcInfo = getListingCoverSheetResponse.data?.tcInfo
    const result = {}
    if (tcInfo) {
      if (showEscrowOption === null && notTCOption === null) {
        this.setState({
          showEscrowOption: tcInfo.is_using_deal_tc === 1,
          notTCOption: tcInfo.is_using_deal_tc === 0,
        })
      }
      return {
        ...tcInfo,
      }
    }
    return result
  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  render() {
    const { showEscrowOption, notTCOption } = this.state
    const {
      history, getListingCoverSheetResponse, updateListingCoverSheetResponse, uploadTCFilesResponse,
      getContractByIdResponse, uploadTCFilesToDbResponse, transactionFormUpdateStageResponse,
      fetchTCDocumentsFromS3Response, deleteTCDocumentsFromS3Response, dealToView, updateDealStatus,
      fetchTCFiles,
    } = this.props
    const {
      isFetching: isUploadingToS3,
    } = uploadTCFilesResponse || {}

    const {
      isFetching: isUploadingToDB,
    } = uploadTCFilesToDbResponse || {}

    const {
      isFetching: isUpdatingStage,
    } = transactionFormUpdateStageResponse || {}

    const {
      data: fetchedFiles,
      isFetching: isFetchingFiles,
    } = fetchTCDocumentsFromS3Response || {}

    const {
      isFetching: isDeletingFiles,
    } = deleteTCDocumentsFromS3Response || {}

    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const isProcessing = isUploadingToS3 || isUploadingToDB || isUpdatingStage || isFetchingFiles || isDeletingFiles
    return (
      <Wrap>
        <HeaderNav>
          Upload Files
        </HeaderNav>
        <Container>
          <RightSideBar>
            {/* {getListingCoverSheetResponse.isFetching ? (<Loader />) : ( */}
            <LabelText>
              <p>
                Please upload the fully executed contract documents for this transaction. If you are using a TC, please ensure they have access to your CAR/MLS/DocuSign credentials so they can begin handling this file.
              </p>
              <br />
              <br />
            </LabelText>
            <FileUpload dealId={dealToView} disabled={!(!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])))} />
            <FlexBox>
              <FlexBox wd="100%"></FlexBox>
              <FlexBox>
                <Form.Item>
                  <Button
                    style={{
                      width: 119,
                      height: 48,
                      background: "white",
                      float: "right",
                      borderColor: "#3B60E4",
                      color: "#3B60E4",
                    }}
                    type="primary"
                    shape="round"
                    size="large"
                    // htmlType="submit"
                    onClick={() => {
                      this.getBackRoute()
                    }}
                  >
                    Back
                  </Button>
                </Form.Item>
                {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                        color: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      disabled={!fetchedFiles}
                      loading={isProcessing}
                      onClick={() => {
                        history.push("/realty/transaction-coordination/contract/successfully-filed")
                        updateDealStatus({
                          dealId: dealToView,
                          step: "submitted",
                        })
                        fetchTCFiles({
                          skip: 0,
                          limit: 100,
                          agentId: cookie.load("agentId"),
                          ignoreReset: true,
                        })
                      }}
                    // onClick={() => { history.push("/realty/listings/coversheet/escrow-info") }}
                    >
                      Finish
                    </Button>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#3B60E4",
                        float: "right",
                        borderColor: "white",
                        color: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      disabled={!fetchedFiles}
                      loading={isProcessing}
                      onClick={() => { history.push("/realty/transaction-dashboard") }}
                    >
                      Finish
                    </Button>
                  </Form.Item>
                )}
              </FlexBox>
            </FlexBox>
            {/* </Form> */}
            {/* )} */}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default withRouter(TransactionContainer(TechAssetsContainer(CoverSheet)))

