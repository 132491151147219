import React from "react"
import styled, { css } from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import { ErrorLabel } from "./common"

const Label = styled.label`
  color: ${colors.starInactive};
  pointer-events: none;
  transition-duration: 0.2s;
  font-weight: 100;
  input {
    ${props => props.meta.touched && props.meta.error && `
      border: 1px solid #ED1616 !important;
    `}
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.contentText};
  position: relative;

  input:active,
  input:focus {
    & ~ ${Label} {
      top: -12px;
      left: 0px;
    }
  }

  ${props => props.isFilled && css`
    ${Label} {
      top: -12px !important;
      left: 0px !important;
    }
  `}

  ${props => !props.ignoreOtherLabelStyles && props.isMaterial && css`
    ${Label} {
      position: absolute;
      top: 22px;
      left: 39px;
    }
  `}


`

const Input = styled.input`
  border: 1px solid ${colors.starInactive};
  border-radius: 2px;
  font-size: 16px;
  padding: 8px 16px 10px 10px;
  outline: none;
  transition-duration: 0.3s;
  width: 100%;
  font-family: ${ATC};
  /* Hiding Arrowss */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${props => props.isErrored ? "#ED1616" : "#ccc"};
    font-weight: 300;
    font-size: 12px;

    ${props => props.bottomForm && `
        font-family: Arial !important;
        font-size: 15px !important;
        font-weight: 300 !important;
        line-height: 35px !important;
        letter-spacing: 0px !important;
        text-align: left !important;
        color: #727272 !important;
    `}
  }

  &:focus, &:active {
    border-bottom: 1px solid ${colors.primaryColor};
  }

  &::after{
    content: "+1";
    background: red;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${props => props.meta.touched && props.meta.error && `
      border: 1px solid #ED1616 !important;
    `}
`

const InputWrap = styled.div`
  display: flex;
  align-items: center;
`

const AreaCode = styled.div`
  color: ${colors.tagInactive};
  margin-right: 10px;
  margin-top: 10px;
`

const RenderPhoneInput = ({
  label,
  placeholder,
  input: { value },
  input,
  inputId,
  areaCode,
  meta,
  isShowAreaCode,
  isAreaCode,
  inputStyle,
  type,
  isMaterial = true,
  isShowError,
  labelStyles = {},
  errorStyles,
  ignoreOtherLabelStyles,
  bottomForm,
  isLandingPage,
  errorPos,
}) => {
  const isErrored = meta.touched && meta.error && meta.error === "* Required"
  return (
    <Wrap
      isMaterial={isMaterial}
      isFilled={Boolean(value)}
      ignoreOtherLabelStyles={ignoreOtherLabelStyles}
    >
      {label && (
        <Label style={{ ...labelStyles }} meta={meta}>{label}</Label>
      )}
      <InputWrap>
        {isShowAreaCode && (
          <AreaCode>{areaCode}</AreaCode>
        )}
        <Input
          type={type}
          bottomForm={bottomForm}
          placeholder={isMaterial ? "" : (isAreaCode ? areaCode : placeholder)}
          id={inputId}
          meta={meta}
          style={{
            borderBottom: "none",
            flex: 1,
            ...inputStyle,
          }}
          isErrored={isErrored}
          {...input}
        />
        {(meta.touched || isShowError) && meta.error && isLandingPage && (
          <ErrorLabel
            isLandingPage={isLandingPage}
            errorPos={errorPos}
          >
            <span>
              <img src={`${CLOUDFRONT}/AlertIcon.png`} alt="error" />
            </span>
          </ErrorLabel>
        )}
      </InputWrap>
      {meta.touched && meta.error && !isLandingPage && (
        <ErrorLabel style={{ textAlign: "left", ...errorStyles || {} }}>{meta.error}</ErrorLabel>
      )}
      {isShowError && (
        <ErrorLabel style={{ textAlign: "left", ...errorStyles || {} }}>{isShowError}</ErrorLabel>
      )}
    </Wrap>
  )
}

RenderPhoneInput.defaultProps = {
  areaCode: "+1",
  placeholder: "(xxx) xxx-xxxx",
  isAreaCode: false,
  isShowAreaCode: true,
  type: "text",
}

export default RenderPhoneInput
