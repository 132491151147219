import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as PropertyStatusEmailer from "./actions"

const initState = {
  currentPropertyStatus: "",
  ...createDeltaReducer("fetchPropertyStatusResponse"),
  ...createDeltaReducer("updatePropertyStatusResponse"),
  ...createDeltaReducer("deletePropertyStatusResponse"),
  ...createDeltaReducer("updatePropertyStatusFromProfileResponse"),
}

export default function propertyStatusReducer(state = initState, action) {
  switch (action.type) {
    case PropertyStatusEmailer.getPropertyStatusListAction.FAILURE:
    case PropertyStatusEmailer.getPropertyStatusListAction.REQUEST:
    case PropertyStatusEmailer.getPropertyStatusListAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyStatusEmailer.getPropertyStatusListAction,
          "fetchPropertyStatusResponse",
        ),
      }
    }

    case PropertyStatusEmailer.handleUpdateSelectAction.type: {
      const {
        currentPropertyStatus,
      } = action.data
      return {
        ...state,
        currentPropertyStatus,
      }
    }

    case PropertyStatusEmailer.toggleModalAction.type: {
      return {
        ...state,
        isPropertyDeleteModalOpen: action.data,
      }
    }

    case PropertyStatusEmailer.handleUserConfirmationAction.type: {
      return {
        ...state,
        isPropertyDeleteModalOpen: action.data,
      }
    }

    //deletePropertyAction
    case PropertyStatusEmailer.deletePropertyAction.FAILURE:
    case PropertyStatusEmailer.deletePropertyAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyStatusEmailer.deletePropertyAction,
          "deletePropertyStatusResponse",
        ),
      }
    }
    //updatePropertyStatusFromProfileAction

    case PropertyStatusEmailer.updatePropertyStatusFromProfileAction.FAILURE:
    case PropertyStatusEmailer.updatePropertyStatusFromProfileAction.REQUEST:
    case PropertyStatusEmailer.updatePropertyStatusFromProfileAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyStatusEmailer.updatePropertyStatusFromProfileAction,
          "updatePropertyStatusFromProfileResponse",
        ),
      }
    }

    case PropertyStatusEmailer.updatePropertyStatusAction.FAILURE:
    case PropertyStatusEmailer.updatePropertyStatusAction.REQUEST:
    case PropertyStatusEmailer.updatePropertyStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          PropertyStatusEmailer.updatePropertyStatusAction,
          "updatePropertyStatusResponse",
        ),
      }
    }

    default: {
      return state
    }
  }
}
