import React, { useEffect, useState } from "react"
import styled from "styled-components"
import queryString from "query-string"
import root from "window-or-global"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import { ATC, INTER } from "@fonts"

const Wrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-family: ${INTER};
`

const RedirectionPage = () => {

  const [url, setUrl] = useState("")
  const [count, setCount] = useState(3)

  useEffect(() => {
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)

    let outsideTimer = setTimeout(() => {
        setCount((curr) => curr - 1)
    }, 1000)


    const {
      redirectTo = ""
    } = parsedQuery || {}

    setUrl(redirectTo)

    
    const countdown = (currentCount) => {
      if (currentCount > 0) {
        setTimeout(() => {
          setCount(currentCount - 1);
          countdown(currentCount - 1);
        }, 1000);
      } else {
        const finalUrl = redirectTo.indexOf("https") > -1 ? redirectTo : `https://${redirectTo}`;
        root.location.href = finalUrl;
      }
    };

    countdown(count);

    return () => {
      const highestTimeoutId = setTimeout(() => {});
      for (let i = 0; i <= highestTimeoutId; i++) {
        clearTimeout(i);
      }
    }
  }, [])

  return (
    <Wrap>
      <p>
        {`You are being redirected to ${url || "another website"} in ${count} seconds.`}
      </p>
      <p>
        Click
        {" "}
        <span>
           <a href={url}>here</a>
        </span>
        {" "}
        if not redirected
      </p>
    </Wrap>
  )
}

export default withRouter(RedirectionPage)