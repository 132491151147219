import React from "react"
import root from "window-or-global"
import { enabledFeatureCheck } from "services/Utils"
import cookie from "services/CookieStorage"
import { InnerTab, NavLink } from "./commonStyles"
import Listings from "../Icons/NewIcons/Listings"

const TransactionDashboard = ({ routeArray, isBroker }) => {
  const tcInformation = cookie.load("tcInformation")
  const flag = isBroker || tcInformation?.is_tc
  return (
    <>
      {flag ? (
        <InnerTab isActive={routeArray[1] === "transaction-dashboard"}>
          <div className="icon-block-new">
            <Listings />
          </div>
          <div className="text-block">
            <NavLink
              to={
                enabledFeatureCheck({ isBroker, tabName: "transaction_dashboard_tab" })
                  ? "/realty/transaction-dashboard"
                  : "/realty/promos?type=listings"
              }
              isActive={routeArray[1] === "transaction-dashboard"}
              onClick={() => {
                root.scrollTo(0, 0)
                if (root.heap) {
                  root.heap.track("click_sidebar_productAndServicesRAR_web")
                }
              }}
            >
              My Transactions
            </NavLink>
          </div>
        </InnerTab>
      ) : (<> </>)
      }
    </>
  )

}

export default TransactionDashboard
