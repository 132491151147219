import React from "react"
import { Form, NewRadioGroup, NewRadioButton } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  RadioWrap,
  RadioQuestion,
} from "./styles"

const InputRadio = ({
  name,
  required = false,
  onChange,
  text,
  disabled,
  value,
  question,
  options,
}) => {
  const getRules = () => {
    if (required) {
      return [{ required: true, message: "Required" }]
    }
    return []
  }

  return (
    <Form.Item
      name={name}
      rules={getRules()}
      label={<LabelComponent text={`${text}`} required={required} />}
      required={required}
      disabled={disabled}
    >
      <RadioWrap>
        <RadioQuestion>
          <p>{question}</p>
        </RadioQuestion>
        <NewRadioGroup
          onChange={onChange}
          //value={value}
          value={value}
          size="small"
          style={{
            display: "flex",
          }}
        >
          {options && options.map(item => (
            <NewRadioButton value={item.value}>{item.label}</NewRadioButton>
          ))}

        </NewRadioGroup>
      </RadioWrap>
    </Form.Item>
  )
}

export default InputRadio
