import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { authCheck } from "services/auth"
import { getFormValues } from "redux-form"
import {
  fetchApplicants,
  getAllCreatedJobs,
  createJob,
  updateJob,
  deleteJob,
  showShareModal,
  showJobCreateModal,
  showJobDeleteModal,
  showJobEditModal,
  setShowInitialRecruitmentPage,
  getAllCreatedPublicJobs,
  markJobFavourite,
  toggleJobsDetailsModalAction,
  initialPageTabToggleAction,
  toggleModalPagesAction,
  currentJobViewedAction,
  applyJobAction,
  updateJobAction,
  setLocationValuesForJobsAction,
  markSeenAction,
  getSpecificCreatedPublicJobs,
  setCurrentSearchTermAction,
} from "./actions"

const mapStateToProps = (state, ownProps) => {
  const { app } = state
  const { brokerJobsDashboard: brokerJobsDashboardServer } = (ownProps && ownProps.staticContext) || {}
  const { techAssetsRarDashboard, brokerJobsDashboard } = state
  const assetsCreativeRequestForm = getFormValues("CREATIVE_REQUEST_FORM")(state)
  // const mortgageForm = getFormValues("MORTGAGE_FORM")(state)
  // const flyHomesForm = getFormValues("FLYHOMES_FORM")(state)
  // const zoomCasaForm = getFormValues("ZOOM_CASA")(state)
  // const curbioForm = getFormValues("CURBIO_FORM")(state)
  const JobFormOne = getFormValues("APPLY_JOB_FORM_I")(state)
  const JobFormTwo = getFormValues("APPLY_JOB_FORM_II")(state)
  const isLogged = authCheck()
  const {
    user,
  } = app
  return {
    isLogged,
    ...techAssetsRarDashboard,
    ...brokerJobsDashboard,
    ...brokerJobsDashboardServer,
    assetsCreativeRequestForm,
    // mortgageForm,
    // flyHomesForm,
    // zoomCasaForm,
    // curbioForm,
    JobFormOne,
    JobFormTwo,
    user,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchApplicants: fetchApplicants.request,
      getAllCreatedJobs: getAllCreatedJobs.request,
      createJob: createJob.request,
      updateJob: updateJob.request,
      deleteJob: deleteJob.request,
      showShareModal: showShareModal.request,
      showJobCreateModal: showJobCreateModal.request,
      showJobEditModal: showJobEditModal.request,
      showJobDeleteModal: showJobDeleteModal.request,
      setShowInitialRecruitmentPage: setShowInitialRecruitmentPage.request,
      getAllCreatedPublicJobs: getAllCreatedPublicJobs.request,
      markJobFavourite: markJobFavourite.request,
      toggleJobsModal: toggleJobsDetailsModalAction.call,
      toggleFirstPageTab: initialPageTabToggleAction.call,
      setCurrentViewedJob: currentJobViewedAction.call,
      toggleModalPages: toggleModalPagesAction.call,
      setLocation: setLocationValuesForJobsAction.call,
      applyToJob: applyJobAction.request,
      updateJobApplication: updateJobAction.request,
      markSeen: markSeenAction.request,
      getSpecificCreatedPublicJobs: getSpecificCreatedPublicJobs.request,
      setCurrentSearchTerm: setCurrentSearchTermAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
