import React, { useEffect } from "react"
import styled from "styled-components"
import RadiusNetworkImagePreview from "images/Graphics/LandingPagePreviews/HomePagePreview.png"
import { Helmet } from "react-helmet"
import { get } from "lodash"
import queryString from "query-string"
import root from "window-or-global"
import container from "container/App/applyFlowContainer"
import HeaderContent from "./Header"
import Footer from "../common/Footer"
import WebinarInfo from "./WebinarInfo"
import Testimonials from "./Testimonials"
import FlatFee from "./FlatFee"
import Companies from "./Companies"

// eslint-disable-next-line max-len
const META_DESCRIPTION = "Real estate brokerage for performing agents and teams - no split. Only focus on sales and customer service, while Radius does the rest."

const Wrap = styled.div`
`

const NewLandingPage = ({
  toggleApplyModal,
}) => {
  const meta = {
    title: "Radius Community of Real Estate Professionals",
    description: META_DESCRIPTION,
    keywords: "Referrals, community, real estate agents, marketplace, network, coaching, training, education",
    type: "website",
    url: `${WEB_URL}network`,
    image: RadiusNetworkImagePreview,
  }

  useEffect(() => {
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const { tag } = parsedQuery
    console.log(tag, "showGetStartedForm")
    if (tag === "get_started_form") {
      toggleApplyModal({
        bool: true,
        accountType: "owner",
        headerText: "Nice to meet you!",
        subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
      })
    }
  })
  return (
    <Wrap>
      <Helmet>
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.title} />
        <meta property="title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="description" content={meta.description} />
        <meta property="keywords" content={meta.keywords} />
        <meta property="og:image" content={meta.image} />
        <meta property="image" content={meta.image} />
      </Helmet>
      <HeaderContent toggleApplyModal={toggleApplyModal} />
      <Companies />
      <WebinarInfo />
      <Testimonials toggleApplyModal={toggleApplyModal} />
      {/* <FlatFee toggleApplyModal={toggleApplyModal} /> */}
      <Footer />
    </Wrap>
  )
}

export default container(NewLandingPage)
