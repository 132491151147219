import cookie from "services/CookieStorage"
import LogRocketLib from "logrocket"

// temp constant variable

const testEmails = [
  "bijuashokan@radiusagent.com",
  "vanessabrown@radius.com",
  "neethu@ad.com",
  "finalship@ad.com",
  "robin@radiusagent.com",
  "erika@radiusagent.com",
  "nolan@radiusagent.com",
  "shannon@radiusagent.com",
  "channing@radiusagent.com",
  "testrar@ad.com",
  "joel.lee@radiusagent.com",
  "sam.kasle@radiusagent.com",
  "jennifer.doepke@radiusagent.com",
  "droidan@test.com",
  "tc@california.tc",
  "lokem@ad.com",
  "rumsum@ad.com",
  "rumsum2@ad.com",
  "kane@test.com",
  "ashuthosh+890021@radiusagent.com",
  "oscarp@radusagent.com",
  "landotest@radiusagent.com",
  "anukriti+1505@radiusagent.com",
  "pavan@radiusagent.com",
  "ashuthosh+890023@radiusagent.com",
  "vishal.gupta@radiusagent.com",
  "prachi.talreja@radiusagent.com",
  "aashin@radiusagent.com",
  "ashuthosh+67234@radiusagent.com",
  "anukriti+776@radiusagent.com",
  "checop@radiusagent.com",
  "ashuthosh+820051@radiusagent.com",
  "aashin+network@radiusagent.com",
  "swapnil+11@radiusagent.com",
  "ashuthosh@radiusagent.com",
  "poojavyas@radiusagent.com",
  "rishit@radiusagent.com",
  "aashin+200044@radiusagent.com",
  "henry@ad.com"
]

const emailsToTrack = [
  "henry@ad.com",
  "grace@radiusagent.com",
]
class LogRocket {
  initialize = (trackOnlyAuthorized = true) => {
    try {
      const user = cookie.load("user")
      if (ENV !== "production") {
        return
      }
      if (trackOnlyAuthorized && !(user && user.agent_id)) {
        return
      }
      if (user && user.agent_id && !testEmails.includes(user.email) && (user.email && !user.email.includes("@ad.com"))) {
        // apply for odd even rule like delhi traffic here as well
        // LogRocketLib.init("ksghyr/radiusagent")
        LogRocketLib.init("3q0c2i/radius-brokerage-platform")
        LogRocketLib.identify(user.agent_id, {
          name: `${user.firstname || ""} ${user.lastname || ""}`,
          email: user.email,
          agent_image: user.agent_image,
          agency_name: user.agency_name,
          profile_url: user.profile_url,
        })
      } else if (user && user.email && user.agent_id && emailsToTrack.includes(user.email)) {
        LogRocketLib.init("3q0c2i/radius-brokerage-platform")
        LogRocketLib.identify(user.agent_id, {
          name: `${user.firstname || ""} ${user.lastname || ""}`,
          email: user.email,
          agent_image: user.agent_image,
          agency_name: user.agency_name,
          profile_url: user.profile_url,
        })
      }
    } catch (err) {
      console.warn("Error initializing Logrocket!")
    }
  }
}
export default new LogRocket()
