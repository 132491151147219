import React from "react"
import { Link, withRouter } from "react-router-dom"
import { isEmpty, get, propertyOf } from "lodash"
import { addDays } from "date-fns"
import root from "window-or-global"
import query from "query-string"
import container from "container/App/sidebarContainer"
import RealtyContainer from "container/Realty"
import LinkOrA from "dumbComponents/common/Navbar/LinkOrA"
import teamContainer from "container/Realty/TeamProfileContainer"
import TechAssetsContainer from "container/TechAssets"
import navBarContainer from "container/Navbar"
import globalContainer from "container/App"
import lmpContainer from "container/Marketplace"
import { ShimmerBox } from "shared/styles/animation"
import { SIDEBAR_WIDTH } from "@consts"
import cookie from "services/CookieStorage"
import { authCheck } from "services/auth"
import OnboardingContainer from "container/OnboardingSetup"
import { checkForUserObject, checkIfTeamUpdatedByLead, canAccessFeature } from "services/Utils"
import MortgagePopup from "dumbComponents/common/UI/SitePopups/MortgagePopup"
import {
  Block,
  SidebarContainer,
  SidebarBody,
  SidebarFooter,
} from "./Components/commonStyles"

import {
  HomeTab,
  TeamProfileTab,
  PostAJobTab,
  RecruitAndEarnTab,
  ProductsTab,
  AssetsTab,
  SubmitTransactionTab,
  SubmitListingsTab,
  CashbackTab,
  MortgageTab,
  StagingTab,
  CashOffersTab,
  ResourcesTab,
  CRMTab,
  FindAnAgentTab,
  MarketplaceTab,
  ClaimedLeadsTab,
  SubmittedLeadsTab,
  NewsFeedTab,
  RoomsTab,
  JobsTab,
  WellnessTab,
  LeaderboardTab,
  AgentWebsiteTab,
  NotificationsTab,
  ShimmerLoader,
  StatsBoxes,
  AcademyTab,
  SupportTab,
  JoinOurBrokerageTab,
  ListingVideosTab,
  OffersTab,
  TeamDevelopment,
  CommissionTracker,
  ReferAndEarnPromo,
  WellnessPromo,
  ProductsPromoTab,
  RequestCreativeTab,
  JoinBrokerageTab,
  UserProfile,
  TeamDetails,
} from "./Components"

const isLoggedIn = authCheck()

export const checkIfOnboardingBannerIsVisible = (callback) => {
  if (document) {
    const $el = document.getElementById("onboarding-banner")

    if ($el) {
      return callback(true)
    }

    return callback(false)
  }
}

class Sidebar extends React.PureComponent {
  state = {
    isShow: false,
    leadAgreementSigned: cookie.load("leadAgreementSigned"),
    isOnboardingBannerVisible: false,
  }

  componentDidMount() {
    const {
      fetchNotificationUnreadCount,
      match,
      getSidebarUpdates,
      sideMenuUpdateresponse,
      fcmRegister,
      getLeadForMarketplace,
    } = this.props

    const {
      data: updateData,
    } = sideMenuUpdateresponse

    checkIfOnboardingBannerIsVisible((isVisible) => {
      this.setState(prevState => ({
        ...prevState,
        isOnboardingBannerVisible: isVisible,
      }))
    })

    const user = checkForUserObject("user")
    this.agentId = get(user, "agent_id") || get(user, "id")

    const id = parseInt(cookie.load("brokerageTeamId"), 10) || ""
    if (id && id > 0) {
      // getTeamDetails({
      //   id,
      // })
    }

    getLeadForMarketplace({
      agentId: this.agentId,
    })

    cookie.remove("notificationDotClicked")

    const token = cookie.load("FCMToken")

    if (token) {
      fcmRegister({
        token,
      })
    }

    const isFindAnAgentNewTag = cookie.load("findAnAgentNewTag")
    if (!isFindAnAgentNewTag) {
      cookie.save(
        "findAnAgentNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const teamProfileFeatureNewTag = cookie.load("teamProfileFeatureNewTag")
    if (!teamProfileFeatureNewTag) {
      cookie.save(
        "teamProfileFeatureNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const jobsNewTag = cookie.load("jobsNewTag")
    if (!jobsNewTag) {
      cookie.save(
        "jobsNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const wellnessNewTag = cookie.load("wellnessNewTag")
    if (!wellnessNewTag) {
      cookie.save(
        "wellnessNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const cashbackNewTag = cookie.load("cashbackNewTag")
    if (!cashbackNewTag) {
      cookie.save(
        "cashbackNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const homeNewTag = cookie.load("homeNewTag")
    if (!homeNewTag) {
      cookie.save(
        "homeNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const mortgageNewTag = cookie.load("mortgageNewTag")
    if (!mortgageNewTag) {
      cookie.save(
        "mortgageNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const listingVideosTag = cookie.load("listingVideosTag")
    if (!listingVideosTag) {
      cookie.save(
        "listingVideosTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const supportNewTag = cookie.load("supportNewTag")
    if (!supportNewTag) {
      cookie.save(
        "supportNewTag",
        true,
        {
          expires: addDays(new Date(), 15),
        }
      )
    }

    const recruitAndEarnKey = cookie.load("recruitAndEarnKey")
    if (!recruitAndEarnKey) {
      cookie.save(
        "recruitAndEarnKey",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    const submitListings = cookie.load("submitListings")
    if (!submitListings) {
      cookie.save(
        "submitListings",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }

    const offers = cookie.load("offers")
    if (!offers) {
      cookie.save(
        "offers",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }

    const submitContracts = cookie.load("submitContracts")
    if (!submitContracts) {
      cookie.save(
        "submitContracts",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }

    if (!cookie.load("isBrokerNewTagShown")) {
      cookie.save(
        "isBrokerNewTagShown",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    if (!cookie.load("isRollaNewTag")) {
      cookie.save(
        "isRollaNewTag",
        true,
        {
          expires: addDays(new Date(), 7),
        }
      )
    }

    if (!isEmpty(user)) {
      this.setState({
        isShow: true,
      })
    }

    fetchNotificationUnreadCount()
    getSidebarUpdates()

    const isContactDashboardNewTag = cookie.load("isContactDashboardNewTag")
    const isFinancingDashboardNewTag = cookie.load("isFinancingDashboardNewTag")
    const isRecruitmentDashboardNewTag = cookie.load("isRecruitmentDashboardNewTag")

    if (!isContactDashboardNewTag) {
      cookie.save(
        "isContactDashboardNewTag",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }

    if (!isFinancingDashboardNewTag) {
      cookie.save(
        "isFinancingDashboardNewTag",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }

    if (!isRecruitmentDashboardNewTag) {
      cookie.save(
        "isRecruitmentDashboardNewTag",
        true,
        {
          expires: addDays(new Date(), 30),
        }
      )
    }
    this.setState({
      isRecruitmentDashboardNewTag,
    })
  }

  componentDidUpdate() {
    checkIfOnboardingBannerIsVisible((isVisible) => {
      this.setState(prevState => ({
        ...prevState,
        isOnboardingBannerVisible: isVisible,
      }))
    })
  }

  handleHomeClick = () => {
    const {
      getFeedByHomeClick,
      history,
      hideNewFeedNotificationDot,
    } = this.props

    root.document.title = "Community | Radius Agent"

    getFeedByHomeClick({
      push: history.push,
    })
    hideNewFeedNotificationDot()
  }

  createRouteArray = (pathname = "") => pathname && pathname.split("/").splice(1)

  handleLearnMore = () => {
    const { RARTracker } = this.props
    cookie.save("isBrokerNewTagShown", false)
    if (isLoggedIn) {
      RARTracker({
        payload: {
          source: "community-left-side-menu",
        },
      })
    }
  }

  handleApplyNowVersion2 = () => {
    const {
      toggleApplyModal,
      heap,
      RARTracker,
    } = this.props
    if (root.heap) {
      root.heap.track(heap)
    }
    root.history.pushState("", "", "community?interestSource=Left Menu")

    RARTracker({
      payload: {
        source: "Click Left Menu",
      },
    })

    toggleApplyModal({
      bool: "true",
      accountType: "owner",
      headerText: "Nice to meet you!",
      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
    })
  }

  isMortgageEnabled = () => {
    const enabledState = "California"
    const { user, agentState } = this.props
    const {
      citiesServed,
    } = user || {}
    const States = agentState && agentState.data ? agentState.data.map(st => st.name) : []
    if (citiesServed) {
      citiesServed.forEach((cityInfo) => {
        States.push(cityInfo.state)
      })
    }
    return States.includes(enabledState)
  }

  enableTab = (tabName) => {
    const tcInformation = cookie.load("tcInformation")
    // const { isBroker } = this.props
    if (tcInformation?.is_tc) {
      return canAccessFeature({
        type: "tc",
        tabName,
      })
    } else {
      // same above infra in utils can be used for all access management control
      return true
    }
  }

  render() {
    // const {  } = this.state
    const {
      location,
      isBroker,
      agentWebsite,
      unreadCount,
      fetchNotificationUnreadCount,
      user,
      isAppMounted,
      sideMenuUpdateresponse,
      notificationViewed,
      homeRedDot,
      leads,
      isOpenPressReleaseBanner,
      getTeamDetailsResponse,
      getTeamDetailsForLogoResponse,
      toggleEditMode,
      popupDetails,
      showPopupDetails,
      fetchOrientationInfoResponse,
      getOnboardingSetupResponse,
      getRoomsResponse,
      isFetchingUserObject,
      showLoaderUntilTeamDetailsAreFetched,
    } = this.props

    const { isOnboardingBannerVisible } = this.state

    const { data: roomsList } = getRoomsResponse
    const showLiveTag = roomsList && roomsList.length > 0

    const {
      data: updateData,
    } = sideMenuUpdateresponse

    const {
      data: teamDetails,
      isFetching: fetchingTeamDetails,
    } = getTeamDetailsResponse || {}

    const {
      data: teamDetailsForLogo,
      isFetching: fetchingTeamDetailsForLogo,
    } = getTeamDetailsForLogoResponse || {}

    const {
      data: lmpData,
    } = leads || {}

    const {
      total,
    } = lmpData || {}

    const {
      isShow,
      leadAgreementSigned,
      isRecruitmentDashboardNewTag,
      isLoaded,
    } = this.state
    const isBrokerNewTagShown = cookie.load("isBrokerNewTagShown")
    const isRollaNewTagShown = cookie.load("isRollaNewTag")
    const tcInformation = cookie.load("tcInformation")

    const updatesObjectExist = updateData && Object.keys(updateData).length > 0
    const routeArray = this.createRouteArray(location.pathname) || []
    const thisAgentHasATeam = parseInt(cookie.load("brokerageTeamId"), 10) > 0
    const isDashboard = location.pathname && location.pathname.includes("/realty/dashboard")
    const { data: popupData } = popupDetails
    const completionPercentage = fetchOrientationInfoResponse?.data?.response !== null ? fetchOrientationInfoResponse?.data?.response?.form_completion_percentage : 0
    const isOrientationCompleted = getOnboardingSetupResponse?.data?.isOrientationCompleted && getOnboardingSetupResponse?.data?.form_completion_percentage < 100

    const { search } = root.location
    const parsedQuery = query.parse(search)

    const {
      type,
    } = parsedQuery || {}

    if (type) {
      routeArray[1] = type
    }

    if (showLoaderUntilTeamDetailsAreFetched) {
      return (
        <SidebarContainer
          isHide={!isShow}
          id="logged-in-sidebar"
          showOnboardingNav={isOnboardingBannerVisible}
          showLoaderUntilTeamDetailsAreFetched={showLoaderUntilTeamDetailsAreFetched}
        >
          <ShimmerBox br="0px" w={`${SIDEBAR_WIDTH}px`} h="100%" />
        </SidebarContainer>
      )
    }

    return (
      <SidebarContainer
        isHide={!isShow}
        id="logged-in-sidebar"
        showOnboardingNav={isOnboardingBannerVisible}
      >
        <SidebarBody>
          {showPopupDetails.showMortgagePopup && (<MortgagePopup />)}

          {/*Header Logo*/}
          {/* {isAppMounted ? (
            <>
              {isBroker ? (
                <LogoDiv
                  show={thisAgentHasATeam}
                  thisAgentHasATeam={thisAgentHasATeam}
                  isLoaded={!fetchingTeamDetails && teamDetails && teamDetails.icon_url}
                  fallback="https://d2fedz0by71ckz.cloudfront.net/images/rds-logo-blue.gif"
                >
                  <TeamLogo
                    isImageLoaded={isLoaded}
                    onLoad={() => {
                      this.setState({
                        isLoaded: true,
                      })
                    }}
                    src={teamDetails && teamDetails.icon_url}
                    alt="test_img"
                  />
                </LogoDiv>
              ) : (
                <PromoCard
                  onClick={() => {
                    this.handleApplyNowVersion2()
                  }}
                />
              )}
            </>
          ) : (
            <ShimmerBox
              h="142px"
              w="202px"
              style={{
                marginBottom: "20px",
                marginLeft: "20px",
                borderRadius: "8px",
              }}
            />
          )} */}

          <TeamDetails
            routeArray={routeArray}
            checkIfTeamUpdatedByLead={checkIfTeamUpdatedByLead}
            teamDetails={teamDetails}
            teamDetailsForLogo={teamDetailsForLogo}
            toggleEditMode={toggleEditMode}
            isBroker={isBroker}
            thisAgentHasATeam={thisAgentHasATeam}
          />

          {/*TC BLOCK*/}
          <Block
            isBannerOpen={isOpenPressReleaseBanner}
            hide={!this.enableTab("transaction_management")}
          >
            <div className="header">
              <p>Transaction Management</p>
            </div>
            <>
              {isAppMounted ? (
                <>
                  {this.enableTab("transaction_management_crm") && (
                    <CRMTab routeArray={routeArray} />
                  )}
                  {this.enableTab("transaction_management_listing") && (
                    <SubmitListingsTab
                      routeArray={routeArray}
                      isBroker={isBroker}
                    />
                  )}
                  {this.enableTab("transaction_management_offer") && (
                    <OffersTab
                      routeArray={routeArray}
                      isBroker={isBroker}
                    />
                  )}
                  {this.enableTab("transaction_management_contract") && (
                    <SubmitTransactionTab
                      routeArray={routeArray}
                      isBroker={isBroker}
                    />
                  )}
                  {this.enableTab("transaction_management_home_tab") && (
                    <HomeTab
                      routeArray={routeArray}
                      isBroker={isBroker}
                    />
                  )}
                  {/* {isAppMounted && thisAgentHasATeam && (
                    <CashbackTab
                      routeArray={routeArray}
                    />
                  )} */}
                  {this.enableTab("transaction_management_resourcetab") && (
                    <ResourcesTab
                      routeArray={routeArray}
                      isBroker={isBroker}
                    />
                  )}
                </>
              ) : (
                <div>
                  {Array(5).fill(1).map(() => (
                    <>
                      <ShimmerBox
                        h="40px"
                        w="100%"
                        style={{
                          marginBottom: "4px",
                          opacity: "0.15",
                        }}
                      />
                    </>
                  ))}
                </div>
              )}
            </>
          </Block>

          <Block isBannerOpen={isOpenPressReleaseBanner} hide={!this.enableTab("bussiness_operation")}>
            <div className="header">
              <p>Helpful Links</p>
            </div>
            <>
              {isAppMounted ? (
                <>
                  <ProductsTab
                    routeArray={routeArray}
                    isBroker={isBroker}
                  />
                  <AssetsTab
                    routeArray={routeArray}
                    isBroker={isBroker}
                  />
                  <WellnessTab routeArray={routeArray} />
                  <RecruitAndEarnTab
                    routeArray={routeArray}
                    isBroker={isBroker}
                  />
                  <NotificationsTab
                    routeArray={routeArray}
                    updatesObjectExist={updatesObjectExist}
                    updateData={updateData}
                    notificationViewed={notificationViewed}
                    fetchNotificationUnreadCount={fetchNotificationUnreadCount}
                    unreadCount={unreadCount}
                  />
                  {/* {!isBroker ? (
                    <TeamProfileTab
                      routeArray={routeArray}
                      checkIfTeamUpdatedByLead={checkIfTeamUpdatedByLead}
                      teamDetails={teamDetails}
                      toggleEditMode={toggleEditMode}
                    />
                  ) : (
                    <>
                      {isBroker && thisAgentHasATeam && (
                        <TeamProfileTab
                          routeArray={routeArray}
                          checkIfTeamUpdatedByLead={checkIfTeamUpdatedByLead}
                          teamDetails={teamDetails}
                          toggleEditMode={toggleEditMode}
                          isBroker={isBroker}
                          thisAgentHasATeam={thisAgentHasATeam}
                        />
                      )}
                    </>
                  )} */}
                </>
              ) : (
                <div>
                  {Array(5).fill(1).map(() => (
                    <>
                      <ShimmerBox
                        h="40px"
                        w="100%"
                        style={{
                          marginBottom: "4px",
                          opacity: "0.15",
                        }}
                      />
                    </>
                  ))}
                </div>
              )}
            </>
          </Block>

          <Block isBannerOpen={isOpenPressReleaseBanner} hide={!this.enableTab("financing")}>
            <div className="header">
              <p>Financing</p>
            </div>
            {isAppMounted ? (
              <>
                {this.enableTab("transaction_management_mortgage") && (
                  <MortgageTab
                    routeArray={routeArray}
                  />
                )}
                {this.enableTab("transaction_management_stagingtab") && isBroker && (
                  <StagingTab
                    isBroker={isBroker}
                    routeArray={routeArray}
                  />
                )}
                {this.enableTab("transaction_management_cashoffertab") && (
                  <CashOffersTab
                    routeArray={routeArray}
                    isBroker={isBroker}
                  />
                )}
              </>
            ) : (
              <div>
                {Array(3).fill(1).map(() => (
                  <>
                    <ShimmerBox
                      h="40px"
                      w="100%"
                      style={{
                        marginBottom: "4px",
                        opacity: "0.15",
                      }}
                    />
                  </>
                ))}
              </div>
            )}
          </Block>

          <Block isBannerOpen={isOpenPressReleaseBanner} hide={!this.enableTab("community")}>
            <div className="header">
              <p>Community</p>
            </div>
            <NewsFeedTab
              routeArray={routeArray}
              homeRedDot={homeRedDot}
              handleHomeClick={this.handleHomeClick}
              showLiveTag={showLiveTag}
            />
            <RoomsTab
              routeArray={routeArray}
            />
            {/* {isAppMounted && thisAgentHasATeam && (
              <PostAJobTab
                routeArray={routeArray}
                isRecruitmentDashboardNewTag={isRecruitmentDashboardNewTag}
              />
            )} */}
            <JobsTab routeArray={routeArray} />
            <FindAnAgentTab routeArray={routeArray} />
            <MarketplaceTab
              routeArray={routeArray}
              updatesObjectExist={updatesObjectExist}
              updateData={updateData}
              leadAgreementSigned={leadAgreementSigned}
              total={total}
            />
          </Block>

          {/* <Block isBannerOpen={isOpenPressReleaseBanner} hide={!this.enableTab("marketting")}>
            <div className="header">
              <p>Marketing</p>
            </div>
            <RequestCreativeTab
              routeArray={routeArray}
              isBroker={isBroker}
            />
            <ListingVideosTab
              routeArray={routeArray}
              isRollaNewTagShown={isRollaNewTagShown}
            />
          </Block> */}

          {/*HELP BLOCK*/}
          <Block isBannerOpen={isOpenPressReleaseBanner} hide={!this.enableTab("help")}>
            <div className="header">
              <p>Help</p>
            </div>
            {/* <AcademyTab
              updatesObjectExist={updatesObjectExist}
              updateData={updateData}
            /> */}
            <SupportTab
              routeArray={routeArray}
              isBroker={isBroker}
            />
            {!isBroker && (
              <JoinBrokerageTab
                routeArray={routeArray}
                handleApplyNowVersion2={this.handleApplyNowVersion2}
                isBrokerNewTagShown={isBrokerNewTagShown}
                updatesObjectExist={updatesObjectExist}
                updateData={updateData}
              />
            )}
          </Block>
        </SidebarBody>

        {/* <SidebarFooter>
          <UserProfile profileImage={this.props.profileImage} user={user} />
        </SidebarFooter> */}
      </SidebarContainer>
    )
  }
}

export default withRouter(
  globalContainer(
    navBarContainer(
      container(
        lmpContainer(
          RealtyContainer(
            teamContainer(
              TechAssetsContainer(
                OnboardingContainer(
                  Sidebar
                )
              )
            )
          )
        )
      )
    )
  )
)