import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as AppActions from "container/App/actions"
import * as NetworkAction from "./actions"
import {
  finishTabsSpotlightFlowAction,
} from "container/App/actions"
import { getLikersList, getRequestingAgentToFollowFromStore } from "./selector"
import {
  followAgent,
  unfollowAgent,
  getLikers,
  getStandAloneFeed,
  updateFeedLike,
  updateCommentLike,
} from "./actionCreators"

const mapStateToProps = (state, ownProps) => {
  const feedServer = (ownProps && ownProps.staticContext && ownProps.staticContext.feedDetail) || {}
  const { feedDetail } = state.network || {}
  const { selectedFeed: feedClient, ...otherProps } = feedDetail
  const { network } = state
  const {
    isLikingFeed,
    isLikingComment,
    feedError,
    isDeletingFeed,
  } = network
  return {
    feedDetail: {
      ...otherProps,
      selectedFeed: {
        ...feedServer,
        ...feedClient,
      },
    },
    isDeletingFeed,
    feedError,
    likers: getLikersList(state, true),
    requestingAgentToFollow: getRequestingAgentToFollowFromStore(state),
    isLikingFeed,
    isLikingComment,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  openLikers: NetworkAction.openLikersAction,
  closeLikers: NetworkAction.closeLikersAction,
  openFeedDetailView: NetworkAction.openFeedDetailViewAction,
  getCommentsForFeed: NetworkAction.getCommentsForFeedAction.request,
  closeFeedDetailView: NetworkAction.closeFeedDetailViewAction,
  updateFeedLikeStandAlone: NetworkAction.updateFeedLikeStandAloneAction.request,
  postCommentForFeed: NetworkAction.postCommentForFeedAction.request,
  feedsViewed: NetworkAction.feedsViewedAction.request,
  hideNewQuestionAvailable: AppActions.hideNewQuestionAvailableAction,
  updateFeedLike,
  updateCommentLike,
  getStandAloneFeed,
  followAgent,
  unfollowAgent,
  getLikers,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
