import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import container from "container/Auth"
import { appleLogin } from "dumbComponents/Auth/SignupV2/consts"

const Wrap = styled.a`
  margin-top: 10px;
  display: inline-block;
  border-radius: 24.5px;
  img {
    box-shadow: 0px 1px 3px rgb(180,180,180);
    border-radius: 24.5px;
  }

  ${props => props.newUI && `
      margin-left: 10px;
      height: 32px;
      position: relative;
      top: -6px;
    `}
`

class AppleSignup extends React.PureComponent {
  createLoginLink = () => {
    const config = {
      response_type: "code",
      response_mode: "form_post",
      redirect_uri: root.encodeURIComponent(`${RADIUS_URL}/login`),
      scope: "name%20email",
      state: "a5f24dc7c2",
    }
    return `https://appleid.apple.com/auth/authorize?client_id=${APPLE_CLIENT_ID}&redirect_uri=${config.redirect_uri}&response_type=${config.response_type}&scope=${config.scope}&response_mode=${config.response_mode}&state=${config.state}`
  }

  render() {
    const {
      newUI,
    } = this.props
    const authURL = this.createLoginLink()
    return (
      <Wrap
        href={authURL}
        newUI={newUI}
        onClick={() => {
          if (root.heap) {
            root.heap.track("Click_Apple_Sign_In_SSO")
          }
        }}
      >
        <img
          src={appleLogin}
          alt="Apple Login"
        />
      </Wrap>
    )
  }
}

export default container(AppleSignup)
