import React, { useEffect } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import colors from "@colors"
import WhiteArrow from "images/Graphics/RadiusJobsAssets/ArrowRightWhite_1.svg"
import PlayButton from "dumbComponents/VideoRooms/components/LandingPage/Components/images/PlayButton.svg"
import { prettyUrl } from "dumbComponents/Academy/common/Helpers"
import {
  secondsInDuration,
} from "dumbComponents/Academy/common/Helpers"
import root from "window-or-global"

const Wrap = styled.div`
  display: flex;
  overflow: scroll;
  gap: 15px;
  flex-direction: row;
  align-items: baseline;

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`

const CardDetails = styled.div`
  position: relative;
  height: 230px;
  width: 410px;
  margin-bottom: 16px;

  h1 {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    @media(max-width: 767px) {
      font-size: 16px;
    }
  }

  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }

  img {
    height: 230px;
    width: 410px;
    border-radius: 8px;
  }
`

const RoomWrap = styled.div`
  position: absolute;
  top: 0;
  flex-direction: column;
  width: 410px;
  height: 235px;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 8px;

  ${props => props.isCourse && `
    background: linear-gradient(0deg, rgba(48, 48, 48, 0.3), rgba(48, 48, 48, 0.3));
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  h1 {
    display: none;
  }

  p {
    display: none;
  }

  img {
    height: 64px;
    width: 64px;
    display: block;
  }

  &:hover {
    ${props => !props.isCourse && `
      background: linear-gradient(0deg, rgba(48, 48, 48, 0.7), rgba(48, 48, 48, 0.7));
    `}
    display: flex;

    h1 {
      font-family: ${ATC};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: ${colors.white};
      display: block;
    }
  
    p {
      font-family: ${ATC};
      font-style: normal;
      font-weight: 200;
      font-size: 14px;
      line-height: 20px;
      color: ${colors.dreamWhite};
      display: block;
    }
  }
`

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  h1 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: ${colors.black};
    margin-top: 5px;

    @media(max-width: 1024px) {
      font-size: 16px;
    }
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.suvaGray};
    margin-top: 5px;
  }
`

const RoomImage = styled.div`
  height: 235px;
  width: 410px;
  border-radius: 8px;
  
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
`

const Tag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
  margin-top: 10px;

  img {
    width: 91px;
    height: 37px;
  }
`

const JoinRoomTag = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 10px;
  margin-bottom: 10px;

  p {
    font-family: ${ATC};
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    img {
      width: 16px;
      height: 16px; 
      position: relative;
      top: 3px;
      left: 2px;
    }
  }
`

const RoomTitle = styled.h1`
  max-width: 350px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${colors.black};
  margin-top: 5px;

  @media(max-width: 1024px) {
    font-size: 16px;
  }

`

const newFallbackImage = "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_New_Fallback.jpg"

const EventList = ({
  sortedEvents,
  id,
}) => {
  const handleClick = (data) => {
    const watchId = data.course_hash_id
    const name = data.title && data.title.split(" ").join("_")
    root.open(`/watch/rooms/${watchId}/${name}`, "_blank")
  }
  return (
    <Wrap
      // innerRef={currentRef}
      id={`${id}_scroll_target_id`}
    >
      {sortedEvents && sortedEvents.map(course => (
        <CardWrap
          onClick={() => handleClick(course, false, false, true)}
        >
          <CardDetails>
            <RoomImage url={course.cover_image_url || newFallbackImage} />
            <RoomWrap
              isCourse
            >
              <img src={PlayButton} alt="Play Button" />
            </RoomWrap>
          </CardDetails>
          <h1>
            {course.title}
          </h1>
          <p>
            {course.total_duration_in_seconds ? `${secondsInDuration(course.total_duration_in_seconds)}` : null}
          </p>
        </CardWrap>
      ))}
    </Wrap>
  )
}

export default EventList
