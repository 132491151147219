import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import root from "window-or-global"
import axios from "axios"
import { get } from "lodash"
import CookiesStorage from "services/CookieStorage"
import KickUserOutModal from "../../Modal/KickUserOutModal"
import {
  ActiveMic,
  MuteMic,
  LeaveRoom,
  PinUser,
  UnPinUser,
} from "../VideoContainer/Controls/Icons"

/*
* THIS FILE IS INDEPENDENT OF ROOT CONTAINER.
* NORMAL REDUX ACTIONS ARE NOT APPLIED HERE.
* FOR API CALLS USE axios.
*/

const ModalWrap = styled.div`
  position: absolute;
  background: white;
  padding: 20px;
`

const HoverWrap = styled.div``

const IconWrap = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 0.3s;
  width: 38px;
  height: 39px;
  background-color: ${colors.white};
`

const HoverControls = ({
  id: agentId,
  reduxData,
  override,
}) => {
  // const [speakers, setSpeakers] = useState(null)
  // const [hosts, setHosts] = useState(null)
  // const [listeners, setListeners] = useState(null)
  const [isPresentInListeners, setListenerStatus] = useState(false)
  const [isUiReady, setUIReady] = useState(false)
  const [roomId, setRoomId] = useState(null)
  const [kickUserOutModal, setKickUserModal] = useState()
  const [isMicrophoneMuted, setMicrophoneMuted] = useState(false)
  const [currentUser, setCurrentUser] = useState(false)
  const currentAgentId = CookiesStorage.load("agentId")
  const [hostBlock, setHostBlock] = useState(false)
  const [isPinned, setPinned] = useState(false)

  useEffect(() => {
    getMembers()
    // root.addEventListener("itemInserted", () => {
    //   const currentStatus = root.localStorage.getItem(`${agentId}-pinned-locally`)
    //   setPinned(currentStatus)
    // }, false)
  }, [])

  const getMembers = async () => {
    try {
      const currentRoomId = get(reduxData, "match.params.roomId") || root.location.pathname.split("/")[2]

      axios.get(`${API_URL}radius-club/event-room/${currentRoomId}/members`).then((fetched) => {
        setRoomId(currentRoomId)
        const { data: { response } } = fetched || {}
        const isListener = response
        && response.listeners.length > 0
        && response.listeners.filter(item => item.id === parseInt(currentAgentId, 10)) || false

        let currentAgent = response
        && response.speakers.length > 0
        && response.speakers.filter(item => item.id === agentId) || false

        if (currentAgent && currentAgent.length <= 0 || !currentAgent) {
          currentAgent = response
          && response.hosts.length > 0
          && response.hosts.filter(item => item.id === agentId) || false
        }

        if (currentAgent && currentAgent.length > 0) {
          const isMute = currentAgent[0].isMuted === 1
          setMicrophoneMuted(isMute)
        }

        const host = response
        && response.hosts
        && response.hosts.filter(item => item.id === agentId) || false

        const hostId = host && host.length > 0 && host[0].id

        const isHostBlock = agentId === hostId

        const isCurrentUser = agentId === currentAgentId

        const hideHoverIf = isListener && isListener.length > 0 || isHostBlock

        // setHosts(response.hosts)
        // setSpeakers(response.speakers)
        // setListeners(response.listeners)
        setHostBlock(isHostBlock)
        setListenerStatus(hideHoverIf)
        setCurrentUser(isCurrentUser)
        setUIReady(true)
      })
    } catch (e) {
      //
    }
  }

  /**
   * TODO: VIDEO_ROOMS
   * [X] Handle Mute/Unmute Speaker
   * [X] Handle User Kick
   */

  const MuteUser = () => {
    const payload = {
      agent_id: agentId,
      muted: 1,
    }
    axios.put(`${API_URL}radius-club/event-room/${roomId}/mute-user`, payload)
  }

  const KickUser = () => {
    const payload = {
      agent_id: agentId,
    }
    axios.put(`${API_URL}radius-club/event-room/${roomId}/kick-out-user`, payload)
  }

  // const handleLocalPin = () => {
  //   setPinned(!isPinned)
  // }

  // const handleLocalUnpin = () => {
  //   setPinned(!isPinned)
  // }

  return (
    <>
      {isUiReady && (
        <HoverWrap
          className={`hover-controls ${isPresentInListeners || hostBlock ? "hide-hover-controls" : ""}`}
          id={`controls-${agentId || currentAgentId}`}
        >
          {/**
           * if mute
           */}
          {!hostBlock && (
            <>
              <IconWrap
                style={{
                  cursor: "not-allowed",
                }}
                id={`microphone-mute-${agentId}`}
                className={isMicrophoneMuted ? "show-hover-mic" : "hide-hover-mic"}
              //onClick={UnmuteUser}
              >
                <MuteMic />
              </IconWrap>
              <IconWrap
                className={isMicrophoneMuted ? "hide-hover-mic" : "show-hover-mic"}
                id={`microphone-unmute-${agentId}`}
                onClick={MuteUser}
              >
                <ActiveMic />
              </IconWrap>
              {override || currentUser && (
                <IconWrap
                  style={{
                    display: "flex",
                  }}
                  onClick={() => setKickUserModal(true)}
                >
                  <LeaveRoom />
                </IconWrap>
              )}
            </>
          )}
        </HoverWrap>
      )}
      {kickUserOutModal && (
        <ModalWrap>
          <KickUserOutModal
            onCancel={() => { setKickUserModal(false) }}
            onKick={() => { KickUser() }}
          />
        </ModalWrap>
      )}
    </>
  )
}

export default HoverControls
