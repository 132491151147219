import styled from "styled-components"

export const DropdownWrap = styled.div`
display: flex;
height: 40px;
padding: 12px 29px 12px 24px;
align-items: center;
gap: 8px;
border-radius: 32px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
 &:hover {
    cursor: pointer;
    background-color: var(--Hover-Background-Color, #f5f5f5);
  }
`
