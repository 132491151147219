import assistImage from "images/navimages/assist-icon@2x.png"
import marketplaceImage from "images/navimages/marketplace-icon@2x.png"
import communityImage from "images/navimages/community_33.png"

export const PRODUCTS = [
  {
    label: "Radius Marketplace",
    content: "Claim live leads and send referrals",
    href: "",
    target: "",
    logo: marketplaceImage,
    alt: "Claim live leads and send referrals",
    pointer: "none",
    visibility: false,
    id: "marketplace_1dfc",
  },
  // {
  //   label: "Radius Assist",
  //   content: "Subscribe to our ISA program",
  //   href: `${RADIUS_URL}/radius-assist`,
  //   target: "_blank",
  //   logo: assistImage,
  //   alt: "Subscribe to our ISA program",
  //   pointer: "",
  //   visibility: true,
  //   id: "assist_2dfc",
  // },
  {
    label: "Radius Community",
    content: "Engage with an active network of agents",
    href: "",
    target: "",
    logo: communityImage,
    alt: "Engage with an active network of agents",
    pointer: "none",
    visibility: false,
    id: "community_3dfc",
  },
]

export const RESOURCES = [
  {
    label: "Knowledge Base",
    content: "FAQs, instructions, and more",
    href: "https://blog.radiusagent.com",
    target: "_blank",
    logo: "",
    pointer: "",
    visibility: true,
    id: "Knowledge_4dfc",
  },
  {
    label: "Blog",
    content: "Learn more about Radius Agent",
    href: "https://blog.radiusagent.com",
    target: "_blank",
    logo: "",
    pointer: "",
    visibility: true,
    id: "blog_4dfc",
  },
]
