import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "RAR_BROKER_JOBS"

export const fetchApplicants = createSignalAction(BASE, "FETCH_APPLICANTS")
export const getAllCreatedJobs = createSignalAction(BASE, "GET_ALL_CREATED_JOBS")
export const createJob = createSignalAction(BASE, "CREATE_JOB")
export const updateJob = createSignalAction(BASE, "UPDATE_JOB")
export const deleteJob = createSignalAction(BASE, "DELETE_JOB")
export const showShareModal = createSignalAction(BASE, "SHOW_SHARE_MODAL")
export const showJobCreateModal = createSignalAction(BASE, "SHOW_JOB_CREATE_MODAL")
export const showJobEditModal = createSignalAction(BASE, "SHOW_JOB_EDIT_MODAL")
export const showJobDeleteModal = createSignalAction(BASE, "SHOW_JOB_DELETE_MODAL")
export const setShowInitialRecruitmentPage = createSignalAction(BASE, "SET_SHOW_INIT_RECRUITMENT")
export const getAllCreatedPublicJobs = createSignalAction(BASE, "GET_ALL_CREATED_PUBLIC_JOBS")
export const markJobFavourite = createSignalAction(BASE, "MARK_JOB_FAVOURITE")
export const getSpecificCreatedPublicJobs = createSignalAction(BASE, "GET_SPECIFIC_CREATED_PUBLIC_JOBS")

//JOBS
export const toggleJobsDetailsModalAction = createSimpleCreator(BASE, "TOGGLE_JOBS_MODAL")
export const toggleModalPagesAction = createSimpleCreator(BASE, "TOGGLE_MODAL_PAGE")
export const initialPageTabToggleAction = createSimpleCreator(BASE, "TOGGLE_FIRST_PAGE_TABS")
export const currentJobViewedAction = createSimpleCreator(BASE, "CURRENT_JOB_VIEWED")
export const applyJobAction = createSignalAction(BASE, "APPLY_JOB_APPLICATION")
export const updateJobAction = createSignalAction(BASE, "UPDATE_JOB_APPLICATION")
export const setLocationValuesForJobsAction = createSimpleCreator(BASE, "SET_LOCATION_JOBS")
export const saveJobAction = createSignalAction(BASE, "SAVE_JOB")
export const markSeenAction = createSignalAction(BASE, "JOB_SEEN")
export const setCurrentSearchTermAction = createSimpleCreator(BASE, "CURRENT_SEARCH_TERM")
export const setCurrentSearchLocationAction = createSimpleCreator(BASE, "CURRENT_SEARCH_LOCATION")
export const clearExistingJobsAction = createSimpleCreator(BASE, "CLEAR_JOBS")
export const showLoaderWhenDelayAction = createSimpleCreator(BASE, "SHOW_LOADER_PUBLISHING")
