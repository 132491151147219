import React, { useEffect } from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import { checkForUserObject } from "services/Utils"
import { get } from "lodash"
import PaymentAccountSetupBar from "dumbComponents/TransactionCoordinationV2/Navbar/PaymentAccountSetupBar"
import TCContainer from "container/Realty/TransactionFormContainer"
import { LeftOutlined, GoogleOutlined } from "@ant-design/icons"
import TCOfferBanner from "./TCOfferBanner"
import TechAssets from "container/TechAssets/index"
import Listings from "container/Listings/index"
import ListingsV2 from "container/ListingsV2/index"
import TransactionBreadCrumb from "dumbComponents/TransactionDashboard/components/BreadCrumbTransaction"
const Wrap = styled.div``

const H1 = styled.h1`
  font-family: ${ATC};
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
`

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
    border: none;
  `}
`

const SubPara = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 387px;
`

const ReturnPara = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #303030;
  display: flex;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`

const IntegrationWarpper = styled.h1`
  font-family: ${ATC};
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  color: ${colors.black};
  cursor: pointer;
  display: flex;
  span {
    margin-left: 5px;
  }
`

const TransactionCoordinationNavbar = ({
  toggleCommissionsPopupStep,
  isFormPage,
  history,
  isLastPage,
  setOfferIdToView,
  setDealToView,
  setListingId,
}) => (
  <Wrap>
    {toggleCommissionsPopupStep !== 5 && toggleCommissionsPopupStep !== null && (
      <PaymentAccountSetupBar />
    )}
    {!isLastPage && (
      <>
        {isFormPage ? (
          <HeaderWrap noborder>
            <TransactionBreadCrumb />
            {/* <ReturnPara
              onClick={() => {
                setOfferIdToView(null)
                setDealToView(null)
                setListingId(null)
                history.push("/realty/transaction-coordination")
              }}
            >
              <LeftOutlined />
              <span>Return to All Contracts</span>
            </ReturnPara> */}
            {/* {isFormPage && (
              <IntegrationWarpper onClick={() => { history.push("/realty/transaction-coordination/contract/integrations") }}>
                Sync Your Gmail
                <GoogleOutlined />
              </IntegrationWarpper>
            )} */}
          </HeaderWrap>
        ) : (
          <HeaderWrap>
            <H1>Contract Submissions</H1>
            {/* <IntegrationWarpper onClick={() => { history.push("/realty/transaction-coordination/contract/integrations?from_header=true") }}>
              Sync Your Gmail
              <GoogleOutlined />
            </IntegrationWarpper> */}
            {/* <SubPara>
        Submit new files, view submitted files and view your active listings
      </SubPara> */}
          </HeaderWrap>
        )}
      </>
    )}
  </Wrap>
)

export default TCContainer(Listings(ListingsV2(TechAssets(TransactionCoordinationNavbar))))
