import {
  takeLatest,
  call,
  put,
} from "redux-saga/effects"
import {
  isSuccess,
} from "services/Utils"

import * as EventActions from "./actions"
import * as EventAPI from "./api"

function* handlePublicFetch(action) {
  try {
    const { roomId, callback } = action.data
    const query = `?skip=0&limit=10&room_id=${roomId}&show_live=true&include_immortal_rooms=true`
    const res = yield call(EventAPI.getEventRoomPublicAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      const shouldJoinRoom = !!response.length
      yield put(EventActions.getEventRoomDetailsAction.success(response))
      if (callback) callback(shouldJoinRoom)
    }
  } catch (error) {
    yield put(EventActions.getEventRoomDetailsAction.failure(error))
  }
}

function* handleFetchScheduledRoomsDetails(action) {
  try {
    const { roomId, callback } = action.data
    const res = yield call(EventAPI.getScheduledRoomsPublicDetailsAPI, roomId)
    if (isSuccess(res)) {
      const { response } = res.data
      const shouldJoinRoom = !!response.length
      yield put(EventActions.getScheduledRoomEventDetailsAction.success(response))
      if (callback) callback(shouldJoinRoom)
    }
  } catch (error) {
    yield put(EventActions.getScheduledRoomEventDetailsAction.failure(error))
  }
}

function* handleJoinRoomAsGuest(action) {
  try {
    const { roomId, payload, callback } = action.data
    const res = yield call(EventAPI.joinRoomAsGuestDetailsAPI, { roomId, payload })
    if (isSuccess(res)) {
      const { response } = res.data
      console.log("==response===", response)
      yield put(EventActions.joinRoomAsGuestDetailsAction.success(response))
      if (callback) {
        callback({
          token: response.token,
        })
      }
    }
  } catch (error) {
    yield put(EventActions.joinRoomAsGuestDetailsAction.failure(error))
  }
}

function* handleLeaveRoomAsGuest(action) {
  try {
    const { roomId, payload } = action.data
    const res = yield call(EventAPI.leaveRoomAsGuestDetailsAPI, { roomId, payload })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(EventActions.leaveRoomAsGuestDetailsAction.success(response))
    }
  } catch (error) {
    yield put(EventActions.leaveRoomAsGuestDetailsAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(EventActions.getEventRoomDetailsAction.REQUEST, handlePublicFetch)
  yield takeLatest(EventActions.getScheduledRoomEventDetailsAction.REQUEST, handleFetchScheduledRoomsDetails)
  yield takeLatest(EventActions.joinRoomAsGuestDetailsAction.REQUEST, handleJoinRoomAsGuest)
  yield takeLatest(EventActions.leaveRoomAsGuestDetailsAction.REQUEST, handleLeaveRoomAsGuest)
}
