import React, { useState } from "react"
import styled from "styled-components"
import Box from "dumbComponents/common/UI/BuilderComponents/BoxV2"
import colors from "@colors"
import { useWindowSize } from "services/Utils"
import Button from "@ui/Button"
import Paragraph from "@ui/BuilderComponents/Paragraph/index"
import LargeText from "@ui/BuilderComponents/LargeText/index"
import HoriLine from "dumbComponents/NewLandingPage/images/horiLine.png"
import { ATC } from "@fonts"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  > button {
    @media(max-width: 1099px) {
      width: 223px !important;
    }

    @media(max-width: 767px) {
      width: 205px !important;
    }
  }
`
const VideoIframe = styled.video`
  width: 100%;
  margin: 0px auto;
  position: relative;
  height: 700px;
  object-fit: cover;
  position: absolute;
  opacity: 0.8;
  filter: brightness(0.4);
  ${props => !props.isLoaded && `
    background: url("https://d2fedz0by71ckz.cloudfront.net/images/heroBgImage.webp");
  `}
  &::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 1;
  }
`

const VideoOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 700px;
  opacity: 1;
  z-index: 1;
  filter: contrast(1);
`

const VideoWrap = styled.div`
  overflow: hidden;
  display: flex;
  max-width: 100%;
  position: relative;
  height: 700px;
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
`

const NewImageWrap = styled.div``

const NewImage = styled.img`
  width: 100%;
  padding: 100px 100px 0px 100px;

  @media(max-width: 1099px) {
    padding: 60px;
  }

  @media(max-width: 478px) {
    padding: 30px;
  }
`

const HeaderArea = ({
  toggleApplyModal,
}) => {
  const size = useWindowSize()
  const [isLoaded, setLoaded] = useState(false)
  const handleOnLoad = () => {
    setLoaded(true)
  }
  return (
    <Wrap>
      <VideoWrap>
        <VideoIframe autoPlay playsInline muted loop id="home-page-video-wrap" isLoaded={isLoaded} onLoad={handleOnLoad}>
          <source src="https://d2fedz0by71ckz.cloudfront.net/video/radius_lp_video.mov" type="video/mp4" />
        </VideoIframe>
        <VideoOverlay>
          <ContentWrap>
            <LargeText
              content="Supercharge your real estate business"
              largeTextFamily="Butler"
              width="100%"
              color={colors.dreamWhite}
              minDesktopLargeTextSize="54px"
              minLargeDesktopLargeTextSize="54px"
              preDesktopLargeTextSize="48px"
              maxMobilelargeTextWidth="100%"
              maxMobilelargeTextSize="44px"
              minTabletLargeTextSize="48px"
              alignLargeText="center"
              preTabletlargeTextWidth="601px"
              letterSpacing="1px"
              largeTextWeight="600"
              customLargeTextStyling={{
                position: size.width < 1099 ? "relative" : "static",
                width: "fit-content",
              }}
            />
            {(size.width > 425) ? (
              <Paragraph
                content={`
                Premiere brokerage for performing agents | Perfect partner for teams | Agent and employee-owned
                `}
                color={colors.dreamWhite}
                minLargeDesktopParaSize="26px"
                minDesktopParaSize="22px"
                preDesktopParaSize="22px"
                minTabletParaSize="20px"
                paraWeight="300"
                /*TEXT WIDTHS*/
                minLargeDesktopParaWidth="895px"
                minDesktopParaWidth="739px"
                preDesktopParaWidth="739px"
                preTabletParaWidth="100%"
                alignPara="center"
                paraFamily={ATC}
                margin="0px 0px 26px 0px"
              />
            ) : (
              <React.Fragment>
                <Paragraph
                  content={`
                  Premiere brokerage for performing agents
                  `}
                  color={colors.dreamWhite}
                  minLargeDesktopParaSize="22px"
                  minDesktopParaSize="22px"
                  preDesktopParaSize="20px"
                  minTabletParaSize="18px"
                  paraWeight="300"
                  /*TEXT WIDTHS*/
                  minLargeDesktopParaWidth="895px"
                  maxMobileParaWidth="196px"
                  minDesktopParaWidth="739px"
                  preDesktopParaWidth="739px"
                  preTabletParaWidth="100%"
                  alignPara="center"
                  paraFamily={ATC}
                  margin="0px 0px 10px 0px"
                />
                <img
                  src={HoriLine}
                  alt=""
                  style={{
                    margin: "10px",
                  }}
                  height={1}
                  width={37}
                />
                <Paragraph
                  content={`
                  Perfect partner for teams
                  `}
                  color={colors.dreamWhite}
                  minLargeDesktopParaSize="22px"
                  minDesktopParaSize="22px"
                  preDesktopParaSize="20px"
                  minTabletParaSize="18px"
                  paraWeight="300"
                  /*TEXT WIDTHS*/
                  minLargeDesktopParaWidth="895px"
                  minDesktopParaWidth="739px"
                  preDesktopParaWidth="739px"
                  preTabletParaWidth="100%"
                  alignPara="center"
                  paraFamily={ATC}
                  margin="0px 0px 10px 0px"
                />
                <img
                  src={HoriLine}
                  alt="Radius hori line"
                  style={{
                    margin: "10px",
                  }}
                  height={1}
                  width={37}
                />
                <Paragraph
                  content={`
                    Agent and employee-owned
                  `}
                  color={colors.dreamWhite}
                  minLargeDesktopParaSize="22px"
                  minDesktopParaSize="22px"
                  preDesktopParaSize="20px"
                  minTabletParaSize="18px"
                  paraWeight="300"
                  /*TEXT WIDTHS*/
                  minLargeDesktopParaWidth="895px"
                  minDesktopParaWidth="739px"
                  preDesktopParaWidth="739px"
                  preTabletParaWidth="100%"
                  alignPara="center"
                  paraFamily={ATC}
                  margin="0px 0px 26px 0px"
                />
              </React.Fragment>

            )}
            <ButtonWrap>
              <Button
                bsStyle="white"
                style={{
                  height: "61px",
                  width: "265px",
                  borderRadius: "0px",
                  fontSize: "24px",
                  fontWeight: "400",
                  marginTop: "10px",
                  color: "#303030",
                }}
                onClick={() => {
                  toggleApplyModal({
                    bool: true,
                    accountType: "owner",
                    headerText: "Nice to meet you!",
                    subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                  })
                }}
              >
                Get Started
              </Button>
            </ButtonWrap>
          </ContentWrap>
        </VideoOverlay>
      </VideoWrap>
      {/* <NewImageWrap>
        <NewImage src="https://s3.amazonaws.com/cdn.agentdesks.com/images/hubspot+1.png" />
      </NewImageWrap> */}
    </Wrap>
  )
}

export default HeaderArea
