/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Listings from "container/Listings/index"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div``

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const ConfirmDelete = ({
  query,
  isDeleteModalOpen,
  deleteListing,
  deleteListingResponse,
  toggleDeleteModal,
}) => {
  const {
    id,
  } = isDeleteModalOpen || {}

  const {
    isFetching: deleting,
  } = deleteListingResponse || {}

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Confirm Deletion!
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              Are you sure you want to delete the selected listing?
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "blue",
            }}
            onClick={() => {
              toggleDeleteModal({
                bool: false,
                id: "",
              })
            }}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            isShowLoader={deleting}
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              deleteListing({
                id,
                query,
              })
            }}
          >
            Delete
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default Listings(ConfirmDelete)
