import React from "react"
import styled from "styled-components"
import CircularImage from "dumbComponents/common/CircularImage"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const LikedAgentLink = styled.a`
  position: relative;
  float: left;
  z-index: 5;
  text-decoration: none;
  &:not(:first-child) {
    left: ${props => props.index * -14}px;
    z-index: ${props => SHOWABLE_IMAGES - props.index};
  }
`

const LikedAgentsContainer = styled.div`
  width: 75px;
  display: flex;
  justify-content: ${props => (props.totalAgents === SHOWABLE_IMAGES ? "flex-start" : "flex-end")};
`

const LikedAgentsCount = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a5a8ad;
`

const Count = styled.small`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.83;
  color: #ffffff;
`

const SHOWABLE_IMAGES = 4
const LikedAgents = ({ agentsToDisplay = [], otherLikedAgents }) => (
  <Wrapper>
    <LikedAgentsContainer totalAgents={agentsToDisplay.length}>
      {agentsToDisplay.length > 0
        && agentsToDisplay.map((agent, index) => (
          <LikedAgentLink key={agent.id} index={index}>
            <CircularImage
              image={agent.agent_image}
              alt={agent.firstname}
              fontSize="11px"
              name={`${agent.firstname} ${agent.lastname || ""}`}
              size={28}
            />
          </LikedAgentLink>
        ))}
    </LikedAgentsContainer>
    {otherLikedAgents > 0 && (
      <LikedAgentsCount>
        <Count>
+
          {otherLikedAgents}
        </Count>
      </LikedAgentsCount>
    )}
  </Wrapper>
)

export default LikedAgents
