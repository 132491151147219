import React, {useRef, createRef, useState } from "react"
import styled, { css } from "styled-components"
import { reduxForm, Field } from "redux-form"
import root from "window-or-global"
import colors from "@colors"
import { get } from "lodash"
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share"
import { validURL, normalizePhone, onlyAlphabets } from "services/formUtils"
import { LocationAutoComplete } from "@ui/Form"

import Box from "@ui/Box"
import InstaIcon from "images/icons/insta.png"
import { Form, Button, Input,
  InputNumber, Select, GoogleAutoComplete
} from "@ui/antd"
import { ATC } from "@fonts"
import {
  Footer,
  Header,
  SaveButton,
  ProfileCompletionButton,
  BlankButton,
} from "./commonStyles"

const ICON_SIZE = 30
const { Item } = Form
const { Option } = Select
const Wrapper = styled.form`xw
  border: 1px solid  #e7ebed;
  border-radius: 4px;

  span.optional-text{
    bottom: 0px !important;
  }
`

const ErrorText = styled.div`
  color: ${colors.redText};
  margin-top: 5px;
  font-size: 12px;
`

const SocialMediaIcon = styled.div`
  position: absolute;
  top: 0px;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  border-radius: 50%;
  z-index: 2;
  top: 30px;
  right: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.facebook && css`
    background: blue;
  `}
`
const FormTitle = styled.h1`
font-family: ${ATC};
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #303030;
margin-left: 5px;
`

const H4 = styled.h4`
  color: #CCC;
  margin: 0px;
  margin-bottom: 5px;
  font-weight: 300;
  position: relative;
  font-size: 16px;
`

export const InvalidLocation = styled.p`
  font-size: 12px;
  position: relative;
  top: -7px;
  color: red;
`

const MARGIN_BOTTOM = 20

class EditBasicInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }
  selectCity = (data) => {
    const { change } = this.props
    const {
      address,
      administrative_area_level_1: {
        long_name: stateName = "",
        short_name: stateShortName = "",
      },
      locality: {
        long_name: cityName = "",
      },
    } = data
    if (address) {
      this.setState({ error: false })
      change("office_address", address)
      change("office_short_state_name", stateShortName)
      change("office_city", cityName)
      change("office_state", stateName)
    }
  }

  componentDidMount() {
    const { nextStep, initialValues } = this.props
    if (nextStep === "office_address") {
      const ele = root.document.getElementById("cities")
      if (ele) {
        ele.focus()
      }
    }
    console.log("initial value-->",initialValues)
  }

  render() {
    const {
      isSavingUpdates,
      handleSubmit,
      initialValues = {},
      isProfileCompletion,
      skipProfileCompletionStep,
      nextStep,
      nextToNextStep,
      formErrorResponse,
      requestUpdateUserObject,
      saveProfileUpdate,
    } = this.props
    const {
      error,
      initialVal=[],
    } = this.state
    const errorMessage = get(formErrorResponse, "data.error.message")
    const { office_address } = initialValues
    const onFinish = () => {
      const {initialVal} = this.state
      const values = formRef.current.getFieldsValue()
      const payload = {
            ...values,
            ...initialVal
        }
      saveProfileUpdate({ ...payload })
    }
  
    const setCities = (location) => {
      const locationDetails = {
        address: get(location,"address"),
        city: get(location, "locality.long_name"),
        state: get(location, "administrative_area_level_1.long_name"),
        stateShortName: get(location, "administrative_area_level_1.short_name"),
        country: get(location, "country.long_name"),
        latitude: get(location, "lat"),
        longitude: get(location, "lng"),
      }
      formRef.current.setFieldsValue({
        office_city: `${locationDetails.city || ""}`,
      })
      this.setState({
        initialVal: {
          ...this.state.initialVal,
          office_state: `${locationDetails.state || ""}`,
          office_address: `${locationDetails.address || ""} `
        }
      })
    }
    const formRef = createRef(null)
    return (
      <Wrapper >
        <Header>
          <h4>Edit your basic informations</h4>
        </Header>
        <Box d="flex"  p="30px" pt="20" fd="column">
          <Form
          name="transaction_form"
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          ref={formRef}
          >
            <Form.Item
              label="First Name"
              name="firstname">
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastname">
              <Input />
            </Form.Item>
            <Form.Item
              label="Designation"
              name="designation">
              <Input />
            </Form.Item>
            <Form.Item
              label="Brokerage Address"
              name="agency_name">
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="City"
              name="city">
              <Input />
            </Form.Item> */}
            <Form.Item name="office_city" label="City">
              <GoogleAutoComplete
                id="cities"
                types={["(cities)"]}
                name="cities"
                      // formValue={cityFormValue}
                onChange={(e) => { }}
                location={(loc) => { setCities(loc) }}
                placeholder=""
                styles={{
                  height: "49px",
                  borderRadius: "0px",
                  background: "white",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="socialMedias.facebook.handle"
              label="Add your Facebook"
              optional>
              <Input 
              addonAfter={<FacebookIcon size={ICON_SIZE} />}/>
            </Form.Item>
            <Form.Item
              name="socialMedias.linkedin.handle"
              label="Add your LinkedIn"
              optional>
              <Input 
              addonAfter={<LinkedinIcon size={ICON_SIZE} />}/>
            </Form.Item>
            <Form.Item
              name="socialMedias.twitter.handle"
              label="Add your Twitter"
              optional>
              <Input 
              addonAfter={<TwitterIcon size={ICON_SIZE} />}/>
            </Form.Item>
            <Form.Item
              name="socialMedias.instagram.handle"
              label="Add your Instagram"
              optional>
              <Input 
              addonAfter={<img src={InstaIcon} alt="insta" style={{ width: 25 }} />}/>
            </Form.Item>
            <Form.Item
              label="Add Phone number"
              name="phone">
              <Input />
            </Form.Item>
            <Form.Item
              label="Add website"
              optional
              name="website">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => onFinish()} >Submit</Button>
            </Form.Item>
          </Form>
        </Box>
      </Wrapper>
    )
  }
}

export default reduxForm({
  form: "EDIT_BASIC_INFO",
})(EditBasicInfo)
