import { isEmpty, toLower } from "lodash"
import {
  SUPPORTED_COUNTRY_PHONENUMBER_CODES,
  PHONE_REGEX,
} from "shared/consts"

const HAS_ALPHA_RE = /^[a-zA-Z]*$/
const SPL_CHAR_RE = /[^0-9]+/g
const ONLY_ALPH_RE = /^[A-Z\sa-z]+$/
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,5})?(\/.*)?$/
export const ALPHA_NUM_SPL_8LEN = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

export const minLength = min => value => value
 && value.replace(SPL_CHAR_RE, "").length < min ? `Must be ${min} characters at least` : undefined

 export const minLengthWithSpecialCharacters = min => value => value
 && value.length < min ? `Must be ${min} characters at least` : undefined

export const leastChars = min => value => value
 && value.length > min ? undefined : `Must be ${min + 1} characters at least`

export const maxLength = max => value => value
  && value.replace(SPL_CHAR_RE, "").length > max ? "Word limit exceeded" : undefined

export const removeAllChars = (str = "", character, replaceWith = "") => {
  const re = new RegExp(character, "g")
  return str.replace(re, replaceWith)
}

export const MoneyFormatter = (value) => {
  if (!value || value === undefined) return 0
  const replaceAsNumbers = value.replace(/[^\w\s]/gi, "")
  return parseInt(replaceAsNumbers, 10)
}

export const formatAmount = (input) => {
  if (!input || input === null) return ""

  if(typeof input === "number") {
    input = input.toString()
  }
  const conv = input
    .replace(/,/g, "")
    .replace(/[^\d]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  if (conv && conv[0] !== "$") {
    return `$${conv}`
  }

  return conv.replace(/(\$)/g, "$")
}

export const passwordValidation= value => ALPHA_NUM_SPL_8LEN.test(value) ? undefined : "Password must be alpha-numeric, must have a special character and must be at-least 8 characters long."

export const validateEmail = email => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

// Helper Functions
function funkyStringSplit(val = "") {
  const s = val
  let i = s.length % 3
  const parts = i ? [s.substr(0, i)] : []
  for (; i < s.length; i += 3) {
    parts.push(s.substr(i, 3))
  }
  return parts
}
const numberPattern = /\d+/g

export const required = value => value ? undefined : "* Required"
export const requiredWithInvalidEmptyString = value => value && !!value.trim() ? undefined : "* Required"

export const customReduxObjectFormValidation = (obj, key) => obj[key] ? undefined : "* Required"

export const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    "Invalid email address" : undefined
)

export const commaSeperatedNumberUS = (value = "") => {
  try {
    if (!value) {
      return value
    }
    value = String(value)
    let trimmedNum = value.match(numberPattern)
    trimmedNum = Array.isArray(trimmedNum) ? trimmedNum.join("") : trimmedNum
    const parts = funkyStringSplit(trimmedNum) || [value]
    return parts.join(",")
  } catch (e) {
    return value
  }
}

export const locationRequired = value => !isEmpty(value) ? undefined : "* Required"

export const getNumbers = value => value && value.replace(/[^\d]/g, "")

export const nonSpecialCharOnly = value => /^[^a-zA-Z0-9]+$/.test(value) ? "Only special characters not allowed" : ""

export const normalizePhone = (value = "", previousValue) => {
  if (!value) {
    return value
  }
  let onlyNums = getNumbers(value)
  const isWitCountryCode = false && value[0] === "+"
  const countryCode = isWitCountryCode && SUPPORTED_COUNTRY_PHONENUMBER_CODES.find(x => value.includes(x)) || ""
  if (isWitCountryCode && !countryCode) {
    return `+${onlyNums}`
  }
  if (countryCode) {
    onlyNums = getNumbers(value.replace(countryCode, ""))
  }
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${countryCode}(${onlyNums})-`
    }
    if (onlyNums.length === 6) {
      return `${countryCode}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`
    }
  }
  // This is to remove first bracket
  if (value.length === 4 && value.indexOf(")") === -1) {
    return `${countryCode}(${onlyNums.slice(0, 3)}`
  }
  if (onlyNums.length <= 3) {
    return `${countryCode}(${onlyNums}`
  }
  if (onlyNums.length <= 6) {
    return `${countryCode}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
  }
  return `${countryCode}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const zeroToHundred = value => value > 100 || value < 0 ? "Allowed range is from 0 to 100" : undefined

export const onlyNumbers = value => !HAS_ALPHA_RE.test(removeAllChars(value, ",")) || !value ? undefined : "Only numbers are allowed"

export const validURL = value => !value || URL_REGEX.test(toLower(value)) ? undefined : "Please enter valid URL"

export const validPhoneNumber = value => !value || PHONE_REGEX.test(value) ? undefined : "Please enter a valid phone number"

export const onlyAlphabets = value => ONLY_ALPH_RE.test(value) ? undefined : "Should be alphabets"

export const serverInvalidPhoneNumber = validation => (value) => {
  const isValidError = validation(value)
  return isValidError || undefined
}
