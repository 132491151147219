import React from "react"
import styled from "styled-components"
import Card from "./Card"
import BlueSection from "./BlueSection"
import { HeroText } from "../commonStyles"

const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  padding-top: 93px;


  @media(max-width: 1099px) {
    // padding: 0px;
    gap: 20px;
    padding-top: 0px;
  }
`

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 60px 198px;
  margin-bottom: 70px;

  @media(min-width: 1100px) {
    gap: 18px;
  }

  @media(min-width: 1366px) {
    gap: 60px 175px;
  }

  @media(max-width: 1099px) {
    margin-bottom: 50px;
    gap: 50px;
  }
`

const TextWrap = styled.div`
  margin-bottom: 55px;
`

const DISPLAY_OBJECT = [{
  image: `${CLOUDFRONT}/Ownership.png`,
  heroText: "Ownership",
  subText: "Launch your business from day one. No waiting period. No up-front costs.",
  imgWidth: "62px",
  imgHeight: "57.753px",
}, {
  image: `${CLOUDFRONT}/Branding.png`,
  heroText: "Branding",
  subText: "Have a brand? Bring it with you. Need a brand? We’ll help you bring your vision to life.",
  imgWidth: "53px",
  imgHeight: "60px",
}, {
  image: `${CLOUDFRONT}/Compliance.png`,
  heroText: "Compliance",
  subText: "All the ownership without the risk. We’ll take on all the compliance for your business.",
  imgWidth: "57px",
  imgHeight: "57px",
}, {
  image: `${CLOUDFRONT}/Innovation.png`,
  heroText: "Innovation",
  subText: "On-the-go, meaningful tech — built with your business needs in mind.",
  imgWidth: "58px",
  imgHeight: "58px",
}]

const SectionThree = ({
  isCurrentUserOniOS,
  width,
}) => (
  <Wrap className="container">
    <TextWrap>
      <HeroText
        spanStyles
      >
        The
        {" "}
        <span>Radius</span>
        {" "}
        Difference
      </HeroText>
    </TextWrap>
    <CardWrap>
      {DISPLAY_OBJECT && DISPLAY_OBJECT.map(item => (
        <Card
          img={item.image}
          heroText={item.heroText}
          subText={item.subText}
          logoWidth={item.imgWidth}
          logoHeight={item.imgHeight}
          width="481px"
        />
      ))}
    </CardWrap>
    <BlueSection
      isCurrentUserOniOS={isCurrentUserOniOS}
    />
  </Wrap>
)

export default SectionThree
