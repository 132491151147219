import * as React from 'react'

const Add = props => {
  const { color = '#D9D9D9' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <mask
        id="a"
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill={color} d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill={color}
          d="M9.167 10.835H5a.806.806 0 0 1-.593-.24.807.807 0 0 1-.24-.594c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h4.167V5.001c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.593v4.167H15c.236 0 .434.08.594.24.16.16.24.357.24.593s-.08.434-.24.594a.806.806 0 0 1-.594.24h-4.166V15c0 .236-.08.434-.24.594a.806.806 0 0 1-.594.24.806.806 0 0 1-.593-.24.807.807 0 0 1-.24-.594v-4.166Z"
        />
      </g>
    </svg>
  )
}

export default Add
