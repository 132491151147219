import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  finishTabsSpotlightFlowAction,
} from "container/Navbar/actions"
import {
  fetchUserInfoAction,
  appReadyAction,
  toggleMessagesPanelAction,
  showNewFeedsAvailableAction,
  collectPhoneNumberAction,
  togglePhoneCollectionModalAction,
  getPopUpScreenAction,
  closePopUpAction,
  allowUserToClosePhoneCollectionModalAction,
  getSideMenuUpdatesAction,
  trackSessionAction,
  showNewQuestionAvailableAction,
  logoutUserAction,
  registerDeviceForFCMAction,
  getNotificationReminderMappingAction,
  updateDBWithUserChoiceOnNotificationAction,
  hideNewFeedNotificationDotAction,
  updateIncompleteProfileAction,
  toggleHeroVideoModalAction,
  toggleErrorModalAction,
  sideMenuToggleAction,
  getStateAnnouncementAction,
} from "./actions"

const mapStateToProps = (state, ownProps) => {
  const { app, referral, navbar } = state
  const { isShowInviteeBanner } = referral
  const { isStartNavbarSpotlightFlow } = navbar
  const { vwoVariant } = (ownProps && ownProps.staticContext) || {}

  return {
    ...app,
    ...ownProps,
    ...vwoVariant,
    isShowInviteeBanner,
    isStartNavbarSpotlightFlow,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchUserInfo: fetchUserInfoAction.request,
      appReady: appReadyAction,
      toggleMessagesPanel: toggleMessagesPanelAction,
      showNewFeedsAvailable: showNewFeedsAvailableAction,
      showNewQuestionAvailable: showNewQuestionAvailableAction,
      collectPhoneNumber: collectPhoneNumberAction.request,
      togglePhoneCollectionModal: togglePhoneCollectionModalAction.call,
      getPopUpScreen: getPopUpScreenAction.request,
      closePopUp: closePopUpAction.call,
      allowUserToClosePhoneCollectionModal: allowUserToClosePhoneCollectionModalAction.call,
      finishTabsSpotlightFlow: finishTabsSpotlightFlowAction.request,
      getSidebarUpdates: getSideMenuUpdatesAction.request,
      fcmRegister: registerDeviceForFCMAction.request,
      trackSession: trackSessionAction.request,
      getNotificationMapping: getNotificationReminderMappingAction.request,
      setNotificationChoice: updateDBWithUserChoiceOnNotificationAction.request,
      logoutUser: logoutUserAction.request,
      hideNewFeedNotificationDot: hideNewFeedNotificationDotAction.call,
      updateIncompleteProfile: updateIncompleteProfileAction.request,
      toggleVideoModal: toggleHeroVideoModalAction.call,
      toggleErrorModal: toggleErrorModalAction.call,
      sideMenuToggle: sideMenuToggleAction.call,
      toggleStateBanner: getStateAnnouncementAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
