import React from "react"
import styled from "styled-components"
import queryString from "query-string"
import CRMContainer from "container/CRM"
import AppContainer from "container/App"
import TeamContainer from "container/Realty/TeamProfileContainer"
import root from "window-or-global"
import flowContainer from "container/App/applyFlowContainer"
import { Link } from "react-router-dom"
import CookiesStorage from "services/CookieStorage"
import BrbcContainer from "container/BRBC"
import { LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"
import LinkButton from "@ui/LinkButton"
import colors from "@colors"
import { ATC } from "@fonts"
import { ga4DataLayerPush } from "services/Utils"
import CRMDashboard from "./CRMDashboard"
import {
  DragAndDropArea, UserHeaderDrawer, TeamFilterComponent, RivenMortgageBanner,
  ArchiveLeadsModal,
} from "./CRMDashboard/Components"
import SectionHeader from "./CRMDashboard/Components/SectionHeader/index"
import AllCRMModals from "./CRMDashboard/Components/AllCRMModals/index"

const Wrap = styled.div`
  padding: 47px 42px 30px 42px;
`

const DragDropWrap = styled.div`

`

const Tabs = styled.div`
  display: flex;
  margin: 40px 0px 0px 0px;
  justify-content: space-between;

  li {
    list-style: none;
  }
`

const StyledLink = styled(Link)`
  ${LeaderBoardFontStyle}
  font-family: ${ATC};
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 18px;
  display: inline-block;
  position: relative;
  color: ${colors.suvaGray} !important;
  ${props => props.width && `
    width: ${props.width}
  `}

  ${props => props.isActive ? `
    font-weight: 600;
    color: ${colors.marineBLue}!important;
    &:after {
      content: "";
      height: 3px;
      position: absolute;
      bottom: -6px;
      left: 0px;
      width: 100%;
      background: ${colors.marineBLue};
      border-radius: 2px;
      ${props.width && `
        width: 160px;
      `}
    }
  ` : `
    color: ${colors.black};
    border-bottom: 3px solid transparent;
  `}
`

// code for landing page revamp of CRM
class CRMV2 extends React.Component {
  state = {
    selectedMenu: "My Leads",
  }

  componentDidMount() {
    const {
      getClientTransactions, getClientFilter, location,
      getClient, toggleUserOverview,
      setCurrentDrawerPage, fetchUserInfo,
      currentFilters, setCurrentClientId, clearData, getClientTransactionsById,
      getBrokerageTransactionStatus, user: userData, isUserOverviewDrawerOpen,
      currentClientTransactionID, currentCRMDealId, setCurrentTab, fetchPresignedBrbcDocs, fetchBrbcCoversheet, fetchBrbcStatus
    } = this.props
    const parsedQuery = queryString.parse(location.search)
    const { client_id, transactionId, type, tab } = parsedQuery
    const { agentInfo } = isUserOverviewDrawerOpen || {}
    const { type: agentType } = agentInfo || {}
    const clientType = type || agentType
    if (client_id || currentClientTransactionID) {
      const clientId = client_id || currentClientTransactionID
      // setCurrentDrawerPage("drawer-header")
      toggleUserOverview({
        showUserOverviewDrawer: true,
        agentInfo,
      })
      clearData()
      getClient({ clientId })
      setCurrentClientId(clientId)
      getBrokerageTransactionStatus({ transactionId: transactionId || currentCRMDealId })
      getClientTransactionsById({ transactionId: transactionId || currentCRMDealId })
      const user = CookiesStorage.load("user") || {}
      user && fetchPresignedBrbcDocs({agentId: user?.agent_id, clientId})
      user && fetchBrbcStatus({agentId: user?.agent_id, clientId})
      if (clientType === "buyer") {
        setCurrentDrawerPage("search-criteria")
      } else if (clientType === "seller" || clientType === "Seller") {
        setCurrentDrawerPage("property-listing")
      } else {
        setCurrentDrawerPage("drawer-header")
      }
      if (tab === "REMINDERS") setCurrentTab(tab)
    }
    if (root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove()
    }
    fetchUserInfo()
    getClientTransactions({
      currentFilters,
    })
    getClientFilter()

    const noUserData = !userData || (userData && Object.keys(userData).length <= 0)

    fetchUserInfo()

    if (!noUserData) {
      ga4DataLayerPush(userData)
    }
  }

  handleClose = () => {
    const {
      toggleUserOverview,
      history,
      setClientID,
    } = this.props
    toggleUserOverview({ isUserOverviewDrawerOpen: false })
    setClientID({
      id: "",
      deal_id: "",
    })
    history.push("/crm")
  }

  getFiltersOptions = () => {
    const {
      getTeamDetailsResponse,
    } = this.props

    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = CookiesStorage.load("user") || {}
    const teamNames = []

    teamNames.push({
      value: "view_mine",
      label: "View my leads",
      key: JSON.stringify({
        value: "view_mine",
        label: "My leads",
      }),
    })

    teamNames.push({
      value: "view_all",
      label: "View all leads",
      key: JSON.stringify({
        value: "view_all",
        label: "All Leads",
      }),
    })

    teamNames.push({
      value: "view_all_team_members",
      label: "View team member's leads",
      key: JSON.stringify({
        value: "view_all_team_members",
        label: "All Team Member's Leads",
      }),
    })

    if (teamDetails && teamDetails.team_members) {
      teamDetails.team_members.filter(td => td.agent_id !== user.id).forEach((tm) => {
        teamNames.push({
          key: JSON.stringify({
            value: tm.agent_id,
            label: `${tm.firstname}'s leads`,
          }),
          label: `${tm.firstname}'s leads`,
          value: tm.agent_id,
        })
      })
    }

    return teamNames || []
  }

  handleMenuClick = (e) => {
    try {
      const {
        currentFilters,
        updateFilterQueries,
        getClientTransactions,
      } = this.props
      const selectedMenu = JSON.parse(e.key).label
      const selectedValue = JSON.parse(e.key).value
      const updated = currentFilters
      updated["team_member_id"] = selectedValue

      updated.view_type = selectedValue !== "view_all"
      && selectedValue !== "view_mine"
      && selectedValue !== "view_all_team_members"
        ? "view_specific_team_members" : selectedValue

      updated.view_type_label = selectedMenu

      updateFilterQueries({
        filters: updated,
      })

      getClientTransactions({
        currentFilters,
      })
    // this.handleFilterSelect(team_member_id, keys[0])
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      toggleUserOverview,
      isUserOverviewDrawerOpen,
      location,
      getClientTransactions,
      getClientTransactionResponse,
      getTeamDetailsResponse,
      currentFilters,
      isArchiveModalOpen,
      isShowWarning,
    } = this.props

    const {
      data: teamDetails,
      isFetching: fetchingTeamDetails,
    } = getTeamDetailsResponse || {}

    const {
      selectedMenu,
    } = this.state

    const {
      view_type_label: viewTypeLabel,
    } = currentFilters || {}

    const { data: ClientTransactionResponse, isFetching: clientTransactionFetching } = getClientTransactionResponse
    const { client_transactions_count, client_transactions } = ClientTransactionResponse || {}
    const { total_client_transactions_count } = client_transactions_count || {}
    const dndColumns = client_transactions || {}
    const { pathname } = location

    return (
      <div
        style={{
          marginTop: "31px",
        }}
      >
        {/* <RivenMortgageBanner /> */}
        <Wrap>
          <SectionHeader />
          <Tabs>
            {teamDetails && teamDetails.id ? (
              <TeamFilterComponent
                // handleMenuClick={this.handleMenuClick}
                firstValueOption={{ key: "My Leads", label: "My Active Clients", value: "view_mine" }}
                secondValueOption={{ key: "Team Leads", label: "Team Clients", value: "view_all_team_members" }}
                firstValueLabel="My Active Clients"
                secondValueLabel="Team Clients"
                defaultInitialName="My Active Clients"
              />
            ) : (
              <li>
                <StyledLink to="/assist/crm" isActive={pathname.includes("crm")}>
                  CLIENTS
                </StyledLink>
              </li>
            )}
            {/* <li>
            <StyledLink
              to="/assist/crmagain/assistleads"
              isActive={pathname.includes("assistleads")}
            >
              SUBMITTED LEADS
            </StyledLink>
          </li>*/}
            <CRMDashboard />
          </Tabs>
          <DragDropWrap>
            <DragAndDropArea
              dndColumns={dndColumns}
              leadsCount={total_client_transactions_count}
              stageLeadCount={client_transactions_count}
              hideMarkAsQualified
              toggleUserOverviewDrawer={toggleUserOverview}
              getClientTransactions={getClientTransactions}
              getClientTransactionResponse={getClientTransactionResponse}
              fetchingTransactions={clientTransactionFetching}
            />
          </DragDropWrap>
          <LinkButton
            color={colors.marineBLue}
            onClick={() => {
              window.location.href = "/archivedleads"
            }}
          >
            Archived Leads
          </LinkButton>
        </Wrap>
        <UserHeaderDrawer
          toggleUserOverview={toggleUserOverview}
          isUserOverviewDrawerOpen={isUserOverviewDrawerOpen}
          handleClose={() => this.handleClose()}
        />
        {isArchiveModalOpen && <ArchiveLeadsModal />}
        <AllCRMModals />
      </div>
    )
  }
}

export default AppContainer(TeamContainer(CRMContainer(BrbcContainer(flowContainer(CRMV2)))))
