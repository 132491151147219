import React from "react"
import styled from "styled-components"
import icons from "@icons"
import { get } from "lodash"
import colors from "@colors"

const Wrap = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: ${colors.disabledInput} 1px solid;
  position: relative;
  margin: 10px 20px 10px 90px;
`

const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
`

const ImgPrev = styled.div`
  flex: 1;
  min-height: 236px;
  background: ${colors.disabledInput} no-repeat center;
  background-size: contain;
`

const LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.disabledInput};
  min-height: 112px;

  span {
    font-size: 16px;
    font-weight: 100;
  }
`

const Meta = styled.div`
  background: ${colors.white};
  padding: 10px 20px;
  h2 {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
  }

 p {
   color: ${colors.dirtBrown};
   font-size: 10px;
   margin: 5px 0px;
   padding: 0px;
 }
`

const SnippetPreview = ({
  isFetchingSnippet,
  removeUploadedImage,
  snippetTitle,
  snippetURL,
  snippetImageURL,
}) => (
  <Wrap>
    {isFetchingSnippet ? (
      <LoadingState>
        <span>Loading...</span>
      </LoadingState>
    ) : (
      <React.Fragment>
        <CloseBtn onClick={() => { removeUploadedImage("link", snippetURL) }}>
          <i className={icons.arrowsClose}></i>
        </CloseBtn>
        <ImgPrev style={{ backgroundImage: `url(${snippetImageURL})` }} />
        <Meta>
          <h2>{snippetTitle}</h2>
          <p>{snippetURL}</p>
        </Meta>
      </React.Fragment>
    )}
  </Wrap>
)

export default SnippetPreview
