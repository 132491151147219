import React from "react"
import { Radio, Space } from "antd"
import Listings from "container/Listings"
import {
  FilterWrap,
} from "./styles"

const DaysOnMarket = ({
  onChange,
  value,
}) => (
  <FilterWrap>
    <Radio.Group onChange={onChange} value={value}>
      <Space direction="vertical">
        <Radio value="Less than 1 week">Less than 1 week</Radio>
        <Radio value="Less than 2 weeks">Less than 2 weeks</Radio>
        <Radio value="1 month">1 month</Radio>
        <Radio value="3 months">3 months</Radio>
        <Radio value="6 months">6 months</Radio>
        <Radio value="1 year">1 year</Radio>
      </Space>
    </Radio.Group>
  </FilterWrap>
)

export default Listings(DaysOnMarket)
