import styled from "styled-components"
import colors from "@colors"

export const LeaderBoardFontStyle = `
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
`

export const LeaderBoardHeading = styled.h1`
  ${LeaderBoardFontStyle}
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
`
