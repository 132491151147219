import React from "react"
import styled from "styled-components"
import colors from "@colors"

const Wrap = styled.div`
  padding: 20px;
`

const ErrorText = styled.h1`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.black};
`

const ErrorComponent = ({ error }) => {
  const errorString = error && error.toString()
  return (
    <Wrap>
      <ErrorText>Something went wrong!</ErrorText>
      <ErrorText>Please contact support</ErrorText>
      <pre>{errorString}</pre>
    </Wrap>
  )
}

class AppErrorBoundary extends React.Component {
  state = { hasError: false, error: "" }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    const {
      hasError,
      error,
    } = this.state

    const {
      children,
    } = this.props

    if (hasError) {
      return (
        <ErrorComponent error={error} />
      )
    }

    return children
  }
}

export default AppErrorBoundary
