/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "@ui/antd"
import TCContainer from "container/Realty/TransactionFormContainer"
import { ATC } from "@fonts"
import OfferImage from "./OfferImage.png"
import ThankYouTick from "./ThankYouTick.svg"
import Close from "./Close_9.svg"

const Wrap = styled.div`
  height: 111px;
  border: 0;
  margin-top: 18px;
  display: flex;
`
const ColorDiv = styled.div`
  background: #14245F;
  width: 100%;
  display: flex;
  justify-content: space-around;
`
const ColorClaimedDiv = styled.div`
  background: #3B60E4;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ImageDiv = styled.img`
`
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 22.5px;
`

const BigText = styled.h1`
  margin: 0px;
  padding: 0px;
  color: #F9F9F7;
  font-size: 22px;
  font-family: ${ATC};
  font-weight: 300;
  ${props => props.isClaimed && `
    font-weight: 400;
    font-size: 28px;
  `}
`
const SmallText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;
  color: #F9F9F7;
  padding: 0px;
  margin-top: 4px;
  ${props => props.isClaimed && `
    font-weight: 300;
    font-size: 14px;
  `}
`
const ClaimButton = styled(Button)`
  width: 148px;
  font-family: ${ATC};
  font-weight: 500;
  font-size: 14px;
  margin-top: 39.5px;
`
const CrossButton = styled.div`
  z-index: 10;
  margin-left: -23px;
  margin-top: -9px;
`

const TCOfferBanner = (props) => {
  const {
    setShowTCBannerForm,
    showTCBannerForm,
    agentId,
    postTCBanner,
    getTCBannerResponse,
    offerClaimed,
    setOfferClaimed,
    onClick,
  } = props
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if (getTCBannerResponse.data) {
      setShowBanner(getTCBannerResponse.data.isShow)
    }
  }, [getTCBannerResponse.data])

  const offerClaim = () => {
    postTCBanner(agentId)
    setOfferClaimed(true)
  }

  const onClickFunction = () => {
    if (onClick !== null) {
      onClick()
    }
  }

  return (
    <>
      {showBanner === true && showTCBannerForm === true ? (
        <Wrap>
          {offerClaimed === false ? (
            <>
              <ImageDiv src={OfferImage} />
              <ColorDiv>
                <TextDiv>
                  <BigText>Get 50 % off Transaction Coordination services on your next two deals!</BigText>
                  <SmallText>Close your deal quicker, save time and money with the assurance of a compliant file and streamlined process.</SmallText>
                </TextDiv>
                <ClaimButton
                  shape="round"
                  onClick={() => {
                    offerClaim()
                    onClickFunction()
                  }}
                >
                  Claim Offer
                </ClaimButton>
              </ColorDiv>
            </>
          ) : (
            <>
              <ColorClaimedDiv>
                <TextDiv>
                  <BigText isClaimed>Thank you for your interest!</BigText>
                  <SmallText isClaimed>Someone from our TC team will get in touch with you soon.</SmallText>
                </TextDiv>
                <ImageDiv src={ThankYouTick} />
              </ColorClaimedDiv>
              <CrossButton>
                <ImageDiv
                  src={Close}
                  onClick={() => {
                    setShowTCBannerForm(false)
                  }}
                />
              </CrossButton>
            </>
          )
          }
        </Wrap>
      ) : null }
    </>
  )
}

export default TCContainer(TCOfferBanner)

