import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import root from "window-or-global"
import DetailCardOne from "./DetailCardOne"
import DetailCardTwo from "./DetailCardTwo"
import DetailCardThree from "./DetailCardThree"
import DetailCardFour from "./DetailCardFour"
import LottieMachine from "./AnimationsJSON/LottieMachine"
import {
  MobileOne,
  MobileTwo,
  transactionTwoJSON,
  Approval,
  PerformanceTrack,
  P1,
  P2,
} from "./AnimationsJSON/index"

const Wrap = styled.div`
  display: block;
  // padding: 100px 0px;
  margin-top: 39px;

  display: flex;
  flex-direction: column;
  gap: 440px;

  @media(min-width: 1100px) {
    padding: 100px 0px;
  }

  @media(max-width: 1099px) {
    // padding: 0px;
    gap: 10px;
    margin-top: unset;
  }
`

const DesktopStickWrap = styled.div`
  display: block;
  position: sticky;
  top: 100px;
  padding-top: 50px;
  max-height: 576px;
  right: 55px;

  @media(max-width: 1099px) {
    display: none;
  }


  ${props => props.currentText === "Approval" && `
    background-image: url(${CLOUDFRONT}/TopRightGradient.svg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
  `}

  ${props => props.currentText === "Transaction" && `
    // background-image: url(${CLOUDFRONT}/DiamondSquaresPattern.svg);
    // background-position: right top;
    // background-repeat: no-repeat;
    // background-size: contain;
  `}

   ${props => props.currentText === "Performance" && `
    background-image: url(${CLOUDFRONT}/EggGradient.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
  `}

    transition: background-image 0.2s ease-in;
  }
  

`

const ParentWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: url(${CLOUDFRONT}/PaddedPolkaDotPattern.svg);
  background-repeat: repeat;
  background-size: contain;
  margin-top: 115px;
  background-position: 504px;
  background-attachment: fixed;

  @media(max-width: 1099px) {
    margin-top: unset;
    //background: transparent;
  }
`

const MOBILE = {
  animData: MobileOne,
  scrollFrames: [0, 200],
  endFrames: [200, 300],
  text: "Mobile",
  expand: false,
  reduce: false,
}

const MOBILE_II = {
  animData: MobileTwo,
  scrollFrames: [100, 200],
  endFrames: [0, 300],
  text: "Mobile",
  expand: false,
  reduce: false,
}

const APPROVAL = {
  animData: Approval,
  scrollFrames: [0, 150],
  endFrames: [150, 300],
  text: "Approval",
  expand: true,
  reduce: false,
}

const TRANSACTION = {
  animData: transactionTwoJSON,
  scrollFrames: [0, 100],
  endFrames: [100, 300],
  text: "Transaction",
  expand: false,
  reduce: false,
}

const PERFORMANCE = {
  animData: P1,
  scrollFrames: [0, 150],
  endFrames: [0, 250],
  text: "Performance",
  expand: false,
  reduce: true,
}

const PERFORMANCE_II = {
  animData: P2,
  scrollFrames: [150, 200],
  endFrames: [0, 250],
  text: "Performance",
  expand: false,
  reduce: true,
}

const AnimationSection = () => {
  const [currentAnim, setAnim] = useState(MOBILE)
  const MobileRef = useRef(null)

  const isInViewport = (targetElement) => {
    const rect = targetElement.getBoundingClientRect()
    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (root.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (root.innerWidth || document.documentElement.clientWidth)
    )
  }

  const handleScroll = () => {
    const elementOne = document.getElementById("mobileBusiness_1")
    const elementOneContinuous = document.getElementById("mobileBusiness_2")
    const elementTwo = document.getElementById("approval")

    const elementThree = document.getElementById("transaction")
    const elementFour = document.getElementById("performance")
    const elementFourContinuous = document.getElementById("performance_II")

    if (isInViewport(MobileRef.current)) {
      if (isInViewport(elementOneContinuous)) {
        setAnim(MOBILE_II)
      } else {
        setAnim(MOBILE)
      }
    } else if (isInViewport(elementTwo)) {
      setAnim(APPROVAL)
    } else if (isInViewport(elementThree)) {
      setAnim(TRANSACTION)
    } else if (isInViewport(elementFour)) {
      if (isInViewport(elementFourContinuous)) {
        setAnim(PERFORMANCE_II)
      } else {
        setAnim(PERFORMANCE)
      }
    } else {
      //setAnim(MOBILE)
    }
  }

  useEffect(() => {
    root.addEventListener("scroll", handleScroll)

    return () => {
      root.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <ParentWrap>
      <Wrap className="container">
        <DetailCardOne
          MobileRef={MobileRef}
        />
        <DetailCardTwo />
        <DetailCardThree />
        <DetailCardFour />
      </Wrap>
      <DesktopStickWrap
        currentText={currentAnim.text}
        overflowHide={currentAnim.expand}
      >
        <LottieMachine
          animationData={currentAnim.animData}
          scrollFrames={currentAnim.scrollFrames}
          endFrames={currentAnim.endFrames}
          expand={currentAnim.expand}
          reduce={currentAnim.reduce}
          modified
        />
      </DesktopStickWrap>
    </ParentWrap>

  )
}

export default AnimationSection
