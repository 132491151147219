export const REQUESTING_SIGN_POST_INSTALLATION = [
  {
    id: 1,
    name: "Yes",
    value: "sign_post_installation_request_yes",
  },
  {
    id: 2,
    name: "No",
    value: "sign_post_installation_request_no",
  },
]

export const FIELD_VALUES = {
  schedule_sign_post_installation: "checkbox",
  sign_post_installation_on: "date",
  sign_post_installation_riders: "string",
  sign_post_installation_location: "string",
  hoa_guidelines: "string",
}
