//currentTransactionDashboardTab

import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import TechAssets from "container/TechAssets/index"
import Listings from "container/Listings/index"
import ListingsV2 from "container/ListingsV2/index"
import OffersV2 from "container/OffersV2/index"
import cookie from "services/CookieStorage"
import { Space } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import colors from "@colors"
import { useWindowSize } from "services/Utils"
import {
  InputSearch,
} from "dumbComponents/common/InputFields"
import moment from "moment"
import TeamFilterAndSearch from "./TeamFilterAndSearch"
import {
  Wrap,
  FilterWrapper,
  FilterButton,
  CommonFilterWrap,
  FilterWrap,
  ClearFilterButton,
} from "./styles"
import SelectStatusFilter from "./SelectStatusFilter"
import FieldShimmer from "../FieldShimmer"
import { DATE_FILTERS } from "../../const"
import DateFilter from "./DateFilterTemp"

const TransactionFilters = ({
  constructTransaction,
  transactionQuery,
  currentTransactionType,
  getOfferStatusMappingsResponse,
  fetchContractStatusesResponse,
  getTeamDetailsResponse,
  fetchTransactionDataResponse,
  isFilterTabOpen,
  setFilterTabOpen,
}) => {
  const {
    isFetching: gettingOfferStatuses,
    data: offerStatus,
  } = getOfferStatusMappingsResponse || {}

  const {
    isFetching: gettingListingStatuses,
    data: transactionStatuses,
  } = fetchContractStatusesResponse || {}

  const {
    listingStatus,
    contractStatus,
  } = transactionStatuses || {}

  const {
    isFetching: gettingTransactionData,
  } = fetchTransactionDataResponse || {}
  const [searchValue, setSearchValue] = useState("")

  const { isFetching: fetchingTeams, data: teamData } = getTeamDetailsResponse || {}
  const { width: screenWidth } = useWindowSize()
  const statusList = currentTransactionType === "buyer" ? offerStatus : listingStatus
  const fetchingStatuses = currentTransactionType === "buyer" ? gettingOfferStatuses : gettingListingStatuses
  const teamExists = cookie.load("brokerageTeamId") || ""
  const tcInformation = cookie.load("tcInformation")
  const teamLead = teamData?.team_members?.find(member => member.member_type === "Team Lead")
  const teamLeadId = teamLead ? teamLead.agent_id : null
  const handleTeamFilterChange = (selectedValue, teamMember = null) => {
    const map = {
      "Team Transactions": "view_all_team_members",
      "My Transactions": "view_mine",
      "All Transactions": "view_all",
    }
    const curr = {
      ...transactionQuery,
    }
    if (tcInformation?.is_tc) {
      curr.agentId = teamLeadId
    }

    // eslint-disable-next-line no-prototype-builtins
    if (map.hasOwnProperty(selectedValue)) {
      curr.viewType = map[selectedValue]
      curr.agentSelectedFilter = selectedValue
    } else {
      curr.viewType = "view_specific_team_members"
      curr.teamMemberIds = [parseInt(selectedValue, 10)]
      curr.agentSelectedFilter = teamMember
    }
    curr.clientTransactionType = currentTransactionType

    constructTransaction({
      query: curr,
    })
  }
  const handleStatusChange = (value) => {
    const curr = {
      ...transactionQuery,
      dealType: value,
    }
    curr.clientTransactionType = currentTransactionType
    constructTransaction({
      query: curr,
    })
  }

  const handleContractStatusChange = (value) => {
    const curr = {
      ...transactionQuery,
      contractStatus: value,
    }

    curr.clientTransactionType = currentTransactionType
    constructTransaction({
      query: curr,
    })
  }

  const handleDateChange = (value) => {
    if (typeof value === "object" && value.value === "custom_range") {
      const fromDateLabel = moment.unix(value.fromDate) // Convert Unix timestamp to Moment.js object
      const toDateLabel = moment.unix(value.toDate)
      const curr = {
        ...transactionQuery,
        dateFilter: `${fromDateLabel.format("YYYY-MM-DD")} - ${toDateLabel.format("YYYY-MM-DD")}`,
        fromDate: value.fromDate,
        toDate: value.toDate,
      }

      curr.clientTransactionType = currentTransactionType
      constructTransaction({ query: curr })
    } else {
      const toDateValue = moment().unix() // Current timestamp
      let fromDateValue
      let valueLabel

      switch (value) {
        case "7_days":
          fromDateValue = moment().subtract(7, "days").unix()
          valueLabel = "Last 7 days"
          break
        case "30_days":
          fromDateValue = moment().subtract(30, "days").unix()
          valueLabel = "Last 30 days"
          break
        case "90_days":
          fromDateValue = moment().subtract(90, "days").unix()
          valueLabel = "Last 90 days"
          break
        case "1_year":
          fromDateValue = moment().subtract(1, "year").unix()
          valueLabel = "Last 1 year"
          break
        default:
          console.error("Invalid value")
          valueLabel = "Select Date"
          return
      }

      const curr = {
        ...transactionQuery,
        dateFilter: valueLabel,
        fromDate: fromDateValue,
        toDate: toDateValue,

      }

      curr.clientTransactionType = currentTransactionType
      constructTransaction({
        query: curr,
      })
    }
  }

  const getStatusValue = () => {
    const {
      dealType,
    } = transactionQuery || {}

    if (!dealType || dealType === null) return null

    if (dealType) {
      return dealType
    }

    return null
  }

  const getContractStatusValue = () => {
    const {
      contractStatus: currentVal,
    } = transactionQuery || {}

    if (!currentVal || currentVal === null) return null

    if (currentVal) {
      return currentVal
    }

    return null
  }
  const getDateValue = () => {
    const {
      dateFilter,
    } = transactionQuery || {}

    if (!dateFilter || dateFilter === null) return null

    if (dateFilter) {
      return dateFilter
    }

    return null
  }

  const getTeamFilter = () => {
    const {
      agentSelectedFilter,
    } = transactionQuery || {}

    if (!agentSelectedFilter || agentSelectedFilter === null) return null

    if (agentSelectedFilter) {
      return agentSelectedFilter
    }

    return null
  }

  const clearFilters = () => {
    const curr = {
      ...transactionQuery,
    }

    Object.keys(curr).forEach((item) => {
      curr[item] = null
    })

    curr.skip = 0
    curr.limit = 5
    curr.overview = false
    curr.dateFilter = "Select Date"
    curr.clientTransactionType = currentTransactionType
    setSearchValue("")

    if (tcInformation?.is_tc) {
      curr.viewType = "view_all"
      curr.agentId = teamLeadId
      constructTransaction({
        query: curr,
      })
    } else {
      constructTransaction({
        query: curr,
      })
    }
  }

  const showFilters = () => {
    setFilterTabOpen(!isFilterTabOpen)
  }

  const triggerQuery = (value) => {
    const curr = {
      ...transactionQuery,
      searchTextInProperty: value || "",
    }
    curr.clientTransactionType = currentTransactionType
    setSearchValue(value || "")
    constructTransaction({
      query: curr,
    })
  }

  return (
    <Wrap>
      <FilterWrapper>
        {
          fetchingStatuses || gettingTransactionData ? (
            <FieldShimmer width="1130px" />
          ) : (
            <>
              <InputSearch
                placeholder={currentTransactionType == "buyer" ? "Search by address or buyer name" : "Search by address or seller name"}
                onSearch={e => triggerQuery(e)}
                style={screenWidth <= 1440 ? { width: 700, borderRadius: "4px" } : { width: 1030, borderRadius: "4px" }}
                noWidth
                borderColor={colors.transaction_tab_select}
                defaultValue={searchValue}
              />
              <FilterButton onClick={showFilters}>
                {" "}
                <Space>
                  Filters
                  {
                    isFilterTabOpen ? (<UpOutlined style={{ position: "relative", top: "-2px" }} />) : (<DownOutlined style={{ position: "relative", top: "-2px" }} />)
                  }
                </Space>
                {" "}
              </FilterButton>
            </>

          )
        }
      </FilterWrapper>
      {
        isFilterTabOpen && (
          <FilterWrapper>
            <FilterWrap>
              {teamExists && teamExists !== "null" && teamExists !== undefined && (
              <>
                {
                  (fetchingTeams || fetchingStatuses || gettingTransactionData) ? (
                    <FieldShimmer width="300px"/>
                  ) : (
                    <>
                      {
                        (tcInformation?.is_tc) ? (
                          <TeamFilterAndSearch
                            initOptions={["All Transactions"]}
                            onMainSelectChange={handleTeamFilterChange}
                            borderColor={colors.transaction_tab_select}
                            currentSearchValue={getTeamFilter()}
                          />
                        ) : (
                          <TeamFilterAndSearch
                            initOptions={["My Transactions", "Team Transactions", "All Transactions"]}
                            onMainSelectChange={handleTeamFilterChange}
                            borderColor={colors.transaction_tab_select}
                            currentSearchValue={getTeamFilter()}
                          />
                        )
                      }
                    </>
                  )
                }
              </>
              )}
            </FilterWrap>
            <FilterWrap>
              <CommonFilterWrap>
                {
                  fetchingStatuses || gettingTransactionData ? (
                    <FieldShimmer width="300px"/>
                  ) : (
                    <SelectStatusFilter
                      handleStatusChange={handleStatusChange}
                      currentStatus={getStatusValue()}
                      optionList={statusList}
                      loading={fetchingStatuses}
                      borderColor={colors.transaction_tab_select}
                      placeholder={currentTransactionType == "buyer" ? "Offer status" : "Listing status"}
                    />
                  )

                }
                {/* {
                  fetchingStatuses || gettingTransactionData || gettingListingStatuses ? (
                    <FieldShimmer />
                  ) : (
                    <SelectStatusFilter
                      handleStatusChange={handleContractStatusChange}
                      currentStatus={getContractStatusValue()}
                      optionList={contractStatus}
                      loading={gettingListingStatuses}
                      borderColor={colors.transaction_tab_select}
                      placeholder="Contract status"
                    />
                  )

                } */}
                {
                  fetchingStatuses || gettingTransactionData ? (
                    <FieldShimmer width="300px"/>
                  ) : (
                    <DateFilter
                      handleDateChange={handleDateChange}
                      currentValue={getDateValue()}
                      optionList={DATE_FILTERS}
                      borderColor={colors.transaction_tab_select}
                    />
                  )

                }
              </CommonFilterWrap>
              {
                fetchingStatuses || gettingTransactionData ? (
                  <></>
                ) : (
                  <ClearFilterButton
                    onClick={clearFilters}
                    style={{ position: "relative", top: "5px" }}
                  >
                    <p>Clear</p>
                  </ClearFilterButton>
                )
              }
            </FilterWrap>
          </FilterWrapper>
        )
      }
    </Wrap>
  )
}

export default Listings(ListingsV2(TechAssets(withRouter(OffersV2(TeamProfileContainer(TransactionFilters))))))
