import React from "react"
import styled from "styled-components"
import Icons from "@icons"
import FormSelectionPurple from "images/icons/form_selection_purple.svg"

const Label = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
`

const Checkmark = styled.span`
  position: absolute;
  font-family: 'icomoon' !important;
  line-height: 1;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  font-size: 20px;
  color: transparent;
  border: 1px solid #CCC;
  border-radius: 100px;

  &:after {
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    color: #2196F3;
    display: block;
    border: 0px solid #CCC;
    background-image: url(${FormSelectionPurple});
  }
`

const LabelDiv = styled.div`
  padding-left: 35px;
  display: flex;
  flex-direction: column;
`

const LabelName = styled.p``

const DescriptionText = styled.p``

const CheckboxV2 = ({
  label,
  checked,
  input,
  labelStyles,
  description,
  descriptionStyles,
  wrapperStyles,
  checkmarkStyle,
  ...props
}) => (
  <Label
    style={{
      ...wrapperStyles,
    }}
  >
    <Input
      type="checkbox"
      {...input}
    />
    <LabelDiv>
      <p
        style={{
          ...labelStyles
        }}
      >
        {label}
      </p>
      {description && (
        <DescriptionText
          style={{
            ...descriptionStyles
          }}
        >
          {description}
        </DescriptionText>
      )}
    </LabelDiv>
    <Checkmark
      style={{
        ...checkmarkStyle,
      }}
    />
  </Label>
)

export default CheckboxV2
