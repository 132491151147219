import React from "react"
import styled from "styled-components"
import colors from "@colors"
import BlueTick from "images/Graphics/HomeV6/CheckmarkTransparent.svg"
import ArrowRight from "images/Graphics/Button/ArrowRight_1.svg"
import root from "window-or-global"
import {
  preDesktopWidth,
  preTabletWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
  minMediumDesktopWidth,
} from "shared/consts"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"

const Wrap = styled.div`
  padding-top: 105px;

  //cannot use media query util inside a prop condition
  @media(min-width: 1100px) {
    padding-top: 60px;

    ${props => props.leftPadding && `
      padding-left: 50px;
    `}

  }

  ${minLargeDesktopWidth`
    padding-left: 50px;
  `}

  ${preDesktopWidth`
    padding-top: 42px;
  `}
`

const TagOrTextWrap = styled.div``

const Text = styled.h2`
  font-family: ${props => props.tagFontFamily || "DM Sans"};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: left;
  color: ${colors.black};

  ${preDesktopWidth`
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: left;
  `}

  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: left;
  `}
`

const Tag = styled.div`
  height: 37px;
  width: 132px;
  border-radius: 7px;
  padding: 6px;
  background: transparent;
  font-family: ${props => props.tagFontFamily || "DM Sans"};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
  color: ${colors.black};

  ${preDesktopWidth`
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: left;
  `}

  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: left;
  `}
`

const HeaderTextWrap = styled.div`
  margin-top: 11px;
`

const HeaderText = styled.h2`
  font-family: ${props => props.headerFontFamily || "DM Sans"};
  font-size: 30px;
  color: ${colors.black};
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  width: ${props => props.headerTextWidth};

  ${preDesktopWidth`
    font-family: Butler;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.015em;
    text-align: left;
    width: 100%;
  `}

  ${preTabletWidth`
    font-family: Butler;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    width: 100%;
  `}
`

const SubHeaderTextWrap = styled.div`
  margin-bottom: -15px;
`

const SubHeaderText = styled.h3`
  font-family: ${props => props.contentFontFamily || "DM Sans"};
  color: ${colors.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 370px;

  @media(min-width: 1100px) {
    width: ${props => props.subTextWidthMinDesktop || "370px"}
  }

  ${preDesktopWidth`
    width: 280px;
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  `}

  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  `}
`

const CheckPointWrap = styled.div`
  margin-top: 33px;
`

const ListUL = styled.div`
  width: 334px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const ListLI = styled.div`
  display: flex;
  align-items: baseline;
`
const ListText = styled.p`
  font-family: ${props => props.contentFontFamily || "DM Sans"};
  font-size: 18px;
  color: ${colors.black};
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 9px;

  //cannot use media query util inside or with a prop condition
  @media(min-width: 1100px) {
    width: ${props => props.customListWidthMinDesktop || "max-content"};
  }

  ${minMediumDesktopWidth`
    width: max-content;
  `}

  @media(max-width: 1099px) {
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: ${props => props.customListWidthTablet || "max-content"};
  }

  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  `}
`
const BlueTicketImg = styled.img`
  position: relative;
  top: 2px;

  ${preDesktopWidth`
    top: 6px;
  `}
`

const HyperLinkCTAWrap = styled.div``

const CheckPointListWrap = styled.div``

const HyperLinkCTA = styled.button`
  display: block;
  background: transparent;
  cursor: pointer;
  font-family: ${ATC};
  font-size: 16.15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  border: 1px solid ${colors.marineBLue};
  color: ${colors.marineBLue};
  margin-top: 33px;
  padding: 9px 13px 13px 9px;


  ${preTabletWidth`
    font-family: ${ATC};
    font-size: 16.15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  `}
`

const LMPStatWrap = styled.div`
  display: flex;
  flex-direction: row;
  ${preDesktopWidth`
    gap: 18px;
  `}

  ${preTabletWidth`
    flex-direction: column;
  `}
`

const StatDiv = styled.div`
  height: 90px;
  width: 197.14886474609375px;
  border-radius: 9px;
  padding: 14px 0px 0px 24px;
  background: ${colors.dreamWhite};
  color: ${colors.black};

  ${minDesktopWidth`
    margin-top: 15px;
    margin-right: 28px;
  `}

  //cannot use media query util inside or with a prop condition
  @media(max-width: 1099px) {
    width: 184px;
    ${props => props.marginTop && `
      margin-top: ${props.marginTop};
    `}
  }

  ${preTabletWidth`
    display: flex;
    gap: 10px;
    align-items: baseline;
    height: 51px;
    width: 270px;
    border-radius: 9px;
    padding: 9px 24px 0px 24px;
    background: #F9F9F7;
    color: #303030;
  `}
`

const Amount = styled.div`
  font-family: Butler, DM Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;

  ${preDesktopWidth`
    font-size: 20px;
  `}

  ${preTabletWidth`
    font-size: 18px;
  `}
`

const Desc = styled.div`
  font-family: ${ATC}, DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`
const ArrowSpan = styled.span`
  font-size: 25px;
  position: relative;
  right: -2px;
  top: 4px;
`

const Content = ({
  isTagHeaderText,
  tagHeaderText,
  headerText,
  tagText,
  subHeaderText,
  checklistData,
  hyperLink,
  headerTextWidth,
  customListWidthTablet,
  customListWidthMinDesktop,
  isLMP,
  url,
  history: {
    push,
  },
  action,
  heap,
  leftPadding,
  subTextWidthMinDesktop,
  headerFontFamily,
  tagFontFamily,
  contentFontFamily,
}) => (
  <Wrap leftPadding={leftPadding}>
    <TagOrTextWrap>
      {isTagHeaderText ? (
        <Text tagFontFamily={tagFontFamily}>{tagHeaderText}</Text>
      ) : (
        <Tag tagFontFamily={tagFontFamily}>
          {tagText}
        </Tag>
      )}
    </TagOrTextWrap>
    <HeaderTextWrap>
      <HeaderText
        headerFontFamily={headerFontFamily}
        headerTextWidth={headerTextWidth}
      >
        {headerText}
      </HeaderText>
    </HeaderTextWrap>
    <SubHeaderTextWrap>
      <SubHeaderText
        contentFontFamily={contentFontFamily}
        subTextWidthMinDesktop={subTextWidthMinDesktop}
      >
        {subHeaderText}
      </SubHeaderText>
    </SubHeaderTextWrap>
    <CheckPointWrap>
      <CheckPointListWrap>
        <ListUL>
          {checklistData && checklistData.map(checklist => (
            <ListLI>
              <BlueTicketImg src={BlueTick} />
              <ListText
                contentFontFamily={contentFontFamily}
                customListWidthMinDesktop={customListWidthMinDesktop}
                customListWidthTablet={customListWidthTablet}
              >
                {checklist.text}
              </ListText>
            </ListLI>
          ))}
        </ListUL>
      </CheckPointListWrap>
    </CheckPointWrap>
    {isLMP && (
      <LMPStatWrap>
        <StatDiv marginTop="15px">
          <Amount>$200M+</Amount>
          <Desc>worth of referrals</Desc>
        </StatDiv>
        <StatDiv marginTop="12px">
          <Amount>11,000+</Amount>
          <Desc>total referrals</Desc>
        </StatDiv>
      </LMPStatWrap>
    )}
    {hyperLink && (
      <HyperLinkCTAWrap>
        <HyperLinkCTA
          onClick={() => {
            if (action && typeof action === "function") action()
            if (url) {
              if (root && root.heap) root.heap.track(heap)
              push(url)
            }
          }}
        >
          {hyperLink}
          <ArrowSpan>
            <img src={ArrowRight} alt="arrow" />
          </ArrowSpan>
        </HyperLinkCTA>
      </HyperLinkCTAWrap>
    )}
  </Wrap>
)

export default withRouter(Content)
