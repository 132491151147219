import React from "react"
import Box from "@ui/Box"

class PropertyListing extends React.Component {
  componentDidMount() {
    const { fetchClientPropertyListing } = this.props
    fetchClientPropertyListing()
  }

  render() {
    const { children, isLoadingProperties } = this.props
    if (isLoadingProperties) {
      return <span>Loading...</span>
    }
    return (
      <Box mt="20">
        { children }
      </Box>
    )
  }
}

export default PropertyListing
