import React, { useRef } from "react"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
} from "@ui/antd"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
} from "./commonStyles"
import {
  InputField,
  InputNumber,
  SelectField,
  InputLocation,
} from "../../InputFields"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"
import { TYPE_OF_OWNERSHIP } from "dumbComponents/OffersV2/consts"

const BuyerForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteBuyer,
}) => {
  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData) {
      initValues = {
        ...prefilledData,
      }
    }

    return initValues
  }

  return (
    <FormWrap>
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Buyer Form"}</p>
        </FormTitle>
        <CancelOrGoBackButton
          onClick={() => {
            const id = prefilledData ? prefilledData.f_id : ""
            handleEditModeChange(id)
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V2/minus.svg`} alt="minus" />
        </CancelOrGoBackButton>
      </HeaderWrap>

      <Form
        name="offers_buyer_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
      >
        <InputField
          name="name"
          inputType="text"
          placeholder="Name*"
        />

        <InputField
          name="buyer_lls_trust"
          inputType="text"
          placeholder="LLC/Trust"
        />
        
        {/* <InputField
          name="buyer_client_source"
          inputType="text"
          placeholder="Client Source*"
        /> */}

        <InputField
          name="email"
          inputType="email"
          type="email"
          placeholder="Email*"
        />

        <InputNumber
          name="phone"
          inputType="phone"
          type="phone"
          placeholder="Phone*"
        />

        {/**Requires Google Address*/}
        {/* <InputField
          name="buyer_street_address"
          inputType="text"
          type="text"
          placeholder="Street Address*"
        /> */}

        <InputLocation
          name="buyer_street_address"
          inputType="text"
          type="text"
          placeholder="Street Address*"
          formRef={formRef}
        />

        <InputField
          name="buyer_apartment_number"
          inputType="text"
          placeholder="Apt / Unit / Suit No."
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
        />

        <InputField
          name="state"
          inputType="text"
          placeholder="State"
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zipcode"
        />

        <SelectField
          isFormField
          name="type_of_ownership"
          options={TYPE_OF_OWNERSHIP}
          defaultValue={prefilledData ? prefilledData.type_of_ownership : null}
          placeholder="Type of Ownership*"
        />

        <CTAWrap>
          <Form.Item>
            <DangerButton
              type="button"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="button"
              onClick={() => deleteBuyer(prefilledData.f_id)}
            >
              <p>Delete</p>
            </DangerButton>
          </Form.Item>

          <Form.Item>
            <BlueButton
              type="submit"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton>
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default BuyerForm
