import {
  createSignalAction,
} from "shared/reduxUtils"

const BASE = "RAR_LEADERBOARD"

export const getLeaderBoardStats = createSignalAction(BASE, "RAR_LEADERBOARD_STATS")
export const getTeamStats = createSignalAction(BASE, "RAR_TEAM_STATS")
export const getLeaderBoardLists = createSignalAction(BASE, "RAR_LEADERBOARD_LISTS")
export const resetLeaderBoardLists = createSignalAction(BASE, "RAR_LEADERBOARD_RESET_LISTS")
export const getLeaderBoardMonthlyWinner = createSignalAction(BASE, "RAR_LEADERBOARD_MONTHLY_WINNER")
export const getHomePageGraphStats = createSignalAction(BASE, "HOME_PAGE_GRAPH_STATS")

