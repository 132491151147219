import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"
import root from "window-or-global"
import { LoadScript, isMobile } from "services/Utils"
import { mobile } from "@consts"
import Box from "@ui/Box"
import colors from "@colors"
import icons from "@icons"
import "video-react/dist/video-react.css"

const Wrap = styled.div`
  min-height: 300px;
  ${mobile`
    min-height: auto;
  `}

  iframe {
    height: 500px;
    width: 100%;
  }
`

const VideoContainer = styled.div`
  position: relative;
`

const VideoOverlap = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
`

const NextLessonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 100%;
  max-width: 300px;
  padding: 16px;
  background: ${colors.white};
  border-radius: 4px;
  pointer-events: all;

  ${mobile`
    background: rgba(57, 36, 99, 0.75);
    max-width: none;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border-radius: 0px;

    h6, h1 {
      display: none;
    }
  `}

  h6 {
    margin: 0px;
    font-size: 14px;
    color: ${colors.silkGrey};
  }

  h1 {
    font-size: 18px;
  }
`

const NextButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: ${colors.white};
  border-radius: 4px;
  ${mobile`
    color: ${colors.white};
  `}

  > span {
    display: none;
    ${mobile`
      display: inline-block;
    `}
  }
`

class VideoPlayer extends React.PureComponent {
  state = {
    isReachedEndOfVideo: false,
  }

  componentDidMount() {
    LoadScript("https://player.vimeo.com/api/player.js", () => {
      const { url, updateVideoStatus, watchedTill } = this.props
      const $node = root.document.getElementById("academy-player-wrap")
      const isMobileDevice = isMobile()
      const videoOptions = {
        url,
        byline: false,
        title: false,
        autoplay: true,
        width: $node ? $node.offsetWidth : 100,
        height: isMobileDevice ? "auto" : 600,
      }
      this.player = new root.Vimeo.Player($node, videoOptions)

      if (watchedTill > 10) {
        this.player.setCurrentTime(watchedTill).then(() => {
          // `seconds` indicates the actual time that the player seeks to
        }).catch((error) => {
          console.log("error settings time", error)
        })
      }

      this.player.on("timeupdate", (data) => {
        console.log(data)
        const { isReachedEndOfVideo } = this.state
        const timePassed = parseInt(data.seconds, 10)
        const timeLeft = data.duration - timePassed
        if (!isReachedEndOfVideo && timeLeft < 20) {
          this.reachedEndOfTheVideo()
        }
        if (timePassed % 10 === 0) {
          updateVideoStatus(timePassed)
        }
      })
    })
  }

  reachedEndOfTheVideo = () => {
    const { handleNextLesson } = this.props
    this.setState({
      isReachedEndOfVideo: true,
    })
    handleNextLesson()
  }

  isVimeo = () => {
    const {
      url,
    } = this.props

    return url.includes("vimeo")
  }

  render() {
    const { isReachedEndOfVideo } = this.state
    const {
      nextLesson,
      history,
      totalLessonCount,
      match: {
        params,
      },
    } = this.props
    const {
      sequence_number,
      title,
    } = nextLesson || {}

    return (
      <Wrap>
        <VideoContainer id="academy-player-wrap">
          {isReachedEndOfVideo && !isEmpty(nextLesson) && (
            <VideoOverlap>
              <NextLessonWrap>
                <h6>{`Lesson ${sequence_number || ""} / ${totalLessonCount || ""}`}</h6>
                <Box
                  d="flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(`/academy/course-details/${params.courseId}/lesson/${nextLesson.id}?next_video=true`)
                  }}
                >
                  <Box flex="1">
                    <h1>{title}</h1>
                  </Box>
                  <NextButton>
                    <span>Next</span>
                    <i className={icons.arrowsArrowRight}></i>
                  </NextButton>
                </Box>
              </NextLessonWrap>
            </VideoOverlap>
          )}
        </VideoContainer>
      </Wrap>
    )
  }
}

export default withRouter(VideoPlayer)
