import React, { Component } from "react"
import styled from "styled-components"
import Container from "container/Settings/BillingContainer"
import colors from "@colors"
import { get } from "lodash"
import Loader from "@ui/Loader"
import Box from "@ui/Box"
import Button from "@ui/Button"
import StripeCheckout from "react-stripe-checkout"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import ErrorIcon from "images/icons/error-icon.svg"
import CheckIcon from "images/icons/check-icon.png"
import VisaImg from "images/settings/visa.png"
import DownloadImg from "images/settings/paymeny-Union.svg"
import EditImg from "images/settings/card-Edit.svg"
import MasterCardImg from "images/settings/master-card.png"
import Modal from "@ui/Modal"
import { format } from "date-fns"
import { DEPRECATE_pricingPlansMap } from "dumbComponents/Referral/ISAAgreement/common/PricingSection"
import { getFormattedCardDetails } from "services/Utils"

export const updateCardModalStates = {
  loading: "loading",
  success: "success",
  error: "error",
}

const OuterWrap = styled.div`
  margin-bottom: 60px;
  color: ${colors.contentText};
  font-weight: 300;
`

const BrandImg = styled.img`
  max-height: 30px;
`

const SectionHeading = styled.h3`
  color: ${colors.primaryColor};
  font-size: 16px;
  font-weight: 500;
`

const Wrapper = styled.div`
  margin-top: 24px;
`

const CardSection = styled.div`
  position: relative;
  background-color: ${colors.transparentWhite};
  border: 1px solid ${colors.moonGrey};
  border-radius: 16px;
  padding: 20px 20px 0;
  height: fit-content;
  margin-bottom: 20px;
`

const CardHeader = styled.h4`
  font-size: 22px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  color: ${colors.marineBLue};
`

const ActionRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
`

const ActionItem = styled.span`
  color: ${colors.black};
  cursor: pointer;
  position: absolute;
  top: -35px;
  right: 10px;

  img {
    max-height: 16px;
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const ActionLink = styled.a`
  color: ${colors.primaryColor};
  text-decoration: none;

  img {
    max-height: 16px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const RowHeader = styled.p`
  margin-bottom: 0;
  color: ${colors.tagInactive};
  font-weight: 500;
`

const DetailsRow = styled.p`
  margin-bottom: 0;
  display: flex;
`

const UpdateCardResponseModal = styled.div`
  text-align: center;
  padding: 0 20px 32px;

  p {
    text-align: center;
  }
`

const ExpiredTag = styled.span`
  color: ${colors.redText};
`

const CardDetails = styled.div`
  margin-left: 30px;
  p {
    font-size: 16px;
    color: ${colors.black};
    padding: 0px;
    margin: 0px;
  }

  h6 {
    color: ${colors.suvaGray};
    margin: 0px;
    font-size: 14px;
  }
`

const StyledCheckIcon = styled.img`
  background: ${props => (props.error ? "#FFF" : "#51d3b7")};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0px auto;
  display: block;
  position: relative;
  top: -40px;
`

const EmptyStateText = styled.p`
  color: ${colors.black};
  margin-bottom: 20px;
`

const NoCardWrap = styled.div`
  padding-bottom: 20px;
`

const Status = styled.div`
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px;
  border-radius: 4px;
  display: inline-block;

  ${props => props.isSuccess ? `
    background: #b9ffe0;
    color: ${colors.gradientGreen};
  ` : `
    color: ${colors.errorDanger};
    background: #f8cdcd;
  `}

`

class Billing extends Component {
  componentDidMount() {
    const {
      fetchSubscriptions,
      fetchDefaultCard,
      fetchInvoices,
      getCardDetails,
    } = this.props
    fetchSubscriptions()
    fetchDefaultCard()
    fetchInvoices()
    getCardDetails()
  }

  handleUpdateCard = ({ id: tokenId }) => {
    const {
      saveStripeToken,
      fetchSubscriptions,
      fetchDefaultCard,
      fetchInvoices,
      getCardDetails,
    } = this.props
    saveStripeToken({
      token: tokenId,
      callback: () => {
        fetchSubscriptions()
        fetchDefaultCard()
        fetchInvoices()
        getCardDetails()
      },
    })
  }

  handleModalUpdate = (nextState = false) => {
    const { toggleUpdateCardModal } = this.props
    toggleUpdateCardModal(nextState)
  }

  getDate = dateString => format(dateString * 1000, "MM/DD/YYYY")

  getPlanNameFromPlanId = (planId) => {
    const {
      pricingPlansMap,
    } = this.props
    if (pricingPlansMap && Object.keys(pricingPlansMap).length > 0) {
      return pricingPlansMap[planId] && pricingPlansMap[planId].name || "Live transfer addon" //TODO : rewrite this map 
    }
    return "Retrieving.."
  }

  getPlanNames = (data) => {
    const planNames = []
    let isCharge = false
    data.forEach((item) => {
      if (item.plan) {
        planNames.push(this.getPlanNameFromPlanId(item.plan.id))
      } else {
        isCharge = true
      }
    })
    if (planNames.length === 0 && isCharge) {
      planNames.push("100 bulk leads")
    }
    return planNames.join(", ")
  }

  getBillingDateDetails = (categorizedSubscriptions, subscriptions) => {
    let nextBillingDate = null
    let isPlanCancelled = false
    if (categorizedSubscriptions.rtl) {
      const planId = categorizedSubscriptions.rtl
      let i
      for (i = 0; i < subscriptions.length; i += 1) {
        const subscription = subscriptions[i]
        if (subscription.plan && subscription.plan.id === planId && subscription.plan.active) {
          nextBillingDate = format(subscription.current_period_end * 1000, "MMMM DD, YYYY")
          isPlanCancelled = !!subscription.canceled_at
          break
        } else if (
          subscription.items
          && subscription.items.data
          && subscription.items.data[0].plan.id === planId) {
          nextBillingDate = format(subscription.current_period_end * 1000, "MMMM DD, YYYY")
          isPlanCancelled = !!subscription.canceled_at
        }
      }
    }
    return {
      nextBillingDate,
      isPlanCancelled,
    }
  }

  render() {
    const {
      categorizedSubscriptions,
      subscriptions,
      isFetchingSubscription,
      isFetchingCard,
      updateCardModalState,
      isFetchingInvoices,
      invoices,
      savedCard,
    } = this.props

    const { nextBillingDate, isPlanCancelled } = this.getBillingDateDetails(categorizedSubscriptions, subscriptions)
    const savedCardData = get(savedCard, "data.payment_sources[0]")

    let cardDetails = savedCardData

    if (savedCardData) {
      cardDetails = getFormattedCardDetails(savedCardData.expiration_month, savedCardData.expiration_year)
    }

    return (
      <OuterWrap>
        <SectionHeading>BILLING</SectionHeading>
        <Wrapper>
          <CardSection>
            <CardHeader>Payment Details</CardHeader>
            {savedCardData && (
              <ActionRow>
                <StripeCheckout
                  token={this.handleUpdateCard}
                  stripeKey={`${STRIPE_PUBLISH_KEY}`}
                  ComponentClass="div"
                  panelLabel="Update Card"
                >
                  <ActionItem>
                    <img src={EditImg} alt="Edit Icon" />
                    Edit
                  </ActionItem>
                </StripeCheckout>
              </ActionRow>
            )}
            {isFetchingCard || isFetchingSubscription
              ? <Loader top={0} />
              : (
                <React.Fragment>
                  {savedCardData
                    ? (
                      <Box mb={20}>
                        <DetailsRow>
                          {savedCardData.brand === "Visa" && (
                            <BrandImg src={VisaImg} alt="aaa" />
                          )}
                          <CardDetails>
                            <p>
                              {`····  ····  ···· ${savedCardData.last_four_digits}`}
                            </p>
                            <h6>
                              {`${savedCardData.brand} - Expires ${savedCardData.expiration_month}/${savedCardData.expiration_year}`}
                              {cardDetails.isExpired && (<ExpiredTag>[Expired]</ExpiredTag>)}
                            </h6>
                          </CardDetails>
                        </DetailsRow>
                      </Box>
                    )
                    : (
                      <NoCardWrap>
                        <EmptyStateText>
                          No credit card on file.
                        </EmptyStateText>
                        <StripeCheckout
                          token={this.handleUpdateCard}
                          stripeKey={`${STRIPE_PUBLISH_KEY}`}
                          ComponentClass="div"
                          panelLabel="Update Card"
                        >
                          <Button>
                            Add credit card
                          </Button>
                        </StripeCheckout>
                      </NoCardWrap>
                    )
                  }
                  {/* {nextBillingDate && (
                    <Box mb={20}>
                      <ActionRow>
                        <RowHeader>
                          {isPlanCancelled ? "Subscription Details" : "Upcoming Payments"}
                        </RowHeader>
                      </ActionRow>
                      <DetailsRow>
                        {`${isPlanCancelled ? "End date:" : "Next billing date:"} ${nextBillingDate}`}
                      </DetailsRow>
                    </Box>
                  )} */}
                </React.Fragment>
              )
            }
          </CardSection>

          <CardSection>
            <CardHeader>Billing History</CardHeader>
            {isFetchingInvoices
              ? <Loader top={0} />
              : invoices.length > 0
                ? (invoices.map(invoice => (
                  <Box mb={20} key={invoice.id}>
                    <ActionRow>
                      <RowHeader>
                        {this.getDate(invoice.finalized_at || invoice.created)}
                      </RowHeader>
                      <ActionLink href={invoice.hosted_invoice_url} target="_blank">
                        <img src={DownloadImg} alt="Download Img"/>
                      </ActionLink>
                    </ActionRow>
                    <DetailsRow>
                      {this.getPlanNames(invoice.lines.data)}
                    </DetailsRow>
                    {this.getPlanNames(invoice.lines.data) !== "Retrieving.." && (
                      <Status isSuccess={invoice.paid}>
                        {invoice.paid ? "Paid" : "Failed"}
                      </Status>
                    )}
                    
                  </Box>
                )))
                : <EmptyStateText>No invoices found</EmptyStateText>
            }
            {}
          </CardSection>

        </Wrapper>

        <Modal
          show={updateCardModalState}
          showCloseButton={updateCardModalState !== updateCardModalStates.loading}
          toClose={() => this.handleModalUpdate(false)}
          closeModalOnOutsideClick={false}
          width="562px"
          modalStyles={{
            marginTop: 0,
            padding: 0,
          }}
        >
          <UpdateCardResponseModal>
            {updateCardModalState === updateCardModalStates.success && (
              <React.Fragment>
                <StyledCheckIcon src={CheckIcon} />
                <Box>
                  <Heading>Card Details Updated</Heading>
                </Box>
                <Box mt="60">
                  <Button size="large" onClick={() => this.handleModalUpdate(false)}>
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {updateCardModalState === updateCardModalStates.error && (
              <React.Fragment>
                <StyledCheckIcon src={ErrorIcon} error />
                <Box>
                  <Heading>Card Update Failed</Heading>
                  <Paragraph mt="24">Please try again</Paragraph>

                </Box>
                <Box mt="60">
                  <Button size="large" onClick={() => this.handleModalUpdate(false)}>
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {updateCardModalState === updateCardModalStates.loading && (
              <React.Fragment>
                <div style={{ height: 300 }}>
                  <Loader top={85} bottom={100} />
                  <Box mt="90">
                    <Heading>Updating Card Details...</Heading>
                  </Box>
                </div>
              </React.Fragment>
            )}
          </UpdateCardResponseModal>
        </Modal>
      </OuterWrap>
    )
  }
}

export default Container(Billing)
