import React, { useState, useEffect } from "react"
import colors from "@colors"
import TransactionShimmer from "./StatusShimer"
import {
  CustomSelect,
  StatusSelectContainer,
  StatusIndicator,
} from "./styles"
import {
  OFFER, CONTRACT, LISTING,
} from "../../const"

const SelectStatus = ({
  data,
  optionListStatus,
  loading,
  currentStatus,
  defaultStatus,
  disabledStatuses,
  handleStatusChange,
}) => {
  const getColor = () => {
    if (transactionStatuses) {
      const status = transactionStatuses.find(item => item.value === currentStatus)
      const color = status ? status.colorHex : colors.new_grey400
      return color
    }
    return colors.new_grey400
  }
  const getBGColor = () => {
    if (transactionStatuses) {
      const status = transactionStatuses.find(item => item.value === currentStatus)
      const color = status ? status.backgroundColorHex : colors.new_grey400
      return color
    }
    return "#FEF4E6"
  }
  const [transactionStatuses, setTransactionStatuses] = useState([])

  useEffect(() => {
    if (optionListStatus) {
      const altered_statuses = [...optionListStatus]
      altered_statuses.forEach((item) => {
        if (item.value === "new_offer") {
          item.label = "New Offer"
        } else {
          item.label = item.display_name
        }
        if (item.value === "incomplete") {
          item.disabled = true
        }
      })
      setTransactionStatuses([...altered_statuses])
    }
  }, [optionListStatus])

  return (
    <>
      {
        loading || transactionStatuses?.length == 0 ? (
          <>
            <TransactionShimmer />
          </>
        ) : (
          <>
            {
              (data.record_type == CONTRACT) && (data.is_auto_generated) ? (
                <> - </>
              ) : (
                currentStatus ? (
                  <>
                    <StatusSelectContainer
                      textColor={getColor()}
                      backgroundColor={getBGColor()}
                    >
                      {/* <StatusIndicator bg={getColor()} /> */}
                      <CustomSelect
                        textColor={getColor()}
                        backgroundColor={getBGColor()}
                        value={currentStatus}
                        defaultValue={defaultStatus}
                        onChange={handleStatusChange}
                        //bordered={false}
                        options={transactionStatuses || []}
                        placement="bottomRight"
                        dropdownMatchSelectWidth={false}
                        disabled={disabledStatuses && disabledStatuses.includes(currentStatus)}
                      />
                    </StatusSelectContainer>
                  </>
                ) : (
                  <> - </>
                )
              )
            }

          </>
        )
      }
    </>
  )
}

export default SelectStatus
