import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const TableWrap = styled.div`
  padding: 0 24px;

  tr {
    cursor: pointer;
  }

  .ant-pagination .anticon {
    position: relative;
    top: -3px;
  }

  .ant-pagination-item {
    border: none;
    
    &:hover {
      background: aliceblue;
    }
  }

  th {
    color: var(--Gray-500, #667085);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  th:nth-child(1) {
    color: var(--Gray-500, #667085);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }

  td {
    color: var(--Gray-500, #667085);
    /* Text sm/Normal */
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    vertical-align: middle;
  }

  .ant-table-cell {
    @media(max-width: 1315px) {
      padding: 10px 10px;
    }
  }
`

export const AddressWrap = styled.div``

export const ImageAndAddressWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 0px 2px 2px;
`

export const Address = styled.p`
  margin: 0;
  color: var(--Gray-900, #101828);

  /* Text sm/Medium */
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`
export const SellerName = styled.p`
  margin: 0;
  color: var(--Gray-500, #667085);
  font-family: ${INTER};
  font-size: 11.535px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.302px; /* 150% */
`

export const PropertyPriceLabel = styled.p`
  overflow: hidden;
  color: var(--Color-Gray-400, #41446A);
  text-overflow: ellipsis;

  /* leading-tight/caption/regular */
  font-family: ${INTER};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 13.5px */
  letter-spacing: 0.5px;
  margin: 0;
`

export const PropertyPriceWrap = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: baseline;

  border-radius: 16px;
  background: var(--Primary-50, #F9F5FF);
  gap: 10px;

`

export const PropertyPriceValue = styled.p`
  margin: 0;
  overflow: hidden;
  color: var(--Color-Primary-Primary, #5A5FF2);
  text-overflow: ellipsis;

  /* leading-tight/body-2/semi-bold */
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 16.2px */
  letter-spacing: 0.5px;
`

export const PropertyImage = styled.img`
  height: 38.571px;
  align-self: stretch;
`

export const SellerAndPriceWrap = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: baseline;
  gap: 10px;
`

export const ActionsList = styled.ul``

export const ActionItem = styled.li`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  align-self: normal;


  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${MONTSERRAT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 139px;
    height: 17.4px;
    margin: 0;
  }

  img {
    height: 17.4px;
    width: 17.4px;
  }
  
  &:hover {
    background-color: #f7f7f7;
  }
`
export const Block = styled.div`
display:block`