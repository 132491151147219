import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ShimmerBox } from "shared/styles/animation"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import app from "container/App"
import RoomsContainer from "container/Rooms"
import AgentGrowth from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Indi.png"
import Teams from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Teams.png"
import Brokerage from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Broker.png"
import Tools from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Tools.png"
import Brand from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Brand.png"
import Community from "images/Graphics/RadiusRoomsLandingPageAssets/Topics/Community.png"
import { handleDataLayerPush } from "services/dataLayerUtil"

const Wrap = styled.div`
  @media(min-width: 1100px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const InnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
  gap: 18px;
`

const CategoryCard = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  height: 58px;

  border: 1px solid #BCCBFF;
  background: #F1F3F8;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  gap: 14px;
  align-items: baseline;

  cursor: pointer;

  max-width: 420px;
  padding-right: 3px;

  @media(max-width: 767px) {
    width: 200px;
    max-width: unset;
    padding-right: 0px;
  }
`

const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 18px 22px;
  gap: 10px;

  width: 89.45px;
  height: 56px;

  background: #E2E8FF;
  border-radius: 4px 0px 0px 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  img {
    height: 45px;
    width: 45px;
  }
`

const HeaderWrap = styled.div`
  margin-bottom: 30px;
  h1 {
    font-family: Butler;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: ${colors.black};

    @media(max-width: 767px) {
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.005em;
      text-align: left;
    }

    @media(max-width: 1099px) {
      font-size: 24px;
    }
  }
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.black};

    @media(max-width: 767px) {
      font-family: ${ATC};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`

const Title = styled.h2`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
`

const ImageMap = {
  "Agent Growth" : AgentGrowth,
  "Tools & Services": Tools,
  "Marketing & Brand": Brand,
  "Compliance & Brokerage Support": Brokerage,
  "Lending": Tools,
  "Radius Community": Community,
  "Teams": Teams,
}


const TrendingTopics = ({
  getRoomTopicsResponse,
  size,
  user: userData,
}) => {

  const { data: RoomTopicData, isFetching } = getRoomTopicsResponse || {}

  const dummy = [{}, {}, {}, {}, {}, {}, {}]
  
  return (
    <Wrap className="container">
       <HeaderWrap>
          <h1>
            Topics that Inspire
          </h1>
          <p>
            From wealth building strategies to technology use, Radius Rooms has an event to help you grow professionally.
          </p>
      </HeaderWrap>
      <InnerWrap>
        {isFetching ? (
          <p>Loading</p>
        ) : (
          <>
            {RoomTopicData && RoomTopicData.map(RoomTopic => (
              <CategoryCard
                onClick={() => {
                  handleDataLayerPush(userData, "topics_that_inspired_clicked", RoomTopic.topic_name)
                  let element = document.getElementById(`${RoomTopic.id}_category_id`);
                    element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
                }}
              >
                <ImageWrap>
                  <img src={ImageMap[RoomTopic.topic_name]} alt="rooms_topics_radius" />
                </ImageWrap>
                <Title>
                  {RoomTopic.topic_name}
                </Title>
              </CategoryCard>
            ))}
          </>
        )}
      </InnerWrap>
    </Wrap>
  )
}

export default app(RoomsContainer(TrendingTopics))
