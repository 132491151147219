import styled from "styled-components";
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const CheckListWrapPsuedo = styled.div`
  border-bottom: 1px solid var(--Color-Neutral-100, #f1f1fe);
  p {
    overflow: hidden;
    color: var(--Color-Gray-600, #292a37);
    text-overflow: ellipsis;
    font-family: ${INTER};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: 0.5px;
  }
`;

export const CheckListSelect = styled.div`
display: flex;
height: 45px;
flex-direction: column;
align-items: flex-start;
gap: 6px;
width: 100%;

.ant-select-selector {
    border-radius: 9px !important;
    height: 100% !important;
    border-radius: 8px !important;
    width: 50%: !important
  }
.ant-select {
    border-radius: 8px !important;
    border: 1px solid var(--Color-Primary-Primary, #5A5FF2) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 50%;
    height: 42px !important;
  }
`;

export const CheckListWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const CheckListEmpty = styled.div`
  display: flex;
  height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  align-self: stretch;
  background: var(--Color-Neutral-200, #e5e6ff);
  color: var(--Color-Gray-400, #41446a);
  font-family: ${MONTSERRAT};
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
`;

export const AdditionalText = styled.p`
  color: var(--Color-Gray-400, #41446a);
  font-family: ${MONTSERRAT};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 24.3px */
  margin-top: -40px;
`;
