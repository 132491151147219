import React from "react"
import Container from "container/Settings"
import {
  Wrap,
  ContentWrap,
  LeftWrap,
  LeftImage,
  RightWrap,
  ICAButtons,
} from "dumbComponents/Auth/SignupV2/common"
import { expiredImage, creditCardLinkExpiredIcon } from "../../consts"
import { PageTitle, ExpireWrap } from "../../common"

const Expired = ({
  sendAddCreditCardLinkV2,
}) => {
  return (
    <Wrap>
      <ContentWrap>
        <LeftWrap
          width="50%"
        >
          <LeftImage src={expiredImage} height={300} width={300} top="200px" />
        </LeftWrap>
        <RightWrap
          width="50%"
          // padding="169px 133px 0px 133px"
          // justifyContent="flex-start"
        >
          <ExpireWrap>
            <img src={creditCardLinkExpiredIcon} alt="expired" width={46} height={46} />
            <PageTitle>
              This link has expired. Please contact our Sales team for assistance.
            </PageTitle>
          </ExpireWrap>
          <ICAButtons
            style={{
              width: "250px",
              height: "50px",
            }}
            onClick={() => {
              // const payload = {
              //   agentId: 
              // }
              // sendAddCreditCardLinkV2({ payload })
              console.log("clicked")
            }}
          >
            Request Link Renewal
          </ICAButtons>
        </RightWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default Container(Expired)
