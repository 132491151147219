import styled from "styled-components"
import { INTER } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/addIcon.svg

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;

  border: none;
  background: transparent;
  cursor: pointer;

  ${props => props.disabled && `
    cursor: not-allowed;
  `}

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    // text-transform: capitalise;
    margin: 0;
  }

  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`
