import { createSelector } from "reselect"

export const getGmailEmailFromStore = state => state.app.user.gmail_email
export const getIsFetchingUserFromStore = state => state.app.isFetchingUserObject
export const getGmailRefreshTokenFromStore = state => state.app.user.gmail_refresh_token
export const getPrimaryEmailFromStore = state => state.app.user.primary_email
export const getPhoneFromStore = state => state.app.user.phone
export const getIsCalSyncFromStore = state => state.app.user.is_cal_sync
export const getIsMailSyncFromStore = state => state.app.user.is_mail_sync
export const getIsPbookSyncFromStore = state => state.app.user.is_pbook_sync
export const getUserObjectFromStore = state => state.app.user
export const getDashbaordLinkFromStore = state => state.app.dashboardLinksResponse

export const getIsUserATeamOwner = createSelector(
  [getUserObjectFromStore],
  user => user && user.teamRole === "OWNER"
)

export const getIsUserATeamMember = createSelector(
  [getUserObjectFromStore],
  user => user && user.teamRole === "MEMBER"
)

export const getIsUserATeamAdmin = createSelector(
  [getUserObjectFromStore],
  user => user && user.teamRole === "ADMIN"
)

export const getAgentId = createSelector(
  [getUserObjectFromStore],
  user => user.id
)

export const getIsUserABroker = createSelector(
  [getUserObjectFromStore],
  user => user.isSubscribedToBrokerPlan
)

export const getIsSubscribedToAnyISAPlan = createSelector(
  [getUserObjectFromStore],
  user => (
    user
    && (user.isSubscribedToAnyISAPlan
      || (
        user.teamRole === "OWNER"
        || user.teamRole === "MEMBER"
        || user.teamRole === "ADMIN"
      )
    )
  )
)
