/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect } from "react"
import {
  Form,
} from "@ui/antd"
import moment from "moment"
import {
  InputField,
  InputLocation,
  SelectField,
  InputNumber,
  InputToggle,
  InputDatePicker,
} from "dumbComponents/common/InputFields"
import {
  FormWrap,
  SubmitWrap,
  ValidationNumberCircle,
} from "./commonStyles"
import {
  REPRESENTING,
  CLIENT_FILE_MAP,
  RENT_INTERVAL_TYPE,
  COMMISSION_TYPE,
  TRANSACTION_TYPE,
} from "./formConfig"

const TransactionDetailsForm = ({
  onFinishFailed,
  handleSubmit,
  prefilledData,
  showField,
  showERAFields,
  onValueChange,
  saving,
  dealId,
  disableForm,
  validationReport,
}) => {
  const formRef = useRef(null)
  const [currentRepresent, setRepresent] = useState("")
  const [isRentTypeOther, setRentTypeBool] = useState(false)
  const [commissionType, setCommissionType] = useState("")
  const [transactionType, setTransactionType] = useState("")
  const [hideLenderEmail, setLenderEmailFieldVisibility] = useState(true)

  const getInitialValues = () => {
    // let initValues = {}
    if (prefilledData && dealId) {
      const initValues = { ...prefilledData }
      initValues.closed_on = prefilledData.closed_on ? moment(prefilledData.closed_on * 1000) : null
      initValues.created_at = prefilledData.created_at ? moment(prefilledData.created_at * 1000) : null
      initValues.lease_commencement_on = prefilledData.lease_commencement_on ? moment(prefilledData.lease_commencement_on * 1000) : null
      initValues.is_all_cash_offer = prefilledData.is_all_cash_offer === 1
      // setLenderEmailFieldVisibility(prefilledData.is_all_cash_offer === 0)
      return initValues
    }
    //initValues.phone = normalizePhone(initValues.phone)
    return {}
  }

  useEffect(() => {
    if (prefilledData && prefilledData.is_all_cash_offer >= 0) {
      setLenderEmailFieldVisibility(Boolean(prefilledData.is_all_cash_offer))
    }
    if (prefilledData && prefilledData.commission_type) {
      const commissionMode = prefilledData.commission_type
      setCommissionType(commissionMode)
    }

    if (prefilledData && prefilledData.other_rent_amount_term) {
      setRentTypeBool(true)
    }
  }, [prefilledData])

  return (
    <FormWrap>
      <Form
        name="transaction_details_form"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        onKeyDown={(e) => { e.stopPropagation() }}
        onValuesChange={onValueChange}
        ref={formRef}
        disabled={disableForm}
      >

        {/**PRICE TYPE */}
        {(showField("price") && showERAFields("price")) && (
          <InputNumber
            name="price"
            inputType="money"
            type="money"
            placeholder="Sales Price"
            text="Sales Price"
            required={true}
            requiredMessage="Sales Price is required."
          />
        )}

        {(showField("list_price") || showERAFields("list_price")) && (
          <InputNumber
            name="list_price"
            inputType="money"
            type="money"
            placeholder="List Price"
            text="List Price"
            required={true}
            requiredMessage="List Price is required."
          />
        )}
        {(showField("rent_amount") || showERAFields("rent_amount")) && (
          <InputNumber
            name="rent_amount"
            inputType="money"
            type="money"
            placeholder="Rent Amount"
            text="Rent Amount"
            required={true}
            requiredMessage="Rent Amount is required."
          />
        )}

        {(showField("rent_amount_term") || showERAFields("rent_amount_term")) && (
          <SelectField
            isFormField
            name="rent_amount_term"
            options={RENT_INTERVAL_TYPE}
            defaultValue="Select"
            placeholder="What is your rent term basis?"
            required={true}
            text="What is your rent term basis?"
            onChange={(value) => {
              setRentTypeBool(value === "other")
            }}
            requiredMessage="Rent Term is required."
          />
        )}

        {showField("rent_amount_term") && isRentTypeOther && (
          <InputField
            name="other_rent_amount_term"
            inputType="text"
            placeholder="Other Rent Term"
            text="Other Rent Term"
            required={true}
            requiredMessage="Other Rent Term is required."
          />
        )}

        {showField("commission_type") && (
          <SelectField
            isFormField
            name="commission_type"
            options={COMMISSION_TYPE}
            defaultValue="Select"
            placeholder="What type of commission are you receiving?"
            text="What type of commission are you receiving?"
            required={true}
            onChange={(value) => {
              setCommissionType(value)
            }}
            requiredMessage="Commission Type is required."
          />
        )}

        {["percentage", "both"].includes(commissionType) && (
          <InputNumber
            name="commision_percentage"
            inputType="percentage"
            type="percentage"
            placeholder="What is your commission percentage?"
            text="What is your commission percentage?"
            required={true}
            requiredMessage="Commission percentage is required."
          />
        )}

        {["fixed", "both"].includes(commissionType) && (
          <InputNumber
            name="fixed_fee"
            inputType="money"
            type="money"
            placeholder="What is your fixed fee?"
            text="What is your fixed fee?"
            required={true}
            requiredMessage="Fixed fee is required."
          />
        )}

        {showField("team_split_percentage") && (
          <InputNumber
            name="team_split_percentage"
            inputType="percentage"
            type="percentage"
            placeholder="What is your split with your team?"
            text="What is your split with your team?"
            required={true}
            requiredMessage="Team Split Percentage is required."
          />
        )}

        {showField("created_at") && (
          <InputDatePicker
            name="created_at"
            datesDisabledFlag="after"
            text="Acceptance date?"
            required={true}
            requiredMessage="Acceptance Date is required."
          />
        )}
        {showField("closed_on") && (
          <InputDatePicker
            name="closed_on"
            datesDisabledFlag="before"
            text="Estimated Close of Escrow"
            required={true}
            requiredMessage="Estimated Close of Escrow is required."
          />
        )}
        {showField("lease_commencement_on") && (
          <InputDatePicker
            name="lease_commencement_on"
            text="Lease Commencement Date"
            required={true}
            requiredMessage="Lease Commencement Date is required."
          />
        )}

        <SelectField
          isFormField
          name="transaction_type"
          options={TRANSACTION_TYPE}
          defaultValue="Select"
          placeholder="Transaction Type"
          text="Transaction Type"
          required={true}
          onChange={(value) => {
            setTransactionType(value)
          }}
          requiredMessage="Transaction Type is required."
        />

        {transactionType === "other" && (
          <InputField
            name="other_transaction_type"
            inputType="text"
            placeholder="Other Transaction Term"
            text="Other Transaction Term"
            required={true}
            requiredMessage="Other Transaction Term is required."
          />
        )}

        <InputToggle
          name="is_all_cash_offer"
          text="Is this Transaction an all cash offer?"
          required={true}
          checked={hideLenderEmail}
          onChange={(value) => {
            setLenderEmailFieldVisibility(value)
          }}
        />

        {!hideLenderEmail && (
          <InputField
            name="lender_email_address"
            inputType="text"
            placeholder="Lender Email"
            text="Lender Email"
            required={true}
            requiredMessage="Lender Email is required."
          />
        )}

        {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}
      </Form>
    </FormWrap>
  )
}

export default TransactionDetailsForm
