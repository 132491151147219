import React, { useEffect, useState } from "react"
import {
  Form,
} from "@ui/antd"
import _ from "lodash"
import {
  InputField,
  InputNumber,
  SelectField,
} from "dumbComponents/common/InputFields"
import {
  SELLER_TYPE,
} from "dumbComponents/OffersV2/consts"
import { AddButton } from "dumbComponents/OffersV3/components"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
  CoWrap,
} from "./commonStyles"
// import {
//   InputField,
//   InputNumber,
//   SelectField,
// } from "../../InputFields"

//fix shrungi - seller info addition

const { List } = Form

const SellerForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  handleSellersChange,
  disabled,
  key,
  form,
}) => {

  const [typeOfOwnership, setTypeOfOwnership] = useState("individual")
  useEffect(() => {
    if (prefilledData && prefilledData.length > 0) {
      setTypeOfOwnership(prefilledData[0].type_of_ownership)
    }
  }, [prefilledData])
  const [coSellersOwnership, setCoSellersOwnership] = useState({})
  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData && prefilledData.length > 0) {
      initValues = {
        ...prefilledData[0],
      }
      console.log("initValue Seller",initValues)
      
      //initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        const sliced = prefilledData.slice(1)
        initValues.co_sellers = sliced
      }
    }

    return initValues
  }


  const onCoSellerOwnershipChange = (value, index) => {
    setCoSellersOwnership(currentValues => ({
      ...currentValues,
      [index]: value,
    }))
  }

  // useEffect(() => {
  //   if (prefilledData && prefilledData.length > 0) {
  //     const primarySellerOwnershipType = prefilledData[0].ownership_name
  //     const sliced = prefilledData.slice(1)
  //     setTypeOfOwnership(primarySellerOwnershipType)
  //     //let temp = {}
  //     // sliced.forEach((item, iter) => {
  //     //   return {
  //     //     [iter]
  //     //   }
  //     // })
  //   }
  // }, [prefilledData])

  const handleTypeOfOwnership = (value) => {
    console.log(value, "here")
    setTypeOfOwnership(value)
    console.log("here", typeOfOwnership)
  }

  const getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "successor_trust") {
      return "Full Name of Trust"
    }
    if (typeOfOwnership === "entity") {
      return "Full Name of Entity"
    }
    if (typeOfOwnership === "probate") {
      return "Full Name of Executer"
    }
    if (typeOfOwnership === "estate") {
      return "Full Name of Estate"
    }
    if (typeOfOwnership === "power_of_attorney") {
      return "Full Name of Attorney-in-fact"
    }
    return "Full Name"
  }
  return (
    <FormWrap
      id={`${key}_wrap`}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Seller Form"}</p>
        </FormTitle>
        {/* <CancelOrGoBackButton
          onClick={() => {
            const id = prefilledData ? prefilledData.seller_id : ""
            handleEditModeChange(id)
          }}
        >
          <img src={`${CLOUDFRONT}/OFFERS_V3/Offers_v3_asset_icon.svg`} alt="minus" style={{marginTop:'1px' }} />
        </CancelOrGoBackButton> */}
      </HeaderWrap>

      <Form
        id={`${key}_form`}
        name="offers_sellers_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        onKeyDown={(e) => { e.stopPropagation() }}
        onValuesChange={handleSellersChange}
        disabled={disabled}
        form={form}
      >
        <InputField
          name="firstname"
          inputType="text"
          placeholder="First Name"
          text="First Name"
          // required={true}
        />

        <InputField
          name="lastname"
          inputType="text"
          placeholder="Last Name"
          text="Last Name"
        />

        <SelectField
          isFormField={true}
          name="type_of_ownership"
          options={SELLER_TYPE}
          defaultValue={prefilledData ? prefilledData.type_of_ownership : null}
          placeholder="Type of Ownership"
          text="Type of Ownership"
          // required="true"
          // requiredMessage="Required"
          onChange={handleTypeOfOwnership}
        />
        {typeOfOwnership !== "individual" && (
          <InputField
            name="ownership_name"
            inputType="text"
            placeholder={getFieldName(typeOfOwnership)}
            text={getFieldName(typeOfOwnership)}
            // required="true"
            // requiredMessage="Required"
          />
        )}

        <CTAWrap>
          <List
            name="co_sellers"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }) => (
                  <CoWrap>
                    <HeaderWrap>
                      <FormTitle>
                        <p>Additional Seller Information</p>
                      </FormTitle>
                      <CancelOrGoBackButton
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation()
                          console.log("CLCIKED?")
                          remove(name)
                          setCoSellersOwnership({})
                        }}
                      >
                        <img src={`${CLOUDFRONT}/OFFERS_V3/Offers_v3_asset_icon.svg`} alt="minus" style={{marginTop:'1px' }} />
                        <p> Remove </p>
                      </CancelOrGoBackButton>
                    </HeaderWrap>
                    <FormFieldWrap>
                      <InputField
                        name={[name, "firstname"]}
                        inputType="text"
                        placeholder="First Name"
                        text="First Name"
                      />

                      <InputField
                        name={[name, "lastname"]}
                        inputType="text"
                        placeholder="Last Name"
                        text="Last Name"
                      />
                    </FormFieldWrap>
                    <FormFieldWrap>
                      <SelectField
                        isFormField={true}
                        name={[name, "type_of_ownership"]}
                        options={SELLER_TYPE}
                        defaultValue={prefilledData ? prefilledData.type_of_ownership : "Select"}
                        placeholder="Type of Ownership"
                        text="Type of Ownership"
                        onChange={value => onCoSellerOwnershipChange(value, name)}
                      />

                      {
                        !_.isEmpty(coSellersOwnership)
                        && coSellersOwnership[name] !== "individual"
                        && (
                          <InputField
                            name={[name, "ownership_name"]}
                            inputType="text"
                            placeholder={getFieldName(coSellersOwnership[name])}
                            text={getFieldName(coSellersOwnership[name])}
                          />
                        )
                      }
                    </FormFieldWrap>
                  </CoWrap>
                ))}
                {/* <FormFieldWrap> */}
                <CTAWrap>
                  <Form.Item>
                    <AddButton
                      name="ADD SELLER"
                      disabled={prefilledData.length <= 0}
                      //name={listingAgents.length > 0 ? "ADD ADDITIONAL LISTING AGENT" : "ADD LISTING AGENT"}
                      onClick={(e) => {
                        e.stopPropagation()
                        // if (prefilledData.length <= 0) return
                        console.log("CLCIKED?")
                        add()
                      }}
                    />
                  </Form.Item>
                </CTAWrap>
                {/* </FormFieldWrap> */}
              </>
            )}
          </List>

          <Form.Item>
            {/* <BlueButton
              type="submit"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              <p>Save</p>
            </BlueButton> */}
          </Form.Item>
        </CTAWrap>

      </Form>
    </FormWrap>
  )
}

export default SellerForm
