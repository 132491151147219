import React, { useEffect, useState } from "react";
import ListingsV2 from "container/ListingsV2";
import Listings from "container/Listings";
import TechAssets from "container/TechAssets";
import { FloatingCTAEnvelopes } from "dumbComponents/common/InputFields";
import { Envelopes, FillAndSign } from "./Components";
import CreateEnvelopeModal from "../CreateEnvelopeModal";
import { WrapContainer } from "./styles";
import { Drawer } from "@ui/antd";
import { GoogleOutlined } from "@ant-design/icons";
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons";
import SendOffer from "../Envelopes/SendOffer";
import VoidAndRestart from "dumbComponents/ListingsV2/Components/Envelopes/components/VoidAndRestart"
import VoidAndDelete from "dumbComponents/ListingsV2/Components/Envelopes/components/VoidAndDelete"
import {getBrokerageType} from "dumbComponents/Compliance/utils"
import { withRouter } from "react-router-dom"
import OffersV2 from "container/OffersV2"
import { OFFERS, LISTINGS, PURCHASE } from "dumbComponents/OffersV3/consts"
import PubNubForListingsV2 from "../PubNubForListingsV2"

const RecommendedDocs = ({
  getOfferStatus,
  fetchEnvelopeListings,
  dealToView,
  envelopeListings,
  //shrungi
  toggleCreateEnvelope,
  isCreateEnvelopeOpen,
  listingIdToView,
  mergedEnvelopes,
  fetchListingPackageDocuments,
  fetchListingPackageDocumentsResponse,
  setViewListingSendUI,
  viewListingSendUI,
  currentDocumentTab,
  history,
  history: {
    location,
  },
  offerIdToView,
  setEnvelopeId,
  toggleListingsDocumentProcessing
}) => {
    const [openLoader, setOpenLoader] = useState(false)
  const currentBrokerageType = getBrokerageType(location)
  useEffect(() => {
    setEnvelopeId(null),
    toggleListingsDocumentProcessing(false)
    if(currentBrokerageType === OFFERS){
      fetchEnvelopeListings({
        type: currentBrokerageType,
        offerId: (offerIdToView || dealToView),
      });
    } else {
      fetchEnvelopeListings({
        type: currentBrokerageType,
        listingId: listingIdToView,
      });
    }
  }, []);
  const { isFetching: fetchingPackageDocuments } =
    fetchListingPackageDocumentsResponse || {};
  const multipleShare = mergedEnvelopes && mergedEnvelopes.length > 0 && mergedEnvelopes.filter(doc => doc.isSelected)
  const totalCompletedEnvelopes = mergedEnvelopes && mergedEnvelopes.length > 0 && mergedEnvelopes.filter(doc => doc.status =="completed") 
  console.log("totalCompletedEnvelopes",totalCompletedEnvelopes)
  let selectedEnvelopeCount = `Send Envelopes (${multipleShare.length}/${totalCompletedEnvelopes.length})`
  const handleCreateEnvelopes = () => {
        setOpenLoader(true);

    let envelopeIds = [];
    const currDocs = [...mergedEnvelopes];

    currDocs.forEach((item) => {
      if (item.isSelected) {
        envelopeIds.push(item.envelope_id);
      }
    });
    const payload = {
      envelopeIds,
    };
    if(currentBrokerageType == OFFERS){
      fetchListingPackageDocuments({
        type:currentBrokerageType,
        offerId: (offerIdToView || dealToView),
        payload,
        callback: () => {
          setOpenLoader(false);
          setViewListingSendUI(true);
        },
      });
    } else {
      fetchListingPackageDocuments({
        type:currentBrokerageType,
        listingId: listingIdToView,
        payload,
        callback: () => {
          setOpenLoader(false);
          setViewListingSendUI(true);
        },
      });
    }
  };

  return (
    <>
      <WrapContainer>
      {currentBrokerageType == OFFERS ? (
        <PubNubForListingsV2
        id={offerIdToView || dealToView}
      />
      ) : (
        <PubNubForListingsV2
        id={listingIdToView}
      />
      )
        }
        <Envelopes listingIdToView={listingIdToView} offerIdToView={offerIdToView || dealToView}/>
        <FillAndSign dealToView={offerIdToView || dealToView}/>
      </WrapContainer>
      {isCreateEnvelopeOpen && (
        <CreateEnvelopeModal
          currentBrokerageType={currentBrokerageType}
          toClose={() => {
            toggleCreateEnvelope(false);
          }}
        />
      )}
      {!(multipleShare.length === 0) && (multipleShare) && (
        <FloatingCTAEnvelopes
          customText={selectedEnvelopeCount}
          disabled={fetchingPackageDocuments}
          onClick={handleCreateEnvelopes}
          loading={fetchingPackageDocuments}
        />
      )}

      {viewListingSendUI && (
        <Drawer
          width="600px"
          style={{overflow: "hidden"}}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <GoogleOutlined
                style={{ fontSize: "24px", marginRight: "10px" }}
              />
              <span style={{ marginRight: "10px" }}>POWERED BY GMAIL</span>
            </div>
          }
          placement="right"
          onClose={() => setViewListingSendUI(false)}
          open={viewListingSendUI}
          type="Basic Drawer"
          CloseIconRight
          hideDrawerTitle={false}
          // fontSize={titleFontSize}
          closeIcon={<CloseIcon />}
          maskClosable={false}
        >
          <SendOffer
           goBack={() => setViewListingSendUI(false)}
           currentBrokerageType={currentBrokerageType} />
        </Drawer>
      )}
      <VoidAndRestart currentBrokerageType={currentBrokerageType}/>
      <VoidAndDelete currentBrokerageType={currentBrokerageType}/>
    </>
  );
};

export default withRouter(OffersV2(Listings(ListingsV2(TechAssets(RecommendedDocs)))));
