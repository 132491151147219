import React from "react"
import moment from "moment"
import {
  TransparentButton,
} from "dumbComponents/OffersV2/commonStyles"
import { EyeIcon } from "dumbComponents/OffersV2/icons"
import {
  Wrap,
  HeaderWrap,
  StyledStatus,
  StatusTitle,
  DocWrap,
  DocTitle,
  FooterWrap,
  DateCreatedContent,
  DocContent,
  DateContent,
  DateContentWrap,
  OfferPackageTitle,
} from "./style"
import ResponseTag from "./ResponseTag"

const OfferPackageCard = ({
  docs,
  responseCount,
  title,
  date,
}) => (
  <Wrap>
    <HeaderWrap>
      <OfferPackageTitle>
        <p>{title}</p>
      </OfferPackageTitle>
      {/* {responseCount && responseCount > 0 && (
        <ResponseTag
          responseCount={responseCount}
        />
      )} */}
    </HeaderWrap>
    <DocContent>
      {/* {docs && docs.map(doc => (
        <DocWrap>
          <DocTitle>
            {doc.name}
          </DocTitle>
        </DocWrap>
      ))} */}
    </DocContent>
    <FooterWrap>
      <DateContentWrap>
        <DateCreatedContent>
          Date created
        </DateCreatedContent>
        <DateContent>
          {moment.unix(date).format("DD/MM/YYYY")}
        </DateContent>
      </DateContentWrap>
      <TransparentButton onClick={() => {}}>
        <EyeIcon />
        <p>VIEW</p>
      </TransparentButton>
    </FooterWrap>
  </Wrap>
)

export default OfferPackageCard
