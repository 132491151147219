/* eslint-disable max-len */
import React, { PureComponent } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import LoaderButton from "@ui/LoaderButton"
import root from "window-or-global"
import { get } from "lodash"
import colors from "@colors"
import BrokerJobsContainer from "container/BrokerJobs"
import { ATC } from "@fonts"
import { maxLargeMobile } from "@consts"
import Footer from "dumbComponents/common/FooterNew"
import cookie from "services/CookieStorage"
import JobTitleSection from "dumbComponents/RealtyV3/RARRecruitment/components/PreviewJobLandingComponents/JobTitleSection"
import AboutTeamSection from "dumbComponents/RealtyV3/RARRecruitment/components/PreviewJobLandingComponents/AboutTeamSection"
import Overview from "dumbComponents/RealtyV3/RARRecruitment/components/PreviewJobLandingComponents/Overview"
import Requirements from "dumbComponents/RealtyV3/RARRecruitment/components/PreviewJobLandingComponents/Requirements"
import CommissionSplit from "dumbComponents/RealtyV3/RARRecruitment/components/PreviewJobLandingComponents/CommissionSplit"

const Wrap = styled.div`
  padding: 0 90px;
  margin-top: 30px;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  ${props => props.pd && `
    padding: ${props.pd};
  `}
  .form-group {
    padding: 5px;
    > span {
      width: 100%;
    }
  }
  .form-group:focus-within {
    border: 1px solid ${colors.eggYolkWhite};
  }
  ${maxLargeMobile(`
    padding: 0px;
  `)}

  ${props => props.noPadding && `
    padding: 0px;
  `}

  @media(min-width: 1024px) {
    width: 100%;
  }
`
const FooterWrap = styled.div`
  .container {
    margin: 0;
  }
`
const InnerContainer = styled.div`
  ${props => props.isLandingPage && props.haveBorder && `
    border: 1px solid ${colors.fungiWhite};
  `}
`

const TeamImage = styled.img`
  height: 100%;
  width: 250px;
`

const PublishTopBar = styled.div`
  display: flex;
  background: #303030;
  padding: 13px 34px;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
`
const PublicText = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${colors.dreamWhite};
  margin: 0;
`

const Content = styled.div`
  padding: 0 22px 22px;

  ${props => props.noPadding && `
    padding-left: 0px;
  `}
`

const Section = styled.div`
  margin-top: 30px;
`

const ButtonWrap = styled.div`
  padding: 50px 0;
  text-align: center;
`

class RadiusTeamJobsPreview extends PureComponent {
  state = {
    jobTitle: "",
    overview: "",
    requirements: "",
    commissionSplit: "",
    jobTitleCopy: "",
    overviewCopy: "",
    requirementsCopy: "",
    commissionSplitCopy: "",
    jobTitleEdit: false,
    overviewEdit: false,
    requirementsEdit: false,
    commissionSplitEdit: false,
    locationDetails: [],
    locationDetailsCopy: [],
    about_team: "",
    team_name: "",
  }

  componentDidMount() {
    const { pageContentFromProps } = this.props
    const content = pageContentFromProps || JSON.parse(root.localStorage.getItem("brokerage_jobs_storage"))
    this.setState({
      jobId: content.jobId || content.job_id || content.id,
      jobSlug: content.jobSlug || content.job_slug,
      jobTitle: content.job_title,
      overview: content.job_description_and_responsibilities,
      requirements: content.requirements,
      commissionSplit: content.commission_split,
      locationDetails: content.location_details,
      jobTitleCopy: content.job_title,
      overviewCopy: content.job_description_and_responsibilities,
      requirementsCopy: content.requirements,
      commissionSplitCopy: content.commission_split,
      locationDetailsCopy: content.location_details,
      workplace_type: content.workplace_type,
      team_name: content.team_name,
      about_team: content.about_team,
    })
  }

  componentDidUpdate(prevProps) {
    const { selectedJobId: selectedJobIdPrevious } = prevProps
    const { selectedJobId: selectedJobIdNew, pageContentFromProps } = this.props
    if (selectedJobIdNew !== selectedJobIdPrevious) {
      const content = pageContentFromProps
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        jobId: content.jobId || content.id || content.job_id,
        jobSlug: content.jobSlug || content.job_slug,
        jobTitle: content.job_title,
        overview: content.job_description_and_responsibilities,
        requirements: content.requirements,
        commissionSplit: content.commission_split,
        locationDetails: content.location_details,
        jobTitleCopy: content.job_title,
        overviewCopy: content.job_description_and_responsibilities,
        requirementsCopy: content.requirements,
        commissionSplitCopy: content.commission_split,
        locationDetailsCopy: content.location_details,
        workplace_type: content.workplace_type,
        team_name: content.team_name,
        about_team: content.about_team,
      })
    }
  }

  updateField = (data, property) => {
    this.setState({
      [`${property}Copy`]: data.content,
    })
  }

  enableEdit = (property, value) => {
    const { [property]: propertyValue, [`${property}Copy`]: propertyCopyValue } = this.state
    this.setState({
      [`${property}Edit`]: value,
    })
    if (value) {
      this.setState({
        [property]: propertyCopyValue,
      })
    } else {
      this.setState({
        [`${property}Copy`]: "",
      })
      setTimeout(() => {
        this.setState({
          [`${property}Copy`]: propertyValue,
        })
      }, 500)
    }
  }

  saveField = (property) => {
    const { [`${property}Copy`]: value } = this.state
    this.setState({
      [property]: value,
      [`${property}Edit`]: false,
    })
  }

  postJob = () => {
    const { createJob, updateJob, history } = this.props
    const {
      jobId, jobTitle, workplace_type, overview, commissionSplit, requirements, locationDetailsCopy,
      jobSlug,
    } = this.state
    const location_details = Object.keys(locationDetailsCopy).map(lc => (locationDetailsCopy[lc]))
    const body = {
      job_title: jobTitle,
      workplace_type,
      job_description_and_responsibilities: overview,
      status: "completed",
      requirements,
      commission_split: commissionSplit,
      location_details,
      team_id: parseInt(cookie.load("brokerageTeamId"), 10),
    }
    if (window.opener) window.opener.postMessage({ jobPosted: true })
    root.localStorage.setItem("brokerage_jobs_storage", JSON.stringify({ ...body, jobSlug, jobId }))
    if (jobId) {
      return updateJob({
        jobId,
        body,
        history,
        jobSlug,
      })
    }
    return createJob({ body, history, jobSlug })
  }

  addCityForm = () => {
    const { locationDetailsCopy } = this.state
    locationDetailsCopy.push({
      city: "",
      state: "",
      location: "",
    })
    this.setState({
      locationDetailsCopy: [...locationDetailsCopy],
    })
  }

  removeCityForm = (index) => {
    const { locationDetailsCopy } = this.state
    const locationDetails = locationDetailsCopy.filter((ld, id) => id !== index)
    this.setState({
      locationDetailsCopy: [...locationDetails],
    })
    this.forceUpdate()
  }

  saveJobAndLocation = () => {
    const { jobTitleCopy, locationDetailsCopy } = this.state

    this.setState({
      jobTitle: jobTitleCopy,
      locationDetails: locationDetailsCopy.filter(ld => !!ld.city),
      jobTitleEdit: false,
    })
  }

  handleCity = (location, index) => {
    const { locationDetailsCopy } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    locationDetailsCopy[index] = {
      city: locationDetails.city,
      state: locationDetails.state,
      location: `${locationDetails.city}, ${locationDetails.stateShortName}`,
    }
    this.setState({
      locationDetailsCopy: [...locationDetailsCopy],
    })
  }

  getLocation = (index) => {
    const { locationDetailsCopy } = this.state
    return locationDetailsCopy && locationDetailsCopy.length && locationDetailsCopy[index] ? locationDetailsCopy[index].location : ""
  }

  render() {
    const {
      jobTitle, overview, jobTitleEdit, overviewEdit, requirementsEdit, commissionSplitEdit,
      jobTitleCopy, overviewCopy, requirementsCopy, commissionSplitCopy, locationDetails,
      locationDetailsCopy, team_name, about_team,
    } = this.state

    const {
      isLandingPage, editable = true, mt, pd,
      brokerageCreateJobs, brokerageUpdateJobs, enableTeamProfile,
      isIndividualJobPage,
      noPadding,
      hideTeamLink,
      pageContentFromProps,
    } = this.props

    return (
      <Wrap mt={mt} pd={pd} noPadding={noPadding}>
        <InnerContainer isLandingPage={isLandingPage}>
          {!isLandingPage && (
            <PublishTopBar>
              <PublicText>
                This is only a preview of your job listing. Publish in order to start receiving applications.
              </PublicText>
              <LoaderButton
                bsStyle="blackAndWhite"
                fontSize="14px"
                fontWeight="500"
                fontColor="#303030"
                padding="8px 14px"
                width="80px"
                borderRadius="0px"
                color="black"
                isShowLoader={brokerageUpdateJobs.isFetching || brokerageCreateJobs.isFetching}
                onClick={this.postJob}
              >
                Publish
              </LoaderButton>
            </PublishTopBar>
          )}
          <JobTitleSection
            isIndividualJobPage={isIndividualJobPage}
            jobTitleEdit={jobTitleEdit}
            editable={editable}
            jobTitle={isLandingPage && pageContentFromProps ? pageContentFromProps.job_title : jobTitle}
            jobTitleCopy={isLandingPage && pageContentFromProps ? pageContentFromProps.job_title : jobTitleCopy}
            updateField={this.updateField}
            isLandingPage={isLandingPage}
            enableEdit={this.enableEdit}
            locationDetailsCopy={isLandingPage && pageContentFromProps ? pageContentFromProps.location_details : locationDetailsCopy}
            handleCity={this.handleCity}
            addCityForm={this.addCityForm}
            locationDetails={isLandingPage && pageContentFromProps ? pageContentFromProps.location_details : locationDetails}
            saveJobAndLocation={this.saveJobAndLocation}
            removeCityForm={this.removeCityForm}
            getLocation={this.getLocation}
            noPadding={noPadding}
          />
          <Content noPadding={noPadding}>
            <Section>
              <AboutTeamSection
                isIndividualJobPage={isIndividualJobPage}
                header={`About ${isLandingPage && pageContentFromProps ? pageContentFromProps.team_name : team_name}`}
                paragraph={isLandingPage && pageContentFromProps ? pageContentFromProps.about_team : about_team}
                isLandingPage={isLandingPage}
                enableTeamProfile={enableTeamProfile}
                hideTeamLink={hideTeamLink}
              />
            </Section>
            <Section>
              <Overview
                isLandingPage={isLandingPage}
                header="Position Overview"
                overviewEdit={overviewEdit}
                editable={editable}
                enableEdit={this.enableEdit}
                overviewCopy={isLandingPage && pageContentFromProps ? pageContentFromProps.job_description_and_responsibilities : overviewCopy}
                overview={isLandingPage && pageContentFromProps ? pageContentFromProps.job_description_and_responsibilities : overview}
                updateField={this.updateField}
                saveField={this.saveField}
              />
            </Section>
            <Section>
              <Requirements
                isLandingPage={isLandingPage}
                header="Requirements"
                requirementsEdit={requirementsEdit}
                editable={editable}
                enableEdit={this.enableEdit}
                requirementsCopy={isLandingPage && pageContentFromProps ? pageContentFromProps.requirements : requirementsCopy}
                updateField={this.updateField}
                saveField={this.saveField}
              />
            </Section>
            <Section>
              <CommissionSplit
                isLandingPage={isLandingPage}
                header="Commission Split"
                commissionSplitEdit={commissionSplitEdit}
                editable={editable}
                enableEdit={this.enableEdit}
                commissionSplitCopy={isLandingPage && pageContentFromProps ? pageContentFromProps.commission_split : commissionSplitCopy}
                updateField={this.updateField}
                saveField={this.saveField}
              />
            </Section>
          </Content>
          {!isLandingPage && (
            <ButtonWrap>
              <LoaderButton
                bsStyle="marineBLueFill"
                fontSize="16px"
                fontWeight="500"
                fontColor="#303030"
                padding="8px 14px"
                borderRadius="0px"
                width="200px"
                isShowLoader={brokerageUpdateJobs.isFetching || brokerageCreateJobs.isFetching}
                onClick={() => {
                  this.postJob()
                  if (root.heap) {
                    root.heap.track("Clicked_Publish_Job_Recruitment")
                  }
                }}
              >
                Publish job posting
              </LoaderButton>
            </ButtonWrap>
          )}
        </InnerContainer>
        {/* {!hideFooter && (<FooterWrap><Footer hideRarForm="true" /></FooterWrap>)} */}
      </Wrap>
    )
  }
}

export default BrokerJobsContainer(withRouter(RadiusTeamJobsPreview))
