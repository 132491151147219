import React from "react"

const MobileViewLegal = () => {
  return (
    <div className="above-the-fold">
      <div className="div">
        <div className="overlap">
          <div className="disclosures">Disclosures &amp; Licensing</div>
          <div className="group">
            <div className="overlap-group">
              <div className="text-wrapper">UPDATED DATE : 9/25/23</div>
            </div>
          </div>
        </div>
        <p className="p">
          Radius Agent is a licensed real estate broker headquartered in the below address and licensed to do business
          in the below states. Radius is an Equal Opportunity Employer and supports the Fair Housing Act.
        </p>
        {/* <img
          className="version-default"
          alt="Version default"
          src="https://c.animaapp.com/6NbPTvr2/img/version-default-1@2x.png"
        /> */}
        {/* <FiRrMenuBurger1 className="fi-rr-menu-burger" color="#5A5FF2" /> */}
        <div className="text-wrapper-2">Radius Agent - Headquarters</div>
        <p className="element-battery-street">
          1160 Battery Street East
          <br />
          San Francisco CA 94111
          <br />
          415-649-0689
        </p>
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <div className="rectangle-wrapper">
              <div className="rectangle" />
            </div>
            <p className="CA-DRE-CO">
              <span className="span">CA DRE #</span>
              <a
                href="https://www2.dre.ca.gov/PublicASP/pplinfo.asp?License_id=02051216"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  {" "}
                  02051216
                  <br />
                </span>
              </a>
              <span className="span">CO DRE #</span>
              <a
                href="https://apps.colorado.gov/dre/licensing/Lookup/LicenseLookup.aspx"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  {" "}
                  100106082
                  <br />
                </span>
              </a>
              <span className="span">FL DBPR</span>
              <span className="text-wrapper-4"> # </span>
              <a
                href="https://www.myfloridalicense.com/LicenseDetail.asp?SID=&amp;id=99C1AB64E775B899B93231B8C4F3D561"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  CQ1068468
                  <br />
                </span>
              </a>
              <span className="span">GA REC</span>
              <span className="text-wrapper-4"> #</span>
              <a href="https://ata.grec.state.ga.us/Account/Search" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-3">
                  {" "}
                  79559
                  <br />
                </span>
              </a>
              <span className="span">OR REA</span>
              <span className="text-wrapper-4"> # </span>
              <a
                href="https://orea.elicense.micropact.com/Lookup/LicenseLookup.aspx"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  201252778
                  <br />
                </span>
              </a>
              <span className="span">TX TREC</span>
              <span className="text-wrapper-4"> #</span>
              <a
                href="https://www.trec.texas.gov/apps/license-holder-search/?detail_id=1000259976"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3"> 9011839</span>
              </a>
              <span className="text-wrapper-4">
                {" "}
                <br />
              </span>
              <span className="span">WA DOL </span>
              <span className="text-wrapper-4"># </span>
              <a href="https://professions.dol.wa.gov/s/license-lookup" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-3">
                  22017298
                  <br />
                </span>
              </a>
              <a
                href="https://drive.google.com/file/d/1SJS_ymPzUpf4s-0S96zvijlPa5JTltb5/view?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">Consumer Notice</span>
              </a>
              <span className="text-wrapper-4">
                {" "}
                <br />
              </span>
              <a
                href="https://drive.google.com/file/d/1Bv6vOOncejKZUpjcLN2g4X0C85emDizR/view?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  Information About Brokerage Services
                  <br />
                </span>
              </a>
              <a
                href="https://docs.google.com/document/d/1QLtUWeRvQa8RfxlPJ6ZKVux62DYtIyp3iv_3osHwLxU/edit"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="text-wrapper-3">
                  Affiliated Business Arrangement Disclosure
                  <br />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileViewLegal
