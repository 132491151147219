import styled from "styled-components"
import { INTER } from "@fonts"

//https://s3.amazonaws.com/cdn.agentdesks.com/images/OFFERS_V2/addIcon.svg

export const Button = styled.button`
  gap: 10px;

  width: 518px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F1FE;
  border: 1px solid #A5A8FF;
  border-radius: 9px;
  cursor: pointer;
  height: ${props => props.height};
  width: ${props => props.width};

  p {
    color: var(--Color-Primary-Primary, #5A5FF2);
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    text-transform: uppercase;
    margin: 0;
  }

  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  ${props => props.disabled && `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`
