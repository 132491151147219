import React from "react"
import root from "window-or-global"
import styled from "styled-components"
import { isBrowser } from "services/Utils"
import ReferralProgram from "./ReferralPromo"

const Wrap = styled.div`
  h1 {
    font-size: 44px;
    font-family: Carrera;
  }
`

const POPUP_TYPES = {
  SHARE_THE_LOVE: "shareTheLove",
}

// Never shows pops ups on this route, if you want to show it anyways,
// then please don't add it to this components. this is only for global popups
const DISABLED_ROUTES = [
  "/community/onboarding/welcome",
  // "/radius-assist",
  "/referrals/isa",
  "/isa-program",
  "/icons",
]

// Only render this on client side.
const Popups = (props) => {
  if (!isBrowser()) {
    return null
  }
  const { popups, closePopUp } = props
  const { data } = popups
  const popupsToShow = data && Object.keys(data)
  const { pathname } = root.location
  if (
    popupsToShow
    && popupsToShow.length
    && !DISABLED_ROUTES.includes(pathname)
  ) {
    return (
      <Wrap>
        {popupsToShow.includes(POPUP_TYPES.SHARE_THE_LOVE) && (
          <ReferralProgram
            toClose={closePopUp}
            {...data.shareTheLove}
          />
        )}
      </Wrap>
    )
  }
  return null
}

export default Popups
