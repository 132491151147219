import { createSelector } from "reselect"
import { isArray, find } from "lodash"

const getValues = values => values.value || {}
const getKeys = values => values.keys || []
const getActivities = timeline => timeline.data && timeline.data.activities
const getEntities = timeline => timeline.data && timeline.data.entities
const getBuyerMatches = match => match.data

export const formatFormValues = createSelector(
  [getValues, getKeys],
  (values, keys) => {
    const newValues = { ...values }
    let modifiedValues = null
    keys.map((key) => {
      const selectedField = values[key]
      if (isArray(selectedField) && selectedField.length > 0) {
        modifiedValues = selectedField.map(item => ({
          value: item,
          label: item,
        }))
      }
      newValues[key] = modifiedValues
    })
    return newValues
  }
)

export const processTimeline = createSelector([
  getActivities,
  getEntities,
], (activities, entities) => activities && activities.length > 0 && activities.map((activity) => {
  let entity = null
  // check if entity_name and entity_id exists
  if (activity.entity_name && activity.entity_id) {
    // find the id in entities object
    const selectedEntity = entities && entities[activity.entity_name]
      && entities[activity.entity_name]
        .find(x => parseInt(x.entity_id, 0) === parseInt(activity.entity_id, 0))
    // insert the new key
    entity = selectedEntity || {}
    // when some entity want data from activity, in this case it's Notes and requirement
    if (
      activity.entity_name === "notes"
      || activity.entity_name === "requirement"
    ) {
      // Notes entity wants text key from activity
      entity.text = activity.text
      entity.time = activity.time
    }
  }
  if (activity.entity_name === "manualCall" || activity.entity_name === "radiusCall") {
    entity = {
      ...entity,
      ...activity,
    }
  }
  // when looping through timeline, this is make our life easier
  return {
    ...activity,
    entity,
  }
}) || [])

export const processBuyerMatches = createSelector(
  [getBuyerMatches],
  (buyerMatches) => {
    const { matches, agentDetails, requirementDetails } = buyerMatches || {}
    return (
      (matches
        && matches.length > 0
        && matches.map(match => ({
          ...match,
          agentDetails: find(agentDetails, { id: match.requirement_agent_id }),
          requirementDetails: find(requirementDetails, { req_id: match.requirement_id }),
        })))
      || []
    )
  }
)
