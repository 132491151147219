import React, { useMemo, useRef, useState } from "react"
import { Select, Spin } from "antd"
import stuff from "images/about-us/pete.png"
import debounce from "lodash/debounce"
import {
  DropdownStyleWrap,
  ResultWrap,
  ResultTitle,
  Wrap,
} from "./googleLocationSearchStyles"
import FilledMLSTab from "./filledMLSTab"
import CustomDropdown from "./customDropdown"

function DebounceSelect({
  placeholder,
  fetchOptions,
  debounceTimeout = 800,
  value,
  onChange,
  ...props
}) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [selectDisplay, setDisplay] = useState("block")
  const [selectedOption, setSelectedOption] = useState()
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  console.log(options)
  return (
    <Wrap>
      <Select
        labelInValue
        placeholder={placeholder}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
        dropdownStyle={{
          display: "none",
        }}
        style={{
          width: "100%",
          display: selectDisplay,
        }}
        {...props}
      />
      <DropdownStyleWrap
        display={options && options.length > 0}
      >
        <ResultWrap>
          <ResultTitle>
            {`Showing ${options.length} results`}
          </ResultTitle>
        </ResultWrap>
        <CustomDropdown
          selectedOption={options}
          setOptions={setOptions}
          setFetching={setFetching}
          setDisplay={setDisplay}
          setSelectedOption={setSelectedOption}
          callback={onChange}
        />
      </DropdownStyleWrap>
      {selectDisplay === "none" && (
        <FilledMLSTab
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setDisplay={setDisplay}
        />
      )}
    </Wrap>
  )
}

export default DebounceSelect
