import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import JobBoard from "../Icons/NewIcons/JobBoard"

const JobsTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[1] === "jobs"}>
    <div className="icon-block-new">
      <JobBoard />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/jobs"
        isActive={routeArray[1] === "jobs"}
        onClick={() => {
          if (root.heap) {
            root.heap.track("click_sidebar_JobsRAR_web")
          }
        }}
      >
        Job Board
      </NavLink>
    </div>
  </InnerTab>
)

export default JobsTab
