export const DashIcon = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/dashIcon.png"
export const ReviewFreeIcon = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/reviewFeeSchedule.png"
export const ComplianceImage = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/Compliance.png"
export const signICAActive = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/icaSignActive.png"
export const headphones = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/headbones.png"
export const checkTick = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/checkTick.png"
export const message = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/message.png"
export const icaSignedImage = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/icaSigned.png"
export const disableIcon = "https://d2fedz0by71ckz.cloudfront.net/images/Onboarding/disableIcon.png"
export const QRCodeImage = "https://d2fedz0by71ckz.cloudfront.net/images/OFFICE_QRCodeV2.png"
export const QRCodeNetwork = "https://d2fedz0by71ckz.cloudfront.net/images/RADS_QR_BRIGHT.svg"
export const handPhoneLogo = "https://d2fedz0by71ckz.cloudfront.net/images/handPhone.png"
export const handPhoneBrokerage = "https://s3.amazonaws.com/cdn.agentdesks.com/images/handPhoneBrokerage.png"
export const appleLogin = "https://d2fedz0by71ckz.cloudfront.net/images/appleLogin.png"
