import React from "react"
import styled from "styled-components"
import Box from "@ui/Box"
import RadioButton from "./RenderRadioButton"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorLabel = styled.span`
  color: #ff4b4b;
  font-size: 12px;
  margin-top: 10px;
`

const RenderRadioGroup = (field) => {
  const {
    input: {
      onChange,
      name,
      value,
    },
    buttonStyles,
    labelStyles,
    detailsWrapStyle,
    descriptionStyles,
    errorAction,
    disabled,
    options,
    meta,
    fd,
    mt,
    clr,
    br,
    brrd,
    pd,
  } = field
  return (
    <Wrapper>
      <Box d="flex" fd={fd}>
        {options.map(item => (
          <RadioButton
            descriptionStyles={descriptionStyles}
            buttonStyles={buttonStyles}
            labelStyles={labelStyles}
            detailsWrapStyle={detailsWrapStyle}
            description={item.description || ""}
            disabled={disabled}
            key={item.value}
            handleOnClick={() => {
              if (disabled) {
                if (errorAction && typeof errorAction === "function") errorAction(true)
                return
              }
              onChange(item.value, name)
            }}
            selected={item.value === value}
            label={item.label}
            value={item.value}
            clr={clr}
            br={br}
            brrd={brrd}
            pd={pd}
            styles={{
              marginTop: mt,
            }}
          />
        ))}
      </Box>
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </Wrapper>
  )
}

RenderRadioGroup.defaultProps = {
  size: "20px",
}

export default RenderRadioGroup
