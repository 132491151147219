import React from "react"
import Modal from "@ui/Modal"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import colors from "@colors"
import { ATC } from "@fonts"

const ButtonWrap = styled.div``

const CRMModal = ({
  toClose,
  children,
  onClick,
  buttonText,
}) => {
  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      closeIcon={CloseIcon}
      showCloseButton
      width="600px"
      modalStyles={{
        marginTop: 0,
        padding: 0,
        boxShadow: "none",
      }}
      customDialogColor={colors.black}
      zIndex={1001}
      dialogStyles={{
        background: "#F9F9F7",
        borderRadius: "8px",
        // minHeight: "280px",
        fontFamily: `${ATC}`,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "28px",
        lineHeight: "32px",
        textAlign: "center",
        color: colors.black,
      }}
      iconStyles={{
        padding: "8px",
        borderRadius: "18px",
        fontSize: "10px",
        right: "20px",
        width: "40px",
        margin: "6px",
      }}
    >
      {children && children}
      {/* <ButtonWrap>
        <LoaderButton
          onClick={onClick}
        >
          {buttonText}
        </LoaderButton>
        <LoaderButton
          onClick={toClose}
        >
          No, thanks
        </LoaderButton>
      </ButtonWrap> */}
    </Modal>
  )
}

export default CRMModal
