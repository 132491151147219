import React from "react"
import { Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ErrorModal from "dumbComponents/common/GlobalModals/ErrorModal"
import Listings from "dumbComponents/Listings/pages/Listings"

// import ListingAgentInfo from "src/dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/BasicInfo"
// import AppErrorBoundary from "dumbComponents/common/ErrorBoundary"
// import SellerInfo from "dumbComponents/Listings/pages/CoverSheet/SellerInfo"
// import ListingDetails from "dumbComponents/Listings/pages/CoverSheet/ListingDetails"
// import PropertyInformation from "dumbComponents/Listings/pages/CoverSheet/PropertyInformation"
// import AskTCInformation from "dumbComponents/Listings/pages/CoverSheet/AskTCInformation"
// import EscrowInformation from "dumbComponents/Listings/pages/CoverSheet/EscrowInformation"
// import Vendors from "dumbComponents/Listings/pages/CoverSheet/Vendors"
import Header from "dumbComponents/Listings/components/Header"

const Wrap = styled.div`
  padding: 47px 50px;
`

const ProductAndServices = ({ isErrorModalOpen, toggleErrorModal }) => (
  <React.Fragment>
    asdadas
    <Wrap>
      <Helmet>
        <title>Products and Services | Radius Agent</title>
      </Helmet>
      {/* <Header /> */}
      <Switch>
        {/* <Route path="/realty/listings" exact component={Listings} /> */}
        <Route path="/realty/transaction-coordination/contract/basic-info" exact component={ListingAgentInfo} />
        {/* <Route path="/realty/listings/coversheet/seller-info" exact component={SellerInfo} />
        <Route path="/realty/listings/coversheet/listing-details" exact component={ListingDetails} />
        <Route path="/realty/listings/coversheet/property-info" exact component={PropertyInformation} />
        <Route path="/realty/listings/coversheet/tc-info" exact component={AskTCInformation} />
        <Route path="/realty/listings/coversheet/escrow-info" exact component={EscrowInformation} />
        <Route path="/realty/listings/coversheet/vendors" exact component={Vendors} /> */}
      </Switch>
    </Wrap>
  </React.Fragment>
)

export default ProductAndServices
