import React from "react"
import OffersBreadCrumb from "../BreadCrumb"
import OfferStatusSelect from "../OfferStatusSelectDropdown"
import {
  HeaderWrap,
  TitleAndSelectWrap,
  Title,
} from "./styles"

const HeaderSectionCommon = () => (
  <HeaderWrap>
    <OffersBreadCrumb />
    <TitleAndSelectWrap>
      <Title>
        <p>Write an Offer</p>
      </Title>
      <OfferStatusSelect />
    </TitleAndSelectWrap>
  </HeaderWrap>
)

export default HeaderSectionCommon
