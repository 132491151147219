import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Loader from "@ui/Loader"
import AsyncComponent from "hoc/asyncComponent"

const RadiusDefaultLoader = () => (
  <div style={{ padding: 20, marginTop: 100, textAlign: "center" }}>
    <Loader />
  </div>
)

const ISARedirectionAsync = () => import("dumbComponents/common/ISARedirection.js").then(module => module.default)
const ISARedirection = AsyncComponent(ISARedirectionAsync, <RadiusDefaultLoader />)

const RedirectionRoute = () => (
  <Switch>
    <Redirect from="/redirect" to="/community" exact />
    <Route path="/redirect/isa-status-redirection" exact component={ISARedirection} />
  </Switch>
)

export default RedirectionRoute
