import root from "window-or-global"
import { get } from "lodash"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const EVENT_ROOM_PUBLIC = "eventRoomPublicResponse"
const SCHEDULED_ROOM_PUBLIC = "scheduledRoomPublicResponse"

const initState = {
  ...get(root, "__STATE__.eventRoom", {}),
  ...get(root, "__STATE__.scheduledEventRoom", {}),
}

export default function eventRoomReducer(state = initState, action) {
  switch (action.type) {
    case Actions.getEventRoomDetailsAction.REQUEST:
    case Actions.getEventRoomDetailsAction.SUCCESS:
    case Actions.getEventRoomDetailsAction.CLEAR:
    case Actions.getEventRoomDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getEventRoomDetailsAction, EVENT_ROOM_PUBLIC),
      }
    }

    case Actions.joinRoomAsGuestDetailsAction.REQUEST:
    case Actions.joinRoomAsGuestDetailsAction.SUCCESS:
    case Actions.joinRoomAsGuestDetailsAction.CLEAR:
    case Actions.joinRoomAsGuestDetailsAction.FAILURE: {
      return {
        ...state,
      }
    }

    case Actions.leaveRoomAsGuestDetailsAction.REQUEST:
    case Actions.leaveRoomAsGuestDetailsAction.SUCCESS:
    case Actions.leaveRoomAsGuestDetailsAction.CLEAR:
    case Actions.leaveRoomAsGuestDetailsAction.FAILURE: {
      return {
        ...state,
      }
    }

    case Actions.getScheduledRoomEventDetailsAction.REQUEST:
    case Actions.getScheduledRoomEventDetailsAction.SUCCESS:
    case Actions.getScheduledRoomEventDetailsAction.CLEAR:
    case Actions.getScheduledRoomEventDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getScheduledRoomEventDetailsAction, SCHEDULED_ROOM_PUBLIC),
      }
    }

    default: {
      return state
    }
  }
}
