import React from "react"
import { Form } from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"
import {
  Wrap,
  SelectFieldWrap,
} from "./styles"

const SelectField = ({
  placeholder,
  options,
  style,
  arrowIcon,
  isFormField,
  required = false,
  defaultValue,
  name,
  onChange,
  text,
  requiredMessage = "",
  borderColor,
  noWidth,
  value,
  width,
  disabled,
  rules,
}) => {
  const customRules = rules || [{ required: true, message: requiredMessage }]

  const getSelectField = () => {
    switch (isFormField) {
      case true:
        return (
          <Form.Item
            name={name}
            rules={customRules}
            label={<LabelComponent text={`${text}`} />}
            required={required}
          >
            <SelectFieldWrap
              placeholder={placeholder || "Select"}
              options={options}
              style={{ ...style }}
              suffixIcon={arrowIcon}
              isFormField
              name={name}
              defaultValue={defaultValue}
              onChange={onChange}
              disabled={disabled}
              isRequired={required}
            />
          </Form.Item>
        )
      default:
        return (
          <Wrap
            noWidth={noWidth}
            width={width}
          >
            <SelectFieldWrap
              placeholder={placeholder}
              options={options}
              style={{ ...style }}
              suffixIcon={arrowIcon}
              defaultValue={defaultValue}
              onChange={onChange}
              borderColor={borderColor}
              value={value}
              disabled={disabled}
              required={required}
              isRequired={required}
            />
          </Wrap>
        )
    }
  }

  return (
    <>
      {getSelectField()}
    </>
  )
}

export default SelectField
