import React from "react"
import OffersV2 from "container/OffersV2"
import {
  getState,
  DEAL_TERMS_STEP,
} from "dumbComponents/OffersV2/consts"
import {
  Wrap,
  SectionTitle,
  SectionWrap,
  ListWrap,
  ListRowWrap,
  StateDiv,
} from "./styles"
import {
  DealTermsForm,
} from "./Forms"

const DealTerms = ({
  dealTermObject,
  updateDealTermsInformationTab,
  dealToView,
  stateForOffer,
  updateDealTermsInformationTabResponse,
}) => {
  const {
    isFetching: updatingDealTerms,
  } = updateDealTermsInformationTabResponse || {}

  const {
    form,
    type_of_transaction,
  } = dealTermObject || {}

  const handleFinalSubmit = (values) => {
    const payload = {
      ...values,
      current_offer_step: DEAL_TERMS_STEP,
      draft: 0,
      type_of_transaction,
    }

    updateDealTermsInformationTab({
      id: dealToView,
      payload,
    })
  }

  return (
    <Wrap>
      {/*Deal Terms*/}
      <SectionWrap>
        <ListRowWrap>
          <SectionTitle>
            <p>Deal Terms</p>
          </SectionTitle>
          <StateDiv>
            <p>State:</p>
            <h2>{getState(stateForOffer)}</h2>
          </StateDiv>
        </ListRowWrap>
        <ListWrap>
          <DealTermsForm
            prefilledData={form[stateForOffer]}
            state={stateForOffer}
            onFinish={handleFinalSubmit}
            updatingDealTerms={updatingDealTerms}
          />
        </ListWrap>
      </SectionWrap>
    </Wrap>
  )
}

export default OffersV2(DealTerms)
