import styled from "styled-components"
import { INTER } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const SectionTitle = styled.div`
  p {
    color: var(--Color-Gray-400, #41446A);
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.352px;
  }

  margin-bottom: 18px;
`

export const SectionWrap = styled.div`
  //padding-bottom: 68px;
`

export const CTAWrap = styled.div`
  // padding-top: 18px;
  padding-bottom: 18px;
`
export const ListWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`
