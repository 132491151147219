import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { convertUnicode } from "services/Utils"
import { mobile } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
  margin: 20px 0px 40px 0px;
  color: ${colors.dirtBrown};
  line-height: 1.5;
  font-weight: 300;
  position: relative;
  ${props => props.notesAndResources && `
     font-size: 14px;
   `}

  ${props => props.fSize && `
    font-size: ${props.fSize}
  `}

  ${props => props.isTrimmed && `
    display: -webkit-box;
    margin: 0px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 160px;

    > p {
      margin: 0px;
    }
  `}
`

const List = styled.ul`
  margin-left: 15px;
  list-style: disc;
`

const Para = styled.p`
  font-family: ${ATC};
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  
  @media(min-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media(min-width: 1024px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media(min-width: 1366px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 23px;
  }
`

const Header = styled.h4`
  font-size: 18px;
  font-weight: bold;
  ${mobile(`
    font-size: 12px;
  `)}
`

const Anchor = styled.a`
  color: ${colors.primaryColors};
  text-decoration: none;
`

const HR = styled.hr`

`

const SeeMore = styled.span`
  position: absolute;
  bottom: -2px;
  left: 0px;
  z-index: 10;
`

const getTotalWordCount = (blocks) => {
  if (blocks && blocks.length > 0) {
    const text = blocks.reduce((rawText, item) => {
      if (item.type === "header" || item.type === "paragraph") {
        rawText += item.data.text
      }
      return rawText
    }, "")
    return text
  }
}

//const ParseTextForStyling = (text) => ()

const RTV = (props) => {
  const {
    data,
    isTrimmed,
    notesAndResources,
    id,
    style,
    mxHt,
    mnHt,
    fSize,
  } = props
  return (
    <div style={{ position: "relative", maxHeight: mxHt ? mxHt : "initial", minHeight: mnHt ? mnHt : "initial" }} id={id}>
      {/* {isTrimmed && wordLen && wordLen.length > 1000 && (
          <SeeMore onClick={onSeeMoreClick}>
            See More...
          </SeeMore>
      )} */}
      <Wrap isTrimmed={isTrimmed} notesAndResources={notesAndResources} style={style} fSize={fSize}>
        {data && data.blocks && data.blocks.length > 0 ? data.blocks.map((item, index) => {
          if (item.type === "header") {
            return (
              <Header key={index} dangerouslySetInnerHTML={{ __html: item.data.text }} />
            )
          }

          if (item.type === "paragraph") {
            return (
              <Para
                key={index}
                dangerouslySetInnerHTML={{ __html: item.data.text }}
              />
            )
          }
          if (item.type === "list") {
            return (
              <List as={item.data.style === "ordered" ? "ol" : "ul"} key={index}>
                {item.data.items && item.data.items.map(x => (
                  <li dangerouslySetInnerHTML={{ __html: x }} />
                ))}
              </List>
            )
          }
          if (item.type === "linkTool") {
            return (
              <Anchor href={item.data.link} target="_blank" key={index}>
                {item.data.link}
              </Anchor>
            )
          }

          if (item.type === "delimiter") {
            return (
              <HR key={index} />
            )
          }
          return `N/A ${item.type}`
        }) : (
          <span></span>
        )}
      </Wrap>
    </div>
  )
}

export default RTV
