import React, { useEffect, useState } from "react";
import root from "window-or-global";
import { InnerTab, NavLink } from "./commonStyles";
import { enabledFeatureCheck } from "services/Utils";
import ConsumerAppContainer from "container/ConsumerApp/index";
import ConsumerAppStatusModal from "@ui/ConsumerAppStatusModal";
import AppContainer from "container/App";
import { getAppColorScheme } from "../../../../../hoc/utils";

const ConsumerAppDashboard = ({
  routeArray,
  isBroker,
  getAppStatus,
  getAppStatusResponse,
  teamDetails,
  teamDetailsForLogo,
}) => {
  const data = teamDetails || teamDetailsForLogo;
  const [isOpen, setIsOpen] = useState(false);
  const [modalTriggered, setModalTriggered] = useState(false);
  const [isAppInWaitlist, setIsAppInWaitlist] = useState(false);

  const checkAppStatus = () => {
    getAppStatus();
  };

  const appColorScheme = getAppColorScheme(teamDetailsForLogo);

  useEffect(() => {
    if (
      !modalTriggered &&
      getAppStatusResponse?.data &&
      Object.keys(getAppStatusResponse.data).length === 0
    ) {
      setIsOpen(true);
      setIsAppInWaitlist(false);
      setModalTriggered(true);
    }
    if (!modalTriggered && getAppStatusResponse?.data?.wait_list) {
      setIsOpen(true);
      setIsAppInWaitlist(true);
      setModalTriggered(true);
    }
    if (!modalTriggered && getAppStatusResponse?.data?.status === "App Live") {
      setIsOpen(true);
      setIsAppInWaitlist(true);
      setModalTriggered(true);
    }
  }, [getAppStatusResponse, modalTriggered]);

  const handleNavClick = (e) => {
    e.preventDefault();
    setModalTriggered(false);
    checkAppStatus();
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalTriggered(true);
  };

  // Define styles
  const styles = {
    teamIcon: {
      width: "25px",
      height: "25px",
      marginLeft: "-10px",
      borderRadius: "4px",
    },
    placeholderIcon: {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      backgroundColor: "#ccc",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: "bold",
      marginLeft: "-15px",
    },
    // newBadge: {
    //   color: "#F37475",
    //   padding: "4px 8px",
    //   borderRadius: "4px",
    //   marginTop: "4px",
    //   display: "inline-block",
    //   background: appColorScheme?.cssVariables?.sidebarBackground || "#fff",
    // },
  };

  return (
    <InnerTab isActive={routeArray[1] === "consumer-app"}>
      <div className="icon-block-new"></div>
      <div className="text-block">
        <NavLink
          to="/consumer-app"
          isActive={routeArray[1] === "consumer-app"}
          onClick={handleNavClick}
        >
          {data?.icon_url ? (
            <img
              style={styles.teamIcon}
              src={data.icon_url}
              alt={`${data.name}'s team icon`}
            />
          ) : (
            <div style={styles.placeholderIcon}>
              {data?.name?.charAt(0)?.toUpperCase() || ""}
            </div>
          )}
          Radius Client App
        </NavLink>
        {isOpen && (
          <ConsumerAppStatusModal
            isOpen={isOpen}
            setIsOpen={closeModal}
            isAppInWaitlist={isAppInWaitlist}
            teamImage={data}
          />
        )}
      </div>
    </InnerTab>
  );
};

export default AppContainer(ConsumerAppContainer(ConsumerAppDashboard));
