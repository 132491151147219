import React from "react"
import styled, { keyframes } from "styled-components"
import root from "window-or-global"
import CircularImage from "dumbComponents/common/CircularImage"
import MariImage from "images/realty-v2/mari_kawaski.png"
import { getReferralManager, getOS, getRarHubspotMeetingLink } from "services/Utils"
import queryString from "query-string"
import colors from "@colors"
import icons from "@icons"
import ASMChat from "./ASMChat"

const showAnimation = keyframes`
  0% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }

  100% {
    height: 340px;
    width: 300px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
`

const showAnimationFlip = keyframes`
  0% {
    height: 340px;
    width: 300px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
  100% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }


`

const HelloWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  overflow: hidden;
  width: 100%;
  opacity: 1;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;

  ${props => props.isOpen && `
    width: 0%;
    opacity: 0;
    height: 0px;
  `}

  span {
    margin-left: 5px;
  }

  ${props => props.isISA && `
    line-height: 1.5;
    text-align: left;
  `}
`

const StyledAnchor = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  background: ${colors.darkPurple};
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  z-index:9999;
  border: 1px solid #FBF8EF;

  height: 250px;
  width: 200px;
  border-radius: 16px;


  ${props => props.isOpen ? `
    animation: ${showAnimation} 0.3s ease forwards;
  ` : `
    animation: ${showAnimationFlip} 0s ease forwards;
  `}


  /* ${props => props.isISA && `
    width: 320px !important;
  `} */
  /* ${props => props.isOpen && props.isISA && `
    height: 340px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 4px;
    cursor: inherit;
    width: 310px !important;
    background: yellow;
  `}

   ${props => props.isOpen && !props.isISA && `
      height: 340px;
      width: 350px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      border-radius: 5px;
      cursor: inherit;
   `} */
`

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 28.5px;
  border: 2px solid #2bda8e;
  color: #262d35;
  transition-duration: 0.5s;
  padding: 6px;

  ${props => props.isOpen && `
    border-radius: 4px;
    border: none;
    margin-top: 10px;
  `}
`

const MessageWrap = styled.div`
  width: 220px;
  margin: 20px auto;
  text-align: center;
  min-height: 100px;
  p {
    margin: 6px 0px 0px 0px;
    padding: 0px;
    color: ${colors.contentText};

    &:nth-child(1) {
      font-weight: 100;
    }

    &:nth-child(2) {
      font-weight: 500;
    }

    &:nth-child(3) {
      font-weight: 300;
    }
  }

  a {
    margin-top: 10px;
    color: #333;
    display: block;
  }
  ${props => props.isQualified && `
        margin: 39px auto
    `}
`

const ControllWrap = styled.div`
  display: flex;
  flex-direction: column;

  a, button  {
    font-size: 18px;
    width: 100%;
    display: block;
    border: none;
    padding: 12px;
    text-decoration: none;
    font-weight: 100;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #333;
  }
`

const CommunicationWrap = styled.div`
display: flex;
color: white;
 button {
  border-right: none !important;
 }

`

const BookADemoDiv = styled.div``

const CloseIcon = styled.div`
  position: absolute;
  right: 11px;
  top: 12px;
  font-size: 7px;
  color: #CCC;
  display: none;
  border: 1px solid;
  border-radius: 17px;
  padding: 5px;

  ${props => props.isOpen && `
    display: inherit;
  `}
`

const BookADemo = styled.a`
  margin-top: 19px;
  background: ${colors.darkPurple};
  color: ${colors.white} !important;
  p {
    text-decoration: underline;
    font-family: "DM Sans", "Rubik", serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
  }
`

const CTAWrap = styled.div`
  padding: 0px;

  ${props => props.isIsa && `
    padding: 0px 0px 0px 80px;
  `}
  margin-top: -24px;
  button {
    margin-right: 17px;
  }
  button, a {
    font-size: 13px;
    height: 24px;
    width: 60px;
    left: 0px;
    top: 0px;
    border-radius: 100px;
    background: #BFB1DB;
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 4px 16px 4px 16px;
    border: none;
  }
`

const ISAText = styled.div`
 p {
    color: white;
    font-family: "DM Sans", "Rubik", serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
 }
`

const OrBlock = styled.div`
  display: flex;
  height: 27px;
  width: 100%;
  background: transparent;
  text-align: center;
  div {
    height: 100%;
    flex-grow: 1;
    border-bottom: 1px solid #FBF8EF;
  }

  .or {
    padding-top: 19px;
    color: white;
    border-bottom: none;
    background: none;
  }
`

const FoldedView = styled.div`
  width: 60px;
  height: 60px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 10px;
  position: fixed;
  background: center no-repeat;
  background-size: cover;
  right: 20px;
  bottom: 40px;
  border: 4px solid ${colors.white};
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  background-color: #3C4857;
`

class ReferralManagerContactV2 extends React.Component {
  constructor(props) {
    super(props)
    const {
      isOpen = false,
      type,
    } = props
    this.state = {
      isOpen,
      isMounted: false,
      type,
      source: "web",
    }
  }

  componentDidMount() {
    const currentOS = getOS()
    this.setState({
      source: currentOS !== "browser" ? "mobile" : root.location.search && queryString.parse(root.location.search).src || "web",
    })
    // setTimeout(() => {
      // this.setState({
      //   isMounted: true,
      // })
    //   root.fcWidget.on("widget:closed", () => {
    //     root.fcWidget.hide()
    //   })
    // }, 2000)
    const {
      ms,
      isPlugIn,
    } = this.props

    setTimeout(() => {
      this.setState({
        isMounted: true,
        isOpen: true,
      })
    }, ms)

    if (isPlugIn) {
      root.fcWidget.on("widget:closed", () => {
        root.top.postMessage("isFreshChatClose", "*")
        this.setState({
          isOpen: false,
        })
      })
    }
  }

  toggleOpen = () => {
    const { isOpen } = this.state
    this.setState({
      isOpen: !isOpen,
    })
  }

  handleChatClick = () => {
    this.setState({
      isOpen: false,
    })
    root.fcWidget.show()
    root.fcWidget.open()
    root.top.postMessage("isFreshChatOpen", "*")
  }

  getBookADemoLink = () => "https://meetings.hubspot.com/mari23/rar-m"

  render() {
    const { isOpen, isMounted } = this.state
    const {
      type,
    } = this.props
    let referralManager = null
    if (type === "isa") {
      // This is for logged in state for Radius Assist
      referralManager = getReferralManager(true, "isa")
    } else {
      // This is for other pages.
      referralManager = getReferralManager(false, type)
    }
    const {
      photo,
      firstname,
      lastname,
      phone,
      email,
      title,
    } = referralManager || {}

    if (!isMounted) {
      return null
    }
    if (isOpen) {
      root.top.postMessage("isOpenCustomChat", "*")
    } else {
      root.top.postMessage("isCloseCustomChat", "*")
    }
    return (
      <React.Fragment>
        {type === "isa" ? (
          <FoldedView
            style={{ backgroundImage: `url(${photo})` }}
            onClick={this.toggleOpen}
          />
        ) : (
          <FoldedView
            style={{ backgroundImage: `url(${MariImage})` }}
            onClick={!isOpen ? this.toggleOpen : undefined}
          />
        )}
        <StyledAnchor
          onClick={!isOpen ? this.toggleOpen : undefined}
          isOpen={isOpen}
          isISA={type === "isa" || type === "concierge"}
        >
          <CloseIcon onClick={isOpen ? this.toggleOpen : undefined} isOpen={isOpen}>
            <i className={icons.arrowsClose}></i>
          </CloseIcon>
          <StyledButton isOpen={isOpen} isISA={type === "isa"}>
            {/* <SupportImage style={{ backgroundImage: `url(${photo})` }} /> */}
            <CircularImage
              image={type === "isa" ? photo : MariImage}
              size={isOpen ? 92 : 40}
              name={type === "isa" ? `${firstname} ${lastname || ""}` : "Mari Kawasaki"}
              alt="rar support"
              style={{
                transitionDuration: "0.3s",
                backgroundPosition: "center",
              }}
            />
          </StyledButton>
          <MessageWrap>
            <ISAText>
              {type === "isa" ? (
                <p>
                  {firstname === "Ben" ? "Hi, my name is Ben, How can I help you?" : ""}
                </p>
              ) : (
                <p>
                  Hi, I&apos;m Mari! Have questions about Radius Agent Realty?
                </p>
              )}
            </ISAText>
          </MessageWrap>
          <CTAWrap isIsa={type === "rar"}>
            <button onClick={this.handleChatClick} type="button">Chat</button>
            {type === "isa" ? (
              <a href={`mailto:${email}`}>
                Email
              </a>
            ) : (
              <a href="mailto:mari@radiusagent.com">Email</a>
            )}
          </CTAWrap>
          <OrBlock>
            <div></div>
            <div className="or">
              <p>OR</p>
            </div>
            <div></div>
          </OrBlock>
          <ControllWrap>
            <BookADemoDiv>
              <BookADemo
                href={type === "isa" ? "https://meetings.hubspot.com/radiusagent/radius-assist-isa-interest-lp" : getRarHubspotMeetingLink()}
                target="_blank"
              >
                <p>Speak With Us</p>
              </BookADemo>
            </BookADemoDiv>
          </ControllWrap>
          <ASMChat referralManager={referralManager} />
        </StyledAnchor>
      </React.Fragment>

    )
  }
}

export default ReferralManagerContactV2
