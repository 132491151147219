import React from "react"
import { reduxForm, Field } from "redux-form"
import styled from "styled-components"
import { Input, Select } from "@ui/Form"
import { required } from "services/formUtils"
import {
  Footer,
  SaveButton,
  ErrorText,
} from "./commonStyles"

const FormWrap = styled.form``

const FieldRow = styled.div`
  margin-bottom: 20px;
  label {
    margin-bottom: 0px;
    margin-top: 40px;
  }
`

const FormDiv = styled.div`
  padding:10px;
`

const SyncMlsForm = ({
  syncMLSProps,
  syncMLSProps: {
    isSyncingMls,
    syncMlsValues,
    mlsStates,
    filteredMlsLists,
  } = {},
  handleSubmit,
  MLSSyncError,
}) => {
  const onMlsStateChange = (mlsState) => {
    const { mlsStateChange } = syncMLSProps
    mlsStateChange({ mlsState: mlsState.value })
  }

  return (
    <FormDiv>
      <FormWrap p="0px 25px 25px 25px" onSubmit={handleSubmit}>
        <FieldRow>
          <Field
            name="mls_state"
            component={Select}
            placeholder="MLS state"
            options={mlsStates}
            validate={[required]}
            onChange={evt => onMlsStateChange(evt)}
            config={{
              isSearchable: false,
            }}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="serving_id"
            component={Select}
            placeholder="Select MLS"
            options={filteredMlsLists}
            validate={[required]}
            config={{
              isSearchable: false,
              isDisabled: !syncMlsValues.state,
            }}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="email"
            component={Input}
            label="MLS Email"
            placeholder="MLS Email"
            validate={[required]}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="agent_mls_id"
            component={Input}
            validate={[required]}
            label="MLS Agent ID"
            placeholder="MLS Agent ID"
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="license"
            component={Input}
            validate={[required]}
            label="Real Estate License #"
            placeholder="Real Estate License #"
          />
        </FieldRow>
        {MLSSyncError && (
          <ErrorText>{MLSSyncError || "Something went wrong! Please try again."}</ErrorText>
        )}
        <Footer d="flex" jc="flex-end">
          <SaveButton
            width="100px"
            isShowLoader={isSyncingMls}
            type="submit"
          >
            Save
          </SaveButton>
        </Footer>
      </FormWrap>
    </FormDiv>
  )
}

export default reduxForm({
  form: "SYNC_MLS_FORM"
})(SyncMlsForm)
