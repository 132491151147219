import React from "react"
import { Select } from "antd"
import { uniqBy } from "lodash"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"

const TeamMemberSelect = ({
  getTeamDetailsResponse,
  onChange,
  defaultValue,
  options,
}) => {

  return (
    <Select
      showSearch
      style={{
        width: "100%",
      }}
      placeholder="Search"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())}
      // filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
    />
  )
}
export default TeamProfileContainer(TechAssetsContainer(TeamMemberSelect))
