import React from "react"
import ReactDOM from "react-dom"
import RadiusLogoBig from "images/Graphics/RadiusIconBig.svg"
import HoverControls from "./components/HoverControls"
import Pin from "./components/PinComponent"
/*
  Document Block
  DO NOT CHANGE UNLESS REQUIRED
*/
export const SPOTLIGHT = "spotlight"
export const GRID = "radius-rooms-users-grid"
export const OTHERS = "radius-other-users"
export const OTHERS_VIDEO_FEED = "rooms-box-others"
export const SPOTLIGHT_VIDEO_FEED = "rooms-box"
export const SIDEBAR = "sidebar"
export const GRID_VIEW_HEIGHT = "244px"
export const GRID_VIEW_WIDTH = "440px"
export const IS_ACTIVE = "is-active"
export const ACTIVE_SPEAKER = "is-speaking"
export const SECONDARY_SPEAKER = "new-order-2"
export const NONE = "none"
export const ORDER = "order"
export const BLOCK = "block"
export const RELATIVE = "relative"
export const PRIMARY_SPEAKER = "new-order-1"
export const SIDEBAR_GRID_CONTAINER = "rooms-box-sidebar"
export const ANIMATION = "ui-change"

/**UNUSED DELETE IF NOT REQUIRED */
// const SIDEBAR_CONTAINER = "sidebar-container"
// const STATIC = "static"

const fallbackImage = "https://s3.amazonaws.com/image.agentdesks.com/profile/1014090/1576262972336.jpeg"

export const createUserHoverControls = (id) => {
  const ControlContainer = document.createElement("div")
  ControlContainer.setAttribute("id", `controls-${id}`)
  ControlContainer.classList.add("hover-controls")

  /*MAKE SPEAKER*/
  /*MAKE SPEAKER*/

  return ControlContainer
}

export const createUserCircle = (img) => {
  try {
    const UserCircle = document.createElement("div")
    UserCircle.classList.add("userCircle")
    const ImageElement = document.createElement("img")
    ImageElement.setAttribute("src", img || RadiusLogoBig)
    ImageElement.style.height = "100%"
    ImageElement.style.width = "100%"
    if (UserCircle) {
      UserCircle.append(ImageElement)
    }
    return UserCircle
  } catch (e) {
    console.log("error occured in createUserCircle", e)
  }
  return false
}

export const toggleCircle = (id) => {
  const circleEle = document.querySelector(`#video-container-${id} .userCircle`)
  if (circleEle) circleEle.style.display = NONE
}

export const createNameTag = () => {
  const NameTag = document.createElement("div")
  NameTag.classList.add("rooms-name-tag")
  return NameTag
}

export const getId = (element) => {
  if (!element) return "user"
  const {
    id,
  } = element || {}
  const idSplit = id.split("-")
  const idVal = idSplit[idSplit.length - 1]
  return idVal
}

export const getUserName = (members, id) => {
  if (!members || members.length <= 0) return ""
  const filteredMember = members && members.filter(member => member.id === parseInt(id, 10))
  if (!filteredMember || filteredMember.length <= 0) return ""
  const userName = `${filteredMember[0].firstname} ${filteredMember[0].lastname}`
  return userName
}

export const isUserIdHost = (members, id) => {
  if (!members || members.length <= 0) return false

  const hostBool = members && (members
    .filter(member => member.id === parseInt(id, 10) && member.Role === "host"))
  return hostBool.length > 0
}

export const addUserToSpotlightNonTrack = (id, name, props) => {
  try {
    console.log("spotlight")
  /*Name Element*/
    const NameTag = createNameTag()
    const UserCircle = createUserCircle()

    /*Use the name from the members list or extract it from current user object as FALLBACK*/
    NameTag.innerText = name || ""
    const DivContainer = document.createElement("div")
    DivContainer.setAttribute("id", `video-container-${id}`)
    DivContainer.classList.add(SPOTLIGHT_VIDEO_FEED, ANIMATION)

    const ParentElement = document.getElementById(SPOTLIGHT)
    if (DivContainer) {
      ParentElement.append(DivContainer)
    }
    ReactDOM.render(
      <>
        <Pin id={id} />
        <HoverControls id={id} reduxData={props} override />
      </>,
      document.querySelector(`#video-container-${id}`)
    )
    if (DivContainer) {
      DivContainer.append(NameTag)
      DivContainer.append(UserCircle)
    }
  } catch (e) {
    // failed silently
  }
}

export const addUserToSpotlight = (track, id, toggleSpotlightView, props) => {
  try {
    /*Name Element*/
    toggleSpotlightView(true)
    const NameTag = createNameTag()
    const UserCircle = createUserCircle()

    /*Use the name from the members list or extract it from current user object as FALLBACK*/
    NameTag.innerText = id.toString() || "USER"
    const DivContainer = document.createElement("div")
    DivContainer.setAttribute("id", `video-container-${id}`)
    // DivContainer.setAttribute("id", `video-${isScreenShare ? "screen-share-" : ""}container-${id}`)
    DivContainer.classList.add(SPOTLIGHT_VIDEO_FEED, ANIMATION)
    const ParentElement = document.getElementById(SPOTLIGHT)
    if (ParentElement) {
      ParentElement.append(DivContainer)
    }

    //
    ReactDOM.render(
      <>
        <Pin id={id} />
        <HoverControls id={id} reduxData={props} />
      </>,
      document.querySelector(`#video-container-${id}`)
    )
    /**MAKE SURE WE FOLLOW THIS*/
    if (DivContainer) {
      DivContainer.append(NameTag)
      DivContainer.append(UserCircle)
    }
    const circle = document.querySelector(`#video-container-${id} .userCircle`)
    if (track) {
      circle.style.display = NONE
      const trackThatWasPromised = track.play(DivContainer)

      if (trackThatWasPromised !== undefined) {
        trackThatWasPromised.then(() => {
          //
        }).catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
          console.log("Error", error)
        })
      }
    } else {
      circle.style.display = BLOCK
    }
  } catch (error) {
    console.log("Error in addUserToSpotlight", error)
  }
}

export const handleSpotlightChange = (
  toggleOtherUsersView,
  id,
  toggleSidebarView,
) => {
  toggleOtherUsersView(false)
  const spotlightView = document.getElementById(SPOTLIGHT)
  const currentSpotlightUsers = spotlightView && spotlightView.children

  //remove absolute since we are dealing with no on video user

  if (currentSpotlightUsers && currentSpotlightUsers.length <= 2) {
    //loop and remove absolute
    convertToSidebarMode(id, toggleSidebarView)
    // spotlightView.style.flexDirection = "column"
    // for (let index = 0; index < currentSpotlightUsers.length; index += 1) {
    //   currentSpotlightUsers[index].style.position = "static"
    // }
  } else if (currentSpotlightUsers.length > 2) {
    spotlightView.style.flexDirection = "row"
    spotlightView.style.flexWrap = "wrap"
    for (let index = 0; index < currentSpotlightUsers.length; index += 1) {
      currentSpotlightUsers[index].style.position = "static"
      currentSpotlightUsers[index].style.height = "500px"
      currentSpotlightUsers[index].style.width = "550px"
    }
  }
}

export const convertToSidebarMode = (id, toggleSidebarView) => {
  try {
    //toggle side-bar
    const spotlight = document.getElementById(SPOTLIGHT)
    const otherUsersView = document.getElementById(OTHERS)
    const sidebarComponent = document.getElementById(SIDEBAR)
    const childCount = sidebarComponent.childElementCount
    const SidebarHeight = sidebarComponent.clientHeight
    const totalHeightOfChild = (childCount + 1) * 135
    toggleSidebarView(true)

    //append all the users to sidebar spotlight and normal users
    const otherUsers = otherUsersView && otherUsersView.children

    for (let index = 0; index < otherUsers.length; index += 1) {
      otherUsers[index].style.position = "static"
      otherUsers[index].style.width = "100%"
      otherUsers[index].style.height = "200px"
      if (sidebarComponent) {
        sidebarComponent.append(otherUsers[index])
      }
      if (totalHeightOfChild > SidebarHeight) {
        otherUsers[index].classList.add("hidden-container")
      }
    }

    const spotlightUsers = spotlight && spotlight.children

    for (let index = 0; index < spotlightUsers.length; index += 1) {
      spotlightUsers[index].classList.add(OTHERS_VIDEO_FEED)
      spotlightUsers[index].classList.remove(SPOTLIGHT_VIDEO_FEED)
      spotlightUsers[index].setAttribute("style", "")
      if (sidebarComponent) {
        sidebarComponent.append(spotlightUsers[index])
      }
    }
  } catch (e) {
    console.log("error", e)
  }
}

export const addUsersToSidebar = (track, id, name, image) => {
  try {
    /*Name Element*/
    const NameTag = createNameTag()
    NameTag.innerText = name || id.toString()
    const UserCircle = createUserCircle(image)

    const ParentElement = document.getElementById(SIDEBAR)
    const childCount = ParentElement.childElementCount

    const DivContainer = document.createElement("div")
    // DivContainer.append(UserCircle)
    DivContainer.setAttribute("id", `video-container-${id}`)
    DivContainer.classList.add("rooms-box-others", ANIMATION)
    DivContainer.setAttribute("style", `
        height: 135px;
        width: 290px;
        order: ${childCount + 2};
      `)
    if (ParentElement) {
      ParentElement.append(DivContainer)
    }

    const SidebarHeight = ParentElement.clientHeight
    const totalHeightOfChild = (childCount + 1) * 135
    if (totalHeightOfChild > SidebarHeight) {
      DivContainer.classList.add("hidden-container")
    }

    const hoverElement = document.querySelector(`#controls-${id}`)

    //NEED TO HANDLE FOR LISTENER HERE

    if (hoverElement) {
      hoverElement.classList.remove("hide-hover-controls")
      hoverElement.classList.remove("hover-controls")
      hoverElement.classList.add("speaker-hover-controls")
    } else {
      ReactDOM.render(
        <>
          <Pin id={id} />
          <HoverControls id={id} override />
        </>,
        document.querySelector(`#video-container-${id}`)
      )
    }

    if (DivContainer) {
      DivContainer.append(NameTag)
      DivContainer.append(UserCircle)
    }

    const circleEle = document.querySelector(`#video-container-${id} .userCircle`)

    if (track) {
      toggleCircle(id)
      const trackThatWasPromised = track.play(DivContainer)

      if (trackThatWasPromised !== undefined) {
        trackThatWasPromised.then(() => {
          // Automatic playback started!
          // Show playing UI.
        }).catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
          console.log("Error", error)
        })
      }
    } else if (circleEle) {
      circleEle.style.display = BLOCK
    }
  } catch (error) {
    console.log("error", error)
  }
}

export const addUsersToGridView = (track, id, name, image, props = {}, skipResize) => {
  try {
    /*Name Element*/
    const NameTag = createNameTag()
    const Circle = createUserCircle(image)
    NameTag.innerText = name || id.toString() || "???"

    const DivContainer = document.createElement("div")
    DivContainer.setAttribute("id", `video-container-${id}`)
    DivContainer.classList.add(OTHERS_VIDEO_FEED, ANIMATION)
    DivContainer.style.position = RELATIVE
    // DivContainer.style.height = GRID_VIEW_HEIGHT
    // DivContainer.style.width = GRID_VIEW_WIDTH
    const ParentElement = document.getElementById(GRID)

    if (ParentElement) {
      ParentElement.append(DivContainer)
    }

    const hoverElement = document.querySelector(`#controls-${id}`)

    //NEED TO HANDLE FOR LISTENER HERE

    if (hoverElement) {
      hoverElement.classList.remove("hide-hover-controls")
      hoverElement.classList.remove("hover-controls")
      hoverElement.classList.add("speaker-hover-controls")
    } else {
      ReactDOM.render(
        <>
          <Pin id={id} />
          <HoverControls id={id} reduxData={props} override />
        </>,
        document.querySelector(`#video-container-${id}`)
      )
    }

    if (DivContainer) {
      DivContainer.append(NameTag)
      DivContainer.append(Circle)
    }
    const circleEle = document.querySelector(`#video-container-${id} .userCircle`)

    if (track) {
      toggleCircle(id)
      const trackThatWasPromised = track.play(DivContainer)

      trackThatWasPromised.then(() => {
        // Automatic playback started!
        // Show playing UI.
      }).catch((error) => {
        // Auto-play was prevented
        // Show paused UI.
        console.log("Error", error)
      })
    } else {
      circleEle.style.display = BLOCK
    }
    if (!skipResize) {
      resizeScreen()
    }
  } catch (e) {
    console.log("error in addUsersToGrid", e)
  }
}

export const convertToGridView = (toggleGridView, toggleOtherUsersView, toggleSpotlightView, hostId = null) => {
  try {
    const spotlightView = document.getElementById(SPOTLIGHT)
    const otherUsersView = document.getElementById(OTHERS)
    const gridView = document.getElementById(GRID)

    //get the spotlight user
    const currentSpotlightUser = document.getElementsByClassName(SPOTLIGHT_VIDEO_FEED)

    //remove its class
    if (currentSpotlightUser && currentSpotlightUser.length > 0) {
      currentSpotlightUser[0].classList.add(OTHERS_VIDEO_FEED)
      currentSpotlightUser[0].classList.remove(SPOTLIGHT_VIDEO_FEED)
    }

    const spotlightChildren = spotlightView && spotlightView !== null && spotlightView.children

    if (spotlightChildren && spotlightChildren.length > 0) {
      const idVal = getId(spotlightChildren[0])
      const HoverElement = document.querySelector(`#controls-${idVal}`)
      spotlightChildren[0].style.position = RELATIVE
      spotlightChildren[0].style.height = GRID_VIEW_HEIGHT
      spotlightChildren[0].style.width = GRID_VIEW_WIDTH
      const isHostDiv = idVal === hostId

      /**TODO ROOMS NEED TO CHECK | SAURAV */
      // if (HoverElement) {
      //   console.log("here? hover bolted")
      //   if (!isHostDiv) {
      //     HoverElement.classList.remove("hide-hover-controls")
      //     HoverElement.classList.remove("hover-controls")
      //     HoverElement.classList.add("speaker-hover-controls")
      //   } else {
      //     HoverElement.classList.remove("hover-controls")
      //     HoverElement.classList.remove("speaker-hover-controls")
      //     HoverElement.classList.add("hide-hover-controls")
      //   }
      // }

      if (gridView) {
        gridView.append(spotlightChildren[0])
      }
    }

    const OtherTags = createNameTag()

    for (let index = 0; index < otherUsersView.children.length; index += 1) {
      if (otherUsersView && otherUsersView !== null && otherUsersView.children[index]) {
        otherUsersView.children[index].append(OtherTags)
        otherUsersView.children[index].style.position = "relative"
        otherUsersView.children[index].style.height = GRID_VIEW_HEIGHT
        otherUsersView.children[index].style.width = GRID_VIEW_WIDTH
        if (gridView) {
          gridView.append(otherUsersView.children[index])
        }
      }
    }

    toggleSpotlightView(false)
    toggleOtherUsersView(false)
    toggleGridView(true)
  } catch (e) {
    console.log("error", e)
  }
}

export const activeSpeakerIndicator = (id, volumeLevel) => {
  try {
    const DivContainer = document.querySelector(`#video-container-${id}`)
    const ControlContainer = document.querySelector(`#speaker-control-${id}`)
    let ActiveMicElement
    let AnimElement
    if (ControlContainer) {
      // eslint-disable-next-line prefer-destructuring
      ActiveMicElement = ControlContainer.children[0]
      // eslint-disable-next-line prefer-destructuring
      AnimElement = ControlContainer.children[1]
    }
    if (Math.ceil(volumeLevel) > 10) {
      if (DivContainer) {
        DivContainer.classList.add(ACTIVE_SPEAKER)
      }
      if (ActiveMicElement) {
        ActiveMicElement.classList.add("hide-anim-gif")
        ActiveMicElement.classList.remove("show-anim-gif")
      }

      if (AnimElement) {
        AnimElement.classList.add("show-anim-gif")
        AnimElement.classList.remove("hide-anim-gif")
      }
    } else {
      if (DivContainer) {
        DivContainer.classList.remove(ACTIVE_SPEAKER)
      }

      if (ActiveMicElement) {
        ActiveMicElement.classList.add("show-anim-gif")
        ActiveMicElement.classList.remove("hide-anim-gif")
      }

      if (AnimElement) {
        AnimElement.classList.add("hide-anim-gif")
        AnimElement.classList.remove("show-anim-gif")
      }
    }
  } catch (error) {
    console.log("error", error)
  }
}

export const resizeScreen = () => {
  try {
    const ParentElement = document.getElementById("radius-rooms-users-grid")
    if (ParentElement) {
      const tentativeChildElementHeight = ParentElement.clientHeight
      const tentativeChildElementWidth = ParentElement.clientWidth
      const childCount = ParentElement.childElementCount

      const maxChildrenInOneRow = Math.ceil(Math.sqrt(childCount))
      const totalNumberOfRows = Math.ceil(childCount / maxChildrenInOneRow)
      const heightOfSingleChild = tentativeChildElementHeight / totalNumberOfRows
      const widthOfSingleChild = (tentativeChildElementWidth / maxChildrenInOneRow) - 100

      for (let index = 0; index < childCount; index += 1) {
        ParentElement.children[index].setAttribute("style", `height: ${heightOfSingleChild}px; width: ${widthOfSingleChild}px;`)
        ParentElement.children[index].classList.add("add-grid-to-tile")
      }
    }
  } catch (e) {
    // failed silently
  }
}

let order = 2

export const activeSpeakerLogic = (id, volumeLevel, userIds) => {
  try {
    const ParentElement = document.querySelector("#sidebar")
    const childCount = ParentElement.childElementCount
    //pass array of user id where this fn is called to give exact count
    if (childCount) {
      const heightOfSingleChild = 135
      const maxChildOnScreen = Math.floor(ParentElement.clientHeight / heightOfSingleChild)
      const extraChild = userIds.length - maxChildOnScreen

      let lastChildInFrame = userIds.length - extraChild
      // give all elements which does not have hidden container
      let lastChild = ParentElement.children[lastChildInFrame]
      if (lastChild) {
        if (lastChild.classList.contains("hidden-container")) {
          if (lastChildInFrame !== 0) {
            lastChildInFrame -= 1
          } else {
            lastChildInFrame = childCount
          }
          lastChild = ParentElement.children[lastChildInFrame]
        }
      }
      const DivContainer = document.querySelector(`#video-container-${id}`)
      if (DivContainer) {
      // write logic to hide extra tiles in sidebar
        if (DivContainer.classList.contains("hidden-container") && volumeLevel > 15) {
          if (maxChildOnScreen < order) {
            order = 2
          }
          DivContainer.setAttribute("style", `order: ${order}; height: 135px; width: 290px;`)
          DivContainer.classList.add(OTHERS_VIDEO_FEED)
          DivContainer.classList.remove("hidden-container")
          order += 1
          lastChild.classList.add("hidden-container")
          // lastChild.classList.remove(OTHERS_VIDEO_FEED)
          lastChild.classList.remove("add-grid-to-tile")
        }
      }
    }
  } catch (e) {
    console.log("Error", e)
  }
}

export const roomDashedName = (roomName) => {
  const name = roomName.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g, "").split(" ").join("-").substr(0, 50)
  return name
}

export const createBackgroundConfigArray = (members) => {
  try {
    const configArray = []
    const {
      data: memberData,
    } = members || {}

    const {
      speakers,
    } = memberData || {}

    if (speakers) {
      speakers.forEach((agent) => {
        configArray.push({
          uid: `${agent.id}`,
          image_url: revertToJPEGFallback(agent.image_url),
          render_mode: 0,
        })
      })
    }

    return configArray
  } catch (e) {
    console.error("error creating config", e)
    return []
  }
}

export const revertToJPEGFallback = (url) => {
  if (!url || url === null) return `${CLOUDFRONT}/FallbackJPEG.jpg`

  const validPng = url.includes(".png") || url.includes("googleusercontent")

  return validPng ? `${CLOUDFRONT}/FallbackJPEG.jpg` : url
}

export const createUIDArray = (members) => {
  try {
    const configArray = []

    const {
      speakers,
      hosts,
      screen_shares,
    } = members || {}

    if (speakers) {
      speakers.forEach((agent) => {
        configArray.push(`${agent.id}`)
      })
    }

    // if (hosts) {
    //   hosts.forEach((agent) => {
    //     configArray.push(`${agent.id}`)
    //   })
    // }

    if (screen_shares) {
      screen_shares.forEach((agent) => {
        configArray.push(`${agent.unique_id}`)
      })
    }

    console.log("configArray", configArray)
    return configArray
  } catch (e) {
    console.error("error creating config", e)
    return []
  }
}

export const entryNewMember = (members, id) => {
  try {
    const filteredArray = []
    const {
      data: memberData,
    } = members || {}

    const {
      speakers,
    } = memberData || {}

    if (speakers) {
      const filtered = speakers.filter(item => item.id === id)
      if (filtered.length > 0) {
        filtered.forEach((agent) => {
          filteredArray.push({
            uid: `${agent.id}`,
            image_url: revertToJPEGFallback(agent.image_url),
            render_mode: 0,
          })
        })
      }
    }

    return filteredArray
  } catch (e) {
    console.error("error creating config", e)
    return []
  }
}
