import React, { Component } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { MOBILE_BREAK_POINT } from "shared/consts"
import { objectToQueryParams } from "services/Utils"
import icons from "@icons"
import colors from "@colors"
import GooglePlaces from "@ui/GooglePlaces"
import IconInput from "@ui/IconInput"
import DropdownMenu from "@ui/FeedOptions"

export const associationMap = {
  // crs: {
  //   name: "CRS",
  //   imageURL: "https://s3.amazonaws.com/cdn.agentdesks.com/images/crs-badge.png"
  // },
  areaa: {
    name: "AREAA",
    imageURL: "https://s3.amazonaws.com/cdn.agentdesks.com/images/areaaLogo.jpg"
  },
  samcar: {
    name: "SAMCAR",
    imageURL: "https://d3h12z5cxndkqx.cloudfront.net/communityGroupImages/samcar-100%402x.png"
  },
  wcr: {
    name: "Women's Council",
    imageURL: "https://s3.amazonaws.com/cdn.agentdesks.com/images/WCRLogoTransparent.png"
  },
}

const Wrapper = styled.div`
  margin: 0px 5px 0 30px;
  display: flex;
  width: 580px;
  height: 40px;

  /* input {
    border-top: 1px solid #e7e8ea;
  } */
  @media(max-width: 1280px) {
    margin: 0px 5px 0 auto;
  }
`

const CloseIcon = styled.i`
  font-size: 10px;
  font-weight: 300;
  color: ${colors.primaryInactiveColor};
  margin: 0 2px 0 6px;
`

const LocationTag = styled.div`
  margin: 0;
  cursor: pointer;
  line-height: 17px;
  width: 100%;
  display: flex;
  align-items: center;
`

const SearchIcon = styled.i`
  height: 40px;
  width: 50px;
  background-color: ${colors.primaryColor};
  color: ${colors.white};
  font-size: 20px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: none;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: 70px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:before {
    position: relative;
    top: 9px;
    left: 10px;
  }
`

const LocationWrapper = styled.div`
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    width: auto;
  }
`

const AssociationToggle = styled.div`
  width: 90px;
  background-color: ${colors.white};
  color: ${colors.tagInactive};
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.borderColor};
  border-right: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`

const AssocContainer = styled.div`
  width: 40px;
  padding: 4px;
`

const ExpandIcon = styled.i`
  color: ${colors.tagInactive};
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
`

const AssocLogo = styled.img`
  height: 30px;
`

const AssocName = styled.span`
  margin-left: 8px;
  padding: 4px;
  color: ${colors.tagInactive};
`

class AgentSearchInput extends Component {
  constructor(props) {
    super(props)
    const { location: { search } } = props
    this.state = this.getInitialState(search)
  }

  componentDidMount() {
    this.autoCompleteRef = React.createRef()
  }

  getInitialState = (search) => {
    const queryParams = queryString.parse(search)
    const stateObject = {
      addressTextBoxValue: "",
    }
    stateObject.fullname = queryParams.fullname || ""

    stateObject.location = null

    if (queryParams.locationType === "zipcode" && queryParams.zipcode) {
      const { locationType, zipcode } = queryParams
      stateObject.location = {
        zipcode,
        locationType,
      }
    } else if (queryParams.locationType === "location" && queryParams.city && queryParams.lat && queryParams.lng) {
      const { city, state, country, lat, lng, locationType } = queryParams
      stateObject.location = {
        city,
        state,
        country,
        lat,
        lng,
        locationType,
      }
    }

    stateObject.associationType = queryParams.associationType || null

    return stateObject
  }

  handleSubmit = () => {
    const { history: { push } } = this.props
    const { fullname, location, associationType } = this.state
    const search = objectToQueryParams({
      fullname,
      associationType,
      ...location,
    })
    push({
      pathname: "/search",
      search,
    })
  }

  handleNameUpdate = (e) => {
    const fullname = e.target.value
    this.setState({
      fullname,
    })
  }

  handleCityClick = (location) => {
    this.setState({
      location: {
        ...location,
        locationType: "location",
      },
    }, () => {
      this.handleSubmit()
    })
  }

  rawTextSearch = (text) => {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(text)

    if (text && isValidZip) {
      this.setState({
        location: {
          zipcode: text,
          locationType: "zipcode",
        },
      }, () => {
        this.handleSubmit()
      })
    }
  }

  removeLocation = () => {
    this.setState({
      addressTextBoxValue: "",
      location: null,
    }, () => {
      this.focusAutocompleteInput()
    })
  }

  handleFullnameRef = (input) => {
    this.fullnameInput = input
  }

  focusAutocompleteInput() {
    this.autoCompleteRef.current.focus()
  }

  onEnterSubmit = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit()
    }
  }

  handleAddressTextBoxChange = (addressTextBoxValue) => {
    this.setState({
      addressTextBoxValue,
    })
  }

  updateLocationObject = () => {
    const { addressTextBoxValue } = this.state
    this.rawTextSearch(addressTextBoxValue)
  }

  setAssociation = (associationType) => {
    this.setState({
      associationType,
    })
  }

  render() {
    const { fullname, location, addressTextBoxValue, associationType } = this.state
    const { isLogged } = this.props
    const isLocationSet = Boolean(location)
    return (
      <Wrapper
        isLogged={isLogged}
        id="agentSearchInput"
      >
        <DropdownMenu
          position="bottom-start"
          overlay={(
            <AssociationToggle>
              <AssocContainer>
                {associationType
                  ? (
                    <AssocLogo src={associationMap[associationType].imageURL} alt="radius-assiciations-logo" />
                  )
                  : (
                    <AssocName>All</AssocName>
                  )
                }
              </AssocContainer>
              <ExpandIcon className={icons.arrowsExpandDown} />
            </AssociationToggle>
          )}
        >
          <ListItem onClick={() => this.setAssociation(null)}>
            <AssocName>All</AssocName>
          </ListItem>
          {Object.keys(associationMap).map((assocType) => {
            return (
              <ListItem key={assocType} onClick={() => this.setAssociation(assocType)}>
                <AssocLogo src={associationMap[assocType].imageURL} alt="radius-assiciations-logo"/>
                <AssocName>{associationMap[assocType].name}</AssocName>
              </ListItem>
            )
          })}

        </DropdownMenu>

        <IconInput
          iconClassName={icons.genericUser}
          width="auto"
          style={{
            borderRight: 0,
            maxWidth: 240,
          }}
        >
          <input
            type="text"
            placeholder="Search agent"
            value={fullname}
            onChange={e => this.handleNameUpdate(e)}
            onKeyUp={this.onEnterSubmit}
            ref={this.handleFullnameRef}
          />
        </IconInput>
        <LocationWrapper>
          {isLocationSet ? (
            <IconInput
              iconClassName={icons.locationLocationPoint}
              style={{ maxWidth: 193 }}
              width="auto"
            >
              <LocationTag onClick={this.removeLocation}>
                {location.locationType === "zipcode"
                  ? location.zipcode
                  : location.city
                }
                <CloseIcon className={icons.arrowsClose} />
              </LocationTag>
            </IconInput>
          ) : (
            <GooglePlaces
              onLocationSelect={this.handleCityClick}
              rawTextSearch={this.rawTextSearch}
              addressTextBoxValue={addressTextBoxValue}
              handleAddressTextBoxChange={this.handleAddressTextBoxChange}
              handleSubmit={this.handleSubmit}
              handleOnBlur={this.updateLocationObject}
              forwardedRef={this.autoCompleteRef}
            />
          )}
        </LocationWrapper>

        <SearchIcon
          onClick={this.handleSubmit}
          className={icons.genericSearch}
        />

      </Wrapper>
    )
  }
}

export default withRouter(AgentSearchInput)
