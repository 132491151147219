import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import createToast from "@ui/Toast"
import Container from "container/TechAssets"
import { get } from "lodash"


const user = checkForUserObject("user")

const agentId = user && user.id

class PubnubForUpload extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      refreshListingDocuments,
      dealToView,
      updateDocusignEnvelopeStatus,
      updateDealStatus,
      getAllListings,
      getListingDocuments,
      toggleShowUpdate,
      setContentForUpdate,
    } = this.props
    const docusignUploadChannel = `docusign_listings_upload_${agentId}`
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {

      root.pubNubListingDocusign = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      root.pubNubListingDocusign.subscribe({
        channels: [docusignUploadChannel],
      })

      root.pubNubListingDocusign.addListener({
        message: (data) => {
          console.log("Listening to..", docusignUploadChannel) //needed to check if pubnub channel came

          try {
            const { message, channel } = data
            const { event_type } = message


            if(agentId && (channel === docusignUploadChannel) && message.listingId) {
              if(message.status == "active"){
                toggleShowUpdate(true)
                let content = `Document succesfully uploaded for ${message.address}`
                setContentForUpdate(content)
                getListingDocuments({
                    payload: {
                      listingId: dealToView,
                    },
                })
              }
              if(message.status == "processing"){
                toggleShowUpdate(true)
                setContentForUpdate("Envelope seems to be large. This may take time. We will notify you when the envelope is created. Please wait")
              }

              if(message.status == "action_required"){
                toggleShowUpdate(true)
                let content = `Envelope created for ${message.address}. Please check`
                setContentForUpdate(content)
              }
              
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { dealToView, } = this.props
    const docusignUploadChannel = `docusign_listings_upload_${agentId}`
    if (root.pubNubListingDocusign) {
      root.pubNubListingDocusign.unsubscribe({
        channels: [docusignUploadChannel],
      })
    }
  }

  render() {
    return null
  }
}

export default Container(PubnubForUpload)
