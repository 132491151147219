import React from "react"
import {
  CashbackSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const CashbackTab = ({
  routeArray,
}) => (
  <InnerTab isActive={routeArray[1] === "cashback"}>
    <div className="icon-block-new">
      <IconEle
        scale="1.5"
        src={CashbackSVGIcon}
        alt="RARleaderboard"
        isActive={routeArray[1] === "cashback"}
      />
    </div>
    <div className="text-block">
      <NavLink
        to="/realty/cashback"
        isActive={routeArray[1] === "cashback"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_cashback_web")
          }
        }}
      >
        Cashback
        {CookiesStorage.load("cashbackNewTag") && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )}
      </NavLink>
    </div>
  </InnerTab>
)

export default CashbackTab
