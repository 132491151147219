import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import axios from "axios"
import md5 from "md5"
import queryString from "query-string"
import {
  L_MAX_MOBILE_BREAK_POINT_LIMIT,
  preTabletWidth,
} from "@consts"
import { get } from "lodash"
import loginContainer from "container/App/applyFlowContainer"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import AppleIcon from "images/home-v7/apple-login.png"
import GoogleIcon from "images/home-v7/google-login.png"
import GoogleSignIn from "dumbComponents/Auth/GoogleSignIn"
import createToast from "@ui/Toast"
import cookie from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import { GetUserPlatform, UrlSearchParams, getOnboardingNavigateRoute } from "dumbComponents/Auth/authUtils"
import colors from "@colors"
import Modal from "@ui/Modal"
import Container from "container/BrokerJobs"
import Form from "./LoginForm"

const OrWrap = styled.div`
  height: 2px;
  color: ${colors.black};
  position: relative;
  margin: 35px auto;
  background: ${colors.black};
  max-width: 300px;

  > span {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.white};
    font-family: ${ATC};
    padding: 2px 5px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 45px;
  flex-direction: column;
`

const FormArea = styled.div``

const SocialButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  color: #303030;

  > span {
    font-size: 18px;
  }

  > img {
    max-height: 25px;
    margin-right: 12px;
    filter: brightness(0);
  }

  ${props => props.isGoogle && `
    background: #E4E4E4;
    color: ${colors.black};
  `}

  ${props => props.isApple && `
    background: #000;
    color: #303030;
  `}

  &:hover {
    background: #fffc;
  }

  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
  }

  margin-bottom: 40px;
`

const Wrap = styled.div`
  font-family: ${ATC};
  max-width: 500px;
`

const Header = styled.div`
  h1 {
    font-family: Butler;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
  }

  margin-bottom: 40px;
`

const SocialLoginWrap = styled.div`
`

class LoginForm extends React.PureComponent {
  state = {
    isJobPage: false,
    modalWidth: "auto",
    header: "Thank you for your interest. In the meantime...",
    subText: "Start your free community account and connect with 80,000+ agents today.",
    dialogStyles: {
      background: colors.white,
    },
    modalStyles: {},
  }

  componentDidMount() {
    const { pathname } = root.location
    const splitPath = pathname.split("/")
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const currentPathName = splitPath.length > 2 ? splitPath[splitPath.length - 1] : splitPath[1]
    const {
      platform,
    } = parsedQuery || {}
    if (currentPathName === "jobs") {
      this.setState({
        isJobPage: true,
        modalWidth: "650px",
        header: "Thank you for applying!",
        subText: "Finish creating your account for exclusive access to the Radius community and more.",
      })
    } else if (platform === "ios") {
      this.setState({
        isIOS: platform === "ios",
        header: "Thank you for your interest!",
        subText: "",
        modalWidth: "650px",
      })
    }
    // const {  } = this.props
  }

  googleSignIn = (res) => {
    const { pathname } = root.location
    const {
      history: {
        push,
      },
      logInModalClassName,
    } = this.props
    if (root.heap) {
      root.heap.track("Click_Google_Sign_In_SSO")
    }
    const data = {
      get_refresh_token: true,
      auth_code: res.code,
      redirect_uri: "postmessage",
      signup_source:
        UrlSearchParams(pathname, "signup_source") !== null ? UrlSearchParams(pathname, "signup_source") : undefined,
    }
    if (res.code) {
      axios.post(`${API_URL}user/gmail/sign-up`, data)
        .then(
          (result) => {
            if (result.status > 400) {
              throw result
            }
            root.localStorage.setItem("variant_className", logInModalClassName)
            cookie.save("secureId", result.data.response.secure_id)
            if (result.data.response.newUser) {
              root.localStorage.setItem("google_user", JSON.stringify(result.data.response.user))
              root.localStorage.setItem("socialLogin", JSON.stringify({
                type: "google_login",
                firstname: result.data.response.user.firstname,
                lastname: result.data.response.user.lastname,
                email: result.data.response.user.email,
              }))

              const userPhoneNumber = get(result, "data.response.user.phone")

              this.goToIncompleteProfile(userPhoneNumber)
              // window.location.href = RADIUS_URL + "/signup/social"
              // push("/onboarding/tell-about-yourself")
              return
            }
            const { user } = result.data.response
            const {
              account_type,
            } = user || {}
            cookie.saveUserToCookies(user)

            if (cookie.load("redirectUrl")) {
              const redirectUrl = cookie.load("redirectUrl")
              cookie.remove("redirectUrl")
              window.location.href = `${APP_URL}${redirectUrl}`
              return
            }
            if (GetUserPlatform() === "desktop") {
              const initialLoginFlag = {
                id: result.data.response.user.id,
                flag: true,
              }
              cookie.save("initialLoginFlag", JSON.stringify(initialLoginFlag))
              const userPhoneNumber = get(result, "data.response.user.phone")
              this.goToIncompleteProfile(userPhoneNumber)
            } else if (result.data.response.newUser) {
              root.localStorage.setItem("socialLogin", JSON.stringify({
                type: "google_login",
                firstname: result.data.response.user.firstname,
                lastname: result.data.response.user.lastname,
                email: result.data.response.user.email,
              }))
              const userPhoneNumber = get(result, "data.response.user.phone")
              this.goToIncompleteProfile(userPhoneNumber)
            } else {
              window.location.href = `${RADIUS_URL}/page/redirect?tag=landing_page&params={}`
            }
          },
          (error) => {
            createToast("Something went wrong!", "erorr")
          },
        ).catch((error) => {
          const errorMessage = get(error, "data.error.message")
          createToast(errorMessage, "erorr")
        })
    } else {
      console.error(res)
    }
  }

  goToIncompleteProfile = (isPhone) => {
    const {
      history: {
        push,
      },
    } = this.props
    if (!isPhone || isPhone === null) {
      push("/signup/social")
    } else {
      window.location.href = "/community"
    }
  }

  createLoginLink = () => {
    const config = {
      response_type: "code",
      response_mode: "form_post",
      redirect_uri: root.encodeURIComponent(`${RADIUS_URL}/login`),
      scope: "name%20email",
      state: "a5f24dc7c2",
    }
    return `https://appleid.apple.com/auth/authorize?client_id=${APPLE_CLIENT_ID}&redirect_uri=${config.redirect_uri}&response_type=${config.response_type}&scope=${config.scope}&response_mode=${config.response_mode}&state=${config.state}`
  }

  handleAppleLogin = () => {
    const {
      logInModalClassName,
    } = this.props || {}
    root.localStorage.setItem("variant_className", logInModalClassName)
    const authLink = this.createLoginLink()
    root.location.href = authLink
  }

  createAccount = (signUpFormValues) => {
    if (root.heap) {
      root.heap.track("Click_Sign_Up_CTA")
    }

    const {
      logInModalClassName,
    } = this.props || {}

    const {
      first_name: firstname,
      last_name: lastname,
    } = signUpFormValues || {}
    this.setState({ isSubmitted: true })
    const { state } = this
    const pathname = root.location.search
    const { phone, countryCode } = this.state
    const accountType = signUpFormValues.account_type && signUpFormValues.account_type.value
    //const mlsLiecense = state.signupForm.mlsLiecense.value
    // const nameArray = signUpFormValues.firstname
    // const firstname = signUpFormValues.firstname
    // const lastname = signUpFormValues.lastname
    const data = {
      username: {
        firstname: firstname || "",
        lastname: lastname || "",
      },
      account_type: accountType || "real_estate_agent",
      email: signUpFormValues.email,
      password: md5(signUpFormValues.password),
      phone: signUpFormValues.phone,
      signup_source: signUpFormValues.signup_source ||
        UrlSearchParams(pathname, "signup_source") !== null ? UrlSearchParams(pathname, "signup_source") : undefined,
    }

    if (accountType === "Other") {
      data.is_other_account_type = true
    }

    // handling recruit and earn flow
    const recruitAndEarnId = cookie.load("recruitAndEarnId")
    const inviteType = cookie.load("inviteType")
    if (recruitAndEarnId) {
      data.signup_source = "recruit_and_earn"
      data.invite_code = recruitAndEarnId
      data.invite_type = inviteType
    }
    this.setState({ isSaving: true })
    axios.post(`${API_V2_URL}user/sign-up`, data)
      .then(
        (result) => {
          root.localStorage.setItem("variant_className", logInModalClassName)
          const {
            data: {
              response,
            },
          } = result || {}
          const {
            user: userDetail,
          } = response || {}
          const user = userDetail
          // Inspectlet and rootscope profile image portion pending
          cookie.save("secureId", result.data.response.secure_id)
          if (result.data.response.newUser) {
            cookie.save("newUser", result.data.response.newUser)
          }
          // Saving Cookie user Object
          cookie.saveUserToCookies(user)

          // identify the user
          if (window.heap) {
            window.heap.identify(get(user, "id"))
          }
          axios
            .post(
              `${API_V2_URL}appDownloadSms`,
              { phone: signUpFormValues.phone },
              { headers: { "secure-id": result.data.response.secure_id } },
            )
            .then(
              (res) => {
                this.setState({ isSaving: false })
                if (
                  cookie.load("redirectUrl")
                  && cookie.load("redirectUrl").indexOf("isa-program") === -1
                  // && cookie.load("redirectUrl").indexOf("radius-assist") === -1
                  && cookie.load("redirectUrl").indexOf("academy") === -1
                ) {
                  const redirectUrl = cookie.load("redirectUrl")
                  cookie.remove("redirectUrl")
                  this.redirectionFlow(accountType, redirectUrl)
                } else {
                  this.redirectionFlow(accountType, false)
                }
              },
              (error) => {
                console.error(error)
                this.setState({ isSaving: false })
                if (cookie.load("redirectUrl")) {
                  const redirectUrl = cookie.load("redirectUrl")
                  cookie.remove("redirectUrl")
                  window.location.href = `${APP_URL}${redirectUrl}`
                } else {
                  this.redirectionFlow(accountType, false)
                }
              },
            )
        },
        (error) => {
          console.log(error)
          this.setState({ isSaving: false })
          createToast(get(error, "data.error.message") || "Oop! Something went wrong", "error")
        },
      ).catch((error) => {
        console.log(error)
        createToast(get(error, "data.error.message") || "Oop! Something went wrong", "error")
      })
  }

  redirectionFlow = (accountType, redirectUrl) => {
    const {
      history: {
        push,
      },
      toggleSignUpForm,
    } = this.props
    if (accountType === "Independent Real Estate Agent") {
      toggleSignUpForm(false)
      push("/onboarding/tell-about-yourself")
    } else if (redirectUrl) {
      root.location.href = redirectUrl
    } else {
      root.location.href = "/community"
    }
  }

  handleSubmit = () => {

  }

  getFormDetails = () => {
    const { JobFormOne } = this.props
    if (JobFormOne && JobFormOne.email) {
      return {
        email: JobFormOne.email,
      }
    }
    return {}
  }

  getFormValues = () => {
    const { signUpFormValues, JobFormOne } = this.props
    if (JobFormOne && JobFormOne.email) {
      return {
        signup_source: "brokerage-jobs",
        firstname: `${JobFormOne.first_name} ${JobFormOne.last_name}`,
        email: JobFormOne.email,
        phone: JobFormOne.phone,
      }
    }
    if (signUpFormValues && signUpFormValues.email) {
      return signUpFormValues
    }
    return {}
  }

  render() {
    const {
      isSaving, modalWidth, subText, header, dialogStyles, modalStyles, isIOS,
    } = this.state
    const {
      isShowLoginModal,
      toggleSignUpForm,
      signUpHeaderText,
      signUpSubText,
      toggleFormContent,
      logInModalClassName,
    } = this.props || {}
    // const formDetails = this.getFormDetails()

    const fallbackText = `Tell us a little about yourself and one of our 
              team members will get to you shortly.`

    const initialValues = this.getFormValues()
    const appleAuthLink = this.createLoginLink()
    const isMobile = root.innerWidth <= L_MAX_MOBILE_BREAK_POINT_LIMIT
    return (
      <Modal
        toClose={() => { toggleSignUpForm(false) }}
        show={isShowLoginModal}
        width={isMobile ? "335px" : modalWidth}
        modalVersion={3}
        closeIcon={CloseIcon}
        dialogStyles={(toggleFormContent && toggleFormContent.dialogStyles) || dialogStyles}
        modalStyles={{
          padding: "30px 40px 10px 40px",
        }}
      >
        {/* <Wrap>
          <h1>
            {toggleFormContent ? toggleFormContent.header : header}
          </h1>
          <p>
            {toggleFormContent ? toggleFormContent.subtext : subText}
          </p>
          {!isIOS && (
            <Form
              onSubmit={this.createAccount}
              handleAppleLogin={this.handleAppleLogin}
              handleGoogleLogin={this.googleSignIn}
              initialValues={{ ...initialValues }}
              formStyles={(toggleFormContent && toggleFormContent.formStyles) || {}}
              isLoading={isSaving}
            />
          )}
        </Wrap> */}
        <Wrap>
          <Header>
            <h1>{`${signUpHeaderText || "Nice to meet you!"}`}</h1>
            <p>
              {`${signUpSubText || fallbackText}`}
            </p>
          </Header>
          <SocialLoginWrap>
            <ButtonWrap>
              <GoogleSignIn
                isModal
                signIn={this.googleSignIn}
                customButtonStyles={{
                  background: "#ffffff",
                  border: "0.5px solid black",
                  borderRadius: "100px",
                  fontFamily: "Satoshi",
                  fontSize: "18px",
                  color: "#303030",
                }}
              />
              <SocialButton
                isApple
                onClick={this.handleAppleLogin}
                style={{
                  background: "#ffffff",
                  border: "0.5px solid black",
                  borderRadius: "100px",
                  fontFamily: "Satoshi",
                  color: "#303030",
                }}
              >
                <img src={AppleIcon} alt="Apple Login" />
                <span>
                  Continue with Apple
                </span>
              </SocialButton>
            </ButtonWrap>
          </SocialLoginWrap>
          <FormArea>
            {!isIOS && (
              <Form
                onSubmit={this.createAccount}
                handleAppleLogin={this.handleAppleLogin}
                handleGoogleLogin={this.googleSignIn}
                initialValues={{ ...initialValues }}
                formStyles={(toggleFormContent && toggleFormContent.formStyles) || {}}
                isLoading={isSaving}
                className={logInModalClassName}
              />
            )}
          </FormArea>
        </Wrap>
      </Modal>
    )
  }
}

export default loginContainer(
  Container(withRouter(LoginForm))
)
