import React from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import icons from "@icons"
import colors from "@colors"
import checkIconPNG from "images/icons/check-icon.png"
import errorIconSVG from "images/icons/error-icon.svg"

const ToastWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`

const StyledText = styled.p`
  color: ${colors.black};
  margin-bottom: 0;
`

const StyledCloseIcon = styled.i`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
`

const StyledStatusIcon = styled.img`
  margin: 2px 6px;
  width: 16px;
  height: 16px;
`

const toastOptions = {
  closeButton: false,
  hideProgressBar: true,
}

const toastIconMap = {
  success: checkIconPNG,
  error: errorIconSVG,
}

const CustomToast = ({
  closeToast,
  toastText,
  toastType,
  style,
}) => (
  <ToastWrapper>
    <StyledStatusIcon
      src={toastIconMap[toastType]}
    />
    <StyledText style={style}>
      {toastText}
    </StyledText>
    <StyledCloseIcon
      className={icons.arrowsClose}
      onClick={closeToast}
    />
  </ToastWrapper>
)

const createToast = (toastText, toastType = "success") => {
  switch (toastType) {
    case "success": {
      toast(<CustomToast toastText={toastText} toastType={toastType} />, toastOptions)
      break
    }
    case "error": {
      toast.error(
        <CustomToast
          style={{ color: colors.white }}
          toastText={toastText}
          toastType={toastType}
        />, toastOptions
      )
      break
    }
    default:
      toast(<CustomToast toastText={toastText} toastType={toastType} />, toastOptions)
  }
}

export default createToast
